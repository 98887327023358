import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  DownlineTreeviewItem,
  OrderDownlineTreeviewEventParser,
  TreeviewComponent,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewItem,
} from 'ngx-treeview';
import { Observable } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { USER_TYPES } from 'src/constants/application.const';
import { APIResponseModel } from '../../../../interface/response.interface';


@Component({
  selector: 'app-access-control',
  templateUrl: './access-control.component.html',
  providers: [
    {
      provide: TreeviewEventParser,
      useClass: OrderDownlineTreeviewEventParser,
    },
  ],
})
/**
 * Access control component
 */
export class AccessControlComponent implements OnInit {
  public sectionList: Array<any> = null;
  @ViewChild(TreeviewComponent, { static: false }) treeView: TreeviewComponent;
  public permissionList: Array<any> = [];
  public items: TreeviewItem[];
  public config = TreeviewConfig.create({
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 300,
  });
  public advisorId: string;
  public firmId:string;
  public consumerId: string;
  public isLoading: boolean;
  public isAdvisor: boolean = false;

  /**
   * constructor
   */
  constructor(
    private modalService: CommonModelService,
    private peopleService: PeopleService,
    private commonHelper: CommonHelper,
    private localStorageService:LocalStorageService
  ) {
  }

  /**
   * loaded on initialization
   */
  ngOnInit(): void {
    if (this.sectionList === null) { // To reduce on repetitive calls
      this.getSectionsList();
    }
  }

  /**
   * Setter for edit values in access control
   *
   * @param {any} data
   */
  @Input() set advisorData(data: any) {
    this.advisorId = data?.advisor_id ?? data?.id;
    this.firmId = data?.id;
    this.consumerId = data?.user_id;
    if (data?.role_type != undefined) {
      this.isAdvisor = true;
    }
    this.items = this.getItems(data?.['permission']);
    if (data?.isUpdatePermission) {
      this.updateAccess().subscribe();
    }

    // Disable shimmer-loaders from getSectionsList if we get items set-up beforehand.
    if (this.items.length > 0) {
      this.isLoading = false;
    }
  }

  /**
   *  Return {@link this.items} containing all the permission data.
   */
  public getPermissionsData() {
    return this.items;
  }

  /**
   * get sections list
   */
  public getSectionsList() {
    this.isLoading = true;
    const clientData = this.localStorageService.getUserData(USER_TYPES.user);
    const isPrimaryAdvisor = clientData && clientData?.['slug_details']?.id === this.firmId
    const viewValue = !this.isAdvisor|| isPrimaryAdvisor ? 1 : 0; // make value checked
    this.peopleService.getDashboardSection().subscribe({
      next: (response) => {
        this.sectionList = [];        
        response.data.forEach((e) => {
          this.sectionList.push({
            permission_id: e['id'],
            permission_name: e['name'],
            view: 0, add: 0, edit: 0, remove: 0,
          });
        });
      },
      error: (error) => {
        this.commonHelper.httpResponseHandler(error.error);
        this.isLoading = false;
      }, complete: () => {
        this.isLoading = false;
        if (this.items?.length == 0) {
          this.items = this.getItems([]);
        }
      },
    });
  }


  /**
   * Merge the sections from {@link sectionList} and {@link permissionList} and return as a new array.
   * The purpose of this is to always ensure all the sections are shown in the ACL, even if only partial sections were
   * saved.
   * @param permissionList
   * @private
   */
  private mergeList(permissionList: Array<any>): Array<any> {
    const newList = permissionList ? Array.from(permissionList) : [];
    this.sectionList?.forEach((sectionList_item) => {
      // If no matching ID in newList, add item to newList
      if (!newList.find((newList_item) => newList_item['permission_id'] == sectionList_item['permission_id'])) {
        newList.push(sectionList_item);
      }
    });
    return newList;
  }


  /**
   * get items
   * @param {Array} permissionList
   * @return {Array}
   */
  public getItems(permissionList: Array<any>) {
    const itemsArray = [];
    const list = this.mergeList(permissionList);
    // let list = permissionList?.length ? permissionList : this.sectionList;

    list?.forEach((_cur, index) => {
      const item = {
        text: list[index]?.permission_name,
        value: list[index]?.permission_id,
        collapsed: index !== 0, // Open the first tree automatically
        checked: false,
        children: [
          {
            text: 'View',
            value: list[index]?.view,
            checked: list[index]?.view == 1,
          },

        ],
      };

      if (this.isAdvisor) {
        item.children.push(
          {
            text: 'Add',
            value: list[index]?.add,
            checked: list[index]?.add == 1,
          },
          {
            text: 'Edit',
            value: list[index]?.edit,
            checked: list[index]?.edit == 1,
          },
        );
      }

      itemsArray.push(new TreeviewItem(item));
    });

    return itemsArray;
  }


  /**
   *
   * @param {DownlineTreeviewItem}downlineItems
   * @param {any}data
   */
  public onSelectedChange(downlineItems: DownlineTreeviewItem[], data: any) {
    this.items = data.items;
    this.checkViewImplicit();
  }

  /**
   * add/update access
   */
  public updateAccess(): Observable<APIResponseModel> {
    // add/ update
    this.items.forEach((cur) => {
      cur['permission_id'] = cur['id'];
    });
    const payload = {
      permission: this.items,
      advisor_id: this.advisorId,
      user_id: this.consumerId,
    };
    return new Observable((observer) => { // save advisor permissions
      this.peopleService.saveAdvisorPermissions(payload).subscribe({
        next: (r) => observer.next(r),
        error: (e) => {
          this.commonHelper.httpResponseHandler(e.error);
          observer.error(e);
        },
        complete: () => observer.complete(),
      });
      return {
        unsubscribe() {
        },
      };
    });
  }

  /**
   * For each permission, check and set implicitly true view.
   * If `add`, `edit`, or `remove` are `true`, then `view` should be implicitly `true`.
   * @private
   */
  private checkViewImplicit() {
    this.items.forEach((section) => {
      const implicit = !!section.children.find((e) => e.text != 'View' && e.checked);
      if (implicit) {
        section.children.find((e) => e.text == 'View').setCheckedRecursive(true);
      }
      section.correctChecked();
    });
  }
}
