import { Component } from '@angular/core';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html'
})
/**
 * Support component
 */
export class SupportComponent {}
