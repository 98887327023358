import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ObservableEventInterface} from '../interface/common.interface';
import {APIResponseModel} from '../interface/response.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * Overview service for all user data
 */
export class OverviewService {
  public BASE_URL:string;
  /**
   * dashboard data event handlers
   */
  public dashboardData:any;
  public dashboardDataObserve:Observable<any>;
  /**
   * @constructor
   */
  constructor(
    private http:HttpClient,
  ) {
    this.BASE_URL = environment.BASE_URL;
    this.dashboardData=new BehaviorSubject({});
    this.dashboardDataObserve=this.dashboardData.asObservable();
  }
  /**
   * dashboard data obserable events
   *
   * @param {ObservableEventInterface} data
   */
  public dashboardDataObservableEvents(data: ObservableEventInterface):void {
    this.dashboardData.next(data);
  }
  /**
   * get barchart data
   * @param {string}requestId
   * @return {APIResponseModel}Observable
   */
  public getPlaidtoken(requestId:string):Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL+`/request/get-plaid-token-chart/${requestId}`);
  }
  /**
   * get barchart data
   * @param{string}data
   * @return {APIResponseModel}Observable
   */
  public getBarChartData(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL+`/request/get-plaid-transactions-chart`, data);
  }
}
