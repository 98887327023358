<!-- Begin: Whole Page  -->
<div class="lg:grid grid-cols-12 background-color-grey">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 my-4">
              <div class="col-span-12 sm:col-span-2">
                <img src="assets/images/executors/executors.svg" class="w-20" alt="digital vault">
              </div>

              <div class="col-span-12 sm:col-span-10">
                <div class="flex items-center gap-4">
                  <h2 class="text-darkblue text-2xl break-word sm:text-3xl uppercase text-semibold">
                    Digital Vault Custodian
                    <a class="inline-block" (click)="openModal('custodianDigitalVault')"
                      title='Click here for help from Aida'>
                      <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                        stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1n text-primary cursor-pointer">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                      </svg>
                    </a>
                  </h2>
                </div>
                <p class="text-sm text-grey tracking-wide text-regular">
                  This information is critical and a key to verifying their identity. Please double-check your input
                  prior to moving on.
                </p>
              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-4/6'"></app-card-progress-bar>
              <!-- END: Progress Bar -->

              <!--Begin : Whether this is alternate custodian flow or not -->
              <div class="items-center pt-6 px-6 pb-4">
                <ng-container *ngIf="!isAlternativeCustodian">
                  <h4 class="text-regular text-lg text-darkblue font-semibold capitalize tracking-wide">
                    Your Digital Vault Custodian
                  </h4>
                  <p class="text-grey text-regular tracking-wide pt-1 text-sm">
                    You have selected the below executor(s) as your Digital Vault Custodian(s). They are responsible
                    for notifying LegacyNOW of your passing. The confirmation of your passing is critical to allowing
                    LegacyNOW and your Executors (and others) perform their services.
                  </p>
                </ng-container>
                <ng-container *ngIf="isAlternativeCustodian">
                  <h4 class="text-regular text-lg text-darkblue font-semibold  tracking-wide">
                    Who do you want as your Digital Vault Custodian (DVC) ?
                  </h4>
                </ng-container>
                  <p class="text-grey text-regular tracking-wide pt-1 text-sm">
                    When selecting a Digital Vault Custodian, please elect a person who meets the minimum
                    requirements:
                  </p>
                  <ul class="my-2">
                    <li class="text-grey text-regular pt-2 text-sm tracking-wide">
                      ➝ At least 18
                    </li>
                    <li class="text-grey text-regular pt-2 text-sm tracking-wide">
                      ➝ Is capable of providing official Proof of Death
                    </li>
                    <li class="text-grey text-regular pt-2 text-sm tracking-wide">
                      ➝ Trustworthy and responsible
                    </li>
                    <li class="text-grey text-regular pt-2 text-sm tracking-wide">
                      ➝ Is informed of your decision and accept the responsibilities associated.
                    </li>
                  </ul>
                <p class="text-grey text-regular tracking-wide pt-1 text-sm">
                  <b>Please Note:</b> Once you proceed to the next section, we will reach out to your elected Digital
                  Vault Custodian(s) via email to establish communication and
                  acceptance of their role, and to set up a dashboard for them. Your contact information will be
                  available to them from their DVC dashboard.
                </p>
              </div>
              <!--End : Whether this is alternate custodian flow or not  -->
              <!-- If it is alternate/digital vault custodian and some custodians are already selected or added -->
              <div class="px-6 pb-4">
                <ng-container *ngIf="hasCustodianSelected && isAlternativeCustodian">
                  <!-- First Consent -->
                  <!-- If no executors are added, consent to give vault access. If it is exe-custodian access for this content is already given. But consent is not being showed-->
                  <div class="grid grid-cols-12 items-center gap-4 row-gap-4 pb-2">
                    <div class="col-span-12 sm:col-span-8">
                      <div>
                        <app-shimmer-loading [loading]="loadingConsent" class="w-full h-12">
                          <p class="text-grey text-regular pt-1 text-sm text-justify">
                            <fa-icon *ngIf="accessVaultToCustodian==1" [icon]="faCheckCircle"
                              class="green-tick"></fa-icon>
                            <fa-icon *ngIf="accessVaultToCustodian!==1" [icon]="faCircleXmark"
                              class="red-tick"></fa-icon>
                            I hereby grant LegacyNOW permission to allow the Digital Vault Custodian(s) below to access
                            my
                            Digital Vault before my passing.
                          </p>
                        </app-shimmer-loading>
                      </div>
                    </div>
                    <div class="col-span-12 sm:col-span-4">
                      <app-shimmer-loading [loading]="loadingConsent" class="w-full h-12">
                        <app-toggle-switch [defaultSelected]="accessVaultToCustodian" [options]="toggleOptions"
                          (optionChangeEmitter)="toggleAccessVaultToCustodian($event)">
                        </app-toggle-switch>
                      </app-shimmer-loading>
                    </div>
                  </div>

                  <!-- Second Consent -->
                  <!-- If executors are added,consent to inform executors. If yes fourth consent will be shown -->
                  <div *ngIf="hasNamedExecutor" class="grid grid-cols-12 items-center gap-4 row-gap-4 pb-2">
                    <div class="col-span-12 sm:col-span-8">
                      <div>
                        <app-shimmer-loading [loading]="loadingConsent" class="w-full h-12">
                          <p class="text-grey text-regular pt-1 text-sm text-justify">
                            <fa-icon *ngIf="accessToInformExecutor==1" [icon]="faCheckCircle"
                              class="green-tick"></fa-icon>
                            <fa-icon *ngIf="accessToInformExecutor!==1" [icon]="faCircleXmark"
                              class="red-tick"></fa-icon>
                            I hereby grant LegacyNOW the permission to allow the Digital Vault Custodian(s) to inform
                            the
                            Executor(s) about my Digital Vault, upon my passing.
                          </p>
                        </app-shimmer-loading>
                      </div>
                    </div>
                    <div class="col-span-12 sm:col-span-4">
                      <app-shimmer-loading [loading]="loadingConsent" class="w-full h-12">
                        <app-toggle-switch [defaultSelected]="accessToInformExecutor" [options]="toggleOptions"
                          (optionChangeEmitter)="toggleInformExecutor($event)">
                        </app-toggle-switch>
                      </app-shimmer-loading>
                    </div>
                  </div>

                  <div *ngIf="hasNamedExecutor && accessToInformExecutor==1" class="background-color-grey gap-3 row-gap-3 pb-2 flex">
                    <p class="text-grey text-regular pt-1 text-sm">
                     <b>Info: </b> As part of our standard procedure, the appointed Digital Vault Custodian (DVC) must promptly submit proof of death (POD) for verification to notify the designated Executor(s).
                    </p>
                  </div>
                  <!-- Third Consent -->
                  <!-- If inform executor access has not been given  -->

                  <div *ngIf="!accessToInformExecutor || !hasNamedExecutor"
                    class="grid grid-cols-12 items-center gap-4 row-gap-4 pb-2">

                    <div class="col-span-12 sm:col-span-8">
                      <div>
                        <app-shimmer-loading [loading]="loadingConsent" class="w-full h-12">
                          <p class="text-grey text-regular pt-1 text-sm text-justify">
                            <fa-icon *ngIf="accessToClaimVault==1" [icon]="faCheckCircle" class="green-tick"></fa-icon>
                            <fa-icon *ngIf="accessToClaimVault!==1" [icon]="faCircleXmark" class="red-tick"></fa-icon>
                            I hereby grant LegacyNOW the permission to allow the Digital Vault Custodian(s) to claim my
                            Digital
                            Vault, upon my passing.
                          </p>
                        </app-shimmer-loading>
                      </div>
                    </div>
                    <div class="col-span-12 sm:col-span-4">
                      <app-shimmer-loading [loading]="loadingConsent" class="w-full h-12">
                        <app-toggle-switch [defaultSelected]="accessToClaimVault" [options]="toggleOptions"
                          (optionChangeEmitter)="toggleAccessToClaimVault($event)">
                        </app-toggle-switch>
                      </app-shimmer-loading>
                    </div>
                  </div>

                </ng-container>

                <!-- people listing component -->
                <div *ngIf="userPeopleData?.length" class="mb-6">
                  <app-list-people [lpSettings]="lpSettings" [userPeopleData]="userPeopleData"
                    (clickEmitter)="clickListener($event)">
                  </app-list-people>
                </div>
                <!-- Show/Hide Add Button -->
                <ng-container *ngIf="showAddButton">
                  <button type="button"
                    class="button button--lg w-full flex flex-row justify-center items-center bg-transparent border-lightblue rounded-xs h-12 md:h-16 focus:outline-none text-regular font-semibold tracking-wide text-darkblue px-6 text-sm md:text-base"
                    (click)="toggleModal()">
                    <img src="assets/images/about-yourself/add-plus.svg" class="w-6 mr-3 hidden sm:block"
                      alt="digital vault">
                    Add a Digital Vault Custodian
                  </button>
                </ng-container>

                <!-- Navigation Buttons -->
                <div class="flex flex-col-reverse sm:flex sm:flex-row gap-4 justify-between items-center sm:mt-8">
                  <a (click)="click('Back')" *ngIf="this.userType === 'Consumer'"
                    class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </a>
                  <a (click)="click('Back')" *ngIf="this.userType !== 'Consumer'"
                    class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </a>
                  <div
                    class="flex flex-col-reverse sm:flex sm:flex-row w-full sm:w-auto items-center gap-4 mt-8 sm:mt-0">
                    <button (click)="click('ReturnToDashboard')" *ngIf="this.userType !== 'Custodian'"
                      class="flex items-center justify-center border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 focus:outline-none text-regular text-white whitespace-nowrap px-6">
                      Return to Dashboard
                    </button>
                    <app-button-loader class="w-full" [buttonText]="'Save and Continue'" [valid]="isValidSave"
                      [loader]="submitLoader" [buttonType]="'button'" (click)="click('Next')">
                    </app-button-loader>
                  </div>
                  <!-- END: Leftside Card -->
                </div>
              </div>
            </div>
            <!-- END: Left Side Card -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class="col-span-12 md:col-span-6 lg:grid justify-center">
    <about-yourself-right-content [formInputValue]="{}" [otherValues]="sectionSaveExitOptions" [otherArrayValues]="{}">
    </about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->

</div>
<!-- END - Whole Page -->

<!-- Modals -->

<app-choose-people [userPeopleData]="chooseCustodiansData" [cpcSettings]="cpcSettings"
  [loadListener]="lpSettings.isLoading" (updateEmitter)="updateListener($event)">
</app-choose-people>

<app-add-custodian [editData]="editData" (toggleModalEmitter)="addModalListener($event)"></app-add-custodian>
<!-- BEGIN: AccessControl -->
<app-access-control-modal [advisorData]="editACLData" [description]="DVC_DESCRIPTION_TEXT"
  (saveEmitter)="aclSaveListener($event)">
</app-access-control-modal>

<app-message-modal [setMessageSettings]="messageSettings" [setMessageData]="messageData" (buttonEmitter)="messageModalListener($event)"></app-message-modal>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>
