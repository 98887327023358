import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIResponseModel } from '../../../interface/response.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * User services
 */
export class CardService {
  private BASE_URL: string
  /**
   * @constructor
   */
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.BASE_URL;
  }

  /**
   * Add a card via API.
   *
   * @param {Object} cardData - Data of the card to be added.
   * @param {boolean} [isPro=false] - Indicates whether the user is a professional user.
   * @returns {Observable<APIResponseModel>} - An Observable emitting the API response.
   */
  public addCardApi(cardData: any, isPro: boolean = false): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(
      this.BASE_URL + (isPro ? '/professional/' : '/') + 'add-card',
      cardData
    );
  }

  /**
   * Retrieve cards
   *
   * @param {boolean} [isPro=false] - Indicates whether the user is a professional user.
   * @return {Observable<APIResponseModel>} - An Observable emitting the API response containing the card list.
   */
  public getCardsApi(isPro: boolean = false): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(
      this.BASE_URL + (isPro ? '/professional/' : '/') + 'card-list'
    );
  }

  /**
   * Generates a Stripe card token using the provided card information.
   * @param {any} card_number - The card number.
   * @param {any} exp_month - The expiration month of the card.
   * @param {any} exp_year - The expiration year of the card.
   * @param {any} cvv - The card verification code (CVC).
   * @returns {Observable<any>} - An Observable that represents the result of the HTTP POST request to the Stripe API.
   */
  public generateCardToken(card_number: any, exp_month: any, exp_year: any, cvv: any) {
    const body = `card[number]=${card_number}&card[exp_month]=${exp_month}&card[exp_year]=${exp_year}&card[cvc]=${cvv}`
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${environment.stripe.publicKey}`
    });
    return this.http.post('https://api.stripe.com/v1/tokens', body, { headers });
  }

  /**
   * Delete a card
   *
   * @param {Object} cardData - Data of the card to be deleted.
   * @param {boolean} [isPro=false] - Indicates whether the user is a professional user.
   * @return {Observable<APIResponseModel>} - An Observable emitting the API response for the delete action.
   */
  public deleteCardApi(cardData: any, isPro: boolean = false): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(
      this.BASE_URL + (isPro ? '/professional/' : '/') + 'delete-card',
      cardData
    );
  }

  /**
   * Make a card default
   *
   * @param {Object} cardData - Data of the card to be set as default.
   * @param {boolean} [isPro=false] - Indicates whether the user is a professional user.
   * @return {Observable<APIResponseModel>} - An Observable emitting the API response for setting the card as default.
   */
  public makeCardDefaultApi(cardData: any, isPro: boolean = false): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(
      this.BASE_URL + (isPro ? '/professional/' : '/') + 'make-card-default',
      cardData
    );
  }
}
