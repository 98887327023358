<app-consumer-focused-navbar></app-consumer-focused-navbar>

<img *ngIf="isSmallWindow" class="great-choice-banner-mobile" src="assets/images/intro/landing-banner-intro.png" alt="image">
<div
  class="relative flex items-center justify-center contact-form-banner great-choice-banner"
  [ngClass]="{'consumer-marketing-banner__wrap new-landing-height consumer-intro-banner__wrap height-400': !isSmallWindow}">

<!-- <div class="relative flex items-center justify-center contact-form-banner consumer-marketing-banner__wrap mh-800-height new-landing-height consumer-intro-banner__wrap"> -->
  <div class="max-w-screen mx-auto z-30 custom-width mb-0">
    <div>
      <div class="grid grid-cols-12 gap-0 lg:gap-12 relative">
        <div class="hidden md:flex col-span-12 lg:col-span-6">

        </div>

        <div class="col-span-12 lg:col-span-5">
          <ng-container *ngIf="!isSmallWindow; else small_screen_header">
          <!-- <ng-container> -->
            <h1 class="text-semibold text-white text-left fs-bhead desktop-title text-regular leading-normal">
              <b>Great choice.</b><br/>
              You're on your<br/>
              way to securing<br/>
              your legacy in<br/>
              a <b>Digital Vault</b>
            </h1>
          </ng-container>
          <ng-template #small_screen_header>
          <!-- <ng-template> -->
            <h1 class="text-darkblue text-semibold fs-bhead text-lg text-regular py-4 md:py-6 lg:py-0">
              <b>Great choice.</b><br/>
              You're on your way to securing your legacy in a Digital Vault
            </h1>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN: Start Now Wrapper -->
<div class="section section-even bg-blue-light py-8">
  <div class="max-w-screen mx-auto">
    <div class="grid grid-cols-12 gap-3 md:gap-4 lg:gap-6">
      <div class="col-span-12">
        <p class="text-base sm:text-lg md:text-xl lg:text-xl leading-snug text-regular text-left text-black">
          Think of this as your <span class="font-bold text-blue italic">estate organizer</span> because the system will guide you,
step by step, through entering all your critical estate information so that nothing is
missed and everything is ready to pass on to your loved ones.
        </p>
      </div>
      <div class="col-span-12 mx-auto">
        <button (click)="onClickContinue()"
                class="bg-primary text-white rounded-sm py-2 lg:py-3 px-16 md:px-20 lg:px-24 mt-0 text-base md:text-lg lg:text-xl xl:text-2xl font-medium uppercase font-sans w-auto focus:outline-none">
                CONTINUE
        </button>
      </div>

    </div>
  </div>
</div>
<!-- END: Start Now Wrapper -->

<app-consumer-focused-footer></app-consumer-focused-footer>
