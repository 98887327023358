import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'textFilterList',
})
/**
 * Filtering text based on string
 */
export class TextFilterListPipe implements PipeTransform {
  /**
   * transform function
   *
   * @param {Array<any>} value
   * @param {Array<string>} args
   *
   * @return {Array<any>}
   */
  transform(value: Array<any>, args: Array<string>): Array<any> {
    // Refactored sort to only occur if also filtering.
    // By performing the sort here you are taking control of the sort away from the utilizing components, which caused
    // a problem in add-vehicle modal. I have changed this to only sort if performing a search, since the order won't
    // matter as much at that point.
    // ~ Luke (PC-Gonzales)

    // If filter is provided (args[0]) then perform filter and sort.
    if (args[0] && value.length > 0) {
      value = value.filter((item) => {
        return item[args[1]]?.match(new RegExp(args[0], 'i'));
      });

      // Sort the array by displayText, but keep 'Others' at the end
      try {
        value?.sort((a, b) => {
          if ((a.showFirst ? a.showFirst === a.displayText : false) && (b.showFirst ? b.showFirst !== b.displayText : false)) {
            return -1;
          } else if ((a.showFirst ? a.showFirst !== a.displayText : false) && (b.showFirst ? b.showFirst === b.displayText : false)) {
            return 1;
          } else if ((a.displayText === 'Others' || a.displayText === 'Other') && (b.displayText !== 'Others' || b.displayText !== 'Other')) {
            return 1;
          } else if ((a.displayText !== 'Others' || a.displayText !== 'Other') && (b.displayText === 'Others' || b.displayText === 'Other')) {
            return -1;
          } else {
            return a.displayText.localeCompare(b.displayText);
          }
        });
      } catch (e) {
      }
      return value;
    }

    return value;
  }
}
