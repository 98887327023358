<div class="lg:grid grid-cols-12 background-color-grey">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 my-4">
              <div class="col-span-12 sm:col-span-2">
                  <img src="assets/images/executors/executors.svg" class="w-20" alt="digital vault">
              </div>

              <div class="col-span-12 sm:col-span-10">
                  <h2 class="text-darkblue text-md sm:text-2xl break-words uppercase text-semibold">
                    Heirs and Beneficiaries
                  </h2>
                  <p class="text-sm text-grey tracking-wide text-regular">
                    This information is critical and a key to verifying their identity. Please double-check your input
                    prior to moving on.
                  </p>
              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-1/6'"></app-card-progress-bar>
              <!-- END: Progress Bar -->

              <div class="p-6">
                <div class="mb-4">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    Who is a Beneficiary?
                  </h4>
                  <p class="text-grey text-regular pt-2 text-sm tracking-wide">
                    A beneficiary is someone you name in your will or trust to receive specific assets after you pass
                    away. A beneficiary doesn't have to be related to you — they can be anyone you choose, including
                    friends, colleagues and charitable organizations.
                  </p>
                  <p class="text-grey text-regular pt-2 text-sm tracking-wide">
                    If you name beneficiaries in your will, they have a legal claim to the assets you leave them. If you
                    pass
                    away without a valid will or trust, your assets will be distributed to your heirs, people related to
                    you by blood or marriage, based on your state's intestate succession laws.
                  </p>
                </div>
                <div class="mb-4">

                  <p class="text-grey text-regular pt-2 text-sm tracking-wide">
                    <b><u>Please note</u></b> that LegacyNOW does not provide legal advice or opinions, nor does it
                    make any
                    recommendations or suggestions regarding estate planning, taxation or any other matter concerning
                    your estate or the consequences of your passing. The local law of the state or province in which you
                    reside or in which your loved one may have passed away may place restrictions on your ability to
                    appoint or exclude certain individuals as heirs or beneficiaries, other restrictions as to the
                    inheritance of your estate. Please consult a legal professional for more information pertaining to
                    the naming of heirs and beneficiaries or any other matter concerning your will or an intestate
                    distribution under applicable law. LegacyNOW accept no responsibility or liability for any of such
                    matters and our Digital Vault Services are limited to that described in your Digital Vault Agreement.
                  </p>

                </div>

                <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 mb-2">
                  <a (click)="changeRoute('about-yourself/view')" *ngIf="this.userType === 'Consumer'"
                    class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </a>
                  <a (click)="changeRoute('about-yourself/view/')" *ngIf="this.userType !== 'Consumer'"
                    class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </a>
                  <app-button-loader class="w-full sm:w-auto" *ngIf="this.userType === 'Consumer'" [buttonText]="'Next'" [valid]="true"
                    [loader]="false" (click)="changeRoute('executor-beneficiary/manage-beneficiaries')"
                    [buttonType]="'button'">
                  </app-button-loader>
                  <app-button-loader class="w-full sm:w-auto"  *ngIf="this.userType !== 'Consumer'" [buttonText]="'Next'" [valid]="true"
                    [loader]="false" [buttonType]="'button'"
                    (click)="changeRoute('executor-beneficiary/manage-beneficiaries/'+requestId)">
                  </app-button-loader>
                </div>
              </div>
              <!-- END: Leftside Card -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class="col-span-12 md:col-span-6 lg:grid justify-center">
    <about-yourself-right-content [formInputValue]="{}"
      [otherValues]="sectionSaveExitOptions "></about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->
</div>
