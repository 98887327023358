<app-common-modal id="add-subscription-modal" class="app-common-modal">
  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
         (click)="closeModel()">
    <form [formGroup]="subscriptionForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Subscription Details
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">
            This section tells us about the services you have subscribed to.
          </p>
          <p class="text-regular font-medium text-xs mt-2 error-alert"
             *ngIf="!canEdit && !isConsumer">
            You do not have permission to
            {{isNewAsset ? 'add information for this client.' : 'edit this client\'s information.'}}
          </p>
        </div>
        <div class="col-span-12 relative">
          <label for="valuable-type" class="text-label-grey text-sm text-regular">Type*</label>
          <app-select-with-search [options]="subscriptionTypes" [placeholderValue]="'Select Subscription type'"
                                  (optionChangeEmitter)="optionChangeEmitterSubscriptionType($event)" id="valuable-type"
                                  [disabled]="!canEdit || !canAccessForm"
                                  [defaultSelected]="subscriptionForm?.value?.['property_subscriptions_id']"></app-select-with-search>
        </div>
        <div class="col-span-12">
          <label for="subscription-name" class="text-label-grey text-sm text-regular">Name*</label>
          <input [attr.disabled]="!canEdit || undefined" type="text" id="subscription-name"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 placeholder="e.g. Reader's Digest" formControlName="subscription_name" name="subscription-name"
                 [ngClass]="formGet.subscription_name.errors && formGet.subscription_name.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('subscription_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12  mt-1">
          <label for="account_number-plate" class="text-grey text-sm text-regular ">Account/Member Number</label>
          <input [attr.disabled]="!canEdit || undefined" type="text" id="account_number-plate"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="account_number" formControlName="account_number" placeholder="e.g 87821MPA"
                 [ngClass]="formGet.account_number.errors && formGet.account_number.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('account_number')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>

        <div class="col-span-12  mt-1">
          <label for="subscription-count-days" class="text-grey text-sm text-regular ">Subscription Period (Days/Months/Years)</label>
          <div class="grid grid-cols-12 gap-2 gap-y-1">
            <div class="col-span-6 -mt-1">
              <input [attr.disabled]="!canEdit || undefined" type="text" id="subscription-count-days"
              class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
              placeholder="e.g. 60" formControlName="subscription_number" name="subscription-count-days"
              [ngClass]="formGet.subscription_number.errors && formGet.subscription_number.touched?'form-input-error':'form-input-focus'">
            </div>
          <div class="col-span-6 relative">
            <app-select-with-search [options]="subscriptionPeriodTypes" [placeholderValue]="'Select Subscription period'"
            (optionChangeEmitter)="optionChangeEmitterSubscriptionPeriod($event)" id="subscription_term-plate"
            [disabled]="!canEdit || !canAccessForm" [showSearchBox]="false"
            [defaultSelected]="subscriptionPeriodSelected"></app-select-with-search>
          </div>
          </div>
          <p *ngFor="let error of getErrors('subscription_number')"
          class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
        </p>
        </div>

        <div class="col-span-12  mt-1">
          <label for="subscription_cost" class="text-label-grey text-sm text-regular">Subscription Cost</label>
          <div class="relative flex items-center">
            <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
            <input [attr.disabled]="!canEdit || undefined" type="text" appCurrencyInput
              class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 price-input"
              name="subscription_cost" id="subscription_cost" formControlName="subscription_cost" placeholder="e.g 1000"
              [ngClass]="formGet?.subscription_cost.errors && formGet?.subscription_cost.touched ? 'form-input-error' : 'form-input-focus'">
          </div>
          <p *ngFor="let error of getErrors('subscription_cost')"
            class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>

        <div class="col-span-12">
          <label for="contact-details" class="text-label-grey text-sm text-regular">
            Phone Number</label>
          <app-phone-input [placeholder]="'e.g. 8665044754'" id="contact-details"
                           [phoneNumber]="subscriptionForm.value?.['contact_number']" [countryCode]="countryCode"
                           [uniqueId]="'phone-subscription'" (click)="formGet.contact_number.markAsTouched()"
                           [disabled]="!canEdit || !canAccessForm"
                           (onChange)="detectChangePhonenumber($event)"
                           [errorClassTrigger]="formGet.contact_number.errors && formGet.contact_number.touched"
                           [form]="subscriptionForm" controlKey="contact_number"
                           (focusout)="formGet.contact_number.markAsTouched()">
          </app-phone-input>
          <p *ngFor="let error of getErrors('contact_number')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="mb-2 col-span-12">
          <div class="flex flex-row items-baseline">
            <div>
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Web Access
              </h4>
              <p class="text-grey text-regular pt-1 text-sm">
                Online access information for this subscription.
              </p>
            </div>
          </div>
        </div>
        <div class="col-span-12">
          <label for="website" class="text-label-grey text-sm text-regular">Web URL</label>
          <div class="relative">
            <input type="url" [attr.disabled]="!canEdit || undefined"
                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                   placeholder="www.mybank.com" formControlName="website" id="website"
                   [ngClass]="formGet.website.errors && formGet.website.touched?'form-input-error':'form-input-focus'">
            <p *ngFor="let error of getErrors('website')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </div>


        <div class="col-span-12 md:col-span-6 md:pr-3">
          <label for="user_name" class="text-label-grey text-sm text-regular">Username</label>
          <input [attr.disabled]="!canEdit || undefined" type="text"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 placeholder="e.g. Marktylor_54-" formControlName="user_name" name="user_name" id="user_name"
                 [ngClass]="formGet?.['user_name'].errors && formGet?.['user_name'].touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('user_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 md:col-span-6">
          <label for="password" class="text-label-grey text-sm text-regular">Password</label>
          <div class="relative">
            <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
               (click)="togglePasswordView = !togglePasswordView">
              <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
              <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
            </a>
            <input [type]="togglePasswordView?'text':'password'" [attr.disabled]="!canEdit || undefined"
                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                   placeholder="e.g. JOHy56-89_" formControlName="password" name="password" id="password"
                   [ngClass]="formGet.password.errors && formGet.password.touched?'form-input-error':'form-input-focus'">
            <p *ngFor="let error of getErrors('password')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 ">
        <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewAsset" [buttonText]="'Remove Subscription'"
                           [valid]="!submitLoader && canEdit"
                           [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true"
                           (click)="deleteSubscription()">
        </app-button-loader>
        <app-button-loader class="w-full sm:w-auto" [buttonText]="!isNewAsset?'Update Subscription':'Add Subscription'"
                           [valid]=" ((isNewAsset && permissions?.['add']) || (!isNewAsset && permissions?.['edit'])) && !submitLoaderDelete && canEdit"
                           [loader]="submitLoader" [buttonType]="'submit'">
        </app-button-loader>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>
