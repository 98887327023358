import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'appFilter' })
/**
 * filter pipe
 */
export class FilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @return {any[]}
   */
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toString().toLowerCase();
    return items.filter((it) => {
      return JSON.stringify(it).toLowerCase().includes(searchText);
    });
  }
}
