import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonModelService } from 'src/app/services/common-model.service';
import { EMAIL_PATTERN } from 'src/constants/application.const';

@Component({
  selector: 'app-resend-verification-modal',
  templateUrl: './resend-verification-modal.component.html',
  styleUrls: ['./resend-verification-modal.component.css']
})
export class ResendVerificationModalComponent implements OnInit {
  @Input() modalId: string = 'resend-verification-modal';
  @Input() selectedLogin: { text: string, id: string };

  @Output() emitResendVerificationEvent = new EventEmitter<{ $resend_verify_email: string }>(null)

  public resendVerfiyEmailForm: FormGroup

  constructor(private modalService: CommonModelService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.resendVerfiyEmailForm = this.formBuilder.group({
      'email': ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
    })
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.resendVerfiyEmailForm.controls;
  }

  /**
   *
   */
  public closeResendVerificationModal() {
    this.modalService.close(this.modalId);
    this.resendVerfiyEmailForm.reset();
  }

  /**
   * Method to resend the verification email.
   * This method emits an event to notify that the verification email should be resent
   * and then resets the resend verification form.
   */
  public resendVerificationEmail() {
    this.emitResendVerificationEvent.emit({
      $resend_verify_email: this.formGet['email']?.value
    })
    this.resendVerfiyEmailForm.reset();
  }

}
