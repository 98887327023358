import { Component } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
})
/**
 * profile modal
 */
export class ProfileModalComponent   {
  /**
 * constructor
 */
  constructor(private modalService:CommonModelService) { }
  
  /**
  * close modal
  */
  public closeModel(): void {
    this.modalService.close('view-profile-modal');
  }
}
