<div class="scroll-hidden box-border body-content pb-12 mb-8" (click)="toggleDropDown(-1)">
  <!--start navbar-->
  <!--end-->
  <!--first Section start-->
  <div class="mx-8 ">
    <div class="pt-12">
      <div class="flex flex-wrap ">
        <div class="w-full md:w-3/5 px-1">
          <div>
            <h4 class="text-semibold text-blue-900 text-md md:text-lg  lg:text-xl xl:text-2xl">
              Manager Details
            </h4>
          </div>
        </div>
        <div class="w-full md:w-2/5 px-0 ">
          <div class="w-full px-2 space-y-4 md:space-y-0 md:flex items-center justify-end md:justify-end gap-4">
            <!-- <button (click)="changeRoute('managers/add-manager')" -->
            <!-- <button
              (click)="changeRoute(roleType === 'Law Firm'? 'managers/add' : roleType === 'Wealth Management'? 'project-managers/add':'funeral-directors/add')" -->
               <!--bulk import manager -->
              <div>
               <button (click)="toggleBulkImportModal()"
              class="button button--lg bg-darkblue  px-4 rounded-xs h-10 focus:outline-none text-regular text-white text-sm flex flex-row justify-center items-center">
              <img src="assets/images/dashboard/plus-white.svg" class="mr-3" alt="LegacyNow">Bulk Import Managers
            </button>
            </div>
            
               <!--add import manager -->
               <div>
              <button (click)="changeRoute('managers/add')"
              class="button button--lg bg-darkblue  px-4 rounded-xs h-10 focus:outline-none text-regular text-white text-sm flex flex-row justify-center items-center">
              <!-- <img src="assets/images/dashboard/plus-white.svg" class="mr-3" alt="LegacyNow"> Add
                            {{agentTerm}}s -->
              <img src="assets/images/dashboard/plus-white.svg" class="mr-3" alt="LegacyNow">Add Individual Manager
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--end-->
    <!--start chart-->
    <div class="pt-4">
      <div>
        <div class="flex flex-wrap -mx-2 ">
          <!-- <div class="w-full md:w-2/5 px-2 mb-3">
            <div class="border-card bg-white rounded-md p-6 ">
              <h4 class="text-semibold text-grey text-md md:text-lg  lg:text-xl xl:text-2xl">Manager
                Details</h4>
              <p class="text-grey text-regular text-sm md:text-md lg:text-md pb-2">Active and Inactive
                Managers
              </p>
              <app-shimmer-loading [loading]="dataLoading" [width]="'100%'" [height]="'4rem'"></app-shimmer-loading>
              <canvas baseChart *ngIf="!dataLoading" [data]="doughnutChartData" [labels]="doughnutChartLabels"
                [chartType]="'doughnut'" [legend]="true" [options]="doughnutOptions" [colors]="doughnutColor">
              </canvas>
            </div>
          </div> -->
          <div class="w-full px-2 mb-3">
            <div class="border-card bg-white rounded-md p-6 ">
              <h4 class="text-semibold text-grey text-md md:text-lg pb-2 lg:text-xl xl:text-2xl">
                Managers Created</h4>
              <app-shimmer-loading [loading]="dataLoading" [width]="'100%'" [height]="'4rem'"></app-shimmer-loading>
              <canvas *ngIf="showData && !dataLoading" baseChart height="80" [datasets]="barChartData" [labels]="barChartLabels"
                [options]="barChartOptions" [legend]="true" [chartType]="'bar'">
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end-->
    <div class="my-6">
      <div class="border-card bg-white rounded-md p-6">
        <!--start second section -->
        <div class="flex flex-wrap">
          <div class="w-full lg:w-1/2 ">
            <div class="relative">
              <div class="sm:w-full md:w-2/4  relative h-10 text-grey ">
                <div class=" absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"> <img
                    src="assets/images/about-yourself/search.svg" class="w-4  h-4" alt="digital vault"> </div>
                <input type="text" class=" input py-3 px-4 mt-1 w-full text-regular text-grey
                            rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                  placeholder="Search by Name" (input)="onSearchAgent($event.target['value'])">
              </div>
            </div>
          </div>
          <div class="w-full lg:w-1/2">
            <div class="flex flex-wrap item-center justify-between">
              <!-- <div>
                <div>
                  <div class="flex item-center justify-between mt-4">
                    <div
                      class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                      Status: </div>
                    <select
                      class="status-min-width text-center form-select cursor-pointer pt-1 bg-transparent ml-4 mt-0 w-2/4 rounded-xs h-8 text-regular text-grey p-2 border-none block focus:outline-none insurance_term">
                      <option value="">All</option>
                    </select>
                  </div>
                </div>
              </div> -->
              <div>
                <div class=" md:justify-end">
                  <div class="flex justify-center item-center text-grey mt-4">
                    <div
                      class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                      Showing: </div>
                    <select [(ngModel)]="proManagerTableSettings.recordsPerPage" (change)="getManagersList()"
                      class="text-center form-select cursor-pointer bg-transparent pt-1 ml-4 w-20 mt-0 rounded-xs h-8 text-regular text-grey p-2 border-none block focus:outline-none insurance_term">
                      <option *ngFor="let option of selectOptions;let i=index" [value]="option['id']">
                        {{option["value"]}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class=" md:justify-end css-shortby">
                <div class="md:justify-end">
                  <div class="flex justify-center item-center h-10 text-grey mt-4">
                    <div
                      class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                      Sort by: </div>
                    <select [(ngModel)]="proManagerTableSettings.sortPreference" (change)="getManagersList()"
                      class="text-center form-select cursor-pointer bg-transparent ml-2 py-0 rounded-xs text-regular text-grey border-none block focus:outline-none insurance_term ng-pristine ng-valid ng-touched">
                      <option *ngFor="let order of orderOptions;let i=index" [value]="order['id']">{{order["value"]}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end-->
        <div class="w-full" *ngIf="spinner">
          <!-- <div class="w-12 h-12 border-b-2 border-darkblue rounded-full animate-spin mt-3 "></div> -->
          <div class="mt-3 w-full">
            <app-shimmer-loading [loading]="true" [width]="'100%'" [height]="'3rem'"></app-shimmer-loading>
          </div>
          <div class="flex flex-row mt-3 mb-6 items-center">
            <div>
              <app-shimmer-loading [loading]="true" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
            </div>
            <div class="ml-4 mt-0 w-full">
              <ng-container *ngFor="let _ of [].constructor(3)">
                <app-shimmer-loading [loading]="true" [width]="'100%'"></app-shimmer-loading>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="mt-8" *ngIf="!spinner">
          <div class="overflow-x-auto">
            <table class="table w-full text-left mb-6" aria-describedby="advisorTable">
              <thead class="bg-gray-400 text-blue text-md text-regular ">
                <tr>
                  <th class="p-4" scope="row">Name</th>
                  <th class="p-4" scope="row">Title</th>
                  <th class="p-4" scope="row">No&nbsp;of&nbsp;Clients</th>
                  <th class="p-4" scope="row">Added&nbsp;On</th>
                  <th class="p-4" scope="row">Last&nbsp;Login</th>
                  <th class="p-4" scope="row">Action</th>
                </tr>
              </thead>

              <ng-container>
                <tbody *ngFor="let agent of agentList;let i = index;">
                  <tr class="border-b-2 border-fuchsia-600 p-4 relative" *ngIf="agentList?.length > 0">
                    <td>
                      <div class="flex items-center ">
                        <div class="relative w-16 h-16">
                          <app-image-component [image]="agentImageSrc+agent['profile_picture']"
                            [class]="'rounded-full w-14 h-14 lg:w-16 lg:h-16 object-cover '"
                            [defaultImage]="'assets/icons/user-bg.svg'">
                          </app-image-component>
                          <div
                            class="active w-3 h-3 rounded-full bg-green-500 border-2 border-white absolute bottom-2 right-1 ">
                          </div>
                        </div>
                        <div class="pl-2 pr-4 lg:p-5 ">
                          <a
                            class="text-blue-600 text-semibold underline cursor-pointer text-md lg:text-lg text-regular">{{agent["full_name"]}}</a>
                          <p class="text-grey text-base lg:text-md text-regular">
                            {{agent["email"]}}</p>
                        </div>
                      </div>
                    </td>
                    <td class="p-4">
                      <div class="flex items-center ">
                        <div>
                          <p class="text-grey text-regular tracking-wide">
                            {{agent["manager_term"] ?agent["manager_term"]:'NA' }}</p>
                        </div>
                      </div>
                    </td>
                    <td class="p-4">
                      <div class="flex items-center ">
                        <div><img src="assets/images/dashboard/table-user.svg" alt="digital vault"></div>
                        <div class="pl-3">
                          <p class="text-grey text-regular tracking-wide">
                            {{agent["total_clients"]}}</p>
                        </div>
                      </div>
                    </td>
                    <td class="p-4 whitespace-normal">
                      <p class="text-grey text-regular text-md">{{agent["added_on"]}}</p>
                    </td>
                    <td class="p-4">
                      <p class="text-grey text-regular tracking-wide whitespace-normal">
                        {{agent["last_login"]}}</p>
                    </td>
                    <td class="p-4 cursor-pointer" (click)="toggleDropDown(i)"><img
                        src="assets/images/dashboard/icon-more.png" alt="db"></td>
                    <div class="py-1 absolute right-0 top-14 w-40 z-50 rounded-md shadow-lg bg-white focus:outline-none"
                      role="menu" aria-orientation="vertical" aria-labelledby="user-menu"
                      *ngIf="profileMenuToggle && (this.index === i )">
                      <a (click)="viewManager(agent)"
                        class="cursor-pointer block px-4 py-1 text-sm text-grey text-regular hover:bg-gray-100"
                        role="menuitem">View</a>
                      <a (click)="changeRoute('managers/edit/'+agent['id']); editAgent(agent['id'])"
                        class="cursor-pointer block px-4 py-2 text-sm text-grey text-regula hover:bg-gray-100"
                        role="menuitem">Edit</a>
                      <a (click)="openConfirmationModal(agent)"
                        class="cursor-pointer block px-4 py-1 text-sm text-grey text-regular hover:bg-gray-100"
                        role="menuitem">Delete</a>
                    </div>
                  </tr>
                </tbody>
              </ng-container>
            </table>
            <div class=" flex justify-center mt-5 " *ngIf="agentList?.length === 0">
              <h4 class="text-grey text-base lg:text-md text-regular">
                No data found
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-8">
        <nav class="flex flex-row flex-nowrap justify-between md:justify-center items-center" aria-label="Pagination">
          <a *ngIf="currentPage > 1" (click)="getManagersList( currentPage > 1 ? currentPage-1 : 0 )"
            class="flex w-7 h-7 mr-1 justify-center items-center text-black cursor-pointer" title="Previous Page">
            <span class="sr-only">Previous Page</span>
            <img src="assets/images/dashboard/left-arrow-enable.png" alt="digital vault" />
          </a>
          <a *ngIf="currentPage === 1" class="flex w-7 h-7 mr-1 justify-center items-center text-black "
            title="Previous Page">
            <span class="sr-only">Previous Page</span>
            <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
            <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
          </a>
          <!-- <ng-container *ngFor="let page of paginationLinks;let i = index;">
            <a *ngIf=" i <= lastPage && i > 0 " (click)="getAgentsList(i)"
              [ngClass]="{ 'bg-darkblue' : currentPage === i , 'text-white' : currentPage === i}"
              class=" md:flex w-7 h-7 mx-1 justify-center items-center  text-black text-center cursor-pointer "
              title="Page {{i}}">
              {{i}}
            </a>
          </ng-container> -->
          <div class="pagination">
            <a (click)="getManagersList(1)" [ngClass]="{ 'bg-darkblue' : currentPage === 1, 'text-white' : currentPage === 1 }" class="pagination-link" title="Page 1">1</a>
            <span *ngIf="currentPage > 5">...</span>
            <ng-container *ngFor="let i of [-2, -1, 0, 1, 2]">
              <a *ngIf="currentPage + i > 1 && currentPage + i < lastPage" (click)="getManagersList(currentPage + i)" [ngClass]="{ 'bg-darkblue' : currentPage === currentPage + i, 'text-white' : currentPage === currentPage + i }" class="pagination-link" title="Page {{currentPage + i}}">
                {{currentPage + i}}
              </a>
            </ng-container>
            <span *ngIf="currentPage < lastPage - 4">...</span>
            <a *ngIf="lastPage > 1" (click)="getManagersList(lastPage)" [ngClass]="{ 'bg-darkblue' : currentPage === lastPage, 'text-white' : currentPage === lastPage }" class="pagination-link" title="Page {{lastPage}}">
              {{lastPage}}
            </a>
          </div>
          <a (click)="getManagersList( currentPage + 1 )" *ngIf="(currentPage) < lastPage"
            class="flex w-7 h-7 ml-1 justify-center items-center text-black cursor-pointer" title="Next Page">
            <span class="sr-only">Next Page</span>
            <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
            <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
          </a>
          <a *ngIf="(currentPage) ===  lastPage" class="flex w-7 h-7 ml-1 justify-center items-center text-black "
            title="Next Page">
            <span class="sr-only">Next Page</span>
            <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
            <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
          </a>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- View Detailed Info Modal -->
<app-view-detailed-info-modal [viewModalData]="selectedUser" [viewModalHeading]="'View' + ' ' + 'Manager'">
</app-view-detailed-info-modal>
<!-- Delete confirmation Modal -->
<app-delete-confirmation-modal [submitLoaderDelete]="submitLoaderDelete" [content]="'Manager'"
  (deleteOption)="deleteAgent($event)">
</app-delete-confirmation-modal>
<!-- Bulk Client Import Modal -->
<app-client-bulk-import [importTerm]="'manager'" [userRole]="professionalType"></app-client-bulk-import>
