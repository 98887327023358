import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { requirePasswordToBeCheckedValidator } from 'src/app/helper/customValidatorPassword.helper';
import { ICountryCode } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProfileService } from 'src/app/services/profile.service';
import { UserService } from 'src/app/services/user.service';
import { EMAIL_PATTERN, LOCAL_STORAGE_UPDATE_STATUS, PHONE_PATTERN, USER_TYPES } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../helper/validation.helper';
import { CommonModelService } from '../../services/common-model.service';


@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css'],
})
/**
 * Account Setting Component
 */
export class AccountSettingsComponent implements OnInit {
  private menuActive: number = 0;
  public menuOptions: { title: string, id: string }[] = [
    { title: 'Your Account Details', id: 'account_details' },
    { title: 'Change Your Password', id: 'password' },
    //{ title: 'Contact Preferences', id:'contact_pref' },
  ];
  public accountDetailForm: FormGroup;
  public profilePictureForm: FormGroup;
  public phoneNumberForm: FormGroup;
  public accountDetailsSubmit: boolean;
  public phoneFormSubmit: boolean;
  public changePasswordForm: FormGroup;
  public paymentForm: FormGroup;
  public changePasswordSubmit: boolean;
  public faEye: any;
  public faEyeSlash: any;
  public togglePasswordView: boolean;
  public togglePasswordToPhoneNo: boolean;
  public toggleOldPasswordView: boolean;
  public toggleNewPasswordView: boolean;
  public toggleNewPasswordViewConfirm: boolean;
  public imageSrc: string;
  public uploadForm: FormGroup;
  public preDefinedLoader: boolean;
  public accountDetails: any;
  public changeEmail: boolean;
  public changePhone: boolean;
  @ViewChild('propicfield') propicfield: ElementRef;
  public userType: string;
  public requestId: string;
  public countryCode: ICountryCode;
  public oldCountryCode: any;
  public changePasswordMatch: boolean = true;
  public resetPassword: boolean = false;
  public showVerificationCodes: boolean;
  public newEmail: string;
  public userUniqueID: string;

  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private commonHelper: CommonHelper,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private activeRouter: ActivatedRoute,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private validation: ValidationHelper,
  ) {
  }

  /**
   * Account Detail Form called initially on page load
   *
   */
  ngOnInit(): void {
    if (!environment.production) {
      this.menuOptions.push({ title: 'Test Menu (hidden on production)', id: 'test_menu' });
    }

    this.requestId = this.activeRouter.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    this.oldCountryCode = environment.DEFAULT_COUNTRY_CODE;
    this.faEye = faEye;
    this.changePhone = false;
    this.phoneFormSubmit = false;
    this.faEyeSlash = faEyeSlash;
    this.togglePasswordView = false;
    this.toggleOldPasswordView = false;
    this.toggleNewPasswordView = false;
    this.toggleNewPasswordViewConfirm = false;
    this.togglePasswordToPhoneNo = false;
    this.initAccountDetailForm();
    this.profilePictureForm = this.formBuilder.group({
      profile_photo: [''],
    });
    this.changePasswordForm = this.formBuilder.group({
      password: ['', { validators: [Validators.required] }],
      new_password: ['', [Validators.required, Validators.minLength(8), requirePasswordToBeCheckedValidator()]],
      new_password_confirm: ['', { validators: [Validators.required] }],
    });
    this.uploadForm = this.formBuilder.group({
      avatar: [null],
      file: [''],
    });
    this.phoneNumberForm = this.formBuilder.group({
      old_phone: ['', { validators: [Validators.required, Validators.pattern(PHONE_PATTERN)] }],
      phone: ['', { validators: [Validators.required, Validators.pattern(PHONE_PATTERN)] }],
      password: ['', { validators: [Validators.required] }],
    });

    // reading params for menu redirection
    const menu = +this.activeRouter.snapshot.queryParams['menu']; //The parameter is obtained from the query parameters (queryParams) of the route.
    this.menuActive = Math.abs(menu) < this.menuOptions.length ? Math.abs(menu) : 0;

    this.getCurrentUserDetails();
  }

  /**
   * Initialize {@link accountDetailForm} & related fields for email change flow.
   * @private
   */
  private initAccountDetailForm() {
    this.showVerificationCodes = false;
    this.changeEmail = false;
    this.changePhone = false;
    this.accountDetailForm = this.formBuilder.group({
      'old_email': ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(EMAIL_PATTERN)] }],
      'email': ['', {
        updateOn: 'blur',
        validators: [Validators.required, Validators.pattern(EMAIL_PATTERN), this.validation.fn.checkSameEmail()],
      }],
      'password': ['', { validators: [Validators.required] }],
    });
  }

  /**
   * Get personal details for filling profile details
   */
  public getCurrentUserDetails(): void {
    this.preDefinedLoader = true;
    this.userService.getCurrentUserDetails().subscribe({
      next: (response: APIResponseModel) => {
        this.preDefinedLoader = false;
        if (response.status) {
          this.accountDetails = { ...response.data };
          this.imageSrc = `${environment.BASE_URL_RAW}uploads/profile_photo/${this.accountDetails.profile_photo}`;
          let tempPhoneNumber;
          this.accountDetailForm.reset();
          if (response?.data?.phone) {
            tempPhoneNumber = response?.data?.phone;
            this.oldCountryCode = { code: response?.data?.user_request?.flag_code, dialCode: response?.data?.country_code };
          }
          this.userUniqueID = this.accountDetails['user_request']?.['request_unique_id'];
          // Patch values
          this.phoneNumberForm.patchValue({ old_phone: tempPhoneNumber });
          this.accountDetailForm.patchValue({ old_email: response.data.email });

          // Reset Password
          this.resetPassword = response.data.reset_password == 1;
        }
      }, error: e => {
        console.error(e);
        this.preDefinedLoader = false;
      },
    });
  }

  /**
   * Detect change on phone number component
   *
   * @param event
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }

  /**
   * Account Detail form submit Functionality
   */
  public accountDetailFormSubmit(): void {
    this.accountDetailsSubmit = true;
    const sendingData = this.accountDetailForm.value;

    const formData = new FormData();
    // appending every value to form object
    Object.keys(sendingData).forEach((key) => {
      if (sendingData[key] == undefined) return;
      formData.append(key, sendingData[key]);
    });

    // Change email - only new email gets otp while trigger this api
    this.profileService.changeEmail(formData).subscribe({
      next: (response: APIResponseModel) => {
        this.accountDetailsSubmit = false;
        if (response.status) {
          this.newEmail = this.accountDetailForm.controls['email'].value;
          this.changeEmail = false;
          this.toastrService.success(response.message.replace(/otp/ig, 'OTP'));
          // open email verification modal
          this.modalService.open('otp-verification-modal');
          const old_email = this.accountDetailForm.get('old_email').value;
          this.accountDetailForm.reset();
          this.accountDetailForm.patchValue({ old_email: old_email });
        }
      }, error: (exception: any) => {
        this.accountDetailsSubmit = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      },
    });
  }

  /**
   * Account Detail form submit Functionality
   */
  public updatePhoneFormSubmit(): void {
    this.phoneFormSubmit = true;
    const payLoad = {
      ...this.phoneNumberForm.value,
      country_code: this.countryCode.dialCode,
      flag_code: this.countryCode.code,
    };

    const formData = new FormData();
    // appending every value to form object
    Object.keys(payLoad).forEach((key) => {
      if (payLoad[key] == undefined) return;
      formData.append(key, payLoad[key]);
    });

    /**
     * Update email, profile pic
     */
    this.profileService.updatePhoneNumber(formData).subscribe({
      next: (response: APIResponseModel) => {
        this.phoneFormSubmit = false;
        if (response.status) {
          this.changePhone = false;
          this.toastrService.success(response.message);
          this.countryCode = environment.DEFAULT_COUNTRY_CODE;
          this.getCurrentUserDetails();
          this.phoneNumberForm.reset();
        }
      }, error: (exception: any) => {
        this.phoneFormSubmit = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      },
    });
  }

  /**
   * Account details form object getter for validation and showing errors in html
   */
  get accountDetailFormGet() {
    return this.accountDetailForm.controls;
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get phoneFormGet() {
    return this.phoneNumberForm.controls;
  }

  /**
   * Change Pasword form submit Functionality
   */
  public changePasswordFormSubmit(): void {
    this.changePasswordMatch = this.changePasswordFormGet?.new_password_confirm?.value == this.changePasswordFormGet?.new_password?.value;
    if (this.changePasswordMatch) {
      this.changePasswordSubmit = true;
      this.profileService.changePassword(this.changePasswordForm.value).subscribe({
        next: (response: APIResponseModel) => {
          this.changePasswordSubmit = false;
          if (response.status) {
            this.changePasswordForm.reset();
            this.toastrService.success(response.message);
            this.resetPassword = response.data.reset_password;
          }
        }, error: (exception) => {
          this.changePasswordSubmit = false;
          this.commonHelper.httpResponseHandler(exception?.error);
        },
      });
    }
  }

  /**
   * Change Password form object getter for validation and showing errors in html
   */
  get changePasswordFormGet() {
    return this.changePasswordForm.controls;
  }

  /**
   * Menu on click scroll to related card
   *
   * @param {HTMLElement} el
   * @param {string} key
   *
   *  !Do not remove this code for now
   */
  // public scroll(el: HTMLElement, key: string) {
  //   this.menuActive = key;
  //   el.scrollIntoView();
  //   el.scrollIntoView({ behavior: 'smooth' });
  // }

  /**
   *
   * Password confirmation
   * @param {string} oldpassword
   * @param {string} newpassowrd
   * @return {FormGroup}
   *
   * !Do not remove this code for now
   */
  // public ConfirmedValidator(oldpassword: string, newpassowrd: string):Function {
  //   return (formGroup: FormGroup) => {
  //     const currentPassword = formGroup.controls[oldpassword];
  //     const confirmPassword = formGroup.controls[newpassowrd];
  //     if (currentPassword.errors && !confirmPassword.errors.confirmedValidator) {
  //       return;
  //     }
  //     if (currentPassword.value !== confirmPassword.value) {
  //       confirmPassword.setErrors({ confirmedValidator: true });
  //     } else {
  //       confirmPassword.setErrors(null);
  //     }
  //   };
  // }

  /**
   * On change image upload with preview
   * @param {string} event
   */
  public readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageSrc = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      // updating on form
      this.profilePictureForm.patchValue({
        profile_photo: event.target.files[0],
      });
    }
    const sendingData = this.profilePictureForm.value;

    const formData = new FormData();
    // appending every value to form object
    Object.keys(sendingData).forEach((key) => {
      if (sendingData[key] == undefined) return;
      formData.append(key, sendingData[key]);
    });
    this.profileService
      .updateProfilePicture(formData)
      .subscribe((response) => {
        this.toastrService.success(response.message);
        this.localStorageService.updateUserData(USER_TYPES.user, {
          key: 'user',
          updateValue: response.data.profile_photo,
          updateKey: 'profile_photo',
          type: LOCAL_STORAGE_UPDATE_STATUS.O,
        });
        this.commonService.getCurrentUserProfilePicture(response.data.profile_photo);
        this.getCurrentUserDetails();
      });
  }

  /**
   * Menu change
   * @param {number} menu
   */
  public menuChange(menu: number): void {
    this.changeEmail = false;
    this.changePhone = false;
    this.imageSrc = `${environment.BASE_URL_RAW}uploads${this.accountDetails.profile_photo}`;
    this.accountDetailForm.patchValue({
      email: this.accountDetails.email,
      password: this.accountDetails.password,
    });
    this.changePasswordForm.reset();
    this.menuActive = menu;
    this.getCurrentUserDetails();
  }

  /**
   * Get the Active {@link menuOptions menu option}.
   */
  public get activeMenu(): { title: string, id: string } {
    return this.menuOptions[this.menuActive];
  }
}
