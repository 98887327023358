import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ChatService } from 'src/app/professional-dashboard/services/chat.service';
import { ProSocketService } from 'src/app/professional-dashboard/services/pro-socket.service';
import { CommonModelService } from 'src/app/services/common-model.service';

@Component({
  selector: 'app-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.css'],
})
/**
 * chat modal
 */
export class ChatModalComponent implements OnInit {
  @Input() clientList: Array<any>;
  @Input() professionalId: string;
  public searchKeyword: string;
  @Input() spinner: boolean;
  /**
 * constructor
 */
  constructor(private modalService: CommonModelService,
    private chatService: ChatService,
    private proSocketservice: ProSocketService,
    private toastr: ToastrService) { }
  /**
 * loaded initially
 */
  ngOnInit(): void {
    this.searchKeyword = '';
    // this.proSocketservice.setUserOnline({
    //   'from_id': this.professionalId
    // });
  }


  /**
   * close modal
   */
  public closeModel(): void {
    this.searchKeyword = '';
    this.modalService.close('chat-modal');
  }
  /**
   * start new chart
   * @param {string}clientId
   */
  public startNewChat(clientId: string) {
    const payLoad = {
      from_user_id: this.professionalId,
      to_user_id: clientId,
    };
    this.chatService.startNewChat(payLoad).subscribe((response) => {
      this.toastr.success(response.message);
    });
    this.closeModel();
    this.chatService.emitChange(true);
  }
  /**
   * search client
   */
  public searchClient() {
    this.spinner = true;
    this.chatService.getConsumersList(this.searchKeyword).subscribe((response) => {
      this.clientList = response.data;
      this.spinner = false;
    });
  }
}
