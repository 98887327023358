import { Component, OnInit } from '@angular/core';
/**
 * Component
 */
@Component({
  selector: 'app-professional-partners',
  templateUrl: './professional-partners.component.html',
  styleUrls: ['./professional-partners.component.css']
})

/**
 * Professional partners Component
 */
export class ProfessionalPartnersComponent {
}
