import { Component } from '@angular/core';
import { Consumer4060RootComponent } from '../consumer-40-60-root.component';


@Component({
  selector: 'app-consumer-40-60-get-organized',
  templateUrl: './consumer-40-60-get-organized.component.html',
})
export class GetOrganized4060Component extends Consumer4060RootComponent {
}
