<!-- BEGIN: Executory Tab Content-->
<div id="executoryBeneficiary">
  <div class="grid grid-cols-12 gap-6 pt-8">
    <!-- Executor details -->
    <ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="peopleLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-6">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Executor
            </h4>
          </div>
          <div class="col-span-6">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
              <img (click)="changeRoute('/executor-beneficiary/manage-executors')"
                src="assets/images/about-yourself/edit.svg" alt="digital vault"
                class="w-4 ml-4 float-right cursor-pointer"
                *ngIf="!peopleLoader && (!requestIdParam && this.userType === 'Consumer') && !accessVault">
            </p>
          </div>
        </div>
        <div class="bg-transparent border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">

            <div class="col-span-12">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6 ">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6" *ngIf="showSSN">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6 ">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Related
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Address
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Country
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">State
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">City
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Zip Code
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>

          </div>
        </div>
      </div>
      <ng-container *ngIf="!peopleLoader && executorArray.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-6">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                {{executorArray?.length >1 ? 'Executors' : 'Executor'}}
              </h4>
            </div>
            <div class="col-span-6">
              <p *ngIf="executorArray?.length"
                class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                {{executorArray?.length}} {{executorArray?.length > 1 ? 'Executors' : 'Executor'}}
                <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
                <img (click)="changeRoute('/executor-beneficiary/manage-executors')"
                  src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
                  alt="digital vault"
                  *ngIf="(!requestIdParam && this.userType==='Consumer' && this.executorArray.length != 0) && !accessVault">
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4">
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4" #executorList
              *ngFor="let data of executorArray; let i=index">
              <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full">
              </div>
              <div class="col-span-12">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{data?.['full_name']}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6 ">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['dob'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['dob'] | date:domainViewDateFormat) || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="showSSN">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['ssn'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['ssn'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6 ">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['phone'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['phone'] ? ['+',data?.['country_code'],' ', data['phone']].join('') : 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['email'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['email'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Related
                </h4>
                <ng-container [ngSwitch]="data?.['relation']">
                  <p class="text-darkgrey text-base text-regular tracking-wide" *ngSwitchCase="1">Spouse/Partner</p>
                  <p class="text-darkgrey text-base text-regular tracking-wide" *ngSwitchCase="2">Your Child</p>
                  <p class="text-darkgrey text-base text-regular tracking-wide" *ngSwitchDefault>
                    {{data?.['people_relation']?.name}}</p>
                </ng-container>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation'] !=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Address
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data['address'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data['address'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation'] !=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Apartment/Suite Number
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['apt_number'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['apt_number'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation']!=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Country
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['country']?.['name'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['country']?.['name'] || 'Not Available') | titleCaseExceptPipe}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation']!=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">State
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['state']?.['name'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['state']?.['name'] || 'Not Available') | titleCaseExceptPipe}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation']!=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">City
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['city']?.['name'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['city']?.['name'] || 'Not Available') | titleCaseExceptPipe}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation']!=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Zip Code
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['zipcode'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['zipcode'] || 'Not Available'}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!peopleLoader && executorArray.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-6">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              {{ executorArray?.length > 1 ? "Executors" : "Executor" }}
            </h4>
          </div>
          <div class="col-span-6">

            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
              <img (click)="changeRoute('executor-beneficiary/manage-executors')"
                src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
                alt="digital vault"
                *ngIf="(!requestIdParam && this.userType === 'Consumer' && this.executorArray.length != 0) && !accessVault">
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
    </ng-container>
    <!-- Beneficiary Details -->
    <ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pl-6" *ngIf="peopleLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-6">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Heir and Beneficiary
            </h4>
          </div>
          <div class="col-span-6">
            <p *ngIf="beneficiaryList?.length"
              class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              {{beneficiaryList?.length}} {{beneficiaryList?.length > 1 ?
              'Heirs and Beneficiaries' : 'Heir and Beneficiary'}}
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </p>
          </div>
        </div>
        <div class="bg-transparent border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
            <div class="col-span-12">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Beneficiary Type
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>

            <div class="col-span-12">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6 ">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6" *ngIf="showSSN">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6 ">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Related
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Address
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Country
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">State
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">City
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Zip Code
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>

          </div>
        </div>
      </div>
      <ng-container *ngIf="!peopleLoader && beneficiaryArray.length ">
        <div class="col-span-12  md:col-span-6 my-4 lg:pl-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-6">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                {{ beneficiaryArray?.length > 1 ? "Heirs and Beneficiaries" : "Heir and Beneficiary" }}
              </h4>
            </div>
            <div class="col-span-6">
              <p *ngIf="beneficiaryArray?.length"
                class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                {{beneficiaryArray?.length}} {{beneficiaryArray?.length > 1 ? 'Beneficiaries' : 'Beneficiary'}}
                <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
                <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
                  (click)="changeRoute('/executor-beneficiary/manage-beneficiaries')" alt="digital vault"
                  *ngIf="(!requestIdParam && this.userType==='Consumer' && this.beneficiaryArray.length != 0) && !accessVault">
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4">
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4" #beneficiaryList
              *ngFor="let data of beneficiaryArray ; let i=index">
                            <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full">
              </div>
              <div class="col-span-12">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Beneficiary Type
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['beneficiary_type']?.['name'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['beneficiary_type']?.['name'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  {{data?.['beneficiary_trustee_detail']?.['trust_name'] ? data?.['beneficiary_type']?.['is_charity'] ?
                  'Charity Name':'Trust Name' : 'Name'}}
                </h4>
                <p class="break-words whitespace-break-spaces	 text-darkgrey text-base text-regular tracking-wide">
                  {{data?.['beneficiary_trustee_detail']?.['trust_name']  || data?.['full_name'] }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6 " *ngIf="!data?.['beneficiary_trustee_detail']?.['trust_name']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['dob'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['dob'] | date:domainViewDateFormat) || 'Not Available'}}
                </p>
              </div>

              <div class="col-span-12 md:col-span-6 " *ngIf="data?.['beneficiary_trustee_detail']?.['trust_name']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  {{data?.['beneficiary_type']?.['is_charity']?'Contact Person':'Trustee'}}
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['beneficiary_trustee_detail']['trustee'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['beneficiary_trustee_detail']["trustee"] || 'Not Available'}}
                </p>
              </div>

              <div class="col-span-12 md:col-span-6 " *ngIf="data?.['beneficiary_trustee_detail']?.['trust_name']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  {{data?.['beneficiary_trustee_detail']?.['trust_type']?.['is_revocable']?'SSN':'EIN'}}
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['beneficiary_trustee_detail']['tax_id'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['beneficiary_trustee_detail']['tax_id'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6 "
                *ngIf="data?.['beneficiary_trustee_detail']?.['trust_name'] && !data?.['beneficiary_type']?.['is_charity']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Trust Type
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['beneficiary_trustee_detail']['trust_type']['name'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['beneficiary_trustee_detail']['trust_type']['name'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6 "
                *ngIf="data?.['beneficiary_trustee_detail']?.['trust_name'] && !data?.['beneficiary_type']?.['is_charity']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Successor Trustee
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['beneficiary_trustee_detail']['successor_trustee'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['beneficiary_trustee_detail']['successor_trustee'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6 "
                *ngIf="data?.['beneficiary_trustee_detail']?.['trust_name']&& !data?.['beneficiary_type']?.['is_charity']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Trustee Dated Date
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['beneficiary_trustee_detail']['dated_date'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['beneficiary_trustee_detail']['dated_date'] | date:domainViewDateFormat) || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6"
                *ngIf="showSSN && !data?.['beneficiary_trustee_detail']?.['trust_name'] && !data?.['beneficiary_type']?.['is_charity']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['ssn'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['ssn'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6 ">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">

                  {{data?.['beneficiary_type']?.name == 'Trust'?'Trustee Phone Number':'Phone Number'}}
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['phone'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['phone'] ? ['+',data?.['country_code'],' ', data['phone']].join('') : 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="!data?.['beneficiary_type']?.['is_charity']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                {{data?.['beneficiary_type']?.name == 'Trust'?'Trustee Email Address':'Email Address'}}
              </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['email'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['email'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="!data?.['beneficiary_type']?.['is_charity']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Related
                </h4>
                <ng-container [ngSwitch]="data?.['relation']">
                  <p class="text-darkgrey text-base text-regular tracking-wide" *ngSwitchCase="1">Spouse/Partner</p>
                  <p class="text-darkgrey text-base text-regular tracking-wide" *ngSwitchCase="2">Your Child</p>
                  <ng-container *ngSwitchDefault>
                    <p class="text-darkgrey text-base text-regular tracking-wide"
                      *ngIf="!data?.['beneficiary_trustee_detail_id']">
                      {{data?.['people_relation']?.name}}
                    </p>
                    <p class="text-darkgrey text-base text-regular tracking-wide"
                      *ngIf="data?.['beneficiary_trustee_detail_id']">
                      Trust
                    </p>
                  </ng-container>
                </ng-container>
              </div>
              <div class="col-span-12 md:col-span-6"
                *ngIf="data?.['relation'] !=1 && !data?.['beneficiary_type']?.['is_charity']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Address
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data['address'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data['address'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6"
                *ngIf="data?.['relation'] !=1 && !data?.['beneficiary_type']?.['is_charity']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Apartment/Suite Number
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['apt_number'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['apt_number'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6"
                *ngIf="data?.['relation']!=1 && !data?.['beneficiary_type']?.['is_charity']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Country
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['country']?.['name'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['country']?.['name'] || 'Not Available') | titleCaseExceptPipe}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6"
                *ngIf="data?.['relation']!=1 && !data?.['beneficiary_type']?.['is_charity']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">State
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['state']?.['name'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['state']?.['name'] || 'Not Available') | titleCaseExceptPipe}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6"
                *ngIf="data?.['relation']!=1 && !data?.['beneficiary_type']?.['is_charity']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">City
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['city']?.['name'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['city']?.['name'] || 'Not Available') | titleCaseExceptPipe}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6"
                *ngIf="data?.['relation']!=1 && !data?.['beneficiary_type']?.['is_charity']">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Zip Code
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['zipcode'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['zipcode'] || 'Not Available'}}
                </p>
              </div>
            </div>
          </div>
        </div>

      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!peopleLoader && beneficiaryArray.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-6">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Beneficiary
            </h4>
          </div>
          <div class="col-span-6">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <img (click)="changeRoute('executor-beneficiary/manage-beneficiaries')"
                src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
                alt="digital vault"
                *ngIf="(!requestIdParam && this.userType === 'Consumer' && this.beneficiaryArray.length != 0) && !accessVault">
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>

    </ng-container>

    <!-- Advisor details -->
    <ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="peopleLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-6">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              {{agentTerm}}
            </h4>
          </div>
          <div class="col-span-6">
            <p *ngIf="advisorList?.length"
              class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              {{advisorList?.length}} {{agentTerm + advisorList?.length > 1 ? 's' : ''}}

              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
              <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
                (click)="changeRoute('advisors/manage-advisors')" alt="digital vault"
                *ngIf="(!requestIdParam && this.userType==='Consumer' && this.advisorArray.length != 0) && !accessVault">
            </p>
          </div>
        </div>
        <div class="bg-transparent border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
            <div class="col-span-12">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">{{agentTerm}} Type
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>

            <div class="col-span-12">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>

            <div class="col-span-12 md:col-span-6 ">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Address
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Country
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">State
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">City
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Zip Code
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>


          </div>
        </div>
      </div>
      <ng-container *ngIf="!peopleLoader && advisorArray.length ">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-6">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                {{agentTerm + (advisorArray?.length > 1 ? 's' : '')}}
              </h4>
            </div>
            <div class="col-span-6">
              <p *ngIf="advisorArray?.length"
                class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                {{advisorArray?.length}} {{agentTerm + (advisorArray?.length > 1 ? 's' : '')}}
                <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
                <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
                  (click)="changeRoute('advisors/manage-advisors')" alt="digital vault"
                  *ngIf="(!requestIdParam && this.userType==='Consumer' && this.advisorArray.length != 0) && !accessVault">
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4">
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4" #advisorList
              *ngFor="let data of advisorArray ; let i=index">
              <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full">
              </div>
              <div class="col-span-12">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">{{agentTerm}} Type
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{data?.['advisor_type']?.name || data?.['professional_type']?.name || data?.['professional_type']}}</p>
              </div>
              <div class="col-span-12">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{data?.['full_name']}}
                </p>
              </div>

              <div class="col-span-12 md:col-span-6 ">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['phone'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['phone'] ? ['+',data?.['country_code'],' ', data['phone']].join('') : 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['email'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['email'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation'] !=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Address
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data['address'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data['address'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation'] !=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Apartment/Suite Number
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['apt_number'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['apt_number'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation']!=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Country
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['country']?.['name'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{ (data?.['country']?.['name'] || 'Not Available') | titleCaseExceptPipe}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation']!=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">State
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['state']?.['name'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['state']?.['name'] || 'Not Available') | titleCaseExceptPipe}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation']!=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">City
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['city']?.['name'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['city']?.['name'] || 'Not Available') | titleCaseExceptPipe}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation']!=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Zip Code
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['zipcode'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['zipcode'] || 'Not Available'}}
                </p>
              </div>
            </div>
          </div>
        </div>

      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!peopleLoader && advisorArray.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-6">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              {{agentTerm}}
            </h4>
          </div>
          <div class="col-span-6">

            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
              <img src="assets/images/about-yourself/edit.svg" (click)="changeRoute('advisors/manage-advisors')"
                class="w-4 ml-4 float-right cursor-pointer" alt="digital vault"
                *ngIf="(!requestIdParam && this.userType === 'Consumer' && this.advisorArray.length != 0) && !accessVault">
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
    </ng-container>

    <!-- Custodian details -->
    <ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="peopleLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-6">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Digital Vault Custodian
            </h4>
          </div>
          <div class="col-span-6">
            <p *ngIf="custodianList?.length"
              class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              {{custodianList?.length}}
              {{custodianList?.length > 1 ? 'Digital Vault Custodians' : 'Digital Vault Custodian'}}

              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
              <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
                (click)="changeRoute('executor-beneficiary/manage-custodian')" alt="digital vault"
                *ngIf="(!requestIdParam && this.userType==='Consumer' && this.custodianArray.length != 0) && !accessVault">
            </p>
          </div>
        </div>
        <div class="bg-transparent border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
            <div class="col-span-12">

              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>

            <div class="col-span-12">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>

            <div class="col-span-12 md:col-span-6 ">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Date of Birth
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Related
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Address
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Country
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">State
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">City
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Zip Code
              </h4>
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
            </div>


          </div>
        </div>
      </div>
      <ng-container *ngIf="!peopleLoader && custodianArray.length ">
        <div class="col-span-12 md:col-span-6 my-4 lg:pl-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-6">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                {{custodianArray?.length > 1 ? 'Digital Vault Custodians' : 'Digital Vault Custodian'}}
              </h4>
            </div>
            <div class="col-span-6">
              <p *ngIf="custodianArray?.length"
                class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                {{custodianArray?.length}} {{custodianArray?.length > 1 ? 'Digital Vault Custodians' : 'Digital Vault Custodian'}}
                <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
                <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
                  (click)="changeRoute('executor-beneficiary/manage-custodian')" alt="digital vault"
                  *ngIf="(!requestIdParam && this.userType==='Consumer' && this.custodianArray.length != 0) && !accessVault">
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4">
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4" #custodianList
              *ngFor="let data of custodianArray ; let i=index">
              <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full">
              </div>

              <div class="col-span-12">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{data?.['full_name']}}
                </p>
              </div>

              <div class="col-span-12 md:col-span-6 ">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['phone'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['phone'] ? ['+',data?.['country_code'],' ', data['phone']].join('') : 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['email'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['email'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6 ">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['dob'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['dob'] | date:domainViewDateFormat) || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="showSSN">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['ssn'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['ssn'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Related
                </h4>
                <ng-container [ngSwitch]="data?.['relation']">
                  <p class="text-darkgrey text-base text-regular tracking-wide" *ngSwitchCase="1">Spouse/Partner</p>
                  <p class="text-darkgrey text-base text-regular tracking-wide" *ngSwitchCase="2">Your Child</p>
                  <p class="text-darkgrey text-base text-regular tracking-wide" *ngSwitchDefault>
                    {{data?.['people_relation']?.name}}</p>
                </ng-container>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation'] !=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Address
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data['address'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data['address'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation'] !=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Apartment/Suite Number
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['apt_number'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['apt_number'] || 'Not Available'}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation']!=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Country
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['country']?.['name'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['country']?.['name'] || 'Not Available') | titleCaseExceptPipe}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation']!=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">State
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['state']?.['name'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['state']?.['name'] || 'Not Available') | titleCaseExceptPipe}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation']!=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">City
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['city']?.['name'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{(data?.['city']?.['name'] || 'Not Available') | titleCaseExceptPipe}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['relation']!=1">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Zip Code
                </h4>
                <p class="text-base text-regular tracking-wide"
                  [ngClass]="data?.['zipcode'] ? 'text-darkgrey' : 'text-darkblue'">
                  {{data?.['zipcode'] || 'Not Available'}}
                </p>
              </div>
            </div>
          </div>
        </div>

      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!peopleLoader && custodianArray.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-6">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Digital Vault Custodian
            </h4>
          </div>
          <div class="col-span-6">

            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
              <img (click)="changeRoute('executor-beneficiary/manage-custodian')"
                src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
                alt="digital vault"
                *ngIf="(!requestIdParam && this.userType === 'Consumer' && this.custodianArray.length != 0) && !accessVault">
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
    </ng-container>
  </div>

</div>
<!-- END: Executory Tab Content-->
