import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIResponseModel } from '../interface/response.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * vehicle services
 */
export class VehicleService {
  public BASE_URL: string;
  private resetVehicleForm: BehaviorSubject<boolean>;
  public resetVehicleFormObserve: Observable<boolean>;
  // refresh added list details common
  public refreshDetailsCommon: BehaviorSubject<Object>;
  public refreshDetailsCommonObserve: Observable<Object>;

  // set insurance type
  public insuranceType: BehaviorSubject<boolean>;
  public insuranceTypeObserve: Observable<boolean>;

  // set loan type
  public loanType: BehaviorSubject<boolean>;
  public loanTypeObserve: Observable<boolean>;
  /**
 * @constructor
 */
  constructor(
    private http: HttpClient,
  ) {
    this.BASE_URL = environment.BASE_URL;

    // for resetting vehicle form
    this.resetVehicleForm = new BehaviorSubject(false);
    this.resetVehicleFormObserve = this.resetVehicleForm.asObservable();

    //
    // set insurane type
    this.insuranceType = new BehaviorSubject(false);
    this.insuranceTypeObserve = this.insuranceType.asObservable();

    // refresh added list details common
    this.refreshDetailsCommon = new BehaviorSubject<Object>({});
    this.refreshDetailsCommonObserve = this.refreshDetailsCommon.asObservable();
  }

  /**
 * Clear reset vehicle form
 *
 * @param {boolean} trigger
 */
  public resetVehicleFormTrigger(trigger: boolean): void {
    this.resetVehicleForm.next(trigger);
  }

  /**
* refresh added list details common
*
* @param {any} data
*/
  public refreshDetailList(data: Object): void {
    this.refreshDetailsCommon.next(data);
  }

  /**
   * set insurance type
   */
  /**
* refresh added list details common
*
* @param {any} data
*/
  public setInsuranceType(data: any): void {
    this.insuranceType.next(data);
  }

  /**
 * Add vehicle
 *
 * @param {any} data
 * @return {Observable<APIResponseModel>}
 */
  public addVehicle(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-vehicle-details`, data);
  }
}
