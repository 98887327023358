<div class="section mb-0">
    <div class="max-w-screen mx-auto">
        <div class="grid grid-cols-12 gap-4 row-gap-8 md:row-gap-5">
            <div class="col-span-12">
                <h2 class="text-pale-blue font-ave-bold font-bold text-2xl md:text-3xl lg:text-5xl mb-2">
                    LegacyNOW Subscription
                </h2>
                <p class="text-grey font-ave-medium text-lg sm:text-xl lg:text-2xl">Create your account.</p>
            </div>
            <div class="col-span-12">
                <div>
                    <label for="first-name" class="text-base pb-2 font-ave-semibold text-black">First Name*</label>
                    <input type="text" name="first-name" id="first-name"
                        class="input w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                        placeholder="Enter your first name">
                    <!-- <p class="font-ave-medium text-xs md:text-sm mt-2 error-alert">
                        Please enter a valid email address.</p> -->
                </div>
            </div>
            <div class="col-span-12">
                <div>
                    <label for="middle-name" class="text-base pb-2 font-ave-semibold text-black">Middle Address*</label>
                    <input type="text" name="middle-name" id="middle-name"
                        class="input w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                        placeholder="Enter your middle name">
                    <!-- <p class="font-ave-medium text-xs md:text-sm mt-2 error-alert">
                        Please enter a valid email address.</p> -->
                </div>
            </div>
            <div class="col-span-12">
                <div>
                    <label for="last-name" class="text-base pb-2 font-ave-semibold text-black">Last Name*</label>
                    <input type="text" name="last-name" id="last-name"
                        class="input w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                        placeholder="Enter your last name*">
                    <!-- <p class="font-ave-medium text-xs md:text-sm mt-2 error-alert">
                        Please enter a valid email address.</p> -->
                </div>
            </div>
            <div class="col-span-12">
                <div>
                    <label for="email" class="text-base pb-2 font-ave-semibold text-black">Email Address*</label>
                    <input type="email" name="email" id="email"
                        class="input w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                        placeholder="Enter your Email address">
                    <!-- <p class="font-ave-medium text-xs md:text-sm mt-2 error-alert">
                        Please enter a valid email address.</p> -->
                </div>
            </div>
            <div class="col-span-12">
                <div>
                    <label for="phone-no" class="text-base pb-2 font-ave-semibold text-black">Phone Number*</label>
                    <input type="number" name="phone-no" id="phone-no"
                        class="input w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                        placeholder="e.g. 8665044754">
                    <!-- <p class="font-ave-medium text-xs md:text-sm mt-2 error-alert">
                        Please enter a valid email address.</p> -->
                </div>
            </div>
            <div class="col-span-12">
                <div>
                    <label for="new-password" class="text-base pb-2 font-ave-semibold text-black">
                        Password</label>
                    <div class="relative">
                        <a class="absolute inset-y-0 right-0 pr-3 flex items-center" (click)="togglePasswordView = !togglePasswordView">
                            <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
                            <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
                        </a>
                        <input [type]="togglePasswordView?'text':'password'" name="new-password"
                            id="new-password" autocomplete="new-password"
                            class="input py-3 px-4 mt-1 w-full text-regular text-grey rounded-xs h-12 appearance-none pl-4 border block focus:outline-none"
                            placeholder="Enter Password">
                            <!-- <p class="font-ave-medium text-xs md:text-sm mt-2 error-alert">Password is required</p> -->
                    </div>
                </div>
            </div>
            <div class="col-span-12">
                <div>
                    <label for="confirm-password" class="text-base pb-2 font-ave-semibold text-black">
                    Confirm Password</label>
                    <div class="relative">
                        <a class="absolute inset-y-0 right-0 pr-3 flex items-center" (click)="togglePasswordView = !togglePasswordView">
                            <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
                            <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
                        </a>
                        <input [type]="togglePasswordView?'text':'password'" name="confirm-password" id="confirm-password"
                            autocomplete="confirm-password"
                            class="input py-3 px-4 mt-1 w-full text-regular text-grey rounded-xs h-12 appearance-none pl-4 border block focus:outline-none"
                            placeholder="Enter Password">
                        <!-- <p class="font-ave-medium text-xs md:text-sm mt-2 error-alert">Password is required</p> -->
                    </div>
                </div>
            </div>

            <div class="col-span-12">
                <div class="flex gap-4">
                    <div class="w-8 h-8">
                        <input id="default-checkbox" type="checkbox" value="" class="w-5 h-5 mt-1 text-primary border border-gray-300 rounded">
                    </div>
                    <label for="default-checkbox" class="text-base md:text-lg font-ave-semibold text-black">I acknowledge, certify, and warrant to LegacyNOW that I am at least 18 years of age and duly authorized or permitted to
                    provide the personal and
                    other information for individuals I may define within my account for the purposes of LegacyNOW’s Digital Vault and
                    related services, including as a
                    parent, guardian, tutor or otherwise. I further agree to release, discharge, and hold harmless LegacyNOW from any
                    damages, loss, claim or other
                    liability in connection with the use of such information (Vault User Agreement and Privacy Policy), including and claims
                    for invasion of privacy.
                    I agree that this release shall be binding upon me and my heirs, legal representatives and assigns.</label>
                </div>
            </div>
            <div class="col-span-12 mt-12">
                <p class="text-base md:text-lg font-ave-semibold text-black text-center">Already Have an Account? <a
                        class="text-pale-blue font-ave-semibold underline cursor-pointer">Login Here</a></p>
            </div>
            <div class="col-span-12 text-center">
                <button type="button"  routerLink="subscription-plan"
                    class="flex items-center justify-center button button--lg text-sm sm:text-base max-w-xl w-full mx-auto bg-pale-blue rounded-xs h-12 focus:outline-none font-ave-semibold text-white px-4 cursor-pointer">
                    Register
                </button>
            </div>
            <div class="col-span-12">
                <p class="text-base md:text-lg font-ave-semibold text-black text-center">By creating an account you agree to our <a
                        class="text-pale-blue font-ave-semibold underline cursor-pointer">Privacy Policy</a></p>
            </div>
            <div class="col-span-12 mt-12"> 
                <p class="text-base md:text-lg font-ave-semibold text-black text-center">* Required fields.</p>

            </div>
        </div>
    </div>
</div>