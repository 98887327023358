<div class="scroll-hidden">
  <!-- Navbar -->
  <app-navbar></app-navbar>

  <div class="lg:grid grid-cols-12 gap-4 md:gap-6 lg:gap-8 space-y-8 md:space-y-0 p-8 md:px-24 py-12 background-color-grey">
    <div class="col-span-12 mb-1">
      <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
        Who’s in your Digital Vault
      </h2>
      <hr class="border-blue w-24 my-2 border-1">
    </div>
    <!-- BEGIN: Executor -->
    <div class="col-span-12 md:col-span-6">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-11">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            {{ peopleData?.executors?.length > 1 ? "Executors" : "Executor" }}
          </h4>
        </div>
        <div class="col-span-1">
          <img src="assets/images/executors/plus-square.svg" class="w-5 object-contain float-right cursor-pointer"
               alt="digital vault" (click)="toggleModal({executor:1})">
        </div>
      </div>

      <app-list-people
        [lpSettings]="lpSettings"
        [userPeopleData]="peopleData?.executors"
        (clickEmitter)="listListener($event, {executor:1})">
      </app-list-people>

      <div class="bg-transparent border-card-grey rounded-xs"
           *ngIf="!lpSettings.isLoading && !peopleData?.executors?.length">
        <div class="grid grid-cols-12  items-center gap-4 h-18">
          <div class="col-span-12 sm:col-span-12">
            <div class="sections-root justify-between gap-2 p-3 w-full">
              <h4 class="text-base sm:text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate text-center">
                No Data to display
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Executor -->

    <!-- BEGIN: Custodian -->
    <div class="col-span-12 md:col-span-6">
      <div class="grid grid-cols-12 justify-between items-center">

        <div class="col-span-11">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            {{ peopleData?.custodians?.length > 1 ? "Digital Vault Custodians" : "Digital Vault Custodian" }}
          </h4>
          <!-- <p class="text-base text-regular pt-2 text-grey">
            Digital Vault Custodians are responsible for notifying LegacyNOW and companies you have a relationship
            with
            of your passing.
          </p> -->
        </div>
        <div class="col-span-1">
          <img src="assets/images/executors/plus-square.svg" class="w-5 object-contain float-right cursor-pointer"
               alt="digital vault" (click)="toggleModal({custodian:1})">
        </div>
      </div>

      <app-list-people
        [lpSettings]="lpSettings"
        [userPeopleData]="peopleData?.custodians"
        (clickEmitter)="listListener($event, {custodian:1})">
      </app-list-people>

      <div class="bg-transparent border-card-grey rounded-xs"
           *ngIf="!lpSettings.isLoading && !peopleData?.custodians?.length">
        <div class="grid grid-cols-12  items-center gap-4 h-18">
          <div class="col-span-12 sm:col-span-12">
          <div class="sections-root justify-between gap-2 p-3 w-full">
            <h4 class="text-base sm:text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate text-center">
              No Data to display
            </h4>
          </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Custodian -->

    <div class="col-span-12">
      <div class="my-8">
        <hr class="divider">
      </div>
    </div>

    <!-- BEGIN: Beneficiary -->
    <div class="col-span-12 md:col-span-6">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-11">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            {{ peopleData?.beneficiaries?.length > 1 ? "Beneficiaries" : "Beneficiary" }}
          </h4>
          <!-- <p class="text-base text-regular pt-2 text-grey">
            Beneficiary is someone who is entitled to inherit some or all of the assets in the Estate.
          </p> -->
        </div>
        <div class="col-span-1">
          <img src="assets/images/executors/plus-square.svg" class="w-5 object-contain float-right cursor-pointer"
               alt="digital vault" (click)="toggleModal({beneficiary:1})">
        </div>
      </div>

      <app-list-people
        [lpSettings]="lpSettings"
        [userPeopleData]="peopleData?.beneficiaries"
        (clickEmitter)="listListener($event, {beneficiary:1})">
      </app-list-people>

      <div class="bg-transparent border-card-grey rounded-xs"
           *ngIf="!lpSettings.isLoading && !peopleData?.beneficiaries?.length">
        <div class="grid grid-cols-12  items-center gap-4 h-18">
          <div class="col-span-12 sm:col-span-12">
          <div class="sections-root justify-between gap-2 p-3 w-full">
            <h4 class="text-base sm:text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate text-center">
              No Data to display
            </h4>
          </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Beneficiary -->

    <!-- BEGIN: Spouse -->
    <div class="col-span-12 md:col-span-6">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-11">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Relationship Status
          </h4>
        </div>
        <div class="col-span-1">
          <img src="assets/images/executors/plus-square.svg" class="w-5 object-contain float-right cursor-pointer"
               alt="digital vault" (click)="toggleModal({relation:1})" *ngIf="peopleData?.relationship?.length > 0">
          <img src="assets/images/executors/plus-square.svg" class="w-5 object-contain float-right cursor-pointer"
               alt="digital vault" (click)="changeRoute('about-yourself/partner')"
               *ngIf="!peopleData?.relationship?.length">
        </div>
      </div>

      <app-list-people
        [lpSettings]="lpSettings"
        [userPeopleData]="peopleData?.relationship"
        (clickEmitter)="listListener($event, {relation:1})">
      </app-list-people>

      <div class="bg-transparent border-card-grey rounded-xs"
           *ngIf="!lpSettings.isLoading && !peopleData?.relationship?.length">
        <div class="grid grid-cols-12  items-center gap-4 h-18">
          <div class="col-span-12 sm:col-span-12">
            <div class="sections-root justify-between gap-2 p-3 w-full">
              <h4 class="text-base sm:text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate text-center">
                No Data to display
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Spouse -->
    <div class="col-span-12">
      <div class="my-8">
        <hr class="divider">
      </div>
    </div>

    <!-- BEGIN: Children -->
    <div class="col-span-12 md:col-span-6">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-11">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Children
          </h4>

        </div>
        <div class="col-span-1">
          <img src="assets/images/executors/plus-square.svg" class="w-5 object-contain float-right cursor-pointer"
               alt="digital vault" (click)="toggleModal({relation:2})">
        </div>
      </div>

      <app-list-people
        [lpSettings]="lpSettings"
        [userPeopleData]="peopleData?.children"
        (clickEmitter)="listListener($event, {relation:2})">
      </app-list-people>

      <div class="bg-transparent border-card-grey rounded-xs"
           *ngIf="!lpSettings.isLoading && !peopleData?.children?.length">
        <div class="grid grid-cols-12  items-center gap-4 h-18">
          <div class="col-span-12 sm:col-span-12">
            <div class="sections-root justify-between gap-2 p-3 w-full">
              <h4 class="text-base sm:text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate text-center">
                No Data to display
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Children -->
  </div>

</div>
<app-payment-success></app-payment-success>
<app-payment-failure></app-payment-failure>