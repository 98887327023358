import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel, APIStatusCodes } from 'src/app/interface/response.interface';
import { AgentsService } from 'src/app/professional-dashboard/services/agents.service';
import { ProSocketService } from 'src/app/professional-dashboard/services/pro-socket.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { SlugService } from 'src/app/services/slug.service';
import { EMAIL_PATTERN, ERROR_401, LOCAL_STORAGE_UPDATE_STATUS, USER_TYPES } from 'src/constants/application.const';
import { ELEMENT_EVENTS } from 'src/constants/form.const';
import { environment } from 'src/environments/environment';
import { ProfessionalUserService } from '../../../../services/professional-user.service';

@Component({
  selector: 'app-professional-login',
  templateUrl: './professional-login.component.html',
  styleUrls: ['./professional-login.component.css'],
})
/**
 * professional login class
 */
export class ProfessionalLoginComponent implements OnInit {
  public faEye: any;
  public loginForm: FormGroup;
  public submitted: boolean;
  public togglePasswordView: boolean;
  public faEyeSlash: any;
  public googleCaptchaKey: string;
  public loginLoader: boolean;
  public captchaEnabled: boolean;
  public isLoggedIn: any;
  public menuActive: string;
  public orgLogo: string;
  public orgLogoPath: string;
  public proTypeText: string;
  public tempProTypeText: string;
  public proTypes: Array<any>;
  public proType: string;
  public isClicked: boolean;
  public supportEmail: string;
  public supportPhoneNumber: string;

  // recaptcha validation
  isRecaptchaValidationEnable: boolean = false;
  isCaptchaValid: boolean = true;

  public isPrivacyPolicyChecked = false;


  // referral link
  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private professionalUserService: ProfessionalUserService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private commonHelper: CommonHelper,
    private commonService: CommonService,
    private peopleService: PeopleService,
    private modalService: CommonModelService,
    private proSocketService: ProSocketService,
    private slugService: SlugService,
    private slugInterceptorService: SlugInterceptorService,
    private agentsService: AgentsService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    // this.proTypeText = this.localStorageService.getDataByKey('pro-type')?.[0]?.['displayText'];
    // this.tempProTypeText = this.route.parent.snapshot.params['pro-type'].replace(/-/g, ' ');
    // Suppose user accesses direct url instead of selecting professional type in /pro page, this code will get called
    // if (!this.proTypeText || this.proTypeText.toLowerCase() !== this.tempProTypeText) {
    // this.tempProTypeText = this.route.parent.snapshot.params['pro-type'].replace('-', ' ');
    // this.getProfessionalTypes();
    // }
    this.tempProTypeText = 'wealth management';
    this.supportEmail = this.localStorageService.getDataByKey('psp_support_email');
    this.supportPhoneNumber = this.localStorageService.getDataByKey('psp_support_number');
    this.getProfessionalTypes();
    this.updateContactInfo();

    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      } else {
        this.orgLogoPath = '';
        this.orgLogo = '';
      }
    });
    this.isLoggedIn = this.localStorageService.getUserData(USER_TYPES.pro)?.access_token;
    if (this.isLoggedIn) {
      void this.slugInterceptorService.navigate(['dashboard'], null, true);
    }
    this.googleCaptchaKey = environment.GOOGLE_CAPTCHA_KEY;
    this.togglePasswordView = false;
    this.faEye = faEye;
    this.faEyeSlash = faEyeSlash;
    this.loginLoader = false;
    this.isClicked = true;
    // register form
    this.loginForm = this.formBuilder.group({
      'email': ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      'password': ['', [Validators.required]],
      // 'agree': [false, [Validators.requiredTrue]],
      'g-recaptcha-response': ['', [this.isRecaptchaValidationEnable ? Validators.required : Validators.nullValidator]],
      // 'g-recaptcha-response': ['', [Validators.required]],
      'professional_type': [''],
    });
    this.proType = this.localStorageService.getDataByKey('pro-type')?.[0]?.value;
    this.loginForm.patchValue({
      professional_type: this.proType,
    });
    this.setRecaptchaValidation();
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.loginForm.controls;
  }

  /**
   * get advisor types
   */
  public getProfessionalTypes() {
    this.peopleService.getAdvisorTypes()?.subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.proTypes = this.commonHelper.convertToOptionsFormat(response?.data, 'id', 'name');
        const seletedProType = this.proTypes.filter((cur) => cur.displayText.toLowerCase() === this.tempProTypeText);
        this.localStorageService.storeData('pro-type', seletedProType);
        this.proTypeText = seletedProType[0]?.displayText;
        this.proType = seletedProType[0]?.value;

        this.loginForm.patchValue({
          professional_type: this.proType,
        });
      }
    });
  }


  /**
   * @param{string}url
   * on menu change
   */
  public menuChange(url?: string,isOpenInNewTab:boolean=false) {
    this.menuActive = url;
    void this.slugInterceptorService.navigate(url ? [url] : ['/'], null, true,isOpenInNewTab);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }

  /**
   * Login user
   *
   * @param {any} captchaRef for reseting the captcha field
   */
  public loginUser(captchaRef?: any): void {
    this.loginLoader = true;
    this.isCaptchaValid = this.loginForm.value['g-recaptcha-response'] ? true : false;
    this.isPrivacyPolicyChecked = this.loginForm.value['agree'] ? true : false;
    this.loginForm.markAllAsTouched();
    if (!this.loginForm.valid) { // if form not valid, it will return to form else login
      this.loginLoader = false;
      return;
    }
    this.professionalUserService.userLogin(this.loginForm.value).pipe(tap(data => {
      data.data['user']['upload_logo'] = `/professional_logo/${data.data['user']?.logo}`;
    })).subscribe((response: APIResponseModel) => {
      if (response.status === true) {
        this.loginLoader = false;
        this.setCompanyName(response);
        this.localStorageService.storeUserData(USER_TYPES.pro, response.data);
        this.sessionStorageService.storeDataInSession(USER_TYPES.pro, response.data);
        this.localStorageService.storeData('role', response.data.user.role_name);
        this.localStorageService.storeData('roleType', response.data.user.professional_type_name);
        this.localStorageService.storeData('proPaymentType', response.data.user['payment_mode']);
        this.localStorageService.storeData('admin_settings', response.data ? response.data?.admin_settings : {});
        this.commonHelper.setDomainCountryInLocal(response?.data?.country[0]?.id).subscribe();
        this.commonHelper.updateProDetailsInLocalStorage(response?.data?.user);
        this.localStorageService.clearLocalStorage();
        this.localStorageService.clearLocalStorage(USER_TYPES.org);
        this.professionalUserService.getCurrentUserName(response.data.user.name);
        this.toastrService.success(response.message);
        this.proSocketService.setUseSocket(true);
        void this.slugInterceptorService.navigate(['dashboard'], null, true);
        this.captchaReset(captchaRef);
        this.localStorageService.storeData('logout-event', Date.now().toString());
        this.localStorageService.clearLocalStorage('logout-event');
      }
    }, (exception) => {
      this.loginLoader = false;
      if (exception?.error?.statusCode === APIStatusCodes.FORBIDDEN) {
        this.localStorageService.updateUserData(USER_TYPES.pro, {
          key: 'user',
          updateValue: { email: this.loginForm.value.email },
          type: LOCAL_STORAGE_UPDATE_STATUS.S,
        });
        void this.slugInterceptorService.navigate(['login'], null, true);
        this.commonHelper.httpResponseHandler(exception?.error);
        return;
      }
      if (exception?.error?.statusCode === APIStatusCodes.UNAUTHORIZED) {
        exception.error.message = ERROR_401;
      }
      this.commonHelper.httpResponseHandler(exception?.error);
      this.captchaReset(captchaRef);
    });
  }

  /**
   * resolve captcha google
   *
   * @param {string} captchaResponse
   */
  public resolvedCaptcha(captchaResponse: string): void {
    if (!captchaResponse) return;
    this.loginForm.patchValue({
      'g-recaptcha-response': captchaResponse,
    });
    this.isCaptchaValid = this.loginForm.value['g-recaptcha-response'] ? true : false;
  }

  /**
   * catch error captcha google
   *
   * @param {string} errorDetails
   */
  public onErrorCaptcha(errorDetails: RecaptchaErrorParameters): void {
    console.log(errorDetails);
  }

  /**
   * reset captcha
   *
   * @param {any} captchaRef
   */
  public captchaReset(captchaRef?: any): void {
    captchaRef?.reset();
    this.loginForm.get('g-recaptcha-response')?.reset();
  }

  /**
   * on Register
   * @param{string}path
   */
  public onRegister(path?: string) {
    void this.slugInterceptorService.navigate(['register'], null, true);
  }

  /**
   * on mouse down
   */
  public onMouseDown() {
    this.onRegister();
  }
  /**
   * on mouse down
   */
  public onMouseDownPrivacyPolicy() {
    this.menuChange('privacy-policy',true);
  }

  /**
   * on mouse down
   */
  public onMouseDownUserAgreeement() {
    this.menuChange('user-agreement',true);
  }

  /**
   * typing / onchange input field
   * changed as untouched field
   * not show validation error
   * @param {any} data
   * @param {string} key
   */
  public onChange(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.INPUT) {
      this.loginForm.get(key).markAsUntouched();
    }
  }

  /**
   * focusout of input field
   * changed as touched field
   * check and show validation error
   * @param {any} data
   * @param {string} key
   */
  public onFocusout(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.FOCUSOUT) {
      this.loginForm.get(key).markAsTouched();
    }
  }

  /**
  * Sets up the reCAPTCHA validation for the  form.
  * Fetches the reCAPTCHA configuration and sets the validation accordingly.
  */
  public setRecaptchaValidation(): void {
    this.commonHelper.getCaptchaConfig().subscribe({
      next: (response) => {
        this.isRecaptchaValidationEnable = response?.data['is_enabled'];
        this.loginForm.get('g-recaptcha-response').setValidators(this.isRecaptchaValidationEnable ? Validators.required : Validators.nullValidator);
      }
    })
  }

  /**
   * Sets the company name in local storage based on the login response.
   *
   * @param {APIResponseModel} loginResponse - The response object from the login API call.
   * @returns {void}
   */
  public setCompanyName(loginResponse: APIResponseModel): void {
    const professionalId = loginResponse?.data?.user?.professional_id;
    if (professionalId) {
      this.agentsService.viewAgent(professionalId).subscribe({
        next: (response: APIResponseModel) => {
          const companyName: string = response?.data['full_name'];
          this.localStorageService.storeData('companyName', companyName)
        }
      })
    } else {
      this.localStorageService.deleteDataByKey('companyName')
    }
  }
  /**
   * Updates the contact information for support.
   * 
   * 
   * @returns {void}
   */
  public updateContactInfo() {
    this.commonService.getContactUsDetails().subscribe({
      next: (response) => {
        this.supportEmail = response?.data?.psp_support_email;
        this.supportPhoneNumber = response?.data?.psp_support_number;
        this.localStorageService.storeData('psp_support_email', this.supportEmail);
        this.localStorageService.storeData('psp_support_number', this.supportPhoneNumber);
      },
      error: (error) => {
        this.supportEmail = ''
        this.supportPhoneNumber = ''
      }
    })
  }
}
