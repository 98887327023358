import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { ICountryCode, SelectOptionsInterface, ToggleOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { AssetsService } from 'src/app/services/assets.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { ALPHA_NUMERIC_WITH_HYPHEN_PATTERN, LIST_DELETE, LIST_NEW, LIST_UPDATE, NAME_PATTERN, NUMBERS_VALID_DOLLARS, RETIREMENT_ASSET_TYPE, TOGGLE_OPTIONS_YES_NO, UNITED_KINGDOM, USER_NAME_WITH_SPECIAL_CHARACTERS_PATTERN, WEBSITE_PATTERN_NEW } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../../helper/validation.helper';
import { PersonalDetailsService } from '../../../../services/personal-details.service';
import { ChosenBeneficiaryEntry, SelectBeneficiaryRatioEmitModel } from '../../../../sharedComponent/select-beneficiary-ratio/select-beneficiary-ratio.component';
import { SelectWithSearchComponent } from '../../../../sharedComponent/select-with-search/select-with-search.component';
import { ConsumerSubscriptionService } from '../../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-add-retirement-asset-popup',
  templateUrl: './add-retirement-asset-popup.component.html',

})
/**
 * Retirement assets popup component
 */
export class AddRetirementAssetPopupComponent implements OnInit {
  @ViewChildren(SelectWithSearchComponent) inputComponents: QueryList<SelectWithSearchComponent>;
  @Output() toggleModalEmitter: any = new EventEmitter<any>();
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() modal_id = 'add-retirement-assets-modal';
  @Input() clientHasPaidSubscription:boolean = false;

  public readonly faEye = faEye;
  public readonly faEyeSlash = faEyeSlash;
  public readonly faInfoCircle = faInfoCircle;
  public readonly PRIMARY: number = 1;
  public readonly SECONDARY: number = 2;
  public readonly toggleOptions: Array<ToggleOptionsInterface> = TOGGLE_OPTIONS_YES_NO;
  public readonly countryCode: ICountryCode = environment.DEFAULT_COUNTRY_CODE;
  public readonly retirementAssetTypesConst = RETIREMENT_ASSET_TYPE;
  public readonly TYPE = { primary: 1, secondary: 2 };
  private chosenBeneficiaries: {
    1: { user_people_id: string, percentage: number, type: number }[],
    2: { user_people_id: string, percentage: number, type: number }[]
  } = { 1: [], 2: [] };
  private currencyFields: string[] = ['current_balance', 'contribution_amount', 'market_value'];
  private disableBeneClick: boolean = false;
  public retirementAssetForm: FormGroup;
  public submitLoader: boolean = false;
  public submitLoaderDelete: boolean = false;
  public togglePasswordView: boolean = false;
  public retirementAssetTypeArray: Array<SelectOptionsInterface> = [];
  public userType: string;
  public requestId: string;
  public vaultOwnerSelectedAsBeneficiary: string;
  public vaultHolderAsBeneficiaryId:string;
  public isPlaidData: boolean = false;
  public retirementAssetTypeId: string;
  public frequencyTypeId: string;
  public accountOwnerId: string;
  public retirementAssetType: number = null; // retirement asset type(1 or 2)
  public currentContribution: number;
  public userRetirementAssetId: string; // user retirement form id to get percentage details of beneficiary
  public touchedRetirementType = false;
  public frequencyTypeArray: Array<SelectOptionsInterface>;
  public userPeopleArray: Array<SelectOptionsInterface>;
  public beneficiariesRAW: Array<any>;
  public isNewAsset = true;
  public userData: Object = {};
  public validFields: {};
  public midPlaid = 'accounts-card-plaid-import-modal'; // from common asset settings
  static loadComplete = false;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public selectedBeneficiaries: Array<Object>;
  public remainingToAllocate: Array<ChosenBeneficiaryEntry>;
  public userAsBenificiary:number = 1;
  public relationshipDetails: Array<Object>;
  public beneficiaryTypes: Array<Object>;
  private hasPaidSubscription: boolean;

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private commonHelper: CommonHelper,
    private assetsService: AssetsService,
    private subscriptionService: ConsumerSubscriptionService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private personalDetailsService: PersonalDetailsService,
    private peopleService: PeopleService,
    private validation: ValidationHelper,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.hasPaidSubscription = r);
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.userData = this.localStorageService.getUserData();
    this.requestId = this.userData?.['request']?.['id'] || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');

    // Also calls buildAssetForm()
    this.getRelationshipList();
    this.getBeneficiaryTypes();
    this.setAssetType(null);
    this.initValidationErrors();
    this.getAssetDetails();
    this.getFrequencyList();
    this.getUserPeople();
    if (!this.canAccessForm) {
      this.setAssetType(this.retirementAssetTypesConst.OLDFORM);
      this.retirementAssetForm.disable();
    }
  }

  /**
   * Called after view checked
   */
  ngAfterViewChecked(): void {
    // update include current login user if asset's account ownerId are same
    if(this.modalService.isOpen(this.modal_id)) {
      const vaultHolderEmail = this.userData?.['user']?.['email'];
      const isAccountOwnerSelected = this.beneficiariesRAW?.find(user => user.id === this.accountOwnerId && user.email == vaultHolderEmail);
      this.vaultOwnerSelectedAsBeneficiary = isAccountOwnerSelected?.['id'];
    }
  }

  /**
   * Initialize validation texts for {@link ValidationHelper}.
   * @private
   */
  private initValidationErrors() {
    this.validation.setValidationErrors({
      market_value: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places.', pattern: false },
      contribution_amount: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places.', pattern: false },
      current_balance: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places.', pattern: false },
      website: { pattern: 'Invalid URL' },
    });
  }

  /**
   * Set the edit value
   * @param {any}data
   */
  @Input() set editData(data: any) {
    if (data && data?.id) {
      this.currentContribution = data?.['current_contributions'];
      this.retirementAssetTypeId = data?.['retirement_asset'].id;
      this.setAssetType(data?.['retirement_asset'].type);
      this.userRetirementAssetId = data.id; // user retirement form id to get percentage details of beneficiary
      this.frequencyTypeId = data.frequency_id;
      this.accountOwnerId = data.user_people_id;
      this.retirementAssetForm?.patchValue({
        ...data,
        v_account_number: data?.account_number,
      });
      this.currencyFields.forEach((e: string) => {
        if (this.retirementAssetForm?.get(e)) {
          this.commonHelper.commaSeparateNumberPatch(this.retirementAssetForm.get(e).value, this.retirementAssetForm.get(e));
        }
      });
      this.percentageDetails(this.userRetirementAssetId);
      this.isPlaidData = data.import_type === 2;
      this.isNewAsset = false; // If importing data, then this is not a new asset.
    }
  }

  /**
   * Determine if the 'Save/Update Details' button should be valid.
   */
  public isValidSave(): boolean {
    return (

      this.retirementAssetType != null &&
      !this.submitLoaderDelete &&

      this.retirementAssetTypeId !== '' &&
      (
        (this.isNewAsset && this.permissions?.['add']) ||
        (!this.isNewAsset && this.permissions?.['edit'])
      )
    );
  }

  /**
   * Set asset type and update controlKeys.
   * If null is passed, this effectively disables the input fields.
   * @param type
   */
  public setAssetType(type: number) {
    this.retirementAssetType = type;
    this.validFields = {
      'bank_name': type != null,
      'asset_name': type != null,
      'user_people_id': type != null,
      'account_number': type != null,
      'v_account_number': type != null,
      'institution': type!= null,
      'account_name': type != null,
      'import_type': type != null,
      'id': type != null,
      'beneficiaries': type != null,
      'market_value': type === this.retirementAssetTypesConst.NEWFORM,
      'contribution_amount': type === this.retirementAssetTypesConst.NEWFORM,
      'frequency_id': type === this.retirementAssetTypesConst.NEWFORM,
      'current_contributions': type === this.retirementAssetTypesConst.NEWFORM,
      'note': type === this.retirementAssetTypesConst.NEWFORM,
      'current_balance': type === this.retirementAssetTypesConst.OLDFORM,
      'user_name': type === this.retirementAssetTypesConst.OLDFORM,
      'password': type === this.retirementAssetTypesConst.OLDFORM,
      'website': type === this.retirementAssetTypesConst.OLDFORM,
    };
    this.buildAssetForm();
  }

  /**
   * Builds the {@link retirementAssetForm} with conditional validators based on {@link validFields}
   * @private
   */
  private buildAssetForm() {
    // Dynamically determine validators based on valid fields
    const validators = {
      //bank_name: [Validators.pattern(NAME_PATTERN), this.validation.validators.trim],
      asset_name: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN),this.validation.noWhitespaceValidator()],
      user_people_id: [],
      account_number: [Validators.pattern(ALPHA_NUMERIC_WITH_HYPHEN_PATTERN), Validators.minLength(5), Validators.maxLength(20)],
      v_account_number: [],
      institution: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim],
      account_name: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN),this.validation.noWhitespaceValidator()],
      market_value: [...(this.validFields?.['market_value'] ? [Validators.pattern(NUMBERS_VALID_DOLLARS)] : [])],
      contribution_amount: [...(this.validFields?.['contribution_amount'] ? [Validators.pattern(NUMBERS_VALID_DOLLARS)] : [])],
      frequency_id: [],
      import_type: [],
      current_contributions: [],
      id: [],
      note: [Validators.maxLength(500)],
      type: [],
      current_balance: [Validators.pattern(NUMBERS_VALID_DOLLARS)],
      user_name: [Validators.minLength(3), Validators.maxLength(30), Validators.pattern(USER_NAME_WITH_SPECIAL_CHARACTERS_PATTERN)],
      password: [Validators.minLength(3)],
      website: [Validators.pattern(WEBSITE_PATTERN_NEW), Validators.maxLength(150)],
    };

    // Form builder for retirementAssetForm. Only build if nullish.
    this.retirementAssetForm = this.retirementAssetForm ?? this.formBuilder.group({
      //bank_name: ['', { updateOn: 'blur' }],
      asset_name: ['', { updateOn: 'blur' }],
      user_people_id: ['', { updateOn: 'blur' }],
      account_number: ['', { updateOn: 'blur' }],
      v_account_number: [''],
      institution: ['', {
        updateOn: 'blur',
      }],
      account_name: ['', { updateOn: 'blur' }],
      market_value: ['', { updateOn: 'blur' }],
      contribution_amount: ['', { updateOn: 'blur' }],
      frequency_id: ['', { updateOn: 'blur' }],
      import_type: ['', { updateOn: 'blur' }],
      current_contributions: [this.currentContribution],
      id: [''],
      note: [''],
      type: [this.retirementAssetTypesConst.NEWFORM],
      current_balance: ['', { updateOn: 'blur' }],
      user_name: [''],
      password: [''],
      website: [''],
    });

    // Reassign validators.
    for (let validatorsKey in validators) {
      this.retirementAssetForm.get(validatorsKey).clearValidators();
      this.retirementAssetForm.get(validatorsKey).setValidators(validators[validatorsKey]);
    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.retirementAssetForm.controls;
  }

  /**
   * Check verification (confirmation) controls.
   * @param control
   */
  public isVerified(control: string) {
    const v_control = `v_${control}`;
    const formControl = this.formGet;
    return (formControl[control]?.value || null) == (formControl[v_control]?.value || null);

  }

  /**
   * get all asset type list
   */
  public getAssetDetails(): void {
    this.commonService.getRetirementAssetType().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.retirementAssetTypeArray = this.commonHelper.convertToOptionsFormat(response.data, 'id', 'name').sort(this.commonHelper.compareDisplayText);

        // Always place 'Other' last to make it easy to find.
        this.commonHelper.placeAtPointOfArray(this.retirementAssetTypeArray, (e) => e.displayText.toLowerCase() == 'other');

        this.resetForm();
      }
    });
  }

  /**
   * get frequency list
   */
  public getFrequencyList(): void {
    this.commonService.getFrequencyTypes().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.frequencyTypeArray = this.commonHelper.convertToOptionsFormat(response.data, 'id', 'name');
        this.frequencyTypeId = this.frequencyTypeArray[0].value;
        this.resetForm();
      }
    });
  }

  /**
  * Calls server to get user_people data for this Vault Holder.
  * @private
  */
  private getUserPeople() {
    const userType = this.localStorageService.getDataByKey('role');
    const isConsumer = userType === 'Consumer';
    this.personalDetailsService.getUserPeople(!isConsumer ? this.requestId : undefined,this.userAsBenificiary).subscribe({
      next: r => {
        this.beneficiariesRAW = r.data?.user_people
        const vaultHolderEmail = this.userData?.['user']?.['email'];
        const is_user_also_an_beneficiary = this.beneficiariesRAW.some(user => user.email === vaultHolderEmail);
        if (is_user_also_an_beneficiary) {
          this.userPeopleArray = this.commonHelper.convertToOptionsFormat(this.beneficiariesRAW, 'id', 'full_name');
          const vaultHolderAsBeneficiary = this.beneficiariesRAW?.find((user) =>user.email === vaultHolderEmail);
          this.vaultHolderAsBeneficiaryId = vaultHolderAsBeneficiary?.['id'];
        }
        else {
          const payload = {
            ...this.commonHelper.getVaultHolderData(this.userData),
            beneficiary: 1,
            beneficiary_type_id: 1,
            type: 1,
            id: null,
            user_as_beneficiary:true,
            relationship: this.getTypeIdFromDetails('other', this.relationshipDetails), // Other
            define_others: 'Vault Holder',
            beneficiary_type: this.getTypeIdFromDetails('individual', this.beneficiaryTypes), // Individual
            request_id: this.requestId,
            is_state: this.commonHelper.getCountryNameById(this.localStorageService.getDataByKey('country')) == UNITED_KINGDOM ? '0' : '1', // state necessity decided by user country name
          }
          this.peopleService.storePeopleDetails(payload).subscribe({
            next: (response) => {
              this.vaultHolderAsBeneficiaryId = response.data['id'];
              this.getUserPeople();
            }
          })
        }
      },
      error: () => this.userPeopleArray = [],
    });
  }

  /**
   * change listener for asset type change
   * @param {SelectOptionsInterface}selectedOption
   */
  public optionChangeEmitterAssetType(selectedOption: SelectOptionsInterface): void {
    if (selectedOption) {
      this.retirementAssetTypeId = selectedOption.value;
      this.setAssetType(selectedOption.additionalDetails?.type || this.retirementAssetTypesConst.NEWFORM);
      if (this.retirementAssetForm?.value?.id) return;
      this.resetForm(selectedOption.additionalDetails?.type);
    }
  }

  /**
   * change listener for frequency type change
   * @param {SelectOptionsInterface}selectedOption
   */
  public optionChangeEmitterFrequencyType(selectedOption: SelectOptionsInterface): void {
    if (selectedOption) {
      this.frequencyTypeId = selectedOption.value;
      this.retirementAssetForm.patchValue({
        frequency_id: selectedOption.value,
      });

      // Timeout to disable beneficiary section clicks, to avoid opening on double-click.
      this.disableBeneClick = true;
      setTimeout(() => {
        this.disableBeneClick = false;
      }, 500);
    }
  }

  /**
   * change listener for account owner
   * @param selectedOption
   */
  public optionChangeEmitterAccountOwner(selectedOption: SelectOptionsInterface): void {
    if (selectedOption) {
      this.accountOwnerId = selectedOption.value;

      this.retirementAssetForm.patchValue({
        user_people_id: this.accountOwnerId
      })

      // Check if any beneficiary in chosenBeneficiaries has vaultHolderAsBeneficiaryId
      const isVaultHolderChosenAsBenef = Object.values(this.chosenBeneficiaries).some((arr: any[]) =>
      arr.length > 0 && arr.some((obj: any) => obj.user_people_id === this.vaultHolderAsBeneficiaryId));

     // Reset the chosenBeneficiaries and trigger the resetChosenBenefTrigger event
     // if the vault holder is chosen both as the account owner and beneficiary.
      if(this.accountOwnerId === this.vaultHolderAsBeneficiaryId && isVaultHolderChosenAsBenef){
        this.chosenBeneficiaries = { 1: [], 2: [] };
        this.peopleService.resetChosenBenefTrigger(true);
      }
    }
  }

  /**
   * close modal
   */
  public closeModal(): void {
    this.setAssetType(undefined);
    this.retirementAssetTypeId = null;
    this.userRetirementAssetId = null;
    this.selectedBeneficiaries = undefined;
    this.chosenBeneficiaries = { 1: [], 2: [] };
    this.frequencyTypeId = null;
    this.accountOwnerId = null;
    this.togglePasswordView = false;
    this.isPlaidData = false;
    this.isNewAsset = true;
    this.resetForm();
    this.modalService.close('add-retirement-assets-modal');
    this.toggleModalEmitter.emit();
    this.peopleService.refreshDetailList({ refresh: true });

  }

  /**
   * On Submit
   */
  public onSubmit(): void {
    this.canEdit && this.retirementAssetForm.markAllAsTouched();
    if (!this.retirementAssetForm.valid) {
      this.commonHelper.scrollToFirstInvalidControl(this.retirementAssetForm);
      return; // return if the form is invalid
    }
    this.submitLoader = true;
    this.submitRetirementAsset().subscribe({
      next: () => {
        // Wait to submit beneficiaries in case this is a new asset.
        // Can be assured that an assetID is gathered for the beneficiaries at this point.
        this.submitBeneficiaries();
        this.modalService.close(this.midPlaid);
        this.closeModal();
      },
      error: (exception) => {
        this.submitLoader = true;
        this.commonHelper.httpResponseHandler(exception?.error);
      },
      complete: () => this.submitLoader = false,
    });
  }

  /**
   * @returns {@link Observable} which handles submitting the retirement asset to server.
   */
  public submitRetirementAsset(): Observable<any> {
    return new Observable<any>((observer) => {
      const isConsumer = this.userType === 'Consumer';
      const formValue: { [name: string]: any } = {
        ...this.retirementAssetForm.value,
        frequency_id: this.frequencyTypeId,
      };
      // If no contributions, zero any value that may be in these fields.
      if (this.currentContribution == 0) {
        formValue['contribution_amount'] = null;
        formValue['frequency_id'] = null;
      }

      // Remove unrelated fields for selected type
      for (let formValueKey in formValue) {
        if (!this.validFields[formValueKey]) {
          delete formValue[formValueKey];
        }
      }

      // Remove commas for backend.
      this.currencyFields.forEach((form_name) => {
        if (formValue[form_name]) {
          formValue[form_name] = this.commonHelper.commaSeparateNumberClean(formValue[form_name], null);
        }
      });

      // Create Payload
      let payload: { [name: string]: any } = {
        ...formValue,
        roletype: this.userType,
        country_code: this.countryCode?.dialCode,
        flag_code: this.countryCode?.code,
        request_id: this.requestId,
        current_contributions: this.currentContribution,
        type: this.retirementAssetType,
        retirement_asset_id: this.retirementAssetTypeId,
      };
      if (!payload?.['id']) {
        payload.id = this.userRetirementAssetId;
      }
      if (!payload?.current_balance) {
        delete payload.current_balance;
      }
      this.submitLoader = true;
      this.assetsService.storeRetirementAssetDetails(payload).subscribe({
        next: (response: APIResponseModel) => {
          if (response.status) {
            if (isConsumer) {
              this.commonHelper.updateLocalstorageRequestStage(response.data);
              formValue?.['id'] ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();

            }
            this.userRetirementAssetId = response.data.id;
            this.toggleModalEmitter.emit({
              ...response.data,
              listStatus: (formValue?.['id']) ? LIST_UPDATE : LIST_NEW,
            });

          }
          observer.next(response);
        },
        error: (exception: any) => {
          observer.error(exception);
          this.submitLoader = false;
        },
        complete: () => observer.complete(),
      });
      return {
        unsubscribe() {
        },
      };
    },
    );
  }

  /**
   * Submit Beneficiaries to the user_retirement_asset defined by {@link userRetirementAssetId}.
   */
  public submitBeneficiaries(): void {
    this.submitLoader = true;
    const getData = (type) => {
      return {
        request_id: this.requestId,
        user_retirement_asset_id: this.userRetirementAssetId, // retirement asset id to user
        retirement_asset_id: this.retirementAssetTypeId, // selected form type id
        beneficiary: this.chosenBeneficiaries[type],
        type: type,
      };
    };
    const data = { 1: getData(this.PRIMARY), 2: getData(this.SECONDARY) };
    this.personalDetailsService.submitBeneficiaries(data, 1).subscribe({
      error: (e) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(e.error);
      },
      complete: () => this.submitLoader = false,
    });
  }
  /**
   * delete retirement assets details
   */
  public deleteRetirementAssets(): void {
    // ACL check
    if (!this.permissions?.['delete']) {
      this.toastrService.info('You do not have permission to delete items for this client.');
      return;
    }

    this.submitLoaderDelete = true;
    const formValue = this.retirementAssetForm.value;
    const isConsumer = this.userType === 'Consumer';

    let data: {} = {
      is_delete: '1',
      id: formValue?.['id'],
      roletype: this.userType,
    };
    if (!isConsumer) {
      data['request_id'] = this.requestId;
    }

    this.assetsService.storeRetirementAssetDetails(data).subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          this.toggleModalEmitter.emit({
            id: formValue?.['id'],
            listStatus: LIST_DELETE,
          });
          if (isConsumer) {
            this.commonHelper.toastrDeleteSuccess();
          }
          this.submitLoaderDelete = false;
          this.closeModal();
        }
      },
      error: (exception: any) =>{
        this.submitLoaderDelete = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      },
      complete: () => this.submitLoaderDelete = false,
    });
  }

  /**
   * on change toggle
   * @param {any}data
   */
  public onChangeContributions(data: any) {
    this.currentContribution = data.value;
  }
 /**
   * Get relationship list
   */
 public getRelationshipList(): void {
  this.commonService.getRelationshipList().subscribe((response: APIResponseModel) => {
    if (response.status) {
      this.relationshipDetails = response?.data
    }
  });
}
  /**
   * get beneficiary types
   */
  public getBeneficiaryTypes() {
    this.commonService.getBeneficiaryTypes().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.beneficiaryTypes = response?.data;
      }
    });
  }
  /**
   * Get type id from details
   * @param type
   * @param details
   * @returns type id from details
   */
  public getTypeIdFromDetails(type: string, details: Array<Object>): string {
    return details?.find(element => {
      String(element?.['name']).toLocaleLowerCase() == type
     })?.['id']
  }
  /**
   * Reset form
   * @param{any}type
   */
  public resetForm(type?: any): void {
    AddRetirementAssetPopupComponent.loadComplete = false;
    this.touchedRetirementType = false;
    this.currentContribution = 0;  //ensuring that it reflects as zero and contributes to displaying "no" in the toggle.
    this.inputComponents.forEach(e => e.clearSelectedOption());
    this.retirementAssetForm.reset({
      import_type: '1',
    });
    AddRetirementAssetPopupComponent.loadComplete = true;
  }

  /**
   * Get beneficiary percentage details, populate {@link chosenBeneficiaries}.
   * @param retirementId
   */
  private percentageDetails(retirementId) {
    if (retirementId != undefined) {
      this.submitLoaderDelete = true;
      this.assetsService.getRetirementPercentageList(retirementId).subscribe({
        next: (res) => {
          this.chosenBeneficiaries = this.commonHelper.populateChosenBeneficiaries(res.data?.beneficiary);
          this.selectedBeneficiaries = res.data?.beneficiary
        },
        error: (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
        complete: () => this.submitLoaderDelete = false,
      });
    }
  }

  /**
   * Select beneficiary ratio listener
   * @param $event
   */
  selectBeneficiaryRatioListener($event: SelectBeneficiaryRatioEmitModel) {
    this.chosenBeneficiaries = $event;
  }
  /**
   * Remaining to allocate listener
   * @param event
   */
  remainingToAllocateListener(event: Array<ChosenBeneficiaryEntry>) {
  this.remainingToAllocate = event;
  }
  /**
   * Get is there remaining allocation for beneficiaries
   */
  public get remainingAllocation(): boolean {
    return Object.values(this.TYPE).some(type => {
      return this.remainingToAllocate?.[type] && this.remainingToAllocate?.[type]?.percentage !== 100;
    });
  }

  public markAsTouchedType() {
    this.touchedRetirementType = true;
  }

  /**
   * If user can edit existing asset, or add a new asset.
   */
  get canEdit(): boolean {
    return this.commonHelper.canEditSection(this.isNewAsset, this.permissions) && (this.hasPaidSubscription || this.clientHasPaidSubscription)
  }

  /**
   * show error message
   */
  public canShowErrorMessage() {
    return !this.formGet?.['account_number']?.value && this.formGet?.['v_account_number']?.touched && this.formGet?.['v_account_number']?.value;
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlsKey
   */
  public getErrors(controlsKey: string): Array<string> {
    return this.validation.getErrors(controlsKey, this.retirementAssetForm);
  }
}
