<div class="lg:grid grid-cols-12 lg:pb-10 background-color">
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 md:gap-8 my-4">
              <div class="col-span-12 sm:col-span-2">
                <!-- <div class="w-16 my-2"> -->
                <img src="assets/images/about-yourself/about-yourself.png" class="w-20" alt="digital vault">
                <!-- </div> -->
              </div>

              <div class="col-span-12 sm:col-span-10">
                <!-- <div class="pl-4 sm:px-4 ml-8"> -->
                <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
                  Tell us about yourself
                </h2>
                <!--p class="text-sm text-grey tracking-wide text-regular">
                  This information is critical and a key to verifying your identity. Please double-check your input
                  prior to moving on.
                </p-->
                <!-- </div> -->

              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-full'"></app-card-progress-bar>
              <!-- END: Progress Bar -->

              <div class="p-6">
                <div class="grid grid-cols-12 items-center gap-4 row-gap-4 mb-4">
                  <div class="col-span-12 sm:col-span-8">
                    <div>
                      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                        Your Children
                      </h4>
                      <p class="text-grey text-regular pt-1 text-sm">Do you have children?
                      </p>
                    </div>
                  </div>
                  <div class="col-span-12 sm:col-span-4">
                    <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                      <app-toggle-switch [defaultSelected]="childStatus" [options]="toggleOptions"
                                         (optionChangeEmitter)="onChangeToggle($event)"
                                         [disabled]="submitLoader[0]|| submitLoader[1]">
                      </app-toggle-switch>
                    </app-shimmer-loading>
                  </div>
                </div>

                <div *ngIf="preDefinedLoader">
                  <div class="my-8">
                    <hr class="mid-line border border-2">
                  </div>
                  <div class="bg-transparent border-card-grey rounded-xs mt-6 h-20">
                    <div class="grid grid-cols-12 h-20 items-center gap-4 px-4">
                      <div class="col-span-2">
                        <app-shimmer-loading [loading]="preDefinedLoader" [shape]="'circle'" [width]="'4rem'">
                        </app-shimmer-loading>

                      </div>
                      <div class="col-span-7 sm:col-span-8">
                        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                          <h4
                            class="text-base sm:text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate"></h4>
                        </app-shimmer-loading>
                        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                          <p class="text-grey text-regular text-xs sm:text-sm break-all"></p>
                        </app-shimmer-loading>
                      </div>
                      <div class="col-span-2 cursor-pointer">
                        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                          <img src="assets/images/about-yourself/edit.svg" class="w-4 sm:w-5 float-right cursor-pointer"
                               alt="digital vault">
                        </app-shimmer-loading>
                      </div>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="childStatus == 1">
                  <!-- executor listing component -->
                  <app-list-people
                    [lpSettings]="lpSettings"
                    [userPeopleData]="userPeopleData"
                    (clickEmitter)="listListener($event)">
                  </app-list-people>

                  <!-- Add a child button -->
                  <div class="mt-6">
                    <button type="button" (click)="toggleModal()"
                            class="button button--lg w-full flex flex-row justify-center items-center bg-transparent border-red rounded-xs h-16 focus:outline-none text-regular font-semibold tracking-wide text-darkblue px-6">
                      <img src="assets/images/about-yourself/add-plus.svg" class="w-6 mr-3 hidden sm:block"
                           alt="digital vault"> Add a
                      Child
                    </button>
                  </div>
                </ng-container>

                <div class="flex flex-col-reverse sm:flex sm:flex-row gap-4 justify-between items-center mt-8">
                  <a (click)="changeRoute('/about-yourself/partner')" *ngIf="this.userType === 'Consumer'"
                     class=" cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </a>
                  <a (click)="changeRoute('/about-yourself/partner/'+requestId)" *ngIf="this.userType !== 'Consumer'"
                     class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </a>
                  <!-- Submit Button -->
                  <app-button-loader class="w-full sm:w-auto" *ngIf="(!childStatus || this.userPeopleDataRaw.length > 0) && !this.redirectUrl"
                                     [buttonText]="this.redirectUrl? 'Discard': 'Save and Continue'" [valid]="true" [loader]="submitLoader[0]"
                                     [buttonType]="'button'"
                                     (click)="onSubmit(0)">
                  </app-button-loader>
                  <app-button-loader class="w-full sm:w-auto"
                                     *ngIf="(!childStatus || this.userPeopleDataRaw.length > 0) &&this.redirectUrl"
                                     [buttonText]="'Save and Return to' + redirectBtnText" [valid]="true"
                                     [loader]="submitLoader[1]" [buttonType]="'button'"
                                     (click)="onSubmit(1)">
                  </app-button-loader>
                  <app-button-loader class="w-full sm:w-auto" *ngIf=" childStatus&& this.userPeopleDataRaw.length == 0 && !this.redirectUrl"
                                     [buttonText]="'Save and Continue'"
                                     [buttonTitle]="'Please add Child/Children details'"
                                     [valid]="false" [loader]="submitLoader[0]" [buttonType]="'button'">
                  </app-button-loader>
                </div>
              </div>
              <!-- END: Leftside Card -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- BEGIN: Rightside Content -->
  <div class=" col-span-12 md:col-span-6 lg:grid justify-center ">
    <about-yourself-right-content [formInputValue]="{has_child: childStatus}" [otherValues]="sectionSaveExitOptions"
                                  [buttonStatus]="+ (!childStatus || this.userPeopleDataRaw.length > 0)"></about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->
  <app-add-children-model [editData]="editData" [addressDetails]="addressDetails">
  </app-add-children-model>
</div>
