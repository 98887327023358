<div class="grid grid-cols-5 border-card" *ngIf="contactPermissionItems?.length > 1 && showTree">
  <div class="col-span-5 xl:col-span-3">
    <h4 class="text-darkblue text-semibold tracking-wide px-3 pt-2 text-center">
      {{agentTerm}} Communication
    </h4>
    <div class="text-grey text-regular text-justify tracking-wide text-sm px-3 pb-3">
      Here you can define which of your providers can communicate with each other.
      For example, if you don't wish for your Attorney to communicate with your Wealth Manager,
      simply uncheck the box.
    </div>
  </div>
  <div class="col-span-5 xl:col-span-2 bg-login-grey p-2">
    <ngx-treeview [config]="config" [items]="contactPermissionItems" (selectedChange)="itemChanged()">
    </ngx-treeview>
  </div>
</div>

<!-- people listing component -->
<app-list-people [lpSettings]="lpSettings" [userPeopleData]="userPeopleData" [sectionName]="'advisor'"
                 (clickEmitter)="clickListener($event)">
</app-list-people>

<div class="bg-transparent border-card-grey rounded-xs mt-4 md:h-auto lg:h-20" *ngIf="this.lpSettings?.isLoading">
  <div class="grid grid-cols-12 h-20 items-center gap-4 px-3 sm:pl-3">
    <div class="col-span-3 sm:col-span-2">
      <div class="flex sm:items-center sm:justify-center border-circle sm:mx-auto rounded-full h-12 w-12 sm:h-8 w-8">
          <app-shimmer-loading [shape]="'circle'" [width]="'3rem'">
            <div class="flex items-center justify-center mx-auto rounded-full h-12 w-12 bg-darkblue">
              <p class="uppercase text-white text-regular text-base">
                <img src="assets/images/executors/display-img.svg" class="w-5" alt="digital vault">
              </p>
            </div>
          </app-shimmer-loading>  
      </div>
   
    </div>
    <div class="col-span-7 sm:col-span-8">
      <h4 class="break-all text-base sm:text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate">
        <app-shimmer-loading class="w-full h-12">
          Lily-Rose Depp
        </app-shimmer-loading>
      </h4>
      <p class="text-grey text-regular text-xs sm:text-sm">
        <app-shimmer-loading class="w-full h-12">
          Your Child
        </app-shimmer-loading>
      </p>
    </div>
    <div class="col-span-2">
      <div class="flex items-center justify-center h-5">
        <app-shimmer-loading [width]="'3rem'">
          <input type="checkbox" class="h-4 w-4 input text-lightblue border rounded">
        </app-shimmer-loading>
      </div>
    </div>
  </div>
</div>


<ng-container *ngIf="!this.lpSettings?.isLoading && (userPeopleData.length==0 && this.userType !== 'Consumer')">
  <p class="text-grey text-regular text-semibold sm:text-sm">No Data to display</p>
</ng-container>

<ng-container
  *ngIf="!this.lpSettings?.isLoading &&(userPeopleData?.length==0 && this.userType === 'Consumer'&& currentRoute === 'executors-beneficiaries-view')">
  <p class="text-grey text-regular text-semibold sm:text-sm">
    You have not added {{isCustodian ? 'custodians' : (agentTerm + 's')}} yet.
  </p>
</ng-container>
<app-advisor-settings-modal [advisorData]="advisorData"
                            (editInfo)="editAdvisorListener($event)"></app-advisor-settings-modal>
<app-add-advisor-modal (toggleModalEmitter)="refreshListing($event)" [editData]="editData" [isCustodian]="isCustodian">
</app-add-advisor-modal>
<app-define-advisor-modal
  [modal_id]="defineAdvisorID"
  [editData]="advisorData">
</app-define-advisor-modal>

