import { Directive, EventEmitter, HostListener, Output } from '@angular/core';


@Directive({
  selector: '[appSwipe]',
})
/**
 * Interprets touchstart and touchend events into swipe events with direction and duration.
 */
export class SwipeDirective {
  @Output() onswipe: EventEmitter<SwipeModel> = new EventEmitter();
  private swipeStartCoordinate?: [number, number];
  private swipeStartTime?: number;


  @HostListener('touchstart', ['$event'])
  onTouchStart($event) {
    this.onSwipe($event, 'start');
  }


  @HostListener('touchend', ['$event'])
  onTouchEnd($event) {
    this.onSwipe($event, 'end');
  }


  onSwipe($event: TouchEvent, when: string) {
    const coord: [number, number] = [$event.changedTouches[0].clientX, $event.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeStartCoordinate = coord;
      this.swipeStartTime = time;
    } else if (when === 'end') {
      const dPos = { x: coord[0] - this.swipeStartCoordinate[0], y: coord[1] - this.swipeStartCoordinate[1] };
      const dTime = time - this.swipeStartTime;

      if (dTime < 1000) {
        const direction = this.getDirection(dPos);
        if (direction) {
          this.onswipe.emit({ direction: direction, duration: dTime });
          console.log({ direction: direction, duration: dTime });
        }
      }
    }
  }

  private getDirection(dPos:{x:number,y:number}){
    const horizontal = Math.abs(dPos.x) > Math.abs(dPos.y * 3) && Math.abs(dPos.x) > 30; // Significantly Horizontal
    const vertical = Math.abs(dPos.y) > Math.abs(dPos.x * 3) && Math.abs(dPos.y) > 30; // Significantly Horizontal
    let direction: 'L' | 'R' | 'U' | 'D';
    if (horizontal) {
      direction = dPos.x > 0 ? 'L' : 'R';
    } else if (vertical) {
      direction = dPos.y > 0 ? 'U' : 'D';
    }
    return direction;
  }
}


export interface SwipeModel {
  // The opposite direction the finger moves. i.e. A left swipe starts at x and ends at z where `z > x`.
  direction: 'L' | 'R' | 'U' | 'D';
  duration: number;
}
