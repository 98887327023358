import { Component, OnInit } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';

@Component({
  selector: 'app-complete-purchase',
  templateUrl: './complete-purchase.component.html',
  styleUrls: ['./complete-purchase.component.css'],
})
export class CompletePurchaseComponent implements OnInit {
  constructor(private modalService: CommonModelService) {}

  ngOnInit(): void {}
  public startSubscription(): void {
    this.modalService.open('start-subscription');
  }
  public closeModal(): void {
     this.modalService.close('start-subscription');
  }
}
