import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { ExeCusNavbarModule } from 'src/app/executor-custodian/pages/exe-cus-navbar/exe-cus-navbar.module';
import { PipeModule } from 'src/app/pipes/pipes.module';
import { ProfessionalNavBarModule } from 'src/app/professional-dashboard/components/professional/professional-navbar/professional-navbar.module';
import { SharedModule } from 'src/app/sharedComponent/shared.module';
import { CardDetailsComponent } from '../consumer-payment-options/card-details/card-details.component';
import { NavBarModule } from '../navbar/navbar.module';



@NgModule({
  declarations: [CardDetailsComponent],
  imports: [
    CommonModule,
    RouterModule, FontAwesomeModule, ReactiveFormsModule,FormsModule,NgxMaskModule, PipeModule, SharedModule,NavBarModule,ExeCusNavbarModule,ProfessionalNavBarModule
  ],
  exports: [CardDetailsComponent],
})
export class ManageCardsModule { }
