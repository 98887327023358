<div class=" border-card bg-white rounded-md p-6">
    <div class="flex justify-between" id="AgentTable">
        <div>
            <h4 class="text-semibold text-grey text-md md:text-lg lg:text-xl xl:text-2xl">
                Invoice Details
            </h4>
            <p class="text-grey text-regular text-sm md:text-md lg:text-md">
                Invoices will be shown here
            </p>
        </div>
    </div>
    <!-- Professional Invoice Table -->
    <div *ngIf="dataLoading">
        <div class="mt-3 w-full">
            <app-shimmer-loading [loading]="true" [width]="'100%'" [height]="'3rem'"></app-shimmer-loading>
        </div>
        <div class="flex flex-row mt-3 mb-6 items-center">
            <div>
                <app-shimmer-loading [loading]="true" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
            </div>
            <div class="ml-4 mt-0 w-full">
                <ng-container *ngFor="let _ of [].constructor(3)">
                    <app-shimmer-loading [loading]="true" [width]="'100%'"></app-shimmer-loading>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="mt-5" *ngIf="!dataLoading">
        <div class="professional-table overflow-x-auto">
            <table class="table w-full text-left mb-10" aria-describedby="professionalTable">
                <thead class="bg-gray-400 text-blue text-md text-regular">
                    <tr>
                        <th class="p-4 whitespace-normal" scope="row">Invoice Number</th>
                        <th class="p-4 whitespace-normal" scope="row">Invoice Date</th>
                        <th class="p-4 whitespace-normal" scope="row">
                            No of Clients
                        </th>
                        <th class="p-4 whitespace-normal" scope="row">
                            Total Invoice Amount
                        </th>
                        <th class="p-4 whitespace-normal" scope="row">Payment Status</th>
                        <th class="p-4 whitespace-normal" scope="row"> Payment Date</th>
                        <th class="p-4 whitespace-normal" scope="row"> Payment Amount</th>
                    </tr>
                </thead>
                <tbody *ngFor="let invoice of invoiceList; let i = index">
                    <tr class="border-b-2 border-fuchsia-600 p-4 relative">
                        <!-- Invoice Number -->
                        <td class="p-4 whitespace-normal">
                            <p class="text-grey text-regular text-md">
                                {{ invoice["invoice_number"] }}
                            </p>
                        </td>
                        <!-- Invoice Date -->
                        <td class="p-4 whitespace-normal">
                            <p class="text-grey text-regular text-md">
                                {{ invoice["created_at"] | date:dateFormat }}
                            </p>
                        </td>
                        <!-- Total Clients -->
                        <td class="p-4">
                            <div class="flex items-center">
                                <div>
                                    <img src="assets/images/dashboard/table-user.svg" class="" alt="digital vault" />
                                </div>
                                <div class="pl-3">
                                    <p class="text-grey text-regular tracking-wide">
                                        {{ invoice["vault_count"] ?? 'N/A'}}
                                    </p>
                                </div>
                            </div>
                        </td>
                        <!-- Total Invoice Amount -->
                        <td class="p-4 whitespace-normal">
                            <p class="text-grey text-regular text-md">
                                {{ '$' + getTotalAmount(invoice) }}
                            </p>
                        </td>


                        <!-- Payment Status -->
                        <td class="p-4">
                            <a *ngIf="invoice.status === 0" (click)="onClickPayNow(invoice)"
                                class="text-blue-900 text-semibold text-md underline cursor-pointer text-md lg:text-lg text-regular">Pay
                                Now</a>
                            <a *ngIf="invoice.status === 1"
                                class="text-green-900 text-semibold text-md text-md lg:text-lg text-regular">Paid</a>
                            <a *ngIf="invoice.status === 2"
                                class="text-red-500 text-semibold text-md  text-md lg:text-lg text-regular">Missed
                                Payment</a>
                        </td>
                        <!-- Payment Date -->
                        <td class="p-4 whitespace-normal">
                            <p *ngIf="invoice?.status === 0" class="text-grey text-regular text-md">
                                Pending Payment
                            </p>
                            <p *ngIf="invoice?.status === 1" class="text-grey text-regular text-md">
                                {{invoice["updated_at"] | date:dateFormat}}
                            </p>
                            <p *ngIf="invoice?.status === 2" class="text-grey text-regular text-md">
                                Missed Payment
                            </p>
                        </td>
                        <!-- Payment Amount -->
                        <td class="p-4 whitespace-normal">
                            <p *ngIf="invoice?.status === 0" class="text-grey text-regular text-md">
                                Pending Payment
                            </p>
                            <p *ngIf="invoice?.status === 1" class="text-grey text-regular text-md">
                                {{'$' + invoice["vault_amount"]}}
                            </p>
                            <p *ngIf="invoice?.status === 2" class="text-grey text-regular text-md">
                                Missed Payment
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="flex justify-center mt-5 bg-card-overview border-card-grey rounded"
                *ngIf="invoiceList?.length === 0">
                <div class="my-4">
                    <h4 class="text-base text-regular tracking-wide text-semibold  text-center">
                        No Data to Display
                    </h4>
                </div>
            </div>
            <!-- Paginations -->
            <div class="pt-8" *ngIf="invoiceList?.length !== 0">
                <nav class="flex flex-row flex-nowrap justify-between md:justify-center items-center"
                    aria-label="Pagination">
                    <a *ngIf="currentPage > 1" (click)="getInvoiceList( currentPage > 1 ? currentPage-1 : 0 )"
                        class="flex w-7 h-7 mr-1 justify-center items-center text-black cursor-pointer "
                        title="Previous Page">
                        <span class="sr-only">Previous Page</span>
                        <img src="assets/images/dashboard/left-arrow-enable.png" alt="digital vault" />
                    </a>
                    <a *ngIf="currentPage === 1" class="flex w-7 h-7 mr-1 justify-center items-center text-black "
                        title="Previous Page">
                        <span class="sr-only">Previous Page</span>
                        <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                        <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                    </a>
                    <ng-container *ngFor="let page of paginationLinks;let i = index;">
                        <a *ngIf=" i <= lastPage && i > 0 " (click)="getInvoiceList(i)"
                            [ngClass]="{ 'bg-darkblue' : currentPage === i , 'text-white' : currentPage === i}"
                            class=" md:flex w-7 h-7 mx-1 justify-center items-center  text-black text-center cursor-pointer "
                            title="Page {{i}}">
                            {{i}}
                        </a>
                    </ng-container>
                    <a (click)="getInvoiceList( currentPage + 1 )" *ngIf="(currentPage) <  lastPage"
                        class="flex w-7 h-7 ml-1 justify-center items-center text-black cursor-pointer "
                        title="Next Page">
                        <span class="sr-only">Next Page</span>
                        <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                        <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                    </a>
                    <a *ngIf="(currentPage) ===  lastPage"
                        class="flex w-7 h-7 ml-1 justify-center items-center text-black " title="Next Page">
                        <span class="sr-only">Next Page</span>
                        <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                        <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                    </a>
                </nav>
            </div>
        </div>
    </div>
</div>