import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { CommonHelper } from 'src/app/helper/common.helper';
import { requireCheckboxesToBeCheckedValidator } from 'src/app/helper/customValidatorCheckbox.helper';
import { ICountryCode } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { ConsumerLandingPageService } from 'src/app/services/consumer-landing-page.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { EMAIL_PATTERN, NAME_PATTERN, PHONE_PATTERN } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../../../helper/validation.helper';



@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
})
/**
 * contact us class
 */
export class ContactUsComponent implements OnInit {
  public mobile: boolean;
  public innerWidth: number;
  public contactUsProForm: FormGroup;
  public typesList: Array<any>;
  public countryCode: ICountryCode;
  public bsConfig: Partial<BsDatepickerConfig>;
  public preDefinedLoader: boolean;
  public submitLoader: boolean;
  public minDate: Date;
  public domainDateFormat = this.commonHelper.domainDateFormat;
  public professionalType: string;
  public customerSupportMail:string;
  public customerSupportPhone: number;
  public vaultVideo: string;
  public isScheduleDemo: boolean = false;

  @ViewChild('contactForm', { static: true }) contactForm: ElementRef;

  /**
   * form array getter for validation and showing errors in html
   */
  public get formArrayGet() {
    return this.contactUsProForm.controls.type as FormArray;
  }
  /**
   * constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private landingPageService: ConsumerLandingPageService,
    private toastr: ToastrService,
    private commonHelper: CommonHelper,
    private commonService : CommonService,
    private localStorageService: LocalStorageService,
    private validation: ValidationHelper,
    private modalService: CommonModelService,
    private route: ActivatedRoute
  ) {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.route.queryParams.pipe(map(param => param?.source)).subscribe((data)=>{
      if(data){
        this.isScheduleDemo = true;
      }else{
        this.isScheduleDemo = false;
      }
      if (this.typesList?.length > 0) {
        const demoIndex = this.typesList?.length - 1;
        this.formArrayGet?.controls[demoIndex]?.setValue(this.isScheduleDemo)
      }
    })
  }
  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.getContactDetails();
    // const formScript = '<script type="text/javascript" src=https://inheritnowsubmission.formstack.com/forms/js.php/notifynow_us_creditor_form_copy_1_copy></script>'
    // postscribe(this.contactForm.nativeElement, formScript);
    this.professionalType = this.localStorageService.getDataByKey('pro-type')?.[0]?.displayText;
    console.log(this.professionalType);

    this.preDefinedLoader = false;
    this.onResize();
    this.typesList = [];
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    this.contactUsProForm = this.formBuilder.group({
      first_name: ['', { validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim] }],
      middle_name: ['', { validators: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim] }],
      last_name:  ['', { validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim] }],
      company_name: ['', { validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim] }],
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      phone: ['', { updateOn: 'blur', validators: [Validators.pattern(PHONE_PATTERN)] }],
      description: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]],
      type: new FormArray([], requireCheckboxesToBeCheckedValidator()),
      // date_time: ['', [Validators.required]],
    });
    this.validation.setValidationErrors({
      phone: { pattern: 'Please enter a valid phone number' },
      email: { pattern: 'Please enter a valid email address.' },
    });
    this.landingPageService.getContactUsTypes().subscribe((response: APIResponseModel) => {
      this.typesList = response.data;
      if (this.typesList.length > 0) {
        this.addCheckboxes();
      }
    });
    this.bsConfig = Object.assign({}, {
      showWeekNumbers: false,
      // showTodayButton: true,
      // showClearButton: true,
      minDate: this.minDate,
      isAnimated: true,
      adaptivePosition: true,
      withTimepicker: true,
      rangeInputFormat: this.domainDateFormat + ', h:mm:ss a',
      dateInputFormat: this.domainDateFormat + ', h:mm:ss a',
    });
  }
  /**
   * window resize
   * @param {any} event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.innerWidth = window.innerWidth;

    this.mobile = this.innerWidth <= 767;
  }
  /**
   * form object getter for validation and showing errors in html
   */
  public get formGet() {
    return this.contactUsProForm.controls;
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.contactUsProForm);
  }



  /**
   * add checkboxes arrray to form
   */
  private addCheckboxes() {
    if (this.typesList.length > 0) {
      const demoIndex = this.typesList.length - 1;
      this.typesList?.forEach((data, index) => this.formArrayGet.push(new FormControl((index == demoIndex && this.isScheduleDemo) ? true : false)));
    }
  }
  /**
   * on form submit
   */
  public onSubmit() {
    this.submitLoader = true;
    const selectedOrderIds = this.contactUsProForm.value.type
      .map((checked, i) => checked ? this.typesList[i].id : null)
      .filter((v) => v !== null);
    const payLoad = {
      ...this.contactUsProForm.value,
      user_type: 'Professional',
      type: selectedOrderIds,
      country_code: this.countryCode.dialCode,
      flag_code: this.countryCode.code,
      // date_time: this.contactUsProForm.value.date_time,
    };
    this.landingPageService.sendContactUsData(payLoad).subscribe((response: APIResponseModel) => {
      this.submitLoader = false;
      this.toastr.success(response.message);
      this.contactUsProForm.reset({

      });
      this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    },
      (exception: any) => {
        this.submitLoader = false;
        this.contactUsProForm.reset({

        });
        this.countryCode = environment.DEFAULT_COUNTRY_CODE;
        this.commonHelper.httpResponseHandler(exception?.error);
      },
    );
  }

  public getContactDetails(){
    this.commonService.getContactUsDetails().subscribe((response)=>{
      this.customerSupportMail =response.data?.support_email
      this.customerSupportPhone = response.data?.support_number;
    })
  }


  /**
   * Detect change on phone number component
   *
   * @param event
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }

    /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');
  }
}
