import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PipeModule } from 'src/app/pipes/pipes.module';
import { ExeCusNavbarComponent } from './exe-cus-navbar.component';


@NgModule({
  declarations: [ExeCusNavbarComponent],
  imports: [CommonModule, RouterModule, FontAwesomeModule, PipeModule],
  exports: [ExeCusNavbarComponent],
})
/**
 * Executor Custodian Module */
export class ExeCusNavbarModule { }
