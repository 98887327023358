import { CurrencyPipe } from '@angular/common';
import { AfterContentChecked, Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { UserService } from 'src/app/services/user.service';
import { ConsumerPaymentMethod, LOCAL_STORAGE_UPDATE_STATUS, USER_TYPES } from 'src/constants/application.const';
import { CommonHelper } from '../../helper/common.helper';
import { SlugInterceptorService } from '../../helper/slug-interceptor.service';
import { CommonService } from '../../services/common.service';
import { CheckoutService } from '../checkout/checkout.service';
import { PaymentOption } from '../checkout/payment-option.model';


@Component({
  selector: 'app-vault-service-options',
  templateUrl: './vault-service-options.component.html',
})
export class VaultServiceOptions implements OnInit, AfterContentChecked {
  @Input() hidePaid: boolean = false;
  @Input() openTransferFeeModal: boolean = false;
  private hideIDs: string[] = [];
  private currencyPipe: CurrencyPipe = new CurrencyPipe(this.locale);
  private defaultDropdownStates: { [key: string]: boolean };
  downloadDigitalVault: boolean;
  paymentOptions: PaymentOption[] = [];
  loading: boolean = false;
  paymentMode: ConsumerPaymentMethod;


  @Input() set setOpen(open: { [key: string]: boolean }) {
    this.defaultDropdownStates = open;
    this.updateUI();
  }


  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    private commonService: CommonService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private checkoutService: CheckoutService) {
  }

  ngOnInit(): void {
    this.getPaymentOptions();
    this.commonService.paymentStatusUpdateSubscriberObservable.subscribe((data: boolean)=>{
      if(data && typeof data === 'boolean') {
        this.getPaymentOptions();
        this.commonService.paymentUpdate(false);
      }
    })

    // download_digital_vault 1 is true, 0 is false
    this.downloadDigitalVault = this.commonHelper.getAdminSettings()?.download_digital_vault == 1;
  }

  ngAfterContentChecked() {
    const transferPaidAmount = this.localStorageService.getUserData().user?.paid_transfer_discount;
    const subscriptionPaidAmount = this.localStorageService.getUserData().user?.paid_subscription_discount;
    this.paymentOptions.map(e => {
      if (e.id == 'dataLinkFee') {
        e.feePaid = this.localStorageService.getDataByKey('is_transfer_amount_paid') == 1;
        e.fee = e.feePaid ? transferPaidAmount : e.fee;
      } else if (e.id == 'subscription_monthly') {
        e.feePaid = this.localStorageService.getDataByKey('is_payment_complete') == 1;
        e.fee = e.feePaid ? subscriptionPaidAmount : e.fee;
      }
    });
  }

  /**
   * {@link CommonService.getContactUsDetails getContactUsDetails}
   */
  private getPaymentOptions() {
    this.loading = true;
    forkJoin([this.checkoutService.getPaymentOptions(), this.commonService.getContactUsDetails()]).subscribe({
      next: results => {
        const getPaymentOptions = results[0];
        const { download_digital_vault } = results[1].data;
        this.paymentOptions = getPaymentOptions;
        if(this.paymentOptions.length) {
          this.paymentOptions[0].dropdownToggle = true;
        }
        const isDownloadDV: boolean = (download_digital_vault == 1);
        if (!isDownloadDV) {
          this.hideIDs.push('dataLinkFee');
        }
        this.loading = false;
        this.updateUI();
      },
      error: e => this.commonHelper.httpResponseHandler(e.error),
    });
  }

  /**
   * Perform UI state updates
   * @private
   */
  private updateUI() {
    // Default Dropdown States
    if (this.defaultDropdownStates) {
      Object.keys(this.defaultDropdownStates).forEach(id => {
        const option = this.getOption(id);
        if (option) {
          this.getOption(id).dropdownToggle = this.defaultDropdownStates[id];
        }
      });
    }
    this.paymentMode = this.localStorageService.getDataByKey('payment_mode');
    // Update paid amount
    this.updatePaidAmount();
  }

  /**
   * Update payment
   */
  private updatePaidAmount(): void {
    this.userService.getCurrentUserDetails().subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          let updateAmountPaid = [{
            key: 'user',
            updateValue: response.data.paid_subscription_discount,
            updateKey: 'paid_subscription_discount',
            type: LOCAL_STORAGE_UPDATE_STATUS.O,
          },
            {
              key: 'user',
              updateValue: response.data.paid_transfer_discount,
              updateKey: 'paid_transfer_discount',
              type: LOCAL_STORAGE_UPDATE_STATUS.O,
            }];
          updateAmountPaid.forEach(e => {
            this.localStorageService.updateUserData(USER_TYPES.user, Object(e));
          });
        }
      },
    });
  }

  /**
   * Get the {@link PaymentOption} matching the given id.
   * @param id
   * @private
   */
  private getOption(id: string) {
    return this.paymentOptions?.find(e => e.id == id);
  }

  /**
   * Get the source for either up arrow or down arrow image based on {@link PaymentOption.dropdownToggle option}.<br>
   * If `true` up, else down.
   * @param option
   */
  getArrowSRC(option: PaymentOption): string {
    return `assets/images/dashboard/angle-${option.dropdownToggle ? 'up' : 'down'}-arrow.png`;
  }

  /**
   * If {@link open} is not provided, invert the value of {@link PaymentOption.dropdownToggle option}.<br>
   * Else, set the value of {@link PaymentOption.dropdownToggle option} to {@link open}.
   * @param option
   * @param open
   */
  toggleSection(option: PaymentOption, open?: boolean) {
    option.dropdownToggle = open ?? !option.dropdownToggle;
  }

  /**
   * Get selected {@link PaymentOption PaymentOptions}
   */
  get getSelectedOptions(): PaymentOption[] {
    return this.paymentOptions.filter(e => e.selected);
  }

  /**
   * Get the total fee for each selected option.
   */
  get getTotal(): number {
    let total: number = 0;
    this.getSelectedOptions.forEach(e => total += e.fee);
    return total;
  }

  /**
   * Sets selected items to the cart in {@link CheckoutService}, then navigates to checkout.<br>
   */
  payNow(): void {
    const ids: string[] = [];
    if (this.paymentOptions.length == 1) { // First is automatic if it is the only one.
      ids.push(this.paymentOptions[0].id);
    } else { // Else only use selected items.
      this.paymentOptions.forEach(e => e.selected ? ids.push(e.id) : null);
      const getSubscriptionOption = this.paymentOptions.find(e => e.id == 'subscription_monthly' || e.id == 'subscription_yearly');
      if(!getSubscriptionOption?.selected  && !getSubscriptionOption.feePaid && this.paymentMode !== ConsumerPaymentMethod.PAID_BY_PROFESSIONAL) {
        getSubscriptionOption.requiredToSelect = true;
        return;
      }
    }
    this.checkoutService.setCart(ids);
    void this.slugInterceptorService.navigate(['checkout']);
  }

  /**
   * Whether to show the checkbox for the given {@link PaymentOption}.
   * @param option
   */
  showCheckbox(option: PaymentOption): boolean {
    return this.paymentOptions.length > 1 && !option.feePaid && !option.paymentInProgress && !!option.fee;
  }
  /**
   * Show yet to paid by professional text
   * @param option 
   * @returns true if yet to paid text 
   */
  showYetToPaidText(option: PaymentOption): boolean {
    return this.paymentMode == ConsumerPaymentMethod.PAID_BY_PROFESSIONAL && !option.feePaid && !option.paymentInProgress && (option.id !== 'dataLinkFee')
  }

  /**
   * checks only for company paying case, whether to show pay button or not
   */
  get paidSubscription(): boolean {
    return !!(!this.paymentOptions.find(e => e.disable && e.id == 'dataLinkFee'));
  }

  /**
   * Returns the string to use for the 'fee' location for the given {@link PaymentOption}.<br>
   * If the option is paid, returns 'Paid!'.<br>
   * If payment is 'in-progress', returns 'Payment In Progress'.<br>
   * If neither previous condition is tree, it returns the fee formatted as currency.
   * @param option
   */
  getFeeString(option: PaymentOption): string {
    let fee: string = this.currencyPipe.transform(option.fee);
    if (option.paymentInProgress) {
      return 'Payment In Progress!';
    }
    if (option.Recurring) {
      fee = `${fee} <span class="text-grey text-sm font-light">${option.RecurringPeriod}</span>`;
    }
    return fee;
  }

  hideOption(option: PaymentOption): boolean {
    return !!this.hideIDs.find(e => option.id == e);
  }
}


