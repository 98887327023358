<app-common-modal id="{{modalID}}" (commonModalEmitter)="cmListener($event)" class="app-common-modal">

  <div class="relative">
    <img src="assets/images/about-yourself/close.svg" (click)="closeModal()" alt=""
         class="absolute right-2 w-8 -mt-4 -mr-6 shadow-md shadow-red-300 rounded-full cursor-pointer z-10">
    <div *ngIf="handoff">
      <div class="loading-red-free m-auto h-24 w-24"></div>
    </div>
    <div *ngIf="!handoff" class="relative">
      <div class="flex flex-row">
        <h4 class="text-regular text-base md:text-lg text-darkgrey font-semibold tracking-wide w-11/12 sm:w-auto">
          How do you want to enter your {{ assetType }} information?

          <a class="inline-block mr-2" (click)="openModal('plaidUs')" title='Click here for help from Aida'>
            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1n text-primary cursor-pointer">
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
              <line x1="12" y1="17" x2="12.01" y2="17"></line>
            </svg>
          </a>
        </h4>
      </div>
      <ng-container *ngIf="!hasPaidSubscription && !clientHasPaidSubscription; else plaidButton">
        <button
          *ngIf="plaidKey && canAccessForm"
          (click)="clickPlaid()"
          class="mt-5 p-5 tracking-wide w-full bg-transparent border-red rounded-xs focus:outline-none text-darkblue px-6 relative">
          <div class="flex w-full justify-center">
            <div class="font-semibold text-regular text-base">Please <em class="text-regular">pay</em> to unlock importing via Plaid</div>
            <div *ngIf="buttonLoaderPlaid" class="loading-red block ml-2"></div>
          </div>
          
        </button>
        <button
          (click)="clickManual()"
          type="button"
          class="mt-5 w-full bg-darkblue rounded-xs p-5 focus:outline-none text-white px-6 tracking-wide relative">
          <div class="flex w-full justify-center">
            <div class="font-semibold text-regular text-base">Enter {{ assetType }} details manually after completing <em class="text-regular">payment</em></div>
            <div *ngIf="buttonLoaderManual" class="loading-red block ml-2"></div>
          </div>

        </button>
      </ng-container>   
      <ng-template #plaidButton>
        <!-- Sandbox mode -->
        <button
          *ngIf="plaidKey && canAccessForm && canEdit"
          class="mt-5 p-5 font-semibold tracking-wide w-full bg-transparent border-red rounded-xs focus:outline-none text-regular text-darkblue px-6 text-base"
          type="button" ngxPlaidLink
          [env]="PLAID_ENV" [token]="this.plaidKey" institution="" [countryCodes]="['US']"
          (Success)="onPlaidSuccess($event)"
          (Event)="onPlaidEvent($event)"
          (Exit)="onPlaidExit($event)"
          (Load)="onPlaidLoad($event)"
          (click)="clickPlaid()">
          Import {{ assetType }} from Plaid
        </button>
        <button
        (click)="clickManual()"
        type="button"
        class="mt-5 w-full bg-darkblue rounded-xs p-5 focus:outline-none text-regular text-white px-6 text-base tracking-wide">
        Enter {{ assetType }} details manually
      </button>
      </ng-template>
    </div>
  </div>
</app-common-modal>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>

