import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardUser } from 'src/app/helper/authGuard.helper';
import { AuthGuardProfessional } from 'src/app/helper/authGuardProfessional.helper';
import { AboutYourselfViewComponent } from './about-yourself-view/about-yourself-view.component';
import { AboutYourselfComponent } from './about-yourself.component';
import { ChildrenStatusComponent } from './children/children-status.component';
import { LocationDetailsComponent } from './location-details/location-details.component';
import { NamingDetailsComponent } from './naming-details/naming-details.component';
import { PartnerComponent } from './partner/partner.component';
import { PhoneEmailComponent } from './phone-email/phone-email.component';

const routes: Routes = [
  {
    path: '',
    component: AboutYourselfComponent,
    canActivate: [AuthGuardUser],
    children: [
      { path: 'location-details', component: LocationDetailsComponent },
      { path: 'contact-details', component: PhoneEmailComponent },
      { path: 'partner', component: PartnerComponent },
      { path: 'children', component: ChildrenStatusComponent },
      { path: 'view', component: AboutYourselfViewComponent },
      { path: '', component: NamingDetailsComponent },
    ],
  },
  {
    path: '',
    component: AboutYourselfComponent,
    children: [
      { path: 'location-details/:id', component: LocationDetailsComponent, canActivate: [AuthGuardProfessional] },
      { path: 'contact-details/:id', component: PhoneEmailComponent, canActivate: [AuthGuardProfessional] },
      { path: 'partner/:id', component: PartnerComponent, canActivate: [AuthGuardProfessional] },
      { path: 'children/:id', component: ChildrenStatusComponent, canActivate: [AuthGuardProfessional] },
      { path: 'view/:id', component: AboutYourselfViewComponent, canActivate: [AuthGuardProfessional] },
      { path: ':id', component: NamingDetailsComponent, canActivate: [AuthGuardProfessional] },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
/**
 * about yourself routing module
 */
export class AboutYourselfRoutingModule {}
