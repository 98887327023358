<div class="flex justify-center loader " *ngIf="spinner">
  <div class="w-16 h-16  border-b-2 border-red rounded-full animate-spin mt-3 "></div>
</div>
<div class="scroll-hidden" *ngIf="!spinner">
  <div class="h-screen lg:grid grid-cols-12">
    <!-- BEGIN: Login Form -->
    <div class="col-span-12 md:col-span-6 xl:col-span-5 h-screen lg:h-auto p-6 flex">
      <div class="flex flex-col w-full">
        <!-- BEGIN: Logo -->
        <div class="md:pl-2 flex items-center gap-2 sm:gap-4 h-16 flex-row">
          <a (click)="onClickLogo()" class="flex items-center cursor-pointer">
            <img alt="The-Estate-Registry" width="250" [src]="'assets/images/logo-old.png'"
              class="object-contain w-40 sm:w-48">
          </a>
          <a *ngIf="orgLogoPath" class=" border-l-black pl-6 cursor-pointer max-h-12 maxw128">
            <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
          </a>
          <!-- <div class="flex items-start gap-1 md:gap-2 flex-col" *ngIf="orgLogoPath">
            <p class=" whitespace-nowrap text-dark-grey text-regular">Sponsored by: </p>

            <a *ngIf="orgLogoPath" class="cursor-pointer max-h-12 maxw128">
              <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">

            </a>
          </div> -->
        </div>
        <!-- END: Logo -->
        <div class="h-full flex items-center justify-center mt-4">
          <div class="my-auto mx-auto bg-white pt-12 sm:pl-10 xl:bg-transparent px-5 sm:px-8 w-full">
            <div class="h-full justify-center items-center ">
              <h2 class="font-bold text-darkblue text-lg text-regular text-left">
                LegacyNOW Digital Vault
              </h2>

              <hr class="border-blue w-20 mt-1 border-1">
              <div class="mt-4">

                <h2 class="text-darkblue text-4xl text-semibold pt-1">Welcome to<br>
                  LegacyNOW
                </h2>
                <p class="text-regular text-grey text-base pt-3">This link is intended for <span class="font-medium">{{clientName}}</span> at <span class="font-medium break-all">{{clientEmail}}</span>. If this
                  does not match your information, please do not proceed and contact us here
                  <a href="mailto:{{customerSupportMail}}." class="font-semibold hover:underline hover:text-orange-600">{{customerSupportMail}}</a>.
                </p>
                <p class="text-regular text-grey text-base pt-3">Create a secure password for your LegacyNOW Digital Vault account with <span class="font-medium">{{firmName}}</span>.
                </p>
              </div>
              <form [formGroup]="setPasswordForm" autocomplete="off" (ngSubmit)="setNewPassword()">

                <div class="mt-4">
                  <label for="password" class="text-sm pb-2 text-regular text-grey">
                    Password</label>
                  <div class="relative h-12 text-grey z-10">
                    <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
                      (click)="togglePasswordView = !togglePasswordView">
                      <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
                      <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
                    </a>
                    <input [type]="togglePasswordView?'text':'password'" name="password" formControlName="password"
                      id="password"
                      class="input py-3 px-4 mt-1 w-full text-regular text-grey rounded-xs h-12 appearance-none pl-4 border block focus:outline-none"
                      placeholder="Enter Password"
                      [ngClass]="formGet.password.errors && formGet.password.touched?'form-input-error':'form-input-focus'">
                    <div *ngIf="formGet.password.errors && formGet.password.dirty" class="z-100">
                      <div *ngIf="formGet.password.errors.required">
                        <p class="text-regular font-medium text-xs mt-2 error-alert">Password is required</p>
                      </div>
                      <div *ngIf="!formGet.password.errors.required"
                        class="absolute border mt-4 rounded bg-white p-2 text-red-700 border-3 w-full rounded-xs border block focus:outline-none">

                        <p [ngClass]="!formGet.password.errors.minlength? 'green-error-text':'red-error-text'">
                          <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                            alt="digital vault" *ngIf="!formGet.password.errors.minlength">
                          <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                            alt="digital vault" *ngIf="formGet.password.errors.minlength">
                          Minimum at least 8 characters.
                        </p>

                        <p
                          [ngClass]="formGet.password.errors?.passwordStrength?.hasNumeric? 'green-error-text':'red-error-text'">
                          <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                            alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.hasNumeric">
                          <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                            alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.hasNumeric">
                          At least one number (0-9).
                        </p>

                        <p
                          [ngClass]="formGet.password.errors?.passwordStrength?.hasLowerCase? 'green-error-text':'red-error-text'">
                          <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                            alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.hasLowerCase">
                          <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                            alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.hasLowerCase">
                          At least one lowercase letter (a-z).
                        </p>

                        <p
                          [ngClass]="formGet.password.errors?.passwordStrength?.hasUpperCase? 'green-error-text':'red-error-text'">
                          <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                            alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.hasUpperCase">
                          <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                            alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.hasUpperCase">
                          At least one uppercase letter (A-Z).
                        </p>

                        <p
                          [ngClass]="formGet.password.errors?.passwordStrength?.hasSpecialCharacters? 'green-error-text':'red-error-text'">
                          <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                            alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.hasSpecialCharacters">
                          <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                            alt="digital vault"
                            *ngIf="!formGet.password.errors?.passwordStrength?.hasSpecialCharacters">
                          At least one special character (!@#$%^&*).
                        </p>

                      </div>
                    </div>

                    <!-- <div *ngIf="formGet.password.errors && formGet.password.touched">
                    <div *ngIf="formGet.password.errors && formGet.password.touched" class="z-100">
                      <div *ngIf="formGet.password.errors.required">
                        <p class="text-regular font-medium text-xs mt-2 error-alert">Password is required</p>
                      </div>
                      <div *ngIf="!formGet.password.errors.required"
                        class="absolute border mt-4 rounded bg-white p-2 text-red-700 border-3 w-full rounded-xs border block focus:outline-none">

                        <p [ngClass]="!formGet.password.errors.minlength? 'green-error-text':'red-error-text'">
                          <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                            alt="digital vault" *ngIf="!formGet.password.errors.minlength">
                          <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                            alt="digital vault" *ngIf="formGet.password.errors.minlength">
                          Minimum at least 8 characters.
                        </p>

                        <p
                          [ngClass]="formGet.password.errors?.passwordStrength?.hasNumeric? 'green-error-text':'red-error-text'">
                          <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                            alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.hasNumeric">
                          <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                            alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.hasNumeric">
                          At least one number (0-9).
                        </p>

                        <p
                          [ngClass]="formGet.password.errors?.passwordStrength?.hasLowerCase? 'green-error-text':'red-error-text'">
                          <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                            alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.hasLowerCase">
                          <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                            alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.hasLowerCase">
                          At least one lowercase letter (a-z).
                        </p>

                        <p
                          [ngClass]="formGet.password.errors?.passwordStrength?.hasUpperCase? 'green-error-text':'red-error-text'">
                          <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                            alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.hasUpperCase">
                          <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                            alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.hasUpperCase">
                          At least one uppercase letter (A-Z).
                        </p>

                        <p
                          [ngClass]="formGet.password.errors?.passwordStrength?.hasSpecialCharacters? 'green-error-text':'red-error-text'">
                          <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                            alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.hasSpecialCharacters">
                          <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                            alt="digital vault"
                            *ngIf="!formGet.password.errors?.passwordStrength?.hasSpecialCharacters">
                          At least one special character (!@#$%^&*).
                        </p>

                      </div>
                    </div>

                    <-- <div *ngIf="formGet.password.errors && formGet.password.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.password.errors.minlength">Password must be at least 6 characters</p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.password.errors.maxlength">Password must not exceed 12 characters</p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.password.errors.required">Password is required</p>
                    </div> -->
                  </div>
                </div>
                <div class="mt-4">
                  <label for="passwordc" class="text-sm pb-2 text-regular text-grey">
                    Confirm Password</label>
                  <div class="relative h-12 text-grey">
                    <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
                      (click)="togglePasswordViewc = !togglePasswordViewc">
                      <fa-icon [icon]="faEye" *ngIf="togglePasswordViewc"></fa-icon>
                      <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordViewc"></fa-icon>
                    </a>
                    <input [type]="togglePasswordViewc?'text':'password'" name="password_confirmation"
                      formControlName="password_confirmation" id="passwordc"
                      class="input py-3 px-4 mt-1 w-full text-regular text-grey rounded-xs h-12 appearance-none pl-4 border block focus:outline-none"
                      placeholder="Enter Password"
                      [ngClass]="formGet.password_confirmation.errors && formGet.password_confirmation.touched?'form-input-error':'form-input-focus'">
                    <div *ngIf="formGet.password_confirmation.errors && formGet.password_confirmation.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.password_confirmation.errors.minlength">Confirm password must be at least 8
                        characters</p>
                      <p class="text-regular font-medium text-xs my-2 error-alert"
                        *ngIf="formGet?.password_confirmation?.errors?.confirmedValidator">Your
                        Password and Confirm Password must match.
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.password_confirmation.errors.required">Confirm password is required</p>
                    </div>
                  </div>
                </div>

                <!-- BEGIN: Form Register Button -->
                <div class="mt-6 md:flex md:flex-row text-center flex-col-reverse items-center">
                  <div class="ml-auto mt-4 md:mt-0">
                    <app-button-loader [buttonText]="'Submit'" [valid]="setPasswordForm.valid" [loader]="submitLoader"
                      [buttonType]="'submit'"></app-button-loader>
                  </div>
                </div>
                <!-- END: Form Register Button -->

              </form>

            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- END: Login Form -->

    <!-- BEGIN: Login Info -->
    <div class="md:col-span-6 xl:col-span-7 hidden lg:flex bg-login-grey min-h-screen justify-center">
      <div class="fixed h-full flex flex-col items-center justify-center">
        <!-- BEGIN: First Slide -->
        <app-right-carousel-intro></app-right-carousel-intro>
      </div>
    </div>
    <!-- END: Login Info -->
  </div>
</div>