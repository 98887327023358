import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { AssetsService } from 'src/app/services/assets.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { SelectWithSearchComponent } from 'src/app/sharedComponent/select-with-search/select-with-search.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-agent-appointment-popup',
  templateUrl: './agent-appointment-popup.component.html',
})
/**
 * Add appointment with agent popup component
 */
export class AgentAppointmentPopupComponent implements OnInit {
  @ViewChild(SelectWithSearchComponent) dropdownRef: SelectWithSearchComponent;
  @Output() toggleModalEmitter = new EventEmitter<any>();
  public appointmentForm: FormGroup;
  public submitLoader: boolean;
  public locale = 'en';
  public minDate: Date;
  public userType: string;
  public countryCode: string; //  in future should be embeded with form itself
  public advisorArray: Array<SelectOptionsInterface>;
  public bsConfig: Partial<BsDatepickerConfig>;
  public preDefinedLoader: boolean;
  public agentTerm: string;
  public domainDateFormat = this.commonHelper.domainDateFormat;


  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private formBuilder: FormBuilder,
    private assetService: AssetsService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private personalDetailsService: PersonalDetailsService,
    private localeService: BsLocaleService,
    private commonService: CommonService,
  ) {
    this.minDate = new Date();
    this.minDate.setHours(this.minDate.getHours() + 2);
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.preDefinedLoader = false;
    this.countryCode = environment.DEFAULT_COUNTRY_DIALCODE;
    this.userType = this.localStorageService.getDataByKey('role');
    this.appointmentForm = this.formBuilder.group({
      agent_id: ['', { validators: [Validators.required] }],
      appointmentDate: ['', { updateOn: 'blur', validators: [Validators.required, this.futureDateValidator.bind(this)] }],
    });
    this.getAdvisorDetails();
    this.applyLocale();
    this.bsConfig = Object.assign({}, {
      showWeekNumbers: false,
      minDate: this.minDate,
      adaptivePosition: true,
      withTimepicker: true,
      rangeInputFormat: this.domainDateFormat + ', h:mm a',
      dateInputFormat: this.domainDateFormat + ', h:mm a',
    });
  }


  /**
   * add agent appointment
   */
  public onSubmit(): void {
    if (this.appointmentForm.invalid) {
      this.commonHelper.scrollToFirstInvalidControl(this.appointmentForm);
      this.appointmentForm.markAllAsTouched();
      return;
    }
    const user: Object = this.localStorageService.getUserData()?.user;
    const userData: Object = {
      name: user['full_name'],
      email: user['email'],
      contact_number: user['phone'],
    };

    this.submitLoader = true;
    const payload = {
      agent_id: this.appointmentForm.value.agent_id,
      ...userData,
      date_time: this.commonHelper.dateformat(this.appointmentForm.value.appointmentDate),
    };
    this.commonService.scheduleAppointment(payload).subscribe((response: APIResponseModel) => {
      this.submitLoader = false;
      if (response.status) {
        this.commonHelper.toastrAppointmentSuccess();
        this.resetForm();
        this.closeModal();
      }
    }, (exception: any) => {
      this.submitLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }


  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }


  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.appointmentForm.controls;
  }


  /**
   * close modal
   */
  public closeModal(): void {
    this.dropdownRef.clearSelectedOption();
    this.appointmentForm.reset();
    this.modalService.close('agent-appointment-modal');
  }


  /**
   * change listner for agent
   * @param {SelectOptionsInterface}selectedOption
   */
  public optionChangeEmitterAgent(selectedOption: SelectOptionsInterface): void {
    if (selectedOption) {
      this.appointmentForm.patchValue({ agent_id: selectedOption.value });
    }
  }


  /**
   * get advisor list
   */
  public getAdvisorDetails(): void {
    this.personalDetailsService.getPersonalDetails().subscribe((response: APIResponseModel) => {
      if (response.status) {
        let responseAdvisorArray: Array<Object> = response?.data?.user_people?.filter((user) => {
          return user.advisor === 1 && user.professional_id;
        });
        responseAdvisorArray = this.personalDetailsService.processUserPeople(responseAdvisorArray);

        this.advisorArray = this.commonHelper.convertToOptionsFormat(
          responseAdvisorArray,
          'professional_id',
          'full_name',
          'email',
        );
      }
      this.localStorageService.storeData('advisorArray', this.advisorArray?.length);
    }, (exception: any) => {
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }


  /**
   * Reset appointment Form
   */
  public resetForm(): void {
    this.appointmentForm.reset();
  }


  /**
   * Mark selected from list
   */
  public markAgentType() {
    this.formGet['agent_id'].markAsTouched({
      onlySelf: true,
    });
  }


  /**
   * Validator for a valid future time  that is 2 hours ahead from now. If the selected time is less than or equal to the current Date and  current time plus two hours, then it
   * will return the error <code>invalid_time</code>
   * @param {AbstractControl} control
   * @return {ValidationErrors | null}
   */
  private futureDateValidator(control: AbstractControl): ValidationErrors | null {
    if (control?.value) {
      const timeApt: number = Date.parse(control.value);
      return timeApt < this.minDate.valueOf() ? { invalid_time: true } : null;
    }
    return null;
  }
}
