import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { ICountryCode, SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PropertyService } from 'src/app/services/property.service';
import { APARTMENT_NUMBER_PATTERN, DATE_FORMAT, DONATION_TYPES, LIST_DELETE, LIST_NEW, LIST_UPDATE, NAME_PATTERN, NUMBERS_VALID_DOLLARS, PHONE_PATTERN, UNITED_KINGDOM, USER_NAME_WITH_SPECIAL_CHARACTERS_PATTERN, WEBSITE_PATTERN_NEW } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../helper/validation.helper';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-add-donation',
  templateUrl: './add-donation.component.html',
})
/**
 * add donation popup
 */
export class AddDonationComponent implements OnInit {
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;
  @Output() toggleModalEmitter = new EventEmitter<any>();
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() isNewAsset: boolean = true;
  @Input() clientHasPaidSubscription:boolean = false;
  public donationForm: FormGroup;
  public readonly donationTypes: Array<any> = DONATION_TYPES;
  public readonly faEye: any = faEye;
  public readonly faEyeSlash: any = faEyeSlash;
  public readonly unitedKingdom: string = UNITED_KINGDOM;
  public submitLoaderDelete: boolean;
  public preDefinedLoader: boolean;
  public submitLoader: boolean;
  public userType: string;
  public clientRequestId: string;
  public countryCode: ICountryCode = environment.DEFAULT_COUNTRY_CODE;
  public defaultCountry: number;
  public selectedCountry: number = environment.APP_DOMAIN;
  public selectedCountryName: string = '';
  public domainDateFormat: any;
  public locale = 'en';
  public maxDate: Date;
  public bsConfig: Partial<BsDatepickerConfig>;
  public frequencyTypeId: string;
  public frequencyTypeArray: Array<SelectOptionsInterface>;
  public togglePasswordView: boolean;
  public isFrequentDonation: boolean;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public elementRefSet: boolean = false;
  private hasPaidSubscription: boolean;
  public searchElementRef: ElementRef;

  @ViewChild('search', { static: false })
  set setSearchElementRef(value: ElementRef) {
    this.searchElementRef = value;
    if (this.searchElementRef && !this.elementRefSet) { // if not elementRef set initial value for searchElement
      this.updateAddressPacContainer(this.defaultCountry);
      this.elementRefSet = true;
    }
  }

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private propertyService: PropertyService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private addressHelper: AddressHelper,
    private ngZone: NgZone,
    private toastrService: ToastrService,
    private localeService: BsLocaleService,
    private subscriptionService: ConsumerSubscriptionService,
    private validation: ValidationHelper,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.hasPaidSubscription = r);
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.domainDateFormat = this.commonHelper.domainDateFormat;
    this.submitLoaderDelete = false;
    this.togglePasswordView = false;
    this.clientRequestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    this.updateAddressPacContainer(this.defaultCountry);
    this.initForm();
    this.isEnableState();
    this.applyLocale();
    this.bsConfig = Object.assign({}, {
      showWeekNumbers: false,
      adaptivePosition: true,
      maxDate: this.maxDate,
      dateInputFormat: this.domainDateFormat,
    });
    this.getFrequencyList();
    if (!this.canAccessForm) {
      this.donationForm.disable();
    }
  }

  private initForm() {
    this.donationForm = this.formBuilder.group({
      donation_type: ['', { updateOn: 'blur', validators: [Validators.required] }],
      organization_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN)] }],
      phone: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(PHONE_PATTERN)] }],
      address: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(255)] }],
      apt_number: ['', { updateOn: 'blur', validators: [Validators.pattern(APARTMENT_NUMBER_PATTERN), Validators.maxLength(25)] }],
      city: ['', { updateOn: 'blur', validators: [Validators.required, this.validation.noWhitespaceValidator()] }],
      country: ['', { updateOn: 'blur', validators: [Validators.required] }],
      state: ['', [Validators.required]],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(10)] }],
      id: [''],
      date_of_donation: [''],
      amount: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
      frequency_id: [''],
      user_name: ['', { updateOn: 'blur', validators: [Validators.minLength(3), Validators.maxLength(30), Validators.pattern(USER_NAME_WITH_SPECIAL_CHARACTERS_PATTERN)] }],
      password: [''],
      website: ['', { updateOn: 'blur', validators: [Validators.pattern(WEBSITE_PATTERN_NEW), Validators.maxLength(150)] }],
    });

    Object.keys(this.donationForm.controls).forEach(controlName => {
      this.donationForm.get(controlName)?.valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.donationForm.get(controlName))
      );
    });
    this.validation.setValidationErrors({
      phone: { pattern: 'Please enter a valid phone number' },
      website: { pattern: 'Invalid URL' },
      amount: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places.', pattern: false },
      organization_name: { pattern: 'Accept only alphanumeric input with up to 2 decimal places and the following special characters: @&*.,:;()-\'' },
      zipcode: { pattern: 'Please enter a valid zipcode' },
      city: { whitSpaceValue: 'Please enter valid character'}
    });
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getErrors(controlKey: string): Array<string> {
    return !this.canEdit ? [] : this.validation.getErrors(controlKey, this.donationForm);
  }

  /**
   * Detect change on phone number component
   *
   * @param event
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }

  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }

  /**
   * get frequency list
   */
  public getFrequencyList(): void {
    this.commonService.getFrequencyTypes().subscribe({
        next: (r) => {
          if (r.status) {
            this.frequencyTypeArray = this.commonHelper.convertToOptionsFormat(r.data, 'id', 'name');
            this.resetForm();
          }
        },
        error: () => this.resetForm(),
      },
    );
  }

  /**
   * change listener for frequency type change
   * @param {SelectOptionsInterface}selectedOption
   */
  public optionChangeEmitterFrequencyType(selectedOption: SelectOptionsInterface): void {
    if (selectedOption) {
      this.frequencyTypeId = selectedOption.value;
      this.donationForm.patchValue({
        frequency_id: selectedOption.value,
      });
    }
  }

  /**
   * setter for editing business details
   *
   * @param {any} data
   */
  @Input() set editData(data: any) {
    if (data && Object.keys(data).length && data?.id) {
      data.date_of_donation = data?.date_of_donation && new Date(data?.date_of_donation?.replace(/-/g, '/'));
      this.donationForm.patchValue({
        ...data,
        country: data?.country?.id,
        city: data?.city?.id,
        state: data?.state?.id,
      });
      this.frequencyTypeId = data.frequency_id;

      let tempPhoneNumber;
      if (data?.phone) {
        tempPhoneNumber = data?.phone;
        this.countryCode = { code: data?.flag_code, dialCode: data?.country_code };
      }
      this.donationForm.patchValue({
        phone: tempPhoneNumber,
      });
      this.donationTypeChange(data?.donation_type);
      this.updateAddressPacContainer(data?.country?.id);
    }
  }

  /**
   * Donations type change
   * @param value
   */
  public donationTypeChange(value: any): void {
    this.elementRefSet = false;
    this.isFrequentDonation = value == this.donationTypes[0]?.value;
  }

  /**
   * Initialize google map api by country
   * @param {any} countryId
   */
  public initMap(countryId: any): void {
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: (r) => {
        this.ngZone.run(() => {
          this.donationForm.patchValue(r);
        });
      },
      error: (e) => console.error(e),
    });
  }

  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.donationForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }

  /**
   * listen for options changes country
   *
   * @param {SelectOptionsInterface} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry) return;
    this.donationForm.controls.state.reset();
    this.donationForm.patchValue({
      country: selectedCountry.value,
    });
    this.updateAddressPacContainer(selectedCountry.value);
  }

  /**
   * remove pac container and update country in pac container
   *
   */
  public updateAddressPacContainer(initCountry: any) {
    this.addressHelper.removeAutocompletePacContainer();
    this.initMap(initCountry);
  }

  /**
   * listen for options changes state
   *
   * @param {SelectOptionsInterface} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.donationForm.controls.city.reset();
    this.donationForm.patchValue({
      state: selectedState.value,
    });
  }

  /**
   * listen for options changes state
   *
   * @param {SelectOptionsInterface} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.donationForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.donationForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(selectedCity.additionalDetails[0]?.postal_code_regex))]);
      zipCodeRef.updateValueAndValidity();
    }
    // this loader is set false here because of the delay to set country state city
    this.preDefinedLoader = false;
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.donationForm.controls;
  }

  /**
   * add donation
   */
  public onSubmit(): void {
    this.canEdit && this.donationForm.markAllAsTouched();
    if (!this.donationForm.valid) {
      this.commonHelper.scrollToFirstInvalidControl(this.donationForm);
      return; // return if the form is invalid
    }
    this.submitLoader = true;
    this.donationForm.value.date_of_donation = this.commonHelper.formatDate(this.donationForm.value.date_of_donation, DATE_FORMAT);

    // add donation
    const payLoad = {
      ...this.donationForm.value,
      roletype: this.userType,
      request_id: this.clientRequestId,
      is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
      country_code: this.countryCode.dialCode,
      flag_code: this.countryCode.code,
    };
    payLoad.amount = payLoad?.amount ? this.commonHelper.commaSeparateNumberClean(payLoad.amount, null) : null; // Remove commas for backend.

    payLoad.state = this.selectedCountryName == this.unitedKingdom ? null : payLoad.state;
    this.commonService.getCityDetail(payLoad.country, payLoad.state, payLoad.city).subscribe((cResponse: APIResponseModel) => {
      if (cResponse?.status) {
        payLoad.city = cResponse?.data?.id;
        this.propertyService.addDonation(payLoad).subscribe((response: APIResponseModel) => {
            if (response.status) {
              if (this.userType === 'Consumer') {
                this.commonHelper.updateLocalstorageRequestStage(response.data);
              }
              this.toggleModalEmitter.emit({
                ...response.data,
                listStatus: (this.donationForm.value.id) ? LIST_UPDATE : LIST_NEW,
              });
              payLoad.id ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();
              this.closeModel();
              this.submitLoader = false;
            }
          }, (exception: any) => {
            this.commonHelper.httpResponseHandler(exception?.error);
            this.submitLoader = false;
          },
        );
      }
    }, (exception: any) => {
      this.commonHelper.httpResponseHandler(exception?.error);
      this.submitLoader = false;
    });
  }

  /**
   * delete donation details
   */
  public deleteDonation(): void {
    // ACL check
    if (!this.permissions?.['delete']) {
      this.toastrService.info('You do not have permission to delete items for this client.');
      return;
    }

    this.submitLoaderDelete = true;
    const sendingData: {} = {
      is_delete: '1',
      id: this.donationForm.value.id,
      roletype: this.userType,
    };
    if (this.userType !== 'Consumer') {
      sendingData['request_id'] = this.clientRequestId;
    }

    //  Delete donation
    this.propertyService.addDonation(sendingData).subscribe({
        next: (response: APIResponseModel) => {
          if (response.status) {
            this.toggleModalEmitter.emit({
              id: this.donationForm.value.id,
              listStatus: LIST_DELETE,
            });
            this.commonHelper.toastrDeleteSuccess();
            this.closeModel();
          }
        }, error: (exception: any) => this.commonHelper.httpResponseHandler(exception?.error), complete: () => this.submitLoaderDelete = false,
      },
    );
  }

  /**
   * mark a field as touched ( Individual donation )
   *
   * @param {string} donation
   */
  public markAsTouchedIndividual(donation: string): void {
    this.formGet[donation].markAsTouched({
      onlySelf: true,
    });
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.commonHelper.scrollToTopModal(this.donationForm);
    this.modalService.close('add-donation-modal');
    this.frequencyTypeId = null;
    this.togglePasswordView = false;
    this.resetForm();
    this.updateAddressPacContainer(this.defaultCountry);
  }

  /**
   * Reset form
   */
  public resetForm(): void {
    this.donationForm.reset({
      donation_type: this.donationTypes[0]?.value,
      country: this.defaultCountry,
    });
    this.isFrequentDonation = true;
    this.elementRefSet = false;
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
  }

  get state() {
    return this.donationForm.get('state');
  }

  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.donationForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ?
        this.state.addValidators([Validators.required]) : this.state.clearValidators();
      this.state.updateValueAndValidity();
    });
  }

  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.donationForm.get('zipcode');
    const stateId = this.donationForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        if (response.data.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(zipCodeRegex))]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        } else {
          zipCodeRef.setValidators([Validators.required]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }

  /**
   * If user can edit existing asset, or add a new asset.
   */
  get canEdit(): boolean {
    return this.commonHelper.canEditSection(this.isNewAsset, this.permissions) && (this.hasPaidSubscription || this.clientHasPaidSubscription)
  }

  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }
}
