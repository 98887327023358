// flag emoji with country code
export const FLAG_COUNTRY_CODES = [{
  // code: 'AC',
  code: 'SH',
  dialCode: '1-268',
  emoji: 'ac',
  name: 'Ascension Island',
  unicode: 'U+1F1E6 U+1F1E8',
}, {
  code: 'AE',
  dialCode: '971',
  emoji: '🇦🇪',
  name: 'United Arab Emirates',
  unicode: 'U+1F1E6 U+1F1EA',
}, {
  code: 'AF',
  dialCode: '93',
  emoji: '🇦🇫',
  name: 'Afghanistan',
  unicode: 'U+1F1E6 U+1F1EB',
}, {
  code: 'AG',
  dialCode: '213',
  emoji: '🇦🇬',
  name: 'Antigua and Barbuda',
  unicode: 'U+1F1E6 U+1F1EC',
}, {
  code: 'AL',
  dialCode: '355',
  emoji: '🇦🇱',
  name: 'Albania',
  unicode: 'U+1F1E6 U+1F1F1',
}, {
  code: 'AM',
  dialCode: '374',
  emoji: '🇦🇲',
  name: 'Armenia',
  unicode: 'U+1F1E6 U+1F1F2',
}, {
  code: 'AO',
  dialCode: '244',
  emoji: '🇦🇴',
  name: 'Angola',
  unicode: 'U+1F1E6 U+1F1F4',
}, {
  code: 'AQ',
  dialCode: '1-684',
  emoji: '🇦🇶',
  name: 'Antarctica',
  unicode: 'U+1F1E6 U+1F1F6',
}, {
  code: 'AR',
  dialCode: '54',
  emoji: '🇦🇷',
  name: 'Argentina',
  unicode: 'U+1F1E6 U+1F1F7',
}, {
  code: 'AS',
  // dialCode: '61',
  dialCode: '1-684',
  emoji: '🇦🇸',
  name: 'American Samoa',
  unicode: 'U+1F1E6 U+1F1F8',
}, {
  code: 'AU',
  dialCode: '61',
  emoji: '🇦🇺',
  name: 'Australia',
  unicode: 'U+1F1E6 U+1F1FA',
}, {
  code: 'BA',
  dialCode: '973',
  emoji: '🇧🇦',
  name: 'Bosnia and Herzegovina',
  unicode: 'U+1F1E7 U+1F1E6',
}, {
  code: 'BB',
  dialCode: '1-246',
  emoji: '🇧🇧',
  name: 'Barbados',
  unicode: 'U+1F1E7 U+1F1E7',
}, {
  code: 'BD',
  dialCode: '1-441',
  emoji: '🇧🇩',
  name: 'Bangladesh',
  unicode: 'U+1F1E7 U+1F1E9',
}, {
  code: 'BE',
  dialCode: '32',
  emoji: '🇧🇪',
  name: 'Belgium',
  unicode: 'U+1F1E7 U+1F1EA',
}, {
  code: 'BF',
  dialCode: '1-242',
  emoji: '🇧🇫',
  name: 'Burkina Faso',
  unicode: 'U+1F1E7 U+1F1EB',
}, {
  code: 'BG',
  dialCode: '880',
  emoji: '🇧🇬',
  name: 'Bulgaria',
  unicode: 'U+1F1E7 U+1F1EC',
}, {
  code: 'BH',
  dialCode: '501',
  emoji: '🇧🇭',
  name: 'Bahrain',
  unicode: 'U+1F1E7 U+1F1ED',
}, {
  code: 'BL',
  dialCode: '591',
  emoji: '🇧🇱',
  name: 'St. Barthélemy',
  unicode: 'U+1F1E7 U+1F1F1',
}, {
  code: 'BM',
  dialCode: '95',
  emoji: '🇧🇲',
  name: 'Bermuda',
  unicode: 'U+1F1E7 U+1F1F2',
}, {
  code: 'BN',
  dialCode: '229',
  emoji: '🇧🇳',
  name: 'Brunei',
  unicode: 'U+1F1E7 U+1F1F3',
}, {
  code: 'BO',
  dialCode: '375',
  emoji: '🇧🇴',
  name: 'Bolivia',
  unicode: 'U+1F1E7 U+1F1F4',
}, {
  code: 'BR',
  dialCode: '55',
  emoji: '🇧🇷',
  name: 'Brazil',
  unicode: 'U+1F1E7 U+1F1F7',
}, {
  code: 'BT',
  dialCode: '975',
  emoji: '🇧🇹',
  name: 'Bhutan',
  unicode: 'U+1F1E7 U+1F1F9',
}, {
  code: 'BV',
  dialCode: '47',
  emoji: '🇧🇻',
  name: 'Bouvet Island',
  unicode: 'U+1F1E7 U+1F1FB',
}, {
  code: 'BY',
  dialCode: '257',
  emoji: '🇧🇾',
  name: 'Belarus',
  unicode: 'U+1F1E7 U+1F1FE',
}, {
  code: 'CD',
  dialCode: '235',
  emoji: '🇨🇩',
  name: 'Congo - Kinshasa',
  unicode: 'U+1F1E8 U+1F1E9',
}, {
  code: 'CF',
  dialCode: '242',
  emoji: '🇨🇫',
  name: 'Central African Republic',
  unicode: 'U+1F1E8 U+1F1EB',
}, {
  code: 'CG',
  dialCode: '243',
  emoji: '🇨🇬',
  name: 'Congo - Brazzaville',
  unicode: 'U+1F1E8 U+1F1EC',
}, {
  code: 'CH',
  dialCode: '86',
  emoji: '🇨🇭',
  name: 'Switzerland',
  unicode: 'U+1F1E8 U+1F1ED',
}, {
  code: 'CI',
  dialCode: '56',
  emoji: '🇨🇮',
  name: 'Côte d’Ivoire',
  unicode: 'U+1F1E8 U+1F1EE',
}, {
  code: 'CK',
  // dialCode: '61',
  dialCode: '682',
  emoji: '🇨🇰',
  name: 'Cook Islands',
  unicode: 'U+1F1E8 U+1F1F0',
}, {
  code: 'CM',
  dialCode: '237',
  emoji: '🇨🇲',
  name: 'Cameroon',
  unicode: 'U+1F1E8 U+1F1F2',
}, {
  code: 'CN',
  dialCode: '269',
  emoji: '🇨🇳',
  name: 'China',
  unicode: 'U+1F1E8 U+1F1F3',
}, {
  code: 'CO',
  dialCode: '57',
  emoji: '🇨🇴',
  name: 'Colombia',
  unicode: 'U+1F1E8 U+1F1F4',
}, {
  code: 'CU',
  dialCode: '53',
  emoji: '🇨🇺',
  name: 'Cuba',
  unicode: 'U+1F1E8 U+1F1FA',
}, {
  code: 'CV',
  dialCode: '238',
  emoji: '🇨🇻',
  name: 'Cape Verde',
  unicode: 'U+1F1E8 U+1F1FB',
}, {
  code: 'CW',
  dialCode: '682',
  emoji: '🇨🇼',
  name: 'Curaçao',
  unicode: 'U+1F1E8 U+1F1FC',
}, {
  code: 'CY',
  dialCode: '357',
  emoji: '🇨🇾',
  name: 'Cyprus',
  unicode: 'U+1F1E8 U+1F1FE',
}, {
  code: 'DJ',
  dialCode: '253',
  emoji: '🇩🇯',
  name: 'Djibouti',
  unicode: 'U+1F1E9 U+1F1EF',
}, {
  code: 'DO',
  dialCode: '1-767',
  emoji: '🇩🇴',
  name: 'Dominican Republic',
  unicode: 'U+1F1E9 U+1F1F4',
}, {
  code: 'EC',
  dialCode: '593',
  emoji: '🇪🇨',
  name: 'Ecuador',
  unicode: 'U+1F1EA U+1F1E8',
}, {
  code: 'EG',
  dialCode: '20',
  emoji: '🇪🇬',
  name: 'Egypt',
  unicode: 'U+1F1EA U+1F1EC',
}, {
  code: 'ER',
  dialCode: '291',
  emoji: '🇪🇷',
  name: 'Eritrea',
  unicode: 'U+1F1EA U+1F1F7',
}, {
  code: 'ES',
  dialCode: '503',
  emoji: '🇪🇸',
  name: 'Spain',
  unicode: 'U+1F1EA U+1F1F8',
}, {
  code: 'ET',
  dialCode: '251',
  emoji: '🇪🇹',
  name: 'Ethiopia',
  unicode: 'U+1F1EA U+1F1F9',
}, {
  code: 'FI',
  dialCode: '358',
  emoji: '🇫🇮',
  name: 'Finland',
  unicode: 'U+1F1EB U+1F1EE',
}, {
  code: 'FJ',
  dialCode: '679',
  emoji: '🇫🇯',
  name: 'Fiji',
  unicode: 'U+1F1EB U+1F1EF',
}, {
  code: 'FK',
  dialCode: '500',
  emoji: '🇫🇰',
  name: 'Falkland Islands',
  unicode: 'U+1F1EB U+1F1F0',
}, {
  code: 'FM',
  dialCode: '691',
  emoji: '🇫🇲',
  name: 'Micronesia',
  unicode: 'U+1F1EB U+1F1F2',
}, {
  code: 'FO',
  dialCode: '298',
  emoji: '🇫🇴',
  name: 'Faroe Islands',
  unicode: 'U+1F1EB U+1F1F4',
}, {
  code: 'FR',
  dialCode: '33',
  emoji: '🇫🇷',
  name: 'France',
  unicode: 'U+1F1EB U+1F1F7',
}, {
  code: 'GA',
  dialCode: '220',
  emoji: '🇬🇦',
  name: 'Gabon',
  unicode: 'U+1F1EC U+1F1E6',
}, {
  code: 'UK',
  dialCode: '44',
  emoji: '🇬🇧',
  name: 'United Kingdom',
  unicode: 'U+1F1EC U+1F1E7',
}, {
  code: 'GG',
  dialCode: '995',
  emoji: '🇬🇬',
  name: 'Guernsey',
  unicode: 'U+1F1EC U+1F1EC',
}, {
  code: 'GH',
  dialCode: '233',
  emoji: '🇬🇭',
  name: 'Ghana',
  unicode: 'U+1F1EC U+1F1ED',
}, {
  code: 'GI',
  dialCode: '350',
  emoji: '🇬🇮',
  name: 'Gibraltar',
  unicode: 'U+1F1EC U+1F1EE',
}, {
  code: 'GL',
  dialCode: '299',
  emoji: '🇬🇱',
  name: 'Greenland',
  unicode: 'U+1F1EC U+1F1F1',
}, {
  code: 'GM',
  dialCode: '49',
  emoji: '🇬🇲',
  name: 'Gambia',
  unicode: 'U+1F1EC U+1F1F2',
}, {
  code: 'GP',
  dialCode: '590',
  emoji: '🇬🇵',
  name: 'Guadeloupe',
  unicode: 'U+1F1EC U+1F1F5',
}, {
  code: 'GQ',
  dialCode: '1-671',
  emoji: '🇬🇶',
  name: 'Equatorial Guinea',
  unicode: 'U+1F1EC U+1F1F6',
}, {
  code: 'GR',
  dialCode: '30',
  emoji: '🇬🇷',
  name: 'Greece',
  unicode: 'U+1F1EC U+1F1F7',
}, {
  code: 'GT',
  dialCode: '502',
  emoji: '🇬🇹',
  name: 'Guatemala',
  unicode: 'U+1F1EC U+1F1F9',
}, {
  code: 'GY',
  dialCode: '592',
  emoji: '🇬🇾',
  name: 'Guyana',
  unicode: 'U+1F1EC U+1F1FE',
}, {
  code: 'HK',
  dialCode: '852',
  emoji: '🇭🇰',
  name: 'Hong Kong SAR China',
  unicode: 'U+1F1ED U+1F1F0',
}, {
  code: 'HM',
  dialCode: '672',
  emoji: '🇭🇲',
  name: 'Heard and McDonald Islands',
  unicode: 'U+1F1ED U+1F1F2',
}, {
  code: 'HR',
  dialCode: '385',
  emoji: '🇭🇷',
  name: 'Croatia',
  unicode: 'U+1F1ED U+1F1F7',
}, {
  code: 'HU',
  dialCode: '36',
  emoji: '🇭🇺',
  name: 'Hungary',
  unicode: 'U+1F1ED U+1F1FA',
}, {
  code: 'IC',
  dialCode: '354',
  emoji: '🇮🇨',
  name: 'Canary Islands',
  unicode: 'U+1F1EE U+1F1E8',
}, {
  code: 'ID',
  dialCode: '62',
  emoji: '🇮🇩',
  name: 'Indonesia',
  unicode: 'U+1F1EE U+1F1E9',
}, {
  code: 'IM',
  dialCode: '44',
  emoji: '🇮🇲',
  name: 'Isle of Man',
  unicode: 'U+1F1EE U+1F1F2',
}, {
  code: 'IN',
  dialCode: '91',
  emoji: '🇮🇳',
  name: 'India',
  unicode: 'U+1F1EE U+1F1F3',
}, {
  code: 'IO',
  dialCode: '246',
  emoji: '🇮🇴',
  name: 'British Indian Ocean Territory',
  unicode: 'U+1F1EE U+1F1F4',
}, {
  code: 'IR',
  dialCode: '98',
  emoji: '🇮🇷',
  name: 'Iran',
  unicode: 'U+1F1EE U+1F1F7',
}, {
  code: 'IS',
  dialCode: '972',
  emoji: '🇮🇸',
  name: 'Iceland',
  unicode: 'U+1F1EE U+1F1F8',
}, {
  code: 'IT',
  dialCode: '39',
  emoji: '🇮🇹',
  name: 'Italy',
  unicode: 'U+1F1EE U+1F1F9',
}, {
  code: 'JE',
  dialCode: '44',
  emoji: '🇯🇪',
  name: 'Jersey',
  unicode: 'U+1F1EF U+1F1EA',
}, {
  code: 'JM',
  dialCode: '1-876',
  emoji: '🇯🇲',
  name: 'Jamaica',
  unicode: 'U+1F1EF U+1F1F2',
}, {
  code: 'JO',
  dialCode: '962',
  emoji: '🇯🇴',
  name: 'Jordan',
  unicode: 'U+1F1EF U+1F1F4',
}, {
  code: 'KE',
  dialCode: '254',
  emoji: '🇰🇪',
  name: 'Kenya',
  unicode: 'U+1F1F0 U+1F1EA',
}, {
  code: 'KG',
  dialCode: '996',
  emoji: '🇰🇬',
  name: 'Kyrgyzstan',
  unicode: 'U+1F1F0 U+1F1EC',
}, {
  code: 'KN',
  dialCode: '850',
  emoji: '🇰🇳',
  name: 'St. Kitts and Nevis',
  unicode: 'U+1F1F0 U+1F1F3',
}, {
  code: 'KR',
  dialCode: '686',
  emoji: '🇰🇷',
  name: 'South Korea',
  unicode: 'U+1F1F0 U+1F1F7',
}, {
  code: 'KZ',
  dialCode: '7',
  emoji: '🇰🇿',
  name: 'Kazakhstan',
  unicode: 'U+1F1F0 U+1F1FF',
}, {
  code: 'LA',
  dialCode: '856',
  emoji: '🇱🇦',
  name: 'Laos',
  unicode: 'U+1F1F1 U+1F1E6',
}, {
  code: 'LI',
  dialCode: '231',
  emoji: '🇱🇮',
  name: 'Liechtenstein',
  unicode: 'U+1F1F1 U+1F1EE',
}, {
  code: 'LS',
  dialCode: '423',
  emoji: '🇱🇸',
  name: 'Lesotho',
  unicode: 'U+1F1F1 U+1F1F8',
}, {
  code: 'LT',
  dialCode: '266',
  emoji: '🇱🇹',
  name: 'Lithuania',
  unicode: 'U+1F1F1 U+1F1F9',
}, {
  code: 'LU',
  dialCode: '352',
  emoji: '🇱🇺',
  name: 'Luxembourg',
  unicode: 'U+1F1F1 U+1F1FA',
}, {
  code: 'LY',
  dialCode: '218',
  emoji: '🇱🇾',
  name: 'Libya',
  unicode: 'U+1F1F1 U+1F1FE',
}, {
  code: 'MA',
  dialCode: '261',
  emoji: '🇲🇦',
  name: 'Morocco',
  unicode: 'U+1F1F2 U+1F1E6',
}, {
  code: 'MC',
  dialCode: '853',
  emoji: '🇲🇨',
  name: 'Monaco',
  unicode: 'U+1F1F2 U+1F1E8',
}, {
  code: 'MD',
  dialCode: '373',
  emoji: '🇲🇩',
  name: 'Moldova',
  unicode: 'U+1F1F2 U+1F1E9',
}, {
  code: 'MF',
  dialCode: '262',
  emoji: '🇲🇫',
  name: 'St. Martin',
  unicode: 'U+1F1F2 U+1F1EB',
}, {
  code: 'MG',
  dialCode: '976',
  emoji: '🇲🇬',
  name: 'Madagascar',
  unicode: 'U+1F1F2 U+1F1EC',
}, {
  code: 'MH',
  dialCode: '1-664',
  emoji: '🇲🇭',
  name: 'Marshall Islands',
  unicode: 'U+1F1F2 U+1F1ED',
}, {
  code: 'MK',
  dialCode: '389',
  emoji: '🇲🇰',
  name: 'Macedonia',
  unicode: 'U+1F1F2 U+1F1F0',
}, {
  code: 'ML',
  dialCode: '223',
  emoji: '🇲🇱',
  name: 'Mali',
  unicode: 'U+1F1F2 U+1F1F1',
}, {
  code: 'MN',
  dialCode: '377',
  emoji: '🇲🇳',
  name: 'Mongolia',
  unicode: 'U+1F1F2 U+1F1F3',
}, {
  code: 'MO',
  dialCode: '212',
  emoji: '🇲🇴',
  name: 'Macau SAR China',
  unicode: 'U+1F1F2 U+1F1F4',
}, {
  code: 'MP',
  dialCode: '230',
  emoji: '🇲🇵',
  name: 'Northern Mariana Islands',
  unicode: 'U+1F1F2 U+1F1F5',
}, {
  code: 'MR',
  dialCode: '222',
  emoji: '🇲🇷',
  name: 'Mauritania',
  unicode: 'U+1F1F2 U+1F1F7',
}, {
  code: 'MT',
  dialCode: '356',
  emoji: '🇲🇹',
  name: 'Malta',
  unicode: 'U+1F1F2 U+1F1F9',
}, {
  code: 'MU',
  dialCode: '968',
  emoji: '🇲🇺',
  name: 'Mauritius',
  unicode: 'U+1F1F2 U+1F1FA',
}, {
  code: 'MV',
  dialCode: '960',
  emoji: '🇲🇻',
  name: 'Maldives',
  unicode: 'U+1F1F2 U+1F1FB',
}, {
  code: 'MX',
  dialCode: '52',
  emoji: '🇲🇽',
  name: 'Mexico',
  unicode: 'U+1F1F2 U+1F1FD',
}, {
  code: 'MY',
  dialCode: '60',
  emoji: '🇲🇾',
  name: 'Malaysia',
  unicode: 'U+1F1F2 U+1F1FE',
}, {
  code: 'MZ',
  dialCode: '258',
  emoji: '🇲🇿',
  name: 'Mozambique',
  unicode: 'U+1F1F2 U+1F1FF',
}, {
  code: 'NC',
  dialCode: '687',
  emoji: '🇳🇨',
  name: 'New Caledonia',
  unicode: 'U+1F1F3 U+1F1E8',
}, {
  code: 'NE',
  dialCode: '683',
  emoji: '🇳🇪',
  name: 'Niger',
  unicode: 'U+1F1F3 U+1F1EA',
}, {
  code: 'NF',
  dialCode: '672',
  emoji: '🇳🇫',
  name: 'Norfolk Island',
  unicode: 'U+1F1F3 U+1F1EB',
}, {
  code: 'NG',
  dialCode: '227',
  emoji: '🇳🇬',
  name: 'Nigeria',
  unicode: 'U+1F1F3 U+1F1EC',
}, {
  code: 'NI',
  dialCode: '234',
  emoji: '🇳🇮',
  name: 'Nicaragua',
  unicode: 'U+1F1F3 U+1F1EE',
}, {
  code: 'NL',
  dialCode: '31',
  emoji: '🇳🇱',
  name: 'Netherlands',
  unicode: 'U+1F1F3 U+1F1F1',
}, {
  code: 'NO',
  dialCode: '47',
  emoji: '🇳🇴',
  name: 'Norway',
  unicode: 'U+1F1F3 U+1F1F4',
}, {
  code: 'NP',
  dialCode: '977',
  emoji: '🇳🇵',
  name: 'Nepal',
  unicode: 'U+1F1F3 U+1F1F5',
}, {
  code: 'NR',
  dialCode: '674',
  emoji: '🇳🇷',
  name: 'Nauru',
  unicode: 'U+1F1F3 U+1F1F7',
}, {
  code: 'NU',
  dialCode: '505',
  emoji: '🇳🇺',
  name: 'Niue',
  unicode: 'U+1F1F3 U+1F1FA',
}, {
  code: 'NZ',
  dialCode: '64',
  emoji: '🇳🇿',
  name: 'New Zealand',
  unicode: 'U+1F1F3 U+1F1FF',
}, {
  code: 'PA',
  dialCode: '595',
  emoji: '🇵🇦',
  name: 'Panama',
  unicode: 'U+1F1F5 U+1F1E6',
}, {
  code: 'PE',
  dialCode: '51',
  emoji: '🇵🇪',
  name: 'Peru',
  unicode: 'U+1F1F5 U+1F1EA',
}, {
  code: 'PK',
  dialCode: '92',
  emoji: '🇵🇰',
  name: 'Pakistan',
  unicode: 'U+1F1F5 U+1F1F0',
}, {
  code: 'PL',
  dialCode: '48',
  emoji: '🇵🇱',
  name: 'Poland',
  unicode: 'U+1F1F5 U+1F1F1',
}, {
  code: 'PM',
  dialCode: '507',
  emoji: '🇵🇲',
  name: 'St. Pierre and Miquelon',
  unicode: 'U+1F1F5 U+1F1F2',
}, {
  code: 'PS',
  dialCode: '680',
  emoji: '🇵🇸',
  name: 'Palestinian Territories',
  unicode: 'U+1F1F5 U+1F1F8',
}, {
  code: 'QA',
  dialCode: '974',
  emoji: '🇶🇦',
  name: 'Qatar',
  unicode: 'U+1F1F6 U+1F1E6',
}, {
  code: 'RE',
  dialCode: '262',
  emoji: '🇷🇪',
  name: 'Réunion',
  unicode: 'U+1F1F7 U+1F1EA',
}, {
  code: 'RO',
  dialCode: '40',
  emoji: '🇷🇴',
  name: 'Romania',
  unicode: 'U+1F1F7 U+1F1F4',
}, {
  code: 'RS',
  dialCode: '7',
  emoji: '🇷🇸',
  name: 'Serbia',
  unicode: 'U+1F1F7 U+1F1F8',
}, {
  code: 'RW',
  dialCode: '250',
  emoji: '🇷🇼',
  name: 'Rwanda',
  unicode: 'U+1F1F7 U+1F1FC',
}, {
  code: 'SA',
  dialCode: '966',
  emoji: '🇸🇦',
  name: 'Saudi Arabia',
  unicode: 'U+1F1F8 U+1F1E6',
}, {
  code: 'SB',
  dialCode: '508',
  emoji: '🇸🇧',
  name: 'Solomon Islands',
  unicode: 'U+1F1F8 U+1F1E7',
}, {
  code: 'SC',
  dialCode: '1-869',
  emoji: '🇸🇨',
  name: 'Seychelles',
  unicode: 'U+1F1F8 U+1F1E8',
}, {
  code: 'SE',
  dialCode: '248',
  emoji: '🇸🇪',
  name: 'Sweden',
  unicode: 'U+1F1F8 U+1F1EA',
}, {
  code: 'SG',
  dialCode: '221',
  emoji: '🇸🇬',
  name: 'Singapore',
  unicode: 'U+1F1F8 U+1F1EC',
}, {
  code: 'SH',
  dialCode: '290 n',
  emoji: '🇸🇭',
  name: 'St. Helena',
  unicode: 'U+1F1F8 U+1F1ED',
}, {
  code: 'SI',
  dialCode: '386',
  emoji: '🇸🇮',
  name: 'Slovenia',
  unicode: 'U+1F1F8 U+1F1EE',
}, {
  code: 'SL',
  dialCode: '232',
  emoji: '🇸🇱',
  name: 'Sierra Leone',
  unicode: 'U+1F1F8 U+1F1F1',
}, {
  code: 'SM',
  dialCode: '378',
  emoji: '🇸🇲',
  name: 'San Marino',
  unicode: 'U+1F1F8 U+1F1F2',
}, {
  code: 'SN',
  dialCode: '65',
  emoji: '🇸🇳',
  name: 'Senegal',
  unicode: 'U+1F1F8 U+1F1F3',
}, {
  code: 'SO',
  dialCode: '252',
  emoji: '🇸🇴',
  name: 'Somalia',
  unicode: 'U+1F1F8 U+1F1F4',
}, {
  code: 'ST',
  dialCode: '1-758',
  emoji: '🇸🇹',
  name: 'São Tomé and Príncipe',
  unicode: 'U+1F1F8 U+1F1F9',
}, {
  code: 'SX',
  dialCode: '500',
  emoji: '🇸🇽',
  name: 'Sint Maarten',
  unicode: 'U+1F1F8 U+1F1FD',
}, {
  code: 'SY',
  dialCode: '963',
  emoji: '🇸🇾',
  name: 'Syria',
  unicode: 'U+1F1F8 U+1F1FE',
}, {
  code: 'SZ',
  dialCode: '41',
  emoji: '🇸🇿',
  name: 'Swaziland',
  unicode: 'U+1F1F8 U+1F1FF',
}, {
  code: 'TD',
  dialCode: '1-868',
  emoji: '🇹🇩',
  name: 'Chad',
  unicode: 'U+1F1F9 U+1F1E9',
}, {
  code: 'TH',
  dialCode: '66',
  emoji: '🇹🇭',
  name: 'Thailand',
  unicode: 'U+1F1F9 U+1F1ED',
}, {
  code: 'TK',
  dialCode: '1-649',
  emoji: '🇹🇰',
  name: 'Tokelau',
  unicode: 'U+1F1F9 U+1F1F0',
}, {
  code: 'TL',
  dialCode: '690',
  emoji: '🇹🇱',
  name: 'Timor-Leste',
  unicode: 'U+1F1F9 U+1F1F1',
}, {
  code: 'TN',
  dialCode: '676',
  emoji: '🇹🇳',
  name: 'Tunisia',
  unicode: 'U+1F1F9 U+1F1F3',
}, {
  code: 'TO',
  dialCode: '228',
  emoji: '🇹🇴',
  name: 'Tonga',
  unicode: 'U+1F1F9 U+1F1F4',
}, {
  code: 'TT',
  dialCode: '670',
  emoji: '🇹🇹',
  name: 'Trinidad and Tobago',
  unicode: 'U+1F1F9 U+1F1F9',
}, {
  code: 'TV',
  dialCode: '688',
  emoji: '🇹🇻',
  name: 'Tuvalu',
  unicode: 'U+1F1F9 U+1F1FB',
}, {
  code: 'TW',
  dialCode: '886',
  emoji: '🇹🇼',
  name: 'Taiwan',
  unicode: 'U+1F1F9 U+1F1FC',
}, {
  code: 'TZ',
  dialCode: '255',
  emoji: '🇹🇿',
  name: 'Tanzania',
  unicode: 'U+1F1F9 U+1F1FF',
}, {
  code: 'UG',
  dialCode: '256',
  emoji: '🇺🇬',
  name: 'Uganda',
  unicode: 'U+1F1FA U+1F1EC',
}, {
  code: 'US',
  dialCode: '1',
  emoji: '🇺🇸',
  name: 'United States',
  unicode: 'U+1F1FA U+1F1F8',
}, {
  code: 'CA',
  dialCode: '1',
  emoji: '🇨🇦',
  name: 'Canada',
  unicode: 'U+1F1E8 U+1F1E6',
},
{
  code: 'UY',
  dialCode: '598',
  emoji: '🇺🇾',
  name: 'Uruguay',
  unicode: 'U+1F1FA U+1F1FE',
}, {
  code: 'UZ',
  dialCode: '998',
  emoji: '🇺🇿',
  name: 'Uzbekistan',
  unicode: 'U+1F1FA U+1F1FF',
}, {
  code: 'VC',
  dialCode: '1-784',
  emoji: '🇻🇨',
  name: 'St. Vincent and Grenadines',
  unicode: 'U+1F1FB U+1F1E8',
}, {
  code: 'VE',
  dialCode: '58',
  emoji: '🇻🇪',
  name: 'Venezuela',
  unicode: 'U+1F1FB U+1F1EA',
}, {
  code: 'VI',
  dialCode: '1-284',
  emoji: '🇻🇮',
  name: 'U.S. Virgin Islands',
  unicode: 'U+1F1FB U+1F1EE',
}, {
  code: 'WF',
  dialCode: '681',
  emoji: '🇼🇫',
  name: 'Wallis and Futuna',
  unicode: 'U+1F1FC U+1F1EB',
}, {
  code: 'WS',
  dialCode: '685',
  emoji: '🇼🇸',
  name: 'Samoa',
  unicode: 'U+1F1FC U+1F1F8',
}, {
  code: 'ZA',
  dialCode: '260',
  emoji: '🇿🇦',
  name: 'South Africa',
  unicode: 'U+1F1FF U+1F1E6',
}];
