import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlaidOnSuccessArgs } from 'ngx-plaid-link';
import { ToastrService } from 'ngx-toastr';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PlaidOptionEmit } from 'src/app/sharedComponent/import-from-plaid-option/import-from-plaid-option.component';
import { SECTIONS, SectionStatus } from '../../../../constants/application.const';
import { CommonHelper } from '../../../helper/common.helper';
import { AssetsService } from '../../../services/assets.service';
import { PersonalDetailsService } from '../../../services/personal-details.service';

// Define the interface for functionMapper
interface FunctionMapperI {
  [key: string]: () => boolean;
}

interface PermissionsI {
  view: boolean;
  add: boolean;
  edit: boolean;
  delete: boolean;
  name?:string;
}

@Component({
  selector: 'app-accounts-card-view',
  templateUrl: './accounts-card-view.component.html',
})
/**
 * Account card view
 */
export class AccountsCardViewComponent implements OnInit {
  public sectionSaveExitOptions: any;
  public userType: string;
  public clientRequestId: string;
  public submitLoader: boolean;
  public editAccessList: Array<string>;
  public isLoading: boolean;
  private isLoadingSections: { retirement?: boolean, financial?: boolean,liabilities?:boolean };
  public permissions: PermissionsI ;
  public financialAssetPermissions:PermissionsI
  public retirementAssetPermissions:PermissionsI
  public liabilityAssetPermissions:PermissionsI
  public assetsPermissionsList:PermissionsI[] = [];
  public assetPermissionCheckFunctions: FunctionMapperI;
  /**
   * True signifies hiding the 'save' buttons and showing the 'progress' buttons
   */
  public buttonProgress: boolean = false;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public assetType: any;
  public midPlaid = 'accounts-card-plaid-import-modal';
  public clientHasPaidSubscription: boolean;
  public isConsumer: Boolean;

  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService,
    private personalDetailsService: PersonalDetailsService,
    private commonHelper: CommonHelper,
    private assetsService: AssetsService,
    private commonService: CommonService,
    private toastr: ToastrService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.isLoadingSections = {};
    this.setLoading(true, 'retirement');
    this.setLoading(true, 'financial');
    this.clientRequestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.isConsumer = this.commonHelper.isConsumer;
    this.sectionSaveExitOptions = {
      section: 3,
      type: 3,
    };
    this.editAccessList = this.localStorageService.getDataByKey('permission-list');

    this.assetPermissionCheckFunctions = {
      'Financial Assets': () => {
        return this.commonHelper.isProfessional ?  this.commonHelper.canEditSection(true, this.financialAssetPermissions) && this.clientHasPaidSubscription : true;
      },
      'Retirement Assets': () => {
        return this.commonHelper.isProfessional ?  this.commonHelper.canEditSection(true, this.retirementAssetPermissions) && this.clientHasPaidSubscription : true;
        },
      'Liabilities': () => {
        return this.commonHelper.isProfessional ?  this.commonHelper.canEditSection(true, this.liabilityAssetPermissions) && this.clientHasPaidSubscription : true;
      },
    };
  }

  /**
   * toggle add finance asset modal
   */
  public toggleFinanceModal(): void {
    this.assetType = 'Financial Assets';
    this.permissions = this.financialAssetPermissions;
    setTimeout(()=>{
      this.modalService.open(this.midPlaid);
    },1000)
  }

  /**
   * toggle add retirement asset modal
   */
  public toggleRetirementModal(): void {
    this.assetType = 'Retirement Assets';
    this.permissions = this.retirementAssetPermissions;
    setTimeout(()=>{
      this.modalService.open(this.midPlaid);
    },1000)
  }

 /**
   * toggle add liabilities modal
   */
  public toggleLiabilitiesModal(): void {
    this.assetType = 'Liabilities';
    this.permissions = this.liabilityAssetPermissions;
    setTimeout(()=>{
      this.modalService.open(this.midPlaid);
    },1000)
  }

  /**
   * Set loading flags
   * @param loading
   * @param key
   */
  public setLoading(loading: boolean, key: string) {
    this.isLoadingSections[key] = loading;
    this.isLoading = this.isLoadingSections.financial || this.isLoadingSections.retirement || this.isLoadingSections.liabilities;
  }

  /**
   * Button click handler
   * @param command
   */
  public click(command: string) {
    const isPro = this.userType !== 'Consumer';
    switch (command) {
      case 'MarkComplete':
          this.buttonProgress = true;
          this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.liabilities, SectionStatus.COMPLETE).subscribe();
        break;
      case 'SaveForLater':
          this.buttonProgress = true;
        break;
      case 'NextSection':
        let url = ['property'];
        if (isPro) {
          url.push(this.clientRequestId);
        }
        void this.slugInterceptorService.navigate(url);
        break;
      case 'ReturnToDashboard':
        const return_url = isPro ? ['overview', 'finance-overview'] : ['dashboard'];
        void this.slugInterceptorService.navigate(return_url);
        break;
      case 'Back':
        void this.slugInterceptorService.navigate(['liabilities', isPro ? this.clientRequestId : '']);
        break;
    }
  }
  /**
   * Handle Plaid success event.
   * @param $event PlaidOnSuccessArgs
   */
  public onPlaidSuccess($event: PlaidOnSuccessArgs): void {
    // Check if the token is present in the event
    if (!$event?.token) return;
    let accountList;

    const observer = {
      next: (response: APIResponseModel) => {
        if (this.assetType === 'Financial Assets') {
          accountList = response.data?.financial;
        } else if (this.assetType === 'Retirement Assets') {
          accountList = response.data?.retirement;
        } else if (this.assetType === 'Liabilities') {
          accountList = response.data?.liability;
        }
        if (!accountList?.length) {
          this.toastr.error(`You don't have any Plaid accounts related to ${this.assetType}`);
        }
        this.modalService.close(this.midPlaid);
        this.assetsService.accountListTrigger(accountList.length ? accountList : []);
      },
      error: exception => this.commonHelper.httpResponseHandler(exception?.error),
    }

    const assetTypeMap = {
      'Financial Assets': 'getPlaidAccounts',
      'Retirement Assets': 'getRetirementPlaidAccounts',
      'Liabilities': 'getLiabilityPlaidAccounts'
    };

    // Determine the method to call based on this.assetType
    const assetTypeMethod = assetTypeMap[this.assetType];
    if (assetTypeMethod) {
      this.commonService[assetTypeMethod](
        $event?.token,
        this.userType !== 'Custodian' ? this.clientRequestId : undefined
      ).subscribe(observer);
    } else {
      console.error('Invalid asset type:', this.assetType);
    }
  }

  /**
   * Handle Plaid option event.
   * @param $event PlaidOptionEmit
   */
  public plaidOptionListener($event: PlaidOptionEmit) {
    // Check if Plaid onSuccess callback is present
    if ($event.plaid?.onSuccess) {
      this.onPlaidSuccess($event.plaid.onSuccess);
    }

    // Check for manual trigger
    if ($event.manual) {
      const assetModalMap = {
        'Financial Assets': 'add-financial-assets-modal',
        'Retirement Assets': 'add-retirement-assets-modal',
        'Liabilities': 'add-liabilities-modal'
      };
      // Open the appropriate modal based on asset type
      const assetModalID = assetModalMap[this.assetType];
      this.modalService.open(assetModalID);
      // Close the Plaid modal
      // this.modalService.close(this.midPlaid);
    }
  }

  /**
   * Listens for changes in the paid subscription event.
   * @param event The event containing subscription information.
   */
  public paidStatusEventListener(event) {
    if (Object.keys(event).length > 0) {
      this.clientHasPaidSubscription = event['clientHasPaidSubscription'];
    }
  }

  /**
   * Event listener to get permissions and set the canEdit flag.
   * @param {Object} event - The event object containing permissions.
   */
  public getPermissionsEventListener(event) {
    if (Object.keys(event).length > 0) {
      this.assetsPermissionsList?.push(event?.['permissionsList']);
      this.financialAssetPermissions = this.assetsPermissionsList.find((asset)=> asset?.name === 'Financial Assets')
      this.retirementAssetPermissions = this.assetsPermissionsList.find((asset)=> asset?.name === 'Retirement Assets')  
      this.liabilityAssetPermissions = this.assetsPermissionsList.find((asset)=> asset?.name === 'Liabilities')   
    }
  }
}
