import { Component, Input, OnInit } from '@angular/core';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { ClientsService } from 'src/app/professional-dashboard/services/clients.service';
import { CommonModelService } from 'src/app/services/common-model.service';

@Component({
  selector: 'app-client-link-modal',
  templateUrl: './client-link-modal.component.html',
})
/**
 * client link modal
 */
export class ClientLinkModalComponent implements OnInit {
  @Input() shortCode:string;
  public linkCopied:boolean;
  public generatedUrl:string;
  /**
 * constructor
 */
  constructor(private modalService:CommonModelService,
    private commonHelper: CommonHelper,
    private professionalClientService: ClientsService) { }
  /**
 * loaded initially
 */
  ngOnInit(): void {
    this.linkCopied=false;
    const payload = {
      payment_type: 1,
      promo_code: ""
    }
    this.professionalClientService.generateOnboardLink().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.generatedUrl = this.commonHelper.encryptRegisterLink(response.data);
      }
    }, (exception: any) => {
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }
  /**
   * close modal
   */
  public closeModel(): void {
    this.modalService.close('client-link-modal');
    this.linkCopied=false;
  }
}
