import { Component, OnInit } from '@angular/core';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { PeopleService } from 'src/app/services/people.service';
import { LocalStorageService } from '../../../../../services/local-storage.service';

@Component({
  selector: 'app-professional-banner',
  templateUrl: './professional-banner.component.html',
  styleUrls: ['./professional-banner.component.css'],
})

/**
 * Professiona Banner Component
 */
export class ProfessionalBannerComponent implements OnInit {
  public selectedType: string;
  public proTypes: Array<any>;
  public vaultVideo: string;


  /**
   * constructor
   */
  constructor(
    private slugInterceptorService: SlugInterceptorService,
    private localStorageService: LocalStorageService,
    private peopleService: PeopleService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService

  ) { }
  /**
   * loaded on initialization
   */
  ngOnInit(): void {
    // const navigateTo = this.localStorageService.getDataByKey('pro-type');
    // if (navigateTo) {
    //   void this.slugInterceptorService.navigate([''], null, true);
    // }
    this.getProfessionalTypes();
  }

  /**
   * on navigating to selected professional type's landing page
   */
  public onNavigation() {
    const seletedProType = this.proTypes.filter((cur) => cur.displayText === this.selectedType);
    console.log(seletedProType);

    this.localStorageService.storeData('pro-type', seletedProType);
    if (this.selectedType) {
      void this.slugInterceptorService.navigate(['/'], null, true);
    }
  }

  /**
   * get advisor types
   */
  public getProfessionalTypes() {
    this.peopleService.getAdvisorTypes()?.subscribe((response: APIResponseModel) => {
      if (response.status) {
        const order = ['Wealth Management', 'Human Resources', 'Law Firm', 'Funeral Home'];
        response.data.sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
        this.proTypes = this.commonHelper.convertToOptionsFormat(response?.data, 'id', 'name');
        console.log(this.proTypes);
      }
    });
  }

   /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');
  }
}
