import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';

@Component({
  selector: 'app-plan-cancel-reason-modal',
  templateUrl: './plan-cancel-reason-modal.component.html',
  styleUrls: ['./plan-cancel-reason-modal.component.css'],
})
export class PlanCancelReasonModalComponent implements OnInit {
  public planCancelForm: FormGroup;
  public reasonsList: any[] = [];
  public submitLoader: boolean;
  public isOtherChosen: boolean = false;

  @Output() cancelReasonSubmitted = new EventEmitter<boolean>();

  constructor(
    private form: FormBuilder,
    private subscriptionService: ConsumerSubscriptionService,
    private modalService: CommonModelService,
    private toastr:ToastrService,
    private commonHelper:CommonHelper
  ) {}

  ngOnInit(): void {
    this.getCancelReasonList();
    this.planCancelForm = this.form.group({
      cancellation_reason_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
      user_reason: ['', { updateOn: 'blur', validators: [Validators.minLength(3), Validators.maxLength(225)] }],
      additional_details: ['', { updateOn: 'blur', validators: [Validators.minLength(3)] }],
    });
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.planCancelForm.controls;
  }

  /**
   * Handles the selection of a cancellation reason.
   *
   * @param {any} order - The selected reason ID.
   */
  public selectedReason(order) {
    if (order == 5) {
      this.isOtherChosen = true;
      this.formGet['user_reason'].addValidators(Validators.required);
    } else {
      this.isOtherChosen = false;
      this.formGet['user_reason'].removeValidators(Validators.required);
    }
    this.formGet['user_reason'].updateValueAndValidity();
  }

  /**
   * Handles form submission.
   */
  public onSubmit() {
    if (!this.planCancelForm.valid) {
      this.planCancelForm.markAllAsTouched();
      this.commonHelper.scrollToFirstInvalidControl(this.planCancelForm);
      return;
    }
    this.submitLoader = true;

    const payLoad = {
      ...this.planCancelForm.value,
    };
    this.subscriptionService.updateCancellationReason(payLoad).subscribe((response:APIResponseModel)=>{
    this.submitLoader = false;
    this.cancelReasonSubmitted.emit(true)
    this.closeModel();
    },(exception:any)=>{
      this.submitLoader = true;
      this.commonHelper.httpResponseHandler(exception?.error);
    })

  }

  /**
   * Retrieves a list of cancellation reasons.
   */
  public getCancelReasonList() {
    this.subscriptionService.fetchCancellationReasons().subscribe((response)=>{
      console.log(response)
      response.data.forEach((e)=>{
      const reason = {
        id:e.id,
        order:e.order,
        reason:e.reason
      }
      this.reasonsList.push(reason)
      })
    })
  }

  /**
   * Closes the modal
   */
  public closeModel(): void {
    this.modalService.close('plan-cancel-reasons');
    this.isOtherChosen = false;
    this.planCancelForm.reset();
  }
}
