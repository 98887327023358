<!-- BEGIN: Navbar Wrapper -->
<app-nav-landing-pro></app-nav-landing-pro>
<!-- END: Navbar Wrapper -->
<main>
  <div class="p-12 lg:px-24 lg:py-32 container mx-auto">
    <div class="title__wrap text-center py-8">
      <h1 class="text-semibold text-accent fs-title pb-2">LegacyNOW</h1>
      <h2 class="text-semibold text-primary fs-title">CALIFORNIA PRIVACY POLICY </h2>
    </div>
    <div>
      <h4 class="text-accent text-semibold fs-subtitle mb-2">California Residents</h4>
      <p class="text-content text-regular fs-content">
        The below section, as required by the California Consumer Privacy Act of 2018 ("CCPA"), supplements the
        information contained in the Equifax Privacy Statement and applies solely to visitors, users, and others who
        reside in the State of California. Any
        terms defined in the CCPA have the same meaning when used in the below section.</p>
      <p class="text-content text-regular fs-content my-4">
        Categories of Personal Information Collected
      </p>
      <p class="text-content text-regular fs-content">
        We collect information that identifies, relates to, describes, references, is capable of being associated with,
        or could reasonably be linked, directly or indirectly, with a particular consumer or device ("personal
        information"). In particular, we have
        collected the following categories of California consumer personal information within the last twelve (12)
        months:
      </p>

      <table class="table-auto border-collapse w-full my-6" aria-describedby="california-privacy">
        <thead>
          <tr>
            <th class="text-content text-regular fs-content border border-gray-300 py-3 px-6" scope="row">
              <h4 class="text-accent text-left text-semibold fs-subtitle">
                Category</h4>
            </th>
            <th class="text-content text-regular fs-content border border-gray-300 py-3 px-6" scope="row">
              <h4 class="text-accent text-left text-semibold fs-subtitle px-6">Examples</h4>
            </th>
            <th class="text-content text-regular fs-content border border-gray-300 py-3 px-6" scope="row">
              <h4 class="text-accent text-left text-semibold fs-subtitle">Collected</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="align-top">
            <td class="text-content text-regular fs-content border border-gray-300 p-6">Identifiers</td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6">A real name, alias, postal
              address, unique personal identifier, online identifier, Internet Protocol address, email address, account
              name, Social Security number, driver's license number, passport number, or other similar identifiers.</td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6">Yes</td>
          </tr>
          <tr class="align-top">
            <td class="text-content text-regular fs-content border border-gray-300 p-6">Personal information categories
              listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6">A name, signature, Social
              Security number, physical characteristics or description, address, telephone number, passport number,
              driver's license or state identification card number, insurance policy number, education, employment,
              employment history, bank account number, credit card number, debit card number, or any other financial
              information, medical information, or health insurance information. Some personal information included in
              this category
              may overlap with other categories.</td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6">Yes</td>
          </tr>
          <tr class="align-top">
            <td class="text-content text-regular fs-content border border-gray-300 p-6">Internet or other similar
              activity</td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6">Browsing history, search
              history, information on a consumer's interaction with a website, application, or advertisement.</td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6">Yes</td>
          </tr>
          <tr class="align-top">
            <td class="text-content text-regular fs-content border border-gray-300 p-6">Geolocation data</td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6">Physical location or movements.
            </td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6">Yes</td>
          </tr>
        </tbody>
      </table>
      <h4 class="text-accent text-semibold fs-subtitle my-6">Personal information does not include:</h4>
      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">Publicly available information from government records.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"> De-identified or aggregated consumer information. </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Information excluded from the CCPA's scope, like:</p>
        </li>
        <ul class="lg:pl-8">
          <li>
            <p class="text-content text-regular fs-content pb-3">
              ◦ Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996
              (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;</p>
            <p class="text-content text-regular fs-content pb-3"> ◦ Personal information covered by certain
              sector-specific privacy laws, including the FCRA, the GLBA or California Financial Information Privacy Act
              (FIPA), and the Driver's Privacy Protection Act of 1994.
            </p>
          </li>
        </ul>
      </ul>
      <p class="text-content text-regular fs-content">We obtain the categories of information listed above from the
        categories of sources listed in the Sources of Personal Data section above. </p>
      <h4 class="text-accent text-semibold fs-subtitle my-6">Sharing your Personal Information</h4>
      <p class="text-content text-regular fs-content pb-3">In the preceding 12 months, we have disclosed the following
        categories of information for the business purposes described above: </p>
      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">Identifiers.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Personal information categories listed in the California
            Customer Records statute (Cal. Civ. Code § 1798.80(e)).</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Internet or other similar activity.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Geolocation data.</p>
        </li>
      </ul>
      <p class="text-content text-regular fs-content pb-3">We disclose these categories of personal information
        for business purposes to the following categories of third parties:</p>
      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">Our affiliates.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Our partners.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Our service providers and other third parties that cannot
            use personal information collected by or on behalf of us for their purposes, including but not limited to
            advertising networks, business process outsourcing providers, internet
            service providers, data analytics providers, data processors and storage providers, operating systems and
            platforms, social networks, and consumer data resellers.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Third parties to whom you or your {{agentTerm}}s
            authorize us to
            disclose your personal information in connection with the products or services we provide to you.</p>
        </li>
      </ul>
      <h4 class="text-accent text-semibold fs-subtitle my-6">Selling your Personal Information </h4>
      <p class="text-content text-regular fs-content pb-3">In the preceding 12 months, we have sold the below categories
        of information for the commercial purposes described above. </p>
      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">Identifiers.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Personal information categories listed in the California
            Customer Records statute (Cal. Civ. Code § 1798.80(e)).</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Internet or other similar activity.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Geolocation data.</p>
        </li>
      </ul>
      <p class="text-content text-regular fs-content pb-3">We sell these categories of personal information for
        commercial purposes to the following categories of third parties:</p>
      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">Financial Institutions.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Consumer credit customers.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Fraud detection providers.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Creditors/collection agencies.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Business customers, including but not limited to
            advertising networks, internet service providers, data analytics providers, operating systems and platforms,
            social networks, and consumer data resellers.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Government agencies and contractors.</p>
        </li>
      </ul>
      <h4 class="text-accent text-semibold fs-subtitle my-6">Rights of California Residents  </h4>
      <p class="text-content text-regular fs-content pb-3">California residents have the right to submit the following
        verifiable requests to Equifax.</p>
      <h4 class="text-accent text-semibold fs-subtitle my-6">Access </h4>
      <p class="text-content text-regular fs-content pb-3">You have a right to request Equifax provide you with the
        following information, covering the 12 months preceding the date your request is submitted.</p>
      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">The categories and specific pieces of personal
            information collected about you.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">The categories of sources of the personal information
            collected about you.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">The business or commercial purposes for collecting or
            selling your personal information.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">The categories of third parties to whom we sold your
            personal information.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">The categories of third parties to whom we disclosed your
            personal information for a business purpose.</p>
        </li>
      </ul>
      <h4 class="text-accent text-semibold fs-subtitle my-6">Deletion</h4>
      <p class="text-content text-regular fs-content pb-3">You have the right to request Equifax to delete the personal
        information collected from you, subject to certain exceptions. Once we receive and confirm your verifiable
        request, we will delete (and direct our service providers to delete)
        your personal information from our records, unless an exception applies.</p>
      <p class="text-content text-regular fs-content pb-3">We may deny your deletion request if retaining the
        information is necessary for us or our service providers to:</p>
      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">Complete the transaction for which we collected the
            personal information, provide a good or service that you requested, take actions reasonably anticipated
            within the context of our ongoing business relationship with you, or otherwise
            perform our contract with you.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Detect security incidents, protect against malicious,
            deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Debug products to identify and repair errors that impair
            existing intended functionality.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Exercise free speech, ensure the right of another
            consumer to exercise their free speech rights, or exercise another right provided for by law.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Comply with the California Electronic Communications
            Privacy Act (Cal. Penal Code § 1546 seq.).</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Enable solely internal uses that are reasonably aligned
            with consumer expectations based on your relationship with us.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Comply with a legal obligation.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Make other internal and lawful uses of that information
            that are compatible with the context in which you provided it.</p>
        </li>
      </ul>
      <h4 class="text-accent text-semibold fs-subtitle my-6">Opt-Out</h4>
      <p class="text-content text-regular fs-content pb-3">You have the right to request that Equifax no longer sell
        your personal information.</p>

      <h4 class="text-accent text-semibold fs-subtitle my-6">Submit a Verifiable Request</h4>
      <p class="text-content text-regular fs-content pb-3">To make a verifiable consumer request, visit myPrivacy or
        call our Customer Care team at +1 866-322-4119 (our regular business hours of 8 A.M. - Midnight EST, 7 days a
        week). To exercise your right to opt-out of the sale of your personal
        information, you may click here: Do Not Sell My Personal Information or call our Customer Care team at the
        number listed above.</p>

      <p class="text-content text-regular fs-content pb-3">To ensure the security of personal information and prevent
        fraudulent requests, we may need to collect personal information and other information such as your name, email,
        or transaction history to verify your identity. If an authorized
        {{agentTerm}} is making a request on your behalf, they must provide your information for verification.</p>
      <h4 class="text-accent text-semibold fs-subtitle my-6">Authorized Agent</h4>
      <p class="text-content text-regular fs-content pb-3">Authorized Agents making requests on behalf of a California
        Resident need to contact us to process the request. To submit a request, please click here: Privacy Contact
        Form and use the "CCPA Authorized Agent" selection in the Contact Form.</p>
      <h4 class="text-accent text-semibold fs-subtitle my-6">Right to be Free from Discrimination</h4>
      <p class="text-content text-regular fs-content pb-3">Equifax is prohibited from discriminating against in any way
        in response to your exercise of any of these rights. This means we will not:</p>
      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">Deny you goods or services.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Charge you different prices or rates for goods or
            services, including through granting discounts or other benefits, or imposing penalties.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Provide you a different level or quality of goods or
            services.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Suggest that you may receive a different price or rate
            for goods or services or a different level or quality of goods or services.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Offer a product enhancement or financial incentive that
            is contingent on you sharing personal information, unless that incentive is reasonably related to the value
            provided to us by that collection.</p>
        </li>

      </ul>
      <h4 class="text-accent text-semibold fs-subtitle mb-2">Individual Rights Requests Metrics Reporting</h4>
      <p class="text-content text-regular fs-content">
        The following metrics are representative of individual rights requests submitted by California consumers for the
        period of January 1, 2020 to December to December 31, 2020.</p>


      <table class="table-auto border-collapse w-full my-6" aria-describedby="California-privacy">
        <thead>
          <tr>
            <th class="text-content text-regular fs-content border border-gray-300 py-3 px-6" scope="row">
              <h4 class="text-accent text-left text-semibold fs-subtitle">
                Request Type</h4>
            </th>
            <th class="text-content text-regular fs-content border border-gray-300 py-3 px-6" scope="row">
              <h4 class="text-accent text-left text-semibold fs-subtitle px-6">Total Received</h4>
            </th>
            <th class="text-content text-regular fs-content border border-gray-300 py-3 px-6" scope="row">
              <h4 class="text-accent text-left text-semibold fs-subtitle">Total Complied With (%)</h4>
            </th>
            <th class="text-content text-regular fs-content border border-gray-300 py-3 px-6" scope="row">
              <h4 class="text-accent text-left text-semibold fs-subtitle">Total Denied</h4>
            </th>
            <th class="text-content text-regular fs-content border border-gray-300 py-3 px-6" scope="row">
              <h4 class="text-accent text-left text-semibold fs-subtitle">Average Days to Complete</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="align-top">
            <td class="text-content text-regular fs-content border border-gray-300 p-6">Right to Know</td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6">N/A
            </td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6"></td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6"></td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6"></td>
          </tr>
          <tr class="align-top">
            <td class="text-content text-regular fs-content border border-gray-300 p-6">Right to Opt-out</td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6">N/A
            </td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6"></td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6"></td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6"></td>
          </tr>
          <tr class="align-top">
            <td class="text-content text-regular fs-content border border-gray-300 p-6">Right to Delete</td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6">N/A
            </td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6"></td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6"></td>
            <td class="text-content text-regular fs-content border border-gray-300 p-6"></td>
          </tr>

        </tbody>
      </table>
      <p class="text-content text-regular fs-content">
        Last Updated January 1, {{getCurrentYear()}}.
      </p>
    </div>
  </div>
</main>