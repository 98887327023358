import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

/**
 * Manager's Service
 */
export class ManagerService {
  private BASE_URL:string;


  /**
   * constructor
   */
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.BASE_URL;
  }

  /**
   * Get managers
   * @return {Observable}
   */
  public getManagers():Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent-list?type=manager`);
  }

  /**
   *  list for managers
   * @param{number}page
   * @param{number}length
   * @param{string}sort
   * @param{string}search
   *
   * @return {Observable}
   */
  public getManagersList(page?:number, length?:number, sort?:string, search?:string):Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent-list-paginate?type=manager&page=${page}&length=${length}&sort=${sort}&search=${search}`);
  }

  /**
   * @param {any} data
   * @return {Observable}
   * add business organization type
   */
  public addManager(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/agent-save?type=manager`, data);
  }
  /**
   * @param {any} data
   * @return {Observable}
   * view business organization type
   */
  public viewAgent(data:any):Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent?type=manager${data}`);
  }
  /**
   * @param {any} data
   * @return {Observable}
   * edit business organization type
   */
  public editAgent(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/agent-update?type=manager`, data);
  }


  /**
   * @param {any} data
   * @return {Observable}
   * delete business organization type
   */
  public deleteAgent(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/agent?type=manager/${data}`, null);
  }
  /**
   * Get all agents
   *
   *
   * @return {Observable}
   */
  public getAllAgents():Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent-list?type=manager`);
  }


  /**
   * pagination for agents
   * @param{string}search
   *
   * @return {Observable}
   */
  public getAgentsPerSearch(search?:string):Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent-list-paginate?type=manager&search=${search}`);
  }
}
