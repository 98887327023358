import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CommonHelper } from './common.helper';


@Injectable({
  providedIn: 'root',
})
/**
 * This component helper makes the population of validation messages in templates dynamic, cleaning up the HTML. It also
 * makes it easy to edit the validation texts from one place.
 */
export class ValidationHelper {
  private errorText: Object = {};

  // Defines text which can be used globally across the platform. If the error key is provided to {@link setValidationErrors}, this will be ignored.
  private static readonly globalErrorText: ErrorsModel = {
    required: () => `Field is required`,
    /**
     * This will find offending characters in a pattern validation. Good for patterns which want to exclude certain characters.
     * Will not work well for specific format validation, like with {@link WEBSITE_PATTERN_NEW}.
     */
    pattern: (error) => {
      // Remove end-of-string anchor
      const reqPattern: string = error['requiredPattern'].endsWith('$') ? error['requiredPattern'].slice(0, -1) : error['requiredPattern'];
      const regex: RegExp = new RegExp(reqPattern, 'g');
      let value: string = error['actualValue'];
      const invalidChars: string[] = [];

      // Find Offending Characters
      while (value.length > 0) {
        const matches = value.match(regex)?.filter((e) => e != '') ?? [];
        if (matches?.length > 0) {
          let uniqueMatches = [...new Set(matches)].join('');
          uniqueMatches = CommonHelper.escapeRegExp(uniqueMatches); // Escape any special characters.
          value = value.replace(new RegExp(uniqueMatches, 'g'), '');
        } else {
          if (!invalidChars.includes(value[0])) {
            invalidChars.push(value[0]);
          }
          value = value.slice(1);
        }
      }
      invalidChars.sort();

      // If plural
      const s = invalidChars.length > 1 ? 's' : '';
      return `Invalid Character${s}: ${invalidChars.join(', ')}`;
    },
    minlength: (error) => `Minimum ${error['requiredLength']} characters required`,
    maxlength: (error) => `Maximum ${error['requiredLength']} characters allowed`,
    invalidCurrency: () => `Please enter a valid currency amount`,
    dateInvalid: () => `Invalid Date`,
    bsDate: (error) => (error.invalid ? 'Invalid Date' : `Unhandled bsDate Error: ${Object.keys(error)}`),
  };

  /**
   * Set the validation errors in the form of<br>
   * <code>{ control: {error: "This is an error"} }</code><br>
   * This can contain any number of keys. If a key provided already exists, it will be overwritten. Existing keys which
   * are not overwritten will not be removed.<br>
   * Refer to {@link globalErrorText} to see which texts have a
   * @param errors
   */
  public setValidationErrors(errors: Object) {
    for (const errorsKey in errors) {
      this.errorText[errorsKey] = errors[errorsKey];
    }
  }

  /**
   * Returns an {@link Array} of validation error messages as defined in {@link errorText} for {@link form}.
   * If {@link checkTouched} is <code>true</code>, then the errors will only be returned if the control has been marked
   * as touched.
   * @param controlKey
   * @param form
   * @param checkTouched
   * @return An array of strings matching failed validation errors as defined by {@link setValidationErrors}. If no validation errors have failed, an empty array will be returned<br>
   * If {@link checkTouched} is true, then the failed validation errors will only be populated if the control has been touched.<br>
   * If {@link form} is null or undefined, then undefined will be returned.
   */
  public getErrors(controlKey: string, form: FormGroup, checkTouched = true): Array<string> {
    const arrErrors: Array<string> = [];
    const control = form?.get(controlKey);
    if (control?.errors) {
      const touched: boolean = !checkTouched || control.touched;
      const errors: ValidationErrors = touched ? control.errors : [];
      for (const errorKey in errors) {
        if (this.errorText[controlKey]?.[errorKey] != '' && this.errorText[controlKey]?.[errorKey] !== null) {
          const errorText =
            this.errorText[controlKey]?.[errorKey] ??
            this.getGlobalErrorText(errorKey)(errors[errorKey]) ??
            `!Missing Error Text [${controlKey}.${errorKey}]!`;
          arrErrors.push(errorText);
        }
      }
    }
    return arrErrors;
  }

  /**
   * @return The {@link globalErrorText} function for the given {@link errorKey}. If it doesn't exist, returns a function which returns undefined.
   * @param errorKey
   * @private
   */
  private getGlobalErrorText(errorKey): ((a?) => any) {
    return ValidationHelper.globalErrorText[errorKey] ?? (() => undefined);
  }

  /**
   * Custom Validation functions
   */
  fn = {
    /**
     * Interprets `control.value` as a Date, then compares to `minDate` and `maxDate`.
     * If either `minDate` or `maxDate` are undefined, then it will be treated as no limit.
     * @returns `invalidYear: true` if the value is not within the range of `minDate` and `maxDate`.<br>
     * `yearLow: true` if the value is lower than `minDate`. <br>
     * `yearHigh: true` if the value is higher than `maxDate`.
     * @param minDate
     * @param maxDate
     */
    dateRange: (minDate?: Date, maxDate?: Date): ValidatorFn => {
      return (control: FormControl): { [s: string]: any } => {
        if (!control.value) {
          return null;
        }

        if (minDate && maxDate && minDate.getTime() > maxDate.getTime()) {
          throw new Error('minDate is greater than maxDate!');
        }
        const controlValue = new Date(control.value);

        // Set the time components (hours, minutes, seconds, and milliseconds) to zero
        minDate?.setHours(0, 0, 0, 0);
        maxDate?.setHours(0, 0, 0, 0);
        controlValue?.setHours(0, 0, 0, 0);

        const year = controlValue.getTime();
        const low = minDate && year < minDate.getTime() && year !== 0;
        const high = maxDate && year > maxDate.getTime();
        return low || high ? { dateInvalid: true, ...(low && { dateLow: true }), ...(high && { dateHigh: true }) } : null;
      };
    },

    /**
     * Will automatically trim whitespace from the input on update-validity.
     * @param control
     */
    trim: (control: AbstractControl): null => {
      const r = { startWhitespace: /^\s/, endWhitespace: /\s$/ };
      const value: string = control.value;
      if (!value || value.length == 0) return null;
      if (r.startWhitespace.test(value) || r.endWhitespace.test(value)) {
        control.setValue(value.trim(), { emitEvent: false });
        control.updateValueAndValidity();
      }
      return null;
    },

    /**
     * Returns the results of {@link Validators.required},
     * except when `exceptFn` is `true`, in which case `null` is returned.
     *
     * @param exceptFn Function that returns a boolean indicating whether the validation should be skipped.
     * @returns A validator function.
     */
    requiredExcept: (exceptFn): ValidatorFn => (control) => exceptFn() ? null : Validators.required(control),

    /**
     * Returns a validator function to check if the provided date is a future date.
     *
     * Parses the date from the control's value and compares it to the current date.
     * If the date is in the future, a {@link ValidationErrors} object with the key 'future_date'
     * and a value of `true` is returned; otherwise, returns `null`.
     *
     * @returns A validator function for ensuring the date is in the future.
     */
    isFutureDate: (): ValidatorFn => {
      return (control): ValidationErrors | null => {
        const date = Date.parse(control.value);
        const todayDate = (new Date()).valueOf();
        return date < todayDate ? { future_date: { value: true } } : null;
      };
    },

    /**
     * Returns a validator function to check if the provided date is not a future date.
     *
     * Parses the date from the control's value and compares it to the current date.
     * If the date is in the future, a {@link ValidationErrors} object with the key 'future_date'
     * and a value of `true` is returned; otherwise, returns `null`.
     *
     * @returns A validator function for ensuring the date is not in the future.
     */
    isNotFutureDate: (): ValidatorFn => {
      return (control): ValidationErrors | null => {
        const date = Date.parse(control.value);
        const todayDate = (new Date()).valueOf();
        return date > todayDate ? { future_date: { value: true } } : null;
      };
    },

    /**
     * check same email - Account Settings (Dashboard of Consumer, Professional, Executor/Custodian, Organization)
     * @return {boolean}
     */
    /**
     * Returns a validator function to check if a new email is the same as the old email.
     *
     * Compares the new email to the old email and returns a {@link ValidationErrors} object
     * with the key 'same_email' set to `true` if they are not different.
     *
     * @returns A validator function for checking if the new email is the same as the old email.
     */
    checkSameEmail: (): ValidatorFn => {
      // Compare new email to old email, return <code>same_email</code> {@link ValidationErrors} if they are not different.
      return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) {
          return null;
        }
        const isSame: boolean = control.value == control.parent?.value.old_email;
        return isSame ? { same_email: true } : null;
      };
    },

    /**
     * SSN pattern validator. Compares the control value with {@link regex} and checks for repeating values.
     * @param{RegExp} regex
     * @return{ValidatorFn}
     * returns {@link ValidationErrors ValidationError} <code>pattern</code> if the regex test fails, or if the value
     * contains a repeating value.
     */
    SSNPatternValidator: (regex: RegExp): ValidatorFn => {
      return (control: AbstractControl): ValidationErrors => {
        const error = { pattern: true };
        if (!control.value) {
          return null;
        }
        const valid = regex.test(control.value);
        const formControlValue = String(control.value).split('-').join('');
        if (valid) {
          for (let i = 0; i <= formControlValue.length; i++) {
            const result = String(i).repeat(formControlValue.length); // check continuous series values for eg: '11-111-1111'
            if (result === formControlValue) {
              return error;
            }
          }
        }
        return valid ? null : error;
      };
    },
  };

  /**
   * whitespace validator
   * @returns whitespace validator 
   */
  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const regex = /^\s*$/;
      const isWhiteSpace = regex.test(control.value);
      if(control.value?.length && isWhiteSpace) {
        return { whitSpaceValue: true };
      }
    };
  }

  /**
   * Trims whitespace
   * @param control 
   * @returns whitespace 
   */
  trimWhitespace(control: AbstractControl): void {
    const value: any = control.value;
    if (typeof value !== 'string') return;
  
    const trimmedValue = value.trim();
    if (value !== trimmedValue) {
      control.setValue(trimmedValue, { emitEvent: false });
      control.updateValueAndValidity();
    }
  }
}


interface ErrorsModel {
  [key: string]: (e?: any) => string;
}



