<main class="relative">

  <!-- BEGIN: Navbar Wrapper -->
  <app-nav-landing-pro></app-nav-landing-pro>
  <!-- END: Navbar Wrapper -->

  <!-- BEGIN: Banner Wrapper -->
  <!-- <div class="banner--height"> -->
    <div class="contact-banner__wrap relative flex items-center justify-center banner-padding-hfull mh-700-height contact-form-banner">
      <div class="container mx-auto z-30">
        <div class="flex items-center text-white">
          <div class="grid grid-cols-12 gap-4 xl:gap-12">
            <div class="col-span-12 lg:col-span-6 mt-8 lg:mt-12" *ngIf="professionalType==='Wealth Management'">
                <h1 class="contact-banner__head text-semibold text-white fs-bhead text-regular">Bring estate planning to life
                  with LegacyNOW.</h1>
                <p
                  class="contact-banner__content fs-content mt-4 text-regular leading-relaxed text-regular">
                  Please submit your info and a team member will contact to and share how LegacyNOW will help you and your
                  clients.</p>
              </div>
          <div class="col-span-12 lg:col-span-6 mt-8 lg:mt-12"
            *ngIf="professionalType!=='Wealth Management'">
            <h1 class="contact-banner__head text-semibold text-white fs-bhead text-regular">
              Bring estate data management to your clients with LegacyNOW.
            </h1>
            <p
              class="contact-banner__content fs-content mt-4 text-regular leading-relaxed text-regular">
              Please submit your info and our team
              will be in touch to help you go deeper into your client's wealth and estate planning.</p>
          </div>
          <div class="col-span-12 lg:col-span-6 xl:col-span-6 contact-form reachus-form relative">
            <div
              class="bg-white b-contact-card lg:max-w-lg xl:max-w-full lg:absolute lg:top-8 mt-8 lg:mt-0 p-5 sm:p-8 xl:p-14">
              <h1 class="mb-6 text-semibold text-primary fs-bhead text-regular">
                Trying to reach us?</h1>
                <!-- <div #contactForm></div> -->
              <form [formGroup]="contactUsProForm" (ngSubmit)="onSubmit()" autocomplete="off">
                <div class="grid grid-cols-12 gap-1 lg:gap-2">
                  <div class="col-span-12 md:col-span-4">
                    <label for="first-name" class="text-grey text-regular fs-label">First Name *</label>
                    <input type="text" name="first-name" id="first-name" formControlName="first_name"
                           [ngClass]="{'border-red' : formGet?.first_name?.errors && formGet?.first_name?.touched}"
                           class="h-12 lg:h-14 px-4 w-full text-regular text-content shadow-sm border border-input rounded-md focus:outline-none">
                    <p *ngFor="let error of getErrors('first_name')"
                       class="text-regular font-medium text-xs mt-2 error-alert">
                      {{error}}
                    </p>
                  </div>
                  <div class="col-span-12 md:col-span-4">
                    <label for="middle_name" class="text-grey text-regular fs-label">Middle Name </label>
                    <input type="text" name="middle_name" id="middle_name" formControlName="middle_name"
                           [ngClass]="{'border-red' : formGet?.middle_name?.errors && formGet?.middle_name?.touched}"
                           class="h-12 lg:h-14 px-4 w-full text-regular text-content shadow-sm border border-input rounded-md focus:outline-none">
                    <p *ngFor="let error of getErrors('middle_name')"
                       class="text-regular font-medium text-xs mt-2 error-alert">
                      {{error}}
                    </p>
                  </div>
                  <div class="col-span-12 md:col-span-4">
                    <label for="last_name" class="text-grey text-regular fs-label">Last Name *</label>
                    <input type="text" name="last_name" id="last_name" formControlName="last_name"
                           [ngClass]="{'border-red' : formGet?.last_name?.errors && formGet?.last_name?.touched}"
                           class="h-12 lg:h-14 px-4 w-full text-regular text-content shadow-sm border border-input rounded-md focus:outline-none">
                    <p *ngFor="let error of getErrors('last_name')"
                       class="text-regular font-medium text-xs mt-2 error-alert">
                      {{error}}
                    </p>
                  </div>

                  <div class="col-span-12">
                    <label for="company_name" class="text-grey text-regular fs-label">Company Name *</label>
                    <input type="text" name="company_name" id="company_name" formControlName="company_name"
                           [ngClass]="{'border-red' : formGet?.company_name?.errors && formGet?.company_name?.touched}"
                           class="h-12 lg:h-14 px-4 w-full text-regular text-content shadow-sm border border-input rounded-md focus:outline-none">
             
                           <p *ngFor="let error of getErrors('company_name')"
                       class="text-regular font-medium text-xs mt-2 error-alert">
                      {{error}}
                    </p>
                  </div>
                  <div class="col-span-12">
                    <label for="email" class="text-content text-regular fs-label">Email *</label>
                    <input type="email" name="email" id="email" placeholder="e.g. marktylor@gmail.com"
                      formControlName="email"
                      class="h-12 lg:h-14 px-4 w-full text-regular text-content shadow-sm border border-input rounded-md focus:outline-none">
                      <p *ngFor="let error of getErrors('email')"
                      class="text-regular font-medium text-xs mt-2 error-alert">
                     {{error}}
                      </p>
                  </div>
                  <div class="col-span-12">
                    <label for="phone-no" class="text-content text-regular fs-label">Phone Number</label>
                    <app-phone-input [placeholder]="'e.g. 8665044754'" [classList]="true" id="phone-no"
                      [phoneNumber]="contactUsProForm.value.phone" [countryCode]="countryCode"
                      [uniqueId]="'phone-contact-pro'"
                      (onChange)="detectChangePhonenumber($event)"
                      [errorClassTrigger]="formGet.phone.errors && formGet.phone.touched"
                      [form]="contactUsProForm" (focusout)="formGet.phone.markAsTouched()">

                    </app-phone-input>
                    <p *ngFor="let error of getErrors('phone')"
                    class="text-regular font-medium text-xs mt-2 error-alert">
                   {{error}}
                    </p>
                  </div>
                  <!-- <div class="col-span-12">
                    <label for="date_time" class="text-content text-regular fs-label">Date & Time *</label>
                    <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                      <input type="text" id="date_time"
                            #dp=bsDatepicker [dp]="dp" bsDatepicker [bsConfig]="bsConfig" name="date_time"
                        formControlName="date_time"
                        class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                        placeholder="{{domainDateFormat}}, HH:MM:AA"
                        [ngClass]="formGet.date_time.errors && formGet.date_time.touched?'form-input-error':'form-input-focus'">
                    </app-shimmer-loading>
                    <div *ngIf="formGet.date_time.errors && formGet.date_time.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.date_time.errors.required">
                        Date & Time is required
                      </p>
                    </div>

                  </div> -->
                  <ng-container *ngIf="typesList.length>0">
                    <div class="col-span-12">
                      <label class="text-content text-regular fs-label">
                        Please select one or more of the following contact reasons *:
                      </label>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-4"
                      *ngFor="let type of formArrayGet.controls;let i = index">
                      <div formArrayName="type" class="flex items-center h-5">
                        <input [id]="this.typesList[i]?.name" name="type" type="checkbox"
                          class="mr-2 h-4 w-4 lg:h-5 lg:w-5 text-regular text-content border-checkbox rounded"
                          [formControlName]="i">
                        <label [for]="this.typesList[i]?.name"
                          class="text-content text-regular fs-label">{{this.typesList[i]?.name}}</label>
                      </div>
                    </div>
                    <div class="col-span-12" *ngIf="formGet.type.errors && formGet.type.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.type.errors && formGet.type.errors.requireCheckboxesToBeChecked">
                        At least one checkbox is required to check
                      </p>
                    </div>
                  </ng-container>
                  <div class="col-span-12 mt-1">
                    <label for="description" class="text-content text-regular fs-label">
                      Description *
                    </label>
                    <div class="mt-1">
                      <textarea id="description" name="description" rows="5"
                        class="p-4 shadow-sm text-regular text-content w-full border border-checkbox rounded-md rounded-md focus:outline-none"
                        [ngClass]="{'border-red' : formGet?.description?.errors && formGet?.description?.touched}"
                        placeholder="Please describe your question or comment here."
                        formControlName="description"></textarea>
                        <p *ngFor="let error of getErrors('description')"
                        class="text-regular font-medium text-xs mt-2 error-alert">
                       {{error}}
                       </p>
                    </div>
                  </div>
                  <div class="col-span-12 mt-4">
                    <div class="btn-wrap flex items-left justify-start lg:items-center lg:justify-center">
                      <app-button-loader [buttonText]="'Submit Your Request'"
                        [buttonClass]="'button-primary button-submit w-full whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white'"
                        [valid]="contactUsProForm.valid" [loader]="submitLoader"
                        buttonType="'submit'"></app-button-loader>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->

  <!-- END: Banner Wrapper -->

  <div class="section">
    <div class="container mx-auto">
      <div class="grid grid-cols-12">
        <div class="col-span-12 lg:col-span-4 contact__info">
          <h4 class="fs-title text-primary text-semibold">Have a Question</h4>
          <p class="text-content fs-content text-regular mb-4"
            *ngIf="professionalType==='Wealth Management'">Talk to our support team at</p>
          <p class="text-content fs-content text-regular mb-4"
            *ngIf="professionalType!=='Wealth Management'">Talk to our support team or Chat with
            us now or call us at</p>
          <ul class="leading-10">
            <li>
              <div class="flex items-center">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="black" class="bi bi-dot"
                    viewBox="0 0 16 16">
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                  </svg>
                </div>
                <div>

                  <a href="tel:+1{{customerSupportPhone}}" class="text-regular text-semibold fs-content">

                    +1 {{customerSupportPhone}}
                  </a>
                </div>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="black" class="bi bi-dot"
                    viewBox="0 0 16 16">
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                  </svg>
                </div>
                <div>

                  <a href="mailTo:{{customerSupportMail}}" class="text-semibold text-regular fs-content">

                    {{customerSupportMail}}</a>
                </div>
              </div>
            </li>
            <li>
              <div class="flex items-center mt-2">
              <p class="text-lg md:text-xl lg:text-2xl text-content text-regular leading-8 pt-2">Hours of Operation: <br>Monday to Friday 9am to 5pm</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- BEGIN: Testimonial Wrapper-->
  <div class="section section-even">
    <app-testimonials [mobile]="mobile"></app-testimonials>
  </div>

  <!-- END: Testimonial Wrapper -->

  <!-- BEGIN: Advisor Insight Inbox -->
  <!-- <div class="section insight-inbox__wrap">
    <div class="container mx-auto">
      <div class="col-start-2 col-span-4 ...">
        <div class="title__wrap text-center pb-8 mx-12">
          <h1 class="text-primary text-semibold fs-title text-regular pb-4 ">For Advisors Insights sent direct to your
            inbox</h1>
          <p class="fs-content text-regular text-content mx-8">Just sign up here and you'll receive news and articles
            that will help better serve your clients and out perform your competition.</p>
        </div>
        <div class="col-span-12" *ngIf="mobile">
          <form action="#" method="post"
            class="flex flex-wrap items-center justify-between rounded-md xl:h-16 md:flex-row">
            <div class="relative w-full bg-transparent border rounded-md mx-6 md:w-5/6 lg:w-3/5">
              <input type="text" placeholder="Enter your Name"
                class="flex-1 h-10 w-full text-content fs-content px-4 mx-2 p-2 m-1 text-gray-700 placeholder-gray-400 border-none appearance-none lg:h-12 focus:outline-none">
            </div>
            <button type="submit"
              class="flex items-center justify-center text-semibold text-sm text-white w-full sm:mx-2 p-2 m-1 rounded-md md:h-12 md:mx-6 lg:text-xl xl:h-12 sm:text-base md:p-0 bg-primary focus:outline-none ">
              Let Me Know
            </button>
          </form>
        </div>
        <div class="col-span-12" *ngIf="!mobile">
          <div class="bg-transparent border rounded-md mx-auto md:w-5/6 lg:w-3/5">
            <form action="#" method="post"
              class="flex flex-wrap items-center justify-between rounded-md xl:h-16 md:flex-row bg-white">
              <div class="relative w-full">
                <input type="text" placeholder="Enter your Name"
                  class="flex-1 h-10 text-content text-sm md:text-lg md:px-4 m-1 text-gray-700 placeholder-gray-400 border-none appearance-none lg:h-12 focus:outline-none ">
                <div class="absolute top-1 right-3 md:right-10">
                  <button type="submit"
                    class="flex items-center justify-center text-semibold text-sm text-white w-full sm:mx-2 p-2 m-1 rounded-md md:h-12 md:mx-6 lg:text-xl xl:h-12 sm:text-base md:p-0 bg-primary focus:outline-none ">
                    Let Me Know
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- END: Advisor Insight Inbox -->

  <!-- BEGIN: Footer Wrapper -->
  <app-footer-landing-pro></app-footer-landing-pro>
  <!-- END: Footer Wrapper -->
</main>


<div class="fixed right-6 bottom-6 group z-50">
  <button type="button" aria-expanded="false" (click)="openModal('assistanceHelp')"
    class="flex items-center justify-center text-white bg-blue-700 rounded-full w-16 h-16 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
    <img src="assets/images/intro-video.png" alt="">
    <span class="sr-only">Open actions menu</span>
  </button>
</div>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>
