<div class="scroll-hidden">
  <!-- Navbar -->
  <app-navbar *ngIf="this.userType === 'Consumer'"></app-navbar>
  <app-exe-cus-navbar *ngIf="this.userType === 'Custodian'"></app-exe-cus-navbar>
  <app-professional-navbar [showReturnToDashboard]="true" *ngIf="this.userType !== 'Consumer'&& this.userType!=='Custodian'"></app-professional-navbar>

  <!-- Router outlet -->
  <div class="lg:grid grid-cols-12 background-color-grey">
    <!-- BEGIN: Leftside Content -->
    <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
      <div class="flex flex-col w-full">
        <div class="h-full items-center justify-center">
          <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
            <div class="h-full justify-center items-center ">
              <div class="grid grid-cols-12 items-center gap-4 my-4">
                <div class="col-span-12 sm:col-span-2">
                  <div class="w-16 my-2">
                    <img src="assets/images/digital-files/upload-will.svg" class="w-20" alt="digital vault">
                  </div>
                </div>

                <div class="col-span-12 sm:col-span-10">
                    <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
                      Upload Legal Documents
                    </h2>
                    <p class="text-sm text-grey tracking-wide text-regular">Your legal document will be saved on
                      blockchain and sent to your
                      beneficiary and executors securely
                    </p>
                </div>
              </div>
              <!-- BEGIN: Leftside Card -->
              <div class="border-card bg-white rounded-md">
                <!-- BEGIN: Progress Bar -->
                <app-card-progress-bar [progressValue]="'w-full'"></app-card-progress-bar>
                <!-- END: Progress Bar -->

                <div class="p-6">
                  <div class="grid grid-cols-12 items-center gap-4 row-gap-4">
                    <div class="col-span-12">
                      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                        Upload Document
                      </h4>
                      <p class="text-grey text-regular pt-1 text-sm">Upload Wills, Trusts, Power of Attorneys, Health
                        Power of Attorneys, and other
                        important legal documents.
                      </p>
                      <p class="text-grey text-regular pt-1 text-sm">
                        Please select a document type to begin uploading your files.
                      </p>
                      <p class="text-grey text-regular pt-1 text-sm">
                        <span class="font-sans font-bold">Accepted File Formats</span> — jpeg, jpg, png, doc, docx, txt, pdf, mp3, mp4, avi,
                        mov, flv, mkv.
                      </p>
                      <p class="text-grey text-regular pt-1 text-sm">
                        <span class="font-sans font-bold">Maximum File Size</span> — 100 MB.
                      </p>
                      <app-payment-message-text [clientHasPaidSubscription]="clientHasPaidSubscription"></app-payment-message-text>
                    </div>
                  </div>
                  <form [formGroup]="documentForm" autocomplete="off">
                    <div class="col-span-12 relative mt-5">
                      <div class="col-span-12 lg:col-span-2 relative">
                        <label for="document-type" class="text-grey text-sm text-regular">Document Type*
                        </label>
                        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                          <app-select-component [options]="documentTypes" id="document-type"
                            [selectedOptionDefault]="documentForm?.value?.['document_type']" [disabled]="!canAccessForm || !canEdit"
                            [placeholder]="'Select Document Type'" (optionChangeEmitter)="optionChangeEmitter($event)"
                            [ifError]="formGet.document_type.errors && formGet.document_type.touched"
                            (toggleEmiter)="toggleEmitter($event)"></app-select-component>
                          <div *ngIf="formGet.document_type.errors && formGet.document_type.touched">
                            <p class="text-regular font-medium text-xs mt-2 error-alert"
                              *ngIf="formGet.document_type.errors.required">
                              Document type is required
                            </p>
                          </div>
                        </app-shimmer-loading>
                      </div>
                    </div>
                    <div *ngIf="useOther" class="col-span-12 mt-5">
                      <label for="document_type_other" class="text-label-grey text-sm text-regular">Define Other</label>

                      <div class="relative flex items-center">
                        <input type="text" id="document_type_other"
                          class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                          name="document_type_other" formControlName="document_type_other" placeholder="Define Other"
                          [ngClass]="formGet.document_type_other.errors && formGet.document_type_other.touched?'form-input-error':'form-input-focus'">
                      </div>
                      <div *ngIf="formGet.document_type_other.errors && formGet.document_type_other.touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.document_type_other.errors['pattern']">
                          Enter Alphabet only
                        </p>
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.document_type_other.errors['minlength'] && !formGet.document_type_other.errors['pattern']">
                          Minimum 3 characters required
                        </p>
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.document_type_other.errors['maxlength'] && !formGet.document_type_other.errors['pattern']">
                          Maximum 25 characters allowed
                        </p>
                      </div>
                    </div>

                    <div class="col-span-12 mt-4">
                      <app-file-listing
                        [fileArray]="willFilesArray"
                        [permissions]="permissions"
                        [isDisabled]="!hasPaidSubscription && !clientHasPaidSubscription"
                        [isLoading]="preDefinedLoader"
                        [hideDeleteIcon]="hideDeleteIcon"
                        [fileUploadSettings]="hasDocumentType ? fileUploadSettings : undefined"
                        [fileUploadPercent]="filePercentage"
                        (fileListingEmitter)="fileListingListener($event)">
                      </app-file-listing>
                    </div>
                  </form>

                  <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
                    <button (click)="click('Back')"
                      class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                      <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault">
                      Back
                    </button>
                    <div *ngIf="!buttonProgress" class="flex flex-col-reverse sm:flex-row justify-end items-center gap-4 w-full">
                      <button (click)="click('SaveForLater')"
                        [disabled]="preDefinedLoader || !canAccessForm || filePercentage!=0"
                        class="flex items-center justify-center border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 text-regular px-6">
                        <div class="loading-blue mr-2" *ngIf="preDefinedLoader"></div>
                        Save for Later
                      </button>
                      <button (click)="click('MarkComplete')" [tooltip]="'You can update later should this information change'"
                        [disabled]="preDefinedLoader || !canAccessForm || filePercentage!=0"
                        class="flex items-center justify-center mark-complete-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 text-regular px-6">
                        <div class="loading-blue mr-2" *ngIf="preDefinedLoader"></div>
                        Mark as Complete
                      </button>
                    </div>
                    <div *ngIf="buttonProgress" class="flex flex-col-reverse sm:flex-row justify-end items-center gap-4 w-full">
                      <button (click)="click('ReturnToDashboard')" [disabled]="preDefinedLoader"
                        class="text-sm tracking-wide w-full sm:w-auto bg-darkblue hover:underline-text rounded-xs h-10 focus:outline-none text-regular text-white px-6 text-bold">
                        Return to Dashboard
                      </button>
                    </div>
                  </div>
                </div>
                <!-- END: Leftside Card -->
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- END: Leftside Content -->

    <!-- BEGIN: Rightside Content -->
    <div class="col-span-12 md:col-span-6 lg:grid justify-center">
      <about-yourself-right-content [formInputValue]="{}"
        [otherValues]="sectionSaveExitOptions"></about-yourself-right-content>
    </div>
    <!-- END: Rightside Content -->
  </div>
</div>
<app-payment-status-modal></app-payment-status-modal>
<app-payment-success></app-payment-success>
<app-payment-failure></app-payment-failure>
