import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonService } from 'src/app/services/common.service';
import { PeopleService } from 'src/app/services/people.service';
import { SlugService } from 'src/app/services/slug.service';
import { environment } from '../../../../../../environments/environment';
import { CommonModelService } from '../../../../../services/common-model.service';
import { LocalStorageService } from '../../../../../services/local-storage.service';

@Component({
  selector: 'app-nav-landing-pro',
  templateUrl: './nav-landing-pro.component.html',
})

/**
 * professional landing page navbar
 */
export class NavLandingProComponent implements OnInit {
  @Input() bannerPage: boolean = false;
  @Input() showLoginBtn:boolean = false;
  public proType: string;
  public menuActive: string;
  public menuAlternate: boolean;
  public responsiveMenuToggle: boolean;

  public videoSourceURL: string;
  public orgLogoPath: string;
  public orgLogo: string;
  public videoModalID: string = 'pro-navbar-video';
  public proTypes: Array<any>;
  public proTypeText: string;
  public tempProTypeText: string;


  /**
   * constructor
   */
  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private modalService: CommonModelService,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    private slugService: SlugService,
    private localStorageService: LocalStorageService,
    private peopleService: PeopleService
  ) {

  }

  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      } else {
        this.orgLogoPath = '';
        this.orgLogo = '';
      }
    });
    this.responsiveMenuToggle = false;
    this.commonService.navbarObserve.subscribe((response) => {
      if (response) {
        this.menuActive = response;
      }
    });
    this.menuActive = this.route.snapshot.url[this.route.snapshot.url.length - 1]?.path;
    this.menuAlternate = ['privacy-policy', 'acceptable-use-policy', 'digital-vault-agreement', 'digital-vault-licensing-agreement', 'user-agreement', 'california-privacy-policy'].indexOf(this.menuActive) < 0;
    const ls_proType = this.localStorageService.getDataByKey('pro-type');
    if (ls_proType) {
      this.proType = this.localStorageService.getDataByKey('pro-type')[0]['displayText'];
    }
    this.tempProTypeText = 'wealth management';
    this.getProfessionalTypes();
  }

  /**
   * get advisor types
   */
  public getProfessionalTypes() {
    this.peopleService.getAdvisorTypes()?.subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.proTypes = this.commonHelper.convertToOptionsFormat(response?.data, 'id', 'name');
        const seletedProType = this.proTypes.filter((cur) => cur.displayText.toLowerCase() === this.tempProTypeText);
        this.localStorageService.storeData('pro-type', seletedProType);
        this.proTypeText = seletedProType[0]?.displayText;
        this.proType = seletedProType[0]?.value;
      }
    });
  }
    /**
   * @param{string}url
   * on menu change
   */
    public menuChange(url?: string) {
      this.menuActive = url;
      void this.slugInterceptorService.navigate(url ? [url] : ['/'], null, true);
      this.commonService.setActiveNavbarTrigger(this.menuActive);
    }

  /**
   * Display intro video modal
   */
  public viewIntroVideo() {
    this.modalService.open('view-video-modal');
  }
}
