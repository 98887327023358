import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { PARTNER_RELATIONSHIP_STATUS, PEOPLE_SALUTATIONS } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ReceivedDlbService } from '../../../services/received-dlb.service';
import { CheckoutService } from '../../checkout/checkout.service';


@Component({
  selector: 'app-about-yourself-overview',
  templateUrl: './about-yourself-overview.component.html',
  styleUrls: ['./about-yourself-overview.component.css'],
})
/**
 * About yourself component
 */
export class AboutYourselfComponentOverview implements OnInit {
  public userData: any;
  public sessionData: any;
  public requestStage: number;
  public userDetailsLoader: boolean = true;
  public partnerDetails: any;
  public childrenArray: any;
  public relationShipMapper: any;
  public requestIdParam: string;
  public clientRequestId: string;
  public userType: string;
  public salutationMapper: any;
  public showSSN: boolean;
  public domainViewDateFormat: string;
  private transferDataPaid: boolean = false;

  /**
   * @constructor
   */
  constructor(
    private personalDetailsService: PersonalDetailsService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private activeRouter: ActivatedRoute,
    private slugInterceptorService: SlugInterceptorService,
    private checkoutService: CheckoutService,
    private receivedDlbService: ReceivedDlbService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.domainViewDateFormat == this.commonHelper.domainViewDateFormat;
    this.userType = this.localStorageService.getDataByKey('role');

    this.requestIdParam = this.commonHelper.encodeDecodeRequestId(this.activeRouter.snapshot.parent.params?.id, false) || this.activeRouter.snapshot.parent.params?.id || this.localStorageService.getDataByKey('req_id');

    this.clientRequestId = this.localStorageService.getDataByKey('req_id');
    this.showSSN = environment.APP_DOMAIN == 1;
    this.sessionData = this.localStorageService.getUserData();
    this.requestStage = this.sessionData?.request?.request_stage || this.sessionData?.user_request?.request_stage;
    this.salutationMapper = {};
    this.relationShipMapper = {};
    // salutation mapper
    PEOPLE_SALUTATIONS.forEach((d) => {
      this.salutationMapper[d.value] = d.displayText;
    });
    // relationship mapper
    PARTNER_RELATIONSHIP_STATUS.forEach((d: any) => {
      this.relationShipMapper[d.value] = d.displayText;
    });

    if (this.isCustodian) {
      this.initTransferDataPaid();
    } else {
      this.getUserDetails();
    }
  }

  private initTransferDataPaid() {
    this.receivedDlbService.isTransferPaid(this.requestIdParam).subscribe({
      next: r => this.transferDataPaid = r,
      error: e => console.error(e),
      complete: () => this.getUserDetails(),
    });
  }

  private get useCensor(): boolean {
    return this.isCustodian && !this.transferDataPaid;
  }

  private get isCustodian(): boolean {
    return this.userType === 'Custodian';
  }

  /**
   * User details
   */
  public getUserDetails(): void {
    this.userDetailsLoader = true;

    if (!this.useCensor) {
      this.personalDetailsService.getPersonalDetails(this.isCustodian ? this.requestIdParam : this.clientRequestId).subscribe({
        next: (response: APIResponseModel) => {
          if (response?.status) {
            this.userData = response.data;
            this.requestStage = this.userData.request_stage;

            // Form the result string
            let userCountry = this.capitalizeFirstLetter(this.userData.country?.name);
            const user = this.userData;
            this.userData['full_address'] = this.commonHelper.addCommas(user.address, user?.apt_number, user.city?.name, user.state?.name, userCountry); 

            this.partnerDetails = _.find(response?.data?.user_people, { relation: 1 });
            const partner = this.partnerDetails ?? null;
            if(partner) {
              let partnerCountry = this.capitalizeFirstLetter(partner.country?.name);
              this.partnerDetails['full_address'] = partner? this.commonHelper.addCommas(
                partner?.address, partner?.apt_number, partner.city?.name, partner.state?.name, partnerCountry) || null : null;
            }
            
            this.childrenArray = _.filter(response?.data?.user_people, { relation: 2 });
            this.childrenArray.forEach(child => {
            let childCountry = this.capitalizeFirstLetter(child.country?.name);
              child['full_address'] = this.commonHelper.addCommas(
                child?.address, child?.apt_number, child.city?.name, child.state?.name, childCountry) || null;
              child['child_type_str'] = this.commonHelper.getDisplayTextByValue(child['child_type']);
            });
          }
        }, error: (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
        complete: () => this.userDetailsLoader = false,
      });
    }
  }

  /**
   * change route
   */
  get sis(): SlugInterceptorService {
    return this.slugInterceptorService;
  }

  /**
   * Capitalize First Letter
   * @param country
   * @returns
   */
  public capitalizeFirstLetter(country?: string) {
    return country.replace(/\b\w/g, match => match.toUpperCase());
  }
}
