import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { ExecutorCustodianService } from 'src/app/executor-custodian/services/executor-custodian.service';
import { CommonHelper } from 'src/app/helper/common.helper';
import { requirePasswordToBeCheckedValidator } from 'src/app/helper/customValidatorPassword.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SlugService } from 'src/app/services/slug.service';
import { UserService } from 'src/app/services/user.service';
import { BubbleRadioOption } from 'src/app/sharedComponent/bubble-radio/bubble-radio.component';
import { EMAIL_PATTERN, LoginType } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
})
/**
 * Change password component
 */
export class ChangePasswordComponent implements OnInit {
  public faEye:any;
  public faEyeSlash:any;
  public togglePasswordViewc:boolean;
  public togglePasswordView:boolean;
  public changePasswordForm:FormGroup;
  public submitLoader: boolean;
  public orgLogo:string;
  public orgLogoPath:string;
  public hasExecutorCustodian: boolean = false;
  public readonly loginOptions: BubbleRadioOption[] = [
    { text: 'Digital Vault', id: LoginType.DIGITAL_VAULT },
    { text: 'Digital Vault Custodian/Executor', id: LoginType.CUSTODIAN },
  ];
  /**
   * @constructor
   */
  constructor(
    private formBuilder:FormBuilder,
    private activeRouter:ActivatedRoute,
    private router:Router,
    private userService:UserService,
    private toastService:ToastrService,
    private commonHelper:CommonHelper,
    private slugInterceptorService:SlugInterceptorService,
    private localStorageService:LocalStorageService,
    private slugService:SlugService,
    private executorCustodianService: ExecutorCustodianService
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      } else {
        this.orgLogoPath = '';
        this.orgLogo = '';
      }
    });
    this.togglePasswordViewc = false;
    this.togglePasswordView = false;

    const currentRoute = this.activeRouter.snapshot;
    // Check if 'executor-custodian' is in the route path
    this.hasExecutorCustodian = !!(currentRoute.url.some(segment => segment.path === 'executor-custodian'));
    // Get token and email from url
    const queryParams = this.activeRouter.snapshot.queryParamMap;

    this.changePasswordForm = this.formBuilder.group({
      'token': [(queryParams.get('token') || ''), [Validators.required]],
      'email': [(queryParams.get('email') || ''), {updateOn: 'blur',validators:[Validators.required, Validators.pattern(EMAIL_PATTERN)]}],
      'password': ['', [Validators.required, Validators.minLength(8), requirePasswordToBeCheckedValidator()]],
      'password_confirmation': ['', {updateOn: 'blur',validators:[Validators.required, Validators.minLength(8)]}],
    }, {
      validator: this.ConfirmedValidator('password', 'password_confirmation'),
    });
    this.faEye = faEye;
    this.faEyeSlash = faEyeSlash;
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.changePasswordForm.controls;
  }

  /**
   *
   * Password confirmation
   * @param {string} oldpassword
   * @param {string} newpassowrd
   * @return {FormGroup}
  */
  public ConfirmedValidator(oldpassword: string, newpassowrd: string):Function {
    return (formGroup: FormGroup) => {
      const currentPassword = formGroup.controls[oldpassword];
      const confirmPassword = formGroup.controls[newpassowrd];
      if (currentPassword.errors && !confirmPassword.errors.confirmedValidator) {
        return;
      }
      if (currentPassword.value !== confirmPassword.value) {
        confirmPassword.setErrors({ confirmedValidator: true });
      } else {
        confirmPassword.setErrors(null);
      }
    };
  }

  /**
   * Reset password
   */
  public resetPassword():void {
    this.submitLoader = true;
    const observer = {
      next: (response: APIResponseModel) => {
        if (response.status) {
          this.toastService.success(response?.message);
          this.hasExecutorCustodian? void this.slugInterceptorService.navigate([`executor-custodian/login`]): void this.slugInterceptorService.navigate([`login`]);
        }
      },
      error: (exception: any) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      },
    };

    if (this.hasExecutorCustodian) {
      this.executorCustodianService.passwordReset(this.changePasswordForm.value).subscribe(observer);
    } else {
      this.userService.resetPassword(this.changePasswordForm.value).subscribe(observer);
    }
  }
 /**
   * Get forget password role name for header
   */
 get headerRole() {
  let role = this.hasExecutorCustodian? 1 : 0; // 0: consumer, 1: executor-custodian
  return this.loginOptions.find(i => i.id === role)
}
  /**
   * on click logo
   */
  public onClickLogo() {
    void this.slugInterceptorService.navigate(['']);
  }
}
