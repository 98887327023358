import { Component } from '@angular/core';

@Component({
  selector: 'app-professional-agents',
  templateUrl: './professional-agents.component.html',
  
})
/**
 * prfessional agent
 */
export class ProfessionalAgentsComponent {
}
