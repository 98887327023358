import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { ProfessionalPermissionService } from 'src/app/professional-dashboard/services/professional-permission.service';
import { AssetsService } from 'src/app/services/assets.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { ConsumerPaymentMethod, LIABILITY_TYPE, USER_TYPES } from 'src/constants/application.const';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';

@Component({
  selector: 'app-liabilities-list',
  templateUrl: './liabilities-list.component.html',

})
/**
 * Liabilities listing component
 */
export class LiabilitiesListComponent implements OnInit {
  @Input() isAssetsView = false;
  @Output() isLoading = new EventEmitter();
  @Output() paidStatusEvent = new EventEmitter(null);  
  @Output() emitPermissionsList = new EventEmitter(null);
  public midPlaid = 'L-plaid-opt';
  public preDefinedLoader: boolean;
  public liabilitiesList: Array<any>;
  public editData: any;
  public liabilityTypesConst: any;
  public loadEditDetailsMapper: any;
  public userType: string;
  public clientRequestId: string;
  public clientUserId:string;
  public clientPaymentMode:ConsumerPaymentMethod;
  public permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public userRequestID: string;
  public clientHasPaidSubscription:boolean;

  /**
* constructor
*/
  constructor(
    private personalDetailsService: PersonalDetailsService,
    private modalService: CommonModelService,
    private commonHelper: CommonHelper,
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
    private proPermissionService: ProfessionalPermissionService,
    private toastrService: ToastrService,
    private assetsService: AssetsService,
    private subscriptionService: ConsumerSubscriptionService,
  ) { }

  /**
* called initially
*/
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.clientRequestId = this.route.snapshot.params['id'] || this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id');
    this.userType = this.localStorageService.getDataByKey('role');
    this.userRequestID = this.commonHelper.getRequestId(this.route);
    this.liabilityTypesConst = LIABILITY_TYPE;
    this.loadEditDetailsMapper = {
      [this.liabilityTypesConst.L]: 'user_liability_loan',
      [this.liabilityTypesConst.C]: 'user_liability_card',
    };
    this.getPersonalDetails();
    this.getAccessControl();
    this.assetsService.accountListObserve.subscribe((response) => {
      if (response.length) {
        this.getPersonalDetails();
      }
    });
  }

  /**
* Establish ACL Permissions. Consumer is assigned full access.
*/
  public getAccessControl(): void {
    if (this.userType !== 'Consumer') {
      let getACLService;
      const requestId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('request_user_id') : this.userRequestID;
      const userId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('user_id') : this.localStorageService.getDataByKey('agent_id') || this.localStorageService.getUserData(USER_TYPES.pro)?.user?.id;
      getACLService = this.proPermissionService.getAccessControl(userId, requestId, ['Liabilities']);
      getACLService.subscribe(
        (permissions) => {
          this.permissions = permissions?.['Liabilities']
          this.emitPermissionsList.emit({permissionsList : this.permissions})
        },
        (exception: any) => (this.commonHelper.httpResponseHandler(exception?.error)));
    } else {
      this.permissions = {
        view: true,
        add: true,
        edit: true,
        delete: true,
      };
      this.emitPermissionsList.emit({permissionsList : this.permissions})
    }
  }

  /**
* Get personal details
*/
  public getPersonalDetails(): void {
    this.setLoading(true);
    // get people list
    this.personalDetailsService.getPersonalDetails(this.clientRequestId).subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.liabilitiesList = response?.data?.user_liability;
        if(this.commonHelper.isProfessional){
          this.clientUserId = response?.data?.['user']?.['id'];
          this.clientPaymentMode = response?.data?.['user']?.['payment_type'];
          this.subscriptionService.clientHasPaidSubscription(this.clientUserId,this.clientPaymentMode).then( r =>{
            this.clientHasPaidSubscription = r;
            this.paidStatusEvent.emit({clientHasPaidSubscription : r})
          })
        }
      }
    }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error), () => this.setLoading(false));
  }

  /**
* toggle add beneficiary modal
*
* @param {any} editValue
* @param {string} type
* @param {any} liabilityData
* @param {string} id
*/
  public toggleModalEdit(editValue: any, type: string, liabilityData: any, id: string): void {
    const bEdit = this.permissions?.['view'] || this.permissions?.['add'] || this.permissions?.['edit'] || this.permissions?.['delete'];
    if (bEdit) {
      this.editData = editValue ? { ...editValue, type, liabilityData, id } : {};
      this.modalService.open('add-liabilities-modal');
    } else {
      this.toastrService.info('You do not have permission to access this information.');
    }
  }

  /**
* Modal event listener
*
* @param {any} event
*/
  public modalEventListener(event: any): void {
    if (event) {
      this.modalService.close(this.midPlaid)
    }
    this.commonHelper.diffCheckerAndUpdate(this.liabilitiesList, 'id', event?.id, event);
    this.getPersonalDetails();
  }
  /**
* Set {@link preDefinedLoader} and emit the value.
* @param loading
*/
  public setLoading(loading: boolean) {
    this.preDefinedLoader = loading;
    this.isLoading.emit(loading);
  }

  /**
   * Determines whether the liabilities list can be shown based on certain conditions.
   * @returns {boolean} Returns true if the liabilities list can be shown; otherwise, false.
   */
  public get canShowLiabilitiesList():boolean{
    return this.liabilitiesList.length > 0 &&
    (!this.commonHelper.isProfessional || this.clientHasPaidSubscription);
  }
}
