<div *ngIf="!completedLocal">
  <div class="h-full flex flex-col p-12 sm:p-16">
    <!-- BEGIN: First Slide -->
    <div *ngIf="this.requestId" class="mb-6 col-span-12 md:col-span-4" (click)="navigateToPro()">
      <button type="button"
        class="button button--lg w-full bg-darkblue rounded-xs py-2 focus:outline-none text-regular text-white px-6 ">Back
        to Professional Dashboard</button>
    </div>
    <div class="pt-4 pb-4">
      <img src="assets/images/dashboard/right-img.png" class="mx-auto" alt="LegacyNow">
    </div>
    <div class="py-8">
      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
        Your Secure Digital Vault
      </h4>
      <p class="text-grey text-regular text-base mt-2"> A secure estate digital vault that uses Blockchain technology to
        ensure all consumer data is reliably & securely stored in our central database. We call this SayfData®.
      </p>
    </div>
    <div *ngIf="!!dlbId" class="mb-6 border-card bg-white rounded p-4 px-6  flex items-center ">
      <div class="mt-2 md:mt-0   lg:mt-2 xl:mt-0 ">
        <div>
          <img src="assets/images/about-yourself/idcard.svg" class="w-auto h-16" alt="idcard">
        </div>
      </div>
      <div class="py-1   pl-8 pt-4">
        <h3 class="text-label-grey text-sm tracking-wide text-regular mb-1">Your LegacyNOW Digital Vault ID</h3>
        <h6 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
          {{dlbId}}
        </h6>

      </div>

    </div>
    <div class="border-card bg-white rounded p-4 px-6 sm:flex sm:flex-row xl:flex xl:flex-row lg:flex lg:flex-col">
      <div class="py-1  xl:flex-2">
        <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
          Contact Us
        </h4>
        <div class="flex flex-row items-center mt-3">

          <img src="assets/images/dashboard/phone.png" class="w-4 object-contain" alt="digital vault">
          <a href="tel:+1{{customerSupportPhone}}" class="link-blue text-regular ml-4 text-sm">Call us on +1 {{customerSupportPhone}}</a>
        </div>
        <div class="flex flex-row items-baseline pt-2">
          <img src="assets/images/dashboard/mail.png" class="w-4 object-contain" alt="digital vault">

          <a href="mailto:{{customerSupportMail}}" class="link-blue text-regular ml-4 text-sm">Email us
            {{customerSupportMail}}
          </a>
        </div>
        <div class="flex flex-row items-baseline pt-1">
          <p class="text-sm text-content text-regular leading-8 pt-2 custom-line">Hours of Operation:<br>Monday to
            Friday 9am to 5pm EST</p>
        </div>
      </div>
      <div
        class="mt-2 md:mt-0 flex justify-center sm:justify-end lg:justify-center lg:mt-2 xl:mt-0 xl:justify-end items-center flex-1">
        <div class="flex items-center">
          <img src="assets/images/dashboard/support-img1.png" class="w-10  h-10 object-contain -mr-2"
            alt="digital vault">
          <img src="assets/images/dashboard/support-img2.png"
            class="w-16 h-16 object-contain z-10 border-white rounded-full" alt="digital vault">
          <img src="assets/images/dashboard/support-img3.png" class="w-10 h-10 object-contain -ml-2"
            alt="digital vault">
        </div>
      </div>

    </div>
  </div>
</div>
<div *ngIf="completedLocal && this.userType === 'Consumer' ">
  <div class="h-full flex flex-col p-8 pt-0 lg:pt-16 sm:p-16 lg:p-0 xl:p-16 lg:pr-20">
    <!-- BEGIN: First Slide -->
    <div class="pt-16 pb-4">
      <img src="assets/images/dashboard/completed.svg" class="mx-auto" alt="digital vault">
    </div>
    <ng-container *ngIf="(showPaymentOption) || (isDemoEmail)">
      <div class="py-8">
        <h4 class="text-regular text-lg text-darkgrey font-semibold tracking-wide">
          Congratulations! You have successfully initiated creation of your LegacyNOW Digital Vault.
        </h4>
        <p class="text-grey text-regular text-base mt-2">
          For continued use of the Digital Vault, you must make the required payment. Please click the below button to process payment.
        </p>
      </div>
      <div class="mb-5 col-span-12 md:col-span-4">
        <app-button-loader
          [buttonText]="'Pay for your LegacyNOW Digital Vault'" [valid]="true" [loader]="submitLoader"
          [buttonType]="'button'" (click)="payDlb()"
          [buttonClass]="'button button--lg w-full bg-darkblue rounded-xs py-2 md:h-10 focus:outline-none text-regular text-white px-6'">
        </app-button-loader>
      </div>
    </ng-container>

    <!-- BEGIN: ID Verify -->
    <!-- <p class="text-grey text-regular text-base mt-2 mb-5" *ngIf="(!isIdVerified)|| (isDemoEmail)">
      To enhance the security and access control of your Digital Vault, please complete the identity verification
      process.</p>
    <div class="mb-10 col-span-12 md:col-span-4">
      <div *ngIf="isIdVerified == verificationStatus['verified'] || (isDemoEmail)">
        <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon w-8 h-8" alt="digital vault">
        <p class="font-semibold text-lg text-darkblue"> &nbsp; ID verification has been completed successfully</p>
      </div>

      <div class="mb-10 mt-10 col-span-12 md:col-span-4"  *ngIf="isIdVerified == verificationStatus['pending']">
        <img src="assets/images/password/alert-circle.svg" class="float-left green-error-icon w-8 h-8" alt="digital vault">
        <p class="font-semibold text-lg text-darkblue"> &nbsp;&nbsp;Identity verification in progress...
        <app-button-loader
        [buttonTitle]="'Resend ID App Link'"
        [valid]="true" [loader]="submitLoader" [buttonType]="'button'" (click)="iDVerification()"
        [buttonClass]="'button button--lg mt-5 cursor-pointer w-full bg-darkblue rounded-xs py-2 md:h-10 focus:outline-none text-regular text-white px-6'">
      </app-button-loader>
      </div>

      <app-button-loader *ngIf="!isIdVerified || isDemoEmail"
      [buttonTitle]="'Verify Identity'"
      [valid]="!isIdVerified"
       [loader]="submitLoader" [buttonType]="'button'" (click)="iDVerification()"
      [buttonClass]="'button button--lg cursor-pointer w-full bg-darkblue rounded-xs py-2 md:h-10 focus:outline-none text-regular text-white px-6'">
      </app-button-loader>
    </div> -->
    <!-- END: ID Verify -->
    <div *ngIf="!!dlbId" class="mb-6 border-card bg-white rounded p-4 px-6 flex items-center ">
      <div class="mt-2 md:mt-0 lg:mt-2 xl:mt-0  ">
        <div>
          <img src="assets/images/about-yourself/idcard.svg" class="w-auto h-16" alt="idcard">
        </div>
      </div>
      <div class="py-1  pl-8 pt-4">
        <h3 class="text-label-grey text-sm tracking-wide text-regular mb-1">Your LegacyNOW Digital Vault ID</h3>
        <h6 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
          {{dlbId}}
        </h6>

      </div>

    </div>
    <div class="border-card bg-white rounded p-4 px-6 sm:flex sm:flex-row xl:flex xl:flex-row lg:flex lg:flex-col">
      <div class="py-1  xl:flex-2">
        <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
          Contact Us
        </h4>
        <div class="flex flex-row items-center mt-3">
          <img src="assets/images/dashboard/phone.png" class="w-4 object-contain" alt="digital vault">
          <a href="tel:+1{{customerSupportPhone}}" class="text-darkgrey text-regular ml-4 text-sm">Call us on +1 {{customerSupportPhone}}</a>
        </div>
        <div class="flex flex-row items-baseline pt-1">
          <img src="assets/images/dashboard/mail.png" class="w-4 object-contain" alt="digital vault">
          <a href="mailto:{{customerSupportMail}}" class="text-darkgrey text-regular ml-4 text-sm">Email us
            {{customerSupportMail}}
          </a>
        </div>
        <div class="flex flex-row items-baseline pt-1">
          <p class="text-sm text-content text-regular leading-8 block">Hours of Operation:<br>Monday to Friday 9am to
            5pm EST</p>
        </div>
      </div>
      <div
        class="mt-2 md:mt-0 flex justify-center sm:justify-end lg:justify-center lg:mt-2 xl:mt-0 xl:justify-end items-center flex-1">
        <div class="flex items-center">
          <img src="assets/images/dashboard/support-img1.png" class="w-10  h-10 object-contain -mr-2"
            alt="digital vault">
          <img src="assets/images/dashboard/support-img2.png"
            class="w-16 h-16 object-contain z-10 border-white rounded-full" alt="digital vault">
          <img src="assets/images/dashboard/support-img3.png" class="w-10 h-10 object-contain -ml-2"
            alt="digital vault">
        </div>
      </div>
    </div>
    <div *ngIf="false"
         class="mt-5 col-span-12 md:col-span-4">
      <div class="mt-5 col-span-12 md:col-span-4">
        <app-button-loader
          [buttonText]="'Appointment with your ' + agentTerm" [loader]="submitLoader"
          [buttonType]="'button'" (click)="openAppointmentModal()" [buttonTooltip]="buttonTooltip"
          [buttonClass]="'button button--lg w-full bg-darkblue rounded-xs py-2 md:h-10 focus:outline-none text-regular text-white px-6'">
        </app-button-loader>
      </div>
    </div>
  </div>
</div>
<div *ngIf="completedLocal && this.userType !== 'Consumer'">
  <div class="h-full flex flex-col p-8 pt-0 lg:pt-16 sm:p-16 lg:p-0 xl:p-16 lg:pr-20">
    <!-- BEGIN: First Slide -->
    <div *ngIf="this.requestId" class="mb-6 col-span-12 md:col-span-4" (click)="navigateToPro()">
      <button type="button"
        class="button button--lg w-full bg-darkblue rounded-xs py-2 focus:outline-none text-regular text-white px-6 ">Back
        to Professional Dashboard</button>

    </div>
    <div class="pt-14 pb-4">
      <img src="assets/images/dashboard/completed.svg" class="mx-auto" alt="digital vault">
    </div>
    <div class="py-8">
      <h4 class="text-regular text-lg text-darkgrey font-semibold tracking-wide">
        You have successfully completed your submission to LegacyNOW Digital Vault.
      </h4>
    </div>
    <div class="mb-10 col-span-12 md:col-span-4">

      <button type="button"
        class="button button--lg w-full bg-darkblue rounded-xs py-2 focus:outline-none text-regular text-white px-6 opacity-50"
        disabled>LegacyNOW Digital Vault Paid</button>
    </div>

    <div *ngIf="!!dlbId" class="mb-6 border-card bg-white rounded p-4 px-6 flex items-center">
      <div class="mt-2 md:mt-0  lg:mt-2 xl:mt-0  ">
        <div>
          <img src="assets/images/about-yourself/idcard.svg" class="w-auto h-16" alt="idcard">
        </div>
      </div>
      <div class="py-1  xl:flex-1 pl-8 pt-4">
        <h3 class="text-label-grey text-sm tracking-wide text-regular mb-1">Your LegacyNOW Digital Vault ID</h3>
        <h6 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
          {{dlbId}}
        </h6>

      </div>

    </div>
    <div class="border-card bg-white rounded p-4 px-6 sm:flex sm:flex-row xl:flex xl:flex-row lg:flex lg:flex-col">
      <div class="py-1  xl:flex-2">
        <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
          Contact Us
        </h4>
        <div class="flex flex-row items-center mt-3">
          <img src="assets/images/dashboard/phone.png" class="w-4 object-contain" alt="digital vault">
          <a href="tel:+1{{customerSupportPhone}}" class="link-blue text-regular ml-4 text-sm">Call us on +1 {{customerSupportPhone}}</a>
        </div>
        <div class="flex flex-row items-baseline pt-2">
          <img src="assets/images/dashboard/mail.png" class="w-4 object-contain" alt="digital vault">
          <a href="mailto:{{customerSupportMail}}" class="link-blue text-regular ml-4 text-sm">Email us
            {{customerSupportMail}}
          </a>
        </div>
        <div class="flex flex-row items-baseline pt-1">
          <p class="text-sm text-content text-regular leading-8 pt-2 custom-line">Hours of Operation:<br>Monday to
            Friday 9am to 5pm EST</p>
        </div>
      </div>
      <div
        class="mt-2 md:mt-0 flex justify-center sm:justify-end lg:justify-center lg:mt-2 xl:mt-0 xl:justify-end items-center flex-1">
        <div class="flex items-center">
          <img src="assets/images/dashboard/support-img1.png" class="w-10  h-10 object-contain -mr-2"
            alt="digital vault">
          <img src="assets/images/dashboard/support-img2.png"
            class="w-16 h-16 object-contain z-10 border-white rounded-full" alt="digital vault">
          <img src="assets/images/dashboard/support-img3.png" class="w-10 h-10 object-contain -ml-2"
            alt="digital vault">
        </div>
      </div>
    </div>

  </div>
</div>
<!--add agent appointment popup -->
<app-agent-appointment-popup></app-agent-appointment-popup>
