<app-common-modal [id]="modalID" *ngIf="!isLoginUrl" class="app-common-modal">
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg" class="absolute right-2 w-8 -mr-6 -mt-4 cursor-pointer" alt=""
         (click)="closeModal()">
    <div class="w-full sm:p-6">
      <div class="ml-auto mr-auto">
        <img src="assets/images/payment/payment-failure.svg" alt="payment-failure"
             class="mb-5 w-40 h-40 ml-auto mr-auto">
        <h3 class="text-darkblue text-semibold text-center text-lg mb-4">Your Transaction Failed</h3>
        <p class="text-md text-grey py-1 tracking-wide text-regular text-center">
          {{errorMessageForFailed}}.<a class="text-xl font-bold text-darkblue hover:underline-text cursor-pointer" (click)="closeModal()">
            Try Again
          </a>
        </p>
        
      </div>
    </div>
  </div>
</app-common-modal>
