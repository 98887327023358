import { Component, OnInit } from '@angular/core';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

import { Router } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { ROUTE_PREFIX } from '../../../../constants/application.const';


@Component({
  selector: 'app-consumer-landing-footer',
  templateUrl: './consumer-landing-footer.component.html',
})
/**
 * footer of consumer landing page
 */
export class ConsumerLandingFooterComponent implements OnInit {
  public proRoute: string = ROUTE_PREFIX.PRO;
  public menuActive: string;
  public videoSourceURL: string;
  public customerSupportMail:string;
  public customerSupportPhone:number;


  /**
   * constructor
   */
  constructor(private commonService: CommonService,
    public commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private slugInterceptorService: SlugInterceptorService,
    private router: Router,
    private localstorageService: LocalStorageService) {
  }


  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.getContactDetails();
  }


  /**
   * @param{string}url
   * on menu change
   */
  public menuChange(url?: string) {
    this.menuActive = url;
    if (url === 'pro') {
      this.localstorageService.clearOrgData();
    }
    let newTab = false;
    if(this.router.url == '/dashboard') {
      newTab = true;
    }
    void this.slugInterceptorService.navigate([url], undefined, undefined, newTab);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }


  /**
   * Display intro video modal
   */
  viewIntroVideo(): void {
    this.modalService.open('view-video-modal');
  }

  public getContactDetails(){
    this.commonService.getContactUsDetails().subscribe((response)=>{
      const {support_email,support_number, download_digital_vault} = response.data;
      this.commonService.showTrVaultPricing(Number(download_digital_vault) ?? 0);
      this.customerSupportMail = support_email;
      this.customerSupportPhone = support_number;
    })
  }
}
