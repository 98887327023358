import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { CommonHelper } from '../../../helper/common.helper';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';

@Component({
  selector: 'app-consumer-pricing',
  templateUrl: './consumer-pricing.component.html',
})
/**
 * consumer pricing class
 */
export class ConsumerPricingComponent implements OnInit {
  public mobile: boolean;
  public innerWidth: number;
  public menuActive: string;
  public allowTransferPayment: boolean;
  public pricing: { monthly: string; annual: string; vault_transfer: string };
  public vaultVideo: string;

  /**
   * constructor
   */
  constructor(
    private router: Router,
    private slugInterceptorService: SlugInterceptorService,
    private subscriptionService: ConsumerSubscriptionService,
    private commonService: CommonService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService
  ) {}

  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.getPricing();
    this.onResize();
    
    this.commonService.showTVaultPricingObserve.subscribe((data)=>{
      this.allowTransferPayment = (data == 1);
    })
  }

  private getPricing() {
    this.subscriptionService.getPricingDetails().subscribe({
      next: (response: any) => {
        if (response?.status) {
          console.log('getSubscriptionDetailsApi', response);
          const packagePrice = {
            monthly: response.data.subscription_price,
            yearly: response.data.packages.find(e => e.name.toLowerCase() == 'yearly')?.price,
          };
          this.pricing = {
            monthly: this.commonHelper.toCurrency(packagePrice.monthly),
            annual: this.commonHelper.toCurrency(packagePrice.yearly),
            vault_transfer: this.commonHelper.toCurrency(response.data.vault_transfer_amount),
          };
        }
      }, complete: () => {
        console.log('pricing', this.pricing);
      },
    });
  }

  /**
   * window resize
   * @param {any} event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.innerWidth = window.innerWidth;
    this.mobile = this.innerWidth <= 767;
  }

  /**
   * @param{string}url
   * on menu change
   */
  public menuChange(url?: string) {
    if (url) {
      this.menuActive = url;
      void this.slugInterceptorService.navigate([url]);
      this.commonService.setActiveNavbarTrigger(this.menuActive);
    }
  }

  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');
  }
  /**
   * Getter for show Get Started
   */
  get showGetStarted():boolean {
    return !!(!this.commonHelper.getOrgLogo());
  }
}
