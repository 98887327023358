import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { APARTMENT_NUMBER_PATTERN, EMAIL_PATTERN, LIST_DELETE, MODAL_CLOSE, NAME_PATTERN, PHONE_PATTERN, SSN_MASK, UNITED_KINGDOM } from '../../../../../../constants/application.const';
import { environment } from '../../../../../../environments/environment';
import { AddressHelper } from '../../../../../helper/address.helper';
import { CommonHelper } from '../../../../../helper/common.helper';
import { ValidationHelper } from '../../../../../helper/validation.helper';
import { ICountryCode, SelectOptionsInterface } from '../../../../../interface/common.interface';
import { APIResponseModel } from '../../../../../interface/response.interface';
import { CommonModelService } from '../../../../../services/common-model.service';
import { CommonService } from '../../../../../services/common.service';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { PeopleService } from '../../../../../services/people.service';
import { UserService } from '../../../../../services/user.service';
import { BubbleRadioEmitModel, BubbleRadioOption } from '../../../../../sharedComponent/bubble-radio/bubble-radio.component';


@Component({
  selector: 'app-define-advisor-modal',
  templateUrl: './define-advisor-modal.component.html',
})

/**
 *
 */
export class DefineAdvisorModalComponent implements OnInit {
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;
  @Input() modal_id: string = 'define-advisor-modal';
  @Input() toggleModal: boolean;
  @Output() toggleModalEmitter = new EventEmitter<boolean>();
  private role_id: string;
  public selectedNamingTypeEmit: BubbleRadioEmitModel;
  public readonly namingTypeOptions: BubbleRadioOption[] = [
    { text: 'Individual', id: 'i' },
    { text: 'Company', id: 'c' },
  ];
  public readonly faEye = faEye;
  public readonly faEyeSlash = faEyeSlash;
  public readonly unitedKingdom = UNITED_KINGDOM;
  public readonly SSN_MASK: string = SSN_MASK;
  public advisorForm: FormGroup;
  public submitLoader: boolean = false;
  public currentDateRestriction: string;
  public countryCode: ICountryCode;
  public proTypeList: Array<SelectOptionsInterface> = [];
  public userType: string;
  public requestId: string;
  public locale = 'en';
  public maxDate: Date;
  public bsConfig: Partial<BsDatepickerConfig>;
  public showSSN: boolean;
  public defaultCountry: number;
  public submitLoaderDelete: boolean;
  public selectedCountry: number = environment.APP_DOMAIN;
  public toggleSSNView: boolean = false;
  public selectedCountryName: string = '';
  public domainDateFormat: any;
  public agentTerm: string;
  public isPaymentCompleted: boolean;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public searchElementRef: ElementRef;
  public elementRefSet: boolean = false;

  @ViewChild('search', { static: false })
  set setSearchElementRef(value: ElementRef) {
    this.searchElementRef = value;
    if (this.searchElementRef && !this.elementRefSet) { // if not elementRef set initial value for searchElement
      this.initMap(this.defaultCountry);
      this.elementRefSet = true;
    }
  }
  @ViewChild('scrollToTop', {static: false}) scrollToTop: ElementRef;

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private peopleService: PeopleService,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private userService: UserService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private localeService: BsLocaleService,
    private addressHelper: AddressHelper,
    private ngZone: NgZone,
    private validation: ValidationHelper,
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.domainDateFormat = this.commonHelper.domainDateFormat;
    // this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.agentTerm = 'Professional Service Provider';
    this.showSSN = environment.APP_DOMAIN == 1;
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    this.initMap(this.defaultCountry);
    this.initForm();
    if (!this.canAccessForm) {
      this.advisorForm.disable();
    }
    this.getProTypeList();
    this.applyLocale();
    this.isEnableState();
    this.bsConfig = Object.assign({}, {
      showWeekNumbers: false,
      maxDate: this.maxDate,
      dateInputFormat: this.domainDateFormat,
    });
    this.resetForm();
    /**
     * Listen for current modal
     */
    this.commonService.currentModalStatusObservable.subscribe((status: string) => {
      if (status === MODAL_CLOSE) {
        this.resetForm();
        this.removePacContainer();
        this.initMap(this.defaultCountry);
      }
    });
    this.markAsTouchedIndividual('address');
  }


  private initForm() {
    this.advisorForm = this.formBuilder.group({
      first_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      middle_name: ['', { updateOn: 'blur', validators: [Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      last_name: ['', { updateOn: 'blur', validators: [this.validation.fn.requiredExcept(() => this.selectedNamingType?.id != 'i'), Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      phone: ['', { updateOn: 'blur', validators: [Validators.pattern(PHONE_PATTERN)] }],
      email: ['', { updateOn: 'blur', validators: [Validators.pattern(EMAIL_PATTERN)] }],
      professional_type: ['', { updateOn: 'blur', validators: [Validators.required] }],
      advisor_type: [null],
      address: ['', { updateOn: 'blur', validators: [Validators.minLength(3), Validators.maxLength(255)] }],
      apt_number: ['', { updateOn: 'blur', validators: [Validators.pattern(APARTMENT_NUMBER_PATTERN), Validators.maxLength(25)] }],
      city: ['', {validators: [this.validation.noWhitespaceValidator()]}],
      country: [''],
      state: [''],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.maxLength(10)] }],
      id: [''],
      role_id: [''],
    });
    this.validation.setValidationErrors({
      phone: { pattern: 'Please enter a valid phone number' },
      email: { pattern: 'Please enter a valid email address.' }
    });

    const trimWhitespaceControls = ['first_name', 'middle_name', 'last_name', 'address', 'apt_number', 'city', 'zipcode', 'email', 'professional_type'];
    
    trimWhitespaceControls.forEach(controlName => {
      this.advisorForm.controls[controlName].valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.advisorForm.controls[controlName])
      );
    });
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlsKey
   */
  public getErrors(controlsKey: string): Array<string> {
    return this.validation.getErrors(controlsKey, this.advisorForm);
  }

  @Input() set setAdvisorEmail(email: string) {
    if (email && this.advisorForm) {
      this.advisorForm.get('email').setValue(email);
      this.advisorForm.get('email').markAsTouched();
    }
  }


  /**
   * Detect change on phone number component
   *
   * @param {any} inputObject
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }

  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }

  /**
   * Get professional_type list
   */
  private getProTypeList(): void {
    this.peopleService.getAdvisorTypes().subscribe({
      next: (r) => {
        if (r.status) {
          this.proTypeList = this.commonHelper.convertToOptionsFormat(r?.data, 'id', 'name');
        }
      },
      error: (e) => console.error('error', e),
      complete: () => console.log('complete'),
    });
    this.peopleService.getProfessionalTypes(1)?.subscribe((response: APIResponseModel) => {
      if (response.status) {
        const role_id = response.data.role.find((e) => e['role_type'] == 2)['id'];
        this.advisorForm.patchValue({
          role_id: role_id,
        });
        this.advisorForm.get('role_id').setValue(role_id);
        this.role_id = role_id;
      }
    });
  }

  /**
   * Setter for edit values in custodian
   *
   * @param {any} data
   */
  @Input() set editData(data: any) {
    if (data?.id) {
      this.resetForm();
      let tempPhoneNumber;
      if (data.phone) {
        tempPhoneNumber = data.phone;
        this.countryCode = { code: data.flag_code, dialCode: data.country_code };
      }
      // If no last name, then this was a company usage.
      if (!data['last_name']) {
        const index = this.namingTypeOptions.findIndex(e => e.id == 'c');
        this.onClickNamingTypOptions({ index: index, option: this.namingTypeOptions[index] });
      }
      this.advisorForm.patchValue({
        ...data,
        phone: tempPhoneNumber,
        city: data.city?.id,
        country: data.country?.id,
        state: data.state?.id,
        professional_type: data.advisor_type?.name || data.professional_type?.name || data.professional_type,
        advisor_type: data?.advisor_type?.id || null,
      });
      this.removePacContainer();
      this.initMap(data?.country?.id);
    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.advisorForm?.controls;
  }

  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }

  /**
   * add custodian function
   */
  public onSubmit(): void {
    if (!this.advisorForm.valid) {
      this.advisorForm.markAllAsTouched();
      this.scrollToTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      return; // return if the form is invalid
    }
    const isCompany = false;
    this.submitLoader = true;

    if (isCompany) {
      this.advisorForm.value.last_name = '';
    }
    const payload = {
      ...this.advisorForm.value,
      country_code: this.countryCode.dialCode,
      flag_code: this.countryCode.code,
      is_state: Number(this.selectedCountryName != this.unitedKingdom),
      roletype: this.localStorageService.getDataByKey('role'),
      advisor: 1,
      custodian: 0,
      // advisor_type: this.advisorForm.value?.professional_type, // added null while initialize form, becoz of professional_type changed from pro type options to string
    };
    payload.state = this.selectedCountryName == this.unitedKingdom ? null : payload.state;
    /**
     * Current flow attempts to create an account then assign the account as an advisor to this vault.
     */
    this.commonService.getCityDetail(payload.country, payload.state, payload.city).subscribe(
      (cResponse: APIResponseModel) => {
        if (cResponse?.status) {
          payload.city = cResponse?.data?.id;
          this.peopleService.storePeopleDetails(payload).subscribe({
            next: (r) => {
              this.toastrService.success(r.message);
              this.peopleService.refreshDetailList({});
              this.closeModel();
              this.submitLoader = false;
            },
            error: (exception: any) => {
              this.submitLoader = false;
              this.commonHelper.httpResponseHandler(exception?.error);
            },
          });
        }
      },
    );
  }

  /**
   * Initialize google map api by country
   * @param {any} countryId
   */
  public initMap(countryId: any): void {
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: (r) => {
        this.ngZone.run(() => {
          this.advisorForm.patchValue({ ...r, address: r.address });
        });
      },
      error: (e) => console.error(e),
    });
  }

  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.advisorForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }

  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry) return;
    this.advisorForm.controls.state.reset();
    this.advisorForm.patchValue({
      country: selectedCountry.value,
    });
    this.removePacContainer();
    this.initMap(selectedCountry.value);
  }

  /**
   * remove pac container
   *
   */
  private removePacContainer() {
    this.addressHelper.removeAutocompletePacContainer();
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.advisorForm.controls.city.reset();
    this.advisorForm.patchValue({
      state: selectedState.value,
    });
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.advisorForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.advisorForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(selectedCity?.additionalDetails[0]?.postal_code_regex))]);
      zipCodeRef.updateValueAndValidity();
    }

    // this loader is set false here because of the delay to set country state city
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.modalService.close(this.modal_id);
    this.resetForm();
  }

  /**
   * Reset form
   */
  public resetForm(): void {
    this.advisorForm?.reset({
      custodian: '0',
      role_id: this.role_id,
      country: this.defaultCountry,
    });
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    this.selectedNamingTypeEmit = { index: 0, option: this.namingTypeOptions[0] };
    this.commonHelper.scrollToTopModal(this.advisorForm);
  }

  /**
   * delete advisor
   */
  public deleteAdvisor(): void {
    this.submitLoaderDelete = true;
    const payload = {
      is_delete: '1',
      id: this.advisorForm.value.id,
      roletype: this.userType,
    };
    if (this.userType !== 'Consumer') {
      payload['request_id'] = this.requestId;
    }

    this.peopleService.storePeopleDetails(payload).subscribe((response: APIResponseModel) => {
      this.submitLoaderDelete = false;
      if (response.status) {
        this.closeModel();
        this.toggleModalEmitter.emit({
          ...response.data,
          listStatus: LIST_DELETE,
        });
        this.peopleService.advisorObservableId(payload['id']);
        this.commonHelper.toastrDeleteSuccess();
      }
    }, (exception: any) => {
      this.submitLoaderDelete = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
   * getter for state
   */
  get state() {
    return this.advisorForm.get('state');
  }

  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.advisorForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      // this.selectedCountryName !== this.unitedKingdom ? //use later
      //   this.state.addValidators([Validators.required]) : this.state.clearValidators();
      // this.state.updateValueAndValidity();
    });
  }

  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.advisorForm.get('zipcode');
    const stateId = this.advisorForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe({
        next: (response: APIResponseModel) => {
          const validators = [Validators.required];
          if (response.data.length) {
            const zipCodeRegex = response.data[0].postal_code_regex;
            validators.push(Validators.pattern(new RegExp(zipCodeRegex)));
          }
          zipCodeRef.setValidators(validators);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        },
        error: (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
      });
    } else {
      return;
    }
  }

  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }

  /**
   * Clear values for `['first_name', 'middle_name', 'last_name', 'professional_type']` controls.
   * Update {@link selectedNamingType}.
   * @param $event
   */
  onClickNamingTypOptions($event: BubbleRadioEmitModel) {
    this.selectedNamingTypeEmit = $event;
    ['first_name', 'middle_name', 'last_name'].forEach(e => {
      const control = this.advisorForm.get(e);
      control.reset();
    });
  }

  get selectedNamingType() {
    return this.selectedNamingTypeEmit?.option;
  }
}
