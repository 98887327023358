<div class="items-center m-5 w-full">
    <a (click)="changeRouter('dashboard')">
      <img alt="The-Estate-Registry" class="object-contain w-40 lg:w-64 custom-logo-large cursor-pointer"
        src="assets/images/logo-old.png">
    </a>
  </div>
<div class="flex flex-col m-20 mt-0">
    <div class="m-auto text-center" *ngIf="messageDetails['messageResponse']==0">
        <img src="assets/images/digital-files/loader.gif" alt="" class="text-center w-20 h-20">
    </div>
    <div class="m-auto">
        <p class="text-gray-500 text-regular md:text-base text-base pr-3">
        <img src="assets/images/consumer/rounded-tick.svg" class="w-20 h-20" alt="success" *ngIf="messageDetails['messageResponse']==1">
        <img src="assets/images/dashboard/exclamation_red.svg" class="w-20 h-20" alt="something wrong" *ngIf="messageDetails['messageResponse']==2 || messageDetails['messageResponse'] ==3"></p> 
    </div>
    <div class="m-auto">
        <p class="text-red-400 font-semibold text-xl">
           {{messageDetails?.headerMessage}}
        </p>
    </div>
    <div class="text-center mt-5">
        <p class="text-gray-600 text-2xl font-bold sm: text-base">{{messageDetails?.greeting}}</p>   
    </div>
    <div class="text-center mt-1" *ngIf="messageDetails['messageResponse'] !==0">
        <p class="text-black-100 text-md font-semibold">
            {{messageDetails?.supportMessage}}
            <a href="mailTo:'support@legacynow.com'" class="text-lg underline text-darkblue fs-content" *ngIf="messageDetails?.supportMessage">
            support@legacynow.com</a>  
            <span *ngIf="messageDetails['messageResponse'] == 2"> for prompt investigation and resolution. </span>     
        </p>  
    </div> 
    <div class="text-center mt-2" *ngIf="messageDetails['messageResponse'] == 2">
        <p class="text-gray-800 text-sm font-normal"> 
            {{messageDetails?.footerMessage}}
        </p>
    </div>
</div>
