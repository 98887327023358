import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CardService } from 'src/app/components/consumer-payment-options/card-details/card-details.service';
import { ConsumerSubscriptionService } from 'src/app/components/consumer-payment-section/consumer-subscription.service';
import { ConsumerPaymentService } from 'src/app/components/consumer-update-payment/consumer-payment-update.service';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SocketService } from 'src/app/services/socket.service';
import { ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN, CREDIT_CARD_MASK, LOCAL_STORAGE_UPDATE_STATUS, NUMBERS_ONLY_PATTERN, USER_TYPES, YEAR_MASK, ZIPCODE_PATTERN } from 'src/constants/application.const';

@Component({
  selector: 'app-card-detail',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.css'],
})
/**
 * card details component
 */
export class CardDetailComponent implements OnInit, OnChanges {
  public selectedUserRequestId: string;
  public userType: string;
  @Input() subscriptionFlag: boolean;
  @Input() vaultTransferAmount: string;
  @Input() total: number;
  @Input() activePackage: any;
  @Input() dlbId: string;
  @Input() paymentCompleted: string;
  @Input() isPromoCodeApplied: boolean;
  @Input() appliedPromoCodeId: string;
  @Input() isTransferDigitalWallet: boolean;
  @Input() isUpdatePlan: boolean;
  @Input() transferVaultScreen: boolean;
  @Input() isPaymentCompleted: number;
  @Input() monthlySubscription: number;
  @Input() promoCodeApplied: number;
  @Input() discountAmount: number;
  public toggleSectionBody: boolean;
  public monthlyPackage: any;
  public yearlyPackage: any;
  public selectedPackage: string = 'Yearly';
  public subscription: boolean;
  public toggleSectionBodys: boolean;
  public userData: any;
  public submitLoader: boolean;
  public progressValue: number;
  public paymentForm: FormGroup;
  public cardNumber: any;
  public expiryMonth: any;
  public expiryYear: any;
  public cardHolderName: any;
  public securityCode: any;
  public cardMask: any;
  public yearMask: any;
  public cards: any;
  public selectedCardDetails: any;
  public isLoading = false;
  public digitalVaultId: string;
  public cardToken: string;
  public updateSubscriptionSubscription: Subscription;
  public transferVaultScreenSubscription: Subscription;

  /**
   * constructor
   */
  constructor(
    private slugInterceptorService: SlugInterceptorService,
    private subscriptionService: ConsumerSubscriptionService,
    private modalService: CommonModelService,
    private activeRouter: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private toastService: ToastrService,
    private formBuilder: FormBuilder,
    private paymentSubscriptionService: ConsumerPaymentService,
    private cardService: CardService,
    private socketService: SocketService
  ) {
  }

  /**
   * on change
   * @param{SimpleChanges}changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.subscriptionFlag) {
      this.subscription = changes.subscriptionFlag.currentValue;
      console.log('sub', this.subscription);
    }
    this.digitalVaultId = changes.dlbId?.currentValue;
    this.isUpdatePlan = changes.isUpdatePlan.currentValue;
    this.isTransferDigitalWallet = changes.isTransferDigitalWallet.currentValue;
    this.transferVaultScreen = changes.transferVaultScreen.currentValue;
  }

  /**
   *on init
   */
  ngOnInit(): void {
    this.cardMask = CREDIT_CARD_MASK;
    this.yearMask = YEAR_MASK;
    this.selectedUserRequestId = this.activeRouter.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.userData = this.localStorageService.getUserData();
    this.dlbId = this.localStorageService.getUserData()?.request?.request_unique_id;
    this.isPaymentCompleted = this.localStorageService.getDataByKey('is_payment_complete');
    this.loadCards();
    this.paymentForm = this.formBuilder.group({
      currency: ['usd'],
      new_card: ['1'],
      card_id: [''],
      card_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN), Validators.minLength(3), Validators.maxLength(50)] }],
      card_number: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(16), Validators.maxLength(16)] }],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.maxLength(10), Validators.pattern(ZIPCODE_PATTERN)] }],
      exp_month: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(2), this.monthValidator.bind(this)] }],
      exp_year: ['', { updateOn: 'blur', validators: [Validators.required, this.yearValidator.bind(this)] }],
      cvv: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern(NUMBERS_ONLY_PATTERN)] }],
    });
    // get user request id for executor/custodian login
    this.selectedUserRequestId = this.localStorageService.getDataByKey('user_request_id');
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.paymentForm?.controls;
  }

  /**
   * get card details
   */
  public loadCards() {
    this.cardService.getCardsApi().subscribe((response: any) => {
      this.cards = response?.data;
      this.cards.forEach((current, index) => {
        if (current.is_default == 1) {
          this.selectedCard(this.cards[index]);
        }
      });
    });
  }

  /**
   * toggle active package
   */

  /* public toggleActivePackage() {
    if (this.selectedPackage == 'Yearly') {
      this.selectedPackage = 'Monthly';
      this.activePackage = this.monthlyPackage;
      this.total = this.total - parseFloat(this.yearlyPackage?.price) + parseFloat(this.monthlyPackage?.price);
    } else {
      this.selectedPackage = 'Yearly';
      this.activePackage = this.yearlyPackage;
      this.total = this.total - parseFloat(this.monthlyPackage?.price) + parseFloat(this.yearlyPackage?.price);
    }
  } */

  /**
   * month validation
   * @param {FormControl}control
   * @return {boolean}
   */
  monthValidator(control: FormControl): { [s: string]: boolean } {
    const enteredMonth = +(control.value);
    if (enteredMonth > 12 || enteredMonth < 1) {
      return { isNotAllowed: true };
    } else {
      return null;
    }
  }

  /**
   * year validation
   * @param {FormControl}control
   * @return {boolean}
   */
  yearValidator(control: FormControl): { [s: string]: boolean } {
    const enteredYear = +(control.value);
    if (enteredYear < new Date().getFullYear() && enteredYear != 0) {
      return { isNotAllowed: true };
    } else {
      return null;
    }
  }

  public initiatePayment() {
    this.isLoading = true;
    if (this.selectedCardDetails) {
      this.paymentValidation();
    } else {
      // Token is Created for the New card
      this.getcardToken().subscribe({
        next: (response: any) => {
          this.isLoading = false;
          this.cardToken = response.id;
          this.paymentValidation();
        },
        error: e => {
          this.isLoading = false;
          const msg = e?.['error']?.['error']?.message;
          if (msg) {
            this.toastService.error(msg);
          }
        },
      });
    }
  }

  /**
   * Generates Card Token for the New Card Details
   */
  public getcardToken() {
    const cardNumber = this.paymentForm.value.card_number;
    const expMonth = this.paymentForm.value.exp_month;
    const expYear = this.paymentForm.value.exp_year;
    const cvv = this.paymentForm.value.cvv;
    return this.cardService.generateCardToken(cardNumber, expMonth, expYear, cvv);
  }

  /**
   * Calculates the monthly payment, considering any applied promo code discount.
   * @return The calculated monthly payment amount.
   */
  public calculateMonthlyPayment(): number {
    if (this.isPromoCodeApplied) {
      const amount = this.monthlySubscription - this.discountAmount;
      // Apply promo code discount if it is applied
      return Number(amount.toFixed(2));
    } else {
      // Return the regular monthly subscription amount
      return Number(this.monthlySubscription);
    }
  }

  /**
   * payment validation
   */
  public paymentValidation() {
    if (this.isPaymentCompleted && this.isPaymentCompleted !== 2) {
      this.updateSubscription();
    } else {
      this.makePayment();
    }
  }


  /**
   * make payment
   */
  public makePayment() {
    const params = {
      token: this.cardToken,
      currency: this.paymentForm.value.currency,
      new_card: this.paymentForm.value.new_card,
      card_id: this.paymentForm.value.card_id,
      zipcode: this.paymentForm.value.zipcode,
      exp_month: this.paymentForm.value.exp_month,
      exp_year: this.paymentForm.value.exp_year,
      transfer_type: this.isTransferDigitalWallet === false ? '0' : '1',
      // package_id: this.activePackage?.id,  // because they creating package based upon the amount from backend
      amount: this.calculateMonthlyPayment(),
      request_id: this.selectedUserRequestId,
      promo_code_id: this.appliedPromoCodeId ?? '',
      discount_amt: this.discountAmount ?? '',
    };

    this.modalService.open('payment-process');

    const handleSuccess = (response: APIResponseModel, processedCheckoutResponse: any) => {
      this.submitLoader = false;
      this.isLoading = false;
      if (response.status) {
        // this.toastService.success(response.message);
        if (this.userType === 'Consumer') {
          this.paymentCompleted = response.data.is_payment_complete;
          console.log('payment completed status', this.paymentCompleted);
          this.localStorageService.storeData('is_payment_complete', this.paymentCompleted);
          this.localStorageService.storeData('is_transfer_amount_paid', response?.data?.is_transfer_amount_paid);
          this.localStorageService.updateUserData(USER_TYPES.user, {
            key: 'user',
            updateValue: response.data.is_payment_complete,
            updateKey: 'is_payment_complete',
            type: LOCAL_STORAGE_UPDATE_STATUS.O,
          });
          // Here need to handle socket for pending stripe payments
          if (this.paymentCompleted == '1') {
            this.modalService.close('payment-process');
            void this.slugInterceptorService.navigate(['dashboard']);
          }
          // if 3d secure payment open authentication page in new tab
          if (processedCheckoutResponse !== null) {
            window.open(processedCheckoutResponse?.data?.payment_confirm_url, "_blank");
          }
        } else {
          void this.slugInterceptorService.navigate(['executor-custodian', 'dashboard']);
        }
      }
    };

    const handleException = (exception: any) => {
      this.submitLoader = false;
      this.isLoading = false;
      this.modalService.close('payment-process');
      const exceptionMessage = exception?.error?.message;
      if (typeof exceptionMessage == 'string') {
        this.commonService.paymentErrorListener(exceptionMessage);
        this.modalService.open('payment-failure');
        return;
      }else {
        this.modalService.open('payment-failure');
      }
      const message_obj: {} = exceptionMessage;
      const messages: Array<string> = [];
      Object.keys(message_obj).forEach((k) => message_obj[k]?.forEach((e) => messages.push(e)));
      messages?.forEach((m) => this.toastService.error(m));
    };

    if (this.selectedCardDetails) {
      params.new_card = '0';
      params.token = '';
      params.card_id = this.selectedCardDetails.id;
      params.zipcode = this.selectedCardDetails.zipcode;
      params.exp_month = this.selectedCardDetails.exp_date.split('/')[0];
      params.exp_year = this.selectedCardDetails.exp_date.split('/')[1];
    }

    this.subscriptionService.checkoutApi(params).subscribe({
      next: (checkoutResponse) => {
        const processedCheckoutResponse = checkoutResponse?.data?.hasOwnProperty('payment_confirm_url') ? checkoutResponse : null;
        const requestId = this.userData?.request?.id || this.selectedUserRequestId;

        this.commonService.payForDlb(requestId).subscribe({
          next: (response) => {
            handleSuccess(response, processedCheckoutResponse);
          },
          error: (exception) => {
            handleException(exception);
          },
        });
      },
      error: (exception) => {
        handleException(exception);
      },
    });
  }

  /**
   * disable card screen
   */
  public disableCardScreen() {
    if (this.subscription) {
      this.paymentSubscriptionService.paymentScreenObservableEvents1(false);
    } else {
      if (this.userType === 'Consumer') {
        if (this.isPaymentCompleted) {
          void this.slugInterceptorService.navigate(['/subscription-plan']);
        } else {
          void this.slugInterceptorService.navigate(['/dashboard']);
        }
      } else {
        void this.slugInterceptorService.navigate(['executor-custodian', 'dashboard']);
      }
    }
  }

  /**
   * update subscription
   */
  public updateSubscription() {
    const errorFn = (exception) => {
      this.submitLoader = false;
      this.isLoading = false;
      this.modalService.close('payment-process');
      this.toastService.error(exception['error']?.message);
    };
    let params = {
      token: this.cardToken,
      currency: this.paymentForm.value.currency,
      new_card: this.paymentForm.value.new_card,
      card_id: this.paymentForm.value.card_id,
      zipcode: this.paymentForm.value.zipcode,
      exp_month: this.paymentForm.value.exp_month,
      exp_year: this.paymentForm.value.exp_year,
      transfer_type: this.isTransferDigitalWallet ? '1' : '0',
      // package_id: this.activePackage?.id,
      isupdate: this.isUpdatePlan ? '1' : '0',
      promo_code_id: this.appliedPromoCodeId ?? '',
      amount: this.calculateMonthlyPayment(),
      discount_amt: this.discountAmount ?? '',
    };
    if (this.selectedCardDetails) {
      params = {
        ...params,
        new_card: '0',
        card_id: this.selectedCardDetails.id,
        exp_month: this.selectedCardDetails.exp_date.split('/')[0],
        exp_year: this.selectedCardDetails.exp_date.split('/')[1],
      };
    }

    this.modalService.open('payment-process');
    this.subscriptionService.updateSubscription(params).subscribe({
      next: () => {
        this.commonService.payForDlb(this.userData?.request?.id).subscribe({
          next: (response: APIResponseModel) => {
            this.submitLoader = false;
            this.isLoading = false;
            if (response.status) {
              this.localStorageService.storeData('is_payment_complete', response.data.is_payment_complete);
              this.localStorageService.storeData('is_transfer_amount_paid', response?.data?.is_transfer_amount_paid);

              this.localStorageService.updateUserData(USER_TYPES.user, {
                key: 'request',
                updateValue: response.data.is_payment_complete,
                updateKey: 'is_payment_complete',
                type: LOCAL_STORAGE_UPDATE_STATUS.O,
              });
              this.modalService.close('payment-process');
              // Here also need to handle socket for failure & pending payment status
              if (response.data.is_payment_complete === 5 || response.data.is_payment_complete === 4) {
                void this.slugInterceptorService.navigate(['subscribe']);
                return;
              }
              this.toastService.success(response.message);
              this.paymentSubscriptionService.paymentScreenObservableEvents1(false);
              void this.slugInterceptorService.navigate(['dashboard']);
            }
          }, error: (exception) => {
            errorFn(exception);
            this.modalService.open('payment-failure');
          },
        },
        );
      },
      error: errorFn,
    });
  }

  /**
   * on destroy
   */
  public ngOnDestroy(): void {
    this.updateSubscriptionSubscription?.unsubscribe();
    this.transferVaultScreenSubscription?.unsubscribe();
  }

  /**
   * selected card
   * @param {any} card
   */
  public selectedCard(card: any) {
    this.selectedCardDetails = card;
  }

  /**
   * Handle Input keyup event to autofocus the next input field.
   * @param $event
   */
  onInputKeyUp($event: KeyboardEvent) {
    const omitKeys = [
      'Backspace',
      'Tab',
      'ShiftLeft',
      'ShiftRight',
    ];
    if (omitKeys.indexOf($event.code) < 0) {
      // autofocus order
      const arrIDs = [
        'card_number',
        'exp_month',
        'exp_year',
        'sec_code',
        'card_name',
      ];
      const inputID: string = $event.target?.['id'];
      const maxLength: number = $event.target?.['maxLength'];
      const currentLength: number = ($event.target?.['value'] as string).length;

      let element;
      if (currentLength >= maxLength) {
        const nextID = arrIDs[arrIDs.indexOf(inputID) + 1];
        element = document.getElementById(nextID);
        element.focus();
      }
    }
  }

  /**
   * To navigate saved card page
   */
  public navigateToSavedCards() {
    void this.slugInterceptorService.navigate(['/saved-cards']);
  }
}
