import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { MODAL_CLOSE, MODAL_OPEN } from 'src/constants/application.const';


@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
})
/**
 * Common modal for whole project
 */
export class CommonModalComponent implements OnInit {
  @ViewChild('modalContentRef') modal: ElementRef;
  @Input() id: string;
  @Input() heightClass: string = 'h-auto';
  @Output() commonModalEmitter: EventEmitter<CommonModalEmitter> = new EventEmitter<CommonModalEmitter>();
  private readonly element: any;
  private openStatus: boolean = false;

  /**
   * @constructor
   */
  constructor(private modalService: CommonModelService, private el: ElementRef, private commonService: CommonService) {
    this.element = this.el.nativeElement;
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', (el) => {
      if (el.target.className === 'jw-modal') {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  /**
   * called when component destroys
   */
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
    document.body.classList.remove('jw-modal-open');
  }

  /**
   * open model
   */
  public open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');

    // // scroll the modal back to the top.
    const modal = document.querySelector('.scroll') as HTMLElement;
    modal.scrollIntoView({ behavior: 'smooth' });
    this.commonModalEmitter.emit({ id: this.id, action: 'open' });

    this.openStatus = true;
    this.commonService.sendCurrentModalStatus(MODAL_OPEN);
  }

  /**
   * close modal
   */
  public close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    this.commonModalEmitter.emit({ id: this.id, action: 'close' });

    this.openStatus = false;
    this.commonService.sendCurrentModalStatus(MODAL_CLOSE);
  }

  /**
   * @return <code>true</code> if the template contains 'jw-modal-open', otherwise <code>false</code>.
   */
  public isOpen(): boolean {
    return this.openStatus;
  }
}


export interface CommonModalEmitter {
  id: string;
  action: 'open' | 'close';
}
