<div class="absolute left-0 right-0 top-0 z-50 bg-red-700 lgn-new-header" id="navBarStarts">
  <div class="w-full pr-4 sm:pr-6 lg:pr-0">
    <div class="flex justify-between items-center lg:justify-start md:space-x-10 h-18 custom-mobile-header">
      <div class="bg-white h-full pl-4 sm:pl-10 pr-2 sm:pr-6 flex items-center gap-2 md:gap-4 flex-row logo-col">
        <a class="cursor-pointer flex pr-2" (click)="menuChange('')">
          <span class="sr-only">Legacy Now</span>
          <img width="374" src="assets/images/consumer/logo-old.png" alt="legacy-now" class="object-contain w-40 sm:w-48">
        </a>
        <a *ngIf="orgLogoPath" class="border-l pl-8 cursor-pointer max-h-12 maxw128">
          <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
        </a>
      </div>
      <div class="md:-mr-2 -my-2 lg:hidden">
        <button type="button" (click)="responsiveMenuToggle = !responsiveMenuToggle"
          class="rounded-md p-2 inline-flex items-center justify-center text-white focus:outline-none nav-toggle-btn"
          aria-controls="menuToggleItems" id="menuToggle" aria-expanded="false">
          <span class="sr-only">Open menu</span>
          <!-- Heroicon name: outline/menu -->
          <svg *ngIf="!responsiveMenuToggle" class="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <svg *ngIf="responsiveMenuToggle" class="w-8 h-8" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="#FFF" stroke-linecap="round" stroke-linejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
      <nav class="hidden lg:flex lg:space-x-5 xl:space-x-8 menu" aria-labelledby="navbar">
        <a (click)="menuChange('home')" [class.menu--active]="this.menuActive==='home'"
          class="cursor-pointer fs-menu lh-menu font-ave-black text-lg"
          [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-white menu--inactive'">
          Home
        </a>
        <a (click)="menuChange('pricing')" [class.menu--active]="this.menuActive==='pricing'"
          class="cursor-pointer fs-menu lh-menu font-ave-black text-lg"
          [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-white menu--inactive'">
          Pricing
        </a>
        <a (click)="menuChange('contact-us')" [class.menu--active]="this.menuActive==='contact-us'"
          class="cursor-pointer fs-menu lh-menu font-ave-black text-lg"
          [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-white menu--inactive'">
          Contact
        </a>
        <a (click)="viewIntroVideo()" class="cursor-pointer font-ave-black fs-menu lh-menu text-lg"
          [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-white menu--inactive'">
          Intro Video
        </a>
      </nav>

      <div class="hidden lg:flex items-center justify-end md:flex-1 lg:w-0 pr-10 secondary-menu-col">
        <a (click)="navigateToLogin()"
          class="cursor-pointer xl:border-l xl:pl-6 whitespace-nowrap font-ave-black text-base fs-menu button-dark-blue bg-dark-blue"
          [ngClass]="!menuAlternate ? 'text-blue':'text-secondary'">
          Log In
        </a>
      </div>
    </div>
  </div>
  <div id="menuToggleItems" *ngIf="responsiveMenuToggle"
    class="menu-mobile-box absolute inset-x-0 shadow-lg transition transform origin-top-right lg:hidden">
    <div class="bg-white divide-y-2 divide-gray-50">
      <div class="py-5 mx-4">
        <nav class="grid gap-2" aria-labelledby="navbar">
          <a *ngIf="showGetStartedBtn"
             (click)="menuChange('educational-intro')"
             [ngClass]="(menuActive === 'educational-intro') ? 'text-white bg-red-700' : 'text-primary'"
             class="cursor-pointer text-semibold fs-menu hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Get Started
          </a>
          <a (click)="menuChange('home')" [ngClass]="(menuActive === 'home')?'text-white bg-red-700': 'text-primary'"
            class="cursor-pointer text-semibold fs-menu hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Home
          </a>
          <a (click)="menuChange('pricing')"
            [ngClass]="(menuActive === 'pricing')?'text-white bg-red-700': 'text-primary'"
            class="cursor-pointer text-semibold fs-menu hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Pricing
          </a>
          <a (click)="menuChange('contact-us')" [ngClass]="{'text-white bg-red-700': (menuActive === 'contact-us')}"
            class="text-semibold fs-menu text-primary hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Contact
          </a>
          <a (click)="navigateToLogin()"
            class="cursor-pointer text-semibold fs-menu text-primary hover:text-white px-4 py-3 rounded-md hover:bg-red-500">
            Log In
          </a>
          <a (click)="viewIntroVideo()"
            class="text-semibold fs-menu text-primary hover:text-white px-4 py-2 rounded-md hover:bg-red-500 cursor-pointer">
            Intro Video
          </a>
        </nav>
      </div>
    </div>
  </div>
</div>
<app-demo-video-modal [modal_id]="MODEL_DEMO_VIDEO" [vaultVideo]="VAULT_VIDEO"></app-demo-video-modal>
