<app-common-modal id="plan-cancel-reasons" class="app-common-modal">
    <div class="w-full relative rounded-md">
        <img src="assets/images/about-yourself/close.svg" alt="" (click)="closeModel()"
            class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer">
        <form [formGroup]="planCancelForm" autocomplete="off" (ngSubmit)="onSubmit()">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12">
                    <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide"> Cancel Subscription
                    </h4>
                    <p class="text-grey text-regular pt-1 text-base"> We're sorry to see you go. We'd love to gather
                        your
                        feedback. Please share your response below. </p>
                </div>
                <!-- Reason -->
                <div class="col-span-12">
                    <ng-container *ngFor="let reason of reasonsList">
                        <label class="flex items-center gap-3">
                            <input [value]="reason.id" [id]="reason.id" type="radio" (input)="selectedReason(reason.order)"
                                class=" h-5 w-5 my-2 text-darkblue focus:outline-none" formControlName="cancellation_reason_id">
                            <p class="text-regular text-base text-grey font-medium">
                                {{reason.reason}}
                            </p>
                        </label>
                    </ng-container>
                    <div *ngIf="formGet['cancellation_reason_id'].errors">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                            *ngIf="formGet['cancellation_reason_id'].errors.required &&formGet['cancellation_reason_id'].touched">
                            Please make a selection
                        </p>
                    </div>
                </div>
                <!-- Other Reason -->
                <div class="col-span-12" *ngIf="isOtherChosen">
                    <label for="user_reason" class="text-label-grey text-sm text-regular mb-2">Tell Us More*</label>
                    <input type="text" id="user_reason" formControlName="user_reason"
                        class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                        name="user_reason">
                    <div *ngIf="formGet['user_reason'].errors && formGet['user_reason'].touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                            *ngIf="formGet['user_reason'].errors.maxlength">
                            Reason must not exceed 225 characters
                        </p>
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                            *ngIf="formGet['user_reason'].errors.minlength && !formGet['user_reason'].errors.pattern">
                            Reason must not have atleast 3 characters
                        </p>
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                            *ngIf="formGet['user_reason'].errors.required">
                            Field is required
                        </p>
                    </div>
                </div>
                <!-- Additional Comments -->
                <div class="col-span-12">
                    <label for="additional_details" class="text-label-grey text-sm text-regular mb-2">Additional
                        Comments:</label>
                    <textarea type="text" id="additional_details"
                        class=" input py-3 px-4 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                        placeholder="Provide details about your cancellation reason (optional)"
                        name="additional_details" formControlName="additional_details"></textarea>
                    <div *ngIf="formGet['additional_details'].errors && formGet['additional_details'].touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                            *ngIf="formGet['additional_details'].errors.minlength">
                            Must have atleast 3 characters
                        </p>
                    </div>
                </div>
                <!-- Submit Button -->
                <div class="col-span-12">
                    <app-button-loader [buttonText]="'Submit'" [loader]="submitLoader" [valid]="!submitLoader"
                        [buttonClass]="'tracking-wide hover:underline-text text-base w-full sm:w-auto bg-darkblue rounded-xs h-auto focus:outline-none text-regular text-white px-4 py-2'"
                        [buttonType]="'submit'">
                    </app-button-loader>
                </div>
            </div>
        </form>

    </div>

</app-common-modal>