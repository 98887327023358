<div class="my-6">
    <div class="border-card bg-white rounded-md p-6">
        <!-- START: Table Sort & Search Options -->
        <div class="flex flex-wrap">
            <div class="w-full lg:w-1/2 ">
                <div class="relative">
                    <div class="sm:w-full md:w-2/4  relative h-10 text-grey ">
                        <div class=" absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"> <img
                                src="assets/images/about-yourself/search.svg" class="w-4  h-4" alt="digital vault">
                        </div>
                        <input type="text" class=" input py-3 px-4 mt-1 w-full text-regular text-grey
                          rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                            placeholder="Search by Name" (input)="onSearchProfessional($event.target['value'])">
                    </div>
                </div>
            </div>
            <div class="w-full lg:w-1/2">
                <div class="flex flex-wrap item-center justify-between">
                    <div>
                        <div class=" md:justify-end">
                            <div class="flex justify-center item-center text-grey mt-4">
                                <div
                                    class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                                    Showing: </div>
                                <select [(ngModel)]="professionalTableSettings.recordsPerPage"
                                    (change)="getProfessionalsList()"
                                    class="text-center form-select cursor-pointer bg-transparent ml-4  pt-1 w-20 mt-0 rounded-xs h-8 text-regular text-grey p-2 border-none block focus:outline-none insurance_term">
                                    <option *ngFor="let option of selectOptions;let i=index" [value]="option['id']">
                                        {{option["value"]}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class=" md:justify-end css-shortby">
                        <div class="md:justify-end">
                            <div class="flex justify-center item-center h-10 text-grey mt-4">
                                <div
                                    class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                                    Sort by: </div>
                                <select [(ngModel)]="professionalTableSettings.sortPreference"
                                    (change)="getProfessionalsList()"
                                    class="text-center form-select cursor-pointer bg-transparent w-40 ml-4 pt-1 mt-0 rounded-xs h-10 text-regular text-grey border-none block focus:outline-none insurance_term ng-pristine ng-valid ng-touched">
                                    <option *ngFor="let order of orderOptions;let i=index" [value]="order['id']">
                                        {{order["value"]}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: Table Sort & Search Options -->
        <div class="w-full" *ngIf="spinner">
            <div class="mt-3 w-full">
                <app-shimmer-loading [loading]="true" [width]="'100%'" [height]="'3rem'"></app-shimmer-loading>
            </div>
            <div class="flex flex-row mt-3 mb-6 items-center">
                <div>
                    <app-shimmer-loading [loading]="true" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
                </div>
                <div class="ml-4 mt-0 w-full">
                    <ng-container *ngFor="let _ of [].constructor(3)">
                        <app-shimmer-loading [loading]="true" [width]="'100%'"></app-shimmer-loading>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="mt-8" *ngIf="!spinner">
            <div class="table-responsive">
                <table class="table w-full text-left mb-6" aria-describedby="advisorTable">
                    <thead class="bg-gray-400 text-blue text-md text-regular ">
                        <tr>
                            <th class="p-4" scope="row">Name</th>
                            <th class="p-4" scope="row">Title</th>
                            <th class="p-4" scope="row">No&nbsp;of&nbsp;Clients</th>
                            <th class="p-4" scope="row">Added&nbsp;On</th>
                            <th class="p-4" scope="row">Last&nbsp;Login</th>
                            <th class="p-4" scope="row">Action</th>
                        </tr>
                    </thead>

                    <ng-container *ngIf="!spinner">
                        <ng-container>
                            <tbody *ngFor="let professional of professionalsList;let i = index;">
                                <tr class="border-b-2 border-fuchsia-600 p-4 relative" *ngIf="professionalsList?.length > 0">
                                    <!-- Name , Profile Picture , Email -->
                                    <td>
                                        <div class="flex items-center ">
                                            <div class="relative w-16 h-16">
                                                <app-image-component [image]="professionalImageSrc+professional['profile_picture']"
                                                    [class]="'rounded-full w-14 h-14 lg:w-16 lg:h-16 object-cover '"
                                                    [defaultImage]="'assets/icons/user-bg.svg'">
                                                </app-image-component>
                                                <div
                                                    class="active w-3 h-3 rounded-full bg-green-500 border-2 border-white absolute bottom-2 right-1 ">
                                                </div>
                                            </div>
                                            <div class="pl-2 pr-4 lg:p-5 ">
                                                <a
                                                    class="text-blue-600 text-semibold underline cursor-pointer text-md lg:text-lg text-regular">{{professional["full_name"]}}</a>
                                                <p class="text-grey text-base lg:text-md text-regular">
                                                    {{professional["email"]}}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <!-- Title -->
                                    <td class="p-4">
                                        <p class="text-grey text-regular tracking-wide whitespace-normal">
                                            {{professional['manager_term']}}</p>
                                    </td>
                                    <!-- No of Clients -->
                                    <td class="p-4">
                                        <div class="flex items-center ">
                                            <div><img src="assets/images/dashboard/table-user.svg" alt="digital vault">
                                            </div>
                                            <div class="pl-3">
                                                <p class="text-grey text-regular tracking-wide">
                                                    {{professional["total_clients"]}}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <!-- Added on -->
                                    <td class="p-4 whitespace-normal">
                                        <p class="text-grey text-regular text-md">{{professional["added_on"]}}</p>
                                    </td>
                                    <!-- Last Login -->
                                    <td class="p-4">
                                        <p class="text-grey text-regular tracking-wide whitespace-normal">
                                            {{professional["last_login"]}}</p>
                                    </td>
                                    <!-- Action -->
                                    <td class="p-4 cursor-pointer" (click)="toggleDropDown(i)"><img
                                            src="assets/images/dashboard/icon-more.png" alt="db"></td>
                                    <div class="py-1 absolute right-0 top-14 w-40 z-50 rounded-md shadow-lg bg-white focus:outline-none"
                                        role="menu" aria-orientation="vertical" aria-labelledby="user-menu"
                                        *ngIf="profileMenuToggle && (this.index === i )">
                                        <!-- View -->
                                        <a (click)="viewProfessional(professional)"
                                            class="cursor-pointer block px-4 py-1 text-sm text-grey text-regular hover:bg-gray-100"
                                            role="menuitem">View
                                        </a>
                                        <!-- Edit -->
                                        <a (click)="changeRoute('professional-management/edit/'+professional['id']);editProfessional(professional['id']) "
                                            class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100"
                                            role="menuitem">Edit
                                        </a>
                                        <!-- Delete -->
                                        <a (click)="openConfirmationModal(professional)"
                                            class="cursor-pointer block px-4 py-1 text-sm text-grey text-regular hover:bg-gray-100"
                                            role="menuitem">Delete
                                        </a>
                                    </div>
                                </tr>
                            </tbody>
                        </ng-container>
                    </ng-container>
                </table>
                <div class=" flex justify-center mt-5 " *ngIf="professionalsList?.length === 0">
                    <h4 class="text-grey text-base lg:text-md text-regular">
                        No data found
                    </h4>
                </div>
            </div>
        </div>
    </div>
    <!-- START: Table Paginations -->
    <div class="pt-8">
        <nav class="flex flex-row flex-nowrap justify-between md:justify-center items-center" aria-label="Pagination">
            <a *ngIf="currentPage > 1" (click)="getProfessionalsList( currentPage > 1 ? currentPage-1 : 0 )"
                class="flex w-7 h-7 mr-1 justify-center items-center text-black cursor-pointer" title="Previous Page">
                <span class="sr-only">Previous Page</span>
                <img src="assets/images/dashboard/left-arrow-enable.png" alt="digital vault" />
            </a>
            <a *ngIf="currentPage === 1" class="flex w-7 h-7 mr-1 justify-center items-center text-black "
                title="Previous Page">
                <span class="sr-only">Previous Page</span>
                <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
            </a>

            <div class="pagination">
                <a (click)="getProfessionalsList(1)"
                    [ngClass]="{ 'bg-darkblue' : currentPage === 1, 'text-white' : currentPage === 1 }"
                    class="pagination-link" title="Page 1">1</a>
                <span *ngIf="currentPage > 5">...</span>
                <ng-container *ngFor="let i of [-2, -1, 0, 1, 2]">
                    <a *ngIf="currentPage + i > 1 && currentPage + i < lastPage"
                        (click)="getProfessionalsList(currentPage + i)"
                        [ngClass]="{ 'bg-darkblue' : currentPage === currentPage + i, 'text-white' : currentPage === currentPage + i }"
                        class="pagination-link" title="Page {{currentPage + i}}">
                        {{currentPage + i}}
                    </a>
                </ng-container>
                <span *ngIf="currentPage < lastPage - 4">...</span>
                <a *ngIf="lastPage > 1" (click)="getProfessionalsList(lastPage)"
                    [ngClass]="{ 'bg-darkblue' : currentPage === lastPage, 'text-white' : currentPage === lastPage }"
                    class="pagination-link" title="Page {{lastPage}}">
                    {{lastPage}}
                </a>
            </div>

            <a (click)="getProfessionalsList( currentPage + 1 )" *ngIf="(currentPage) <  lastPage"
                class="flex w-7 h-7 ml-1 justify-center items-center text-black cursor-pointer" title="Next Page">
                <span class="sr-only">Next Page</span>
                <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
            </a>
            <a *ngIf="(currentPage) ===  lastPage" class="flex w-7 h-7 ml-1 justify-center items-center text-black "
                title="Next Page">
                <span class="sr-only">Next Page</span>
                <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
            </a>
        </nav>
    </div>
    <!-- END: Table Paginations -->
</div>

<!-- Delete Confirmation Modal -->
<app-delete-confirmation-modal [submitLoaderDelete]="submitLoaderDelete" [content]="'Professional'"
    (deleteOption)="deleteProfessional($event)">
</app-delete-confirmation-modal>

<!-- View Detailed Info Modal -->
<app-view-detailed-info-modal [viewModalData]="viewProData" [viewModalHeading]="'View Professional '">
</app-view-detailed-info-modal>