import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { AddressDetails, ToggleOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { UserService } from 'src/app/services/user.service';
import { LOCAL_STORAGE_UPDATE_STATUS, TOGGLE_OPTIONS_YES_NO, USER_TYPES } from 'src/constants/application.const';
import { RESET_CHILD_FORM } from 'src/constants/children.const';
import { LPEmitterInterface, LPSettingsInterface, LPUserPeopleInterface } from '../../../sharedComponent/list-people/list-people-interfaces';
import { ListPeopleComponent } from '../../../sharedComponent/list-people/list-people.component';


@Component({
  selector: 'app-children-status',
  templateUrl: './children-status.component.html',
  styleUrls: ['./children-status.component.css'],
})

/**
 * Children status component
 */
export class ChildrenStatusComponent implements OnInit {
  public readonly toggleOptions: Array<ToggleOptionsInterface> = TOGGLE_OPTIONS_YES_NO;
  public readonly sectionSaveExitOptions = { section: 1, type: 6 };
  public lpSettings: LPSettingsInterface = { edit: { show: true }, isLoading: true};
  public userPeopleData: Array<LPUserPeopleInterface>;
  public submitLoader: boolean[] = [false, false];
  public childStatus: number;
  public toggleModalAdd: boolean = false;
  public userPeopleDataRaw: Array<any> = [];
  public editData: any;
  public preDefinedLoader: boolean = false;
  public backToRelationship: boolean;
  public userType: string;
  public requestId: string;
  public addressDetails: AddressDetails;
  public redirectUrl: any;
  public redirectUrlPersonId: string;
  public redirectBtnText: string;

  /**
   * @constructor
   */
  constructor(
    private personalDetailsService: PersonalDetailsService,
    private commonHelper: CommonHelper,
    private router: Router,
    private userService: UserService,
    private modalServce: CommonModelService,
    private peopleService: PeopleService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private slugInterceptorService: SlugInterceptorService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.redirectUrlPersonId = '';
    this.getPersonalDetails();

    // listen for changes
    this.userService.refreshChildrenListObserve.subscribe((updateValue: any) => {
      if (!updateValue || Object.keys(updateValue).length === 0) return;
      this.preDefinedLoader = true;
      this.commonHelper.diffCheckerAndUpdate(this.userPeopleDataRaw, 'id', updateValue?.id, updateValue);
      this.processUserPeopleData();
      this.preDefinedLoader = false;
    });

    // check for user marital status
    if (this.userType === 'Consumer') {
      const currentUserData = this.localStorageService.getUserData();
      this.backToRelationship = [3].indexOf(currentUserData?.request?.marital_status) === -1;
    }

    this.route.queryParams.subscribe((params) => {
      this.redirectUrl = params.redirectTo;
      this.redirectUrlPersonId = params.peopleId;
      this.redirectBtnText = this.commonHelper.getRedirectBtnText(this.redirectUrl);
    });
  }

  /**
   * Get personal details
   */
  public getPersonalDetails(): void {
    const isConsumer: boolean = this.userType === 'Consumer';
    this.preDefinedLoader = true;
    // get children list
    this.personalDetailsService.getPersonalDetails(!isConsumer ? this.requestId : undefined).subscribe(
      (response: APIResponseModel) => {
        this.preDefinedLoader = false;
        if (response.status) {
          this.childStatus = response?.data?.has_child;
          if (isConsumer) {
            this.addressDetails = {
              address: response.data.address,
              country: response.data.country?.id,
              apt_number: response.data?.apt_number,
              state: response.data.state?.id,
              city: response.data.city?.name,
              zipcode: response.data?.zipcode,
            };
          }
          this.userPeopleDataRaw = response.data.user_people.filter((data: any) => data.relation === 2);
          const getRedirectUrlPerson = this.userPeopleDataRaw?.find(data => data.id === this.redirectUrlPersonId)
          if(getRedirectUrlPerson) this.toggleModal(getRedirectUrlPerson);
          this.processUserPeopleData();
        }
      },
      (e) => {
        this.preDefinedLoader = false;
        this.commonHelper.httpResponseHandler(e?.error);
      },
    );
  }

  /**
   * Prepare data to display in list
   */
  public processUserPeopleData() {
    this.userPeopleData = ListPeopleComponent.getLPUserPeople(this.userPeopleDataRaw);
    this.userPeopleData.forEach(e => {
      const raw = this.userPeopleDataRaw.find(r => r['id'] == e.id);
      ['email', 'phone', 'address'].forEach(k => raw[k] = raw[k] || null); // Sanitize empty string to null
      e.subtext = raw['email'] ?? raw['phone'] ?? raw['address'];
    });
    this.lpSettings.isLoading = false;
  }

  /**
   * On Change toggle
   *
   * @param {ToggleOptionsInterface} selectedValue
   */
  public onChangeToggle(selectedValue: ToggleOptionsInterface): void {
    if (!selectedValue) return;
    this.childStatus = selectedValue?.value;
  }

  /**
   * onSubmit function
   *
   * @param {number} loaderIndex
   */
  public onSubmit(loaderIndex: number): void {
    this.submitLoader[loaderIndex] = true;
    if (this.userType === 'Consumer') {
      const payLoad = {
        has_child: this.childStatus,
        roletype: this.userType,
      };
      this.personalDetailsService.storePersonalDetails(payLoad, 6).subscribe(
        (response: APIResponseModel) => {
          if (response.status) {
            this.commonHelper.updateLocalstorageRequestStage(response.data);
            this.localStorageService.updateUserData(USER_TYPES.user, {
              key: 'request',
              updateValue: this.childStatus,
              updateKey: 'has_child',
              type: LOCAL_STORAGE_UPDATE_STATUS.O,
            });
            if (this.redirectUrl) {
              void this.slugInterceptorService.navigate([this.redirectUrl]);
              this.redirectUrl = '';
              return;
            }
            this.submitLoader[loaderIndex] = false;
            void this.slugInterceptorService.navigate(['about-yourself/view']);
          }
        },
        (exception: any) => {
          this.submitLoader[loaderIndex] = false;
          this.commonHelper.httpResponseHandler(exception?.error);
        },
      );
    } else {
      const payLoad = {
        has_child: this.childStatus,
        roletype: this.userType,
        request_id: this.requestId,
      };
      this.personalDetailsService.storePersonalDetails(payLoad, 6).subscribe(
        (response: APIResponseModel) => {
          this.submitLoader[loaderIndex] = false;
          if (response.status) {
            void this.slugInterceptorService.navigate([`/about-yourself/view/${this.requestId}`]);
          }
        },
        (exception: any) => {
          this.submitLoader[loaderIndex] = false;
          this.commonHelper.httpResponseHandler(exception?.error);
        },
      );
    }
  }

  /**
   * toggle add children modal
   *
   * @param {any} editValue
   */
  public toggleModal(editValue?: any): void {
    this.editData = {};

    if (editValue) {
      this.editData = { ...editValue };
    } else {
      this.peopleService.childrenObservableEvents({
        type: RESET_CHILD_FORM,
      });
    }
    this.modalServce.open('add-children-modal');
    this.toggleModalAdd = !this.toggleModalAdd;
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }

  /**
   * Handle events from {@link ListPeopleComponent}.
   * @param $event
   */
  listListener($event: LPEmitterInterface) {
    if ($event.clickedEdit || $event.clickedName) {
      this.toggleModal(this.userPeopleDataRaw.find(e => e['id'] == $event.id));
    }
  }
}
