<div class="scroll-hidden background-color-grey h-full">
  <!-- Navbar -->
  <app-professional-navbar></app-professional-navbar>


  <div class="grid grid-cols-12 px-8 sm:px-12 sm:py-4">
    <div class="col-span-12">
      <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
        Payout Management
      </h2>
      <p class="text-base text-grey py-2 tracking-wide text-regular">Check, Update payout
        details and history</p>
      <hr class="border-lightblue w-24 my-1 border-1">
    </div>
  </div>
  <div class="grid grid-cols-12 md:px-12  gap-6 md:gap-12">
    <div class="col-span-12">
      <ul class="flex">
        <li class="mr-3">
          <a class="inline-block text-grey text-regular text-lg cursor-pointer" (click)="menuChange(1)"
            [ngClass]="{'active': menuActive === 1}">New Payouts</a>
        </li>
        <li class="mr-3">
          <a class="inline-block text-grey text-regular text-lg cursor-pointer" (click)="menuChange(2)"
            [ngClass]="{'active': menuActive === 2}">History</a>
        </li>
      </ul>
    </div>
    <div class="col-span-12 lg:col-span-12 space-y-8">
      <!-- BEGIN: Payment Management -->
      <div *ngIf=" menuActive===1">
        <div class="scroll-hidden box-border body-content">

          <!--first Section start-->
          <div class="mx-8">

            <div class="flex flex-wrap ">
              <div class="w-full md:w-3/5 px-2">
                <div>
                  <h4 class="text-semibold text-grey text-md md:text-lg  lg:text-xl xl:text-2xl">New Payouts</h4>
                  <p class="text-grey text-regular text-sm md:text-md lg:text-md mb-3">Payout details will be shown
                    here</p>
                </div>
              </div>

            </div>
            <!--end-->
            <div class="pt-4 mb-6">
              <div class="border-card bg-white rounded-md p-6 ">
                <!--start second section -->
                <div class="flex flex-wrap">
                  <div class="w-full lg:w-1/2 ">
                    <div class="relative">
                      <div class="sm:w-full md:w-2/4  relative h-10 text-grey ">
                        <div class=" absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"> <img
                            src=" assets/images/about-yourself/search.svg " class="w-4  h-4" alt="digital vault"> </div>
                        <input type=" text " class=" input py-3 px-4 mt-1 w-full text-regular text-grey
                                  rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                          placeholder="Search" [(ngModel)]="searchKeyword"
                          (input)="onSearch()">
                      </div>
                    </div>
                  </div>
                </div>
                <!--end-->
                <div class="mt-5">
                  <div>
                    <table class="table w-full text-left pb-5 mb-5 " aria-describedby="payout">
                      <thead class="bg-gray-400 text-blue text-md text-regular ">
                        <tr>
                          <th class="p-4" scope="row">S.No</th>
                          <th class="p-4" scope="row">No. of Vaults created</th>
                          <th class="p-4" scope="row">Price per Vault</th>
                          <th class="p-4" scope="row">Total Vault Amount</th>
                          <th class="p-4" scope="row">Total Upcharge Amount</th>
                          <th class="p-4" scope="row">Payout Date</th>

                        </tr>
                      </thead>


                      <ng-container *ngIf="!loader">

                        <tbody *ngFor="let data of paymentData;let i = index">
                          <tr class="border-b-2 border-fuchsia-600 p-4 relative" *ngIf="paymentData.length > 0">
                            <td class="p-2 lg:p-4">


                              <p class="text-grey text-semibold  cursor-pointer text-lg  text-regular">
                                {{fromNo+i}}
                              </p>


                            </td>

                            <td class="p-4">


                              <p class="text-grey text-semibold  text-sm  text-regular">
                                {{data?.vault_count}}
                              </p>

                            </td>
                            <td class="p-4">

                              <p class="text-grey text-semibold  text-sm  text-regular">
                                {{data?.price_per_vault}} </p>

                            </td>
                            <td class="p-4">

                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{data?.vault_amount}}</p>

                            </td>
                            <td class="p-4">

                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{data?.upcharge_amount}} </p>

                            </td>
                            <td class="p-4">

                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{data?.payout_date}} </p>

                            </td>

                          </tr>


                        </tbody>

                      </ng-container>

                    </table>
                    <div class="flex justify-center" *ngIf="loader">
                      <div class="w-12 h-12 border-b-2 border-red rounded-full animate-spin mt-3 "></div>
                    </div>
                    <div class=" flex justify-center" *ngIf="paymentData?.length === 0 && !loader">
                      <h4 class="text-grey text-base lg:text-md text-regular mt-3">
                        No data found
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-8">
                <nav class="flex flex-row flex-nowrap justify-between md:justify-center items-center"
                  aria-label="Pagination">
                  <a *ngIf="currentPage > 1" (click)="getPayoutDetailsByPage( currentPage > 1 ? currentPage-1 : 0 )"
                    class="flex w-7 h-7 mr-1 justify-center items-center text-black cursor-pointer "
                    title="Previous Page">
                    <span class="sr-only">Previous Page</span>
                    <img src="assets/images/dashboard/left-arrow-enable.png" alt="digital vault" />
                  </a>
                  <a *ngIf="currentPage === 1" class="flex w-7 h-7 mr-1 justify-center items-center text-black "
                    class="flex w-7 h-7 mr-1 justify-center items-center text-black " title="Previous Page">
                    <span class="sr-only">Previous Page</span>
                    <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                    <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                  </a>
                  <ng-container *ngFor="let page of paginationLinks;let i = index;">
                    <a *ngIf=" i <= lastPage && i > 0 " (click)="getPayoutDetailsByPage(i)"
                      [ngClass]="{ 'bg-darkblue' : currentPage === i , 'text-white' : currentPage === i}"
                      class=" md:flex w-7 h-7 mx-1 justify-center items-center  text-black text-center cursor-pointer "
                      title="Page {{i}}">
                      {{i}}
                    </a>
                  </ng-container>
                  <a (click)="getPayoutDetailsByPage( currentPage + 1 )" *ngIf="(currentPage) <  lastPage"
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black cursor-pointer " title="Next Page">
                    <span class="sr-only">Next Page</span>
                    <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                    <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                  </a>
                  <a *ngIf="(currentPage) ===  lastPage"
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black "
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black " title="Next Page">
                    <span class="sr-only">Next Page</span>
                    <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                    <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>



      </div>
      <!-- END: Payout Management -->
      <!-- BEGIN:Payout History -->
      <div *ngIf="menuActive===2">
        <div class="scroll-hidden box-border body-content">

          <!--first Section start-->
          <div>
            <div>
              <div class="flex flex-wrap ">
                <div class="w-full md:w-3/5 px-2">
                  <div>
                    <h4 class="text-semibold text-grey text-md md:text-lg  lg:text-xl xl:text-2xl">Payout History
                    </h4>
                    <p class="text-grey text-regular text-sm md:text-md lg:text-md mb-3">Payout history and details
                      will be shown
                      here</p>
                  </div>
                </div>
              </div>
            </div>
            <!--end-->
            <div class="pt-4 mb-6">
              <div class="border-card bg-white rounded-md p-6 mb-2">
                <!--start second section -->
                <div class="flex flex-wrap">
                  <div class="w-full lg:w-2/4 ">
                    <div class="relative">
                      <div class="sm:w-full md:w-2/4  relative h-10 text-grey ">
                        <div class=" absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"> <img
                            src=" assets/images/about-yourself/search.svg " class="w-4  h-4" alt="digital vault"> </div>
                        <input type=" text " class=" input py-3 px-4 mt-1 w-full text-regular text-grey
                                  rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                          placeholder="Search" [(ngModel)]="searchKeyword"
                          (input)="getPayoutDetails()">
                      </div>
                    </div>
                  </div>
                  <div class="w-full lg:w-2/4">
                    <div class="flex flex-wrap items-center justify-around">

                      <div>
                        <div class=" md:justify-end">
                          <div class="flex justify-center item-center text-grey mt-4">
                            <div
                              class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                              Showing: </div>
                            <select [(ngModel)]="paymentCount" (change)="getPayoutDetailsByPage()"
                              class="form-select  bg-transparent ml-4 w-20 mt-0 pt-1 w-2/4 rounded-xs h-10 text-regular text-grey  p-2 border-none block focus:outline-none insurance_term">
                              <option *ngFor="let option of selectOptions;let i=index" [value]="option.id">
                                {{option.value}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class=" md:justify-end css-shortby">
                        <div class="md:justify-end">
                          <div class="flex justify-center item-center h-10 text-grey mt-4">
                            <div
                              class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                              Sort by: </div>
                            <select [(ngModel)]="paymentOrder" (change)="getPayoutDetailsByPage()"
                              class="form-select bg-transparent ml-4 mt-0 w-9/12 rounded-xs h-10 text-regular text-grey  p-2 border-none block focus:outline-none insurance_term">
                              <option *ngFor="let order of orderOptions;let i=index" [value]="order.id">{{order.value}}
                              </option>

                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end-->
                <div class="mt-5">
                  <div>
                    <table class="table w-full text-left pb-5 mb-5 " aria-describedby="payout">
                      <thead class="bg-gray-400 text-blue text-md text-regular ">
                        <tr>
                          <th class="p-4" scope="row">S.No</th>
                          <th class="p-4" scope="row">No. of Vaults created</th>
                          <th class="p-4" scope="row">Price per Vault</th>
                          <th class="p-4" scope="row">Total Vault Amount</th>
                          <th class="p-4" scope="row">Total Upcharge Amount</th>
                          <th class="p-4" scope="row">Payout Date</th>
                          <th class="p-4" scope="row">Payment Status</th>
                          <th class="p-4" scope="row">Payment Date</th>
                        </tr>
                      </thead>

                      <ng-container *ngIf="!loader">

                        <tbody *ngFor="let data of paymentData;let i = index">
                          <tr class="border-b-2 border-fuchsia-600 p-4 relative" *ngIf="paymentData.length > 0">
                            <td class="p-2 lg:p-4">


                              <p class="text-grey text-semibold  cursor-pointer text-md  text-regular">
                                {{fromNo+i}}
                              </p>


                            </td>
                            <td class="p-4">


                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{data?.vault_count}}
                              </p>

                            </td>
                            <td class="p-4">

                              <p class="text-grey text-semibold  text-sm  text-regular">
                                {{data?.price_per_vault}} </p>

                            </td>
                            <td class="p-4">

                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{data?.vault_amount}} </p>

                            </td>
                            <td class="p-4">
                              <p class="text-grey text-semibold text-sm text-xs md:text-sm  ">
                                {{data?.upcharge_amount}} </p>
                            </td>
                            <td class="p-4">
                              <p class="text-grey text-semibold text-sm text-xs md:text-sm  ">
                                {{data?.payout_date}} </p>
                            </td>
                            <td class="p-4" *ngIf="paymentType === 'history'">

                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{'Paid'}} </p>

                            </td>
                            <td class="p-4" *ngIf="paymentType === 'new'">

                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{'Pending'}} </p>

                            </td>
                            <td class="p-4">

                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{data?.updated_at}} </p>

                            </td>


                          </tr>
                        </tbody>

                      </ng-container>

                    </table>
                    <div class="flex justify-center" *ngIf="loader">
                      <div class="w-12 h-12 border-b-2 border-red rounded-full animate-spin mt-3 "></div>
                    </div>
                    <div class=" flex justify-center mt-5 " *ngIf="paymentData?.length === 0 && !loader">
                      <h4 class="text-grey text-base lg:text-md text-regular">

                        No data found
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-8">
                <nav class="flex flex-row flex-nowrap justify-between md:justify-center items-center"
                  aria-label="Pagination">
                  <a *ngIf="currentPage > 1" (click)="getPayoutDetailsByPage( currentPage > 1 ? currentPage-1 : 0 )"
                    class="flex w-7 h-7 mr-1 justify-center items-center text-black cursor-pointer "
                    title="Previous Page">
                    <span class="sr-only">Previous Page</span>
                    <img src="assets/images/dashboard/left-arrow-enable.png" alt="digital vault" />
                  </a>
                  <a *ngIf="currentPage === 1" class="flex w-7 h-7 mr-1 justify-center items-center text-black "
                    class="flex w-7 h-7 mr-1 justify-center items-center text-black " title="Previous Page">
                    <span class="sr-only">Previous Page</span>
                    <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                    <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                  </a>
                  <ng-container *ngFor="let page of paginationLinks;let i = index;">
                    <a *ngIf=" i <= lastPage && i > 0 " (click)="getPayoutDetailsByPage(i)"
                      [ngClass]="{ 'bg-darkblue' : currentPage === i , 'text-white' : currentPage === i}"
                      class=" md:flex w-7 h-7 mx-1 justify-center items-center  text-black text-center cursor-pointer "
                      title="Page {{i}}">
                      {{i}}
                    </a>
                  </ng-container>
                  <a (click)="getPayoutDetailsByPage( currentPage + 1 )" *ngIf="(currentPage) <  lastPage"
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black cursor-pointer " title="Next Page">
                    <span class="sr-only">Next Page</span>
                    <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                    <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                  </a>
                  <a *ngIf="(currentPage) ===  lastPage"
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black "
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black " title="Next Page">
                    <span class="sr-only">Next Page</span>
                    <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                    <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>



      </div>
      <!-- END:Payment History -->


    </div>

  </div>
</div>
