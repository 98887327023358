import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-consumer-company',
  templateUrl: './consumer-company.component.html',
})
/**
 * consumer company class
 */
export class ConsumerCompanyComponent implements OnInit {
  public mobile:boolean;
  public innerWidth:number;
  /**
 * constructor
 */
  constructor() {
    console.log('entered in constructor');
  }
  /**
 * loaded initially
 */
  ngOnInit(): void {
    this.onResize();
  }
  /**
   * window resize
   * @param {any} event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.innerWidth=window.innerWidth;
    this.mobile = this.innerWidth <= 767;
  }
}
