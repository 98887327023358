import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { USER_TYPES } from 'src/constants/application.const';

@Component({
  selector: 'app-client-management-cards',
  templateUrl: './client-management-cards.component.html',
  styleUrls: ['./client-management-cards.component.css']
})
export class ClientManagementCardsComponent implements OnInit {
  public professionalType: string;
  public isNewClient: boolean;
  public proID: string;
  public cardsAdded: boolean;
  public isPayVaultRegistration:boolean;

  @Output() buttonGroupEvent = new EventEmitter(null);

  constructor(private slugInterceptorService: SlugInterceptorService, private commonHelper:CommonHelper, private modalService: CommonModelService, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.professionalType = this.localStorageService.getDataByKey('role');
    this.proID = this.localStorageService.getUserData(USER_TYPES.pro).user.id;
    this.cardsAdded = this.localStorageService.getDataByKey('cardsAdded');
    this.isPayVaultRegistration = (this.localStorageService.getDataByKey('proPaymentType') === 1);
    this.checkCardsAdded();
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, true);
  }

  /**
   * Opens the modal for generating a URL link.
   */
  public onClickGenerateLink() {
    this.modalService.open('generate-url-link-modal');
  }

  /**
   * Toggles the bulk import client modal and sets the client type.
   *
   * @param {boolean} isNewClient - Indicates if the client is new.
   * @returns {void}
   */
  public toggleBulkImportClient(isNewClient: boolean): void {
    this.isNewClient = isNewClient;
    this.modalService.open('bulk-import-client-modal');
  }

  /**
   * Toggles the import client modal.
   *
   * @returns {void}
   */
  public toggleImportClient(): void {
    this.modalService.open('import-client-modal');
  }

  /**
   * Client Import Modal event listner
   *
   * @param {any} data
   */
  public modalEventListner(modalEventData: any) {
    if (Object.keys(modalEventData).length > 0) {
      this.buttonGroupEvent.emit(modalEventData)
    }
  }

  /**
  * Checks if cards are added
  * Updates the cardsAdded property based on the API response length.
  * @returns {void}
  */
  public async checkCardsAdded() {
    this.cardsAdded = await this.commonHelper.isCardsAdded();
  }
  
  /**
   * Navigates to the manage cards section within the payment settings.
   */
  public navigateToManageCards() {
    void this.slugInterceptorService.navigate(['payment-settings'], { queryParams: { menu: 3 } }, true);
  }


}
