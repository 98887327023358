// Email pattern
import { FileValidationOptionsInterface, SelectOptionsInterface, ToggleOptionsInterface } from '../app/interface/common.interface';
import { environment } from '../environments/environment';

export const PASSWORD_REGEX = '[a-zA-Z0-9!@#$%^&*]*$';
export const EMAIL_PATTERN = '^[a-zA-Z0-9_%+-.]+([.][a-zA-Z0-9_%+-])*@[a-zA-Z0-9-]+(\\.[a-zA-Z]{2,3}|\\.[a-zA-Z]{2,8}\.[a-zA-Z]{2,3})$';
export const USER_NAME_WITH_SPECIAL_CHARACTERS_PATTERN = '^[a-zA-Z0-9 !@#\$%\^\&*\)\(+=._-]+$';
export const ALPHABET_ONLY_PATTERN = '^[a-zA-Z]([a-zA-Z ])*$';
/**
 \u0020-\u007E: Basic Latin (letters, digits, and basic punctuation)<br>
 \u0091-\u0094: open/close apostrophe & quotes<br>
 \u00A0-\u00FF: Latin-1 Supplement (extended Latin characters, including accented ones)<br>
 \u0100-\u017F: Latin Extended-A<br>
 \u0180-\u024F: Latin Extended-B<br>
 \u2032-\u2037: PRIME - REVERSED TRIPLE PRIME<br>
 \u02B9, \u02BA: MODIFIER LETTER Prime, MODIFIER LETTER Double Prime<br>
 \u1E00-\u1EFF: Latin Extended Additional<br>
 \u2018-\u2019: SINGLE QUOTATION MARKS<br>
 \u201C-\u201D: DOUBLE QUOTATION MARKS<br>
 */
export const NAME_PATTERN = `^[\u0020-\u002E\u0030-\u003B\u003D\u0040-\u007E\u0091-\u0094\u00A0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF\u2032-\u2037\u02B9\u02BA\u2018-\u2019\u201C-\u201D]+$`;
export const ALPHABET_WITH_SPECIAL_CHARACTERS_PATTERN = '^[a-zA-Z !@#\$%\^\&*\')\(+=.,_-]+$';
export const ALPHA_NUMERIC_PATTERN = '^[a-zA-Z0-9][a-zA-Z0-9 ]*';
export const ALPHA_NUMERIC_WITH_HYPHEN_PATTERN = '^[a-zA-Z0-9][a-zA-Z0-9- ]+';
export const ALPHA_NUMERIC_WITH_HYPHEN_PATTERN_AND_COMMA = '^[a-zA-Z0-9][a-zA-Z0-9-, ]+';
export const ALPHA_NUMERIC_WITH_COMMA_SINGLEQUOTE_SPACE_PATTERN = '^[a-zA-Z0-9 \',]+$';
export const ALPHA_NUMERIC_WITH_HYPHEN_SINGLEQUOTE_SPACE_PATTERN = '^[a-zA-Z0-9 \'-]+$';
export const ALPHA_NUMERIC_WITH_HYPHEN_PATTERN_NEW = '^[a-zA-Z0-9-]+$';
export const ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN = '^[a-zA-Z0-9 `!@#\$%\^\&*\'\)\(+=.,_-]+$';
export const ALPHA_NUMERIC_PATTERN_WITHOUT_SPACE = '^[a-zA-Z0-9-]([a-zA-Z0-9-])*$';
export const EIN_NUMBER_PATTERN = '^[0-9]{2}-[0-9]{7}$';
export const APARTMENT_NUMBER_PATTERN = '^[a-zA-Z0-9.,\\- ]*$'; // Allows Alphanumeric, dot, comma, hyphen and whitespace
export const TEXT_BOX_PATTERN = '^[a-zA-Z0-9]+([ a-zA-Z0-9,\\-])*[^ ]$';
export const ORGANIZATION_PATTERN = /^[ A-Za-z0-9@&.'()-]*$/;
export const TEXT_BOX_PATTERN_WITH_LATIN_UNICODE = '^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒß]+([ a-zA-Z0-9,\\-])*[^ ]$';
export const TEXT_BOX_WITH_SPECIAL_CHARACTERS_PATTERN = '^[a-zA-Z0-9]([a-zA-Z0-9 ,._!\'\-])*$';
export const TRUST_NAME_PATTERN = '^[a-zA-Z \'@&*-.,:;()]+$';
export const CHARITY_NAME_PATTERN = '^[a-zA-Z0-9 \'@&*-.,:;()]+$';
// export const LOAN_NAME_PATTERN = '^[a-zA-Z0-9 `!@#;:/\$%\^\&*\'\)\(+=.,_-]+$';
export const LOAN_NAME_PATTERN = '^[a-zA-Z0-9 @&\',-]*$';
export const VEHICLE_MODEL_PATTERN = '^[a-zA-Z0-9\'@ &*,;\'-]+';
export const WEBSITE_PATTERN = '^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z+\.[a-z]+(\/[a-zA-Z0-9#]+\/+-?)*$';
export const WEBSITE_PATTERN_NEW = '^[a-zA-Z0-9-:/.#?=%_]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9-/.#?=%_]*)$';
export const ZIPCODE_PATTERN = /^[a-zA-Z\d\-\s]+$/;
export const SECURITY_ANSWER_PATTERN = /^(?!\s)(?!.*\s$).+/;
export const COMPANY_NAME_PATTERN = '^[a-zA-Z0-9 @\'.,-]*$';

// Subscription
export const ACCOUNT_OR_MEMBER_NUMBER_PATTERN = '^[a-zA-Z0-9 `!@#\$%\^\&*\'\)\(+=._-]+$';

// Donation
export const ORGANIZATION_NAME_PATTERN = '^[a-zA-Z0-9 \'@&*.,:;()-]+$';

export const NUMBERS_ONLY_PATTERN = '^[0-9 ]*$';
export const INTEGERS_ONLY_PATTERN = '^-?[0-9]*$';
export const NUMBERS_ONLY_PATTERN_CARD = '^[0-9 ]*$';
export const NUMBERS_VALID_DOLLARS = '^([0-9,.]+(\.[0-9]+)?)$';
// export const NUMBERS_VALID_DOLLARS = '^[0-9]{1,13}(?:,[0-9]{3})*(?:\.[0-9]+)?$';
export const PHONE_PATTERN = '^[0-9]{9,13}$';
// export const PHONE_PATTERN = '^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$';
export const STATE_CLEAR = 'STATE_CLEAR';
export const CITY_CLEAR = 'CITY_CLEAR';
export const SSN_PATTERN = new RegExp('^(?!0{3})(?!6{3})[0-8]\\d{2}-(?!0{2})\\d{2}-(?!0{4})\\d{4}$', 'i');
export const SSN_MASK = '000-00-0000';
export const PHONE_MASK = '0000000000000';
export const CREDIT_CARD_MASK = '0000 0000 0000 0000';
export const EIN_MASK = '00-0000000';
export const YEAR_PATTERN = '(?:(?:18|19|20)[0-9]{2})';
export const YEAR_MASK = '0000';
export const PERCENTAGE_MASK = '0*';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const INVALID_REQUEST_ID = 'INVALID_REQUEST_ID';
// modal status
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';

// list status
export const LIST_NEW = 'N';
export const LIST_UPDATE = 'U';
export const LIST_DELETE = 'D';

export const PEOPLE_SALUTATIONS = [
  { value: '1', displayText: 'Mr.' },
  { value: '2', displayText: 'Mrs.' },
  { value: '3', displayText: 'Ms.' },
];

export const PEOPLE_RELATION_STATUS = {
  partner: 1,
  children: 2,
};

export const PAYMENT_STATUS = {
  notPaid: 0,
  paid: 1,
  onRefundPeriod: 2,
  onDeletionPeriod: 3,
  pending: 4,
  failed: 5
};

export const TRANSFER_AMOUNT_STATUS = {
  notPaid: 0,
  paid: 1,
  pending: 2,
  failed: 3,
  refund: 4
}

export const PARTNER_RELATIONSHIP_STATUS = [
  { value: '1', displayText: 'Single (never married)' },
  { value: '7', displayText: 'Civil Partnership' },
  { value: '2', displayText: 'Divorced' },
  { value: '5', displayText: 'Domestic Partnership' },
  { value: '3', displayText: 'Married' },
  { value: '6', displayText: 'Separated' },
  { value: '4', displayText: 'Widowed' },
];
export const CHILD_STATUS = [
  { value: '1', displayText: 'Biological' },
  { value: '2', displayText: 'Adopted' },
  { value: '3', displayText: 'Stepchild' },
];
export const TOGGLE_OPTIONS_YES_NO: Array<ToggleOptionsInterface> = [
  { value: 1, displayText: 'Yes' },
  { value: 0, displayText: 'No' },
];
export const SELECT_OPTIONS_YES_NO: Array<SelectOptionsInterface> = [
  { value: '1', displayText: 'Yes' },
  { value: '0', displayText: 'No' },
];
export const PAYMENT_MODE = [
  { id: '0', name: 'Pay Vault Registration' },
  { id: '1', name: 'Pass to Consumer' },
];
export const DROPDOWN_YEAR = [
  { value: '1', displayText: '2021' },
  { value: '2', displayText: '2020' },
  { value: '3', displayText: '2019' },
  { value: '4', displayText: '2018' },
  { value: '5', displayText: '2017' },
];
export const DONATION_TYPES = [
  { value: 1, displayText: 'Ongoing' },
  { value: 2, displayText: 'One time' },
];
export const INFORMATION_TYPE = [
  { value: '1', displayText: 'Via Text Message' },
  { value: '2', displayText: 'Via Email' },
];
export const VERIFICATION_APP = [
  { value: '1', displayText: 'Using the Web App' },
  { value: '2', displayText: 'Using the Mobile App' },
];
export const VERIFICATION_STATUS = {
  notInitiated: 0,
  verified: 1,
  pending: 2,
  rejected: 3,
};
export const LOCAL_STORAGE_UPDATE_STATUS = {
  S: 'S',
  O: 'O',
};
// router stage mapper
export const ROUTER_STAGE_MAPPER = {
  1: {
    1: '/about-yourself',
    2: '/about-yourself/location-details',
    3: '/about-yourself/contact-details',
    4: '/about-yourself/partner',
    5: '/about-yourself/partner',
    6: '/about-yourself/children',
    7: '/about-yourself/children',
  },
  2: {
    8: '/executor-beneficiary/manage-executors',
    9: '/executor-beneficiary/manage-beneficiaries',
    10: '/advisors/manage-advisors', // optional,
  },
  3: {
    11: '/assets/financial-assets',
    12: '/assets/retirement-assets',
  },
  4: {
    13: '/liabilities',
  },
  5: {
    14: '/insurance-details',
  },
  6: {
    15: '/property',
  },
  7: {
    16: '/vehicle',
  },
  8: {
    17: '/business-details',
  },
  9: {
    18: '/valuable-details',
  },
  10: {
    19: '/digital-files',
  },
  11: {
    20: '/will-files',
  },
};
/**
 * Payment refund locked pages list
 */
export const REFUND_LOCK_PAGES = ['advisor-info', 'manage-advisors', 'assets', 'financial-assets', 'retirement-assets', 'account-card-view', 'liabilities', 'insurance-details', 'property', 'vehicle', 'business-details', 'valuable-details', 'social-media-list', 'subscriptions-list', 'donation-list', 'prescription-list', 'government-id-list', 'digital-files', 'will-files', 'overview', 'advisors', 'people'];
/**
 * Router white listing
 */
export const ROUTER_WHITE_LIST = ['/executor-beneficiary', '/executor-beneficiary/info'];

// Stage unlocking based on request stage .left operand is request stage and right operand is section
export const STAGE_UNLOCK_CONST = {
  // 1: 1,
  // 7: 2,
  // 9: 3,
  // 11: 4,
  // 12: 5,
  // 13: 6,
  // 14: 7,
  // 15: 8,
  // 16: 9,
  // 17: 10,
  // 18: 11,
  1: 1,
  7: 2,
  9: 3,
  // 10: 3,
  // 10: 4,
  // 10: 5,
  // 10: 6,
  // 10: 7,
  // 10: 8,
  // 10: 9,
  // 10: 10,
  // 10: 11,
  // 20: 12,
  // 20: 13,
  // 20: 14,
  // 20: 15,
  // 20: 16,
};

/**
 * Grouping file based on their types
 */
export const FILE_GROUPING = {
  'V': ['mp3', 'mp4', 'avi', 'mov', 'flv', 'mkv'],
  'D': ['doc', 'docx', 'txt', 'pdf'],
  'I': ['jpeg', 'jpg', 'png'],
  'A': ['mp3', 'wav', 'wave', 'x-wav'],
};

/**
 * labels for trust and charity
 */
export const BENEFICIARY_LABELS = {
  0: ['Trust Name', 'Trustee', 'Trustee Phone Number'],
  1: ['Charity Name', 'Contact Person', 'Phone Number'],
};

/**
 * dlb status handling text
 * <br>
 * STATUS 0 = departed not verified (initial) <br>
 * STATUS 1 = departed verified (when verify details given) <br>
 * STATUS 2 = departed approved (If consumer not respond then marked as departed) <br>
 * STATUS 3 = claim dlb (need to work with blockchain, after that will decide)
 */
export const DLB_STATUS_TEXT = {
  0: 'Departed Not Verified',
  1: 'Departed Verified',
  2: 'Digital Vault Approved',
  3: 'Digital Vault Approved',
};

/**
 * dlb status handling button text
 * <br>
 * STATUS null = process not begun <br>
 * STATUS 0 = departed not verified (initial) <br>
 * STATUS 1 = departed verified (when verify details given) <br>
 * STATUS 2 = departed approved (If consumer not respond then marked as departed) <br>
 * STATUS 3 = claim dlb (need to work with blockchain, after that will decide)
 */
export const DLB_STATUS_BTN_TEXT = {
  null: 'Report a Death',
  0: 'Submit Proof of Death',
  1: 'Verifying Proof of Death',
  2: 'Verifying Proof of Death',
  3: 'Claim Digital Vault',
  4: 'Report a Death', // Re-Submit Death Report
  5: 'Approved', // Final status of Alternative Custodian,  do not let claim digital vault
  6: 'View Digital Vault',
};

export const DLB_STATUS_BTN_TOOLTIP = {
  null: '',
  0: '',
  1: '',
  2: '',
  3: 'Click to view and download the Digital Vault',
  4: 'Please complete the payment on behalf of the Vault holder',
};

export const DLB_CARD_STATUS = {
  SHOW: 'show-card',
  REMOVED: 'removed-card',
  CLAIMED: 'claimed-card',
};

/**
 * dlb status handling class
 * <br>
 * STATUS 0 = departed not verified (initial) <br>
 * STATUS 1 = departed verified (when verify details given) <br>
 * STATUS 2 = departed approved (If consumer not respond then marked as departed) <br>
 * STATUS 3 = claim dlb (need to work with blockchain, after that will decide)
 */
export const DLB_STATUS_CLASS = {
  0: 'text-notverified',
  1: 'text-verified',
  2: 'text-approved',
  3: 'text-approved',
};
/**
 * Beneficiary Types
 */
export const BENEFICIARY_TYPE = {
  individual: 1,
  trustee: 2,
};
/**
 * Liability types
 */
export const LIABILITY_TYPE = {
  L: 'L',
  C: 'C',
};
/*
* retirement asset types(1- adding beneficiaries(few form fields), 2- default old form)
*/
export const RETIREMENT_ASSET_TYPE = {
  NEWFORM: 1,
  OLDFORM: 2,
};
/**
 * financial assets types (crypto-1, non-crypto-0)
 */
export const FINANCIAL_ASSET_TYPE = {
  NC: 0,
  C: 1,
};
/**
 * insurance types (1 - Long term care, 2 - Life Insurance, 3 - Annuities, 4 - Others)
 */
export const INSURANCE_TYPE = {
  LT: 1,
  LI: 2,
  A: 3,
  O: 4,
};

/**
 * Event const for common subscriber service
 */
export const COMMON_SUBSCRIBER_EVENT = {
  CLEAR_REQUEST_ID: 'CLEAR_REQUEST_ID',
  DEFAULT_VALUE_SUBSCRIBER: 'DEFAULT_VALUE_SUBSCRIBER',
};
export const SELECT_VALUE = [
  { id: 5, value: 5 },
  { id: 10, value: 10 },
  { id: 50, value: 50 },
  { id: 100, value: 100 },
];

export const ORDER_VALUE = [
  { id: 'desc', value: 'Name' },
  { id: 'clients', value: 'No. of Clients' },
  { id: 'login', value: 'Last Login' },
  { id: 'Old to New', value: 'Old to New' },
  { id: 'New To Old', value: 'New To Old' },
];

export const SORT_BY_CLIENTS = [
  { id: 'desc', value: 'Name' },
  // { id: 'vault', value: 'Digital Vault Status' },
  { id: 'Old to New', value: 'Old to New' },
  { id: 'New To Old', value: 'New To Old' },
  // { id: 'advisor', value: 'PSP' },
  // { id: 'advisor_permission', value: 'PSP Permission' },
  // { id: 'bene', value: 'Beneficiaries' },
  // { id: 'exec', value: 'Executors' },
];

export const PAYMENT_TYPE = [
  { id: 'new', value: 'Pending' },
  { id: 'history', value: 'History' },

];
export const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_DATE = new Date();
export const AFTERTENDAYS = new Date(CURRENT_DATE.setDate(CURRENT_DATE.getDate() + 10));

/**
 * Notification Expire days
 */
export const INSURANCE_MSSG = 'expires on';
export const APPOINT_MESSG = 'You have received an appointment request from ';
export const CALL_MESSG = 'Client call due date expired';
export const SCHEDULE_MESSG = 'Reminder of review of documents and data Expired';

export const DATE_TODAY = new Date();

export const SCHEDULE_REMINDER = [
  { value: 'quarterly', displayText: 'Quarterly' },
  { value: 'bi-yearly', displayText: 'Bi-Yearly' },
  { value: 'yearly', displayText: 'Yearly' },
];

export const PAYMENT_TYPES = [
  {value:1, displayText: 'Pay vault Registration' },
  {value:2, displayText: 'Pass to Consumer'}
]

export const ONBOARD_TYPES = [
  {value:1, displayText: 'Send Email Invitation' },
  {value:2, displayText: 'Generate Link to Copy and Share'},
  {value:3, displayText: 'Both'}
]

export enum OnboardType {
  VIA_EMAIL = 1,
  VIA_LINK = 2,
  BOTH = 3
}

export const INVOICE_TYPES = [
  {value:0, displayText: 'Receive one consolidated email at the end of the day' },
  {value:1, displayText: 'Receive an email alert for each payment'},
]

export enum InvoiceType {
  EOD = 0, //Receive one consolidated email at the end of the day
  INSTANT = 1 //Receive an email alert for each payment
}


export const UNITED_STATES = 'united states';
export const CANADA = 'canada';
export const AUSTRALIA = 'australia';
export const UNITED_KINGDOM = 'united kingdom';
/**
 * Login credential error message
 */
export const ERROR_401 = 'Email or password is incorrect';

/**
 * chat - image handling menus
 */
export const CHAT_IMAGE_HANDLE_MENUS = [
  { menuText: 'View', event: 'view' },
  { menuText: 'Copy', event: 'copy' },
  { menuText: 'Download', event: 'download' },
];

/**
 * User-types to use as keys in LocalStorageService
 */
export const USER_TYPES = {
  user: 'user',
  pro: 'professional-user',
  org: 'organization',
  exe: 'executor-custodian',
};


export enum LoginType {
  DIGITAL_VAULT,
  CUSTODIAN,
  PROFESSIONAL
}


/**
 * Role of Executor and custodian user
 */
export const EXE_CUST_ROLES = {
  exe: 'Executor',
  cust: 'Custodian',
};
/**
 * Professional and Organization URL-Route Strings
 */
export const ROUTE_PREFIX = {
  PRO: environment.PRO_ROUTE,
  ORG: environment.ORG_ROUTE,
  EXE: environment.EXE_ROUTE,
};

export const PROFESSIONAL_TERMS: { [key: string]: { agent: string, manager: string } } = {
  'Law Firm': { agent: 'Attorney', manager: 'Manager' },
  'Wealth Management': { agent: 'Advisor', manager: 'Project Manager' },
  'Funeral Home': { agent: 'Sales Agent', manager: 'Funeral Director' },
  'Attorney': { agent: '', manager: '' },
  'Estate Planners': { agent: '', manager: '' },
  'Organization': { agent: '', manager: '' },
};

export const PROFESSIONAL_ROLE: { agent: string, manager: string, professional: string } = {
  agent: 'Advisor',
  manager: 'Partner',
  professional: 'Professional',
};

export const PROFESSIONAL_TYPE = {
  certifiedPublicAccountant: 'certified-public-accountant',
  wealthManagement: 'wealth-management',
  humanResources: 'human-resources',
  lawFirm: 'law-firm',
  funeralHome: 'funeral-home'
}

/**
 * subscription period types
 */
export const SUBSCRIPTION_PERIOD_TYPE = [
  { value: 'days', displayText: 'Days' },
  { value: 'months', displayText: 'Months' },
  { value: 'years', displayText: 'Years' }
]

/**
 * Grouping file based on their types
 */
export const DOWNLOADING_FILE_GROUPS = {
  'D': ['application/doc', 'application/docx', 'application/txt', 'application/pdf', 'application/msword'],
  'I': ['image/jpeg', 'image/jpg', 'image/png'],
};

/**
 * Grouping file - chat
 */
export const FILE_GROUPING_CHAT = {
  'V': ['mp4', 'mov', 'ogg', 'mkv'],
  'D': ['doc', 'docx', 'txt', 'pdf'],
  'I': ['jpeg', 'jpg', 'png'],
  'A': ['octet-stream', 'mpeg', 'mpga', 'mp3', 'wav'],
};

/**
 * Grouping file - chat
 */
export const FILE_GROUPING_WITH_TYPE = {
  'V': ['video/mp4', 'video/mov', 'video/ogg', 'video/mkv'],
  'D': ['application/doc', 'application/docx', 'application/txt', 'application/pdf', 'application/msword'],
  'I': ['image/jpeg', 'image/jpg', 'image/png'],
  'A': ['application/octet-stream', 'audio/mpeg', 'audio/mpga', 'audio/mp3', 'audio/wav'],
};

/**
 * Employee status
 */
export const EMPLOYEE_STATUS = [
  { displayText: 'Active', value: 'active' },
  { displayText: 'Retired', value: 'retired' },
  { displayText: 'Deceased', value: 'deceased' },
  { displayText: 'Deactivated', value: 'deactivated' },
  { displayText: 'Reactivated', value: 'reactivated' },
];
/**
 * Employee status
 */
export const RECORD_STATUS = [
  { displayText: 'Active', value: 'active' },
  { displayText: 'Completed', value: 'complete' },
  { displayText: 'Incomplete', value: 'incomplete' },
];

export const SECTIONS = {
  professionalServiceProviders: 'professional_service_providers',
  accountsAssets: 'accounts_assets',
  retiremetAssets: 'retirement_assets',
  liabilities: 'liabilities',
  insurance: 'insurance',
  realEstate: 'real_estate',
  vehicles: 'vehicles',
  business: 'business',
  valuables: 'valuables',
  socialMedia: 'social_media',
  subscriptions: 'subscriptions',
  donations: 'donations',
  prescriptions: 'prescriptions',
  governmentIDs: 'gov_ids',
  digitalFiles: 'digital_files',
  willFiles: 'will_files',
};


/**
 * For use with {@link PersonalDetailsService.getSectionCompletionStatus getSectionCompletionStatus} to reference the
 * status values.
 * <br>Example:<br>`getSectionCompletionStatus().insurance.status == SECTION_STATUS.COMPLETE`
 */
export enum SectionStatus {
  NOT_STARTED = 0,
  INCOMPLETE = 1, // Partially Complete or has been opened but not marked as completed.
  COMPLETE = 2,
}


export const messageDataDecedent = {
  title: 'Decedent Check',
  message: [
    'Your organization has not opted into the Decedent Check.',
    'For additional information please contact us at +1 866 272 3003', '',
  ],
  buttons: [{
    content: 'Understood',
    emitValue: true,
  }],
};

export const TOASTR_TIME_OUT = 10000;

/**
 * notify  button details
 * <br>
 * STATUS 0 = Notify All Companies(NotifyNOW platform) <br>
 * STATUS 1 = departed verified (when verify details given) <br>
 * STATUS 2 = departed approved (If consumer not respond then marked as departed) <br>
 * STATUS 3 = claim dlb (need to work with blockchain, after that will decide)
 */
export const NOTIFY_ALL_BUTTON_TEXT = {
  0: 'Notify Companies',
  1: 'Verified',
  2: 'Retry',
};

/**
 * professional role constants
 */
export const PROFESSIONAL_ROLE_CONSTANTS = {
  ADVISOR_ROLE: 'Advisor',
};

// Resend Email Constant
export const RESEND_EMAIL = 'Resend Email';

export const DVC_DESCRIPTION_TEXT = 'Manage the access your Digital Vault Custodian will have to your Digital Vault information from their dashboard. You can specify whether they are able to view information in each section. You can come back and modify these access controls at any time.';

export const fileValidationOptions: FileValidationOptionsInterface = {
  size: 50000,
  fileFormat: ['jpeg', 'jpg', 'png', 'doc', 'docx', 'pdf', 'msword', 'vnd.openxmlformats-officedocument.wordprocessingml.document'],
};

// Transfer vault service amount required
export const DV_TANSFER_CONTENT = {
  noAccessWhileAlive: `<div class="items-baseline m-5 text-base tracking-wide">
  <span class="mb-1">
  This one-time fee enables your Digital Vault Custodian and/or Executor access to the information stored in your LegacyNOW Digital Vault, to initiate the estate resolution process efficiently, after your passing. <br>
  </span>
  <h3 class="mt-3">
    Digital Vault Transfer features:
  </h3>
  <span class="m-2 text-semibold">
    <li class="ml-4">
    Access to the Digital Vault and the information needed to assist with finalizing the estate.
    </li>
    <li class="ml-4">
    Ability to download data and documents from the Digital Vault in a well-organized PDF format.
    </li>
    <li class="ml-4">
    Procurement of critical data points—such as account numbers, logins and passwords—required to notify companies of your passing.
    </li>
    <li class="ml-4">
    Downloadable Service Provider Contact Sheet—a comprehensive list of service providers and their contact information.
    </li>
  </span>
  <p class="mt-2">
  You can let your estate handle the activation fee after you pass, or you can choose to pay it now to ensure your Digital Vault Custodian and/or Executor have access immediately upon your passing
  </p>
  <p class="mt-4">
  To ensure your data remains secure, access to your Digital Vault will only be provided after your Digital Vault Custodian and/or Executor’s ID has been verified and your death has been confirmed. A valid government issued photo identification is required.
  </p>
</div>`,
  accessGrantedWhileAlive: `<div class="items-baseline m-5 text-base tracking-wide">
  <span class="mb-1">
  This one-time fee enables your Digital Vault Custodian and/or Executor access to the information stored in your LegacyNOW Digital Vault.<br>
  </span>
  <h3 class="mt-3">
    Digital Vault Transfer features:
  </h3>
  <span class="m-2 text-semibold">
    <li class="ml-4">
    Real-time collaboration enabled though view access of your Digital Vault, governed by permissions you maintain.
    </li>
    <li class="ml-4">
    Access to a downloadable Service Provider Contact Sheet—a comprehensive list of service providers and their contact information.
    </li>
    <li class="ml-4">
    Ability to download data and documents from the Digital Vault in a well-organized PDF format.
    </li>
    <li class="ml-4">
    Procurement of critical data points—such as assets, liabilities, account numbers, logins and passwords—required to finalize the estate.
    </li>
  </span>
  <p class="mt-2">
  To ensure the data remains secure, access to your Digital Vault will only be provided after your Digital Vault Custodian and/or Executor’s identity has been verified. A valid government issued photo identification is required.
  </p>
</div>`,
  afterPODProvied: `<div class="items-baseline m-5 text-base tracking-wide">
  <span class="mb-1">
  This one-time fee enables you, as Executor, to access to the information stored in <span class="text-gray-800 italic">[VaultHolderName]’s</span> LegacyNOW Digital Vault. <br>
  </span>
  <h3 class="mt-3">
    Digital Vault Transfer features:
  </h3>
  <span class="m-2 text-semibold">
    <li class="ml-4">
    Access to <span class="text-gray-800 italic">[VaultHolderName]’s</span> Digital Vault containing the information needed to initiate probate for their estate.
    </li>
    <li class="ml-4">
    Ability to download data and documents from the Digital Vault in a well-organized PDF format.
    </li>
    <li class="ml-4">
    Procurement of critical data points—such as assets, liabilities, account numbers, logins and passwords—required to finalize the estate.
    </li>
    <li class="ml-4">
    Downloadable Service Provider Contact Sheet—a comprehensive list of service providers and their contact information.
    </li>
  </span>
  <p class="mt-4">
  To ensure the data remains secure, access to <span class="text-gray-800 italic">[VaultHolderName]’s</span> Digital Vault will only be provided after your identity has been verified. A valid government issued photo identification is required.
  </p>
</div>`
};

export const DESIGNATION_TYPE = [
  { value: '2', displayText: 'Pass on Death' },
  { value: '1', displayText: 'Transfer On Death' },
];

export enum ConsumerPaymentMethod {
  // Payment is made by a professional
  PAID_BY_PROFESSIONAL = 1,

  // Payment is made directly by the co-brand consumer.
  PASS_TO_CONSUMER = 2,
}



