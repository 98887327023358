import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';

@Component({
  selector: 'app-digital-vault-agreement-pro',
  templateUrl: './digital-vault-agreement-pro.component.html',

})
/**
   * Digital vault pro
   */
export class DigitalVaultAgreementProComponent {

  public agentTerm: string;
  public proType: string;

  /**
  * @constructor
  */
  constructor(
    private commonHelper: CommonHelper,
    private route: ActivatedRoute,
    private slugInterceptorService: SlugInterceptorService,
  ) { 
    this.proType = this.route.snapshot.params['pro-type'];
  }

  /**
  * called initially
  */
  ngOnInit(): void {
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
  }

  /**
   * change route
   * @param{string}url
    */
  public changeRoute(url:string) {
    void this.slugInterceptorService.navigate([url], null, true);
  }
}
