import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { PeopleService } from '../../services/people.service';
import { PersonalDetailsService } from '../../services/personal-details.service';
import { ProfessionalDashboardService } from './professional-dashboard.service';


@Injectable({
  providedIn: 'root',
})
/**
 * Professional Permission Service
 */
export class ProfessionalPermissionService {
  /* These do not appear to be used anywhere
  public permissionList:Array<any>;
  public permission:Array<any>;
  public editAccessList:Array<any>;
   */

  // The key is the name of the permission, and this contains routes for the appropriate overview and info section.
  public permissionList: { [key: string]: { overview: string[], section: string[] } } = {
    'Business': { overview: ['/overview', 'property-overview'], section: ['/business-details'] },
    'Digital Files': { overview: ['/overview', 'will-digital-file-overview'], section: ['/digital-files'] },
    'Donations': { overview: ['/overview', 'property-overview'], section: ['/donation-list'] },
    'Financial Assets': { overview: ['/overview', 'finance-overview'], section: ['/assets', 'financial-assets'] },
    'Government Id': { overview: ['/overview', 'property-overview'], section: ['/government-id-list'] },
    'Insurance': { overview: ['/overview', 'insurance-overview'], section: ['/insurance-details'] },
    'Liabilities': { overview: ['/overview', 'finance-overview'], section: ['/liabilities'] },
    'Prescriptions': { overview: ['/overview', 'property-overview'], section: ['/prescription-list'] },
    'Retirement Assets': { overview: ['/overview', 'finance-overview'], section: ['/assets', 'retirement-assets'] },
    'Real Estate': { overview: ['/overview', 'property-overview'], section: ['/property'] },
    'Social Media': { overview: ['/overview', 'property-overview'], section: ['/social-media-list'] },
    'Subscriptions': { overview: ['/overview', 'property-overview'], section: ['/subscriptions-list'] },
    'Valuables': { overview: ['/overview', 'property-overview'], section: ['/valuable-details'] },
    'Vehicles': { overview: ['/overview', 'property-overview'], section: ['/vehicle'] },
  };


  /**
   * constructor
   */
  constructor(private professionalDashboardService: ProfessionalDashboardService,
    private localStorageService: LocalStorageService,
    private personalDetailsService: PersonalDetailsService,
    private router: Router,
    private peopleService: PeopleService,
    private slugInterceptorService: SlugInterceptorService,
  ) {
  }


  /**
   * Convenience function to get each permissionList key (Permission Names) in a string array.
   */
  get getPermissionNames(): string[] {
    return Object.keys(this.permissionList);
  }


  /**
   * Get Access Control List (ACL).
   * @param professionalId
   * @param userRequestId Client/Consumer request ID
   * @param isProfessional Get boolean indicating whether not professional
   * @param permissions_request An array of permission names to return. If left empty, all names will be used.
   * @return An object containing permissions for each requested permission. If the permission information does not
   * exist, then it defaults to false in returned object.
   */
  public getAccessControl(professionalId: string, userRequestId: string, permissions_request: string[] = this.getPermissionNames, isProfessional?: boolean):
    Observable<{ [key: string]: { name: string, view: boolean, add: boolean, edit: boolean, delete: boolean } }> {
    return new Observable((observer) => {
      try {
        // Permissions Object to be returned
        const permissions_return: { [key: string]: { name: string, view: boolean, add: boolean, edit: boolean, delete: boolean } } = {};
        let viewAdvisorPermission;
        let userType = this.localStorageService.getDataByKey('role')
        if(!isProfessional && (userType == "Custodian" || userType == 'Executor')) {
          viewAdvisorPermission = this.peopleService.viewAdvisorPermissions(professionalId, userRequestId);
        } else {
          viewAdvisorPermission = this.peopleService.viewAdvisorPermissionsRequest(professionalId, userRequestId);
        }
        // Get Permissions
        viewAdvisorPermission.subscribe((response) => {
            const permissions: {} = response.status ? response.data : null;
            // Find requested permissions in viewAdvisorPermissionsRequest response
            permissions_request.forEach((e_request) => {
              let b_found = false;
              permissions?.['permission']?.forEach((e_permission) => {
                if (e_permission['permission_name'] === e_request) {
                  b_found = true;
                  permissions_return[e_request] = {
                    name: e_permission['permission_name'],
                    view: e_permission['view'] == 1,
                    add: e_permission['add'] == 1,
                    edit: e_permission['edit'] == 1,
                    delete: e_permission['delete'] == 1,
                  };
                }
              });

              // Handle permission data not found. Default to no permissions.
              if (!b_found) {
                permissions_return[e_request] = {
                  name: e_request,
                  view: false,
                  add: false,
                  edit: false,
                  delete: false,
                };
              }
            });

            observer.next(permissions_return);
          }, (e) => observer.error(e),
          () => {
            observer.complete();
          });
      } catch (e) {
        observer.error(e);
      }

      return {
        unsubscribe() {
        },
      };
    });
  }


  /**
   * get section based on access
   * @param{any}section
   * @param{string}requestId
   */
  public getSection(section: string, requestId: string) {
    void this.slugInterceptorService.navigate([this.permissionList[section]['section'].join('/') + requestId]);
  }
}
