<p *ngFor="let data of userPeopleData; let i=index"
  [tooltip]="data?.name"
  class="p-2 px-4 !pr-7 inline-block chip-active text-semibold mr-2 mb-2 text-regular relative"
  (click)="''">
  {{data?.name}} 
 <!-- Delete Icon -->
 <ng-container *ngIf="data.name" class="ml-2">
    <fa-icon
      class="filter-darkblue w-4 h-4 cursor-pointer my-auto shadow-lg absolute right-2.5 top-2.5"
      [icon]="removeIcon"
      (click)="clickDelete(data.id)">
    </fa-icon>
  </ng-container>
</p>
 