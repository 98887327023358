<app-common-modal id="access-control-modal" class="app-common-modal">
  <div class="w-full relative rounded-md">
    <div class="mb-2 title-modal">
      <div class="flex flex-row items-baseline">
        <div>
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Access Control
          </h4>
          <p class="text-grey text-regular text-sm">{{descriptionMessage}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-transparent rounded mt-2 modal-content">
    <app-access-control [advisorData]="data"></app-access-control>
  </div>


  <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
    <app-button-loader class="w-full sm:w-auto" [buttonText]="'Cancel'" [valid]="true" [buttonType]="'button'" [outlineOnly]="true"
                       [buttonTooltip]="'Your changes will not be saved!'"
                       (click)="closeModal()">
    </app-button-loader>

    <app-button-loader class="w-full sm:w-auto" [loader]="isLoading" [buttonText]="'Save'" (click)="updateAccess()" [valid]="true"
                       [buttonType]="'submit'"></app-button-loader>
  </div>
  <!-- </div> -->
</app-common-modal>
