import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { FileValidationOptionsInterface, SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel, APIStatusCodes } from 'src/app/interface/response.interface';
import { AgentsService } from 'src/app/professional-dashboard/services/agents.service';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { CommonFormControlService } from 'src/app/services/common-form-control.service';
import { CommonService } from 'src/app/services/common.service';
import { EMAIL_PATTERN, NAME_PATTERN, PHONE_PATTERN, UNITED_KINGDOM, USER_TYPES } from 'src/constants/application.const';
import { CLEAR_SELECTED_FILE } from 'src/constants/digitalFile.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../../../helper/validation.helper';
import { LocalStorageService } from '../../../../../services/local-storage.service';


@Component({
  selector: 'app-edit-professional-partner',
  templateUrl: './edit-professional-partner.component.html',
  styleUrls: ['./edit-professional-partner.component.css'],
})

/**
 * edit  Component
 */
export class EditProfessionalPartnerComponent implements OnInit {
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;

  public proRoute: string = environment.PRO_ROUTE;
  public agentForm: FormGroup;
  public countryCode: any;
  public preDefinedLoader: boolean;
  public editData: any;
  public agentID: string;
  public loginLoader: boolean;
  public asterisk: any;
  public activateFileUploadLoader: boolean;
  public filePercentage: number;
  public currentFile: any;
  public fileValidationOptions: FileValidationOptionsInterface;
  public imageFormatFiltering: Array<string>;
  public downloadLink: string;
  public downloadFileName: string;
  public proof: any;
  public profilePicture: any;
  public hideDeleteIcon: boolean;
  public deleteIndex: number;
  public willFilesArray: Array<any>;
  public advisorTypes: Array<any>;
  private editUserID: string;
  private userType: string;
  public defaultCountry: number;
  public selectedCountry: number;
  public agentTerm: string;
  public selectedCountryName: string = '';
  public unitedKingdom: string = UNITED_KINGDOM;
  public roleType: string;
  public roleTypeId: string;
  public isProofImage: boolean;
  public isProfilePictureImage: boolean;
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;


  /**
   * constructor
   */
  constructor(private form: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private agentService: AgentsService,
    private toastr: ToastrService,
    private commonHelper: CommonHelper,
    private commonService: CommonService,
    private localStorageService: LocalStorageService,
    private addressHelper: AddressHelper,
    private slugInterceptorService: SlugInterceptorService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
    private commonFormControlService: CommonFormControlService,
    private professionalUserService: ProfessionalUserService,
    private validation: ValidationHelper
  ) {
  }


  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.selectedCountry = environment.APP_DOMAIN;
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    this.loginLoader = false;
    this.preDefinedLoader = false;
    this.editUserID = this.localStorageService.getDataByKey('edit-id');
    this.asterisk = faAsterisk;
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    // If search element not loaded initially
    setTimeout(() => {
      this.initMap(this.defaultCountry);
    }, 0);
    this.fileValidationOptions = {
      size: 100000,
      fileFormat: [
        'jpeg',
        'jpg',
        'png',
        'doc',
        'docx',
        'txt',
        'pdf',
        'mp3',
        'mp4',
        'avi',
        'mov',
        'flv',
        'mkv',
      ],
    };
    this.imageFormatFiltering = ['jpeg', 'jpg', 'png'];
    this.commonService.fileHanlderObservableEvents({
      type: CLEAR_SELECTED_FILE,
    });

    // add agent form
    this.agentForm = this.form.group({
      first_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim] }],
      middle_name: ['', { updateOn: 'blur', validators: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim] }],
      last_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim] }],
      manager_term: [
        '',
        {
          updateOn: 'blur', validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
          ],
        },
      ],
      phone: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(PHONE_PATTERN)] }],
      email: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(EMAIL_PATTERN)] }],
      street_address: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(255), this.validation.fn.trim] }],
      city: ['', { updateOn: 'blur', validators: [Validators.required, this.validation.noWhitespaceValidator()] }],
      country: ['', { updateOn: 'blur', validators: [Validators.required] }],
      state: [''],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(10)] }],
      professional_type: [''],
      type: ['manager'],
    });
    this.validation.setValidationErrors({
      city: { whitSpaceValue: 'Please enter valid character'}
    });

    // Set validator if domain country is not UK
    this.isEnableState();
    if (this.agentForm) {
      this.getAdvisorTypes();
      this.getAgentData();
    }

    // types of advisors
    this.roleType = this.localStorageService.getUserData(USER_TYPES.pro).user.professional_type_name;
  }


  /**
   * patch data
   */
  public getAgentData() {
    this.agentService.viewAgent(this.editUserID).subscribe((response: APIResponseModel) => {
        this.editData = response?.data;
        const tempData = { ...response.data };


        delete response.data.country;
        delete response.data.city;
        delete response.data.state;
        delete response.data.phone;
        this.agentForm.patchValue({
          ...response.data,
          country: this.defaultCountry,
          professional_type: tempData.professional_type?.id,
          role_id: tempData?.role_id,

        });

        if (tempData?.state?.id) {
          this.agentForm.patchValue({
            city: tempData?.city?.id,
            country: tempData?.country?.id,
            state: tempData?.state?.id,
          });
        }
        this.removePacContainer();
        this.initMap(tempData?.country?.id);
        let tempPhoneNumber;
        if (tempData?.phone) {
          tempPhoneNumber = tempData?.phone;
          this.countryCode = { code: tempData?.flag_code, dialCode: tempData?.country_code };
        }
        this.agentForm.patchValue({
          phone: tempPhoneNumber,
        });
        this.cd.detectChanges();
      },
      (exception) => {
        this.preDefinedLoader = false;
        if (exception.error.statusCode === APIStatusCodes.UNPROCESSABLE_CONTENT) {
          void this.slugInterceptorService.navigate(['invalid-request']);
        } else if ((exception.error.statusCode === APIStatusCodes.INTERNAL_SERVER_ERROR)) {
          void this.slugInterceptorService.navigate(['/unauthorized']);
        } else {
          this.commonHelper.httpResponseHandler(exception?.error);
        }
      });
  }


  /**
   * get advisor types
   */
  public getAdvisorTypes() {
    this.agentService.getAdvisorTypes(this.userType)?.subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.advisorTypes = this.commonHelper.convertToOptionsFormat(response?.data, 'id', 'name');
      }
    });
  }


  /**
   * form object getter for validation and showing errors in html
   */
  public get formGet() {
    return this.agentForm?.controls;
  }

  /**
   * Detect change on phone number component
   *
   * @param {any} inputObject
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }


  /**
   * submit form
   */
  public onSubmit() {
    this.commonFormControlService.markFormGroupTouched(this.agentForm);
    if (this.agentForm.invalid) return;
    const payLoad = {
      ...this.agentForm.value,
      id: this.editUserID,
      country_code: this.countryCode.dialCode,
      flag_code: this.countryCode.code,
      is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
    };
    payLoad.state = this.selectedCountryName == this.unitedKingdom ? null : payLoad.state;

    this.loginLoader = true;
    this.commonService.getCityDetail(payLoad.country, payLoad.state, payLoad.city).subscribe((cResponse: APIResponseModel) => {
        if (cResponse?.status) {
          payLoad.city = cResponse?.data?.id;
          const formData = new FormData();
          // appending every value to form object
          Object.keys(payLoad).forEach((key) => {
            if (!payLoad[key]) return;
            formData.append(key, payLoad[key]);
          });
          this.agentService.editAgent(formData).subscribe((response) => {
              this.toastr.success(response.message);
              this.agentForm.reset();
              void this.slugInterceptorService.navigate(['managers'], null, true);
            },
            (exception) => {
              this.loginLoader = false;

              this.commonHelper.httpResponseHandler(exception?.error);
            });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error)
      , () => {
      });
  }


  /**
   * Initialize google map api by country
   * @param {any} countryId
   */
  public initMap(countryId: any): void {
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: (r) => {
        this.ngZone.run(() => {
          this.agentForm.patchValue({ ...r, street_address: r.address });
        });
      },
      error: (e) => console.error(e),
    });
  }


  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.agentForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }


  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    //
    if (!selectedCountry || this.preDefinedLoader) return;
    this.agentForm.controls.state.reset();
    this.agentForm.patchValue({
      country: selectedCountry.value,
    });
    this.removePacContainer();
    this.initMap(selectedCountry.value);
  }


  /**
   * remove pac container
   *
   */
  public removePacContainer() {
    this.addressHelper.removeAutocompletePacContainer();
  }


  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.agentForm.controls.city.reset();
    this.agentForm.patchValue({
      state: selectedState.value,
    });
  }


  /**
   * listen for options changes state
   *
   * @param {string} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.agentForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.agentForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(selectedCity?.additionalDetails[0]?.postal_code_regex))]);
      zipCodeRef.updateValueAndValidity({ onlySelf: true });
    }
    // this loader is set false here because of the delay to set country state city
    this.preDefinedLoader = false;
  }


  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }


  /**
   * Remove uploaded file
   */
  public removeUploadedFile(): void {
    this.agentForm.patchValue({
      upload_proof: '',
    });
  }


  /**
   * get state
   */
  get state() {
    return this.agentForm.get('state');
  }


  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.agentForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ?
        this.state.addValidators([Validators.required]) : this.state.clearValidators();
      this.state.updateValueAndValidity();
    });
  }


  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, true);
  }


  /**
   * get advisor term
   * @return{string}
   */
  public getAdvisorTerm() {
    return this.professionalUserService.getAdvisorTerm();
  }


  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.agentForm.get('zipcode');
    const stateId = this.agentForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        if (response.data.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(zipCodeRegex))]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        } else {
          zipCodeRef.setValidators([Validators.required]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }


  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }
}
