import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import {
  ALPHABET_ONLY_PATTERN,
  CREDIT_CARD_MASK,
  LOCAL_STORAGE_UPDATE_STATUS,
  NUMBERS_ONLY_PATTERN,
  USER_TYPES,
  YEAR_MASK,
} from 'src/constants/application.const';
import { CardService } from '../../consumer-payment-options/card-details/card-details.service';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';
import { ConsumerPaymentService } from '../consumer-payment-update.service';

@Component({
  selector: 'app-consumer-update-card-details',
  templateUrl: './consumer-update-card-details.component.html',
})
/**
 * consumer update payment card details
 */
export class ConsumerUpdateCardDetailsComponent implements OnInit {
  @Input() vaultRegistrationAmount: string;
  @Input() vaultTransferAmount: string;
  @Input() total: number;
  @Input() activePackage: any;
  @Input() dlbId: string;
  @Input() paymentCompleted: string;
  @Input() subscriptionFlag: boolean;
  @Input() isTransferDigitalWallet: boolean;
  @Input() isUpdatePlan: boolean;
  @Input() transferVaultScreen: boolean;
  @Input() isPaymentCompleted: boolean;
  public requestId: string;
  public userType: string;
  public monthlyPackage: any;
  public yearlyPackage: any;
  public selectedPackage: string = 'Yearly';
  public toggleSectionBody: boolean;
  public toggleSectionBodys: boolean;
  public userData: any;
  public submitLoader: boolean;
  public progressValue: number;
  public paymentForm: FormGroup;
  public cardNumber: any;
  public expiryMonth: any;
  public expiryYear: any;
  public cardHolderName: any;
  public securityCode: any;
  public cardMask: any;
  public yearMask: any;
  public cards: any;
  public selectedCardDetails: any;
  public isLoading = false;
  public updateSubscriptionSubscription1: Subscription;
  public transferVaultScreenSubscription1: Subscription;

  /**
   * constructor
   */
  constructor(
    private router: Router,
    private subscriptionService: ConsumerSubscriptionService,
    private modalService: CommonModelService,
    private activeRouter: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private toastService: ToastrService,
    private formBuilder: FormBuilder,
    private paymentService: ConsumerPaymentService,
    private cardService: CardService,
    private slugInterceptorService: SlugInterceptorService,
  ) { }
  /**
     * ngonchange
     * @param {SimpleChanges}changes
     */
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }
  /**
   *on init
   */
  ngOnInit(): void {
    this.cardMask = CREDIT_CARD_MASK;
    this.yearMask = YEAR_MASK;
    // get user request id for executor/custodian login
    this.requestId = this.localStorageService.getDataByKey('user_request_id');
    this.userType = this.localStorageService.getDataByKey('role');
    this.userData = this.localStorageService.getUserData();
    this.isPaymentCompleted = this.userData?.user?.is_payment_complete;
    this.loadCards();
    this.paymentForm = this.formBuilder.group({
      currency: ['usd'],
      new_card: ['1'],
      card_id: [''],
      card_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(ALPHABET_ONLY_PATTERN), Validators.minLength(3)] }],
      card_number: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(16), Validators.maxLength(16)] }],
      exp_month: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(2), this.monthValidator.bind(this)] }],
      exp_year: ['', { updateOn: 'blur', validators: [Validators.required, this.yearValidator.bind(this)] }],
      cvv: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern(NUMBERS_ONLY_PATTERN)] }],
    });

    this.updateSubscriptionSubscription1 = this.paymentService.subscriptionPlanObserve1.subscribe(
      (responseString: string) => {
        if (responseString !== '') {
          this.subscriptionService
            .getSubscriptionDetailsApi()
            .subscribe((response: any) => {
              this.isUpdatePlan = true;
              this.transferVaultScreen = false;
              this.isTransferDigitalWallet = false;
              this.total = 0;
              response.data.package.forEach((element) => {
                if (responseString != element.name) {
                  this.activePackage = element;
                  this.total = parseFloat(element?.price);
                }
              });
            });
        }
      },
    );
    this.transferVaultScreenSubscription1 = this.paymentService.transferVaultScreenObserve1.subscribe((data: boolean) => {
      if (data) {
        this.subscriptionService
          .getSubscriptionDetailsApi()
          .subscribe((response: any) => {
            this.isUpdatePlan = false;
            this.transferVaultScreen = true;
            this.isTransferDigitalWallet = true;
            this.vaultTransferAmount = response.data.vault_transfer_amount;
            this.total = 0;
            this.total = parseFloat(this.vaultTransferAmount);
          });
      }
    });
  }
  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.paymentForm.controls;
  }
  /**
   * get card details
   */
  public loadCards() {
    this.cardService.getCardsApi().subscribe((response: any) => {
      this.cards = response?.data;
      this.cards.forEach((current, index) => {
        if (current.is_default == 1) {
          this.selectedCard(this.cards[index]);
        }
      });
    });
  }

  /**
   * toggle active package
   */
  public toggleActivePackage() {
    if (this.selectedPackage == 'Yearly') {
      this.selectedPackage = 'Monthly';
      this.activePackage = this.monthlyPackage;
      this.total = this.total - parseFloat(this.yearlyPackage?.price);
      this.total = this.total + parseFloat(this.monthlyPackage?.price);
    } else {
      this.selectedPackage = 'Yearly';
      this.activePackage = this.yearlyPackage;
      this.total = this.total - parseFloat(this.monthlyPackage?.price);
      this.total = this.total + parseFloat(this.yearlyPackage?.price);
    }
  }
  /**
   * toggle digital wallet
   */
  public toggleDigitalWallet() {
    if (this.isTransferDigitalWallet == true) {
      this.isTransferDigitalWallet = false;
      this.total = this.total - parseFloat(this.vaultTransferAmount);
    } else {
      this.isTransferDigitalWallet = true;
      this.total = this.total + parseFloat(this.vaultTransferAmount);
    }
  }
  /**
   * month validation
   * @param {FormControl}control
   * @return {boolean}
   */
  monthValidator(control: FormControl): { [s: string]: boolean } {
    const enteredMonth = +(control.value);
    if (enteredMonth > 12) {
      return { isNotAllowed: true };
    } else {
      return null;
    }
  }
  /**
   * year validation
   * @param {FormControl}control
   * @return {boolean}
   */
  yearValidator(control: FormControl): { [s: string]: boolean } {
    const enteredYear = +(control.value);
    if (enteredYear < new Date().getFullYear()) {
      return { isNotAllowed: true };
    } else {
      return null;
    }
  }
  /**
   * payment validation
   * @param {any} card
   */
  public paymentValidation() {
    if (this.isPaymentCompleted) {
      this.updateSubscription();
    } else {
      this.makePayment();
    }
  }

  /**
   * make payment
   */
  public makePayment() {
    if (this.selectedCardDetails) {
      const params = {
        ...this.paymentForm.value,
        new_card: '0',
        card_id: this.selectedCardDetails.id,
        card_name: this.selectedCardDetails.name,
        card_number: this.selectedCardDetails.card_last_four,
        exp_month: this.selectedCardDetails.exp_date.split('/')[0],
        exp_year: this.selectedCardDetails.exp_date.split('/')[1],
        cvv: this.selectedCardDetails.cvv,
        transfer_type: this.isTransferDigitalWallet == false ? '0' : '1',
        package_id: this.activePackage.id,

      };
      this.modalService.open('payment-process');
      this.subscriptionService.checkoutApi(params).subscribe(
        (res: any) => {
          this.commonService.payForDlb(this.userData?.request?.id).subscribe(
            (response: APIResponseModel) => {
              this.submitLoader = false;
              if (response.status) {
                this.toastService.success(response.message);
                this.paymentCompleted = response.data.is_payment_complete;
                this.localStorageService.storeData('is_payment_complete', this.paymentCompleted);

                this.localStorageService.updateUserData(USER_TYPES.user, {
                  key: 'request',
                  updateValue: response.data.is_payment_complete,
                  updateKey: 'is_payment_complete',
                  type: LOCAL_STORAGE_UPDATE_STATUS.O,
                });
                this.modalService.close('payment-process');
                void this.slugInterceptorService.navigate(['subscription-plan']);
              }
            },
            (exception) => {
              this.submitLoader = false;
              this.isLoading = false;
              this.modalService.close('payment-process');
              this.modalService.open('payment-failure');
              this.toastService.error(exception?.error?.message);
            },
          );
        },
        (excep) => {
          this.submitLoader = false;
          this.isLoading = false;
          this.modalService.close('payment-process');
          this.toastService.error(excep?.error?.message);
        },
      );
    } else {
      const params = {
        ...this.paymentForm.value,
        transfer_type: this.isTransferDigitalWallet == false ? '0' : '1',
        package_id: this.activePackage.id,
      };
      this.modalService.open('payment-process');
      this.subscriptionService.checkoutApi(params).subscribe(
        (resp: any) => {
          this.commonService.payForDlb(this.userData?.request?.id).subscribe(
            (response: APIResponseModel) => {
              this.submitLoader = false;
              if (response.status) {
                this.toastService.success(response.message);
                this.paymentCompleted = response.data.is_payment_complete;
                this.localStorageService.storeData('is_payment_complete', this.paymentCompleted);

                this.localStorageService.updateUserData(USER_TYPES.user, {
                  key: 'request',
                  updateValue: response.data.is_payment_complete,
                  updateKey: 'is_payment_complete',
                  type: LOCAL_STORAGE_UPDATE_STATUS.O,
                });
                this.modalService.close('payment-process');
                void this.slugInterceptorService.navigate(['subscription-plan']);
              }
            },
            (exception) => {
              this.submitLoader = false;
              this.isLoading = false;
              this.modalService.close('payment-process');
              this.modalService.open('payment-failure');
              this.toastService.error(exception?.error?.message);
            },
          );
        },
        (excep) => {
          this.submitLoader = false;
          this.isLoading = false;
          this.modalService.close('payment-process');
          this.toastService.error(excep?.error?.message);
        },
      );
    }
  }

  /**
   * disable card screen
   */
  public disableCardScreen() {
    void this.slugInterceptorService.navigate(['subscription-plan']);
  }
  /**
   * update subscription
   */
  public updateSubscription() {
    if (this.selectedCardDetails) {
      const params = {
        ...this.paymentForm.value,
        new_card: '0',
        card_id: this.selectedCardDetails.id,
        card_name: this.selectedCardDetails.name,
        card_number: this.selectedCardDetails.card_last_four,
        exp_month: this.selectedCardDetails.exp_date.split('/')[0],
        exp_year: this.selectedCardDetails.exp_date.split('/')[1],
        cvv: this.selectedCardDetails.cvv,
        transfer_type: this.isTransferDigitalWallet == false ? '0' : '1',
        package_id: this.activePackage.id,
      };
      this.modalService.open('payment-process');
      this.subscriptionService.updateSubscription(params).subscribe(
        (result: any) => {
          this.commonService.payForDlb(this.userData?.request?.id).subscribe(
            (response: APIResponseModel) => {
              this.submitLoader = false;
              if (response.status) {
                this.toastService.success(response.message);
                this.localStorageService.storeData('is_payment_complete', response.data.is_payment_complete);

                this.localStorageService.updateUserData(USER_TYPES.user, {
                  key: 'request',
                  updateValue: response.data.is_payment_complete,
                  updateKey: 'is_payment_complete',
                  type: LOCAL_STORAGE_UPDATE_STATUS.O,
                });
                this.modalService.close('payment-process');
                void this.slugInterceptorService.navigate(['subscription-plan']);
              }
            },
            (exception) => {
              this.submitLoader = false;
              this.isLoading = false;
              this.modalService.close('payment-process');
              this.modalService.open('payment-failure');
              this.toastService.error(exception?.error?.message);
            },
          );
        },
        (exception) => {
          this.submitLoader = false;
          this.isLoading = false;
          this.modalService.close('payment-process');
          this.toastService.error(exception?.error?.message);
        },
      );
    } else {
      const params = {
        transfer_type: this.isTransferDigitalWallet == false ? '0' : '1',
        package_id: this.activePackage.id,
        isupdate: this.isUpdatePlan ? '1' : '0',
        ...this.paymentForm.value,
      };
      this.modalService.open('payment-process');
      this.subscriptionService.updateSubscription(params).subscribe(
        (respo: any) => {
          this.commonService.payForDlb(this.userData?.request?.id).subscribe(
            (response: APIResponseModel) => {
              this.submitLoader = false;
              if (response.status) {
                this.toastService.success(response.message);
                this.localStorageService.storeData('is_payment_complete', response.data.is_payment_complete);

                this.localStorageService.updateUserData(USER_TYPES.user, {
                  key: 'request',
                  updateValue: response.data.is_payment_complete,
                  updateKey: 'is_payment_complete',
                  type: LOCAL_STORAGE_UPDATE_STATUS.O,
                });
                this.modalService.close('payment-process');
                void this.slugInterceptorService.navigate(['subscription-plan']);
              }
            },
            (exception) => {
              this.submitLoader = false;
              this.isLoading = false;
              this.modalService.close('payment-process');
              this.modalService.open('payment-failure');
              this.toastService.error(exception?.error?.message);
            },
          );
        },
        (excep) => {
          this.submitLoader = false;
          this.isLoading = false;
          this.modalService.close('payment-process');
          this.toastService.error(excep?.error?.message);
        },
      );
    }
  }
  /**
   * on destroy
   */
  public ngOnDestroy(): void {
    this.updateSubscriptionSubscription1?.unsubscribe();
    this.transferVaultScreenSubscription1?.unsubscribe();
  }
  /**
   * selected card
   * @param {any} card
   */
  public selectedCard(card: any) {
    this.selectedCardDetails = card;
  }
}
