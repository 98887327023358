import { Component, Input, OnInit } from '@angular/core';
import { fileListTypeInterface } from 'src/app/interface/common.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { FILE_GROUPING } from 'src/constants/application.const';

@Component({
  selector: 'app-image-view-popup',
  templateUrl: './image-view-popup.component.html',
  styleUrls: ['./image-view-popup.component.css'],
})
/**
 * Image popup view
 */
export class ImageViewPopupComponent implements OnInit {
  public imagrSrc: string;
  public fileGrouping: any;
  public isLoading = false;
  public getExtension: string;
  @Input() secondModalOpen: boolean;
  @Input() uID: string = 'view-image-modal';


  /**
   * @constructor
   */
  constructor(
    private commonService: CommonService,
    private modalService: CommonModelService
  ) {
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.fileGrouping = FILE_GROUPING;
    this.commonService.setImageViewPopupObserve.subscribe((src: fileListTypeInterface) => {
      if (!src) return;
      this.imagrSrc = src.imageSrc;
      this.getExtension = src.fileExtension;
      this.commonService.setImageViewPopupTrigger(null);
    });
  }


  /**
   * Close modal
   */
  public closeModel(): void {
    this.modalService.close('view-image-modal');
    this.imagrSrc = '';
    if (this.secondModalOpen) {
      this.modalService.open('open-search-modal');
    }
  }


  /**
   * Get file format
   *
   * @param {string} type
   * @return {string}
   */
  public get checkFileType(): string {
    switch (this.getExtension) {
      case fileType.mp3: case fileType.mp3Multiple:
        return fileType.mp3;
      case fileType.mp4: case fileType.mp4Multiple:
        return fileType.mp4;
      default:
        return fileType.default;
    }
  }


  /**
   * file loader
   */
  viewImage(): void {
    this.isLoading = true;
    setTimeout(() => this.isLoading = false, 2000);
  }
}
/**
 * File type
 */
enum fileType {
  mp3 = 'mp3',
  mp3Multiple = 'mp3,',
  mp4 = 'mp4',
  mp4Multiple = 'mp4,',
  default = 'default'
}
