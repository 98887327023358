<div class="box-border body-content" id="main-content">
  <!--start navbar-->
  <app-professional-navbar></app-professional-navbar>
  <!--end-->
  <!--first Section start-->
  <div class="m-8 xl:mx-20 xl:mt-8">
    <!-- BEGIN: Chat Wrapper -->
    <div class="chat-wrap grid grid-cols-12 gap-4 xl:gap-8 h-screen mb-5 start relative">
      <div class="col-span-12">
        <h4 class="text-regular text-3xl text-darkblue font-semibold tracking-wide text-semibold">
          Messages
        </h4>
        <h4 class="text-regular text-xl text-darkblue font-semibold tracking-wide mt-2">
          Professional Service Providers
        </h4>
      </div>
      <!-- BEGIN: Chat List Wrap -->
      <div
        class="chat__chat-list-wrap bg-white overflow-y-hidden flex flex-col col-span-12 lg:col-span-5 xl:col-span-4 xxl:col-span-3">
        <div class="chat__chat-search-wrap box relative mx-5 my-5">
          <div class=" absolute inset-y-0 left-0 px-4 flex items-center pointer-events-none">
            <img src=" assets/images/about-yourself/search.svg" class="w-4 h-4" alt="dv">
          </div>
          <input type="text" class="input chat__chat-search h-12 p-4 w-full text-regular text-grey
                rounded-xs appearance-none pl-12 block focus:outline-none" placeholder="Search by Name"
            [(ngModel)]="searchKeyword">
        </div>
        <!-- start of loading shimmer -->
        <div class="bg-transparent  rounded-xs h-20" *ngIf="clientLoader">
          <div class="grid grid-cols-12 h-20 items-center gap-4 px-3 sm:pl-3">
            <div class="col-span-12 sm:col-span-12 ">
              <div class="flex gap-4 items-center mt-5 mb-5" *ngFor="let _ of [].constructor(5)">
                <app-shimmer-loading [shape]="'circle'" [width]="'4rem'">
                  <div class=" flex items-center justify-center mx-auto rounded-full h-12 w-12 bg-darkblue">
                    <p class="uppercase text-white text-regular text-base">
                      <img src="assets/images/executors/display-img.svg" class="w-5" alt="dv">
                    </p>
                  </div>
                </app-shimmer-loading>

                <div class="w-full">
                  <app-shimmer-loading [width]="'20%'">

                  </app-shimmer-loading>
                  <app-shimmer-loading [width]="'50%'">

                  </app-shimmer-loading>
                  <app-shimmer-loading [width]="'80%'">

                  </app-shimmer-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end of loading shimmer -->
        <!-- start of left side chat users list -->

        <div class="overflow-y-scroll">
          <div *ngFor="let chat of professionalsList | appFilter: searchKeyword; let i = index;">
            <!--  -->
            <div class="chat__chat-list cursor-pointer box relative flex items-center p-5"
              [ngClass]="{'chat__chat-list-active':  chat.professional_id===selectedUser}"
              (click)="onSelectUser(chat.professional_id, i)">

              <!-- <div>
                <svg _ngcontent-dme-c308="" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                  stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                  <line _ngcontent-dme-c308="" x1="19" y1="12" x2="5" y2="12"></line>
                  <polyline _ngcontent-dme-c308="" points="12 19 5 12 12 5"></polyline>
                </svg>
              </div> -->
              <div class="w-14 h-14 flex-none image-fit mr-2">
                <img alt="dv" class="rounded-full"
                  [src]="chat.profile_picture?baseUrl+'uploads'+chat.profile_picture:'assets/icons/user-bg.svg'">
                <div *ngIf="chat?.is_online === 1"
                  class="w-4 h-4 chat__list-online-status-active absolute right-0 bottom-0 rounded-full border-2 border-white">
                </div>
              </div>
              <div class="w-8/12 shrink ml-2 overflow-hidden">
                <div class="flex items-center justify-between">
                  <div class="chat__chat-name text-xl md:text-lg lg:text-xl text-medium capitalize">{{chat.name}}</div>
                  <div>
                    <div *ngIf="(+((chat?.last_chat_created_at)|date:'d') - +((dateToday)|date:'d')!==0)"
                      class="w-full truncate text-regular text-sm md:text-md lg:text-sm text-darkgrey mt-0.5">
                      {{chat?.last_chat_created_at | lastSeen}}
                    </div>
                    <div *ngIf="(+((chat?.last_chat_created_at)|date:'d') - +((dateToday)|date:'d')===0)"
                      class="w-full truncate text-regular text-sm md:text-md lg:text-sm text-darkgrey mt-0.5">
                      {{chat?.last_chat_created_at | lastSeen}}
                    </div>
                    <!-- <div
                      *ngIf="chat?.is_online === 0 && chat?.last_seen_at === null && !chat?.is_typing && chat?.lastMessage"
                      class="w-full truncate text-regular  text-sm md:text-sm lg:text-sm text-darkgrey mt-0.5">
                      long time ago
                    </div> -->
                  </div>
                </div>
                <div class="flex items-center justify-between">
                  <div *ngIf="chat?.lastMessage && !chat?.is_typing"
                    class="w-full truncate text-regular text-sm md:text-sm lg:text-sm text-darkgrey mt-0.5">
                    <ng-container *ngIf="chat?.last_message_type===0">
                      <ng-container *ngIf="multipleLineContent(chat?.lastMessage).length>1">
                        <p class="w-9/12 truncate">{{multipleLineContent(chat?.lastMessage)[0]}}</p>
                      </ng-container>
                      <ng-container *ngIf="multipleLineContent(chat?.lastMessage).length===1" #singleLineContent>
                        <p class="w-9/12 truncate">{{chat?.lastMessage}}</p>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="chat?.last_message_type===1">
                      <ng-container>
                        <div class="flex items-center gap-2"><img class="w-4 h-4"
                            src="assets/images/chat-images/image.png">
                          <p class="text-sm font-sans">Photo</p>
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="chat?.last_message_type===2">
                      <ng-container>
                        <div class="flex items-center gap-2"><img class="w-4" src="assets/images/chat-images/pdf.png">
                          <p class="text-sm font-sans">File</p>
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="chat?.last_message_type===3">
                      <ng-container>
                        <div class="flex items-center gap-2"><img class="w-4" src="assets/images/chat-images/audio.png">
                          <p class="text-sm font-sans">Audio</p>
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="chat?.last_message_type===4">
                      <ng-container>
                        <div class="flex items-center gap-2"><img class="w-4" src="assets/images/chat-images/video.png">
                          <p class="text-sm font-sans">Video</p>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                  <div *ngIf="chat?.is_typing"
                    class="w-full truncate text-regular text-sm md:text-sm lg:text-sm text-darkgrey mt-0.5">
                    typing...
                  </div>
                  <div *ngIf="!chat?.lastMessage && !chat?.is_typing"
                    class="w-full truncate text-regular  text-sm md:text-sm lg:text-sm text-darkgrey mt-0.5">
                    Start a Conversation!
                  </div>
                  <div *ngIf="chat?.totalUnread> 0"
                    class="w-6 h-6 rounded-full flex text-xs text-white text-right bg-logo-darkblue items-center justify-center text-white">
                    {{chat?.totalUnread}}
                  </div>
                </div>

              </div>
            </div>
            <div class="chat__chat-list-hr"></div>
          </div>
        </div>

        <!-- end of left side chat users list -->
      </div>
      <!-- END: Chat List Wrap -->

      <!-- BEGIN: Chat View Wrap -->
      <ng-container *ngIf="showchat">
        <div id="showchat-mob"
          class="chat__view-chat  bg-white overflow-y-hidden flex items-center justify-center flex-col col-span-12 lg:col-span-7 xl:col-span-8 xxl:col-span-9 relative chat-page">
          <!-- BEGIN: Chat View Content -->
          <div *ngIf="isShowMessage !== true" class="max-w-xl flex flex-col justify-center h-full ">
            <img src="assets/images/professional/chat.svg" class="w-64 h-40 mx-auto" alt="chat">
            <h4 class="text-center text-semibold text-md md:text-lg lg:text-xl xl:text-2xl text-darkblue">Hi, {{
              loggedInUser?.user?.first_name }} {{
              loggedInUser?.user?.last_name }}</h4>
            <p class="text-center text-grey text-regular text-sm md:text-md lg:text-lg mb-3">Search for the professional
              who
              you need to talk with or
              start a new converstion with the professional</p>
          </div>
          <div *ngIf="isShowMessage === true"
            class="flex sm:items-center justify-between py-3 border-b-2 border-gray-200 w-full px-4">
            <div class="relative flex items-center space-x-4">
              <div class="lg:hidden" (click)="backToListingPage()">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                  stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                  <line x1="19" y1="12" x2="5" y2="12"></line>
                  <polyline points="12 19 5 12 12 5"></polyline>
                </svg>
              </div>
              <div class="w-14 h-14 flex-none image-fit mr-2"><img alt="dv"
                  [src]="selectedProfessional.profile_picture?baseUrl+'uploads'+selectedProfessional.profile_picture:'assets/icons/user-bg.svg'"
                  class="rounded-full">
                <!-- <div _ngcontent-bsc-c286="" *ngIf="selectedProfessional?.is_online === 1"
              class="w-4 h-4 chat__list-online-status-active absolute right-0 bottom-0 rounded-full border-2 border-white">
            </div> -->
              </div>
              <div class="flex flex-col leading-tight">
                <div class=" flex items-center">
                  <span
                    class="text-darkgrey text-md md:text-lg lg:text-xl text-regular capitalize mr-3">{{selectedProfessional?.name}}</span>
                </div>
                <span class="text-base  text-regular text-grey">
                  <span *ngIf="selectedProfessional?.is_typing && selectedProfessional?.is_online===1">typing...</span>
                  <span *ngIf="!selectedProfessional?.is_typing && selectedProfessional?.is_online===1">online</span>
                  <span *ngIf="selectedProfessional?.is_online===0"><span>last seen at</span>
                    {{selectedProfessional?.last_seen_at
                    | lastSeen}}</span>
                  <span *ngIf="selectedProfessional?.is_online===0 && selectedProfessional?.last_seen_at===null">long
                    time
                    ago</span>
                </span>
              </div>
            </div>
            <!-- BEGIN: Chat Input Content -->
            <div *ngIf="isShowMessage == true  && previews.length===0"
              class="chat-input box w-full border flex items-center px-5 py-4 bg-white z-10">

              <!--image uploader-->
              <input type="file" class="hidden" multiple accept="image/x-png, image/gif, image/jpeg, image/jpg"
                (change)="onSelectingFiles($event)" #image>
              <!--document uploader-->
              <input type="file" class="hidden" multiple accept=".doc,.docx,.txt,.pdf"
                (change)="onSelectingFiles($event)" #pdf>
              <input type="file" class="hidden" multiple accept="application/octet-stream, audio/mpeg, mpga, mp3, wav"
                (change)="onSelectingFiles($event)" #audio>
              <!--document uploader-->
              <input type="file" class="hidden" multiple accept="video/mp4, video/mov, video/ogg, video/mkv"
                (change)="onSelectingFiles($event)" #video>
              <div class="upload_document">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                  class="bi bi-paperclip" viewBox="0 0 16 16">
                  <path
                    d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                </svg>
                <div class="upload_document_pos">
                  <div class="d-flex align-items-center flex-col gap-4">
                    <div>
                      <img (click)="image.click()" class="cursor-pointer" src="assets/images/chat-images/image.png"
                        alt="image">
                    </div>
                    <div>
                      <img (click)="pdf.click()" class="cursor-pointer" src="assets/images/chat-images/pdf.png"
                        alt="pdf">
                    </div>
                    <div>
                      <img (click)="audio.click()" class="cursor-pointer" src="assets/images/chat-images/audio.png"
                        alt="audio">
                    </div>
                    <div>
                      <img (click)="video.click()" class="cursor-pointer" src="assets/images/chat-images/video.png"
                        alt="video">
                    </div>
                  </div>
                </div>
              </div>

              <textarea [(ngModel)]="textMessage" (keyup)="typing($event)" (keyup.enter)="sendMessage()"
                id="textArea" (keydown)="onKeydown($event)"
                class="focus:outline-none w-full h-12 overflow-scroll  rounded-full chat__input form-control shadow-none resize-none border-transparent px-5 py-3 focus:shadow-none mr-3 sm:mr-0"
                rows="1" placeholder="Type your message...">
           </textarea>
              <img *ngIf="textMessage?.trim()" src="assets/images/chat/chat-send.svg" (click)="sendMessage()"
                class="ml-4 w-10 h-10 cursor-pointer" alt="">
            </div>
          </div>
          <div class="w-full relative my-auto" *ngIf="previews.length>=1">
            <div class="selectedfiletoshow  m-6">
              <div class="closeicon cursor-pointer" (click)="closeImagePreview()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-x-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
              <div class="largeimg">
                <img
                  *ngIf="fileGroups.D[fileGroups.D.indexOf(fileToShow?.type)]!=='pdf' && fileGroups.D.indexOf(fileToShow?.type)!==-1"
                  src="assets/images/chat-images/doc-main.svg">
                <img
                  *ngIf="fileGroups.D[fileGroups.D.indexOf(fileToShow?.type)]==='pdf' && fileGroups.D.indexOf(fileToShow?.type)!==-1"
                  src="assets/images/chat-images/pdf-main.svg">
                <img *ngIf="fileGroups.A.indexOf(fileToShow?.type)!==-1" src="assets/images/chat-images/audio-main.png">
                <img *ngIf="fileGroups.V.indexOf(fileToShow?.type)!==-1" src="assets/images/chat-images/video-main.png">
                <p class="text-center"
                  *ngIf="fileGroups.D.indexOf(fileToShow?.type)!==-1 || fileGroups.A.indexOf(fileToShow?.type)!==-1 || fileGroups.V.indexOf(fileToShow?.type)!==-1">
                  {{fileToShow?.fileName}}</p>
                <img *ngIf="fileGroups.I.indexOf(fileToShow?.type)!==-1" [src]="fileToShow?.file">

              </div>
              <div class="thumbimg">
                <ng-container *ngFor="let img of previews; let i = index;">
                  <div class="cursor-pointer relative" (click)="onSelectToShow(i)">
                    <img *ngIf="fileGroups.I.indexOf(img?.type)!==-1" [src]="img?.file">
                    <img
                      *ngIf="fileGroups.D[fileGroups.D.indexOf(img?.type)]!=='pdf' && fileGroups.D.indexOf(img?.type)!==-1"
                      src="assets/images/chat-images/small-doc-icon.svg">
                    <img
                      *ngIf="fileGroups.D[fileGroups.D.indexOf(img?.type)]==='pdf' && fileGroups.D.indexOf(img?.type)!==-1"
                      src="assets/images/chat-images/small-pdf-icon.svg">
                    <img *ngIf="fileGroups.A.indexOf(img?.type)!==-1"
                      src="assets/images/chat-images/small-audio-icon.png">
                    <img *ngIf="fileGroups.V.indexOf(img?.type)!==-1"
                      src="assets/images/chat-images/small-video-icon.png">
                    <div class="cursor-pointer absolute img--close" *ngIf="img?.type" (click)="removeThumpImage(i)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </div>
                  </div>

                </ng-container>
                <div class="relative cursor-pointer sendfile">
                  <img src="assets/images/chat/chat-send.svg" class="w-6 h-6 md:w-10 md:h-10 cursor-pointer"
                    alt="" (click)="SendFile()" (keydown.enter)="SendFile()">
                  <div class="absolute image-count text-white">
                    <span>{{previews.length}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--chat list-->
          <div *ngIf="previews.length===0" id="messages" #scrollMe (scroll)="onScroll($event)"
            class="overflow-y-scroll w-full scrollbar-hidden p-5 flex-1 flex flex-col justify-between"
            (click)="clickOnPage()">
            <div (click)="scrollToDown()" class="scroller cursor-pointer"
              *ngIf="isShowMessage === true && !isStartPosition">
              <img class="w-4" src="assets/icons/arrow-down.svg" alt="down-arrow">
            </div>
            <!--read messages-->
            <div *ngIf="displayMessages?.length">
              <div #readMessagesContainer
                *ngFor="let message of displayMessages; let i = index; let first = first; let last = last;"
                class="clear-both">
                <!-- Start of the day -->
                <ng-container
                  *ngIf="first || ((displayMessages[i - 1].created_at) | date:'d') !== ((message.created_at) | date:'d')">
                  <div class="flex items-center justify-center my-3 -mx-6">
                    <div class="flex-auto border-b"></div>
                    <div
                      *ngIf="(+(message.created_at | date:'d') - +(dateToday | date:'d') !== 0) && +(message.created_at | date:'d') - +(dateToday | date:'d') !== -1"
                      class="flex-0 mx-4 text-sm font-medium leading-5 text-secondary">
                      {{(message.created_at) | date: 'longDate'}}
                    </div>
                    <div *ngIf="+(message.created_at | date:'d') - +(dateToday | date:'d') === 0"
                      class="flex-0 mx-4 text-sm font-medium leading-5 text-secondary">
                      Today
                    </div>
                    <div *ngIf="+(message.created_at | date:'d') - +(dateToday | date:'d') === -1"
                      class="flex-0 mx-4 text-sm font-medium leading-5 text-secondary">
                      Yesterday
                    </div>
                    <div class="flex-auto border-b"></div>
                  </div>
                </ng-container>
                <!-- BEGIN: Chat Text -->
                <!--sender text-->
                <div *ngIf="message.from_id == loggedInUserId"
                  class="chat__text-box-sender max-w-lg xxl:max-w-xl flex items-end mb-6 relative ">
                  <div class="shrink">
                    <div class="items-end px-4 py-3" [ngClass]="{'flex':message?.message_type===0}">
                      <!--TEXT-->
                      <div class="box leading-relaxed pr-4 font-sans text-md flex-1" *ngIf="message?.message_type===0">
                        <ng-container *ngIf="multipleLineContent(message.message).length>1">
                          <ng-container *ngFor="let content of multipleLineContent(message.message)">
                            <p class="break-all">{{content}}</p>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="multipleLineContent(message.message).length===1" #singleLineContent>
                          <p class="break-all">{{message.message}}</p>
                        </ng-container>
                      </div>
                      <!--IMAGE-->
                      <div class="box leading-relaxed font-sans text-md flex-1" *ngIf="message?.message_type==1">
                        <ng-container>
                          <div class="relative image-min-height">
                            <img class="cursor-pointer w-40" (click)="viewFile(message?.message)"
                              [src]="message?.message? baseUrl+'uploads'+message?.message : message?.message_url"
                              (contextmenu)="onRightClickOnImage($event, message?.message, message?.id)"
                              (load)="onFileLoading(message?.id)">
                            <div id="" class="absolute loader-img">
                              <img id="loader_{{message?.id}}" class="w-10 h-10"
                                src="assets/images/common/imageLoder.gif" alt="load...">
                            </div>
                          </div>
                          <ng-container *ngIf="isDisplayImageMenu">
                            <div id="{{'menu'+message?.id}}" [ngStyle]="getRightClickOnImageStyle()">
                              <!-- <p class="cursor-pointer" (click)="onFileMenuClick('view', messageUrlOnClicked)">View</p>
                              <hr /> -->
                              <p class="cursor-pointer" (click)="onFileMenuClick('download', messageIdOnClicked)">
                                Download</p>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                      <!--PDF-->
                      <div class="box leading-relaxed font-sans text-md flex-1" *ngIf="message?.message_type==2">
                        <ng-container>
                          <div class="relative image-min-height">
                            <ng-container *ngIf="message?.message">
                              <img *ngIf="fileGroups.D.indexOf(message?.message.split('.').pop().toLowerCase())===3"
                                class="cursor-pointer max-w-10" src="assets/images/chat-images/small-pdf-icon.svg"
                                (click)="viewFile(message?.message)"
                                (contextmenu)="onRightClickOnImage($event, message?.message, message?.id)"
                                (load)="onFileLoading(message?.id)">
                              <img *ngIf="fileGroups.D.indexOf(message?.message.split('.').pop().toLowerCase())!==3"
                                class="cursor-pointer max-w-10" src="assets/images/chat-images/small-doc-icon.svg"
                                (click)="viewFile(message?.message)"
                                (contextmenu)="onRightClickOnImage($event, message?.message, message?.id)"
                                (load)="onFileLoading(message?.id)">
                            </ng-container>
                            <img *ngIf="message?.message_url" class="cursor-pointer max-w-10"
                              [src]="'assets/images/chat-images/small-doc-icon.svg'">
                            <div id="" class="absolute loader-img">
                              <img id="loader_{{message?.id}}" class="w-8 h-8" src="assets/images/common/imageLoder.gif"
                                alt="load...">
                            </div>
                          </div>
                          <ng-container *ngIf="isDisplayImageMenu">
                            <div id="{{'menu'+message?.id}}" [ngStyle]="getRightClickOnImageStyle()">
                              <!-- <p class="cursor-pointer" (click)="onFileMenuClick('view', messageUrlOnClicked)">
                                View
                              </p>
                              <hr /> -->
                              <p class="cursor-pointer" (click)="onFileMenuClick('download', messageIdOnClicked)">
                                Download</p>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                      <!--Audio-->
                      <div class="box leading-relaxed font-sans text-md flex-1" *ngIf="message?.message_type==3">
                        <ng-container>
                          <p>Audio</p>
                          <div class="flex gap-1 image-min-height">
                            <div *ngIf="message?.message">
                              <span class="w-4 h-6">
                                <button class="w-4 h-6"
                                  (click)="message.audio?.paused ? message.audio?.play() : message.audio?.pause()">
                                  <i class="fa fa-play" *ngIf="message.audio?.paused">
                                    <img class="cursor-pointer max-w-10" src="assets/icons/icon-play.svg">
                                  </i>
                                  <i class="fa fa-pause" *ngIf="!message.audio?.paused">
                                    <img class="cursor-pointer max-w-10" src="assets/icons/icon-pause.svg"></i></button>
                                <div id="" class="absolute loader-img">
                                  <img id="loader_{{message?.id}}" class="w-10 h-10"
                                    src="assets/images/common/imageLoder.gif" alt="load...">
                                </div>
                              </span>
                            </div>
                            <ng-container *ngIf="message?.message">
                              <audio attr.id="audioId{{message?.id}}" id="audioId{{message?.id}}"
                                [src]="baseUrl+'uploads'+message?.message" (timeupdate)="updateAudioTime(message)"
                                (loadedmetadata)="prepareAudioPlayer(message)" (ended)="onAudioEnded(message)"></audio>
                              <div>
                                <input attr.id="sliderId{{message?.id}}" id="sliderId{{message?.id}}" type="range"
                                  name="sliderId{{message?.id}}" class="slider" value='{{message?.audio?.currentTime}}'
                                  min="0" max="{{message?.audio?.duration}}"
                                  (change)="setTargetDuration(message, $event)" />
                              </div>
                            </ng-container>
                            <div *ngIf="message?.message_url" class="flex">
                              <span class="w-4 h-6">
                                <button class="w-4 h-6">
                                  <i class="fa fa-play">
                                    <img class="cursor-pointer max-w-10" src="assets/icons/icon-play.svg">
                                  </i></button>
                                <div id="" class="absolute loader-img">
                                  <img id="loader_{{message?.unique_id}}" class="w-10 h-10"
                                    src="assets/images/common/imageLoder.gif" alt="load...">
                                </div>
                              </span>
                              <div>
                                <input attr.id="sliderId{{message?.unique_id}}" id="sliderId{{message?.unique_id}}"
                                  type="range" name="sliderId{{message?.id}}" class="slider" />
                              </div>
                            </div>
                          </div>
                          <ng-container *ngIf="isDisplayImageMenu">
                            <div id="{{'menu'+message?.id}}" [ngStyle]="getRightClickOnImageStyle()">
                              <!-- <p class="cursor-pointer" (click)="onFileMenuClick('view', messageUrlOnClicked)">View</p>
                              <hr /> -->
                              <p class="cursor-pointer" (click)="onFileMenuClick('download', messageIdOnClicked)">
                                Download</p>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                      <!--Video-->
                      <div class="box leading-relaxed font-sans text-md flex-1" *ngIf="message?.message_type==4">
                        <ng-container>
                          <div class="relative image-min-height">
                            <img *ngIf="message?.message" class="cursor-pointer max-w-10"
                              (click)="viewFile(message?.message)" src="assets/images/chat-images/small-video-icon.png"
                              (contextmenu)="onRightClickOnImage($event, message?.message, message?.id)"
                              (load)="onFileLoading(message?.id)">
                            <img *ngIf="message?.message_url" class="cursor-pointer max-w-10"
                              src="assets/images/chat-images/small-video-icon.png">
                            <div id="" class="absolute loader-img">
                              <img id="loader_{{message?.id}}" class="w-10 h-10"
                                src="assets/images/common/imageLoder.gif" alt="load...">
                            </div>
                          </div>
                          <ng-container *ngIf="isDisplayImageMenu">
                            <div id="{{'menu'+message?.id}}" [ngStyle]="getRightClickOnImageStyle()">
                              <!-- <p class="cursor-pointer" (click)="onFileMenuClick('view', messageUrlOnClicked)">View</p>
                              <hr /> -->
                              <p class="cursor-pointer" (click)="onFileMenuClick('download', messageIdOnClicked)">
                                Download</p>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                      <sub class="text-xs flex flex-row-reverse text-regular chat__text-timing "><img
                          src="{{getReadUnreadImage(message.message_status)}}" alt="Digital-Lock-Box" class="ml-2">
                        {{message.updated_at | date:'h:mm a'}}</sub>
                    </div>
                  </div>
                  <div id="threedots" class="cursor-pointer threedots">
                    <div class="cursor-pointer">
                      <svg id="Layer_1" width="10" height="10" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 29.96 122.88">
                        <defs>
                          <style>
                            .cls-1 {
                              fill-rule: evenodd;
                            }
                          </style>
                        </defs>
                        <title>3-vertical-dots</title>
                        <path class="cls-1"
                          d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,92.93a15,15,0,1,1-15,15,15,15,0,0,1,15-15Zm0-46.47a15,15,0,1,1-15,15,15,15,0,0,1,15-15Z" />
                      </svg>
                    </div>

                    <div id="{{'file-handling-menus-'+message?.id}}" class="file-menus cursor-pointer filemenu-block">
                      <ul>
                        <li
                          *ngIf="(message?.message_type===1 || message?.message_type===2) && (fileGroups.D.indexOf(message?.message.split('.').pop().toLowerCase())===3 || fileGroups.I.indexOf(message?.message.split('.').pop().toLowerCase())!==-1)"
                          (click)="onFileMenuClick('view', message?.message)"><a>View</a></li>
                        <li *ngIf="message?.message_type!==0" (click)="onFileMenuClick('download', message?.id)">
                          <a>Download</a>
                        </li>
                        <li *ngIf="message?.message_type===0" (click)="onFileMenuClick('edit', message)"><a>Edit</a>
                        </li>
                        <li *ngIf="message?.message_type===0" (click)="onFileMenuClick('copy', message)"><a>Copy</a>
                        </li>
                        <li (click)="onFileMenuClick('remove', message)"><a>Remove</a></li>
                        <!-- <li><a href="#">Delete</a></li> -->
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- END: Chat Text -->
                <div class="clear-both"></div>
                <!-- BEGIN: Chat Text -->
                <!--receiver chat-->
                <div *ngIf="message.from_id != loggedInUserId"
                  class="chat__text-box-receiver max-w-lg xxl:max-w-xl flex items-end mb-6 relative">
                  <div class="shrink">
                    <div class="items-end px-4 py-3" [ngClass]="{'flex':message?.message_type===0}">
                      <!--TEXT-->
                      <div class="box leading-relaxed pr-4 font-sans text-md flex-1" *ngIf="message?.message_type==0">
                        <ng-container *ngIf="multipleLineContent(message.message).length!==1">
                          <ng-container *ngFor="let content of multipleLineContent(message.message)">
                            <p class="break-all">{{content}}</p>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="multipleLineContent(message.message).length===1" #singleLineContent>
                          <p class="break-all">{{message.message}}</p>
                        </ng-container>
                      </div>
                      <!--IMAGE-->
                      <div class="box leading-relaxed font-sans text-md flex-1" *ngIf="message?.message_type==1">
                        <ng-container>

                          <div class="relative image-min-height">
                            <img class="cursor-pointer w-40" (click)="viewFile(message?.message)"
                              [src]="baseUrl+'uploads'+message?.message"
                              (contextmenu)="onRightClickOnImage($event, message?.message, message?.id)"
                              (load)="onFileLoading(message?.id)">
                            <div id="" class="absolute loader-img">
                              <img id="loader_{{message?.id}}" class="w-10 h-10"
                                src="assets/images/common/imageLoder.gif" alt="load...">
                            </div>
                          </div>
                          <ng-container *ngIf="isDisplayImageMenu">
                            <div id="{{'menu'+message?.id}}" [ngStyle]="getRightClickOnImageStyle()">
                              <!-- <p class="cursor-pointer" (click)="onFileMenuClick('view', messageUrlOnClicked)">View</p>
                              <hr /> -->
                              <p class="cursor-pointer" (click)="onFileMenuClick('download', messageIdOnClicked)">
                                Download</p>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                      <!--PDF-->
                      <div class="box leading-relaxed font-sans text-md flex-1" *ngIf="message?.message_type==2">
                        <ng-container>
                          <div class="relative image-min-height" (click)="clickOnPage()">
                            <img *ngIf="fileGroups.D.indexOf(message?.message.split('.').pop().toLowerCase())===3"
                              class="cursor-pointer max-w-10" src="assets/images/chat-images/small-pdf-icon.svg"
                              (click)="viewFile(message?.message)"
                              (contextmenu)="onRightClickOnImage($event, message?.message, message?.id)"
                              (load)="onFileLoading(message?.id)">
                            <img *ngIf="fileGroups.D.indexOf(message?.message.split('.').pop().toLowerCase())!==3"
                              class="cursor-pointer max-w-10" src="assets/images/chat-images/small-doc-icon.svg"
                              (click)="viewFile(message?.message)"
                              (contextmenu)="onRightClickOnImage($event, message?.message, message?.id)"
                              (load)="onFileLoading(message?.id)">
                            <div id="" class="absolute loader-img">
                              <img id="loader_{{message?.id}}" class="w-10 h-10"
                                src="assets/images/common/imageLoder.gif" alt="load...">
                            </div>
                          </div>
                          <ng-container *ngIf="isDisplayImageMenu">
                            <div id="{{'menu'+message?.id}}" [ngStyle]="getRightClickOnImageStyle()">
                              <!-- <p class="
                                cursor-pointer" (click)="onFileMenuClick('view', messageUrlOnClicked)">
                                View
                              </p>
                              <hr /> -->
                              <p class="cursor-pointer" (click)="onFileMenuClick('download', messageIdOnClicked)">
                                Download</p>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                      <!--Audio-->
                      <div class="box leading-relaxed font-sans text-md flex-1" *ngIf="message?.message_type==3">
                        <ng-container>
                          <p>Audio</p>
                          <div class="flex gap-1 image-min-height">
                            <div *ngIf="message?.message">
                              <span class="w-4 h-6">
                                <button class="w-4 h-6"
                                  (click)="message.audio?.paused ? message.audio?.play() : message.audio?.pause()">
                                  <i class="fa fa-play" *ngIf="message.audio?.paused">
                                    <img class="cursor-pointer max-w-10" src="assets/icons/icon-play.svg">
                                  </i>
                                  <i class="fa fa-pause" *ngIf="!message.audio?.paused">
                                    <img class="cursor-pointer max-w-10" src="assets/icons/icon-pause.svg"></i></button>
                                <div id="" class="absolute loader-img">
                                  <img id="loader_{{message?.id}}" class="w-10 h-10"
                                    src="assets/images/common/imageLoder.gif" alt="load...">
                                </div>
                              </span>
                            </div>
                            <ng-container *ngIf="message?.message">
                              <audio attr.id="audioId{{message?.id}}" id="audioId{{message?.id}}"
                                [src]="baseUrl+'uploads'+message?.message" (timeupdate)="updateAudioTime(message)"
                                (loadedmetadata)="prepareAudioPlayer(message)" (ended)="onAudioEnded(message)"></audio>
                              <div>
                                <input attr.id="sliderId{{message?.id}}" id="sliderId{{message?.id}}" type="range"
                                  name="sliderId{{message?.id}}" class="slider" value='{{message?.audio?.currentTime}}'
                                  min="0" max="{{message?.audio?.duration}}"
                                  (change)="setTargetDuration(message, $event)" />
                              </div>
                            </ng-container>
                          </div>
                          <ng-container *ngIf="isDisplayImageMenu">
                            <div id="{{'menu'+message?.id}}" [ngStyle]="getRightClickOnImageStyle()">
                              <!-- <p class="cursor-pointer" (click)="onFileMenuClick('view', messageUrlOnClicked)">View</p>
                                <hr /> -->
                              <p class="cursor-pointer" (click)="onFileMenuClick('download', messageIdOnClicked)">
                                Download</p>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                      <!--Video-->
                      <div class="box leading-relaxed font-sans text-md flex-1" *ngIf="message?.message_type==4">
                        <ng-container>
                          <div class="relative image-min-height">
                            <img *ngIf="message?.message" class="cursor-pointer max-w-10"
                              (click)="viewFile(message?.message)" src="assets/images/chat-images/small-video-icon.png"
                              (contextmenu)="onRightClickOnImage($event, message?.message, message?.id)"
                              (load)="onFileLoading(message?.id)">
                            <div id="" class="absolute loader-img">
                              <img id="loader_{{message?.id}}" class="w-10 h-10"
                                src="assets/images/common/imageLoder.gif" alt="load...">
                            </div>
                          </div>
                          <ng-container *ngIf="isDisplayImageMenu">
                            <div id="{{'menu'+message?.id}}" [ngStyle]="getRightClickOnImageStyle()">
                              <!-- <p class="cursor-pointer" (click)="onFileMenuClick('view', messageUrlOnClicked)">View</p>
                              <hr /> -->
                              <p class="cursor-pointer" (click)="onFileMenuClick('download', messageIdOnClicked)">
                                Download</p>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                      <div class="text-xs flex flex-row-reverse text-regular chat__text-timing ">
                        {{message.updated_at | date:'h:mm a'}}</div>
                    </div>
                  </div>
                  <div id="threedots-receiver" class="cursor-pointer threedots">
                    <div class="cursor-pointer">
                      <svg id="Layer_1" width="10" height="10" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 29.96 122.88">
                        <defs>
                          <style>
                            .cls-1 {
                              fill-rule: evenodd;
                            }
                          </style>
                        </defs>
                        <title>3-vertical-dots</title>
                        <path class="cls-1"
                          d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,92.93a15,15,0,1,1-15,15,15,15,0,0,1,15-15Zm0-46.47a15,15,0,1,1-15,15,15,15,0,0,1,15-15Z" />
                      </svg>
                    </div>

                    <div id="{{'file-handling-menus-'+message?.id}}" class="file-menus cursor-pointer filemenu-block">
                      <ul>
                        <li
                          *ngIf="(message?.message_type===1 || message?.message_type===2) && (fileGroups.D.indexOf(message?.message.split('.').pop().toLowerCase())===3 || fileGroups.I.indexOf(message?.message.split('.').pop().toLowerCase())!==-1)"
                          (click)="onFileMenuClick('view', message?.message)"><a>View</a></li>
                        <li *ngIf="message?.message_type!==0" (click)="onFileMenuClick('download', message?.id)">
                          <a>Download</a>
                        </li>
                        <li *ngIf="message?.message_type===0" (click)="onFileMenuClick('copy', message)"><a>Copy</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- END: Chat Text -->
              </div>
            </div>
            <!-- END: Chat Text -->
            &nbsp;
          </div>
          <!-- END: Chat View Content -->
          <!-- END: Chat View Wrap -->
        </div>
      </ng-container>
      <!-- END: Chat Wrapper -->
    </div>
  </div>

  <!--view image-->
  <app-image-view-popup></app-image-view-popup>
  <!--delete chat confirmation-->
  <app-delete-chat-confirmation-modal
    (deleteOption)="removeMessageConfirmed($event)"></app-delete-chat-confirmation-modal>
  <!--chat status-->
  <app-chat-status-handler [message]="chatStatusMessage"></app-chat-status-handler>
