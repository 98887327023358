<div class="relative flex items-start justify-center get-organized-banner__wrap mh-800-height new-landing-height">
  <div class="max-w-screen mx-auto z-30 custom-width">
    <div class="grid grid-cols-12 gap-4 lg:gap-12">
      <div class="col-span-12 lg:col-span-8">
        <h1 class="text-white text-center font-ave-black">
          Organize, store, and share ALL your most important information—for
          you now, and to pass on to loved ones.
        </h1>
      </div>

      <div class="col-span-12 md:col-span-12 lg:col-span-4" *ngIf="!isSmallWindow">
        <div class="hero-box">
          <h2 class="font-ave-black text-darkblue text-left">
            Keep your digital legacy securely organized and accessible.
          </h2>
          <button
            (click)="menuChange('register')"
            class="bg-primary font-ave-medium text-white rounded-sm p-3 lg:p-4 text-base lg:text-lg xl:text-xl w-full uppercase my-6 lg:my-8 focus:outline-none start-now">
            Start Now
          </button>
          <p class="font-ave-black text-darkblue text-left">NO OBLIGATION</p>
          <p class="text-black text-left font-ave-semibold">
            Cancel within the next 30 days for a full refund.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
