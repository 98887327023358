<app-common-modal id="{{modal_id}}" class="app-common-modal" [heightClass]="'min-h-75'">
  <div class="w-full  relative rounded-md mb-6">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
         (click)="closeModel()">
    <div class="mb-4">
      <div class="flex flex-row items-baseline">
        <div>
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide w-11/12">
            Digital Vault Custodian Details
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">
            Please provide contact information for your Digital Vault Custodian. This information will be critical at
            the time of your passing. It is important that should this information change, you update this record.
          </p>
          <p class="text-grey text-regular pt-2 text-sm">
            By including the below information, you are certifying to us that you are authorized to do so.
          </p>
        </div>
      </div>
      <p *ngIf="isFormDisable" class="text-red-500">Note: Fields are non-editable after their registration. </p>
    </div>
    <form [formGroup]="custodianForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
        <div class="col-span-12 lg:col-span-4">
          <label for="first_name" class="text-label-grey text-sm text-regular">First
            Name*</label>
          <input type="text" [attr.disabled]="isFormDisable || undefined"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 placeholder="First Name" formControlName="first_name" name="first_name" id="first_name"
                 [ngClass]="formGet.first_name.errors && formGet.first_name.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('first_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 lg:col-span-4">
          <label for="middle_name" class="text-label-grey text-sm text-regular">
            Middle Name
          </label>
          <input type="text" [attr.disabled]="isFormDisable || undefined"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 id="middle_name" formControlName="middle_name" placeholder="Middle Name"
                 [ngClass]="formGet.middle_name.errors && formGet.middle_name.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('middle_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 lg:col-span-4">
          <label for="last-name" class="text-label-grey text-sm text-regular">Last
            Name*</label>
          <input type="text" [attr.disabled]="isFormDisable || undefined"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 placeholder="Last Name" formControlName="last_name" name="last-name" id="last-name"
                 [ngClass]="formGet.last_name.errors && formGet.last_name.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('last_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="relationship" class="text-label-grey text-sm text-regular">
            Relationship to you?*
          </label>
          <app-select-component [options]="relationshipList" [placeholder]="'Select Relationship'"
                                id="relationship" (optionChangeEmitter)="optionChangeEmitterRelationship($event)"
                                [selectedOptionDefault]="custodianForm?.value?.['relationship']"
                                [ifError]="formGet.relationship.errors && formGet.relationship.touched"
                                [disabled]="isFormDisable"
                                (toggleEmiter)="toggleEmiter($event)"></app-select-component>
          <p *ngFor="let error of getErrors('relationship')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div *ngIf="useOther" class="col-span-12">
          <label for="define_others" class="text-label-grey text-sm text-regular">Define Other*</label>

          <div class="relative flex items-center">
            <input type="text" id="define_others" [attr.disabled]="isFormDisable || undefined"
                   class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                   name="define_others" formControlName="define_others" placeholder="Define Other"
                   [ngClass]="formGet?.define_others.errors && formGet?.define_others.touched?'form-input-error':'form-input-focus'">
          </div>
          <p *ngFor="let error of getErrors('define_others')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label [for]="'dob'" class="text-label-grey text-sm text-regular">
            Date of Birth ( {{domainDateFormat}} )*
          </label>
          <app-datepicker-toggle
            inputID="dob"
            class="mt-1"
            [bsConfig]="bsConfig"
            [ngStyle]="{ 'opacity': isFormDisable ? '0.5' : '1', 'pointer-events': isFormDisable ? 'none' : 'auto' }"
            [useFormControl]="formGet.dob">
          </app-datepicker-toggle>
          <p *ngFor="let error of getErrors('dob')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12  lg:col-span-6" *ngIf="showSSN">
          <label for="ssn" class="text-label-grey text-sm text-regular">SSN
            (Social Security Number)</label>
          <div class="relative text-gray-700">
            <a class="absolute inset-y-0 right-2 pr-3 flex items-center"
               (click)="toggleSSNView.ssn = !toggleSSNView.ssn">
              <fa-icon [icon]="faEye" *ngIf="toggleSSNView.ssn"></fa-icon>
              <fa-icon [icon]="faEyeSlash" *ngIf="!toggleSSNView.ssn"></fa-icon>
            </a>
            <input type="text" [type]="toggleSSNView.ssn?'text':'password'"
                   [attr.disabled]="isFormDisable ? true : null"
                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                   name="ssn" id="ssn" formControlName="ssn" placeholder="e.g. 255-34-5345"
                   [ngClass]="formGet.ssn.errors && formGet.ssn.touched?'form-input-error':'form-input-focus'"
                   tabindex="0"
                   [mask]="SSN_MASK" [dropSpecialCharacters]="false" maxlength="11"
                   (focusout)="onFocusout($event,'ssn')">
          </div>
          <p *ngFor="let error of getErrors('ssn')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>

        <div class="col-span-12 lg:col-span-6" *ngIf="showSSN">
          <label for="ssn_validation" class="text-label-grey text-sm text-regular">
            Confirm SSN
          </label>
          <div class="relative h-12 text-gray-700">

            <div class="relative">
              <a class="absolute inset-y-0 right-2 pr-3 flex items-center"
                 (click)="toggleSSNView.v_ssn = !toggleSSNView.v_ssn">
                <fa-icon [icon]="faEye" *ngIf="toggleSSNView.v_ssn"></fa-icon>
                <fa-icon [icon]="faEyeSlash" *ngIf="!toggleSSNView.v_ssn"></fa-icon>
              </a>
              <input type="text" [type]="toggleSSNView.v_ssn ? 'text' : 'password'"
                     class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                     name="ssn_validation" id="ssn_validation" formControlName="ssn_validation"
                     placeholder="e.g. 255-34-9345" [attr.disabled]="isFormDisable || undefined"
                     [ngClass]="formGet.ssn_validation.errors && formGet.ssn_validation.touched?'form-input-error':'form-input-focus'"
                     [mask]="SSN_MASK" [dropSpecialCharacters]="false" maxlength="11"
                     (input)="onChange($event,'ssn_validation')" (focusout)="onFocusout($event,'ssn_validation')">
            </div>

          </div>
          <div *ngIf="!checkSSNMatch() && ((formGet.ssn.value && formGet.ssn.valid) && !formGet.ssn_validation.value)">
            <p class="text-regular font-medium text-xs mt-2 error-alert">
              Confirm SSN is required.
            </p>
          </div>
          <div *ngIf="!checkSSNMatch() && ((formGet.ssn.value && formGet.ssn.valid) && formGet.ssn_validation.value)">
            <p class="text-regular font-medium text-xs mt-2 error-alert">
              SSN numbers must match.
            </p>
          </div>
        </div>
        <div class="col-span-12" *ngIf="showSSN && formGet.ssn.value">
          <div *ngIf="checkSSNMatch() && validatingSSN == 1" class="flex">
            <p class="text-regular font-medium text-xs checking-alert">
              Validating SSN &nbsp;
            </p>
            <div class="loading-red mr-2"></div>
          </div>
          <p class="text-regular font-medium text-xs error-alert"
             *ngIf="checkSSNMatch() && validatingSSN == 2 && !isUniqueSSN">
            SSN has already been entered.
          </p>
        </div>
        <div class="my-6 col-span-12">
          <div class="flex flex-row items-baseline">
            <div>
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Phone Number and Email Address
              </h4>
            </div>
          </div>

          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
            <div class="col-span-12">
              <label for="phone" class="text-label-grey text-sm text-regular">
                Phone Number*</label>
              <app-phone-input id="phone" [placeholder]="'e.g. 8665044754'" [phoneNumber]="custodianForm.value.phone"
                               [countryCode]="countryCode"
                               [errorClassTrigger]="formGet.phone.errors && formGet.phone.touched"
                               [uniqueId]="'phone'" [form]="custodianForm"
                               (onChange)="detectChangePhonenumber($event)" [disabled]="isFormDisable"
                               (focusout)="formGet.phone.markAsTouched()">
              </app-phone-input>
              <p *ngFor="let error of getErrors('phone')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12">
              <label for="email" class="text-label-grey text-sm text-regular">
                Email Address*</label>
              <input type="email"
                     class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                     placeholder="e.g. marktylor@gmail.com" [attr.disabled]="isFormDisable || undefined"
                     [ngClass]="formGet.email.errors && formGet.email.touched?'form-input-error':'form-input-focus'"
                     formControlName="email" name="email" id="email" (focusout)="markAsTouchedIndividual('email')">
              <p *ngFor="let error of getErrors('email')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
          </div>
        </div>

        <div class="mt-1 col-span-12">
          <div class="mb-4">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Address
            </h4>
            <p class="text-grey text-regular pt-1 text-sm">Make sure to enter your address including your postcode.
            </p>
          </div>
          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
            <div class="col-span-12">
              <label for="country" class="text-label-grey text-sm text-regular">Country*</label>
              <app-country-search-component id="country" (optionChangeEmitter)="optionChangeListenerCountry($event)"
                [defaultSelected]="formGet?.country?.value" [ngStyle]="{ 'opacity': isFormDisable ? '0.5' : '1', 'pointer-events': isFormDisable ? 'none' : 'auto' }"
                [enableError]="formGet.country.errors && formGet.country.touched"></app-country-search-component>
              <p *ngFor="let error of getErrors('country')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-8 relative move-map" #moveMapHere>
              <label for="street-address" class="text-label-grey text-sm text-regular">
                Street Address*
              </label>
              <input type="text" id="street-address" #search (keyup)="onAddressChange(search)"
                (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null" (input)="onFocusAddress()"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                placeholder="e.g Main St" name="address" formControlName="address" [attr.disabled]="isFormDisable || undefined"
                [ngClass]="formGet.address.errors && formGet.address.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('address')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-4">
              <label for="apt_number" class="text-label-grey text-sm text-regular">Apartment / Suite #</label>
              <input name="apt_number" id="apt_number" formControlName="apt_number" [attr.disabled]="isFormDisable || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                [ngClass]="formGet.apt_number.errors && formGet.apt_number.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('apt_number')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12" *ngIf="selectedCountryName!==unitedKingdom">
              <label for="state-Province" class="text-label-grey text-sm text-regular">State/Province*</label>
              <app-state-search-component id="state-province" [countryCode]="formGet?.country?.value"
                [enableError]="formGet.state.errors && formGet.state.touched" [defaultSelected]="formGet?.state?.value"
                (optionChangeEmitter)="optionChangeListenerState($event)" tabindex="0" [disabled]="isFormDisable"
                (focusout)="markAsTouchedIndividual('state')"></app-state-search-component>
              <p *ngFor="let error of getErrors('state')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 md:col-span-6 relative">
              <label for="city" class=" text-label-grey text-sm text-regular ">City*</label>
              <app-city-search id="city" [stateCode]="formGet?.state?.value"
                [enableError]="formGet.city.errors && formGet.city.touched" [defaultSelected]="formGet?.city?.value"
                (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0" [disabled]="isFormDisable"
                (focusout)="markAsTouchedIndividual('city')"></app-city-search>
              <p *ngFor="let error of getErrors('city')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class=" col-span-12 md:col-span-6 ">
              <label for="zipcode" class="text-label-grey text-sm text-regular">
                Zip/Postal Code*
              </label>
              <input type="text" (focusout)="markAsTouchedIndividual('zipcode')"
                class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
                placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                name="zipcode" id="zipcode" formControlName="zipcode" oninput="this.value = this.value.toUpperCase()"
                (focus)="validateZipCode()" [attr.disabled]="isFormDisable || undefined"
                [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('zipcode')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
          </div>
        </div>

        <div class="flex flex-col-reverse sm:flex-row col-span-12 justify-between items-center mt-8">
          <app-button-loader class="w-full sm:w-auto" *ngIf="this.custodianForm['controls']['id'].value && isCustodian"
                             [buttonText]="'Remove Custodian'" [loader]="submitLoaderDelete" [buttonType]="'button'"
                             [outlineOnly]="true"
                             (click)="deleteCustodian()" [valid]="!submitLoader">
          </app-button-loader>
          <app-button-loader class="w-full sm:w-auto"
                             [buttonText]="this.custodianForm['controls']['id'].value ? 'Update Custodian' : 'Save and Continue'"
                             [loader]="submitLoader" buttonType="'submit'"
                             [valid]="checkSSNMatch() && checkSSNValidation && !submitLoaderDelete">
          </app-button-loader>
        </div>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>
