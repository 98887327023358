import { Component, OnInit } from '@angular/core';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { ProCheckoutService } from 'src/app/professional-dashboard/services/pro-checkout.service';
import { ProfessionalInvoiceService } from 'src/app/professional-dashboard/services/professional-invoice.service';
@Component({
  selector: 'app-professional-invoice-table',
  templateUrl: './professional-invoice-table.component.html',
  styleUrls: ['./professional-invoice-table.component.css']
})
export class ProfessionalInvoiceTableComponent implements OnInit {
  public dataLoading: boolean = false;
  public currentPage:number = 1;
  public lastPage: number;
  public recordsPerPage: number = 5;
  public listOrder = 'desc';
  public searchKeyword = '';
  public invoiceList: Array<Object>;
  public paginationLinks: Array<any>;
  public dateFormat: string;

  constructor(private professionalInvoiceService: ProfessionalInvoiceService,
    private proCheckoutService : ProCheckoutService , 
    private commonHelper: CommonHelper,
    private slugInterceptorService : SlugInterceptorService) { 
      this.dateFormat = this.commonHelper.domainViewTableDateFormat;
    }

  ngOnInit(): void {
    this.getInvoiceList();
  }

  /**
   * Retrieve Invoice List
   */
  public getInvoiceList(pageNo?: number) {
    if (pageNo === this.currentPage) return;
    if (pageNo) {
      this.currentPage = pageNo;
    }
    this.dataLoading = true;
    this.professionalInvoiceService.getInvoiceList('new',this.currentPage, this.recordsPerPage, this.listOrder, this.searchKeyword).subscribe({
      next: (response: APIResponseModel) => {
        this.invoiceList = response?.data?.data;
        this.paginationLinks = response?.data?.links;
        this.lastPage = response?.data?.last_page;
      },
      error: () => {
      },
      complete: () => {
        this.dataLoading = false;
      }
    })
  }


  /**
   * Handles the onClick event for paying now
   * @param invoiceDetails An object containing details of the invoice
   */
  public onClickPayNow(invoiceDetails:any){
    const {vault_count,invoice_number} = invoiceDetails
    const totalAmount = this.getTotalAmount(invoiceDetails);
    const payVaultRegistrationFeePerVault = this.getPayVaultRegistrationFeePerVault(invoiceDetails)
    this.proCheckoutService.proceedToVaultRegistrationPayment(payVaultRegistrationFeePerVault, vault_count,totalAmount).subscribe((response) => {
      void this.slugInterceptorService.navigate(['checkout'],{ queryParams: { invoice: invoice_number } },true);
    });
  }
  
  /**
   * Calculates the total amount based on the invoice details.
   * @param invoiceDetails An object containing invoice details.
   * @returns The total amount.
   */
  public getTotalAmount(invoiceDetails: any): number {
    return invoiceDetails["vault_amount"] || invoiceDetails["price_per_vault"] * invoiceDetails["vault_count"]
  }

  /**
   * Calculates the vault registration fee per vault based on the invoice details.
   * @param invoiceDetails An object containing invoice details.
   * @returns The vault registration fee per vault.
   */
  public getPayVaultRegistrationFeePerVault(invoiceDetails:any): number{
    return invoiceDetails["price_per_vault"] || (invoiceDetails["vault_amount"] / invoiceDetails["vault_count"])
  }
}
