import { Component, Input } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-file-downloader',
  templateUrl: './file-downloader.component.html',
})
/**
 * File downloader component
 */
export class FileDownloaderComponent  {
  @Input() downloadLink:string;
  @Input() fileName:string;
  /**
   * @constructor
   */
  constructor(
    private modalService:CommonModelService,
  ) { }

 

  /**
   * close modal
   */
  public closeModal():void {
    this.modalService.close('file-download-modal');
  }

  /**
   * Download file function
   */
  public downloadFile():void {
    saveAs(this.downloadLink, this.fileName);
    this.closeModal();
  }
}
