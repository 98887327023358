import { NgModule } from '@angular/core';
import { DateStringtoDateformatPipe } from './date-stringto-dateformat.pipe';
import { FileTypeIconsPipe } from './file-type-icons.pipe';
import { FilterPipe } from './filter.pipe';
import { GetCurrencySymbolPipe } from './get-currency-symbol.pipe';
import { InsuranceDateFilterPipe } from './insurance-date-filter.pipe';
import { LastSeenPipe } from './last-message-time.pipe';
import { SecondsTransformPipe } from './seconds-transform.pipe';
import { SlicePipe } from './slice.pipe';
import { SortDatePipe } from './sort-date.pipe';

@NgModule({
  imports: [],
  declarations: [FileTypeIconsPipe, InsuranceDateFilterPipe, DateStringtoDateformatPipe, SlicePipe, FilterPipe, LastSeenPipe, GetCurrencySymbolPipe, SecondsTransformPipe, SortDatePipe],
  exports: [FileTypeIconsPipe, InsuranceDateFilterPipe, DateStringtoDateformatPipe, SlicePipe, FilterPipe, LastSeenPipe, GetCurrencySymbolPipe, SecondsTransformPipe, SortDatePipe],
})
/**
 * pipe module
 */
export class PipeModule {

}
