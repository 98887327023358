<div class="scroll-hidden">
  <!-- Navbar -->
  <app-navbar *ngIf="this.userType === 'Consumer'"></app-navbar>
  <app-exe-cus-navbar *ngIf="this.userType === 'Custodian'"></app-exe-cus-navbar>
  <app-professional-navbar [showReturnToDashboard]="true"
    *ngIf="this.userType !== 'Consumer' && this.userType!=='Custodian'"></app-professional-navbar>


  <!-- Router outlet -->
  <div class="lg:grid grid-cols-12 background-color-grey">
    <!-- BEGIN: Leftside Content -->
    <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
      <div class="flex flex-col w-full">
        <div class="h-full items-center justify-center">
          <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
            <div class="h-full justify-center items-center ">
              <div class="grid grid-cols-12 items-center gap-4 my-4">
                <div class="col-span-12 sm:col-span-2">
                  <img src="assets/images/digital-files/digital-files.svg" class="w-20" alt="digital vault">
                </div>

                <div class="col-span-12 sm:col-span-10">
                  <div class="flex items-center gap-4">
                    <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
                      Digital Files
                      <a class="inline-block" (click)="openModal('digitalFiles')" title='Click here for help from Aida'>
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2"
                             fill="none"
                             stroke-linecap="round" stroke-linejoin="round"
                             class="css-i6dzq1n text-primary cursor-pointer">
                          <circle cx="12" cy="12" r="10"></circle>
                          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                          <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                      </a>
                    </h2>
                  </div>

                  <p class="text-sm text-grey tracking-wide text-regular">
                    Leaving photos and videos for your loved ones is the sweetest gift you can give. These memories will
                    be cherished forever.
                  </p>

                </div>
              </div>
              <!-- BEGIN: Leftside Card -->
              <div class="border-card bg-white rounded-md">
                <!-- BEGIN: Progress Bar -->
                <app-card-progress-bar [progressValue]="'w-full'"></app-card-progress-bar>
                <!-- END: Progress Bar -->

                <div class="p-6">
                  <div class="grid grid-cols-12 items-center gap-4 row-gap-4">
                    <div class="col-span-12">
                      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                        For your loved ones
                      </h4>
                      <p class="text-grey text-regular pt-1 text-sm">Upload Photos, Audios and Videos for your loved
                        ones to be cherished forever.
                      </p>
                      <p class="text-grey text-regular pt-1 text-sm">
                        <span class="font-sans font-bold">Accepted File Formats</span> — jpeg, jpg, png, doc, docx, txt,
                        pdf, mp3, mp4, avi,
                        mov, flv, mkv.
                      </p>
                      <p class="text-grey text-regular pt-1 text-sm">
                        <span class="font-sans font-bold">Maximum File Size</span> — 100 MB.
                      </p>
                      <app-payment-message-text [clientHasPaidSubscription]="clientHasPaidSubscription"></app-payment-message-text>
                    </div>
                  </div>

                  <div class="col-span-12 mt-4">
                    <app-file-listing
                      [fileArray]="digitalFilesArray"
                      [permissions]="permissions"
                      [isLoading]="preDefinedLoader"
                      [isDisabled]="!hasPaidSubscription && !clientHasPaidSubscription"
                      [hideDeleteIcon]="hideDeleteIcon"
                      [fileUploadSettings]="fileUploadSettings"
                      [fileUploadPercent]="filePercentage"
                      (fileListingEmitter)="fileListingListener($event)">
                    </app-file-listing>
                  </div>

                  <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
                    <button (click)="click('Back')"
                            class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                      <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault">
                      Back
                    </button>
                    <div *ngIf="!buttonProgress"
                         class="flex flex-col-reverse sm:flex-row justify-end items-center gap-4 w-full">
                      <button (click)="click('SaveForLater')"
                              [disabled]="preDefinedLoader || !canAccessForm || filePercentage!=0"
                              class="flex items-center justify-center border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 text-regular px-6">
                        <div class="loading-blue mr-2" *ngIf="preDefinedLoader"></div>
                        Save for Later
                      </button>
                      <button (click)="click('MarkComplete')"
                              [tooltip]="'You can update later should this information change'"
                              [disabled]="preDefinedLoader || !canAccessForm || filePercentage!=0"
                              class="flex items-center justify-center mark-complete-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 text-regular px-6">
                        <div class="loading-blue mr-2" *ngIf="preDefinedLoader"></div>
                        Mark as Complete
                      </button>
                    </div>
                    <div *ngIf="buttonProgress"
                         class="flex flex-col-reverse sm:flex-row justify-end items-center gap-4 w-full">
                      <button *ngIf="buttonProgress && this.userType === 'Consumer'"
                              (click)="click('ReturnToDashboard')" [disabled]="preDefinedLoader"
                              class="border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 focus:outline-none text-regular text-white px-6">
                        Return to Dashboard
                      </button>
                      <button (click)="click('NextSection')" [disabled]="preDefinedLoader"
                              class="text-sm tracking-wide w-full sm:w-auto bg-darkblue hover:underline-text rounded-xs h-10 focus:outline-none text-regular text-white px-6 text-bold">
                        Next Section
                      </button>
                    </div>
                  </div>
                </div>
                <!-- END: Leftside Card -->
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- END: Leftside Content -->

    <!-- BEGIN: Rightside Content -->
    <div class="col-span-12 md:col-span-6 lg:grid justify-center">
      <about-yourself-right-content [formInputValue]="{}"
                                    [otherValues]="sectionSaveExitOptions"></about-yourself-right-content>
    </div>
    <!-- END: Rightside Content -->
  </div>
</div>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>
<app-payment-status-modal></app-payment-status-modal>
<app-payment-success></app-payment-success>
<app-payment-failure></app-payment-failure>
