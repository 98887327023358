<app-common-modal id="add-prescription-modal" class="app-common-modal" [heightClass]="'min-h-75'">
  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
         (click)="closeModel()">
    <form [formGroup]="prescriptionForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Prescription Details
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">
            Add all your prescription details here.
          </p>
          <p class="text-regular font-medium text-xs mt-2 error-alert"
             *ngIf="(isNewAsset && !permissions?.['add']) || (!isNewAsset && !permissions?.['edit'])">
            You do not have permission to
            {{isNewAsset ? 'add information for this client.' : 'edit this client\'s information.'}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="prescription-name" class="text-label-grey text-sm text-regular">Prescription Name*</label>
          <input [attr.disabled]="!canEdit || undefined" type="text" minlength="3" maxlength="50" id="prescription-name"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 placeholder="Prescription Name" formControlName="prescription_name" name="prescription-name"
                 [ngClass]="formGet.prescription_name.errors && formGet.prescription_name.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('prescription_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="prescription_number" class="text-label-grey text-sm text-regular">Prescription Number</label>
          <input [attr.disabled]="!canEdit || undefined" type="text"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 placeholder="e.g. 026009593" formControlName="prescription_number" name="prescription_number"
                 id="prescription_number"
                 [ngClass]="formGet?.['prescription_number'].errors && formGet?.['prescription_number'].touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('prescription_number')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label [for]="'expiry_date'" class="text-label-grey text-sm text-regular">
            Expiry Date ( {{domainDateFormat}} )
          </label>
          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
            <app-datepicker-toggle
              inputID="expiry_date"
              [bsConfig]="bsConfig"
              [disabled]="!canEdit"
              [useFormControl]="formGet.expiry_date">
            </app-datepicker-toggle>
          </app-shimmer-loading>
          <p *ngFor="let error of getErrors('expiry_date')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="pharmacy-name" class="text-label-grey text-sm text-regular">Pharmacy Name</label>
          <input [attr.disabled]="!canEdit || undefined" type="text" minlength="3" maxlength="50" id="pharmacy-name"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 placeholder="e.g. Shoppers Drug Mart" formControlName="pharmacy_name" name="pharmacy-name"
                 [ngClass]="formGet.pharmacy_name.errors && formGet.pharmacy_name.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('pharmacy_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="phone-no" class="text-label-grey text-sm text-regular">Phone Number</label>
          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
            <app-phone-input [placeholder]="'e.g. 8665044754'" [phoneNumber]="prescriptionForm.value.phone"
                             [countryCode]="countryCode" [uniqueId]="'phone-no'"
                             [disabled]="!canEdit || !canAccessForm"
                             [errorClassTrigger]="formGet.phone.errors && formGet.phone.touched"
                             [form]="prescriptionForm" id="phone-no"
                             (onChange)="detectChangePhonenumber($event)"
                             (focusout)="formGet.phone.markAsTouched()">
            </app-phone-input>
          </app-shimmer-loading>
          <p *ngFor="let error of getErrors('phone')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Address
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">Make sure to enter Pharmacy's address
          </p>
        </div>
        <div class="col-span-12">
          <label for="country" class=" text-label-grey text-sm text-regular ">Country</label>
          <div class=" col-span-12 lg:col-span-2 relative">
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <app-country-search-component (optionChangeEmitter)="optionChangeListenerCountry($event)" id="country"
                                            [defaultSelected]="formGet?.country?.value"
                                            [disabled]="!canEdit || !canAccessForm"
                                            [enableError]="formGet.country.errors && formGet.country.touched"
                                            (focusout)="formGet.country.markAsTouched()"></app-country-search-component>
              <p *ngFor="let error of getErrors('country')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </app-shimmer-loading>
          </div>
        </div>
        <div class="col-span-12 relative move-map" #moveMapHere>
          <label for="street-add" class="text-label-grey text-sm text-regular">Street
            Address</label>
          <input [attr.disabled]="!canEdit || undefined" type="text" minlength="3" maxlength="500" id="street-add"
                 #search (keyup)="onAddressChange(search)"
                 (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="address" formControlName="address" placeholder="e.g Main St"
                 [ngClass]="formGet.address.errors && formGet.address.touched?'form-input-error':'form-input-focus'"
                 (focusout)="formGet.address.markAsTouched()">
          <p *ngFor="let error of getErrors('address')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="apt_number" class="text-label-grey text-sm text-regular">Apartment / Suite #</label>
          <input name="apt_number" id="apt_number" formControlName="apt_number" [attr.disabled]="!canEdit || undefined"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 [ngClass]="formGet.apt_number.errors && formGet.apt_number.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('apt_number')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12" *ngIf="selectedCountryName!==unitedKingdom">
          <label for="state-Province" class="text-label-grey text-sm text-regular">State/Province</label>
          <div class="col-span-12 lg:col-span-2 relative">
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <app-state-search-component [enableError]="formGet.state.errors && formGet.state.touched"
                                          [countryCode]="formGet?.country?.value" id="state-Province"
                                          [defaultSelected]="formGet?.state?.value"
                                          (optionChangeEmitter)="optionChangeListenerState($event)" tabindex="0"
                                          [disabled]="!canEdit || !canAccessForm"
                                          (focusout)="formGet.state.markAsTouched()">
              </app-state-search-component>
            </app-shimmer-loading>
            <p *ngFor="let error of getErrors('state')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <label for="city" class="text-label-grey text-sm text-regular">City</label>
          <div class="col-span-12 lg:col-span-2 relative">
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <app-city-search [stateCode]="formGet?.state?.value" id="city"
                               [enableError]="formGet.city.errors && formGet.city.touched"
                               [defaultSelected]="formGet?.city?.value"
                               (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0"
                               [disabled]="!canEdit || !canAccessForm"
                               (focusout)="markAsTouchedIndividual('city')">
              </app-city-search>
            </app-shimmer-loading>
            <p *ngFor="let error of getErrors('city')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </div>
        <div class=" col-span-12 md:col-span-6 ">
          <label for="zipcode" class=" text-label-grey text-sm text-regular ">Zip/Postal Code</label>
          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
            <input [attr.disabled]="!canEdit || undefined" type="text" id="zipcode"
                   (focusout)="markAsTouchedIndividual('zipcode')"
                   class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
                   placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                   name="zipcode" formControlName="zipcode" oninput="this.value = this.value.toUpperCase()"
                   (focus)="validateZipCode()"
                   [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
          </app-shimmer-loading>
          <p *ngFor="let error of getErrors('zipcode')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
      </div>
      <div class=" flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 ">
        <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewAsset" [buttonText]="'Remove Prescription'"
                           [valid]="!submitLoader && canEdit"
                           [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true"
                           (click)="deletePrescription()">

        </app-button-loader>
        <app-button-loader class="w-full sm:w-auto" [buttonText]="!isNewAsset?'Update Prescription':'Add Prescription'"
                           [valid]="((isNewAsset && permissions?.['add']) || (!isNewAsset && permissions?.['edit'])) && !submitLoaderDelete && canEdit"
                           [loader]="submitLoader" [buttonType]="'submit'">
        </app-button-loader>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>
