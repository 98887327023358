import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

  @Injectable({
    providedIn: 'root'
  })
  export class CommonFormControlService {

    markFormGroupTouched(formGroup: FormGroup) {
      Object.values(formGroup.controls).forEach(control => {
        if (control instanceof FormGroup) {
          this.markFormGroupTouched(control);
        } else {
          control.markAsTouched();
        }
      });
    }
  };

