import { Component, HostListener, OnInit } from '@angular/core';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { SlugService } from 'src/app/services/slug.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',

})
/**
 * Landing page for professional service
 */
export class LandingPageComponent implements OnInit {
  public mobile: boolean;
  public hideBanner: boolean;
  public innerWidth: number;
  public menuActive: string;
  public selectedType: string;
  public proTypes: Array<any>;
  public bLoadingProTypes: boolean = false;
  public professionalType: string;
  public vaultVideo: string;



  /**
 * constructor
 */
  constructor(
    private commonService: CommonService,
    private slugInterceptorService: SlugInterceptorService,
    private localStorageService: LocalStorageService,
    private peopleService: PeopleService,
    private commonHelper: CommonHelper,
    private slugService: SlugService,
    private modalService: CommonModelService


  ) { }


  /**
 * loaded initially
 */
  ngOnInit(): void {
    this.getProfessionalTypes();
    this.professionalType = this.localStorageService.getDataByKey('pro-type')?.[0]?.displayText;
    this.slugService.bannerObserve.subscribe((response: boolean) => {
      if (response) {
        this.hideBanner = response;
      }
    });
    this.selectedType = this.localStorageService.getDataByKey('pro-type');
    if (this.selectedType) {
      this.hideBanner = true;
    }
    this.onResize();
  }
  /**
   * window resize
   * @param {any} event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.innerWidth = window.innerWidth;

    this.mobile = this.innerWidth <= 767; // 768px portrait
  }

  /**
   * @param{string}url
   * on menu change
   */
  public menuChange(url?: string) {
    if (url) {
      this.menuActive = url;
      void this.slugInterceptorService.navigate([url], null, true);
      this.commonService.setActiveNavbarTrigger(this.menuActive);
    }
  }

  /**
   * on navigating to selected professional type's landing page
   */
  public onNavigation() {
    const seletedProType = this.proTypes.filter((cur) => cur.displayText === this.selectedType);
    this.localStorageService.storeData('pro-type', seletedProType);
    if (this.selectedType) {
      if (this.selectedType === 'Human Resources') {
        this.localStorageService.clearOrgData();
      }
      this.hideBanner = true;
      void this.slugInterceptorService.navigate(['home'], null, true);
      // this.slugService.setBanner(true);
    }
  }

  /**
   * get advisor types
   */
  public getProfessionalTypes() {
    this.bLoadingProTypes = true;
    this.peopleService.getAdvisorTypes()?.subscribe((response: APIResponseModel) => {
      if (response.status) {
        const order = ['Wealth Management', 'Human Resources', 'Law Firm', 'Funeral Home'];
        response.data.sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
        this.proTypes = this.commonHelper.convertToOptionsFormat(response?.data, 'id', 'name');
        
        //PRO - Landing Page - Refactor - Wealth Management by DEFAULT
        this.selectedType = 'Wealth Management';
        this.onNavigation();
      }
    }, (e) => this.commonHelper.httpResponseHandler(e.error),
      () => this.bLoadingProTypes = false);
  }

     /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');
  }
}
