import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';
import { SlugInterceptorService } from './slug-interceptor.service';

@Injectable({
  providedIn: 'root',
})

/**
 * guard to block page access if request stage is than 9 (request_stage <9 indicates that Personal Details Section is not completed)
 * and user is on refund period
 */
export class PageGuard implements CanActivate {
  /**
   * constructor
   */
  constructor(
    private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService,
  ) {

  }
  /**
   * @method canActivate
   * @description method to check if router can render or not
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return {Boolean}
   */
  canActivate(): boolean {
    const currentUser = this.localStorageService.getUserData();
    const requestStage = currentUser?.request?.request_stage;
    const isUserOnRefundPeriod = false;
    if (requestStage >= 9 && !isUserOnRefundPeriod) {
      return true;
    }
    this.slugInterceptorService.navigate(['dashboard']);
    return false;
  }
}
