<div #carouselRef class="h-full carousel-container"
     appSwipe (onswipe)="onSwipe($event)">
  <!-- Parent html components here -->
  <ng-content></ng-content>
</div>

<div *ngIf="!hideDots && ( !landingPage || (landingPage && carouselCount > 1) )"
     class="flex justify-center items-center"
     [ngClass]="{
      'absolute w-full top-100' : !landingPage,
      'mt-5': landingPage && carouselCount > 1
      }">
  <div *ngFor="let _ of [].constructor(carouselItems?.length); let indexValue = index" class="flex flex-col">
    <input type="radio" name="selectedCarousel" class="z-10" [ngClass]="(indexValue % 2 === 0) ? 'mx-2' : ''"
           (click)="changeCarousel(indexValue)" [value]="indexValue" [(ngModel)]="currentlySelectedCarousel"/>
  </div>
</div>

<div *ngIf="!hideNav && carouselCount > 1"
  class="flex justify-between items-center lg:justify-center absolute left-0 w-full carousel-nav-bar" >
    <button (click)="lastCarousel()" class="nav-btn prev"></button>
    <button (click)="nextCarousel()" class="nav-btn next"></button>
</div>
