import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { ProfessionalPermissionService } from 'src/app/professional-dashboard/services/professional-permission.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { SECTIONS, SectionStatus, USER_TYPES } from '../../../constants/application.const';


@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
})
/**
 * Insurance details component
 */
export class InsuranceComponent implements OnInit {
  public editData: any;
  public preDefinedLoader: boolean;
  public insuranceArray: Array<any>;
  public sectionSaveExitOptions: any;
  public userType: string;
  public clientRequestId: string;
  public addInsuranceKey: boolean = true;
  public beneficiariesCount: number;
  public $overallBeneficiaryArray: Array<any>;
  public permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  public isNewAsset: boolean;
  public vaultVideo: string;

  /**
   * True signifies hiding the 'save' buttons and showing the 'progress' buttons
   */
  public buttonProgress: boolean = false;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public userRequestID: string;
  // new beneficiary added from primary/secondary percentage list modal
  public newBeneficiaryList: boolean;

  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private personalDetailsService: PersonalDetailsService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private commonHelper: CommonHelper,
    private proPermissionService: ProfessionalPermissionService,
    private toastrService: ToastrService,
    private slugInterceptorService: SlugInterceptorService,
    private peopleService: PeopleService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.beneficiariesCount = 0;
    this.sectionSaveExitOptions = {
      section: 5,
      type: 1,
    };
    this.insuranceArray = [];
    this.clientRequestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.userRequestID = this.commonHelper.getRequestId(this.route);
    this.getPersonalDetails();
    this.getAccessControl();
    if (this.clientRequestId) {
      this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.insurance, SectionStatus.INCOMPLETE).subscribe();
    }
    // refresh beneficiary
    this.peopleService.refreshDetailsCommonObserve.subscribe((response: any) => {// listen new beneficiary added
      if (response) {
        this.newBeneficiaryList = response; // new beneficiary - input to add insurance modal
        // this.getPersonalDetails();
      }
    });
  }

  /**
   * Establish ACL Permissions. Consumer is assigned full access.
   */
  public getAccessControl(): void {
    if (this.userType !== 'Consumer') {
      let getACLService;
      const requestId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('request_user_id') : this.userRequestID;
      const userId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('user_id') : this.localStorageService.getDataByKey('agent_id') || this.localStorageService.getUserData(USER_TYPES.pro)?.user?.id;
      getACLService = this.proPermissionService.getAccessControl(userId, requestId, ['Insurance']);
      getACLService.subscribe(
        (permissions) => this.permissions = permissions?.['Insurance'],
        (exception: any) => (this.commonHelper.httpResponseHandler(exception?.error)));
    } else {
      this.permissions = {
        view: true,
        add: true,
        edit: true,
        delete: true,
      };
    }
  }

  /**
   * Get insurance types
   */
  // public getInsurancePolicyTypes():void {
  //   forkJoin(
  //       [this.commonService.getInsuranceTypes(), this.commonService.getPolicyTypes()],
  //   ).subscribe((arrayResponse:Array<API_RESPONSE_INTERFACE>)=>{
  //     const responseStatus = arrayResponse.map((d)=>d.status).reduce((a, b)=>(a&&b));
  //     if (responseStatus) {
  //       // console.log(arrayResponse);
  //       this.insuranceType = this.commonHelper.convertToOptionsFormat(arrayResponse[0]?.data, 'id', 'name');
  //       // this.policyTypes = this.commonHelper.convertToOptionsFormat(arrayResponse[1]?.data, 'id', 'name');
  //     }
  //     // this.resetForm();
  //   });
  //   // console.log('it',this.insuranceType);
  // }

  /**
   * Get personal details
   */
  public getPersonalDetails(): void {
    this.preDefinedLoader = true;
    // get people list
    this.personalDetailsService.getPersonalDetailsWithBeneficiary(this.userType !== 'Consumer' ? this.clientRequestId : null).subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.insuranceArray = response.data.user_insurance;
        this.beneficiariesCount = response.data?.user_people?.filter((user) => user.beneficiary === 1).length;
        this.$overallBeneficiaryArray = response.data?.user_people?.filter((user) => user.beneficiary === 1);
      }
    }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error), () => this.preDefinedLoader = false);
  }

  /**
   * toggle add insurance modal
   *
   * @param {any} editValue
   */
  public toggleModal(editValue?: any): void {
    const bEdit = this.permissions?.['view'] || this.permissions?.['add'] || this.permissions?.['edit'] || this.permissions?.['delete'];

    if (bEdit) {
      this.addInsuranceKey = !this.addInsuranceKey;
      this.editData = editValue ? { ...editValue } : {};
      this.isNewAsset = editValue == null;
      this.modalService.open('add-insurance-modal');
    } else {
      this.toastrService.info('You do not have permission to access this information.');
    }
  }

  /**
   * Modal event listner
   *
   * @param {any} data
   */
  public modalEventListener(data: any): void {
    this.getPersonalDetails();
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }

  /**
   * Button click handler
   * @param command
   */
  public click(command: string) {
    const isPro = this.userType !== 'Consumer';
    switch (command) {
      case 'Back':
        const back_url = isPro ? ['overview', 'insurance-overview']:['advisors', 'manage-advisors'];
        if (isPro) {
          back_url.push(this.clientRequestId);
        }
        void this.slugInterceptorService.navigate(back_url);
        break;
      case 'ReturnToDashboard':
        const return_url = isPro ? ['overview', 'insurance-overview'] : ['dashboard'];
        void this.slugInterceptorService.navigate(return_url);
        break;
      case 'MarkComplete':
        this.buttonProgress = true;
        this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.insurance, SectionStatus.COMPLETE).subscribe();
        break;
      case 'SaveForLater':
        this.buttonProgress = true;
        break;
      case 'NextSection':
        const url = ['assets', 'financial-assets'];
        if (isPro) {
          url.push(this.clientRequestId);
        }
        void this.slugInterceptorService.navigate(url);
        break;
    }
  }

  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');

  }
}
