<app-common-modal id="id-verify-options-modal" class="app-common-modal" [heightClass]="'h-ahuto'">

  <img src="assets/images/about-yourself/close.svg"
    class="absolute right-2 w-8  -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
    (click)="closeModal()">
  <div class="mb-4">
    <div>
      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
        Choose Your Verification Preferences
      </h4>
      <p class="text-grey text-regular pt-1 text-sm">Select your preferred method </p>
    </div>
  </div>
  <form [formGroup]="idVerifyOptionsForm" autocomplete="off" (ngSubmit)="onSubmit()">
    <div class="mb-4">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
        <div class="col-span-12">
          <label for="information_type" class="text-grey text-lg text-regular">How would you like to receive the
            verification link?</label>
          <div class="col-span-12">
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <div class="flex flex-col sm:flex-row gap-2 sm:gap-4 sm:items-center">
                <div class="flex items-center" *ngFor="let infoType of informationTypeOptions">
                  <input id="radio-{{infoType?.['displayText']}}" type="radio" [value]="infoType?.['value']"
                    name="information_type" formControlName="information_type"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 input-accent">
                  <label for="radio-{{infoType?.['displayText']}}"
                    class="ml-2 home-banner__content text-base xl:text-md text-gray-700">{{infoType?.['displayText']}}</label>
                </div>
              </div>
            </app-shimmer-loading>
          </div>
        </div>

        <div class="col-span-12">
          <label for="link_type" class="text-grey text-lg text-regular">How would you prefer to complete the
            verification process?</label>
          <div class="col-span-12 lg:col-span-2 relative">
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <div class="lex flex-col sm:flex-row gap-4 sm:items-center">
                <div class="flex items-center" *ngFor="let verifyOption of verificationAppOptions">
                  <input id="radio-{{verifyOption?.['displayText']}}" type="radio" [value]="verifyOption?.['value']"
                    name="link_type" formControlName="link_type"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 input-accent">
                  <label for="radio-{{verifyOption?.['displayText']}}"
                    class="ml-2 home-banner__content text-base xl:text-md text-gray-700">{{verifyOption?.['displayText']}}</label>
                </div>
              </div>
            </app-shimmer-loading>
          </div>
        </div>
      </div>
      <div>
        <div class="flex sm:flex-row float-right mt-4 cursor-pointer">
          <app-button-loader [buttonText]="'Submit'" [valid]="idVerifyOptionsForm?.valid" [loader]="submitLoader"
            [buttonType]="'submit'"></app-button-loader>
        </div>
      </div>
    </div>
  </form>
</app-common-modal>