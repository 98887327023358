
<div class="border-card bg-white rounded-md p-6 pb-14" *ngIf="isDashboardPage">
  <div class="flex justify-between">
    <div>
      <h4 class="text-semibold text-grey text-md md:text-lg lg:text-xl xl:text-2xl">
        Notifications
      </h4>
      <p class="text-grey text-regular text-sm md:text-md lg:text-md">
        Notifications will be shown here
      </p>
    </div>
  </div>
  <!--Notifications -->
  <div class="mt-5" *ngIf="NotificationData?.length>0">
    <div class="border px-4 py-3 rounded relative mb-3 mt-1"
      [ngClass]="(!expiry['iconType']=='expiryCall')?'text-red-600 border-red-300':'text-blue-900 border-blue-300'"
      *ngFor="let expiry of NotificationData | insuranceDateFilter:10" role="alert">
      <div *ngIf="expiry['isInsurance']">
        <span class="px-5 text-2xl">

          <fa-icon [icon]="faInfoCircle"></fa-icon>

        </span>
        <strong class="font-bold">{{expiry["request_id"]}}</strong>
        <span class="block sm:inline px-10">{{expiry['insurance_type']}}
          - {{expiry.message}} {{expiry.date}}</span>
      </div>
      <div *ngIf="!expiry['isInsurance']">
        <span class="px-5 text-2xl">
          <fa-icon [icon]="faPhoneAlt" *ngIf="expiry['iconType']=='expiryCall'"></fa-icon>
          <fa-icon [icon]="faInfoCircle" *ngIf="expiry['iconType']!=='expiryCall'"></fa-icon>
        </span>
        <strong class="font-bold">{{expiry["request_id"]}}</strong>
        <span class="block sm:inline px-10">{{expiry.message}} {{expiry.date}}</span>
        <div class="absolute right-2 bottom-2">
          <a *ngIf="expiry?.meeting_link !==null" href="{{expiry.meeting_link}}" target="_blank"
            class="text-green-500 hover:text-blue-700 underline text-md">Join Zoom Meeting</a>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div *ngIf="loader">
    <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
      <h4 class="text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold"></h4>
    </app-shimmer-loading>
  </div>
  </div>
  <div class="flex justify-center mt-5 bg-card-overview border-card-grey rounded"
    *ngIf="NotificationData?.length===0 && !loader">
    <h4 class="text-base text-regular tracking-wide text-semibold  text-center my-4">
      No Data to Display
    </h4>
  </div>
  <div class="mb-2 absolute flex flex-row" *ngIf="NotificationData?.length>10">
    <button
      class="button button--lg bg-darkblue px-5 w-full rounded-xs h-10 focus:outline-none text-regular text-white text-sm flex flex-row justify-center items-center"
      (click)="changeRoute('dashboard/pro-notifications')">
      Show more

    </button>
  </div>
</div>



<div class="scroll-hidden box-border body-content pb-12 mb-8" *ngIf="!isDashboardPage">
  <!--first Section start-->
  <div class="pt-12">
    <div class="flex flex-wrap lg:mx-8 mx-6 ">
      <div class="w-full">
        <div class="flex items-center">
          <img src="assets/images/about-yourself/back-arrow.svg" alt="digital vault" class="cursor-pointer"
            (click)="changeRoute('dashboard')">
          <h2 class="text-darkblue text-md lg:text-2xl capitalize pl-5 text-semibold">
            Notifications
          </h2>
        </div>
      </div>
    </div>
  </div>
  <!--end-->

  <div class="scroll-hidden box-border body-content">

    <!--first Section start-->
    <div class="mx-8">
      <div class="pt-4 mb-6">
        <div class="border-card bg-white rounded-md p-6 ">
          <div class="mt-5">
            <div>
              <table class="table w-full text-left" aria-describedby="client">
                <thead class="bg-gray-400 text-blue text-md text-regular ">
                  <tr>
                    <th class="p-4" scope="row">Vault id</th>
                    <th class="p-4 whitespace-normal" scope="row">Notification Message</th>
                    <th class="p-4" scope="row">Date</th>
                    <th class="p-4" scope="row">Meeting Link</th>
                  </tr>
                </thead>
                <ng-container>
                  <tbody *ngFor="let expiry of  NotificationData | insuranceDateFilter | paginate
                    : {
                        itemsPerPage: tableSize,
                        currentPage: page,
                        totalItems: count
                      };let i = index">

                       <tr class="border-b-2 border-fuchsia-600 p-4 relative" *ngIf="NotificationData.length > 0">
                        <td class="p-4">
                          <p class="text-grey text-sm lg:text-lg text-regular">
                            {{expiry.request_id}}</p>
                        </td>
                        <td class="p-4 whitespace-normal">
                          <a *ngIf="expiry['isInsurance']"
                          class="text-blue-900 text-semibold text-sm text-xs md:text-sm lg:text-xl ">
                          {{expiry['insurance_type']}} - {{expiry.message}}</a>
                          <a *ngIf="!expiry['isInsurance']"
                            class="text-blue-900 text-semibold text-sm text-xs md:text-sm lg:text-xl ">
                            {{expiry.message}}</a>

                        </td>
                        <td class="p-4">
                          <p class="text-grey text-sm lg:text-lg text-regular">
                            {{expiry.date | dateStringtoDateformat}}</p>
                        </td>
                        <td class="p-4">
                          <a *ngIf="expiry?.meeting_link !==null" href="{{expiry.meeting_link}}" target="_blank" class="text-green-500 hover:text-blue-700 underline text-md">Join Zoom Meeting</a>
                        </td>
                       </tr>
                       </tbody>
                      </ng-container>
                     </table>
                <div *ngIf="loader" class="w-full">
                  <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                    <h4 class="text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold"></h4>
                  </app-shimmer-loading>
                </div>
                <div class=" flex justify-center mt-5" *ngIf="NotificationData?.length === 0 && !loader">
                  <h4 class="text-grey text-base lg:text-md text-regular">
                    No data found
                  </h4>
                </div>
                <div class="flex justify-center mt-5">
                  <pagination-controls
                  previousLabel=""
                  nextLabel=""
                  (pageChange)="onTableDataChange($event)">

                </pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
