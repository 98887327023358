<app-carousal>
  <div>
    <img alt="The-Estate-Registry" class="w-5/12 mx-auto object-contain" [src]="'assets/images/loginCarousal/img1.png'">
    <h2 class="text-darkblue text-regular font-semibold text-center text-3xl leading-tight pt-10 pb-4">
      Securely Stored on Blockchain
    </h2>
    <p class="text-regular text-grey text-base w-8/12 mx-auto text-center">With so much of your
      private, personal, and sensitive information being stored and transmitted online, tight
      security and privacy are now necessities for everyone.</p>
  </div>
  <!-- END: First Slide -->

  <!-- BEGIN: Second Slide -->
  <div>
    <img alt="The-Estate-Registry" class="w-5/12 mx-auto object-contain" [src]="'assets/images/loginCarousal/img2.png'">
    <h2 class="text-darkblue text-regular font-semibold text-center text-3xl leading-tight pt-10 pb-4">
      Credential Manager Extension
    </h2>
    <p class="text-regular text-grey text-base w-8/12 mx-auto text-center">With so much of your
      private, personal, and sensitive information being
      stored and transmitted online, tight security and privacy are now
      necessities for everyone.</p>
  </div>
  <!-- END: Second Slide -->

  <!-- BEGIN: Thirs Slide -->
  <div>
    <img alt="The-Estate-Registry" class="w-5/12 mx-auto object-contain" [src]="'assets/images/loginCarousal/img3.png'">
    <h2 class="text-darkblue text-regular font-semibold text-center text-3xl leading-tight pt-10 pb-4">
      Our Own Secure SayfData
    </h2>
    <p class="text-regular text-grey text-base w-8/12 mx-auto text-center"> A secure estate digital vault that uses
      Blockchain technology to ensure
      all consumer data is reliably & securely stored in our central
      database. We call this SayfData®.</p>
  </div>
  <!-- END: First Slide -->
</app-carousal>
