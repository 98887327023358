<app-common-modal id="subscription-plan" class="app-common-modal">
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">
    <div class="mb-4">
      <h3 class="text-black text-md lg:text-xl text-normal">Update Plan</h3>
      <p class="text-md text-grey py-1 tracking-wide text-regular">
        Update Maintain Digital Vault Plan according to your will
      </p>
    </div>
    <div class="w-full" (click)="setPlan('Yearly')">
      <div [ngClass]="{'bg-gray-300': subscriptionType == 'Yearly'}"
        class="flex items-center justify-between bg-white border-2 rounded-md border-grey-600 p-4 mb-8 hover:bg-gray-300 hover:border-1 hover:border-blue-400 active:bg-gray-300">
        <div>
          <h3 class="text-darkblue text-lg text-semibold">Pay Yearly</h3>
          <p *ngIf="subscriptionDetails?.name == 'Yearly'" class="text-md text-grey py-1 tracking-wide text-regular">
            Your subscription renews on {{subscriptionEndDate}} </p>
        </div>
        <div>
          <p class="text-lg text-darkblue py-2 tracking-wide font-semibold text-regular">
            ${{yearlyPackageDetails?.['price']}} <span class="text-grey text-sm font-light">Year</span></p>
        </div>
      </div>
    </div>
    <div class="my-4 ">
      <h3 class="text-black text-md lg:text-xl text-normal mb-4">Current Plan</h3>
    </div>
    <div class="w-full mb-6" (click)="setPlan('Monthly')">
      <div [ngClass]="{'bg-gray-300': subscriptionType == 'Monthly'}"
        class="flex items-center justify-between bg-white border-2 rounded-md border-grey-600 p-4 mb-8 hover:bg-gray-300 hover:border-1 hover:border-blue-400 active:bg-gray-300">
        <div>
          <h3 class="text-darkblue text-lg text-semibold">Pay Monthly</h3>
          <p class="text-md text-grey py-1 tracking-wide text-regular">
            Your subscription renews on {{subscriptionEndDate}}</p>
        </div>
        <div>
          <p class="text-lg text-darkblue py-2 tracking-wide font-semibold text-regular">
            ${{monthlyPackageDetails?.['price']}} <span class="text-grey text-sm font-light">Month</span></p>
        </div>
      </div>
    </div>
    <div class="w-full">
      <div class="flex items-center justify-between">
        <a class="flex items-center justify-center px-5 py-2 border rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn-one text-center cursor-pointer hover:underline"
          (click)="closeModal()">
          Exit
        </a>
        <div class="flex">
          <app-button-loader class="sm:w-auto" (click)="openConfirmationModal()" [buttonText]="'Cancel Plan '"
            [loader]="cancelSubscriptionLoader" [buttonType]="'submit'">
          </app-button-loader>
          <app-button-loader class="sm:w-auto ml-2" [valid]="updatePlanButton" [buttonText]="'Update Plan '"
            [buttonType]="'submit'" (click)="clickUpdatePlan()">
          </app-button-loader>
          <!-- <button type="button"
            class="flex items-center justify-center px-5 py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold text-center cursor-pointer mx-1 hover:underline"
            [ngClass]="updatePlanButton ? 'bg-nav-btn' : 'bg-gray-300 text-white'" (click)="clickUpdatePlan()">
            Update Plan
          </button> -->
        </div>
      </div>
    </div>
  </div>
</app-common-modal>

<app-message-modal [id]="messageModalID" [setMessageData]="!isAlertShowed?alertData: messageData"
  (buttonEmitter)="messageModalListener($event)" [loader]="cancelSubscriptionLoader"></app-message-modal>