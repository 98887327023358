import { Location } from '@angular/common';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faAsterisk, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ICountryCode, SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { AgentsService } from 'src/app/professional-dashboard/services/agents.service';
import { ClientsService } from 'src/app/professional-dashboard/services/clients.service';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { CommonFormControlService } from 'src/app/services/common-form-control.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import {
  ConsumerPaymentMethod,
  EMAIL_PATTERN,
  NAME_PATTERN,
  ONBOARD_TYPES,
  OnboardType,
  PAYMENT_TYPES,
  PROFESSIONAL_ROLE,
  SCHEDULE_REMINDER,
  SSN_MASK,
  UNITED_KINGDOM,
  USER_TYPES
} from 'src/constants/application.const';
import { ELEMENT_EVENTS, STATUS_CHECK } from 'src/constants/form.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../../../helper/validation.helper';
import { LocalStorageService } from '../../../../../services/local-storage.service';


@Component({
  selector: 'app-add-professional-client',
  templateUrl: './add-professional-client.component.html',
  styleUrls: ['./add-professional-client.component.css'],
})
/**
 * add client class
 */
export class AddProfessionalClientComponent implements OnInit {
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;

  public clientForm: FormGroup;
  public preDefinedLoader: boolean;
  public currentDateRestriction: string;
  public SSN_MASK: string;
  public userType: string;
  public asterisk: any;
  public countryCode: ICountryCode;
  public loginLoader: boolean;
  public faEye: any;
  public faEyeSlash: any;
  public toggleSSNView: boolean;

  public locale = 'en';
  public minDate: Date;
  public maxDate: Date;
  public showSSN: boolean;
  public showSSNNumber: any;
  public defaultCountry: number;
  public agentList: Array<any>;
  public selectedCountry: number;
  public selectedCountryName: string = '';
  public bsConfig: Partial<BsDatepickerConfig>;
  public unitedKingdom: string = UNITED_KINGDOM;
  public scheduleReminder = SCHEDULE_REMINDER;
  public selectedSchedule = 'quarterly';
  public agentTerm: string;
  public advisorTerm: string;
  public domainDateFormat: string = this.commonHelper.domainDateFormat;
  public isUniqueSSN: boolean = false;
  public personId: string = '';
  public validatingSSN: number = ssnValidationProcess.initial;
  public readonly PROFESSIONAL_ROLE = PROFESSIONAL_ROLE;
  public readonly paymentTypes = PAYMENT_TYPES;
  public selectedPaymentType: any;
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;
  public subscriptionAmount: number;

  public selectedOnboardType: any;
  public readonly onboardTypes = ONBOARD_TYPES;

  public generatedUrl: string;
  public submitDisabled: boolean = false;

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientsService,
    private commonHelper: CommonHelper,
    private validation: ValidationHelper,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    private professionalUserService: ProfessionalUserService,
    private localeService: BsLocaleService,
    private agentService: AgentsService,
    private commonService: CommonService,
    private addressHelper: AddressHelper,
    private slugInterceptorService: SlugInterceptorService,
    private ngZone: NgZone,
    private commonFormControlService: CommonFormControlService,
    private location: Location,
    private modalService: CommonModelService
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
    this.minDate = new Date(1900, 0, 1);
    this.minDate.setDate(this.minDate.getDate());
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.advisorTerm = this.professionalUserService.getAdvisorTerm();
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.subscriptionAmount = Number(this.localStorageService.getDataByKey('admin_settings').subscription_price);
    this.selectedCountry = environment.APP_DOMAIN;
    this.selectedPaymentType = ConsumerPaymentMethod.PAID_BY_PROFESSIONAL;
    this.selectedOnboardType = OnboardType.VIA_EMAIL;
    this.checkCardsAdded();
    this.faEye = faEye;
    this.faEyeSlash = faEyeSlash;
    this.toggleSSNView = false;
    this.userType = this.localStorageService.getDataByKey('role');
    this.asterisk = faAsterisk;
    this.getAgents();
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    this.SSN_MASK = SSN_MASK;
    this.showSSN = environment.APP_DOMAIN == 1;
    this.defaultCountry =
      this.localStorageService.getDataByKey('country');
    // If search element not loaded initially
    setTimeout(() => {
      this.initMap(this.defaultCountry);
    }, 0);
    this.currentDateRestriction =
      this.commonHelper.getCurrentDateForRestriction();

    this.showSSNNumber = false;
    // initiating form
    this.clientForm = this.formBuilder.group({

      first_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN)] }],
      middle_name: ['', { updateOn: 'blur', validators: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN)] }],
      last_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN)] }],
      // dob: ['', { updateOn: 'blur', validators: [Validators.required, this.dateValidator.bind(this)] }],
      // ssn: ['', { updateOn: 'blur', validators: [Validators.required, this.validation.fn.SSNPatternValidator(SSN_PATTERN)] }],
      email: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(EMAIL_PATTERN)] }],
      // phone: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(PHONE_PATTERN)] }],
      // agent_id: [''],
      // address: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(255)] }],
      // city: ['', { updateOn: 'blur', validators: [Validators.required] }],
      // country: ['', { updateOn: 'blur', validators: [Validators.required] }],
      // state: [''],
      // zipcode: [
      //   '',
      //   {
      //     updateOn: 'blur', validators: [
      //       Validators.required,
      //       Validators.maxLength(10),
      //     ],
      //   },
      // ],
      // schedule_remainder_type: [''],
      // payment_type: ['', { updateOn: 'blur', validators: [Validators.required] }],
      // pay_amount: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS),this.commonHelper.subscriptionAmountValidator.bind(this)] }]
      onboard_via_type: [this.selectedOnboardType, { updateOn: 'blur', validators: [Validators.required] }]
    });
    Object.keys(this.clientForm.controls).forEach(controlName => {
      this.clientForm.get(controlName)?.valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.clientForm.get(controlName))
      );
    });
    this.validation.setValidationErrors({
      dob: { dateInvalid: null, isNotAllowed: 'Must be at least 18 years of age', invalidYear: 'Invalid Date' },
      ssn: { pattern: 'Invalid SSN', mask: null },
      phone: { pattern: 'Please enter a valid phone number' },
      email: { pattern: 'Please enter a valid email address.' },
      zipcode: { pattern: 'Please enter a valid zipcode' },
      // pay_amount: {amountBelowMinimum: 'Please ensure the amount is at least $0.50. Please adjust as necessary.', subscriptionAmountExceeded: this.subscriptionAmount ? `Please ensure the subscription amount does not exceed $${this.subscriptionAmount}.` : 'Please enter a valid amount'}
    });
    this.bsConfig = {
      showWeekNumbers: false,
      showTodayButton: true,
      showClearButton: true,
      minDate: this.minDate,
      maxDate: this.maxDate,
      dateInputFormat: this.domainDateFormat,
    };
    // this.updateSubscriptionAmountValidations();
    this.resetForm();
    // Set validator if domain country is not UK
    // this.isEnableState();
    // if (!this.showSSN) {
    //   this.clientForm.get('ssn').clearValidators();
    // } else {
    //   this.clientForm
    //     .get('ssn')
    //     .addValidators([Validators.required, this.validation.fn.SSNPatternValidator(SSN_PATTERN)]);
    // }

    // Salutation options
    this.clientForm.patchValue({
      country: this.defaultCountry,
    });
    this.applyLocale();
    // if (this.userType === this.PROFESSIONAL_ROLE.manager || this.userType === this.PROFESSIONAL_ROLE.professional) {
    //   this.clientForm.get('agent_id').setValidators([Validators.required]);
    //   this.clientForm.get('agent_id').updateValueAndValidity();
    // }
  }

  /**
  * See {@link ValidationHelper.getErrors}
  * @param controlsKey
  */
  public getErrors(controlsKey: string, checkTouched?): Array<string> {
    return this.validation.getErrors(controlsKey, this.clientForm, checkTouched);
  }
  /**
   * get all agents
   */
  public getAgents() {
    this.agentService.getAllAgents().subscribe(
      (response) => {
        this.agentList = this.commonHelper.convertToOptionsFormat(
          response.data,
          'id',
          'full_name',
        );
        //If logged-in user is a professional or manager ,add the professional user to the advisors list
        const isProfessionalAsAgent: boolean = this.userType === this.PROFESSIONAL_ROLE.professional || this.userType === this.PROFESSIONAL_ROLE.manager;
        if (isProfessionalAsAgent) {
          this.agentList.push({
            displayText: (this.localStorageService.getUserData(USER_TYPES.pro))?.user.first_name,
            value: ((this.localStorageService.getUserData(USER_TYPES.pro))?.user.id
            )
          })
        }
      },

      (exception: any) => {
        this.loginLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      },
    );
  }

  /**
   * get Age
   * @param {string}date
   * @return {number}
   */
  public getAge(date: string) {
    return this.commonHelper.getAge(date);
  }

  /**
   * Detect change on phone number component
   *
   * @param {any} inputObject
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }

  /**
   * dob validation
   * @param {FormControl}control
   * @return {boolean}
   */
  dateValidator(control: FormControl): { [s: string]: boolean } {
    const selectedDate = new Date(control.value);
    const age = this.getAge(control.value);
    if (selectedDate) {
      // Check if the year is greater than or equal to 1900
      const year = selectedDate.getFullYear();
      if (year < 1900) {
        return { invalidYear: true };
      }
    }
    if (age < 18) {
      return { isNotAllowed: true };
    } else {
      return null;
    }
  }

  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }

  /**
   * Checks if cards are added by calling the commonHelper's isCardsAdded method.
   * Updates the cardsAdded property based on the API response length.
   * If no cards are added and the payment type is PayVault, navigates back to the previous location.
   * 
   * @returns {Promise<void>} A promise that resolves when the check is complete.
   */
  public async checkCardsAdded() {
    const cardsAdded = await this.commonHelper.isCardsAdded();
    const isPayVaultRegistration = (this.localStorageService.getDataByKey('proPaymentType') === 1);
    if (!cardsAdded && isPayVaultRegistration) {
      this.location.back();
    }
  }


  /**
   * form object getter for validation and showing errors in html
   */
  public get formGet() {
    return this.clientForm.controls;
  }

  /**
   * Handle on submit
   */
  public onSubmit() {
    this.commonFormControlService.markFormGroupTouched(this.clientForm);
    if (this.clientForm.invalid) return;
    // this.checkIsSsnUnique(this.clientForm.controls.ssn.value, this.personId); // Validation hided, it already performs when SSN input enters.
    // if (!this.isUniqueSSN) {
    //   return;
    // }
    this.loginLoader = true;
    // this.clientForm.value.dob = this.commonHelper.formatDate(this.clientForm.value.dob);
    const payLoad = {
      ...this.clientForm.value,
      // country_code: this.countryCode.dialCode,
      // flag_code: this.countryCode.code,
      // is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
      // schedule_remainder_type: this.selectedSchedule,
      reset_password: this.selectedOnboardType === OnboardType.VIA_EMAIL ? 1 : 0,
    };
    const formData = new FormData();
    Object.keys(payLoad).forEach((key) => {
      if (payLoad[key] == undefined) return;
      formData.append(key, payLoad[key]);
    });
    this.clientService.addClient(formData).subscribe({
      next: (response: APIResponseModel) => {
        this.toastr.success('Client Created Successfully');
        this.loginLoader = false;
        if (payLoad['onboard_via_type'] == OnboardType.BOTH || payLoad['onboard_via_type'] == OnboardType.VIA_EMAIL) {
          setTimeout(()=>{this.toastr.success('Email sent with the invitation link');}, 2000) 
        }
        if (payLoad['onboard_via_type'] != OnboardType.VIA_EMAIL) {
          this.generatedUrl = response?.data?.url
          this.submitDisabled = true;
          if (this.selectedOnboardType !== '1' && this.generatedUrl) {
            this.modalService.open('generated-onboarding-link-modal');
          }
          return;
        }
        this.clientForm.reset();
        void this.slugInterceptorService.navigate(['clients'], null, true);
      },
      error: (e) => {
        this.commonHelper.httpResponseHandler(e?.error);
        this.loginLoader = false;
      }
    });
    // payLoad.state = this.selectedCountryName == this.unitedKingdom ? null : payLoad.state;
    // this.commonService.getCityDetail(payLoad.country, payLoad.state, payLoad.city).subscribe({
    //   next: (cResponse: APIResponseModel) => {
    //     if (cResponse?.status) {
    //       payLoad.city = cResponse?.data?.id;
    //       const formData = new FormData();
    //       // appending every value to form object
    //       Object.keys(payLoad).forEach((key) => {
    //         if (payLoad[key] == undefined) return;
    //         formData.append(key, payLoad[key]);
    //       });
    //       this.clientService.addClient(formData).subscribe({
    //         next: () => {
    //           this.toastr.success('Client Created Successfully');
    //           this.clientForm.reset();
    //           this.loginLoader = false;
    //           void this.slugInterceptorService.navigate(['clients'], null, true);
    //         },
    //         error: (e) => {
    //           this.commonHelper.httpResponseHandler(e?.error);
    //           this.loginLoader = false;
    //         }
    //       });
    //     } else this.loginLoader = false;
    //   },
    //   error: (e) => {
    //     this.commonHelper.httpResponseHandler(e?.error);
    //     this.loginLoader = false
    //   }
    // });
  }

  /**
   * Listen for option change (salutation)
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitter(selectedOption: SelectOptionsInterface): void {
    this.clientForm.patchValue({
      prefix: selectedOption.value,
    });
  }

  /**
   * Auto hypen SSN keyup
   */
  public autoHypenSSN(): void {
    let ssnValue = this.clientForm.value.ssn.replace(/\D/g, '');
    ssnValue = ssnValue.replace(/^(\d{3})/, '$1-');
    ssnValue = ssnValue.replace(/-(\d{2})/, '-$1-');
    ssnValue = ssnValue.replace(/(\d)-(\d{4}).*/, '$1-$2');

    this.clientForm.patchValue({
      ssn: ssnValue,
    });
  }

  /**
   * Initialize google map api by country
   * @param {any} countryId
   */
  public initMap(countryId: any): void {
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: (r) => {
        this.ngZone.run(() => {
          this.clientForm.patchValue(r);
        });
      },
      error: (e) => console.error(e),
    });
  }

  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.clientForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }

  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry || this.preDefinedLoader) {
      return;
    }
    this.clientForm.controls.state.reset();
    this.clientForm.patchValue({
      country: selectedCountry.value,
    });
    this.addressHelper.removeAutocompletePacContainer();
    this.initMap(selectedCountry.value);
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.clientForm.controls.city.reset();
    this.clientForm.patchValue({
      state: selectedState.value,
    });
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.clientForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.clientForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([
        Validators.required,
        Validators.pattern(
          new RegExp(selectedCity.additionalDetails[0].postal_code_regex),
        ),
      ]);
      zipCodeRef.updateValueAndValidity({ onlySelf: true });
    }

    // this loader is set false here because of the delay to set country state city
    this.preDefinedLoader = false;
  }

  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }

  /**
   * change event listener for agent type
   *
   * @param {any} data
   */
  public optionChangeEmitterAgentType(data: any): void {
    if (!data) return;
    this.clientForm.patchValue({
      agent_id: data.value,
    });
  }

  /**
   * add schedule value
   * @param {any} data
   */
  public onSelectSchedule(data) {
    this.selectedSchedule = data?.target?.value;
    this.clientForm.patchValue({
      schedule_remainder_type: data.target.value,
    });
  }
  public onSelectOnboardType(data) {
    this.selectedOnboardType = data?.target?.value;
    this.clientForm.patchValue({
      onboard_via_type: data?.target?.value,
    });
  }
  /**
     * payment type select on change
     * @param {any} data
     */
  // public onPaymentTypeSelect(data) {
  // this.selectedPaymentType = data
  // this.clientForm.patchValue({
  //   payment_type: data
  // })
  //  this.updateSubscriptionAmountValidations()
  // }

  /**
   * selected checked
   * @param {number} data
   * @return{boolean}
   */
  public selectedChecked(field, data) {
    switch (field) {
      case 'schedule':
        return this.selectedSchedule === data;
      case 'payment':
        return this.selectedPaymentType === data;
      case 'onboard':
        return this.selectedOnboardType === data;
    }
  }

  /**
   * Close generated on boarding link model
   */
  public closeModel(): void {
    this.modalService.close('generated-onboarding-link-modal');
    void this.slugInterceptorService.navigate(['clients'], null, true);
  }

  /**
   * Used for DOB picker to remove "Invalid date" text from the field should the user put an invalid date in.
   * This allows the user to type in a date, but also stops the bsDatePicker from not working properly if they select
   * a date after having inputted an invalid date.
   * @param event
   */
  public isInvalidDate(event) {
    if (event.target.value == 'Invalid date') {
      event.target.value = '';
    }
  }

  get state() {
    return this.clientForm.get('state');
  }

  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.clientForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ?
        this.state.addValidators([Validators.required]) : this.state.clearValidators();
      this.state.updateValueAndValidity();
    });
  }

  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.clientForm.get('zipcode');
    const stateId = this.clientForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        if (response.data.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(zipCodeRegex))]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        } else {
          zipCodeRef.setValidators([Validators.required]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, true);
  }

  /**
   * typing / onchange input field
   * changed as untouched field
   * not show validation error
   * @param {any} data
   * @param {string} key
   */
  public onChange(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.INPUT) {
      this.clientForm.get(key).markAsUntouched();
    }
  }

  /**
   * focusout of input field
   * changed as touched field
   * check and show validation error
   * @param {any} data
   * @param {string} key
   */
  public onFocusout(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.FOCUSOUT) {
      this.clientForm.get(key).markAllAsTouched();
    }
    if (this.clientForm.controls.ssn.status == STATUS_CHECK.VALID) { // if SSN status valid , check unique SSN
      this.checkIsSsnUnique(this.clientForm.controls.ssn.value, this.personId);
    }
  }

  /**
   * check ssn is unique
   * @param {string} data
   * @param id
   */
  public checkIsSsnUnique(data: string, id: string) {
    this.validatingSSN = ssnValidationProcess.start_validation;
    this.commonService.getIsSSNUnique(data, id, this.commonHelper.isShowSSNToast(), 1).subscribe({
      next: (r) => {
        this.isUniqueSSN = r;
        this.validatingSSN = ssnValidationProcess.end_validation;
      },
      error: (err) => {
        console.log('error', err);
        this.validatingSSN = ssnValidationProcess.end_validation;
      },
    });
  }

  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }

  /**
   * Updates form validations based on the selected payment type.
   */
  private updateSubscriptionAmountValidations() {
    const subscriptionAmountControl = this.clientForm.get('pay_amount');
    if (this.selectedPaymentType === 2) {
      subscriptionAmountControl.setValidators([Validators.required, this.commonHelper.subscriptionAmountValidator.bind(this)]);
    } else {
      subscriptionAmountControl.removeValidators([Validators.required]);
    }
    this.clientForm.patchValue({
      pay_amount: null,
    });
    subscriptionAmountControl.markAsUntouched();
    subscriptionAmountControl.updateValueAndValidity();
  }

  /**
   * Reset form
   */
  public resetForm(): void {
    this.selectedPaymentType = 1;
    this.selectedOnboardType = OnboardType.VIA_EMAIL
    // this.clientForm.patchValue({
    //   pay_amount: null,
    //   payment_type: ConsumerPaymentMethod.PAID_BY_PROFESSIONAL
    // });
  }

  /**
   * Copies the generated url to the clipboard.
   */
  public async copyToClipboard() {
    await navigator.clipboard.writeText(this.generatedUrl);
    this.toastr.success('URL Copied')
  }
}


/**
 * enum for ssn validation processing
 */
enum ssnValidationProcess {
  initial,
  start_validation,
  end_validation
}
