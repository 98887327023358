import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { CommonModelService } from 'src/app/services/common-model.service';
import { ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN, CREDIT_CARD_MASK, LoginType, NUMBERS_ONLY_PATTERN, YEAR_MASK, ZIPCODE_PATTERN } from 'src/constants/application.const';
import { ValidationHelper } from '../../../helper/validation.helper';
import { CardService } from './card-details.service';


@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.css'],
})
/**
 * Card Details Component
 */
export class CardDetailsComponent implements OnInit {
  //
  public cardNumber: string;
  public expiryMonth: string;
  public expiryYear: string;
  public securityCode: string;
  public cardHolderName: string;
  public cards: any;
  public addCardLoading = false;
  public loginType: LoginType;
  public requestId: string;
  public toggleSectionBody: boolean;
  public toggleSectionBodys: boolean;
  public menusection: boolean;
  public addCardForm: FormGroup;
  public cardMask: any;
  public yearMask: any;
  public submitLoader: boolean;
  public selectedCardDetails: any;
  public cardToken: string;
  public isProfessional: boolean;
  @Input() showNavBar:boolean = true;
  @ViewChild('menuList') menuList: any;

  /**
   * constructor
   */
  constructor(private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private commonHelper: CommonHelper,
    private validation: ValidationHelper,
    private route: ActivatedRoute,
    private modalService: CommonModelService,
    private cardService: CardService) {
  }

  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.isProfessional = this.commonHelper.isProfessional || this.commonHelper.isAdvisor;
    this.cardMask = CREDIT_CARD_MASK;
    this.yearMask = YEAR_MASK;
    this.requestId = this.route.snapshot.params['id'];
    this.loginType = this.commonHelper.getUserRole();
    this.loadCards();
    this.initForm();
    this.selectedCardDetails = null;
  }

  private initForm() {
    this.addCardForm = this.formBuilder.group({
      currency: ['usd'],
      new_card: ['1'],
      card_id: [''],
      card_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN)] }],
      card_number: ['', { updateOn: 'blur', validators: [Validators.required] }],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.maxLength(10), Validators.pattern(ZIPCODE_PATTERN)] }],
      exp_month: ['', { updateOn: 'blur', validators: [Validators.required, this.monthValidator.bind(this)] }],
      exp_year: ['', { updateOn: 'blur', validators: [Validators.required, this.yearValidator.bind(this)] }],
      cvv: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern(NUMBERS_ONLY_PATTERN)] }],
      is_default: [''],
    });

    this.validation.setValidationErrors({
      zipcode: { pattern: 'Please enter a valid zipcode' },
      exp_month: { isNotAllowed: 'Please enter a valid month', mask: null },
      exp_year: { isNotAllowed: 'Please enter a valid year', mask: null },
      cvv: { isNotAllowed: 'Please enter a valid security code', mask: null },
    });
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param{string} controlKey
   * @return{Array<string>}
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.addCardForm);
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.addCardForm.controls;
  }

  /**
   * getter -  LoginType.
   * @returns {typeof LoginType}
   */
  get login_Type(): typeof LoginType {
    return LoginType;
  }

  /**
   * month validation
   * @param {FormControl}control
   * @return {boolean}
   */
  monthValidator(control: FormControl): { [s: string]: boolean } {
    if (!control.value) {
      return null
    }

    const enteredMonth = +(control.value);
    if (enteredMonth > 12 || enteredMonth < 1) {
      return { isNotAllowed: true };
    } else {
      return null;
    }
  }

  /**
   * year validation
   * @param {FormControl}control
   * @return {boolean}
   */
  yearValidator(control: FormControl): { [s: string]: boolean } {
    if (!control.value) {
      return null
    }
    const enteredYear = +(control.value);
    if (enteredYear < new Date().getFullYear()) {
      return { isNotAllowed: true };
    } else {
      return null;
    }
  }

  /**
   * add new card
   */
  public addnewcard() {
    if (this.cards?.length >= 2) {
      this.modalService.open('delete-old-card');
    } else {
      this.addCardForm.reset();
      this.modalService.open('Add-credit-debit-card');
    }
  }

  /**
   * close modal
   */
  public closeModel() {
    this.addCardForm.reset();
    this.modalService.close('Add-credit-debit-card');
  }

  /**
   * cancel card modal
   */
  public cancelnewcard() {
    this.modalService.close('Add-credit-debit-card');
  }

  /**
   * set Menu Open
   * @param {boolean} menu
   */
  public setMenuOpen(menu: boolean) {
    if (menu == this.menusection) {
      this.menusection = null;
    } else {
      this.menusection = menu;
    }
  }

  /**
   * get cards details
   */
  public loadCards() {
    this.cardService.getCardsApi(this.isProfessional).subscribe((response: any) => {
      this.cards = response.data;
    });
  }

  /**
   * make default card
   * @param {any} id
   */
  public makeDefault(id: any) {
    const param = { id: id };
    this.cardService.makeCardDefaultApi(param, this.isProfessional).subscribe((response: any) => {
      this.toastr.success(response.message);
      this.loadCards();
    }, (error: any) => {
      this.toastr.error(error.error.message);
    });
    this.closeMenu();
  }

  /**
   * Generates Card Token for the New Card Details
   */
  public getcardToken() {
    this.addCardLoading = true;
    const cardNumber = this.addCardForm.value.card_number;
    const expMonth = this.addCardForm.value.exp_month;
    const expYear = this.addCardForm.value.exp_year;
    const cvv = this.addCardForm.value.cvv;
    this.cardService.generateCardToken(cardNumber, expMonth, expYear, cvv).subscribe({
      next: (response: any) => {
        this.cardToken = response.id;
        this.addCardDetails();
      },
      error: (err) => {
        this.addCardLoading = false;
        this.toastr.error(err?.['error']?.['error']?.message ?? null);
      },
    });
  }

  /**
   * add card
   */
  public addCardDetails() {
    this.addCardLoading = true;
    const param = {
      token: this.cardToken,
      card_name: this.addCardForm.value.card_name,
      exp_month: this.addCardForm.value.exp_month,
      exp_year: this.addCardForm.value.exp_year,
      zipcode: this.addCardForm.value.zipcode,
      is_default: '1',
      new_card: '1',
    };
    this.cardService.addCardApi(param, this.isProfessional).subscribe((response: any) => {

      if (this.selectedCardDetails != null) {
        this.deleteCard(this.selectedCardDetails.id);
      } else {
        this.deleteModalOpen();
        this.loadCards();
        this.closeModel();
      }
      this.addCardLoading = false;
      this.toastr.success(response.message);
    }, (error: any) => {
      this.addCardLoading = false;
      const errorMessage = (typeof error.error.message === 'object') ? error.error.message?.exp_year : error.error.message;
      this.toastr.error(errorMessage);
    });
  }

  /**
   * delete card
   * @param {string} id
   */
  public deleteCard(id: string) {
    this.addCardLoading = true;
    const param = { id: id };
    this.cardService.deleteCardApi(param, this.isProfessional).subscribe((response: any) => {
      this.toastr.success(response.message);
      this.loadCards();
      this.selectedCardDetails = null;
      this.closeModel();
      this.addCardLoading = false;
    }, (error: any) => {
      this.toastr.error(error.error.message);
      this.addCardLoading = false;
    });
    this.closeMenu();
  }

  /**
   * get card brand logo
   * @param {string} brand
   * @return {string}
   */
  public getCardBrandLogo(brand: string, iconColor?: string) {
    switch (brand) {
      case 'visa':
        return iconColor ? `assets/images/payment/visa-${iconColor}.svg` : `assets/images/payment/visa.svg`;

      case 'mastercard':
        return iconColor ? `assets/images/payment/mastercard-${iconColor}.svg` : `assets/images/payment/mastercard.svg`;

      case 'amex':
        return iconColor ? `assets/images/payment/amex-${iconColor}.svg` : `assets/images/payment/amex.svg`;

      case 'diners':
        return iconColor ? `assets/images/payment/diners-${iconColor}.svg` : `assets/images/payment/diners.svg`;

      case 'discover':
        return iconColor ? `assets/images/payment/discover-${iconColor}.svg` : `assets/images/payment/discover.svg`;

      case 'unionpay':
        return iconColor ? `assets/images/payment/unionpay-${iconColor}.svg` : `assets/images/payment/unionpay.svg`;

      case 'jcb':
        return iconColor ? `assets/images/payment/jcb-${iconColor}.svg` : `assets/images/payment/jcb.svg`;

      default:
        return null;
    }
  }

  /**
   * this function helps to close delete model
   */
  public closeDeleteModel() {
    this.modalService.close('delete-old-card');
  }

  /**
   * to get selected card details
   * @param card
   */

  public selectedCard(card: any) {
    this.selectedCardDetails = card;
  }

  /**
   * Opens the "delete-old-cards" modal if there are more than two card present.
   * @returns
   */
  public deleteModalOpen() {
    if (this.cards.length < 2) return;
    this.modalService.open('delete-old-card');
  }

  /**
   * to delete selected card and add new card
   */

  public deleteCards() {
    if (this.selectedCardDetails != null) {
      this.closeDeleteModel();
      this.addCardForm.reset();
      this.modalService.open('Add-credit-debit-card');
    }
  }

  /**
   * to close the menu, after clicking any menu list
   */

  public closeMenu() {
    this.menuList.nativeElement.style.display = 'none';
  }
}
