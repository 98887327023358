<app-common-modal id="{{modal_id}}" class="app-common-modal" [heightClass]="'min-h-75'">
  <img src="assets/images/about-yourself/close.svg"
    class="absolute right-2 w-8  -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
    (click)="closeModal()">
  <div class="mb-4">
    <div>
      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
        Retirement Assets
      </h4>
      <p class="text-grey text-regular pt-1 text-sm">Start by adding your retirement assets. </p>
      <p class="text-regular font-medium text-xs mt-2 error-alert"
        *ngIf="(isNewAsset && !permissions?.['add']) || (!isNewAsset && !permissions?.['edit'])">
        You do not have permission
        to {{isNewAsset ? 'add information for this client.' : 'edit this client\'s information.'}}
      </p>
    </div>
  </div>
  <div class="col-span-12 relative">
    <label for="type_of_assets" class="text-label-grey text-sm text-regular">Type Of Asset</label>
    <app-select-with-search id="type_of_assets" [options]="retirementAssetTypeArray"
      [placeholderValue]="'Select Asset Type'" (optionChangeEmitter)="optionChangeEmitterAssetType($event)"
      [defaultSelected]="retirementAssetTypeId" [ifError]="!retirementAssetTypeId && touchedRetirementType" tabindex="0"
      (focusout)="canAccessForm ?markAsTouchedType():''" [disabled]="!canEdit || !canAccessForm">
    </app-select-with-search>
    <div *ngIf="!retirementAssetTypeId && touchedRetirementType && canEdit">
      <p class="text-regular font-medium text-xs mt-2 error-alert">Type is required
      </p>
    </div>
  </div>
  <form [formGroup]="retirementAssetForm" autocomplete="off" (ngSubmit)="onSubmit()">
    <div>
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
        <div class="-mt-1 col-span-12 md:col-span-6" *ngIf="validFields['asset_name']">
          <label for="asset_name" class="text-label-grey text-sm text-regular">Asset Nickname</label>
          <input [attr.disabled]="!canEdit || undefined" type="text" id="asset_name"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="Asset Nickname" formControlName="asset_name" name="asset_name"
            [ngClass]="formGet?.['asset_name'].errors && formGet?.['asset_name'].touched?'form-input-error':'form-input-focus'"
            (focusout)="formGet.website.markAsTouched()">
          <p *ngFor="let error of getErrors('asset_name')" class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 md:col-span-6 relative" *ngIf="validFields['user_people_id']">
          <label for="user_people_id" class="text-label-grey text-sm text-regular">Account Owner</label>
          <app-select-with-search id="user_people_id" [options]="userPeopleArray" [disabled]="!canEdit"
            [placeholderValue]="'Select account owner'" (optionChangeEmitter)="optionChangeEmitterAccountOwner($event)"
            [defaultSelected]="accountOwnerId">
          </app-select-with-search>
          <p *ngFor="let error of getErrors('user_people_id')"
            class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12" *ngIf="validFields['account_name']">
          <label for="account-name" class="text-label-grey text-sm text-regular">Account Name</label>
          <input [attr.disabled]="!canEdit || undefined" type="text" id="account-name"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="Full Name" formControlName="account_name" name="account_name"
            [ngClass]="formGet?.['account_name'].errors && formGet?.['account_name'].touched?'form-input-error':'form-input-focus'"
            (focusout)="formGet.website.markAsTouched()">
          <p *ngFor="let error of getErrors('account_name')" class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 md:col-span-6" *ngIf="validFields['account_number']">
          <label for="routing-no" class="text-label-grey text-sm text-regular">Account Number</label>
          <input [attr.disabled]="!canEdit || undefined" type="text" id="routing-no"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="e.g. 026009593" formControlName="account_number" name="account_number"
            [ngClass]="formGet?.['account_number'].errors && formGet?.['account_number'].touched?'form-input-error':'form-input-focus'">
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="canShowErrorMessage()">Please enter
            Account Number
          </p>
          <p *ngFor="let error of getErrors('account_number')"
            class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 md:col-span-6" *ngIf="validFields['account_number']">
          <label for="v_account_number" class="text-label-grey text-sm text-regular">Verify Account Number</label>
          <input [attr.disabled]="!canEdit || undefined " type="text"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="e.g. 026009593" formControlName="v_account_number" name="v_account_number"
            id="v_account_number"
            [ngClass]="formGet?.['v_account_number'].touched &&formGet?.['v_account_number'].value&& !isVerified('account_number')?'form-input-error':'form-input-focus'">
          <div
            *ngIf="!isVerified('account_number') && formGet?.['account_number'].valid && formGet?.['account_number'].value">
            <p class="text-regular font-medium text-xs mt-2 error-alert">
              Account Numbers must match.
            </p>
          </div>
        </div>
        <!-- Institution (Where Asset Is Held) -->
        <div class="col-span-12" *ngIf="validFields['institution']">
          <label for="institution" class="text-label-grey text-sm text-regular">Institution (Where Asset Is Held)</label>
          <input type="text" [attr.disabled]="!canEdit || undefined"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="e.g. Bank of America" formControlName="institution" name="institution" id="institution"
            [ngClass]="formGet?.['institution'].errors && formGet?.['institution'].touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('institution')" class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
        <div class="col-span-12" *ngIf="validFields['current_balance']">
          <label for="current_balance" class="text-label-grey text-sm text-regular">Current Balance</label>
          <div class="relative flex items-center">
            <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
            <input type="text" id="current_balance"
              class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 price-input"
              placeholder="e.g. 1000" formControlName="current_balance" name="currency-amount" appCurrencyInput
              [ngClass]="formGet?.['current_balance'].errors && formGet?.['current_balance'].touched?'form-input-error':'form-input-focus'">
          </div>
          <p *ngFor="let error of getErrors('current_balance')"
            class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>

        <div class="col-span-12" *ngIf="validFields['market_value']">
          <label for="market_value" class="text-label-grey text-sm text-regular">Market Value</label>
          <div class="relative flex items-center">
            <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
            <input [attr.disabled]="!canEdit || undefined" type="text" id="market_value" appCurrencyInput
              class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 price-input"
              placeholder="e.g. 1000" formControlName="market_value" name="currency-amount"
              [ngClass]="formGet?.['market_value'].errors && formGet?.['market_value'].touched?'form-input-error':'form-input-focus'">
          </div>
          <p *ngFor="let error of getErrors('market_value')" class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div *ngIf="validFields['current_contributions']" class="col-span-12">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Current Contributions
              </h4>
            </div>
            <div class="col-span-12 sm:col-span-4">
              <app-toggle-switch [defaultSelected]="currentContribution" [options]="toggleOptions"
                (optionChangeEmitter)="onChangeContributions($event)" [disabled]="!canEdit || submitLoader">
              </app-toggle-switch>
            </div>
          </div>

        </div>
        <ng-container *ngIf="currentContribution == 1">
          <div class="col-span-12 md:col-span-6" *ngIf="validFields['contribution_amount']">
            <label for="contribution-amount" class="text-label-grey text-sm text-regular">Contribution Amount</label>
            <div class="relative flex items-center">
              <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
              <input [attr.disabled]="!canEdit || undefined" type="text" id="contribution-amount" appCurrencyInput
                class="price-input h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                placeholder="e.g. 10,000" formControlName="contribution_amount" name="contribution_amount"
                [ngClass]="formGet?.['contribution_amount'].errors && formGet?.['contribution_amount'].touched?'form-input-error':'form-input-focus'">
            </div>
            <p *ngFor="let error of getErrors('contribution_amount')"
              class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <div class="col-span-12 md:col-span-6 relative" *ngIf="validFields['frequency_id']">
            <label for="frequency_id" class="text-label-grey text-sm text-regular">Frequency</label>
            <app-select-with-search id="frequency_id" [options]="frequencyTypeArray" [disabled]="!canEdit"
              [placeholderValue]="'Select Frequency Type'"
              (optionChangeEmitter)="optionChangeEmitterFrequencyType($event)" [defaultSelected]="frequencyTypeId">
            </app-select-with-search>
          </div>
        </ng-container>
        <div class="col-span-12" *ngIf="validFields['beneficiaries']">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide mb-2">
            Add Beneficiaries
          </h4>
          <p class="text-grey text-regular mb-2 text-sm">
            This information should match what is in your last will. It is important that should this information
            change, you update this record
          </p>
          <app-select-beneficiary-ratio [editData]="selectedBeneficiaries" [canEdit]="canAccessForm"
            (isRemainingToAllocateEmitter)="remainingToAllocateListener($event)"
            [vaultOwnerSelectedAsBeneficiary]="vaultOwnerSelectedAsBeneficiary"
            [userShownAsBenificiary]="userAsBenificiary"
            (selectBeneficiaryRatioEmitter)="selectBeneficiaryRatioListener($event)">
          </app-select-beneficiary-ratio>
        </div>

        <div class="col-span-12" *ngIf="validFields['note']">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Notes:
          </h4>
          <label for="note" class="text-label-grey text-sm text-regular">Note</label>
          <textarea rows="4" cols="50" id="note"
            class="input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
            name="note" formControlName="note" placeholder="e.g Note."
            [ngClass]="formGet?.['note'].errors && formGet?.['note'].touched?'form-input-error':'form-input-focus'">
          </textarea>
          <p *ngFor="let error of getErrors('note')" class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>

        <div class="col-span-12"
          *ngIf="!isPlaidData && (validFields['website'] || validFields['user_name'] || validFields['password'])">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <div class="flex flex-row items-baseline">
                <div>
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    Web Access
                  </h4>
                  <p class="text-grey text-regular pt-1 text-sm">
                    Online access information for this asset.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-span-12" *ngIf="!isPlaidData && validFields['website']">
              <label for="website" class="text-label-grey text-sm text-regular">Web URL</label>
              <div class="relative">
                <input [attr.disabled]="!canEdit || undefined" type="url"
                  class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                  placeholder="www.mybank.com" formControlName="website" id="website"
                  [ngClass]="formGet.website.errors && formGet.website.touched?'form-input-error':'form-input-focus'"
                  (focusout)="formGet.website.markAsTouched()">
                <p *ngFor="let error of getErrors('website')" class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
            <div class="col-span-12 md:col-span-6" *ngIf="!isPlaidData && validFields['user_name']">
              <label for="user-name" class="text-label-grey text-sm text-regular">Username</label>
              <input [attr.disabled]="!canEdit || undefined" type="text" id="user-name"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                placeholder="e.g. Marktylor_54" formControlName="user_name" name="user_name"
                [ngClass]="formGet?.['user_name'].errors && formGet?.['user_name'].touched?'form-input-error':'form-input-focus'"
                (focusout)="formGet.user_name.markAsTouched()">
              <p *ngFor="let error of getErrors('user_name')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 md:col-span-6" *ngIf="!isPlaidData && validFields['password']">
              <label for="password" class="text-label-grey text-sm text-regular">Password</label>
              <div class="relative">
                <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
                  (click)="togglePasswordView = !togglePasswordView">
                  <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
                  <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
                </a>
                <input [attr.disabled]="!canEdit || undefined" [type]="togglePasswordView?'text':'password'"
                  id="password"
                  class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                  placeholder="e.g. JOHy5689" formControlName="password" name="password"
                  [ngClass]="formGet.password.errors && formGet.password.touched?'form-input-error':'form-input-focus'"
                  (focusout)="formGet.password.markAsTouched()">
                <p *ngFor="let error of getErrors('password')"
                  class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
        <app-button-loader *ngIf="!isNewAsset" class="w-full md:w-auto" [buttonText]="'Remove Details'"
          [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true" (click)="deleteRetirementAssets()"
          [valid]="!submitLoader && retirementAssetTypeId !== ''"></app-button-loader>
        <app-button-loader *ngIf="retirementAssetTypeId" class="w-full md:w-auto"
          [buttonText]="!isNewAsset ? 'Update Details' : 'Save Details'"
          [valid]="this.isValidSave() && isVerified('account_number') && !submitLoaderDelete && !remainingAllocation"
          [loader]="submitLoader" [buttonType]="'submit'"></app-button-loader>
      </div>
    </div>
  </form>
</app-common-modal>