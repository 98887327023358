import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  
})
/**
 * vehicle details component
 */
export class VehicleComponent implements OnInit {
  public userType:string;
  public requestId:string;
  /**
   * @constructor
   */
  constructor(
    private route:ActivatedRoute,
    private localStorageService:LocalStorageService,
  ) { }

  /**
   * celled initially
   */
  ngOnInit(): void {
    this.requestId=this.route.snapshot.params['id'];
    this.userType=this.localStorageService.getDataByKey('role');
  }
}
