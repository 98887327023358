import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * Overview service for all user data
 */
export class ConsumerPaymentService {
  public BASE_URL:string;
  /**
   * dashboard data event handlers
   */
  public showPaymentScreen:any;
  public showPaymentScreenObserve:Observable<any>;
  public showPaymentScreen1:any;
  public showPaymentScreenObserve1:Observable<any>;
  public subscriptionPlan:any;
  public subscriptionPlanObserve:Observable<any>;
  public transferVault:any;
  public transferVaultScreenObserve:Observable<any>;
  public subscriptionPlan1:any;
  public subscriptionPlanObserve1:Observable<any>;
  public transferVault1:any;
  public transferVaultScreenObserve1:Observable<any>;
  /**
   * @constructor
   */
  constructor(
    private http:HttpClient,
  ) {
    this.BASE_URL = environment.BASE_URL;
    this.showPaymentScreen=new BehaviorSubject(false);
    this.showPaymentScreenObserve=this.showPaymentScreen.asObservable();
    this.showPaymentScreen1=new BehaviorSubject(false);
    this.showPaymentScreenObserve1=this.showPaymentScreen1.asObservable();
    this.subscriptionPlan=new BehaviorSubject('');
    this.subscriptionPlanObserve=this.subscriptionPlan.asObservable();
    this.transferVault=new BehaviorSubject(false);
    this.transferVaultScreenObserve=this.transferVault.asObservable();
    this.subscriptionPlan1=new BehaviorSubject('');
    this.subscriptionPlanObserve1=this.subscriptionPlan1.asObservable();
    this.transferVault1=new BehaviorSubject(false);
    this.transferVaultScreenObserve1=this.transferVault1.asObservable();
  }
  /**
   * payment screen obserable events
   *
   * @param {ObservableEventInterface} data
   */
  public paymentScreenObservableEvents(data: boolean):void {
    this.showPaymentScreen.next(data);
  }
  /**
   * payment screen obserable events
   *
   * @param {ObservableEventInterface} data
   */
  public paymentScreenObservableEvents1(data: boolean):void {
    this.showPaymentScreen1.next(data);
  }
  /**
   * dashboard data obserable events
   *
   * @param {ObservableEventInterface} data
   */
  public subscriptionPlanObservableEvents(data: string):void {
    this.subscriptionPlan.next(data);
  }
  /**
   * payment transfer vault screen obserable events
   *
   * @param {ObservableEventInterface} data
   */
  public transfervaultScreenObservableEvents(data: boolean):void {
    this.transferVault.next(data);
  }
  /**
   * payment transfer vault screen obserable events
   *
   * @param {ObservableEventInterface} data
   */
  public transfervaultScreenObservableEvents1(data: boolean):void {
    this.transferVault1.next(data);
  }
  /**
   * dashboard data obserable events
   *
   * @param {ObservableEventInterface} data
   */
  public subscriptionPlanObservableEvents1(data: string):void {
    this.subscriptionPlan1.next(data);
  }
}
