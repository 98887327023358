<app-common-modal id="add-beneficiary-modal" class="app-common-modal" [heightClass]="'min-h-75'">
  <img src="assets/images/about-yourself/close.svg"
       class="absolute right-2 w-8 shadow-md shadow-red-300 rounded-full cursor-pointer z-50" alt=""
       (click)="closeModel()"/>
  <div class="w-full relative rounded-md">
    <div class="title-modal w-11/12">
      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
        Heir and Beneficiary Details
      </h4>
      <p class="text-grey text-regular pt-1 text-sm">
        Please provide contact information for your heirs and beneficiaries. This information will be critical at the
        time of your passing and should match what is in your last will. It is important that should this information
        change, you update this record.
      </p>
      <p class="mt-2 text-grey text-regular pt-1 text-sm">
        By including the below information, you are certifying to us that you are authorized to do so.
      </p>
    </div>


    <div>
      <div class="col-span-12 relative">
        <label for="bene-type" class="text-label-grey text-sm text-regular">Beneficiary Type*
        </label>
        <app-select-with-search id="bene-type" [options]="beneficiaryTypes"
                                [placeholderValue]="'Select Beneficiary Type'"
                                (optionChangeEmitter)="optionChangeEmitterBeneficiaryType($event)"
                                [ifError]="touchedBeneType && !beneficiaryId" [defaultSelected]="beneficiaryId"
                                (focusout)="markAsBeneficiaryType()" tabindex="0">
        </app-select-with-search>
        <div *ngIf="touchedBeneType && !beneficiaryId">
          <p class="text-regular font-medium text-xs mt-2 error-alert">
            Beneficiary type is required
          </p>
        </div>
      </div>
      <form [formGroup]="beneficiaryForm" autocomplete="off" (ngSubmit)="onSubmit()">
        <div formGroupName="individualType">
          <!-- Individual -->
          <div *ngIf="beneficiaryType === beneficiaryTypesConst.individual">
            <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
              <div class="col-span-12">
                <label for="first-name" class="text-label-grey text-sm text-regular">First
                  Name*</label>
                <input type="text"
                       class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                       placeholder="First Name" formControlName="first_name" name="first-name" id="first-name"
                       [ngClass]="formGet['first_name'].errors && formGet['first_name'].touched?'form-input-error':'form-input-focus'">
                <p *ngFor="let error of getErrors('first_name')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12">
                <label for="middle-name" class="text-label-grey text-sm text-regular">Middle
                  Name</label>
                <input type="text"
                       class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                       placeholder="Middle Name" formControlName="middle_name" name="middle-name" id="middle-name"
                       [ngClass]="formGet?.['middle_name']?.errors && formGet?.['middle_name']?.touched?'form-input-error':'form-input-focus'">
                <p *ngFor="let error of getErrors('middle_name')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12">
                <label for="last-name" class="text-label-grey text-sm text-regular">Last
                  Name*</label>
                <input type="text"
                       class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                       placeholder="Last Name" formControlName="last_name" name="last-name" id="last-name"
                       [ngClass]="formGet['last_name'].errors && formGet['last_name'].touched?'form-input-error':'form-input-focus'">
                <p *ngFor="let error of getErrors('last_name')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12 " [ngClass]="{' md:col-span-12': !showSSN}">
                <label [for]="'dob'" class="text-label-grey text-sm text-regular">Date of
                  Birth ( {{domainDateFormat}} )</label>
                <app-datepicker-toggle
                  inputID="dob"
                  class="mt-1"
                  [bsConfig]="bsConfig"
                  [useFormControl]="formGet['dob']">
                </app-datepicker-toggle>
                <p *ngFor="let error of getErrors('dob')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="showSSN">
                <label for="ssn" class="text-label-grey text-sm text-regular">SSN
                  (Social Security Number)</label>
                <div class="relative text-gray-700">
                  <a class="absolute inset-y-0 right-2 pr-3 flex items-center"
                     (click)="toggleSSNView.ssn = !toggleSSNView.ssn">
                    <fa-icon [icon]="faEye" *ngIf="toggleSSNView.ssn"></fa-icon>
                    <fa-icon [icon]="faEyeSlash" *ngIf="!toggleSSNView.ssn"></fa-icon>
                  </a>
                  <input type="text" [type]="toggleSSNView.ssn?'text':'password'"
                         class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                         name="ssn" id="ssn" formControlName="ssn" placeholder="e.g. 255-34-5345"
                         [ngClass]="formGet['ssn'].errors && formGet['ssn'].touched?'form-input-error':'form-input-focus'"
                         [mask]="SSN_MASK" [dropSpecialCharacters]="false" maxlength="11 "
                         (focusout)="onFocusout($event,'ssn', 'individualType')">
                </div>
                <p *ngFor="let error of getErrors('ssn')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6 " *ngIf="showSSN">
                <label for="ssn" class="text-label-grey text-sm text-regular">Confirm SSN</label>
                <div class="relative text-gray-700">
                  <a class="absolute inset-y-0 right-2 pr-3 flex items-center"
                     (click)="toggleSSNView.v_ssn = !toggleSSNView.v_ssn">
                    <fa-icon [icon]="faEye" *ngIf="toggleSSNView.v_ssn"></fa-icon>
                    <fa-icon [icon]="faEyeSlash" *ngIf="!toggleSSNView.v_ssn"></fa-icon>
                  </a>
                  <input type="text" [type]="toggleSSNView.v_ssn?'text':'password'"
                         class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                         name="ssn_validation" id="ssn_validation" formControlName="ssn_validation"
                         placeholder="e.g. 255-34-5345"
                         [ngClass]="formGet['ssn_validation'].errors && formGet['ssn_validation'].touched ? 'form-input-error' : 'form-input-focus'"
                         [mask]="SSN_MASK" [dropSpecialCharacters]="false" maxlength="11"
                         (input)="onChange($event,'ssn_validation')"
                         (focusout)="onFocusout($event,'ssn_validation', 'individualType')">
                </div>
                <div
                  *ngIf="!checkSSNMatch() && ((formGet['ssn'].value && formGet['ssn'].valid) && !formGet['ssn_validation'].value)">
                  <p class="text-regular font-medium text-xs mt-2 error-alert">
                    Confirm SSN is required.
                  </p>
                </div>
                <div
                  *ngIf="!checkSSNMatch() && ((formGet['ssn'].value && formGet['ssn'].valid) && formGet['ssn_validation'].value)">
                  <p class="text-regular font-medium text-xs mt-2 error-alert">
                    SSN numbers must match.
                  </p>
                </div>
              </div>
              <div class="col-span-12" *ngIf="showSSN && formGet['ssn'].value">
                <div *ngIf="checkSSNMatch() && validatingSSN == 1" class="flex">
                  <p class="text-regular font-medium text-xs checking-alert">
                    Validating SSN &nbsp;
                  </p>
                  <div class="loading-red mr-2"></div>
                </div>
                <p class="text-regular font-medium text-xs error-alert"
                   *ngIf="checkSSNMatch() && validatingSSN == 2 && !isUniqueSSN">
                  SSN has already been entered.
                </p>
              </div>
              <div class="col-span-12">
                <label for="relationship" class="text-label-grey text-sm text-regular">Relationship to you?*
                </label>
                <app-select-component id="relationship" [options]="relationshipList"
                                      [placeholder]="'Select Relationship'"
                                      (optionChangeEmitter)="optionChangeEmitterRelationshipTrust($event)"
                                      [selectedOptionDefault]="beneficiaryForm['controls'].individualType?.value?.['relationship']"
                                      [ifError]="formGet['relationship'].errors && formGet['relationship'].touched"
                                      (toggleEmiter)="toggleEmiter($event, 'relationship')">
                </app-select-component>
                <p *ngFor="let error of getErrors('relationship')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div *ngIf="useOther" class="col-span-12">
                <label for="define_others" class="text-label-grey text-sm text-regular">Define Other*</label>

                <div class="relative flex items-center">
                  <input type="text" id="define_others"
                         class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                         name="define_others" formControlName="define_others" placeholder="Define Other"
                         [ngClass]="formGet?.['define_others'].errors && formGet?.['define_others'].touched?'form-input-error':'form-input-focus'">
                </div>
                <p *ngFor="let error of getErrors('define_others')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
          </div>


          <!-- Phone Number -->
          <div *ngIf="beneficiaryType === beneficiaryTypesConst.individual  " class="my-6">
            <div class="flex flex-row items-baseline">
              <div>
                <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                  Phone Number and Email Address
                </h4>
              </div>
            </div>

            <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
              <div class="col-span-12">
                <label class="text-label-grey text-sm text-regular">
                  Phone Number*</label>
                <app-phone-input [placeholder]="'e.g. 8665044754'"
                                 (onChange)="detectChangePhoneNumber($event)"
                                 [phoneNumber]="this.beneficiaryForm['controls'].individualType.value.phone?this.beneficiaryForm['controls'].individualType.value.phone:this.beneficiaryForm['controls'].trusteeType.value.phone"
                                 [countryCode]="countryCode" [uniqueId]="'phone-beneficiary'"
                                 [errorClassTrigger]="formGet?.['phone']?.errors && formGet['phone']?.touched"
                                 [form]="getSelectedForm"
                                 (focusout)="formGet?.['phone'].markAsTouched()">
                </app-phone-input>
                <p *ngFor="let error of getErrors('phone')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12">
                <label for="email_ind" class="text-label-grey text-sm text-regular">
                  Email Address*</label>
                <input type="email"
                       class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                       placeholder="e.g. marktylor@gmail.com"
                       [ngClass]="formGet?.['email']?.errors && formGet['email']?.touched?'form-input-error':'form-input-focus'"
                       formControlName="email" name="email" id="email_ind"
                       (focusout)="formGet['email'].markAsTouched()">
                <p *ngFor="let error of getErrors('email')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>

            </div>
          </div>

          <!-- Address -->
          <div *ngIf="beneficiaryType === beneficiaryTypesConst.individual ">
            <div class="mb-4">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Address
              </h4>
              <p class="text-grey text-regular pt-1 text-sm">Make sure to enter their address including their postcode.
              </p>
            </div>
            <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
              <div class="col-span-12">
                <label for="country_ind" class="text-label-grey text-sm text-regular">Country*</label>
                <app-country-search-component id="country_ind"
                                              (optionChangeEmitter)="optionChangeListenerCountry($event)"
                                              [defaultSelected]="formGet?.['country']?.value"
                                              (focusout)="markAsTouchedIndividual('country')"
                                              [enableError]="formGet['country'].errors && formGet['country'].touched">
                </app-country-search-component>
                <div *ngIf="formGet['country'].errors && formGet['country'].touched">
                  <p class="text-regular font-medium text-xs mt-2 error-alert">Country is required</p>
                </div>
              </div>
              <div class="col-span-12 lg:col-span-8 relative move-map" #moveMapHere>
                <label for="address_ind" class="text-label-grey text-sm text-regular">Street
                  Address*</label>
                <input type="text" #search (keyup)="onAddressChange(search)"
                       (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null"
                       class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                       placeholder="e.g Main St" name="address" formControlName="address" id="address_ind"
                       (focusout)="formGet['address'].markAsTouched()" (input)="onFocusAddress()"
                       [ngClass]="formGet['address'].errors && formGet['address'].touched?'form-input-error':'form-input-focus'">
                <p *ngFor="let error of getErrors('address')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12 lg:col-span-4">
                <label for="apt_number_ind" class="text-label-grey text-sm text-regular">Apartment / Suite #</label>
                <input name="apt_number" id="apt_number_ind" formControlName="apt_number" placeholder="e.g 2a"
                       class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                       [ngClass]="formGet['apt_number']?.errors && formGet['apt_number']?.touched?'form-input-error':'form-input-focus'">
                <p *ngFor="let error of getErrors('apt_number')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12" *ngIf="selectedCountryName!==unitedKingdom">
                <label for="state-Province" class="text-label-grey text-sm text-regular">State/Province*</label>
                <app-state-search-component id="state-Province" [countryCode]="formGet?.['country']?.value"
                                            [enableError]="formGet['state'].errors && formGet['state'].touched"
                                            [defaultSelected]="formGet?.['state']?.value"
                                            (optionChangeEmitter)="optionChangeListenerState($event)"
                                            tabindex="0"
                                            (focusout)="markAsTouchedIndividual('state')"></app-state-search-component>
                <p *ngFor="let error of getErrors('state')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6 relative ">
                <label for="city" class=" text-label-grey text-sm text-regular ">City*</label>
                <app-city-search id="city" [stateCode]="formGet?.['state']?.value"
                                 [enableError]="formGet['city'].errors && formGet['city'].touched"
                                 [defaultSelected]="formGet?.['city']?.value"
                                 (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0"
                                 (focusout)="markAsTouchedIndividual('city')"></app-city-search>
                <p *ngFor="let error of getErrors('city')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class=" col-span-12 md:col-span-6 ">
                <label for="zipcode" class="text-label-grey text-sm text-regular">Zip/Postal
                  Code*</label>
                <input type="text" (focusout)="markAsTouchedIndividual('zipcode')"
                       class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
                       placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                       name="zipcode" formControlName="zipcode" id="zipcode"
                       oninput="this.value = this.value.toUpperCase()"
                       (focus)="validateZipCode()"
                       [ngClass]="formGet['zipcode'].errors && formGet['zipcode'].touched?'form-input-error':'form-input-focus'">
                <p *ngFor="let error of getErrors('zipcode')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
          </div>

          <!-- Submit/Update/Remove -->
          <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8"
               *ngIf="beneficiaryType === beneficiaryTypesConst.individual ">
            <app-button-loader class="w-full sm:w-auto" *ngIf="isUpdate" [buttonText]="'Remove Beneficiary'"
                               (click)="confirmDelete()" [loader]="submitLoaderDelete" [buttonType]="'button'"
                               [outlineOnly]="true">
            </app-button-loader>
            <app-button-loader class="w-full sm:w-auto"
                               [valid]="checkSSNMatch()"
                               [buttonText]="updateText"
                               [loader]="submitLoader"
                               [buttonType]="'submit'"></app-button-loader>
          </div>
        </div>
        <div formGroupName="trusteeType">
          <!-- Trustee -->
          <div *ngIf="beneficiaryType === beneficiaryTypesConst.trustee">
            <div class="mt-2 col-span-12" *ngIf="!isCharityType">
              <label for="trust_type" class="mt-1 text-label-grey text-sm text-regular">Trust Type*</label>
              <app-select-component id="trust_type" [options]="trusteeTypesList" [placeholder]="'Select Trust Type'"
                                    (optionChangeEmitter)="optionChangeEmitterRelationshipTrust($event)"
                                    (focusout)="markAsTouchedIndividual('trust_type_id')"
                                    [selectedOptionDefault]="beneficiaryForm['controls'].trusteeType.value?.trust_type_id"
                                    [ifError]="formGet['trust_type_id'].errors && formGet['trust_type_id'].touched"
                                    (toggleEmiter)="toggleEmiter($event, 'trust_type_id')">
              </app-select-component>
              <p *ngFor="let error of getErrors('trust_type_id')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">

              <div class="col-span-12">
                <label for="trust_name" class="text-label-grey text-sm text-regular">
                  {{labelMappings?.[isCharityType]?.[0] }}* </label>
                <input type="text"
                       class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                       placeholder="{{isCharityType ?'e.g Direct Relief':' e.g. The HALO Trust USA'}}"
                       [ngClass]="formGet['trust_name'].errors && formGet['trust_name'].touched?'form-input-error':'form-input-focus'"
                       formControlName="trust_name" name="trust_name" id="trust_name">
                <p *ngFor="let error of getErrors('trust_name')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <label for="trustee_name" class="text-label-grey text-sm text-regular">
                  {{labelMappings?.[isCharityType]?.[1] }}</label>
                <input type="text"
                       class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                       placeholder="e.g. Tim Church"
                       [ngClass]="formGet['trustee'].errors && formGet['trustee'].touched?'form-input-error':'form-input-focus'"
                       formControlName="trustee" name="trustee" id="trustee_name">
                <p *ngFor="let error of getErrors('trustee')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="!isCharityType">
                <label for="successor_trustee_name" class="text-label-grey text-sm text-regular">
                  Successor Trustee</label>
                <input type="text"
                       class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                       placeholder="e.g. Jose Pedro Augustin"
                       [ngClass]="formGet['successor_trustee'].errors && formGet['successor_trustee'].touched?'form-input-error':'form-input-focus'"
                       formControlName="successor_trustee" name="successor_trustee" id="successor_trustee_name">
                <p *ngFor="let error of getErrors('successor_trustee')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <label for="tax_id" class="text-label-grey text-sm text-regular">
                  {{isRevocable ? 'SSN (Social Security Number)' : 'EIN (Employer Identification Number)'}}</label>
                <input type="text" [mask]="isRevocable ? SSN_MASK : EIN_MASK" [dropSpecialCharacters]="false"
                       class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                       placeholder="{{isRevocable ?'e.g. 255-34-9345':'e.g 45-3676756'}}" formControlName="tax_id"
                       name="tax_id" id="tax_id" (focusout)="onFocusout($event,'tax_id', 'trusteeType')">
                <p *ngFor="let error of getErrors('tax_id')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
                <div *ngIf="formGet['tax_id'].value && formGet['tax_id'].valid && isRevocable">
                  <div *ngIf="validatingSSN == 1" class="flex">
                    <p class="text-regular font-medium text-xs mt-2 checking-alert">
                      Validating SSN &nbsp;
                    </p>
                    <div class="loading-red mr-2"></div>
                  </div>
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                     *ngIf="validatingSSN == 2 && !isUniqueSSN">
                    SSN has already been entered.
                  </p>
                </div>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="!isCharityType">
                <label [for]="'dob_2'" class="text-label-grey text-sm text-regular">
                  Trustee Dated Date</label>
                <app-datepicker-toggle
                  inputID="dob_2"
                  class="mt-1"
                  [bsConfig]="bsConfig"
                  [useFormControl]="formGet['dated_date']">
                </app-datepicker-toggle>
                <p *ngFor="let error of getErrors('dated_date')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
          </div>

          <!-- Phone Number -->
          <div *ngIf="beneficiaryType === beneficiaryTypesConst.trustee" class="my-6">
            <div class="flex flex-row items-baseline" *ngIf="!isCharityType">
              <div>
                <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                  Phone Number and Email Address
                </h4>
              </div>
            </div>

            <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
              <div class="col-span-12">
                <label class="text-label-grey text-sm text-regular">
                  {{labelMappings?.[isCharityType]?.[2] }}</label>
                <app-phone-input [placeholder]="'e.g. 8665044754'"
                                 [phoneNumber]="this.beneficiaryForm['controls'].individualType.value.phone?this.beneficiaryForm['controls'].individualType.value.phone:this.beneficiaryForm['controls'].trusteeType.value.phone"
                                 [countryCode]="countryCode" [uniqueId]="'phone-beneficiary'"
                                 (onChange)="detectChangePhoneNumber($event)"
                                 [errorClassTrigger]="formGet?.['phone']?.errors && formGet['phone']?.touched"
                                 [form]="getSelectedForm"
                                 (focusout)="formGet?.['phone'].markAsTouched()">
                </app-phone-input>
                <p *ngFor="let error of getErrors('phone')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12" *ngIf="!isCharityType">
                <label for="email" class="text-label-grey text-sm text-regular">
                  Trustee Email Address</label>
                <input type="email"
                       class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                       placeholder="e.g. marktylor@gmail.com" formControlName="email" name="email" id="email"
                       (focusout)="formGet['email'].markAsTouched()">
                <p *ngFor="let error of getErrors('email')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>

            </div>
          </div>

          <!-- Address -->
          <div *ngIf="beneficiaryType === beneficiaryTypesConst.trustee && !isCharityType">
            <div class="mb-4">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Trustee Address
              </h4>
              <p class="text-grey text-regular pt-1 text-sm">Make sure to enter their address including their postcode.
              </p>
            </div>
            <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
              <div class="col-span-12">
                <label for="country" class="text-label-grey text-sm text-regular">Country</label>
                <app-country-search-component id="country" (optionChangeEmitter)="optionChangeListenerCountry($event)"
                                              [defaultSelected]="formGet?.['country']?.value"
                                              (focusout)="markAsTouchedIndividual('country')">
                </app-country-search-component>
              </div>
              <div class="col-span-12 lg:col-span-8 relative move-map" #moveMapHere>
                <label for="address" class="text-label-grey text-sm text-regular">Street
                  Address</label>
                <input type="text" #search (keyup)="onAddressChange(search)"
                       (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null" (input)="onFocusAddress()"
                       class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                       placeholder="e.g Main St" name="address" formControlName="address" id="address"
                       (focusout)="formGet['address'].markAsTouched()">
                <p *ngFor="let error of getErrors('address')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>

              </div>
              <div class="col-span-12 lg:col-span-4">
                <label for="apt_number" class="text-label-grey text-sm text-regular">Apartment / Suite #</label>
                <input name="apt_number" id="apt_number" formControlName="apt_number" placeholder="e.g 2a"
                       class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                       [ngClass]="formGet['apt_number']?.errors && formGet['apt_number']?.touched?'form-input-error':'form-input-focus'">
                <p *ngFor="let error of getErrors('apt_number')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12" *ngIf="selectedCountryName!==unitedKingdom">
                <label class="text-label-grey text-sm text-regular">State/Province</label>
                <app-state-search-component [countryCode]="formGet?.['country']?.value"
                                            [defaultSelected]="formGet?.['state']?.value"
                                            (optionChangeEmitter)="optionChangeListenerState($event)"
                                            tabindex="0"
                                            (focusout)="markAsTouchedIndividual('state')"></app-state-search-component>
              </div>
              <div class="col-span-12 md:col-span-6 relative ">
                <label class=" text-label-grey text-sm text-regular ">City</label>
                <app-city-search [stateCode]="formGet?.['state']?.value" [defaultSelected]="formGet?.['city']?.value"
                                 (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0"
                                 (focusout)="markAsTouchedIndividual('city')"></app-city-search>
              </div>
              <div class=" col-span-12 md:col-span-6 ">
                <label class="text-label-grey text-sm text-regular">Zip/Postal
                  Code</label>
                <input type="text" (focusout)="markAsTouchedIndividual('zipcode')"
                       class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
                       placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                       name="zipcode" formControlName="zipcode" oninput="this.value = this.value.toUpperCase()"
                       (focus)="validateZipCode()">
                <p *ngFor="let error of getErrors('zipcode')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
          </div>
          <!-- Submit/Update/Remove -->
          <div class="flex flex-col-reverse sm:flex sm:flex-row gap-4 justify-between items-center mt-8"
               *ngIf="beneficiaryType === beneficiaryTypesConst.trustee ">
            <app-button-loader class="w-full sm:w-auto" *ngIf="isUpdate" [buttonText]="'Remove Beneficiary'"
                               (click)="confirmDelete()" [loader]="submitLoaderDelete" [buttonType]="'button'"
                               [outlineOnly]="true">
            </app-button-loader>
            <app-button-loader class="w-full sm:w-auto"
                               [buttonText]="updateText"
                               [loader]="submitLoader"
                               [buttonType]="'submit'"></app-button-loader>
          </div>
        </div>
      </form>
    </div>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>
