import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
})
/**
 * Assets component
 */
export class AssetsComponent implements OnInit {
  public userType:string;
  public requestId:string;
  /**
   * @constructor
   */
  constructor(
    private route:ActivatedRoute,
    private localStorageService:LocalStorageService,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.requestId=this.route.snapshot.params['id'];
    this.userType=this.localStorageService.getDataByKey('role');
  }
}
