import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { USER_TYPES } from 'src/constants/application.const';
import { SlugInterceptorService } from '../../../helper/slug-interceptor.service';
import { ProfessionalPermissionService } from '../../../professional-dashboard/services/professional-permission.service';
import { ReceivedDlbService } from '../../../services/received-dlb.service';


@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
})
/**
 * Insurance overview component
 */
export class InsuranceComponentOverview implements OnInit {
  public initialDataLoader: boolean;
  public initialLoader: boolean;
  public permissionStatus: boolean;
  public permissionEdit: boolean;
  public insuranceDetails: Array<any> = [];
  public requestIdParam: string;
  public clientRequestId: string;
  public userType: string;
  public permissionList: Array<any>;
  public permission: Array<any>;
  public annuitiesDetails: Array<any> = [];
  public healthInsuranceDetails: Array<any> = [];
  public longTermCareDetails: Array<any> = [];
  public autoInsuranceDetails: Array<any> = [];
  public dentalDetails: Array<any> = [];
  public lifeInsuranceDetails: Array<any> = [];
  public visionInsuranceDetails: Array<any> = [];
  public otherInsuranceDetails: Array<any> = [];
  public domainViewDateFormat: string;
  public isAccessingVault: boolean;
  private transferDataPaid: boolean = false;
  public isVaultClaimed: boolean;
  public userRequestID: string;

  /**
   * @constructor
   */
  constructor(
    private personalDetailsService: PersonalDetailsService,
    private localStorageService: LocalStorageService,
    private commonHelper: CommonHelper,
    private activeRouter: ActivatedRoute,
    private toastr: ToastrService,
    private proPermissionService: ProfessionalPermissionService,
    private slugInterceptorService: SlugInterceptorService,
    private receivedDlbService: ReceivedDlbService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.userRequestID = this.commonHelper.getRequestId(this.activeRouter);
    this.initTransferDataPaid();
    this.domainViewDateFormat = this.commonHelper.domainViewDateFormat;
    this.isAccessingVault = this.localStorageService.getDataByKey('accessVault');
    this.initialDataLoader = true;
    this.requestIdParam = this.commonHelper.encodeDecodeRequestId(this.activeRouter.snapshot.parent.params?.id, false) || this.activeRouter.snapshot.parent.params?.id || this.localStorageService.getDataByKey('req_id');
    this.clientRequestId = this.localStorageService.getDataByKey('req_id');
    this.userType = this.localStorageService.getDataByKey('role');
    this.insuranceDetails = [];
    this.getAccessControl();
    this.isVaultClaimed = this.localStorageService.getDataByKey('isVaultClaimed') == 1;
  }

  private initTransferDataPaid() {
   this.commonHelper.isCustodian && this.receivedDlbService.isTransferPaid(this.userRequestID).subscribe({
      next: r => {this.localStorageService.storeData('is_transfer_amount_paid', r)},
      error: e => console.error(e)
    });
  }

  /**
   * get insurance details
   */
  public getInsuranceDetails(): void {
    this.initialDataLoader = true;
    if(this.commonHelper.isClaimedAndNotPaid) return; // handle custodian claimed valut but not paid transfer fee

    if (this.permissionStatus) {
      this.personalDetailsService.getPersonalDetailsWithBeneficiary(this.userType === 'Consumer' || (this.commonHelper.isCustodian && !this.isAccessingVault) ? this.requestIdParam : this.clientRequestId).subscribe((response: APIResponseModel) => {
        if (response.status) {
          this.insuranceDetails = response?.data?.user_insurance;
          this.annuitiesDetails = this.insuranceDetails.filter((item) => {
            return item?.insurance_type?.name === 'Annuity';
          });
          this.healthInsuranceDetails = this.insuranceDetails.filter((item) => {
            return item?.insurance_type?.name === 'Health';
          });
          this.longTermCareDetails = this.insuranceDetails.filter((item) => {
            return item?.insurance_type?.name === 'Long-Term Care';
          });
          this.autoInsuranceDetails = this.insuranceDetails.filter((item) => {
            return item?.insurance_type?.name === 'Auto';
          });
          this.dentalDetails = this.insuranceDetails.filter((item) => {
            return item?.insurance_type?.name === 'Dental';
          });
          this.lifeInsuranceDetails = this.insuranceDetails.filter((item) => {
            return item?.insurance_type?.name === 'Life';
          });
          this.visionInsuranceDetails = this.insuranceDetails.filter((item) => {
            return item?.insurance_type?.name === 'Vision';
          });
          this.otherInsuranceDetails = this.insuranceDetails.filter((item) => {
            return item?.insurance_type?.name === 'Other';
          });
        }
      }, (exception: any) => {
        this.commonHelper.httpResponseHandler(exception?.error);
      }, () => this.initialDataLoader = false);
    } else {
      this.initialDataLoader = false;
    }
  }

  /**
   * get access control list
   */
  public getAccessControl() {
    if (this.userType === 'Consumer' || (this.commonHelper.isCustodian && this.localStorageService.getDataByKey('isVaultClaimed'))) {
      this.permissionEdit = true;
      this.permissionStatus = true;
      this.initialLoader = false;
      this.getInsuranceDetails();
    } else {
      const requestId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('request_user_id') : this.userRequestID;
      const userId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('user_id') : this.localStorageService.getDataByKey('agent_id') || this.localStorageService.getUserData(USER_TYPES.pro)?.user?.id;
      this.proPermissionService.getAccessControl(userId, requestId, ['Insurance']).subscribe({
        next: (permissions) => {
          if (permissions?.['Insurance']) {
            this.permissionEdit = permissions?.['Insurance']['add'] || permissions?.['Insurance']['edit'] || permissions?.['Insurance']['delete'];
            this.permissionStatus = permissions?.['Insurance']['view'] || this.permissionEdit;
          }
        },
        error: (exception: any) => (this.commonHelper.httpResponseHandler(exception?.error)),
        complete: () => {
          this.getInsuranceDetails();
          // If Professional doesn't have permission to view/edit/add/delete, then redirect to general overview.
          if (!this.permissionStatus) {
            this.toastr.info('You do not have permission to view this information. You will be redirected to a general overview.');
            void this.slugInterceptorService.navigate(['/overview', this.clientRequestId]);
          }
          this.initialLoader = false;
        }
      }
      );
    }
  }

  /**
   * function to return index in asc order
   * @param {any} data
   * @param {number} value
   * @return {any} result
   */
  public indexHandler(data: any, value: number) {
  const result: any = [];
  data.forEach((element) => {
    if (element.pivot.type == value) {
      result.push(element);
    }
  });
  return result;
}

  /**
   * Accessor for commonHelper in template
   */
  get getCommonHelper(): CommonHelper {
  return this.commonHelper;
}
}
