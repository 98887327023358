import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * Digital files Services
 */
export class DigitalFilesService {
  private BASE_URL: string;
  /**
   * @constructor
   */
  constructor(
    private http: HttpClient,
  ) {
    this.BASE_URL = environment.BASE_URL;
  }

  /**
   * Store digital files
   *
   * @param {any} file
   * @return {Observable<any>}
   */
  public storeDigitalFiels(file: any): Observable<any> {
    const req = new HttpRequest('POST', `${this.BASE_URL}/request/store-digital-files`, file, {
      reportProgress: true,
    });
    return this.http.request(req);
  }

  /**
   * Import clients
   *
   * @param {any} file
   * @return {Observable<any>}
   */
  public storeClientBulkImport(file: any): Observable<any> {
    const req = new HttpRequest('POST', `${this.BASE_URL}/professional/client-import-file`, file, {
      reportProgress: true,
    });
    return this.http.request(req);
  }
    /**
   * Import employees
   *
   * @param {any} file
   * @return {Observable<any>}
   */
    public storeEmployeeBulkImportProfessional(file: any): Observable<any> {
      const req = new HttpRequest('POST', `${this.BASE_URL}/professional/employee-import-file`, file, {
        reportProgress: true,
      });
      return this.http.request(req);
    }
  /**
   * Import employees
   *
   * @param {any} file
   * @return {Observable<any>}
   */
  public storeEmployeeBulkImportOrganization(file: any): Observable<any> {
    const req = new HttpRequest('POST', `${this.BASE_URL}/organization/employee-import-file`, file, {
      reportProgress: true,
    });
    return this.http.request(req);
  }
}
