import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CommonModelService } from '../../services/common-model.service';


@Component({
  selector: 'app-digital-vault-users-agreement-modal',
  templateUrl: './digital-vault-users-agreement-modal.component.html',
  styleUrls: ['./digital-vault-users-agreement-modal.component.css'],
})
export class DigitalVaultUsersAgreementModalComponent {
  @Input() modalID: string = 'digital-vault-users-agreement-modal';
  @Output() acceptEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  mobile: boolean;

  constructor(private modalService: CommonModelService) {
  }

  /**
   * window resize
   */
  @HostListener('window:resize')
  onResize() {
    const innerWidth = window.innerWidth;
    this.mobile = innerWidth <= 767;
  }

  closeModal(accepted: boolean) {
    this.modalService.close(this.modalID);
    this.acceptEmitter.emit(accepted);
  }
}
