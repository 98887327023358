import { Component, OnInit } from '@angular/core';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-consumer-focused-footer',
  templateUrl: './consumer-focused-footer.component.html',
  styleUrls: ['./consumer-focused-footer.component.css']
})
export class ConsumerFocusedFooterComponent implements OnInit {
  public customerSupportMail: string;
  public customerSupportPhone: number;

  constructor(private slugInterceptorService: SlugInterceptorService,
    private commonService: CommonService) { }

  ngOnInit(): void {
    this.getContactDetails();
  }

  /** On Menu Change
   * @param{string}url
   *
   */
  public menuChange(url?: string) {
    if (url) {
      void this.slugInterceptorService.navigate([url]);
      this.commonService.setActiveNavbarTrigger(url);
    }
  }

  /**
   * Retrieves contact details
   */
  public getContactDetails() {
    this.commonService.getContactUsDetails().subscribe((response) => {
      this.customerSupportMail = response.data.support_email;
      this.customerSupportPhone = response.data.support_number;
    })
  }

}
