import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardProfessional } from 'src/app/helper/authGuardProfessional.helper';
import { ExpiryNotificationTsGuard } from 'src/app/helper/expiry-notification.helper.ts.guard';
import { ConfirmAccountProfessionalComponent } from './confirm-account-professional/confirm-account-professional.component';
import { InvalidIdComponent } from './invalid-id/invalid-id.component';
import { CaliforniaPrivacyComponent } from './landing-page/california-privacy/california-privacy.component';
import { DigitalVaultAgreementProComponent } from './landing-page/digital-vault-agreement-pro/digital-vault-agreement-pro.component';
import { DigitalVaultLicensingAgreementProComponent } from './landing-page/digital-vault-licensing-agreement-pro/digital-vault-licensing-agreement-pro.component';
import { UsPrivacyPolicyComponent } from './landing-page/us-privacy-policy/us-privacy-policy.component';
import { UsePolicyProComponent } from './landing-page/use-policy-pro/use-policy-pro.component';
import { UserAgreementProComponent } from './landing-page/user-agreement-pro/user-agreement-pro.component';
import { ProCheckoutComponent } from './pro-checkout/pro-checkout.component';
import { ProfessionalAccountSettingsComponent } from './professional-account-settings/professional-account-settings.component';
import { EditProfessionalAgentComponent } from './professional-agents/edit-professional-agent/edit-professional-agent.component';
import { ProfessionalForgotPasswordComponent } from './professional-auth/professional-forgot-password/professional-forgot-password.component';
import { ProfessionalLoginComponent } from './professional-auth/professional-login/professional-login.component';
import { ProfessionalChangePasswordComponent } from './professional-change-password/professional-change-password.component';
import { AddProfessionalClientComponent } from './professional-clients/add-professional-client/add-professional-client.component';
import { HomeProfessionalClientComponent } from './professional-clients/home-professional-client/home-professional-client.component';
import { ProfessionalClientsComponent } from './professional-clients/professional-clients.component';
import { ProfessionalConsumerMessageComponent } from './professional-consumer-message/professional-consumer-message.component';
import { HomeProfessionalDashboardComponent } from './professional-dashboard/home-professional-dashboard/home-professional-dashboard.component';
import { ProfessionalDashboardComponent } from './professional-dashboard/professional-dashboard.component';
import { ProfessionalExpiryNotificationComponent } from './professional-dashboard/professional-expiry-notification/professional-expiry-notification.component';
import { ProfessionalManagementComponent } from './professional-management/professional-management.component';
import { ViewProfessionalManagementComponent } from './professional-management/view-professional-management/view-professional-management.component';
import { ProfessionalNotFoundComponent } from './professional-not-found/professional-not-found.component';
import { ProfessionalPaymentSettingsComponent } from './professional-payment-settings/professional-payment-settings.component';
import { ProfessionalPayoutComponent } from './professional-payout/professional-payout.component';
import { ProfessionalProfessionalMessageComponent } from './professional-professional-message/professional-professional-message.component';
import { ProfessionalSupportComponent } from './professional-support/professional-support.component';
import { ProfessionalUnauthorizedComponent } from './professional-unauthorized/professional-unauthorized.component';
import { ProfessionalComponent } from './professional.component';

const professionalManagementRoutes = [
  { path: '', component: ViewProfessionalManagementComponent},
  { path: 'add', component: EditProfessionalAgentComponent },
  { path: 'edit/:id', component: EditProfessionalAgentComponent },
];


const routes: Routes = [
  // { path: '', pathMatch: 'full', component: LandingPageComponent },
  // { path: ':slug', pathMatch: 'full', component: ProfessionalBannerComponent },
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  // { path: 'home', component: LandingPageComponent  },
  // { path: 'features', component: FeaturesComponent  },
  // { path: 'contact-us', component: ContactUsComponent  },
  // { path: 'pricing', component: PricingProComponent  },
  // { path: 'company', component: CompanyProComponent  },
  { path: 'privacy-policy', component: UsPrivacyPolicyComponent  },
  { path: 'acceptable-use-policy', component: UsePolicyProComponent  },
  { path: 'digital-vault-agreement', component: DigitalVaultAgreementProComponent  },
  { path: 'digital-vault-licensing-agreement', component: DigitalVaultLicensingAgreementProComponent  },
  { path: 'user-agreement', component: UserAgreementProComponent  },
  { path: 'california-privacy-policy', component: CaliforniaPrivacyComponent  },
  { path: 'confirm-account', component: ConfirmAccountProfessionalComponent  },
  { path: 'payment-settings', component: ProfessionalPaymentSettingsComponent, canActivate: [AuthGuardProfessional] },
  { path: 'checkout', component: ProCheckoutComponent, canActivate: [AuthGuardProfessional] },
  {
    path: '', component: ProfessionalComponent, children: [
      { path: 'login', component: ProfessionalLoginComponent },
      // { path: 'register', component: ProfessionalRegisterComponent  },
      {
        path: 'dashboard', component: ProfessionalDashboardComponent, canActivate: [AuthGuardProfessional], children: [
          { path: '', component: HomeProfessionalDashboardComponent },
          { path: 'pro-notifications', component: ProfessionalExpiryNotificationComponent, canActivate: [ExpiryNotificationTsGuard] },
        ],
      },
      { path: 'account-settings', component: ProfessionalAccountSettingsComponent, canActivate: [AuthGuardProfessional] },
      { path: 'payout-management', component: ProfessionalPayoutComponent, canActivate: [AuthGuardProfessional] },
      { path: 'unauthorized', component: ProfessionalUnauthorizedComponent },
      {
        path: 'clients', component: ProfessionalClientsComponent, canActivate: [AuthGuardProfessional], children: [
          { path: '', component: HomeProfessionalClientComponent },
          { path: 'add-client', component: AddProfessionalClientComponent },
        ],
      },
      {
        path: 'professional-management', component: ProfessionalManagementComponent, canActivate: [AuthGuardProfessional],
        children: professionalManagementRoutes,
      },
      // {
      //   path: 'managers', component: ProfessionalPartnersComponent, canActivate: [AuthGuardProfessional],
      //   children: professionalPartnerRoutes,
      // },
      // {
      //   path: 'project-managers', component: ProfessionalPartnersComponent,
      //   children: professionalPartnerRoutes,
      // },
      // {
      //   path: 'funeral-directors', component: ProfessionalPartnersComponent,
      //   children: professionalPartnerRoutes,
      // },
      // {
      //   path: 'advisors', component: ProfessionalAgentsComponent, canActivate: [AuthGuardProfessional],
      //   children: professionalAgentRoutes,
      // },
      // {
      //   path: 'professional', component: ProfessionalAgentsComponent, canActivate: [AuthGuardProfessional],
      //   children: professionalAgentRoutes, data:{isProfessional: true}
      // },
      // {
      //   path: 'attorney', component: ProfessionalAgentsComponent, canActivate: [AuthGuardProfessional],
      //   children: professionalAgentRoutes,
      // },
      // {
      //   path: 'sales-agents', component: ProfessionalAgentsComponent, canActivate: [AuthGuardProfessional],
      //   children: professionalAgentRoutes,
      // },
      { path: 'message', component: ProfessionalConsumerMessageComponent, canActivate: [AuthGuardProfessional] },
      { path: 'pro-message', component: ProfessionalProfessionalMessageComponent, canActivate: [AuthGuardProfessional] },
      { path: 'support', component: ProfessionalSupportComponent, canActivate: [AuthGuardProfessional] },
      { path: 'forgot-password-request', component: ProfessionalForgotPasswordComponent },
      { path: 'reset-password', component: ProfessionalChangePasswordComponent },
      { path: 'invalid-request', component: InvalidIdComponent },


    ],
  },

  // { path: ':pro-type', component: LandingPageComponent },

  // slug routes
  { path: ':slug', component: ProfessionalLoginComponent },
  // { path: ':slug/home', component: LandingPageComponent  },
  // { path: ':slug/features', component: FeaturesComponent  },
  // { path: ':slug/contact-us', component: ContactUsComponent  },
  // { path: ':slug/pricing', component: PricingProComponent  },
  // { path: ':slug/company', component: CompanyProComponent  },
  { path: ':slug/privacy-policy', component: UsPrivacyPolicyComponent  },
  { path: ':slug/acceptable-use-policy', component: UsePolicyProComponent  },
  { path: ':slug/digital-vault-agreement', component: DigitalVaultAgreementProComponent },
  { path: ':slug/digital-vault-licensing-agreement', component: DigitalVaultLicensingAgreementProComponent },
  { path: ':slug/user-agreement', component: UserAgreementProComponent },
  { path: ':slug/california-privacy-policy', component: CaliforniaPrivacyComponent },
  { path: ':slug/confirm-account', component: ConfirmAccountProfessionalComponent },
  { path: ':slug/checkout', component: ProCheckoutComponent, canActivate: [AuthGuardProfessional] },
  { path: ':slug/payment-settings', component: ProfessionalPaymentSettingsComponent, canActivate: [AuthGuardProfessional] },
  {
    path: ':slug', component: ProfessionalComponent, children: [
      { path: 'login', component: ProfessionalLoginComponent },
      // { path: 'register', component: ProfessionalRegisterComponent },
      {
        path: 'dashboard', component: ProfessionalDashboardComponent, canActivate: [AuthGuardProfessional], children: [
          { path: '', component: HomeProfessionalDashboardComponent },
          { path: 'pro-notifications', component: ProfessionalExpiryNotificationComponent, canActivate: [ExpiryNotificationTsGuard] },
        ],
      },
      { path: 'account-settings', component: ProfessionalAccountSettingsComponent, canActivate: [AuthGuardProfessional] },
      { path: 'payout-management', component: ProfessionalPayoutComponent, canActivate: [AuthGuardProfessional] },
      { path: 'unauthorized', component: ProfessionalUnauthorizedComponent },
      // {
      //   path: 'advisors', component: ProfessionalAgentsComponent, canActivate: [AuthGuardProfessional],
      //   children: professionalAgentRoutes,
      // },
      // {
      //   path: 'professional', component: ProfessionalAgentsComponent, canActivate: [AuthGuardProfessional],
      //   children: professionalAgentRoutes, data:{isProfessional: true}
      // },
      {
        path: 'clients', component: ProfessionalClientsComponent, canActivate: [AuthGuardProfessional], children: [
          { path: '', component: HomeProfessionalClientComponent },
          { path: 'add-client', component: AddProfessionalClientComponent },
        ],
      },
      {
        path: 'professional-management', component: ProfessionalManagementComponent, canActivate: [AuthGuardProfessional],
        children: professionalManagementRoutes,
      },
      // {
      //   path: 'managers', component: ProfessionalPartnersComponent, canActivate: [AuthGuardProfessional],
      //   children: professionalPartnerRoutes,
      // },
      // {
      //   path: 'project-managers', component: ProfessionalPartnersComponent,
      //   children: professionalPartnerRoutes,
      // },
      // {
      //   path: 'funeral-directors', component: ProfessionalPartnersComponent,
      //   children: professionalPartnerRoutes,
      // },
      // {
      //   path: 'advisors', component: ProfessionalAgentsComponent, canActivate: [AuthGuardProfessional],
      //   children: professionalAgentRoutes,
      // },
      // {
      //   path: 'professional', component: ProfessionalAgentsComponent, canActivate: [AuthGuardProfessional],
      //   children: professionalAgentRoutes, data:{isProfessional: true}
      // },
      // {
      //   path: 'attorney', component: ProfessionalAgentsComponent, canActivate: [AuthGuardProfessional],
      //   children: professionalAgentRoutes,
      // },
      // {
      //   path: 'sales-agents', component: ProfessionalAgentsComponent, canActivate: [AuthGuardProfessional],
      //   children: professionalAgentRoutes,
      // },
      { path: 'message', component: ProfessionalConsumerMessageComponent, canActivate: [AuthGuardProfessional] },
      { path: 'pro-message', component: ProfessionalProfessionalMessageComponent, canActivate: [AuthGuardProfessional] },
      { path: 'support', component: ProfessionalSupportComponent, canActivate: [AuthGuardProfessional] },
      { path: 'forgot-password-request', component: ProfessionalForgotPasswordComponent },
      { path: 'reset-password', component: ProfessionalChangePasswordComponent },
      { path: 'invalid-request', component: InvalidIdComponent },
      { path: '**', component: ProfessionalNotFoundComponent },

    ],
  },


];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
/**
 * professional routing module
 */
export class ProfessionalRoutingModule {


}


