<div class="scroll-hidden background-color-grey h-full">
  <!-- Navbar -->
  <app-professional-navbar></app-professional-navbar>
  <div class="grid grid-cols-12 px-8 sm:px-12 sm:py-4">
    <div class="col-span-12">
      <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
        Payment Settings
      </h2>
      <p class="text-base text-grey py-2 tracking-wide text-regular">Check and update payment
        information and history</p>
      <hr class="border-lightblue w-24 my-1 border-1">
    </div>
  </div>
  <div class="grid grid-cols-12 md:px-12  gap-6 md:gap-12">

    <!-- BEGIN: Payment Settings Chips -->
    <div class="col-span-12">
      <nav class="flex flex-col hidden lg:flex sm:flex-row border-b" aria-label="Navigation">
        <button *ngFor="let chip of chipsList"
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': menuActive === chip.value}"
          (click)="menuChange(chip.value)"
          class="text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          {{chip.displayText}}
        </button>
      </nav>
      <nav aria-label="Navigation">
        <div class="relative w-auto mt-6 lg:hidden">
          <div (click)="responsiveMenuToggle = !responsiveMenuToggle"
          class="cursor-pointer input py-2 h-12 px-4 lg:px-0 text-regular text-sm border rounded-xs appearance-none block focus:outline-none text-white flex flex-row items-center justify-between lg:justify-around">
            <a class="text-darkblue">{{mobileMenuName}}</a>
            <div>
              <img src="assets/images/about-yourself/angle-down-arrow.png" class="w-3 cursor-pointer"
                alt="digital vault">
            </div>
          </div>

          <div class="lg:hidden shadow-md cursor-pointer pb-4" id="mobile-menu-consumer" *ngIf="responsiveMenuToggle">
            <ul class="input w-full border up-arrow bg-white rounded-xs mt-4">
                <li *ngFor="let chip of chipsList" (click)="changeRoute('/PAYMENT SETTINGS')" (click)="onMobileMenuchange(chip.displayText)"
              [ngClass]="{'text-lightblue font-semibold ': menuActive === chip.value}"
              (click)="menuChange(chip.value)"
              class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer">
              {{chip.displayText}} 
                </li>
            </ul>
          </div>

        </div>
      </nav>
    </div>
    <!-- END: Payment Settings Chips -->

    <div class="col-span-12 lg:col-span-12 space-y-8">
      <!-- BEGIN: Payment Management -->
      <div *ngIf=" menuActive=== paymentSettingsChip.PaymentManagement">
        <div class="scroll-hidden box-border body-content">

          <!--first Section start-->
          <div class="mx-8">

            <div class="flex flex-wrap ">
              <div class="w-full md:w-3/5 px-2">
                <div>
                  <h4 class="text-semibold text-grey text-md md:text-lg  lg:text-xl xl:text-2xl">Payment Management</h4>
                  <p class="text-grey text-regular text-sm md:text-md lg:text-md mb-3">Payment details will be shown
                    here</p>
                </div>
              </div>

            </div>
            <!--end-->
            <div class="pt-4 mb-6">
              <div class="border-card bg-white rounded-md p-6 ">
                <!--start second section -->
                <div class="flex flex-wrap">
                  <div class="w-full lg:w-1/2 ">
                    <div class="relative">
                      <div class="sm:w-full md:w-2/4  relative h-10 text-grey ">
                        <div class=" absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"> <img
                            src=" assets/images/about-yourself/search.svg " class="w-4  h-4" alt="digital vault"> </div>
                        <input type=" text " class=" input py-3 px-4 mt-1 w-full text-regular text-grey
                                  rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                          placeholder="Search by Invoice Number" [(ngModel)]="searchKeyword"
                          (input)="onSearchInvoice()">
                      </div>
                    </div>
                  </div>
                </div>
                <!--end-->
                <div class="mt-5">
                  <div>
                    <table class="table w-full text-left pb-5 mb-5 " aria-describedby="payment">
                      <thead class="bg-gray-400 text-blue text-md text-regular ">
                        <tr>
                          <th class="p-4" scope="row">S.No</th>
                          <th class="p-4" scope="row">Invoice Date</th>
                          <th class="p-4" scope="row">Invoice Number</th>
                          <th class="p-4" scope="row">No. of Vaults created</th>
                          <th class="p-4" scope="row">Price per Vault</th>
                          <th class="p-4" scope="row">Total Vault Amount</th>
                          <th class="p-4" scope="row">Payment Status</th>
                        </tr>
                      </thead>


                      <ng-container *ngIf="!loader">

                        <tbody *ngFor="let data of paymentData;let i = index">
                          <tr class="border-b-2 border-fuchsia-600 p-4 relative" *ngIf="paymentData.length > 0">
                            <td class="p-2 lg:p-4">


                              <p class="text-grey text-semibold  cursor-pointer text-lg  text-regular">
                                {{fromNo+i}}
                              </p>


                            </td>
                            <td class="p-4">
                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{data?.invoice_date | date:'MM/dd/YYYY'}}</p>
                            </td>
                            <td class="p-4">
                              <p class="text-grey text-semibold  text-sm text-xs md:text-sm  ">
                                {{data?.invoice_number}} </p>
                            </td>
                            <td class="p-4">


                              <p class="text-grey text-semibold  text-sm  text-regular">
                                {{data?.vault_count}}
                              </p>

                            </td>
                            <td class="p-4">

                              <p class="text-grey text-semibold  text-sm  text-regular">
                                {{'$' + data?.price_per_vault}} </p>

                            </td>
                            <td class="p-4">

                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{'$' + data?.vault_amount}} </p>

                            </td>
                            <!-- Hided because of payment status change from admin -->
                            <td class="p-4">
                              <ng-container *ngIf="data.status === 0">
                                <a *ngIf="this.roleName === PROFESSIONAL_ROLE.professional"
                                  (click)="onClickPayNow(data)"
                                  class="text-blue-900 text-semibold text-md underline cursor-pointer text-md lg:text-lg text-regular">Pay
                                  Now</a>
                                <a *ngIf="this.roleName !== PROFESSIONAL_ROLE.professional"
                                  class="text-blue-900 text-semibold text-md text-md lg:text-lg text-regular">Payment
                                  Pending</a>
                              </ng-container>
                              <a *ngIf="data.status === 1"
                                class="text-green-900 text-semibold text-md text-md lg:text-lg text-regular">Paid
                              </a>
                              <a *ngIf="data.status === 2"
                                class="text-red-500 text-semibold text-md  text-md lg:text-lg text-regular">Missed
                                Payment</a>
                            </td>
                            <!-- <div [ngClass]="data.status==0? 'cursor-pointer': ''"
                              class="show mt-2 py-1 absolute right-0 top-6 w-44 rounded-md shadow-lg  bg-white focus:outline-none"
                              role="menu" aria-orientation="vertical" aria-labelledby="user-menu"
                              *ngIf="profileMenuToggle && (this.index === i ) && data.status==0">
                              <a class="block px-4 py-2 text-sm text-grey text-regular cursor-pointer hover:bg-gray-100" role="menuitem"
                                (click)="onClickPayNow(data)">Pay Now</a>
                            </div> -->

                          </tr>


                        </tbody>

                      </ng-container>

                    </table>
                    <div class="flex justify-center" *ngIf="loader">
                      <div class="w-12 h-12 border-b-2 border-red rounded-full animate-spin mt-3 "></div>
                    </div>
                    <div class=" flex justify-center" *ngIf="paymentData?.length === 0 && !loader">
                      <h4 class="text-grey text-base lg:text-md text-regular mt-3">
                        No data found
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-8">
                <nav class="flex flex-row flex-nowrap justify-between md:justify-center items-center"
                  aria-label="Pagination">
                  <a *ngIf="currentPage > 1" (click)="getPaymentDetailsByPage( currentPage > 1 ? currentPage-1 : 0 )"
                    class="flex w-7 h-7 mr-1 justify-center items-center text-black cursor-pointer "
                    title="Previous Page">
                    <span class="sr-only">Previous Page</span>
                    <img src="assets/images/dashboard/left-arrow-enable.png" alt="digital vault" />
                  </a>
                  <a *ngIf="currentPage === 1" class="flex w-7 h-7 mr-1 justify-center items-center text-black "
                    class="flex w-7 h-7 mr-1 justify-center items-center text-black " title="Previous Page">
                    <span class="sr-only">Previous Page</span>
                    <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                    <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                  </a>
                  <ng-container *ngFor="let page of paginationLinks;let i = index;">
                    <a *ngIf=" i <= lastPage && i > 0 " (click)="getPaymentDetailsByPage(i)"
                      [ngClass]="{ 'bg-darkblue' : currentPage === i , 'text-white' : currentPage === i}"
                      class=" md:flex w-7 h-7 mx-1 justify-center items-center  text-black text-center cursor-pointer "
                      title="Page {{i}}">
                      {{i}}
                    </a>
                  </ng-container>
                  <a (click)="getPaymentDetailsByPage( currentPage + 1 )" *ngIf="(currentPage) <  lastPage"
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black cursor-pointer " title="Next Page">
                    <span class="sr-only">Next Page</span>
                    <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                    <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                  </a>
                  <a *ngIf="(currentPage) ===  lastPage"
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black "
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black " title="Next Page">
                    <span class="sr-only">Next Page</span>
                    <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                    <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>



      </div>
      <!-- END: Payment Management -->

      <!-- BEGIN:Payment History -->
      <div *ngIf="menuActive=== paymentSettingsChip.PaymentHistory">
        <div class="scroll-hidden box-border body-content">

          <!--first Section start-->
          <div>
            <div>
              <div class="flex flex-wrap ">
                <div class="w-full md:w-3/5 px-2">
                  <div>
                    <h4 class="text-semibold text-grey text-md md:text-lg  lg:text-xl xl:text-2xl">Payment History
                    </h4>
                    <p class="text-grey text-regular text-sm md:text-md lg:text-md mb-3">Payment history and details
                      will be shown
                      here</p>
                  </div>
                </div>
              </div>
            </div>
            <!--end-->
            <div class="pt-4 mb-6">
              <div class="border-card bg-white rounded-md p-6 mb-2">
                <!--start second section -->
                <div class="flex flex-wrap">
                  <div class="w-full lg:w-2/4 ">
                    <div class="relative">
                      <div class="sm:w-full md:w-2/4  relative h-10 text-grey ">
                        <div class=" absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"> <img
                            src=" assets/images/about-yourself/search.svg " class="w-4  h-4" alt="digital vault"> </div>
                        <input type=" text " class=" input py-3 px-4 mt-1 w-full text-regular text-grey
                                  rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                          placeholder="Search by Invoice Number" [(ngModel)]="searchKeyword"
                          (input)="getPaymentDetails()">
                      </div>
                    </div>
                  </div>
                  <div class="w-full lg:w-2/4">
                    <div class="flex flex-wrap items-center justify-around">
                      <div></div>

                      <div>
                        <div class=" md:justify-end">
                          <div class="flex justify-center item-center text-grey mt-4">
                            <div
                              class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                              Showing: </div>
                            <select [(ngModel)]="paymentCount" (change)="getPaymentDetailsByPage()"
                              class="bg-transparent pt-1 ml-4 w-20 mt-0 w-2/4 rounded-xs h-10 text-regular text-grey  p-2 border-none block focus:outline-none insurance_term">
                              <option *ngFor="let option of selectOptions;let i=index" [value]="option.id">
                                {{option.value}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class=" md:justify-end css-shortby">
                        <div class="md:justify-end">
                          <div class="flex justify-center item-center h-10 text-grey mt-4">
                            <div
                              class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                              Sort by: </div>
                            <select [(ngModel)]="paymentOrder" (change)="getPaymentDetailsByPage()"
                              class="bg-transparent ml-4 mt-0 w-9/12 rounded-xs h-10 text-regular text-grey  p-2 border-none block focus:outline-none insurance_term">
                              <option *ngFor="let order of orderOptions;let i=index" [value]="order.id">{{order.value}}
                              </option>

                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end-->
                <div class="mt-5">
                  <div>
                    <table class="table w-full text-left pb-5 mb-5 " aria-describedby="payment">
                      <thead class="bg-gray-400 text-blue text-md text-regular ">
                        <tr>
                          <th class="p-4" scope="row">S.No</th>
                          <th class="p-4" scope="row">Invoice Date</th>
                          <th class="p-4" scope="row">Invoice Number</th>
                          <th class="p-4" scope="row">No. of Vaults created</th>
                          <th class="p-4" scope="row">Price per Vault</th>
                          <th class="p-4" scope="row">Total Vault Amount</th>
                          <th class="p-4" scope="row">Payment Status</th>
                          <th class="p-4" scope="row">Payment Date</th>
                        </tr>
                      </thead>

                      <ng-container *ngIf="!loader">

                        <tbody *ngFor="let data of paymentData;let i = index">
                          <tr class="border-b-2 border-fuchsia-600 p-4 relative" *ngIf="paymentData.length > 0">
                            <td class="p-2 lg:p-4">
                              <p class="text-grey text-semibold  cursor-pointer text-md  text-regular">
                                {{fromNo+i}}
                              </p>
                            </td>
                            <td class="p-4">
                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{data?.invoice_date | date:'MM/dd/YYYY'}}</p>
                            </td>
                            <td class="p-4">
                              <p class="text-grey text-semibold text-sm text-xs md:text-sm  ">
                                {{data?.invoice_number}} </p>
                            </td>
                            <td class="p-4">


                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{data?.vault_count}}
                              </p>

                            </td>
                            <td class="p-4">

                              <p class="text-grey text-semibold  text-sm  text-regular">
                                {{'$' + data?.price_per_vault}} </p>

                            </td>
                            <td class="p-4">

                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{'$' + data?.vault_amount}} </p>

                            </td>
                            <td class="p-4" *ngIf="data?.status === 0">

                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{'Pending'}} </p>

                            </td>
                            <td class="p-4" *ngIf="data?.status === 1">

                              <p class="text-green text-semibold text-sm  text-regular">
                                {{'Paid'}} </p>

                            </td>
                            <td class="p-4" *ngIf="data?.status === 2">

                              <p class="text-red text-semibold text-sm  text-regular">
                                {{'Missed Payment'}} </p>

                            </td>
                            <td class="p-4">

                              <p class="text-grey text-semibold text-sm  text-regular">
                                {{data?.updated_at | date: 'MM/dd/YYYY' }} </p>

                            </td>
                          </tr>
                        </tbody>

                      </ng-container>

                    </table>
                    <div class="flex justify-center" *ngIf="loader">
                      <div class="w-12 h-12 border-b-2 border-red rounded-full animate-spin mt-3 "></div>
                    </div>
                    <div class=" flex justify-center mt-5 " *ngIf="paymentData?.length === 0 && !loader">
                      <h4 class="text-grey text-base lg:text-md text-regular">

                        No data found
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-8">
                <nav class="flex flex-row flex-nowrap justify-between md:justify-center items-center"
                  aria-label="Pagination">
                  <a *ngIf="currentPage > 1" (click)="getPaymentDetailsByPage( currentPage > 1 ? currentPage-1 : 0 )"
                    class="flex w-7 h-7 mr-1 justify-center items-center text-black cursor-pointer "
                    title="Previous Page">
                    <span class="sr-only">Previous Page</span>
                    <img src="assets/images/dashboard/left-arrow-enable.png" alt="digital vault" />
                  </a>
                  <a *ngIf="currentPage === 1" class="flex w-7 h-7 mr-1 justify-center items-center text-black "
                    class="flex w-7 h-7 mr-1 justify-center items-center text-black " title="Previous Page">
                    <span class="sr-only">Previous Page</span>
                    <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                    <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                  </a>
                  <ng-container *ngFor="let page of paginationLinks;let i = index;">
                    <a *ngIf=" i <= lastPage && i > 0 " (click)="getPaymentDetailsByPage(i)"
                      [ngClass]="{ 'bg-darkblue' : currentPage === i , 'text-white' : currentPage === i}"
                      class=" md:flex w-7 h-7 mx-1 justify-center items-center  text-black text-center cursor-pointer "
                      title="Page {{i}}">
                      {{i}}
                    </a>
                  </ng-container>
                  <a (click)="getPaymentDetailsByPage( currentPage + 1 )" *ngIf="(currentPage) <  lastPage"
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black cursor-pointer " title="Next Page">
                    <span class="sr-only">Next Page</span>
                    <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                    <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                  </a>
                  <a *ngIf="(currentPage) ===  lastPage"
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black "
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black " title="Next Page">
                    <span class="sr-only">Next Page</span>
                    <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                    <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>



      </div>
      <!-- END:Payment History -->

      <!-- BEGIN:Manage Cards -->
      <div *ngIf="menuActive=== paymentSettingsChip.ManageCards">
        <app-card-details [showNavBar]="false"></app-card-details>
      </div>
      <!-- END:Manage Cards -->


      <!-- BEGIN: Invoice Settings -->
      <div *ngIf=" menuActive=== paymentSettingsChip.InvoiceSettings">
        <div class="scroll-hidden box-border body-content">
          <div class="mx-8">
            <div class="pt-4 mb-6">
              <!-- BEGIN : Invoice Settings Section -->
              <div class="grid grid-cols-12 gap-6 bg-white mx-8 md:mx-0 border-card rounded  p-6">
                <div class="col-span-12">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    Invoice Settings
                  </h4>
                </div>
                <div class="col-span-12 md:col-span-12">
                  <!-- BEGIN : Invoice Settings Form -->
                  <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mb-5">
                    <!-- Invoice Email Alerts -->
                    <div class="col-span-6 md:col-span-12 lg:col-span-6">
                      <label for="radio" class="text-sm mb2 text-regular text-lg text-label-grey">How you would like
                        to
                        receive payment invoice email alerts?
                      </label>
                      <span>
                        <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                          [styles]="{'stroke': 'red', 'color': '#9CA3AF'}">
                        </fa-icon>
                      </span>
                      <div class="md:flex justify-between items block mt-2">
                        <div id="radio" class="form-check" *ngFor="let type of invoiceTypes;let i=index">
                          <input class="form-check-input text-regular  text-grey" type="radio" name="radios"
                            id="radios_{{i}}" [value]="type?.value" (change)="onSelectInvoiceType($event)"
                            [checked]="selectedChecked('invoice',type?.value)">
                          <label class="form-check-label-size text-semibold text-grey placeholder-gray-400 text-md"
                            for="radios_{{i}}">
                            {{type?.displayText}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END : Invoice Settings Form -->
                </div>

                <div class="col-span-6 lg:col-span-12">
                  <div class="grid justify-items-end">
                    <!-- Invoice Settings Form: Save Details Button -->
                    <app-button-loader [buttonText]="'Save Details'" [valid]="true" (click)="updateInvoiceSettings()"
                      [buttonClass]="'button button--lg bg-darkblue  px-4 rounded-xs h-10 focus:outline-none text-regular text-white text-sm'"
                      [loader]="invoiceSettingsLoader" buttonType="'button'">
                    </app-button-loader>
                  </div>
                </div>
              </div>
              <!-- END : Invoice Settings Section -->
            </div>
          </div>
        </div>
      </div>
      <!-- END: Invoice Settings -->
    </div>

  </div>
</div>
<app-payment-success></app-payment-success>