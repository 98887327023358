<div class="flex justify-center loader " *ngIf="spinner ">
  <div class="w-16 h-16  border-b-2 border-red rounded-full animate-spin mt-3 "></div>
</div>
<div class="scroll-hidden" *ngIf="!spinner && !isMailExpired">
  <div class="h-screen lg:grid grid-cols-12">
    <!-- BEGIN: Login Form -->
    <div class="col-span-12 md:col-span-6 xl:col-span-5 h-screen lg:h-auto p-6 flex">
      <div class="flex flex-col w-full">
        <!-- BEGIN: Logo -->
        <div class="md:pl-2 flex items-center gap-2 sm:gap-4 h-16 flex-row">
          <a (click)="onClickLogo()" class="flex items-center cursor-pointer">
            <img alt="The-Estate-Registry" width="250" [src]="'assets/images/logo-old.png'" class="object-contain w-40 sm:w-48">
          </a>
          <a *ngIf="orgLogoPath"  class=" border-l-black pl-6 cursor-pointer max-h-12 maxw128">
            <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
          </a>
          <!-- <div class="flex items-start gap-1 md:gap-2 flex-col" *ngIf="orgLogoPath">
            <p class="whitespace-nowrap text-xs md:text-sm lg:text-regular text-dark-grey">Sponsored by: </p>

            <a *ngIf="orgLogoPath" class="cursor-pointer max-h-12 maxw128">
              <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">

            </a>
          </div> -->
        </div>
        <!-- END: Logo -->
        <div class="h-full flex items-center justify-center mt-4">
          <div class="my-auto mx-auto bg-white pt-6 sm:pl-10 xl:bg-transparent sm:px-8 w-full">
            <div class="h-full justify-center items-center ">
              <!--mail conformation image-->
              <img src="assets/images/dashboard/mail-verfication.png" class="mt-3 mb-4 w-28 object-contain h-full"
                alt="digital vault">
              <!--end-->
              <!--start mail confirm text-->
              <div class="my-3">
                <!--text warpper-->
                <h2 class="text-darkblue text-regular font-semibold text-left text-4xl leading-tight pt-4 pb-4">
                  Your Email Address has been Verified
                </h2>
                <p class="text-regular text-grey text-xl  mx-auto text-left mb-3">
                  Thank you for verifying your email address. You may now begin using the LegacyNOW Digital Vault.
                </p>
                <div class="pt-6">
                  <app-button-loader (click)="redirectToLogin()"
                    [buttonText]="confirmLoader?'Verifying Account':'Continue to Login'" [valid]="!confirmLoader"
                    [loader]="false" [buttonType]="'button'"></app-button-loader>
                </div>
                <!--end-->
              </div>
              <!--end-->
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- END: Login Form -->

    <!-- BEGIN: Login Info -->
    <div class="md:col-span-6 xl:col-span-7 hidden lg:flex bg-login-grey min-h-screen justify-center">
      <div class="fixed h-full flex flex-col items-center justify-center">
        <!-- BEGIN: First Slide -->
        <app-right-carousel-intro></app-right-carousel-intro>
      </div>
    </div>
    <!-- END: Login Info -->
  </div>
</div>

<div class="scroll-hidden" *ngIf="isMailExpired">
  <div class="h-screen lg:grid grid-cols-12">
    <!-- BEGIN: Login Form -->
    <div class="col-span-12 md:col-span-6 xl:col-span-5 h-screen lg:h-auto p-6 flex">
      <div class="flex flex-col w-full">
        <!-- BEGIN: Logo -->
        <div class="md:pl-2 flex items-center gap-2 sm:gap-4 h-16 flex-row">
          <a (click)="onClickLogo()" class="flex items-center cursor-pointer">
            <img alt="The-Estate-Registry" width="250" [src]="'assets/images/logo-old.png'" class="object-contain w-40 sm:w-48">
          </a>
          <a *ngIf="orgLogoPath"  class=" border-l-black pl-6 cursor-pointer max-h-12 maxw128">
            <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
          </a>
          <!-- <div class="flex items-center gap-2 md:gap-4 flex-col" *ngIf="orgLogoPath">
            <p class="whitespace-nowrap text-xs md:text-sm lg:text-regular text-dark-grey">Sponsored by:</p>

            <a *ngIf="orgLogoPath" class="cursor-pointer max-h-12 maxw128">
              <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">

            </a>
          </div> -->
        </div>
        <!-- END: Logo -->
        <div class="h-full flex items-center justify-center mt-4">
          <div class="my-auto mx-auto bg-white pt-6 sm:pl-10 xl:bg-transparent sm:px-8 w-full">
            <div class="h-full justify-center items-center ">
              <!--mail conformation image-->
              <img src="assets/images/dashboard/expired-mail.png" class="mt-3 mb-4 w-28 object-contain h-full"
                alt="digital vault">
              <!--end-->
              <!--start mail confirm text-->
              <div class="my-3">
                <!--text warpper-->
                <h2 class="text-darkblue text-regular font-semibold text-left text-4xl leading-tight pt-4 pb-4">
                  It seems the verification link has expired.
                </h2>
                <!--end-->
              </div>
              <!--end-->
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- END: Login Form -->

    <!-- BEGIN: Login Info -->
    <div class="md:col-span-6 xl:col-span-7 hidden lg:flex bg-login-grey min-h-screen justify-center">
      <div class="fixed h-full flex flex-col items-center justify-center">
        <!-- BEGIN: First Slide -->
        <app-right-carousel-intro></app-right-carousel-intro>
      </div>
    </div>
    <!-- END: Login Info -->
  </div>
</div>

