<app-common-modal id="chat-status-message-modal" class="app-common-modal">
    <!-- BEGIN: Modal -->
    <div class="relative">
        <!-- <h2 class="text-center border-b-2 p-4 mb-8 text-md text-grey font-400">MESSAGE</h2> -->

        <p class="text-md text-grey font-100 text-center">{{message}}</p>

        <!-- END: Leftside Card -->
    </div>
</app-common-modal>
