import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { ChipsOptionsInterface } from 'src/app/interface/common.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ReceivedDlbService } from 'src/app/services/received-dlb.service';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.css'],
})
/**
 * Chips component for whole project
 */
export class ChipsComponent implements OnInit {
  @Input() chipsList: Array<ChipsOptionsInterface>;
  @Output() changeEmitter = new EventEmitter<ChipsOptionsInterface>();
  @Input() selected: any;
  public viewAccessList: string[] = [];
  public updatedViewList: Array<ChipsOptionsInterface>;
  public userType: string;
  private clientRequestId: string;
  private transferDataPaid: boolean = false;

  /**
   * constructor
   */
  constructor(
    private localStorage: LocalStorageService,
    private receivedDlbService: ReceivedDlbService,
    private activeRouter: ActivatedRoute,
    private commonHelper: CommonHelper
  ) {
    this.clientRequestId = this.commonHelper.getRequestId(this.activeRouter);
    this.initTransferDataPaid();
  }

  /**
   * loaded on initialization
   */
  ngOnInit(): void {
    this.userType = this.localStorage.getDataByKey('role');
    this.initTransferDataPaid();
  }
  private initTransferDataPaid() {
    this.receivedDlbService.isTransferPaid(this.clientRequestId).subscribe({
      next: r => this.transferDataPaid = r,
      error: e => console.error(e),
      complete: () => {
        if (this.userType === 'Custodian' && this.localStorage.getDataByKey('view-access-list')) {
          this.viewAccessList = this.localStorage.getDataByKey('view-access-list');
          this.updatedViewList = this.chipsList.map((item) => {
            const hasAccess = this.localStorage.getDataByKey('isVaultClaimed') ? true : this.viewAccessList.includes(item.displayText);
            return { ...item, hasAccess };
          });

          if (this.viewAccessList.includes('Accounts and Assets')) {
            this.updatedViewList
              .filter(item => item.displayText === 'Financial Assets' || item.displayText === 'Retirement Assets')
              .forEach(item => item.hasAccess = true);
          }

          this.updatedViewList.shift();
        }
      }
    });
  }
  /**
   * On select change
   *
   * @param {ChipsOptionsInterface} data
   */
  public onSelectChangeChips(data: ChipsOptionsInterface): void {
    if (!data) return;
    this.selected = data.value;
    this.changeEmitter.emit(data);
  }
}
