import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appSsnSplitter]',
})
/**
 * SSN splitter directives
 */
export class SsnSplitterDirective {
  /**
   * @constructor
   */
  constructor(
    private elementRef:ElementRef,
  ) {

  }

  /**
   * listener for input change
   */
  @HostListener('keyup') keyup() {
    console.log('key-up');
  }
}
