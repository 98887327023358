import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { CommonService } from 'src/app/services/common.service';
import { SelectSearchComponentt } from '../select-search/select-search.component';


@Component({
  selector: 'app-city-search',
  templateUrl: './city-search.component.html',
  styleUrls: ['./city-search.component.css'],
})
/**
 * City component
 */
export class CitySearchComponent extends SelectSearchComponentt implements OnInit, OnChanges {
  @Input() countryName: string;
  @Input() stateCode: number;
  @Input() enableError: boolean;
  @Input() defaultSelected: any;
  @Output() optionChangeEmitter = new EventEmitter<SelectOptionsInterface>();


  /**
   * @constructor
   */
  constructor(
    private commonServices: CommonService,
    public _eref: ElementRef,
  ) {
    super(_eref);
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.initialPlaceholder = 'Select your City ';
    this.placeholder = 'Search your City here';
    this.items = [];
    this.showSearchBox = true;
  }


  /**
   * triggered when changes occur
   *
   * @param {SimpleChanges} simpleChanges
   */
  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges?.stateCode || simpleChanges.defaultSelected?.currentValue) {
      this.checkCachedValueStateList(simpleChanges?.defaultSelected?.currentValue);
    }
    if ((simpleChanges.countryName?.previousValue && simpleChanges.countryName?.currentValue)) {
      this.clearSelectedOption();
      this.clearValues();
    }
  }


  /**
   * check for cached value for state list
   * @param {any} cityCode
   */
  private checkCachedValueStateList(cityCode: any): void {
    if (cityCode && typeof cityCode !== 'string') {
      this.commonServices.getCityById(cityCode).subscribe((data) => {
        this.selectedOption = { value: cityCode, displayText: data?.data?.name };
        this.optionChangeListener(this.selectedOption, data?.data?.name);
        this.defaultSelected = this.selectedOption.displayText;
      });
    } else if (cityCode) {
      this.onSelectChange(cityCode);
    }
  }


  /**
   *
   */
  public clearValues() {
    this.defaultSelected = '';
  }


  /**
   * listen for options changes
   *
   * @param {SelectOptionsInterface} selectedCity
   * @param {any} city
   */
  public optionChangeListener(selectedCity: SelectOptionsInterface, city: any): void {
    if (!selectedCity) {
      selectedCity = { value: null, displayText: city };
    }
    this.optionChangeEmitter.emit(selectedCity);
  }


  /**
   *
   * @param{string}value
   */
  public onChange(value: string): void {
    this.onSelectChange(value);
  }


  /**
   * On select change
   *
   * @param {string} value
   */
  public onSelectChange(value: string): void {
    super.onSelectPreProcess(value);
    this.optionChangeListener(this.selectedOption, value);
    this.defaultSelected = typeof value == 'string' ? value : this.selectedOption?.displayText;
  }
}
