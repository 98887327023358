<!-- LN Notice to Vault Holders (v1.0 110923) -->
<app-consumer-landing-navbar></app-consumer-landing-navbar>

<main>
  <div class="p-12 lg:px-24 lg:py-32 container mx-auto">
    <div class="title__wrap text-center py-8">
      <h1 class="text-semibold text-accent fs-title pb-2">LegacyNOW</h1>
      <h2 class="text-semibold text-primary fs-title">IMPORTANT NOTICE TO DIGITAL VAULT HOLDERS</h2>
    </div>
    <div>
      <p>
        As a Digital Vault holder and user of our Digital Vault Services, it is important that you are aware that the
        sole service we provide to you is the provision and maintenance of your Digital Vault. We do NOT provide any
        other service. As such, you expressly acknowledge and agree that:
      </p>
      <ul class="list-disc">
        <li>
          We do not provide legal advice or opinions, nor do we make any recommendations or suggestions regarding estate
          planning, taxation, or any other matter concerning your estate or the consequences of your passing. The
          applicable law to where of you reside or in which your loved one may have passed away may place restrictions
          on your ability to appoint or exclude certain individuals as executors, heirs or beneficiaries, other
          restrictions as to the inheritance of your estate. Please consult a legal professional for more information
          pertaining to the naming of executors, heirs and beneficiaries or any other matter concerning your will or an
          intestate distribution under applicable law. <b>We accept no responsibility or liability for any of such
          matters and our Digital Vault services are limited to that described in your
          <a (click)="changeRoute('digital-vault-agreement')" class="link">Digital Vault Agreement</a>. </b>
        </li>
        <li>
          The maintenance, modification or updating of any information regarding your estate (and particularly your
          heirs, beneficiaries, and their respective inheritance) that is in your Digital Vault in no way impacts or
          substitutes for that that may be provided in your will or any provision of applicable law. Your Digital Vault
          is simply a secured holding facility for important information, should you desire or intend to effect and
          modification, change or updating of your estate, your will, your specific bequests, or any other matter
          concerning your estate and/or estate planning, you must consult with a legal and/or financial professional and
          take the legally required steps to effect your estate and its distribution upon your passing. <b>We accept no
          responsibility or liability for any of such matters and our Digital Vault services are limited to that
          described in your
          <a (click)="changeRoute('digital-vault-agreement')" class="link">Digital Vault Agreement</a>.</b>
        </li>
        <li>
          We not provide any financial, tax or estate planning advice or opinions with respect to any matter concerning
          yourself or your estate, nor do we make any recommendations or suggestions regarding estate planning,
          taxation, or any other matter concerning your estate or the consequences of your passing. Please consult a
          financial or estate planning professional for more information pertaining to your estate and any tax or other
          implications resulting from your death. <b>We accept no responsibility or liability for any of such matters
          and our Digital Vault services are limited to that described in your
          <a (click)="changeRoute('digital-vault-agreement')" class="link">Digital Vault Agreement</a>.</b></li>
        <li>
          In the course of setting up your Digital Vault and using our Digital Vault Services you will be able to
          consent to, and allow, individuals that you designate to have access to all or some of the information you
          have deposited in your Digital Vault. Such access is in your sole discretion, and we take no responsibility
          with respect to the individuals you made designate or the information to which you may allow them access. <b>
          We accept no responsibility or liability for any use or misuse by such individuals of any information in your
          Vault, or any damage or loss that may occur directly or indirectly as a result thereof. </b></li>
      </ul>
    </div>
  </div>

  <p class="mx-4">
    Last Updated: November 14, 2023
  </p>
</main>

<app-consumer-landing-footer></app-consumer-landing-footer>
