<main class="relative">
  <!-- BEGIN: Navbar Wrapper -->
  <app-consumer-landing-navbar-new></app-consumer-landing-navbar-new>
  <!-- END: Navbar Wrapper -->

  <!-- BEGIN: Banner Wrapper -->
  <div class="relative flex flex-col items-center justify-center mh-600-height lg:mh-700-height hero-ss overflow-hidden">
    <div class="container mx-auto z-30">
      <div class="flex items-center">
        <div class="grid grid-cols-12 gap-4 xl:gap-12">
          <div class="col-span-12 md:col-span-7 lg:col-span-6 xl:col-span-5">
            <div class="">
            <h1 class="home-banner__head font-ave-black text-4xl lg:text-5xl text-darkblue">
              Easily secure your digital legacy today.
            </h1>
            <p class="home-banner__content text-xl lg:text-2xl mt-8 mb-8 lg:mb-12 lg:mt-12 font-ave-medium">
              LegacyNOW protects your most valuable information, documents, and other materials, and leaves your loved ones a road map of your estate when they need it the most.
            </p>
            <div class="btn-wrap flex items-center flex-wrap gap-3 md:gap-6 lg:gap-8 lg:mb-12">
              <button (click)="menuChange('educational-intro')" *ngIf="showGetStarted"
                class="ld-btn whitespace-nowrap mr-2 sm:mr-3 text-base md:text-lg flex items-center justify-center text-white font-ave-semibold">
                Get Started
              </button>
              <button (click)="onClickLearnMore()"
                class="ld-btn whitespace-nowrap text-base md:text-lg flex items-center justify-center text-white font-ave-semibold">
                Learn More
              </button>

            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <img class="mt-12 md:mt-0 md:absolute banner__bg z-10 md:object-cover md:w-full md:h-full"
        src="assets/images/home-page/hero-bg.jpeg" alt="">
  </div>
  <!-- END: Banner Wrapper -->

  <!-- BEGIN: Introduce Legacy Wrapper -->
  <div id="introduceLegacy" class="section introduce-legacy-ss">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-start gap-4 md:gap-6 xl:gap-12">
        <div class="col-span-12 lg:col-span-6 mb-8 lg:mb-0">
          <div class="grid-flow-row grid gap-6 lg:gap-8">
            <a (click)="openModal()" (keydown)="onKeyDownOnSiteGuide($event)"
                 tabindex="0" class="cursor-pointer relative open-site-guid-modal">
              <img class="w-full bg-img" src="assets/images/home-page/introduce-video-bg.png" alt="">
              <img class="yt-btn-img" src="assets/images/home-page/youtube2.png" alt="">
            </a>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6 flex flex-col justify-center h-full">
          <h2 class="font-ave-bold text-3xl md:text-4xl leading-snug text-darkblue pb-6">Introducing the Estate Data Management Platform.</h2>
          <p class="font-ave-medium text-xl md:text-2xl leading-snug">Includes data protection, secure document storage, privacy management, and legacy planning. Allows you to grant access to your personal estate information to those you select when you pass. It’s a thoughtful gift to the loved ones you entrust with the information needed to settle your estate.</p>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Introduce Legacy Vault Wrapper -->

  <!-- BEGIN: Why Legacy Wrapper -->
  <div class="section why-legacy-ss bg-ld-blue">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 gap-4 lg:gap-12">
        <div class="col-span-12 flex flex-col justify-center lg:col-span-7 md:pr-3 md:pr-0 pt-6 lg:pt-0 order-1 lg:order-0">
          <h2 class="font-ave-black text-white text-center text-3xl pb-6">
            Why LegacyNOW?
          </h2>
          <p class="font-ave-medium text-white text-left">
            Do you have easy access to all your critical asset and account information? Do your loved ones have access if you suddenly pass? Now is the time to organize your legacy.
          </p>
          <ul class="why-legacy-lst md:pr-8">
            <li class="open font-ave-medium text-white text-left">
              <b class="font-ave-black">Open a LegacyNOW</b> account in minutes.
            </li>
            <li class="fill font-ave-medium text-white text-left">
              <b class="font-ave-black">Fill LegacyNOW’s digital vault</b> with asset info from banks, utilities, social media accounts, and whatever you want to store and share.
            </li>
            <li class="encrypted font-ave-medium text-white text-left">
              <b class="font-ave-black">Your info is encrypted</b> so only you
              and those you share it with can see it<b class="font-ave-black">—we can never see your personal data.</b>
            </li>
            <li class="cancel font-ave-medium text-white text-left">
              Cancel within 30 days for a full refund.
              <a class="font-ave-black-oblique underline cursor-pointer" tabindex="0" *ngIf="showGetStarted"
                (click)="menuChange('educational-intro')">START NOW</a>
            </li>
          </ul>
        </div>

        <div class="col-span-12 md:col-span-12 lg:col-span-5 order-0 lg:order-1">
          <img class="ml-auto md:mr-auto"
            src="assets/images/home-page/computer_safe_screen.png"
            alt=""/>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Why Legacy Wrapper -->

  <!-- BEGIN: How Legacy Works Wrapper -->
  <div class="section how-legacynow-ss">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 lg:divide-y-0 lg:divide-x-2 divide-primary">
        <div class="col-span-12 lg:col-span-6 flex flex-col justify-between h-full pr-0 lg:pr-16">
          <div class="pb-10">
            <div class="flex gap-4 md:gap-4 items-center">
              <img
                src="assets/images/home-page/access-pencil.png"
                class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16"
                alt="Access at your fingertips"
              />
              <h4 class="font-ave-semibold text-darkblue text-xl xl:text-2xl">
                Access at your fingertips
              </h4>
            </div>
            <p class="font-ave-regular text-base xl:text-lg pt-4">
              As life changes, so does your information. LegacyNOW’s cloud-based platform makes it easy to update and change your information at any time.
            </p>
          </div>
          <div>
            <div class="flex gap-4 md:gap-4 items-center">
              <img
                src="assets/images/home-page/planning-clipboard.png"
                class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16"
                alt="What is Legacy Planning"
              />
              <h4 class="font-ave-semibold text-darkblue text-xl xl:text-2xl">
                What is Legacy Planning
              </h4>
            </div>
            <p class="font-ave-regular text-base xl:text-lg pt-4">
              Your life accomplishments and wishes are unique. Sharing common end-of-life documents is not enough. LegacyNOW provides an online archive, managed by you, of everything your loved ones need to know after you pass, based on the role they play in your life.
            </p>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6 flex flex-col justify-between h-full pl-0 lg:pl-16 pt-10 lg:pt-0">
          <div class="pb-10">
            <div class="flex gap-4 md:gap-4 items-center">
              <img
                src="assets/images/home-page/access-pencil.png"
                class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16"
                alt="You’re in control"
              />
              <h4 class="font-ave-semibold text-darkblue text-xl xl:text-2xl">
                You’re in control
              </h4>
            </div>
            <p class="font-ave-regular text-base xl:text-lg pt-4">
              Organizing your legacy information for your loved ones to access when you pass is important, however, you control who gets access to your Digital Vault prior to your passing.
            </p>
          </div>
          <div>
            <div class="flex gap-4 md:gap-4 items-center">
              <img
                src="assets/images/home-page/protected-lock.png"
                class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16"
                alt="Keep files protected from accidental loss"
              />
              <h4 class="font-ave-semibold text-darkblue text-xl xl:text-2xl">
                Keep files protected from accidental loss
              </h4>
            </div>
            <p class="font-ave-regular text-base xl:text-lg pt-4">
              Accidents happen and things get overlooked. Physical documents and policies stored around your home can be lost. Once stored in the Digital Vault they are easily available.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: How Legacy Works Wrapper -->

   <!-- BEGIN: Testimonial Wrapper-->
  <div class="section testimonial-ss">
    <div class="container mx-auto">
      <div class="grid grid-cols-12">
        <div class="col-span-12 ">
          <div class="testimonial-box p-8 sm:p-12 px-12 sm:px-16 md:pt-12 lg:pt-16 flex justify-center h-full">
            <div class="testimonial-content">
              <div class="testimonial-quote relative font-ave-regular text-base sm:text-lg">
                LegacyNOW’s digital vault has given me the peace of mind that I’m doing something that will help my family and friends if I ever do leave unexpectedly.
              </div>
              <div class="flex items-center justify-center pt-4 sm:pt-6">
                <strong class="font-ave-semibold text-lg text-center">T. Cai</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Testimonial Wrapper -->

  <!-- BEGIN: Footer Wrapper -->
  <app-consumer-landing-footer></app-consumer-landing-footer>
  <!-- END: Footer Wrapper -->
</main>
<app-demo-video-modal [modal_id]="VIDEO_MODAL_ID" [vaultVideo]="VAULT_VIDEO"></app-demo-video-modal>
