import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'secondsTransform',
})
/**
 * Used to transform a number as seconds to a string denoting the number of hours, minutes, and seconds in that string.<br>
 * For example, an input of '7730' would output '2 hours 8 minutes 50 seconds'
 */
export class SecondsTransformPipe implements PipeTransform {
  /**
   *
   * @param{number} value
   * @param{boolean} shorthand
   * @return{string}
   */
  transform(value: number, shorthand = false): string {
    let seconds = value;
    let minutes: number = 0;
    let hours: number = 0;

    // Set minutes
    if (seconds >= 60) {
      minutes = Math.trunc(seconds / 60);
      seconds = seconds - (minutes * 60);
    }

    // Set hours
    if (minutes >= 60) {
      hours = Math.trunc(minutes / 60);
      minutes = minutes - (hours * 60);
    }

    // Build output string
    return this.getOutput(hours, minutes, seconds, shorthand);
  }


  /**
   * Builds output string from given values in format of `H hours M minutes S seconds`.
   * @param{number} H hours
   * @param{number} M minutes
   * @param{number} S seconds
   * @param{boolean} shorthand
   * @return{string}
   */
  public getOutput(H: number, M: number, S: number, shorthand: boolean): string {
    // Build output string
    let output: string = '';
    [[H, 'h', 'hour'], [M, 'm', 'minute'], [S, 's', 'second']].forEach((e) => {
      if ((e[0] as number) > 0) {
        const s = e[0] > 1 && !shorthand ? 's' : '';
        const term = shorthand ? e[1] : e[2];
        output += `${e[0]} ${term}${s} `;
      }
    });
    return output.trim();
  }
}
