import { Component, OnInit, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { environment } from 'src/environments/environment';
import { ReceivedDlbService } from '../../../services/received-dlb.service';


@Component({
  selector: 'app-executor-beneficiaries-overview',
  templateUrl: './executor-beneficiaries-overview.component.html',

})
/**
 * Executor and beneficiary overview
 */
export class ExecutorBeneficiariesOverviewComponent implements OnInit {
  public peopleLoader: boolean = true;
  public peopleArray: Array<any>;
  public executorArray: Array<any>;
  public beneficiaryArray: Array<any>;
  public advisorArray: Array<any>;
  public custodianArray: Array<any>;
  @ViewChildren('executorList') executorList: Array<any>;
  @ViewChildren('beneficiaryList') beneficiaryList: Array<any>;
  @ViewChildren('advisorList') advisorList: Array<any>;
  @ViewChildren('custodianList') custodianList: Array<any>;
  public requestIdParam: string;
  public showSSN: boolean;
  public clientRequestId: string;
  public userType: string;
  public agentTerm: string;
  public domainViewDateFormat: any;
  public accessVault: boolean;
  private transferDataPaid: boolean = false;

  /**
   * @constructor
   */
  constructor(
    private commonHelper: CommonHelper,
    private personalDetailsService: PersonalDetailsService,
    private localStorageService: LocalStorageService,
    private activeRouter: ActivatedRoute,
    private slugInterceptorService: SlugInterceptorService,
    private receivedDlbService: ReceivedDlbService,
  ) {
  }

  /**
   * called intially
   */
  ngOnInit(): void {
    this.domainViewDateFormat = this.commonHelper.domainViewDateFormat;
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.clientRequestId = this.localStorageService.getDataByKey('req_id');
    this.userType = this.localStorageService.getDataByKey('role');
    this.requestIdParam = this.commonHelper.encodeDecodeRequestId(this.activeRouter.snapshot.parent.params?.id, false) || this.activeRouter.snapshot.parent.params?.id || this.localStorageService.getDataByKey('req_id');
    this.showSSN = environment.APP_DOMAIN == 1;
    this.executorList = [];
    this.beneficiaryList = [];
    this.advisorList = [];
    this.custodianList = [];
    this.peopleArray = [];
    this.executorArray = [];
    this.beneficiaryArray = [];
    this.advisorArray = [];
    this.custodianArray = [];
    this.accessVault = this.localStorageService.getDataByKey('accessVault');

    if (this.isCustodian) {
      this.initTransferDataPaid();
    } else {
      this.getPeopleDetails();
    }
  }

  private initTransferDataPaid() {
    this.receivedDlbService.isTransferPaid(this.requestIdParam).subscribe({
      next: r => this.transferDataPaid = r,
      error: e => console.error(e),
      complete: () => this.getPeopleDetails(),
    });
  }

  private get useCensor(): boolean {
    return this.isCustodian && !this.transferDataPaid;
  }

  private get isCustodian(): boolean {
    return this.userType === 'Custodian';
  }

  /**
   * get people details ( Executor and beneficiary )
   */
  public getPeopleDetails(): void {
    this.peopleLoader = true;

    if (!this.useCensor) {
      const requestID = this.userType === 'Consumer' || this.isCustodian ? this.requestIdParam : this.clientRequestId;
      this.personalDetailsService.getUserPeople(requestID).subscribe((response: APIResponseModel) => {
        if (response.status) {
          this.executorArray = response?.data?.user_people.filter((user) => user.executor === 1 && user.beneficiary_type_short !== 2);
          this.beneficiaryArray = response?.data?.user_people.filter((user) => user.beneficiary === 1);
          this.advisorArray = response?.data?.user_people.filter((user) => user.advisor === 1);
          this.custodianArray = response?.data?.user_people.filter((user) => user.custodian === 1);
        }
        this.peopleLoader = false;
      }, (exception: any) => {
        this.peopleLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    }
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }
}
