import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { FileValidationOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PropertyService } from 'src/app/services/property.service';
import { ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN, LIST_DELETE, LIST_NEW, LIST_UPDATE, WEBSITE_PATTERN_NEW } from 'src/constants/application.const';
import { ValidationHelper } from '../../../helper/validation.helper';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-add-social-media',
  templateUrl: './add-social-media.component.html',
})
/**
 * add social media popup
 */
export class AddSocialMediaComponent implements OnInit {
  @Output() toggleModalEmitter = new EventEmitter<any>();
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() isNewAsset: boolean = true;
  @Input() clientHasPaidSubscription:boolean = false;
  public readonly faEye = faEye;
  public readonly faEyeSlash = faEyeSlash;
  public socialMediaForm: FormGroup;
  public submitLoaderDelete: boolean = false;
  public submitLoader: boolean;
  public userType: string;
  public requestId: string;
  public fileValidationOptions: FileValidationOptionsInterface;
  public togglePasswordView: boolean = false;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  private hasPaidSubscription: boolean;

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private propertyService: PropertyService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private toastrService: ToastrService,
    private validation: ValidationHelper,
    private subscriptionService: ConsumerSubscriptionService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.hasPaidSubscription = r);
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    // file validation options
    this.fileValidationOptions = {
      size: 100000,
      fileFormat: ['jpeg', 'jpg', 'png'],
    };
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.initForm();
    this.resetForm();
    if (!this.canAccessForm) {
      this.socialMediaForm.disable();
    }
  }

  private initForm() {
    this.socialMediaForm = this.formBuilder.group({
      user_name: ['', { updateOn: 'blur', validators: [Validators.minLength(3), Validators.maxLength(30), Validators.pattern(ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN)] }],
      social_media_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(50), Validators.pattern(ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN)] }],
      password: [''],
      website: ['', { updateOn: 'blur', validators: [Validators.pattern(WEBSITE_PATTERN_NEW), Validators.maxLength(150)] }],
      id: [''],
    });
    Object.keys(this.socialMediaForm.controls).forEach(controlName => {
      this.socialMediaForm.get(controlName)?.valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.socialMediaForm.get(controlName))
      );
    });
    this.validation.setValidationErrors({ website: { pattern: 'Invalid URL' } });
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.socialMediaForm);
  }

  /**
   * setter for editing social media details
   *
   * @param {any} data
   */
  @Input() set editData(data: any) {
    if (data && Object.keys(data).length) {
      this.socialMediaForm.patchValue({
        ...data,
      });
    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.socialMediaForm.controls;
  }

  /**
   * add social media
   */
  public onSubmit(): void {
    this.canEdit && this.socialMediaForm.markAllAsTouched();
    if (!this.socialMediaForm.valid) {
      this.commonHelper.scrollToFirstInvalidControl(this.socialMediaForm);
      return; // return if the form is invalid
    }
    this.submitLoader = true;
    // add social media if user is consumer
    if (this.userType === 'Consumer') {
      const requestData = {
        ...this.socialMediaForm.value,
        roletype: this.userType,
        request_id: this.localStorageService.getUserData()?.request?.id,

      };
      const formData = new FormData();
      // appending every value to form object
      Object.keys(requestData).forEach((key) => {
        if (requestData[key] == undefined) return;
        formData.append(key, requestData[key]);
      });
      this.propertyService.addSocialMedia(formData).subscribe((response: APIResponseModel) => {
        this.submitLoader = false;
        if (response.status) {
          this.commonHelper.updateLocalstorageRequestStage(response.data);
          this.toggleModalEmitter.emit({
            ...response.data,
            listStatus: (this.socialMediaForm.value.id) ? LIST_UPDATE : LIST_NEW,
          });
          requestData.id ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();
          this.closeModel();
        }
      }, (exception: any) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    } else {
      const requestData = {
        ...this.socialMediaForm.value,
        roletype: this.userType,
        request_id: this.requestId,
      };
      const formData = new FormData();
      // appending every value to form object
      Object.keys(requestData).forEach((key) => {
        if (requestData[key] == undefined) return;
        formData.append(key, requestData[key]);
      });
      this.propertyService.addSocialMedia(formData).subscribe((response: APIResponseModel) => {
        this.submitLoader = false;
        if (response.status) {
          this.toggleModalEmitter.emit({
            ...response.data,
            listStatus: (this.socialMediaForm.value.id) ? LIST_UPDATE : LIST_NEW,
          });
          this.closeModel();
        }
      }, (exception: any) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    }
  }

  /**
   * delete social media details
   */
  public deleteSocialMedia(): void {
    // ACL check
    if (!this.permissions?.['delete']) {
      this.toastrService.info('You do not have permission to delete items for this client.');
      return;
    }

    this.submitLoaderDelete = true;

    let sendingData: {} = {
      is_delete: '1',
      id: this.socialMediaForm.value.id,
      roletype: this.userType,
    };

    if (this.userType === 'Consumer') {
      sendingData = { ...sendingData, request_id: this.requestId };
    }

    //  Delete valuable
    this.propertyService.addSocialMedia(sendingData).subscribe((response: APIResponseModel) => {
        if (response.status) {
          this.toggleModalEmitter.emit({
            id: this.socialMediaForm.value.id,
            listStatus: LIST_DELETE,
          });
          this.commonHelper.toastrDeleteSuccess();
          this.closeModel();
        }
      },
      (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
      () => this.submitLoaderDelete = false);
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.modalService.close('add-social-media-modal');
    this.togglePasswordView = false;
    this.resetForm();
  }

  /**
   * Reset form
   */
  public resetForm(): void {
    this.socialMediaForm.reset({

      social_media_name: '',

    });
  }

  /**
   * If user can edit existing asset, or add a new asset.
   */
  get canEdit(): boolean {
    return this.commonHelper.canEditSection(this.isNewAsset, this.permissions) && (this.hasPaidSubscription || this.clientHasPaidSubscription);
  }

}
