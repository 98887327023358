import { Component, Input, OnInit } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-failure',
  templateUrl: './payment-failure.component.html',
})
/**
 * payment failure modal
 */
export class PaymentFailureComponent implements OnInit {
  @Input() modalID: string = 'payment-failure';
  errorMessageForFailed: string = `Sorry We weren't able to complete your payment
  at this time`;
  public isLoginUrl: boolean;

  constructor(private modalService: CommonModelService,
    private commonService: CommonService, 
    private route: Router) {
  }
  ngOnInit(): void {
    console.log('payment failure');
    // To prevent the modal from opening on the login page.
    this.isLoginUrl = this.route.url == '/' || this.route.url == '/login';
    this.commonService.paymentErrorDetectionObservable.subscribe((response)=>{
      if(response) {
        this.errorMessageForFailed = response;
      }else {
        this.errorMessageForFailed = `Sorry We weren't able to complete your payment
        at this time`;
      }
    })
  }

  public closeModal() {
    this.modalService.close('payment-failure');
  }
}
