<app-common-modal id="client-link-modal" class="app-common-modal">
  <!-- BEGIN: Modal -->

  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">
    <div class="p-4">
      <div class="flex items-center justify-start mb-1">
        <p class="text-label-grey text-regular md:text-base text-base">Client Registration Link </p>

      </div>
      <div class="flex items-center justify-start">
        <p class="text-label-grey text-regular md:text-base text-base pr-3"><img
            src="assets/images/social-icons/link.svg " class="w-4 h-4" alt="social-icon"> </p>
        <input class="text-gray-900 text-regular md:text-base text-base outline-none pr-3 w-96" #shortLink
          value="{{generatedUrl}}">
        <button (click)="this.linkCopied = !this.linkCopied"
          class="button button--lg bg-blue-600  px-2 rounded-xs h-8 focus:outline-none  text-regular text-white text-sm flex flex-row justify-center items-center">
          <img src="assets/images/social-icons/copycat.svg" class="w-4 h-4" alt="social icons"
            [ngxClipboard]="shortLink">
        </button>
        <p class=" text-xs flex flex-row justify-end items-end ml-1 whitespace-nowrap" *ngIf="this.linkCopied">Link
          Copied</p>
      </div>
    </div>
  </div>

  <!-- End Modal -->
</app-common-modal>
