<div class="lg:grid grid-cols-12 background-color-grey">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-7 xl:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 my-4">
              <div class="col-span-12 sm:col-span-2">
                  <img src="assets/images/executors/executors.svg" class="w-20" alt="digital vault">
              </div>

              <div class="col-span-12 sm:col-span-10">
                <div class="flex items-center gap-4">
                  <h2 class="text-darkblue text-md sm:text-2xl break-words uppercase text-semibold">
                    Executors
                  </h2>
                  <a (click)="openModal('whatIsAnExecutor')" title='Click here for help from Aida'>
                    <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                          stroke-linecap="round" stroke-linejoin="round"
                          class="css-i6dzq1n text-primary cursor-pointer">
                      <circle cx="12" cy="12" r="10"></circle>
                      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                      <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                  </a>
                </div>
                <p class="text-sm text-grey tracking-wide text-regular">
                  This information is critical and a key to verifying their identity. Please double-check your input
                  prior to moving on.
                </p>
              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-2/6'"></app-card-progress-bar>
              <!-- END: Progress Bar -->

              <div class="p-6">
                <div class="items-center">
                  <div>
                    <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                      Who do you want as your executor(s)?
                    </h4>
                    <p class="text-grey text-regular tracking-wide pt-1 text-sm">
                      If you have a will in place, please enter the person(s) identified as executor in your will.
                      Listing additional names here will not overwrite your will.
                    </p>
                    <p class="text-grey text-regular tracking-wide pt-1 text-sm">
                      The people listed below will be marked as your executors. To remove executors, click the delete
                      icon. To add executors to your list, click the 'Add an Executor' button below.
                    </p>
                  </div>
                </div>

                <!-- people listing component -->
                <app-list-people
                  [lpSettings]="lpSettings" [userPeopleData]="userPeopleData"
                  (clickEmitter)="clickListener($event)">
                </app-list-people>

                <div class="mt-10">
                  <button type="button"
                          class="button button--lg w-full flex flex-row justify-center items-center bg-transparent border-lightblue rounded-xs h-12 md:h-16 focus:outline-none text-regular font-semibold tracking-wide text-darkblue px-6 text-sm md:text-base whitespace-nowrap"
                          (click)="toggleChooseRelativesModal()">
                    <img src="assets/images/about-yourself/add-plus.svg" class="w-6 mr-3 hidden sm:block" alt="digital vault">
                    Add an Executor
                  </button>
                </div>

                <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
                  <a (click)="changeRoute('executor-beneficiary')" *ngIf="this.userType === 'Consumer'"
                     class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </a>
                  <a (click)="changeRoute('executor-beneficiary/'+requestId)" *ngIf="this.userType !== 'Consumer'"
                     class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </a>
                  <app-button-loader class="w-full sm:w-auto" [buttonText]="'Save and Continue'" [valid]="true"
                                     [loader]="submitLoader"
                                     buttonType="'button'" (click)="saveAndContinue()">
                  </app-button-loader>
                </div>
              </div>
              <!-- END: Leftside Card -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class="col-span-12 md:col-span-5 xl:col-span-6 lg:grid justify-center">
    <about-yourself-right-content></about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->

</div>

<!-- END: Rightside Content -->
<app-choose-people
  [userPeopleData]="chooseExecutorsData"
  [cpcSettings]="cpcSettings"
  [loadListener]="lpSettings.isLoading"
  (updateEmitter)="updateListener($event)">
</app-choose-people>

<app-add-executor [editData]="editData" (toggleModalEmitter)="toggleAddModalListener($event)"></app-add-executor>
<app-message-modal [setMessageData]="messageData" (buttonEmitter)="messageModalListener($event)"></app-message-modal>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>
