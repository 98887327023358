import { Component, Input, OnInit } from '@angular/core';
import { faInfoCircle, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { DashboardNoticationDetailsInterface } from 'src/app/interface/dashboard.interface';
import { ClientsService } from 'src/app/professional-dashboard/services/clients.service';
import { ProSocketService } from 'src/app/professional-dashboard/services/pro-socket.service';
import { ProfessionalDashboardService } from 'src/app/professional-dashboard/services/professional-dashboard.service';
import { CommonService } from 'src/app/services/common.service';
import {
  AFTERTENDAYS,
  APPOINT_MESSG,
  CALL_MESSG,
  DATE_TODAY,
  INSURANCE_MSSG,
  PROFESSIONAL_ROLE,
  SCHEDULE_MESSG,
  USER_TYPES,
} from 'src/constants/application.const';
import { LocalStorageService } from '../../../../../services/local-storage.service';
@Component({
  selector: 'app-professional-expiry-notification',
  templateUrl: './professional-expiry-notification.component.html',
  styleUrls: ['./professional-expiry-notification.component.css'],
})
export class ProfessionalExpiryNotificationComponent implements OnInit {
  // Nofification
  public NotificationData: Array<DashboardNoticationDetailsInterface> = [];
  public noData: boolean = true;
  public role: string;
  public currentPage = 1;
  public searchKeyword = '';
  public lastPage: number;
  public recordsPerPage: number = 5;
  public listOrder = 'desc';
  public clientList: Array<Object>;
  public userId: string;
  public roleName: string;
  public faInfoCircle: any = faInfoCircle;
  public faPhoneAlt: any = faPhoneAlt;
  public loader: boolean = true;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  @Input() isDashboardPage: boolean = false;

  constructor(
    private professionalDashboardService: ProfessionalDashboardService,
    private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService,
    private clientService: ClientsService,
    private commonService: CommonService,
    private socketService: ProSocketService,
    private commonHelper: CommonHelper,
  ) {

  }
  ngOnInit(): void {
    const proUser = this.localStorageService.getUserData(USER_TYPES.pro).user;
    this.userId = proUser?.id;
    this.roleName = proUser.role_name;
    const professional = this.localStorageService.getUserData(USER_TYPES.pro);
    this.role = professional.user.role_name;
    this.loadData();
    setTimeout(() => {
      this.listenProfessionalAppointment();
    })
  }
  /**
  * load data as per roleName
  *
  */
  public loadData(): void {
    this.NotificationData = [];
    this.clientList = [];
    if (this.roleName === PROFESSIONAL_ROLE.manager || this.roleName === PROFESSIONAL_ROLE.professional) {
      this.getProfessionalNotifications();
    }
  }
  onTableDataChange(event: any) {
    this.page = event;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  /**
     * change route
     * @param{string}url
      */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, true);
  }

  /**
 * Notifications for Professional
 *
 */
  public getProfessionalNotifications() {
    forkJoin([
      this.clientService.getClients(this.currentPage, this.recordsPerPage, this.listOrder, this.searchKeyword),
      this.commonService.getInsuranceTypes(),
    ]).subscribe((response) => {
      this.clientList = response[0]?.data.data;
      const insuranceType = response[1]?.data;
      if (this.clientList.length) {
        this.clientList.forEach((cur) => {
          const uniqueId = cur?.['user_request']?.['request_unique_id'];
          const insurance = cur?.['user_request']?.['user_insurance'];
          // Notification to contact consumer for every three months
          const checkContact = this.commonHelper.checkContact(cur['created_at']);
          if (checkContact) {
            const afterTenDays = this.commonHelper.getTenthDay();
            if (new Date(checkContact) < DATE_TODAY && DATE_TODAY <= new Date(afterTenDays)) {
              this.pushInToArray(uniqueId, CALL_MESSG, new Date(checkContact), false, undefined, 'expiryCall', null, 'consumerContact');
            }
          }
          // Notification of expired insurance
          if (uniqueId && insurance.length) {
            insurance.forEach((insurance) => {
              if (new Date(insurance?.end_date) > DATE_TODAY && new Date(insurance?.end_date) < AFTERTENDAYS) {
                const insuranceName = insuranceType?.filter((data) => {
                  return data.id === insurance?.insurance_type_id;
                })[0]?.name;
                this.pushInToArray(uniqueId, INSURANCE_MSSG, insurance?.end_date, true, insuranceName, 'expiryCall', null, 'expiredInsurance');
              }
            });
          }
        });
        // Failsafe to remove duplicate notification details
        this.NotificationData = _.uniqWith(this.NotificationData, _.isEqual);
      }
    });
  }

  /**
   * Get notifications for common
   */
  public getNotifications() {
    this.loader = true;
    this.clearAppointmentNotifications();
    forkJoin([
      this.commonService.getAppointmentlist(),
      this.commonService.getSchedulelist(this.userId),
      this.professionalDashboardService.getClientScheduleStatus(),
    ]).subscribe((response) => {
      const getClientRequestList = response[0]?.data;
      const getScheduleList = response[1]?.data;
      const getProfessionalRequestList = response[2]?.data;
      // client requested appointment list notification
      if (getClientRequestList?.length) {
        getClientRequestList?.forEach((cur) => {
          this.pushInToArray(cur?.user?.user_request?.request_unique_id, APPOINT_MESSG + cur?.user?.first_name, cur?.date_time, false, undefined, 'remainder', cur.host_meeting_link ? cur.host_meeting_link : null, 'clientRequestAppointment');
        });
      }
      // client default schedule remainder notification to check records
      if (getScheduleList?.length) {
        getScheduleList.forEach((cur) => {
          if (cur?.user_request?.user?.remainded_at) {
            const startDate = this.commonHelper.stringToDate(cur?.user_request?.user?.remainded_at);
            const endDate = this.commonHelper.addDaysFromDate(startDate, 10);
            if (new Date(startDate) < DATE_TODAY && new Date(endDate) > DATE_TODAY) {
              this.pushInToArray(cur?.user_request?.request_unique_id, SCHEDULE_MESSG + cur?.user_request?.user?.first_name + ' on', cur?.user_request?.user?.remainded_at, false, undefined, 'expiryCall', null, 'scheduleRemainder');
            }
          }
        });
      }

      // professional requested appointment list notification
      if (getProfessionalRequestList?.length) {
        getProfessionalRequestList.forEach((cur) => {
          this.pushInToArray(cur?.user?.user_request?.request_unique_id, ((cur?.status) ? `Upcoming ` : `Pending - `) + cur?.schedule_type?.name + ` with ` + cur?.user?.first_name + ` on `, cur?.date_time, false, undefined, 'remainder', cur.host_meeting_link ? cur.host_meeting_link : null, 'professionalRequestAppointment');
        });
      }
      // Failsafe to remove duplicate notification details
      this.NotificationData = _.uniqWith(this.NotificationData, _.isEqual);
      this.loader = false;
    });
  }
  /**
   * listen professional appointment list status change
   */
  public listenProfessionalAppointment(): void {
    this.socketService.listenAppointmentStatus().subscribe(() => {
      this.getNotifications();
    });
  }
  /**
   * clear appointment notifications
   */
  public clearAppointmentNotifications(): void {
    this.NotificationData = this.NotificationData.filter((x) => x.typeOfNotification !== 'professionalRequestAppointment');
    this.NotificationData = this.NotificationData.filter((x) => x.typeOfNotification !== 'clientRequestAppointment');
    this.NotificationData = this.NotificationData.filter((x) => x.typeOfNotification !== 'scheduleRemainder');
  }

  /**
   * push notifications
   * @param {any} request_id
   * @param {any} message
   * @param {any} date
   * @param {boolean} isInsurance
   * @param {string} insurance_type
   * @param {string} iconType
   * @param {string} meeting_link
   * @param {string} typeOfNotification
   */
  public pushInToArray(request_id: any, message: any, date: any, isInsurance: boolean, insurance_type: string, iconType: string, meeting_link: string, typeOfNotification: string): void {
    const notification = {
      request_id: request_id,
      message: message,
      date: date,
      isInsurance: isInsurance,
      insurance_type: insurance_type,
      iconType: iconType,
      meeting_link: meeting_link,
      typeOfNotification: typeOfNotification,
    };
    this.commonHelper.checkDuplicateData(notification, this.NotificationData) ? this.NotificationData.push(notification) : false;
  }
}
