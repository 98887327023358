import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { AgentsService } from 'src/app/professional-dashboard/services/agents.service';
import { ChartsService } from 'src/app/professional-dashboard/services/charts.service';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ORDER_VALUE, SELECT_VALUE, USER_TYPES } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-professional-agent',
  templateUrl: './home-professional-agent.component.html',
  styleUrls: ['./home-professional-agent.component.css'],
})
/**
 * professional agent home class
 */
export class HomeProfessionalAgentComponent implements OnInit {
  public agentList: Array<Object>;
  public profileMenuToggle: boolean;
  public profileMenuToggleBuffer: boolean;
  public index: number;
  public spinner: boolean;
  public paginationLinks: Array<any>;
  public lastPage: number;
  public dataLoading: boolean;
  public selectOptions: Array<Object>;
  public orderOptions: Array<Object>;
  public proAdvisorsTableSettings: proClientTableSettingsI;
  public currentPage = 1;
  public editData: any;
  public toggleModalAdd: boolean;
  public roleName: string;
  public agentImageSrc: string;
  public agentTerm: string;
  public advisorTerm: string;
  public submitLoaderDelete: boolean;
  public deleteId: any;

  // Doughnut
  public doughnutOptions: any;
  public doughnutColor: any;
  public doughnutChartLabels: Label[];
  public doughnutChartData: MultiDataSet;

  // Bar Chart
  public barChartOptions: ChartOptions;
  public barChartLabels: Label[];
  public barChartData: ChartDataSets[];
  public barChartColor: any;
  public barChartLegend: boolean;
  public barChartValues: any;
  public showData: boolean;
  public roleType: string = '';
  public viewAgentData: IAgentData;
  /**
   * constructor
   */
  constructor(
    private agentService: AgentsService,
    private toastrService: ToastrService,
    private chartService: ChartsService,
    private localStorageService: LocalStorageService,
    private professionalUserService: ProfessionalUserService,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    public modalService: CommonModelService,
  ) {
    this.barChartData = [
      { data: [], label: 'My Advisors', backgroundColor: '#2692f7' },
    ];
  }
  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.proAdvisorsTableSettings = this.localStorageService.getDataByKey('proAdvisorsTableSettings') || {
      sortPreference: SortPreference.Name,
      recordsPerPage: 5,
      searchKeyword: '',
    };
    this.proAdvisorsTableSettings.searchKeyword = '';
    this.advisorTerm = this.professionalUserService.getAdvisorTerm();
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.agentImageSrc = `${environment.BASE_URL_RAW}uploads/agent_proof/`;
    this.currentPage = 1;
    this.spinner = true;
    this.dataLoading = true;
    this.roleName = this.localStorageService.getUserData(USER_TYPES.pro).user.role_name;
    this.getAgents();
    this.selectOptions = SELECT_VALUE;
    this.orderOptions = ORDER_VALUE;
    this.profileMenuToggle = false;
    this.doughnutChartLabels = ['Active', 'Inactive'];

    this.doughnutChartData = [[280, 80]];
    this.doughnutOptions = {
      legend: { display: true, position: 'bottom' },
      rotation: 2.3 * Math.PI,
      circumference: 2 * Math.PI,
      responsive: true,
    };
    this.doughnutColor = [
      {
        backgroundColor: ['#29be18', '#dedbdd'],
      },
    ];
    this.barChartOptions = {
      responsive: true,
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawOnChartArea: true,
            },
            ticks: {
              beginAtZero: true,
              stepSize: 1,
              max: 10,
            },
          },
        ],
      },
      legend: {
        position: 'bottom',
      },
    };

    this.getBarChartData();

    this.barChartLegend = true;

    this.barChartLabels = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    // types of advisors
    this.roleType = this.localStorageService.getUserData(USER_TYPES.pro).user.professional_type_name;
  }

  /**
   * get barchart data
   */
  private getBarChartData() {
    this.dataLoading = true;
    this.chartService.getBarChartDataAgents()?.subscribe((response) => {
      this.dataLoading = false;
      this.barChartValues = response?.data;
      this.showData = true;
      const data: {} = this.barChartValues;
      const ChartData: number[] = this.barChartLabels.map((key) => data['agents'][key]);
      this.barChartData = [
        {
          data: ChartData,
          label: 'My ' + this.advisorTerm,
          backgroundColor: '#2692f7',
        },
      ];
    });
  }

  /**
   * get all agents
   */
  public getAgents() {
    this.spinner = true;
    this.localStorageService.storeData('proAdvisorsTableSettings', this.proAdvisorsTableSettings);
    this.agentService
      .getAgents(this.currentPage, this.proAdvisorsTableSettings.recordsPerPage, SortPreference.Name, this.proAdvisorsTableSettings.searchKeyword)
      .subscribe({
        next: (response) => {
          this.spinner = false;
          this.agentList = response.data.data;
          this.paginationLinks = response.data.links;
          this.lastPage = response.data.last_page;
          this.sortAdvisorList();
        },
        error: () => this.spinner = false,
      });
  }

  /**
   * edit agent
   * @param{any}data
   */
  public editAgent(data: any) {
    this.localStorageService.storeData('edit-id', data);
    this.agentService.emitChange(data);
  }
  /**
   * delete agent
   * @param{any}isDelete
   */
  public deleteAgent(isDelete: any) {
    if (isDelete) {
      this.submitLoaderDelete = true;
      this.agentService.deleteAgent(this.deleteId).subscribe((response) => {
        this.modalService.close('delete-confirmation-modal');
        this.toastrService.success(response.message);
        this.getAgents();
        this.submitLoaderDelete = false;
      },
        (exception: any) => {
          this.submitLoaderDelete = false;

          this.commonHelper.httpResponseHandler(exception.error);
        });
    } else {
      this.submitLoaderDelete = false;
    }
    this.profileMenuToggle = false;
  }
  /**
   * open delete modal
   * @param{any}data
   */
  public openConfirmationModal(data: any) {
    this.deleteId = data.id;
    this.modalService.open('delete-confirmation-modal');
    this.profileMenuToggle = false;
  }
  /**
   * toggle dropdown
   * Index less than zero should close all.
   * @param{number}ind
   */
  public toggleDropDown(ind: number) {
    if (ind < 0 && this.profileMenuToggle && this.profileMenuToggleBuffer) {
      this.profileMenuToggleBuffer = false;
    } else if (ind < 0) {
      this.profileMenuToggle = false;
    } else { // Else :: open at index.
      this.profileMenuToggleBuffer = true;
      this.profileMenuToggle = !this.profileMenuToggle;
      this.index = ind;
    }
  }
  /**
   * search agent based on keyword
   * @param{string}keyword
   */
  public onSearchAgent(keyword: string) {
    this.proAdvisorsTableSettings.searchKeyword = keyword;
    if (keyword === '') {
      this.getAgents();
    } else {
      this.spinner = true;
      this.localStorageService.storeData('proAdvisorsTableSettings', this.proAdvisorsTableSettings);
      this.agentService.getAgents(this.currentPage, this.proAdvisorsTableSettings.recordsPerPage, SortPreference.Name, this.proAdvisorsTableSettings.searchKeyword).subscribe((response) => {
        this.spinner = false;
        this.agentList = response.data.data;
        this.lastPage = response.data.last_page;
        this.sortAdvisorList();
      });
    }
  }

  /**
  * agent list
  * @param {number}pageNo
  // * @param {number}records
  // * @param {string}order
  */
  public getAgentsList(pageNo?: number) {
    if (this.currentPage === pageNo) return;
    if (pageNo) {
      this.currentPage = pageNo;
    }
    this.spinner = true;
    this.localStorageService.storeData('proAdvisorsTableSettings', this.proAdvisorsTableSettings);
    this.agentService
      .getAgents(this.currentPage, this.proAdvisorsTableSettings.recordsPerPage, SortPreference.Name, this.proAdvisorsTableSettings.searchKeyword)
      .subscribe((response) => {
        this.agentList = response.data.data;
        this.paginationLinks = response.data.links;
        this.lastPage = response.data.last_page;
        this.spinner = false;
        this.sortAdvisorList();
      });
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, true);
  }

  // Function to sort the agentList based on the current sortPreference
  public sortAdvisorList() {
    switch (this.proAdvisorsTableSettings.sortPreference) {

      case SortPreference.NoofClients:
        this.agentList.sort((a, b) => {
          const noOfClientsA = a['total_clients'];
          const noOfClientsB = b['total_clients'];

          if (noOfClientsA < noOfClientsB) {
            return -1;
          }

          if (noOfClientsA > noOfClientsB) {
            return 1;
          }

          return 0;
        });
        break;

      case SortPreference.OldToNew:
        // Sort by Old to New
        this.agentList.sort((a, b) => {
          const dateA = new Date(a['created_at']);
          const dateB = new Date(b['created_at']);

          if (dateA < dateB) {
            return -1;
          }

          if (dateA > dateB) {
            return 1;
          }

          return 0;
        });
        break;

      case SortPreference.NewToOld:
        // Sort by New to Old
        this.agentList.sort((a, b) => {
          const dateA = new Date(a['created_at']);
          const dateB = new Date(b['created_at']);

          if (dateA > dateB) {
            return -1;
          }

          if (dateA < dateB) {
            return 1;
          }

          return 0;
        });
        break;

      default:
        // Handle the default case (no sorting)
        break;
    }
  }

  /**
   * Displays the agent's information in a modal view.
   * @param {object} agentData - The data of the agent to view.
   */
  public viewAgent(agentData: any) {
    const country = this.commonHelper.capitalizeFirstLetter(agentData.country?.name);
    const fullAddress = this.commonHelper.addCommas(agentData?.address, agentData?.apt_number, agentData.city?.name, agentData.state?.name, country)
    this.viewAgentData = {
      name: agentData?.["full_name"],
      email: agentData?.['email'],
      address: fullAddress,
      phone: (agentData['country_code'] ? '+' + agentData['country_code'] + ' ' : ' ') + agentData['phone'],
      profileImageSrc: agentData['profile_picture'] ? this.agentImageSrc + agentData['profile_picture'] : 'assets/icons/user-bg.svg'
    }
    this.modalService.open('profile-view');
  }
}
enum SortPreference {
  Name = 'desc',
  OldToNew = 'Old to New',
  NewToOld = 'New to Old',
  LastLogin = 'login',
  NoofClients = 'clients'
}


interface proClientTableSettingsI {
  sortPreference: SortPreference,
  recordsPerPage: number,
  searchKeyword: string
}

interface IAgentData {
  name?: string;
  email?: string;
  address?: string;
  phone?: string;
  profileImageSrc?: string;
}

