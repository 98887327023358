<!-- !Need to adapt to pdf view if possible -->
<app-common-modal [id]="uID" class="app-common-modal">
  <loading *ngIf="isLoading">{{ isLoading }}</loading>
  <div class="relative" *ngIf="imagrSrc">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">

    <!-- image -->
    <app-image-component [image]="imagrSrc" [class]="'w-full h-full'" *ngIf="checkFileType == 'default'">
    </app-image-component>

    <!-- audio -->
    <audio controls [src]="imagrSrc" autoplay *ngIf="checkFileType == 'mp3'"></audio>

    <!-- video -->
    <video *ngIf="checkFileType == 'mp4'" controls autoplay width="100%" height="100%">
      <source [src]="imagrSrc" type="video/mp4">
  </video>
  </div>
</app-common-modal>
