<div class="ng-container">
  <div class="w-full">
    <app-shimmer-loading [loading]="isLoading">
      <ng-container *ngIf="!isLoading && familyArray?.length > 0">
        <div class="bg-transparent border-card-grey rounded-xs mt-6 h-20"
          *ngFor="let peopleData of familyArray; let i = index">
          <div class="grid grid-cols-12 h-20 items-center gap-4 px-3 sm:pl-3">
            <div class="col-span-2">
              <div class="flex items-center justify-center mx-auto rounded-full h-12 w-12 bg-darkblue">
                <p class="uppercase text-white text-regular text-base">
                  <ng-container [ngSwitch]="peopleData?.['relation']">
                    <img *ngSwitchCase="1" src="assets/images/executors/heart.svg" class="w-5" alt="digital vault">
                    <img *ngSwitchCase="2" src="assets/images/executors/display-img.svg" class="w-5"
                      alt="digital vault">
                  </ng-container>
                </p>
              </div>
            </div>
            <div class="col-span-6">
              <h4
              (click)="requestPage == 'executor'?addExecutor(peopleData):addCustodian(peopleData)"
              class="break-words text-base text-regular tracking-wide text-semibold text-darkblue">
                {{ peopleData?.['first_name'] }} {{ peopleData?.['middle_name'] }} {{ peopleData?.['last_name'] }}</h4>
              <ng-container [ngSwitch]="peopleData?.['relation']">
                <p class="text-grey text-regular text-xs sm:text-sm" *ngSwitchCase="1">Spouse/Partner</p>
                <p class="text-grey text-regular text-xs sm:text-sm" *ngSwitchCase="2">Your Child</p>
                <p class="text-grey text-regular text-xs sm:text-sm" *ngSwitchDefault>
                  {{peopleData?.['people_relation']?.name}}</p>
              </ng-container>
            </div>
            <div class="col-span-4">
              <div class="flex items-center justify-center h-5">
                <p class="text-sm whitespace-nowrap mr-2">add as {{requestPage === 'executor'?'executor':'custodian'}}:
                </p>
                <input type="checkbox" id="notify_checkbox"
                  class="h-4 w-4 input text-lightblue focus-within:text-blue-500 border rounded"
                  (change)="onChangeEmitter(peopleData?.id)"
                  [checked]="this.requestPage === 'executor'?peopleData?.['executor'] === 1:peopleData?.['custodian'] === 1">
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isLoading && nonFamilyArray.length>0">
        <div class="bg-transparent border-card-grey rounded-xs mt-6 h-20"
          *ngFor="let peopleData of nonFamilyArray ; let i = index">
          <div class="grid grid-cols-12 h-20 items-center gap-4 px-3 sm:pl-3">
            <div class="col-span-2">
              <div class="flex items-center justify-center mx-auto rounded-full h-12 w-12 bg-darkblue">
                <div class="uppercase text-white text-regular text-base">
                  <ng-container [ngSwitch]="peopleData?.['relation']">
                    <img *ngSwitchCase="1" src="assets/images/executors/heart.svg" class="w-5" alt="digital vault">
                    <img *ngSwitchCase="2" src="assets/images/executors/display-img.svg" class="w-5"
                      alt="digital vault">
                    <p *ngSwitchDefault>{{ peopleData?.['first_name']?.charAt(0)?.toUpperCase() }} {{
                      peopleData?.['last_name']?.charAt(0)?.toUpperCase() }}</p>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="col-span-6">
              <h4 (click)="requestPage == 'executor'?addExecutor(peopleData):addCustodian(peopleData)"
              class="break-words text-base text-regular tracking-wide text-semibold text-darkblue">
                {{ peopleData?.['first_name'] }} {{ peopleData?.['middle_name'] }} {{ peopleData?.['last_name'] }}</h4>
              <ng-container [ngSwitch]="peopleData?.['relation']">
                <p class="text-grey text-regular text-xs sm:text-sm" *ngSwitchCase="1">Spouse/Partner</p>
                <p class="text-grey text-regular text-xs sm:text-sm" *ngSwitchCase="2">Your Child</p>
                <p class="text-grey text-regular text-xs sm:text-sm" *ngSwitchDefault>
                  {{peopleData?.['people_relation']?.name}}</p>
              </ng-container>
            </div>
            <div class="col-span-4">
              <div class="flex items-center justify-center h-5">
                <p class="text-sm whitespace-nowrap mr-2">add as {{requestPage === 'executor'?'executor':'custodian'}}:
                </p>
                <input type="checkbox" (change)="onChangeEmitter(peopleData?.id)"
                  class="h-4 w-4 input text-lightblue focus-within:text-blue-500  border rounded"
                  [checked]="this.requestPage === 'executor'?peopleData?.['executor'] === 1:peopleData?.['custodian'] === 1">
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isLoading && alternativeCustodianArray?.length>0">
        <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide mt-3 mb-2">
          Your Alternative Custodian
        </h4>
        <div class="bg-transparent border-card-grey rounded-xs mt-6 h-20"
          *ngFor="let peopleData of alternativeCustodianArray ; let i = index">
          <div class="grid grid-cols-12 h-20 items-center gap-4 px-3 sm:pl-3">
            <div class="col-span-2">
              <div class="flex items-center justify-center mx-auto rounded-full h-12 w-12 bg-darkblue">
                <div class="uppercase text-white text-regular text-base">
                  <ng-container [ngSwitch]="peopleData?.['relation']">
                    <img *ngSwitchCase="1" src="assets/images/executors/heart.svg" class="w-5" alt="digital vault">
                    <img *ngSwitchCase="2" src="assets/images/executors/display-img.svg" class="w-5"
                      alt="digital vault">
                    <p *ngSwitchDefault>{{ peopleData?.['first_name']?.charAt(0)?.toUpperCase() }} {{
                      peopleData?.['last_name']?.charAt(0)?.toUpperCase() }}</p>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="col-span-6">
              <h4 (click)="addCustodian(peopleData)"
              class="break-words text-base text-regular tracking-wide text-semibold">
                {{ peopleData?.['first_name'] }} {{ peopleData?.['middle_name'] }} {{ peopleData?.['last_name'] }}</h4>
              <ng-container [ngSwitch]="peopleData?.['relation']">
                <p class="text-grey text-regular text-xs sm:text-sm" *ngSwitchCase="1">Spouse/Partner</p>
                <p class="text-grey text-regular text-xs sm:text-sm" *ngSwitchCase="2">Your Child</p>
                <p class="text-grey text-regular text-xs sm:text-sm" *ngSwitchDefault>
                  {{peopleData?.['people_relation']?.name}}</p>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </app-shimmer-loading>
  </div>
  <div class="w-full mt-4" *ngIf="requestPage=='executor'">
    <button type="button" *ngIf="!isLoading"
      class="button button--lg w-full flex flex-row justify-center items-center bg-transparent border-lightblue rounded-xs h-12 md:h-16 focus:outline-none text-regular font-semibold tracking-wide text-darkblue px-6 text-sm md:text-base"
      (click)="addExecutor()">
      <img src="assets/images/about-yourself/add-plus.svg" class="w-6 mr-3 hidden sm:block" alt="digital vault">
      Add a Executor
    </button>
  </div>
  <div class="w-full mt-4" *ngIf="alternativeCustodianArray?.length < 1 && requestPage!='executor'">

    <button type="button" *ngIf="!isLoading"
      class="button button--lg w-full flex flex-row justify-center items-center bg-transparent border-lightblue rounded-xs h-12 md:h-16 focus:outline-none text-regular font-semibold tracking-wide text-darkblue px-6 text-sm md:text-base"
      (click)="addCustodian()">
      <img src="assets/images/about-yourself/add-plus.svg" class="w-6 mr-3 hidden sm:block" alt="digital vault">
      Add a Custodian
    </button>
  </div>

</div>
