import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';

/**
 * Component
 */
@Component({
  selector: 'app-delete-chat-confirmation-modal',
  templateUrl: './delete-chat-confirmation-modal.component.html',
  styleUrls: ['./delete-chat-confirmation-modal.component.css'],
})

/**
 * delete chat
 */
export class DeleteChatConfirmationModalComponent implements OnInit {
  @Output() deleteOption = new EventEmitter<boolean>();
  @Input() submitLoaderDelete: boolean;
  public submitLoader: boolean;
  /**
     * constructor
     */
  constructor(
    private modalService: CommonModelService,
  ) { }

  /**
     * called initially
     */
  ngOnInit(): void {
    this.submitLoaderDelete = false;
  }


  /**
   * confirm delete
   */
  public onDelete(): void {
    this.deleteOption.emit(true);
  }


  /**
   * close modal
   */
  public closeModel(): void {
    this.deleteOption.emit(false);
    this.modalService.close('delete-chat-confirmation-modal');
  }
}
