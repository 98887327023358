<nav class="bg-white">
  <div class="w-full px-4 lg:px-8">
    <div class="relative flex items-center justify-between pb-4 lg:h-28 pt-4">
      <div class="flex-1 flex items-start gap-2 sm:gap-4 lg:items-stretch justify-start">
        <div class="m-auto flex items-center lg:hidden" *ngIf="showNavMenu()">
          <!-- BEGIN: Mobile Menu button-->
          <button type="button" class="flex items-center p-1 border rounded text-grey lg:hidden"
            aria-controls="mobile-menu" aria-expanded="false" (click)="responsiveMenuToggle = !responsiveMenuToggle">
            <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
          <!-- END: Mobile Menu button-->
        </div>

        <div class="flex justify-start gap-2 lg:gap-4 items-center">
          <!-- Dashboard Logo -->
          <a *ngIf="this.userType === 'Consumer'" (click)="navigate('')" title="Return to Landing Page">
            <img alt="The-Estate-Registry" class="object-contain w-40 lg:w-64 custom-logo-large cursor-pointer"
              src="assets/images/logo-old.png">
          </a>

          <!-- Temporary Co-brand Logo implementation for demo -->
          <p *ngIf="this.currentUser?.['user']?.['email'] === 'pfiumano@phillips-cohen.com'|| this.currentUser?.['user']?.['email'] === 'gleonard@phillips-cohen.com'"
            class=" whitespace-nowrap text-xs md:text-sm text-grey text-regular">
            Sponsored by:</p>
          <!-- Temporary Co-brand Logo implementation for demo -->
          <a
            *ngIf="this.currentUser?.['user']?.['email'] === 'pfiumano@phillips-cohen.com' || this.currentUser?.['user']?.['email'] === 'gleonard@phillips-cohen.com'">
            <img alt="The-Estate-Registry" class="object-contain w-20 h-20 pl-4 "
              src="assets/images/Mass Retirees logo_small.png">
          </a>

          <!-- <div class="flex items-center gap-2 md:gap-4 h-16 md:h-24 flex-row">
            <a (click)="navigateToPro()" *ngIf="this.userType !== 'Consumer'" class="flex">
              <img alt="The-Estate-Registry" class="object-contain w-20 md:w-24 h-16 md:h-24 lg:w-32"
                src="assets/images/logo-old.png">
            </a>
            <a *ngIf="orgLogoPath"  class=" border-l-black pl-6 cursor-pointer max-h-12 maxw128">
              <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
            </a>
          </div> -->
          <div class="flex justify-start gap-2 lg:gap-4 items-center whitespace-nowrap">
            <a (click)="navigateToPro()" title="Return to Landing Page" *ngIf="this.userType !== 'Consumer'">
              <img alt="The-Estate-Registry" src="assets/images/logo-old.png" class="object-contain max-h-10 cursor-pointer">
            </a>
            <ng-container *ngIf="orgLogoPath">
              <span class="border-l-black h-8 max-h-8"></span>
              <a class="cursor-pointer">
                <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" [src]="orgLogo">
              </a>
            </ng-container>
          </div>          
        </div>

        <div class="items-center hidden lg:flex sm:space-x-2 lg:space-x-4 xl:space-x-8 menu" *ngIf="showNavMenu()">
          <div class="flex sm:space-x-2 lg:space-x-4 xl:space-x-12 pl-4 xl:pl-8">
            <a *ngIf="showPaymentOption && !isCheckOutPage" (click)="processPayment()"
              [ngClass]="{'menu-active': currentPage === 'payment'}"
              class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer">
              Pay for Digital Vault
            </a>
            <a (click)="changeRouter('dashboard')"
              class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
              [ngClass]="{'menu-active': ['about-yourself','dashboard','executor-beneficiary','vehicle','property','assets','will-files','digital-files'].indexOf(currentPage) !== -1}">Digital
              Vault Dashboard</a>
            <a (click)="changeRouter('overview')"
              class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
              [ngClass]="{'menu-active': currentPage === 'overview'}">Overview</a>
            <a (click)="changeRouter('people')"
              class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
              [ngClass]="{'menu-active': currentPage === 'people'}">People</a>
            <a *ngIf="!DISABLE_MESSAGES && canShowMessageMenu" id="dropdownDefaultButton" data-dropdown-toggle="dropdown" [ngClass]="{
               'menu-active': currentPage === 'message' || currentPage === 'org-message',
               'text-gray-300 pointer-events-none cursor-not-allowed': disableMessages,
               'text-nav-blue': !disableMessages
               }" class="text-regular py-2 text-sm tracking-wide font-medium cursor-pointer">
              Messages
              <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4" />
              </svg>
              <!-- Dropdown menu -->
              <div *ngIf="!disableMessages" id="dropdown"
                class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                  <li>
                    <a (click)="changeRouter('message')"
                      class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
                      [ngClass]="{'submenu-active': currentPage === 'message'}">Professional</a>
                  </li>
                  <li>
                    <a (click)="changeRouter('org-message')"
                      class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
                      [ngClass]="{'submenu-active': currentPage === 'org-message'}">Organization</a>
                  </li>
                </ul>
              </div>
            </a>
            <a (click)="showVideo()"
              class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
              [ngClass]="{'menu-active': currentPage === 'support'}">Intro Video</a>
            <a  *ngIf="canShowMessageMenu" (click)="changeRouter('/message')"
              [ngClass]="{'menu-active': currentPage === 'message'}"
              class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
              >Message</a>

          </div>

          <!-- <div class="flex sm:space-x-2 lg:space-x-4 xl:space-x-12 pl-4 xl:pl-8" *ngIf="isReceivedDv">

            <a (click)="changeRouter('/dashboard')"
              class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
              [ngClass]="{'menu-active': ['about-yourself','dashboard','executor-beneficiary','vehicle','property','assets','will-files','digital-files'].indexOf(currentPage) !== -1}">
              Received Digital Vault
            </a>
          </div> -->

        </div>

        <div class="m-auto justify-end flex items-center gap-4 w-full lg:static lg:inset-auto flex-1">
          <!-- BEGIN: Navbar Button -->

          <!-- TO DO Hided for Received DLB dashboard changes -->
          <!-- <a (click)="changeRoute('received-dlb')" *ngIf="isReceivedDv"
            class="cursor-pointer hidden lg:block w-auto flex items-center justify-center px-5 py-2 border border-transparent rounded text-sm tracking-wide  text-regular font-semibold bg-nav-btn">

            Received Digital Vault
          </a> -->

          <!-- END: Navbar Button -->

          <!-- BEGIN: Logout Icon -->

          <div *ngIf="showLogoutBtn()">
            <button class="relative cursor-pointer flex items-center gap-2 justify-center border-button text-base tracking-wide w-full sm:w-auto rounded text-regular py-3 px-6" (click)="logout()">
              <img src="assets/icons/logout.svg" alt="">
              <span>Logout</span>
            </button>
          </div>

          <!-- END: Logout Button -->

          <!-- BEGIN: Notification Icon -->

          <div class="relative cursor-pointer w-2 sm:w-4" #notification (click)="notificationToggle = !notificationToggle"
          *ngIf="showNavMenu()">

            <fa-icon [icon]="faBell"></fa-icon>
            <span class="sr-only">Notifications</span>
            <div
              class="absolute inline-flex items-center justify-center w-4 h-4 md:w-5 md:h-5 text-xs font-bold text-white bg-red-500 border-0 rounded-full -top-2 -right-2 dark:border-gray-900"
              *ngIf="notificationList.length>0">{{notificationList.length}}</div>
          </div>
          <!-- END: Notification Icon -->

          <div class="relative w-6 lg:w-8">
            <div (click)="profileMenuToggle = !profileMenuToggle" class="cursor-pointer" #profileMenu
            *ngIf="showNavMenu()">
              <img class="rounded-full object-contain w-8 h-8 lg:h-10 lg:w-10 "
                src="assets/images/navbar/profile-avatar.png" alt=""
                *ngIf="this.currentUser?.['user']?.['profile_photo'] === ''">
              <img class="rounded-full object-contain w-8 h-8 lg:h-10 lg:w-10 " [src]="this.imageSrc" alt=""
                *ngIf="this.currentUser?.['user']?.['profile_photo'] !== ''">
            </div>
            <!-- BEGIN: Notification dropdown -->
            <div
              class="origin-top-right absolute right-10 mt-2 w-96 rounded-md shadow-lg py-1 bg-white focus:outline-none z-50"
              role="menu" aria-orientation="vertical" aria-labelledby="user-menu"
              *ngIf="notificationToggle&&!profileMenuToggle&&notificationList.length>0">

              <ul *ngFor="let list of notificationList | slice:5; last as isLast">
                <li class="py-1 px-3">
                  <div
                    class="text-sm border border-grey-600 border-8 h-20 w-full text-gray-700 px-2 py-1 rounded relative"
                    role="alert">
                    <ng-container *ngIf="list['schedule_type_id'];else userRequestNotificationBlock">
                      <div *ngIf="!list['status'];else zoomMeetingLinkBlock">
                        <span>Appointment Request: {{list?.['professional']?.['first_name']}} would like to schedule a
                          {{list?.['schedule_type']?.name}} on&nbsp;<strong class="font-bold">{{list?.['date_time'] |
                            dateStringtoDateformat}}&nbsp;&nbsp;</strong></span>
                        <div class="space-x-3 absolute bottom-2 right-2">
                          <fa-icon [icon]="faCircleXmark" class="red-tick cursor-pointer" title="decline"
                            (click)="declineEvent(list?.id)"></fa-icon>
                          <fa-icon [icon]="faCheckCircle" class="green-tick cursor-pointer" title="accept"
                            (click)="acceptEvent(list?.id)"></fa-icon>
                        </div>
                      </div>
                      <ng-template #zoomMeetingLinkBlock>
                        <span>You confirmed appointment successfully, scheduled by
                          {{list?.['professional']?.['first_name']}}&nbsp;for&nbsp;<strong
                            class="font-bold">{{list?.['date_time'] |
                            dateStringtoDateformat}}&nbsp;&nbsp;</strong></span>
                        <div class="space-x-3 absolute bottom-2 right-2">
                          <a *ngIf="list?.['meeting_link'] !==null" href="{{list['meeting_link']}}" target="_blank"
                            class="text-green-500 hover:text-blue-700 underline text-md">Join Zoom Meeting</a>
                        </div>
                      </ng-template>
                    </ng-container>

                    <ng-template #userRequestNotificationBlock>
                      <span *ngIf="!list['schedule_type_id']">You have requested an appointment
                        with&nbsp;{{list?.['professional']?.['first_name']}}&nbsp;on&nbsp;<strong
                          class="font-bold">{{list?.['date_time'] | dateStringtoDateformat}}&nbsp;&nbsp;</strong></span>
                      <div class="absolute bottom-2 right-2">
                        <a *ngIf="list?.['meeting_link'] !==null" href="{{list['meeting_link']}}" target="_blank"
                          class="text-green-500 hover:text-blue-700 underline text-md">Join Zoom Meeting</a>
                      </div>
                    </ng-template>

                  </div>
                </li>
                <li class="py-1 px-3 relative flex flex-row" *ngIf="isLast&&notificationList.length>5">
                  <button
                    class="button button-lg bg-blue px-5 w-full rounded-xs h-10 focus:outline-none text-regular text-white text-sm flex flex-row justify-center items-center"
                    (click)="navigate('notifications')">
                    Show more
                  </button>
                </li>
              </ul>
            </div>
            <div
              class="origin-top-right absolute right-0 mt-2 w-60 h-10 text-center rounded-md shadow-lg py-1 bg-white focus:outline-none z-50"
              role="menu" aria-orientation="vertical" aria-labelledby="user-menu"
              *ngIf="notificationToggle&&!profileMenuToggle&&notificationList.length==0">
              <ul>
                <li class="block px-4 py-2 text-md text-grey text-regular"> No notifications to display</li>
              </ul>
            </div>
            <!-- END: Notification dropdown -->

            <!-- BEGIN: Profile dropdown -->
            <div
              class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white focus:outline-none z-50"
              role="menu" aria-orientation="vertical" aria-labelledby="user-menu" *ngIf="profileMenuToggle">
              <a (click)="navigate('account-settings')"
                class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100"
                role="menuitem">Your Profile</a>
              <button  [disabled]="this.currentPage === 'subscription-plan'"
                (click)="toSubscriptionPage('subscription-plan')"
                class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100 w-full text-left"
                role="menuitem">Subscriptions
              </button>
              <a  (click)="navigate('billing-history')"
              class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100"
              role="menuitem">Billing
              History</a>
              <a  (click)="navigate('saved-cards')"
                class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100"
                role="menuitem">Manage
                Cards
              </a>
              <a (click)="navigate('account-settings',{ menu:2 })"
                class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100"
                role="menuitem">Change
                Password</a>
              <button [disabled]="buttonClicked" (click)="logout()"
                class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100 w-full text-left"
                role="menuitem">Log out
              </button>
            </div>
            <!-- END: Profile dropdown -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- BEGIN: Mobile Menus-->
  <div class="lg:hidden shadow-md px-4 pb-4" id="mobile-menu" *ngIf="responsiveMenuToggle && showNavMenu()">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <a [ngClass]="isCheckOutPage?'block sm:hidden':'hidden'" *ngIf="isCheckOutPage" (click)="navigate('dashboard')"
        class="cursor-pointer text-grey text-regular block px-3 py-2 rounded text-base font-medium mobile-menuitem">
        Return to Dashboard
      </a>
      <a *ngIf="showPaymentOption && !isCheckOutPage" (click)="processPayment()"
        [ngClass]="{'mob-menu-active': currentPage === 'payment'}"
        class="cursor-pointer text-grey text-regular block px-3 py-2 rounded text-base font-medium mobile-menuitem">
        Pay for Digital Vault
      </a>
      <a (click)="changeRouter('dashboard')"
        class="cursor-pointer text-grey text-regular block px-3 py-2 rounded text-base font-medium mobile-menuitem"
        [ngClass]="{'mob-menu-active': ['about-yourself','dashboard','executor-beneficiary','vehicle','property','assets','will-files','digital-files'].indexOf(currentPage) !== -1}">Digital
        Vault Dashboard</a>
      <a (click)="changeRouter('overview')"
        class="cursor-pointer text-grey text-regular block px-3 py-2 rounded text-base font-medium mobile-menuitem"
        [ngClass]="{'mob-menu-active': currentPage === 'overview'}">Overview</a>
      <a (click)="changeRouter('people')" [ngClass]="{'menu-active': currentPage === 'people'}"
        class="cursor-pointer text-grey text-regular block px-3 py-2 rounded text-base font-medium mobile-menuitem">People</a>
      <a *ngIf="!DISABLE_MESSAGES" id="dropdownDefaultButton" data-dropdown-toggle="dropdown" [ngClass]="{
               'mob-menu-active': currentPage === 'message' || currentPage === 'org-message',
               'text-gray-300 pointer-events-none cursor-not-allowed': disableMessages,
               'text-grey': !disableMessages
               }" class="cursor-pointer text-regular block px-3 py-2 rounded text-base font-medium mobile-menuitem">
        <span class="menu-downarrow-align">
          Message<svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 4 4 4-4" />
          </svg>
        </span>
        <!-- Dropdown menu -->
        <div id="dropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
          <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
            <li>
              <a id="sub-menu" (click)="changeRouter('message')"
                class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
                [ngClass]="{'submenu-active': currentPage === 'message'}">Professional</a>
            </li>
            <li>
              <a id="sub-menu" (click)="changeRouter('org-message')"
                class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
                [ngClass]="{'submenu-active': currentPage === 'org-message'}">Organization</a>
            </li>
          </ul>
        </div>
      </a>
      <a (click)="showVideo()"
        class="cursor-pointer text-grey text-regular block px-3 py-2 rounded text-base font-medium mobile-menuitem"
        [ngClass]="{'menu-active': currentPage === 'support'}">Intro Video</a>
        <a  *ngIf="canShowMessageMenu" (click)="changeRouter('/message')"
        [ngClass]="{'menu-active': currentPage === 'message'}"
        class="cursor-pointer text-grey text-regular block px-3 py-2 rounded text-base font-medium mobile-menuitem"
        >Message</a>
    </div>
  </div>
  <!-- END: Mobile Menus-->
</nav>

<app-demo-video-modal [modal_id]="'navbar-video'" [vaultVideo]="'consumerLanding'"></app-demo-video-modal>
<app-payment-success></app-payment-success>
<app-payment-failure></app-payment-failure>

