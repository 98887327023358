import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { USER_TYPES } from '../../constants/application.const';
import { IdleService } from '../services/idle.service';
import { LocalStorageService } from '../services/local-storage.service';
import { SessionStorageService } from '../services/session-storage.service';
import { SlugInterceptorService } from './slug-interceptor.service';


@Injectable({ providedIn: 'root' })
/**
 * @class AuthGuardUser
 * @description for protecting routers when user is not logged in
 */
export class AuthGuardProfessional implements CanActivate {
  /**
   * @constructor
   */
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private idleService: IdleService,
    private slugInterceptorService: SlugInterceptorService,
  ) { }

  /**
   * @method canActivate
   * @description method to check if router can render or not
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return {Boolean}
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this.localStorageService.getUserData(USER_TYPES.pro);
    const currentUserSession = this.sessionStorageService.getDataFromSession(USER_TYPES.pro);
    const currentUserCookie = this.localStorageService.getLoginCookie(USER_TYPES.pro);
    const executorUser = this.localStorageService.getUserData(USER_TYPES.exe);
    const executorUserCookie = this.localStorageService.getLoginCookie(USER_TYPES.exe);
    const consumer = this.localStorageService.getUserData();

    if (currentUser && currentUserCookie && currentUserSession) {
      // logged in so return true
      this.idleService.reset();
      return true;
    }
    if (executorUser && executorUserCookie) {
      this.router.navigate(['unauthorized']);
      return false;
    }
    if (consumer) {
      this.router.navigate(['unauthorized']);
      return false;
    } else {
      // not logged in so redirect to login page with the return url
      if (route?.parent?.params?.slug) {
        // void this.slugInterceptorService.navigateByUrl('/pro/' + route.parent.params.slug + '/login');
        void this.slugInterceptorService.navigateByUrl('login', null, true);
      } else {
        void this.slugInterceptorService.navigate(['login'], null, true);
      }
      return false;
    }
  }
}
