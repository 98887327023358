<app-common-modal id="schedule-client-modal" class="app-common-modal">
  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg" class="absolute right-10 w-8 -mr-6 -mt-4 cursor-pointer" alt=""
      (click)="closeModal()" />
    <div class="mb-4">
      <div>
        <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
          Schedule to {{clientName}}
        </h4>
        <p class="text-grey text-regular pt-1 text-sm">Start by adding your Schedule.
        </p>
      </div>
    </div>
    <form [formGroup]="appointmentForm" autocomplete="off" (ngSubmit)="onSubmit()">

      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-1">
        <div class="col-span-12">
          <ul class="list-group flex flex-row space-x-4">
            <li class="list-group-item text-label-grey text-sm text-regular border-none" *ngFor="let item of itemsList">

              <input type="radio" name="schedule_type_id" [id]="item.name" [value]="item.id"
                formControlName="schedule_type_id" class="form-checkbox w-4 h-4">
              <label [for]="item.name" class="text-sm mb2 text-regular text-label-grey pb-2">&nbsp;Schedule
                {{item.name}}</label>
            </li>
          </ul>
        </div>

        <div class="grid grid-cols-12 gap-5 p-0 m-0">
          <div class="col-span-11">
            <label for="appointmentDate" class="text-label-grey text-sm text-regular">Date & Time</label>
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <input id="appointmentDate" placeholder="Schedule Date and Time" formControlName="appointmentDate"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" [min]="minDate" [max]="maxDate"
                [ngClass]="formGet.appointmentDate.errors && formGet.appointmentDate.touched?'form-input-error':'form-input-focus'">
              <owl-date-time #dt1 class="z-10"></owl-date-time>
            </app-shimmer-loading>
            <div *ngIf="formGet.appointmentDate.errors && formGet.appointmentDate.touched">
              <p class="text-regular font-medium text-xs mt-2 error-alert"
                *ngIf="formGet.appointmentDate.errors.required">
                Date & time is required
              </p>
            </div>
          </div>
        </div>


        <div class="col-span-12">
          <label for="description" class="text-label-grey text-sm text-regular">Description</label>
          <textarea rows="4" cols="50" name="description"
            class="input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
            id="description" placeholder="e.g We are looking forward to meeting with you and providing our services."
            formControlName="description"></textarea>
          <div *ngIf="formGet.description.errors && formGet.description.touched">
            <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.description.errors.required">
              Description is required
            </p>
            <p class="text-regular font-medium text-xs mt-2 error-alert"
              *ngIf="formGet.description.errors.noteCharacterValidation">
              Ampersat, colon, double slash, greater than, lesser than are not allowed
            </p>
            <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.description.errors.minlength">
              Minimum 3 characters required
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
        <app-button-loader [buttonText]="'Send'" [valid]="appointmentForm.valid" [loader]="submitLoader"
          [buttonType]="'submit'"></app-button-loader>
      </div>
    </form>
  </div>

  <!-- End Modal -->
</app-common-modal>