/* eslint-disable indent */
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Route, Router, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EMPTY } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { PROFESSIONAL_TYPE, ROUTE_PREFIX, USER_TYPES } from '../../constants/application.const';
import { APIResponseModel } from '../interface/response.interface';
import { LocalStorageService } from '../services/local-storage.service';
import { SlugService } from '../services/slug.service';


@Injectable({
  providedIn: 'root',
})
export class SlugInterceptorService {
  private verifiedSlug: string;

  constructor(
    private router: Router,
    private slugService: SlugService,
    private toastrService: ToastrService,
    private localStorageService: LocalStorageService,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1),
        switchMap(() => {
          const url = this.router.url;
          this.verifiedSlug = localStorage.getItem('verifiedSlug');
          const userData = this.localStorageService.getDataByKey(USER_TYPES.user);
          const isUserLoggedIn = !!userData?.access_token;
          const isExecutorLoggedIn = !!(this.localStorageService.getDataByKey(USER_TYPES.exe)?.access_token);
          const isProLoggedIn = !!(this.localStorageService.getDataByKey(USER_TYPES.pro)?.access_token);
          const isConfirmAccountUrl = url.includes('confirm-account');
          
          if ((url === '/' && !isUserLoggedIn || url === '/pro/login' && !isProLoggedIn)) {
            // Default module route, handle it accordingly
            // For example, set a default slug or perform any necessary actions

            this.localStorageService.clearOrgData();
            // this.localStorageService.clearProTypeData();
            return EMPTY;
          } else if (isConfirmAccountUrl) {
            this.localStorageService.clearOrgData();
            this.verifiedSlug = localStorage.getItem('verifiedSlug');
          }
          const segments = url.split('/');
          if (segments[1].includes('?')) {
            segments[1] = segments[1]?.split('?')[0];
          }
          if (segments[2] && segments[2].includes('?')) {
            segments[2] = segments[2]?.split('?')[0];
          }
          if (segments[3] && segments[3].includes('?')) {
            segments[3] = segments[3]?.split('?')[0];
          }
          // Consumer slug verify
          const isPro = segments[1] === 'pro';
          const isOrg = segments[2] === 'org';
          if (!isPro && !isOrg) {
            const urlParams = new URLSearchParams(url.split('?')[1]);
            const baseUrl = url.split('?')[0];
            // Constructing queryParams object
            const queryParams = {};
            urlParams.forEach((value, key) => {
              queryParams[key] = value;
            });
            const fnMergeChildren = (route: Route) => {
              // Include child routes where path is empty
              if (route.path === '' && route.children) {
                route.children.forEach(child => {
                  config.push(child);
                  fnMergeChildren(child);
                });
              }
            };
            const config = this.router.config;
            config.forEach(route => fnMergeChildren(route));
            const route = this.router.config.find(route => route.path === segments[1] || route.path === `${segments[1]}/:id` || route.path === `:slug/${segments[1]}` || route.path === `:slug/${segments[1]}/:id`);
            if (route && this.verifiedSlug && this.verifiedSlug !== segments[1]) {
              if (!isUserLoggedIn && route) {
                this.localStorageService.clearOrgData();
                return EMPTY;
              } else {
                void this.navigate([baseUrl], { queryParams });
                this.slugService.setSlug(true);
                return EMPTY;
              }
            } else if (route) {
              return EMPTY;
            } else {
              const slug = segments[1];
              return (isUserLoggedIn || isExecutorLoggedIn) ? this.slugService.verifyUserSlug().pipe(
                map(response => {
                  if (response?.data?.slug_url && response?.data?.slug_url !== slug) {
                    const navigateUrl = url.replace(slug, response.data.slug_url);
                    void this.navigate([navigateUrl]);
                    return EMPTY;
                  } else if (!this.verifiedSlug && !route && !response?.data?.slug_url) { // remove unwanted slug here
                    const navigateUrl = baseUrl.replace(`/${url.split('/')[1]}`, '');
                    void this.navigate([navigateUrl], { queryParams });
                    this.slugService.setSlug(false);
                    return { data: { isCatchError: true } };
                  } else {
                    return response;
                  }
                }),
              ) : this.slugService.verifyProSlug(slug);
            }

          }
          // Pro slug verify
          if (segments.length >= 2) {
            let slug = '';
            let isSlugValid = false;
            this.router.config.push(
              { path: 'clients' },
              { path: 'pro-message' },
              { path: 'advisors' },
              { path: 'managers' },
              { path: 'funeral-directors' },
              { path: 'project-managers' },
              { path: 'attorney' },
              { path: 'sales-agents' },
            );
            // Check if the last segment is a valid slug

            if (segments[1] === 'pro') {
              if (segments.length > 2 && !this.router.config.some((route) => route.path === segments[2])) {
                slug = segments[2];
              } else
                if (this.verifiedSlug && this.router.config.some((route) => route.path === segments[2])) {
                  this.slugService.setSlug(true);
                  return EMPTY;
                } else if (!this.verifiedSlug) {
                  return EMPTY;
                }

            } else if (segments[1] === 'org' && segments.length > 3 && (!(segments[2] === this.localStorageService.getDataByKey('pro-type')?.[0]?.displayText.toLowerCase().replace(/\s+/g, '-')) && segments[2] !== 'human-resources')) {
              slug = segments[2];
            } else if (segments[1] !== 'pro' && segments[1] !== 'org' && !this.router.config.some((route) => (route.path?.includes('/:id') ? route.path.split('/')[0] : route.path) === segments[1])) {
              slug = segments[1];
            }

            isSlugValid = !!slug || isSlugValid;

            if (isSlugValid) {
              const route = this.router.config.find(route => route.path === segments[2]);
              if (this.verifiedSlug && slug !== this.verifiedSlug && isProLoggedIn) {
                const navigateUrl = route ? url : url.replace(`${url.split('/')[2]}`, this.verifiedSlug);
                void this.navigate([navigateUrl]);
                this.slugService.setSlug(true);
                return EMPTY;
              }
              return segments[1] !== 'pro' ? this.slugService.verifySlug(slug) : this.slugService.verifyProSlug(slug);
            } else {
              return EMPTY;
            }
          } else {
            this.localStorageService.clearProTypeData();
            this.slugVerificationFailedHandler(url);
            return EMPTY;
          }
        }),
      )
      .subscribe(
        (response: APIResponseModel) => {
          // Handle API response and perform necessary action
          if (response.data?.['logo']) {
            // Slug verification successful
            this.verifiedSlug = response.data['slug_url'];
            localStorage.setItem('verifiedSlug', response.data['slug_url']);
            localStorage.setItem('orgLogo', response.data['upload_logo']);
            this.slugService.setSlug(true);
          } else if (!response.data?.isCatchError && response.data) {
            // Handle API exception/error
            this.toastrService.error('Sorry! You do not have permission to access the page.');
            void this.navigate(['page-not-found']);
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status == HttpStatusCode.Unauthorized || err.status == HttpStatusCode.Forbidden) {
            this.toastrService.error('Sorry! You do not have permission to access the page.');
            this.slugVerificationFailedHandler(['']);
          }
        },
      );
  }

  /**
   * Drop-in function for {@link Router.navigate} with functionality to prepend the verified slug and optionally the professional route to the route.
   * @param{any[]} commands
   * @param{NavigationExtras} extras
   * @param{boolean} bPro If Professional slug is needed (or org, dynamically determined)
   * @param{boolean} openInNewTab If true, the route will be opened in a new tab.
   * @return{boolean}
   * @see {@link Router.navigate}
   */
  public navigate(commands: any[], extras?: NavigationExtras, bPro: boolean = false, openInNewTab: boolean = false): Promise<boolean> {
    const proType = this.localStorageService.getDataByKey('pro-type')?.[0]?.displayText.toLowerCase().replace(/\s+/g, '-');
    this.verifiedSlug = localStorage.getItem('verifiedSlug');
    const isVerifiedSlugPresent = commands?.find(command => (command && command?.includes(this.verifiedSlug)));
    commands = [
      ...(bPro ?
        [proType === 'human-resources' ? ROUTE_PREFIX.ORG : ROUTE_PREFIX.PRO] :
        []), // Add Pro Slugs
      // ...(this.verifiedSlug != null && !isVerifiedSlugPresent ? [this.verifiedSlug, bPro ? proType : ''] : [bPro ? proType : '']), // Add Verified Slug & industry type
      ...(this.verifiedSlug != null && !isVerifiedSlugPresent ? [this.verifiedSlug, ''] : ['']),
      ...commands,
    ];

    // encode and decode commands for proper navigation
    const encodedUrl: string = encodeURIComponent(commands.join('/').replace(/\/+/g, '/'));
    const decodedUrl: string = decodeURIComponent(encodedUrl);
    if (openInNewTab) {
      window.open(decodedUrl, '_blank');
      return;
    }
    return this.router.navigate([decodedUrl], extras ? extras : {}); // Can't pass null extras -- route won't work.
  }

  /**
   * Drop-in function for {@link Router.navigateByUrl} with functionality to prepend the verified slug and optionally the professional route to the route.
   * Functionally, this will pass the arguments along to {@link navigate}, making this a convenience function.
   * @param{any[]} commands
   * @param{NavigationExtras} extras
   * @param{boolean} bPro
   * @param openInNewTab
   * @return{boolean}
   * @see navigate
   * @see {@link Router.navigateByUrl}
   */
  public navigateByUrl(commands: string | UrlTree, extras?: NavigationExtras, bPro: boolean = false, openInNewTab = false): Promise<boolean> {
    return this.navigate(commands?.toString().split('/'), extras, bPro, openInNewTab);
  }

  /**
   * Handle a failed slug verification.
   * @param{string | string[]}url
   * @private
   */
  private slugVerificationFailedHandler(url: string | string[] = null) {
    this.verifiedSlug = '';
    this.localStorageService.clearOrgData();
    this.slugService.setSlug(false);
    if (url != null) {
      void this.navigate(typeof url === 'string' ? url.split('/') : url);
    }
  }

  /**
   * verifyMissingProType
   * @param segments
   */
  private verifyMissingProType(segments: string[]): boolean {
    return !(Object.values(PROFESSIONAL_TYPE).some(proType => segments.some((value: string) => value === proType)));
  }
}
