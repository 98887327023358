<app-common-modal id="add-social-media-modal" class="app-common-modal">
  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">
    <form [formGroup]="socialMediaForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Social Media Details
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">Social media is an internet-based form of communication. Social
            media platforms allow users to have conversations, share information and create web content.
          </p>
          <p class="text-regular font-medium text-xs mt-2 error-alert"
            *ngIf="(isNewAsset && !permissions?.['add']) || (!isNewAsset && !permissions?.['edit'])">
            You do not have permission to
            {{isNewAsset ? 'add information for this client.' : 'edit this client\'s information.'}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="social-media-type" class="text-label-grey text-sm text-regular">Name of Social Media*</label>
          <input [attr.disabled]="!canEdit || undefined" type="text" id="social-media-type" autocomplete="off"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="Name of Social Media" formControlName="social_media_name" name="social_media_name"
            [ngClass]="formGet.social_media_name.errors && formGet.social_media_name.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('social_media_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="website" class="text-label-grey text-sm text-regular">Website</label>
          <input [attr.disabled]="!canEdit || undefined" type="text"
            class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
            name="website" formControlName="website" placeholder="e.g www.unitedhealthgroup.com" id="website"
            [ngClass]="formGet?.website.errors && formGet?.website.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('website')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-6">
          <label for="user-name" class="text-label-grey text-sm text-regular">Username</label>
          <input [attr.disabled]="!canEdit || undefined" type="text" id="user-name"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="e.g. Marktylor_54" formControlName="user_name" name="user_name"
            [ngClass]="formGet.user_name.errors && formGet.user_name.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('user_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-6">
          <label for="password" class="text-label-grey text-sm text-regular">Password</label>
          <div class="relative">
            <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
              (click)="togglePasswordView = !togglePasswordView">
              <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
              <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
            </a>
            <input [type]="togglePasswordView?'text':'password'" id="password" [attr.disabled]="!canEdit || undefined"
              class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
              placeholder="e.g. JOHy5689" formControlName="password" name="password"
              [ngClass]="formGet.password.errors && formGet.password.touched?'form-input-error':'form-input-focus'">
            <p *ngFor="let error of getErrors('password')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </div>
      </div>
      <div class=" flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 ">
        <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewAsset" [buttonText]="'Remove Social Media'" [valid]="!submitLoader && canEdit"
          [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true" (click)="deleteSocialMedia()">
        </app-button-loader>
        <app-button-loader class="w-full sm:w-auto" [buttonText]="!isNewAsset?'Update Social Media':'Add Social Media'"
          [valid]="((isNewAsset && permissions?.['add']) || (!isNewAsset && permissions?.['edit'])) && !submitLoaderDelete && canEdit"
          [loader]="submitLoader" [buttonType]="'submit'">
        </app-button-loader>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>
