import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {CommonHelper} from './common.helper';
import {APIStatusCodes} from "../interface/response.interface";
import { USER_TYPES } from '../../constants/application.const';

@Injectable()
/**
 * unauthorized
 */
export class UnAuthorizedInterceptor implements HttpInterceptor {
  /**
   * constructor
   *  */
  constructor(private router: Router,
              private commonHelper:CommonHelper,
              private toastr:ToastrService) { }
  /**
   * intercept
   * @description interceptor to intercept the request
   * @param {HttpRequest} request request object from http
   * @param {HttpHandler} next handler object from http
   * @return {Observable} return api request with jwt token attached to header
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap((res)=>{},
      (err: any) => {
        if ((err?.error?.statusCode === APIStatusCodes.LOCKED))
          this.commonHelper.logoutCommon(USER_TYPES.pro);
      }));
  }
}
