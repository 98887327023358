<div class="scroll-hidden">
  <!-- Navbar -->
  <app-navbar *ngIf="this.userType === 'Consumer'"></app-navbar>
  <app-exe-cus-navbar *ngIf="this.userType === 'Custodian'"></app-exe-cus-navbar>
  <app-professional-navbar [showReturnToDashboard]="true" *ngIf="this.userType !== 'Consumer'&& this.userType!=='Custodian'"></app-professional-navbar>

  <!-- Overview section -->
  <div class="lg:grid grid-cols-12 p-8 sm:p-12 lg:px-24 py-12 background-color-grey">
    <div class="col-span-12 mb-1">
      <div class="flex items-center gap-4">
        <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
          Digital Vault Overview
        </h2>
        <a (click)="openModal('digitalVaultOverview')" title='Click here for help from Aida'>
          <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
            stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1n text-primary cursor-pointer">
            <circle cx="12" cy="12" r="10"></circle>
            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
          </svg>
        </a>
      </div>
      <hr class="border-blue w-24 my-2 border-1">
    </div>

    <div class="col-span-12">
      <!-- BEGIN: Web view Tabs -->
      <nav class="flex flex-col hidden lg:flex sm:flex-row border-b" aria-label="Navigation"
        *ngIf="this.userType==='Consumer'">

        <button (click)="changeRoute('overview')"
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': applyActiveRouteClass('overview')}"
          class="text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none ">
          Overview
        </button>
        <button (click)="changeRoute('overview/about-yourself-overview')"
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': applyActiveRouteClass('overview/about-yourself-overview')}"
          class="text-regular text-lg text-grey text-regular py-4 px-8 block hover:text-blue-500 focus:outline-none">
          About Yourself
        </button>

        <button
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': applyActiveRouteClass('overview/executor-beneficiaries-overview')}"
          (click)="changeRoute('overview/executor-beneficiaries-overview')"
          class="cursor-pointer text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          People Summary
        </button>
        <button
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': applyActiveRouteClass('overview/finance-overview')}"
          (click)="changeRoute('overview/finance-overview')"
          class="cursor-pointer text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          Finance
        </button>
        <button
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': applyActiveRouteClass('overview/insurance-overview')}"
          (click)="changeRoute('overview/insurance-overview')"
          class="cursor-pointer text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          Insurance
        </button>
        <button
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': applyActiveRouteClass('overview/property-overview')}"
          (click)="changeRoute('overview/property-overview')"
          class="cursor-pointer text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          Property
        </button>
        <button
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': applyActiveRouteClass('overview/will-digital-file-overview')}"
          (click)="changeRoute('overview/will-digital-file-overview')"
          class="cursor-pointer text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          Uploads
        </button>
      </nav>
      <nav class="flex flex-col hidden lg:flex sm:flex-row border-b" aria-label="Navigation"
        *ngIf="this.userType!=='Consumer'">
        <!-- will be used in future -->
        <!-- <button (click)="changeRoute('/overview/'+requestId)"
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': applyActiveRouteClass('overview/'+requestId')}"
          class="text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none ">
          Overview
        </button> -->
        <!-- <button (click)="changeRoute('/overview/about-yourself-overview/'+requestId)"
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': applyActiveRouteClass('overview/about-yourself-overview/'+requestId')}"
          class="text-regular text-lg text-grey text-regular py-4 px-8 block hover:text-blue-500 focus:outline-none">
          About Yourself
        </button> -->
        <!-- <button
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': applyActiveRouteClass('overview/executor-beneficiaries-overview/'+requestId')}"
          (click)="changeRoute('/overview/executor-beneficiaries-overview/'+requestId)"
          class="text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          People Summary
        </button> -->
        <button *ngIf="this.assetsStatus || this.liabilityStatus"
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': applyActiveRouteClass('overview/finance-overview/'+requestId)}"
          (click)="changeRoute('/overview/finance-overview/'+requestId)"
          class="text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          Finance
        </button>
        <!-- {{this.assetsStatus}} {{liabilityStatus}} -->
        <button *ngIf="this.insuranceStatus"
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': applyActiveRouteClass('overview/insurance-overview/'+requestId)}"
          (click)="changeRoute('/overview/insurance-overview/'+requestId)"
          class="text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          Insurance
        </button>

        <button
          *ngIf="this.realEstateStatus|| this.vehicleStatus || this.businessStatus || this.valuableStatus || this.socialMediaStatus || this.subscriptionStatus || this.prescriptionStatus || this.donationStatus || this.governmentIdStatus"
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': applyActiveRouteClass('overview/property-overview/'+requestId)}"
          (click)="changeRoute('/overview/property-overview/'+requestId)"
          class="text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          Property
        </button>

        <button *ngIf="this.digitalFileStatus"
          [ngClass]="{'text-lightblue border-b-2 font-semibold tab-border-red ': applyActiveRouteClass('overview/will-digital-file-overview/'+requestId)}"
          (click)="changeRoute('/overview/will-digital-file-overview/'+requestId)"
          class="text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          Uploads
        </button>
      </nav>
      <!-- END: Web view Tabs -->

      <!-- BEGIN: Mobile View Tabs -->
      <nav aria-label="Navigation" *ngIf="this.userType==='Consumer'">
        <div class="relative w-auto mt-6 lg:hidden">
          <div (click)="responsiveMenuToggle = !responsiveMenuToggle"
            class="cursor-pointer input py-2 h-12 px-4 lg:px-0 text-regular text-sm border rounded-xs appearance-none block focus:outline-none text-white  flex flex-row items-center justify-between lg:justify-around">
            <a class="text-darkblue">{{mobileMenuName}}</a>
            <div>
              <img src="assets/images/about-yourself/angle-down-arrow.png" class="w-3 cursor-pointer"
                alt="digital vault">
            </div>
          </div>

          <div class="lg:hidden shadow-md cursor-pointer pb-4" id="mobile-menu-consumer" *ngIf="responsiveMenuToggle">
            <ul class="input w-full border up-arrow bg-white rounded-xs mt-4">
              <li class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer"
                [ngClass]="{'text-lightblue font-semibold tab-border-red ': applyActiveRouteClass('overview')}"
                (click)="changeRoute('/overview')" (click)="onMobileMenuchange('Overview')">
                Overview
              </li>
              <li class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer"
                [ngClass]="{'text-lightblue font-semibold tab-border-red ': applyActiveRouteClass('overview/about-yourself-overview')}"
                (click)="changeRoute('/overview/about-yourself-overview')"
                (click)="onMobileMenuchange('About Yourself')">
                About Yourself
              </li>

              <li class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer"
                [ngClass]="{'text-lightblue font-semibold tab-border-red ': applyActiveRouteClass('overview/executor-beneficiaries-overview')}"
                (click)="changeRoute('/overview/executor-beneficiaries-overview')"
                (click)="onMobileMenuchange('People Summary')">
                People Summary
              </li>
              <li class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer"
                [ngClass]="{'text-lightblue font-semibold tab-border-red ': applyActiveRouteClass('overview/finance-overview')}"
                (click)="changeRoute('/overview/finance-overview')" (click)="onMobileMenuchange('Finance')">
                Finance
              </li>
              <li class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer"
                [ngClass]="{'text-lightblue font-semibold tab-border-red ': applyActiveRouteClass('overview/insurance-overview')}"
                (click)="changeRoute('/overview/insurance-overview')" (click)="onMobileMenuchange('Insurance')">
                Insurance
              </li>
              <li class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer"
                [ngClass]="{'text-lightblue font-semibold tab-border-red ': applyActiveRouteClass('overview/property-overview')}"
                (click)="changeRoute('/overview/property-overview')" (click)="onMobileMenuchange('Property')">
                Property
              </li>
              <li class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer"
                [ngClass]="{'text-lightblue font-semibold tab-border-red ': applyActiveRouteClass('overview/will-digital-file-overview')}"
                (click)="changeRoute('/overview/will-digital-file-overview')" (click)="onMobileMenuchange('Uploads')">
                Uploads
              </li>
            </ul>
          </div>

        </div>
      </nav>
      <nav aria-label="Navigation" *ngIf="this.userType!=='Consumer'">
        <div class="relative w-auto mt-6 lg:hidden">
          <div (click)="responsiveMenuToggle = !responsiveMenuToggle"
            class="cursor-pointer input py-2 h-12 px-4 lg:px-0 text-regular text-sm border rounded-xs appearance-none block focus:outline-none text-white  flex flex-row items-center justify-between lg:justify-around">
            <a class="text-darkblue">{{mobileMenuName}}</a>
            <div>
              <img src="assets/images/about-yourself/angle-down-arrow.png" class="w-3 cursor-pointer"
                alt="digital vault">
            </div>
          </div>

          <div class="lg:hidden shadow-md cursor-pointer pb-4" id="mobile-menu" *ngIf="responsiveMenuToggle">
            <ul class="input w-full border up-arrow bg-white rounded-xs mt-4">
              <li class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer"
                [ngClass]="{'text-lightblue font-semibold tab-border-red ': applyActiveRouteClass('overview/'+requestId)}"
                (click)="changeRoute('/overview/'+requestId)" (click)="onMobileMenuchange('Overview')">
                Overview
              </li>
              <li class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer"
                [ngClass]="{'text-lightblue font-semibold tab-border-red ': applyActiveRouteClass('overview/about-yourself-overview/'+requestId)}"
                (click)="changeRoute('/overview/about-yourself-overview/'+requestId)"
                (click)="onMobileMenuchange('About Yourself')">
                About Yourself
              </li>

              <li class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer"
                [ngClass]="{'text-lightblue font-semibold tab-border-red ': applyActiveRouteClass('overview/executor-beneficiaries-overview/'+requestId)}"
                (click)="changeRoute('/overview/executor-beneficiaries-overview/'+requestId)"
                (click)="onMobileMenuchange('People Summary')">
                People Summary
              </li>
              <li *ngIf="this.assetsStatus || this.liabilityStatus"
                class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer"
                [ngClass]="{'text-lightblue font-semibold tab-border-red ': applyActiveRouteClass('overview/finance-overview/'+requestId)}"
                (click)="changeRoute('/overview/finance-overview/'+requestId)" (click)="onMobileMenuchange('Finance')">
                Finance
              </li>
              <li *ngIf="this.insuranceStatus"
                class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer"
                [ngClass]="{'text-lightblue font-semibold tab-border-red ': applyActiveRouteClass('overview/insurance-overview/'+requestId)}"
                (click)="changeRoute('/overview/insurance-overview/'+requestId)"
                (click)="onMobileMenuchange('Insurance')">
                Insurance
              </li>
              <li
                *ngIf="this.realEstateStatus|| this.vehicleStatus || this.businessStatus || this.valuableStatus || this.socialMediaStatus || this.subscriptionStatus || this.prescriptionStatus || this.donationStatus || this.governmentIdStatus"
                class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer"
                [ngClass]="{'text-lightblue font-semibold tab-border-red ': applyActiveRouteClass('overview/property-overview/'+requestId)}"
                (click)="changeRoute('/overview/property-overview/'+requestId)"
                (click)="onMobileMenuchange('Property')">
                Property
              </li>
              <li *ngIf="this.digitalFileStatus"
                class="px-3 text-grey text-sm py-2 flex justify-between items-center cursor-pointer"
                [ngClass]="{'text-lightblue font-semibold tab-border-red ': applyActiveRouteClass('overview/will-digital-file-overview/'+requestId)}"
                (click)="changeRoute('/overview/will-digital-file-overview/'+requestId)"
                (click)="onMobileMenuchange('Uploads')">
                Uploads
              </li>
            </ul>
          </div>

        </div>
      </nav>
      <!-- Router outlet overview section - sub-components -->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>
<app-payment-success></app-payment-success>
<app-payment-failure></app-payment-failure>