import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-us-privacy-policy',
  templateUrl: './us-privacy-policy.component.html',
  styleUrls: ['../../../../../components/consumer-landing-page/documents.css'],
})
/**
 * privacy pilicy
 */
export class UsPrivacyPolicyComponent {
  public menuActive: string;
  public responsiveMenuToggle: boolean;
  public customerSupportMail: string;
  public customerSupportPhone: number;

  /**
   * constructor
   * @param {Router} router
   * @param {ActivatedRoute} route
   * @param {CommonService}commonService
   */
  constructor(
    private commonHelper: CommonHelper,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private slugInterceptorService: SlugInterceptorService,
  ) { }
  /**
  * initial loader
  */
  ngOnInit(): void {
    this.getContactDetails();
    this.responsiveMenuToggle = false;
    this.menuActive = this.route.snapshot.url[0]?.path;
    this.commonService.navbarObserve.subscribe((response) => {
      if (response) {
        this.menuActive = response;
      }
    });
  }
  /**
     * @param{string}url
     * on menu change
     */
  public menuChange(url?: string) {
    if (url) {
      this.menuActive = url;
      void this.slugInterceptorService.navigate([url], null, true);
      this.commonService.setActiveNavbarTrigger(this.menuActive);
    }
  }

  /**
    * get current year
    * @return{number}
    */
  public getCurrentYear() {
    return this.commonHelper.currentYear;
  }

  public getContactDetails() {
    this.commonService.getContactUsDetails().subscribe((response) => {
      this.customerSupportMail = response.data?.support_email
      this.customerSupportPhone = response.data?.support_number;
    })
  }


  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, false, true);
  }
}
