import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { DlbService } from 'src/app/services/dlb.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ReceivedDlbService } from 'src/app/services/received-dlb.service';
import { ALPHA_NUMERIC_PATTERN } from 'src/constants/application.const';

@Component({
  selector: 'app-claim-dlb',
  templateUrl: './claim-dlb.component.html',
})
/**
 * Claim dlb popup
 */
export class ClaimDlbComponent implements OnInit {
  public claimDlbForm: FormGroup;
  public submitLoader: boolean;
  public userDetails: any;
  public requestId: string;
  public name: string;
  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private dlbService: DlbService,
    private formBuilder: FormBuilder,
    private commonHelper: CommonHelper,
    private toastService: ToastrService,
    private localService: LocalStorageService,
    private receivedDlbService: ReceivedDlbService,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.userDetails = this.localService.getUserData();
    this.receivedDlbService.selectedUserObserve.subscribe((response: any) => {
      if (Object.keys(response).length > 0) {
        this.name = response.first_name + ' ' + response.last_name;
        this.requestId = response.request_id;
      }
    });
    this.claimDlbForm = this.formBuilder.group({
      'blockchain_key': ['', [Validators.required, Validators.pattern(ALPHA_NUMERIC_PATTERN)]],
    });
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.claimDlbForm.controls;
  }

  /**
   * on claiming
   */
  public onSubmit(): void {
    this.submitLoader = true;
    this.dlbService.claimDlb({
      ...this.claimDlbForm.value,
      request_id: this.requestId,
      user_id: this.userDetails?.user?.id,
      requestIdOverride: true,
    }).subscribe((response: APIResponseModel) => {
      this.submitLoader = false;
      if (response.status) {
        this.closeModel();
        this.dlbService.dlbListingTrigger(true);
        this.toastService.success(response.message);
      }
    }, (exception: any) => {
      this.submitLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
   * Claim dlb model
   */
  public closeModel(): void {
    this.modalService.close('claim-dlb-modal');
    this.claimDlbForm.reset();
  }
}
