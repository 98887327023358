import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { UpdatePeopleInterface } from 'src/app/interface/common.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { LIST_DELETE, LIST_NEW } from 'src/constants/application.const';
import { PersonalDetailsService } from '../../../services/personal-details.service';
import {
  CPCEmitInterface,
  CPCSettingsInterface,
} from '../../../sharedComponent/choose-people-component/choose-people-interfaces.';
import {
  LPEmitterInterface,
  LPSettingsInterface,
  LPUserPeopleInterface,
} from '../../../sharedComponent/list-people/list-people-interfaces';
import { ListPeopleComponent } from '../../../sharedComponent/list-people/list-people.component';
import { MessageModalDataInterface } from '../../../sharedComponent/message-modal/message-modal.component';


// eslint-disable-next-line no-unused-vars
enum messageResponses {
  addSelectedAsExecutor_yes,
  addSelectedAsExecutor_no,
  advisor_yes,
  partner_yes,
  child_yes,
}


@Component({
  selector: 'app-manage-executors',
  templateUrl: './manage-executors.component.html',
})
/**
 * Manage executors
 */
export class ManageExecutorsComponent implements OnInit {
  private userPeopleDataRaw: Array<Object> = [];
  public userPeopleData: Array<LPUserPeopleInterface>;
  public chooseExecutorsData: Array<LPUserPeopleInterface>;
  public lpSettings: LPSettingsInterface;
  public messageData: MessageModalDataInterface;
  public editData: Object;
  public submitLoader: boolean;
  public userType: string;
  public requestId: string;
  @Output() requestIdEmitter = new EventEmitter<string>();
  public newExecutor: object = {};
  // ChoosePersonComponent settings.
  public cpcSettings: CPCSettingsInterface;
  public vaultVideo: string;
  public currentUrl: string;
  public isPartnerDeceased: boolean;

  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private peopleService: PeopleService,
    private router: Router,
    private commonHelper: CommonHelper,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService,
    private personalDetailsService: PersonalDetailsService,
  ) {
  }

  /**
   * Called initially
   */
  ngOnInit(): void {
    this.currentUrl = window.location.pathname;
    this.cpcSettings = {
      text: {
        header: 'Choose Executors',
        sub_header: 'Please provide contact information for your Executor(s). This information will be critical at ' +
          'the time of your passing. It is important that should this information change, you update this record.',
        add_new: 'Add a New Executor',
        choose_people: 'Set as Executors',
        checkbox: {
          text: 'Set as Executor:',
        },
      },
    };
    this.lpSettings = {
      checkbox: {
        show: true,
        text: 'Assign as DVC:',
        tooltip: 'The DVC is a trusted individual you appoint to manage your digital assets and account information while you are alive and to inform LegacyNOW of your passing',
      },
      delete: {
        show: true,
        tooltip: 'Remove',
        useIcon: 'faTrash',
      },
      edit: { show: true, tooltip: 'Edit' },
    };
    this.requestId = this.route.snapshot.params['id'];
    this.getUserPeople();
    this.requestIdEmitter.emit(this.requestId);
    this.userType = this.localStorageService.getDataByKey('role');
  }

  /**
   * Toggles relatives choose as executor
   * @param {any} editValue
   */
  public toggleChooseRelativesModal(editValue?: any): void {
    this.modalService.open('choose-people-modal');
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }

  /**
   * change route with redirect url
   * @param{string} url
   * @param{string} redirectUrl
   */
  public changeRouteWithRedirection(url, redirectUrl: any, peopleId?: string) {
    void this.slugInterceptorService.navigate([url], { queryParams: { 'redirectTo': redirectUrl, 'peopleId': peopleId } });
  }

  /**
   * Function called with 'Save and Continue' button is pressed.
   */
  public saveAndContinue() {
    this.submitLoader = true;

    const isProfessional = this.userType !== 'Consumer';

    // Executor-Custodians
    const executorCustodiansList = this.userPeopleDataRaw.filter((e) => e['custodian'] == 1 && e['executor'] == 1).map((e) => e['id']);

    // Executors Alone
    const executorsList = this.userPeopleDataRaw.filter((e) => e['executor'] == 1).map((e) => e['id']);

    // Executor-Custodians set permissions for ACL
    if (executorCustodiansList?.length) {
      this.storeConsent();
    }

    // Executor-Custodians and Executors
    const payloadExecutorCustodians: UpdatePeopleInterface = {
      executors: executorsList,
      dvcs: executorCustodiansList,

    };

    this.saveExecutorCustodianPeople(payloadExecutorCustodians, isProfessional);
  }

  /**
   * save executor custodian people
   * @param{any}payloadExecutorCustodians
   * @param{boolean}isProfessional
   */
  public saveExecutorCustodianPeople(payloadExecutorCustodians: any, isProfessional: boolean) {
    // if not consumer - must pass request_id to access
    if (isProfessional) {
      payloadExecutorCustodians['request_id'] = this.requestId;
    }
    // Common error function.
    const errorFn = (exception) => {
      this.submitLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    };

    this.peopleService.updateExecutorCustodianDetails(payloadExecutorCustodians).subscribe({
      next: (response) => {
        if (response.status) {
          this.commonHelper.updateLocalstorageRequestStage(response.data);
          const url = ['executor-beneficiary', 'manage-custodian'];
          if (isProfessional) {
            url.push(this.requestId);
          }
          this.submitLoader = false;
          void this.slugInterceptorService.navigate(url);
        }
      }, error: errorFn,
    });
  }

  /**
   * Persist changes from previous state of {@link userPeopleDataRaw} using {@link oldRawData}
   * @private
   */
  private persistState(oldRawData: Array<Object>) {
    this.userPeopleDataRaw.forEach((current) => {
      const prev = oldRawData.find((e) => e['id'] == current['id']);
      if (prev) {
        current['executor'] = prev['executor'];
        current['custodian'] = prev['custodian'];
      }
    });
  }

  /**
   * Get User People from endpoint, call {@link processUserPeopleData}.
   * @private
   */
  private getUserPeople() {
    this.lpSettings.isLoading = true;
    this.personalDetailsService.getUserPeople(this.requestId).subscribe({
      next: (r) => {
        if (r.status) {
          const oldRawData = this.userPeopleDataRaw;
          // Filter out trust-beneficiaries
          this.userPeopleDataRaw = r.data.user_people.filter((e) => e['beneficiary_type']?.type != 2);
          // here 4 indicates user has selected 'Widowed' option in marital status section
          this.isPartnerDeceased = r.data.marital_status === 4;
          this.persistState(oldRawData);
          this.processUserPeopleData();
          this.updateListener(this.newExecutor); // new executor add as custodian
        }
        this.lpSettings.isLoading = false;
      },
      error: (e) => {
        this.commonHelper.httpResponseHandler(e?.error);
        this.lpSettings.isLoading = false;
      },
    });
  }

  /**
   * Process {@link this.userPeopleDataRaw userPeopleDataRaw} to populate {@link this.userPeopleData userPeopleData}
   * with data to display.
   * @private
   */
  private processUserPeopleData() {
    this.userPeopleData = [];
    this.chooseExecutorsData = [];

    // Process items
    this.userPeopleDataRaw.forEach((e) => {
      const person = ListPeopleComponent.getLPUserPeople(e)[0];

      // Removes Deceased Partner or Removes Professional User or Removes PSP has choosen Company
      if (this.isPartnerDeceased && e['relation'] === 1 || (e['advisor'] == 1 && e?.['professional']?.['professional_id'] === null || (e['advisor'] == 1 && !e?.['last_name']))) {
        return;
      }
      // Advisor can't be edited
      if (e['advisor'] == 1) {
        person.edit = { hide: true };
      }

      if (e['executor'] == 1) {
        // user_people to display as executors.
        person.checkbox.isChecked = e['custodian'];
        // person.checkbox.disableCheckbox = true;
        this.userPeopleData.push(person);
      } else {
        // Determine Eligibility
        this.commonHelper.processPersonForExecutorCustodianEligibility(e, person);

        // user_people who are not executors, to choose from a list when adding a new executor(s).
        this.chooseExecutorsData.push(person);
      }
    });
  }

  /**
   * Listener for ListPeopleComponent
   * @param event
   */
  public updateListener(event: CPCEmitInterface) {
    if (event.chosen_people) {
      // Removed - UAT #227 | Keep in case this decision is vetoed later.
      // const user_person_list = [];
      // let hasOtherPeopleWithAdvisor = false;
      event?.chosen_people.forEach((e) => {
        const user_person = this.userPeopleDataRaw.find((p) => p['id'] == e['id']);
        if (user_person) {
          user_person['executor'] = e.checkbox?.isChecked ? 1 : 0;
          user_person['custodian'] = 0;
          // Removed - UAT #227 | Keep in case this decision is vetoed later.
          // user_person_list.push(user_person);
        }
      });
      // Removed - UAT #227 | Keep in case this decision is vetoed later.
      // hasOtherPeopleWithAdvisor = !!user_person_list.find((p) => p['advisor'] !== 1); // to avoid asking to assign them as your DVC if selected person is advisor only
      this.processUserPeopleData();
      this.messageData = {
        title: { content: 'Important note:', class: 'text-red' },
        message: [
          {
            content: 'We recommend that users can select their executor as their Digital Vault Custodian. Would you like to assign them as your DVC? If you would like to assign a different DVC, you can do so in the next step of your vault creation.',
            class: 'text-sm',
          },
        ],
        buttons: [{
          content: 'No',
          emitValue: { data: messageResponses.addSelectedAsExecutor_no, value: true },
          class: 'text-red custom-noButton-border',
        }, {
          content: 'Yes',
          emitValue: { data: messageResponses.addSelectedAsExecutor_yes, value: event.chosen_people },
          class: 'bg-darkblue text-white mr-4',
        }],
      };
      this.modalService.open('message-modal');
      // Removed - UAT #227 | Keep in case this decision is vetoed later.
      // if (hasOtherPeopleWithAdvisor) {
      // }
    }
    if (event.add_new) {
      this.toggleAddModal();
    }
    if (event.person_clicked_id) {
      this.openEdit(event.person_clicked_id);
    }
  }

  /**
   * store acceess permissions
   */
  public storeConsent(): void {
    const payLoad = {
      inform_exec_permission: 1,
      access_to_custodian: 1,
      roletype: this.userType,
      request_id: this.userType !== 'Consumer' ? this.requestId : undefined,
    };
    this.peopleService.authorizeAdvisor(payLoad).subscribe({
      error: (exception: any) => {
        this.commonHelper.httpResponseHandler((exception.error));
      },
    });
  }

  /**
   * Handles event of editing a user-person
   * TODO currently filters out advisors and family members as their information doesn't fit with the current component. In future, we want to replace the component with a dynamic component which can handle each case.
   * @param{string} id
   * @private
   */
  private openEdit(id: string) {
    const person = this.userPeopleDataRaw.find((e) => e['id'] == id);
    this.messageData = {
      title: 'Edit Required To Continue',
      message: [],
    };
    if (person['advisor'] == 1) {
      const agentTerm = this.commonHelper.LocalStorageAgentTerm();
      // this.messageData.title = 'Edit Blocked'
      // this.messageData.message = [`You do not have access to edit your ${agentTerm}'s information`];
      // this.messageData.buttons = [{ content: 'Close', emitValue: { data: messageResponses.advisor_yes, value: true } }];
      // this.toggleMessageModal();
    } else if (person['relation'] == 1) {
      this.messageData.message = ['Executors are required to be 18 years of age or older, and we must have their phone and email to contact them. If the person selected meets this criteria, kindly proceed to About Yourself and update your partner’s details to proceed.'];
      this.messageData.buttons = [{ content: 'Proceed', emitValue: { data: messageResponses.partner_yes, value: true } }];
      this.toggleMessageModal();
    } else if (person['relation'] == 2) {
      this.messageData.message = ['Executors are required to be 18 years of age or older, and we must have their phone and email to contact them. If the person selected meets this criteria, kindly proceed to About Yourself and update your child’s details to proceed.'];
      this.messageData.buttons = [{ content: 'Proceed', emitValue: { data: messageResponses.child_yes, value: person?.['id'] } }];
      this.toggleMessageModal();
    } else {
      this.toggleAddModal(person);
    }
  }

  /**
   * Retrieve click events from list-people
   * @param {LPEmitterInterface} event
   */
  public clickListener(event: LPEmitterInterface) {
    const person = this.userPeopleDataRaw.find((e) => e['id'] == event.id);
    if (event.clickedDelete) { // Remove Button
      person['executor'] = 0;
      person['custodian'] = 0;
    } else if (event.clickedCheckbox) { // Setting to 'Inform' (set as custodian)
      person['custodian'] = event.clickedCheckbox.value ? 1 : 0;
    } else if (event.clickedName || event.clickedEdit) {
      this.openEdit(event.id);
    } else {
      // Do nothing, return
      return;
    }
    // Update display data
    this.processUserPeopleData();
  }

  /**
   * Toggle {@link AddExecutorComponent} modal.
   * @param{Object} editValue
   */
  private toggleAddModal(editValue?: Object): void {
    this.modalService.open('add-executor-modal');
    this.editData = {};
    if (editValue) {
      this.editData = { ...editValue };
    }
  }

  /**
   * Toggle {@link MessageModalComponent} modal.
   */
  private toggleMessageModal(): void {
    this.modalService.open('message-modal');
  }

  /**
   * Listener for {@link AddExecutorComponent}
   * @param {any} event
   */
  public toggleAddModalListener(event: any) {
    if (event['id'] || event['listStatus']) {
      if (event['listStatus'] == LIST_NEW) {
        this.newExecutor = { // new executor payload to add as custodian
          chosen_people: [{
            checkbox: { isChecked: true },
            id: event?.id,
            name: '',
          }],
        };
      } else if (event['listStatus'] == LIST_DELETE) {
        this.newExecutor = {};
      }
      this.getUserPeople();
    }
  }

  /**
   * Listener for Message Modal Events
   * @param{any} event
   */
  public messageModalListener(event: any) {
    const isProfessional = this.userType !== 'Consumer';
    if (event != null) {
      this.modalService.close('message-modal');
      switch (event?.data) {
        case messageResponses.addSelectedAsExecutor_yes: // Ensure thet chosen executor can be add as executor else select NO means not to be added
          if (event?.value) {
            event?.value.forEach((e) => {
              // Removed - UAT #227 | Keep in case this decision is vetoed later.
              // const user_person = this.userPeopleDataRaw.find((p) => p['id'] == e['id'] && !p['advisor']);
              const user_person = this.userPeopleDataRaw.find((p) => p['id'] == e['id']);
              if (user_person) {
                user_person['custodian'] = 1;
              }
            });
            this.processUserPeopleData();
            this.modalService.close('choose-people-modal');
          }
          break;
        case messageResponses.addSelectedAsExecutor_no:
          this.modalService.close('choose-people-modal');
          break;
        case messageResponses.advisor_yes:
          // let advisorPageUrl = 'advisors/manage-advisors/';
          // isProfessional ? advisorPageUrl = advisorPageUrl + this.requestId : advisorPageUrl;
          // this.changeRoute(advisorPageUrl);
          break;
        case messageResponses.partner_yes:
          let partnerPageUrl = 'about-yourself/partner/';
          isProfessional ? partnerPageUrl = partnerPageUrl + this.requestId : partnerPageUrl;
          this.changeRouteWithRedirection(partnerPageUrl, this.currentUrl);
          break;
        case messageResponses.child_yes:
          let childPageUrl = 'about-yourself/children/';
          isProfessional ? childPageUrl = childPageUrl + this.requestId : childPageUrl;
          this.changeRouteWithRedirection(childPageUrl, this.currentUrl, event?.value);
          break;
      }
    }
  }

  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');

  }
}


