<app-common-modal id="about-yourself-modal" class="app-common-modal" [heightClass]="'h-full'">
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">
  </div>
  <div class="flex flex-row items-baseline">
    <div>
      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
        Enter your basic details to claim Digital Vault
      </h4>
    </div>
  </div>

  <form [formGroup]="basicDetailsForm" autocomplete="off" (ngSubmit)="onSubmit()">
    <label for="your-name" class="text-label-grey text-sm text-regular">Your full legal name</label>
    <div class="grid grid-cols-12 gap-4 row-gap-4 pt-1">
      <div class="col-span-12 lg:col-span-6">
        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
          <input type="text" id="your-name"
            class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
            name="first_name" formControlName="first_name" placeholder="First Name " maxlength="25"
            [ngClass]="formGet.first_name.errors && formGet.first_name.touched?'form-input-error':'form-input-focus'">
        </app-shimmer-loading>
        <div *ngIf="formGet.first_name.errors && formGet.first_name.touched">
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.first_name.errors.pattern">
            Only alphabets and special characters like ' and - are allowed
          </p>
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.first_name.errors.required">
            Name cannot be empty
          </p>
          <p class="text-regular font-medium text-xs mt-2 error-alert"
            *ngIf="formGet.first_name.errors.maxlength && !formGet.first_name.errors.pattern">
            First Name must not exceed 50 characters
          </p>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-6">
        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
          <input type="text"
            class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
            name="last_name" formControlName="last_name" placeholder="Last Name" maxlength="25"
            [ngClass]="formGet.last_name.errors && formGet.last_name.touched?'form-input-error':'form-input-focus'">
        </app-shimmer-loading>
        <div *ngIf="formGet.last_name.errors && formGet.last_name.touched">
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.last_name.errors.required">Name
            cannot be empty</p>
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.last_name.errors.pattern">
            Only alphabets and special characters like ' and - are allowed
          </p>
          <p class="text-regular font-medium text-xs mt-2 error-alert"
            *ngIf="formGet.last_name.errors.maxlength && !formGet.last_name.errors.pattern">
            Last Name must not exceed 50 characters
          </p>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4 row-gap-4 mt-4">
      <div class="col-span-12 lg:col-span-6" [ngClass]="{'lg:col-span-12': !showSSN}"
        (click)="markAsTouchedIndividual('dob')">
        <label for="dob" class="text-label-grey text-sm text-regular">Date of Birth ( {{domainDateFormat}} )</label>
        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
          <input type="text" id="dob" #dp=bsDatepicker [dp]="dp" bsDatepicker [bsConfig]="bsConfig" name="dob"
            formControlName="dob"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="{{domainDateFormat}}"
            [ngClass]="formGet.dob.errors && formGet.dob.touched?'form-input-error':'form-input-focus'">
        </app-shimmer-loading>
        <div *ngIf="formGet.dob.errors && formGet.dob.touched">
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.dob.errors.required">
            DOB is required
          </p>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-6" *ngIf="showSSN">
        <label for="ssn" class="text-label-grey text-sm text-regular">SSN
          (Social Security Number)</label>
        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
          <div class="relative text-gray-700">
            <a class="absolute inset-y-0 right-2 pr-3 flex items-center" (click)="toggleSSNView = !toggleSSNView">
              <fa-icon [icon]="faEye" *ngIf="toggleSSNView"></fa-icon>
              <fa-icon [icon]="faEyeSlash" *ngIf="!toggleSSNView"></fa-icon>
            </a>
            <input type="text" id="ssn" [type]="toggleSSNView?'text':'password'"
              class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
              name="ssn" formControlName="ssn" placeholder="e.g. 255-34-9345"
              [ngClass]="formGet.ssn.errors && formGet.ssn.touched?'form-input-error':'form-input-focus'"
              [mask]="SSN_MASK" [dropSpecialCharacters]="false" maxlength="11" (focusout)="onFocusout($event,'ssn')">
          </div>
        </app-shimmer-loading>
        <div *ngIf="formGet.ssn.errors && formGet.ssn.touched">
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.ssn.errors.pattern">
            Invalid SSN
          </p>
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.ssn.errors.required">
            SSN is required
          </p>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4 row-gap-4 mt-4">
      <div class="col-span-12 relative move-map" #moveMapHere>
        <label for="street-address" class="text-label-grey text-sm text-regular">Street Address</label>
        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
          <input type="text" id="street-address" #search (keyup)="onAddressChange(search)"
            (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null" (input)="onFocusAddress()"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="e.g Main St" name="address" formControlName="address"
            [ngClass]="formGet.address.errors && formGet.address.touched?'form-input-error':'form-input-focus'">
          <div *ngIf="formGet.address.errors && formGet.address.touched">
            <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.address.errors.required">Address
              is required</p>
            <p class="text-regular font-medium text-xs mt-2 error-alert"
              *ngIf="formGet.address.errors.minlength && !formGet.address.errors.pattern">Minimum 3 characters
              required </p>
            <p class="text-regular font-medium text-xs mt-2 error-alert"
              *ngIf="formGet.address.errors.maxlength && !formGet.address.errors.pattern">Maximum 25 characters
              allowed</p>
            <!-- <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.address.errors.pattern">Only
              letters, digits, space, hyphen, comma are allowed</p> -->
          </div>
        </app-shimmer-loading>
      </div>
      <div class=" col-span-12 md:col-span-6 relative ">
        <label for="country" class=" text-label-grey text-sm text-regular ">Country</label>
        <div class=" col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('country')">
          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
            <app-country-search-component id="country" (optionChangeEmitter)="optionChangeListenerCountry($event)"
              [defaultSelected]="formGet?.country?.value"
              [enableError]="formGet.country.errors && formGet.country.touched"></app-country-search-component>
            <div *ngIf="formGet.country.errors && formGet.country.touched">
              <p class="text-regular font-medium text-xs mt-2 error-alert">Country is required</p>
            </div>
          </app-shimmer-loading>
        </div>

      </div>
      <div class="col-span-12 md:col-span-6 relative" *ngIf="selectedCountryName!==unitedKingdom">
        <label for="state-Province" class="text-label-grey text-sm text-regular">State/Province</label>
        <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('state')">
          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
            <app-state-search-component id="state-Province" [countryCode]="formGet?.country?.value"
              [enableError]="formGet.state.errors && formGet.state.touched" [defaultSelected]="formGet?.state?.value"
              (optionChangeEmitter)="optionChangeListenerState($event)" tabindex="0"
              (focusout)="markAsTouchedIndividual('state')"></app-state-search-component>
          </app-shimmer-loading>
          <div *ngIf="formGet.state.errors && formGet.state.touched">
            <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.state.errors.required">State is
              required</p>
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6">
        <label for="city" class="text-label-grey text-sm text-regular">City</label>
        <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('city')">
          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
            <app-city-search id="city" [stateCode]="formGet?.state?.value"
              [enableError]="formGet.city.errors && formGet.city.touched" [defaultSelected]="formGet?.city?.value"
              (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0"
              (focusout)="markAsTouchedIndividual('city')"></app-city-search>
          </app-shimmer-loading>
          <div *ngIf="formGet.city.errors && formGet.city.touched">
            <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.city.errors.required">City is
              required</p>
          </div>
        </div>
      </div>
      <div class=" col-span-12 md:col-span-6 ">
        <label for="zipcode" class=" text-label-grey text-sm text-regular ">Zip/Postal Code</label>
        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
          <input type="text" id="zipcode" (focusout)="markAsTouchedIndividual('zipcode')"
            class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
            placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
            name="zipcode" formControlName="zipcode" oninput="this.value = this.value.toUpperCase()"
            (focus)="validateZipCode()"
            [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
        </app-shimmer-loading>
        <div *ngIf="formGet.zipcode.errors && formGet.zipcode.touched">
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.zipcode.errors.pattern">Invalid
            zipcode</p>
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.zipcode.errors.required">Zipcode
            is required</p>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4 row-gap-4 mt-4">
      <div class="col-span-12">
        <label for="mail-id" class="text-label-grey text-sm text-regular">Your Email Address</label>
        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
          <input type="email" id="mail-id"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="e.g. marktylor@gmail.com"
            [ngClass]="formGet.email.errors && formGet.email.touched?'form-input-error':'form-input-focus'"
            formControlName="email" name="email">
        </app-shimmer-loading>
        <div *ngIf="formGet.email.errors && formGet.email.touched">
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.email.errors.pattern">Please enter
            a valid email address.</p>
        </div>
      </div>
      <div class="col-span-12">
        <label for="phone-no" class="text-label-grey text-sm text-regular">Your Phone Number</label>
        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
          <app-phone-input id="phone-no" [placeholder]="'e.g. 8665044754'" [phoneNumber]="basicDetailsForm.value.phone"
            [countryCode]="phoneCountryCode" [uniqueId]="'phone-basic-details'"
            (onChange)="detectChangePhonenumber($event)"
            [errorClassTrigger]="formGet.phone.errors && formGet.phone.touched" [form]="basicDetailsForm"
            (focusout)="formGet.phone.markAsTouched()">
          </app-phone-input>

        </app-shimmer-loading>
        <div *ngIf="formGet.phone.errors && formGet.phone.touched">
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.phone.errors.required">Phone
            number is required</p>
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.phone.errors.pattern">Please enter
            a valid phone number</p>
        </div>
      </div>
    </div>
    <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
      <!-- BUTTON: Disabled - opacity-50 -->
      <app-button-loader [buttonText]="'Save and Continue'" [valid]="basicDetailsForm.valid" [loader]="submitLoader"
        buttonType="'submit'"></app-button-loader>
    </div>
  </form>
</app-common-modal>
