import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * professional user service
 */
export class ProfessionalDashboardService {
  private readonly BASE_URL: string;
  public loginMode:any;
  public loginModeObserve :Observable<boolean>;
  public currentUser:any;
  public currentUserObserve :Observable<boolean>;
  public selectedClient:any;
  public selectedClientObserve :Observable<number>;

  /**
 * constructor
 */
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.BASE_URL;
    this.loginMode = new BehaviorSubject(false);
    this.loginModeObserve=this.loginMode.asObservable();
    this.currentUser=new BehaviorSubject({});
    this.currentUserObserve=this.currentUser.asObservable();
    this.selectedClient=new BehaviorSubject(null);
    this.selectedClientObserve=this.selectedClient.asObservable();
  }

  /**
   * get Agents
   *
   * @return {Observable<APIResponseModel>}
   */
  public getAgents():Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/professional/dashboard-agent-list');
  }
  /**
   * get clients
   * @return {Observable<APIResponseModel>}
   *
   */
  public getClients():Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/professional/dashboard-client-list');
  }
  /**
   * get access control for professional user
   * @param{string}requestId
   *  @return {Observable<APIResponseModel>}
   */
  public getAccessControlList(requestId:string):Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/professional-type-permission/${requestId}`);
  }


  /**
   * Save the {@link permissions} as the default Access Control permissions for new clients to the specified
   * {@link professionalID professional}.
   * @param professionalID
   * @param permissions
   */
  public saveDefaultAccessControlList(professionalID: string, permissions: {}): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/professional/permission-save`, permissions);
  }

  /**
   * Contains the {@link HttpClient.get http-get} call for the <code>/professional/permission/{professional_id}</code>
   * endpoint. Returns the {@link Observable}.
   * @param professionalID
   * @private
   */
  private getDefaultAccessControlListHTTP(professionalID: string):Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/professional/permission/${professionalID}`);
  }


  /**
   * Retrieve Default Access Control permissions for the specified {@link professionalID professional}.
   * @param professionalID
   */
  public getDefaultAccessControlList(professionalID: string): Observable<{}[]> {
    return new Observable<any>((observer) => {
      this.getDefaultAccessControlListHTTP(professionalID).subscribe({
        next: (res) => {
          if (res.status && res?.data?.permission) {
            console.log("advisor data", res);
            let permissions: {}[] = res.data.permission;

            // make number -> boolean
            permissions.forEach((e) =>
              ['add', 'edit', 'view', 'delete'].forEach((p) => e[p] = e[p] == 1)
            );

            // Sort permission names alphabetically for presentation.
            permissions.sort((a, b) => {
              if (a['permission_name'] > b['permission_name']) {
                return 1;
              } else if (a['permission_name'] < b['permission_name']) {
                return -1;
              } else {
                return 0;
              }
            });

            observer.next(permissions);
          }
        },
        error: (err) => observer.error(err.error),
        complete: () => observer.complete()
      });
      return {
        unsubscribe() {
        }
      }
    })
  }

  /**
   * get selected client
   * @param{number}index
   */
  public selectedClientInfo(index:number) {
    this.selectedClient.next(index);
  }
  public getScheduleTypes(){
    return this.http.get<APIResponseModel>(this.BASE_URL + `/professional/get-schedule-types`);
  }
  public getClientScheduleStatus(){
    return this.http.get<APIResponseModel>(this.BASE_URL + `/professional/get-professional-schedules`);
  }
  public getDashboardDetails(): Observable<APIResponseModel>{
    return this.http.get<APIResponseModel>(this.BASE_URL + `/professional/dashboard-details`);
  }
}
