import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';


/**
 * Currencys validator
 * @returns validator
 */
export function currencyValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    // Check if the value is empty
    const isEmpty = control.value === '' || control.value == null;
    if (isEmpty) {
      return null; // No validation error if the value is empty or not a number
    }

    // Check if contains invalid chars OR if valid chars comprise NaN
    const valueValidChars = control.value?.toString().replace(/[^\d.]+/g, '').replace(/\s/g, '');
    const valueInvalidChars = control.value?.toString().replace(/[0-9., ]+/g, ''); // Also ignore separator value and space
    const numericValue = parseFloat(valueValidChars);
    const isInvalid = valueInvalidChars !== '' || isNaN(numericValue);
    if (isInvalid) {
      return { invalidCurrency: true };
    }

    // Format the numeric value as currency without the currency symbol
    const formattedValue = numericValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'code', // Use 'code' to exclude the symbol
    });

    // Remove the "USD" string from the formatted value
    const formattedValueWithoutCurrency = formattedValue.replace('USD', '').trim();

    // Update the control's value with the formatted currency value.
    // Only update if different. Otherwise, inf recursion loop.
    if (formattedValueWithoutCurrency != control.value) {
      control.setValue(formattedValueWithoutCurrency, {emitEvent: false});
      control.updateValueAndValidity();
    }

    return null;
  };
}
