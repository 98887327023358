import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ToggleOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-advisor-info',
  templateUrl: './advisor-info.component.html',
})

/**
 * Advisor Info
 */
export class AdvisorInfoComponent implements OnInit {
  public PERMISSION_TYPE: {} = { permissionContact: 0, permissionBeneficiaries: 1, permissionAdvisorTerms: 2 };
  public proRoute: string = environment.PRO_ROUTE;
  public faCheckCircle: any;
  public faCircleXmark: any;
  public loading: boolean;
  public sectionSaveExitOptions: any;
  public clientRequestId: string;
  public userType: string;
  public userData: Array<Object>;
  public permissionAdvisorTerms: number;
  public permissionBeneficiaries: number;
  public permissionContact: number;
  public permissionInform: number = 0;
  public toggleOptions: Array<ToggleOptionsInterface>;
  public agentTerm: string;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public vaultVideo: string;

  /**
* constructor
*/
  constructor(
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private personalDetailsService: PersonalDetailsService,
    private peopleService: PeopleService,
    private router: Router,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    private modalService: CommonModelService,
  ) {
  }

  /**
* called initially
*/
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.loading = false;
    this.clientRequestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.faCheckCircle = faCheckCircle;
    this.faCircleXmark = faCircleXmark;
    this.userType = this.localStorageService.getDataByKey('role');
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.sectionSaveExitOptions = {
      section: 2,
      // type: 2,
    };
    this.toggleOptions = [
      {
        displayText: 'NO',
        value: 0,
      },
      {
        displayText: 'YES',
        value: 1,
      },
    ];
    this.getPersonalDetails();
  }

  /**
* Get personal details
*/
  public getPersonalDetails(): void {
    this.loading = true;
    // get people list
    this.personalDetailsService.getPersonalDetails(this.userType !== 'Consumer' ? this.clientRequestId : null).subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          this.permissionAdvisorTerms = response.data.grant_advisor_terms;
          this.permissionBeneficiaries = response.data.beneficiary_advisor_permission;
          this.permissionInform = response.data.inform_exec_permission;
          this.permissionContact = response.data.advisor_permission;
        }
      }, error: e => this.commonHelper.httpResponseHandler(e.error),
      complete: () => this.loading = false,
    });
  }


  /**
* On Change toggle
*
* @param {ToggleOptionsInterface} selectedValue
* @param term Which term to update, select using {@link this.PERMISSION_TYPE}
*/
  public onChangeToggle(selectedValue: ToggleOptionsInterface, term: number): void {
    if (!selectedValue) return;
    console.log(selectedValue, term);

    this.permissionAdvisorTerms =
      term == this.PERMISSION_TYPE['permissionAdvisorTerms'] ?
        selectedValue?.value : this.permissionAdvisorTerms;
    this.permissionBeneficiaries =
      term == this.PERMISSION_TYPE['permissionBeneficiaries'] ?
        selectedValue?.value : this.permissionBeneficiaries;
    this.permissionContact =
      term == this.PERMISSION_TYPE['permissionContact'] ?
        selectedValue?.value : this.permissionContact;

    let payLoad: {} = {
      grant_advisor_terms: this.permissionAdvisorTerms,
      beneficiary_advisor_permission: this.permissionBeneficiaries,
      advisor_permission: this.permissionContact,
      roletype: this.userType,
    };
    if (this.userType === 'Consumer') {
      payLoad = { ...payLoad, request_id: this.clientRequestId };
    };
    this.peopleService.authorizeAdvisor(payLoad).subscribe(() => {
    }, () => this.getPersonalDetails());
  }

  /**
* Accessor for CommonHelper for HTML template
*/
  get getCommonHelper(): CommonHelper {
    return this.commonHelper;
  }

  /**
* change route
* @param{string}url
*/
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, false, url === 'digital-vault-agreement' ? true : false);
  }

  /**
* Click handler
* @param command
*/
  public click(command: string) {
    const isPro = this.userType !== 'Consumer';
    switch (command) {
      case 'Back':
        const about_yourself_url = ['executor-beneficiary', 'executors-beneficiaries-view', isPro ? this.clientRequestId : ''];
        void this.slugInterceptorService.navigate(about_yourself_url);
        break;
      case 'Next':
        const next_url = ['advisors', 'manage-advisors', isPro ? this.clientRequestId : ''];
        void this.slugInterceptorService.navigate(next_url);
        break;
      case 'ReturnToDashboard':
        void this.slugInterceptorService.navigate(['dashboard'], null, isPro);
        break;
    }
  }

  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');


  }
}
