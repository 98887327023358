import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { LoadStateHelper } from 'src/app/helper/load-state.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PAYMENT_STATUS } from 'src/constants/application.const';
import { ConsumerPaymentsService } from './consumer-payment-section.service';
import { ConsumerSubscriptionService } from './consumer-subscription.service';


@Component({
  selector: 'app-consumer-payment-section',
  templateUrl: './consumer-payment-section.component.html',
  styleUrls: ['./consumer-payment-section.component.css'],
})
/**
 * consumer payment section class
 */
export class ConsumerPaymentSectionComponent implements OnInit {
  public hideDataTransfer = true;
  // For now, we will only have a monthly option.
  public chooseSubscriptionOption: boolean = false;
  public paymentItems: { title: string, price: any }[];
  public verbiage: { consumer: VerbiageModel, custodian: VerbiageModel };
  public requestId: string;
  public userType: string;
  public promoCode: string;
  public vaultTransferAmount: string;
  public monthlyPackage: any;
  public monthlyPackageDetails: number;
  public discountAmount: number;
  public yearlyPackage: any;
  public activePackage: any;
  public total: number = 0;
  public isTransferDigitalWallet: boolean = false;
  public showCardScreen = false;
  public isUpdatePlan = false;
  public dlbId: string;
  public userData: any;
  public submitLoader: boolean;
  public transferVaultScreen = false;
  public progressValue: number;
  public yearMask: any;
  public promoCodeApplied: boolean = false;
  public isLoading = false;
  public updateSubscriptionSubscription: Subscription;
  public transferVaultScreenSubscription: Subscription;
  public showpaymentScreenSubscription: Subscription;
  public dataLoader: boolean;
  public appliedPromoId: string;
  public paymentCompleted: any;
  public paymentStatusConst = PAYMENT_STATUS;
  public isChecked: boolean;
  public isVaultTransferPaid: boolean;
  loader: LoadStateHelper;

  /**
   * constructor
   */
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private subscriptionService: ConsumerSubscriptionService,
    private localStorageService: LocalStorageService,
    private paymentService: ConsumerPaymentsService,
    private slugInterceptorService: SlugInterceptorService,
    private loadTracker: LoadStateHelper,
  ) {
    this.loader = loadTracker;
  }

  /**
   *on init
   */
  ngOnInit(): void {
    this.loader.set('subscriptionService');
    this.subscriptionService.getPaymentStatus().subscribe((response) => {
      this.paymentCompleted = response?.data?.is_payment_complete;
      this.isVaultTransferPaid = response?.data?.is_transfer_amount_paid == 1;
      this.localStorageService.storeData('is_payment_complete', this.paymentCompleted);
      this.localStorageService.storeData('is_transfer_amount_paid', this.isVaultTransferPaid);
      if (this.paymentCompleted == this.paymentStatusConst.paid) {
        void this.slugInterceptorService.navigate(['dashboard']);
      }
    });
    // this.paymentCompleted = this.localStorageService.getDataByKey('is_payment_complete');
    this.promoCode = '';
    this.appliedPromoId = '';

    this.verbiage = {
      consumer: {
        sub_header: 'Select your plan to get access to your LegacyNOW Digital Vault.',
        vault_subscription:
          `A subscription fee is required to access your LegacyNOW Digital Vault and maintain your data.
          This will be a reoccurring monthly payment which can be cancelled at any time.`,
        vault_transfer:
          `This is a one-time service fee so that your executor, upon your passing, can utilize NotifyNOW to
          expedite the process of notifying companies of your passing securely and efficiently. This service is optional,
          and you can pay now or later. If you choose not to pay for this option, your executor will also have the option of
          paying for this service themselves once they've completed Proof of Death.`,
        ln_dv_id: 'Your LegacyNOW Digital Vault ID',
      },
      custodian: {
        sub_header: 'Please note that Vault Owner has not made any payment. To gain access to their LegacyNOW Digital Vault, payments need to be made.',
        vault_subscription:
          `If you wish to keep your loved one's LegacyNOW Digital Vault active, a subscription fee wil required to access and maintain their data.
          This will be a reoccurring monthly payment which can be cancelled at any time.`,
        vault_transfer:
          `This is a one-time service fee to enabled a data transfer to NotifyNOW. This is a service you can utilize to expedite
          the process of notifying companies of your passing securely and efficiently. This service is entirely optional.`,
        ln_dv_id: 'User\'s LegacyNOW Digital Vault ID',
      },
    };
    this.userType = this.localStorageService.getDataByKey('role');
    this.userData = this.localStorageService.getUserData();
    this.getSubscriptionList();
    this.navigateToDigitalVaultComponent();
    // get user request id for executor/custodian login
    this.requestId = this.localStorageService.getDataByKey('user_request_id');
    if (this.requestId) {
      this.hideDataTransfer = false;
    }
    this.showpaymentScreenSubscription = this.paymentService.showPaymentScreenObserve.subscribe((response: boolean) => {
      if (response) {
        this.showCardScreen = true;
        this.loader.set('subscriptionService', false);
      } else {
        setTimeout(() => {
          this.loader.set('subscriptionService', false);
        }, 500);
      }
    });
    this.isTransferDigitalWallet = (!!this.requestId && this.userType === 'Custodian');
    this.updatePricing();

    // to open page from top
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // scroll to top
        window.scrollTo(0, 0);
      }
    });
  }

  /**
   * get subscription list
   */
  public getSubscriptionList() {
    this.dataLoader = true;
    this.subscriptionService.getSubscriptionDetailsApi(this.userType !== 'Consumer' ? this.requestId : undefined).subscribe({
      next: (response: any) => {
        const data = response.data;
        this.dataLoader = false;
        this.vaultTransferAmount = data.vault_transfer_amount;
        this.dlbId = data.request_unique_id;
        this.monthlyPackage = data.package.find((e) => e.name == 'Monthly');
        this.monthlyPackageDetails = data?.subscription_price;
        this.yearlyPackage = data.package.find((e) => e.name == 'Yearly');
        this.activePackage = this.monthlyPackage;
        this.updatePricing();
      },
      error: (e) => {
        console.error(e);
        this.dataLoader = false;
      },
    },
    );
  }

  /**
   * toggle active package
   */
  public toggleActivePackage() {
    this.activePackage = this.activePackage == this.monthlyPackage ? this.yearlyPackage : this.monthlyPackage;
    this.updatePricing();
  }

  /**
   * Update {@link paymentItems} by adding all items the user currently has to pay for.<br>
   * Update {@link total} by summing the values of each element <code>e</code> of {@link paymentItems} <code>e.price</code>.
   * @private
   */
  private updatePricing() {
    this.paymentItems = [];
    if (this.userType !== 'Custodian') {
      this.paymentItems.push({ title: 'Digital Vault Subscription', price: this.monthlyPackageDetails });
    } else if (this.isTransferDigitalWallet) {
      this.paymentItems.push({ title: 'Vault Transfer Fee', price: this.vaultTransferAmount });
    }
    this.total = 0;
    this.paymentItems.forEach((e) => (this.total += parseFloat(e.price)));

    if (this.promoCodeApplied && this.userType !== 'Custodian') {
      this.total = this.total - this.discountAmount;
    }
  }

  /**
   * toggle digital wallet
   */
  public toggleDigitalWallet() {
    this.isTransferDigitalWallet = !this.isTransferDigitalWallet;
    this.updatePricing();
  }

  /**
   * enable card screen
   */
  public toCheckoutScreen() {
    this.loader.set('subscriptionService');
    // this.showCardScreen = true;
    setTimeout(() => {
      this.loader.set('subscriptionService', false);
    }, 500);
    void this.slugInterceptorService.navigate(['checkout']);
  }

  /**
   * disable card screen
   */
  public disableCardScreen() {
    this.showCardScreen = false;
    this.isUpdatePlan = false;
    void this.slugInterceptorService.navigate(['subscription-plan']);
  }

  /**
   * on destroy
   */
  public ngOnDestroy(): void {
    this.showpaymentScreenSubscription?.unsubscribe();
  }

  /**
   *
   */
  navigateToDigitalVaultComponent(): void {
    const storedValue = this.localStorageService.getDataByKey('DigitalVaultAgreementChecked');
    console.log('Stored Value:', storedValue);
    this.isChecked = storedValue === true || storedValue === 'true';
    if (!this.isChecked) {
      void this.router.navigate(['/digital-vault']);
    }
  }

  /**
   *
   */
  public checkPromoCode() {
    this.subscriptionService.checkPromoCode(this.promoCode, []).subscribe({
      next: (response) => {
        if (response.data.valid_promo_code) {
          this.toastr.success('Promo Code Applied Successfully');
          this.promoCodeApplied = true;
          this.promoCode = '';
          this.discountAmount = response.data.coupon_price;
          this.appliedPromoId = response.data.promo_id;
          this.updatePricing();
        }
        else {
          this.toastr.error(response.data?.error_message);
          this.promoCode = '';
          this.promoCodeApplied = false;
        }
      },
      error: (error) => {
        console.error('Error applying promo code:', error);
      }
    });

  }

  /**
   *
   */
  public removePromoCode() {
    this.promoCode = '';
    this.promoCodeApplied = false;
    this.appliedPromoId = '';
    this.discountAmount = 0;
    this.updatePricing();
  }

  /**
   * Returns a {@link VerbiageModel} based on {@link userType} from {@link verbiage}.
   */
  get getVerbiage(): VerbiageModel {
    return this.verbiage[this.userType.toLowerCase()];
  }
}


interface VerbiageModel {
  sub_header: string;
  vault_subscription: string;
  vault_transfer: string;
  ln_dv_id: string;
}
