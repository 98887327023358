import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonModelService } from 'src/app/services/common-model.service';
import { DEMO_VIDEOS } from 'src/constants/demo-videos.const';


@Component({
  selector: 'app-demo-video-modal',
  templateUrl: './demo-video-modal.component.html',
  styleUrls: ['./demo-video-modal.component.css'],
})
export class DemoVideoModalComponent {
  @ViewChild('videoElementRef') set videoElementChild(e: ElementRef) {
    if (e) {
      this.videoElement = e.nativeElement as HTMLVideoElement;
    }
  };


  @Input() modal_id: string = 'view-demo-video-modal';
  @Input() vaultVideo: string = '';
  @Input() userLink:boolean = true;
  private videoElement: HTMLVideoElement;
  public isOpen: boolean;
  public readonly urlLibrary = DEMO_VIDEOS;

  constructor(private modalService: CommonModelService,private sanitizer: DomSanitizer) {
  }


  public closeModal() {
    this.modalService.close(this.modal_id);
    this.modalService.setModalVideo(null);
    this.videoElement = document.getElementById(this.vaultVideo) as HTMLVideoElement;
    this.stop();
  }


  modalListener($event) {
    this.isOpen = $event.action == 'open';
    if (this.isOpen) {
      this.play();
    }
  }


  private play() {
    // Interval to wait until the videoElement is not `undefined` (need dom to update)
    const interval = setInterval(() => {
      if (this.videoElement) {
        void this.videoElement?.play();
        clearInterval(interval);
      }
    }, 100);
  }


  private stop() {
    if (this.videoElement) {
      this.videoElement?.pause();
      this.videoElement.currentTime = 0;
    }
  }

  /**
   * Generates a SafeResourceUrl for the current vaultVideo.
   * 
   * @returns SafeResourceUrl
   */
  getSafeUrl(): SafeResourceUrl {
    if(this.userLink){
    const url = `${this.urlLibrary[this.vaultVideo]}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }else{
      return this.urlLibrary[this.vaultVideo]
    }
  }
  
}
