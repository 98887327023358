import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { FileValidationOptionsInterface } from 'src/app/interface/common.interface';
import { ClientsService } from 'src/app/professional-dashboard/services/clients.service';
import { ProSocketService } from 'src/app/professional-dashboard/services/pro-socket.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { DigitalFilesService } from 'src/app/services/digital-files.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ONBOARD_TYPES, OnboardType, PROFESSIONAL_ROLE } from 'src/constants/application.const';
import { CLEAR_SELECTED_FILE } from 'src/constants/digitalFile.const';

@Component({
  selector: 'app-client-bulk-import',
  templateUrl: './client-bulk-import.component.html',
  styleUrls: ['./client-bulk-import.component.css'],
})

/**
 * Bulk Import Client Modal
 */
export class ClientBulkImportComponent implements OnInit {
  @Input() importTerm: string;
  @Output() responseEmitter = new EventEmitter<any>();
  @Input() userRole: string = '';
  public importClientBulkForm: FormGroup;
  public submitLoader: boolean;
  public preDefinedLoader: boolean;
  public activateFileUploadLoader: boolean;
  public fileValidationOptions: FileValidationOptionsInterface;
  public filePercentage: number;
  public file: File;
  public imageFormatFiltering: string[];
  public checked = false;
  public userId: string = '';
  public role: string = '';
  public bulkImportCall: Observable<any>;
  public asterisk: any;
  public readonly PROFESSIONAL_ROLE = PROFESSIONAL_ROLE;

  public selectedOnboardType: number;
  public readonly onboardTypes = ONBOARD_TYPES;


  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private professionalClientService: ClientsService,
    public commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private proSocketService: ProSocketService,
    private digitalFilesService: DigitalFilesService,
    private commonService: CommonService,
  ) {
    this.role = localStorageService.getDataByKey('role');
    this.userId =
      this.role === 'Sub Organization'
        ? localStorageService.getDataByKey('organization')?.['user'].id
        : localStorageService.getDataByKey('professional-user')?.user.id;
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.asterisk = faAsterisk;
    this.selectedOnboardType = OnboardType.VIA_EMAIL;
    this.fileValidationOptions = {
      size: 100000,
      fileFormat: ['vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'xlsx', 'csv'],
    };
    this.imageFormatFiltering = ['jpeg', 'jpg', 'png'];
    this.importClientBulkForm = this.formBuilder.group({
      document: ['', { updateOn: 'blur', validators: [Validators.required] }],
      onboard_via_type: [this.selectedOnboardType, { updateOn: 'blur', validators: [this.importTerm === 'employee' ? Validators.required : Validators.nullValidator] }],
      // ignore_first_row: ['0'],
    });

    this.proSocketService.bulkImportStatusListener().subscribe((response) => {
      if (!!response && response?.data?.id === this.userId) {
        setTimeout(() => {
          this.commonHelper.toastrFileUploadSuccess();
          this.proSocketService.bulkImportCompleted$.next(null);
        }, 1000);
      }
    });
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.importClientBulkForm.controls;
  }

  /**
   * file getter
   * @param{File}file
   */
  public getFileData(file: File) {
    this.file = file;
    this.importClientBulkForm.patchValue({
      document: this.file,
    });
  }

  /**
   * on submit
   */
  public onSubmit(): void {
    this.submitLoader = true;
    this.activateFileUploadLoader = true;
    const payload = {
      file: this.importClientBulkForm.value.document,
      onboard_via_type: this.importTerm === 'employee' ? this.importClientBulkForm.value.onboard_via_type: null,
      ignoreFirstRow: 1,
    };
    const formData = new FormData();
    // appending every value to form object
    Object.keys(payload).forEach((key) => {
      if (!payload[key]) return;
      formData.append(key, payload[key]);
    });
    switch (this.importTerm) {
      case 'client':
        this.bulkImportCall = this.digitalFilesService.storeClientBulkImport(formData);
        break;
      case 'employee':
        if (this.userRole === PROFESSIONAL_ROLE.professional) {
          this.bulkImportCall = this.digitalFilesService.storeEmployeeBulkImportProfessional(formData);
        } else if (this.userRole === PROFESSIONAL_ROLE.agent || this.userRole === PROFESSIONAL_ROLE.manager) {
          this.bulkImportCall = this.digitalFilesService.storeEmployeeBulkImportProfessional(formData);
        } else {
          this.bulkImportCall = this.digitalFilesService.storeEmployeeBulkImportOrganization(formData);
        }
        break;
      case 'manager':
        break;
    }

    this.bulkImportCall.subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.filePercentage = Math.round((100 * event.loaded) / event.total);
        }
        if (event.type === HttpEventType.Response) {
          this.toastr.success('File uploaded successfully! It may take 2-3 minutes to load the data. Please refresh the page to see the updated client import data.');

          this.responseEmitter.emit(true);
          this.activateFileUploadLoader = false;
          this.commonService.fileHanlderObservableEvents({ type: CLEAR_SELECTED_FILE });
          this.commonHelper.updateLocalstorageRequestStage(event.body.data);
          this.resetForm();
          this.modalService.close('bulk-import-client-modal');
          this.submitLoader = false;
          // this.checked = false;
        }
      },
      (exception) => {
        this.activateFileUploadLoader = false;
        this.submitLoader = false;
        this.responseEmitter.emit(false);
        // this.toastService.error('Error uploading file');
        this.commonService.fileHanlderObservableEvents({ type: CLEAR_SELECTED_FILE });
        this.commonHelper.httpResponseHandler(exception?.error);
      },
    );
  }

  /**
   * Returns the appropriate button text based on the import term and selected onboard type.
   *
   * @returns {string} The text to be displayed on the button.
   */
  public getButtonText() {
    if (this.importTerm === 'manager') {
      return this.commonHelper?.capitalizeFirstLetter(this.importTerm)
    } else if (this.importTerm === 'employee') {
      return 'Submit'
    } else {
      return 'Import Client'
    }
  }

  /**
   * close modal
   */
  public closeModal(): void {
    this.modalService.close('bulk-import-client-modal');
    this.resetForm();
  }

  /**
   * reset form
   */
  public resetForm() {
    this.selectedOnboardType = OnboardType.VIA_EMAIL;
    this.importClientBulkForm.reset({
      document: '',
      onboard_via_type:this.selectedOnboardType
      // ignore_first_row: '0',
    });
    this.commonService.fileHanlderObservableEvents({ type: CLEAR_SELECTED_FILE });
    this.importClientBulkForm.markAsUntouched();
  }

  /**
   * have first row of bulk import file - check/not check
   *
   * @param {any} data
   */
  public onCheck(data: any) {
    if (data?.target?.checked) {
      this.checked = true;
      this.importClientBulkForm.patchValue({
        ignore_first_row: '1',
      });
    } else {
      this.checked = false;
      this.importClientBulkForm.patchValue({
        ignore_first_row: '0',
      });
    }
  }
  /**
   * Handles the selection of an onboarding type
   *
   * @param {Event} data - The event object triggered by the selection action.
   */
  public onSelectOnboardType(data) {
    this.selectedOnboardType = Number(data?.target?.value)
    this.importClientBulkForm.patchValue({
      onboard_via_type:this.selectedOnboardType ,
    });
  }

  /**
   * selected checked
   * @param {number} data
   * @return{boolean}
   */
  public selectedChecked(field, data) {
    switch (field) {
      case 'onboard':
        return this.selectedOnboardType === data;
    }
  }
}
