<app-common-modal id="agent-appointment-modal" class="app-common-modal">
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
         (click)="closeModal()">
    <div class="mb-4">
      <div>
        <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
          Appointment with your {{agentTerm}}
        </h4>
        <p class="text-grey text-regular pt-1 text-sm">
          Start by adding your Appointment.
        </p>
      </div>
    </div>
    <form [formGroup]="appointmentForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
        <div class="col-span-12 relative" (click)="markAgentType()">
          <label for="advisor" class="text-label-grey text-sm text-regular">{{agentTerm}}*</label>
          <app-select-with-search id="advisor" [options]="advisorArray" [placeholderValue]="'Select ' + agentTerm"
                                  (optionChangeEmitter)="optionChangeEmitterAgent($event)"
                                  [ifError]="formGet.agent_id.errors && formGet.agent_id.touched" tabindex="0"
                                  (focusout)="markAgentType()"
                                  [useTitleCaseFormatting]="false">
          </app-select-with-search>
          <div *ngIf="formGet.agent_id.errors && formGet.agent_id.touched">
            <p class="text-regular font-medium text-xs mt-2 error-alert">
              {{agentTerm}} is required
            </p>
          </div>
        </div>
        <div class="col-span-12">
          <label [for]="'appointmentDate'" class="text-label-grey text-sm text-regular">Date of
            Appointment*</label>
          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
            <app-datepicker-toggle
              inputID="appointmentDate"
              class="mt-1"
              placeholder="{{domainDateFormat}}, HH:MM:AA"
              [bsConfig]="bsConfig"
              [useFormControl]="formGet.appointmentDate">
            </app-datepicker-toggle>
            <!--input type="text" id="appointmentDate" bsDatepicker placement="right"
                   [bsConfig]="bsConfig" readonly
                   name="appointmentDate" formControlName="appointmentDate"
                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                   placeholder="{{domainDateFormat}}, HH:MM:AA" [ngClass]="formGet.appointmentDate.errors && formGet.appointmentDate.touched ? 'form-input-error' :
            'form-input-focus'"-->
          </app-shimmer-loading>

          <div *ngIf="formGet.appointmentDate.errors && formGet.appointmentDate.touched">
            <p class="text-regular font-medium text-xs mt-2 error-alert"
               *ngIf="formGet.appointmentDate.errors?.['required']">
              Date and Time is required
            </p>
            <p class="text-regular font-medium text-xs mt-2 error-alert"
               *ngIf="formGet.appointmentDate.errors?.['invalid_time']">
              Please select a future date and time that is 2 hours ahead from now
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
        <app-button-loader [buttonText]="'Book Appointment'" [loader]="submitLoader"
                           [buttonType]="'submit'"></app-button-loader>
      </div>
    </form>
  </div>
</app-common-modal>
