<app-common-modal id="file-download-modal" class="app-common-modal">
  <div class="relative">

    <div class="relative flex items-center gap-4 w-full">
      <div class="w-full">
        <h4 class="text-regular text-lg text-darkgrey font-semibold tracking-wide break-words">
          Download {{fileName}}? </h4>
        <p class="text-darkgrey text-regular text-base pt-2">( After clicking the download button, you will be
          redirected to a
          new page, right click and save your file )</p>
      </div>
      <div class="w-12">
        <img src="assets/images/about-yourself/close.svg" class="cursor-pointer absolute -top-2 -right-2 w-8 z-9999" alt=""
          (click)="closeModal()" />
      </div>
    </div>
    <div class="relative">
      <button type="button" (click)="downloadFile()"
        class="button button--lg mt-5 w-full bg-darkblue rounded-xs p-5 focus:outline-none text-regular text-white px-6 text-base tracking-wide">
        Download
      </button>
    </div>
  </div>
</app-common-modal>