<!-- Check if the user does not have a paid subscription -->
<ng-container *ngIf="!hasPaidSubscription && hasPaidSubscription!=undefined && !isProfessional">
  <!-- Display this message if the user is not an existing user -->
  <p *ngIf="!isExistingUser" class="text-lg text-regular tracking-wide text-red">
    Please choose your subscription plan and complete <em class="underline cursor-pointer text-semibold"
      (click)='proceedToPayment()'>payment</em> to proceed.
  </p>
  <!-- Display this message if the user is an existing user -->
  <p *ngIf="isExistingUser" class="text-lg text-regular tracking-wide text-red">
    This section is currently view only. Please complete the <em class="underline cursor-pointer text-semibold"
      (click)='proceedToPayment()'>payment</em> to
    proceed or edit.
  </p>
</ng-container>


<!-- Check if the user is professional & the client does not have a paid subscription -->
<ng-container *ngIf="isProfessional && !clientHasPaidSubscription && clientHasPaidSubscription!=undefined ">
  <p class="text-lg text-regular tracking-wide text-red">
    This Vault Holder has not yet completed their subscription payment. Please ask them to complete the payment.
  </p>
</ng-container>