<div class="flex justify-center loader " *ngIf="spinner">
  <div class="w-16 h-16  border-b-2 border-red rounded-full animate-spin mt-3 "></div>
</div>
<div class="scroll-hidden" *ngIf="!spinner">
  <div class="h-screen lg:grid grid-cols-12">
    <!-- BEGIN: Login Form -->
    <div class="col-span-12 md:col-span-6 xl:col-span-5 h-screen lg:h-auto p-6 flex">
      <div class="flex flex-col w-full">
        <!-- BEGIN: Logo -->
        <div class="md:pl-2 flex items-center gap-2 sm:gap-4 h-16 flex-row">
          <a (click)="clickOnLogo('')" class="flex items-center cursor-pointer">
            <img alt="The-Estate-Registry" width="250" [src]="'assets/images/logo-old.png'" class="object-contain w-40 sm:w-48">
          </a>
          <a *ngIf="orgLogoPath"  class=" border-l-black pl-6 cursor-pointer max-h-12 maxw128">
            <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
          </a>
          <!-- <div class="flex items-start gap-1 md:gap-2 flex-col" *ngIf="orgLogoPath">
            <p class="whitespace-nowrap text-xs md:text-sm lg:text-regular text-dark-grey">Sponsored by:</p>

            <a *ngIf="orgLogoPath"  class="cursor-pointer max-h-12 maxw128">
              <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">

            </a>
          </div> -->
        </div>
        <!-- END: Logo -->
        <div class="h-full flex items-center justify-center mt-4">
          <div class="my-auto mx-auto bg-white pt-8 sm:pl-10 xl:bg-transparent px-5 sm:px-8 w-full">
            <div class="h-full justify-center items-center ">
              <h2 class="text-darkblue text-4xl text-semibold pt-1">
                LegacyNOW
                <br> <small>Sign In</small>
              </h2>
              <div class="mt-4">
                <app-bubble-radio [options]="loginOptions" [selectedIndex]="selectedLoginOption.index"
                  (onClick)="onLoginOptionClick($event)">
                </app-bubble-radio>
              </div>
              <form [formGroup]="loginForm" autocomplete="off" (ngSubmit)="loginUser(captchaRef)">
                <div class="mt-6">
                  <div class="text-regular green-tick mb-4" *ngIf="verificationEmail != null">
                    We have sent a link to your email, please verify your email to continue on the site.
                  </div>
                  <div>
                    <label for="new-email" class="text-sm pb-2 text-regular text-grey">Email
                      Address</label>
                    <input type="email" name="new-email" formControlName="email" id="new-email"
                      class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                      placeholder="Enter your Email address" maxlength="50"
                      [ngClass]="formGet.email.errors && formGet.email.touched?'form-input-error':'form-input-focus'"
                      (focusout)="onFocusout($event,'email')" (input)="onChange($event,'email')">
                    <div *ngIf="formGet.email.errors && formGet.email.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.email.errors.pattern">
                        Please enter a valid email address.</p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.email.errors.required && formGet.email.touched">
                        Email address is required.</p>
                    </div>
                  </div>

                  <div class="mt-4">
                    <label for="new-password" class="text-sm pb-2 text-regular text-grey">
                      Password</label>
                    <div class="relative h-12 text-grey">
                      <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
                        (click)="togglePasswordView = !togglePasswordView">
                        <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
                        <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
                      </a>
                      <input [type]="togglePasswordView?'text':'password'" name="new-password"
                        formControlName="password" id="new-password"
                        class="input py-3 px-4 mt-1 w-full text-regular text-grey rounded-xs h-12 appearance-none pl-4 border block focus:outline-none"
                        placeholder="Enter Password" (input)="onChange($event,'password')"
                        (focusout)="onFocusout($event,'password')"
                        [ngClass]="formGet.password.errors && formGet.password.touched?'form-input-error':'form-input-focus'">
                      <div *ngIf="formGet.password.errors && formGet.password.touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.password.errors.required && formGet.password.touched">Password is required</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex text-grey text-xs sm:text-sm"
                  [ngClass]="(formGet.password.errors && formGet.password.touched)?'mt-8':'mt-4'">
                  <a class="cursor-pointer" (click)="onClick('forgot-password')">Forgot Password?</a>
                  <a class="ml-auto cursor-pointer" (click)="onClick('reset-verification')">Resend Verification
                    Email</a>
                </div>

                <!-- BEGIN: Recaptcha -->
                <div class="mt-8">
                  <div class="recaptcha h-20">
                    <re-captcha #captchaRef="reCaptcha" (resolved)="resolvedCaptcha($event)"
                      (error)="onErrorCaptcha($event)" errorMode="handled" [siteKey]="googleCaptchaKey"></re-captcha>
                  </div>
                  <div *ngIf="isRecaptchaValidationEnable">
                    <p *ngIf="!isCaptchaValid" class="text-regular font-medium text-xs error-alert">GOOGLE
                      Recaptcha is required</p>
                  </div>
                </div>
                <!-- END: Recaptcha -->
                <!-- BEGIN: Form Register Button -->
                <div class="sm:mt-6 sm:flex sm:flex-row text-center flex flex-col-reverse gap-4 items-center">
                  <p *ngIf="selectedLogin.id == LoginType.DIGITAL_VAULT && hasNotSlugDetails"
                    class="text-grey text-regular text-xs rounded p-2 px-4 border-darkgrey w-full sm:w-auto">Don’t
                    have an account?
                    <br>
                    <a (click)="onClick('register')"
                      class="text-darkblue font-medium underline cursor-pointer">Register</a>
                  </p>
                  <div class="ml-auto mt-4 md:mt-0 w-full sm:w-auto">
                    <button type="submit"
                      class="flex items-center justify-center button button--lg w-full bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-4 cursor-pointer">
                      <div class="loading-red mr-2" *ngIf="loginLoader"></div>
                      <div class="text-sm font-medium">Sign In ({{selectedLogin.text}})</div>
                    </button>
                  </div>
                </div>
                <!-- END: Form Register Button -->
              </form>
              <p class="text-regular text-grey text-center py-6 text-xs">
                By creating an account you agree to our
                <a (click)="onClick('privacy-policy')"
                  class="text-regular font-semibold text-darkblue underline cursor-pointer">
                  Privacy Policy
                </a>
                and
                <a (click)="onClick('user-agreement')"
                  class="text-regular font-semibold text-darkblue underline cursor-pointer">
                  User Agreement
                </a>
                .Please note that these documents contain important information regarding the terms and conditions under
                which we provide our Digital Vault Services
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- END: Login Form -->

    <!-- BEGIN: Login Info -->
    <div class="md:col-span-6 xl:col-span-7 hidden lg:flex bg-login-grey min-h-screen justify-center">
      <div class="fixed h-full flex flex-col items-center justify-center">
        <!-- BEGIN: First Slide -->
        <app-right-carousel-intro></app-right-carousel-intro>
      </div>
    </div>
    <!-- END: Login Info -->
  </div>
</div>
<app-common-modal id="user-logged-in" class="app-common-modal">
  <h2 class="text-xl lg:text-2xl font-sans font-medium highlighted-text truncate mr-5">
    You are already logged in
  </h2>
  <div class="flex flex-row items-center">
    <p class="font-sans text-lg mt-2">
      Redirecting to Dashboard
    </p>
    <div class="loading-red ml-2 mt-2"></div>
  </div>
</app-common-modal>

<app-resend-verification-modal [selectedLogin]="selectedLogin" [modalId]="'resend-verification-modal'" (emitResendVerificationEvent)="verificationModalListener($event)">
</app-resend-verification-modal>


<app-message-modal [setMessageData]="messageData" [setMessageSettings]="{showExit: false}"
  (buttonEmitter)="messageModalListener($event)">
</app-message-modal>
