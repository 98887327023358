import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModelService } from '../../services/common-model.service';

/**
 * Interface for {@link MessageModalComponent} message data. <br>
 * {@link MessageModalDataInterface.title title} <br>
 * {@link MessageModalDataInterface.message message} <br>
 * {@link MessageModalDataInterface.buttons buttons}
 */
export interface MessageModalDataInterface {
  title?: { content: string, class?: string } | string;
  message?: Array<{ content: string, class?: string } | string>;
  buttons?: Array<{ content: string, emitValue: any, class?: string }>;
}

/**
 *  Settings for the MessageModalComponent. See {@link messageSettingsDefaults here} for default settings.
 * {@link MessageModalSettingsInterface.titleClass titleClass} <br>
 * {@link MessageModalSettingsInterface.messageClass messageClass} <br>
 * {@link MessageModalSettingsInterface.buttonClass buttonClass} <br>
 * {@link MessageModalSettingsInterface.buttonAlignment buttonAlignment} <br>
 * {@link MessageModalSettingsInterface.showExit showExit} <br>
 */
export interface MessageModalSettingsInterface {
  /** Shared css for the Title **/
  titleClass?: string,
  /** Shared css for messages **/
  messageClass?: string,
  /** Shared css for buttons **/
  buttonClass?: string,
  /** Alignment of the buttons **/
  buttonAlignment?: 'start' | 'end' | 'center',
  /** Whether to show the 'exit' icon in the top-right **/
  showExit?: boolean,
  /** Modal Class  */
  modalClass?:string
}


@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
})

/**
 * A generalized pop-up modal for presenting a message with any number of custom buttons
 */
export class MessageModalComponent implements OnInit {
  @Input() id: string = 'message-modal';
  @Input() loader: boolean;

  /** EventEmitter for messageData.buttons.emitValue **/
  @Output() buttonEmitter = new EventEmitter();

  readonly MMButtonAlignment = {
    start: 'justify-start',
    end: 'justify-end',
    center: 'justify-center',
  };

  public messageSettingsDefaults: MessageModalSettingsInterface = {
    titleClass: 'text-regular text-2xl font-semibold tracking-wide pb-2 text-darkblue',
    messageClass: 'text-grey text-regular py-1',
    buttonClass: 'tracking-wide hover:underline-text text-base w-full sm:w-auto bg-darkblue rounded-xs h-auto focus:outline-none text-regular text-white px-4 py-2',
    buttonAlignment: 'end',
    showExit: true,
  };

  /**
   * setter {MessageModalSettingsInterface}settings
   */
  @Input() set setMessageSettings(settings: MessageModalSettingsInterface) {
    this.initSettings(settings);
  }

  /**
   * Handles convenience/backwards-compatibility operations to convert title & message to desired objects if sent as strings.
   * @param data
   */
  @Input() set setMessageData(data: MessageModalDataInterface) {
    // Title function
    if (typeof data?.title === 'string') {
      data.title = { content: data.title };
    }

    // Message function
    data?.message?.forEach((e, i) => {
      if (typeof e === 'string') {
        data.message[i] = { content: e };
      }
    });

    this.messageData = data;
  }

  /**
   * Message Display data
   */
  public messageData: MessageModalDataInterface;

  /**
   * Message Settings data
   */
  public messageSettings: MessageModalSettingsInterface;


  /**
   *
   * constructor
   */
  constructor(private modalService: CommonModelService) {
  }


  /**
   * loaded on initialization
   */
  ngOnInit() {
    if (!this.messageSettings) {
      this.initSettings();
    }
  }

  /**
   * On Click event emitter
   * @param value
   */
  public click(value: any) {
    this.buttonEmitter.emit(value);
  }

  /**
   * Closes the modal identified by the provided ID and triggers a click event.
   * @param value - The value to be emitted with the click event.
   */
  public closeModel(value: any) {
    // Close the modal using the modal service and the specified ID
    this.modalService.close(this.id);
    // Emit a click event with the provided value
    this.click(value)
  }

  /**
   * Initializes settings using {@link messageSettingsDefaults} for undefined keys in {@link settings}.
   * @param settings
   * @private
   */
  private initSettings(settings?: MessageModalSettingsInterface) {
    if (!settings) {
      settings = this.messageSettingsDefaults;
    } else {
      // Assign Defaults to missing keys
      for (const key in this.messageSettingsDefaults) {
        settings[key] = settings[key] ?? this.messageSettingsDefaults[key];
      }
    }
    this.messageSettings = settings;
  }
}
