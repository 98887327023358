import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * clients service
 */
export class ClientsService {
  private BASE_URL: string;

  private typeModalSource: any;
  public typeModalObserve: Observable<boolean>;
  private emitChangeSource: any;
  public changeEmitted: Observable<boolean>;
  /**
 * constructor
 */
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.BASE_URL;
    this.typeModalSource = new BehaviorSubject({});
    this.typeModalObserve = this.typeModalSource.asObservable();
    this.emitChangeSource = new BehaviorSubject({});
    this.changeEmitted = this.emitChangeSource.asObservable();
  }
  /**
   * Called after the business organization type is added/edited successfully
   */
  public triggerAfterTypeAdd(): void {
    this.typeModalSource.next(true);
  }
  /**
   *
   * emit changes
   * @param {any} change
   */
  public emitChange(change: any) {
    this.emitChangeSource.next(change);
  }
  /**
   * @param {any} data
   * @return {Observable}
   * add business organization type
   */
  public addClient(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/client-save`, data);
  }

  /**
   * Generates an onboarding link
   * @param data The data required to generate the onboarding link.
   * @returns An Observable
   */
  public generateOnboardLink(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/generate-url`);
  }

  /**
   * Import generate url
   * @param file 
   * @returns generate url 
   */
  public importGenerateUrl(file: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/import-generate-url`, file);
  }
  /**
   * @param {any} data
   * @return {Observable}
   * import client
   */
  public importClient(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/client-import`, data);
  }
  /**
   * @param {any} data
   * @return {Observable}
   * view business organization type
   */
  public viewAgent(data: any): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent/${data}`);
  }
  /**
   * @param {any} data
   * @return {Observable}
   * edit business organization type
   */
  public editAgent(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/agent-update`, data);
  }


  /**
   * @param {any} data
   * @return {Observable}
   * delete business organization type
   */
  public deleteAgent(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/agent/${data}`, null);
  }
  /**
   * @param {any} data
   * @return {Observable}
   * delete client
   */
  public deleteClient(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/client-unlink`, data);
  }
  /**
   * Get all business organization types for listing
   *
   *
   * @return {Observable}
   */
  public getAllClients(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/client-list`);
  }
  /**
   * search for clients
   * @param{string}search
   *
   * @return {Observable}
   */
  public getClientsPerSearch(search?: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/client-list-paginate?search=${search}`);
  }
  /**
     *  list for agents
     * @param{number}page
     * @param{number}length
     * @param{string}sort
     * @param{string}search
     *
     * @return {Observable}
     */
  public getClients(page?: number, length?: any, sort?: string, search?: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/client-list-paginate?page=${page}&length=${parseInt(length)}&sort=${sort}&search=${search}`);
  }
  /**
   * search for document
   * @param{string}search
   *
   * @return {Observable}
   */
  public getDocumentBySearch(search: any): Observable<APIResponseModel> {
    if (Object.keys(search).length) {
      return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/search-will`, search);
    }
  }

  /**
   * Retrieves a paginated list of recently imported clients.
   * 
   * @param {number} [page] 
   * @param {any} [length] 
   * @param {string} [sort] 
   * @param {string} [search]
   * @returns {Observable<APIResponseModel>} - An observable containing the API response model.
   */
  public getRecentlyImportedClients(page?: number, length?: any, sort?: string, search?: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/professional-clients-histories-list?page=${page}&length=${parseInt(length)}&sort=${sort}&search=${search}`);
  }

  /**
   * Retrieves a bulk imported file by its ID.
   * 
   * @param {string} id - The ID of the file to retrieve.
   * @returns {Observable<Blob>} - An observable containing the file as a blob.
   */
  public retrieveBulkImportedFile(id: string) {
    return this.http.get(`${this.BASE_URL}/professional/histories-file-download/${id}`, { responseType: 'blob' });
  }
}
