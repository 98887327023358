<div class="lg:grid grid-cols-12 background-color">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 md:gap-8 my-4">
              <div class="col-span-12 sm:col-span-2">
                <!-- <div class="w-16 my-2"> -->
                <img src="assets/images/about-yourself/about-yourself.png" class="w-20" alt="digital vault">
                <!-- </div> -->
              </div>

              <div class="col-span-12 sm:col-span-10">
                <!-- <div class="pl-4 sm:px-4 ml-8"> -->
                <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
                  Tell us about yourself
                </h2>
              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-4/6'"></app-card-progress-bar>
              <!-- END: Progress Bar -->

              <form class="p-6" [formGroup]="relationshipForm" autocomplete="off">
                <div class="mb-4">
                  <div class="flex flex-row items-baseline">
                    <div>
                      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                        What is your Marital Status?
                      </h4>
                      <p class="text-grey text-regular pt-1 text-sm">
                        Select your current legal status from the dropdown. If this status changes in
                        the future please remember to update this section accordingly.
                      </p>
                    </div>
                  </div>
                </div>


                <div class="col-span-12 relative">
                  <label for="marital_status" class="text-grey text-sm text-regular">Marital Status*</label>
                  <div class="col-span-12 lg:col-span-2 relative">

                    <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                      <app-select-component id="marital_status" [options]="relationshipOptions"
                                            [placeholder]="'Select Status'"
                                            [selectedOptionDefault]="relationshipForm?.value?.['marital_status']"
                                            (optionChangeEmitter)="optionChangeListener($event)"
                                            [ifError]="getRelationshipForm.marital_status.errors && getRelationshipForm.marital_status.touched"
                                            (toggleEmiter)="toggleEmiter($event)"></app-select-component>
                      <p *ngFor="let error of getRelationshipFormErrors('marital_status')"
                         class="text-regular font-medium text-xs mt-2 error-alert">
                        {{error}}
                      </p>
                    </app-shimmer-loading>
                  </div>
                </div>

                <div *ngIf="showToggle" class="col-span-12 relative space-y-4 mt-4">
                  <div class="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                    <label for="marital_status" class="text-grey text-sm text-regular">
                      Would you like to provide us with their information?
                    </label>
                    <app-toggle-switch [defaultSelected]="enterPartnerInfo" [options]="toggleOptions"
                                       (optionChangeEmitter)="onChangeToggleProvideInfo($event)"
                                       [disabled]="submitLoader[0]|| submitLoader[1]">
                    </app-toggle-switch>
                  </div>

                </div>

                <form *ngIf="enterPartnerInfo" [formGroup]="partnerForm" autocomplete="off">

                  <div class="my-8">
                    <hr class="mid-line border border-2">
                  </div>

                  <div class="my-4">
                    <div class="flex flex-row items-baseline">
                      <div>
                        <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                          Their Personal Information
                        </h4>
                      </div>
                    </div>
                    <p *ngIf="isDisableForm" class="text-red-500">Note: Fields are non-editable after their registration. </p>
                  </div>

                  <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
                    <div class="col-span-12">
                      <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 lg:col-span-4">
                          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                            <label for="first_name" class="text-label-grey text-sm text-regular">First Name*</label>
                            <input type="text" [attr.disabled]="isDisableForm || undefined"
                                   class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                                   name="first_name" id="first_name" formControlName="first_name"
                                   placeholder="First Name"
                                   [ngClass]="getPartnerForm.first_name.errors && getPartnerForm.first_name.touched?'form-input-error':'form-input-focus'">
                          </app-shimmer-loading>
                          <p *ngFor="let error of getErrors('first_name')"
                             class="text-regular font-medium text-xs mt-2 error-alert">
                            {{error}}
                          </p>
                        </div>
                        <div class="col-span-12 lg:col-span-4">
                          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                            <label for="middle_name" class="text-label-grey text-sm text-regular">Middle Name</label>
                            <input type="text" [attr.disabled]="isDisableForm || undefined"
                                   class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                                   id="middle_name" formControlName="middle_name" placeholder="Middle Name"
                                   [ngClass]="getPartnerForm.middle_name.errors && getPartnerForm.middle_name.touched?'form-input-error':'form-input-focus'">
                          </app-shimmer-loading>
                          <p *ngFor="let error of getErrors('middle_name')"
                             class="text-regular font-medium text-xs mt-2 error-alert">
                            {{error}}
                          </p>
                        </div>
                        <div class="col-span-12 lg:col-span-4">
                          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                            <label for="last_name" class="text-label-grey text-sm text-regular">Last Name*</label>
                            <input type="text" id="last_name" [attr.disabled]="isDisableForm || undefined"
                                   class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                                   name="last_name" formControlName="last_name" placeholder="Last Name"
                                   [ngClass]="getPartnerForm.last_name.errors && getPartnerForm.last_name.touched?'form-input-error':'form-input-focus'">
                          </app-shimmer-loading>
                          <p *ngFor="let error of getErrors('last_name')"
                             class="text-regular font-medium text-xs mt-2 error-alert">
                            {{error}}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-span-12">
                      <label [for]="'dob'" class="text-label-grey text-sm text-regular">
                        Date of Birth ( {{domainDateFormat}} )
                      </label>
                      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                        <app-datepicker-toggle
                          inputID="dob"
                          class="mt-1"
                          [bsConfig]="bsConfig"
                          [ngStyle]="{ 'opacity': isDisableForm ? '0.5' : '1', 'pointer-events': isDisableForm ? 'none' : 'auto' }"
                          [useFormControl]="getPartnerForm.dob">
                        </app-datepicker-toggle>
                      </app-shimmer-loading>
                      <p *ngFor="let error of getErrors('dob')"
                         class="text-regular font-medium text-xs mt-2 error-alert">
                        {{error}}
                      </p>
                    </div>
                    <ng-container *ngIf="showSSN && !isWidowed">
                      <div class="col-span-12 lg:col-span-6">
                        <label for="ssn" class="text-label-grey text-sm text-regular whitespace-nowrap">SSN
                          (Social Security Number)</label>
                        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                          <div class="relative text-gray-700">
                            <a class="absolute inset-y-0 right-2 pr-3 flex items-center"
                               (click)="toggleSSNView.ssn = !toggleSSNView.ssn">
                              <fa-icon [icon]="faEye" *ngIf="toggleSSNView.ssn"></fa-icon>
                              <fa-icon [icon]="faEyeSlash" *ngIf="!toggleSSNView.ssn"></fa-icon>
                            </a>
                            <input type="text" [type]="toggleSSNView.ssn ? 'text' : 'password'" [attr.disabled]="isDisableForm || undefined"
                                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                                   placeholder="e.g. 255-34-5345" id="ssn" formControlName="ssn"
                                   [ngClass]="getPartnerForm.ssn.errors && getPartnerForm.ssn.touched?'form-input-error':'form-input-focus'"
                                   maxlength="11" [mask]="SSN_MASK" [dropSpecialCharacters]="false"
                                   (focusout)="onFocusout($event,'ssn');onFocusout($event,'ssn_validation')">
                          </div>
                        </app-shimmer-loading>
                        <p *ngFor="let error of getErrors('ssn')"
                           class="text-regular font-medium text-xs mt-2 error-alert">
                          {{error}}
                        </p>
                      </div>
                      <div class="col-span-12 lg:col-span-6">
                        <label for="ssn_validation" class="text-label-grey text-sm text-regular">
                          Confirm SSN
                        </label>
                        <div class="relative h-12 text-gray-700">

                          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                            <div class="relative">
                              <a class="absolute inset-y-0 right-2 pr-3 flex items-center"
                                 (click)="toggleSSNView.v_ssn = !toggleSSNView.v_ssn">
                                <fa-icon [icon]="faEye" *ngIf="toggleSSNView.v_ssn"></fa-icon>
                                <fa-icon [icon]="faEyeSlash" *ngIf="!toggleSSNView.v_ssn"></fa-icon>
                              </a>
                              <input type="text" [type]="toggleSSNView.v_ssn ? 'text' : 'password'"
                                     class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                                     name="ssn_validation" id="ssn_validation" formControlName="ssn_validation"
                                     placeholder="e.g. 255-34-9345" [attr.disabled]="isDisableForm || undefined"
                                     [ngClass]="getPartnerForm.ssn_validation.errors && getPartnerForm.ssn_validation.touched?'form-input-error':'form-input-focus'"
                                     [mask]="SSN_MASK" [dropSpecialCharacters]="false" maxlength="11"
                                     (input)="onChange($event,'ssn_validation')"
                                     (focusout)="onFocusout($event,'ssn_validation')">
                            </div>
                          </app-shimmer-loading>

                        </div>
                        <div
                          *ngIf="!checkSSNMatch() && ((getPartnerForm.ssn.value && getPartnerForm.ssn.valid) && !getPartnerForm.ssn_validation.value)">
                          <p class="text-regular font-medium text-xs mt-2 error-alert">
                            Confirm SSN is required.
                          </p>
                        </div>
                        <div
                          *ngIf="!checkSSNMatch() && ((getPartnerForm.ssn.value && getPartnerForm.ssn.valid) && getPartnerForm.ssn_validation.value)">
                          <p class="text-regular font-medium text-xs mt-2 error-alert">
                            SSN numbers must match.
                          </p>
                        </div>
                      </div>

                      <div class="col-span-12" *ngIf="checkSSNMatch()">
                        <div *ngIf="validatingSSN == 1" class="flex">
                          <p class="text-regular font-medium text-xs checking-alert">
                            Validating SSN
                          </p>
                          <div class="loading-red mr-2"></div>
                        </div>
                        <p class="text-regular font-medium text-xs error-alert"
                           *ngIf="validatingSSN == 2 && !isUniqueSSN">
                          SSN has already been entered.
                        </p>
                      </div>
                    </ng-container>
                  </div>

                  <ng-container *ngIf="!isWidowed">
                    <div class="my-8">
                      <hr class="mid-line border border-2">
                    </div>

                    <div>
                      <div class="flex flex-row items-baseline">
                        <div>
                          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                            Their Contact Information
                          </h4>
                        </div>
                      </div>
                      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
                        <div class="col-span-12">
                          <label for="mail-id" class="text-label-grey text-sm text-regular">
                            Email Address</label>
                          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                            <input type="email" id="mail-id" [attr.disabled]="isDisableForm || undefined"
                                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                                   placeholder="e.g. marktylor@gmail.com"
                                   [ngClass]="getPartnerForm.email.errors && getPartnerForm.email.touched?'form-input-error':'form-input-focus'"
                                   formControlName="email" name="email">
                          </app-shimmer-loading>
                          <p *ngFor="let error of getErrors('email')"
                             class="text-regular font-medium text-xs mt-2 error-alert">
                            {{error}}
                          </p>
                        </div>

                        <div class="col-span-12">
                          <label [for]="'phone-no'" class="text-label-grey text-sm text-regular">
                            Phone Number</label>
                          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                            <app-phone-input [placeholder]="'e.g. 8665044754'" [phoneNumber]="partnerForm.value.phone"
                                             [countryCode]="countryCode" [uniqueId]="'phone-no'"
                                             (onChange)="detectChangePhonenumber($event)"
                                             (click)="getPartnerForm.phone.markAsTouched()"
                                             [errorClassTrigger]="getPartnerForm.phone.errors && getPartnerForm.phone.touched"
                                             [form]="partnerForm"
                                             (focusout)="getPartnerForm.phone.markAsTouched()" [disabled]="isDisableForm">
                            </app-phone-input>
                          </app-shimmer-loading>
                          <p *ngFor="let error of getErrors('phone')"
                             class="text-regular font-medium text-xs mt-2 error-alert">
                            {{error}}
                          </p>
                        </div>
                      </div>

                      <div class="my-8">
                        <hr class="mid-line border border-2">
                      </div>
                      <div>
                        <div class="mb-4">
                          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                            Address
                          </h4>
                        </div>
                        <div class="grid grid-cols-12 gap-4">
                          <div class="col-span-12 sm:col-span-8">
                            <h5 class="text-regular text-sm text-darkblue font-semibold tracking-wide">
                              Is their address the same as your address?
                            </h5>
                          </div>
                          <div class="col-span-12 sm:col-span-4">
                            <app-toggle-switch [defaultSelected]="addressStatus" [options]="toggleOptions"
                                               [disabled]="submitLoader[0]|| submitLoader[1] || isDisableForm"
                                               (optionChangeEmitter)="onChangeToggleAddress($event)">
                            </app-toggle-switch>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="!addressStatus">
                        <div class="mt-1 col-span-12">
                          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
                            <div class="col-span-12">
                              <label for="country" class="text-label-grey text-sm text-regular">Country</label>
                              <div class="relative">
                                <app-country-search-component id="country"
                                                              (optionChangeEmitter)="optionChangeListenerCountry($event)"
                                                              [defaultSelected]="getPartnerForm?.country?.value"
                                                              [enableError]="getPartnerForm.country.errors && getPartnerForm.country.touched" [disabled]="isDisableForm">
                                </app-country-search-component>
                              </div>
                            </div>
                            <div class="col-span-12 lg:col-span-8 relative move-map" #moveMapHere>
                              <label for="street-address" class="text-label-grey text-sm text-regular">
                                Street Address
                              </label>
                              <input type="text" id="street-address" #search (keyup)="onAddressChange(search)"
                                     (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null"
                                     (input)="onFocusAddress()" [attr.disabled]="isDisableForm || undefined"
                                     class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                                     placeholder="e.g Main St" name="address" formControlName="address"
                                     [ngClass]="getPartnerForm.address.errors && getPartnerForm.address.touched?'form-input-error':'form-input-focus'">
                              <p *ngFor="let error of getErrors('address')"
                                 class="text-regular font-medium text-xs mt-2 error-alert">
                                {{error}}
                              </p>
                            </div>
                            <div class="col-span-12 lg:col-span-4">
                              <label for="apt_number" class="text-label-grey text-sm text-regular">Apartment / Suite
                                #</label>
                              <input name="apt_number" id="apt_number" formControlName="apt_number" [attr.disabled]="isDisableForm || undefined"
                                     class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                                     [ngClass]="getPartnerForm.apt_number.errors && getPartnerForm.apt_number.touched?'form-input-error':'form-input-focus'">
                              <p *ngFor="let error of getErrors('apt_number')"
                                 class="text-regular font-medium text-xs mt-2 error-alert">
                                {{error}}
                              </p>
                            </div>
                            <div class="col-span-12" *ngIf="selectedCountryName!==unitedKingdom">
                              <label for="state-Province"
                                     class="text-label-grey text-sm text-regular">State/Province</label>
                              <div class="relative">
                                <app-state-search-component id="state-province"
                                                            [countryCode]="getPartnerForm?.country?.value"
                                                            [enableError]="getPartnerForm.state.errors && getPartnerForm.state.touched"
                                                            [defaultSelected]="getPartnerForm?.state?.value"
                                                            (optionChangeEmitter)="optionChangeListenerState($event)"
                                                            tabindex="0"
                                                            (focusout)="markAsTouchedIndividual('state')" [disabled]="isDisableForm"></app-state-search-component>
                              </div>
                            </div>
                            <div class="col-span-12 md:col-span-6 relative">
                              <label for="city" class=" text-label-grey text-sm text-regular ">City</label>
                              <app-city-search [countryName]="selectedCountryName" id="city"
                                               [stateCode]="getPartnerForm?.state?.value"
                                               [enableError]="getPartnerForm.city.errors && getPartnerForm.city.touched"
                                               [defaultSelected]="getPartnerForm?.city?.value"
                                               (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0"
                                               (focusout)="markAsTouchedIndividual('city')" [disabled]="isDisableForm"></app-city-search>
                              <p *ngFor="let error of getErrors('city')"
                                 class="text-regular font-medium text-xs mt-2 error-alert">
                                {{error}}
                              </p>
                            </div>
                            <div class=" col-span-12 md:col-span-6 ">
                              <label for="zipcode" class="text-label-grey text-sm text-regular">
                                Zip/Postal Code
                              </label>
                              <input type="text" (focusout)="markAsTouchedIndividual('zipcode')"
                                     class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
                                     placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                                     name="zipcode" id="zipcode" formControlName="zipcode" [attr.disabled]="isDisableForm || undefined"
                                     oninput="this.value = this.value.toUpperCase()" (focus)="validateZipCode()"
                                     [ngClass]="getPartnerForm.zipcode.errors && getPartnerForm.zipcode.touched?'form-input-error':'form-input-focus'">
                              <p *ngFor="let error of getErrors('zipcode')"
                                 class="text-regular font-medium text-xs mt-2 error-alert">
                                {{error}}
                              </p>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </form>
                <p *ngIf="enterPartnerInfo" class="mt-2 text-grey text-regular pt-1 text-sm">
                  By including the above information, you are certifying to us that you are authorized to do so.
                </p>
                <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
                  <a (click)="onClickBack()"
                     class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </a>
                  <app-button-loader class="w-full sm:w-auto ml-auto" [buttonText]="this.redirectUrl? 'Discard':'Save and Continue'" [valid]="isSubmitValid"
                                     [loader]="submitLoader[0]" buttonType="'submit'" *ngIf="!this.redirectUrl"
                                     (click)="onSubmit(0)"></app-button-loader>
                  <app-button-loader class="w-full sm:w-auto" [buttonText]="'Save and Return to' + redirectBtnText"
                                     [valid]="isSubmitValid" [loader]="submitLoader[1]" *ngIf="this.redirectUrl"
                                     buttonType="'submit'"
                                     (click)="onSubmit(1)"></app-button-loader>
                </div>
              </form>
              <!-- END: Leftside Card -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class="col-span-12 md:col-span-6 lg:grid justify-center">
    <about-yourself-right-content [formInputValue]="relationshipForm?.value"
                                  [otherValues]="sectionSaveExitOptions"></about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->
</div>
