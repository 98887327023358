import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PersonalDetailsService } from '../../services/personal-details.service';
import { SelectOptionsInterface } from '../../interface/common.interface';
import { CommonHelper } from '../../helper/common.helper';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { CommonModelService } from 'src/app/services/common-model.service';

@Component({
  selector: 'app-select-user-person',
  templateUrl: './select-user-person.component.html',
})
export class SelectUserPersonComponent implements OnInit {
  /**
   *  Currently emits 'true' to notify parent component 'User wants to add a new person' and lets the parent component
   *  handle it from there. In the future, I want to make this a dynamic component which can handle collecting & saving
   *  new user-person information itself.
   */
  @Input() requestPage: string;
  @Output() addButtonEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectEmitter: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() editDetailsEmitter: EventEmitter<Object> = new EventEmitter<Object>();
  public nonFamilyArray: Array<any>;
  public familyArray: Array<any>;
  public peopleArray: Array<any>;
  public alternativeCustodianArray: Array<any>;
  public custodianArray: Array<any>;
  public userPeopleOptions: Array<SelectOptionsInterface>;
  private userPeople: Array<Object>;
  public isLoading: boolean;
  public userType: string;
  public userId: string;
  public editData: any;

  constructor(private personalDetailsService: PersonalDetailsService,
    private localStorageService: LocalStorageService,
    private peopleService: PeopleService,
    private modelService: CommonModelService,
    private commonHelper: CommonHelper) {
  }

  ngOnInit(): void {
    this.userType = this.localStorageService.getDataByKey('role');
    this.userId = this.localStorageService.getDataByKey('user')?.user?.id;
    this.getUserPeople();
    // refresh executor
    this.peopleService.refreshDetailsCommonObserve.subscribe((response: any) => {
      if (response) {
        this.getUserPeople();
      }
    });
  }

  /**
   * Emitter for when the 'add' button is pressed.
   */
  public addButton() {
    this.addButtonEmitter.emit(true);
  }

  /**
   * Adds custodian
   * @param [editValue]
   */
  public addCustodian(editValue?: any): void {
    this.editData = {};
    
    if (editValue) {
      console.log(this.checkIsCustodinaOnly(editValue), "--------------")
      if(!this.checkIsCustodinaOnly(editValue)){
        return;
      }
      
      this.editData = { ...editValue };
      this.editDetailsEmitter.emit(this.editData);
      this.modelService.close('choose-relatives-or-add-modal');
    }else{
      this.modelService.open('add-custodian-modal');
      this.modelService.close('choose-relatives-or-add-modal');
    }
  }
  /**
   * Adds executor
   * @param [editValue]
   */
  public addExecutor(editValue?: any): void {
    this.editData = {};
    if (editValue) {
      if(!this.checkIsExecutorOnly(editValue)){
        return;
      }
      this.modelService.close('choose-relatives-or-add-modal');
      this.editData = { ...editValue };
      this.editDetailsEmitter.emit(this.editData);
    }else{
      this.modelService.close('choose-relatives-or-add-modal');
      this.modelService.open('add-executor-modal');
    }
  }
  /**
   * Gets user people
   */
  private getUserPeople() {
    this.userPeopleOptions = [];
    this.userPeople = [];
    this.isLoading = true;
    this.personalDetailsService.getUserPeople().subscribe({
      next: (r) => {
        if (r.status) {
          this.familyArray = r.data.user_people.filter((user) => {
            return (user.relation === 1 || user.relation === 2);
          });
          this.alternativeCustodianArray = r.data.user_people.filter((user) => {
            return user.custodian === 1 && user.executor !== 1 && user.advisor !== 1 && user.beneficiary !== 1 && user.relation == null;
          });
          this.nonFamilyArray = r.data.user_people.filter((user) => {
            return user.relation === null && !this.alternativeCustodianArray?.find((v) => v.id === user.id);
          });
          if (this.requestPage === 'executor') {
            this.alternativeCustodianArray = [];
          } else {
            this.familyArray = this.familyArray.filter((user) => {
              return user.executor !== 1;
            });
            this.nonFamilyArray = this.nonFamilyArray.filter((user) => {
              return user.executor !== 1;
            });
          }
          console.log('nonFamilyArray', this.nonFamilyArray);
          this.formCustodinArray();
          this.userPeopleOptions.sort(this.commonHelper.compareDisplayText);
        }
        console.log('user_people1', r);
        console.log('user_people2', this.userPeopleOptions);
      },
      complete: () => this.isLoading = false,
    });
  }

  /**
   * Form custodian array
   */
  private formCustodinArray(): void {
    this.custodianArray = [...this.familyArray.filter((data) => data?.executor === 1 && data?.custodian === 1).map((data) => data?.id), ...this.nonFamilyArray.filter((data) => data?.executor === 1 && data?.custodian === 1).map((data) => data?.id)];
    this.selectEmitter.emit(this.custodianArray);
  }

  /**
   * Listener for dropdown list selection.
   * @param event
   */
  public optionChangeListener(event: SelectOptionsInterface) {
    this.selectEmitter.emit(this.userPeople.find((e) => e['id'] == event.value));
  }
  /**
   * add/ remove id form an array
   *
   * @param {string} id
   */
  public onChangeEmitter(id: string): void {
    if (this.custodianArray.indexOf(id) === -1) {
      this.custodianArray.push(id);
    } else {
      this.custodianArray.splice(this.custodianArray.indexOf(id), 1);
    }
    // emit after custodian array changes
    this.selectEmitter.emit(this.custodianArray);
  }
  /**
   * Checks is executor only
   * @param value
   * @returns true if is executor only
   */
  private checkIsExecutorOnly(value: any): boolean {
    return value.advisor !==1 && value.beneficiary !==1 && value.relation !==1 && value.relation !==2;
  }
  /**
   * Checks is custodina only
   * @param value 
   * @returns true if is custodina only 
   */
  private checkIsCustodinaOnly(value: any): boolean {
    return value.advisor !==1 && value.beneficiary !==1 && value.relation !==1 && value.relation !==2 && value.executor !==1 && value.custodian==1;
  }
}
