import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SectionStatus } from '../../constants/application.const';
import { CommonHelper } from '../helper/common.helper';
import { APIResponseModel } from '../interface/response.interface';
import { LocalStorageService } from './local-storage.service';


@Injectable({
  providedIn: 'root',
})
/**
 * Personal Details
 */
export class PersonalDetailsService {
  private readonly BASE_URL: string;

  // state observables
  private personalDetailsTrigger: any;
  public personalDetailsObserve: Observable<any>;
  public requestId: any;

  /**
   * @constructor
   */
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private commonHelper: CommonHelper,
  ) {
    // for personal details
    this.personalDetailsTrigger = new BehaviorSubject({});
    this.personalDetailsObserve = this.personalDetailsTrigger.asObservable();

    this.BASE_URL = environment.BASE_URL;
  }

  /**
   * Send user details when ready
   *
   * @param {any} userDetails
   */
  public sendPersonalDetails(userDetails: any): void {
    this.personalDetailsTrigger.next(userDetails);
  }

  /**
   * Body interceptor for personal details
   *
   * @param {any} body
   * @param {number} type
   * @return {any}
   */
  private bodyInterceptor(body: any, type: number): any {
    return {
      ...body,
      type,
    };
  }

  /**
   * post personal details
   *
   * @param {any} data
   * @param {number} type ( Incrementing number for consecutive form submission )
   * @return {Observable<APIResponseModel>}
   */
  public storePersonalDetails(data: any, type: number): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/request/store-personal-details', this.bodyInterceptor(data, type));
  }

  /**
   *update personal details
   *
   * @param {any} data
   * @param {number} type ( Incrementing number for consicutive form submission )
   * @return {Observable<APIResponseModel>}
   */
  public updatePersonalDetails(data: any, type: number): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/request/update-personal-details', this.bodyInterceptor(data, type));
  }

  /**
   * Get personal details by request_id
   *
   * @param {string} requestId
   * @return {Observable<APIResponseModel>}
   */
  public getPersonalDetails(requestId?: string,userAsBenificiary:number = 0): Observable<APIResponseModel> {
    if (requestId) {
      return this.http.get<APIResponseModel>(this.BASE_URL + `/request/get-request-details/${requestId}?user_as_beneficiary=${userAsBenificiary}`);
    } else {
      this.requestId = this.localStorageService.getUserData()?.request?.id;
      return this.http.get<APIResponseModel>(this.BASE_URL + `/request/get-details/${this.requestId}?user_as_beneficiary=${userAsBenificiary}`);
    }
  }

  /**
   * Return User People by request ID. <br>
   * Provides ease-of-use fields, see {@link processUserPeople}.
   * @param requestId
   */
  public getUserPeople(requestId?: string,userAsBenificiary:number = 0): Observable<APIResponseModel> {
    return new Observable<APIResponseModel>((observer) => {
      this.getPersonalDetails(requestId,userAsBenificiary).subscribe({
        next: (r) => {
          const data = {
            status: r?.status,
            statusCode: r?.statusCode,
            message: r?.message,
            data: {
              id: r?.data?.id,
              marital_status: r?.data?.marital_status,
              user_people: this.processUserPeople(r.data.user_people, r?.data?.marital_status),
            },

          };
          observer.next(data);
        },
        error: (e) => observer.error(e),
        complete: () => observer.complete(),
      });
    });
  }

  /**
   * Provides some extra easy-of-use fields to user_people data.
   * @return Processed {@link user_people}
   */
  public processUserPeople(user_people: Array<Object>, marital_status?: number) {
    user_people?.forEach((e) => {
      // Fill common alternate people_relation terms.
      if (!e['people_relation']) {
        if (e['relation'] == 1) {
          e['people_relation'] = { name: CommonHelper.getPartnerSubText(marital_status) ?? 'Spouse/Partner' };
        } else if (e['relation'] == 2) {
          e['people_relation'] = { name: 'Your Child' };
        }
      }

      // Fill full_name
      if (e['beneficiary_type']?.type != 2) {
        // Individual
        const first_name = e['first_name']?.length ? e['first_name'] : '';
        const middle_name = e['middle_name']?.length ? e['middle_name'] : '';
        const last_name = e['last_name']?.length ? e['last_name'] : '';
        e['full_name'] = `${first_name} ${middle_name} ${last_name}`.replace('  ', ' ');
      } else {
        // Trust
        e['full_name'] = e['beneficiary_trustee_detail']?.trust_name ?? '';
      }

      // Fill initials
      e['name_initials'] = e['beneficiary'] == 1 ? e['beneficiary_trustee_detail']?.['trust_name']?.charAt(0).toUpperCase() : undefined;
      if (e['full_name'] && !e['name_initials']) {
        const fullNameInitialLength = e['full_name']?.split(' ')?.length ?? 0;
        const initials = fullNameInitialLength < 4 ? e['full_name']?.split(' ') : [e['first_name'], e['last_name']]
        initials.forEach((e, i) => initials[i] = e?.charAt(0));
        e['name_initials'] = initials.join(' ');
      }
    });
    return user_people;
  }

  /**
   * Returns the advisor(s) user_people information. If an advisor ID is passed, that will
   * be the only advisor returned by next (if it exists). If no advisorID is passed, then all advisors will be returned.
   * @param advisorID
   * @param requestID
   */
  public getAdvisorInfo(advisorID?: string, requestID?: string): Observable<Object[]> {
    const bAll = !advisorID;
    return new Observable<{}[]>((observer) => {
      this.getPersonalDetails(requestID).subscribe({
        next: (r) => {
          const user_people = r.data.user_people;
          const user_advisors: Object[] = [];
          user_people.forEach((e) => {
            if (e.advisor == 1 && (bAll || e.id == advisorID)) {
              user_advisors.push(e);
            }
          });
          observer.next(user_advisors);
        },
        error: (e) => observer.error(e),
        complete: () => observer.complete(),
      });
      return {
        unsubscribe() {
        },
      };
    });
  }

  /**
   * Get personal details for  insurnce and retirement asset due to primary/secondary beneficiaries by request_id
   *
   * @param {string} requestId
   * @return {Observable<APIResponseModel>}
   */
  public getPersonalDetailsWithBeneficiary(requestId?: string): Observable<APIResponseModel> {
    if (requestId) {
      return this.http.get<APIResponseModel>(this.BASE_URL + `/request/get-beneficiary-details/${requestId}`);
    } else {
      this.requestId = this.localStorageService.getUserData()?.request?.id;
      return this.http.get<APIResponseModel>(this.BASE_URL + `/request/get-beneficiary-details/${this.requestId}`);
    }
  }

  /**
   * Get personal details by request_id
   *
   * @param {string} requestId
   * @return {Observable<APIResponseModel>}
   */
  public getDashboardDetails(requestId?: string): Observable<APIResponseModel> {
    if (requestId) {
      return this.http.get<APIResponseModel>(this.BASE_URL + `/request/get-request-details-count/${requestId}`);
    } else {
      this.requestId = this.localStorageService.getUserData()?.request?.id;
      return this.http.get<APIResponseModel>(this.BASE_URL + `/request/get-details-count/${this.requestId}`);
    }
  }

  /**
   * Set the completion status of a specified section.
   * See {@link SECTIONS} for section names
   * @param {string} requestId
   * @param {string} section_name
   * @param {number} status
   * @return {Observable}
   */
  public setSectionCompletionStatus(requestId: string, section_name: string, status: SectionStatus): Observable<APIResponseModel> {
    return new Observable((observer) => {
        this.setSectionStatus(requestId, section_name, status).subscribe({
          next: (r) => observer.next(r),
          error: (e) => {
            this.commonHelper.httpResponseHandler(e.error);
            observer.error(e);
          },
          complete: () => observer.complete(),
        });
        return {
          unsubscribe() {
          },
        };
      },
    );
  }

  /**
   * Set the completion status of a specified section.
   * See {@link SECTIONS} for section names
   * @param{string} requestId
   * @param{string} sectionName
   * @param{number} status
   * @private
   * @return{Observable}
   */
  private setSectionStatus(requestId: string, sectionName: string, status: number): Observable<APIResponseModel> {
    const data = { user_request_id: requestId, section_name: sectionName, status: status };
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/users/update-section-status`, data);
  }

  /**
   * Makes http call for user section status.
   * @param{string} requestId
   * @private
   * @return{Observable}
   */
  private getSectionStatus(requestId: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/users/get-section-status/${requestId}`);
  }

  /**
   * Get the completion status of each section of the consumer's digital vault, referenced by request ID.
   * See {@link SECTIONS} for section names
   * @param{string} requestId User requestID
   * @param{string[]} sections A list of section names which, if provided, will filter the return by these sections.
   * @return{Observable}
   */
  public getSectionCompletionStatus(requestId: string, sections: string[] = null): Observable<{ [key: string]: { section_name: string, status: number, updated_at: string, created_at: string } }> {
    return new Observable((observer) => {
      this.getSectionStatus(requestId).subscribe({
          next: (response) => {
            try {
              if (response.status) {
                const data: {}[] = response.data;
                const sectionStatus: { [key: string]: { section_name: string, status: number, updated_at: string, created_at: string } } = {};
                data.forEach((e) => {
                  if (sections == null || sections.indexOf(e['section_name']) >= 0) {
                    sectionStatus[e['section_name']] = {
                      section_name: e['section_name'],
                      status: SectionStatus[Object.keys(SectionStatus).find((key) => SectionStatus[key] === e['status'])],
                      updated_at: e['updated_at'],
                      created_at: e['created_at'],
                    };
                  }
                });
                observer.next(sectionStatus);
              }
            } catch (e) {
              observer.error(e);
            }
          },
          error: (e) => {
            observer.error(e);
            observer.complete();
          },
          complete: () => observer.complete(),
        },
      );
      return {
        unsubscribe() {
        },
      };
    });
  }

  // beneficiary percentage details
  /**
   *store beneficiary insurance details
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public beneficiaryInsuranceDetails(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/request/store-beneficiary-insurance', data);
  }

  /**
   *store beneficiary retirement details
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public beneficiaryRetirementDetails(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/request/store-beneficiary-retirement', data);
  }

  /**
   *store beneficiary financial details
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public beneficiaryFinancialDetails(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/request/store-beneficiary-financial', data);
  }

  /**
   * Common functionality for submitting primary and secondary beneficiaries simultaneously.<br>
   * Type:<br>
   * 1: Retirement Asset {@link beneficiaryRetirementDetails}<br>
   * 2: Financial Asset {@link beneficiaryFinancialDetails}<br>
   * 3: Insurance asset {@link beneficiaryInsuranceDetails}
   * @param data
   * @param type
   */
  public submitBeneficiaries(data: { 1: Object, 2: Object }, type: 1 | 2 | 3): Observable<APIResponseModel> {
    const submitFn = {
      1: this.beneficiaryRetirementDetails.bind(this),
      2: this.beneficiaryFinancialDetails.bind(this),
      3: this.beneficiaryInsuranceDetails.bind(this),
    };
    return new Observable<APIResponseModel>((observer) => {
      submitFn[type](data[2]).subscribe({
        error: (e) => observer.error(e),
        complete: () => {
          submitFn[type](data[1]).subscribe({
            error: (e) => observer.error(e),
            complete: () => observer.complete(),
          });
        },
      });
    });
  }
}
