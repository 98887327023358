import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { CommonHelper } from '../../../helper/common.helper';
import { MessageModalSettingsInterface } from '../../../sharedComponent/message-modal/message-modal.component';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';
import { ConsumerPaymentService } from '../consumer-payment-update.service';


@Component({
  selector: 'app-subscription-plan-modal',
  templateUrl: './subscription-plan-modal.component.html',
})
/**
 * modal for subscription plan
 */
export class SubscriptionPlanModalComponent {
  @Input() subscriptionType: string = 'Monthly';
  @Input() updatePlanButton: boolean = false;
  @Input() subscriptionDetails: any;
  @Input() monthlyPackageDetails: any;
  @Input() yearlyPackageDetails: any;
  @Input() currentSubscriptionType: any;
  @Output() clickEmitter = new EventEmitter<boolean>();
  public messageModalID = 'cancel-plan-message-modal';
  public customerSupportMail: string;
  public customerSupportPhone: number;
  public cancelSubscriptionLoader: boolean;
  public isAlertShowed: boolean;
  public messageData = {
    title: { content: 'Alert', class: 'text-2xl' },
    message: [
      'Your refund process has been initiated. Refund amount will be credited to your account in two to three business days',
    ],
    buttons: [{
      content: 'Understood!',
      emitValue: false,
    }],
  };
  public alertData = {
    title: { content: 'Alert', class: 'text-2xl' },
    message: [
      'By canceling your subscription, you will lose access to LegacyNOW\'s premium features and your subscription benefits. However, you will still be able to use the application until your current billing period ends. To proceed, click Confirm Cancellation.',
    ],
    buttons: [{
      content: 'Confirm Cancellation',
      emitValue: true,
      class: 'custom-border-button bg-white confirm_cancellation',
    }, {
      content: 'Keep my Subscription',
      emitValue: false,
    }],
  };
  public messageSettings: MessageModalSettingsInterface = {
    showExit: false,
  };


  /**
   * constructor
   */
  constructor(private modalService: CommonModelService,
    private localStorageService: LocalStorageService,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    private paymentService: ConsumerPaymentService,
    private subscriptionService: ConsumerSubscriptionService,
    private commonService: CommonService) {
  }


  ngOnInit() {
    this.getContactDetails();
  }


  /**
   * close modal
   */
  public closeModal() {
    this.modalService.close('subscription-plan');
  }


  /**
   * set plan
   * @param {string} plan
   */
  public setPlan(plan: any): void {
    this.subscriptionType = plan;
    this.updatePlanButton = plan != this.currentSubscriptionType;
  }


  /**
   * close
   */
  public closeModel() {
    this.modalService.close('subscription-plan');
  }


  /**
   * update plan
   */
  public clickUpdatePlan() {
    if (this.updatePlanButton) {
      this.paymentService.subscriptionPlanObservableEvents1(this.currentSubscriptionType);
      this.paymentService.paymentScreenObservableEvents1(true);
      this.modalService.close('subscription-plan');
      void this.slugInterceptorService.navigate(['subscription-plan']);
    }
  }


  /**
   * Get end_date of the subscription, formatted to long-date format.
   */
  get subscriptionEndDate(): string {
    const end_date = this.subscriptionDetails?.end_date;
    return this.commonHelper.formatDate(moment(end_date), 'MMMM DD, YYYY');
  }


  /**
   * cancel plan
   */
  // public clickCancelPlan() {
  //   this.messageData = {
  //     title: 'Cancel Subscription Plan',
  //     message: [
  //       `To cancel your subscription plan, please contact us at:`,
  //       `
  //       <div class="flex justify-left mb-2">
  //         <p>
  //           Phone:<br>&emsp;
  //           <a href="tel:+1${this.customerSupportPhone}" target="_blank" class="hover:underline cursor-pointer">+1 ${this.customerSupportPhone}</a>
  //         </p>
  //         <p class="m-auto">
  //           Email:<br>&emsp;
  //           <a href="mailto:${this.customerSupportMail}" target="_blank" class="hover:underline cursor-pointer">${this.customerSupportMail}</a>
  //         </p>
  //       </div>
  //       `,
  //     ],
  //     buttons: [{ content: 'Exit', emitValue: true }],
  //   };
  //   this.toggleMessageModal();
  // }


  /**
   * opens cancel subscription alert modal
   */
  public openConfirmationModal() {
    this.modalService.open('message-modal');
    this.modalService.close('subscription-plan');
  }


  /**
   * First Month - user can cancel the subscription as many times as he wants and amount will be refunded each time.If he cancels subscription from 4 h section, all will be locked
   * Second Month - if user cancels the subscription , no refund will be provided but he can login and access vault till the end of the second month. If he cancels subscription also,he can access all sections
   * Third Month - only after payment user will be allowed to login
   */
  public cancelSubscription() {
    this.cancelSubscriptionLoader = true;
    this.subscriptionService.applyRefund().subscribe({
      next: (response: APIResponseModel) => {
        this.isAlertShowed = true;
        this.modalService.close('subscription-plan');
        this.localStorageService.storeData('is_payment_complete', response.data.is_payment_complete);
        this.modalService.open('message-modal');
        this.cancelSubscriptionLoader = false;
      },
      error: (exception: any) => {
        this.cancelSubscriptionLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      },
    });
  }

  /**
   * Listener for Message Modal buttons.
   * @param{any} event
   */
  public buttonListener(event: any) {
    if (event) {
      this.clickEmitter.emit(true);
      this.cancelSubscription();
    } else {
      this.modalService.close('message-modal');
    }
  }


  /**
   * Toggle between Message Modal and Subscription Plan Modal
   */
  public toggleMessageModal() {
    const messageOpen = this.modalService.isOpen(this.messageModalID);
    const openModal = messageOpen ? 'subscription-plan' : this.messageModalID;
    const closeModal = !messageOpen ? 'subscription-plan' : this.messageModalID;
    this.modalService.close(closeModal);
    this.modalService.open(openModal);
  }


  /**
   * Just litens to toggle the message modal.
   * @param $event
   */
  messageModalListener($event: any) {
    if ($event) {
      this.toggleMessageModal();
    }
  }


  public getContactDetails() {
    this.commonService.getContactUsDetails().subscribe((response) => {
      this.customerSupportMail = response.data?.support_email;
      this.customerSupportPhone = response.data?.support_number;
    });
  }
}
