import {Component} from '@angular/core';
import {SlugInterceptorService} from 'src/app/helper/slug-interceptor.service';

@Component({
  selector: 'app-invalid-id',
  templateUrl: './invalid-id.component.html',
})

/**
 *
 */
export class InvalidIdComponent {
  /**
   * constructor
   */
  constructor(
    private slugInterceptorService: SlugInterceptorService,
  ) {

  }
  /**
   * change route
   * @param{string}url
    */
  public changeRoute(url:string) {
    void this.slugInterceptorService.navigate([ url], null, true);
  }
}

