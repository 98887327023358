import { Component, HostListener, OnInit } from '@angular/core';
import { CommonHelper } from '../../../helper/common.helper';
import { SlugInterceptorService } from '../../../helper/slug-interceptor.service';
import { CommonModelService } from '../../../services/common-model.service';
import { CommonService } from '../../../services/common.service';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-consumer-40-60-root',
  templateUrl: './consumer-40-60-root.component.html',
})
export class Consumer4060RootComponent implements OnInit {
  static isQuestionsShort: boolean;
  monthlyPricing: string;
  socialShareModalID: string = 'social-share-modal';
  private innerWidth: number;
  private menuActive: string;

  /**
   * constructor
   */
  constructor(
    private subscriptionService: ConsumerSubscriptionService,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    private commonService: CommonService,
    private modalService: CommonModelService,
  ) {
  }

  /**
   * isSmallWindow
   */
  get isSmallWindow(): boolean {
    return this.innerWidth < 1024;
  }

  ngOnInit(): void {
    this.updateInnerWidth();
    this.getPricing();
    Consumer4060RootComponent.isQuestionsShort = false;
  }

  get isQuestionsShort(): boolean {
    return Consumer4060RootComponent.isQuestionsShort;
  }

  /**
   * On Menu Change
   * @param{string}url
   */
  menuChange(url: string) {
    this.menuActive = url;
    void this.slugInterceptorService.navigateByUrl(url);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }

  @HostListener('window:resize', [])
  windowResizeListener() {
    this.updateInnerWidth();
  }

  showSocialShareModal() {
    this.modalService.open(this.socialShareModalID);
  }

  private updateInnerWidth() {
    this.innerWidth = window.innerWidth;
  }

  /**
   * Retrieves pricing details
   */
  private getPricing() {
    this.subscriptionService.getPricingDetails().subscribe({
      next: (response) => {
        if (response.status) {
          const packagePrice = { monthly: response.data.subscription_price };
          this.monthlyPricing = this.commonHelper.toCurrency(packagePrice.monthly);
        }
      },
    });
  }
}
