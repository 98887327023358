import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { SlugService } from 'src/app/services/slug.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-consumer-landing-navbar',
  templateUrl: './consumer-landing-navbar.component.html',

})
/**
 * consumer landing page navbar
 */
export class ConsumerLandingNavbarComponent implements OnInit, OnDestroy {
  public menuActive: string;
  public menuAlternate: boolean;
  public isFooterLink: boolean;
  public showGetStartedBtn: boolean = false;
  public responsiveMenuToggle: boolean;
  public orgLogo: string;
  public orgLogoPath: string;
  public videoModalID: string = 'consumer-navbar-video';
  private navbarSubscription: Subscription;

  /**
   * constructor
   */
  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private slugInterceptorService: SlugInterceptorService,
    private slugService: SlugService,
  ) {

  }

  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      }
    });
    this.responsiveMenuToggle = false;
    this.navbarSubscription = this.commonService.navbarObserve.subscribe((response) => {
      if (response) {
        this.menuActive = response;
      }
    });
    this.menuActive = this.route.snapshot.url[this.route.snapshot.url.length - 1]?.path;

    // Display the getStarted button if the user is on the landing or homepages,
    // regardless of whether a slug is present or not.
    const slug = this.route.snapshot.params['slug'];
    this.route.url.subscribe((url) => {
      if (url.length === 0 || url[url.length - 1]?.path === 'home' || url[url.length - 1]?.path === slug) {
        this.showGetStartedBtn = true;
      }
    });
    this.isFooterLink = true;
    this.menuAlternate = ['privacy-policy', 'acceptable-use-policy', 'digital-vault-agreement', 'digital-vault-licensing-agreement', 'user-agreement', 'california-privacy-policy', 'important-notice-to-digital-vault-holders'].indexOf(this.menuActive) < 0;
  }

  /**
   * @param{string}url
   * on menu change
   */
  public menuChange(url?: string) {
    this.menuActive = url;
    if (url === '') {
      void this.slugInterceptorService.navigate(['/']);
    }
    this.isFooterLink = false;

    void this.slugInterceptorService.navigate(url ? [url] : ['/']);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }

  /**
   * Display intro video modal
   */
  viewIntroVideo() {
    this.modalService.open(this.videoModalID);
  }

  /**
   * on login
   */
  public navigateToLogin() {
    void this.slugInterceptorService.navigate(['login']);
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.navbarSubscription?.unsubscribe();
  }
}
