import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';


@Component({
  selector: 'app-select-component',
  templateUrl: './select-component.component.html',
  styleUrls: ['./select-component.component.css'],
  host: {
    '(document:click)': 'closeDropDown($event)',
  },
})
/**
 * Select component
 */
export class SelectComponentComponent implements OnInit, OnChanges {
  @Input() options: Array<SelectOptionsInterface>;
  @Input() selectedOptionDefault: any;
  @Input() placeholder: string;
  @Input() year: boolean;
  @Input() ifError: boolean;
  @Input() input: boolean;
  @Input() disabled: boolean = false;
  @Output() optionChangeEmitter = new EventEmitter<SelectOptionsInterface>();
  public selectToggle: boolean;
  public selectedOption: SelectOptionsInterface = { displayText: '', value: '' };
  @Input() class: any;
  public enableError: boolean;
  @Output() toggleEmiter = new EventEmitter<boolean>(false); // Toggle listener used to listen dropdown open and close.
  public isVerifySecurityPage: boolean;

  /**
   * @constructor
   */
  constructor(private _eref: ElementRef,
    private localStorageService: LocalStorageService) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.selectToggle = false;
    this.optionChangeEmitter.emit(this.selectedOption);
    this.isVerifySecurityPage = this.localStorageService.getDataByKey('is_verify_security_page');
  }

  /**
   * called when changes occur
   *
   * @param {SimpleChanges} change
   */
  ngOnChanges(change: SimpleChanges): void {
    if (change?.selectedOptionDefault?.currentValue || change?.options?.currentValue) {
      this.selectedOption = this.options.find(e => e.value == this.selectedOptionDefault?.toString()) ?? { displayText: '', value: '' };
    } else {
      // Clear selected option
      this.selectedOption = { displayText: undefined, value: undefined };
    }
    this.enableError = this.ifError;
  }

  /**
   * On select change
   *
   * @param {number} index
   */
  public onSelectChange(index: number): void {
    this.selectedOption = this.options[index];
    this.selectToggle = false;
    this.optionChangeEmitter.emit(this.selectedOption);
  }

  /**
   * Triggered when click outside a element
   *
   * @param {Event} event
   */
  public closeDropDown(event: Event): void { // Called when component loading and click outside.
    if (!this._eref.nativeElement.contains(event.target)) {
      this.selectToggle = false;
      this.optionChangeEmitter.emit(this.selectedOption);
    }
  }

  /**
   * dropdown event
   */
  public clickDropdown() {
    this.selectToggle = !this.disabled ? !this.selectToggle : false;
    if (this.selectToggle) {
      this.toggleEmiter.emit(true); // toggle opened
    } else {
      this.optionChangeEmitter.emit(this.selectedOption);
    }
  }
}
