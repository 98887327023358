import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { FileValidationOptionsInterface, ICountryCode, SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { AgentsService } from 'src/app/professional-dashboard/services/agents.service';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { CommonFormControlService } from 'src/app/services/common-form-control.service';
import { CommonService } from 'src/app/services/common.service';
import { PeopleService } from 'src/app/services/people.service';
import { EMAIL_PATTERN, NAME_PATTERN, PHONE_PATTERN, UNITED_KINGDOM, USER_TYPES } from 'src/constants/application.const';
import { CLEAR_SELECTED_FILE } from 'src/constants/digitalFile.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../../../helper/validation.helper';
import { LocalStorageService } from '../../../../../services/local-storage.service';
@Component({
  selector: 'app-add-professional-partner',
  templateUrl: './add-professional-partner.component.html',
  styleUrls: ['./add-professional-partner.component.css'],
})

/**
 * add profesional partner Component
 */
export class AddProfessionalPartnerComponent implements OnInit {
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;

  public agentForm: FormGroup;
  public countryCode: ICountryCode;
  public preDefinedLoader: boolean;
  public loginLoader: boolean;
  public asterisk: any;
  public currentFile: any;
  public fileValidationOptions: FileValidationOptionsInterface;
  public imageFormatFiltering: Array<string>;
  public advisorTypes: Array<any>;
  public downloadLink: string;
  public downloadFileName: string;
  public userType: string;
  public file: any;
  public defaultCountry: number;
  public selectedCountry: number;
  public agentTerm: string;
  public selectedCountryName: string = '';
  public unitedKingdom: string = UNITED_KINGDOM;
  public roleType: string = '';
  public advisorId: string = '';
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;


  /**
   * constructor
   */
  constructor(
    private form: FormBuilder,
    private router: Router,
    private agentService: AgentsService,
    private toastr: ToastrService,
    private commonHelper: CommonHelper,
    private commonService: CommonService,
    private localStorageService: LocalStorageService,
    private addressHelper: AddressHelper,
    private slugInterceptorService: SlugInterceptorService,
    private peopleService: PeopleService,
    private ngZone: NgZone,
    private commonFormControlService: CommonFormControlService,
    private professionalUserService: ProfessionalUserService,
    private validation: ValidationHelper,
  ) { }
  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.selectedCountry = environment.APP_DOMAIN;
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    console.log('country', this.countryCode);
    this.preDefinedLoader = false;
    this.loginLoader = false;
    this.asterisk = faAsterisk;
    this.downloadLink = '';
    this.downloadFileName = '';
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    // If search element not loaded initially
    setTimeout(() => {
      this.initMap(this.defaultCountry);
    }, 0);
    this.userType = this.localStorageService.getDataByKey('role');
    // this.agentForm.reset();
    // file valiadtion options
    this.fileValidationOptions = {
      size: 100000,
      fileFormat: [
        'jpeg',
        'jpg',
        'png',
        'doc',
        'docx',
        'txt',
        'pdf',
        'mp3',
        'mp4',
        'avi',
        'mov',
        'flv',
        'mkv',
      ],
    };
    this.imageFormatFiltering = ['jpeg', 'jpg', 'png'];

    // add agent form
    this.agentForm = this.form.group({
      first_name: [
        '',
        {
          updateOn: 'blur', validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
          ],
        },
      ],
      middle_name: [
        '',
        {
          updateOn: 'blur', validators: [
            Validators.maxLength(50),
            Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
          ],
        },
      ],
      last_name: [
        '',
        {
          updateOn: 'blur', validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
          ],
        },
      ],
      manager_term: [
        '',
        {
          updateOn: 'blur', validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
          ],
        },
      ],
      phone: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(PHONE_PATTERN)] }],
      email: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(EMAIL_PATTERN)] }],
      street_address: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(255), this.validation.fn.trim] }],
      city: ['', Validators.required, this.validation.noWhitespaceValidator()],
      country: ['', { updateOn: 'blur', validators: [Validators.required] }],
      state: ['', { updateOn: 'blur', validators: [Validators.required] }],
      zipcode: [
        '',
        {
          updateOn: 'blur', validators: [
            Validators.required,
            Validators.maxLength(10),
          ],
        },
      ],
      professional_type: ['', { updateOn: 'blur', validators: [Validators.required] }],
      role_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
      type: ['manager'],
      created_role_type: [''],
    });
    this.validation.setValidationErrors({
      phone: { pattern: 'Please enter a valid phone number' },
      email: { pattern: 'Please enter a valid email address.' },
      zipcode: { pattern: 'Please enter a valid zipcode' },
      city: { whitSpaceValue: 'Please enter valid character'}
    });
    this.agentForm.patchValue({
      country: this.defaultCountry,
    });
    this.commonService.fileHanlderObservableEvents({
      type: CLEAR_SELECTED_FILE,
    });

    // Set validator if domain country is not UK
    this.isEnableState();
    this.getAdvisorTypes();

    // get professional types
    this.getProfessionalTypes();

    // types of advisors
    this.roleType = this.localStorageService.getUserData(USER_TYPES.pro).user.professional_type_name;
  }
  /**
   * form object getter for validation and showing errors in html
   */
  public get formGet() {
    return this.agentForm.controls;
  }

  /**
   * Detect change on phone number component
   *
   * @param {any} inputObject
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }

  /**
   * Option change emitter listner
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitterAdvisorType(selectedOption: SelectOptionsInterface): void {
    if (selectedOption) {
      this.agentForm.patchValue({
        professional_type: selectedOption.value,
      });
    }
  }
  /**
   * get advisor types
   */
  public getAdvisorTypes() {
    this.agentService.getAdvisorTypes(this.userType)?.subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.advisorTypes = this.commonHelper.convertToOptionsFormat(response?.data, 'id', 'name');
        const advisorTypeList = response?.data;
        const indexNumber = advisorTypeList?.findIndex((el) => el?.name === this.roleType);
        if (indexNumber !== -1) {
          this.agentForm.patchValue({
            professional_type: advisorTypeList[indexNumber].id,
          });
        }
      }
    });
  }
  /**
   * submit form
   */
  public onSubmit() {
    this.commonFormControlService.markFormGroupTouched(this.agentForm);
    if (this.agentForm.invalid) return;
    const payLoad = {
      ...this.agentForm.value,
      country_code: this.countryCode.dialCode,
      flag_code: this.countryCode.code,
      is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
      created_role_type: this.roleType === 'Law Firm' ? 'Manager' : this.roleType === 'Wealth Management' ? 'Project Manager' : 'Funeral Director',
    };
    this.loginLoader = true;
    payLoad.state = this.selectedCountryName == this.unitedKingdom ? null : payLoad.state;
    this.commonService.getCityDetail(payLoad.country, payLoad.state, payLoad.city).subscribe((cResponse: APIResponseModel) => {
      if (cResponse?.status) {
        payLoad.city = cResponse?.data?.id;
        const formData = new FormData();
        // appending every value to form object
        Object.keys(payLoad).forEach((key) => {
          if (!payLoad[key]) return;
          formData.append(key, payLoad[key]);
        });
        this.agentService.addAgent(formData).subscribe(
          (response) => {
            this.loginLoader = false;
            this.toastr.success(response?.message);
            this.agentForm.reset();
            this.commonService.fileHanlderObservableEvents({
              type: CLEAR_SELECTED_FILE,
            });
            void this.slugInterceptorService.navigate(['managers'], null, true);
          },
          (exception) => {
            this.loginLoader = false;

            this.commonHelper.httpResponseHandler(exception?.error);
          },
        );
      }
    }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error)
      , () => { });
  }


  /**
  * Initialize google map api by country
  * @param {any} countryId
  */
  public initMap(countryId: any): void {
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: (r) => {
        this.ngZone.run(() => {
          this.agentForm.patchValue({ ...r, street_address: r.address });
        });
      },
      error: (e) => console.error(e),
    });
  }

  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.agentForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }

  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(
    selectedCountry: SelectOptionsInterface,
  ): void {
    //
    if (!selectedCountry || this.preDefinedLoader) return;
    this.agentForm.controls.state.reset();
    this.agentForm.patchValue({
      country: selectedCountry.value,
    });
    this.addressHelper.removeAutocompletePacContainer();
    this.initMap(selectedCountry.value);
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.agentForm.controls.city.reset();
    this.agentForm.patchValue({
      state: selectedState.value,
    });
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.agentForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.agentForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([
        Validators.required,
        Validators.pattern(
          new RegExp(selectedCity?.additionalDetails[0]?.postal_code_regex),
        ),
      ]);
      zipCodeRef.updateValueAndValidity({ onlySelf: true });
    }
    // this loader is set false here because of the delay to set country state city
    this.preDefinedLoader = false;
  }
  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }
  /**
  * See {@link ValidationHelper.getErrors}
  * @param controlsKey
  */
  public getErrors(controlsKey: string, checkTouched?): Array<string> {
    return this.validation.getErrors(controlsKey, this.agentForm, checkTouched);
  }
  /**
   *
   */
  get state() {
    return this.agentForm.get('state');
  }
  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.agentForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ?
        this.state.addValidators([Validators.required]) : this.state.clearValidators();
      this.state.updateValueAndValidity();
    });
  }

  /**
   * change route
   * @param{string}url
    */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, true);
  }

  /**
  * get professional role types
  */
  public getProfessionalTypes() {
    this.peopleService.getProfessionalTypes(1)?.subscribe((response: APIResponseModel) => {
      if (response.status) {
        const findIndex = response?.data?.role.findIndex((el) => el.role_name === 'Partner');
        if (findIndex) {
          this.advisorId = response?.data?.role[findIndex].id;
          this.agentForm.patchValue({
            role_id: response?.data?.role[findIndex].id,
          });
        }
      }
    });
  }

  /**
  * get advisor term
  * @return{string}
  */
  public getAdvisorTerm() {
    return this.professionalUserService.getAdvisorTerm();
  }


  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.agentForm.get('zipcode');
    const stateId = this.agentForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        if (response.data.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(zipCodeRegex))]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        } else {
          zipCodeRef.setValidators([Validators.required]); zipCodeRef.updateValueAndValidity({ onlySelf: true });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }

  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }
}
