import { Component } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';


@Component({
  selector: 'app-consumer-features',
  templateUrl: './consumer-features.component.html',
})
export class ConsumerFeaturesComponent {
  public vaultVideo: string;

  /**
   * constructor
   */
  constructor(
    private modalService: CommonModelService
  ) { }
  
  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');
  }
}
