import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Params} from '@fortawesome/fontawesome-svg-core';
import {ToastrService} from 'ngx-toastr';
import {CommonHelper} from 'src/app/helper/common.helper';
import {SlugInterceptorService} from 'src/app/helper/slug-interceptor.service';
import {ChipsOptionsInterface} from 'src/app/interface/common.interface';
import {APIResponseModel, APIStatusCodes} from 'src/app/interface/response.interface';
import {ProfessionalUserService} from 'src/app/professional-dashboard/services/professional-user.service';
import {ORDER_VALUE, PAYMENT_TYPE, SELECT_VALUE} from 'src/constants/application.const';

@Component({
  selector: 'app-professional-payout',
  templateUrl: './professional-payout.component.html',
  styleUrls: ['./professional-payout.component.css'],
})
/**
 * professional payout class
 */
export class ProfessionalPayoutComponent implements OnInit {

  public menuActive: number;
  public currentPage = 1;
  public paymentCount = 5;
  public paymentOrder = 'desc';
  public searchKeyword = '';
  public index: number;
  public paymentType = 'new';
  public loader: boolean;
  public profileMenuToggle: boolean;
  public paymentData: any;
  public paginationLinks: Array<any>;
  public lastPage: number;
  public fromNo: number;
  public toNo: number;
  public selectOptions: Array<Object>;
  public orderOptions: Array<Object>;
  public paymentOptions: Array<Object>;
  public chipsList: Array<ChipsOptionsInterface>;
  public currentFilter: number;
  public toReload = false;

  /**
   * constructor
   */
  constructor(
    private route: ActivatedRoute,
    private professionalUserService: ProfessionalUserService,
    private router: Router,
    private toastr: ToastrService,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
  ) {}
  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParam: Params) => {
      this.menuActive = +queryParam['menu'];
    });
    this.selectOptions = SELECT_VALUE;
    this.orderOptions = ORDER_VALUE;
    this.paymentOptions = PAYMENT_TYPE;
    this.currentFilter = 1;
    this.menuChange(this.menuActive);
  }
  /**
   * on menu change
   * @param {number}menu
   */
  public menuChange(menu: number) {
    this.profileMenuToggle = false;
    this.menuActive = menu;
    void this.slugInterceptorService.navigate(['payout-management'], {
      queryParams: { menu: menu },
    },true);
    if (menu ===1) {
      this.searchKeyword='';
      this.paymentType='new';
      this.getPayoutDetails();
    } else {
      this.searchKeyword='';
      this.paymentType='history';
      this.getPayoutDetails();
    }
  }
  /**
   * get payout details
   */
  public getPayoutDetails() {
    this.profileMenuToggle = false;
    this.loader = true;
    this.professionalUserService
        .getPayoutDetails(
            this.paymentType,
            this.currentPage,
            this.paymentCount,
            this.paymentOrder,
            this.searchKeyword,
        )
        .subscribe((response: APIResponseModel) => {
          this.loader = false;
          this.paymentData = response.data.data;
          this.fromNo = response.data.from;
          this.toNo = response.data.to;
          this.paginationLinks = response.data.links;
          this.lastPage = response.data.last_page;
        });
  }
  /**
   * search
   * @param{string}keyword
   */
  public onSearch() {
    this.profileMenuToggle = false;
    this.loader = true;
    this.professionalUserService
        .getPayoutDetails(
            this.paymentType,
            this.currentPage,
            this.paymentCount,
            this.paymentOrder,
            this.searchKeyword,
        )
        .subscribe((response) => {
          this.loader = false;
          this.paymentData = response.data.data;
          this.fromNo = response.data.from;
          this.toNo = response.data.to;
          this.lastPage = response.data.last_page;
        });
  }
  /**
   * toggle dropdown
   * @param{number}ind
   */
  public toggleDropDown(ind: number) {
    this.profileMenuToggle = !this.profileMenuToggle;
    this.index = ind;
  }
  /**
 * payout data
 * @param {number}pageNo

 */
  public getPayoutDetailsByPage(pageNo?: number) {
    this.profileMenuToggle = false;
    if (pageNo) {
      this.currentPage = pageNo;
    }

    this.loader = true;
    this.professionalUserService
        .getPayoutDetails(
            this.paymentType,
            this.currentPage,
            this.paymentCount,
            this.paymentOrder,
            this.searchKeyword,
        )
        .subscribe((response) => {
          this.paymentData = response.data.data;

          this.paginationLinks = response.data.links;
          this.fromNo = response.data.from;
          this.toNo = response.data.to;
          this.lastPage = response.data.last_page;
          this.loader = false;
        });
  }
  /**
   * pay for vaults created
   * @param {string}id
   */
  public payment(id:string) {
    this.profileMenuToggle=false;
    const payload = { amount: '100', currency: 'usd', invoice_id: id };
    this.professionalUserService.payInvoice(payload).subscribe((response:APIResponseModel) => {
      if (response.status) {
        window.open(response.data, '_blank');
      }
    },
    (exception: any) => {
      if (exception?.error?.statusCode === APIStatusCodes.BAD_REQUEST)
        this.getPayoutDetails();
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }
  /**
   * reload page
   */
  reloadPage(): void {
    window.location.reload();
  }
  /**
 * ng on destroy
 */
  ngOnDestroy(): void {
    window.removeEventListener('focus', this.reloadPage);
  }
}
