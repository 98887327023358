import { Component, Input, OnInit } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';

@Component({
  selector: 'app-view-detailed-info-modal',
  templateUrl: './view-detailed-info-modal.component.html',
  styleUrls: ['./view-detailed-info-modal.component.css']
})
export class ViewDetailedInfoModalComponent implements OnInit {
  @Input() viewModalData: any;
  @Input() viewModalHeading:string

  constructor(public modalService: CommonModelService,) { }

  ngOnInit(): void {
  }

  /**
   * Closes modal
   */
  public closeModal(): void {
    this.modalService.close('profile-view');
  }

}
