import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PeopleService } from 'src/app/services/people.service';
import { CommonHelper } from '../../helper/common.helper';
import { CommonModelService } from '../../services/common-model.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { PersonalDetailsService } from '../../services/personal-details.service';
import { AssignBeneficiaryEmitInterface, AssignBeneficiarySettings } from '../assign-beneficiary-modal/assign-beneficiary-interfaces';
import { LPSettingsInterface, LPUserPeopleInterface } from '../list-people/list-people-interfaces';
import { ListPeopleComponent } from '../list-people/list-people.component';


@Component({
  selector: 'app-select-beneficiary-ratio',
  templateUrl: './select-beneficiary-ratio.component.html',
})
export class SelectBeneficiaryRatioComponent implements OnInit {
  // Inputs / Outputs
  @Input() set editData(beneficiaries: Array<Object>) {
    if (beneficiaries?.length > 0) {
      let data: {
        1: { user_people_id: string, percentage: number }[],
        2: { user_people_id: string, percentage: number }[]
      } = { 1: [], 2: [] };

      // Separate beneficiaries based on type (primary/secondary)
      beneficiaries.forEach(b => data[b['type']].push({ id: b['user_people_id'], percentage: b['percentage'] }));
      Object.values(this.TYPE).forEach(t => this.setBeneficiaries(data[t], t));
    }
  }

  @Input() canEdit: boolean = false;
  @Input() vaultOwnerSelectedAsBeneficiary: string;
  @Input() userShownAsBenificiary:number = 0;
  @Output() selectBeneficiaryRatioEmitter: EventEmitter<SelectBeneficiaryRatioEmitModel> = new EventEmitter();
  @Output() isRemainingToAllocateEmitter: EventEmitter<Array<ChosenBeneficiaryEntry>> = new EventEmitter();
  // General fields
  private requestID: string;
  readonly TYPE = { primary: 1, secondary: 2 };
  readonly TYPE_STRING = { 1: 'primary', 2: 'secondary' };

  // Select Beneficiary fields
  selectedType: number;
  private beneficiariesRAW: Array<Object>;
  private chosenBeneficiaries: ChosenBeneficiariesModel = { 1: [], 2: [] };

  // Assign Beneficiary Modal
  assignBeneSettings: AssignBeneficiarySettings = { user_people: [], allow_zero: true };
  assignBeneModalID = 'assign-beneficiaries(select)';

  // Include current user in beneficiary list
  public userData: Object = {}; 

  // List People Component
  lpSettings: LPSettingsInterface = {
    isLoading: true,
    rightContent: { show: true, innerHTML: 'Inner Text' },
  };
  userPeople: { 1: Array<LPUserPeopleInterface>, 2: Array<LPUserPeopleInterface> };
  public chosenBeneficiay: Array<ChosenBeneficiaryEntry> = [];

  constructor(
    private personalDetailsService: PersonalDetailsService,
    private modalService: CommonModelService,
    private commonHelper: CommonHelper,
    private peopleService: PeopleService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.requestID = this.commonHelper.getRequestId(this.route);
    this.userData = this.localStorageService.getUserData();
    this.getUserPeople();

    // Reset chosenBeneficiaries and userPeople arrays upon subscription
    this.peopleService.resetChosenBenefObserve.subscribe((response)=>{
      if(response){
        this.chosenBeneficiaries  = { 1: [], 2: [] };
        this.userPeople =  { 1: [], 2: [] };
      }
    })
  }

  /**
   * detect on changes
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges) {
    // Vault owner whether needs in beneficiary ratio
    if(changes.vaultOwnerSelectedAsBeneficiary?.currentValue || changes.vaultOwnerSelectedAsBeneficiary?.previousValue) {
      this.getUserPeople();
    }
  }

  /**
   * Calls server to get user_people data for this Vault Holder.
   * @private
   */
  private getUserPeople() {
    const userType = this.localStorageService.getDataByKey('role');
    const isConsumer = userType === 'Consumer';
    this.lpSettings.isLoading = true;
    this.personalDetailsService.getUserPeople(!isConsumer ? this.requestID : undefined,this.userShownAsBenificiary).subscribe({
      next: r => {
        this.beneficiariesRAW = r.data.user_people.filter(e => e['beneficiary'] == 1);
        if(this.vaultOwnerSelectedAsBeneficiary) {
          const userActAsBeneficiaryIndex = this.beneficiariesRAW.findIndex(e => e['email'] === this.userData?.['user']?.['email']);
          this.beneficiariesRAW.splice(userActAsBeneficiaryIndex, 1);
          this.prepareBeneficiaryDetails(this.selectedType);
        }
        this.lpSettings.isLoading = false;
        this.updateLists();
      },
      error: () => this.lpSettings.isLoading = false,
    });
  }

  /**
   * Prepare {@link assignBeneSettings} type and user_people.
   * @private
   */
  private prepareBeneficiaryDetails(type: number) {
    const otherType = type == this.TYPE.primary ? this.TYPE.secondary : this.TYPE.primary;
    const beneficiaries = this.beneficiariesRAW.filter(e => !this.chosenBeneficiaries?.[otherType]?.find(b => b.user_people_id == e['id']));
    this.assignBeneSettings.type = type;
    this.assignBeneSettings.user_people = ListPeopleComponent.getLPUserPeople(beneficiaries);
    this.assignBeneSettings.user_people.forEach(e => {
      const chosen = this.chosenBeneficiaries[type]?.find(c => c.user_people_id == e.id);
      if (chosen) {
        e.checkbox.isChecked = true;
        e.textInput.text = chosen?.percentage;
      }
    });
    // Force change detection
    this.assignBeneSettings = { ...this.assignBeneSettings };
  }

  /**
   * Listener Function for {@link AssignBeneficiaryModalComponent}.
   * @param $event
   */
  public listenerAssignBeneficiaries($event: AssignBeneficiaryEmitInterface) {
    if ($event.trigger_refresh) {
      this.getUserPeople();
    }
    if ($event.user_people) {
      this.setBeneficiaries($event.user_people, $event.type);
      this.emitBeneficiaries();
    }
  }

  /**
   * Emit the chosen beneficiaries' data. This should happen anytime a change is made.
   * @private
   */
  private emitBeneficiaries() {
    let beneficiaries: SelectBeneficiaryRatioEmitModel = { 1: [], 2: [] };
    Object.values(this.TYPE).forEach(t => {
      this.chosenBeneficiaries[t].forEach(chosen => {
        beneficiaries[t].push({
          user_people_id: chosen.user_people_id,
          percentage: chosen.percentage,
          type: t,
        });
      });
    });
    this.selectBeneficiaryRatioEmitter.emit(beneficiaries);
  }

  /**
   * Assign beneficiaries info. Will call {@link updateLists}.
   * @param data
   * @param type
   */
  private setBeneficiaries(data: Array<{ id: string, percentage: number }>, type: number) {
    const beneficiaries: Array<{ user_people_id: string, percentage: number }> = [];
    data.forEach(e => beneficiaries.push({ user_people_id: e.id, percentage: e.percentage }));
    this.chosenBeneficiaries[type] = beneficiaries;

    // If removed primaries but secondaries exist, turn the secondaries into primaries.
    if (this.chosenBeneficiaries[this.TYPE.primary].length == 0 && this.chosenBeneficiaries[this.TYPE.secondary].length > 0) {
      this.chosenBeneficiaries[this.TYPE.primary] = this.chosenBeneficiaries[this.TYPE.secondary];
      this.chosenBeneficiaries[this.TYPE.secondary] = [];
    }

    this.updateLists();
  }

  /**
   * Update display detail lists for beneficiaries
   * @private
   */
  private updateLists() {
    this.userPeople = { 1: [], 2: [] };
    Object.values(this.TYPE).forEach(t => {
      if (this.chosenBeneficiaries[t].length > 0 && this.beneficiariesRAW?.length > 0) {
        this.chosenBeneficiaries[t].forEach(chosen => {
          let person = this.beneficiariesRAW.find(person => person['id'] == chosen?.user_people_id);
          if (person) {
            person = ListPeopleComponent.getLPUserPeople(person)[0];
            (person as LPUserPeopleInterface).rightContent = { innerHTML: `${chosen.percentage}%` };
            this.userPeople[t].push(person);
          }
        });
      }
      this.checkIsFullyAllocated(t);
    });
    if(this.modalService.isOpen(this.assignBeneModalID)){
      this.prepareBeneficiaryDetails(this.selectedType);
    }
  }

  /**
   * view primary percentage details
   */
  public viewBeneficiaryDetails(type: number) {
    if (this.canEdit && this.canViewDetails(type)) {
      this.selectedType = type;
      this.prepareBeneficiaryDetails(type);
      this.modalService.open(this.assignBeneModalID);
    }
  }

  /**
   * Whether the beneficiary details can be viewed/edited for a certain type.<br>
   * Always returns true where <code>type == {@link PRIMARY}</code>. <br>
   * Returns true if <code>type == {@link SECONDARY}</code> and at least one primary beneficiary has been chosen.
   * @param type
   * @private
   */
  private canViewDetails(type: number): boolean {
    return type == this.TYPE.primary ||
      (type == this.TYPE.secondary && this.chosenBeneficiaries[this.TYPE.primary].length > 0);
  }
    /**
   * Vault holder beneficiary removed from list means update chosen list
   * @param array 
   * @returns chosen beneficiary 
   */
  private updateChosenBeneficiary(array: []): Array<any> {
    return array.filter(user => user['user_people_id'] !== this.vaultOwnerSelectedAsBeneficiary)
  }
  /**
   * check the ratio allocation must not be partially allocated
   * @param type 
   */
  private checkIsFullyAllocated(type: number) {
    let count = 0;
    this.chosenBeneficiaries[type] = this.updateChosenBeneficiary(this.chosenBeneficiaries[type]);
    const chosenBeneficiaries = this.chosenBeneficiaries[type];
    chosenBeneficiaries?.forEach(user => {
      count += user?.['percentage']
      this.chosenBeneficiay[type] = {percentage: count, chosen_type : type};
    })
    this.isRemainingToAllocateEmitter.emit(this.chosenBeneficiay)
  }
}


export interface ChosenBeneficiaryEntry {
  user_people_id?: string;
  percentage: number;
  chosen_type?: number;
}

interface ChosenBeneficiariesModel {
  1: Array<ChosenBeneficiaryEntry>;
  2: Array<ChosenBeneficiaryEntry>;
}


export interface SelectBeneficiaryRatioEmitModel {
  1: Array<{ user_people_id: string, percentage: number, type: number }>;
  2: Array<{ user_people_id: string, percentage: number, type: number }>;
}
