import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * clients service
 */
export class ChatService {
  private BASE_URL: string;
  private emitChangeSource: any;
  public changeEmitted: Observable<boolean>;


  /**
 * constructor
 */
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.BASE_URL;
    this.emitChangeSource = new BehaviorSubject(false);
    this.changeEmitted = this.emitChangeSource.asObservable();
  }
  /**
   *
   * emit changes
   * @param {any} change
   */
  public emitChange(change: boolean) {
    this.emitChangeSource.next(change);
  }

  /**
   * @return {Observable}
   * get professional details
   */
  public professionalDetails(): Observable<APIResponseModel> {
    // return this.http.get<API_RESPONSE_INTERFACE>(`${this.BASE_URL}/professional-details`);
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/user/get-professional-agents`);
  }

  /**
   * Get selected professional's chat list
   * @param{string}professionalId
   * @param {number} userRequest
   * @return {Observable}
   */
  public getProChatList(professionalId: string, userRequest: number): Observable<APIResponseModel> {
    const url = userRequest >= 2 ? `${this.BASE_URL}/get-professional-chats/${professionalId}?page=${userRequest}` : `${this.BASE_URL}/get-professional-chats/${professionalId}`;
    return this.http.get<APIResponseModel>(url);
  }

  /**
     * file upload
    * @param {any} data
    *
    * @return {Observable}
    */
  public onUploadFiles(data: any): Observable<any> {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (!data) return;
      if (data[key] instanceof Array) {
        data[key].forEach((item) => {
          formData.append(`${key.toString()}[]`, item);
        });
      } else {
        formData.append(key.toString(), data[key]);
      }
    });
    const req = new HttpRequest('POST', `${this.BASE_URL}/upload-chat-file`, formData);

    return this.http.request(req);
  }

  /**
     * chat file download - image/documents
     *
    * @param {string} id
    * @return {Observable}
    */
  public fileDownload(id: string): Observable<any> {
    return this.http.get(`${this.BASE_URL}/download-chat-file/${id}`, { responseType: 'blob' });
  }
}
