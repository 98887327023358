// api response interface
export interface APIResponseModel<T=any> {
  status: boolean,
  message: string,
  statusCode: APIStatusCodes
  data: T
}
export interface APIErrorModel {
  status: boolean,
  message: string | { [p: string]: string[] },
  // eslint-disable-next-line camelcase
  statusCode: APIStatusCodes
  data: any
}


export enum APIStatusCodes {
  SUCCESS = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  UNPROCESSABLE_CONTENT = 422,
  LOCKED = 423,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504
}
