<!-- LN User Agreement (v1.0 110923) -->
<!-- BEGIN: Navbar Wrapper -->
<app-consumer-landing-navbar></app-consumer-landing-navbar>
<!-- END: Navbar Wrapper -->
<main>

  <div class="p-12 lg:px-24 lg:py-32 container mx-auto">
    <div class="title__wrap text-center py-8">
      <h1 class="text-semibold text-accent fs-title pb-2">LegacyNOW</h1>
      <h2 class="text-semibold text-primary fs-title">User Agreement</h2>
    </div>
    <div>
      <p>
        Welcome to the LegacyNOW website, located at www.estate-registry.com (the “<u>Website</u>"), which is owned and
        operated by Estate Resolution Technologies (US) LLC, doing business as “LegacyNOW” (the "<u>Company</u>").
      </p>
      <p>
        The Company provides digital vault services in the United States through the use of the Website to, among other
        things, provide for the secure storage of personal, financial and other information of the vault holder. In
        addition, the Digital Vault Service offered by the Company facilitates data management for financial and estate
        planning and for the resolution of the estate upon the passing of the vault holder. The digital vault services
        provided by the Company, as explained above, are referred to in this User Agreement as its “<u>Digital Vault
        Services</u>”.
      </p>
      <p>
        The Website, the Content of the Website (as hereinafter defined) and the Digital Vault Services are provided to
        you subject to your agreement to be bound by the terms and conditions of use ("Terms of Use") of this User
        Agreement. <b>BY ACCESSING OR USING THE WEBSITE OR THE DIGITAL VAULT SERVICES, YOU AGREE TO BE BOUND BY THESE
        TERMS OF USE. IF YOU DO NOT AGREE WITH ONE OR MORE OF THESE TERMS OR USE, DO NOT ACCESS OR USE THE WEBSITE OR
        THE DIGITAL VAULT SERVICES.</b>
      </p>
      <p>
        The Website is intended for individuals who are at least 18 years of age and are accessing the internet from a
        physical location within the United States. By using or accessing the Website, you acknowledge that you are 18
        years of age or older and are accessing the internet from a physical location within the United States.
      </p>

      <h4>
        Terms of Use
      </h4>
      <p>
        These Terms of Use constitute a legal agreement between you and the Company concerning the provision and use of
        the Digital Vault Services and including access to and use of the Website and any related data sharing,
        functionalities and services provided or performed in connection therewith. These Terms of Use set out the rules
        governing the provision and use of the Digital Vault Services, including your access and utilization of the
        Website. Please note that the Company reserves the right to amend these Terms of Use in the future from time to
        time.
      </p>

      <h4>
        Use of the Website
      </h4>
      <p>
        All text, graphics, photographs, videos, sound, trademarks, logos, artwork, interfaces and computer code,
        including but not limited to the design, coordination, “look and feel” and arrangement of elements contained on
        the Website (collectively “Content”) is owned or licensed by or to the Company. The Company grants you a
        limited, non-exclusive, personal, non-transferable license to access and use the Website for personal
        non-commercial purposes. The Company reserves all rights, title and interest not expressly granted under these
        Terms of Use to the fullest extent permitted under applicable laws. Except as expressly provided in these Terms
        of Use, any reproduction, copy, retransmission, distribution, sale, republication, modification, translation of
        or creation of derivative works based on the Website, the Content of the Website and/or the Digital Vault
        Services, in whole or in part, is strictly prohibited. You acquire absolutely no rights or licenses to the
        Website, the Content of the Website or the Digital Vault Services other than the limited right to use the
        Website, the Content of the Website and the Digital Vault Services in accordance with these Terms of Use and
        your Digital Vault Agreement.
      </p>
      <p>
        In connection with your use of the Website, you cannot: (i) reverse engineer, decompile or disassemble any
        portion of the Website, except where such restriction is expressly prohibited by applicable law; (ii) remove any
        copyright, trademark or other proprietary rights notice from the Website; (iii) frame the Website, the Content
        of the Website or any part thereof on any commercial or non-commercial internet web site; (iv) systematically
        download and store the Website, the Content of the Website; or (v) use any robot, spider, site search/retrieval
        application or other manual or automatic device to retrieve, index, “scrape,” “data mine” or otherwise gather
        the Website, the Content of the Website or the Digital Vault Services, or reproduce or circumvent the
        navigational structure or presentation of the Website.
      </p>
      <p>
        The Company may terminate or suspend your use of the Website or the Digital Vault Services immediately at any
        time and without prior notice, for any or no reason, including if it believes that you have violated or acted
        inconsistently with the provisions of these Terms of Use.
      </p>
      <p>
        Specific terms and conditions may apply to specific Content of the Website and/or the Digital Vault Services and
        such specific terms are in addition to these Terms of Use and may, to the extent expressly specified therein,
        supersede these Terms of Use.
      </p>

      <h4>
        New Services and Modifications and Updates
      </h4>
      <p>
        Whenever new and/or modified services are introduced on the Website as part of the Digital Vault Services, your
        use of such new or modified services will be subject to these Terms of Use unless the Company notifies you
        otherwise. The Company reserves the right, in its sole discretion, to modify or update these Terms of Use at any
        time and from time to time. The Company will notify you of any changes to these Terms of Use by notice on this
        Website. Your continued use of this Website after following notice of such change means that you agree to and
        accept these Terms of Use as amended. If you do not agree to any modifications of these Terms of Use, you must
        immediately stop using this Website.
      </p>

      <h4>
        Authorization, Representations and Security
      </h4>
      <p>
        As a user of this Website and/or the Company’s Digital Vault Services, you represent to the Company that:
      </p>
      <ol class="list-lower-alpha">
        <li>
          you have all requisite legal right, power, and authority to enter into these Terms of Use, submit information
          on the Website and/or deposit information into your vault.
        </li>
        <li>
          you agree that any information you submit to the Company on or through the Website about yourself or any other
          related matter is and will be at the time provided true, accurate, correct, and complete and you further agree
          to promptly inform the Company us of any material changes in such information and to keep all such information
          current and accurate.
        </li>
        <li>
          you authorize the Company to directly or through its agents or representatives make any inquiries or conduct
          investigations that it considers necessary to verify your identity and or protect against fraud, including to
          investigate identification information and to take any action the Company reasonably deem necessary based on
          the results of any such inquiries, investigation or the information received as a result thereof, including,
          without limitation, any response reasonably necessary in connection with such inquiries, investigations or
          requests for information; and
        </li>
        <li>
          you acknowledge and agree that with respect to any other person, executor, administrator or other personal
          representative or individual (collectively, “<u>Third Parties</u>”) you have the requisite right, power and
          authority to grant them access to the Website and/or your Digital Vault and have informed each of any such
          Third Parties of the obligations under these Terms of Use as well as the sharing of the data submitted on the
          Website in accordance with the Company’s
          <a class="link" (click)="changeRoute('privacy-policy')">Privacy Policy</a>. NOTWITHSTANDING THE
          ABOVE, THE COMPANY STRONGLY ADVISES YOU NOT TO SHARE PASSWORDS OR GRANT ACCESS TO YOUR DIGITAL VAULT EXCEPT AS
          YOU BELIEVE NECESSARY FOR YOUR FINANCIAL OR OTHER ESTATE PLANNING PURPOSES. IF YOU BELIEVE AN UNAUTHORIZED
          PERSON HAS ACCESS TO YOUR VAULT OR YOUR PASSWORDS YOU MUST CONTACT US IMMEDIATELY AND CHANGE ANY COMPROMISED
          PASSWORDS.
        </li>
      </ol>

      <h4>
        Privacy and Data Sharing
      </h4>
      <p>
        BY ACCESSING AND USING THIS WEBSITE, INCLUDING THE CONTENT OF THE WEBSITE OR DIGITAL VAULT SERVICES, YOU ARE
        CONSENTING TO THE COLLECTION, USE AND DISCLOSURE OF YOUR PERSONAL INFORMATION IN ACCORDANCE WITH THESE TERMS OF
        USE AND THE COMPANY’S <a class="link" (click)="changeRoute('privacy-policy')">Privacy Policy</a>.
      </p>

      <h4>
        User Information
      </h4>
      <p>
        Subject to the Privacy Policy, should you provide electronically or through your access to or usage of the
        Website or the Digital Vault Services, any information or materials including feedback data, such as questions,
        comments, suggestions or the like regarding any information contained on the Website, such information and/or
        materials provided by you shall be deemed to be non-confidential and the Company shall have no obligation of any
        kind with respect to such information and/or materials and shall be free to reproduce, use, disclose and
        distribute such information and/or materials to others without limitation. You hereby grant to the Company a
        transferable world-wide perpetual, irrevocable, royalty-free, non-exclusive license, including the right to
        sublicense to others, to use, reproduce, distribute, publish, modify, edit, translate, transmit, adapt and
        display any information and/or materials provided by you (in whole or in part) and/or incorporate such
        information and/or materials in any form, media or technology now known or developed in the future.
      </p>

      <h4>
        Scope of the Digital Vault Services
      </h4>
      <p>
        The scope of the Digital Vault Services to be provided by the Company are as described in these Terms of Use,
        your Digital Vault Agreement, the Website’s Privacy Policy, and otherwise by the Content of the Website.
      </p>

      <h4>
        Disclaimer of Warranties and Liability
      </h4>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE DIGITAL VAULT SERVICES OFFERED ON THIS WEBSITE ARE
        PROVIDED "AS IS", WITH NO REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED
        BY APPLICABLE LAW, THE COMPANY AND/OR ITS SUPPLIERS DISCLAIM ALL WARRANTIES, REPRESENTATIONS AND CONDITIONS OF
        ANY KIND WITH RESPECT TO THE WEBSITE, THE CONTENT OF THE WEBSITE AND THE DIGITAL VAULT SERVICES WHETHER EXPRESS,
        LEGAL, IMPLIED OR STATUTORY, ARISING FROM COURSE OF DEALING, USAGE OR TRADE, INCLUDING, BUT NOT LIMITED TO,
        WARRANTIES AND CONDITIONS OF MERCHANTABILITY, QUALITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR
        NON-INFRINGEMENT. USE OF THIS WEBSITE, THE CONTENT OF THE WEBSITE AND THE DIGITAL VAULT SERVICES IS AT YOUR OWN
        RISK. NEITHER THE COMPANY NOR ITS SUPPLIERS MAKE ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS ABOUT THE
        QUALITY, ACCURACY, RELIABILITY, COMPLETENESS, CURRENCY, OR TIMELINESS OF THE WEBSITE, THE CONTENT OF THE WEBSITE
        OR THE DIGITAL VAULT SERVICES. THE COMPANY AND/OR ITS SUPPLIERS DO NOT ASSUME ANY RESPONSIBILITY FOR ANY ERRORS,
        OMISSIONS OR INACCURACIES IN THE WEBSITE, THE CONTENT OF THE WEBSITE OR THE DIGITAL VAULT SERVICES. TO THE
        MAXIMUM EXTENT SUCH LIMITATION IS PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THE COMPANY AND/OR ITS SUPPLIERS
        BE LIABLE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, NEGLIGENCE, CIVIL LIABILITY, STRICT LIABILITY OR ANY OTHER
        LEGAL THEORY, FOR ANY DAMAGES OF ANY KIND (INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL,
        CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE DAMAGES, LOST PROFITS, LOSS OF USE, LOSS OF DATA, PERSONAL INJURY,
        FINES, FEES, PENALTIES OR OTHER LIABILITIES), WHETHER OR NOT THE COMPANY OR ITS SUPPLIERS ARE ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES, RESULTING FROM THE USE OF, OR THE INABILITY TO MAKE USE OF, THE WEBSITE, THE
        CONTENT OF THE WEBSITE OR THE DIGITAL VAULT SERVICES.
      </p>
      <p>
        THE CONTENT OF THE WEBSITE IS PROVIDED FOR INFORMATIONAL PURPOSES ONLY AND IS NOT INTENDED TO PROVIDE FINANCIAL,
        TAX OR ESTATE PLANNING ADVICE TO YOU AND YOU SHOULD NOT RELY UPON THE CONTENT OF THE WEBSITE TO PROVIDE ANY SUCH
        ADVICE. YOU SHOULD SEEK THE ADVICE OF PROFESSIONALS REGARDING SUCH MATTERS.
      </p>

      <h4>
        Trademarks and Copyright
      </h4>
      <p>
        The use or reproduction of certain names, words, titles, phrases, logos, services or the trademarks, trade
        names, copyright or other intellectual property rights of the Company or its licensors is strictly prohibited,
        and the Company reserves the right to take such steps as it deems necessary, including legal action to enforce
        such rights to the Content of the Website, and any other signs, graphics, icons, and marks displayed on the
        Website or used in connection with the provision of the Digital Vault Services by the Company may constitute
        trademarks or trade names of the Company or its licensors. Title to the Website, the Content of the Website and
        the trademarks or trade names displayed on the Website remains with the Company and/or its licensors. Nothing
        contained in these Terms of Use or on the Website shall be construed as conferring by implication, estoppel or
        otherwise any license or right to use any trademarks, tradenames, copyright or other intellectual property
        rights of the Company or its licensors. Any unauthorized use of the Website or the Content of the Website is
        strictly prohibited.
      </p>

      <h4>
        Conformance with Law
      </h4>
      <p>
        You agree to use the Website, the Content of the Website, and the Digital Vault Services for lawful purposes
        only and in a manner consistent with all applicable United States, state, local, or other applicable laws, rules
        and regulations. The Website is intended for users located in the United States. Potential users in any other
        jurisdiction where laws (whether common or statutory) would: (i) void these Terms of Use in whole or in any
        essential part (the essential parts being at least, but not only, the provisions relating to the governing law
        and the disclaimer and limitation of liability); or (ii) render accessing the Website or the Content of the
        Website, or using the Digital Vault Services, illegal, are not authorized to use the Website, the Content of the
        Website or the Digital Vault Services.
      </p>

      <h4>
        Policy on Links to Third Party Sites
      </h4>
      <p>
        The Website may contain links to third party web sites. The Company does not represent, guarantee, or endorse
        any web site that you may access from this Website. In addition, if the Company provides a link to a web site,
        the Company does not represent, guarantee, or endorse the company or any of its offerings. Links contained on
        this Website are provided solely as a convenience to you. When you access a non-Company web site, please
        understand that the linked site and its content and the third party’s practices and policies in relation to the
        collection, use and disclosure of personal information are not under the Company’s control. The Company is not
        responsible for web casting, or any other form of transmission received from any linked sites. You are
        responsible for protecting your system from viruses and other invasive items.
      </p>

      <h4>
        International Use
      </h4>
      <p>
        Despite the use of the Website is intended to be limited to the United States, because you can access this
        Website internationally, you agree to follow all United States federal law, all applicable state law and any
        other applicable regulation, rule or guidance regarding the use of this Website as well as generally applicable
        to the use of the Internet, data, e-mail, and privacy. Specifically, you agree to follow all laws that apply to
        transmitting personal information or technical data exported to and from the United States or the country of
        your residence.
      </p>

      <h4>
        Applicable Law
      </h4>
      <p>
        The materials in this Website are designed for use by residents of United States. The Company controls and
        operates this Website through its corporate headquarters in Wilmington, Delaware. To the extent permitted by
        applicable law, these Terms of Use shall be governed, interpreted, and enforced according to the laws of the
        State of Delaware, regardless of conflict of laws rules, and the federal laws of the United States applicable
        therein. To the extent permitted by law, you agree to the non-exclusive jurisdiction of the US or state courts
        in New Castle County, Delaware for any disputes, claim or cause of action arising out of, or relating to or in
        connection with these Terms of Use or your use of the Website, the Content of the Website, the Digital Vault
        Services or any related matter.
      </p>

      <h4>
        Indemnification
      </h4>
      <p>
        To the extent permitted by applicable law, you agree that your use of the Website or the Content of the Website,
        shall not be made the basis for any claim, suit, demand or cause of action or other proceeding against the
        Company and/or its licensors. You agree, at your own expense, to indemnify and hold the Company and its
        directors, officers, employees, representatives, agents, and licensors harmless against any claim, suit, demand,
        action, or other proceeding brought against the Company, its directors, officers, employees, representatives,
        agents and licensors by a third party as a result of your breach of these Terms of Use.
      </p>

      <h4>
        Severability
      </h4>
      <p>
        If any provision of these Terms of Use is held by a court of competent jurisdiction in any jurisdiction to be
        illegal, invalid, or unenforceable, such provision shall be deemed to be severable, and the remaining provisions
        shall remain in force and effect.
      </p>

      <h4>
        No Waiver
      </h4>
      <p>
        No waiver of any of these Terms of Use shall be deemed a further or continuing waiver of such term or condition
        or any other term or condition. Any waiver by the Company may only be granted in writing and signed by an
        authorized representative of The Company. 
      </p>

      <h4>
        Electronic Communications
      </h4>
      <p>
        By accessing this Website or sending electronic mail to the Company you are communicating with the Company
        electronically. By doing so, you agree to receive communications from the Company electronically. The Company
        will communicate with you by electronic mail at the electronic mail address provided by you or by posting
        notices on this Website or any other website that that may be generally designated in advance for such purpose.
      </p>

      <h4>
        Technical Specifications
      </h4>
      <p>
        This Website supports all up-to-date and commonly used internet browsers: The Company uses cookies to time your
        session. Cookies are small files which are stored on a user's computer. They are designed to hold a modest
        amount of data specific to a particular client and website and can be accessed either by the web server or the
        client computer. The information collected by such Cookies provide the Company with information and other data
        pertaining to any user’s utilization of the Website as well as any third-party websites such user may have
        accessed prior to, and subsequent to, their use of the Company’s Website.
      </p>

      <h4>
        Copyright Notice
      </h4>
      <p>
        <b>Copyright ©2023 Estate Resolution Technologies (US) LLC</b>. All Rights Reserved. Except as otherwise stated,
        The Company or its related companies own all Content contained in this Website. The Content of the Website is
        protected by copyright laws of the United States and other countries. The Content of the Website may not be used
        for any commercial purpose or copied, distributed, displayed, modified, or reverse engineered without the
        Company's prior written permission. You may print or download portions of material from the Website solely for
        your own non-commercial use, if you do not change or delete any copyright or proprietary notices from the
        materials.
      </p>
    </div>
  </div>

  <p class="mx-4">
    Last Updated: November 13, 2023
  </p>
</main>

<app-consumer-landing-footer></app-consumer-landing-footer>
