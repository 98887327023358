import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { OverviewService } from 'src/app/services/overview.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { AssetsService } from '../../../services/assets.service';
import { ReceivedDlbService } from '../../../services/received-dlb.service';


@Component({
  selector: 'app-overview-dashboard',
  templateUrl: './overview-dashboard.component.html',
})
/**
 * Overview dashboard ( displaying graphs )
 */
export class OverviewDashboardComponent implements OnInit {
  public years:Array<SelectOptionsInterface> = [];
  // Asset split
  public doughnutChartLabels: Label[];
  public doughnutChartData: SingleDataSet;
  public doughnutOptions: any;
  public doughnutColor: any;
  public initialDataLoader: boolean;
  public showMonthlyChart: boolean;
  public dashboardData: any;
  public assetData: any;
  public requestID: string;
  public plaidPublicToken: string;
  public accessToken: string;
  public chartData: number[];
  public showChart: boolean;
  public defaultSelected: any;
  public defaultSelectedYear: any;

  // Monthly spent
  public barChartOptions: ChartOptions;
  public barChartLabels: Label[];
  public barChartData: Partial<ChartDataSets[]>;
  public barChartColor: any;

  public requestIdParam: string;
  public userType: string;
  private transferDataPaid: boolean = false;

  /**
   * @constructor
   */
  constructor(
    private route: ActivatedRoute,
    private personalDetailsService: PersonalDetailsService,
    private localStorageService: LocalStorageService,
    private commonHelper: CommonHelper,
    private activeRouter: ActivatedRoute,
    private overviewService: OverviewService,
    private assetsService: AssetsService,
    private receivedDlbService: ReceivedDlbService,
  ) {
    this.barChartData = [{ data: [], backgroundColor: '#d72c00' }];
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.years = this.commonHelper.getYears(7);
    this.defaultSelected = this.years[0].value;
    this.defaultSelectedYear = this.years[0].displayText;
    this.userType = this.localStorageService.getDataByKey('role');
    this.accessToken = this.localStorageService.getUserData()?.access_token;
    this.requestID = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.requestIdParam = this.commonHelper.encodeDecodeRequestId(this.activeRouter.snapshot.parent.params?.id, false) || this.route.snapshot.params['id'];
    if (this.requestIdParam) {
      this.getAssetsValue();
      this.getPlaidTokenForReceivedDigitalVault();
      this.getBarChartDataForReceivedDigitalVault();
    }
    this.getPlaidToken();
    this.overviewService.dashboardDataObserve.subscribe((response) => {
      this.assetData = response;
      const totalFlow = +this.assetData?.asset?.total + +this.assetData?.liability?.total;
      this.doughnutChartData = [(this.assetData?.asset?.total / totalFlow) * 100, (this.assetData?.liability?.total / totalFlow) * 100];
    });
    this.initBarChart();

    if (this.isCustodian) {
      this.setDlbDetails();
    } else {
      this.getPersonalDetails();
    }
  }

  private initBarChart() {
    this.getBarChartData();
    this.doughnutChartLabels = ['Assets', 'Liabilities'];
    this.doughnutOptions = {
      legend: { position: 'bottom' },
      rotation: Math.PI,
      circumference: Math.PI,
      responsive: true,
      tooltips: {
        callbacks: {
          label: arg => ` ${Math.round(this.doughnutChartData[arg.index] as number)}%`,
        },
      },
    };
    this.doughnutColor = [{
      backgroundColor: ['#2692f7', '#f53d3d'],
    }];

    this.barChartOptions = {
      responsive: true,
      scales: {
        xAxes: [{
          gridLines: { drawOnChartArea: false },
        }],
        yAxes: [{
          gridLines: { drawOnChartArea: false },
        }],
      },
      legend: { position: 'bottom' },
    };
    this.barChartLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    this.barChartColor = [{
      backgroundColor: '#d72c00',
    }];
  }

  private setDlbDetails() {
    this.receivedDlbService.getRecievedDlbUser(this.requestIdParam).subscribe({
      next: r => {
        this.localStorageService.storeData('isVaultClaimed', r?.['is_vault_claimed']);
        this.localStorageService.storeData('is_transfer_amount_paid',r?.['user']?.['is_tansfer_amount_paid']);
        this.transferDataPaid = r?.['user']?.['is_tansfer_amount_paid'];
      },
      error: e => console.error(e),
      complete: () => this.getPersonalDetails(),
    })
  }

  private get useCensor(): boolean {
    return this.isCustodian && !this.transferDataPaid;
  }

  private get isCustodian(): boolean {
    return this.userType === 'Custodian';
  }

  /**
   * get barchart data for received digital vault
   */
  private getBarChartDataForReceivedDigitalVault() {
    const payload = {
      request_id: this.requestIdParam,
      access_token: this.plaidPublicToken,
      year: this.defaultSelectedYear,
      roletype: 'Consumer',

    };

    if (this.plaidPublicToken) {
      this.overviewService.getBarChartData(payload).subscribe((response: APIResponseModel) => {
        this.showMonthlyChart = true;
        if (response.data) {
          this.chartData = Object.values(response?.data);
          this.barChartData = [{
            data: this.chartData,
            backgroundColor: 'd72c00',
          }];
          this.showChart = false;
        }
      });
    }
  }

  /**
   * get assets value
   */
  public getAssetsValue() {
    this.assetsService.getTotalAssetsValue(this.requestIdParam).subscribe((response: APIResponseModel) => {
      this.overviewService.dashboardDataObservableEvents(response.data);
    });
  }

  /**
   * get plaid public token for received digital vault
   */
  public getPlaidTokenForReceivedDigitalVault() {
    this.overviewService.getPlaidtoken(this.requestIdParam).subscribe((response: APIResponseModel) => {
      this.plaidPublicToken = response.data.token;
      if (response.status) {
        this.getBarChartData();
      }
    });
  }

  /**
   * get personal details
   */
  public getPersonalDetails(): void {
    this.initialDataLoader = true;
    if (!this.useCensor) {
      const reqID = this.userType === 'Consumer' || this.isCustodian ? this.requestIdParam : this.requestID;
      this.personalDetailsService.getPersonalDetails(reqID).subscribe((response: APIResponseModel) => {
          if (response.status) {
            this.dashboardData = response?.data;
          }
        }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
        () => this.initialDataLoader = false);
    }
  }

  /**
   * get plaid public token for consumer
   */
  public getPlaidToken() {
    const reqID = this.userType === 'Consumer' || this.isCustodian ? this.requestIdParam : this.requestID;
    this.overviewService.getPlaidtoken(reqID).subscribe((response: APIResponseModel) => {
      this.plaidPublicToken = response.data.token;
      if (response.status) {
        this.getBarChartData();
      }
    });
  }

  /**
   * get barchart data for consumer
   */
  public getBarChartData() {
    const payload = {
      request_id: this.requestID,
      access_token: this.plaidPublicToken,
      year: this.defaultSelectedYear,
      roletype: 'Consumer',

    };

    if (this.plaidPublicToken) {
      this.overviewService.getBarChartData(payload).subscribe((response: APIResponseModel) => {
        this.showMonthlyChart = true;
        if (response.data) {
          this.chartData = Object.values(response?.data);
          this.barChartData = [{
            data: this.chartData,
            backgroundColor: 'd72c00',
          }];
          this.showChart = false;
        }
      });
    }
  }

  /**
   * Listen for option change (year)
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitter(selectedOption: SelectOptionsInterface): void {
    this.defaultSelected = selectedOption.value;
    this.defaultSelectedYear = selectedOption.displayText;
    this.showChart = true;
    this.barChartData = [
      {
        data: [],
        backgroundColor: '#d72c00',
      },
    ];
    this.getBarChartData();
  }

  /**
   * Accessor for commonHelper in template
   */
  get getCommonHelper(): CommonHelper {
    return this.commonHelper;
  }

  /**
   * toggle emitter
   * @param {boolean} data
   */
  public toggleEmiter(data: boolean) {
    if (data) {
      console.log(data);
    }
  }
}
