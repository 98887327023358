import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeviewConfig } from 'ngx-treeview';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ConsumerPaymentMethod, DV_TANSFER_CONTENT, SECTIONS, SectionStatus } from '../../../../constants/application.const';
import { PersonalDetailsService } from '../../../services/personal-details.service';
import { CheckoutService } from '../../checkout/checkout.service';
import { PaymentOption } from '../../checkout/payment-option.model';


@Component({
  selector: 'app-professional-user-type',
  templateUrl: './professional-user-type.component.html',
})
/**
 * type of professional user class
 */
export class ProfessionalUserTypeComponent implements OnInit {
  public sectionSaveExitOptions: any;
  public clientRequestId: string;
  public userType: string;
  public preDefinedLoader: boolean;
  public toggleModalAdd: boolean;
  public submitLoader: boolean;
  public advisorArray: Array<any>;
  public agentTerm: string;
  public config = TreeviewConfig.create({
    hasAllCheckBox: false,
    maxHeight: 400,
  });
  public buttonProgress: boolean;
  public isTransferAmountPaid: boolean;
  public isTransferAmoutPending: boolean;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public vaultVideo: string;
  public downloadDigitalVault: boolean;
  public requestId: string;
  public paymentOptions: PaymentOption[];
  public dvTransferContent: string;
  public messageData = {
    title: { content: 'Edit Anytime', class: 'text-2xl' },
    message: [
      '<b>Save for Later</b> will leave this section marked as incomplete. Use this to remind yourself to come back to this section later.',
      '<b>Mark as Complete</b> will mark this section as completed. This means you have provided all of your up-to-date information.',
      'Remember, whether you <b>Save for Later</b> or <b>Mark as Complete</b> you can return to edit this data anytime.',
    ],
    buttons: [{
      content: 'Understood!',
      emitValue: true,
    }],
  };
  public modalSettings = {
    modalClass: 'edit-anytime-modal',
  };
  private hasTriggered: boolean = false;
  private clickCommand: string;

  /**
   * constructor
   */
  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private slugInterceptorService: SlugInterceptorService,
    private personalDetailsService: PersonalDetailsService,
    private checkoutService: CheckoutService,
  ) {
  }

  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.requestId = this.route.snapshot.params['id'];
    // this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.agentTerm = 'Professional Service Provider';
    this.sectionSaveExitOptions = {
      section: 2,
      type: 3,
    };
    this.clientRequestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.isTransferAmountPaid = (this.localStorageService.getDataByKey('is_transfer_amount_paid') == 1);
    this.isTransferAmoutPending = (this.localStorageService.getDataByKey('is_transfer_amount_paid') == 2);
    this.preDefinedLoader = false;
    this.checkTransferPaidINConsumerLogin();
  }

  /**
   * toggle add beneficiary modal
   */
  public toggleModal(): void {
    this.modalService.open('get-advisor-modal');
    this.toggleModalAdd = !this.toggleModalAdd;
  }

  /**
   * Listener for Message Modal buttons.
   * @param event
   */
  public buttonListener(event) {
    if (event) {
      this.hasTriggered = true;
      this.clickCommand && this.click(this.clickCommand);
      this.modalService.close('message-modal');
      this.clickCommand = '';
    }
  }

  /**
   * Click handler
   * @param command
   */
  public click(command: string) {
    const isPro = this.userType !== 'Consumer';
    switch (command) {
      case 'MarkComplete':
        if (!this.hasTriggered) {
          this.clickCommand = command;
          this.modalService.open('message-modal');
        } else {
          this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.professionalServiceProviders, SectionStatus.COMPLETE).subscribe();
          this.buttonProgress = true;
        }
        break;
      case 'SaveForLater':
        if (!this.hasTriggered) {
          this.clickCommand = command;
          this.modalService.open('message-modal');
        } else {
          this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.professionalServiceProviders, SectionStatus.INCOMPLETE).subscribe();
          this.buttonProgress = true;
        }
        break;
      case 'Back':
        const url_back = isPro ? ['overview', this.clientRequestId] : ['executor-beneficiary', 'executors-beneficiaries-view'];
        void this.slugInterceptorService.navigate(url_back);
        break;
      case 'ReturnToDashboard':
        const url_dashboard = isPro ? ['overview', this.clientRequestId] : ['dashboard'];
        void this.slugInterceptorService.navigate(url_dashboard);
        break;
      case 'NextSection':
        const url_next = ['insurance-details', isPro ? this.clientRequestId : ''];
        void this.slugInterceptorService.navigate(url_next);
        break;
    }
  }

  /**
   * Check transfer paid while consumer logged in
   */
  private checkTransferPaidINConsumerLogin(): void {
    this.personalDetailsService.getPersonalDetails(this.userType !== 'Consumer' ? this.requestId : undefined).subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          if (response.data['inform_exec_permission']) {
            this.dvTransferContent = DV_TANSFER_CONTENT.accessGrantedWhileAlive;
          }
          // Disable transfer-service-fee-modal
          // this.commonService.getContactUsDetails().subscribe({
          //   next: response => {
          //     const paymentMode = this.localStorageService.getDataByKey('payment_mode');
          //     const currentUser = this.localStorageService.getUserData();
          //     const slugDetails = currentUser?.slug_details;
          //     const showTransferFeeModal = (): boolean => {
          //       return (paymentMode == undefined || paymentMode != ConsumerPaymentMethod.PAID_BY_PROFESSIONAL || !slugDetails);
          //     };
          //
          //     const isDownloadDV: boolean = (response.data.download_digital_vault == 1);
          //     if (this.userType == 'Consumer' && !this.isTransferAmountPaid && !this.isTransferAmoutPending && isDownloadDV) {
          //       setTimeout(() => {
          //         showTransferFeeModal() && this.modalService.open('transfer-service-fee-modal');
          //       }, 50);
          //     }
          //   },
          // });
        }
      },
    });

  }

  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');
  }
}
