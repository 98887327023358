<div class="section">
    <div class="max-w-screen mx-auto">
        <div class="grid grid-cols-12 gap-4 row-gap-8">
            <div class="col-span-12">
                <h2 class="text-pale-blue font-ave-bold font-bold text-2xl md:text-3xl lg:text-5xl mb-2">
                    Subscription
                </h2>
                <p class="text-grey font-ave-medium text-lg sm:text-xl lg:text-2xl">Select your plan to get access to LegacyNOW.
                </p>
            </div>
            <div class="col-span-12 md:mt-12">
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 sm:col-span-10">
                        <h2 class="text-pale-blue font-ave-bold font-bold text-xl md:text-2xl lg:text-3xl mb-2">
                            LegacyNOW Subscription
                        </h2>
                        <p class="text-black font-ave-medium text-base md:text-xl lg:text-2xl">A subscription fee is required to access LegacyNOW. This
                            will be
                            a recurring monthly payment which can be cancelled at any time.
                            If during your first month you are not entirely satisfied with the
                            service, you can cancel for a complete refund.
                        </p>
                    </div>
                    <div class="col-span-12 sm:col-span-2 sm:ml-auto">
                        <h2 class="text-pale-blue font-ave-bold font-bold text-base md:text-xl lg:text-2xl">
                            $5.99
                        </h2>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-4 mt-16">
                    <div class="col-span-10">
                        <h2 class="text-pale-blue font-ave-bold font-bold text-xl md:text-2xl lg:text-3xl">
                            Total Monthly
                        </h2>
                       
                    </div>
                    <div class="col-span-2 ml-auto">
                        <h2 class="text-pale-blue font-ave-bold font-bold text-xl lg:text-2xl">
                            $5.99
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-span-12">
                <button type="submit"  routerLink="../payment-information"
                    class="flex items-center justify-center text-sm sm:text-base ml-auto button button--lg mt-8 w-auto bg-pale-blue rounded-xs h-12 focus:outline-none font-ave-semibold text-white px-8 cursor-pointer">
                PROCEED TO PAYMENT INFORMATION
                </button>
            </div>
        </div>
    </div>
</div>