<!-- Multiple Conditions passed as Object for  ngClass doesn't work as Expected...Code Has been Reverted -->
<button [type]="buttonType" [tooltip]="buttonTooltip" (click)="handleOnclick()"
        class="flex items-center justify-center gap-3 w-full {{buttonClass ?? 'button hover:underline-text px-4 button--lg w-full md:w-auto bg-darkblue rounded-xs py-2 focus:outline-none text-regular text-sm md:text-base text-white'}} {{(!valid)?'opacity-50':''}} {{btnTextSize ?? ''}} {{outlineOnly?'custom-border-button':''}}"
        [disabled]="!valid || loader">
  <img *ngIf="!loader && sandClock"
  src="assets/icons/sand-clock-icon.svg" class="w-5 h-3 mr-1" alt="digital vault">
  <div *ngIf="loader">
    <span class="loading-red block"></span>
  </div>
  <span class="text-sm md:text-base font-sans">{{buttonText}}</span>
</button>
