import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { locationDetailsInterface } from 'src/app/interface/personalDetails.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { APARTMENT_NUMBER_PATTERN, UNITED_KINGDOM } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../helper/validation.helper';


@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
})
/**
 * Location details component
 */
export class LocationDetailsComponent implements OnInit {
  public locationDetailForm: FormGroup;
  public preDefinedLoader: boolean = false;
  public submitLoader: boolean = false;
  public readonly sectionSaveExitOptions = { id: undefined, section: 1, type: 2 };
  public userType: string;
  public requestId: string;
  public defaultCountry: number;
  public selectedCountry: number;
  public selectedCountryName: string = '';
  public stateId: number;
  public unitedKingdom: string = UNITED_KINGDOM;
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;


  /**
   * @constructor
   */
  constructor(
    private form: FormBuilder,
    private personalDetailsService: PersonalDetailsService,
    private localStorageService: LocalStorageService,
    private commonHelper: CommonHelper,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private addressHelper: AddressHelper,
    private ngZone: NgZone,
    private slugInterceptorService: SlugInterceptorService,
    private validation: ValidationHelper,
  ) {
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.selectedCountry = environment.APP_DOMAIN;
    this.requestId = this.route.snapshot.params['id'];

    try {
      this.defaultCountry = this.localStorageService.getDataByKey('country');
    } catch {
      // Handle case where country was stored as `undefined`
      this.defaultCountry = this.selectedCountry;
    }
    this.initForm();
    this.userType = this.localStorageService.getDataByKey('role');
    this.getPersonalDetails();
    this.isEnableState();
    this.validation.setValidationErrors({
      zipcode: { pattern: 'Please enter a valid zipcode'}
    });
  }


  private initForm() {
    // Location details form
    this.locationDetailForm = this.form.group({
      address: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(225)] }],
      city: ['', { updateOn: 'blur', validators: [Validators.required] }],
      country: ['', { updateOn: 'blur', validators: [Validators.required] }],
      state: ['', { updateOn: 'blur', validators: [Validators.required] }],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(10)] }],
      apt_number: ['', [Validators.pattern(APARTMENT_NUMBER_PATTERN), Validators.maxLength(25)]],
    });
    
    const trimWhitespaceControls = ['address', 'apt_number', 'zipcode','city'];
    
    trimWhitespaceControls.forEach(controlName => {
      this.locationDetailForm.controls[controlName].valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.locationDetailForm.controls[controlName])
      );
    });
    
  }


  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.locationDetailForm);
  }


  /**
   * Get personal details
   */
  public getPersonalDetails(): void {
    this.preDefinedLoader = true;
    // get children list
    this.personalDetailsService.getPersonalDetails(this.userType !== 'Consumer' ? this.requestId : null).subscribe((response: APIResponseModel) => {
      if (response.status) {
        const tempData = { ...response.data };
        this.locationDetailForm.patchValue({
          ...response.data,
          city: tempData?.city?.id,
          country: tempData?.country?.id ?? this.defaultCountry,
          state: tempData?.state?.id,
        });
        this.sectionSaveExitOptions.id = response.data.user_id;
      }
    }, (err) => {
      // Default country if error
      this.locationDetailForm.patchValue({
        country: this.defaultCountry,
      });
      this.commonHelper.httpResponseHandler(err?.error);
    }, () => {
      // fail-safe for loader cut
      setTimeout(() => {
        this.preDefinedLoader = false;
      }, 3000);

      // Initialize map API
      this.initMap(this.locationDetailForm.get('country').value);
    });
  }


  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.locationDetailForm.controls;
  }


  /**
   * Initialize google map api by country
   * @param {any} countryId
   */
  public initMap(countryId: any): void {
    this.removePacContainer();
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: (r) => {
        this.ngZone.run(() => {
          this.locationDetailForm.patchValue(r);
        });
      },
      error: (e) => console.error(e),
    });
  }


  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.locationDetailForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }


  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry || this.preDefinedLoader) return;
    this.locationDetailForm.controls.state.reset();
    this.locationDetailForm.patchValue({
      country: selectedCountry.value,
    });
    this.removePacContainer();
    this.initMap(selectedCountry.value);
  }


  /**
   * remove pac container
   *
   */
  public removePacContainer() {
    this.addressHelper.removeAutocompletePacContainer();
  }


  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.locationDetailForm.controls.city.reset();
    this.locationDetailForm.patchValue({
      state: selectedState.value,
    });
  }


  /**
   * listen for options changes state
   *
   * @param {string} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.locationDetailForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.locationDetailForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(selectedCity?.additionalDetails[0]?.postal_code_regex))]);
      zipCodeRef.updateValueAndValidity({ onlySelf: true });
    }
    // this loader is set false here because of the delay to set country state city
    this.preDefinedLoader = false;
  }


  /**
   * Submit location details
   */
  public async onSubmit() {
    this.locationDetailForm.markAllAsTouched();
    if (!this.locationDetailForm.valid) return; // return if the form is invalid
    const sendingData: locationDetailsInterface = this.locationDetailForm.value;
    this.submitLoader = true;

    // Update Local Storage Country
    // this.localStorageService.storeData('country', sendingData.country);
    if (this.userType === 'Consumer') {
      const payLoad = {
        ...sendingData,
        apt_number: this.locationDetailForm.controls.apt_number.value,
        is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
        roletype: this.userType,
      };
      payLoad.state = this.selectedCountryName == this.unitedKingdom ? null : payLoad.state;
      this.commonService.getCityDetail(payLoad.country, payLoad.state, payLoad.city).subscribe((cResponse: APIResponseModel) => {
        if (cResponse?.status) {
          payLoad.city = cResponse?.data?.id;
          this.personalDetailsService.storePersonalDetails(payLoad, 2).subscribe((response: APIResponseModel) => {
            if (response.status) {
              this.commonHelper.updateLocalstorageRequestStage(response.data);
              void this.slugInterceptorService.navigate(['/about-yourself/partner']);
              this.submitLoader = false;
            }
          }, (exception: any) => {
            this.commonHelper.httpResponseHandler(exception?.error);
            this.submitLoader = false;
          });
        }
      }, (exception: any) => {
        this.commonHelper.httpResponseHandler(exception?.error);
        this.submitLoader = false;
      });
    } else {
      const payLoad = {
        ...sendingData,
        roletype: this.userType,
        is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
        request_id: this.requestId,
      };
      payLoad.state = this.selectedCountryName == this.unitedKingdom ? null : payLoad.state;
      this.commonService.getCityDetail(payLoad.country, payLoad.state, payLoad.city).subscribe((cResponse: APIResponseModel) => {
        if (cResponse?.status) {
          payLoad.city = cResponse?.data?.id;
          this.personalDetailsService.storePersonalDetails(payLoad, 2).subscribe((response: APIResponseModel) => {
            if (response.status) {
              this.commonHelper.updateLocalstorageRequestStage(response.data);
              void this.slugInterceptorService.navigate(['/about-yourself/partner/' + this.requestId]);
            }
          }, (exception: any) => {
            this.commonHelper.httpResponseHandler(exception?.error);
            this.submitLoader = false;
          });
        }
      }, (exception: any) => {
        this.commonHelper.httpResponseHandler(exception?.error);
        this.submitLoader = false;
      });
    }
  }


  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }


  /**
   * getter for state control
   */
  get state() {
    return this.locationDetailForm.get('state');
  }


  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.locationDetailForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ?
        this.state.addValidators([Validators.required]) : this.state.clearValidators();
      this.state.updateValueAndValidity();
    });
  }


  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }


  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.locationDetailForm.get('zipcode');
    const stateId = this.locationDetailForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        if (response.data.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(zipCodeRegex))]);
          zipCodeRef.updateValueAndValidity();
        } else {
          zipCodeRef.setValidators([Validators.required]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }


  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }
}
