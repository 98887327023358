import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { PHONE_MASK } from 'src/constants/application.const';
import { FLAG_COUNTRY_CODES } from 'src/constants/phoneComponent.const';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.css'],
  host: {
    '(document:click)': 'closeDropDown($event)',
  },
})
/**
 *
 */
export class PhoneInputComponent implements OnInit, OnChanges {
  @Input() placeholder: string;
  @Input() phoneNumber: string = '';
  @Input() countryCode: any;
  @Input() classList: boolean;
  @Input() errorClassTrigger: boolean;
  @Input() disabled: boolean;
  // Should be equal to the associated label's 'for' attribute.
  @Input() uniqueId: string = 'app-phone-input';
  // Control key for the phone value in {@link form}. Defaults to <code>phone</code>.
  @Input() controlKey: string = 'phone';
  @Input() form: FormGroup;

  @Output() onChange = new EventEmitter<any>();
  // CSS classes for the input element (determined by value of {@link classList})
  public readonly inputClass = {
    true: 'phone-input h-12 lg:h-14 pl-4 w-full text-regular text-content shadow-sm border border-input rounded-md focus:outline-none',
    false: 'input w-full rounded-xs h-10 lg:h-12 mt-1 text-regular text-grey appearance-none pl-4 border block focus:outline-none phone-input',
  };
  public countryDetailsList: any[] = FLAG_COUNTRY_CODES;
  public toggleList: boolean;
  public selectedCountry: any;
  public PHONE_MASK: string;
  public appDomain: number;
  public canadaIndex: number;


  /**
   * @constructor
   */
  constructor(private _el: ElementRef) {
    this.appDomain = environment.APP_DOMAIN;
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.toggleList = false;
    this.PHONE_MASK = PHONE_MASK;
  }


  /**
   * detection on changes
   *
   * @param {SimpleChanges} simpleChanges
   */
  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges?.countryCode?.isFirstChange) {
      this.sortCountryDetails();
      this.canadaIndex = this.countryDetailsList.findIndex((country) => country.code === 'CA');
    }

    const currentValue = simpleChanges?.countryCode?.currentValue;
    const previousValue = simpleChanges?.countryCode?.previousValue;

    if (currentValue && !_.isEqual(currentValue, previousValue)) {
      if (currentValue.dialCode === '1' && this.appDomain == 2) {
        this.selectCountry(this.canadaIndex);
      } else {
        const selectedCountryIndex = this.countryDetailsList.findIndex((country) => country.code === currentValue.code);
        this.selectCountry(selectedCountryIndex);
      }
    }
  }


  /**
   * change country code
   *
   * @param {number} index
   * @param {Event} event (optional)
   */
  public selectCountry(index: number, event?: Event): void {
    event?.stopPropagation();
    this.selectedCountry = this.countryDetailsList[index];
    this.toggleList = false;
    this.detectChange(ChangeType.countryCode);
  }


  /**
   * detect change callback for input the phone number
   *
   * @param {number} type
   */
  public detectChange(type: ChangeType): void {
    // Intrinsic updating of form.
    if (type === ChangeType.phoneNumber && this.form) {
      this.form.get(this.controlKey).setValue(this.phoneNumber);
    } else if (type === ChangeType.countryCode && this.countryCode) {
      const sendObject = {
      value:{ code: this.selectedCountry?.code, dialCode: this.selectedCountry?.dialCode },
    };
    this.onChange.emit(sendObject);
    }
    // if (this.form) {
    //   this.form.get(this.controlKey).markAsUntouched();
    // }
  }


  /**
   * Triggered on document click. If the click does not contain this HTML element, the phone list will be closed if it is open.
   *
   * @param {Event} event
   */
  public closeDropDown(event: Event): void {
    if (!this._el.nativeElement.contains(event.target)) {
      this.toggleList = false;
    }
  }


  /**
   * Sorts the array of country details by the "name" property in a case-insensitive manner.
   */
  public sortCountryDetails() {
    this.countryDetailsList.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }


  /**
   * Handling event for clicking on the flag button.
   * @param $event
   */
  onClickFlag($event: MouseEvent) {
    if (!this.disabled) {
      this.toggleList = !this.toggleList;
      $event.preventDefault();
    }
  }


  keyUpPhoneNumber() {
    this.detectChange(ChangeType.phoneNumber);
  }
}


enum ChangeType {
  countryCode,
  phoneNumber
}
