import { Component } from '@angular/core';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-consumer-privacy-policy',
  templateUrl: './consumer-privacy-policy.component.html',
  styleUrls: ['../documents.css'],
})
export class ConsumerPrivacyPolicyComponent {
  public customerSupportMail: string;


  constructor(private commonService: CommonService,private slugInterceptorService: SlugInterceptorService) {
  }

  /**
   * called initially
   */
  ngOnInit() {
    this.getContactDetails();
  }

  /**
   * get contact details
   */
  private getContactDetails() {
    this.commonService.getContactUsDetails().subscribe((response) => {
      this.customerSupportMail = response.data?.support_email;
    });
  }

  /**
   * change route
   * @param{string}url
   */
    public changeRoute(url: string) {
      void this.slugInterceptorService.navigate([url], null, false, true);
    }
}
