import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIResponseModel } from '../interface/response.interface';

@Injectable({
  providedIn: 'root',
})

/**
 * Slug Service
 */
export class SlugService {
  private slug: BehaviorSubject<boolean>;
  public slugObserve: Observable<unknown>;
  private banner: BehaviorSubject<boolean>;
  public bannerObserve: Observable<unknown>;

  /**
   * constructor
   */
  constructor(private httpClient: HttpClient) {
    this.slug = new BehaviorSubject(false);
    this.slugObserve = this.slug.asObservable();
    this.banner = new BehaviorSubject(false);
    this.bannerObserve = this.banner.asObservable();
  }

  /**
   * set slug
   * @param{boolean}data
   */
  public setSlug(data: boolean) {
    this.slug.next(data);
  }

  /**
   * set banner
   * @param{boolean}data
   */
  public setBanner(data: boolean) {
    this.banner.next(data);
  }

  /**
   * verify slug url
   * @param{string} slug
   * @return{Observable}
   */
  public verifySlug(slug: string): Observable<APIResponseModel> {
    return this.httpClient.post<APIResponseModel>(`${environment.BASE_URL}/organization/get-logo`, { slug_url: slug });
  }

  /**
   * verify slug url
   * @param{string} slug
   * @return{Observable}
   */
  public verifyProSlug(slug: string): Observable<APIResponseModel> {
    return this.httpClient.post<APIResponseModel>(`${environment.BASE_URL}/professional/get-logo-details`, { slug_url: slug });
  }
  /**
   * Verify user slug
   * @returns user slug
   */
  public verifyUserSlug(): Observable<APIResponseModel> {
    return this.httpClient.get<APIResponseModel>(`${environment.BASE_URL}/users/professional-slug-details`);
  }
}

