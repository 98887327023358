<div class="fixed group z-50"
     [ngClass]="position">
  <button
    type="button" aria-expanded="false"
    class="flex items-center justify-center text-white bg-blue-700 rounded-full w-16 h-16 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800"
    (click)="openModal()"
    (touchstart)="onHover('enter')"
    (touchend)="onHover('leave')"
    (touchcancel)="onHover('leave')"
    (mouseenter)="onHover('enter')"
    (mouseleave)="onHover('leave')"
    (focusin)="onHover('enter')"
    (focusout)="onHover('leave')">
    <img src="assets/images/intro-video.png" alt="">
    <span class="sr-only">Open actions menu</span>
    <div class="tooltip__intro-video">
      <p class="tooltiptext text-base"
         [ngClass]="{'tooltiptextnone':!showToolTipText}"
         [innerHTML]="tooltipText"
      ></p>
    </div>
  </button>
</div>
<app-demo-video-modal [modal_id]="videoModalID" [vaultVideo]="vaultVideo"></app-demo-video-modal>
