<app-common-modal id="delete-confirmation-modal" class="app-common-modal">
  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">
    <h4 class="border-b-2 pb-2 text-regular text-lg text-darkblue font-semibold tracking-wide">
      Remove {{content? content : 'Client'}}
    </h4>

    <h2 class="border-b-2 p-4 mb-8 text-md text-grey font-400">Are you sure you want to remove the {{content? content :
      'Client'}} from the
      list
      ?</h2>

    <div class="flex items-center justify-end">
      <div class="mr-4">
        <button type="button" (click)="closeModel()"
          class="flex items-center justify-center button px-4 button--lg w-full md:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white opacity-50':''}}    {{btnTextSize?btnTextSize:''}} {{outlineOnly?'custom-border-button':''}}">

          <span>No</span>
        </button>

      </div>
      <div>
        <button type="submit" (click)="onDelete()"
          class="flex items-center justify-center text-white button px-4 button--lg w-full md:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white' {{outlineOnly?'custom-border-button':''}}">
          <div class="loading-red mr-2" *ngIf="submitLoaderDelete"></div>
          <span>Yes</span>
        </button>
      </div>
    </div>

    <!-- END: Leftside Card -->
  </div>
</app-common-modal>
