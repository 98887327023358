<div class="flex items-center gap-4 w-full relative">
    <input type="text" *ngIf="!input" (click)="selectToggle = !selectToggle" aria-readonly="true" class="w-full cursor-pointer h-10 sm:h-12 input py-3 px-4 text-regular text-sm mt-1 rounded-xs appearance-none border block focus:outline-none rounded">
    <!-- <a>Insurance Company</a> -->
    <!-- <div>
        <img src="assets/images/about-yourself/angle-down-arrow.png" class="w-3" alt="digital vault" >
        <img src="assets/images/overview/white-down-arrow.svg" class="w-3" alt="digital vault">
    </div> -->
</div>
<input *ngIf="input" (click)="selectToggle = !selectToggle" aria-readonly="true"
    class="cursor-pointer h-12 w-full input py-3 px-4 text-regular text-sm  rounded-xs appearance-none border block focus:outline-none rounded flex flex-row items-center justify-between"
    [ngClass]="">

<div class="absolute w-full" [class]="class" *ngIf="selectToggle">
    <ul class="input w-full border up-arrow bg-white rounded-xs mt-4 absolute z-10">
        <li class="cursor-pointer px-3 text-grey text-sm py-2 flex justify-between items-center select-option ">
            data
            <img src="assets/images/about-yourself/list-selected.svg" class="w-4" alt="digital vault">
        </li>
    </ul>
</div>
