import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { LIST_DELETE, LIST_NEW, LIST_UPDATE, UNITED_KINGDOM } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-advisor-modal',
  templateUrl: './add-advisor-modal.component.html',

})
/**
 * add advisor modal
 */
export class AddAdvisorModalComponent implements OnInit {
  @Output() toggleModalEmitter = new EventEmitter<any>();
  @Output() newAdvisorDetails = new EventEmitter();
  @Input() toggleModal: boolean;
  @Input() editData: any;
  @Input() isCustodian: any;
  public submitLoader: boolean;
  public preDefinedLoader: boolean;
  public submitLoaderDelete: boolean;
  public countryCode: string;
  public userType: string;
  public requestId: string;
  public advisorTypes: Array<any>;
  public defaultCountry: number;
  public isProfessionalType: boolean;
  public selectedCountry: number;
  public data: any;
  public showAddButton: boolean;
  public peopleLoader: boolean;
  public dynamicText: string;
  public selectedCountryName: string;
  public unitedKingdom: string = UNITED_KINGDOM;

  /**
   * constructor
   */
  constructor(
    private route: ActivatedRoute,
    private modalService: CommonModelService,
    private peopleService: PeopleService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private zone: NgZone,
  ) { }
  /**
   * loaded initially
   */
  ngOnInit(): void {
    // this.selectedCountry = environment.APP_DOMAIN;
    this.preDefinedLoader = false;
    this.userType = this.localStorageService.getDataByKey('role');
    this.requestId = this.route.snapshot.params['id'];
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    this.countryCode = environment.DEFAULT_COUNTRY_DIALCODE;
    this.getAdvisorTypes();
    this.peopleService.advisorComponentObserve.subscribe((response: any) => {
      if (response.id) {
        this.getAdvisorData(response);
      }
    });
    // advisor remove
    this.peopleService.advisorDetailsObserve.subscribe((response: any) => {
      if (response.id) {
        this.prepareData(response);
      }
    });
  }

  ngAfterViewChecked(): void {
    this.zone.runOutsideAngular(() => {
      // change button text corresponding to page
      this.isCustodian ? this.dynamicText = 'Custodian' : this.dynamicText = 'Advisor';
    });
  }
  /**
   * Setter for edit values in beneficiaryForm
   *
   * @param {any} data
   */
  public prepareData(data: any) {
    this.peopleLoader = true;
    console.log(data);
    this.showAddButton = false;
    this.data = data;
    if (data && data?.id) {
      if (data?.phone) {
        this.countryCode = data?.country_code;
      }
      this.isProfessionalType = data.role_name === 'Professional';
      this.peopleLoader = false;
    }
  }
  /**
   * get advisor data by email
   * @param{any}response
   */
  public getAdvisorData(response: any) {
    this.peopleLoader = true;
    this.showAddButton = true;
    if (response && response?.id) {
      if (response?.phone) {
        this.countryCode = response?.country_code;
      }
      this.selectedCountry = response?.country?.id;
      this.selectedCountryName = this.selectedCountry ? (response?.country?.name).toLowerCase() : '';
      this.isProfessionalType = response.role_name === 'Professional';
      this.data = response;
    }
    this.peopleLoader = false;
  }

  /**
   * get advisor types
   */
  public getAdvisorTypes() {
    this.peopleService.getAdvisorTypes(this.userType).subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.advisorTypes = this.commonHelper.convertToOptionsFormat(response?.data, 'id', 'name');
      }
    });
  }
  /**
   * choose type of professional user
   */
  public addAdvisor() {
    this.submitLoader = true;
    if (this.userType === 'Consumer') {
      this.peopleService.storePeopleDetails({
        ...this.data,
        advisor: this.isCustodian ? 0 : 1,
        id: '',
        advisor_type: this.data?.professional_type?.id,
        country: this.data?.country?.id,
        state: this.data?.state?.id,
        city: this.data?.city?.id,
        roletype: this.userType,
        is_state: this.selectedCountryName == this.unitedKingdom ? 0 : 1,
        custodian: this.isCustodian ? 1 : 0,
      }).subscribe((response: APIResponseModel) => {
        this.submitLoader = false;
        if (response.status) {
          console.log("RESPONSE", response)
          if (response.message == "PSP has been successfully added.") {
            this.commonHelper.httpResponseHandler(response);
          } else {
            this.commonHelper.updateLocalstorageRequestStage(response.data);
            this.toggleModalEmitter.emit({
              ...response.data,
              listStatus: (this.data.id) ? LIST_UPDATE : LIST_NEW,
            });
            this.newAdvisorDetails.emit({
              ...response.data,
              listStatus: (this.data.id) ? LIST_UPDATE : LIST_NEW,
            });
            this.peopleService.refreshDetailList(this.data);
            this.data = {};
            this.commonHelper.toastrInsertSuccess();
          }
          this.closeModel();
        }
      }, (exception: any) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });

    } else {
      this.peopleService.storePeopleDetails({
        ...this.data,
        advisor: this.isCustodian ? 0 : 1,
        id: '',
        advisor_type: this.data?.professional_type?.id,
        country: this.data?.country?.id,
        state: this.data?.state?.id,
        city: this.data?.city?.id,
        roletype: this.userType,
        request_id: this.requestId,
        is_state: this.selectedCountryName == this.unitedKingdom ? 0 : 1,
        custodian: this.isCustodian ? 1 : 0,
      }).subscribe((response: APIResponseModel) => {
        console.log("2nd", response)
        this.submitLoader = false;
        if (response.status) {
          this.toggleModalEmitter.emit({
            ...response.data,
            listStatus: (this.data.id) ? LIST_UPDATE : LIST_NEW,
          });
          this.newAdvisorDetails.emit({
            ...response.data,
            listStatus: (this.data.id) ? LIST_UPDATE : LIST_NEW,
          });
          this.data = {};
          this.peopleService.refreshDetailList(this.data);
          this.closeModel();
          this.commonHelper.toastrInsertSuccess();
        }
      }, (exception: any) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    }
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.modalService.contains('add-advisor-modal') ? this.modalService.close('add-advisor-modal') : false;
    this.modalService.contains('get-advisor-modal') ? this.modalService.close('get-advisor-modal') : false;
    this.modalService.contains('get-custodian-modal') ? this.modalService.close('get-custodian-modal') : false;
  }


  /**
     * delete advisor
     */
  public deleteAdvisor(): void {
    this.submitLoaderDelete = true;
    const data: {} = {
      is_delete: '1',
      id: this.data.id,
      roletype: this.userType,
      request_id: this.requestId,
    };
    if (this.userType === 'Consumer') {
      delete data['request_id'];
    }

    this.peopleService.storePeopleDetails(data).subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          this.closeModel();
          this.toggleModalEmitter.emit({
            ...response.data,
            listStatus: LIST_DELETE,
          });
          this.peopleService.advisorObservableId(this.data?.id);
          this.commonHelper.toastrDeleteSuccess();
        }
      },
      error: (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
      complete: () => this.submitLoaderDelete = false,
    });
  }
}
