import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
/**
 * professional user service
 */
export class ProfessionalUserService {
  private BASE_URL: string;
  private roleType: string;
  public loginMode: any;
  public loginModeObserve: Observable<boolean>;
  public currentUser: any;
  public currentUserObserve: Observable<Object>;
  /**
 * constructor
 */
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private commonHelper: CommonHelper,
  ) {
    this.BASE_URL = environment.BASE_URL;
    this.loginMode = new BehaviorSubject(false);
    this.loginModeObserve = this.loginMode.asObservable();
    this.currentUser = new BehaviorSubject(null);
    this.currentUserObserve = this.currentUser.asObservable();
  }

  /**
  * get advisor term
  * @return{string}
  */
  public getAdvisorTerm() {
    // this.roleType = this.localStorageService.getUserData(USER_TYPES.pro).user.professional_type_name;
    // return PROFESSIONAL_TERMS[this.roleType].agent;
    return this.commonHelper.getAdminSettings()?.advisor_term;
  }


  /**
   * Login user
   *
   * @param {LOGIN_USER_INTERFACE} userData
   * @return {Observable<APIResponseModel>}
   */
  public userLogin(userData: any): Observable<APIResponseModel> {
    this.loginMode.next(true);
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/login', userData);
  }
  /**
   * get current user name
   * @param {any} name
   */
  public getCurrentUserName(name: any): void {
    this.currentUser.next(name);
  }

  /**
   * Reset password
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public confirmUserAccount(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/email/verify', data);
  }

  /**
   * check email verified or not
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public checkEmailVerified(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/check-email-verified', data);
  }
  /**
   * Request reset password
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public requestResetPassword(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/password/reset-request', data);
  }

  /**
   * Reset password
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public resetPassword(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/password/reset', data);
  }
  /**
   * Reset password
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public changePassword(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/change-password', data);
  }
  /**
   * update profile
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public updateProfile(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/update-profile', data);
  }
  /**
   * get current user details
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public getCurrentUser(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/professional/profile');
  }
  /**
  * change email
  * @param{{}}data
  * @return {Observable<APIResponseModel>}
  */
  public changeEmailVerification(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/change-email-verification', data);
  }

  /**
   * Verify Otp
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public verifyOtp(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/professional/update-email`, data);
  }

  /**
   * change email
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public changeEmail(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/change-email', data);
  }
  /**
   * change profile picture
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public changeProfilePicture(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/change-profile-picture', data);
  }

  /**
   * change profile picture
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public changeCompanyLogo(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/update-logo', data);
  }

  /**
   * payment details
   * @param {string} type
   * @param {number} page
   * @param {number} length
   * @param {string} sort
   * @param {string} search
   * @return {Observable<APIResponseModel>}
   */
  public getPaymentDetails(type: string, page?: number, length?: number, sort?: string, search?: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/professional/invoice-list/${type}?page=${page}&length=${length}&sort=${sort}&search=${search}`);
  }
  /**
   * pay for vaults
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public payInvoice(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/paynow`, data);
  }
  /**
   * payment details
   * @param {string} type
   * @param {number} page
   * @param {number} length
   * @param {string} sort
   * @param {string} search
   * @return {Observable<APIResponseModel>}
   */
  public getPayoutDetails(type: string, page?: number, length?: number, sort?: string, search?: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/professional/payout-list/${type}?page=${page}&length=${length}&sort=${sort}&search=${search}`);
  }
  /**
   * schedule client request
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public scheduleClient(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/schedule-details', data);
  }

  /**
   * Retrieves a list of promo codes
   * @returns An Observable
   */
  public getPromoCodeList(): Observable<APIResponseModel> { 
    return this.http.get<APIResponseModel>(this.BASE_URL + `/professional/get-promo-codes`);
  }
  
}
