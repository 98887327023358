import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { APARTMENT_NUMBER_PATTERN, DATE_FORMAT, EMAIL_PATTERN, LOCAL_STORAGE_UPDATE_STATUS, NAME_PATTERN, PARTNER_RELATIONSHIP_STATUS, PHONE_PATTERN, SSN_MASK, SSN_PATTERN, TOGGLE_OPTIONS_YES_NO, UNITED_KINGDOM, USER_TYPES } from 'src/constants/application.const';

import { AddressDetails, ICountryCode, SelectOptionsInterface, SSNToggleInterface, ToggleOptionsInterface } from '../../../interface/common.interface';
import { PeopleService } from '../../../services/people.service';

import { Observable } from 'rxjs';
import { ELEMENT_EVENTS, STATUS_CHECK } from 'src/constants/form.const';
import { environment } from 'src/environments/environment';
import { AddressHelper } from '../../../helper/address.helper';
import { ValidationHelper } from '../../../helper/validation.helper';


@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css'],
})
/**
 * Partner component
 */
export class PartnerComponent implements OnInit {
  public readonly LOCALE = 'en';
  public readonly faEye = faEye;
  public readonly faEyeSlash = faEyeSlash;
  public readonly SSN_MASK = SSN_MASK;
  public readonly domainDateFormat: string = this.commonHelper.domainDateFormat;
  public readonly currentDateRestriction: string = this.commonHelper.getCurrentDateForRestriction();
  public showSSN: boolean;
  public toggleSSNView: SSNToggleInterface;
  public minDate:Date;
  public maxDate: Date;
  public bsConfig: Partial<BsDatepickerConfig>;
  public personId: string;
  public countryCode: ICountryCode;
  public readonly relationshipOptions: Array<SelectOptionsInterface> = PARTNER_RELATIONSHIP_STATUS;
  public relationshipForm: FormGroup;
  public partnerForm: FormGroup;
  public submitLoader: boolean[] = [false, false];
  public preDefinedLoader: boolean;
  public sectionSaveExitOptions: any;
  public userType: string;
  public requestId: string;
  public readonly toggleOptions: Array<ToggleOptionsInterface> = TOGGLE_OPTIONS_YES_NO;
  public enterPartnerInfo: number;
  public validatingSSN: number = ssnValidationProcess.initial;
  public confirmSSNMatch: boolean = true;
  public isUniqueSSN: boolean = false;
  public userDetails: any;
  public toggleEmitterListener: boolean = false; // toggle listener

  // // Address Variables
  public readonly unitedKingdom: string = UNITED_KINGDOM;
  private _setAddressDetails: AddressDetails;
  private isToggleChanged: boolean;
  public selectedCountryName: string = '';
  public defaultCountry: number;
  public selectedCountry: number;
  public addressStatus: number = 0;
  public searchElementRef: ElementRef;
  public elementRefSet: boolean = false;
  public redirectUrl: any;
  public redirectBtnText: string;
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;
  public isDisableForm: boolean;


  /**
   * @param{ElementRef}value
   */
  @ViewChild('search', { static: false })
  set setSearchElementRef(value: ElementRef) {
    this.searchElementRef = value;
    if (this.searchElementRef && !this.elementRefSet) { // if not elementRef set initial value for searchElement
      this.initMap(this.defaultCountry);
      this.elementRefSet = true;
    }
  }


  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private ngZone: NgZone,
    private addressHelper: AddressHelper,
    private personalDetailsService: PersonalDetailsService,
    private peopleService: PeopleService,
    private commonHelper: CommonHelper,
    private commonService: CommonService,
    private localeService: BsLocaleService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private slugInterceptorService: SlugInterceptorService,
    private validation: ValidationHelper,
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
    this.minDate = new Date(1900, 0, 1);
    this.minDate.setDate(this.minDate.getDate());
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    // Address related init
    this.selectedCountry = environment.APP_DOMAIN;
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    this.initMap(this.defaultCountry);
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;

    this.showSSN = environment.APP_DOMAIN == 1;
    this.toggleSSNView = { ssn: false, v_ssn: false };
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.userDetails = this.localStorageService.getUserData();
    this.sectionSaveExitOptions = {
      section: 1,
      type: 5,
    };
    this.enterPartnerInfo = 1;


    this.initForms();
    this.getPersonalDetails();
    this.localeService.use(this.LOCALE);

    this.bsConfig = {
      showWeekNumbers: false,
      minDate:this.minDate,
      maxDate: this.maxDate,
      dateInputFormat: this.domainDateFormat,
    };

    this.route.queryParams.subscribe((params) => {
      this.redirectUrl = params.redirectTo;
      this.redirectBtnText = this.commonHelper.getRedirectBtnText(this.redirectUrl);
    });
  }


  private initForms() {
    const NAME_VALIDATORS = [
      this.partnerRequiredOptional.bind(this),
      Validators.pattern(NAME_PATTERN),
      Validators.maxLength(50)
    ];
    
    const ADDRESS_VALIDATORS = [
      Validators.minLength(3),
      Validators.maxLength(255)
    ];
    
    const APT_NUMBER_VALIDATORS = [
      Validators.pattern(APARTMENT_NUMBER_PATTERN),
      Validators.maxLength(25)
    ];
    
    const EMAIL_VALIDATORS = [
      Validators.pattern(EMAIL_PATTERN)
    ];
    
    const PHONE_VALIDATORS = [
      Validators.pattern(PHONE_PATTERN)
    ];
    
    const SSN_VALIDATORS = [
      this.validation.fn.SSNPatternValidator(SSN_PATTERN)
    ];
    
    // Creating relationship form
    this.relationshipForm = this.formBuilder.group({
      marital_status: ['', [Validators.required]],
      enter_partner_info: [this.enterPartnerInfo],
    });
    
    // Creating Partner form
    this.partnerForm = this.formBuilder.group({
      first_name: ['', { updateOn: 'blur', validators: NAME_VALIDATORS }],
      middle_name: ['', { updateOn: 'blur', validators: [Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      last_name: ['', { updateOn: 'blur', validators: NAME_VALIDATORS }],
      dob: ['', { updateOn: 'blur', validators: [this.commonHelper.DOBValidator.bind(this)] }],
      ssn: ['', { updateOn: 'blur', validators: SSN_VALIDATORS }],
      ssn_validation: [''],
      phone: ['', { updateOn: 'blur', validators: PHONE_VALIDATORS }],
      email: ['', { updateOn: 'blur', validators: EMAIL_VALIDATORS }],
      relation: [1, { updateOn: 'blur' }],
      address: ['', { updateOn: 'blur', validators: ADDRESS_VALIDATORS }],
      apt_number: ['', { updateOn: 'blur', validators: APT_NUMBER_VALIDATORS }],
      country: ['', { updateOn: 'blur' }],
      state: ['', { updateOn: 'blur' }],
      city: ['', { updateOn: 'blur' }],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.maxLength(10)] }],
      address_status: [''],
      id: [''],
    });
    
    this.partnerForm.controls['ssn_validation'].valueChanges.subscribe(() => this.confirmSSNMatch = true);
    this.partnerForm.controls['country'].valueChanges.subscribe(value => this.selectedCountryName = this.commonHelper.getCountryNameById(value));
    
    this.validation.setValidationErrors({
      phone: { pattern: 'Please enter a valid phone number' },
      email: { pattern: 'Please enter a valid email address.' },
      dob: { isNotAllowed: 'Must be at least 18 years of age', invalidYear: 'Invalid Date' },
      ssn: { pattern: 'Invalid SSN', mask: null },
      zipcode: { pattern: 'Please enter a valid zipcode' }
    });
    
    const trimWhitespaceControls = ['first_name', 'middle_name', 'last_name', 'address', 'apt_number', 'city', 'zipcode', 'email'];
    
    trimWhitespaceControls.forEach(controlName => {
      this.partnerForm.controls[controlName].valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.partnerForm.controls[controlName])
      );
    });
    
  }


  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.partnerForm);
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getRelationshipFormErrors(controlKey: string):Array<string> {
    return this.validation.getErrors(controlKey, this.relationshipForm);
  }


  /**
   * Set up {@link partnerForm} using provided {@link data}.
   * @param data
   * @private
   */
  private setPartnerAddressInfo(data: any) {
    if (!data && !data?.id || data?.id == undefined) return;
    this.countryCode = data?.country_code && (data?.country_code != undefined || data?.country_code != null) ? { code: data?.flag_code, dialCode: data?.country_code } : this.countryCode;
    data.dob = this.commonHelper.formatDate(data.dob, this.domainDateFormat);
    this.addressStatus = data?.address_status;
    // 'this.isDisableForm' is a variable controlling the editability of the form.
    // Check if the partner is the executor custodian and is registered
    // Disable the form editing for the partner
    this.isDisableForm = data?.people_id != null;
    this.partnerForm.patchValue({
      ...data,
      ssn_validation: data.ssn,
      country: data?.country?.id,
      state: data?.state?.id,
      city: data?.city?.name,
      address_status: this.addressStatus,
    });
    this.sectionSaveExitOptions.id = data?.id;
    this.personId = data?.id;

    // Enable partner info form if info already exists.
    this.onChangeToggleProvideInfo({ value: 1 });

    this.removePacContainer();
    this.initMap(data?.country?.id);
  }


  /**
   * Get personal details
   */
  public getPersonalDetails(): void {
    const isConsumer: boolean = this.userType === 'Consumer';
    // get children list
    this.preDefinedLoader = true;
    this.personalDetailsService.getPersonalDetails(this.userType === 'Consumer' ? undefined : this.requestId).subscribe((response: APIResponseModel) => {
      this.preDefinedLoader = false;
      if (response.status) {
        // // Address
        if (isConsumer) {
          this.setAddressDetails = {
            address: response.data.address,
            country: response.data.country?.id,
            apt_number: response.data?.apt_number,
            state: response.data.state?.id,
            city: response.data.city?.name,
            zipcode: response.data?.zipcode,
          };
        }


        this.sectionSaveExitOptions.id = response.data.user_id;
        if (response?.data?.marital_status != 0) {
          this.relationshipForm.patchValue({
            marital_status: response?.data?.marital_status?.toString(),
          });
        } else {
          this.relationshipForm.patchValue({
            marital_status: '',
          });
        }
        this.updateUI();

        const partnerDetail = response.data.user_people.filter((data: any) => data.relation === 1)[0];
        console.log('partnerDetail', partnerDetail);
        if (!!partnerDetail) {
          this.setPartnerAddressInfo(partnerDetail);
        } else {
          this.personId = this.userDetails?.request?.id; // set request id if partner adding initially for UniqueSSN Validation
          this.partnerForm.patchValue({ // when no partner details , add default country value
            country: this.defaultCountry,
          });
        }
        if (this.partnerForm.controls.ssn.value && this.checkSSNMatch()) { // check is SSN unique
          this.checkIsSsnUnique(this.partnerForm.controls.ssn.value, this.personId);
        }
      }
    }, () => {
      this.preDefinedLoader = false;
    });
  }


  /**
   * Listen for option change (salutation)
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeListener(selectedOption: SelectOptionsInterface): void {
    if (selectedOption?.value) {
      this.elementRefSet = false;
      this.relationshipForm.patchValue({ marital_status: selectedOption.value });
      if (this.isWidowed || !this.showSSN) {
        this.partnerForm.get('ssn').setValue('');
        this.partnerForm.get('ssn_validation').setValue('');
      }
      if (this.toggleEmitterListener) {
        this.relationshipForm.get('marital_status').markAsUntouched();
      }
    } else {
      if (this.toggleEmitterListener) {
        this.relationshipForm.get('marital_status').markAsTouched();
      }
    }
    this.toggleEmitterListener = false;
    this.updateUI();
  }

  /**
   * Detect change on phone number component
   *
   * @param event
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }


  private getPartnerPayload(): Observable<Object> {
    const isConsumer = this.userType === 'Consumer';
    return new Observable<Object>((observer) => {
      const payload: {} = {
        ...this.partnerForm.value,
        roletype: this.userType,
        country_code: this.countryCode.dialCode,
        flag_code: this.countryCode.code,
       is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1'
      };
      if (!isConsumer) {
        payload['request_id'] = this.requestId;
      }
      this.commonService.getCityDetail(payload['country'], payload['state'], payload['city']).subscribe({
        next: (response) => {
          payload['city'] = response.data.id;
          observer.next(payload);
        },
        error: (e) => observer.error(e),
        complete: () => observer.complete(),
      });
    });
  }


  /**
   * Handle on submit
   * @param {number} loaderIndex
   */
  public onSubmit(loaderIndex: number): void {
    const isConsumer = this.userType === 'Consumer';
    if (this.enterPartnerInfo) {
      if (this.isWidowed) {
        this.updateFormWidow();
      }
      this.partnerForm.markAllAsTouched();
      if (!this.partnerForm.valid) {
        this.commonHelper.scrollToFirstInvalidControl(this.partnerForm);
        return; // return if the form is invalid
      }
      this.confirmSSNMatch = this.partnerForm.controls['ssn'].value === this.partnerForm.controls['ssn_validation'].value;
      if (!this.confirmSSNMatch) {
        return;
      }
    } else {
      // not choosing to provide info, reset form
      this.partnerForm.reset();
    }
    this.submitLoader[loaderIndex] = true;

    this.partnerForm.value.dob = this.commonHelper.formatDate(this.partnerForm.value.dob, DATE_FORMAT);
    const payloadRelationship: {} = {
      ...this.relationshipForm.value,
      roletype: this.userType,
      enter_partner_info: this.enterPartnerInfo,
    };

    if (!isConsumer) {
      payloadRelationship['request_id'] = this.requestId;
    }

    const errorFn = (exception: any) => {
      this.submitLoader[loaderIndex] = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    };
    this.getPartnerPayload().subscribe({
      next: (r) => {
        const payloadPartner = r;
        console.log('payloadPartner', r);

        this.personalDetailsService.storePersonalDetails(payloadRelationship, 4).subscribe((response: APIResponseModel) => {
          if (response.status) {
            if (isConsumer) {
              this.commonHelper.updateLocalstorageRequestStage(response.data);
            }

            // Update marital status for Consumer
            this.localStorageService.updateUserData(USER_TYPES.user, {
              key: 'request',
              updateValue: response.data.marital_status,
              updateKey: 'marital_status',
              type: LOCAL_STORAGE_UPDATE_STATUS.O,
            });
            // ... for Professional
            this.localStorageService.storeData('client_marital_status', response.data.marital_status);

            if (this.enterPartnerInfo) {
              this.peopleService.storePeopleDetails(payloadPartner).subscribe({
                next: (response) => {
                  this.submitLoader[loaderIndex] = false;
                  if (response.status && isConsumer) {
                    this.commonHelper.updateLocalstorageRequestStage(response.data);
                  }
                  this.nextPage(loaderIndex);
                },
                error: errorFn,
              });
            }
            if (!this.enterPartnerInfo) {
              this.nextPage(loaderIndex);
            }
          }
        }, errorFn);
      },
    });
  }


  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }


  /**
   *
   * @param{any} event
   */
  public onChangeToggleProvideInfo(event: any) {
    this.enterPartnerInfo = event.value;
    // Hided because of updated country not getting in form
    // this.elementRefSet = false;
    // if (this.enterPartnerInfo) {
    //   this.partnerForm.patchValue({
    //     country: this.defaultCountry,
    //   });
    //   console.log(this.partnerForm.controls['country'].value, 'onChangeToggleProvideInfo');

    // }
    this.updateUI();
  }


  /**
   * On Change toggle
   *
   * @param {ToggleOptionsInterface} selectedValue
   */
  public onChangeToggleAddress(selectedValue: ToggleOptionsInterface): void {
    this.isToggleChanged = true;
    this.elementRefSet = false;
    if (!selectedValue) return;
    this.addressStatus = selectedValue?.value;
    if (!this.addressStatus) {
      this.partnerForm.patchValue({
        country: this._setAddressDetails?.['country'],
      });
    }
    this.updateAddressInfo();
    this.removePacContainer();
    this.initMap(this._setAddressDetails?.['country']);
  }


  /**
   * form object getter for validation and showing errors in html
   */
  get getPartnerForm() {
    return this.partnerForm.controls;
  }


  /**
   * form object getter for validation and showing errors in html
   */
  get getRelationshipForm() {
    return this.relationshipForm.controls;
  }

  /**
   * get Age
   * @param {string}date
   * @return {number}
   */
    public getAge(date: string) {
      return this.commonHelper.getAge(date);
    }


  /**
   *
   * @return{boolean}
   */
  public checkSSNMatch(): boolean {
    return this.partnerForm.controls['ssn'].value == this.partnerForm.controls['ssn_validation'].value;
  }


  /**
   * typing / onchange input field
   * changed as untouched field
   * not show validation error
   * @param {any} data
   * @param {string} key
   */
  public onChange(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.INPUT) {
      this.partnerForm.get(key).markAsUntouched();
      this.validatingSSN = ssnValidationProcess.initial;
    }
  }


  /**
   * focusout of input field
   * changed as touched field
   * check and show validation error
   * @param {any} data
   * @param {string} key
   */
  public onFocusout(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.FOCUSOUT) {
      this.partnerForm.get(key).markAllAsTouched();
    }
    const isSSNValid = this.partnerForm.controls.ssn.value ? this.partnerForm.controls.ssn.status == STATUS_CHECK.VALID : false;
    if (isSSNValid && this.checkSSNMatch()) { // if SSN status valid and ssn and confirmation ssn are same
      this.checkIsSsnUnique(this.partnerForm.controls.ssn.value, this.personId);
    }
  }


  /**
   * check ssn is unique
   * @param {string} data
   * @param id
   */
  public checkIsSsnUnique(data: string, id: string) {
    this.validatingSSN = ssnValidationProcess.start_validation;
    this.commonService.getIsSSNUnique(data, id, this.commonHelper.isShowSSNToast()).subscribe({
      next: (r) => {
        this.isUniqueSSN = r;
        this.validatingSSN = ssnValidationProcess.end_validation;
      },
      error: () => {
        this.validatingSSN = ssnValidationProcess.end_validation;
      },
    });
  }


  /**
   * Auto hypen SSN keyup
   */
  public autoHypenSSN(): void {
    this.partnerForm.patchValue({
      ssn: this.commonHelper.autoHypenSSN(this.partnerForm.value.ssn),
    });
  }


  /**
   * Navigate to the next page.
   * @private
   */
  private nextPage(loaderIndex:number) {
    if (!!this.redirectUrl) {
      void this.slugInterceptorService.navigate([this.redirectUrl]);
      this.redirectUrl = '';
      return;
    }
    const url = ['about-yourself', 'children'];
    const isConsumer = this.userType === 'Consumer';
    if (!isConsumer) {
      url.push(this.requestId);
    }
    void this.slugInterceptorService.navigate(url);
  }


  /**
   * dob validation
   * @param {FormControl}control
   * @return {boolean}
   */
  public partnerRequiredOptional(control: AbstractControl): ValidationErrors | null {
    if (control && this.enterPartnerInfo === 1) {
      return Validators.required(control);
    } else {
      return null;
    }
  }


  /**
   * getter for show toggle
   */
  public get showToggle(): boolean {
    return this.relationshipForm?.value?.['marital_status'] != 1 && this.relationshipForm?.value?.['marital_status'] != '';
  }


  /**
   * getter for form valid status
   */
  public get isSubmitValid(): boolean {
    return this.relationshipForm?.value?.['marital_status'] && (this.enterPartnerInfo && !this.isWidowed ? this.checkSSNValidation : true);
  }


  /**
   * getter for ssn validation status
   */
  private get checkSSNValidation(): boolean {
    return this.validatingSSN !== 1 && (this.partnerForm.controls.ssn.value ? this.isUniqueSSN : !this.partnerForm.controls.ssn_validation.value);
  }


  /**
   * Update validation checks on {@link partnerForm}, handle logic for show/hide <code>partnerForm</code>.
   * @private
   */
  private updateUI() {
    Object.keys(this.partnerForm.controls).forEach((key) => this.partnerForm.get(key).updateValueAndValidity());
    if(!this.partnerForm.controls['country'].value) {
        this.partnerForm.patchValue({
          'country': this.defaultCountry
        })
        this.removePacContainer();
        this.initMap(this.defaultCountry);
    }
    // Set 'provide marital info' switch to false if single or unselected.
    if (!this.showToggle) {
      this.enterPartnerInfo = 0;
    }
  }


  /**
   * Updates form widow marital status
   */
  private updateFormWidow(): void {
    const notRequiredFields = ['ssn', 'ssn_validation', 'phone', 'email', 'relation', 'address', 'apt_number', 'country', 'state', 'city', 'zipcode'];
    notRequiredFields.forEach((cur) => {
      cur !== 'relation' ? this.partnerForm.get(cur).setValue('') : this.partnerForm.get(cur).setValue(1);
      this.partnerForm.get(cur).updateValueAndValidity();
    });
  }


  /**
   * toggle emitter
   * @param {boolean} data
   */
  public toggleEmiter(data: boolean) {
    if (data) {
      this.toggleEmitterListener = true; // toggle listener
    }
  }


  public get isWidowed(): boolean {
    return this.relationshipForm.get('marital_status').value == '4';
  }


  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry) return;
    this.partnerForm.controls.state.reset();
    this.partnerForm.patchValue({
      country: selectedCountry.value,
    });
    this.removePacContainer();
    this.initMap(selectedCountry.value);
  }


  /**
   * remove pac container
   *
   */
  private removePacContainer() {
    this.addressHelper.removeAutocompletePacContainer();
  }


  /**
   * Initialize google map api by country
   * @param {any} countryId
   */
  public initMap(countryId: any): void {
    if (this.searchElementRef?.nativeElement) {
      this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
        next: (r) => {
          this.ngZone.run(() => {
            const { city, state, zipcode, address } = r;
            this.partnerForm.patchValue({ city, state, zipcode, address });
          });
        },
        error: (e) => console.error(e),
      });
    }
  }


  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.partnerForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }


  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.partnerForm.controls.city.reset();
    this.partnerForm.patchValue({
      state: selectedState.value,
    });
  }


  /**
   * listen for options changes state
   *
   * @param {string} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.partnerForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.partnerForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([Validators.pattern(new RegExp(selectedCity?.additionalDetails[0]?.postal_code_regex)), Validators.required]);
      zipCodeRef.updateValueAndValidity();
    }
  }


  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.getPartnerForm[property].markAsTouched({ onlySelf: true });
  }


  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.partnerForm.get('zipcode');
    const stateId = this.partnerForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        if (response.data.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          zipCodeRef.setValidators([Validators.nullValidator, Validators.pattern(new RegExp(zipCodeRegex)), Validators.required]);
          zipCodeRef.updateValueAndValidity();
        } else {
          zipCodeRef.setValidators([Validators.nullValidator]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }


  /**
   * Update {@link partnerForm} based on current {@link addressStatus}.
   */
  private updateAddressInfo() {
    const addressData = { ...this._setAddressDetails };
    const addressFields = ['country', 'address', 'zipcode', 'state', 'city'];

    if (!this.addressStatus && this.isToggleChanged) {
      // Handle the case when editing and there address details already patched.
      // removing country element from array because we already set country from the parent's this._addressDetails data
      addressFields.slice(1).forEach((control) => {
        this.partnerForm.get(control).patchValue('');
        this.partnerForm.get('apt_number').patchValue('');
      });
      this.partnerForm.patchValue({
        address_status: this.addressStatus,
      });
    } else {
      this.partnerForm.patchValue({
        country: addressData?.['country'],
        address: addressData?.['address'],
        apt_number: addressData?.['apt_number'],
        zipcode: addressData?.['zipcode'],
        state: addressData?.['state'],
        city: addressData?.['city']?.toString(),
        address_status: this.addressStatus,
      });
    }
    if (!this.addressStatus) {
      this.getPartnerForm['apt_number'].reset();
      addressFields.forEach((fieldName) => this.getPartnerForm[fieldName].reset());
      this.getPartnerForm['country'].patchValue(this.defaultCountry);
    }
  }


  set setAddressDetails(value: AddressDetails) {
    this._setAddressDetails = value;
    this.defaultCountry = this._setAddressDetails?.['country'];
    this.setSearchElementRef = this.searchElementRef;
  }


  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }


  /**
   * Handling clicking 'back' button.
   */
  onClickBack() {
    const reqID = this.userType !== 'Consumer' ? `/${this.requestId}` : '';
    let url = `/about-yourself/location-details${reqID}`;
    this.changeRoute(url);
  }
}


/**
 * enum for ssn validation processing
 */
enum ssnValidationProcess {
  initial,
  start_validation,
  end_validation
}
