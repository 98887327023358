<div class="relative flex items-center">
  <button class="absolute ml-4 outline-none" type="button" (click)="onClickFlag($event)">
    <img [src]="'assets/images/flags/'+ selectedCountry?.code?.toLowerCase()+'.png'" alt="" class="img-responsive">
  </button>
  <input type="text" [id]="uniqueId"
         [class]="inputClass[!!classList+'']"
         [placeholder]="placeholder || 'Enter Phone number'" [(ngModel)]="phoneNumber"
         (keyup)="keyUpPhoneNumber()"
         [mask]="PHONE_MASK" [attr.disabled]="disabled || undefined"
         [ngClass]="errorClassTrigger ? 'form-input-error' : 'form-input-focus'">
</div>

<div class="absolute mt-4" *ngIf="toggleList">
  <ul class="input relative border up-arrow bg-white rounded-xs z-20 h-48 overflow-y-auto">
    <li class="cursor-pointer px-3 text-grey text-sm py-2 select-option flex flex-row"
        *ngFor="let data of countryDetailsList; let i = index" (click)="selectCountry(i,$event)"
        [ngClass]="{ 'list-active-bg': selectedCountry?.['dialCode'] === data?.['dialCode'] }">
      <p>
        <span class="pr-4">
          <img class="inline" [src]="'assets/images/flags/'+ data.code.toLowerCase()+'.png'"
               alt="LegacyNow">
        </span>
        <span>{{data?.name}}</span>
        <span class="pl-4 text-grey">{{data?.['dialCode']}}</span>
      </p>
      <img src="assets/images/about-yourself/list-selected.svg" class="w-4 pl-1" alt="digital vault"
           *ngIf="selectedCountry?.code === data?.code">
    </li>
  </ul>
</div>
