import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-consumer-landing-content-new',
  templateUrl: './consumer-landing-content-new.component.html',
  styleUrls: ['./consumer-landing-content-new.component.css'],
})
/**
 * consumer landing page which contains content
 */
export class ConsumerLandingContentNewComponent implements OnInit {
  public mobile: boolean;
  public innerWidth: number;
  public menuActive: string;
  public readonly VIDEO_MODAL_ID = 'app-consumer-landing-content-new-demo-video-modal';
  public readonly VAULT_VIDEO = 'onSiteGuide';

  /**
   * constructor
   */
  constructor(
    private slugInterceptorService: SlugInterceptorService,
    private commonService: CommonService,
    private modalService: CommonModelService,
    private route: ActivatedRoute,
    private commonHelper: CommonHelper,
  ) {
  }

  /**
   * Getter for show Get Started
   */
  get showGetStarted(): boolean {
    return !!(!this.commonHelper.getOrgLogo());
  }

  /**
   * Trigger when user presses a key while focused on the 'onSiteGuide' video trigger container.
   * If the user pressed space bar or enter, the video modal will be opened.
   */
  onKeyDownOnSiteGuide(event: KeyboardEvent) {
    const triggerKeys = [' ', 'Spacebar', 'Enter'];
    if (triggerKeys.indexOf(event.key) >= 0) {
      this.openModal();
    }
  }

  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.onResize();
    this.route.queryParams.subscribe((params) => {
      if (params.scrollTestimonial) {
        this.scrollTestimonialSection();
      }
    });
  }

  /**
   * window resize
   */
  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
    this.mobile = this.innerWidth <= 767;
  }

  /**
   * @param{string}url
   * on menu change
   */
  public menuChange(url: string) {
    this.menuActive = url;
    void this.slugInterceptorService.navigateByUrl(url);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }

  public openModal() {
    this.modalService.open(this.VIDEO_MODAL_ID);
  }

  /**
   * scroll to testimonial
   */
  public scrollTestimonialSection() {
    const testimonialSection = document.getElementById('testimonialSection');
    testimonialSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  /**
   * Scrolls to the 'aboutDigitalVault' section on the page.
   */
  public onClickLearnMore() {
    const aboutDVSection = document.getElementById('introduceLegacy');
    aboutDVSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
