
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RecaptchaModule } from 'ng-recaptcha';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { TooltipModule } from 'ng2-tooltip-directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPlaidLinkModule } from 'ngx-plaid-link';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { TreeviewModule } from 'ngx-treeview';
import { PaymentFailureComponent } from '../components/consumer-payment-section/consumer-payment-card/payment-failure/payment-failure.component';
import { PaymentProcessingComponent } from '../components/consumer-payment-section/consumer-payment-card/payment-processing/payment-processing.component';
import { PaymentSuccessComponent } from '../components/consumer-payment-section/consumer-payment-card/payment-success/payment-success.component';
import { AccessControlComponent } from '../components/executor-beneficiaries/professional-user-type/access-control/access-control.component';
import { DirectivesModule } from '../directives/directives.module';
import { ExeCusNavbarModule } from '../executor-custodian/pages/exe-cus-navbar/exe-cus-navbar.module';
import { PipeModule } from '../pipes/pipes.module';
import { TextFilterListPipe } from '../pipes/text-filter-list.pipe';
import { AidaFloatingVideoComponent } from './aida-floating-video/aida-floating-video.component';
import { AddBeneficiaryComponent } from './assign-beneficiary-modal/add-beneficiary/add-beneficiary.component';
import { AssignBeneficiaryModalComponent } from './assign-beneficiary-modal/assign-beneficiary-modal.component';
import { BubbleRadioComponent } from './bubble-radio/bubble-radio.component';
import { ButtonLoaderTwoComponent } from './button-loader-two/button-loader-two.component';
import { ButtonLoaderComponent } from './button-loader/button-loader.component';
import { CardProgressBarComponent } from './card-progress-bar/card-progress-bar.component';
import { CarousalComponent } from './carousal/carousal.component';
import { ChatStatusHandlerComponent } from './chat-status-handler/chat-status-handler.component';
import { ChipListPeopleComponent } from './chip-list-people/chip-list-people.component';
import { ChipsComponent } from './chips/chips.component';
import { ChoosePeopleComponent } from './choose-people-component/choose-people.component';
import { CitySearchComponent } from './city-search/city-search.component';
import { CommonModalComponent } from './common-modal/common-modal.component';
import { CountrySearchComponentComponent } from './country-search-component/country-search-component.component';
import { DataLinkServiceInfoModalComponent } from './data-link-service-info/data-link-service-info-modal.component';
import { DatepickerToggleComponent } from './datepicker-toggle/datepicker-toggle.component';
import { DeleteChatConfirmationModalComponent } from './delete-chat-confirmation-modal/delete-chat-confirmation-modal.component';
import { DeleteConfirmationModalComponent } from './delete-confirmation-modal/delete-confirmation-modal.component';
import { DemoVideoModalComponent } from './demo-video-modal/demo-video-modal.component';
import { DigitalVaultUsersAgreementModalComponent } from './digital-vault-users-agreement-modal/digital-vault-users-agreement-modal.component';
import { FileDownloaderComponent } from './file-downloader/file-downloader.component';
import { FileListingComponent } from './file-listing/file-listing.component';
import { FileUploadHandlerTwoComponent } from './file-upload-handler-two/file-upload-handler-two.component';
import { FileUploadHandlerComponent } from './file-upload-handler/file-upload-handler.component';
import { IdVerifyOptionsModalComponent } from './id-verify-options-modal/id-verify-options-modal.component';
import { ImageComponentComponent } from './image-component/image-component.component';
import { ImageViewPopupComponent } from './image-view-popup/image-view-popup.component';
import { ImportFromPlaidOptionComponent } from './import-from-plaid-option/import-from-plaid-option.component';
import { InputSuggestionComponent } from './input-suggestion/input-suggestion.component';
import { ListPeopleComponent } from './list-people/list-people.component';
import { MessageModalComponent } from './message-modal/message-modal.component';
import { PaymentStatusModalComponent } from './payment-status-modal/payment-status-modal.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { RegisterCarouselComponent } from './register-carousel/register-carousel.component';
import { RightCarouselIntroComponent } from './right-carousel-intro/right-carousel-intro.component';
import { SelectBeneficiaryRatioComponent } from './select-beneficiary-ratio/select-beneficiary-ratio.component';
import { SelectComponentComponent } from './select-component/select-component.component';
import { SelectSearchComponentt } from './select-search/select-search.component';
import { SelectUserPersonComponent } from './select-user-person/select-user-person.component';
import { SelectWithSearchComponent } from './select-with-search/select-with-search.component';
import { ShimmerLoadingComponent } from './shimmer-loading/shimmer-loading.component';
import { SocialSharePopupComponent } from './socical-share-popup/social-share-popup.component';
import { StateSearchComponentComponent } from './state-search-component/state-search-component.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { TitleCaseExceptPipe } from './title-case-except-pipe.pipe';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { UserAccessEnableListComponent } from './user-access-enable-list/user-access-enable-list.component';
import { VideoViewPopupComponent } from './video-view-popup/video-view-popup.component';

@NgModule({
  declarations: [
    PaymentSuccessComponent,
    PaymentFailureComponent,
    CarousalComponent,
    AddBeneficiaryComponent,
    RightCarouselIntroComponent,
    RegisterCarouselComponent,
    ButtonLoaderComponent,
    CountrySearchComponentComponent,
    StateSearchComponentComponent,
    CitySearchComponent,
    TextFilterListPipe,
    SelectSearchComponentt,
    SelectComponentComponent,
    SelectWithSearchComponent,
    CommonModalComponent,
    ShimmerLoadingComponent,
    FileUploadHandlerComponent,
    ImageViewPopupComponent,
    ImageComponentComponent,
    FileUploadHandlerTwoComponent,
    ButtonLoaderTwoComponent,
    ChipsComponent,
    FileDownloaderComponent,
    ToggleSwitchComponent,
    CardProgressBarComponent,
    DeleteConfirmationModalComponent,
    TitleCaseExceptPipe,
    VideoViewPopupComponent,
    SocialSharePopupComponent,
    DeleteChatConfirmationModalComponent,
    ChatStatusHandlerComponent,
    MessageModalComponent,
    AccessControlComponent,
    SelectUserPersonComponent,
    ListPeopleComponent,
    InputSuggestionComponent,
    UserAccessEnableListComponent,
    DemoVideoModalComponent,
    AssignBeneficiaryModalComponent,
    ChoosePeopleComponent,
    IdVerifyOptionsModalComponent,
    PhoneInputComponent,
    TestimonialsComponent,
    FileListingComponent,
    AidaFloatingVideoComponent,
    BubbleRadioComponent,
    SelectBeneficiaryRatioComponent,
    ImportFromPlaidOptionComponent,
    DatepickerToggleComponent,
    DataLinkServiceInfoModalComponent,
    DigitalVaultUsersAgreementModalComponent,
    PaymentStatusModalComponent,
    PaymentProcessingComponent,
    PaymentSuccessComponent,
    PaymentFailureComponent,
    ChipListPeopleComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    NgxMaskModule,
    NgxShimmerLoadingModule,
    NgxFileDropModule,
    Ng2TelInputModule,
    PipeModule,
    ClipboardModule,
    TreeviewModule,
    FontAwesomeModule,
    TooltipModule,
    BsDatepickerModule,
    ExeCusNavbarModule,
    DirectivesModule,
    NgxPlaidLinkModule,
    ShareButtonsModule,
    ShareIconsModule,
  ],
  exports: [
    PaymentSuccessComponent,
    PaymentFailureComponent,
    CarousalComponent,
    RightCarouselIntroComponent,
    RegisterCarouselComponent,
    ButtonLoaderComponent,
    CountrySearchComponentComponent,
    StateSearchComponentComponent,
    CitySearchComponent,
    TextFilterListPipe,
    SelectSearchComponentt,
    SelectComponentComponent,
    SelectWithSearchComponent,
    CommonModalComponent,
    ShimmerLoadingComponent,
    FileUploadHandlerComponent,
    ImageViewPopupComponent,
    ImageComponentComponent,
    FileUploadHandlerTwoComponent,
    ButtonLoaderTwoComponent,
    ChipsComponent,
    ChipListPeopleComponent,
    FileDownloaderComponent,
    CardProgressBarComponent,
    ToggleSwitchComponent,
    CommonModalComponent,
    DeleteConfirmationModalComponent,
    VideoViewPopupComponent,
    SocialSharePopupComponent,
    ClipboardModule,
    DeleteChatConfirmationModalComponent,
    ChatStatusHandlerComponent,
    MessageModalComponent,
    AccessControlComponent,
    SelectUserPersonComponent,
    ListPeopleComponent,
    InputSuggestionComponent,
    DemoVideoModalComponent,
    TooltipModule,
    UserAccessEnableListComponent,
    AddBeneficiaryComponent,
    AssignBeneficiaryModalComponent,
    ChoosePeopleComponent,
    IdVerifyOptionsModalComponent,
    PhoneInputComponent,
    TestimonialsComponent,
    TitleCaseExceptPipe,
    FileListingComponent,
    FileListingComponent,
    AidaFloatingVideoComponent,
    BubbleRadioComponent,
    SelectBeneficiaryRatioComponent,
    ImportFromPlaidOptionComponent,
    DatepickerToggleComponent,
    DataLinkServiceInfoModalComponent,
    DigitalVaultUsersAgreementModalComponent,
    PaymentStatusModalComponent,
    PaymentProcessingComponent,
    PaymentSuccessComponent,
    PaymentFailureComponent,
  ],
  providers: [],
})
/**
 * professional dashboard module
 */
export class SharedModule {
}
