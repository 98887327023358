import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-consumer-landing-intro',
  templateUrl: './consumer-landing-intro.component.html',
  styleUrls: ['./consumer-landing-intro.component.css'],
})
/**
 * landing
 */
export class ConsumerLandingIntroComponent implements OnInit {
  public menuActive: string;
  public isFooterLink: boolean;
  private innerWidth: number;
  private currentUrl:string;

  /**
   * constructor
   */
  constructor(
    private slugInterceptorService: SlugInterceptorService,
    private commonService: CommonService,
    private router: Router) { }

  /**
   * loaded initialy
   */
  ngOnInit(): void {
    this.updateInnerWidth();
  }
  /**
   * isSmallWindow
   */
  get isSmallWindow(): boolean {
    return this.innerWidth < 800;
  }
  /**
   * updateInnerWidth
   */
  private updateInnerWidth() {
    this.innerWidth = window.innerWidth;
    console.debug('inner width', this.innerWidth);
  }
  /**
   * windowResizeListener
   */
  @HostListener('window:resize', [])
  windowResizeListener() {
    this.updateInnerWidth();
  }

  /**
   * @param{string}url
   * on menu change
   */
  public menuChange(url?: string) {
    this.menuActive = url;
    if (url === '') {
      void this.slugInterceptorService.navigate(['/']);
    }
    this.isFooterLink = false;

    void this.slugInterceptorService.navigate(url ? [url] : ['/']);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }

  /**
 * Handles click event to proceed to the next page.
 */
  public onClickContinue() {
    this.currentUrl = this.router.url.split('/').pop();
    const nextPageUrl = this.currentUrl === 'landing-intro' ? 'landing-steps' : 'educational-intro2';
    this.menuChange(nextPageUrl);
  }
}
