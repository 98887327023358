import { Component, OnInit } from '@angular/core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-create-subscription-account',
  templateUrl: './create-subscription-account.component.html',
  styleUrls: ['./create-subscription-account.component.css'],
})
export class CreateSubscriptionAccountComponent implements OnInit {
  public togglePasswordView = false;
  public readonly faEye = faEye;
  public readonly faEyeSlash = faEyeSlash;
  constructor(
   
  ) {}

  ngOnInit(): void {}


}
