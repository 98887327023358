import {Component, OnInit} from '@angular/core';
import {SlugInterceptorService} from 'src/app/helper/slug-interceptor.service';
import {LocalStorageService} from 'src/app/services/local-storage.service';
import {USER_TYPES} from "../../../../../constants/application.const";

@Component({
  selector: 'app-professional-unauthorized',
  templateUrl: './professional-unauthorized.component.html',
})

/**
 * Professional Unauthorized Component
 */
export class ProfessionalUnauthorizedComponent implements OnInit {
  public loggedIntype: string;

  /**
   * constructor
   */
  constructor(
    private localStorageService:LocalStorageService,
    private slugInterceptorService:SlugInterceptorService,
  ) {

  }

  /**
   * loaded on initialization
   */
  public ngOnInit(): void {
    this.checkType();
  }

  /**
   * check type of logged  in user
   */
  public checkType() {
    const consumer = this.localStorageService.getUserData();
    const professional = this.localStorageService.getUserData(USER_TYPES.pro);
    const organization = this.localStorageService.getUserData(USER_TYPES.org);
    if (Object.keys(consumer).length > 0) {
      this.loggedIntype = 'Consumer';
    } else if (Object.keys(professional).length > 0) {
      this.loggedIntype='Professional';
    } else if (Object.keys(organization).length > 0) {
      this.loggedIntype='Organization';
    }
  }

  /**
     * change route
     * @param{string}url
      */
  public changeRoute(url:string) {
    void this.slugInterceptorService.navigate([ url], null, true);
  }
}
