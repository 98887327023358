import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { requirePasswordToBeCheckedValidator } from 'src/app/helper/customValidatorPassword.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { FileValidationOptionsInterface, ICountryCode, SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { SlugService } from 'src/app/services/slug.service';
import {
  EMAIL_PATTERN,
  LOCAL_STORAGE_UPDATE_STATUS,
  NAME_PATTERN,
  PHONE_PATTERN,
  PROFESSIONAL_ROLE,
  UNITED_KINGDOM,
  USER_TYPES,
} from 'src/constants/application.const';
import { CLEAR_SELECTED_FILE, SET_IMAGE } from 'src/constants/digitalFile.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../../helper/validation.helper';
import { LocalStorageService } from '../../../../services/local-storage.service';

@Component({
  selector: 'app-professional-account-settings',
  templateUrl: './professional-account-settings.component.html',
  styleUrls: ['./professional-account-settings.component.css'],
})
/**
 * professional user account settings
 */
export class ProfessionalAccountSettingsComponent implements OnInit {
  @ViewChild('propicfield') propicfield: ElementRef;
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;

  public accountDetailForm: FormGroup;
  public profilePictureForm: FormGroup;
  public accountDetailsSubmit: boolean;
  public editDetailsForm: FormGroup;
  public changePasswordForm: FormGroup;
  public changePasswordSubmit: boolean;
  public faEye: any;
  public faEyeSlash: any;
  public togglePasswordView: boolean;
  public toggleOldPasswordView: boolean;
  public toggleNewPasswordView: boolean;
  public toggleNewPasswordViewConfirm: boolean;
  public menuActive: number = 1;
  public imageSrc: string;
  public uploadForm: FormGroup;
  public preDefinedLoader: boolean;
  public accountDetails: any;
  public changeEmail: boolean;
  private availableMenu: number;
  public currentUser: any;
  public userData: any;
  public roleName: string;
  public readonly PROFESSIONAL_ROLE = PROFESSIONAL_ROLE;
  public countryCode: ICountryCode;
  public loginLoader: boolean;
  public editUserID: string;
  public editDetailsSubmit: boolean;
  public fileValidationOptions: FileValidationOptionsInterface;
  public editData: boolean;
  public profilePicLoader: boolean;
  public fileName: string;
  public defaultCountry: number;
  public file: any;
  public shouldLastnameShown: boolean;
  public selectedCountry: number;
  public changePasswordMatch: boolean = true;
  public selectedCountryName: string = '';
  public unitedKingdom: string = UNITED_KINGDOM;
  public showVerificationCodes: boolean;
  public newEmail: string;
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;


  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private formBuilder: FormBuilder,
    private commonHelper: CommonHelper,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private activeRouter: ActivatedRoute,
    private route: Router,
    private slugService: SlugService,
    private professionalUserService: ProfessionalUserService,
    private localStorageService: LocalStorageService,
    private addressHelper: AddressHelper,
    private slugInterceptorService: SlugInterceptorService,
    private ngZone: NgZone,
    private validation: ValidationHelper
  ) {
  }

  /**
   * Account Detail Form called initially on page load
   *
   */
  ngOnInit(): void {
    this.selectedCountry = environment.APP_DOMAIN;
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    this.professionalUserService.currentUserObserve?.subscribe(
      data => this.setUserData(data ?? this.localStorageService.getUserData(USER_TYPES.pro).user),
    );
    this.roleName = this.userData.role_name;
    this.loginLoader = false;
    this.editData = false;
    this.availableMenu = 3;
    this.fileValidationOptions = {
      size: 100000,
      profilePicSize: 200000,
      fileFormat: ['jpeg', 'jpg', 'png'],
    };
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    // If search element not loaded initially
    setTimeout(() => {
      this.initMap(this.defaultCountry);
    }, 0);
    this.initAccountDetailForm();
    this.activeRouter.queryParams.subscribe(
      (queryParam: Params) => {
        this.menuActive = +queryParam['menu'];
      },
    );

    this.faEye = faEye;
    this.editDetailsSubmit = false;
    this.changeEmail = false;
    this.faEyeSlash = faEyeSlash;
    this.togglePasswordView = false;
    this.toggleOldPasswordView = false;
    this.toggleNewPasswordView = false;
    this.toggleNewPasswordViewConfirm = false;
    this.profilePictureForm = this.formBuilder.group({
      profile_picture: [''],
    });
    // add agent form
    this.editDetailsForm = this.formBuilder.group({
      first_name: [
        '',
        {
          updateOn: 'blur', validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(NAME_PATTERN), this.validation.fn.trim,

          ],
        },
      ],
      middle_name: [
        '',
        {
          updateOn: 'blur', validators: [
            Validators.maxLength(50),
            Validators.pattern(NAME_PATTERN), this.validation.fn.trim,

          ],
        },
      ],
      last_name: [
        '',
        {
          updateOn: 'blur', validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(NAME_PATTERN), this.validation.fn.trim,

          ],
        },
      ],
      phone: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(PHONE_PATTERN)] }],
      street_address: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(255), this.validation.fn.trim] }],
      city: ['', { updateOn: 'blur', validators: [Validators.required, this.validation.noWhitespaceValidator()] }],
      country: ['', { updateOn: 'blur', validators: [Validators.required] }],
      state: [''],
      zipcode: [
        '',
        {
          updateOn: 'blur', validators: [
            Validators.required,
            Validators.maxLength(10),
          ],
        },
      ],
      upload_proof: [''],
      is_image_deleted: ['0'],
    });
    this.changePasswordForm = this.formBuilder.group({
      password: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(6)] }],
      new_password: ['', [Validators.required, Validators.minLength(8), requirePasswordToBeCheckedValidator()]],
      new_password_confirm: ['', { updateOn: 'blur', validators: [Validators.required] }],
    });
    this.editDetailsForm.patchValue({
      country: this.defaultCountry,
    });
    this.validation.setValidationErrors({
      city: { whitSpaceValue: 'Please enter valid character'}
    });


    this.isEnableState();

    this.getCurrentUserDetails();
  }

  private setUserData(data: any) {
    this.userData = data;
    this.userData['full_name'] = `${this.userData['first_name'] ?? ''} ${this.userData['last_name'] ?? ''}`.trim();
  }


  /**
   * Get personal details for filling profile details
   */
  public getCurrentUserDetails(): void {
    this.preDefinedLoader = true;
    this.professionalUserService
      .getCurrentUser()
      .subscribe((response: APIResponseModel) => {
        this.preDefinedLoader = false;

        if (response.status) {
          this.accountDetails = { ...response.data };
          this.currentUser = this.accountDetails;
          this.accountDetailForm.reset();
          if (this.currentUser.profile_picture && this.isNotAFirmAccount) {
            this.imageSrc = `${environment.BASE_URL_RAW}uploads/agent_proof/${this.currentUser.profile_picture}`;
          } else {
            this.imageSrc = `${environment.BASE_URL_RAW}uploads//professional_logo/${this.currentUser.logo}`;
          }
          this.accountDetailForm.patchValue({
            old_email: this.currentUser.email,
          });
          this.shouldLastnameShown = this.currentUser.role_name !== PROFESSIONAL_ROLE.professional
          this.editDetailsForm.patchValue({
            first_name: this.currentUser.first_name,
            middle_name: this.currentUser?.middle_name,
            last_name: this.currentUser?.last_name,
            street_address: this.currentUser.street_address,
            country: this.currentUser.country,
            state: this.currentUser.state,
            city: this.currentUser.city,
            zipcode: this.currentUser.zipcode,
            upload_proof: '',
          });
          let tempPhoneNumber;
          if (this.currentUser?.phone) {
            tempPhoneNumber = this.currentUser?.phone;
            this.countryCode = { code: this.currentUser?.flag_code, dialCode: this.currentUser?.country_code };
          }
          this.editDetailsForm.patchValue({
            phone: tempPhoneNumber,
          });
          this.removePacContainer();
          this.initMap(this.currentUser?.country);
          if (this.currentUser?.upload_proof) {
            this.commonService.fileHanlderObservableEvents({
              type: SET_IMAGE,
              data: this.currentUser?.upload_proof,
              id: this.currentUser?.id,
            });
          }
        }
      });
  }

  /**
   * Account Detail form submit Functionality
   */
  public accountDetailFormSubmit(): void {
    this.accountDetailsSubmit = true;
    const sendingData = this.accountDetailForm.value;
    const formData = new FormData();
    // appending every value to form object
    Object.keys(sendingData).forEach((key) => {
      if (sendingData[key] == undefined) return;
      formData.append(key, sendingData[key]);
    });
    /**
     * Update email, profile pic
     */
    this.professionalUserService.changeEmailVerification(formData).subscribe(
      (response: APIResponseModel) => {
        this.accountDetailsSubmit = false;
        if (response.status) {
          this.newEmail = this.accountDetailForm.controls['email'].value;
          this.changeEmail = false;
          this.toastrService.success(response.message.replace(/otp/ig, 'OTP'));
          // open email verification modal
          this.modalService.open('professional-otp-verification-modal');
        }
      },
      (exception: any) => {
        this.accountDetailsSubmit = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      },
    );
  }

  /**
   * Account details form object getter for validation and showing errors in html
   */
  get accountDetailFormGet() {
    return this.accountDetailForm.controls;
  }

  /**
   * form object getter for validation and showing errors in html
   */
  public get formGet() {
    return this.editDetailsForm.controls;
  }

  /**
   * Change Pasword form submit Functionality
   */
  public changePasswordFormSubmit(): void {
    this.changePasswordMatch = this.changePasswordFormGet?.new_password_confirm?.value == this.changePasswordFormGet?.new_password?.value;
    if (this.changePasswordMatch) {
      this.changePasswordSubmit = true;
      this.professionalUserService
        .changePassword(this.changePasswordForm.value)
        .subscribe(
          (response: APIResponseModel) => {
            this.changePasswordSubmit = false;
            if (response.status) {
              this.changePasswordForm.reset();
              this.toastrService.success(response.message);
            }
          },
          (exception) => {
            this.changePasswordSubmit = false;
            this.commonHelper.httpResponseHandler(exception?.error);
          },
        );
    }
  }

  /**
   * Detect change on phone number component
   *
   * @param {any} inputObject
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }
  
  /**
   * Initialize {@link accountDetailForm} & related fields for email change flow.
   * @private
   */
  private initAccountDetailForm() {
    this.showVerificationCodes = false;
    this.changeEmail = false;
    this.accountDetailForm = this.formBuilder.group({
      'old_email': ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(EMAIL_PATTERN)] }],
      'email': ['', {
        updateOn: 'blur',
        validators: [Validators.required, Validators.pattern(EMAIL_PATTERN), this.validation.fn.checkSameEmail()],
      }],
      'confirm_email': ['', { updateOn: 'blur', validators:[Validators.pattern(EMAIL_PATTERN), Validators.required]}],
      'password': ['', { validators: [Validators.required] }],
    });
  }
  /**
   * Get whether is confirm password match
   */
  get isConfirmPasswordMatch() {
    if(this.accountDetailForm.get('email').valid) {
      return this.accountDetailForm.get('email').value == this.accountDetailForm.get('confirm_email').value
    } else {
      return true;
    }
  }
  /**
   * Change Password form object getter for validation and showing errors in html
   *
   */
  get changePasswordFormGet() {
    return this.changePasswordForm.controls;
  }

  /**
   * Menu on click scroll to related card
   *
   * @param {HTMLElement} el
   * @param {string} key
   *
   *  !Do not remove this code for now
   */
  // public scroll(el: HTMLElement, key: string) {
  //   this.menuActive = key;
  //   el.scrollIntoView();
  //   el.scrollIntoView({ behavior: 'smooth' });
  // }
  /**
   *
   * Password confirmation
   * @param {string} oldpassword
   * @param {string} newpassowrd
   * @return {FormGroup}
   *
   * !Do not remove this code for now
   */
  // public ConfirmedValidator(oldpassword: string, newpassowrd: string):Function {
  //   return (formGroup: FormGroup) => {
  //     const currentPassword = formGroup.controls[oldpassword];
  //     const confirmPassword = formGroup.controls[newpassowrd];
  //     if (currentPassword.errors && !confirmPassword.errors.confirmedValidator) {
  //       return;
  //     }
  //     if (currentPassword.value !== confirmPassword.value) {
  //       confirmPassword.setErrors({ confirmedValidator: true });
  //     } else {
  //       confirmPassword.setErrors(null);
  //     }
  //   };
  // }
  /**
   * On change image upload with preview
   *
   * @param {string} event
   */
  public readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      const fileFormat = event.target.files[0].type.split('/')[1];
      if (this.fileValidationOptions?.fileFormat?.indexOf(fileFormat) === -1) {
        this.toastrService.error('File must be one of the following format ' + this.fileValidationOptions?.fileFormat?.join(','));
        return;
      }
      // check file size
      const fileSize = (event.target.files[0].size);
      if (fileSize > 2097152) {
        this.toastrService.error('File size must not exceed 2 MB');
        return;
      }
      reader.onload = (e: any) => {
        this.imageSrc = e.target.result;
      };

      // updating on form
      if (fileSize < 2097152) {
        this.profilePictureForm.patchValue({
          profile_picture: event.target.files[0],
        });
      }
    }
    const sendingData = this.isNotAFirmAccount ? this.profilePictureForm.value : { logo: event.target.files[0] };
    const formData = new FormData();
    // appending every value to form object
    Object.keys(sendingData).forEach((key) => {
      if (sendingData[key] == undefined) return;
      formData.append(key, sendingData[key]);
    });
    this.profilePicLoader = true;
    const uploadObservable = this.isNotAFirmAccount ? this.professionalUserService.changeProfilePicture(formData) : this.professionalUserService.changeCompanyLogo(formData)
    uploadObservable.subscribe({
      next: (response) => {
        const updatedKey: string = this.isNotAFirmAccount ? 'profile_picture' : 'logo'
        this.toastrService.success(response.message);
        this.localStorageService.updateUserData(USER_TYPES.pro, {
          key: 'user',
          updateValue: response.data[updatedKey],
          updateKey: updatedKey,
          type: LOCAL_STORAGE_UPDATE_STATUS.O,
        });
        if(!this.isNotAFirmAccount) { // set is logo updated that to change
          localStorage.setItem('orgLogo', response.data['upload_logo']);
          this.slugService.setSlug(true);
        }
        this.commonService.getCurrentUserProfilePicturePro(response.data[updatedKey]);
        this.getCurrentUserDetails();
      }
    })
    this.profilePicLoader = false;
  }

  /**
   * Menu change
   *
   * @param {number} menu
   */
  public menuChange(menu: number): void {
    this.changeEmail = false;
    this.editData = false;
    this.imageSrc = `${environment.BASE_URL_RAW}uploads/agent_proof/${this.currentUser.profile_picture}`;

    this.accountDetailForm.reset({
      old_email: this.accountDetails.email,
      password: this.accountDetails.password,
    });
    this.changePasswordForm.reset();
    this.menuActive = menu;
    void this.slugInterceptorService.navigate(['account-settings'], { queryParams: { menu: menu } }, true);
    this.getCurrentUserDetails();
  }

  /**
  * Initialize google map api by country
  * @param {any} countryId
  */
  public initMap(countryId: any): void {
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: (r) => {
        this.ngZone.run(() => {
          this.editDetailsForm.patchValue({ ...r, street_address: r.address });
        });
      },
      error: (e) => console.error(e),
    });
  }

  /**
   * Get whether the logged-in user is not a firm account
   */
  public get isNotAFirmAccount(): boolean {
    return this.roleName !== PROFESSIONAL_ROLE.professional && (!this.commonHelper.isNullorUndefined(this.userData.professional_id) || !this.commonHelper.isNullorUndefined(this.userData.slug_url))
  }

  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.editDetailsForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }

  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(
    selectedCountry: SelectOptionsInterface,
  ): void {
    if (!selectedCountry || this.preDefinedLoader) return;
    this.editDetailsForm.controls.state.reset();
    this.editDetailsForm.patchValue({
      country: selectedCountry.value,
    });
    this.removePacContainer();
    this.initMap(selectedCountry.value);
  }
  /**
   * remove pac container
   *
   */
  public removePacContainer() {
    this.addressHelper.removeAutocompletePacContainer();
  }
  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.editDetailsForm.controls.city.reset();
    this.editDetailsForm.patchValue({
      state: selectedState.value,
    });
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.editDetailsForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.editDetailsForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([
        Validators.required,
        Validators.pattern(
          new RegExp(selectedCity?.additionalDetails[0]?.postal_code_regex),
        ),
      ]);
      zipCodeRef.updateValueAndValidity({ onlySelf: true });
    }
    // this loader is set false here because of the delay to set country state city
    this.preDefinedLoader = false;
  }

  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }

  /**
   * Upload file
   *
   * @param {any} file
   */
  public uploadProofImage(file: any): void {
    this.file = file;
    this.editDetailsForm.patchValue({
      upload_proof: file,
    });
  }

  /**

   /**
   * Remove uploaded file
   */
  public removeUploadedFile(): void {
    this.editDetailsForm.patchValue({
      upload_proof: '',
    });
  }

  /**
   * delete file
   *
   * @param {any} isDeleted
   */
  public deleteImage(isDeleted: any): void {
    this.editDetailsForm.patchValue({
      is_image_deleted: isDeleted,
    });
  }

  /**
   * save profile details
   */
  public onSave() {
    this.editDetailsSubmit = true;

    const payLoad = {
      ...this.editDetailsForm.value,
      country_code: this.countryCode.dialCode,
      flag_code: this.countryCode.code,
      id: this.currentUser?.id,
      type: this.currentUser?.role_name,
      is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
    };
    payLoad.state = this.selectedCountryName == this.unitedKingdom ? null : payLoad.state;

    this.loginLoader = true;
    this.commonService.getCityDetail(payLoad.country, payLoad.state, payLoad.city).subscribe((cResponse: APIResponseModel) => {
      if (cResponse?.status) {
        payLoad.city = cResponse?.data?.id;
        const formData = new FormData();
        // appending every value to form object
        Object.keys(payLoad).forEach((key) => {
          if (payLoad[key] == undefined) return;
          formData.append(key, payLoad[key]);
        });
        this.professionalUserService.updateProfile(formData).subscribe(
          (response) => {
            this.editDetailsSubmit = false;
            this.editData = false;
            this.loginLoader = false;
            this.toastrService.success(response.message);
            this.editDetailsForm.reset();
            this.localStorageService.updateUserData(USER_TYPES.pro, {
              key: 'user',
              updateValue: response.data.first_name,
              updateKey: 'first_name',
              type: LOCAL_STORAGE_UPDATE_STATUS.O,
            });
            this.localStorageService.updateUserData(USER_TYPES.pro, {
              key: 'user',
              updateValue: response.data.last_name,
              updateKey: 'last_name',
              type: LOCAL_STORAGE_UPDATE_STATUS.O,
            });
            this.professionalUserService.getCurrentUserName(response.data);
            this.getCurrentUserDetails();


            this.commonService.fileHanlderObservableEvents({
              type: CLEAR_SELECTED_FILE,
            });
          },
          (exception) => {
            this.editDetailsSubmit = false;
            this.loginLoader = false;
            this.commonHelper.httpResponseHandler(exception?.error);
          },
        );
      }
    }, (exception: any) => {
      this.commonHelper.httpResponseHandler(exception?.error);
    }
      , () => { });
  }

  /**
   * getter for state control
   */
  get state() {
    return this.editDetailsForm.get('state');
  }
  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.editDetailsForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ?
        this.state.addValidators([Validators.required]) : this.state.clearValidators();
      this.state.updateValueAndValidity();
    });
  }

  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.editDetailsForm.get('zipcode');
    const stateId = this.editDetailsForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        if (response.data.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(zipCodeRegex))]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        } else {
          zipCodeRef.setValidators([Validators.required]); zipCodeRef.updateValueAndValidity({ onlySelf: true });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }

  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }
}
