import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { ProfessionalPermissionService } from 'src/app/professional-dashboard/services/professional-permission.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { PropertyService } from 'src/app/services/property.service';
import { SECTIONS, SectionStatus, USER_TYPES } from '../../../constants/application.const';
import { ConsumerSubscriptionService } from '../consumer-payment-section/consumer-subscription.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',

})
/**
 * subscription component
 */
export class SubscriptionsComponent implements OnInit {
  public editData: any;
  public preDefinedLoader: boolean;
  public subscriptionArray: Array<any>;
  public currentUser: any;
  public sectionSaveExitOptions: any;
  public userType: string;
  public clientRequestId: string;
  public permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  public isNewAsset: boolean;
  public isProfessional:boolean;
  public clientHasPaidSubscription:boolean;

  /**
   * True signifies hiding the 'save' buttons and showing the 'progress' buttons
   */
  public buttonProgress: boolean = false;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public userRequestID: string;


  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private personalDetailsService: PersonalDetailsService,
    private localStorageService: LocalStorageService,
    private commonHelper: CommonHelper,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private router: Router,
    private proPermissionService: ProfessionalPermissionService,
    private toastrService: ToastrService,
    private slugInterceptorService: SlugInterceptorService,
    private subscriptionService: ConsumerSubscriptionService,
  ) { }

  /**
     * called initially
     */
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.isProfessional = this.commonHelper.isProfessional
    this.sectionSaveExitOptions = {
      section: 11,
      type: 1,
    };
    this.subscriptionArray = [];
    this.clientRequestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.userRequestID = this.commonHelper.getRequestId(this.route);
    this.currentUser = this.localStorageService.getUserData();
    this.getPersonalDetails();
    this.getAccessControl();
    if (this.clientRequestId) {
      this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.subscriptions, SectionStatus.INCOMPLETE).subscribe();
    }
  }

  /**
   * Establish ACL Permissions. Consumer is assigned full access.
   */
  public getAccessControl(): void {
    if (this.userType !== 'Consumer') {
      let getACLService;
      const requestId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('request_user_id') : this.userRequestID;
      const userId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('user_id') : this.localStorageService.getDataByKey('agent_id') || this.localStorageService.getUserData(USER_TYPES.pro)?.user?.id;
      getACLService = this.proPermissionService.getAccessControl(userId, requestId, ['Subscriptions']);
      getACLService.subscribe(
        (permissions) => this.permissions = permissions?.['Subscriptions'],
        (exception: any) => (this.commonHelper.httpResponseHandler(exception?.error)));
    } else {
      this.permissions = {
        view: true,
        add: true,
        edit: true,
        delete: true,
      };
    }
  }

  /**
   * Get personal details
   */
  public async getPersonalDetails(){
    this.preDefinedLoader = true;
    // get subscription list
    this.personalDetailsService.getPersonalDetails(this.clientRequestId).subscribe(async (response: APIResponseModel) => {
      if (response.status) {
        this.subscriptionArray = response.data.user_subscriptions;
        this.clientHasPaidSubscription = await this.commonHelper.checkClientSubscription(response);
      }
    },
      (exception: any) => this.commonHelper.httpResponseHandler(exception?.error)
      , () => this.preDefinedLoader = false);
  }

  /**
   * toggle add subscription modal
   *
   * @param {any} editValue
   */
  public toggleModal(editValue?: any): void {
    this.editData = {};
    this.isNewAsset = editValue == null;
    if (editValue) {
      // Permission to View?
      if (!this.permissions?.['view']) {
        this.toastrService.info('You do not have permission to view this asset');
        return;
      }
      this.editData = { ...editValue };
    }
    this.modalService.open('add-subscription-modal');
  }

  /**
   * Modal event listner
   *
   * @param {any} data
   */
  public modalEventListener(data: any): void {
    this.preDefinedLoader = true;
    this.commonHelper.diffCheckerAndUpdate(this.subscriptionArray, 'id', data?.id, data);
    this.preDefinedLoader = false;
  }

  /**
   * skip social media  section
   */
  public skipSubSection() {
    this.propertyService.skipPropertySubscriptionDetails({ request_id: this.clientRequestId }).subscribe((response) => {
      if (this.userType === 'Consumer') {
        this.commonHelper.updateLocalstorageRequestStage(response.data);
      }
    });
  }

  /**
   * Button click handler
   * @param command
   */
  public click(command: string) {
    const isPro = this.userType !== 'Consumer';
    switch (command) {
      case 'Back':
        const back_url = ['social-media-list'];
        if (isPro) {
          back_url.push(this.clientRequestId);
        }
        void this.slugInterceptorService.navigate(back_url);
        break;
      case 'ReturnToDashboard':
        const return_url = isPro ? ['overview', 'property-overview'] : ['dashboard'];
        void this.slugInterceptorService.navigate(return_url);
        break;
      case 'MarkComplete':
        this.buttonProgress = true;
        this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.subscriptions, SectionStatus.COMPLETE).subscribe();
        break;
      case 'SaveForLater':
        this.buttonProgress = true;
        this.propertyService.skipPropertySubscriptionDetails({ request_id: this.clientRequestId }).subscribe((response) => {
          if (this.userType === 'Consumer') {
            this.commonHelper.updateLocalstorageRequestStage(response.data);
          }
        });
        break;
      case 'NextSection':
        const url = ['prescription-list'];
        if (isPro) {
          url.push(this.clientRequestId);
        }
        void this.slugInterceptorService.navigate(url);
        break;
    }
  }

  /**
   * Determines whether the button should be disabled
   * @returns A boolean
   */
  public get disableButton(): boolean {
    return this.isProfessional && !this.clientHasPaidSubscription
  }
}
