import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { LOCAL_STORAGE_UPDATE_STATUS, USER_TYPES } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { UpdateLocalStorageInterface } from '../interface/common.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * Local storage service
 */
export class LocalStorageService {
  /**
   * @constructor
   */
  constructor(
    private cookieService: CookieService,
  ) {
  }

  /**
   * Store User Data.
   * encruption enabled
   * @param userType Use {@link USER_TYPES}
   * @param data
   */
  public storeUserData(userType: string, data: any): void {
    if (!data) return;
    this.storeData(userType, data);
    this.cookieService.set(userType, data?.user?.id, {
      expires: moment().add(data?.expires_in || 3600, 'seconds').toDate(),
      path: '/',
    });
  }

  /**
   * Convenience function for readability. Functionality is identical to {@link getDataByKey}.
   * @param userType Use {@link USER_TYPES}. Defaults to {@link USER_TYPES.user}.
   * @return {JSON} returns specified user data
   */
  public getUserData(userType = USER_TYPES.user): any {
    return this.getDataByKey(userType);
  }

  /**
   * store any data
   *
   * @param {string} key
   * @param {any} value
   */
  public storeData(key: string, value: any): void {
    const data = this.encrypt(JSON.stringify(value));
    localStorage.setItem(key, data);
  }


  /**
   * Get localstorage value by key
   *
   * @param {string} key
   * @return {any}
   */
  public getDataByKey(key: string): any {
    if (!localStorage.getItem(key)) return null;
    const data = this.decrypt(localStorage.getItem(key));
    // Handle case of item stored as invalid json
    try {
      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  }

  /**
   * Get cookie data for login check
   * @param userType Use {@link USER_TYPES}. Defaults to {@link USER_TYPES.user}.
   * @return {any}
   */
  public getLoginCookie(userType = USER_TYPES.user): any {
    return this.cookieService.get(userType);
  }

  /**
   * update user data
   *
   * @param userType Use {@link USER_TYPES}.
   * @param {UpdateLocalStorageInterface} data
   */
  public updateUserData(userType: string, data: UpdateLocalStorageInterface): void {
    let userData;

    // Check if data already exists in local storage
    if (localStorage.getItem(userType)) {
      userData = this.decrypt(localStorage.getItem(userType));
    }

    const parsedData = userData ? JSON.parse(userData) : {};
    switch (data.type) {
      case LOCAL_STORAGE_UPDATE_STATUS.S:
        parsedData[data.key] = data.updateValue;
        break;
      case LOCAL_STORAGE_UPDATE_STATUS.O:
        parsedData[data.key] = { ...parsedData[data.key], [data.updateKey]: data.updateValue };
        break;
    }
    this.storeUserData(userType, parsedData);
  }


  /**
   * Remove an item from Local storage if it exists.
   * @param key Key of the data item.
   */
  public deleteDataByKey(key: string) {
    localStorage.removeItem(key);
  }


  /**
   * clear role
   */
  public clearRole() {
    localStorage.removeItem('role');
  }

  /**
   * Clear recaptcha status
   */
  public clearRecaptchaStatus() {
    localStorage.removeItem('enable_google_recaptcha');
  }


  /**
   *
   * @param{string} userType Use {@link USER_TYPES}. Defaults to {@link USER_TYPES.user}.
   */
  public clearLocalStorage(userType: string = USER_TYPES.user): void {
    localStorage.removeItem(userType);
    this.cookieService.delete(userType);

    // UserType-Specific storage clear
    switch (userType) {
      case USER_TYPES.user:
        const itemsToRemoveUser = [
          'is-demo-email',
          'consumer-email',
          'notify-companies',
          'enableVaultCustodian',
          'countryList',
          'country',
          'is_payment_complete',
          'payment_alert_called',
          'payment_mode',
          'is_first_month',
          'paymentInitiatedSource',
          'subscription_cancellation_count',
          'enable_google_recaptcha',
          'slug_subscription_amount',
          'subscription_plan_type',
          'payment_mode',
          'co_brand_grace_period_started_at',
          'post_checkout_redirect_url',
          'cardsAdded',
          'in_trial_period'
        ];

        itemsToRemoveUser.forEach((item) => localStorage.removeItem(item));

        break;
      case USER_TYPES.pro:
        const itemsToRemove = [
          'client_marital_status',
          'req_id',
          'permission-list',
          'enable_google_recaptcha',
          'overview_user_id',
          'companyName',
          'proClientTableSettings',
          'proPaymentType'
        ];

        itemsToRemove.forEach((item) => localStorage.removeItem(item));

        break;
      case USER_TYPES.org:
        const itemsToRemoveOrg = [
          'organization-email',
          'enable_google_recaptcha'
        ];

        itemsToRemoveOrg.forEach((item) => localStorage.removeItem(item));
        break;
      case USER_TYPES.exe:
        const itemsToRemoveExecutor = [
          'request_user_id',
          'req_id',
          'user_id',
          'is_id_verified',
          'redirect-url',
          'executor-email',
          'custodian-email',
          'currentDlb',
          'isIdVerifyShown',
          'accessVault',
          'user_request_id',
          'is_challenge_verified',
          'is_verify_security_page',
          'isVaultClaimed',
          'enable_google_recaptcha',
          'is_transfer_amount_paid',
          'post_checkout_redirect_url'
        ];

        itemsToRemoveExecutor.forEach((item) => localStorage.removeItem(item));

        break;
    }
  }

  /**
   * clear org logo
   */
  public clearOrgData() {
    localStorage.removeItem('verifiedSlug');
    localStorage.removeItem('orgLogo');
  }

  /**
   * Professional Type
   */
  public clearProTypeData() {
    localStorage.removeItem('pro-type');
  }

  /**
   * Encrypt the value using environment.LOCAL_STORAGE_KEY. If environment.ENCRYPT_LOCAL_STORAGE is false, then it will not encrypt.
   * @param value
   * @private
   */
  private encrypt(value: any): any {
    return environment.ENCRYPT_LOCAL_STORAGE ? CryptoJS.AES.encrypt(value, environment.LOCAL_STORAGE_SECRET).toString() : value;
  }

  /**
   * Decrypt the value using environment.LOCAL_STORAGE_KEY. If environment.ENCRYPT_LOCAL_STORAGE is false, then it will not decrypt.
   * @param value
   * @private
   */
  private decrypt(value: any): any {
    return environment.ENCRYPT_LOCAL_STORAGE ? CryptoJS.AES.decrypt(value, environment.LOCAL_STORAGE_SECRET).toString(CryptoJS.enc.Utf8) : value;
  }

  /**
   * clear everything from the local storage
   */
  public clearAllLocalStorage() {
    localStorage.clear();
  }
}
