import { Component, Input, OnInit } from '@angular/core';

/**
 * Component
 */
@Component({
  selector: 'app-chat-status-handler',
  templateUrl: './chat-status-handler.component.html',
})

/**
 * Chat status showing modal
 */
export class ChatStatusHandlerComponent {
  @Input() message: String = '';
}
