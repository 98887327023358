import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-professional-not-found',
  templateUrl: './professional-not-found.component.html',
})
/**
 * Professional Not Found Component
 */
export class ProfessionalNotFoundComponent {
  public userType: string;
  public requestId: string;

  /**
   * constructor
   */
  constructor(
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private slugInterceptorService: SlugInterceptorService,
  ) {
    void this.slugInterceptorService.navigate(['dashboard'], null, true);
   }

  /**
     * change route
     * @param{string}url
      */
  public changeRoute(url:string) {
    void this.slugInterceptorService.navigate([ url], null, true);
  }
}
