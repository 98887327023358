<div class="border-card bg-white rounded-md mb-10">
  <div class="px-4 px-6 my-4">
    <!-- BEGIN: First Accordion -->
    <div class="mb-2">
      <div class="grid grid-cols-12 gap-2 items-center">
        <div class="col-span-10">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            {{title}}
          </h4>
          <p class="text-grey text-regular text-sm">{{description}}
          </p>
        </div>
        <div class="col-span-1 cursor-pointer" (click)="toggleSectionBody = !toggleSectionBody">
          <img src="assets/images/dashboard/angle-up-arrow.png" *ngIf="toggleSectionBody" class="w-4 float-right"
               alt="digital vault">
          <img src="assets/images/dashboard/angle-down-arrow.png" *ngIf="!toggleSectionBody" class="w-4 float-right"
               alt="digital vault">
        </div>
        <!-- <div class="col-span-1">
          <img *ngIf="stageCompleted && this.sectionIndex===0" src="assets/images/dashboard/selected.svg"
               class="w-5 ml-auto" alt="digital vault">
        </div> -->
      </div>
    </div>
    <!-- Section Index =0 refers to Personal Details (about yourself,exe/bene,psp) -->

    <ng-container *ngIf="toggleSectionBody && this.sectionIndex===0">
      <div *ngFor="let sectionData of sectionItemList; let i = index" id="{{i}}"
           [ngClass]="(sectionData?.sectionInitialButton)?'text-darkgrey':'text-disable'">

        <div class="sm:grid sm:grid-cols-12  xl:flex xl:flex-row my-6 flex-col-reverse items-center">
          <div class="sm:col-span-2">
            <img [src]="sectionData?.sectionIcon" class="w-12" alt="digital vault">
          </div>
          <div class="w-full xl:ml-6 mt-4 xl:mt-0 sm:col-span-8">
            <p class="text-xs tracking-wide text-regular breadcrumb"> Section
              <span class="font-bold">{{sectionData?.sectionStep}}</span> / {{totalSections}}
            </p>
            <h4 class="text-regular text-lg  font-semibold tracking-wide">
              {{sectionData?.sectionTitle}}
            </h4>
            <p class="text-regular text-sm whitespace-pre-line">{{sectionData?.sectionDescription}}
            </p>
          </div>
          <div class="md:ml-auto mt-4 mr-4 xl:mt-0 col-span-1 cursor-pointer"
               *ngIf="((userDetails?.['request_stage'] && i== 0) || (i>0 && userDetails?.['request_stage'] >= sectionItemList[i-1]?.lastRequestStage ))"
               (click)="changeRoute(sectionData?.sectionInitialButton?.redirectionLink)">
            <div class="flex flex-row">
              <img src="assets/images/dashboard/edit.svg" class="w-5 mr-8" alt="digital vault" id="scroll-to-button">
                <img *ngIf="userDetails?.['stage'] <= sectionData?.sectionStep && userDetails?.['request_stage'] < 10 && ((userDetails['request_stage'] < 6 || userDetails['request_stage'] > 6) || (sectionData?.sectionStep !== 1 && userDetails?.['request_stage'] == 6)) && (userDetails?.['request_stage'] !== 8 && sectionData?.sectionStep == 2) || (sectionData?.sectionStep == 1 && userDetails?.['request_stage']<= 4) || ( sectionData?.sectionStep == 3 && userDetails?.['request_stage'] == 9)" src="assets/images/dashboard/exclamation_yellow.svg"
                class="w-5 ml-auto" alt="digital vault">
                <img *ngIf="userDetails?.['stage'] > sectionData?.sectionStep || (userDetails?.['request_stage'] >= 5 && sectionData?.sectionStep == 1) || (userDetails?.['request_stage'] >= 8 && sectionData?.sectionStep == 2) || (userDetails?.['request_stage'] >= 10 && sectionData?.sectionStep == 3)" src="assets/images/dashboard/selected.svg"
                class="w-5 ml-auto" alt="digital vault">
                <img *ngIf="(userDetails?.['request_stage'] <= 8 && sectionData?.sectionStep == 3)" src="assets/images/dashboard/exclamation_red.svg" class="w-5"
                     alt="digital vault">
            </div>
          </div>

          <div class="xl:ml-auto mt-4 xl:mt-0 md:col-span-12"
               *ngIf="((!userDetails?.['request_stage']) && (sectionData?.sectionStep == 1))">
            <button type="button"
                    class="button button--lg w-full xl:w-32 bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-5 "
                    (click)="changeRoute(sectionData?.sectionInitialButton?.redirectionLink.substring(1))">Get
              Started
            </button>
          </div>

        </div>
        <hr class="hr-line" *ngIf="sectionItemList.length-1 !== i">
      </div>
    </ng-container>
    <!-- Section Index >0 refers excluding Personal Details Block -->
    <ng-container *ngIf="toggleSectionBody && this.sectionIndex > 0">
      <div *ngFor="let sectionData of sectionItemList; let i = index" id="{{i}}"
           [ngClass]="(sectionData?.sectionInitialButton)?'text-darkgrey':'text-disable'">

        <div class="sm:grid sm:grid-cols-12  xl:flex xl:flex-row my-6 flex-col-reverse items-center">
          <div class="sm:col-span-2">
            <img [src]="sectionData?.sectionIcon" class="w-12" alt="digital vault">
          </div>
          <div class="w-full xl:ml-6 mt-4 xl:mt-0 sm:col-span-8">
            <p class="text-xs tracking-wide text-regular breadcrumb"> Section
              <span class="font-bold">{{sectionData?.sectionStep}}</span> / {{totalSections}}
            </p>
            <h4 class="text-regular text-lg  font-semibold tracking-wide">
              {{sectionData?.sectionTitle}}
            </h4>
            <p class="text-regular text-sm whitespace-pre-line">{{sectionData?.sectionDescription}}
            </p>
          </div>
          <!-- userDetails?.['request_stage'] <= 9 to check 1st 3rd stage is completed or not -->
          <div class="md:ml-auto mt-4 xl:mt-0 col-span-1 cursor-pointer"
               *ngIf="userDetails?.['request_stage'] <= 9  || userDetails?.['user']?.['is_payment_complete']===4">
            <button (click)="buttonListener('open-message-modal')"
                    class="flex items-center hover:underline-text text-sm tracking-wide w-full sm:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-6 ml-2">
              Locked
            </button>
          </div>
          <div class="md:ml-auto mt-4 mr-4 xl:mt-0 col-span-1 cursor-pointer"
               *ngIf="userDetails?.['request_stage'] > 9"
               (click)="changeRoute(sectionData?.sectionInitialButton?.redirectionLink)">
            <div class="flex flex-row" (click)="click(sectionData?.iscompletedKey)">
              <img src="assets/images/dashboard/edit.svg" class="w-5 mr-8" alt="digital vault">
              <ng-container
                [ngSwitch]="sectionStatus?.[sectionData?.sectionName]?.status ? sectionStatus?.[sectionData?.sectionName]?.status: '0'">
                <img *ngSwitchCase="0" src="assets/images/dashboard/exclamation_red.svg" class="w-5"
                     alt="digital vault">
                <img *ngSwitchCase="1" src="assets/images/dashboard/exclamation_yellow.svg" class="w-5"
                     alt="digital vault">
                <img *ngSwitchCase="2" src="assets/images/dashboard/selected.svg" class="w-5" alt="digital vault">
                <span *ngSwitchDefault class="w-5"></span>
              </ng-container>
            </div>
          </div>
        </div>
        <hr class="hr-line" *ngIf="sectionItemList.length-1 !== i">
      </div>
    </ng-container>
  </div>
  <!-- END: First Accordion -->
  <div class="h-1 relative mt-2 rounded-b-sm w-full overflow-hidden">
    <app-card-progress-bar [progressValue]="progressbarConditionString"></app-card-progress-bar>
  </div>
</div>


<app-message-modal
  [id]="message_modal_id"
  [setMessageData]="userDetails?.['user']?.['is_payment_complete']!==2 ? messageData : messageDataForRefundPeriod"
  (buttonEmitter)="buttonListener($event)">
</app-message-modal>
