import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { COMMON_SUBSCRIBER_EVENT } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ObservableEventInterface, PreviewFileDownloadInterface, fileListTypeInterface } from '../interface/common.interface';
import { APIResponseModel } from '../interface/response.interface';


@Injectable({
  providedIn: 'root',
})
/**
 * Common services
 */
export class CommonService {
  private readonly BASE_URL: string;
  // navbar observables
  private navbar: BehaviorSubject<string>;
  public navbarObserve: Observable<string>;
  // image observables
  private setImageViewPopup: any;
  public setImageViewPopupObserve: Observable<object>;
  // button observables
  private setButtonValue: any;
  public setButtonValueObserve: Observable<boolean>;

  // profile pic observable

  private defaultProfilePic: any;
  public profilePicObserve: Observable<string>;

  // professional profile pic observable

  private defaultProfilePicPro: any;
  public profilePicProObserve: Observable<string>;

  // file handler observables
  private fileHandlerComponent: BehaviorSubject<{}>;
  public fileHandlerComponentObserve: Observable<any>;
  // file handler observables with previews
  private filePreviewComponent: any;
  public filePreviewComponentObserve: Observable<any>;
  // image handler observables
  private imageHandlerComponent: any;
  public imageHandlerComponentObserve: Observable<any>;
  // property handler observables
  private propertyHandlerComponent: any;
  public propertyHandlerComponentObserve: Observable<any>;

  // current modal status
  private currentModal: any;
  public currentModalStatusObservable: Observable<string>;

  // common observable service
  private commonSubscriber: any;
  public commonSubscriberObservable: Observable<any>;

  // payment status
  private paymentStatusUpdateHandle: any;
  public paymentStatusUpdateSubscriberObservable: Observable<any>;

  // to show transfer vault pricing details
  private showTVaultPricing:any;
  public showTVaultPricingObserve: Observable<number>;

  // to show payment button in consumer navbar
  private showPaymentButton: any;
  public showPaymentButtonObservable: Observable<boolean>;

  private paymentErrorDetection: any;
  public paymentErrorDetectionObservable: Observable<any>;

  private paymentSuccessMessage: any;
  public paymentSuccessMessageObservable: Observable<any>;

  private proPaymentStatus: any;
  public proPaymentStatusObservable: Observable<any>;

  /**
   * @constructor
   */
  constructor(
    private http: HttpClient,
    private toastrService: ToastrService,
  ) {
    this.BASE_URL = environment.BASE_URL;

    // profile picture change
    this.defaultProfilePic = new BehaviorSubject('');
    this.profilePicObserve = this.defaultProfilePic.asObservable();
    // button value change
    this.setButtonValue = new BehaviorSubject(true);
    this.setButtonValueObserve = this.setButtonValue.asObservable();
    // professional profile picture change
    this.defaultProfilePicPro = new BehaviorSubject(null);
    this.profilePicProObserve = this.defaultProfilePicPro.asObservable();
    // set navbar active
    this.navbar = new BehaviorSubject('');
    this.navbarObserve = this.navbar.asObservable();
    // set view popup image
    this.setImageViewPopup = new BehaviorSubject(null);
    this.setImageViewPopupObserve = this.setImageViewPopup.asObservable();

    // payment status
    this.paymentStatusUpdateHandle = new BehaviorSubject({});
    this.paymentStatusUpdateSubscriberObservable = this.paymentStatusUpdateHandle.asObservable();

    // to show transfer vault pricing details
    this.showTVaultPricing = new BehaviorSubject(0);
    this.showTVaultPricingObserve = this.showTVaultPricing.asObservable();

    // file hanlder component
    this.fileHandlerComponent = new BehaviorSubject({});
    this.fileHandlerComponentObserve = this.fileHandlerComponent.asObservable();

    // file handler component with previews
    this.filePreviewComponent = new BehaviorSubject({});
    this.filePreviewComponentObserve = this.filePreviewComponent.asObservable();

    // image hanlder component
    this.imageHandlerComponent = new BehaviorSubject({});
    this.imageHandlerComponentObserve = this.imageHandlerComponent.asObservable();
    
    // property hanlder component
    this.propertyHandlerComponent = new BehaviorSubject({});
    this.propertyHandlerComponentObserve = this.propertyHandlerComponent.asObservable();

    // send modal current status
    this.currentModal = new BehaviorSubject(null);
    this.currentModalStatusObservable = this.currentModal.asObservable();

    // to show payment button in consumer navbar
    this.showPaymentButton = new BehaviorSubject(false);
    this.showPaymentButtonObservable = this.showPaymentButton.asObservable();

    this.paymentErrorDetection = new BehaviorSubject(null);
    this.paymentErrorDetectionObservable = this.paymentErrorDetection.asObservable();

    this.paymentSuccessMessage = new BehaviorSubject({});
    this.paymentSuccessMessageObservable = this.paymentSuccessMessage.asObservable();

    this.proPaymentStatus = new BehaviorSubject('');
    this.proPaymentStatusObservable = this.proPaymentStatus.asObservable();

    // send modal current status
    this.commonSubscriber = new BehaviorSubject({
      type: COMMON_SUBSCRIBER_EVENT.DEFAULT_VALUE_SUBSCRIBER,
    });
    this.commonSubscriberObservable = this.commonSubscriber.asObservable();
  }

  /**
   * Send modal current Status
   *
   * @param {string} currentStatus
   */
  public sendCurrentModalStatus(currentStatus: string): void {
    this.currentModal.next(currentStatus);
  }

  /**
   * Send button Status
   *
   * @param {boolean} currentStatus
   */
  public sendCurrentButtonValue(currentStatus: boolean): void {
    this.setButtonValue.next(currentStatus);
  }

  /**
   * get current profile picture
   * @param {any} name
   */
  public getCurrentUserProfilePicture(name: string): void {
    this.defaultProfilePic.next(name);
  }

  /**
   * get current professional user profile picture
   * @param {any} name
   */
  public getCurrentUserProfilePicturePro(name: string): void {
    this.defaultProfilePicPro.next(name);
  }

  /**
   * Send paymeny current Status
   *
   * @param {boolean} status
   */
  public paymentUpdate(status: boolean): void {
    this.paymentStatusUpdateHandle.next(status);
  }

  /**
   * Set active navbar
   *
   * @param {string} data
   */
  public setActiveNavbarTrigger(data: string): void {
    this.navbar.next(data);
  }

  /**
   * Set image on image view modal
   *
   * @param {string} data
   */
  public setImageViewPopupTrigger(data: fileListTypeInterface): void {
    this.setImageViewPopup.next(data);
  }

  /**
   * file hanlder component observables
   *
   * @param {any} data
   */
  public fileHanlderObservableEvents(data: ObservableEventInterface): void {
    this.fileHandlerComponent.next(data);
  }

  /**
   * file hanlder component observables
   *
   * @param {any} data
   */
  public filePreviewObservableEvents(data: PreviewFileDownloadInterface): void {
    this.filePreviewComponent.next(data);
  }

  /**
   * image hanlder component observables
   *
   * @param {any} data
   */
  public imageHandlerObservableEvents(data: ObservableEventInterface): void {
    this.imageHandlerComponent.next(data);
  }

  /**
   * image hanlder component observables
   *
   * @param {any} data
   */
  public propertyHandlerObservableEvents(data: ObservableEventInterface): void {
    this.propertyHandlerComponent.next(data);
  }

  /**
   * common subscriber
   *
   * @param {any} data
   */
  public commonSubscriberEvent(data: any): void {
    this.commonSubscriber.next(data);
  }

  /**
   * set payment button show/hide
   * @param{boolean}data
   */
  public showPaymentButtonInNavBar(data: boolean) {
    this.showPaymentButton.next(data);
  }

  /**
   * set TRANSFER VAULT pricing show/hide
   * @param{number}data
   */
  public showTrVaultPricing(data:number){
    this.showTVaultPricing.next(data);
  }

  public paymentErrorListener(data: string) {
    this.paymentErrorDetection.next(data);
  }

  public paymentSuccessMessageListener(data: string) {
    this.paymentSuccessMessage.next(data);
  }

  public proPaymentStatusListener(data: object) {
    this.proPaymentStatus.next(data);
  }

  /**
   * Get countries
   * @return {Observable<APIResponseModel>}
   */
  public getCountries(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/country-list');
  }

  /**
   * Get state
   *
   * @param {number} countryId
   * @return {Observable<APIResponseModel>}
   */
  public getStates(countryId: number): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/state-list/${countryId}`);
  }

  /**
   * Get city
   *
   * @param {number} cityCode
   * @return {Observable<APIResponseModel>}
   */
  public getCity(cityCode: number): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/city-list/${cityCode}`);
  }

  /**
   * Get regex for zipcode based on state id
   *
   * @param {number} stateId
   * @return {Observable<APIResponseModel>}
   */
  public getRegex(stateId: number): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/zipcode-regex/${stateId}`);
  }

  /**
   * Get refresh token
   *
   * @return {Observable<APIResponseModel>}
   */
  public getRefreshToken(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/refresh-token`);
  }

  /**
   * Get refresh token professional
   *
   * @return {Observable<APIResponseModel>}
   */
  public getRefreshTokenProfessional(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/professional/refresh-token`);
  }

  /**
   * Save and exit for all modules
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public saveExitCommon(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-draft-details`, data);
  }

  /**
   * Get Card type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getCardType(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/card-type-list');
  }

  /**
   * Get Vehicle Insurance type - list will have data if user added ,auto type insurace
   *@param{string}requestId
   * @return {Observable<APIResponseModel>}
   */
  public getAutoInsuranceType(requestId: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/request/request-insurance-type?request_id=${requestId}&insurance_type=auto`);
  }

  /**
   * Get Vehicle type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getVehicleType(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/vehicle-type-list');
  }

  /**
   * Get Vehicle type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getVehicleMake(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/vehicle-make-list');
  }

  /**
   * Get Beneficiary type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getBeneficiaryTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/beneficiary-type-list');
  }

  /**
   * Get Trustee type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getTrusteeTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/trust-type-list');
  }

  /**
   * Get Vehicle type
   *
   * @param {string} makeId
   * @return {Observable<APIResponseModel>}
   */
  public getVehicleModel(makeId: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/vehicle-model-list/${makeId}`);
  }

  /**
   * Get Bank account type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getBankAccountType(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/bank-account-type-list');
  }

  /**
   * Pay for dlb
   *
   * @param {string} id
   * @return {Observable<APIResponseModel>}
   */
  public payForDlb(id: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/request/make-payment/${id}`);
  }

  /**
   * Get details for Received Digital Vault
   *
   * !have cors issues ( need to resolve )
   * @return {Observable<APIResponseModel>}
   */
  public getDetailsForReceivedDlb(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>('https://dev.decedent-registry.com.au/api/get-basic-details', {
      headers: {
        'ApiKey': 'DLB', // should be changed dynamically
      },
    });
  }

  /**
   * Get plaid token from api
   *
   //  * @param {string} publicToken
   * @return {Observable<APIResponseModel>}
   */
  public getPlaidToken(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/request/get-plaid-token`);
  }

  /**
   * Get plaid account list from api
   *
   * @param {string} publicToken
   * @param {string} requestId
   * @return {Observable<APIResponseModel>}
   */
  public getPlaidAccounts(publicToken: string, requestId?: string): Observable<APIResponseModel> {
    if (requestId) {
      return this.http.post<APIResponseModel>(this.BASE_URL + `/request/get-plaid-accounts`, {
        public_token: publicToken,
        request_id: requestId,
      });
    } else {
      return this.http.post<APIResponseModel>(this.BASE_URL + `/request/get-plaid-accounts`, {
        public_token: publicToken,
      });
    }
  }

  /**
   * Get plaid retirement account list from api
   *
   * @param {string} publicToken
   * @param {string} requestId
   * @return {Observable<APIResponseModel>}
   */
  public getRetirementPlaidAccounts(publicToken: string, requestId?: string): Observable<APIResponseModel> {
    if (requestId) {
      return this.http.post<APIResponseModel>(this.BASE_URL + `/request/get-plaid-retirement-assets`, {
        public_token: publicToken,
        request_id: requestId,
      });
    } else {
      return this.http.post<APIResponseModel>(this.BASE_URL + `/request/get-plaid-retirement-assets`, {
        public_token: publicToken,
      });
    }
  }

  /**
   * Get plaid liabilities account list from api
   *
   * @param {string} publicToken
   * @param {string} requestId
   * @return {Observable<APIResponseModel>}
   */
  public getLiabilityPlaidAccounts(publicToken: string, requestId?: string): Observable<APIResponseModel> {
    let body = { public_token: publicToken };
    if (requestId) {
      body['request_id'] = requestId;
    }
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/get-plaid-liabilities`, body);
  }

  /**
   * get relationship data
   *
   * @return {Observable<APIResponseModel>}
   */
  public getRelationshipList(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/relation-list`);
  }

  /**
   * Get Asset type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getAssetType(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/financial-asset-type-list`);
  }

  /**
   * Get Retirement Asset type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getRetirementAssetType(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/retirement-asset-type-list`);
  }

  /**
   * Get property type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getPropertyType(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/property-type-list`);
  }

  /**
   * Get recaptcha config
   *
   * @return {Observable<APIResponseModel>}
   */
  public getRecaptchaConfig(): Observable<APIResponseModel>{
    return this.http.get<APIResponseModel>(this.BASE_URL + `/google-captcha`);
  }

  /**
   * Get business types
   *
   * @return {Observable<APIResponseModel>}
   */
  public getBusinessTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/business-type-list`);
  }

  /**
   * Get organisation type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getOrganisationTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/organization-type-list`);
  }

  /**
   * Get valuables type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getValuablesTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/valuable-type-list`);
  }

  /**
   * Get social media type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getSocialMediaTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/social-media-list`);
  }

  /**
   * Get subscription type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getSubscriptionTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/property-subscriptions-list`);
  }

  /**
   * Get prescription type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getPrescriptionTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/prescription-list`);
  }

  /**
   * Get government ids list
   *
   * @return {Observable<APIResponseModel>}
   */
  public getGovernmentIDList(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/government-id-list`);
  }

  /**
   * Get list of Insurance Types
   *
   * @return {Observable<APIResponseModel>}
   */
  public getInsuranceTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/insurance-type-list`);
  }

  /**
   * Get policy type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getPolicyTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/insurance-policy-type-list`);
  }

  /**
   * Get Liability type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getLiabilityTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/liability-type-list`);
  }

  /**
   * Get document type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getDocumentTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/will-document-type-list`);
  }

  /**
   * Get credit card type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getCreditCardTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/card-type-list`);
  }

  /**
   * Get cause of death type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getCauseOfDeathTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/cause-of-death-list`);
  }

  /**
   * Get cause of death document type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getCauseOfDeathDocumentTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/document-type-list`);
  }

  /**
   * Get cause of death identification type
   *
   * @return {Observable<APIResponseModel>}
   */
  public getCauseOfDeathIdentificationTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/government-id-list`);
  }

  /**
   * Get frequency types in retirement asset
   *
   * @return {Observable<APIResponseModel>}
   */
  public getFrequencyTypes(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/frequency-list`);
  }

  /**
   * Check state details if present else add a new state
   * @param {any} country
   * @param {string} state
   * @return {Observable<APIResponseModel>}
   */
  public getStateDetail(country: any, state: string): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/users/get-state-detail`, {
      country_id: country,
      state: state,
    });
  }

  /**
   * Check city details if present else add a new city
   * @param {any} country
   * @param {any} state
   * @param {string} city
   * @return {Observable<APIResponseModel>}
   */
  public getCityDetail(country: any, state: any, city: string): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/users/get-city-detail`, {
      country_id: country,
      state_id: state,
      city: city,
    });
  }

  /**
   * Get city details by id
   * @param {number} id
   * @return {Observable<APIResponseModel>}
   */
  public getCityById(id: number): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/users/get-city/${id}`);
  }

  /**
   * Schedule appointment to agent
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public scheduleAppointment(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/appointment/schedule`, data);
  }

  /**
   * Get appointment list for notification requested from consumer
   * @return {Observable<APIResponseModel>}
   */
  public getAppointmentlist(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/appointment/schedule/list`);
  }

  /**
   * Get appointment list for notification requested from agent
   * @return {Observable<APIResponseModel>}
   */
  public getUsersAppointmentList(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/get-consumer-schedules`);
  }

  /**
   * Get schedulelist for notification
   * @param {any} id
   * @return {Observable<APIResponseModel>}
   */
  public getSchedulelist(id: any): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/user/schedule_remainder/list/${id}`);
  }

  /**
   * Get Consumer landing page introductory video URL
   * @param {string}type
   * @return {Observable<APIResponseModel>}
   */
  public getIntroVideoURL(type: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/users/intro-video/${type}`);
  }

  /**
   * The term that should be used for professional 'Agents' as defined in the admin dashboard
   * @return {Observable<APIResponseModel>} Agent term
   */
  public getAgentTerm(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/get-agent-term`);
  }

  public getContactUsDetails(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/settings/list`);
  }

  /**
   * To check SSN value is unique or not
   * @param {object} data
   * @return {Observable<APIResponseModel>}
   */
  public checkSSNIsUnique(data: object): Observable<APIResponseModel> {
    Object.keys(data).forEach((key) => data[key] == null && delete data[key]);
    return this.http.post<APIResponseModel>(this.BASE_URL + '/request/ssn-unique-validation', data);
  }

  /**
   * Check if SSN value is unique. Returns false if SSN exists in database, or if error is returned.
   * @param ssn
   * @param id
   * @param showToaster
   * @param isConsumerDetailsUpdate
   */
  public getIsSSNUnique(ssn: string, id: string, showToaster: boolean = true, isConsumerDetailsUpdate: number = 0) {
    const payload = { 'ssn': ssn, 'id': id, 'is_consumer_update': isConsumerDetailsUpdate };
    const checkToaster = (isUnique) => {
      if (!isUnique && showToaster) {
        this.toastrService.error('SSN already exists');
      }
    };
    return new Observable<boolean>((observer) => {
      this.checkSSNIsUnique(payload).subscribe({
        next: (res) => {
          const isUnique = !!res.status;
          checkToaster(isUnique);
          observer.next(isUnique);
        },
        error: (e) => {
          observer.next(false);
          checkToaster(false);
          observer.error(e);
        },
        complete: () => observer.complete(),
      });
      return {
        unsubscribe() {
        },
      };
    });
  }

  /**
   * Updates user verfied
   * @param [requestId]
   * @return user verfied
   */
  public updateUserVerfied(verified = 0): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/verify-people/${verified}`);
  }
}
