import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APIErrorModel, APIStatusCodes } from '../interface/response.interface';


@Injectable({
  providedIn: 'root',
})
/**
 * For common error handling
 */
export class HttpErrorHandlingInterceptor implements HttpInterceptor {

  constructor(private _toastrService: ToastrService) {
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request URL should be excluded from interception
     if (this.shouldExcludeRequest(req)) {
      return next.handle(req);
    }
    return next.handle(req).pipe(
      catchError((error) => {
        this.handleErrorResponse(error);
        return throwError(error);
      }),
    );
  }

  private shouldExcludeRequest(request: HttpRequest<any>): boolean {
    // Add conditions to exclude specific API calls
    const excludedUrls = ['add-card'];
    return excludedUrls.some(url => request.url.includes(url));
  }


  /**
   * Common responses to HTTP errors
   * @param response
   */
  private handleErrorResponse(response: { error: APIErrorModel; status: number; statusText: string; }): void {
    if (!response) {
      return;
    }
    const error: APIErrorModel = response.error;
    let toastMessage: string;
    // TODO maybe replace with all, with ability to limit on a per-call basis to allow individual component handling?
    const c = APIStatusCodes;
    const errorArray = [c.GATEWAY_TIMEOUT, c.BAD_GATEWAY, c.SERVICE_UNAVAILABLE];
    if (errorArray.includes(error.statusCode)) {
      toastMessage = `${response.status}: ${response.statusText} — ${response.error.message}`;
    }
    if (toastMessage) {
      this._toastrService.error(toastMessage);
    }
    console.error(toastMessage, response.error);
  }
}
