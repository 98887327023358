import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ChartsService } from 'src/app/professional-dashboard/services/charts.service';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { USER_TYPES } from 'src/constants/application.const';

@Component({
  selector: 'app-view-professional-management',
  templateUrl: './view-professional-management.component.html',
  styleUrls: ['./view-professional-management.component.css']
})
export class ViewProfessionalManagementComponent implements OnInit {
  public dataLoading: boolean;
  public editData: any;
  public toggleModalAdd: boolean;
  public roleName: string;
  public agentImageSrc: string;
  public agentTerm: string;
  public advisorTerm: string;
  public submitLoaderDelete: boolean;
  public deleteId: any;

  // Doughnut
  public doughnutOptions: any;
  public doughnutColor: any;
  public doughnutChartLabels: Label[];
  public doughnutChartData: MultiDataSet;

  // Bar Chart
  public barChartOptions: ChartOptions;
  public barChartLabels: Label[];
  public barChartData: ChartDataSets[];
  public barChartColor: any;
  public barChartLegend: boolean;
  public barChartValues: any;
  public showData: boolean;
  public roleType: string = '';
  /**
   * constructor
   */
  constructor(
    private chartService: ChartsService,
    private localStorageService: LocalStorageService,
    private professionalUserService: ProfessionalUserService,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    public modalService: CommonModelService,
  ) {
    this.barChartData = [
      { data: [], label: 'Professionals', backgroundColor: '#00ADFB' }
    ];
  }
  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.advisorTerm = this.professionalUserService.getAdvisorTerm();
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.dataLoading = true;
    this.roleName = this.localStorageService.getUserData(USER_TYPES.pro).user.role_name;
    this.barChartOptions = {
      responsive: true,
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawOnChartArea: true,
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      legend: {
        position: 'bottom',
      },
    };

    this.getBarChartData();

    this.barChartLegend = true;

    this.barChartLabels = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    // types of advisors
    this.roleType = this.localStorageService.getUserData(USER_TYPES.pro).user.professional_type_name;
  }

  /**
   * get barchart data
   */
  private getBarChartData() {
    this.dataLoading = true;
    this.chartService.getBarChartDataCompany().subscribe(({
      next: (response) => {
        this.dataLoading = false;
        this.barChartValues = response?.data;
        this.showData = true;
        const data: {} = this.barChartValues;
        const ChartData: number[] = this.barChartLabels.map((key) => data['agents'][key]);
        this.barChartData = [
          {
            data: ChartData,
            label: 'Professionals',
            backgroundColor: '#00ADFB',
            hoverBackgroundColor: '#00ADFB'
          }
        ];
      }
    }))
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, true);
  }

}


