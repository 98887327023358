import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ToggleOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';

import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';


@Component({
  selector: 'app-executor-beneficiaries-info2',
  templateUrl: './executor-beneficiaries-info2.component.html',
})
/**
 * Information about Executor Beneficiaries
 */
export class ExecutorBeneficiariesInfo2Component implements OnInit {

  public loading: boolean;
  public sectionSaveExitOptions: any;
  public requestId: string;
  public userType: string;
  public faCheckCircle: any;
  public faCircleXmark: any;
  public permissionInform: number = 0;
  public nominateExecutor: number = 1;
  public notifyExecutorImmediately: number = 0;
  public accessVaultToExecutor: number = 0;
  public userData: Array<Object>;
  public toggleOptions: Array<ToggleOptionsInterface>;
  public agentTerm: string;
  public spinner: boolean;
  public userId: string;
  public data: any;


  /**
   * @constructor
   */
  constructor(
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private personalDetailsService: PersonalDetailsService,
    private peopleService: PeopleService,
    private router: Router,
    private commonHelper: CommonHelper,
    private zone: NgZone,
    private modalServce: CommonModelService,
    private slugInterceptorService: SlugInterceptorService,
  ) {
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.loading = false;
    this.spinner = false;
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.userData = this.localStorageService.getUserData();
    this.userId = this.userData['user']?.id;
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.faCheckCircle = faCheckCircle;
    this.faCircleXmark = faCircleXmark;
    this.sectionSaveExitOptions = {
      section: 2,
      // type: 2,
    };
    this.toggleOptions = [
      {
        displayText: 'NO',
        value: 0,
      },
      {
        displayText: 'YES',
        value: 1,
      },
    ];
    this.getPersonalDetails();
  }


  /**
   * Toggles modal
   */
  public toggleModal(): void {
    this.spinner = true;
    setTimeout(() => {
      this.modalServce.open('access-control-modal');
      this.spinner = false;
    }, 1000);
  }


  /**
   *
   */
  ngAfterViewChecked(): void {
    this.zone.runOutsideAngular(() => {
      this.localStorageService.storeData('enableExecutor', Number(this.nominateExecutor));
      this.localStorageService.storeData('enableVaultCustodian', Number(!this.nominateExecutor || (this.nominateExecutor && !this.notifyExecutorImmediately)));
    });
  }


  /**
   * Get personal details
   */
  public getPersonalDetails(): void {
    this.loading = true;
    // get people list
    if (this.userType === 'Professional' || this.userType === 'Advisor') {
      this.personalDetailsService.getPersonalDetails(this.requestId).subscribe({
        next: (response: APIResponseModel) => {
          if (response.status) {
            this.permissionInform = response.data.inform_exec_permission;
          }
        }, error: (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
        complete: () => this.loading = false,
      });
    } else {
      this.personalDetailsService.getPersonalDetails().subscribe({
        next: (response: APIResponseModel) => {
          if (response.status) {
            this.nominateExecutor = response.data?.nominate_executor;
            this.notifyExecutorImmediately = response.data?.inform_exec_permission;
            this.accessVaultToExecutor = response.data?.access_to_executor;
          }
          this.loading = false;
        }, error: (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
      });
    }
  }


  /**
   * On Change toggle - PermissionInformExecutor
   *
   * @param {ToggleOptionsInterface} selectedValue
   */
  public onChangeToggleExec(selectedValue: ToggleOptionsInterface): void {
    console.log(selectedValue, 'selectedValue');
    if (!selectedValue) return;
    this.permissionInform = selectedValue?.value;
    if (this.userType === 'Consumer') {
      const payLoad = {
        inform_exec_permission: this.permissionInform,
        roletype: this.userType,
      };
      this.peopleService.authorizeAdvisor(payLoad).subscribe((response: APIResponseModel) => {
        if (response.status) {
          console.log(response.status);
        }
      }, (exception: any) => {
        this.getPersonalDetails();
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    } else {
      const payLoad = {
        inform_exec_permission: this.permissionInform,
        roletype: this.userType,
        request_id: this.requestId,
      };
      this.peopleService.authorizeAdvisor(payLoad).subscribe((response: APIResponseModel) => {
        if (response.status) {
          console.log(response.status);
        }
      }, (exception: any) => {
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    }
  }


  /**
   * Toggles nominate executor
   * @param {ToggleOptionsInterface}toggleValue
   */
  public toggleNominateExecutor(toggleValue: ToggleOptionsInterface): void {
    this.nominateExecutor = toggleValue?.value;
    if (!this.nominateExecutor) {
      this.notifyExecutorImmediately = 0;
      this.accessVaultToExecutor = 0;
    }
    this.storeConsent();
  }


  /**
   * Toggles notify executor immediately
   * @param {ToggleOptionsInterface}toggleValue
   */
  public toggleNotifyExecutorImmediately(toggleValue: ToggleOptionsInterface): void {
    this.notifyExecutorImmediately = toggleValue.value;
    if (!this.notifyExecutorImmediately) {
      this.accessVaultToExecutor = 0;
    }
    this.storeConsent();
  }


  /**
   * Toggles access vault to executor
   * @param {ToggleOptionsInterface}toggleValue
   */
  public toggleAccessVaultToExecutor(toggleValue: ToggleOptionsInterface): void {
    this.accessVaultToExecutor = toggleValue.value;
    this.storeConsent();
  }


  /**
   * store consent permissions
   */
  public storeConsent(): void {
    const payLoad = {
      nominate_executor: this.nominateExecutor,
      inform_exec_permission: this.notifyExecutorImmediately,
      access_to_executor: this.accessVaultToExecutor,
      roletype: this.userType,
    };
    this.peopleService.authorizeAdvisor(payLoad).subscribe((response: APIResponseModel) => {
      if (response.status) {
        console.log(response.status);
      }
    }, (exception: any) => {
      this.getPersonalDetails();
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }


  /**
   * Next page
   */
  public nextPage(): void {
    const param = this.userType == 'Consumer' ? '/' + this.requestId : '';
    const url = `executor-beneficiary/${this.nominateExecutor ? 'manage-executors' : 'custodian-info'}${param}`;
    void this.changeRoute(url);
  }


  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }

}
