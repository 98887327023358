import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { requirePasswordToBeCheckedValidator } from 'src/app/helper/customValidatorPassword.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SlugService } from 'src/app/services/slug.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
})
/**
 * verify client/consumer ccount class
 */
export class VerifyAccountComponent implements OnInit {
  public faEye: any;
  public faEyeSlash: any;
  public togglePasswordViewc: boolean;
  public togglePasswordView: boolean;
  public setPasswordForm: FormGroup;
  public submitLoader: boolean;
  public orgLogo: string;
  public orgLogoPath: string;
  public customerSupportMail: string;
  public spinner: boolean;

  public verifyToken: any // Token extracted from the URL.
  public verifyEmailLater: any; //Flag indicating whether to check email later.
  public firmName: string; //Company name extracted from the URL.
  public clientName: string; //Client email extracted from the URL.
  public clientEmail: string; //Client name extracted from the URL.
  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private activeRouter: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private toastService: ToastrService,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    private localStorageService: LocalStorageService,
    private slugService: SlugService,
    private commonService: CommonService,

  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.spinner = true;
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.customerSupportMail = this.localStorageService.getDataByKey('consumer_support_email')
    this.updateContactInfo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      } else {
        this.orgLogoPath = '';
        this.orgLogo = '';
      }
    });

    this.togglePasswordViewc = false;
    this.togglePasswordView = false;
    this.extractQueryParams();

    this.setPasswordForm = this.formBuilder.group({
      'token': [(this.verifyToken || ''), [Validators.required]],
      'password': ['', { updateOn: 'change', validators: [Validators.required, Validators.minLength(8), requirePasswordToBeCheckedValidator()] }],
      'password_confirmation': ['', { updateOn: 'change', validators: [Validators.required, Validators.minLength(8)] }],
    }, {
      validator: this.ConfirmedValidator('password', 'password_confirmation'),
    });
    this.faEye = faEye;
    this.faEyeSlash = faEyeSlash;
    this.localStorageService.clearAllLocalStorage();

    if (!this.verifyToken) {
      this.toastService.error('Invalid token, redirecting to Login');
      setTimeout(() => {
        this.slugInterceptorService.navigate(['/', 'login']);
        this.spinner = false;
      }, 3000);
      return;
    } else {
      this.userService.checkClientEmailVerified({ token: this.verifyToken }).subscribe((response: APIResponseModel) => {
        this.spinner = false;
        if (response) {
          if (this.verifyEmailLater) {
            if (response?.status) {
              this.toastService.success(response?.message);
              this.slugInterceptorService.navigate(['/', 'login']);
            }
          } else {
            switch (response.data) {
              case 0:
                this.toastService.success('Kindly set your password');
                break;
              case 1: default:
                this.spinner = true;
                this.userService.checkEmailVerified(this.verifyToken).subscribe((response: APIResponseModel) => {
                  if (response?.status) {
                    this.spinner = false;
                    this.toastService.info('Your Email has been already verified');
                    this.slugInterceptorService.navigate(['/', 'login'])
                  } else {
                    this.userService.confirmAccount({ token: this.verifyToken }).subscribe((response: APIResponseModel) => {
                      if (response.status) {
                        this.spinner = false;
                        this.toastService.success(response.message);
                        this.slugInterceptorService.navigate(['/', 'login']);
                      }
                    }, () => {
                      this.spinner = false;
                      this.toastService.success('Kindly set your password')
                    })
                  }
                });
                break;
                case 2:
                this.toastService.info('Your Email has been already verified');
                this.slugInterceptorService.navigate(['/', 'login'])
                break;
            }
          }
        }
      })
    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.setPasswordForm.controls;
  }

  /**
   *
   * Password confirmation
   * @param {string} oldpassword
   * @param {string} newpassowrd
   * @return {FormGroup}
  */
  public ConfirmedValidator(oldpassword: string, newpassowrd: string): Function {
    return (formGroup: FormGroup) => {
      const currentPassword = formGroup.controls[oldpassword];
      const confirmPassword = formGroup.controls[newpassowrd];
      if (currentPassword?.errors && !confirmPassword?.errors?.confirmedValidator) {
        return;
      }
      if (currentPassword.value !== confirmPassword.value) {
        confirmPassword.setErrors({ confirmedValidator: true });
      } else {
        confirmPassword.setErrors(null);
      }
    };
  }

  /**
   * Reset password
   */
  public setNewPassword(): void {
    this.submitLoader = true;
    this.spinner = true;
    const formData = {
      token: this.setPasswordForm.value.token,
      password: this.setPasswordForm.value.password,
      confirm_password: this.setPasswordForm.value.password_confirmation,
      check_later: this.verifyEmailLater ? 1 : 0
    };
    this.userService.verifyAccount(formData).subscribe((response: APIResponseModel) => {
      this.submitLoader = false;
      if (response.status) {
        this.spinner = false;
        this.toastService.success(response?.message);
        void this.slugInterceptorService.navigate(['login']);
      }
    }, (exception) => {
      this.spinner = true;
      this.submitLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
   * on click logo
   */
  public onClickLogo() {
    void this.slugInterceptorService.navigate(['/']);
  }

  /**
   * Updates the contact information for support.
   * 
   * 
   * @returns {void}
   */
  public updateContactInfo() {
    this.commonService.getContactUsDetails().subscribe({
      next: (response) => {
        this.customerSupportMail = response.data?.support_email
        this.localStorageService.storeData('constumer_support_email', this.customerSupportMail);
      },
      error: (error) => {
        this.customerSupportMail = ''
      }
    })
  }


  /**
   * Extracts query parameters from the URL and assigns them to class properties.
   * 
   * @returns {void}
   */
  public extractQueryParams() {
    const queryParams = this.commonHelper.decryptRegisterLink(this.router.url);
    this.verifyToken = queryParams['token'];
    this.verifyEmailLater = queryParams['check_later'];
    this.clientEmail = queryParams['email'];
    this.clientName = queryParams['name'];
    this.firmName = queryParams['company_name'];
  }
}
