import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';


@Component({
  selector: 'app-consumer-company-new',
  templateUrl: './consumer-company-new.component.html',
})
export class ConsumerCompanyNewComponent implements OnInit {
  public mobile: boolean;
  public innerWidth: number;
  public vaultVideo: string;

  constructor(private modalService: CommonModelService) {}
  ngOnInit(): void {
    this.onResize();
  }
  /**
   * window resize
   * @param {any} event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.innerWidth = window.innerWidth;
    this.mobile = this.innerWidth <= 767;
  }

  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');
  }
}
