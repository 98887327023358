import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { UserService } from 'src/app/services/user.service';
import { ConsumerPaymentMethod, PAYMENT_STATUS, VERIFICATION_STATUS } from 'src/constants/application.const';
import { CheckoutService } from '../../checkout/checkout.service';


@Component({
  selector: 'app-dashborad-right-content',
  templateUrl: './dashborad-right-content.component.html',
})
/**
 * Dashboard right content
 */
export class DashboradRightContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() totalStage: number;
  @Input() dlbId: string;
  @Input() clientDetails: any;
  @Input() paymentStatus: boolean;
  // @Output() idVerificationEmitter = new EventEmitter<number>();


  public completedLocal: boolean;
  public userData: any;
  public submitLoader: boolean;
  public progressValue: number;
  public requestId: string;
  public userType: string;
  public professionalPayment: boolean;
  public isDemoEmail: boolean;
  public paymentSts = PAYMENT_STATUS;
  public currentPaymentSts: any;
  public agentTerm: string;
  public messageData: any;
  public clientRequestId: any;
  // public isIdVerified: number;
  public verificationStatus: object;
  public advisorArrayLength: any;

  public customerSupportMail: string;
  public customerSupportPhone: number;

  public paymentStatusSubscription: Subscription;

  public paymentMode: ConsumerPaymentMethod;
  public isCobrandPaidByPro: boolean;
  public currentUser: Object;
  public showPaymentOption: boolean;
  public slugDetails: any;



  /**
   * @constructor
   */
  constructor(
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private modalService: CommonModelService,
    private commonHelper: CommonHelper,
    private commonService: CommonService,
    private slugInterceptorService: SlugInterceptorService,
    private checkoutService: CheckoutService
  ) {
  }


  /**
   * switch right dashboard content
   * @param {boolean} value
   */
  @Input() set completed(value: boolean) {
    this.completedLocal = value;
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.getContactDetails();
    this.currentUser = this.localStorageService.getUserData();
    this.slugDetails = this.currentUser?.['slug_details'];
    this.currentPaymentSts = this.localStorageService.getDataByKey('is_payment_complete');
    this.paymentMode = this.localStorageService.getDataByKey('payment_mode');
    this.isCobrandPaidByPro = (this.paymentMode === ConsumerPaymentMethod.PAID_BY_PROFESSIONAL);
    this.showPaymentOption = (this.isCobrandPaidByPro && this.slugDetails) ? false : this.currentPaymentSts != undefined && this.currentPaymentSts != this.paymentSts.paid
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.isDemoEmail = this.localStorageService.getDataByKey('is-demo-email');
    this.verificationStatus = VERIFICATION_STATUS;
    this.advisorArrayLength = this.localStorageService.getDataByKey('advisorArray');
    this.getContactDetails();
    if (this.userType === 'Consumer') {
      this.getLocalStorageUserData();
      this.clientRequestId = this.localStorageService.getUserData()?.request?.id;
      this.paymentStatusSubscription = this.userService.paymentStatusObserve.subscribe((data) => {
        if (!!data) {
          this.currentPaymentSts = this.localStorageService.getDataByKey('is_payment_complete');
        }
      });
      // this.userService.userIdVerfiedObserve.subscribe(() => {
      //   this.isIdVerified = Number(this.localStorageService.getDataByKey('is_id_verified'));
      // });
    }
  }


  /**
   * onchange
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (this.userType !== 'Consumer') {
      this.getUserData(changes);
    }
  }


  /**
   * get localstorage user data
   */
  private getLocalStorageUserData(): void {
    this.userData = this.localStorageService.getUserData();
    this.currentPaymentSts = this.localStorageService.getDataByKey('is_payment_complete');
    this.progressValue = (this.userData?.request?.stage / this.totalStage) * 100 || 0;
    this.progressValue = Math.floor(this.progressValue);
  }


  /**
   * get  user data
   * @param {SimpleChanges} changes
   */
  private getUserData(changes: SimpleChanges): void {
    this.userData = changes.clientDetails.currentValue;
    this.progressValue = (this.userData?.stage / this.totalStage) * 100 || 0;
    this.progressValue = Math.floor(this.progressValue);
    this.professionalPayment = this.userData?.is_payment_complete === 1;
    if (this.completedLocal && !this.professionalPayment) {
      this.payDlb();
    }
  }


  /**
   * Pay for dlb
   */
  public payDlb(): void {
    if (this.userType === 'Consumer') {
      this.checkoutService.proceedTosubscriptionPayment().subscribe(() => {
        void this.slugInterceptorService.navigate(['/checkout']);
      });;
    }
  }


  // /**
  //  * Directs to verify ID
  //  */
  // public iDVerification(): void {
  //   this.idVerificationEmitter.emit(1); // call Identity Verification Required
  // }


  /**
   * open add appointment with agent
   */
  public openAppointmentModal(): void {
    if (this.advisorArrayLength === 0) return;
    this.modalService.open('agent-appointment-modal');
  }


  /**
   * change route
   */
  public navigateToPro() {
    void this.slugInterceptorService.navigate(['dashboard'], null, true);
  }


  get buttonTooltip(): string {
    if (this.advisorArrayLength === 0) {
      return 'Add a PSP to schedule appointments';
    }
  }


  public getContactDetails() {
    this.commonService.getContactUsDetails().subscribe((response) => {
      this.customerSupportMail = response.data?.support_email;
      this.customerSupportPhone = response.data?.support_number;
    });
  }


  ngOnDestroy(): void {
    this.paymentStatusSubscription?.unsubscribe();
  }
}
