import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-about-yourself',
  templateUrl: './about-yourself.component.html'
})
/**
 * About yourself component ( Has nested routing )
 */
export class AboutYourselfComponent implements OnInit {
  public userType:string;
  public requestId:string;
  /**
   * @constructor
   */
  constructor(private route:ActivatedRoute,
    private localStorageService:LocalStorageService) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.userType = this.localStorageService.getDataByKey('role');
  }
}
