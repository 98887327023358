import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { environment } from '../../../environments/environment';
import { CommonHelper } from '../../helper/common.helper';
import { FileValidationOptionsInterface } from '../../interface/common.interface';
import { CommonModelService } from '../../services/common-model.service';
import { CommonService } from '../../services/common.service';
import { LocalStorageService } from '../../services/local-storage.service';


@Component({
  selector: 'app-file-listing',
  templateUrl: './file-listing.component.html',
  styleUrls: ['./file-listing.component.css'],
})
export class FileListingComponent implements OnInit {
  @Input() fileUploadPercent: number;
  @Input() isLoading: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() subDirectory: string = '';
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() imageFormatFiltering = ['image/jpeg', 'image/jpg', 'image/png', 'jpeg', 'jpg', 'png' ,'png,','jpeg,','jpg,','mp3','mp3,','mp4','mp4,'];
  @Input() fileArray: Array<any> = [];
  @Input() fileUploadSettings: FileUploadSettingsModel;
  @Input() hideDeleteIcon: boolean;
  @Input() removePrefix:boolean = true;
  @Output() fileListingEmitter: EventEmitter<FileListingEmitModel> = new EventEmitter();
  private userType: string;
  private userRequestId: string;
  canAccessForm: boolean;


  constructor(private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private commonService: CommonService) {
  }


  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.userType = this.localStorageService.getDataByKey('role');
    this.userRequestId = this.commonHelper.getRequestId(this.route);
  }


  /**
   * View image popup
   *
   * @param index
   */
  public viewImage(index: number): void {
    if (!this.canAccessForm || this.isDisabled) {
      return;
    }
    let file = this.fileArray[index];
    let file_extension = file['file_extension'];
    if (file instanceof File) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64String = event.target.result as string;
        this.commonService.setImageViewPopupTrigger({imageSrc:base64String, fileExtension:file_extension});
        this.modalService.open('view-image-modal');
      };
      reader.readAsDataURL(file);

    } else if (file instanceof FileReader) {
      const base64String = file.result as string;
      this.commonService.setImageViewPopupTrigger({imageSrc:base64String, fileExtension:file_extension});
      this.modalService.open('view-image-modal');
    } else if (!file.size) {
      const filename = typeof file === 'string' ? file : file.name;
      const PIC_URL = this.getFileLocation(filename);
      this.commonService.setImageViewPopupTrigger({imageSrc:PIC_URL, fileExtension:file_extension});
      this.modalService.open('view-image-modal');
    } else {
      console.error('Invalid file type');
    }
  }


  /**
   * Download file
   *
   * @param {any} file
   */

  public downloadFile(file: any): void {
    if (!this.canAccessForm || this.isDisabled) {
      return;
    }
    const downloadFileName = file.name;
    if (file.size > 0) {
      const url = window.URL.createObjectURL(file);
      this.downloadURI(url, file.name);
    } else if (this.userType === 'Consumer' || this.userType === 'Professional') {
      let downloadLink = this.getFileLocation(downloadFileName);
      saveAs(downloadLink, file.name);
    }
  }


  /**
   * @param filename
   * @returns the fetch URL for the specified image.
   * @private
   */
  private getFileLocation(filename: string): string {
    const sDir = this.subDirectory ? `${this.subDirectory}/` : '';
    return `${environment.BASE_URL_RAW}uploads/${this.userRequestId}/${sDir}${filename}`;
  }


  /**
   * Preview the file by download
   * @param {any} uri
   * @param {string} name
   */
  private downloadURI(uri, name) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  /**
   * delete file
   *
   * @param filename
   * @param {number} index - The index of the file to delete in fileArray.
   */
  public deleteImage(filename: string, index: number): void {
    if(!this.isDisabled) {
      this.fileListingEmitter.emit({ image_delete: filename });
    }
  }
  
  /**
   * Checks if the file type is supported for filtering.
   * @param {any} file - The file object containing either a 'type' or 'file_extension' property.
   * @returns {boolean} - True if the file type is supported, false otherwise.
   */
  public checkFileType(file:any ){
    let fileType = file.type ?? file.file_extension;
    if (typeof fileType === 'string') {
      fileType = fileType.toLowerCase();
    }
    return this.imageFormatFiltering.includes(fileType.toLowerCase());
  }


  public onFileUploadChangeEmitter($event) {
    this.fileListingEmitter.emit({ fileUploadChange: $event });
  }


  onIsFileDrop($event: any) {
    this.fileListingEmitter.emit({ isFileDropWhenNoDocumentSelected: $event });
  }


  onFileUploadHandlerClick() {
    this.fileListingEmitter.emit({ hasAttemptedUpload: true });
  }


  getName(file: any): string {
    const name = file?.name.split('/');
    return this.commonHelper.removePrefixNumber(file['fileName'] ?? name[name.length - 1]);;
  }

}


export interface FileListingEmitModel {
  image_delete?: string;
  /** {@link FileUploadHandlerComponent.fileUploadChangeEmitter} */
  fileUploadChange?: any;
  /** {@link FileUploadHandlerComponent.fileDeleteChangeEmitter} */
  fileUploadDelete?: any;
  /** {@link FileUploadHandlerComponent.isFileDropWhenNoDocumentSelected} */
  isFileDropWhenNoDocumentSelected?: any;
  hasAttemptedUpload?: boolean;
}


export interface FileUploadSettingsModel {
  fileValidationOptions: FileValidationOptionsInterface;
  hideFileOperations?: boolean;
  allowMultiple?: boolean;
  isDigitalFiles?: boolean;
  displayFileName?: boolean;
  hasDocumentType?: boolean;
}
