import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TreeviewItem } from 'ngx-treeview';
import { MessageModalDataInterface } from 'src/app/sharedComponent/message-modal/message-modal.component';
import { CommonHelper } from '../../../../helper/common.helper';
import { CommonModelService } from '../../../../services/common-model.service';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { PeopleService } from '../../../../services/people.service';
import { AccessControlComponent } from '../access-control/access-control.component';
import { SchedulerComponent } from './scheduler/scheduler.component';


@Component({
  selector: 'app-advisor-settings-modal',
  templateUrl: './advisor-settings-modal.component.html',
})

/**
 * Advisor settings
 */
export class AdvisorSettingsModalComponent implements OnInit, OnChanges {
  @ViewChild(AccessControlComponent) aclComponent: AccessControlComponent;
  @ViewChild(SchedulerComponent) scheduleComponent: SchedulerComponent;
  @Input() advisorData: any;
  @Input() peopleData: any;
  @Input() index: number;
  @Output() editInfo: EventEmitter<boolean> = new EventEmitter<boolean>();
  private userType: string;
  private loaders: {} = {};
  public readonly disableInactiveAccountWarning: boolean = true;
  public advisorID: string;
  public requestId: string;
  public items: TreeviewItem[];
  public bViewAdvisorInfo: boolean = true;
  public showSettingsToggle: boolean = true;

  public messageData: MessageModalDataInterface = {
    message: [`We noticed that you are attempting to remove a PSP associated with your Vault. Please be advised that if you proceed with this action, you will no longer be considered a cobrand customer and will be treated as a regular customer.<br><br>

    As a regular user, you will be obligated to pay the standard subscription fee at the end of your current subscription period. This fee is required to maintain your access to our Vault services.<br><br>
    
    If you still wish to proceed with the removal, please click <i>Confirm<i> below.`],
    buttons: [{
      content: 'Confirm',
      emitValue: { data: true },
      class: 'custom-border-button bg-white confirm_cancellation',
    }, {
      content: 'Cancel',
      emitValue: { data: false },
    }],
  };


  /**
   * constructor
   */
  constructor(private modalService: CommonModelService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private peopleService: PeopleService,
    private commonHelper: CommonHelper) {
  }


  /**
   * loaded on initialization
   */
  ngOnInit(): void {
    this.requestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
  }


  /**
   *
   * @param{SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['advisorData'] && this.advisorData) {
      this.advisorID = this.advisorData['advisor_id'];
      this.showSettingsToggle = !!this.advisorData['has_active_account'];
    }
  }


  /**
   * Runs save for settings, closes modal when complete.
   */
  public save() {
    const closeCheck = () => {
      if (!this.isLoading()) {
        this.toastr.success('Changes Saved');
        this.closeModal();
      }
    };
    this.aclComponent.updateAccess().subscribe({
      next: (r) => {
        if (r.status) {
          this.loaders['aclLoading'] = false;
          closeCheck();
        }
      },
    });
  }


  /**
   * If any loaders are set to true, return false.
   * @return{boolean}
   */
  public isLoading(): boolean {
    for (const loadersKey in this.loaders) {
      if (this.loaders[loadersKey]) {
        return true;
      }
    }
    return false;
  }


  /**
   * close modal
   */
  public closeModal() {
    this.bViewAdvisorInfo = true;
    this.showSettingsToggle = true;
    this.modalService.close('advisor-settings-modal');
  }


  /**
   * remove advisor
   */
  public removeAdvisor() {
    const data: {} = {
      is_delete: '1',
      id: this.advisorData.advisor_id,
      roletype: this.userType,
      request_id: this.requestId,
    };
    if (this.userType === 'Consumer') {
      delete data['request_id'];
    }
    this.peopleService.storePeopleDetails(data).subscribe({
      next: (r) => {
        if (r.status) {
          this.closeModal();
          this.peopleService.advisorObservableId(data['id']);
          this.commonHelper.toastrDeleteSuccess();
          if (this.advisorData.is_primary_advisor) {
          this.modalService.close('confirm-remove-message-modal');
          this.commonHelper.removeProDetailsFromLocalStorage();
          }
        }
      },
      error: (error) => this.commonHelper.httpResponseHandler(error?.error),
      complete: () => this.loaders['removeAdvisor'] = false,
    });
  }


  /**
   * Toggle between viewing advisor info and viewing advisor settings.
   */
  public viewAdvisorInformation() {
    this.bViewAdvisorInfo = !this.bViewAdvisorInfo;
  }


  get agentTerm() {
    // return this.commonHelper.LocalStorageAgentTerm();
    return 'Professional Service Provider';
  }


  editAdvisorInformation() {
    this.editInfo.emit(true);
  }

  /**
   * Listens for events and handles removal of advisor based on event data.
   * If event data is present, removes advisor. Otherwise, closes the modal.
   * @param $event The event object containing data.
   */
  messageListener($event: any) {
    if ($event?.['data']) {
      this.removeAdvisor();
    } else {
      this.modalService.close('confirm-remove-message-modal');
    }
  }

  /**
   * Handles click event to remove advisor.
   * If the advisor is the primary advisor, opens confirmation modal. 
   * If not, directly removes the advisor.
   */
  onClickRemove() {
    if (this.advisorData.is_primary_advisor) {
      this.modalService.open('confirm-remove-message-modal');
    } else {
      this.removeAdvisor();
    }
  }
}
