<app-card-detail
[vaultTransferAmount]="vaultTransferAmount"
[total]="total"
[activePackage]="activePackage"
[dlbId]="dlbId"
[paymentCompleted]="paymentCompleted"
[isTransferDigitalWallet]="isTransferDigitalWallet"
[transferVaultScreen]="transferVaultScreen"
[isUpdatePlan]="isUpdatePlan"
[subscriptionFlag]="subscriptionFlag"
></app-card-detail>
<app-payment-success></app-payment-success>
<app-payment-processing></app-payment-processing>
<app-payment-failure></app-payment-failure>
