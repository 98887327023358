import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { USER_TYPES } from "../../../constants/application.const";

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',

})

/**
 * Un Authorized Component
 */
export class UnauthorizedComponent {
  public loggedIntype: UserType;

  /**
   * constructor
   */
  constructor(
    private localStorageService:LocalStorageService,
    private router: Router,
    private slugInterceptorService:SlugInterceptorService,
  ) {

  }

  /**
   * loaded on initialization
   */
  public ngOnInit(): void {
    this.checkType();
  }

  /**
   * check type of logged  in user
   */
  public checkType() {
    const consumer = this.localStorageService.getUserData();
    const professional = this.localStorageService.getUserData(USER_TYPES.pro);
    const executor = this.localStorageService.getUserData(USER_TYPES.exe);
    const organization = this.localStorageService.getUserData(USER_TYPES.org);
    if (consumer != null && Object.keys(consumer)?.length > 0) {
      this.loggedIntype = UserType.Consumer;
    } else if (professional != null && Object.keys(professional)?.length > 0) {
      this.loggedIntype = UserType.Pro;
    } else if (organization != null && Object.keys(organization)?.length > 0) {
      this.loggedIntype = UserType.Org;
    } else if (executor !== null && Object.keys(executor)?.length > 0){
      this.loggedIntype = UserType.Exe;
    }
  }

/**
 * Change route based on the logged-in user type.
 */
public navigateToDashboard() {
  switch (this.loggedIntype) {
    case UserType.Exe:
      this.router.navigate(['/executor-custodian/dashboard']);
      break;
  
    case UserType.Pro:
      void this.slugInterceptorService.navigate(['dashboard'], null, true);
      break;

    case UserType.Consumer:
      void this.slugInterceptorService.navigate(['dashboard']);
      break;
  
    default:
      void this.slugInterceptorService.navigate(['dashboard']);
      break;
  }
}
}

enum UserType {
  Consumer = 'Consumer',
  Pro = 'Professional',
  Org = 'Organization',
  Exe = 'Executor'
}
