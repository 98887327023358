<div *ngIf="!input" (click)="clickDropdown()" aria-readonly="true"
  class="cursor-pointer h-10 sm:h-12 input py-3 px-4 text-regular text-sm mt-1 rounded-xs appearance-none border block focus:outline-none rounded flex flex-row items-center justify-between"
  [ngClass]="enableError?'form-input-error':''">
  <a class=" {{selectedOption?.displayText?'text-darkgrey':'placeholder-grey'}} "
    [ngClass]="{'text-white' : year, 'placeholder-grey' : disabled}">{{selectedOption?.displayText || placeholder}}</a>
  <div>
    <img src="assets/images/about-yourself/angle-down-arrow.png" class="w-3" alt="digital vault" *ngIf="!year && !isVerifySecurityPage">
    <img src="assets/images/overview/white-down-arrow.svg" class="w-3" alt="digital vault" *ngIf="year">
  </div>
</div>

<div class="absolute w-full" [class]="class" *ngIf="selectToggle">
  <ul class="input w-full border up-arrow bg-white rounded-xs mt-4 absolute z-10">
    <li class="cursor-pointer px-3 text-grey text-sm py-2 flex justify-between items-center select-option "
      *ngFor="let data of options ; let i = index" (click)="onSelectChange(i)"
      [ngClass]="{ 'list-active-bg': selectedOption?.value === data?.value }">
      {{data?.displayText}}
      <img src="assets/images/about-yourself/list-selected.svg" class="w-4" alt="digital vault"
        *ngIf="selectedOption?.value === data?.value">
    </li>
  </ul>
</div>
