<nav>
    <div>
        <div class="relative flex h-20 items-center justify-between">

            <div class="flex items-stretch justify-start px-4 sm:px-6 lg:px-8 gap-2 sm:gap-4 flex-row">
                <div class="flex flex-shrink-0 items-center">
                    <img src="assets/images/consumer/logo-old.png" alt="legacy-now" class="object-contain w-40 sm:w-48 cursor-pointer" (click)="menuChange('home')">
                </div>

                <!-- Sponsored By Logo -->
                <a *ngIf="orgLogoPath"  class=" border-l-black pl-6 cursor-pointer max-h-12 maxw128">
                    <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
                  </a>
                <!-- <div class="flex items-start gap-1 md:gap-2 flex-col">
                    <p *ngIf="orgLogoPath"
                        class="whitespace-nowrap text-xs md:text-sm lg:text-regular text-dark-grey">
                        Sponsored by:</p>
                    <a class="cursor-pointer max-h-12 maxw128" (click)="navigate('dashboard')" *ngIf="orgLogoPath">
                        <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
                    </a>
                </div> -->

                <div class="hidden px-2 sm:px-6 lg:px-8 lg:flex items-center justify-between bg-primary nav__right">
                    <div class="flex space-x-4">
                        <a class="cursor-pointer text-white font-ave-semibold text-base xl:text-lg px-3 py-2"
                           (click)="menuChange('pricing')">Pricing</a>
                        <a class="cursor-pointer text-white font-ave-semibold text-base xl:text-lg px-3 py-2"
                           (click)="menuChange('contact-us')">Contact</a>
                        <a class="cursor-pointer text-white font-ave-semibold text-base xl:text-lg px-3 py-2"
                           (click)="viewIntroVideo()">Intro Video</a>
                    </div>
                    <div class="flex font-ave-medium text-base lg:text-lg leading-6 text-white gap-1">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                        stroke-linecap="round" stroke-linejoin="round" class="w-3 h-3 sm:w-5 sm:h-5 mt-3">
                     <path
                       d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                     </path>
                     </svg>
                        <a href="tel:+1 {{customerSupportPhone}}" class="text-white font-ave-semibold text-base xl:text-lg px-2 py-2"> +1 {{customerSupportPhone}}</a>
                    </div>
                </div>
            </div>
            <div class="absolute inset-y-0 right-0 flex items-center lg:hidden">
                <!-- Mobile menu button-->
                <button type="button" (click)="responsiveMenuToggle = !responsiveMenuToggle"
                    class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    aria-controls="mobile-menu" aria-expanded="false">
                    <span class="absolute -inset-0.5"></span>
                    <span class="sr-only">Open main menu</span>
                    <!--
                        Icon when menu is closed.

                        Menu open: "hidden", Menu closed: "block"
                      -->
                    <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                        aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                    <!--
                        Icon when menu is open.

                        Menu open: "block", Menu closed: "hidden"
                      -->
                    <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                        aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
        </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div class="lg:hidden" *ngIf="responsiveMenuToggle" id="mobile-menu">
        <div class="space-y-1 px-2 pb-3 pt-2">
            <a (click)="menuChange('pricing')"
                class="text-primary hover:bg-red-500 hover:text-white block rounded-md px-3 py-2 text-base font-ave-semibold">Pricing</a>
            <a (click)="menuChange('contact-us')"
                class="text-primary hover:bg-red-500 hover:text-white block rounded-md px-3 py-2 text-base font-ave-semibold">Contact</a>
            <a (click)="viewIntroVideo()"
                class="text-primary hover:bg-red-500 hover:text-white block rounded-md px-3 py-2 text-base font-ave-semibold">Intro Video</a>
        </div>
    </div>
</nav>
<app-video-view-popup [id]="videoModalID" [userRole]="'consumer'" [class]="'w-full h-full'"></app-video-view-popup>
