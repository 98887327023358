<app-common-modal id="add-government-id-modal" class="app-common-modal">
  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
         (click)="closeModel()">
    <form [formGroup]="governmentIDsForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Government ID Details
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">
            Add all your government ID details here.
          </p>
          <p class="text-regular font-medium text-xs mt-2 error-alert"
             *ngIf="(isNewAsset && !permissions?.['add']) || (!isNewAsset && !permissions?.['edit'])">
            You do not have permission to
            {{isNewAsset ? 'add information for this client.' : 'edit this client\'s information.'}}
          </p>
        </div>
        <div class="col-span-12 relative">
          <label for="government-id-type" class="text-label-grey text-sm text-regular">Type of Government ID*</label>
          <app-select-with-search [options]="governmentIDList" [placeholderValue]="'Select Government ID type'"
                                  (optionChangeEmitter)="optionChangeEmitterIDType($event)" id="government-id-type"
                                  [disabled]="!canEdit || !canAccessForm"
                                  [defaultSelected]="governmentIDsForm?.value?.['government_id']"></app-select-with-search>
        </div>
        <div *ngIf="useOther" class="col-span-12">
          <label for="document_type_other" class="text-label-grey text-sm text-regular">Define Other*</label>

          <div class="relative flex items-center">
            <input [attr.disabled]="!canEdit || undefined" type="text" id="document_type_other"
                   class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                   name="government_id_other" formControlName="government_id_other" placeholder="Define Other"
                   [ngClass]="formGet?.government_id_other.errors && formGet?.government_id_other.touched?'form-input-error':'form-input-focus'">
          </div>
          <p *ngFor="let error of getErrors('government_id_other')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="id-number" class="text-label-grey text-sm text-regular">ID Number*</label>
          <input [attr.disabled]="!canEdit || undefined" type="text" minlength="3" maxlength="31" id="id-number"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 placeholder="e.g. 546545456256" formControlName="id_number" name="id-number"
                 [ngClass]="formGet?.id_number.errors && formGet?.id_number.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('id_number')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 lg:col-span-6">
          <label [for]="'issue_date'" class="text-label-grey text-sm text-regular">
            ID Issued Date ( {{domainDateFormat}} )
          </label>
          <app-datepicker-toggle
            inputID="issue_date"
            class="mt-1"
            [bsConfig]="bsConfig"
            [disabled]="!canEdit"
            [useFormControl]="formGet.issue_date">
          </app-datepicker-toggle>
          <p *ngFor="let error of getErrors('issue_date')"
          class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
          </p>
        </div>

        <div class="col-span-12 lg:col-span-6">
          <label [for]="'expiry_date'" class="text-label-grey text-sm text-regular">
            ID Expiry Date ( {{domainDateFormat}} )
          </label>
          <app-datepicker-toggle
            inputID="expiry_date"
            class="mt-1"
            [bsConfig]="expiryDateBsConfig"
            [disabled]="!canEdit"
            [useFormControl]="formGet.expiry_date">
          </app-datepicker-toggle>
          <p *ngFor="let error of getErrors('expiry_date')"
          class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
          </p>
        </div>
        <div class="col-span-12 md:col-span-6 relative ">
          <label for="country-issued" class=" text-grey text-sm text-regular ">Country Issued</label>
          <app-country-search-component (optionChangeEmitter)="optionChangeListenerCountry($event)"
                                        [defaultSelected]="formGet?.country_issued?.value" id="country-issued"
                                        [disabled]="!canEdit || !canAccessForm"
                                        [enableError]="formGet?.country_issued.errors && formGet?.country_issued.touched">
          </app-country-search-component>
        </div>
        <div class=" col-span-12 md:col-span-6 relative " (click)="markAsTouchedType('state_issued')"
             *ngIf="selectedCountryName!==unitedKingdom">
          <label for="state-issued" class=" text-grey text-sm text-regular ">State Issued</label>
          <app-state-search-component [countryCode]="formGet?.country_issued?.value" id="state-issued"
                                      [enableError]="formGet?.state_issued.errors && formGet?.state_issued.touched"
                                      [disabled]="!canEdit || !canAccessForm"
                                      [defaultSelected]="formGet?.state_issued?.value"
                                      (optionChangeEmitter)="optionChangeListenerState($event)" tabindex="0"
                                      (focusout)="markAsTouchedType('state_issued')"></app-state-search-component>
          <p *ngFor="let error of getErrors('state_issued')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
      </div>
      <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 ">
        <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewAsset" [buttonText]="'Remove Government ID'"
                           [loader]="submitLoaderDelete" [valid]="!submitLoader && canEdit" [buttonType]="'button'"
                           [outlineOnly]="true" (click)="deleteGovernmentID()">
        </app-button-loader>
        <app-button-loader class="w-full sm:w-auto"
                           [buttonText]="!isNewAsset?'Update Government ID':'Add Government ID'"
                           [valid]="((isNewAsset && permissions?.['add']) || (!isNewAsset && permissions?.['edit'])) && !submitLoaderDelete && canEdit"
                           [loader]="submitLoader" [buttonType]="'submit'">
        </app-button-loader>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>
