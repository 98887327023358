import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * Overview service for all user data
 */
export class ConsumerPaymentsService {
  public BASE_URL:string;
  /**
   * dashboard data event handlers
   */
  public showPaymentScreen:any;
  public showPaymentScreenObserve:Observable<any>;
  public subscriptionPlan:any;
  public subscriptionPlanObserve:Observable<any>;
  public transferVault:any;
  public transferVaultScreenObserve:Observable<any>;
  public subscriptionPlan1:any;
  public subscriptionPlanObserve1:Observable<any>;
  public transferVault1:any;
  public transferVaultScreenObserve1:Observable<any>;
  /**
   * @constructor
   */
  constructor(
    private http:HttpClient,
  ) {
    this.BASE_URL = environment.BASE_URL;
    this.showPaymentScreen=new BehaviorSubject(false);
    this.showPaymentScreenObserve=this.showPaymentScreen.asObservable();
  }
  /**
   * payment screen obserable events
   *
   * @param {ObservableEventInterface} data
   */
  public paymentScreenObservableEvents(data: boolean):void {
    this.showPaymentScreen.next(data);
  }
}
