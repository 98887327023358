<div class="flex flex-col mt-10">
    <div class="m-auto text-center" *ngIf="!isSuccessMsg && !isWrongMsg">
        <img src="assets/images/digital-files/loader.gif" alt="" class="text-center w-20 h-20">
    </div>
    <div class="m-auto">
        <p class="text-gray-500 text-regular md:text-base text-base pr-3">
        <img src="assets/images/consumer/rounded-tick.svg" class="w-20 h-20" *ngIf="isSuccessMsg">
        <img src="assets/images/consumer/sad-smiley.svg" class="w-20 h-20" *ngIf="isWrongMsg"></p>  
    </div>
    <div class="text-center">
        <p class="text-gray-600 text-lg font-bold md:text-base text-base">{{greeting}}</p>   
    </div>
    <div class="text-center">
        <p class="text-gray-500 text-md font-semibold">
            {{message}}
        </p>  
    </div> 
</div>