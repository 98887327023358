import { Component, Input } from '@angular/core';
import { CommonModelService } from '../../services/common-model.service';


@Component({
  selector: 'app-aida-floating-video',
  templateUrl: './aida-floating-video.component.html',
  styleUrls: ['./aida-floating-video.component.css'],
})
export class AidaFloatingVideoComponent {
  @Input() position: string = 'right-6 bottom-6';
  @Input() vaultVideo: string;
  @Input() videoModalID: string = 'aida-floating-video-modal';
  @Input() tooltipText: string;
  public showToolTipText: boolean = false;


  constructor(private modalService: CommonModelService) {
  }


  /**
   * open modal
   */
  public openModal() {
    this.modalService.open(this.videoModalID);
  }


  onHover(type: 'enter' | 'leave') {
    this.showToolTipText = type == 'enter';
  }
}
