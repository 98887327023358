<div class="section">
    <div class="max-w-screen mx-auto">
        <div class="grid grid-cols-12 gap-4 row-gap-8 md:row-gap-5">
            <div class="col-span-12">
                <h2 class="text-pale-blue font-ave-bold font-bold text-2xl md:text-3xl lg:text-5xl mb-2">
                    LegacyNOW Subscription
                </h2>
                <p class="text-grey font-ave-medium text-lg sm:text-xl lg:text-2xl">Payment Information</p>
            </div>
            <div class="col-span-12">
                <div>
                    <label for="card-name" class="text-base pb-2 font-ave-semibold text-black">Card Number*</label>
                    <input type="number" name="card-name" id="card-name"
                        class="input w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                        placeholder="e.g. 1234 5678 9101 1121">
                    <!-- <p class="font-ave-medium text-xs md:text-sm mt-2 error-alert">
                        Please enter a valid email address.</p> -->
                </div>
            </div>
            <div class="col-span-12">
                <div>
                    <label for="expiration-month" class="text-base pb-2 font-ave-semibold text-black">Expiration Month*</label>
                    <input type="number" name="expiration-month" id="expiration-month"
                        class="input w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                        placeholder="e.g. 08">
                    <!-- <p class="font-ave-medium text-xs md:text-sm mt-2 error-alert">
                        Please enter a valid email address.</p> -->
                </div>
            </div>
            <div class="col-span-12">
                <div>
                    <label for="expiration-year" class="text-base pb-2 font-ave-semibold text-black">Expiration Year*</label>
                    <input type="number" name="expiration-year" id="expiration-year"
                        class="input w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                        placeholder="e.g. 2022">
                    <!-- <p class="font-ave-medium text-xs md:text-sm mt-2 error-alert">
                        Please enter a valid email address.</p> -->
                </div>
            </div>
            <div class="col-span-12">
                <div>
                    <label for="security-code" class="text-base pb-2 font-ave-semibold text-black">Security Code*</label>
                    <input type="number" name="security-code" id="security-code"
                        class="input w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                        placeholder="e.g. 067">
                    <!-- <p class="font-ave-medium text-xs md:text-sm mt-2 error-alert">
                        Please enter a valid email address.</p> -->
                </div>
            </div>
            <div class="col-span-12">
                <div>
                    <label for="card-holder-name" class="text-base pb-2 font-ave-semibold text-black">Card Holder Name*</label>
                    <input type="number" name="card-holder-name" id="card-holder-name"
                        class="input w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                        placeholder="e.g. 067">
                    <!-- <p class="font-ave-medium text-xs md:text-sm mt-2 error-alert">
                        Please enter a valid email address.</p> -->
                </div>
            </div>
            <div class="col-span-12">
                <div>
                    <label for="card-holder-name" class="text-base pb-2 font-ave-semibold text-black">ZIP / Postal Code*</label>
                    <input type="number" name="card-holder-name" id="card-holder-name"
                        class="input w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                        placeholder="e.g. 067">
                    <!-- <p class="font-ave-medium text-xs md:text-sm mt-2 error-alert">
                        Please enter a valid email address.</p> -->
                </div>
            </div>
            <div class="col-span-12">
                <div class="flex flex-col sm:flex-row justify-between gap-4 mt-12">
                    <button type="submit"
                        class="flex items-center justify-center button button--lg text-sm sm:text-base sm:w-64 bg-primary rounded-xs h-12 focus:outline-none font-ave-semibold text-white px-8 cursor-pointer">
                            < BACK
                    </button>
                    <button type="submit"  routerLink="../complete-purchase"
                        class="flex items-center justify-center button button--lg text-sm sm:text-base sm:w-64 bg-pale-blue rounded-xs h-12 focus:outline-none font-ave-semibold text-white px-8 cursor-pointer">
                        SAVE PAYMENT
                    </button>
                </div>
               
            </div>
        </div>
    </div>
</div>
