/* eslint-disable valid-jsdoc */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { SCHEDULE_REMINDER } from 'src/constants/application.const';
import { Observable } from 'rxjs';

/**
 * Component
 */
@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
})

/**
 * Component
 */
export class SchedulerComponent implements OnInit, OnChanges {
  @Input() strictListing: number;
  @Input() requestID: string;
  @Input() advisorID: string;
  public preDefinedLoader: boolean;
  public submitLoader: boolean;
  public userType: string;
  public scheduleReminder = SCHEDULE_REMINDER;
  public selectedSchedule: string = '';
  @Output() selectedScheduling = new EventEmitter();


  /**
   * @constructor
   */
  constructor(
    private personalDetailsService: PersonalDetailsService,
    private modalServce: CommonModelService,
    private router: Router,
    private commonHelper: CommonHelper,
    private changeDetection: ChangeDetectorRef,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
  ) {
  }

  /**
   * called initailly
   */
  ngOnInit(): void {
    this.userType = this.localStorageService.getDataByKey('role');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['advisorID'] && this.advisorID) {
      this.getScheduleInfo();
    }
  }

  /**
   * reminder set
   * @param {any} data
   */
  public onSelectSchedule(data) {
    this.selectedSchedule = data.target.value;
    this.selectedScheduling.emit(this.selectedSchedule);
  }

  /**
   * reminder set
   * @param {any} data
   */
  public onCheckedSchedule(data) {
    return this.selectedSchedule === data;
  }

  /**
   * Gets the schedule of the advisor {@link advisorID}.
   * @private
   */
  private getScheduleInfo(): void {
    this.preDefinedLoader = true;
    this.personalDetailsService.getAdvisorInfo(this.advisorID, this.userType !== 'Consumer' ? this.requestID : undefined).subscribe({
      next: (r) => {
        const schedule = r[0]['schedule_remainder_type'];
        if (schedule) {
          this.selectedSchedule = schedule;
          this.selectedScheduling.emit(schedule);
        } else {
          this.selectedSchedule = 'quarterly';
        }
      },
      error: (e) => this.commonHelper.httpResponseHandler(e?.error),
      complete: () => this.preDefinedLoader = false,
    });
  }

  /**
   * Get personal details
   */
  public getPersonalDetails(): void {
    this.preDefinedLoader = true;
    // get people list
    if (this.userType === 'Consumer') {
      this.personalDetailsService.getPersonalDetails().subscribe((response: APIResponseModel) => {
        this.preDefinedLoader = false;
        const schedule = response?.data?.user?.schedule_remainder_type;
        if (schedule) {
          this.selectedSchedule = schedule;
          this.selectedScheduling.emit(schedule);
        } else {
          this.selectedSchedule = 'quarterly';
        }
      }, () => {
        this.preDefinedLoader = false;
      });
    } else {
      this.personalDetailsService.getPersonalDetails(this.requestID).subscribe((response: APIResponseModel) => {
        this.preDefinedLoader = false;
        const schedule = response?.data?.user?.schedule_remainder_type;
        if (schedule) {
          this.selectedSchedule = schedule;
        } else {
          this.selectedSchedule = 'quarterly';
        }
      },
        (exception: any) => {
          this.preDefinedLoader = false;
          this.commonHelper.httpResponseHandler(exception?.error);
        });
    }
  }

  /**
   * Save Advisor Schedule Reminder Selection
   * @private
   */
  public saveSchedule(): Observable<APIResponseModel> {
    const payload = {
      requestId: this.requestID,
      schedule_remainder_type: this.selectedSchedule,
      advisor_id: this.advisorID,
      roletype: this.userType,
    };
    return new Observable((observer) => {
      this.personalDetailsService.storePersonalDetails(payload, 7).subscribe({
        next: (r) => observer.next(r),
        error: (e) => {
          this.commonHelper.httpResponseHandler(e.error);
          observer.error(e);
        },
        complete: () => observer.complete(),
      });
      return {
        unsubscribe() {
        },
      };
    });
  }
}
