import { Component, Input } from '@angular/core';
import { CommonHelper } from '../../../../../helper/common.helper';

@Component({
  selector: 'app-view-advisor-info',
  templateUrl: './view-advisor-info.component.html',
})

/**
 * View Advisor Info
 */
export class ViewAdvisorInfoComponent {
  @Input() advisorInfo;

  /**
   *
   */
  constructor(public commonHelper: CommonHelper) {
  }

  /**
   *
   */
  get agentTerm() {
    return this.commonHelper.LocalStorageAgentTerm();
  }
}
