import { AfterViewInit, Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { ConnectionService } from 'ng-connection-service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SparkoutChatWidgetService } from 'sparkout-chat-web-component';
import { LOCAL_STORAGE_UPDATE_STATUS, PAYMENT_STATUS, TRANSFER_AMOUNT_STATUS, USER_TYPES } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { CheckoutService } from './components/checkout/checkout.service';
import { CommonHelper } from './helper/common.helper';
import { SlugInterceptorService } from './helper/slug-interceptor.service';
import { ProSocketService } from './professional-dashboard/services/pro-socket.service';
import { CommonModelService } from './services/common-model.service';
import { CommonService } from './services/common.service';
import { LocalStorageService } from './services/local-storage.service';
import { SocketService } from './services/socket.service';
import { UserService } from './services/user.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
/**
 * App component
 */
export class AppComponent implements OnInit, AfterViewInit {
  public AUTO_LOGOUT_DURATION = environment.AUTO_LOGOUT_DURATION;
  public connectionStatus: boolean;
  public userType: string;
  // Payment Alert modal dialog
  public proceedToPay = `Proceed to Payment`;
  public freeTrailDays: Number;
  public isFirstMonth: boolean;
  public subscriptionEndDate: string;
  public remainingSubscriptionDays: Number;
  public idleWarningTime: number;
  public paymentStatus = PAYMENT_STATUS;
  public transferAmountStatus = TRANSFER_AMOUNT_STATUS;
  public subscription: Subscription;
  public isTrialPeriodForConsumerFromSlugUser: boolean;
  previousUrl: string = null;
  currentUrl: string = null;

  @HostListener('window:afterunload', ['$event']) // event active when do hard refresh
  clearLocalStorage() {
    this.refreshLocalStorage();
  }

  constructor(
    private commonService: CommonService,
    private slugInterceptorService: SlugInterceptorService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private modalService: CommonModelService,
    private connectionService: ConnectionService,
    private renderer: Renderer2,
    private socketService: SocketService,
    private proSocketService: ProSocketService,
    private checkoutService: CheckoutService,
    private commonHelper: CommonHelper,
    private sparkChatService: SparkoutChatWidgetService,
    private idle: Idle,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.sparkChatService.setBaseURI(environment.OPENAI_URL);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // store previous url and Return the user to the step they were completing before payment instead of the main dashboard
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.checkoutService.setRedirectUrl(this.previousUrl);
      
      // Get the root ActivatedRoute
      let route = this.activatedRoute;
      while (route.firstChild) {
        route = route.firstChild;
      }
      // Access the data property of the ActivatedRoute snapshot
      const snapshot = route.snapshot.data;
      this.commonHelper.updateAgentTermLocalStorage();
    });
    this.commonHelper.connectSocket();
    this.saveRouteValue();
  }

  /**
   * called initially
   */
  public ngOnInit(): void {
    this.userType = this.localStorageService.getDataByKey('role');
    this.idle.onTimeoutWarning.subscribe(countdown => this.idleWarningTime = countdown);

    // listen for connection changes browser
    this.connectionStatus = true;
    this.connectionService.monitor().subscribe((isConnected: boolean) => {
      this.connectionStatus = isConnected;
      if (isConnected) {
        this.modalService.close('network_connection');
        window.location.reload();
      } else {
        this.modalService.open('network_connection');
      }
    });
    this.commonHelper.updateAgentTermLocalStorage();
    this.adminSettingsListener();
    this.userDeathVerificationListener();
    this.paymentStatusListener();
    this.slugDetailListener();

    // Payment Alert modal dialog
    this.userService.freeTrailDaysActiveObserve.subscribe((data) => {
      if (data?.['freeTrailDays']) {
        this.freeTrailDays = data?.['freeTrailDays'];
        this.isFirstMonth = data?.['isFirstMonth'];
        this.subscriptionEndDate = data?.['subscriptionEndDate'];
        this.remainingSubscriptionDays = data?.['remainingSubscriptionDays'];
        this.isTrialPeriodForConsumerFromSlugUser = data?.['isTrialPeriodForConsumerFromSlugUser'];
      }
    });
    window.addEventListener('storage', (event) => {
      if (event.key === 'logout-event') {
        void this.slugInterceptorService.navigate(['/login']);
      }
    });
  }

  /**
   * after view initialized
   */
  public ngAfterViewInit(): void {
    const load = this.renderer.selectRootElement('#spin');
    this.renderer.addClass(load, 'hidden');

    // Password-reset routing
    if (this.localStorageService.getUserData()?.user?.reset_password == 1) {
      void this.slugInterceptorService.navigate(['/account-settings', 2]);
    }
  }
  /**
   * Get whether is first refund
   */
  public get isFirstRefund(): boolean {
    return this.localStorageService.getDataByKey('subscription_cancellation_count') == 1;
  }

  /**
   * proceed to payment section
   */
  public processPayment(): void {
    this.checkoutService.getPaymentOptions().subscribe({
      next: paymentOptions => {
        paymentOptions.find(item => item.id === 'subscription_monthly').selected = true;
        const ids: string[] = [];
        paymentOptions.forEach(e => e.selected ? ids.push(e.id) : null);
        this.checkoutService.setCart(ids);
      },
      complete: () => void this.slugInterceptorService.navigate(['/checkout'])
    });
    this.modalService.close('payment-alert-freeTrial-completed');
  }

  /**
   * Closes modal
   */
  public closeModal(): void {
    this.modalService.close('payment-alert-freeTrial-completed');
  }

  /**
   * Update subscription amount
   * @param response
   */
  private updateSubscriptionAmount(response: any): void {
    this.localStorageService.storeData('is_payment_complete', response?.['is_payment_complete']);
    if (response?.['is_payment_complete'] == this.paymentStatus.paid) {
      this.modalService.close('payment-process');
      // void this.slugInterceptorService.navigate(['/dashboard']);
      void this.checkoutService.postCheckoutRedirect();
      setTimeout(() => {
        this.modalService.open('payment-success');
      }, 200);
    } else if (response?.['is_payment_complete'] == this.paymentStatus.failed) {
      this.commonService.paymentErrorListener(response?.['message']);
      // void this.slugInterceptorService.navigate(['/subscribe']);
      setTimeout(() => {
        this.modalService.open('payment-failure');
      }, 200);
    }
    // On successful payment, remove all items from the checkout page's cart.
    this.checkoutService.setCart([]);
    this.commonService.paymentUpdate(true);
    this.localStorageService.updateUserData(USER_TYPES.user, {
      key: 'request',
      updateValue: response.is_payment_complete,
      updateKey: 'is_payment_complete',
      type: LOCAL_STORAGE_UPDATE_STATUS.O,
    });
  }

  /**
   * Update transfer amount
   * @param response
   */
  private updateTransferAmount(response: any): void {
    const isConsumer: boolean = this.localStorageService.getDataByKey('role') === 'Consumer';
    isConsumer && this.localStorageService.storeData('is_transfer_amount_paid', response?.['is_tansfer_amount_paid']);
    this.modalService.close('payment-process');
    // this.navigationToDashboard();
    void this.checkoutService.postCheckoutRedirect();
    this.commonService.paymentErrorListener(response?.['message']);
    switch (response?.['is_tansfer_amount_paid']) {
      case this.transferAmountStatus.paid:
          this.commonService.paymentSuccessMessageListener('Your transfer payment is successfully completed');
        setTimeout(() => {
          this.modalService.open('payment-success');
        }, 200);
        break;
      case this.transferAmountStatus.failed:
        this.modalService.open('payment-failure');
        break;
    }
    this.commonService.paymentUpdate(true);
    // On successful payment, remove all items from the checkout page's cart.
    this.checkoutService.setCart([]);
    isConsumer && this.localStorageService.updateUserData(USER_TYPES.user, {
      key: 'request',
      updateValue: response.is_tansfer_amount_paid,
      updateKey: 'is_transfer_amount_paid',
      type: LOCAL_STORAGE_UPDATE_STATUS.O,
    });
  }

  // /**
  //  * Closes modal
  //  */
  //   public closePaymentPendingModal(): void {
  //     this.modalService.close('payment-pending-popup');
  //   }

  /**
   * open contact us page
   */
  public openContactPage() {
    void this.slugInterceptorService.navigate(['contact-us']);
  }
  /**
   * redirect to opened DLB
   */
  private redirectToDLB() {
    if(this.localStorageService.getDataByKey('user_request_id')){
      void this.slugInterceptorService.navigate([`dlb-dashboard/${this.commonHelper.encodeDecodeRequestId(this.localStorageService.getDataByKey('user_request_id'), true)}`]);
    } else {
    this.navigationToDashboard();
    }
  }

  /**
   * Navigates to the appropriate dashboard.
   */
  public navigationToDashboard(): void {
    const isConsumer: boolean = this.localStorageService.getDataByKey('role') === 'Consumer'
    if (isConsumer) {
      const paymentInitiatedSource = this.localStorageService.getDataByKey('paymentInitiatedSource');
      if (paymentInitiatedSource) {
        void this.slugInterceptorService.navigate([paymentInitiatedSource])
      } else {
        void this.slugInterceptorService.navigate(['/dashboard'])
      }
    }
    else {
      void this.slugInterceptorService.navigate(['executor-custodian/dashboard']);
    }
  }

  /**
   * Refresh local storage
   */
  private refreshLocalStorage() {
    this.localStorageService.deleteDataByKey('agent_term'); // Clear agent term and to set new value
    this.localStorageService.clearRecaptchaStatus(); // Clear google captcha when the page is about to unload
  }

  public saveRouteValue() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const lastSegmentUrl = this.router.url.split('/').pop();
        if (lastSegmentUrl === "page-not-found") return;
        let currentUrl = this.router.url.split('/').slice(1);
        this.localStorageService.storeData("url", currentUrl[0]);
      }
    });
  }
  /**
   * listen consumer adding and removing from professional, will update in user and slug details
   */
  private slugDetailListener() {
    this.socketService.clientMigrateUnderCompanyListener().subscribe((response) => {
      const consumerId: string = this.localStorageService.getUserData()?.user?.['id'];
      if(!response?.data) return; 
      const userDetails = response.data;
      const slugDetails = userDetails.professional;
      delete userDetails?.professional;
      //The current login user has been added under a specific professional category.
      if (response.data?.['id'] === consumerId) {
        this.localStorageService.updateUserData(USER_TYPES.user, {
          key: 'user',
          updateValue: {...userDetails},
          type: LOCAL_STORAGE_UPDATE_STATUS.S,
        });
        this.localStorageService.updateUserData(USER_TYPES.user, {
          key: 'slug_details',
          updateValue: {...slugDetails},
          type: LOCAL_STORAGE_UPDATE_STATUS.S,
        });
        this.commonHelper.updateProDetailsInLocalStorage(slugDetails, userDetails);
      }
      //The current login user has been deleted under a specific professional category.
      if(response['user_id'] === consumerId && this.commonHelper.isNullorUndefined(response['co_brand_slug_id'])) {
        this.commonHelper.removeProDetailsFromLocalStorage();
      }
    })
  }
  /**
   * Admins settings listener
   */
  private adminSettingsListener() {
    this.socketService.receiveAdminSettings().subscribe((response: any) => {
      if (Object.keys(response)?.length > 0) {
        this.localStorageService.storeData('form_access', +response.data?.consumer_form_access_type);
        this.localStorageService.storeData('admin_settings', response.data);
      }
    });
    this.proSocketService.receiveAdminSettings().subscribe((response: any) => {
      if (Object.keys(response)?.length > 0) {
        this.localStorageService.storeData('admin_settings', response.data);
      }
    });
  }

/**
 * Users admin death verify listener
 */
private userDeathVerificationListener() {
  //Logout on Admin Death Verify
  const isConsumer: boolean = this.localStorageService.getDataByKey('role') === 'Consumer';
  const user_request_id = this.localStorageService.getUserData(USER_TYPES.user)?.request?.id
  this.socketService.listenAdminDeathVerifyStatus().subscribe((response:any)=>{
    if (isConsumer && user_request_id === response?.['request_id'] && response.admin_death_verify == 3) {
      this.commonHelper.logoutCommon(USER_TYPES.user);
    }
  })
}

/**
 * listener for payment status
 */
private paymentStatusListener() {
  this.socketService.listenPaymentStatus().subscribe((response: any) => {
    const payerId: string = response?.['payer_id'];

    const isConsumer: boolean = this.localStorageService.getDataByKey('role') === 'Consumer';
    const consumerId: string = this.localStorageService.getUserData()?.user?.['id'];

    const isCustodian: boolean = this.localStorageService.getDataByKey('role') === 'Custodian';
    const custodianId: string = this.localStorageService.getUserData(USER_TYPES.exe)?.user?.['id'];

    if (isConsumer && payerId == consumerId) {
      response?.['subscription_amount_payment'] == 1 && this.updateSubscriptionAmount(response);
      response?.['transfer_amount_payment'] == 1 && this.updateTransferAmount(response);
    } else if (isCustodian && payerId == custodianId) {
      response?.['transfer_amount_payment'] && this.updateTransferAmount(response);
    }
  });
}

}
