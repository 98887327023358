import { Component } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';

@Component({
  selector: 'app-email-not-verified',
  templateUrl: './email-not-verified.component.html'
})
/**
 * Email not verified
 */
export class EmailNotVerifiedComponent   {
/**
 * constructor
 */
  constructor(private modal:CommonModelService) { }
  /**
 * loaded initially
 */

}
