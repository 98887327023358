import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * requirePasswordToBeCheckedValidator
 * @return {ValidatorFn}
 */
export function requirePasswordToBeCheckedValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const hasUpperCase = /[A-Z]+/.test(value);
    const hasLowerCase = /[a-z]+/.test(value);
    const hasNumeric = /[0-9]+/.test(value);
    const hasSpecialCharacters = /[!@#$%^&*]+/.test(value);
    const hasMinLength = value.length >= 8;
    const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecialCharacters && hasMinLength;
    return !passwordValid ? { passwordStrength: { hasUpperCase: hasUpperCase, hasLowerCase: hasLowerCase, hasNumeric: hasNumeric, hasSpecialCharacters: hasSpecialCharacters, hasMinLength: hasMinLength } } : null;
  };
}
