import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExecutorCustodianService } from 'src/app/executor-custodian/services/executor-custodian.service';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SlugService } from 'src/app/services/slug.service';
import { UserService } from 'src/app/services/user.service';
import { BubbleRadioOption } from 'src/app/sharedComponent/bubble-radio/bubble-radio.component';
import { EMAIL_PATTERN, LoginType } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-forgot-password-request',
  templateUrl: './forgot-password-request.component.html',
})
/**
 * Forgot password request component
 */
export class ForgotPasswordRequestComponent implements OnInit {
  public resetEmailSent: boolean = false;
  public resetRequestForm: FormGroup;
  public submitLoader: boolean = false;
  public resendOtpTimer: number = 0;
  public orgLogo: string = '';
  public orgLogoPath: string = '';
  public resendInterval: NodeJS.Timeout;
  public shortUrl: any;
  public readonly loginOptions: BubbleRadioOption[] = [
    { text: 'Digital Vault', id: LoginType.DIGITAL_VAULT },
    { text: 'Digital Vault Custodian/Executor', id: LoginType.CUSTODIAN },
  ];


  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastService: ToastrService,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    private localStorageService: LocalStorageService,
    private slugService: SlugService,
    private route: ActivatedRoute,
    private executorCustodianService: ExecutorCustodianService
  ) {
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.shortUrl = this.route?.snapshot?.queryParams['role'];
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      }
    });
    this.resetRequestForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
    });
  }

  /**
   * start resend timer
   */
  public startResendTimer(): void {
    if (this.resendInterval) {
      clearInterval(this.resendInterval);
      this.resendInterval = undefined;
    }
    // resend otp timer
    this.resendOtpTimer = 30;
    this.resendInterval = setInterval(() => {
      this.resendOtpTimer -= 1;
      if (this.resendOtpTimer <= 0) {
        clearInterval(this.resendInterval);
      }
    }, 1000);
  }


  /**
   * Reset request onsubmit function
   */
  public resetRequest(): void {
    if (!this.resendOtpTimer && !this.submitLoader) {
      this.submitLoader = true;
      this.resetEmailSent = false;
      const observer = {
        next: (response: APIResponseModel) => {
          if (response.status) {
            this.startResendTimer();
            this.resetEmailSent = true;
            this.submitLoader = false;
            if (response.status) {
              this.toastService.success(response?.message);
            }
          }
        },
        error: (exception: any) => {
          this.submitLoader = false;
          this.commonHelper.httpResponseHandler(exception?.error);
        },
      };
  
      if (this.shortUrl == LoginType.DIGITAL_VAULT) {
        this.userService.requestResetPassword(this.resetRequestForm.value).subscribe(observer);
      } else if (this.shortUrl == LoginType.CUSTODIAN) {
        this.executorCustodianService.passwordResetRequest(this.resetRequestForm.value).subscribe(observer);
      }
    }
    
  }


  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.resetRequestForm.controls;
  }

  /**
   * Get forget password role name for header
   */
  get headerRole() {
    return this.loginOptions.find(i => String(i.id) === this.shortUrl)
  }


  /**
   * on click logo
   */
  public onClickLogo() {
    void this.slugInterceptorService.navigate(['/']);
  }
}
