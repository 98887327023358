<div class="scroll-hidden box-border body-content pb-12 mb-8">
  <div class="mx-8 ">
    <div class="pt-12">
      <div class="flex flex-wrap ">
        <!-- START: Heading & Sub-Heading -->
        <div class="w-full md:w-3/5 px-1">
          <div>
            <!-- Heading -->
            <h4 class="text-semibold text-blue-900 text-md md:text-lg  lg:text-xl xl:text-2xl">Your Professionals
            </h4>
            <!-- Sub-Heading -->
            <p class="text-grey text-regular text-sm md:text-md lg:text-md mb-3">Professional Details
            </p>
          </div>
        </div>
        <!-- START: Heading & Sub-Heading -->

        <!-- START: Add/Edit Professional Button -->
        <div class="w-full md:w-2/5 px-0 ">
          <div class="flex items-center justify-end ">
            <button (click)="changeRoute('professional-management/add')"
              class="button button--lg bg-darkblue px-4 rounded-xs h-10 focus:outline-none text-regular text-white text-sm flex flex-row justify-center items-center">
              <img src="assets/images/dashboard/plus-white.svg" class="mr-3" alt=""> Add Professional
            </button>
          </div>
        </div>
        <!-- END: Add/Edit Professional Button -->

      </div>
    </div>
    <!-- START: Professionals Created Chart -->
    <div class="pt-4">
      <div>
        <div class="flex flex-wrap -mx-2 ">
          <div class="w-full md:w-full px-2 mb-">
            <div class="border-card bg-white rounded-md p-6 ">
              <h4 class="text-semibold text-grey text-md md:text-lg pb-2 lg:text-xl xl:text-2xl">Professionals
                Created
              </h4>
              <app-shimmer-loading [loading]="dataLoading" [width]="'100%'" [height]="'4rem'"></app-shimmer-loading>
              <canvas *ngIf="showData && !dataLoading" baseChart [datasets]="barChartData" [labels]="barChartLabels"
                [options]="barChartOptions" [legend]="true" [chartType]="'bar'" height="100">
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Professionals Created Chart -->

    <!-- START: Professionals Listing Table -->
     <app-professionals-listing-table></app-professionals-listing-table>
    <!-- END: Advisors Listing Table -->
  </div>
</div>