import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { ProfessionalPermissionService } from 'src/app/professional-dashboard/services/professional-permission.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { SECTIONS, SectionStatus, USER_TYPES } from 'src/constants/application.const';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';

@Component({
  selector: 'app-manage-business-details',
  templateUrl: './manage-business-details.component.html',
})
/**
 * Manage business
 */
export class ManageBusinessDetailsComponent implements OnInit {
  public editData: any;
  public toggleModalEditVar: boolean;
  public preDefinedLoader: boolean;
  public businessArray: Array<any>;
  public currentUser: any;
  public sectionSaveExitOptions: any;
  public userType: string;
  public clientRequestId: string;
  public permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  public isNewAsset: boolean;
  public vaultVideo: string;
  public isProfessional:boolean;
  public clientHasPaidSubscription:boolean;

  /**
   * True signifies hiding the 'save' buttons and showing the 'progress' buttons
   */
  public buttonProgress: boolean = false;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public userRequestID: string;


  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private personalDetailsService: PersonalDetailsService,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private commonHelper: CommonHelper,
    private route: ActivatedRoute,
    private router: Router,
    private proPermissionService: ProfessionalPermissionService,
    private toastrService: ToastrService,
    private slugInterceptorService: SlugInterceptorService,
    private subscriptionService: ConsumerSubscriptionService,
  ) { }

  /**
     * called initially
     */
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.sectionSaveExitOptions = {
      section: 8,
      type: 1,
    };
    this.businessArray = [];
    this.clientRequestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.isProfessional = this.commonHelper.isProfessional
    this.currentUser = this.localStorageService.getUserData();
    this.userRequestID = this.commonHelper.getRequestId(this.route);
    this.getPersonalDetails();
    this.getAccessControl();
    if (this.clientRequestId) {
      this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.business, SectionStatus.INCOMPLETE).subscribe();
    }
  }

  /**
   * Establish ACL Permissions. Consumer is assigned full access.
   */
  public getAccessControl(): void {
    if (this.userType !== 'Consumer') {
      let getACLService;
      const requestId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('request_user_id') : this.userRequestID;
      const userId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('user_id') : this.localStorageService.getDataByKey('agent_id') || this.localStorageService.getUserData(USER_TYPES.pro)?.user?.id;
      getACLService = this.proPermissionService.getAccessControl(userId, requestId, ['Business']);
      getACLService.subscribe(
        (permissions) => this.permissions = permissions?.['Business'],
        (exception: any) => (this.commonHelper.httpResponseHandler(exception?.error)));
    } else {
      this.permissions = {
        view: true,
        add: true,
        edit: true,
        delete: true,
      };
    }
  }

  /**
   * Get personal details
   */
  public async getPersonalDetails() {
    this.preDefinedLoader = true;
    // get people list
    this.personalDetailsService.getPersonalDetails(this.clientRequestId).subscribe({
      next: async (response: APIResponseModel) => {
        if (response.status) {
          this.businessArray = response.data.user_business;
          this.clientHasPaidSubscription =  await this.commonHelper.checkClientSubscription(response);
        }
      },
      error: (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
      complete: () => this.preDefinedLoader = false,
    });
  }

  /**
   * toggle add beneficiary modal
   *
   * @param {any} editValue
   */
  public toggleModal(editValue?: any): void {
    this.editData = {};
    if (editValue) {
      // Permission to View?
      if (!this.permissions?.['view']) {
        this.toastrService.info('You do not have permission to view this asset');
        return;
      }
      this.isNewAsset = false;
      this.editData = { ...editValue };
    } else {
      this.isNewAsset = true;
      // clear selected file while adding new one
      this.commonHelper.clearfileHanlderObservableEvents();
    }
    this.modalService.open('add-business-modal');
    this.toggleModalEditVar = !this.toggleModalEditVar;
  }

  /**
   * Modal event listner
   *
   * @param {any} data
   */
  public modalEventListener(data: any): void {
    this.preDefinedLoader = true;
    this.commonHelper.diffCheckerAndUpdate(this.businessArray, 'id', data?.id, data);
    this.preDefinedLoader = false;
    this.getPersonalDetails();
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }

  /**
   * Button click handler
   * @param command
   */
  public click(command: string) {
    const isPro = this.userType !== 'Consumer';
    let url: any[];
    switch (command) {
      case 'Back':
        const back_url = ['vehicle'];
        if (isPro) {
          back_url.push(this.clientRequestId);
        }
        void this.slugInterceptorService.navigate(back_url);
        break;
      case 'ReturnToDashboard':
        const return_url = isPro ? ['overview', 'property-overview'] : ['dashboard'];
        void this.slugInterceptorService.navigate(return_url);
        break;
      case 'MarkComplete':
        this.buttonProgress = true;
        this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.business, SectionStatus.COMPLETE).subscribe();
        break;
      case 'SaveForLater':
        this.buttonProgress = true;
        break;
      case 'NextSection':
        url = ['valuable-details'];
        if (isPro) {
          url.push(this.clientRequestId);
        }
        void this.slugInterceptorService.navigate(url);
        break;
    }
  }
  /**
 * open modal
 * @param{string} item
 */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');
  }


  /**
   * Determines whether the button should be disabled
   * @returns A boolean
   */
  public get disableButton(): boolean {
    return this.isProfessional && !this.clientHasPaidSubscription
  }
}
