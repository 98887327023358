import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { ICountryCode } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { ConsumerLandingPageService } from 'src/app/services/consumer-landing-page.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { EMAIL_PATTERN, NAME_PATTERN, PHONE_PATTERN } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../helper/validation.helper';



@Component({
  selector: 'app-consumer-contact-us',
  templateUrl: './consumer-contact-us.component.html',
})
/**
 * consumer contact us
 */
export class ConsumerContactUsComponent implements OnInit {
  public mobile: boolean;
  public innerWidth: number;
  public contactUsForm: FormGroup;
  public countryCode: ICountryCode;
  public submitLoader: boolean;
  public customerSupportMail: string;
  public customerSupportPhone: number;
  public vaultVideo: string;
  // recaptcha validation
  public readonly googleCaptchaKey = environment.GOOGLE_CAPTCHA_KEY;
  public isRecaptchaValidationEnable: boolean = false;
  public isCaptchaValid: boolean = true;

  @ViewChild('contactForm', { static: true }) contactForm: ElementRef;
  @ViewChild('captchaRef') captchaRef: any;


  /**
   * constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private landingPageService: ConsumerLandingPageService,
    private toastr: ToastrService,
    private commonHelper: CommonHelper,
    private commonService: CommonService,
    private validation: ValidationHelper,
    private modalService: CommonModelService,
    private localStorage: LocalStorageService
  ) {

  }


  /**
   * loaded initiallycontactForm
   */
  ngOnInit(): void {
    this.getContactDetails();
    this.onResize();
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    this.initForm();
    this.setRecaptchaValidation();
  }


  private initForm() {
    this.contactUsForm = this.formBuilder.group({
      first_name: ['', { validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim] }],
      middle_name: ['', { validators: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim] }],
      last_name: ['', { validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim] }],
      email: ['', { validators: [Validators.required, Validators.pattern(EMAIL_PATTERN)] }],
      phone: ['', { validators: [Validators.pattern(PHONE_PATTERN)] }],
      description: ['', { validators: [Validators.required, Validators.minLength(3), Validators.maxLength(500)] }],
      'g-recaptcha-response': ['', [this.isRecaptchaValidationEnable ? Validators.required : Validators.nullValidator]]
    });

    this.validation.setValidationErrors({
      phone: { pattern: 'Please enter a valid phone number' },
      email: { pattern: 'Please enter a valid email address.' },
    });
  }


  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.contactUsForm);
  }


  /**
   * form object getter for validation and showing errors in html
   */
  public get formGet() {
    return this.contactUsForm.controls;
  }


  /**
   * window resize
   * @param {any} event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.innerWidth = window.innerWidth;
    this.mobile = this.innerWidth <= 768;
  }


  /**
   * on form submit
   */
  public onSubmit() {
    if (!this.contactUsForm.valid) {
      console.error('Constact Us Form invalid in `onSubmit`');
      this.isCaptchaValid = !!this.contactUsForm.value['g-recaptcha-response'];
      return;
    }
    this.submitLoader = true;
    const payload = {
      ...this.contactUsForm.value,
      country_code: this.countryCode.dialCode,
      flag_code: this.countryCode.code,
      user_type: 'Consumer',
    };

    this.landingPageService.sendContactUsData(payload).subscribe((response: APIResponseModel) => {
      this.submitLoader = false;
      this.contactUsForm.reset();
      this.captchaReset();
      this.countryCode = environment.DEFAULT_COUNTRY_CODE;
      this.toastr.success(response.message);
    }, (exception: any) => {
      this.submitLoader = false;
      this.contactUsForm.reset();
      this.captchaReset();
      this.countryCode = environment.DEFAULT_COUNTRY_CODE;
      this.commonHelper.httpResponseHandler(exception?.error);
    },
    );
  }


  /**
   * Detect change on phone number component
   *
   * @param event
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }


  /**
   * get contact details
   */
  public getContactDetails() {
    this.commonService.getContactUsDetails().subscribe((response) => {
      this.customerSupportMail = response.data?.support_email;
      this.customerSupportPhone = response.data?.support_number;
    });
  }
  /**
 * catch error captcha google
 *
 * @param {string} errorDetails
 */
  public onErrorCaptcha(errorDetails?: RecaptchaErrorParameters): void {
    console.log(errorDetails);
  }

  /**
   * reset captcha
   */
  public captchaReset(): void {
    this.captchaRef.reset();
    this.contactUsForm.get('g-recaptcha-response')?.reset();
  }
  /**
 * resolve captcha google
 *
 * @param {string} captchaResponse
 */
  public resolvedCaptcha(captchaResponse?: string): void {
    if (!captchaResponse) return;
    this.contactUsForm.patchValue({
      'g-recaptcha-response': captchaResponse,
    });
    this.isCaptchaValid = !!this.contactUsForm.value['g-recaptcha-response'];
  }
  /**
  * open modal
  * @param{string} item
  */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');
  }
  /**
  * Sets up the reCAPTCHA validation for the  form.
  * Fetches the reCAPTCHA configuration and sets the validation accordingly.
  */
  public setRecaptchaValidation(): void {
    this.commonHelper.getCaptchaConfig().subscribe({
      next: (response) =>{
        this.isRecaptchaValidationEnable = response?.data['is_enabled'];
        this.contactUsForm.get('g-recaptcha-response').setValidators(this.isRecaptchaValidationEnable ? Validators.required : Validators.nullValidator);
      }
    })
  }
}
