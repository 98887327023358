import { Component, OnInit } from '@angular/core';
import { CommonHelper } from 'src/app/helper/common.helper';

@Component({
  selector: 'app-digital-vault-licensing-agreement',
  templateUrl: './digital-vault-licensing-agreement.component.html',
})
/**
 * Digital Vault Licensing Agreement
 */
export class DigitalVaultLicensingAgreementComponent implements OnInit {
  public agentTerm: string;

  /**
  * @constructor
  */
  constructor(private commonHelper: CommonHelper) { }
  /**
  * called initially
  */
  ngOnInit(): void {
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
  }

  /**
   * get current year
   * @return{number}
   */
  public getCurrentYear() {
    return this.commonHelper.currentYear;
  }
}
