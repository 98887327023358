<app-common-modal id="verify-departed-modal" class="app-common-modal" [heightClass]="'h-full'">
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">
    <form [formGroup]="verifyDeathForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Verify {{personNameLocal}}'s Departed
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">Please enter details as per record for verification to unlock
            Digital Vault
          </p>
        </div>
        <div class="col-span-12 md:col-span-6 ">
          <label for="dod" class="text-label-grey text-sm text-regular">Date of Death ( {{domainDateFormat}} )</label>
          <input type="text" (click)="markAsTouchedIndividual('date_of_death')" #dp=bsDatepicker [dp]="dp" bsDatepicker
            [bsConfig]="bsConfig" id="dod"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 placeholder-grey"
            [ngClass]="formGet.date_of_death.errors && formGet.date_of_death.touched?'form-input-error':'form-input-focus'"
            placeholder="{{domainDateFormat}}" formControlName="date_of_death">
          <div *ngIf="formGet.date_of_death.errors && formGet.date_of_death.touched">
            <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.date_of_death.errors.required">
              DOD is required
            </p>
          </div>
        </div>
        <div class=" col-span-12 md:col-span-6 relative">
          <label for="certificates" class="text-label-grey text-sm text-regular">Certificates</label>
          <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('certificate')">
            <app-select-with-search [ifError]="formGet.certificate.errors && formGet.certificate.touched"
              [options]="certificateTypes" [placeholderValue]="'Select certificate type'"
              (optionChangeEmitter)="optionChangeEmitterCertificate($event)" id="certificates"
              (focusout)="markAsTouchedIndividual('certificate')" tabindex="0">
            </app-select-with-search>
          </div>
          <div *ngIf="formGet.certificate.errors && formGet.certificate.touched">
            <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.certificate.errors.required">
              Certificate Number is required
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <label for="certificate-no" class="text-label-grey text-sm text-regular">Certificate Number</label>
          <input type="text" id="certificate-no"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700"
            placeholder="e.g 19801562"
            [ngClass]="formGet.certificate_number.errors && formGet.certificate_number.touched?'form-input-error':'form-input-focus'"
            formControlName="certificate_number">
          <div *ngIf="formGet.certificate_number.errors && formGet.certificate_number.touched">
            <p class="text-regular font-medium text-xs mt-2 error-alert"
              *ngIf="formGet.certificate_number.errors.pattern">Please enter valid number
            </p>
            <p class="text-regular font-medium text-xs mt-2 error-alert"
              *ngIf="formGet.certificate_number.errors.required">Certificate Number is required
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 relative">
          <label for="identification-id" class=" text-label-grey text-sm text-regular ">Identification Type</label>
          <div class=" col-span-12 lg:col-span-2 relative " (click)="markAsTouchedIndividual('identification')">
            <app-select-with-search [options]="identificationTypes" [placeholderValue]="'Select Identification Type'"
              (optionChangeEmitter)="optionChangeEmitterIdentification($event)" id="identification-id"
              [ifError]="formGet.identification.errors && formGet.identification.touched"
              (focusout)="markAsTouchedIndividual('identification')" tabindex="0"></app-select-with-search>
          </div>
          <div *ngIf="formGet.identification.errors && formGet.identification.touched">
            <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.identification.errors.required">
              Identification Type is required
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 ">
          <label for="identification-no" class="text-label-grey text-sm text-regular">Identification Number
          </label>
          <input type="text" id="identification-no"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full font-sans text-regular rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 "
            placeholder="e.g 19801"
            [ngClass]="formGet.identification_number.errors && formGet.identification_number.touched?'form-input-error':'form-input-focus'"
            formControlName="identification_number">
          <div *ngIf="formGet.identification_number.errors && formGet.identification_number.touched">
            <p class="text-regular font-medium text-xs mt-2 error-alert"
              *ngIf="formGet.identification_number.errors.pattern">Please enter a valid ID Number ( Alpha numeric )
            </p>
            <p class="text-regular font-medium text-xs mt-2 error-alert"
              *ngIf="formGet.identification_number.errors.required">Identification Number is required
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 relative ">
          <label for="creditor-type" class="text-label-grey text-sm text-regular">Inform Creditor
          </label>
          <div class=" col-span-12 lg:col-span-2 relative mt-1 ">
            <app-select-component [options]="informCreditor" [placeholder]="'Select Option'" id="creditor-type"
              (optionChangeEmitter)="optionChangeEmitterCreditorType($event)"
              [selectedOptionDefault]="verifyDeathForm?.value?.['inform_creditor']"
              (toggleEmiter)="toggleEmiter($event)">
            </app-select-component>
          </div>
        </div>
        <div class="col-span-12 mt-6">
          <h4 class=" text-regular text-lg text-darkblue font-semibold tracking-wide ">
            Upload Death Certificate or Other Proof of Passing
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">
            <strong>Accepted File Format</strong> — pdf
          </p>
          <p class="text-grey text-regular pt-1 text-sm">
            <strong>Maximum File Size</strong> — 10 MB.
          </p>
        </div>
        <div class="col-span-12">
          <app-file-upload-handler [fileValidationOptions]="fileValidationOptions" [hideFileOperations]="true"
            (fileUploadChangeEmitter)="uplaodDeathCertificate($event)">
          </app-file-upload-handler>
        </div>

      </div>

      <div class=" flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 ">
        <app-button-loader [buttonText]="'Cancel'" [valid]="true" [loader]="false" [buttonType]="'button'"
          (click)="closeModel()" [outlineOnly]="true">
        </app-button-loader>

        <app-button-loader [buttonText]="'Check Departed'" [valid]="verifyDeathForm.valid" [loader]="submitLoader"
          [buttonType]="'submit'"></app-button-loader>
      </div>
    </form>
  </div>
</app-common-modal>
