<app-common-modal id="profile-view" class="app-common-modal">
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
    class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
    (click)="closeModal()">
    <div clas="sm:p-6">
      <h3 class="text-darkblue text-semibold text-left text-lg mb-4">{{viewModalHeading}}</h3>
    </div>
    <div class="bg-white max-w-200 xl:max-w-250 shadow overflow-hidden sm:rounded-lg">
      <div class="border-t border-gray-200">
        <!-- Profile Picture -->
        <img class="h-32 w-32 rounded-full border-4 object-cover border-white dark:border-gray-800 mx-auto my-4"
          [src]="viewModalData?.['profileImageSrc']" alt="">
        <dl>
          <!-- Name -->
          <div class="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-300 hover:border-card">
            <dt class="text-blue-600 text-semibold  text-sm lg:text-md">
              Name
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{viewModalData?.['name']}}
            </dd>
          </div>
          <!-- Email Address -->
          <div class="bg-gray-10 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-300">
            <dt class="text-blue-600 text-semibold  text-sm lg:text-md">
              Email Address
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{viewModalData?.['email']}}
            </dd>
          </div>
          <!-- Address -->
          <div class="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-300 hover:border-card">
            <dt class="text-blue-600 text-semibold  text-sm lg:text-md">
              Address
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{viewModalData?.['address']}}
            </dd>
          </div>
          <!-- Phone Number -->
          <div class="bg-gray-10 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-300">
            <dt class="text-blue-600 text-semibold  text-sm lg:text-md">
              Phone Number
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{viewModalData?.['phone']}}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</app-common-modal>