import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Socket, io } from 'socket.io-client';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { USER_TYPES } from 'src/constants/application.const';
import { BULK_IMPORT_COMPLETED, DECEDENT_STATUS, DELETE, MESSAGE, OFFLINE, ONLINE, READ, TYPING, UPDATE } from 'src/constants/socket-events.const';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

/**
 * Professional/organization Socket Service
 */
export class ProSocketService {
  public socket: Socket;
  public message$ = new BehaviorSubject({});
  public typing$ = new BehaviorSubject({});
  public offlineStatus$ = new BehaviorSubject({});
  public readMessageStatus$ = new BehaviorSubject({});
  public onlineStatus$ = new BehaviorSubject({});
  public removeMessageStatus$ = new BehaviorSubject({});
  public editMessageStatus$ = new BehaviorSubject({});
  public professionalAppointmentStatus$ = new BehaviorSubject({});
  public paymentStatus$ = new BehaviorSubject({});
  public fromIdType: string = '';
  public fromId: string = '';
  public role: string = '';
  public decedentCheckStatus$ = new BehaviorSubject({});
  public adminSettings$ = new BehaviorSubject({});
  public bulkImportCompleted$ = new BehaviorSubject(null);
  private socketListnersEstablished = false;


  /**
   * constructor
   */
  constructor(
    private localStorageService: LocalStorageService,
  ) {
    this.role = localStorageService.getDataByKey('role');
    this.fromIdType = (this.role === 'Sub Organization' || this.role === 'Organization') ? 'organization' : 'professional';
    this.fromId = (this.role === 'Sub Organization' || this.role === 'Organization') ? localStorageService.getDataByKey('organization')?.user.id : this.localStorageService.getUserData(USER_TYPES.pro)?.user?.id;
  }

  /**
   * connect to socket
   */
  public connectSocket() {
    if (this.fromId !== undefined) {
      this.socket = io(environment.SOCKET_URL + '?id=' + this.fromId + `&from_id_type=${this.fromIdType}`, { forceNew: true, reconnectionDelay: 2000, timeout: 100000 });
    }
    if (this.socket) {
      this.socket.connect();
      this.establishSocketEventListeners();
    }
  }

  /**
   * disconnect socket
   */
  public disconnectSocket() {
    if (this.socket) {
      this.socket.close();
    }
  }

  /**
   * Set use socket
   * @param use 
   */
  public setUseSocket(use: Boolean): void {
    if (use) {
      this.connectSocket();
    } else {
      this.disconnectSocket();
    }
  }

  /**
   * send message
   * @param{{}}data
   */
  public sendMesssage(data: {}) {
    if (Object.keys(data)?.length > 0) {
      this.socket.emit(MESSAGE, data);
    }
  }

  /**
   * get new message
   * @return {Observable}
   */
  public getNewMessage(): Observable<any> {
    return this.message$?.asObservable().pipe(tap(response =>{
      console.log(response);
    }))
  };

  /**
   * send typing event
   * @param{{}}data
   */
  public onTyping(data: {}) {
    this.socket.emit(TYPING, data);
  }

  /**
   * send online event
   * @param{{}}data
   */
  public setUserOnline(data: {}) {
    this.socket.emit(ONLINE, data);
  }

  /**
   * get typing event
   * @return {Observable}
   */
  public getTypingEvent(): Observable<any> {
    return this.typing$?.asObservable();
  };

  /**
   * get offline event
   * @return {Observable}
   */
  public getOfflineEvent(): Observable<any> {
    return this.offlineStatus$?.asObservable();
  };

  /**
   * send read message
   * @param{{}}data
   */
  public sendReadMessage(data: {}) {
    console.log(data);
    this.socket.emit(READ, data);
  }

  /**
   * listen read event
   * @return {Observable}
   */
  public listenReadMessage(): Observable<any> {
    return this.readMessageStatus$?.asObservable();
  };

  /**
   * get online users
   * @return {Observable}
   */
  public getOnlineUsers(): Observable<any> {
    return this.onlineStatus$?.asObservable();
  };

  /**
   * remove message
   *
   * @param {object} data
   */
  public removeMessage(data: {}) {
    this.socket.emit(DELETE, data);
  };

  /**
   * listen remove message
   * @return {Observable}
   */
  public listenRemoveMessage(): Observable<any> {
    return this.removeMessageStatus$?.asObservable();
  };

  /**
  * edit message
  *
  * @param {object} data
  */
  public editMessage(data: {}) {
    this.socket.emit(UPDATE, data);
  };

  /**
   * listen edit message
   * @return {Observable}
   */
  public listenEditMessage(): Observable<any> {
    return this.editMessageStatus$?.asObservable();
  };

  /**
   * listen appointment notifications
   * @return {Observable}
   */
  public listenAppointmentStatus(): Observable<any> {
    return this.professionalAppointmentStatus$?.asObservable();
  }

  /**
 * Listen Payment status
 * @returns{Observable} 
 */
  public listenProPaymentStatus(): Observable<any> {
    return this.paymentStatus$?.asObservable();
  }

  /**
   * listen decedent check notifications
   * @return {Observable}
   */
  public decedentCheckStatus(): Observable<any> {
    return this.decedentCheckStatus$?.asObservable();
  }

  /**
   * receive admin settings from the socket
   * @return{Observable}
   */
  public receiveAdminSettings(): Observable<any> {
    return this.adminSettings$?.asObservable();
  }

  public bulkImportStatusListener(): Observable<any> {
    return this.bulkImportCompleted$?.asObservable();
  }

  private establishSocketEventListeners() {
    if (!this.socketListnersEstablished) {
      const subjects: { [key: string]: BehaviorSubject<any>[] } = {
       'appointment_status_updated':[this.professionalAppointmentStatus$],
       'professional_payment_status':[this.paymentStatus$],
        [BULK_IMPORT_COMPLETED]:[this.bulkImportCompleted$],
        [DECEDENT_STATUS]:[this.decedentCheckStatus$],
        'admin_site_settings': [this.adminSettings$],
        [UPDATE]: [this.editMessageStatus$],
        [MESSAGE]: [this.message$],
        [DELETE]: [this.removeMessageStatus$],
        [READ]: [this.readMessageStatus$],
        [ONLINE]: [this.onlineStatus$],
        [TYPING]: [this.typing$],
        [OFFLINE]: [this.offlineStatus$],
      };
      Object.keys(subjects).forEach(subjectKey => {
        this.socket.on(subjectKey, message => {
          if (message ||Object.keys(message || {})?.length > 0) {
            subjects[subjectKey].forEach(subject => subject.next(message));
          }
        });
      });
    }
    this.socketListnersEstablished = true;
  }
}


