<div class="relative">
  <a class="absolute inset-y-0 right-2 pr-3 flex items-center"
     (click)="onToggle()">
    <fa-icon [icon]="faCalendar"></fa-icon>
  </a>
  <input
    bsDatepicker
    autocomplete="false"
    type="text" name="dob" id="{{inputID}}"
    class="h-10 sm:h-12 input py-3 px-4 text-sm w-full text-regular rounded-xs appearance-none border block placeholder-grey focus:outline-none focus-within:text-gray-700"
    #dp=bsDatepicker [dp]="dp"
    [attr.disabled]="disabled || undefined"
    [formControl]="$any(useFormControl)"
    [ngClass]="getClass"
    [bsConfig]="bsConfig"
    [placeholder]="placeholder ?? domainDateFormat"
    (onShown)="dpToggle || dp.hide()"
    (onHidden)="onHiddenListener()"
    (bsValueChange)="bsValueChange($event)"
    maxlength="10">
</div>
