<div class="absolute left-0 right-0 top-0 z-50" [class.bg-transparent]="!menuAlternate" id="navBarStarts">
  <div class="w-full mx-auto px-4 sm:px-6">
    <div class="flex justify-between items-center py-6 lg:justify-start md:space-x-10 h-24 custom-mobile-header">
      <div class="flex justify-start lg:w-5/12 items-center gap-2 md:gap-4 flex-row">
        <a class="cursor-pointer flex pr-2" (click)="menuChange('')">
          <span class="sr-only">Legacy Now</span>
          <img width="300" src="assets/images/consumer/logo-old.png" alt="legacy-now" class="object-contain w-40 sm:w-48">
        </a>
        <a *ngIf="orgLogoPath" class="border-l pl-8 cursor-pointer max-h-12 maxw128">
          <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
        </a>
        <!-- <div class="flex items-start gap-1 md:gap-2 flex-col" *ngIf="orgLogoPath">
          <div [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-secondary menu--inactive'">
            <p class=" whitespace-nowrap text-xs md:text-sm lg:text-regular"
            [ngClass]="!menuAlternate ? 'text-dark-grey': 'text-white'">Sponsored by:</p>
          </div>
          <a *ngIf="orgLogoPath" class="cursor-pointer max-h-12 maxw128">
            <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
          </a>
        </div> -->
      </div>
      <div class="md:-mr-2 -my-2 lg:hidden">
        <button type="button" (click)="responsiveMenuToggle = !responsiveMenuToggle"
          class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500"
          aria-controls="menuToggleItems" id="menuToggle" aria-expanded="false">
          <span class="sr-only">Open menu</span>
          <!-- Heroicon name: outline/menu -->
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>
      <nav class="hidden lg:flex lg:space-x-5 xl:space-x-8 menu" aria-labelledby="navbar">
        <a (click)="menuChange('home')" [class.menu--active]="this.menuActive==='home'"
          class="cursor-pointer text-semibold fs-menu lh-menu"
          [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-secondary menu--inactive'">
          Home
        </a>
        <a (click)="menuChange('pricing')" [class.menu--active]="this.menuActive==='pricing'"
          class="cursor-pointer text-semibold fs-menu lh-menu"
          [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-secondary menu--inactive'">
          Pricing
        </a>
        <a (click)="menuChange('contact-us')" [class.menu--active]="this.menuActive==='contact-us'"
          class="cursor-pointer text-semibold fs-menu lh-menu"
          [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-secondary menu--inactive'">
          Contact
        </a>
        <a (click)="viewIntroVideo()" class="cursor-pointer text-semibold fs-menu lh-menu"
          [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-secondary menu--inactive'">
          Intro Video
        </a>
      </nav>

      <div class="hidden lg:flex items-center justify-end md:flex-1 lg:w-0">
        <button (click)="menuChange('educational-intro')" *ngIf="showGetStartedBtn"
          class="button-get-started whitespace-nowrap text-regular shadow-sm fs-nav-button bg-get-started mr-6">
          Get Started
        </button>
        <a (click)="navigateToLogin()"
          class="cursor-pointer xl:border-l xl:pl-6 whitespace-nowrap text-semibold fs-menu "
          [ngClass]="!menuAlternate ? 'text-blue':'text-secondary'">
          Log In
        </a>
      </div>
    </div>
  </div>
  <div id="menuToggleItems" *ngIf="responsiveMenuToggle"
    class="absolute top-20  inset-x-0 p-2 shadow-lg transition transform origin-top-right lg:hidden">
    <div class="rounded-lg bg-white divide-y-2 divide-gray-50">
      <div class="py-5 mx-4">
        <nav class="grid gap-2" aria-labelledby="navbar">
          <a (click)="menuChange('educational-intro')" [ngClass]="(menuActive === 'educational-intro')?'text-white bg-red-700': 'text-primary'"
            *ngIf="showGetStartedBtn" class="cursor-pointer text-semibold fs-menu hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Get Started
          </a>
          <a (click)="menuChange('home')" [ngClass]="(menuActive === 'home')?'text-white bg-red-700': 'text-primary'"
            class="cursor-pointer text-semibold fs-menu hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Home
          </a>
          <a (click)="menuChange('pricing')"
            [ngClass]="(menuActive === 'pricing')?'text-white bg-red-700': 'text-primary'"
            class="cursor-pointer text-semibold fs-menu hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Pricing
          </a>
          <a (click)="menuChange('contact-us')" [ngClass]="{'text-white bg-red-700': (menuActive === 'contact-us')}"
            class="text-semibold fs-menu text-primary hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Contact
          </a>
          <a (click)="navigateToLogin()"
            class="cursor-pointer text-semibold fs-menu text-primary hover:text-white px-4 py-3 rounded-md hover:bg-red-500">
            Log In
          </a>
          <a (click)="viewIntroVideo()"
            class="text-semibold fs-menu text-primary hover:text-white px-4 py-2 rounded-md hover:bg-red-500 cursor-pointer">
            Intro Video
          </a>
        </nav>
      </div>
    </div>
  </div>
</div>
<app-video-view-popup [id]="videoModalID" [userRole]="'consumer'" [class]="'w-full h-full'"></app-video-view-popup>
