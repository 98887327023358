import { Component, OnInit } from '@angular/core';
import { CommonHelper } from 'src/app/helper/common.helper';
@Component({
  selector: 'app-consumer-privacy-policy-california',
  templateUrl: './consumer-privacy-policy-california.component.html',
})

export class ConsumerPrivacyPolicyCaliforniaComponent implements OnInit {
  public agentTerm: string;

  /**
* @constructor
*/
  constructor(private commonHelper: CommonHelper) { }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
  }

  /**
   * get current year
   * @return{number}
   */
  public getCurrentYear() {
    return this.commonHelper.currentYear;
  }
}
