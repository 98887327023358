import { Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { environment } from 'src/environments/environment';
import { USER_TYPES } from '../../constants/application.const';
import { CommonHelper } from '../helper/common.helper';
import { CommonModelService } from './common-model.service';
import { LocalStorageService } from './local-storage.service';


@Injectable({
  providedIn: 'root',
})
/**
 * Idle Service file
 */
export class IdleService {

  /**
   * constructor
   */
  constructor(
    private modalService: CommonModelService,
    private idle: Idle,
    private localStorageService: LocalStorageService,
    private commonHelper: CommonHelper,
  ) {
    this.idle.setIdle(environment.AUTO_LOGOUT_DURATION);
    this.idle.setTimeout(environment.AUTO_LOGOUT_TIMEOUT);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => this.reset());
    this.idle.onIdleStart.subscribe(() => this.modalService.open('session_expiry_modal'));
    /** Backup to stop idle timer if still running while user is not logged in (haven't seen this be needed yet) */
    this.idle.onTimeoutWarning.subscribe(() => {
      if (!this.isLoggedIn) {
        this.idle.stop();
      }
    });

    /** called after the inactive timeout */
    this.idle.onTimeout.subscribe(() => {
      this.modalService.close('session_expiry_modal');
      this.commonHelper.logoutCommon(this.userType);
    });
  }

  /**
   * Get current {@link USER_TYPES user type}.
   * @private
   */
  private get userType() {
    const userTypes = {
      Consumer: USER_TYPES.user,
      Professional: USER_TYPES.pro,
      Organization: USER_TYPES.org,
      Custodian: USER_TYPES.exe,
    };
    return userTypes[this.userRole];
  }

  /**
   * Get current user role
   * @private
   */
  private get userRole(): string {
    let userRole = this.localStorageService.getDataByKey('role');
    if (userRole == 'Executor') {
      userRole = 'Custodian';
    } else if (userRole == 'Advisor') {
      userRole = 'Professional';
    } else if (userRole == 'Sub Organization') {
      userRole = 'Organization';
    }
    return userRole;
  }

  private get isLoggedIn(): boolean {
    const currentUser = this.localStorageService.getUserData(this.userType);
    return !!currentUser?.access_token;
  }

  /**
   * Close session expiry modal and start idle if logged in & not running.
   */
  public reset(): void {
    this.modalService.close('session_expiry_modal');
    if (this.isLoggedIn && !this.idle.isRunning()) {
      this.idle.watch();
    }
  }
}
