import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIResponseModel } from '../interface/response.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * Profile service for updating profile details
 */
export class ProfileService {
  public BASE_URL:string;
  /**
   * @constructor
   */
  constructor(
    private http:HttpClient,
  ) {
    this.BASE_URL = environment.BASE_URL;
  }

  /**
   * Update email
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public updateEmail(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/users/change-email`, data);
  }
  /**
   * Update phone
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public updatePhoneNumber(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/users/change-phone`, data);
  }
  /**
   * Update profile picture
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public updateProfilePicture(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/users/change-profile-picture`, data);
  }

  /**
   * Change password
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public changePassword(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/users/change-password`, data);
  }

  /**
   * Verify Otp
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
    public verifyOtp(data:any):Observable<APIResponseModel> {
      return this.http.post<APIResponseModel>(this.BASE_URL + `/users/update-email`, data);
    }

  /**
   * Change email
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public changeEmail(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/users/change-email-verification', data);
  }
}
