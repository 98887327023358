<div id="aboutYourself">
  <div class="grid grid-cols-12 gap-6 py-8">
    <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-11">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            About {{userData?.user?.['first_name'] | titlecase}}
          </h4>
        </div>
        <div class="col-span-1" *ngIf="!userDetailsLoader">
          <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
               alt="digital vault" (click)="sis.navigate(['about-yourself/'],null,false)"
               *ngIf="(!requestIdParam && this.userType === 'Consumer')">
          <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
               (click)="sis.navigate(['about-yourself/'+clientRequestId],null,false)" alt="digital vault"
               *ngIf="(!requestIdParam && this.userType !== 'Consumer')">
        </div>
      </div>
      <div class="bg-transparent border-card-grey rounded my-4 ">
        <div class="grid grid-cols-12 p-6 gap-y-4 row-gap-4">
          <div class="col-span-12">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">Name</h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'100%'">
              <p class="text-darkgrey text-base text-regular tracking-wide">
                {{userData?.user?.['first_name'] + ' ' + userData?.user?.['middle_name'] + ' ' + userData?.user?.['last_name']}}
              </p>
            </app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6 ">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'90%'">
              <p class="text-darkgrey text-base text-regular tracking-wide">{{userData?.['dob'] | date:
                domainViewDateFormat}}</p>
            </app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6" *ngIf="showSSN">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'90%'">
              <p class="text-darkgrey text-base text-regular tracking-wide">{{userData?.['ssn']}}</p>
            </app-shimmer-loading>
          </div>
          <div class="col-span-12">
            <h4 class="text-label-grey text-sm tracking-wide text-regular capitalize">Address
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'100%'">
              <p class="text-darkgrey text-base text-regular tracking-wide">
                {{userData?.full_address || 'Not Available'}}
            </app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6 " *ngIf="userData?.user.phone">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'90%'">
              <p class="text-darkgrey text-base text-regular tracking-wide">+{{userData?.user?.['country_code']}}
                {{userData?.user.phone}}</p>
            </app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6" *ngIf="userData?.place_of_birth">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">Place of Birth
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'90%'">
              <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize">
                {{userData?.place_of_birth?.name}}</p>
            </app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6" *ngIf="userData?.user.email">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'90%'">
              <p class="text-darkgrey break-all text-base text-regular tracking-wide">{{userData?.user.email}}</p>
            </app-shimmer-loading>
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-12 md:col-span-6 my-4 lg:pl-6">


      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-8">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Relationship Status
          </h4>
        </div>
        <div class="col-span-4">
          <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row"
             *ngIf="!userDetailsLoader">
            {{relationShipMapper[userData?.['marital_status']]}}
            <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
                 alt="digital vault" (click)="sis.navigate(['about-yourself/partner/'],null,false)"
                 *ngIf="(!requestIdParam && this.userType === 'Consumer')">
            <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
                 (click)="sis.navigate(['about-yourself/partner/'+clientRequestId],null,false)" alt="digital vault"
                 *ngIf="(!requestIdParam && this.userType !== 'Consumer')">
          </p>
        </div>
      </div>

      <div class="bg-transparent border-card-grey rounded my-4" *ngIf="userDetailsLoader">

        <div class="grid grid-cols-12 p-6 gap-y-4 row-gap-4">
          <div class="col-span-12">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'100%'">
              <p class="text-darkgrey text-base text-regular tracking-wide">{{partnerDetails?.['first_name']}}
                {{partnerDetails?.['last_name']}}</p>
            </app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6 ">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'90%'">
              <p class="text-darkgrey text-base text-regular tracking-wide">{{partnerDetails?.['dob'] | date:
                domainViewDateFormat}}</p>
            </app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6" *ngIf="showSSN">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'90%'">
              <p class="text-darkgrey text-base text-regular tracking-wide">{{partnerDetails?.['ssn']}}</p>
            </app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6 ">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'90%'">
              <p class="text-darkgrey text-base text-regular tracking-wide">+{{partnerDetails?.['country_code']}}
                {{partnerDetails?.phone}}</p>
            </app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'90%'">
              <p class="text-darkgrey break-all text-base text-regular tracking-wide">{{partnerDetails?.email}}</p>
            </app-shimmer-loading>
          </div>
        </div>

      </div>
      <div class="bg-transparent border-card-grey rounded my-4" *ngIf="!userDetailsLoader && partnerDetails">
        <div class="grid grid-cols-12 p-6 gap-y-4 row-gap-4">
          <div class="col-span-12">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
            </h4>
            <p class="text-darkgrey text-base text-regular tracking-wide">{{partnerDetails?.['first_name']}}
              {{partnerDetails?.['middle_name']}}
              {{partnerDetails?.['last_name']}}</p>

          </div>
          <div class="col-span-12 md:col-span-6 ">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB
            </h4>
            <p class="text-base text-regular tracking-wide"
               [ngClass]="partnerDetails['dob'] ? 'text-darkgrey' : 'text-darkblue'">
              {{(partnerDetails['dob'] | date:domainViewDateFormat) || 'Not Available'}}
            </p>
          </div>
          <div class="col-span-12 md:col-span-6" *ngIf="showSSN">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
            </h4>
            <p class="text-base text-regular tracking-wide"
               [ngClass]="partnerDetails['ssn'] ? 'text-darkgrey' : 'text-darkblue'">
              {{partnerDetails['ssn'] || 'Not Available'}}
            </p>
          </div>
          <div class="col-span-12 md:col-span-6 ">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
            </h4>
            <p class="text-base text-regular tracking-wide"
               [ngClass]="partnerDetails?.['phone'] ? 'text-darkgrey' : 'text-darkblue'">
              {{
              partnerDetails?.['phone'] ?
                ['+', partnerDetails?.['country_code'], ' ', partnerDetails['phone']].join('') : 'Not Available'
              }}
            </p>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
            </h4>
            <p class="text-base text-regular tracking-wide"
               [ngClass]="partnerDetails['email'] ? 'text-darkgrey' : 'text-darkblue'">
              {{partnerDetails['email'] || 'Not Available'}}
            </p>
          </div>
          <div class="col-span-12 ">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Address
            </h4>
            <p class="text-base text-regular tracking-wide"
               [ngClass]="partnerDetails['full_address'] ? 'text-darkgrey' : 'text-darkblue'">
              {{partnerDetails['full_address'] || 'Not Available'}}
            </p>
          </div>
        </div>
      </div>
      <div class="bg-card-overview border-card-grey rounded mt-4" *ngIf="!userDetailsLoader && !partnerDetails">
        <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
          No Data to Display
        </h4>
      </div>


      <div class="grid grid-cols-12 justify-between mt-8 items-center">
        <div class="col-span-8">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Your Children
          </h4>
        </div>
        <div class="col-span-4">
          <p class="text-lightblue float-right text-regular text-semibold tracking-wide flex flex-row"
             *ngIf="!userDetailsLoader">
            {{childrenArray?.length}} Children
            <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
                 alt="digital vault" (click)="sis.navigate(['about-yourself/children/'],null,false)"
                 *ngIf="(!requestIdParam && this.userType === 'Consumer')">
            <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer"
                 (click)="sis.navigate(['about-yourself/children/'+clientRequestId],null,false)" alt="digital vault"
                 *ngIf="(!requestIdParam && this.userType !== 'Consumer')">
          </p>
        </div>
      </div>
      <div class="bg-transparent border-card-grey rounded my-4" *ngIf="userDetailsLoader">
        <div class="grid grid-cols-12 p-6 gap-y-4 row-gap-4">
          <div class="col-span-12">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6 ">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'90%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6" *ngIf="showSSN">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'90%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6 ">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'90%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
            </h4>
            <app-shimmer-loading [loading]="userDetailsLoader" [width]="'90%'"></app-shimmer-loading>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!userDetailsLoader">
        <div class="bg-transparent border-card-grey rounded my-4" *ngFor="let child of childrenArray">
          <div class="grid grid-cols-12 p-6 gap-y-4 row-gap-4">
            <div class="col-span-12">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
              </h4>
              <p class="text-darkgrey text-base text-regular tracking-wide">{{child?.['first_name']}}
                {{child?.['middle_name']}}
                {{child?.['last_name']}}</p>
            </div>
            <div class="col-span-12 md:col-span-6 ">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Status
              </h4>
              <p class="text-base text-regular tracking-wide"
                 [ngClass]="child['child_type_str'] ? 'text-darkgrey' : 'text-darkblue'">
                {{child['child_type_str'] || 'Not Available'}}
              </p>
            </div>
            <div class="col-span-12 ">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Address
              </h4>
              <p class="text-base text-regular tracking-wide"
                 [ngClass]="child['full_address'] ? 'text-darkgrey' : 'text-darkblue'">
                {{child['full_address'] || 'Not Available'}}
              </p>
            </div>
            <div class="col-span-12 md:col-span-6 ">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB
              </h4>
              <p class="text-base text-regular tracking-wide"
                 [ngClass]="child['dob'] ? 'text-darkgrey' : 'text-darkblue'">
                {{(child['dob'] | date:domainViewDateFormat) || 'Not Available'}}
              </p>
            </div>
            <div class="col-span-12 md:col-span-6" *ngIf="showSSN">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
              </h4>
              <p class="text-base text-regular tracking-wide"
                 [ngClass]="child['ssn'] ? 'text-darkgrey' : 'text-darkblue'">
                {{child['ssn'] || 'Not Available'}}
              </p>
            </div>
            <div class="col-span-12 md:col-span-6 ">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
              </h4>
              <p class="text-base text-regular tracking-wide"
                 [ngClass]="child?.['phone'] ? 'text-darkgrey' : 'text-darkblue'">
                {{child?.['phone'] ? ['+', child?.['country_code'], ' ', child['phone']].join('') : 'Not Available'}}
              </p>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
              </h4>
              <p class="text-base text-regular tracking-wide"
                 [ngClass]="child?.['email'] ? 'text-darkgrey' : 'text-darkblue'">
                {{child?.['email'] || 'Not Available'}}
              </p>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="bg-card-overview border-card-grey rounded mt-4" *ngIf="childrenArray?.length === 0">
        <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
          No Data to Display
        </h4>
      </div>
    </div>
  </div>

</div>
