<div class="scroll-hidden">
  <!-- Navbar -->
  <app-navbar *ngIf="this.userType === 'Consumer'"></app-navbar>
  <app-exe-cus-navbar *ngIf="this.userType === 'Custodian'"></app-exe-cus-navbar>
  <app-professional-navbar [showReturnToDashboard]="true" *ngIf="this.userType !== 'Consumer'&& this.userType!=='Custodian'"></app-professional-navbar>
  <div class="lg:grid grid-cols-12 background-color-grey">
    <!-- BEGIN: Leftside Content -->
    <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
      <div class="flex flex-col w-full">
        <div class="h-full items-center justify-center">
          <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
            <div class="h-full justify-center items-center ">
              <div class="grid grid-cols-12 items-center gap-4 my-4">
                <div class="col-span-12 sm:col-span-2">
                    <img src="assets/images/property-details/donation.svg" alt="digital vault" class="w-20">
                </div>

                <div class="col-span-12 sm:col-span-10">
                    <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
                      Donation Details
                    </h2>
                    <p class="text-sm text-grey tracking-wide text-regular">
                      Enter donation details.
                    </p>
                </div>
              </div>
              <!-- BEGIN: Leftside Card -->
              <div class="border-card bg-white rounded-md">
                <!-- BEGIN: Progress Bar -->
                <app-card-progress-bar [progressValue]="'w-full'"></app-card-progress-bar>
                <!-- END: Progress Bar -->

                <div class="p-6">
                  <div class="items-center">
                    <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                      Donation(s)
                    </h4>
                    <p class="text-grey text-regular pt-1 text-sm">The making of a gift especially to a charity or
                      public institution.</p>
                      <app-payment-message-text [clientHasPaidSubscription]="clientHasPaidSubscription"></app-payment-message-text>
                    </div>

                  <div class="bg-transparent border-card-grey rounded-xs mt-6 h-20" *ngIf="preDefinedLoader">
                    <div class="grid grid-cols-12 h-20 items-center gap-4 px-4">
                      <div class="col-span-3">
                        <div class="flex items-center justify-center border-circle mx-auto rounded-full h-12 w-12">
                          <app-shimmer-loading [loading]="preDefinedLoader" [shape]="'circle'" [width]="'4rem'">
                          </app-shimmer-loading>
                        </div>
                      </div>
                      <div class="col-span-7">
                        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                          <h4 class="text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate"></h4>
                        </app-shimmer-loading>
                        <p class="text-grey text-regular text-sm truncate">
                          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                            <span class="font-semibold"></span>
                          </app-shimmer-loading>
                        </p>
                      </div>
                      <div class="col-span-2">
                        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                          <img src="assets/images/about-yourself/edit.svg" class="cursor-pointer w-4 sm:w-5 float-right cursor-pointer"
                            alt="digital vault">
                        </app-shimmer-loading>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="!preDefinedLoader">
                    <ng-container *ngIf="!this.permissions?.view">
                      <p class="p-2 rounded-b-sm text-gray-700 background-color-grey mt-6">
                        You do not have permission to view these items
                      </p>
                    </ng-container>
                    <ng-container *ngIf="this.permissions?.view">
                      <div class="bg-transparent border-card-grey rounded-xs mt-6 h-20"
                        *ngFor="let data of donationsArray">
                        <div class="grid grid-cols-12 h-20 items-center gap-4 px-4">
                          <div class="col-span-3 sm:col-span-2">
                            <div class="flex items-center justify-center mx-auto rounded-full">

                              <app-image-component [image]="'assets/images/property-details/donation-list.svg'"
                                [class]="'object-cover rounded-full h-12 w-12'">
                              </app-image-component>
                            </div>
                          </div>
                          <div class="col-span-7 sm:col-span-8">
                            <h4 class="text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate truncate">
                              {{data?.['organization_name']}}</h4>
                            <p class="text-grey text-regular text-sm truncate"> {{data?.['donation_type_name']}}
                            </p>
                          </div>
                          <div class="col-span-2">
                            <img src="assets/images/about-yourself/edit.svg"
                              [ngClass]="{'cursor-pointer':canAccessForm}" class="w-4 sm:w-5 float-right cursor-pointer" alt="digital vault"
                              (click)="canAccessForm? toggleModal(data):''">
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>

                  <button type="button" (click)="toggleModal()" [disabled]="disableButton" [ngClass]="{'text-grey border-outline-grey' : disableButton}"
                    class="button button--lg w-full mt-6 flex flex-row gap-3 justify-center items-center bg-transparent border-red rounded-xs h-12 md:h-16 focus:outline-none text-regular text-sm md:text-base font-semibold tracking-wide text-darkblue px-6">
                    <img src="assets/images/accounts-assets/file-plus.svg" class="hidden md:block w-5" alt="digital vault">
                    Add Donation Details
                  </button>

                  <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
                    <button (click)="click('Back')"
                      class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                      <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault">
                      Back
                    </button>
                    <div *ngIf="!buttonProgress" class="flex flex-col-reverse sm:flex-row justify-end items-center gap-4 w-full">
                      <button (click)="click('SaveForLater')"
                        [disabled]="preDefinedLoader || !canAccessForm"
                        class="flex items-center justify-center border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 text-regular px-6">
                        <div class="loading-blue mr-2" *ngIf="preDefinedLoader"></div>
                        Save for Later
                      </button>
                      <button (click)="click('MarkComplete')" [tooltip]="'You can update later should this information change'"
                        [disabled]="preDefinedLoader || !canAccessForm"
                        class="flex items-center justify-center mark-complete-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 text-regular px-6">
                        <div class="loading-blue mr-2" *ngIf="preDefinedLoader"></div>
                        Mark as Complete
                      </button>
                    </div>
                    <div *ngIf="buttonProgress" class="flex flex-col-reverse sm:flex-row justify-end items-center gap-4 w-full">
                      <button *ngIf="buttonProgress && this.userType === 'Consumer'"
                        (click)="click('ReturnToDashboard')" [disabled]="preDefinedLoader"
                        class="border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 focus:outline-none text-regular text-white px-6">
                        Return to Dashboard
                      </button>
                      <button (click)="click('NextSection')" [disabled]="preDefinedLoader"
                        class="text-sm tracking-wide w-full sm:w-auto bg-darkblue hover:underline-text rounded-xs h-10 focus:outline-none text-regular text-white px-6 text-bold">
                        Next Section
                      </button>
                    </div>
                  </div>
                </div>
                <!-- END: Leftside Card -->
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- END: Leftside Content -->

    <!-- BEGIN: Rightside Content -->
    <div class=" col-span-12 md:col-span-6 lg:grid justify-center ">
      <about-yourself-right-content [formInputValue]="{}"
        [otherValues]="sectionSaveExitOptions"></about-yourself-right-content>
    </div>
    <!-- END: Rightside Content -->
  </div>
</div>

<!-- Add donation popup -->
<app-add-donation [editData]="editData" [permissions]="permissions" [isNewAsset]="isNewAsset"
 [clientHasPaidSubscription]="clientHasPaidSubscription"
  (toggleModalEmitter)="modalEventListener($event)">
</app-add-donation>
<app-payment-status-modal></app-payment-status-modal>
<app-payment-success></app-payment-success>
<app-payment-failure></app-payment-failure>
