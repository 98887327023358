import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { MailStatusReponseInterface } from 'src/app/interface/common.interface';
import { ExecutorCustodianService } from '../../services/executor-custodian.service';

@Component({
  selector: 'app-mail-status-response',
  templateUrl: './mail-status-response.component.html'
})
export class MailStatusResponseComponent implements OnInit {
  public currentPage: string;
  public mailEventName: string;
  public messageDetails: MailStatusReponseInterface;
  constructor(
    private route : ActivatedRoute,
    private custodianService: ExecutorCustodianService,
    private activatedRoute: ActivatedRoute,
    private slugInterceptorService: SlugInterceptorService
  ) {
    if(this.route.snapshot.params['id'] == 'invalid_email_link') {
      this.mailEventName = this.route.snapshot.params['id'];
    }
  }
 /**
 * loaded initially
 */
 ngOnInit(): void {
  this.messageDetails = {
    messageResponse: message_Response.loading,
    headerMessage: '',
    footerMessage: 'Thank you for your cooperation!',
    greeting: 'Please wait...',
    supportMessage: ''
  }
  if(!this.mailEventName) {
    Object.keys(this.route?.snapshot?.queryParams).forEach((item)=>{
      this.mailEventName = item;
    })
  }
  switch(this.mailEventName) {
    case mail_event.do_not_approve:
      this.route.params.subscribe(params => {
        if(params.id){
          this.custodianService.updateUserDeathAproveStatus(params.id).subscribe({
            next: () =>{
              this.messageDetails = {
                messageResponse: message_Response.success_not_approve,
                headerMessage: '',
                footerMessage: '',
                greeting: 'Your decision not to approve has been duly recorded and will be promptly addressed.',
                supportMessage: 'If you have any feedback or concerns, please feel free to reach out to our support team at'
              }
            },
            error: (err) => {
              if(err?.status == 403) {
                this.messageDetails = {
                  messageResponse: message_Response.timeout_event,
                  headerMessage: 'Approval Request Expired',
                  footerMessage: 'Thank you for your cooperation',
                  greeting: 'We wish to inform you that the timeframe for rejecting this request has elapsed. Please be aware that the Digital Vault Custodian/Executor has initiated the vault claim process.',
                  supportMessage: 'If you suspect any unauthorized or false claim, please report it immediately to our support team at'
                }
              } else {
                this.messageDetails = {
                  messageResponse: message_Response.error_exception,
                  headerMessage: '',
                  footerMessage: '',
                  greeting: 'Something went wrong',
                  supportMessage: 'If you suspect any unauthorized or false claim, please report it immediately to our support team at'
                }
              }
            }
          })
        }
      })
      break;
    case mail_event.invalid_email_link:
      this.messageDetails = {
        messageResponse: message_Response.error_exception,
        headerMessage: 'Invalid Email Link',
        footerMessage: 'Thank you for your cooperation',
        greeting: 'The email link you are accessing is no longer valid. If you have any questions or concerns, please contact support for assistance.',
        supportMessage: ''
      }
      break;
    default:

      break;
  }
}
  /**
   * Change router url
   * @param {string} url
   */
  public changeRouter(url?: string): void {
    this.currentPage = this.activatedRoute.snapshot.url[0]?.path;

    if (url) {
      void this.slugInterceptorService.navigate([url]);
    }
  }
}
enum message_Response {
  loading,
  success_not_approve,
  timeout_event,
  error_exception
}
enum mail_event {
  do_not_approve = 'do-not-approve',
  invalid_email_link = 'invalid_email_link'
}
