import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SwipeModel } from '../../directives/swipe.directive';


@Component({
  selector: 'app-carousal',
  templateUrl: './carousal.component.html',
  styleUrls: ['./carousal.component.css'],
})
/**
 * Carousal component shared across module
 */
export class CarousalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('carouselRef') carouselRef: ElementRef;
  @Input() interval: number = 5000;
  @Input() landingPage: boolean;
  @Input() hideNav: boolean = true;
  @Input() hideDots: boolean = false;
  private readonly classHidden: string = 'hidden';
  private readonly classActive: string = 'transition-all duration-1000 ease-in-out opacity-100';
  public currentlySelectedCarousel: number = 0;
  public carouselCount: number = 0;
  public timerIncrement: NodeJS.Timeout;
  public carouselItems: {}[];

  ngOnInit(): void {
    this.currentlySelectedCarousel = 0;
  }

  ngAfterViewInit(): void {
    this.updateCarouselItems();
    this.changeCarousel(0);
  }

  /**
   * Reset & set timout for Carousel auto-increment.
   * @private
   */
  private resetTimer() {
    // Clear the timeout
    if (this.timerIncrement) {
      clearTimeout(this.timerIncrement);
    }
    this.timerIncrement = setTimeout(() => {
      this.nextCarousel();
      this.resetTimer();
    }, this.interval);
  }

  /**
   * Go to previous carousel item
   * @private
   */
  lastCarousel() {
    // In case of dynamic template changes.
    this.updateCarouselItems();
    this.currentlySelectedCarousel--;
    if (this.currentlySelectedCarousel < 0) {
      this.currentlySelectedCarousel = this.carouselItems?.length - 1;
    }
    this.changeCarousel(this.currentlySelectedCarousel);
  }

  /**
   * Go to next carousel item
   * @private
   */
  nextCarousel() {
    // In case of dynamic template changes.
    this.updateCarouselItems();
    this.currentlySelectedCarousel++;
    if (this.currentlySelectedCarousel === this.carouselItems?.length) {
      this.currentlySelectedCarousel = 0;
    }
    this.changeCarousel(this.currentlySelectedCarousel);
  }

  /**
   * Change carousel by index
   * @param {number} index
   */
  public changeCarousel(index: number): void {
    this.currentlySelectedCarousel = index;
    this.carouselItems.forEach((e) => e['className'] = this.classHidden);
    this.carouselItems[index]['className'] = this.classActive;
  }

  /**
   * Determine containers to use as carousel items, populate them in {@link carouselItems}.
   * @private
   */
  private updateCarouselItems() {
    const childNodes = this.carouselRef.nativeElement.childNodes;
    this.carouselItems = [...childNodes].filter((e) => e.nodeName == 'DIV');
    this.carouselCount = this.carouselItems?.length;
  }

  /**
   * Called when component destroyed
   */
  ngOnDestroy(): void {
    if (this.timerIncrement) {
      clearInterval(this.timerIncrement);
    }
  }

  /**
   * Handle touch swipe actions.
   * @param $event
   */
  onSwipe($event: SwipeModel) {
    const actions = { L: this.lastCarousel.bind(this), R: this.nextCarousel.bind(this) };
    if (Object.keys(actions).indexOf($event.direction) >= 0) {
      this.resetTimer();
      actions[$event.direction]();
    }
  }
}