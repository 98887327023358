/* eslint-disable camelcase */
import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel, APIStatusCodes } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { USER_TYPES } from 'src/constants/application.const';
import { LPEmitterInterface, LPSettingsInterface, LPUserPeopleInterface } from '../../../../sharedComponent/list-people/list-people-interfaces';
import { ListPeopleComponent } from '../../../../sharedComponent/list-people/list-people.component';


@Component({
  selector: 'app-advisor-listing',
  templateUrl: './advisor-listing.component.html',

})
/**
 * Advisor list
 */
export class AdvisorListingComponent implements OnInit {
  @Input() requestID: string;
  public defineAdvisorID: string = 'define-advisor-modal--advisor-listing';
  public isCustodian: boolean;
  public hideAccountPermissions: boolean;
  public userPeopleDataRaw: Array<Object>;
  public userPeopleData: Array<LPUserPeopleInterface>;
  public editData: any;
  public advisorData: any;
  public submitLoader: boolean;
  public currentRoute: string;
  // public advisorArray:Array<string>;
  public userType: string;
  public permissionList: Array<any> = [];
  public grantAdvisorTerms: boolean;
  public index: number;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public lpSettings: LPSettingsInterface;
  public contactPermissionItemsOld: { value: any, children: { value: any, checked: boolean }[] }[];
  public contactPermissionItems: TreeviewItem[];
  public config = TreeviewConfig.create({
    hasAllCheckBox: false,
    maxHeight: 400,
  });
  // TODO hide until implementation for professional communication permissions is ready.
  showTree: boolean = false;


  /**
   * @constructor
   */
  constructor(
    private personalDetailsService: PersonalDetailsService,
    private modalService: CommonModelService,
    private router: Router,
    private commonHelper: CommonHelper,
    private changeDetection: ChangeDetectorRef,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private peopleService: PeopleService,
  ) {
  }


  /**
   * called initailly
   */
  ngOnInit(): void {
    this.lpSettings = {
      button: {
        text: `Manage Provider`,
        show: true,
        //tooltip: 'Select and manage access to different Vault sections for your authorized individuals',
        useSpinner: true,
      },
      isLoading: false,
    };
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.currentRoute = this.route.snapshot.url[0]?.path;
    this.userType = this.localStorageService.getDataByKey('role');
    this.isCustodian = this.userType == 'Custodian';
    this.getPersonalDetails();
    // advisor remove
    this.peopleService.advisorIdObserve.subscribe((response: string) => {
      if (response) {
        this.onRemoveAdvisor(response);
      }
    });
    // fetch advisor
    this.peopleService.refreshDetailsCommonObserve.subscribe((response: any) => {
      if (response) {
        this.getPersonalDetails();
      }
    });
    this.detectHideAccountPermissions();
  }


  /**
   *
   * @private
   */
  @HostListener('window:resize', [])
  private onResize() {
    this.detectHideAccountPermissions();
  }


  /**
   * Determine whether to hide "Account Permissions" text to avoid small-screen clutter.
   * @private
   */
  private detectHideAccountPermissions() {
    this.hideAccountPermissions = window.innerWidth < 480;
  }


  /**
   * Get personal details
   */
  private getPersonalDetails(): void {
    this.lpSettings.isLoading = true;
    // get people list
    this.personalDetailsService.getPersonalDetails(this.userType !== 'Consumer' ? this.requestID : null).subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          this.grantAdvisorTerms = response.data.grant_advisor_terms;
          this.userPeopleDataRaw = response.data.user_people?.filter((user) => {
            if (this.isCustodian) {
              return user.custodian === 1 && user.advisor === 0;
            } else {
              return user.advisor === 1;
            }
          });
          this.userPeopleDataRaw = this.personalDetailsService.processUserPeople(this.userPeopleDataRaw);
          this.processUserPeopleData();
        }
      },
      error: (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
      complete: () => this.lpSettings.isLoading = false,
    });
  }


  /**
   * Process {@link this.userPeopleDataRaw userPeopleDataRaw} to populate {@link this.userPeopleData userPeopleData}
   * with data to display.
   * @private
   */
  private processUserPeopleData() {
    this.userPeopleData = ListPeopleComponent.getLPUserPeople(this.userPeopleDataRaw);
    this.userPeopleData.forEach(e => {
      const rawDataPerson = this.userPeopleDataRaw.find(r => r['id'] == e.id);
      if (!rawDataPerson['professional_id']) {
        e.button = { text: 'View Provider Info' };
      }
    });
    this.updatePermissionsTree();
  }


  /**
   * Refresh listing
   *
   * @param {any} updateValue
   */
  public refreshListing(updateValue: any): void {
    this.changeDetection.detectChanges();
    this.getPersonalDetails();
  }


  /**
   * remove advisor
   * @param removed_id
   */
  private onRemoveAdvisor(removed_id: string) {
    if (removed_id) {
      const index = this.userPeopleDataRaw.findIndex((val) => val['id'] === removed_id);
      if (index !== -1) {
        this.userPeopleDataRaw.splice(index, 1);
      }
      this.processUserPeopleData();
    }
  }


  /**
   *
   */
  get agentTerm() {
    return this.commonHelper.LocalStorageAgentTerm();
  }


  /**
   *
   * @param{any} advisorData
   */
  public openSettings(advisorData: Object) {
    /**
     * Handles getting advisor permissions, then opens advisor-settings-modal.
     * @param hasActiveAccount
     */
    const viewAdvisorPermissions = (hasActiveAccount: boolean) => {
      const userData = this.localStorageService.getUserData(USER_TYPES.user);
      const primaryAdvisorId = userData?.['slug_details']?.id;
      this.peopleService.viewAdvisorPermissions(advisorData['id'], userId).subscribe({
        next: (response) => {
          this.permissionList = response?.data;
          this.advisorData = {
            professional_type: { name: person.subtext },
            ...response.data,
            ...this.editData,
            user_id: userId,
            is_primary_advisor : primaryAdvisorId && (primaryAdvisorId === this.editData['professional_id'] ||  primaryAdvisorId === this.editData['id']),
            advisor_id: advisorData['id'],
            has_active_account: hasActiveAccount,
          };
          if (Object.keys(response.data)?.length > 0) {
            this.modalService.open('advisor-settings-modal');
          }
        },
        error: e => {
          this.commonHelper.httpResponseHandler(e.error);
          person.isLoading = false;
        },
        complete: () => person.isLoading = false,
      });
    };
    const person = this.userPeopleData.find((e) => e.id == advisorData['id']);
    const userId = this.localStorageService.getUserData()?.user?.id;
    this.advisorData = {};
    person.isLoading = true;
    this.editData = { ...advisorData };

    this.peopleService.getAdvisorByEmail({ email: advisorData['email'] }).subscribe({
      next: (response) => {
        if (response && response.data?.id) {
          this.editData = { ...response.data };
          this.peopleService.advisorRemoveEvents(this.editData);
          viewAdvisorPermissions(true);
        }
      },
      error: (e) => {
        // If not found, then this is a pro user_person without a professional account.
        if (e.status == APIStatusCodes.NOT_FOUND) {
          viewAdvisorPermissions(false);
        } else {
          this.commonHelper.httpResponseHandler(e.error);
        }
      },
    });
  }


  /**
   * Retrieve click events from list-people
   * @param {LPEmitterInterface} event
   */
  public clickListener(event: LPEmitterInterface) {
    // Manage Provider Settings
    if (event.clickedName || event.clickedButton) {
      const peopleData = this.userPeopleDataRaw.find((e) => e['id'] == event.id);
      this.openSettings(peopleData);
    }
  }


  /**
   * Updates the items in the permissions tree
   */
  private updatePermissionsTree(): void {
    const advisorTypes: string[] = [];

    // Get unique ids TODO uses names atm just to set up presentation, but should use ids in future.
    this.userPeopleData.forEach((advisor) => {
      const name: string = advisor.subtext;
      if (!advisorTypes.includes(name)) {
        advisorTypes.push(name);
      }
    });

    this.contactPermissionItems = [];
    advisorTypes.forEach((e) => {
      this.contactPermissionItems.push(this.buildItem(e, e, advisorTypes));
    });

    if (this.contactPermissionItems.length > 0) {
      // Expand first item
      this.contactPermissionItems[0].collapsed = false;
    }
    this.contactPermissionItemsOld = this.buildCopyData();
  }


  /**
   * Build a TreeviewItem and return it
   * @param{string} text
   * @param{string} value
   * @param{any} children
   * @return{TreeviewItem}
   */
  private buildItem(text: string, value: string, children: any) {
    const childrenTree = [];
    children.filter((e) => e !== text).forEach((e) => {
      childrenTree.push({
        text: e,
        value: e,
        checked: true,
      });
    });

    return new TreeviewItem({
      text: text,
      value: value,
      collapsed: true,
      children: childrenTree,
    });
  }


  /**
   * Called on ngx-treeview item change.
   * When an item is changed, the corresponding permission pair must also be changed.
   */
  public itemChanged(): void {
    // Item that will need to change
    const parentValues: any[] = [];
    const childValues: any[] = [];
    const bCheckedValues: boolean[] = [];

    // find what changed by comparing contactPermissionItemsOld
    let bFound: boolean = false;
    for (let x = 0; x < this.contactPermissionItems.length; x++) {
      for (let y = 0; y < this.contactPermissionItems[x].children.length; y++) {
        if (this.contactPermissionItems[x].children[y].checked !== this.contactPermissionItemsOld[x].children[y].checked) {
          bFound = true;
          bCheckedValues.push(this.contactPermissionItems[x].children[y].checked);
          parentValues.push(this.contactPermissionItems[x].children[y].value);
          childValues.push(this.contactPermissionItems[x].value);
        }
      }
    }

    // Change Corresponding Contact Permission to 'checked'
    if (bFound) {
      parentValues.forEach((parentValue, index) => {
        this.contactPermissionItems.forEach((p) => {
          if (p.value === parentValue) {
            p.children.forEach((c) => {
              if (c.value === childValues[index]) {
                c.setCheckedRecursive(bCheckedValues[index]);
                p.correctChecked(); // To update status of parent checked status
                return;
              }
            });
          }
        });
      });

      // Update contactPermissionItemsOld
      this.contactPermissionItemsOld = this.buildCopyData();
    }
  }


  /**
   * Copies the TreeviewItems data to separate them from object reference.
   * @return{any}
   */
  private buildCopyData(): { value: any, children: { value: any, checked: boolean }[] }[] {
    const copy: { value: any, children: { value: any, checked: boolean }[] }[] = [];

    this.contactPermissionItems.forEach((e) => {
      const childrenAdd: { value: any, checked: boolean }[] = [];
      if (e.children) {
        e.children.forEach((c) => childrenAdd.push({ value: c.value, checked: c.checked }));
      }
      copy.push({ value: e.value, children: childrenAdd });
    });

    return copy;
  }


  editAdvisorListener(event: boolean) {
    if (event) {
      this.advisorData = { ...this.advisorData };
      this.modalService.close('advisor-settings-modal');
      this.modalService.open(this.defineAdvisorID);
    }
  }
}
