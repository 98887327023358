<div class="lg:grid grid-cols-12 background-color">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 md:gap-8 my-4">
              <div class="col-span-12 sm:col-span-2">
                <!-- <div class="w-16 my-2"> -->
                <img src="assets/images/about-yourself/about-yourself.png" class="w-20" alt="digital vault">
                <!-- </div> -->
              </div>

              <div class="col-span-12 sm:col-span-10">
                <!-- <div class="pl-4 sm:px-4 ml-8"> -->
                <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
                  Tell us about yourself
                </h2>
                <p class="text-sm text-grey tracking-wide text-regular">This information is critical and a key to
                  verifying your identity. Please double-check
                  your input prior to moving on.
                </p>
                <!-- </div> -->

              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-3/6'"></app-card-progress-bar>
              <!-- END: Progress Bar -->

              <div class="p-6">
                <div class="mb-4">
                  <div class="flex flex-row items-baseline">
                    <div>
                      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                        What’s your Email Address and Phone Number?
                      </h4>
                    </div>
                  </div>
                </div>
                <form [formGroup]="contactDetailForm" (ngSubmit)="onSubmit()" autocomplete="off">
                  <div class="grid grid-cols-12 gap-4 row-gap-4 mt-4">
                    <div class="col-span-12">
                      <label for="email" class="text-label-grey text-sm text-regular">Email Address*</label>
                      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                        <input type="email"
                          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                          placeholder="e.g. marktylor@gmail.com"
                          [ngClass]="formGet?.email?.errors && formGet?.email?.touched?'form-input-error':'form-input-focus'"
                          formControlName="email" name="email" id="email" disabled="true">
                      </app-shimmer-loading>
                      <div *ngIf="formGet?.email?.errors && formGet?.email?.touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet?.email?.errors?.pattern">Please enter a valid email address.</p>
                      </div>
                    </div>
                    <div class="col-span-12">
                      <label for="phone-no" class="text-label-grey text-sm text-regular">Phone Number*</label>
                      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                        <app-phone-input [placeholder]="'e.g. 8665044754'" id="phone-no"
                          [phoneNumber]="contactDetailForm.value.phone" [countryCode]="countryCode"
                          [uniqueId]="'phone-self'"
                          (onChange)="detectChangePhonenumber($event)"
                          [errorClassTrigger]="formGet?.phone.errors && formGet?.phone.touched"
                          [form]="contactDetailForm"
                          (focusout)="formGet?.phone?.markAsTouched()">
                        </app-phone-input>
                      </app-shimmer-loading>
                      <div *ngIf="formGet?.phone.errors && formGet?.phone.touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet?.phone.errors.pattern">Please enter a valid phone number</p>
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet?.phone.errors.required">
                          Phone
                          Number is required</p>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
                    <a (click)="changeRoute('about-yourself/location-details')" *ngIf="this.userType === 'Consumer'"
                      class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                      <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                    </a>
                    <a (click)="changeRoute('about-yourself/location-details/'+requestId)"
                      *ngIf="this.userType !== 'Consumer'"
                      class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                      <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                    </a>
                    <app-button-loader [buttonText]="'Save and Continue'" [valid]="true"
                      [loader]="submitLoader" buttonType="'submit'"></app-button-loader>
                  </div>
                </form>
              </div>
              <!-- END: Leftside Card -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class="col-span-12 md:col-span-6 lg:grid justify-center">
    <about-yourself-right-content
      [formInputValue]="{phone:contactDetailForm?.value.phone,country_code: this.countryCode}"
      [buttonStatus]="contactDetailForm?.valid ? 1 : 0"
      [otherValues]="sectionSaveExitOptions"></about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->
</div>
