import { Component, Input, OnInit } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-processing',
  templateUrl: './payment-processing.component.html',
})
/**
 * payment process modal
 */
export class PaymentProcessingComponent implements OnInit {
  /**
   * modalService
   */
  @Input() onlyShowProcessHeader: boolean = false;
  public isLoginUrl: boolean;

  constructor(private modalService:CommonModelService, 
    private route: Router) { }
  /**
 * loaded initially
 */
  ngOnInit(): void {
    // To prevent the modal from opening on the login page.
    this.isLoginUrl = this.route.url == '/' || this.route.url == '/login';
    console.log('payment-processing');
  }
  /**
   * close modal
   */
  public closeModal() {
    this.modalService.close('payment-process');
  }
}
