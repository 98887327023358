import { Component } from '@angular/core';
import { CommonHelper } from 'src/app/helper/common.helper';

@Component({
  selector: 'app-digital-vault-licensing-agreement-pro',
  templateUrl: './digital-vault-licensing-agreement-pro.component.html',

})
export class DigitalVaultLicensingAgreementProComponent {
  /**
   * constructor
   */
  constructor(
    private commonHelper: CommonHelper,
  ) {

  }

  /**
   * get current year
   * @return{number}
   */
  public getCurrentYear() {
    return this.commonHelper.currentYear;
  }
}
