<div class="col-span-12 lg:col-span-6">
  <div class="bg-transparent border-card-grey rounded">
    <div class="grid grid-cols-12 p-6 gap-6">
      <div class="col-span-12 sm:col-span-1">
        <div class="rounded-full border grid items-center justify-center h-12 w-12">
          <img src="assets/images/property-details/view-image.svg" class="w-4" alt="">
        </div>
      </div>
      <div class="col-span-12 sm:col-span-11 sm:ml-6">
        <h4 class="text-darkgrey text-lg tracking-wide text-regular">{{userDetails?.['first_name']}}
          {{userDetails?.['last_name']}}
        </h4>
        <p class="text-label-grey text-sm text-regular font-normal tracking-wide">Added you as
          <span class="text-grey font-semibold">
            {{userDetails?.['relationship']}} | {{userDetails?.['peopleType']}}
          </span>
        </p>
      </div>
      <div class="col-span-12 md:col-span-4">
        <h4 class="text-blue-status font-semibold text-xl tracking-wide text-regular">
          {{userDetails?.['bankBalance']}}
        </h4>
        <p class="text-label-grey text-sm text-regular tracking-wide">Bank Balance
        </p>
      </div>
      <div class="col-span-12 md:col-span-4">
        <h4 class="text-yellow font-semibold text-xl tracking-wide text-regular">
          {{userDetails?.['vehicleValue']}}
        </h4>
        <p class="text-label-grey text-sm text-regular tracking-wide">Vehicle Value
        </p>
      </div>
      <div class="col-span-12 md:col-span-4">
        <h4 class="text-red font-semibold text-xl tracking-wide text-regular">
          {{userDetails?.['propertyValue']}}
        </h4>
        <p class="text-label-grey text-sm text-regular tracking-wide">Property Value
        </p>
      </div>

      <div class="col-span-12 sm:col-span-6">
        <h4 class="text-darkgrey text-sm tracking-wide text-regular">Status
        </h4>
        <p class="{{userDetails?.['statusColor']}} text-sm font-semibold text-regular tracking-wide">
          {{userDetails?.status}}
        </p>
      </div>
      <div class="col-span-12 sm:col-span-6 sm:ml-auto">
        <app-button-loader [buttonText]="userDetails?.['btnName']" [valid]="userDetails?.['btnEnabled']"
          [loader]="false" buttonType="'button'" [btnTextSize]="'text-sm'"
          (click)="functionMapper[userDetails?.statusCode]()"></app-button-loader>
      </div>
    </div>
  </div>
</div>
<app-verify-departed></app-verify-departed>
<app-claim-dlb>
</app-claim-dlb>
<app-check-basic-details [phoneCountryCode]="countryCode" [dlbStatus]="dlbStatus"></app-check-basic-details>
