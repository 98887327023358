<app-common-modal [id]="id" class="app-common-modal {{messageSettings.modalClass}}">
  <div class="relative">
    <img *ngIf="this.messageSettings.showExit" src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel($event)">

    <h2 class="{{this.messageSettings.titleClass}}" [ngClass]="this.messageData?.title?.class"
      [innerHtml]="this.messageData?.title?.content">
    </h2>

    <p *ngFor="let message of this.messageData?.message" class="{{this.messageSettings.messageClass}}"
      [ngClass]="message.class" [innerHtml]="message.content">
    </p>


    <div class="flex items-center gap-4 mt-4" [ngClass]="MMButtonAlignment[this.messageSettings.buttonAlignment]">
      <button *ngFor="let button of this.messageData?.buttons" class="{{this.messageSettings.buttonClass}} "
        [ngClass]="button.class" (click)="click(button.emitValue)" [innerHtml]="button.content">
      </button>
    </div>
  </div>
</app-common-modal>