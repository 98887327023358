import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonHelper } from '../helper/common.helper';
import * as moment from 'moment';

@Pipe({
  name: 'dateStringtoDateformat',
})
export class DateStringtoDateformatPipe implements PipeTransform {
  constructor(
    private commonHelper: CommonHelper,
    private datePipe: DatePipe,
  ) {

  }
  transform(value: string): any {
    const arr = [];
    let date = value;
    try {
      date = this.datePipe.transform(date, this.commonHelper.domainViewDateFormat + ', h:mm a');
      if (date == 'Invalid date') {
        date = value;
      }
    } catch (e) {
    }
    return date;
  }
}
