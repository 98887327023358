<app-common-modal id="payment-success" *ngIf="!isLoginUrl" class="app-common-modal">
    <div class="relative">
        <img src="assets/images/about-yourself/close.svg" class="absolute right-2 w-8 -mr-6 -mt-4 cursor-pointer" alt=""
            (click)="closeModal()">
        <div class="w-full sm:p-6">
            <div class="ml-auto mr-auto">
                <img src="assets/images/payment/payment-success.svg" alt="payment-success" class="mb-5 w-40 h-40 ml-auto mr-auto">
                <h3 class="text-darkblue text-semibold text-center text-lg mb-4">{{headerMessage}}
                </h3>
                <p class="text-md text-grey py-1 tracking-wide text-regular text-center">Thank you for your payment.
                    Payment receipt will be sent
                    to your registered email. </p>
            </div>
        </div>
    </div>
</app-common-modal>
