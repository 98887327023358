import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProfessionalNavbarComponent } from './professional-navbar.component';
import {SharedModule} from "../../../../sharedComponent/shared.module";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [ProfessionalNavbarComponent],
  imports: [CommonModule, RouterModule, SharedModule, FontAwesomeModule],
  exports: [ProfessionalNavbarComponent],
})
/**
 * nav bar module to reuse across component
 */
export class ProfessionalNavBarModule {}
