import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * chart data
 */
export class ChartsService {
  private BASE_URL: string;
  /**
 * constructor
 */
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.BASE_URL;
  }
  /**
 * getting bar chart data for dashboard page
 * @return {Observable}
 */
  public getBarChartDataDashboard(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/client-bar-chart`);
  }
  /**
 * getting bar chart data for agents page
 * @return {Observable}
 */
  public getBarChartDataAgents(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent-bar-chart?type=advisor`);
  }

  /**
 * getting bar chart data for managers page
 * @return {Observable}
 */
  public getBarChartDataManagers(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent-bar-chart?type=manager`);
  }

  /**
   * getting bar chart data all professionals under company
   * @returns {Observable} 
   */
  public getBarChartDataCompany(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent-bar-chart`);
  }
}
