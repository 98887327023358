import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ICountryCode } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { EMAIL_PATTERN, PHONE_PATTERN } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-phone-email',
  templateUrl: './phone-email.component.html',
  styleUrls: ['./phone-email.component.css'],
})
/**
 * Phone Email component
 */
export class PhoneEmailComponent implements OnInit {
  public contactDetailForm: FormGroup;
  public submitLoader: boolean;
  public userEmail: string;
  public preDefinedLoader: boolean;
  public sectionSaveExitOptions: any;
  public countryCode: ICountryCode; //  in future should be embeded with form itself
  public userType: string;
  public requestId: string;
  public userMail: string;
  public server: number;


  /**
   * @constructor
   */
  constructor(
    private form: FormBuilder,
    private personalDetailsService: PersonalDetailsService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private slugInterceptorService: SlugInterceptorService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.server = environment.APP_DOMAIN;
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    this.sectionSaveExitOptions = {
      section: 1,
      type: 3,
    };
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.preDefinedLoader = false;
    this.getPersonalDetails();
    this.userEmail = this.localStorageService.getDataByKey('consumer-email') || this.localStorageService.getDataByKey('user')?.user?.email || this.localStorageService.getDataByKey('consumer-email');
    // Initiating form
    this.contactDetailForm = this.form.group({
      phone: ['', [Validators.required, Validators.pattern(PHONE_PATTERN)]],
      email: ['', {updateOn: 'blur',validators:[Validators.required, Validators.pattern(EMAIL_PATTERN)]}],
    });
  }

  /**
   * Get personal details
   */
  public getPersonalDetails(): void {
    this.preDefinedLoader = true;
    // get children list
    if (this.userType === 'Consumer') {
      this.personalDetailsService.getPersonalDetails().subscribe((response: APIResponseModel) => {
        this.preDefinedLoader = false;
        if (response.status) {
          this.sectionSaveExitOptions.id = response.data.user_id;
          let tempPhoneNumber;
          if (response?.data?.user.phone) {
            tempPhoneNumber = response?.data?.user.phone;
          }
          if (response?.data?.user.country_code) {
            this.countryCode = {code:response?.data?.user?.flag_code,dialCode:response?.data?.user?.country_code}
          }
          this.userMail = response?.data?.user.email;
          console.log(this.userMail);
          this.contactDetailForm.patchValue({
            email: this.userMail,
            phone: tempPhoneNumber,
          });
        }
      }, () => {
        this.preDefinedLoader = false;
      });
    } else {
      this.personalDetailsService.getPersonalDetails(this.requestId).subscribe((response: APIResponseModel) => {
        this.preDefinedLoader = false;
        if (response.status) {
          this.sectionSaveExitOptions.id = response.data.user_id;
          if (response?.data?.user?.country_code) {
            this.countryCode = {code:response?.data?.user?.flag_code,dialCode:response?.data?.user?.country_code}
          }
          this.userMail = response?.data?.user?.email;
          this.contactDetailForm?.patchValue({
            email: this.userMail,
            phone: response?.data?.user?.phone,
          });
        }
      }, (exception: any) => {
        this.preDefinedLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.contactDetailForm?.controls;
  }

  /**
   * On submit
   */
  public onSubmit(): void {
    this.contactDetailForm.markAllAsTouched();
    if(!this.contactDetailForm.valid) return; // return if the form is invalid
    this.submitLoader = true;
    this.contactDetailForm.value.email = this.userEmail;
    if (this.userType === 'Consumer') {
      const payLoad = {
        ...this.contactDetailForm.value,
        email: this.userMail,
        roletype: this.userType,
        country_code: this.countryCode.dialCode,
        flag_code: this.countryCode.code,
      };
      console.log(payLoad);
      this.personalDetailsService.storePersonalDetails({ ...payLoad }, 3).subscribe((response: APIResponseModel) => {
        this.submitLoader = false;
        if (response.status) {
          this.commonHelper.updateLocalstorageRequestStage(response.data);
          void this.slugInterceptorService.navigate(['about-yourself/partner']);
        }
      }, (exception: any) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    } else {
      const payLoad = {
        ...this.contactDetailForm.value,
        email: this.userMail,
        roletype: this.userType,
        request_id: this.requestId,
        country_code: this.countryCode.dialCode,
        flag_code: this.countryCode.code,
      };
      this.personalDetailsService.storePersonalDetails({ ...payLoad }, 3).subscribe((response: APIResponseModel) => {
        this.submitLoader = false;
        if (response.status) {
          void this.slugInterceptorService.navigate([`/about-yourself/partner/${this.requestId}`]);
        }
      }, (exception: any) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    }
  }

  /**
   * Detect change on phone number component
   *
   * @param {any} inputObject
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }


  /**
     * change route
     * @param{string}url
    */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, this.userType !== 'Consumer');
  }

  }
