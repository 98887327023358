import { Component, OnInit } from '@angular/core';
import { AssetsService } from 'src/app/services/assets.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-account-list-popup',
  templateUrl: './account-list-popup.component.html'
})
/**
 * Account list popup component
 */
export class AccountListPopupComponent implements OnInit {
  public searchKeyword:string;
  public accountList:Array<any>;
  public faSearch:any;
  /**
   * @constructor
   */
  constructor(
    private modalService:CommonModelService,
    private assetsService:AssetsService,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.faSearch = faSearch;
    // listen for list change
    this.assetsService.accountListObserve.subscribe((accountList:Array<any>)=>{
      if(!accountList || accountList.length === 0) return;
      this.accountList = accountList;
    })
  }

  /**
   * close model
   */
  public closeModel():void{
    this.modalService.close('account-list-popup');
  }

}
