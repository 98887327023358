import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'peopleListFilter',
})
/**
 * People list filter executor/ benificiary
 */
export class PeopleListFilterPipe implements PipeTransform {
  /**
   * transform function
   *
   * @param {any} value
   * @param {any} args args 1 is for strictly matching beneficiary to 1 or 2 ( default undefined, activated when sent 1 )
   * @return {any}
   */
  transform(value: any, ...args: any[]): any {
    return value.filter((data:any)=> {
      return (data?.beneficiary === args[0]) && !args[1];
    });
  }
}
