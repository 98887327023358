import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { USER_TYPES } from '../../constants/application.const';
import { ExecutorCustodianService } from '../executor-custodian/services/executor-custodian.service';
import { APIResponseModel } from '../interface/response.interface';
import { IdleService } from '../services/idle.service';
import { LocalStorageService } from '../services/local-storage.service';
import { SessionStorageService } from '../services/session-storage.service';
import { SlugInterceptorService } from './slug-interceptor.service';


@Injectable({ providedIn: 'root' })
/**
 * @class AuthGuardUser
 * @description for protecting routers when user is not logged in
 */
export class AuthGuardExePro implements CanActivate {
  /**
   * @constructor
   */
  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private idleService: IdleService,
    private executorCustodianService: ExecutorCustodianService,
    private slugInterceptorService: SlugInterceptorService,
  ) {
  }

  /**
   * @method canActivate
   * @description method to check if router can render or not
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return {Boolean}
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const consumer = this.localStorage.getUserData();

    if (this.checkUser(USER_TYPES.exe) || this.checkUser(USER_TYPES.pro)) {
      this.checkUser(USER_TYPES.exe) && this.checkSecurityQuestionsStatus();
      this.idleService.reset();
      return true;
    }
    if (consumer) {
      void this.router.navigate(['unauthorized']);
    } else {
      if (route?.parent?.params?.slug) {
        void this.slugInterceptorService.navigateByUrl('login', null, true);
      } else if (route?.params?.id && route?.url[0].path == 'dlb-dashboard') {
        // to check if executor custodian is logged in or not in exe custodian dashboard received dlb flow
        this.localStorage.storeData('redirect-url', route?.params.id);
        void this.slugInterceptorService.navigate(['executor-custodian/login']);
      } else {
        void this.slugInterceptorService.navigate(['login'], null, true);
      }
    }
    return false;
  }

  /**
   * Checks if essential data, session, and login cookie exist for a given user.
   * @returns {boolean} True if all required data is present, otherwise false.
   */
  public checkUser(usertype: string) {
    const userData = this.localStorage.getUserData(usertype);
    const session = this.sessionStorage.getDataFromSession(usertype);
    const userCookie = this.localStorage.getLoginCookie(usertype);
    return userData && session && userCookie;
  }

  /**
   * Checks the status of security questions for the current user
   */
  public checkSecurityQuestionsStatus() {
    this.executorCustodianService.checkSecurityQuestionsStatus().subscribe({
      next: (response: APIResponseModel) => {
        this.localStorage.storeData('notify_companies', response.data['notify_companies']);
        const securityQuestionsStatusResponse = response.data;
        this.executorCustodianService.emitCVStatus(securityQuestionsStatusResponse['access_to_custodian']);
        if (
          securityQuestionsStatusResponse &&
          !securityQuestionsStatusResponse['is_security_questions_set'] &&
          securityQuestionsStatusResponse['access_to_custodian']
        ) {
          void this.slugInterceptorService.navigate(['executor-custodian/security-questions']);
        }
      },
    });
  }
}
