import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { ExeCusNavbarModule } from 'src/app/executor-custodian/pages/exe-cus-navbar/exe-cus-navbar.module';
import { ProfessionalNavBarModule } from 'src/app/professional-dashboard/components/professional/professional-navbar/professional-navbar.module';
import { SharedModule } from 'src/app/sharedComponent/shared.module';
import { ComponentsRoutingModule } from '../components-routing.module';
import { NavBarModule } from '../navbar/navbar.module';
import { AboutYourselfRoutingModule } from './about-yourself-routing.module';
import { AboutYourselfComponent } from './about-yourself.component';
import { AddChildrenModelComponent } from './children/add-children-model/add-children-model.component';
import { ChildrenStatusComponent } from './children/children-status.component';
import { LocationDetailsComponent } from './location-details/location-details.component';
import { NamingDetailsComponent } from './naming-details/naming-details.component';
import { PartnerComponent } from './partner/partner.component';
import { PhoneEmailComponent } from './phone-email/phone-email.component';
import { RightContentComponent } from './right-content/right-content.component';

@NgModule({
  declarations: [
    AboutYourselfComponent,
    ChildrenStatusComponent,
    AddChildrenModelComponent,
    LocationDetailsComponent,
    NamingDetailsComponent,
    PartnerComponent,
    PhoneEmailComponent,
    RightContentComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NavBarModule,
    ProfessionalNavBarModule,
    AboutYourselfRoutingModule,
    SharedModule,
    BsDatepickerModule,
    NgxMaskModule.forChild(),
    ToastrModule,
    NgIdleKeepaliveModule,
    DirectivesModule,
    ComponentsRoutingModule,
    FontAwesomeModule,
    ExeCusNavbarModule,
  ],
  exports: [
    AboutYourselfComponent,
    ChildrenStatusComponent,
    AddChildrenModelComponent,
    LocationDetailsComponent,
    NamingDetailsComponent,
    PartnerComponent,
    PhoneEmailComponent,
    RightContentComponent,
  ],
})
/**
 * about yourself module
 */
export class AboutYourselfModule { }
