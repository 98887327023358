<div class="scroll-hidden h-full">
  <!-- Navbar -->
    <app-navbar></app-navbar >
  <div class="background-color-grey h-full">
    <div class="lg:grid grid-cols-12 p-8 sm:p-12 lg:px-24 lg:p-8">
      <div class="col-span-12">

        <div class="my-4 flex ">
          <div class="flex items-center">
            <img src="assets/images/dashboard/support-img1.png" class="w-16 h-16 object-contain -mr-2" alt="digital vault">
            <img src="assets/images/dashboard/support-img2.png"
              class="w-20 h-20 object-contain z-10 border-white rounded-full" alt="digital vault">
            <img src="assets/images/dashboard/support-img3.png" class="w-16 h-16 object-contain -ml-2" alt="digital vault">
          </div>
        </div>

        <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
          We are your dedicated support team.
        </h2>
        <p class="text-base text-grey py-2 tracking-wide text-regular">We can answer basic questions about
          your Will-Based Estate Plan.

          We’re available: M-F, 8 am-5 pm PST, S-Sun, 9 am-1 pm PST</p>

      </div>
    </div>

    <div class="grid grid-cols-12 px-8 lg:px-24 gap-6 md:gap-12">
      <div class="col-span-12 lg:col-span-4 grid  bg-white border-card-grey rounded p-6">

        <div class="mb-4">
          <img src="assets/images/support/chat.svg" width="100" alt="digital vault">
        </div>
        <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
          Chat With Us
        </h4>
        <p class="text-grey text-regular pt-2 text-base">We’re here to help. We typically
          respond in less than 60 seconds
          during normal business hours.
        </p>
        <div>
          <button type="button"
            class="button button--lg mt-5 w-full text-sm sm:w-auto bg-transparent border-red rounded-xs h-10 focus:outline-none text-regular font-semibold tracking-wide text-lightblue px-6">
            Chat With Us</button>
        </div>

      </div>
      <div class="col-span-12 lg:col-span-4 grid bg-white border-card-grey rounded p-6">

        <div class="mb-4">
          <img src="assets/images/support/email.svg" width="100" alt="digital vault">
        </div>
        <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
          Email Us
        </h4>
        <p class="text-grey text-regular text-base">Have a specific question or issue?
          Email us anytime. We’ll respond
          within 1 business day.
        </p>
        <div class="flex items-center">
          <button type="button"
            class="button button--lg w-full text-sm sm:w-auto bg-transparent border-red rounded-xs h-10 focus:outline-none text-regular font-semibold tracking-wide text-lightblue px-6">
            Email Us</button>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-4 bg-white border-card-grey rounded p-6">

        <div class="mb-4">
          <img src="assets/images/support/call.svg" width="100" alt="digital vault">
        </div>
        <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
          Schedule a Call
        </h4>
        <p class="text-grey text-regular pt-2 text-base">Talk with our customer support
          team for quick answers to basic
          questions or technical support.
        </p>
        <div>
          <button type="button"
            class="button button--lg mt-5 w-full text-sm sm:w-auto bg-transparent border-red rounded-xs h-10 focus:outline-none text-regular font-semibold tracking-wide text-lightblue px-6">
            Schedule a Call</button>
        </div>
      </div>
    </div>
  </div>
</div>