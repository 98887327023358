import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { ICountryCode, SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { APARTMENT_NUMBER_PATTERN, DATE_FORMAT, EMAIL_PATTERN, LIST_DELETE, LIST_NEW, LIST_UPDATE, MODAL_CLOSE, NAME_PATTERN, PHONE_PATTERN, SSN_MASK, SSN_PATTERN, UNITED_KINGDOM } from 'src/constants/application.const';
import { ELEMENT_EVENTS, STATUS_CHECK } from 'src/constants/form.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../../helper/validation.helper';


@Component({
  selector: 'app-add-executor',
  templateUrl: './add-executor.component.html',
})
/**
 * Add / edit executor modal
 */
export class AddExecutorComponent implements OnInit {
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;
  @Input() toggleModal: boolean;
  @Output() toggleModalEmitter = new EventEmitter<Object>();
  public readonly selectedCountry = environment.APP_DOMAIN;
  public readonly SSN_MASK = SSN_MASK;
  public readonly showSSN = environment.APP_DOMAIN == 1;
  public readonly faEye = faEye;
  public readonly faEyeSlash = faEyeSlash;
  public searchElementRef: ElementRef;
  public elementRefSet: boolean = false;
  public relationshipList: SelectOptionsInterface[] = [];
  public submitLoader = false;
  public preDefinedLoader = false;
  public isExecutor = true;
  public toggleSSNView = { ssn: false, v_ssn: false };
  public executorForm: FormGroup;
  public currentDateRestriction: string;
  public countryCode: ICountryCode;
  public userType: string;
  public requestId: string;
  public locale = 'en';
  public minDate: Date;
  public maxDate: Date;
  public bsConfig: Partial<BsDatepickerConfig>;
  public defaultCountry: number;
  public submitLoaderDelete: boolean;
  public selectedCountryName: string = '';
  public unitedKingdom: string = UNITED_KINGDOM;
  public domainDateFormat: any;
  public isEditing: boolean;
  public validatingSSN: number = ssnValidationProcess.initial;
  public isUniqueSSN: boolean = false;
  public userDetails: any;
  public personId: any;
  public toggleEmiterListener: boolean = false; // toggle listener
  public isFormDisable: boolean;

  // @ViewChild('search', { static: false }) public searchElementRef: ElementRef;
  /**
   * @param{ElementRef}value
   */
  @ViewChild('search', { static: false })
  set setSearchElementRef(value: ElementRef) {
    this.searchElementRef = value;
    if (this.searchElementRef && !this.elementRefSet) { // if not elementRef set initial value for searchElement
      this.initMap(this.defaultCountry);
      this.elementRefSet = true;
    }
  }

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private peopleService: PeopleService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private localeService: BsLocaleService,
    private addressHelper: AddressHelper,
    private ngZone: NgZone,
    private validation: ValidationHelper,
  ) {
    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18); // Minimum 18 years old
    this.minDate = new Date(1900, 0, 1);
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.domainDateFormat = this.commonHelper.domainDateFormat;
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    this.userDetails = this.localStorageService.getUserData();
    this.initMap(this.defaultCountry);
    this.getRelationshipList();
    this.bsConfig = Object.assign({}, {
      showWeekNumbers: false,
      minDate: this.minDate,
      maxDate: this.maxDate,
      dateInputFormat: this.domainDateFormat,
    });
    this.applyLocale();
    this.initForm();
    /**
     * Listen for current modal
     */
    this.commonService.currentModalStatusObservable.subscribe((status: string) => {
      if (status === MODAL_CLOSE) {
        this.resetForm();
        this.removePacContainer();
        this.initMap(this.defaultCountry);
      }
    });
  }

  private initForm() {
    this.executorForm = this.formBuilder.group({
      first_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      middle_name: ['', { updateOn: 'blur', validators: [Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      last_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      define_others: [''],
      dob: ['', { updateOn: 'blur', validators: [Validators.required, this.validation.fn.dateRange(this.minDate, this.maxDate)] }],
      ssn: ['', { updateOn: 'blur', validators: [this.validation.fn.SSNPatternValidator(SSN_PATTERN)] }],
      ssn_validation: [''],
      phone: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(PHONE_PATTERN)] }],
      email: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(EMAIL_PATTERN)] }],
      relationship: ['', [Validators.required]],
      address: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(255)] }],
      apt_number: ['', { updateOn: 'blur', validators: [Validators.pattern(APARTMENT_NUMBER_PATTERN), Validators.maxLength(25)] }],
      city: ['', { updateOn: 'blur', validators: [Validators.required] }],
      country: ['', { updateOn: 'blur', validators: [Validators.required] }],
      state: [''],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(10)] }],
      id: [''],
      executor: ['1'],
    });
    this.validation.setValidationErrors({
      dob: { dateInvalid: null, dateHigh: 'Must be at least 18 years of age', dateLow: 'Invalid Date' },
      ssn: { pattern: 'Invalid SSN', mask: null },
      phone: { pattern: 'Please enter a valid phone number' },
      email: { pattern: 'Please enter a valid email address.' },
      zipcode: { pattern: 'Please enter a valid zipcode' }
    });

    const trimWhitespaceControls = ['first_name', 'middle_name', 'last_name', 'address', 'apt_number', 'city', 'zipcode', 'email'];
    
    trimWhitespaceControls.forEach(controlName => {
      this.executorForm.controls[controlName].valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.executorForm.controls[controlName])
      );
    });

    this.isEnableState();
    this.resetForm();
  }

  /**
   * Detect change on phone number component
   *
   * @param event
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlsKey
   */
  public getErrors(controlsKey: string): Array<string> {
    return this.validation.getErrors(controlsKey, this.executorForm);
  }

  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }

  /**
   * get Age
   * @param {string}date
   * @return {number}
   */
  public getAge(date: string) {
    return this.commonHelper.getAge(date);
  }

  /**
   * Get relationship list
   */
  public getRelationshipList(): void {
    this.commonService.getRelationshipList().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.relationshipList = this.commonHelper.convertToOptionsFormat(response.data, 'id', 'name');
        this.commonHelper.placeAtPointOfArray(this.relationshipList, (e) => e.displayText.toLowerCase() === 'other');
        if (this.relationshipList[0]) {
          this.executorForm.patchValue({
            relationship: this.relationshipList[0].value,
          });
        }
      }
    });
  }

  /**
   * Setter for edit values in executor
   *
   * @param {any} data
   */
  @Input() set editData(data: any) {
    if (data && data?.id) {
      this.resetForm();
      this.isFormDisable = data.people_id != null;
      this.isEditing = true;
      this.isExecutor = true;
      let tempPhoneNumber;
      if (data?.phone) {
        tempPhoneNumber = data?.phone;
        this.countryCode = { code: data?.flag_code, dialCode: data?.country_code };
      }

      // update other roles from executor details
      if (!(data['advisor'] !== 1 && data['beneficiary'] !== 1 && data['relation'] !== 1 && data['relation'] !== 2)) {
        this.isExecutor = false;
        data['executor'] = 1;
      }
      this.executorForm.patchValue({
        ...data,
        phone: tempPhoneNumber,
        city: data?.city?.id,
        country: data?.country?.id,
        state: data?.state?.id,
        relationship: data?.people_relation?.id,
        dob: this.commonHelper.formatDate(data?.dob, this.domainDateFormat),
        ssn_validation: data.ssn,
      });
      this.removePacContainer();
      this.initMap(data?.country?.id);
      this.personId = data?.id;
    } else {
      this.isEditing = false;
      this.personId = this.userDetails?.request?.id; // set request id if partner adding initially for UniqueSSN Validation
    }

    if (this.executorForm?.controls?.ssn?.value && this.checkSSNMatch()) { // check is SSN unique
      this.checkIsSsnUnique(this.executorForm.controls.ssn.value, this.personId);
    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.executorForm.controls;
  }

  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }

  /**
   * add executor function
   */
  public onSubmit(): void {
    this.executorForm.value.dob = this.commonHelper.formatDate(this.executorForm.value.dob, DATE_FORMAT);

    const errorFn = (exception: any) => {
      this.submitLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    };

    const payLoad = {
      ...this.executorForm.value,
      country_code: this.countryCode.dialCode,
      flag_code: this.countryCode.code,
      roletype: this.userType,
      is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
    };
    payLoad.state = this.selectedCountryName == this.unitedKingdom ? null : payLoad.state;
    if (this.executorForm.invalid) {
      console.log(this.executorForm);
      this.commonHelper.scrollToFirstInvalidControl(this.executorForm);
      this.executorForm.markAllAsTouched();
      return;
    }
    payLoad?.id && delete payLoad.executor;
    this.submitLoader = true;
    this.commonService.getCityDetail(payLoad.country, payLoad.state, payLoad.city).subscribe((cResponse: APIResponseModel) => {
      if (cResponse?.status) {
        payLoad.city = cResponse?.data?.id;
        this.peopleService.storePeopleDetails(payLoad).subscribe({
          next: (response: APIResponseModel) => {
            if (response.status) {
              this.toggleModalEmitter.emit({
                ...response.data,
                listStatus: (this.executorForm.value.id) ? LIST_UPDATE : LIST_NEW,
              });
              this.peopleService.refreshDetailList(response.data);
              if (this.userType === 'Consumer') {
                this.commonHelper.updateLocalstorageRequestStage(response.data);
                this.executorForm.value.id ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();
              }
              this.submitLoader = false;
              this.closeModel();
            }
          },
          error: errorFn,
        });
      }
    }, errorFn);
  }

  /**
   * Auto hypen SSN keyup
   */
  public autoHypenSSN(): void {
    this.executorForm.patchValue({
      ssn: this.commonHelper.autoHypenSSN(this.executorForm.value.ssn),
    });
  }

  /**
   * Initialize google map api by country
   * @param {any} countryId
   */
  public initMap(countryId: any): void {
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: (r) => {
        this.ngZone.run(() => {
          this.executorForm.patchValue(r);
        });
      },
    });
  }

  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.executorForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }

  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry) return;
    this.executorForm.controls.state.reset();
    this.executorForm.patchValue({
      country: selectedCountry.value,
    });
    this.removePacContainer();
    this.initMap(selectedCountry.value);
  }

  /**
   * remove pac container
   *
   */
  private removePacContainer() {
    this.addressHelper.removeAutocompletePacContainer();
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.executorForm.controls.city.reset();
    this.executorForm.patchValue({
      state: selectedState.value,
    });
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.executorForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.executorForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(selectedCity?.additionalDetails[0]?.postal_code_regex))]);
      zipCodeRef.updateValueAndValidity();
    }

    // this loader is set false here because of the delay to set country state city
    this.preDefinedLoader = false;
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.modalService.close('add-executor-modal');
    this.toggleModalEmitter.emit({ closeModal: true, edit: this.isEditing });
    this.resetForm();
  }

  /**
   * Option change emitter relationship
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitterRelationship(selectedOption: SelectOptionsInterface): void {
    if (selectedOption?.value) {
      this.executorForm.patchValue({
        relationship: selectedOption?.value,
      });
      if (this.useOther) {
        this.formGet['define_others'].addValidators([Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.pattern(NAME_PATTERN)]);
        this.formGet['define_others'].updateValueAndValidity();
      } else {
        this.formGet['define_others'].clearValidators();
        this.formGet['define_others'].updateValueAndValidity();
      }
      if (this.toggleEmiterListener) {
        this.executorForm.get('relationship').markAsUntouched();
        this.toggleEmiterListener = false;
      }
    } else {
      if (this.toggleEmiterListener) {
        this.executorForm.get('relationship').markAsTouched();
        this.toggleEmiterListener = false;
      }
    }
  }

  /**
   * Whether the 'Other' field is being used.
   */
  get useOther(): boolean {
    if (this.formGet) {
      return this.formGet.relationship.value ===
        this.relationshipList.find((e) => e.displayText.toLowerCase() === 'other')?.value;
    }
    return false;
  }

  /**
   * Reset form
   */
  public resetForm(): void {
    this.executorForm?.reset({
      executor: '1',
      country: this.defaultCountry,
    });
    this.isFormDisable = false;
    this.elementRefSet = false;
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    this.commonHelper.scrollToTopModal(this.executorForm);
  }

  /**
   * delete executor
   */
  public deleteExecutor(): void {
    this.submitLoaderDelete = true;
    if (this.userType === 'Consumer') {
      this.peopleService.storePeopleDetails({
        is_delete: '1',
        id: this.executorForm.value.id,
        roletype: this.userType,
      }).subscribe((response: APIResponseModel) => {
        this.submitLoaderDelete = false;
        if (response.status) {
          this.closeModel();
          this.toggleModalEmitter.emit({
            ...response.data,
            listStatus: LIST_DELETE,
          });
          this.commonHelper.toastrDeleteSuccess();
          this.peopleService.refreshDetailList(response.data);
        }
      }, (exception: any) => {
        this.submitLoaderDelete = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    } else {
      this.peopleService.storePeopleDetails({
        is_delete: '1',
        id: this.executorForm.value.id,
        roletype: this.userType,
        request_id: this.requestId,
      }).subscribe((response: APIResponseModel) => {
        this.submitLoaderDelete = false;
        if (response.status) {
          this.closeModel();
          this.toggleModalEmitter.emit({
            ...response.data,
            listStatus: LIST_DELETE,
          });
        }
      }, (exception: any) => {
        this.submitLoaderDelete = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    }
  }

  /**
   * getter for state
   */
  get state() {
    return this.executorForm.get('state');
  }

  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.executorForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ?
        this.state.addValidators([Validators.required]) : this.state.clearValidators();
      this.state.updateValueAndValidity();
    });
  }

  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.executorForm.get('zipcode');
    const stateId = this.executorForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        if (response.data.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(zipCodeRegex))]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        } else {
          zipCodeRef.setValidators([Validators.required]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }

  /**
   * getter for ssn validation status
   */
  public get checkSSNValidation(): boolean {
    return this.validatingSSN !== 1 && (this.executorForm.controls.ssn.value ? this.isUniqueSSN : !this.executorForm.controls.ssn_validation.value);
  }

  /**
   *
   * @return{boolean}
   */
  public checkSSNMatch(): boolean {
    return this.executorForm.controls['ssn'].value == this.executorForm.controls['ssn_validation'].value || (this.executorForm.controls['ssn'].value == null && this.executorForm.controls['ssn_validation'].value == '') || (this.executorForm.controls['ssn'].value == '' && this.executorForm.controls['ssn_validation'].value == null);
  }

  /**
   * Whether it is a new executor or not
   */
  get isNew(): boolean {
    return !this.executorForm['controls']['id'].value;
  }

  /**
   * typing / onchange input field
   * changed as untouched field
   * not show validation error
   * @param {any} data
   * @param {string} key
   */
  public onChange(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.INPUT) {
      this.executorForm.get(key).markAsUntouched();
      this.validatingSSN = ssnValidationProcess.initial;
    }
  }

  /**
   * focusout of input field
   * changed as touched field
   * check and show validation error
   * @param {any} data
   * @param {string} key
   */
  public onFocusout(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.FOCUSOUT) {
      this.executorForm.get(key).markAllAsTouched();
    }
    const isSSNValid = this.executorForm.controls.ssn.value ? this.executorForm.controls.ssn.status == STATUS_CHECK.VALID : false;
    if (isSSNValid && this.checkSSNMatch()) { // if SSN status valid and ssn and confirmation ssn are same
      this.checkIsSsnUnique(this.executorForm.controls.ssn.value, this.personId);
    }
  }

  /**
   * check ssn is unique
   * @param {string} data
   * @param id
   */
  public checkIsSsnUnique(data: string, id: string) {
    this.validatingSSN = ssnValidationProcess.start_validation;
    this.commonService.getIsSSNUnique(data, id, this.commonHelper.isShowSSNToast()).subscribe({
      next: (r) => {
        this.isUniqueSSN = r;
        this.validatingSSN = ssnValidationProcess.end_validation;
      },
      error: () => {
        this.validatingSSN = ssnValidationProcess.end_validation;
      },
    });
  }

  /**
   * toggle emitter
   * @param {boolean} data
   */
  public toggleEmitter(data: boolean) {
    if (data) {
      this.toggleEmiterListener = true; // toggle listener
    }
  }

  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }
}


/**
 * enum for ssn validation processing
 */
enum ssnValidationProcess {
  initial,
  start_validation,
  end_validation
}
