import { HttpEventType } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { FileValidationOptionsInterface, SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { ProfessionalPermissionService } from 'src/app/professional-dashboard/services/professional-permission.service';
import { CommonService } from 'src/app/services/common.service';
import { DigitalFilesService } from 'src/app/services/digital-files.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { CLEAR_SELECTED_FILE } from 'src/constants/digitalFile.const';
import { ALPHABET_ONLY_PATTERN, SECTIONS, SectionStatus, USER_TYPES } from '../../../constants/application.const';
import { FileListingEmitModel, FileUploadSettingsModel } from '../../sharedComponent/file-listing/file-listing.component';
import { ConsumerSubscriptionService } from '../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-will-files',
  templateUrl: './will-files.component.html',
})
/**
 * Will files component
 */
export class WillFilesComponent implements OnInit {
  public file: any;
  public preDefinedLoader: boolean;
  public willFilesArray: Array<any>;
  public fileUploadSettings: FileUploadSettingsModel;
  public filePercentage: number;
  public selectedFiles: any;
  public fileValidationOptions: FileValidationOptionsInterface;
  public imageFormatFiltering: Array<string>;
  public downloadLink: string;
  public downloadFileName: string;
  public sectionSaveExitOptions: any;
  public hideDeleteIcon: boolean;
  public userType: string;
  public clientRequestId: string;
  public PIC_URL: string;
  public documentTypes: Array<SelectOptionsInterface>;
  public documentForm: FormGroup;
  public userData: object;
  public hasAttemptedUpload: boolean;
  public permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  /**
   * True signifies hiding the 'save' buttons and showing the 'progress' buttons
   */
  public buttonProgress: boolean = false;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public isFileDropWhenNoDocumentSelected: boolean = false;
  public toggleEmitterListener: boolean = false; // toggle listener
  hasPaidSubscription: boolean;
  public userRequestID: string;
  public clientHasPaidSubscription: boolean;


  /**
   * @constructor
   */
  constructor(
    private digitalFileService: DigitalFilesService,
    private localStorageService: LocalStorageService,
    private commonHelper: CommonHelper,
    private personalDetailsService: PersonalDetailsService,
    private commonService: CommonService,
    private toastService: ToastrService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private proPermissionService: ProfessionalPermissionService,
    private slugInterceptorService: SlugInterceptorService,
    private subscriptionService: ConsumerSubscriptionService,
  ) {
  }

  @HostListener('window:beforeunload')
  beforeUnloadHandler() {
    return this.filePercentage == 0 || this.filePercentage == 100;
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.hasPaidSubscription = r);
    // file validation options
    this.fileValidationOptions = {
      size: 100000,
      fileFormat: ['jpeg', 'jpg', 'png', 'doc', 'docx', 'txt', 'pdf', 'mp3', 'mp4', 'avi', 'mov', 'flv', 'mkv', 'x-flv', 'quicktime', 'x-msvideo', 'vnd.openxmlformats-officedocument.wordprocessingml.document', 'plain', 'mpeg', 'msword', 'x-matroska'],
    };
    this.fileUploadSettings = {
      fileValidationOptions: this.fileValidationOptions,
      hideFileOperations: true,
      allowMultiple: true,
      isDigitalFiles: true,
      hasDocumentType: true,
    };
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.sectionSaveExitOptions = {
      section: 16,
      type: 1,
    };
    this.clientRequestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.userRequestID = this.commonHelper.getRequestId(this.route);
    this.hideDeleteIcon = false;
    this.downloadLink = '';
    this.downloadFileName = '';
    // Creating relationship form
    this.documentForm = this.formBuilder.group({
      document_type: ['', { updateOn: 'blur', validators: [Validators.required] }],
      document_type_other: ['', { updateOn: 'blur', validators: [Validators.minLength(3), Validators.maxLength(50), Validators.pattern(ALPHABET_ONLY_PATTERN)] }],
    });
    this.imageFormatFiltering = ['jpeg', 'jpg', 'png'];
    this.filePercentage = 0;

    this.commonService.fileHanlderObservableEvents({
      type: CLEAR_SELECTED_FILE,
    });
    this.getDocumentTypes();
    this.getPersonalDetails();
    this.getAccessControl();
    // Get User Data
    this.userData = this.localStorageService.getUserData()?.user;
    if (this.clientRequestId) {
      this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.willFiles, SectionStatus.INCOMPLETE).subscribe();
    }
  }

  /**
   * Establish ACL Permissions. Consumer is assigned full access.
   */
  public getAccessControl(): void {
    if (this.userType !== 'Consumer') {
      const requestId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('request_user_id') : this.userRequestID;
      const userId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('user_id') : this.localStorageService.getDataByKey('agent_id') || this.localStorageService.getUserData(USER_TYPES.pro)?.user?.id;
      const getACLService = this.proPermissionService.getAccessControl(userId, requestId, ['Digital Files']);
      getACLService.subscribe({
        next: (permissions) => {
          console.log(permissions);
          this.permissions = permissions?.['Digital Files'];
        },
        error: (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
      });
    } else {
      this.permissions = {
        view: true,
        add: true,
        edit: true,
        delete: true,
      };
    }
  }

  /**
   * get document types
   */
  public getDocumentTypes() {
    this.preDefinedLoader = true;
    this.commonService.getDocumentTypes().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.documentTypes = response.data.map((data: any) => ({
          value: data?.id,
          displayText: data?.name,
        }),
        );
        this.documentTypes.sort(this.commonHelper.compareDisplayText);
        this.commonHelper.placeAtPointOfArray(this.documentTypes, (e) => e.displayText.toLowerCase() === 'other');
      }
    });
    // this.resetForm();
  }

  /**
   * Get personal details
   */
  public async getPersonalDetails(){
    this.preDefinedLoader = true;
    // get people list
    this.personalDetailsService.getPersonalDetails(this.clientRequestId).subscribe({
      next: async (response) => {
        if (response.status) {
          this.willFilesArray = response?.data?.user_digital_file.filter((d: any) => d.is_will);
          this.includeFileName();
          console.log('IF', this.willFilesArray);
          this.clientHasPaidSubscription = await this.commonHelper.checkClientSubscription(response);
        }
      },
      error: (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
      complete: () => {
        this.preDefinedLoader = false;
        if (this.willFilesArray != null) {
          this.willFilesArray.sort((a, b) => a['file_name'].localeCompare(b['file_name']));
        }
      },
    });
  }

  /**
   * Listen for option change (salutation)
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitter(selectedOption: SelectOptionsInterface): void {
    if (selectedOption?.value) {
      this.documentForm.patchValue({
        document_type: selectedOption?.value,
      });
      if (this.toggleEmitterListener) {
        this.documentForm.get('document_type').markAsUntouched();
        this.toggleEmitterListener = false;
      }
    } else {
      if (this.toggleEmitterListener) {
        this.documentForm.get('document_type').markAsTouched();
        this.toggleEmitterListener = false;
      }
    }
    this.hasAttemptedUpload = false;
    this.isFileDropWhenNoDocumentSelected = false;
  }

  /**
   * File upload change emitter
   *
   * @param {any} data
   */
  public fileUploadChangeEmitter(data: any): void {
    if (data) {
      this.selectedFiles = data;
      const sendingData = {
        is_will: '1',
        is_delete: '0',
        request_id: this.clientRequestId,
        roletype: this.userType,
        document_type_id: this.documentForm.value.document_type,
      };
      if (this.selectedFiles.length > 0) {
        const formData = new FormData();

        for (const file of this.selectedFiles) {
          formData.append('digital_files[]', file);
        }
        // appending every value to form object
        Object.keys(sendingData).forEach((key) => {
          if (!sendingData[key]) return;
          formData.append(key, sendingData[key]);
        });
        this.digitalFileService.storeDigitalFiels(formData).subscribe((event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.filePercentage = Math.round(100 * event.loaded / event.total);
          }
          if (event.type === HttpEventType.Response) {
            this.commonService.fileHanlderObservableEvents({ type: CLEAR_SELECTED_FILE });
            this.commonHelper.updateLocalstorageRequestStage(event.body.data);
            this.getPersonalDetails();
            this.commonHelper.toastrFileUploadSuccess();
            this.resetForm();
            this.hasAttemptedUpload = false;
          }
        }, (exception) => {
          this.filePercentage = 0;
          this.commonService.fileHanlderObservableEvents({ type: CLEAR_SELECTED_FILE });
          this.commonHelper.httpResponseHandler(exception?.error);
        }, () => this.filePercentage = 0);
      } else {
        this.filePercentage = 0;
        this.file = undefined;
        this.commonService.fileHanlderObservableEvents({ type: CLEAR_SELECTED_FILE });
      }
    }
  }

  /**
   * Delete digital files uploaded based on name
   *
   * @param {string} id
   */
  public deleteDigitalFiles(id: string): void {
    this.hideDeleteIcon = !this.hideDeleteIcon;
    const sendingData = {
      is_will: '1',
      is_delete: '1',
      id,
      request_id: this.clientRequestId,
      roletype: this.userType,
    };
    const formData = new FormData();
    // appending every value to form object
    Object.keys(sendingData).forEach((key) => {
      if (!sendingData[key]) return;
      formData.append(key, sendingData[key]);
    });
    // Delete digital files
    this.digitalFileService.storeDigitalFiels(formData).subscribe((event: any) => {
      if (event.type === HttpEventType.Response) {
        this.getPersonalDetails();
        this.toastService.success(event.body.message);
        this.hideDeleteIcon = !this.hideDeleteIcon;
      }
    }, (exception) => {
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
   * reset form
   */
  public resetForm() {
    this.documentForm.reset({
      document_type: '',
    });
    this.commonService.fileHanlderObservableEvents({ type: CLEAR_SELECTED_FILE });
    this.documentForm.markAsUntouched();
    this.getDocumentTypes();
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.documentForm.controls;
  }

  /**
   * Button click handler
   * @param command
   */
  public click(command: string) {
    const isPro = this.userType !== 'Consumer' && this.userType !== 'Custodian';
    const isCustodian = this.userType == 'Custodian';
    switch (command) {
      case 'Back':
        const back_url = ['digital-files'];
        if (isPro || isCustodian) {
          back_url.push(this.clientRequestId);
        }
        void this.slugInterceptorService.navigate(back_url);
        break;
      case 'MarkComplete':
        this.buttonProgress = true;
        this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.willFiles, SectionStatus.COMPLETE).subscribe();
        break;
      case 'SaveForLater':
        this.buttonProgress = true;
        break;
      case 'ReturnToDashboard':
        const return_url = isPro ? ['overview', 'will-digital-files-overview'] : isCustodian ? ['/executor-custodian/dashboard'] : ['dashboard'];
        void this.slugInterceptorService.navigate(return_url);
        break;
    }
  }

  /**
   * use other type
   */
  get useOther(): boolean {
    return this.formGet.document_type?.value ===
      this.documentTypes?.find((e) => e.displayText.toLowerCase() === 'others')?.value;
  }

  /**
   * toggle emitter
   * @param {boolean} data
   */
  public toggleEmitter(data: boolean) {
    if (data) {
      this.toggleEmitterListener = true; // toggle listener
    }
  }

  /**
   * Adds `fileName` key to {@link willFilesArray} items as an alternate display string.
   */
  public includeFileName() {
    for (let i = 0; i < this.willFilesArray.length; i++) {
      let splitName = this.willFilesArray[i].file_name.split('_');
      this.willFilesArray[i]['fileName'] = `${splitName[0]} — ${splitName[1]}`;
    }
  }

  fileListingListener($event: FileListingEmitModel) {
    if ($event.image_delete) {
      const name = $event.image_delete;
      const id = this.willFilesArray.find((e => e.name == name)).id;
      this.deleteDigitalFiles(id);
    }
    if ($event.fileUploadChange) {
      this.fileUploadChangeEmitter($event.fileUploadChange);
    }
  }

  /**
   * @return `true` if {@link documentForm this.documentForm.value?.['document_type']} is defined, else `false`.
   */
  get hasDocumentType(): boolean {
    return !!this.documentForm.value?.['document_type'];
  }
   /**
   * If user can edit existing asset, or add a new asset.
   */
   get canEdit(): boolean {
    return this.commonHelper.canEditSection(true, this.permissions) && (this.hasPaidSubscription || this.clientHasPaidSubscription)
  }
}
