<div class="lg:grid grid-cols-12 background-color-grey">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 my-4">
              <div class="col-span-12 sm:col-span-2">
                  <img src="assets/images/executors/executors.svg"  class="w-20" alt="digital vault">
              </div>


              <div class="col-span-12 sm:col-span-10">
                <div>
                    <h2 class="text-darkblue text-xl break-words sm:text-3xl uppercase text-semibold">
                      {{agentTerm}} Details
                        <a class="inline-block" (click)="openModal('professionalServiceProviders')" title='Click here for help from Aida'>
                          <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                            stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1n text-primary cursor-pointer">
                            <circle cx="12" cy="12" r="10"></circle>
                            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                            <line x1="12" y1="17" x2="12.01" y2="17"></line>
                          </svg>
                        </a>
                    </h2>

                  </div>
                  <!-- <p class="text-sm text-grey tracking-wide text-regular">This bit of information is important, as
                    it helps to make it clear that this is genuinely you. Double-check your spelling and details
                    to be safe.
                  </p> -->
              </div>

            </div>
          </div>
          <!-- BEGIN: Leftside Card -->
          <div class="border-card bg-white rounded-md">
            <!-- BEGIN: Progress Bar -->
            <app-card-progress-bar [progressValue]="'w-1/6'"></app-card-progress-bar>
            <!-- END: Progress Bar -->

            <div class="p-6">
              <!-- <div class="mb-4">
                <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                  Choosing a {{agentTerm}}
                </h4>
                <p class="text-grey text-regular pt-2 text-sm tracking-wide">Beneficiary
                  designations may be separate from beneficiaries of wills:

                </p>

                <ul class="mt-2">
                  <li class="text-grey text-regular pt-2 text-sm tracking-wide"> ➝
                    Will receives some or all of your property when you die </li>

                  <li class="text-grey text-regular pt-2 text-sm tracking-wide"> ➝ You
                    can choose multiple beneficiaries</li>
                </ul>
              </div> -->

              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                {{agentTerm}} Permissions
              </h4>
              <div class="grid grid-cols-12 items-center gap-4 row-gap-4">
                <div class="col-span-12 sm:col-span-8">
                  <div>
                    <h4 class="text-regular text-base text-grey font-semibold tracking-wide pt-4 md:ml-2">
                      Information Access
                    </h4>
                    <div class="text-grey text-regular pt-1 text-sm text-justify md:ml-4" *ngIf="loading">
                      <p *ngFor="let _ of [].constructor(5)">
                        <app-shimmer-loading [loading]='true' [width]="'75%'" [height]="'12px'"></app-shimmer-loading>
                      </p>
                    </div>
                    <p class="text-grey text-regular pt-1 md:ml-4 text-sm text-justify" *ngIf="!loading">
                      <fa-icon *ngIf="permissionAdvisorTerms==1" [icon]="faCheckCircle" class="green-tick"></fa-icon>
                      <fa-icon *ngIf="permissionAdvisorTerms==0" [icon]="faCircleXmark" class="red-tick"></fa-icon>
                      I hereby grant the {{agentTerm}} a non-exclusive, nontransferable, limited right to access to
                      the
                      designated information in my Digital Vault Account for purpose of facilitating the
                      {{agentTerm}}’s
                      consultation services related to my Digital Vault Account. I understand that the {{agentTerm}}’s
                      access to my Digital Vault Account will be subject to the terms and conditions of the <b><a
                          class="cursor-pointer" (click)="changeRoute('digital-vault-agreement')"
                          [ngStyle]="{'text-decoration':'underline'}">Digital Vault Agreement</a></b>.
                    </p>
                  </div>
                </div>
                <div class="col-span-12 sm:col-span-4">
                  <app-shimmer-loading [loading]="loading" [width]="'80%'" [height]="'24px'">
                    <app-toggle-switch [defaultSelected]="permissionAdvisorTerms" [options]="toggleOptions"
                      [disabled]="!canAccessForm"
                      (optionChangeEmitter)="onChangeToggle($event, PERMISSION_TYPE['permissionAdvisorTerms'])">
                    </app-toggle-switch>
                  </app-shimmer-loading>
                </div>
              </div>
              <div class="grid grid-cols-12 items-center gap-4 row-gap-4">
                <div class="col-span-12 sm:col-span-8">
                  <div>
                    <h4 class="text-regular text-base text-grey font-semibold tracking-wide pt-4 md:ml-2">
                      Beneficiaries Contact
                    </h4>
                    <div class="text-grey text-regular pt-1 text-sm text-justify md:ml-4" *ngIf="loading">
                      <p *ngFor="let _ of [].constructor(5)">
                        <app-shimmer-loading [loading]='true' [width]="'75%'" [height]="'12px'"></app-shimmer-loading>
                      </p>
                    </div>
                    <p class="text-grey text-regular pt-1 text-sm text-justify md:ml-4" *ngIf="!loading">
                      <fa-icon *ngIf="permissionBeneficiaries==1" [icon]="faCheckCircle" class="green-tick"></fa-icon>
                      <fa-icon *ngIf="permissionBeneficiaries==0" [icon]="faCircleXmark" class="red-tick"></fa-icon>
                      I give my {{agentTerm}} permission to reach out to my beneficiaries so he/she can introduce the
                      idea of the digital vault to them, go over their roles in the event of my passing and better
                      educate them on how the estate digital assets stored in the vault will assist in the resolution
                      of my estate.
                    </p>
                  </div>
                </div>
                <div class="col-span-12 sm:col-span-4">
                  <app-shimmer-loading [loading]="loading" [width]="'80%'" [height]="'24px'">
                    <app-toggle-switch [defaultSelected]="permissionBeneficiaries" [options]="toggleOptions"
                      [disabled]="!canAccessForm"
                      (optionChangeEmitter)="onChangeToggle($event,PERMISSION_TYPE['permissionBeneficiaries'])">
                    </app-toggle-switch>
                  </app-shimmer-loading>
                </div>
              </div>
              <div class="grid grid-cols-12 items-center gap-4 row-gap-4" *ngIf="permissionInform!==0">
                <div class="col-span-12 sm:col-span-8">
                  <div>
                    <h4 class="text-regular text-base text-grey font-semibold tracking-wide pt-4 md:ml-2">
                      Executors Contact
                    </h4>
                    <p class="text-grey text-regular pt-1 text-sm text-justify md:ml-4">
                      <fa-icon *ngIf="permissionContact==1" [icon]="faCheckCircle" class="green-tick"></fa-icon>
                      <fa-icon *ngIf="permissionContact==0" [icon]="faCircleXmark" class="red-tick"></fa-icon>
                      I hereby grant permission for the {{agentTerm}} to contact (via emails, calls, or scheduled
                      appointments) the Executor(s) and/or Authorized Representative(s) regarding the services and
                      functions of Digital Vault and NotifyNOW. <span class="font-black">After my death</span>,
                      NotifyNOW may email the Executor(s)
                      and/or Authorized Representative(s) to set up a phone call/in-person meeting for the purpose of
                      discussing the services and functions of Digital Vault. I understand that {{agentTerm}} will not
                      disclose any information in my Digital Vault Account without my prior consent.
                    </p>
                  </div>
                </div>
                <div class="col-span-12 sm:col-span-4">
                  <app-toggle-switch [defaultSelected]="permissionContact" [options]="toggleOptions"
                    [disabled]="!canAccessForm"
                    (optionChangeEmitter)="onChangeToggle($event,PERMISSION_TYPE['permissionContact'])">
                  </app-toggle-switch>
                </div>
              </div>

              <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
                <button (click)="click('Back')"
                  class="w-full sm:w-auto cursor-pointer text-sm mt-2 sm:mt-0 text-back text-regular font-semibold tracking-wide flex justify-center items-center focus:outline-none">
                  <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                </button>
                <app-button-loader class="w-full sm:w-auto" *ngIf="this.userType === 'Consumer'" [buttonText]="'Next'" [valid]="!loading"
                  [loader]="false" (click)="changeRoute('advisors/manage-advisors')" [buttonType]="'button'">
                </app-button-loader>
                <app-button-loader  class="w-full sm:w-auto" *ngIf="this.userType !== 'Consumer'" [buttonText]="'Next'" [valid]="!loading"
                  [loader]="false" [buttonType]="'button'"
                  (click)="changeRoute('advisors/manage-advisors/'+clientRequestId)">
                </app-button-loader>
              </div>
            </div>
            <!-- END: Leftside Card -->
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class="col-span-12 md:col-span-6 lg:grid justify-center">
    <about-yourself-right-content [formInputValue]="{}"
      [otherValues]="sectionSaveExitOptions "></about-yourself-right-content>
  </div>
</div>
<!-- END: Rightside Content -->

<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>
