import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { AddressDetails, ICountryCode, ObservableEventInterface, SSNToggleInterface, SelectOptionsInterface, ToggleOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { UserService } from 'src/app/services/user.service';
import { APARTMENT_NUMBER_PATTERN, CHILD_STATUS, DATE_FORMAT, EMAIL_PATTERN, LIST_DELETE, LIST_NEW, LIST_UPDATE, LOCAL_STORAGE_UPDATE_STATUS, NAME_PATTERN, PHONE_PATTERN, SSN_MASK, SSN_PATTERN, TOGGLE_OPTIONS_YES_NO, UNITED_KINGDOM, USER_TYPES } from 'src/constants/application.const';
import { RESET_CHILD_FORM } from 'src/constants/children.const';
import { ELEMENT_EVENTS, STATUS_CHECK } from 'src/constants/form.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../../helper/validation.helper';


@Component({
  selector: 'app-add-children-model',
  templateUrl: './add-children-model.component.html',
})
/**
 * Add Child component
 */
export class AddChildrenModelComponent implements OnInit {
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;
  @Output() toggleModalEmitter = new EventEmitter<any>();
  public readonly SSN_MASK = SSN_MASK;
  public readonly faEye = faEye;
  public readonly faEyeSlash = faEyeSlash;
  public readonly toggleOptions: Array<ToggleOptionsInterface> = TOGGLE_OPTIONS_YES_NO;
  public readonly childStatusOptions = CHILD_STATUS;
  public childrenForm: FormGroup;
  public submitLoader: boolean;
  public submitLoaderDelete: boolean;
  public currentDateRestriction: string;
  public countryCode: ICountryCode;
  public userType: string;
  public requestId: string;
  public locale = 'en';
  public minDate: Date;
  public maxDate: Date;
  public bsConfig: Partial<BsDatepickerConfig>;
  public showSSN: boolean;
  public toggleSSNView: SSNToggleInterface = { ssn: false, v_ssn: false };
  public domainDateFormat: any;
  public addressStatus: number;
  public personId: string;
  public isUniqueSSN: boolean = false;
  public validatingSSN: number = ssnValidationProcess.initial;
  public userDetails: any;
  private isEditing: boolean;
  private _addressDetails: AddressDetails;
  private isToggleChanged: boolean;

  // Address related fields
  public defaultCountry: number;
  public readonly selectedCountry: number = environment.APP_DOMAIN;
  public selectedCountryName: string = '';
  public unitedKingdom: string = UNITED_KINGDOM;
  public elementRefSet: boolean = false;
  public searchElementRef: ElementRef;
  public toggleEmiterListener: boolean = false; // toggle listener
  public isDisableForm: boolean;

  // @ViewChild('search', { static: false }) public searchElementRef: ElementRef;
  /**
   * @param{ElementRef}value
   */
  @ViewChild('search', { static: false })
  set setSearchElementRef(value: ElementRef) {
    this.searchElementRef = value;
    if (this.searchElementRef && !this.elementRefSet) {
      // if not elementRef set initial value for searchElement
      this.initMap(this.defaultCountry);
      this.elementRefSet = true;
    }
  }

  @Input() set addressDetails(value: AddressDetails) {
    this._addressDetails = value;
    this.defaultCountry = this._addressDetails?.['country'];
    this.setSearchElementRef = this.searchElementRef;
  }

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private commonHelper: CommonHelper,
    private peopleService: PeopleService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private modalService: CommonModelService,
    private route: ActivatedRoute,
    private localeService: BsLocaleService,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private addressHelper: AddressHelper,
    private ngZone: NgZone,
    private validation: ValidationHelper,
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
    this.minDate = new Date(1900, 0, 1);
    this.minDate.setDate(this.minDate.getDate());
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.domainDateFormat = this.commonHelper.domainDateFormat;
    // Address related fields
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    this.initMap(this.defaultCountry);
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.userDetails = this.localStorageService.getUserData();
    this.showSSN = environment.APP_DOMAIN == 1;
    this.initForm();
    this.isEnableState();
    /**
     * listen for childern event handlers
     */
    this.peopleService.childComponentObserve.subscribe((data: ObservableEventInterface) => {
      if (data.type === RESET_CHILD_FORM) {
        this.removePacContainer();
        this.initMap(this.defaultCountry);
        this.resetForm();
      }
    });
    this.applyLocale();
    this.bsConfig = Object.assign({}, {
        showWeekNumbers: false,
        minDate: this.minDate,
        maxDate: this.maxDate,
        dateInputFormat: this.domainDateFormat,
      },
    );
    this.resetForm();
  }

  private initForm() {
    // initiating form
    this.childrenForm = this.formBuilder.group({
      first_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      middle_name: ['', { updateOn: 'blur', validators: [Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      last_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      child_type: ['', { updateOn: 'blur', validators: [Validators.required] }],
      address: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(255)] }],
      apt_number: ['', { updateOn: 'blur', validators: [Validators.pattern(APARTMENT_NUMBER_PATTERN), Validators.maxLength(25)] }],
      country: ['', { updateOn: 'blur', validators: [Validators.required] }],
      state: ['', { updateOn: 'blur', validators: [Validators.required] }],
      city: ['', { updateOn: 'blur', validators: [Validators.required, this.validation.noWhitespaceValidator()] }],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.maxLength(10), Validators.required] }],
      dob: ['', { validators: [this.validation.fn.dateRange(this.minDate, this.maxDate)], updateOn: 'blur' }],
      ssn: ['', { updateOn: 'blur', validators: [this.validation.fn.SSNPatternValidator(SSN_PATTERN)] }],
      ssn_validation: [''],
      address_status: [''],
      phone: ['', { updateOn: 'blur', validators: [Validators.pattern(PHONE_PATTERN)] }],
      email: ['', { updateOn: 'blur', validators: [Validators.pattern(EMAIL_PATTERN)] }],
      relation: [''],
      id: [''],
    });
    this.validation.setValidationErrors({
      phone: { pattern: 'Please enter a valid phone number' },
      dob: { dateLow: null, dateHigh: null },
      email: { pattern: 'Please enter a valid email address.' },
      ssn: { pattern: 'Invalid SSN', mask: null },
      zipcode: { pattern: 'Please enter a valid zipcode' }
    });

    const trimWhitespaceControls = ['first_name', 'middle_name', 'last_name', 'address', 'apt_number', 'city', 'zipcode', 'email'];
    
    trimWhitespaceControls.forEach(controlName => {
      this.childrenForm.controls[controlName].valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.childrenForm.controls[controlName])
      );
    });
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.childrenForm);
  }

  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }

  /**
   * get Age
   * @param {string}date
   * @return {number}
   */
  public getAge(date: string) {
    return this.commonHelper.getAge(date);
  }

  /**
   * On Change toggle
   *
   * @param {ToggleOptionsInterface} selectedValue
   */
  public onChangeToggle(selectedValue: ToggleOptionsInterface): void {
    this.isToggleChanged = true;
    this.elementRefSet = false;
    if (!selectedValue) return;
    this.addressStatus = selectedValue?.value;
    if (!this.addressStatus) {
      this.childrenForm.patchValue({
        country: this._addressDetails?.['country'],
      });
    }
    this.updateAddressInfo(this.isEditing);
    this.removePacContainer();
    this.initMap(this._addressDetails?.['country']);
  }

  /**
   * Detect change on phone number component
   *
   * @param event
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }

  /**
   * Update {@link childrenForm} based on current {@link addressStatus}.
   * @param{boolean}isEdit
   */
  private updateAddressInfo(isEdit?: boolean) {
    const addressData = { ...this._addressDetails };
    const addressFields = ['country', 'address', 'zipcode', 'state', 'city'];

    if (isEdit && !this.addressStatus && this.isToggleChanged) {
      // Handle the case when editing and there address details already patched.
      //  removing country element from array because we already set country from the parent's this._addressDetails data
      addressFields.slice(1).forEach((control) => {
        this.childrenForm.get(control).patchValue('');
        this.childrenForm.get('apt_number').patchValue('');
      });
      this.childrenForm.patchValue({
        address_status: this.addressStatus,
      });
    } else {
      this.childrenForm.patchValue({
        country: addressData?.['country'],
        address: addressData?.['address'],
        apt_number: addressData?.['apt_number'],
        zipcode: addressData?.['zipcode'],
        state: addressData?.['state'],
        city: addressData?.['city']?.toString(),
        address_status: this.addressStatus,
      });
    }
    if (this.addressStatus) {
      addressFields.forEach((fieldName) => {
        this.removeRequiredValidator(fieldName);
      });
    } else {
      this.formGet['apt_number'].reset();
      addressFields.forEach((fieldName) => {
        this.formGet[fieldName].reset();
        this.addRequiredValidator(fieldName);
      });
      this.formGet['country'].patchValue(this.defaultCountry);
    }
  }

  /**
   * Function to remove the required validator
   * @param{string}fieldName
   */
  private removeRequiredValidator(fieldName: string) {
    const control = this.childrenForm.get(fieldName);
    if (control) {
      control.clearValidators(); // Remove all validators
      control.updateValueAndValidity(); // Update the control's validity
    }
  }

  /**
   * Function to add the required validator
   * @param{string}fieldName
   */
  private addRequiredValidator(fieldName: string) {
    const control = this.childrenForm.get(fieldName);
    if (control) {
      control.addValidators([Validators.required]); // add all validators
      control.updateValueAndValidity(); // Update the control's validity
    }
  }

  /**
   * triggered when editData comes in
   *
   * @param {any} data
   */
  @Input() set editData(data: any) {
    if(!data)
      return;
    // 'this.isFormDiabled' is a variable controlling the editability of the form.
    // Check if the child is the executor custodian and is registered
    // Disable the form editing for the child
    this.isDisableForm = data.people_id != null;
    if ((!data && !data?.id) || data?.id == undefined) return;
    this.resetForm();
    this.isEditing = true;
    // splitting country code and phone number
    let tempPhoneNumber;
    if (data?.phone) {
      tempPhoneNumber = data?.phone;

      this.countryCode = { code: data?.flag_code, dialCode: data?.country_code };
    }
    this.addressStatus = data?.address_status;
    this.childrenForm.patchValue({
      ...data,
      phone: tempPhoneNumber,
      ssn_validation: data.ssn,
      country: data?.country?.id,
      state: data?.state?.id,
      city: data?.city?.id,
      address_status: this.addressStatus,
    });
    // this.updateAddressInfo(this.isEditing);
    this.removePacContainer();
    this.initMap(data?.country?.id);
    if (Number.isNaN(new Date(data?.dob).valueOf()) === true || data?.dob === null) {
      this.childrenForm.patchValue({
        dob: '',
      });
    } else {
      this.childrenForm.patchValue({
        dob: this.commonHelper.formatDate(data.dob, this.domainDateFormat),
      });
    }
    this.personId = data?.id;
    if (this.childrenForm.controls.ssn.value && this.checkSSNMatch()) {
      // check is SSN unique
      this.checkIsSsnUnique(this.childrenForm.controls.ssn.value, this.personId);
    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.childrenForm.controls;
  }

  /**
   * Listen for option change (salutation)
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitter(selectedOption: SelectOptionsInterface): void {
    if (selectedOption?.value) {
      this.childrenForm.patchValue({
        child_type: selectedOption?.value,
      });
      if (this.toggleEmiterListener) {
        this.childrenForm.get('child_type').markAsUntouched();
        this.toggleEmiterListener = false;
      }
    } else {
      if (this.toggleEmiterListener) {
        this.childrenForm.get('child_type').markAsTouched();
        this.toggleEmiterListener = false;
      }
    }
  }

  /**
   * Handle on submit
   */
  public onSubmit(): void {
    const isConsumer: boolean = this.userType === 'Consumer';
    this.childrenForm.markAllAsTouched();
    if (!this.childrenForm.valid) {
      this.commonHelper.scrollToFirstInvalidControl(this.childrenForm);
      return; // return if the form is invalid
    }
    this.submitLoader = true;
    this.childrenForm.patchValue({
      relation: 2,
    });
    this.childrenForm.value.dob = this.commonHelper.formatDate(this.childrenForm.value.dob, DATE_FORMAT);

    const payload = {
      ...this.childrenForm.value,
      roletype: this.userType,
      country_code: this.countryCode.dialCode,
      flag_code: this.countryCode.code,
      is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
    };
    if (!isConsumer) {
      payload['request_id'] = this.requestId;
    }
    payload.state = this.selectedCountryName == this.unitedKingdom ? null : payload.state;
    if (typeof payload.city != 'string') {
      this.commonService.getCityById(payload.city).subscribe((response) => {
        payload.city = response.data.name;

        this.updateChildDetails(payload, isConsumer);
      });
    } else {
      this.updateChildDetails(payload, isConsumer);
    }
  }

  public updateChildDetails(payload: any, isConsumer: boolean) {
    const errorFn = (exception: any) => {
      this.submitLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    };
    this.commonService.getCityDetail(payload.country, payload.state, payload.city).subscribe((cResponse: APIResponseModel) => {
      if (cResponse?.status) {
        payload.city = cResponse?.data?.id;
        this.peopleService.storePeopleDetails({ ...payload }).subscribe((response: APIResponseModel) => {
          this.submitLoader = false;
          if (response.status) {
            payload.id ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();
            if (isConsumer) {
              this.commonHelper.updateLocalstorageRequestStage(response.data);
              // updating child status
              this.localStorageService.updateUserData(USER_TYPES.user, {
                key: 'request',
                updateValue: 1,
                updateKey: 'has_child',
                type: LOCAL_STORAGE_UPDATE_STATUS.O,
              });
            }
            this.userService.refreshChildrenListFunction({
              ...response.data,
              listStatus: this.childrenForm.value.id ? LIST_UPDATE : LIST_NEW,
            });
            this.toggleModalEmitter.emit({
              ...response.data,
              listStatus: this.childrenForm.value.id ? LIST_UPDATE : LIST_NEW,
            });
            this.closeModel();
          }
        }, errorFn);
      }
    }, errorFn);
  }

  /**
   * delete children
   */
  public deleteChildren(): void {
    const isConsumer: boolean = this.userType === 'Consumer';
    this.submitLoaderDelete = true;
    const payload = {
      is_delete: '1',
      id: this.childrenForm.value.id,
      roletype: this.userType,
    };
    if (!isConsumer) {
      payload['request_id'] = this.requestId;
    }
    this.peopleService.storePeopleDetails(payload).subscribe(
      (response: APIResponseModel) => {
        this.submitLoaderDelete = false;
        if (response.status) {
          this.commonHelper.toastrDeleteSuccess();
          this.userService.refreshChildrenListFunction({
            id: this.childrenForm.value.id,
            listStatus: LIST_DELETE,
          });
          this.toggleModalEmitter.emit({
            id: this.childrenForm.value.id,
            listStatus: LIST_DELETE,
          });
          this.closeModel();
        }
      },
      (exception: any) => {
        this.submitLoaderDelete = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      },
    );
  }

  /**
   * Auto hypen SSN keyup
   */
  public autoHypenSSN(): void {
    this.childrenForm.patchValue({
      ssn: this.commonHelper.autoHypenSSN(this.childrenForm.value.ssn),
    });
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.modalService.close('add-children-modal');
    this.resetForm();
  }

  /**
   * Reset form
   */
  private resetForm() {
    this.commonHelper.scrollToTopModal(this.childrenForm);
    this.childrenForm.reset({
      country: this.defaultCountry,
      address_status: 0,
    });
    this.addressStatus = 0;
    this.personId = this.userDetails?.request?.id;
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
  }

  /**
   *
   * @return{boolean}
   */
  /**
   *
   * @return{boolean}
   */
  public checkSSNMatch(): boolean {
    return this.childrenForm.controls['ssn'].value == this.childrenForm.controls['ssn_validation'].value || (this.childrenForm.controls['ssn'].value == null && this.childrenForm.controls['ssn_validation'].value == '') || (this.childrenForm.controls['ssn'].value == '' && this.childrenForm.controls['ssn_validation'].value == null);
  }

  /**
   * typing / onchange input field
   * changed as untouched field
   * not show validation error
   * @param {any} data
   * @param {string} key
   */
  public onChange(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.INPUT) {
      this.childrenForm.get(key).markAsUntouched();
      this.validatingSSN = ssnValidationProcess.initial;
    }
  }

  /**
   * focusout of input field
   * changed as touched field
   * check and show validation error
   * @param {any} data
   * @param {string} key
   */
  public onFocusout(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.FOCUSOUT) {
      this.childrenForm.get(key).markAllAsTouched();
    }
    const isSSNValid = this.childrenForm.controls.ssn.value ? this.childrenForm.controls.ssn.status == STATUS_CHECK.VALID : false;
    if (isSSNValid && this.checkSSNMatch()) {
      // if SSN status valid and ssn and confirmation ssn are same
      this.checkIsSsnUnique(this.childrenForm.controls.ssn.value, this.personId);
    }
  }

  /**
   * check ssn is unique
   * @param {string} data
   * @param {string} id
   */
  public checkIsSsnUnique(data: string, id: string) {
    this.validatingSSN = ssnValidationProcess.start_validation;
    this.commonService.getIsSSNUnique(data, id, this.commonHelper.isShowSSNToast()).subscribe({
      next: (r) => {
        this.isUniqueSSN = r;
        this.validatingSSN = ssnValidationProcess.end_validation;
      },
      error: () => {
        this.validatingSSN = ssnValidationProcess.end_validation;
      },
    });
  }

  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }

  /**
   * Initialize google map api by country
   * @param {any} countryId
   */
  public initMap(countryId: any): void {
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: (r) => {
        this.ngZone.run(() => {
          const { city, state, zipcode, address } = r;
          this.childrenForm.patchValue({ city, state, zipcode, address });
        });
      },
      error: (e) => console.error(e),
    });
  }

  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.childrenForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }

  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry) return;
    this.childrenForm.controls.state.reset();
    this.childrenForm.patchValue({
      country: selectedCountry.value,
    });
    this.removePacContainer();
    this.initMap(selectedCountry.value);
  }

  /**
   * remove pac container
   *
   */
  private removePacContainer() {
    this.addressHelper.removeAutocompletePacContainer();
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.childrenForm.controls.city.reset();
    this.childrenForm.patchValue({
      state: selectedState.value,
    });
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.childrenForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.childrenForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([Validators.pattern(new RegExp(selectedCity?.additionalDetails[0]?.postal_code_regex)), Validators.required]);
      zipCodeRef.updateValueAndValidity();
    }
  }

  /**
   * getter for ssn validation status
   */
  public get checkSSNValidation(): boolean {
    return this.validatingSSN !== 1 && (this.childrenForm.controls.ssn.value ? this.isUniqueSSN : !this.childrenForm.controls.ssn_validation.value);
  }

  /**
   * getter for state
   */
  get state() {
    return this.childrenForm.get('state');
  }

  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.childrenForm?.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ? this.state.addValidators([Validators.required]) : this.state.clearValidators();
      this.state.updateValueAndValidity();
    });
  }

  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.childrenForm.get('zipcode');
    const stateId = this.childrenForm.get('state')?.value;
    if (stateId) {
      // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe(
        (response: APIResponseModel) => {
          if (response.data.length) {
            const zipCodeRegex = response.data[0].postal_code_regex;
            zipCodeRef.setValidators([Validators.nullValidator, Validators.pattern(new RegExp(zipCodeRegex)), Validators.required]);
            zipCodeRef.updateValueAndValidity({ onlySelf: true });
          } else {
            zipCodeRef.setValidators([Validators.nullValidator]);
            zipCodeRef.updateValueAndValidity({ onlySelf: true });
          }
        },
        (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
      );
    } else {
      return;
    }
  }

  /**
   * toggle emitter
   * @param {boolean} data
   */
  public toggleEmiter(data: boolean) {
    if (data) {
      this.toggleEmiterListener = true; // toggle listener
    }
  }

  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }
}


/**
 * enum for ssn validation processing
 */
enum ssnValidationProcess {
  initial,
  start_validation,
  end_validation,
}
