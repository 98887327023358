import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CommonService } from '../services/common.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
/**
 * @class JwtInterceptor
 * @description for intercepting http request to include jwt token
 */
export class JwtInterceptor implements HttpInterceptor {
  private isPaymentButtonShowed = false;
  private paymentStatusStored = false;
  /**
   * @constructor
   */
  constructor(
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
  ) { }

  /**
   * @method intercept
   * @description interceptor to intercept the request
   * @param {HttpRequest} request request object from http
   * @param {HttpHandler} next handler object from http
   * @return {Observable} return api request with jwt token attached to header
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const currentUser = this.localStorageService.getUserData();
    const cookieUser = this.localStorageService.getLoginCookie();
    const isLoggedIn = currentUser && currentUser.access_token;
    const isApiUrl = request.url.startsWith(environment.BASE_URL);
    const isApiUrlTwo = request.url.startsWith(environment.BASE_URL_TWO);

    if ((isLoggedIn) && (isApiUrl || isApiUrlTwo) && (cookieUser)) {
      const requestDetails = {
        setHeaders: {
          'Authorization': `Bearer ${currentUser.access_token}`,
          'X-Content-Type-Options': `nosniff`,
          'X-XSS-Protection': '0',
        },

        body: {
          ...request.body,
          request_id: (request?.body?.requestIdOverride) ? request?.body?.request_id : currentUser?.request?.id,
        },
      };
      if (request.body instanceof FormData) {
        delete requestDetails.body;
      }
      request = request.clone(requestDetails);
    }

    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (!this.paymentStatusStored) {
          this.localStorageService.storeData('is_payment_complete', event?.body?.data?.user?.is_payment_complete || currentUser?.user?.is_payment_complete);
        }
        this.paymentStatusStored = true;
        if (event?.body?.data?.request_stage >= 10 && !this.isPaymentButtonShowed) {
          this.commonService.showPaymentButtonInNavBar(true);
          this.isPaymentButtonShowed = true;
        }
      }
      return event;
    }));
  }
}
