<app-common-modal id="import-client-modal" class="app-common-modal">
  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">
    <form [formGroup]="importClientForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Import Client
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">Please enter the LegacyNOW Digital Vault Request ID to import
            an existing client.
          </p>
        </div>
        <!-- LegacyNOW Digital Vault Request ID -->
        <div class="col-span-12">
          <div class="col-span-12 mb-2">
            <label for="id" class="text-label-grey text-sm text-regular">LegacyNOW Digital Vault Request ID</label>
            <div class="relative flex items-center">
              <input type="text" id="id"
                class="h-10 sm:h-12 input py-3 mt-2 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none  border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 price-input"
                name="id" formControlName="id" placeholder="e.g DV-1q10a1a198"
                [ngClass]="formGet.id.errors && formGet.id.touched?'form-input-error':'form-input-focus'">

            </div>
            <p *ngFor="let error of getErrors('id')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </div>
        <!-- Choose Advisor -->
        <!-- <div class="col-span-12 mb-2">
          <div class="relative"
            *ngIf="this.userRole=== PROFESSIONAL_ROLE.manager||this.userRole===PROFESSIONAL_ROLE.professional"
            (click)="submitted=true">
            <label for="advisor" class="text-label-grey text-sm text-regular">{{advisorTerm}}</label>
            <app-select-with-search id="advisor" [options]="agentList" [placeholderValue]="'Choose '+advisorTerm"
              (optionChangeEmitter)="optionChangeEmitterAgentType($event)" (focusout)="submitted=true"
              [ifError]="submitted && !((this.userRole=== PROFESSIONAL_ROLE.manager||this.userRole===PROFESSIONAL_ROLE.professional)? this.selectedAgentId? true : false : this.agentID ? true : false)"></app-select-with-search>
            <div
              *ngIf="submitted && !((this.userRole=== PROFESSIONAL_ROLE.manager||this.userRole===PROFESSIONAL_ROLE.professional)? this.selectedAgentId? true : false : this.agentID ? true : false)">
              <p class="text-regular font-medium text-xs mt-2 error-alert">Agent is required</p>
            </div>
          </div>
        </div> -->
        <!-- Payment Mode -->
        <!-- <div class="col-span-12 md:col-span-12 lg:col-span-12 mb-2">
          <label for="payment_radio" class="text-sm mb2 text-regular text-label-grey">Payment Mode</label>
          <span>
            <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
              [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
          </span>
          <div class="md:flex md:space-x-56 items block">
            <div id="payment_radio" class="form-check" *ngFor="let payment of paymentTypes;let i=index">
              <input class="form-check-input text-regular text-grey" type="radio" name="payment_radio"
                id="radios_{{i+3}}" [value]="payment?.value" (change)="onPaymentTypeSelect(payment?.value)"
                [checked]="selectedPaymentType === payment?.value" [disabled]="generatedUrl">
              <label class="form-check-label text-regular text-grey placeholder-gray-400 text-base"
                for="radios_{{i+3}}">
                {{payment?.displayText}}
              </label>
            </div>
          </div>
        </div> -->
        <!-- Subscription Amount -->
        <div class="col-span-12 mb-2" *ngIf="selectedPaymentType == 2">
          <label for="pay_amount" class="text-sm mb2  text-regular text-label-grey">Subscription Amount</label>
          <span>
            <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
              [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
          </span>
          <div class="relative flex items-center">
            <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
            <input type="text"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none currency-input placeholder-gray-400 text-base"
              [ngClass]="formGet?.pay_amount.errors && formGet?.pay_amount.touched?'form-input-error':'form-input-focus'"
              appCurrencyInput placeholder="e.g. 5.99" formControlName="pay_amount" id="pay_amount">
          </div>
          <p *ngFor="let error of getErrors('pay_amount')" class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
      </div>
      <div class=" flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 ">
        <app-button-loader [buttonText]="'Import Client'" [valid]="true" [loader]="submitLoader" [buttonType]="'submit'"
          (click)="submitted=true"></app-button-loader>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>