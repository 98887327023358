<div *ngIf="settings.isLoading" class="bg-transparent border-card-grey rounded-xs mt-3 h-18">
  <div class="grid grid-cols-12 h-20 items-center gap-2 px-3 sm:pl-3">
    <div class="col-span-3 sm:col-span-2">
      <div class="flex sm:items-center sm:justify-center border-circle sm:mx-auto rounded-full h-12 w-12 sm:h-8 w-8">
        <app-shimmer-loading [shape]="'circle'" [width]="'3rem'">
        </app-shimmer-loading>
      </div>

    </div>
    <div class="col-span-7 sm:col-span-8">
      <app-shimmer-loading class="w-full h-12"></app-shimmer-loading>
      <app-shimmer-loading class="w-full h-12"></app-shimmer-loading>
    </div>
    <div class="col-span-2">
      <div class="flex items-center justify-center h-5">
        <app-shimmer-loading class="w-full h-12"></app-shimmer-loading>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="!settings.isLoading">
  <div class="space-y-4 md:space-y-6 mt-4">
    <div *ngFor="let person of getPeople"
         class=" border-card-grey rounded-xs">
      <label [for]="cardRootNodeID">
        <div class="sections-root justify-between gap-2 p-3 w-full"
             [attr.id]="cardRootNodeID"
             (click)="clickRoot($event, person)">
          <!-- Identifier -->
          <div class="identifier-section gap-2">
            <!-- Image/Alt Text display -->
            <div class="sm:pr-4 sm:pl-2">
              <div class="flex items-center justify-center rounded-full w-10 h-10 sm:h-12 sm:w-12 cursor-pointer"
                   [ngClass]="person.isDisabled ? 'inactive-bg-color' : 'active-bg-color'">
                <div class="uppercase text-white text-regular text-base">
                  <img *ngIf="person.imageSRC" src="{{person.imageSRC}}" class="w-4 sm:w-5" alt="{{person.imageALT}}">
                  <p *ngIf="!person.imageSRC"
                     class="font-sans font-medium text-xs sm:text-base">
                    {{person.imageALT ?? 'N/A'}}
                  </p>
                </div>
              </div>
            </div>
            <!-- Name display -->
            <div class="flex flex-col gap-1 name-container cursor-pointer">
              <h4
                class="name-style text-base sm:text-lg text-regular tracking-wide text-semibold leading-5"
                [tooltip]="person.tooltip"
                [ngClass]="person.isDisabled ? 'inactive-color' : 'active-color'" (click)="clickName(person.id)">
                {{person.name}} 
                <span *ngIf="person.isDefaultBadge" class="bg-orange-100 text-orange-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-orange-900 dark:text-orange-300">Default</span>
              </h4>
            <!-- Company Name display -->
              <ng-container *ngIf="!!person.companyName">
                <h4
                  class="name-style active-dark-grey text-base sm:text-md text-regular tracking-wide text-semibold leading-5">
                  {{person.companyName}}
                </h4>
              </ng-container>
              <!-- Subtext display -->
              <ng-container *ngIf="!!person.subtext">
                <p class="subtext-style text-grey text-regular text-xs sm:text-sm">
                  {{person.subtext}}
                </p>
              </ng-container>
            </div>
          </div>

          <!-- Interactables -->
          <div class="interactables-section gap-3">

            <ng-container *ngIf="settings.rightContent?.show && !person.rightContent?.hideLabel">
              <p class="flex justify-center items-center text-regular"
                 [tooltip]="person.rightContent?.tooltip ?? settings.rightContent?.tooltip"
                 [innerHTML]="person.rightContent?.innerHTML ?? settings.rightContent?.innerHTML"></p>
            </ng-container>

            <div *ngIf="!person.dropdown && !isMobile" class="my-auto">
              <ng-container
                [ngTemplateOutlet]="interactables"
                [ngTemplateOutletContext]="{$implicit:person}">
              </ng-container>
            </div>

            <!-- Dropdown Arrow-->
            <ng-container *ngIf="person.dropdown">
              <div class="h-auto my-auto py-2 w-5 ml-3 cursor-pointer"
                   (click)="clickDropdownToggle(person)">
                <img [src]="getDropdownArrowSrc(person)"
                     alt="dropdown"
                     class="w-full items-center justify-center">
              </div>
            </ng-container>
            <!-- Delete Icon -->
            <ng-container *ngIf="settings.delete?.show">
              <fa-icon
                class="filter-darkblue w-4 h-4 cursor-pointer my-auto"
                [icon]="deleteIcon"
                [tooltip]="settings.delete.tooltip"
                (click)="clickDelete(person.id)">
              </fa-icon>
            </ng-container>
          </div>

        </div>
      </label>

      <!-- Dropdown -->
      <ng-container *ngIf="showDropdown(person)">
        <div class="px-3 pb-3">
          <ng-container
            [ngTemplateOutlet]="interactables"
            [ngTemplateOutletContext]="{$implicit:person}">
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

</ng-container>
<!-- END: AccessControl -->

<ng-template #interactables let-person>
  <div class="interactables">
    <!-- Text Input display -->
    <ng-container *ngIf="settings?.textInput?.show">
      <input
        class="input-style h-8 input py-3 px-4 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
        (change)="onTextInput(person, 'change', $event)" (input)="onTextInput(person, 'oninput', $event)"
        (keyup)="onTextInput(person, 'keyup', $event)" (keydown)="onTextInput(person, 'keydown', $event)"
        [min]="settings?.textInput?.min" [mask]="settings?.textInput?.mask" [(ngModel)]="person['textInput'].text"
        [value]="person['textInput'].text" [type]="getInputType(person)"
        [placeholder]="settings?.textInput?.placeholder ?? ''" [tooltip]="settings?.textInput?.tooltip"
        [attr.disabled]="person['textInput']?.['disableInput'] || undefined"/>
    </ng-container>

    <!-- Button display -->
    <div *ngIf="settings?.button?.show"
         class="flex justify-center w-full">
      <ng-container *ngIf="!person.button?.['hideButton']">
        <button (click)="clickButton(person.id)"
                class="px-4 sm:px-5 float-right flex items-center justify-center bg-darkblue button button--md focus:outline-none w-full sm:w-auto p-2 rounded-xs text-regular text-sm sm:text-base text-white sm:button--sm whitespace-nowrap"
                [tooltip]="settings.button.tooltip">
          {{person.button?.text ?? settings?.button.text}}
          <img *ngIf="settings?.button.useSpinner" class="cursor-pointer filter-white ml-2 w-5 h-5"
               [ngClass]="{'animate-spin': person['isLoading']}" src="assets/icons/settings-cog.svg" alt="">
        </button>
      </ng-container>
    </div>

    <!-- checkbox display -->
    <div *ngIf="settings?.checkbox?.show"
         class="flex justify-center items-center cursor-pointer">
      <ng-container *ngIf="!person['checkbox']?.['hideCheckbox']">
        <label class="text-sm rounded-b-sm text-gray-700 pr-3 whitespace-nowrap" [for]="'checkbox-'+person.id"
               (click)="onChangeCheckBox(person.id)">
          {{person['checkbox']?.text ?? settings?.checkbox.text}}
        </label>
        <span
          class="span-checkbox"
          [attr.id]="'checkbox-'+person.id"
          [ngClass]="cssCheckbox(person)"
          [tooltip]="person['checkbox']?.['tooltip'] ?? settings.checkbox.tooltip ?? ''"
          (click)="onChangeCheckBox(person.id)">
  </span>
      </ng-container>
    </div>
    <!-- Edit Icon -->
    <div *ngIf="settings?.edit?.show && !person['edit']?.['hide']" class="my-auto cursor-pointer w-4">
      <img
        src="assets/images/about-yourself/edit.svg" alt="Edit"
        class="w-4 h-4 float-right cursor-pointer"
        [tooltip]="person['edit']?.['tooltip'] ?? settings?.edit.tooltip ?? ''"
        (click)="person['edit']?.['disable'] || clickEdit(person.id)">
    </div>
  </div>

  <!-- toggle switch(s) display -->
  <div *ngIf="person['toggle_switch']">
    <ng-container *ngFor="let toggle of person['toggle_switch']; let i = index">
      <div class="flex px-4 py-2">
        <div *ngIf="toggle['showIcon']"
             class="pr-3">
          <fa-icon *ngIf="toggle.value == 1" [icon]="faCheckCircle"
                   class="green-tick"></fa-icon>
          <fa-icon *ngIf="toggle.value == 0" [icon]="faCircleXMark"
                   class="red-tick"></fa-icon>
        </div>
        <p class="text-grey text-regular pt-1 text-sm text-justify text-center w-full"
           [innerHTML]="toggle.text"></p>
        <div class="w-auto my-auto pl-6">
          <app-toggle-switch [defaultSelected]="toggle.value" [options]="toggleOptionsYesNo"
                             (optionChangeEmitter)="onToggle($event, person, i)">
          </app-toggle-switch>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
