<div class="scroll-hidden box-border body-content pb-12">
  <div class="pt-12">
    <div class="flex flex-wrap lg:mx-8 mx-6 ">
      <div class="w-full">
        <div class="flex items-center">
          <img src="assets/images/about-yourself/back-arrow.svg" alt="digital vault" class="cursor-pointer"
            (click)="changeRoute('clients')">
          <h2 class="text-darkblue text-md lg:text-2xl capitalize pl-5 text-semibold">
            Add Client
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-8">

    <form [formGroup]="clientForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="flex p-6 mx-3 pt-0 w-full text-gray-700">
        <div class="text-sm font-normal">Please ensure accuracy when entering customer details as they cannot be edited
          once submitted.</div>
      </div>
      <div class="border-card bg-white rounded-md p-6 mx-8">
        <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mb-5 ">
          <!-- First Name -->
          <div class="col-span-12 md:col-span-6 lg:col-span-4  ">
            <label for="first_name" class="text-sm mb2 text-regular text-label-grey">First Name</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="text"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet?.first_name.errors && formGet?.first_name.touched?'form-input-error':'form-input-focus'"
              placeholder="First Name" formControlName="first_name" id="first_name">
            <p *ngFor="let error of getErrors('first_name')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <!-- Middle Name -->
          <div class="col-span-12 md:col-span-6 lg:col-span-4  ">
            <label for="middle_name" class="text-sm mb2 text-regular text-label-grey">Middle Name</label>

            <input type="text" id="middle_name"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet?.middle_name.errors && formGet?.middle_name.touched?'form-input-error':'form-input-focus'"
              placeholder="Middle Name" formControlName="middle_name">
            <p *ngFor="let error of getErrors('middle_name')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <!-- Last Name -->
          <div class="col-span-12 md:col-span-6 lg:col-span-4  ">
            <label for="last_name" class="text-sm mb2 text-regular text-label-grey">Last Name</label>
            <span>

              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="text" id="last_name"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet?.last_name.errors && formGet?.last_name.touched?'form-input-error':'form-input-focus'"
              placeholder="Last Name" formControlName="last_name">
            <p *ngFor="let error of getErrors('last_name')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>

          <!-- <div class="col-span-12 md:col-span-6 lg:col-span-4">
            <label for="dob" class="text-sm mb2 text-regular text-label-grey">Date of Birth ( {{domainDateFormat}}
              )</label>
            <span><fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="text" id="dob" #dp=bsDatepicker [dp]="dp" bsDatepicker [maxDate]="maxDate"
              [bsConfig]="bsConfig" (change)="isInvalidDate($event)"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet?.dob.errors && formGet?.dob.touched?'form-input-error':'form-input-focus'"
              formControlName="dob" name="dob" placeholder="{{domainDateFormat}}">
            <p *ngFor="let error of getErrors('dob')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-4" *ngIf="showSSN">
            <label for="ssn" class="text-sm mb2 text-regular text-label-grey">SSN
              (Social Security Number)</label>
            <span><fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <div class="relative text-gray-700">
              <a class="absolute inset-y-0 right-2 pr-3 flex items-center" (click)="toggleSSNView = !toggleSSNView">
                <fa-icon [icon]="faEye" *ngIf="toggleSSNView"></fa-icon>
                <fa-icon [icon]="faEyeSlash" *ngIf="!toggleSSNView"></fa-icon>
              </a>
              <input type="text" id="ssn" [type]="toggleSSNView?'text':'password'"
                class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
                name="ssn" formControlName="ssn" placeholder="e.g. 255-34-9345"
                [ngClass]="formGet?.ssn.errors && formGet?.ssn.touched?'form-input-error':'form-input-focus'"
                [mask]="SSN_MASK" [dropSpecialCharacters]="false" maxlength="11" (input)="onChange('$event', 'ssn')"
                (focusout)="onFocusout($event,'ssn')">
            </div>
            <p *ngFor="let error of getErrors('ssn')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
            <div *ngIf="formGet?.ssn.value && formGet?.ssn.valid">
              <div class="flex" *ngIf="validatingSSN == 1">
                <p class="text-regular font-medium text-xs mt-2 checking-alert">
                  Validating SSN &nbsp;
                </p>
                <div class="loading-red mr-2 mt-2"></div>
              </div>
              <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="validatingSSN == 2 && !isUniqueSSN">
                SSN has already been entered.
              </p>
            </div>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-4">
            <label for="phone_number" class="text-sm mb2 text-regular text-label-grey">Phone Number</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>

            <app-phone-input id="phone_number" [placeholder]="'e.g. 8665044754'"
              [phoneNumber]="clientForm?.value?.phone" [countryCode]="countryCode" [uniqueId]="'phone-pro-client'"
              (onChange)="detectChangePhonenumber($event)"
              [errorClassTrigger]="formGet?.phone.errors && formGet?.phone.touched" [form]="clientForm"
              (focusout)="formGet?.phone.markAsTouched()">
            </app-phone-input>
            <p *ngFor="let error of getErrors('phone')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div> -->
        </div>

        <!-- Email Address -->
        <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mb-5">
          <div class="col-span-12 md:col-span-6 lg:col-span-4">
            <label for="email" class="text-sm mb2 text-regular text-label-grey">Email Address</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="email" id="email"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet?.email.errors && formGet?.email.touched?'form-input-error':'form-input-focus'"
              formControlName="email" name="email" placeholder="e.g. marktylor@gmail.com">
            <p *ngFor="let error of getErrors('email')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <!-- <div class="col-span-12 md:col-span-6 lg:col-span-4 relative"
          *ngIf="this.userType === PROFESSIONAL_ROLE.manager || this.userType === PROFESSIONAL_ROLE.professional">
          <label for="advisor" class="text-sm mb2 text-regular text-label-grey ">{{advisorTerm}}*</label>
          <app-select-with-search id="advisor" [options]="agentList" [placeholderValue]="'Choose '+advisorTerm"
            (optionChangeEmitter)="optionChangeEmitterAgentType($event)"
            (focusout)="markAsTouchedIndividual('agent_id')" (click)="markAsTouchedIndividual('agent_id')"
            [ifError]="formGet.agent_id.errors && formGet.agent_id.touched"></app-select-with-search>
          <div *ngIf="formGet.agent_id.errors && formGet.agent_id.touched">
            <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.agent_id.errors.required">
              Field is required</p>
          </div>
        </div> -->
        </div>

        <!-- Onboard Type -->
        <div class="w-full my-3 mt-10">
          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
            <div class="col-span-12 md:col-span-6 lg:col-span-6">
              <label for="radio" class="text-sm mb2 text-regular text-label-grey">How would you like to send the onboarding link ?</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}">
                </fa-icon>
              </span>
              <div class="md:flex justify-between items block mt-2">
                <div id="radio" class="form-check" *ngFor="let onboardType of onboardTypes;let i=index">
                  <input class="form-check-input text-regular text-grey" type="radio" name="radios" id="radios_{{i}}"
                    [value]="onboardType?.value" (change)="onSelectOnboardType($event)"
                    [checked]="selectedChecked('onboard',onboardType?.value)">
                  <label class="form-check-label text-semibold text-grey placeholder-gray-400 text-xl"
                    for="radios_{{i}}">
                    {{onboardType?.displayText}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Copy Onboarding Link -->
        <!-- <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mb-5"  *ngIf="selectedOnboardType !== '1' && generatedUrl">
          <div class="col-span-6 md:col-span-6 lg:col-span-6">
            <label for="id" class="text-sm mb2 text-regular text-label-grey">Your Onboarding Link</label>
            <div class="flex flex-col md:flex-row items-center gap-4 my-2">
              <input type="text" id="onboarding_link" [readOnly]="true" [value]="generatedUrl"
                class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base">
              <app-button-loader [buttonText]="'Copy'" [valid]="generatedUrl" [buttonType]="'button'" (click)="copyToClipboard()">
              </app-button-loader>
            </div>
          </div>
        </div> -->

        <!-- <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mb-5">
          <div class="col-span-6 md:col-span-6 lg:col-span-4">
            <label for="payment_radio" class="text-sm mb2 text-regular text-label-grey">Payment Mode</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <div class="md:flex justify-between items block">
              <div id="payment_radio" class="form-check" *ngFor="let payment of paymentTypes;let i=index">
                <input class="form-check-input text-regular text-grey" type="radio" name="payment_radio" id="radios_{{i+3}}"
                  [value]="payment?.value" (change)="onPaymentTypeSelect(payment?.value)"
                  [checked]="selectedChecked('payment',payment?.value)">
                <label class="form-check-label text-regular text-grey placeholder-gray-400 text-base"
                  for="radios_{{i+3}}">
                  {{payment?.displayText}}
                </label>
              </div>
            </div>
          </div>

          <div class="col-span-12 md:col-span-6 lg:col-span-4" *ngIf="selectedPaymentType == 2">
            <label for="pay_amount" class="text-sm mb2  text-regular text-label-grey">Subscription Amount</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <div class="relative flex items-center">
              <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
              <input type="text"
                class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none currency-input placeholder-gray-400 text-base"
                [ngClass]="formGet?.pay_amount.errors && formGet?.pay_amount.touched?'form-input-error':'form-input-focus'" appCurrencyInput
                placeholder="e.g. 5.99" formControlName="pay_amount" id="pay_amount">
            </div>
              <p *ngFor="let error of getErrors('pay_amount')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
          </div>
        </div> -->

        <!-- <div class="w-full my-3 mb-5">
          <h3 class="text-semibold text-darkblue text-lg">Address</h3>
          <p class="text-grey text-md text-regular mb-4">Please enter their primary physical address.</p>
          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
              <label for="country" class="text-sm mb-2 text-regular text-label-grey">Country</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <div class=" col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('country')">
                <app-country-search-component id="country" (optionChangeEmitter)="optionChangeListenerCountry($event)"
                  [defaultSelected]="formGet?.country?.value"
                  [enableError]="formGet?.country.errors && formGet?.country.touched"></app-country-search-component>
                <p *ngFor="let error of getErrors('country')" class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
            <div class="col-span-12 md:col-span-6 lg:col-span-4 relative move-map" #moveMapHere>
              <label for="address" class="text-sm mb-2 text-regular text-label-grey">Street Address</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <input type="text" id="address" #search (keyup)="onAddressChange(search)" (input)="onFocusAddress()"
                (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null"
                class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
                placeholder="e.g Main St" name="address" formControlName="address"
                [ngClass]="formGet?.address.errors && formGet?.address.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('address')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 md:col-span-6 lg:col-span-4" *ngIf="selectedCountryName!==unitedKingdom">
              <label for="state-province" class="text-sm mb-2 text-regular text-label-grey">State/Province</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('state')">
                <app-state-search-component id="state-province" [countryCode]="formGet?.country?.value"
                  [enableError]="formGet?.state.errors && formGet?.state.touched"
                  [defaultSelected]="formGet?.state?.value" (optionChangeEmitter)="optionChangeListenerState($event)"
                  tabindex="0" (focusout)="markAsTouchedIndividual('state')">
                </app-state-search-component>
                <p *ngFor="let error of getErrors('state')" class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
              <label for="city" class="text-sm mb-2 text-regular text-label-grey">City</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('city')">
                <app-city-search id="city" [stateCode]="formGet?.state?.value"
                  [enableError]="formGet?.city.errors && formGet?.city.touched" [defaultSelected]="formGet?.city?.value"
                  (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0"
                  (focusout)="markAsTouchedIndividual('city')"></app-city-search>
                <p *ngFor="let error of getErrors('city')" class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
              <label for="zipcode" class="text-sm mb-2 text-regular text-label-grey">Zip/Postal Code</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <input type="text" id="zipcode" (focusout)="markAsTouchedIndividual('zipcode')"
                class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
                placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                name="zipcode" formControlName="zipcode" oninput="this.value = this.value.toUpperCase() "
                (focus)="validateZipCode()"
                [ngClass]="formGet?.zipcode.errors && formGet?.zipcode.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('zipcode')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
          </div>
        </div> -->

        <!--schedule remainder-->
        <!-- <div class="w-full my-3 mt-10">
          <h3 class="text-semibold text-darkblue text-lg">Schedule Remainder Options</h3>
          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
            <div class="col-span-12 md:col-span-6 lg:col-span-6">
              <label for="radio" class="text-grey text-md text-regular mb-4 mb-2">Select the frequency for regular
                checkups with the new client for their Digital Vault setup:</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <div class="md:flex justify-between items block mt-6">
                <div id="radio" class="form-check" *ngFor="let schedule of scheduleReminder;let i=index">
                  <input class="form-check-input text-regular text-grey" type="radio" name="radios" id="radios_{{i}}"
                    [value]="schedule?.value" (change)="onSelectSchedule($event)"
                    [checked]="selectedChecked('schedule',schedule?.value)">
                  <label class="form-check-label text-semibold text-grey placeholder-gray-400 text-xl"
                    for="radios_{{i}}">
                    {{schedule?.displayText}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- Fifth row -->
        <div class="w-full h-12 mb-4 lg:h-24 md:w-full  relative">
          <div class="absolute bottom-0 right-0">
            <button [disabled]="loginLoader || submitDisabled" [ngClass]="{'opacity-50': submitDisabled}"
              class="flex items-center justify-between button button--lg w-full bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white text-sm px-4">
              <div class="loading-red mr-2" *ngIf="loginLoader"></div>
              <div>Add Client</div>
            </button>
          </div>
        </div>
        <!-- End -->
      </div>
    </form>
  </div>
</div>

<app-common-modal id="generated-onboarding-link-modal" class="app-common-modal">
  <div class="relative">
      <img src="assets/images/about-yourself/close.svg"
        class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
        (click)="closeModel()">

        <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mb-5"  *ngIf="generatedUrl">
          <div class="col-span-12">
            <label for="id" class="text-sm mb2 text-regular text-label-grey">Your Onboarding Link</label>
            <div class="flex flex-col md:flex-row items-center gap-4 my-2">
              <input type="text" id="onboarding_link" [readOnly]="true" [value]="generatedUrl"
                class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base">
              <app-button-loader [buttonText]="'Copy'" [valid]="generatedUrl" [buttonType]="'button'" (click)="copyToClipboard()">
              </app-button-loader>
            </div>
          </div>
        </div>
  </div>
</app-common-modal>