<div id="vehicle">
  <div class="grid grid-cols-12 gap-6 pt-8">

    <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="preLoader">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-8">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </h4>
        </div>
        <div class="col-span-4">
          <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
            $30,595
            <img src="assets/images/about-yourself/edit.svg" *ngIf="!accessVault && !isVaultClaimed" class="w-4 ml-2 sm:ml-4 cursor-pointer float-right" alt="digital vault">
          </p>
        </div>
      </div>
      <div class="bg-transparent border-card-grey rounded mt-4">
        <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
          <div class="col-span-12 sm:col-span-1">
            <div class="rounded-full border grid items-center justify-center h-12 w-12">
              <app-shimmer-loading [loading]="preLoader" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-11 sm:ml-6">
            <h4 class="text-label-grey text-sm text-regular">Vehicle</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 sm:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Year
            </h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 sm:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Mileage
            </h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 sm:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Licence Plate
            </h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 sm:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">State of Registration
            </h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!preLoader">
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngFor="let data of vehicleArray">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              {{data?.['vehicle_type']?.type}}
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              $30,595
              <img src="assets/images/about-yourself/edit.svg" *ngIf="!accessVault && !isVaultClaimed" class="w-4 ml-2 sm:ml-4 cursor-pointer float-right" alt="digital vault" (click)="toggleModal(data)">
            </p>
          </div>
        </div>
        <div class="bg-transparent border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
            <div class="col-span-12 sm:col-span-2">
              <div class="flex items-center justify-center mx-auto rounded-full">
                <app-image-component
                  [image]="PIC_URL+data?.['vehicle_image']"
                  [class]="'object-cover rounded-full h-12 w-12'"
                >
                </app-image-component>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-10 sm:ml-6">
              <h4 class="text-label-grey text-sm text-regular">Vehicle</h4>
              <p class="text-lg text-regular text-darkgrey">{{data?.['vehicle_type']?.type}} {{data?.['vehicle_model']?.name}} {{data?.['vehicle_make']?.name}}</p>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Year
              </h4>
              <p class="text-lg text-regular text-darkgrey">{{data?.year}}</p>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Mileage
              </h4>
              <p class="text-lg text-regular text-darkgrey">{{data?.['millage']}}</p>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Licence Plate
              </h4>
              <p class="text-lg text-regular text-darkgrey">{{data?.['licence']}}</p>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">State of Registration
              </h4>
              <p class="text-lg text-regular text-darkgrey">{{data?.state}}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</div>

<app-add-vehicle
    [editData]="editData"
    (toggleModalEmitter)="modalEventListner($event)"
></app-add-vehicle>
