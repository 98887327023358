import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ROUTE_PREFIX } from '../../../../../../constants/application.const';

@Component({
  selector: 'app-footer-landing-pro',
  templateUrl: './footer-landing-pro.component.html',
})
/**
 * footer landing page
 */
export class FooterLandingProComponent implements OnInit {
  public menuActive: string;
  public proAddress: string = ROUTE_PREFIX.PRO;
  public videoSourceURL: string;
  public proTypeText: string;
  public customerSupportMail:string;
  public customerSupportPhone:number;

  /**
   * constructor
   */
  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private commonService: CommonService,
    private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService,
    public commonHelper: CommonHelper,
    private modalService: CommonModelService) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.getContactDetails();
    this.proTypeText = this.localStorageService.getDataByKey('pro-type')?.[0]?.['displayText'];
  }


  /**
   * @param {string} url
   * on menu change
   */
  public menuChange(url?: string) {
    this.menuActive = url;
    if (url === '/') {
      this.localStorageService.clearOrgData();
      this.localStorageService.clearProTypeData();
    }
    void this.slugInterceptorService.navigate(url ? [url] : [''], null, true);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }

  /**
   * Display intro video modal
   */
  viewIntroVideo() {
    this.modalService.open('view-video-modal');
  }

  /**
   * clear org data
   */
  public clearOrgData() {
    this.localStorageService.clearOrgData();
    this.localStorageService.clearProTypeData();
  }

  public getContactDetails(){
    this.commonService.getContactUsDetails().subscribe((response)=>{
      this.customerSupportMail =response.data?.support_email 
      this.customerSupportPhone = response.data?.support_number;
    })
  }
}
