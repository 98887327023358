<div class="space-y-3">
  <ng-container *ngFor="let type of [TYPE.primary, TYPE.secondary]">
    <div *ngIf="type == TYPE.primary || userPeople?.[TYPE.primary].length > 0" class="border p-4">
      <div class="flex flex-row" (click)="viewBeneficiaryDetails(type)">
        <p class="cursor-pointer text-sm lg:text-base text-regular text-label-grey">
          Select {{TYPE_STRING[type] | titlecase}} Beneficiaries
        </p>
        <img class="ml-auto cursor-pointer w-5 cursor-pointer" src="assets/images/about-yourself/edit.svg" alt="Edit"/>
      </div>
      <div [ngClass]="{'mt-2' : userPeople?.[type].length}">
        <app-list-people
          [lpSettings]="lpSettings"
          [userPeopleData]="userPeople?.[type]">
        </app-list-people>
      </div>
    </div>
    <div class="text-red text-regular" *ngIf="chosenBeneficiay?.[type] && chosenBeneficiay?.[type]?.percentage != 100">
      Remaining to allocate {{100 - chosenBeneficiay?.[type]?.percentage}}%
    </div>
  </ng-container>
</div>
<app-assign-beneficiary-modal
  [modal_id]="assignBeneModalID"
  [settings]="assignBeneSettings"
  (submitEmitter)="listenerAssignBeneficiaries($event)">
</app-assign-beneficiary-modal>
