import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-suggestion',
  templateUrl: './input-suggestion.component.html',
  styleUrls: ['./input-suggestion.component.css'],
})
export class InputSuggestionComponent {
  public selectToggle: boolean;
  @Input() placeholder: string;
  @Input() input: boolean;
}
