import { Component } from '@angular/core';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
})
/**
 * Forgot password component
 */
export class ForgetPasswordComponent {
}
