<app-common-modal id="{{id}}" class="app-common-modal">
  <div class="w-full relative rounded-md">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8  -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
         (click)="closeModal()">
    <div class="mb-2 title-modal">
      <div class="flex flex-row items-baseline">
        <div>
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Access Control Default Settings
          </h4>
          <p class="text-grey text-regular text-sm">
            Here you can edit the default Access Control your advisors will have over their client's information. Please
            note that this will not modify existing Access Control settings for your clients, and your clients can edit
            their personal Access Control settings at any time to overwrite these defaults.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-transparent rounded mt-2 modal-content">
    <app-access-control [advisorData]="data"></app-access-control>
  </div>
  <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
    <button
      class="flex items-center hover:underline-text border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 focus:outline-none text-regular text-white px-6"
      (click)="closeModal()" [disabled]="isLoading">
      Cancel
    </button>
    <button
      class="flex items-center hover:underline-text text-sm tracking-wide w-full sm:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-6 text-bold ml-2"
      (click)="submit()" [disabled]="isLoading">
      <div class="loading-red mr-2" *ngIf="isLoading"></div>
      Save
    </button>
  </div>
</app-common-modal>
