import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ClientsService } from 'src/app/professional-dashboard/services/clients.service';
import { ProfessionalDashboardService } from 'src/app/professional-dashboard/services/professional-dashboard.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { PROFESSIONAL_ROLE, RECORD_STATUS, SELECT_VALUE, SORT_BY_CLIENTS, USER_TYPES } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../../../../services/local-storage.service';


@Component({
  selector: 'app-home-professional-client',
  templateUrl: './home-professional-client.component.html',
  styleUrls: ['./home-professional-client.component.css'],
})
/**
 * professional client home page class
 */
export class HomeProfessionalClientComponent implements OnInit {
  @ViewChild('clientTable') clientTable: ElementRef;

  public advisorData: {};
  public dataLoading: boolean;
  public clientList: Array<Object>;
  public profileMenuToggle: boolean;
  public profileMenuToggleBuffer: boolean;
  public index: number;
  public spinner: boolean;
  public submitLoaderDelete: boolean;
  public paginationLinks: Array<any>;
  public lastPage: number;
  public selectOptions: Array<Object>;
  public orderOptions: Array<Object>;
  public proClientTableSettings: proClientTableSettingsI;
  public recordStatus: {}[];
  public currentPage = 1;
  public clientImageSrc: string;
  public clientId: string;
  public agentTerm: string;
  public ROUTE: { [key: string]: string } = {
    OVERVIEW: '/overview',
    EXEC_BENE_VIEW: '/executor-beneficiary/executors-beneficiaries-view',
    ABOUT_YOURSELF: '/about-yourself',
  };
  public professionalType: string;
  public proID = '';
  public readonly PROFESSIONAL_ROLE = PROFESSIONAL_ROLE;
  public isSendInvite: boolean = false;
  public dateFormat: string;
  public clientDeletePermissions:ClientRemovePermissionI[] = [];
  public generateUrlLink:boolean = false;


  /**
   * constructor
   */
  constructor(
    private clientService: ClientsService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private modalService: CommonModelService,
    private toastrService: ToastrService,
    private slugInterceptorService: SlugInterceptorService,
    private professionalDashboardService: ProfessionalDashboardService,
  ) {
    this.dateFormat = this.commonHelper.domainViewTableDateFormat;
  }


  /**
   * loaded initally
   */
  ngOnInit(): void {
    this.proClientTableSettings = this.localStorageService.getDataByKey('proClientTableSettings') || {
      sortPreference: SortPreference.Name,
      recordsPerPage: 5,
      searchKeyword: '',
    };
    this.proClientTableSettings.searchKeyword = '';
    this.proID = this.localStorageService.getUserData(USER_TYPES.pro).user.id;
    this.professionalDashboardService.getDefaultAccessControlList(this.proID).subscribe({
      next: (r) => this.advisorData = { permission: r, professional_id: this.proID },
    });
    this.professionalType = this.localStorageService.getDataByKey('role');
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.clientImageSrc = `${environment.BASE_URL_RAW}uploads/profile_photo/`;
    this.currentPage = 1;
    this.recordStatus = RECORD_STATUS;
    this.selectOptions = SELECT_VALUE;
    this.orderOptions = SORT_BY_CLIENTS;
    this.getClients();

  }


  /**
   * get all agents
   */
  public getClients() {
    this.profileMenuToggle = false;
    this.dataLoading = true;
    this.spinner = true;
    this.localStorageService.storeData('proClientTableSettings', this.proClientTableSettings);
    this.clientService
      .getClients(this.currentPage, this.proClientTableSettings.recordsPerPage, this.proSortPreference, this.proClientTableSettings.searchKeyword)
      .subscribe({
        next: (response) => {
          this.spinner = false;
          this.clientList = response.data.data;
          this.updateRemovePermission();
          this.paginationLinks = response.data.links;
          this.lastPage = response.data.last_page;
          this.sortClientList();
          if (this.clientTable && this.clientTable.nativeElement) {
            this.clientTable.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        },
        error: () => this.spinner = false,
      });


  }


  /**
   * open delete modal
   * @param{any}client
   */
  public openConfirmationModal(client: any) {
    this.clientId = client.id;
    this.modalService.open('delete-confirmation-modal');
    this.profileMenuToggle = false;
  }


  /**
   * delete client
   * @param{string}client
   */
  public deleteClient(client: any) {
    this.submitLoaderDelete = true;
    if (client) {
      this.clientService.deleteClient({ id: this.clientId }).subscribe((response) => {
          this.modalService.close('delete-confirmation-modal');
          this.toastrService.success(response.message);
          this.getClients();
          this.submitLoaderDelete = false;
        },
        (exception: any) => {
          this.submitLoaderDelete = false;

          this.commonHelper.httpResponseHandler(exception.error);
        },
      );
    } else {
      this.submitLoaderDelete = false;
    }
    this.profileMenuToggle = false;
  }


  /**
   * on select button
   * @param {[key: string]: any} client
   * @param {string} route
   */
  public onSelectButton(client: { [key: string]: any }, route?: string) {
    this.localStorageService.storeData('overview_user_id', client['id'])
    if (client?.['advisor_permission']?.permission?.length >= 1) { // Overall Advisor Permissions
      this.localStorageService.deleteDataByKey('agent_id');

      if (route === this.ROUTE.OVERVIEW) { // Handle Overview route
        void this.slugInterceptorService.navigate([route, client['user_request'].id], null, false);
        this.localStorageService.storeData('req_id', client['user_request'].id);

      } else if (route === this.ROUTE.EXEC_BENE_VIEW) { // Handle Executive/Beneficiaries route
        if (client.user_request.request_stage < 8) {
          this.toastrService.info('User has not added Executors yet.');
        } else {
          void this.slugInterceptorService.navigate([route, client['user_request'].id], null, false);
        }
      } else {
        void this.slugInterceptorService.navigate([route, client['user_request'].id], null, false);
      }
    } else if (!client?.['advisor_permission'] && client?.['user_request']?.professional_id) {
      if (route === this.ROUTE.OVERVIEW) {
        void this.slugInterceptorService.navigate([route, client['user_request'].id], null, false);
        this.localStorageService.storeData('req_id', client['user_request'].id);
        this.localStorageService.storeData('agent_id', client['user_request'].professional_id);

      } else if (route === this.ROUTE.EXEC_BENE_VIEW) { // Handle Executive/Beneficiaries route
        if (client.user_request.request_stage < 8) {
          this.toastrService.info('User has not added Executors yet.');
        } else {
          void this.slugInterceptorService.navigate([route, client['user_request'].professional_id], null, false);
        }
      } else {
        void this.slugInterceptorService.navigate([route, client['user_request'].professional_id], null, false);
      }
    } else {
      // this.professionalDashboardService.getAccessControlList().subscribe
      this.toastrService.error('Client Permission Required');
    }
  }


  /**
   * toggle dropdown
   * Index less than zero should close all.
   * @param{number}ind
   */
  public toggleDropDown(ind: number) {
    // If 'close index', Menu is Open & its just been opened :: don't close it
    // Else if 'close index' :: close it
    // Else :: open at index.
    if (ind < 0 && this.profileMenuToggle && this.profileMenuToggleBuffer) {
      this.profileMenuToggleBuffer = false;
    } else if (ind < 0) {
      this.profileMenuToggle = false;
    } else {
      this.profileMenuToggleBuffer = true;
      this.profileMenuToggle = !this.profileMenuToggle;
      this.index = ind;
    }
  }


  /**
   * toggle import client modal
   *
   * @param {any} editValue
   */
  public toggleModal(editValue?: any): void {
    this.modalService.open('import-client-modal');
  }


  /**
   * togglebulk import client modal
   *
   * @param {any} editValue
   */
  public toggleBulkImportModal(editValue?: any): void {
    this.isSendInvite = false;
    this.modalService.open('bulk-import-client-modal');
  }

  /**
   * Toggle bulk send invite
   */
  public toggleBulkSendInvite(): void {
    this.isSendInvite = true;
    this.modalService.open('bulk-import-client-modal');
  }


  /**
   * toggle search modal
   */
  public toggleSearchModal() {
    this.modalService.open('open-search-modal');
  }


  /**
   * Modal event listner
   *
   * @param {any} data
   */
  public modalEventListner(data: any): void {
    this.getClients();
    this.commonHelper.diffCheckerAndUpdate(
      this.clientList,
      'id',
      data?.id,
      data,
    );
  }

  /**
   * Handles button group events.
   *
   * @param {Event} event - The event object triggered by the button group.
   * @returns {void}
   */
  public buttonGroupEventListener(event){
    if(Object.keys(event).length > 0){
      this.modalEventListner(event);
    }
  }

  /**
   * search agent based on keyword
   * @param{string}keyword
   */
  public onSearchClient(keyword: string) {
    this.proClientTableSettings.searchKeyword = keyword;
    if (keyword) {
      this.getClients();
    } else {
      this.spinner = true;
      this.localStorageService.storeData('proClientTableSettings', this.proClientTableSettings);
      this.clientService.getClients(this.currentPage, this.proClientTableSettings.recordsPerPage, this.proSortPreference, this.proClientTableSettings.searchKeyword).subscribe({
        next: (response) => {
          this.spinner = false;
          this.clientList = response.data.data;
          this.updateRemovePermission();
          this.lastPage = response.data.last_page;
          this.sortClientList();
        }, error: () => this.spinner = false,
      });
    }
  }


  /**
   * agent list
   * @param {number}pageNo
   */
  public getClientList(pageNo?: number) {
    if(this.currentPage === pageNo) return
    if (pageNo) {
      this.currentPage = pageNo;
    }
    this.spinner = true;
    this.localStorageService.storeData('proClientTableSettings', this.proClientTableSettings);
    this.clientService
      .getClients(this.currentPage, this.proClientTableSettings.recordsPerPage, this.proSortPreference, this.proClientTableSettings.searchKeyword)
      .subscribe({
        next: (response) => {
          this.clientList = response.data.data;
          this.updateRemovePermission();
          this.paginationLinks = response.data.links;
          this.lastPage = response.data.last_page;
          this.spinner = false;
          this.sortClientList();
        },
        error: () => this.spinner = false
      });
  }


  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, true);
  }

  /**
   * Opens the modal for generating a URL link.
   */
  public onClickGenerateLink(){
    this.generateUrlLink = true;
    this.modalService.open('generate-url-link-modal');
  }

  public clickACL() {
    this.modalService.open('access-control-defaults-modal');
  }


  // Function to sort the clientList based on the current sortPreference
  public sortClientList() {
    switch (this.proClientTableSettings.sortPreference) {
      case SortPreference.OldToNew:
        // Sort by Old to New
        this.clientList.sort((a, b) => {
          console.log(a);
          const dateA = new Date(a['created_at']);
          const dateB = new Date(b['created_at']);

          if (dateA < dateB) {
            return -1;
          }

          if (dateA > dateB) {
            return 1;
          }

          return 0;
        });
        break;

      case SortPreference.NewToOld:
        // Sort by New to Old
        this.clientList.sort((a, b) => {
          const dateA = new Date(a['created_at']);
          const dateB = new Date(b['created_at']);

          if (dateA > dateB) {
            return -1;
          }

          if (dateA < dateB) {
            return 1;
          }

          return 0;
        });
        break;

      default:
        // Handle the default case (no sorting)
        break;
    }
  }


  /**
   * Get whether this user can edit default ACL for clients.
   */
  get canEditDefaultACL() {
    return (this.localStorageService.getUserData(USER_TYPES.pro)?.user?.can_edit_default_acl ?? 0) == 1;
  }


  get proSortPreference(): SortPreference {
    return this.proClientTableSettings.sortPreference === SortPreference.Advisor || SortPreference.Advisor_Permission ? this.proClientTableSettings.sortPreference : SortPreference.Name;
  }

  /**
   * Checks if a client can be removed based on their ID.
   * @param clientId - The ID of the client to check.
   * @returns True if the client can be removed, otherwise false.
   */
  public canRemoveClient(clientId):boolean {
    const client = this.clientDeletePermissions.find(c => c.clientId === clientId);
    return client ? client.removePermission : false;
  }

  /**
   * Updates the remove permissions for each client in the client list.
   * it sets removePermission to true for that client.
   */
  public updateRemovePermission(){
    this.clientList.forEach((client:any) => {
      const canRemoveClient = client.user_request?.user_people.some(user_people => (user_people['advisor'] == 1 && user_people['professional_id'] === this.proID));
      this.clientDeletePermissions.push({
        clientId: client.id,
        removePermission: canRemoveClient
      });
    });
  }
}


enum SortPreference {
  Name = 'desc',
  Advisor = 'advisor',
  OldToNew = 'Old to New',
  NewToOld = 'New to Old',
  Advisor_Permission = 'advisor_permission'
}


interface proClientTableSettingsI {
  sortPreference: SortPreference,
  recordsPerPage: number,
  searchKeyword: string
}

export interface ClientRemovePermissionI {
  clientId: string;
  removePermission: boolean;
}


