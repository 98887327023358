import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { requirePasswordToBeCheckedValidator } from 'src/app/helper/customValidatorPassword.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { ProfileService } from 'src/app/services/profile.service';
import { UserService } from 'src/app/services/user.service';
import { EMAIL_PATTERN } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-professional-profile',
  templateUrl: './professional-profile.component.html',
})
/**
 * professional profile class
 */
export class ProfessionalProfileComponent implements OnInit {
  public accountDetailForm: FormGroup;
  public accountDetailsSubmit: boolean;
  public changePasswordForm: FormGroup;
  public changePasswordSubmit: boolean;
  public faEye: any;
  public faEyeSlash: any;
  public togglePasswordView: boolean;
  public toggleOldPasswordView: boolean;
  public toggleNewPasswordView: boolean;
  public menuActive: number = 1;
  public imageSrc: string;
  public uploadForm: FormGroup;
  public preDefinedLoader:boolean;
  public accountDetails:any;
  public changeEmail:boolean;
  private availableMenu:number;
  @ViewChild('propicfield') propicfield: ElementRef;

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private personalDetailsService:PersonalDetailsService,
    private profileService:ProfileService,
    private commonHelper:CommonHelper,
    private toastrService: ToastrService,
    private activeRouter:ActivatedRoute,
    private userService:UserService,
  ) { }

  /**
   * Account Detail Form called initially on page load
   *
   */
  ngOnInit(): void {
    this.availableMenu = 3;
    // reading query params for menu ( reset password redirection )
    const queryParams = this.activeRouter.snapshot.queryParamMap;
    const menu = parseInt(queryParams.get('menu'));
    if (menu <= this.availableMenu) {
      this.menuActive = menu;
    }

    this.faEye = faEye;
    this.changeEmail = false;
    this.faEyeSlash = faEyeSlash;
    this.togglePasswordView = false;
    this.toggleOldPasswordView = false;
    this.toggleNewPasswordView = false;
    this.accountDetailForm = this.formBuilder.group({
      'email': ['', {updateOn: 'blur',validators:[Validators.required, Validators.pattern(EMAIL_PATTERN)]}],
      'password': ['', {updateOn: 'blur',validators:[Validators.required, Validators.minLength(8)]}],
      'profile_photo': [''],
    });
    this.changePasswordForm = this.formBuilder.group({
      'password': ['', [Validators.required, Validators.minLength(8), requirePasswordToBeCheckedValidator()]],
      'new_password': ['', {updateOn: 'blur',validators:[Validators.required, Validators.minLength(8)]}],
    });
    this.uploadForm = this.formBuilder.group({
      avatar: [null],
      file: [''],
    });
    this.getCurrentUserDetails();
  }

  /**
   * Get personal details for filling profile details
   */
  public getCurrentUserDetails():void {
    this.preDefinedLoader = true;
    this.userService.getCurrentUserDetails().subscribe((response:APIResponseModel)=>{
      this.preDefinedLoader = false;

      if (response.status) {
        this.accountDetails = { ...response.data };
        this.imageSrc = `${environment.BASE_URL_RAW}uploads/${this.accountDetails.profile_photo}`;
        // Patch value
        this.accountDetailForm.patchValue({
          email: response.data.email,
        });
      }
    });
  }

  /**
   * Account Detail form submit Functionality
  */
  public accountDetailFormSubmit(): void {
    this.accountDetailsSubmit = true;
    const sendingData = this.accountDetailForm.value;

    const formData = new FormData();
    // appending every value to form object
    Object.keys(sendingData).forEach((key)=>{
      if (!sendingData[key]) return;
      formData.append(key, sendingData[key]);
    });

    /**
     * Update email, profile pic
     */
    this.profileService.updateEmail(formData).subscribe((response:APIResponseModel)=>{
      this.accountDetailsSubmit = false;
      if (response.status) {
        this.changeEmail = false;
        this.toastrService.success(response.message);
        this.getCurrentUserDetails();
      }
    }, (exception:any)=>{
      this.accountDetailsSubmit = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
   * Account details form object getter for validation and showing errors in html
  */
  get accountDetailFormGet() {
    return this.accountDetailForm.controls;
  }

  /**
   * Change Pasword form submit Functionality
  */
  public changePasswordFormSubmit(): void {
    this.changePasswordSubmit = true;
    this.profileService.changePassword(this.changePasswordForm.value).subscribe((response:APIResponseModel)=>{
      this.changePasswordSubmit = false;
      if (response.status) {
        this.changePasswordForm.reset();
        this.toastrService.success(response.message);
      }
    }, (exception)=>{
      this.changePasswordSubmit = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }


  /**
  * Change Password form object getter for validation and showing errors in html
  *
  */
  get changePasswordFormGet() {
    return this.changePasswordForm.controls;
  }

  /**
   * Menu on click scroll to related card
   *
   * @param {HTMLElement} el
   * @param {string} key
   *
   *  !Do not remove this code for now
   */


  /**
   *
   * Password confirmation
   * @param {string} oldpassword
   * @param {string} newpassowrd
   * @return {FormGroup}
   *
   * !Do not remove this code for now
  */


  /**
   * On change image upload with preview
   *
   * @param {string} event
   */
  public readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageSrc = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      // updating on form
      this.accountDetailForm.patchValue({
        profile_photo: event.target.files[0],
      });
    }
  }

  /**
   * Menu change
   *
   * @param {number} menu
   */
  public menuChange(menu:number):void {
    this.changeEmail = false;
    this.imageSrc = `${environment.BASE_URL_RAW}uploads/${this.accountDetails.profile_photo}`;
    this.accountDetailForm.reset({
      email: this.accountDetails.email,
      password: this.accountDetails.password,
    });
    this.changePasswordForm.reset();
    this.menuActive = menu;
  }
}
