import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { requirePasswordToBeCheckedValidator } from 'src/app/helper/customValidatorPassword.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { CommonService } from 'src/app/services/common.service';
import { SlugService } from 'src/app/services/slug.service';
import { EMAIL_PATTERN } from 'src/constants/application.const';
import { environment } from '../../../../../environments/environment';
import { LocalStorageService } from "../../../../services/local-storage.service";

@Component({
  selector: 'app-professional-change-password',
  templateUrl: './professional-change-password.component.html',
})
/**
 * change password for both reset and forgot password
 */
export class ProfessionalChangePasswordComponent implements OnInit {
  public faEye: any;
  public faEyeSlash: any;
  public togglePasswordViewc: boolean;
  public togglePasswordView: boolean;
  public changePasswordForm: FormGroup;
  public submitLoader: boolean;
  public orgLogo: string;
  public orgLogoPath: string;
  public menuActive: string;
  public proTypeText: string;

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private activeRouter: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private router: Router,
    private professionalUserService: ProfessionalUserService,
    private toastService: ToastrService,
    private commonHelper: CommonHelper,
    private slugService: SlugService,
    private slugInterceptorService: SlugInterceptorService,
    private commonService: CommonService,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.proTypeText = this.localStorageService.getDataByKey('pro-type')?.[0]?.['displayText'];
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      } else {
        this.orgLogoPath = '';
        this.orgLogo = '';
      }
    });
    this.togglePasswordViewc = false;
    this.togglePasswordView = false;

    // Get token and email from url
    const queryParams = this.activeRouter.snapshot.queryParamMap;

    this.changePasswordForm = this.formBuilder.group({
      'token': [(queryParams.get('token') || ''), [Validators.required]],
      'email': [(queryParams.get('email') || ''), {updateOn: 'change',validators:[Validators.required, Validators.pattern(EMAIL_PATTERN)]}],
      'password': ['', [Validators.required, Validators.minLength(8), requirePasswordToBeCheckedValidator()]],
      'password_confirmation': ['', {updateOn: 'change',validators:[Validators.required, Validators.minLength(8)]}],
    }, {
      validator: this.ConfirmedValidator('password', 'password_confirmation'),
    });
    this.faEye = faEye;
    this.faEyeSlash = faEyeSlash;
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.changePasswordForm.controls;
  }

  /**
   *
   * Password confirmation
   * @param {string} oldpassword
   * @param {string} newpassowrd
   * @return {FormGroup}
  */
  public ConfirmedValidator(oldpassword: string, newpassowrd: string): Function {
    return (formGroup: FormGroup) => {
      const currentPassword = formGroup.controls[oldpassword];
      const confirmPassword = formGroup.controls[newpassowrd];
      if (currentPassword.errors && !confirmPassword.errors.confirmedValidator) {
        return;
      }
      if (currentPassword.value !== confirmPassword.value) {
        confirmPassword.setErrors({ confirmedValidator: true });
      } else {
        confirmPassword.setErrors(null);
      }
    };
  }

  /**
   * Reset password
   */
  public resetPassword(): void {
    this.submitLoader = true;
    this.professionalUserService.resetPassword(this.changePasswordForm.value).subscribe((response: APIResponseModel) => {
      this.submitLoader = false;
      if (response.status) {
        this.toastService.success(response?.message);
        void this.slugInterceptorService.navigate(['login'], null, true);
      }
    }, (exception) => {
      this.submitLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
   * @param{string}url
   * on menu change
   */
  public menuChange(url?: string) {
    this.menuActive = url;
    void this.slugInterceptorService.navigate(url ? [url] : ['/'], null, true);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }
}
