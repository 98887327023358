import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {APIResponseModel} from '../interface/response.interface';

/**
 * Schedule services
 */
@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  private BASE_URL: string;
  constructor(
    private http: HttpClient
  ) {
    this.BASE_URL = environment.BASE_URL;
   }
   public updateScheduleStatus(data: any): Observable<APIResponseModel>{
      return this.http.post<APIResponseModel>(this.BASE_URL + `/professional/update-schedule-status`, data);
   }
   public getScheduleDetailsById(id:any): Observable<APIResponseModel>{
    return this.http.get<APIResponseModel>(this.BASE_URL + `/professional/get-schedule-details/${id}`);
   }
}
