import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';
import { DatePipe } from '@angular/common';
import { CommonHelper } from '../helper/common.helper';

import * as moment from 'moment';

@Pipe({
  name: 'insuranceDateFilter',
})
export class InsuranceDateFilterPipe implements PipeTransform {
  constructor(
    private commonHelper: CommonHelper,
    private datePipe: DatePipe) {

  }
  /**
   * Transform functions
   *
   * @param {any} value
   * @param {number} count
   * @return {any}
   */
  transform(value: any, count: number): any {
    let data = orderBy(value, ['date'], ['asc']);
    count == 10 ? data = data.filter((value, index) => {
      value.date = this.datePipe.transform(value.date, this.commonHelper.domainViewDateFormat + ', h:mm a'); return index < 10;
    }) : data = data.filter((value, index) => value.date = this.datePipe.transform(value.date, this.commonHelper.domainViewDateFormat + ', h:mm a'));

    return data;
  }
}
