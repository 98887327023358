<app-carousal [hideNav]="false" [hideDots]="true">
  <!-- START: First Slide -->
  <div>
    <img alt="Guided, Step-by-Step Setup" class="mx-auto object-contain slide-img" src="assets/images/register-carousel/guide.jpg" />
    <h2 class="text-darkblue font-ave-medium text-center text-xl lg:text-2xl mx-auto w-10/12 lg:w-9/12 leading-tight pt-4 lg:pt-8 pb-2 lg:pb-4">
      Guided, Step-by-Step Setup
    </h2>
    <p class="font-ave-regular text-grey text-sm lg:text-lg w-10/12 lg:w-9/12 mx-auto text-center">
      LegacyNOW guides you through all possible asset categories
      so you can be sure nothing is missed when you upload your
      information into your Digital Vault.
    </p>
  </div>
  <!-- END: First Slide -->

  <!-- BEGIN: Second Slide -->
  <div>
    <img alt="Impenetrably Secure. Universally Accessible." class="mx-auto object-contain slide-img" src="assets/images/register-carousel/secure.jpg">
    <h2 class="text-darkblue font-ave-medium text-center text-xl lg:text-2xl mx-auto w-10/12 lg:w-9/12 leading-tight pt-4 lg:pt-8 pb-2 lg:pb-4">
      Impenetrably Secure. Universally Accessible.
    </h2>
    <p class="font-ave-regular text-grey text-sm lg:text-lg w-10/12 lg:w-9/12 mx-auto text-center">
      Built to modern security standards on the very latest bank-level encryption technology to make sure your vital information is
      accessible to only you and those you designate.
    </p>
  </div>
  <!-- END: Second Slide -->

  <!-- BEGIN: Third Slide -->
  <div>
    <img alt="A Clear Path Forward" class="mx-auto object-contain slide-img" src="assets/images/register-carousel/forward.jpg">
    <h2 class="text-darkblue font-ave-medium text-center text-xl lg:text-2xl mx-auto w-10/12 lg:w-9/12 leading-tight pt-4 lg:pt-8 pb-2 lg:pb-4">
      A Clear Path Forward
    </h2>
    <p class="font-ave-regular text-grey text-sm lg:text-lg w-10/12 lg:w-9/12 mx-auto text-center">
      You will designate a Digital Vault Custodian—someone you trust with all this information should you pass—so that you can rest assured that your digital legacy will be in good hands.
    </p>
  </div>
  <!-- END: Third Slide -->
</app-carousal>
