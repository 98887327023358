import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { CommonService } from 'src/app/services/common.service';
import { SlugService } from 'src/app/services/slug.service';
import { EMAIL_PATTERN } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../../../../services/local-storage.service';

@Component({
  selector: 'app-professional-forgot-password',
  templateUrl: './professional-forgot-password.component.html',
})
/**
 * forgot password professional
 */
export class ProfessionalForgotPasswordComponent implements OnInit {
  public resetRequestForm:FormGroup;
  public submitLoader:boolean;
  public resendOtpTimer: number;
  public orgLogo: string;
  public orgLogoPath: string;
  public menuActive: string;
  public proTypeText: string;

  /**
   * @constructor
   */
  constructor(
    private formBuilder:FormBuilder,
    private professionalUserService:ProfessionalUserService,
    private toastService:ToastrService,
    private commonHelper: CommonHelper,
    private slugService: SlugService,
    private commonService: CommonService,
    private slugInterceptorService: SlugInterceptorService,
    private localStorageService: LocalStorageService,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.proTypeText = this.localStorageService.getDataByKey('pro-type')?.[0]?.['displayText'];
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      } else {
        this.orgLogoPath = '';
        this.orgLogo='';
      }
    });
    this.resetRequestForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
    });
  }
  /**
   * start redsend timer
   */
  public startResendTimer():void {
    this.submitLoader = true;
    // resend otp timer
    this.resendOtpTimer = 30;
    const resendInterval = setInterval(()=>{
      this.resendOtpTimer -= 1;
      if (!this.resendOtpTimer) {
        clearInterval(resendInterval);
      }
    }, 1000);
  }
  /**
   * Reset request onsubmit function
   */
  public resetRequest():void {
    this.startResendTimer();
    this.professionalUserService.requestResetPassword(this.resetRequestForm.value).subscribe((response:APIResponseModel)=>{
      this.submitLoader = false;
      if (response.status) {
        this.toastService.success(response?.message);
      }
    }, (exception)=>{
      this.submitLoader = false;
      this.resendOtpTimer = undefined
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.resetRequestForm.controls;
  }

  /**
   * change route
   * @param{string}url
    */
  public changeRoute(url:string) {
    void this.slugInterceptorService.navigate(['/'], null, true);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }
}
