<app-common-modal id="otp-verification-modal" class="app-common-modal">

  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
         (click)="closeModel()">
    <h2 class="text-regular text-2xl font-semibold tracking-wide pb-2 text-darkblue">
      Email Verification Code Sent!
    </h2>
    <p class="text-grey text-regular py-1">
      A verification code was sent to {{newEmail ?? '{error: unknown}'}}. Please enter the OTP verification code below.
    </p>


    <form [formGroup]="otpVerificationForm" (ngSubmit)="onOtpVerify()" autocomplete="off" class="p-2 grid grid-cols-6">
      <label for="otp" class="text-label-grey text-sm text-regular col-span-6">Enter your OTP*</label>
      <div class="col-span-6 sm:col-span-4">
        <input type="text" id="otp"
               class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
               placeholder="Enter your OTP" name="otp" formControlName="otp"
               [ngClass]="verifyOtpFormGetter.otp.errors && verifyOtpFormGetter.otp.touched?'form-input-error':'form-input-focus'">
        <div *ngIf="verifyOtpFormGetter.otp.errors && verifyOtpFormGetter.otp.touched">
          <p class="text-regular font-medium text-xs mt-2 error-alert"
             *ngIf="verifyOtpFormGetter.otp.errors.minlength">
            OTP should be 6 numbers long
          </p>
          <p class="text-regular font-medium text-xs mt-2 error-alert"
             *ngIf="verifyOtpFormGetter.otp.errors.required">
            OTP is required
          </p>
        </div>
      </div>

      <div class="flex justify-end sm:justify-start w-auto sm:w-full col-span-6 sm:col-span-2 m-2">
        <app-button-loader [buttonText]="'Verify OTP'" [valid]="otpVerificationForm.valid" [loader]="submitLoader"
                           [buttonType]="'submit'"></app-button-loader>
      </div>
    </form>
  </div>
</app-common-modal>
