import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fileExtension',
})
/**
 * Get file extensions
 */
export class FileExtensionPipe implements PipeTransform {
  /**
   * Transform functions
   *
   * @param {any} value
   * @param {Array<any>} args
   * @return {any}
   */
  transform(value: any, ...args: any[]): any {
    return value.split('.')[1];
  }
}
