<div class="relative flex items-center justify-center questions-short-banner__wrap mh-800-height new-landing-height">
  <div class="max-w-screen mx-auto z-30 custom-width">
    <div class="grid grid-cols-12 gap-4 lg:gap-12">
      <div class="col-span-12 lg:col-span-8">
        <ng-container>
          <h1 class="text-white text-center font-ave-black">
                <span class="font-ave-black">Your loved ones <br/><span
                  class="font-ave-black-oblique hl-text">will</span>
                  have questions.</span>
            <span class="font-ave-black">Provide the answers today.</span>
            <span class="font-ave-black">It’s easier than you think.</span>
          </h1>
        </ng-container>
      </div>

      <div class="col-span-12 md:col-span-12 lg:col-span-4" *ngIf="!isSmallWindow">
        <div class="hero-box">
          <h2 class="font-ave-black text-darkblue text-left">
            Keep your digital legacy securely organized and accessible.
          </h2>

          <button (click)="menuChange('register')"
                  class="bg-primary font-ave-medium text-white rounded-sm p-3 lg:p-4 text-base lg:text-lg xl:text-xl w-full uppercase my-6 lg:my-8 focus:outline-none start-now">
            Start Now
          </button>
          <p class="font-ave-black text-darkblue text-left">NO OBLIGATION</p>
          <p class="text-black text-left font-ave-semibold">
            Cancel within the next 30 days for a full refund.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN: About Digital Vault Wrapper -->
<div class="bg-primary-lite py-5 md:py-12">
  <div class="grid grid-cols-12 gap-4 lg:gap-8">
    <div class="col-span-12 overflow-hidden">
      <div
        class="marquee qs-marquee flex items-center justify-center divide-x-2 divide-primary text-center font-bold leading-6">
        <div
          class="px-6 whitespace-nowrap text-base md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          What credit cards did Mom have?
        </div>
        <div
          class="px-6 whitespace-nowrap text-base md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          Did they have a timeshare?
        </div>
        <div
          class="px-6 whitespace-nowrap text-base md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          Did Dad have life insurance?
        </div>
        <div
          class="px-6 whitespace-nowrap text-base md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          What credit cards did Mom have?
        </div>
        <div
          class="px-6 whitespace-nowrap text-base md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          Did they have a timeshare?
        </div>
        <div
          class="px-6 whitespace-nowrap text-base md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          Did Dad have life insurance?
        </div>
        <div
          class="px-6 whitespace-nowrap text-base md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          What credit cards did Mom have?
        </div>
        <div
          class="px-6 whitespace-nowrap text-base md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          Did they have a timeshare?
        </div>
        <div
          class="px-6 whitespace-nowrap text-base md:text-2xl lg:text-3xl xl:text-4xl text-bold italic text-pale-blue capitalize cursor-default">
          Did Dad have life insurance?
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: About Digital Vault Wrapper -->
