import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { FileValidationOptionsInterface, SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { DlbService } from 'src/app/services/dlb.service';
import { ReceivedDlbService } from 'src/app/services/received-dlb.service';
import { ALPHA_NUMERIC_PATTERN, NUMBERS_ONLY_PATTERN, SELECT_OPTIONS_YES_NO } from 'src/constants/application.const';
import { CLEAR_SELECTED_FILE } from 'src/constants/digitalFile.const';


@Component({
  selector: 'app-verify-departed',
  templateUrl: './verify-departed.component.html',
})
/**
 * Verify departed component
 */
export class VerifyDepartedComponent implements OnInit {
  public readonly informCreditor: Array<SelectOptionsInterface> = SELECT_OPTIONS_YES_NO;
  public requestId: string;
  public verifyDeathForm: FormGroup;
  public currentDateRestriction: string;
  public fileValidationOptions: FileValidationOptionsInterface;
  public submitLoader: boolean;
  public causeOfDeath: Array<SelectOptionsInterface>;
  public certificateTypes: Array<SelectOptionsInterface>;
  public identificationTypes: Array<SelectOptionsInterface>;
  public personNameLocal: string;
  public locale = 'en';
  public maxDate: Date;
  public bsConfig: Partial<BsDatepickerConfig>;
  public domainDateFormat = this.commonHelper.domainDateFormat;
  public toggleEmiterListener: boolean = false; // toggle listener

  /**
   * @constructed
   */
  constructor(
    private modalService: CommonModelService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private commonHelper: CommonHelper,
    private dlbService: DlbService,
    private receivedDlbService: ReceivedDlbService,
    private toastService: ToastrService,
    private localeService: BsLocaleService,
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    // file valiadtion options
    this.getDetailsForReceivedDlb();
    this.fileValidationOptions = {
      size: 100000,
      fileFormat: ['pdf'],
    };
    this.receivedDlbService.selectedUserObserve.subscribe((response: any) => {
      if (Object.keys(response).length > 0) {
        this.personNameLocal = response.first_name + ' ' + response.last_name;
        this.requestId = response.request_id;
      }
    });
    this.currentDateRestriction = this.commonHelper.getCurrentDateForRestriction();

    // form builder for death vaerification
    this.verifyDeathForm = this.formBuilder.group({
      date_of_death: ['', [Validators.required]],
      // cause_of_death: ['', [Validators.required]],
      certificate: ['', [Validators.required]],
      certificate_number: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_PATTERN)]],
      identification: ['', [Validators.required]],
      identification_number: ['', [Validators.required, Validators.pattern(ALPHA_NUMERIC_PATTERN)]],
      document: ['', [Validators.required]],
      inform_creditor: ['1', [Validators.required]],
    });
    this.applyLocale();
    this.bsConfig = Object.assign({}, {
      showWeekNumbers: false,
      maxDate: this.maxDate,
      // showTodayButton: true,
      // showClearButton: true,
      dateInputFormat: this.commonHelper.domainDateFormat,
    });
  }

  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }

  /**
   * set person name for popup
   *
   * @param {string} name
   */
  @Input() set personName(name: string) {
    if (!name) return;
    this.personNameLocal = name;
    console.log(name);
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.verifyDeathForm.controls;
  }

  /**
   * Get details for receined Digital Vault
   */
  public getDetailsForReceivedDlb(): void {
    forkJoin([this.commonService.getCauseOfDeathDocumentTypes(), this.commonService.getCauseOfDeathIdentificationTypes()])
      .subscribe((response: Array<APIResponseModel>) => {
        this.certificateTypes = this.commonHelper.convertToOptionsFormat(response[0]?.data, 'id', 'document_type');
        this.identificationTypes = this.commonHelper.convertToOptionsFormat(response[1]?.data, 'id', 'id_name');
      });
  }

  /**
   * Upload file death certificate
   *
   * @param {any} file
   */
  public uplaodDeathCertificate(file: any): void {
    this.verifyDeathForm.patchValue({
      document: file,
    });
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.verifyDeathForm.reset();
    this.commonService.fileHanlderObservableEvents({ type: CLEAR_SELECTED_FILE });
    this.receivedDlbService.setUser({});
    this.modalService.close('verify-departed-modal');
  }

  /**
   * Handling on form submit
   */
  public onSubmit(): void {
    this.submitLoader = true;
    this.verifyDeathForm.value.date_of_death = this.commonHelper.formatDate(this.verifyDeathForm.value.date_of_death);
    const requestData = { ...this.verifyDeathForm.value, request_id: this.requestId, requestIdOverride: true };
    const formData = new FormData();
    // appending every value to form object
    Object.keys(requestData).forEach((key) => {
      if (!requestData[key]) return;
      formData.append(key, requestData[key]);
    });
    this.dlbService.verifyDeparted(formData).subscribe((response: APIResponseModel) => {
      this.submitLoader = false;
      if (response.status) {
        this.closeModel();
        this.dlbService.dlbListingTrigger(true);
        this.toastService.success(response.message);
      }
    }, (exception: any) => {
      this.submitLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }


  /**
   * callback for cause of death
   *
   * @param {SelectOptionsInterface} selectedCause
   */
  public optionChangeEmitterCauseOfDeath(selectedCause: SelectOptionsInterface): void {
    this.verifyDeathForm.patchValue({
      cause_of_death: selectedCause.value,
    });
  }

  /**
   * callback for certificate
   *
   * @param {SelectOptionsInterface} selectedCertificate
   */
  public optionChangeEmitterCertificate(selectedCertificate: SelectOptionsInterface): void {
    this.verifyDeathForm.patchValue({
      certificate: selectedCertificate.value,
    });
  }

  /**
   * callback for selectedIdentification
   *
   * @param {SelectOptionsInterface} selectedIdentification
   */
  public optionChangeEmitterIdentification(selectedIdentification: SelectOptionsInterface): void {
    this.verifyDeathForm.patchValue({
      identification: selectedIdentification.value,
    });
  }
  /**
   * callback for selected type
   * @param {SelectOptionsInterface} selectedType
   */
  public optionChangeEmitterCreditorType(selectedType: SelectOptionsInterface) {
    if (selectedType?.value) {
      this.verifyDeathForm.patchValue({
        inform_creditor: ''+selectedType?.value,
      });
      if(this.toggleEmiterListener){
        this.verifyDeathForm.get('inform_creditor').markAsUntouched();
        this.toggleEmiterListener=false;
      }
    }else{
      if(this.toggleEmiterListener){
        this.verifyDeathForm.get('inform_creditor').markAsTouched();
        this.toggleEmiterListener = false;
      }
    }
  }
  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }

  /**
   * toggle emitter
   * @param {boolean} data
   */
  public toggleEmiter(data: boolean){
    if(data){
        this.toggleEmiterListener = true; // toggle listener
       }
    }
}
