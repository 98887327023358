import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CheckoutService } from 'src/app/components/checkout/checkout.service';
import { PaymentOption } from 'src/app/components/checkout/payment-option.model';
import { CardService } from 'src/app/components/consumer-payment-options/card-details/card-details.service';
import { ConsumerSubscriptionService } from 'src/app/components/consumer-payment-section/consumer-subscription.service';
import { CommonHelper } from 'src/app/helper/common.helper';
import { LoadStateHelper } from 'src/app/helper/load-state.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ValidationHelper } from 'src/app/helper/validation.helper';
import { FinalizedAmountInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { ProCheckoutService } from 'src/app/professional-dashboard/services/pro-checkout.service';
import { ProSocketService } from 'src/app/professional-dashboard/services/pro-socket.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MessageModalDataInterface } from 'src/app/sharedComponent/message-modal/message-modal.component';
import { ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN, CREDIT_CARD_MASK, LoginType, NUMBERS_ONLY_PATTERN, USER_TYPES, YEAR_MASK, ZIPCODE_PATTERN } from 'src/constants/application.const';

@Component({
  selector: 'app-pro-checkout',
  templateUrl: './pro-checkout.component.html',
  styleUrls: ['./pro-checkout.component.css']
})
export class ProCheckoutComponent implements OnInit {
  readonly CREDIT_CARD_MASK = CREDIT_CARD_MASK;
  readonly YEAR_MASK = YEAR_MASK;
  public preDefinedLoader: boolean;
  private yearlyPackageDetails: any;
  public domainDateFormat: any;
  public appliedPromoCode: string;
  public promoCodeValidity: string;
  private adjustedValue: FinalizedAmountInterface = { download_price: 0, transfer_price: 0, subscription_price: 0, pay_vault_registration_price: 0 };
  isDVAgreementChecked: boolean = false;
  isPaymentClicked: boolean = false;
  isProfessional: boolean;
  userData: any;
  promoCode: string;
  selectedCardDetails: any;
  cards: any;
  paymentOptions: PaymentOption[];
  userType: string;
  toggleSectionBody: boolean;
  cardToken: string;
  loader: LoadStateHelper;
  total: number;
  isLoading: boolean;
  paymentForm: FormGroup;
  messageData: MessageModalDataInterface = {
    message: [`We see you have typed in a code but have not applied it. Please press <i>Apply</i> or clear the field`],
    buttons: [{ content: 'Okay!', emitValue: true }],
  };
  invoiceNumber: string;

  constructor(
    private slugInterceptorService: SlugInterceptorService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private subscriptionService: ConsumerSubscriptionService,
    private proCheckoutService: ProCheckoutService,
    private proSocketService: ProSocketService,
    private cardService: CardService,
    private formBuilder: FormBuilder,
    private toastService: ToastrService,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private location: Location,
    private validation: ValidationHelper,
    private activeRouter: ActivatedRoute,
    loadStateHelper: LoadStateHelper) {
    this.loader = loadStateHelper;
  }

  ngOnInit(): void {
    this.domainDateFormat = this.commonHelper.domainDateFormat;
    this.activeRouter.queryParams.subscribe(
      (queryParam: Params) => {
        this.invoiceNumber = queryParam['invoice'];
      },
    );
    this.userType = this.localStorageService.getDataByKey('role');
    this.isProfessional = this.commonHelper.getUserRole() === LoginType.PROFESSIONAL
    this.userData = this.localStorageService.getUserData(USER_TYPES.pro);
    this.setupVaultRegistrationPayment();
    this.loadCards();
    this.initForm();
    this.listenPaymentStatus();
  }

  /**
   * Load card items from {@link CheckoutService}.
   * @private
   */
  private loadCart() {
    const cart = this.proCheckoutService.getCart;
    this.paymentOptions.forEach(opt => opt.selected = !!cart.find(cid => cid == opt.id));
    // If nothing is selected, return to previous screen.
    if (!this.paymentOptions.find(opt => opt.selected) || !this.invoiceNumber) {
      this.goBack();
    }
    this.setSelectedOptionAmountDefault();
    this.loader.set('subscription_details');
  }

  private initForm() {
    this.paymentForm = this.formBuilder.group({
      currency: ['usd'],
      new_card: ['1'],
      card_id: [''],
      card_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN), Validators.maxLength(50)] }],
      card_number: ['', { updateOn: 'blur', validators: [Validators.required] }],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.maxLength(10), Validators.pattern(ZIPCODE_PATTERN)] }],
      exp_month: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(2), this.monthValidator.bind(this)] }],
      exp_year: ['', { updateOn: 'blur', validators: [Validators.required, this.yearValidator.bind(this)] }],
      cvv: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern(NUMBERS_ONLY_PATTERN)] }],
    });

    this.validation.setValidationErrors({
      zipcode: { pattern: 'Please enter a valid zipcode' },
      exp_month: { isNotAllowed: 'Please enter a valid month', mask: null },
      exp_year: { isNotAllowed: 'Please enter a valid year', mask: null },
      cvv: { isNotAllowed: 'Please enter a valid security code', mask: null },
    });
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param{string} controlKey
   * @return{Array<string>}
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.paymentForm);
  }

  /**
   * month validation
   * @param {FormControl}control
   * @return {boolean}
   */
  private monthValidator(control: FormControl): { [s: string]: boolean } {
    const enteredMonth = +(control.value);
    if (control.value != '' && (enteredMonth > 12 || enteredMonth < 1)) {
      return { isNotAllowed: true };
    } else {
      return null;
    }
  }

  /**
   * year validation
   * @param {FormControl}control
   * @return {boolean}
   */
  private yearValidator(control: FormControl): { [s: string]: boolean } {
    const enteredYear = +(control.value);
    if (control.value != '' && enteredYear < new Date().getFullYear() && enteredYear != 0) {
      return { isNotAllowed: true };
    } else {
      return null;
    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get getForm() {
    return this.paymentForm?.controls;
  }

  /**
   * get card details
   */
  private loadCards() {
    const isPro:boolean = true;
    this.cardService.getCardsApi(isPro).subscribe(
      {
        next: (response: any) => {
          this.cards = response?.data;
          this.cards.forEach((current, index) => {
            if (current.is_default == 1) {
              this.selectedCard(this.cards[index]);
            }
          });
        },
        complete: () => {
          this.preDefinedLoader = false;
        },
      });
  }

  /**
   * To navigate saved card page
   */
  public navigateToSavedCards() {
    void this.slugInterceptorService.navigate(['payment-settings'], {
      queryParams: { menu: 3 },
    }, true);
  }

  /**
   * selected card
   * @param {any} card
   */
  public selectedCard(card: any) {
    this.selectedCardDetails = card;
  }

  /**
   * Handle Input keyup event to autofocus the next input field.
   * @param $event
   */
  onInputKeyUp($event: KeyboardEvent) {
    const omitKeys = [
      'Backspace',
      'Tab',
      'ShiftLeft',
      'ShiftRight',
    ];
    if (omitKeys.indexOf($event.code) < 0) {
      // autofocus order
      const arrIDs = [
        'card_number',
        'exp_month',
        'exp_year',
        'sec_code',
        'card_name',
      ];
      const inputID: string = $event.target?.['id'];
      const maxLength: number = $event.target?.['maxLength'];
      const currentLength: number = ($event.target?.['value'] as string).length;

      let element;
      if (currentLength >= maxLength) {
        const nextID = arrIDs[arrIDs.indexOf(inputID) + 1];
        element = document.getElementById(nextID);
        element.focus();
      }
    }
  }

  get getTotal(): number {
    const idMap = { payVaultRegistrationFee: 'pay_vault_registration_price', };
    let total: number = 0;
    this.getSelected?.forEach(e => total += (this.adjustedValue[idMap[e.id]] ?? e.fee));
    return total;
  }

  get getSelected(): PaymentOption[] {
    return this.paymentOptions?.filter(e => e.selected);
  }

  removeOption(option: PaymentOption) {
    this.proCheckoutService.updateCart(option.id, true);
    this.loadCart();
  }

  convertNumber(value: any): number {
    const numberValue = Number(value);
    return isNaN(numberValue) ? 0 : numberValue;
  }

  goBack() {
    this.location.back();
  }

  initiatePayment() {
    if (this.isPaymentButtonDisabled) {
      this.isPaymentClicked = true;
      return;
    }
    if (this.promoCode) {
      this.modalService.open('checkout-message-modal');
      return;
    }
    this.isLoading = true;
    if (this.selectedCardDetails) {
      this.makePayment();
    } else {
      // Token is Created for the New card
      this.getCardToken().subscribe({
        next: (response: any) => {
          this.isLoading = false;
          this.cardToken = response.id;
          this.makePayment();
        },
        error: e => {
          this.isLoading = false;
          const msg = e?.['error']?.['error']?.message;
          if (msg) {
            this.toastService.error(msg);
          }
        },
      });
    }
  }

  /**
   * make payment
   */
  makePayment() {
    let params = {
      token: this.cardToken,
      currency: this.paymentForm.value.currency,
      new_card: this.paymentForm.value.new_card,
      card_id: this.paymentForm.value.card_id,
      card_name: this.paymentForm.value.card_name,
      zipcode: this.paymentForm.value.zipcode,
      exp_month: this.paymentForm.value.exp_month,
      exp_year: this.paymentForm.value.exp_year,
      vault_amount: this.getTotal,
      invoice_number: this.invoiceNumber
    };
    if (this.selectedCardDetails) {
      params.new_card = '0';
      params.token = '';
      params.card_id = this.selectedCardDetails.id;
      params.zipcode = this.selectedCardDetails.zipcode;
      params.exp_month = this.selectedCardDetails.exp_date.split('/')[0];
      params.exp_year = this.selectedCardDetails.exp_date.split('/')[1];
    }
    console.log(params, 'check params');
    this.modalService.open('payment-process');

    const handleSuccess = (processedCheckoutResponse?: APIResponseModel) => {
      // if 3d secure payment open authentication page in new tab
      if (processedCheckoutResponse) {
        window.open(processedCheckoutResponse.data?.payment_confirm_url, '_blank');
      }
    };

    const handleException = (exception: any) => {
      this.isLoading = false;
      this.modalService.close('payment-process');
      const exceptionMessage = exception?.error?.message;
      this.modalService.open('payment-failure');
      if (typeof exceptionMessage == 'string') {
        this.commonService.paymentErrorListener(exceptionMessage);
        return;
      }
      const message_obj: {} = exceptionMessage;
      const messages: Array<string> = [];
      Object.keys(message_obj).forEach((k) => message_obj[k]?.forEach((e) => messages.push(e)));
      messages?.forEach((m) => this.toastService.error(m));
    };

    this.subscriptionService.proCheckoutApi(params).subscribe({
      next: (checkoutResponse) => {
        const processedCheckoutResponse = checkoutResponse?.data?.hasOwnProperty('payment_confirm_url') ? checkoutResponse : undefined;
        handleSuccess(processedCheckoutResponse);
        // const requestId = this.isProfessional ? null : (this.userData?.request?.id || null);
        // this.commonService.payForDlb(requestId).subscribe({
        //   next: (response) => handleSuccess(response, processedCheckoutResponse),
        //   error: handleException,
        // });
        // this.modalService.close('payment-process')
      },
      error: handleException,
    });
  }

  /**
   * Generates Card Token for the New Card Details
   */
  public getCardToken() {
    const { card_number, exp_month, exp_year, cvv } = this.paymentForm.value;
    return this.cardService.generateCardToken(card_number, exp_month, exp_year, cvv);
  }

  showDVAgreement() {
    // Invert value for clicking on link
    this.isDVAgreementChecked = !this.isDVAgreementChecked;
    this.modalService.open('agreement-modal');
  }

  /**
   * Get whether is payment button disabled
   */
  get isPaymentButtonDisabled(): boolean {
    return this.isLoading || !this.isDVAgreementChecked || (!this.cards || !this.cards.length ? this.paymentForm.invalid : false);
  }

  messageListener($event: any) {
    if ($event) this.modalService.close('checkout-message-modal');
  }

  /**
   * Setup invoice payment
   */
  public setupVaultRegistrationPayment() {
    this.loader.set('subscription_details');
    this.proCheckoutService.getPaymentOptions().subscribe({
      next: (response) => {
        this.paymentOptions = response;
      },
      error: () => {
        this.goBack();
      },
      complete: () => {
        this.loadCart();
        this.loader.set('subscription_details', false)
      }
    })
  }

  /**
   * Updatesthe adjusted values based on the selected payment options.
   */
  public setSelectedOptionAmountDefault(): void {
    this.paymentOptions.forEach(obj => {
      console.log(obj);
      if (obj.selected) {
        let type;
        switch (obj.id) {
          case 'subscription_monthly':
            type = 1;
            this.adjustedValue.subscription_price = obj.fee;
            break;
          case 'dataLinkFee':
            type = 2;
            this.adjustedValue.transfer_price = obj.fee;
            break;
          case 'payVaultRegistrationFee':
            this.adjustedValue.pay_vault_registration_price = obj.perVaultFee * obj.totalVaults
            break;
          default:
            type = 0;
            break;
        }
      }
    });
  }
  public listenPaymentStatus(): void {
    this.proSocketService.listenProPaymentStatus().subscribe((response: any) => {
      if(response['payer_id'] == this.localStorageService.getDataByKey('agent_id') && response['is_payment_complete']) {
      setTimeout(() => {
        this.modalService.close('payment-process');
        this.commonService.proPaymentStatusListener(response);
        this.goBack();
      }, 100);
      }
    })
  }
}
