<div class="scroll-hidden background-color-grey h-full">
  <!-- Navbar -->
  <app-professional-navbar></app-professional-navbar>
  <div class="grid grid-cols-12 p-8 sm:p-12 xl:px-24 ">
    <div class="col-span-12">
      <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
        Account Settings
      </h2>
      <p class="text-base text-grey py-2 tracking-wide text-regular">Check and update your personal
        information and account settings</p>
      <hr class="border-lightblue w-24 my-1 border-1">
    </div>
  </div>
  <div class="grid grid-cols-12 md:px-12 xl:px-24 pb-12 gap-6 md:gap-12">
    <!-- BEGIN: Contents-->
    <div class="col-span-12 lg:col-span-3 bg-transparent hidden lg:block">
      <div class="sticky top-10 fixed">
        <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
          Contents
        </h4>
        <ul class="space-y-1 mt-2">
          <li class="text-grey text-regular text-lg cursor-pointer" (click)="menuChange(1)"
            [ngClass]="{'active': menuActive === 1}">Your
            Account Details</li>
          <li class="text-grey text-regular text-lg cursor-pointer" (click)="menuChange(2)"
            [ngClass]="{'active': menuActive === 2}">Change
            your Password
          </li>
        </ul>
      </div>
    </div>
    <!-- END: Contents-->

    <div class="col-span-12 lg:col-span-9 space-y-8">
      <!-- BEGIN: Update Profile Picture & Email Section -->
      <div class="grid grid-cols-12 gap-6 bg-white mx-8 md:mx-0 border-card rounded p-6" *ngIf="menuActive === 1">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
           {{isNotAFirmAccount ? 'Change your Profile' : 'Change your Company Logo' }} 
          </h4>
        </div>
        <!-- BEGIN: Update Profile Picture Form -->
        <div class="col-span-12 md:col-span-2">
          <form [formGroup]="profilePictureForm">
            <div class="h-32 w-32 flex justify-center items-center rounded-full relative">
              <app-image-component [image]="imageSrc" [class]="'w-32 h-32 rounded-full object-fill object-contain'"
                [defaultImage]="'assets/icons/user-bg.svg'" [spinner]="profilePicLoader">
              </app-image-component>
              <span
                class="absolute h-8 w-8 right-0 flex justify-center items-center rounded-full border-white border-2 bg-edit bottom-0 cursor-pointer"
                (click)="propicfield.click()">
                <img [src]="'assets/icons/edit.svg'" class="w-3 h-3" alt="digital vault">
                <input type="file" class="hidden" #propicfield (change)="readURL($event);" id="file">
              </span>
            </div>
          </form>
        </div>
        <!-- END: Update Profile Picture Form -->

        <!-- BEGIN: Update Email Address Form -->
        <div class="col-span-12 md:col-span-10 md:ml-10 lg:ml-12 xl:ml-4">
          <form [formGroup]="accountDetailForm" (ngSubmit)="accountDetailFormSubmit()" autocomplete="off">
            <!-- Your Email Address -->
            <div>
              <label for="old-email" class="text-sm pb-2 text-regular text-grey">Your Email
                Address</label>
              <input type="email" name="old-email" id="old-email" formControlName="old_email"
                class="input mt-1 w-full rounded-xs h-12 text-regular appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                [ngClass]="accountDetailFormGet?.old_email?.errors && accountDetailFormGet?.old_email?.touched ?'form-input-error':'form-input-focus'"
                placeholder="Enter your Email address" disabled>
            </div>
            <div class="mt-3" *ngIf="changeEmail">
              <!-- New Email Address -->
              <div>
                <label for="new-email" class="text-sm pb-2 text-regular text-grey">Enter New Email Address</label>
                <input type="email" name="new-email" id="new-email" formControlName="email"
                  class="input mt-1 w-full rounded-xs h-12 text-regular appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                  [ngClass]="accountDetailFormGet?.email?.errors && accountDetailFormGet?.email?.touched ?'form-input-error':'form-input-focus'"
                  placeholder="Enter your new Email address" [attr.disabled]="this.showVerificationCodes || undefined">
                <div *ngIf="accountDetailFormGet?.email?.errors && accountDetailFormGet?.email?.touched">
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                    *ngIf="accountDetailFormGet?.email?.errors?.same_email">
                    The email address you have entered is the same as your old address.
                  </p>
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                    *ngIf="accountDetailFormGet?.email?.errors?.pattern">
                    Please enter a valid email address.
                  </p>
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                    *ngIf="accountDetailFormGet?.email?.errors?.required">
                    Email address is required.
                  </p>
                </div>
              </div>
              <!-- Confirmation Email -->
              <div>
                <label for="confirm-email" class="text-sm pb-2 text-regular text-grey">Confirmation Email</label>
                <input type="email" name="confirm-email" id="confirm-email" formControlName="confirm_email"
                  class="input mt-1 w-full rounded-xs h-12 text-regular appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                  [ngClass]="accountDetailFormGet?.email?.valid && accountDetailFormGet?.confirm_email?.errors && !isConfirmPasswordMatch ?'form-input-error':'form-input-focus'"
                  placeholder="Enter Confirm Email address" [attr.disabled]="this.showVerificationCodes || undefined">
                <div *ngIf="accountDetailFormGet?.email?.valid">
                  <p *ngIf="accountDetailFormGet?.confirm_email?.errors?.required"
                    class="text-regular font-medium text-xs mt-2 error-alert">
                    Confirm Email is required.
                  </p>
                  <p *ngIf="!accountDetailFormGet?.confirm_email?.errors?.required && !isConfirmPasswordMatch"
                    class="text-regular font-medium text-xs mt-2 error-alert">
                    Confirm Email must match.
                  </p>
                </div>
              </div>
              <!-- Password -->
              <div class="mt-3" *ngIf="!showVerificationCodes">
                <label for="password_email" class="text-sm pb-2 text-regular text-grey">
                  Enter Your Password</label>
                <div class="relative h-12 text-grey">
                  <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    (click)="togglePasswordView = !togglePasswordView">
                    <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
                    <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
                  </div>
                  <input name="password" id="password_email" autocomplete="new-password"
                    class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                    formControlName="password" [type]="togglePasswordView?'text':'password'"
                    [ngClass]="accountDetailFormGet?.password?.errors && accountDetailFormGet?.password?.touched ?'form-input-error':'form-input-focus'"
                    placeholder="Enter Password to Change Email">
                  <div *ngIf="accountDetailFormGet?.password?.errors && accountDetailFormGet?.password?.touched">
                    <p class="text-regular font-medium text-xs mt-2 error-alert"
                      *ngIf="accountDetailFormGet?.password?.errors?.required">Your
                      Password is required
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-3 grid grid-cols-2" *ngIf="showVerificationCodes">
                <!-- Verification Code 1 -->
                <div class="col-span-1 md:col-span-2">
                  <label for="verification_code1" class="text-sm pb-2 text-regular text-grey">
                    Verification Code 1
                  </label>
                  <input name="verification_code1" id="verification_code1"
                    class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                    formControlName="password" placeholder="Verification Code 1">
                </div>
                <!-- Verification Code 2 -->
                <div class="col-span-1 md:col-span-2">
                  <label for="verification_code2" class="text-sm pb-2 text-regular text-grey">
                    Verification Code 2
                  </label>
                  <input name="verification_code2" id="verification_code2"
                    class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                    formControlName="password" placeholder="Verification Code 2">
                </div>
              </div>
            </div>
            <div class="mt-8">
              <!-- Account Settings Form : Save Changes Button -->
              <app-button-loader *ngIf="changeEmail" [buttonText]="'Save Changes'"
                [valid]="accountDetailForm?.valid && isConfirmPasswordMatch" buttonType="'submit'"
                [loader]="accountDetailsSubmit">
              </app-button-loader>
              <!-- Account Settings Form : Change email Button -->
              <app-button-loader *ngIf="!changeEmail" [buttonText]="'Change email'" [valid]="true" buttonType="'button'"
                (clickButton)="changeEmail = true">
              </app-button-loader>
            </div>
          </form>
        </div>
      </div>
      <!-- END: Update Email Address Form -->

      <!-- BEGIN : Account Details Section -->
      <div class="grid grid-cols-12 gap-6 bg-white mx-8 md:mx-0 border-card rounded  p-6" *ngIf="menuActive === 1">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Your Account Details
          </h4>
        </div>
        <div class="col-span-12 md:col-span-12  ">
          <!-- BEGIN : Account Details Form -->
          <form [formGroup]="editDetailsForm" autocomplete="off" (ngSubmit)="onSave()">
            <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mb-5">
              <!-- Company Name/First Name -->
              <div class="col-span-12 md:col-span-6 lg:col-span-6">
                <label for="fc_name" class="text-sm pb-2 text-regular text-grey">
                  {{!shouldLastnameShown?'Company Name*':'First Name*'}}
                </label>
                <input type="text" id="fc_name"
                  class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
                  [ngClass]="formGet.first_name.errors && formGet.first_name.touched?'form-input-error':'form-input-focus'"
                  placeholder="First Name" formControlName="first_name">
                <div *ngIf="formGet.first_name.errors && formGet.first_name.touched">
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                    *ngIf="formGet.first_name.errors.required">Name is required</p>
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                    *ngIf="formGet.first_name.errors.pattern">
                    Only alphabets and special characters like ' and - are allowed
                  </p>
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                    *ngIf="formGet.first_name.errors.maxlength && !formGet.first_name.errors.pattern">
                    Name must not exceed 50 characters
                  </p>
                </div>
              </div>
              <!-- Middle Name -->
              <div class="col-span-12 md:col-span-6 lg:col-span-6" *ngIf="shouldLastnameShown">
                <label for="middle_name" class="text-sm pb-2 text-regular text-grey">Middle Name</label>
                <input type="text" id="middle_name"
                  class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
                  [ngClass]="formGet.middle_name.errors && formGet.middle_name.touched?'form-input-error':'form-input-focus'"
                  placeholder="e.g. Tylor" formControlName="middle_name">
                <div *ngIf="formGet.middle_name.errors && formGet.middle_name.touched">
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                    *ngIf="formGet.middle_name.errors.pattern">
                    Only alphabets and special characters like ' and - are allowed</p>
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                    *ngIf="formGet.middle_name.errors.maxlength && !formGet.middle_name.errors.pattern">
                    Name must not exceed 50 characters
                  </p>
                </div>
              </div>
              <!-- Last Name -->
              <div class="col-span-12 md:col-span-6 lg:col-span-6" *ngIf="shouldLastnameShown">
                <label for="last_name" class="text-sm pb-2 text-regular text-grey">Last Name*</label>
                <input type="text" id="last_name"
                  class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
                  [ngClass]="formGet.last_name.errors && formGet.last_name.touched?'form-input-error':'form-input-focus'"
                  placeholder="e.g. Tylor" formControlName="last_name">
                <div *ngIf="formGet.last_name.errors && formGet.last_name.touched">
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                    *ngIf="formGet.last_name.errors.required">Last Name is required</p>
                  <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.last_name.errors.pattern">
                    Only alphabets and special characters like ' and - are allowed</p>
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                    *ngIf="formGet.last_name.errors.maxlength && !formGet.last_name.errors.pattern">
                    Name must not exceed 50 characters
                  </p>
                </div>
              </div>
              <!-- Phone Number -->
              <div class="col-span-12 md:col-span-6 lg:col-span-6 mt-1">
                <label for="phone_number" class="text-sm pb-2 text-regular text-grey">Phone Number*</label>
                <app-phone-input id="phone_number" [placeholder]="'e.g. 8665044754'"
                  [phoneNumber]="editDetailsForm.value.phone" [countryCode]="countryCode"
                  [uniqueId]="'phone-contact-professional'" (onChange)="detectChangePhonenumber($event)"
                  [errorClassTrigger]="formGet.phone.errors && formGet.phone.touched" [form]="editDetailsForm"
                  (focusout)="formGet.phone.markAsTouched()">
                </app-phone-input>
                <div *ngIf="formGet.phone.errors && formGet.phone.touched">
                  <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.phone.errors.pattern">
                    Please enter a valid phone number</p>
                  <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.phone.errors.required">
                    Phone Number is required</p>
                </div>
              </div>
            </div>
            <div class="w-full my-3 mb-5">
              <h3 class="text-semibold text-darkblue text-lg ">Address</h3>
              <p class="text-grey text-md text-regular mb-4">Please enter your primary physical address.</p>
              <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
                <!-- Country -->
                <div class="col-span-12">
                  <label for="country" class="text-sm pb-2 text-regular text-grey">Country*</label>
                  <div class=" col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('country')">
                    <app-country-search-component id="country"
                      (optionChangeEmitter)="optionChangeListenerCountry($event)"
                      [defaultSelected]="formGet?.country?.value"
                      [enableError]="formGet.country.errors && formGet.country.touched"></app-country-search-component>
                    <div *ngIf="formGet.country.errors && formGet.country.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.country.errors.required">
                        Country is required</p>
                    </div>
                  </div>
                </div>
                <!-- Street Address -->
                <div class="col-span-12 md:col-span-12 lg:col-span-12 relative move-map" #moveMapHere>
                  <label for="address" class="text-sm pb-2 text-regular text-grey">Street Address*</label>
                  <input type="text" id="address" #search (keyup)="onAddressChange(search)"
                    (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null" (input)="onFocusAddress()"
                    class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
                    placeholder="e.g Main St" name="street_address" formControlName="street_address"
                    [ngClass]="formGet.street_address.errors && formGet.street_address.touched?'form-input-error':'form-input-focus'">
                  <div *ngIf="formGet.street_address.errors && formGet.street_address.touched">
                    <p class="text-regular font-medium text-xs mt-2 error-alert"
                      *ngIf="formGet.street_address.errors.required">Address is required</p>
                    <!-- <p class="text-regular font-medium text-xs mt-2 error-alert"
                      *ngIf="formGet.street_address.errors.pattern">Only letters, digits, space, hyphen, comma are
                      allowed</p> -->
                    <p class="text-regular font-medium text-xs mt-2 error-alert"
                      *ngIf="formGet.street_address.errors.minlength && !formGet.street_address.errors.pattern">Minimum
                      3 characters required</p>
                    <p class="text-regular font-medium text-xs mt-2 error-alert"
                      *ngIf="formGet.street_address.errors.maxlength && !formGet.street_address.errors.pattern">Maximum
                      500 characters allowed required</p>
                  </div>
                </div>
                <!-- State/Province -->
                <div class="col-span-12" *ngIf="selectedCountryName!==unitedKingdom">
                  <label for="state-province" class="text-sm pb-2 text-regular text-grey">State/Province*</label>
                  <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('state')">
                    <app-state-search-component id="state-province" [countryCode]="formGet?.country?.value"
                      [enableError]="formGet.state.errors && formGet.state.touched"
                      [defaultSelected]="formGet?.state?.value"
                      (optionChangeEmitter)="optionChangeListenerState($event)" tabindex="0"
                      (focusout)="markAsTouchedIndividual('state')">
                    </app-state-search-component>
                    <div *ngIf="formGet.state.errors && formGet.state.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.state.errors.required">State is required</p>
                    </div>
                  </div>
                </div>
                <!-- City -->
                <div class="col-span-12 md:col-span-6 lg:col-span-6">
                  <label for="city" class="text-sm pb-2 text-regular text-grey">City*</label>
                  <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('city')">
                    <app-city-search id="city" [stateCode]="formGet?.state?.value"
                      [enableError]="formGet.city.errors && formGet.city.touched"
                      [defaultSelected]="formGet?.city?.value" (optionChangeEmitter)="optionChangeListenerCity($event)"
                      tabindex="0" (focusout)="markAsTouchedIndividual('city')">
                    </app-city-search>
                    <div *ngIf="formGet.city.errors && formGet.city.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.city.errors.required">
                        City is required</p>
                    </div>
                  </div>
                </div>
                <!-- Zip/Postal Code -->
                <div class="col-span-12 md:col-span-6 lg:col-span-6">
                  <label for="zipcode" class="text-sm pb-2 text-regular text-grey">Zip/Postal Code*</label>
                  <input type="text" id="zipcode" (focusout)="markAsTouchedIndividual('zipcode')"
                    class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
                    placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                    name="zipcode" formControlName="zipcode" oninput="this.value = this.value.toUpperCase()"
                    (focus)="validateZipCode()"
                    [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
                  <div *ngIf="formGet.zipcode.errors && formGet.zipcode.touched">
                    <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.zipcode.errors.pattern">
                      Invalid zipcode</p>
                    <p class="text-regular font-medium text-xs mt-2 error-alert"
                      *ngIf="formGet.zipcode.errors.required">
                      Zipcode is required</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid justify-items-end">
              <!-- Account Details Form: Save Details Button -->
              <app-button-loader [buttonText]="'Save Details'" [valid]="true"
                [buttonClass]="'button button--lg bg-darkblue  px-4 rounded-xs h-10 focus:outline-none text-regular text-white text-sm'"
                [loader]="editDetailsSubmit" buttonType="'submit'">
              </app-button-loader>
            </div>
          </form>
          <!-- END : Account Details Form -->
        </div>
      </div>
      <!-- END : Account Details Section -->

      <!-- BEGIN: Change Passsword Section -->
      <div class="grid grid-cols-12 gap-6 bg-white mx-8 md:mx-0 border-card rounded p-6" *ngIf="menuActive === 2">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Change your password
          </h4>
        </div>
        <div class="col-span-12 ml-4">
          <!-- BEGIN: Change Passsword Form -->
          <form [formGroup]="changePasswordForm" autocomplete="off" (ngSubmit)="changePasswordFormSubmit()">
            <!-- Old password -->
            <div>
              <label for="old-password" class="text-sm pb-2 text-regular text-grey">
                Old password</label>
              <div class="relative h-12 text-grey">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  (click)="toggleOldPasswordView = !toggleOldPasswordView">
                  <fa-icon [icon]="faEye" *ngIf="toggleOldPasswordView"></fa-icon>
                  <fa-icon [icon]="faEyeSlash" *ngIf="!toggleOldPasswordView"></fa-icon>
                </div>
                <input formControlName="password" [type]="toggleOldPasswordView?'text':'password'" name="password"
                  id="old-password"
                  class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                  [ngClass]="changePasswordFormGet?.password?.errors && changePasswordFormGet?.password?.touched ?'form-input-error':'form-input-focus'"
                  placeholder="Enter Old Password">
                <!-- BEGIN: Error Alert text -->
                <div *ngIf="changePasswordFormGet?.password?.errors && changePasswordFormGet?.password?.touched">
                  <p class="text-regular font-medium text-xs my-2 error-alert"
                    *ngIf="changePasswordFormGet?.password?.errors?.required">Your
                    password is required.
                  </p>
                  <!-- <p class="text-regular font-medium text-xs my-2 error-alert"
                    *ngIf="changePasswordFormGet?.password?.errors?.maxlength">Your
                    password should not exceed 12 characters
                  </p> -->
                </div>
                <!-- END: Error Alert text -->
              </div>
            </div>
            <!-- New Password -->
            <div class="mt-5">
              <label for="newPassword" class="text-sm pb-2 text-regular text-grey">
                New Password</label>
              <div class="relative h-12 text-grey z-10">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  (click)="toggleNewPasswordView = !toggleNewPasswordView">
                  <fa-icon [icon]="faEye" *ngIf="toggleNewPasswordView"></fa-icon>
                  <fa-icon [icon]="faEyeSlash" *ngIf="!toggleNewPasswordView"></fa-icon>
                </div>
                <input name="new_password" id="newPassword"
                  class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                  formControlName="new_password" [type]="toggleNewPasswordView?'text':'password'"
                  [ngClass]="changePasswordFormGet?.new_password?.errors && changePasswordFormGet?.new_password?.touched ?'form-input-error':'form-input-focus'"
                  placeholder="Enter New Password">
                <!-- BEGIN: Error Alert text -->

                <div *ngIf="changePasswordFormGet.new_password.errors && changePasswordFormGet.new_password.dirty">
                  <div *ngIf="changePasswordFormGet.new_password.errors.required">
                    <p class="text-regular font-medium text-xs mt-2 error-alert">Your Password is required</p>
                  </div>
                  <div *ngIf="!changePasswordFormGet.new_password.errors.required"
                    class="absolute border mt-4 rounded bg-white p-2 text-red-700 border-3 w-full rounded-xs border block focus:outline-none">

                    <p
                      [ngClass]="!changePasswordFormGet.new_password.errors.minlength? 'green-error-text':'red-error-text'">
                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                        alt="digital vault" *ngIf="!changePasswordFormGet.new_password.errors.minlength">
                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                        alt="digital vault" *ngIf="changePasswordFormGet.new_password.errors.minlength">
                      Minimum at least 8 characters.
                    </p>

                    <p
                      [ngClass]="changePasswordFormGet.new_password.errors?.passwordStrength?.hasNumeric ? 'green-error-text' : 'red-error-text'">
                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                        alt="digital vault"
                        *ngIf="changePasswordFormGet.new_password.errors?.passwordStrength?.hasNumeric">
                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                        alt="digital vault"
                        *ngIf="!changePasswordFormGet.new_password.errors?.passwordStrength?.hasNumeric">
                      At least one number (0-9).
                    </p>

                    <p
                      [ngClass]="changePasswordFormGet.new_password.errors?.passwordStrength?.hasLowerCase? 'green-error-text':'red-error-text'">
                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                        alt="digital vault"
                        *ngIf="changePasswordFormGet.new_password.errors?.passwordStrength?.hasLowerCase">
                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                        alt="digital vault"
                        *ngIf="!changePasswordFormGet.new_password.errors?.passwordStrength?.hasLowerCase">
                      At least one lowercase letter (a-z).
                    </p>

                    <p
                      [ngClass]="changePasswordFormGet.new_password.errors?.passwordStrength?.hasUpperCase? 'green-error-text':'red-error-text'">
                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                        alt="digital vault"
                        *ngIf="changePasswordFormGet.new_password.errors?.passwordStrength?.hasUpperCase">
                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                        alt="digital vault"
                        *ngIf="!changePasswordFormGet.new_password.errors?.passwordStrength?.hasUpperCase">
                      At least one uppercase letter (A-Z).
                    </p>

                    <p
                      [ngClass]="changePasswordFormGet.new_password.errors?.passwordStrength?.hasSpecialCharacters? 'green-error-text':'red-error-text'">
                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                        alt="digital vault"
                        *ngIf="changePasswordFormGet.new_password.errors?.passwordStrength?.hasSpecialCharacters">
                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                        alt="digital vault"
                        *ngIf="!changePasswordFormGet.new_password.errors?.passwordStrength?.hasSpecialCharacters">
                      At least one special character (!@#$%^&*).
                    </p>

                  </div>
                </div>
                <!-- END: Error Alert text -->
              </div>
            </div>
            <!-- Confirm New Password -->
            <div class="mt-4">
              <label for="newPasswordConfirm" class="text-sm pb-2 text-regular text-grey">
                Confirm New Password</label>
              <div class="relative h-12 text-grey">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  (click)="toggleNewPasswordViewConfirm = !toggleNewPasswordViewConfirm">
                  <fa-icon [icon]="faEye" *ngIf="toggleNewPasswordViewConfirm"></fa-icon>
                  <fa-icon [icon]="faEyeSlash" *ngIf="!toggleNewPasswordViewConfirm"></fa-icon>
                </div>
                <input name="new_password_confirm" id="newPasswordConfirm"
                  class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                  formControlName="new_password_confirm" [type]="toggleNewPasswordViewConfirm?'text':'password'"
                  [ngClass]="(changePasswordFormGet?.new_password_confirm?.errors || !changePasswordMatch) && changePasswordFormGet?.new_password_confirm?.touched ?'form-input-error':'form-input-focus'"
                  (click)="changePasswordMatch = true" placeholder="Confirm New Password">
                <!-- BEGIN: Error Alert text -->
                <div
                  *ngIf="(changePasswordFormGet?.new_password_confirm?.errors || !changePasswordMatch) && changePasswordFormGet?.new_password_confirm?.touched">
                  <p class="text-regular font-medium text-xs my-2 error-alert" *ngIf="!changePasswordMatch">
                    Your passwords must match.
                  </p>
                  <p class="text-regular font-medium text-xs my-2 error-alert"
                    *ngIf="changePasswordFormGet?.new_password_confirm?.errors?.required">Confirm Password is required
                  </p>
                </div>
                <!-- END: Error Alert text -->
              </div>
            </div>
            <div class="mt-8">
              <!-- Change Password Form: Save Changes Button -->
              <app-button-loader [buttonText]="'Save Changes'" [valid]="changePasswordForm.valid"
                [loader]="changePasswordSubmit" buttonType="'submit'">
              </app-button-loader>
            </div>
          </form>
          <!-- END: Change Passsword Form -->
        </div>
      </div>
      <!-- END: Change Passsword Section -->

      <!-- BEGIN: Contact Preferences Section -->
      <div class="grid grid-cols-12 gap-4 bg-white border-card mx-8 md:mx-0 rounded p-6" *ngIf="menuActive === 3">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Contact Preferences
          </h4>
        </div>
        <div class="col-span-12 mt-2 md:space-x-4 sm:space-x-0 md:flex sm:flex-row">
          <label>
            <input type="checkbox" class="form-checkbox h-5 w-5 text-darkblue focus:outline-none" checked>
            <span class="text-darkgrey text-base text-regular ml-4">Receive marketing
              emails from Digital Vault</span>
          </label>
          <label>
            <input type="checkbox" class="form-checkbox h-5 w-5 text-darkblue focus:outline-none">
            <span class="text-darkgrey text-base text-regular ml-4">Receive calls from Digital Lock
              Box</span>
          </label>
        </div>
        <div class="col-span-12 mt-2">
          <button type="submit"
            class="button button--lg mt-2 text-sm tracking-wide shadow-lg md:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-6">Save
            Changes</button>
        </div>
      </div>
      <!-- END: Contact Preferences Section -->
    </div>
  </div>
</div>


<app-professional-otp-verification-modal [newEmail]="newEmail" (otpVerificationStatus)="getCurrentUserDetails()">
</app-professional-otp-verification-modal>