import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonHelper} from 'src/app/helper/common.helper';
import {SlugInterceptorService} from 'src/app/helper/slug-interceptor.service';
import {APIResponseModel} from 'src/app/interface/response.interface';
import {LocalStorageService} from 'src/app/services/local-storage.service';
import {PersonalDetailsService} from 'src/app/services/personal-details.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
})
/**
 * Executor and beneficiary component
 */
export class IntroEBComponent implements OnInit {

  public sectionSaveExitOptions: any;
  public requestId: string;
  public userType: string;
  public userData:Array<Object>;
  /**
   * @constructor
   */
  constructor(
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private personalDetailsService:PersonalDetailsService,
    private router:Router,
    private commonHelper: CommonHelper,
    private slugInterceptorService:SlugInterceptorService,
  ) {}

  /**
   * called initially
   */
  ngOnInit(): void {
    this.requestId=this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.sectionSaveExitOptions = {
      section: 2,
      // type: 1,
    };
    this.getPersonalDetails();
  }

  /**
   * Get personal details
   */
  public getPersonalDetails(): void {
    // get people list
    if (this.userType === 'Professional' || this.userType === 'Advisor') {
      this.personalDetailsService.getPersonalDetails(this.requestId).subscribe((response: APIResponseModel) => {
        if (response.status) {
          this.userData=response.data;
        }
      },
      (exception:any)=>{
        this.commonHelper.httpResponseHandler(exception?.error)
      });
    }
  }

  /**
   * Click handler
   * @param command
   */
  public click(command:string) {
    const isPro = this.userType !== 'Consumer';
    switch (command) {
      case 'Back':
        const back_url = ['executor-beneficiary','manage-beneficiaries', isPro ? this.requestId : ''];
        void this.slugInterceptorService.navigate(back_url);
        break;
      case 'Next':
        const next_url = ['executor-beneficiary','manage-executors', isPro ? this.requestId : ''];
        void this.slugInterceptorService.navigate(next_url);
        break;
    }
  }
}
