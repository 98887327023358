import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CommonModelService} from "../../../../../services/common-model.service";
import {AccessControlComponent} from "../access-control.component";

@Component({
  selector: 'app-access-control-modal',
  templateUrl: './access-control-modal.component.html',
})
export class AccessControlModalComponent implements OnInit {
  @ViewChild(AccessControlComponent) aclComponent: AccessControlComponent;
  @Input() returnModal: string;
  @Input() description: string;
  @Output() saveEmitter = new EventEmitter<{ id: string, next?: any, error?: any, complete?: boolean }>();
  public data: any;
  public isLoading: boolean;
  public descriptionMessage: string = 'Your Advisors will help you to manage your vault.';

  /**
   * constructor
   */
  constructor(private modalService: CommonModelService) {
  }

  ngOnInit(): void {
  }

  /**
   * on changes
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.description?.currentValue) {
      this.descriptionMessage = changes.description.currentValue;
    }
  }

  /**
   * Setter for edit values in access control
   *
   * @param {any} data
   */
  @Input() set advisorData(data: any) {
    this.data = data;
  }

  /**
   * close modal
   */
  public closeModal() {
    this.modalService.close('access-control-modal');
    if (this.returnModal) {
      this.modalService.open(this.returnModal);
    }
  }

  public updateAccess() {
    this.closeModal();
    this.saveEmitter.emit({id: this.data['advisor_id'], complete: false});
    this.aclComponent.updateAccess().subscribe({
      next: r => this.saveEmitter.emit({id: this.data['advisor_id'], next: r}),
      error: e => this.saveEmitter.emit({id: this.data['advisor_id'], error: e}),
      complete: () => this.saveEmitter.emit({id: this.data['advisor_id'], complete: true})
    });
  }
}
