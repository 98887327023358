import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';
import { SessionStorageService } from '../services/session-storage.service';
import { SlugInterceptorService } from './slug-interceptor.service';

@Injectable({
  providedIn: 'root',
})

/**
 * @class RoleGuard
 * @description for prohibiting access to register page if user is already logged in ;
 */
export class RoleGuard implements CanActivate {
  /**
   * @constructor
   */
  constructor(
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private slugInterceptorService: SlugInterceptorService) { }

  /**
   * canActivate
   * @description method to check if router can render or not
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return {any}
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const consumer = this.localStorageService.getUserData();
    const currentSession = this.sessionStorageService.getDataFromSession('user');
    const isLoggedIn = consumer && consumer.access_token;
    if (isLoggedIn && currentSession) {
      void this.slugInterceptorService.navigate(['/dashboard']);
      return false;
    } else {
      // this.router.navigate(['/']);

      return true;
    }
  }
}
