<app-common-modal id="add-vehicle-modal" class="app-common-modal" [heightClass]="'min-h-75'">
  <!-- BEGIN: Modal -->
  <div class="relative ">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer z-50"
         alt="LegacyNow"
         (click)="closeModel()">

    <form [formGroup]="vehicleForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">

        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Vehicle Details
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">
            Record your vehicle's make, model, and vehicle plate number.
          </p>
          <p class="text-regular font-medium text-xs mt-2 error-alert"
             *ngIf="(isNewVehicle && !permissions?.['add']) || (!isNewVehicle && !permissions?.['edit'])">
            You do not have permission to
            {{isNewVehicle ? 'add information for this client.' : 'edit this client\'s information.'}}
          </p>
        </div>
        <div class="col-span-12 md:col-span-6 relative">
          <label class="text-grey text-sm text-regular">Type of
            Vehicle*</label>
          <input type="text" id="vehicle-type" [attr.disabled]="!canEdit || undefined"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="vehicle-type" formControlName="vehicle_type" placeholder="e.g Motorcycle, Car, Boat"
                 [ngClass]="formGet.vehicle_type.errors && formGet.vehicle_type.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('vehicle_type')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 md:col-span-6 relative ">
          <label for="year" class="text-grey text-sm text-regular ">Year of Registration </label>
          <input type="text" id="year" [attr.disabled]="!canEdit || undefined"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="year" formControlName="year" placeholder="e.g. 2021" [maxlength]="4" [minlength]="4"
                 [ngClass]="formGet.year.errors && formGet.year.touched?'form-input-error':'form-input-focus'">
            <div *ngIf="formGet.year.errors && formGet.year.touched">
              <p class="text-regular font-medium text-xs mt-2 error-alert">Please enter a year between 1700 & {{maxYear}}
              </p>
            </div>
        </div>
        <div class=" col-span-12 md:col-span-6 relative ">
          <label for="vehicle-make" class=" text-grey text-sm text-regular ">Manufacturer of the Vehicle*</label>
          <input type="text" [attr.disabled]="!canEdit || undefined" id="vehicle-make"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="vehicle-make" formControlName="vehicle_make" placeholder="e.g Ford, Yamaha, Boeing"
                 [ngClass]="formGet.vehicle_make.errors && formGet.vehicle_make.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('vehicle_make')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class=" col-span-12 md:col-span-6  relative ">
          <label for="vehicle-model" class=" text-grey text-sm text-regular ">Model*</label>
          <input type="text" [attr.disabled]="!canEdit || undefined" id="vehicle-model"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="vehicle-model" formControlName="vehicle_model" placeholder="e.g Focus, R1, 747" maxlength="51"
                 [ngClass]="formGet.vehicle_model.errors && formGet.vehicle_model.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('vehicle_model')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>

        <!-- VIN -->
        <div class="col-span-12 md:col-span-6">
          <label for="vin" class="text-grey text-sm text-regular ">Vehicle Identification Number (VIN)</label>
          <input type="text" [attr.disabled]="!canEdit || undefined" id="vin"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="vin" formControlName="vin" placeholder="e.g 1GNEK13ZX3R298984" maxlength="20"
                 oninput="this.value = this.value.toUpperCase()"
                 [ngClass]="formGet.vin.errors && formGet.vin.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('vin')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 md:col-span-6 mt-1">
          <label for="licence-plate" class="text-grey text-sm text-regular ">Vehicle Plate Number</label>
          <input type="text" [attr.disabled]="!canEdit || undefined" id="licence-plate"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="licence" formControlName="licence" placeholder="e.g 878 MPA" maxlength="15"
                 [ngClass]="formGet.licence.errors && formGet.licence.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('licence')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class=" col-span-12 md:col-span-6 relative ">
          <label for="country-registration" class=" text-grey text-sm text-regular ">Country of Registration</label>
          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
            <app-country-search-component (optionChangeEmitter)="optionChangeListenerCountry($event)"
                                          [defaultSelected]="formGet?.country?.value" id="country-registration"
                                          [disabled]="!canEdit || !canAccessForm"
                                          [enableError]="formGet.country.errors && formGet.country.touched">
            </app-country-search-component>
            <p *ngFor="let error of getErrors('country')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </app-shimmer-loading>
        </div>
        <div class=" col-span-12 md:col-span-6 relative " (click)="markAsTouchedType('state')"
             *ngIf="selectedCountryName!==unitedKingdom">
          <label class="text-grey text-sm text-regular">
            State of Registration
          </label>
          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
            <app-state-search-component [countryCode]="formGet?.country?.value"
                                        [enableError]="formGet.state.errors && formGet.state.touched"
                                        [defaultSelected]="formGet?.state?.value"
                                        (optionChangeEmitter)="optionChangeListenerState($event)" tabindex="0"
                                        [disabled]="!canEdit || !canAccessForm" (focusout)="markAsTouchedType('state')">
            </app-state-search-component>
          </app-shimmer-loading>
          <p *ngFor="let error of getErrors('state')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>

        <div class="col-span-12 mt-1">
          <label class="text-grey text-sm text-regular ">
            Insurance Details
          </label>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-8 relative">
              <app-select-with-search id="insurance-type" [options]="insuranceOptions"
                                      [disabled]="!canEdit || !canAccessForm"
                                      [placeholderValue]="'Select Vehicle Insurance'"
                                      (optionChangeEmitter)="optionChangeEmitterInsuranceType($event)"
                                      [defaultSelected]="selectedInsuranceID"
                                      tabindex="0">
              </app-select-with-search>
            </div>
            <div class="col-span-12 md:col-span-4">
              <button type="button" [disabled]="disableInsuranceView" (click)="openInsuranceModal(selectedInsuranceID)"
                      class="m-auto hover:underline-text text-sm md:text-base tracking-wide w-full h-10 sm:h-12 bg-darkblue rounded-xs focus:outline-none text-regular text-white py-3 md:py-0 px-6 flex items-center justify-center"
                      [ngClass]="disableInsuranceView ? 'opacity-50' : ''">
                <div class="loading-red mr-2" *ngIf="submitLoader"></div>
                View / Edit
              </button>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-8">
          <div>
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Is this vehicle paid off?
            </h4>

          </div>
        </div>
        <div class="col-span-12 sm:col-span-4">
          <app-toggle-switch [defaultSelected]="paymentStatus" [options]="toggleOptions"
                             [disabled]="!canEdit || !canAccessForm || submitLoader" (optionChangeEmitter)="onChangeToggle($event)">
          </app-toggle-switch>
        </div>

        <div class="col-span-12 mt-1" *ngIf="paymentStatus == 0" >
          <label class="text-grey text-sm text-regular ">
            Loan Details
          </label>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-8 relative">
              <app-select-with-search id="loan-type" [options]="loanOptions"
                                      [disabled]="!canEdit || !canAccessForm"
                                      [placeholderValue]="'Select Vehicle Loan'"
                                      (optionChangeEmitter)="optionChangeEmitterLoanType($event)"
                                      [defaultSelected]="selectedLoanID"
                                      tabindex="0">
              </app-select-with-search>
            </div>
            <div class="col-span-12 md:col-span-4">
              <button type="button" [disabled]="disableLoanView" (click)="editVehicleLoan()"
                      class="m-auto hover:underline-text text-sm md:text-base tracking-wide w-full h-10 sm:h-12 bg-darkblue rounded-xs focus:outline-none text-regular text-white py-3 md:py-0 px-6 flex items-center justify-center"
                      [ngClass]="disableLoanView ? 'opacity-50' : ''">
                <div class="loading-red mr-2" *ngIf="submitLoader"></div>
                View / Edit
              </button>
            </div>
          </div>
        </div>

        <div class="col-span-12">
          <h4 class=" text-regular text-lg text-darkblue font-semibold tracking-wide ">
            Upload Vehicle File
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">
            <span class="font-sans font-bold">Accepted File Formats</span> — jpeg, jpg, png, doc, docx, pdf
          </p>
          <p class="text-grey text-regular pt-1 text-sm">
            <span class="font-sans font-bold">Maximum File Size</span> — 50 MB.
          </p>
        </div>

        <div class="col-span-12">
          <app-file-listing
            [fileArray]="vehicleFileArray"
            [removePrefix]="true"
            [permissions]="permissions"
            [isLoading]="preDefinedLoader"
            [isDisabled]="submitLoader || !canEdit"
            [fileUploadSettings]="fileUploadSettings"
            (fileListingEmitter)="fileListingListener($event)"
            subDirectory="vehicle_image">
          </app-file-listing>
        </div>

        <div class="col-span-12">
          <label for="price" class="text-label-grey text-sm text-regular">Purchase Price</label>
          <div class="relative flex items-center">
            <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
            <input type="text" [attr.disabled]="!canEdit || undefined" id="price" appCurrencyInput
                   class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 price-input"
                   name="vehicle-value" formControlName="vehicle_value" placeholder="e.g 1000"
                   [ngClass]="formGet.vehicle_value.errors && formGet.vehicle_value.touched?'form-input-error':'form-input-focus'">
          </div>
          <p *ngFor="let error of getErrors('vehicle_value')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="estimated-value" class="text-label-grey text-sm text-regular">Estimated Value</label>
          <div class="relative flex items-center">
            <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
            <input type="text" [attr.disabled]="!canEdit || undefined" id="estimated-value" appCurrencyInput
                   class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 price-input"
                   name="estimated-value" formControlName="estimated_value" placeholder="e.g 1000"
                   [ngClass]="formGet.estimated_value.errors && formGet.estimated_value.touched?'form-input-error':'form-input-focus'">
          </div>
          <p *ngFor="let error of getErrors('estimated_value')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <!-- Hide this until we can get disclaimer text -->
        <div class="col-span-12" *ngIf="false">
          <p class="text-regular text-sm placeholder-grey"> You can get your vehicle value estimate from here : <br>
            <a class="text-nav-blue" href="https://www.kbb.com/whats-my-car-worth/"
               target="self">https://www.kbb.com/whats-my-car-worth/</a>
          </p>
        </div>


        <div class="col-span-12">
          <label for="description" class="text-label-grey text-sm text-regular">Description</label>
          <textarea rows="4" cols="50" id="description" [attr.disabled]="!canEdit || undefined"
                    class="input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                    name="description" formControlName="description"

                    placeholder="Enter details about your vehicle or any relevant information here."

                    [ngClass]="formGet.description.errors && formGet.description.touched?'form-input-error':'form-input-focus'">
          </textarea>
          <p *ngFor="let error of getErrors('description')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
      </div>

      <div class=" flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 ">
        <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewVehicle" [buttonText]="'Remove Vehicle'"
                           [valid]="!submitLoader && canEdit"
                           [loader]="submitLoader" [buttonType]="'button'" [outlineOnly]="true"
                           (click)="deleteVehicle()">
        </app-button-loader>

        <app-button-loader class="w-full sm:w-auto" [buttonText]="!isNewVehicle?'Update Vehicle':'Add Vehicle'"
                           [valid]="((isNewVehicle && permissions?.['add']) || (!isNewVehicle && permissions?.['edit'])) && !submitLoader && canEdit"
                           [loader]="submitLoader" [buttonType]="'submit'"></app-button-loader>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>

<app-add-insurance [setEditData]="insuranceInputs?.['editData']" [permissions]="permissions"
                   [isNewAsset]="insuranceInputs?.['isNewAsset']" [lockType]="true"
                   (toggleModalEmitter)="modalEventListener($event)">
</app-add-insurance>

<!-- Add liabilities popup modal -->
<app-add-liabilities-popup [editData]="loanEditData" [setLockType]="this.vehicleLoanType?.['id']"
  [clientHasPaidSubscription]="clientHasPaidSubscription"
  [permissions]="permissions" (toggleModalEmitter)="loanModalEventListener($event)">
</app-add-liabilities-popup>


