<main class="relative">
  <!-- BEGIN: Navbar Wrapper -->
  <app-nav-landing-pro></app-nav-landing-pro>
  <!-- END: Navbar Wrapper -->

  <!-- BEGIN: Banner Wrapper -->
  <div class="company-banner__wrap relative flex items-center justify-center banner-padding-hfull mh-700-height">
    <div class="container mx-auto">
      <div class="flex items-center text-white">
          <div class="grid grid-cols-12 gap-4 md:gap-6 xl:gap-12">
            <div class="col-span-12 xl:col-span-8">
              <div class="mt-8 lg:mt-12">
                <h1 class="company-banner__head text-semibold fs-bhead text-regular">
                  LegacyNOW, secure asset management for estate planning
                </h1>
                <p class="company-banner__content fs-content mt-4 text-regular">
                  LegacyNOW is dedicated to easing the burden many encounter when a loved one passes. We help people prepare
                  for the
                  unexpected, so they can live their best life and set their loved ones up to do the same.
                </p>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <!-- END: Banner Wrapper -->

  <div class="section">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 gap-4 md:gap-6 xl:gap-12">
        <div class="col-span-12">
          <div class="title__wrap pb-4">
            <h1 class="text-primary text-semibold fs-title max-w-5xl mx-auto text-regular text-center">
              LegacyNOW – our history diﬀerentiates
              us
            </h1>
          </div>
          <div class="content__wrap space-y-4 md:space-y-6">
            <p class="fs-content text-regular text-content mx-auto text-center">
              The Estate Registry (TER), the parent company of LegacyNOW, is a
              trailblazer in the field of estate management services. TER offers a range of
              cloud-based tools and services designed for inter-generational estate
              management. These offerings encompass LegacyNOW, a comprehensive
              digital estate lifecycle management suite for navigating the estate process;
              NotifyNOW, a platform that provides essential support to estate executors;
              and InheritNOW, a service tailored to provide inheritance advances to
              beneficiaries in need of swift access to their inheritance funds.
            </p>
            <p class="fs-content text-regular text-content mx-auto text-center">
              The Estate Registry was the brainchild of a team of seasoned professionals
              with decades of experience serving both organizations and individuals across
              the United States, Canada, and the United Kingdom. During their extensive
              tenure, they recognized the challenges associated with estate management,
              particularly in the period after a person has passed, as an area where
              individuals and organizations could significantly benefit from services aimed at
              automating and streamlining the transfer of assets to beneficiaries. The
              creation of The Estate Registry was driven by this identified need.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>




  <div class="section beneficiary-proof-of-death__wrap">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-baseline gap-4 md:gap-6 xl:gap-12">
        <div class="col-span-12 lg:col-span-6 flex flex-col-reverse lg:flex-col">
          <img src="assets/images/company/beneficiaries.webp" width="100%" height="100%" class="pb-10 lg:pb-12" alt="legacy-now">
            <div>
              <h2 class="fs-title text-primary text-semibold mb-2">
                TER is designed to support various sectors
              </h2>
              <p class="fs-content text-content text-regular mb-6">
                such as finance, legal, government, and utilities. Our clients enjoy the advantages of
                having access to industry experts with top-tier skills, a wealth of technological
                resources, exclusive databases, and an unwavering dedication to quality. TER caters
                to a diverse global clientele through its regional offices located in the United States,
                Canada, Europe, and Australia.
              </p>
            </div>
        </div>
        <div class="col-span-12 lg:col-span-6 flex flex-col-reverse lg:flex-col">
          <img src="assets/images/company/proof-of-death.webp" width="100%" height="100%" class="pb-4 lg:pb-12"
            alt="legacy-now">
              <div>
                <h2 class="fs-title text-primary text-semibold mb-2">
                  Dedication to deliver superior value to companies,
                  executors, and beneﬁciaries
                </h2>
                <p class="fs-content text-content text-regular mb-6">
                  who engage with us, resulted in the creation of our decedent notification
                  system, NotifyNOW <a href="https://notifynow.com/" target="_blank"
                    class="underline text-primary">(www.notifynow.com)</a>. NotifyNOW assists executors and
      
                  estate administrators by facilitating the death notification process to
                  companies where the deceased held accounts. NotifyNOW is a private and
                  time-saving tool that allows executors to quickly fulfill their duties, reducing
                  stress during this difficult time of bereavement.
                </p>
              </div>
        </div>
      </div>
    </div>
  </div>


  <div class="section">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 gap-4 md:gap-6 xl:gap-12">
        <div class="col-span-12">
          <div class="title__wrap pb-4">
            <h1 class="text-primary text-semibold fs-title max-w-5xl mx-auto text-regular text-center">
              NotifyNOW, replaces conventional deceased
              notiﬁcations.
            </h1>
          </div>
          <div class="content__wrap">
            <p class="company-banner__content fs-content mt-0 lg:mt-4 text-regular">
              Our streamlined process provides a single entry platform that can be used to
              notify any company, and provides a compassionate approach we call Passing
              Care
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="section">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 gap-4 md:gap-6 xl:gap-12">
        <div class="col-span-12">
          <div class="title__wrap pb-4">
            <h1 class="text-primary text-semibold fs-title max-w-5xl mx-auto text-regular text-center">
              NotifyNOW, the prelude to LegacyNOW
            </h1>
          </div>
          <div class="content__wrap space-y-4 md:space-y-6">
            <p class="fs-content text-regular text-content mx-auto text-center">
              After the development of NotifyNOW, we recognized that it only addressed half of the
              challenges faced by executors when dealing with deceased notifications. While a top-
              notch tool for deceased notifications proved to be immensely beneficial to executors,
              they still had to confront the uncomfortable duty of visiting the homes of their departed
              loved ones to retrieve important company information.
            </p>
            <p class="fs-content text-regular text-content mx-auto text-center">
              Executors frequently reported difficulties in locating company information and essential
              account documentation required for reporting a death. Additionally, they often struggled
              with accessing the necessary passwords to retrieve critical information. This often led to
              incomplete notifications to various companies and lingering issues in the estate
              resolution process for beneficiaries.
            </p>
            <p class="fs-content text-regular text-content mx-auto text-center">
              The phrase "necessity is the mother of invention" rang true as it became evident that we
              needed to offer a more comprehensive solution for estate resolution for executors. The
              missing piece of the puzzle was a personalized digital vault designed to proactively and
              securely store all an account holder's assets digitally throughout their lifetime, which
              could seamlessly transfer to the executor upon the individual's passing. This is how
              LegacyNOW came into existence.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- BEGIN: Completed Programming Wrap -->
  <div class="section advisors__wrap">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-center gap-4 md:gap-6 xl:gap-12">
        <div class="col-span-12 lg:col-span-6">
          <img src="assets/images/company/advisor.webp" width="100%" height="100%" alt="legacy-now"
            class="rounded-md overflow-hidden">
        </div>
        <div class="col-span-12 lg:col-span-6 xl:pl-4 lg:pb-0 order-first lg:order-last">
          <h2 class="fs-title text-primary text-semibold mb-4">
            A global leader in compassionate care
            services and technology
          </h2>
          <p class="fs-content text-content text-regular">
            Our expertise empowers us to seamlessly broaden our business scope to
            encompass estate account resolution, executor assistance, beneficiary
            services, and life planning. Compassion and innovation lie at the core of our
            company's mission, aiming to simplify and lighten the load of estate data
            management and resolution at every juncture.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Completed Programming Wrap -->

  <div class="section">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 gap-4 md:gap-6 xl:gap-12">
        <div class="col-span-12">
          <div class="title__wrap pb-4">
            <h1 class="text-primary text-semibold fs-title max-w-5xl mx-auto text-regular text-center">
              The missing link for Professional
              Service Providers
            </h1>
          </div>
          <div class="content__wrap space-y-4 md:space-y-6">
            <p class="fs-content text-regular text-content mx-auto text-center">
              After extensive research and numerous focus group discussions with professional
              service providers, such as wealth advisors, it became evident that while the market is
              saturated with estate management software, none of them effectively combine estate
              data preparation features with estate resolution tools to create a comprehensive estate
              settlement platform. We firmly believe that our best-in-class products are in demand
              among estate planners and other professional service providers because they fill a
              critical gap and serve as a distinguishing factor for their clients.
            </p>
            <p class="fs-content text-regular text-content mx-auto text-center">
              LegacyNOW is specifically crafted to offer value-added services that have been
              lacking in estate, trusts, and probate administration practices. We are fully committed
              to delivering the most advanced estate and legacy data management tool currently
              available in the market, with a forward-thinking approach to meeting your future
              needs. Our dedication is to earn your business, and we have established a robust
              communication system to facilitate professional service providers in providing and
              receiving feedback, enhancing system capabilities, and supporting your business.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- BEGIN: Testimonial Wrapper-->
  <div class="section">
    <app-testimonials [mobile]="mobile"></app-testimonials>
  </div>

  <!-- END: Testimonial Wrapper -->


  <!-- BEGIN: Footer Wrapper -->
  <app-footer-landing-pro></app-footer-landing-pro>

</main>

<div class="fixed right-6 bottom-6 group z-50">
  <button type="button" aria-expanded="false" (click)="openModal('assistanceHelp')"
    class="flex items-center justify-center text-white bg-blue-700 rounded-full w-16 h-16 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
    <img src="assets/images/intro-video.png" alt="">
    <span class="sr-only">Open actions menu</span>
  </button>
</div>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>