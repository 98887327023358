<div class="scroll-hidden box-border body-content pb-12" (click)="toggleDropDown(-1);">
  <!--end-->
  <div class="pt-12">
    <div class="flex flex-wrap lg:mx-12 mx-8">
      <div class="w-full md:w-3/5">
        <div>
          <!-- If the user is a company, show the company name; otherwise, show the logged-in user's name -->
          <h2 class="text-darkblue text-lg lg:text-5xl md:text-2xl sm:text-md capitalize text-semibold">
            Welcome {{ userData["full_name"] }}
          </h2>
          <!-- If the logged-in user is an Advisor or Manager and affiliated with a company, show the company name -->
          <p *ngIf="roleName !== PROFESSIONAL_ROLE.professional && companyName"
            class="text-sm lg:text-2xl md:text-lg sm:text-base text-grey py-2 tracking-wide text-regular mb-3">
            {{companyName}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Cards Section Start -->
  <div *ngIf="dataLoading"
    class="mx-8 lg:mx-12 mt-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 lg:gap-8 mb-8">
    <ng-container *ngFor="let _ of [].constructor(8)">
      <app-shimmer-loading [loading]="dataLoading" [width]="'100%'" [height]="'6rem'">
      </app-shimmer-loading>
    </ng-container>
  </div>
  <div *ngIf="!dataLoading" [class]="roleName !== PROFESSIONAL_ROLE.agent ? 'xl:grid-cols-4' : 'xl:grid-cols-3'"
    class="mx-8 lg:mx-12 mt-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8 mb-8">
    <!-- Total Vaults Created -->
    <div class="card bg__card--three hover:shadow-lg">
      <div class="card-body py-6" (click)="scrollTo('VaultChart')">
        <div class="flex flex-row justify-between items-center px-4 gap-2">
          <div>
            <div class="flex flex-row">
            <h3 class="text-lg font-semibold text-charcoal pb-1">
              <span>
              Total Vaults Created <small class="text-grey">YTD</small></span>
              <span class="card_icon cursor-pointer ml-2">
                <fa-icon [icon]="faInfoCircle" [tooltip]="cardsToolTip.vaults_created" [options]="toolTipOptions"></fa-icon>
              </span>
            </h3>
          </div>
            <p class="font-bold text-green text-4xl">{{dataOverview?.['vaults_created']}}</p>
          </div>
          <svg viewBox="0 0 24 24" width="50" height="50" stroke="#00e1a4" stroke-width="2" fill="none"
            stroke-linecap="round" stroke-linejoin="round">
            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
            <line x1="12" y1="11" x2="12" y2="17"></line>
            <line x1="9" y1="14" x2="15" y2="14"></line>
          </svg>
        </div>
      </div>
    </div>
    <!--End: Total Vaults Created -->
    <!-- Total Vaults Updated -->
    <div class="card bg__card--four hover:shadow-lg" (click)="scrollTo('VaultChart')">
      <div class="card-body py-6">
        <div class="flex flex-row justify-between items-center px-4 gap-2">
          <div>
           <div class="flex flex-row">
            <h3 class="text-lg font-semibold text-charcoal pb-1">
              Vaults Updated
            <span class="card_icon cursor-pointer ml-2">
              <fa-icon [icon]="faInfoCircle" [tooltip]="cardsToolTip.vaults_updated" [options]="toolTipOptions"></fa-icon>
            </span>
            </h3>
           </div>
            <p class="font-bold text-violet text-4xl">
              {{dataOverview?.['vaults_completed']}}
              <small
                *ngIf="dataOverview?.['vaults_created'] >0">({{100*dataOverview?.['vaults_completed']/dataOverview?.['vaults_created']
                |
                number:'1.0-0'}}%)</small>
            </p>
          </div>
          <svg viewBox="0 0 24 24" width="50" height="50" stroke="#c07cff" stroke-width="2" fill="none"
            stroke-linecap="round" stroke-linejoin="round">
            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
          </svg>
        </div>
      </div>
    </div>
    <!-- End: Total Vaults Updated -->
        <!-- Vaults Out-Of-Date -->
        <div class="card bg__card--five hover:shadow-lg" (click)="scrollTo('VaultChart')">
          <div class="card-body py-6">
            <div class="flex flex-row justify-between items-center px-4 gap-2">
              <div>
               <div class="flex flex-row">
                <h3 class="text-lg font-semibold text-charcoal pb-1">Vaults Out-Of-Date
                <span class="card_icon cursor-pointer ml-2">
                  <fa-icon [icon]="faInfoCircle" [tooltip]="cardsToolTip.vaults_out_of_date" [options]="toolTipOptions"></fa-icon>
                </span>
                </h3>
               </div>
                <p class="font-bold text-five text-4xl">
                  {{dataOverview?.['vaults_incomplete']}}
                  <small *ngIf="dataOverview?.['vaults_created']>0">
                    ({{100*dataOverview?.['vaults_incomplete']/dataOverview?.['vaults_created']
                    | number:'1.0-0'}}%)
                  </small>
                </p>
              </div>
              <svg viewBox="0 0 24 24" width="50" height="50" stroke="#5eaff7" stroke-width="2" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                <line x1="9" y1="14" x2="15" y2="14"></line>
              </svg>
            </div>
          </div>
        </div>
        <!--End: Vaults Out-Of-Date -->
    <!-- Vault Onboard Rate -->
    <div class="card bg__card--ten hover:shadow-lg" (click)="scrollTo('VaultChart')"
      *ngIf="roleName === PROFESSIONAL_ROLE.professional || roleName === PROFESSIONAL_ROLE.manager">
      <div class="card-body py-6">
        <div class="flex flex-row justify-between items-center px-4 gap-2">
          <div class="w-9/12">
            <div class="flex flex-row">
              <h3 class="text-lg font-semibold text-charcoal pb-1">Vault Onboard Rate
              <span class="card_icon cursor-pointer ml-2">
                <fa-icon [icon]="faInfoCircle" [tooltip]="cardsToolTip.vaults_onboard_rate" [options]="toolTipOptions"></fa-icon>
              </span>
              </h3>
            </div>
            <p class="font-bold text-six text-4xl">
              {{dataOverview?.['vault_onboard_rate']}}%
            </p>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="50"
            height="50">
            <path
              d="m11.375,11.805s.473.195.626.195c.126,0,.559-.147.559-.147,1.042-.419,4.44-2.07,4.44-5.723v-2.63c0-1.08-.688-2.033-1.714-2.373l-2.971-.985c-.205-.067-.425-.067-.629,0l-2.973.985c-1.024.34-1.713,1.293-1.713,2.373v2.63c0,3.237,3.349,5.162,4.375,5.675Zm-2.375-8.305c0-.216.138-.407.342-.475l2.658-.881,2.657.881c.205.068.343.259.343.475v2.63c0,2.189-2.032,3.353-2.955,3.769-.839-.457-3.045-1.84-3.045-3.769v-2.63Zm8.819,7.296c.4.135.811.204,1.22.204h3.961c.553,0,1,.448,1,1s-.447,1-1,1h-3.961c-.627,0-1.252-.104-1.858-.308-.523-.177-.805-.744-.628-1.268.176-.523.742-.806,1.267-.628Zm6.181,8.204c0,.553-.447,1-1,1h-4.531l-4.065,3.173c-.684.533-1.537.827-2.403.827s-1.72-.294-2.403-.827l-4.066-3.173H1c-.552,0-1-.447-1-1s.448-1,1-1h4.875c.223,0,.439.074.615.212l4.337,3.385c.667.52,1.679.52,2.346,0l3.118-2.434-4.027-3.589-1.604,1.493c-.3.302-.749.477-1.215.433-.462-.036-.883-.274-1.156-.652-.451-.63-.372-1.492.188-2.054l1.215-1.122c.764-.703,1.944-.86,2.856-.404.16.05.31.141.432.272l.003.003c.04.031,4.781,4.256,5.007,4.457h5.009c.553,0,1,.447,1,1ZM0,12c0-.552.448-1,1-1h3.958c.41,0,.821-.068,1.223-.204.526-.175,1.09.106,1.267.629.176.523-.105,1.091-.629,1.267-.608.205-1.234.308-1.86.308H1c-.552,0-1-.448-1-1Z"
              fill="#9acd32" />
          </svg>
        </div>
      </div>
    </div>
    <!--End: Vault Onboard Rate -->
    <!-- % Active Vaults -->
    <div class="card bg__card--nine hover:shadow-lg" (click)="scrollTo('VaultChart')"
      *ngIf="roleName === PROFESSIONAL_ROLE.professional || roleName === PROFESSIONAL_ROLE.manager">
      <div class="card-body py-6">
        <div class="flex flex-row justify-between items-center px-4 gap-2">
          <div class="w-9/12">
            <div class="flex flex-row">
              <h3 class="text-lg font-semibold text-charcoal pb-1">% Active Vaults
                <span class="card_icon cursor-pointer ml-2">
                  <fa-icon [icon]="faInfoCircle"  [tooltip]="cardsToolTip.active_vaults" [options]="toolTipOptions"></fa-icon>
                </span>
              </h3>
            </div>
            <p class="font-bold text-nine text-4xl">
              {{dataOverview?.['vaults_access_firm']}}%
            </p>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="50" height="50">
            <path
              d="M18.581,2.14,12.316.051a1,1,0,0,0-.632,0L5.419,2.14A4.993,4.993,0,0,0,2,6.883V12c0,7.563,9.2,11.74,9.594,11.914a1,1,0,0,0,.812,0C12.8,23.74,22,19.563,22,12V6.883A4.993,4.993,0,0,0,18.581,2.14ZM20,12c0,5.455-6.319,9.033-8,9.889-1.683-.853-8-4.42-8-9.889V6.883A3,3,0,0,1,6.052,4.037L12,2.054l5.948,1.983A3,3,0,0,1,20,6.883Z"
              fill="#618ff8" />
            <path
              d="M15.3,8.3,11.112,12.5,8.868,10.16a1,1,0,1,0-1.441,1.386l2.306,2.4a1.872,1.872,0,0,0,1.345.6h.033a1.873,1.873,0,0,0,1.335-.553l4.272-4.272A1,1,0,0,0,15.3,8.3Z"
              fill="#618ff8" />
          </svg>
        </div>
      </div>
    </div>
    <!--End: % Active Vaults -->

    <!-- <div *ngIf="roleName === PROFESSIONAL_ROLE.professional || roleName === PROFESSIONAL_ROLE.manager"
      class="card bg__card--one hover:shadow-lg">
      <div class="card-body py-6" (click)="scrollTo('ManagerTable')">
        <div class="flex flex-row justify-between items-center px-4 gap-2">
          <div>
            <h3 class="text-lg font-semibold text-charcoal pb-1">Total Managers</h3>
            <p class="font-bold text-red text-4xl">{{dataOverview?.['managers']}}</p>
          </div>
          <svg viewBox="0 0 24 24" width="50" height="50" stroke="#d72c00" stroke-width="2" fill="none"
            stroke-linecap="round" stroke-linejoin="round">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>

        </div>
      </div>
    </div> -->
    <!-- <div *ngIf="roleName === PROFESSIONAL_ROLE.manager || roleName === PROFESSIONAL_ROLE.professional"
      class="card bg__card--two hover:shadow-lg">
      <div class="card-body py-6" (click)="scrollTo('AgentTable')">
        <div class="flex flex-row justify-between item-center px-5">
          <div>
            <h3 class="text-lg font-semibold text-charcoal pb-1">Total Professionals</h3>
            <p class="font-bold text-yellow text-4xl">{{dataOverview?.['agents']}}</p>
          </div>
          <svg viewBox="0 0 24 24" width="50" height="50" stroke="#fdb400" stroke-width="2" fill="none"
            stroke-linecap="round" stroke-linejoin="round">
            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          </svg>
        </div>

      </div>
    </div> -->



    <!-- Total Vaults allowing access to Firm -->
    <div class="card bg__card--six hover:shadow-lg" (click)="scrollTo('VaultChart')"
      *ngIf="roleName === PROFESSIONAL_ROLE.manager">
      <div class="card-body py-6">
        <div class="flex flex-row justify-between items-center px-4 gap-2">
          <div class="w-9/12">
            <div class="flex flex-row">
              <h3 class="text-lg font-semibold text-charcoal pb-1">Total Vaults allowing access to Firm
                <span class="card_icon cursor-pointer ml-2">
                  <fa-icon [icon]="faInfoCircle"></fa-icon>
                </span>
              </h3>
            </div>
            <p class="font-bold text-six text-4xl">
              {{dataOverview?.['vaults_access_firm']}}
              <small
                *ngIf="dataOverview?.['vaults_created']>0 && dataOverview?.['vaults_access_firm'] !==404">({{100*dataOverview?.['vaults_access_firm']/dataOverview?.['vaults_created']
                |
                number:'1.0-0'}}%)</small>
            </p>
          </div>
          <svg viewBox="0 0 24 24" width="50" height="50" stroke="#9acd32" stroke-width="2" fill="none"
            stroke-linecap="round" stroke-linejoin="round">
            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
          </svg>
        </div>
      </div>
    </div>
    <!--End: Total Vaults allowing access to Firm -->
    <!-- Total Vaults allowing contact with Executors & Beneficiaries -->
    <div class="card bg__card--seven hover:shadow-lg" (click)="scrollTo('VaultChart')">
      <div class="card-body py-6">
        <div class="flex flex-row justify-between items-center px-4 gap-2">
          <div class="w-9/12">
           <div class="flex flex-row">
            <h3 class="text-lg font-semibold text-charcoal pb-1">
              Vaults with Extended <span class="whitespace-nowrap">Contact
              <span class="card_icon cursor-pointer ml-1">
                <fa-icon [icon]="faInfoCircle" [tooltip]="cardsToolTip.vaults_access_contact" [options]="toolTipOptions"></fa-icon>
              </span>
            </span>
            </h3>
           </div>
            <p class="font-bold text-seven text-4xl">
              {{dataOverview?.['vaults_access_contact']}}
              <small
                *ngIf="dataOverview?.['vaults_created']>0 && dataOverview?.['vaults_access_contact']!==404">({{100*dataOverview?.['vaults_access_contact']/dataOverview?.['vaults_created']
                |
                number:'1.0-0'}}%)</small>
            </p>
          </div>
          <svg viewBox="0 0 24 24" width="50" height="50" stroke="#E1C015" stroke-width="2" fill="none"
            stroke-linecap="round" stroke-linejoin="round">
            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="8.5" cy="7" r="4"></circle>
            <polyline points="17 11 19 13 23 9"></polyline>
          </svg>
        </div>
      </div>
    </div>
    <!--End: Total Vaults allowing contact with Executors & Beneficiaries -->
    <div class="card bg__card--two hover:shadow-lg" *ngIf="this.roleName == PROFESSIONAL_ROLE.professional">
      <div class="card-body py-6">
        <div class="flex flex-row justify-between items-center px-4 gap-2">
          <div class="w-9/12">
            <div class="flex flex-row">
              <h3 class="text-lg font-semibold text-charcoal pb-1">No. of Vaults with a Vault Custodian
                <span class="card_icon cursor-pointer ml-1">
                  <fa-icon [icon]="faInfoCircle" [tooltip]="cardsToolTip.vaults_with_custodian" [options]="toolTipOptions"></fa-icon>
                </span>
              </h3>
            </div>
            <p class="font-bold text-six text-4xl">{{dataOverview?.['vaults_with_custodian']}} 
              <small
                *ngIf="dataOverview?.['vaults_created']>0 && dataOverview?.['vaults_with_custodian']!==404">({{100*dataOverview?.['vaults_with_custodian']/dataOverview?.['vaults_created']
                |
                number:'1.0-0'}}%)</small>
            </p>
          </div>
          <svg viewBox="0 0 24 24" width="50" height="50" stroke="#fd7200" stroke-width="2" fill="none"
            stroke-linecap="round" stroke-linejoin="round">
            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <!-- Cards Section End -->

  <!-- Charts Section -->
  <div class="mx-8 lg:mx-12 mt-3">
    <!-- <div class="border-card bg-white rounded-md p-6 mb-6">
      <h4 class="text-semibold text-grey text-md md:text-lg lg:text-xl xl:text-2xl">
        Digital Vault Subscription
      </h4>
      <app-shimmer-loading [loading]="dataLoading" [width]="'100%'">
        <p class="text-grey text-regular text-md md:text-md lg:text-md mt-2">
          Client Vault Subscription count = {{totalClients}}
        </p>
      </app-shimmer-loading>

    </div> -->
    <div class="flex flex-wrap">
      <!-- <div class="w-full md:w-3/6 px-2 mb-3 border-card bg-white rounded-md p-6"
        *ngIf="this.roleName !== PROFESSIONAL_ROLE.agent">
        <div class="px-2">
          <h4 class="text-semibold text-grey text-md md:text-lg lg:text-xl xl:text-2xl">Professionals Managed Chart
          </h4>
          <p class="text-grey text-regular text-sm md:text-md lg:text-md pb-3">
            Total Professionals under management
          </p>
          <app-shimmer-loading [loading]="dataLoading" [width]="'100%'" [height]="'4rem'"></app-shimmer-loading>
          <canvas *ngIf="showData && !dataLoading" baseChart [datasets]="barChartDataAdvisor" [labels]="barChartLabels"
            [options]="barChartOptions" [legend]="true" [chartType]="'bar'">
          </canvas>
        </div>
      </div> -->
      <!-- <div class="w-full px-2 mb-3 border-card bg-white rounded-md p-6" id="VaultChart"
        [ngClass]="this.roleName !== PROFESSIONAL_ROLE.agent?'md:w-3/6':''"> -->
      <div class="w-full px-2 mb-3 border-card bg-white rounded-md p-6" id="VaultChart">
        <div class="px-2">
          <h4 class="text-semibold text-grey text-md md:text-lg lg:text-xl xl:text-2xl">
            Digital Vault Clients Chart
          </h4>
          <p class="text-grey text-regular text-sm md:text-md lg:text-md pb-3">
            Total Digital Vaults under management
          </p>
          <app-shimmer-loading [loading]="dataLoading" [width]="'100%'" [height]="'4rem'"></app-shimmer-loading>
          <canvas *ngIf="showData && !dataLoading" baseChart [datasets]="barChartData" [labels]="barChartLabels"
            [options]="barChartOptions" [legend]="true" [chartType]="'bar'"
            [attr.height]="this.screenWidth > 500 ? '35%' : '45%'" width="100%">
          </canvas>
        </div>
      </div>
    </div>
  </div>
  <!-- Managers section -->
  <!-- <div class="pt-6 mx-8 lg:mx-12"
    *ngIf="roleName === PROFESSIONAL_ROLE.manager || roleName === PROFESSIONAL_ROLE.professional">
    <div class="border-card bg-white rounded-md p-6">

      <div class="flex justify-between" id="ManagerTable">
        <div>
          <h4 class="text-semibold text-grey text-md md:text-lg lg:text-xl xl:text-2xl">
            Managers
          </h4>
          <p class="text-grey text-regular text-sm md:text-md lg:text-md">
            Manager Details
          </p>
        </div>
        <div class="grid grid-cols-3">
          <div class="w-full col-span-2">
            <div class="w-full relative h-10 pr-2 text-grey ">
              <div class=" absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"><img
                  src="assets/images/about-yourself/search.svg" class="w-4  h-4" alt="digital vault"></div>
              <input type="text" class=" input py-3 px-4 w-full text-regular text-grey
                          rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                placeholder="Search by Name" (input)="onSearchManager($event.target['value'])">
            </div>
          </div>
          <button
            (click)="changeRoute(roleType === 'Law Firm'? 'managers/add' : roleType === 'Wealth Management'? 'project-managers/add':'funeral-directors/add')"
            class="button button--lg bg-darkblue px-4 rounded-xs h-10 focus:outline-none text-regular text-white text-sm flex flex-row justify-center items-center">
            <img src="assets/images/dashboard/plus-white.svg" class="mr-3" alt="LegacyNow" />
            Add Manager
          </button>
        </div>
      </div>
      <div *ngIf="dataLoading">
        <div class="mt-3 w-full">
          <app-shimmer-loading [loading]="true" [width]="'100%'" [height]="'3rem'"></app-shimmer-loading>
        </div>
        <div class="flex flex-row mt-3 mb-6 items-center">
          <div>
            <app-shimmer-loading [loading]="true" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
          </div>
          <div class="ml-4 mt-0 w-full">
            <ng-container *ngFor="let _ of [].constructor(3)">
              <app-shimmer-loading [loading]="true" [width]="'100%'"></app-shimmer-loading>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="mt-5" *ngIf="!dataLoading">
        <div class="professional-table overflow-x-auto">
          <table class="table w-full text-left mb-10" aria-describedby="professionalTable">
            <thead class="bg-gray-400 text-blue text-md text-regular">
              <tr>
                <th class="p-4" scope="row">Name</th>
                <th class="p-4 whitespace-normal" scope="row">
                  No&nbsp;of&nbsp;{{getAdvisorTerm()}}
                </th>
                <th class="p-4 whitespace-normal" scope="row">Added&nbsp;On</th>
                <th class="p-4 whitespace-normal" scope="row">Added&nbsp;By</th>
                <th class="p-4 whitespace-normal" scope="row">
                  Last&nbsp;Login
                </th>
                <th scope="row">Action</th>
              </tr>
            </thead>
            <tbody *ngFor="let agent of managerList; let i = index">
              <tr class="border-b-2 border-fuchsia-600 p-4 relative">
                <td class="p-2 lg:p-4">
                  <div class="flex items-center">
                    <div class="relative w-16 h-16">
                      <app-image-component [image]="agentImageSrc + agent['profile_picture']" [class]="
                              'rounded-full w-14 h-14 lg:w-16 lg:h-16 object-cover '
                            " [defaultImage]="'assets/icons/user-bg.svg'">
                      </app-image-component>
                      <div
                        class="active w-4 h-4 rounded-full bg-green-500 border-2 border-white absolute bottom-2 right-0">
                      </div>
                    </div>
                    <div class="pl-4">
                      <a class="text-blue-600 text-semibold underline cursor-pointer text-md lg:text-lg text-regular">
                        {{ agent["full_name"] }}</a>
                      <p class="text-grey text-base lg:text-md text-semibold">
                        {{ agent["email"] }}
                      </p>
                    </div>
                  </div>
                </td>
                <td class="p-4">
                  <div class="flex items-center">
                    <div>
                      <img src="assets/images/dashboard/table-user.svg" alt="digital vault" />
                    </div>
                    <div class="pl-3">
                      <p class="text-grey text-regular tracking-wide">
                        {{ agent["total_clients"] }}
                      </p>
                    </div>
                  </div>
                </td>
                <td class="p-4 whitespace-normal">
                  <p class="text-grey text-regular text-md">
                    {{ agent["added_on"] }}
                  </p>
                </td>
                <td class="p-4">
                  <a
                    class="text-blue-900 text-semibold text-md underline cursor-pointer text-md lg:text-lg text-regular">
                    {{ agent["added_by"] }}</a>
                </td>

                <td class="p-4">
                  <p class="text-grey text-regular tracking-wide whitespace-normal">
                    {{ agent["last_login"] }}
                  </p>
                </td>
                <td class="p-4 cursor-pointer" (click)="toggleDropDownManager(i)">
                  <img src="assets/images/dashboard/icon-more.png" alt="db" />
                </td>
                <div
                  class="mt-2 py-1 absolute right-0 top-12 w-60 rounded-md shadow-lg bg-white focus:outline-none z-10"
                  role="menu" aria-orientation="vertical" aria-labelledby="user-menu"
                  *ngIf="profileMenuToggleManager && this.managerIndex === i ">
                  <a (click)="changeRoute('managers/edit/'+agent['id']);editAgent(agent['id'])"
                    class="cursor-pointer  block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100"
                    role="menuitem">Edit</a>
                  <a (click)="openConfirmationModal(agent, 'Manager')"
                    class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100"
                    role="menuitem">Delete</a>
                </div>
              </tr>
            </tbody>
          </table>
          <div class="flex justify-center mt-5 bg-card-overview border-card-grey rounded"
            *ngIf="managerList.length===0">
            <div class="my-4">
              <h4 class="text-base text-regular tracking-wide text-semibold  text-center">
                No Data to Display
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Advisor's section -->
  <!-- <div class="pt-6 mx-8 lg:mx-12"
    *ngIf="roleName === PROFESSIONAL_ROLE.manager || this.roleName === PROFESSIONAL_ROLE.professional">
    <div class=" border-card bg-white rounded-md p-6">

      <div class="flex justify-between" id="AgentTable">
        <div>
          <h4 class="text-semibold text-grey text-md md:text-lg lg:text-xl xl:text-2xl">
            {{getAdvisorTerm()}}s
          </h4>
          <p class="text-grey text-regular text-sm md:text-md lg:text-md">
            {{getAdvisorTerm()}} Details
          </p>
        </div>
        <div class="grid grid-cols-3">
          <div class="w-full col-span-2">
            <div class="w-full relative h-10 pr-2 text-grey ">
              <div class=" absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"><img
                  src="assets/images/about-yourself/search.svg" class="w-4  h-4" alt="digital vault"></div>
              <input type="text" class=" input py-3 px-4 w-full text-regular text-grey
                          rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                placeholder="Search by Name" (input)="onSearchAgent($event.target['value'])">
            </div>
          </div>
          <button (click)="changeRoute(roleType === 'Law Firm'? 'attorney/add' : roleType === 'Wealth Management'?
          'advisors/add':'sales-agents/add')" class="button button--lg bg-darkblue px-4 rounded-xs h-10 focus:outline-none text-regular text-white text-sm
            flex flex-row justify-center items-center">
            <img src="assets/images/dashboard/plus-white.svg" class="mr-3" alt="LegacyNow" />
            Add {{getAdvisorTerm()}}
          </button>
        </div>
      </div>
      <div *ngIf="dataLoading">
        <div class="mt-3 w-full">
          <app-shimmer-loading [loading]="true" [width]="'100%'" [height]="'3rem'"></app-shimmer-loading>
        </div>
        <div class="flex flex-row mt-3 mb-6 items-center">
          <div>
            <app-shimmer-loading [loading]="true" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
          </div>
          <div class="ml-4 mt-0 w-full">
            <ng-container *ngFor="let _ of [].constructor(3)">
              <app-shimmer-loading [loading]="true" [width]="'100%'"></app-shimmer-loading>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="mt-5" *ngIf="!dataLoading">
        <div class="professional-table overflow-x-auto">
          <table class="table w-full text-left mb-10" aria-describedby="professionalTable">
            <thead class="bg-gray-400 text-blue text-md text-regular">
              <tr>
                <th class="p-4" scope="row">Name</th>
                <th class="p-4 whitespace-normal" scope="row">
                  No&nbsp;of&nbsp;Clients
                </th>
                <th class="p-4 whitespace-normal" scope="row">Added&nbsp;On</th>
                <th class="p-4 whitespace-normal" scope="row">Added&nbsp;By</th>
                <th class="p-4 whitespace-normal" scope="row">
                  Last&nbsp;Login
                </th>
                <th scope="row">Action</th>
              </tr>
            </thead>
            <tbody *ngFor="let agent of agentsList; let i = index">
              <tr class="border-b-2 border-fuchsia-600 p-4 relative">
                <td class="p-2 lg:p-4">
                  <div class="flex items-center">
                    <div class="relative w-16 h-16">
                      <app-image-component [image]="agentImageSrc + agent['profile_picture']" [class]="
                              'rounded-full w-14 h-14 lg:w-16 lg:h-16 object-cover '
                            " [defaultImage]="'assets/icons/user-bg.svg'">
                      </app-image-component>
                      <div
                        class="active w-4 h-4 rounded-full bg-green-500 border-2 border-white absolute bottom-2 right-0">
                      </div>
                    </div>
                    <div class="pl-4">
                      <a class="text-blue-600 text-semibold underline cursor-pointer text-md lg:text-lg text-regular">
                        {{ agent["full_name"] }}</a>
                      <p class="text-grey text-base lg:text-md text-semibold">
                        {{ agent["email"] }}
                      </p>
                    </div>
                  </div>
                </td>
                <td class="p-4">
                  <div class="flex items-center">
                    <div>
                      <img src="assets/images/dashboard/table-user.svg" alt="digital vault" />
                    </div>
                    <div class="pl-3">
                      <p class="text-grey text-regular tracking-wide">
                        {{ agent["total_clients"] }}
                      </p>
                    </div>
                  </div>
                </td>
                <td class="p-4 whitespace-normal">
                  <p class="text-grey text-regular text-md">
                    {{ agent["added_on"] }}
                  </p>
                </td>
                <td class="p-4">
                  <a
                    class="text-blue-900 text-semibold text-md underline cursor-pointer text-md lg:text-lg text-regular">
                    {{ agent["added_by"] }}</a>
                </td>

                <td class="p-4">
                  <p class="text-grey text-regular tracking-wide whitespace-normal">
                    {{ agent["last_login"] }}
                  </p>
                </td>
                <td class="p-4 cursor-pointer" (click)="toggleDropDownAgent(i)">
                  <img src="assets/images/dashboard/icon-more.png" alt="db" />
                </td>
                <div
                  class="mt-2 py-1 absolute right-0 top-12 w-60 rounded-md shadow-lg bg-white focus:outline-none z-10"
                  role="menu" aria-orientation="vertical" aria-labelledby="user-menu"
                  *ngIf="profileMenuToggleAgent && this.index === i ">
                  <a class="cursor-pointer  block px-4 py-2 text-sm text-grey text-regular" role="menuitem"
                    (click)="editAgent(agent['id'])">Edit</a>
                  <a (click)="openConfirmationModal(agent, getAdvisorTerm())"
                    class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular" role="menuitem">Delete</a>
                </div>
              </tr>
            </tbody>
          </table>
          <div class="flex justify-center mt-5 bg-card-overview border-card-grey rounded"
            *ngIf="agentsList?.length === 0">
            <div class="my-4">
              <h4 class="text-base text-regular tracking-wide text-semibold  text-center">
                No Data to Display
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Advisor Manager as Professional section-->
  <!-- <app-home-professional-advisor-manager></app-home-professional-advisor-manager> -->

   <!-- Client Management Cards -->
  <app-client-management-cards  (buttonGroupEvent)="buttonGroupEventListener($event)"></app-client-management-cards>

    <!-- Clients Section -->
  <div class="pt-6 mx-8 lg:mx-12">
    <div class="border-card bg-white rounded-md p-6 ">
      <div class="pt-8">
        <div class="flex flex-wrap mx-2">
          <div class="w-full md:w-2/5">
            <div class="mb-3">
              <h4 class="text-semibold text-grey text-md md:text-lg lg:text-xl xl:text-2xl">
                Digital Vaults
              </h4>
              <p class="text-grey text-regular text-sm md:text-md lg:text-md">
                Clients Details
              </p>
            </div>
            <!-- <div>
              <div class="sm:w-full md:w-full mr-4  relative h-10 text-grey ">
                <div class=" absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"><img
                    src=" assets/images/about-yourself/search.svg " class="w-4  h-4" alt="digital vault"></div>
                <input type="text"
                  class=" input py-3 px-4 mt-1 w-full text-regular text-grey rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                  placeholder="Search by Name" (input)="onSearchClient($event.target['value'])">
              </div>
            </div> -->
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-1/2 ">
              <div class="relative">
                  <div class="sm:w-full md:w-2/4  relative h-10 text-grey ">
                      <div class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"> <img
                              src=" assets/images/about-yourself/search.svg " class="w-4  h-4" alt="digital vault"> </div>
                      <input type="text" class=" input py-3 px-4 mt-1 w-full text-regular text-grey
                          rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                          placeholder="Search by Name" (input)="onSearchClient($event.target['value'])">
                  </div>
              </div>
          </div>
          <div class="w-full lg:w-1/2">
              <div class="flex flex-wrap item-center justify-between">
                <div>
                  <div class=" md:justify-end">
                    <div class="flex justify-center item-center text-grey mt-4">
                      <div
                        class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                        Showing: 
                      </div>
                      <select [(ngModel)]="clientListTableSettings.recordsPerPage" (change)="getClientsList()"
                        class="text-center form-select cursor-pointer bg-transparent ml-4 pt-1 mt-0 rounded-xs w-20 h-8 text-regular text-grey p-2 border-none block focus:outline-none insurance_term">
                        <option *ngFor="let option of selectOptions;let i=index" [value]="option['id']">
                          {{option["value"]}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class=" md:justify-end css-shortby">
                  <div class="md:justify-end">
                    <div class="flex justify-center item-center h-10 text-grey mt-4">
                      <div
                        class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                        Sort by: 
                      </div>
                      <select [(ngModel)]="clientListTableSettings.sortPreference" (change)="getClientsList()"
                        class="text-center form-select cursor-pointer bg-transparent ml-4 pt-1 mt-0 rounded-xs h-10 text-regular text-grey border-none block focus:outline-none insurance_term ng-pristine ng-valid ng-touched">
                        <option *ngFor="let order of orderOptions;let i=index" [value]="order['id']"
                          [hidden]="order['value']===professionalType">
                          {{order["value"]}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
      </div>

      <div *ngIf="dataLoading" class="flex flex-row my-6 items-center">
        <div>
          <app-shimmer-loading [loading]="true" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
        </div>
        <div class="sm:ml-0 md:ml-6 mt-0 w-full">
          <ng-container *ngFor="let _ of [].constructor(3)">
            <app-shimmer-loading [loading]="true" [width]="'95%'"></app-shimmer-loading>
          </ng-container>
        </div>
        <div class="mt-0 w-full">
          <app-shimmer-loading [loading]="true" [width]="'95%'" [height]="'4.5rem'"></app-shimmer-loading>
        </div>
      </div>
      <div class="mt-5" *ngIf="!dataLoading">
        <!-- start first Div -->
        <div class="mx-2" *ngIf="totalClients === 0">
          <div class="mobile-scroller">
            <table class="table text-left w-full mt-10" aria-describedby="client">
              <thead class="bg-gray-400 text-blue text-md text-regular">
                <tr>
                  <th class="p-4" scope="row">Name</th>
                  <th class="p-4" scope="row">
                    Digital Vault&nbsp;Status
                  </th>
                  <!-- <th class="p-4" scope="row">{{getAdvisorTerm()}}</th> -->
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="3">
                    <div class="my-8">
                      <h4 class="text-grey-400 text-regular text-center">
                        {{!onSearch ? 'Add/Import clients to access them' : 'No Data Found'}}
                      </h4>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- full view  -->
        <!-- two wrap calc start -->
        <div class="md:flex md:flex-wrap mx-0" *ngIf="totalClients > 0">
          <div class="w-full lg:w-1/2 md:px-2 mb-3 overflow-x-auto">
            <div class="w-full h-auto">
              <table class="table text-left w-full " aria-describedby="advisor">
                <thead class="bg-gray-400 text-blue text-md text-regular">
                  <tr>
                    <th class="p-4" scope="row">Name</th>
                    <th class="p-4" scope="row">
                      Digital Vault ID
                    </th>
                  </tr>
                </thead>
                <tbody class="h-24" *ngFor="let client of clientList; let i = index">
                  <tr class="border-b-2 border-fuchsia-600 relative cursor-pointer" [ngClass]="
                          clientSelected === true && this.index === i
                            ? 'active'
                            : ''
                        " (click)="getClientDetails(client, i)">
                    <td class="p-1 lg:p-4">
                      <div class="flex items-center">
                        <div class="relative w-16 ">
                          <app-image-component *ngIf="client['profile_photo']"
                            [image]="clientImageSrc + client['profile_photo']"
                            [class]="'rounded-full w-10 h-10  object-cover '"
                            [defaultImage]="'assets/icons/user-bg.svg'">
                          </app-image-component>
                          <app-image-component *ngIf="!client['profile_photo']"
                            [class]="'rounded-full w-10 h-10  object-cover '"
                            [defaultImage]="'assets/icons/user-bg.svg'">
                          </app-image-component>
                        </div>

                        <div>
                          <a class="text-blue-600 text-semibold underline cursor-pointer text-base text-regular">{{client['first_name'] }} {{client['middle_name'] }} {{client['last_name'] }}
                          </a>
                          <p class="text-grey text-regular text-base">
                            {{ client['email'] }}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="p-4">
                      <p class="text-grey text-regular text-">{{ client?.['user_request']?.["request_unique_id"] }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pt-8">
                <nav class="flex flex-row flex-nowrap justify-between md:justify-center items-center"
                  aria-label="Pagination">
                  <a *ngIf="currentPage > 1" (click)="getClientList( currentPage > 1 ? currentPage-1 : 0 )"
                    class="flex w-7 h-7 mr-1 justify-center items-center text-black cursor-pointer "
                    title="Previous Page">
                    <span class="sr-only">Previous Page</span>
                    <img src="assets/images/dashboard/left-arrow-enable.png" alt="digital vault" />
                  </a>
                  <a *ngIf="currentPage === 1" class="flex w-7 h-7 mr-1 justify-center items-center text-black "
                    title="Previous Page">
                    <span class="sr-only">Previous Page</span>
                    <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                    <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                  </a>
                  <ng-container *ngFor="let page of paginationLinks;let i = index;">
                    <a *ngIf=" i <= lastPage && i > 0 " (click)="getClientList(i)"
                      [ngClass]="{ 'bg-darkblue' : currentPage === i , 'text-white' : currentPage === i}"
                      class=" md:flex w-7 h-7 mx-1 justify-center items-center  text-black text-center cursor-pointer "
                      title="Page {{i}}">
                      {{i}}
                    </a>
                  </ng-container>
                  <a (click)="getClientList( currentPage + 1 )" *ngIf="(currentPage) <  lastPage"
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black cursor-pointer " title="Next Page">
                    <span class="sr-only">Next Page</span>
                    <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                    <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                  </a>
                  <a *ngIf="(currentPage) ===  lastPage"
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black " title="Next Page">
                    <span class="sr-only">Next Page</span>
                    <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                    <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                  </a>
                </nav>
              </div>
            </div>
          </div>
          <!-- end -->
          <!-- start second div -->
          <div class="w-full lg:w-1/2 md:px-2 mb-3">
            <div class="border-card bg-white rounded-md p-3 md:mx-6  flex flex-col">
              <div class="p-2 mx-2 gap-2">
                <div class="flex flex-row items-center">
                  <div class="relative w-16 h-16">
                    <app-image-component *ngIf="userDetails?.profile_photo"
                      [image]="clientImageSrc + userDetails?.profile_photo" [class]="
                            'rounded-full w-14 h-14 lg:w-16 lg:h-16 object-cover '
                          " [defaultImage]="'assets/icons/user-bg.svg'">
                    </app-image-component>
                    <app-image-component *ngIf="!userDetails?.profile_photo" [class]="
                            'rounded-full w-14 h-14 lg:w-16 lg:h-16 object-cover '
                          " [defaultImage]="'assets/icons/user-bg.svg'">
                    </app-image-component>
                    <!-- <div class=" w-4 h-4 rounded-full bg-green-500 border-2 border-white absolute bottom-2 right-0">
                    </div> -->
                  </div>
                  <div class="pl-4 w-full">
                   <div class="flex justify-between flex-wrap">
                    <a class="text-blue-600 text-semibold underline cursor-pointer text-md lg:text-lg text-regular">{{
                      userDetails?.full_name }}</a>
                       <a *ngIf="isClientDeathVerified" class="text-red-600 text-semibold text-md lg:text-lg text-regular text-right">Reported as deceased</a>

                   </div>
                    <p class="text-grey text-base lg:text-md text-semibold">
                      {{ userDetails?.email }}
                    </p>
                  </div>
                </div>

                <!-- <div class="pl-4 m-0">
                  <button (click)="toggleScheduleClientModal(userDetails)" title="schedule"
                    class="button button--lg bg-darkblue p-2  w-full rounded-xs h-10 focus:outline-none text-regular text-white text-sm flex flex-row justify-center items-center">
                    Schedule Appointment
                  </button>
                </div> -->

              </div>
              <!-- 2nd starart -->
              <hr />
              <!--end-->
              <!--3rd-->
              <app-user-access-enable-list [permissionList]="permissionList" [userDetails]="userDetails"
                [isClientDeathVerified]="isClientDeathVerified"
                [loadingClientDetails]="loadingClientDetails"></app-user-access-enable-list>
              <!-- end -->
              <!-- 4th -->
              <div class="py-4 mx-2" *ngIf=" userDetails?.user_request?.city?.name">
                <div class="flex items-center">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#1e3a8a" class="bi bi-geo-alt"
                      viewBox="0 0 16 16">
                      <path
                        d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg>
                  </div>
                  <div class="pl-2">
                    <p class="text-grey font-400 text-md">Location</p>
                  </div>
                </div>
                <div>
                  <p class="font-700 text-darkblue text-lg text-semibold">
                    {{ userDetails?.user_request?.address | titlecase}} <br>
                    {{ userDetails?.user_request?.city?.name | titlecase}},
                    {{ userDetails?.user_request?.state?.name | titlecase}}
                    {{ userDetails?.user_request?.['zipcode'] }} <br>
                    {{ userDetails?.user_request?.country?.name | titlecase }}
                  </p>
                </div>
              </div>
              <!-- end -->
              <!-- 5th -->
              <div class="py-4 mx-2" *ngIf="userDetails?.phone">
                <div class="flex items-center">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                      class="bi bi-telephone" viewBox="0 0 16 16">
                      <path
                        d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg>
                  </div>
                  <div class="pl-2">
                    <p class="text-grey font-400 text-md">Phone</p>
                  </div>
                </div>
                <div>
                  <p class="font-700 text-darkblue text-lg text-semibold">
                    {{ userDetails?.country_code ? ('+' + userDetails?.country_code) :'' }} {{ userDetails?.phone }}
                  </p>
                </div>
              </div>
              <!-- end -->
              <!-- 5th -->
              <div class="py-3 mx-2">
                <div class="flex items-center">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                      class="bi bi-envelope" viewBox="0 0 16 16">
                      <path
                        d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                    </svg>
                  </div>
                  <div class="pl-2">
                    <p class="text-grey font-400 text-md">Mail ID</p>
                  </div>
                </div>
                <div>
                  <p class="font-700 text-darkblue text-lg text-semibold">
                    {{ userDetails?.email }}
                  </p>
                </div>
              </div>
              <!-- end -->
            </div>
          </div>
          <!-- `end -->
          <!--end-->
        </div>
        <!-- end -->
      </div>
    </div>
  </div>
  <!-- Client section -->
  <div class="pt-6 mx-8 lg:mx-12" *ngIf="roleName === PROFESSIONAL_ROLE.agent" hidden>
    <div class="border-card bg-white rounded-md p-6">

      <!--start-->
      <div class="flex justify-between" id="ClientTable">
        <div>
          <h4 class="text-semibold text-grey text-md md:text-lg lg:text-xl xl:text-2xl">
            Clients
          </h4>
          <p class="text-grey text-regular text-sm md:text-md lg:text-md">
            Client Details
          </p>
        </div>
        <div class="grid grid-cols-3">
          <div class="w-full col-span-2">
            <div class="w-full relative h-10 pr-2 text-grey ">
              <div class=" absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"><img
                  src="assets/images/about-yourself/search.svg" class="w-4  h-4" alt="digital vault"></div>
              <input type="text" class=" input py-3 px-4 w-full text-regular text-grey
                          rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                placeholder="Search by Name" (click)="onSearchClient($event.target['value'])">
            </div>
          </div>
          <button (click)="changeRoute('clients/add-client')"
            class="button button--lg bg-darkblue px-4 rounded-xs h-10 focus:outline-none text-regular text-white text-sm flex flex-row justify-center items-center">
            <img src="assets/images/dashboard/plus-white.svg" class="mr-3" alt="LegacyNow" />
            Add Clients
          </button>
        </div>
      </div>
      <!-- Clients Table -->
      <div *ngIf="dataLoading">
        <div class="mt-3 w-full">
          <app-shimmer-loading [loading]="true" [width]="'100%'" [height]="'3rem'"></app-shimmer-loading>
        </div>
        <div class="flex flex-row mt-3 mb-6 items-center">
          <div>
            <app-shimmer-loading [loading]="true" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
          </div>
          <div class="ml-4 mt-0 w-full">
            <ng-container *ngFor="let _ of [].constructor(3)">
              <app-shimmer-loading [loading]="true" [width]="'100%'"></app-shimmer-loading>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="mt-8" *ngIf="!dataLoading">
        <div class="professional-table overflow-x-auto">
          <table class="table w-full text-left mb-6" aria-describedby="client">
            <thead class="bg-gray-400 text-blue text-md text-regular">
              <tr>
                <th class="p-4" scope="row">Name</th>
                <th class="p-4" scope="row">Digital Vault Status</th>
                <th class="p-4" scope="row">{{getAdvisorTerm()}} Permission</th>
                <th class="p-4" scope="row">Beneficiary</th>
                <th class="p-4" scope="row">Executors</th>
                <th class="p-4" scope="row">Action</th>
              </tr>
            </thead>
            <div class="loader" *ngIf="dataLoading">
              <div class="center-loader w-12 h-12 border-b-2 border-red rounded-full animate-spin mt-3 "></div>
            </div>
            <ng-container *ngIf="!dataLoading">
              <tbody *ngFor="let client of clientList;let i = index">
                <tr class="border-b-2 border-fuchsia-600 p-4 relative" *ngIf="clientList.length > 0">
                  <td class="p-2 lg:p-4">
                    <div class="flex items-center no-wrap">
                      <app-image-component class="w-14 h-14 lg:w-20 lg:h-20"
                        [image]="clientImageSrc+client['profile_photo']"
                        [class]="'rounded-full w-14 h-14 lg:w-16 lg:h-16 object-cover '"
                        [defaultImage]="'assets/icons/user-bg.svg'">
                      </app-image-component>
                      <div class="pl-2 pr-4 lg:p-5 "><a
                          class="text-blue-600 text-semibold underline cursor-pointer text-md lg:text-lg text-regular whitespace-normal">
                          {{client['first_name']}} {{client["last_name"]}} </a>
                        <p class="text-grey text-regular">{{client["email"]}}</p>
                      </div>
                    </div>
                  </td>
                  <td class="p-4">
                    <p class="text-sm lg:text-lg text-regular"
                      [ngClass]="client?.['user_request']?.['is_payment_complete'] === 1 ? 'text-green-500' : 'text-red-500'">
                      {{client?.["user_request"]?.["is_payment_complete"] === 1 ? 'Completed' : 'Incomplete' }}</p>
                  </td>
                  <td class="p-4">
                    <p class="text-green-500 text-sm lg:text-lg text-regular"
                      [ngClass]="{'danger': client?.['user_request']?.['advisor_permission'] === 0}">
                      {{client?.["user_request"]?.["advisor_permission"] === 1 ? 'Yes' : 'No' }}</p>
                  </td>
                  <td class="p-4">
                    <div class="flex items-center ">
                      <img src="assets/images/dashboard/table-user.svg" alt="digital vault">
                      <div class="pl-3">
                        <p class="text-grey text-semibold tracking-wide">
                          {{client?.["user_request"]?.["total_beneficiary"]}}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td class="p-4">
                    <div class="flex items-center">
                      <img src="assets/images/dashboard/table-user.svg" alt="digital vault">
                      <div class="pl-3">
                        <p class="text-grey text-semibold tracking-wide">
                          {{client?.["user_request"]?.["total_executor"]}} </p>
                      </div>
                    </div>
                  </td>
                  <td class="p-4 cursor-pointer m-auto" (click)="toggleDropDown(i)">
                    <img src="assets/images/dashboard/icon-more.png" alt="db">
                  </td>
                  <div class="mt-2 py-1 absolute right-0 top-16 w-60 rounded-md shadow-lg bg-white focus:outline-none"
                    role="menu" aria-orientation="vertical" aria-labelledby="user-menu"
                    *ngIf="profileMenuToggleClient && (this.index === i )">
                    <a class="block px-4 py-2 text-sm text-grey text-regular cursor-pointer hover:bg-gray-100"
                      role="menuitem" (click)="toDLB(ROUTE.OVERVIEW, client)">Client Overview</a>
                    <a (click)="openConfirmationModal(client, 'Client')"
                      class="cursor-pointer block px-4 py-1 text-sm text-grey text-regular hover:bg-gray-100"
                      role="menuitem">Delete
                      Client</a>
                  </div>
                </tr>
              </tbody>
            </ng-container>
          </table>
          <div class="flex justify-center mt-5 bg-card-overview border-card-grey rounded"
            *ngIf="clientList?.length === 0">
            <h4 class="text-base text-regular tracking-wide text-semibold  text-center">
              No Data to Display
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Payment Invoice Section -->
  <div class="pt-6 mx-8 lg:mx-12" *ngIf="showPaymentDetails" id="InvoiceTable">
    <app-professional-invoice-table></app-professional-invoice-table>
  </div>
  <!-- <div class="pt-6 mx-8 lg:mx-12">
    <app-professional-expiry-notification [isDashboardPage]="isDashboardPage"></app-professional-expiry-notification>
  </div> -->
</div>

<!-- View Profile Modal -->
<app-profile-modal></app-profile-modal>
<!-- client link Modal -->
<app-client-link-modal [shortCode]="shortCode">
</app-client-link-modal>
<!-- schedule client Modal -->
<app-schedule-client-modal [clientId]="selectedUserId" [clientVault]="selectedVaultId" [clientName]="selectedUserName">
</app-schedule-client-modal>
<!-- Delete confirmation Modal -->
<app-delete-confirmation-modal [submitLoaderDelete]="submitLoaderDelete" [content]="content"
  (deleteOption)="this.content == 'Client'?deleteUser($event):deleteAgent($event)">
</app-delete-confirmation-modal>
<app-payment-success></app-payment-success>
<!-- Message Modal -->
<app-message-modal [id]="'home-add-card-alert-modal'" [setMessageData]="messageData" (buttonEmitter)="messageModalListener($event)"></app-message-modal>
