import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlaidOnSuccessArgs } from 'ngx-plaid-link';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SECTIONS, SectionStatus } from 'src/constants/application.const';
import { APIResponseModel } from '../../interface/response.interface';
import { AssetsService } from '../../services/assets.service';
import { CommonService } from '../../services/common.service';
import { PersonalDetailsService } from '../../services/personal-details.service';
import { PlaidOptionEmit } from '../../sharedComponent/import-from-plaid-option/import-from-plaid-option.component';


@Component({
  selector: 'app-liabilities',
  templateUrl: './liabilities.component.html',
})
/**
 * Liabilities
 */
export class LiabilitiesComponent implements OnInit {
  public sectionSaveExitOptions: any;
  public userType: string;
  public clientRequestId: string;
  public isLoading: boolean = false;
  public vaultVideo: string;
  public isProfessional:boolean;
  public clientHasPaidSubscription:boolean;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public midPlaid = 'L-plaid-opt';
  private midLiabilities = 'add-liabilities-modal';
  public permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };


  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private commonService: CommonService,
    private assetsService: AssetsService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService,
    private personalDetailsService: PersonalDetailsService,
    private commonHelper: CommonHelper,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.userType = this.localStorageService.getDataByKey('role');
    this.isProfessional = this.commonHelper.isProfessional
    this.clientRequestId = this.commonHelper.getRequestId(this.route);
    if (this.clientRequestId) {
      this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.liabilities, SectionStatus.INCOMPLETE).subscribe();
    }
    this.sectionSaveExitOptions = {
      section: 4,
      type: 1,
    };
    this.modalService.modalVideoObservable.subscribe((video) => this.vaultVideo = video);
  }

  /**
   * Set loading flags
   * @param loading
   */
  public setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  /**
   * open add account modal
   */
  public openAccountModal(): void {
    this.modalService.open(this.midPlaid);
  }

  /**
   * Button click handler
   * @param command
   */
  public click(command: string) {
    const isPro = this.userType !== 'Consumer';
    switch (command) {
      case 'Back':
        const back_url = ['assets', 'retirement-assets'];
        if (isPro) {
          back_url.push(this.clientRequestId);
        }
        void this.slugInterceptorService.navigate(back_url);
        break;
      case 'ReturnToDashboard':
        const return_url = isPro ? ['overview', 'finance-overview'] : ['dashboard'];
        void this.slugInterceptorService.navigate(return_url);
        break;
      case 'Next':
        const url = ['assets','account-card-view'];
        if (isPro) {
          url.push(this.clientRequestId);
        }
        void this.slugInterceptorService.navigate(url);
        break;
    }
  }

  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.modalService.setModalVideo(item);
    this.modalService.open('view-demo-video-modal');

  }

  public onPlaidSuccess($event: PlaidOnSuccessArgs): void {
    if (!$event?.token) return;
    let accountList;
    this.commonService.getLiabilityPlaidAccounts($event?.token, this.userType !== 'Custodian' ? this.clientRequestId : undefined)
      .subscribe({
          next: (response: APIResponseModel) => {
            accountList = response.data?.liability;
            this.modalService.close(this.midPlaid);
            if (!accountList.length) {
              this.toastr.error(`You don't have any Plaid accounts related to Liabilities`);
            }
            this.assetsService.accountListTrigger(accountList.length ? accountList : []);
          },
          error: exception => this.commonHelper.httpResponseHandler(exception?.error),
        },
      );
  }

  plaidOptionListener($event: PlaidOptionEmit) {
    if ($event.plaid?.onSuccess) {
      this.onPlaidSuccess($event.plaid.onSuccess);
    }
    if ($event.manual) {
      this.modalService.open(this.midLiabilities);
    }
  }

  /**
   * Listens for changes in the paid subscription event.
   * @param event The event containing subscription information.
   */
  public paidStatusEventListener(event) {
    if (Object.keys(event).length > 0) {
      this.clientHasPaidSubscription = event['clientHasPaidSubscription'];
    }
  }

  /**
   * Event listener to get permissions and set the canEdit flag.
   * @param {Object} event - The event object containing permissions.
   */
   public getPermissionsEventListener(event) {
    if (Object.keys(event).length > 0) {
      this.permissions = event?.['permissionsList']
    }
  }

  /**
   * Determines whether the button should be disabled
   * @returns A boolean
   */
  public get disableButton(): boolean {
    return this.isProfessional && !this.clientHasPaidSubscription
  }
}
