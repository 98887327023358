import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from '../../services/common-model.service';
import { CommonService } from '../../services/common.service';
import { CommonModalEmitter } from '../common-modal/common-modal.component';


@Component({
  selector: 'app-video-view-popup',
  templateUrl: './video-view-popup.component.html',
  styleUrls: ['./video-view-popup.component.css'],
})

/**
 * Intro Video Component
 */
export class VideoViewPopupComponent {
  @Input() userRole: string;
  @Input() class: string = '';
  @Input() id: string = 'view-video-modal';
  isLoading: boolean;
  private videoSourceURL: string;
  public safeUrl: SafeResourceUrl;


  /**
   * constructor
   */
  constructor(
    private commonService: CommonService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private sanitizer: DomSanitizer,
  ) {
  }


  /**
   * get introVideoURL
   */
  public getIntroVideoURL(): void {
    this.commonService.getIntroVideoURL(this.userRole).subscribe({
      next: (response: APIResponseModel) => {
        this.videoSourceURL = response.data;
        try {
          this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoSourceURL);
          return true;
        } catch (error) {
          console.log('url sanitizer error', error);
          return false;
        }
      }, error: (exception: any) => {
        this.commonHelper.httpResponseHandler(exception?.error);
      },
    });
  }


  /**
   * Close modal
   */
  public closeModal(): void {
    this.modalService.close(this.id);
    this.safeUrl = '';
  }


  /**
   * modal listener
   * @param{CommonModalEmitter} event
   */
  modalListener(event: CommonModalEmitter) {
    if (event.action == 'open' && !this.safeUrl) {
      this.getIntroVideoURL();
    }
  }
}
