import { Component, OnInit } from '@angular/core';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { PARTNER_RELATIONSHIP_STATUS } from 'src/constants/application.const';
import _ from 'lodash';

@Component({
  selector: 'app-name-address',
  templateUrl: './name-address.component.html',
})
/**
 * Name address component
 */
export class NameAddressComponent implements OnInit {
  public userDetails:any;
  // public salutationOptions:Array<SelectOptionsInterface>;
  public relationshipOptions:Array<SelectOptionsInterface>;
  public personalDetailsVisibility:boolean;
  /**
   * @constructor
   */
  constructor(
    private personalDetailsService:PersonalDetailsService,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.personalDetailsVisibility = false;
    this.userDetails = {};

    // Salutation options
    // this.salutationOptions = PEOPLE_SALUTATIONS;

    // Relationship options
    this.relationshipOptions = PARTNER_RELATIONSHIP_STATUS;

    // User details observe
    this.personalDetailsService.personalDetailsObserve.subscribe((userDetails:any)=>{
      if (!userDetails || !Object.values(userDetails).length) return;
      this.userDetails = userDetails;

      this.userDetails.marital_status = _.find(this.relationshipOptions, { value: this.userDetails.marital_status.toString() }).displayText;
    });
  }
}
