<section class="bg-darkblue lg:px-12 py-12">
    <div class="max-w-screen mx-auto overflow-hidden">
        <nav
            class="flex flex-col sm:flex-row sm:flex-wrap gap-4 sm:gap-0 sm:items-center sm:divide-x-2 divide-primary ">
            <div class="sm:px-5 sm:pl-0">
                <a (click)="menuChange('home')" class="cursor-pointer t-base lg:text-lg uppercase leading-6 text-white hover:text-orange-600 hover:underline">
                    About
                </a>
            </div>
            <div class="sm:px-5">
                <a (click)="menuChange('pricing')"
                    class="cursor-pointer font-ave-medium text-base lg:text-lg uppercase leading-6 text-white hover:text-orange-600 hover:underline">
                    Pricing
                </a>
            </div>
            <div class="sm:px-5">
                <a (click)="menuChange('contact-us')"
                    class="cursor-pointer font-ave-medium text-base lg:text-lg uppercase leading-6 text-white hover:text-orange-600 hover:underline">
                    Contact
                </a>
            </div>
            <div class="sm:px-5 md:pr-0">
                <a (click)="menuChange('user-agreement')"
                    class="font-ave-medium text-base lg:text-lg uppercase leading-6 text-white hover:text-orange-600 hover:underline cursor-pointer">
                    Terms
                </a>
            </div>
        </nav>
        <div class="flex flex-col sm:flex-row flex-wrap gap-4 md:gap-8 pt-12">
            <div>
                <div class="flex font-ave-medium text-base lg:text-lg leading-6 text-white gap-2 items-center">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                    stroke-linecap="round" stroke-linejoin="round" class="w-3 h-3 sm:w-5 sm:h-5 flex lg:hidden">
                 <path
                   d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                 </path>
               </svg>
               <span class="hidden lg:flex">CALL:</span>   <a href="tel:+1 {{customerSupportPhone}}" class="font-ave-medium text-white hover:text-orange-600 hover:underline  text-base">
                        +1 {{customerSupportPhone}}
                    </a>
                </div>
            </div>
            <div>
                <div class="flex font-ave-medium text-base lg:text-lg leading-6 text-white gap-2 items-center">
                    <svg viewBox="0 0 24 24"  width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                   stroke-linecap="round" stroke-linejoin="round" class="w-3 h-3 sm:w-5 sm:h-5 flex lg:hidden">
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </svg>
              <span class="hidden lg:flex">EMAIL:</span>   <a href="mailto:{{customerSupportMail}}"  class="font-ave-medium text-white hover:text-orange-600 hover:underline  text-base">
                        {{customerSupportMail}}
                    </a>
                </div>
            </div>
            <div>
                <div class="flex font-ave-medium text-base lg:text-lg leading-6 text-white gap-2">
                    <svg width="24" height="24" viewBox="0 0 1024 1024" fill="#fff" class="icon flex lg:hidden" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#fff" stroke-width="19.456"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M512 1012.8c-253.6 0-511.2-54.4-511.2-158.4 0-92.8 198.4-131.2 283.2-143.2h3.2c12 0 22.4 8.8 24 20.8 0.8 6.4-0.8 12.8-4.8 17.6-4 4.8-9.6 8.8-16 9.6-176.8 25.6-242.4 72-242.4 96 0 44.8 180.8 110.4 463.2 110.4s463.2-65.6 463.2-110.4c0-24-66.4-70.4-244.8-96-6.4-0.8-12-4-16-9.6-4-4.8-5.6-11.2-4.8-17.6 1.6-12 12-20.8 24-20.8h3.2c85.6 12 285.6 50.4 285.6 143.2 0.8 103.2-256 158.4-509.6 158.4z m-16.8-169.6c-12-11.2-288.8-272.8-288.8-529.6 0-168 136.8-304.8 304.8-304.8S816 145.6 816 313.6c0 249.6-276.8 517.6-288.8 528.8l-16 16-16-15.2zM512 56.8c-141.6 0-256.8 115.2-256.8 256.8 0 200.8 196 416 256.8 477.6 61.6-63.2 257.6-282.4 257.6-477.6C768.8 172.8 653.6 56.8 512 56.8z m0 392.8c-80 0-144.8-64.8-144.8-144.8S432 160 512 160c80 0 144.8 64.8 144.8 144.8 0 80-64.8 144.8-144.8 144.8zM512 208c-53.6 0-96.8 43.2-96.8 96.8S458.4 401.6 512 401.6c53.6 0 96.8-43.2 96.8-96.8S564.8 208 512 208z" fill=""></path></g></svg>
                    <span class="hidden lg:flex">ADDRESS:</span>  <a class="font-ave-medium text-white text-base">
                        LegacyNOW <span class="text-primary px-1">|</span> 1002 Justison Street <span class="text-primary px-1">|</span> Ste. 103 <span class="text-primary px-1">|</span> Wilmington, DE <span class="text-primary px-1">|</span> 19801
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
