<!-- LN Digital Vault Agreement (v1.0 110923) -->
<!-- BEGIN: Navbar Wrapper -->
<app-consumer-landing-navbar></app-consumer-landing-navbar>
<!-- END: Navbar Wrapper -->
<main>
  <div class="p-12 lg:px-24 lg:py-32 container mx-auto">
    <div class="title__wrap text-center py-8">
      <h1 class="text-semibold text-accent fs-title pb-2">LegacyNOW</h1>
      <h2 class="text-semibold text-primary fs-title">DIGITAL VAULT AGREEMENT</h2>
    </div>

    <div>
      <p>
        <b><u>PLEASE READ:</u> THIS DIGITAL VAULT AGREEMENT (THIS “AGREEMENT”) CAREFULLY BEFORE USING LEGACYNOW’S
          SOFTWARE AND SERVICES (DEFINED BELOW). BY USING OUR SOFTWARE, YOU ARE AGREEING TO BE BOUND BY THE TERMS OF
          THIS AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT USE THE SOFTWARE,
          THE SERVICES AND/OR SUBMIT ANY INFORMATION ONTO THE WEBSITE, AND IF PRESENTED WITH THE OPTION TO “AGREE” OR
          “DISAGREE” TO THE TERMS, CLICK “DISAGREE”. IF YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS
          AGREEMENT, CLICK “AGREE”. BY CLICKING “AGREE” OR BY DOWNLOADING, USING THE SOFTWARE, THE SERVICES AND/OR
          SUBMITTING ANY INFORMATION ONTO THE WEBSITE, YOU ARE AGREEING TO BE BOUND TO ALL THE TERMS AND CONDITIONS OF
          THIS AGREEMENT.</b>
      </p>
      <p>
        This Digital Vault Agreement (this “Agreement”) is between Estate Resolution Technologies (US) LLC d/b/a
        LegacyNOW (referred to in this Agreement as “LegacyNOW”, “Company, “we”, “us”, or “our”) and you as our customer
        and user of our services (referred to in this Agreement as “Customer”, you”, or “your”) and governs your use and
        access to our digital vault services, software, and website. In return for our digital storage services, you
        agree to pay us the corresponding amounts described on our Website.
      </p>
      <p>
        Our <a class="link" (click)="changeRoute('privacy-policy')">Privacy Policy</a>
        explains how we collect, use and store your digital information and materials while our
        <a class="link"(click)="changeRoute('acceptable-use-policy')">Acceptable Use Policy</a>
        outlines your responsibilities when using our Services. By using our digital vault or other services, you agree
        to be bound by this Agreement, our
        <a class="link" (click)="changeRoute('privacy-policy')">Privacy Policy</a>,
        <a class="link"  (click)="changeRoute('user-agreement')">User Agreement</a>, and
        <a class="link" (click)="changeRoute('acceptable-use-policy')">Acceptable Use Policy</a>.
      </p>

      <h4>
        Background Information
      </h4>
      <p>
        We provide digital vault storage services that securely store your important digital information and documents
        that you submit and store on our Website. The purpose of the submittal and storage of your digital information
        and materials on our Website is primarily to assist and facilitate (i) the management of your financial,
        confidential and other information for estate planning and other services that you may select, and (ii) the
        resolution of your estate by your appointed executor, personal administrator, or other authorized person
        following your death.
      </p>

      <ol class="numbered_section">
        <li #section1 class="numbered_section header">
          <u>Definitions</u>.
          <ol class="numbered_section">
            <li class="numbered_section">
              <u>Defined Terms</u>
              <ul class="list-disc">
                <li>
                  “<b>LegacyNOW</b>”, “<b>Company</b>”, “<b>us</b>”, “<b>we</b>”, “<b>our</b>” or “<b>Company</b>”
                  refers to
                  Estate Resolution Technologies (US) LLC, a/b/a “LegacyNOW”.
                </li>
                <li>
                  “<b>Customer</b>”, “<b>you</b>”, “<b>your</b>”, “<b>yourself</b>” refers to you as an individual, and
                  includes any relative, next-of-kin, heir, beneficiary, executor, administrator, representative, or any
                  other
                  individual you have specifically authorized to have access to your Digital Information, Digital Vault,
                  and
                  use our Services.
                </li>
                <li>
                  “<b>Website</b>” means website we operated operate located at
                  <a class="link" href="." target="_blank">https://www.legacynow.com</a> which we use to provide you
                  with the
                  Digital Vault Services. The term “<u>Website</u>” as used in this Privacy Policy refers solely to the
                  United
                  States version of the Company’s Website and does not include any other websites that are not hosted,
                  owned
                  and/or operated by any of the Company’s domestic or international affiliated companies, including,
                  without
                  limitation any website either owned or operated by the DNS Provider.
                </li>
                <li>
                  “<b>Estate</b>” means your real, personal, digital, or intellectual property which may become subject
                  to
                  inheritance upon your death.
                </li>
                <li>
                  “<b>Digital Information</b>” means any information, document, or materials, in any format capable of
                  being
                  deposited in your Digital Vault pertaining to yourself, your finances, your Estate or any related
                  information that you or any Authorized Representative submit to your Digital Vault on our Website,
                  such as
                  your name, address, phone number, date of birth, social security number, creditor names, locations,
                  and
                  account information, wills, testaments, codicils, and powers of attorney, insurance policies, policy
                  numbers
                  and related information, and any other information or materials regarding yourself, your assets, your
                  Estate
                  or otherwise. You acknowledge and agree that you will only submit and store information, documents, or
                  materials in your Digital Vault that are in accordance with the Company’s
                  <a class="link" (click)="changeRoute('acceptable-use-policy')">Acceptable Use Policy</a>.
                </li>
                <li>
                  “<b>Digital Vault</b>" means the secure vault facility operated by the Company on the Website.
                </li>
                <li>
                  “<b>Deceased Notification Services</b>” refers to the deceased notification registry and services
                  provided
                  by the DNS Provider (which may be an additional and separate service offered by us or any our
                  affiliates).
                </li>
                <li>
                  “<b>DNS Provider</b>” means Estate Resolution Technologies (US) LLC d/b/a NotifyNOW. It is intended
                  that we
                  will also provide the Deceased Notification Services in addition to, but as a separate service, to our
                  Digital Vaults.
                </li>
                <li>
                  “<b>Authorized Representative</b>” means any executor, administrator, vault custodian, financial
                  advisor,
                  estate planning profession, lawyer, accountant, family member or other individual to whom you grant
                  any
                  level of access to the Website, your Digital Vault or your Digital Information.
                </li>
                <li>
                  “<b>Password</b>" means the confidential identification number or other code assigned to you by us or
                  selected by you for identification purposes.
                </li>
              </ul>
            </li>
          </ol>
        </li>

        <li #section2 class="numbered_section header">
          <u>Your Digital Vault</u>.
          <ol class="numbered_section">
            <li #section2_1 class="numbered_section">
              <u>Our Services</u>.
              <p>
                From and after the date of this Agreement and for a period of one (1) year
                (automatically renewed for successive one (1) year periods upon payment of the fees currently posted on
                our
                Website (the initial one (1) year period and all renewals are collectively referred to the
                “<u>Term</u>”).
                During the Term, we will store, maintain and service your Digital Vault and the Digital Information that
                you
                and any of your Authorized Representatives have submitted and deposited in your Digital Vault (our
                “<u>Services</u>”).
              </p>
            </li>
            <li #section2_2 class="numbered_section">
              <u>Access to Your Digital Vault</u>.
              <ol class="list-lower-alpha">
                <li>
                  You will be able to access your Digital Vault via the Website and may add, update, modify or withdraw
                  any
                  Digital Information deposited in your Digital Vault at any time during the Term. All Digital
                  Information
                  that is added, updated, or modified in your Digital Vault will be held under the same terms and
                  conditions
                  of this Agreement. Each time you submit, deposit, add, update, modify or withdraw any Digital
                  Information
                  deposited in your Digital Vault you will receive a notification from us that your action has been
                  completed. All Digital Information deposited in your Digital Vault must be confirmed and recorded by
                  us in
                  a digital information inventory or record that we maintain that is associated with your Digital Vault.
                </li>
                <li>
                  You acknowledge and agree that we are not responsible for any losses as a result of any errors or
                  omissions that you make in connection with any submittal, deposit, addition, modification, update, or
                  withdrawal of Digital Information in your Digital Vault, including, for example, if you mistype any
                  information, online address or otherwise provide incorrect information. You also acknowledge and agree
                  that we are not responsible or liable, if a deposit or other transaction involving Digital Information
                  that you initiate with your Digital Vault via the Website may not be completed, or maybe substantially
                  delayed, by the network used to process the transaction.
                </li>
                <li>
                  You acknowledge and agree that, in addition to the terms and conditions of this Agreement, you must
                  comply
                  with the provisions of the
                  <a class="link" (click)="changeRoute('important-notice-to-digital-vault-holders')">Important Vault
                    Holder
                    Notice</a>,
                  <a class="link" (click)="changeRoute('acceptable-use-policy')">Acceptable Use Policy</a>,
                  <a class="link" (click)="changeRoute('privacy-policy')">Privacy Policy</a>, and
                  <a class="link"(click)="changeRoute('user-agreement')">User Agreement</a> all of which are
                  incorporated in
                  this Agreement.
                </li>
                <li>
                  As provided and described on our Website, you will have the option to appoint Authorized
                  Representatives
                  or other individuals to access, use or submit information to your Digital Vault. You are not required
                  to
                  provide such access but may choose to do so to facilitate the updating of information, the providing
                  of
                  financial, estate planning or other advice, or for any other purpose you may determine to be
                  beneficial.
                  Please note, however, that in doing you further acknowledge and agree that, in addition to the terms
                  and
                  conditions of this Agreement, along with the provisions of the
                  <a class="link"  (click)="changeRoute('important-notice-to-digital-vault-holders')">Important Vault
                    Holder
                    Notice</a>,
                  <a class="link" (click)="changeRoute('acceptable-use-policy')">Acceptable Use Policy</a>,
                  <a class="link" (click)="changeRoute('privacy-policy')">Privacy Policy</a>, and
                  <a class="link"(click)="changeRoute('user-agreement')">User Agreement</a> all of which are
                  incorporated in
                  this Agreement. You may remove, modify, or add any Authorized Representative upon express notification
                  to
                  use as provided in <a class="link" (click)="section9_5.scrollIntoView()">Section 9.5</a> and we will
                  exercise our best
                  commercial efforts to have such change
                  reflected in your account within thirty (30) days of our receipt of notification.
                </li>
                <li>
                  With respect to your Digital Vault, you authorize us (or any of our subcontractors) to perform any
                  routine
                  or necessary actions we deem necessary or prudent to maintain, repair, modify or update any software,
                  program, application, infrastructure, servers, or any other software or hardware used to provide our
                  Services.
                </li>
              </ol>
            </li>
            <li #section2_3 class="numbered_section">
              <u>Unauthorized Use of your Digital Vault</u>.
              <ol class="list-lower-alpha">
                <li>
                  You agree to promptly notify us of any unauthorized access, use, or disclosure of your Password or
                  other
                  credentials, any unauthorized access or use of your (or any) Digital Vault, or any other breach of
                  security. The notification that you provide us should reasonably describe the issue at hand including
                  the
                  date, type of problem, and part of the Digital Vault or Website where you experienced that problem.
                </li>
                <li>
                  You will ensure that you log out of your Digital Vault at the end of each session or time that you
                  access
                  the Website, any Digital Vault, or our Services.
                </li>
                <li>
                  In the interest of security, any access to your Digital Vault by an Authorized Representative to have
                  access may require authentication by us by means we believe, in our sole discretion, adequate to
                  verify
                  the identity of the individual. You agree that we may refuse to process any withdrawal should we fail
                  to
                  authenticate that person’s identity based on our verification procedures.
                </li>
                <li>
                  When using the Website or your Digital Vault, you agree to indemnify us against any losses incurred if
                  your Digital Value becomes inaccessible due to your error such as forgotten passwords, unauthorized
                  third-party access to your Digital Vault Digital Assets, or any unauthorized third-party activities,
                  including, but without limitation to, the use or introduction of computer viruses, or other malware,
                  phishing, spoofing or any other means of attack against you, us, or the Website.
                </li>
              </ol>
            </li>
            <li #section2_4 class="numbered_section">
              <u>Prohibited Activities</u>.
              <ol class="list-lower-alpha">
                <li>
                  You agree that you will not engage in any of the following activities or help a third party in any
                  such
                  activity:
                  <ol class="list-decimal">
                    <li>
                      attempt to gain unauthorized access to the Website or another user’s account;
                    </li>
                    <li>
                      make any attempt to bypass or circumvent any security features;
                    </li>
                    <li>
                      violate any law, statute, ordinance, or regulation;
                    </li>
                    <li>
                      reproduce, duplicate, copy, sell, resell, or rent your Digital Vault or our Services for any
                      purpose;
                    </li>
                    <li>
                      engage in any activity that is abusive or interferes with or disrupts the Website or any of our
                      services;
                    </li>
                    <li>
                      use the Website in connection with any transaction involving illegal products or services is
                      prohibited; and/or
                    </li>
                    <li>
                      violate the terms of the this Agreement,
                      <a class="link" (click)="changeRoute('acceptable-use-policy')">Acceptable Use Policy</a>,
                      <a class="link"(click)="changeRoute('user-agreement')">User Agreement</a>,
                      <a class="link" (click)="changeRoute('privacy-policy')">Privacy Policy</a>, or any other agreement
                      or
                      commitment you may make with us in connection with your use of the Website, our Services, your
                      Digital
                      Vault, or otherwise.
                    </li>
                  </ol>
                <li>
                  We will have the right to suspend your access to your Account in the event of any breach of this
                  Agreement
                  or the matters listed in
                  <a class="link" (click)="section2_4.scrollIntoView()">Section 2.4(a)</a> above.
                </li>
                <li>
                  You shall remain fully responsible for any acts or omissions of any Authorized Representative or other
                  individual you grant access to your Digital Vault and will ensure that that individual complies with
                  the
                  terms of this Agreement and the matters listed in
                  <a class="link" (click)="section2_4.scrollIntoView()">Section 2.4(a)</a> above.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li #section3 class="numbered_section header">
          <u>Data Transfer and Deceased Notification Services</u>.
          <p>
            In the event of your death, you hereby irrevocably authorize, direct, and instruct us to, the event of your
            death, have your Digital Information transferred to the linked account or linked digital asset address with
            the DNS Provider upon our receipt and acceptance of the your proof of death. Upon the transfer of your
            Digital Information to the DNS Provider, will a payment any and all outstanding obligations (e.g. storage
            fees) applicable to your Digital Vault along with a {{ transferFee | currency }} one-time transfer fee to cover the costs and
            expenses associated with the transfer of your information to the DNS Provider. Any outstanding amounts owed
            to us, along with the one-time transfer fee, will be invoiced by us to your estate. Upon completion of the
            transfer, we may terminate your Digital Vault in accordance with Section 5 of this Agreement. If previously
            disclosed to us and designated by you, any or your Authorized Representatives will be notified of the data
            transfer.
          </p>
        </li>

        <li #section4 class="numbered_section header">
          <u>Our Obligations with respect to your Digital Vault</u>.
          <ol class="numbered_section">
            <li class="numbered_section">
              We agree to use our best efforts to keep all Digital Information deposited in your Digital Vault in safe
              custody and securely stored by us on your behalf in an encrypted format. We further agree to use our
              best efforts to keep your Digital Information secure and will not disclose such information to any
              party; except (a) to that provided in <a class="link" (click)="section2_2.scrollIntoView()">Section
                2.2(e)</a>, (b) your Authorized Representative or any other individual to whom you have given express
              authorization to access your Digital Vault, and (c) or as may be required by law or in accordance with
              an order issued by an applicable legal or governmental body.
            </li>
          </ol>
        </li>

        <li #section5 class="numbered_section header">
          <u>Termination</u>.
          <ol class="list-lower-alpha">
            <li>
              This Agreement will commence upon signing by both Parties and will continue to be effective until
              terminated as provided under <a class="link" (click)="section2_1.scrollIntoView()">Section 2.1</a> of this
              Agreement.
            </li>
            <li>
              This Agreement may be terminated by either Party upon 30 days’ written notice to the other Party;
              provided, however, if you terminate this Agreement prior to the expiration of the Term you will be
              required to make the monthly or other payments with respect to the then current Term. Furthermore, we
              reserve the right to suspend, limit the use of, and/or close our Services, including your Digital Vault,
              and immediately this Agreement if you violate any of its terms or conditions.
            </li>
            <li>
              Upon termination of this Agreement, we shall promptly arrange for the withdrawal, removal, and delivery to
              you of any Digital Information from your Digital Vault (such information may also be deleted as per your
              instructions). Any termination of this Agreement will not affect any right or liability arising out of
              events occurring, or Services delivered (e.g., storage and withdrawal fees), prior to the effectiveness of
              the termination.
            </li>
          </ol>
        </li>

        <li #section6 class="numbered_section header">
          <u>Indemnification</u>.
          <ol class="numbered_section">
            <li class="numbered_section">
              <u>By You</u>. You agree to indemnify, defend, and hold us harmless from and against all liabilities,
              damages, and costs (including settlement costs and reasonable attorneys' fees) arising out of any claim
              against us regarding the use of your Digital Vault or other services provided to you in violation of the
              Agreement.
            </li>
            <li class="numbered_section">
              <u>By Us</u>. We agree to indemnify, defend, and hold you harmless from and against all liabilities,
              damages, and costs (including settlement costs and reasonable attorneys' fees) arising out of any claim
              against you to the extent based on an allegation that our technology used to deliver our Services to you
              infringes or misappropriates any copyright, trade secret, U.S. patent, or trademark right of the third
              party. In no event will we have any obligations or liability under this section arising from (a) the use
              of our Services in a modified form or in combination with materials not furnished by us, or in any other
              manner in violation of this Agreement; and (b) with respect to any content, information, or data provided
              by you Customer or any third parties.
            </li>
            <li class="numbered_section">
              <u>Possible Infringement</u>. If we believe that the Services infringe or may be alleged to infringe a
              third party's intellectual property rights, then we may: (a) obtain the right for you at our expense, to
              continue using the Services; (b) provide a non-infringing functionally equivalent replacement; or (c)
              modify the Services so that they no longer infringe. If we do not believe, in our sole discretion, the
              options described in this section are commercially reasonable, then we may suspend or terminate your use
              of the affected Services, with a pro-rata refund of prepaid fees, if any, for the Services.
            </li>
            <li class="numbered_section">
              <u>General</u>. The Party seeking indemnification will promptly notify the other Party of the claim and
              cooperate with the other Party in defending the claim. The indemnifying Party will have full control and
              authority over the defense, except that: (a) any settlement requiring the Party seeking indemnification to
              admit liability requires prior written consent, not to be unreasonably withheld or delayed; and (b) the
              other Party may join in the defense with its own counsel at its own expense. THE INDEMNITIES ABOVE ARE
              YOURS AND OUR ONLY REMEDY UNDER THE AGREEMENT FOR VIOLATION BY THE OTHER PARTY OF A THIRD PARTY'S
              INTELLECTUAL PROPERTY RIGHTS.
            </li>
          </ol>
        </li>

        <li #section7 class="numbered_section header">
          <u>Disclaimers</u>.
          <p class="uppercase">
            THE SERVICES ARE PROVIDED "AS IS." TO THE FULLEST EXTENT PERMITTED BY LAW, EXCEPT AS EXPRESSLY STATED IN THE
            AGREEMENT, NEITHER YOU NOR US OR ANY OF OUR AFFILIATES MAKE ANY WARRANTY OF ANY KIND, WHETHER EXPRESS,
            IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE, OR
            NON-INFRINGEMENT. YOU ARE RESPONSIBLE FOR USING THE SERVICES OR SOFTWARE IN ACCORDANCE WITH THE TERMS SET
            FORTH HEREIN AND BACKING UP ANY STORED DATA ON THE SERVICES.
          </p>
          <p class="uppercase">
            TO THE FULLEST EXTENT PERMITTED BY LAW, EXCEPT FOR YOUR AND OUR INDEMNIFICATION OBLIGATIONS EXPRESSLY
            PROVIDED IN THIS AGREEMENT, NEITHER YOU NOR US OR ANY OF OUR AFFILIATES WILL BE LIABLE UNDER THE AGREEMENT
            FOR (I) INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, (II) LOSS OF USE,
            DATA, BUSINESS, REVENUES, OR PROFITS (IN EACH CASE WHETHER DIRECT OR INDIRECT), OR (III) ANY OTHER LOSS OR
            DAMAGE SUFFERED AS A DIRECT OR INDIRECT RESULT THEREOF, EVEN IF IT WAS KNOWN OR SHOULD HAVE BEEN KNOWN THAT
            SUCH DAMAGES WERE POSSIBLE.
          </p>
          <p class="uppercase">
            TO THE FULLEST EXTENT PERMITTED BY LAW, OUR AGGREGATE LIABILITY UNDER THE AGREEMENT WILL NOT EXCEED THE
            AMOUNT PAID BY YOU TO US HEREUNDER DURING THE TWELVE MONTHS PRIOR TO THE EVENT GIVING RISE TO LIABILITY.
          </p>
        </li>

        <li #section8 class="numbered_section header">
          <u>Disputes</u>.
          <ol class="numbered_section">
            <li class="numbered_section">
              <u>Informal Resolution</u>. Before filing a claim, each Party agrees to try to resolve the dispute by
              contacting the other Party through the notice procedures in this
              <a class="link" (click)="section8.scrollIntoView()">Section 8</a>. If a dispute is not resolved
              within 60 days of notice, either you or we or may bring a formal proceeding.
            </li>
            <li class="numbered_section">
              <u>Arbitration</u>. You agree that we will resolve any claims relating to the Agreement or the Services
              through final and binding arbitration, except as set forth below. The American Arbitration
              Association (AAA) will administer the arbitration under its Commercial Arbitration Rules. The arbitration
              will be held in Wilmington, Delaware, or any other location both Parties agree to in writing.
            </li>
            <li class="numbered_section">
              <u>Exception to Arbitration</u>. Either Party may bring a lawsuit in the federal or state courts of New
              Castle County, Delaware solely for injunctive relief to stop unauthorized use or abuse of the Services or
              infringement of any intellectual property rights without first engaging in the informal dispute notice
              process described above. You consent to venue and personal jurisdiction there.
            </li>
            <li class="numbered_section">
              <u>NO CLASS ACTIONS</u>. You expressly agree to only resolve disputes with us on an individual basis and
              will not bring a claim in a class, consolidated or representative of a class, arbitrations, class actions,
              private attorney general actions and consolidation with other arbitrations are not allowed.
            </li>
          </ol>
        </li>

        <li #section9 class="numbered_section header">
          <u>Miscellaneous</u>.
          <ol class="numbered_section">
            <li #section9_1 class="numbered_section">
              <u>Modification of this Agreement</u>. We may revise this Agreement from time to time and the most current
              version will always be posted on the Website. You are responsible for checking these postings on the
              Website on a regular basis. If a revision, at our sole discretion, is material, we will notify you (by,
              for example, sending an email to the email address associated with the applicable account). By continuing
              to access or use the Website or our Services after revisions become effective, you agree to be bound by
              the revised Agreement. If you do not agree to the revised Agreement terms, you may terminate our Services
              within thirty days of receiving notice of the change.
            </li>
            <li #section9_2 class="numbered_section">
              <u>Policies and other Agreements</u>. All terms provisions and conditions of the
              <a class="link" (click)="changeRoute('acceptable-use-policy')">Acceptable Use Policy</a>,
              <a class="link"(click)="changeRoute('user-agreement')">User Agreement</a>,
              <a class="link" (click)="changeRoute('privacy-policy')">Privacy Policy</a>, or any other agreement or
              commitment you may make during your use of the Website, our Services, your Digital Vault, or otherwise
              with use in connection therewith is hereby incorporated into this Agreement by reference.
            </li>
            <li #section9_3 class="numbered_section">
              <u>Governing Law</u>. This Agreement shall be governed by and construed in accordance with the laws of the
              State of Delaware and the federal laws of the United States (regardless of that jurisdiction or any other
              jurisdiction's choice of law principles). To the extent permitted by law and subject to that provided in
              <a class="link" (click)="section8.scrollIntoView()">Section 8</a>, the Parties agree that all actions or
              proceedings arising in connection with this Agreement or the Services. shall be litigated in the state or
              federal courts located in Wilmington, Delaware.
            </li>
            <li #section9_4 class="numbered_section">
              <u>Entire Agreement</u>. The Agreement supersedes any prior agreements or understandings between us and
              constitutes the entire agreement between us related to this subject matter.
            </li>
            <li #section9_5 class="numbered_section">
              <u>Severability</u>. Unenforceable provisions will be modified to reflect the Parties' intention and only
              to the extent necessary to make them enforceable, and the remaining provisions of the Agreement will
              remain in full effect.
            </li>
            <li #section9_6 class="numbered_section">
              <u>Notice</u>. Notices must be sent via email, first-class, airmail, or overnight courier and are deemed
              given when received. Notices to you may also be sent to the applicable account email address and are
              deemed given when sent. Notices us must be sent to us at
              <a href="mailto:{{customerSupportMail}}">support@legacynow.com</a>, LegacyNOW, 1002 Justison Street, Suite
              103, Wilmington, DE 19801.
            </li>
            <li #section9_7 class="numbered_section">
              <u>Waiver</u>. A waiver of any default is not a waiver of any subsequent default.
            </li>
            <li #section9_8 class="numbered_section">
              <u>Assignment</u>. You may not assign or transfer the Agreement or any rights or obligations under the
              Agreement. We cannot assign the Agreement without providing you notice; however, we may assign the
              Agreement or any rights or obligations under the Agreement to an affiliated company or in connection with
              a merger, acquisition, corporate reorganization, or sale of all or substantially all of its assets without
              providing notice. Any other attempt to transfer or assign is void.
            </li>
            <li #section9_9 class="numbered_section">
              <u>No Agency</u>. You and the Company are not legal partners or agents but are independent contractors.
            </li>
            <li #section9_10 class="numbered_section">
              <u>Subcontracting</u>. In addition to that provided in
              <a class="link" (click)="section2_2.scrollIntoView()">Section 2.2(e)</a>, you also agree and consent to
              our appointment of subcontractors and third-party service providers to perform the Services.
            </li>
            <li #section9_11 class="numbered_section">
              <u>Force Majeure</u>. Except for payment obligations, neither you or the Company will be liable for
              inadequate performance to the extent caused by a condition that was beyond the Party's reasonable control
              (for example, natural disaster, an act of war or terrorism, riot, labor condition, governmental action,
              and Internet disturbance).
            </li>
            <li #section9_12 class="numbered_section">
              <u>No Third-Party Beneficiaries</u>. There are no third-party beneficiaries to this Agreement.
            </li>
          </ol>
        </li>

      </ol>
    </div>
  </div>

  <p class="mx-4">
    Last Updated: December 21, 2023
  </p>
</main>

<app-consumer-landing-footer></app-consumer-landing-footer>
