<app-common-modal id="add-donation-modal" class="app-common-modal" [heightClass]="'min-h-75'">
  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
         (click)="closeModel()">
    <form [formGroup]="donationForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Donation Details
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">
            Add your Donation details here
          </p>
          <p class="text-regular font-medium text-xs mt-2 error-alert"
             *ngIf="(isNewAsset && !permissions?.['add']) || (!isNewAsset && !permissions?.['edit'])">
            You do not have permission to
            {{isNewAsset ? 'add information for this client.' : 'edit this client\'s information.'}}
          </p>
        </div>

        <div class="col-span-12">
          <label for="donation-type" class="block text-label-grey text-sm text-regular">Type of Donation*</label>
        </div>
        <div class="col-span-8 flex items-baseline justify-between -mt-3">
          <div *ngFor="let type of donationTypes;let i = index">
            <input type="radio" class="mt-2" [id]="type.value" [value]="type.value" formControlName="donation_type"
                   id="donation-type" [attr.disabled]="!canEdit || undefined" (input)="donationTypeChange(type.value)">
            <label [for]="type.value"
                   class="text-sm rounded-xs appearance-none pl-2 z-20 align-text-bottom leading-5 focus:outline-none text-gray-600">
              {{type?.['displayText']}}</label>
          </div>
        </div>
        <div class="col-span-12">
          <label for="organization-name" class="text-label-grey text-sm text-regular">Name of Organization*</label>
          <input [attr.disabled]="!canEdit || undefined" type="text" minlength="3" maxlength="40" id="organization-name"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 placeholder="e.g. Red Cross" formControlName="organization_name" name="organization-name"
                 [ngClass]="formGet.organization_name.errors && formGet.organization_name.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('organization_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="phone" class="text-label-grey text-sm text-regular">Phone Number*</label>
          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
            <app-phone-input [placeholder]="'e.g. 8665044754'" [phoneNumber]="donationForm.value.phone"
                             [countryCode]="countryCode" [uniqueId]="'phone-donation'"
                             [disabled]="!canEdit || !canAccessForm"
                             (onChange)="detectChangePhonenumber($event)"
                             [form]="this.donationForm"
                             [errorClassTrigger]="formGet.phone.errors && formGet.phone.touched"
                             id="phone"
                             (focusout)="formGet.phone.markAsTouched()">
            </app-phone-input>
          </app-shimmer-loading>
          <p *ngFor="let error of getErrors('phone')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label [for]="'date_of_donation'" class="text-label-grey text-sm text-regular">
            Date of Donation ( {{domainDateFormat}} )
          </label>
          <app-datepicker-toggle
            inputID="date_of_donation"
            [bsConfig]="bsConfig"
            [disabled]="!canEdit"
            [useFormControl]="formGet.date_of_donation">
          </app-datepicker-toggle>
          <p *ngFor="let error of getErrors('date_of_donation')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 sm:col-span-6">
          <label for="price" class="text-label-grey text-sm text-regular">Amount</label>
          <div class="relative flex items-center">
            <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
            <input [attr.disabled]="!canEdit || undefined" type="text" id="price" appCurrencyInput
                   class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 price-input"
                   name="vehicle-value" formControlName="amount" placeholder="e.g 1000"
                   [ngClass]="formGet.amount.errors && formGet.amount.touched?'form-input-error':'form-input-focus'">
          </div>
          <p *ngFor="let error of getErrors('amount')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 relative" *ngIf="isFrequentDonation">
          <label for="frequency_id" class="text-label-grey text-sm text-regular">Frequency</label>
          <app-select-with-search id="frequency_id" [options]="frequencyTypeArray"
                                  [placeholderValue]="'Select Frequency Type'" [disabled]="!canEdit || !canAccessForm"
                                  (optionChangeEmitter)="optionChangeEmitterFrequencyType($event)"
                                  [defaultSelected]="frequencyTypeId">
          </app-select-with-search>
        </div>
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Address
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">Make sure to enter Organization's address
          </p>
        </div>
        <div class="col-span-12">
          <label for="country" class=" text-label-grey text-sm text-regular ">Country*</label>
          <div class=" col-span-12 lg:col-span-2 relative">
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <app-country-search-component (optionChangeEmitter)="optionChangeListenerCountry($event)"
                                            [defaultSelected]="formGet?.country?.value" id="country"
                                            [disabled]="!canEdit || !canAccessForm"
                                            [enableError]="formGet.country.errors && formGet.country.touched"
                                            (focusout)="markAsTouchedIndividual('country')"></app-country-search-component>
            </app-shimmer-loading>
          </div>
        </div>
        <div class="col-span-12 relative move-map" #moveMapHere>
          <label for="street-add" class="text-label-grey text-sm text-regular">Street
            Address*</label>
          <input [attr.disabled]="!canEdit || undefined" type="text" minlength="3" maxlength="500" id="street-add"
                 #search (keyup)="onAddressChange(search)"
                 (input)="onFocusAddress()"
                 (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="address" formControlName="address" placeholder="e.g Main St"
                 [ngClass]="formGet.address.errors && formGet.address.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('address')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="apt_number" class="text-label-grey text-sm text-regular">Apartment / Suite #</label>
          <input name="apt_number" id="apt_number" formControlName="apt_number" [attr.disabled]="!canEdit || undefined"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 [ngClass]="formGet.apt_number.errors && formGet.apt_number.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('apt_number')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12" *ngIf="selectedCountryName!==unitedKingdom">
          <label for="state-Province" class="text-label-grey text-sm text-regular">State/Province*</label>
          <div class="col-span-12 lg:col-span-2 relative">
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <app-state-search-component [enableError]="formGet.state.errors && formGet.state.touched"
                                          [countryCode]="formGet?.country?.value" id="state-Province"
                                          [defaultSelected]="formGet?.state?.value"
                                          (optionChangeEmitter)="optionChangeListenerState($event)" tabindex="0"
                                          [disabled]="!canEdit || !canAccessForm"
                                          (focusout)="markAsTouchedIndividual('state')">
              </app-state-search-component>
            </app-shimmer-loading>
            <p *ngFor="let error of getErrors('state')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <label for="city" class="text-label-grey text-sm text-regular">City*</label>
          <div class="col-span-12 lg:col-span-2 relative">
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <app-city-search [stateCode]="formGet?.state?.value" id="city"
                               [enableError]="formGet.city.errors && formGet.city.touched"
                               [defaultSelected]="formGet?.city?.value"
                               (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0"
                               [disabled]="!canEdit || !canAccessForm"
                               (focusout)="markAsTouchedIndividual('city')">
              </app-city-search>
            </app-shimmer-loading>
            <p *ngFor="let error of getErrors('city')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </div>
        <div class=" col-span-12 md:col-span-6 ">
          <label for="zipcode" class="text-label-grey text-sm text-regular">Zip/Postal Code*</label>
          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
            <input [attr.disabled]="!canEdit || undefined" type="text" id="zipcode"
                   class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
                   placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                   name="zipcode" formControlName="zipcode" oninput="this.value = this.value.toUpperCase()"
                   (focus)="validateZipCode()"
                   [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
          </app-shimmer-loading>
          <p *ngFor="let error of getErrors('zipcode')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12">
            <div class="flex flex-row items-baseline">
              <div>
                <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                  Web Access
                </h4>
                <p class="text-grey text-regular pt-1 text-sm">
                  Online access information for this donation.
                </p>
              </div>
            </div>
          </div>
          <div class="col-span-12">
            <label for="website" class="text-label-grey text-sm text-regular">Web URL</label>
            <div class="relative">
              <input type="url" [attr.disabled]="!canEdit || undefined"
                     class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                     placeholder="www.mybank.com" formControlName="website" id="website"
                     [ngClass]="formGet.website.errors && formGet.website.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('website')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
          </div>
          <div class="col-span-12 md:col-span-6">
            <label for="user-name" class="text-label-grey text-sm text-regular">Username</label>
            <input [attr.disabled]="!canEdit || undefined" type="text" id="user-name"
                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                   placeholder="e.g. Marktylor_54" formControlName="user_name" name="user_name"
                   [ngClass]="formGet?.['user_name'].errors && formGet?.['user_name'].touched?'form-input-error':'form-input-focus'">
            <p *ngFor="let error of getErrors('user_name')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <div class="col-span-12 md:col-span-6">
            <label for="password" class="text-label-grey text-sm text-regular">Password</label>
            <div class="relative">
              <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
                 (click)="togglePasswordView = !togglePasswordView">
                <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
                <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
              </a>
              <input [type]="togglePasswordView?'text':'password'" id="password" [attr.disabled]="!canEdit || undefined"
                     class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                     placeholder="e.g. JOHy5689" formControlName="password" name="password"
                     [ngClass]="formGet.password.errors && formGet.password.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('password')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
          </div>
          </div>
        </div>

      </div>

      <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
        <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewAsset" [buttonText]="'Remove Donation'"
                           [valid]="!submitLoade &&canEdit"
                           [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true"
                           (click)="deleteDonation()">
        </app-button-loader>
        <app-button-loader class="w-full sm:w-auto" [buttonText]="!isNewAsset?'Update Donation':'Add Donation'"
                           [valid]="((isNewAsset && permissions?.['add']) || (!isNewAsset && permissions?.['edit'])) && !submitLoaderDelete && canEdit"
                           [loader]="submitLoader" [buttonType]="'submit'">
        </app-button-loader>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>
