import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { SlugService } from 'src/app/services/slug.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-consumer-focused-navbar',
  templateUrl: './consumer-focused-navbar.component.html',
  styleUrls: ['./consumer-focused-navbar.component.css'],
})
export class ConsumerFocusedNavbarComponent implements OnInit {
  public responsiveMenuToggle: boolean;
  public customerSupportMail: string;
  public customerSupportPhone: number;
  public orgLogo: string;
  public orgLogoPath: string;
  public videoModalID: string = 'consumer-navbar-video';

  constructor(
    private slugInterceptorService: SlugInterceptorService,
    private route: ActivatedRoute,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private commonHelper: CommonHelper,
    private slugService: SlugService,
  ) { }

  ngOnInit(): void {
    this.getContactDetails();
    this.responsiveMenuToggle = false;
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;

    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      } else {
        this.orgLogoPath = '';
        this.orgLogo = '';
      }
    });
  }

  /**
    * @param{string}url
    * on menu change
    */
  public menuChange(url?: string) {
    if (url) {
      void this.slugInterceptorService.navigate([url]);
      this.commonService.setActiveNavbarTrigger(url);
    }
  }
  /**
   * Display intro video modal
   */
  public viewIntroVideo() {
    this.modalService.open(this.videoModalID);
  }


 /**
 * Retrieves contact details
 */
  public getContactDetails() {
   this.commonService.getContactUsDetails().subscribe((response) => {
     this.customerSupportMail = response.data.support_email;
     this.customerSupportPhone = response.data.support_number;
   })
 }
}
