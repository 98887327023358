import { Component, OnInit } from '@angular/core';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { ClientsService } from 'src/app/professional-dashboard/services/clients.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-document-search-modal',
  templateUrl: './document-search-modal.component.html',
})
/**
 * document search modal
 */
export class DocumentSearchModalComponent implements OnInit {
  public documentList:Array<any>;
  public preDefinedLoader:boolean;
  public imageFormatFiltering:Array<string>;
  public downloadLink:string;
  public downloadFileName:string;
  public requestId:string;
  public PIC_URL:string;
  public searchKeyword:string ;
  public spinner:boolean;
  /**
 * constructor
 */
  constructor(private modalService:CommonModelService,
    private clientsService:ClientsService,
    private commonService:CommonService,

  ) { }
  /**
 * loaded initially
 */
  ngOnInit(): void {
    this.searchKeyword='';
    this.imageFormatFiltering = ['jpeg', 'jpg', 'png'];
  }


  /**
   * close modal
   */
  public closeModel(): void {
    this.searchKeyword='';
    this.modalService.close('open-search-modal');
  }

  /**
   * search client
   * @param {string}keyword
   */
  public searchDocument(keyword:string) {
    this.searchKeyword = keyword;
    this.spinner=true;
    if (!keyword ) {
      this.spinner=false;
      this.documentList=[];
      return;
    } else {
      const payload = { search: this.searchKeyword };
      this.clientsService.getDocumentBySearch(payload).subscribe((response:APIResponseModel)=>{
        this.documentList=response.data;
        this.spinner=false;
      });
    }
  }
  /**
   * Delete digital files uploaded based on name
   *
   * @param {string} id
   * @param {number} index
   */

  /**
   * View image popup
   *
   * @param {string} image
   * @param {string} requestId
   */
  public viewImage(image:string, requestId:string):void {
    this.PIC_URL = `${environment.BASE_URL_RAW}uploads/${requestId}${image}`;
;
    this.commonService.setImageViewPopupTrigger({imageSrc:this.PIC_URL});
    this.modalService.open('view-image-modal');
    this.modalService.close('open-search-modal');
  }


  /**
   * Download file
   *
   * @param {any} file
   * @param {string} requestId
   */
  public downLoadFile(file:any, requestId:string):void {
    this.downloadLink = `${environment.BASE_URL_RAW}uploads/${requestId}${file?.name}`;

    this.downloadFileName = file?.file_name;
    this.modalService.open('file-download-modal');
  }
}
