import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {CommonHelper} from 'src/app/helper/common.helper';
import {SlugInterceptorService} from 'src/app/helper/slug-interceptor.service';
import {APIResponseModel} from 'src/app/interface/response.interface';
import {LocalStorageService} from 'src/app/services/local-storage.service';
import {SlugService} from 'src/app/services/slug.service';
import {UserService} from 'src/app/services/user.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-resend-email',
  templateUrl: './resend-email.component.html',
})
/**
 * Resend email component
 */
export class ResendEmailComponent implements OnInit {
  public resendOtpTimer: number;
  public orgLogo:string;
  public orgLogoPath:string;
  /**
   * @constructor
   */
  constructor(
    private userService:UserService,
    private commonHelper:CommonHelper,
    private localStorageService:LocalStorageService,
    private toastrService: ToastrService,
    private slugService:SlugService,
    private slugInterceptorService:SlugInterceptorService,

  ) { }

  /**
 *
 */
  public ngOnInit(): void {
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      } else {
        this.orgLogoPath = '';
        this.orgLogo = '';
      }
    });
  }


  /**
   * start resend timer
   */
  public startResendTimer():void {
    // resend otp timer
    this.resendOtpTimer = 30;
    const resendInterval = setInterval(()=>{
      this.resendOtpTimer -= 1;
      if (!this.resendOtpTimer) {
        clearInterval(resendInterval);
      }
    }, 1000);
  }

  /**
   * resend email
   */
  public resendEmail():void {
    this.startResendTimer();
    const email = this.localStorageService.getUserData()?.user?.email;
    this.userService.resendEmail({ email }).subscribe((response:APIResponseModel)=>{
      if (response.status) {
        this.toastrService.success(response.message);
      }
    }, (exception)=>{
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
   * on click logo
   */
  public onClickLogo() {
    void this.slugInterceptorService.navigate(['/']);
  }
}
