<main class="relative">
  <!-- BEGIN: Navbar Wrapper -->
  <app-nav-landing-pro></app-nav-landing-pro>
  <!-- END: Navbar Wrapper -->
  

  <div class="p-12 lg:px-24 lg:py-32 container mx-auto">
    <div class="title__wrap text-center py-8">
      <h1 class="text-semibold text-accent fs-title pb-2">LegacyNOW, Inc.</h1>
      <h2 class="text-semibold text-primary fs-title">Acceptable Use Policy</h2>
    </div>
    <div>

      <p class="text-content text-regular fs-content">
        You agree not to misuse the Digital Vault services ("Services") or help anyone else to do so. For example, you
        must not even try to do any of the following in connection with the Services:</p>

      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">Probe, scan, or test the vulnerability of any system or
            network relating to the Services or the Website;</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Breach or otherwise circumvent any security or
            authentication measures to access or use the Services or the Website;
          <p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Access, tamper with, or use non-public areas or parts of
            the Services or Website, or shared areas of the Services or Website you haven't been authorized to;
          <p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Submit altered, deceptive or false source-identifying
            information to your Vault, including "spoofing" or "phishing";
          <p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Circumvent storage space limits;
          <p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Sell the Services unless specifically authorized to do so
            [permit anyone else to use your vault]
          <p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Submit or store materials that constitute child sexually
            exploitative material (including material which may not be illegal child sexual abuse material but which
            nonetheless sexually exploits or promotes the sexual exploitation
            of minors), unlawful pornography, or are otherwise indecent;
          <p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Submit or store content that contains or promotes extreme
            acts of violence or terrorist activity, including terror propaganda;
          <p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Submit any information or materials that advocate bigotry
            or hatred against any person or group of people based on their race, religion, ethnicity, sex, gender
            identity, sexual orientation, disability, or impairment;
          <p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Violate the law in any way, including storing, publishing
            or sharing material that’s fraudulent, defamatory, or misleading, or that violates the intellectual property
            rights of others; or
          <p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Violate the privacy or infringe the rights of others,
            including, without limitation, the privacy or rights of other subscribers to the Service.
          <p>
        </li>

      </ul>


      <p class="text-content text-regular fs-content">

        We reserve the right to take appropriate action in response to violations of this policy, which could include
        removing or disabling access to content, suspending a user’s access to the Services, or terminating an account.
      </p>
    </div>
  </div>

</main>
