<div class="pt-6 mx-8 lg:mx-12">
    <div class="border-card bg-white rounded-md p-6 ">
      <div class="pt-8">
        <div class="flex flex-wrap mx-2">
          <div class="w-full md:w-2/5">
            <div class="mb-3">
              <h4 class="text-semibold text-grey text-md md:text-lg lg:text-xl xl:text-2xl">
                Professionals
              </h4>
              <p class="text-grey text-regular text-sm md:text-md lg:text-md">
                Professional Details
              </p>
            </div>
            <div>
              <div class="sm:w-full md:w-full mr-4  relative h-10 text-grey ">
                <div class=" absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"><img
                    src=" assets/images/about-yourself/search.svg " class="w-4  h-4" alt="digital vault"></div>
                <input type="text"
                  class=" input py-3 px-4 mt-1 w-full text-regular text-grey rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                  placeholder="Search by Name" (input)="onSearchClient($event.target['value'])">
              </div>
            </div>
          </div>
          <div class="w-full lg:w-3/5 md:px-2 flex flex-wrap items-center lg:justify-end md:flex-wrap justify-start">
            <div class="mb-2 mr-2">
                <button (click)="changeRoute('professional-management/add')"
                  class="button button--lg bg-darkblue px-5 w-full rounded-xs h-10 focus:outline-none text-regular text-white text-sm flex flex-row justify-center items-center">
                  <img src="assets/images/dashboard/plus-white.svg" class="mr-3" alt="LegacyNow" />
                  Add Professional
                </button>
              </div>
            <div class="mr-2 mb-2">
              <button (click)="toggleBulkSendInvite()"
                class="button button--lg bg-darkblue px-4  w-full rounded-xs h-10 focus:outline-none text-regular text-white text-sm flex flex-row justify-center items-center">
                <img src="assets/images/dashboard/file-plus-white.svg" class="mr-3" alt="LegacyNow" />
                Import Professionals
              </button>
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="dataLoading" class="flex flex-row my-6 items-center">
        <div>
          <app-shimmer-loading [loading]="true" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
        </div>
        <div class="sm:ml-0 md:ml-6 mt-0 w-full">
          <ng-container *ngFor="let _ of [].constructor(3)">
            <app-shimmer-loading [loading]="true" [width]="'95%'"></app-shimmer-loading>
          </ng-container>
        </div>
        <div class="mt-0 w-full">
          <app-shimmer-loading [loading]="true" [width]="'95%'" [height]="'4.5rem'"></app-shimmer-loading>
        </div>
      </div>
      <div class="mt-5" *ngIf="!dataLoading">
        <!-- start first Div -->
        <div class="mx-2" *ngIf="agentsList?.length == 0">
          <div class="mobile-scroller">
            <table class="table text-left w-full " aria-describedby="client">
              <thead class="bg-gray-400 text-blue text-md text-regular">
                <tr>
                  <th class="p-4" scope="row">Name</th>
                  <th class="p-4" scope="row">LegacyNOW ID</th>
                  <th class="p-4" scope="row">Title</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="3">
                    <div class="my-8">
                      <h4 class="text-grey-400 text-regular text-center">
                        No professionals found
                      </h4>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- full view  -->
        <!-- two wrap calc start -->
        <div class="md:flex md:flex-wrap mx-0" *ngIf="agentsList?.length > 0">
          <div class="w-full  md:px-2 mb-3 overflow-x-auto" [ngClass]="{'sm:w-1/2': userDetails}">
            <div class="w-full h-auto">
              <table class="table text-left w-full" aria-describedby="advisor">
                <thead class="bg-gray-400 text-blue text-md text-regular">
                  <tr>
                    <th class="p-4" scope="row">Name</th>
                    <th class="p-4" scope="row">Manager</th>
                    <th class="p-4" scope="row">Title</th>
                  </tr>
                </thead>
                <tbody class="h-24" *ngFor="let client of agentsList; let i = index">
                  <tr class="border-b-2 border-fuchsia-600 relative cursor-pointer" [ngClass]="
                  client?.['id'] === userDetails?.['id']
                            ? 'active'
                            : ''" (click)="selectProfessional(client)">
                    <td class="p-1 lg:p-4">
                      <div class="flex items-center">
                        <div class="relative w-16 ">
                          <app-image-component *ngIf="client['profile_picture']"
                          [image]="clientImageSrc + client['profile_picture']"
                            [class]="'rounded-full w-10 h-10  object-cover '"
                            [defaultImage]="'assets/icons/user-bg.svg'">
                          </app-image-component>
                        </div>

                        <div>
                            <a class="text-blue-600 text-semibold underline cursor-pointer text-base text-regular">{{
                              client['full_name'] | titlecase}}
                            </a>
                            <p class="text-grey text-regular text-base">
                              {{ client['email'] }}
                            </p>
                          </div>
                      </div>
                    </td>
                    <td class="p-4">
                      <p class="text-red-500 text-base text-regular">
                        {{ client['manager_details']?.full_name | titlecase}}
                      </p>
                    </td>
                    <td class="p-4 whitespace-normal">
                      <a class="text-blue-600 text-semibold text-sm text-xs  md:text-sm cursor-pointer">
                        {{client['manager_term'] | titlecase}}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pt-8">
                <nav class="flex flex-row flex-nowrap justify-between md:justify-center items-center"
                  aria-label="Pagination">
                  <a *ngIf="currentPage > 1" (click)="getAgentsList( currentPage > 1 ? currentPage-1 : 0 )"
                    class="flex w-7 h-7 mr-1 justify-center items-center text-black cursor-pointer "
                    title="Previous Page">
                    <span class="sr-only">Previous Page</span>
                    <img src="assets/images/dashboard/left-arrow-enable.png" alt="digital vault" />
                  </a>
                  <a *ngIf="currentPage === 1" class="flex w-7 h-7 mr-1 justify-center items-center text-black "
                    title="Previous Page">
                    <span class="sr-only">Previous Page</span>
                    <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                    <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                  </a>
                  <ng-container *ngFor="let page of paginationLinks;let i = index;">
                    <a *ngIf=" i <= lastPage && i > 0 " (click)="getAgentsList(i)"
                      [ngClass]="{ 'bg-darkblue' : currentPage === i , 'text-white' : currentPage === i}"
                      class=" md:flex w-7 h-7 mx-1 justify-center items-center  text-black text-center cursor-pointer "
                      title="Page {{i}}">
                      {{i}}
                    </a>
                  </ng-container>
                  <a (click)="getAgentsList( currentPage + 1 )" *ngIf="(currentPage) <  lastPage"
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black cursor-pointer " title="Next Page">
                    <span class="sr-only">Next Page</span>
                    <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                    <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                  </a>
                  <a *ngIf="(currentPage) ===  lastPage"
                    class="flex w-7 h-7 ml-1 justify-center items-center text-black " title="Next Page">
                    <span class="sr-only">Next Page</span>
                    <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                    <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                  </a>
                </nav>
              </div>
            </div>
          </div>
          <!-- end -->
          <!-- start second div -->
          <div class="w-full sm:w-1/2 md:px-2 mb-3" *ngIf="userDetails">
            <div class="border-card bg-white rounded-md p-3 md:mx-6  flex flex-col">
              <div class="p-2 mx-2 grid grid-cols-3 gap-2">
                <div class="flex flex-row col-span-3 md:col-span-2 lg:col-span-2">
                  <div class="relative w-16 h-16">
                    <app-image-component *ngIf="userDetails?.profile_picture"
                      [image]="clientImageSrc + userDetails?.profile_picture" [class]="
                            'rounded-full w-14 h-14 lg:w-16 lg:h-16 object-cover '
                          " [defaultImage]="'assets/icons/user-bg.svg'">
                    </app-image-component>
                    <!-- <div class=" w-4 h-4 rounded-full bg-green-500 border-2 border-white absolute bottom-2 right-0">
                    </div> -->
                  </div>
                  <div class="pl-4">
                    <a class="text-blue-600 text-semibold underline cursor-pointer text-md lg:text-lg text-regular">{{
                      userDetails?.full_name }}</a>
                    <p class="text-grey text-base lg:text-md text-semibold">
                      {{ userDetails?.email }}
                    </p>
                  </div>
                </div>

              </div>
              <!-- 2nd starart -->
              <hr />
              <!--end-->
              <!-- 4th -->
              <div class="py-4 mx-2" *ngIf=" userDetails?.user_request?.city?.name">
                <div class="flex items-center">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#1e3a8a" class="bi bi-geo-alt"
                      viewBox="0 0 16 16">
                      <path
                        d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg>
                  </div>
                  <div class="pl-2">
                    <p class="text-grey font-400 text-md">Location</p>
                  </div>
                </div>
                <div>
                  <p class="font-700 text-darkblue text-lg text-semibold">
                    {{ userDetails?.user_request?.address | titlecase}} <br>
                    {{ userDetails?.user_request?.city?.name | titlecase}},
                    {{ userDetails?.user_request?.state?.name | titlecase}}
                    {{ userDetails?.user_request?.['zipcode'] }} <br>
                    {{ userDetails?.user_request?.country?.name | titlecase }}
                  </p>
                </div>
              </div>
              <!-- end -->
              <!-- 5th -->
              <div class="py-4 mx-2" *ngIf="userDetails?.phone">
                <div class="flex items-center">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                      class="bi bi-telephone" viewBox="0 0 16 16">
                      <path
                        d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg>
                  </div>
                  <div class="pl-2">
                    <p class="text-grey font-400 text-md">Phone</p>
                  </div>
                </div>
                <div>
                  <p class="font-700 text-darkblue text-lg text-semibold">
                    {{ userDetails?.country_code ? ('+' + userDetails?.country_code) :'' }} {{ userDetails?.phone }}
                  </p>
                </div>
              </div>
              <!-- end -->
              <!-- 5th -->
              <div class="py-3 mx-2">
                <div class="flex items-center">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                      class="bi bi-envelope" viewBox="0 0 16 16">
                      <path
                        d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                    </svg>
                  </div>
                  <div class="pl-2">
                    <p class="text-grey font-400 text-md">Mail ID</p>
                  </div>
                </div>
                <div>
                  <p class="font-700 text-darkblue text-lg text-semibold">
                    {{ userDetails?.email }}
                  </p>
                </div>
              </div>
              <!-- end -->
            </div>
          </div>
          <!-- `end -->
          <!--end-->
        </div>
        <!-- end -->
      </div>
    </div>
  </div>