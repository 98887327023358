import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MailStatusResponseComponent } from '../executor-custodian/pages/mail-status-response/mail-status-response.component';
import { AuthGuardUser } from '../helper/authGuard.helper';
import { AuthGuardExePro } from '../helper/authGuardExePro';
import { AuthGuardExecutor } from '../helper/authGuardExecutor';
import { AuthGuardProfessional } from '../helper/authGuardProfessional.helper';
import { PageGuard } from '../helper/page.guard';
import { routeDataShareInterface } from '../interface/common.interface';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { AccountsCardViewComponent } from './assets/accounts-card-view/accounts-card-view.component';
import { AssetsComponent } from './assets/assets.component';
import { FinancialAssetsComponent } from './assets/financial-assets/financial-assets.component';
import { RetirementAssetsComponent } from './assets/retirement-assets/retirement-assets.component';
import { BillingHistoryComponent } from './billing-history/billing-history.component';
import { BusinessDetailsComponent } from './business-details/business-details.component';
import { ManageBusinessDetailsComponent } from './business-details/manage-business-details/manage-business-details.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ConfirmAccountComponent } from './confirm-account/confirm-account.component';
import { GetOrganized4060Component } from './consumer-landing-page/consumer-40-60/consumer-40-60-get-organized/consumer-40-60-get-organized.component';
import { QuestionShort4060Component } from './consumer-landing-page/consumer-40-60/consumer-40-60-questions-short/consumer-40-60-questions-short.component';
import { Consumer4060RootComponent } from './consumer-landing-page/consumer-40-60/consumer-40-60-root.component';
import { ConsumerCompanyNewComponent } from './consumer-landing-page/consumer-company-new/consumer-company-new.component';
import { ConsumerCompanyComponent } from './consumer-landing-page/consumer-company/consumer-company.component';
import { ConsumerContactUsComponent } from './consumer-landing-page/consumer-contact-us/consumer-contact-us.component';
import { ConsumerFeaturesComponent } from './consumer-landing-page/consumer-features/consumer-features.component';
import { ConsumerFocusedComponent } from './consumer-landing-page/consumer-focused/consumer-focused.component';
import { ConsumerLandingContentNewComponent } from './consumer-landing-page/consumer-landing-content-new/consumer-landing-content-new.component';
import { ConsumerLandingIntroComponent } from './consumer-landing-page/consumer-landing-intro/consumer-landing-intro.component';
import { ConsumerLandingStepsComponent } from './consumer-landing-page/consumer-landing-steps/consumer-landing-steps.component';
import { ConsumerMarketingComponent } from './consumer-landing-page/consumer-marketing/consumer-marketing.component';
import { ConsumerPricingComponent } from './consumer-landing-page/consumer-pricing/consumer-pricing.component';
import { ConsumerPrivacyPolicyCaliforniaComponent } from './consumer-landing-page/consumer-privacy-policy-california/consumer-privacy-policy-california.component';
import { ConsumerPrivacyPolicyComponent } from './consumer-landing-page/consumer-privacy-policy/consumer-privacy-policy.component';
import { ConsumerUsePolicyComponent } from './consumer-landing-page/consumer-use-policy/consumer-use-policy.component';
import { DigitalVaultAgreementComponent } from './consumer-landing-page/digital-vault-agreement/digital-vault-agreement.component';
import { DigitalVaultLicensingAgreementComponent } from './consumer-landing-page/digital-vault-licensing-agreement/digital-vault-licensing-agreement.component';
import { NoticeToVaultHoldersComponent } from './consumer-landing-page/notice-to-vault-holders/notice-to-vault-holders.component';
import { CompletePurchaseComponent } from './consumer-landing-page/subscription/complete-purchase/complete-purchase.component';
import { CreateSubscriptionAccountComponent } from './consumer-landing-page/subscription/create-subscription-account/create-subscription-account.component';
import { PaymentInformationComponent } from './consumer-landing-page/subscription/payment-information/payment-information.component';
import { SubscriptionPlanComponent } from './consumer-landing-page/subscription/subscription-plan/subscription-plan.component';
import { SubscriptionComponent } from './consumer-landing-page/subscription/subscription.component';
import { UserAgreementComponent } from './consumer-landing-page/user-agreement/user-agreement.component';
import { ConsumerOrgMessageComponent } from './consumer-org-message/consumer-org-message.component';
import { CardDetailsComponent } from './consumer-payment-options/card-details/card-details.component';
import { PaymentDetailsComponent } from './consumer-payment-options/payment-details/payment-details.component';
import { ConsumerPaymentSectionComponent } from './consumer-payment-section/consumer-payment-section.component';
import { ConsumerUpdatePaymentComponent } from './consumer-update-payment/consumer-update-payment.component';
import { ConsumerNotificationsComponent } from './dashboard/consumer-notifications/consumer-notifications.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DeactivatedAccountComponent } from './deactivated-account/deactivated-account.component';
import { DigitalFilesComponent } from './digital-files/digital-files.component';
import { DigitalVaultComponent } from './digital-vault/digital-vault.component';
import { DlbDashboardComponent } from './dlb-dashboard/dlb-dashboard.component';
import { DonationsComponent } from './donations/donations.component';
import { EmailNotVerifiedComponent } from './email-not-verified/email-not-verified.component';
import { AdvisorInfoComponent } from './executor-beneficiaries/advisor-info/advisor-info.component';
import { BeneficiaryInfoComponent } from './executor-beneficiaries/beneficiary-info/beneficiary-info.component';
import { CustodianInfoComponent } from './executor-beneficiaries/custodian-info/custodian-info.component';
import { ExecutorBeneficiariesInfo2Component } from './executor-beneficiaries/executor-beneficiaries-info2/executor-beneficiaries-info2.component';
import { ExecutorBeneficiariesViewComponent } from './executor-beneficiaries/executor-beneficiaries-view/executor-beneficiaries-view.component';
import { ExecutorBeneficiariesComponent } from './executor-beneficiaries/executor-beneficiaries.component';
import { IntroEBComponent } from './executor-beneficiaries/intro/intro.component';
import { ManageBeneficiariesComponent } from './executor-beneficiaries/manage-beneficiaries/manage-beneficiaries.component';
import { ManageCustodianComponent } from './executor-beneficiaries/manage-custodian/manage-custodian.component';
import { ManageExecutorsComponent } from './executor-beneficiaries/manage-executors/manage-executors.component';
import { ProfessionalUserTypeComponent } from './executor-beneficiaries/professional-user-type/professional-user-type.component';
import { ForgotPasswordRequestComponent } from './fotgot-password-request/forgot-password-request.component';
import { GovernmentProofsComponent } from './government-proofs/government-proofs.component';
import { GracePeriodComponent } from './grace-period/grace-period.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { InvalidShortLinkComponent } from './invalid-short-link/invalid-short-link.component';
import { LiabilitiesListComponent } from './liabilities/liabilies-list/liabilities-list.component';
import { LiabilitiesComponent } from './liabilities/liabilities.component';
import { MessageComponent } from './message/message.component';
import { OauthCallbackComponent } from './oauth-callback/oauth-callback.component';
import { AboutYourselfComponentOverview } from './overview/about-yourself-overview/about-yourself-overview.component';
import { AccountAssetsComponent } from './overview/account-assets/account-assets.component';
import { ExecutorBeneficiariesOverviewComponent } from './overview/executor-beneficiaries-overview/executor-beneficiaries-overview.component';
import { FinanceOverviewComponent } from './overview/finance-overview/finance-overview.component';
import { InsuranceComponentOverview } from './overview/insurance/insurance.component';
import { OverviewDashboardComponent } from './overview/overview-dashboard/overview-dashboard.component';
import { OverviewComponent } from './overview/overview.component';
import { PropertyOverviewComponent } from './overview/property-overview/property-overview.component';
import { WillDigitalFilesOverviewComponent } from './overview/will-digital-files-overview/will-digital-files-overview.component';
import { PeopleComponent } from './people/people.component';
import { PrescriptionsComponent } from './prescriptions/prescriptions.component';
import { ManagePropertiesComponent } from './property/manage-properties/manage-properties.component';
import { PropertyComponent } from './property/property.component';
import { ReceivedDlbComponent } from './received-dlb/received-dlb.component';
import { ResendEmailComponent } from './resend-email/resend-email.component';
import { ScheduleAcceptPageComponent } from './schedule-accept-page/schedule-accept-page.component';
import { ScheduleDeclinePageComponent } from './schedule-decline-page/schedule-decline-page.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SupportComponent } from './support/support.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ValuableDetailsComponent } from './valuable-details/valuable-details.component';
import { ManageVehicleComponent } from './vehicle/manage-vehicle/manage-vehicle.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { VerifyClientImportComponent } from './verify-client-import/verify-client-import.component';
import { WillFilesComponent } from './will-files/will-files.component';

const dataShareService: routeDataShareInterface = { socketService: false };
const advisorsChildren: {}[] = [
  // { path: 'advisor-info', component: AdvisorInfoComponent },
  { path: 'advisor-info/:id', component: AdvisorInfoComponent, canActivate: [AuthGuardProfessional] },
  { path: 'manage-advisors', component: ProfessionalUserTypeComponent, canActivate: [AuthGuardUser] },
  { path: 'manage-advisors/:id', component: ProfessionalUserTypeComponent, canActivate: [AuthGuardProfessional] },
];

const routes: Routes = [
  {
    path: 'overview',
    component: OverviewComponent,
    canActivate: [AuthGuardUser],
    children: [
      {
        path: 'about-yourself-overview',
        component: AboutYourselfComponentOverview,
      },
      {
        path: 'executor-beneficiaries-overview',
        component: ExecutorBeneficiariesOverviewComponent,
      },
      {
        path: 'executor-beneficiaries-overview/:id',
        component: ExecutorBeneficiariesOverviewComponent,
      },
      { path: 'account-assets-overview', component: AccountAssetsComponent },
      { path: 'finance-overview', component: FinanceOverviewComponent },
      { path: 'property-overview', component: PropertyOverviewComponent },
      {
        path: 'will-digital-file-overview',
        component: WillDigitalFilesOverviewComponent,
      },
      { path: 'insurance-overview', component: InsuranceComponentOverview },
      { path: '', component: OverviewDashboardComponent },
    ],
  },
  {
    path: 'overview',
    component: OverviewComponent,
    children: [
      {
        path: 'about-yourself-overview/:id',
        component: AboutYourselfComponentOverview,
        canActivate: [AuthGuardProfessional],
      },
      { path: 'finance-overview/:id', component: FinanceOverviewComponent, canActivate: [AuthGuardExePro] },
      { path: 'property-overview/:id', component: PropertyOverviewComponent, canActivate: [AuthGuardExePro] },
      {
        path: 'will-digital-file-overview/:id',
        component: WillDigitalFilesOverviewComponent,
        canActivate: [AuthGuardExePro],
      },
      { path: 'insurance-overview/:id', component: InsuranceComponentOverview, canActivate: [AuthGuardExePro] },
      {
        path: ':id',
        component: OverviewDashboardComponent,
        canActivate: [AuthGuardExePro],
      },
    ],
  },
  { path: 'subscribe', component: GracePeriodComponent },
  { path: 'features', component: ConsumerFeaturesComponent },
  { path: 'pricing', component: ConsumerPricingComponent },
  { path: 'company-old', component: ConsumerCompanyComponent },
  { path: 'company', component: ConsumerCompanyNewComponent },
  { path: 'contact-us', component: ConsumerContactUsComponent },
  { path: 'f-landing-start-now', component: ConsumerFocusedComponent },
  { path: 'm-landing-open-account', component: ConsumerMarketingComponent },
  {
    path: '', component: Consumer4060RootComponent, children: [
      { path: 'questions', component: QuestionShort4060Component },
      { path: 'get-organized', component: GetOrganized4060Component },
      { path: ':slug/questions', component: QuestionShort4060Component },
      { path: ':slug/get-organized', component: GetOrganized4060Component },
    ],
  },
  { path: 'landing-intro', component: ConsumerLandingIntroComponent },
  { path: 'landing-steps', component: ConsumerLandingStepsComponent },
  { path: 'educational-intro', component: ConsumerLandingIntroComponent },
  { path: 'educational-intro2', component: ConsumerLandingStepsComponent },
  {
    path: 'consumer-subscription',
    component: SubscriptionComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: CreateSubscriptionAccountComponent,
      },
      {
        path: 'subscription-plan',
        component: SubscriptionPlanComponent,
      },
      {
        path: 'payment-information',
        component: PaymentInformationComponent,
      },
      {
        path: 'complete-purchase',
        component: CompletePurchaseComponent,
      },
    ],
  },
  { path: 'privacy-policy', component: ConsumerPrivacyPolicyComponent },
  { path: 'acceptable-use-policy', component: ConsumerUsePolicyComponent },
  {
    path: 'important-notice-to-digital-vault-holders',
    component: NoticeToVaultHoldersComponent,
  },
  {
    path: 'digital-vault-agreement',
    component: DigitalVaultAgreementComponent,
  },
  {
    path: 'digital-vault-licensing-agreement',
    component: DigitalVaultLicensingAgreementComponent,
  },
  { path: 'user-agreement', component: UserAgreementComponent },
  {
    path: 'california-privacy-policy',
    component: ConsumerPrivacyPolicyCaliforniaComponent,
  },
  { path: 'forgot-password', component: ForgotPasswordRequestComponent },
  { path: 'social/:id', component: OauthCallbackComponent },
  { path: 'reset-password', component: ChangePasswordComponent },
  { path: 'schedule-accept/:id', component: ScheduleAcceptPageComponent },
  { path: 'schedule-decline/:id', component: ScheduleDeclinePageComponent },
  { path: ':slug/schedule-accept/:id', component: ScheduleAcceptPageComponent },
  {
    path: ':slug/schedule-decline/:id',
    component: ScheduleDeclinePageComponent
  },
  { path: 'mail-status/:id', component: MailStatusResponseComponent },
  { path: ':slug/mail-status/:id', component: MailStatusResponseComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardUser],
  },
  // { path: 'dashboard/:id', component: DashboardComponent, canActivate: [AuthGuardCombinedService] },
  { path: 'resend-email', component: ResendEmailComponent },
  { path: 'confirm-account', component: ConfirmAccountComponent },
  { path: 'confirm-account-client', component: VerifyAccountComponent },
  { path: 'confirm-client-import', component: VerifyClientImportComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'email-not-verified', component: EmailNotVerifiedComponent },
  { path: 'page-not-found', component: InvalidShortLinkComponent },
  {
    path: 'about-yourself',
    loadChildren: () =>
      import('./about-yourself/about-yourself.module').then(
          (module) => module.AboutYourselfModule,
      ),
  },
  { path: 'notifications', component: ConsumerNotificationsComponent },
  { path: 'deactivated', component: DeactivatedAccountComponent },

  {
    path: 'dlb-dashboard/:id',
    component: DlbDashboardComponent,
    canActivate: [AuthGuardExecutor],
    children: [
      { path: '', component: OverviewDashboardComponent },
      {
        path: 'about-yourself-overview',
        component: AboutYourselfComponentOverview,
      },
      {
        path: 'executor-beneficiaries-overview',
        component: ExecutorBeneficiariesOverviewComponent,
      },
      { path: 'account-assets-overview', component: AccountAssetsComponent },
      { path: 'finance-overview', component: FinanceOverviewComponent },
      { path: 'property-overview', component: PropertyOverviewComponent },
      {
        path: 'will-digital-file-overview',
        component: WillDigitalFilesOverviewComponent,
      },
      { path: 'insurance-overview', component: InsuranceComponentOverview },
    ],
  },
  {
    path: 'property',
    component: PropertyComponent,
    canActivate: [AuthGuardUser],
    children: [
      {
        path: '',
        component: ManagePropertiesComponent,
        canActivate: [PageGuard],
      },
    ],
  },
  {
    path: 'property',
    component: PropertyComponent,
    children: [
      {
        path: ':id',
        component: ManagePropertiesComponent,
        canActivate: [AuthGuardProfessional],
      },
    ],
  },
  {
    path: 'vehicle',
    component: VehicleComponent,
    canActivate: [AuthGuardUser],
    children: [
      { path: '', component: ManageVehicleComponent, canActivate: [PageGuard] },
    ],
  },
  {
    path: 'vehicle',
    component: VehicleComponent,
    children: [
      {
        path: ':id',
        component: ManageVehicleComponent,
        canActivate: [AuthGuardProfessional],
      },
    ],
  },
  {
    path: 'business-details',
    component: BusinessDetailsComponent,
    canActivate: [AuthGuardUser],
    children: [
      {
        path: '',
        component: ManageBusinessDetailsComponent,
        canActivate: [PageGuard],
      },
    ],
  },
  {
    path: 'business-details',
    component: BusinessDetailsComponent,
    children: [
      {
        path: ':id',
        component: ManageBusinessDetailsComponent,
        canActivate: [AuthGuardProfessional],
      },
    ],
  },
  {
    path: 'liabilities',
    component: LiabilitiesComponent,
    canActivate: [AuthGuardUser],
    children: [
      {
        path: '',
        component: LiabilitiesListComponent,
        canActivate: [PageGuard],
      },
    ],
  },
  {
    path: 'liabilities',
    component: LiabilitiesComponent,
    children: [
      {
        path: ':id',
        component: LiabilitiesListComponent,
        canActivate: [AuthGuardProfessional],
      },
    ],
  },
  {
    path: 'valuable-details',
    component: ValuableDetailsComponent,
    canActivate: [AuthGuardUser, PageGuard],
  },
  {
    path: 'valuable-details/:id',
    component: ValuableDetailsComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: 'social-media-list',
    component: SocialMediaComponent,
    canActivate: [AuthGuardUser, PageGuard],
  },
  {
    path: 'social-media-list/:id',
    component: SocialMediaComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: 'subscriptions-list',
    component: SubscriptionsComponent,
    canActivate: [AuthGuardUser, PageGuard],
  },
  {
    path: 'subscriptions-list/:id',
    component: SubscriptionsComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: 'donation-list',
    component: DonationsComponent,
    canActivate: [AuthGuardUser, PageGuard],
  },
  {
    path: 'donation-list/:id',
    component: DonationsComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: 'prescription-list',
    component: PrescriptionsComponent,
    canActivate: [AuthGuardUser, PageGuard],
  },
  {
    path: 'prescription-list/:id',
    component: PrescriptionsComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: 'government-id-list',
    component: GovernmentProofsComponent,
    canActivate: [AuthGuardUser, PageGuard],
  },
  {
    path: 'government-id-list/:id',
    component: GovernmentProofsComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: 'assets',
    component: AssetsComponent,
    canActivate: [AuthGuardUser],
    canActivateChild: [AuthGuardUser],
    children: [
      { path: 'account-assets', component: FinancialAssetsComponent },
      // { path: 'credit-card', component: CreditCardComponent },
      { path: 'account-card-view', component: AccountsCardViewComponent },
      { path: 'financial-assets', component: FinancialAssetsComponent },
      { path: 'retirement-assets', component: RetirementAssetsComponent },
    ],
  },
  {
    path: 'assets',
    component: AssetsComponent,
    children: [
      {
        path: 'account-assets/:id',
        component: FinancialAssetsComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'account-card-view/:id',
        component: AccountsCardViewComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'financial-assets/:id',
        component: FinancialAssetsComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'retirement-assets/:id',
        component: RetirementAssetsComponent,
        canActivate: [AuthGuardProfessional],
      },
    ],
  },
  {
    path: 'advisors',
    component: ExecutorBeneficiariesComponent,
    children: advisorsChildren,
  },
  {
    path: ':slug/advisors',
    component: ExecutorBeneficiariesComponent,
    children: advisorsChildren,
  },
  {
    path: 'insurance-details',
    component: InsuranceComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: 'insurance-details/:id',
    component: InsuranceComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: 'executor-beneficiary',
    component: ExecutorBeneficiariesComponent,
    canActivate: [AuthGuardUser],
    children: [
      { path: 'info', component: ExecutorBeneficiariesInfo2Component },
      { path: 'manage-executors', component: ManageExecutorsComponent },
      { path: 'beneficiary-info', component: BeneficiaryInfoComponent },
      { path: 'custodian-info', component: CustodianInfoComponent },
      { path: 'manage-beneficiaries', component: ManageBeneficiariesComponent },
      {
        path: 'executors-beneficiaries-view',
        component: ExecutorBeneficiariesViewComponent,
      },
      { path: 'manage-custodian', component: ManageCustodianComponent },
      { path: '', component: IntroEBComponent },
    ],
  },
  {
    path: 'executor-beneficiary',
    component: ExecutorBeneficiariesComponent,
    children: [
      {
        path: 'info/:id',
        component: ExecutorBeneficiariesInfo2Component,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'manage-executors/:id',
        component: ManageExecutorsComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'beneficiary-info/:id',
        component: BeneficiaryInfoComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'manage-beneficiaries/:id',
        component: ManageBeneficiariesComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'custodian-info/:id',
        component: CustodianInfoComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'manage-custodian/:id',
        component: ManageCustodianComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'executors-beneficiaries-view/:id',
        component: ExecutorBeneficiariesViewComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: ':id',
        component: IntroEBComponent,
        canActivate: [AuthGuardProfessional],
      },
    ],
  },

  { path: 'people', component: PeopleComponent, canActivate: [AuthGuardUser] },
  { path: 'digital-vault', component: DigitalVaultComponent },
  {
    path: 'message',
    component: MessageComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: 'received-dlb',
    component: ReceivedDlbComponent,
    canActivate: [AuthGuardUser],
  },
  // { path: 'dlb-dashboard', component: DlbDashboardComponent, canActivate: [AuthGuardUser] },
  {
    path: 'digital-files',
    component: DigitalFilesComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: 'digital-files/:id',
    component: DigitalFilesComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: 'will-files',
    component: WillFilesComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: 'will-files/:id',
    component: WillFilesComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: 'account-settings',
    component: AccountSettingsComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: 'account-settings/:menu',
    component: AccountSettingsComponent,
    canActivate: [AuthGuardUser],
  },
  // { path: 'subscription-plan', component: ConsumerSubscriptionComponent, canActivate: [AuthGuardUser] },
  {
    path: 'saved-cards',
    component: CardDetailsComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: 'billing-history',
    component: BillingHistoryComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: 'payment-details',
    component: PaymentDetailsComponent,
    canActivate: [AuthGuardUser],
  },
  // { path: 'payment', component: PaymentComponent, canActivate: [AuthGuardUser] },
  {
    path: 'payment',
    component: ConsumerPaymentSectionComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: 'subscription-plan',
    component: ConsumerUpdatePaymentComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: 'org-message',
    component: ConsumerOrgMessageComponent,
    canActivate: [AuthGuardUser],
  },
  // { path: 'subscription-update', component: ConsumerUpdateCardDetailsComponent, canActivate: [AuthGuardUser] },
  // { path: 'payment-card-option', component: ConsumerPaymentCardComponent, canActivate: [AuthGuardUser] },
  // {
  //   matcher: customMatcher,
  //   loadChildren: () => import('../professional-dashboard/components/professional/professional.module').then((module) => module.ProfessionalDashboardModule)
  // },

  {
    path: 'support',
    component: SupportComponent,
    canActivate: [AuthGuardUser],
  },
  { path: ':slug/subscribe', component: GracePeriodComponent, data: dataShareService },
  { path: ':slug/home', component: ConsumerLandingContentNewComponent, data: dataShareService },
  { path: ':slug/features', component: ConsumerFeaturesComponent, data: dataShareService },
  { path: ':slug/pricing', component: ConsumerPricingComponent, data: dataShareService },
  { path: ':slug/company', component: ConsumerCompanyComponent, data: dataShareService },
  { path: ':slug/contact-us', component: ConsumerContactUsComponent, data: dataShareService },
  { path: ':slug/f-landing-start-now', component: ConsumerFocusedComponent },
  { path: ':slug/m-landing-open-account', component: ConsumerMarketingComponent },
  { path: ':slug/landing-intro', component: ConsumerLandingIntroComponent },
  { path: ':slug/landing-steps', component: ConsumerLandingStepsComponent },
  { path: ':slug/educational-intro', component: ConsumerLandingIntroComponent },
  { path: ':slug/educational-intro2', component: ConsumerLandingStepsComponent },
  { path: ':slug/privacy-policy', component: ConsumerPrivacyPolicyComponent },
  {
    path: ':slug/acceptable-use-policy',
    component: ConsumerUsePolicyComponent,
  },
  {
    path: ':slug/digital-vault-agreement',
    component: DigitalVaultAgreementComponent,
  },
  {
    path: ':slug/digital-vault-licensing-agreement',
    component: DigitalVaultLicensingAgreementComponent,
  },
  {
    path: ':slug/user-agreement',
    component: UserAgreementComponent,
  },
  {
    path: ':slug/california-privacy-policy',
    component: ConsumerPrivacyPolicyCaliforniaComponent,
  },
  { path: ':slug/forgot-password', component: ForgotPasswordRequestComponent },
  { path: ':slug/social/:id', component: OauthCallbackComponent },
  { path: ':slug/reset-password', component: ChangePasswordComponent },
  {
    path: ':slug/dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardUser],
  },
  // { path: 'dashboard/:id', component: DashboardComponent, canActivate: [AuthGuardCombinedService] },
  { path: ':slug/resend-email', component: ResendEmailComponent},
  { path: ':slug/confirm-account', component: ConfirmAccountComponent },
  { path: ':slug/confirm-account-client', component: VerifyAccountComponent },
  {
    path: ':slug/confirm-client-import',
    component: VerifyClientImportComponent,
  },
  { path: ':slug/unauthorized', component: UnauthorizedComponent },
  { path: ':slug/email-not-verified', component: EmailNotVerifiedComponent },
  { path: ':slug/page-not-found', component: InvalidShortLinkComponent },
  {
    path: ':slug/about-yourself',
    loadChildren: () =>
      import('./about-yourself/about-yourself.module').then(
          (module) => module.AboutYourselfModule,
      ),
  },
  { path: ':slug/notifications', component: ConsumerNotificationsComponent },
  { path: ':slug/checkout', component: CheckoutComponent, canActivate: [AuthGuardUser] },
  { path: ':slug/billing-history', component: BillingHistoryComponent, canActivate: [AuthGuardUser] },
  { path: ':slug/important-notice-to-digital-vault-holders', component: NoticeToVaultHoldersComponent },
  {
    path: ':slug/dlb-dashboard/:id',
    component: DlbDashboardComponent,
    canActivate: [AuthGuardUser],
    children: [
      { path: '', component: OverviewDashboardComponent },
      {
        path: 'about-yourself-overview',
        component: AboutYourselfComponentOverview,
      },
      {
        path: 'executor-beneficiaries-overview',
        component: ExecutorBeneficiariesOverviewComponent,
      },
      { path: 'account-assets-overview', component: AccountAssetsComponent },
      { path: 'finance-overview', component: FinanceOverviewComponent },
      { path: 'property-overview', component: PropertyOverviewComponent },
      {
        path: 'will-digital-file-overview',
        component: WillDigitalFilesOverviewComponent,
      },
      { path: 'insurance-overview', component: InsuranceComponentOverview },
    ],
  },
  {
    path: ':slug/overview',
    component: OverviewComponent,
    canActivate: [AuthGuardUser],
    children: [
      {
        path: 'about-yourself-overview',
        component: AboutYourselfComponentOverview,
      },
      {
        path: 'executor-beneficiaries-overview',
        component: ExecutorBeneficiariesOverviewComponent,
      },
      {
        path: 'executor-beneficiaries-overview/:id',
        component: ExecutorBeneficiariesOverviewComponent,
      },
      { path: 'account-assets-overview', component: AccountAssetsComponent },
      { path: 'finance-overview', component: FinanceOverviewComponent },
      { path: 'property-overview', component: PropertyOverviewComponent },
      {
        path: 'will-digital-file-overview',
        component: WillDigitalFilesOverviewComponent,
      },
      { path: 'insurance-overview', component: InsuranceComponentOverview },
      { path: '', component: OverviewDashboardComponent },
    ],
  },
  {
    path: ':slug/overview',
    component: OverviewComponent,
    children: [
      {
        path: 'about-yourself-overview/:id',
        component: AboutYourselfComponentOverview,
        canActivate: [AuthGuardProfessional],
      },
      { path: 'finance-overview/:id', component: FinanceOverviewComponent, canActivate: [AuthGuardExePro] },
      { path: 'property-overview/:id', component: PropertyOverviewComponent, canActivate: [AuthGuardExePro] },
      {
        path: 'will-digital-file-overview/:id',
        component: WillDigitalFilesOverviewComponent,
        canActivate: [AuthGuardExePro],
      },
      { path: 'insurance-overview/:id', component: InsuranceComponentOverview, canActivate: [AuthGuardExePro] },
      {
        path: ':id',
        component: OverviewDashboardComponent,
        canActivate: [AuthGuardExePro],
      },
    ],
  },
  {
    path: ':slug/property',
    component: PropertyComponent,
    canActivate: [AuthGuardUser],
    children: [
      { path: '', component: ManagePropertiesComponent },
    ],
  },
  {
    path: ':slug/property',
    component: PropertyComponent,
    children: [
      {
        path: ':id',
        component: ManagePropertiesComponent,
        canActivate: [AuthGuardProfessional],
      },
    ],
  },
  {
    path: ':slug/vehicle',
    component: VehicleComponent,
    canActivate: [AuthGuardUser],
    children: [
      { path: '', component: ManageVehicleComponent, canActivate: [PageGuard] },
    ],
  },
  {
    path: ':slug/vehicle',
    component: VehicleComponent,
    children: [
      {
        path: ':id',
        component: ManageVehicleComponent,
        canActivate: [AuthGuardProfessional],
      },
    ],
  },
  {
    path: ':slug/business-details',
    component: BusinessDetailsComponent,
    canActivate: [AuthGuardUser],
    children: [
      { path: '', component: ManageBusinessDetailsComponent },
    ],
  },
  {
    path: ':slug/business-details',
    component: BusinessDetailsComponent,
    children: [
      {
        path: ':id',
        component: ManageBusinessDetailsComponent,
        canActivate: [AuthGuardProfessional],
      },
    ],
  },
  {
    path: ':slug/liabilities',
    component: LiabilitiesComponent,
    canActivate: [AuthGuardUser],
    children: [
      { path: '', component: LiabilitiesListComponent },
    ],
  },
  {
    path: ':slug/liabilities',
    component: LiabilitiesComponent,
    children: [
      {
        path: ':id',
        component: LiabilitiesListComponent,
        canActivate: [AuthGuardProfessional],
      },
    ],
  },
  {
    path: ':slug/valuable-details',
    component: ValuableDetailsComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: ':slug/valuable-details/:id',
    component: ValuableDetailsComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: ':slug/social-media-list',
    component: SocialMediaComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: ':slug/social-media-list/:id',
    component: SocialMediaComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: ':slug/subscriptions-list',
    component: SubscriptionsComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: ':slug/subscriptions-list/:id',
    component: SubscriptionsComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: ':slug/donation-list',
    component: DonationsComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: ':slug/donation-list/:id',
    component: DonationsComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: ':slug/prescription-list',
    component: PrescriptionsComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: ':slug/prescription-list/:id',
    component: PrescriptionsComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: ':slug/government-id-list',
    component: GovernmentProofsComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: ':slug/government-id-list/:id',
    component: GovernmentProofsComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: ':slug/assets',
    component: AssetsComponent,
    canActivate: [AuthGuardUser],
    children: [
      { path: 'account-assets', component: FinancialAssetsComponent },
      // { path: 'credit-card', component: CreditCardComponent },
      { path: 'account-card-view', component: AccountsCardViewComponent },
      { path: 'financial-assets', component: FinancialAssetsComponent },
      { path: 'retirement-assets', component: RetirementAssetsComponent },
    ],
  },
  {
    path: ':slug/assets',
    component: AssetsComponent,
    children: [
      {
        path: 'account-assets/:id',
        component: FinancialAssetsComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'account-card-view/:id',
        component: AccountsCardViewComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'financial-assets/:id',
        component: FinancialAssetsComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'retirement-assets/:id',
        component: RetirementAssetsComponent,
        canActivate: [AuthGuardProfessional],
      },
    ],
  },

  {
    path: ':slug/insurance-details',
    component: InsuranceComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: ':slug/insurance-details/:id',
    component: InsuranceComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: ':slug/executor-beneficiary',
    component: ExecutorBeneficiariesComponent,
    canActivate: [AuthGuardUser],
    children: [
      { path: 'info', component: ExecutorBeneficiariesInfo2Component },
      { path: 'manage-executors', component: ManageExecutorsComponent },
      { path: 'beneficiary-info', component: BeneficiaryInfoComponent },
      { path: 'custodian-info', component: CustodianInfoComponent },
      { path: 'manage-beneficiaries', component: ManageBeneficiariesComponent },
      {
        path: 'executors-beneficiaries-view',
        component: ExecutorBeneficiariesViewComponent,
      },
      { path: 'manage-custodian', component: ManageCustodianComponent },
      { path: '', component: IntroEBComponent },
    ],
  },
  {
    path: ':slug/executor-beneficiary',
    component: ExecutorBeneficiariesComponent,
    children: [
      {
        path: 'info/:id',
        component: ExecutorBeneficiariesInfo2Component,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'manage-executors/:id',
        component: ManageExecutorsComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'beneficiary-info/:id',
        component: BeneficiaryInfoComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'manage-beneficiaries/:id',
        component: ManageBeneficiariesComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'advisor-info/:id',
        component: AdvisorInfoComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'custodian-info/:id',
        component: CustodianInfoComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: 'executors-beneficiaries-view/:id',
        component: ExecutorBeneficiariesViewComponent,
        canActivate: [AuthGuardProfessional],
      },
      {
        path: ':id',
        component: IntroEBComponent,
        canActivate: [AuthGuardProfessional],
      },
    ],
  },

  {
    path: ':slug/people',
    component: PeopleComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: ':slug/message',
    component: MessageComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: ':slug/received-dlb',
    component: ReceivedDlbComponent,
    canActivate: [AuthGuardUser],
  },
  // { path: 'dlb-dashboard', component: DlbDashboardComponent, canActivate: [AuthGuardUser] },
  {
    path: ':slug/digital-files',
    component: DigitalFilesComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: ':slug/digital-files/:id',
    component: DigitalFilesComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: ':slug/will-files',
    component: WillFilesComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: ':slug/will-files/:id',
    component: WillFilesComponent,
    canActivate: [AuthGuardProfessional],
  },
  {
    path: ':slug/account-settings',
    component: AccountSettingsComponent,
    canActivate: [AuthGuardUser],
  },
  // { path: 'subscription-plan', component: ConsumerSubscriptionComponent, canActivate: [AuthGuardUser] },
  {
    path: ':slug/saved-cards',
    component: CardDetailsComponent,
    canActivate: [AuthGuardUser],
  },
  { path: ':slug/payment-details', component: PaymentDetailsComponent },
  // { path: 'payment', component: PaymentComponent, canActivate: [AuthGuardUser] },
  {
    path: ':slug/payment',
    component: ConsumerPaymentSectionComponent,
    canActivate: [AuthGuardUser],
  },
  {
    path: ':slug/subscription-plan',
    component: ConsumerUpdatePaymentComponent,
    canActivate: [AuthGuardUser],
  },
  // { path: 'subscription-update', component: ConsumerUpdateCardDetailsComponent, canActivate: [AuthGuardUser] },
  // { path: 'payment-card-option', component: ConsumerPaymentCardComponent, canActivate: [AuthGuardUser] },
  {
    path: ':slug/support',
    component: SupportComponent,
    canActivate: [AuthGuardUser],
  },
  { path: ':slug', component: ConsumerLandingContentNewComponent },
  {
    path: ':slug/org-message',
    component: ConsumerOrgMessageComponent,
    canActivate: [AuthGuardUser],
  },
  { path: ':slug/deactivated', component: DeactivatedAccountComponent },
  { path: '**', component: UnauthorizedComponent },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
/**
 * consumer components routing module
 */
export class ComponentsRoutingModule {
}


