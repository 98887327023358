<app-common-modal [id]="modalID" class="app-common-modal" [heightClass]="null">
  <img src="assets/images/about-yourself/close.svg"
       class="absolute right-2 w-8 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
       (click)="closeModal()">
  <div class="w-full relative rounded-md">
    <div class="mb-4">
      <div class="ml-0 items-center flex flex-row w-full">
        <img src="assets/images/dashboard/valuables.svg" alt="vault transfer" class="w-12 h-12">
        <div class="ml-4 w-full">
          <h5 class="text-semibold text-2xl tracking-wide text-darkblue">
            <app-shimmer-loading [loading]="loading">
              {{dataLinkPaymentOption?.header}}
            </app-shimmer-loading>
          </h5>
          <p class="text-xl text-darkgrey flex flex-row">
            <app-shimmer-loading [loading]="loading">
              (one-time activation fee of {{dataLinkPaymentOption?.fee | currency}})
            </app-shimmer-loading>
          </p>
        </div>
      </div>
      <app-shimmer-loading *ngFor="let _ of [].constructor(5)" [loading]="loading">
      </app-shimmer-loading>
      <div
        *ngIf="!loading"
        [innerHTML]="dataLinkPaymentOption?.description">
      </div>
    </div>

    <ng-container>
      <div class="flex sm:flex-row justify-end items-center gap-4 mt-8">
        <app-button-loader
        *ngIf="isConsumer"
          [buttonText]="'Continue & pay later' | titlecase" [valid]="true" (click)="closeModal()"
          [loader]="loading" [buttonType]="'button'" [outlineOnly]="true">
        </app-button-loader>

        <app-button-loader
          [buttonText]="'Pay now' | titlecase" [valid]="true" (click)="payNow()"
          [loader]="loading" [buttonType]="'submit'">
        </app-button-loader>
      </div>
    </ng-container>
  </div>
</app-common-modal>
