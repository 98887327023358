export const MESSAGE = 'message';
export const TYPING = 'typing';
export const ONLINE = 'online';
export const OFFLINE = 'offline';
export const READ = 'read';
export const DELETE = 'delete';
export const UPDATE = 'update_message';

// decedent check status
export const DECEDENT_STATUS = 'decedent_check_status_changed';

export const ADMIN_SETTINGS = 'admin_site_settings';

export const BULK_IMPORT_COMPLETED = 'bulk_import_completed'
