import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AUSTRALIA, CANADA, CHILD_STATUS, ConsumerPaymentMethod, DATE_FORMAT, LIST_DELETE, LIST_NEW, LIST_UPDATE, LOCAL_STORAGE_UPDATE_STATUS, LoginType, PAYMENT_STATUS, UNITED_KINGDOM, UNITED_STATES, USER_TYPES } from 'src/constants/application.const';
import { CLEAR_SELECTED_FILE } from 'src/constants/digitalFile.const';
import { environment } from '../../environments/environment';
import { CardService } from '../components/consumer-payment-options/card-details/card-details.service';
import { ConsumerSubscriptionService } from '../components/consumer-payment-section/consumer-subscription.service';
import { ExecutorCustodianService } from '../executor-custodian/services/executor-custodian.service';
import { SelectOptionsInterface, toasterCloseOptionInterface } from '../interface/common.interface';
import { APIResponseModel, APIStatusCodes } from '../interface/response.interface';
import { OrganizationService } from '../organization/services/organization.service';
import { ProSocketService } from '../professional-dashboard/services/pro-socket.service';
import { CommonModelService } from '../services/common-model.service';
import { CommonService } from '../services/common.service';
import { LocalStorageService } from '../services/local-storage.service';
import { SessionStorageService } from '../services/session-storage.service';
import { SlugService } from '../services/slug.service';
import { SocketService } from '../services/socket.service';
import { UserService } from '../services/user.service';
import { LPUserPeopleInterface } from '../sharedComponent/list-people/list-people-interfaces';
import { MonthData } from '../viewData/month.data';
import { SlugInterceptorService } from './slug-interceptor.service';


@Injectable({
  providedIn: 'root',
})
/**
 * Common helper for whole project
 */
export class CommonHelper {
  /**
   * @constructor
   */
  constructor(
    private toastr: ToastrService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private executorCustodian: ExecutorCustodianService,
    private sessionStorageService: SessionStorageService,
    private socketService: SocketService,
    private proSocketService: ProSocketService,
    private commonService: CommonService,
    private organizationService: OrganizationService,
    private slugInterceptorService: SlugInterceptorService,
    private currencyPipe: CurrencyPipe,
    private consumerSubscription: ConsumerSubscriptionService,
    private modalService: CommonModelService,
    private slugService: SlugService,
    private cardService: CardService
  ) {
  }


  /**
   * Common responses to HTTP Responses
   * @param {APIResponseModel} response
   */
  public httpResponseHandler(response: APIResponseModel): void {
    if (response.message == 'The token could not be parsed from the request') {
      this.slugInterceptorService.navigate(['login']);
    }
    switch (response?.statusCode) {
      // Do not remove this part
      case APIStatusCodes.UNPROCESSABLE_CONTENT:
        const errorArray = [];
        // formatting errors
        Object.keys(response.message).forEach((keys) => {
          errorArray.push(...response.message[keys]);
        });
        const message = errorArray.join(',');
        this.toastr.error(message);
        break;

      default:
        this.toastr.error(response?.message ? response?.message : 'Unknown error');
        console.error(response);
        break;
    }
  }

  /**
   * Auto hypen SSN keyup
   *
   * @param {string} ssnValueInput
   * @return {string}
   */
  public autoHypenSSN(ssnValueInput: string): string {
    let ssnValue = ssnValueInput.replace(/\D/g, '');
    ssnValue = ssnValue.replace(/^(\d{3})/, '$1-');
    ssnValue = ssnValue.replace(/-(\d{2})/, '-$1-');
    ssnValue = ssnValue.replace(/(\d)-(\d{4}).*/, '$1-$2');
    return ssnValue;
  }

  /**
   * Date of Birth (DOB) validation function.
   * @param {FormControl} control - The form control containing the date of birth.
   * @return {object} - Validation result object.
   */
  DOBValidator(control: FormControl): { [s: string]: boolean } {
    const age = this.getAge(control.value);
    const selectedDate = new Date(control.value);
    const currentDate = new Date();

    if (selectedDate) {
      // Check if the year is greater than or equal to 1900
      const year = selectedDate.getFullYear();
      if (year < 1900) {
        return { invalidYear: true };
      }

      // Check if the selected date is in the future
      if (selectedDate > currentDate) {
        return { invalidYear: true };
      }
    }

    // Check if the calculated age is less than 18
    if (age < 18) {
      return { isNotAllowed: true };
    } else {
      return null;
    }
  }

  /**
   * Validates the subscription amount entered by the user.
   * @param control The form control containing the subscription amount.
   * @returns An object indicating validation errors if any, otherwise null.
   */
  subscriptionAmountValidator(control: FormControl): { [s: string]: boolean } {
    const amountInput = control.value ? Number(control.value) : null;
    const subscriptionAmount = Number(this.localStorageService.getDataByKey('admin_settings')?.subscription_price);
    if (!amountInput) {
      return null
    }
    if ((!(amountInput >= 0.5))) {
      return { amountBelowMinimum: true }
    } else if (amountInput > subscriptionAmount) {
      return { subscriptionAmountExceeded: true };
    } else {
      return null;
    }
  }

  /**
   * Common Logout functionality
   * @param {string} user Type of logout to perform, defined by {@link USER_TYPES}. `undefined` log out all user types.
   * @param{boolean} allowRoute If `false`, will not route to default logout pages.
   */
  public logoutCommon(user = USER_TYPES.user, allowRoute = true): void {
    if (user === USER_TYPES.pro || !user) {
      this.logoutPro(allowRoute);
    }
    if (user === USER_TYPES.org || !user) {
      this.logoutOrg(allowRoute);
    }
    if (user === USER_TYPES.exe || !user) {
      this.logoutCustodian(allowRoute);
    }
    if (user === USER_TYPES.user || !user) {
      this.logoutDV(allowRoute);
    }
  }

  /**
   * Logout Pro
   * @see {@link CommonHelper.logoutCommon logoutCommon}
   * @param{boolean} allowRoute
   * @private
   */
  private logoutPro(allowRoute = true) {
    this.userService.userLogoutProfessional().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.proSocketService.disconnectSocket();
        this.localStorageService.clearLocalStorage(USER_TYPES.pro);
        this.sessionStorageService.deleteDataByKey(USER_TYPES.pro);
        this.localStorageService.clearRole();
        if (allowRoute) {
          void this.slugInterceptorService.navigate(['login'], null, true);
          this.toastr.success('Logged Out Successfully');
        }
      }
    }, (exception: any) => {
      this.httpResponseHandler(exception?.error);
    });
  }

  /**
   * Logout Organization
   * @see {@link CommonHelper.logoutCommon logoutCommon}
   * @param{boolean} allowRoute
   * @private
   */
  private logoutOrg(allowRoute = true) {
    this.organizationService.orgLogout().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.proSocketService.disconnectSocket();
        this.localStorageService.clearLocalStorage(USER_TYPES.org);
        this.sessionStorageService.deleteDataByKey(USER_TYPES.org);
        this.localStorageService.clearRole();
        if (allowRoute) {
          void this.slugInterceptorService.navigate(['login'], null, true);
          this.toastr.success('Logged Out Successfully');
        }
        this.proSocketService.disconnectSocket();
      }
    }, (exception: any) => {
      this.httpResponseHandler(exception?.error);
    },
    );
  }

  /**
   * Logout Digital Vault Custodian
   * @see {@link CommonHelper.logoutCommon logoutCommon}
   * @param{boolean} allowRoute
   * @private
   */
  private logoutCustodian(allowRoute = true) {
    this.executorCustodian.logout().subscribe((res) => {
      if (res.status) {
        this.localStorageService.clearLocalStorage(USER_TYPES.exe);
        this.sessionStorageService.deleteDataByKey(USER_TYPES.exe);
        this.localStorageService.clearRole();
        if (allowRoute) {
          void this.slugInterceptorService.navigate(['/executor-custodian/login']);
          this.toastr.success('Logged Out Successfully');
        }
      }
    }, (exception: any) => {
      this.httpResponseHandler(exception?.error);
    });
  }

  /**
   * Logout Digital Vault Holder.
   * @see {@link CommonHelper.logoutCommon logoutCommon}
   * @param{boolean} allowRoute
   * @private
   */
  private logoutDV(allowRoute = true) {
    this.userService.userLogout().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.commonService.showPaymentButtonInNavBar(false);
        this.socketService.disconnectSocket();
        this.localStorageService.clearLocalStorage();
        this.sessionStorageService.deleteDataByKey(USER_TYPES.user);
        this.localStorageService.clearLocalStorage(USER_TYPES.exe);
        // this.localStorageService.clearRole();
        if (allowRoute) {
          void this.slugInterceptorService.navigate(['login']);
          this.toastr.success('Logged Out Successfully');
        }
      }
    }, (exception: any) => {
      this.httpResponseHandler(exception?.error);
    });
  }

  /**
   * Update local storage
   *
   * @param {any} data
   */
  public updateLocalstorageRequestStage(data: any): void {
    const stage = [
      data?.request_stage ? data.request_stage : data?.stage,
      data?.request_stage ? 'request_stage' : 'stage',
    ];
    this.localStorageService.updateUserData(USER_TYPES.user, {
      key: 'request',
      updateValue: stage[0],
      updateKey: stage[1],
      type: LOCAL_STORAGE_UPDATE_STATUS.O,
    });
  }

  /**
   * Clearfiles hanlder observable events
   */
  public clearfileHanlderObservableEvents(): void {
    this.commonService.fileHanlderObservableEvents({
      type: CLEAR_SELECTED_FILE,
    });
  }

  /**
   * Get current date for restriction
   *
   * @param {Date} date
   * @return {string}
   */
  public getCurrentDateForRestriction(date: Date = new Date()): string {
    return moment().format('YYYY-MM-DD').toString();
  }

  /**
   * Convert the array to {@link SelectOptionsInterface} format
   *
   * @param {Array<Object>} data
   * @param {string} valueKey
   * @param {string} displayKey
   * @param {string} additionaldisplayKey
   * @param {string} showFirst
   * @return {Array<SelectOptionsInterface>}
   */
  public convertToOptionsFormat(data: Array<Object>, valueKey: string, displayKey: string, additionaldisplayKey?: string, showFirst?: string): Array<SelectOptionsInterface> {
    return data?.map((item: any) => ({
      value: item[valueKey],
      displayText: additionaldisplayKey ? item[displayKey] + ' - ' + item[additionaldisplayKey] : item[displayKey],
      additionalDetails: item,
      showFirst: showFirst ? showFirst : '', // key added to show first in the option list
    }));
  }

  /**
   * Convert the array to {@link SelectOptionsInterface} format with displayText altered for promoCode Listing
   *
   * @param {Array<Object>} data
   * @param {string} valueKey
   * @param {string} displayKey
   * @param {string} showFirst
   * @return {Array<SelectOptionsInterface>}
   */
  public getPromoCodeOptionsFormat(data: Array<Object>, valueKey: string, displayKey: string, showFirst?: string): Array<SelectOptionsInterface> {
    return data?.map((item: any) => ({
      value: item[valueKey],
      displayText: item[displayKey] + ' - ' + (item.type !== 1 ? '$' : '') + item.value + (item.type === 1 ? '%' : ''),
      additionalDetails: item,
      showFirst: showFirst ? showFirst : '', // key added to show first in the option list
    }));
  }

  /**
   * Check for difference in an array and update it
   *
   * @param {Array<any>} arrayInput
   * @param {string} matchKey key to be matched for update
   * @param {string} matchValue value to be matched with the key
   * @param {any} updateValue update value for the matched key ( must contain listStatus )
   */
  public diffCheckerAndUpdate(arrayInput: Array<any>, matchKey: string, matchValue: string, updateValue: any): void {
    switch (updateValue?.listStatus) {
      case LIST_NEW:
        arrayInput.push(updateValue);
        break;
      case LIST_UPDATE: {
        arrayInput.some((data: any, index: number) => {
          if (data[matchKey] === matchValue) {
            arrayInput[index] = { ...arrayInput[index], ...updateValue };
          }
        });
      }
        break;
      case LIST_DELETE: {
        arrayInput.some((data: any, index: number) => {
          if (data[matchKey] === matchValue) {
            arrayInput.splice(index, 1);
            return true;
          }
        });
      }
        break;
    }
  }

  /**
   * Encode and decode request id ( used in url )
   *
   * @param {string} id
   * @param {boolean} type true for encoding and false for decoding
   * @return {string}
   */
  public encodeDecodeRequestId(id: string, type: boolean): string {
    try {
      return (type) ? btoa(id) : atob(id);
    } catch (e) {
      return '';
    }
  }

  /**
   * Returns a date formatted as defined by {@link format}.
   * If {@link date} is <code>nullish</code>, then <code>null</code> is returned.
   * @param{moment.MomentInput} date
   * @param{string} format
   * @return{string|null}
   */
  public formatDate(date: moment.MomentInput, format: string = DATE_FORMAT): string | null {
    return date ? moment(date).format(format) : null;
  }

  /**
   * Get date format by domain
   *  @return {string}
   */
  public get domainDateFormat(): string {
    return environment.DATE_FORMAT;
  }

  /**
   * Get to view date format by domain
   *  @return {string}
   */
  public get domainViewDateFormat(): string {
    return environment.VIEW_DATE_FORMAT;
  }

  /**
   * Get to table view date format by domain
   *  @return {string}
   */
  public get domainViewTableDateFormat(): string {
    return environment.TABLE_VIEW_DATE_FORMAT;
  }

  /**
   * Get current year
   */
  public get currentYear(): number {
    return Number(new Date().getFullYear());
  }

  /**
   * To give alert message insert success
   */
  public toastrInsertSuccess() {
    this.toastr.success('Details saved successfully');
  }

  /**
   * To give alert message update success
   */
  public toastrUpdateSuccess() {
    this.toastr.success('Details updated Successfully');
  }

  /**
   * To give alert message file upload success
   */
  public toastrFileUploadSuccess() {
    this.toastr.success('File uploaded Successfully');
  }

  /**
   * To give alert message delete success
   */
  public toastrDeleteSuccess() {
    this.toastr.success('Details removed successfully');
  }

  /**
   * To give appointment created successfully
   */
  public toastrAppointmentSuccess() {
    this.toastr.success('Appointment created successfully');
  }

  /**
   * Display a success message when a file is being uploaded, notifying the user to wait.
   */
  public showFileUploadInProgressMessage() {
    this.toastr.success('File is being uploaded', 'Please wait');
  }

  /**
   * to give schedule success
   */
  public toastrScheduleSuccess() {
    this.toastr.success('Schedule sent successfully');
  }

  /**
   * Toaster with closable option
   * @param{toasterCloseOptionInterface} options
   */
  public toasterCloseOption(options: toasterCloseOptionInterface): void {
    this.toastr.info(options.message, options.title, options.positionClass);
  }

  /**
   * Calculate days between two dates
   * @param {Date} date1 - the first date
   * @param {Date} date2 - the second date
   * @return {number}
   */
  public daysBetweenDates(date1: Date, date2: Date): number {
    return Math.round((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
  }

  /**
   * Get 10th day of month
   * @return {any}
   */
  public getTenthDay(): any {
    const date = new Date();
    return date.setDate(10);
  }

  /**
   * add days in date
   * @param{Date}date
   * @return{any}
   */
  public addDaysFromDate(date: Date, days: number): any {
    const calculatedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
    return new Date(calculatedDate);
  }

  /**
   * add 30 days
   * @param{Date}date
   * @return{any}
   */
  public addOneMonth(date: Date): Date {
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
    return new Date(endDate);
  }

  /**
   * Adds one year
   * @param{Date}date
   * @return{Date}
   */
  public addYear(date: Date , count: number): Date {
    const endDate = new Date(date.getFullYear() + count, date.getMonth(), date.getDate());
    return endDate;
}

  /**
   * Adds date by passing param
   * @param{Date} date
   * @param{number}days
   * @return{any} date by passing param
   */
  public incrementDate(date: Date, days: number): any {
    const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
    return new Date(endDate);
  }

  /**
   * Check month to contact with consumer
   * @param {Date} date -  date
   * @return {any}
   */
  public checkContact(date: Date): any {
    const getMonth = new Date(date).getMonth() + 1;
    const quarterlyMonth = MonthData.map((a) => {
      return a.items;
    })[0];
    console.log(quarterlyMonth);
    const jsonData = quarterlyMonth.find((month) => month.id === getMonth);
    const currentMonth = new Date().getMonth() + 1;
    const createdYear = new Date(date).getFullYear();
    if ((jsonData?.id == currentMonth && createdYear !== new Date().getFullYear()) || jsonData?.first == currentMonth || jsonData?.second == currentMonth || jsonData?.third == currentMonth) {
      return new Date().setDate(1);
    } else {
      return null;
    }
  }

  /**
   * change date format to YYY-MM-DD HH:MM:SS as string
   * @param {Date} date -  date
   * @return {any}
   */
  public dateformat(date: Date): any {
    return (date.getFullYear() + '-' + this.padTo2Digits(date.getMonth() + 1) + '-' + this.padTo2Digits(date.getDate()) + ' ' + this.padTo2Digits(date.getHours()) + ':' + this.padTo2Digits(date.getMinutes()) + ':' + this.padTo2Digits('00'));
  }

  /**
   * change date format to YYY-MM-DD as string
   * @param {Date} date -  date
   * @return {any}
   */
  public dateformatWithoutTime(date: Date): any {
    return (date.getFullYear() + '-' + this.padTo2Digits(date.getMonth() + 1) + '-' + this.padTo2Digits(date.getDate()));
  }

  /**
   * change date format to date
   * @param {string} value -  date
   * @return {Date}
   */
  public stringToDate(value: string): Date {
    const arr: any[] = value.split(/-/);
    return new Date(arr[0], arr[1] - 1, arr[2]);
  }

  /**
   * pad 2 digits
   * @param{any}num
   * @return{any}
   */
  public padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  /**
   * Converts a number, or a string representing a decimal number, to a string representing a number with comma-separation.
   * @param {string|number} value A number or a string representing a decimal number.
   * @param{boolean} omitSign Omit dollar symbols like 'CA$' from the output. <pre><code>/[^0-9.,]/g</code></pre>
   * @return {string} A string representing a number with comma-separation.
   */
  public toCurrency(value: string | number, omitSign: boolean = false): string {
    try {
      return this.currencyPipe.transform(value, environment.CURRENCY_CODE, omitSign ? '' : undefined);
    } catch (e) {
      return '';
    }
  }

  /**
   * Perform comma-separation on value of the event target then update the control with this value.
   * @param {string | number} value - String or Number to patch
   * @param {AbstractControl} control - Control to patch the value into
   */
  public commaSeparateNumberPatch(value: string | number, control: AbstractControl): void {
    control.patchValue(this.toCurrency(value, true));
  }

  /**
   * Perform clean comma-separation on value of the event target then update the control with this value.
   * @param {string | number} value - String or Number to clean
   * @param {AbstractControl} control - Control to patch the value into. If null, this is ignored.
   * @return {string} String of the cleaned value
   */
  public commaSeparateNumberClean(value: string | number, control?: AbstractControl | null): string {
    if (!value || value === '') {
      return null;
    }
    value = value.toString().replace(/[^0-9.-]+/g, '');
    if (control) {
      control.patchValue(value);
    }
    return value;
  }

  /**
   * Set Agent term in Local Storage
   * @return{string}
   */
  public LocalStorageAgentTerm(): string {
    return this.localStorageService.getDataByKey('agent_term') ?? 'MISSING_TERM';
  }

  /**
   * Set default domain country in Local Storage
   * @param{any}useCountryIfException
   */
  public setDomainCountryInLocal(useCountryIfException: any) {
    return new Observable<string>((observer) => {
      let domainCountry = '';
      switch (environment.APP_DOMAIN) {
        case 1:
          domainCountry = UNITED_STATES;
          break;
        case 2:
          domainCountry = CANADA;
          break;
        case 3:
          domainCountry = AUSTRALIA;
          break;
        case 4:
          domainCountry = UNITED_KINGDOM;
          break;
      }
      this.commonService.getCountries().subscribe((response) => {
        if (response?.data?.length) {
          this.localStorageService.storeData('countryList', response?.data);
          const list = response.data.filter((list) => {
            const name = list.name.toLowerCase();
            return name === domainCountry;
          });
          list ?
            this.localStorageService.storeData('country', list[0].id) :
            this.localStorageService.storeData('country', useCountryIfException);
          observer.next(list[0].id || useCountryIfException);
        }
      }, () => {
        this.localStorageService.storeData('country', useCountryIfException);
      });
    });
  }

  /**
   * get country name by country is
   * @param{number} id Country ID
   * @return{string}
   */
  public getCountryNameById(id: number): string {
    const countryList = this.localStorageService.getDataByKey('countryList');
    return countryList?.filter((country) => country.id === id)[0]?.name?.toLowerCase();
  }

  /**
   * get org logo
   * @return{string}
   */
  public getOrgLogo() {
    return localStorage.getItem('orgLogo');
  }

  /**
   *
   * @param{any} url
   * @param{any} id
   */
  public routerNavigate(url: any, id: any) {
    void this.slugInterceptorService.navigate([url, id]);
  };

  /**
   * toaster
   *
   * @param {any} data
   */
  public toastMessages(data: any): void {
    switch (data?.status) {
      case 1: {
        this.toastr.success(data?.message);
      }
        break;
      case 0:
        this.toastr.error(data?.message);
    }
  }

  /**
   * Shows ssn toast
   * @return{boolean} true if ssntoast
   */
  public isShowSSNToast(): boolean {
    return false;
  }

  /**
   * capitalize first letter
   * @param {string} word
   * @return {string}
   */
  public capitalizeFirstLetter(word: string): string {
    return word?.charAt(0).toUpperCase() + word.slice(1);
  }

  /**
   * Checks duplicate data
   * @param {any} data
   * @param {any} oldData
   * @return{boolean} true if duplicate data
   */
  public checkDuplicateData(data: any, oldData: any): boolean {
    if (oldData?.length) {
      return !oldData?.filter((list) => JSON.stringify(list) === JSON.stringify(data))?.length;
    } else {
      return true;
    }
  }

  /**
   * To be used as {@link Array.sort} <code>compareFn</code> where the elements in the array are of type
   * {@link SelectOptionsInterface}. This will sort the array alphabetically by
   * {@link SelectOptionsInterface.displayText displayText}.
   * @param {any} a
   * @param {any} b
   * @return {number}
   */
  public compareDisplayText(a: SelectOptionsInterface, b: SelectOptionsInterface): number {
    const displayTextA = a.displayText?.toUpperCase();
    const displayTextB = b.displayText?.toUpperCase();

    if (displayTextA < displayTextB) {
      return -1;
    } else if (displayTextA > displayTextB) {
      return 1;
    } else {
      return 0;
    }
  }

  /**
   * This will find the first index of an item which satisfies {@link findFn}. It will take the element at that index
   * and move it to the specified point of the array.
   * @param{Array} array
   * @param{any} findFn
   * @param{string} placeAt
   * @return{Array}
   */
  public placeAtPointOfArray(array: Array<any>, findFn: (value: any, index: number, obj: any[]) => unknown, placeAt: 'start' | 'end' = 'end'): Array<any> {
    const indexItem = array?.findIndex(findFn);
    if (indexItem >= 0) {
      const other = array[indexItem];
      array.splice(indexItem, 1);
      if (placeAt == 'start') {
        array.unshift(other); // add data at beginning
      } else {
        array.push(other);
      }
    }
    return array;
  }

  /**
   * Retrieves <code>user.can_add_employees</code> from stored Organization user data from LocalStorage, returns true
   * if <code>user.can_add_employees === 1</code>.
   * @return{boolean}
   */
  public getOrgCanAddEmployees(): boolean {
    return this.localStorageService.getUserData(USER_TYPES.org)?.user?.can_add_employees === 1;
  }

  /**
   * Compare {@link date} with today's date to determine the difference.
   * If {@link date} is nullish, <code>undefined</code> is returned.
   * @param {string} date
   * @return {number}
   */
  public getAge(date: string): number {
    if (!date) {
      return undefined;
    }
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    console.log('age', age);
    return age;
  }

  /**
   * Convert the array to {@link SelectOptionsInterface} format
   *
   * @param {Array<any>} data
   * @param {string} valueKey
   * @param {string} displayKey
   * @param {string} additionaldisplayKey
   * @return {Array<SelectOptionsInterface>}
   */
  public convertToOptions(data: Array<any>, valueKey: string, displayKey: string, additionaldisplayKey?: string): Array<SelectOptionsInterface> {
    return data?.map((item: any) => ({
      value: item[valueKey],
      displayText: additionaldisplayKey ? (item[displayKey] ? item[displayKey] : '') + ' ' + (item[additionaldisplayKey] ? item[additionaldisplayKey] : '') : (item[displayKey] ? item[displayKey] : ''),
      additionalDetails: item,
    }));
  }

  /**
   * Used in section editing when being accessed by advisor/custodian to determine permission to edit the form.
   * @param{boolean} isNewAsset
   * @param{{}} permissions
   * @return{boolean}
   */
  public canEditSection(isNewAsset: boolean, permissions: { add: boolean, edit: boolean }) {
    return (!isNewAsset && permissions?.edit) || (isNewAsset && permissions?.add);
  }

  /**
   * Determines whether trial period for consumer from slug user
   * @returns true if trial period for consumer from slug user
   */
  public isTrialPeriodForConsumerFromSlugUser(): boolean {
    const grace_period_started_at = this.localStorageService.getDataByKey('co_brand_grace_period_started_at');
    const paymentMode = this.localStorageService.getDataByKey('payment_mode');
    const isNotPaid = this.localStorageService.getDataByKey('is_payment_complete') == 0;
    return grace_period_started_at ? isNotPaid && paymentMode == null && this.addDaysFromDate(new Date(grace_period_started_at), 15) > new Date() : false;
  }

  /**
   * Checks payment status
   */
  public checkPaymentStatus(): void {
    this.modalService.close('payment-process');
    const paymentMode = this.localStorageService.getDataByKey('payment_mode');
    this.consumerSubscription.getPaymentStatus().subscribe((response) => {
      const currentUser = this.localStorageService.getUserData();
      const slugDetails = currentUser?.slug_details;
      const today = new Date();
      const paymentStatus = response?.data?.is_payment_complete;
      const subscriptionCancellationCount = response?.data?.subscription_cancellation_count;
      this.localStorageService.storeData('is_payment_complete', paymentStatus);
      this.localStorageService.storeData('subscription_cancellation_count', response?.data?.subscription_cancellation_count);
      this.userService.getPaymentStatus(paymentStatus);
      const paymentRefundAt = response?.data?.['payment_refund_at'];
      const onRefundPeriod = (paymentStatus == PAYMENT_STATUS.onRefundPeriod);
      const coBrandGracePeriodStartedAt = this.localStorageService.getDataByKey('co_brand_grace_period_started_at');
      const paymentDoneAt = response?.data?.['payment_done_at'];
      const trialPeriod = 15; //The consumer who transitioned from being a slug user to a normal user is undergoing a trial period.
      const subscriptionEndsAt = response?.data?.['subscription_ends_at'];
      const subscriptionEndDate = this.dateformatWithoutTime(new Date(subscriptionEndsAt));
      const paymentAlertCalled = this.localStorageService.getDataByKey('payment_alert_called');
      const isTrialPeriodForConsumerFromSlugUser = this.isTrialPeriodForConsumerFromSlugUser();
      this.localStorageService.storeData('is_transfer_amount_paid', response?.data?.['is_transfer_amount_paid']);
      this.localStorageService.storeData('cancellation_period', response?.data?.['cancellation_period']);
      this.localStorageService.storeData('is_first_month', response?.data?.['is_first_month']); // 1- is first month, 0 -not first month
      const isFirstMonth = (this.localStorageService.getDataByKey('is_first_month') == 1);
      this.localStorageService.storeData('gotRefund', !!response.data.payment_refund_at);

      /**
       * Function to determine whether to redirect to the subscribe page.
       * Redirect conditions:
       * 1. If payment mode is undefined.
       * 2. If payment mode is not set to 'PAID_BY_PROFESSIONAL'.
       * 3. If slug details are not available.
       * @returns {boolean} True if redirection is needed, otherwise false.
       */
      const redirectToSubscribe = (): boolean => {
        return (paymentMode == undefined || paymentMode != ConsumerPaymentMethod.PAID_BY_PROFESSIONAL || !slugDetails);
      };

      const handlePaymentAlertPopup = () => {
        const trialEndDate = this.incrementDate(new Date(coBrandGracePeriodStartedAt), Number(trialPeriod));
        const inTrialDays = trialEndDate >= today;
        const daysLeftforTrialPeriod = this.daysBetweenDates(new Date(coBrandGracePeriodStartedAt), today);
        const remainingSubscriptionDays = this.daysBetweenDates(today, new Date(subscriptionEndsAt));
        if ((inTrialDays || isTrialPeriodForConsumerFromSlugUser) && !paymentAlertCalled) {
          this.localStorageService.storeData('in_trial_period', inTrialDays);
          setTimeout(() => {
            this.userService.getFreeTrailDaysActiveStatus({ freeTrailDays: (+trialPeriod) - (+daysLeftforTrialPeriod), remainingSubscriptionDays: remainingSubscriptionDays, subscriptionEndDate: subscriptionEndDate, isFirstMonth: isFirstMonth, isTrialPeriodForConsumerFromSlugUser: isTrialPeriodForConsumerFromSlugUser });
            this.modalService.open('payment-alert-freeTrial-completed');
            this.localStorageService.storeData('payment_alert_called', 1);
          });
        }
      };
      // admin_death_verify is 3, redirect to deactivated
      if (currentUser?.['request']?.['admin_death_verify'] === 3) {
        return void this.slugInterceptorService.navigate(['/deactivated']);
      }
      //  This indicates that the user is beyond the first month of their subscription and has chosen to cancel the subscription.
      // const cancelledAfterFirstMonth = (response?.data?.['cancellation_period'] == 1);
      switch (paymentStatus) {
        case 0:
          if (!isFirstMonth || (isFirstMonth && paymentRefundAt)) {
            handlePaymentAlertPopup();
            return;
          }
          if (isTrialPeriodForConsumerFromSlugUser) {
            handlePaymentAlertPopup(); // Todo: Need to update alert messge based here condition
            return;
          }
          //  Not paid
          console.log('case 0')
          //redirectToSubscribe() && void this.slugInterceptorService.navigate(['/subscribe']);
          break;
        case 1:
          // Paid
          break;
        case 2:
          if ((!isFirstMonth && onRefundPeriod) || subscriptionCancellationCount == 1) {
            console.log('case 2')
            //redirectToSubscribe() && void this.slugInterceptorService.navigate(['/subscribe']);
            return;
          }

          // Refunded but within subscription trial time
          // show alert:  days left in trial period
          if (paymentDoneAt && paymentRefundAt) {
            isFirstMonth && handlePaymentAlertPopup();
          }
          break;
        case 3:
          // In account deletion period
          console.log('case 3')
          //redirectToSubscribe() && void this.slugInterceptorService.navigate(['/subscribe']);
          break;
        case 4:
        case 5:
          // Handle payment pending and cancelled
          console.log('case 5')
          // redirectToSubscribe() && void this.slugInterceptorService.navigate(['/subscribe']);
          break;
      }
    });
  }

  /**
  * Update slug details
  */
  public updateProDetailsInLocalStorage(slugDetails: any, userDetails?: any): void {
    const defaultSubscriptionAmount = Number(this.localStorageService.getDataByKey('admin_settings')?.subscription_price);
    this.localStorageService.storeData('co_brand_grace_period_started_at', userDetails?.co_brand_grace_period_started_at);
    userDetails && this.setSubscriptionPlanType({...slugDetails, ...userDetails}); // set subscription plan type.
    if (slugDetails) {
      userDetails && this.localStorageService.storeData('slug_subscription_amount', userDetails?.pay_amount ? userDetails?.pay_amount : defaultSubscriptionAmount);
      userDetails && this.localStorageService.storeData('payment_mode', userDetails?.payment_type);
      userDetails && this.localStorageService.storeData('firm_name',slugDetails?.full_name)
      if (this.isNullorUndefined(slugDetails['slug_url'])) {
        return;
      };
      localStorage.setItem('verifiedSlug', slugDetails.slug_url);
      localStorage.setItem('orgLogo', slugDetails?.upload_logo);
      this.slugService.setSlug(true);
    } else {
      localStorage.removeItem('verifiedSlug');
      localStorage.removeItem('orgLogo');
    }
  }

  /**
   * Sets subscription plan type
   * @param user 
   * @returns subscription plan type 
   */
  private setSubscriptionPlanType(user: any): void {
    this.localStorageService.storeData('subscription_plan_type', 2); // store default subscription plan per month
    if(user.is_payment_complete == 1 && !user.is_psp_paid) { // remains same plan, though plan still exists
      return;
    } else if(user.slug_url) { // set the psp's subscription plan
    this.localStorageService.storeData('subscription_plan_type', user?.plan_type);
    }
  }

  /**
  * Removes details related to the affiliated professional service provider (PSP) from local storage.
  * This method clears specific keys associated with the PSP and its details.
  */
  public removeProDetailsFromLocalStorage() {
    this.localStorageService.clearOrgData();
    this.localStorageService.updateUserData(USER_TYPES.user, {
      key: 'slug_details',
      updateValue: null,
      type: LOCAL_STORAGE_UPDATE_STATUS.S,
    });
    this.localStorageService.deleteDataByKey('slug_subscription_amount');
    this.localStorageService.deleteDataByKey('payment_mode');
    this.localStorageService.deleteDataByKey('subscription_plan_type');
    this.localStorageService.deleteDataByKey('firm_name');
    this.localStorageService.deleteDataByKey('co_brand_grace_period_started_at');
    location.reload();
  }

  /**
   * Whether user can access forms from section four(PSP) till last section in consumer dashboard
   * @return{boolean}
   */
  public getFormAccessControl(): boolean {
    const isPaymentCompleted = this.localStorageService.getDataByKey('is_payment_complete');

    // const isIdVerified = this.localStorageService.getDataByKey('is_id_verified');
    // form access option from admin.
    // If formAccessType = 1 - Full Access to all forms until trial period ends.(First three sections should be completed to unlock remaining sections)
    // If formAccessType = 2  - View Only access for forms from section 4 to last section if payment is not completed or ID is not verified
    const formAccessType = this.localStorageService.getDataByKey('form_access');

    // enables user to add/edit form fields
    return !this.isProfessional ? formAccessType === 1 || (isPaymentCompleted === 1 && formAccessType === 2) : true
  }

  /**
   * Updates the agent term data in local storage if not already present.
   * Fetches the data from the server if not available locally.
   */
  public updateAgentTermLocalStorage() {
    const getAgentTerm = this.localStorageService.getDataByKey('agent_term');
    if (!getAgentTerm) {
      this.commonService.getAgentTerm().subscribe({
        next: (response) => {
          this.localStorageService.storeData('agent_term', response?.data);
        },
        error: () => {
          this.localStorageService.storeData('agent_term', '');
        },
      });
    }
  }

  /**
   * add
   commas to the arguments
   * @param{string[]}args
   * @return{string}
   */
  public addCommas(...args: string[]): string {
    const isDefined = (value) => value != null && value !== '';
    // Function to add commas between elements with data.
    return args.filter(isDefined).join(', ');
  }

  /**
   * get children status
   * @param{number}type
   * @return{string}
   */
  public getDisplayTextByValue(type: number): string {
    if (type == null) {
      return null;
    }
    const status = CHILD_STATUS.find((statusItem) => statusItem.value == type.toString());
    return status ? status.displayText : undefined;
  }

  /**
   * get settings from admin
   * @return{{}}
   */
  public getAdminSettings() {
    return this.localStorageService.getDataByKey('admin_settings');
  }

  /**
   * scroll to first empty field
   * @param{FormGroup}form
   */
  public scrollToFirstInvalidControl(form: FormGroup) {
    const formControls = form.controls;

    for (const controlName in formControls) {
      if (controlName) {
        const control = formControls[controlName];
        if (control.invalid) {
          this.focusOnElement(controlName);
          return;
        }
      }
    }
  }

  /**
   * Scrolls to the topmost visible element of a form.
   * @param{FormGroup} form The FormGroup containing form controls.
   */
  public scrollToTopModal(form: FormGroup) {
    const formControls = form.controls;
    for (const controlName in formControls) {
      if (controlName) {
        const element = document.querySelector(`[formcontrolname="${controlName}"]`) as HTMLElement || document.querySelector(`[id="${controlName}"]`) as HTMLElement;
        if (!!element) {
          console.log(controlName);
          element.scrollIntoView({ behavior: 'smooth' });
          element.focus();
          element.blur();
          return;
        }
      }
    }
  }

  /**
   * Focuses on the HTML element associated with the given controlName.
   * If an element with the 'formcontrolname' attribute matching the controlName is found, it is focused.
   * Otherwise, if an element with the 'id' attribute matching the controlName is found, it is focused.
   * Scrolls the page to bring the focused element into view with smooth behavior.
   *
   * @param {string} controlName - The name of the form control or id of the element to focus on.
   */
  public focusOnElement(controlName: string) {
    // Try to find the element by 'formcontrolname' attribute first, then fallback to 'id' attribute.
    const invalidElement = document.querySelector(`[formcontrolname="${controlName}"]`) as HTMLElement || document.querySelector(`[id="${controlName}"]`) as HTMLElement;
    // If a matching element is found, scroll into view and set focus.
    if (invalidElement) {
      invalidElement.scrollIntoView({ behavior: 'smooth' });
      invalidElement.focus();
    }
  }

  /**
   * Common process for eligibility for assignment as DV executor or custodian.
   * @param{Object} data The raw data of the person from which to determine eligibility.
   * @param{LPUserPeopleInterface} person The {@link LPUserPeopleInterface} to modify.
   * @return{LPUserPeopleInterface}
   * returns {@link person} modified based on eligibility. Since {@link person} is modified directly, it is not strictly necessary to re-assign the object; it is returned to allow chaining.
   */
  public processPersonForExecutorCustodianEligibility(data: Object, person: LPUserPeopleInterface): LPUserPeopleInterface {
    // Disable If Person is an Advisor
    // Removed - UAT #227 | Keep in case this decision is vetoed later.
    /* if (data['advisor'] == 1) {
      person.isDisabled = true;
      person.checkbox.disableCheckbox = true;
      person.checkbox.tooltip = 'Disabled';
      return person;
    } */
    const age = this.getAge(data['dob']);
    const isAdvisor = data['advisor'] == 1;
    console.log('data', data);
    // Age restriction
    if (age < 18 && !isAdvisor) {
      person.isDisabled = true;
      person.checkbox.disableCheckbox = true;
      person.checkbox.tooltip = 'Must be at least 18 years of age.';
      return person;
    }

    // Missing Data
    if (!data['phone'] || !data['email'] || (!age && !isAdvisor)) {
      person.isDisabled = true;
      person.checkbox.disableCheckbox = true;
      person.checkbox.tooltip = 'Please complete phone, email, and date of birth.';
    }

    return person;
  }

  /**
   *
   * @param{string} redirectUrl
   * @return{string}
   */
  public getRedirectBtnText(redirectUrl: string) {
    if (redirectUrl) {
      const parts = redirectUrl.split('/');
      const lastPart = parts[parts.length - 1];

      if (lastPart === 'manage-executors') {
        return ' Executor Management';
      } else if (lastPart === 'manage-custodian') {
        return ' Custodian Management';
      } else {
        return ' Beneficiary Management';
      }
    }
  }

  /**
   * Retruns Partner Sub Text
   * @param{number}maritalStatus
   * @return{string}
   */
  static getPartnerSubText(maritalStatus: number) {
    switch (maritalStatus) {
      case PartnerType.Divorced:
        return 'Former Spouse/Partner';
      case PartnerType.Separated:
        return 'Separated Spouse/Partner';
      case PartnerType.Widowed:
        return 'Late Spouse/Partner';
      default:
        return 'Spouse/Partner';
    }
  }

  /**
   * Returns Advisors Sub Text
   * @param {any} user_people
   * @return {string}
   */
  static getAdvisorSubText(user_people, SectionName = '') {
    const localStorageService = new LocalStorageService(null);
    const userData = localStorageService.getUserData(USER_TYPES.user);
    const primaryAdvisorId = userData?.['slug_details']?.id;
    if (SectionName == 'advisor') {
      if (primaryAdvisorId && (primaryAdvisorId === user_people?.professional?.professional_id || primaryAdvisorId === user_people?.professional?.id)) {
        return 'Primary affiliation';
      }
      return 'Other Associations';
    }
    return user_people['advisor_type']?.['name'] ?? user_people['professional_type'] ?? user_people['professional']?.['professional_type_name'] ?? '';
  }

  /**
   * Get the company name based on user details and section name.
   * @param {object} user_people - Object containing user details.
   * @param {string} sectionName - Optional section name.
   * @returns {string} - The company name or an empty string if not found.
   */
  static getCompanyName(user_people, sectionName = '') {
    const isAdvisor = user_people['advisor'] == 1
    const professional = user_people?.['professional'];
    const localStorage = new LocalStorageService(null);
    const slugDetails = (localStorage.getUserData(USER_TYPES.user))?.slug_details
    if (isAdvisor && professional && sectionName === 'advisor') {
      return slugDetails?.id === professional?.professional_id ? slugDetails?.full_name : ''
    }
    else {
      return ''
    }
  }

  /**
   * Escape special characters in regex in the given {@link text}.
   * @param{string} text
   * @return{string}
   */
  static escapeRegExp(text: string) {
    return text.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, '\\$&');
  }

  /**
   * Removes alpha numeric characters
   * @param{string} text
   * @return{string}
   */
  public removeAlphaNumericCharacters(text: string) {
    return text.replace(/[^a-zA-Z0-9]/g, '');
  }

  /**
   * Logic to get the Vault-Holder user request ID based on who is accessing the vault (VH, Custodian, or Professional).
   * `ActivatedRoute` is needed to get ID param from the URL for Custodians.
   * @param{ActivatedRoute} activeRouter
   * @return{string}
   */
  public getRequestId(activeRouter?: ActivatedRoute) {
    const userType = this.localStorageService.getUserData('role');
    const isAccessingVault = this.localStorageService.getDataByKey('accessVault');
    const userProReqID = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id');
    const paramReqID = !!activeRouter ? (this.encodeDecodeRequestId(activeRouter.snapshot.parent.params?.id, false) || activeRouter.snapshot.parent.params?.id) : undefined;
    return (userType === 'Custodian' && !isAccessingVault) ? paramReqID : userProReqID;
  }

  /**
   * Populates chosenBeneficiaries based on beneficiary type.
   * @param{boolean} chosenBeneficiaries
   * @return{boolean} An object containing categorized beneficiaries.
   */
  public populateChosenBeneficiaries(chosenBeneficiaries: any) {
    return chosenBeneficiaries.reduce((result, beneficiary) => {
      delete beneficiary.user_people;
      const type = beneficiary.type;
      result[type].push(beneficiary);
      return result;
    },
      { 1: [], 2: [] },
    );
  }

  /**
   * Determines the role of the current user based on stored user data.
   * @returns {LoginType}
   */
  public getUserRole(): LoginType {
    const consumer = this.localStorageService.getUserData();
    const professional = this.localStorageService.getUserData(USER_TYPES.pro);
    const executor = this.localStorageService.getUserData(USER_TYPES.exe);
    if (!this.isNullorUndefined(consumer) && Object.keys(consumer)?.length > 0) {
      return LoginType.DIGITAL_VAULT;
    } else if (!this.isNullorUndefined(professional) && Object.keys(professional)?.length > 0) {
      return LoginType.PROFESSIONAL;
    } else if (!this.isNullorUndefined(executor) && Object.keys(executor)?.length > 0) {
      return LoginType.CUSTODIAN;
    }
  }

  /**
   * Determine period
   * @param days
   * @returns period
   */
  public determinePeriod(days: number): object {
    if (days % 365 === 0) {
      return { number: days / 365, period: 'years' };
    } else if (days % 30 === 0) {
      return { number: days / 30, period: 'months' };
    } else {
      return { number: days, period: 'days' };
    }
  }

  /**
   * Remove the prefix number from file name which is added from backend
   * @param file
   * @returns file Name
   */
  public removePrefixNumber(file: string): string {
    return String(file).split('_').map((r, i) => {
      return i == 0 && !isNaN(+r) ? '' : r;
    }).join('');
  }

  /**
   * Extracts relevant data from the userData object and returns a standardized data object.
   *
   * @param {Object} userData - The user data object
   * @returns {Object} - data object containing vault holder information.
   */
  public getVaultHolderData(userData): Object {
    return {
      first_name: userData?.['user']?.['first_name'],
      middle_name: userData?.['user']?.['middle_name'],
      last_name: userData?.['user']?.['last_name'],
      dob: userData?.['request']?.['dob'],
      ssn: null,
      ssn_validation: null,
      phone: userData?.['user']?.['phone'],
      email: userData?.['user']?.['email'],
      address: userData?.['request']?.['address'],
      city: userData?.['request']?.['city']?.['id'],
      apt_number: userData?.['request']?.['apt_number'],
      country: userData?.['request']?.['country']?.['id'],
      state: userData?.['request']?.['state']?.['id'],
      zipcode: userData?.['request']?.['zipcode'],
      is_state: userData?.['request']?.['country']?.['is_state'],
      country_code: userData?.['user']?.['country_code'],
      flag_code: userData?.['user']?.['flag_code'],
      roletype: 'Consumer',
    };
  }

  /**
   * Retrieves the reCAPTCHA configuration.
   * If not available in local storage, fetches from the server.
   * Otherwise, retrieves from local storage.
   * @returns An observable of APIResponseModel containing the reCAPTCHA configuration.
   */
  public getCaptchaConfig(): Observable<APIResponseModel> {
    const enableRecaptcha = this.localStorageService.getDataByKey('enable_google_recaptcha');
    if (enableRecaptcha == null || enableRecaptcha == undefined) {
      return this.commonService.getRecaptchaConfig().pipe(
        tap((response) => {
          this.localStorageService.storeData('enable_google_recaptcha', response?.data['is_enabled']);
        }),
      );
    } else {
      return of({
        status: true,
        message: 'Success',
        statusCode: APIStatusCodes.SUCCESS,
        data: { 'is_enabled': enableRecaptcha },
      });
    }
  }

  /**
   * Redirects the user to the dashboard if the cobrand is paid by a professional and there are slug details available.
   */
  public redirectToDbIfPaidByPro() {
    const isCobrandPaidByPro = (this.localStorageService.getDataByKey('payment_mode') == ConsumerPaymentMethod.PAID_BY_PROFESSIONAL);
    const slugDetails = (this.localStorageService.getUserData())?.['slug_details'];
    if (isCobrandPaidByPro && slugDetails) {
      void this.slugInterceptorService.navigate(['dashboard']);
    }
  }

  /**
   * Determines whether the trial period for a DLB consumer from a slug user is active.
   * @param dlbData - Data related to the subscription.
   * @returns True if the trial period for the DLB consumer from the slug user is active, otherwise false.
   */
  public isDlbInTrialPeriod(dlbData: any): boolean {
    const grace_period_started_at = dlbData?.user_request?.user?.co_brand_grace_period_started_at;
    const paymentMode = dlbData?.user_request?.user?.co_brand?.payment_mode;
    const isNotPaid = dlbData?.user_request?.user?.is_payment_complete;

    return grace_period_started_at ?
      isNotPaid && (paymentMode === undefined || paymentMode === null) && this.addDaysFromDate(new Date(grace_period_started_at), 15) > new Date() :
      false;
  }


  /**
   * Encrypts the query part of the registered URL for security purposes
   * @param url The generated URL
   * @returns The encrypted URL
   */
  public encryptRegisterLink(url: string): string {
    const index = url.indexOf('?');
    if (index === -1) {
      return url;
    } else {
      const encryptedUrl = btoa(url.substring(index + 1));
      // Encrypt the URL with AES and the secret key
      // const encryptedUrl = CryptoJS.AES.encrypt(url.substring(index + 1), environment.LOCAL_STORAGE_SECRET).toString();
      // Encode the encrypted URL with Base64 (no special characters)
      // const processedUrl = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptedUrl));
      return [url.substring(0, index), encryptedUrl].join('?');
    }
  }


  /**
   * Decrypts the query part of the registered URL for security purposes
   * and extracts query parameters
   * @param encryptedUrl The encrypted URL
   * @returns A query parameters object
   */
  public decryptRegisterLink(encryptedUrl: string): Object {
    const index = encryptedUrl.indexOf('?');
    if (index === -1) {
      return encryptedUrl;
    }
    else {
      let getString = String(encryptedUrl.substring(index + 1));
      if (getString.endsWith("=")) {
        getString = getString.slice(0, -1);
      }
      // Decode the Base64 encoded URL
      const decodedUrl = atob(getString);
      // const decodedUrl = CryptoJS.enc.Base64.parse(encryptedUrl.substring(index + 1)).toString(CryptoJS.enc.Utf8);
      // Decrypt the URL with AES and the secret key
      // const processedUrl = CryptoJS.AES.decrypt(decodedUrl, environment.LOCAL_STORAGE_SECRET).toString(CryptoJS.enc.Utf8);
      const decryptedUrl = [encryptedUrl.substring(0, index), decodedUrl].join('?');
      return this.extractQueryParams(decryptedUrl)
    }
  }

  /**
   * Extracts query parameters from the URL
   * @param url The URL
   * @returns An object containing the query parameters
   */
  public extractQueryParams(url: string) {
    const queryString = url.split('?')[1];
    const queryParamsArray = queryString.split('&');
    const queryParams: any = {};
    queryParamsArray.forEach(pair => {
      const [key, value] = pair.split('=');
      queryParams[key] = value;
    });
    return queryParams
  }

  /**
   * Find the value has null or undefined
   * @returns boolean
   */
  public isNullorUndefined(value: any): boolean {
    return value == null || value == undefined;
  }
  /**
   * Get whether is consumer role
   */
  public get isConsumer(): boolean {
    return this.localStorageService.getDataByKey('role') === 'Consumer';
  }
  /**
   * Get whether is custodian role
   */
  public get isCustodian(): boolean {
    return this.localStorageService.getDataByKey('role') === 'Custodian';
  }

  /**
   * Checks if the user's role is 'Professional'.
   * @returns {boolean} Returns true if the user's role is 'Professional', otherwise false.
   */
  public get isProfessional(): boolean {
    return this.localStorageService.getDataByKey('role') === 'Professional';
  }

  /**
   * Checks if the user's role is 'Professional'.
   * @returns {boolean} Returns true if the user's role is 'Professional', otherwise false.
   */
  public get isAdvisor(): boolean {
    return this.localStorageService.getDataByKey('role') === 'Advisor';
  }

  /**
   * Get whether is custodian & is vault claimed & not transfer fee paid status
   */
  public get isClaimedAndNotPaid(): boolean {
    return this.isCustodian && this.localStorageService.getDataByKey('isVaultClaimed') && !this.localStorageService.getDataByKey('is_transfer_amount_paid')
  }

  /**
   * Checks if the client has a paid subscription.
   *
   * This function checks whether the client, identified by user ID and payment type,
   * has a paid subscription. It returns a boolean indicating the subscription status.
   *
   * @param {Object} response - The response object containing user information.
   * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the client has a paid subscription.
   * @throws Will log an error and return false if the subscription check fails.
   */
  public async checkClientSubscription(response) {
    if (this.isProfessional) {
      const clientUserId = response?.data?.['user']?.['id'];
      const clientPaymentMode = response?.data?.['user']?.['payment_type'];
      try {
        const clientHasPaidSubscription = await this.consumerSubscription.clientHasPaidSubscription(clientUserId, clientPaymentMode);
        return clientHasPaidSubscription;
      } catch (error) {
        console.error('Error checking subscription:', error);
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Get an array of recent years including the current year.
   * @param {number} count - The number of years to include in the array.
   * @returns {Array<SelectOptionsInterface>} - An array of years.
   */
  public getYears(count: number): Array<SelectOptionsInterface> {
    const currentYear = moment().year();
    return Array.from({ length: count }, (_, index) => ({ displayText: `${currentYear - index}`, value: `${index + 1}` }));
  }

  /**
   * Modifies the file name by removing any prefixes and returning the file name without directory paths.
   *
   * @param {any} fileName - The full path or file name string.
   * @returns {string} The modified file name with the prefix removed.
   */
  public modifyFileName(fileName: any) {
    const name = fileName?.split('/');
    return this.removePrefixNumber(name[name.length - 1]);
  }

  /**
   * get logged in user type
   */
  public get getLoggedInUserType() {
    const isUserLoggedIn = !!this.localStorageService.getDataByKey(USER_TYPES.user)?.access_token;
    const isExecutorLoggedIn = !!(this.localStorageService.getDataByKey(USER_TYPES.exe)?.access_token);
    const isProLoggedIn = !!(this.localStorageService.getDataByKey(USER_TYPES.pro)?.access_token);
    const isOrgLoggedIn = !!(this.localStorageService.getDataByKey(USER_TYPES.org)?.access_token);
    if (isUserLoggedIn) {
      return USER_TYPES.user;
    } else if (isProLoggedIn) {
      return USER_TYPES.pro;
    } else if (isOrgLoggedIn) {
      return USER_TYPES.org;
    } else if (isExecutorLoggedIn) {
      return USER_TYPES.exe;
    }
  }

  /**
   * Connect socket
   */
  public connectSocket() {
    switch (this.getLoggedInUserType) {
      case USER_TYPES.user:
        this.socketService.setUseSocket(true);
        break;
      case USER_TYPES.pro: case USER_TYPES.org: case USER_TYPES.exe:
        this.proSocketService.setUseSocket(true);
        break;
      default:
        this.socketService.setUseSocket(false);
        this.proSocketService.setUseSocket(false);
    }
  }

  /**
   * Checks if any cards have been added by calling the getCardsApi method.
   * Stores the result in local storage.
   * 
   * @returns {Promise<boolean>} A promise that resolves to a boolean indicating if cards have been added.
   */
  public isCardsAdded(): Promise<boolean> {
    return this.cardService.getCardsApi(true).pipe(map(r => {
      const cardsAdded = r?.data?.length > 0
      this.localStorageService.storeData('cardsAdded', cardsAdded);
      return cardsAdded
    })).toPromise();
  }
}


enum PartnerType {
  Separated = 6,
  Divorced = 2,
  Widowed = 4
}
