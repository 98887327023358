import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileTypeIcons',
})
/**
 * File type icons
 */
export class FileTypeIconsPipe implements PipeTransform {
  /**
   * transformation function
   * @param {string} value
   * @param {string} args
   * @return {string}
   */
  transform(value: string, ...args: string[]): string {
    return {
      'jpeg': 'assets/images/digital-files/jpeg.svg',
      'jfif': 'assets/images/digital-files/jpeg.svg',
      'jpg': 'assets/images/digital-files/jpg.svg',
      'png': 'assets/images/digital-files/png.svg',
      'pdf': 'assets/images/digital-files/pdf.svg',
      'mp4': 'assets/images/digital-files/video.svg',
      'avi': 'assets/images/digital-files/avi_icon.svg',
      'mkv': 'assets/images/digital-files/mkv.svg',
      'flv': 'assets/images/digital-files/flv.svg',
      'mov': 'assets/images/digital-files/mov.svg',
      'mp3': 'assets/images/digital-files/mp3.svg',
      'mpeg': 'assets/images/digital-files/mp3.svg',
      'txt': 'assets/images/digital-files/txt.svg',
      'docx': 'assets/images/digital-files/docx.svg',
      'xlsx': 'assets/images/digital-files/xlsx.png',
      'csv': 'assets/images/digital-files/csv.png',
      'doc': 'assets/images/digital-files/doc.svg',
      'gif': 'assets/images/digital-files/gif.svg',
    }[(args[0].split(/\.(?=[^\.]+$)/)[1]).toLowerCase()];

    // ['jpeg', 'jpg', 'png', 'doc', 'docx', 'txt', 'pdf', 'mp3', 'mp4', 'avi', 'mov', 'flv', 'mkv']
  }
}
