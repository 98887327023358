<app-common-modal id="email" class="app-common-modal">
  <h2 class="text-xl lg:text-2xl font-sans font-medium highlighted-text truncate mr-5">
    Email is not verified
  </h2>
  <div class="flex flex-row items-center">
    <p class="font-sans text-lg mt-2">
      Your email is not verified.Please verify email and login again
    </p>
    <div class="loading-red ml-2"></div>
  </div>
</app-common-modal>
