import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ObservableEventInterface } from '../interface/common.interface';
import { APIResponseModel } from '../interface/response.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * Assets service
 */
export class AssetsService {
  // state observables
  private resetSelectAccout: any;
  public resetSelectAccoutObserve: Observable<any>;

  // credit card event handlers
  private creditCardComponent: any;
  public creditCardComponentObserve: Observable<any>;

  // bank event handlers
  private bankComponent: any;
  public bankComponentObserve: Observable<any>;

  // account list event handlers
  private accountList: any;
  public accountListObserve: Observable<any>;

  // percentage details handler
  private percentageList: any;
  public percentageListObserve: Observable<any>;

  private BASE_URL: string;
  private BASE_URL_TWO: string;


  /**
   * @constructor
   */
  constructor(
    private http: HttpClient,
  ) {
    this.BASE_URL = environment.BASE_URL;
    this.BASE_URL_TWO = environment.BASE_URL_TWO;

    // for refeshing select
    this.resetSelectAccout = new BehaviorSubject(false);
    this.resetSelectAccoutObserve = this.resetSelectAccout.asObservable();

    // credit card observable and handlers
    this.creditCardComponent = new BehaviorSubject({});
    this.creditCardComponentObserve = this.creditCardComponent.asObservable();

    // bank observable and handlers
    this.bankComponent = new BehaviorSubject({});
    this.bankComponentObserve = this.bankComponent.asObservable();

    // account observable and handlers
    this.accountList = new BehaviorSubject([]);
    this.accountListObserve = this.accountList.asObservable();

    // account observable and handlers
    this.percentageList = new BehaviorSubject(false);
    this.percentageListObserve = this.accountList.asObservable();
  }


  /**
   * Children obserable events
   *
   * @param {ObservableEventInterface} data
   */
  public creditCardObservableEvents(data: ObservableEventInterface): void {
    this.creditCardComponent.next(data);
  }

  /**
   * Children obserable events
   *
   * @param {ObservableEventInterface} data
   */
  public bankObservableEvents(data: ObservableEventInterface): void {
    this.bankComponent.next(data);
  }

  /**
   * Clear selected option select and select search - state
   *
   * @param {Array<any>} list
   */
  public accountListTrigger(list: Array<any>): void {
    this.accountList.next(list);
  }

  /**
   * Clear selected option select and select search - state
   *
   * @param {boolean} trigger
   */
  public resetSelectTrigger(trigger: boolean): void {
    this.resetSelectAccout.next(trigger);
  }
  /**
   * Clear selected option select and select search - state
   *
   * @param {boolean} trigger
   */
  public percentageListTrigger(trigger: boolean): void {
    this.percentageList.next(trigger);
  }

  /**
   * store bank details
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public storeBankDetails(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-bank-details`, data);
  }

  /**
   * store financial asset details
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public storeFinancialAssetDetails(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-financial-asset-details`, data);
  }
  /**
   * skip financial asset details
   *
   * @param {string} requestID
   * @return {Observable<APIResponseModel>}
   */
  public skipFinancialAssetDetails(requestID: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/skip-financial-asset-details?`, requestID);
  }
  /**
   * skip retirement asset details
   *
   * @param { string} requestID
   * @return {Observable<APIResponseModel>}
   */
  public skipRetirementAssetDetails(requestID: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/skip-retirement-asset-details?`, requestID);
  }

  /**
   * Storing retirement asset details
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public storeRetirementAssetDetails(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-retirement-asset-details`, data);
  }

  /**
   * store Credit card details
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public storeCreditCardDetails(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-credit-card-details`, data);
  }

  /**
   * store insurance details
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public storeInsuranceDetails(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-insurance-details`, data);
  }

  /**
   * store liability details
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public storeLiabilityDetails(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-liability-details`, data);
  }

  /**
   * get assets value details
   *
   * @param {string} requestId
   * @return {Observable<APIResponseModel>}
   */
  public getTotalAssetsValue(requestId: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/request/dashboard-asset-value/${requestId}`);
  }
  /**
   * get beneficiary percentage list
   * @param {string} data
   * @return {Observable<APIResponseModel>}
   */
  public getInsurancePercentageList(data: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/request/beneficiary-insurance/${data}`);
  }
  /**
   * get retirement beneficiary percentage list
   * @param {string} data
   * @return {Observable<APIResponseModel>}
   */
  public getRetirementPercentageList(data: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/request/beneficiary-retirement/${data}`);
  }

  /**
   * get financial beneficiary percentage list
   * @param {string} data
   * @return {Observable<APIResponseModel>}
   */
  public getFinancialPercentageList(data: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/request/beneficiary-financial/${data}`);
  }

  /**
   * get beneficairy list
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public getBeneficairyList(data: any): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/request/get-user-people-beneficiary/${data}`);
  }
}


