<div class="my-6">
  <div class="border-card bg-white rounded-md p-6 mb-36">
    <div class="flex flex-wrap">
      <!-- Search Section -->
      <div class="w-full lg:w-1/2 ">
        <div class="relative">
          <div class="sm:w-full md:w-2/4  relative h-10 text-grey ">
            <div class=" absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"> <img
              src="assets/images/about-yourself/search.svg" class="w-4  h-4" alt="digital vault"> </div>
            <input type="text" class=" input py-3 px-4 mt-1 w-full text-regular text-grey
                          rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                   placeholder="Search by Name" (input)="onSearch($event)">
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
