import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker/bs-datepicker.component';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-datepicker-toggle',
  templateUrl: './datepicker-toggle.component.html',
  styleUrls: ['./datepicker-toggle.component.css'],
})
export class DatepickerToggleComponent {
  @ViewChild('dp') datepicker: BsDatepickerDirective;
  @Output() onChange: EventEmitter<Date> = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Input() class: string;
  @Input() disableErrorCSS: boolean = false;
  @Input() bsConfig: Partial<BsDatepickerConfig>;
  @Input() inputID: string;
  @Input() useFormControl: AbstractControl | FormControl;
  @Input() disabled: boolean = false;
  @Input() placeholder: string;
  readonly domainDateFormat = environment.DATE_FORMAT;
  readonly faCalendar = faCalendar;
  dpToggle: boolean = false;

  onToggle() {
    if(!this.disabled) {
      this.dpToggle = !this.dpToggle;
      this.datepicker.show();
    }
  }

  /**
   * Get class for datepicker input.
   */
  get getClass() {
    return `
     ${!this.disableErrorCSS && this.useFormControl.errors && this.useFormControl.touched ? 'form-input-error' : 'form-input-focus'}
     ${this.class}
    `;
  }

  /**
   * On Date Change
   * @param $event
   */
  bsValueChange($event: Date) {
    // Check if $event is already null or undefined, to prevent unnecessary processing
    if (!$event || isNaN($event.getTime())) {
      // Check if the current value is already null or undefined, to prevent unnecessary updates
      if (this.useFormControl?.value !== null) {
        // Manually set the model value to null (or any default value you want)
        this.useFormControl?.setValue(null);
      }
    } else {
      // Check if the new value is different from the current value
      if (this.useFormControl?.value !== $event) {
        // Emit the change event only if there's an actual change
        this.onChange.emit($event);
      }
    }
  }

  /**
   * On Date Picker Hide
   * @param $event
   */
  onHiddenListener(){
    this.onClose.emit(true);
    this.dpToggle = false
  }
}
