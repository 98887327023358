<div class=" border-card bg-white rounded-md p-6 mt-10">
    <div class="flex justify-between" id="AgentTable">
        <div>
            <h4 class="text-semibold text-grey text-md md:text-lg lg:text-xl xl:text-2xl">
                Onboard New Clients
            </h4>
        </div>
    </div>
    <div class="flex flex-wrap mt-10">
        <div class="w-full lg:w-1/2 ">
            <div class="relative">
                <div class="sm:w-full md:w-2/4  relative h-10 text-grey ">
                    <div class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"> <img
                            src=" assets/images/about-yourself/search.svg " class="w-4  h-4" alt="digital vault"> </div>
                    <input type="text" class=" input py-3 px-4 mt-1 w-full text-regular text-grey
                        rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                        placeholder="Search by Name" (input)="onSearchVaultHolder($event.target['value'])">
                </div>
            </div>
        </div>
        <div class="w-full lg:w-1/2">
            <div class="flex flex-wrap item-center justify-between">
              <div>
                <div class=" md:justify-end">
                  <div class="flex justify-center item-center text-grey mt-4">
                    <div
                      class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                      Showing: 
                    </div>
                    <select [(ngModel)]="clientListTableSettings.recordsPerPage" (change)="getImportedVaultHoldersList()"
                      class="text-center form-select cursor-pointer bg-transparent ml-4 pt-1 mt-0 rounded-xs w-20 h-8 text-regular text-grey p-2 border-none block focus:outline-none insurance_term">
                      <option *ngFor="let option of selectOptions;let i=index" [value]="option['id']">
                        {{option["value"]}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class=" md:justify-end css-shortby">
                <div class="md:justify-end">
                  <div class="flex justify-center item-center h-10 text-grey mt-4">
                    <div
                      class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                      Sort by: 
                    </div>
                    <select [(ngModel)]="clientListTableSettings.sortPreference" (change)="getImportedVaultHoldersList()"
                      class="text-center form-select cursor-pointer bg-transparent ml-4 pt-1 mt-0 rounded-xs h-10 text-regular text-grey border-none block focus:outline-none insurance_term ng-pristine ng-valid ng-touched">
                      <option *ngFor="let order of orderOptions;let i=index" [value]="order['id']"
                        [hidden]="order['value']===professionalType">
                        {{order["value"]}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
    <!-- Professional Invoice Table -->
    <div *ngIf="dataLoading">
        <div class="mt-3 w-full">
            <app-shimmer-loading [loading]="true" [width]="'100%'" [height]="'3rem'"></app-shimmer-loading>
        </div>
        <div class="flex flex-row mt-3 mb-6 items-center">
            <div>
                <app-shimmer-loading [loading]="true" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
            </div>
            <div class="ml-4 mt-0 w-full">
                <ng-container *ngFor="let _ of [].constructor(3)">
                    <app-shimmer-loading [loading]="true" [width]="'100%'"></app-shimmer-loading>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="mt-5" *ngIf="!dataLoading">
        <div class="professional-table overflow-x-auto">
            <table class="table w-full text-left mb-10" aria-describedby="professionalTable">
                <thead class="bg-gray-400 text-blue text-md text-regular">
                    <tr>
                        <th class="p-4 whitespace-normal" scope="row">Name</th>
                        <th class="p-4 whitespace-normal" scope="row">Date Imported</th>
                        <th class="p-4 whitespace-normal" scope="row">
                            Status
                        </th>
                        <th class="p-4 whitespace-normal" scope="row">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody *ngFor="let importedVH of importedVHList; let i = index">
                    <tr class="border-b-2 border-fuchsia-600 p-4 relative">
                        <!-- Vault Holder Name & Email -->
                        <td class="p-2 lg:p-4">
                            <ng-container>
                                <div class="flex items-center no-wrap">
                                    <div class="p-4 whitespace-normal ">
                                        <a
                                            class="text-blue-600 text-semibold text-md lg:text-lg text-regular whitespace-normal">
                                            {{importedVH?.['user']?.['first_name']}} {{importedVH?.['user']?.['middle_name']}} {{importedVH?.['user']?.['last_name']}}</a>
                                        <p class="text-grey text-regular">{{importedVH?.['user']?.['email']}}
                                        </p>
                                    </div>
                                </div>
                            </ng-container>
                        </td>
                        <!-- Date Imported -->
                        <td class="p-4 whitespace-normal">
                            <p class="text-grey text-regular text-md">
                                {{ importedVH?.["created_at"] | date:dateFormat }}
                            </p>
                        </td>
                        <!-- Status -->
                        <td class="p-4 whitespace-normal">
                            <a *ngIf="importedVH['status'] === 1"
                                class="text-yellow-600  text-md lg:text-lg text-regular whitespace-normal">
                                Pending
                            </a>
                            <a *ngIf="importedVH['status'] === 2"
                                class="text-blue-600  text-md lg:text-lg text-regular whitespace-normal">
                                Approved
                            </a>
                        </td>
                        <!-- Actions -->
                        <td class="p-4 whitespace-normal">
                            <ng-container *ngIf="importedVH?.['response']">
                                <a *ngIf="importedVH['status'] === 1"
                                    (click)="copyToClipboard(importedVH?.['response'])"
                                    class="text-blue-600 text-semibold underline cursor-pointer hover:text-orange-600 text-md lg:text-lg text-regular whitespace-normal">
                                    Copy Link
                                </a>
                                <a *ngIf="importedVH['status'] === 2"
                                    class="text-blue-600 text-semibold text-md cursor-default lg:text-lg text-regular whitespace-normal">
                                    Registration Complete
                                </a>
                            </ng-container>
                            <ng-container *ngIf="!importedVH?.['response']">
                                <a
                                    class="text-blue-600 text-semibold text-md cursor-default lg:text-lg text-regular whitespace-normal">
                                    Email Sent
                                </a>
                            </ng-container>
                    </tr>
                </tbody>
            </table>
            <div class="flex justify-center mt-5 bg-card-overview border-card-grey rounded"
                *ngIf="importedVHList?.length === 0">
                <div class="my-4">
                    <h4 class="text-base text-regular tracking-wide text-semibold  text-center">
                        No Data to Display
                    </h4>
                </div>
            </div>
            <!-- Paginations -->
            <div class="pt-8" *ngIf="importedVHList?.length !== 0">
                <nav class="flex flex-row flex-nowrap justify-between md:justify-center items-center"
                    aria-label="Pagination">
                    <a *ngIf="currentPage > 1"
                        (click)="getImportedVaultHoldersList( currentPage > 1 ? currentPage-1 : 0 )"
                        class="flex w-7 h-7 mr-1 justify-center items-center text-black cursor-pointer "
                        title="Previous Page">
                        <span class="sr-only">Previous Page</span>
                        <img src="assets/images/dashboard/left-arrow-enable.png" alt="digital vault" />
                    </a>
                    <a *ngIf="currentPage === 1" class="flex w-7 h-7 mr-1 justify-center items-center text-black "
                        title="Previous Page">
                        <span class="sr-only">Previous Page</span>
                        <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                        <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
                    </a>
                    <ng-container *ngFor="let page of paginationLinks;let i = index;">
                        <a *ngIf=" i <= lastPage && i > 0 " (click)="getImportedVaultHoldersList(i)"
                            [ngClass]="{ 'bg-darkblue' : currentPage === i , 'text-white' : currentPage === i}"
                            class=" md:flex w-7 h-7 mx-1 justify-center items-center  text-black text-center cursor-pointer "
                            title="Page {{i}}">
                            {{i}}
                        </a>
                    </ng-container>
                    <a (click)="getImportedVaultHoldersList( currentPage + 1 )" *ngIf="(currentPage) <  lastPage"
                        class="flex w-7 h-7 ml-1 justify-center items-center text-black cursor-pointer "
                        title="Next Page">
                        <span class="sr-only">Next Page</span>
                        <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                        <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
                    </a>
                    <a *ngIf="(currentPage) ===  lastPage"
                        class="flex w-7 h-7 ml-1 justify-center items-center text-black " title="Next Page">
                        <span class="sr-only">Next Page</span>
                        <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                        <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
                    </a>
                </nav>
            </div>
        </div>
    </div>
</div>