import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CPCSettingsInterface } from 'src/app/sharedComponent/choose-people-component/choose-people-interfaces.';
import { LPEmitterInterface, LPSettingsInterface, LPUserPeopleInterface } from 'src/app/sharedComponent/list-people/list-people-interfaces';
import { ListPeopleComponent } from 'src/app/sharedComponent/list-people/list-people.component';

@Component({
  selector: 'app-assign-professional',
  templateUrl: './assign-professional.component.html',
  styleUrls: ['./assign-professional.component.css']
})
export class AssignProfessionalComponent implements OnInit {
  @Input() set editData(professionals: Array<string>) {
    console.log(professionals);
    if (professionals?.length > 0) {
      let data: Array<{ id: string }> = []
      professionals?.forEach(b => data.push({ id: b }));
      this.setProfessionals(data)
    }
  }
  @Input() set professionalsList(professionalsData: any) {
    this.professionalsRAW = professionalsData;
    this.updateLists();
  }

  @Output() selectProfessionalEmitter: EventEmitter<SelectProfessionalEmitModel> = new EventEmitter();
  // Select Professional fields
  private professionalsRAW: Array<Object>;
  private chosenProfessionals: ChosenProfessionalsModel = [];

  // Choose Professional Modal
  choooseProSettings = { professional_people: [] };
  choosePeopleModalID = 'choose-professional-modal';


  // List People Component
  lpSettings: LPSettingsInterface = {
    isLoading: false,
    delete: {
      show: true,
      tooltip: 'Remove',
      useIcon: 'faTrash',
    },
  };
  public cpcSettings: CPCSettingsInterface;
  professionalPeople: Array<LPUserPeopleInterface>;

  constructor(private modalService: CommonModelService) {
  }

  ngOnInit(): void {
    this.cpcSettings = {
      text: {
        header: 'Professional Management',
        sub_header: 'Select Existing Professionals from your organization',
        add_new: '',
        choose_people: 'Submit',
        checkbox: {
          text: '',
        },
      },
    };
  }

  /**
   * Prepare {@link choooseProSettings} type and user_people.
   * @private
   */
  private prepareProfessionalDetails() {
    const professionals = this.professionalsRAW?.filter(e => !this.chosenProfessionals?.find(b => b.id == e['id']));
    this.choooseProSettings.professional_people = ListPeopleComponent.getLPProfessionalPeople(professionals);
    this.choooseProSettings.professional_people?.forEach(e => {
      const chosen = this.chosenProfessionals?.find(c => c.id == e.id);
      if (chosen) {
        e.checkbox.isChecked = true;
      }
    });
    // Force change detection
    this.choooseProSettings = { ...this.choooseProSettings };
  }

  /**
   * Listener Function for {@link AssignProfessionalComponent}.
   * @param $event
   */
  public listenerAssignProfessionals($event: any) {
    if ($event.trigger_refresh) {
      this.updateLists();
    }
    if ($event.chosen_people) {
      this.setProfessionals($event.chosen_people);
      this.emitProfessionals();
    }
  }

  /**
   * Emit the chosen professionals data. This should happen anytime a change is made.
   * @private
   */
  private emitProfessionals() {
    let professionals: SelectProfessionalEmitModel = []
    this.chosenProfessionals?.forEach(chosen => {
      professionals.push({
        id: chosen.id,
      });
    });
    this.selectProfessionalEmitter.emit(professionals);
  }

  /**
   * Assign professionals info. Will call {@link updateLists}.
   * @param data
   */
  private setProfessionals(data: Array<{ id: string }>) {
    const professionals: Array<{ id: string }> = [];
    data.forEach(e => professionals.push({ id: e?.id }));
    this.chosenProfessionals = professionals;
    this.updateLists();
  }

  /**
   * Update display detail lists for professionals
   * @private
   */
  private updateLists() {
    this.professionalPeople = [];
    if (this.chosenProfessionals?.length > 0 && this.professionalsRAW?.length > 0) {
      this.chosenProfessionals?.forEach(chosen => {
        let person: any = this.professionalsRAW.find(person => person?.['value'] == chosen?.id);
        if (person) {
          person = ListPeopleComponent.getLPProfessionalPeople(person)[0];
          this.professionalPeople.push(person);
        }
      });
    }
    if (this.modalService.isOpen(this.choosePeopleModalID)) {
      this.prepareProfessionalDetails();
    }
  }

  /**
   * view primary percentage details
   */
  public viewProfessionalDetails() {
    this.prepareProfessionalDetails();
    this.modalService.open(this.choosePeopleModalID);
  }

  /**
   * Retrieve click events from list-people
   * @param {LPEmitterInterface} event
   */
  public clickListener(event: LPEmitterInterface) {
    const person = this.professionalsRAW.find((e) => e['value'] == event.id);
    if (event.clickedDelete) { // Remove Button
      this.chosenProfessionals = this.chosenProfessionals.filter(chosenPro => chosenPro.id !== person['value'])
      this.emitProfessionals();
    } else {
      // Do nothing, return
      return;
    }
    // Update display data
    this.updateLists();
  }
}




export interface ChosenProfessionalEntry {
  id?: string;
}

type ChosenProfessionalsModel = Array<ChosenProfessionalEntry>;


export type SelectProfessionalEmitModel = Array<ChosenProfessionalEntry>;

