<div class="scroll-hidden background-color-grey">

  <!-- Navbar -->
  <ng-container *ngIf="showNavBar">
    <app-navbar *ngIf="loginType === login_Type.DIGITAL_VAULT"></app-navbar>
    <app-exe-cus-navbar *ngIf="loginType === login_Type.CUSTODIAN"></app-exe-cus-navbar>
  </ng-container>

  <div class="lg:grid grid-cols-12 px-12 gap-6">
    <!-- BEGIN: Leftside Content -->
    <div class="col-span-12 sm:p-6 lg:pr-0">
      <div class="w-full">
        <h2 class="text-black text-xl lg:text-2xl xl:text-4xl uppercase text-semibold pt-1">Manage Cards</h2>
        <p class="text-md text-grey py-3 tracking-wide text-regular"> Select your Cards to make it default to avoid
          cancellation</p>
        <p class="border-b-8 w-24 border-red mb-3"></p>
      </div>
    </div>
    <!--start first column-->

    <div *ngFor="let card of cards" class="col-span-4 mb-8 px-4 flex flex-col h-full">
      <div class="h-full">
        <div class="master-card-payment px-6 p-4 rounded-md flex flex-col h-full">
          <div class="flex justify-between items-center">
            <div class="items-center">
              <img *ngIf="getCardBrandLogo(card['card_brand']); else card_brand_text"
                   [src]="getCardBrandLogo(card['card_brand'])"
                   class="h-4"
                   alt="broken_link">
              <ng-template #card_brand_text>
                <h3 class="text-lg text-white font-normal text-semibold">
                  {{ card['card_brand'] | titlecase }}
                </h3>
              </ng-template>
            </div>
            <div>
              <div class="flex relative ml-4 lg:ml-10">
                <p *ngIf="card.is_default == 1" class="bg-white p-1 px-3 rounded-md text-xs cardblue font-normal">
                  Default</p>
                <img *ngIf="card.is_default != 1 && !addCardLoading" src="assets/images/payment/dots.svg"
                     (click)="setMenuOpen(card.id)"
                     class="cursor-pointer" alt="Payments Images">
                <ng-container *ngIf="menusection == card.id">
                  <div *ngIf="card.is_default != 1" #menuList role="menu" aria-orientation="vertical"
                       aria-labelledby="user-menu"
                       class="origin-top-right absolute right_20 mt-10 w-24 rounded-md shadow-lg py-1 bg-white focus:outline-none z-50 ng-star-inserted">
                    <ul>
                      <a *ngIf="card.is_default != 1" (click)="makeDefault(card.id)" role="menuitem"
                         class="block px-4 py-2 text-sm text-grey text-regular flex items-center cursor-pointer hover:bg-gray-100"><img
                        src="assets/images/payment/setdefault.svg" class="w-3 mr-2"
                        alt="Payment Setdefault">Default</a>

                      <a *ngIf="card.is_default != 1" (click)="deleteCard(card.id)" role="menuitem"
                         class="block px-4 py-2 text-sm text-grey text-regular flex items-center cursor-pointer hover:bg-gray-100"><img
                        src="assets/images/payment/delete-icon.svg" class="w-3 mr-2" alt="Payment Delete">Delete</a>
                    </ul>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="w-full mb-4">
            <h3 class="text-lg text-white font-normal">XXXX XXXX XXXX {{ card.card_last_four }}</h3>
          </div>
          <div *ngIf="card?.zipcode">
            <h4 class="text-sm text-white font-thin">Zipcode </h4>
            <h5 class="text-md text-white font-medium">{{ card.zipcode }}</h5>
          </div>
          <div class="flex items-center justify-between mt-auto mb-2">
            <div>
              <h4 class="text-sm text-white font-thin">Card holder Name </h4>
              <h5 class="text-md text-white font-medium">{{ card.name }}</h5>
            </div>
            <div>
              <h4 class="text-sm text-white font-thin">Exp. Date </h4>
              <h5 class="text-md text-white font-medium">{{ card.exp_date }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-4 mb-8 px-4 flex flex-col h-full">
      <div class="add-new-card px-6 p-4 rounded-md py-20 flex flex-col justify-center items-center h-full">
        <div class="flex items-center justify-center cursor-pointer" (click)="addnewcard()">
          <img src="assets/images/payment/addplus.svg" class="mr-8" alt="Payment Addimage">
          <h5 class="text-xl text-white font-medium"> Add New Card</h5>
        </div>
      </div>
    </div>
    <!--end-->
  </div>


</div>

<app-common-modal id="Add-credit-debit-card" class="app-common-modal">
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
         (click)="closeModel()">
    <!--heading-->
    <div class="mb-4">
      <h3 class="text-darkblue text-md lg:text-xl text-normal">Add New Card</h3>
      <p class="text-md text-grey py-1 tracking-wide text-regular">Add card to make payment without delay</p>
    </div>
    <!--end---->
    <form [formGroup]="addCardForm" (ngSubmit)="getcardToken()" autocomplete="off">
      <div class="grid grid-cols-12 gap-4 md:gap-6">
        <div class="col-span-12 relative">
          <label for="card_number" class="text-label-grey text-sm text-regular mb-2">Card Number</label>
          <input formControlName="card_number" mask="0*" type="text" id="card_number"
                 placeholder="Debit or Credit Card Number"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 relative"
                 [ngClass]="formGet.card_number.errors && formGet.card_number.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('card_number')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>

        <div class="col-span-12 lg:col-span-4">
          <label for="exp_month" class="text-label-grey text-sm text-regular mb-2">Expiry Month</label>
          <input formControlName="exp_month" mask="00" maxlength="2" max="12" type="text" id="exp_month"
                 placeholder="MM"
                 class="h-10 sm:h-12 input py-4 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 [ngClass]="formGet.exp_month.errors && formGet.exp_month.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('exp_month')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
        <div class="col-span-12 lg:col-span-4">
          <label for="exp_year" class="text-label-grey text-sm text-regular mb-2">Expiry Year</label>
          <input formControlName="exp_year" maxlength="4" [mask]="this.yearMask" type="text" id="exp_year"
                 placeholder="YYYY"
                 class="h-10 sm:h-12 input py-4 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 [ngClass]="formGet.exp_year.errors && formGet.exp_year.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('exp_year')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
        <div class="col-span-12 lg:col-span-4">
          <label for="cvv" class="text-label-grey text-sm text-regular mb-2">Security Code</label>
          <input formControlName="cvv" type="text" maxlength="4" id="cvv" placeholder="Security Code"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 mask="0*" [ngClass]="formGet.cvv.errors && formGet.cvv.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('cvv')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
        <div class="col-span-12 lg:col-span-8">
          <label for="card_name" class="text-label-grey text-sm text-regular mb-2">Card Holder Name</label>
          <input formControlName="card_name" maxlength="50" type="text" name="card_name" id="card_name"
                 placeholder="Card Holder Name"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 [ngClass]="formGet.card_name.errors && formGet.card_name.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('card_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
        <div class="col-span-12 lg:col-span-4">
          <label for="zipcode" class=" text-label-grey text-sm text-regular">Zip/Postal Code</label>
          <input type="text"
                 class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 placeholder="Enter Zipcode" name="zipcode" id="zipcode" formControlName="zipcode"
                 oninput="value = value.toUpperCase()"
                 [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('zipcode')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
      </div>
      <div class="w-full my-5">
        <div class="flex flex-col-reverse md:flex-row gap-4 items-center justify-between w-full">
          <a
            class="flex items-center justify-center w-full px-8 py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn-one text-center cursor-pointer"
            (click)="cancelnewcard()">Cancel</a>
          <button type="submit" [disabled]="addCardForm.invalid || addCardLoading"
                  [ngClass]="{'opacity-50': addCardForm.invalid}"
                  class="flex items-center justify-center w-full px-8 py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn text-center cursor-pointer ">
            {{ addCardLoading ? 'Loading...' : 'Add Card' }}
          </button>
        </div>
      </div>
    </form>
  </div>
</app-common-modal>

<app-common-modal id="delete-old-card" class="app-common-modal">
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
         (click)="closeDeleteModel()">
    <!--heading-->
    <div class="mb-4">
      <h3 class="text-darkblue text-md lg:text-xl text-normal">Manage Cards
      </h3>
      <p class="text-md text-grey py-1 tracking-wide text-regular">It seems you're adding a new card. To keep things
        organized, please choose which of your old cards you'd like to remove.
        Please select the card you wish to delete.</p>
    </div>


    <ng-container *ngFor="let card of cards;let i = index">
      <ng-container *ngIf="!toggleSectionBody">
        <div class="w-full my-3">
          <div class="flex flex-col sm:flex-row gap-3 sm:items-center justify-between">
            <div class="sm:flex">
              <div class="flex items-center gap-3">
                <label>
                  <input type="radio" name="radio" (change)="selectedCard(card)"
                         [id]="card.id" [value]="card.id" class="form-checkbox w-4 h-4">
                </label>
                <img *ngIf="getCardBrandLogo(card['card_brand'],'blue'); else card_brand_text"
                   [src]="getCardBrandLogo(card['card_brand'],'blue')"
                   class="h-4"
                   alt="broken_link">
                   <ng-template #card_brand_text>
                    <h3 class="text-sm text-darkblue font-normal text-semibold">
                      {{ card['card_brand'] | titlecase }}
                    </h3>
                  </ng-template>
                  <br>
              </div>
              <p class="text-regular text-black font-normal">XXXX XXXX XXXX {{ card['card_last_four'] }}</p>
            </div>

            <div class="mr-3">
              <p>{{ card['exp_date'] }} </p>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <app-button-loader [buttonText]="'Remove Card'" [valid]="true" [loader]="submitLoader" [buttonType]="'button'"
                       (click)="deleteCards()" [outlineOnly]="true"></app-button-loader>
  </div>
  <!--end---->
</app-common-modal>
