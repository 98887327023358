import { Component } from '@angular/core';

@Component({
  selector: 'app-right-carousel-intro',
  templateUrl: './right-carousel-intro.component.html',
})
/**
 * Right carousel
 */
export class RightCarouselIntroComponent {

}
