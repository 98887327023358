<app-common-modal id="view-profile-modal" class="app-common-modal">
  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">
    <div class="p-6">
      <div class="flex items-center justify-start mb-3">
        <div><img src="assets/images/social-icons/information.svg" alt="social-icons"> </div>
        <p class="text-gray-400 text-regular md:text-base text-base pl-3"> This is how you will represented to
          your Clients </p>
      </div>
      <div class="flex items-center justify-start flex-wrap mb-3">
        <img src="assets/images/insurance/insurance-block-image.png" class="w-6 h-6 sm:w-8 sm:h-8 lg:h-10 lg:w-10 rounded-full"
          alt="pro-img">
        <div class="pl-4">
          <p class="text-grey-700 text-regular text-lg ">Michale B Jordan</p>
          <p class="text-gray-400 text-regular text-lg">michalejordan@gmail.com</p>
        </div>
      </div>
      <div class="flex mb-4">
        <p class="text-gray-400 text-regular text-base">I Spent over 20 Years helping high net worth families
          grow,<br>
          Retain, and disturb their assets. My Primary objective is to<br>
          ensure my clients can focus on enjoying their lives while<br>
          I worry about the finances.</p>
      </div>
      <div class="flex items-center justify-start mb-6">
        <a href="#" class="pr-3" target="_blank"><img src="assets/images/social-icons/fb.svg" alt="social icons"></a>
        <a href="#" class="pr-3" target="_blank"><img src="assets/images/social-icons/tweet.svg" alt="social icons"></a>
        <a href="#" class="pr-3" target="_blank"><img src="assets/images/social-icons/insta.svg" alt="social icons"></a>
        <a href="#" class="pr-3" target="_blank"><img src="assets/images/social-icons/linkedin.svg"
            alt="social icons"></a>
      </div>
      <div class="border-t-2 border-grey-600 ">
        <div class="flex items-center justify-between mt-4">
          <div>
            <p class="text-gray-400 text-regular text-sm flex"><img src="assets/images/social-icons/locate.svg"
                alt="social-icons"><span class="pl-2">Locations</span> </p>
            <p class="text-gray-500 text-regular text-sm">paso robles, california</p>
          </div>
          <div>
            <p class="text-gray-400 text-regular text-sm flex"><img src="assets/images/social-icons/phone.svg"
                alt="social-icons"> <span class="pl-2">Phone</span></p>
            <p class="text-gray-500 text-regular text-sm">+1 950 793 2441</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
</app-common-modal>
