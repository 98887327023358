import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIResponseModel } from '../interface/response.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * Property services
 */
export class PropertyService {
  private BASE_URL:string;
  private resetPropertyForm: any;
  public resetPropertyFormObserve: Observable<any>;
  /**
   * @constructor
   */
  constructor(
    private http: HttpClient,
  ) {
    this.BASE_URL = environment.BASE_URL;

    // for resetting property form
    this.resetPropertyForm = new BehaviorSubject(false);
    this.resetPropertyFormObserve = this.resetPropertyForm.asObservable();
  }

  /**
   * Clear reset property form
   *
   * @param {boolean} trigger
   */
  public resetPropertyFormTrigger(trigger: boolean): void {
    this.resetPropertyForm.next(trigger);
  }

  /**
   * Add property
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public addProperty(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-property-details`, data);
  }

  /**
   * Add Business
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public addBusiness(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-business-details`, data);
  }

  /**
   * Add Valuable
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public addValuable(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-valuable-details`, data);
  }
  /**
   * Add Social Media
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public addSocialMedia(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-social-media`, data);
  }
  /**
   * skip social media details
   *
   * @param {string} requestID
   * @return {Observable<APIResponseModel>}
   */
  public skipSocialMediaDetails(requestID: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/skip-social-media?`, requestID);
  }
  /**
   * Add Subscription
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public addSubscription(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-property-subscriptions`, data);
  }
  /**
   * skip property subscription details
   *
   * @param {string} requestID
   * @return {Observable<APIResponseModel>}
   */
  public skipPropertySubscriptionDetails(requestID: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/skip-property-subscriptions?`, requestID);
  }
  /**
   * Add donation
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public addDonation(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-donations`, data);
  }
  /**
   * skip donation details
   *
   * @param {string} requestID
   * @return {Observable<APIResponseModel>}
   */
  public skipDonationDetails(requestID: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/skip-donations?`, requestID);
  }
  /**
   * Add prescription
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public addPrescription(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-prescriptions`, data);
  }
  /**
   * skip prescription details
   *
   * @param {string} requestID
   * @return {Observable<APIResponseModel>}
   */
  public skipPrescriptionDetails(requestID: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/skip-prescriptions?`, requestID);
  }
  /**
   * Add government id
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public addGovernmentID(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/store-government-ids`, data);
  }
  /**
   * skip government id  details
   *
   * @param {string} requestID
   * @return {Observable<APIResponseModel>}
   */
  public skipGovernmentIdDetails(requestID: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/skip-government-ids?`, requestID);
  }
}
