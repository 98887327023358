<app-common-modal id="{{modalID}}" class="app-common-modal" [heightClass]="'min-h-75'">
  <img src="assets/images/about-yourself/close.svg"
    class="absolute right-2 w-8 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
    (click)="closeModal()">
  <div class="mb-4">
    <div>
      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
        Liabilities Details
      </h4>
      <p class="text-grey text-regular pt-1 text-sm">Start by adding your Liabilities.
      </p>
      <p class="text-regular font-medium text-xs mt-2 error-alert"
        *ngIf="(isNewAsset && !permissions?.['add']) || (!isNewAsset && !permissions?.['edit'])">
        You do not have permission to
        {{isNewAsset ? 'add information for this client.' : 'edit this client\'s information.'}}
      </p>
    </div>
  </div>
  <div class="col-span-12 relative" (click)="canAccessForm ? markAsLiabilityTouched() : ''">
    <label for="liability-type" class="text-label-grey text-sm text-regular">Liability Type*</label>
    <app-shimmer-loading [loading]="liabilitiesTypeLoader" class="w-full h-12">
      <app-select-with-search id="liability-type" [options]="liabilitiesTypeArray"
        [disabled]="!canEdit || lockTypeDropdown || !canAccessForm" [placeholderValue]="'Select liability type'"
        (optionChangeEmitter)="optionChangeEmitterLiabilityType($event)" [defaultSelected]="liabilityTypeId"
        [ifError]="liabilityTypeTouched && !liabilityTypeId" tabindex="0"
        (focusout)="canAccessForm ? markAsLiabilityTouched() : ''">
      </app-select-with-search>
    </app-shimmer-loading>
    <div *ngIf="liabilityTypeTouched && !liabilityTypeId && canEdit">
      <p class="text-regular font-medium text-xs mt-2 error-alert">Liability type is required
      </p>
    </div>
  </div>
  <form *ngIf="liabilityType === LIABILITY_TYPE.C" [formGroup]="forms.creditCard" autocomplete="off"
    (ngSubmit)="onSubmit()">
    <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
      <div class="relative {{useOtherCCType ? 'col-span-12 md:col-span-6' : 'col-span-12'}}">
        <label for="card_type_id" class="text-label-grey text-sm text-regular">Credit Card type*</label>
        <app-select-with-search id="card_type_id" [options]="creditCardTypeArray"
          [placeholderValue]="'Select credit card type'" [disabled]="!canEdit || !canAccessForm"
          (optionChangeEmitter)="optionChangeEmitterCreditCardType($event)"
          [defaultSelected]="forms.creditCard.get('card_type_id').value"
          [ifError]="formGet['card_type_id'].errors && formGet['card_type_id'].touched">
        </app-select-with-search>
        <div *ngIf="formGet['card_type_id'].errors && formGet['card_type_id'].touched">
          <p class="text-regular font-medium text-xs mt-2 error-alert">Credit Card type is required
          </p>
        </div>
      </div>
      <div *ngIf="useOtherCCType" class="col-span-12 md:col-span-6">
        <label for="other_type" class="text-label-grey text-sm text-regular">Other Type</label>
        <input [attr.disabled]="!canEdit || undefined" type="text"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="Other Type" formControlName="other_type" name="other_type" id="other_type"
          [ngClass]="formGet['other_type'].errors && formGet['other_type'].touched?'form-input-error':'form-input-focus'">
        <p *ngFor="let error of getErrors('other_type')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
        </p>
      </div>
      <div class="col-span-12">
        <label for="loan_amount_cc" class="text-label-grey text-sm text-regular">Amount*</label>
        <div class="relative flex items-center">
          <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
          <input [attr.disabled]="!canEdit || undefined" type="text" appCurrencyInput
            class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 price-input"
            name="loan_amount_cc" id="loan_amount_cc" formControlName="amount" placeholder="e.g 1000"
            [ngClass]="formGet?.amount.errors && formGet?.amount.touched ? 'form-input-error' : 'form-input-focus'">
        </div>
        <p *ngFor="let error of getErrors('amount')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
        </p>
      </div>
      <div class="col-span-12 md:col-span-6">
        <label for="card_name" class="text-label-grey text-sm text-regular">Card Name*</label>
        <input [attr.disabled]="!canEdit || undefined" type="text"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="Full Name" formControlName="card_name" name="card_name" id="card_name"
          [ngClass]="formGet['card_name'].errors && formGet['card_name'].touched?'form-input-error':'form-input-focus'">
        <p *ngFor="let error of getErrors('card_name')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
        </p>
      </div>
      <div class="col-span-12 md:col-span-6">
        <label for="card_number" class="text-label-grey text-sm text-regular">Card Number*</label>
        <input [attr.disabled]="!canEdit || undefined" type="text" placeholder="Debit or Credit Card Number"
          [dropSpecialCharacters]="false" mask="0*"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          formControlName="card_number" name="card_number" id="card_number"
          [ngClass]="formGet['card_number'].errors && formGet['card_number'].touched?'form-input-error':'form-input-focus'">
        <p *ngFor="let error of getErrors('card_number')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
        </p>
      </div>

      <div class="col-span-12 md:col-span-3 ">
        <label for="exp_month" class="text-label-grey text-sm text-regular">Expiry Month*</label>
        <input [attr.disabled]="!canEdit || undefined" type="text" mask="0*" minlength="1" maxlength="2" max="12"
          min="1" formControlName="exp_month" name="exp_month" id="exp_month" placeholder="MM"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          [ngClass]="formGet['exp_month'].errors && formGet['exp_month'].touched?'form-input-error':'form-input-focus'">
        <p *ngFor="let error of getErrors('exp_month')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
        </p>
      </div>

      <div class="col-span-12 md:col-span-3">
        <label for="exp_year" class="text-label-grey text-sm text-regular">Expiry Year*</label>
        <input [attr.disabled]="!canEdit || undefined" type="text" maxlength="4" [mask]="this.yearMask"
          formControlName="exp_year" name="exp_year" id="exp_year" placeholder="YYYY "
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          [ngClass]="formGet['exp_year'].errors && formGet['exp_year'].touched?'form-input-error':'form-input-focus'">
        <p *ngFor="let error of getErrors('exp_year')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
        </p>
      </div>
      <div class="col-span-12 md:col-span-6">
        <label for="card_issuer" class="text-label-grey text-sm text-regular">Card Issuer*</label>
        <input [attr.disabled]="!canEdit || undefined" type="text"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          name="card_issuer" id="card_issuer" formControlName="card_issuer" placeholder="e.g Bank of America"
          [ngClass]="formGet['card_issuer'].errors && formGet['card_issuer'].touched?'form-input-error':'form-input-focus'" />
        <p *ngFor="let error of getErrors('card_issuer')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
        </p>
      </div>
    </div>
    <div class="col-span-12 mt-3">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12">
          <div class="flex flex-row items-baseline">
            <div>
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Web Access
              </h4>
              <p class="text-grey text-regular pt-1 text-sm">
                Online access information for this liability.
              </p>
            </div>
          </div>
        </div>
        <div class="col-span-12">
          <label for="website" class="text-label-grey text-sm text-regular">Web URL</label>
          <div class="relative">
            <input [attr.disabled]="!canEdit || undefined" type="url"
              class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
              placeholder="www.mybank.com" formControlName="website" id="website"
              [ngClass]="formGet?.website.errors && formGet?.website.touched?'form-input-error':'form-input-focus'">
            <p *ngFor="let error of getErrors('website')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <label for="user_name" class="text-label-grey text-sm text-regular">Username</label>
          <input [attr.disabled]="!canEdit || undefined" type="text"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="e.g. Marktylor_54-" formControlName="user_name" name="user_name" id="user_name"
            [ngClass]="formGet?.['user_name'].errors && formGet?.['user_name'].touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('user_name')" class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 md:col-span-6">
          <label for="password" class="text-label-grey text-sm text-regular">Password</label>
          <div class="relative">
            <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
              (click)="togglePasswordView = !togglePasswordView">
              <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
              <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
            </a>
            <input [attr.disabled]="!canEdit || undefined" [type]="togglePasswordView?'text':'password'"
              class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
              placeholder="e.g. JOHy56-89_" formControlName="password" name="password" id="password"
              [ngClass]="formGet?.password.errors && formGet?.password.touched?'form-input-error':'form-input-focus'">
            <p *ngFor="let error of getErrors('password')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </div>
      </div>
    </div>


    <div class="flex flex-col-reverse sm:flex-row justify-between items-center w-full gap-4 mt-8" *ngIf="liabilityType">
      <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewAsset" [buttonText]="'Remove Details'"
        [valid]="!submitLoader" [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true"
        (click)="deleteLiabilities()"></app-button-loader>
      <app-button-loader class="w-full sm:w-auto" [buttonText]="!isNewAsset?'Update Details':'Save Details'"
        [valid]="isSubmitValid() && !submitLoaderDelete" [loader]=" submitLoader" [buttonType]="'submit'">
      </app-button-loader>
    </div>
  </form>
  <form *ngIf="liabilityType === LIABILITY_TYPE.L" [formGroup]="forms.otherLiability" autocomplete="off"
    (ngSubmit)="onSubmit()">
    <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
      <div class="col-span-12">
        <label for="loan_name" class="text-label-grey text-sm text-regular">Loan Name*</label>
        <input type="text" [attr.disabled]="!canEdit || undefined"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="Loan Name" formControlName="loan_name" name="loan_name" id="loan_name"
          [ngClass]="formGet['loan_name'].errors && formGet['loan_name'].touched?'form-input-error':'form-input-focus'">
        <p *ngFor="let error of getErrors('loan_name')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
        </p>
      </div>
      <div *ngIf="useOther" class="col-span-12">
        <label for="loan_type_other" class="text-label-grey text-sm text-regular">Define Other*</label>
        <input [attr.disabled]="!canEdit || undefined" type="text"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="Define Other" formControlName="loan_type_other" name="loan_type_other" id="loan_type_other"
          [ngClass]="formGet['loan_type_other'].errors && formGet['loan_type_other'].touched?'form-input-error':'form-input-focus'">
        <p *ngFor="let error of getErrors('loan_type_other')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
        </p>
      </div>
      <div class="col-span-12 md:col-span-6">
        <label for="account_number" class="text-label-grey text-sm text-regular">Account Number*</label>
        <input [attr.disabled]="!canEdit || undefined" type="text"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="e.g. 00A12BC4567X9" formControlName="account_number" name="account_number" id="account_number"
          [ngClass]="formGet['account_number'].errors && formGet['account_number'].touched?'form-input-error':'form-input-focus'">
        <p *ngFor="let error of getErrors('account_number')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
        </p>
      </div>
      <div class="col-span-12 md:col-span-6">
        <label for="v_account_number" class="text-label-grey text-sm text-regular">Verify Account Number*</label>
        <input [attr.disabled]="!canEdit || undefined" type="text"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="e.g. 00A12BC4567X9" formControlName="v_account_number" name="v_account_number"
          id="v_account_number"
          [ngClass]="formGet?.['v_account_number'].touched && (formGet?.['account_number'].value && !isVerified('account_number'))?'form-input-error':'form-input-focus'">
        <div
          *ngIf="!isVerified('account_number') && formGet?.['account_number'].valid && formGet?.['account_number'].value">
          <p class="text-regular font-medium text-xs mt-2 error-alert">
            Account Numbers must match.
          </p>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6">
        <label for="financial_institution" class="text-label-grey text-sm text-regular">Financial
          Institution*</label>
        <input [attr.disabled]="!canEdit || undefined" type="text"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="e.g. Chase Bank" formControlName="financial_institution" name="financial_institution"
          id="financial_institution"
          [ngClass]="formGet['financial_institution'].errors && formGet['financial_institution'].touched?'form-input-error':'form-input-focus'">
        <p *ngFor="let error of getErrors('financial_institution')"
          class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
        </p>
      </div>
      <div class="col-span-12 md:col-span-6">
        <label for="loan_amount" class="text-label-grey text-sm text-regular">Loan Amount*</label>
        <div class="relative flex items-center">
          <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
          <input [attr.disabled]="!canEdit || undefined" type="text" appCurrencyInput
            class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 price-input"
            name="loan_amount" id="loan_amount" formControlName="loan_amount" placeholder="e.g 1000"
            [ngClass]="formGet['loan_amount'].errors && formGet['loan_amount'].touched?'form-input-error':'form-input-focus'">
        </div>
        <p *ngFor="let error of getErrors('loan_amount')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
        </p>
      </div>
      <div class="col-span-12">
        <label for="notes" class="text-label-grey text-sm text-regular">Description / Notes</label>
        <textarea rows="4" cols="50" [attr.disabled]="!canEdit || undefined"
          class="input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
          name="notes" id="notes" formControlName="notes" [placeholder]="getDescriptionPlaceholder"
          [ngClass]="formGet['notes'].errors && formGet['notes'].touched?'form-input-error':'form-input-focus'">
          </textarea>
        <p *ngFor="let error of getErrors('notes')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{error}}
        </p>
      </div>
    </div>

    <div class="col-span-12 mt-3 ">
      <div class="grid grid-cols-12 gap-4">

        <div class="col-span-12">
          <div class="flex flex-row items-baseline">
            <div>
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Web Access
              </h4>
              <p class="text-grey text-regular pt-1 text-sm">
                Online access information for this liability.
              </p>
            </div>
          </div>
        </div>
        <div class="col-span-12">
          <label class="text-label-grey text-sm text-regular">Web URL</label>
          <div class="relative">
            <input [attr.disabled]="!canEdit || undefined" type="url"
              class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
              placeholder="www.mybank.com" formControlName="website"
              [ngClass]="formGet?.website.errors && formGet?.website.touched?'form-input-error':'form-input-focus'">
            <p *ngFor="let error of getErrors('website')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <label class="text-label-grey text-sm text-regular">Username</label>
          <input [attr.disabled]="!canEdit || undefined" type="text"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="e.g. Marktylor_54-" formControlName="user_name" name="user_name"
            [ngClass]="formGet?.['user_name'].errors && formGet?.['user_name'].touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('user_name')" class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 md:col-span-6">
          <label class="text-label-grey text-sm text-regular">Password</label>
          <div class="relative">
            <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
              (click)="togglePasswordView = !togglePasswordView">
              <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
              <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
            </a>
            <input [attr.disabled]="!canEdit || undefined" [type]="togglePasswordView?'text':'password'"
              class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
              placeholder="e.g. JOHy56-89_" formControlName="password" name="password"
              [ngClass]="formGet?.password.errors && formGet?.password.touched?'form-input-error':'form-input-focus'">
            <p *ngFor="let error of getErrors('password')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </div>
      </div>

    </div>


    <div class="flex flex-col-reverse sm:flex-row justify-between items-center w-full gap-4 mt-8" *ngIf="liabilityType">
      <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewAsset" [buttonText]="'Remove Details'"
        [valid]="!submitLoader" [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true"
        (click)="deleteLiabilities()"></app-button-loader>
      <app-button-loader class="w-full sm:w-auto" [buttonText]="!isNewAsset?'Update Details':'Save Details'"
        [valid]="isVerified('account_number') && isSubmitValid() && !submitLoaderDelete" [loader]=" submitLoader"
        [buttonType]="'submit'">
      </app-button-loader>
    </div>
  </form>
</app-common-modal>
