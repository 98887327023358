export const MonthData =[
    {
    title: 'quarterly',
    items:[{
        id:1,
        month:"jan",
        first: 4,
        second: 7,
        third:10
    },
    {
        id:2,
        month:"feb",
        first: 5,
        second: 8,
        third: 11
    },
    {
        id:3,
        month:"mar",
        first: 6,
        second: 9,
        third: 12
    },
    {
        id:4,
        month:"apr",
        first: 7,
        second: 10,
        third: 1
    },
    {
        id:5,
        month:"may",
        first: 8,
        second: 11,
        third: 2
    },
    {
        id:6,
        month:"jun",
        first: 9,
        second: 12,
        third: 3
    },
    {
        id:7,
        month:"jul",
        first: 10,
        second: 1,
        third: 4
    },
    {
        id:8,
        month:"aug",
        first: 11,
        second: 2,
        third: 5
    },
    {
        id:9,
        month:"sep",
        first: 12,
        second: 3,
        third: 6
    },
    {
        id:10,
        month:"oct",
        first: 1,
        second: 4,
        third: 7
    },
    {
        id:11,
        month:"nov",
        first: 2,
        second: 5,
        third: 8
    },
    {
        id:12,
        month:"dec",
        first: 3,
        second: 6,
        third: 9
    }]
}
]