<app-common-modal id="add-advisor-modal" class="app-common-modal" [heightClass]="''">
  <div class="w-full relative rounded-md">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8  -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">
    <div class="mb-4">
      <div class="flex flex-row items-baseline">
        <div>
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide" *ngIf="dynamicText">
            {{dynamicText}} Details
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">Your {{dynamicText}}s will help you to manage your vault.
          </p>
        </div>
      </div>
    </div>
    <div class="bg-transparent border-card-grey rounded mt-4" *ngIf="peopleLoader">
      <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
        <div class="col-span-12">
          <h4 class="text-label-grey text-sm tracking-wide text-regular">Advisor Type
          </h4>
          <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
        </div>

        <div class="col-span-12">
          <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
          </h4>
          <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
        </div>

        <div class="col-span-12 md:col-span-6 ">
          <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
          </h4>
          <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
        </div>
        <div class="col-span-12 md:col-span-6">
          <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
          </h4>
          <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
        </div>
        <div class="col-span-12 md:col-span-6">
          <h4 class="text-label-grey text-sm tracking-wide text-regular">Address
          </h4>
          <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
        </div>
        <div class="col-span-12 md:col-span-6">
          <h4 class="text-label-grey text-sm tracking-wide text-regular">Country
          </h4>
          <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
        </div>
        <div class="col-span-12 md:col-span-6">
          <h4 class="text-label-grey text-sm tracking-wide text-regular">State
          </h4>
          <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
        </div>
        <div class="col-span-12 md:col-span-6">
          <h4 class="text-label-grey text-sm tracking-wide text-regular">City
          </h4>
          <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
        </div>
        <div class="col-span-12 md:col-span-6">
          <h4 class="text-label-grey text-sm tracking-wide text-regular">Zip Code
          </h4>
          <app-shimmer-loading [loading]="peopleLoader" [width]="'100%'"></app-shimmer-loading>
        </div>


      </div>
    </div>

    <ng-container>

      <ng-container>
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-0 " *ngIf="!peopleLoader">

          <div class="bg-card-overview border-card-grey rounded mt-4">
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">

              <div class="col-span-12">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Advisor Type
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['professional_type']?.['name']}}</p>

              </div>
              <div class="col-span-12">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">{{data?.['first_name']}}
                  {{data?.['last_name']}}
                </p>
              </div>

              <div class="col-span-12 md:col-span-6 ">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">+{{data?.['country_code']}}
                  {{data?.phone}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
                </h4>
                <p class="text-darkgrey break-all text-base text-regular tracking-wide">
                  {{data?.email}}</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Address
                </h4>
                <p class="text-darkgrey break-all text-base text-regular tracking-wide">
                  {{data?.address}}</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Country
                </h4>
                <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize">
                  {{data?.country?.name }}</p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="selectedCountryName!==unitedKingdom">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">State
                </h4>
                <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize">
                  {{data?.state?.name}}</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">City
                </h4>
                <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize">
                  {{data?.city?.name}}</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">Zip Code
                </h4>
                <p class="text-darkgrey break-all text-base text-regular tracking-wide">
                  {{data?.['zipcode']}}</p>
              </div>

            </div>
          </div>
        </div>
        <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
          <app-button-loader *ngIf="!showAddButton" [buttonText]="'Remove '+dynamicText" [valid]="true"
            [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true" (click)="deleteAdvisor()">
          </app-button-loader>

          <app-button-loader *ngIf="showAddButton" [buttonText]="'Add '+dynamicText" [valid]="true"
            (click)="addAdvisor()" [loader]="submitLoader" [buttonType]="'submit'">
          </app-button-loader>
        </div>

      </ng-container>


    </ng-container>

  </div>
</app-common-modal>
