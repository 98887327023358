import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { ExecutorCustodianService } from 'src/app/executor-custodian/services/executor-custodian.service';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { ICountryCode, SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { DlbService } from 'src/app/services/dlb.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import {
  EMAIL_PATTERN,
  LOCAL_STORAGE_UPDATE_STATUS,
  NAME_PATTERN,
  PHONE_PATTERN,
  SSN_MASK,
  SSN_PATTERN,
  UNITED_KINGDOM,
  USER_TYPES,
} from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../helper/validation.helper';

@Component({
  selector: 'app-check-basic-details',
  templateUrl: './check-basic-details.component.html',

})
/**
 * whether basic details is completed or not
 */
export class CheckBasicDetailsComponent implements OnInit {
  @Input() phoneCountryCode: any;
  @Input() dlbStatus: string;
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;

  public firstName: string;
  public lastName: string;
  public basicDetailsForm: FormGroup;
  public submitLoader: boolean;
  public preDefinedLoader: boolean;
  public sectionSaveExitOptions: any;
  public currentDateRestriction: string;
  public SSN_MASK: string;
  private currentUserDetails: any;
  public userType: string;
  public requestId: string;
  public userEmail: string;
  public countryCode: ICountryCode; //  in future should be embeded with form itself
  public userMail: string;
  public clearSelectedState: boolean;
  public clearSelectedCity: boolean;
  public locale = 'en';
  public maxDate: Date;
  public bsConfig: Partial<BsDatepickerConfig>;
  public showSSN: boolean;
  public defaultCountry: number;
  public selectedCountry: number;
  public faEye: any;
  public faEyeSlash: any;
  public toggleSSNView: boolean;
  public selectedCountryName: string = '';
  public unitedKingdom: string = UNITED_KINGDOM;
  public domainDateFormat = this.commonHelper.domainDateFormat;
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;


  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private personalDetailsService: PersonalDetailsService,
    private executorCustodianService: ExecutorCustodianService,
    private commonHelper: CommonHelper,
    private validation: ValidationHelper,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private modalService: CommonModelService,
    private dlbService: DlbService,
    private localeService: BsLocaleService,
    private commonService: CommonService,
    private addressHelper: AddressHelper,
    private ngZone: NgZone,
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.selectedCountry = environment.APP_DOMAIN;
    this.selectedCountry = 4;
    this.countryCode = this.phoneCountryCode;
    this.showSSN = environment.APP_DOMAIN == 1;
    this.faEye = faEye;
    this.faEyeSlash = faEyeSlash;
    this.toggleSSNView = false;
    this.currentUserDetails = this.localStorageService.getUserData(USER_TYPES.exe);
    this.sectionSaveExitOptions = {
      section: 1,
      type: 3,
    };
    this.userType = this.localStorageService.getDataByKey('role');
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    this.initMap(this.defaultCountry);
    this.SSN_MASK = SSN_MASK;
    this.currentDateRestriction = this.commonHelper.getCurrentDateForRestriction();
    this.userEmail = this.localStorageService.getUserData(USER_TYPES.exe)?.user?.email || '';

    // initiating form
    this.basicDetailsForm = this.formBuilder.group({
      first_name: [
        this.firstName,
        {
          updateOn: 'blur', validators: [
            Validators.required,
            Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
            Validators.maxLength(50),
          ],
        },
      ],
      last_name: [
        this.lastName,
        {
          updateOn: 'blur', validators: [
            Validators.required,
            Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
            Validators.maxLength(50),
          ],
        },
      ],
      dob: ['', { updateOn: 'blur', validators: [Validators.required, this.dateValidator.bind(this)] }],
      ssn: ['', { updateOn: 'blur', validators: [Validators.required] }],
      address: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(255), this.validation.fn.trim] }],
      city: ['', { updateOn: 'blur', validators: [Validators.required, this.validation.noWhitespaceValidator()] }],
      country: ['', { updateOn: 'blur', validators: [Validators.required] }],
      state: [''],
      zipcode: [
        '',
        {
          updateOn: 'blur', validators: [
            Validators.required,
            Validators.maxLength(10),
          ],
        },
      ],
      phone: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(PHONE_PATTERN)] }],
      email: [
        { value: this.userEmail, disabled: true },
        { updateOn: 'blur', validators: [Validators.required, Validators.pattern(EMAIL_PATTERN)] },
      ],
    });
    this.validation.setValidationErrors({ 
      city: { whitSpaceValue: 'Please enter valid character'}
    });
    if (!this.showSSN) {
      this.basicDetailsForm.get('ssn').clearValidators();
    } else {
      this.basicDetailsForm
        .get('ssn')
        .addValidators([Validators.required, this.validation.fn.SSNPatternValidator(SSN_PATTERN)]);
    }

    this.getPersonalDetails();
    this.applyLocale();
    this.isEnableState();
    this.bsConfig = Object.assign(
      {},
      {
        showWeekNumbers: false,
        maxDate: this.maxDate,
        showTodayButton: true,
        showClearButton: true,
        dateInputFormat: this.domainDateFormat,
      },
    );
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.basicDetailsForm.controls;
  }


  /**
   * Detect change on phone number component
   *
   * @param {any} inputObject
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }
  
  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }
  /**
   * get Age
   * @param {string}date
   * @return {number}
   */
  public getAge(date: string) {
    return this.commonHelper.getAge(date);
  }

  /**
     * dob validation
     * @param {FormControl}control
     * @return {boolean}
     */
  dateValidator(control: FormControl): { [s: string]: boolean } {
    const age = this.getAge(control.value);
    if (age < 13) {
      return { isNotAllowed: true };
    } else {
      return null;
    }
  }

  /**
   * Get personal details
   */
  public getPersonalDetails(): void {
    this.preDefinedLoader = true;

    // get children list
    if (this.userType === 'Custodian') {
      this.executorCustodianService.getCurrentUser().subscribe(
        (response: APIResponseModel) => {
          this.preDefinedLoader = false;
          let tempPhoneNumber;
          if (response.status) {
            const tempData = { ...response.data };
            delete response.data.country;
            delete response.data.city;
            delete response.data.state;
            this.basicDetailsForm.patchValue({
              ...response.data,
              country: this.defaultCountry,
            });
            if (tempData?.state?.id) {
              this.basicDetailsForm.patchValue({
                city: tempData?.city?.id,
                country: tempData?.country?.id,
                state: tempData?.state?.id,
              });
            }
            this.removePacContainer();
            this.initMap(tempData?.country?.id);
            this.sectionSaveExitOptions.id = response.data.user_id;
            this.basicDetailsForm.patchValue(response.data);
            tempPhoneNumber = response?.data?.user?.phone;
            this.countryCode = { code: response?.data?.user?.['flag_code'], dialCode: response?.data?.user?.['country_code'] };
          }
          this.basicDetailsForm.patchValue({
            phone: tempPhoneNumber,
            first_name: response.data.user.first_name,
            last_name: response.data.user.last_name,
          });
          this.basicDetailsForm.patchValue({
            dob: this.commonHelper.formatDate(response?.data?.dob, this.domainDateFormat),
          });
        },
        () => {
          this.preDefinedLoader = false;
        },
      );
    }
  }

  /**
   * Handle on submit
   */
  public onSubmit(): void {
    this.submitLoader = true;
    this.basicDetailsForm.value.first_name = this.basicDetailsForm.controls.first_name.value;
    this.basicDetailsForm.value.last_name = this.basicDetailsForm.controls.last_name.value;
    this.basicDetailsForm.value.email = this.userEmail;
    this.basicDetailsForm.value.dob = this.commonHelper.formatDate(this.basicDetailsForm.value.dob);

    if (this.userType === 'Custodian') {
      const payLoad = {
        ...this.basicDetailsForm.value,
        name:
          this.basicDetailsForm.value.first_name +
          this.basicDetailsForm.value.last_name,
        roletype: this.userType,
        country_code: this.phoneCountryCode.dialCode,
        flag_code: this.phoneCountryCode.code,
        is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
      };
      payLoad.state = this.selectedCountryName == this.unitedKingdom ? null : payLoad.state;

      this.commonService.getCityDetail(payLoad.country, payLoad.state, payLoad.city).subscribe((cResponse: APIResponseModel) => {
        if (cResponse?.status) {
          payLoad.city = cResponse?.data?.id;
          this.personalDetailsService
            .updatePersonalDetails({ ...payLoad }, 3)
            .subscribe(
              (response: APIResponseModel) => {
                this.submitLoader = false;
                if (response.status) {
                  if (!this.currentUserDetails.request) {
                    this.localStorageService.updateUserData(USER_TYPES.user, {
                      key: 'request',
                      updateValue: {
                        id: response.data.id,
                        request_stage: response.data.request_stage,
                      },
                      type: LOCAL_STORAGE_UPDATE_STATUS.S,
                    });
                    this.toastr.success(response.message);
                    this.modalService.close('about-yourself-modal');

                    if (this.dlbStatus === 'Departed Verified') {
                      this.modalService.open('claim-dlb-modal');
                    } else {
                      this.modalService.open('verify-departed-modal');
                    }
                  } else {
                    this.commonHelper.updateLocalstorageRequestStage(response.data);
                    this.toastr.success(response.message);
                    this.modalService.close('about-yourself-modal');

                    if (this.dlbStatus === 'Departed Verified') {
                      this.modalService.open('claim-dlb-modal');
                    } else {
                      this.modalService.open('verify-departed-modal');
                    }
                  }
                }
              },
              (exception: any) => {
                this.submitLoader = false;
                this.commonHelper.httpResponseHandler(exception?.error);
              },
            );
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error)
        , () => this.submitLoader = false);
    }
  }


  /**
   * Listen for option change (salutation)
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitter(selectedOption: SelectOptionsInterface): void {
    this.basicDetailsForm.patchValue({
      prefix: selectedOption.value,
    });
  }

  /**
   * Auto hypen SSN keyup
   */
  public autoHypenSSN(): void {
    let ssnValue = this.basicDetailsForm.value.ssn.replace(/\D/g, '');
    ssnValue = ssnValue.replace(/^(\d{3})/, '$1-');
    ssnValue = ssnValue.replace(/-(\d{2})/, '-$1-');
    ssnValue = ssnValue.replace(/(\d)-(\d{4}).*/, '$1-$2');

    this.basicDetailsForm.patchValue({
      ssn: ssnValue,
    });
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.modalService.close('about-yourself-modal');
    this.dlbService.dlbListingTrigger(true);
  }

  /**
  * Initialize google map api by country
  * @param {any} countryId
  */
  public initMap(countryId: any): void {
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: (r) => {
        this.ngZone.run(() => {
          this.basicDetailsForm.patchValue(r);
        });
      },
      error: (e) => {
        // will later handle this error callback
        // console.log(e)
      },
    });
  }

  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.basicDetailsForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }
  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(
    selectedCountry: SelectOptionsInterface,
  ): void {
    //
    if (!selectedCountry || this.preDefinedLoader) return;
    this.basicDetailsForm.controls.state.reset();
    this.basicDetailsForm.patchValue({
      country: selectedCountry.value,
    });
    this.removePacContainer();
    this.initMap(selectedCountry.value);
  }
  /**
   * remove pac container
   *
   */
  public removePacContainer() {
    this.addressHelper.removeAutocompletePacContainer();
  }
  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.basicDetailsForm.controls.city.reset();
    this.basicDetailsForm.patchValue({
      state: selectedState.value,
    });
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.basicDetailsForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.basicDetailsForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([
        Validators.required,
        Validators.pattern(
          new RegExp(selectedCity?.additionalDetails[0]?.postal_code_regex),
        ),
      ]);
      zipCodeRef.updateValueAndValidity({ onlySelf: true });
    }
    // this loader is set false here because of the delay to set country state city
    this.preDefinedLoader = false;
  }
  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }
  get state() {
    return this.basicDetailsForm.get('state');
  }
  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.basicDetailsForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ?
        this.state.addValidators([Validators.required]) : this.state.clearValidators();
      this.state.updateValueAndValidity();
    });
  }

  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.basicDetailsForm.get('zipcode');
    const stateId = this.basicDetailsForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        if (response.data.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(zipCodeRegex))]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        } else {
          zipCodeRef.setValidators([Validators.required]); zipCodeRef.updateValueAndValidity({ onlySelf: true });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }

  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }
}
