export const DEMO_VIDEOS = {
  consumerLanding: 'https://cdn.bfldr.com/EY1MWF7K/as/8h23pmcn8b3rm8gb68qg63h/LNUS_Welcome_Video_USv2',
  createdDigitalVault: 'https://cdn.bfldr.com/EY1MWF7K/as/2rkwckwwrwcs5b5ghs58w/LNUS_Open_A_LegacyNOW_Digital_Vault',
  aboutExecutor: 'https://cdn.bfldr.com/EY1MWF7K/as/53qn44c4xh35qmjm45rpbv/LNUS_Duties_of_an_Executor',
  whatIsAnExecutor: 'https://cdn.bfldr.com/EY1MWF7K/as/wpfr8gf4ncxc27q5gxcqgc77/LNUS_-_What_is_an_Executor',
  custodianDigitalVault: 'https://cdn.bfldr.com/EY1MWF7K/as/4gfx6gc3r33fmx4b95xfvnsm/LNUS_Digital_Vault_Custodian',
  accurateInfo: 'https://cdn.bfldr.com/EY1MWF7K/as/r948n7h33kwccrwqh55hnnm3/LNUS-Accurate_Info',
  digitalFiles: 'https://cdn.bfldr.com/EY1MWF7K/as/5bckrzhnnzrx5rj7mwqf8ts/LNUS-Digital_Files',
  plaidUs: 'https://cdn.bfldr.com/EY1MWF7K/as/whqgbw54ktgb5w3kz2g6tfs/LNUS-Plaid',
  prescriptionUs: 'https://cdn.bfldr.com/EY1MWF7K/as/987b87x8vrkp5fphfx6z74k9/LNUS-Prescriptions',
  socialMediaUs: 'https://cdn.bfldr.com/EY1MWF7K/as/55cr58h44gfbmxcwmkjf9j7t/LNUS-Social_Media',
  ValuableUs: 'https://cdn.bfldr.com/EY1MWF7K/as/6mwvr3k35mgw9zvbhjv4cs/LNUS-Valuables',
  accountsAndAssets: 'https://cdn.bfldr.com/EY1MWF7K/as/nzkzfxfjc32nxthr7hp8s6p/LNUS-Accounts_and_Assets',
  insurance: 'https://cdn.bfldr.com/EY1MWF7K/as/3g2h5vbfvtch7nxmhrxbbp2f/LNUS-Insurance',
  liabilities: 'https://cdn.bfldr.com/EY1MWF7K/as/4n67qhvj668pkngnrnh8t27/LNUS-Liabilites',
  businessHoldings: 'https://cdn.bfldr.com/EY1MWF7K/as/gvkvngpqv36qsbvvhk54bj6/LNUS-Business_Holdings',
  digitalVaultOverview: 'https://cdn.bfldr.com/EY1MWF7K/as/2rjc6h6679skqjsqpr5tqf9t/LNUS_LegacyNOW_Digital_Vault_Overview',
  professionalServiceProviders: 'https://cdn.bfldr.com/EY1MWF7K/as/96kcx5xc8p4p2cbrjqt3rw9z/LNUS-Professional_Service_Providers',
  assistanceHelp: 'https://cdn.bfldr.com/EY1MWF7K/as/kx4k2gx725hmt8cmb88rjvv/LNUS_-Aida_Virtual_Assistant_Intro',
  onSiteGuide: 'https://cdn.bfldr.com/EY1MWF7K/as/3mt5v4h2gfh45wv57fj4s/LGN-1039_-_20_Explainer_v8mp4',
};
