import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';


@Component({
  selector: 'app-pricing-pro',
  templateUrl: './pricing-pro.component.html',

})
/**
 * professional pricing class
 */
export class PricingProComponent implements OnInit {
  public mobile: boolean;
  public innerWidth: number;
  public menuActive: string;
  public professionalType: string;
  public vaultVideo: string;


  /**
* constructor
*/
  constructor(private router: Router,
    private commonService: CommonService,
    private slugInterceptorService: SlugInterceptorService,
    private localStorageService: LocalStorageService,
    private modalService: CommonModelService

  ) { }


  /**
 * loaded initially
 */
  ngOnInit(): void {
    this.professionalType = this.localStorageService.getDataByKey('pro-type')?.[0]?.displayText;
    this.onResize();
  }
  /**
   * window resize
   * @param {any} event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.innerWidth = window.innerWidth;


    if (this.innerWidth <= 767) { // 768px portrait
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  /**
   * @param{string}url
   * on menu change
   */
  public menuChange(url?: string) {
    if (url) {
      this.menuActive = url;
      void this.slugInterceptorService.navigate([url], null, true);
      this.commonService.setActiveNavbarTrigger(this.menuActive);
    }
  }
    /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');
  }
}
