<footer class="section pb-0 bg-darkblue">
  <div class="container mx-auto">
    <div class="grid grid-cols-12 gap-4 md:gap-6 lg:gap-12 pb-12">
      <div class="col-span-12 sm:col-span-6 lg:col-span-3">
        <h4 class="text-white text-semibold fs-subtitle">Quick Links</h4>
        <ul class="leading-10 mt-4 space-y-2">
          <!-- <li>
            <a (click)="menuChange(proRoute)" class="cursor-pointer text-gray-200 text-regular text-lg lg:text-xl >Professional</a>
          </li> -->
          <li>
            <a (click)="menuChange('pricing')"
               class="cursor-pointer text-gray-200 text-regular text-lg lg:text-xl hover:underline hover:text-orange-600">Pricing</a>
          </li>
          <li>
            <a (click)="viewIntroVideo()"
               class="cursor-pointer text-gray-200 text-regular text-lg lg:text-xl hover:underline hover:text-orange-600">Intro
              Video</a>
          </li>
        </ul>
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-3">
        <h4 class="text-white text-semibold fs-subtitle">Our Company</h4>
        <ul class="leading-10 mt-4 space-y-2">
          <!-- <li>
            <a (click)="menuChange('company')" class="cursor-pointer text-gray-200 text-regular text-lg lg:text-xl >About Us</a>
          </li>
          <li>
            <a href="#" class="text-gray-200 text-regular text-lg lg:text-xl">Blog</a>
          </li> -->
          <li>
            <a (click)="menuChange('contact-us')"
               class="cursor-pointer text-gray-200 text-regular text-lg lg:text-xl hover:underline hover:text-orange-600">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-3">
        <h4 class="text-white text-semibold fs-subtitle">Legal</h4>
        <ul class="leading-10 mt-4 space-y-2">
          <li>
            <a (click)="menuChange('digital-vault-agreement')"
               class="cursor-pointer text-gray-200 text-regular text-lg lg:text-xl hover:underline hover:text-orange-600">
              Digital Vault Agreement
            </a>
          </li>
          <li>
            <a (click)="menuChange('privacy-policy')"
               class="cursor-pointer text-gray-200 text-regular text-lg lg:text-xl hover:underline hover:text-orange-600">
              Privacy Policy
            </a>
          </li>
          <li>
            <a (click)="menuChange('acceptable-use-policy')"
               class="cursor-pointer text-gray-200 text-regular text-lg lg:text-xl hover:underline hover:text-orange-600">
              Acceptable Use Policy
            </a>
          </li>
          <li>
            <a (click)="menuChange('user-agreement')"
               class="cursor-pointer text-gray-200 text-regular text-lg lg:text-xl hover:underline hover:text-orange-600">
              User Agreement
            </a>
          </li>
          <li>
            <a (click)="menuChange('important-notice-to-digital-vault-holders')"
               class="cursor-pointer text-gray-200 text-regular text-lg lg:text-xl hover:underline hover:text-orange-600">
              Important Notice to Digital Vault Holders
            </a>
          </li>
        </ul>
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-3">
        <h4 class="text-white text-semibold fs-subtitle">Have a Question?</h4>
        <ul class="leading-10 mt-4 space-y-4">
          <li>
            <p class="text-gray-200 text-regular text-lg lg:text-xl">Contact a Support Service Representative at</p>
          </li>
          <li>
            <a href="tel:+1{{customerSupportPhone}}" target="_blank"
               class="flex items-center gap-2 cursor-pointer text-gray-200 text-lg lg:text-xl text-regular font-semibold hover:underline hover:text-orange-600">
              <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                   stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 sm:w-5 sm:h-5">
                <path
                  d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                </path>
              </svg>
              <span>+1 {{customerSupportPhone}} </span>
            </a>
          </li>
          <li>
            <a href="mailto:{{customerSupportMail}}" target="_blank"
               class="flex items-center gap-2 cursor-pointer text-gray-200 text-lg lg:text-xl text-regular font-semibold hover:underline hover:text-orange-600">
              <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                   stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 sm:w-5 sm:h-5">
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </svg>
              <span>{{customerSupportMail}}</span>
            </a>
          </li>
          <li>
            <p class="text-gray-200 text-regular text-lg lg:text-xl">Hours of Operation: <span
              class="inline text-gray-200 text-regular text-lg lg:text-xl">Monday to Friday</span> 9am to 5pm EST</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="pt-2 ">
      <div class="flex lg:flex-row py-5 justify-between items-start m-auto border-t text-sm flex-col space-y-2">
        <div>
          <a class="cursor-pointer" (click)="menuChange('')">
            <span class="sr-only">Legacy Now</span>
            <img class="w-48 lg:w-60 object-contain" src="assets/images/LegacyNOW-white.png" alt="legacy-now">
          </a>
        </div>
        <div class="cursor-pointer text-gray-200 text-regular text-lg lg:text-xl">
          Copyright © {{commonHelper?.currentYear}} LegacyNOW. All Rights Reserved.
        </div>
        <!--div class="flex space-x-6">
          <img src="assets/images/social-media/facebook.svg" width="32" height="32" alt="legacy-now">
          <img src="assets/images/social-media/linkedin.svg" width="32" height="32" alt="legacy-now">
          <img src="assets/images/social-media/twitter.svg" width="32" height="32" alt="legacy-now">
        </div-->
      </div>
    </div>
  </div>
</footer>
<app-video-view-popup [userRole]="'consumer'" [class]="'w-full h-full'"></app-video-view-popup>
