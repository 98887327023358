import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SlugService } from 'src/app/services/slug.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.css'],
})
/**
 * Account confirmation component
 */
export class ConfirmAccountComponent implements OnInit {
  public confirmLoader: boolean;
  public spinner: boolean;
  public orgLogo: string;
  public orgLogoPath: string;
  public isMailExpired: boolean;
  /**
   * @constructor
   */
  constructor(
    private activeRouter: ActivatedRoute,
    private toastService: ToastrService,
    private router: Router,
    private userService: UserService,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    private localStorageService: LocalStorageService,
    private slugService: SlugService,


  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.isMailExpired = false;
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      } else {
        this.orgLogoPath = '';
        this.orgLogo = '';
      }
    });
    // Get token from url
    const queryParams = this.activeRouter.snapshot.queryParamMap;
    const token = queryParams.get('token');
    if (!token) {
      this.spinner = true;
      this.toastService.error('Invalid token, redirecting to Login');
      setTimeout(() => {
        void this.slugInterceptorService.navigate(['login']);
        this.spinner = false;
      }, 3000);
      return;
    }
    /**
     * confirm account
     */
    this.confirmLoader = true;
    this.spinner = true;
    this.userService.confirmAccount({ token }).subscribe((response: APIResponseModel) => {
      this.confirmLoader = false;
      if (response.status) {
        this.toastService.success(response.message);
        this.spinner = false;
      }
    }, () => {
      // void this.slugInterceptorService.navigate(['mail-expired']);
      this.confirmLoader = false;
      this.isMailExpired = true;
      this.spinner = false;
    });
  }

  /**
   * Redirect to login
   */
  public redirectToLogin(): void {
    if (this.confirmLoader) return;
    void this.slugInterceptorService.navigate(['login']).finally(() => {
      // Reload the page after navigating
      window.location.reload();
    });
  }

  /**
   * on click logo
   */
  public onClickLogo() {
    void this.slugInterceptorService.navigate(['']);
  }
}
