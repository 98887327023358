import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { MessageModalDataInterface } from 'src/app/sharedComponent/message-modal/message-modal.component';
import { LPEmitterInterface, LPSettingsInterface, LPUserPeopleInterface } from '../../sharedComponent/list-people/list-people-interfaces';
import { ListPeopleComponent } from '../../sharedComponent/list-people/list-people.component';


@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',

})
/**
 * People page component
 */
export class PeopleComponent implements OnInit {
  private peopleDataRaw: Array<any>;
  public peopleData: {
    executors: Array<LPUserPeopleInterface>,
    beneficiaries: Array<LPUserPeopleInterface>,
    custodians: Array<LPUserPeopleInterface>,
    relationship: Array<LPUserPeopleInterface>,
    children: Array<LPUserPeopleInterface>,
  };
  public lpSettings: LPSettingsInterface = { edit: { show: true }, isLoading: true };
  public messageData: MessageModalDataInterface;
  public userType: string;
  public requestId: string;

  /**
   * @constructor
   */
  constructor(
    private personalDetailsService: PersonalDetailsService,
    private modalServce: CommonModelService,
    private commonHelper: CommonHelper,
    private changeDetection: ChangeDetectorRef,
    private slugInterceptorService: SlugInterceptorService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.peopleDataRaw = [];
    this.getPersonalDetails();
  }

  /**
   * Get people details
   */
  public getPersonalDetails(): void {
    this.lpSettings.isLoading = true;
    // get people list
    this.personalDetailsService.getUserPeople().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.peopleDataRaw = this.personalDetailsService.processUserPeople(response.data.user_people, response?.data?.marital_status);
        this.processUserPeopleData();
      }
    }, () => {
      this.lpSettings.isLoading = false;
    });
  }

  /**
   * Prepare data to display in list
   */
  public processUserPeopleData() {
    this.peopleData = { executors: [], custodians: [], beneficiaries: [], relationship: [], children: [] };
    // Executors
    const rawExecutors = this.peopleDataRaw.filter(e => e.executor === 1 && e.beneficiary_type_short !== 2);
    this.peopleData.executors = ListPeopleComponent.getLPUserPeople(rawExecutors);
    // Custodians
    const rawCustodians = this.peopleDataRaw.filter(e => e.custodian === 1);
    this.peopleData.custodians = ListPeopleComponent.getLPUserPeople(rawCustodians);
    // Beneficiaries
    const rawBeneficiaries = this.peopleDataRaw.filter(e => e.beneficiary === 1);
    this.peopleData.beneficiaries = ListPeopleComponent.getLPUserPeople(rawBeneficiaries);
    // Relationship
    const rawRelationship = this.peopleDataRaw.filter(e => e.relation === 1);
    this.peopleData.relationship = ListPeopleComponent.getLPUserPeople(rawRelationship);
    // Children
    const rawChildren = this.peopleDataRaw.filter(e => e.relation === 2);
    this.peopleData.children = ListPeopleComponent.getLPUserPeople(rawChildren);

    this.lpSettings.isLoading = false;
  }

  listListener($event: LPEmitterInterface, toggleModalData) {
    console.log('$event', $event);
    if ($event.clickedEdit || $event.clickedName) {
      this.toggleModal(toggleModalData);
    }
  }

  /**
   * toggle modal/ navigate page
   *
   * @param {any} editValue
   */
  public toggleModal(editValue: any): void {
    switch (true) {
      case (!editValue.relation && editValue.beneficiary === 1):
        void this.slugInterceptorService.navigate(['executor-beneficiary/manage-beneficiaries']);
        break;
      case (!editValue.relation && editValue.executor === 1):
        void this.slugInterceptorService.navigate(['executor-beneficiary/manage-executors']);
        break;
      case (editValue.relation && editValue.relation === 1):
        void this.slugInterceptorService.navigate(['about-yourself/partner']);
        break;
      case (editValue.relation && editValue.relation === 2):
        void this.slugInterceptorService.navigate(['about-yourself/children']);
        break;
      case (!editValue.relation && editValue.custodian === 1):
        void this.slugInterceptorService.navigate(['executor-beneficiary/manage-custodian']);
        break;
    }
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }
}
