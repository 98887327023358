<div class="scroll-hidden background-color">
  <!-- Navbar -->
  <app-navbar *ngIf="this.userType === 'Consumer'"></app-navbar>
  <app-professional-navbar *ngIf="this.userType !== 'Consumer'"></app-professional-navbar>

  <!-- Dashboard -->
  <div class="lg:grid grid-cols-12">
    <!-- BEGIN: Leftside Content -->
    <div class="col-span-12 md:col-span-7 xl:col-span-7 pt-6 p-6 md:px-0 xl:px-6 flex">
      <div class="flex flex-col w-full">
        <div class="h-full items-center justify-center">
          <div class="sm:px-12 lg:pl-16 w-full">
            <div class="h-full justify-center items-center ">
              <div class="flex items-center" *ngIf="this.userType !== 'Consumer'">
                <img src="assets/images/about-yourself/back-arrow.svg" (click)="navigateToPro()" alt=""
                     class="mr-4 cursor-pointer ">
                <h2 class="font-bold text-grey text-lg text-regular text-left">
                  The Professional Services Edition
                </h2>
              </div>
              <div class="my-6">

                <div>
                  <h2 class="text-darkblue text-2xl xl:text-4xl uppercase text-semibold pt-1"
                      *ngIf="this.userType === 'Consumer'">
                    Welcome To Your LegacyNOW
                    <br> Digital Vault
                    <a class="inline-block" title='Click here for help from Aida' (click)="openModal('accurateInfo')">
                      <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                           stroke-linecap="round" stroke-linejoin="round"
                           class="css-i6dzq1n text-primary cursor-pointer">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                      </svg>
                    </a>
                  </h2>


                </div>
                <h2 class="text-darkblue text-2xl xl:text-4xl uppercase text-semibold pt-1"
                    *ngIf="this.userType !== 'Consumer'">You
                  are creating a LegacyNOW
                  <br>digital vault for {{clientName}}
                </h2>
              </div>
              <!-- Section List -->
              <ng-container *ngIf="!isLoading">
                <app-summery-sections *ngFor="let sectionData of dashboardSectionDetails?.sectionList; let i = index"
                                      [sectionIntroVideo]="i==0"
                                      [sectionIndex]="i" [totalSections]="dashboardSectionDetails?.totalSection"
                                      [title]="sectionData?.title" [description]="sectionData?.description"
                                      [endingSection]="sectionData?.endingSection"
                                      [lastSection]="sectionData?.lastSection"
                                      [sectionItemList]="sectionData?.sectionItemList"
                                      [sectionLastRequestStage]="sectionData?.sectionLastRequestStage"
                                      [additionalData]="userDetails"
                                      [dashboardData]="dashboardData" [sectionStatus]="sectionStatus">
                </app-summery-sections>
              </ng-container>
              <div *ngIf="isLoading" class="border-card bg-white rounded-md ">
                <div class="px-4 px-6 my-4">
                  <!-- BEGIN: First Accordion -->
                  <div class="mb-2">
                    <div class="flex flex-row items-baseline">
                      <div class="w-full">
                        <app-shimmer-loading [loading]="true" [width]="'20%'">
                          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                          </h4>
                        </app-shimmer-loading>
                        <app-shimmer-loading [loading]="true" [width]="'50%'">
                          <h4 class="text-grey text-regular text-sm">
                          </h4>
                        </app-shimmer-loading>
                      </div>
                    </div>
                  </div>
                  <div class="md:grid md:grid-cols-12 xl:flex xl:flex-row my-6 flex-col-reverse items-center">
                    <div class="md:col-span-2">
                      <app-shimmer-loading [loading]="true" [shape]="'circle'" [width]="'4rem'">
                        <img src="assets/images/dashboard/about-yourself.png" class="w-12" alt="digital vault">
                      </app-shimmer-loading>
                    </div>
                    <div class="xl:ml-6 mt-4 xl:mt-0 md:col-span-10 w-full">
                      <app-shimmer-loading [loading]="true" [width]="'20%'">
                        <p class="text-xs tracking-wide text-regular breadcrumb"> Section<span
                          class="font-bold">1</span> / 7
                        </p>
                      </app-shimmer-loading>
                      <app-shimmer-loading [loading]="true" [width]="'50%'">
                        <h4 class="text-regular text-lg  font-semibold tracking-wide">
                          About Yourself
                        </h4>
                      </app-shimmer-loading>
                      <app-shimmer-loading [loading]="true" [width]="'80%'">
                        <p class="text-grey text-regular text-sm">Enter basic info about yourself & your family.
                        </p>
                      </app-shimmer-loading>
                    </div>
                    <div class="xl:ml-auto mt-4 xl:mt-0 md:col-span-12 w-32">
                      <app-shimmer-loading [loading]="true" [width]="'100%'" [height]="'2.5rem'">
                        <button type="submit"
                                class="button button--lg w-full bg-darkblue rounded-xs focus:outline-none text-regular text-white px-6">
                          Get
                          Started
                        </button>
                      </app-shimmer-loading>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- END: Leftside Content -->

    <!-- BEGIN: Rightside Content -->
    <div class="md:col-span-5 xl:col-span-5 flex background-color">
      <app-dashborad-right-content [completed]="!paymentStatus || paymentStatus == paymentStatusData.onRefundPeriod || paymentStatus == paymentStatusData.pending"
                                   [totalStage]="dashboardSectionDetails?.totalSection" [dlbId]="requestUniqueId"
                                   [clientDetails]="clientData"
                                   [paymentStatus]="paymentStatusProfessional"></app-dashborad-right-content>
    </div>

    <!-- END: Rightside Content -->
  </div>

  <app-aida-floating-video
    vaultVideo="assistanceHelp"
    tooltipText="Meet Aida, Your Virtual Assistant">
  </app-aida-floating-video>
  <!-- BEGIN: Footer Wrapper -->
  <app-consumer-landing-footer></app-consumer-landing-footer>
  <!-- END: Footer Wrapper -->
</div>

<app-email-modal></app-email-modal>
<app-id-verify-options-modal></app-id-verify-options-modal>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>
<app-payment-success></app-payment-success>
<app-payment-failure></app-payment-failure>