import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-email-verification-modal',
  templateUrl: './email-verification-modal.component.html',
  styleUrls: ['./email-verification-modal.component.css'],
})
export class EmailVerificationModalComponent implements OnInit {
  @Input() newEmail = '';
  @Output() otpVerificationStatus = new EventEmitter();
  public otpVerificationForm: FormGroup;
  public submitLoader: boolean;

  /**
   * Creates an instance of email verification modal component.
   * @param modalService
   * @param formBuilder
   * @param profileService
   * @param localStorageService
   * @param commonHelper
   * @param toastr
   */
  constructor(
    private modalService:CommonModelService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private localStorageService: LocalStorageService,
    private commonHelper: CommonHelper,
    private toastr: ToastrService,
  ) { }


  /**
   * on init
   */
  ngOnInit(): void {
    this.otpVerificationForm = this.formBuilder.group({
      otp: ['', { validators: [Validators.required, Validators.minLength(6)] }],
    });
  }

  /**
   * Gets verify otp form getter
   */
  get verifyOtpFormGetter() {
    return this.otpVerificationForm.controls;
  }

  /**
   * Determines whether otp verify on
   */
  public onOtpVerify() {
    this.submitLoader = true;
    this.profileService.verifyOtp(this.otpVerificationForm.value).subscribe((response: APIResponseModel) => {
      this.submitLoader = false;
      if (response.status) {
        // Event Emitted to parent component once otp verified successfully to refresh state variables
        this.otpVerificationStatus.emit();
        this.localStorageService.storeData('consumer-email', response.data.email);
        this.toastr.success(response.message);
        this.closeModel();
      }
    }, (exception: any) => {
      this.submitLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
   * Closes model
   */
  public closeModel(): void {
    this.modalService.close('otp-verification-modal');
    this.otpVerificationForm.reset();
  }
}
