<nav class="bg-white">
  <div class="w-full px-4 lg:px-8">
    <div class="relative flex items-center justify-between pb-4 lg:h-28 pt-4">
      <div class="flex-1 flex items-start gap-2 sm:gap-4 lg:items-stretch justify-start">
        <div *ngIf="showMobileMenu"
             class="relative sm:inset-y-0 left-0 flex items-center sm:hidden">
          <!-- BEGIN: Mobile Menu button-->
          <button type="button"
                  class="inline-flex sm:ml-2 items-center justify-center p-1 border rounded text-grey focus:outline-none"
                  aria-controls="mobile-menu" aria-expanded="false" (click)="mobileMenuToggle = !mobileMenuToggle">
            <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
            </svg>
          </button>
          <!-- END: Mobile Menu button-->
          <!-- BEGIN: Mobile Menu dropdown -->
          <div *ngIf="mobileMenuToggle && !dlbDataLoader"
               class="absolute top-10 mt-2 w-48 rounded-md shadow-lg py-1 bg-white focus:outline-none z-50"
               role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <a *ngIf="showDownloadVault" [href]="downloadVault">
              <button [disabled]="disableDownloadVault" [title]="disableDownloadVault?'please pay transfer fee to download':'click to download vault'"
                      class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100 w-full text-left"
                      [ngClass]="disableDownloadVault? 'bg-gray-500':'bg-darkblue'"
                      role="menuitem">Download Digital Vault
              </button>
            </a>
            <button *ngIf="showReturnDashboardOption"
                    (click)="returnToDashBoard()"
                    class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100 w-full text-left"
                    role="menuitem">Return to Dashboard
            </button>
            <button *ngIf="useCensor"
                    (click)="payNow()"
                    class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100 w-full text-left"
                    role="menuitem">Pay Transfer Fee
            </button>
          </div>
          <!-- END: Mobile Menu dropdown -->
        </div>

        <!-- BEGIN: LOGO -->
        <div class="flex justify-between items-center my-auto">
          <a (click)="changeRoute('executor-custodian/dashboard')">
            <img alt="The-Estate-Registry" class="object-contain w-40 lg:w-64 custom-logo-large cursor-pointer"
                 src="assets/images/logo-old.png">
          </a>
        </div>
        <!-- END: LOGO -->

        <!-- BEGIN: SPONSERED BY : SLUG LOGO -->
        <div class="flex items-start gap-1 md:gap-2 flex-col">
          <p *ngIf="this.userType === 'Custodian' && slugLogoPath"
             class="whitespace-nowrap text-xs md:text-sm lg:text-regular text-dark-grey">Sponsored by:</p>
          <a class="cursor-pointer max-h-12 maxw128" (click)="navigate('dashboard')"
            *ngIf="this.userType === 'Custodian' && slugLogoPath">
            <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{slugLogo}}">
          </a>
        </div>
        <!-- END: SPONSERED BY : SLUG LOGO -->

        <div *ngIf="!showLogoOnly"
             class="justify-end flex items-center gap-4 md:gap-6 w-full lg:static lg:inset-auto sm:ml-6 flex-1">
          <div *ngIf="showReturnDashboardOption"
               class="hidden sm:block">
            <button (click)="returnToDashBoard()"
                    class="flex items-center gap-4 button button--lg w-full bg-darkblue rounded-xs py-2 md:h-10 focus:outline-none text-regular text-white px-6 text-sm">
              <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
                   stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              <span>Return to Dashboard</span>
            </button>
          </div>
          <div *ngIf="showDownloadVault" class="hidden sm:block">
            <a [href]="downloadVault">
              <button [title]="disableDownloadVault?'please pay transfer fee to download':'click to download vault'"
                class="flex items-center gap-4 button button--lg w-full rounded-xs py-2 md:h-10 focus:outline-none text-regular text-white px-6 text-sm"
                [disabled]="disableDownloadVault || dlbDataLoader" [ngClass]="disableDownloadVault? 'bg-gray-500':'bg-darkblue'">
                <svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-download" viewBox="0 0 16 16">
                  <path
                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
                    fill="white"></path>
                  <path
                    d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"
                    fill="white"></path>
                </svg>
                <span>Download Digital Vault</span>
              </button>
            </a>
          </div>
          <div *ngIf="useCensor && !dlbDataLoader"
               class="hidden sm:block">
            <button (click)="payNow()"
                    class="flex items-center gap-4 button button--lg w-full bg-darkblue rounded-xs py-2 md:h-10 focus:outline-none text-regular text-white px-6 text-sm">
              <span>Pay Transfer Fee</span>
            </button>
          </div>
          <div class="relative w-6 lg:w-8">
            <div (click)="profileMenuToggle = !profileMenuToggle" class="cursor-pointer" #profileMenu>
              <img class="rounded-full object-contain w-8 h-8 lg:h-10 lg:w-10"
                   [src]="profileMenuImage" alt="">
            </div>

            <!-- BEGIN: Profile dropdown -->
            <div
              class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white focus:outline-none z-50"
              role="menu" aria-orientation="vertical" aria-labelledby="user-menu" *ngIf="profileMenuToggle">
              <a (click)="changeRoute('executor-custodian/account-settings')"
                class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100"
                role="menuitem">Your Profile</a>
              <a (click)="changeRoute('executor-custodian/saved-cards')"
                class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100"
                role="menuitem">Manage Cards</a>
              <button [disabled]="logoutLoader" (click)="logout()"
                class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100 w-full text-left"
                role="menuitem">Log out
              </button>
            </div>
            <!-- END: Profile dropdown -->
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
