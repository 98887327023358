import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConsumerPaymentMethod } from '../../../constants/application.const';
import { CheckoutService } from '../../components/checkout/checkout.service';
import { ConsumerSubscriptionService } from '../../components/consumer-payment-section/consumer-subscription.service';
import { SlugInterceptorService } from '../../helper/slug-interceptor.service';
import { CommonModelService } from '../../services/common-model.service';
import { LocalStorageService } from '../../services/local-storage.service';


@Component({
  selector: 'app-payment-status-modal',
  templateUrl: './payment-status-modal.component.html',
  styleUrls: ['./payment-status-modal.component.css'],
})
export class PaymentStatusModalComponent implements OnInit {
  readonly TIMEOUT_LENGTH_MINUTES = 5;
  readonly TIMEOUT_LENGTH_LS_KEY = 'app-payment-status-modal-timestamp';
  readonly PAYMENT_MODAL_ID = 'payment-status-message-modal';
  isLoading: boolean;
  mobile: boolean = false;
  monthlyPackageDetails: number;
  paymentMode: number;

  constructor(
    private readonly modalService: CommonModelService,
    private readonly localStorageService: LocalStorageService,
    private readonly slugInterceptorService: SlugInterceptorService,
    private readonly subscriptionService: ConsumerSubscriptionService,
    private readonly checkoutService: CheckoutService,
    private readonly router: Router,
  ) {
    this.paymentMode = this.localStorageService.getDataByKey('payment_mode');
  }

  closeModal() {
    this.modalService.close(this.PAYMENT_MODAL_ID);
  }

  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription().then(r => {
      const old_timeout = this.localStorageService.getDataByKey(this.TIMEOUT_LENGTH_LS_KEY) ?? 0;
      const now_time = Date.now();
      const time_diff = (now_time - old_timeout) / (1000 * 60); // convert to minutes
      if (!r
        && this.paymentMode !== ConsumerPaymentMethod.PAID_BY_PROFESSIONAL
        && time_diff > this.TIMEOUT_LENGTH_MINUTES) {
        this.localStorageService.storeUserData(this.TIMEOUT_LENGTH_LS_KEY, now_time);
        this.modalService.open(this.PAYMENT_MODAL_ID);
      }
    });
    this.getSubscriptionDetails();
  }

  public redirectToPaymentPage() {
    this.localStorageService.storeData('isClicked', true);
    this.checkoutService.proceedTosubscriptionPayment().subscribe(() => {
      void this.slugInterceptorService.navigate(['/checkout']);
      this.closeModal();
    });
  }

  /**
   * getSubscriptionDetails for getting monthly package Details
   */
  public getSubscriptionDetails() {
    const slugSubscriptionAmount = this.localStorageService.getDataByKey('slug_subscription_amount');
    this.isLoading = true;
    this.subscriptionService.getSubscriptionDetailsApi().subscribe({
      next: r => {
        this.monthlyPackageDetails = r?.data?.subscription_price;
        if (this.paymentMode == ConsumerPaymentMethod.PASS_TO_CONSUMER && slugSubscriptionAmount >= 0) {
          this.monthlyPackageDetails = slugSubscriptionAmount;
        }
        this.isLoading = false;
      },
    });
  }

  /**
   * window resize
   */
  @HostListener('window:resize')
  onResize() {
    const innerWidth = window.innerWidth;
    this.mobile = innerWidth <= 767;
  }

}
