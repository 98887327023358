import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { faInfoCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { filter, take } from 'rxjs/operators';
import { CardService } from 'src/app/components/consumer-payment-options/card-details/card-details.service';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { AgentsService } from 'src/app/professional-dashboard/services/agents.service';
import { ChartsService } from 'src/app/professional-dashboard/services/charts.service';
import { ClientsService } from 'src/app/professional-dashboard/services/clients.service';
import { ManagerService } from 'src/app/professional-dashboard/services/manager.service';
import { ProfessionalDashboardService } from 'src/app/professional-dashboard/services/professional-dashboard.service';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import {
  PROFESSIONAL_ROLE,
  RECORD_STATUS,
  SELECT_VALUE,
  SORT_BY_CLIENTS,
  USER_TYPES
} from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { ProfessionalPermissionService } from '../../../../services/professional-permission.service';


enum SortPreference {
  Name = 'desc',
  Advisor = 'advisor',
  OldToNew = 'Old to New',
  NewToOld = 'New to Old',
  Advisor_Permission = 'advisor_permission'
}


interface clientListTableSettingsI {
  sortPreference: SortPreference,
  recordsPerPage: number,
  searchKeyword: string
}

@Component({
  selector: 'app-home-professional-dashboard',
  templateUrl: './home-professional-dashboard.component.html',
  styleUrls: ['./home-professional-dashboard.component.css'],
})
/**
 * home
 */
export class HomeProfessionalDashboardComponent implements OnInit {
  public submitLoaderDelete: boolean;
  public readonly PROFESSIONAL_ROLE = PROFESSIONAL_ROLE;
  public readonly faInfoCircle = faInfoCircle;
  public toggleProfileView: boolean;
  public toggleLinkView: boolean;
  public index: number;
  public userData: any;
  public roleName: string;
  public requestId: string;
  public dataLoading: boolean;
  public loadingClientDetails: boolean;
  public profileMenuToggleClient: boolean;
  public profileMenuToggleAgent: boolean;
  public profileMenuToggleManager: boolean;
  public profileMenuToggleBuffer: boolean;
  public clientDropdownToggle: boolean;
  public clientList: Array<Object>;
  public agentsList: Array<Object>;
  public permissionList: Array<any>;
  public permission: Array<any>;
  public clientSelected: boolean;
  public professionalType: string;
  public userId: string;
  public content: string = '';
  // referral code for client registration
  public shortCode: string;
  public agentImageSrc: string;
  public clientImageSrc: string;
  public clientRequestId: string;
  public isClientDeathVerified:boolean = false;
  public userDetails: { [key: string]: any };
  public currentPage = 1;
  public searchKeyword = '';
  public lastPage: number;
  public recordsPerPage: number = 5;
  public selectOptions: Array<Object>;
  public orderOptions: Array<Object>;
  public listOrder = 'desc';
  public paginationLinks: Array<any>;
  public totalClients: number;
  public deleteId: string;
  public onSearch: boolean;
  public selectedVaultId: string = '';
  public selectedUserId: any;
  public selectedUserName: string;
  public roleType: string;
  public managerIndex: number;
  public isSendInvite: boolean = false;
  public toolTipOptions: Object = {
      'placement': 'right',
      'showDelay': '200',
      'theme' : 'light',
      'shadow': 'true'
  };
  // If consumer has given specic access to advisor
  public userBasedAccess: { [key: string]: any } = {};
  public _ = require('lodash');

  // Doughnut
  public doughnutOptions: any;
  public doughnutColor: any;
  public doughnutChartLabels: Label[];
  public doughnutChartData: MultiDataSet;
  public faUsers: any;

  // Bar Chart
  public barChartOptions: ChartOptions;
  public barChartLabels: Label[];
  public barChartData: ChartDataSets[];
  public barChartDataAdvisor: ChartDataSets[];
  public barChartColor: any;
  public barChartLegend: boolean;
  public barChartValues: any;
  public showData: boolean;

  public previousUrl: string;
  // Notification
  public isDashboardPage: boolean = true;
  // Used to determine aspect ratio of client chart's <canvas>
  public screenWidth: number;
  public ROUTE: { [key: string]: string } = {
    OVERVIEW: '/overview',
    EXEC_BENE_VIEW: '/executor-beneficiary/executors-beneficiaries-view',
    ABOUT_YOURSELF: '/about-yourself',
  };
  public agentTerm: string;
  public managerList: any[] = [];
  public dataOverview: {};

  // add card alert
  public cardsAdded: boolean = true;
  public isPayVaultRegistration: boolean;
  public messageData = {
    title: 'Alert!',
    message: ['Please ensure that you add cards first before proceeding to add or import a client.'],
    buttons: [{ content: 'Add Card', emitValue: { proceedToAddCard: true }, class: 'bg-darkblue text-white mr-4' }],
  };

  /**
   * constructor
   */
  constructor(
    private localStorageService: LocalStorageService,
    private clientService: ClientsService,
    private managerService: ManagerService,
    private professionalUserService: ProfessionalUserService,
    private chartService: ChartsService,
    private modalService: CommonModelService,
    private commonHelper: CommonHelper,
    private agentService: AgentsService,
    private slugInterceptorService: SlugInterceptorService,
    private toastrService: ToastrService,
    private professionalDashboardService: ProfessionalDashboardService,
    private proPermissionService: ProfessionalPermissionService,
    private commonService: CommonService,
    private router: Router,
    private cardService: CardService,
  ) {
    this.barChartData = [
      { data: [], label: 'My Clients', backgroundColor: '#2692f7' },
      { data: [], label: 'My ' + this.agentTerm + 's\' Clients', backgroundColor: '#ea7c1f' },
    ];
    this.barChartDataAdvisor = [
      // To Do
      // { data: [], label: 'My Clients', backgroundColor: '#2692f7' },
      { data: [], label: 'My ' + this.getAdvisorTerm(), backgroundColor: '#ea7c1f' },
    ];
    router.events
      .pipe(filter((event): boolean => {
        return event instanceof NavigationEnd;
      }))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
    this.checkCardsAdded();
  }

  public clientListTableSettings: clientListTableSettingsI;
  public recordStatus: {}[];

  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.clientListTableSettings = this.localStorageService.getDataByKey('clientListTableSettings') || {
      sortPreference: SortPreference.Name,
      recordsPerPage: 5,
      searchKeyword: '',
    };
    this.recordStatus = RECORD_STATUS;
    this.selectOptions = SELECT_VALUE;
    this.orderOptions = SORT_BY_CLIENTS;
    

    this.loadingClientDetails = false;
    this.getDashboardData();
    this.selectOptions = SELECT_VALUE;
    this.faUsers = faUser;
    this.roleType = this.localStorageService.getUserData(USER_TYPES.pro).user.professional_type_name;
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.isPayVaultRegistration = (this.localStorageService.getDataByKey('proPaymentType') === 1);
    this.screenWidth = window.innerWidth;
    this.agentImageSrc = `${environment.BASE_URL_RAW}uploads/agent_proof/`;
    this.clientImageSrc = `${environment.BASE_URL_RAW}uploads/profile_photo/`;

    // TODO investigate: This assigns userName, which is also assigned after using localstorage.
    this.professionalUserService.currentUserObserve?.subscribe(
      data => this.setUserData(data ?? this.localStorageService.getUserData(USER_TYPES.pro).user),
    );
    this.onSearch = false;
    this.requestId = this.userData.professional_type;
    this.professionalType = this.userData.professional_type_name;
    this.shortCode = this.userData.short_code;
    this.roleName = this.userData.role_name;
    this.userId = this.userData.id;
    this.profileMenuToggleClient = false;
    this.profileMenuToggleAgent = false;
    this.profileMenuToggleManager = false;
    this.toggleProfileView = false;
    this.toggleLinkView = false;
    this.dataLoading = true;
    this.selectedUserId = '';
    this.selectedVaultId = '';

    // doughnut data
    this.doughnutChartLabels = ['Clients', 'Remaining Clients'];
    this.doughnutChartData = [[280, 80]];
    this.doughnutOptions = {
      legend: { display: true, position: 'bottom' },
      rotation: 2.45 * Math.PI,
      circumference: 2 * Math.PI,
      responsive: true,
    };
    this.doughnutColor = [
      {
        backgroundColor: ['#276fC4', '#dedbdd'],
      },
    ];

    // barchart data
    // this.getChartData(); // getDashboardData accomplishes this
    this.barChartOptions = {
      responsive: true,
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawOnChartArea: true,
            },
            ticks: {
              beginAtZero: true,
              stepSize: 1,
              max: 10,
            },
          },
        ],
      },
      legend: {
        position: 'bottom',
      },
    };

    this.barChartLegend = true;
    this.barChartLabels = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    if (this.roleName === PROFESSIONAL_ROLE.manager || this.roleName === PROFESSIONAL_ROLE.professional) {
      this.getAgentsList();
    }
    this.professionalDashboardService.selectedClientObserve.subscribe((response) => {
      if (response) {
        this.index = response;
      }
    });
    this.getClientsList();
    this.getManagers();
    this.proPaymentStatusListen();
  }

  private setUserData(data: any) {
    this.userData = data;
    this.userData['full_name'] = `${this.userData['first_name'] ?? ''} ${this.userData['last_name'] ?? ''}`.trim();
  }

  /**
   * get dashboard details
   */
  public getDashboardData(): void {
    /*
    // Default values for demonstration
    this.dataOverview = {
      managers: 450,
      agents: 1000,
      vaults_created: 700,
      vaults_completed: 670,
      vaults_incomplete: 30,
      vaults_access_firm: 212,
      vaults_access_contact: 212,
      revenue: 2200,
      renewals: 44,
    };
    */
    this.professionalDashboardService.getDashboardDetails().subscribe(
      (r) => {
        if (r.status) {
          const data: {} = r.data;
          console.log('data', data);
          this.dataOverview = {
            managers: data['manager_count'],
            agents: data['advisor_count'],
            vaults_created: data['total_vault_count'],
            vaults_completed: data['completed_vault_count'],
            vaults_incomplete: data['pending_vault_count'],
            vaults_access_firm: data['active_vaults_percentage'] || 0,
            vaults_access_contact: data['vaults_with_extended_contact'] || 0,
            vaults_with_custodian: data?.['vault_has_cusotdian'],
            revenue: data['total_revenew'],
            renewals: 0,
            vault_onboard_rate: this.roundToDecimalPlaces(data['vault_onboard_rate'], 2)
          };
          const clientChartData: number[] = [];
          Object.values(data['consumer_monthly_list']).forEach((e: number) => clientChartData.push(e));
          let getGreatestNumber = this._.max(clientChartData);
          if(getGreatestNumber > 10 ) { // set y axis, bar chart value based on count 
            this.barChartOptions.scales = {
              xAxes: [
                {
                  gridLines: {
                    drawOnChartArea: false,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    drawOnChartArea: true,
                  },
                  ticks: {
                    beginAtZero: true,
                    stepSize: this.linearEquationOp(getGreatestNumber)/10,
                    max: this.linearEquationOp(getGreatestNumber),
                  },
                },
              ],
            }
          }
          this.barChartData = [
            {
              data: clientChartData, // [1, 5, 2, 2, 1, 9, 3, 1, 5, 8, 5, 2],
              label: this.roleName === PROFESSIONAL_ROLE.manager ? 'Total Clients' : 'No of Digital Vaults',
              backgroundColor: '#2692f7',
            },
          ];
          if (this.roleName === PROFESSIONAL_ROLE.manager || this.roleName === PROFESSIONAL_ROLE.professional) {
            const advisorChartData: number[] = [];
            Object.values(data['manager_monthly_list']).forEach((e: number) => advisorChartData.push(e));
            console.log(advisorChartData);

            this.barChartDataAdvisor = [
              {
                data: advisorChartData, // [1, 5, 3, 2, 1, 3, 3, 1, 6, 8, 4, 2],
                label: this.getAdvisorTerm() + 's',
                backgroundColor: '#ea7c1f',
              },
            ];
          }
        }
      },
      (e) => this.commonHelper.httpResponseHandler(e.error),
      () => {
        console.log('Get Dashboard Details Complete');
        this.showData = true;
      },
    );
  }
  /**
   * Get linear equation
   * @param x 
   * @returns output 
   */
  public linearEquationOp(x: number): number {
    const m = 10 / 9;
    const b = 40.6667; // approximating 86.6667 for 86.\overline{6}
    return Math.round(m * x + b);
}

  /**
   * get chart data
   */
  public getChartData(): void {
    this.chartService.getBarChartDataDashboard()?.subscribe((response) => {
      console.log(response?.data);
      this.barChartValues = response?.data;
      this.showData = true;

      this.barChartData = [
        {
          data: [1, 5, 2, 2, 1, 9, 3, 1, 5, 8, 5, 2],
          label: this.roleName === PROFESSIONAL_ROLE.manager ? 'Total Clients' : 'My Clients',
          backgroundColor: '#2692f7',
        },
      ];
      if (this.roleName === PROFESSIONAL_ROLE.manager || this.roleName === PROFESSIONAL_ROLE.professional) {
        this.barChartDataAdvisor = [
          {
            data: [1, 5, 3, 2, 1, 3, 3, 1, 6, 8, 4, 2],
            label: 'My ' + this.getAdvisorTerm(),
            backgroundColor: '#ea7c1f',
          },
        ];
      }
    }, (e) => this.commonHelper.httpResponseHandler(e));
  }

  /**
   * get all managers
   */
  public getManagers(): void {
    this.dataLoading = true;
    this.managerService.getManagers().subscribe(
      (response) => {
        this.managerList = response.data;
      }, (exception: any) => {
        this.commonHelper.httpResponseHandler(exception?.error);
      }, () => this.dataLoading = false,
    );
  }

  /**
   * get agents list
   */
  public getAgentsList() {
    this.dataLoading = true;
    // changed api from dashboard-agent-list from pro user dashboard service for time being
    this.agentService.getAllAgents().subscribe((response) => {
      this.agentsList = response.data;
    }, () => {
    }, () => this.dataLoading = false);
  }

  /**
   * initial get client list
   */
  public getClientsList() {
    this.onSearch = false;
    this.dataLoading = true;
    this.localStorageService.storeData('clientListTableSettings', this.clientListTableSettings);
    this.clientService.getClients(this.currentPage, this.clientListTableSettings.recordsPerPage, this.proSortPreference, this.clientListTableSettings.searchKeyword).subscribe((response) => {
      this.totalClients = response.data['total'];
      this.clientList = response.data.data;
      this.sortClientList();      
      this.paginationLinks = response.data.links;
      this.lastPage = response.data.last_page;
      if (this.clientList.length && !this.index) {
        this.getClientDetails(this.clientList[0], 0);
      } else {
        this.getClientDetails(this.clientList[this.index], this.index);
      }
    }, () => {
    }, () => this.dataLoading = false);
  }

  /**
   * Gets pro sort preference
   */
  get proSortPreference(): SortPreference {
    return this.clientListTableSettings.sortPreference === SortPreference.Advisor || SortPreference.Advisor_Permission ? this.clientListTableSettings.sortPreference : SortPreference.Name;
  }

  /**
   * Sorts client list
   */
  public sortClientList() {
    switch (this.clientListTableSettings.sortPreference) {
      case SortPreference.OldToNew:
        // Sort by Old to New
        this.clientList.sort((a, b) => {
          console.log(a);
          const dateA = new Date(a['created_at']);
          const dateB = new Date(b['created_at']);

          if (dateA < dateB) {
            return -1;
          }

          if (dateA > dateB) {
            return 1;
          }

          return 0;
        });
        break;

      case SortPreference.NewToOld:
        // Sort by New to Old
        this.clientList.sort((a, b) => {
          const dateA = new Date(a['created_at']);
          const dateB = new Date(b['created_at']);

          if (dateA > dateB) {
            return -1;
          }

          if (dateA < dateB) {
            return 1;
          }

          return 0;
        });
        break;

      default:
        // Handle the default case (no sorting)
        break;
    }
  }

  /**
   * toggle import client modal
   */
  public toggleModal(): void {
    this.modalService.open('import-client-modal');
  }

  /**
   * toggle bulk import client modal
   */
  public toggleBulkImportModal(): void {
    this.isSendInvite = false;
    this.modalService.open('bulk-import-client-modal');
  }

  /**
   * Toggle bulk send invite
   */
  public toggleBulkSendInvite(): void {
    this.isSendInvite = true;
    this.modalService.open('bulk-import-client-modal');
  }

  /**
   * toggle view profile modal
   *
   */
  public toggleProfileModal(): void {
    this.modalService.open('view-profile-modal');
  }


  /**
   * Handles button group events.
   *
   * @param {Event} event - The event object triggered by the button group.
   * @returns {void}
   */
  public buttonGroupEventListener(event) {
    if (Object.keys(event).length > 0) {
      this.modalEventListener(event);
    }
  }

  /**
   * toggle schedule client modal
   * @param{any}item
   */
  public toggleScheduleClientModal(item: any): void {
    console.log(item);
    if (item?.user_request) {
      this.selectedVaultId = item?.user_request?.request_unique_id;
      this.selectedUserId = item?.user_request?.user_id;
      this.selectedUserName = item?.full_name;
    } else {
      return;
    }

    this.modalService.open('schedule-client-modal');
  }

  /**
   * Modal event listner
   *
   * @param {any} data
   */
  public modalEventListener(data: any): void {
    this.getClientsList();
    this.commonHelper.diffCheckerAndUpdate(this.clientList, 'id', data?.id, data);
  }

  /**
   * edit agent
   * @param{any}agent_id
   */
  public editAgent(agent_id: any) {
    this.changeRoute('advisors/edit/' + agent_id);
    this.agentService.emitChange(agent_id);
  }

  /**
   * delete agent
   * @param{string}isDelete
   */
  public deleteAgent(isDelete: any) {
    if (isDelete) {
      this.submitLoaderDelete = true;
      this.clientService.deleteAgent(this.deleteId).subscribe((response) => {
        this.modalService.close('delete-confirmation-modal');
        this.toastrService.success(response.message);
        this.getAgentsList();
        this.getManagers();
        this.submitLoaderDelete = false;
      },
        (exception: any) => {
          this.submitLoaderDelete = false;

          this.commonHelper.httpResponseHandler(exception.error);
        });
    } else {
      this.submitLoaderDelete = false;
    }
    this.profileMenuToggleAgent = false;
    this.profileMenuToggleManager = false;
  }

  /**
   * toggle dropdown
   * Index less than zero should close all.
   * @param{number}ind
   */
  public toggleDropDown(ind: number) {
    // If 'close index', Menu is Open & its just been opened :: don't close it
    // Else if 'close index' :: close it
    // Else :: open at index.

    if (ind < 0 && this.profileMenuToggleClient && this.profileMenuToggleBuffer) {
      this.profileMenuToggleBuffer = false;
    } else if (ind < 0) {
      this.profileMenuToggleClient = false;
    } else {
      this.profileMenuToggleBuffer = true;
      this.profileMenuToggleClient = !this.profileMenuToggleClient;
      this.index = ind;
    }
  }

  /**
   * toggle dropdown manager
   * @param {number}ind
   */
  public toggleDropDownManager(ind: number) {
    this.profileMenuToggleManager = !this.profileMenuToggleManager;
    this.managerIndex = ind;
    this.index = null;
  }

  /**
   * toggle dropdown agent
   * @param {number}ind
   */
  public toggleDropDownAgent(ind: number) {
    this.profileMenuToggleAgent = !this.profileMenuToggleAgent;
    this.index = ind;
    this.managerIndex = null;
  }

  /**
   * to dlb
   * permission checking
   * @param {any} route
   * @param {any}client
   */
  public toDLB(route, client) {
    // void this.slugInterceptorService.navigate([`overview/${id}`]);
    // this.localStorageService.storeData('req_id', id);
    this.localStorageService.storeData('overview_user_id', client['id'])
    if (client?.['advisor_permission']?.permission?.length >= 1) { // Overall Advisor Permissions
      if (route === this.ROUTE.OVERVIEW) { // Handle Overview route
        void this.slugInterceptorService.navigate([route, client['user_request'].id], null, false);
        this.localStorageService.storeData('req_id', client['user_request'].id);
      } else if (route === this.ROUTE.EXEC_BENE_VIEW) { // Handle Executive/Beneficiaries route
        if (client.user_request.request_stage < 8) {
          this.toastrService.info('User has not added Executors yet.');
        } else {
          void this.slugInterceptorService.navigate([route, client['user_request'].id], null, false);
        }
      } else {
        void this.slugInterceptorService.navigate([route, client['user_request'].id], null, false);
      }
    } else {
      // this.professionalDashboardService.getAccessControlList().subscribe
      this.toastrService.error('Client Permission Required');
    }
  }

  /**
   * get individual client details for access control
   * @param {{}}client
   * @param {number}index
   */
  public getClientDetails(client: {}, index: number) {
    this.localStorageService.deleteDataByKey('agent_id');
    this.loadingClientDetails = true;
    this.userDetails = client;
    this.isClientDeathVerified = Number(client?.['user_request']?.admin_death_verify) === 3; 
    this.clientRequestId = client?.['user_request']?.id;
    this.localStorageService.storeData('req_id', client?.['user_request']?.id);
    this.localStorageService.storeData('agent_id', client?.['user_request']?.professional_id);
    this.localStorageService.storeData('consumer-email', client?.['email']);
    this.professionalDashboardService.selectedClientInfo(index);
    this.clientSelected = true;
    this.index = index;
    if (this.clientRequestId) {
      const isPrimaryAdvisorLoggedIn = this.isPrimaryAdvisorLoggedIn(client);
      const userId = this.localStorageService.getDataByKey('agent_id') || this.localStorageService.getUserData(USER_TYPES.pro).user.id;
      if (isPrimaryAdvisorLoggedIn) {
        this.getClientPermissionsList(userId, this.clientRequestId)
      } else {
        this.permissionList = [];
        this.loadingClientDetails = false
      }
    }
  }

  /**
   * Checks if the primary advisor is logged in.
   * 
   * @param {object} client - The client object.
   * @returns {boolean} - Returns true if the primary advisor is logged in, otherwise false.
   */
  public isPrimaryAdvisorLoggedIn(client: {}): boolean {
    const proID = this.localStorageService.getUserData(USER_TYPES.pro).user.id;
    const primaryAdvisor = client?.['user_request']?.user_people.find(user_people => user_people['advisor'] == 1);
    return primaryAdvisor ? primaryAdvisor['professional_id'] === proID : false;
  }

  /**
   * Retrieves client permissions list based on user ID and client request ID.
   * 
   * @param {string} userId - The user ID.
   * @param {string} clientRequestId - The client request ID.
   */
  public getClientPermissionsList(userId: string, clientRequestId: string) {
    this.proPermissionService.getAccessControl(userId, this.clientRequestId, undefined, true).subscribe({
      next: (permissions) => {
        this.permissionList = [];
        for (const permissionsKey in permissions) {
          const e = permissions[permissionsKey];
          if (e.add || e.edit || e.delete) {
            this.permissionList.push(e);
          }
        }
        this.localStorageService.storeData('permission-list', this.permissionList);
      },
      error: (e) => {
        this.commonHelper.httpResponseHandler(e.error)
      },
      complete: () => { this.loadingClientDetails = false }
    })
  }

  /**
   * search agent based on keyword
   * @param {string}keyword
   */
  public onSearchClient(keyword: string) {
    this.onSearch = true;
    this.searchKeyword = keyword;
    if (keyword === '') {
      this.getClientsList();
    } else {
      this.clientService.getClients(this.currentPage, this.recordsPerPage, this.listOrder, this.searchKeyword).subscribe((response) => {
        this.clientList = response.data.data;
        this.lastPage = response.data.last_page;
        this.totalClients = response.data['total'];
      });
    }
  }

  /**
   * pagination client list
   * @param {number}pageNo
   */
  public getClientList(pageNo?: number) {
    if (pageNo === this.currentPage) return;
    if (pageNo) {
      this.currentPage = pageNo;
    }
    this.onSearch = true;
    this.dataLoading = true;
    this.clientService
      .getClients(this.currentPage, this.recordsPerPage, this.listOrder, this.searchKeyword)
      .subscribe((response) => {
        this.clientList = response.data.data;
        this.totalClients = response.data['total'];
        this.paginationLinks = response.data.links;
        this.lastPage = response.data.last_page;
        if (this.clientList.length && !this.index) {
          this.getClientDetails(this.clientList[0], 0);
        } else {
          this.getClientDetails(this.clientList[this.index], this.index);
        }
      }, () => {
      }, () => this.dataLoading = false);
    this.dataLoading = false;
  }

  /**
   * Checks if cards are added
   * Updates the cardsAdded property based on the API response length.
   * @returns {void}
   */
  public checkCardsAdded() {
    this.cardService.getCardsApi(true).subscribe((response: any) => {
      this.cardsAdded = response?.data?.length > 0
    });
  }


  /**
   * Listens for events from a message modal component.
   * If the event indicates proceeding to add a card, navigates to the payment settings page.
   * @param {any} event - The event object emitted by the message modal.
   * @returns {void}
   */
  public messageModalListener(event) {
    if (event.proceedToAddCard) {
      void this.slugInterceptorService.navigate(['payment-settings'], { queryParams: { menu: 3 } }, true);
    }
  }

  /**
   * can show invoice table
   */
  public get showPaymentDetails() {
    return this.localStorageService.getDataByKey('proPaymentType') == 1;
    // return this.roleName === PROFESSIONAL_ROLE.professional ||  (this.commonHelper.isNullorUndefined(this.userData['professional_id']) && this.commonHelper.isNullorUndefined(this.userData['slug_url']))
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, true);
  }

  /**
   * get advisor term
   * @return{string}
   */
  public getAdvisorTerm() {
    // return PROFESSIONAL_TERMS?.[this.roleType]?.['agent'] ?? 'AGENT';
    return this.professionalUserService.getAdvisorTerm();
  }

  /**
   * scroll
   * @param {string} targetId
   */
  public scrollTo(targetId: string): void {
    document.getElementById(targetId).scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * search agent based on keyword
   * @param{string}keyword
   */
  public onSearchManager(keyword: string) {
    this.searchKeyword = keyword;
    if (keyword === '') {
      this.getManagers();
    } else {
      this.agentService.getManagers(this.currentPage, this.recordsPerPage, this.listOrder, this.searchKeyword).subscribe((response) => {
        this.managerList = response.data.data;
        this.lastPage = response.data.last_page;
      });
    }
  }

  /**
   * search agent based on keyword
   * @param{string}keyword
   */
  public onSearchAgent(keyword: string) {
    this.searchKeyword = keyword;
    if (keyword === '') {
      this.getAgentsList();
    } else {
      this.agentService.getAgents(this.currentPage, this.recordsPerPage, this.listOrder, this.searchKeyword).subscribe((response) => {
        this.agentsList = response.data.data;
        this.lastPage = response.data.last_page;
      });
    }
  }

  /**
   * open delete modal
   * @param{any}data
   * @param{string}content
   */
  public openConfirmationModal(data: any, content: any) {
    this.content = content;
    this.deleteId = data.id;
    this.modalService.open('delete-confirmation-modal');
    this.profileMenuToggleClient = false;
  }

  /**
   * delete client or agent
   * @param{string}client
   */
  public deleteUser(client: any) {
    this.submitLoaderDelete = true;
    if (client) {
      this.clientService.deleteClient({ id: this.deleteId }).subscribe((response) => {
        this.modalService.close('delete-confirmation-modal');
        this.toastrService.success(response.message);
        this.getClients();
        this.submitLoaderDelete = false;
      },
        (exception: any) => {
          this.submitLoaderDelete = false;

          this.commonHelper.httpResponseHandler(exception?.error);
        },
      );
    }
    this.profileMenuToggleClient = false;
  }

  /**
   * get all agents
   */
  public getClients() {
    this.profileMenuToggleClient = false;
    this.dataLoading = true;
    this.clientService
      .getClients(this.currentPage, this.recordsPerPage, this.listOrder, this.searchKeyword)
      .subscribe((response) => {
        this.clientList = response.data.data;
        this.paginationLinks = response.data.links;
        this.lastPage = response.data.last_page;
        this.dataLoading = false;
      });
  }
  /**
   * Professional payment listen for success payment to show Payment success modal
   * @obervable proPaymentStatusObservable observes values emitted from socket event
   */
  public proPaymentStatusListen() {
    this.commonService.proPaymentStatusObservable.pipe(take(1)).subscribe((response) => {
      const agent_id = this.localStorageService.getDataByKey('agent_id')
      if (response['payer_id'] === agent_id && response['is_payment_complete']) {
        setTimeout(() => {
          this.modalService.open('payment-success');
        }, 500)
        this.commonService.proPaymentStatusListener(null)
      }
    })
  }

  private roundToDecimalPlaces(value: number, decimalPlaces: number): number {
    let multiplier = Math.pow(10, decimalPlaces);
    return Math.round(value * multiplier) / multiplier;
}

  /**
   * Gets the company name from local storage.
   *
   * @returns {string} The company name retrieved from local storage.
   */
  public get companyName(): string {
    return this.localStorageService.getDataByKey('companyName')
  }
  /**
   * Get tool tip for cards
   */
  get cardsToolTip() : any {
    return toolTipValue;
  }
}
/**
 * enum for Tool tip
 */
enum toolTipValue {
  vaults_with_custodian = 'Total number of vaults that have a designated custodian',
  vaults_access_contact = 'Number of digital vaults that are providing access to contact their executors and beneficiaries.',
  vaults_created = 'Total number of digital vaults created in the last year.',
  vaults_updated = 'Number of digital vaults that have completed all their sections.',
  vaults_out_of_date = 'Number of digital vaults that have yet to complete their digital vault.',
  vaults_onboard_rate = 'Number of digital vaults that have been successfully onboarded compare to invite that were not fulfilled.',
  active_vaults = `Percentage of digital vaults that still have an active subscription. If your client's digital vault doesn't have an active subscription, they can't keep their information up to date.`
}
