import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlaidOnSuccessArgs } from 'ngx-plaid-link';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { AssetsService } from 'src/app/services/assets.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SECTIONS, SectionStatus } from '../../../../constants/application.const';
import { PersonalDetailsService } from '../../../services/personal-details.service';
import { PlaidOptionEmit } from '../../../sharedComponent/import-from-plaid-option/import-from-plaid-option.component';


@Component({
  selector: 'app-financial-assets',
  templateUrl: './financial-assets.component.html',
})
/**
 * Financial assets component
 */
export class FinancialAssetsComponent implements OnInit {
  public midFinancialAssets = 'add-financial-assets-modal';
  public midPlaid = 'FA_plaid-import-modal';
  public sectionSaveExitOptions: any;
  public userType: string;
  public clientRequestId: string;
  public isLoading: boolean = false;
  public vaultVideo: string;
  public paymentMode: number;
  public isProfessional: boolean;
  public clientHasPaidSubscription: boolean;
  public permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  /**
  * True signifies hiding the 'save' buttons and showing the 'progress' buttons
  */
  public buttonProgress: boolean = false;
  public canAccessForm: boolean;

  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private commonService: CommonService,
    private commonHelper: CommonHelper,
    private assetsService: AssetsService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService,
    private personalDetailsService: PersonalDetailsService,
  ) {
    this.paymentMode = this.localStorageService.getDataByKey('payment_mode');
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.clientRequestId = this.localStorageService?.getUserData()?.request?.id || this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.isProfessional = this.commonHelper.isProfessional
    this.sectionSaveExitOptions = {
      section: 3,
      type: 1,
    };
    if (this.clientRequestId) {
      this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.accountsAssets, SectionStatus.INCOMPLETE).subscribe();
    }

    this.modalService.modalVideoObservable.subscribe((video) => {
      this.vaultVideo = video;
    });
  }

  /**
   * open add Financial Asset modal
   */
  public openFinancialAssetModal(): void {
    this.modalService.open(this.midPlaid);
  }

  /**
   * Set loading flag
   * @param loading
   */
  public setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  /**
   * Button click handler
   * @param command
   */
  public click(command: string) {
    const isPro = this.userType !== 'Consumer';
    switch (command) {
      case 'Back':
        const url_back = isPro ? ['overview', 'finance-overview', this.clientRequestId] : ['insurance-details'];
        void this.slugInterceptorService.navigate(url_back);
        break;
      case 'ReturnToDashboard':
        const return_url = isPro ? ['overview', 'finance-overview'] : ['dashboard'];
        void this.slugInterceptorService.navigate(return_url);
        break;
      case 'MarkComplete':
        this.buttonProgress = true;
        this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.accountsAssets, SectionStatus.COMPLETE).subscribe();
        break;
      case 'SaveForLater':
        this.buttonProgress = true;
        break;
      case 'Next':
        const url_next = ['assets', 'retirement-assets', isPro ? this.clientRequestId : ''];
        void this.slugInterceptorService.navigate(url_next);
        break;
    }
  }

  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.modalService.setModalVideo(item);
    this.modalService.open('view-demo-video-modal');

  }

  public onPlaidSuccess($event: PlaidOnSuccessArgs): void {
    if (!$event?.token) return;
    let accountList;
    this.commonService.getPlaidAccounts($event?.token, this.userType !== 'Custodian' ? this.clientRequestId : undefined)
      .subscribe({
        next: (response: APIResponseModel) => {
          accountList = response.data?.financial;
          this.modalService.close(this.midPlaid);
          if (!accountList.length) {
            this.toastr.error(`You don't have any Plaid accounts related to Financial Assets`);
          }
          this.assetsService.accountListTrigger(accountList.length ? accountList : []);
        },
        error: exception => this.commonHelper.httpResponseHandler(exception?.error),
      },
      );
  }

  plaidOptionListener($event: PlaidOptionEmit) {
    if ($event.plaid?.onSuccess) {
      this.onPlaidSuccess($event.plaid.onSuccess);
    }
    if ($event.manual) {
      this.modalService.open(this.midFinancialAssets);
    }
  }

  /**
   * Listens for changes in the paid subscription event.
   * @param event The event containing subscription information.
   */
  public paidStatusEventListener(event) {
    if (Object.keys(event).length > 0) {
      this.clientHasPaidSubscription = event['clientHasPaidSubscription'];
    }
  }

    /**
     * Event listener to get permissions and set the canEdit flag.
     * @param {Object} event - The event object containing permissions.
     */
    public getPermissionsEventListener(event) {
      if (Object.keys(event).length > 0) {
        this.permissions = event?.['permissionsList'];
      }
    }

  /**
   * Determines whether the button should be disabled
   * @returns A boolean
   */
  public get disableButton(): boolean {
    return this.isProfessional && !this.clientHasPaidSubscription
  }
}
