<div class="lg:grid grid-cols-12 background-color-grey">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 my-4">
              <div class="col-span-12 sm:col-span-2">
                  <img src="assets/images/vehicle-details/vehicle-details.svg" alt="digital vault">
              </div>

              <div class="col-span-12 sm:col-span-10">
                  <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
                    Vehicle(s)
                  </h2>
                  <p class="text-sm text-grey tracking-wide text-regular">
                    Enter details on your automobiles.
                  </p>
              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-full'"></app-card-progress-bar>
              <!-- END: Progress Bar -->

              <div class="p-6">
                <div class="items-center">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    Vehicle(s)
                  </h4>
                  <p class="text-grey text-regular pt-1 text-sm">A machine, usually with wheels and an engine, used
                    for transporting people or goods,
                    especially on land.
                  </p>
                  <app-payment-message-text [clientHasPaidSubscription]="clientHasPaidSubscription"></app-payment-message-text>
                </div>

                <div class="bg-transparent border-card-grey rounded-xs mt-4 md:h-auto lg:h-20" *ngIf="preDefinedLoader">
                  <div class="grid grid-cols-12 md:h-auto lg:h-20 items-end sm:items-center gap-2 sm:gap-4 p-2 lg:px-4">
                    <div class="col-span-12 sm:col-span-2">
                      <div class="flex sm:items-center sm:justify-center border-circle sm:mx-auto h-12 w-12">
                        <app-shimmer-loading [loading]="preDefinedLoader" [shape]="'circle'" [width]="'4rem'">
                        </app-shimmer-loading>
                      </div>
                    </div>
                    <div class="col-span-8">
                      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                        <h4 class="text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate"></h4>
                      </app-shimmer-loading>
                      <p class="text-grey text-regular text-sm truncate">
                        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                          <span class="font-semibold"></span>
                        </app-shimmer-loading>
                      </p>
                    </div>
                    <div class="col-span-2">
                      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                        <img src="assets/images/about-yourself/edit.svg" class="cursor-pointer w-4 sm:cursor-pointer w-4 sm:w-4 sm:w-5 float-right cursor-pointer mb-1 mb-1"
                          alt="digital vault">
                      </app-shimmer-loading>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="!preDefinedLoader">
                  <ng-container *ngIf="!this.permissions?.view">
                    <p class="p-2 rounded-b-sm text-gray-700 background-color-grey mt-6">
                      You do not have permission to view these items
                    </p>
                  </ng-container>
                  <ng-container *ngIf="this.permissions?.view">
                    <div class="bg-transparent border-card-grey rounded-xs mt-4 md:h-auto lg:h-20" *ngFor="let data of vehicleArray">
                      <div class="grid grid-cols-12 md:h-auto lg:h-20 items-end sm:items-center gap-2 sm:gap-4 p-2 lg:px-4">
                        <div class="col-span-12 sm:col-span-2">
                          <div class="flex sm:items-center sm:justify-center border-circle sm:mx-auto rounded-full">
                            <app-image-component [image]="'assets/images/property-details/vehicles.svg'"
                            [class]="'object-cover rounded-full h-12 w-12'">
                          </app-image-component>
                            <!-- <app-image-component [image]="PIC_URL+data?.['vehicle_image']"
                              [class]="'object-cover rounded-full h-12 w-12'">
                            </app-image-component> -->
                          </div>
                        </div>
                        <div class="col-span-10 sm:col-span-8">
                          <h4 class="text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate">
                            {{data?.['vehicle_type']}} |
                            {{data?.['year']}} {{data?.['vehicle_make']}} {{data?.['vehicle_model']}}
                          </h4>
                          <p class="text-grey text-regular text-sm truncate" *ngIf="data?.['licence'] && data?.['vin']">
                            {{data?.['licence']}} | {{data?.['vin']}}
                          </p>
                        </div>
                        <div class="col-span-2">
                          <img src="assets/images/about-yourself/edit.svg" [ngClass]="{'cursor-pointer':canAccessForm}"
                            class="cursor-pointer w-4 sm:w-4 sm:w-5 float-right cursor-pointer mb-1" alt="digital vault" (click)="canAccessForm ?toggleModal(data):''">
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>

                <button type="button" (click)="toggleModal()" [disabled]="disableButton" [ngClass]="{'text-grey border-outline-grey' : disableButton}"
                  class="button button--lg w-full flex flex-row gap-3 justify-center items-center bg-transparent border-red rounded-xs h-12 md:h-16 focus:outline-none text-regular text-sm md:text-base font-semibold tracking-wide text-darkblue px-6 mt-6">
                  <img src="assets/images/accounts-assets/file-plus.svg" class="hidden md:block w-5" alt="digital vault"> Add
                  Vehicle Details
                </button>

                <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
                  <button (click)="click('Back')"
                    class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault">
                    Back
                  </button>
                  <div *ngIf="!buttonProgress" class="flex flex-col-reverse sm:flex-row justify-end items-center gap-4 w-full">
                    <button (click)="click('SaveForLater')"
                      [disabled]="preDefinedLoader || !canAccessForm"
                      class="flex items-center justify-center border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 text-regular px-6">
                      <div class="loading-blue mr-2" *ngIf="preDefinedLoader"></div>
                      Save for Later
                    </button>
                    <button (click)="click('MarkComplete')" [tooltip]="'You can update later should this information change'"
                      [disabled]="preDefinedLoader || !canAccessForm"
                      class="flex items-center justify-center mark-complete-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 text-regular px-6">
                      <div class="loading-blue mr-2" *ngIf="preDefinedLoader"></div>
                      Mark as Complete
                    </button>
                  </div>
                  <div class="flex flex-col-reverse sm:flex-row gap-4 justify-center w-full items-center sm:justify-end" *ngIf="buttonProgress">
                    <button *ngIf="buttonProgress && this.userType === 'Consumer'" (click)="click('ReturnToDashboard')"
                      [disabled]="preDefinedLoader"
                      class="sm:ml-auto border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 focus:outline-none text-regular text-white px-6">
                      Return to Dashboard
                    </button>
                    <button (click)="click('NextSection')" [disabled]="preDefinedLoader"
                      class="text-sm tracking-wide w-full sm:w-auto bg-darkblue hover:underline-text rounded-xs h-10 focus:outline-none text-regular text-white px-6 text-bold">
                      Next Section
                    </button>
                  </div>
                </div>
              </div>
              <!-- END: Leftside Card -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class=" col-span-12 md:col-span-6 lg:grid justify-center ">
    <about-yourself-right-content [formInputValue]="{}"
      [otherValues]="sectionSaveExitOptions"></about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->
</div>

<app-add-vehicle [editData]="editData" [isNewVehicle]="isNewVehicle" [permissions]="permissions"
[clientHasPaidSubscription]="clientHasPaidSubscription"
  (toggleModalEmitter)="modalEventListener($event)"></app-add-vehicle>
<app-payment-status-modal></app-payment-status-modal>
<app-payment-success></app-payment-success>
<app-payment-failure></app-payment-failure>
