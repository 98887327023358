<app-common-modal id="advisor-settings-modal" class="app-common-modal">
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="float-right w-8 shadow-md shadow-red-300 rounded-full cursor-pointer" alt="" (click)="closeModal()">


    <!-- Advisor Info -->
    <div class="border-b pb-4" *ngIf="bViewAdvisorInfo">
      <h4 class="text-regular text-darkblue font-semibold tracking-wide pb-2">
        {{agentTerm}} Information
      </h4>
      <div *ngIf="!advisorData?.['has_active_account'] && !disableInactiveAccountWarning" class="pb-4">
        <h4 class="error-alert">
          Inactive Account
        </h4>
        <div class="text-darkgrey text-base text-regular tracking-wide">
          Please note, this {{agentTerm}} does not have an active account with us, meaning that some features may be
          unavailable.
        </div>
      </div>
      <app-view-advisor-info [advisorInfo]="advisorData">
      </app-view-advisor-info>
    </div>

    <!-- Advisor Settings -->
    <div *ngIf="!bViewAdvisorInfo">
      <!-- ACL Modal -->
      <div class="border-b pb-4">
        <div class="items-center gap-4 row-gap-4 pb-2">
          <h4 class="text-regular text-darkblue font-semibold tracking-wide">
            Establish {{agentTerm}} Account Permissions
          </h4>
          <p class="text-grey text-regular tracking-wide pt-1 text-sm">
            Here you can define exactly how much access your {{agentTerm}} has to your information.
          </p>
        </div>
        <div class="mt-4">
          <div class="w-full relative rounded-md">
            <div class="mt-2">
              <app-access-control [advisorData]="advisorData"></app-access-control>
            </div>
          </div>
        </div>
      </div>
      <!-- ACL Modal End -->
    </div>
    <!-- Buttons Section -->
    <div class="flex flex-col flex-wrap sm:flex-row float-none gap-4 sm:float-right pt-6">
      <button (click)="onClickRemove()"
        class="flex items-center justify-center whitespace-no-wrap hover:underline-text cursor-pointer w-full sm:w-auto border-darkblue text-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-2 sm:px-4 text-base">
        Remove
        <div *ngIf="isLoading()" class="loading-red ml-2"></div>
      </button>
      <button (click)="editAdvisorInformation()" *ngIf="!advisorData?.['has_active_account'] && bViewAdvisorInfo"
        class="flex items-center justify-center whitespace-no-wrap hover:underline-text cursor-pointer w-full sm:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-2 sm:px-4 text-base">
        Edit Information
      </button>

      <button (click)="viewAdvisorInformation()" *ngIf="showSettingsToggle"
        class="flex items-center justify-center whitespace-no-wrap hover:underline-text cursor-pointer w-full sm:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-2 sm:px-4 text-base">
        {{!bViewAdvisorInfo ? 'View Information' : 'View Settings'}}
        <div *ngIf="isLoading()" class="loading-red ml-2"></div>
      </button>
      <button (click)="bViewAdvisorInfo ? closeModal() : save()"
        class="flex items-center justify-center whitespace-no-wrap hover:underline-text cursor-pointer w-full sm:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-2 sm:px-4 text-base">
        {{bViewAdvisorInfo ? 'Exit' : 'Save & Return'}}
      </button>
    </div>
  </div>
</app-common-modal>


<app-message-modal id="confirm-remove-message-modal" (buttonEmitter)="messageListener($event)"
  [setMessageData]="messageData" [setMessageSettings]="{showExit:false}">
</app-message-modal>