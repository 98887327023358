<div class="scroll-hidden">
  <div class="h-screen lg:grid grid-cols-12">
    <div class="col-span-12 flex">
      <div class="flex flex-col w-full h-full justify-center items-center ">
        <img alt="The-Estate-Registry" width="250" [src]="'assets/images/logo-old.png'" class="object-contain w-52 lg:w-60">
        <div class="pt-2 w-full">
          <h1 class="text-center text-grey text-semibold text-3xl mb-4 pt-5 flex items-center justify-center">
            <span>Logging in..</span><div class="loading-red ml-2"></div>
          </h1>
        </div>
      </div>
    </div>
  </div>
</div>
