import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ToggleOptionsInterface } from 'src/app/interface/common.interface';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';


@Component({
  selector: 'app-beneficiary-info',
  templateUrl: './beneficiary-info.component.html',
})
/**
 * Beneficiary information component
 */
export class BeneficiaryInfoComponent implements OnInit {
  public loading: boolean;
  public sectionSaveExitOptions: any;
  public requestId: string;
  public userType: string;
  public userData: Array<Object>;
  public faCheckCircle: any;
  public faCircleXmark: any;
  public toggleOptions: Array<ToggleOptionsInterface>;
  public agentTerm: string;
  public enableVaultCustodian: boolean;


  /**
   * @constructor
   */
  constructor(
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private personalDetailsService: PersonalDetailsService,
    private peopleService: PeopleService,
    private router: Router,
    private commonHelper: CommonHelper,
    private commonService: CommonService,
    private slugInterceptorService: SlugInterceptorService,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.loading = false;
    this.faCheckCircle = faCheckCircle;
    this.faCircleXmark = faCircleXmark;
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.enableVaultCustodian = this.localStorageService.getDataByKey('enableVaultCustodian');
    this.sectionSaveExitOptions = {
      section: 2,
      // type: 2,
    };
    this.toggleOptions = [
      {
        displayText: 'NO',
        value: 0,
      },
      {
        displayText: 'YES',
        value: 1,
      },
    ];
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }
}
