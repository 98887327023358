import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';


@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css'],
})
export class PaymentDetailsComponent implements OnInit {
  public userType: string;
  public requestId: string;
  public sectionToggle = {
    saved_cards: false,
    card_details: false,
  };
  public customerSupportMail: string;
  public customerSupportPhone: number;


  constructor(private localStorageService: LocalStorageService, private route: ActivatedRoute, private commonService: CommonService) {
  }


  ngOnInit(): void {
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.getContactDetails();
  }


  public getContactDetails() {
    this.commonService.getContactUsDetails().subscribe((response) => {
      this.customerSupportMail = response.data?.support_email;
      this.customerSupportPhone = response.data?.support_number;
    });
  }
}
