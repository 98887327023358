import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIStatusCodes } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { ConsumerPaymentMethod, PAYMENT_STATUS, USER_TYPES } from 'src/constants/application.const';
import { CommonHelper } from '../../helper/common.helper';
import { LoadStateHelper } from '../../helper/load-state.helper';
import { CheckoutService } from '../checkout/checkout.service';
import { ConsumerSubscriptionService } from '../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-grace-period',
  templateUrl: './grace-period.component.html',
  styleUrls: ['./grace-period.component.css'],
})

/**
 * Grace Period Component  */
export class GracePeriodComponent implements OnInit {
  public allowTransferPayment: boolean;
  loader: LoadStateHelper;
  userType: any;
  isChecked: boolean = false;
  mobile: boolean = false;
  paymentCompleteStatus: number;
  monthlyPackageDetails: number;
  isFirstMonth: boolean;
  subscriptionCancelledCount: number;

  /**
   * constructor
   */
  constructor(
    private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService,
    private router: Router,
    private commonService: CommonService,
    private toastr: ToastrService,
    private subscriptionService: ConsumerSubscriptionService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private sessionStorageService: SessionStorageService,
    private checkoutService: CheckoutService,
    loadTracker: LoadStateHelper,
  ) {
    this.loader = loadTracker;
  }

  /**
   * loaded on initialization
   */
  ngOnInit(): void {
    const currentUserSession = this.sessionStorageService.getDataFromSession(USER_TYPES.user);
    this.userType = this.localStorageService.getDataByKey('role');
    this.localStorageService.storeData('DigitalVaultAgreementChecked', false);
    this.isChecked = false;
    this.onResize();
    this.commonService.showTVaultPricingObserve.subscribe((data) => {
      this.allowTransferPayment = (data == 1);
    });
    // If a user opens a new tab and enters a URL, they should be redirected to the login page.
    if (!currentUserSession) {
      void this.slugInterceptorService.navigate(['/login']);
    }

    this.getSubscriptionDetails();
  }

  private checkGracePeriod() {
    // payment state is not in grace period means navigate to dashboard
    this.isFirstMonth = this.localStorageService.getDataByKey('is_first_month');
    this.subscriptionCancelledCount = this.localStorageService.getDataByKey('subscription_cancellation_count');

    if ((this.paymentCompleteStatus == 1) || (this.isFirstMonth && this.paymentCompleteStatus == 2 && this.subscriptionCancelledCount != 1)) {
      void this.slugInterceptorService.navigate(['/dashboard']);
    }

    setTimeout(() => {
      if (this.paymentCompleteStatus == 4) {
        this.modalService.open('payment-process');
      }
      if (this.paymentCompleteStatus == 5) {
        this.modalService.open('payment-failure');
      }
    }, 100);

  }

  /**
   * redirect to payment page
   */
  public redirectToPaymentPage() {
    if (this.userType === 'Custodian') {
      void this.router.navigate(['/executor-custodian/payment']);
    } else {
      this.localStorageService.storeData('isClicked', true);
      this.checkoutService.getPaymentOptions().subscribe({
        next: paymentOptions => {
          paymentOptions.find(item => item.id === 'subscription_monthly').selected = true;
          const ids: string[] = [];
          paymentOptions.forEach(e => e.selected ? ids.push(e.id) : null);
          this.checkoutService.setCart(ids);
        },
        complete: ()=> void this.slugInterceptorService.navigate(['/checkout'])
      });
    }
  }

  /**
   * window resize
   */
  @HostListener('window:resize')
  onResize() {
    this.mobile = window.innerWidth <= 767;
  }

  /**
   * getSubscriptionDetails for getting monthly package Details
   */
  public getSubscriptionDetails() {
    const slugSubscriptionAmount = this.localStorageService.getDataByKey('slug_subscription_amount');
    const paymentMode = this.localStorageService.getDataByKey('payment_mode');
    this.loader.set('subscriptionService');
    this.subscriptionService.getSubscriptionDetailsApi().subscribe({
      next: r => {
        this.paymentCompleteStatus = r.data.is_payment_complete;
        this.monthlyPackageDetails = r?.data?.subscription_price;
        if(paymentMode == ConsumerPaymentMethod.PASS_TO_CONSUMER && slugSubscriptionAmount>=0){
          this.monthlyPackageDetails = slugSubscriptionAmount
        }
        // this.monthlyPackageDetails = r.data.package.find(p => p['name'] == 'Monthly')?.['price'];
        console.log('check grace period', this.paymentCompleteStatus);
        this.checkGracePeriod();
        this.loader.set('subscriptionService', false);
      },
      error: e => {
        this.loader.set('subscriptionService', false);
        if (e?.error?.statusCode == APIStatusCodes.UNAUTHORIZED) {
          this.toastr.error('Please Login to Continue');
          void this.slugInterceptorService.navigate(['/login']);
          return;
        }
        this.commonHelper.httpResponseHandler(e.error);
      },
    });
  }

  public get showImportantInfo(): boolean {
    return this.paymentCompleteStatus == PAYMENT_STATUS.onDeletionPeriod || this.paymentCompleteStatus == PAYMENT_STATUS.onRefundPeriod;
  }

  public get showIntro(): boolean {
    return this.paymentCompleteStatus == 0 || this.paymentCompleteStatus == 5 || this.paymentCompleteStatus == 4;
  }

}
