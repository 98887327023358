import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModelService } from "../../services/common-model.service";
import { LPEmitterInterface, LPSettingsInterface } from "../list-people/list-people-interfaces";
import { AssignBeneficiaryEmitInterface, AssignBeneficiarySettings } from "./assign-beneficiary-interfaces";


@Component({
  selector: 'app-assign-beneficiary-modal',
  templateUrl: './assign-beneficiary-modal.component.html'
})
export class AssignBeneficiaryModalComponent implements OnInit, OnChanges {
  @Input() modal_id: string = 'assign-beneficiary-modal';
  @Output() submitEmitter = new EventEmitter<AssignBeneficiaryEmitInterface>();
  @Input() settings: AssignBeneficiarySettings;
  private readonly BENEFICIARY_LIMIT: number = 3;
  public lpSettings: LPSettingsInterface;


  constructor(private modalService: CommonModelService) {
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.settings) {
      this.updateUI();
    }
  }


  ngOnInit(): void {
    this.lpSettings = {
      textInput: {
        show: true,
        placeholder: 'Enter %',
      },
      checkbox: {
        show: true
      }
    }
  }


  /**
   * Get total percent allocated.
   */
  public getAllocated(): number {
    let sum = 0;
    this.settings.user_people.forEach((e) => {
      sum += +(e.textInput?.text ?? 0);
    });
    return sum;
  }


  /**
   * Remove selected beneficiaries with 0% allocation.
   * @private
   */
  private cleanSelectedBeneficiaries() {
    this.settings.user_people.forEach(e => {
      if (e.checkbox.isChecked && !+e.textInput.text) {
        e.checkbox.isChecked = false;
      }
    });
  }


  /**
   * Return true if total allocation is more than 100%.
   */
  public isBadAllocation(): boolean {
    return 100 - this.getAllocated() < 0;
  }


  /**
   * Return true if allocation is 100% or 0%.
   * 100% for proper allocation.
   * 0% for removing allocation.
   */
  public get isSubmitValid(): boolean {
    return this.getAllocated() == 100 || (this.settings?.allow_zero && this.getAllocated() == 0);
  }


  /**
   * submit percentage details
   */
  public submit() {
    this.cleanSelectedBeneficiaries();
    const user_people: Array<{ id: string, percentage: number }> = [];
    this.settings.user_people.filter(e => e.checkbox.isChecked).forEach(e =>
      user_people.push({
        id: e.id,
        percentage: +e.textInput.text
      })
    );
    this.submitEmitter.emit({user_people: user_people, type: this.settings.type});
    this.closeModal();
  }


  /**
   * close modal
   */
  public closeModal(): void {
    this.submitEmitter.emit({closed_modal: true});
    this.modalService.close(this.modal_id);
  }


  /**
   * add beneficiary if beneficiary is not added already in beneficiary section
   */
  public addBeneficiary() {
    this.modalService.close(this.modal_id);
    this.modalService.open('add-beneficiary-modal');
  }


  /**
   *
   */
  public refreshListing($event) {
    if ($event) {
      this.submitEmitter.emit({trigger_refresh: true});
    }
    this.modalService.open(this.modal_id);
  }


  /**
   * Update UI-related elements and fields.
   * @private
   */
  private updateUI() {
    // update text hiding
    this.settings.user_people.forEach(person => {
      person.textInput.hideText = !person.checkbox.isChecked;
      if (person.textInput.hideText) {
        person.textInput.text = '';
      }
    });

    // Check Beneficiary Limits
    // If reached beneficiary limit
    const numChecked = this.settings.user_people.filter(e => e.checkbox.isChecked).length;
    if (numChecked >= this.BENEFICIARY_LIMIT) {
      this.settings.user_people.forEach(e => {
        if (!e.checkbox.isChecked) {
          e.checkbox.disableCheckbox = true;
        }
      });

    } else {
      // If below limit
      this.settings.user_people.forEach(e => e.checkbox.disableCheckbox = false);
    }
  }


  /**
   * Listener for {@link ListPeopleComponent}.
   * @param $event
   */
  public listenerListPeople($event: LPEmitterInterface) {
    // Handle TextInput events
    if ($event.textInput) {
      let strValue: string = $event.textInput.event.target['value'];

      if (strValue != '') {
        let value: number = +strValue;

        // Handle NaN
        value = value || 0;

        // Enforce range 0 -> 100
        value = Math.min(Math.abs(value), 100);

        // Update value
        strValue = String(value);
        $event.textInput.event.target['value'] = strValue;
        $event.person.textInput.text = strValue;
      }
    }
    this.updateUI();
  }
}
