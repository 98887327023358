import { MapsAPILoader } from '@agm/core';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable } from 'rxjs';
import { locationDetailsInterface } from '../interface/personalDetails.interface';
import { APIResponseModel } from '../interface/response.interface';
import { CommonService } from '../services/common.service';
import { addressData } from '../viewData/address.seperation.data';

const _ = require('lodash');


@Injectable({
  providedIn: 'root',
})
/**
 * Autofill Address Helper
 */
export class AddressHelper {
  private autocomplete: google.maps.places.Autocomplete = undefined;
  private countryList = [];
  private stateList = [];
  private selectedCountry = [];
  private renderer: Renderer2;

  constructor(
    private commonService: CommonService,
    private mapsAPILoader: MapsAPILoader,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Autofill address filter
   *
   * @param {Array<any>} fullAddress
   * @return {locationDetailsInterface}
   */
  private commonAddressFilter(fullAddress: Array<any>): locationDetailsInterface {
    const cityAllVariations = [];
    const addressInterface: locationDetailsInterface = {
      address: '',
      country: '',
      state: '',
      city: '',
      zipcode: '',
    };
    fullAddress?.forEach((element: any) => {
      if (element?.types?.length) {
        const field_name = this.findByMatchingTypes(element?.types);

        switch (field_name?.field_name) {
          case 'address':
            addressInterface.address += addressInterface.address == '' ? element?.long_name : ' ' + element?.short_name;
            break;
          case 'state':
            addressInterface.state = element?.long_name;
            break;
          case 'city':
            field_name.long_name = element?.long_name;
            cityAllVariations.push(field_name);
            break;
          case 'postal_code':
            addressInterface.zipcode = element?.long_name;
            break;
          default:
            break;
        }
      }
    });

    if (addressInterface?.address == '') {
      if (fullAddress?.length > 1) {
        addressInterface.address = `${fullAddress[0].long_name} ${fullAddress[1].short_name}`;
      } else if (fullAddress?.length > 0) {
        addressInterface.address = fullAddress[0].long_name;
      }
    }

    /**
     * Search city from multiple city variants
     *
     */
    if (cityAllVariations.length > 0) {
      const types_priority = ['locality', 'administrative_area_level_3', 'sublocality', 'neighborhood'];
      for (let type of types_priority) {
        const foundCity = getSearchCity(cityAllVariations, type);
        if (foundCity) {
          addressInterface.city = foundCity.long_name;
          break;
        }
      }
      // If none of the priorities matched, take last item
      if (!addressInterface.city) {
        addressInterface.city = cityAllVariations[cityAllVariations.length - 1].long_name;
      }
      // return addressInterface;
    }
    return addressInterface;

    /**
     * To find city
     *
     * @param {Array<any>} cityData
     * @param {String} type
     * @return any
     */
    function getSearchCity(cityData: Array<any>, type: string) {
      return _.find(cityData, (e: { type: string; }) => e.type === type);
    }
  }

  /**
   * Filter by type of address from JSON data
   *
   * @param {Array} type
   * @return any
   */
  private findByMatchingTypes(type: Array<any>) {
    let addressFilterByField: any;
    try {
      addressFilterByField = _.filter(addressData, ((data: { type: any; }) => data.type === type[0]))[0];
    } catch (error) {
      addressFilterByField = undefined;
    }
    return addressFilterByField;
  }

  /**
   * To find selected addres country short name
   * @param{any} defaultCountry
   * @return{Promise}
   */
  private findSelectedCountryShortName(defaultCountry: any): Promise<any> {
    if (!this.countryList.length) {
      return new Promise((resolve, reject) => {
        this.commonService.getCountries().subscribe({
            next: (response: APIResponseModel) => {
              this.countryList = response.data;
              this.selectedCountry = response?.data?.filter((country: { id: any; }) => country.id === defaultCountry);
              if (this.countryList?.length > 0) {
                resolve(this.countryList[0]['iso2']);
              }
            }, error: (error: any) => reject(error),
          },
        );
      });
    } else {
      return new Promise((resolve) => {
        this.selectedCountry = this.countryList.filter((country) => country.id === defaultCountry);
        resolve(this.selectedCountry[0]['iso2']);
      });
    }
  }

  /**
   * Initialize Google Maps autocomplete for a particular {@link HTMLInputElement}.
   * @param countryID
   * @param searchElement
   */
  initMap(countryID: any, searchElement: HTMLInputElement) {
    return new Observable<locationDetailsInterface>((observer) => {
      if (countryID && searchElement) {
        this.commonService.getStates(countryID).subscribe({
          next: (response: APIResponseModel) => this.stateList = response?.data || [],
          error: () => this.stateList = [],
        });
        this.findSelectedCountryShortName(countryID).then((value: any) => {
          this.mapsAPILoader.load().then(() => {
            const opts = value ? { componentRestrictions: { country: value } } : undefined;
            // Declare the autocomplete variable here to prevent the issue where the first attempt to getPlace() returns undefined, followed by the expected Google Place object on the second attempt.
            this.autocomplete = new google.maps.places.Autocomplete(searchElement, opts);
            this.autocomplete.setFields(['address_components']);
            this.autocomplete.addListener('place_changed', () => {
              const locationDetails: locationDetailsInterface = {
                address: '',
                country: countryID,
                state: '',
                city: '',
                zipcode: '',
              };
              const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
              console.log('place', place);
              const userAddress = this.commonAddressFilter(place?.address_components);
              const getStateId = this.stateList?.find((state) =>
                String(state.name).toLowerCase() == String(userAddress.state).toLowerCase(),
              )?.id; // get state id from state list
              locationDetails.address = userAddress.address;
              locationDetails.zipcode = userAddress.zipcode;
              locationDetails.city = userAddress.city;
              locationDetails.state = getStateId ?? userAddress.state;

              observer.next(locationDetails);
              if (value && userAddress.state && !getStateId) { // if not in state list adding new state
                this.commonService.getStateDetail(countryID, userAddress.state).subscribe({
                  next: (stateResponse: APIResponseModel) => {
                    if (stateResponse?.status) {
                      locationDetails.state = stateResponse?.data?.id;
                      observer.next(locationDetails);
                    }
                  },
                  error: (e) => {
                    locationDetails.state = getStateId;
                    observer.next(locationDetails);
                    observer.error(e);
                  },
                });
              }
            });
          }).finally(() => {
            const errorInterval = setInterval(() => {
              const hasError = searchElement.classList.contains('gm-err-autocomplete');
              if (hasError) {
                searchElement.classList.remove('gm-err-autocomplete');
                searchElement.placeholder = '';
                searchElement.disabled = false;
                searchElement.style.backgroundImage = '';
                clearInterval(errorInterval);
              }
            }, 100);
          });
        });
      } else {
        observer.error({
          error: 'Missing Value',
          values: {
            countryID: countryID,
            searchElement: searchElement,
          },
        });
      }
    });
  }

  /**
   * Remove Pac container from google map places
   *
   * @param {any} autocomplete
   * @return {any}
   */
  removeAutocompletePacContainer(autocomplete: any = this.autocomplete): any {
    if (autocomplete) {
      const place: Object = autocomplete?.['gm_accessors_']?.place;
      let placeKey: string;
      let input: { [x: string]: { remove: () => any; }; };
      let inputKey: string;
      if (typeof place === 'object') {
        placeKey = Object.keys(place).find((value) =>
          (typeof (place[value]) === 'object') && place[value].hasOwnProperty('gm_accessors_'));
        input = place?.[placeKey]?.['gm_accessors_']?.input[placeKey];
        inputKey = Object.keys(input).find((value) => input[value]?.['classList']?.contains('pac-container'));
      }
      const retValue = input?.[inputKey]?.remove();
      if (autocomplete === this.autocomplete) {
        this.autocomplete = retValue;
      }
      return retValue;
    }
  }

  /**
   *
   * @param{HTMLElement} moveMapHere
   */
  movePacContainer(moveMapHere: HTMLElement) {
    const pacContainer = document.querySelector('.pac-container');
    if (pacContainer && moveMapHere) {
      this.renderer.appendChild(moveMapHere, pacContainer);
    }
  }
}
