<div [attr.aria-disabled]="disabled"
     class="h-10 sm:h-12 input py-3 px-4 text-regular text-sm focus-within:text-gray-700 rounded-xs appearance-none border block focus:outline-none rounded flex flex-row items-center justify-between"
     (click)="clickDropdown()" [ngClass]="{'form-input-error' : enableError && !disabled,'cursor-none' : disabled}">
  <a class="truncate {{selectedOption?.displayText ? 'text-darkgrey' : 'placeholder-grey'}}"
     [ngClass]="{'placeholder-grey' : disabled}">
    {{getDisplayText(selectedOption, true)}}
  </a>
  <div>
    <img src="assets/images/about-yourself/angle-down-arrow.png" class=" w-3 " alt="digital vault">
  </div>
</div>
<div class="absolute w-full z-10" [class]="class" *ngIf="toggleListing">
  <ul class=" input w-full border border-t-0 up-arrow bg-white rounded-xs mt-0 rounded-br rounded-bl">
    <div class=" mt-0 p-2 ">
      <input type="text" *ngIf="showSearchBox"
        class=" h-10 sm:h-12 input py-3 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
        [placeholder]="placeholder | titleCaseExceptPipe" [(ngModel)]="searchKeyword">
    </div>
    <div
      class="overflow-y-auto {{loader?'flex items-center justify-center':''}} {{items?.length > 4 ? 'h-48' : 'h-auto'}}">
      <div class="loading-red mr-2 override-size" *ngIf="loader"></div>
      <ng-container *ngIf="!loader">
        <li class="px-3 text-grey text-sm py-2 flex justify-between items-center select-option"
          *ngFor="let item of items | textFilterList:[searchKeyword,'displayText']; let i = index"
          (click)="onSelectChange(item.value)" [ngClass]="{ 'list-active-bg': selectedOption?.value === item?.value }">
          {{getDisplayText(item)}}
          <img src="assets/images/about-yourself/list-selected.svg" class="w-4" alt="digital vault"
            *ngIf="selectedOption?.value === item?.value">
        </li>
      </ng-container>
    </div>
  </ul>
</div>
