import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../sharedComponent/shared.module';
import { GetOrganized4060Component } from './consumer-40-60/consumer-40-60-get-organized/consumer-40-60-get-organized.component';
import { QuestionShort4060Component } from './consumer-40-60/consumer-40-60-questions-short/consumer-40-60-questions-short.component';
import { Consumer4060RootComponent } from './consumer-40-60/consumer-40-60-root.component';
import { ConsumerCompanyNewComponent } from './consumer-company-new/consumer-company-new.component';
import { ConsumerFocusedFooterComponent } from './consumer-focused-footer/consumer-focused-footer.component';
import { ConsumerFocusedNavbarComponent } from './consumer-focused-navbar/consumer-focused-navbar.component';
import { ConsumerFocusedComponent } from './consumer-focused/consumer-focused.component';
import { ConsumerLandingFooterComponent } from './consumer-landing-footer/consumer-landing-footer.component';
import { ConsumerLandingIntroComponent } from './consumer-landing-intro/consumer-landing-intro.component';
import { ConsumerLandingNavbarNewComponent } from './consumer-landing-navbar-new/consumer-landing-navbar-new.component';
import { ConsumerLandingNavbarComponent } from './consumer-landing-navbar/consumer-landing-navbar.component';
import { ConsumerLandingStepsComponent } from './consumer-landing-steps/consumer-landing-steps.component';
import { ConsumerMarketingComponent } from './consumer-marketing/consumer-marketing.component';
import { NoticeToVaultHoldersComponent } from './notice-to-vault-holders/notice-to-vault-holders.component';
import { CompletePurchaseComponent } from './subscription/complete-purchase/complete-purchase.component';
import { CreateSubscriptionAccountComponent } from './subscription/create-subscription-account/create-subscription-account.component';
import { PaymentInformationComponent } from './subscription/payment-information/payment-information.component';
import { SubscriptionPlanComponent } from './subscription/subscription-plan/subscription-plan.component';
import { SubscriptionComponent } from './subscription/subscription.component';


@NgModule({
  declarations: [
    ConsumerLandingNavbarComponent,
    ConsumerLandingNavbarNewComponent,
    ConsumerLandingFooterComponent,
    ConsumerCompanyNewComponent,
    NoticeToVaultHoldersComponent,
    ConsumerFocusedComponent,
    ConsumerFocusedNavbarComponent,
    ConsumerFocusedFooterComponent,
    ConsumerMarketingComponent,
    QuestionShort4060Component,
    GetOrganized4060Component,
    Consumer4060RootComponent,
    SubscriptionComponent,
    CreateSubscriptionAccountComponent,
    SubscriptionPlanComponent,
    PaymentInformationComponent,
    CompletePurchaseComponent,
    ConsumerLandingIntroComponent,
    ConsumerLandingStepsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
  ],
  exports: [
    ConsumerLandingNavbarComponent,
    ConsumerLandingNavbarNewComponent,
    ConsumerLandingFooterComponent,
  ],
})
/**
 * nav bar module to reuse across component
 */
export class ConsumerLandingPageModule {
}
