import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonModalComponent } from '../sharedComponent/common-modal/common-modal.component';


@Injectable({
  providedIn: 'root',
})
/**
 * Service file for common modal
 */
export class CommonModelService {
  private modals: CommonModalComponent[] = [];
  private openModals: string[] = [];
  private modalVideo: any;
  public modalVideoObservable: Observable<string>;

  constructor() {
    this.modalVideo = new BehaviorSubject('');
    this.modalVideoObservable = this.modalVideo.asObservable();
  }

  /**
   * Add modal. Modals must be unique by ID. If a duplicate modal is added, the previous modal with a matching ID will
   * be removed.
   * @param {any} modal
   */
  public add(modal: CommonModalComponent): void {
    // add modal to array of active modals
    if (this.contains(modal)) {
      this.remove(modal.id);
    } // In cases where multiple download modals are implemented, the second one is preferred to keep for functionality.
    this.modals.push(modal);
  }

  /**
   * Check if the specified modal exists in the service. Can be used on a modal object containing an id field,
   * or a string containing the ID to look for.
   * @param modal Modal object containing id field or modal ID string.
   */
  public contains(modal: CommonModalComponent | string): boolean {
    return this.modals.filter((x) => (x.id === (typeof modal == 'string' ? modal : modal.id))).length > 0;
  }

  /**
   * remove modal
   *
   * @param {string} id
   */
  public remove(id: string): void {
    // remove modal from array of active modals
    this.modals = this.modals.filter((x) => x.id !== id);
  }

  /**
   * open model based on id
   * @param {string} id
   */
  public open(id: string): void {
    const openIndex = this.openModals.findIndex(e => e == id);
    // Existing ID floats to top of list.
    if (openIndex >= 0) {
      this.openModals.splice(openIndex, 1);
    }
    this.openModals.push(id);
    this.updateOpenModals();
  }


  /**
   * open model based on id
   * @param {string} id
   */
  private openModal(id: string): boolean {
    const modal = this.getModal(id);
    if (modal) {
      modal.open();
      return true;
    } else {
      console.error(`open: Modal ${id} does not exist!`);
      return false;
    }
  }

  /**
   * close model based on id
   * @param {string} id
   */
  public close(id: string): void {
    const deleteID = this.openModals.findIndex(e => e == id);
    if (deleteID >= 0) {
      this.openModals.splice(deleteID, 1);
      this.updateOpenModals();
    }
  }

  /**
   * All modals are closed, then the
   * @private
   */
  private updateOpenModals() {
    this.modals.forEach(e => {
      if (this.isOpen(e['id'])) {
        e.close();
      }
    });
    if (this.openModals.length > 0) {
      const openID = this.openModals[this.openModals.length - 1];
      this.openModal(openID);
    }
  }

  /**
   * See: {@link CommonModalComponent.isOpen}
   * @param id
   */
  public isOpen(id: string): boolean {
    return this.getModal(id)?.isOpen();
  }

  /**
   * @param id
   * @return the modal which matches the given id from {@link modals}.
   * @private
   */
  private getModal(id: string) {
    return this.modals.find((x) => x.id === id);
  }

  public setModalVideo(video: string) {
    this.modalVideo.next(video);
  }
}
