import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel, APIStatusCodes } from 'src/app/interface/response.interface';
import { ProCheckoutService } from 'src/app/professional-dashboard/services/pro-checkout.service';
import { ProfessionalInvoiceService } from 'src/app/professional-dashboard/services/professional-invoice.service';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { INVOICE_TYPES, LOCAL_STORAGE_UPDATE_STATUS, ORDER_VALUE, PAYMENT_TYPE, PROFESSIONAL_ROLE, SELECT_VALUE, USER_TYPES } from 'src/constants/application.const';

@Component({
  selector: 'app-professional-payment-settings',
  templateUrl: './professional-payment-settings.component.html',
  styleUrls: ['./professional-payment-settings.component.css'],
})
/**
 * Professional Payment Management class
 */
export class ProfessionalPaymentSettingsComponent implements OnInit {

  public menuActive: number;
  public currentPage = 1;
  public paymentCount = 5;
  public paymentOrder = 'desc';
  public searchKeyword = '';
  public index: number;
  public paymentType = 'new';
  public loader: boolean;
  public asterisk: any;
  public profileMenuToggle: boolean;
  public paymentData: any;
  public paginationLinks: Array<any>;
  public lastPage: number;
  public fromNo: number;
  public toNo: number;
  public selectOptions: Array<Object>;
  public orderOptions: Array<Object>;
  public paymentOptions: Array<Object>;
  public chipsList: Array<IPaymentSettingsChips>;
  public currentFilter: number;
  public toReload = false;
  public userData: any;
  public roleName: string;
  public readonly PROFESSIONAL_ROLE = PROFESSIONAL_ROLE;

  public selectedInvoiceType: any;
  public readonly invoiceTypes = INVOICE_TYPES;
  public pspDebitMode: number;
  public isPayVaultRegistration: boolean;
  public invoiceSettingsLoader: boolean = false;
  public responsiveMenuToggle = false;
  public mobileMenuName: string;


  /**
   * constructor
   */
  constructor(
    private route: ActivatedRoute,
    private professionalUserService: ProfessionalUserService,
    private commonHelper: CommonHelper,
    private commonService: CommonService,
    private modalService: CommonModelService,
    private slugInterceptorService: SlugInterceptorService,
    private proCheckoutService: ProCheckoutService,
    private localStorageService: LocalStorageService,
    private invoiceService: ProfessionalInvoiceService,
    private toastr: ToastrService,
  ) {
  }

  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParam: Params) => {
      this.menuActive = +queryParam['menu'];
    });
    this.userData = this.localStorageService.getUserData(USER_TYPES.pro)?.user
    this.isPayVaultRegistration = Number(this.localStorageService.getDataByKey('proPaymentType')) === 1;
    this.pspDebitMode = Number(this.userData?.debit_mode)
    this.selectedInvoiceType = Number(this.localStorageService.getUserData(USER_TYPES.pro)?.user?.invoice_mode);
    this.asterisk = faAsterisk;
    this.selectOptions = SELECT_VALUE;
    this.orderOptions = ORDER_VALUE;
    this.paymentOptions = PAYMENT_TYPE;
    this.chipsList = [
      { value: 1, displayText: 'Payment Management' },
      { value: 2, displayText: 'Payment History' },
      { value: 3, displayText: 'Manage Cards' },
    ];
    this.setupInvoiceSettings();
    this.currentFilter = 1;
    this.menuChange(this.menuActive);
    this.proPaymentStatusListen();
    this.professionalUserService.currentUserObserve?.subscribe(
      data => this.setUserData(data ?? this.localStorageService.getUserData(USER_TYPES.pro).user),
    );

    this.roleName = this.userData.role_name;
    this.redirectIfNotAFirmAccount();
    this.mobileMenuName = 'PAYMENT SETTINGS';
  }

  /**
   * Sets up invoice settings menu based on certain conditions.
   * Adds 'Invoice Settings' to the chipsList if PayVault registration is enabled
   * and PSP debit mode is set to 1.
   */
  public setupInvoiceSettings() {
    if (this.isPayVaultRegistration && this.pspDebitMode == PSPDebitMode.INSTANT) {
      this.chipsList.push({
        value: 4, displayText: 'Invoice Settings'
      })
    }
  }

  /**
   * on menu change
   * @param {number}menu
   */
  public menuChange(menu: number) {
    this.profileMenuToggle = false;
    this.menuActive = menu;
    void this.slugInterceptorService.navigate(['payment-settings'], {
      queryParams: { menu: menu },
    }, true);
    if (menu === PaymentSettingsChip.PaymentManagement) {
      this.searchKeyword = '';
      this.paymentType = 'new';
      this.getPaymentDetails();
    } else if (menu === PaymentSettingsChip.PaymentHistory) {
      this.searchKeyword = '';
      this.paymentType = 'history';
      this.getPaymentDetails();
    }
  }


  /**
   * Get whether the logged-in user is not a firm account
   */
  public get isNotAFirmAccount(): boolean {
    return this.roleName !== PROFESSIONAL_ROLE.professional && (!this.commonHelper.isNullorUndefined(this.userData.professional_id) || !this.commonHelper.isNullorUndefined(this.userData.slug_url))
  }

  /**
   * get payment details
   */
  public getPaymentDetails() {
    this.profileMenuToggle = false;
    this.loader = true;
    this.professionalUserService
      .getPaymentDetails(
        this.paymentType,
        this.currentPage,
        this.paymentCount,
        this.paymentOrder,
        this.searchKeyword,
      )
      .subscribe((response: APIResponseModel) => {
        this.loader = false;
        this.paymentData = response.data.data;
        this.fromNo = response.data.from;
        this.toNo = response.data.to;
        this.paginationLinks = response.data.links;
        this.lastPage = response.data.last_page;
      });
  }

  private setUserData(data: any) {
    this.userData = data;
    this.userData['full_name'] = `${this.userData['first_name'] ?? ''} ${this.userData['last_name'] ?? ''}`.trim();
  }

  /**
   * search invoice by number
   * @param{string}keyword
   */
  public onSearchInvoice() {
    this.profileMenuToggle = false;
    this.loader = true;
    this.professionalUserService
      .getPaymentDetails(
        this.paymentType,
        this.currentPage,
        this.paymentCount,
        this.paymentOrder,
        this.searchKeyword,
      )
      .subscribe((response) => {
        this.loader = false;
        this.paymentData = response.data.data;
        this.fromNo = response.data.from;
        this.toNo = response.data.to;
        this.lastPage = response.data.last_page;
      });
  }

  /**
   * toggle dropdown
   * @param{number}ind
   */
  public toggleDropDown(ind: number) {
    this.profileMenuToggle = !this.profileMenuToggle;
    this.index = ind;
  }

  /**
   * payment data
   * @param {number}pageNo

   */
  public getPaymentDetailsByPage(pageNo?: number) {
    if (this.currentPage === pageNo) return
    this.profileMenuToggle = false;
    if (pageNo) {
      this.currentPage = pageNo;
    }

    this.loader = true;
    this.professionalUserService
      .getPaymentDetails(
        this.paymentType,
        this.currentPage,
        this.paymentCount,
        this.paymentOrder,
        this.searchKeyword,
      )
      .subscribe((response) => {
        this.paymentData = response.data.data;

        this.paginationLinks = response.data.links;
        this.fromNo = response.data.from;
        this.toNo = response.data.to;
        this.lastPage = response.data.last_page;
        this.loader = false;
      });
  }

  /**
   * pay for vaults created
   * @param {string}id
   */
  public payment(id: string) {
    this.profileMenuToggle = false;
    const payload = { amount: '100', currency: 'usd', invoice_id: id };
    this.professionalUserService.payInvoice(payload).subscribe((response: APIResponseModel) => {
      if (response.status) {
        window.open(response.data, '_blank');
      }
    },

      (exception: any) => {
        if (exception?.error?.statusCode === APIStatusCodes.BAD_REQUEST)
          this.getPaymentDetails();
        this.commonHelper.httpResponseHandler(exception?.error);
      });
  }
  /**
  * Handles the onClick event for paying now
  * @param invoiceDetails An object containing details of the invoice
  */
  public onClickPayNow(invoiceDetails: any) {
    const { vault_count, invoice_number } = invoiceDetails
    const totalAmount = this.getTotalAmount(invoiceDetails);
    const payVaultRegistrationFeePerVault = this.getPayVaultRegistrationFeePerVault(invoiceDetails)
    this.proCheckoutService.proceedToVaultRegistrationPayment(payVaultRegistrationFeePerVault, vault_count, totalAmount).subscribe((response) => {
      void this.slugInterceptorService.navigate(['checkout'], { queryParams: { invoice: invoice_number } }, true);
    });
  }
  /**
  * Calculates the vault registration fee per vault based on the invoice details.
  * @param invoiceDetails An object containing invoice details.
  * @returns The vault registration fee per vault.
  */
  public getPayVaultRegistrationFeePerVault(invoiceDetails: any): number {
    return invoiceDetails["price_per_vault"] || (invoiceDetails["vault_amount"] / invoiceDetails["vault_count"])
  }
  /**
   * Calculates the total amount based on the invoice details.
   * @param invoiceDetails An object containing invoice details.
   * @returns The total amount.
   */
  public getTotalAmount(invoiceDetails: any): number {
    return invoiceDetails["vault_amount"] || invoiceDetails["price_per_vault"] * invoiceDetails["vault_count"]
  }

  /**
   * Professional payment listen for success payment to show Payment success modal
   * @obervable proPaymentStatusObservable observes values emitted from socket event
   */
  public proPaymentStatusListen() {
    this.commonService.proPaymentStatusObservable.pipe(take(1)).subscribe((response) => {
      if (response['is_payment_complete']) {
        setTimeout(() => {
          this.modalService.open('payment-success');
        }, 500)
      }
    })
  }

  /**
   * Redirects the user to the dashboard if the current user is not a firm account.
   * 
   */
  public redirectIfNotAFirmAccount() {
    if (this.isNotAFirmAccount) {
      void this.slugInterceptorService.navigate(['dashboard'], {}, true)
    }
  }

  /**
   * selected checked
   * @param {number} data
   * @return{boolean}
   */
  public selectedChecked(field, data) {
    switch (field) {
      case 'invoice':
        return this.selectedInvoiceType === data;
    }
  }
  /**
   * Sets the selected invoice type based on the value from the event data.
   * 
   * @param {Event} data - Event data containing the selected invoice type value.
   */
  public onSelectInvoiceType(data) {
    this.selectedInvoiceType = Number(data?.target?.value);
  }

  /**
   * Updates the invoice mail alert settings with the selected invoice mode.
   * Displays success or error messages via toastr notifications.
   */
  public updateInvoiceSettings() {
    this.invoiceSettingsLoader = true;
    const payload = {
      invoice_mode: this.selectedInvoiceType
    }
    this.invoiceService.updateInvoiceMailAlertSettings(payload).subscribe({
      next: (response) => {
        this.toastr.success(response.message);
        this.invoiceSettingsLoader = false;
        this.localStorageService.updateUserData(USER_TYPES.pro, {
          key: 'user',
          updateValue: this.selectedInvoiceType,
          updateKey: 'invoice_mode',
          type: LOCAL_STORAGE_UPDATE_STATUS.O,
        });
      },
      error: (error) => {
        this.toastr.error(error.error.message);
        this.invoiceSettingsLoader = false;
      }
    })
  }

  /**
   * reload page
   */
  reloadPage(): void {
    window.location.reload();
  }

  get paymentSettingsChip() {
    return PaymentSettingsChip
  }

  /**
   * ng on destroy
   */
  ngOnDestroy(): void {
    window.removeEventListener('focus', this.reloadPage);
  }

  /**
   * Determines whether mobile menuchange on
   * @param menu 
   */
  public onMobileMenuchange(menu: string) {
    this.responsiveMenuToggle = !this.responsiveMenuToggle;
    this.mobileMenuName = menu;
  }
 
}

enum PaymentSettingsChip {
  PaymentManagement = 1,
  PaymentHistory = 2,
  ManageCards = 3,
  InvoiceSettings = 4
}

interface IPaymentSettingsChips {
  value: PaymentSettingsChip;
  displayText: string;
}

enum PSPDebitMode {
  END_OF_THE_MONTH = 0,
  INSTANT = 1
}
