<div id="account/Assets">
  <div class="grid grid-cols-12 gap-6 pt-8">
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
              <div class="col-span-8">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                      Bank Account
                  </h4>
              </div>
              <div class="col-span-4">
                  <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                      $37,046.34
                      <img routerLink="/assets/account-assets" *ngIf="!accessVault" src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer" alt="digital vault">
                  </p>
              </div>
          </div>
          <div class="bg-transparent border-card-grey rounded mt-4">
              <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
                <ng-container *ngIf="preLoader">
                  <div class="col-span-12">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Bank Name
                      </h4>
                      <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
                  </div>
                  <div class="col-span-12 md:col-span-6 ">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Account Number
                      </h4>
                      <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
                  </div>
                  <div class="col-span-12 md:col-span-6">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Type
                      </h4>
                      <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
                  </div>

                  <div class="col-span-12 md:col-span-6 ">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Account Name
                      </h4>
                      <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
                  </div>
                  <div class="col-span-12 md:col-span-6">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Username
                      </h4>
                      <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
                  </div>
                  <div class="col-span-12">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Password
                      </h4>
                      <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
                  </div>
                </ng-container>
                <ng-container *ngIf="!preLoader">
                  <ng-container *ngFor="let data of accountArray; let i=index">
                    <div class="col-span-12" *ngIf="i">
                      <hr class="divider w-full">
                    </div>
                    <div class="col-span-12">
                        <h4 class="text-label-grey text-sm tracking-wide text-regular">Bank Name
                        </h4>
                        <p class="text-darkgrey text-base text-regular tracking-wide">{{data?.bank_name}}
                        </p>
                    </div>
                    <div class="col-span-12 md:col-span-6 ">
                        <h4 class="text-label-grey text-sm tracking-wide text-regular">Account Number
                        </h4>
                        <p class="text-darkgrey text-base text-regular tracking-wide">{{data?.account_number}}</p>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <h4 class="text-label-grey text-sm tracking-wide text-regular">Type
                        </h4>
                        <p class="text-darkgrey text-base text-regular tracking-wide">{{data?.bank_account_type?.type}}</p>
                    </div>

                    <div class="col-span-12 md:col-span-6 ">
                        <h4 class="text-label-grey text-sm tracking-wide text-regular">Account Name
                        </h4>
                        <p class="text-darkgrey text-base text-regular tracking-wide">{{data?.account_name}}</p>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                        <h4 class="text-label-grey text-sm tracking-wide text-regular">Username
                        </h4>
                        <p class="text-darkgrey text-base text-regular tracking-wide">{{data?.user_name}}</p>
                    </div>
                    <div class="col-span-12">
                        <h4 class="text-label-grey text-sm tracking-wide text-regular">Password
                        </h4>
                        <p class="text-darkgrey text-base text-regular tracking-wide">{{data?.password}}</p>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
          </div>
      </div>
      <div class="col-span-12 md:col-span-6 my-4 lg:pl-6">
          <div class="grid grid-cols-12 justify-between items-center">
              <div class="col-span-8">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                      Credit Card
                  </h4>
              </div>
              <div class="col-span-4">
                  <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                      -$75,390.24
                      <img routerLink="/assets/credit-card" *ngIf="!accessVault"src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right cursor-pointer" alt="digital vault">
                  </p>
              </div>
          </div>
          <div class="bg-transparent border-card-grey rounded mt-4">
              <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
                <ng-container *ngIf="preLoader">
                  <div class="col-span-12">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Card Name
                      </h4>
                      <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
                  </div>
                  <div class="col-span-12 md:col-span-6 ">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Type of Card
                      </h4>
                      <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
                  </div>
                  <div class="col-span-12 md:col-span-6">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Issuer
                      </h4>
                      <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
                  </div>

                  <div class="col-span-12 md:col-span-6 ">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Card Number
                      </h4>
                      <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
                  </div>
                  <div class="col-span-12 md:col-span-6">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Expiration date
                      </h4>
                      <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
                  </div>
                </ng-container>
                <ng-container *ngIf="!preLoader">
                  <ng-container *ngFor="let data of creditCardArray; let i=index">
                    <div class="col-span-12" *ngIf="i">
                      <hr class="divider w-full">
                    </div>
                    <div class="col-span-12">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Card Name
                      </h4>
                      <p class="text-darkgrey break-all text-base text-regular tracking-wide">{{data?.card_number}}</p>
                  </div>
                  <div class="col-span-12 md:col-span-6 ">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Type of Card
                      </h4>
                      <p class="text-darkgrey break-all text-base text-regular tracking-wide">{{data?.card_type?.type}}</p>
                  </div>
                  <div class="col-span-12 md:col-span-6">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Issuer
                      </h4>
                      <p class="text-darkgrey break-all text-base text-regular tracking-wide">{{data?.issuer}}</p>
                  </div>

                  <div class="col-span-12 md:col-span-6 ">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Card Number
                      </h4>
                      <p class="text-darkgrey break-all text-base text-regular tracking-wide">{{data?.card_number}}</p>
                  </div>
                  <div class="col-span-12 md:col-span-6">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Expiration date
                      </h4>
                      <p class="text-darkgrey break-all text-base text-regular tracking-wide">{{data?.expiration_date}}</p>
                  </div>
                  </ng-container>
                </ng-container>
              </div>
          </div>
      </div>
  </div>
</div>
