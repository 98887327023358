<main class="relative">
  <!-- BEGIN: Navbar Wrapper -->
  <app-consumer-landing-navbar></app-consumer-landing-navbar>
  <!-- END: Navbar Wrapper -->

  <!-- BEGIN: Banner Wrapper -->
  <div class="section company-banner__wrap ">
    <div class="grid grid-cols-12 gap-6 xl:gap-12">
      <div class="col-span-12 xl:col-span-8 mt-32 text-left">
        <h1 class="company-banner__head text-semibold text-white fs-bhead text-regular">
          How LegacyNOW came into being actually created the premise for what diﬀerentiates us.
        </h1>
        <p class="company-banner__content fs-content mt-4 mb-12 text-regular">
          LegacyNOW’s parent company PCA. is a global leader in estate resolution services with compassionate
          care rooted in its success.
        </p>

      </div>
      <div class="col-span-12 md:col-span-5 lg:col-span-6"></div>
    </div>

  </div>
  <!-- END: Banner Wrapper -->


  <!-- BEGIN: Account Care Wrap -->
  <div class="section account-care__wrap">
    <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
      <div class="col-span-12 lg:col-span-6 pb-8 xl:pr-4 lg:pb-0">
        <h2 class="fs-title text-primary text-semibold mb-4">
          Founded in 1997, We Originally Built a Reputation by Pioneering Eﬀective and Compassionate Deceased
          Account Care
        </h2>
        <p class="fs-content text-content text-regular ">
          We remain the leader in deceased account services and assist clients, executors, and beneficiaries
          effectively satisfying creditor’s deceased account holders estate balances.
        </p>
      </div>
      <div class="col-span-12 lg:col-span-6">
        <img class="float-right rounded-md overflow-hidden" src="assets/images/company/founded-care.svg" width="100%"
          height="100%"
          alt="Founded in 1997, we originally built a reputation by pioneering eﬀective and compassionate deceased account care">
      </div>
    </div>
  </div>
  <!-- END: Account Care Wrap -->

  <!-- BEGIN: Industry Sectors Wrap -->
  <div class="section industry-sector__wrap">
    <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
      <div class="col-span-12 lg:col-span-6">
        <img class="float-right rounded-md overflow-hidden" src="assets/images/company/pca-currently.webp" width="100%"
          height="100%" alt="legacy-now">
      </div>
      <div class="col-span-12 lg:col-span-6 xl:pl-4 lg:pb-0">
        <h2 class="fs-title text-primary text-semibold mb-4">
          PCA Currently Works With Over 70 of the World's Largest Creditors Across a Wide Range of Industry
          Sectors
        </h2>
        <p class="fs-content text-content text-regular">
          including banking and utilities. We support our clients with a highly-trained staff of
          professionals,
          extensive technology resources, proprietary databases, and a commitment to quality. Phillips & Cohen
          Associates currently serves our global client base from regional offices in the United States,
          Canada, Europe, and Australia.
        </p>
      </div>
    </div>
  </div>
  <!-- END: Industry Sectors Wrap -->

  <!-- BEGIN: Beneficiaries Proof of Death Wrap -->
  <div class="section beneficiary-proof-of-death__wrap">
    <div class="grid grid-cols-12 items-baseline gap-6 lg:gap-12">
      <div class="col-span-12 lg:col-span-6">
        <img src="assets/images/company/beneficiaries.webp" width="100%" height="100%" class="pb-12" alt="legacy-now">
        <h2 class="fs-title text-primary text-semibold mb-2">
          In an Eﬀort to Bring Added Value to our Creditor Clients, Executors, and Beneﬁciaries
        </h2>
        <p class="fs-content text-content text-regular mb-6">
          of our client’s deceased account holders, we built a decedent notiﬁcation system,
          called NotifyNOW <a href="https://notifynow.com/" target="_blank" class="underline text-primary">(www.notifynow.com)</a>. NotifyNOW assists executors by automating the
          process of notifying all creditors where their recently passed loved one held accounts.
          NotifyNOW provides them with a more private, time-saving resource in the diﬃcult time
          of balancing bereavement with their estate related responsibilities.
        </p>
      </div>
      <div class="col-span-12 lg:col-span-6">
        <img src="assets/images/company/proof-of-death.webp" width="100%" height="100%" class="pb-12" alt="legacy-now">
        <h2 class="fs-title text-primary text-semibold mb-2">
          The Estate Registry Replaces Less Eﬀective Deceased Notiﬁcation & Proof of Death
        </h2>
        <p class="fs-content text-content text-regular mb-6">
          procedures used by creditors, with a link on the creditors website which directs
          the executor to a co-branded NotifyNOW site and streamlines the notification processes
          while delivering a more compassionate approach we call "Passing Care".
        </p>
      </div>

    </div>
  </div>
  <!-- END: Beneficiaries Proof of Death Wrap -->

  <!-- BEGIN: Completed Programming Wrap -->
  <div class="section completed-programming__wrap">
    <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
      <div class="col-span-12 lg:col-span-6">
        <img class="float-right rounded-md overflow-hidden" src="assets/images/company/completed-programming.webp"
          width="100%" height="100%" alt="LegacyNOW was envisioned when we completed the programming">
      </div>
      <div class="col-span-12 lg:col-span-6 xl:pl-4 lg:pb-0">
        <h2 class="fs-title text-primary text-semibold mb-4">
          NotifyNOW was the Prelude to LegacyNOW
        </h2>
        <p class="fs-content text-content text-regular">
          After completing NotifyNOW, we realized we had only solved half the problem
          executors faced with deceased notiﬁcations. While a better creditor deceased
          notifying tool is extremely helpful to the executor, they still have to face the
          unpleasant responsibility of going to their loved ones homes to retrieve
          creditor information. Executors frequently have trouble ﬁnding creditor
          information documentation to report and they often don’t know the passwords for
          the personal computer to retrieve pertinent information. More often than not,
          this results in an incomplete notiﬁcation to all creditors and problems
          with resolving the estate for the beneﬁciaries. They say necessity is the mother
          of invention, it became clear we needed to provide a more complete
          and simplified estate resolution solution for executors. Therefore, a
          personalized estate digital vault that proactively and securely stores all account
          holder’s digital assets needed to be developed and thus LegacyNOW was born.
        </p>
      </div>
    </div>
  </div>
  <!-- END: Completed Programming Wrap -->
  <!-- BEGIN: Advisors Wrap -->
  <div class="section advisors__wrap">
    <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">

      <div class="col-span-12 lg:col-span-6">
        <h2 class="fs-title text-primary text-semibold mb-4">
          We Could Have Created a Direct to Consumer Product but After Much Research and Focus Groups with
          Advisors
        </h2>
        <img class="float-right rounded-md overflow-hidden" src="assets/images/company/advisor.webp" width="100%"
          height="100%" alt="legacy-now">
      </div>
      <div class="col-span-12 lg:col-span-6 xl:pl-4 lg:pb-0">

        <p class="fs-content text-content text-regular">
          it became clear, while estate management software is abundant in the market place, none combine
          estate data preparation features with estate resolution tools, to create a comprehensive automated
          estate settlement platform, we ﬁrmly believe this specialized niche product will prove to be very
          attractive and useful to estate planners and other professional service providers and serve as a
          differentiator for their clients. LegacyNOW is designed to provide value-added services missing in
          estate, trusts and probate administration practices. We stand poised and determined to deliver the
          most advanced estate & legacy data management tool available in the market today with an eye on
          delivering what you’ll need in the future. We will work hard to earn your business, and have built
          communication systems to enable advisors to provide and receive feedback, improve system
          capabilities,
          and keep your business.
        </p>
      </div>
    </div>
  </div>
  <!-- END: Advisors Wrap -->


  <div class="section services-tech__wrap ">
    <div class="grid grid-cols-12 gap-6 xl:gap-12">
      <div class="title__wrap col-span-12 pb-6">

        <h1 class="text-primary text-semibold fs-title max-w-5xl mx-auto text-regular pb-4 text-center">
          As the Global Leader in Compassionate Care Services and Technology
        </h1>
        <p class="fs-content text-regular text-content max-w-5xl mx-auto text-center">
          We’re focused on reinventing our business across four verticals: estate account
          resolution, executor support,beneficiary and life planning. Compassion and innovation
          are at the heart of the company’s mission to make estate handling simpler and less
          burdensome every step of the way.
        </p>
      </div>
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6 xl:hidden">
          <div class="col-span-12 text-center mb-4">
            <div class="p-2 px-6 bg-dark-blue text-semibold text-white rounded inline-block">Compassionate
              Care Conglomerate</div>
          </div>
          <div class="col-span-12 sm:col-span-6">
            <div class="ps-bot bg-white p-3 rounded-lg">
              <div class="bg-dark-blue p-2 text-white text-regular text-center rounded">
                Life Planning
              </div>
              <p class="text-regular text-accent pt-2 text-left"><strong>LegacyNOW- a digital vault for
                  family members to proactively store </strong>important Documents to lessen the
                administrative burden on loved ones left behind, System will aggregate, digitally store,
                and create a digital
                footprint of a will Holder’s creditor account information and passwords.</p>
              <img width="300" class="mt-4" src="assets/images/logo.svg" alt="legacy-now">
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6">
            <div class="ps-bot ps-bot bg-white p-3 rounded-lg">
              <div class="bg-dark-blue p-2 text-white text-regular text-center rounded">
                Beneficiary Support
              </div>
              <p class="text-regular text-accent pt-2 text-left">
                <strong>InheritNOW- providing funds to beneficiaries</strong> in advance of receiving
                inheritance Due to them as part of an estate. Service provided for a fee, with no
                recourse If the inheritance doesn’t materialize.
              </p>
              <picture>
                <source srcset="assets/images/partners/inherit.webp" type="image/webp">
                <source srcset="assets/images/partners/inherit.png" type="image/png">
                <img class="mt-4" src="assets/images/partners/inherit.png" width="100%" height="100%" alt="legacy-now">
              </picture>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6">
            <div class="ps-bot ps-bot bg-white p-3 rounded-lg">
              <div class="bg-dark-blue p-2 text-white text-regular text-center rounded">
                Executor Support
              </div>
              <p class="text-regular text-accent pt-2 text-left">
                <strong> The Estate Registry-self-service deceased notification web portal</strong> with
                centralized Support across creditors. Securely registers, stores and reports the death
                of Recently deceased account holder to all
                known creditors.
              </p>
              <picture>
                <source srcset="assets/images/partners/estate-registry.webp" type="image/webp">
                <source srcset="assets/images/partners/estate-registry.png" type="image/png">
                <img class="mt-4" src="assets/images/partners/estate-registry.png" width="100%" height="100%"
                  alt="legacy-now">
              </picture>
            </div>
          </div>

          <div class="col-span-12 sm:col-span-6">
            <div class="ps-bot ps-bot bg-white p-3 rounded-lg">
              <div class="bg-dark-blue p-2 text-white text-regular text-center rounded">
                Estate Account Resolution
              </div>
              <p class="text-regular text-accent pt-2 text-left">
                <strong>Credit Balance</strong> - expanding into decedent credit balance refund
                issuance/servicing. <br>

                <strong>Estate-Serve-secure</strong>, online estate management tool. <br>

                <strong>Speech Analytics and Digital Channels</strong>
              </p>
              <picture>
                <source srcset="assets/images/partners/philips.webp" type="image/webp">
                <source srcset="assets/images/partners/philips.png" type="image/png">
                <img class="mt-4" src="assets/images/partners/philips.png" width="100%" height="100%" alt="legacy-now">
              </picture>
            </div>
          </div>
        </div>
        <section class="ps-timeline-sec hidden xl:block">
          <div class="container text-center">
            <div class="p-2 px-6 bg-dark-blue text-semibold text-white rounded inline-block">Compassionate
              Care Conglomerate</div>
            <ol class="ps-timeline">
              <li>
                <div class="ps-bot bg-white p-3 rounded-lg roadmap__firstbox">
                  <div class="bg-dark-blue p-2 text-white text-regular text-center rounded">
                    Life Planning
                  </div>
                  <p class="text-regular text-accent pt-2 text-left"><strong>LegacyNOW- a digital
                      vault for family members to proactively store </strong>important Documents
                    to lessen the administrative burden on loved ones left behind, System will
                    aggregate, digitally store, and create
                    a digital footprint of a will Holder’s creditor account information and
                    passwords.</p>
                  <img width="300" class="mt-4" src="assets/images/logo.svg" alt="legacy-now">
                </div>
                <span class="ps-sp-top left"></span>
              </li>

              <li>
                <div class="ps-bot ps-bot bg-white p-3 rounded-lg roadmap__secondbox">
                  <div class="bg-dark-blue p-2 text-white text-regular text-center rounded">
                    Beneficiary Support
                  </div>
                  <p class="text-regular text-accent pt-2 text-left">
                    <strong>InheritNOW- providing funds to beneficiaries</strong> in advance of
                    receiving inheritance Due to them as part of an estate. Service provided for a
                    fee, with no recourse If the inheritance doesn’t materialize.
                  </p>
                  <picture>
                    <source srcset="assets/images/partners/inherit.webp" type="image/webp">
                    <source srcset="assets/images/partners/inherit.png" type="image/png">
                    <img class="mt-4" src="assets/images/partners/inherit.png" width="100%" height="100%"
                      alt="legacy-now">
                  </picture>
                </div>
                <span class="ps-sp-bot second--leftline"></span>
              </li>


              <li>
                <div class="ps-bot ps-bot bg-white p-3 rounded-lg roadmap__thirdbox">
                  <div class="bg-dark-blue p-2 text-white text-regular text-center rounded">
                    Executor Support
                  </div>
                  <p class="text-regular text-accent pt-2 text-left">
                    <strong> The Estate Registry-self-service deceased notification web
                      portal</strong> with centralized Support across creditors. Securely
                    registers, stores and reports the death of Recently deceased account holder
                    to all known creditors.
                  </p>
                  <picture>
                    <source srcset="assets/images/partners/estate-registry.webp" type="image/webp">
                    <source srcset="assets/images/partners/estate-registry.png" type="image/png">
                    <img class="mt-4" src="assets/images/partners/estate-registry.png" width="100%" height="100%"
                      alt="legacy-now">
                  </picture>
                </div>
                <span class="ps-sp-bot third--rightline"></span>
              </li>
              <li>

                <div class="ps-bot ps-bot bg-white p-3 rounded-lg roadmap__fourbox">
                  <div class="bg-dark-blue p-2 text-white text-regular text-center rounded">
                    Estate Account Resolution
                  </div>
                  <p class="text-regular text-accent pt-2 text-left">
                    <strong>Credit Balance</strong> - expanding into decedent credit balance refund
                    issuance/servicing. <br>

                    <strong>Estate-Serve-secure</strong>, online estate management tool. <br>

                    <strong>Speech Analytics and Digital Channels</strong>
                  </p>
                  <picture>
                    <source srcset="assets/images/partners/philips.webp" type="image/webp">
                    <source srcset="assets/images/partners/philips.png" type="image/png">
                    <img class="mt-4" src="assets/images/partners/philips.png" width="100%" height="100%"
                      alt="legacy-now">
                  </picture>
                </div>
                <span class="ps-sp-top right"></span>
              </li>
            </ol>
          </div>
        </section>
      </div>
    </div>
  </div>
  <!-- END: Services Technologies Wrap -->
  <!-- BEGIN: For More Information Wrapper -->
  <div class="section  for-more-info__wrap">
    <div class="grid grid-cols-12 items-center rounded-xl p-10 md:p-12 bg-dark-blue gap-5 lg:gap-8">
      <div class="col-span-12 lg:col-span-9">
        <div class="title__wrap">
          <h1 class="text-white text-semibold fs-title pb-3 text-bold text-left">For more information and to
            set up a demo please</h1>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-3">
        <div class="btn-wrap flex items-left justify-start lg:items-center lg:justify-center">
          <button
            class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">Contact
            Us</button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: For More Information Wrapper -->
  <!-- BEGIN: Testimonial Wrapper-->
  <app-testimonials [mobile]="mobile"></app-testimonials>
  <!-- END: Testimonial Wrapper -->

  <!-- BEGIN: Advisor Insight Inbox -->
  <!-- <div class="section insight-inbox__wrap">
    <div class="grid grid-cols-9 gap-4 lg:gap-12">
      <div class="title__wrap col-span-12 md:col-start-2 md:col-span-9 pb-8">
        <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-center">For Advisors
          <br> Insights sent directly to your inbox
        </h1>
        <p class="fs-content text-regular text-content text-center">Just sign up here and you'll receive news and
          articles that will help better serve your clients and outperform your competition.</p>
      </div>
      <div class="col-span-12">
        <div class="bg-transparent border rounded-md mx-auto md:w-5/6 lg:w-3/5">
          <form action="#" method="post"
            class="flex flex-wrap items-center justify-between rounded-md xl:h-16 md:flex-row bg-white">
            <div class="relative w-full">
              <input type="text" placeholder="Enter your Name"
                class="flex-1 h-10 text-content text-sm md:text-lg md:px-4 m-1 text-gray-700 placeholder-gray-400 border-none appearance-none lg:h-12 focus:outline-none ">
              <div class="absolute top-1 right-3 md:right-10">
                <button type="submit"
                  class="flex items-center justify-center text-semibold text-sm text-white w-full sm:mx-2 p-2 m-1 rounded-md md:h-12 md:mx-6 lg:text-xl xl:h-12 sm:text-base md:p-0 bg-primary focus:outline-none ">Let
                  Me Know</button>
              </div>
            </div>
          </form>
        </div>

      </div>

    </div>
  </div> -->
  <!-- END: Advisor Insight Inbox -->




  <!-- BEGIN: Footer Wrapper -->
  <app-consumer-landing-footer></app-consumer-landing-footer>
</main>
