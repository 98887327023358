import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { LIST_DELETE } from 'src/constants/application.const';
import { PersonalDetailsService } from '../../../services/personal-details.service';
import {
  CPCEmitInterface,
  CPCSettingsInterface,
} from '../../../sharedComponent/choose-people-component/choose-people-interfaces.';
import {
  LPEmitterInterface,
  LPSettingsInterface,
  LPUserPeopleInterface,
} from '../../../sharedComponent/list-people/list-people-interfaces';
import { ListPeopleComponent } from '../../../sharedComponent/list-people/list-people.component';
import { MessageModalDataInterface } from '../../../sharedComponent/message-modal/message-modal.component';


@Component({
  selector: 'app-manage-beneficiaries',
  templateUrl: './manage-beneficiaries.component.html',
})
/**
 * Manage beneficiaries
 */
export class ManageBeneficiariesComponent implements OnInit {
  // Raw user_people data. Never remove data from this.
  private userPeopleDataRaw: Array<Object> = [];
  // ListPeople interfaces for displaying beneficiaries
  public userPeopleData: Array<LPUserPeopleInterface>;
  // ListPeople interfaces for displaying choose-able beneficiaries
  public choosePeopleData: Array<LPUserPeopleInterface>;
  // ListPeople settings
  public lpSettings: LPSettingsInterface;
  // ChoosePersonComponent settings.
  public cpcSettings: CPCSettingsInterface;
  // user_people Object when opening add-beneficiary to edit.
  public editData: Object;
  public submitLoader: boolean;
  public userType: string;
  public requestId: string;
  public messageData: MessageModalDataInterface;
  public deleteId: string;

  public currentUrl: string;
  public isPartnerDeceased: boolean;

  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private peopleService: PeopleService,
    private commonHelper: CommonHelper,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService,
    private personalDetailsService: PersonalDetailsService,
  ) {
  }

  /**
   * Called initially
   */
  ngOnInit(): void {
    this.currentUrl = window.location.pathname;
    this.cpcSettings = {
      text: {
        header: 'Choose Beneficiaries',
        sub_header: 'Choose who you would like as your beneficiaries from the list below, or enter someone else by clicking the <i><u>Add New Beneficiary</u></i> button.',
        add_new: 'Add New Beneficiary',
        choose_people: 'Set as Beneficiaries',
        checkbox: {
          text: 'Set as Beneficiary:',
        },
      },
    };
    this.lpSettings = {
      delete: {
        show: true,
        tooltip: 'Remove',
        useIcon: 'faTrash',
      },
      edit: { show: true, tooltip: 'Edit' },
    };
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.getUserPeople();
  }

  /**
   * Persist changes from previous state of {@link userPeopleDataRaw} using {@link oldRawData}
   * @private
   */
  private persistState(oldRawData: Array<Object>) {
    this.userPeopleDataRaw.forEach((current) => {
      const prev = oldRawData.find((e) => e['id'] == current['id']);
      if (prev) {
        current['beneficiary'] = prev['beneficiary'];
      }
    });
  }

  /**
   * Get User People from endpoint, call {@link processUserPeopleData}.
   * @private
   */
  private getUserPeople() {
    this.lpSettings.isLoading = true;
    this.personalDetailsService.getUserPeople(this.requestId).subscribe({
      next: (r) => {
        if (r.status) {
          const oldRawData = this.userPeopleDataRaw;
          this.userPeopleDataRaw = r.data.user_people;
          // here 4 indicates user has selected 'Widowed' option in marital status section
          this.isPartnerDeceased = r.data.marital_status === 4;
          this.persistState(oldRawData);
          this.processUserPeopleData();
        }
        this.lpSettings.isLoading = false;
      },
      error: (e) => {
        this.commonHelper.httpResponseHandler(e?.error);
        this.lpSettings.isLoading = false;
      },
    });
  }

  /**
   * Process {@link this.userPeopleDataRaw userPeopleDataRaw} to populate {@link this.userPeopleData userPeopleData}
   * with data to display.
   * @private
   */
  private processUserPeopleData() {
    this.userPeopleData = [];
    this.choosePeopleData = [];

    // Process items
    this.userPeopleDataRaw.forEach((e) => {
      // filter out advisors and deceased partner
      if (e['advisor'] == 1 || (this.isPartnerDeceased && e['relation'] === 1)) {
        return;
      }
      const person = ListPeopleComponent.getLPUserPeople(e)[0];

      // Sort between those who are and are not beneficiaries.
      if (e['beneficiary'] == 1) {
        this.userPeopleData.push(person);
      } else {
        this.choosePeopleData.push(person);
      }
    });
  }

  /**
   * toggle add beneficiary modal
   */
  public toggleModal(): void {
    this.modalService.open('choose-people-modal');
  }

  /**
   * Go to the next section page
   * @private
   */
  private nextPage() {
    const isConsumer = this.userType === 'Consumer';
    const url = ['executor-beneficiary'];
    if (isConsumer) {
      url.push(this.requestId);
    }
    void this.slugInterceptorService.navigate(url);
  }

  /**
   * Get user_people data for all who are currently set as beneficiaries.
   */
  public get getBeneficiaries(): Array<Object> {
    return this.userPeopleDataRaw?.filter((e) => e['beneficiary'] == 1);
  }

  /**
   * Save beneficiary information and then move to the next page if successful.
   */
  public saveAndContinue(): void {
    const isConsumer = this.userType === 'Consumer';

    // Beneficiaries to set
    const beneficiaries: Array<string> = [];
    this.getBeneficiaries.forEach((e) => beneficiaries.push(e['id']));

    this.submitLoader = true;
    const payload = {
      beneficiary: beneficiaries,
      type: '1',
      roletype: this.userType,
    };
    if (isConsumer) {
      payload['request_id'] = this.requestId;
    }
    this.peopleService.updatePeopleDetails(payload).subscribe((response: APIResponseModel) => {
      this.submitLoader = false;
      if (response.status) {
        if (isConsumer) {
          this.commonHelper.updateLocalstorageRequestStage(response.data);
        }
        this.nextPage();
      }
    }, (exception) => {
      this.submitLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
   * Retrieve click events from list-people
   * @param {LPEmitterInterface} event
   */
  public clickListener(event: LPEmitterInterface) {
    if (event.clickedDelete) { // Remove Button
      const person = this.userPeopleDataRaw.find((e) => e['id'] == event.id);
      person['beneficiary'] = 0;
      this.processUserPeopleData();
    } else if (event.clickedName || event.clickedEdit) { // Edit person
      this.openEdit(event.id);
    }
  }

  /**
   * Listener for {@link ChoosePeopleComponent}.
   * @param $event
   */
  public listenerChoosePeople($event: CPCEmitInterface) {
    if ($event.chosen_people) {
      // Set chosen_people as beneficiaries
      $event.chosen_people.forEach((chosen) => {
        this.userPeopleDataRaw.find((person) => chosen.id == person['id'])['beneficiary'] = 1;
      });
      this.processUserPeopleData();
    } else if ($event.add_new) {
      this.toggleAddModal();
    } else if ($event.person_clicked_id) {
      this.openEdit($event.person_clicked_id);
    }
  }

  /**
   * Listener for 'app-add-beneficiary' toggleModalEmitter.
   * @param $event
   */
  public listenerAddBeneficiary($event: Object) {
    if ($event?.['listStatus'] == LIST_DELETE) {
      this.messageData = {
        title: 'Alert!',
        message: ['Removing this beneficiary will also remove them from their roles as Executor or Digital Vault Custodian (DVC).<br> Are you sure you want to proceed?'],
        buttons: [{ content: 'Remove', emitValue: messageResponses.list_delete, class: 'bg-darkblue text-white mr-4' },
          { content: 'Cancel', emitValue: false, class: 'text-red custom-noButton-border' }],
      };
      this.deleteId = $event['id'];
      this.modalService.close('add-beneficiary-modal');
      this.modalService.open('message-modal');
    } else if ($event) {
      this.getUserPeople();
    }
  }

  /**
   * Opens {@link AddBeneficiaryComponent} with associated user-person as edit data.
   * @param person_id
   * @private
   */
  private openEdit(person_id: string) {
    const person = this.userPeopleDataRaw.find((e) => e['id'] == person_id);
    console.log({ person });

    this.messageData = {
      title: 'Edit Required to Continue',
      message: [],
    };
    if (person['advisor'] == 1) {
      const agentTerm = this.commonHelper.LocalStorageAgentTerm();
      this.messageData.title = 'Edit Blocked';
      this.messageData.message = [`You do not have access to edit your ${agentTerm}'s information`];
      this.messageData.buttons = [{ content: 'Close', emitValue: messageResponses.advisor_yes }];
      this.toggleMessageModal();
    } else if (person['relation'] == 1) {
      this.messageData.message = ['Kindly proceed to About Yourself section to update your partner information'];
      this.messageData.buttons = [{ content: 'Proceed', emitValue: messageResponses.partner_yes }];
      this.toggleMessageModal();
    } else if (person['relation'] == 2) {
      this.messageData.message = ['Kindly proceed to About Yourself section to update your child information'];
      this.messageData.buttons = [{ content: 'Proceed', emitValue: messageResponses.child_yes }];
      this.toggleMessageModal();
    } else {
      this.toggleAddModal(person);
    }
  }

  /**
   * Toggle {@link AddBeneficiaryComponent} modal.
   * @param {Object} editValue
   */
  private toggleAddModal(editValue?: Object): void {
    this.modalService.close('choose-people-modal');
    this.modalService.open('add-beneficiary-modal');
    this.editData = editValue ? { ...editValue } : {};
  }

  /**
   * Toggle {@link MessageModalComponent} modal.
   */
  private toggleMessageModal(): void {
    this.modalService.close('choose-people-modal');
    this.modalService.open('message-modal');
  }

  /**
   * delete beneficiary
   */
  private deleteBeneficiary(deleteId: any): void {
    if (this.userType === 'Consumer') {
      this.peopleService
        .storePeopleDetails({
          is_delete: '1',
          id: deleteId,
          roletype: this.userType,
        })
        .subscribe(
          (response: APIResponseModel) => {
            if (response.status) {
              this.commonHelper.toastrDeleteSuccess();
              this.getUserPeople();
            }
          },
          (exception: any) => {
            this.commonHelper.httpResponseHandler(exception?.error);
          },
        );
    } else {
      this.peopleService
        .storePeopleDetails({
          is_delete: '1',
          id: deleteId,
          roletype: this.userType,
          request_id: this.requestId,
        })
        .subscribe(
          (response: APIResponseModel) => {
            if (response.status) {
              this.commonHelper.toastrDeleteSuccess();
              this.getUserPeople();
            }
          },
          (exception: any) => {
            this.commonHelper.httpResponseHandler(exception?.error);
          },
        );
    }
  }

  /**
   * Listener for Message Modal Events
   * @param event
   */
  public messageModalListener(event: any) {
    const isConsumer = this.userType === 'Consumer';
    if (event != null) {
      this.modalService.close('message-modal');
      switch (event) {
        case messageResponses.advisor_yes:
          this.modalService.close('message-modal');
          // const advisorPageUrl = ['advisors', 'manage-advisors'];
          // if (!isConsumer) {
          //   advisorPageUrl.push(this.requestId);
          // }
          // this.navigate(advisorPageUrl);
          break;
        case messageResponses.partner_yes:
          const partnerPageUrl = ['about-yourself', 'partner'];
          if (!isConsumer) {
            partnerPageUrl.push(this.requestId);
          }
          this.changeRouteWithRedirection(partnerPageUrl, this.currentUrl);
          break;
        case messageResponses.child_yes:
          const childPageUrl = ['about-yourself', 'children'];
          if (!isConsumer) {
            childPageUrl.push(this.requestId);
          }
          this.changeRouteWithRedirection(childPageUrl, this.currentUrl);
          break;
        case messageResponses.list_delete:
          this.deleteBeneficiary(this.deleteId);
          break;
      }
    }
  }

  public clickBack(): void {
    const isConsumer = this.userType === 'Consumer';
    const url = ['executor-beneficiary', 'beneficiary-info'];
    if (!isConsumer) {
      url.push(this.requestId);
    }
    this.navigate(url);
  }

  /**
   * change route
   * @param{string}url
   */
  private navigate(url: Array<string>) {
    void this.slugInterceptorService.navigate(url);
  }

  /**
   * change route with redirect url
   * @param{string} url
   * @param{string} redirectUrl
   */
  public changeRouteWithRedirection(url, redirectUrl: any) {
    void this.slugInterceptorService.navigate(url, { queryParams: { 'redirectTo': redirectUrl } });
  }
}


enum messageResponses {
  advisor_yes,
  partner_yes,
  child_yes,
  list_delete
}
