import { ViewportScroller } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { STAGE_UNLOCK_CONST } from 'src/constants/application.const';
import { SummerySubSectionInterface } from '../../../interface/dashboard.interface';
import { CommonModelService } from '../../../services/common-model.service';
import { CheckoutService } from '../../checkout/checkout.service';


@Component({
  selector: 'app-summery-sections',
  templateUrl: './summery-sections.component.html',
  styleUrls: ['./summery-sections.component.css'],
})
/**
 * Summery components in dashboard
 */
export class SummerySectionsComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() sectionIntroVideo: boolean;
  @Input() description: string;
  @Input() endingSection: number;
  @Input() lastSection: number;
  @Input() sectionItemList: Array<SummerySubSectionInterface>;
  @Input() additionalData: any;
  @Input() totalSections: number;
  @Input() sectionIndex: number;
  @Input() sectionLastRequestStage: number;
  @Input() dashboardData: Object;
  // eslint-disable-next-line camelcase
  @Input() sectionStatus: {
    [key: string]: {
      section_name: string;
      status: number;
      updated_at: string;
      created_at: string;
    };
  };
  public toggleSectionBody: boolean;
  public userDetails: any;
  public stageUnlockMapper: any;
  public progressbarConditionString: string;
  public stageCompleted: any;
  private id: string;
  public vaultVideo: string;

  public messageData = {
    title: 'Info',
    message: [
      `Access to this section is restricted until the necessary information in the <b>Personal Details</b> section is provided. Please complete the <b>Personal Details</b> section to unlock and proceed.`,
    ],
    buttons: [{ content: 'Understood', emitValue: 'return' }],
  };
  public messageDataForRefundPeriod = {
    title: 'Premium Access Required',
    message: ['To access this premium section, please re-confirm your subscription to LegacyNOW Digital Vault.'],
    buttons: [
      { content: 'Not Right Now', emitValue: 'return' },
      { content: 'Subscribe', emitValue: 'subscribe' },
    ],
  };
  public message_modal_id: string = 'message-modal-locked';


  /**
   * @constructor
   */
  constructor(
    private route: ActivatedRoute,
    private viewPortScroller: ViewportScroller,
    private checkoutService: CheckoutService,
    private slugInterceptorService: SlugInterceptorService,
    private modalService: CommonModelService,
  ) {
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.stageUnlockMapper = STAGE_UNLOCK_CONST;
  }


  /**
   * after view initialized
   */
  // ngAfterViewInit(): void {
  //   if (this.userDetails?.stage > 0) {
  //     this.viewPortScroller.scrollToAnchor('scroll-to-button');
  //   }
  // }

  /**
   * called on changes
   *
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.additionalData['currentValue'] &&
      Object.keys(changes.additionalData.currentValue).length
    ) {
      this.userDetails = this.additionalData;
      this.toggleSectionBody =
        this.userDetails?.stage >= this.endingSection ||
        this.userDetails.request_stage >= 10;
      this.stageCompleted =
        this.userDetails?.request_stage >= this.sectionLastRequestStage;
      this.progressbarConditionString =
        this.userDetails?.stage >=
        this.sectionItemList[this.sectionItemList.length - 1].sectionStep ?
          'w-full' :
          this.userDetails?.stage >= this.sectionItemList[0].sectionStep &&
          this.userDetails?.stage <=
          this.sectionItemList[this.sectionItemList.length - 1]
            .sectionStep ?
            'w-' +
            this.userDetails?.stage +
            '/' +
            this.sectionItemList[this.sectionItemList.length - 1].sectionStep :
            '';
      this.progressbarConditionString =
        this.progressbarConditionString === 'w-6/7' ?
          'w-1/2' :
          this.progressbarConditionString;
    } else {
      this.toggleSectionBody = this.endingSection === 1;
    }
  }


  /**
   * check for second stage
   *
   * @param {any} sectionData
   * @return {Boolean}
   */
  public checkForSecondStage(sectionData: any): boolean {
    return (
      this.userDetails?.request_stage >= 6 &&
      this.userDetails?.stage === 1 &&
      !this.userDetails.has_child &&
      sectionData?.sectionStep === 2
    );
  }


  /**
   * check for third stage
   *
   * @param {any} sectionData
   * @return {Boolean}
   */
  public checkForThirdStage(sectionData: any): boolean {
    if (sectionData.sectionStep === 3) {
      const areExeBeneAdded =
        this.userDetails?.user_people?.some((cur: any) => {
          return cur.executor === 1;
        }) &&
        this.userDetails?.user_people?.some((cur) => {
          return cur.beneficiary === 1;
        });

      return (
        areExeBeneAdded &&
        this.userDetails?.['request_stage'] >= 9 &&
        this.userDetails?.['request_stage'] < 11
      );
    }
  }


  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    const route: any[] = [url];
    if (!!this.id) {
      route.push(this.id);
    }
    void this.slugInterceptorService.navigate(route);
  }


  /**
   *
   * @param{any} data
   */
  public click(data: any) {
    // console.log(data);
  }


  /**
   *
   * @param{any} event
   */
  public buttonListener(event: any) {
    if (event == 'return') {
      this.modalService.close(this.message_modal_id);
    } else if (event == 'subscribe') {
      this.checkoutService.proceedTosubscriptionPayment().subscribe(() => {
        void this.slugInterceptorService.navigate(['/checkout']);
      });
    } else if (event == 'open-message-modal') {
      this.modalService.open(this.message_modal_id);
    }
  }


  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');

  }
}
