<nav class="bg-white">
  <div class="w-full px-4 lg:px-8">
    <div class="relative flex items-center justify-between h-24">
      <div class="flex-1 flex items-center lg:items-stretch justify-start">
        <div class="relative inset-y-0 left-0 flex items-center lg:hidden mr-2 sm:mr-4 ml-0">
          <!-- BEGIN: Mobile Menu button-->
          <button type="button"
            class="inline-flex sm:ml-2 items-center justify-center p-1 border rounded text-grey focus:outline-none"
            aria-controls="mobile-menu" aria-expanded="false" (click)="responsiveMenuToggle = !responsiveMenuToggle">
            <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
          <!-- END: Mobile Menu button-->
        </div>
        <div class="flex-shrink-0 flex items-center cursor-pointer gap-2 md:gap-4 flex-row">
          <!-- Landing Page Return -->
          <div>
            <a class="cursor-pointer"
               title="Return to Landing Page"
               (click)="navigate('/')">
              <img alt="The-Estate-Registry" class="object-contain w-36 sm:w-40 lg:w-56 xl:w-64" src="assets/images/logo-old.png">
              <p class="text-regular text-semibold text-orange text-center text-xs lg:text-sm xl:text-lg custom-slogan-logo">
                {{this.roleType ? this.roleType + " Edition" : ""}}
              </p>
            </a>
          </div>
          <div class="flex items-center justify-center gap-2 md:gap-4 h-16 md:h-24">
            <a *ngIf="orgLogoPath"  class=" border-l-black pl-6 cursor-pointer max-h-12 maxw128">
              <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
            </a>
            

          </div>
        </div>
        <div class="hidden lg:flex items-center md:ml-4 lg:ml-8">
          <div class="flex space-x-6 xl:space-x-8">
            <a (click)="navigate('dashboard')"
              class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
              [ngClass]="{'menu-active': currentPage === 'dashboard'}">Dashboard</a>
              <!-- hided due to professional initial launch -->
            <!-- <a (click)="navigate('professional-management')"
              class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
              [ngClass]="{'menu-active': currentPage === 'professional-management'}">
              Professional Management
            </a> -->
            <a (click)="navigate('clients')"
              class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
              [ngClass]="{'menu-active': currentPage === 'clients'}">Clients</a>
              <!-- Message Menu for Advisor & Managers -->
            <a id="dropdownDefaultButton" data-dropdown-toggle="dropdown" *ngIf="roleName !==PROFESSIONAL_ROLE.professional && canShowMessageMenu"
              [ngClass]="{'menu-active': currentPage === 'message' || currentPage === 'pro-message'}"
              class=" text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer">Message<svg
                class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
              </svg>
              <!-- Dropdown menu -->
              <div id="dropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                  <li>
                    <a (click)="navigate('pro-message')"
                      class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
                      [ngClass]="{'submenu-active': currentPage === 'pro-message'}">Professional</a>
                  </li>
                  <li>
                    <a (click)="navigate('message')"
                      class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
                      [ngClass]="{'submenu-active': currentPage === 'message'}">Consumer</a>
                  </li>
                </ul>
              </div>
            </a>
             <!-- Message Menu for Company -->
            <a  *ngIf="roleName ===PROFESSIONAL_ROLE.professional && canShowMessageMenu" (click)="navigate('message')"
              class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
              [ngClass]="{'menu-active': currentPage === 'message'}">Message
            </a>
          </div>
        </div>
        
        <div class="justify-end flex items-center pr-2 lg:static lg:inset-auto sm:ml-6 pr-4 lg:pr-0 flex-1 gap-4 md:gap-6">
          
          <!-- BEGIN: Return to Dashboard button -->
          <div *ngIf="showReturnDashboardOption" class="hidden sm:block">
            <button (click)="navigate('dashboard')"
              class="flex items-center gap-4 button button--lg w-full bg-darkblue rounded-xs py-2 md:h-10 focus:outline-none text-regular text-white px-6 text-sm">
              <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
                stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              <span>Return to Dashboard</span>
            </button>
          </div>
          <!-- END: Return to Dashboard button -->


          <!-- BEGIN: Profile dropdown -->
          <div class="relative w-6 sm:w-8 lg:w-10">
            <div (click)="profileMenuToggle = !profileMenuToggle" class="cursor-pointer" #profileMenu>
              <img class="w-6 h-6 sm:w-8 sm:h-8 lg:h-10 lg:w-10 rounded-full" src="assets/images/navbar/profile-avatar.png"
                alt="default-avatar" *ngIf="this.currentUser?.user?.['profile_picture'] === null && this.currentUser?.user?.['logo'] == null">
              <img class="w-6 h-6 sm:w-8 sm:h-8 lg:h-10 lg:w-10 rounded-full" [src]="this.imageSrc" alt="broken-profile-image"
                *ngIf="this.currentUser?.user?.['profile_picture'] !== null || this.currentUser?.user?.['logo'] != null">
            </div>

            <div (click)="profileMenuToggle = !profileMenuToggle"
              class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white focus:outline-none z-10"
              role="menu" aria-orientation="vertical" aria-labelledby="user-menu" *ngIf="profileMenuToggle">
              <a (click)="navigate('account-settings',1)"
                class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100"
                role="menuitem">Your Profile</a>
              <a (click)="navigate('account-settings',2)"
                class=" cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100"
                role="menuitem">Change
                Password</a>
              <a *ngIf="isPayByProfessional" (click)="navigate('payment-settings',1)"
                class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100"
                role="menuitem">Payment
                Settings</a>
              <button [disabled]="buttonClicked" (click)="logout()"
                class="cursor-pointer block px-4 py-2 text-sm text-grey text-regular hover:bg-gray-100 w-full text-left"
                role="menuitem">Log out</button>
            </div>
          </div>
          <!-- END: Profile dropdown -->
        </div>
      </div>
    </div>
  </div>
  <!-- BEGIN: Mobile Menus-->
  <div class="lg:hidden shadow-md px-4 pb-4" id="mobile-menu" *ngIf="responsiveMenuToggle">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <a class="text-grey text-regular block px-3 py-2 rounded text-base font-medium mobile-menuitem cursor-pointer"
        (click)="navigate('dashboard')"
        [ngClass]="{'mob-menu-active': currentPage === 'dashboard'}"><span>Dashboard</span></a>
        <!-- <a class="text-grey text-regular block px-3 py-2 rounded text-base font-medium mobile-menuitem cursor-pointer"
        (click)="navigate('professional-management')"
        [ngClass]="{'mob-menu-active': currentPage === 'professional-management'}"><span>Professional Management</span></a> -->
      <a (click)="navigate('clients')" [ngClass]="{'mob-menu-active': currentPage === 'clients'}"
         class="text-grey text-regular block px-3 py-2 rounded text-base font-medium mobile-menuitem cursor-pointer"><span>Clients</span></a>
      <!-- Message Menu for Advisor & Manager -->
      <a *ngIf="roleName!==PROFESSIONAL_ROLE.professional && canShowMessageMenu" id="dropdownDefaultButton" data-dropdown-toggle="dropdown"
        [ngClass]="{'mob-menu-active': currentPage === 'message' || currentPage === 'pro-message'}"
        class="text-grey text-regular block px-3 py-2 rounded text-base font-medium mobile-menuitem cursor-pointer">
        <span class="menu-downarrow-align">
          Message<svg class=" w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 4 4 4-4" />
          </svg>
        </span>
        <!-- Dropdown menu -->
        <div id="dropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
          <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
            <li>
              <a (click)="navigate('pro-message')"
                class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
                [ngClass]="{'submenu-active': currentPage === 'pro-message'}">Professional</a>
            </li>
            <li>
              <a (click)="navigate('message')"
                class="text-nav-blue text-regular py-2 text-sm tracking-wide font-medium cursor-pointer"
                [ngClass]="{'submenu-active': currentPage === 'message'}">Consumer</a>
            </li>
          </ul>
        </div>
      </a>
      <!-- Message Menu for Company -->
      <a *ngIf="roleName === PROFESSIONAL_ROLE.professional && canShowMessageMenu" (click)="navigate('message')"
        class="text-grey text-regular block px-3 py-2 rounded text-base font-medium mobile-menuitem cursor-pointer"
        [ngClass]="{'menu-active': currentPage === 'message'}">Message
      </a>
    </div>
  </div>
  <!-- END: Mobile Menus-->
</nav>

<app-message-modal [setMessageData]="this.messageDataDecedent" [setMessageSettings]="{showExit: false}"
  (buttonEmitter)="this.decedentModalHandler($event)">
</app-message-modal>
