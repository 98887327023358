import { Component } from '@angular/core';

@Component({
  selector: 'app-register-carousel',
  templateUrl: './register-carousel.component.html',
  styleUrls: ['./register-carousel.component.css'],
})
/**
 * Right carousel
 */
export class RegisterCarouselComponent {

}
