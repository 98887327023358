import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CPCEmitInterface, CPCSettingsInterface } from 'src/app/sharedComponent/choose-people-component/choose-people-interfaces.';
import { LPEmitterInterface, LPSettingsInterface, LPUserPeopleInterface } from 'src/app/sharedComponent/list-people/list-people-interfaces';

@Component({
  selector: 'app-choose-professional-modal',
  templateUrl: './choose-professional-modal.component.html',
  styleUrls: ['./choose-professional-modal.component.css']
})
export class ChooseProfessionalModalComponent implements OnInit {
  @Input() modal_id: string = 'choose-professional-modal';

  @Input() set cpcSettings(data: CPCSettingsInterface) {
    this.settings = data;
    this.lpSettings.checkbox = {
      show: true,
      text: this.settings.text.checkbox.text,
      tooltip: this.settings.text.checkbox.tooltip,
    };
  }

  @Input() professionalPeopleData: Array<LPUserPeopleInterface>;
  @Output() updateEmitter = new EventEmitter<CPCEmitInterface>();

  // Listener for whether to toggle loading graphics
  @Input() set loadListener(isLoading: boolean) {
    this.lpSettings.isLoading = isLoading ?? true;
  }

  public settings: CPCSettingsInterface;
  // ListPerson settings
  public lpSettings: LPSettingsInterface = {};
  public searchKeyword:string=''

  constructor(private modalService: CommonModelService) {
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.lpSettings = {
      checkbox: {
        show: true,
        text: this.settings?.text.checkbox.text,
        tooltip: this.settings?.text.checkbox.tooltip,
      },
      isLoading: false,
    };
  }

  /**
  * Closes the modal and resets checkbox states.
  * After closing the modal and opening it again, ensures that checkboxes are unchecked.
  */
  public closeModel(): void {
    this.professionalPeopleData.filter((e) => e.checkbox.isChecked)
      .forEach((e) => e.checkbox.isChecked = false);
    this.modalService.close(this.modal_id);
  }

  /**
   * Listener for {@link ListPeopleComponent} click events.
   * @param event
   */
  public clickListener(event: LPEmitterInterface) {
    if (event.clickedName) {
      this.updateEmitter.emit({
        person_clicked_id: event.id,
      });
    }
  }

  /**
   * 'Choose As' button handler
   */
  public clickChooseAs(): void {
    this.updateEmitter.emit({
      chosen_people: this.professionalPeopleData.filter((e) => e.checkbox.isChecked),
    });
    this.closeModel();
  }

  public onSearchProfessional(){
    
  }

  /**
   * 'Choose As' button is invalid if nobody has been chosen to set.
   */
  get isValidSet(): boolean {
    return !!this.professionalPeopleData?.find((e) => e.checkbox.isChecked);
  }
}

