import {Component, OnInit} from '@angular/core';
import {CommonHelper} from 'src/app/helper/common.helper';
import {APIResponseModel} from 'src/app/interface/response.interface';
import {DlbService} from 'src/app/services/dlb.service';
import {ReceivedDlbService} from 'src/app/services/received-dlb.service';
import {DLB_STATUS_BTN_TEXT, DLB_STATUS_CLASS, DLB_STATUS_TEXT} from 'src/constants/application.const';

@Component({
  selector: 'app-received-dlb',
  templateUrl: './received-dlb.component.html',
})
/**
   * Received Dlb Component
   */
export class ReceivedDlbComponent implements OnInit {
  public userDetails: Array<any>;
  public initialLoader: boolean;
  public dlbStatusTextMapper: any;
  public dlbStatusTextBtnMapper: any;
  public dlbStatusClass: any;
  public requestStage:number;
  /**
   * @constructor
   */
  constructor(
    private receivedDlbService:ReceivedDlbService,
    private dlbService:DlbService,
    private commonHelper:CommonHelper,
  ) { }

  /**
   * Called Initially On load
   */
  ngOnInit(): void {
    this.dlbStatusTextMapper = DLB_STATUS_TEXT;
    this.dlbStatusTextBtnMapper = DLB_STATUS_BTN_TEXT;
    this.dlbStatusClass = DLB_STATUS_CLASS;
    /**
     * User Details Input Properties || Component: people-account-details
     */
    this.userDetails = [];
    this.getDlbList();

    this.dlbService.dlbListingObserve.subscribe((response:boolean)=>{
      if (response) {
        this.getDlbList();
      }
    });
  }

  /**
   * Information regarding dlb status
   *
   * STATUS 0 = departed not verified (initial)
   * STATUS 1 = departed verified (when verify details given)
   * STATUS 2 = departed approved (If consumer not respond then marked as departed)
   * STATUS 3 = claim dlb (need to work with blockchain, after that will decide)
   */

  /**
   * get dlb list
   */
  public getDlbList():void {
    this.initialLoader = true;
    this.receivedDlbService.getReceivedDlbList().subscribe((response:APIResponseModel)=>{
      this.initialLoader = false;
      if (response.status) {
        this.userDetails = response.data.filter((data)=>data?.user_request?.is_payment_complete).map((data)=>({
          'request_id': data?.user_request_id,
          'first_name': data?.user_request?.user?.first_name,
          'last_name': data?.user_request?.user?.last_name,
          'relationship': data?.relationship,
          'peopleType': (data?.beneficiary === 1)?'Beneficiary':'Executor',
          'bankBalance': '$'+data?.value?.bank_balance || '$3X,XXX.XXX',
          'vehicleValue': '$'+data?.value?.vehicle || '$3X,XXX',
          'propertyValue': '$'+data?.value?.property || '$5X,XX,XXX',
          'status': this.dlbStatusTextMapper[data?.status],
          'btnName': this.dlbStatusTextBtnMapper[data?.status],
          'statusColor': this.dlbStatusClass[data?.status],
          'statusCode': data?.status,
          'btnEnabled': true,
        }));
      }
    }, (exception:any)=>{
      this.initialLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }
}
