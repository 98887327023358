import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'peopleListFilterV2',
})
/**
 * People list filter version 2
 */
export class PeopleListFilterV2Pipe implements PipeTransform {
  /**
   *
   * @param {Array<any>} value
   * @param {Array<any>} args ( index 0 for matching executors/beneficiary and index 1 for matching children/parent )
   * @return {Array<any>}
   */
  transform(value: Array<any>, ...args: Array<any>): Array<any> {


    return value.filter((data:any)=> {
      return (args[0] && (data?.beneficiary === args[0])) || (args[1] && (data?.relation === args[1]));
    });
  }
}
