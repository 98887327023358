import { Component } from '@angular/core';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})
/**
 * Not Found component
 */
export class NotFoundComponent {
  public userType: string;
  public requestId: string;
  /**
   * @constructor
   */
  constructor(
    private slugInterceptorService:SlugInterceptorService,
  ) {
    console.log('not found');
  }

  /**
     * change route
     * @param{string}url
    */
  public changeRoute(url:string) {
    void this.slugInterceptorService.navigate([ url]);
  }
}
