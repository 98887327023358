import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { ICountryCode } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PropertyService } from 'src/app/services/property.service';
import { ACCOUNT_OR_MEMBER_NUMBER_PATTERN, LIST_DELETE, LIST_NEW, LIST_UPDATE, NAME_PATTERN, NUMBERS_ONLY_PATTERN, NUMBERS_VALID_DOLLARS, PHONE_PATTERN, SUBSCRIPTION_PERIOD_TYPE, USER_NAME_WITH_SPECIAL_CHARACTERS_PATTERN, WEBSITE_PATTERN_NEW } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../helper/validation.helper';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-add-subscription',
  templateUrl: './add-subscription.component.html',
})
/**
 * add subscription popup
 */
export class AddSubscriptionComponent implements OnInit {
  @Output() toggleModalEmitter = new EventEmitter<any>();
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() isNewAsset: boolean = true;
  @Input() clientHasPaidSubscription:boolean = false;
  public subscriptionForm: FormGroup;
  public subscriptionTypes: Array<any>;
  public submitLoaderDelete: boolean;
  public submitLoader: boolean;
  public userType: string;
  public clientRequestId: string;
  public countryCode: ICountryCode;
  public togglePasswordView: boolean;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public subscriptionPeriodTypes = SUBSCRIPTION_PERIOD_TYPE;
  public subscriptionPeriodSelected: string;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  isConsumer: boolean;
  private hasPaidSubscription: boolean;

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private propertyService: PropertyService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private toastrService: ToastrService,
    private validation: ValidationHelper,
    private subscriptionService: ConsumerSubscriptionService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.hasPaidSubscription = r);
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.subscriptionTypes = [];
    this.submitLoaderDelete = false;
    this.clientRequestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.isConsumer = this.userType === 'Consumer';
    this.togglePasswordView = false;
    this.initForm();
    this.resetForm();
    this.getSubscriptionTypes();
    if (!this.canAccessForm) {
      this.subscriptionForm.disable();
    }
  }

  public initForm() {
    this.subscriptionForm = this.formBuilder.group({
      property_subscriptions_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
      subscription_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      contact_number: ['', { updateOn: 'blur', validators: [Validators.pattern(PHONE_PATTERN)] }],
      subscription_number: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_ONLY_PATTERN)] }],
      subscription_cost: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
      id: [''],
      website: ['', { updateOn: 'blur', validators: [Validators.pattern(WEBSITE_PATTERN_NEW), Validators.maxLength(150)] }],
      user_name: ['', { updateOn: 'blur', validators: [Validators.minLength(3), Validators.maxLength(30), Validators.pattern(USER_NAME_WITH_SPECIAL_CHARACTERS_PATTERN)] }],
      password: [''],
      account_number: ['', { updateOn: 'blur', validators: [Validators.pattern(ACCOUNT_OR_MEMBER_NUMBER_PATTERN), Validators.maxLength(50)] }],
    });
    this.validation.setValidationErrors({
      contact_number: { pattern: 'Invalid Phone Number' },
      subscription_number: { pattern: 'Invalid Input' },
      subscription_cost: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places.', pattern: false },
      website: { pattern: 'Invalid URL' },
    });

    Object.keys(this.subscriptionForm.controls).forEach(controlName => {
      this.subscriptionForm.get(controlName)?.valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.subscriptionForm.get(controlName))
      );
    });
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.subscriptionForm);
  }

  /**
   * Get subscription types
   */
  public getSubscriptionTypes(): void {
    this.commonService.getSubscriptionTypes().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.subscriptionTypes = this.commonHelper.convertToOptionsFormat(response.data, 'id', 'name').sort(this.commonHelper.compareDisplayText);
        // Always place 'Other' last to make it easy to find.
        this.commonHelper.placeAtPointOfArray(this.subscriptionTypes, (e) => e.displayText.toLowerCase() == 'other');
        this.resetForm();
      }
    });
  }

  /**
   * setter for editing subscription details
   *
   * @param {any} data
   */
  @Input() set editData(data: any) {
    if (data && Object.keys(data).length) {
      let tempPhoneNumber;
      if (data?.contact_number) {
        tempPhoneNumber = data?.contact_number;
        this.countryCode = { code: data?.flag_code, dialCode: data?.country_code };
      }
      let subscription_number = Number(data?.subscription_number);
      if (subscription_number) {
        let subscription = this.commonHelper.determinePeriod(subscription_number);
        data['subscription_number'] = subscription?.['number'];
        this.subscriptionPeriodSelected = subscription?.['period'];
      }
      this.subscriptionForm.patchValue({
        ...data,
        contact_number: tempPhoneNumber,
      });

    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.subscriptionForm.controls;
  }

  /**
   * change event listener for organisation type
   *
   * @param {any} data
   */
  public optionChangeEmitterSubscriptionType(data: any): void {
    if (!data) return;
    this.subscriptionForm.patchValue({
      property_subscriptions_id: data.value,
    });
  }

  /**
   * Updates the subscription form with the selected subscription term.
   * @param data The data emitted by the option change emitter.
   */
  public optionChangeEmitterSubscriptionPeriod(data: any): void {
    if (!data) return;
    this.subscriptionPeriodSelected = data.value;
  }

  /**
   * add subscription
   */
  public onSubmit(): void {
    this.canEdit && this.subscriptionForm.markAllAsTouched();
    if (!this.subscriptionForm.valid) {
      this.commonHelper.scrollToFirstInvalidControl(this.subscriptionForm);
      return; // return if the form is invalid
    }
    this.submitLoader = true;
    // add subscription
    const subscriptionCost = this.subscriptionForm.controls['subscription_cost'].value;
    if (subscriptionCost) {
      this.subscriptionForm.patchValue({
        subscription_cost: this.commonHelper.commaSeparateNumberClean(subscriptionCost, null)
      })
    }
    if (this.userType === 'Consumer') {
      const requestData = {
        ...this.subscriptionForm.value,
        roletype: this.userType,
        country_code: this.countryCode.dialCode,
        flag_code: this.countryCode.code,
        request_id: this.localStorageService.getUserData()?.request?.id,
      };
      requestData['subscription_number'] = this.convertToDays() ?? 0;
      const formData = new FormData();
      // appending every value to form object
      Object.keys(requestData).forEach((key) => {
        if (requestData[key] == undefined) return;
        formData.append(key, requestData[key]);
      });
      this.propertyService.addSubscription(formData).subscribe((response: APIResponseModel) => {
        this.submitLoader = false;
        if (response.status) {
          this.commonHelper.updateLocalstorageRequestStage(response.data);
          this.toggleModalEmitter.emit({
            ...response.data,
            listStatus: (this.subscriptionForm.value.id) ? LIST_UPDATE : LIST_NEW,
          });
          requestData.id ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();
          this.closeModel();
        }
      }, (exception: any) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    } else {
      const requestData = {
        ...this.subscriptionForm.value,
        roletype: this.userType,
        country_code: this.countryCode.dialCode,
        flag_code: this.countryCode.code,
        request_id: this.clientRequestId,
      };
      const formData = new FormData();
      // appending every value to form object
      Object.keys(requestData).forEach((key) => {
        if (requestData[key] == undefined) return;
        formData.append(key, requestData[key]);
      });
      this.propertyService.addSubscription(formData).subscribe((response: APIResponseModel) => {
        this.submitLoader = false;
        if (response.status) {
          this.toggleModalEmitter.emit({
            ...response.data,
            listStatus: (this.subscriptionForm.value.id) ? LIST_UPDATE : LIST_NEW,
          });
          this.closeModel();
        }
      }, (exception: any) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    }
  }

  /**
   * delete subscription details
   */
  public deleteSubscription(): void {
    // ACL check
    if (!this.permissions?.['delete']) {
      this.toastrService.info('You do not have permission to delete items for this client.');
      return;
    }

    this.submitLoaderDelete = true;
    let sendingData: {} = {
      is_delete: '1',
      id: this.subscriptionForm.value.id,
      roletype: this.userType,
    };
    if (this.userType !== 'Consumer') {
      sendingData = { ...sendingData, request_id: this.clientRequestId };
    }

    //  Delete subscription
    this.propertyService.addSubscription(sendingData).subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.toggleModalEmitter.emit({
          id: this.subscriptionForm.value.id,
          listStatus: LIST_DELETE,
        });
        this.commonHelper.toastrDeleteSuccess();
        this.closeModel();
      }
      this.submitLoaderDelete = false;
    }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
  }
  /**
   * Convert to days
   * @returns to days
   */
  private convertToDays(): number {
    const subscriptionValue = this.subscriptionForm.controls['subscription_number'].value;
    if (this.subscriptionPeriodSelected) {
      switch (this.subscriptionPeriodSelected) {
        case 'days':
          return subscriptionValue;
        case 'months':
          return subscriptionValue * 30; // Assuming 1 month = 30 days
        case 'years':
          return subscriptionValue * 365; // Assuming 1 year = 365 days
        default:
          throw new Error('Invalid period');
      }
    }
  }

  /**
   * Detect change on phone number component
   *
   * @param event
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.togglePasswordView = false;
    this.modalService.close('add-subscription-modal');
    this.resetForm();
  }

  /**
   * Reset form
   */
  public resetForm(): void {
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    this.subscriptionPeriodSelected = SUBSCRIPTION_PERIOD_TYPE[0].value;
    this.subscriptionForm.reset({
      property_subscriptions_id: this.subscriptionTypes[0]?.value,
    });
  }

  /**
   * If user can edit existing asset, or add a new asset.
   */
  get canEdit(): boolean {
    return this.commonHelper.canEditSection(this.isNewAsset, this.permissions) && (this.hasPaidSubscription || this.clientHasPaidSubscription)
  }

}
