import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
})
/**
 * payment success component
 */
export class PaymentSuccessComponent implements OnInit {
  /**
   *
   * @param {CommonModelService} modalService
   */
  headerMessage: string = `Your Payment is successfully completed`;
  public isLoginUrl: boolean;
  
  constructor( private modalService:CommonModelService,
    private commonService: CommonService, 
    private route: Router ) { }
  /**
 * initial loader
 */
  ngOnInit(): void {
    //To prevent the modal from opening on the login page.
    this.isLoginUrl = this.route.url == '/' || this.route.url == '/login';
    this.commonService.paymentSuccessMessageObservable.subscribe((response)=>{
      if(response && Object.keys(response).length !== 0){
        this.headerMessage = response;
      }else{
        this.headerMessage = `Your Payment is successfully completed`;
      }
    })
  }

  /**
   * close modal
   */
  public closeModal() {
    this.modalService.close('payment-success');
  }
}
