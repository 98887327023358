import { Component, Input } from '@angular/core';
import { CommonModelService } from '../../services/common-model.service';

@Component({
  selector: 'app-social-share-popup',
  templateUrl: './social-share-popup.component.html',
  styleUrls: ['./social-share-popup.component.css'],
})

/**
 * Intro Video Component
 */
export class SocialSharePopupComponent {
  @Input() class: string = '';
  @Input() id: string = 'social-share-modal';
  isLoading: boolean;


  /**
   * constructor
   */
  constructor(
    private modalService: CommonModelService,
  ) {
  }

  /**
   * Close modal
   */
  public closeModal(): void {
    this.modalService.close(this.id);
  }
}
