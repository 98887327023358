<app-common-modal [id]="modalID" class="app-common-modal">
  <div class="title__wrap text-center mb-4">
    <h1 class="text-semibold text-accent pb-2" [ngClass]="mobile ? 'fs-bhead' : 'fs-title'">
      IMPORTANT INFORMATION FOR DIGITAL VAULT USERS
    </h1>
    <h2 class="text-semibold text-primary" [ngClass]="mobile ? 'fs-bhead' : 'fs-title'">
      ACKNOWLEDGMENT
    </h2>
  </div>
  <!-- <h1 class="text-darkblue text-2xl xl:text-3xl uppercase text-semibold pt-1 py-2"></h1> -->

  <!-- <h4 class="text-regular text-lg font-semibold tracking-wide py-2"></h4> -->

  <p class="text-content text-regular mb-4">Your Digital Vault and related services provided by LegacyNOW
    are intended solely to facilitate the collection of a wide range of information and other data which will
    simplify your estate planning needs and facilitate the resolution of your estate upon your passing. With your
    prior authorization, your Digital Vault allows your executor and/or heirs to readily and easily access all this
    information in one place - easing the administrative and reporting burdens that are a necessary task upon your
    passing. For that reason, it is important that the information in your Digital Vault is maintained, modified,
    and updated to have the most accurate information on hand when it is most needed.</p>

  <p class="text-content text-regular mb-4">PLEASE NOTE, however, that the maintenance, modification or
    updating of any information deposited into your digital vault regarding your estate (and particularly your
    heirs, beneficiaries, and their respective inheritances) in no way impacts or substitutes that may be provided
    in your will or any provision of applicable law. Your Digital Vault is simply a secured holding facility for
    important information. </p>
  <p class="text-content text-regular mb-4 uppercase">The LegacyNOW services in no way constitutes legal
    advice or
    impacts the application of law to the TERMS of your will (as applicable), THE VALIDITY OF YOUR WILL, OR the
    resolution of your estate. Should you desire or intend to effect anY modification, change or updating of your
    estate, your will, your specific bequests, or any other matter concerning your estate and/or estate planning,
    you must consult with a legal and/or financial professional and take the legally required actions to effect your
    estate and its distribution upon your passing. LegacyNOW in no way accepts or assumes any responsibility with
    respect to your estate or any inheritance or distribution thereof. All matters concerning the Digital Vault and
    LegacyNOW are provided in YOUR
    <a class="link" href="/digital-vault-agreement" target="_blank">Digital Vault Agreement</a>.
  </p>

  <label class="text-content text-regular mb-4 py-2">
    <i><b>
      I have read and understood the notice above and acknowledge and agree that (i) LEGACYNOW does not provide
      and legal, financial or other advice with regard to my assets, estate or otherwise, and (ii) LEGACYNOW
      accepts or assumes no responsibility or liability with respect to my estate, inheritance, or distribution
      thereof. I further acknowledge that any modification, change or updating of the information in my Digital
      Vault requires consultation with a legal and/or financial professional and may further require other actions
      (such as the modification of a will) to have any impact on the resolution of your estate.
    </b></i>
  </label>
  <div class="flex gap-4 py-4 text-center mt-8">
    <button
      (click)="closeModal(null)"
      class="custom-border-button button button--lg mx-auto flex focus:outline-none hover:underline-text items-center justify-center md:w-auto px-4 xl:px-8 py-2 xl:py-3 rounded-xs text-sm md:text-base xl:text-2xl text-white w-full">
      Close
    </button>
    <button
      (click)="closeModal(true)"
      class="bg-darkblue button button--lg mx-auto flex focus:outline-none hover:underline-text items-center justify-center md:w-auto px-4 xl:px-8 py-2 xl:py-3 rounded-xs text-sm md:text-base xl:text-2xl text-white w-full">
      Accept
    </button>
  </div>
</app-common-modal>
