import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SSNToggleInterface, SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';

import { ActivatedRoute } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { LocalStorageService } from 'src/app/services/local-storage.service';

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonService } from 'src/app/services/common.service';
import { DATE_FORMAT, LOCAL_STORAGE_UPDATE_STATUS, NAME_PATTERN, PHONE_PATTERN, SSN_MASK, SSN_PATTERN, USER_TYPES } from 'src/constants/application.const';
import { ELEMENT_EVENTS, STATUS_CHECK } from 'src/constants/form.const';
import { environment } from 'src/environments/environment';
import { DatepickerDirective } from '../../../directives/datepicker.directive';
import { ValidationHelper } from '../../../helper/validation.helper';


@Component({
  selector: 'app-naming-details',
  templateUrl: './naming-details.component.html',
  styleUrls: ['./naming-details.component.css'],
})
/**
 * Naming details in about yourself section
 */
export class NamingDetailsComponent implements OnInit {
  public countryCode = environment.DEFAULT_COUNTRY_CODE;
  public namingForm: FormGroup;
  public submitLoader: boolean;
  public preDefinedLoader: boolean;
  public sectionSaveExitOptions: any;
  public currentDateRestriction: string;
  public SSN_MASK: string;
  private currentUserDetails: any;
  public userType: string;
  public requestId: string;
  public locale = 'en';
  public minDate: Date;
  public maxDate: Date;
  public bsConfig: Partial<BsDatepickerConfig>;
  public showSSN: boolean;
  public toggleSSNView: SSNToggleInterface;
  public faEye: any;
  public faEyeSlash: any;
  public confirmSSNMatch: boolean = true;
  public domainDateFormat: string;
  public isUniqueSSN: boolean = false;
  public validatingSSN: number = ssnValidationProcess.initial;
  public personId: string = ''; // request id
  public defaultCountry: number;
  public selectedCountry: number;
  @ViewChild('dp') datepicker: DatepickerDirective;

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private personalDetailsService: PersonalDetailsService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private localeService: BsLocaleService,
    private slugInterceptorService: SlugInterceptorService,
    private commonService: CommonService,
    private validation: ValidationHelper
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
    this.minDate = new Date(1900, 0, 1);
    this.minDate.setDate(this.minDate.getDate());
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.initForm();
    this.selectedCountry = environment.APP_DOMAIN;
    try {
      this.defaultCountry = this.localStorageService.getDataByKey('country');
    } catch {
      // Handle case where country was stored as 'undefined'
      this.defaultCountry = this.selectedCountry;
    }
    this.currentUserDetails = this.localStorageService.getUserData();
    this.domainDateFormat = this.commonHelper.domainDateFormat;
    this.SSN_MASK = SSN_MASK;
    this.faEye = faEye;
    this.faEyeSlash = faEyeSlash;
    this.showSSN = environment.APP_DOMAIN == 1;
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.currentDateRestriction =
      this.commonHelper.getCurrentDateForRestriction();
    this.sectionSaveExitOptions = {
      section: 1,
      type: 1,
    };
    this.toggleSSNView = { ssn: false, v_ssn: false };
    this.namingForm.controls['ssn_validation'].valueChanges.subscribe(() => {
      this.confirmSSNMatch = true;
    });
    this.getPersonalDetails();
    this.applyLocale();
    this.bsConfig = Object.assign({}, {
      showWeekNumbers: false,
      adaptivePosition: true,
      minDate: this.minDate,
      maxDate: this.maxDate,
      dateInputFormat: this.domainDateFormat,
    });
  }

  public initForm() {
    const userData = this.localStorageService.getUserData()?.user || {};
    const name = {
      first: userData.first_name || '',
      middle: userData.middle_name || '',
      last: userData.last_name || '',
    };

    // Initiating form
    this.namingForm = this.formBuilder.group({
      first_name: [
        name.first,
        {
          updateOn: 'blur',
          validators: [
            Validators.required,
            Validators.pattern(NAME_PATTERN),
            Validators.maxLength(50),
          ],
        },
      ],
      middle_name: [
        name.middle,
        {
          updateOn: 'blur',
          validators: [
            Validators.pattern(NAME_PATTERN),
            Validators.maxLength(50),
          ],
        },
      ],
      last_name: [
        name.last,
        {
          updateOn: 'blur',
          validators: [
            Validators.required,
            Validators.pattern(NAME_PATTERN),
            Validators.maxLength(50),
          ],
        },
      ],
      dob: ['', { validators: [Validators.required, this.commonHelper.DOBValidator.bind(this)], updateOn: 'blur' }],
      place_of_birth: ['', { updateOn: 'blur', validators: [Validators.required] }],
      ssn: ['', { validators: [Validators.required, this.validation.fn.SSNPatternValidator(SSN_PATTERN)], updateOn: 'blur' }],
      ssn_validation: [''],
      phone: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(PHONE_PATTERN)] }],
    });

    this.validation.setValidationErrors({
      dob: { isNotAllowed: 'Must be at least 18 years of age', invalidYear: 'Invalid Date' },
      phone: { pattern: 'Please enter a valid phone number' },
      ssn: { pattern: 'Invalid SSN', mask: null },
    });

    const trimWhitespaceControls = ['first_name', 'middle_name', 'last_name'];

    trimWhitespaceControls.forEach(controlName => {
      this.namingForm.controls[controlName].valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.namingForm.controls[controlName])
      );
    });

  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.namingForm);
  }

  /**
   * get Age
   * @param {string}date
   * @return {number}
   */
  public getAge(date: string) {
    return this.commonHelper.getAge(date);
  }

  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.namingForm?.controls;
  }

  /**
   * Get personal details
   */
  public getPersonalDetails(): void {
    this.preDefinedLoader = true;

    // Get Name/DOB/SSN details.
    this.personalDetailsService.getPersonalDetails(this.userType !== 'Consumer' ? this.requestId : undefined).subscribe({
      next: (response: APIResponseModel) => {
        // to check empty response and prevent dob field prefill
        const noResponseData = Object.keys(response.data).length === 0;
        this.namingForm.patchValue({ place_of_birth: this.defaultCountry });
        if (noResponseData) {
          return;
        } else {
          this.sectionSaveExitOptions.id = response.data.user_id;
          this.countryCode = response?.data?.user?.country_code && (response?.data?.user?.country_code != undefined || response?.data?.user?.country_code != null) ? { code: response?.data?.user?.flag_code, dialCode: response?.data?.user?.country_code } : this.countryCode;
          const dateOfBirth = response?.data?.dob ? new Date(response?.data?.dob.replace(/-/g, '/')) : '';
          
          this.namingForm.patchValue(response?.data);
          this.namingForm.patchValue({
            first_name: response.data.user?.first_name,
            middle_name: response.data.user?.middle_name ?? '',
            last_name: response.data.user?.last_name,
            ssn_validation: response.data?.ssn,
            dob: dateOfBirth,
            place_of_birth: response?.data?.place_of_birth?.id ?? this.defaultCountry,
            phone: response?.data?.['user'].phone
          });
          this.personId = response?.data?.id; // request id
          if (this.namingForm.controls.ssn.value && this.checkSSNMatch()) { // check is SSN unique
            this.checkIsSsnUnique(this.namingForm.controls.ssn.value, this.personId);
          }
        }
      },
      error: (exception) => {
        this.namingForm.patchValue({
          place_of_birth: this.defaultCountry,
        });
        this.commonHelper.httpResponseHandler(exception?.error);
      },
      complete: () => this.preDefinedLoader = false,
    });
  }

  /**
   * Handle on submit
   */
  public onSubmit(): void {
    this.namingForm.markAllAsTouched();
    if (!this.namingForm.valid) return; // return if the form is invalid
    this.confirmSSNMatch = Boolean(this.namingForm.controls['ssn'].value === this.namingForm.controls['ssn_validation'].value);
    if (!this.confirmSSNMatch) {
      return;
    }
    this.submitLoader = true;

    this.namingForm.value.first_name = this.namingForm.controls.first_name.value;
    this.namingForm.value.middle_name = this.namingForm.controls.middle_name.value;
    this.namingForm.value.last_name = this.namingForm.controls.last_name.value;
    if (this.namingForm.value.dob) {
      this.namingForm.value.dob = this.commonHelper.formatDate(this.namingForm.value.dob, DATE_FORMAT);
    }
    if (this.userType === 'Consumer') {
      const payLoad = {
        ...this.namingForm.value,
        roletype: this.userType,
        country_code: this.countryCode.dialCode,
        flag_code: this.countryCode.code,
      };
      console.log('payload', payLoad);
      this.personalDetailsService.storePersonalDetails(payLoad, 1).subscribe((response: APIResponseModel) => {
          this.submitLoader = false;

          if (response.status) {
            if (this.currentUserDetails.request?.id) {
              this.commonHelper.updateLocalstorageRequestStage(response.data);
            } else {
              this.localStorageService.updateUserData(USER_TYPES.user, {
                key: 'request',
                updateValue: {
                  id: response.data.id,
                  request_stage: response.data.request_stage,
                  is_payment_complete: this.currentUserDetails.user.is_payment_complete,
                },
                type: LOCAL_STORAGE_UPDATE_STATUS.S,
              });
            }
            void this.slugInterceptorService.navigate(['about-yourself', 'location-details']);
          }
        }, (exception: any) => {
          this.submitLoader = false;
          this.commonHelper.httpResponseHandler(exception?.error);
        },
      );
    } else {
      const payLoad = {
        ...this.namingForm.value,
        first_name: this.namingForm.controls.first_name.value,
        last_name: this.namingForm.controls.last_name.value,
        roletype: this.userType,
        request_id: this.requestId,
      };
      if (this.namingForm.value.dob) {
        this.namingForm.value.dob = this.commonHelper.formatDate(this.namingForm.value.dob, DATE_FORMAT);
      }
      this.personalDetailsService.storePersonalDetails(payLoad, 1).subscribe((response: APIResponseModel) => {
          this.submitLoader = false;
          if (response.status) {
            void this.slugInterceptorService.navigate([`/about-yourself/location-details/${this.requestId}`],
            );
          }
        }, (exception: any) => {
          this.submitLoader = false;
          this.commonHelper.httpResponseHandler(exception?.error);
        },
      );
    }
  }

  /**
   * Listen for option change (salutation)
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitter(selectedOption: SelectOptionsInterface): void {
    this.namingForm.patchValue({
      prefix: selectedOption.value,
    });
  }

  /**
   * Auto hypen SSN keyup
   * @param {any} event
   */
  public autoHypenSSN(event: any): void {
    this.namingForm.patchValue({
      ssn: this.commonHelper.autoHypenSSN(this.namingForm.value.ssn),
    });
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, this.userType !== 'Consumer');
  }

  /**
   *
   * @return{boolean}
   */
  public checkSSNMatch(): boolean {
    return this.namingForm.controls['ssn'].value == this.namingForm.controls['ssn_validation'].value;
  }

  /**
   * typing / onchange input field
   * changed as untouched field
   * not show validation error
   * @param {any} data
   * @param {string} key
   */
  public onChange(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.INPUT) {
      this.namingForm.get(key).markAsUntouched();
      this.validatingSSN = ssnValidationProcess.initial;
    }
  }

  /**
   * focusout of input field
   * changed as touched field
   * check and show validation error
   * @param {any} data
   * @param {string} key
   */
  public onFocusout(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.FOCUSOUT) {
      this.namingForm.get(key).markAllAsTouched();
    }
    if (this.namingForm.controls.ssn.status == STATUS_CHECK.VALID && this.checkSSNMatch()) { // if SSN status valid and ssn and confirmation ssn are same
      this.checkIsSsnUnique(this.namingForm.controls.ssn.value, this.personId);
    }
  }

  /**
   * check ssn is unique
   * @param {string} data
   * @param{string} id
   */
  public checkIsSsnUnique(data: string, id: string) {
    this.validatingSSN = ssnValidationProcess.start_validation;
    this.commonService.getIsSSNUnique(data, id, this.commonHelper.isShowSSNToast(), 1).subscribe({ // passing value 1 to set isConsumerDetailsUpdate-(payload from user naming details)
      next: (r) => {
        this.isUniqueSSN = r;
        this.validatingSSN = ssnValidationProcess.end_validation;
      },
      error: () => this.validatingSSN = ssnValidationProcess.end_validation,
    });
  }
/**
   * Detect change on phone number component
   *
   * @param event
   */
public detectChangePhonenumber(event): void {
  if (event) {
    this.countryCode = event.value;
  }
}
  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }

  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry || this.preDefinedLoader) return;
    this.namingForm.patchValue({
      place_of_birth: selectedCountry.value,
    });
  }
}


/**
 * enum for ssn validation processing
 */
enum ssnValidationProcess {
  initial,
  start_validation,
  end_validation
}
