<div class="pb-12 xl:p-12" *ngIf="this.userType === 'Consumer'">
  <div class="flex justify-end px-8 sm:px-12 sm:mx-2 mt-4 lg:mx-0 lg:pr-16" *ngIf="useButton">
    <app-button-loader [buttonText]="'Save & Return to Dashboard'" *ngIf="buttonStatus >0 && newUser && otherValues.type"
      [valid]="true" [loader]="submitLoader" [buttonType]="'button'" (click)="saveAndExit()"
      [outlineOnly]="true"></app-button-loader>
    <app-button-loader [buttonText]="'Save & Return to Dashboard'" [buttonTitle]="tooltip"
      *ngIf="buttonStatus == 0 && otherValues.type" [valid]="false" [loader]="submitLoader" [buttonType]="'button'"
      [outlineOnly]="true"></app-button-loader>
    <app-button-loader (click)="changeRoute('dashboard')" [buttonText]="'Return to Dashboard'" [valid]="true"
      *ngIf="otherValues.section===2&& !otherValues.type" [loader]="submitLoader" [buttonType]="'button'"
      [outlineOnly]="true"></app-button-loader>
  </div>
  <div class="h-full pt-12 hidden lg:flex flex justify-center items-center">
    <img src="assets/images/about-yourself/right-img.svg" class="w-6/12 md:w-full lg:w-9/12" alt="digital vault">
  </div>
</div>
<div class="pb-12 xl:p-12" *ngIf="this.userType !== 'Consumer'">
  <div class="flex justify-end px-8 sm:px-12 sm:mx-2 mt-4 lg:mx-0 lg:pr-16" *ngIf="useButton">
    <app-button-loader [buttonText]="'Save & Return to Dashboard'" *ngIf="buttonStatus >0 && otherValues.type " [valid]="true"
      [loader]="submitLoader" [buttonType]="'button'" (click)="saveAndExit()" [outlineOnly]="true"></app-button-loader>
    <app-button-loader [buttonText]="'Save & Return to Dashboard'" [buttonTitle]="tooltip" *ngIf="buttonStatus == 0" [valid]="false"
      [loader]="submitLoader" [buttonType]="'button'" [outlineOnly]="true"></app-button-loader>
    <app-button-loader (click)="changeRoute('dashboard')" [buttonText]="'Return to Dashboard'" [valid]="true"
      *ngIf="otherValues.section===2&& !otherValues.type && this.userType !== 'Custodian'" [loader]="submitLoader" [buttonType]="'button'"
      [outlineOnly]="true"></app-button-loader>
  </div>
  <div class="h-full pt-12 hidden lg:flex flex justify-center items-center">
    <img src="assets/images/about-yourself/right-img.svg" class="w-6/12 md:w-full lg:w-9/12" alt="digital vault">
  </div>
</div>
