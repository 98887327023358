import { Component, OnInit } from '@angular/core';
import { Consumer4060RootComponent } from '../consumer-40-60-root.component';


@Component({
  selector: 'app-consumer-40-60-questions-short',
  templateUrl: './consumer-40-60-questions-short.component.html',
})
export class QuestionShort4060Component extends Consumer4060RootComponent implements OnInit {

  override ngOnInit() {
    super.ngOnInit();
    Consumer4060RootComponent.isQuestionsShort = true;
  }
}
