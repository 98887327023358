<div id="overview">
  <div class="grid grid-cols-12 gap-6 my-8">
    <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
      <div class="border rounded-2xl bg-white">
        <div class="grid grid-cols-12 items-center p-5">
          <div class="col-span-10">
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'20%'">
              <h4 class="text-base text-blue-500 sm:text-lg text-regular tracking-wide  text-semibold">
                {{dashboardData?.['user_vehicle']?.length}}</h4>
            </app-shimmer-loading>
            <p class="text-gray-800 text-sm break-all ">No of Vehicles</p>
          </div>
          <div class="col-span-2">
            <img src="assets/images/dashboard-tab/noodvehicle.svg" class="object-contain float-right"
              alt="digital vault">
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
      <div class=" border rounded-2xl bg-white">
        <div class="grid grid-cols-12 items-center p-5">
          <div class="col-span-10">
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'20%'">
              <h4 class="text-base text-green-500 sm:text-lg text-regular tracking-wide  text-semibold ">
                {{dashboardData?.['user_insurance']?.length}}</h4>
            </app-shimmer-loading>
            <p class="text-gray-800  text-sm break-all ">No of Insurance
            </p>
          </div>
          <div class="col-span-2">
            <img src="assets/images/dashboard-tab/noofinsurance.svg" class="object-contain float-right"
              alt="digital vault">
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
      <div class=" border rounded-2xl bg-white">
        <div class="grid grid-cols-12 items-center p-5">
          <div class="col-span-10">
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'20%'">
              <h4 class="text-base text-yellow-400 sm:text-lg text-regular tracking-wide  text-semibold ">
                {{dashboardData?.['user_financial_asset']?.length}}</h4>
            </app-shimmer-loading>
            <p class="text-gray-800 text-sm break-all ">No of Finance
            </p>
          </div>
          <div class="col-span-2">
            <img src="assets/images/dashboard-tab/nooffinance.svg" class="object-contain float-right"
              alt="digital vault">
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
      <div class=" border rounded-2xl bg-white">
        <div class="grid grid-cols-12 items-center p-5">
          <div class="col-span-10">
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'20%'">
              <h4 class="text-base text-red-500 sm:text-lg text-regular tracking-wide  text-semibold ">
                {{dashboardData?.['user_property']?.length}}</h4>
            </app-shimmer-loading>
            <p class="text-gray-800  text-sm break-all ">No of Properties
            </p>
          </div>
          <div class="col-span-2">
            <img src="assets/images/dashboard-tab/noofproperty.svg" class="object-contain float-right"
              alt="digital vault">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-12">
    <div class="flex flex-row justify-between items-center mb-4 h-10">
      <h4 class="text-xl text-regular tracking-wide text-darkblue text-semibold">
        Finances</h4>
    </div>
  </div>

  <div class="grid grid-cols-12 gap-6 my-1  ">
    <!--start-->
    <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
      <div class=" border rounded-2xl bg-white">
        <div class="p-5 h-80">
          <div class="grid grid-cols-12 items-start h-full">
            <div class="col-span-10 h-full">
              <p class="text-gray-800  text-sm break-all "> Net Worth </p>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'25%'">
                <h4 class="text-base text-black-500 sm:text-lg text-regular tracking-wide  text-semibold">
                  {{(assetData?.total ? assetData?.total : '') | currency}}</h4>
              </app-shimmer-loading>
            </div>
            <div class="col-span-2">
              <img src="assets/images/dashboard-tab/noofmoney.svg" class="object-contain float-right"
                alt="digital vault">
            </div>
            <div class="col-span-12 py-4">
              <div class="border-t-4 border-gray-200"></div>
            </div>
            <app-shimmer-loading class="col-span-12 w-full" [loading]="initialDataLoader" [width]="'100%'"
              [height]="'8rem'">
            </app-shimmer-loading>
            <div *ngIf="!initialDataLoader" class="col-span-12 info-box items-center relative h-full">
              <canvas baseChart [height]="'100%'" [data]="doughnutChartData" [labels]="doughnutChartLabels"
                [chartType]="'doughnut'" [legend]="true" [options]="doughnutOptions" [colors]="doughnutColor">
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end-->
    <!--start-->
    <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
      <div class=" border rounded-2xl bg-white">
        <div class="p-5 h-80">
          <div class="grid grid-cols-12 items-start">
            <div class="col-span-10">
              <p class="text-gray-800  text-sm break-all "> Total Assets </p>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'25%'">
                <h4 class="text-base text-black-500 sm:text-lg text-regular tracking-wide  text-semibold ">
                  {{(assetData?.['asset']?.total || '') | currency}} </h4>
              </app-shimmer-loading>
            </div>
            <div class="col-span-2">
              <img src="assets/images/dashboard-tab/noofasset.svg" class="object-contain float-right"
                alt="digital vault">
            </div>
            <div class="col-span-12 py-4">
              <div class="border-t-4 border-gray-200"></div>
            </div>

            <div *ngIf="initialDataLoader" class="col-span-12">
              <ng-container *ngFor="let _ of [].constructor(5)">
                <div class="flex justify-between">
                  <app-shimmer-loading [loading]="true" [width]="'6rem'"></app-shimmer-loading>
                  <app-shimmer-loading [loading]="true" [width]="'6rem'"></app-shimmer-loading>
                </div>
              </ng-container>
            </div>
            <div *ngIf="!initialDataLoader" class="col-span-12">
              <div class="flex justify-between mb-2" *ngIf="assetData?.['asset']?.['financial'] > 0">
                <h4 class="text-sm text-gray-800 sm:text-base text-regular tracking-wide">Financial</h4>
                <p class="text-sm text-blue-600 sm:text-base text-regular tracking-wide">
                  {{assetData?.['asset']?.['financial'] | currency}}</p>
              </div>
              <div class="flex justify-between mb-2" *ngIf="assetData?.['asset']?.['retirement'] > 0">
                <h4 class="text-sm text-gray-800 sm:text-base text-regular tracking-wide">Retirement</h4>
                <p class="text-sm text-black-300 sm:text-base text-regular tracking-wide">
                  {{assetData?.['asset']?.['retirement'] | currency}}</p>
              </div>
              <div class="flex justify-between mb-2" *ngIf="assetData?.['asset']?.['property'] > 0">
                <h4 class="text-sm text-gray-800 sm:text-base text-regular tracking-wide">Property</h4>
                <p class="text-sm text-blue-600 sm:text-base text-regular tracking-wide">
                  {{assetData?.['asset']?.['property'] | currency}}</p>
              </div>
              <div class="flex justify-between mb-2" *ngIf="assetData?.['asset']?.['vehicle'] > 0">
                <h4 class="text-sm text-gray-800 sm:text-base text-regular tracking-wide">Vehicle</h4>
                <p class="text-sm text-black-300 sm:text-base text-regular tracking-wide">
                  {{assetData?.['asset']?.['vehicle'] | currency}}</p>
              </div>
              <div class="flex justify-between mb-2" *ngIf="assetData?.['asset']?.['business'] > 0">
                <h4 class="text-sm text-gray-800 sm:text-base text-regular tracking-wide">Business</h4>
                <p class="text-sm text-blue-600 sm:text-base text-regular tracking-wide">
                  {{assetData?.['asset']?.['business'] | currency}}</p>
              </div>
              <div class="flex justify-between mb-2" *ngIf="assetData?.['asset']?.['valuable'] > 0">
                <h4 class="text-sm text-gray-800 sm:text-base text-regular tracking-wide">Valuable</h4>
                <p class="text-sm text-black-600 sm:text-base text-regular tracking-wide">
                  {{assetData?.['asset']?.['valuable'] | currency}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end-->
    <!--start-->
    <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
      <div class=" border rounded-2xl bg-white">
        <div class="p-5 h-80">
          <div class="grid grid-cols-12 items-start">
            <div class="col-span-10">
              <p class="text-gray-800 text-sm break-all "> Total Liabilities </p>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'25%'">
                <h4 class="text-base text-black-500 sm:text-lg text-regular tracking-wide text-semibold">
                  {{(assetData?.['liability']?.['total'] ? assetData?.['liability']?.['total'] : '') | currency}} </h4>
              </app-shimmer-loading>
            </div>
            <div class="col-span-2">
              <img src="assets/images/dashboard-tab/totalasset.svg" class="object-contain float-right"
                alt="digital vault">
            </div>
            <div class="col-span-12 py-4">
              <div class="border-t-4 border-gray-200"></div>
            </div>
            <div *ngIf="initialDataLoader" class="col-span-12">
              <ng-container *ngFor="let _ of [].constructor(4)">
                <div class="flex justify-between">
                  <app-shimmer-loading [loading]="true" [width]="'6rem'"></app-shimmer-loading>
                  <app-shimmer-loading [loading]="true" [width]="'6rem'"></app-shimmer-loading>
                </div>
              </ng-container>
            </div>
            <div *ngIf="!initialDataLoader" class="col-span-12">
              <div class="flex justify-between mb-2" *ngIf="assetData?.['liability']?.['credit_card'] > 0">
                <h4 class="text-sm text-gray-800 sm:text-base text-regular tracking-wide">Credit Card</h4>
                <p class="text-sm text-red-500 sm:text-base text-regular tracking-wide">
                  {{assetData?.['liability']?.['credit_card'] | currency}}</p>
              </div>
              <div class="flex justify-between mb-2" *ngIf="assetData?.['liability']?.['loan'] > 0">
                <h4 class="text-sm text-gray-800 sm:text-base text-regular tracking-wide">Loan</h4>
                <p class="text-sm text-black-300 sm:text-base text-regular tracking-wide">
                  {{assetData?.['liability']?.['loan'] | currency}}</p>
              </div>
              <div class="flex justify-between mb-2" *ngIf="assetData?.['liability']?.['mortgage'] > 0">
                <h4 class="text-sm text-gray-800 sm:text-base text-regular tracking-wide">Mortgage</h4>
                <p class="text-sm text-red-500 sm:text-base text-regular tracking-wide">
                  {{assetData?.['liability']?.['mortgage'] | currency}}</p>
              </div>
              <div class="flex justify-between mb-2" *ngIf="assetData?.['liability']?.['others'] > 0">
                <h4 class="text-sm text-gray-800 sm:text-base text-regular tracking-wide">Other</h4>
                <p class="text-sm text-blue-300 sm:text-base text-regular tracking-wide">
                  {{assetData?.['liability']?.['others'] | currency}}</p>
              </div>
              <div class="flex justify-between mb-2">
                <h4 class="text-sm text-gray-800 sm:text-base text-regular tracking-wide"></h4>
                <p class="text-sm text-blue-300 sm:text-base text-regular tracking-wide"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end-->
  </div>
  <!--start-->
  <div class="col-span-12 md:col-span-8 my-12" *ngIf="showMonthlyChart">
    <div class="flex flex-row justify-between items-center mb-4 h-10">
      <h4 class="text-xl text-regular tracking-wide text-darkblue text-semibold">
        Monthly Spent</h4>
      <div class="relative w-24">
        <app-select-component [options]="years" [year]="true" [selectedOptionDefault]="defaultSelected"
          (optionChangeEmitter)="optionChangeEmitter($event)"
          (toggleEmiter)="toggleEmiter($event)"></app-select-component>
      </div>
    </div>

    <app-shimmer-loading [loading]="showChart" [width]="'100%'">
      <h4 class="text-base text-red-500 sm:text-lg text-regular tracking-wide  text-semibold "></h4>
    </app-shimmer-loading>
    <app-shimmer-loading [loading]="showChart" [width]="'100%'">
      <h4 class="text-base text-red-500 sm:text-lg text-regular tracking-wide  text-semibold "></h4>
    </app-shimmer-loading>
    <app-shimmer-loading [loading]="showChart" [width]="'10%'" [height]="'20px'">
      <h4 class="text-base text-red-500 sm:text-lg text-regular tracking-wide  text-semibold "></h4>
    </app-shimmer-loading>

    <div class="info-box border rounded-md p-6 flex items-center relative" *ngIf="!showChart">
      <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [legend]="false"
        [chartType]="'bar'" [colors]="[{backgroundColor:'#d72c00'}]">
      </canvas>
    </div>
  </div>
  <!--end-->
</div>
