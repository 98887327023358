import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { ICountryCode, SSNToggleInterface, SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { SelectWithSearchComponent } from 'src/app/sharedComponent/select-with-search/select-with-search.component';
import { APARTMENT_NUMBER_PATTERN, BENEFICIARY_LABELS, BENEFICIARY_TYPE, DATE_FORMAT, EIN_MASK, EIN_NUMBER_PATTERN, EMAIL_PATTERN, LIST_DELETE, LIST_NEW, LIST_UPDATE, MODAL_CLOSE, NAME_PATTERN, PHONE_PATTERN, SSN_MASK, SSN_PATTERN, UNITED_KINGDOM } from 'src/constants/application.const';
import { ELEMENT_EVENTS, STATUS_CHECK } from 'src/constants/form.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../helper/validation.helper';
import { MessageModalDataInterface } from '../../message-modal/message-modal.component';


@Component({
  selector: 'app-add-beneficiary',
  templateUrl: './add-beneficiary.component.html',
})
/**
 * Add beneficiary modal
 */
export class AddBeneficiaryComponent implements OnInit, AfterViewInit {
  @ViewChildren(SelectWithSearchComponent) inputComponents: QueryList<SelectWithSearchComponent>;
  @Input() toggleModal: boolean;
  @Output() toggleModalEmitter = new EventEmitter<any>();
  public readonly faEye = faEye;
  public readonly faEyeSlash = faEyeSlash;
  public readonly EIN_MASK = EIN_MASK;
  public readonly SSN_MASK = SSN_MASK;
  public readonly labelMappings = BENEFICIARY_LABELS;
  public readonly beneficiaryTypesConst = BENEFICIARY_TYPE;
  public readonly unitedKingdom = UNITED_KINGDOM;
  public relationshipList: Array<SelectOptionsInterface> = [];
  public touchedBeneType: boolean = false;
  public selectedCountry: number = environment.APP_DOMAIN;
  public toggleSSNView: SSNToggleInterface = { ssn: false, v_ssn: false };
  public beneficiaryForm: FormGroup;
  public preDefinedLoader: boolean;
  public submitLoader: boolean;
  public submitLoaderDelete: boolean;
  public currentDateRestriction: string;
  public countryCode: ICountryCode;
  public trusteeTypesList: Array<any>;
  public beneficiaryTypes: Array<any>;
  public beneficiaryTypeId: number;
  public beneficiaryId: string;
  public beneficiaryType: number;
  public userType: string;
  public requestId: string;
  public locale = 'en';
  public minDate: Date;
  public maxDate: Date;
  public bsConfig: Partial<BsDatepickerConfig>;
  public showSSN: boolean;
  public defaultCountry: number;
  public selectedCountryName: string = '';
  public personId: string;
  public validatingSSN: any = ssnValidationProcess.initial;
  public isUniqueSSN: boolean = false;
  public userDetails: any;
  public messageData: MessageModalDataInterface;
  public elementRefSet: boolean = false;
  public searchElementRef: ElementRef;
  public isCharityType: boolean;
  public isRevocable: boolean;
  public getPropertyData: any;

  /**
   * @param{ElementRef}value
   */
  @ViewChild('search', { static: false })
  set setSearchElementRef(value: ElementRef) {
    this.searchElementRef = value;
    if (this.searchElementRef && !this.elementRefSet) { // if not elementRef set initial value for searchElement
      this.initMap(this.defaultCountry);
      this.elementRefSet = true;
    }
  }

  public domainDateFormat: any;
  public toggleEmitterListenerIndividual: boolean = false; // toggle listener
  public toggleEmitterListenerTrust: boolean = false; // toggle listener
  private selectedTrustType: SelectOptionsInterface;
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;

  /**
   * constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private commonHelper: CommonHelper,
    private addressHelper: AddressHelper,
    private modalService: CommonModelService,
    private peopleService: PeopleService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private localeService: BsLocaleService,
    private ngZone: NgZone,
    private validation: ValidationHelper,
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
    this.minDate = new Date(1900, 0, 1);
    this.minDate.setDate(this.minDate.getDate());
  }

  /**
   * called initially
   */
  ngOnInit() {
    this.domainDateFormat = this.commonHelper.domainDateFormat;
    this.showSSN = environment.APP_DOMAIN == 1;
    this.currentDateRestriction = this.commonHelper.getCurrentDateForRestriction();
    this.submitLoader = false;
    this.preDefinedLoader = false;
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    this.userDetails = this.localStorageService.getUserData();
    this.getRelationshipList();
    this.getBeneficiaryTypes();
    this.getTrusteeTypes();
    this.initForm();
    this.resetForm();
    this.applyLocale();
    this.isEnableState();
    this.bsConfig = Object.assign({}, {
        showWeekNumbers: false,
        minDate: this.minDate,
        maxDate: this.maxDate,
        adaptivePosition: true,
        dateInputFormat: this.domainDateFormat,
      },
    );
    /**
     * Listen for current modal
     */
    this.commonService.currentModalStatusObservable.subscribe((status: string) => {
        if (status === MODAL_CLOSE && this.defaultCountry) {
          this.initMap(this.defaultCountry);
          this.resetForm();
          this.removePacContainer();
        }
      },
    );
    if (this.trusteeTypesList?.length) {
      this.resetForm();
    }
    this.getPropertyData = [];
  }

  private initForm() {
    this.beneficiaryForm = this.formBuilder.group({
      individualType: this.formBuilder.group({
        first_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
        middle_name: ['', { updateOn: 'blur', validators: [Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
        last_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
        dob: ['', { updateOn: 'blur', validators: [this.validation.fn.dateRange(this.minDate, this.maxDate)] }],
        // If locale doesn't have ssn, ignore validations.
        ssn: ['', !this.showSSN ? [] : [this.validation.fn.SSNPatternValidator(SSN_PATTERN)]],
        ssn_validation: [''],
        phone: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(PHONE_PATTERN)] }],
        email: ['', { updateOn: 'blur', validators: [Validators.pattern(EMAIL_PATTERN)] }],
        relationship: ['', { updateOn: 'blur', validators: [Validators.required] }],
        define_others: [''],
        address: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(255)] }],
        city: ['', { updateOn: 'blur', validators: [Validators.required, this.validation.noWhitespaceValidator()] }],
        apt_number: ['', { updateOn: 'blur', validators: [Validators.pattern(APARTMENT_NUMBER_PATTERN), Validators.maxLength(25)] }],
        country: ['', { updateOn: 'blur', validators: [Validators.required] }],
        state: [''],
        zipcode: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(10)] }],
        id: [''],
        beneficiary: ['1'],
        beneficiary_type_id: [''],
        type: [this.beneficiaryTypesConst.individual, { updateOn: 'blur', validators: [Validators.required] }],
      }),
      trusteeType: this.formBuilder.group({
        trust_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(NAME_PATTERN), Validators.minLength(2), Validators.maxLength(50)] }],
        trustee: ['', { updateOn: 'blur', validators: [Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
        successor_trustee: ['', { updateOn: 'blur', validators: [Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
        tax_id: ['', { updateOn: 'blur', validators: [Validators.pattern(EIN_NUMBER_PATTERN)] }],
        dated_date: ['', { validators: [this.validation.fn.isNotFutureDate(), this.validation.fn.dateRange(this.minDate)] }],
        trust_type_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
        email: ['', { updateOn: 'blur', validators: [Validators.pattern(EMAIL_PATTERN)] }],
        phone: ['', { updateOn: 'blur', validators: [Validators.pattern(PHONE_PATTERN)] }],
        address: ['', { updateOn: 'blur', validators: [Validators.minLength(3), Validators.maxLength(100)] }],
        city: [''],
        apt_number: ['', { updateOn: 'blur', validators: [Validators.pattern(APARTMENT_NUMBER_PATTERN), Validators.maxLength(25)] }],
        country: [''],
        state: ['', { updateOn: 'blur', validators: [this.selectedCountryName !== this.unitedKingdom ? Validators.required : Validators.nullValidator] }],
        zipcode: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(10)] }],
        id: [''],
        beneficiary: ['1'],
        beneficiary_type_id: [''],
        type: [this.beneficiaryTypesConst.trustee, { updateOn: 'blur', validators: [Validators.required] }],
      }),
    });
    this.updateValidationErrors();
  }

  /**
   * Initializes validation errors for various fields.
   *
   */
  public updateValidationErrors() {
    this.validation.setValidationErrors({
      phone: { pattern: 'Please enter a valid phone number' },
      email: { pattern: 'Please enter a valid email address.' },
      dob: { dateLow: null, dateHigh: null },
      dated_date: { dateLow: null, future_date: 'Dated Date must be a date before today.'},
      zipcode: { pattern: 'Please enter a valid zipcode' },
      ssn: { pattern: 'Invalid SSN', mask: null },
      tax_id: { pattern: `Invalid ${(this.isRevocable) ? 'SSN' : 'EIN'}`, mask: null }
    });

    // Define controls to trim whitespace
    const trimWhitespaceControls = [
      'first_name', 'middle_name', 'last_name', 'address', 
      'apt_number', 'city', 'zipcode', 'email',
      'trust_name', 'trustee', 'successor_trustee'
    ];

    // Subscribe to value changes
    trimWhitespaceControls.forEach(controlName => {
      this.beneficiaryForm.get(`individualType.${controlName}`)?.valueChanges.subscribe(() =>
        this.validation.trimWhitespace(this.beneficiaryForm.get(`individualType.${controlName}`))
      );

      this.beneficiaryForm.get(`trusteeType.${controlName}`)?.valueChanges.subscribe(() =>
        this.validation.trimWhitespace(this.beneficiaryForm.get(`trusteeType.${controlName}`))
      );
    });
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlsKey
   * @param form
   */
  public getErrors(controlsKey: string, form: string = this.getFormType): Array<string> {
    return this.validation.getErrors(controlsKey, (this.beneficiaryForm.controls[form] as FormGroup));
  }

  /**
   * after view is initialized
   */
  public ngAfterViewInit(): void {
    this.initMap(this.defaultCountry);
  }

  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }

  /**
   * get Age
   * @param {string}date
   * @return {number}
   */
  public getAge(date: string) {
    return this.commonHelper.getAge(date);
  }

  /**
   * change event listener for beneficiary type
   *
   *@param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitterBeneficiaryType(selectedOption: SelectOptionsInterface): void {
    if (selectedOption) {
      this.touchedBeneType = true;
      this.beneficiaryTypeId = selectedOption.additionalDetails.type;
      this.beneficiaryId = selectedOption.value;
      this.beneficiaryType = selectedOption.additionalDetails?.type;
      this.isCharityType = selectedOption.additionalDetails.is_charity;
      const selectedForm = this.getSelectedForm;
      selectedForm.reset({ phone: '' });
      this.resetForm(selectedOption.additionalDetails?.type);
      this.updateTaxIdValidity();
      if (this.isCharityType) {
        this.isRevocable = false;
        selectedForm.get('tax_id')?.removeValidators(Validators.pattern(SSN_PATTERN));
        selectedForm.get('tax_id')?.setValidators([Validators.pattern(EIN_NUMBER_PATTERN)]);
        selectedForm.get('tax_id')?.updateValueAndValidity();
      }
      if (selectedForm?.value?.id) return;
    } else {
      this.beneficiaryType = this.beneficiaryTypesConst.individual;
    }
    this.updateValidationErrors();
    this.initMap(this.defaultCountry);
  }

  /**
   * update validation for tax_id
   */
  private updateTaxIdValidity() {
    const selectedForm = this.getSelectedForm;
    if (this.isCharityType) {
      const requiredFields = ['dated_date', 'trust_type_id', 'email', 'address', 'city', 'country', 'state', 'zipcode',
      ];
      requiredFields.forEach((cur) => {
        selectedForm.get(cur).clearValidators();
        selectedForm.get(cur).updateValueAndValidity();
      });
    } else {
      selectedForm.get('tax_id')?.clearValidators();
      selectedForm.get('tax_id')?.setValidators([this.isRevocable ? this.validation.fn.SSNPatternValidator(SSN_PATTERN) : Validators.pattern(EIN_NUMBER_PATTERN)]);
      selectedForm.get('tax_id')?.updateValueAndValidity();
      this.addValidators();
    }
  }

  /**
   * add validators
   */
  private addValidators() {
    const selectedForm = this.getSelectedForm;
    const validatorMapping = {
      successor_trustee: [],
      dated_date: [this.validation.fn.isNotFutureDate(), this.validation.fn.dateRange(this.minDate)],
      trust_type_id: [Validators.required],
      email: [this.beneficiaryType === this.beneficiaryTypesConst.individual ? Validators.required : Validators.nullValidator, Validators.pattern(EMAIL_PATTERN)],
      address: [this.beneficiaryType === this.beneficiaryTypesConst.individual ? Validators.required : Validators.nullValidator, Validators.minLength(3), Validators.maxLength(100)],
      city: [this.beneficiaryType === this.beneficiaryTypesConst.individual ? Validators.required : Validators.nullValidator],
      apt_number: [Validators.pattern(APARTMENT_NUMBER_PATTERN), Validators.maxLength(25)],
      country: [this.beneficiaryType === this.beneficiaryTypesConst.individual ? Validators.required : Validators.nullValidator],
      state: [this.selectedCountryName !== this.unitedKingdom ? this.beneficiaryType === this.beneficiaryTypesConst.individual ? Validators.required : Validators.nullValidator : Validators.nullValidator],
      zipcode: [this.beneficiaryType === this.beneficiaryTypesConst.individual ? Validators.required : Validators.nullValidator, Validators.maxLength(10)],
    };

    // Iterating through the keys and applying validators to form controls
    for (const fieldName in validatorMapping) {
      if (validatorMapping.hasOwnProperty(fieldName)) {
        const validators = this.isCharityType ? [] : validatorMapping[fieldName];
        selectedForm.get(fieldName)?.setValidators(validators);
        selectedForm.get(fieldName)?.updateValueAndValidity();
      }
    }
  }

  /**
   * Setter for edit values in beneficiaryForm
   *
   * @param {any} data
   */
  @Input() set editData(data: any) {
    this.getPropertyData = data;
    console.log('EDIT', this.getPropertyData);

    if (data && data?.id) {
      this.resetForm();
      this.beneficiaryTypeId = data.beneficiary_type?.type;
      this.beneficiaryType = data.beneficiary_type?.type;
      const selectedForm = this.getSelectedForm;
      // const data = {...data}
      this.beneficiaryId = data.beneficiary_type?.id;
      this.isCharityType = data?.beneficiary_type?.is_charity;
      this.personId = data?.id;
      let tempPhoneNumber;
      if (data?.phone) {
        tempPhoneNumber = data?.phone;
        this.countryCode = { code: data?.flag_code, dialCode: data?.country_code };
      }
      if (this.beneficiaryType === 2) {
        this.isRevocable = data?.beneficiary_trustee_detail?.trust_type?.is_revocable;
        this.updateTaxIdValidity();
        this.updateValidationErrors();
        if (this.isCharityType) {
          this.isRevocable = false;
          selectedForm.get('tax_id')?.removeValidators(Validators.pattern(SSN_PATTERN));
          selectedForm.get('tax_id')?.setValidators([Validators.pattern(EIN_NUMBER_PATTERN)]);
          selectedForm.get('tax_id')?.updateValueAndValidity();
        }
        selectedForm?.patchValue({
          ...data,
          phone: tempPhoneNumber,
          city: data?.city?.id,
          country: data?.country?.id,
          state: data?.state?.id,
          successor_trustee: data?.beneficiary_trustee_detail?.successor_trustee,
          trust_name: data?.beneficiary_trustee_detail?.trust_name,
          trust_type_id: data?.beneficiary_trustee_detail?.trust_type_id,
          trustee: data?.beneficiary_trustee_detail?.trustee,
          tax_id: data?.beneficiary_trustee_detail?.tax_id,
        });
        setTimeout(() => {
          selectedForm?.patchValue({
            tax_id: data?.beneficiary_trustee_detail?.tax_id,
          });
          if (this.isRevocable) {
            if (selectedForm?.get('tax_id')?.value) { // check is SSN unique
              this.checkIsSsnUnique(selectedForm.get('tax_id').value, this.personId);
            }
          }
        }, 500);
        selectedForm?.patchValue({
          dated_date: this.commonHelper.formatDate(data?.beneficiary_trustee_detail?.dated_date, this.domainDateFormat),
        });
      } else {
        selectedForm?.patchValue({
          ...data,
          phone: tempPhoneNumber,
          city: data?.city?.id,
          country: data?.country?.id,
          state: data?.state?.id,
          relationship: data?.people_relation?.id,
          ssn_validation: data?.ssn,
        });
        if (data.dob) {
          selectedForm.patchValue({
            dob: this.commonHelper.formatDate(data.dob, this.domainDateFormat),
          });
        }
        if (selectedForm?.get('ssn')?.value && this.checkSSNMatch()) { // check is SSN unique
          this.checkIsSsnUnique(selectedForm.get('ssn').value, this.personId);
        }
      }
      this.removePacContainer();
      this.initMap(data?.country?.id);
    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet(): AbstractControl {
    return this.beneficiaryType === this.beneficiaryTypesConst.trustee ?
      this.beneficiaryForm['controls'].trusteeType['controls'] :
      this.beneficiaryForm['controls'].individualType['controls'];
  }

  /**
   * markAsBeneficiaryType
   */
  public markAsBeneficiaryType() {
    this.touchedBeneType = true;
  }

  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }

  /**
   * Get relationship list
   */
  public getRelationshipList(): void {
    this.commonService.getRelationshipList().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.relationshipList = this.commonHelper.convertToOptionsFormat(response.data, 'id', 'name',
        );
        this.commonHelper.placeAtPointOfArray(this.relationshipList, (e) => e.displayText.toLowerCase() === 'other');
        const selectedForm = this.getSelectedForm;
        if (this.beneficiaryType === 1) {
          selectedForm.patchValue({
            relationship: this.relationshipList[0].value,
          });
        }
      }
    });
  }

  /**
   * get beneficiary types
   */
  public getBeneficiaryTypes() {
    this.commonService.getBeneficiaryTypes().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.beneficiaryTypes = this.commonHelper.convertToOptionsFormat(response.data, 'id', 'name');
        this.beneficiaryTypes = this.commonHelper.placeAtPointOfArray(this.beneficiaryTypes, (e) => e.displayText.toLowerCase() == 'individual', 'start');
        this.beneficiaryTypes = this.commonHelper.placeAtPointOfArray(this.beneficiaryTypes, (e) => e.displayText.toLowerCase() == 'trust', 'end');
      }
    });
  }

  /**
   * Get trustee list
   */
  public getTrusteeTypes(): void {
    this.commonService.getTrusteeTypes().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.trusteeTypesList = this.commonHelper.convertToOptionsFormat(response.data, 'id', 'name',
        );
        const selectedForm = this.getSelectedForm;
        selectedForm?.patchValue({
          trust_type_id: this.trusteeTypesList[0].value,
          country: this.defaultCountry,
        });
      }
    });
  }

  private get getFormType(): string {
    return this.beneficiaryType === this.beneficiaryTypesConst.individual ? 'individualType' : 'trusteeType';
  }

  /**
   * add executor function
   */
  public onSubmit(): void {
    const formType = this.getFormType;
    const selectedForm = this.beneficiaryForm.value[formType];

    if (this.beneficiaryType === this.beneficiaryTypesConst.individual && selectedForm.dob) {
      selectedForm.dob = this.commonHelper.formatDate(selectedForm.dob, DATE_FORMAT);
    } else {
      selectedForm.dated_date = this.commonHelper.formatDate(selectedForm.dated_date, DATE_FORMAT);
    }
    console.log('beneficiaryT', this.beneficiaryType, 'type', this.beneficiaryTypesConst);

    const selectedFormControl = this.beneficiaryForm.controls[
      this.beneficiaryType === this.beneficiaryTypesConst.individual ?
        'individualType' :
        'trusteeType'
      ];
    console.log('type', selectedFormControl);
    const formTypeControl = (this.beneficiaryForm.get(this.beneficiaryType == 1 ? 'individualType' : 'trusteeType') as FormGroup);

    // If SSN value is valid, check uniqueness
    if (selectedForm?.ssn || selectedForm?.tax_id) {
      const beneficiaryDetail = this.getPropertyData?.beneficiary_trustee_detail;
      if (beneficiaryDetail) {
        if (beneficiaryDetail?.tax_id && beneficiaryDetail.tax_id !== selectedForm?.tax_id && !this.isUniqueSSN) {
          console.log('beneficiaryDetail?.tax_id && beneficiaryDetail.tax_id !== selectedForm?.tax_id && !this.isUniqueSSN', beneficiaryDetail?.tax_id && beneficiaryDetail.tax_id !== selectedForm?.tax_id && !this.isUniqueSSN);
          return;
        }
      } else if (!this.isUniqueSSN) {
        console.log('!this.isUniqueSSN', !this.isUniqueSSN);
        return;
      }
    } else if (selectedForm?.ssn_validation || this.validatingSSN === 1) {
      console.log('selectedForm?.ssn_validation || this.validatingSSN === 1', selectedForm?.ssn_validation || this.validatingSSN === 1);
      return;
    }

    if (this.getPropertyData.beneficiary_type && this.getPropertyData?.beneficiary_type?.is_charity === 1) {
      if (selectedForm.type == 2) {
        selectedFormControl.get('trust_type_id').setValue(null);
        selectedFormControl.get('city').setValue(null);
        selectedFormControl.get('state').setValue(null);
        selectedFormControl.get('state').clearValidators();
      }
    }

    if (selectedFormControl.invalid) {
      this.commonHelper.scrollToFirstInvalidControl(formTypeControl);
      selectedFormControl.markAllAsTouched();
      this.updateValidationErrors();
      console.log('selectedFormControl.invalid', selectedFormControl.invalid);
      return;
    }
    console.log('accepted form');
    this.submitLoader = true;
    const isConsumer: boolean = this.userType === 'Consumer';
    const errorFn = (exception: any) => {
      this.submitLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    };
    const payLoad = {
      ...selectedForm,
      country_code: this.countryCode.dialCode,
      flag_code: this.countryCode.code,
      roletype: this.userType,
      // To differentiate charity and trust this.isCharityType is checked and being sent to the api.If this.isCharityType resolves to true ,value 3 is sent
      beneficiary_type_id: this.isCharityType ? 3 : this.beneficiaryTypeId,
      type: this.isCharityType ? 3 : this.beneficiaryTypeId,
      beneficiary_type: this.beneficiaryId,
      is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
    };
    if (!isConsumer) {
      payLoad['request_id'] = this.requestId;
    }
    payLoad?.id && delete payLoad.beneficiary;

    payLoad.state = this.selectedCountryName == this.unitedKingdom ? null : payLoad.state;
    this.commonService.getCityDetail(payLoad.country, payLoad.state, payLoad.city).subscribe((cResponse: APIResponseModel) => {
      if (cResponse?.status) {
        payLoad.city = cResponse?.data?.id;
        this.peopleService.storePeopleDetails(payLoad).subscribe((response: APIResponseModel) => {
            this.submitLoader = false;
            if (response.status) {
              if (isConsumer) {
                this.commonHelper.updateLocalstorageRequestStage(response.data);
              }
              this.toggleModalEmitter.emit({
                ...response.data,
                listStatus: selectedForm.id ? LIST_UPDATE : LIST_NEW,
              });
              if (selectedForm.id) {
                this.commonHelper.toastrUpdateSuccess();
              } else {
                this.commonHelper.toastrInsertSuccess();
              }
              this.peopleService.refreshDetailList(response.data);
              this.closeModel();
            }
          }, errorFn,
        );
      }
    }, errorFn);
  }

  /**
   * Auto hypen SSN keyup
   */
  public autoHypenSSN(): void {
    this.beneficiaryForm.patchValue({
      ssn: this.commonHelper.autoHypenSSN(this.beneficiaryForm.value.ssn),
    });
  }

  /**
   * Initialize google map api by country
   * @param {any} countryId
   */
  public initMap(countryId: any): void {
    if (this.searchElementRef?.nativeElement) {
      const selectedForm = this.getSelectedForm;
      this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
        next: (r) => {
          this.ngZone.run(() => {
            selectedForm.patchValue(r);
          });
        },
      });
    }
  }

  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    const selectedForm = this.getSelectedForm;
    if (search.value === '') {
      selectedForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }

  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry) return;
    const selectedForm = this.getSelectedForm;
    selectedForm['controls']?.state.reset();
    selectedForm.patchValue({
      country: selectedCountry.value,
    });
    this.removePacContainer();
    this.initMap(selectedCountry.value);
  }

  /**
   * remove pac container
   *
   */
  public removePacContainer() {
    this.addressHelper.removeAutocompletePacContainer();
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    const selectedForm = this.getSelectedForm;
    selectedForm['controls']?.city?.reset();
    selectedForm.patchValue({
      state: selectedState.value,
    });
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    const selectedForm = this.getSelectedForm;
    selectedForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = selectedForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([
        Validators.required,
        Validators.pattern(new RegExp(selectedCity.additionalDetails[0].postal_code_regex)),
      ]);
      zipCodeRef.updateValueAndValidity();
    }
    // this loader is set false here because of the delay to set country state city
    this.preDefinedLoader = false;
  }

  /**
   * Confirms delete
   */
  public confirmDelete(): void {
    const selectedForm = this.getSelectedForm;
    this.toggleModalEmitter.emit({
      id: selectedForm.value.id,
      roletype: this.userType,
      listStatus: LIST_DELETE,
    });
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.modalService.close('add-beneficiary-modal');
    this.resetForm();
    this.toggleModalEmitter.emit(null);
  }

  /**
   * Option change emitter relationship / trust type
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitterRelationshipTrust(selectedOption: SelectOptionsInterface): void {
    const selectedForm = this.getSelectedForm;
    // this method gets called everytime whenever there is a click outside the dropdown <code>closeDropDown()</code> in Select Search Component
    //  which makes tax_id value to a empty string always .So only if the option gets changed (revocable/irrevocable) tax_id value will be cleared
    if (selectedOption.value !== this.selectedTrustType?.value) {
      selectedForm.get('tax_id')?.patchValue('');
    }
    this.selectedTrustType = selectedOption;
    this.isRevocable = selectedOption.additionalDetails?.is_revocable;
    this.updateTaxIdValidity();
    this.updateValidationErrors();
    if (selectedOption.value) {
      if (this.beneficiaryType === this.beneficiaryTypesConst.individual) {
        selectedForm.patchValue({
          relationship: selectedOption.value,
        });
      } else {
        selectedForm.patchValue({
          trust_type_id: selectedOption.value,
        });
      }
      if (this.useOther) {
        this.formGet['define_others'].addValidators([Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.pattern(NAME_PATTERN)]);
        this.formGet['define_others'].updateValueAndValidity();
      } else {
        this.formGet['define_others']?.clearValidators();
        this.formGet['define_others']?.updateValueAndValidity();
      }
      if (this.toggleEmitterListenerIndividual) {
        this.beneficiaryForm['controls']['individualType'].get('relationship').markAsUntouched();
        this.toggleEmitterListenerIndividual = false;
      }
      if (this.toggleEmitterListenerTrust) {
        this.beneficiaryForm['controls']['trusteeType']?.get('trust_type_id')?.markAsUntouched();
        this.toggleEmitterListenerTrust = false;
      }
    } else {
      if (this.toggleEmitterListenerIndividual) {
        this.beneficiaryForm['controls']['individualType'].get('relationship').markAsTouched();
        this.toggleEmitterListenerIndividual = false;
      }
      if (this.toggleEmitterListenerTrust) {
        this.beneficiaryForm['controls']['trusteeType']?.get('trust_type_id').markAsTouched();
        this.toggleEmitterListenerTrust = false;
      }
    }
  }

  /**
   * Whether the 'Other' field is being used.
   */
  get useOther(): boolean {
    if (this.formGet) {
      return this.formGet['relationship']?.value ===

        this.relationshipList.find((e) => e.displayText.toLowerCase() === 'other')?.value;
    }
    return false;
  }

  /**
   * Reset form
   * @param {string}type
   */
  public resetForm(type?: number): void {
    if (!this.trusteeTypesList?.length) return;
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    // this.inputComponents.forEach((e) => e.clearSelectedOption());

    // Determine ID to use, if any.
    let id: string;
    if (type == this.beneficiaryTypesConst.trustee) {
      id = this.beneficiaryForm['controls'].individualType.value.id;
    } else if (type == this.beneficiaryTypesConst.individual) {
      id = this.beneficiaryForm['controls'].trusteeType.value.id;
    }

    const commonResetValues = {
      beneficiary: '1',
      country: this.defaultCountry,
      phone: '',
    };
    switch (type) {
      case this.beneficiaryTypesConst.individual:
        this.beneficiaryForm['controls'].individualType.reset({
          ...(type == this.beneficiaryTypesConst.individual ? { id: id } : {}),
          type: this.beneficiaryTypesConst.individual,
          ...commonResetValues,
        });
        break;

      case this.beneficiaryTypesConst.trustee:
        this.beneficiaryForm['controls'].trusteeType.reset({
          ...(type == this.beneficiaryTypesConst.trustee ? { id: id } : {}),
          type: this.beneficiaryTypesConst.trustee,
          trust_type_id: this.trusteeTypesList[0]?.value,
          ...commonResetValues,
        });
        break;

      default:
        this.beneficiaryId = '';
        this.beneficiaryType = null;
        this.beneficiaryTypeId = null;
        this.touchedBeneType = false;
        this.beneficiaryForm.reset({
          individualType: {
            type: this.beneficiaryTypesConst.individual,
            ...commonResetValues,
          },
          trusteeType: {
            type: this.beneficiaryTypesConst.trustee,
            trust_type_id: this.trusteeTypesList[0]?.value,
            ...commonResetValues,
          },
        });
        break;
    }

    this.elementRefSet = false;
    this.personId = this.userDetails?.request?.id;
  }

  /**
   * Detect change on phone number component
   *
   * @param event
   */
  public detectChangePhoneNumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }

  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const selectedForm = this.beneficiaryForm['controls']['individualType'];
    const zipCodeRef = selectedForm.get('zipcode');
    const stateId = selectedForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        if (response.data.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(zipCodeRegex))]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        } else {
          zipCodeRef.setValidators([Validators.required]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }

  /**
   * Determines if `ssn` & `ssn_validation` are equal. Nullish values are treated as an empty string.
   * @return{boolean}
   */
  public checkSSNMatch(): boolean {
    const selectedForm = this.beneficiaryForm['controls']['individualType'];
    const ssn1 = selectedForm.get('ssn').value ?? '';
    const ssn2 = selectedForm.get('ssn_validation').value ?? '';
    return ssn1 === ssn2;
  }

  /**
   * getter to check whether the form is getting updated
   */
  get isUpdate(): boolean {
    return ((this.beneficiaryType === this.beneficiaryTypesConst.trustee &&
        this.beneficiaryForm['controls'].trusteeType.value.id) ||
      (this.beneficiaryType === this.beneficiaryTypesConst.individual &&
        this.beneficiaryForm['controls'].individualType.value.id));
  }

  /**
   * Get text for the 'update' button. Dynamically determines if verbiage should be 'add' or 'update'
   */
  get updateText(): string {
    return `${this.isUpdate ? 'Update' : 'Add'} Beneficiary`;
  }

  /**
   * typing / onchange input field
   * changed as untouched field
   * not show validation error
   * @param {any} data
   * @param {string} key
   */
  public onChange(data: any, key: string) {
    const selectedForm = this.beneficiaryForm['controls']['individualType'];
    if (data?.type == ELEMENT_EVENTS.INPUT) {
      selectedForm.get(key).markAsUntouched();
      this.validatingSSN = ssnValidationProcess.initial;
    }
  }

  /**
   * focusout of input field
   * changed as touched field
   * check and show validation error
   * @param {any} data
   * @param {string} key
   * @param {string} formType
   */
  public onFocusout(data: any, key: string, formType: string) {
    const selectedForm = this.beneficiaryForm['controls'][formType];
    if (data?.type == ELEMENT_EVENTS.FOCUSOUT) {
      selectedForm.get(key).markAllAsTouched();
    }
    if (key == ('ssn' || 'ssn_validation')) {
      this.formGet['ssn'].markAsTouched();
      const isSSNValid = selectedForm.get('ssn').value ? selectedForm.get('ssn').status == STATUS_CHECK.VALID : false;
      if (isSSNValid && this.checkSSNMatch()) { // if SSN status valid and ssn and confirmation ssn are same
        this.checkIsSsnUnique(selectedForm.get('ssn').value, this.personId);
      }
    } else {
      selectedForm.get(key).markAsTouched();
      if (selectedForm.get(key).valid) {
        this.checkIsSsnUnique(selectedForm.get(key).value, this.personId);
      }
    }
  }

  /**
   * check ssn is unique
   * @param {string} data
   * @param{string} id
   */
  public checkIsSsnUnique(data: string, id: string) {
    this.validatingSSN = ssnValidationProcess.start_validation;
    this.commonService.getIsSSNUnique(data, id, this.commonHelper.isShowSSNToast()).subscribe({
      next: (r) => {
        this.isUniqueSSN = r;
        this.validatingSSN = ssnValidationProcess.end_validation;
      },
      error: () => this.validatingSSN = ssnValidationProcess.end_validation,
    });
  }

  /**
   * getter for indivdual form group's state
   */
  get individualState() {
    return this.beneficiaryForm['controls']['individualType'].get('state');
  }

  /**
   * getter for trust form group's state
   */
  get trusteeTypeState() {
    return this.beneficiaryForm['controls']['trusteeType']?.get('state');
  }

  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.beneficiaryForm['controls']['individualType'].get('country').valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ?
        this.individualState.addValidators([Validators.required]) : this.individualState.clearValidators();
    });
    this.beneficiaryForm['controls']['trusteeType'].get('country').valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ?
        this.trusteeTypeState.addValidators([Validators.required]) : this.trusteeTypeState.clearValidators();
    });
  }

  /**
   * toggle emitter
   * @param {boolean} data
   * @param{string} formKey
   */
  public toggleEmiter(data: boolean, formKey: string) {
    if (data) {
      if (formKey === 'relationship') {
        this.toggleEmitterListenerIndividual = true; // toggle listener
      } else {
        this.toggleEmitterListenerTrust = true; // toggle listener
      }
    }
  }

  /**
   * getter for selected form
   */
  get getSelectedForm(): FormGroup {
    let form;
    switch (this.beneficiaryType) {
      case this.beneficiaryTypesConst.individual:
        form = this.beneficiaryForm['controls']['individualType'];
        break;
      case this.beneficiaryTypesConst.trustee:
        form = this.beneficiaryForm['controls']['trusteeType'];
        break;
    }
    return form as FormGroup;
  }

  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }
}


/**
 * enum for ssn validation processing
 */
enum ssnValidationProcess {
  initial,
  start_validation,
  end_validation
}
