import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { FileValidationOptionsInterface, ICountryCode, SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { AgentsService } from 'src/app/professional-dashboard/services/agents.service';
import { ManagerService } from 'src/app/professional-dashboard/services/manager.service';
import { ProfessionalManagementService } from 'src/app/professional-dashboard/services/professional-management.service';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { CommonFormControlService } from 'src/app/services/common-form-control.service';
import { CommonService } from 'src/app/services/common.service';
import { EMAIL_PATTERN, NAME_PATTERN, PHONE_PATTERN, UNITED_KINGDOM, USER_TYPES } from 'src/constants/application.const';
import { CLEAR_SELECTED_FILE, SET_IMAGE } from 'src/constants/digitalFile.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../../../helper/validation.helper';
import { LocalStorageService } from '../../../../../services/local-storage.service';


@Component({
  selector: 'app-edit-professional-agent',
  templateUrl: './edit-professional-agent.component.html',
  styleUrls: ['./edit-professional-agent.component.css'],
})
/**
 * edit professional agent
 */
export class EditProfessionalAgentComponent implements OnInit {
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;
  public isNewAgent: boolean;
  public agentForm: FormGroup;
  public countryCode: ICountryCode;
  public preDefinedLoader: boolean;
  public editData: any;
  public loginLoader: boolean;
  public asterisk: any;
  public activateFileUploadLoader: boolean;
  public filePercentage: number;
  public currentFile: any;
  public fileValidationOptions: FileValidationOptionsInterface;
  public imageFormatFiltering: Array<string>;
  public downloadLink: string;
  public downloadFileName: string;
  public proof: any;
  public profilePicture: any;
  public hideDeleteIcon: boolean;
  public willFilesArray: Array<any>;
  public advisorTypes: Array<any>;
  public managersList: Array<any>;
  public companyUserData: Object;
  private editUserID: string;
  public userType: string;
  public defaultCountry: number;
  public selectedCountry: number;
  public selectedCountryName: string = '';
  public unitedKingdom: string = UNITED_KINGDOM;
  public roleType: string;
  public roleTypeId: string;
  public advisorTerm: string;
  public isProfilePictureImage: boolean;
  public isProofImage: boolean;
  public hasAttemptedProfilePic: boolean = false;
  public hasAttemptedProof: boolean = false;
  public editAssignProfessionals: Array<any>;
  public professionalsList: any;
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;


  /**
   * constructor
   */
  constructor(private form: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private agentService: AgentsService,
    private managerService: ManagerService,
    private toastr: ToastrService,
    private commonHelper: CommonHelper,
    private commonService: CommonService,
    private professionalUserService: ProfessionalUserService,
    private addressHelper: AddressHelper,
    private slugInterceptorService: SlugInterceptorService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
    private validation: ValidationHelper,
    private commonFormControlService: CommonFormControlService,
    private proManagementService: ProfessionalManagementService
  ) {
  }


  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.commonService.fileHanlderObservableEvents({ type: CLEAR_SELECTED_FILE });
    this.commonService.imageHandlerObservableEvents({ type: CLEAR_SELECTED_FILE });
    this.route.parent.data.subscribe((data) => { // data from route if professional
      this.advisorTerm = data['isProfessional'] ? 'professional' : this.professionalUserService.getAdvisorTerm();
    })
    this.companyUserData = this.localStorageService.getUserData(USER_TYPES.pro).user;
    this.editUserID = this.route.snapshot.params['id'];
    this.isNewAgent = !this.editUserID;
    this.selectedCountry = environment.APP_DOMAIN;
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    this.loginLoader = false;
    this.preDefinedLoader = false;
    this.asterisk = faAsterisk;
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    this.userType = this.localStorageService.getDataByKey('role');
    this.fileValidationOptions = {
      size: 100000,
      fileFormat: [
        'jpeg',
        'jpg',
        'png',
        'doc',
        'docx',
        'pdf',
      ],
    };
    this.imageFormatFiltering = ['jpeg', 'jpg', 'png'];
    this.commonService.fileHanlderObservableEvents({
      type: CLEAR_SELECTED_FILE,
    });

    // add agent form
    this.agentForm = this.form.group({
      first_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim] }],
      middle_name: ['', { updateOn: 'blur', validators: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim] }],
      last_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim] }],
      title: [
        '',
        {
          updateOn: 'blur', validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
          ],
        },
      ],
      phone: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(PHONE_PATTERN)] }],
      email: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(EMAIL_PATTERN)] }],
      street_address: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(255), this.validation.fn.trim] }],
      city: ['', { updateOn: 'blur', validators: [Validators.required, this.validation.noWhitespaceValidator()] }],
      country: ['', { updateOn: 'blur', validators: [Validators.required] }],
      state: [''],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(10)] }],
      profile_picture: ['', { updateOn: 'blur', validators: [this.profilePicRequired.bind(this)] }],
      // upload_proof: ['', { updateOn: 'blur', validators: [this.proofrequired.bind(this)] }],
      manager_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
      assign_professionals: [[]],
      get_assigned_professionals:[[]], //duplicate assign_professionals, value patched only on edit
      professional_type: [''],
      is_image_deleted: ['0'],
      // is_proof_deleted: ['0'],
    });
    this.validation.setValidationErrors({
      profile_picture: { required: 'Profile picture is required' },
      // upload_proof: { required: 'Proof is required' },
      phone: { pattern: 'Please enter a valid phone number' },
      email: { pattern: 'Please enter a valid email address.' },
      zipcode: { pattern: 'Please enter a valid zipcode' },
      city: { whitSpaceValue: 'Please enter valid character'}
    });
    this.agentForm.patchValue({
      country: this.defaultCountry,
    });
    // If search element not loaded initially
    setTimeout(() => {
      this.initMap(this.defaultCountry);
    }, 0);

    // Set validator if domain country is not UK
    this.isEnableState();
    if (this.agentForm) {
      this.getAdvisorTypes();
      this.getAgentData();
      this.getManagers();
    }
    // types of advisors
    this.roleType = this.localStorageService.getUserData(USER_TYPES.pro).user.professional_type_name;
  }
  /**
   * Profile pic whether required
   * @returns pic required 
   */
  private profilePicRequired(): ValidationErrors {
    return this.hasAttemptedProfilePic && !this.formGet['profile_picture'].value ? { required: true } : null
  }

  /**
   * Determines whether click uploader on
   */
  onClickUploader() {
    this.hasAttemptedProfilePic = (!!this.agentForm.controls['profile_picture'].value || !!this.hasAttemptedProfilePic);
    // this.hasAttemptedProof = (!!this.agentForm.controls['upload_proof'].value || !!this.hasAttemptedProof);
    this.agentForm.get('profile_picture')?.updateValueAndValidity();
    this.agentForm.get('upload_proof')?.updateValueAndValidity();
  }
  /**
   * Mark upload field as touched
   * @param [controlName] 
   */
  markUploadFieldAsTouched(controlName?: string) {
    switch (controlName) {
      case 'profile_picture':
        this.hasAttemptedProfilePic = true;
        break;
      case 'upload_proof':
        this.hasAttemptedProof = true;
        break;
    }
    this.hasAttemptedProfilePic = true;
  }
  ngAfterViewChecked(): void {
    this.onClickUploader();
  }

  /**
   * get all managers
   */
  public getManagers() {
    this.managerService.getManagers().subscribe({
      next: (response) => {
        // Add self to list if 'Professional' to serve as manager.
        if (this.userType === 'Professional') {
          const fullName = `${this.companyUserData['first_name']} ${this.companyUserData['last_name'] ? this.companyUserData['last_name'] : ''}`.trim();
          this.companyUserData['full_name'] = this.companyUserData['full_name'] ?? fullName;
          response.data.push(this.companyUserData);
        }
        this.managersList = this.commonHelper.convertToOptionsFormat(
          response.data,
          'id',
          'full_name',
        );
        // this.getProfessionals();
      },
      error: (exception) => this.commonHelper.httpResponseHandler(exception?.error),
    });
  }


  /**
   * Fetches professionals under a chosen manager and updates the professionals list.
   * 
   * Also it filters out the manager from the list to ensure the manager is not included.
   * 
   * @param {string} chosenManagerId - The ID of the chosen manager.
   */
  public getProfessionals(chosenManagerId: string) {
    if (chosenManagerId) {
      this.proManagementService.getProfessionalsByManagerId(chosenManagerId).subscribe({
        next: (response:APIResponseModel) => {
          this.professionalsList = this.commonHelper.convertToOptionsFormat(
            response.data,
            'id',
            'full_name',
          );
        }
      })
    }
  }

  /**
   * Selected professional
   * @param data 
   */
  public selectedProfessional(data) {
    const ids = data.map(item => item.id);
    this.agentForm.patchValue({
      'assign_professionals': ids
    })
  }


  /**
   * patch data
   */
  public getAgentData() {
    if (!this.editUserID) {
      return;
    }
    this.preDefinedLoader = true;
    this.agentService.viewAgent(this.editUserID).subscribe({
      next: (response: APIResponseModel) => {
        this.editData = response.data;
        this.agentForm.patchValue({
          ...this.editData,
          country: this.defaultCountry,
          professional_type: this.editData.professional_type?.id,
          manager_id: this.editData?.manager_id,
          title: this.editData?.manager_term,
          assign_professionals: String(this.editData?.assign_professionals).split(','),
          get_assigned_professionals: String(this.editData?.assign_professionals).split(',')
        });
        if (this.editData?.state?.id) {
          this.agentForm.patchValue({
            city: this.editData?.city?.id,
            country: this.editData?.country?.id,
            state: this.editData?.state?.id,
          });
        }
        this.getProfessionals(this.editData?.manager_id);
        this.removePacContainer();
        this.initMap(this.editData?.country?.id);
        this.countryCode = { code: this.editData?.flag_code, dialCode: this.editData?.country_code };
        this.agentForm.patchValue({
          profile_picture: '',
          // upload_proof: '',
        });
        this.imageFormatFiltering.indexOf(this.editData.profile_picture.slice(this.editData.profile_picture.lastIndexOf('.') + 1).toLowerCase()) !== -1 ? this.isProfilePictureImage = true : this.isProfilePictureImage = false;


        if (this.editData.profile_picture) {
          this.commonService.fileHanlderObservableEvents({
            type: SET_IMAGE,
            data: this.editData?.profile_picture,
            id: this.editData?.id,
            isImageFile: this.isProfilePictureImage,
          });
        }
        this.updateFormControls('add');
        this.cd.detectChanges();
        this.preDefinedLoader = false;
      },
      error: (exception) => {
        this.preDefinedLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      },
    });
  }


  /**
   * update form controls
   * @param{string}type
   */
  public updateFormControls(type: string) {
    this.agentForm.get('profile_picture').clearValidators();
    // this.agentForm.get('upload_proof').clearValidators();
    this.agentForm.get('profile_picture').updateValueAndValidity();
    // this.agentForm.get('upload_proof').updateValueAndValidity();
  }

  /**
   * Detect change on phone number component
   *
   * @param {any} inputObject
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }


  /**
   * get advisor types
   */
  public getAdvisorTypes() {
    this.agentService.getAdvisorTypes(this.userType).subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          const advisorTypeList = response?.data;
          this.advisorTypes = this.commonHelper.convertToOptionsFormat(advisorTypeList, 'id', 'name');
          const indexNumber = advisorTypeList?.findIndex((el) => el?.name === this.roleType);
          if (indexNumber !== -1) {
            this.roleTypeId = advisorTypeList[indexNumber].id;
          }
        }
      },
    });
  }


  /**
   * form object getter for validation and showing errors in html
   */
  public get formGet() {
    return this.agentForm?.controls;
  }


  /**
   * Upload file
   *
   * @param {any} file
   */
  public uploadProofImage(file: any): void {
    console.log('file', file);
    this.proof = file;
    this.agentForm.patchValue({
      upload_proof: file,
    });
  }


  /**
   * Upload profile pic
   *
   * @param {any} file
   */
  public uploadProfilePicture(file: any): void {
    this.profilePicture = file;
    this.agentForm.patchValue({
      profile_picture: file,
    });
  }


  /**
   * delete profile picture
   *
   * @param {any} isDeleted
   */
  public deleteImage(isDeleted: any): void {
    this.agentForm.patchValue({
      is_image_deleted: isDeleted,
    });
    this.agentForm.get('profile_picture').addValidators([Validators.required]);
    this.agentForm.get('profile_picture').updateValueAndValidity();
  }


  /**
   * delete proof
   *
   * @param {any} isDeleted
   */
  public deleteProof(isDeleted: any): void {
    this.agentForm.patchValue({
      is_proof_deleted: isDeleted,
    });
    this.agentForm.get('upload_proof').addValidators([Validators.required]);
    this.agentForm.get('upload_proof').updateValueAndValidity();
  }


  /**
   * Returns an Object representing the payload for adding/editing this advisor.
   * @private
   */
  private getPayload(): Observable<FormData> {
    return new Observable<FormData>((observer) => {
      const payload = {
        ...this.agentForm.value,
        manager_term: this.agentForm.value['title'],
        country_code: this.countryCode.dialCode,
        flag_code: this.countryCode.code,
        is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
        state: this.selectedCountryName == this.unitedKingdom ? null : this.agentForm.value.state,
      };
      if (this.isNewAgent) {
        payload.professional_type = this.roleTypeId;
      } else {
        payload.id = this.editUserID;
        //Fetch the list of professionals who have been removed from their assigned positions.
        const originalProfessionals = payload.get_assigned_professionals;
        const alteredProfessionals = payload.assign_professionals;
        payload['remove_professionals'] = originalProfessionals.filter((data) => {return alteredProfessionals.findIndex((originalData) => originalData === data) === -1})
      }
      delete payload.title ;delete payload.get_assigned_professionals;
      payload['type'] = payload['type'] || (payload.manager_id === this.companyUserData['id']) ? 'manager' : 'advisor'
      this.commonService.getCityDetail(payload['country'], payload['state'], payload['city']).subscribe({
        next: (cResponse: APIResponseModel) => {
          if (cResponse?.status) {
            payload['city'] = cResponse?.data?.id;
            let formData = new FormData();
            Object.keys(payload).forEach((key) => {
              if (payload[key] == undefined) return;
              formData.append(key, payload[key]);
            });
            observer.next(formData);
          }
        },
        error: (e) => observer.error(e),
        complete: () => observer.complete(),
      });
    });
  }


  /**
   * submit form
   */
  public onSubmit() {
    this.commonFormControlService.markFormGroupTouched(this.agentForm);
    this.markUploadFieldAsTouched('profile_picture');
    // this.markUploadFieldAsTouched('upload_proof');
    if (this.agentForm.invalid) {
      return;
    }

    this.loginLoader = true;
    const observer = {
      next: (r) => {
        this.toastr.success(r.message);
        this.agentForm.reset();
        this.commonService.fileHanlderObservableEvents({ type: CLEAR_SELECTED_FILE });
        this.commonService.imageHandlerObservableEvents({ type: CLEAR_SELECTED_FILE });
        void this.slugInterceptorService.navigate(['professional-management'], null, true);
      },
      error: (e) => {
        this.loginLoader = false;
        this.commonHelper.httpResponseHandler(e?.error);
      },
    };
    this.getPayload().subscribe({
      next: (payload) => {
        if (this.isNewAgent) {
          this.agentService.addAgent(payload).subscribe(observer);
        } else {
          this.agentService.editAgent(payload).subscribe(observer);
        }
      },
      error: observer.error,
    });
  }


  /**
   * Initialize google map api by country
   * @param {any} countryId
   */
  public initMap(countryId: any): void {
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: (r) => {
        this.ngZone.run(() => {
          this.agentForm.patchValue({ ...r, street_address: r.address });
        });
      },
      error: (e) => console.error(e),
    });
  }


  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.agentForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }


  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    //
    if (!selectedCountry || this.preDefinedLoader) return;
    this.agentForm.controls.state.reset();
    this.agentForm.patchValue({
      country: selectedCountry.value,
    });
    this.removePacContainer();
    this.initMap(selectedCountry.value);
  }


  /**
   * remove pac container
   *
   */
  public removePacContainer() {
    this.addressHelper.removeAutocompletePacContainer();
  }


  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.agentForm.controls.city.reset();
    this.agentForm.patchValue({
      state: selectedState.value,
    });
  }


  /**
   * listen for options changes state
   *
   * @param {string} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.agentForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.agentForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(selectedCity?.additionalDetails[0]?.postal_code_regex))]);
      zipCodeRef.updateValueAndValidity({ onlySelf: true });
    }
    // this loader is set false here because of the delay to set country state city
    this.preDefinedLoader = false;
  }


  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }


  /**
   * Remove uploaded file
   */
  public removeUploadedFile(): void {
    this.agentForm.patchValue({
      upload_proof: '',
    });
  }


  /**
   * get state
   */
  get state() {
    return this.agentForm.get('state');
  }


  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.agentForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ?
        this.state.addValidators([Validators.required]) : this.state.clearValidators();
      this.state.updateValueAndValidity();
    });
  }


  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, true);
  }

  /**
 * See {@link ValidationHelper.getErrors}
 * @param controlsKey
 */
  public getErrors(controlsKey: string, checkTouched?): Array<string> {
    return this.validation.getErrors(controlsKey, this.agentForm, checkTouched);
  }


  /**
   * change event listener for managers list
   *
   * @param {any} data
   */
  public optionChangeEmitterManagerType(data: any): void {
    if (!data) return;
    this.agentForm.patchValue({
      manager_id: data.value,
    });
    this.getProfessionals(data?.value);
  }

  /**
   * change event listener for managers list
   *
   * @param {any} data
   */
  public optionChangeEmitterAssignedPro(data: any): void {
    if (!data) return;
    console.log(data);
    // this.agentForm.patchValue({
    //   manager_id: data.value,
    // });
  }


  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.agentForm.get('zipcode');
    const stateId = this.agentForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        const validators = [Validators.required];
        if (response.data.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          validators.push(Validators.pattern(new RegExp(zipCodeRegex)));
        }
        zipCodeRef.setValidators(validators);
        zipCodeRef.updateValueAndValidity({ onlySelf: true });
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }


  get getZipcodePlaceholder(): string {
    switch (this.selectedCountry) {
      case 1:
        return 'Eg. 12345';
      case 2:
        return 'Eg. A1A 1A1';
      case 3:
        return 'Eg. 1234';
      default:
        return 'Enter Zipcode';
    }
  }

  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }
}
