import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shimmer-loading',
  templateUrl: './shimmer-loading.component.html',
})
/**
 * Shimmer component
 */
export class ShimmerLoadingComponent {
  @Input() loading:boolean = true;
  @Input() shape: "circle" | "square" | "rect";
  @Input() width:string;
  @Input() height:string;
}
