import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FinalizedAmountInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { SocketService } from 'src/app/services/socket.service';
import { ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN, CREDIT_CARD_MASK, ConsumerPaymentMethod, LOCAL_STORAGE_UPDATE_STATUS, LoginType, NUMBERS_ONLY_PATTERN, PAYMENT_STATUS, USER_TYPES, YEAR_MASK, ZIPCODE_PATTERN } from '../../../constants/application.const';
import { CommonHelper } from '../../helper/common.helper';
import { LoadStateHelper } from '../../helper/load-state.helper';
import { SlugInterceptorService } from '../../helper/slug-interceptor.service';
import { ValidationHelper } from '../../helper/validation.helper';
import { LocalStorageService } from '../../services/local-storage.service';
import { MessageModalDataInterface } from '../../sharedComponent/message-modal/message-modal.component';
import { CardService } from '../consumer-payment-options/card-details/card-details.service';
import { ConsumerSubscriptionService } from '../consumer-payment-section/consumer-subscription.service';
import { CheckoutService } from './checkout.service';
import { PaymentOption } from './payment-option.model';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit {
  readonly CREDIT_CARD_MASK = CREDIT_CARD_MASK;
  readonly YEAR_MASK = YEAR_MASK;
  public preDefinedLoader: boolean;
  public domainDateFormat: any;
  public appliedPromoCode: string;
  public promoCodeValidity: string;
  isDVAgreementChecked: boolean = false;
  isPaymentClicked: boolean = false;
  isConsumer: boolean;
  isCustodian: boolean;
  isProfessional: boolean;
  userData: any;
  selectedUserRequestId: string;
  promoCodeApplied: boolean = false;
  promoCode: string;
  selectedCardDetails: any;
  cards: any;
  paymentOptions: PaymentOption[];
  userType: string;
  vaultTransferAmount: string;
  isTransferDigitalWallet = false;
  toggleSectionBody: boolean;
  transferVaultScreen = false;
  paymentStatus: number;
  cardToken: string;
  loader: LoadStateHelper;
  total: number;
  isLoading: boolean;
  paymentForm: FormGroup;
  userRequestId: string;
  paymentMode: ConsumerPaymentMethod;
  messageData: MessageModalDataInterface = {
    message: [`We see you have typed in a code but have not applied it. Please press <i>Apply</i> or clear the field`],
    buttons: [{ content: 'Okay!', emitValue: true }],
  };
  invoiceNumber: string;
  private destroyRedirect = true;
  private subscriptionDetails: any;
  private monthlyPackageDetails: any;
  private yearlyPackageDetails: any;
  private vaultRegistrationAmount: string;
  private activePackage: any;
  private dlbId: string;
  private transferAmountPaidDate: string;
  private paymentRefundAt: string;
  private refundStatus: boolean;
  private appliedPromoId: any;
  private paymentTypes: number[] = [];
  private adjustedValue: FinalizedAmountInterface = { download_price: 0, transfer_price: 0, subscription_price: 0, pay_vault_registration_price: 0 };

  constructor(
    private slugInterceptorService: SlugInterceptorService,
    private toastr: ToastrService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private subscriptionService: ConsumerSubscriptionService,
    private checkoutService: CheckoutService,
    private cardService: CardService,
    private formBuilder: FormBuilder,
    private toastService: ToastrService,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private location: Location,
    private socketService: SocketService,
    private validation: ValidationHelper,
    loadStateHelper: LoadStateHelper) {
    this.loader = loadStateHelper;
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get getForm() {
    return this.paymentForm?.controls;
  }

  get getTotal(): number {
    const idMap = { dataLinkFee: 'transfer_price', subscription_monthly: 'subscription_price' , subscription_yearly :'subscription_price' };
    let total: number = 0;
    this.getSelected?.forEach((e) => {
      total += (this.adjustedValue[idMap[e.id]] ?? e.fee)
    });
    return total;
  }

  get getDiscountAmount(): number {
    const totalAfterDeductions: number = this.getTotal; // Total amount of selected payments after deductions for discounts
    let totalBeforeDeductions: number = 0; // Total amount of selected payments without deductions for discounts
    this.getSelected?.forEach(e => totalBeforeDeductions += e.fee);
    return Number(totalBeforeDeductions - totalAfterDeductions);
  }

  get getSelected(): PaymentOption[] {
    return this.paymentOptions?.filter(e => e.selected);
  }

  get isDataLinkFee(): boolean {
    return this.paymentOptions?.find(item => item.id == 'dataLinkFee')?.selected;
  }

  get isSubscriptionMonthlyFee(): boolean {
    return this.paymentOptions?.find(item => item.id == 'subscription_monthly')?.selected;
  }

  get isSubscriptionAnnualFee(): boolean {
    return this.paymentOptions?.find(item => item.id == 'subscription_yearly')?.selected;
  }

  get showPromoCode(): boolean {
    if (this.paymentMode) {
      return (!this.promoCodeApplied && (this.paymentMode != ConsumerPaymentMethod.PASS_TO_CONSUMER || this.isDataLinkFee));
    }
    return !this.promoCodeApplied;
  }

  /**
   * Get whether is payment button disabled
   */
  get isPaymentButtonDisabled(): boolean {
    return this.isLoading || !this.getSelected?.length || !this.isDVAgreementChecked || (!this.cards.length ? this.paymentForm.invalid : false);
  }


  ngOnInit(): void {
    this.domainDateFormat = this.commonHelper.domainDateFormat;
    this.paymentMode = this.localStorageService.getDataByKey('payment_mode');
    this.userType = this.localStorageService.getDataByKey('role');
    this.isConsumer = this.commonHelper.getUserRole() === LoginType.DIGITAL_VAULT;
    this.isProfessional = this.commonHelper.getUserRole() === LoginType.PROFESSIONAL;
    this.isCustodian = this.commonHelper.getUserRole() === LoginType.CUSTODIAN;
    this.userRequestId = this.localStorageService.getDataByKey('user_request_id');
    this.paymentStatus = this.localStorageService.getDataByKey('is_payment_complete');
    this.userData = this.localStorageService.getUserData();
    this.preDefinedLoader = true;
    this.checkCustodianAccess();
    this.checkoutService.getPaymentOptions().subscribe({
      next: r => this.paymentOptions = r,
      complete: () => this.loadCart(),
    });
    this.getSubscriptionDetails();
    this.loadCards();
    this.initForm();
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param{string} controlKey
   * @return{Array<string>}
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.paymentForm);
  }

  /**
   * To navigate saved card page
   */
  public navigateToSavedCards() {
    let url = [...(!this.isConsumer ? ['executor-custodian'] : []), 'saved-cards'];
    void this.slugInterceptorService.navigate(url);
  }

  /**
   * selected card
   * @param {any} card
   */
  public selectedCard(card: any) {
    this.selectedCardDetails = card;
  }

  /**
   * Handle Input keyup event to autofocus the next input field.
   * @param $event
   */
  onInputKeyUp($event: KeyboardEvent) {
    const omitKeys = [
      'Backspace',
      'Tab',
      'ShiftLeft',
      'ShiftRight',
    ];
    if (omitKeys.indexOf($event.code) < 0) {
      // autofocus order
      const arrIDs = [
        'card_number',
        'exp_month',
        'exp_year',
        'sec_code',
        'card_name',
      ];
      const inputID: string = $event.target?.['id'];
      const maxLength: number = $event.target?.['maxLength'];
      const currentLength: number = ($event.target?.['value'] as string).length;

      let element;
      if (currentLength >= maxLength) {
        const nextID = arrIDs[arrIDs.indexOf(inputID) + 1];
        element = document.getElementById(nextID);
        element.focus();
      }
    }
  }

  setSelectedOptionAmountDefault(): void {
    this.paymentTypes = [];
    this.paymentOptions.forEach(obj => {
      if (obj.selected) {
        let type;
        switch (obj.id) {
          case 'subscription_monthly':
            type = 1;
            this.adjustedValue.subscription_price = obj.fee;
            break;
          case 'dataLinkFee':
            type = 2;
            this.adjustedValue.transfer_price = obj.fee;
            break;
          case 'subscription_yearly':
            type = 1;
            this.adjustedValue.subscription_price = obj.fee;
          default:
            type = 0; // Set a default value if none of the cases match
            break;
        }
        this.paymentTypes.push(type);
      }
    });
  }

  removeOption(option: PaymentOption) {
    this.checkoutService.updateCart(option.id, true);
    this.loadCart();
  }

  convertNumber(value: any): number {
    const numberValue = Number(value);
    return isNaN(numberValue) ? 0 : numberValue;
  }

  checkPromoCode() {
    this.setSelectedOptionAmountDefault();
    this.subscriptionService.checkPromoCode(this.promoCode.toUpperCase(), this.paymentTypes).subscribe({
      next: (response) => {
        const data = response.data;
        if (data.valid_promo_code) {
          this.toastr.success('Promo Code Applied Successfully');
          this.appliedPromoCode = this.promoCode.toUpperCase();
          this.promoCodeApplied = true;
          this.promoCode = '';
          this.promoCodeValidity = this.commonHelper.formatDate(response.data['validity'], this.domainDateFormat);
          this.adjustedValue = {
            subscription_price: data?.subscription_price ? this.convertNumber(data.subscription_price) : this.adjustedValue?.subscription_price,
            transfer_price: data?.transfer_price ? this.convertNumber(data.transfer_price) : this.adjustedValue?.transfer_price,
            download_price: this.convertNumber(data.download_price),
          };
          this.appliedPromoId = data.promo_id;
        } else {
          this.toastr.error(response.data?.error_message);
          this.promoCode = '';
          this.appliedPromoCode = '';
          this.promoCodeValidity = '';
          this.promoCodeApplied = false;
        }
      },
      error: error => console.error('Error applying promo code:', error),
    });

  }

  goBack() {
    this.location.back();
  }

  initiatePayment() {
    if (this.isPaymentButtonDisabled) {
      this.isPaymentClicked = true;
      return;
    }
    if (this.promoCode) {
      this.modalService.open('checkout-message-modal');
      return;
    }
    this.isLoading = true;
    if (this.selectedCardDetails) {
      this.makePayment();
    } else {
      // Token is Created for the New card
      this.getCardToken().subscribe({
        next: (response: any) => {
          this.isLoading = false;
          this.cardToken = response.id;
          this.makePayment();
        },
        error: e => {
          this.isLoading = false;
          const msg = e?.['error']?.['error']?.message;
          if (msg) {
            this.toastService.error(msg);
          }
        },
      });
    }
  }

  /**
   * Generates Card Token for the New Card Details
   */
  public getCardToken() {
    const { card_number, exp_month, exp_year, cvv } = this.paymentForm.value;
    return this.cardService.generateCardToken(card_number, exp_month, exp_year, cvv);
  }

  removePromoCode() {
    this.setSelectedOptionAmountDefault();
    this.appliedPromoCode = '';
    this.promoCodeValidity = '';
    this.promoCodeApplied = false;
  }

  showDVAgreement() {
    // Invert value for clicking on link
    this.isDVAgreementChecked = !this.isDVAgreementChecked;
    this.modalService.open('agreement-modal');
  }

  messageListener($event: any) {
    if ($event) this.modalService.close('checkout-message-modal');
  }

  /**
   * If {@link userRequestId} is not set for Custodian, they accessed this paged improperly (or there is a bug in a previous flow)
   * @private
   */
  private checkCustodianAccess() {
    if (this.isCustodian && !this.userRequestId) {
      console.error('DVC accessed checkout without a user_request_id.');
      void this.slugInterceptorService.navigate(['executor-custodian', 'dashboard']);
    }
  }

  /**
   * Load card items from {@link CheckoutService}.
   * @private
   */
  private loadCart() {
    const cart = this.checkoutService.getCart;
    this.paymentOptions.forEach(opt => opt.selected = !!cart.find(cid => cid == opt.id));
    // If nothing is selected, return to previous screen.
    if (!this.paymentOptions.find(opt => opt.selected)) {
      this.goBack();
    }
    this.setSelectedOptionAmountDefault();
  }

  private initForm() {
    this.paymentForm = this.formBuilder.group({
      currency: ['usd'],
      new_card: ['1'],
      card_id: [''],
      card_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN), Validators.maxLength(50)] }],
      card_number: ['', { updateOn: 'blur', validators: [Validators.required] }],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.maxLength(10), Validators.pattern(ZIPCODE_PATTERN)] }],
      exp_month: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(2), this.monthValidator.bind(this)] }],
      exp_year: ['', { updateOn: 'blur', validators: [Validators.required, this.yearValidator.bind(this)] }],
      cvv: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(4), Validators.pattern(NUMBERS_ONLY_PATTERN)] }],
    });

    this.validation.setValidationErrors({
      zipcode: { pattern: 'Please enter a valid zipcode' },
      exp_month: { isNotAllowed: 'Please enter a valid month', mask: null },
      exp_year: { isNotAllowed: 'Please enter a valid year', mask: null },
      cvv: { isNotAllowed: 'Please enter a valid security code', mask: null },
    });
  }

  /**
   * month validation
   * @param {FormControl}control
   * @return {boolean}
   */
  private monthValidator(control: FormControl): { [s: string]: boolean } {
    const enteredMonth = +(control.value);
    if (control.value != '' && (enteredMonth > 12 || enteredMonth < 1)) {
      return { isNotAllowed: true };
    } else {
      return null;
    }
  }

  /**
   * year validation
   * @param {FormControl}control
   * @return {boolean}
   */
  private yearValidator(control: FormControl): { [s: string]: boolean } {
    const enteredYear = +(control.value);
    if (control.value != '' && enteredYear < new Date().getFullYear() && enteredYear != 0) {
      return { isNotAllowed: true };
    } else {
      return null;
    }
  }

  /**
   * get card details
   */
  private loadCards() {
    this.cardService.getCardsApi().subscribe(
      {
        next: (response: any) => {
          this.cards = response?.data;
          this.cards.forEach((current, index) => {
            if (current.is_default == 1) {
              this.selectedCard(this.cards[index]);
            }
          });
        },
        complete: () => {
          this.preDefinedLoader = false;
        },
      });
  }

  /**
   * get subscription details
   */
  private getSubscriptionDetails() {
    this.loader.set('subscription_details');
    this.subscriptionService.getSubscriptionDetailsApi(this.userRequestId).subscribe({
      next: response => {
        const data: Object = response.data ?? {};
        this.vaultRegistrationAmount = data['vault_registration_amount'];
        this.subscriptionDetails = data['subscription_details'];
        this.vaultTransferAmount = data['vault_transfer_amount'];
        this.transferAmountPaidDate = data['transfer_amount_paid_at'];
        this.paymentRefundAt = data['payment_refund_at'];
        this.dlbId = data['request_unique_id'];
        this.localStorageService.storeData('is_payment_complete', data['is_payment_complete']);
        this.refundStatus = this.localStorageService.getDataByKey('is_payment_complete') === PAYMENT_STATUS.onRefundPeriod;

        const packages = (data['package'] as Object[]);
        this.yearlyPackageDetails = packages.find(e => e['name'] == 'Yearly');
        this.monthlyPackageDetails = packages.find(e => e['name'] == 'Monthly');
        this.activePackage = this.yearlyPackageDetails;

        this.loader.set('subscription_details', false);
      }, error: e => {
        this.commonHelper.httpResponseHandler(e.error);
        this.loader.set('subscription_details', false);
      },
    });
  }

  private makePayment() {
    this.destroyRedirect = false;
    let params = {
      token: this.cardToken,
      currency: this.paymentForm.value.currency,
      new_card: this.paymentForm.value.new_card,
      card_id: this.paymentForm.value.card_id,
      card_name: this.paymentForm.value.card_name,
      zipcode: this.paymentForm.value.zipcode,
      exp_month: this.paymentForm.value.exp_month,
      exp_year: this.paymentForm.value.exp_year,
      request_id: this.isCustodian ? this.userRequestId : null,
    };
    if (this.isSubscriptionMonthlyFee || this.isSubscriptionAnnualFee ) {
      params['subscription_price'] = this.adjustedValue.subscription_price;
    }
    if (this.isDataLinkFee) {
      params['transfer_price'] = this.adjustedValue.transfer_price;
    }
    if (this.promoCodeApplied) {
      params['promo_code_id'] = this.appliedPromoId;
    }
    if (this.selectedCardDetails) {
      params.new_card = '0';
      params.token = '';
      params.card_id = this.selectedCardDetails.id;
      params.zipcode = this.selectedCardDetails.zipcode;
      params.exp_month = this.selectedCardDetails.exp_date.split('/')[0];
      params.exp_year = this.selectedCardDetails.exp_date.split('/')[1];
    }
    console.log(params, 'check params');
    this.modalService.open('payment-process');

    const handleSuccess = (response: APIResponseModel, processedCheckoutResponse?: APIResponseModel) => {
      this.isLoading = false;
      if (!response.status) {
        return;
      }
      if (this.userType === 'Consumer') {
        this.paymentStatus = response.data.is_payment_complete;
        this.localStorageService.storeData('is_payment_complete', this.paymentStatus);
        this.localStorageService.storeData('is_transfer_amount_paid', response.data.is_transfer_amount_paid);
        this.localStorageService.updateUserData(USER_TYPES.user, {
          key: 'user',
          updateValue: response.data.is_payment_complete,
          updateKey: 'is_payment_complete',
          type: LOCAL_STORAGE_UPDATE_STATUS.O,
        });
      }
      // if 3d secure payment open authentication page in new tab
      if (processedCheckoutResponse) {
        window.open(processedCheckoutResponse.data?.payment_confirm_url, '_blank');
      }
    };

    const handleException = (exception: any) => {
      this.isLoading = false;
      this.modalService.close('payment-process');
      const exceptionMessage = exception?.error?.message;
      this.modalService.open('payment-failure');
      if (typeof exceptionMessage == 'string') {
        this.commonService.paymentErrorListener(exceptionMessage);
        return;
      }
      const message_obj: {} = exceptionMessage;
      const messages: Array<string> = [];
      Object.keys(message_obj).forEach((k) => message_obj[k]?.forEach((e) => messages.push(e)));
      messages?.forEach((m) => this.toastService.error(m));
    };

    this.subscriptionService.checkoutApi(params).subscribe({
      next: (checkoutResponse) => {
        const processedCheckoutResponse = checkoutResponse?.data?.hasOwnProperty('payment_confirm_url') ? checkoutResponse : undefined;
        const requestId = this.isCustodian ? this.userRequestId : (this.userData?.request?.id || this.selectedUserRequestId);

        this.commonService.payForDlb(requestId).subscribe({
          next: (response) => handleSuccess(response, processedCheckoutResponse),
          error: handleException,
        });
      },
      error: handleException,
    });
  }

}
