<div class="scroll-hidden background-color-grey h-full">
  <!-- Navbar -->
  <app-professional-navbar></app-professional-navbar>
  <div class="grid grid-cols-12 p-8 sm:p-12 xl:px-24 ">
      <div class="col-span-12">
          <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
              Account Settings
          </h2>
          <p class="text-base text-grey py-2 tracking-wide text-regular">Check and update your personal
              information and account settings</p>
          <hr class="border-lightblue w-24 my-1 border-1">
      </div>
  </div>
  <div class="grid grid-cols-12 md:px-12 xl:px-24 pb-12 gap-6 md:gap-12">
      <div class="col-span-12 lg:col-span-3 bg-transparent hidden lg:block">
          <div class="sticky top-10 fixed">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                  Contents
              </h4>
              <ul class="space-y-1 mt-2">
                  <li class="text-grey text-regular text-lg cursor-pointer"
                      (click)="menuChange(1)"
                      [ngClass]="{'active': menuActive === 1}">Your
                      Account Details</li>
                  <li class="text-grey text-regular text-lg cursor-pointer"
                      (click)="menuChange(2)"
                      [ngClass]="{'active': menuActive === 2}">Change
                      your Password
                  </li>
                  <!--li class="text-grey text-regular text-lg cursor-pointer"
                      (click)="menuChange(3)"
                      [ngClass]="{'active': menuActive === 3}">Contact
                      Preferences</li-->
              </ul>
          </div>
      </div>
      <div class="col-span-12 lg:col-span-9 space-y-8">
          <!-- BEGIN: Account Details -->
          <div class="grid grid-cols-12 gap-6 bg-white mx-8 md:mx-0 border-card rounded p-6" *ngIf="menuActive === 1">
              <div class="col-span-12">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                      Your Account Details
                  </h4>
              </div>
              <div class="col-span-12 md:col-span-2">
                  <form [formGroup]="accountDetailForm">
                      <div class="h-32 w-32 flex justify-center items-center rounded-full relative">
                          <app-image-component
                              [image]="imageSrc"
                              [class]="'w-full h-full rounded-full object-contain'"
                              [defaultImage]="'assets/icons/user-bg.svg'"
                          >
                        </app-image-component>
                          <span
                              class="absolute h-8 w-8 right-0 flex justify-center items-center rounded-full border-white border-2 bg-edit bottom-0 cursor-pointer" (click)="propicfield.click()">
                              <img [src]="'assets/icons/edit.svg'" class="w-3 h-3" alt="digital vault">
                              <input type="file" class="hidden" #propicfield (change)="readURL($event);" id="file">
                          </span>
                      </div>
                  </form>
              </div>
              <div class="col-span-12 md:col-span-10 md:ml-10 lg:ml-12 xl:ml-4">
                  <form [formGroup]="accountDetailForm" autocomplete="off" (ngSubmit)="accountDetailFormSubmit()">
                      <div>
                          <label for="email" class="text-sm pb-2 text-regular text-grey">Email
                              Address</label>

                          <input type="email" name="email" id="email" formControlName="email"
                              class="input mt-1 w-full rounded-xs h-12 text-regular appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                              [ngClass]="accountDetailFormGet?.email?.errors && accountDetailFormGet?.email?.touched ?'form-input-error':'form-input-focus'"
                              placeholder="Enter your Email address" [attr.disabled]="!changeEmail? true : null">
                          <!-- BEGIN: Error Alert text -->
                          <div *ngIf="accountDetailFormGet.email.errors && accountDetailFormGet.email.touched">
                              <p class="text-regular font-medium text-xs mt-2 error-alert"
                                  *ngIf="accountDetailFormGet.email.errors.pattern">
                                  Please enter a valid email address.
                              </p>
                              <p class="text-regular font-medium text-xs mt-2 error-alert"
                                  *ngIf="accountDetailFormGet.email.errors.required">
                                  Email is required
                              </p>
                          </div>
                          <!-- END: Error Alert text -->
                      </div>
                      <div class="mt-5" *ngIf="changeEmail">
                          <label for="password" class="text-sm pb-2 text-regular text-grey">
                              Password</label>
                          <div class="relative h-12 text-grey">
                              <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                  (click)="togglePasswordView = !togglePasswordView">
                                  <fa-icon [icon]="faEye" *ngIf="!togglePasswordView"></fa-icon>
                                  <fa-icon [icon]="faEyeSlash" *ngIf="togglePasswordView"></fa-icon>
                              </div>
                              <input name="password" id="password"
                                  class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                                  formControlName="password" [type]="togglePasswordView?'text':'password'"
                                  [ngClass]="accountDetailFormGet?.password?.errors && accountDetailFormGet?.password?.touched ?'form-input-error':'form-input-focus'"
                                  placeholder="Enter Password">
                              <!-- BEGIN: Error Alert text -->
                              <div
                                  *ngIf="accountDetailFormGet?.password.errors && accountDetailFormGet?.password.touched ?'form-input-error':'form-input-focus'">
                                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                                      *ngIf="accountDetailFormGet?.password?.errors?.minlength">Your
                                      password is too short. Please use at least 8 characters.
                                  </p>
                                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                                      *ngIf="accountDetailFormGet?.password?.errors?.required">
                                      Password is required
                                  </p>
                              </div>
                              <!-- END: Error Alert text -->
                          </div>
                      </div>
                      <div class="mt-8">
                          <app-button-loader *ngIf="changeEmail" [buttonText]="'Save Changes'"
                                             [valid]="accountDetailForm.valid" buttonType="'submit'" [loader]="accountDetailsSubmit"></app-button-loader>
                          <app-button-loader
                              *ngIf="!changeEmail"
                              [buttonText]="'Change email'"
                              [valid]="true"
                              buttonType="'button'"
                              (clickButton)="changeEmail = true"
                          ></app-button-loader>
                      </div>
                  </form>
              </div>
          </div>
          <!-- END: Account Details -->

          <!-- BEGIN: Change Passsword -->
          <div class="grid grid-cols-12 gap-6 bg-white mx-8 md:mx-0 border-card rounded p-6" *ngIf="menuActive === 2">
              <div class="col-span-12">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                      Change your password
                  </h4>
              </div>

              <div class="col-span-12 ml-4">
                  <form [formGroup]="changePasswordForm" autocomplete="off" (ngSubmit)="changePasswordFormSubmit()">
                      <div>
                          <label for="old-password" class="text-sm pb-2 text-regular text-grey">
                              Old password</label>
                          <div class="relative h-12 text-grey">
                              <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                  (click)="toggleOldPasswordView = !toggleOldPasswordView">
                                  <fa-icon [icon]="faEye" *ngIf="!toggleOldPasswordView"></fa-icon>
                                  <fa-icon [icon]="faEyeSlash" *ngIf="toggleOldPasswordView"></fa-icon>
                              </div>
                              <input formControlName="password" [type]="toggleOldPasswordView?'text':'password'"
                                  name="password" id="oldPassword"
                                  class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                                  [ngClass]="changePasswordFormGet?.oldPassword?.errors && changePasswordFormGet?.oldPassword?.touched ?'form-input-error':'form-input-focus'"
                                  placeholder="Enter Old Password">
                              <!-- BEGIN: Error Alert text -->
                              <div
                                  *ngIf="changePasswordFormGet?.oldPassword?.errors && changePasswordFormGet?.oldPassword?.touched ?'form-input-error':'form-input-focus'">
                                  <p class="text-regular font-medium text-xs my-2 error-alert"
                                      *ngIf="changePasswordFormGet?.oldPassword?.errors?.invalid">Your
                                      password is too short. Please use at least 8 characters.
                                  </p>
                              </div>
                              <!-- END: Error Alert text -->
                          </div>
                      </div>
                      <div class="mt-5">
                          <label for="newPassword" class="text-sm pb-2 text-regular text-grey">
                              New Password</label>
                          <div class="relative h-12 text-grey z-10">
                              <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                  (click)="toggleNewPasswordView = !toggleNewPasswordView">
                                  <fa-icon [icon]="faEye" *ngIf="!toggleNewPasswordView"></fa-icon>
                                  <fa-icon [icon]="faEyeSlash" *ngIf="toggleNewPasswordView"></fa-icon>
                              </div>
                              <input name="new_password" id="newPassword"
                                  class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                                  formControlName="new_password" [type]="toggleNewPasswordView?'text':'password'"
                                  [ngClass]="changePasswordFormGet?.newPassword?.errors && changePasswordFormGet?.newPassword?.touched ?'form-input-error':'form-input-focus'"
                                  placeholder="Enter New Password">
                              <!-- BEGIN: Error Alert text -->

                              <div *ngIf="changePasswordFormGet.password.errors && changePasswordFormGet.password.dirty" class="z-100">
                                <div *ngIf="changePasswordFormGet.password.errors.required">
                                  <p class="text-regular font-medium text-xs mt-2 error-alert">Password is required</p>
                                </div>
                                <div *ngIf="!changePasswordFormGet.password.errors.required" class="absolute border mt-4 rounded bg-white p-2 text-red-700 border-3 w-full rounded-xs border block focus:outline-none">

                                    <p [ngClass]="!changePasswordFormGet.password.errors.minlength? 'green-error-text':'red-error-text'">
                                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                                      alt="digital vault" *ngIf="!changePasswordFormGet.password.errors.minlength">
                                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                                      alt="digital vault" *ngIf="changePasswordFormGet.password.errors.minlength">
                                      Minimum at least 8 characters.
                                    </p>

                                    <p [ngClass]="changePasswordFormGet.password.errors?.passwordStrength?.hasNumeric? 'green-error-text':'red-error-text'">
                                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                                      alt="digital vault" *ngIf="changePasswordFormGet.password.errors?.passwordStrength?.hasNumeric">
                                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                                      alt="digital vault" *ngIf="!changePasswordFormGet.password.errors?.passwordStrength?.hasNumeric">
                                      At least one number (0-9).
                                    </p>

                                    <p [ngClass]="changePasswordFormGet.password.errors?.passwordStrength?.hasLowerCase? 'green-error-text':'red-error-text'">
                                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                                      alt="digital vault" *ngIf="changePasswordFormGet.password.errors?.passwordStrength?.hasLowerCase">
                                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                                      alt="digital vault" *ngIf="!changePasswordFormGet.password.errors?.passwordStrength?.hasLowerCase">
                                      At least one lowercase letter (a-z).
                                    </p>

                                    <p [ngClass]="changePasswordFormGet.password.errors?.passwordStrength?.hasUpperCase? 'green-error-text':'red-error-text'">
                                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                                      alt="digital vault" *ngIf="changePasswordFormGet.password.errors?.passwordStrength?.hasUpperCase">
                                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                                      alt="digital vault" *ngIf="!changePasswordFormGet.password.errors?.passwordStrength?.hasUpperCase">
                                      At least one uppercase letter (A-Z).</p>

                                    <p [ngClass]="changePasswordFormGet.password.errors?.passwordStrength?.hasSpecialCharacters? 'green-error-text':'red-error-text'">
                                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                                      alt="digital vault" *ngIf="changePasswordFormGet.password.errors?.passwordStrength?.hasSpecialCharacters">
                                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                                      alt="digital vault" *ngIf="!changePasswordFormGet.password.errors?.passwordStrength?.hasSpecialCharacters">
                                      At least one special character (!@#$%^&*).
                                    </p>

                                </div>
                                </div>
                              <!-- <div
                                  *ngIf="changePasswordFormGet?.newPassword?.errors && changePasswordFormGet?.newPassword?.touched ?'form-input-error':'form-input-focus'">
                                  <p class="text-regular font-medium text-xs my-2 error-alert"
                                      *ngIf="changePasswordFormGet?.newPassword?.errors?.minlength">Your
                                      password is too short. Please use at least 6 characters.
                                  </p>
                                  <p class="text-regular font-medium text-xs my-2 error-alert"
                                      *ngIf="changePasswordFormGet?.newPassword?.errors?.confirmedValidator">Your
                                      Password and Confirm Password must be match.
                                  </p>
                                  <p class="text-regular font-medium text-xs my-2 error-alert"
                                      *ngIf="changePasswordFormGet?.newPassword?.errors?.confirmedValidator">Your
                                      Password is required.
                                  </p>
                              </div> -->
                              <!-- END: Error Alert text -->
                          </div>
                      </div>
                      <div class="mt-8">
                          <app-button-loader
                              [buttonText]="'Save Changes'"
                              [valid]="changePasswordForm.valid"
                              [loader]="changePasswordSubmit"
                              buttonType="'submit'"
                          ></app-button-loader>

                      </div>
                  </form>
              </div>
          </div>
          <!-- END: Change Passsword -->

          <!-- BEGIN: Contact Preferences -->
          <div class="grid grid-cols-12 gap-4 bg-white border-card mx-8 md:mx-0 rounded p-6" *ngIf="menuActive === 3">
              <div class="col-span-12">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                      Contact Preferences
                  </h4>
              </div>

              <div class="col-span-12 mt-2 md:space-x-4 sm:space-x-0 md:flex sm:flex-row">

                  <label>
                      <input type="checkbox" class="form-checkbox h-5 w-5 text-darkblue focus:outline-none" checked>
                      <span class="text-darkgrey text-base text-regular ml-4">Receive marketing
                          emails from Digital Vault</span>
                  </label>
                  <label>
                      <input type="checkbox" class="form-checkbox h-5 w-5 text-darkblue focus:outline-none">
                      <span class="text-darkgrey text-base text-regular ml-4">Receive calls from Digital Lock
                          Box</span>
                  </label>
              </div>
              <div class="col-span-12 mt-2">
                <button type="submit"
                    class="button button--lg mt-2 text-sm tracking-wide shadow-lg md:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-6">Save
                    Changes</button>
            </div>
          </div>
          <!-- END: Contact Preferences -->
      </div>

  </div>
</div>
