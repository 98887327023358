import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ICountryCode } from 'src/app/interface/common.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ReceivedDlbService } from 'src/app/services/received-dlb.service';
import { COMMON_SUBSCRIBER_EVENT } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-people-account-details',
  templateUrl: './people-account-details.component.html',
})
/**
 * People account details
 */
export class PeopleAccountDetailsComponent implements OnInit, OnChanges {
  @Input() userDetails: any;
  public requestStage: number;
  public functionMapper: any;
  public countryCode: ICountryCode;
  public dlbStatus: string;
  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private router: Router,
    private localService: LocalStorageService,
    private receivedDlbService: ReceivedDlbService,
    private commonHelper: CommonHelper,
    private commonService: CommonService,
    private slugInterceptorService: SlugInterceptorService,
  ) { }

  /**
   * onchange
   */
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }
  /**
   * called initailly
   */
  ngOnInit(): void {
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    this.dlbStatus = this.userDetails.status;
    this.openModal();
    this.requestStage = this.localService.getUserData()?.request?.request_stage;
    console.log('this.requestStage', this.requestStage);
  }

  /**
   * fkefke
   */
  public openModal() {
    console.log('coming');

    this.functionMapper = {
      0: () => {
        this.requestStage = this.localService.getUserData()?.request?.request_stage;

        if (this.requestStage < 3 || !this.requestStage) {
          this.receivedDlbService.setUser(this.userDetails);
          this.modalService.open('about-yourself-modal');
        } else {
          this.receivedDlbService.setUser(this.userDetails);
          this.modalService.open('verify-departed-modal');
        }
      },
      1: () => {
        this.requestStage = this.localService.getUserData()?.request?.request_stage;
        if (this.requestStage < 3 || !this.requestStage) {
          this.receivedDlbService.setUser(this.userDetails);
          this.modalService.open('about-yourself-modal');
        } else {
          this.receivedDlbService.setUser(this.userDetails);
          this.modalService.open('claim-dlb-modal');
        }
      },
      2: () => {
        // ! Need to change this for pending state
        this.commonService.commonSubscriberEvent({
          type: COMMON_SUBSCRIBER_EVENT.DEFAULT_VALUE_SUBSCRIBER,
        });
        this.localService.storeData('currentDlb', this.userDetails);
        void this.slugInterceptorService.navigate([`dlb-dashboard/${this.commonHelper.encodeDecodeRequestId(this.userDetails?.request_id, true)}`]);
      },
      3: () => {
        this.commonService.commonSubscriberEvent({
          type: COMMON_SUBSCRIBER_EVENT.DEFAULT_VALUE_SUBSCRIBER,
        });
        this.localService.storeData('currentDlb', this.userDetails);
        void this.slugInterceptorService.navigate([`dlb-dashboard/${this.commonHelper.encodeDecodeRequestId(this.userDetails?.request_id, true)}`]);
      },
    };
  }
}
