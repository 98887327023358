import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { FileValidationOptionsInterface, ObservableEventInterface } from 'src/app/interface/common.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { CLEAR_SELECTED_FILE, SET_IMAGE } from 'src/constants/digitalFile.const';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-file-upload-handler',
  templateUrl: './file-upload-handler.component.html',
  styleUrls: ['./file-upload-handler.component.css'],
})
/**
 * File upload component handler
 */
export class FileUploadHandlerComponent implements OnInit {
  @Output() fileUploadChangeEmitter = new EventEmitter<any>();
  @Output() fileDeleteChangeEmitter = new EventEmitter<any>();
  @Output() isFileDropWhenNoDocumentSelected = new EventEmitter<any>(false);
  @Input() fileValidationOptions: FileValidationOptionsInterface;
  @Input() canEdit: boolean = true;
  @Input() hideFileOperations: boolean;
  @Input() disable: boolean;
  @Input() displayFileName: boolean;
  @Input() isDigitalFiles: boolean = false;
  @Input() imageFormatFiltering: Array<string>;
  @Input() handler_id: number;
  @Input() hasDocumentType: boolean = true;
  @Input() allowMultiple = false;
  @Input() allowImagesOnly = false;

  public file: any;
  public imagePreviewUrl: any;
  public requestId: string;
  public userType: string;
  public isImageFile: boolean = true;
  public downloadLink: string;
  public downloadFileName: string;
  public updateSubscription: Subscription;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public uploadedFiles = [];

  /**
   * @constructor
   */
  constructor(
    private commonService: CommonService,
    private localStorageService: LocalStorageService,
    private modalService: CommonModelService,
    private toastService: ToastrService,
    private route: ActivatedRoute,
    private commonHelper: CommonHelper,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.downloadLink = '';
    this.downloadFileName = '';
    if (this.allowImagesOnly) {
      this.fileValidationOptions = {
        size: 100000,
        fileFormat: ['jpeg', 'jpg', 'png'],
      };
    }
    // listen for file observable events
    this.commonService.fileHandlerComponentObserve.subscribe((data: ObservableEventInterface) => {
      // First check if this is the right handler (or if handler_id is null, any handler)
      this.isImageFile = data.isImageFile;
      switch (data?.type) {
        case SET_IMAGE:
          if (!data.data) return;
          if (!this.displayFileName) {
            this.file = { name: data.data };
          }
          if (data?.id) {
            this.imagePreviewUrl = `${environment.BASE_URL_RAW}uploads/agent_proof/${data.data}`;
          } else if (this.userType === 'Consumer') {
            this.imagePreviewUrl = `${environment.BASE_URL_RAW}uploads/${this.localStorageService.getUserData()?.request?.id}${data.data}`;
          } else {
            this.imagePreviewUrl = `${environment.BASE_URL_RAW}uploads/${this.requestId}${data.data}`;
          }
          break;
        case CLEAR_SELECTED_FILE:
          this.file = undefined;
          this.imagePreviewUrl = '';
          break;
      }
    });

    // listen for file preview download
    this.commonService.filePreviewObservableEvents({ isPreviewProcess: false });
  }

  /**
   * Upload file
   *
   * @param {NgxFileDropEntry[]} files
   */
  public uplaodPropertyImage(files: NgxFileDropEntry[]): void {
    this.uploadedFiles = [];
    // To support multiple file uploads(Currently in Digital and Upload Imp. Document Sections)
    if (files.length > 1) {
      for (let index = 0; index < files.length; index++) {
        this.handleFiles(files, index);
      }
    } else {
      // 0 here refers to the first file of NgxFileDropEntry[]
      this.handleFiles(files, 0);
    }
  }

  /**
   * file handling for single/multiple file uploading
   * @param{NgxFileDropEntry[]}files
   * @param{number}index
   */
  private handleFiles(files: NgxFileDropEntry[], index: number) {
    const propertyFiles = files;
    if (propertyFiles[index].fileEntry.isFile) {
      const fileEntry = propertyFiles[index].fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        // check file format after the last dot
        const fileType = file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2);
        console.log(fileType);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.commonService.filePreviewObservableEvents({
            dataUrl: reader.result, fileName: propertyFiles[index].relativePath, isPreviewProcess: true,
          });
        };
        if (this.fileValidationOptions?.fileFormat?.indexOf(fileType?.toLowerCase()) === -1) {
          if (this.isDigitalFiles) {
            this.toastService.error('Please upload any one of the given formats');
            this.file = '';
          } else {
            this.toastService.error('File must be one of the following format ' + this.fileValidationOptions?.fileFormat?.join(','));
          }
          return;
        } else {
          // check if image or file format
          this.imageFormatFiltering?.indexOf(fileType?.toLowerCase()) !== -1 ? this.isImageFile = true : this.isImageFile = false;
        }
        // check file size
        const fileSize = (file.size) / 1000;
        if (this.fileValidationOptions?.size && fileSize > this.fileValidationOptions?.size) {
          this.toastService.error('File size must not exceed ' + (this.fileValidationOptions.size / 1000) + 'mb');
          return;
        }
        this.uploadedFiles.push(file);
        if (index === propertyFiles.length - 1) {
          this.fileUploadChangeEmit(files.length >= 1 && this.isDigitalFiles ? this.uploadedFiles : file);
        }
        this.imagePreviewUrl = '';
        if (!this.displayFileName) {
          this.file = file;
        }
      });
    } else {
      // It was a directory (empty directories are added, otherwise only files)
      const fileEntry = propertyFiles[index].fileEntry as FileSystemDirectoryEntry;
      console.log(fileEntry);
    }
  }

  /**
   * If no handler_id is set, it will emit just the file (old functionality).
   * If a handler_id is set, then it will emit an object with the 'handler_id' and 'file' keys.
   * @param file
   */
  public fileUploadChangeEmit(file: any): void {
    this.fileUploadChangeEmitter.emit(this.handler_id != null ? { handler_id: this.handler_id, file: file } : file);
  }

  getName(file: any): string {
    const name = file?.name.split('/');
    return this.commonHelper.removePrefixNumber(file['fileName'] ?? name[name.length - 1]);;
  }

  /**
   * If no handler_id is set, it will emit just the file (old functionality).
   * If a handler_id is set, then it will emit an object with the 'handler_id' and 'deleted' keys.
   * @param deleted
   */
  public fileUploadDeleteEmit(deleted: any): void {
    this.fileDeleteChangeEmitter.emit(this.handler_id != null ? { handler_id: this.handler_id, deleted: deleted } : deleted);
  }

  /**
   * Remove uploaded file
   */
  public removeUploadedFile(): void {
    this.file = '';
    this.fileUploadChangeEmit('');
    this.fileUploadDeleteEmit('1');
  }

  /**
   * View image popup
   */
  public viewImage(file?: any): void {
    if (this.imagePreviewUrl) {
      this.commonService.setImageViewPopupTrigger({imageSrc:this.imagePreviewUrl});
      this.modalService.open('view-image-modal');
      return;
    }

    if (file && file instanceof File) {  // view file before sumbitted form
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result as string;
        this.commonService.setImageViewPopupTrigger({ imageSrc: base64String });
        this.modalService.open('view-image-modal');
      };
      reader.readAsDataURL(file);
      this.updateSubscription.unsubscribe();
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreviewUrl = reader.result as string;
      this.commonService.setImageViewPopupTrigger(this.imagePreviewUrl);
      this.modalService.open('view-image-modal');
    };
    reader.readAsDataURL(this.file);
  }

  /**
   * Download file
   * @param {file} file
   */
  public downloadFile(file: any): void {
    if (file) {
      this.updateSubscription = this.commonService.filePreviewComponentObserve.subscribe((data) => {
        if (data.isPreviewProcess == true) {
          this.downloadURI(data.dataUrl, data.fileName);
          return;
        } else {
          if (this.userType === 'Consumer') {
            this.downloadLink = `${environment.BASE_URL_RAW}uploads/${this.localStorageService.getUserData()?.request?.id}${file.name}`;
          } else {
            this.downloadLink = `${environment.BASE_URL_RAW}uploads/${this.requestId}${file.name}`;
          }
          this.downloadFileName = file.file_name;
          this.modalService.open('file-download-modal');
        }
      });
      this.updateSubscription.unsubscribe();
    }
  }

  /**
   * Preview the file by download
   * @param {any} uri
   * @param {string} name
   */
  public downloadURI(uri, name) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public onFileDrop() {
    const documentTypeNotSelected = this.userType === 'Consumer' ? this.canAccessForm && this.hasDocumentType : this.hasDocumentType;
    if (!documentTypeNotSelected) {
      this.isFileDropWhenNoDocumentSelected.emit(true);
    }
  }

  public onClickUpload(openFileSelector: () => void) {
    if (!this.disable && this.canEdit) {
      openFileSelector();
    }
  }
}
