import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.css']
})
/**
 * Dynamic table component with toggleable features: Search, List Length, Sort-By, Per-item Actions
 * Dynamically assign Columns and Data.
 */
export class TableViewComponent implements OnInit {
  @Output() searchEvent: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() actionEvent: EventEmitter<Event> = new EventEmitter<Event>();
  @Input() public columns: string[];
  @Input() public data: { [key: string]: any }[];
  @Input() public useActions: boolean = true;


  ngOnInit(): void {
    // Example Column object
    let columns = [
      'Name',
      'No. of Clients',
      'Added On',
      'Last Login'
    ]
    // Example Data object
    let data = [
      {
        'Name': 'John',
        'No. of Clients': 4,
        'Added On': new Date(),
        'Last Login': new Date(),
        'Actions': ['Edit', "Delete"],    //  Will be returned by Emitter
        'Nested': [{                      // One expandable nested layer, optional.
          'Name': 'Hallie',
          'Added On': new Date(),
          'Last Login': new Date(),
          'Actions': ['Edit', "Delete"]   // Will be returned by Emitter
        }]
      },
      {
        'Name': 'Jerry',
        'No. of Clients': 4,
        'Added On': new Date(),
        'Last Login': new Date(),
        'Actions': ['Edit', "Delete"], // Will be returned by Emitter
        'Nested': []
      }];
  }


  public onSearch(event: Event): void {
    this.searchEvent.emit(event);
  }
}
