import { Component, OnInit } from '@angular/core';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-invalid-short-link',
  templateUrl: './invalid-short-link.component.html',
})
/**
 * invalid short link component
 */
export class InvalidShortLinkComponent implements OnInit {
  /**
   * constructor
   */
  constructor(
    private slugInterceptorService:SlugInterceptorService,
    private localStorageService: LocalStorageService
  ) {
  }
  /**
   * initial loader
   */
  ngOnInit(): void {
    console.log('invalid-short-link');
  }

  /**
     * change route
     * @param{string}url
    */
  public changeRoute(url: string) {
    const routeUrl = this.localStorageService.getDataByKey('url')
    switch (routeUrl) {
      case 'pro':
        void this.slugInterceptorService.navigate([''],null, true);
        break;
      default :
        void this.slugInterceptorService.navigate([url]);
        break;
    }
  }
}
