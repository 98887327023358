import { Component } from '@angular/core';

@Component({
  selector: 'app-professional-dashboard',
  templateUrl: './professional-dashboard.component.html',
  styleUrls: ['./professional-dashboard.component.css'],
})
/**
 * professional dashboard
 */
export class ProfessionalDashboardComponent {

}
