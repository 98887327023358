<app-common-modal id="open-search-modal" class="app-common-modal">
  <!-- BEGIN: Modal -->

  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">

  </div>
  <div class="h-full ">
    <div class=" bg-white rounded-md ">
      <!--search bar chart-->
      <!--end-->
      <div class="inbox-height">
        <!--start-->
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Search Document
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">Please enter the document type name
          </p>
        </div>
        <div class="w-full mb-3">
          <div class="relative">
            <div class="relative h-10 text-grey ">
              <div class=" absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"> <img
                  src=" assets/images/about-yourself/search.svg " class="w-4  h-4" alt=" dv "> </div>
              <input type=" text " class="  h-10 sm:h-12 input mt-3 py-3  w-full placeholder-grey text-regular text-sm rounded-xs appearance-none  border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 price-input
                       rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                placeholder="Search by Document Name" [(ngModel)]="searchKeyword"
                (input)="searchDocument($event.target.value)">
            </div>
          </div>
        </div>
        <div class="bg-transparent border-card-grey rounded-xs mt-6 h-20" *ngIf="spinner">
          <div class="grid grid-cols-12 h-20 items-center gap-4 px-3 sm:pl-3">
            <div class="col-span-3 sm:col-span-2">
              <app-shimmer-loading [loading]="spinner" [shape]="'circle'" [width]="'4rem'">
                <div class="flex items-center justify-center mx-auto rounded-full h-12 w-12 bg-darkblue">
                  <p class="uppercase text-white text-regular text-base">
                    <img src="assets/images/executors/display-img.svg" class="w-5" alt="dv">
                  </p>
                </div>
              </app-shimmer-loading>
            </div>
            <div class="col-span-7 sm:col-span-8">
              <h4 class="break-all text-base sm:text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold">
                <app-shimmer-loading [loading]="spinner">
                  Lily-Rose Depp
                </app-shimmer-loading>
              </h4>
              <p class="text-grey text-regular text-xs sm:text-sm">
                <app-shimmer-loading [loading]="spinner">
                  Your Child
                </app-shimmer-loading>
              </p>
            </div>
            <div class="col-span-2">
              <div class="flex items-center justify-center h-5">
                <app-shimmer-loading [loading]="spinner" [width]="'4rem'">
                  <input type="checkbox" class="h-4 w-4 input text-lightblue border rounded">
                </app-shimmer-loading>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="!spinner && documentList?.length > 0 && this.searchKeyword">

          <div class="overflow-y-scroll h-60">
            <div *ngFor="let document of documentList;let i = index"
              class="border border-dashed border-grey h-24 mt-6 rounded p-6 flex flex-row justify-between items-center">
              <div class="flex flex-row items-center w-9/12 sm:w-10/12 xl:w-11/12">
                <img src="{{'assets/images/digital-document/pdf.svg' | fileTypeIcons:document?.name}}" class="w-6"
                  alt="digital vault">
                <p class=" text-darkgrey text-regular text-sm px-4 overflow-ellipsis overflow-hidden turncate">
                  {{document?.file_name}}
                </p>
              </div>
              <div class="flex flex-row items-center justify-between gap-2 w-3/12 sm:w-2/12 xl:w-1/12">
                <div class="cursor-pointer" (click)="viewImage(document?.name,document?.user_request_id)"><img
                    src=" assets/images/digital-files/eye.svg " class="w-6" alt=""
                    *ngIf="imageFormatFiltering.indexOf(document?.file_extension) !== -1"></div>
                <div class="cursor-pointer" (click)="downLoadFile(document,document?.user_request_id)"><img
                    src="assets/images/digital-files/download.svg" class="w-6" alt=""
                    *ngIf="imageFormatFiltering.indexOf(document?.file_extension) === -1"></div>

              </div>
            </div>
          </div>
        </ng-container>

        <div *ngIf="documentList?.length === 0 && !spinner && this.searchKeyword">
          <div class="mt-8">
            <h4 class="py-2 text-grey text-center border border-red-400 text-base lg:text-md text-regular">
              No data found
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>



  <!-- End Modal -->
</app-common-modal>
<app-image-view-popup [secondModalOpen]="true"></app-image-view-popup>
<app-file-downloader [downloadLink]="downloadLink" [fileName]="downloadFileName"></app-file-downloader>
