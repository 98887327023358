import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { SocketService } from 'src/app/services/socket.service';
import {
  FILE_GROUPING_CHAT,
  FILE_GROUPING_WITH_TYPE,
} from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../services/local-storage.service';
import { ConsumerOrgChatService } from './consuer-org-chat.service';

/**
 * component
 */
@Component({
  selector: 'app-consumer-org-message',
  templateUrl: './consumer-org-message.component.html',
  styleUrls: ['./consumer-org-message.component.css'],
})

/**
 * ConsumerOrgMessageComponent
 */
export class ConsumerOrgMessageComponent implements OnInit {
  public spinner: boolean;
  // public connected = false;
  // public searchKeyword: string;
  // public connectUrl: string = environment?.CHAT_BASE_URL;
  // public userId: string = '1';
  // public sessionId: string = '1';
  // public accessToken: string = '1';
  // public apiKey: string = '1aderygjiyt6ujgd6jjgfb';
  public selectedToId: string = '';
  // public chatUrl: string;
  public fromUser: any;
  public textMessage: string;
  public fromUserId: string;
  public isShowMessage = false;
  public onlineUsers: any = [];
  public chatMessages: any = [];
  public searchText: string;
  public activeChatScreenUser: string = '';
  public chatContactList: any = [];
  public usersOnlineStatus: any = [];
  public unreadMessageCount: any = [];
  // public professionalId: string = '';
  // public professionalName: string;
  // public userOnlineStatus: any = true;
  public displayMessages: any = [];
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  public disableScrollDown = false;
  public clientLoader = false;


  // new chat works
  public toUsers: Array<any> = [];
  public dateToday: Date = new Date()
  public toUser: { [key: string]: any } = {};
  public isTyping: string = '';
  public userRequest = 1;
  public scrollTop;
  public scrollDirection;
  public lastChatPage;
  public tempDisplayMessages: any = [];
  public baseUrl: string;
  public selectedFiles = [];
  public previews = [];
  public fileToShow;
  public thumpImagePreview = [];
  public fileGroups = FILE_GROUPING_CHAT;
  public showingImageId;
  public isDisplayImageMenu: boolean;
  public rightClickOnImagePosX: number;
  public rightClickOnImagePosY: number;
  public messageUrlOnClicked: string;
  public messageIdOnClicked: number = 0;
  public downloadLink;
  public downloadFileName;
  public showReceiversList = false;
  public showchat = true;
  public screenwidth: number = window.innerWidth;
  public isStartPosition = true;
  public isEditMessage = false;
  public selectedMessageIdToUpdate = 0;
  public previousScrollHeight: number = 0;
  public chatStatusMessage: String = '';
  public fileExtensionWithTypeGroup = FILE_GROUPING_WITH_TYPE;
  public targetDuration = 0;
  public filesPayload = [];
  /**
   * constructor
   */
  constructor(
    private localStorageService: LocalStorageService,
    private chatService: ConsumerOrgChatService,
    private socketService: SocketService,
    private commonHelper: CommonHelper,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private modalService: CommonModelService,
  ) {

  }

  /**
   * initial loader
   */
  ngOnInit() {
    this.baseUrl = environment.BASE_URL_RAW;
    this.fromUser = this.localStorageService.getUserData();
    this.getToUsersList();
    this.socketService.getNewMessage().subscribe((response: any) => {
      if (Object.keys(response).length > 0) {
        if (this.selectedToId == response.to_id || this.selectedToId == response.from_id) {
          const index = this.displayMessages?.findIndex((chat) => chat?.id === response?.id);
          const uniqueIndex = this.displayMessages?.findIndex((chat) => chat?.unique_id === response?.unique_id);
          if (response?.message_type !== 0 && uniqueIndex !== -1) {
            delete response?.unique_id;
            this.displayMessages[uniqueIndex] = response;
            this.scrollToDown();
          } else if (index === -1) {
            this.displayMessages?.push(response);
            this.previousScrollHeight = this.myScrollContainer.nativeElement.scrollHeight;
            this.scrollToDown();
          }
        }
        if (this.selectedToId === response.from_id) {
          const payload = {
            'from_id': this.fromUserId,
            'to_id': response.from_id,
            'chat_id': response.id,
            'to_id_type': 'organization',
          };
          console.log(payload);
          this.socketService.sendReadMessage(payload);
        }

        this.toUsers?.forEach((cur) => {
          if (cur.sub_organization_id === response.from_id || cur.sub_organization_id === response.to_id) {
            cur.lastMessage = response.message;
            cur.last_chat_created_at = response.created_at;
            cur.last_message_type = response?.message_type;
          }
          if ((this.selectedToId === '' || this.selectedToId !== response.from_id) && cur.sub_organization_id === response.from_id) {
            cur.totalUnread = cur.totalUnread === 0 ? 1 : cur.totalUnread + 1;
          }
        });
        this.sortChatList();
      }
    });
    this.socketService.getTypingEvent().subscribe((response: any) => {
      if (Object.keys(response).length > 0) {
        if (response === this.toUser.sub_organization_id) {
          this.isTyping = 'typing...';
          setTimeout(() => {
            this.isTyping = 'online';
          }, 2000);
        }
        if (response === this.toUser?.sub_organization_id) {
          this.toUser.is_typing = true;
        }
        const idx = this.toUsers?.findIndex((el) => el?.sub_organization_id === response);
        if (idx !== -1) {
          this.toUsers[idx].is_typing = true;
        }
        setTimeout(() => {
          if (response === this.toUser?.sub_organization_id) {
            this.toUser.is_typing = false;
          }
          const idx = this.toUsers?.findIndex((el) => el?.sub_organization_id === response);
          if (idx !== -1) {
            this.toUsers[idx].is_typing = false;
          }
        }, 2000);
      }
    });
    this.socketService.getOnlineUsers().subscribe((response: any) => {
      if (Object.keys(response).length > 0) {
        const idx = this.toUsers?.findIndex((el) => el?.sub_organization_id === response);
        if (this.toUsers?.length > 0 && idx !== -1) {
          this.toUsers[idx].is_typing = false;
          this.toUsers[idx].is_online = 1;
        }
        if (this.toUser) {
          this.toUser.is_online = 1;
        }
        if (response === this.toUser?.sub_organization_id) {
          this.toUser.is_typing = false;
          this.toUser.is_online = 1;
          this.displayMessages.forEach((chat) => {
            if (chat.message_status === 0) {
              chat.message_status = 1;
            }
          });
        }
      }
    });
    this.socketService.getOfflineEvent().subscribe((response: any) => {
      if (Object.keys(response).length > 0) {
        if (response === this.toUser?.sub_organization_id) {
          this.toUser.is_typing = false;
          this.toUser.is_online = 0;
        }
        const idx = this.toUsers?.findIndex((el) => el?.sub_organization_id === response);
        if (idx !== -1) {
          this.toUsers[idx].is_typing = false;
        }
        this.toUser.is_online = 0;
      }
    });
    this.socketService.listenReadEvent().subscribe((response: any) => {
      if (Object.keys(response).length > 0) {
        if (this.selectedToId === response.from_id && this.fromUserId === response.to_id) {
          this.displayMessages?.forEach((el, i) => {
            if (el.message_status !== 2) {
              this.displayMessages[i].message_status = 2;
            }
          });
        }
        if (response.from_id === this.fromUserId) {
          this.toUsers?.forEach((el) => {
            if (el.sub_organization_id === response.to_id) {
              el.totalUnread = 0;
            }
          });
        }
      }
    });
    this.mobileview();
    this.socketService.listenRemoveMessage().subscribe((response: any) => {
      if (Object.keys(response).length > 0) {
        if (response.to_id === this.selectedToId || response?.from_id === this.selectedToId) {
          const msgIndex = this.displayMessages?.findIndex((el) => el.id === response?.chat_id);
          if (msgIndex !== -1) {
            this.displayMessages.splice(msgIndex, 1);
            this.previousScrollHeight = this.myScrollContainer.nativeElement.scrollHeight;
            this.getToUsersList();
          }
        } else {
          this.getToUsersList();
        }
      }
    });
    this.socketService.listenEditMessage().subscribe((response: any) => {
      if (Object.keys(response).length > 0) {
        if (response.to_id === this.selectedToId || response?.from_id === this.selectedToId) {
          const msgIndex = this.displayMessages?.findIndex((el) => el.id === response?.id);
          this.displayMessages[msgIndex].message = response?.message;
          this.getToUsersList();
        } else {
          this.getToUsersList();
        }
      }
    });
  }

  /**
 * get status
 * @param {number} status
 * @return {string}
 */
  public getReadUnreadImage(status) {
    switch (status) {
      case 0:
        return 'assets/images/chat/single-tick.svg';

      case 1:
        return 'assets/images/chat/grey-tick.svg';

      case 2:
        return 'assets/images/chat/blue-tick.svg';

      default:
        return 'assets/images/chat/single-tick.svg';
    }
  }

  /**
   * get online status
   * @return {boolean}
   */
  // public getUserOnlineStatus() {
  //   return this.userOnlineStatus;
  // }

  /**
   * get unread message count
   * @param {string} senderId
   * @param {string} receiverId
   * @return {string}
   */
  public getUnreadMessageCount(senderId, receiverId) {
    if (senderId == this.fromUserId) {
      return this.unreadMessageCount[receiverId];
    } else {
      return this.unreadMessageCount[senderId];
    }
  }

  /**
   * get time interval
   * @param {any} dateTime
   * @return {any}
   */
  public getTimeInterval(dateTime) {
    return moment(moment.utc(dateTime)).local().fromNow(true);
  }

  /**
   * convert date
   * @param {any} date
   * @return {any}
   */
  public convertDate(date) {
    return moment(moment.utc(date)).local().format('hh:mm A');
  }

  /**
   * bottom scroll
   */
  public scrollToDown() {
    setTimeout(() => {
      document.getElementById('messages').scrollTop = document.getElementById('messages').scrollHeight;
    }, 100);
  }

  /**
   * keydown
   * @param{any}event
   */
  public onKeydown(event: any) {
    if (!this.textMessage || !this.textMessage.trim()) {
      // alert('Enter message to send');
      return;
    } else if (event.altKey && event.key === 'Enter') {
      this.textMessage = this.textMessage + '\r\n';
    } else if (!event.altKey && event.key === 'Enter') {
      event.preventDefault();
    } else if (event?.code === 'Space') {
      this.textMessage = this.textMessage + '\xa0';
    }
  }

  /**
   * send message
   */
  public sendMessage() {
    if (!this.textMessage || !this.textMessage.trim()) {
      // alert('Enter message to send');
      return;
    } else {
      if (this.isEditMessage === true) {
        const payload = {
          'chat_id': this.selectedMessageIdToUpdate,
          'message': this.textMessage,
          'to_id': this.selectedToId,
          'to_id_type': 'organization',
          'from_id_type': 'user',
          'from_id': this.fromUserId,
          'message_type': 0,
        };
        this.socketService.editMessage(payload);
        this.textMessage = undefined;
        this.isEditMessage = false;
      } else {
        const data = {
          'from_id': this.fromUserId,
          'to_id': this.selectedToId,
          'from_id_type': 'user',
          'to_id_type': 'organization',
          'message': this.textMessage,
          'message_type': 0,
          'message_status': 0,
        };
        this.socketService.sendMesssage(data);
        this.textMessage = undefined;
      }
    }
  }


  /**
   *
   * @param {KeyboardEvent} event
   */
  public typing(event: KeyboardEvent) {
    // typing event
    const data = {
      'from_id': this.fromUserId,
      'to_id': this.selectedToId,
      'to_id_type': 'organization',
    };
    this.socketService.onTyping(data);
    if (!this.textMessage) {
      this.isEditMessage = false;
    }
  }

  /**
 * on scroll
 * @param {any} data
 */
  public onScroll(data) {
    this.isDisplayImageMenu = false;
    if (this.scrollTop) {
      this.scrollDirection = data.srcElement.scrollTop > this.scrollTop ? 'bottom' : 'top';
    }
    this.scrollTop = data.srcElement.scrollTop;
    if (this.scrollDirection === 'top' && data.srcElement.scrollTop === 0) {
      if (this.userRequest !== this.lastChatPage) {
        this.userRequest = this.lastChatPage === this.userRequest ? this.userRequest : this.userRequest + 1;
        // this.disableScrollDown = false;
        if (this.lastChatPage >= this.userRequest && this.userRequest !== 1) {
          this.getMessages(this.selectedToId);
        }
      }
    }
    if (this.myScrollContainer.nativeElement.scrollTop <= (this.myScrollContainer.nativeElement.scrollHeight - 500)) {
      this.isStartPosition = false;
    } else {
      this.isStartPosition = true;
    }
  }

  /**
   * on select user
   * @param {string}receiverId
   * @param {number} index
   */
  public onSelectUser(receiverId: string, index: number) {
    this.selectedToId = receiverId;
    this.isShowMessage = true;
    this.toUser = this.toUsers[index];
    // this.toUsers[index].totalUnread = 0;
    this.userRequest = 1;
    this.getMessages(receiverId);
    this.previews = [];
    if (this.screenwidth <= 1024) {
      this.showchat = true;
    }
  }

  /**
 * scroll to bottom
 *
 */
  private scrollToBottom(): void {
    // if (this.disableScrollDown) {
    //   return;
    // }
    try {
      // this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  /**
   * after view checked
   */
  public ngAfterViewChecked() {
    this.scrollToBottom();
    this.cdr.detectChanges();
  }

  /**
   * online user data prepare
   * @param {any} data // string or array
   */
  public onlineUsersDataPrepare(data?: any) {
    // if (typeof (data) === 'string') {
    const index = this.toUsers.findIndex((e) => e.sub_organization_id === data);
    this.toUsers[index]['last_seen_at'] === 'online';
  }

  /**
   * read messages
   *
   * @param {any} data
   * @return {Array} data
   */
  // public onShowReadMessages(data) {
  //   return data;
  // }

  /**
   * get messages
   *
   * @param {string} id
   */
  public getMessages(id: string) {
    this.chatService.getChatList(id, this.userRequest).subscribe((response: APIResponseModel) => {
      const tempDisplayMessages = [...response?.data?.data].reverse();
      const responseStorage = [...response?.data?.data];
      const readCountStorage = [...response?.data?.data];
      this.scrollToDown();
      this.previews = [];
      if (this.userRequest <= 1) {
        this.displayMessages = tempDisplayMessages;
        this.previousScrollHeight = this.myScrollContainer.nativeElement.scrollHeight;
        this.scrollToDown();
      } else {
        this.previousScrollHeight = this.myScrollContainer.nativeElement.scrollHeight;
        this.displayMessages = [...responseStorage.reverse(), ...this.displayMessages];
        this.scrollToFixedPosition();
      }
      this.lastChatPage = response?.data?.last_page;
      this.readEventToSelectedProfessional(readCountStorage);
    }, (exception: any) => {
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
  * sort chat list
  */
  public sortChatList() {
    this.toUsers = this.toUsers?.sort((a, b) => {
      // Sort by last_chat_created_at (recent chats come first)
      if (a.last_chat_created_at && b.last_chat_created_at) {
        return new Date(b.last_chat_created_at).getTime() - new Date(a.last_chat_created_at).getTime();
      } else if (a.last_chat_created_at) {
        return -1; // a has a valid value, so it comes before b
      } else if (b.last_chat_created_at) {
        return 1; // b has a valid value, so it comes before a
      }

      // Sort alphabetically based on the name field
      return a.name.localeCompare(b.name);
    });
  }

  /**
   * read event
   * @param {object} data
   */
  public readEventToSelectedProfessional(data: any) {
    const BreakError = [];
    try {
      data?.forEach((el) => {
        if (el.from_id !== this.fromUserId && el.message_status !== 2) {
          const payload = {
            'from_id': this.fromUserId,
            'to_id': el.from_id,
            'chat_id': el.id,
            'to_id_type': 'organization',
          };
          this.socketService.sendReadMessage(payload);
          console.log(payload);
          throw BreakError;
        }
      });
    } catch (err) {
      if (err !== BreakError) throw err;
    }
  }

  /**
   * multiple line content
   *
   * @param {string} data
   * @return {Array}
   */
  public multipleLineContent(data: string) {
    return data.split(/\r?\n/);
  }

  // File upload and send
  /**
   * uploading files
   * @param {file} data
   */
  public onSelectingFiles(data: any) {
    this.selectedFiles = data.target.files;
    if (this.selectedFiles?.length > 5) {
      this.commonHelper.toastMessages({ status: 0, message: 'Files length not exceeds 5' });
      data.preventDefault();
      return;
    }
    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      for (let i = 0; i < this.selectedFiles?.length; i++) {
        const fileExtension = this.selectedFiles[i]?.name?.split('.').pop().toLowerCase();
        if (this.fileExtensionCheck(fileExtension) === false) {
          this.previews = [];
          this.commonHelper.toastMessages({ status: 0, message: 'File types of doc, docx, txt, pdf, jpeg, jpg, png, mp4, mkv, mov, ogg, mp3, wav, octet-stream, mpeg and mpga only allowed.' });
          return;
        }
        if (Math.floor(this.selectedFiles[i]?.size / 1000000) > 20) {
          this.previews = [];
          this.commonHelper.toastMessages({ status: 0, message: 'File size should be within 20MB.' });
          return;
        }
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews[i].file = e.target.result;
          this.previews[i].type = fileExtension;
        };
        reader.readAsDataURL(this.selectedFiles[i]);

        this.preparePreview(i, this.selectedFiles[i]);
      }
    }
  }

  /**
   * prepare to show
   * @param {number}idx
   * @param {File}file
   */
  public preparePreview(idx: number, file: File): void {
    this.previews[idx] = {
      ...this.previews[idx],
      media: this.selectedFiles[idx],
      value: 0,
      fileName: file.name,
    };
    this.filesPayload[idx] = this.selectedFiles[idx];
    if (idx === this.selectedFiles?.length - 1) {
      this.fileToShow = this.previews[0];
      this.showingImageId = 0;
      this.thumpImagePreview = [...this.previews];
    }
  }

  /**
  * send file
  */
  public SendFile(): void {
    this.previews = [];
    this.scrollToDown();
    this.commonHelper.toastMessages({ status: 1, message: 'Files uploading...' });
    for (let i = 0; i < this.thumpImagePreview.length; i++) {
      const fileExtension = this.thumpImagePreview[i]?.fileName?.split('.').pop().toLowerCase();
      const uniqueId = this.fromUserId + '-' + this.thumpImagePreview[i]?.fileName;
      const dataToPreview = {
        'unique_id': uniqueId,
        'from_id': this.fromUserId,
        'to_id': this.selectedToId,
        'from_id_type': 'user',
        'to_id_type': 'organization',
        'message_url': this.thumpImagePreview[i]?.file,
        'created_at': new Date().toUTCString(),
        'updated_at': new Date().toUTCString(),
        'message_type': this.fileType(fileExtension),
        'message_status': 0,

      };
      this.displayMessages?.push(dataToPreview);
      this.scrollToDown();
    }
    const payload = {
      'file': this.filesPayload,
      'from_id': this.fromUserId,
      'to_id': this.selectedToId,
      'from_id_type': 'user',
      'to_id_type': 'organization',
    };
    if (payload) {
      this.chatService.onUploadFiles(payload).subscribe(
        (event: any) => {
          console.log(event);
          // this.commonHelper.toastMessages({ status: 1, message: 'Uploaded Successfully' });
        },
        (err: any) => {
          // this.modalService.close('chat-status-message-modal');
          this.previews = [];
          this.commonHelper.httpResponseHandler(err?.error);
          // this.isFileSending = false;
        });
    }
  }

  /**
   * close preview
   */
  public closeImagePreview() {
    this.previews = [];
  }

  /**
   * remove thump
   * @param {number} id
   */
  public removeThumpImage(id: number) {
    this.previews.splice(id, 1);
    if (this.previews?.length === 0) {
      // this.fileCount = 0;
      this.previews = [];
      this.fileToShow = {};
      this.previews = [];
      this.showingImageId = 0;
    } else {
      if (this.showingImageId === id) {
        if (id === 0) {
          this.fileToShow = this.previews[0];
          this.showingImageId = 0;
        } else if (id === this.previews?.length) {
          this.fileToShow = this.previews[this.previews?.length - 1];
          this.showingImageId = this.previews?.length - 1;
        } else {
          this.fileToShow = this.previews[id - 1];
          this.showingImageId = id - 1;
        }
      } else {
        if (this.showingImageId === 0) {
          this.showingImageId = 0;
        } else if (this.showingImageId === this.previews?.length) {
          this.fileToShow = this.previews[this.showingImageId - 1];
          this.showingImageId = this.showingImageId - 1;
        } else {
          if (this.showingImageId > id) {
            this.fileToShow = this.previews[this.showingImageId - 1];
            this.showingImageId = this.showingImageId - 1;
          } else if (this.showingImageId < id) {
            this.fileToShow = this.previews[this.showingImageId];
          }
        }
      }
    }
  }

  /**
   * select thump image to show
   *
   * @param {number} id
   */
  public onSelectToShow(id: number) {
    this.showingImageId = id;
    this.fileToShow = this.previews[id];
  }

  /**
  *
  * view file - image/document
  *
  *@param {string} url
  */
  public viewFile(url: any) {
    const fileUrl = `${environment.BASE_URL_RAW}uploads${url}`;
    const imageIndex = this.fileGroups.I.indexOf(fileUrl.split(/[#?]/)[0].split('.').pop().trim());
    // const documentIndex = this.fileGroups.D.indexOf(url.substring(url.lastIndexOf(".")));
    if (imageIndex !== -1) {
      this.commonService.setImageViewPopupTrigger({imageSrc:fileUrl});
      this.modalService.open('view-image-modal');
    } else {
      saveAs(fileUrl, 'doc');
    }
  }

  /**
   * right click
   *
   * @param {any} event
   * @param {any} url
   * @param {any} id
   */
  public onRightClickOnImage(event: any, url: any, id: any) {
    event.preventDefault();
    this.isDisplayImageMenu = true;
    this.messageIdOnClicked = id;
    this.messageUrlOnClicked = url;
    this.rightClickOnImagePosX = event?.clientX;
    this.rightClickOnImagePosY = event?.clientY;
  }

  /**
   * image handling menu style
   * @param {number} id
   * @return {object}
   */
  public getRightClickOnImageStyle() {
    return {
      position: 'fixed',
      left: `${this.rightClickOnImagePosX}px`,
      top: `${this.rightClickOnImagePosY}px`,
      background: '#fff',
      padding: '10px 20px',
    };
  }

  /**
   * click on page
   */
  public clickOnPage() {
    this.isDisplayImageMenu = false;
  }

  /**
     * download file - image/document
     * @param {string} id
     */
  public downloadFile(id: String) {
    this.commonHelper.toastMessages({ status: 1, message: 'Downloading...' });
    const fileId = `${id}`;
    this.chatService.fileDownload(fileId).subscribe((response) => {
      const filename = this.fileTypeCheckWithGroup(response?.type) === '1' ? 'image' : this.fileTypeCheckWithGroup(response?.type) === '2' ? 'file' : this.fileTypeCheckWithGroup(response?.type) === '3' ? 'audio' : 'video';
      saveAs(response, filename);
    },
      ((err) => {
        this.commonHelper.toastMessages({ status: 0, message: 'error' });
      }));
  }

  /**
   * file menu events - click on menu
   *
   * @param {string} event
   * @param {any} data
   */
  public onFileMenuClick(event: string, data: any) {
    switch (event) {
      case 'view':
        this.viewFile(data);
        break;
      case 'download':
        this.downloadFile(data);
        break;
      case 'remove':
        this.removeMessage(data);
        break;
      case 'edit':
        this.editMessage(data);
        break;
      case 'copy':
        this.copyMessage(data);
        break;
      default:
        this.viewFile(data);
    }
  }

  /**
  * loading files
  * @param {string} id
  */
  public onFileLoading(id: string): void {
    if (id) {
      document.getElementById(`loader_${id}`).classList.add('hidden');
      const isLastMessage = this.displayMessages[this.displayMessages.length - 1]?.id === id;
      if (isLastMessage) {
        this.scrollToDown();
      }
    }
  }

  /**
   * mobile view
   */
  public mobileview() {
    if (this.screenwidth <= 1024) {
      this.showchat = false;
    }
  }

  /**
  * back to listing page
  */
  public backToListingPage() {
    if (this.screenwidth <= 1024) {
      this.showchat = false;
      this.displayMessages = [];
      this.selectedToId = '';
    }
  }


  /**
  * remove message
  *
  * @param {any} data
  */
  public removeMessage(data: any) {
    this.selectedMessageIdToUpdate = data?.id;
    this.modalService.open('delete-chat-confirmation-modal');
  }

  /**
   * remove message
   *
   * @param {any} data
   */
  public removeMessageConfirmed(data: any) {
    if (data === true) {
      const payload = {
        chat_id: this.selectedMessageIdToUpdate,
        to_id: this.selectedToId,
        to_id_type: 'organization',
        from_id: this.fromUserId,
      };
      this.socketService.removeMessage(payload);
      this.modalService.close('delete-chat-confirmation-modal');
    }
  }

  /**
   * edit message
   *
   * @param {any} data
   */
  public editMessage(data: any) {
    this.textMessage = data?.message;
    this.isEditMessage = true;
    this.selectedMessageIdToUpdate = data?.id;
  }

  /**
   * get users list
   */
  public getToUsersList() {
    this.clientLoader = true;
    this.chatService.subOrganizationList().subscribe((response: any) => {
      this.clientLoader = false;
      this.toUsers = response.data;
      this.sortChatList();
      this.fromUserId = this.fromUser?.user?.id;
      this.toUsers.forEach((el) => {
        if (el?.sub_organization_id) {
          el['is_typing'] = false;
        }
      });
    });
  }

  /**
   * handle key board event
   * handle enter key event - send text and files
   *
   * @param {any} event
   */
  @HostListener('document:keypress', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.previews?.length > 0) {
        this.SendFile();
      } else if (this.textMessage) {
        this.sendMessage();
      }
    }
  }

  /**
   * scroll to fixed position
   */
  public scrollToFixedPosition() {
    setTimeout(() => {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight - this.previousScrollHeight;
    }, 200);
  }


  /**
   * copy text message
   * @param {any} data
   */
  public copyMessage(data: any) {
    navigator.clipboard.writeText(data?.message);
    this.commonHelper.toastMessages({ status: 1, message: 'Copied' });
  }

  /**
   * file extension check
   * @param {string} data
   * @return {boolean}
   */
  public fileExtensionCheck(data: string) {
    const docType = this.fileGroups.D.indexOf(data);
    const imageType = this.fileGroups.I.indexOf(data);
    const audioType = this.fileGroups.A.indexOf(data);
    const videoType = this.fileGroups.V.indexOf(data);
    if (docType !== -1 || imageType !== -1 || audioType !== -1 || videoType !== -1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * file extension check
   * @param {string} data
   * @return {boolean}
   */
  public fileTypeCheckWithGroup(data: string) {
    const docType = this.fileExtensionWithTypeGroup.D.indexOf(data);
    const imageType = this.fileExtensionWithTypeGroup.I.indexOf(data);
    const audioType = this.fileExtensionWithTypeGroup.A.indexOf(data);
    // const videoType = this.fileExtensionWithTypeGroup.V.indexOf(data);
    return imageType !== -1 ? '1' : docType !== -1 ? '2' : audioType !== -1 ? '3' : '4';
  }

  /**
   * file type
   * @param {string} data
   * @return {string}
   */
  public fileType(data: string) {
    const docType = this.fileGroups.D.indexOf(data);
    const imageType = this.fileGroups.I.indexOf(data);
    const audioType = this.fileGroups.A.indexOf(data);
    // const videoType = this.fileGroups.V.indexOf(data);
    return imageType !== -1 ? '1' : docType !== -1 ? '2' : audioType !== -1 ? '3' : '4';
  }

  /**
   * audio messsages
   * preparing audio elements
   *
   * @param {any} message
   */
  public prepareAudioPlayer(message) {
    message.audioId = `audioId${message?.id}`;
    message.sliderId = `sliderId${message?.id}`;
    message.audio = <HTMLAudioElement>document.getElementById(`audioId${message?.id}`);
    message.slider = <HTMLInputElement>document.getElementById(`sliderId${message?.id}`);
    document.getElementById(`loader_${message?.id}`).classList.add('hidden');
  }

  /**
  * update audio playing time to slider
  *
  * @param {any} data
  */
  public updateAudioTime(data: any) {
    data.slider.style.background = `linear-gradient(to right, #205CD5 0%, #1d4ed81a ${(data?.audio?.currentTime) / (data?.audio?.duration) * 100}%)`;
  }

  /**
   * set target durations
   *
   * @param {any} data
   * @param {any} event
   */
  public setTargetDuration(data: any, event: any) {
    if (data) {
      data.audio.currentTime = event.target.value;
      data.slider.style.background = `linear-gradient(to right, #205CD5 0%, #1d4ed81a ${(data?.audio?.currentTime) / (data?.audio?.duration) * 100}%)`;
    }
  }

  /**
   * audio player ending
   *
   * @param {any} data
   */
  public onAudioEnded(data: any) {
    data.audio.currentTime = 0;
  }
}
