import { AgmCoreModule } from '@agm/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';


import { CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { RecaptchaModule } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';
import { QuicklinkModule } from 'ngx-quicklink';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { ToastrModule } from 'ngx-toastr';
import { SparkoutChatWidgetModule } from 'sparkout-chat-web-component';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TableViewComponent } from './baseComponents/table-view/table-view.component';
import { ComponentsModule } from './components/components.module';
import { ConsumerLandingContentNewComponent } from './components/consumer-landing-page/consumer-landing-content-new/consumer-landing-content-new.component';
import { ConsumerLandingContentComponent } from './components/consumer-landing-page/consumer-landing-content/consumer-landing-content.component';
import { ConsumerLandingPageComponent } from './components/consumer-landing-page/consumer-landing-page.component';
import { ConsumerLandingPageModule } from './components/consumer-landing-page/consumer-landing-page.module';
import { LoginComponent } from './components/login/login.component';
import { NavBarModule } from './components/navbar/navbar.module';
import { RegisterComponent } from './components/register/register.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { JwtInterceptorExecutor } from './helper/jwtInterceptoExecutor.helper';
import { JwtInterceptor } from './helper/jwtInterceptor.helper';
import { JwtInterceptorOrg } from './helper/jwtInterceptorOrg.helper';
import { JwtInterceptorProfessional } from './helper/jwtInterceptorProfessional.helper';
import { UnAuthorizedInterceptor } from './helper/logoutInterceptor.helper';
import { PipeModule } from './pipes/pipes.module';
import { SharedModule } from './sharedComponent/shared.module';

import { TOASTR_TIME_OUT } from 'src/constants/application.const';
import { HttpErrorHandlingInterceptor } from './services/http-error-handling-interceptor.service';


@NgModule({
  declarations: [
    AppComponent,
    ConsumerLandingPageComponent,
    ConsumerLandingContentComponent,
    ConsumerLandingContentNewComponent,
    LoginComponent,
    RegisterComponent,
    VerifyOtpComponent,
    TableViewComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    ConsumerLandingPageModule,
    RecaptchaModule,
    FontAwesomeModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: TOASTR_TIME_OUT,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      maxOpened: 5,
    }),
    NgIdleKeepaliveModule.forRoot(),
    NgxShimmerLoadingModule,
    NgxMaskModule.forRoot(),
    PipeModule,
    NavBarModule,
    QuicklinkModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_PLACES_API_KEY,
      libraries: ['places'],
    }),
    ComponentsModule,
    SparkoutChatWidgetModule,

    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000',
    // }),

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorHandlingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorProfessional, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorOrg, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnAuthorizedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorExecutor, multi: true },
    { provide: DEFAULT_CURRENCY_CODE, useValue: environment.CURRENCY_CODE },
    CookieService, CurrencyPipe,
  ],
  exports: [ComponentsModule],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
/**
 * App module
 */
export class AppModule {
}
