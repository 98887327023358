import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LoginType } from 'src/constants/application.const';
import { CheckoutService } from '../../components/checkout/checkout.service';
import { PaymentOption } from '../../components/checkout/payment-option.model';
import { CommonHelper } from '../../helper/common.helper';
import { SlugInterceptorService } from '../../helper/slug-interceptor.service';
import { CommonModelService } from '../../services/common-model.service';
import { CommonService } from '../../services/common.service';


@Component({
  selector: 'app-data-link-service-info-modal',
  templateUrl: './data-link-service-info-modal.component.html',
  styleUrls: ['./data-link-service-info-modal.component.css'],
})
export class DataLinkServiceInfoModalComponent implements OnInit {
  @Input() modalID: string = 'transfer-service-fee-modal';
  @Input() paymentInitiatedSource: string = '';
  @Input() dvTransferContent: string;
  public isConsumer: boolean;
  dataLinkPaymentOption: PaymentOption;
  loading: boolean = true;

  constructor(
    private modalService: CommonModelService,
    private checkoutService: CheckoutService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private router: SlugInterceptorService,
  ) {
  }

  ngOnInit(): void {
    this.isConsumer = (this.commonHelper.getUserRole() === LoginType.DIGITAL_VAULT);
    this.getPaymentOptions();
  }
  ngOnChanges(changes: SimpleChanges): void {
  if(changes?.dvTransferContent.currentValue) {
    this.dataLinkPaymentOption.description = changes.dvTransferContent.currentValue
  }
  }

  /**
   * {@link CommonService.getContactUsDetails getContactUsDetails}
   */
  private getPaymentOptions() {
    this.loading = true;
    this.checkoutService.getPaymentOptions().subscribe({
      next: options => {
        
        this.dataLinkPaymentOption = options.find(e => 'dataLinkFee' == e.id);
        if(this.dvTransferContent) {
          this.dataLinkPaymentOption.description = this.dvTransferContent;
        }
        this.loading = false;
      },
      error: e => this.commonHelper.httpResponseHandler(e.error),
    });
  }

  closeModal(): void {
    this.modalService.close('transfer-service-fee-modal');
  }

  payNow(): void {
    this.checkoutService.setCart([this.dataLinkPaymentOption.id]);
    if (this.isConsumer) {
      this.localStorageService.storeData('paymentInitiatedSource', this.paymentInitiatedSource);
      void this.router.navigate(['checkout']);
    } else {
      void this.router.navigate(['executor-custodian/checkout']);
    }
    this.closeModal();
  }

}
