// noinspection SpellCheckingInspection
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MaskPipe } from 'ngx-mask';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { AssetsService } from 'src/app/services/assets.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ALPHABET_ONLY_PATTERN, ALPHA_NUMERIC_WITH_HYPHEN_PATTERN_NEW, CREDIT_CARD_MASK, LIABILITY_TYPE, LIST_DELETE, LIST_NEW, LIST_UPDATE, NAME_PATTERN, NUMBERS_ONLY_PATTERN_CARD, NUMBERS_VALID_DOLLARS, USER_NAME_WITH_SPECIAL_CHARACTERS_PATTERN, WEBSITE_PATTERN_NEW, YEAR_MASK } from 'src/constants/application.const';
import { ValidationHelper } from '../../../helper/validation.helper';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-add-liabilities-popup',
  templateUrl: './add-liabilities-popup.component.html',
  providers: [MaskPipe],

})
/**
 * Add liabilities popup component
 */
export class AddLiabilitiesPopupComponent implements OnInit {
  private currencyFields: string[] = ['loan_amount', 'amount'];
  @Output() toggleModalEmitter = new EventEmitter<any>();
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() modalID = 'add-liabilities-modal';
  @Input() clientHasPaidSubscription:boolean = false;
  public readonly faEye = faEye;
  public readonly faEyeSlash = faEyeSlash;
  public readonly LIABILITY_TYPE = LIABILITY_TYPE;
  public readonly CREDIT_CARD_MASK = CREDIT_CARD_MASK;
  private lockType: string;
  public lockTypeDropdown: boolean = false;
  public isNewAsset: boolean = true;
  public yearMask = YEAR_MASK;
  public togglePasswordView: boolean = false;
  // public liabilitiesForm: FormGroup;
  public forms: {
    otherLiability: FormGroup,
    creditCard: FormGroup
  };
  public liabilitiesTypeArray: Array<SelectOptionsInterface> = [];
  public submitLoader: boolean;
  public liabilitiesTypeLoader: boolean = false;
  public submitLoaderDelete: boolean;
  public currentDateRestriction: string;
  public liabilityTypeId: string;
  public liabilityType: string = '';
  public creditCardTypeArray: Array<any> = [];
  public userType: string;
  public requestId: string;
  public locale = 'en';
  public minDate: Date;
  public bsConfig: Partial<BsDatepickerConfig>;
  public liabilityTypeTouched = false;
  public otherType = '';
  // enables user to add/edit form fields
  private hasPaidSubscription: boolean;
  public canAccessForm: boolean;

  @Input() set setLockType(type: string) {
    this.lockType = type;
    const insuranceType = this.liabilitiesTypeArray.find((e) => e.value == type);
    this.optionChangeEmitterLiabilityType(insuranceType);
    this.lockTypeDropdown = !!type;
  }

  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private assetService: AssetsService,
    private commonHelper: CommonHelper,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private localeService: BsLocaleService,
    private toastrService: ToastrService,
    private validation: ValidationHelper,
    private maskPipe: MaskPipe,
    private subscriptionService: ConsumerSubscriptionService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.hasPaidSubscription = r);
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.currentDateRestriction = this.commonHelper.getCurrentDateForRestriction();
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.initForm();
    this.getCardLiabilityTypeDetails();
    this.applyLocale();
    this.bsConfig = {
      showWeekNumbers: false,
      minDate: this.minDate,
      adaptivePosition: true,
    };
    if (!this.canAccessForm) {
      // show credit card form fields by default if payment has not been done
      this.liabilityType = this.LIABILITY_TYPE.C;
      this.forms.otherLiability.disable();
      this.forms.creditCard.disable();
    }
  }

  private initForm() {
    this.forms = {
      otherLiability: this.formBuilder.group({
        loan_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN)] }],
        loan_type_other: ['', { updateOn: 'blur', validators: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN)] }],
        account_number: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(20), Validators.pattern(ALPHA_NUMERIC_WITH_HYPHEN_PATTERN_NEW)] }],
        v_account_number: [''],
        financial_institution: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN)] }],
        loan_amount: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
        notes: ['', { updateOn: 'blur', validators: [Validators.maxLength(500)] }],
        type: [this.LIABILITY_TYPE.L],
        id: [''],
        user_name: ['', { updateOn: 'blur', validators: [Validators.minLength(3), Validators.maxLength(30), Validators.pattern(USER_NAME_WITH_SPECIAL_CHARACTERS_PATTERN)] }],
        password: [''],
        website: ['', { updateOn: 'blur', validators: [Validators.pattern(WEBSITE_PATTERN_NEW), Validators.maxLength(150)] }],
      }),
      creditCard: this.formBuilder.group({
        card_type_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
        card_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN)] }],
        other_type: ['', { updateOn: 'blur', validators: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN)] }],
        card_number: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(10), Validators.pattern(NUMBERS_ONLY_PATTERN_CARD)] }],
        exp_month: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(2), this.monthValidator.bind(this)] }],
        exp_year: ['', { updateOn: 'blur', validators: [Validators.required, this.yearValidator.bind(this)] }],
        card_issuer: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(20), Validators.pattern(ALPHABET_ONLY_PATTERN)] }],
        amount: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
        type: [this.LIABILITY_TYPE.C],
        id: [''],
        user_name: ['', { updateOn: 'blur', validators: [Validators.minLength(3), Validators.maxLength(30), Validators.pattern(USER_NAME_WITH_SPECIAL_CHARACTERS_PATTERN)] }],
        password: [''],
        website: ['', { updateOn: 'blur', validators: [Validators.pattern(WEBSITE_PATTERN_NEW), Validators.maxLength(150)] }],
      }),
    };
    // Define controls to trim whitespace
    const trimWhitespaceControls = [
      'loan_name', 'loan_type_other', 'financial_institution', 
      'user_name', 'card_issuer','card_name','website'
    ];

    // Subscribe to value changes for each form
    trimWhitespaceControls.forEach(controlName => {
      this.forms.otherLiability.get(controlName)?.valueChanges.subscribe(() =>
        this.validation.trimWhitespace(this.forms.otherLiability.get(controlName))
      );

      this.forms.creditCard.get(controlName)?.valueChanges.subscribe(() =>
        this.validation.trimWhitespace(this.forms.creditCard.get(controlName))
      );
    });
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlsKey
   */
  public getErrors(controlsKey: string): Array<string> {
    return this.validation.getErrors(controlsKey, this.forms[this.formKey]);
  }

  /**
   * month validation
   * @param {FormControl}control
   * @return {boolean}
   */
  monthValidator(control: FormControl): { [s: string]: boolean } {
    const enteredMonth = +(control.value);
    if (control.value == null || control.value == '') {
      return null;
    } else {
      if (enteredMonth > 12 || enteredMonth < 1) {
        return { isNotAllowed: true };
      } else {
        return null;
      }
    }
  }

  /**
   * year validation
   * @param {FormControl}control
   * @return {boolean}
   */
  yearValidator(control: FormControl): { [s: string]: boolean } {
    const enteredYear = +(control.value);
    if (control.value == null || control.value == '') {
      return null;
    } else {
      if (enteredYear < new Date().getFullYear()) {
        return { isNotAllowed: true };
      } else {
        return null;
      }
    }
  }

  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }

  /**
   * setter for editing bank details
   *
   * @param {any} data
   */
  @Input() set editData(data: any) {
    if (data?.id && data.liabilityData) {
      this.liabilityType = data.type;
      const selectedForm = this.forms[this.formKey];
      this.liabilityTypeId = data.liabilityData.id;
      selectedForm?.patchValue(data);
      if (data?.type === this.LIABILITY_TYPE.L) {
        selectedForm.patchValue({
          v_account_number: data?.account_number,
        });
      }
      if (data?.type === this.LIABILITY_TYPE.C) {
        const maskedValue = this.maskPipe.transform(data?.card_number, CREDIT_CARD_MASK);
        selectedForm.get('card_number')?.patchValue(maskedValue);
      }
      if (data?.other_type) {
        this.otherType = data?.other_type;
      }
      // this.valuableForm.get('price').patchValue(this.commonHelper.commaSeparateNumber(this.valuableForm.get('price').value)); // Commas
      this.isNewAsset = false; // If importing data, then this is not a new asset.
    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet(): { [p: string]: AbstractControl } {
    return this.forms[this.formKey].controls;
  }

  /**
   * Get credit card and liabilities type
   */
  public getCardLiabilityTypeDetails(): void {
    forkJoin([this.commonService.getLiabilityTypes(), this.commonService.getCreditCardTypes()])
      .subscribe((response: Array<APIResponseModel>) => {
        this.creditCardTypeArray = this.commonHelper.convertToOptionsFormat(response[1].data, 'id', 'type')
          .sort(this.commonHelper.compareDisplayText);
        this.liabilitiesTypeArray = this.commonHelper.convertToOptionsFormat(response[0]?.data, 'id', 'name')
          .sort(this.commonHelper.compareDisplayText);

        // Always place 'Other' last to make it easy to find.
        this.commonHelper.placeAtPointOfArray(this.liabilitiesTypeArray, (e) => e.displayText.toLowerCase() == 'other');
        this.commonHelper.placeAtPointOfArray(this.creditCardTypeArray, (e) => e.displayText.toLowerCase() == 'other');

        this.resetForm();

        this.setLockType = this.lockType;
      });
  }

  /**
   *
   * @param{string} control
   * @return{boolean}
   */
  public isVerified(control: string) {
    if (this.liabilityType === this.LIABILITY_TYPE.L) {
      const v_control = `v_${control}`;
      const formControl = this.formGet;
      if (!!formControl[control]?.value) {
        return formControl[control]?.value === formControl[v_control]?.value;
      }
      return true;
    }
  }

  /**
   * Option change emitter ( Liability Type )
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitterLiabilityType(selectedOption: SelectOptionsInterface): void {
    if (selectedOption) {
      this.liabilityTypeId = selectedOption.value;
      this.liabilityType = selectedOption.additionalDetails?.type || this.LIABILITY_TYPE.L;
      const selectedForm = this.forms[this.formKey];
      if (selectedOption?.additionalDetails?.name === 'Other') {
        selectedForm.get('loan_type_other').setValidators([Validators.required]);
      } else {
        selectedForm.get('loan_type_other').removeValidators([Validators.required]);
      }
      selectedForm.get('loan_type_other').updateValueAndValidity();
      if (selectedForm?.value?.id) return;
      this.resetForm(selectedOption.additionalDetails?.type);
    }
  }

  /**
   * Change listener for credit card type
   *
   * @param {SelectOptionsInterface} selectedOption
   * @return {void}
   */
  public optionChangeEmitterCreditCardType(selectedOption: SelectOptionsInterface): void {
    if (!selectedOption) return;
    this.forms.creditCard.patchValue({
      card_type_id: selectedOption.value,
    });
    if (selectedOption?.additionalDetails?.type === 'Other') {
      this.forms.creditCard.get('other_type').setValidators([Validators.required]);
    } else {
      this.forms.creditCard.get('other_type').removeValidators([Validators.required]);
    }
    this.forms.creditCard.get('other_type').updateValueAndValidity();
  }

  /**
   * close modal
   */
  public closeModal(emitValue?: Object): void {
    this.toggleModalEmitter.emit(emitValue);
    this.isNewAsset = true;
    if (!this.lockType) {
      this.liabilityTypeId = null;
      this.liabilityType = null;
      this.liabilityTypeTouched = false;
    }
    this.modalService.close('add-liabilities-modal');
    this.resetForm();
  }

  /**
   * Returns appropriate key form {@link forms} based on {@link liabilityType}.
   */
  get formKey(): string {
    return (this.liabilityType === this.LIABILITY_TYPE.L) ? 'otherLiability' : 'creditCard';
  }

  /**
   * add liabilities
   */
  public onSubmit(): void {
    let selectedForm = this.forms[this.formKey];
    this.canEdit && selectedForm.markAllAsTouched();
    if (!selectedForm.valid) {
      this.commonHelper.scrollToFirstInvalidControl(selectedForm);
      return; // return if the form is invalid
    }
    this.submitLoader = true;

    selectedForm = selectedForm.value;

    // backend SQL database requires notes to be non-null.
    selectedForm['notes'] = selectedForm['notes'] ?? '';

    // Remove commas for backend.
    this.currencyFields.forEach((formControl) => {
      if (selectedForm[formControl]) {
        selectedForm[formControl] = this.commonHelper.commaSeparateNumberClean(selectedForm[formControl], null);
      }
    });

    // Remove spaces from card number for backend.
    selectedForm.type === this.LIABILITY_TYPE.C ? selectedForm['card_number'] = selectedForm['card_number'].replace(/ /gi, '') : '';

    const payload: {} = {
      ...selectedForm,
      liability_type_id: this.liabilityTypeId,
      roletype: this.userType,
      ...(this.userType !== 'Consumer' ? { request_id: this.requestId } : {}),
    };
    this.assetService.storeLiabilityDetails(payload).subscribe((response: APIResponseModel) => {
      this.submitLoader = false;
      if (response.status) {
        if (this.userType === 'Consumer') {
          this.commonHelper.updateLocalstorageRequestStage(response.data);
          selectedForm.id ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();
        }

        this.closeModal({
          ...response.data,
          listStatus: (selectedForm.id) ? LIST_UPDATE : LIST_NEW,
        });
      }
    }, (exception: any) => {
      this.commonHelper.httpResponseHandler(exception?.error);
      this.submitLoader = false;
    });
  }

  /**
   * delete liabilities details
   */
  public deleteLiabilities(): void {
    // ACL check
    if (!this.permissions?.['delete']) {
      this.toastrService.info('You do not have permission to delete items for this client.');
      return;
    }

    const selectedForm = this.forms[this.formKey];
    this.submitLoaderDelete = true;
    const payload: {} = {
      is_delete: '1',
      id: selectedForm.value.id,
      roletype: this.userType,
      ...(this.userType !== 'Consumer' ? { request_id: this.requestId } : {}),
    };

    this.assetService.storeLiabilityDetails(payload).subscribe((response: APIResponseModel) => {
        if (response.status) {
          this.commonHelper.toastrDeleteSuccess();
          this.submitLoaderDelete = false;
          this.closeModal({
            id: selectedForm.id,
            listStatus: LIST_DELETE,
          });
        }
      }, (exception: any) => {
        this.commonHelper.httpResponseHandler(exception?.error)
        this.submitLoaderDelete = false;
      }
      , () => this.submitLoaderDelete = false);
  }

  /**
   * Reset liabilities form
   *
   * @param {string} type
   */
  public resetForm(type?: string): void {
    switch (type) {
      case this.LIABILITY_TYPE.C:
        this.forms.creditCard.reset({
          id: this.forms.otherLiability.value.id,
          type: this.LIABILITY_TYPE.C,
        });
        break;
      case this.LIABILITY_TYPE.L:
        this.forms.otherLiability.reset({
          id: this.forms.creditCard.value.id,
          type: this.LIABILITY_TYPE.L,
        });
        break;
      default: {
        this.forms.otherLiability.reset({ type: this.LIABILITY_TYPE.L });
        this.forms.creditCard.reset({
          type: this.LIABILITY_TYPE.C,
        });
      }
    }
  }

  /**
   * markAsLiabilityTouched
   *
   */
  public markAsLiabilityTouched() {
    this.liabilityTypeTouched = true;
  }

  /**
   * Accessor for commonHelper in template
   */
  get getCommonHelper(): CommonHelper {
    return this.commonHelper;
  }

  /**
   * Is valid if permissions are allowed && if the appropriate form is valid.
   */
  public isSubmitValid(): boolean {
    return (this.isNewAsset && this.permissions?.['add']) || (!this.isNewAsset && this.permissions?.['edit']);
  }

  /**
   * Whether the 'Other' field is being used for {@link business_organization_type_id}.
   */
  get useOtherCCType(): boolean {
    return this.forms.creditCard.get('card_type_id').value ===
      this.creditCardTypeArray.find((e) => e.displayText.toLowerCase() === 'other')?.value;
  }

  /**
   * Whether the 'Other' field is being used.
   */
  get useOther(): boolean {
    const useOther = this.liabilityTypeId ===
      this.liabilitiesTypeArray.find((e) => e.displayText.toLowerCase() === 'other')?.value;
    useOther ? this.formGet['loan_type_other'].setValidators(Validators.required) : this.formGet['loan_type_other'].clearValidators(); // Use Other type means required validation
    return useOther;
  }

  /**
   * If user can edit existing asset, or add a new asset.
   */
  get canEdit(): boolean {
    return this.commonHelper.canEditSection(this.isNewAsset, this.permissions)&& (this.hasPaidSubscription || this.clientHasPaidSubscription)
  }

  /**
   * Returns the {@link SelectOptionsInterface.displayText displayText} for the current liability type.
   * @private
   */
  private get getLiabilityTypeName(): string {
    return this.liabilitiesTypeArray.find((e) => e.value == this.liabilityTypeId)?.displayText;
  }

  /**
   * Placeholder for Description/Notes input. Dynamic based on liability type name.
   */
  get getDescriptionPlaceholder(): string {
    let typeName: string = this.getLiabilityTypeName.toLowerCase();
    typeName = (typeName == 'other' ? undefined : typeName) ?? 'liability';
    return `Enter details about the ${typeName}, financial institution, or any relevant information here.`;
  }
}
