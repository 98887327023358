import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { requirePasswordToBeCheckedValidator } from 'src/app/helper/customValidatorPassword.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { SlugService } from 'src/app/services/slug.service';
import { UserService } from 'src/app/services/user.service';
import { EMAIL_PATTERN, NAME_PATTERN, PHONE_PATTERN, PROFESSIONAL_ROLE_CONSTANTS, UNITED_KINGDOM } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../../../helper/validation.helper';


@Component({
  selector: 'app-professional-register',
  templateUrl: './professional-register.component.html',
})
/**
 * Professional Register Component
 */
export class ProfessionalRegisterComponent implements OnInit {
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;
  public readonly faEye = faEye;
  public readonly faEyeSlash = faEyeSlash;
  public readonly googleCaptchaKey = environment.GOOGLE_CAPTCHA_KEY;
  public readonly countryCode = environment.DEFAULT_COUNTRY_CODE;
  public readonly selectedCountry = environment.APP_DOMAIN;
  public readonly unitedKingdom: string = UNITED_KINGDOM;
  public paymentModes: Array<SelectOptionsInterface> = [];
  public professionalRegisterForm: FormGroup;
  public submitted: boolean;
  public togglePasswordView: boolean = false;
  public registerLoader: boolean = false;
  public isRegistered: boolean = false;
  public preDefinedLoader: boolean;
    // recaptcha validation
  public isRecaptchaValidationEnable: boolean = false;
  public captchaEnabled: boolean;
  public isCompany: boolean;
  public requestId: string;
  public menuActive: string;
  public defaultCountry: number;
  public advisorTypes: Array<any>;
  public professionalRoleTypes: Array<any>;
  public selectedCountryName: string = '';
  public orgLogo: string;
  public orgLogoPath: string;
  public tempProTypeText: string;
  public proTypeText: string;
  public toggleNewPasswordViewConfirm: boolean;
  public userPeopleId: string = '';
  public roleType: string = '';
  public toggleEmitterListener: boolean = false; // toggle listener
  public elementRefSet: boolean = false;
  public searchElementRef: ElementRef;
  // manager/partner
  public isManager: boolean = false;

  /**
   * @param{ElementRef}value
   */
  @ViewChild('search', { static: false })
  set setSearchElementRef(value: ElementRef) {
    this.searchElementRef = value;
    if (this.searchElementRef && !this.elementRefSet) { // if not elementRef set initial value for searchElement
      this.removePacContainer();
      this.initMap(this.defaultCountry);
      this.elementRefSet = true;
    }
  }

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private localStorage: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private peopleService: PeopleService,
    private addressHelper: AddressHelper,
    private slugService: SlugService,
    private slugInterceptorService: SlugInterceptorService,
    private localStorageService: LocalStorageService,
    private validation: ValidationHelper,
    private ngZone: NgZone,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.commonHelper.setDomainCountryInLocal(this.selectedCountry).subscribe((data) => {
      this.defaultCountry = Number(data);
      this.removePacContainer();
      this.initMap(data);
    });
    this.commonHelper.logoutCommon(undefined, false);
    // Get token and id
    const queryParams = this.route?.snapshot?.queryParamMap;
    this.proTypeText = this.localStorageService.getDataByKey('pro-type')?.[0]?.['displayText'];
    this.tempProTypeText = this.route.parent.snapshot.params['pro-type'].replace(/-/g, ' ');
    // Suppose user accesses direct url instead of selecting professional type in /pro page, this code will get called
    if (this.tempProTypeText) {
      // this.tempProTypeText = this.route.parent.snapshot.params['pro-type'].replace('-', ' ');
      console.log(this.tempProTypeText);
      this.getAdvisorTypes(true);
    }
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      this.orgLogoPath = response ? this.commonHelper.getOrgLogo() : '';
      this.orgLogo = response ? `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}` : '';
    });
    this.requestId = this.route.snapshot.params['id'];
    this.defaultCountry = this.localStorage.getDataByKey('country');
    // If search element not loaded initially
    setTimeout(() => {
      this.initMap(this.defaultCountry);
    }, 2000);
    this.getAdvisorTypes();
    this.getProfessionalTypes();

    // register form
    this.professionalRegisterForm = this.formBuilder.group({
      'first_name': ['',
        {
          updateOn: 'blur', validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
          ],
        },
      ],
      'last_name': ['', {
        updateOn: 'blur', validators: [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
        ],
      }],
      'manager_term': ['', {
        updateOn: 'blur', validators: [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
        ],
      }],
      'email': ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(EMAIL_PATTERN)] }],
      'password': ['', [Validators.required, Validators.minLength(8), requirePasswordToBeCheckedValidator()]],
      'phone': ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(PHONE_PATTERN)] }],
      'g-recaptcha-response': ['', [this.isRecaptchaValidationEnable ? Validators.required : Validators.nullValidator]],
      'street_address': ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(255), this.validation.fn.trim] }],
      'city': ['', { updateOn: 'blur', validators: [Validators.required, this.validation.noWhitespaceValidator()] }],
      'country': [this.defaultCountry, { updateOn: 'blur', validators: [Validators.required] }],
      'state': [''],
      'zipcode': ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(10)] }],
      'professional_type': ['', { updateOn: 'blur', validators: [Validators.required] }],
      'role_id': ['', { updateOn: 'blur', validators: [Validators.required] }],
      // 'payment_mode': ['', { updateOn: 'blur', validators: [Validators.required] }],
      'middle_name': ['', {
        updateOn: 'blur', validators: [
          Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
          Validators.maxLength(50),
        ],
      }],
      'confirm_password': ['', [Validators.required]],
    });
    this.validation.setValidationErrors({
      city: { whitSpaceValue: 'Please enter valid character'}
    });
    const proType = this.localStorage.getDataByKey('pro-type')?.[0]?.value;
    this.professionalRegisterForm.patchValue({
      professional_type: proType,
    });

    // Set validator if domain country is not UK
    this.isEnableState();

    // if have id in url - get details
    if (queryParams) {
      this.getProfessionalDetails(queryParams.get('id'));
    }
    this.setRecaptchaValidation();
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.professionalRegisterForm.controls;
  }

  /**
   * @param{string}url
   * on menu change
   */
  public menuChange(url?: string) {
    this.menuActive = url;
    void this.slugInterceptorService.navigate(url ? [url] : ['/'], null, true);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }

  /**
   * get advisor types
   * @param{boolean}isForProType
   */
  public getAdvisorTypes(isForProType?: boolean) {
    this.peopleService.getAdvisorTypes()?.subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.advisorTypes = this.commonHelper.convertToOptionsFormat(response?.data, 'id', 'name');

        // this block will get called  if we want to retrieve professional type for registration purposes
        if (isForProType) {
          const seletedProType = this.advisorTypes.filter((cur) => cur.displayText.toLowerCase() === this.tempProTypeText);
          this.localStorage.storeData('pro-type', seletedProType);
          const proType = seletedProType[0].value;
          this.professionalRegisterForm.patchValue({
            professional_type: proType,
          });
        } else { // just get the payment method
          // this.paymentModes = this.commonHelper.convertToOptionsFormat(PAYMENT_MODE, 'id', 'name');
        }
      }
    });
  }

  /**
   * get professional role types
   */
  public getProfessionalTypes() {
    this.peopleService.getProfessionalTypes(1)?.subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.professionalRoleTypes = this.commonHelper.convertToOptionsFormat(response?.data.role, 'id', 'role_name');
        const roleIndex = this.professionalRoleTypes?.findIndex((el) => el?.displayText === this.roleType); // find advisor type
        this.professionalRegisterForm.patchValue({
          country: this.defaultCountry,
          role_id: this.professionalRoleTypes[roleIndex]?.value,
        });
      }
    });
  }

  /**
   * Option change emitter listner
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitterPaymentType(selectedOption: SelectOptionsInterface): void {
    if (selectedOption?.value) {
      this.professionalRegisterForm.patchValue({
        payment_mode: selectedOption?.value,
      });
      if (this.toggleEmitterListener) {
        this.professionalRegisterForm.get('payment_mode').markAsUntouched();
        this.toggleEmitterListener = false;
      }
    } else {
      if (this.toggleEmitterListener) {
        this.professionalRegisterForm.get('payment_mode').markAsTouched();
        this.toggleEmitterListener = false;
      }
    }
  }

  /**
   * Option change emitter listner
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitterProfessionalType(selectedOption: SelectOptionsInterface): void {
    if (selectedOption) {
      this.professionalRegisterForm.patchValue({
        role_id: selectedOption.value,
      });
    }
    this.isCompany = selectedOption.displayText === 'Professional';
    this.isManager = selectedOption.additionalDetails?.role_type === 3;
    // Clear validators for both fields initially
    this.professionalRegisterForm.get('last_name').clearValidators();
    this.professionalRegisterForm.get('manager_term').clearValidators();

    if (this.isCompany) {
      // No validators for last_name and manager_term if it's a company
    } else {
      // Add validators for last_name if it's not a company
      this.professionalRegisterForm.get('last_name').setValidators([
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
      ]);

      if (this.isManager) {
        // Add validators for manager_term if it's a manager
        this.professionalRegisterForm.get('manager_term').setValidators([
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
        ]);
      }
    }

    // update the form controls' validity
    this.professionalRegisterForm.get('last_name').updateValueAndValidity();
    this.professionalRegisterForm.get('manager_term').updateValueAndValidity();
  }

  /**
   * Register user
   *
   *  @param {any} captchaRef for reseting the captcha field
   */
  public registerUser(captchaRef?: any): void {
    if (!this.professionalRegisterForm.valid) {
      this.professionalRegisterForm.markAllAsTouched();
      return;
    }

    if (!this.checkPasswordMatch()) {
      return;
    } // check password match
    this.registerLoader = true;
    if (this.requestId) {
      if (this.isCompany) {
        this.professionalRegisterForm.value.last_name = '';
      }
      const payLoad = {
        ...this.professionalRegisterForm.value,
        referer_id: this.requestId,
        is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
        country_code: this.countryCode.dialCode,
        flag_code: this.countryCode.code,
      };
      payLoad.state = this.selectedCountryName == this.unitedKingdom ? null : payLoad.state;

      this.commonService.getCityDetail(payLoad.country, payLoad.state, payLoad.city).subscribe((cResponse: APIResponseModel) => {
        if (cResponse?.status) {
          payLoad.city = cResponse?.data?.id;
          this.userService.professionalRegister(payLoad).subscribe((response: APIResponseModel) => {
            this.registerLoader = false;
            this.isRegistered = true;
            this.toastrService.success(response.message);
            void this.slugInterceptorService.navigate(['login'], null, true);
            this.captchaReset(captchaRef);
          }, (exception: any) => {
            this.registerLoader = false;
            this.isRegistered = false;
            this.commonHelper.httpResponseHandler(exception?.error);
            this.captchaReset(captchaRef);
          },
          );
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error), () => {
      });
    } else {
      if (this.isCompany) {
        this.professionalRegisterForm.value.last_name = '';
      }
      const payLoad = {
        ...this.professionalRegisterForm.value,
        country_code: this.countryCode.dialCode,
        flag_code: this.countryCode.code,
        is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
      };
      payLoad.state = this.selectedCountryName == this.unitedKingdom ? null : payLoad.state;

      this.commonService.getCityDetail(payLoad.country, payLoad.state, payLoad.city).subscribe((cResponse: APIResponseModel) => {
        if (cResponse?.status) {
          payLoad.city = cResponse?.data?.id;
          this.userService.professionalRegister(payLoad).subscribe((response: APIResponseModel) => {
            this.registerLoader = false;
            this.isRegistered = true;
            this.toastrService.success(response.message);
            void this.slugInterceptorService.navigate(['login'], null, true);
            this.captchaReset(captchaRef);
          }, (exception: any) => {
            this.registerLoader = false;
            this.isRegistered = false;
            this.commonHelper.httpResponseHandler(exception?.error);
            this.captchaReset(captchaRef);
          },
          );
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error), () => {
      });
    }
  }

  /**
   * resolve captcha google
   *
   * @param {string} captchaResponse
   */
  public resolvedCaptcha(captchaResponse: string): void {
    if (!captchaResponse) return;
    this.professionalRegisterForm.patchValue({
      'g-recaptcha-response': captchaResponse,
    });
  }

  /**
   * catch error captcha google
   *
   * @param {string} errorDetails
   */
  public onErrorCaptcha(errorDetails: RecaptchaErrorParameters): void {
    console.log(errorDetails);
  }

  /**
   * reset captcha
   *
   * @param {any} captchaRef
   */
  public captchaReset(captchaRef: any): void {
    captchaRef.reset();
    this.professionalRegisterForm.get('g-recaptcha-response').reset();
  }

  /**
   * Initialize google map api by country
   * @param {any} countryId
   */
  public initMap(countryId: any): void {
    console.log('initMap countryID: ', countryId);
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: (r) => {
        this.ngZone.run(() => {
          this.professionalRegisterForm.patchValue({ ...r, street_address: r.address });
        });
      },
      error: (e) => console.error(e),
    });
  }

  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.professionalRegisterForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }

  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry) return;
    this.professionalRegisterForm.controls.state.reset();
    this.professionalRegisterForm.patchValue({
      country: selectedCountry.value,
    });
    this.removePacContainer();
    this.initMap(selectedCountry.value);
  }

  /**
   * remove pac container
   *
   */
  public removePacContainer() {
    this.addressHelper.removeAutocompletePacContainer();
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.professionalRegisterForm.controls.city.reset();
    this.professionalRegisterForm.patchValue({
      state: selectedState.value,
    });
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.professionalRegisterForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.professionalRegisterForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(selectedCity.additionalDetails[0]?.postal_code_regex))]);
      zipCodeRef.updateValueAndValidity();
    }
    // this loader is set false here because of the delay to set country state city
    this.preDefinedLoader = false;
  }

  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }

  /**
   * getter for state
   */
  get state() {
    return this.professionalRegisterForm.get('state');
  }

  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.professionalRegisterForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ?
        this.state.addValidators([Validators.required]) : this.state.clearValidators();
      this.state.updateValueAndValidity();
    });
  }

  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.professionalRegisterForm.get('zipcode');
    const stateId = this.professionalRegisterForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        if (response?.data?.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(zipCodeRegex))]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        } else {
          zipCodeRef.setValidators([Validators.required]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }

  /**
   *check password match
   * @return{boolean}
   */
  public checkPasswordMatch(): boolean {
    return this.professionalRegisterForm.controls?.['password']?.value == this.professionalRegisterForm.controls?.['confirm_password']?.value;
  }

  /**
   * get user people details - user people not registered as professional
   * @param {string} data
   */
  public getProfessionalDetails(data: string) {
    this.userPeopleId = data;
    // get user people detail
    this.peopleService.getUserPeopleDetail(data).subscribe((response: APIResponseModel) => {
      console.log(response);
      this.roleType = response?.data?.advisor === 1 ? PROFESSIONAL_ROLE_CONSTANTS.ADVISOR_ROLE : '';
      // Remove manage_term if user is not Manager/Partner
      if (response?.data?.advisor !== 3) {
        this.professionalRegisterForm.get('manager_term').clearValidators();
        this.professionalRegisterForm.get('manager_term').updateValueAndValidity();
      }

      this.defaultCountry = response?.data?.country?.id ?? this.defaultCountry;
      this.professionalRegisterForm.patchValue({
        ...response?.data,
        city: response?.data?.city?.id,
        country: this.defaultCountry,
        state: response?.data?.state?.id,
        street_address: response?.data?.address,
      });

      setTimeout(() => {
        this.removePacContainer();
        this.initMap(this.defaultCountry);
      }, 2000);
    }, (exception: any) => {
      this.commonHelper.httpResponseHandler(exception?.error);
    },
    );
  }

  /**
   * toggle emitter
   * @param {boolean} data
   */
  public toggleEmitter(data: boolean) {
    if (data) {
      this.toggleEmitterListener = true; // toggle listener
    }
  }

  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }

 /**
 * Sets up the reCAPTCHA validation for the  form.
 * Fetches the reCAPTCHA configuration and sets the validation accordingly.
 */
  public setRecaptchaValidation(): void {
    this.commonHelper.getCaptchaConfig().subscribe({
      next: (response) =>{
        this.isRecaptchaValidationEnable = response?.data['is_enabled'];
        this.professionalRegisterForm.get('g-recaptcha-response').setValidators(this.isRecaptchaValidationEnable ? Validators.required : Validators.nullValidator);
      }
    })
  }
}
