<app-common-modal id="{{modal_id}}" class="app-common-modal" [heightClass]="'min-h-75'">
  <!-- BEGIN: Modal -->
  <div class="title-modal">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()" />
    <div class="mb-1">
      <div class="col-span-12">
        <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
          Insurance Detail
        </h4>
        <p class="text-grey text-regular pt-1 text-sm">
          Start by adding Insurance.
        </p>
        <p class="text-regular font-medium text-xs mt-2 error-alert"
          *ngIf="(isNewAsset && !permissions?.['add']) || (!isNewAsset && !permissions?.['edit'])">
          You do not have permission to
          {{isNewAsset ? 'add information for this client.' : 'edit this client\'s information.'}}
        </p>
      </div>
    </div>
  </div>
  <div class="col-span-12 relative" (click)="canAccessForm ? markAsTouchedInsuranceType() : ''">
    <label for="insurance-type" class="text-label-grey text-sm text-regular">Insurance Type*</label>

    <app-select-with-search id="insurance-type" [options]="insuranceTypes"
      [disabled]="!canEdit || lockType || !canAccessForm" [placeholderValue]="'Select Insurance Type'"
      (optionChangeEmitter)="optionChangeEmitterInsuranceType($event)" [defaultSelected]="insuranceTypeId" tabindex="0"
      (focusout)="canAccessForm ? markAsTouchedInsuranceType() : ''">
    </app-select-with-search>
  </div>
  <form [formGroup]="insuranceForm" autocomplete="off" (ngSubmit)="onSubmit()">
    <div [ngSwitch]="insuranceTypeNumber">
      <div *ngSwitchCase="1">
        <div formGroupName="longTermCare">
          <div class="grid grid-cols-12 gap-4 mt-4">
            <div class="col-span-12 lg:col-span-6">
              <label for="company_name" class="text-label-grey text-sm text-regular">
                Name of Insurance Company*
              </label>

              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                name="company_name" id="company_name" formControlName="company_name" placeholder="Insurance Provider"
                [ngClass]="
                    formGetLTC['company_name'].errors &&
                    formGetLTC['company_name'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " />
              <p *ngFor="let error of getErrors('company_name')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <label for="owner" class="text-label-grey text-sm text-regular">Owner Name</label>

              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                name="owner" id="owner" formControlName="owner" placeholder="Full Name" [ngClass]="
                    formGetLTC?.['owner'].errors && formGetLTC?.['owner'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " />
              <p *ngFor="let error of getErrors('owner')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>

            <div class="col-span-12 grid grid-cols-2 gap-4">
              <div class="relative {{otherTypeSelected ? 'col-span-1' : 'col-span-2'}}"
                (click)="setAndUpdateMandatoryValidation(1,'insured_person_id')">
                <label class="text-label-grey text-sm text-regular">Insured Person*</label>
                <app-select-with-search [options]="userPeopleList" [disabled]="!canEdit" [id]="'insured_person_id'"
                  [placeholderValue]="'Select person'" (optionChangeEmitter)="listenerOptionChangeInsuredPerson($event)"
                  [defaultSelected]="formGetLTC?.['insured_person_id']?.value" [ifError]="formGetLTC['insured_person_id'].errors &&
                    formGetLTC['insured_person_id'].touched"
                  (focusout)="formGetLTC['insured_person_id'].markAsTouched()">
                </app-select-with-search>
                <p *ngFor="let error of getErrors('insured_person_id')"
                  class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="{{otherTypeSelected ? 'col-span-1' : 'col-span-2'}}" *ngIf="otherTypeSelected">
                <label for="owner" class="text-label-grey text-sm text-regular">Other Name*</label>

                <input type="text" [attr.disabled]="!canEdit || undefined"
                  class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                  name="insured_person" formControlName="insured_person" placeholder="Full Name" [ngClass]="
                    formGetLTC?.['insured_person'].errors &&
                    formGetLTC?.['insured_person'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " (input)="checkPolicyNumber($event, 2)" />
                <p *ngFor="let error of getErrors('insured_person')"
                  class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
            <div class="col-span-12">
              <label for="joint_insured" class="text-label-grey text-sm text-regular">Joint Insured Name</label>
              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                placeholder="Full Name" name="joint_insured" id="joint_insured" formControlName="joint_insured"
                (input)="onChangeInput($event, 'joint_insured')" [ngClass]="
                    formGetLTC?.['joint_insured'].errors &&
                    formGetLTC?.['joint_insured'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " />
              <p *ngFor="let error of getErrors('joint_insured')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12">
              <label for="policy_number" class="text-label-grey text-sm text-regular">Policy Number</label>
              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                placeholder="e.g 356459557" name="policy_number" id="policy_number" formControlName="policy_number"
                [ngClass]="
                    formGetLTC?.['policy_number'].errors &&
                    formGetLTC?.['policy_number'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " (input)="checkPolicyNumber($event, 1)" />
              <p *ngFor="let error of getErrors('policy_number')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>

            <div class="col-span-12 mt-4">
              <label class="flex items-center gap-3">
                <input type="checkbox" class="form-checkbox h-5 w-5 text-darkblue focus:outline-none col-span-1"
                  formControlName="has_sole_ownership" (change)="onchangeOwnership($event)">
                <p class="text-regular text-lg text-darkblue font-semibold col-span-11">
                  I do NOT have sole ownership of this Policy
                </p>
              </label>
            </div>
            <div *ngIf="formGetLTC?.['has_sole_ownership']?.value" class="col-span-12">
              <label for="joint_ownership_name" class="text-label-grey text-sm text-regular">Joint Owner Name*</label>
              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                placeholder="e.g Liberty Mutual" name="joint_ownership_name" id="joint_ownership_name"
                formControlName="joint_ownership_name" (focusout)="onFocusout($event, 'joint_ownership_name')"
                (input)="onChangeInput($event, 'joint_ownership_name')" [ngClass]="
                    formGetLTC?.['joint_ownership_name'].errors &&
                    formGetLTC?.['joint_ownership_name'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " />
              <p *ngFor="let error of getErrors('joint_ownership_name')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12">
              <label for="notes" class="text-label-grey text-sm text-regular">Note</label>
              <textarea rows="4" cols="50"
                class="input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                name="notes" formControlName="notes" placeholder="e.g Please add..." [ngClass]="
                  formGetLTC?.['notes'].errors && formGetLTC?.['notes'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  ">
                </textarea>
              <p *ngFor="let error of getErrors('notes')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 mt-4">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Insurance Agent Details
              </h4>
            </div>

            <div class="col-span-12 lg:col-span-6">
              <label for="insurance_agent_name" class="text-label-grey text-sm text-regular">Insurance Agent
                Name</label>
              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                placeholder="Full Name" name="insurance_agent_name" id="insurance_agent_name"
                formControlName="insurance_agent_name" [ngClass]="
                    formGetLTC?.['insurance_agent_name'].errors &&
                    formGetLTC?.['insurance_agent_name'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " />
              <p *ngFor="let error of getErrors('insurance_agent_name')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <label for="insurance_agent_contact" class="text-label-grey text-sm text-regular">Agent Contact
                Info</label>
              <app-phone-input id="insurance_agent_contact" [placeholder]="'e.g. 8665044754'"
                [phoneNumber]="insuranceForm['controls'].longTermCare.value?.['insurance_agent_contact']"
                [countryCode]="countryCode" [uniqueId]="'phone-insurance'" [disabled]="!canEdit"
                [errorClassTrigger]="formGetLTC?.['insurance_agent_contact'].errors && formGetLTC?.['insurance_agent_contact'].touched"
                [form]="getSelectedForm" controlKey="insurance_agent_contact"
                (onChange)="detectChangePhoneNumber($event)"
                (focusout)="formGetLTC?.['insurance_agent_contact'].markAsTouched()">
              </app-phone-input>
              <p *ngFor="let error of getErrors('insurance_agent_contact')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
          </div>
          <div class="flex flex-col-reverse sm:flex-row gap-4 justify-between items-center w-full sm:w-auto mt-8">
            <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewAsset" [buttonText]="'Remove Insurance'"
              [valid]="!submitLoader" [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true"
              (click)="deleteInsurance()">
            </app-button-loader>
            <app-button-loader class="w-full sm:w-auto"
              [buttonText]="!isNewAsset ? 'Update Insurance' : 'Add Insurance'"
              [valid]="((isNewAsset && permissions?.['add']) || (!isNewAsset && permissions?.['edit'])) && !submitLoaderDelete"
              [loader]="submitLoader" [buttonType]="'submit'"></app-button-loader>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="2">
        <div formGroupName="lifeInsurance">
          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
            <div class="col-span-12">
              <label for="company_name" class="text-label-grey text-sm text-regular">Name of Insurance
                Company*</label>

              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                name="company_name" formControlName="company_name" placeholder="Insurance Provider" [ngClass]="
                    formGetLifeInsurance['company_name'].errors &&
                    formGetLifeInsurance['company_name'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " />
              <p *ngFor="let error of getErrors('company_name')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12">
              <label for="owner" class="text-label-grey text-sm text-regular">Owner Name</label>

              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                name="owner" formControlName="owner" placeholder="Full Name" [ngClass]="
                    formGetLifeInsurance?.['owner'].errors &&
                    formGetLifeInsurance?.['owner'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " />
              <p *ngFor="let error of getErrors('owner')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="relative {{otherTypeSelected ? 'col-span-6' : 'col-span-12'}}"
              (click)="setAndUpdateMandatoryValidation(2,'insured_person_id')">
              <label class="text-label-grey text-sm text-regular">Insured Person*</label>
              <app-select-with-search [options]="userPeopleList" [disabled]="!canEdit" [id]="'insured_person_id'"
                [placeholderValue]="'Select person'" (optionChangeEmitter)="listenerOptionChangeInsuredPerson($event)"
                [defaultSelected]="formGetLifeInsurance?.['insured_person_id']?.value" [ifError]="formGetLifeInsurance['insured_person_id'].errors &&
                  formGetLifeInsurance['insured_person_id'].touched"
                (focusout)="formGetLifeInsurance['insured_person_id'].markAsTouched()">
              </app-select-with-search>
              <p *ngFor="let error of getErrors('insured_person_id')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="{{otherTypeSelected ? 'col-span-6' : 'col-span-12'}}" *ngIf="otherTypeSelected">
              <label for="owner" class="text-label-grey text-sm text-regular">Other Name*</label>

              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                name="insured_person" formControlName="insured_person" placeholder="Full Name" [ngClass]="
                    formGetLifeInsurance?.['insured_person'].errors &&
                    formGetLifeInsurance?.['insured_person'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " (input)="checkPolicyNumber($event, 2)" />
              <p *ngFor="let error of getErrors('insured_person')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>

            <div class="col-span-12 lg:col-span-6">
              <label for="policy_number" class="text-label-grey text-sm text-regular">Policy Number</label>
              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                placeholder="e.g 356459557" name="policy_number" formControlName="policy_number" [ngClass]="
                    formGetLifeInsurance?.['policy_number'].errors &&
                    formGetLifeInsurance?.['policy_number'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " />
              <p *ngFor="let error of getErrors('policy_number')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-6 relative">
              <label for="policy_type" class="text-label-grey text-sm text-regular">Type of Policy*</label>
              <app-select-with-search id="policy_type" [options]="policyTypes" [placeholderValue]="'Select Policy '"
                [disabled]="!canEdit" (optionChangeEmitter)="optionChangeEmitterPolicyType($event)"
                [defaultSelected]="insuranceForm['controls']?.lifeInsurance?.value?.['insurance_policy_type_id']">
              </app-select-with-search>
            </div>
            <div class="col-span-12 mt-6">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Insurance Premium
              </h4>
            </div>
            <div class="col-span-12">
              <label for="lump_sum" class="text-label-grey text-sm text-regular">Lump Sum</label>
              <div class="relative flex items-center">
                <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
                <input type="text" [attr.disabled]="!canEdit || undefined" appCurrencyInput
                  class="price-input h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                  placeholder="e.g. 1000" formControlName="lump_sum" name="lump_sum" id="lump_sum" [ngClass]="
                      formGetLifeInsurance?.['lump_sum'].errors &&
                      formGetLifeInsurance?.['lump_sum'].touched
                        ? 'form-input-error'
                        : 'form-input-focus'
                    " />
              </div>
              <p *ngFor="let error of getErrors('lump_sum')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <label [for]="'start_date'" class="text-label-grey text-sm text-regular">
                Start Date ( {{domainDateFormat}} )
              </label>
              <app-datepicker-toggle inputID="start_date" class="mt-1" [bsConfig]="bsConfig" [disabled]="!canEdit"
                [useFormControl]="formGetLifeInsurance.start_date">
              </app-datepicker-toggle>
              <p *ngFor="let error of getErrors('start_date')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <label [for]="'end_date'" class="text-label-grey text-sm text-regular">End Date ( {{domainDateFormat}}
                )</label>
              <app-datepicker-toggle inputID="end_date" class="mt-1"
                (focusout)="forceValidation(formGetLifeInsurance?.['start_date'])" [bsConfig]="bsConfigEndDate"
                (onClose)="datePickerCloseEventListener($event)"
                [disabled]="!canEdit" [useFormControl]="formGetLifeInsurance.end_date">
              </app-datepicker-toggle>
              <p *ngFor="let error of getErrors('end_date')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12">
              <label for="death_benefit" class="text-label-grey text-sm text-regular">Death Benefit</label>
              <div class="relative flex items-center">
                <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
                <input type="text" [attr.disabled]="!canEdit || undefined"
                  class="price-input h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                  placeholder="e.g. 1000" formControlName="death_benefit" name="death_benefit" id="death_benefit"
                  appCurrencyInput [ngClass]="
                      formGetLifeInsurance?.['death_benefit'].errors &&
                      formGetLifeInsurance?.['death_benefit'].touched
                        ? 'form-input-error'
                        : 'form-input-focus'
                    " />
              </div>
              <p *ngFor="let error of getErrors('death_benefit')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <!--BEGIN: Add Beneficiaries section -->

            <div class="col-span-12 mt-6">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide mb-2">
                Add Beneficiaries
              </h4>
              <p class="text-grey text-regular mb-2 text-sm">
                This information should match what is in your last will. It is important that should this information
                change, you update this record
              </p>
              <app-select-beneficiary-ratio [editData]="selectedBeneficiaries" [canEdit]="canAccessForm && !isDisabled"
                (selectBeneficiaryRatioEmitter)="selectBeneficiaryRatioListener($event)">
              </app-select-beneficiary-ratio>
            </div>
            <!--END: Add Beneficiaries section -->

            <div class="col-span-12 mt-6">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Insurance Agent Details
              </h4>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <label for="insurance_agent_name" class="text-label-grey text-sm text-regular">Insurance Agent
                Name</label>
              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                placeholder="Full Name" name="insurance_agent_name" formControlName="insurance_agent_name" [ngClass]="
                    formGetLifeInsurance?.['insurance_agent_name'].errors &&
                    formGetLifeInsurance?.['insurance_agent_name'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " />
              <p *ngFor="let error of getErrors('insurance_agent_name')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <label for="insurance_agent_contact" class="text-label-grey text-sm text-regular">Agent Contact
                Info</label>

              <app-phone-input [placeholder]="'e.g. 8665044754'"
                [phoneNumber]="insuranceForm['controls'].lifeInsurance?.value?.['insurance_agent_contact']"
                [countryCode]="countryCode" [uniqueId]="'phone-insurance-two'"
                [errorClassTrigger]="formGetLifeInsurance?.['insurance_agent_contact'].errors && formGetLifeInsurance?.['insurance_agent_contact'].touched"
                [form]="getSelectedForm" controlKey="insurance_agent_contact"
                (onChange)="detectChangePhoneNumber($event)"
                (focusout)="formGetLifeInsurance?.['insurance_agent_contact'].markAsTouched()">
              </app-phone-input>
              <p *ngFor="let error of getErrors('insurance_agent_contact')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
          </div>

          <div class="flex flex-col-reverse sm:flex-row gap-4 justify-between items-center w-full sm:w-auto mt-8">
            <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewAsset" [buttonText]="'Remove Insurance'"
              [valid]="!submitLoader" [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true"
              (click)="deleteInsurance()">
            </app-button-loader>
            <app-button-loader class="w-full sm:w-auto"
              [buttonText]="!isNewAsset ? 'Update Insurance' : 'Add Insurance'"
              [valid]="((isNewAsset && permissions?.['add']) || (!isNewAsset && permissions?.['edit'])) && !submitLoaderDelete"
              [loader]="submitLoader" [buttonType]="'submit'"></app-button-loader>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="3">
        <div formGroupName="annuities">
          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
            <div class="col-span-12 mt-2">
              <label for="owner" class="text-label-grey text-sm text-regular">Owner Name*</label>

              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                name="owner" formControlName="owner" placeholder="Full Name" [ngClass]="
                    formGetAnnuity?.['owner'].errors && formGetAnnuity?.['owner'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " />
              <p *ngFor="let error of getErrors('owner')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>

            <div class="col-span-12 grid grid-cols-2 gap-4">
              <div class="relative {{otherTypeSelected ? 'col-span-1' : 'col-span-2'}}"
                (click)="setAndUpdateMandatoryValidation(3,'insured_person_id')">
                <label class="text-label-grey text-sm text-regular">Insured Person*</label>
                <app-select-with-search [options]="userPeopleList" [disabled]="!canEdit" [id]="'insured_person_id'"
                  [placeholderValue]="'Select person'" (optionChangeEmitter)="listenerOptionChangeInsuredPerson($event)"
                  [defaultSelected]="formGetAnnuity?.['insured_person_id']?.value" [ifError]="formGetAnnuity['insured_person_id'].errors &&
                    formGetAnnuity['insured_person_id'].touched"
                  (focusout)="formGetAnnuity['insured_person_id'].markAsTouched()">
                </app-select-with-search>
                <p *ngFor="let error of getErrors('insured_person_id')"
                  class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="{{otherTypeSelected ? 'col-span-1' : 'col-span-2'}}" *ngIf="otherTypeSelected">
                <label for="owner" class="text-label-grey text-sm text-regular">Other Name*</label>

                <input type="text" [attr.disabled]="!canEdit || undefined"
                  class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                  name="insured_person" formControlName="insured_person" placeholder="Full Name" [ngClass]="
                    formGetAnnuity?.['insured_person'].errors &&
                    formGetAnnuity?.['insured_person'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " (input)="checkPolicyNumber($event, 2)" />
                <p *ngFor="let error of getErrors('insured_person')"
                  class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>

            <div class="col-span-12 lg:col-span-6">
              <label for="amount" class="text-label-grey text-sm text-regular">Amount Invested</label>
              <div class="relative flex items-center">
                <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
                <input type="text" [attr.disabled]="!canEdit || undefined"
                  class="price-input h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                  placeholder="e.g. 1000" formControlName="amount" name="amount" appCurrencyInput [ngClass]="
                      formGetAnnuity.amount.errors &&
                      formGetAnnuity.amount.touched
                        ? 'form-input-error'
                        : 'form-input-focus'
                    " />
              </div>
              <p *ngFor="let error of getErrors('amount')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <label for="market_value" class="text-label-grey text-sm text-regular">Market Value</label>
              <div class="relative flex items-center">
                <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
                <input type="text" [attr.disabled]="!canEdit || undefined"
                  class="price-input h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                  placeholder="e.g. 1000" formControlName="market_value" name="market_value" id="market_value"
                  appCurrencyInput [ngClass]="
                      formGetAnnuity?.['market_value'].errors &&
                      formGetAnnuity?.['market_value'].touched
                        ? 'form-input-error'
                        : 'form-input-focus'
                    " />
              </div>
              <p *ngFor="let error of getErrors('market_value')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12">
              <label for="benefit_base2" class="text-label-grey text-sm text-regular">Benefit Base</label>
              <div class="relative flex items-center">
                <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
                <input type="text" [attr.disabled]="!canEdit || undefined"
                  class="price-input h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                  placeholder="e.g. 1000" formControlName="benefit_base" name="benefit_base" id="benefit_base2"
                  appCurrencyInput [ngClass]="
                      formGetAnnuity?.['benefit_base'].errors &&
                      formGetAnnuity?.['benefit_base'].touched
                        ? 'form-input-error'
                        : 'form-input-focus'
                    " />
              </div>
              <p *ngFor="let error of getErrors('benefit_base')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12">
              <label for="income_benefit" class="text-label-grey text-sm text-regular">Income Benefit</label>
              <div class="relative flex items-center">
                <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
                <input type="text" [attr.disabled]="!canEdit || undefined"
                  class="price-input h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                  placeholder="e.g. 1000" formControlName="income_benefit" name="income_benefit" id="income_benefit"
                  appCurrencyInput [ngClass]="
                      formGetAnnuity?.['income_benefit'].errors &&
                      formGetAnnuity?.['income_benefit'].touched
                        ? 'form-input-error'
                        : 'form-input-focus'
                    " />
              </div>
              <p *ngFor="let error of getErrors('income_benefit')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12">
              <label for="death_benefit" class="text-label-grey text-sm text-regular">Death Benefit</label>
              <div class="relative flex items-center">
                <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
                <input type="text" [attr.disabled]="!canEdit || undefined" appCurrencyInput
                  class="price-input h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                  placeholder="e.g. 1000" formControlName="death_benefit" name="death_benefit" [ngClass]="
                      formGetAnnuity?.['death_benefit'].errors &&
                      formGetAnnuity?.['death_benefit'].touched
                        ? 'form-input-error'
                        : 'form-input-focus'
                    " />
              </div>
              <p *ngFor="let error of getErrors('death_benefit')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <!--BEGIN: Add Beneficiaries section -->
            <div class="col-span-12 mt-6">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide mb-2">
                Add Beneficiaries
              </h4>
              <p class="text-grey text-regular mb-2 text-sm">
                This information should match what is in your last will. It is important that should this information
                change, you update this record
              </p>
              <app-select-beneficiary-ratio [editData]="selectedBeneficiaries" [canEdit]="canAccessForm"
                (selectBeneficiaryRatioEmitter)="selectBeneficiaryRatioListener($event)">
              </app-select-beneficiary-ratio>
            </div>
            <!--END: Add Beneficiaries section -->

            <div class="col-span-12 mt-6">
              <div class="flex items-center justify-between gap-4">
                <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                  Spousal Continuation
                </h4>
                <app-toggle-switch [defaultSelected]="spousalContinuation" [options]="toggleOptions"
                  (optionChangeEmitter)="onChangeToggle($event)" [disabled]="submitLoader">
                </app-toggle-switch>
              </div>
            </div>

            <div class="col-span-12">
              <label for="notes" class="text-label-grey text-sm text-regular">Notes for special features and
                benefits</label>
              <textarea rows="4" cols="50"
                class="input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                name="notes" id="notes" formControlName="notes"
                placeholder="e.g Please note primary and secondary beneficiaries." [ngClass]="
                    formGetAnnuity?.['notes'].errors && formGetAnnuity?.['notes'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  ">
                </textarea>
              <p *ngFor="let error of getErrors('notes')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-col-reverse sm:flex-row gap-4 justify-between items-center w-full sm:w-auto mt-8">
          <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewAsset" [buttonText]="'Remove Insurance'"
            [valid]="!submitLoader" [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true"
            (click)="deleteInsurance()">
          </app-button-loader>
          <app-button-loader class="w-full sm:w-auto" [buttonText]="!isNewAsset ? 'Update Insurance' : 'Add Insurance'"
            [valid]="((isNewAsset && permissions?.['add']) || (!isNewAsset && permissions?.['edit'])) && !submitLoaderDelete"
            [loader]="submitLoader" [buttonType]="'submit'"></app-button-loader>
        </div>
      </div>
      <div *ngSwitchCase="4">
        <div formGroupName="otherInsurance">
          <div class="grid grid-cols-12 gap-4 mt-4">
            <div class="col-span-12">
              <label for="company_name" class="text-label-grey text-sm text-regular">
                Name of Insurance Company*
              </label>

              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                name="company_name" formControlName="company_name" placeholder="Insurance Provider" [ngClass]="
                    formOtherGet['company_name'].errors &&
                    formOtherGet['company_name'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " />
              <p *ngFor="let error of getErrors('company_name')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12" [ngClass]="{'lg:col-span-6' : isAuto}">
              <label for="amount" class="text-label-grey text-sm text-regular">Premium Amount</label>
              <div class="relative flex items-center">
                <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
                <input type="text" [attr.disabled]="!canEdit || undefined" appCurrencyInput
                  class="price-input h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                  placeholder="e.g. 1000" formControlName="amount" name="amount" id="amount" [ngClass]="
                      formOtherGet['amount'].errors && formOtherGet['amount'].touched
                        ? 'form-input-error'
                        : 'form-input-focus'
                    " />
              </div>
              <p *ngFor="let error of getErrors('amount')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12" [ngClass]="{'lg:col-span-6' : useInsuredPerson}">
              <label for="policy_number" class="text-label-grey text-sm text-regular">Policy Number</label>
              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                placeholder="e.g 356459557" name="policy_number" formControlName="policy_number" [ngClass]="
                    formOtherGet?.['policy_number'].errors &&
                    formOtherGet?.['policy_number'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " (input)="checkPolicyNumber($event,4)" />
              <p *ngFor="let error of getErrors('policy_number')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-6" *ngIf="useInsuredPerson && !isAuto">
              <label class="text-label-grey text-sm text-regular">Insured {{isAuto ? 'Vehicle' :
                'Person'}}</label>
              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                placeholder="{{isAuto ? 'e.g Honda Civic' : 'Full Name'}}" name="insured_vehicle"
                formControlName="insured_vehicle" [ngClass]="
                    formOtherGet?.['insured_vehicle'].errors &&
                    formOtherGet?.['insured_vehicle'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " [(ngModel)]="vehicleType" />
              <p *ngFor="let error of getErrors('insured_vehicle')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <label [for]="'start_date'" class="text-label-grey text-sm text-regular">
                Start Date ( {{domainDateFormat}} )
              </label>
              <app-datepicker-toggle inputID="start_date" class="mt-1" [bsConfig]="bsConfig" [disabled]="!canEdit"
                [useFormControl]="formOtherGet['start_date']">
              </app-datepicker-toggle>
              <p *ngFor="let error of getErrors('start_date')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <label [for]="'end_date'" class="text-label-grey text-sm text-regular">
                End Date ( {{domainDateFormat}} )
              </label>
              <app-datepicker-toggle inputID="end_date" class="mt-1"
                (focusout)="forceValidation(formOtherGet?.['start_date'])" [bsConfig]="bsConfigEndDate"
                [disabled]="!canEdit" [useFormControl]="formOtherGet['end_date']">
              </app-datepicker-toggle>
              <p *ngFor="let error of getErrors('end_date')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 mt-6">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Insurance Agent Details
              </h4>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <label for="insurance_agent_name" class="text-label-grey text-sm text-regular">Insurance Agent
                Name</label>
              <input type="text" [attr.disabled]="!canEdit || undefined"
                class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                placeholder="Full Name" name="insurance_agent_name" formControlName="insurance_agent_name" [ngClass]="
                    formOtherGet?.['insurance_agent_name'].errors &&
                    formOtherGet?.['insurance_agent_name'].touched
                      ? 'form-input-error'
                      : 'form-input-focus'
                  " />
              <p *ngFor="let error of getErrors('insurance_agent_name')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <label for="insurance_agent_contact" class="text-label-grey text-sm text-regular">Agent Contact
                Info</label>
              <app-phone-input [placeholder]="'e.g. 8665044754'"
                [phoneNumber]="insuranceForm['controls'].otherInsurance.value?.['insurance_agent_contact']"
                [countryCode]="countryCode" [uniqueId]="'phone-insurance-three'"
                [errorClassTrigger]="formOtherGet?.['insurance_agent_contact'].errors && formOtherGet?.['insurance_agent_contact'].touched"
                [disabled]="!canAccessForm" (onChange)="detectChangePhoneNumber($event)" [form]="getSelectedForm"
                controlKey="insurance_agent_contact"
                (focusout)="formOtherGet?.['insurance_agent_contact'].markAsTouched()">
              </app-phone-input>
              <p *ngFor="let error of getErrors('insurance_agent_contact')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-col-reverse sm:flex-row gap-4 justify-between items-center w-full sm:w-auto mt-8">
          <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewAsset" [buttonText]="'Remove Insurance'"
            [valid]="!submitLoader" [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true"
            (click)="deleteInsurance()">
          </app-button-loader>
          <app-button-loader class="w-full sm:w-auto" [buttonText]="!isNewAsset ? 'Update Insurance' : 'Add Insurance'"
            [valid]="(isNewAsset && permissions?.['add']) || (!isNewAsset && permissions?.['edit']) && !submitLoaderDelete"
            [loader]="submitLoader" [buttonType]="'submit'"></app-button-loader>
        </div>
      </div>
    </div>
  </form>
  <!-- END: Leftside Card -->
</app-common-modal>