<div class="bg-transparent border-card-grey rounded-xs mt-4 md:h-auto lg:h-20"
  *ngIf="preDefinedLoader || !isLoadComplete()">
  <div class="grid grid-cols-12 md:h-auto lg:h-20 items-end sm:items-center gap-2 sm:gap-4 p-2 lg:px-4">
    <div class="col-span-12 sm:col-span-2">
      <div class="flex sm:items-center sm:justify-center border-circle sm:mx-auto rounded-full h-12 w-12">
        <app-shimmer-loading [loading]="preDefinedLoader" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
      </div>
    </div>
    <div class="col-span-10 sm:col-span-8">
      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
        <h4 class="text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate">
        </h4>
      </app-shimmer-loading>
      <p class="text-grey text-regular text-sm truncate">
        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
          <span class="font-semibold"></span>
        </app-shimmer-loading>
      </p>
    </div>
    <div class="col-span-2">
      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
        <img src="assets/images/about-yourself/edit.svg" class="cursor-pointer w-4 sm:w-5 float-right cursor-pointer" alt="digital vault">
      </app-shimmer-loading>
    </div>
  </div>
</div>

<ng-container *ngIf="!preDefinedLoader && isLoadComplete()">
  <ng-container *ngIf="(canShowRetirementAssetList || !isAssetsView) else noDataAdded">
    <ng-container *ngIf="!this.permissions?.view">
      <p class="p-2 rounded-b-sm text-gray-700 background-color-grey mt-6">
        You do not have permission to view these items
      </p>
    </ng-container>
    <ng-container *ngIf="this.permissions?.view">
      <div class="bg-transparent border-card-grey rounded-xs mt-4 md:h-auto lg:h-20"
        *ngFor="let data of retirementAssetList">
        <div class="grid grid-cols-12 md:h-auto lg:h-20 items-end sm:items-center gap-2 sm:gap-4 p-2 lg:px-4">
          <div class="col-span-12 sm:col-span-2">
            <div class="flex sm:items-center sm:justify-center border-circle sm:mx-auto rounded-full h-10 w-10">
              <img src="assets/images/accounts-assets/default-bank.png" class="object-contain" alt="digital vault">
            </div>
          </div>
          <div class="col-span-10 sm:col-span-8">
            <!-- Need to work on this -->
            <h4 *ngIf="data.import_type == 1"
              class="text-md text-regular tracking-wide text-darkblue text-semibold truncate">
              {{ data?.['asset_name']?.length == 0 ? data?.['institution'] : data['asset_name'] }}
            </h4>
            <h4 *ngIf="data.import_type == 2"
              class="text-md text-regular tracking-wide text-darkblue text-semibold truncate">
              {{ data?.['account_name']}}
            </h4>
            <p class="text-grey text-regular text-sm truncate">
              <span class="font-semibold">{{data?.['account_number']}}</span> <span *ngIf="getSecondaryContent(data)"> {{ data?.['account_number'] ? '|' : ''}}  {{getSecondaryContent(data)}}</span>
            </p>
          </div>
          <div class="col-span-2">
            <img src="assets/images/about-yourself/edit.svg" [ngClass]="{'cursor-pointer':canAccessForm}"
              class="cursor-pointer w-4 sm:w-4 sm:w-5 float-right cursor-pointer mb-1" alt="digital vault" (click)="canAccessForm ?toggleModalEdit(data):''">
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noDataAdded>
    <p class="p-2 rounded-b-sm text-gray-700 background-color-grey mt-2">
      No Data Added
    </p>
  </ng-template>
</ng-container>
<app-add-retirement-asset-popup [editData]="editData"
  [clientHasPaidSubscription]="clientHasPaidSubscription"
  [permissions]="permissions" (toggleModalEmitter)="modalEventListener($event)"></app-add-retirement-asset-popup>
