<div class="can-toggle">
  <div class="flex items-center sm:justify-end">
    <button (click)="onChange()" type="button" [disabled]="disabled"
      class="text-white px-4 py-2 h-8 w-14 rounded-l text-regular uppercase text-sm focus:outline-none"
      [ngClass]="(selectedOption?.value === 1) ? 'bg-enable-toggle-switch' : 'bg-disable-toggle-switch'">
      YES
    </button>
    <button (click)="onChange()" type="button" [disabled]="disabled"
      class="text-white px-4 py-2 h-8 w-14 rounded-r text-sm text-regular uppercase focus:outline-none"
      [ngClass]="(selectedOption?.value === 0) ? 'bg-enable-toggle-switch' : 'bg-disable-toggle-switch'">
      NO
    </button>
  </div>
</div>
