<app-common-modal id="add-property-modal" class="app-common-modal" [heightClass]="'min-h-75'">
  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">
    <form [formGroup]="propertyForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Property Details
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">
            Document your property details, including ownership, title/ deed information and upload photos.
          </p>
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="!canEdit && !isConsumer">
            You do not have permission to
            {{isNewAsset ? 'add information for this client.' : 'edit this client\'s information.'}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="card-name" class="text-label-grey text-sm text-regular">Type of Property*</label>
          <app-select-component [options]="propertyTypes" [placeholder]="'Select property type'" id="property_type_id"
            (optionChangeEmitter)="optionChangeEmitterPropertyType($event)" [disabled]="!canEdit ||!canAccessForm "
            [selectedOptionDefault]="formGet?.property_type_id?.value"
            [ifError]="formGet.property_type_id.errors && formGet.property_type_id.touched"
            (focusout)="canAccessForm ? markAsTouchedIndividual('property_type_id') : ''"
            (toggleEmiter)="toggleEmiter($event)">
          </app-select-component>
          <p *ngFor="let error of getErrors('property_type_id')"
            class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="property-name" class="text-label-grey text-sm text-regular">Title / Ownership*</label>
          <input type="text" id="property-name" [attr.disabled]="!canEdit || undefined"
            class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
            name="property_name" formControlName="property_name" placeholder="e.g Jane L Smith"
            [ngClass]="formGet.property_name.errors && formGet.property_name.touched ? 'form-input-error' : 'form-input-focus'">
          <p *ngFor="let error of getErrors('property_name')" class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label class="text-grey text-sm text-regular mb-1">
            Mortgage Details
          </label>
          <div class="col-span-12 ">
            <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-7 relative">
              <app-select-with-search [options]="mortgageOptions" [defaultSelected]="selectedMortgageID"
                (optionChangeEmitter)="mortgageChangeListener($event)" [disabled]="!canEdit || !canAccessForm"
                [placeholderValue]="'Select Mortgage'" tabindex="0">
              </app-select-with-search>
            </div>
            <div class="col-span-12 md:col-span-5">
              <button type="button" [disabled]="disableMortgageView" (click)="clickEditMortgage()"
                class="flex items-center justify-center m-auto hover:underline-text text-sm md:text-base tracking-wide w-full bg-darkblue rounded-xs h-10 sm:h-12 focus:outline-none text-regular text-white py-3 px-6"
                [ngClass]="disableMortgageView ? 'opacity-50' : ''">
                <div class="loading-red mr-2" *ngIf="submitLoader"></div>
                View / Edit
              </button>
            </div>
            </div>
          </div>
        </div>
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Address
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">Make sure to enter your property's full
            address
          </p>
        </div>
        <div class=" col-span-12">
          <label for="country" class=" text-label-grey text-sm text-regular ">Country*</label>
          <div class=" col-span-12 lg:col-span-2 relative">
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <app-country-search-component id="country" (optionChangeEmitter)="optionChangeListenerCountry($event)"
                [disabled]="!canEdit || !canAccessForm" [defaultSelected]="formGet?.country?.value"
                [enableError]="formGet.country.errors && formGet.country.touched"
                (focusout)="markAsTouchedIndividual('country')">
              </app-country-search-component>
              <p *ngFor="let error of getErrors('country')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </app-shimmer-loading>
          </div>
        </div>
        <div class="col-span-12 relative move-map" #moveMapHere>
          <label for="street-add" class="text-label-grey text-sm text-regular">Street
            Address*</label>
          <input type="text" [attr.disabled]="!canEdit || undefined" id="street-add" #search
            (keyup)="onAddressChange(search)" (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null"
            class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
            name="address" formControlName="address" placeholder="e.g Main St" (input)="onFocusAddress()"
            [ngClass]="formGet.address.errors && formGet.address.touched?'form-input-error':'form-input-focus'"
            (focusout)="formGet.address.markAsTouched()">
          <p *ngFor="let error of getErrors('address')" class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>

        <div class="col-span-12">
          <label for="apt_number" class="text-label-grey text-sm text-regular">Apartment / Suite #</label>
          <input name="apt_number" id="apt_number" formControlName="apt_number" [attr.disabled]="!canEdit || undefined"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 [ngClass]="formGet.apt_number.errors && formGet.apt_number.touched ? 'form-input-error' : 'form-input-focus'">
          <p *ngFor="let error of getErrors('apt_number')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>

        <div class="col-span-12" *ngIf="selectedCountryName!==unitedKingdom">
          <label for="state-Province" class="text-label-grey text-sm text-regular">State/Province*</label>
          <div class="col-span-12 lg:col-span-2 relative">
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <app-state-search-component id="state-Province" [disabled]="!canEdit || !canAccessForm"
                [enableError]="formGet.state.errors && formGet.state.touched" [countryCode]="formGet?.country?.value"
                [defaultSelected]="formGet?.state?.value" (optionChangeEmitter)="optionChangeListenerState($event)"
                tabindex="0" (focusout)="markAsTouchedIndividual('state')">
              </app-state-search-component>
            </app-shimmer-loading>
            <p *ngFor="let error of getErrors('state')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <label for="city" class="text-label-grey text-sm text-regular">City*</label>
          <div class="col-span-12 lg:col-span-2 relative">
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <app-city-search id="city" [enableError]="formGet.city.errors && formGet.city.touched"
                [stateCode]="formGet?.state?.value" [defaultSelected]="formGet?.city?.value"
                [disabled]="!canEdit|| !canAccessForm" (optionChangeEmitter)="optionChangeListenerCity($event)"
                tabindex="0" (focusout)="markAsTouchedIndividual('city')">
              </app-city-search>
            </app-shimmer-loading>
            <p *ngFor="let error of getErrors('city')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>

        </div>
        <div class=" col-span-12 md:col-span-6 ">
          <label for="zipcode" class=" text-label-grey text-sm text-regular ">Zip/Postal Code*</label>
          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
            <input type="text" [attr.disabled]="!canEdit || undefined" id="zipcode"
              class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
              placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
              name="zipcode" formControlName="zipcode" oninput="this.value = this.value.toUpperCase()"
              (focus)="validateZipCode()"
              [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
          </app-shimmer-loading>
          <p *ngFor="let error of getErrors('zipcode')" class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <h4 class=" text-regular text-lg text-darkblue font-semibold tracking-wide ">
            Upload Property File
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">
            <span class="font-sans font-bold">Accepted File Formats</span> — jpeg, jpg, png, doc, docx, pdf
          </p>
          <p class="text-grey text-regular pt-1 text-sm">
            <span class="font-sans font-bold">Maximum File Size</span> — 50 MB.
          </p>
        </div>

        <div class="col-span-12">
          <app-file-listing
            [fileArray]="propertyFileArray"
            [permissions]="permissions"
            [removePrefix]="true"
            [isLoading]="preDefinedLoader"
            [isDisabled]="submitLoader || !canEdit"
            [fileUploadSettings]="fileUploadSettings"
            (fileListingEmitter)="fileListingListener($event)">
          </app-file-listing>
        </div>

        <div class="col-span-12">
          <label for="price" class="text-label-grey text-sm text-regular">Purchase Price</label>
          <div class="relative flex items-center">
            <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
            <input type="text" [attr.disabled]="!canEdit || undefined" id="price" appCurrencyInput
              class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 price-input"
              name="vehicle-value" formControlName="price" placeholder="e.g 1000"
              [ngClass]="formGet.price.errors && formGet.price.touched?'form-input-error':'form-input-focus'">
          </div>
          <p *ngFor="let error of getErrors('price')" class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="current_price" class="text-label-grey text-sm text-regular">Current Value</label>
          <div class="relative flex items-center">
            <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
            <input type="text" [attr.disabled]="!canEdit || undefined" id="current_price" appCurrencyInput
              class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 price-input"
              name="vehicle-value" formControlName="current_price" placeholder="e.g 1000"
              [ngClass]="formGet.current_price.errors && formGet.current_price.touched?'form-input-error':'form-input-focus'">
          </div>
          <p *ngFor="let error of getErrors('current_price')" class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
      </div>
      <div class=" flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 ">
        <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewAsset" [buttonText]="'Remove property'"
          [valid]="!submitLoader && canEdit" [loader]="submitLoader" [buttonType]="'button'" [outlineOnly]="true"
          (click)="deleteProperty()"></app-button-loader>
        <app-button-loader class="w-full sm:w-auto" [buttonText]="!isNewAsset?'Update Property':'Add Property'"
          [valid]="((isNewAsset && permissions?.['add']) || (!isNewAsset && permissions?.['edit'])) && !submitLoader && canEdit "
          [loader]="submitLoader" [buttonType]="'submit'"></app-button-loader>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>

<!-- Add liabilities popup modal -->
<app-add-liabilities-popup [editData]="mortgageEditData" [setLockType]="this.mortgageLiabilityType?.['id']"
  [clientHasPaidSubscription]="clientHasPaidSubscription"
  [permissions]="permissions" (toggleModalEmitter)="modalEventListener($event)">
</app-add-liabilities-popup>
