import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIResponseModel } from '../interface/response.interface';


@Injectable({
  providedIn: 'root',
})
/**
 * Received dlb services
 */
export class ReceivedDlbService {
  private readonly BASE_URL = environment.BASE_URL;
  private paymentStatus: any;
  public paymentStatusObserve: Observable<any>;
  private selectedUser: any;
  public selectedUserObserve: Observable<any>;

  /**
   * @constructor
   */
  constructor(private http: HttpClient) {
    // for resetting vehicle form
    this.paymentStatus = new BehaviorSubject(false);
    this.paymentStatusObserve = this.paymentStatus.asObservable();
    // select appropriate user
    this.selectedUser = new BehaviorSubject({});
    this.selectedUserObserve = this.selectedUser.asObservable();
  }

  /**
   * set user
   * @param{any}userData
   */
  public setUser(userData: any) {
    this.selectedUser.next(userData);
  }

  /**
   * Get Received Dlb List
   *
   * @return {Observable<APIResponseModel>}
   */
  public getReceivedDlbList(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/received-dlb-list`);
  }

  /**
   * @returns `true` if the Vault Transfer Fee has been paid for, `false` if it has not been paid.
   * @param reqID
   */
  public isTransferPaid(reqID: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.getReceivedDlbList().subscribe({
        next: r => {
          const dlbData = (r.data as any[]).find(e => e.user_request.id == reqID);
          observer.next(dlbData?.user_request.user?.is_tansfer_amount_paid == 1);
        },
        error: e => observer.error(e),
        complete: () => observer.complete(),
      });
    });
  }
  /**
   * @returns `true` if the Vault Transfer Fee has been paid for, `false` if it has not been paid.
   * @param reqID
   */
  public getRecievedDlbUser(reqID: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.getReceivedDlbList().subscribe({
        next: r => {
          const dlbData = (r.data as any[]).find(e => e.user_request.id == reqID);
          observer.next(dlbData?.user_request);
        },
        error: e => observer.error(e),
        complete: () => observer.complete(),
      });
    });
  }
}
