import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { currencyValidator } from '../validators/currency.validator';


@Directive({
  selector: '[appCurrencyInput]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CurrencyInputDirective,
    multi: true
  }]
})
export class CurrencyInputDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    return currencyValidator()(control);
  }
}
