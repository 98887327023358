<!-- BEGIN: Navbar Wrapper -->
<app-nav-landing-pro [showLoginBtn]="true"></app-nav-landing-pro>
<!-- END: Navbar Wrapper -->
<!-- END: Navbar Wrapper -->
<main>
  <div class="p-12 lg:px-24 lg:py-32 container mx-auto">
    <div class="title__wrap text-center py-8">
      <h1 class="text-semibold text-accent fs-title pb-2">LegacyNOW</h1>
      <h2 class="text-semibold text-primary fs-title">PRIVACY POLICY</h2>
    </div>
    <div>
      <p>
        This Website is operated in the United States by Estate Resolution Technologies (US) LLC, doing business as
        “LegacyNOW”. This Privacy Policy describes how we collect, use, and handle your personal information when you
        use our Website and/or Digital Vault Services.
      </p>
      <p>
        <b>
          PLEASE BE AWARE THAT BY USING OUR WEBSITE, YOU CONSENT, TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, TO THE
          COLLECTION, USE, STORAGE, TRANSFER AND DISCLOSURE OF THE PERSONAL INFORMATION YOU PROVIDE REGARDING YOURSELF
          AND YOUR ESTATE FOR THE PURPOSES SET OUT IN THIS PRIVACY POLICY.
        </b>
      </p>

      <h4>
        Scope
      </h4>
      <p>
        We are focused on protecting your privacy and that of your estate. This commitment is reflected in our internal
        policies, safeguards, and practices to ensure compliance with relevant laws and regulations with respect to the
        information entrusted to us. This Privacy Policy provides an overview of our business in the United States and
        describes our approach to the collection, use, storage, transfer, and disclosure of the personal information
        provided to us, including information regarding yourself and your estate. We collect, use, store, transfer and
        disclose in connection with our Digital Vault Services and as otherwise described in this Privacy Policy.
      </p>

      <h4>
        Definitions
      </h4>
      <p>
        In this Privacy Policy, we will use the following terms:
      </p>
      <ul class="list-disc">
        <li>
          “<b>us</b>”, “<b>we</b>”, “<b>our</b>” or “<b>Company</b>” refers to Estate Resolution Technologies (US) LLC,
          doing business as “LegacyNOW”.
        </li>
        <li>
          “<b>Customer</b>”, “<b>you</b>”, “<b>your</b>”, “<b>yourself</b>” refers to you as an individual, and includes
          any relative, next-of-kin, heir, beneficiary, executor, administrator, representative, advisor, beneficiary,
          heir or and Authorized Representative or other individual you have specifically authorized to have access to
          your Digital Information, Digital Vault, and to use our Digital Vault Services.
        </li>
        <li>
          “<b>Authorized Representative</b>” means any executor, administrator, vault custodian, financial advisor,
          estate planning profession, lawyer, accountant, family member or other individual to whom you grant any level
          of access to the Website, your Digital Vault or your Digital Information.
        </li>
        <li>
          “<b>Website</b>” means website we operated operate located at
          <a class="link" href="." target="_blank">https://www.legacynow.com</a>
          which we use to provide you with the Digital Vault Services. The term “<u>Website</u>” as used in this Privacy
          Policy refers solely to the United States version of the Company’s Website and does not include any other
          websites that are not hosted, owned and/or operated by any of the Company’s domestic or international
          affiliated companies, including, without limitation any website either owned or operated by the DNS Provider.
        </li>
        <li>
          “<b>Digital Vault</b>”, “<b>Digital Vault Account</b>”, or “<b>Account</b>” means the secure vault facility
          operated by us and accessible through the Website.
        </li>
        <li>
          “<b>Deceased Notification Services</b>” refers to the deceased notification registry and services provided by
          the DNS Provider.
        </li>
        <li>
          “<b>DNS Provider</b>” means Estate Resolution Technologies (US) LLC, doing business as “NotifyNOW”., or any
          other division or affiliate of the Company.
        </li>
      </ul>
      <p>
        Any capitalized term used in this policy that is not otherwise defined is understood to have the same meaning as
        in your <a>Digital Vault Agreement</a>.
      </p>

      <h4>
        Personal Information We Collect and Store
      </h4>
      <p>
        The personal information that we collect and store in your Digital Vault is composed of the information you have
        deposited or submitted in/ot your Digital Vault. This may contain the following information:
      </p>
      <ul class="list-disc">
        <li>
          <u>Personal Information</u>. We collect, and associate with your account, the information you provide to us
          when you register for our Digital Vault Services and open an account such as your name, email address, phone
          number, payment information, and physical address and other personal information you provide to us or that is
          provided to us by third-parties.
        </li>
        <li>
          <u>Usage information</u>. We collect information related to how you use our Digital Vault Services, including
          the actions you take in your Account (such as depositing, editing, updating, or withdrawing your Digital
          Information). We use this information to provide, improve, and promote our services, and protect our users.
        </li>
        <li>
          <u>Device information</u>. We also collect information from and about the devices you use to access our
          Digital Vault Services. This includes things like IP addresses, the type of browser and device you use, the
          web page you visited before coming to our sites, and identifiers associated with your devices. Your devices
          (depending on their settings) may also transmit location information to the Digital Vault Services. For
          example, we use device information to detect abuse and identify and troubleshoot bugs.
        </li>
        <li>
          <u>Cookies and other technologies</u>. We use technologies like cookies and pixel tags to provide, improve,
          protect, and promote our Digital Vault Services. For example, cookies help us with things like remembering
          your username for your next visit, understanding how you are interacting with our services, and improving them
          based on that information. You can set your browser to not accept cookies, but this may limit your ability to
          use the Services. We may also use third-party service providers that set cookies and similar technologies to
          promote our services.
        </li>
        <li>
          <u>Marketing</u>. If you register for our Services, we will, from time to time, send you information about
          upgrades when permissible. Users who receive these marketing materials can opt out at any time. If you don’t
          want to receive a particular type of marketing material from us, click the ‘unsubscribe’ link in the
          corresponding emails, or update your preferences in the appropriate section of the Website.
        </li>
      </ul>

      <h4>
        Your Digital Information
      </h4>
      <p>
        Our Services allows you to securely submit and store Digital Information in your Digital Vault on our Website.
        You will be able to access your Digital Vault via the Website and may add, update, modify or withdraw any
        Digital Information deposited in your Digital Vault at any time during the Term. All Digital Information that is
        added, updated, or modified in your Digital Vault will be held under the same terms and conditions as provided
        in this Privacy Policy and your
        <a >Digital Vault Agreement</a>.
      </p>
      <p>
        THE DIGITAL INFORMATION THAT YOU DEPOSIT IN YOUR DIGITAL VAULT IS ACCESSIBLE ONLY BY YOU AND THOSE AUTHORIZED
        REPRESENTATIVE THAT YOU SPECIFICALLY AUTHORIZE. THE ACCESS OF ANY SUCH AUTHORIZED REPRESENTATIVE MAY BE LIMITED
        BY THE ACCESS PERMISSION YOU PROVIDE ON THE WEBSITE. DURING THE TERM, WE WILL STORE, MAINTAIN AND SERVICE THE
        DIGITAL INFORMATION THAT YOU HAVE SUBMITTED AND DEPOSITED IN YOUR DIGITAL VAULT. IN ACCORDANCE WITH YOUR <a>DIGITAL VAULT AGREEMENT</a>, AS DESCRIBED BELOW,
        UPON YOUR DEATH, YOU HAVE AUTHORIZED US TO TRANSFER CERTAIN OF THE DIGITAL INFORMATION IN YOUR DIGITAL VAULT TO
        THE DNS PROVIDER IN ORDER TO PROVIDE ITS DECEASED NOTIFICATION SERVICES.
      </p>
      <p>
        Aside for this transfer of your Digital Information from your Digital Vault to the DNS Provider upon your death,
        we will have no access to you Digital Vault or the information you have deposited, except as may be required by
        applicable law or a governmental authority.
      </p>

      <h4>
        Transfer of Digital Information upon Death
      </h4>
      <p>
        In the event of your death, your Digital Vault, including the Digital Information contained therein, will be
        temporarily frozen by us awaiting for instructions from the individual(s) you have designated or who are
        otherwise authorized to manage and administer your estate. In addition, you also expressly authorize us to
        promptly transfer, duplicate, download, upload and/or transfer any of your Digital Information contained in your
        Digital Vault to an account which shall be automatically opened on your behalf with the DNS Provider in order to
        provide Deceased Notification Services. The Digital Information to be transmitted to the DNS Provider shall
        include all information and other materials as are reasonably necessary, as determined by the DNS Provider, to
        perform the Deceased Notification Services.
      </p>

      <h4>
        Our Release of Your Personal Information
      </h4>
      <p>
        We limit our disclosure of the Personal Information to those purposes which you have disclosed and consented to
        as provided in this Privacy Policy.
      </p>
      <p>
        We use and disclose the Personal Information in the following circumstances: (a) to enable the withdrawal of the
        Digital information to you; (b) to enable the provision of Deceased Notification Services and other related
        services by the DNS Provider to which you have expressly authorized and consented to; (c) to confirm or verify
        the your identity and that of any Authorized Representative; (d) to fulfill our obligations under data privacy,
        security and other applicable and/or relevant legislation (such as, a request to initiate an investigation); (e)
        to communicate with you in connection with your role as a customer regarding other products and services that
        may be relevant to our Digital Vault Services or that may be offered by our affiliates or other third parties;
        and (f) to act in respect of an emergency that threatens the life, health or security of an individual or for
        other purposes as permitted or required by law. We also may use account and other information about you provided
        to us from other sources (typically banks, financial institutions, or utility companies) to verify if it matches
        or confirms the information that has been provided by you.
      </p>
      <p>
        We may engage third parties to assist us with infrastructure maintenance and support. These service providers
        are prohibited from using the Digital Information for any purpose other than to provide this assistance and are
        required to protect the Digital Information disclosed to or by us and to comply with the general privacy
        principles described in this Privacy Policy. Some of these service providers may be located outside of the
        United States. As a result, the Digital Information may be subject to local laws in foreign jurisdictions, which
        may permit law enforcement, government, or national security authorities to have access to such information in
        certain circumstances.
      </p>

      <h4>
        Obtaining Consent
      </h4>
      <p>
        We collect, use, store, transfer, and disclose the Personal Information as described in this Privacy Policy when
        you have given consent to such collection, use, storage, transfer, or disclosure, or where the collection, use,
        storage, transfer or disclosure of such Personal Information without consent is permitted or required by law. We
        will not, as a condition of the providing a Customer access to the Website, require you to consent to the
        collection, use, storage, transfer or disclosure of information beyond that required to fulfill the specified
        and legitimate purposes for which the information is being provided or that is inconsistent with this Privacy
        Policy or your Digital Vault Agreement. <b>If you do not grant us the consents required, we may not be able to
        provide you with all or some of our Digital Vault Services.</b>
      </p>
      <p>
        If you have previously agreed to receive communications from us or provided consent and would like to withdraw
        his or her consent pursuant to the terms of this Privacy Policy, however, if such consent is withdrawn or
        limited, we will not be able to provide you with our Digital Vault Services. To withdraw or limit your consent,
        please contact as provided in “<a class="link" (click)="contacting_us.scrollIntoView()">Contacting Us</a>”
        below.
      </p>

      <h4>
        Information Security
      </h4>
      <p>
        We take the protection of your and any Digital Information seriously. We have appointed a Privacy & Compliance
        Manager to oversee our compliance with the privacy principles established by applicable federal and state law.
        We have implemented a security program that is aligned with industry best practices. We have adopted procedures
        to secure storage of your Digital Information and protect its security during any transfer to or from us. We
        have also instituted a number of safeguards to identify and prevent the fraudulent use of Digital Information.
        We use firewall technology to protect data from intruders and have implemented other safeguards to secure
        information such as passwords, encryption, antivirus software and physical security measures. Only our
        authorized employees and representatives will have access to your Digital Information to the extent necessary to
        perform their job functions. All such employees will have received appropriate training as to the privacy and
        data protection requirements applicable to our business.
      </p>

      <h4>
        Retention of Your Digital Information
      </h4>
      <p>
        We may retain your Digital Information in our digital vault storage database for as long as necessary to fulfill
        the purpose(s) for which it was collected and to comply with applicable laws. We will delete personal
        information provided to us that we deem to be no longer needed in connection with our Digital Vault Services as
        may be permitted or required under applicable law.
      </p>
      <p>
        As described above in “Transfer of Digital Information upon Death” we will transfer your Digital Information in
        the event of your death to facilitate the resolution of your estate.
      </p>

      <h4>
        Accuracy of and Access to the Digital Information
      </h4>
      <p>
        We attempt to maintain the Personal Information we collect as accurate, complete, and up to date as necessary
        for purposes of providing our Digital Vault Services; however, essentially all Personal Information is provided
        to us by you and/or other data suppliers and, accordingly we cannot and do not guarantee or make any
        representation as to the accuracy, completeness, or timeliness of any of your Personal Information. You should
        maintain the accuracy of the information provided to us, and to inform us of changes to that information as soon
        as possible. You have the right to access their and the corresponding Personal Information, to verify its
        accuracy and completeness, and to obtain details about the collection, use, storage, transfer and disclosure of
        such information, except where limited by law. In connection with our operations, we provide access and/or
        correction requests and address any other questions or concerns in accordance with applicable law. For
        information on how to access your Personal Information or request a copy, or dispute or update any Personal
        information, please contact us as provided below.
      </p>
      <p>
        When gathering your Personal Information, we work with you and data suppliers to increase their awareness of the
        importance of providing only information that is accurate and up to date. However, we cannot alter the
        information submitted to us by you or data suppliers, unless the information is determined to be wrong,
        incomplete, or otherwise inaccurate. If you do not agree with the accuracy of the information we have on file,
        we have procedures to ensure that such information is verified, and, where appropriate, amended or corrected. We
        also have an established complaint procedure to address your concerns and to ensure any inquiries and complaints
        are appropriately investigated and addressed.
      </p>

      <h4>
        Questions or Concerns
      </h4>
      <p>
        If you have any questions, concerns, or complaints regarding our information handling practices, or the
        information maintained by us, or this Privacy Policy, please contact us as described below.
      </p>

      <h4 #contacting_us>
        Contacting Us
      </h4>
      <p>
        To contact us please use the contact information below. Our consumer service group is staffed by trained
        personnel who can assist with your inquiries. You may also direct your inquiry or request directly to our
        Privacy & Compliance Manager at:
        <a class="link" href="mailto:{{customerSupportMail}}">{{customerSupportMail}}</a>. We may require that you put
        any request for access or correction in writing.
      </p>

      <h4>
        IP Addresses and Server Logs
      </h4>
      <p>
        Unique Internet Protocol (IP) addresses are assigned to all Internet users by their Internet Service Providers
        (ISPs). IP addresses do not directly identify Internet users; however, through an ISP, an IP address may be
        linked to a particular user. During a visit to a website, certain information about a user's traffic patterns,
        linked with the user's IP address, is passively and automatically collected in the normal course of establishing
        and maintaining a website connection. This information is logged by the web server of the website that is
        visited.
      </p>
      <p>
        The following information is logged by our web servers during visits to our Website: IP addresses, type of
        operating system, time and duration of visit, web pages visited and browser type. We do not link server log
        information to any other information in a way that would enable the identification of visitors to our Website.
        Server log information is analyzed by us in order to improve our Website and to customize the Website for users.
        In addition, from time to time, server logs may be reviewed for security purposes; for example, to detect
        unauthorized activity on our Website. In such cases, server log data, containing IP addresses, may be shared
        with law enforcement bodies in order that they may identify users in connection with their investigation of the
        unauthorized activities.
      </p>

      <h4>
        Links to Third Party Sites
      </h4>
      <p>
        Our Website may offer links to other third-party websites, including websites of affiliates of the Company. You
        should be aware that operators of linked websites may also collect your information using cookies or other
        tracking technologies when you visit their websites. We are not responsible for how such third parties collect,
        use, or disclose as such information, so it is important to familiarize yourself with their privacy policies
        before visiting their websites or providing them with information regarding yourself or any estate.
      </p>

      <h4>
        Changes to this Privacy Policy
      </h4>
      <p>
        We may modify this Privacy Policy at any time from time to time and will post the updated version on this
        Website. The Privacy Policy posted at any time or from time to time on this Website shall be the Privacy Policy
        then in effect.
      </p>
    </div>
  </div>

  <p class="mx-4">
    Last Updated: June 27, 2024
  </p>
</main>
