import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { AssetsService } from 'src/app/services/assets.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { SelectWithSearchComponent } from 'src/app/sharedComponent/select-with-search/select-with-search.component';
import { ALPHA_NUMERIC_PATTERN, ALPHA_NUMERIC_WITH_HYPHEN_PATTERN, ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN, DESIGNATION_TYPE, FINANCIAL_ASSET_TYPE, LIST_DELETE, LIST_NEW, LIST_UPDATE, NAME_PATTERN, NUMBERS_ONLY_PATTERN, NUMBERS_VALID_DOLLARS, USER_NAME_WITH_SPECIAL_CHARACTERS_PATTERN, WEBSITE_PATTERN_NEW } from 'src/constants/application.const';
import { ValidationHelper } from '../../../../helper/validation.helper';
import { SelectBeneficiaryRatioEmitModel } from '../../../../sharedComponent/select-beneficiary-ratio/select-beneficiary-ratio.component';
import { ConsumerSubscriptionService } from '../../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-add-financial-asset-popup',
  templateUrl: './add-financial-asset-popup.component.html',
})

/**
 * Financial assets popup component
 */
export class AddFinancialAssetPopupComponent implements OnInit {
  @ViewChildren(SelectWithSearchComponent) selectSearchComponents: QueryList<SelectWithSearchComponent>;
  @Output() toggleModalEmitter: any = new EventEmitter();
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() modal_id = 'add-financial-assets-modal';
  @Input() clientHasPaidSubscription:boolean = false;
  public readonly formType = FINANCIAL_ASSET_TYPE;
  public readonly faEye = faEye;
  public readonly faEyeSlash = faEyeSlash;
  public readonly faInfoCircle = faInfoCircle;
  public cryptoForm: FormGroup;
  public nonCryptoForm: FormGroup;
  public financialAssetType: number;
  public financialAssetTypeId: string;
  public submitLoader: boolean;
  public togglePasswordView: boolean;
  public submitLoaderDelete: boolean;
  public isPlaidData: boolean;
  public assetType: Array<SelectOptionsInterface>;
  public userType: string;
  public requestId: string;
  public touchedAssetType = false;
  public touchedDesignationType = false;
  public isNewAsset = true;
  public assetName: string;
  public midPlaid = 'accounts-card-plaid-import-modal'; // from common asset settings
  static loadComplete = false;
  private chosenBeneficiaries: {
    1: { user_people_id: string, percentage: number, type: number }[],
    2: { user_people_id: string, percentage: number, type: number }[]
  } = { 1: [], 2: [] };
  public readonly PRIMARY: number = 1;
  public readonly SECONDARY: number = 2;
  public overallBeneficiaryList: Array<any>;
  public userFinancialAssetId: string; // user financial form id to get percentage details of beneficiary
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public selectedBeneficiaries: Array<Object>;
  public readonly DesignationTypes: Array<SelectOptionsInterface> = DESIGNATION_TYPE;
  public selectedDesignationValue: any;
  isConsumer: boolean;
  private hasPaidSubscription: boolean;

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private commonHelper: CommonHelper,
    private assetsService: AssetsService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private personalDetailsService: PersonalDetailsService,
    private subscriptionService: ConsumerSubscriptionService,
    private validation: ValidationHelper,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.hasPaidSubscription = r);
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    // type 0 -Non Crypto, type 1- crypto
    // import type 2 - plaid data
    this.submitLoader = false;
    this.togglePasswordView = false;
    this.submitLoaderDelete = false;
    this.assetType = [];
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.isConsumer = this.userType === 'Consumer';
    this.initForm();
    this.getAssetDetails();
    if (!this.canAccessForm) {
      // show non crypto form by default if payment is not done
      this.financialAssetType = this.formType.NC;
      this.cryptoForm.disable();
      this.nonCryptoForm.disable();
    }
    console.log("financi", this.financialAssetType)
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlsKey
   * @param form
   */
  public getErrors(controlsKey: string, form: FormGroup = this.financialAssetType == this.formType.NC ? this.nonCryptoForm : this.cryptoForm): Array<string> {
    return this.validation.getErrors(controlsKey, form);
  }

  private initForm() {
    // two form groups under one form
    this.nonCryptoForm = this.formBuilder.group({
      contract_number: ['', {
        updateOn: 'blur',
        validators: [Validators.minLength(5), Validators.maxLength(20), Validators.pattern(ALPHA_NUMERIC_WITH_HYPHEN_PATTERN)],
      }],
      v_contract_number: [''],
      has_sole_ownership: [{ value: false }],
      joint_ownership_name: ['', {
        updateOn: 'blur',
        validators: [this.jointOwnershipNameRequired.bind(this), Validators.maxLength(50), Validators.pattern(NAME_PATTERN)],
      }],
      routing_number: ['', {
        updateOn: 'blur',
        validators: [Validators.minLength(5), Validators.maxLength(10), Validators.pattern(NUMBERS_ONLY_PATTERN)],
      }],
      v_routing_number: [''],
      bank_name: ['', {
        updateOn: 'blur',
        validators: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN)],
      }],
      account_number: ['', {
        updateOn: 'blur',
        validators: [Validators.minLength(5), Validators.maxLength(20), Validators.pattern(ALPHA_NUMERIC_WITH_HYPHEN_PATTERN)],
      }],
      v_account_number: [''],
      account_name: ['', {
        updateOn: 'blur',
        validators: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN)],
      }],
      current_balance: ['', {
        updateOn: 'blur',
        validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)],
      }],
      website: ['', {
        updateOn: 'blur',
        validators: [Validators.pattern(WEBSITE_PATTERN_NEW), Validators.maxLength(150)],
      }],
      user_name: ['',
        {
          updateOn: 'blur',
          validators: [Validators.minLength(3), Validators.maxLength(30), Validators.pattern(USER_NAME_WITH_SPECIAL_CHARACTERS_PATTERN)],
        },
      ],
      password: [''],
      import_type: ['', { updateOn: 'blur', validators: [Validators.required] }],
      type: [this.formType.NC],
      id: [''],
      designation_type: ['']
    });

    this.cryptoForm = this.formBuilder.group({
      currency_name: ['', {
        updateOn: 'blur',
        validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN)],
      }],
      currency_symbol: ['', {
        updateOn: 'blur',
        validators: [Validators.maxLength(25), Validators.pattern(ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN)],
      }],
      currency_held_in: ['', {
        updateOn: 'blur',
        validators: [Validators.minLength(3), Validators.maxLength(25), Validators.pattern(ALPHA_NUMERIC_PATTERN)],
      }],
      current_balance: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
      currency_description: ['', {
        updateOn: 'blur',
        validators: [Validators.maxLength(500)],
      }],
      type: [this.formType.C, [Validators.required]],
      id: [''],
    });

    const nonCryptoFormValidate = ['website', 'account_name', 'user_name', 'has_sole_ownership', 'joint_ownership_name'];
    
    nonCryptoFormValidate.forEach(controlName => {
      this.nonCryptoForm.controls[controlName].valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.nonCryptoForm.controls[controlName])
      );
    });

    const cryptoFormValidate = ['currency_name', 'currency_symbol', 'currency_held_in', 'current_balance', 'currency_description'];
    
    cryptoFormValidate.forEach(controlName => {
      this.cryptoForm.controls[controlName].valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.cryptoForm.controls[controlName])
      );
    });

    this.validation.setValidationErrors({
      current_balance: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places', pattern: false },
      website: { pattern: 'Invalid URL' },
    });
  }

  /**
   * Set the edit value
   * @param {any} data
   */
  @Input() set editData(data: any) {
    if (data && data.id) {
      const selectedForm = this.getForm(data?.type);
      if (data?.routing_number) {
        data.routing_number = data.routing_number.toString();
      }
      // this.financialPercentageId = data?.people[0]?.pivot.user_financial_asset_id;
      this.userFinancialAssetId = data.id; // user financial form id to get percentage details of beneficiary
      this.financialAssetTypeId = data.financial_asset_type.id;
      this.selectedDesignationValue = data?.designation_type;

      this.financialAssetType = data.type;
      data.current_balance = this.commonHelper.toCurrency(data.current_balance, true); // Commas
      selectedForm.patchValue(data);
      if (data?.type === this.formType.NC) {
        selectedForm.patchValue({
          v_account_number: data?.account_number,
          v_routing_number: data?.routing_number,
          v_contract_number: data?.contract_number,
        });
      }
      this.isPlaidData = data.import_type === 2;
      this.percentageDetails(this.userFinancialAssetId);
      this.isNewAsset = false; // If importing data, then this is not a new asset.
    }
  }

  /**
   * @param{any}data
   */
  @Input() set incomingData(data: any) {
    this.overallBeneficiaryList = data;
  };

  /**
   * Get form of type {@link type}.
   * @param type
   * @private
   */
  private getForm(type = this.financialAssetType): FormGroup {
    return (type === this.formType.NC) ? this.nonCryptoForm : this.cryptoForm;
  }

  public getControls(type): { [p: string]: AbstractControl } {
    return this.getForm(type)?.controls;
  }

  /**
   * get all asset type list
   */
  public getAssetDetails(): void {
    this.commonService.getAssetType().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.assetType = this.commonHelper.convertToOptionsFormat(
          response.data,
          'id',
          'name',
        ).sort(this.commonHelper.compareDisplayText);

        // Always place 'Other' last to make it easy to find.
        this.commonHelper.placeAtPointOfArray(this.assetType, (e) => e.displayText.toLowerCase() == 'other');

        this.resetForm();
      }
    });
  }

  /**
   * get selected asset type's display text
   * @param{string}id
   */
  private getDisplayText(id: string) {
    const match = this.assetType.find((type) => type.value === id);
    return match ? match.displayText : '';
  }

  /**
   * On Submit
   */
  public onSubmit(): void {
    const selectedForm = this.getForm(); // get controls of selected form
    this.canEdit && selectedForm.markAllAsTouched();
    for (let selectedFormKey in selectedForm['controls']) {
      selectedForm['controls'][selectedFormKey].updateValueAndValidity();
    }
    const isDesignationControlInvalid: boolean = (this.selectedDesignationValue === '' && !this.selectedDesignationValue)
    console.log(isDesignationControlInvalid);

    if (this.selectedDesignationValue === '' && !this.selectedDesignationValue && selectedForm == this.nonCryptoForm) {
      this.markDesignationType();
      this.commonHelper.focusOnElement('designation_type');
      return;
    }
    if (!selectedForm.valid) {
      this.commonHelper.scrollToFirstInvalidControl(selectedForm);
      return; // return if the form is invalid
    }
    this.submitLoader = true;
    this.submitFinancialAsset().subscribe({
      next: () => {
        //  Wait to submit beneficiaries in case this is a new asset.
        //  Can be assured that an assetID is gathered for the beneficiaries at this point.
        this.submitBeneficiaries();
        this.modalService.close(this.midPlaid);
        this.closeModel();
      },
      error: (exception) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      },
      complete: () => this.submitLoader = false,
    });
  }

  /**
   *  On Submit Financial Asset
   * @return{Observable}
   */
  public submitFinancialAsset(): Observable<any> {
    return new Observable<any>((observer) => {
      const selectedForm = this.getForm().value;
      this.submitLoader = true;
      selectedForm['current_balance'] = this.commonHelper.commaSeparateNumberClean(selectedForm['current_balance'], null); //  Remove commas for backend.

      const payload = {
        ...selectedForm,
        designation_type: this.selectedDesignationValue,
        asset_type_id: this.financialAssetTypeId,
        roletype: this.userType,
      };
      if (this.userType !== 'Consumer') {
        payload['request_id'] = this.requestId;
      }

      this.assetsService.storeFinancialAssetDetails(payload).subscribe({
        next: (response: APIResponseModel) => {
          if (response.status) {
            this.toggleModalEmitter.emit({
              ...response.data,
              listStatus: selectedForm['id'] ? LIST_UPDATE : LIST_NEW,
            });
            this.userFinancialAssetId = response.data.id;
            if (this.userType === 'Consumer') {
              this.commonHelper.updateLocalstorageRequestStage(response.data);
              selectedForm['id'] ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();
            }
          }
          observer.next(response);
        },
        error: (exception: any) => {
          observer.error(exception);
          this.submitLoader = false;
        },
        complete: () => observer.complete(),
      });
      return {
        unsubscribe() {
        },
      };
    },
    );
  }

  /**
   * Delete financial assets details. Checks delete permissions before proceeding.
   */
  public deleteFinancialAssets(): void {
    // ACL check
    if (!this.permissions?.['delete']) {
      this.toastrService.info('You do not have permission to delete items for this client.');
      return;
    }

    this.submitLoaderDelete = true;

    const selectedForm = this.getForm().value;
    const payload = {
      ...selectedForm,
      is_delete: '1',
      id: selectedForm['id'],
      roletype: this.userType,
    };
    if (this.userType !== 'Consumer') {
      payload['request_id'] = this.requestId;
    }

    this.assetsService.storeFinancialAssetDetails(payload).subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          this.toggleModalEmitter.emit({
            id: selectedForm['id'],
            listStatus: LIST_DELETE,
          });
          this.commonHelper.toastrDeleteSuccess();
          this.submitLoaderDelete = false;
          this.closeModel();
        }
      },
      error: (e: any) => {
        this.submitLoaderDelete = false;
        this.commonHelper.httpResponseHandler(e.error)
      },
      complete: () => {
        this.submitLoaderDelete = false
      }
    });
  }

  /**
   * change listener for asset type change
   * @param {SelectOptionsInterface}selectedOption
   */
  public optionChangeEmitterAssetType(selectedOption: SelectOptionsInterface): void {
    if (selectedOption) {
      this.resetForm(selectedOption.additionalDetails?.type);
      this.financialAssetTypeId = selectedOption.value;
      this.financialAssetType = selectedOption.additionalDetails?.type || this.formType.NC;
      const selectedForm = this.getForm();
      if (selectedForm?.value?.id) return;
      this.assetName = selectedOption.displayText;
    }
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.togglePasswordView = false;
    this.touchedDesignationType = false;
    this.isPlaidData = false;
    this.userFinancialAssetId = null;
    this.financialAssetTypeId = '';
    this.chosenBeneficiaries = { 1: [], 2: [] };
    this.selectedBeneficiaries = undefined;
    this.financialAssetType = undefined;
    this.overallBeneficiaryList = [];
    this.isNewAsset = true;
    this.assetName = '';
    this.modalService.close('add-financial-assets-modal');
    this.resetForm();
    this.toggleModalEmitter.emit();
  }

  /**
   * Submit Beneficiaries to the user_financial_asset defined by {@link userFinancialAssetId}.
   */
  public submitBeneficiaries(): void {
    this.submitLoader = true;
    const getData = (type) => {
      return {
        request_id: this.requestId,
        user_financial_asset_id: this.userFinancialAssetId, // financial asset id to user
        asset_type_id: this.financialAssetTypeId, // selected form type id
        beneficiary: this.chosenBeneficiaries[type],
        type: type,
      };
    };
    const data = { 1: getData(this.PRIMARY), 2: getData(this.SECONDARY) };
    this.personalDetailsService.submitBeneficiaries(data, 2).subscribe({
      error: (e) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(e.error);
      },
      complete: () => this.submitLoader = false,
    });
  }

  /**
   * Reset form
   * @param {number} type
   */
  public resetForm(type?: number): void {
    this.selectedDesignationValue = '';
    AddFinancialAssetPopupComponent.loadComplete = false;
    switch (type) {
      case this.formType.C:
        this.cryptoForm.reset({
          id: this.cryptoForm.value.id,
          type: type,
        });
        break;
      case this.formType.NC:
        this.nonCryptoForm.reset({
          id: this.nonCryptoForm.value.id,
          type: type,
          import_type: '1',
          has_sole_ownership: false,
        });
        break;
      default:
        this.cryptoForm.reset({ type: this.formType.C });
        this.cryptoForm.markAsUntouched();
        this.nonCryptoForm.reset({ type: this.formType.NC, import_type: '1' });
        this.nonCryptoForm.markAsUntouched();
    }
    AddFinancialAssetPopupComponent.loadComplete = true;
  }

  /**
   * Marks the asset type as touched.
   */
  public markAssetType() {
    this.touchedAssetType = true;
  }

  /**
   * Marks the designation type as touched.
   */
  public markDesignationType() {
    this.touchedDesignationType = true;
  }

  /**
   * Accessor to commonHelper for template
   */
  get getCommonHelper(): CommonHelper {
    return this.commonHelper;
  }

  public isVerified(control: string | string[]) {
    let formControl = this.getControls(this.formType.NC);
    if (Array.isArray(control)) {
      return control.every((c) => {
        const v_control = `v_${c}`;
        return formControl[c].value === formControl[v_control].value || (formControl[c].value == '' && formControl[v_control].value == null) || (formControl[c].value == null && formControl[v_control].value == '');
      });
    } else {
      const v_control = `v_${control}`;
      return formControl[control].value === formControl[v_control].value || (formControl[control].value == '' && formControl[v_control].value == null) || (formControl[control].value == null && formControl[v_control].value == '');
    }
  }

  get isValidCrypto(): boolean {
    return !this.submitLoaderDelete && !this.isPlaidData &&
      ((this.isNewAsset && this.permissions?.['add']) || (!this.isNewAsset && this.permissions?.['edit']));
  }

  get useRouting(): boolean {
    return !this.isPlaidData && this.assetName != 'Annuity' && this.assetName != 'Brokerage Account';
  }

  /**
   * Get beneficiary percentage details, populate {@link chosenBeneficiaries}.
   * @param financialId
   */
  private percentageDetails(financialId) {
    if (financialId) {
      this.submitLoaderDelete = true;
      this.assetsService.getFinancialPercentageList(financialId).subscribe({
        next: (res) => {
          this.chosenBeneficiaries = this.commonHelper.populateChosenBeneficiaries(res.data?.beneficiary);
          this.selectedBeneficiaries = res.data?.beneficiary
        },
        error: (exception) => this.commonHelper.httpResponseHandler(exception?.error),
        complete: () => this.submitLoaderDelete = false,
      });
    }
  }

  /**
   * If user can edit existing asset, or add a new asset.
   */
  get canEdit(): boolean {
    return this.commonHelper.canEditSection(this.isNewAsset, this.permissions) && (this.hasPaidSubscription || this.clientHasPaidSubscription)
  }

  /**
   * show error message
   * @param{string}control
   */
  public canShowErrorMessage(control: string) {
    return !this.getControls(this.formType.NC)?.[control].value && this.getControls(this.formType.NC)?.[`v_${control}`].touched && this.getControls(this.formType.NC)?.[`v_${control}`].value;
  }

  /**
   * Join Ownership Name is only required if <code>has_sole_ownership</code> is true.
   * @param control
   */
  public jointOwnershipNameRequired(control: AbstractControl): ValidationErrors | null {
    return this.getControls(this.formType.NC)?.['has_sole_ownership']?.value ? Validators.required(control) : null;
  }

  selectBeneficiaryRatioListener($event: SelectBeneficiaryRatioEmitModel) {
    this.chosenBeneficiaries = $event;
  }

  /**
   * Handles changes to the selected option in the UI.
   * @param selectedOption The newly selected option from the dropdown menu.
   */
  public optionChangeListener(selectedOption: SelectOptionsInterface): void {
    if (selectedOption?.value) {
      this.selectedDesignationValue = selectedOption.value;
    }
  }
}
