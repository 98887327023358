<div class="scroll-hidden">
  <div class="h-screen lg:grid grid-cols-12">
    <!-- BEGIN: Register Form -->
    <div class="col-span-12 md:col-span-6 xl:col-span-5 h-screen lg:h-auto p-6 flex">
      <div class="flex flex-col w-full">
        <div class="md:pl-2 flex items-center gap-2 sm:gap-4 flex-row">
          <a (click)="menuChange('')" class="cursor-pointer">
            <img alt="The-Estate-Registry" width="250" [src]="'assets/images/logo-old.png'" class="object-contain w-52 lg:w-60">
            <p class="text-regular text-semibold text-orange text-sm lg:text-lg text-center">
              {{this.proTypeText ? this.proTypeText + " Edition" : ""}}
            </p>
          </a>
          <a *ngIf="orgLogoPath"  class=" border-l-black pl-6 cursor-pointer max-h-12 maxw128">
            <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
          </a>
          <!-- <div class="flex items-center justify-center gap-2 md:gap-4 h-16 md:h-24">
            <div class="flex items-start gap-1 md:gap-2 flex-col">
              <p *ngIf="orgLogoPath "
                class="whitespace-nowrap text-xs md:text-sm lg:text-regular text-dark-grey">Sponsored by:</p>
              <a class="cursor-pointer max-h-12 maxw128" (click)="menuChange('dashboard')" *ngIf="orgLogoPath">
                <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
              </a>
            </div>
          </div> -->
        </div>
        <div class="h-full flex items-center justify-center mt-4">
          <div class="my-auto mx-auto bg-white pt-6 sm:pl-10 xl:bg-transparent sm:px-8 w-full">
            <div class="h-full justify-center items-center ">
              <div>
                <p class="text-regular text-grey text-base">
                  Create your {{this.proTypeText}} account with
                </p>
                <h2 class="text-darkblue text-4xl text-semibold pt-1">
                  LegacyNOW <br> Digital Vault
                </h2>
              </div>

              <form [formGroup]="professionalRegisterForm" autocomplete="off" (ngSubmit)="registerUser(captchaRef)">
                <div class="mt-6">
                  <div class="col-span-12 relative" (click)="markAsTouchedIndividual('role_id')">
                    <label for="profession" class="text-label-grey text-sm text-regular">Choose Profession Type*</label>
                    <app-select-with-search id="profession" [options]="professionalRoleTypes"
                      [placeholderValue]="'Choose Profession Type'"
                      (optionChangeEmitter)="optionChangeEmitterProfessionalType($event)"
                      [defaultSelected]="professionalRegisterForm.value?.['role_id']"
                      [ifError]="formGet.role_id.errors && formGet.role_id.touched"
                      [disabled]="!!userPeopleId"></app-select-with-search>
                    <div *ngIf="formGet.role_id.errors && formGet.role_id.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.role_id.errors.required">
                        Profession type is required
                      </p>
                    </div>
                  </div>

                  <div>
                    <label for="fc_name" class="text-sm pb-2 text-regular text-grey" *ngIf="!this.isCompany">First
                      Name*</label>
                    <label for="fc_name" class="text-sm pb-2 text-regular text-grey" *ngIf="this.isCompany">
                      Company Name</label>
                    <input type="text" name="fc_name" id="fc_name" formControlName="first_name"
                      class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                      [placeholder]="this.isCompany ? 'Enter Company Name': 'Enter your First Name'"
                      [ngClass]="formGet.first_name.errors && formGet.first_name.touched?'form-input-error':'form-input-focus'">
                    <!-- BEGIN: Error Alert text -->
                    <div *ngIf="formGet.first_name.errors && formGet.first_name.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.first_name.errors.required">
                        {{this.isCompany ? "Company " : "First "}}Name is required
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.first_name.errors.maxlength && !formGet.first_name.errors.pattern">
                        {{this.isCompany ? "Company " : "First "}}Name must not exceed 50 characters
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.first_name.errors.pattern">
                        Only alphabets and special characters like ' and - are allowed
                      </p>
                    </div>
                    <!-- END: Error Alert text -->
                  </div>
                  <!--middle name-->
                  <div *ngIf="!this.isCompany">
                    <label for="middle-name" class="text-sm pb-2 text-regular text-grey">Middle
                      Name</label>
                    <input type="text" name="middle-name" formControlName="middle_name" id="middle-name"
                      class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                      placeholder="Enter your Middle Name"
                      [ngClass]="formGet.middle_name.errors && formGet.middle_name.touched?'form-input-error':'form-input-focus'">
                    <!-- BEGIN: Error Alert text -->
                    <div *ngIf="formGet.middle_name.errors && formGet.middle_name.touched && !this.isCompany">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.middle_name.errors.maxlength && !formGet.middle_name.errors.pattern">
                        Last Name must not exceed 50 characters
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.middle_name.errors.pattern">
                        Only alphabets and special characters like ' and - are allowed
                      </p>
                    </div>
                  </div>
                  <!--last name-->
                  <div *ngIf="!this.isCompany">
                    <label for="last-name" class="text-sm pb-2 text-regular text-grey">Last
                      Name*</label>
                    <input type="text" name="last-name" formControlName="last_name" id="last-name"
                      class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                      placeholder="Enter your Last Name"
                      [ngClass]="formGet.last_name.errors && formGet.last_name.touched?'form-input-error':'form-input-focus'">
                    <!-- BEGIN: Error Alert text -->
                    <div *ngIf="formGet.last_name.errors && formGet.last_name.touched && !this.isCompany">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.last_name.errors.required">
                        Last Name is required
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.last_name.errors.maxlength && !formGet.last_name.errors.pattern">
                        Last Name must not exceed 50 characters
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.last_name.errors.pattern">
                        Only alphabets and special characters like ' and - are allowed
                      </p>
                    </div>
                    <!-- END: Error Alert text -->
                  </div>
                  <!-- Job Title -->
                  <div *ngIf="isManager">
                    <label for="manager_term" class="text-sm pb-2 text-regular text-grey">Job Title*</label>
                    <input type="text" name="manager_term" formControlName="manager_term" id="manager_term"
                      class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                      placeholder="e.g Regional Manager"
                      [ngClass]="formGet.manager_term.errors && formGet.manager_term.touched?'form-input-error':'form-input-focus'">
                    <!-- BEGIN: Error Alert text -->
                    <div *ngIf="formGet.manager_term.errors && formGet.manager_term.touched && isManager">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.manager_term.errors.required">
                        Job Title is required
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.manager_term.errors.maxlength && !formGet.manager_term.errors.pattern">
                        Job Title must not exceed 50 characters
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.manager_term.errors.pattern">
                        Only alphabets and special characters like ' and - are allowed
                      </p>
                    </div>
                    <!-- END: Error Alert text -->
                  </div>
                  <div>
                    <label for="email" class="text-sm pb-2 text-regular text-grey">Email
                      Address*</label>
                    <input type="email" name="email" formControlName="email" id="email"
                      class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                      placeholder="Enter valid email address" maxlength="50"
                      [ngClass]="formGet.email.errors && formGet.email.touched?'form-input-error':'form-input-focus'"
                      [readonly]="userPeopleId">
                    <!-- BEGIN: Error Alert text -->
                    <div *ngIf="formGet.email.errors && formGet.email.touched">

                      <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.email.errors.pattern">
                        Please enter a valid email address.
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.email.errors.required">
                        Email is required.
                      </p>
                    </div>
                    <!-- END: Error Alert text -->
                  </div>
                  <div>
                    <label for="phone-no" class="text-label-grey text-sm text-regular">Your Phone Number*</label>
                    <app-phone-input id="phone-no" [placeholder]="'e.g. 8665044754'"
                      [phoneNumber]="professionalRegisterForm.value.phone" [countryCode]="countryCode"
                      [uniqueId]="'phone-pro-register'"
                      [errorClassTrigger]="formGet.phone.errors && formGet.phone.touched"
                      [form]="professionalRegisterForm" (focusout)="formGet.phone.markAsTouched()">
                    </app-phone-input>
                    <div *ngIf="formGet.phone.errors && formGet.phone.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.phone.errors.pattern">
                        Please enter a valid phone number</p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.phone.errors.required">Phone number is required</p>
                    </div>
                  </div>

                  <div>
                    <label for="password" class="text-sm pb-2 text-regular text-grey">Create
                      Password*</label>
                    <div class="relative h-12 text-grey z-10">
                      <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
                        (click)="togglePasswordView = !togglePasswordView">
                        <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
                        <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
                      </a>
                      <input [type]="togglePasswordView?'text':'password'" name="password" formControlName="password"
                        id="password" autocomplete="new-password"
                        class="input py-3 px-4 mt-1 w-full text-regular text-grey rounded-xs h-12 appearance-none pl-4 border block focus:outline-none"
                        placeholder="Type Your Password"
                        [ngClass]="formGet.password.errors && formGet.password.touched? 'form-input-error':'form-input-focus'">
                      <!-- BEGIN: Error Alert text -->

                      <div *ngIf="formGet.password.errors && formGet.password.touched" class="z-100">
                        <div *ngIf="formGet.password.errors.required">
                          <p class="text-regular font-medium text-xs mt-1 error-alert">Password is required</p>
                        </div>
                        <div *ngIf="professionalRegisterForm.value.password && !checkPasswordMatch()"
                          class="absolute border mt-4 rounded bg-white p-2 text-red-700 border-3 w-full rounded-xs border block focus:outline-none">

                          <p [ngClass]="!formGet.password.errors.minlength? 'green-error-text':'red-error-text'">
                            <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                              alt="digital vault" *ngIf="!formGet.password.errors.minlength">
                            <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                              alt="digital vault" *ngIf="formGet.password.errors.minlength">
                            Minimum at least 8 characters.
                          </p>

                          <p
                            [ngClass]="formGet.password.errors?.passwordStrength?.['hasNumeric']? 'green-error-text':'red-error-text'">
                            <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                              alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.['hasNumeric']">
                            <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                              alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.['hasNumeric']">
                            At least one number (0-9).
                          </p>

                          <p
                            [ngClass]="formGet.password.errors?.passwordStrength?.['hasLowerCase']? 'green-error-text':'red-error-text'">
                            <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                              alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.['hasLowerCase']">
                            <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                              alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.['hasLowerCase']">
                            At least one lowercase letter (a-z).
                          </p>

                          <p
                            [ngClass]="formGet.password.errors?.passwordStrength?.['hasUpperCase']? 'green-error-text':'red-error-text'">
                            <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                              alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.['hasUpperCase']">
                            <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                              alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.['hasUpperCase']">
                            At least one uppercase letter (A-Z).
                          </p>

                          <p
                            [ngClass]="formGet.password.errors?.passwordStrength?.['hasSpecialCharacters']? 'green-error-text':'red-error-text'">
                            <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                              alt="digital vault"
                              *ngIf="formGet.password.errors?.passwordStrength?.['hasSpecialCharacters']">
                            <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                              alt="digital vault"
                              *ngIf="!formGet.password.errors?.passwordStrength?.['hasSpecialCharacters']">
                            At least one special character (!@#$%^&*).
                          </p>

                        </div>
                      </div>
                      <!-- <div *ngIf="formGet.password.errors && formGet.password.touched">

                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.password.errors.required">Password is required</p>
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.password.errors.minlength">Password must be at least 6
                          characters</p>
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.password.errors.maxlength">Password must not exceed
                          12 characters</p>
                      </div> -->

                      <!-- END: Error Alert text -->
                    </div>
                  </div>
                  <div class="mt-4">
                    <label for="newPasswordConfirm" class="text-sm pb-2 text-regular text-grey">
                      Confirm Password*</label>
                    <div class="relative h-12 text-grey">
                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                        (click)="toggleNewPasswordViewConfirm = !toggleNewPasswordViewConfirm">
                        <fa-icon [icon]="faEye" *ngIf="toggleNewPasswordViewConfirm"></fa-icon>
                        <fa-icon [icon]="faEyeSlash" *ngIf="!toggleNewPasswordViewConfirm"></fa-icon>
                      </div>
                      <input name="confirm_password" id="newPasswordConfirm"
                        [ngClass]="formGet.confirm_password.errors && formGet.confirm_password.touched?'form-input-error':'form-input-focus'"
                        class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                        formControlName="confirm_password" [type]="toggleNewPasswordViewConfirm?'text':'password'"
                        placeholder="Confirm New Password">
                      <!-- BEGIN: Error Alert text -->
                      <div *ngIf="formGet?.confirm_password?.touched && formGet?.confirm_password?.errors">
                        <p class="text-regular font-medium text-xs my-1 error-alert"
                          *ngIf="formGet?.confirm_password?.errors.required">
                          Confirmation password is required.
                        </p>
                      </div>
                      <div
                        *ngIf="formGet?.confirm_password?.touched && professionalRegisterForm.value['confirm_password'] && !checkPasswordMatch()">
                        <p class="text-regular font-medium text-xs my-2 error-alert">
                          Your passwords must match.
                        </p>
                      </div>
                      <!-- END: Error Alert text -->
                    </div>
                  </div>
                  <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
                    <div class="col-span-12 relative">
                      <label for="country" class="text-label-grey text-sm text-regular">Country*</label>
                      <app-country-search-component id="country"
                        (optionChangeEmitter)="optionChangeListenerCountry($event)"
                        [defaultSelected]="this.defaultCountry"
                        [enableError]="formGet.country.errors && formGet.country.touched">
                      </app-country-search-component>
                      <div *ngIf="formGet.country.errors && formGet.country.touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.country.errors.required">
                          Country is required</p>
                      </div>
                    </div>
                    <div class="col-span-12 relative  move-map" #moveMapHere>
                      <label for="street_address" class="text-label-grey text-sm text-regular">Street
                        Address*</label>
                      <input type="text" id="street_address" #search (keyup)="onAddressChange(search)"
                        (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null" (input)="onFocusAddress()"
                        class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                        placeholder="e.g Main St" name="street_address" formControlName="street_address"
                        [ngClass]="formGet.street_address.errors && formGet.street_address.touched?'form-input-error':'form-input-focus'">
                      <div *ngIf="formGet.street_address.errors && formGet.street_address.touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.street_address.errors.required">
                          Address required</p>
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.street_address.errors.minlength && !formGet.street_address.errors.pattern">
                          Minimum 6 characters required</p>
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.street_address.errors.maxlength && !formGet.street_address.errors.pattern">
                          Maximum 100 characters allowed</p>
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.street_address.errors.pattern">Only
                          letters, digits, space, hyphen, comma are allowed</p>
                      </div>
                    </div>
                    <div class="col-span-12 relative" (click)="markAsTouchedIndividual('state')"
                      *ngIf="selectedCountryName!==unitedKingdom">
                      <label for="state-Province" class="text-label-grey text-sm text-regular">State/Province*</label>
                      <app-state-search-component id="state-Province" [countryCode]="formGet?.country?.value"
                        [enableError]="formGet.state.errors && formGet.state.touched"
                        [defaultSelected]="formGet?.state?.value"
                        (optionChangeEmitter)="optionChangeListenerState($event)" tabindex="0"
                        (focusin)="markAsTouchedIndividual('state')"></app-state-search-component>
                      <div *ngIf="formGet.state.errors && formGet.state.touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.state.errors.required">
                          State is required</p>
                      </div>
                    </div>
                    <div class="col-span-12 md:col-span-6 relative" (click)="markAsTouchedIndividual('city')">
                      <label for="city" class=" text-label-grey text-sm text-regular ">City*</label>
                      <app-city-search id="city" [stateCode]="formGet?.state?.value"
                        [enableError]="formGet.city.errors && formGet.city.touched"
                        [defaultSelected]="formGet?.city?.value"
                        (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0"
                        (focusin)="markAsTouchedIndividual('city')"></app-city-search>
                      <div *ngIf="formGet.city.errors && formGet.city.touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.city.errors.required">
                          City is required</p>
                      </div>
                    </div>
                    <div class=" col-span-12 md:col-span-6 ">
                      <label for="zipcode" class="text-label-grey text-sm text-regular">Zip/Postal
                        Code*</label>
                      <input type="text" id="zipcode" (focusin)="markAsTouchedIndividual('zipcode')"
                        class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
                        placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                        name="zipcode" formControlName="zipcode" oninput="this.value = this.value.toUpperCase()"
                        (focus)="validateZipCode()"
                        [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
                      <div *ngIf="formGet.zipcode.errors && formGet.zipcode.touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.zipcode.errors.pattern">
                          Invalid zipcode</p>
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.zipcode.errors.required">
                          Zipcode is required</p>
                      </div>
                    </div>

                    <!-- <div class="col-span-12 md:col-span-6 relative">
                      <label for="payment_mode" class="text-label-grey text-sm text-regular">Choose Payment
                        Mode*</label>
                      <app-select-component id="payment_mode" [options]="paymentModes"
                        [placeholder]="'Choose Payment Mode'"
                        [selectedOptionDefault]="professionalRegisterForm?.value?.['payment_mode']"
                        (optionChangeEmitter)="optionChangeEmitterPaymentType($event)"
                        [ifError]="formGet.payment_mode.errors && formGet.payment_mode.touched"
                        (toggleEmiter)="toggleEmitter($event)">
                      </app-select-component>
                      <div *ngIf="formGet.payment_mode.errors && formGet.payment_mode.touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.payment_mode.errors.required">
                          Payment Mode is required</p>
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="mt-8">
                  <div class="recaptcha h-20">
                    <re-captcha #captchaRef="reCaptcha" (resolved)="resolvedCaptcha($event)"
                      (error)="onErrorCaptcha($event)" errorMode="handled" [siteKey]="googleCaptchaKey"></re-captcha>
                  </div>
                </div>
                <div class="mt-6 md:flex md:flex-row text-center flex-col-reverse items-center">
                  <p class="text-grey text-regular text-sm">Already Have an Account?
                    <a (click)="menuChange('login')"
                      class="cursor-pointer text-darkblue font-medium underline cursor-pointer">Login
                      Here</a>
                  </p>
                  <div class="ml-auto mt-4 md:mt-0">
                    <button type="submit"
                      class="flex items-center justify-between button button--lg w-full bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-4"
                      [ngClass]="(!professionalRegisterForm.valid) ? 'opacity-50' : ''" [disabled]="registerLoader">
                      <div class="loading-red mr-2" *ngIf="registerLoader"></div>
                      <span>Register</span>
                    </button>
                  </div>
                </div>
              </form>

              <p class="text-regular text-grey text-center py-6 text-xs">
                By creating an account you agree to our
                <a (click)="menuChange('privacy-policy')"
                  class="text-regular font-semibold text-darkblue underline cursor-pointer">
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- END: Register Form -->

    <!-- BEGIN: Login Info -->
    <div class="md:col-span-6 xl:col-span-7 hidden lg:flex bg-login-grey min-h-screen justify-center">
      <div class="fixed h-full flex flex-col items-center justify-center">
        <app-right-carousel-intro></app-right-carousel-intro>
      </div>
    </div>
    <!-- END: Login Info -->
  </div>
</div>
<app-common-modal id="user-logged-in" class="app-common-modal">
  <h2 class="text-xl lg:text-2xl font-sans font-medium highlighted-text truncate mr-5">
    You are already logged in
  </h2>
  <div class="flex flex-row items-center">
    <p class="font-sans text-lg mt-2">
      Redirecting to Dashboard
    </p>
    <div class="loading-red ml-2 mt-2"></div>
  </div>
</app-common-modal>
