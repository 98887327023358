<div class="scroll-hidden">

  <ng-container *ngIf="loader.check(); else content">
    <div class="center-screen">
      <div class="loading-red-free m-auto h-24 w-24"></div>
    </div>
  </ng-container>

  <ng-template #content>
    <app-navbar *ngIf="this.userType === 'Consumer'"></app-navbar>

    <div class="p-2 lg:px-12 lg:py-24 container mx-auto">
      <ng-container *ngIf="!showIntro && !showImportantInfo">
        <div class="title__wrap text-center mb-4">
          <h1 class="text-semibold text-accent pb-2" [ngClass]="mobile ? 'fs-bhead' : 'fs-title'">
            Something Went Wrong
          </h1>
        </div>
      </ng-container>

      <ng-container *ngIf="showImportantInfo">
        <div class="title__wrap text-center mb-4">
          <h1 class="text-semibold text-accent pb-2" [ngClass]="mobile ? 'fs-bhead' : 'fs-title'">
            IMPORTANT INFORMATION
          </h1>
        </div>

        <h3 class="text-center text-primary text-semibold mb-4" [ngClass]="mobile ? 'fs-bhead' : 'fs-title'">
          Your data is safe with us.
        </h3>
        <p class="text-content text-regular fs-content mb-4">
          Your trial period has concluded. To continue using our services, please proceed with payment. Failure to make
          the required payment after your trial period ends will result in account deletion. This means that your access
          to LegacyNOW and all your data will be terminated and, after 45 days, your account may be deleted from our
          system.
        </p>

        <div class="py-4 text-center mt-8">
          <button (click)="redirectToPaymentPage()"
                  class="bg-darkblue button button--lg mx-auto flex focus:outline-none hover:underline-text items-center justify-center md:w-auto px-4 xl:px-8 py-2 xl:py-3 rounded-xs text-sm md:text-base xl:text-2xl text-white w-full">
            Continue
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="showIntro">
        <div class="title__wrap text-center mb-4">
          <h1 class="text-semibold text-accent pb-2 text-darkblue" [ngClass]="mobile ? 'fs-bhead' : 'fs-title'">
            Welcome to LegacyNOW
          </h1>
        </div>

        <h3 class="text-gray-700 text-gray-700 font-semibold text-xl mb-4 mx-4">
          The LegacyNOW Digital Vault operates on a monthly subscription basis of {{monthlyPackageDetails | currency}}.
          If during your first month you are not entirely satisfied with the service, you can cancel for a complete
          refund.
        </h3>
        <h3 *ngIf="allowTransferPayment" class="text-gray-700 text-gray-700 font-semibold text-xl mb-4 mx-4">
          An optional Vault Transfer service is also available, should you elect it. For an additional one-time fee,
          this service, upon confirmation of your passing, securely transfers account information stored in your Digital
          Vault to NotifyNOW. NotifyNOW’s automated process, uses the data to notify the associated companies of your
          passing and request your account(s) be closed, eliminating the stressful and time consuming burden from your
          next-of-kin.
        </h3>
        <h3 class="text-gray-700 text-gray-700 font-semibold text-xl mb-4 mx-4">
          Click <a class="link" (click)="redirectToPaymentPage()">'Proceed to Payment'</a> to select your option and get
          started.
        </h3>

        <div class="py-4 text-center mt-8">
          <button (click)="redirectToPaymentPage()"
                  class="bg-darkblue button button--lg mx-auto flex focus:outline-none hover:underline-text items-center justify-center md:w-auto px-4 xl:px-8 py-2 xl:py-3 rounded-xs text-sm md:text-base xl:text-2xl text-white w-full">
            Proceed to Payment
          </button>
        </div>
      </ng-container>
    </div>
    <app-consumer-landing-footer></app-consumer-landing-footer>
  </ng-template>
</div>
<app-payment-processing [onlyShowProcessHeader]="true"></app-payment-processing>
<app-payment-failure></app-payment-failure>
