<div class="scroll-hidden box-border body-content">

  <div class="pt-12">
    <div class="flex flex-wrap lg:mx-8 mx-6 ">
      <div class="w-full">
        <div class="flex items-center">
          <img src="assets/images/about-yourself/back-arrow.svg" alt="digital vault" class="cursor-pointer"
            (click)="changeRoute('managers')">
          <h2 class="text-darkblue text-md lg:text-2xl capitalize pl-5 text-semibold">
            Edit Manager
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-8">
    <form [formGroup]="agentForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="border-card bg-white rounded-md p-6 mx-8">

        <!--first row-->
        <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mb-5">
          <div class="col-span-12 md:col-span-6 lg:col-span-4 ">
            <label for="label" class="text-sm mb2 text-regular text-label-grey">First Name</label>
            <span>

              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="text"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.first_name.errors && formGet.first_name.touched?'form-input-error':'form-input-focus'"
              placeholder="First Name" formControlName="first_name">
            <div *ngIf="formGet.first_name.errors && formGet.first_name.touched">
              <p class="text-regular font-medium text-xs mt-2 error-alert"
                *ngIf="formGet.first_name.errors.maxlength && !formGet.first_name.errors.pattern">
                First Name must not exceed 50 characters
              </p>
              <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.first_name.errors.required">
                First Name is required
              </p>
              <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.first_name.errors.pattern">
                Only alphabets and special characters like ' and - are allowed
              </p>
            </div>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-4 ">
            <label for="label" class="text-sm mb2 text-regular text-label-grey">Middle Name</label>

            <input type="text"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.middle_name.errors && formGet.middle_name.touched?'form-input-error':'form-input-focus'"
              placeholder="Middle Name" formControlName="middle_name">
            <div *ngIf="formGet.middle_name.errors && formGet.middle_name.touched">

              <p class="text-regular font-medium text-xs mt-2 error-alert"
                *ngIf="formGet.middle_name.errors.maxlength && !formGet.middle_name.errors.pattern">
                Middle Name must not exceed 50 characters
              </p>
              <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.middle_name.errors.pattern">
                Only alphabets and special characters like ' and - are allowed
              </p>
            </div>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-4 ">
            <label for="label" class="text-sm mb2 text-regular text-label-grey">Last Name</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="text"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.last_name.errors && formGet.last_name.touched?'form-input-error':'form-input-focus'"
              placeholder="Last  Name" formControlName="last_name">
            <div *ngIf="formGet.last_name.errors && formGet.last_name.touched">
              <p class="text-regular font-medium text-xs mt-2 error-alert"
                *ngIf="formGet.last_name.errors.maxlength && !formGet.last_name.errors.pattern">
                Last Name must not exceed 50 characters
              </p>
              <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.last_name.errors.required">
                Last Name is required
              </p>
              <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.last_name.errors.pattern">
                Only alphabets and special characters like ' and - are allowed
              </p>
            </div>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-4">
            <label for="label" class="text-sm mb2 text-regular text-label-grey">Email Address</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="email"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.email.errors && formGet.email.touched?'form-input-error':'form-input-focus'"
              formControlName="email" name="email" placeholder="e.g. jonathanbairstow@gmail.com">
            <div *ngIf="formGet.email.errors && formGet.email.touched">
              <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.email.errors.pattern">Please
                enter a valid email address.</p>
              <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.email.errors.required">
                Email address is required
              </p>
            </div>
          </div>

          <div class="col-span-12 md:col-span-6 lg:col-span-4">
            <label for="label" class="text-sm mb2 text-regular text-label-grey">Phone Number</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <app-phone-input [placeholder]="'e.g. 8665044754'" [phoneNumber]="agentForm.value.phone"
              [countryCode]="countryCode" [uniqueId]="'phone-agent-edit'"
              (onChange)="detectChangePhonenumber($event)"
              [errorClassTrigger]="formGet.phone.errors && formGet.phone.touched"
              [form]="agentForm" (focusout)="formGet.phone.markAsTouched()">
            </app-phone-input>
            <div *ngIf="formGet.phone.errors && formGet.phone.touched">
              <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.phone.errors.pattern">Please
                enter a valid phone number</p>
              <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.phone.errors.required">
                Phone number is required
              </p>
            </div>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-4 ">
            <label for="last_name" class="text-sm mb2 text-regular text-label-grey">Job Title</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="text"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.manager_term.errors && formGet.manager_term.touched?'form-input-error':'form-input-focus'"
              placeholder="e.g Regional Manager" formControlName="manager_term" id="manager_term">
            <div *ngIf="formGet.manager_term.errors && formGet.manager_term.touched">
              <p class="text-regular font-medium text-xs mt-2 error-alert"
                *ngIf="formGet.manager_term.errors.maxlength && !formGet.manager_term.errors.pattern">
                Job Title must not exceed 50 characters
              </p>
              <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.manager_term.errors.required">
                Job Title is required
              </p>
              <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.manager_term.errors.pattern">
                Only alphabets and special characters like ' and - are allowed
              </p>
            </div>
          </div>
        </div>
        <!--end-->
        <!--start second section-->
        <div class="w-full my-3 mb-5">
          <h3 class="text-semibold text-darkblue text-lg">Address</h3>
          <p class="text-grey text-md text-regular mb-4">Please enter their primary physical address.</p>
          <!--start iiner-->
          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
              <label for="label" class="text-sm mb-2 text-regular text-label-grey">Country</label>
              <span>

                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <div class=" col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('country')">
                <app-country-search-component (optionChangeEmitter)="optionChangeListenerCountry($event)"
                  [defaultSelected]="formGet?.country?.value"
                  [enableError]="formGet.country.errors && formGet.country.touched"></app-country-search-component>
                <div *ngIf="formGet.country.errors && formGet.country.touched">
                  <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.country.errors.required">
                    Country is required</p>
                </div>
              </div>
            </div>
            <div class="col-span-12 md:col-span-6 lg:col-span-4 relative move-map" #moveMapHere>
              <label for="label" class="text-sm mb-2 text-regular text-label-grey">Street Address</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <input type="text" #search (keyup)="onAddressChange(search)"
                (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null" (input)="onFocusAddress()"
                class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
                placeholder="e.g Main St" name="street_address" formControlName="street_address"
                [ngClass]="formGet.street_address.errors && formGet.street_address.touched?'form-input-error':'form-input-focus'">
              <div *ngIf="formGet.street_address.errors && formGet.street_address.touched">
                <p class="text-regular font-medium text-xs mt-2 error-alert"
                  *ngIf="formGet.street_address.errors.required">Address is required</p>
                <p class="text-regular font-medium text-xs mt-2 error-alert"
                  *ngIf="formGet.street_address.errors.minlength && !formGet.street_address.errors.pattern">
                  Minimum 3
                  characters required</p>
                <p class="text-regular font-medium text-xs mt-2 error-alert"
                  *ngIf="formGet.street_address.errors.maxlength && !formGet.street_address.errors.pattern">
                  Maximum 500
                  characters allowed</p>
                <!-- <p class="text-regular font-medium text-xs mt-2 error-alert"
                    *ngIf="formGet.street_address.errors.pattern">Only letters, digits, space, hyphen, comma are allowed
                  </p> -->
              </div>
            </div>

            <div class="col-span-12 md:col-span-6 lg:col-span-4" *ngIf="selectedCountryName!==unitedKingdom">
              <label for="label" class="text-sm mb-2 text-regular text-label-grey">State/Province</label>
              <span>

                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('state')">
                <app-state-search-component [countryCode]="formGet?.country?.value"
                  [enableError]="formGet.state.errors && formGet.state.touched"
                  [defaultSelected]="formGet?.state?.value" (optionChangeEmitter)="optionChangeListenerState($event)"
                  tabindex="0" (focusout)="markAsTouchedIndividual('state')">
                </app-state-search-component>
                <div *ngIf="formGet.state.errors && formGet.state.touched">
                  <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.state.errors.required">
                    State is required</p>
                </div>
              </div>
            </div>
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
              <label for="label" class="text-sm mb-2 text-regular text-label-grey">City</label>
              <span>

                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('city')">
                <app-city-search [stateCode]="formGet?.state?.value"
                  [enableError]="formGet.city.errors && formGet.city.touched" [defaultSelected]="formGet?.city?.value"
                  (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0"
                  (focusout)="markAsTouchedIndividual('city')"></app-city-search>
                <div *ngIf="formGet.city.errors && formGet.city.touched">
                  <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.city.errors.required">City
                    is required</p>
                </div>
              </div>
            </div>
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
              <label for="label" class="text-sm mb-2 text-regular text-label-grey">Zip/Postal Code</label>
              <span>

                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <input type="text" (focusout)="markAsTouchedIndividual('zipcode')"
                class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
                placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                name="zipcode" formControlName="zipcode" oninput="this.value = this.value.toUpperCase()"
                (focus)="validateZipCode()"
                [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
              <div *ngIf="formGet.zipcode.errors && formGet.zipcode.touched">
                <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.zipcode.errors.pattern">
                  Invalid zipcode</p>
                <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.zipcode.errors.required">
                  Zipcode is required</p>
                <p class="text-regular font-medium text-xs mt-2 error-alert"
                  *ngIf="formGet.zipcode.errors.minlength && !formGet.zipcode.errors.pattern">
                  Minimum 3 characters required</p>
                <p class="text-regular font-medium text-xs mt-2 error-alert"
                  *ngIf="formGet.zipcode.errors.maxlength && !formGet.zipcode.errors.pattern">
                  Maximum 25 characters allowed</p>
              </div>
            </div>

          </div>
          <!--end-->
        </div>
        <!--end-->
        <div class="w-full my-3">
          <div class="w-full  h-12 lg:h-24 md:w-full  relative">
            <div class="absolute bottom-0 right-0">
              <button
                class="flex items-center justify-between button button--lg w-full bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white text-sm px-4">
                <div class="loading-red mr-2" *ngIf="loginLoader"></div>
                <div>Save Manager</div>
              </button>
            </div>
          </div>
        </div>
        <!--end-->
      </div>
    </form>
  </div>
</div>
