<div class="scroll-hidden background-color-grey">
  <!-- Navbar -->
  <app-navbar *ngIf="this.userType === 'Consumer'"></app-navbar>
  <app-professional-navbar *ngIf="this.userType !== 'Consumer'"></app-professional-navbar>
  <div class="grid grid-cols-12 p-8 sm:p-12 xl:px-24 ">
    <div class="col-span-12">
      <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
        Account Settings
      </h2>
      <p class="text-base text-grey py-2 tracking-wide text-regular">
        Check and update your personal information and account settings
      </p>
      <hr class="border-lightblue w-24 my-1 border-1">
    </div>
  </div>
  <div class="grid grid-cols-12 md:px-12 xl:px-24 pb-12 gap-6 md:gap-12">
    <div class="col-span-12 lg:hidden p-8 md:p-0">

      <div class="text-sm font-medium text-center border-b border-gray-200">
        <ul class="flex flex-row items-end sm:flex-wrap gap-4 -mb-px">
          <li *ngFor="let option of menuOptions; let i = index" class="mr-2">
            <a [ngClass]="{'font-semibold tab-border-red text-lightblue': activeMenu.id == option.id}"
               (click)="menuChange(i)"
               class="inline-block py-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 text-grey text-regular"
               aria-current="page">
              {{option.title}}
            </a>
          </li>
        </ul>
      </div>

    </div>
    <div class="col-span-12 lg:col-span-3 bg-transparent hidden lg:block">
      <div class="sticky top-10 fixed">
        <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
          Contents
        </h4>
        <ul class="space-y-1 mt-2">
          <li *ngFor="let option of menuOptions; let i = index"
              class="text-grey text-regular text-lg cursor-pointer"
              [ngClass]="{'active': activeMenu.id == option.id}"
              (click)="menuChange(i)">
            {{option.title}}
          </li>
        </ul>
      </div>
    </div>
    <!-- BEGIN: Account Details -->
    <div class="col-span-12 lg:col-span-9 space-y-8">
      <!-- BEGIN: Digital Vault ID Section -->
      <div class="grid grid-cols-12 gap-6 bg-white mx-8 md:mx-0 border-card rounded p-6" *ngIf="userUniqueID">
        <div class="col-span-12">
          <div class="flex mx-8 text-regular md:mx-0 md:text-sm items-center">
            <div class="mt-2 md:mt-0   lg:mt-2 xl:mt-0 ">
              <div class="mr-2">
                <img src="assets/images/about-yourself/idcard.svg" class="w-5 h-7" alt="idcard">
              </div>
            </div>
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide mr-5">Your LegacyNOW Digital Vault
              ID </h4>
            <h2 class="text-regular text-gray-800 font-semibold">{{userUniqueID}}</h2>
          </div>
        </div>
      </div>
      <!-- END: Digital Vault ID Section -->
      <div *ngIf="activeMenu.id == 'account_details'"
           class="grid grid-cols-12 gap-6 bg-white mx-8 md:mx-0 border-card rounded p-6">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Change your Email
          </h4>
        </div>
        <div class="col-span-12 md:col-span-2">
          <form [formGroup]="profilePictureForm">
            <div class="h-32 w-32 flex justify-center items-center rounded-full relative">
              <app-image-component [image]="imageSrc" [class]="'w-32 h-32 rounded-full object-fill'"
                                   [defaultImage]="'assets/icons/user-bg.svg'">
              </app-image-component>
              <span
                class="absolute h-8 w-8 right-0 flex justify-center items-center rounded-full border-white border-2 bg-edit bottom-0 cursor-pointer"
                (click)="propicfield.click()">
                <img [src]="'assets/icons/edit.svg'" class="w-3 h-3" alt="digital vault">
                <input type="file" class="hidden" #propicfield (change)="readURL($event);" id="file">
              </span>
            </div>
          </form>
        </div>
        <div class="col-span-12 md:col-span-10 md:ml-10 lg:ml-12 xl:ml-4">
          <form [formGroup]="accountDetailForm" autocomplete="off" (ngSubmit)="accountDetailFormSubmit()">
            <div>
              <label for="email" class="text-sm pb-2 text-regular text-grey">Your Current Email
                Address</label>
              <input type="email" name="old-email" id="email" formControlName="old_email"
                     class="input mt-1 w-full rounded-xs h-12 text-regular appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                     [ngClass]="accountDetailFormGet?.old_email?.errors && accountDetailFormGet?.old_email?.touched ?'form-input-error':'form-input-focus'"
                     placeholder="Enter your Email address" disabled>
            </div>
            <div class="mt-3" *ngIf="changeEmail">
              <div>
                <label for="email" class="text-sm pb-2 text-regular text-grey">Enter New Email Address</label>
                <input type="email" name="new-email" id="new-email" formControlName="email"
                       class="input mt-1 w-full rounded-xs h-12 text-regular appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                       [ngClass]="accountDetailFormGet?.email?.errors && accountDetailFormGet?.email?.touched ?'form-input-error':'form-input-focus'"
                       placeholder="Enter your new Email address"
                       [attr.disabled]="this.showVerificationCodes || undefined">
                <!-- BEGIN: Error Alert text -->
                <div *ngIf="accountDetailFormGet.email.errors && accountDetailFormGet.email.touched">
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                     *ngIf="accountDetailFormGet.email.errors.same_email">
                    The email address you have entered is the same as your old address.
                  </p>
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                     *ngIf="accountDetailFormGet.email.errors.pattern">
                    Please enter a valid email address.
                  </p>
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                     *ngIf="accountDetailFormGet.email.errors.required">
                    Email address is required.
                  </p>
                </div>
              </div>
              <!-- END: Error Alert text -->
              <div class="mt-3" *ngIf="!showVerificationCodes">
                <label for="password_email" class="text-sm pb-2 text-regular text-grey">
                  Enter Your Password</label>
                <div class="relative h-12 text-grey">
                  <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                       (click)="togglePasswordView = !togglePasswordView">
                    <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
                    <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
                  </div>
                  <input name="password" id="password_email" autocomplete="new-password"
                         class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                         formControlName="password" [type]="togglePasswordView?'text':'password'"
                         [ngClass]="accountDetailFormGet?.password?.errors && accountDetailFormGet?.password?.touched ?'form-input-error':'form-input-focus'"
                         placeholder="Enter Password to Change Email">
                  <!-- BEGIN: Error Alert text -->
                  <div *ngIf="accountDetailFormGet?.password.errors && accountDetailFormGet?.password.touched">
                    <p class="text-regular font-medium text-xs mt-2 error-alert"
                       *ngIf="accountDetailFormGet?.password?.errors?.required">Your
                      Password is required
                    </p>
                  </div>
                  <!-- END: Error Alert text -->
                </div>
              </div>
              <div class="mt-3 grid grid-cols-2" *ngIf="showVerificationCodes">
                <div class="col-span-1 md:col-span-2">
                  <label for="verification_code1" class="text-sm pb-2 text-regular text-grey">
                    Verification Code 1
                  </label>
                  <input name="verification_code1" id="verification_code1"
                         class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                         formControlName="password"
                         placeholder="Verification Code 1">
                </div>
                <div class="col-span-1 md:col-span-2">
                  <label for="verification_code2" class="text-sm pb-2 text-regular text-grey">
                    Verification Code 2
                  </label>
                  <input name="verification_code2" id="verification_code2"
                         class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                         formControlName="password"
                         placeholder="Verification Code 2">
                </div>
              </div>
            </div>
            <div class="mt-8">
              <app-button-loader *ngIf="changeEmail" [buttonText]="'Save Changes'" [valid]="accountDetailForm.valid"
                                 buttonType="'submit'" [loader]="accountDetailsSubmit"></app-button-loader>
              <app-button-loader *ngIf="!changeEmail" [buttonText]="'Change email'" [valid]="true"
                                 buttonType="'button'" (clickButton)="changeEmail = true"></app-button-loader>
            </div>
          </form>
        </div>
      </div>
      <!-- Update Mobile Number -->
      <div class="grid grid-cols-12 gap-6 bg-white mx-8 md:mx-0 border-card rounded p-6"
           *ngIf="activeMenu.id == 'account_details'">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Change your Phone Number
          </h4>
        </div>

        <div class="col-span-12 md:col-span-10 md:ml-10 lg:ml-12 xl:ml-4">
          <form [formGroup]="phoneNumberForm" autocomplete="off" (ngSubmit)="updatePhoneFormSubmit()">
            <div>
              <label for="old_phone" class="text-label-grey text-sm text-regular">Your Current Phone Number</label>
              <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                <app-phone-input [placeholder]="'e.g. 8665044754'"
                                 [phoneNumber]="phoneNumberForm.value.old_phone" [countryCode]="oldCountryCode"
                                 [uniqueId]="'old_phone'" [disabled]="true" id="old_phone"
                                 [errorClassTrigger]="phoneFormGet.old_phone.errors && phoneFormGet.old_phone.touched">
                </app-phone-input>
              </app-shimmer-loading>
            </div>
            <div class="mt-3" *ngIf="changePhone">
              <div>
                <label for="new_phone" class="text-label-grey text-sm text-regular">Enter New Phone Number</label>
                <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                  <app-phone-input [placeholder]="'e.g. 8665044754'"
                                   [countryCode]="countryCode" [uniqueId]="'new_phone'"
                                   (onChange)="detectChangePhonenumber($event)"
                                   (click)="phoneFormGet.phone.markAsTouched()" id="new_phone"
                                   [errorClassTrigger]="phoneFormGet.phone.errors && phoneFormGet.phone.touched"
                                   [form]="phoneNumberForm" (focusout)="phoneFormGet.phone.markAsTouched()">
                  </app-phone-input>
                </app-shimmer-loading>
                <div *ngIf="phoneFormGet.phone.errors && phoneFormGet.phone.touched">
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                     *ngIf="phoneFormGet.phone.errors.required">Phone number is required</p>
                  <p class="text-regular font-medium text-xs mt-2 error-alert"
                     *ngIf="phoneFormGet.phone.errors.pattern">Please enter a valid phone number</p>
                </div>
              </div>
              <!-- END: Error Alert text -->
              <div class="mt-3">
                <label for="password" class="text-sm pb-2 text-regular text-grey">
                  Enter Your Password</label>
                <div class="relative h-12 text-grey">
                  <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                       (click)="togglePasswordToPhoneNo = !togglePasswordToPhoneNo">
                    <fa-icon [icon]="faEye" *ngIf="togglePasswordToPhoneNo"></fa-icon>
                    <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordToPhoneNo"></fa-icon>
                  </div>
                  <input name="password" id="password" (focusout)="phoneFormGet.password.markAsTouched()"
                         class="input py-3 px-4 pr-10 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                         formControlName="password" [type]="togglePasswordToPhoneNo?'text':'password'"
                         [ngClass]="phoneFormGet?.password?.errors && phoneFormGet?.password?.touched ?'form-input-error':'form-input-focus'"
                         placeholder="Enter Password to Change Phone Number">
                  <!-- BEGIN: Error Alert text -->
                  <div
                    *ngIf="phoneFormGet?.password.errors && phoneFormGet?.password.touched ?'form-input-error':'form-input-focus'">
                    <p class="text-regular font-medium text-xs mt-2 error-alert"
                       *ngIf="phoneFormGet?.password?.errors?.required">Password is required
                    </p>
                  </div>
                  <!-- END: Error Alert text -->
                </div>
              </div>
            </div>
            <div class="mt-8">
              <app-button-loader *ngIf="changePhone" [buttonText]="'Save Changes'" [valid]="phoneNumberForm.valid"
                                 buttonType="'submit'" [loader]="phoneFormSubmit">
              </app-button-loader>
              <app-button-loader *ngIf="!changePhone" [buttonText]="'Change Phone Number'" [valid]="true"
                                 buttonType="'button'" (clickButton)="changePhone = true"></app-button-loader>
            </div>
          </form>
        </div>
      </div>
      <!-- END: Account Details -->

      <!-- BEGIN: Change Passsword -->
      <div class="grid grid-cols-12 gap-6 bg-white mx-8 md:mx-0 border-card rounded p-6"
           *ngIf="activeMenu.id == 'password'">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Change your password
          </h4>
          <p *ngIf="resetPassword" class="text-regular font-medium error-alert mt-2">
            It is highly recommended that you reset your password before proceeding.
          </p>
        </div>
        <div class="col-span-12 ml-4">
          <form [formGroup]="changePasswordForm" autocomplete="off" (ngSubmit)="changePasswordFormSubmit()">
            <div>
              <label for="oldPassword" class="text-sm pb-2 text-regular text-grey">
                Old password</label>
              <div class="relative h-12 text-grey">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                     (click)="toggleOldPasswordView = !toggleOldPasswordView">
                  <fa-icon [icon]="faEye" *ngIf="toggleOldPasswordView"></fa-icon>
                  <fa-icon [icon]="faEyeSlash" *ngIf="!toggleOldPasswordView"></fa-icon>
                </div>
                <input formControlName="password" [type]="toggleOldPasswordView?'text':'password'" name="password"
                       id="oldPassword" (focusout)="changePasswordFormGet.password.markAsTouched()"
                       class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                       [ngClass]="changePasswordFormGet?.password?.errors && changePasswordFormGet?.password?.touched ?'form-input-error':'form-input-focus'"
                       placeholder="Enter Old Password">
                <!-- BEGIN: Error Alert text -->
                <div *ngIf="changePasswordFormGet?.password?.errors && changePasswordFormGet?.password?.touched">
                  <p class="text-regular font-medium text-xs my-2 error-alert"
                     *ngIf="changePasswordFormGet?.password?.errors?.required">Old password is required
                  </p>
                </div>
                <!-- END: Error Alert text -->
              </div>
            </div>
            <div class="mt-5">
              <label for="newPassword" class="text-sm pb-2 text-regular text-grey">
                New Password</label>
              <div class="relative h-12 text-grey">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                     (click)="toggleNewPasswordView = !toggleNewPasswordView">
                  <fa-icon [icon]="faEye" *ngIf="toggleNewPasswordView"></fa-icon>
                  <fa-icon [icon]="faEyeSlash" *ngIf="!toggleNewPasswordView"></fa-icon>
                </div>
                <input name="new_password" id="newPassword"
                       (focusout)="changePasswordFormGet?.new_password.markAsTouched()"
                       class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                       formControlName="new_password" [type]="toggleNewPasswordView?'text':'password'"
                       [ngClass]="changePasswordFormGet?.new_password?.errors && changePasswordFormGet?.new_password?.touched ?'form-input-error':'form-input-focus'"
                       placeholder="Enter New Password">
                <!-- BEGIN: Error Alert text -->
                <div *ngIf="changePasswordFormGet.new_password.errors && changePasswordFormGet.new_password.dirty">
                  <!-- <div *ngIf="changePasswordFormGet.new_password.errors.required && changePasswordFormGet.new_password.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert">Password is required</p>
                  </div> -->
                  <div *ngIf="!changePasswordFormGet.new_password.errors.required"
                       class="z-10 absolute border mt-4 rounded bg-white p-2 text-red-700 border-3 w-full rounded-xs border block focus:outline-none">

                    <p
                      [ngClass]="!changePasswordFormGet.new_password.errors.minlength? 'green-error-text':'red-error-text'">
                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                           alt="digital vault" *ngIf="!changePasswordFormGet.new_password.errors.minlength">
                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                           alt="digital vault" *ngIf="changePasswordFormGet.new_password.errors.minlength">
                      Minimum at least 8 characters.
                    </p>

                    <p
                      [ngClass]="changePasswordFormGet.new_password.errors?.passwordStrength?.hasNumeric? 'green-error-text':'red-error-text'">
                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                           alt="digital vault"
                           *ngIf="changePasswordFormGet.new_password.errors?.passwordStrength?.hasNumeric">
                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                           alt="digital vault"
                           *ngIf="!changePasswordFormGet.new_password.errors?.passwordStrength?.hasNumeric">
                      At least one number (0-9).
                    </p>

                    <p
                      [ngClass]="changePasswordFormGet.new_password.errors?.passwordStrength?.hasLowerCase? 'green-error-text':'red-error-text'">
                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                           alt="digital vault"
                           *ngIf="changePasswordFormGet.new_password.errors?.passwordStrength?.hasLowerCase">
                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                           alt="digital vault"
                           *ngIf="!changePasswordFormGet.new_password.errors?.passwordStrength?.hasLowerCase">
                      At least one lowercase letter (a-z).
                    </p>

                    <p
                      [ngClass]="changePasswordFormGet.new_password.errors?.passwordStrength?.hasUpperCase? 'green-error-text':'red-error-text'">
                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                           alt="digital vault"
                           *ngIf="changePasswordFormGet.new_password.errors?.passwordStrength?.hasUpperCase">
                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                           alt="digital vault"
                           *ngIf="!changePasswordFormGet.new_password.errors?.passwordStrength?.hasUpperCase">
                      At least one uppercase letter (A-Z).
                    </p>

                    <p
                      [ngClass]="changePasswordFormGet.new_password.errors?.passwordStrength?.hasSpecialCharacters? 'green-error-text':'red-error-text'">
                      <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                           alt="digital vault"
                           *ngIf="changePasswordFormGet.new_password.errors?.passwordStrength?.hasSpecialCharacters">
                      <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                           alt="digital vault"
                           *ngIf="!changePasswordFormGet.new_password.errors?.passwordStrength?.hasSpecialCharacters">
                      At least one special character (!@#$%^&*).
                    </p>

                  </div>
                </div>
                <!-- END: Error Alert text -->
              </div>
            </div>
            <div class="mt-5">
              <label for="newPasswordConfirm" class="text-sm pb-2 text-regular text-grey">
                Confirm New Password</label>
              <div class="relative h-12 text-grey">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                     (click)="toggleNewPasswordViewConfirm = !toggleNewPasswordViewConfirm">
                  <fa-icon [icon]="faEye" *ngIf="toggleNewPasswordViewConfirm"></fa-icon>
                  <fa-icon [icon]="faEyeSlash" *ngIf="!toggleNewPasswordViewConfirm"></fa-icon>
                </div>
                <input name="new_password_confirm" id="newPasswordConfirm"
                       (focusout)="changePasswordFormGet.new_password_confirm.markAsTouched()"
                       class="input py-3 px-4 mt-1 w-full text-regular rounded-xs h-12 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                       formControlName="new_password_confirm" [type]="toggleNewPasswordViewConfirm?'text':'password'"
                       [ngClass]="(changePasswordFormGet?.new_password_confirm?.errors || !changePasswordMatch) && changePasswordFormGet?.new_password_confirm?.touched ?'form-input-error':'form-input-focus'"
                       (click)="changePasswordMatch = true" placeholder="Confirm New Password">
                <!-- BEGIN: Error Alert text -->
                <div
                  *ngIf="(changePasswordFormGet?.new_password_confirm?.errors || !changePasswordMatch) && changePasswordFormGet?.new_password_confirm?.touched">
                  <p class="text-regular font-medium text-xs my-2 error-alert" *ngIf="!changePasswordMatch">
                    Your passwords must match.
                  </p>
                  <p class="text-regular font-medium text-xs my-2 error-alert"
                     *ngIf="changePasswordFormGet?.new_password_confirm?.errors?.required">Confirm Password is required
                  </p>
                </div>
                <!-- END: Error Alert text -->
              </div>
            </div>
            <div class="mt-8">
              <app-button-loader [buttonText]="'Save Changes'" [valid]="changePasswordForm.valid"
                                 [loader]="changePasswordSubmit" buttonType="'submit'"></app-button-loader>
            </div>
          </form>
        </div>
      </div>
      <!-- END: Change Passsword -->

      <!-- BEGIN: Contact Preferences -->
      <div class="grid grid-cols-12 gap-4 bg-white border-card mx-8 md:mx-0 rounded p-6"
           *ngIf="activeMenu.id == 'contact_pref'">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Contact Preferences
          </h4>
        </div>
        <div class="col-span-12 mt-2 md:space-x-4 sm:space-x-0 md:flex sm:flex-row">
          <label class="flex items-center">
            <input type="checkbox" class="form-checkbox h-5 w-5 text-darkblue focus:outline-none" checked>
            <span class="text-darkgrey text-base text-regular ml-4">Receive marketing
              emails from Digital Vault</span>
          </label>
          <label class="flex items-center">
            <input type="checkbox" class="form-checkbox h-5 w-5 text-darkblue focus:outline-none">
            <span class="text-darkgrey text-base text-regular ml-4">Receive calls from Digital Vault
            </span>
          </label>
        </div>
        <div class="col-span-12 mt-2">
          <button type="submit"
                  class="button button--lg mt-2 text-sm tracking-wide shadow-lg md:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-6">
            Save Changes
          </button>
        </div>
      </div>
      <!-- END: Contact Preferences -->
      <!-- BEGIN: Change Passsword -->
      <div class="grid grid-cols-12 gap-6 bg-white mx-8 md:mx-0 border-card rounded p-6"
           *ngIf="activeMenu.id == 'test_menu'">
      </div>
    </div>
  </div>
</div>
<!--button type="button" class="btn btn-primary" (click)="testClick()">Test Click</button-->
<app-email-verification-modal [newEmail]="newEmail"
                              (otpVerificationStatus)="getCurrentUserDetails()"></app-email-verification-modal>
