import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css'],
})
// eslint-disable-next-line require-jsdoc
export class TestimonialsComponent implements OnInit {
  @Input() mobile = false;
  testimonialData: TestimonialModel[];


  // eslint-disable-next-line require-jsdoc
  ngOnInit(): void {
    this.testimonialData = [
      {
        quote: 'LegacyNOW’s digital vault has given me the peace of mind that I\'m doing something that will help my family and friends if I ever do leave unexpectedly.',
        name: 'T. Cai',
      },
      {
        quote: 'I feel very reassured knowing that there is a solution for me to share my private details with my loved ones after I\'m gone.',
        name: 'S. Withers',
      },
      {
        quote: 'Their digital vault features give me the tools to do something now that I can rest assured my legacy is left securely and to the standard that I desire.',
        name: 'J. Neygen',
      },
    ];
  }
}


interface TestimonialModel {
  quote: string;
  name: string;
}
