import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { environment } from 'src/environments/environment';
import { USER_TYPES } from '../../../../constants/application.const';
import { ProfessionalPermissionService } from '../../../professional-dashboard/services/professional-permission.service';
import { ReceivedDlbService } from '../../../services/received-dlb.service';


@Component({
  selector: 'app-will-digital-files-overview',
  templateUrl: './will-digital-files-overview.component.html',
})
/**
 * Will and digital files overview component
 */
export class WillDigitalFilesOverviewComponent implements OnInit {
  public digitalFilesArray: Array<any> = [];
  public willFilesArray: Array<any> = [];
  public preLoader: boolean = true;
  public downloadLink: string;
  public downloadFileName: string;
  public imageFormatFiltering: Array<string>;
  public currentUser: any;
  public isReceivedDigitalVault: boolean;
  public digitalFilesView: boolean;
  public digitalFilesEdit: boolean;
  public digitalFilesStatus: boolean;
  public clientRequestId: string;
  public userType: string;
  public requestIdParam: string;
  public permissionList: Array<any>;
  public permission: Array<any>;
  public ROUTE: { [key: string]: string } = {
    DIGITAL_FILES: '/digital-files',
    WILL_FILES: '/will-files',
  };
  public isAccessingVault: boolean;
  private transferDataPaid: boolean = false;
  public isVaultClaimed: boolean;
  public userRequestID: string;

  /**
   * @constructor
   */
  constructor(
    private personalDetailsService: PersonalDetailsService,
    private proPermissionService: ProfessionalPermissionService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private modalService: CommonModelService,
    private activeRouter: ActivatedRoute,
    private slugInterceptorService: SlugInterceptorService,
    private receivedDlbService: ReceivedDlbService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.isAccessingVault = this.localStorageService.getDataByKey('accessVault');
    this.currentUser = this.localStorageService.getUserData();
    this.clientRequestId = this.localStorageService.getDataByKey('req_id') || this.currentUser?.request?.id;
    this.userType = this.localStorageService.getDataByKey('role');
    this.userRequestID = this.commonHelper.getRequestId(this.activeRouter);
    this.requestIdParam = this.commonHelper.encodeDecodeRequestId(this.activeRouter.snapshot.parent.params?.id, false) || this.activeRouter.snapshot.parent.params?.id || this.localStorageService.getDataByKey('req_id');
    if (this.activeRouter.snapshot.parent.params?.id) {
      this.isReceivedDigitalVault = true;
    }
    this.imageFormatFiltering = ['jpeg', 'jpg', 'png','jpeg,', 'jpg,', 'png,'];
    this.getAccessControl();
    this.isVaultClaimed = this.localStorageService.getDataByKey('isVaultClaimed') == 1;
  }


  private get useCensor(): boolean {
    return this.isCustodian && !this.transferDataPaid;
  }

  private get isCustodian(): boolean {
    return this.userType === 'Custodian';
  }
  public get isConsumer(): boolean {
    return this.userType === 'Consumer';
  }

  /**
   * Get will and digital files
   */
  public getWillDigitalFiles(): void {
    this.preLoader = true;
    if(this.commonHelper.isClaimedAndNotPaid) return; // handle custodian claimed valut but not paid transfer fee
    
      this.personalDetailsService.getPersonalDetails(this.isCustodian || (this.userType === 'Custodian' && !this.isAccessingVault) ? this.requestIdParam : this.clientRequestId).subscribe({
        next: (response: APIResponseModel) => {
          if (response.status) {
            this.digitalFilesArray = response.data.user_digital_file.filter((d: any) => !d.is_will);
            this.willFilesArray = response.data.user_digital_file.filter((d: any) => d.is_will);
            this.includeFileName();
            if (this.willFilesArray != null) {
              this.willFilesArray.sort((a, b) => a['file_name'].localeCompare(b['file_name']));
            }
          }
        },
        error: (exception: any) => (this.commonHelper.httpResponseHandler(exception?.error)),
        complete: () => this.preLoader = false,
      });
      this.preLoader = false;
  }

  /**
   * View image popup
   *
   * @param {string} image
   */
  public viewImage(image: string): void {
    const PIC_URL = `${environment.BASE_URL_RAW}uploads/${this.requestIdParam ? this.requestIdParam : this.clientRequestId}${image}`;
    this.commonService.setImageViewPopupTrigger({imageSrc:PIC_URL});
    this.modalService.open('view-image-modal');
  }

  /**
   * Download file
   *
   * @param {any} file
   */
  public downLoadFile(file: any): void {
    this.downloadLink = `${environment.BASE_URL_RAW}uploads/${this.clientRequestId}${file?.name}`;
    this.downloadFileName = this.getFileName(file?.file_name);
    this.modalService.open('file-download-modal');
  }

  /**
   * Establish ACL Permissions. Consumer is assigned full access.
   */
  public getAccessControl(): void {
    if (this.userType === 'Consumer' || (this.commonHelper.isCustodian && this.localStorageService.getDataByKey('isVaultClaimed'))) {
      this.digitalFilesView = true;
      this.digitalFilesEdit = true;
      this.digitalFilesStatus = true;
      this.getWillDigitalFiles();
    }else{
      const requestId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('request_user_id') : this.userRequestID;
      const userId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('user_id') : this.localStorageService.getDataByKey('agent_id') || this.localStorageService.getUserData(USER_TYPES.pro)?.user?.id;
      this.proPermissionService.getAccessControl(userId, requestId, ['Digital Files']).subscribe({
        next: (permissions) =>{
          const permission = permissions?.['Digital Files'];
          const bEdit = permission['add'] || permission['edit'] || permission['delete'];
          this.digitalFilesView = permission['view'];
          this.digitalFilesEdit = this.digitalFilesStatus = bEdit;
        },
        error: (exception) => (this.commonHelper.httpResponseHandler(exception?.error)),
        complete: ()=>{
          this.getWillDigitalFiles();
        }
      })
      this.proPermissionService.getAccessControl(this.localStorageService.getUserData(USER_TYPES.pro).user.id, this.clientRequestId, ['Digital Files']).subscribe((permissions) => {
        
      }, (exception: any) => (this.commonHelper.httpResponseHandler(exception?.error)));
    }
  }
  /**
   * Get file name
   * @param file 
   * @returns file name 
   */
  public getFileName(file: string): string {
   return this.commonHelper.removePrefixNumber(file);
  }
  /**
   * Get function for html template to access this.commonHelper & allow AOT compilation
   */
  get getCommonHelper(): CommonHelper {
    return this.commonHelper;
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }

  /**
   * Adds `fileName` key to {@link willFilesArray} items as an alternate display string.
   */
  public includeFileName() {
    for (let i = 0; i < this.willFilesArray.length; i++) {
      let splitName = this.willFilesArray[i].file_name.split('_');
      this.willFilesArray[i]['fileName'] = `${splitName[0]} — ${splitName[1]}`;
    }
  }
}
