<!-- LN Acceptable Use Policy (v1.0 110923) -->
<!-- BEGIN: Navbar Wrapper -->
<app-consumer-landing-navbar></app-consumer-landing-navbar>
<!-- END: Navbar Wrapper -->
<main>

  <div class="p-12 lg:px-24 lg:py-32 container mx-auto">
    <div class="title__wrap text-center py-8">
      <h1 class="text-semibold text-accent fs-title pb-2">LegacyNOW</h1>
      <h2 class="text-semibold text-primary fs-title">Acceptable Use Policy</h2>
    </div>
    <div>
      <p>
        <b>Please note that this Acceptable Use Policy is a supplement to your
          <a class="link" (click)="changeRoute('digital-vault-agreement')">Digital Vault Agreement</a> and is
          incorporated therein by reference. Any capitalized term used in this policy that is not otherwise defined is
          understood to have the same meaning as in your
          <a class="link" (click)="changeRoute('digital-vault-agreement')">Digital Vault Agreement</a>.</b>
      </p>
      <p>
        You agree not to misuse our Digital Vault services (the "<u>Services</u>") or help anyone else to do so. For
        example, you agree not even to do any of the following in connection with your vault or our Services:
      </p>

      <ul class="list-disc">
        <li>
          probe, scan, or test the vulnerability of any system or network relating to our Services or the Website;
        </li>
        <li>
          breach or otherwise circumvent any security or authentication measures to access or use our Services or the
          Website;
        </li>
        <li>
          access, tamper with, or use non-public areas or parts of our Services or Website, or shared areas of the
          Services or Website you haven't been authorized to
        </li>
        <li>
          interfere with or disrupt any user, host, or network, for example by sending a virus, overloading, flooding,
          spamming, or mail-bombing any part of our Services or Website;
        </li>
        <li>
          access, search, or create accounts for the Services by any means other than our publicly supported interfaces
          (for example, "scraping" or creating accounts in bulk);
        </li>
        <li>
          submit altered, deceptive or false source-identifying information to your Vault, including "spoofing" or
          "phishing";
        </li>
        <li>
          circumvent storage space limits;
        </li>
        <li>
          sell, resell, lease or charge anyone for the use of the Services unless specifically authorized to do so;
        </li>
        <li>
          Except as specifically provided in your
          <a class="link" (click)="changeRoute('digital-vault-agreement')">Digital Vault Agreement</a>, permit anyone
          else to use your vault or access our Services to you;
        </li>
        <li>
          submit or store materials that constitute child sexually exploitative material (including material which may
          not be illegal child sexual abuse material, but which nonetheless sexually exploits or promotes the sexual
          exploitation of minors), unlawful pornography, or are otherwise indecent;
        </li>
        <li>
          submit or store content that contains or promotes extreme acts of violence or terrorist activity, including
          terror propaganda;
        </li>
        <li>
          submit any information or materials that advocate bigotry or hatred against any person or group of people
          based on their race, religion, ethnicity, sex, gender identity, sexual orientation, disability, or impairment;
        </li>
        <li>
          violate the law in any way, including storing, publishing, or sharing material that’s fraudulent, defamatory,
          or misleading, or that violates the intellectual property rights of others; or
        </li>
        <li>
          violate the privacy or infringe the rights of others, including, without limitation, the privacy or rights of
          other subscribers to the Service.
        </li>
      </ul>

      <p>
        We reserve the right to take appropriate action in response to violations of this policy, which could include
        removing or disabling access to content, suspending your or any user’s access to the Services, or terminating
        your account.
      </p>
    </div>
  </div>

  <p class="mx-4">
    Last Updated: November 13, 2023
  </p>
</main>

<app-consumer-landing-footer></app-consumer-landing-footer>
