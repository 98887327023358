import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SelectOptionsInterface, ToggleOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PropertyService } from 'src/app/services/property.service';
import { APARTMENT_NUMBER_PATTERN, LIST_DELETE, LIST_NEW, LIST_UPDATE, NAME_PATTERN, UNITED_KINGDOM, fileValidationOptions } from 'src/constants/application.const';
import { CLEAR_SELECTED_FILE } from 'src/constants/digitalFile.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../../helper/validation.helper';
import { PersonalDetailsService } from '../../../../services/personal-details.service';
import { FileListingEmitModel, FileUploadSettingsModel } from '../../../../sharedComponent/file-listing/file-listing.component';
import { ConsumerSubscriptionService } from '../../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',

})
/**
 * Add / Edit Property
 */
export class AddPropertyComponent implements OnInit {
  @Output() toggleModalEmitter = new EventEmitter<any>();
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() isNewAsset: boolean = true;
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;
  @Input() clientHasPaidSubscription:boolean = false;


  public propertyForm: FormGroup;
  public preDefinedLoader: boolean;
  public submitLoader: boolean = false;
  public isDigitalFiles: boolean = false;
  public isImageFile: boolean = false;
  public propertyTypes: Array<any>;
  public imageFormatFiltering: Array<string>;
  public PIC_URL: string;
  public countryCode: string;
  public userType: string;
  public clientRequestId: string;
  public defaultCountry: number;
  public file: any;
  public selectedCountry: number;
  public selectedCountryName: string = '';
  public unitedKingdom: string = UNITED_KINGDOM;
  public toggleOptions: Array<ToggleOptionsInterface>;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public toggleEmiterListener: boolean = false; // toggle listener
  public isFileDropWhenNoDocumentSelected: boolean;
  public activateFileUploadLoader: boolean;
  public filePercentage: any;
  public propertyFileArray: Array<any> = [];
  public downloadLink: any;
  public downloadFileName: any;
  public deletedImage: string;
  public updateSubscription: Subscription;
  public fileUploadSettings: FileUploadSettingsModel;

  // Mortgage dropdown fields
  public mortgageStatus: number;
  public selectedMortgageID: string;
  public liabilitiesLoading: boolean;
  public mortgageOptions: Array<SelectOptionsInterface>;
  private mortgageItems: Array<Object>;
  public mortgageLiabilityType: Object;
  public mortgageEditData: Object = {};
  private hasPaidSubscription: boolean;
  isConsumer: boolean;

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private propertyService: PropertyService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private addressHelper: AddressHelper,
    private ngZone: NgZone,
    private toastrService: ToastrService,
    private personalDetailsService: PersonalDetailsService,
    private validation: ValidationHelper,
    private subscriptionService: ConsumerSubscriptionService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.hasPaidSubscription = r);
    this.getMortgageType();
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.selectedCountry = environment.APP_DOMAIN;
    this.propertyTypes = [];
    this.countryCode = environment.DEFAULT_COUNTRY_DIALCODE;
    // file valiadtion options
    this.fileUploadSettings = {
      fileValidationOptions: fileValidationOptions,
      hideFileOperations: true,
      allowMultiple: true,
      isDigitalFiles: true,
      displayFileName: true,
    };
    this.toggleOptions = [
      {
        displayText: 'NO',
        value: 0,
      },
      {
        displayText: 'YES',
        value: 1,
      },
    ];
    // this.imageFormatFiltering = ['jpeg', 'jpg', 'png'];
    this.imageFormatFiltering = ['image/jpeg', 'image/jpg', 'image/png', 'jpeg', 'jpg', 'png'];

    this.clientRequestId = this.route.snapshot.params['id'] || this.localStorageService.getUserData()?.request?.id;
    this.userType = this.localStorageService.getDataByKey('role');
    this.isConsumer = this.userType === 'Consumer';
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    this.initForm();

    // Set validator if domain country is not UK
    this.isEnableState();

    // Listening for reset
    this.propertyService.resetPropertyFormObserve.subscribe((trigger: boolean) => {
      if (trigger) {
        this.resetForm();
        this.removePacContainer();
        this.initMap(this.defaultCountry);
      }
    });
    this.commonHelper.clearfileHanlderObservableEvents(); // clear file events

    this.getPropertyType();
    this.initMap(this.defaultCountry);
    if (!this.canAccessForm) {
      this.propertyForm.disable();
    }
    this.getAccessControl();
    this.deletedImage = '';
  }

  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }

  /**
   * initialize form
   */
  private initForm() {
    this.propertyForm = this.formBuilder.group({
      property_type_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
      property_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      mortgage_id: [''],
      address: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(255), Validators.minLength(3)] }],
      country: ['', { updateOn: 'blur', validators: [Validators.required] }],
      state: [''],
      apt_number: ['', { updateOn: 'blur', validators: [Validators.pattern(APARTMENT_NUMBER_PATTERN), Validators.maxLength(25)] }],
      city: ['', { updateOn: 'blur', validators: [Validators.required, this.validation.noWhitespaceValidator()] }],
      property_image: [''],
      price: ['', { updateOn: 'blur' }],
      current_price: ['', { updateOn: 'blur' }],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(10)] }],
      id: [''],
      is_image_deleted: ['0'],
      remove_image: [''],
    });

    // Automatically trim whitespace for all controls
    Object.keys(this.propertyForm.controls).forEach(controlName => {
      this.propertyForm.get(controlName)?.valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.propertyForm.get(controlName))
      );
    });
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlsKey
   */
  public getErrors(controlsKey: string): Array<string> {
    return this.validation.getErrors(controlsKey, this.propertyForm);
  }

  /**
   * file drop
   * restrict file upload for file drop when no document selected
   * @param data
   */
  public isFileDrop(data) {
    this.isFileDropWhenNoDocumentSelected = data;
  }

  /**
   * Get all property types
   */
  private getPropertyType(): void {
    this.commonService.getPropertyType().subscribe((response) => {
      if (response.status) {
        this.propertyTypes = this.commonHelper.convertToOptionsFormat(response.data, 'id', 'name')
        this.resetForm();
      }
    });
  }

  /**
   * setter for editing bank details
   *
   * @param {any} data
   */
  @Input() set editData(data: any) {
    this.resetForm();
    this.propertyFileArray = [];
    this.deletedImage = '';

    if (data?.property_image) {
      const name = data.property_image.split(',');
      const extension = data.file_extension.split(',');
      for (let i = 0; i < name.length; i++) {
        if (name[i].length > 0) {
          const files = {
            name: name[i],
            type: extension[i],
          };
          this.propertyFileArray.push(files);
        }
      }
    }
    if (data && data?.id) {
      this.propertyForm.patchValue({
        ...data,
        property_image: '',
        country: data.country?.id,
        state: data.state?.id,
        apt_number: data?.apt_number ?? '',
        city: data.city?.id,
      });
      this.selectMortgageOption(data.liability_id);
      this.removePacContainer();
      this.initMap(data.country?.id);
    }
    this.commonService.filePreviewObservableEvents({ isPreviewProcess: false });
  }

  /**
   * Initialize google map api by country
   * @param {any} countryId
   */
  public initMap(countryId: any): void {
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: (r) => {
        this.ngZone.run(() => {
          this.propertyForm.patchValue(r);
        });
      },
      error: (e) => console.error(e),
    });
  }

  /**
   * Upload file
   *
   * @param files
   */
  public fileUploadChangeEmitter(files: any): void {
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.propertyFileArray.push(file);
      }
    }
  }

  /**
   * delete file
   *
   * @param filename
   * @param {number} index - The index of the file to delete in propertyFileArray.
   */
  public deleteImage(filename: string, index: number): void {
    if (this.propertyFileArray[index] instanceof File) {
      this.propertyFileArray.splice(index, 1);
      return;
    } else {
      if (this.deletedImage == '') {
        this.deletedImage = this.propertyFileArray[index].name;
      } else {
        this.deletedImage = `${this.deletedImage},${this.propertyFileArray[index].name}`;
      }
    }
    this.propertyFileArray.splice(index, 1);
    this.propertyForm.patchValue({
      is_image_deleted: '1',
      remove_image: this.deletedImage,
    });
  }

  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.propertyForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }

  /**
   * listen for options changes country
   *
   * @param {SelectOptionsInterface} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry) return;
    this.propertyForm.controls.state.reset();
    this.propertyForm.patchValue({
      country: selectedCountry.value,
    });
    this.removePacContainer();
    this.initMap(selectedCountry.value);
  }

  /**
   * remove pac container
   *
   */
  public removePacContainer() {
    this.addressHelper.removeAutocompletePacContainer();
  }

  /**
   * listen for options changes state
   *
   * @param {SelectOptionsInterface} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.propertyForm.controls.city.reset();
    this.propertyForm.patchValue({
      state: selectedState.value,
    });
  }

  /**
   * listen for options changes state
   *
   * @param {SelectOptionsInterface} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.propertyForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.propertyForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(selectedCity.additionalDetails[0]?.postal_code_regex))]);
      zipCodeRef.updateValueAndValidity();
    }
    // this loader is set false here because of the delay to set country state city
    this.preDefinedLoader = false;
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.propertyForm.controls;
  }

  // public fileUploadChangeEmitter(file: any): void {
  //   if (file) {
  //     this.propertyForm.patchValue({
  //       property_image: file,
  //       is_image_deleted: '0',
  //     });
  //   }
  // }
  /**
   * change event listener for property type
   *
   * @param {any} data
   */
  public optionChangeEmitterPropertyType(data: any): void {
    if (!data) return;
    if (data?.value) {
      this.propertyForm.patchValue({
        property_type_id: data?.value,
      });
      if (this.toggleEmiterListener) {
        this.propertyForm.get('property_type_id').markAsUntouched();
        this.toggleEmiterListener = false;
      }
    } else {
      if (this.toggleEmiterListener) {
        this.propertyForm.get('property_type_id').markAsTouched();
        this.toggleEmiterListener = false;
      }
    }
  }

  /**
   * add property
   */
  public onSubmit(): void {
    this.canEdit && this.propertyForm.markAllAsTouched();
    if (!this.propertyForm.valid) {
      this.commonHelper.scrollToFirstInvalidControl(this.propertyForm);
      return; // return if the form is invalid
    }
    this.submitLoader = true;
    const payload = {
      ...this.propertyForm.value,
      request_id: this.clientRequestId,
      country_code: this.countryCode,
      roletype: this.userType,
      liability_id: this.selectedMortgageID,
      is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
    };
    payload.apt_number = payload.apt_number ?? '';
    payload.price = this.commonHelper.commaSeparateNumberClean(payload.price, null);
    payload.current_price = this.commonHelper.commaSeparateNumberClean(payload.current_price, null);
    payload.state = this.selectedCountryName == this.unitedKingdom ? null : payload.state;
    // if null, make it undefined.
    payload.property_image = payload.property_image ?? undefined;

    this.commonService.getCityDetail(payload.country, payload.state, payload.city).subscribe((cResponse: APIResponseModel) => {
        if (cResponse?.status) {
          payload.city = cResponse?.data?.id;
          const formData = new FormData();

          for (const file of this.propertyFileArray) {
            if (file instanceof File) {
              formData.append('property_image[]', file);
            }
          }
          // appending every value to form object
          Object.keys(payload).forEach((key) => payload[key] === undefined ? null : formData.append(key, payload[key]));
          // add property
          this.propertyService.addProperty(formData).subscribe((response: APIResponseModel) => {
            if (response.status) {
              this.submitLoader = false;
              this.commonHelper.updateLocalstorageRequestStage(response.data);
              this.toggleModalEmitter.emit({
                ...response.data,
                listStatus: (this.propertyForm.value.id) ? LIST_UPDATE : LIST_NEW,
              });
              payload.id ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();
              this.propertyForm.value.property_image = '';
              this.propertyFileArray = [];
              this.closeModel();
            }
          }, (exception: any) => {
            this.submitLoader = false;
            this.commonHelper.httpResponseHandler(exception?.error);
          });
        }
      }, (exception: any) => {
        this.commonHelper.httpResponseHandler(exception?.error);
        this.submitLoader = false;
      },
    );
  }

  /**
   * delete property details
   */
  public deleteProperty(): void {
    // ACL check
    if (!this.permissions?.['delete']) {
      this.toastrService.info('You do not have permission to delete items for this client.');
      return;
    }

    this.submitLoader = true;
    const sendingData: {} = {
      is_delete: '1',
      id: this.propertyForm.value.id,
      roletype: this.userType,
      request_id: this.clientRequestId,
    };

    const formData = new FormData();

    // appending every value to form object
    Object.keys(sendingData).forEach((key) => {
      if (sendingData[key] == undefined) return;
      formData.append(key, sendingData[key]);
    });

    this.propertyService.addProperty(formData).subscribe((response: APIResponseModel) => {
        if (response.status) {
          this.toggleModalEmitter.emit({
            id: this.propertyForm.value.id,
            listStatus: LIST_DELETE,
          });
          if (this.userType !== 'Consumer') {
            this.commonService.propertyHandlerObservableEvents({
              type: CLEAR_SELECTED_FILE,
            });
          }
          this.commonHelper.toastrDeleteSuccess();
          this.submitLoader = false
          this.closeModel();
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
      () => this.submitLoader = false);
  }

  /**
   * mark a field as touched ( Individual property )
   *
   * @param {string} property
   */
  public markAsTouchedIndividual(property: string): void {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }

  /**
   * close modal
   */
  public closeModel(): void {
    if (!this.submitLoader) {
      this.commonHelper.scrollToTopModal(this.propertyForm);
    }
    this.modalService.close('add-property-modal');
  }

  /**
   * Reset form
   */
  public resetForm(): void {
    this.mortgageStatus = undefined;
    this.selectedMortgageID = undefined;
    this.propertyForm?.reset({
      // property_type_id: this.propertyTypes[0]?.value,
      country: this.defaultCountry,
      is_image_deleted: '0',
      remove_image: [''],
    });
    this.countryCode = environment.DEFAULT_COUNTRY_DIALCODE;
    this.commonHelper.clearfileHanlderObservableEvents();
  }

  /**
   * Establish ACL Permissions. Consumer is assigned full access.
   */
  public getAccessControl(): void {
    if (this.userType === 'Consumer') {
      this.permissions = {
        view: true,
        add: true,
        edit: true,
        delete: true,
      };
    }
  }

  get state() {
    return this.propertyForm.get('state');
  }

  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.propertyForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ?
        this.state.addValidators([Validators.required]) : this.state.clearValidators();
      this.state.updateValueAndValidity();
    });
  }

  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.propertyForm.get('zipcode');
    const stateId = this.propertyForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        if (response.data.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(zipCodeRegex))]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        } else {
          zipCodeRef.setValidators([Validators.required]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }

  /**
   * If user can edit existing asset, or add a new asset.
   */
  get canEdit(): boolean {
    return this.commonHelper.canEditSection(this.isNewAsset, this.permissions) && (this.hasPaidSubscription || this.clientHasPaidSubscription)
  }

  /**
   * toggle emitter
   * @param {boolean} data
   */
  public toggleEmiter(data: boolean) {
    if (data) {
      this.toggleEmiterListener = true; // toggle listener
    }
  }

  /**
   * Whether to disable the 'view / edit' button
   */
  get disableMortgageView(): boolean {
    return this.selectedMortgageID == undefined || this.selectedMortgageID == '' || this.selectedMortgageID == 'add_new' || this.liabilitiesLoading;
  }

  /**
   * open insurance modal
   */
  public openLiabilitiesModal() {
    this.modalService.open('add-liabilities-modal');
    this.removePacContainer();
    this.initMap(this.propertyForm.controls['country'].value);
  }

  /**
   * Option change emitter listener
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public mortgageChangeListener(selectedOption: SelectOptionsInterface): void {
    this.selectedMortgageID = selectedOption?.value;
    if (selectedOption?.value == 'add_new') {
      this.openLiabilitiesModal();
    }
  }

  /**
   * Get auto insurance type information.
   */
  public getMortgageType(): void {
    // Get auto insurance type
    if (!this.mortgageLiabilityType) {
      this.commonService.getLiabilityTypes().subscribe({
        next: (r) => {
          const liabilityTypes: Array<Object> = r.data;
          this.mortgageLiabilityType = liabilityTypes.find((e) => e['is_mortgage']);
        },
        error: (e) => this.commonHelper.httpResponseHandler(e?.error),
        complete: () => this.getMortgageOptions(),
      });
    }
  }

  /**
   * Get Insurance options
   * @param{string}selectedMortgage
   */
  private getMortgageOptions(selectedMortgage?: string) {
    this.liabilitiesLoading = true;
    // Get Insurance Items
    const userRequestId = this.userType === 'Consumer' ? this.localStorageService.getUserData()?.request?.id : this.clientRequestId;
    this.personalDetailsService.getPersonalDetailsWithBeneficiary(this.userType !== 'Consumer' ? userRequestId : null).subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          this.mortgageItems = response.data.user_liability;
          const data: Array<Object> = this.mortgageItems.filter((e) => e['liability_type']?.id == this.mortgageLiabilityType['id']);
          data.forEach((e) => e['displayText'] = `${e['user_liability_loan']['loan_name']} | ${e['user_liability_loan']['account_number']}`);
          data.push({ id: 'add_new', displayText: 'Add New' }, { id: '', displayText: 'None' },
          );
          // Auto Type Insurance alone
          this.mortgageOptions = this.commonHelper.convertToOptionsFormat(data, 'id', 'displayText').sort(this.commonHelper.compareDisplayText);
          this.mortgageOptions = this.commonHelper.placeAtPointOfArray(this.mortgageOptions, (e) => e.value.toLowerCase() == 'add_new', 'start');
          this.mortgageOptions = this.commonHelper.placeAtPointOfArray(this.mortgageOptions, (e) => e.value === '', 'end');
        }
        if (selectedMortgage) {
          this.selectMortgageOption(selectedMortgage);
        }
        this.liabilitiesLoading = false;
      }, error: (e) => {
        this.commonHelper.httpResponseHandler(e.error);
        this.liabilitiesLoading = false;
      },
    });
  }

  /**
   * Will assign {@link mortgageID} to {@link selectedMortgageID} if it exists within <code>{@link mortgageOptions}[x].value</code>.
   * If it does not exist, then an empty string will be assigned to {@link selectedMortgageID}.
   * @param mortgageID
   * @private
   */
  private selectMortgageOption(mortgageID: string) {
    this.selectedMortgageID = this.mortgageOptions.find((e) => e.value == mortgageID)?.value ?? '';
  }

  /**
   * insurance modal event listener
   * @param{any} event
   */
  public modalEventListener(event: any) {
    if (event) {
      this.getMortgageOptions(event['id']);
    } else {
      // close modal event, not submitted form
      this.mortgageStatus = undefined;
    }
    if(event?.listStatus == LIST_DELETE) this.selectedMortgageID = undefined;
    this.removePacContainer();
    this.initMap(this.propertyForm.controls['country'].value);
  }

  /**
   * Event handler for click on 'view/edit' button for selected mortgage.
   */
  clickEditMortgage() {
    const selectedMortgage = this.mortgageItems.find((e) => e['id'] == this.selectedMortgageID);
    this.mortgageEditData = {
      ...selectedMortgage['user_liability_loan'],
      ...selectedMortgage,
      liabilityData: { id: selectedMortgage['liability_type']['id'] },
      type: selectedMortgage['liability_type_short'],
    };
    this.openLiabilitiesModal();
  }

  public fileListingListener($event: FileListingEmitModel) {
    if ($event.image_delete) {
      const file = $event.image_delete;
      this.deleteImage(file, this.propertyFileArray.findIndex((e => e.name == file)));
    }
    if ($event.fileUploadChange) {
      this.fileUploadChangeEmitter($event.fileUploadChange);
    }
    if ($event.isFileDropWhenNoDocumentSelected) {
      this.isFileDrop($event.isFileDropWhenNoDocumentSelected);
    }
  }
}
