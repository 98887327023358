import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * Local storage service
 */
export class SessionStorageService {
  /**
   * store any data
   *
   * @param {string} key
   * @param {any} value
   */
  public storeDataInSession(key: string, value: any): void {
    const data = this.encrypt(JSON.stringify(value));
    sessionStorage.setItem(key, data);
  }

  /**
   * Get session storage value by key
   *
   * @param {string} key
   * @return {any}
   */
  public getDataFromSession(key: string): any {
    if (!sessionStorage.getItem(key)) return null;
    const data = this.decrypt(sessionStorage.getItem(key));
    // Handle case of item stored as invalid json
    try {
      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  }


  /**
   * Encrypt the value using environment.LOCAL_STORAGE_KEY. If environment.ENCRYPT_LOCAL_STORAGE is false, then it will not encrypt.
   * @param{any} value
   * @return{any}
   */
  private encrypt(value: any): any {
    return environment.ENCRYPT_LOCAL_STORAGE ? CryptoJS.AES.encrypt(value, environment.LOCAL_STORAGE_SECRET).toString() : value;
  }

  /**
   * Decrypt the value using environment.LOCAL_STORAGE_KEY. If environment.ENCRYPT_LOCAL_STORAGE is false, then it will not decrypt.
   * @param{any} value
   * @return{any}
   */
  private decrypt(value: any): any {
    return environment.ENCRYPT_LOCAL_STORAGE ? CryptoJS.AES.decrypt(value, environment.LOCAL_STORAGE_SECRET).toString(CryptoJS.enc.Utf8) : value;
  }

  /**
   * Remove an item from Local storage if it exists.
   * @param{string} key Key of the data item.
   */
  public deleteDataByKey(key: string) {
    sessionStorage.removeItem(key);
  }
}
