import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
/**
 * Helper to manage and track multiple loading states at once.
 */
export class LoadStateHelper {
  private _loadingStates: { [key: string]: boolean } = {};


  /**
   * Set a loading state
   * @param state
   * @param isLoading Loading state of the {@link state}. Defaults to true.
   */
  public set(state: string, isLoading: boolean = true) {
    this._loadingStates[state] = isLoading;
  }


  /**
   * Check if the state or states are loading.
   * @param state Can accept a `string` or `string[]` of loaders to check. If `undefined`, then all loaders will be checked.
   * @returns `true` if any {@link state states} are `true`, else `false`.
   */
  public check(state?: string | string[]): boolean {
    const keys = this.sanitize(state);
    for (let key of keys) {
      if (this._loadingStates[key]) {
        return true;
      }
    }
    return false;
  }


  /**
   * Get the loading {@link state state} or states provided. If `undefined`, get all loading states.
   * @param state
   */
  public get(state?: string | string[]): { [p: string]: boolean } {
    const keys = this.sanitize(state);
    const get: { [p: string]: boolean } = {};
    keys.forEach(e => get[e] = this._loadingStates[e]);
    return get;
  }


  /**
   * Clear all loaders from the tracker.
   */
  public clear(states?: string | string[]) {
    const keys = this.sanitize(states);
    keys.forEach(e => delete this._loadingStates[e]);
  }


  /**
   * Sanitize to a `string[]` of keys.
   * @param state
   * @private
   */
  private sanitize(state: string | string[] | undefined): string[] {
    if (state == undefined) {
      state = Object.keys(this._loadingStates);
    }
    if (typeof state == 'string') {
      state = [state];
    }
    return state;
  }
}
