import { Component, OnInit } from '@angular/core';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonService } from '../../../services/common.service';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-digital-vault-agreement',
  templateUrl: './digital-vault-agreement.component.html',
  styleUrls: ['../documents.css'],
})
/**
 * digital vault agreement
 */
export class DigitalVaultAgreementComponent implements OnInit {
  public customerSupportMail: string;
  public transferFee: string;

  /**
   * @constructor
   */
  constructor(
    private commonService: CommonService,
    private slugInterceptorService: SlugInterceptorService,
    private subscriptionService: ConsumerSubscriptionService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.getContactDetails();
    this.getPricing();
  }

  /**
   * Retrieves pricing details
   *
   */
  private getPricing() {
    this.subscriptionService.getPricingDetails().subscribe({
      next: (response: any) => this.transferFee = response.data.vault_transfer_amount,
      complete: () => console.log('pricing', this.transferFee),
    });
  }

  /**
   * get contact details
   */
  private getContactDetails() {
    this.commonService.getContactUsDetails().subscribe(
      (response) => this.customerSupportMail = response.data?.support_email);
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, false, true);
  }
}
