import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { FileValidationOptionsInterface, SelectOptionsInterface, ToggleOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { ALPHA_NUMERIC_PATTERN_WITHOUT_SPACE, ALPHA_NUMERIC_WITH_HYPHEN_PATTERN, ALPHA_NUMERIC_WITH_HYPHEN_SINGLEQUOTE_SPACE_PATTERN, CURRENT_YEAR, LIST_DELETE, LIST_NEW, LIST_UPDATE, NUMBERS_VALID_DOLLARS, TOGGLE_OPTIONS_YES_NO, UNITED_KINGDOM, YEAR_MASK, fileValidationOptions } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../../helper/validation.helper';
import { PersonalDetailsService } from '../../../../services/personal-details.service';
import { FileListingEmitModel, FileUploadSettingsModel } from '../../../../sharedComponent/file-listing/file-listing.component';
import { ConsumerSubscriptionService } from '../../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
})
/**
 * Add vehcile modal
 */
export class AddVehicleComponent implements OnInit {
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() isNewVehicle: boolean = true;
  @Input() clientHasPaidSubscription:boolean = false;
  @Output() toggleModalEmitter = new EventEmitter<any>();
  public readonly YEAR_MASK=YEAR_MASK;
  public readonly currentYear = CURRENT_YEAR;
  public maxYear:any;
  public readonly unitedKingdom: string = UNITED_KINGDOM;
  public readonly toggleOptions=TOGGLE_OPTIONS_YES_NO;
  public vehicleForm: FormGroup;
  public preDefinedLoader: boolean=false;
  public submitLoader: boolean;
  public isImageFile: boolean = false;
  public isDigitalFiles: boolean = false;
  public fileValidationOptions: FileValidationOptionsInterface;
  public PIC_URL: string;
  public countryCode=environment.DEFAULT_COUNTRY_DIALCODE;
  public userType: string;
  public requestId: string;
  public defaultCountry: number;
  public file: any;
  public selectedCountryName: string = '';
  public paymentStatus: number = 0;
  public insuranceOptions: Array<SelectOptionsInterface>;
  public loanOptions: Array<SelectOptionsInterface>;
  private insuranceItems: Array<Object>;
  private loanItems: Array<Object>;
  public selectedInsuranceID: string;
  public selectedLoanID: string;
  public insuranceInputs: Object = {};
  public loanEditData: Object = {};
  private autoInsuranceType: Object;
  public vehicleLoanType: Object;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  private insuranceLoading: boolean = false;
  private loanLoading: boolean = false;
  // public preDefinedLoader: boolean = false;
  public isFileDropWhenNoDocumentSelected: boolean;
  public activateFileUploadLoader: boolean;
  public filePercentage: any;
  public clientRequestId: string;
  public vehicleFileArray: Array<any> = [];
  public downloadLink: any;
  public downloadFileName: any;
  public deletedImage: string;
  public updateSubscription: Subscription;
  public fileUploadSettings: FileUploadSettingsModel;
  private hasPaidSubscription: boolean;


  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private commonHelper: CommonHelper,
    private vehicleService: VehicleService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private personalDetailsService: PersonalDetailsService,
    private validationHelper: ValidationHelper,
    private subscriptionService: ConsumerSubscriptionService,
  ) {
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.hasPaidSubscription = r);
    this.maxYear = (new Date().getFullYear()) + 2;
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.getVehicleLoanType();
    // getAutoInsuranceType will call getInsuranceOptions on completion, as getInsuranceOptions depends on the result
    this.getAutoInsuranceType();
    this.commonHelper.clearfileHanlderObservableEvents();
    this.fileUploadSettings = {
      fileValidationOptions: fileValidationOptions,
      hideFileOperations: true,
      allowMultiple: true,
      isDigitalFiles: true,
      displayFileName: true,
    };
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.defaultCountry = this.localStorageService.getDataByKey('country');

    this.initForm();
    this.resetForm();

    // Listening for reset
    this.vehicleService.resetVehicleFormObserve.subscribe((trigger: boolean) => {
      if (trigger) {
        this.resetForm();
      }
    });
    this.isEnableState();
    // refresh auto insurance
    this.vehicleService.refreshDetailsCommonObserve.subscribe((response: any) => {
      // if undefined then use null
      this.getInsuranceOptions(response?.id ?? null);
    });
    if (!this.canAccessForm) {
      this.vehicleForm.disable();
    }
    this.clientRequestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.getAccessControl();
    this.deletedImage = '';
  }


  private initForm() {
    this.vehicleForm = this.formBuilder.group({
      vehicle_type: ['', {
        updateOn: 'blur',
        validators: [Validators.required,Validators.maxLength(50), Validators.pattern(ALPHA_NUMERIC_WITH_HYPHEN_SINGLEQUOTE_SPACE_PATTERN)],
      }],
      vehicle_make: ['', {
        updateOn: 'blur',
        validators: [Validators.required, Validators.pattern(ALPHA_NUMERIC_WITH_HYPHEN_SINGLEQUOTE_SPACE_PATTERN),Validators.maxLength(50)],
      }],
      vehicle_model: ['', {
        updateOn: 'blur',
        validators: [Validators.required, Validators.maxLength(50), Validators.pattern(ALPHA_NUMERIC_WITH_HYPHEN_SINGLEQUOTE_SPACE_PATTERN)],
      }],
      year: ['', { updateOn: 'blur', validators: [this.yearValidator.bind(this)] }],
      licence: ['', { updateOn: 'blur', validators: [Validators.pattern(ALPHA_NUMERIC_WITH_HYPHEN_PATTERN)] }],
      state: [''],
      country: ['', []],
      vehicle_image: [''],
      remove_image: [''],
      vehicle_value: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
      estimated_value: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
      description: ['', { updateOn: 'blur', validators: [Validators.minLength(3), Validators.maxLength(500)] }],
      id: [''],
      is_image_deleted: ['0'],
      insurance_id: [''],
      vin: ['', { updateOn: 'blur', validators: [Validators.pattern(ALPHA_NUMERIC_PATTERN_WITHOUT_SPACE)] }],
      payment_status: ['0'],
    });

    // Automatically trim whitespace for all controls
    Object.keys(this.vehicleForm.controls).forEach(controlName => {
      this.vehicleForm.get(controlName)?.valueChanges.subscribe(() => 
        this.validationHelper.trimWhitespace(this.vehicleForm.get(controlName))
      );
    });

    this.validationHelper.setValidationErrors({
      year: { pattern: `Please enter a year above 1700`, isNotAllowed: 'Please enter a year above 1700', mask: null },
      vehicle_value: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places.', pattern: false },
      estimated_value: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places.', pattern: false },
    });
  }


  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validationHelper.getErrors(controlKey, this.vehicleForm);
  }


  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.vehicleForm?.controls;
  }


  /**
   * file drop
   * restrict file upload for file drop when no document selected
   * @param data
   */
  public isFileDrop(data) {
    this.isFileDropWhenNoDocumentSelected = data;
  }


  /**
   * setter for editing vehicle details
   *
   * @param {any} data
   */
  @Input() set editData(data: any) {
    this.resetForm();
    this.vehicleFileArray = [];
    this.deletedImage = '';

    if (data?.vehicle_image) {
      let name = data.vehicle_image.split(',');
      let extension = data.file_extension.split(',');
      for (let i = 0; i < name.length; i++) {
        if (name[i].length > 0) {
          const files = {
            name: name[i],
            type: extension[i],
          };
          this.vehicleFileArray.push(files);
        }
      }
    }
    if (data && data?.id) {
      this.selectedInsuranceID = data?.insurance_id ?? '';
      this.selectedLoanID = data?.liability_id ?? '';

      if (!this.insuranceItems?.find((e) => e['id'] == this.selectedInsuranceID)) {
        this.selectedInsuranceID = '';
      }

      if (!this.loanItems?.find((e) => e['id'] == this.selectedLoanID)) {
        this.selectedLoanID = '';
      }

      this.vehicleForm.patchValue({
        ...data,
        vehicle_image: '',
        insurance_agent_contact: '',
        country: data?.country?.id,
        state: data?.state?.id || null,
      });
      this.paymentStatus = data?.payment_status ?? this.paymentStatus;
      let tempPhoneNumber;
      if (data?.insurance_agent_contact) {
        tempPhoneNumber = data?.insurance_agent_contact;
        this.countryCode = data?.country_code;
      }

      this.vehicleForm.patchValue({
        insurance_agent_contact: tempPhoneNumber,
      });
    }
    this.commonService.filePreviewObservableEvents({ isPreviewProcess: false });
  }


  /**
   * Get auto insurance type information.
   */
  public getAutoInsuranceType(): void {
    // Get auto insurance type
    if (!this.autoInsuranceType) {
      this.commonService.getInsuranceTypes().subscribe({
        next: (r) => {
          const insuranceTypes: Array<Object> = r.data;
          this.autoInsuranceType = insuranceTypes.find((e) => e['is_auto']);
        },
        error: (e) => this.commonHelper.httpResponseHandler(e?.error),
        complete: () => this.getInsuranceOptions(),
      });
    }
  }


  /**
   * Get vehicle loan type information.
   */
  public getVehicleLoanType(): void {
    if (!this.vehicleLoanType) {
      this.commonService.getLiabilityTypes().subscribe({
        next: (r) => {
          const liabilityTypes: Array<Object> = r.data;
          this.vehicleLoanType = liabilityTypes.find((e) => e['is_vehicle_loan']);
        },
        complete: () => this.getLoanOptions(),
      });
    }
  }


  /**
   * Get Insurance options
   * @param{string}selectedInsurance
   */
  private getInsuranceOptions(selectedInsurance?: string) {
    this.insuranceLoading = true;
    // Get Insurance Items
    const userRequestId = this.userType === 'Consumer' ? this.localStorageService.getUserData()?.request?.id : this.requestId;
    this.personalDetailsService.getPersonalDetailsWithBeneficiary(this.userType !== 'Consumer' ? userRequestId : null).subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          this.insuranceItems = response.data.user_insurance;
          const data: Array<Object> = this.insuranceItems.filter((e) => e['insurance_type']?.id == this.autoInsuranceType?.['id']);
          data.forEach((e) => e['displayText'] = e['company_name']);
          data.push({ id: 'add_new', displayText: 'Add New' }, { id: '', displayText: 'None' },
          );
          // Auto Type Insurance alone
          this.insuranceOptions = this.commonHelper.convertToOptionsFormat(data, 'id', 'displayText').sort(this.commonHelper.compareDisplayText);
          this.insuranceOptions = this.commonHelper.placeAtPointOfArray(this.insuranceOptions, (e) => e.value.toLowerCase() == 'add_new', 'start');
          this.insuranceOptions = this.commonHelper.placeAtPointOfArray(this.insuranceOptions, (e) => e.value === '', 'end');
        }
        if (this.insuranceOptions.find((e) => e.value == selectedInsurance)) {
          this.selectedInsuranceID = selectedInsurance;
        }
        this.insuranceLoading = false;
      }, error: (e) => {
        this.commonHelper.httpResponseHandler(e.error);
        this.insuranceLoading = false;
      },
    });
  }


  /**
   * Retrieve Loan Types
   * @param{string} selectedLoan
   */
  private getLoanOptions(selectedLoan?: string) {
    this.loanLoading = true;
    const userRequestId = this.userType === 'Consumer' ? this.localStorageService.getUserData()?.request?.id : this.requestId;
    this.personalDetailsService.getPersonalDetailsWithBeneficiary(this.userType !== 'Consumer' ? userRequestId : null).subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          this.loanItems = response.data?.user_liability;
          const data: Array<Object> = this.loanItems?.filter((e) => e['liability_type_id'] == this.vehicleLoanType?.['id']);
          data.forEach((e) => e['displayText'] = `${e['user_liability_loan']['loan_name']}`);
          data.push({ id: 'add_new', displayText: 'Add New' }, { id: '', displayText: 'None' },
          );
          // Auto Type Insurance alone
          this.loanOptions = this.commonHelper.convertToOptionsFormat(data, 'id', 'displayText').sort(this.commonHelper.compareDisplayText);
          this.loanOptions = this.commonHelper.placeAtPointOfArray(this.loanOptions, (e) => e.value.toLowerCase() == 'add_new', 'start');
          this.loanOptions = this.commonHelper.placeAtPointOfArray(this.loanOptions, (e) => e.value === '', 'end');
        }
        if (this.loanOptions.find((e) => e.value == selectedLoan)) {
          this.selectedLoanID = selectedLoan;
        }
        this.loanLoading = false;
      }, error: () => {
        this.loanLoading = false;
      },
    });
  }


  /**
   * Whether to disable the 'view / edit' button (insurance)
   */
  get disableInsuranceView(): boolean {
    return this.selectedInsuranceID == '' || this.selectedInsuranceID == 'add_new' || this.insuranceLoading;
  }


  /**
   * Whether to disable the 'view / edit' button (loan)
   */
  get disableLoanView(): boolean {
    return this.selectedLoanID == '' || this.selectedLoanID == 'add_new' || this.loanLoading;
  }


  /**
   * Option change emitter listener
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitterInsuranceType(selectedOption: SelectOptionsInterface): void {
    this.selectedInsuranceID = selectedOption?.value;
    this.vehicleService.setInsuranceType(true);
    if (selectedOption?.value == 'add_new') {
      this.openInsuranceModal();
    }
  }


  /**
   * Option change emitter listener
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitterLoanType(selectedOption: SelectOptionsInterface): void {
    this.selectedLoanID = selectedOption?.value;
    if (selectedOption?.value == 'add_new') {
      this.openLoanModal();
    }
  }


  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry) return;
    this.vehicleForm.controls.state.reset();
    this.vehicleForm.patchValue({
      country: selectedCountry.value,
    });
  }


  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.vehicleForm.patchValue({
      state: selectedState.value,
    });
  }


  /**
   * On Change toggle
   *
   * @param {ToggleOptionsInterface} selectedValue
   */
  public onChangeToggle(selectedValue: ToggleOptionsInterface): void {
    if (!selectedValue) return;
    this.paymentStatus = selectedValue?.value;
    this.selectedLoanID = '';
  }

  /**
   * year validation
   * @param {FormControl}control
   * @return {boolean}
   */
  yearValidator(control: FormControl):{ [s: string]: boolean }{
    const currentYear = new Date().getFullYear();
    const enteredYear = new Date(control.value).getFullYear();
    // Check if the entered year is between 1700 and current year + 2
    if (enteredYear < 1700 || enteredYear > currentYear + 2) {
      return { isNotAllowed: true };
    } else {
      return null;
    }
  }


  /**
   * Upload file
   *
   * @param files
   */
  public fileUploadChangeEmitter(files: any): void {
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.vehicleFileArray.push(file);
      }
    }
  }


  /**
   * delete file
   *
   * @param filename
   * @param index
   */
  public deleteImage(filename: string, index: number): void {
    if (this.vehicleFileArray[index] instanceof File) {
      this.vehicleFileArray.splice(index, 1);
      return;
    } else {
      if (this.deletedImage == '') {
        this.deletedImage = this.vehicleFileArray[index].name;
      } else {
        this.deletedImage = `${this.deletedImage},${this.vehicleFileArray[index].name}`;
      }
    }
    this.vehicleFileArray.splice(index, 1);
    this.vehicleForm.patchValue({
      is_image_deleted: '1',
      remove_image: this.deletedImage,
    });
  }


  /**
   * add Vehicle
   */
  public onSubmit(): void {
    this.canEdit && this.vehicleForm.markAllAsTouched();
    if (!this.vehicleForm.valid) {
      this.commonHelper.scrollToFirstInvalidControl(this.vehicleForm);
      return; // return if the form is invalid
    }
    this.submitLoader = true;
    if (this.userType === 'Consumer') {
      const requestData = {
        ...this.vehicleForm.value,
        insurance_id: this.selectedInsuranceID,
        request_id: this.localStorageService.getUserData()?.request?.id,
        country_code: this.countryCode,
        roletype: this.userType,
        payment_status: this.paymentStatus,
        liability_id: this.paymentStatus == 0 ? this.selectedLoanID : '',
        is_state: this.selectedCountryName == this.unitedKingdom ? 0 : 1,
      };
      requestData.vehicle_value = requestData.vehicle_value ? this.commonHelper.commaSeparateNumberClean(requestData.vehicle_value, null) : ''; // Remove commas for backend.
      requestData.estimated_value = requestData.estimated_value ? this.commonHelper.commaSeparateNumberClean(requestData.estimated_value, null) : ''; // Remove commas for backend.
      requestData['state'] = this.selectedCountryName == this.unitedKingdom ? null : requestData['state'] ? requestData['state'] : null;
      const formData = new FormData();

      for (const file of this.vehicleFileArray) {
        if (file instanceof File) {
          formData.append('vehicle_image[]', file);
        }
      }
      // appending every value to form object
      Object.keys(requestData).forEach((key) => {
        if (requestData[key] == undefined) return;
        formData.append(key, requestData[key]);
      });

      // add vehicle
      this.vehicleService.addVehicle(formData).subscribe((response: APIResponseModel) => {
        this.submitLoader = false;
        if (response.status) {
          this.commonHelper.updateLocalstorageRequestStage(response.data);
          this.toggleModalEmitter.emit({
            ...response.data,
            listStatus: (this.vehicleForm.value.id) ? LIST_UPDATE : LIST_NEW,
          });
          requestData.id ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();
          this.closeModel();
        }
      }, (exception: any) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    } else {
      const requestData = {
        ...this.vehicleForm.value,
        insurance_id: this.selectedInsuranceID,
        country_code: this.countryCode,
        roletype: this.userType,
        request_id: this.requestId,
        payment_status: this.paymentStatus,
        is_state: this.selectedCountryName == this.unitedKingdom ? 0 : 1,
      };
      requestData.vehicle_value = this.commonHelper.commaSeparateNumberClean(requestData.vehicle_value, null); // Remove commas for backend.
      requestData['state'] = this.selectedCountryName == this.unitedKingdom ? null : requestData['state'];
      const formData = new FormData();
      // appending every value to form object
      Object.keys(requestData).forEach((key) => {
        if (requestData[key] == undefined) return;
        formData.append(key, requestData[key]);
      });
      // add vehicle
      this.vehicleService.addVehicle(formData).subscribe((response: APIResponseModel) => {
        this.submitLoader = false;
        if (response.status) {
          this.toggleModalEmitter.emit({
            ...response.data,
            listStatus: (this.vehicleForm.value.id) ? LIST_UPDATE : LIST_NEW,
          });
          this.vehicleFileArray = [];
          this.closeModel();
        }
      }, (exception: any) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    }
  }


  /**
   * delete vehicle details
   */
  public deleteVehicle(): void {
    // ACL check
    if (!this.permissions?.['delete']) {
      this.toastrService.info('You do not have permission to delete items for this client.');
      return;
    }
    this.submitLoader = true;
    if (this.userType === 'Consumer') {
      const sendingData = {
        is_delete: '1',
        id: this.vehicleForm.value.id,
        request_id: this.localStorageService.getUserData()?.request?.id,
        roletype: this.userType,
      };
      const formData = new FormData();
      // appending every value to form object
      Object.keys(sendingData).forEach((key) => {
        if (sendingData[key] == undefined) return;
        formData.append(key, sendingData[key]);
      });
      this.vehicleService.addVehicle(formData).subscribe((response: APIResponseModel) => {
        this.submitLoader = false;
        if (response.status) {
          this.toggleModalEmitter.emit({
            id: this.vehicleForm.value.id,
            listStatus: LIST_DELETE,
          });
          this.commonHelper.toastrDeleteSuccess();
          this.closeModel();
        }
      }, (exception: any) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    } else {
      const sendingData = {
        is_delete: '1',
        id: this.vehicleForm.value.id,
        roletype: this.userType,
        request_id: this.requestId,
      };
      const formData = new FormData();
      // appending every value to form object
      Object.keys(sendingData).forEach((key) => {
        if (sendingData[key] == undefined) return;
        formData.append(key, sendingData[key]);
      });
      this.vehicleService.addVehicle(formData).subscribe((response: APIResponseModel) => {
        this.submitLoader = false;
        if (response.status) {
          this.toggleModalEmitter.emit({
            id: this.vehicleForm.value.id,
            listStatus: LIST_DELETE,
          });
          this.closeModel();
        }
      }, (exception: any) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    }
  }


  /**
   * close modal
   */
  public closeModel(): void {
    if(!this.submitLoader) {
      this.commonHelper.scrollToTopModal(this.vehicleForm);
      this.modalService.close('add-vehicle-modal');
    }
  }


  /**
   * Reset form
   */
  public resetForm(): void {
    this.vehicleForm?.reset({
      country: this.defaultCountry,
      is_image_deleted: '0',
      remove_image: [''],
      insurance_id: '',
    });
    this.paymentStatus = 0;
    this.selectedInsuranceID = '';
    this.selectedLoanID = '';
    this.countryCode = environment.DEFAULT_COUNTRY_DIALCODE;
    this.commonHelper.clearfileHanlderObservableEvents();
  }


  /**
   * Establish ACL Permissions. Consumer is assigned full access.
   */
  public getAccessControl(): void {
    if (this.userType === 'Consumer') {
      this.permissions = {
        view: true,
        add: true,
        edit: true,
        delete: true,
      };
    }
  }


  /**
   * marked field
   * @param{string} property
   */
  public markAsTouchedType(property: string) {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }


  /**
   * Get state
   */
  get state() {
    return this.vehicleForm.get('state');
  }


  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.vehicleForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      // this.selectedCountryName !== this.unitedKingdom ?
      //   this.state.addValidators([Validators.required]) : this.state.clearValidators();
      this.state.updateValueAndValidity();
    });
  }


  /**
   * If user can edit existing asset, or add a new asset.
   */
  get canEdit(): boolean {
    return this.commonHelper.canEditSection(this.isNewVehicle, this.permissions) && (this.hasPaidSubscription || this.clientHasPaidSubscription);
  }


  /**
   * open insurance modal
   * @param{string} editID
   */
  public openInsuranceModal(editID?: string) {
    const selectedInsurance = this.insuranceItems.find((e) => e['id'] == editID);
    // Create new object to force editdata update in add_insurance
    this.insuranceInputs['editData'] = !selectedInsurance ? { insurance_type: this.autoInsuranceType } : { ...selectedInsurance };
    this.insuranceInputs['isNewAsset'] = this.selectedInsuranceID == 'add_new';

    this.modalService.open('add-insurance-modal');
  }


  /**
   * open loan modal
   */
  public openLoanModal() {
    this.modalService.open('add-liabilities-modal');
  }


  /**
   * Event handler for click on 'view/edit' button for selected loan.
   */
  public editVehicleLoan() {
    const selectedLoan = this.loanItems.find((e) => e['id'] == this.selectedLoanID);

    this.loanEditData = {
      ...selectedLoan?.['user_liability_loan'],
      ...selectedLoan,
      liabilityData: { id: selectedLoan['liability_type']['id'] },
      type: selectedLoan['liability_type_short'],
    };
    this.openLoanModal();
  }


  /**
   * insurance modal event listener
   * @param{any} event
   */
  public modalEventListener(event: any) {
    this.getInsuranceOptions();
    switch (event?.listStatus) {
      case LIST_UPDATE || LIST_NEW:
        this.selectedInsuranceID = event['insurance_type_id'];
        break;

      case LIST_DELETE:
        this.selectedInsuranceID = '';
        break;
    }
    this.modalService.open('add-vehicle-modal');
  }


  /**
   * loan modal event listener
   * @param{any} event
   */
  public loanModalEventListener(event: any) {
    if (event?.listStatus != LIST_DELETE) {
      this.getLoanOptions(event?.id);
    } else {
      this.selectedLoanID = '';
    }
    this.getLoanOptions();
  }


  public fileListingListener($event: FileListingEmitModel) {
    if ($event.image_delete) {
      const file = $event.image_delete;
      this.deleteImage(file, this.vehicleFileArray.findIndex((e => e.name == file)));
    }
    if ($event.fileUploadChange) {
      this.fileUploadChangeEmitter($event.fileUploadChange);
    }
    if ($event.isFileDropWhenNoDocumentSelected) {
      this.isFileDrop($event.isFileDropWhenNoDocumentSelected);
    }
  }
}


