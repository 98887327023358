import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { LPEmitterInterface, LPSettingsInterface, LPUserPeopleInterface } from '../list-people/list-people-interfaces';

@Component({
  selector: 'app-chip-list-people',
  templateUrl: './chip-list-people.component.html',
  styleUrls: ['./chip-list-people.component.css']
})
export class ChipListPeopleComponent implements OnInit {
  @Output() clickEmitter = new EventEmitter<LPEmitterInterface>();
  @Input() userPeopleData: Array<LPUserPeopleInterface>;
  @Input() sectionName: string;
  cardRootNodeID: string = 'person_card_root';
  userData: any;
  primaryAdvisorId: string;
  isMobile: boolean;
  static user_marital_status: any;
  static user_mail_id: string;
  static section_name: string;
  isChecked: boolean = false;
  removeIcon = faXmark;
  settings: LPSettingsInterface;
  cssCheckbox = (person: LPUserPeopleInterface) => ({ 'disable': person['checkbox']?.['disableCheckbox'], 'enable': !person['checkbox']?.['disableCheckbox'], 'checked': person['checkbox']['isChecked'] });

  @Input() set lpSettings(settings: LPSettingsInterface) {
    this.settings = settings;
  }
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes?.userPeopleData.currentValue)
  }

  /**
   * Return the {@link LPUserPeopleInterface} object with the specified id.
   * @param id
   * @private
   */
  private getPerson(id: string): LPUserPeopleInterface {
    return this.userPeopleData.find((e) => e.id === id);
  }

    /**
   * Returns an {@link LPEmitterInterface} with {@link LPEmitterInterface.id id},
   * {@link LPEmitterInterface.person person}, and {@link LPEmitterInterface.disabled disabled} initialized based on
   * the {@link id given id}.
   * @param id
   * @private
   */
    private getEmitObjBase(id: string): LPEmitterInterface {
      const person = this.getPerson(id);
      return {
        id: id,
        person: person,
        disabled: person.isDisabled,
      };
    }

  /**
   * Handle delete click events.
   * @param id
   */
  clickDelete(id: any) {
    const payload = this.getEmitObjBase(id);
    payload.clickedDelete = true;
    this.clickEmitter.emit(payload);
  }

}
