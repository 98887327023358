import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';


@Component({
  selector: 'app-consumer-landing-content',
  templateUrl: './consumer-landing-content.component.html',
})
/**
 * consumer landing page which contains content
 */
export class ConsumerLandingContentComponent implements OnInit {
  public mobile: boolean;
  public innerWidth: number;
  public menuActive: string;
  public vaultVideo: string;
  /**
   * constructor
   */
  constructor(
    private router: Router,
    private slugInterceptorService: SlugInterceptorService,
    private commonService: CommonService,
    private modalService: CommonModelService,
    private scroller: ViewportScroller,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute
  ) {}

  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.onResize();
    this.route.queryParams.subscribe(params => {
      if (params.scrollTestimonial) {
        this.scrollTestimonialSection();
      }
    });
  }

  /**
   * window resize
   * @param {any} event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.innerWidth = window.innerWidth;
    this.mobile = this.innerWidth <= 767;
  }

  /**
   * @param{string}url
   * on menu change
   */
  public menuChange(url?: string) {
    if (url) {
      this.menuActive = url;
      void this.slugInterceptorService.navigate([url]);
      this.commonService.setActiveNavbarTrigger(this.menuActive);
    }
  }
  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');

  }

  public onClickDiscover() {
    this.scroller.scrollToAnchor('how-legacynow-works');
  }

  /**
   * scroll to testimonial
   */
  public scrollTestimonialSection(){
    const testimonialSection = document.getElementById('testimonialSection');
    testimonialSection.scrollIntoView({ behavior: 'smooth', block: "start" });
  }
  
  /**
   * Scrolls to the 'aboutDigitalVault' section on the page.
   */
  public onClickLearnMore() {
    const aboutDVSection = document.getElementById('aboutDigitalVault');
    aboutDVSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
