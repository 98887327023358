import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-bubble-radio',
  templateUrl: './bubble-radio.component.html',
  styleUrls: ['./bubble-radio.component.css'],
})
/**
 * Bubble-style buttons which act like a collection radio-select inputs.
 */
export class BubbleRadioComponent {
  @Input() options: BubbleRadioOption[] = [];
  @Input() selectedIndex: number = 0;
  @Output() onClick: EventEmitter<BubbleRadioEmitModel> = new EventEmitter();

  onClickOption(index: number) {
    this.onClick.emit({ index: index, option: this.options[index] });
    this.selectedIndex = index;
  }
}

export interface BubbleRadioEmitModel{ index: number, option: BubbleRadioOption }

export interface BubbleRadioOption {
  text: string;
  id: any;
}
