/* eslint-disable linebreak-style */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Socket, io } from 'socket.io-client';
import { DELETE, MESSAGE, OFFLINE, ONLINE, READ, TYPING, UPDATE } from 'src/constants/socket-events.const';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';


@Injectable({
  providedIn: 'root',
})

/**
 * Socket Service
 */
export class SocketService {
  private socket: Socket;
  private message$ = new BehaviorSubject({});
  private typing$ = new BehaviorSubject({});
  private onlineStatus$ = new BehaviorSubject({});
  private offlineStatus$ = new BehaviorSubject({});
  private readStatus$ = new BehaviorSubject({});
  private removeMessageStatus$ = new BehaviorSubject({});
  private editMessageStatus$ = new BehaviorSubject({});
  private professionalAppointmentStatus$ = new BehaviorSubject({});
  proofOfDeathStatus$ = new BehaviorSubject({});
  proofOfDeathStatusObservable$: Observable<{}>;
  private adminSettings$ = new BehaviorSubject({});
  private adminDeathVerifyStatus$ = new BehaviorSubject({});
  private idVerificationStatus$ = new BehaviorSubject({});
  private slugDetailsUpdate$ = new BehaviorSubject({});
  private socketListnersEstablished = false;


  /**
   * constructor
   */
  constructor(private readonly localStorageService: LocalStorageService) {
    this.proofOfDeathStatusObservable$ = this.proofOfDeathStatus$.asObservable();
  }

  /**
   * connect to socket
   */
  public connectSocket() {
    const senderId = this.localStorageService.getUserData()?.user?.id;

    if (senderId !== undefined) {
      this.socket = io(environment.SOCKET_URL + '?id=' + senderId + '&from_id_type=user', { forceNew: true, reconnectionDelay: 2000, timeout: 100000 });
    }
    if (senderId === undefined || this.socket) {
      this.socket = io(environment.SOCKET_URL);
    }
    this.socket.connect();
    this.establishSocketEventListeners();
  }

  /**
   * disconnect socket
   */
  public disconnectSocket() {
    this.socket?.close();
  }

  /**
   * Set use socket
   * @param use
   */
  public setUseSocket(use: Boolean): void {
    if (use) {
      this.connectSocket();
    } else {
      this.disconnectSocket();
    }
  }

  /**
   * send message
   * @param{{}}data
   */
  public sendMesssage(data: {}) {
    if (Object.keys(data)?.length > 0) {
      this.socket.emit(MESSAGE, data);
    }
  }

  /**
   * get new message
   * @return {Observable}
   */
  public getNewMessage(): Observable<any> {
    return this.message$?.asObservable().pipe(tap(response =>{
      console.log(response);
    }))
  };

  /**
   * send typing event
   * @param{{}}data
   */
  public onTyping(data: {}) {
    this.socket.emit(TYPING, data);
  }

  /**
   * get online users
   * @return {Observable}
   */
  public getOnlineUsers(): Observable<any> {
    return this.onlineStatus$?.asObservable();
  };

  /**
   * get typing event
   * @return {Observable}
   */
  public getTypingEvent(): Observable<any> {
    return this.typing$?.asObservable();
  };

  /**
   * get offline event
   * @return {Observable}
   */
  public getOfflineEvent(): Observable<any> {
    return this.offlineStatus$?.asObservable();
  };

  /**
   * send read message
   * @param{{}}data
   */
  public sendReadMessage(data: {}) {
    this.socket.emit(READ, data);
  }

  /**
   * listen read event
   * @return {Observable}
   */
  public listenReadEvent(): Observable<any> {
    return this.readStatus$?.asObservable();
  };

  /**
   * remove message
   *
   * @param {object} data
   */
  public removeMessage(data: {}) {
    this.socket.emit(DELETE, data);
  };

  /**
   * listen remove message
   * @return {Observable}
   */
  public listenRemoveMessage(): Observable<any> {
    return this.removeMessageStatus$?.asObservable();
  };

  /**
   * edit message
   *
   * @param {object} data
   */
  public editMessage(data: {}) {
    this.socket.emit(UPDATE, data);
  };

  /**
   * listen edit message
   * @return {Observable}
   */
  public listenEditMessage(): Observable<any> {
    return this.editMessageStatus$?.asObservable();
  };

  /**
   * listen appointment notifications
   * @return {Observable}
   */
  public listenAppointmentStatus(): Observable<any> {
    return this.professionalAppointmentStatus$?.asObservable();
  }

  /**
   * Listens pod status
   * @return{{}}
   */
  public listenPodStatus(): Observable<any> {
    return this.proofOfDeathStatus$?.asObservable();
  }

  /**
   * receive admin settings from the socket
   * @return{Observable}
   */
  public receiveAdminSettings(): Observable<any> {
    return this.adminSettings$?.asObservable();
  }

  /**
   * listen admin death verify status from the socket
   * @return{Observable}
   */
  public listenAdminDeathVerifyStatus(): Observable<any> {
    return this.adminDeathVerifyStatus$?.asObservable();
  }

  /**
   * Id Pal- verification status
   * @returns
   */
  public idVerificationStatus(): Observable<any> {
    return this.idVerificationStatus$?.asObservable();
  }

  /**
   * Listen Payment status
   * @returns{Observable}
   */
  public listenPaymentStatus(): Observable<any> {
    return this.idVerificationStatus$?.asObservable();
  }

  /**
   * listen consumer adding and removing from professional, will update in user and slug details
   * @returns user, slug_details details
   */
  public clientMigrateUnderCompanyListener(): Observable<any> {
    return this.slugDetailsUpdate$.asObservable();
  }

  private establishSocketEventListeners() {
    if (!this.socketListnersEstablished) {
      const subjects: { [key: string]: BehaviorSubject<any>[] } = {
        'user_payment_status': [this.idVerificationStatus$],
        'idpal_submission': [this.idVerificationStatus$],
        'admin_death_verify_status': [this.proofOfDeathStatus$, this.adminDeathVerifyStatus$],
        'appointment_status_updated': [this.professionalAppointmentStatus$],
        'client_added': [this.slugDetailsUpdate$],
        'professional_user_convert_normal_user': [this.slugDetailsUpdate$],
        'admin_site_settings': [this.adminSettings$],
        [UPDATE]: [this.editMessageStatus$],
        [MESSAGE]: [this.message$],
        [DELETE]: [this.removeMessageStatus$],
        [READ]: [this.readStatus$],
        [ONLINE]: [this.onlineStatus$],
        [TYPING]: [this.typing$],
        [OFFLINE]: [this.offlineStatus$],
      };
      Object.keys(subjects).forEach(subjectKey => {
        this.socket.on(subjectKey, message => {
          if (message || Object.keys(message || {})?.length > 0) {
            subjects[subjectKey].forEach(subject => subject.next(message));
          }
        });
      });
    }
    this.socketListnersEstablished = true;
  }
}
