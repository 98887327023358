import {Component, OnInit} from '@angular/core';
import {CommonHelper} from 'src/app/helper/common.helper';
import {APIResponseModel} from 'src/app/interface/response.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import {PersonalDetailsService} from 'src/app/services/personal-details.service';

@Component({
  selector: 'app-account-assets',
  templateUrl: './account-assets.component.html'
})
/**
 * Account and assets
 */
export class AccountAssetsComponent implements OnInit {
  public preLoader:boolean;
  public accountArray:Array<any>;
  public creditCardArray:Array<any>;
  public accessVault: boolean;
  /**
   * @constructor
   */
  constructor(
    private personalDetailsService:PersonalDetailsService,
    private commonHelper:CommonHelper,
    private localStorageService: LocalStorageService
  ) { }

  /**
   * called intially
   */
  ngOnInit(): void {
    this.getPeopleDetails();
    this.accessVault = this.localStorageService.getDataByKey('accessVault');
  }

  /**
   * get people details ( Account and credit card details )
   */
  public getPeopleDetails():void {
    this.preLoader = true;
    this.personalDetailsService.getPersonalDetails().subscribe((response:APIResponseModel)=>{
      this.preLoader = false;
      if (response.status) {

        this.accountArray = response?.data?.user_bank_account;
        this.creditCardArray = response?.data?.user_credit_card;
      }
    }, (exception:any)=>{
      this.preLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }
}
