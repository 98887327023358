import { Component, OnInit } from '@angular/core';
import { CommonHelper } from 'src/app/helper/common.helper';
@Component({
  selector: 'app-california-privacy',
  templateUrl: './california-privacy.component.html',
})
export class CaliforniaPrivacyComponent implements OnInit {
  public agentTerm: string;

  /**
  * @constructor
  */
  constructor(private commonHelper: CommonHelper) { }
  /**
  * called initially
  */
  ngOnInit(): void {
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
  }

  /**
   * get current year
   * @return{number}
   */
  public getCurrentYear() {
    return this.commonHelper.currentYear;
  }
}
