import { Component, OnInit } from '@angular/core';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';


@Component({
  selector: 'app-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['../documents.css'],
})
/**
 * user agreement component
 */
export class UserAgreementComponent implements OnInit {
  public agentTerm: string;


  /**
   * @constructor
   */
  constructor(private commonHelper: CommonHelper,private slugInterceptorService: SlugInterceptorService,) {
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, false, true);
  }
}
