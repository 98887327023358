import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { CheckoutService } from '../checkout/checkout.service';
import { ConsumerSubscriptionService } from '../consumer-payment-section/consumer-subscription.service';

@Component({
  selector: 'app-payment-message-text',
  templateUrl: './payment-message-text.component.html',
  styleUrls: ['./payment-message-text.component.css']
})
export class PaymentMessageTextComponent implements OnInit {
  public isProfessional:boolean;
  public hasPaidSubscription: boolean;
  public isExistingUser: boolean;

  @Input() clientHasPaidSubscription:boolean;
  @Output() paidStatusEvent = new EventEmitter(null);

  /**
   * @constructor
   */
  constructor(
    private subscriptionService: ConsumerSubscriptionService,
    private checkoutService: CheckoutService,
    private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService,
    private commonHelper: CommonHelper,
    private toastrService:ToastrService
  ) { }

  ngOnInit(): void {
    this.isProfessional = this.commonHelper.isProfessional
    this.subscriptionService.isExistingUser(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.isExistingUser = r);
    if (!this.isProfessional){
      this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => {
        this.hasPaidSubscription = r;
      });
    }
  }

  /**
   * Proceed to payment
   */
  public proceedToPayment() {
    if(!this.isProfessional){
      this.checkoutService.proceedTosubscriptionPayment().subscribe(() => {
        void this.slugInterceptorService.navigate(['/checkout']);
      });
    }else{
      this.toastrService.error('Make Payment to Proceed')
    }
  }
}
