<!-- BEGIN: Navbar Wrapper -->
<app-nav-landing-pro></app-nav-landing-pro>
<!-- END: Navbar Wrapper -->
<main>

  <div class="p-12 lg:px-24 lg:py-32 container mx-auto">
    <div class="title__wrap text-center py-8">

      <h2 class="text-semibold text-primary fs-title">DIGITAL VAULT AGREEMENT</h2>
    </div>
    <div>

      <p class="text-content text-semibold uppercase fs-content">
        PLEASE READ THIS DIGITAL VAULT AGREEMENT (“AGREEMENT”) CAREFULLY BEFORE USING LEGACYNOW’S SOFTWARE AND SERVICES
        (DEFINED BELOW). BY USING THE SOFTWARE, YOU ARE AGREEING TO BE BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU DO
        NOT AGREE TO THE TERMS AND CONDITIONS
        OF THIS AGREEMENT, DO NOT USE THE SOFTWARE, THE SERVICES AND/OR SUBMIT ANY INFORMATION ONTO THE WEBSITE, AND IF
        PRESENTED WITH THE OPTION TO “AGREE” OR “DISAGREE” TO THE TERMS, CLICK “DISAGREE”. IF YOU AGREE TO BE BOUND BY
        ALL OF THE
        TERMS AND CONDITIONS OF THIS AGREEMENT, CLICK “AGREE”. BY CLICKING “AGREE” OR BY DOWNLOADING, USING THE
        SOFTWARE, THE SERVICES AND/OR SUBMIT TING ANY INFORMATION ONTO THE WEBSITE, YOU ARE AGREEING TO BE BOUND TO ALL
        THE TERMS AND CONDITIONS
        OF THIS AGREEMENT.</p>


      <p class="text-content text-regular fs-content">
        This Digital Vault Agreement (this “<span class="underline">Agreement”</span>) is between [LegacyNOW (US) Inc.]
        (“LegacyNOW” “Company, “we”, “us”, “our”) and you as our customer and user of our services (“Customer”, you”,
        “your”) and
        governs your use and access to our digital vault services, software and website (the “Services”).</p>

      <p class="text-content text-regular fs-content">
        Our <a (click)="changeRoute('privacy-policy')" class="cursor-pointer underline text-primary">Privacy Policy</a>
        explains how we collect, use and store your digital information and materials while our <a
          (click)="changeRoute('acceptable-use-policy')" class="cursor-pointer underline text-primary">Acceptable Use
          Policy</a> outlines your responsibilities when using our Services. By using our Services, you agree to be
        bound by this Agreement, our <a (click)="changeRoute('privacy-policy')"
          class="cursor-pointer underline text-primary">Privacy
          Policy</a>,
        and <a (click)="changeRoute('acceptable-use-policy')" class="cursor-pointer underline text-primary">Acceptable
          Use
          Policy</a>
      </p>
      <p class="text-content text-regular fs-content">
        You agree to pay us for our Services according to our current rate schedule, or any revisions thereto. The
        attached schedule of rates (“<span class="underline">Schedule A </span>”) is incorporated herein and made a part
        hereof. Unless
        modified by specific provisions set forth in <span class="underline">Schedule A </span> A, the following terms
        and conditions shall apply to this Agreement.
      </p>


      <h4 class="text-accent text-semibold text-center fs-subtitle my-6">Background Information</h4>
      <p class="text-content text-regular fs-content pb-3">LegacyNOW provides digital vault storage service whereby it
        creates, encrypts, and securely stores digital information and materials submitted and stored by you or your
        authorized representative(s) on our Website. The purpose of the submittal
        and storage of your digital information and materials on our Website is to primary assist and facilitate the
        resolution of your estate by an executor, personal administrator, or other authorized person following your
        death.</p>

      <p class="text-content text-regular fs-content pb-3">LegacyNOW agrees to provide you with such Services, and you
        wish and voluntarily agree to be provided with our Services, on the terms and subject to the conditions
        contained in this Agreement.</p>

      <p class="text-content text-regular fs-content pb-3">1. <span>Definitions</span>. In this Agreement, we will use
        the following terms:</p>
      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“LegacyNOW”</strong>, <strong>“Company”</strong>,
            <strong>“us”</strong>, <strong>“we”</strong> or <strong>“our”</strong>refers to [LegacyNOW (US), Inc.]
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Customer”</strong>, <strong>“you”</strong>,
            <strong>“your”</strong>, or <strong>“yourself”</strong>refers to you as an individual, and includes any
            relative, next-of-kin, heir, beneficiary, executor, administrator, representative, advisor, beneficiary,
            heir or other individual you have specifically authorized to have access to your Digital Information,
            Digital Vault, and use our Services.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">
            <strong>"Website”</strong> means website operated by the Company located at <a
              href="http://www.legacynow.com" class="text-primary underline">http://www.legacynow.com</a> which is
            utilized by the Company to provide you with the Services. The term “Website” as used in this Agreement
            refers solely to the United States version of the Company’s Website and does not include any other websites
            that are not hosted, owned and/or operated by any of the Company’s domestic or international affiliated
            companies, including, without limitation any website either owned or operated by the DNS Provider.

          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Estate”</strong>means your real, personal,
            digital, or intellectual property which may become subject to inheritance upon your death.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Digital Information”</strong>means any
            information or materials, in any format capable of being deposited in your Digital Vault about or pertaining
            to yourself, your Estate or any related information that you or any Authorized Representative has submitted
            to your Digital Vault on our Website, such as your name, address, phone number, date of birth, social
            security number, Creditor names, locations and account information, wills, testaments, codicils, and powers
            of attorney, insurance policies, policy numbers and related information, and any other information or
            materials regarding yourself, your assets, your Estate or otherwise that you wich to submit and store in
            your Digital Vault (subject to the Company’s <a href="acceptable-use-policy.html" target="_blank"
              class="underline"> Acceptable Use Policy</a> ).
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Digital Information Release Address”</strong> is
            an address (in QR-code format) provided by you and verified separately by the Company which represents the
            destination of the Digital Materials to be withdrawn by you or your Authorized Representative as provided in
            this Agreement.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Digital Vault”</strong> means the secure vault
            facility operated by the Company through the Website.</p>
        </li>

        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Services”</strong>means the Digital Vault
            storage service, the Website, the Software, and all functionalities related thereto.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Authorization List”</strong> means the
            confidential listing submitted by you and accepted by the Company specifying the names of your Authorized
            Representative(s), if any, and the validity of their authority.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Authorized Representative”</strong> means any
            relative, next-of-kin, heir, beneficiary, executor, administrator, representative, advisor, beneficiary,
            heir or other individual appointed by you (and included on your Authorization List) authorized to act on
            your behalf with respect to your Deposited Information or our Services (as operator of your Digital Vault)
            whether in a general or restriction manner (as provided in Section __ of this Agreement). Subject to your
            authorization, such persons may have the power to access and use your [Account], including withdrawing of
            Deposited Information from your Digital Vault and, as the case may be, causing the transfer and transmittal
            of your Digital Information to the DNS Provider in order to provide the Deceased Notification Services.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Deceased Notification Services”</strong> refers
            to the deceased notification registry and services provided by [<a
              href="https://estate-registry.com/terms-of-use" target="_blank" class="underline">PCA Registry
              Technologies (US), inc</a>] ., an affiliate of the Company.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Creditors”</strong> means banks, brokerages
            houses, stockbrokers, credit-card companies, finance companies, debt buyers, utility companies, telecoms and
            other creditors and institutions which you may have an account with.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Business Day”</strong> shall mean any day which
            is not a Saturday, Sunday or a public holiday in Wilmington, Delaware.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Effective Date”</strong> means the date that
            this Agreement was accepted by you as provided herein.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Cookies”</strong> are small text files offered
            to a user's computer by web servers that keep track of the user's browser as a website is navigated.

          </p>
        </li>
      </ul>
      <h4 class="text-accent text-semibold fs-subtitle my-6 underline">Your Digital Vault
      </h4>
      <p class="text-content text-regular fs-content pb-3">2.1 <span class="underline">Storage Services</span> – From
        and after the Effective Date for a period of one (1) year (automatically renewed for successive one (1) year
        periods upon payment of the fees referred to in ____________, (the initial one (1) year period and all renewals
        are collectively referred to the “ <a href="#" class="underline"> Term</a>”), we will store, maintain and
        service the Digital Information that you or your Authorized Representative have submitted and deposited in your
        Digital Vault via the Website.
      </p>
      <p class="text-content text-regular fs-content pb-3">2.2 <span class="underline"> Access to Your Digital
          Vault</span>.You and/or your Authorized Representative will be able to access your Digital Vault via the
        Website and may add, update, modify or withdraw any Digital Information deposited in your Digital Vault at any
        time during the Term. All Digital Information that is added, updated, or modified in your Digital Vault will be
        held under the same terms and conditions as the provided in this Agreement. Each time you submit, deposit, add,
        update, modify or withdraw any Digital Information deposited in your Digital Vault you will receive a
        notification from us that your action has been completed. All Digital Information deposits or transactions to
        your Digital Vault must be confirmed and recorded in a digital information inventory or record that we maintain
        that is associated with your Digital Vault. You accept and acknowledge that we are not responsible for any
        losses as a result of any errors or omissions that you make in connection with any submittal, deposit, addition,
        modification, update or withdrawal of Digital Information in your Digital Vault, including, for example, if you
        mistype any information, online address or otherwise provide incorrect information.
      </p>
      <p class="text-content text-regular fs-content pb-3">[You acknowledge and agree that the any deposit or other
        transaction involving Digital Information that you initiate with your Digital Vault via the Website may not be
        completed, or may be substantially delayed, by the network used to process the transaction]</p>

      <p class="text-content text-regular fs-content pb-3">When using your Digital Vault, you authorize us to arrange or
        perform the following on your behalf: [____________________________].</p>
      <p class="text-content text-regular fs-content pb-3">2.3 <span class="underline">Withdrawal</span>. During the
        Term, you may request a partial or full withdrawal of the Digital Information your Digital Vault by providing a
        withdrawal Instruction [____________________]
      </p>
      <p class="text-content text-regular fs-content pb-3">2.4 <span class="underline">Verification</span>. In the
        interest of security, any withdrawal Instruction, from an Authorized Representative(s) will require
        authentication by us via [video call and such video verification procedure will be recorded by us.] You agree
        that we may refuse to process any withdrawal should we fail to authenticate the Authorized Representative(s)
        identity based on our verification procedures.

      </p>

      <h4 class="text-accent text-semibold fs-subtitle underline my-6">Our Obligations with respect to your Digital
        Vault </h4>
      <p class="text-content text-regular fs-content pb-3">3.1 <span class="underline">Safekeeping of Digital Vault and
          Digital Information </span></p>

      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">Subject to the provisions of this Agreement and the
            operational policies established by the Company in its sole discretion from time to time, the Company shall
            maintain the Digital Vault in proper working order and shall use commercially reasonable efforts to minimize
            any downtime or service interruptions regarding the Digital Vault</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">The Company shall use best efforts to keep in safe
            custody all Digital Information, including the Encrypted Digital Private Key and Digital Recovery Card
            assigned to you. The Company shall ensure that:
          </p>
        </li>
        <ul class="list-disc lg:pl-16">
          <li>
            <p class="text-content text-regular fs-content pb-3">it will securely store all Digital Private Keys and
              Digital Recovery Cards in an encrypted format, laser etched onto two durable polycarbonate cards;
            </p>
          </li>
          <li>
            <p class="text-content text-regular fs-content pb-3">for any Encrypted Private Key and Recovery Card stored
              by us on your behalf, we will use our best efforts to keep the Digital Private Keys secure and will not
              disclose such keys to any other party.
            </p>
          </li>
        </ul>
      </ul>

      <p class="text-content text-regular fs-content pb-3">3.2 <span class="underline"> Insurance Coverage</span> </p>
      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">Should you choose to purchase optional liability
            protection for the assigned Encrypted Private Keys/Recovery Cards, we will ensure that adequate insurance is
            purchased from a third-party insurer to cover the value of the Digital Information in the associated
            Encrypted Private Key/Recovery Card, subject to the applicable insurance coverage limit.
          </p>
        </li>

      </ul>

      <p class="text-content text-regular fs-content pb-3">3.3 <span class="underline">Inspection and Auditing</span>.
        We shall, as soon as reasonably practicable after receipt of any audit report prepared by our internal or
        independent auditors pursuant to our annual audit or otherwise, provide you a copy of such report, and if such
        audit report reveals any material deficiencies or makes any material objections, to furnish you a report stating
        the nature of such deficiencies or such objections, and describing the steps taken or to be taken to remedy the
        same. Such audit report will be deemed Confidential Information of the Company.</p>


      <h4 class="text-accent text-semibold fs-subtitle my-6">Duties and Obligations of Customer
      </h4>
      <p class="text-content text-regular fs-content pb-3">4.1 <span class="underline">Use of LegacyNOW’
          Services</span>.
      </p>

      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">You or your Authorized Representative(s) shall promptly
            notify us of any unauthorized access, use or disclosure of your Digital Vault credentials, unauthorized
            access or use of the Digital Vault or any other breach of security, which such notification shall reasonably
            describe the issue at hand including the date, type of problem and part of the Website where you experienced
            that problem.

          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">You or your Authorized Representative(s) shall ensure
            that you will log off from your Digital Vault at the end of each session.

          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">When processing a withdrawal Instruction, you agree to
            indemnify us against any losses incurred due to providing us with an incorrect Digital Information Release
            Address, or your Digital Information Release Address becomes inaccessible due to user error such as
            forgotten passwords, third-party access to the Digital Information Release Address, or; any unauthorized
            third party activities, including, but without limitation to, the use or introduction of computer viruses,
            or other malware, phishing, spoofing or any other means of attack against you.

          </p>
        </li>
      </ul>
      <p class="text-content text-regular fs-content pb-3">4.2 <span class="underline">Prohibited Activities</span>. You
        agree that you or your Authorized Representative(s) may not engage in any of the prohibited activities as
        described in the <a [routerLink]="'acceptable-use-policy'" target="_blank" class="text-primary underline">LegacyNOW
          Acceptable Use Policy</a> .

      </p>
      <p class="text-content text-regular fs-content pb-3">4.3 Fees and Expenses.
      </p>
      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">We will charge fees in respect of the Services provided
            to you under this Agreement as specified in <a href="#" class="underline"> Schedule A</a>.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">You shall pay such fees and expenses to us on or before
            the relevant payment due date. Should you fail to pay us any amount due and owing from the Services provided
            under this Agreement, you authorize us to temporarily limit/suspend your access to your Digital Vault until
            the full payment of the outstanding fees and expenses is received by us.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Any increase of our fees in <a href="#"
              class="underline">Schedule A</a> , is subject to thirty (30) days prior notice before taking effect.

          </p>
        </li>
      </ul>

      <h4 class="text-accent text-semibold fs-subtitle underline my-6">5. Transfer of Digital Information upon Death
      </h4>
      <p class="text-content text-regular fs-content pb-3">5.1 In the event of your death, your Digital Vault Account,
        including its holdings, will be temporarily frozen by us awaiting for the
        eventual resolution of your estate/will; provided, however, that you expressly authorize us through execution of
        <a href="#" class="underline">Transfer on Death Instruction/ Power of Attorney</a> to promptly transfer,
        duplicate, download, upload and/or transfer any of your Digital Information contained in your Digital Vault to
        an account which shall be automatically opened on your behalf with The Estate Registry website located at <a
          href="http://www.estate-registry.com" target="_blank"
          class="text-primary underline">http://www.estate-registry.com</a>, owned and operated by the DSN Provider in
        order to provide Deceased Notification Services (“TER Account”).
      </p>
      <p class="text-content text-regular fs-content pb-3">5.2 You hereby irrevocably authorize and instruct LegacyNOW
        to have your Digital Information transferred to the linked TER
        Account upon our receipt and acceptance of the Customer’s proof of death, and to settle any and all outstanding
        obligations (e.g., fees and expenses) of your Digital Vault with TER. Upon completion of the transfer, we may
        terminate your Digital Vault in accordance with Section 6 of this Agreement.
      </p>


      <h4 class="text-accent text-semibold underline fs-subtitle my-6">6. Termination </h4>
      <p class="text-content text-regular fs-content pb-3">6.1 This Agreement will commence on the Effective Date and
        will continue be effective until terminated as provided under Section 6.2 of this Agreement.

      </p>
      <p class="text-content text-regular fs-content pb-3">6.2. This Agreement may be terminated by either Party upon 30
        days written notice to the other Party.
      </p>

      <p class="text-content text-regular fs-content pb-3">6.3. Notwithstanding Section 6.2 above, we reserve the right
        to suspend, limit the use of, and/or close your Digital Vault thus terminating our relationship if you violate
        any terms of this Agreement.
      </p>

      <p class="text-content text-regular fs-content pb-3">6.4. Effect of termination of this Agreement</p>

      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">Upon termination of this Agreement, we shall promptly
            arrange for the withdrawal and delivery of any Digital Information from your Digital Vault;
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">Any such termination shall not affect any right or
            liability arising out of events occurring, or services delivered (e.g. storage and withdrawal fees), prior
            to the effectiveness thereof.
          </p>
        </li>

      </ul>
      <h4 class="text-accent text-semibold underline fs-subtitle my-6">Confidentiality. </h4>
      <p class="text-content text-regular fs-content pb-3">7.1 “Confidential Information” shall mean information
        designated by the Parties to this Agreement, their employees, and subcontractors bound by a duty of
        confidentiality that is sensitive in the sole discretion of the Parties (including without limitation,
        information concerning your Estate and the identity of any Authorized Representative).
      </p>
      <p class="text-content text-regular fs-content pb-3">7.2 Access to Confidential Information shall be limited to
        the Parties and such Parties shall use the Confidential Information for purposes only in accordance with this
        Agreement. To this end, other than as provided herein, Parties shall prohibit distribution of Confidential
        Information to persons other than the Parties. Parties shall not use the Confidential Information in any manner
        that is detrimental to Parties, including, without limitation, to solicit or contact customers of Parties. For
        the avoidance of doubt, Parties include the employees, legal counsel, consultants, and other advisors who are
        bound by a duty of confidentiality by contract or otherwise.
      </p>
      <p class="text-content text-regular fs-content pb-3">7.3 The Parties reserve all rights to their Confidential
        Information not expressly granted herein.
      </p>
      <p class="text-content text-regular fs-content pb-3">7.4 Confidential Information shall not include information
        which (i) was in the public domain before disclosure to Parties; (ii) was lawfully in Parties’ possession before
        you produced it pursuant to this Agreement; (iii) becomes part of the public domain by publication or otherwise
        through no unauthorized act or omission on the part of Parties; or (iv) is independently developed by an
        employee(s) or other {{agentTerm}}(s) of Parties with no access to the Confidential Information.
      </p>



      <h4 class="text-accent text-semibold fs-subtitle underline my-6">8. Intellectual Property </h4>
      <p class="text-content text-regular fs-content pb-3">8.1 As between the Parties hereto, LegacyNOW shall retain all
        right, title, and interest (including all copyright, trademark, patent, trade secrets, and all other
        intellectual property rights) of the Digital Vault, the Website and all content provided by LegacyNOW on the
        Website, including its trademarks, service marks, designs, logos, URLs, and trade names (collectively, the
        “LegacyNOW Materials”).
      </p>
      <p class="text-content text-regular fs-content pb-3">8.2 We hereby grant you a non-exclusive, non-transferable,
        worldwide, royalty-free license during the Term of this Agreement to use the LegacyNOW Materials solely as
        reasonably necessary to access and use the Services as contemplated by this Agreement.
      </p>

      <h4 class="text-accent text-semibold underline fs-subtitle my-6">9. Indemnification</h4>
      <p class="text-content text-regular fs-content pb-3 ">9.1 <span class="underline">By Customer</span>. Customer
        will indemnify, defend, and hold harmless the Company from and against all liabilities, damages, and costs
        (including settlement costs and reasonable attorneys' fees) arising out of any claim against the Company
        regarding the use of the Services in violation of the Agreement.
      </p>
      <p class="text-content text-regular fs-content pb-3">9.2 <span class="underline">By the Company </span>. The
        Company will indemnify, defend, and hold harmless Customer from and against all liabilities, damages, and costs
        (including settlement costs and reasonable attorneys' fees) arising out of any claim against Customer to the
        extent based on an allegation that the Company's technology used to deliver the Services to the Customer
        infringes or misappropriates any copyright, trade secret, U.S. patent, or trademark right of the third party. In
        no event will the Company have any obligations or liability under this section arising from: (a) use of any
        Services in a modified form or in combination with materials not furnished by the Company; and (b) any content,
        information, or data provided by Customer or other third parties.
      </p>
      <p class="text-content text-regular fs-content pb-3">9.3 <span class="underline">Possible Infringement</span>. If
        the Company believes the Services or Software infringe or may be alleged to infringe a third party's
        Intellectual Property Rights, then the Company may: (a) obtain the right for Customer, at The Company's expense,
        to continue using the Services or Software; (b) provide a non-infringing functionally equivalent replacement; or
        (c) modify the Services or Software so that they no longer infringe. If the Company does not believe the options
        described in this section are commercially reasonable, then the Company may suspend or terminate Customer's use
        of the affected Services or Software, with a pro-rata refund of prepaid fees for the Services or Software.

      </p>
      <p class="text-content text-regular fs-content pb-3">9.4 <span class="underline">General</span>. The Party seeking
        indemnification will promptly notify the other Party of the claim and cooperate with the other Party in
        defending the claim. The indemnifying Party will have full control and authority over the defense, except that:
        (a) any settlement requiring the Party seeking indemnification to admit liability requires prior written
        consent, not to be unreasonably withheld or delayed; and (b) the other Party may join in the defense with its
        own counsel at its own expense. THE INDEMNITIES ABOVE ARE THE COMPANY AND CUSTOMER'S ONLY REMEDY UNDER THE
        AGREEMENT FOR VIOLATION BY THE OTHER PARTY OF A THIRD PARTY'S INTELLECTUAL PROPERTY RIGHTS.

      </p>

      <h4 class="text-accent text-semibold fs-subtitle underline my-6">10. Representations and Warranties</h4>
      <p class="text-content text-regular fs-content pb-3">10.1 <span class="underline">Representations and Warranties
          of Customer</span> You represent and warrant to the Company that:
      </p>
      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3">You have all requisite legal right and authority to enter
            into this Agreement, to submit the Digital Information on the Website, and to perform the obligations and
            grant the rights, consents, and authorizations described in this Agreement on your own behalf;

          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">You agree that any Digital Information you submit to the
            Company on or through the Website about yourself, your Estate, your Authorized Representatives or any other
            related matter is and will be at the time provided true, accurate, correct and complete and you further
            agree to promptly inform the Company of any material changes in such information and to keep all such
            information current and accurate; and

          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">You acknowledge and agree that, with respect to any
            Authorized Representatives as in connection with the using and accessing of this Website and the Services,
            you (i) have the requisite right, power and authority to grant any such Authorized Representative access to
            the Website and provide the Digital Information submitted, (ii) are ultimately responsible for the Digital
            Information submitted or activities conducted by any Authorized Representatives on the Website regarding
            your Estate, and (iii) have informed each of any such Authorized Representatives of the obligations under
            this Agreement.

          </p>
        </li>

      </ul>
      <p class="text-content text-regular fs-content pb-3">10.2 <span class="underline">Representations and Warranties
          of the Company</span>
        The Company represents and warrants to the Customer that (i) the Company is duly organized or formed, validly
        existing and in good standing under the laws of the state of its organization or formation, as the case may be,
        and in each jurisdiction in which the nature of its business requires it to be so, (ii) the execution, delivery
        and performance of this Agreement by the Licensor have been duly authorized by all necessary action (corporate
        or otherwise) on the part of the Company, (iii) this Agreement has been duly executed and delivered by the
        Company and constitutes a legally valid and binding obligation of the Company, enforceable against the Company
        in accordance with its terms, and (iv) the Company or its affiliates have all permits necessary for the Company
        to enter into this Agreement and perform its obligations in accordance with the terms hereof.
      </p>
      <h4 class="text-primary text-semibold underline fs-subtitle my-6">11.  Disclaimers.
      </h4>

      <p class="text-content text-regular fs-content uppercase pb-3">THE DIGITAL VAULT, THE WEBSITE, THE SOFTWARE AND
        THE SERVICES ARE PROVIDED "AS IS." TO THE FULLEST EXTENT PERMITTED BY LAW, EXCEPT AS EXPRESSLY STATED IN THE
        AGREEMENT, NEITHER CUSTOMER NOR THE COMPANY AND ITS AFFILIATES, SUPPLIERS, AND DISTRIBUTORS MAKE ANY WARRANTY OF
        ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR
        A PARTICULAR USE, OR NON-INFRINGEMENT. CUSTOMER IS RESPONSIBLE FOR USING THE DIGITAL VAULT, THE WEBSITE, THE
        SOFTWARE OR THE SERVICES IN ACCORDANCE WITH THE TERMS SET FORTH HEREIN AND BACKING UP ANY INFORMATION STORED IN
        THE DIGITAL VAULT.
      </p>
      <p class="text-content text-regular fs-content uppercase pb-3">TO THE FULLEST EXTENT PERMITTED BY LAW, EXCEPT FOR
        THE COMPANY OR CUSTOMER'S INDEMNIFICATION OBLIGATIONS, NEITHER CUSTOMER NOR THE COMPANY AND ITS AFFILIATES,
        SUPPLIERS, AND DISTRIBUTORS WILL BE LIABLE UNDER THE AGREEMENT FOR (I) INDIRECT, SPECIAL, INCIDENTAL,
        CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, OR (II) LOSS OF USE, DATA, BUSINESS, REVENUES, OR PROFITS (IN
        EACH CASE WHETHER DIRECT OR INDIRECT), EVEN IF THE PARTY KNEW OR SHOULD HAVE KNOWN THAT SUCH DAMAGES WERE
        POSSIBLE AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.

      </p>
      <p class="text-content text-regular fs-content upercase pb-3">TO THE FULLEST EXTENT PERMITTED BY LAW, THE
        COMPANY'S AGGREGATE LIABILITY UNDER THE AGREEMENT WILL NOT EXCEED THE LESSER OF $1,000 OR THE AMOUNT PAID BY
        CUSTOMER TO THE COMPANY HEREUNDER DURING THE TWELVE MONTHS PRIOR TO THE EVENT GIVING RISE TO LIABILITY. </p>
      <h4 class="text-primary text-semibold underline fs-subtitle my-6">12.  Miscellaneous.
      </h4>
      <p class="text-content text-regular fs-content pb-3">12.1 <span class="underline">Governing Law</span> . This
        Agreement shall be governed, interpreted, and enforced according to the laws of the State of Delaware,
        regardless of conflict of laws rules, and the federal laws of the United States applicable therein. To the
        extent permitted by law, you agree to the non-exclusive jurisdiction of the US or state courts in New Castle
        County, Delaware for any disputes, claim or cause of action arising out of, or relating to or in connection with
        this Agreement.

      </p>
      <p class="text-content text-regular fs-content pb-3">12.2 <span class="underline">Arbitration; No Class
          Actions</span> . Customer and the Company agree to resolve any claims relating to the Agreement or the
        Services through final and binding arbitration. The American Arbitration Association (AAA) will administer the
        arbitration under its Commercial Arbitration Rules. The arbitration will be held in Delaware (DE), or any other
        location both parties agree to in writing.
      </p>

      <p class="text-content text-regular fs-content pb-3">Customer may only resolve disputes with the Company on an
        individual basis and will not bring a claim in a class, consolidated or representative action. Class
        arbitrations, class actions, private attorney general actions and consolidation with other arbitrations are not
        allowed.

      </p>
      <p class="text-content text-regular fs-content pb-3">12.3 <span class="underline">Entire Agreement</span>. This
        Agreement contains the entire agreement and supersedes all prior and contemporaneous understandings between the
        Parties. This Agreement does not alter the terms or conditions of any other electronic or written agreement you
        may have with the Company for any other LegacyNOW’s product or service. In the event of any conflict between
        this Agreement and any other agreement you may have with the Company, the terms of this Agreement will supersede
        the terms of such agreement.

      </p>
      <p class="text-content text-regular fs-content pb-3">12.4 <span class="underline">Severability</span>.
        Unenforceable provisions will be modified to reflect the Parties' intention and only to the extent necessary to
        make them enforceable, and the remaining provisions of the Agreement will remain in full effect.

      </p>
      <p class="text-content text-regular fs-content pb-3">12.5<span class="underline">Notice</span>. Notices must be
        sent via email, first class, airmail, or overnight courier and are deemed given when received. Notices to
        Customer may also be sent to the applicable account email address and are deemed given when sent. Notices to the
        Company must be sent to the Company’s legal department at XXX@legacynow.com, with a copy to LegacyNOW (US) Inc.,
        1002 Justison Street, Wilmington, Delaware 19801.
      </p>

      <p class="text-content text-regular fs-content pb-3">12.6<span class="underline">Waiver</span>. A waiver of any
        default is not a waiver of any subsequent default.
      </p>
      <p class="text-content text-regular fs-content pb-3">12.7<span class="underline">Assignment</span>. Customer may
        not assign or transfer the Agreement or any rights or obligations under the Agreement without the written
        consent of the Company, except that Customer may assign the Agreement to the surviving entity in connection with
        a merger, acquisition, or sale of all or substantially all of its assets by providing written notice to the
        Company. The Company may not assign the Agreement without providing notice to Customer, except the Company may
        assign the Agreement or any rights or obligations under the Agreement to an affiliate or in connection with a
        merger, acquisition, corporate reorganization, or sale of all or substantially all of its assets without
        providing notice. Any other attempt to transfer or assign is void.

      </p>
      <p class="text-content text-regular fs-content pb-3">12.8<span class="underline">Force Majeure</span>. Except for
        payment obligations, neither the Company nor Customer will be liable for inadequate performance to the extent
        caused by a condition that was beyond the Party's reasonable control (for example, natural disaster, act of war
        or terrorism, riot, labor condition, governmental action, mass quarantine, and Internet disturbance).
      </p>

      <p class="text-content text-regular fs-content pb-3">12.9<span class="underline">No Third-Party
          Beneficiaries</span>. There are no third-party beneficiaries to the Agreement.

      </p>
      <p class="text-content text-regular fs-content pb-3">12.10<span class="underline">Relationship of the
          Parties</span>. Nothing in this Agreement shall be deemed or is intended to be deemed, nor shall it cause, the
        Customer and the Company to be treated as partners, joint ventures, or otherwise as joint associates for profit.
      </p>
      <p class="text-content text-regular fs-content pb-3">12.11<span class="underline">Death or Incapacity</span>. In
        case of death or mental incapacity of a Customer holding a Digital Vault Account, we will take instructions in
        respect to your Digital Vault Account from your <a href="#" class="underline"> Transfer on Death Instruction/
          Power of Attorney</a> or if this feature is not enabled on your account, from your authorized executor or
        administrator upon delivery to us of sufficient proof of authority of such executor or administrator and all
        relevant documentation as requested.

      </p>
      <p class="text-content text-regular fs-content pb-3">12.12<span class="underline">Amendment</span>. We may amend
        the provisions of this Agreement by providing a minimum of thirty (30) days notice to your registered email
        and/or via secure notification. You acknowledge and agree that by continuing to use the Services after such
        notice period, you accept any such amendments to this Agreement.
      </p>
      <h4 class="text-accent text-semibold underline fs-subtitle my-6">DIGITAL SIGNATURE AND ACCEPTANCE
      </h4>


      <p class="text-content text-regular fs-content pb-3"> I Agree ________________________</p>

      <p class="text-content text-regular fs-content pb-3"> Disagree ___________________</p>

    </div>
  </div>
</main>