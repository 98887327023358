<div class="lg:grid grid-cols-12 lg:pb-10 background-color-grey">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 md:gap-8 my-4">
              <div class="col-span-12 sm:col-span-2">
                <!-- <div class="w-16 my-2"> -->
                <img src="assets/images/about-yourself/about-yourself.png" class="w-20" alt="digital vault">
                <!-- </div> -->
              </div>

              <div class="col-span-12 sm:col-span-10">
                <!-- <div class="pl-4 sm:px-4 ml-8"> -->
                <h2 class="text-darkblue text-xl sm:text-3xl uppercase text-semibold">
                  Tell us about yourself
                </h2>
                <p class="text-sm text-grey tracking-wide text-regular">
                  This information is critical and a key to verifying your identity. Please double-check
                  your input prior to moving on.
                </p>
              </div>
            </div>

            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-2/6'"></app-card-progress-bar>
              <!-- END: Progress Bar -->

              <div class="p-6">

                <div class="mb-4">

                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    What’s your address?
                  </h4>
                  <p class="text-grey text-regular pt-1 text-sm">
                    Please enter your full address including postcode. You can leverage google address, or
                    manually enter the information yourself.
                  </p>

                </div>
                <form [formGroup]="locationDetailForm" autocomplete="off" (ngSubmit)="onSubmit()">
                  <div class="grid grid-cols-12 gap-4 row-gap-4 mt-4">
                    <div class="col-span-12">
                      <label for="country" class=" text-label-grey text-sm text-regular ">Country*</label>
                      <div class=" col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('country')">
                        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                          <app-country-search-component id="country"
                                                        (optionChangeEmitter)="optionChangeListenerCountry($event)"
                                                        [defaultSelected]="formGet?.country?.value"
                                                        [enableError]="formGet.country.errors && formGet.country.touched"></app-country-search-component>
                          <p *ngFor="let error of getErrors('country')"
                             class="text-regular font-medium text-xs mt-2 error-alert">
                            {{error}}
                          </p>
                        </app-shimmer-loading>
                      </div>
                    </div>
                    <div class="col-span-12 lg:col-span-8 relative move-map" #moveMapHere>
                      <label for="street-address" class="text-label-grey text-sm text-regular">Street Address*</label>
                      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                        <input type="text" (keyup)="onAddressChange(search)" (input)="onFocusAddress()"
                               (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null"
                               class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                               placeholder="e.g Main St" name="address" id="street-address" formControlName="address"
                               #search
                               [ngClass]="formGet.address.errors && formGet.address.touched?'form-input-error':'form-input-focus'">
                      </app-shimmer-loading>
                      <p *ngFor="let error of getErrors('address')"
                         class="text-regular font-medium text-xs mt-2 error-alert">
                        {{error}}
                      </p>
                    </div>
                    <div class="col-span-12 lg:col-span-4">
                      <label for="apt_number" class="text-label-grey text-sm text-regular">Apartment / Suite #</label>
                      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                        <input type="text" name="apt_number" id="apt_number" formControlName="apt_number"
                               class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                               [ngClass]="formGet.apt_number.errors && formGet.apt_number.touched?'form-input-error':'form-input-focus'">
                      </app-shimmer-loading>
                      <p *ngFor="let error of getErrors('apt_number')"
                         class="text-regular font-medium text-xs mt-2 error-alert">
                        {{error}}
                      </p>
                    </div>
                    <div class="col-span-12" *ngIf="selectedCountryName!==unitedKingdom">
                      <label for="state-Province" class="text-label-grey text-sm text-regular">State/Province*</label>
                      <div class="col-span-12 lg:col-span-2 relative">
                        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                          <app-state-search-component id="state-Province" [countryCode]="formGet?.country?.value"
                                                      [enableError]="formGet.state.errors && formGet.state.touched"
                                                      [defaultSelected]="formGet?.state?.value"
                                                      (optionChangeEmitter)="optionChangeListenerState($event)"
                                                      tabindex="0"
                                                      (focusout)="markAsTouchedIndividual('state')"></app-state-search-component>
                        </app-shimmer-loading>
                        <p *ngFor="let error of getErrors('state')"
                           class="text-regular font-medium text-xs mt-2 error-alert">
                          {{error}}
                        </p>
                      </div>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                      <label for="city" class="text-label-grey text-sm text-regular">City*</label>
                      <div class="col-span-12 lg:col-span-2 relative">
                        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                          <app-city-search id="city" [countryName]="selectedCountryName"
                                           [stateCode]="formGet?.state?.value"
                                           [enableError]="formGet.city.errors && formGet.city.touched"
                                           [defaultSelected]="formGet?.city?.value"
                                           (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0"
                                           (focusout)="markAsTouchedIndividual('city')"></app-city-search>
                        </app-shimmer-loading>
                        <p *ngFor="let error of getErrors('city')"
                           class="text-regular font-medium text-xs mt-2 error-alert">
                          {{error}}
                        </p>
                      </div>
                    </div>
                    <div class=" col-span-12 md:col-span-6 ">
                      <label for="zipcode" class=" text-label-grey text-sm text-regular ">Zip/Postal Code*</label>
                      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                        <input type="text"
                               class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
                               placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                               name="zipcode" id="zipcode" formControlName="zipcode"
                               oninput="this.value = this.value.toUpperCase()" (focus)="validateZipCode()"
                               [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
                      </app-shimmer-loading>
                      <p *ngFor="let error of getErrors('zipcode')"
                         class="text-regular font-medium text-xs mt-2 error-alert">
                        {{error}}
                      </p>
                    </div>
                  </div>

                  <div class=" flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 ">
                    <a (click)="changeRoute('about-yourself/'+(this.userType !== 'Consumer' ? requestId : ''))"
                       class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none ">
                      <img src="assets/images/about-yourself/back-arrow.svg " class=" w-3 mr-3 " alt="digital vault">
                      Back
                    </a>

                    <!-- Submit Button -->
                    <app-button-loader class="w-full sm:w-auto" [buttonText]="'Save and Continue'" [valid]="true"
                                       [loader]="submitLoader || preDefinedLoader"
                                       buttonType="'submit'"></app-button-loader>
                  </div>
                </form>
              </div>
              <!-- END: Leftside Content -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->
  <!-- BEGIN: Rightside Content -->
  <div class=" col-span-12 md:col-span-6 lg:grid justify-center ">
    <about-yourself-right-content [formInputValue]="locationDetailForm?.value"
                                  [otherValues]="sectionSaveExitOptions"></about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->
</div>
