import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker/bs-datepicker.component';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { ICountryCode, SelectOptionsInterface, ToggleOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { AssetsService } from 'src/app/services/assets.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { ALPHA_NUMERIC_WITH_COMMA_SINGLEQUOTE_SPACE_PATTERN, ALPHA_NUMERIC_WITH_HYPHEN_PATTERN_NEW, COMPANY_NAME_PATTERN, DATE_FORMAT, INSURANCE_TYPE, LIST_DELETE, LIST_NEW, LIST_UPDATE, NAME_PATTERN, NUMBERS_VALID_DOLLARS, PHONE_PATTERN, TOGGLE_OPTIONS_YES_NO, USER_TYPES } from 'src/constants/application.const';
import { ELEMENT_EVENTS } from 'src/constants/form.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../helper/validation.helper';
import { PersonalDetailsService } from '../../../services/personal-details.service';
import { SelectBeneficiaryRatioEmitModel } from '../../../sharedComponent/select-beneficiary-ratio/select-beneficiary-ratio.component';


@Component({
  selector: 'app-add-insurance',
  templateUrl: './add-insurance.component.html',

})
/**
 * Add insurance component
 */
export class AddInsuranceComponent implements OnInit, OnChanges {
  @ViewChild('dp', { static: false }) public datepicker: BsDatepickerDirective;
  private currencyFields: string[] = ['death_benefit', 'amount', 'market_value', 'lump_sum', 'benefit_base', 'income_benefit'];
  @Output() toggleModalEmitter = new EventEmitter<any>();
  @Input() modal_id = 'add-insurance-modal';
  @Input() beneficiaryCount: number;
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() isNewAsset: boolean = true;
  @Input() lockType: boolean = false;
  private editData: Object;
  public readonly PRIMARY: number = 1;
  public readonly SECONDARY: number = 2;
  public readonly toggleOptions = TOGGLE_OPTIONS_YES_NO;
  public readonly faInfoCircle = faInfoCircle;
  public readonly insuranceTypeConst: { [key: string]: number } = INSURANCE_TYPE;
  public insuranceType: string;
  public insuranceForm: FormGroup;
  public insuranceTypes: Array<SelectOptionsInterface> = [];
  public insuranceTypeNumber: number = null;
  public insuranceTypeName: string;
  public insuranceTypeId: string;
  public policyTypes: Array<any>;
  public submitLoaderDelete: boolean = false;
  public submitLoader: boolean;
  public countryCode: ICountryCode = environment.DEFAULT_COUNTRY_CODE;
  public userType: string;
  public requestId: string;
  public locale = 'en';
  public bsConfig: Partial<BsDatepickerConfig>;
  public bsConfigEndDate: Partial<BsDatepickerConfig>;
  public minDate: Date;
  public maxDate: Date;
  public spousalContinuation: number;
  public insuranceId: string;
  public preDefinedLoader: boolean = false;
  public keyValue: number;
  public keyType: number;
  public keyTypeSecondary: number = 0;
  public keyTypePrimary: number = 0;
  public updateKey: number;
  public percentageDetailsInsuranceId: string;
  public chosenBeneficiaries: { 1: Array<Object>, 2: Array<Object> } = { 1: [], 2: [] };
  public changeDetectionKey: boolean = true;
  public disableOpenModal: boolean;
  public insurancePercentageId: string;
  public disableOpenAddModal: boolean = false;
  public addedPrimaryList: Array<any>;
  public touchedInsuranceType = false;
  public domainDateFormat: string;
  public vehicleType = '';
  public userPeopleList = [];
  public otherTypeSelected = false;
  public ownerShipStatus = 0;
  public isDisabled: boolean = false;
  public dpClosedInterval: any;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public selectedBeneficiaries: Array<Object>;

  @Input() set setEditData(data: Object) {
    this.editData = data;
    this.editDetails();
  };

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private assetsService: AssetsService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private localeService: BsLocaleService,
    private toastrService: ToastrService,
    private personalDetailsService: PersonalDetailsService,
    private vehicleService: VehicleService,
    private validation: ValidationHelper,
  ) {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
    this.dpClosedInterval = null;
  }

  /**
   *
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.editData?.previousValue) {
      this.resetForm();
    }
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.domainDateFormat = this.commonHelper.domainDateFormat;
    this.spousalContinuation = this.toggleOptions[0].value;
    this.requestId = this.route.snapshot.params['id'];
    this.getUserPeople();
    this.userType = this.localStorageService.getDataByKey('role');
    this.initForm();
    this.getInsurancePolicyTypes();
    this.applyLocale();
    this.bsConfig = Object.assign({}, {
      showWeekNumbers: false,
      adaptivePosition: true,
      maxDate: this.maxDate,
      dateInputFormat: this.domainDateFormat,
    });
    this.bsConfigEndDate = Object.assign({}, {
      showWeekNumbers: false,
      adaptivePosition: true,
      minDate: this.minDate,
      dateInputFormat: this.domainDateFormat,
    });

    this.vehicleService.insuranceTypeObserve.subscribe((response: boolean) => {
      if (response) {
        const insuranceType = this.insuranceTypes.find((e) => e.additionalDetails?.is_auto == 1);
        this.optionChangeEmitterInsuranceType(insuranceType);
      }
    });
    if (!this.canAccessForm) {
      // for showing otherInsurance formgroup if payment has not been done
      this.insuranceTypeNumber = 4;
      this.insuranceForm.disable();
    }
  }

  private initForm() {
    this.insuranceForm = this.formBuilder.group({
      // 1
      longTermCare: this.formBuilder.group({
        insurance_type_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
        company_name: ['', {
          updateOn: 'blur',
          validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN)],
        }],
        owner: ['', {
          updateOn: 'blur',
          validators: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN)],
        }],
        insured_person_id: [''],
        insured_person: ['', {
          updateOn: 'blur',
          validators: [Validators.minLength(2), Validators.maxLength(50), Validators.pattern(NAME_PATTERN)],
        }],
        policy_number: ['', {
          updateOn: 'blur',
          validators: [Validators.maxLength(20), Validators.pattern(ALPHA_NUMERIC_WITH_HYPHEN_PATTERN_NEW)],
        }],
        insurance_agent_name: ['', {
          updateOn: 'blur',
          validators: [Validators.minLength(3), Validators.maxLength(30), Validators.pattern(NAME_PATTERN)],
        }],
        insurance_agent_contact: ['', { updateOn: 'blur', validators: [Validators.pattern(PHONE_PATTERN)] }],
        id: [''],
        notes: ['', { updateOn: 'blur', validators: [Validators.maxLength(500)] }],
        has_sole_ownership: [this.ownerShipStatus],
        joint_ownership_name: [''],
        joint_insured: [''],
      }),
      // 2
      lifeInsurance: this.formBuilder.group({
        insurance_type_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
        insurance_policy_type_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
        company_name: ['', {
          updateOn: 'blur',
          validators: [Validators.required, Validators.maxLength(50), Validators.pattern(ALPHA_NUMERIC_WITH_COMMA_SINGLEQUOTE_SPACE_PATTERN)],
        }],
        owner: ['', {
          updateOn: 'blur',
          validators: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN)],
        }],
        policy_number: ['', {
          updateOn: 'blur',
          validators: [Validators.maxLength(20), Validators.pattern(ALPHA_NUMERIC_WITH_HYPHEN_PATTERN_NEW)],
        }],
        insured_person_id: [''],
        insured_person: ['', {
          updateOn: 'blur',
          validators: [Validators.minLength(2), Validators.maxLength(50), Validators.pattern(NAME_PATTERN)],
        }],
        lump_sum: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
        start_date: ['', { updateOn: 'blur', validators: [this.dateLessThan('start_date', 'end_date')] }],
        end_date: ['', { validators: [this.validation.fn.isFutureDate()] }],
        death_benefit: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
        insurance_agent_name: ['', {
          updateOn: 'blur',
          validators: [Validators.minLength(3), Validators.maxLength(30), Validators.pattern(NAME_PATTERN)],
        }],
        insurance_agent_contact: ['', { updateOn: 'blur', validators: [Validators.pattern(PHONE_PATTERN)] }],
        id: [''],
      }),
      // 3
      annuities: this.formBuilder.group({
        insurance_type_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
        owner: ['', {
          updateOn: 'blur',
          validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim],
        }],
        insured_person_id: [''],
        insured_person: ['', {
          updateOn: 'blur',
          validators: [Validators.minLength(2), Validators.maxLength(50), Validators.pattern(NAME_PATTERN), this.validation.fn.trim],
        }],
        amount: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
        market_value: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
        benefit_base: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
        death_benefit: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
        income_benefit: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
        notes: ['', { updateOn: 'blur', validators: [Validators.maxLength(500)] }],
        id: [''],
        spousal_continuation: [0, { updateOn: 'blur', validators: [Validators.required] }],
      }),
      // 4
      otherInsurance: this.formBuilder.group({
        insurance_type_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
        company_name: ['', {
          updateOn: 'blur',
          validators: [Validators.required, Validators.minLength(0), Validators.maxLength(50), Validators.pattern(COMPANY_NAME_PATTERN)],
        }],
        policy_number: ['', {
          updateOn: 'blur',
          validators: [Validators.maxLength(20), Validators.pattern(ALPHA_NUMERIC_WITH_HYPHEN_PATTERN_NEW)],
        }],
        // insured_vehicle: ['', { updateOn: 'blur', validators: [Validators.minLength(3), Validators.maxLength(20), Validators.pattern(ALPHA_NUMERIC_PATTERN)] }],
        start_date: ['', { updateOn: 'blur', validators: [this.dateLessThan('start_date', 'end_date')] }],
        end_date: ['', { updateOn: 'blur', validators: [this.validation.fn.isFutureDate()] }],
        amount: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
        insurance_agent_name: ['', {
          updateOn: 'blur',
          validators: [Validators.minLength(3), Validators.maxLength(30), Validators.pattern(NAME_PATTERN), this.validation.fn.trim],
        }],
        insurance_agent_contact: ['', { updateOn: 'blur', validators: [Validators.pattern(PHONE_PATTERN)] }],

        id: [''],
      }),
    });
    this.validation.setValidationErrors({
      // Long Term Care
      amount: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places', pattern: false },
      start_date: { dates: 'Start Date should be less than End Date' },
      end_date: { future_date: 'End Date must be a future date.' },
      insurance_agent_contact: { pattern: 'Please enter a valid phone number' },

      // Life Insurance
      lump_sum: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places', pattern: false },

      // Annuities
      market_value: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places.', pattern: false },
      benefit_base: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places.', pattern: false },
      income_benefit: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places.', pattern: false },
      death_benefit: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places.', pattern: false },
    });

    const trimWhitespaceControls = [
      'company_name', 'owner', 'insured_person', 'policy_number', 
      'insurance_agent_name'
    ];

    trimWhitespaceControls.forEach(controlName => {
      this.insuranceForm.get(`longTermCare.${controlName}`)?.valueChanges.subscribe(() =>
        this.validation.trimWhitespace(this.insuranceForm.get(`longTermCare.${controlName}`))
      );

      this.insuranceForm.get(`lifeInsurance.${controlName}`)?.valueChanges.subscribe(() =>
        this.validation.trimWhitespace(this.insuranceForm.get(`lifeInsurance.${controlName}`))
      );

      this.insuranceForm.get(`annuities.${controlName}`)?.valueChanges.subscribe(() =>
        this.validation.trimWhitespace(this.insuranceForm.get(`annuities.${controlName}`))
      );

      this.insuranceForm.get(`otherInsurance.${controlName}`)?.valueChanges.subscribe(() =>
        this.validation.trimWhitespace(this.insuranceForm.get(`otherInsurance.${controlName}`))
      );
    });
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlsKey
   */
  public getErrors(controlsKey: string): Array<string> {
    const formKeys = { 1: 'longTermCare', 2: 'lifeInsurance', 3: 'annuities', 4: 'otherInsurance' };
    let formGroup = formKeys[this.insuranceTypeNumber];
    return this.validation.getErrors(controlsKey, this.insuranceForm.controls[formGroup] as FormGroup);
  }

  /**
   * Validation on date must be forced when DatePicker is hidden.
   * @param control
   */
  public forceValidation(control: AbstractControl) {
    control.markAsDirty();
    control.updateValueAndValidity();
  }

  /**
   * Detect change on phone number component
   *
   * @param event
   */
  public detectChangePhoneNumber(event): void {
    this.countryCode = event?.value || this.countryCode;
  }

  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }

  /**
   * Validator to compare two dates. 'from' must be an earlier date than 'to'.
   * If true, return '{dates: {value: true}}', else return null.
   * @param {string} from
   * @param {string} to
   * @return {ValidatorFn}
   */
  dateLessThan(from: string, to: string): ValidatorFn {
    return (): ValidationErrors | null => {
      const formKeys = { 1: 'longTermCare', 2: 'lifeInsurance', 3: 'annuities', 4: 'otherInsurance' };
      const selectedForm = this.insuranceForm?.['controls'][formKeys[this.insuranceTypeNumber]];
      if (selectedForm?.value.end_date) {
        // Update value, otherwise we get the old value
        selectedForm?.updateValueAndValidity();
        const f = selectedForm.value[from];
        const t = selectedForm.value[to];
        if (Date.parse(f) >= Date.parse(t)) {
          return { dates: { value: true } };
        } else {
          return null;
        }
      }
    };
  }

  /**
   * Get insurance types
   */
  public getInsurancePolicyTypes(): void {
    forkJoin(
      [this.commonService.getInsuranceTypes(), this.commonService.getPolicyTypes()],
    ).subscribe((arrayResponse: Array<APIResponseModel>) => {
      const responseStatus = arrayResponse.map((d) => d.status).reduce((a, b) => (a && b));
      if (responseStatus) {
        this.policyTypes = this.commonHelper.convertToOptionsFormat(arrayResponse[1]?.data, 'id', 'name');
        this.insuranceTypes = this.commonHelper.convertToOptionsFormat(arrayResponse[0]?.data, 'id', 'name')
          .sort(this.commonHelper.compareDisplayText);

        // Always place 'Other' last to make it easy to find.
        this.commonHelper.placeAtPointOfArray(this.insuranceTypes, (e) => e.displayText.toLowerCase() == 'other');
      }
    });
  }

  get getSelectedForm(): FormGroup {
    let form;
    switch (this.insuranceTypeNumber) {
      case 1:
        form = this.insuranceForm['controls']['longTermCare'];
        break;
      case 2:
        form = this.insuranceForm['controls']['lifeInsurance'];
        break;
      case 3:
        form = this.insuranceForm['controls']['annuities'];
        break;
      case 4:
        form = this.insuranceForm['controls']['otherInsurance'];
        break;
    }
    return form as FormGroup;
  }

  /**
   * setter for editing business details
   */
  public editDetails() {
    if (this.beneficiaryCount === 1) {
      this.disableOpenModal = true;
    }
    if (this.editData?.['spousal_continuation'] == 0) {
      this.spousalContinuation = this.editData?.['spousal_continuation'];
    } else {
      this.spousalContinuation = this.toggleOptions[0].value;
    }
    if (this.editData && Object.keys(this.editData)?.length) {
      const data = { ...this.editData };
      this.otherTypeSelected = data['insured_person_id'] == '';
      this.insuranceTypeName = data['insurance_type']?.name;
      this.insuranceTypeNumber = data['insurance_type']?.type;
      this.insuranceTypeId = data['insurance_type']?.id;
      this.percentageDetailsInsuranceId = data['id'];
      this.insurancePercentageId = data?.['people']?.[0]?.pivot?.user_insurance_id;

      this.insuranceId = data['id'];
      const selectedForm = this.getSelectedForm;
      selectedForm.patchValue(data);
      selectedForm.patchValue({
        insurance_agent_contact: '',
        company_name: data['company_name'],
      });
      let tempPhoneNumber;
      if (data?.['insurance_agent_contact']) {
        tempPhoneNumber = data?.['insurance_agent_contact'];
        this.countryCode = { code: data?.['flag_code'], dialCode: data?.['country_code'] };
      }
      selectedForm.patchValue({
        insurance_agent_contact: tempPhoneNumber,
      });
      selectedForm.patchValue({
        start_date: this.commonHelper.formatDate(data['start_date'], this.domainDateFormat),
        end_date: this.commonHelper.formatDate(data['end_date'], this.domainDateFormat),
      });
      if ((this.insuranceTypeNumber == this.insuranceTypeConst.LI) || (this.insuranceTypeNumber == this.insuranceTypeConst.A)) {
        this.percentageDetails(this.percentageDetailsInsuranceId);
      }
    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.insuranceForm.controls;
  }

  /**
   * other Insurance form object getter for validation and showing errors in html
   */
  get formOtherGet(): FormGroup {
    return this.insuranceForm['controls'].otherInsurance['controls'];
  }

  /**
   * long term care form object getter for validation and showing errors in html
   */
  get formGetLTC(): { [p: string]: AbstractControl } {
    return this.insuranceForm['controls'].longTermCare['controls'];
  }

  /**
   * life insurance form object getter for validation and showing errors in html
   */
  get formGetLifeInsurance(): { [p: string]: AbstractControl } {
    return this.insuranceForm['controls'].lifeInsurance['controls'];
  }

  /**
   * annuities form object getter for validation and showing errors in html
   */
  get formGetAnnuity(): { [p: string]: AbstractControl } {
    return this.insuranceForm['controls'].annuities['controls'];
  }

  /**
   * change event listener for insurance type
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitterInsuranceType(selectedOption: SelectOptionsInterface): void {
    if (!selectedOption) return;
    if (selectedOption) {
      this.insuranceTypeNumber = selectedOption.additionalDetails.type;
      this.insuranceTypeName = selectedOption.additionalDetails.name;
      this.insuranceTypeId = selectedOption.value;
      let selectedForm;
      switch (this.insuranceTypeNumber) {
        case 1:
          selectedForm = this.insuranceForm['controls']['longTermCare'];
          break;
        case 2:
          selectedForm = this.insuranceForm['controls']['lifeInsurance'];
          break;
        case 3:
          selectedForm = this.insuranceForm['controls']['annuities'];
          break;
        default:
          selectedForm = this.insuranceForm['controls']['otherInsurance'];
      }
      if (selectedForm?.value?.id) return;

      this.resetForm(selectedOption.additionalDetails?.type);
      selectedForm.patchValue({
        insurance_type_id: selectedOption.value,
      });
    }
    this.insuranceType = selectedOption.value;
    if (this.insuranceTypeName.toLowerCase() === 'auto' || this.insuranceTypeName.toLowerCase() === 'auto insurance') {
      // this.insuranceForm['controls']['otherInsurance']['controls']['insured_vehicle'].setValidators([Validators.minLength(3), Validators.maxLength(20), Validators.pattern(ALPHA_NUMERIC_PATTERN), Validators.required]);
      // this.insuranceForm['controls']['otherInsurance']['controls']['insured_vehicle'].updateValueAndValidity();
    }
  }

  /**
   * change event listener for policy type
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public optionChangeEmitterPolicyType(selectedOption: SelectOptionsInterface): void {
    if (!selectedOption) return;
    if (selectedOption) {
      const selectedForm = this.getSelectedForm;
      if (selectedForm?.value?.id) return;
      selectedForm.patchValue({
        insurance_policy_type_id: selectedOption.value,
      });
    }
  }

  /**
   * add insurance
   */
  public onSubmit(): void {
    let selectedForm;
    let selectedFormControl;
    switch (this.insuranceTypeNumber) {
      case 1:
        selectedForm = this.insuranceForm.value['longTermCare'];
        selectedFormControl = this.insuranceForm.controls['longTermCare']; // get form control
        break;
      case 2:
        selectedForm = this.insuranceForm.value['lifeInsurance'];
        selectedFormControl = this.insuranceForm.controls['lifeInsurance'];// get form control
        break;
      case 3:
        selectedForm = this.insuranceForm.value['annuities'];
        selectedFormControl = this.insuranceForm.controls['annuities'];// get form control
        break;
      case 4:
        selectedForm = this.insuranceForm.value['otherInsurance'];
        selectedFormControl = this.insuranceForm.controls['otherInsurance'];// get form control
        break;
    }
    this.canEdit && selectedFormControl.markAllAsTouched();
    if (!this.otherTypeSelected) {
      selectedFormControl['controls']['insured_person_id']?.setValidators([Validators.required]);
      selectedFormControl['controls']['insured_person_id']?.updateValueAndValidity();
      selectedFormControl['controls']['insured_person']?.removeValidators([Validators.required]);
      selectedFormControl['controls']['insured_person']?.markAsUntouched();
    }
    if (selectedFormControl['controls']['insured_person_id']?.invalid) {
      this.scrollIntoInsuredPersonField();
      return
    }
    if (!selectedFormControl.valid) {
      console.log(selectedFormControl);
      this.commonHelper.scrollToFirstInvalidControl(selectedFormControl);
      return; // return if the form is invalid
    }
    this.submitLoader = true;

    // Remove commas for backend.
    this.currencyFields.forEach((form_name) => {
      if (selectedForm[form_name]) {
        selectedForm[form_name] = this.commonHelper.commaSeparateNumberClean(selectedForm[form_name], null);
      }
    });

    // Format dates.
    selectedForm.start_date = this.commonHelper.formatDate(selectedForm.start_date, DATE_FORMAT);
    selectedForm.end_date = this.commonHelper.formatDate(selectedForm.end_date, DATE_FORMAT);

    const payload: { [name: string]: any } = {
      ...selectedForm,
      roletype: this.userType,
      country_code: this.countryCode.dialCode,
      flag_code: this.countryCode.code,
      request_id: this.requestId,
      insurance_type_id: this.insuranceTypeId,
    };

    if (!selectedForm.id) {
      payload.id = (this.insuranceTypeNumber === 2 || this.insuranceTypeNumber === 3) ? this.insuranceId : '';
    }

    // add insurance
    this.assetsService.storeInsuranceDetails(payload).subscribe({
      next: (response: APIResponseModel) => {
        this.submitLoader = false;
        if (response.status) {
          this.toggleModalEmitter.emit({
            ...response.data,
            listStatus: (selectedForm.id) ? LIST_UPDATE : LIST_NEW,
          });
          this.insuranceId = response?.data?.id;
          if (this.userType === 'Consumer') {
            this.commonHelper.updateLocalstorageRequestStage(response.data);
            selectedForm.id ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();
            this.vehicleService.refreshDetailList(response.data);
          }
          // For annuity or life insurance
          if (this.insuranceTypeNumber == 3 || this.insuranceTypeNumber == 2) {
            this.submitBeneficiaries();
          }
          this.insuranceTypeId = null;
          this.insuranceTypeNumber = null;
          this.closeModel();
        }
      },
      error: (exception: any) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      },
    });
  }

  /**
   * delete insurance details
   */
  public deleteInsurance(): void {
    // ACL check
    if (!this.permissions?.['delete']) {
      this.toastrService.info('You do not have permission to delete items for this client.');
      return;
    }

    this.submitLoaderDelete = true;
    const selectedForm = this.getSelectedForm;

    // Store Insurance Details
    let payload: {} = {
      is_delete: '1',
      id: selectedForm.value.id,
      roletype: this.userType,
    };
    if (this.userType !== 'Consumer') {
      payload = { ...payload, request_id: this.requestId };
    }
    this.assetsService.storeInsuranceDetails(payload).subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.toggleModalEmitter.emit({
          id: selectedForm.value.id,
          listStatus: LIST_DELETE,
        });
        this.commonHelper.toastrDeleteSuccess();
        this.vehicleService.refreshDetailList(null);
        this.closeModel();
      }
      this.submitLoaderDelete = false;
    }, (exception: any) => {
      this.submitLoaderDelete = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
   * close modal
   */
  public closeModel(emitValue?: Object): void {
    this.resetForm();
    this.modalService.close('add-insurance-modal');
    this.selectedBeneficiaries = undefined;
    this.insuranceTypeNumber = 0;
    this.insuranceTypeId = '';
    this.toggleModalEmitter.emit(emitValue);
  }

  /**
   * Submit Beneficiaries to the user_retirement_asset defined by {@link userRetirementAssetId}.
   */
  public submitBeneficiaries(): void {
    this.submitLoader = true;
    const getData = (type) => {
      return {
        request_id: this.requestId,
        user_insurance_id: this.insuranceId, // insurance asset id to user
        beneficiary: this.chosenBeneficiaries[type],
        type: type,
      };
    };
    const data = { 1: getData(this.PRIMARY), 2: getData(this.SECONDARY) };
    this.personalDetailsService.submitBeneficiaries(data, 3).subscribe({
      error: (e) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(e.error);
      },
      complete: () => this.submitLoader = false,
    });
  }

  /**
   * Handles the date picker close event.
   * @param {boolean} isClosed - Indicates if the date picker is closed.
   */
  public datePickerCloseEventListener(isClosed) {
    if (this.dpClosedInterval) {
      clearTimeout(this.dpClosedInterval);
      this.dpClosedInterval = null;
    }
    if (isClosed) {
      this.isDisabled = true;
      this.dpClosedInterval = setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    } else {
      this.isDisabled = false;
    }
  }

  /**
   * Reset form
   * @param {number} type
   */
  public resetForm(type?: number): void {
    this.chosenBeneficiaries = { 1: [], 2: [] };
    this.insuranceId = undefined;
    this.otherTypeSelected = false;
    if ((this.insuranceTypeNumber == 2) || (this.insuranceTypeNumber == 3)) {
      this.nullifyArray();
    }
    let selectedForm;
    switch (type || this.insuranceTypeNumber) {
      case 1:
        selectedForm = this.insuranceForm['controls']['longTermCare'];
        selectedForm.patchValue({
          has_sole_ownership: 0,
        });
        break;
      case 2:
        selectedForm = this.insuranceForm['controls']['lifeInsurance'];
        break;
      case 3:
        selectedForm = this.insuranceForm['controls']['annuities'];
        break;
      case 4:
        selectedForm = this.insuranceForm['controls']['otherInsurance'];
        break;
      default:
        selectedForm = this.insuranceForm['controls']['otherInsurance'];
        break;
    }
    selectedForm.reset({
      insurance_type_id: this.insuranceTypes[0]?.value,
    });
    if (selectedForm.get('insurance_policy_type_id')) {
      selectedForm.patchValue({
        insurance_policy_type_id: this.policyTypes[0]?.additionalDetails?.id,
        has_sole_ownership: 0,
      });
    }

    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    if (this.insuranceTypeNumber === this.insuranceTypeConst.A) {
      selectedForm.patchValue({
        spousal_continuation: this.spousalContinuation,
      });
    }
  }

  /**
   * On Change toggle
   *
   * @param {ToggleOptionsInterface} selectedValue
   */
  public onChangeToggle(selectedValue: ToggleOptionsInterface): void {
    if (!selectedValue) return;
    this.spousalContinuation = selectedValue?.value;
    const selectedForm = this.getSelectedForm;
    if (this.insuranceTypeNumber === this.insuranceTypeConst.A) {
      selectedForm.patchValue({
        spousal_continuation: this.spousalContinuation,
      });
    }
  }

  /**
   Get beneficiary percentage details, populate {@link chosenBeneficiaries}.
   * @param insuranceId
   */
  private percentageDetails(insuranceId) {
    if ((this.insuranceTypeNumber == 2) || (this.insuranceTypeNumber == 3)) {
      if (insuranceId) {
        this.assetsService.getInsurancePercentageList(insuranceId).subscribe({
          next: (res) => {
            this.chosenBeneficiaries = this.commonHelper.populateChosenBeneficiaries(res.data?.beneficiary);
            this.selectedBeneficiaries = res.data?.beneficiary;
          },
          error: (exception: any) => {
            this.submitLoaderDelete = false;
            this.commonHelper.httpResponseHandler(exception?.error);
          },
        });
      }
    }
  }

  /**
   * make array empty
   */
  public nullifyArray() {
    this.disableOpenModal = false;
  }

  /**
   * mark a field as touched ( Trust property )
   * markAsTouchedInsuranceType
   */
  public markAsTouchedInsuranceType() {
    this.touchedInsuranceType = true;
  }

  /**
   * Accessor for commonHelpoptionChangeEmitterInsuranceTypeer in template
   */
  get getCommonHelper(): CommonHelper {
    return this.commonHelper;
  }

  /**
   * check selected insurance type is an auto or not
   */
  get isAuto(): boolean {
    return (this.insuranceTypeName?.toLowerCase() === 'auto' || this.insuranceTypeName?.toLowerCase() === 'auto insurance');
  }

  /**
   *
   */
  get useInsuredPerson(): boolean {
    return this.insuranceTypeNumber == 2 || this.isAuto;
  }

  /**
   * change ownership
   * @param {any} data
   */
  public onchangeOwnership(data: any) {
    if (data?.target?.checked) {
      this.ownerShipStatus = 1;

      this.getSelectedForm.patchValue({
        has_sole_ownership: this.ownerShipStatus,
      });
      this.getSelectedForm.get('joint_ownership_name').setValidators(Validators.required);
    } else {
      this.ownerShipStatus = 0;
      this.getSelectedForm.get('joint_ownership_name').clearValidators();
      this.getSelectedForm.get('joint_insured').clearValidators();
      this.getSelectedForm.patchValue({
        has_sole_ownership: this.ownerShipStatus,
        joint_ownership_name: '',
        joint_insured: '',
      });
    }
  }

  /**
   * get beneficiary list
   */
  public getUserPeople() {
    const userData = this.localStorageService.getUserData(USER_TYPES.user)?.user;
    let data: Array<Object> = [];

    const finallyFn = () => {
      // Vault Holder and 'Other' options
      data.push({ id: userData.id, full_name: 'Myself' });
      data.push({ id: '', full_name: 'Other' });

      // Convert to options, sort.
      this.userPeopleList = this.commonHelper.convertToOptions(data, 'id', 'full_name')
        .sort(this.commonHelper.compareDisplayText);

      // Always place 'Other' last to make it easy to find.
      this.commonHelper.placeAtPointOfArray(this.insuranceTypes, (e) => e.displayText.toLowerCase() == 'myself');
      this.commonHelper.placeAtPointOfArray(this.userPeopleList, (e) => e.displayText.toLowerCase() == 'other');
      this.commonHelper.placeAtPointOfArray(this.userPeopleList, (e) => e.displayText.toLowerCase() == 'myself', 'start');
    };

    this.personalDetailsService.getUserPeople(this.userType !== 'Consumer' ? this.requestId : undefined).subscribe({
      next: (res) => {
        if (res.status) {
          data.push(...res.data?.user_people);
          // Filter out advisor (uncomment beneficiary_type if it is decided to disallow trusts)
          data = data.filter((e) => /* e['beneficiary_type']?.type != 2 && */e['advisor'] == 0);
        }
      },
      error: finallyFn,
      complete: finallyFn,
    });
  }

  /**
   * change event listener for selecting an Insured Person from dropdown.
   *
   * @param {SelectOptionsInterface} selectedOption
   */
  public listenerOptionChangeInsuredPerson(selectedOption: SelectOptionsInterface): void {
    if (!selectedOption) return;
    const selectedForm = this.getSelectedForm;
    if (selectedOption?.value) {
      selectedForm.patchValue({
        insured_person_id: selectedOption.value,
        insured_person: selectedOption.displayText,
      });
      this.otherTypeSelected = false;
      selectedForm['controls']['insured_person_id'].clearValidators();
      selectedForm['controls']['insured_person'].clearValidators();
    } else {
      selectedForm['controls']['insured_person_id'].clearValidators();
      selectedForm.patchValue({
        insured_person_id: '',
        insured_person: '',
      });
      this.otherTypeSelected = true;
      selectedForm['controls']['insured_person_id'].clearValidators();
      selectedForm['controls']['insured_person'].setValidators([Validators.required]);
      selectedForm['controls']['insured_person'].updateValueAndValidity();
    }
  }

  /**
   * If user can edit existing asset, or add a new asset.
   */
  get canEdit(): boolean {
    return this.commonHelper.canEditSection(this.isNewAsset, this.permissions);
  }

  /**
   * check policy number have hyphen
   * remove initial hyphen
   * @param {any} data
   * @param {number} formNumber
   */
  public checkPolicyNumber(data: any, formNumber) {
    const selectedForm = this.getFormDetails(formNumber);
    if (data.target.value.charAt(0) == '-') {
      selectedForm.patchValue({
        policy_number: data.target.value.split('-')[0],
      });
    }
  }

  /**
   * get selected form group
   * @param {number} formNumber
   * @return form
   */
  public getFormDetails(formNumber) {
    switch (formNumber) {
      case 1:
        return this.insuranceForm['controls']['longTermCare'];
      case 2:
        return this.insuranceForm['controls']['lifeInsurance'];
      case 3:
        return this.insuranceForm['controls']['annuities'];
      default:
        return this.insuranceForm['controls']['otherInsurance'];
    }
  }

  /**
   * focusout of input field
   * changed as touched field
   * check and show validation error
   * @param {any} data
   * @param {string} key
   */
  public onFocusout(data: any, key: string) {
    this.insuranceForm['controls']['longTermCare']['controls'][key].setValidators([
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(40),
      Validators.pattern(NAME_PATTERN), this.validation.fn.trim,
    ]);
    this.insuranceForm['controls']['longTermCare']['controls'][key].updateValueAndValidity();
    if (data?.type == ELEMENT_EVENTS.FOCUSOUT) {
      this.insuranceForm['controls']['longTermCare']['controls'][key].markAsTouched();
    }
  }

  /**
   * change input field
   * changed as untouched field
   * @param {any} data
   * @param {string} key
   */
  public onChangeInput(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.INPUT) {
      this.insuranceForm['controls']['longTermCare']['controls'][key].markAsUntouched();
    }
  }

  /**
   * set validataion - mandatory(required)
   * cannot add validation in form builder
   * set and clear validation when needed
   *
   * @param {number} formKey
   * @param {string} fieldKey
   */
  public setAndUpdateMandatoryValidation(formKey: number, fieldKey: string) {
    const selectedForm = this.getFormDetails(formKey);
    if (fieldKey === 'insured_person_id' && !this.otherTypeSelected) {
      selectedForm['controls'][fieldKey].addValidators([Validators.required]);
      selectedForm['controls'][fieldKey].updateValueAndValidity();
      selectedForm['controls'][fieldKey].markAsTouched();
    }
    if (this.otherTypeSelected) {
      selectedForm['controls'][fieldKey].clearValidators();
    }
  }

  selectBeneficiaryRatioListener($event: SelectBeneficiaryRatioEmitModel) {
    this.chosenBeneficiaries = $event;
  }

  /**
   * Scrolls to the "owner" form control and focuses on the "insured_person_id" field.
   * NOTE: This function is implemented specifically for scroll functionality if invalid for the insured person field,
   * as the @link {scrollToFirstInvalidControl} functionality didn't work for the insured person field.
   */
  public scrollIntoInsuredPersonField() {
    const invalidElement = document.querySelector(`[id="insured_person_id"]`) as HTMLElement;
    const element = document.querySelector(`[formControlName="owner"]`) as HTMLElement;
    element?.scrollIntoView({ behavior: 'smooth' });
    invalidElement?.focus();
  }
}
