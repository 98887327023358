import { Component } from '@angular/core';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';


@Component({
  selector: 'app-consumer-use-policy',
  templateUrl: './consumer-use-policy.component.html',
  styleUrls: ['../documents.css'],
})
export class ConsumerUsePolicyComponent {

  /**
   * @constructor
   */
  constructor(
    private slugInterceptorService: SlugInterceptorService,
  ) {
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, false, true);
  }
}
