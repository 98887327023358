import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import _ from 'lodash';
import { ToggleOptionsInterface } from 'src/app/interface/common.interface';


@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
})
/**
 * Toggle switch component
 */
export class ToggleSwitchComponent implements OnChanges {
  @Input() options: Array<ToggleOptionsInterface>;
  @Input() defaultSelected: number;
  @Input() disabled: boolean = false;
  @Output() optionChangeEmitter = new EventEmitter<ToggleOptionsInterface>();
  public selectedOption: ToggleOptionsInterface;

  /**
   * called when changes occurs
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.defaultSelected || changes?.disabled) {
      this.selectedOption = _.find(this.options, { value: this.defaultSelected }) ?? this.options[0];
    } else {
      this.selectedOption = this.options[0];
    }
  }

  /**
   * On change listner
   */
  public onChange(): void {
    if (!this.disabled) {
      this.selectedOption = (this.selectedOption.value === this.options[0].value) ? this.options[1] : this.options[0];
      this.optionChangeEmitter.emit(this.selectedOption);
    }
  }
}
