<!-- <div class="absolute left-0 right-0 top-0 z-50" [class.bg-transparent]="!menuAlternate">
  <div class="w-full mx-auto px-3 sm:px-5 md:px-6">
    <div class="flex justify-between items-center py-4 custom-mobile-header">
      <div class="flex justify-start gap-2 lg:gap-4 items-center">
        <a class="cursor-pointer" (click)="menuChange('')">
          <img class="object-contain main-logo w-max-w-logo" width="300" src="assets/images/logo-old.png" alt="legacy-now">
          <p class="text-regular text-semibold text-sm text-center whitespace-nowrap logo-caption"
          [class]="!this.menuAlternate ? 'text-orange' : 'text-gray-100'">
            {{this.proType ? this.proType + " Edition" : ""}}
          </p>
        </a>
        <a *ngIf="orgLogoPath"  class=" border-l pl-6 cursor-pointer max-h-12 maxw128">
          <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
        </a>

      </div>
      <div class="md:-mr-2 -my-2 lg:hidden" *ngIf="!bannerPage">
        <button type="button" (click)="responsiveMenuToggle = !responsiveMenuToggle"
          class="bg-white rounded-md p-2 inline-flex items-center justify-center text-label-grey hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500"
          id="menuToggle" aria-controls="menuToggleItems" aria-expanded="false">
          <span class="sr-only">Open menu</span>
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>

      <div class="hidden lg:flex items-center justify-end gap-4" *ngIf="!bannerPage">
        <nav class="hidden lg:flex gap-4 xxl:gap-6 menu" aria-labelledby="navbar">
          <a (click)="menuChange('home')" [class.menu--active]="this.menuActive==='home'"
            class="cursor-pointer text-semibold fs-menu lh-menu"
            [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-secondary menu--inactive'">
            Home
          </a>
          <a (click)="menuChange('pricing')" [class.menu--active]="this.menuActive==='pricing'"
            class="cursor-pointer text-semibold fs-menu lh-menu"
            [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-secondary menu--inactive'">
            Pricing
          </a>
          <a (click)="menuChange('features')" [class.menu--active]="this.menuActive==='features'"
            class="cursor-pointer text-semibold fs-menu lh-menu"
            [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-secondary menu--inactive'">
            Features
          </a>
          <a (click)="menuChange('company')" [class.menu--active]="this.menuActive==='company'"
            class="cursor-pointer text-semibold fs-menu lh-menu"
            [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-secondary menu--inactive'">
            Company
          </a>
          <a (click)="menuChange('contact-us')" [class.menu--active]="this.menuActive==='contact-us'"
            class="cursor-pointer text-semibold fs-menu lh-menu"
            [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-secondary menu--inactive'">
            Contact
          </a>
          <a (click)="viewIntroVideo()" class="cursor-pointer text-semibold fs-menu lh-menu"
            [ngClass]="!menuAlternate ? 'text-blue menu--inactive__consumer': 'text-secondary menu--inactive'">
            Intro Video
          </a>
        </nav>
        <a (click)="menuChange('login')"
          class="cursor-pointer xl:border-l xl:pl-8 whitespace-nowrap text-semibold fs-menu"
          [ngClass]="!menuAlternate ? 'text-blue':'text-secondary'">
          Log In
        </a>
        <div class="btn-wrap">
          <button (click)="menuChange('contact-us', 'demo')"
            class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">
            Schedule Demo
          </button>
        </div>
      </div>
    </div>
  </div>

  <div id="menuToggleItems" *ngIf="responsiveMenuToggle&&!bannerPage"
    class="absolute top-20  inset-x-0 p-2 shadow-lg transition transform origin-top-right lg:hidden">
    <div class="rounded-lg bg-white divide-y-2 divide-gray-50 mx-4 mt-4">
      <div class="py-5 px-4">
        <nav class="grid" aria-labelledby="navbar">
          <a (click)="menuChange('home')"
            [ngClass]="(menuActive === 'home')?'text-white bg-red-700': 'text-primary'"
            class="cursor-pointer text-semibold fs-menu hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Home
          </a>
          <a (click)="menuChange('pricing')"
            [ngClass]="(menuActive === 'pricing')?'text-white bg-red-700': 'text-primary'"
            class="cursor-pointer text-semibold fs-menu hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Pricing
          </a>
          <a (click)="menuChange('features')" [ngClass]="{'text-white bg-red-700': (menuActive === 'features')}"
            class="cursor-pointer text-semibold fs-menu text-primary hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Features
          </a>
          <a (click)="menuChange('company')" [ngClass]="{'text-white bg-red-700': (menuActive === 'company')}"
            class="cursor-pointer text-semibold fs-menu text-primary hover:text-white px-4 py-2 rounded-md hover:bg-red-500">

            Company
          </a>
          <a (click)="menuChange('contact-us')" [ngClass]="{'text-white bg-red-700': (menuActive === 'contact-us')}"
            class="text-semibold fs-menu text-primary hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Contact
          </a>
          <a (click)="viewIntroVideo()"
            class="text-semibold fs-menu text-primary hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Intro Video
          </a>
          <a (click)="menuChange('login')"
            class="cursor-pointer text-semibold fs-menu text-primary hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Log In
          </a>
          <a (click)="menuChange('contact-us','demo')"
            class="cursor-pointer text-semibold fs-menu text-primary hover:text-white px-4 py-2 rounded-md hover:bg-red-500">
            Schedule Demo
          </a>
        </nav>
      </div>
    </div>
  </div>
</div> -->
<div class="md:pl-2 flex items-center gap-2 sm:gap-4 flex-row mt-4">
  <a (click)="menuChange('')" class="cursor-pointer">
    <img alt="The-Estate-Registry" width="250" [src]="'assets/images/logo-old.png'" class="object-contain w-52 lg:w-60">
    <p class="text-regular text-semibold text-orange text-sm lg:text-lg text-center">
      {{this.proTypeText ? this.proTypeText + " Edition" : ""}}
    </p>
  </a>
  <a *ngIf="orgLogoPath" class=" border-l-black pl-6 cursor-pointer max-h-12 maxw128">
    <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
  </a>
  <!-- <div class="flex items-center justify-center gap-2 md:gap-4 h-16 md:h-24">
    <div class="flex items-start gap-1 md:gap-2 flex-col">
      <p *ngIf="orgLogoPath "
        class="whitespace-nowrap text-xs md:text-sm lg:text-regular text-dark-grey">Sponsored by:</p>
      <a (click)="menuChange('')" *ngIf="orgLogoPath" class="cursor-pointer max-h-12 maxw128">
        <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
      </a>
    </div>
  </div> -->

  <div class="hidden lg:flex items-center justify-end md:flex-1 lg:w-0" *ngIf="showLoginBtn">
    <button (click)="menuChange('')"
      class="button-get-started whitespace-nowrap text-regular shadow-sm fs-nav-button bg-get-started mr-6">
      Login
    </button>
  </div>
</div>
<app-video-view-popup [id]="videoModalID" [userRole]="'professional'" [class]="'w-full h-full'"></app-video-view-popup>