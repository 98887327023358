<app-consumer-focused-navbar></app-consumer-focused-navbar>
<div class="relative flex items-center justify-center">
  <div class="max-w-screen mx-auto z-30 w-full xl:w-9/12 xxl:w-7/12">
    <div>
      <div class="grid grid-cols-12 gap-2 sm:gap-4 md:gap-6 lg:gap-8 relative order-last lg:order-first">

        <div class="col-span-12 lg:col-span-6 pt-0 pb-4 lg:pt-20 lg:pb-16">
            <h3 class="text-medium text-blue leading-5 text-lg sm:text-xl md:text-2xl lg:text-4xl md:pb-4 the-process">The process:</h3>
            <h1 class="text-semibold text-blue text-5xl hidden lg:block">
              Organized, secure,<br/>
              and easy.
            </h1>
            <h1 class="text-semibold text-blue block lg:hidden text-xl sm:text-2xl md:text-3xl lg:text-4xl pb-0">
              Organized, secure, and easy.
            </h1>
        </div>
        <div class="col-span-12 lg:col-span-6 relative order-first lg:order-last">
          <img src="assets/images/intro/process-steps.png" alt="Process Steps" class="p-0 lg:p-6 w-6/12 sm:w-7/12 md:w-7/12 lg:w-10/12 m-auto lg:m-0 lg:absolute lg:right-0 ">
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BEGIN: Start Now Wrapper -->
<div class="section section-even bg-blue-light pt-4">
  <div class="max-w-screen mx-auto w-full xl:w-9/12 xxl:w-7/12">
    <div class="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8">
      <div class="col-span-12">
        <h3 class="text-bold text-black text-xl md:text-2xl pb-0 lg:pb-6">Next steps:</h3>
            <ul class="list pl-4 lg:pl-6">
              <li class="li-disc text-base sm:text-lg md:text-xl lg:text-2xl text-black pb-3 sm:pb-4">Sign up for your Digital Vault</li>
              <li class="li-disc text-base sm:text-lg md:text-xl lg:text-2xl text-black pb-3 sm:pb-4">Fill it with asset info from banks, utilities, social media accounts,
                and whatever you want to store and share.<br/>
                <b>NOTE:</b> Your info is encrypted so only you and those you share it
                with can see it—we can never access the data.</li>
              <li class="li-disc text-base sm:text-lg md:text-xl lg:text-2xl text-black pb-0 sm:pb-1">Cancel within 30 days for a <b>full refund.</b></li>
            </ul>
      </div>
      <div class="col-span-12 mx-auto">
        <button (click)="navigateToRegister()"
                class="bg-primary text-white rounded-sm py-2 lg:py-3 px-16 md:px-20 lg:px-20 mt-0 lg:mt-2 text-xl xl:text-2xl font-medium uppercase font-sans w-auto focus:outline-none">
                LET'S GO
        </button>
      </div>
    </div>
  </div>
</div>
<!-- END: Start Now Wrapper -->

<app-consumer-focused-footer></app-consumer-focused-footer>
