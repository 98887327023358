import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { ValidationHelper } from 'src/app/helper/validation.helper';
import { ICountryCode, SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PropertyService } from 'src/app/services/property.service';
import { ALPHA_NUMERIC_WITH_HYPHEN_PATTERN, APARTMENT_NUMBER_PATTERN, LIST_DELETE, LIST_NEW, LIST_UPDATE, NAME_PATTERN, PHONE_PATTERN, UNITED_KINGDOM } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-add-prescription',
  templateUrl: './add-prescription.component.html',

})
/**
 * add prescription popup
 */
export class AddPrescriptionComponent implements OnInit {
  @Output() toggleModalEmitter = new EventEmitter<any>();
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() isNewAsset: boolean = true;
  @Input() clientHasPaidSubscription:boolean = false;
  public prescriptionForm: FormGroup;
  public submitLoaderDelete: boolean;
  public submitLoader: boolean;
  public preDefinedLoader: boolean;
  public userType: string;
  public requestId: string;
  public countryCode: ICountryCode;
  public defaultCountry: number;
  public selectedCountry: number;
  public selectedCountryName: string = '';
  public unitedKingdom: string = UNITED_KINGDOM;
  public elementRefSet: boolean = false;
  public searchElementRef: ElementRef;
  private hasPaidSubscription: boolean;

  @ViewChild('search', { static: false })
  set setSearchElementRef(value: ElementRef) {
    this.searchElementRef = value;
    if (this.searchElementRef && !this.elementRefSet) { // if not elementRef set initial value for searchElement
      this.updateAddressPacContainer(this.defaultCountry);
      this.elementRefSet = true;
    }
  }

  public domainDateFormat: any;
  public locale = 'en';
  public minDate: Date;
  public bsConfig: Partial<BsDatepickerConfig>;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private propertyService: PropertyService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private addressHelper: AddressHelper,
    private ngZone: NgZone,
    private toastrService: ToastrService,
    private localeService: BsLocaleService,
    private validation: ValidationHelper,
    private subscriptionService: ConsumerSubscriptionService,
  ) {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.hasPaidSubscription = r);
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.domainDateFormat = this.commonHelper.domainDateFormat;
    this.selectedCountry = environment.APP_DOMAIN;
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
    this.preDefinedLoader = false;
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    this.updateAddressPacContainer(this.defaultCountry);
    this.initForm();
    this.resetForm();
    this.isEnableState();
    this.applyLocale();

    this.bsConfig = Object.assign({}, {
      showWeekNumbers: false,
      adaptivePosition: true,
      minDate: this.minDate,
      dateInputFormat: this.domainDateFormat,
    });
    if (!this.canAccessForm) {
      this.prescriptionForm.disable();
    }
  }

  private initForm() {
    this.prescriptionForm = this.formBuilder.group({
      prescription_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      prescription_number: ['', { updateOn: 'blur', validators: [Validators.maxLength(20), Validators.pattern(ALPHA_NUMERIC_WITH_HYPHEN_PATTERN)] }],
      pharmacy_name: ['', { updateOn: 'blur', validators: [Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      address: ['', { updateOn: 'blur', validators: [Validators.minLength(3), Validators.maxLength(255)] }],
      apt_number: ['', { updateOn: 'blur', validators: [Validators.pattern(APARTMENT_NUMBER_PATTERN), Validators.maxLength(25)] }],
      phone: ['', { updateOn: 'blur', validators: [Validators.pattern(PHONE_PATTERN)] }],
      city: ['', { updateOn: 'blur', validators: [this.validation.noWhitespaceValidator()] }],
      country: ['', { updateOn: 'blur' }],
      state: [''],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.maxLength(10)] }],
      id: [''],
      prescription_id: [''],
      expiry_date: ['', { updateOn: 'blur', validators: [this.validation.fn.dateRange(this.minDate)] }],
    });

    Object.keys(this.prescriptionForm.controls).forEach(controlName => {
      this.prescriptionForm.get(controlName)?.valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.prescriptionForm.get(controlName))
      );
    });

    this.validation.setValidationErrors({
      expiry_date: { dateLow: null, dateHigh: null },
      phone: { pattern: 'Please enter a valid phone number' },
      zipcode: { pattern: 'Please enter a valid zipcode' },
      city: { whitSpaceValue: 'Please enter valid character'}
    });
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.prescriptionForm);
  }

  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }

  /**
   * setter for editing business details
   *
   * @param {any} data
   */
  @Input() set editData(data: any) {
    if (data && data?.id) {
      data.expiry_date = data.expiry_date && new Date(data.expiry_date.replace(/-/g, '/'));
      this.prescriptionForm.patchValue({
        ...data,
        country: data?.country?.id,
        city: data?.city?.id,
        state: data?.state?.id,
      });
      let tempPhoneNumber;
      if (data?.phone) {
        tempPhoneNumber = data?.phone;
        this.countryCode = { code: data?.flag_code, dialCode: data?.country_code };
      }
      this.prescriptionForm.patchValue({
        phone: tempPhoneNumber,
      });
      this.updateAddressPacContainer(data?.country?.id);
    }
  }

  /**
   * Initialize google map api by country
   * @param {any} countryId
   */
  public initMap(countryId: any): void {
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: (r) => {
        this.ngZone.run(() => {
          this.prescriptionForm.patchValue(r);
        });
      },
      error: (e) => console.error(e),
    });
  }

  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.prescriptionForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }

  /**
   * listen for options changes country
   *
   * @param {SelectOptionsInterface} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry) return;
    this.prescriptionForm.controls.state.reset();
    this.prescriptionForm.patchValue({
      country: selectedCountry.value,
    });
    this.updateAddressPacContainer(selectedCountry.value);
  }

  /**
   * remove pac container and update country in pac container
   *
   */
  public updateAddressPacContainer(initCountry: any) {
    this.addressHelper.removeAutocompletePacContainer();
    this.initMap(initCountry);
  }

  /**
   * listen for options changes state
   *
   * @param {SelectOptionsInterface} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.prescriptionForm.controls.city.reset();
    this.prescriptionForm.patchValue({
      state: selectedState.value,
    });
  }

  /**
   * listen for options changes state
   *
   * @param {SelectOptionsInterface} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.prescriptionForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.prescriptionForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([Validators.pattern(new RegExp(selectedCity.additionalDetails[0]?.postal_code_regex))]);
      zipCodeRef.updateValueAndValidity();
    }

    // this loader is set false here because of the delay to set country state city
    this.preDefinedLoader = false;
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.prescriptionForm.controls;
  }

  /**
   * add prescription
   */
  public onSubmit(): void {
    this.canEdit && this.prescriptionForm.markAllAsTouched();
    if (!this.prescriptionForm.valid) {
      this.commonHelper.scrollToFirstInvalidControl(this.prescriptionForm);
      return; // return if the form is invalid
    }
    this.submitLoader = true;
    // add prescription
    if (this.userType === 'Consumer') {
      const payLoad = {
        ...this.prescriptionForm.value,
        roletype: this.userType,
        request_id: this.localStorageService.getUserData()?.request?.id,
        is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
        country_code: this.countryCode.dialCode,
        flag_code: this.countryCode.code,
      };
      payLoad.state = this.selectedCountryName == this.unitedKingdom ? null : payLoad.state;
      payLoad.expiry_date = payLoad.expiry_date ?? null;
      this.commonService.getCityDetail(payLoad.country, payLoad.state, payLoad.city).subscribe((cResponse: APIResponseModel) => {
          if (cResponse?.status) {
            payLoad.city = cResponse?.data?.id;
            this.propertyService.addPrescription(payLoad).subscribe((response: APIResponseModel) => {
              if (response.status) {
                this.commonHelper.updateLocalstorageRequestStage(response.data);
                this.toggleModalEmitter.emit({
                  ...response.data,
                  listStatus: (this.prescriptionForm.value.id) ? LIST_UPDATE : LIST_NEW,
                });
                payLoad.id ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();
                this.closeModel();
                this.submitLoader = false;
              }
            }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
          }
        }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
      );
    } else {
      const payLoad = {
        ...this.prescriptionForm.value,
        roletype: this.userType,
        request_id: this.requestId,
        is_state: this.selectedCountryName == this.unitedKingdom ? '0' : '1',
        country_code: this.countryCode.dialCode,
        flag_code: this.countryCode.code,
      };
      payLoad.state = this.selectedCountryName == this.unitedKingdom ? null : payLoad.state;
      this.commonService.getCityDetail(payLoad.country, payLoad.state, payLoad.city).subscribe((cResponse: APIResponseModel) => {
          if (cResponse?.status) {
            payLoad.city = cResponse?.data?.id;
            this.propertyService.addPrescription(payLoad).subscribe((response: APIResponseModel) => {
              if (response.status) {
                this.submitLoader = false;
                this.toggleModalEmitter.emit({
                  ...response.data,
                  listStatus: (this.prescriptionForm.value.id) ? LIST_UPDATE : LIST_NEW,
                });
                this.closeModel();
              }
            }, (exception: any) => {
              this.submitLoader = false;
              this.commonHelper.httpResponseHandler(exception?.error);
            });
          }
        }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error)
        , () => this.submitLoader = false);
    }
  }

  /**
   * delete precription details
   */
  public deletePrescription(): void {
    // ACL check
    if (!this.permissions?.['delete']) {
      this.toastrService.info('You do not have permission to delete items for this client.');
      return;
    }

    this.submitLoaderDelete = true;
    let sendingData: {} = {
      is_delete: '1',
      id: this.prescriptionForm.value.id,
      roletype: this.userType,
    };
    if (this.userType !== 'Consumer') {
      sendingData = { ...sendingData, request_id: this.requestId };
    }

    //  Delete prescription
    this.propertyService.addPrescription(sendingData).subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.toggleModalEmitter.emit({
          id: this.prescriptionForm.value.id,
          listStatus: LIST_DELETE,
        });
        this.commonHelper.toastrDeleteSuccess();
        this.closeModel();
      }
      this.submitLoaderDelete = false;
    }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.commonHelper.scrollToTopModal(this.prescriptionForm);
    this.modalService.close('add-prescription-modal');
    this.resetForm();
    this.updateAddressPacContainer(this.defaultCountry);
  }

  /**
   * mark a field as touched ( Individual prescriptio fields )
   *
   * @param {string} prescription
   */
  public markAsTouchedIndividual(prescription: string): void {
    this.formGet[prescription].markAsTouched({
      onlySelf: true,
    });
  }

  /**
   * Reset form
   */
  public resetForm(): void {
    this.prescriptionForm.reset({
      country: this.defaultCountry,
    });
    this.elementRefSet = false;
    this.countryCode = environment.DEFAULT_COUNTRY_CODE;
  }

  get state() {
    return this.prescriptionForm.get('state');
  }

  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.prescriptionForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
    });
  }

  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.prescriptionForm.get('zipcode');
    const stateId = this.prescriptionForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        if (response.data.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          zipCodeRef.setValidators([Validators.pattern(new RegExp(zipCodeRegex))]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        } else {
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }

  /**
   * If user can edit existing asset, or add a new asset.
   */
  get canEdit(): boolean {
    return this.commonHelper.canEditSection(this.isNewAsset, this.permissions) && (this.hasPaidSubscription || this.clientHasPaidSubscription)
  }

  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }

  /**
   * Detect change on phone number component
   *
   * @param event
   */
  public detectChangePhonenumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }
}
