import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { PARTNER_RELATIONSHIP_STATUS, PEOPLE_SALUTATIONS } from 'src/constants/application.const';
import { FLAG_COUNTRY_CODES } from 'src/constants/phoneComponent.const';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-about-yourself-view',
  templateUrl: './about-yourself-view.component.html',
})
/**
 * About yourself view component
 */
export class AboutYourselfViewComponent implements OnInit {
  public userData: any;
  public salutationMapper: any;
  public relationShipMapper: any;
  public dataLoading: boolean;
  public partnerDetails: any;
  public childrenArray: Array<any>;
  public flagCountriesCodes: Array<any>;
  public userType: string;
  public requestId: string;
  public submitLoader: boolean;
  public showSSN: boolean;
  public domainViewDateFormat: string;


  /**
   * @constructor
   */
  constructor(
    private personalDetailsService: PersonalDetailsService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.flagCountriesCodes = FLAG_COUNTRY_CODES;
    this.showSSN = environment.APP_DOMAIN == 1;
    this.dataLoading = false;
    this.salutationMapper = {};
    this.relationShipMapper = {};
    this.partnerDetails = {};
    this.childrenArray = [];
    this.userType = this.localStorageService.getDataByKey('role');
    this.requestId = this.route.snapshot.params['id'];
    this.domainViewDateFormat = this.commonHelper.domainViewDateFormat;

    PEOPLE_SALUTATIONS.forEach((d) => {
      this.salutationMapper[d.value] = d.displayText;
    });

    // relationship mapper
    PARTNER_RELATIONSHIP_STATUS.forEach((d) => {
      this.relationShipMapper[d.value] = d.displayText;
    });

    this.getPersonalDetails();
  }

  /**
   * Get personal details
   */
  public getPersonalDetails(): void {
    this.dataLoading = true;
    // get children list
    this.personalDetailsService.getPersonalDetails(this.userType !== 'Consumer' ? this.requestId : undefined).subscribe((response: APIResponseModel) => {
      this.dataLoading = false;
      if (response.status) {
        this.userData = response.data;
        this.partnerDetails = _.find(response?.data?.user_people, { relation: 1 });
        this.childrenArray = _.filter(response?.data?.user_people, { relation: 2 });
      }
    }, (exception) => {
      this.dataLoading = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
   * save and exit
   */
  public saveAndExit() {
    this.submitLoader = true;
    if (this.userType === 'Consumer') {
      void this.slugInterceptorService.navigate(['dashboard']);
    } else {
      void this.slugInterceptorService.navigate(['dashboard'], null, true);
    }
  }

  /**
   * chnage route
   * @param{string}url
   * @param{string}type
   */
  public changeRoute(url: string, type?: string) {
    if (type === 'pro') {
      void this.slugInterceptorService.navigate([url], null, true);
      return;
    }
    void this.slugInterceptorService.navigate([url]);
  }

  /**
   * Click handler
   * @param command
   */
  public click(command: string) {
    const isPro = this.userType !== 'Consumer';
    switch (command) {
      case 'AboutYourself':
        const about_yourself_url = ['about-yourself', isPro ? this.requestId : ''];
        void this.slugInterceptorService.navigate(about_yourself_url);
        break;
      case 'Partner':
        const relationship_url = ['about-yourself', 'partner', isPro ? this.requestId : ''];
        void this.slugInterceptorService.navigate(relationship_url);
        break;
      case 'Children':
        const children_url = ['about-yourself', 'children', isPro ? this.requestId : ''];
        void this.slugInterceptorService.navigate(children_url);
        break;
      case 'Next':
        const next_url = ['executor-beneficiary', 'beneficiary-info', isPro ? this.requestId : ''];
        void this.slugInterceptorService.navigate(next_url);
        break;
      case 'ReturnToDashboard':
        const return_url = isPro ? ['overview', 'about-yourself-overview'] : ['dashboard'];
        void this.slugInterceptorService.navigate(return_url);
        break;
    }
  }

  /**
   * Get full address
   * @param userData
   * @return full address
   */
  public getFullAddress(userData: any): string {
    if (userData) {
      const country = this.capitalizeFirstLetter(userData.country?.name);

      return String(
        this.commonHelper.addCommas(
          userData?.address, userData?.apt_number, userData.city?.name, userData.state?.name,country)
      );
    } else {
      return '';
    }
  }

  /**
   * check dob is valid
   * @param {any} data
   */
  public checkValidDob(data: any) {
    if (Number.isNaN(new Date(data).valueOf()) === true || data === null) {
      return '';
    } else {
      return data;
    }
  }

  /**
   * getter for commonHelper
   */
  public get commonHelperFunction() {
    return this.commonHelper;
  }

  /**
   * Capitalize First Letter
   * @param{string} country
   * @return{string}
   */
  public capitalizeFirstLetter(country?: string) {
    return country.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  getName(first: string, middle: string, last: string) {
    const names = [first ?? '', middle ?? '', last ?? ''];    // Handle null/undefined
    let name = names.join(' ');                               // concatenate
    return name.replace(/\s\s+/g, ' ');  // handle double-space
  }
}
