<app-common-modal id="{{modal_id}}" class="app-common-modal" xmlns="http://www.w3.org/1999/html"
  [heightClass]="'min-h-75 md:h-auto h-full'">
  <img src="assets/images/about-yourself/close.svg"
    class="absolute right-2 w-8 -mt-2 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
    (click)="closeModel()">
  <div class="mb-4">
    <div>
      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
        Financial Assets
      </h4>
      <p class="text-grey text-regular pt-1 text-sm">Start by adding your financial asset.</p>
      <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="!this.canEdit && !isConsumer">
        You do not have permission
        to {{ isNewAsset ? 'add information for this client.' : 'edit this client\'s information.' }}
      </p>
    </div>
  </div>
  <div class="col-span-12 relative">
    <label for="asset_type" class="text-label-grey text-sm text-regular">Type of Asset*</label>
    <app-select-with-search id="asset_type" [options]="assetType" [placeholderValue]="'Select Asset Type'"
      (optionChangeEmitter)="optionChangeEmitterAssetType($event)" [disabled]="!canEdit || !canAccessForm"
      [defaultSelected]="financialAssetTypeId" [ifError]="!financialAssetTypeId && touchedAssetType" tabindex="0"
      (focusout)="canAccessForm ? markAssetType() : ''"></app-select-with-search>
    <div *ngIf="!financialAssetTypeId && touchedAssetType && canEdit">
      <p class="text-regular font-medium text-xs mt-2 error-alert">
        Asset type is required
      </p>
    </div>
  </div>
  <form *ngIf="financialAssetType === formType.NC" [formGroup]="nonCryptoForm" autocomplete="off"
    (ngSubmit)="onSubmit()">
    <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
      <div class="col-span-12 relative">
        <label for="designation_type" class="text-grey text-sm text-regular">Designation Type*</label>
        <div class="col-span-12 lg:col-span-2 relative">
          <app-select-component id="designation_type" [options]="DesignationTypes" placeholder="Select Designation Type"
            [disabled]="!canEdit" [ifError]="!selectedDesignationValue && touchedDesignationType"
            [selectedOptionDefault]="selectedDesignationValue"
            (optionChangeEmitter)="optionChangeListener($event)"></app-select-component>
          <div *ngIf="!selectedDesignationValue && touchedDesignationType">
            <p class="text-regular font-medium text-xs mt-2 error-alert">
              Designation type is required
            </p>
          </div>
        </div>
      </div>
      <div class="col-span-12 mt-4">
        <div>
          <label class="flex items-center gap-3">
            <input type="checkbox" class="form-checkbox h-5 w-5 text-darkblue focus:outline-none"
              formControlName="has_sole_ownership" [attr.disabled]="!canEdit || undefined">
            <p class="text-regular text-base lg:text-lg text-darkblue font-semibold">
              I do NOT have sole ownership of this financial asset
            </p>
          </label>
        </div>

      </div>
      <div *ngIf="getControls(formType.NC)?.['has_sole_ownership']?.value" class="col-span-12">
        <label class="text-label-grey text-sm text-regular">Name of Co-owner*</label>
        <input type="text" [attr.disabled]="!canEdit || undefined"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="Name of Co-owner" formControlName="joint_ownership_name"
          [ngClass]="getControls(formType.NC)?.['joint_ownership_name'].errors && getControls(formType.NC)?.['joint_ownership_name'].touched?'form-input-error':'form-input-focus'">
        <p *ngFor="let error of getErrors('joint_ownership_name')"
          class="text-regular font-medium text-xs mt-2 error-alert">
          {{ error }}
        </p>
      </div>
      <div class="col-span-12">
        <label for="account_name" class="text-label-grey text-sm text-regular">Asset Nickname</label>
        <input type="text" [attr.disabled]="!canEdit || undefined"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="Asset Nickname" formControlName="account_name" name="account_name" id="account_name"
          [ngClass]="getControls(formType.NC)?.['account_name'].errors && getControls(formType.NC)?.['account_name'].touched ? 'form-input-error' : 'form-input-focus'">
        <p *ngFor="let error of getErrors('account_name')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{ error }}
        </p>
      </div>
      <div class="col-span-12">
        <label for="bank_name" class="text-label-grey text-sm text-regular">Bank or Brokerage (Where Asset is
          Held) </label>
        <input type="text" [attr.disabled]="!canEdit || undefined"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="e.g. Bank of America" formControlName="bank_name" name="bank_name" id="bank_name"
          [ngClass]="getControls(formType.NC)?.['bank_name'].errors && getControls(formType.NC)?.['bank_name'].touched?'form-input-error':'form-input-focus'">
        <p *ngFor="let error of getErrors('bank_name')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{ error }}
        </p>
      </div>
      <div class="col-span-12 md:col-span-6">
        <label for="account_number" class="text-label-grey text-sm text-regular">Account Number</label>
        <input type="text" [attr.disabled]="!canEdit || undefined"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="e.g. 026009593" formControlName="account_number" name="account_number" id="account_number"
          [ngClass]="getControls(formType.NC)?.['account_number'].errors && getControls(formType.NC)?.['account_number'].touched?'form-input-error':'form-input-focus'">

        <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="canShowErrorMessage('account_number')">
          Please enter Account Number
        </p>
        <p *ngFor="let error of getErrors('account_number')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{ error }}
        </p>
      </div>
      <div class="col-span-12 md:col-span-6">
        <label for="v_account_number" class="text-label-grey text-sm text-regular">Verify Account Number</label>
        <input type="text" [attr.disabled]="!canEdit || undefined"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="e.g. 026009593" formControlName="v_account_number" name="v_account_number" id="v_account_number"
          (focusout)="getControls(formType.NC)?.['v_account_number'].markAsTouched()"
          [ngClass]="(getControls(formType.NC)?.['account_number'].touched || getControls(formType.NC)?.['v_account_number'].touched) && !isVerified('account_number') && getControls(formType.NC)?.['v_account_number'].value?'form-input-error':'form-input-focus'">
        <div
          *ngIf="(getControls(formType.NC)?.['v_account_number'].touched || getControls(formType.NC)?.['account_number'].touched) && !isVerified('account_number') && getControls(formType.NC)?.['account_number'].valid && getControls(formType.NC)?.['account_number'].value">
          <p class="text-regular font-medium text-xs mt-2 error-alert">
            Account Numbers must match.
          </p>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6" *ngIf="useRouting">
        <label for="routing_number" class="text-label-grey text-sm text-regular">Routing Number</label>
        <input type="text" [attr.disabled]="!canEdit || undefined"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="e.g. 026009593" formControlName="routing_number" name="routing_number" id="routing_number"
          [ngClass]="getControls(formType.NC)?.['routing_number'].errors && getControls(formType.NC)?.['routing_number'].touched?'form-input-error':'form-input-focus'">
        <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="canShowErrorMessage('routing_number')">
          Please enter
          Routing Number
        </p>
        <p *ngFor="let error of getErrors('routing_number')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{ error }}
        </p>
      </div>
      <div class="col-span-12 md:col-span-6" *ngIf="useRouting">
        <label for="v_routing_number" class="text-label-grey text-sm text-regular">Verify Routing number</label>
        <input type="text" [attr.disabled]="!canEdit || undefined"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="e.g. 026009593" formControlName="v_routing_number" name="v_routing_number" id="v_routing_number"
          [ngClass]="(getControls(formType.NC)?.['routing_number'].touched || getControls(formType.NC)?.['v_routing_number'].touched) && !isVerified('routing_number')?'form-input-error':'form-input-focus'">
        <div
          *ngIf="(getControls(formType.NC)?.['v_routing_number'].touched || getControls(formType.NC)?.['routing_number'].touched) && !isVerified('routing_number') && getControls(formType.NC)?.['routing_number'].valid && getControls(formType.NC)?.['routing_number'].value">
          <p class="text-regular font-medium text-xs mt-2 error-alert">
            Routing Numbers must match.
          </p>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6" *ngIf="assetName == 'Annuity'">
        <label for="contract_number" class="text-label-grey text-sm text-regular">Contract Number</label>
        <input type="text" [attr.disabled]="!canEdit || undefined"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="e.g. 026009593" formControlName="contract_number" name="contract_number" id="contract_number"
          [ngClass]="getControls(formType.NC)?.['contract_number'].errors && getControls(formType.NC)?.['contract_number'].touched?'form-input-error':'form-input-focus'">
        <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="canShowErrorMessage('contract_number')">
          Please enter Contract Number
        </p>
        <p *ngFor="let error of getErrors('contract_number')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{ error }}
        </p>
      </div>
      <div class="col-span-12 md:col-span-6" *ngIf="assetName == 'Annuity'">
        <label for="v_contract_number" class="text-label-grey text-sm text-regular">Verify Contract Number</label>
        <input type="text" [attr.disabled]="!canEdit || undefined"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="e.g. 026009593" formControlName="v_contract_number" name="v_contract_number"
          id="v_contract_number"
          [ngClass]="getControls(formType.NC)?.['v_contract_number'].touched && getControls(formType.NC)?.['v_contract_number'].value && !isVerified('contract_number')?'form-input-error':'form-input-focus'">
        <div
          *ngIf="!isVerified('contract_number') && getControls(formType.NC)?.['contract_number'].valid && getControls(formType.NC)?.['contract_number'].value && getControls(formType.NC)?.['v_contract_number'].touched">
          <p class="text-regular font-medium text-xs mt-2 error-alert">
            Contract Numbers must match.
          </p>
        </div>
      </div>
      <div class="col-span-12">
        <label for="currency-amount" class="text-label-grey text-sm text-regular">Amount</label>
        <div class="relative flex items-center">
          <span class="absolute ml-4 outline-none">{{ undefined | getCurrencySymbol }}</span>
          <input type="text" [attr.disabled]="!canEdit || undefined"
            class="price-input h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="e.g. 1,000.00" formControlName="current_balance" name="currency-amount" id="currency-amount"
            appCurrencyInput
            [ngClass]="getControls(formType.NC)?.['current_balance'].errors && getControls(formType.NC)?.['current_balance'].touched?'form-input-error':'form-input-focus'">
        </div>
        <p *ngFor="let error of getErrors('current_balance')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{ error }}
        </p>
      </div>
      <!-- Beneficiaries Section show for all types -->
      <ng-container>
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide mb-2">
            Add Beneficiaries
          </h4>
          <p class="text-grey text-regular mb-2 text-sm">
            This information should match what is in your last will. It is important that should this information
            change, you update this record
          </p>
          <app-select-beneficiary-ratio [editData]="selectedBeneficiaries" [canEdit]="canAccessForm"
            (selectBeneficiaryRatioEmitter)="selectBeneficiaryRatioListener($event)">
          </app-select-beneficiary-ratio>
        </div>
      </ng-container>
    </div>

    <!-- Web Access Section -->
    <div class="col-span-12" *ngIf="!isPlaidData">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 ">
          <div class="flex flex-row items-baseline">
            <div>
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Web Access
              </h4>
              <p class="text-grey text-regular pt-1 text-sm">
                Online access information for this asset.
              </p>
            </div>
          </div>
        </div>
        <div class="col-span-12">
          <label for="website" class="text-label-grey text-sm text-regular">Web URL</label>
          <div class="relative">
            <input type="url" [attr.disabled]="!canEdit || undefined"
              class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
              placeholder="www.mybank.com" formControlName="website" id="website"
              [ngClass]="getControls(formType.NC).website.errors && getControls(formType.NC).website.touched?'form-input-error':'form-input-focus'">
            <p *ngFor="let error of getErrors('website')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{ error }}
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6" *ngIf="!isPlaidData">
          <label for="user_name" class="text-label-grey text-sm text-regular">Username</label>
          <input type="text" [attr.disabled]="!canEdit || undefined" autocomplete="off"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="e.g. Marktylor_54-" formControlName="user_name" name="user_name" id="user_name"
            [ngClass]="getControls(formType.NC)?.['user_name'].errors && getControls(formType.NC)?.['user_name'].touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('user_name')" class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
        <div class="col-span-12 md:col-span-6" *ngIf="!isPlaidData">
          <label for="password" class="text-label-grey text-sm text-regular">Password</label>
          <div class="relative">
            <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
              (click)="togglePasswordView = !togglePasswordView">
              <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
              <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
            </a>
            <input [type]="togglePasswordView ? 'text' : 'password'" [attr.disabled]="!canEdit || undefined"
              autocomplete="off"
              class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
              placeholder="e.g. JOHy56-89_" formControlName="password" name="password" id="password"
              [ngClass]="getControls(formType.NC).password.errors && getControls(formType.NC).password.touched?'form-input-error':'form-input-focus'">
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
      <app-button-loader *ngIf="nonCryptoForm.value.id" class="w-full sm:w-auto" [buttonText]="'Remove Details'"
        [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true" (click)="deleteFinancialAssets()"
        [valid]="!submitLoader">
      </app-button-loader>
      <app-button-loader class="w-full sm:w-auto" [buttonText]="(isNewAsset ? 'Save' : 'Update') + ' Details'"
        [valid]="isVerified(['account_number','routing_number','contract_number']) && !submitLoaderDelete"
        [loader]="submitLoader" [buttonType]="'submit'">
      </app-button-loader>
    </div>
  </form>


  <form *ngIf="financialAssetType === formType.C" [formGroup]="cryptoForm" autocomplete="off" (ngSubmit)="onSubmit()">
    <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">

      <div class="col-span-12">
        <label for="currency-name" class="text-label-grey text-sm text-regular">Name of Currency*</label>
        <input type="text" [attr.disabled]="!canEdit || undefined"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="e.g. Bitcoin" formControlName="currency_name" name="currency-name" id="currency-name"
          [ngClass]="getControls(formType.C)?.['currency_name'].errors && getControls(formType.C)?.['currency_name'].touched?'form-input-error':'form-input-focus'">
        <p *ngFor="let error of getErrors('currency_name')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{ error }}
        </p>
      </div>
      <div class="col-span-12">
        <label for="currency-amount" class="text-label-grey text-sm text-regular">Amount</label>
        <div class="relative flex items-center">
          <input type="text" [attr.disabled]="!canEdit || undefined" appCurrencyInput
            class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
            placeholder="e.g. 1,000.00" formControlName="current_balance" name="currency-amount"
            [ngClass]="getControls(formType.C)?.['current_balance'].errors && getControls(formType.C)?.['current_balance'].touched?'form-input-error':'form-input-focus'">
        </div>
        <p *ngFor="let error of getErrors('current_balance')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{ error }}
        </p>
      </div>
      <div class="col-span-12">
        <label for="currency-symbol" class="text-label-grey text-sm text-regular">Symbol</label>
        <input type="text" [attr.disabled]="!canEdit || undefined"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="e.g. XBT" formControlName="currency_symbol" name="currency-symbol" id="currency-symbol"
          [ngClass]="getControls(formType.C)?.['currency_symbol'].errors && getControls(formType.C)?.['currency_symbol'].touched?'form-input-error':'form-input-focus'">
        <p *ngFor="let error of getErrors('currency_symbol')" class="text-regular font-medium text-xs mt-2 error-alert">
          {{ error }}
        </p>
      </div>

      <div class="col-span-12">
        <label for="currency-held-in" class="text-label-grey text-sm text-regular">Where it is held?</label>
        <input type="text" [attr.disabled]="!canEdit || undefined"
          class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
          placeholder="e.g. Digital Wallet" formControlName="currency_held_in" name="currency-held-in"
          id="currency-held-in"
          [ngClass]="getControls(formType.C)?.['currency_held_in'].errors && getControls(formType.C)?.['currency_held_in'].touched?'form-input-error':'form-input-focus'">
        <p *ngFor="let error of getErrors('currency_held_in')"
          class="text-regular font-medium text-xs mt-2 error-alert">
          {{ error }}
        </p>
      </div>

      <div class="col-span-12">
        <label for="currency-description" class="text-label-grey text-sm text-regular">Description</label>
        <textarea rows="4" cols="50"
          class="input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
          id="currency-description" name="currency-description" formControlName="currency_description"
          placeholder="A cryptocurrency is an encrypted data string that denotes a unit of currency. "
          [ngClass]="getControls(formType.C)?.['currency_description'].errors && getControls(formType.C)?.['currency_description'].touched?'form-input-error':'form-input-focus'">
          </textarea>
        <p *ngFor="let error of getErrors('currency_description')"
          class="text-regular font-medium text-xs mt-2 error-alert">
          {{ error }}
        </p>
      </div>
    </div>

    <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
      <app-button-loader *ngIf="cryptoForm.value.id" class="w-full sm:w-auto" [buttonText]="'Remove Details'"
        [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true" (click)="deleteFinancialAssets()"
        [valid]="!submitLoader">
      </app-button-loader>
      <app-button-loader class="w-full sm:w-auto" [buttonText]="(isNewAsset ? 'Save' : 'Update') + ' Details'"
        [valid]="isValidCrypto && !submitLoaderDelete" [loader]="submitLoader" [buttonType]="'submit'">
      </app-button-loader>
    </div>
  </form>
</app-common-modal>