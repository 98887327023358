<div class="lg:grid grid-cols-12 background-color-grey">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 my-4">
              <div class="col-span-12 sm:col-span-2">
                  <img src="assets/images/executors/executors.svg" class="w-20" alt="digital vault">
              </div>

              <div class="col-span-12 sm:col-span-10">
                  <h2 class="text-darkblue text-md sm:text-2xl break-words uppercase text-semibold">
                    Heirs and Beneficiaries
                  </h2>
                  <p class="text-sm text-grey tracking-wide text-regular">
                    This information is critical and a key to verifying their identity. Please double-check
                    your input prior to moving on.
                  </p>
              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-3/6'"></app-card-progress-bar>
              <!-- END: Progress Bar -->

              <div class="p-6">
                <div class="grid grid-cols-12 items-center gap-4 row-gap-4">
                  <div class="col-span-12">
                    <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                      Who do you want as your heirs and beneficiaries?
                    </h4>
                  </div>
                </div>

                <!-- people listing component -->
                <app-list-people [lpSettings]="lpSettings" [userPeopleData]="userPeopleData"
                  (clickEmitter)="clickListener($event)">
                </app-list-people>

                <div class="mt-10">
                  <button type="button"
                    class="button button--lg w-full flex flex-row justify-center items-center bg-transparent border-lightblue rounded-xs h-12 md:h-16 focus:outline-none text-regular font-semibold tracking-wide text-darkblue px-6 text-sm md:text-base"
                    (click)="toggleModal()">
                    <img src="assets/images/about-yourself/add-plus.svg" class="w-6 mr-3 hidden sm:block" alt="digital vault">
                    Add a new beneficiary
                  </button>
                </div>

                <div class="flex flex-col-reverse sm:flex-row justify-between gap-4 items-center mt-4">
                  <a (click)="clickBack()"
                    class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </a>
                  <app-button-loader class="w-full sm:w-auto" [buttonText]="'Save and Continue'" [valid]="getBeneficiaries?.length > 0"
                    [loader]="submitLoader" buttonType="'button'" (click)="saveAndContinue()">
                  </app-button-loader>
                </div>
              </div>
              <!-- END: Leftside Card -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class="col-span-12 md:col-span-6 lg:grid justify-center">
    <about-yourself-right-content [formInputValue]="{}"></about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->

</div>

<!-- Modals -->
<app-choose-people [userPeopleData]="choosePeopleData" [cpcSettings]="cpcSettings" [loadListener]="lpSettings.isLoading"
  (updateEmitter)="listenerChoosePeople($event)">
</app-choose-people>

<app-add-beneficiary [editData]="editData" (toggleModalEmitter)="listenerAddBeneficiary($event)">
</app-add-beneficiary>

<app-message-modal [setMessageData]="messageData" (buttonEmitter)="messageModalListener($event)"></app-message-modal>
