<div class="scroll-hidden background-color-grey" *ngIf="!showCardScreens">
  <app-navbar *ngIf="this.userType === 'Consumer'"></app-navbar>
  <!-- <app-professional-navbar *ngIf="this.userType !== 'Consumer'"></app-professional-navbar> -->
  <app-exe-cus-navbar *ngIf="userType === 'Executor'|| userType === 'Custodian'"></app-exe-cus-navbar>

  <ng-container *ngIf="loader.check('subscription_details'); else content">
    <div class="center-screen">
      <div class="loading-red-free m-auto h-24 w-24"></div>
    </div>
  </ng-container>

  <!--second part-->
  <ng-template #content>
    <div class="grid grid-cols-12 space-x-4 space-y-8 p-6 md:p-8 lg:px-12 xl:px-24">
      <!-- BEGIN: Leftside Content -->
      <div class="col-span-12 lg:col-span-7">
        <div class="w-full">
          <h2 class="text-darkblue text-xl lg:text-2xl xl:text-4xl uppercase text-semibold pt-1">Subscription</h2>
          <p class="text-md text-grey py-2 tracking-wide text-regular"> Select your plan to get access to your LegacyNOW
            Digital Vault</p>
          <p class="border-b-8 w-24 border-red mb-3"></p>
        </div>
        <div class="mb-6">
          <app-vault-service-options></app-vault-service-options>
        </div>
        <div class="w-full" *ngIf="isPaymentCompleted && !paidByProfessional">
          <div
            class="flex flex-col sm:flex-row gap-3 sm:items-center justify-start bg-white rounded-md border-2 border-grey-600 p-4 md:p-6 mb-8">
            <div>
              <h3 class="text-darkblue text-lg md:text-xl text-semibold text-center sm:text-left">
                Cancel Your Subscription
              </h3>
              <p class="text-base text-grey py-1 text-regular w-full xl:w-9/12">
                By canceling your subscription, you will only continue to have restricted access to LegacyNOW until the
                end of your current billing period.
              </p>
            </div>
            <div class="flex justify-center sm:justify-end sm:ml-auto">
              <app-button-loader (click)="openConfirmationModal()" class="whitespace-nowrap" [outlineOnly]="true"
                [buttonText]="'Cancel Subscription'" [loader]="loader.check()" [buttonType]="'submit'">
              </app-button-loader>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Leftside Content -->

      <!-- BEGIN: Rightside Content -->
      <div class="col-span-12 lg:col-span-5 lg:grid ">
        <div class="w-full mb-6">
          <img src="assets/images/dashboard/pricing.svg" alt="Dashboard Completed">
        </div>
        <div class="w-full mb-8" *ngIf="!!dlbId">
          <div class="bg-white rounded-md border-2 border-grey-600 p-4 md:p-6 ">
            <div class="flex gap-4 items-center justify-start">
              <img src="assets/images/payment/dlb.svg" class="w-20 h-20" alt="Payment dv">
              <div>
                <h3 class="text-grey text-base msm:text-lg font-light mb-2">Your LegacyNOW Digital Vault ID</h3>
                <p class="text-base lg:text-xl text-darkblue tracking-wide font-semibold text-regular">{{dlbId}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full mb-8">
          <div
            class="border-card bg-white rounded p-4 px-6 sm:flex sm:flex-row xl:flex xl:flex-row lg:flex lg:flex-col">
            <div class="py-1 xl:flex-2">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Contact Us
              </h4>
              <div class="flex flex-row items-center mt-3">
                <img src="assets/images/dashboard/phone.png" alt="digital vault" class="w-4 object-contain">
                <a href="tel:+1{{customerSupportPhone}}" class="text-darkgrey text-regular ml-4 text-sm">
                  Call us on +1 {{customerSupportPhone}}
                </a>
              </div>
              <div class="flex flex-row items-baseline pt-2">
                <img src="assets/images/dashboard/mail.png" alt="digital vault" class="w-4 object-contain">
                <a href="mailto:{{customerSupportMail}}" class="text-darkgrey text-regular ml-4 text-sm">
                  Email us {{customerSupportMail}}
                </a>
              </div>
            </div>
            <div
              class="mt-2 md:mt-0 flex justify-center sm:justify-end lg:justify-center lg:mt-2 xl:mt-0 xl:justify-end items-center flex-1">
              <div class="flex items-center">
                <img src="assets/images/dashboard/support-img1.png" alt="digital vault"
                  class="w-10 h-10 object-contain -mr-2">
                <img src="assets/images/dashboard/support-img2.png" alt="digital vault"
                  class="w-16 h-16 object-contain z-10 border-white rounded-full">
                <img src="assets/images/dashboard/support-img3.png" alt="digital vault"
                  class="w-10 h-10 object-contain -ml-2">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Rightside Content -->
    </div>
  </ng-template>
  <!--end-->


</div>
<app-subscription-plan-modal [updatePlanButton]="updatePlanButton" [subscriptionType]="subscriptionDetails?.name"
  [subscriptionDetails]="subscriptionDetails" [monthlyPackageDetails]="monthlyPackageDetails"
  [yearlyPackageDetails]="yearlyPackageDetails" [currentSubscriptionType]="subscriptionDetails?.name"
  (clickEmitter)="hideButtons($event)">
</app-subscription-plan-modal>
<app-consumer-update-card-details *ngIf="showCardScreens" [vaultRegistrationAmount]="vaultRegistrationAmount"
  [activePackage]="activePackage" [dlbId]="dlbId" [paymentCompleted]="paymentCompleted"
  [isTransferDigitalWallet]="isTransferDigitalWallet" [transferVaultScreen]="transferVaultScreen"
  [isUpdatePlan]="isUpdatePlan" [subscriptionFlag]="true">
</app-consumer-update-card-details>
<app-message-modal [setMessageSettings]="messageSettings" [setMessageData]="!isAlertShowed?alertData: messageData"
  (buttonEmitter)="buttonListener($event)" [loader]="loader.check('cancel_subscription')">
</app-message-modal>

<app-plan-cancel-reason-modal
  (cancelReasonSubmitted)="cancelReasonModalListener($event)"></app-plan-cancel-reason-modal>
<app-payment-success></app-payment-success>
<app-payment-failure></app-payment-failure>
