<main class="relative">
  <app-consumer-landing-navbar></app-consumer-landing-navbar>
  <!-- BEGIN: Banner Wrapper -->
  <div class="pricing-banner__wrap relative flex items-center justify-center banner-padding-hfull mh-700-height">
    <div class="container mx-auto z-30">
      <div class="flex items-center text-white">
      <div class="grid grid-cols-12 gap-4 xl:gap-12">
        <div class="col-span-12 xl:col-span-7">
          <div class="mt-8 lg:mt-12">
          <h1 class="pricing-banner__head text-semibold text-white fs-bhead text-regular">
            LegacyNOW – Estate Data Management
          </h1>
          <p class="pricing-banner__content fs-content mt-4 mb-12 text-regular">
            LegacyNOW offers multiple set-up and payment options.
          </p>
          <div class="btn-wrap" *ngIf="showGetStarted">
            <button (click)="menuChange('educational-intro')"  class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">
              Get Started
            </button>
          </div>
        </div>
        </div>
      </div>
    </div>
    </div>
  </div>

  <!-- END: Banner Wrapper -->

  <!-- BEGIN: Payment Options -->
  <div class="section">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-center gap-4 md:gap-6 lg:gap-8 xl:gap-12">
        <div class="col-span-12 lg:col-span-6 bg-dark-blue rounded-xl p-6 sm:p-8">
          <h2 class="text-white text-semibold fs-title pb-3 text-bold text-left">
            Payment Options
          </h2>
          <p class="fs-content text-regular text-white text-justify pb-4">
            The Digital Vault is a subscription service which is paid on a monthly basis.
            If you are unsatisfied with our service during your first month, you can cancel and receive a <u>full refund</u>.
          </p>
          <p   *ngIf="allowTransferPayment" class="fs-content text-regular text-white text-justify pb-4">
            We also offer an optional Vault Transfer service. This service, should you elect to pay for it, will allow your
            Digital Vault Custodian to automatically transfer your data to NotifyNOW upon your passing to notify your
            creditors to close your accounts.
          </p>
          <ul class="mt-2 ml-4">
            <li class="li-disc fs-content text-regular text-white text-justify">
              Monthly Subscription Fee: <br>&emsp;{{pricing?.monthly}} per month
            </li>
            <!-- Hide until we start using annual pricing -->
            <li *ngIf="false" class="li-disc fs-content text-regular text-white text-justify">
              Annual Subscription Fee: <br>&emsp;{{pricing?.annual}} per year
            </li>
            <li *ngIf="allowTransferPayment" class="li-disc fs-content text-regular text-white text-justify">
              Vault Transfer Fee: <br>&emsp;{{pricing?.vault_transfer}}
            </li>
          </ul>
        </div>
        <div class="col-span-12 lg:col-span-6 rounded-xl m-3">
          <img src="assets/images/dashboard/pricing.svg" class="mx-auto" alt="digital vault">
        </div>
      </div>
    </div>
  </div>


  <div class="section section-bottom">
    <app-testimonials [mobile]="mobile"></app-testimonials>
  </div>
  <!-- BEGIN: Footer Wrapper -->
  <app-consumer-landing-footer></app-consumer-landing-footer>
</main>

<div class="fixed right-6 bottom-6 group z-50">
  <button type="button" aria-expanded="false" (click)="openModal('assistanceHelp')"
    class="flex items-center justify-center text-white bg-blue-700 rounded-full w-16 h-16 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
    <img src="assets/images/intro-video.png" alt="">
    <span class="sr-only">Open actions menu</span>
  </button>
</div>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>
