<ngx-file-drop class=" col-span-12 h-16" [multiple]="allowMultiple" #ngxFileDrop
  (onFileDrop)="userType==='Consumer' ? canAccessForm && hasDocumentType ?uplaodPropertyImage($event):onFileDrop($event):hasDocumentType?uplaodPropertyImage($event):onFileDrop($event)"
  dropZoneClassName=" border border-grey h-auto rounded flex flex-row items-center justify-center gap-4">
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <div *ngIf="!file" class="w-full flex flex-col justify-center items-center" (click)="openFileSelector()">
      <ng-container>
        <img src=" assets/images/property-details/upload.svg " class="w-12 h-12 py-1 pl-1 w-"
          alt="digital vault">
        <div class="px-1 text-center" *ngIf="!disable && canEdit">
          <h5 class="text-darkblue text-regular font-semibold text-base cursor-pointer "  id="file-selector">
            Select File to Upload
          </h5>
          <p class="text-regular text-grey text-sm">or drop your file here.</p>
        </div>
        <div class="px-1 text-center" *ngIf="disable || !canEdit" title="Please select document type">
          <h5 class="text-darkblue text-regular font-semibold text-base  ">
            Select File to Upload
          </h5>
          <p class="text-regular text-grey text-sm text-center">or drop your file here.</p>
        </div>
      </ng-container>
    </div>

    <!-- once uploaded -->
    <ng-container *ngIf="file" class="lg:w-11/12 ">
      <div class="flex flex-col sm:flex-row gap-2 items-center justify-start w-full ">
        <img src="{{'assets/images/property-details/view-image.svg' | fileTypeIcons:file?.name}}"
          class="pl-2 uploaded-image" alt="LegacyNow" />
        <div class="pl-2 overflow-hidden w-auto">
          <h5
            class="text-darkblue text-regular font-semibold text-base truncate text-center md:text-left w-48 md:w-auto sm:w-auto mr-4">
            {{file?.name}}
          </h5>
          <p class="text-regular text-grey text-sm cursor-pointer flex justify-center md:justify-start"
            *ngIf="!hideFileOperations">
            <span (click)="viewImage()" *ngIf="isImageFile && !disable">View&nbsp;|&nbsp;</span>
            <span *ngIf="!isImageFile && !disable">
              <img src="assets/images/digital-files/download.svg" class="w-5" alt="LegacyNow"
                (click)="downloadFile(this.file)" />
            </span>
            <span *ngIf="!disable && canEdit" class=" text-red cursor-pointer" (click)="removeUploadedFile()">
              Delete
            </span>
          </p>
        </div>
      </div>
    </ng-container>
  </ng-template>
</ngx-file-drop>

<!-- image view popup -->
<app-image-view-popup></app-image-view-popup>

<!-- Downlaod image -->
<app-file-downloader [downloadLink]="downloadLink" [fileName]="downloadFileName"></app-file-downloader>
