import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';

@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
})
/**
 * modal for delete option
 */
export class DeleteConfirmationModalComponent implements OnInit {
  @Output() toggleModalEmitter = new EventEmitter<any>();
  @Output() deleteOption = new EventEmitter<boolean>();
  @Input() submitLoaderDelete: boolean;
  public submitLoader: boolean;
  @Input() userId: string;
  @Input() buttonTitle:string;
  @Input() valid:boolean;
  @Input() loader:boolean;
  @Input() buttonType:string;
  @Input() outlineOnly: boolean;
  @Input() btnTextSize: string;
  @Input() buttonClass: string;
  @Input() content:string


  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.submitLoaderDelete = false;
  }


  /**
   * confirm delete
   */
  public onDelete(): void {
    this.deleteOption.emit(true);
  }


  /**
   * close modal
   */
  public closeModel(): void {
    this.deleteOption.emit(false);
    this.modalService.close('delete-confirmation-modal');
  }
}
