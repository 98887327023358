import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ToastrService} from 'ngx-toastr';
import {environment} from '../../environments/environment';
import {LocalStorageService} from "../services/local-storage.service";
import {USER_TYPES} from "../../constants/application.const";

@Injectable()
/**
 * @class JwtInterceptor
 * @description for intercepting http request to include jwt token
 */
export class JwtInterceptorOrg implements HttpInterceptor {
  /**
   * @constructor
   */
  constructor(
    private localStorageService:LocalStorageService,
    private toastr:ToastrService,
  ) { }

  /**
   * @method intercept
   * @description interceptor to intercept the request
   * @param {HttpRequest} request request object from http
   * @param {HttpHandler} next handler object from http
   * @return {Observable} return api request with jwt token attached to header
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const currentUser = this.localStorageService.getUserData(USER_TYPES.org);
    const cookieUser = this.localStorageService.getLoginCookie(USER_TYPES.org);
    const isLoggedIn = currentUser && currentUser.access_token;
    const isApiUrl = request.url.startsWith(environment.BASE_URL);
    if (isLoggedIn && isApiUrl && cookieUser) {
      const requestDetails = {
        setHeaders: {
          'Authorization': `Bearer ${currentUser.access_token}`,
          'X-Content-Type-Options': `nosniff`,
          'X-XSS-Protection': '0',
        },
        body: {
          ...request.body,
          // request_id: (request?.body?.requestIdOverride)?request?.body?.request_id:currentUser?.request?.id,


        },
      };
      if (request.body instanceof FormData) {
        delete requestDetails.body;
      }
      request = request.clone(requestDetails);
    }
    return next.handle(request);
  }
}

