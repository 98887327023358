import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LocalStorageService} from "../services/local-storage.service";
import {ROUTE_PREFIX, USER_TYPES} from "../../constants/application.const";
import {SlugInterceptorService} from "./slug-interceptor.service";

@Injectable({
  providedIn: 'root',
})
export class ExpiryNotificationTsGuard implements CanActivate {
  public proRoute: string = ROUTE_PREFIX.PRO;

  /**
   * @constructor
   */
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private slugInterceptorService: SlugInterceptorService) {
  }

  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const professional = this.localStorageService.getUserData(USER_TYPES.pro);
    const role = professional.user.role_name;
    if (role == 'Professional' || role == 'Advisor') {
      return true;
    } else {
      void this.slugInterceptorService.navigateByUrl('/unauthorized', null, true);
      return false;
    }
  }
}
