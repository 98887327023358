<div id="overlay">
  <div class="application-loading-container">
    <div class="application-loading-box w-12 h-12 border-b-2 border-red rounded-full animate-spin">
    </div>
  </div>
</div>
<router-outlet></router-outlet>
<app-common-modal id="session_expiry_modal" class="app-common-modal">
  <h2 class="text-darkblue text-4xl text-semibold">
    Warning!
  </h2>
  <p class="font-sans text-lg mt-2">
    You have been inactive for {{AUTO_LOGOUT_DURATION | secondsTransform}}.
  </p>
  <p class="font-sans text-lg mt-2">
    You will be logged out in {{idleWarningTime | secondsTransform}}.
  </p>
</app-common-modal>
<!-- Network connection modal popup -->
<app-common-modal id="network_connection" class="app-common-modal">
  <h2 class="text-xl lg:text-2xl font-sans font-medium highlighted-text truncate mr-5">
    No internet
  </h2>
  <div class="flex flex-row items-center">
    <p class="font-sans text-lg mt-2">
      Looks like there is a problem with the connection. Attempting to reconnect...
    </p>
    <div class="loading-red ml-2"></div>
  </div>
</app-common-modal>
<!-- BEGIN: Initial Payment force modal-->
<app-common-modal id="payment-alert-default-unclosable" class="app-common-modal">
  <div class="relative">
    <div class="w-full sm:p-6">
      <div class="ml-auto mr-auto">
        <img src="assets/images/intro-video.png" alt="payment-need" class="mb-5 w-20 h-20 mr-auto">
        <h3 class="text-darkblue text-semibold text-left text-lg mb-4">Welcome to LegacyNOW! </h3>
        <h2 class="text-gray-700 text-gray-700 font-semibold text-md mb-4">
          We're delighted to have you as part of our LegacyNOW community. To access the full range of services and your
          personal Digital Vault, we kindly ask you to make your initial payment now.
        </h2>
        <h2 class="text-gray-700 text-gray-700 font-semibold text-md mb-4">Click
          <span class="font-semibold text-darkblue">'{{proceedToPay}}'</span>
          and kickstart your journey towards securing your legacy with LegacyNOW.
        </h2>
        <h2 class="text-gray-700 text-gray-700 font-semibold text-md mb-4">
          Thank you for choosing LegacyNOW to protect your digital legacy. Your peace of mind is our priority.
        </h2>
      </div>
    </div>
  </div>
  <div class="flex sm:flex-row items-end justify-end gap-4">
    <app-button-loader [buttonText]="proceedToPay" (click)="processPayment()" [valid]="true" [buttonType]="'button'">
    </app-button-loader>
  </div>
</app-common-modal>
<!-- END: Initial Payment force modal-->
<!-- BEGIN: After Refund trial alert modal-->
<app-common-modal id="payment-alert-freeTrial-completed" class="app-common-modal">
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg" class="absolute right-2 w-8 -mr-6 -mt-4 cursor-pointer" alt=""
      (click)="closeModal()">
    <div class="w-full sm:p-6">
      <div class="ml-auto mr-auto">
        <img src="assets/images/intro-video.png" alt="payment-need" class="mb-5 w-20 h-20 mr-auto">

        <ng-container *ngIf="isTrialPeriodForConsumerFromSlugUser">
          <div>
            <h3 class="text-darkblue text-semibold text-left text-lg mb-4">Alert: Your Account Needs Attention!</h3>
            <p class="text-sm text-grey py-1 tracking-wide text-regular">
              You have {{freeTrailDays}} days left in your trial period, and your account is at risk of being locked.
              Secure
              your access by making your payment today. Simply click '{{proceedToPay}}' to keep your account active.
              Don't let this opportunity slip away. Secure your LegacyNOW membership now!
            </p>
          </div>
          <div *ngIf="!isFirstRefund && !isTrialPeriodForConsumerFromSlugUser">
            <h3 class="text-darkblue text-semibold text-left text-lg mb-4">Attention: Your subscription has been cancelled</h3>
            <p class="text-sm text-grey py-1 tracking-wide text-regular">
              Please note that refunds are not applicable for this cancellation. However, you will retain access to your vault
              until the end of your billing cycle. Any payments made during this period will be carried forward from the end of
              the current billing cycle.
            </p>
          </div>
         
        </ng-container>

        <ng-container *ngIf="!isFirstMonth">
          <h3 class="text-darkblue text-semibold text-left text-lg mb-4">Important Notice: Your Subscription Ends in
            {{remainingSubscriptionDays}} Days </h3>
          <p class="text-sm text-grey py-1 tracking-wide text-regular">
            Please be aware that your login access to LegacyNOW and its features will be discontinued after
            <b>{{subscriptionEndDate}}</b>,
            which marks the end of your current billing cycle. If you wish to continue using our platform,
            please consider renewing your subscription before <b>{{subscriptionEndDate}}</b>.
          </p>
          <p class="text-sm text-grey py-1 tracking-wide text-regular">
            If you need assistance, please reach out to our <b><a (click)="openContactPage();closeModal()">support
                team</a></b>. To renew your
            subscription and avoid any interruption in service, Kindly proceed with payment.
          </p>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="flex sm:flex-row items-end justify-end gap-4">
    <app-button-loader [buttonText]="proceedToPay" (click)="processPayment()" [valid]="true" [buttonType]="'button'">
    </app-button-loader>
  </div>
</app-common-modal>
<!-- END: After Refund trial alert modal-->

<app-payment-success></app-payment-success>
<app-payment-failure></app-payment-failure>

