import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {CommonHelper} from 'src/app/helper/common.helper';
import {APIResponseModel} from 'src/app/interface/response.interface';
import {LocalStorageService} from 'src/app/services/local-storage.service';
import {UserService} from 'src/app/services/user.service';
import {USER_TYPES} from "../../../constants/application.const";
import {SlugInterceptorService} from "../../helper/slug-interceptor.service";

@Component({
  selector: 'app-oauth-callback',
  templateUrl: './oauth-callback.component.html',

})
/**
 * Oauth callback component
 */
export class OauthCallbackComponent implements OnInit {
  /**
   * @constructor
   */
  constructor(
    private slugInterceptorService: SlugInterceptorService,
    private activatedRoute:ActivatedRoute,
    private userService:UserService,
    private localStorageService:LocalStorageService,
    private toastrService:ToastrService,
    private commonHelper:CommonHelper,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.activatedRoute.params.subscribe((paramResponse)=>{
      if (paramResponse?.id) {
        this.userService.oauthLogin({
          code: paramResponse.id,
        }).subscribe((response:APIResponseModel)=>{
          this.localStorageService.storeUserData(USER_TYPES.user, response.data);
          this.toastrService.success(response.message);
          void this.slugInterceptorService.navigate(['/dashboard']);
        }, (exception:any)=>{
          this.commonHelper.httpResponseHandler(exception?.error);
          void this.slugInterceptorService.navigate(['/login']);
        });
      }
    });
  }
}
