<div class="lg:grid grid-cols-12 background-color-grey">

  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 md:gap-8 my-4">
              <div class="col-span-12 sm:col-span-2">
                <!-- <div class="w-16 my-2"> -->
                <img src="assets/images/about-yourself/about-yourself.png" class="w-20" alt="digital vault">
                <!-- </div> -->
              </div>
              <div class="col-span-12 sm:col-span-10">
                <!-- <div class="pl-4 sm:px-4 ml-8"> -->
                <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
                  Tell us about yourself
                </h2>
                <!--p class="text-sm text-grey tracking-wide text-regular">
                  This information is critical and a key to verifying your identity. Please double-check your input
                  prior to moving on.
                </p-->
                <!-- </div> -->
              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-full'"></app-card-progress-bar>
              <!-- END: Progress Bar -->
              <div class="p-6">
                <div class="flex flex-row justify-between mb-4">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    About Yourself
                  </h4>
                  <img src="assets/images/about-yourself/edit.svg" class="w-4 cursor-pointer" alt="digital vault"
                       (click)="click('AboutYourself')">
                </div>
                <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
                  <div class="col-span-12 ">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
                    </h4>
                    <app-shimmer-loading [loading]="dataLoading">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{getName(userData?.user['first_name'], userData?.user['middle_name'], userData?.user['last_name'])}}
                      </p>
                    </app-shimmer-loading>
                  </div>
                  <div class="col-span-12 md:col-span-6 ">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB
                    </h4>
                    <app-shimmer-loading [loading]="dataLoading">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{userData?.['dob'] | date:domainViewDateFormat}}</p>
                    </app-shimmer-loading>
                  </div>
                  <div class="col-span-12 md:col-span-6 ">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular">Place of Birth
                    </h4>
                    <app-shimmer-loading [loading]="dataLoading">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{userData?.['place_of_birth']?.['name'] | titlecase}}</p>
                    </app-shimmer-loading>
                  </div>
                  <div class="col-span-12">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular">
                      Address
                    </h4>
                    <app-shimmer-loading [loading]="dataLoading">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{getFullAddress(userData)}}
                      </p>
                    </app-shimmer-loading>
                  </div>
                  <div class="col-span-12 md:col-span-6" *ngIf="showSSN">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN</h4>
                    <app-shimmer-loading [loading]="dataLoading">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{userData?.['ssn']}}</p>
                    </app-shimmer-loading>
                  </div>

                  <div class="col-span-12 md:col-span-6 ">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
                    </h4>
                    <app-shimmer-loading [loading]="dataLoading">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{userData?.user['country_code']}} {{userData?.user['phone']}}</p>
                    </app-shimmer-loading>
                  </div>
                  <div class="col-span-12 md:col-span-6">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
                    </h4>
                    <app-shimmer-loading [loading]="dataLoading">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{userData?.user['email']}}</p>
                    </app-shimmer-loading>
                  </div>
                </div>
                <div class="my-8">
                  <hr class="mid-line border border-2">
                </div>
                <div class="flex flex-col sm:flex-row gap-2 justify-between my-8">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    Relationship Status
                  </h4>
                  <div>
                    <p class="text-lightblue text-regular text-semibold tracking-wide flex flex-row">
                      <app-shimmer-loading class="flex" [loading]="dataLoading"><span
                        class="text-semibold">{{relationShipMapper[userData?.['marital_status']]}}</span>
                      </app-shimmer-loading>
                      <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-2 sm:ml-4 cursor-pointer"
                           alt="digital vault" (click)="click('Partner')">
                    </p>
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
                  <div class="col-span-12" *ngIf="partnerDetails?.['first_name'] || partnerDetails?.['last_name']">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
                    </h4>
                    <app-shimmer-loading [loading]="dataLoading">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{getName(partnerDetails['first_name'], partnerDetails['middle_name'], partnerDetails['last_name'])}}
                      </p>
                    </app-shimmer-loading>
                  </div>
                  <div class="col-span-12 md:col-span-6 ">
                    <h4 *ngIf="partnerDetails?.['dob']" class="text-label-grey text-sm tracking-wide text-regular">DOB
                    </h4>
                    <app-shimmer-loading [loading]="dataLoading">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{partnerDetails?.['dob'] | date :domainViewDateFormat}}</p>
                    </app-shimmer-loading>
                  </div>
                  <div class="col-span-12 md:col-span-6" *ngIf="showSSN">
                    <h4 *ngIf="partnerDetails?.['ssn']" class="text-label-grey text-sm tracking-wide text-regular">SSN
                    </h4>
                    <app-shimmer-loading [loading]="dataLoading">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{partnerDetails?.['ssn']}}</p>
                    </app-shimmer-loading>
                  </div>
                  <div class="col-span-12 md:col-span-6 " *ngIf="partnerDetails?.phone">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number</h4>
                    <app-shimmer-loading [loading]="dataLoading">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{partnerDetails?.['country_code']}} {{partnerDetails?.phone}}</p>
                    </app-shimmer-loading>
                  </div>
                  <div class="col-span-12 md:col-span-6" *ngIf="partnerDetails?.email">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
                    </h4>
                    <app-shimmer-loading [loading]="dataLoading">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{partnerDetails?.email}}</p>
                    </app-shimmer-loading>
                  </div>
                  <div class="col-span-12" *ngIf="partnerDetails?.address">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular"> Address
                    </h4>
                    <app-shimmer-loading [loading]="dataLoading">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{getFullAddress(partnerDetails)}}</p>
                    </app-shimmer-loading>
                  </div>
                </div>
                <div class="my-8">
                  <hr class="mid-line border border-2">
                </div>
                <div class="flex flex-col sm:flex-row gap-2 justify-between">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    Your Children
                  </h4>
                  <div>
                    <p class="text-lightblue text-regular text-semibold tracking-wide flex flex-row">
                      {{childrenArray.length}} Children
                      <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-2 sm:ml-4 cursor-pointer"
                           alt="digital vault" (click)="click('Children')">
                    </p>
                  </div>
                </div>
                <div *ngIf="dataLoading">
                  <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
                    <div class="col-span-12">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Name</h4>
                      <app-shimmer-loading [loading]="dataLoading"></app-shimmer-loading>
                    </div>
                    <div class="col-span-12 md:col-span-6 ">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB</h4>
                      <app-shimmer-loading [loading]="dataLoading"></app-shimmer-loading>
                    </div>
                    <div class="col-span-12 md:col-span-6" *ngIf="showSSN">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
                      </h4>
                      <app-shimmer-loading [loading]="dataLoading"></app-shimmer-loading>
                    </div>
                    <div class="col-span-12 md:col-span-6 ">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
                      </h4>
                      <app-shimmer-loading [loading]="dataLoading"></app-shimmer-loading>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
                      </h4>
                      <app-shimmer-loading [loading]="dataLoading"></app-shimmer-loading>
                    </div>
                  </div>
                  <div class="my-8">
                    <hr class="mid-line border border-2">
                  </div>
                </div>
                <ng-container *ngIf="!dataLoading">
                  <div *ngFor="let child of childrenArray">
                    <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
                      <div class="col-span-12">
                        <h4 class="text-label-grey text-sm tracking-wide text-regular">Name</h4>
                        <p class="text-darkgrey text-base text-regular tracking-wide">
                          {{getName(child['first_name'], child['middle_name'], child['last_name'])}}
                        </p>
                      </div>
                      <div class="col-span-12">
                        <h4 class="text-label-grey text-sm tracking-wide text-regular">Status</h4>
                        <p class="text-darkgrey text-base text-regular tracking-wide">
                          {{commonHelperFunction.getDisplayTextByValue(child['child_type'])}}</p>
                      </div>
                      <div class="col-span-12 md:col-span-6 " *ngIf="checkValidDob(child?.['dob']) !== ''">
                        <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB</h4>
                        <p class="text-darkgrey text-base text-regular tracking-wide">
                          {{(checkValidDob(child?.['dob']) !== '') ? (checkValidDob(child?.['dob']) | date:domainViewDateFormat)
                          : "" }}</p>
                      </div>
                      <div class="col-span-12 md:col-span-6" *ngIf="showSSN && child?.['ssn']">
                        <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
                        </h4>
                        <p class="text-darkgrey text-base text-regular tracking-wide">
                          {{child?.['ssn']}}</p>
                      </div>
                      <div class="col-span-12 md:col-span-6 " *ngIf="child?.phone">
                        <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone
                          Number</h4>
                        <p class="text-darkgrey text-base text-regular tracking-wide">
                          {{child?.['country_code']}} {{child?.phone}}</p>
                      </div>
                      <div class="col-span-12 md:col-span-6" *ngIf="child?.email">
                        <h4 class="text-label-grey text-sm tracking-wide text-regular">Email
                          Address</h4>
                        <p class="text-darkgrey text-base text-regular tracking-wide">
                          {{child?.email}}</p>
                      </div>
                      <div class="col-span-12">
                        <h4 class="text-label-grey text-sm tracking-wide text-regular">
                          Address
                        </h4>
                        <p class="text-darkgrey text-base text-regular tracking-wide">
                          {{getFullAddress(child)}}
                        </p>
                      </div>
                    </div>
                    <div class="my-8">
                      <hr class="mid-line border border-2">
                    </div>
                  </div>
                </ng-container>
                <div class="flex flex-col-reverse sm:flex sm:flex-row gap-4 justify-between items-center pt-8">
                  <button (click)="click('Children')"
                          class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </button>
                  <div class="flex flex-col-reverse sm:flex sm:flex-row gap-4 items-center justify-end w-full">
                    <button *ngIf="this.userType !== 'Custodian'" (click)="click('ReturnToDashboard')"
                            class="flex items-center justify-center border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 focus:outline-none text-regular text-white px-6">
                      Return to Dashboard
                    </button>
                    <button (click)="click('Next')"
                            class="cursor-pointer text-center button button--lg w-full sm:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-6 text-sm">
                      Next Section
                    </button>
                  </div>
                </div>
              </div>
              <!-- END: Leftside Card -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class="col-span-12 md:col-span-6">
    <div class="pb-12 md:p-12">
      <div class="flex justify-end px-8 sm:px-12 sm:mx-2 lg:mx-0 lg:pr-16" *ngIf="false">
        <app-button-loader [buttonText]="'Save & Return to Dashboard'" [valid]="true" [loader]="submitLoader"
                           [buttonType]="'button'" (click)="saveAndExit()" [outlineOnly]="true"></app-button-loader>
      </div>
      <div class="h-full pt-12 hidden lg:flex flex justify-center items-center">
        <img src="assets/images/about-yourself/right-img.svg" class="w-6/12 md:w-full lg:w-9/12" alt="digital vault">
      </div>
    </div>
  </div>
  <!-- END: Rightside Content -->
</div>
