<div class="scroll-hidden">
  <div class="h-screen lg:grid grid-cols-12">
    <!-- BEGIN: Login Form -->
    <div class="col-span-12 md:col-span-6 xl:col-span-5 h-screen lg:h-auto p-6 flex">
      <div class="flex flex-col w-full">
        <!-- BEGIN: Logo -->
        <div class="md:pl-2 flex items-center gap-2 sm:gap-4 h-16 flex-row">
          <a (click)="onClickLogo()" class="flex items-center cursor-pointer">
            <img alt="The-Estate-Registry" width="250" [src]="'assets/images/logo-old.png'" class="object-contain w-40 sm:w-48">
          </a>
          <a *ngIf="orgLogoPath"  class=" border-l-black pl-6 cursor-pointer max-h-12 maxw128">
            <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
          </a>
          <!-- <div class="flex items-center gap-1 md:gap-2 flex-col" *ngIf="orgLogoPath">
            <p class="whitespace-nowrap text-xs md:text-sm lg:text-regular text-dark-grey">Sponsored by:</p>

            <a *ngIf="orgLogoPath" class="cursor-pointer max-h-12 maxw128">
              <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">

            </a>
          </div> -->
        </div>
        <!-- END: Logo -->
        <div class="h-full flex justify-center">
          <div class="mx-auto bg-white pt-12 sm:pl-10 xl:bg-transparent px-5 sm:px-8 w-full">
            <div class="h-full justify-center items-center ">
              <h2 class="font-bold text-darkblue text-lg text-regular text-left">
                LegacyNOW Digital Vault
              </h2>

              <hr class="border-blue w-20 mt-1 border-1">
              <div class="mt-4">
                <p class="text-regular text-grey text-base">Login to your estate organizer
                  account with
                </p>
                <h2 class="text-darkblue text-4xl text-semibold pt-1">LegacyNOW <br>
                  Digital Vault
                </h2>
              </div>

              <div class="mt-16">
                <p class="text-grey text-2xl text-regular">A verification link has been sent to your email. Please
                  verify to proceed with the registration process.</p>
              </div>
              <div class="mt-4 flex justify-between items-center">
                <p class="text-regular text-base text-label-grey">If you didn't receive the email</p>
                <button type="button"
                  class="relative button button--lg md:w-auto w-full bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-6 flex items-center"
                  [ngClass]="(resendOtpTimer)?'opacity-50':''" [disabled]="resendOtpTimer" (click)="resendEmail()">
                  <div class="loading-red mr-2" *ngIf="resendOtpTimer"></div>
                  <div>Resend Email<span *ngIf="resendOtpTimer">&nbsp;in {{resendOtpTimer}}s</span></div>
                </button>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- END: Login Form -->

    <!-- BEGIN: Login Info -->
    <div class="md:col-span-6 xl:col-span-7 hidden lg:flex bg-login-grey min-h-screen justify-center">
      <div class="fixed h-full flex flex-col items-center justify-center">
        <!-- BEGIN: First Slide -->
        <app-right-carousel-intro></app-right-carousel-intro>
      </div>
    </div>
    <!-- END: Login Info -->
  </div>
</div>
