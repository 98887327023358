<app-common-modal id="get-advisor-modal" class="app-common-modal" [heightClass]="heightClass" (commonModalEmitter)="modalEventListener($event)">
  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModal()">
    <form [formGroup]="getAdvisorForm" autocomplete="off" (ngSubmit)="getAdvisorData()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Add a {{agentTerm}}
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">
            If your {{agentTerm}} has an account with us, you may enter their email below and press the
            <b>Get Provider Details</b> button to automatically import their information. <br> If your provider does not
            have an account with us, use the <b>Add a New Provider</b> button to fill out their information.
          </p>
        </div>
        <div class="col-span-12">

          <div class="col-span-12">
            <label for="id" class="text-label-grey text-sm text-regular">Professional Service Provider's Email</label>
            <div class="relative flex items-center">
              <input type="text" id="id"
                class="h-10 sm:h-12 input py-3 mt-2 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none  border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 price-input"
                name="id" formControlName="email" placeholder="e.g example@gmail.com"
                [ngClass]="formGet.email.errors && formGet.email.touched?'form-input-error':'form-input-focus'">
            </div>
            <div *ngIf="formGet.email.errors && formGet.email.touched">
              <p class="text-regular font-medium text-xs mt-2 error-alert"
                *ngIf="formGet.email.errors.required && formGet.email.touched">
                Email is required
              </p>
            </div>
          </div>

        </div>
      </div>
      <div class="flex float-right mt-4 gap-4">
        <app-button-loader [buttonText]="'Add a New Provider'" [valid]="canAccessForm" (click)="addNewAdvisor()"
          [buttonType]="'button'" [outlineOnly]="true"></app-button-loader>
        <app-button-loader [buttonText]="'Get Provider Details'" [valid]="getAdvisorForm.valid" [loader]="submitLoader"
          [buttonType]="'submit'" [buttonTooltip]="!getAdvisorForm.valid ? 'Please enter an email.' : ''"></app-button-loader>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>
<app-define-advisor-modal [setAdvisorEmail]="advisorEmail"></app-define-advisor-modal>
