<app-common-modal id="add-children-modal" class="app-common-modal">

  <div class="w-full relative rounded-md">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
         (click)="closeModel()">
    <div class="mb-2 pl-2">
      <div class="flex flex-row items-baseline">
        <div>
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Child’s Details
          </h4>
          <p class="mt-2 text-grey text-regular pt-1 text-sm">
            By including the information below you are certifying to us that you are authorized to do so, and you
            acknowledge that the Children's Online Privacy Protection Act requires verifiable parental consent to
            collect or use information from a child under 13. If your child is under 13 you must be, and certify to us
            that you are, a parent or legal guardian of the child referred to below and have consented to the provision
            of such information. You further represent and warrant that you will not allow a child under the age of 13
            to use our services without your verifiable consent or if you do not agree to any of the terms and
            conditions relating to the usage of our Digital Vault and related services.
          </p>
        </div>
      </div>
    </div>


    <form [formGroup]="childrenForm" (ngSubmit)="onSubmit()" autocomplete="off" class="p-2">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 ">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Personal Information
          </h4>
          <p *ngIf="isDisableForm" class="text-red-500">Note: Fields are non-editable after their registration. </p>
        </div>
        <div class="col-span-12 lg:col-span-4">
          <label for="first-name" class="text-label-grey text-sm text-regular">First
            Name*</label>
          <input type="text" id="first-name" [attr.disabled]="isDisableForm || undefined"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 placeholder="First Name" name="first-name" formControlName="first_name"
                 [ngClass]="formGet.first_name.errors && formGet.first_name.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('first_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 lg:col-span-4">
          <label for="middle_name" class="text-label-grey text-sm text-regular">
            Middle Name
          </label>
          <input type="text" [attr.disabled]="isDisableForm || undefined"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 id="middle_name" formControlName="middle_name" placeholder="Middle Name"
                 [ngClass]="formGet.middle_name.errors && formGet.middle_name.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('middle_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 lg:col-span-4">
          <label for="last-name" class="text-label-grey text-sm text-regular">Last
            Name*</label>
          <input type="text" id="last-name" [attr.disabled]="isDisableForm || undefined"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 placeholder="Last Name" name="last-name" formControlName="last_name"
                 [ngClass]="formGet.last_name.errors && formGet.last_name.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('last_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="state-Province" class="text-label-grey text-sm text-regular">Status*</label>
          <app-select-component id="child_type" [options]="childStatusOptions" [placeholder]="'Select child type'"
                                [selectedOptionDefault]="childrenForm?.value?.['child_type']"
                                (optionChangeEmitter)="optionChangeEmitter($event)" [disabled]="isDisableForm"
                                [ifError]="formGet.child_type.errors && formGet.child_type.touched"
                                (toggleEmiter)="toggleEmiter($event)"></app-select-component>
          <p *ngFor="let error of getErrors('child_type')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 sm:col-span-8 ">

          <div class="mt-2">
            <h5 class=" text-regular text-sm text-darkblue font-semibold tracking-wide">
              Is the address the same as your address?
            </h5>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-4">
          <app-toggle-switch [defaultSelected]="addressStatus" [options]="toggleOptions" [disabled]="submitLoader || isDisableForm"
                             (optionChangeEmitter)="onChangeToggle($event)">
          </app-toggle-switch>
        </div>
        <div class="mt-1 col-span-12" *ngIf="!addressStatus">
          <div class="mb-4">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Address
            </h4>
            <p class="text-grey text-regular pt-1 text-sm">Make sure to enter your address including your postcode.
            </p>
          </div>
          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
            <div class="col-span-12">
              <label for="country" class="text-label-grey text-sm text-regular">Country*</label>
              <app-country-search-component id="country" (optionChangeEmitter)="optionChangeListenerCountry($event)"
                [defaultSelected]="formGet?.country?.value" [enableError]="formGet.country.errors && formGet.country.touched" [ngStyle]="{ 'opacity': isDisableForm ? '0.5' : '1', 'pointer-events': isDisableForm ? 'none' : 'auto' }"></app-country-search-component>
              <p *ngFor="let error of getErrors('country')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-8 relative move-map" #moveMapHere>
              <label for="street-address" class="text-label-grey text-sm text-regular">
                Street Address*
              </label>
              <input type="text" id="street-address" #search (keyup)="onAddressChange(search)"
                     (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null" [attr.disabled]="isDisableForm || undefined"
                     class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                     placeholder="e.g Main St" name="address" formControlName="address" (input)="onFocusAddress()"
                     [ngClass]="formGet.address.errors && formGet.address.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('address')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-4">
              <label for="apt_number" class="text-label-grey text-sm text-regular">Apartment / Suite #</label>
              <input name="apt_number" id="apt_number" formControlName="apt_number" [attr.disabled]="isDisableForm || undefined"
                     class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                     [ngClass]="formGet.apt_number.errors && formGet.apt_number.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('apt_number')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12" *ngIf="selectedCountryName!==unitedKingdom">
              <label for="state-Province" class="text-label-grey text-sm text-regular">State/Province*</label>
              <app-state-search-component id="state-province" [countryCode]="formGet?.country?.value"
                                          [enableError]="formGet.state.errors && formGet.state.touched"
                                          [defaultSelected]="formGet?.state?.value" [disabled]="isDisableForm"
                                          (optionChangeEmitter)="optionChangeListenerState($event)" tabindex="0"
                                          (focusout)="markAsTouchedIndividual('state')"></app-state-search-component>
              <p *ngFor="let error of getErrors('state')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 md:col-span-6 relative">
              <label for="city" class=" text-label-grey text-sm text-regular ">City*</label>
              <app-city-search [countryName]="selectedCountryName" id="city" [stateCode]="formGet?.state?.value"
                               [enableError]="formGet.city.errors && formGet.city.touched"
                               [defaultSelected]="formGet?.city?.value" [disabled]="isDisableForm"
                               (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0"
                               (focusout)="markAsTouchedIndividual('city')"></app-city-search>
              <p *ngFor="let error of getErrors('city')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class=" col-span-12 md:col-span-6 ">
              <label for="zipcode" class="text-label-grey text-sm text-regular">Zip/Postal
                Code*</label>
              <input type="text" (focusout)="markAsTouchedIndividual('zipcode')"
                     class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
                     placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                     name="zipcode" id="zipcode" formControlName="zipcode"
                     oninput="this.value = this.value.toUpperCase()"
                     (focus)="validateZipCode()" [attr.disabled]="isDisableForm || undefined"
                     [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('zipcode')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
          </div>
        </div>


        <div class="col-span-12">
          <label [for]="'dob'" class="text-label-grey text-sm text-regular">
            Date of Birth ( {{domainDateFormat}} )
          </label>
          <app-datepicker-toggle
            inputID="dob"
            class="mt-1"
            [bsConfig]="bsConfig"
            [ngStyle]="{ 'opacity': isDisableForm ? '0.5' : '1', 'pointer-events': isDisableForm ? 'none' : 'auto' }"
            [useFormControl]="formGet.dob">
          </app-datepicker-toggle>
          <p *ngFor="let error of getErrors('dob')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 lg:col-span-6" *ngIf="showSSN">
          <label for="ssn" class="text-label-grey text-sm text-regular">SSN
            (Social Security Number)</label>
          <div class="relative text-gray-700">
            <a class="absolute inset-y-0 right-2 pr-3 flex items-center"
               (click)="toggleSSNView.ssn = !toggleSSNView.ssn">
              <fa-icon [icon]="faEye" *ngIf="toggleSSNView.ssn"></fa-icon>
              <fa-icon [icon]="faEyeSlash" *ngIf="!toggleSSNView.ssn"></fa-icon>
            </a>
            <input type="text" [type]="toggleSSNView.ssn?'text':'password'"
                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                   placeholder="e.g. 255-34-5345" name="ssn" formControlName="ssn" id="ssn"
                   [ngClass]="formGet.ssn.errors && formGet.ssn.touched?'form-input-error':'form-input-focus'"
                   maxlength="11" [attr.disabled]="isDisableForm || undefined"
                   (focusout)="onFocusout($event,'ssn')" [mask]="SSN_MASK" [dropSpecialCharacters]="false">
          </div>
          <p *ngFor="let error of getErrors('ssn')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 lg:col-span-6" *ngIf="showSSN">
          <label for="ssn_validation" class="text-label-grey text-sm text-regular">
            Confirm SSN
          </label>
          <div class="relative h-12 text-gray-700">

            <div class="relative">
              <a class="absolute inset-y-0 right-2 pr-3 flex items-center"
                 (click)="toggleSSNView.v_ssn = !toggleSSNView.v_ssn">
                <fa-icon [icon]="faEye" *ngIf="toggleSSNView.v_ssn"></fa-icon>
                <fa-icon [icon]="faEyeSlash" *ngIf="!toggleSSNView.v_ssn"></fa-icon>
              </a>
              <input type="text" [type]="toggleSSNView.v_ssn ? 'text' : 'password'"
                     class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                     name="ssn_validation" id="ssn_validation" formControlName="ssn_validation"
                     placeholder="e.g. 255-34-5345" [attr.disabled]="isDisableForm || undefined"
                     [ngClass]="formGet.ssn_validation.errors && formGet.ssn_validation.touched?'form-input-error':'form-input-focus'"
                     [mask]="SSN_MASK" [dropSpecialCharacters]="false" maxlength="11"
                     (input)="onChange($event,'ssn_validation')" (focusout)="onFocusout($event,'ssn_validation')">
            </div>

          </div>
          <div *ngIf="!checkSSNMatch() && ((formGet.ssn.value && formGet.ssn.valid) && !formGet.ssn_validation.value)">
            <p class="text-regular font-medium text-xs mt-2 error-alert">
              Confirm SSN is required
            </p>
          </div>
          <div *ngIf="!checkSSNMatch() && ((formGet.ssn.value && formGet.ssn.valid) && formGet.ssn_validation.value)">
            <p class="text-regular font-medium text-xs mt-2 error-alert">
              SSN numbers must match.
            </p>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6" *ngIf="showSSN && formGet.ssn.value">
          <div *ngIf="checkSSNMatch() && validatingSSN == 1" class="flex">
            <p class="text-regular font-medium text-xs checking-alert">
              Validating SSN
            </p>
            <div class="loading-red mr-2"></div>
          </div>
          <p class="text-regular font-medium text-xs error-alert"
             *ngIf="checkSSNMatch() && validatingSSN == 2 && !isUniqueSSN">
            SSN has already been entered.
          </p>
        </div>
      </div>


      <div>
        <div class="mt-4">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Contact Information
          </h4>
        </div>
        <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
          <div class="col-span-12">
            <label for="mail-id" class="text-label-grey text-sm text-regular">
              Email Address</label>
            <input type="email" id="mail-id"
                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                   placeholder="e.g. marktylor@gmail.com" [attr.disabled]="isDisableForm || undefined"
                   [ngClass]="formGet.email.errors && formGet.email.touched?'form-input-error':'form-input-focus'"
                   formControlName="email" name="email">
            <p *ngFor="let error of getErrors('email')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <div class="col-span-12">
            <label class="text-label-grey text-sm text-regular">
              Phone Number</label>
            <app-phone-input [placeholder]="'e.g. 8665044754'" [phoneNumber]="childrenForm.value.phone"
                             [countryCode]="countryCode" [disabled]="isDisableForm"
                             (onChange)="detectChangePhonenumber($event)"
                             [errorClassTrigger]="formGet.phone.errors && formGet.phone.touched"
                             [uniqueId]="'phone-children'" [form]="this.childrenForm"
                             (focusout)="formGet.phone.markAsTouched()">
            </app-phone-input>
            <p *ngFor="let error of getErrors('phone')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </div>
      </div>

      <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
        <app-button-loader class="w-full sm:w-auto" *ngIf="childrenForm.value.id" [buttonText]="'Remove Child'"
                           [valid]="!submitLoader" [loader]="submitLoaderDelete" [buttonType]="'button'" [outlineOnly]="true"
                           (click)="deleteChildren()"></app-button-loader>
        <app-button-loader class="w-full sm:w-auto" [buttonText]="(childrenForm.value.id)?'Update Child':'Add a child'"
                           [valid]="checkSSNMatch() && checkSSNValidation && !submitLoader &&!submitLoaderDelete" [loader]="submitLoader"
                           [buttonType]="'submit'"></app-button-loader>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
  <!-- END: Leftside Content -->
</app-common-modal>
