import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

/**
 * Organization Service
 */
export class OrganizationService {
  private BASE_URL: string;

  /**
 * constructor
 */
  constructor(
    private http: HttpClient,
  ) {
    this.BASE_URL = environment.BASE_URL;
  }

  /**
   * Login Organization
   *
   * @param {LOGIN_USER_INTERFACE} orgData
   * @return {Observable<APIResponseModel>}
   */
  public orgLogin(orgData: any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/organization/login', orgData);
  }

  /**
   * Organization logout
   *
   * @return {Observable<APIResponseModel>}
   */
  public orgLogout():Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/organization/logout', {});
  }

  /**
   * Organization reset password request
   * @param{{}}data
   * @return {Observable<APIResponseModel>}
   */
  public orgPasswordResetRequest(data:{email:string}):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/organization/password/reset-request', data);
  }

  /**
   * Organization reset password request
   * @param{{}}data
   * @return {Observable<APIResponseModel>}
   */
  public orgPasswordReset(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/organization/password/reset', data);
  }

  /**
   * Organization change password
   * @param{{}}data
   * @return {Observable<APIResponseModel>}
   */
  public orgChangePassword(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/organization/change-password', data);
  }

  /**
   * Organization change email
   * @param{{}}data
   * @return {Observable<APIResponseModel>}
   */
  public orgChangeEmail(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/organization/change-email', data);
  }

  /**
   * update profile
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public updateProfile(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/organization/update-profile', data);
  }
  /**
   * get current user details
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public getCurrentUser():Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/organization/profile');
  }
  /**
   * change email
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public changeEmail(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/organization/change-email', data);
  }
  /**
   * change profile picture
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public changeProfilePicture(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/organization/change-profile-picture', data);
  }

  /**
   * Dashboards statistics
   * @returns statistics
   */
  public dashboardStatistics(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/organization/dashboard-statistics`)
  }

    /**
  * change email
  * @param{{}}data
  * @return {Observable<APIResponseModel>}
  */
    public changeEmailVerification(data: any): Observable<APIResponseModel> {
      return this.http.post<APIResponseModel>(this.BASE_URL + '/organization/change-email-verification', data);
    }

    /**
     * Verify Otp
     *
     * @param {any} data
     * @return {Observable<APIResponseModel>}
     */
    public verifyOtp(data: any): Observable<APIResponseModel> {
      return this.http.post<APIResponseModel>(this.BASE_URL + `/organization/update-email`, data);
    }

  //  /**
  //  * get user details
  //  * @param {any} data
  //  * @return {Observable<API_RESPONSE_INTERFACE>}
  //  */
  //  public getUserDetail(data:any):Observable<API_RESPONSE_INTERFACE> {
  //   return this.http.get<API_RESPONSE_INTERFACE>(this.BASE_URL + `/organization/${data}`);
  // }
}
