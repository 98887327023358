import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ScheduleService } from 'src/app/services/schedule.service';

@Component({
  selector: 'app-schedule-accept-page',
  templateUrl: './schedule-accept-page.component.html',
  styleUrls: ['./schedule-accept-page.component.css']
})
export class ScheduleAcceptPageComponent implements OnInit {
  public scheduleId: string;
  public message: string = 'Please wait...';
  public greeting: string = '';
  public incomeStatus: number;
  public isSuccessMsg: boolean;
  public isWrongMsg: boolean;
  constructor(
    private route: ActivatedRoute, 
    private scheduleService: ScheduleService,
    private slugInterceptorService: SlugInterceptorService

    ) { }

 /**
 * loaded initially
 */
 ngOnInit(): void {
  this.incomeStatus = null;
  this.isSuccessMsg = false;
  this.isWrongMsg = false;
  this.route.params.subscribe((paramResponse)=>{
    if (paramResponse?.id) {
      this.scheduleService.getScheduleDetailsById(paramResponse?.id).subscribe((response)=>{
        this.incomeStatus = response?.data?.status;
        if(this.incomeStatus == 0){ 
          this.scheduleService.updateScheduleStatus({id:paramResponse?.id, status:1}).subscribe((data)=>{
            this.isSuccessMsg = true;
            this.greeting = 'Thank You !';
            this.message = 'Your acceptance submission has been sent.';
          }, (ex)=>{
            this.callSomethingWrong();
          })
          }else if(this.incomeStatus == 1){
            this.isWrongMsg = true;
            this.greeting = 'Sorry !';
            this.message = 'Your submission has been already sent.';
          }else{
            this.callSomethingWrong();
          }
        },(ex)=>{
            this.callSomethingWrong();
        })
    }
  });
}
/**
 * call if exception happened
 */
 callSomethingWrong () : void {
  this.isWrongMsg = true;
  this.greeting = 'Sorry !';
  this.message = 'Something went wrong.';
}
}


