// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// Switch for backend environment. Just change index to match your use in environment.
const devEnvSwitch = [
  { // 0
    BASE_URL: 'http://localhost:8000/api/v1',
    BASE_URL_TWO: 'http://localhost:8000/api/v2',
    BASE_URL_RAW: 'http://localhost:8000/',
  },
  { // 1
    BASE_URL: 'https://dev-gsfdjasfhvk.legacynow.com/api/v1',
    BASE_URL_TWO: 'https://dev-gsfdjasfhvk.legacynow.com/api/v2',
    BASE_URL_RAW: 'https://dev-gsfdjasfhvk.legacynow.com/',
  },
  { // 2
    BASE_URL: 'http://159.89.174.246/digital-lock-box/public/api/v1',
    BASE_URL_TWO: 'http://159.89.174.246/digital-lock-box/public/api/v2',
    BASE_URL_RAW: 'http://159.89.174.246/digital-lock-box/public/',
  },
  { // 3
    BASE_URL: 'https://digital-lock-box.seaswap.co/api/v1',
    BASE_URL_TWO: 'https://digital-lock-box.seaswap.co/api/v2',
    BASE_URL_RAW: 'https://digital-lock-box.seaswap.co/',
  },
  { // 4
    BASE_URL: 'https://staging-gsfdjasfhvk.legacynow.com/api/v1',
    BASE_URL_TWO: 'https://staging-gsfdjasfhvk.legacynow.com/api/v2',
    BASE_URL_RAW: 'https://staging-gsfdjasfhvk.legacynow.com/',
  }
];

export const environment = {
  production: false,
  ...devEnvSwitch[1],
  GOOGLE_CAPTCHA_KEY: '6LdBZIsaAAAAACo6G8fm8LMocmMaWv9sfaRjkf5C',
  LOCAL_STORAGE_SECRET: 'D96B2B84E3433063E8355912A845488A',
  GOOGLE_PLACES_API_KEY: 'AIzaSyCDsGOXr2xx8JN_Zq0bXyt65vKXVVy_T0I',
  stripe: {
    publicKey: 'pk_test_51PE7yjIEjhk8RiqLKeftYmosB3tq7jlICYPZINBR53JDrhNO2Wwvdotmw3WCaPtRO6d986bKvjyn297NWoanBKF100A5uxDRcZ',
  },
  // PLAID_KEY: 'link-sandbox-7cb3a23e-a514-4f6b-a24d-09e653885804',
  // PLAID_KEY: 'link-sandbox-6c190607-6c9f-4e2b-a5a8-0d3c60efd64c',
  PLAID_ENV: 'sandbox',
  ENCRYPT_LOCAL_STORAGE: false,
  GOOGLE_CAPTCHA_OPTION: false,
  // For Canada - 1, For US - 1, for Australia 61,for UK-241
  APP_DOMAIN: 1,
  DEFAULT_COUNTRY_DIALCODE: '1',
  DEFAULT_COUNTRY_CODE: {
    code: 'US',
    dialCode: '1',
  },
  // chat server url
  CHAT_BASE_URL: 'https://tdr.dconn.io:4009',
  FRONT_END_URL: 'http://localhost:4200',
  PRO_ROUTE: 'pro',
  ORG_ROUTE: 'org',
  EXE_ROUTE: 'executor-custodian',
  CURRENCY_CODE: 'USD',
  DATE_FORMAT: 'MM/DD/YYYY',
  TABLE_VIEW_DATE_FORMAT: 'MM/dd/y',
  VIEW_DATE_FORMAT: 'MMM d y',
  AUTO_LOGOUT_DURATION: 3600, // In seconds; how long for inactive modal to appear
  AUTO_LOGOUT_TIMEOUT: 60, // In seconds; how long for user to act after inactive modal appears before being logged out.

  // SOCKET_URL: 'https://digital-lock-box.seaswap.co:8031',
  SOCKET_URL: 'https://dev-gsfdjasfhvk.legacynow.com:8030',
  DECEDENT_URL: 'https://dev.decedentcheck.com.au/login',
  OPENAI_URL: 'https://chatbot.seaswap.co',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
