<div class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-8 mx-auto">
        <h1 class="mt-2 text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl dark:text-white">Client
            Management </h1>

        <p class="max-w-2xl text-grey mx-auto  mt-2 text-center dark">
            Onboard and Manage Clients
        </p>

        <p *ngIf="!cardsAdded && isPayVaultRegistration"
            class="max-w-2xl text-red mx-auto mt-2 text-lg text-center xl:mt-4 dark">
            Please <span (click)="navigateToManageCards()" class="underline cursor-pointer">add your card</span> details
            to onboard your clients.
        </p>

        <div class="grid grid-cols-1 gap-8 mt-6 xl:mt-12 xl:gap-12 md:grid-cols-2">

            <div class="bg-card rounded-lg shadow-md overflow-hidden">
                <div class="space-y-2.5 bg-red-500 text-white flex flex-col items-center py-7 px-4 lg:p-7"
                    style="background-color: #FCE1E6;">
                    <div class="bg-white p-4 rounded-md">
                        <svg class="w-9 h-9" width="34px" height="34px" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#c02121" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-users">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                <circle cx="9" cy="7" r="4"></circle>
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                            </g>
                        </svg>
                    </div>
                    <h4 class="text-charcoal text-lg lg:text-xl font-semibold text-center mt-3">Onboard New Client</h4>
                </div>
                <div class="py-6 px-4 space-y-4 flex flex-col justify-center items-center">
                    <p class="text-muted-foreground pb-1 text-center">
                        Onboard new clients individually or in bulk by entering their name and email address.
                    </p>
                    <div class="flex gap-4 mb-1 items-end">
                        <app-button-loader class="w-full sm:w-auto custom-btn" [buttonText]="'Add New Client'"
                            [valid]="cardsAdded || !isPayVaultRegistration"
                            (clickButton)="changeRoute('clients/add-client')" buttonType="'button'">
                        </app-button-loader>
                        <app-button-loader class="w-full sm:w-auto custom-btn" [buttonText]="'Bulk Add Clients'"
                            [valid]="cardsAdded || !isPayVaultRegistration" (clickButton)="toggleBulkImportClient(true)"
                            buttonType="'button'">
                        </app-button-loader>
                    </div>
                </div>
            </div>

            <div class="bg-card rounded-lg shadow-md overflow-hidden">
                <div class="space-y-2.5 bg-red-500 text-white flex flex-col items-center py-7 px-4 lg:p-7"
                    style="background-color: #FFF7BB;">
                    <div class="bg-white p-4 rounded-md">
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none"
                            stroke="#E1C015" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="w-9 h-9">
                            <rect width="20" height="8" x="2" y="2" rx="2" ry="2"></rect>
                            <rect width="20" height="8" x="2" y="14" rx="2" ry="2"></rect>
                            <line x1="6" x2="6.01" y1="6" y2="6"></line>
                            <line x1="6" x2="6.01" y1="18" y2="18"></line>
                        </svg>
                    </div>
                    <h4 class="text-charcoal text-lg lg:text-xl font-semibold text-center mt-3">Onboard Existing
                        LegacyNOW
                        Clients</h4>
                </div>
                <div class="py-6 px-4 space-y-4 flex flex-col justify-center items-center">
                    <p class="text-muted-foreground pb-1 text-center">
                        Add or import existing LegacyNOW clients into your company using their unique Digital Vault IDs.
                    </p>
                    <div class="flex gap-4 mb-1">
                        <app-button-loader class="w-full sm:w-auto custom-btn" [buttonText]="'Add LegacyNOW Client'"
                            [valid]="cardsAdded || !isPayVaultRegistration" (clickButton)="toggleImportClient()"
                            buttonType="'button'">
                        </app-button-loader>
                        <app-button-loader class="w-full sm:w-auto custom-btn" [buttonText]="'Import LegacyNOW clients'"
                            [valid]="cardsAdded || !isPayVaultRegistration"
                            (clickButton)="toggleBulkImportClient(false)" buttonType="'button'">
                        </app-button-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Bulk Client Import Modal -->
<app-client-bulk-import [importTerm]="isNewClient?'employee':'client'"
    [userRole]="professionalType"></app-client-bulk-import>

<!-- Client Import Modal -->
<app-import-professional-client (toggleModalEmitter)=" modalEventListner($event)" [userRole]="professionalType"
    [agentID]="proID">
</app-import-professional-client>

<!-- Generate Url Link Modal -->