import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModelService } from '../../services/common-model.service';
import {
  LPEmitterInterface,
  LPSettingsInterface,
  LPUserPeopleInterface,
} from '../list-people/list-people-interfaces';
import { CPCEmitInterface, CPCSettingsInterface } from './choose-people-interfaces.';

@Component({
  selector: 'app-choose-people',
  templateUrl: './choose-people.component.html',
})

/**
 * choose people modal */
export class ChoosePeopleComponent implements OnInit {
  @Input() modal_id: string = 'choose-people-modal';

  @Input() set cpcSettings(data: CPCSettingsInterface) {
    this.settings = data;
    this.lpSettings.checkbox = {
      show: true,
      text: this.settings.text.checkbox.text,
      tooltip: this.settings.text.checkbox.tooltip,
    };
  }

  @Input() userPeopleData: Array<LPUserPeopleInterface>;
  @Output() updateEmitter = new EventEmitter<CPCEmitInterface>();

  // Listener for whether to toggle loading graphics
  @Input() set loadListener(isLoading: boolean) {
    this.lpSettings.isLoading = isLoading ?? true;
  }

  public settings: CPCSettingsInterface;
  // ListPerson settings
  public lpSettings: LPSettingsInterface = {};

  constructor(private modalService: CommonModelService) {
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.lpSettings = {
      checkbox: {
        show: true,
        text: this.settings?.text.checkbox.text,
        tooltip: this.settings?.text.checkbox.tooltip,
      },
      isLoading: true,
    };
  }

 /**
 * Closes the modal and resets checkbox states.
 * After closing the modal and opening it again, ensures that checkboxes are unchecked.
 */
  public closeModel(): void {
    this.userPeopleData.filter((e) => e.checkbox.isChecked)
                   .forEach((e) => e.checkbox.isChecked = false);
    this.modalService.close(this.modal_id);
  }

  /**
   * 'Choose As' button handler
   */
  public clickChooseAs(): void {
    this.updateEmitter.emit({
      chosen_people: this.userPeopleData.filter((e) => e.checkbox.isChecked),
    });
    this.closeModel();
  }

  /**
   * Listener for {@link ListPeopleComponent} click events.
   * @param event
   */
  public clickListener(event: LPEmitterInterface) {
    if (event.clickedName) {
      this.updateEmitter.emit({
        person_clicked_id: event.id,
      });
    }
  }

  /**
   * Sends emit event that the user clicked add-new button.
   */
  public clickAddNew() {
    this.updateEmitter.emit({ add_new: true });
  }

  /**
   * 'Choose As' button is invalid if nobody has been chosen to set.
   */
  get isValidSet(): boolean {
    return !!this.userPeopleData?.find((e) => e.checkbox.isChecked);
  }
}
