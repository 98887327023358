<div class="grid 2xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-2 gap-4 y-4 mx-2 mt-2" *ngIf="loadingClientDetails">
  <p *ngFor="let _ of [].constructor(8)">
    <app-shimmer-loading [loading]='true' [width]="'100%'" [height]="'36px'"></app-shimmer-loading>
  </p>
</div>
<div class="grid 2xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-2 gap-4 y-4 mx-2 mt-2" *ngIf="!loadingClientDetails">
  <div class="bg-darkblue text-white px-2 lg:px-4 lg:py-2 text-center text-base rounded-md cursor-pointer"
    *ngIf="userType!=='Custodian'" hidden>
    <p (click)="clientInfoNavigate(ROUTE.OVERVIEW)" class="prs-1">Overview</p>
  </div>
  <div *ngIf="userType!=='Custodian'" hidden
    class="bg-darkblue text-white px-2 lg:px-4 lg:py-2 text-center text-base rounded-md cursor-pointer">
    <p (click)="clientInfoNavigate(ROUTE.ABOUT_YOURSELF)" class="prs-1">Personal Details</p>
  </div>

  <div *ngIf="userType!=='Custodian'" hidden
    class="bg-darkblue text-white px-2 lg:px-4 lg:py-2 text-center text-base rounded-md cursor-pointer">
    <p (click)="clientInfoNavigate(ROUTE.EXEC_BENE_VIEW)" class="prs-1">
      Executors & Beneficiaries</p>
  </div>
  <div *ngFor="let permission of permissionList; let i = index" class="px-2 lg:px-4 lg:py-2 text-center rounded-md"
    [ngClass]="!permission['view'] || isClientDeathVerified ? 'bgs-disabled cursor-not-allowed' : 'bg-darkblue text-white cursor-pointer'"
    (click)="onSelectSection(permission)">
    <p class="prs-1">
      {{ permission['name'] != 'Digital Files' ? permission['name'] || permission['permission_name'] :
      'Digital Files' }}
    </p>
  </div>
</div>