import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'handleEmptyString',
})
/**
 * Handle empty string
 */
export class HandleEmptyStringPipe implements PipeTransform {
  /**
   * transform function
   *
   * @param {any} value
   * @param {any[]} args
   *
   * @return {string}
   */
  transform(value: any, ...args: any[]): string {
    return value || '---';
  }
}
