import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'imageLetterRepresentation',
})
/**
 * Image letter representation
 */
export class ImageLetterRepresentationPipe implements PipeTransform {
  /**
   * transform function
   * @param {any} value
   * @param {any[]} args
   * @return {any}
   */
  transform(value: any, ...args: any[]): any {
    return value[0].toUpperCase();
  }
}
