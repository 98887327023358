import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { LPSettingsInterface, LPUserPeopleInterface } from 'src/app/sharedComponent/list-people/list-people-interfaces';
import { ListPeopleComponent } from 'src/app/sharedComponent/list-people/list-people.component';
import { PAYMENT_STATUS } from 'src/constants/application.const';

@Component({
  selector: 'app-executor-beneficiaries-view',
  templateUrl: './executor-beneficiaries-view.component.html',
})
/**
 * View component for Executor Beneficiaries
 */
export class ExecutorBeneficiariesViewComponent implements OnInit {
  public toggleModalAddE: boolean;
  public toggleModalAddB: boolean;
  public toggleModalAddA: boolean;
  public toggleModalAddC: boolean;
  public preDefinedLoader: boolean;
  public userArray: Array<any>;
  public peopleArray: Array<any>;
  public beneficiaryArray: Array<any>;
  public executorArray: Array<any>;
  public advisorArray: Array<any>;
  public custodianArray: Array<any>;
  public submitLoader: boolean;
  public sectionSaveExitOptions: any;
  public userType: string;
  public requestId: string;
  public disableCheck: boolean = false;
  public isCustodian: boolean;
  public enableVaultCustodian: any;
  public enableExecutor: any;
  public lpSettings: LPSettingsInterface;
  public executorData: Array<LPUserPeopleInterface>;
  public beneficiaryData: Array<LPUserPeopleInterface>;
  public custodianData: Array<LPUserPeopleInterface>;
  public paymentStatus: number;
  public paymentStatusData = PAYMENT_STATUS;
  public isTransferAmountPaid: boolean;


  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private personalDetailsService: PersonalDetailsService,
    private peopleService: PeopleService,
    private commonHelper: CommonHelper,
    private router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.sectionSaveExitOptions = {
      section: 2,
      type: 5,
    };
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.requestId = params.get('id');
    });
    this.lpSettings = {};
    this.userType = this.localStorageService.getDataByKey('role');
    this.enableExecutor = this.localStorageService.getDataByKey('enableExecutor');
    this.enableVaultCustodian = this.localStorageService.getDataByKey('enableVaultCustodian');
    this.paymentStatus = this.localStorageService.getDataByKey('is_payment_complete')
    this.isTransferAmountPaid = !!(this.localStorageService.getDataByKey('is_transfer_amount_paid') == 1);
    this.getPersonalDetails();
  }

  /**
   * Get personal details
   */
  public getPersonalDetails(): void {
    this.preDefinedLoader = true;
    this.lpSettings.isLoading = true;

    // get people list
    if (this.userType === 'Consumer') {
      this.personalDetailsService.getUserPeople().subscribe((response: APIResponseModel) => {
        this.preDefinedLoader = false;
        this.lpSettings.isLoading = false;
        if (response.status) {
          this.userArray = response?.data?.user;
          this.peopleArray = response?.data?.user_people;
          this.processUserPeopleData();
        }
      }, () => {
        this.preDefinedLoader = false;
        this.lpSettings.isLoading = false;
      });
    } else {
      this.personalDetailsService.getUserPeople(this.requestId).subscribe((response: APIResponseModel) => {
        this.preDefinedLoader = false;
        this.lpSettings.isLoading = false;

        if (response.status) {
          this.userArray = response?.data?.user;
          this.peopleArray = response.data.user_people;
          this.processUserPeopleData();
        }
      }, () => {
        this.preDefinedLoader = false;
        this.lpSettings.isLoading = false;
      },
      );
    }
  }

  /**
   * Process {@link this.peopleArray peopleArray} to populate {@link this.userPeopleData userPeopleData}
   * with data to display.
   * @private
   */
  private processUserPeopleData() {
    this.executorData = [];
    this.beneficiaryData = [];
    this.custodianData = [];

    this.peopleArray.forEach((e) => {
      const person = ListPeopleComponent.getLPUserPeople(e)[0];

      if (e['custodian'] == 1) {
        // user_people to display as custodian(s).
        this.custodianData.push(person);
      }
      if (e['executor'] == 1) {
        this.executorData.push(person);
      }
      if (e['beneficiary'] == 1) {
        this.beneficiaryData.push(person);
      }
    });
  }

  /**
   * toggle add beneficiary modal
   *
   * @param {any} event
   * @param {string} type
   */
  public toggleModal(event: any, type: string): void {
    if (type === 'B') {
      this.modalService.open(`add-beneficiary-modal`);
    } else if (type === 'E') {
      this.modalService.open(`add-executor-modal`);
    } else if (type === 'C') {
      this.isCustodian = true;
      this.modalService.open(`get-custodian-modal`);
    } else if (type === 'A') {
      this.isCustodian = false;
      this.modalService.open(`get-advisor-modal`);
    }
    this['toggleModalAdd' + type] = !this['toggleModalAdd' + type];
  }
  /**
   * to detect custodian or advisor
   *
   * @param {any} data
   * return {any}
   */
  public custodianChangeListner(data: any): void {
    this.isCustodian = data;
  }
  /**
   * Listen for array change executor
   *
   * @param {Array<string>} array
   */
  public executorArrayChangeListner(array: Array<string>): void {
    if (!array) return;
    this.executorArray = array;
    this.disableCheck = !this.disableCheck;
  }

  /**
   * Listen for array change beneficiary
   *
   * @param {Array<string>} array
   */
  public beneficiaryArrayChangeListner(array: Array<string>): void {
    if (!array) return;
    this.beneficiaryArray = array;
    this.disableCheck = !this.disableCheck;
  }
  /**
   * Redirect to scheduler
   */
  public redirectoScheduler(): void {
    void this.slugInterceptorService.navigate(['advisors', 'schedule-reminder']);
  }
  /**
   * change executor and beneficiary
   */
  public submitAlteredPeople(): Observable<APIResponseModel> {
    let data: {} = {
      beneficiary: this.beneficiaryArray,
      executor: this.executorArray,
      type: '3',
      roletype: this.userType,
    };
    if (this.userType !== 'Consumer') {
      data = { ...data, request_id: this.requestId };
    }
    this.submitLoader = true;
    return new Observable((observer) => {
      this.peopleService.updatePeopleDetails(data).subscribe({
        next: (response: APIResponseModel) => {
          if (response.status) {
            observer.next(response);
          }
        },
        error: (exception) => this.commonHelper.httpResponseHandler(exception?.error),
        complete: () => {
          this.submitLoader = false;
          observer.complete();
        },
      });
      return {
        unsubscribe() {
        },
      };
    });
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }

  /**
   * change route
   */
  public navigateToPro() {
    void this.slugInterceptorService.navigate(['dashboard'], null, true);
  }

  /**
   *
   */
  public isValidNextSection() {
    if (this.localStorageService.getDataByKey('enableExecutor')) {
      return this.executorArray?.length > 0 && this.beneficiaryArray?.length > 0;
    } else
      if (this.localStorageService.getDataByKey('enableVaultCustodian')) {
        return this.custodianArray?.length > 0 && this.beneficiaryArray?.length > 0;
      }
  }

  /**
   * Click handler
   * @param{string} command
   */
  public click(command: string) {
    const isPro = this.userType !== 'Consumer';
    switch (command) {
      case 'ReturnToDashboard':
        void this.slugInterceptorService.navigate(['dashboard'], null, isPro);
        break;
      case 'Back':
        const back_url = ['executor-beneficiary', 'manage-custodian', isPro ? this.requestId : ''];
        void this.slugInterceptorService.navigate(back_url);
        break;
      case 'Next':
        const next_url = isPro ? ['advisors', 'manage-advisors', this.requestId] : ['advisors', 'manage-advisors'];
        void this.slugInterceptorService.navigate(next_url);
        break;
    }
  }
}
