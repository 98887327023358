import { Component, OnInit } from '@angular/core';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { AgentsService } from 'src/app/professional-dashboard/services/agents.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-professional-advisor-manager',
  templateUrl: './home-professional-advisor-manager.component.html',
  styleUrls: ['./home-professional-advisor-manager.component.css']
})
export class HomeProfessionalAdvisorManagerComponent implements OnInit {

  dataLoading: Boolean = false;
  showData: Boolean;
  totalClients: number;
  onSearch: boolean;
  clientSelected: boolean;
  agentsList: Array<object>;
  userDetails: object;
  clientImageSrc: string;

  // pagination
  paginationLinks: Array<any>;
  currentPage :number = 1;
  searchKeyword = '';
  lastPage: number;
  recordsPerPage: number = 5;
  proClientTableSettings: proClientTableSettingsI;


  constructor(
    private agentService: AgentsService,
    private slugInterceptorService: SlugInterceptorService
  ) { }

  ngOnInit(): void {
    this.getAgentsList();
    this.clientImageSrc = `${environment.BASE_URL_RAW}uploads/profile_photo/`;
  }
  /**
   * Get agents list
   */
  public getAgentsList(pageNo?: number) {
    if(this.currentPage === pageNo) return
    if (pageNo) {
      this.currentPage = pageNo;
    }
    this.dataLoading = true;
    this.agentService.getAllProfessionals(this.currentPage, this.recordsPerPage, SortPreference.Name, this.proClientTableSettings?.searchKeyword ?? '').subscribe((response) => {
      this.agentsList = response.data.data;
      this.userDetails = this.agentsList[0];
      this.paginationLinks = response.data?.links;
      this.lastPage = response.data?.last_page;
    }, () => {
    }, () => this.dataLoading = false);
  }
  /**
   * Select professional
   * @param details 
   */
  public selectProfessional(details: object): void {
    if (details) {
      this.userDetails = details;
    }
  }

  /**
   * Determine whether search client on
   * @param keyword 
   */
  onSearchClient(keyword: any): void {
    this.proClientTableSettings.searchKeyword = keyword;
    this.getAgentsList();
  }

  toggleBulkSendInvite(): void {

  }
  getAdvisorTerm(): void {

  }
  changeRoute(url: string): void {
    void this.slugInterceptorService.navigate([url], null, true);

  }

}

enum SortPreference {
  Name = 'desc',
  OldToNew = 'Old to New',
  NewToOld = 'New to Old',
  LastLogin = 'login',
  NoofClients = 'clients'
}
interface proClientTableSettingsI {
  sortPreference: SortPreference,
  recordsPerPage: number,
  searchKeyword: string
}
