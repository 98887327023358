import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CommonModelService} from "../../../../services/common-model.service";
import {
  AccessControlComponent
} from "../../../../components/executor-beneficiaries/professional-user-type/access-control/access-control.component";
import {ProfessionalDashboardService} from "../../../services/professional-dashboard.service";
import {CommonHelper} from "../../../../helper/common.helper";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-access-control-defaults-modal',
  templateUrl: './access-control-defaults-modal.component.html',
})
export class AccessControlDefaultsModalComponent implements OnInit {
  @ViewChild(AccessControlComponent) aclComponent: AccessControlComponent;
  @Input() id: string = 'access-control-defaults-modal';
  public isLoading: boolean = false;
  public data: any;
  private professionalID: string;
  private permissionsData;

  constructor(private modalService: CommonModelService,
              private professionalDashboardService: ProfessionalDashboardService,
              private toastr: ToastrService,
              private commonHelper: CommonHelper) {
  }

  ngOnInit(): void {

  }

  /**
   * Setter for edit values in access control
   *
   * @param {any} data
   */
  @Input() set advisorData(data: {}) {
    this.data = data;
    this.professionalID = data?.['professional_id'];
  }

  /**
   * Run submit actions
   */
  public submit() {
    this.permissionsData = this.aclComponent.getPermissionsData();
    this.updateAccess();
  }

  /**
   * add/update access endpoint call
   */
  public updateAccess() {
    // add/ update
    this.permissionsData.forEach((cur) => {
      cur['permission_id'] = cur['value'];
    });
    const payload = {
      permission: this.permissionsData,
      professional_id: this.professionalID
    };
    this.isLoading = true;
    this.professionalDashboardService.saveDefaultAccessControlList(this.professionalID, payload).subscribe({
      next: (res) => {
        if (res.status) {
          this.toastr.success("Default Access Control Settings Saved");
        }
      },
      error: (err) => this.commonHelper.httpResponseHandler(err.error),
      complete: () => {
        this.isLoading = false
        this.closeModal();
      }
    });
  }

  /**
   * Close this modal
   */
  public closeModal() {
    this.modalService.close(this.id);
  }
}
