<div [class]="buttonGroupClass">
    <!-- BEGIN: Onboard New Clients -->
    <div class="relative inline-block text-left" (click)="toggleButtonDropdown(0)">
        <!-- Onboard New Clients : Button -->
        <div>
            <button type="button" 
                class="w-full button button--lg bg-darkblue  px-4 rounded-xs h-10 focus:outline-none text-regular text-white text-sm flex flex-row justify-center items-center ">
                <img src="assets/images/dashboard/file-plus-white.svg" id="menu-button" aria-expanded="true"
                    aria-haspopup="true" class="mr-3 " alt="LegacyNow"> Onboard New Clients
            </button>
            <!-- Onboard New Clients : Dropdown menu -->
            <div *ngIf="buttonDropdownOpen[0]"
                class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="py-1">
                    <a class="block px-4 py-2 text-left text-md text-gray-700" role="menuitem" tabindex="-1"
                        (click)="changeRoute('clients/add-client')" id="menu-item-0">Add New Client</a>
                </div>
                <div class="py-1">
                    <a class="block px-4 py-2 text-left text-md text-gray-700" role="menuitem" tabindex="-1"
                        (click)="toggleBulkImportClient(true)" id="menu-item-0">Import New Client</a>
                </div>
                <div class="py-1">
                    <a class="block px-4 py-2 text-left text-md text-gray-700" role="menuitem" tabindex="-1"
                        (click)="onClickGenerateLink()" id="menu-item-0">Generate Onboarding Link</a>
                </div>
            </div>
        </div>
    </div>
    <!-- END: Onboard New Clients -->

    <!-- START: Onboard Existing Clients -->
    <div class="relative inline-block text-left" (click)="toggleButtonDropdown(1)">
        <!-- Onboard Existing Clients : Button -->
        <div>
            <button type="button"
                class="w-full button button--lg bg-darkblue  px-4 rounded-xs h-10 focus:outline-none text-regular text-white text-sm flex flex-row justify-center items-center ">
                <img src="assets/images/dashboard/file-plus-white.svg" id="onboard-existing-client-menu-button"
                    aria-expanded="true" aria-haspopup="true" class="mr-3 " alt="LegacyNow"> Onboard LegacyNOW Clients
            </button>
            <!-- Onboard Existing Clients : Dropdown menu -->
            <div *ngIf="buttonDropdownOpen[1]"
                class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu" aria-orientation="vertical" aria-labelledby="onboard-existing-client-menu-button"
                tabindex="-1">
                <div class="py-1">
                    <a class="block px-4 py-2 text-left text-md text-gray-700" role="menuitem" tabindex="-1"
                        (click)="toggleImportClient()" id="menu-item-0">Add LegacyNOW Client</a>
                </div>
                <div class="py-1">
                    <a class="block px-4 py-2 text-left text-md text-gray-700" role="menuitem" tabindex="-1"
                        (click)="toggleBulkImportClient(false)" id="menu-item-0">Import LegacyNOW clients</a>
                </div>
            </div>
        </div>
    </div>
    <!-- END: Onboard Existing Clients -->
</div>

<!-- Bulk Client Import Modal -->
<app-client-bulk-import [importTerm]="isNewClient?'employee':'client'"
[userRole]="professionalType"></app-client-bulk-import>

<!-- Client Import Modal -->
<app-import-professional-client (toggleModalEmitter)=" modalEventListner($event)" [userRole]="professionalType"
  [agentID]="proID">
</app-import-professional-client>

<!-- Message Modal -->
<app-message-modal [id]="'add-card-alert-modal'" [setMessageData]="messageData" (buttonEmitter)="messageModalListener($event)"></app-message-modal>
