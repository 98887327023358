<app-common-modal [id]="PAYMENT_MODAL_ID" class="app-common-modal">
  <div class="scroll-hidden" [ngClass]="{'min-h-25': isLoading}">
    <ng-container *ngIf="isLoading; else content">
      <div class="center-screen">
        <div class="loading-red-free m-auto h-24 w-24"></div>
      </div>
    </ng-container>

    <ng-template #content>
      <div class="p-2 container mx-auto">
        <div class="title__wrap text-center mb-4">
          <h1 class="text-semibold text-accent pb-2 text-darkblue" [ngClass]="mobile ? 'fs-bhead' : 'fs-title'">
            Payment Required
          </h1>
        </div>
        <h3 class="text-gray-700 text-gray-700 font-semibold text-xl mb-4 mx-4">
          The LegacyNOW Digital Vault operates on a monthly subscription basis of
          {{ monthlyPackageDetails | currency }}. If during your first month you are not entirely
          satisfied with the service, you can cancel for a complete refund.
        </h3>
        <h3 class="text-gray-700 text-gray-700 font-semibold text-xl mb-4 mx-4">
          Click <a class="link" (click)="redirectToPaymentPage()"> Proceed to Payment</a> and continue to enter information, or you can choose <a class="link" (click)="closeModal()">Pay Later</a> to continue with the ability to only view previously entered information.
        </h3>

        <div class="flex gap-4 mt-8 justify-end">
          <app-button-loader
            (click)="closeModal()" [outlineOnly]="true" buttonText="Pay Later">
          </app-button-loader>
          <app-button-loader
            (click)="redirectToPaymentPage()" [outlineOnly]="false" buttonText="Proceed to Payment">
          </app-button-loader>
        </div>
      </div>
    </ng-template>
  </div>
</app-common-modal>
