import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { faCheckCircle, faCircleXmark, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CommonHelper } from 'src/app/helper/common.helper';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { TOGGLE_OPTIONS_YES_NO, USER_TYPES } from '../../../constants/application.const';
import { ToggleOptionsInterface } from '../../interface/common.interface';
import { LPEmitterInterface, LPSettingsInterface, LPUserPeopleInterface } from './list-people-interfaces';


@Component({
  selector: 'app-list-people',
  templateUrl: './list-people.component.html',
  styleUrls: ['./list-people.css'],
})
/**
 * Show a list of people based off an input Array
 */
export class ListPeopleComponent implements OnInit {
  @Output() clickEmitter = new EventEmitter<LPEmitterInterface>();
  @Input() userPeopleData: Array<LPUserPeopleInterface>;
  @Input() sectionName: string;
  cardRootNodeID: string = 'person_card_root';
  userData: any;
  primaryAdvisorId: string;
  faCheckCircle = faCheckCircle;
  faCircleXMark = faCircleXmark;
  deleteIcon = faTrash;
  settings: LPSettingsInterface;
  toggleOptionsYesNo = TOGGLE_OPTIONS_YES_NO;
  isMobile: boolean;
  static user_marital_status: any;
  static user_mail_id: string;
  static section_name: string;
  isChecked: boolean = false;
  cssCheckbox = (person: LPUserPeopleInterface) => ({ 'disable': person['checkbox']?.['disableCheckbox'], 'enable': !person['checkbox']?.['disableCheckbox'], 'checked': person['checkbox']['isChecked'] });

  @Input() set lpSettings(settings: LPSettingsInterface) {
    this.settings = settings;
    this.deleteIcon = settings.delete?.useIcon == 'faTrash' ? faTrash : faXmark;
  }

  constructor(private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.userData = this.localStorageService.getUserData(USER_TYPES.user);
    this.primaryAdvisorId = this.userData?.['slug_details']?.id;
    ListPeopleComponent.user_marital_status = this.localStorageService.getUserData()?.request?.marital_status;
    ListPeopleComponent.user_mail_id = this.localStorageService.getUserData()?.['user']?.['email']
    ListPeopleComponent.section_name = this.sectionName;
    this.onResize();
  }

  /**
   * Return the {@link LPUserPeopleInterface} object with the specified id.
   * @param id
   * @private
   */
  private getPerson(id: string): LPUserPeopleInterface {
    return this.userPeopleData.find((e) => e.id === id);
  }

  /**
   * Returns an {@link LPEmitterInterface} with {@link LPEmitterInterface.id id},
   * {@link LPEmitterInterface.person person}, and {@link LPEmitterInterface.disabled disabled} initialized based on
   * the {@link id given id}.
   * @param id
   * @private
   */
  private getEmitObjBase(id: string): LPEmitterInterface {
    const person = this.getPerson(id);
    return {
      id: id,
      person: person,
      disabled: person.isDisabled,
    };
  }

  /**
   * Handle click event on name display.
   * @param id
   */
  clickName(id: string) {
    const payload = this.getEmitObjBase(id);
    payload.clickedName = true;
    this.clickEmitter.emit(payload);
  }

  /**
   * Handle checkbox onChange events.
   * @param id
   */
  onChangeCheckBox(id: string) {
    const person = this.getPerson(id);

    // Checkbox is disabled, prevent further action
    if (person['checkbox']?.['disableCheckbox']) {
      return;
    }

    // to change selected person checked || unchecked
    person['checkbox']['isChecked'] = !person['checkbox']['isChecked'];

    // Emit
    const payload = this.getEmitObjBase(person.id);
    payload.clickedCheckbox = { value: payload.person.checkbox.isChecked };
    this.clickEmitter.emit(payload);
  }

  /**
   * Triggered when a {@link LPUserPeopleInterface.toggle_switch toggle switch} is clicked.
   * @see ToggleSwitchComponent
   * @param $event Event returned from {@link ToggleSwitchComponent}.
   * @param person {@link LPUserPeopleInterface person} the event was triggered on.
   * @param index Index of the {@link LPUserPeopleInterface.toggle_switch toggle switch}.
   */
  onToggle($event: ToggleOptionsInterface, person: LPUserPeopleInterface, index: number) {
    person.toggle_switch[index].value = $event.value;
    const payload = this.getEmitObjBase(person.id);
    payload.clickedToggle = { index: index, value: $event.value };
    this.clickEmitter.emit(payload);
  }

  /**
   * Handle text input onChange events.
   * @param person
   * @param type
   * @param $event
   */
  onTextInput(person: LPUserPeopleInterface, type: string, $event: Event) {
    const payload = this.getEmitObjBase(person.id);
    payload.textInput = { type: type, event: $event };
    this.clickEmitter.emit(payload);
  }

  /**
   * Handle button click events.
   * @param id
   */
  clickButton(id: string) {
    const payload = this.getEmitObjBase(id);
    payload.clickedButton = true;
    this.clickEmitter.emit(payload);
  }

  /**
   * Handle delete click events.
   * @param id
   */
  clickDelete(id: any) {
    const payload = this.getEmitObjBase(id);
    payload.clickedDelete = true;
    this.clickEmitter.emit(payload);
  }

  /**
   * Processes a user_people object as received from {@link PersonalDetailsService.getUserPeople getUserPeople}.
   * If an array of objects is sent, it will process all the objects in the array.
   * @param user_people
   */
  static getLPUserPeople(user_people: Object | Array<Object>): Array<LPUserPeopleInterface> {
    function getPeopleSubText(user_people: Object): string {
      if (user_people['relation'] == 1) {
        const user_marital_status = ListPeopleComponent.user_marital_status;
        return CommonHelper.getPartnerSubText(user_marital_status);
      }
      if (user_people['relation'] == 2) {
        return 'Your Child';
      }
      // If advisor, use type advisor_type.name. Else if trust, use 'Trust'. Else use people_relation.name
      if (user_people['advisor'] == 1) {
        return CommonHelper.getAdvisorSubText(user_people, ListPeopleComponent.section_name)
      }
      if (user_people['beneficiary_type_short'] == 2) {
        return user_people?.['beneficiary_type']?.name;
      }
      // If login mail id and user people mail id are same
      if (user_people['email'] === ListPeopleComponent.user_mail_id) {
        return 'Yourself';
      }
      return user_people['people_relation']?.name ?? '';
    }

    const lp_user_people = new Array<LPUserPeopleInterface>();

    // Sent an array of data,
    if (user_people instanceof Array) {
      // recursively handle the objects
      user_people.forEach((e) => {
        const person = this.getLPUserPeople(e);
        if (person[0]) {
          lp_user_people.push(person[0]);
        }
      });

      // Sent an Object, process it.
    } else if (user_people instanceof Object) {
      const subtext = getPeopleSubText(user_people);

      // 1 == partner, 2 == child.
      const imgSRC = { 1: 'assets/images/executors/heart.svg', 2: 'assets/images/executors/display-img.svg' };
      const imageSRC = imgSRC[user_people['relation']] ?? null;

      // Name
      const fullName = `${user_people['first_name'] ?? ''} ${user_people['middle_name'] ?? ''} ${user_people['last_name'] ?? ''}`.replace(/\s\s/g, ' ');
      const name = user_people['full_name'] ?? fullName;

      //CompanyName 
      const companyName = CommonHelper.getCompanyName(user_people, ListPeopleComponent.section_name);

      // Build person
      const person: LPUserPeopleInterface = {
        id: user_people['id'],
        name: name,
        subtext: subtext,
        companyName: companyName,
        imageALT: user_people['name_initials'],
        imageSRC: imageSRC,
        checkbox: {},
        button: {},
        textInput: {},
        rightContent: {},
      };

      lp_user_people.push(person);
    }

    return lp_user_people;
  }


  static getLPProfessionalPeople(professional_people: Object | Array<Object>) {
    const lp_professional = new Array<LPUserPeopleInterface>();
    // Sent an array of data,
    if (professional_people instanceof Array) {
      // recursively handle the objects
      professional_people.forEach((e) => {
        const person = this.getLPProfessionalPeople(e);
        if (person[0]) {
          lp_professional.push(person[0]);
        }
      });

      // Sent an Object, process it.
    } else if (professional_people instanceof Object) {
      const subtext = 'Professional';

      const imgSRC = 'assets/images/executors/display-img.svg';

      // Name
      const fullName = `${professional_people?.['additionalDetails']?.['first_name'] ?? ''} ${professional_people?.['additionalDetails']?.['middle_name'] ?? ''} ${professional_people?.['additionalDetails']?.['last_name'] ?? ''}`.replace(/\s\s/g, ' ');
      const name = professional_people['full_name'] ?? fullName;

      //CompanyName ;
      const companyName = ''

      // Build person
      const person: LPUserPeopleInterface = {
        id: professional_people['value'],
        name: name,
        subtext: subtext,
        companyName: companyName,
        imageALT: 'NA',
        imageSRC: imgSRC,
        checkbox: {},
        button: {},
        textInput: {},
        rightContent: {},
      };

      lp_professional.push(person);
    }

    return lp_professional;
  }

  /**
   * If <code>person.textInput.hideText</code> is true, the type is hidden. <br>
   * Else, if it exists, use <code>person.textInput.type</code> <br>
   * Else use <code>'text'</code> as default.
   * @param person
   */
  getInputType(person: LPUserPeopleInterface): string {
    return person.textInput?.hideText ? 'hidden' :
      (this.settings?.textInput?.type ?? 'text');
  }

  /**
   * Get the src string for the up or down arrow image for the dropdown, based on {@link person person.dropdown.isOpen}.
   * @param person
   */
  getDropdownArrowSrc(person: LPUserPeopleInterface): string {
    const dir = person.dropdown.isOpen ? 'up' : 'down';
    return `assets/images/about-yourself/angle-${dir}-arrow.png`;
  }

  /**
   * Based on {@link window.innerWidth}, whether to treat the screen as a mobile size.
   */
  @HostListener('window:resize')
  onResize() {
    this.isMobile = window.innerWidth < 550;
  }

  /**
   * Toggle dropdown for clicked person (if exists)
   * @param person
   */
  clickDropdownToggle(person: LPUserPeopleInterface) {
    if (person.dropdown) {
      person.dropdown.isOpen = !person.dropdown.isOpen;
    }
  }

  /**
   * Handler for clicking the root element of a card.
   * @param $event
   * @param person
   */
  clickRoot($event: MouseEvent, person) {
    const targetID: string = ($event.target as Element).id;

    // Use dropdown toggle on whole person card (if not clicking another element)
    if (targetID == this.cardRootNodeID) {
      this.clickDropdownToggle(person);
    }
  }

  /**
   * @param person
   * @returns true if <code>{@link person person.dropdown.isOpen} == true</code> OR
   * if the {@link person} doesn't use a dropdown but the screen is a mobile size AND the person {@link hasInteractables}.
   */
  public showDropdown(person: LPUserPeopleInterface): boolean {
    return person.dropdown?.isOpen || (!person.dropdown && this.isMobile && this.hasInteractables(person));
  }

  /**
   * @param person
   * @returns true if there are any interactable objects set to be visible.
   */
  public hasInteractables(person: LPUserPeopleInterface): boolean {
    return this.settings?.textInput?.show ||
      this.settings?.button?.show ||
      this.settings?.checkbox?.show ||
      this.settings?.edit?.show ||
      person.toggle_switch?.length > 0;
  }

  /**
   * Returns a shallow-copy of {@link userPeopleData} with a sort applied if the sort is not disabled with {@link LPSettingsInterface.sort.dontSort sort.dontSort}.
   */
  public get getPeople(): LPUserPeopleInterface[] {
    const people: LPUserPeopleInterface[] = this.userPeopleData ? [...this.userPeopleData] : [];

    // Sort function.
    if (!this.settings.sort?.dontSort) {
      // If a function is not provided, use default name comparison.
      const fn = this.settings.sort?.fn ?? ((a, b) => {
        return (a.name as string).localeCompare(b.name);
      });
      people.sort(fn);
    }

    return people;
  }

  /**
   * Handle click event on the edit icon
   * @param id
   */
  clickEdit(id: string) {
    const payload = this.getEmitObjBase(id);
    payload.clickedEdit = true;
    this.clickEmitter.emit(payload);
  }
}
