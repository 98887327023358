<div class="lg:grid grid-cols-12 background-color-grey">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 my-4">
              <div class="col-span-12 sm:col-span-2">
                <img src="assets/images/accounts-assets/assets.svg" class="w-20" alt="digital vault">
              </div>

              <div class="col-span-12 sm:col-span-10">
                <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
                  Assets and Liabilities
                </h2>
                <p class="text-sm text-grey tracking-wide text-regular">This is where you can list out where your
                  money is kept. It helps whoever deals with your will to make sure they’ve found everything.
                </p>
              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-full'"></app-card-progress-bar>
              <!-- END: Progress Bar -->

              <div class="p-6">
                <!-- BEGIN: Financial Assets -->
                <div class="flex flex-row justify-between items-center">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    Financial Assets
                  </h4>
                  <div (click)="toggleFinanceModal()" *ngIf="assetPermissionCheckFunctions['Financial Assets']()">
                    <img src="assets/images/executors/plus-square.svg" class="w-5 cursor-pointer" alt="digital vault">
                  </div>
                </div>
                <app-finance-list (emitPermissionsList)="getPermissionsEventListener($event)" (isLoading)="setLoading($event, 'financial')" (paidStatusEvent)="paidStatusEventListener($event)" [isAssetsView]="true"></app-finance-list>
                <!-- END: Financial Assets -->

                <!-- BEGIN: Retirement Assets -->
                <div class="flex flex-row justify-between items-center my-4">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    Retirement Assets
                  </h4>
                  <div (click)="toggleRetirementModal()" *ngIf="assetPermissionCheckFunctions['Retirement Assets']()">
                    <img src="assets/images/executors/plus-square.svg" class="w-5 cursor-pointer" alt="digital vault">
                  </div>
                </div>
                <app-retirement-list (emitPermissionsList)="getPermissionsEventListener($event)" (isLoading)="setLoading($event, 'retirement')" (paidStatusEvent)="paidStatusEventListener($event)" [isAssetsView]="true"></app-retirement-list>
                <!-- END: Retirement Assets -->

                <!-- BEGIN: Liabilities -->
                <div class="flex flex-row justify-between items-center my-4">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    Liabilities
                  </h4>
                  <div (click)="toggleLiabilitiesModal()" *ngIf="assetPermissionCheckFunctions['Liabilities']()">
                    <img src="assets/images/executors/plus-square.svg" class="w-5 cursor-pointer" alt="digital vault">
                  </div>
                </div>
                <app-liabilities-list (emitPermissionsList)="getPermissionsEventListener($event)" (isLoading)="setLoading($event, 'liabilities')" (paidStatusEvent)="paidStatusEventListener($event)" [isAssetsView]="true"></app-liabilities-list>
                <!-- END: Liabilities -->

                <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
                  <button (click)="click('Back')"
                    class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault">
                    Back
                  </button>
                  <div class="flex flex-col-reverse sm:flex-row gap-4 justify-end items-center w-full">
                    <button *ngIf="!buttonProgress" (click)="click('SaveForLater')"
                      [disabled]="isLoading || !canAccessForm"
                      class="flex items-center justify-center border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 text-regular px-6">
                      <div class="loading-blue mr-2" *ngIf="isLoading"></div>
                      Save for Later
                    </button>
                    <button *ngIf="!buttonProgress" (click)="click('MarkComplete')"
                      [disabled]="isLoading || !canAccessForm" [zIndex]="0" [tooltipClass]="'tooltip-zindex'"
                      [tooltip]="'You can update later should this information change'"
                      class="flex items-center justify-center mark-complete-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 text-regular px-6">
                      <div class="loading-blue mr-2" *ngIf="isLoading"></div>
                      Mark as Complete
                    </button>
                    <button *ngIf="buttonProgress && this.userType === 'Consumer'" (click)="click('ReturnToDashboard')"
                      [disabled]="isLoading"
                      class="flex items-center justify-center border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 focus:outline-none text-regular text-white px-6">
                      Return to Dashboard
                    </button>
                    <button *ngIf="buttonProgress" (click)="click('NextSection')" [disabled]="isLoading"
                      class="flex items-center justify-center hover:underline-text text-sm tracking-wide w-full sm:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-6 text-bold">
                      Next Section
                    </button>
                  </div>
                </div>
              </div>
              <!-- END: Leftside Card -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class=" col-span-12 md:col-span-6 lg:grid justify-center ">
    <about-yourself-right-content [formInputValue]="{}"
      [otherValues]="sectionSaveExitOptions"></about-yourself-right-content>

  </div>
  <!-- END: Rightside Content -->
</div>
<!-- Plaid modal -->
<app-import-from-plaid-option [clientHasPaidSubscription]="clientHasPaidSubscription" [assetType]="assetType" [permissions]="permissions" [modalID]="midPlaid"
  (plaidOptionEmitter)="plaidOptionListener($event)">
</app-import-from-plaid-option>
<app-payment-success></app-payment-success>
<app-payment-failure></app-payment-failure>