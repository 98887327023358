import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { ValidationHelper } from 'src/app/helper/validation.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { AgentsService } from 'src/app/professional-dashboard/services/agents.service';
import { ClientsService } from 'src/app/professional-dashboard/services/clients.service';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ConsumerPaymentMethod, LIST_NEW, LIST_UPDATE, PAYMENT_TYPES, PROFESSIONAL_ROLE, USER_TYPES } from 'src/constants/application.const';

@Component({
  selector: 'app-import-professional-client',
  templateUrl: './import-professional-client.component.html',
  styles: [`
  .currency-input {
    text-indent: 2rem;
  }
`]
})
/**
 * Import Professional client
 */
export class ImportProfessionalClientComponent implements OnInit {
  @Output() toggleModalEmitter = new EventEmitter<any>();
  public importClientForm: FormGroup;
  public valuableTypes: Array<any>;
  public submitLoaderDelete: boolean;
  public submitLoader: boolean;
  public userType: string;
  public requestId: string;
  @Input() agentID: string = '';
  @Input() userRole: string = '';
  public agentList: Array<any>;
  public advisorTerm: string;
  public selectedAgentId: string = '';
  public submitted: boolean = false;
  public asterisk: any;
  public selectedPaymentType: any;
  public subscriptionAmount: number;
  public readonly PROFESSIONAL_ROLE = PROFESSIONAL_ROLE;
  public readonly paymentTypes = PAYMENT_TYPES;


  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private professionalClientService: ClientsService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private toastr: ToastrService,
    private validation: ValidationHelper,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private agentService: AgentsService,
    private professionalUserService: ProfessionalUserService,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.valuableTypes = [];
    this.selectedPaymentType = ConsumerPaymentMethod.PAID_BY_PROFESSIONAL;
    this.asterisk = faAsterisk;
    this.submitLoaderDelete = false;
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.importClientForm = this.formBuilder.group({
      id: ['', Validators.required],
      // payment_type: ['', { updateOn: 'blur', validators: [Validators.required] }],
      // pay_amount: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS), this.commonHelper.subscriptionAmountValidator.bind(this)] }]
    });
    this.validation.setValidationErrors({
      // pay_amount: { amountBelowMinimum: 'Please ensure the amount is at least $0.50. Please adjust as necessary.', subscriptionAmountExceeded: this.subscriptionAmount ? `Please ensure the subscription amount does not exceed $${this.subscriptionAmount}.` : 'Please enter a valid amount' }
    });
    // this.updateSubscriptionAmountValidations();
    Object.keys(this.importClientForm.controls).forEach(controlName => {
      this.importClientForm.get(controlName)?.valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.importClientForm.get(controlName))
      );
    });
    this.getAgents();
    this.advisorTerm = this.professionalUserService.getAdvisorTerm();
    this.resetForm();
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.importClientForm.controls;
  }


  /**
   * import client
   */
  public onSubmit(): void {
    this.importClientForm.markAllAsTouched();
    // if (!this.importClientForm.valid || !((this.userRole === PROFESSIONAL_ROLE.manager || this.userRole === PROFESSIONAL_ROLE.professional) ? !!this.selectedAgentId : !!this.agentID)) return;
    this.submitLoader = true;
    // import client
    const payload = {
      request_unique_id: this.importClientForm.value.id,
      // payment_type : this.selectedPaymentType,
      // pay_amount :this.importClientForm.value['pay_amount'],
      // agent_id: (this.userRole === PROFESSIONAL_ROLE.manager || this.userRole === PROFESSIONAL_ROLE.professional) ? this.selectedAgentId : this.agentID,
    };

    this.professionalClientService.importClient(payload).subscribe((response: APIResponseModel) => {
      this.submitLoader = false;
      if (response.status) {
        this.toggleModalEmitter.emit({
          ...response.data,
          listStatus: (this.importClientForm.value.id) ? LIST_UPDATE : LIST_NEW,
        });
        this.closeModel();
        this.toastr.success(response.message);
      }
    }, (exception: any) => {
      this.submitLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }


  /**
   * close modal
   */
  public closeModel(): void {
    this.modalService.close('import-client-modal');
    this.resetForm();
    this.submitted = false;
  }

 /**
  * See {@link ValidationHelper.getErrors}
  * @param controlsKey
  */
  public getErrors(controlsKey: string, checkTouched?): Array<string> {
    return this.validation.getErrors(controlsKey, this.importClientForm, checkTouched);
  }

  /**
   * Reset form
   */
  public resetForm(): void {
    this.selectedPaymentType = 1;
    this.importClientForm.reset({
      id: '',
      // pay_amount: null,
      // payment_type: ConsumerPaymentMethod.PAID_BY_PROFESSIONAL
    });
  }

  /**
  * get all agents
  */
  public getAgents() {
    this.agentService.getAllAgents().subscribe(
      (response) => {
        this.agentList = this.commonHelper.convertToOptionsFormat(
          response.data,
          'id',
          'full_name',
        );
         //If logged-in user is a professional or manager ,add the professional user to the advisors list
         const isProfessionalAsAgent: boolean = this.userType === this.PROFESSIONAL_ROLE.professional || this.userType === this.PROFESSIONAL_ROLE.manager;
        if (isProfessionalAsAgent) {
          this.agentList.push({
            displayText: (this.localStorageService.getUserData(USER_TYPES.pro))?.user.first_name,
            value: ((this.localStorageService.getUserData(USER_TYPES.pro))?.user.id
            )
          })
        }
      },

      (exception: any) => {
        this.commonHelper.httpResponseHandler(exception?.error);
      },
    );
  }

  /**
   * change event listener for agent type
   *
   * @param {any} data
   */
  public optionChangeEmitterAgentType(data: any): void {
    if (!data) return;
    this.selectedAgentId = data?.value;
  }

  /**
   * payment type select on change
   * @param {any} data
   */
  public onPaymentTypeSelect(data) {
    this.selectedPaymentType = data
    // this.importClientForm.patchValue({
    //   payment_type: data
    // })
    // this.updateSubscriptionAmountValidations()
  }

  /**
   * Updates form validations based on the selected payment type.
   */
  private updateSubscriptionAmountValidations() {
    const subscriptionAmountControl = this.importClientForm.get('pay_amount');
    if (this.selectedPaymentType === 2) {
      subscriptionAmountControl.setValidators([Validators.required, this.commonHelper.subscriptionAmountValidator.bind(this)]);
    } else {
      subscriptionAmountControl.removeValidators([Validators.required]);
    }
    this.importClientForm.patchValue({
      pay_amount: null,
    });
    subscriptionAmountControl.markAsUntouched();
    subscriptionAmountControl.updateValueAndValidity();
  }
}

