<div class="bg-transparent border-card-grey rounded-xs mt-4 md:h-auto lg:h-20" *ngIf="preDefinedLoader">
  <div class="grid grid-cols-12 md:h-auto lg:h-20 items-end sm:items-center gap-2 sm:gap-4 p-2 lg:px-4">
    <div class="col-span-12 sm:col-span-2">
      <div class="flex sm:items-center sm:justify-center border-circle sm:mx-auto h-12 w-12">
        <app-shimmer-loading [loading]="preDefinedLoader" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
      </div>
    </div>
    <div class="col-span-10 sm:col-span-8">
      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
        <h4 class="text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate truncate">
        </h4>
      </app-shimmer-loading>
      <p class="text-grey text-regular text-sm truncate">
        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
          <span class="font-semibold"></span>
        </app-shimmer-loading>
      </p>
    </div>
    <div class="col-span-2">
      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
        <img src="assets/images/about-yourself/edit.svg" class="cursor-pointer w-4 sm:w-5 float-right cursor-pointer" alt="digital vault">
      </app-shimmer-loading>
    </div>
  </div>
</div>

<ng-container *ngIf="!preDefinedLoader">
  <ng-container *ngIf="(canShowLiabilitiesList || !isAssetsView) else noDataAdded">
    <ng-container *ngIf="!this.permissions?.view">
      <p class="p-2 rounded-b-sm text-gray-700 background-color-grey mt-6">
        You do not have permission to view these items
      </p>
    </ng-container>
    <ng-container *ngIf="this.permissions?.view">
      <div class="bg-transparent border-card-grey rounded-xs mt-4 md:h-auto lg:h-20" *ngFor="let data of liabilitiesList">
        <div class="grid grid-cols-12 md:h-auto lg:h-20 items-end sm:items-center gap-2 sm:gap-4 p-2 lg:px-4">
          <div class="col-span-12 sm:col-span-2">
            <div class="flex sm:items-center sm:justify-center border-circle sm:mx-auto rounded-full h-10 w-10">
              <img src="assets/images/accounts-assets/default-bank.png" class="object-contain" alt="digital vault">
            </div>
          </div>
          <div class="col-span-10 sm:col-span-8" *ngIf="data?.['liability_type']?.type === liabilityTypesConst.L">
            <h4 class="text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate truncate">
              {{data?.['user_liability_loan']?.['loan_name']}}</h4>
            <p class="text-grey text-regular text-sm truncate">
              <span class="font-semibold">{{data?.['user_liability_loan']?.['account_number']}}</span>
              | {{data?.['liability_type']?.name}}
            </p>
          </div>
          <div class="col-span-10 sm:col-span-8" *ngIf="data?.['liability_type']?.type === liabilityTypesConst.C">
            <h4 class="text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold truncate truncate">
              {{data?.['user_liability_card']?.['card_name']}}</h4>
            <p class="text-grey text-regular text-sm truncate">
              <span class="font-semibold">{{data?.['user_liability_card']?.['card_number']}}</span>
              | {{data?.['liability_type']?.name}}
            </p>
          </div>
          <div class="col-span-2">
            <img src="assets/images/about-yourself/edit.svg" [ngClass]="{'cursor-pointer':canAccessForm}"
              class="cursor-pointer w-4 sm:w-4 sm:w-5 float-right cursor-pointer mb-1" alt="digital vault"
              (click)="canAccessForm ?toggleModalEdit(data[loadEditDetailsMapper[data?.['liability_type']?.type]], data?.['liability_type']?.type, data?.['liability_type'], data?.id):''">
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noDataAdded>
    <p class="p-2 rounded-b-sm text-gray-700 background-color-grey mt-2">
      No Data Added
    </p>
  </ng-template>
</ng-container>

<!-- Add liabilities popup modal -->
<app-add-liabilities-popup [editData]="editData" [permissions]="permissions"   [clientHasPaidSubscription]="clientHasPaidSubscription"
  (toggleModalEmitter)="modalEventListener($event)" ></app-add-liabilities-popup>