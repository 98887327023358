import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { EMAIL_PATTERN } from 'src/constants/application.const';
import { CommonModalEmitter } from '../../../../sharedComponent/common-modal/common-modal.component';

import { APIStatusCodes } from '../../../../interface/response.interface';


@Component({
  selector: 'app-get-advisor-modal',
  templateUrl: './get-advisor-modal.component.html',
})
/**
 * get advisor details by entering email id
 */
export class GetAdvisorModalComponent implements OnInit {
  @Output() toggleModalEmitter = new EventEmitter<any>();
  @Input() toggleModal: boolean;
  @Input() heightClass: string;
  @Input() skipEmailSearch: boolean = false;
  public getAdvisorForm: FormGroup;
  public advisorEmail: string;
  public submitLoader: boolean;
  public userType: string;
  public requestId: string;
  public countryCode: string;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public agentTerm: string;


  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private peopleService: PeopleService,
    private toastr: ToastrService,
  ) {
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.getAdvisorForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
    });
    if (!this.canAccessForm) {
      this.getAdvisorForm.disable();
    }
  }


  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.getAdvisorForm.controls;
  }


  /**
   * get advisor data by email
   */
  public getAdvisorData() {
    this.submitLoader = true;

    this.peopleService.getAdvisorByEmail({ email: this.getAdvisorForm.value.email }).subscribe({
      next: (response) => {
        if (response && response.data?.id) {
          console.log(response.data);
          // return;
          this.closeModal();
          this.modalService.open('add-advisor-modal');
          this.peopleService.advisorObservableEvents(response.data);
        }
      },
      error: (exception: any) => {
        this.submitLoader = false;
        if (exception.error.statusCode == APIStatusCodes.NOT_FOUND) {
          this.advisorEmail = this.getAdvisorForm.value.email;
          this.toastr.error(
            'The email you have provided does not have an active account with LegacyNOW.\n' +
            'Please check for spelling, or click \'Add a New Provider\' to enter your Professional Service Provider\'s ' +
            'information and invite them to join LegacyNOW.',
            'No Account Found'
          );
        } else {
          this.commonHelper.httpResponseHandler(exception?.error);
        }
      },
      complete: () => this.submitLoader = false,
    });
  }


  /**
   * close modal
   */
  public closeModal(): void {
    this.modalService.close('get-advisor-modal');
    this.resetForm();
  }


  /**
   * Reset form
   */
  public resetForm(): void {
    this.getAdvisorForm.reset({
      id: '',
    });
  }


  /**
   * create new advisor
   */
  public addNewAdvisor() {
    this.closeModal();
    this.modalService.open('define-advisor-modal');
  }


  /**
   * When the modal opens, check if we wish to skip straight to define-advisor-modal.
   * @param event
   */
  modalEventListener(event: CommonModalEmitter) {
    if(event.action == "open" && this.skipEmailSearch){
      this.addNewAdvisor();
    }
  }
}
