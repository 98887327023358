import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { CardService } from 'src/app/components/consumer-payment-options/card-details/card-details.service';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { USER_TYPES } from 'src/constants/application.const';

@Component({
  selector: 'app-client-management-button-group',
  templateUrl: './client-management-button-group.component.html',
  styleUrls: ['./client-management-button-group.component.css'],
})
export class ClientManagementButtonGroupComponent implements OnInit {
  @Input() buttonGroupClass: string = 'w-full px-2 space-y-4 md:space-y-0 md:flex items-center justify-end md:justify-end gap-4'
  @Output() buttonGroupEvent = new EventEmitter(null);

  public buttonDropdownOpen: boolean[] = [false, false];
  public professionalType: string;
  public isNewClient: boolean;
  public proID: string;
  public cardsAdded: boolean = true;
  public messageData = {
    title: 'Alert!',
    message: ['Please ensure that you add cards first before proceeding to add or import a client.'],
    buttons: [{ content: 'Add Card', emitValue: { proceedToAddCard: true }, class: 'bg-darkblue text-white mr-4' }],
  };
  public isPayVaultRegistration:boolean;

  constructor(private slugInterceptorService: SlugInterceptorService,
    private modalService: CommonModelService,
    private localStorageService: LocalStorageService,
    private _el: ElementRef,
    private cardService: CardService) { }

  ngOnInit(): void {
    this.professionalType = this.localStorageService.getDataByKey('role');
    this.proID = this.localStorageService.getUserData(USER_TYPES.pro).user.id;
    this.isPayVaultRegistration = (this.localStorageService.getDataByKey('proPaymentType') === 1);
    this.checkCardsAdded();
  }

  /**
   * Closes all dropdowns if the click event occurs outside the component.
   * @param {Event} event - The click event.
   */
  @HostListener('document:click', ['$event'])
  public closeAllDropdown(event: Event) {
    if (!this._el.nativeElement.contains(event.target)) {
      this.buttonDropdownOpen = [false, false];
    }
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, true);
  }

  /**
   * Toggles the state of a button dropdown.
   *
   * @param {number} dropdownIndex - The index of the dropdown to toggle.
   * @returns {void}
   */
  public toggleButtonDropdown(dropdownIndex: number) {
    if (this.cardsAdded || !this.isPayVaultRegistration) {
      this.modalService.isOpen('add-card-alert-modal')? this.modalService.close('add-card-alert-modal'): null ;
      this.buttonDropdownOpen = this.buttonDropdownOpen.map((isOpen, index) => index === dropdownIndex ? !isOpen : false);
    } else {
      this.modalService.open('add-card-alert-modal');
    }
  }

  /**
   * Toggles the bulk import client modal and sets the client type.
   *
   * @param {boolean} isNewClient - Indicates if the client is new.
   * @returns {void}
   */
  public toggleBulkImportClient(isNewClient: boolean): void {
    this.isNewClient = isNewClient;
    this.modalService.open('bulk-import-client-modal');
  }

  /**
  * Toggles the import client modal.
  *
  * @returns {void}
  */
  public toggleImportClient(): void {
    this.modalService.open('import-client-modal');
  }

  /**
   * Client Import Modal event listner
   *
   * @param {any} data
   */
  public modalEventListner(modalEventData: any) {
    if (Object.keys(modalEventData).length > 0) {
      this.buttonGroupEvent.emit(modalEventData)
    }
  }

  /**
   * Opens the modal for generating a URL link.
   */
  public onClickGenerateLink() {
    this.modalService.open('generate-url-link-modal');
  }

  /**
   * Checks if cards are added
   * Updates the cardsAdded property based on the API response length.
   * @returns {void}
   */
  public checkCardsAdded() {
    this.cardService.getCardsApi(true).subscribe((response: any) => {
      this.cardsAdded = response?.data?.length > 0
    });
  }

  /**
   * Listens for events from a message modal component.
   * If the event indicates proceeding to add a card, navigates to the payment settings page.
   * @param {any} event - The event object emitted by the message modal.
   * @returns {void}
   */
  public messageModalListener(event) {
    if (event.proceedToAddCard) {
      void this.slugInterceptorService.navigate(['payment-settings'], { queryParams: { menu: 3 } }, true);
    }
  }
}
