<app-common-modal [id]="this.id" class="app-common-modal"
  (commonModalEmitter)="modalListener($event)">
  <div class="relative h-full w-full">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModal()">
    <iframe *ngIf="safeUrl" [src]="safeUrl" width="500" height="380" class="w-full aspect-video md:aspect-square"
      frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen></iframe>
    <script src="https://player.vimeo.com/api/player.js"></script>
    <div *ngIf="!safeUrl" id="spin">
      <div>
        <div class=" w-12 h-12 border-b-2 border-red rounded-full animate-spin m-auto">
        </div>
      </div>
    </div>
  </div>
</app-common-modal>
