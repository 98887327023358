import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faBell, faCheckCircle, faCircleXmark, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/components/message/chat.service';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SlugService } from 'src/app/services/slug.service';
import { SocketService } from 'src/app/services/socket.service';
import { COMMON_SUBSCRIBER_EVENT, ConsumerPaymentMethod, PAYMENT_STATUS, USER_TYPES } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { CommonModelService } from '../../services/common-model.service';
import { CheckoutService } from '../checkout/checkout.service';
import { ConsumerSubscriptionService } from '../consumer-payment-section/consumer-subscription.service';
import { ConsumerPaymentService } from '../consumer-update-payment/consumer-payment-update.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
/**
 * Navbar component
 */
export class NavbarComponent implements OnInit, OnDestroy {
  public readonly DISABLE_MESSAGES: boolean = true;
  public profileMenuToggle: boolean;
  public notificationToggle: boolean;
  public responsiveMenuToggle: boolean;
  public currentPage: string;
  public currentUser: Object;
  public userType: string;
  public requestId: string;
  public imageSrc: string;
  public buttonClicked: boolean;
  public currentRoute: string;
  public isReceivedDv: boolean;
  public faBell = faBell;
  public faLogout = faRightFromBracket
  public faCheckCircle = faCheckCircle;
  public faCircleXmark = faCircleXmark;
  public notificationList: Array<any>;
  public userId: any;
  public agentTerm: string;
  public orgLogo: string;
  public orgLogoPath: string;
  // public hasIDVerified: boolean = false;
  @ViewChild('notification') notificationRef: ElementRef;
  @ViewChild('profileMenu') profileMenuRef: ElementRef;
  public canShowPaymentButton: boolean = false;
  public isPaymentComplete: boolean;// check payment
  public currentPaymentStatus: string;
  public isPaid: boolean;
  public isFirstMonth: boolean;
  public gotRefund: boolean;
  public isOnRefund: boolean;
  public isOnCancellationPeriod: boolean;
  public paymentStatus: any;
  public isTrialPeriod: boolean; // check trial period
  private paymentSubscription: Subscription;
  public isDemoEmail: boolean;
  public refundStatus: number;
  public isDeactivated: any;
  public cancellationCount: any;
  public refundAt: any;
  public paymentMode: ConsumerPaymentMethod;
  public isCobrandPaidByPro: boolean;
  public showDropdownItem: boolean;
  public slugDetails: any;
  public showPaymentOption: boolean;
  public isTrialPeriodForConsumerFromSlugUser:boolean;
  public professionalDetails:any;
  public proDetailsLoader:any;
  public canShowMessageMenu:boolean;


  /**
   * @constructor
   */
  constructor(
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private toastService: ToastrService,
    private paymentService: ConsumerPaymentService,
    private route: ActivatedRoute,
    private checkoutService: CheckoutService,
    private slugInterceptorService: SlugInterceptorService,
    private slugService: SlugService,
    private socketService: SocketService,
    private chatService : ChatService,
    private consumerSubscription: ConsumerSubscriptionService,
  ) {
    document.addEventListener('click', this.offClickHandler.bind(this));
  }


  /**
   *
   * @param{any} event
   */
  offClickHandler(event: any) {
    if (!this.notificationRef?.nativeElement.contains(event.target)) {
      this.notificationToggle = false;
    }
    if (!this.profileMenuRef?.nativeElement.contains(event.target)) {
      this.profileMenuToggle = false;
    }
  }


  /**
   * Called initially
   */
  ngOnInit(): void {
    this.paymentStatus = PAYMENT_STATUS;
    this.proDetailsLoader = true;
    this.chatService.professionalDetails().subscribe({
      next: (response) => {
        this.professionalDetails = response.data;
      },
      complete: ()=> {
        this.proDetailsLoader = false;
      }
    })
    this.isTrialPeriodForConsumerFromSlugUser = this.commonHelper.isTrialPeriodForConsumerFromSlugUser();
    this.canShowMessageMenu = Number(this.localStorageService.getDataByKey('admin_settings')?.message_menu) === 1;
    this.paymentMode = this.localStorageService.getDataByKey('payment_mode');
    this.isCobrandPaidByPro = (this.paymentMode === ConsumerPaymentMethod.PAID_BY_PROFESSIONAL);
    this.isPaid = (this.localStorageService.getDataByKey('is_payment_complete') === this.paymentStatus.paid);
    this.isFirstMonth = this.localStorageService.getDataByKey('is_first_month') === 1;
    this.gotRefund = (this.localStorageService.getDataByKey('gotRefund'))
    this.isOnRefund = (this.localStorageService.getDataByKey('is_payment_complete') === this.paymentStatus.onRefundPeriod);
    this.cancellationCount = this.localStorageService.getDataByKey('subscription_cancellation_count');
    this.isOnCancellationPeriod = (this.localStorageService.getDataByKey('cancellation_period') == 1);
    this.currentUser = this.localStorageService.getUserData();
    this.slugDetails = this.currentUser?.['slug_details'];
    this.showDropdownItem = this.isCobrandPaidByPro ? (!this.slugDetails) : true
    this.showPaymentOption = !(this.isCobrandPaidByPro && this.slugDetails) && !this.isPaid;
    this.isDemoEmail = this.localStorageService.getDataByKey('is-demo-email');
    this.canShowPaymentButton = this.currentUser?.['request']?.['request_stage'] >= 10;
    this.paymentSubscription = this.commonService.showPaymentButtonObservable.subscribe((response: boolean) => this.canShowPaymentButton = !!response);
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    // if admin_death_verify is 3, which means the admin approved users Deceased
    this.isDeactivated = this.currentUser?.['request']?.['admin_death_verify'] == 3
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      } else {
        this.orgLogoPath = '';
        this.orgLogo = '';
      }
    });
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.imageSrc = `${environment.BASE_URL_RAW}uploads/${this.currentUser?.['user']?.profile_photo}`;
    this.currentRoute = this.activatedRoute.snapshot.url[0]?.path;
    this.commonService.profilePicObserve.subscribe((data) => {
      if (!data) return;
      this.currentUser = this.localStorageService.getUserData();
      this.imageSrc = `${environment.BASE_URL_RAW}uploads/${this.currentUser?.['user']?.profile_photo}`;
    });

    this.userId = this.currentUser?.['user']?.id;
    this.userType = this.localStorageService.getDataByKey('role');
    this.requestId = this.route.snapshot.params['id'];
    this.profileMenuToggle = false;
    this.notificationToggle = false;
    this.responsiveMenuToggle = false;
    this.changeRouter();
    // this.dlbService.paymentStatusObserve.subscribe((response) => {
    this.isPaymentComplete = this.localStorageService.getDataByKey('is_payment_complete');
    // this.isReceivedDv = !!response;
    // });
    this.socketService.listenAppointmentStatus().subscribe(() => this.notificationMessage());

    // check ID Verification status
    // this.userService.userIdVerfiedObserve.subscribe(() => {
    //   this.hasIDVerified = this.localStorageService.getDataByKey('is_id_verified') == 1;
    // });
    // check payment status to enable/disable chat
    this.checkPaymentStatus();
    this.refundAt = this.currentUser?.['user']?.['payment_refund_at'];
  }

  /**
   * Change router url
   * @param {string} url
   */
  public changeRouter(url?: string): void {
    const hasSlug = !!this.activatedRoute.snapshot.params['slug'];
    this.currentPage = this.activatedRoute.snapshot.url[hasSlug ? 1 : 0]?.path;
    if (!this.currentUser?.['request']?.['request_stage'] && url === 'overview') {
      this.toastService.info('Data will be made available once a section is completed');
      void this.slugInterceptorService.navigate(['dashboard']);
      return;
    }
    if (!this.currentUser?.['request']?.['request_stage'] && url === 'people') {
      this.toastService.info('Data will be made available once people are added');
      return;
    }
    if (this.currentUser?.['request'] && this.currentUser?.['request']?.request_stage < 9 && (url === 'people' || url === 'overview')) {
      this.toastService.info('Data will be made available once people are added');
      return;
    }
    
    if((this.professionalDetails?.length <= 0 || this.proDetailsLoader) && url === '/message'){
      this.toastService.info('This feature will be made available once PSPs are added');
      return;
    }

    // Clear request id in overview
    if (url === 'overview') {
      this.commonService.commonSubscriberEvent({
        type: COMMON_SUBSCRIBER_EVENT.CLEAR_REQUEST_ID,
      });
    }
    if (url) {
      void this.slugInterceptorService.navigate([url]);
    }
  }


  /**
   * Logout user
   */
  public logout(): void {
    this.buttonClicked = true;
    this.commonHelper.logoutCommon(USER_TYPES.user);
  }


  /**
   * navigate to subscription plan
   * @param {string}route
   */
  public toSubscriptionPage(route: string) {
    void this.slugInterceptorService.navigate([route]);
    this.paymentService.paymentScreenObservableEvents1(false);
  }


  /**
   * Get notifications
   */
  public notificationMessage() {
    this.notificationList = [];
    this.commonService.getUsersAppointmentList().subscribe((response: APIResponseModel) => {
      if (response?.data?.length) {
        this.notificationList = response?.data;
      }
    });
  }


  /**
   * change route to schedule accept
   * @param{any}id
   */
  public acceptEvent(id: any) {
    this.navigate(`schedule-accept/${id}`);
  }


  /**
   * change route to schedule decline
   * @param{any}id
   */
  public declineEvent(id: any) {
    this.navigate(`schedule-decline/${id}`);
  }


  /**
   * change route
   * @param{string}url
   * @param{boolean}query
   */
  public navigate(url: string, query?: {}) {
    void this.slugInterceptorService.navigate([url], query ? { queryParams: query } : undefined);
  }


  /**
   * change route
   */
  public navigateToPro() {
    void this.slugInterceptorService.navigate(['dashboard'], null, true);
  }


  /**
   * Checks payment status
   */
  public checkPaymentStatus(): void {
    this.consumerSubscription.getPaymentStatus().subscribe((response) => {
      const today = new Date();
      const paymentEnabledDate = response?.data?.payment_enabled_date;
      // this.payment = this.localStorageService.getDataByKey('is_payment_complete');
      const freeTrialEnabledFromAdmin = response?.data?.consumer_form_access_type;
      this.cancellationCount = this.localStorageService.getDataByKey('subscription_cancellation_count');
      const freeTrailDays = response?.data?.free_trail_day;
      this.isPaid = response?.data?.is_payment_complete === this.paymentStatus.paid;
      this.isFirstMonth = response?.data?.is_first_month === 1;
      this.gotRefund = !!(response?.data?.payment_refund_at);
      this.isOnRefund = response?.data?.is_payment_complete === this.paymentStatus.onRefundPeriod;
      switch (freeTrialEnabledFromAdmin) {
        case 1:
          if (paymentEnabledDate) {
            const trialEndDate = this.incrementDate(new Date(paymentEnabledDate), Number(freeTrailDays));
            this.isTrialPeriod = trialEndDate >= today; // free trial days from admin, ensure that how long to use vault without initial pay
          }
          break;
        default:
          this.isTrialPeriod = false; // no trial period check
          break;
      }
    });
  }


  /**
   * Messages options are disabled if the vault is NOT paid for AND NOT in a trial period.
   */
  public get disableMessages(): boolean {
    return !this.isPaymentComplete && !this.isTrialPeriod;
  }


  /**
   * Adds date by passing param
   * @param date
   * @param [days]
   * @return date by passing param
   */
  public incrementDate(date: Date, days: number): any {
    const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
    return new Date(endDate);
  }


  /*
   * on destroy
   */
  public ngOnDestroy(): void {
    this.paymentSubscription?.unsubscribe();
  }


  showVideo() {
    this.modalService.open('navbar-video');
  }

  /**
   * proceed to payment section
   */
  processPayment(): void {
    this.checkoutService.proceedTosubscriptionPayment().subscribe(() => {
      void this.slugInterceptorService.navigate(['/checkout']);
    });
    this.modalService.close('payment-alert-freeTrial-completed');
  }

  /**
   * Determines whether to show the navigation menu based on various conditions.
   *
   * @returns {boolean}
   */
  public showNavMenu(): boolean {
    return !this.isDeactivated
    // if (this.isCobrandPaidByPro && this.slugDetails || this.isTrialPeriodForConsumerFromSlugUser) {
    //   return true;
    // } else {
    //   return true;
    //   if (this.isFirstMonth) {
    //     return ((this.isPaid || (!this.isPaid && this.cancellationCount > 1) || (this.cancellationCount === 0 && this.refundAt)) && this.isDeactivated !== 3);
    //   }
    //   return (this.isDeactivated !== 3);
    // }
  }

  /**
   * Determines whether the logo button should be shown.
   *
   * @returns {boolean}
   */
  public showLogoutBtn(): boolean {
    return this.isDeactivated
    // if ((this.isCobrandPaidByPro && this.slugDetails || this.isTrialPeriodForConsumerFromSlugUser)) {
    //   return false;
    // } else {
    //   if (this.isFirstMonth) {
    //     return ((!this.isPaid && this.cancellationCount < 1) || (this.isOnRefund && this.cancellationCount !== 0) || (this.isDeactivated === 3));
    //   }
    //   return (this.isDeactivated === 3);
    // }
  }
}
