import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-button-loader-two',
  templateUrl: './button-loader-two.component.html',
})
/**
 * button loader
 */
export class ButtonLoaderTwoComponent implements OnInit {
  @Input() buttonTitle:string;
  @Input() valid:boolean;
  @Input() loader:boolean;
  @Input() isButtonValid:boolean;
  @Input() buttonType:string;
  @Input() outlineOnly: boolean;
  @Input() btnTextSize: string;
  @Input() buttonClass: string;
  @Output() clickButton = new EventEmitter<any>();
  // public isButtonValid:boolean;
  /**
   * @constructor
   */
  constructor(private commonService:CommonService) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.isButtonValid=true;
    
  }

  /**
   * handle normal button click
   */
  public handleOnclick():void {
    this.clickButton.emit();
  }
}
