<!-- BEGIN: Navbar Wrapper -->
<app-consumer-landing-navbar></app-consumer-landing-navbar>
<!-- END: Navbar Wrapper -->
<main>

  <div class="p-12 lg:px-24 lg:py-32 container mx-auto">
    <div class="title__wrap text-center py-8">
      <h1 class="text-semibold text-accent fs-title pb-2">LegacyNOW </h1>
      <h2 class="text-semibold text-primary fs-title">Digital Vault Licensing Agreement</h2>
    </div>
    <div>

      <p class="text-content text-regular fs-content">
        This Digital Vault Licensing Agreement （this “Agreement”) is entered into on _____ , {{getCurrentYear()}}
        (“Effective Date”) by
        and between LegacyNOW, Inc. (the “Company” or “Licensor”) and [insert financial advisor/firm] (the “Financial
        Advisor’ or “Licensee”). For
        purpose of this Agreement, the Licensor and Licensee are hereinafter referred to collectively as the “Parties”
        or individually as a “Party”. </p>

      <h4 class="text-primary text-center text-semibold fs-subtitle my-6">
        RECITALS</h4>
      <p class="text-content text-regular fs-content">
        WHEREAS, the Licensor operates an online Digital Vault storage service Website which, together with the
        underlying Software, provides encrypted and securely stored digital information and materials submitted and
        stored by individuals in the United States.
        The primary purpose of the submittal and storage of digital information and materials on the Website is to
        assist and facilitate the resolution of the estate of the submitter of such information, by an executor,
        personal administrator,
        or other authorized person following the death of such individual. </p>
      <h4 class="text-primary text-left text-semibold fs-subtitle my-6">
        Scope </h4>
      <p class="text-content text-regular fs-content">
        We are focused on protecting your privacy and that of your estate. This commitment is reflected in our internal
        policies, safeguards, and practices to ensure compliance with relevant laws and regulations with respect to the
        information entrusted to us.
        This Privacy Policy provides an overview of our business in the United States and describes our approach to the
        collection, use, storage, transfer, and disclosure of the personal information provided to us, including
        information regarding
        yourself and your estate. We collect, use, store, transfer and disclose in connection with our Digital Vault
        Services and as otherwise described in this Privacy Policy.
      </p>
      <p class="text-content text-regular fs-content">
        WHEREAS, the Financial Advisor desires to market, offer and resell the Services to his/her clients in connection
        with in the provision of financial advisory and/or estate planning services, and Company is willing to grant to
        the Financial Advisor a non-exclusive
        license to market, offer and resell and promote the Services in accordance with the terms of this Agreement.
      </p>
      <p class="text-content text-regular fs-content">
        NOW AND THEREFORE, in consideration of the mutual promises, covenants, and conditions contained in this
        Agreement, the Parties agree as follows:

      </p>


      <h4 class="text-accent text-semibold fs-subtitle my-6">SECTION 1 : DEFINITIONS </h4>
      <p class="text-content text-regular fs-content pb-3">As used in this Agreement, the following terms shall have the
        following meanings:</p>
      <ul class="list-disc lg:pl-16">
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“LegacyNOW”</strong>, <strong>“Company”</strong>,
            <strong>“Licensor”</strong> refers to LegacyNOW (US), Inc.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Financial Advisor”</strong>,
            <strong>“License”</strong>refers to ________________ [name of the financial advisor/firm].
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">
            <strong>"Website”</strong> shall mean the underlying data processing program or micro program of the online
            Digital Vault consisting of a series or sequence of signals, or instructions, statements, or fonts stored on
            any media
            in machine readable form, and any related materials such as, but not limited to, flow charts, logic
            diagrams, manuals, and listing made generally available by the Licensor for use in connection with the
            online Digital Vault.
          </p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Digital Vault”</strong>means the secure vault
            facility operated by the Company through the Website.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Software”</strong>shall mean the underlying data
            processing program or micro program of the online Digital Vault consisting of a series or sequence of
            signals, or instructions, statements, or fonts stored on any media
            in machine readable form, and any related materials such as, but not limited to, flow charts, logic
            diagrams, manuals, and listing made generally available by the Licensor for use in connection with the
            online Digital Vault.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Services”</strong> means the Digital Vault
            storage service, the Website, the Software, and all functionalities related thereto.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Subscriber”</strong> refers to the client of the
            Financial Advisor in connection within the provision of financial advisory and/or estate planning.</p>
        </li>

        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Effective Date”</strong> means the date that
            this Agreement is fully executed by the Parties.</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3"><strong>“Proprietary Information”</strong> means
            information of any kind in written, documentary or other tangible form disclosed by the Licensor or the
            Financial Advisor to the other and marked by the disclosing party with a legend,
            stamp, label or other marking indicating its proprietary or confidential nature, or, if not in tangible
            form, is clearly identified as confidential at the time of the disclosure, including, but not limited to,
            (i) information
            of a business, planning, marketing or technical nature, (ii) models, tools, hardware and software, and
            (iii) any documents, reports, memoranda, notes, files or analyses prepared by or on behalf of the receiving
            party that contain,
            summarize or are based upon any of the foregoing. The Parties hereby agree that Proprietary Information
            shall not include information that (i) is publicly available prior to the date of this Agreement,
            (ii) becomes publicly
            available after the date of this Agreement through no wrongful act of the receiving Party, (iii) is
            furnished to others by the disclosing Party without similar restrictions on their right to use or disclose,
            (iv) is rightfully
            known by the receiving Party without any proprietary restrictions at the time of receipt of such information
            from the disclosing Party or becomes rightfully known to the receiving Party without proprietary
            restrictions from
            a source other than the disclosing Party or (v) is independently developed by the receiving Party by persons
            who did not have access, directly or indirectly, to the Proprietary Information.</p>
        </li>
      </ul>
      <h4 class="text-primary text-semibold fs-subtitle my-6">SECTION 2 : LICENSE</h4>
      <p class="text-content text-regular fs-content pb-3">2.1 <span class="underline">Non-Exclusive License</span>. The
        Licensor hereby grants to the Financial Advisor a non-exclusive license to market, promote, offer, resell, and
        use the Services to Subscribers in accordance with the terms of
        this Agreement. </p>
      <p class="text-content text-regular fs-content pb-3">2.2 <span class="underline">Financial Advisor’s
          Undertakings</span>. Except as expressly set out in this Agreement or as otherwise required by applicable
        mandatory law without the possibility of contractual waiver or limitation, the Financial
        Advisor agrees and undertakes:
      </p>
      <ol class="list-lower-roman">
        <li>
          <p class="text-content text-regular fs-content pb-3">not to copy the Services, except where such copying is
            incidental to normal use of the Services;</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">not to make any derivative works of the Services, nor
            adapt, vary, translate, modify, or make alterations to, the whole or any part of the Services nor permit the
            Services or any part of it to be combined with, or become incorporated
            in, any other services, websites, software or functionalities owned or operated by the Financial Advisor or
            any other third parties;</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">not to make any derivative works of the Services, nor
            adapt, vary, translate, modify, or make alterations to, the whole or any part of the Services nor permit the
            Services or any part of it to be combined with, or become incorporated
            in, any other services, websites, software or functionalities owned or operated by the Financial Advisor or
            any other third parties not to decompile, disassemble or reverse engineer the whole or any part of the
            Website
            or the
            Software except where required by the terms of this Agreement, or by applicable law without the possibility
            of contractual waiver;</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">not to license, sublicense, sell, resell, transfer,
            assign, distribute, rent, lease, loan or transfer the Services to any third party, or otherwise commercially
            exploit the Services or make the Services available to any third party
            in any manner, without the prior written consent of the Licensor;</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">to supervise and control use of the Services and ensure
            that the Services are used by the Financial Advisor’s employees in accordance with the terms of this
            Agreement; and</p>
        </li>
        <li>
          <p class="text-content text-regular fs-content pb-3">to include any copyright notice of the Licensor on all
            entire and partial copies of the Services in any form.</p>
        </li>
      </ol>


      <h4 class="text-primary text-semibold fs-subtitle my-6">SECTION 3 : TERM AND TERMINATION </h4>
      <p class="text-content text-regular fs-content pb-3">3.1 <span class="underline">Term</span>. Subject to the
        provisions of this Agreement, the Term of this Agreement shall commence on the Effective Date and continue for a
        period of three (3) years (the “<span class="underline">Initial Term</span>”).
        This Agreement may be renewed for further terms of three (3) years (the “<span class="underline">Renewal
          Term</span>”), provided that the either Party does not notify the other in writing of its desire to terminate
        this Agreement at
        least thirty (30) days prior to the expiration of the then current Term. For purposes of this Agreement the
        Initial Term and all Renewal Term(s) are collectively refer to as the “<span class="underline">Term</span>”.</p>

      <p class="text-content text-regular fs-content pb-3">3.2 <span class="underline">Termination</span>. The Licensor
        may terminate this Agreement upon written notice to the Financial Advisor in the event of the Financial
        Advisor’s (i) unauthorized use of the Services, (ii) failure to make timely
        payment to the Licensor, or (iii) should the Services become, or in the Licensor’s reasonable opinion likely to
        become, the subject of a claim of intellectual property infringement or trade secret misappropriation.
        Otherwise, either
        Party may terminate this Agreement immediately in the event the other Party commits a material breach of this
        Agreement and fails to remedy that breach within thirty (30) days of receipt of notice of material breach.</p>


      <p class="text-content text-regular fs-content pb-3">3.3 <span class="underline">Right to Contact
          Subscriber</span>. Upon termination of this Agreement for any reason, Financial Advisor acknowledges and
        agrees that the Licensor shall be entitled to contact each Subscriber, if any, for any
        commercial purpose including the continued provision of the Services to continuation of to any Subscriber. In
        such event the Financial Advisor agrees to cooperate with the Licensor and promptly provide Licensor with any
        information
        reasonably requested by Licensor to continue providing the Services to all Subscribers with minimal disruption.
      </p>


      <h4 class="text-primary text-semibold fs-subtitle my-6">SECTION 5 : RESPONSIBILITIES OF FINANCIAL ADVISOR</h4>
      <p class="text-content text-regular fs-content pb-3">5.1 <span class="underline">Promotion</span>. The Financial
        Advisor shall exercise its best commercial efforts to market, promote, solicit and resell the Services to
        prospective Subscribers. </p>
      <p class="text-content text-regular fs-content pb-3">5.2 <span class="underline">Contracts</span>. Any contracts
        in connection with the use and subscription of the Services shall be entered into between the Licensor and each
        of the Subscribers.
      </p>
      <p class="text-content text-regular fs-content pb-3">5.3 The Financial Advisor shall not make any representation,
        warranty, indemnity or similar claim concerning the operation, performance, reliability, redundancy, fitness for
        particular purpose or similar feature of the Services except
        as specifically set forth herein and as shall be consistent with any and all written documentation provided by
        the Licensor to the Financial Advisor, as such information may be revised from time to time by the Licensor.
      </p>
      <p class="text-content text-regular fs-content pb-3">5.4 The Financial Advisor acknowledges and understands that
        it shall bear all responsibility, risk and cost associated with developing and maintaining its business, and
        neither the Company nor its affiliates shall be liable to the Financial
        Advisor for any costs or damages caused by any failure or impaired performance of the Services or any component
        thereof.
      </p>

      <h4 class="text-primary text-semibold fs-subtitle my-6">SECTION 6: RESPONSIBILITIES OF THE LICENSOR</h4>
      <p class="text-content text-regular fs-content pb-3">6.1 <span class="underline">Access</span>. Subject to the
        provisions of this Agreement and the operational policies established by the Licensor in its sole discretion
        from time to time, Licensor shall provide access to and use of the Services
        to the Subscribers pursuant to the terms and conditions of this Agreement.</p>
      <p class="text-content text-regular fs-content pb-3">6.2 <span class="underline">Licenses and Permits</span>. The
        Licensor shall use commercially reasonable efforts to maintain, or to cause its affiliates to maintain, as the
        case may be, all permits required by the applicable governmental
        authority for the Licensor to provide the Services in the United States. </p>
      <p class="text-content text-regular fs-content pb-3">6.3 <span class="underline">Operation of the Digital
          Vault</span>. The Licensor shall maintain the Digital Vault in proper working order and shall use commercially
        reasonable efforts to minimize any downtime or service interruptions regarding
        the Digital Vault. </p>
      <p class="text-content text-regular fs-content pb-3">6.4 <span class="underline">Digital Vault Maintenance and
          Repairs</span>. The Licensor shall have the right to interrupt the Vault from time to time, without prior
        notice to the Financial Advisor, for the purpose of performing maintenance
        on or upgrading the Vault. Notwithstanding the foregoing, the Licensor shall notify the Financial Advisor no
        less than forty-eight (48) hours in advance of any scheduled interruptions of the Vault for the purpose of
        performing maintenance
        on or upgrading the Vault. </p>
      <p class="text-content text-regular fs-content pb-3">6.5 <span class="underline">Sales and Marketing
          Assistance</span>. The Licensor agrees to provide reasonable sales or technical support to the Financial
        Advisor with respect to the marketing/promoting of the Digital Vault to Subscribers
        (e.g., participating on sales teleconferences), provided such support does not interfere with the Licensor’s
        regular course of business. </p>




      <h4 class="text-primary text-semibold fs-subtitle my-6">SECTION 7 : INTELLECTUAL PROPERTY OWNERSHIP</h4>
      <p class="text-content text-regular fs-content pb-3">The Company and/or its affiliates alone owns all right, title
        and interest, including all related intellectual property rights, in and to, the Services and related
        documentation and derivative works. Any suggestions, ideas, enhancement
        requests, feedback, recommendations or other information provided by the Financial Advisor, Subscribers, or any
        other party relating to the Services shall also be owned by the Company.
      </p>
      <p class="text-content text-regular fs-content pb-3">The “LegacyNOW’ name, logo, and the product names associated
        with the Vault are trademarks of the Company and/or its affiliates, and no right or license is granted to use
        them without the Company’s express authorization. The Company reserves
        any and all intellectual property rights not expressly granted in this Agreement.
      </p>


      <h4 class="text-primary text-semibold fs-subtitle my-6">SECTION 8 :PROPRIETARY INFORMATION, NON-DISCLOSURE AND
        NON-SOLICITATION. </h4>
      <p class="text-content text-regular fs-content pb-3">8.1 <span class="underline">Non-Disclosure</span>. Except as
        provided for herein, from the Effective Date of this Agreement until three (3) years following the date on which
        the Financial Advisor ceases to be a Licensee of the Services,
        the receiving party shall protect all of the disclosing party’s Proprietary Information as confidential and
        proprietary information and, except with the prior written consent of the disclosing party or as otherwise
        specifically provided
        herein, shall not disclose, copy or distribute such Proprietary Information to any other individual, corporation
        or entity. It shall not be deemed a breach of this Agreement if the receiving party produces the Proprietary
        Information
        under order of a court of competent jurisdiction or a valid administrative, arbitral or congressional subpoena,
        provided that the receiving party promptly notifies the disclosing party of such event so that the disclosing
        party may
        seek an appropriate protective order.</p>
      <p class="text-content text-regular fs-content pb-3">8.2 <span class="underline">Degree of Care</span>. The
        receiving Party shall use the same degree of care to protect the confidentiality of the Proprietary Information
        disclosed to it as it uses to protect its own Proprietary Information,
        but in all events shall use at least a reasonable degree of care. Each Party represents that such degree of care
        provides adequate protection for its own proprietary information. The receiving Party shall immediately advise
        the disclosing
        party in writing of any misappropriation or misuse by any person of the disclosing Party’s Proprietary
        Information of which the receiving Party is aware.</p>
      <p class="text-content text-regular fs-content pb-3">8.3 <span class="underline">Return of Proprietary
          Information</span>. All Proprietary Information that is furnished by or on behalf of the disclosing party,
        including, without limitation, any copies of such materials, shall be promptly
        returned by the receiving Party to the disclosing party upon written request by the disclosing parry for any
        reason. Any documents or materials prepared by or on behalf of the receiving Party (including, without
        limitation, reports,
        memoranda, notes, files or analyses, whether in written or electronic form) which contain Proprietary
        Information, including all copies, shall promptly be destroyed by the receiving Party upon written request by
        the disclosing party
        for any reason. Such destruction shall be certified by an officer of the receiving Party. </p>
      <p class="text-content text-regular fs-content pb-3">8.4 <span class="underline">No License or Warranties</span>.
        Except as set forth in this Agreement, no license to the receiving Party under any trade secrets or patents is
        granted or implied by conveying Proprietary Information or other
        information to such Party, and none of the information transmitted or exchanged shall constitute any
        representation, warranty, assurance, guaranty or inducement with respect to the infringement of patents or other
        rights of others.
        In addition, the disclosure of Proprietary Information by the disclosing Party shall not constitute or include
        any representation or warranty as to the accuracy or completeness of such information.</p>


      <h4 class="text-primary text-semibold fs-subtitle my-6">SECTION 9: REPRESENTATIONS, WARRANTIES AND COVENANTS </h4>
      <p class="text-content text-regular fs-content pb-3">9.1 <span class="underline">Representations and Warranties of
          the Financial Advisor</span>. The Financial Advisor represents and warrants to the Licensor that (i) it has
        the legal power and authority to enter into this Agreement; (ii)
        this Agreement has been duly executed and delivered by the Financial Advisor and constitutes a legally valid and
        binding obligation of the Financial Advisor, enforceable against the Financial Advisor in accordance with its
        terms; (iii)
        the Financial Advisor's use of the Services as contemplated by this Agreement does not and will not constitute a
        default, breach or violation of the charter or by-laws of the Financial Advisor, any statute, law, rule, or
        decree of
        any court or legislative or governmental agency applicable to the Financial Advisor, or under any contract,
        agreement, instrument, or document binding on or applicable to the Financial Advisor; (iv) during the Term, the
        Financial Advisor
        shall maintain its professional qualification as required for performing its financial advisory and/or estate
        planning services; and (iv) the Financial Advisor shall not violate any copyright, trade secret, trademark,
        patent, invention,
        proprietary information, privacy, non-disclosure or any other statutory or common law rights of any third party
        in the performance of its obligations under this Agreement.</p>
      <p class="text-content text-regular fs-content pb-3">9.2 <span class="underline">Representations and Warranties of
          the Licensor</span>. The Licensor represents and warrants to the Financial Advisor that (i) the Licensor is
        duly organized or formed, validly existing and in good standing under
        the laws of the state of its organization or formation, as the case may be, and in each jurisdiction in which
        the nature of its business requires it to be so, (ii) the execution, delivery and performance of this Agreement
        by the Licensor
        have been duly authorized by all necessary action (corporate or otherwise) on the part of the Licensor,
        (iii) this Agreement has been duly executed and delivered by the Licensor and constitutes a legally valid and
        binding obligation
        of the Licensor, enforceable against the Licensor in accordance with its terms, and (iv) the Licensor or its
        affiliates have all Permits necessary for the Licensor to enter into this Agreement and perform its obligations
        in accordance
        with the terms hereof.</p>
      <p class="text-content text-regular fs-content pb-3">9.3 <span class="underline">Indemnification</span>. The
        Company and the Financial Advisor agree to indemnify and hold harmless the other and the their respective
        stockholders, members, officers, directors, employees, {{agentTerm}}s and representatives
        against all claims, demands, losses, costs or liabilities (including reasonable attorneys’ fees and costs)
        arising from or in connection with their respective breach of any representations, warranties, covenants or
        agreements contained
        herein.
      </p>
      <p class="text-content text-regular fs-content pb-3">Subject to the Limitation of Liability set forth in Section
        9.2, the Financial Advisor agrees to indemnify the Licensor from and against any and all losses, liabilities,
        damages, costs, expenses, actions and claims of whatever nature or
        kind arising out of or in connection with its access to and use of the Services.</p>


      <h4 class="text-primary text-semibold fs-subtitle my-6">SECTION 10 : DISCLAIMER OF WARRANTIES AND LIMITATION OF
        LIABILITY</h4>
      <p class="text-content text-regular fs-content pb-3 uppercase">10.1 <span class="underline">Disclaimer of
          Warranties</span>. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NONE OF THE COMPANY OR ANY OF ITS
        AFFILIATES HAS MADE OR SHALL BE DEEMED TO HAVE MADE ANY REPRESENTATIONS OR WARRANTIES WHATSOEVER
        WITH RESPECT TO THE VAULT OR ITS RELATED SERVICES. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE
        COMPANY AND ITS AFFILIATES EXPRESSLY DISCLAIM WITH RESPECT TO THE FINANCIAL ADVISOR AND THE SUBSCRIBERS, AND THE
        FINANCIAL ADVISOR
        EXPRESSLY WAIVES, RELEASES AND RENOUNCES ALL WARRANTIES OF THE COMPANY AND ITS AFFILIATES ARISING AT LAW, EQUITY
        OR OTHERWISE, WITH RESPECT TO, INCLUDING, BUT NOT LIMITED TO: (A) ANY IMPLIED WARRANTY OF MERCHANTABILITY OR
        FITNESS FOR
        A PARTICULAR PURPOSE; (B) ANY IMPLIED WARRANTY ARISING FROM COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE OF
        TRADE; (C) ANY WARRANTIES AS TO THE ACCURACY, AVAILABILITY OR CONTENT OF THE VAULT OR ITS RELATED SERVICES; AND
        (D) ANY
        WARRANTY UNDER ANY THEORY OF LAW OR EQUITY, INCLUDING ANY TORT, NEGLIGENCE, STRICT LIABILITY, CONTRACT OR OTHER
        LEGAL OR EQUITABLE THEORY. NO REPRESENTATION OR OTHER AFFIRMATION OF FACT, INCLUDING, BUT NOT LIMITED TO,
        STATEMENTS REGARDING
        CAPACITY OR SUITABILITY FOR USE, THAT IS NOT CONTAINED IN THIS AGREEMENT SHALL BE DEEMED TO BE A WARRANTY BY THE
        COMPANY.</p>
      <p class="text-content text-regular fs-content uppercase pb-3">10.2 <span class="underline">Limitation of
          Liability </span>THE VAULT AND ITS RELATED SERVICES ARE PROVIDED TO THE FINANCIAL ADVISOR AND THE SUBSCRIBERS
        STRICTLY ON AN "AS IS" BASIS. THE LICENSOR MAKES NO REPRESENTATION, WARRANTY, OR GUARANTY
        AS TO THE QUALITY, AVAILABILITY, ACCURACY OR COMPLETENESS OF THE VAULT AND ITS RELATED SERVICES. THE LICENSOR
        DOES NOT REPRESENT OR WARRANT THAT (A) THE USE OF THE VAULT AND ITS RELATED SERVICES WILL BE UNINTERRUPTED OR
        ERROR FREE
        OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA, (B) THE VAULT AND ITS RELATED
        SERVICES WILL MEET THE FINANCIAL ADVISOR’S REQUIREMENTS OR EXPECTATIONS, (C) THE QUALITY OF ANY PRODUCTS,
        SERVICES, INFORMATION,
        OR OTHER MATERIAL PURCHASED OR OBTAINED BY ANY SUBSCRIBERS THROUGH THE VAULT WILL MEET THE SUBSCRIBERS’
        REQUIREMENTS OR EXPECTATIONS. REPRESENTATIONS AND WARRANTIES OR, (D) ERRORS OR DEFECTS WILL BE CORRECTED.
        REPRESENTATIONS AND WARRANTIES
        WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, INFRINGEMENT OF THIRD-PARTY RIGHTS ARE HEREBY DISCLAIMED TO
        THE MAXIMUM EXTENT
        PERMITTED BY APPLICABLE LAW BY THE LICENSOR.</p>
      <p class="text-content text-regular fs-content uppercase pb-3">10.3 <span
          class="underline">Indemnification</span>.UNDER NO CIRCUMSTANCES SHALL THE COMPANY OR ITS AFFILIATES BE LIABLE
        TO THE FINANCIAL ADVISOR FOR INCIDENTAL, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING LOST
        PROFITS,
        LOSS OF BUSINESS OR LOSS OF GOODWILL, LOSS OF USE OF EQUIPMENT OR SERVICES, OR DAMAGES TO BUSINESS OR
        REPUTATION, WHETHER FORESEEABLE OR NOT ARISING FROM THE PERFORMANCE OR NON-PERFORMANCE BY THE COMPANY OF ANY
        ASPECT OF THIS AGREEMENT
        WHETHER IN CONTRACT OR TORT OR OTHERWISE, AND WHETHER THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
        PROVIDED THAT THIS LIMITATION SHALL NOT APPLY TO THIRD PARTY CLAIMS FOR SUCH DAMAGE THAT ARE SUBJECT TO
        INDEMNITY OBLIGATION
        HEREIN.
      </p>

      <h4 class="text-primary text-semibold fs-subtitle my-6">SECTION 11: MISCELLANEOUS</h4>
      <p class="text-content text-regular fs-content pb-3">11.1 <span class="underline"> Governing Law.</span> This
        Agreement will be governed and construed in accordance with the laws of the State of Delaware, United States of
        America. Both Parties agree to submit to exclusive venue and jurisdiction
        in Delaware and further agree that any cause of action or dispute arising under this Agreement will be litigated
        or arbitrated in the federal courts of the State of Delaware United States of America.</p>
      <p class="text-content text-regular fs-content pb-3">11.2 <span class="underline">Severability and Waiver</span>.
        If any provision of this Agreement is held invalid or unenforceable for any reason, the remaining provisions
        will continue in full force without being impaired or invalidated
        in any way. The waiver by either party of a breach of any provision of this Agreement will not operate or be
        interpreted as a waiver of any other or subsequent breach.</p>
      <p class="text-content text-regular fs-content pb-3">11.3 <span class="underline">Successors and
          Assignment</span>. This Agreement will be binding and will inure to the benefit of the Parties and their
        respective representatives, successors and assigns except as specifically prohibited.
        Notwithstanding the foregoing, this Agreement and the licenses granted may not be assigned, sublicensed or
        otherwise transferred by the Financial Advisor without the prior written consent of the Licensor which may be
        withheld for any
        reason.
      </p>
      <p class="text-content text-regular fs-content pb-3">11.4 <span class="underline">Relationship of
          Parties</span>.No agency, partnership, joint venture, or employment relationship is created by this Agreement
        and neither Party has the power to bind the other Party.</p>

      <p class="text-content text-regular fs-content pb-3">11.5 <span class="underline">Notices</span>.Any notice or
        other communication required or permitted to be made or given by either party pursuant to this Agreement will be
        in writing, and will be deemed to have been duly given: (i) three
        (3) Days after the date of mailing if sent by certified mail, postage prepaid, with return receipt requested;
        (ii) when delivered, if delivered personally or sent by express courier service; (iii) by email, if receipt is
        confirmed
        by return of email. All notices will be sent to the other Party at its address set forth as follows: </p>
      <div class="md:flex">
        <h4 class="text-accent text-regular fs-content md:w-1/6">Licensor: </h4>
        <div class="ml-4">
          <p class="text-content text-regular fs-content"> _______________</p>

          <p class="text-content text-regular fs-content">___________________</p>
          <p class="text-content text-regular fs-content"> _______________ (Attn)</p>
          <p class="text-content text-regular fs-content">_______________ (Telephone) </p>
          <p class="text-content text-regular fs-content">_______________ (Email)</p>
        </div>
      </div>



      <div class="md:flex mt-8">
        <h4 class="text-accent text-regular fs-content md:w-1/6"> Financial Advisor: </h4>
        <div class="ml-4">
          <p class="text-content text-regular fs-content"> _______________</p>
          <p class="text-content text-regular fs-content"> _______________</p>
          <p class="text-content text-regular fs-content"> _______________</p>
          <p class="text-content text-regular fs-content">_______________ (Telephone) </p>
          <p class="text-content text-regular fs-content">_______________ (Email)</p>
        </div>
      </div>
      <p class="text-content text-regular fs-content pb-3">11.5 <span class="underline"> Entire Agreement,
          Amendment</span>This Agreement and all attachments (which are hereby made part of this Agreement) contain the
        entire understanding between the Financial Advisor and the Licensor and supersede
        all prior written and oral understandings relating to the subject matter hereof (including any prior
        non-disclosure or confidentiality agreement signed by the parties). No representations, warranties, indemnities,
        agreements or understandings
        not contained herein shall be valid or effective unless agreed to in writing and signed by both parties. Any
        modification or amendment of this Agreement must be in writing and signed by both parties. </p>

      <p class="text-content text-regular fs-content pb-3"> IN WITNESS WHEREOF, the Parties have caused this Agreement
        to be executed as of the day and year first above written. </p>

      <p class="text-content text-regular fs-content pb-3"> LegacyNOW (PSE)</p>

      <p class="text-content text-regular fs-content pb-3"> By: ________________________</p>
      <p class="text-content text-regular fs-content pb-3">  Name: </p>
      <p class="text-content text-regular fs-content pb-3"> Title: </p>



      <p class="text-content text-regular fs-content pb-3"> Financial Advisor</p>

      <p class="text-content text-regular fs-content pb-3"> ___________________</p>
      <p class="text-content text-regular fs-content pb-3"> Name: </p>

    </div>
  </div>
</main>
<app-consumer-landing-footer></app-consumer-landing-footer>
