import { Component, HostListener, OnInit } from '@angular/core';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { USER_TYPES } from 'src/constants/application.const';
import { CheckoutService } from '../checkout/checkout.service';
import { PaymentOption } from '../checkout/payment-option.model';


@Component({
  selector: 'app-digital-vault',
  templateUrl: './digital-vault.component.html',
  styleUrls: ['./digital-vault.component.css'],
})
export class DigitalVaultComponent implements OnInit {
  userType: string;
  isConsentChecked: boolean = false;
  isPaymentClicked: boolean = false;
  mobile: boolean = false;
  paymentOptions: PaymentOption[];
  paymentCompleteStatus: boolean;

  constructor(
    private localStorageService: LocalStorageService,
    private modalService: CommonModelService,
    private slugInterceptorService: SlugInterceptorService,
    private sessionStorageService: SessionStorageService,
    private checkoutService: CheckoutService) {
    this.paymentCompleteStatus = this.localStorageService.getDataByKey('is_payment_complete') == 1;
  }

  ngOnInit(): void {
    const currentUserSession = this.sessionStorageService.getDataFromSession(USER_TYPES.user);
    const executorUser = this.localStorageService.getUserData(USER_TYPES.exe);

    this.userType = this.localStorageService.getDataByKey('role');
    // If a user opens a new tab and enters a URL, they should be redirected to the login page.
    if (!this.userType || (!currentUserSession && !executorUser)) {
      void this.slugInterceptorService.navigate(['/login']);
    }
    if (this.paymentCompleteStatus) {
      void this.slugInterceptorService.navigate(['/dashboard']);
    }
    this.localStorageService.storeData('DigitalVaultAgreementChecked', false);
    this.isConsentChecked = false;
    this.onResize();
  }

  /**
   * after view checked
   */
  ngAfterViewChecked() {
    // close payment alert window
    this.modalService.close('payment-alert-freeTrial-completed');
    this.modalService.close('payment-alert-default-unclosable');
  }

  /**
   * Redirects the user to the appropriate payment page based on their userType and checkbox status.
   * If the userType is 'Custodian' and the checkbox is checked, navigates to '/executor-custodian/payment'.
   * If the checkbox is checked for any other userType, navigates to '/payment'.
   * Stores the information that the user has clicked in the local storage.
   */
  public clickProceedToPayment() {
    this.isPaymentClicked = true;
    if (!this.isConsentChecked) return;
    if (this.userType === 'Custodian') {
      this.localStorageService.storeData('DigitalVaultAgreementChecked', true);
      void this.slugInterceptorService.navigate(['/executor-custodian/payment']);
    } else {
      this.localStorageService.storeData('DigitalVaultAgreementChecked', true);
      this.checkoutService.getPaymentOptions().subscribe({
        next: r => this.paymentOptions = r,
        complete: () => this.setSubscriptionSelected(),
      });
      void this.slugInterceptorService.navigate(['/checkout']);
    }
  }

  /**
   * select month subscription option to pay
   */
  private setSubscriptionSelected(): void {
    this.paymentOptions.find(item => item.id === 'subscription_monthly').selected = true;
    const ids: string[] = [];
    this.paymentOptions.forEach(e => e.selected && ids.push(e.id));
    this.checkoutService.setCart(ids);
  }

  /**
   * window resize
   */
  @HostListener('window:resize')
  onResize() {
    this.mobile = window.innerWidth <= 767;
  }

  public checkValue() {
    if (this.isConsentChecked) {
      this.localStorageService.storeData('DigitalVaultAgreementChecked', false);
    }
  }
}
