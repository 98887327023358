import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-professional-client',
  templateUrl: './edit-professional-client.component.html',
  styleUrls: ['./edit-professional-client.component.css']
})
export class EditProfessionalClientComponent   {

  
}
