import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {APIResponseModel} from '../interface/response.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * consumer landing page service
 */
export class ConsumerLandingPageService {
  public BASE_URL:string;
  private resetForm: any;
  public resetFormObserve: Observable<any>;
  /**
   * @constructor
   */
  constructor(
    private http: HttpClient,
  ) {
    this.BASE_URL = environment.BASE_URL;

    // for resetting vehicle form
    this.resetForm = new BehaviorSubject(false);
    this.resetFormObserve = this.resetForm.asObservable();
  }

  /**
   * Clear reset vehicle form
   *
   * @param {boolean} trigger
   */
  public resetFormTrigger(trigger: boolean): void {
    this.resetForm.next(trigger);
  }
  /**
   * get types
   * @return {Observable<APIResponseModel>}
   */
  public getContactUsTypes():Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/contact-us-type-list`);
  }
  /**
   * send contact us form data
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public sendContactUsData(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/contact-us`, data);
  }
}
