<div class="lg:grid grid-cols-12 background-color-grey">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center my-4">
              <div class="col-span-1">
                <div class="w-16 my-2">
                  <img src="assets/images/executors/executors.svg" alt="digital vault">
                </div>
              </div>


              <div class="col-span-11">
                <div class="pl-4 sm:px-4 ml-8">
                  <h2 class="text-darkblue text-xl break-words sm:text-3xl uppercase text-semibold">
                    Vault Custodian
                  </h2>
                  <!--p class="text-sm text-grey tracking-wide text-regular">This bit of information is important, as
                    it helps to make it clear that this is genuinely you. Double-check your spelling and details
                    to be safe.
                  </p-->
                </div>
              </div>

            </div>
          </div>
          <!-- BEGIN: Leftside Card -->
          <div class="border-card bg-white rounded-md">
            <!-- BEGIN: Progress Bar -->
            <app-card-progress-bar [progressValue]="'w-1/6'"></app-card-progress-bar>
            <!-- END: Progress Bar -->

            <div class="p-6">
              <div class="grid grid-cols-12 items-center gap-4 row-gap-4">
              <div class="col-span-12 sm:col-span-8">
                <div>
                  <app-shimmer-loading [loading]="loading" class="w-full h-12">
                    <p class="text-grey text-regular pt-1 text-sm text-justify">
                    <fa-icon *ngIf="accessVaultToCustodian==1" [icon]="faCheckCircle" class="green-tick"></fa-icon>
                    <fa-icon *ngIf="accessVaultToCustodian!==1" [icon]="faCircleXmark" class="red-tick"></fa-icon>
                    Would you prefer to grant access to the vault for your designated Custodian?
                  </p>
                  </app-shimmer-loading>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-4">
                <app-shimmer-loading [loading]="loading" class="w-full h-12">
                  <app-toggle-switch [defaultSelected]="accessVaultToCustodian" [options]="toggleOptions"
                                     (optionChangeEmitter)="toggleAccessVaultToCustodian($event)">
                  </app-toggle-switch>
                </app-shimmer-loading>
              </div>
            </div>
            <hr class="w-full h-4 mt-4">

              <div class="mb-4">
                <div class="grid grid-cols-12" *ngIf="accessVaultToCustodian">
                  <div class="col-span-12 sm:col-span-9 items-start">
                    <p class="text-regular text-sm text-grey font-semibold tracking-wide">
                      Select the access permissions for data to be displayed to your custodian and ensure a secure and personalized vault experience.
                    </p>
                  </div>
                  <div class="col-span-12 sm:col-span-3">
                    <button (click)="toggleACLModal()"
                      class="float-right flex items-center bg-darkblue button button--md flex focus:outline-none items-center justify-center md:w-auto p-2 rounded-xs text-regular text-white w-full">
                      Access Control
                      <div class="flex items-center justify-center w-5 ml-1">
                        <img class="cursor-pointer filter-white w-full h-full "
                          [ngClass]="(spinner) ? 'animate-spin' : ''" src="assets/icons/settings-cog.svg" alt="">
                      </div>
                    </button>
                </div>
                </div>
                <hr class="w-full h-4 mt-4" *ngIf="accessVaultToCustodian">
                <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                  Choosing a Digital Vault Custodian
                </h4>
                <p class="text-grey text-regular pt-2 text-sm tracking-wide">
                  You have not elected for LegacyNOW to notify your executor of your digital vault. It is critical that
                  LegacyNOW is made aware of your passing, when it occurs, so we can execute your wishes. Please select
                  a person to act as Custodian of your Digital Vault, who will be responsible for informing LegacyNOW of
                  your passing. This person will not have access to your data, and will only be responsible for
                  notifying us of your death.
                </p>
              </div>
              <div class="grid grid-cols-12 items-center gap-4 row-gap-4">
                <div class="col-span-12 sm:col-span-8">
                  <div>
                    <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                      Digital Vault Custodian Permission
                    </h4>
                    <div class="text-grey text-regular pt-1 text-sm text-justify ml-4" *ngIf="loading">
                      <p *ngFor="let _ of [].constructor(5)">
                        <app-shimmer-loading [loading]='true' [width]="'75%'" [height]="'12px'"></app-shimmer-loading>
                      </p>
                    </div>
                    <p class="text-grey text-regular pt-1 text-sm text-justify" *ngIf="!loading">
                      <fa-icon *ngIf="permissionStatus==1" [icon]="faCheckCircle" class="green-tick"></fa-icon>
                      <fa-icon *ngIf="permissionStatus==0" [icon]="faCircleXmark" class="red-tick"></fa-icon>
                      I hereby grant LegacyNOW the permission to inform my Digital Vault Custodian
                      upon completion of my Digital Vault Account setup. I hereby grant permission
                      for my Digital Vault Custodian(s), UPON MY DEATH, to contact the Executor(s)
                      and/or Authorized Representative(s) regarding the existence of my Digital Vault
                      Account and the related services and functions of the Digital Vault. This will
                      trigger a notification to your Executor and grant them access to the Digital Vault
                      according to the Digital Vault Agreement.
                    </p>
                  </div>
                </div>

                <div class="col-span-12 sm:col-span-4">
                  <app-shimmer-loading [loading]="loading" class="w-full h-12">
                    <app-toggle-switch [defaultSelected]="permissionStatus" [options]="toggleOptions"
                      (optionChangeEmitter)="onChangeToggle($event)">
                    </app-toggle-switch>
                  </app-shimmer-loading>
                </div>
              </div>
              <div class="col-span-12 mt-4" *ngIf="!permissionStatus">
                <app-shimmer-loading [loading]="loading" class="w-full h-12">
                  <h5 class="text-regular  text-darkblue font-semibold tracking-wide">
                    Please Note:
                  </h5>
                  <p class="text-grey text-regular pt-1 text-sm text-justify">
                    You have selected not to notify anyone of your LegacyNOW Digital Vault; therefore, your information
                    can not be shared by anyone other than you. If in the future you wish to elect to enter an Executor
                    or Digital Vault Custodian to have access to this information after you pass please update this
                    section.
                  </p>
                </app-shimmer-loading>
              </div>

              <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 mb-2">
                <a *ngIf="this.userType === 'Consumer'"
                  (click)="enableExecutor? changeRoute('executor-beneficiary/manage-executors'):changeRoute('executor-beneficiary/info')"
                  class="cursor-pointer   text-sm mt-2 sm:mt-0 text-back text-regular font-semibold tracking-wide flex justify-between">
                  <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                </a>
                <a *ngIf="this.userType !== 'Consumer'"
                  (click)="enableExecutor? changeRoute('executor-beneficiary/manage-executors/'+requestId):changeRoute('executor-beneficiary/info/'+requestId)"
                  class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                  <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                </a>
                <app-button-loader *ngIf="this.userType === 'Consumer'" [buttonText]="'Next'" [valid]="!loading"
                  [loader]="false" (click)="changeRoute('executor-beneficiary/manage-custodian')"
                  [buttonType]="'button'">
                </app-button-loader>
                <app-button-loader *ngIf="this.userType !== 'Consumer'" [buttonText]="'Next'" [valid]="!loading"
                  [loader]="false" [buttonType]="'button'"
                  (click)="changeRoute('executor-beneficiary/manage-custodian/'+requestId)">
                </app-button-loader>
              </div>
            </div>
            <!-- END: Leftside Card -->
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class="col-span-12 md:col-span-6 lg:grid justify-center">
    <about-yourself-right-content [formInputValue]="{}"
      [otherValues]="sectionSaveExitOptions "></about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->

</div>
<app-add-advisor-modal (newAdvisorDetails)="onGetCustodian($event)"></app-add-advisor-modal>
<!-- BEGIN: AccessControl -->
<app-access-control-modal [advisorData]="data"
[description]="DVC_DESCRIPTION_TEXT"></app-access-control-modal>
<!-- END: AccessControl -->
