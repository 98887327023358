import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';

import { requirePasswordToBeCheckedValidator } from 'src/app/helper/customValidatorPassword.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonService } from 'src/app/services/common.service';
import { PeopleService } from 'src/app/services/people.service';
import { SlugService } from 'src/app/services/slug.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { ProfessionalUserService } from '../../../services/professional-user.service';


@Component({
  selector: 'app-confirm-account-professional',
  templateUrl: './confirm-account-professional.component.html',
  styleUrls: ['./confirm-account-professional.component.css'],
})
/**
 * confirm and verify account - professional
 */
export class ConfirmAccountProfessionalComponent implements OnInit {
  public proTypeText: string;
  public professionalTypeId: string;
  public passwordForm: FormGroup;
  public readonly faEye = faEye;
  public readonly faEyeSlash = faEyeSlash;
  public togglePasswordConfirmView = false;
  public togglePasswordView = false;
  public submitLoader: boolean;
  public spinner: boolean = true;
  public orgLogo: string;
  public orgLogoPath: string;
  public menuActive: string;

  /**
   * @constructor
   */
  constructor(
    private activeRouter: ActivatedRoute,
    private toastService: ToastrService,
    private professionalUserService: ProfessionalUserService,
    private commonHelper: CommonHelper,
    private formBuilder: FormBuilder,
    private slugService: SlugService,
    private slugInterceptorService: SlugInterceptorService,
    private commonService: CommonService,
    private peopleService: PeopleService,
    private localStorageService: LocalStorageService,
  ) {
  }

  /**
   * form object getter for validation and showing errors in html
   */
  public get formGet() {
    return this.passwordForm.controls;
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.proTypeText = this.localStorageService.getDataByKey('pro-type')?.[0]?.['displayText'];
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      this.orgLogoPath = response ? this.commonHelper.getOrgLogo() : '';
      this.orgLogo = response ? `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}` : '';
    });
    // Get token from url
    const queryParams = this.activeRouter.snapshot.queryParamMap;
    this.passwordForm = this.formBuilder.group({
      'token': [(queryParams.get('token') || ''), [Validators.required]],
      'password': ['',{ updateOn: 'change', validators: [Validators.required, Validators.minLength(8), requirePasswordToBeCheckedValidator()] }],
      'password_confirmation': ['', { updateOn: 'change', validators: [Validators.required, Validators.minLength(8)] }], }, {
      validator: this.ConfirmedValidator('password', 'password_confirmation'),
    });
    const token = queryParams.get('token');
    this.professionalTypeId = queryParams.get('professionalTypeId');
    if (!token) {
      this.toastService.error('Invalid token, redirecting to Login');
      setTimeout(() => {
        void this.slugInterceptorService.navigate(['login'], null, true);
        this.spinner = false;
      }, 3000);
      return;
    }
    const payLoad = { token: token };
    this.localStorageService.clearAllLocalStorage();
    this.professionalUserService.checkEmailVerified(payLoad).subscribe((emailVerifyResponse: APIResponseModel) => {
      if (emailVerifyResponse) {
        this.spinner = false;
        this.setProType(emailVerifyResponse);
      }
    });
  }

  /**
   *
   * Password confirmation
   * @param {string} newpassword
   * @param {string} confirmpassword
   * @return {FormGroup}
   */
  public ConfirmedValidator(newpassword: string, confirmpassword: string): Function {
    return (formGroup: FormGroup) => {
      const currentPassword = formGroup.controls[newpassword];
      const confirmPassword = formGroup.controls[confirmpassword];
      if (currentPassword.errors && !confirmPassword.errors.confirmedValidator) {
        return;
      }
      if (currentPassword.value !== confirmPassword.value) {
        confirmPassword.setErrors({ confirmedValidator: true });
      } else {
        confirmPassword.setErrors(null);
      }
    };
  }

  /**
   * Reset password
   */
  public confirmAccount(): void {
    this.submitLoader = true;
    this.spinner = true;
    const payLoad = {
      token: this.passwordForm.value.token,
      password: this.passwordForm.value.password,
      confirm_password: this.passwordForm.value.password_confirmation,
    };
    this.professionalUserService.confirmUserAccount(payLoad).subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.toastService.success(response?.message);
        void this.slugInterceptorService.navigate(['login'], null, true);
      }
    }, (exception) => {
      this.commonHelper.httpResponseHandler(exception?.error);
      void this.slugInterceptorService.navigate(['page-not-found']);
    }, () => {
      this.spinner = false;
      this.submitLoader = false;
    });
  }

  /**
   * @param{string}url
   * on menu change
   */
  public menuChange(url?: string) {
    this.menuActive = url;
    void this.slugInterceptorService.navigate(url ? [url] : ['/'], null, true);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }

  /**
   * Retrieves advisor types from the people service based on the professional type ID obtained from the query parameter.
   * Stores the selected professional type in local storage and handles the email verification response.
   * @param emailVerifyResponse The response from email verification.
   */
  public setProType(emailVerifyResponse: any) {
    this.peopleService.getAdvisorTypes()?.subscribe((proTypeResponse: APIResponseModel) => {
      if (proTypeResponse.status) {
        const proTypes = this.commonHelper.convertToOptionsFormat(proTypeResponse?.data, 'id', 'name');
        const selectedProType = proTypes.find(proType => proType.additionalDetails.id === this.professionalTypeId);
        this.localStorageService.storeData('pro-type', [selectedProType]);
        this.emailVerifyResponseHandler(emailVerifyResponse);
      }
    });
  }

  /**
   * Handles the response from email verification and displays corresponding toast messages.
   * Navigates to the login page if the email is already verified; otherwise, prompts to set a password.
   * @param emailVerifyResponse The response from email verification.
   */
  public emailVerifyResponseHandler(emailVerifyResponse: any) {
    if (emailVerifyResponse?.status) {
      this.toastService.success('Your Email has been already verified');
      void this.slugInterceptorService.navigate(['login'], null, true);
    } else {
      this.toastService.success('Your email has been verified successfully. Kindly set your password');
    }
  }
}
