<ng-container *ngIf="loader.check(); else content">
  <div class="center-screen">
    <div class="loading-red-free m-auto h-24 w-24"></div>
  </div>
</ng-container>
<ng-template #content>
  <div class="scroll-hidden background-color-grey" *ngIf="!showCardScreen">
    <app-navbar *ngIf="userType === 'Consumer'"></app-navbar>
    <app-professional-navbar *ngIf="userType === 'Professional' || userType === 'Advisor'"></app-professional-navbar>
    <app-exe-cus-navbar *ngIf="userType === 'Executor' || userType === 'Custodian'"></app-exe-cus-navbar>
    <div *ngIf="!dataLoader && isChecked" class="grid grid-cols-12 space-x-4 space-y-8 p-6 md:p-8 lg:px-12 xl:px-24">
      <!-- BEGIN: Leftside Content -->
      <div class="col-span-12 lg:col-span-7">
        <div class="w-full">
          <h2 class="text-darkblue text-xl lg:text-2xl xl:text-4xl uppercase text-semibold pt-1">Subscription</h2>
          <p class="text-base text-grey py-2 tracking-wide text-regular">
            {{getVerbiage?.sub_header}}
          </p>
          <p class="border-b-8 w-24 border-red mb-3"></p>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-7 space-y-8">
        <ng-container>
          <div *ngIf="userType!=='Custodian'"
               class="flex flex-col sm:flex-row gap-2 sm:gap-4 sm:items-center justify-between border-2 rounded-md border-grey-600 p-4 md:p-6">
            <div class="flex flex-col sm:flex-row gap-2 sm:gap-4">
              <div>
                <h3 class="text-darkblue text-lg md:text-xl text-semibold">
                  Digital Vault Subscription
                </h3>
                <p class="text-base text-grey py-1 tracking-wide text-regular">
                  {{getVerbiage?.vault_subscription}}
                </p>
              </div>
              <p class="text-lg lg:text-xl text-darkblue font-semibold text-regular my-auto">
                {{monthlyPackageDetails| currency}}
              </p>
            </div>
            <ng-container *ngIf="chooseSubscriptionOption">
              <div class="flex items-center justify-end sm:-mt-0 lg:-mt-2">
                <span>Yearly</span>
                <label class="switch mx-2">
                  <input (click)="toggleActivePackage()" type="checkbox">
                  <span class="slider round"></span>
                </label>
                <span>Monthly</span>
              </div>
            </ng-container>
          </div>
          <!-- <div *ngIf="!hideDataTransfer" class="w-full">
            <div
              class="flex flex-col sm:flex-row gap-2 sm:gap-4 gap-3 md:items-center justify-between bg-white rounded-md border-2 border-grey-600 p-4 md:p-6"
              [ngClass]="{'bg-gray-300 border-blue-400': isVaultTransferPaid, 'border-grey-600 bg-white': !isVaultTransferPaid}">
              <label class="flex gap-2 md:gap-3 items-start">
                <input *ngIf="userType !== 'Custodian'" (click)="toggleDigitalWallet()" type="checkbox"
                       [ngClass]="{'hidden': isVaultTransferPaid}"
                       class="form-checkbox payment-checkbox mt-2 w-5 h-5 background-color-grey">
                <div>
                  <h3 class="text-darkblue text-lg md:text-xl text-semibold">
                    NotifyNOW Data Transfer
                  </h3>
                  <p class="text-base text-grey py-1 tracking-wide text-regular w-full">
                    {{getVerbiage?.vault_transfer}}
                  </p>
                </div>
              </label>
              <div class="flex-row">
                <p class="text-lg lg:text-xl text-green-500 py-2 tracking-wide font-semibold text-regular"
                   *ngIf="isVaultTransferPaid">
                  Paid
                </p>
                <p class="text-lg lg:text-xl text-darkblue py-2 tracking-wide font-semibold text-regular">
                  {{vaultTransferAmount | currency}}
                </p>
              </div>
            </div>
          </div> -->
        </ng-container>

      </div>
      <!-- END: Leftside Content -->

      <!-- BEGIN: Rightside Content -->
      <div class="col-span-12 lg:col-span-5 bg-white rounded-md border-2 border-grey-600" *ngIf="!transferVaultScreen">
        <div class="p-4 md:p-6 h-full w-full flex flex-col">
          <h3 class="text-darkblue text-lg md:text-xl text-semibold border-b pb-3 w-full border-grey-500">
            Payment Summary
          </h3>


          <div>
            <div *ngFor="let item of paymentItems" class="flex item-center justify-between my-4">
              <h3 class="text-grey text-base font-light">
                {{item.title}}
              </h3>
              <p class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular">
                {{item.price | currency}}
              </p>
            </div>
            <div class="flex item-center justify-between my-4" *ngIf="promoCodeApplied">
              <h3 class="text-grey text-base font-light">
                Discount Amount
              </h3>
              <p class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular">
                - {{discountAmount | currency}}
              </p>
            </div>
          </div>

          <div class="border-b mb-4 w-full border-grey-500"></div>
          <div class="flex item-center justify-between my-4">
            <h3 class="text-base text-grey  tracking-wide text-regular">
              Total
            </h3>
            <p class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular">
              {{total | currency}}
            </p>
          </div>

          <div class="mt-auto">
            <div class="flex items-center gap-4" *ngIf="!promoCodeApplied && userType !== 'Custodian'">
              <div class="w-full">
                <label class="text-label-grey text-sm text-regular mb-2" for="promo_code"> Apply Promo Code</label>
                <input type="text" id="promo_code" [(ngModel)]="promoCode"
                       class="h-10 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 relative">
              </div>
              <button (click)="checkPromoCode()"
                      class="cursor-pointer mt-auto mx-auto sm:mr-0 sm:ml-auto w-auto flex md:items-center md:justify-center px-5 py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn text-center">
                Submit
              </button>
            </div>
            <p class="cursor-pointer text-regular font-medium text-xs mt-2 error-alert" (click)="removePromoCode()"
               *ngIf="promoCodeApplied">
              Remove Promo Code
            </p>

            <button (click)="toCheckoutScreen()"
                    class="cursor-pointer mt-2 mx-auto sm:mr-0 sm:ml-auto sm:w-auto w-full flex justify-center px-5 py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn text-center">
              Proceed to Payment Details
            </button>
          </div>

        </div>
        <!-- <div class="w-full mb-8">
          <div class="bg-white rounded-md border-2 border-grey-600 p-6 ">
            <div class="flex items-center justify-start">
              <div>
                <img src="assets/images/payment/dlb.svg" alt="dlb" class="w-20 h-20">
              </div>
              <div class="pl-4">
                <h3 class="text-grey text-lg font-light mb-2">
                  {{getVerbiage?.ln_dv_id}}
                </h3>
                <p class="text-base lg:text-xl text-darkblue tracking-wide font-semibold text-regular">{{dlbId}}</p>
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <!-- END: Rightside Content -->
    </div>
    <div *ngIf="dataLoader" class="bg-white rounded-md ">
      <div class="px-4 px-6 my-4">
        <!-- BEGIN: First Accordion -->
        <div class="mb-2">
          <div class="flex flex-row items-baseline">
            <div class="w-full mt-10">
              <app-shimmer-loading [loading]="true" [width]="'20%'">
                <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                </h4>
              </app-shimmer-loading>
              <app-shimmer-loading [loading]="true" [width]="'50%'">
                <h4 class="text-grey text-regular text-sm">
                </h4>
              </app-shimmer-loading>
            </div>
          </div>
        </div>
        <div class="md:grid md:grid-cols-12 xl:flex xl:flex-row my-6 flex-col-reverse items-center">
          <div class="xl:ml-6 mt-4 xl:mt-0 md:col-span-10 w-full">
            <app-shimmer-loading [loading]="true" [width]="'20%'">
              <p class="text-xs tracking-wide text-regular breadcrumb"> Section<span class="font-bold">1</span> / 7
              </p>
            </app-shimmer-loading>
            <app-shimmer-loading [loading]="true" [width]="'30%'">
              <h4 class="text-regular text-lg  font-semibold tracking-wide">
                About Yourself
              </h4>
            </app-shimmer-loading>
            <app-shimmer-loading [loading]="true" [width]="'60%'">
              <p class="text-grey text-regular text-sm">Enter basic info about you & Your Family.
              </p>
            </app-shimmer-loading>
          </div>
          <div class="xl:ml-auto mt-4 xl:mt-0 md:col-span-12 w-96">
            <app-shimmer-loading [loading]="true" [width]="'100%'" [height]="'20rem'">
              <button type="submit"
                      class="button button--lg w-full bg-darkblue rounded-xs focus:outline-none text-regular text-white px-6">
                Get
                Started
              </button>
            </app-shimmer-loading>
          </div>
        </div>
        <div class="md:grid md:grid-cols-12 xl:flex xl:flex-row my-6 flex-col-reverse items-center">
          <div class="xl:ml-6  xl:mt-0 md:col-span-10 w-full">
            <app-shimmer-loading [loading]="true" [width]="'20%'">
              <p class="text-xs tracking-wide text-regular breadcrumb"> Section<span class="font-bold">1</span> / 7
              </p>
            </app-shimmer-loading>
            <app-shimmer-loading [loading]="true" [width]="'30%'">
              <h4 class="text-regular text-lg  font-semibold tracking-wide">
                About Yourself
              </h4>
            </app-shimmer-loading>
            <app-shimmer-loading [loading]="true" [width]="'60%'">
              <p class="text-grey text-regular text-sm">Enter basic info about you & Your Family.
              </p>
            </app-shimmer-loading>
          </div>
        </div>
        <div class="md:grid md:grid-cols-12 xl:flex xl:flex-row my-6 flex-col-reverse items-center">
          <div class="xl:ml-6  xl:mt-0 md:col-span-10 w-full">
            <app-shimmer-loading [loading]="true" [width]="'20%'">
              <p class="text-xs tracking-wide text-regular breadcrumb"> Section<span class="font-bold">1</span> / 7
              </p>
            </app-shimmer-loading>
            <app-shimmer-loading [loading]="true" [width]="'30%'">
              <h4 class="text-regular text-lg  font-semibold tracking-wide">
                About Yourself
              </h4>
            </app-shimmer-loading>
            <app-shimmer-loading [loading]="true" [width]="'60%'">
              <p class="text-grey text-regular text-sm">Enter basic info about you & Your Family.
              </p>
            </app-shimmer-loading>
          </div>
        </div>
        <div class="md:grid md:grid-cols-12 xl:flex xl:flex-row my-6 flex-col-reverse items-center">
          <div class="xl:ml-6 mt-4 xl:mt-0 md:col-span-10 w-full">
            <app-shimmer-loading [loading]="true" [width]="'20%'">
              <p class="text-xs tracking-wide text-regular breadcrumb"> Section<span class="font-bold">1</span> / 7
              </p>
            </app-shimmer-loading>
            <app-shimmer-loading [loading]="true" [width]="'50%'">
              <h4 class="text-regular text-lg  font-semibold tracking-wide">
                About Yourself
              </h4>
            </app-shimmer-loading>
            <app-shimmer-loading [loading]="true" [width]="'80%'">
              <p class="text-grey text-regular text-sm">Enter basic info about you & Your Family.
              </p>
            </app-shimmer-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-card-detail
    *ngIf="showCardScreen" [vaultTransferAmount]="vaultTransferAmount"
    [monthlySubscription]="monthlyPackageDetails" [discountAmount]="discountAmount"
    [appliedPromoCodeId]="appliedPromoId" [isPromoCodeApplied]="promoCodeApplied" [total]="total"
    [activePackage]="activePackage" [dlbId]="dlbId" [paymentCompleted]="paymentCompleted"
    [isTransferDigitalWallet]="isTransferDigitalWallet" [transferVaultScreen]="transferVaultScreen"
    [isUpdatePlan]="isUpdatePlan"></app-card-detail>
  <app-consumer-landing-footer></app-consumer-landing-footer>
</ng-template>
