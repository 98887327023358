import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-component',
  templateUrl: './image-component.component.html',
})
/**
 * Image loading component with loader
 */
export class ImageComponentComponent {
  public loader:string;
  @Input() class:string;
  @Input() image:string;
  @Input() defaultImage:string;
  @Input() spinner:boolean;

  public isLoading:boolean;
  /**
   * @constructor
   */
  constructor() {
    this.loader = 'assets/images/common/imageLoder.gif';
    this.isLoading = true;
  }


  /**
   * hide loader after image has loaded
   */
  public hideLoader():void {
    this.isLoading=false;
  }

  /**
   * handle src img error ( fallback )
   */
  public onError():void {
        this.image = this.defaultImage || 'assets/images/common/default-no-image.jpeg';
  }
}
