<main class="relative">

  <!-- BEGIN: Navbar Wrapper -->
  <app-consumer-landing-navbar></app-consumer-landing-navbar>
  <!-- END: Navbar Wrapper -->

  <!-- BEGIN: Banner Wrapper -->
  <div class="contact-banner__wrap relative flex items-center justify-center banner-padding-hfull mh-700-height">
    <div class="container mx-auto z-30">
      <div class="flex items-center text-white">
        <div class="grid grid-cols-12 gap-4 xl:gap-12">
          <div class="col-span-5"></div>
          <div class="col-span-12 lg:col-span-7">
            <div class="mt-8 lg:mt-12">
              <h1 class="contact-banner__head text-semibold fs-bhead text-regular">
                CONTACT US
              </h1>
              <p class="contact-banner__content fs-content mt-4 text-regular">
                If you need assistance signing up for LegacyNOW or have any additional questions about our Digital
                Vault,
                please submit a request through the contact form below. An email will be sent to our
                customer
                service team, and they will respond within 24 business hours. For immediate assistance, please call the
                number
                provided below.
              </p>
              <p class="contact-banner__content fs-content mt-4 text-regular">
                Thank you for your interest in LegacyNOW.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Banner Wrapper -->

  <!-- BEGIN: Contact Form -->
  <div class="section contact-form">
    <div class="container mx-auto">
      <div class="b-contact-card bg-darkblue p-4 pt-6">
        <h1 class="text-semibold text-white fs-bhead pb-2 lg:pb-0 text-regular text-center">
          Contact Information
        </h1>
        <div class="b-contact-card bg-white m-4 p-4">
          <form [formGroup]="contactUsForm" autocomplete="off" (ngSubmit)="onSubmit()">
            <div class="grid grid-cols-12 gap-2">
              <!-- <div class="col-span-12">
                <label for="full_name" class="text-grey text-regular fs-label">Full Name *</label>
                <input type="text" id="full_name" formControlName="full_name"
                       [ngClass]="{'border-red' : formGet?.full_name?.errors && formGet?.full_name?.touched}"
                       class="h-12 lg:h-14 px-4 w-full text-regular text-content shadow-sm border border-input rounded-md focus:outline-none">
                <p *ngFor="let error of getErrors('full_name')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div> -->
              <div class="col-span-12 md:col-span-4">
                <label for="first-name" class="text-grey text-regular fs-label">First Name *</label>
                <input type="text" name="first-name" id="first-name" formControlName="first_name"
                       [ngClass]="{'border-red' : formGet?.first_name?.errors && formGet?.first_name?.touched}"
                       class="h-12 lg:h-14 px-4 w-full text-regular text-content shadow-sm border border-input rounded-md focus:outline-none">
                <p *ngFor="let error of getErrors('first_name')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-4">
                <label for="middle_name" class="text-grey text-regular fs-label">Middle Name </label>
                <input type="text" name="middle_name" id="middle_name" formControlName="middle_name"
                       [ngClass]="{'border-red' : formGet?.middle_name?.errors && formGet?.middle_name?.touched}"
                       class="h-12 lg:h-14 px-4 w-full text-regular text-content shadow-sm border border-input rounded-md focus:outline-none">
                <p *ngFor="let error of getErrors('middle_name')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12 md:col-span-4">
                <label for="last_name" class="text-grey text-regular fs-label">Last Name *</label>
                <input type="text" name="last_name" id="last_name" formControlName="last_name"
                       [ngClass]="{'border-red' : formGet?.last_name?.errors && formGet?.last_name?.touched}"
                       class="h-12 lg:h-14 px-4 w-full text-regular text-content shadow-sm border border-input rounded-md focus:outline-none">
                <p *ngFor="let error of getErrors('last_name')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12">
                <label for="email" class="text-grey text-regular fs-label">Email *</label>
                <input type="email" name="email" id="email" formControlName="email"
                       [ngClass]="{'border-red' : formGet?.email?.errors && formGet?.email?.touched}"
                       class="h-12 lg:h-14 px-4 w-full text-regular text-content shadow-sm border border-input rounded-md focus:outline-none">
                <p *ngFor="let error of getErrors('email')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12">
                <label for="phone-no" class="text-grey text-regular fs-label">Phone Number</label>
                <app-phone-input [classList]="true" id="phone-no"
                                 [phoneNumber]="contactUsForm.value.phone" [countryCode]="countryCode"
                                 (click)="formGet.phone.markAsTouched()" [uniqueId]="'phone-consumer-contact'"
                                 (onChange)="detectChangePhonenumber($event)"
                                 [errorClassTrigger]="formGet.phone.errors && formGet.phone.touched"
                                 [form]="contactUsForm" (focusout)="formGet.phone.markAsTouched()">
                </app-phone-input>
                <p *ngFor="let error of getErrors('phone')"
                   class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
              <div class="col-span-12">
                <label for="description" class="text-grey text-regular fs-label">
                  Description of Issue *
                </label>
                <div class="mt-1">
                <textarea id="description" name="description" rows="5"
                          class="p-4 shadow-sm text-regular text-content w-full border border-checkbox rounded-md rounded-md focus:outline-none"
                          [ngClass]="{'border-red' : formGet?.description?.errors && formGet?.description?.touched}"
                          formControlName="description"></textarea>
                  <p *ngFor="let error of getErrors('description')"
                     class="text-regular font-medium text-xs mt-2 error-alert">
                    {{error}}
                  </p>
                </div>
              </div>
              <div class="col-span-12">
                <!-- BEGIN: Recaptcha -->
                <div class="recaptcha h-20">
                  <re-captcha #captchaRef (resolved)="resolvedCaptcha($event)"
                  (error)="onErrorCaptcha($event)" errorMode="handled" [siteKey]="googleCaptchaKey"></re-captcha>
                </div>
                <div *ngIf="isRecaptchaValidationEnable">
                  <p *ngIf="!isCaptchaValid" class="text-regular font-medium text-xs error-alert">GOOGLE
                   Recaptcha is required</p>
                </div>
                <!-- END: Recaptcha -->
                </div>
              <div class="col-span-12">
                <p class="text-content text-regular mb-4 md:mb-0">
                  Hours of Operation:
                  <br class="block md:hidden"><span class="ml-2 md:ml-0">Monday to Friday, </span>
                  <br class="block md:hidden"><span class="ml-2 md:ml-0">9am to 5pm Eastern</span>
                </p>
                <div class="flex flex-col md:flex-row mb-4 md:mb-0">
                  <p class="text-content text-regular">
                    Technical Support Team, call:
                  </p>
                  <a href="tel:+1{{customerSupportPhone}}" target="_blank"
                     class="ml-2 cursor-pointer text-grey font-semibold hover:underline hover:text-orange-600">
                    +1 {{customerSupportPhone}}
                  </a>
                </div>
                <div class="flex flex-col md:flex-row">
                  <p class="text-content text-regular">
                    Or email us at:
                  </p>
                  <a href="mailto:{{customerSupportMail}}" target="_blank"
                     class="ml-2 cursor-pointer text-grey font-semibold hover:underline hover:text-orange-600">
                    {{customerSupportMail}}</a>
                </div>
              </div>
              <div class="col-span-12 mt-4">
                <div class="flex items-left justify-center">
                  <app-button-loader [buttonText]="'Submit Your Request'"
                                     [buttonClass]="'button-primary button-submit w-full whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white'"
                                     [valid]="contactUsForm.valid" [loader]="submitLoader"
                                     buttonType="'submit'"></app-button-loader>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- START: Contact Form -->


  <!-- BEGIN: Testimonial Wrapper-->
  <div class="section section-bottom">
    <app-testimonials [mobile]="mobile"></app-testimonials>
  </div>
  <!-- END: Testimonial Wrapper -->

  <!-- BEGIN: Footer Wrapper -->
  <app-consumer-landing-footer></app-consumer-landing-footer>
  <!-- END: Footer Wrapper -->
</main>

<div class="fixed right-6 bottom-6 group z-50">
  <button type="button" aria-expanded="false" (click)="openModal('assistanceHelp')"
    class="flex items-center justify-center text-white bg-blue-700 rounded-full w-16 h-16 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
    <img src="assets/images/intro-video.png" alt="">
    <span class="sr-only">Open actions menu</span>
  </button>
</div>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>
