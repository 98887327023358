import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { AssetsService } from 'src/app/services/assets.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { OverviewService } from 'src/app/services/overview.service';
import { USER_TYPES } from '../../../constants/application.const';
import { CommonHelper } from '../../helper/common.helper';
import { ProfessionalPermissionService } from '../../professional-dashboard/services/professional-permission.service';


@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
})
/**
 * Overview component
 */
export class OverviewComponent implements OnInit {
  public currentRouter: string;
  public verifiedSlug:string;
  public mobileMenuName: string;
  public responsiveMenuToggle = false;
  public requestId: string;
  public userType: string;
  public insuranceStatus = false;
  public assetsStatus = false;
  public businessStatus = false;
  public digitalFileStatus = false;
  public donationStatus = false;
  public prescriptionStatus = false;
  public socialMediaStatus = false;
  public valuableStatus = false;
  public subscriptionStatus = false;
  public vehicleStatus = false;
  public governmentIdStatus = false;
  public liabilityStatus = false;
  public realEstateStatus = false;
  public messageData = {};
  public agentId: string;
  public vaultVideo: string;
  public userRequestID: string;


  /**
   * @constructor
   */
  constructor(
    private router: Router,
    private assetsService: AssetsService,
    private overviewService: OverviewService,
    private localStorageService: LocalStorageService,
    private proPermissionService: ProfessionalPermissionService,
    private slugInterceptorService: SlugInterceptorService,
    private modalService: CommonModelService,
    private activeRouter: ActivatedRoute,
    private commonHelper: CommonHelper,
  ) {
    // listen for url change
    this.router.events.subscribe((value: NavigationEnd) => {
      this.currentRouter = value?.url;
    });
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.userType = this.localStorageService.getDataByKey('role');
    this.verifiedSlug = localStorage.getItem('verifiedSlug');
    this.mobileMenuName = 'Overview';
    this.requestId = this.commonHelper.getRequestId(this.activeRouter);
    this.userRequestID = this.commonHelper.getRequestId(this.activeRouter);
    this.getAssetsValue();
    this.agentId = this.localStorageService.getDataByKey('agentId');
    if (this.userType !== 'Consumer') {
      this.getAccessControl();
    }
  }


  /**
   * get access control list
   */
  public getAccessControl() {
    let isProfessional: boolean;
    isProfessional = this.userType === 'Custodian'? false: true;
    const requestId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('request_user_id') : this.userRequestID;
    const userId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('user_id') : this.localStorageService.getDataByKey('agent_id') || this.localStorageService.getUserData(USER_TYPES.pro)?.user?.id;
    this.proPermissionService.getAccessControl(userId, requestId, undefined, isProfessional).subscribe((permissions) => {
        for (const permissionsKey in permissions) {
          this.onSelectSection({ permission_name: permissionsKey, ...permissions[permissionsKey] });
        }
      },
    );
  }


  /**
   * mobile menu change
   * @param {string}menu
   */
  public onMobileMenuchange(menu: string) {
    this.responsiveMenuToggle = !this.responsiveMenuToggle;
    this.mobileMenuName = menu;
    this.getAssetsValue();
  }


  /**
   * get assets value
   */
  public getAssetsValue() {
    this.assetsService.getTotalAssetsValue(this.requestId).subscribe((response: APIResponseModel) => {
      this.overviewService.dashboardDataObservableEvents(response.data);
    });
  }


  /**
   * on clicking on section name
   * @param {{}}sectionData
   */
  public onSelectSection(sectionData: {}) {
    const bStatus = sectionData['view'] == 1 || sectionData['add'] == 1 || sectionData['edit'] == 1 || sectionData['delete'] == 1;
    switch (sectionData['permission_name']) {
      case 'Accounts and Assets':
        this.assetsStatus = bStatus;
        break;
      case 'Business':
        this.businessStatus = bStatus;
        break;
      case 'Digital Files':
        this.digitalFileStatus = bStatus;
        break;
      case 'Donations':
        this.donationStatus = bStatus;
        break;
      case 'Government Id':
        this.governmentIdStatus = bStatus;
        break;
      case 'Insurance':
        this.insuranceStatus = bStatus;
        break;
      case 'Liabilities':
        this.liabilityStatus = bStatus;
        break;
      case 'Prescriptions':
        this.prescriptionStatus = bStatus;
        break;
      case 'Real Estate':
        this.realEstateStatus = bStatus;
        break;
      case 'Social Media':
        this.socialMediaStatus = bStatus;
        break;
      case 'Subscriptions':
        this.subscriptionStatus = bStatus;
        break;
      case 'Valuables':
        this.valuableStatus = bStatus;
        break;
      case 'Vehicle':
        this.vehicleStatus = bStatus;
        break;
    }
  }


  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }


  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');
  }

  /**
   * Checks if the provided route matches the current router or a specific route under a verified slug.
   * @param {string} route - The route to check against the current router.
   * @returns {boolean}
   */
  public applyActiveRouteClass(route:string):boolean{
    return (this.currentRouter === `/${route}` || this.currentRouter === `/${this.verifiedSlug}/${route}`)
  }
}
