<div class="lg:grid grid-cols-12 background-color">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 md:gap-8 my-4">
              <div class="col-span-12 sm:col-span-2">
                <!-- <div class="w-16 my-2"> -->
                <img src="assets/images/about-yourself/about-yourself.png" class="w-20" alt="digital vault">
                <!-- </div> -->
              </div>

              <div class="col-span-12 sm:col-span-10">
                <!-- <div class="pl-4 sm:px-4 ml-8"> -->
                <h2 class="text-darkblue text-xl sm:text-3xl uppercase text-semibold">
                  Tell us about yourself
                </h2>
                <p class="text-sm text-grey tracking-wide text-regular">
                  This information is critical and a key to verifying your identity. Please double-check your input
                  prior to moving on.
                </p>
                <!-- </div> -->

              </div>
            </div>

            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-1/6'"></app-card-progress-bar>
              <!-- END: Progress Bar -->

              <div class="p-6">
                <div class="mb-4">
                  <div class="flex flex-row items-baseline">
                    <div>
                      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                        What’s your full legal name?
                      </h4>
                      <p class="text-grey text-regular pt-1 text-sm">
                        This is the name used on your passport and/or driving license.
                      </p>
                    </div>
                  </div>
                </div>

                <form [formGroup]="namingForm" autocomplete="off" (ngSubmit)="onSubmit()">
                  <!-- <label for="first_name" class="text-label-grey text-sm text-regular">Your full legal name</label> -->
                  <div class="grid grid-cols-12 gap-4 row-gap-4 ">

                    <div class="col-span-12 lg:col-span-4">
                      <label for="first_name" class="text-label-grey text-sm text-regular ">First Name*</label>
                      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                        <input type="text"
                               class="h-10 sm:h-12 input py-3 px-4 w-full mt-1  placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                               name="first_name" id="first_name" formControlName="first_name" placeholder="First Name"
                               [ngClass]="formGet?.first_name.errors && formGet?.first_name.touched?'form-input-error':'form-input-focus'"
                               (change)="formGet?.first_name?.markAsUntouched()">
                      </app-shimmer-loading>
                      <p *ngFor="let error of getErrors('first_name')"
                         class="text-regular font-medium text-xs mt-2 error-alert">
                        {{ error }}
                      </p>
                    </div>
                    <div class="col-span-12 lg:col-span-4">
                      <label for="first_name" class="text-label-grey text-sm text-regular">Middle Name</label>
                      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                        <input type="text"
                               class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                               id="middle_name" formControlName="middle_name" placeholder="Middle Name"
                               [ngClass]="formGet?.middle_name.errors && formGet?.middle_name.touched?'form-input-error':'form-input-focus'">
                      </app-shimmer-loading>
                      <p *ngFor="let error of getErrors('middle_name')"
                         class="text-regular font-medium text-xs mt-2 error-alert">
                        {{ error }}
                      </p>
                    </div>
                    <div class="col-span-12 lg:col-span-4">
                      <label for="first_name" class="text-label-grey text-sm text-regular">Last Name*</label>
                      <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                        <input type="text"
                               class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                               name="last_name" formControlName="last_name" placeholder="Last Name"
                               [ngClass]="formGet?.last_name.errors && formGet?.last_name.touched?'form-input-error':'form-input-focus'"
                               (change)="formGet?.last_name?.markAsUntouched()">
                      </app-shimmer-loading>
                      <p *ngFor="let error of getErrors('last_name')"
                         class="text-regular font-medium text-xs mt-2 error-alert">
                        {{ error }}
                      </p>
                    </div>
                  </div>

                  <div class="col-span-12 lg:col-span-4 mt-4">
                    <label for="phone-register" class="text-label-grey text-sm mt-2 text-regular">Phone Number*</label>
                    <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                    <app-phone-input id="phone-register" uniqueId="phone-register"
                                     placeholder="Phone Number" [form]="namingForm"
                                     (onChange)="detectChangePhonenumber($event)"
                                     [phoneNumber]="namingForm.value.phone" [countryCode]="countryCode"
                                     (focusout)="formGet?.phone.markAsTouched()"
                                     [errorClassTrigger]="formGet?.phone.errors && formGet?.phone.touched">
                    </app-phone-input>
                    </app-shimmer-loading>
                    <p *ngFor="let error of getErrors('phone')"
                       class="text-regular font-medium text-xs mt-2 error-alert">
                      {{ error }}
                    </p>
                  </div>

                  <div class="grid grid-cols-12 gap-4 row-gap-4 mt-4">
                    <div class="col-span-12 md:col-span-6 lg:col-span-12" [ngClass]="{'lg:col-span-12':!showSSN}">
                      <label [for]="'dob'" class="text-label-grey text-sm text-regular">
                        Date of Birth ( {{ domainDateFormat }} )*
                      </label>
                      <app-shimmer-loading [loading]="preDefinedLoader">
                        <app-datepicker-toggle
                          inputID="dob"
                          class="mt-1"
                          [bsConfig]="bsConfig"
                          [useFormControl]="formGet?.dob">
                        </app-datepicker-toggle>
                      </app-shimmer-loading>
                      <p *ngFor="let error of getErrors('dob')"
                         class="text-regular font-medium text-xs mt-2 error-alert">
                        {{ error }}
                      </p>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                      <label for="place_of_birth" class=" text-label-grey text-sm text-regular ">Place of
                        Birth*</label>
                      <div class=" col-span-12 lg:col-span-2 relative mt-1"
                           (click)="markAsTouchedIndividual('place_of_birth')">
                        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                          <app-country-search-component id="place_of_birth"
                                                        (optionChangeEmitter)="optionChangeListenerCountry($event)"
                                                        [defaultSelected]="formGet?.place_of_birth?.value"
                                                        [enableError]="formGet?.place_of_birth.errors && formGet?.place_of_birth.touched">
                          </app-country-search-component>
                          <p *ngFor="let error of getErrors('place_of_birth')"
                             class="text-regular font-medium text-xs mt-2 error-alert">
                            {{ error }}
                          </p>
                        </app-shimmer-loading>
                      </div>
                    </div>
                    <div class="col-span-12 lg:col-span-6" *ngIf="showSSN">
                      <label for="ssn" class="text-label-grey text-sm text-regular">SSN
                        (Social Security Number)*</label>
                      <div class="relative h-12 text-gray-700">
                        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                          <div class="relative">
                            <a class="absolute inset-y-0 right-2 pr-3 flex items-center"
                               (click)="toggleSSNView.ssn = !toggleSSNView.ssn">
                              <fa-icon [icon]="faEye" *ngIf="toggleSSNView.ssn"></fa-icon>
                              <fa-icon [icon]="faEyeSlash" *ngIf="!toggleSSNView.ssn"></fa-icon>
                            </a>
                            <input type="text" [type]="toggleSSNView.ssn ? 'text' : 'password'"
                                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                                   name="ssn" id="ssn" formControlName="ssn" placeholder="e.g. 255-34-9345"
                                   [ngClass]="formGet?.ssn.errors && formGet?.ssn.touched?'form-input-error':'form-input-focus'"
                                   [mask]="SSN_MASK" [dropSpecialCharacters]="false" maxlength="11"
                                   (change)="formGet?.ssn?.markAsUntouched()" (focusout)="onFocusout($event,'ssn')">
                          </div>
                        </app-shimmer-loading>
                      </div>
                      <p *ngFor="let error of getErrors('ssn')"
                         class="text-regular font-medium text-xs mt-2 error-alert">
                        {{ error }}
                      </p>
                    </div>
                    <div class="col-span-12 lg:col-span-6" *ngIf="showSSN">
                      <label for="ssn" class="text-label-grey text-sm text-regular">
                        Confirm SSN*
                      </label>
                      <div class="relative h-12 text-gray-700">

                        <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                          <div class="relative">
                            <a class="absolute inset-y-0 right-2 pr-3 flex items-center"
                               (click)="toggleSSNView.v_ssn = !toggleSSNView.v_ssn">
                              <fa-icon [icon]="faEye" *ngIf="toggleSSNView.v_ssn"></fa-icon>
                              <fa-icon [icon]="faEyeSlash" *ngIf="!toggleSSNView.v_ssn"></fa-icon>
                            </a>
                            <input type="text" [type]="toggleSSNView.v_ssn ? 'text' : 'password'"
                                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                                   name="ssn_validation" id="ssn_validation" formControlName="ssn_validation"
                                   placeholder="e.g. 255-34-9345" [mask]="SSN_MASK" [dropSpecialCharacters]="false"
                                   maxlength="11" (input)="onChange($event,'ssn_validation')"
                                   (focusout)="onFocusout($event,'ssn_validation')">
                          </div>
                        </app-shimmer-loading>

                      </div>
                      <div
                        *ngIf="formGet?.ssn.value && formGet?.ssn.valid && !formGet?.ssn_validation.value && !checkSSNMatch()">
                        <p class="text-regular font-medium text-xs mt-2 error-alert">
                          Confirm SSN is required.
                        </p>
                      </div>
                      <div
                        *ngIf="formGet?.ssn.value && formGet?.ssn.valid && formGet?.ssn_validation.value && !checkSSNMatch()">
                        <p class="text-regular font-medium text-xs mt-2 error-alert">
                          SSN numbers must match.
                        </p>
                      </div>
                    </div>

                  </div>
                  <div *ngIf="formGet?.ssn.value">
                    <div *ngIf="checkSSNMatch() && validatingSSN == 1" class="flex">
                      <p class="text-regular font-medium text-xs mt-2 checking-alert">
                        Validating SSN &nbsp;
                      </p>
                      <div class="loading-red mr-2 mt-2"></div>
                    </div>
                    <p class="text-regular font-medium text-xs mt-2 error-alert"
                       *ngIf="checkSSNMatch() && validatingSSN == 2 && !isUniqueSSN">
                      SSN has already been entered.
                    </p>
                  </div>
                  <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
                    <a (click)="changeRoute('dashboard')" *ngIf="this.userType === 'Consumer'"
                       class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                      <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault">
                      Back
                    </a>
                    <a (click)="changeRoute('dashboard')" *ngIf="this.userType !== 'Consumer'"
                       class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                      <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault">
                      Back
                    </a>

                    <!-- BUTTON: Disabled - opacity-50 -->


                    <app-button-loader class="w-full sm:w-auto" [buttonText]="'Save and Continue'"
                                       [valid]="checkSSNMatch() && isUniqueSSN && validatingSSN !== 1"
                                       [loader]="submitLoader"
                                       buttonType="'submit'"></app-button-loader>
                  </div>
                </form>

              </div>
              <!-- END: Leftside Content -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class="col-span-12 md:col-span-6 lg:grid justify-center">
    <about-yourself-right-content [formInputValue]="namingForm?.value"
                                  [otherValues]="sectionSaveExitOptions"></about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->
</div>
