import { DashboardSectionDetailsInterface } from '../interface/dashboard.interface';

// dashboard section details
export const dashboardSectionDetails: DashboardSectionDetailsInterface = {
  totalSection: 19,
  sectionList: [
    {
      title: 'Personal Details',
      description: '',
      endingSection: 1,
      lastSection: 3,
      sectionLastRequestStage: 10,
      sectionItemList: [
        {
          sectionIcon: 'assets/images/dashboard/about-yourself.png',
          sectionStep: 1,
          sectionTitle: 'About Yourself',
          sectionName: 'about_yourself',
          lastRequestStage: 6,
          sectionDescription: 'Enter basic info about you & your family.',
          sectionInitialButton: {
            redirectionLink: '/about-yourself',
          },
        },
        {
          sectionIcon: 'assets/images/dashboard/executors.png',
          sectionStep: 2,
          sectionName: 'beneficiaries',
          sectionTitle: 'Heirs and Beneficiaries',
          lastRequestStage: 8,
          sectionDescription: 'Chosen people (or entity) who are designated to receive benefits of property via your estate.',
          sectionInitialButton: {
            redirectionLink: '/executor-beneficiary/beneficiary-info',
          },
        },
        {
          sectionIcon: 'assets/images/dashboard/security.svg',
          sectionStep: 3,
          sectionName: 'custodian_exec',
          sectionTitle: 'Elected Digital Vault Custodian(s)',
          lastRequestStage: 10,
          sectionDescription: 'Chosen person(s) who is responsible for informing LegacyNOW of your passing.',
          sectionInitialButton: {
            redirectionLink: '/executor-beneficiary',
          },
        },
      ],
    },
    {
      title: 'Professional Service Providers',
      description: '',
      endingSection: 3,
      lastSection: 4,
      sectionItemList: [
        {
          sectionIcon: 'assets/images/dashboard/psp.svg',
          sectionStep: 4,
          sectionName: 'professional_service_providers',
          sectionTitle: 'Professional Service Providers',
          lastRequestStage: 11,
          sectionDescription: 'Connect with your Professional Service Providers.',
          sectionInitialButton: {
            redirectionLink: '/advisors/manage-advisors',
          },
        },
      ],
    },
    {
      title: 'Assets & Liabilities',
      description: 'What you own and what you owe.',
      endingSection: 4,
      lastSection: 8,
      sectionItemList: [
        {
          sectionIcon: 'assets/images/dashboard/insurance.png',
          sectionStep: 5,
          sectionTitle: 'Insurance',
          sectionName: 'insurance',
          lastRequestStage: 13,
          sectionDescription: 'A contract, represented by a policy.',
          sectionInitialButton: {
            redirectionLink: '/insurance-details',
          },
          iscompletedKey: 'user_insurance_count',
        },
        {
          sectionIcon: 'assets/images/dashboard/accounts-assets.png',
          sectionStep: 6,
          sectionTitle: 'Financial Accounts and Assets',
          sectionName: 'accounts_assets',
          lastRequestStage: 14,
          sectionDescription: 'Any resource owned by you.',
          sectionInitialButton: {
            redirectionLink: '/assets/financial-assets',
          },
          iscompletedKey: 'user_retirement_asset_count',
        },
        {
          sectionIcon: 'assets/images/dashboard/retirement-asset.png',
          sectionStep: 7,
          sectionTitle: 'Retirement Assets',
          sectionName: 'retirement_assets',
          lastRequestStage: 14,
          sectionDescription: 'The funds and assets that you have set aside for your retirement.',
          sectionInitialButton: {
            redirectionLink: '/assets/retirement-assets',
          },
          iscompletedKey: 'user_retirement_asset_count',
        },
        {
          sectionIcon: 'assets/images/dashboard/liabilities.png',
          sectionStep: 8,
          sectionTitle: 'Liabilities',
          sectionName: 'liabilities',
          lastRequestStage: 15,
          sectionDescription: 'The debts that you owe to a third-party.',
          sectionInitialButton: {
            redirectionLink: '/liabilities',
          },
          iscompletedKey: 'user_liability_count',
        }
      ],
    },
    {
      title: 'Property',
      description: 'Any item that you have legal title over.',
      endingSection: 8,
      lastSection: 13,
      sectionItemList: [
        {
          sectionIcon: 'assets/images/dashboard/realestatepic.png',
          sectionStep: 9,
          sectionTitle: 'Real Estate',
          sectionName: 'real_estate',
          lastRequestStage: 16,
          sectionDescription: 'Property consisting of land and the buildings on it.',
          sectionInitialButton: {
            redirectionLink: '/property',
          },
          iscompletedKey: 'user_property_count',
        },
        {
          sectionIcon: 'assets/images/dashboard/vehicle-details.png',
          sectionStep: 10,
          sectionTitle: 'Vehicles',
          sectionName: 'vehicles',
          lastRequestStage: 17,
          sectionDescription: 'A machine, usually with wheels and an engine, used for' + '\n' + 'transporting people or goods, especially on land.',
          sectionInitialButton: {
            redirectionLink: '/vehicle',
          },
          iscompletedKey: 'user_vehicle_count',
        },
        {
          sectionIcon: 'assets/images/dashboard/business-details.png',
          sectionStep: 11,
          sectionTitle: 'Business',
          sectionName: 'business',
          lastRequestStage: 18,
          sectionDescription: 'An organization or enterprising entity engaged in commercial,' + '\n' + 'industrial, or professional activities that you own.',
          sectionInitialButton: {
            redirectionLink: '/business-details',
          },
          iscompletedKey: 'user_business_count',
        },
        {
          sectionIcon: 'assets/images/dashboard/valuables.png',
          sectionStep: 12,
          sectionTitle: 'Valuables',
          sectionName: 'valuables',
          lastRequestStage: 19,
          sectionDescription: 'Items having considerable monetary worth.',
          sectionInitialButton: {
            redirectionLink: '/valuable-details',
          },
          iscompletedKey: 'user_valuable_count',
        },
        {
          sectionIcon: 'assets/images/property-details/donation.svg',
          sectionStep: 13,
          sectionTitle: 'Donations',
          sectionName: 'donations',
          lastRequestStage: 20,
          sectionDescription: 'Religious, charities, one time or ongoing.',
          sectionInitialButton: {
            redirectionLink: '/donation-list',
          },
          iscompletedKey: 'user_donations_count',
        }],
    },
    {
      title: 'Subscriptions, Social Media & Prescriptions',
      description: '',
      endingSection: 13,
      lastSection: 16,
      sectionItemList: [
        {
          sectionIcon: 'assets/images/property-details/social-media.svg',
          sectionStep: 14,
          sectionTitle: 'Social Media',
          sectionName: 'social_media',
          lastRequestStage: 21,
          sectionDescription: 'Online profiles and accounts.',
          sectionInitialButton: {
            redirectionLink: '/social-media-list',
          },
          iscompletedKey: 'user_social_media_count',
        },
        {
          sectionIcon: 'assets/images/property-details/subscription.svg',
          sectionStep: 15,
          sectionTitle: 'Subscriptions',
          sectionName: 'subscriptions',
          lastRequestStage: 22,
          sectionDescription: 'Magazines, newspapers and paid television.',
          sectionInitialButton: {
            redirectionLink: '/subscriptions-list',
          },
          iscompletedKey: 'user_subscriptions_count',
        },
        {
          sectionIcon: 'assets/images/property-details/prescription.svg',
          sectionStep: 16,
          sectionTitle: 'Prescriptions',
          sectionName: 'prescriptions',
          lastRequestStage: 23,
          sectionDescription: 'Name of drug, monthly one time or ongoing.',
          sectionInitialButton: {
            redirectionLink: '/prescription-list',
          },
          iscompletedKey: 'user_prescriptions_count',
        },
      ],
    },
    {
      title: 'Government Issued Documents',
      description: '',
      endingSection: 16,
      lastSection: 17,
      sectionItemList: [
        {
          sectionIcon: 'assets/images/property-details/government-id.svg',
          sectionStep: 17,
          sectionTitle: 'Government IDs',
          sectionName: 'gov_ids',
          lastRequestStage: 24,
          sectionDescription: 'Drivers license, passports, etc.',
          sectionInitialButton: {
            redirectionLink: '/government-id-list',
          },
          iscompletedKey: 'user_government_id_count',
        },
      ],
    },
    {
      title: 'Document Storage',
      description: 'Use your secure online safe to store your digital assets, such as Estate Planning Documents, Property Deeds & Titles, Insurance Policies and other important documents such as photos and secret family recipes.',
      endingSection: 17,
      lastSection: 19,
      sectionItemList: [
        {
          sectionIcon: 'assets/images/dashboard/digital-files.png',
          sectionStep: 18,
          sectionTitle: 'Digital Files',
          sectionName: 'digital_files',
          lastRequestStage: 25,
          sectionDescription: 'Upload images and videos for your loved ones.',
          sectionInitialButton: {
            redirectionLink: '/digital-files',
          },
          iscompletedKey: 'user_digital_file_count',
        },
        {
          sectionIcon: 'assets/images/dashboard/upload-will.png',
          sectionStep: 19,
          sectionTitle: 'Upload Important Documents',
          sectionName: 'will_files',
          lastRequestStage: 26,
          sectionDescription: 'Wills, trusts, power of attorneys, health care directives, birth certificates, marriage license, copies of drivers license, passports.\nAll these documents will be saved on Blockchain.',
          sectionInitialButton: {
            redirectionLink: '/will-files',
          },
          iscompletedKey: 'user_will_file_count',
        },
      ],
    },
  ],
};
