import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * professional agents service
 */
export class AgentsService {
  private BASE_URL:string;

  private typeModalSource:any;
  public typeModalObserve:Observable<boolean>;
  private emitChangeSource : any;

  public changeEmitted:Observable<string>;
  /**
 * constructor
 */
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.BASE_URL;
    this.typeModalSource = new BehaviorSubject({});
    this.typeModalObserve = this.typeModalSource.asObservable();
    this.emitChangeSource = new BehaviorSubject({});
    this.changeEmitted = this.emitChangeSource.asObservable();
  }
  /**
   * Called after the business organization type is added/edited successfully
   */
  public triggerAfterTypeAdd():void {
    this.typeModalSource.next(true);
  }
  /**
   *
   * emit changes
   * @param {any} change
   */
  public emitChange(change: any) {
    this.emitChangeSource.next(change);
  }
  /**
   * @param {any} data
   * @return {Observable}
   * add business organization type
   */
  public addAgent(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/agent-save`, data);
  }
  /**
   * @param {any} data
   * @return {Observable}
   * view business organization type
   */
  public viewAgent(data:any):Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent/${data}`);
  }
  /**
   * @param {any} data
   * @return {Observable}
   * edit business organization type
   */
  public editAgent(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/agent-update`, data);
  }


  /**
   * @param {any} data
   * @return {Observable}
   * delete business organization type
   */
  public deleteAgent(data:any):Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/agent/${data}`, null);
  }
  /**
   * Get all agents
   *
   *
   * @return {Observable}
   */
  public getAllAgents():Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent-list`);
  }

  /**
   * Get all professionals
   * @param [page] 
   * @param [length] 
   * @param [sort] 
   * @param [search] 
   * @returns all professionals 
   */
  public getAllProfessionals(page:number = 1, length:number =10, sort?:string, search?:string):Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/professional-list-paginate?page=${page}&length=${length}&sort=${sort}&search=${search}`)
  }

  /**
   * pagination for agents
   * @param{string}search
   *
   * @return {Observable}
   */
  public getAgentsPerSearch(search?:string):Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent-list-paginate?search=${search}`);
  }
  /**
   *  list for agents
   * @param{number}page
   * @param{number}length
   * @param{string}sort
   * @param{string}search
   *
   * @return {Observable}
   */
  public getAgents(page?:number, length?:number, sort?:string, search?:string):Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent-list-paginate?page=${page}&length=${length}&sort=${sort}&search=${search}`);
  }

  /**
   *  list for managers
   * @param{number}page
   * @param{number}length
   * @param{string}sort
   * @param{string}search
   *
   * @return {Observable}
   */
  public getManagers(page?:number, length?:number, sort?:string, search?:string):Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent-list-paginate?type=manager&page=${page}&length=${length}&sort=${sort}&search=${search}`);
  }
  /**
   * @param {any} data
   * get advisor types
   * @return {Observable<APIResponseModel>}
   */
  public getAdvisorTypes(data:any):Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/professional-type-list?roletype=${data}`);
  }
}
