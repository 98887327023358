import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PipeModule } from 'src/app/pipes/pipes.module';
import { SharedModule } from '../../sharedComponent/shared.module';
import { NavbarComponent } from './navbar.component';

@NgModule({
  declarations: [NavbarComponent],
  imports: [CommonModule, RouterModule, FontAwesomeModule, PipeModule, SharedModule],
  exports: [NavbarComponent],
})
/**
 * nav bar module to reuse across component
 */
export class NavBarModule { }
