import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SlugService } from 'src/app/services/slug.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-client-import',
  templateUrl: './verify-client-import.component.html',
  styleUrls: ['./verify-client-import.component.css'],
})
/**
 * verif import of client from professional
 */
export class VerifyClientImportComponent implements OnInit {
  public spinner: boolean;
  public confirmLoader: boolean;
  public agentTerm: string;
  public orgLogo: string;
  public orgLogoPath: string;

  /**
 * constructor
 */
  constructor(
    private activeRouter: ActivatedRoute,
    private toastService: ToastrService,
    private router: Router,
    private userService: UserService,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    private localStorageService: LocalStorageService,
    private slugService: SlugService,

  ) { }
  /**
 * loaded initially
 */
  ngOnInit(): void {
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      } else {
        this.orgLogoPath = '';
        this.orgLogo = '';
      }
    });
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    // Get token from url
    const queryParams = this.activeRouter.snapshot.queryParamMap;
    const token = queryParams.get('token');
    if (!token) {
      this.invalidTokenProcess('Invalid token');
    }

    // confirm account
    this.confirmLoader = true;
    this.spinner = true;
    this.userService.verifyImport({ token }).subscribe((response: APIResponseModel) => {
      this.confirmLoader = false;
      if (response.status) {
        this.toastService.success(response.message);
        this.spinner = false;
      }
    }, (exception) => {
      console.log(exception)
      switch (exception.status) {
        case 422:
          this.invalidTokenProcess(exception?.['error']?.message);
          break;
        default:
          this.spinner = false;
          void this.slugInterceptorService.navigate(['page-not-found']);
          this.confirmLoader = false;
      }
    });
  }

  /**
   * Redirect to login
   */
  public redirectToLogin(): void {
    if (this.confirmLoader) return;
    void this.slugInterceptorService.navigate(['login']).finally(() => {
      // Reload the page after navigating
      window.location.reload();
    });
  }

  /**
   * Invalid token process
   * @returns token process 
   */
  private invalidTokenProcess(msg: string): void {
    this.spinner = true;
    this.toastService.error(`${msg}, redirecting to Login`);
    setTimeout(() => {
      void this.slugInterceptorService.navigate(['login']);
      this.spinner = false;
    }, 3000);
    return;
  }

  /**
   * on click logo
   */
  public onClickLogo() {
    void this.slugInterceptorService.navigate(['/']);
  }
}
