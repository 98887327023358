<div class="scroll-hidden">

        <app-navbar *ngIf="this.userType === 'Consumer'"></app-navbar>
        <div class="p-2 lg:px-12 lg:py-24 container mx-auto">

            <ng-container>
                <div class="title__wrap text-center mb-4">
                    <h1 class="text-semibold text-accent pb-2 text-darkblue"
                        [ngClass]="mobile ? 'fs-bhead' : 'fs-title'">
                        Account Deactivated
                    </h1>
                </div>
                    <h3 class="text-gray-700 font-semibold text-xl mb-4 mx-4">
                        This account has been deactivated as the Executor or Digital Vault Custodian has reported the
                        account holder as deceased. For further assistance or inquiries, please contact our support team at <a href="mailTo:'support@legacynow.com'" class="text-lg underline text-darkblue fs-content">
                            support@legacynow.com</a>
                    </h3>
            </ng-container>
        </div>

        <!-- <app-consumer-landing-footer></app-consumer-landing-footer> -->
</div>