<app-common-modal id="payment-process" *ngIf="!isLoginUrl" class="app-common-modal">
    <div class="relative">
        <div class="w-full sm:p-6">
            <div class="ml-auto mr-auto">
                <!-- <img src="assets/images/payment/payment-process.svg" alt="payment-process" class="mb-5 w-40 h-40 ml-auto mr-auto"> -->
                <svg width="20" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" class="w-20 h-20 mx-auto mb-8">
                  <defs>
                    <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                      <stop stop-color="rgb(0,61,133)" stop-opacity="0" offset="0%"></stop>
                      <stop stop-color="rgb(0,61,133)" stop-opacity=".631" offset="63.146%"></stop>
                      <stop stop-color="rgb(0,61,133)" offset="100%"></stop>
                    </linearGradient>
                  </defs>
                  <g fill="none" fill-rule="evenodd">
                    <g transform="translate(1 1)">
                      <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="3">
                        <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s"
                          repeatCount="indefinite"></animateTransform>
                      </path>
                      <circle fill="rgb(0,61,133)" cx="36" cy="18" r="1">
                        <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s"
                          repeatCount="indefinite"></animateTransform>
                      </circle>
                    </g>
                  </g>
                </svg>
                <h3 class="text-darkblue text-semibold text-center text-lg mb-4">
                  Processing Your Transaction
                </h3>
                <p class="text-md text-grey py-1 tracking-wide text-regular text-center" *ngIf="!onlyShowProcessHeader">
                  Please wait while we process your transaction, this may take up to 20 seconds. Please do not close your window.
                </p>
            </div>
        </div>
    </div>
</app-common-modal>
