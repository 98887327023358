import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { FileValidationOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PropertyService } from 'src/app/services/property.service';
import { ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN, LIST_DELETE, LIST_NEW, LIST_UPDATE, fileValidationOptions } from 'src/constants/application.const';
import { ValidationHelper } from '../../../helper/validation.helper';
import { FileListingEmitModel, FileUploadSettingsModel } from '../../../sharedComponent/file-listing/file-listing.component';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-add-valuable-details',
  templateUrl: './add-valuable-details.component.html',
})
/**
 * Add valuable component
 */
export class AddValuableDetailsComponent implements OnInit {
  @Output() toggleModalEmitter = new EventEmitter<any>();
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() isNewAsset: boolean = true;
  @Input() clientHasPaidSubscription:boolean = false;
  public valuableForm: FormGroup;
  public valuableTypes: Array<any>;
  public submitLoader: boolean;
  public isImageFile: boolean = false;
  public isDigitalFiles: boolean = false;
  public userType: string;
  public requestId: string;
  public PIC_URL: string;
  public fileValidationOptions: FileValidationOptionsInterface;
  public file: any;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public preDefinedLoader: boolean = false;
  public isFileDropWhenNoDocumentSelected: boolean;
  public activateFileUploadLoader: boolean;
  public filePercentage: any;
  public clientRequestId: string;
  public valueFilesArray: Array<any> = [];
  public deletedImage: string;
  public updateSubscription: Subscription;
  public fileUploadSettings: FileUploadSettingsModel;
  private hasPaidSubscription: boolean;
  isConsumer: boolean;


  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private propertyService: PropertyService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private toastrService: ToastrService,
    private validation: ValidationHelper,
    private subscriptionService: ConsumerSubscriptionService,
  ) {
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.hasPaidSubscription = r);
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.valuableTypes = [];
    this.submitLoader = false;
    this.getAccessControl();
    this.deletedImage = '';

    this.fileUploadSettings = {
      fileValidationOptions:fileValidationOptions,
      hideFileOperations:true,
      allowMultiple:true,
      isDigitalFiles:true,
      displayFileName:true
    }
    this.commonHelper.clearfileHanlderObservableEvents();
    this.getValuableTypes();
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.isConsumer = this.userType === 'Consumer';
    this.initForm();

    this.resetForm();
    if (!this.canAccessForm) {
      this.valuableForm.disable();
    }
    this.clientRequestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
  }


  /**
   *
   * @private
   */
  private initForm() {
    this.valuableForm = this.formBuilder.group({
      valuable_type_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
      valuable_type_other: ['', { validators: [Validators.maxLength(50), Validators.pattern(ALPHA_NUMERIC_WITH_SPECIAL_CHARACTERS_PATTERN)] }],
      price: ['', { updateOn: 'blur', validators: [Validators.required] }],
      summary: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(500)] }],
      value_image: [''],
      id: [''],
      is_image_deleted: ['0'],
      remove_image: [''],
    });

    Object.keys(this.valuableForm.controls).forEach(controlName => {
      this.valuableForm.get(controlName)?.valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.valuableForm.get(controlName))
      );
    });
  }


  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.valuableForm);
  }


  /**
   * Get valuable types
   */
  public getValuableTypes(): void {
    this.commonService.getValuablesTypes().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.valuableTypes = this.commonHelper.convertToOptionsFormat(response.data, 'id', 'name')
          .sort(this.commonHelper.compareDisplayText);
        this.commonHelper.placeAtPointOfArray(this.valuableTypes, (e) => e.displayText.toLowerCase() == 'other');
        this.resetForm();
      }
    });
  }


  /**
   * file drop
   * restrict file upload for file drop when no document selected
   * @param data
   */
  public isFileDrop(data) {
    this.isFileDropWhenNoDocumentSelected = data;
  }


  /**
   * setter for editing business details
   *
   * @param {any} data
   */
  @Input() set editData(data: any) {
    this.valueFilesArray = [];
    this.deletedImage = '';

    if (data?.value_image) {
      let name = data.value_image.split(',');
      let extension = data.file_extension.split(',');
      for (let i = 0; i < name.length; i++) {
        if (name[i] != 'null') {
          console.log('NAME', name);
          if (name[i].length > 0) {
            const files = {
              name:name[i],
              type: extension[i],
            };
            this.valueFilesArray.push(files);
          }
        }
      }
    }
    if (data && Object.keys(data).length) {
      if (data?.price) {
        data.price = this.commonHelper.toCurrency(data.price, true); // Commas
      }
      this.valuableForm.patchValue({
        ...data,
        value_image: [''],
      });
    }
    this.commonService.filePreviewObservableEvents({ isPreviewProcess: false });
  }


  /**
   * Updates price value
   * @return price value
   * @param value
   */
  public updatePriceValue(value: any): any {
    if (value) {
      this.valuableForm.patchValue({
        price: value,
      });
    }
  }


  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.valuableForm.controls;
  }


  /**
   * change event listener for organisation type
   *
   * @param {any} data
   */
  public optionChangeEmitterValuableType(data: any): void {
    if (!data) return;
    this.valuableForm.patchValue({
      valuable_type_id: data.value,
    });
  }


  /**
   * Upload file
   *
   * @param files
   */
  public fileUploadChangeEmitter(files: any): void {
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        this.valueFilesArray.push(file);
      }
    }
  }


  /**
   * delete file
   *
   * @param filename
   * @param {number} index - The index of the file to delete in valueFilesArray.
   */
  public deleteImage(filename: string, index: number): void {
    if (this.valueFilesArray[index] instanceof File) {
      this.valueFilesArray.splice(index, 1);
      return;
    } else {
      if (this.deletedImage == '') {
        this.deletedImage = this.valueFilesArray[index].name;
      } else {
        this.deletedImage = `${this.deletedImage},${this.valueFilesArray[index].name}`;
      }
    }
    this.valueFilesArray.splice(index, 1);
    this.valuableForm.patchValue({
      is_image_deleted: '1',
      remove_image: this.deletedImage,
    });
  }


  public fileListingListener($event: FileListingEmitModel) {
    if ($event.image_delete) {
      const file = $event.image_delete;
      this.deleteImage(file, this.valueFilesArray.findIndex((e => e.name == file)));
    }
    if($event.fileUploadChange){
      this.fileUploadChangeEmitter($event.fileUploadChange);
    }
    if($event.isFileDropWhenNoDocumentSelected){
      this.isFileDrop($event.isFileDropWhenNoDocumentSelected);
    }
  }


  /**
   * add valuable
   */
  public onSubmit(): void {
    if (!this.valuableForm.valid) {
      this.canEdit && this.valuableForm.markAllAsTouched();
      this.commonHelper.scrollToFirstInvalidControl(this.valuableForm);
      return;
    }

    this.submitLoader = true;
    const requestData = {
      ...this.valuableForm.value,
      roletype: this.userType,
      request_id: this.userType === 'Consumer' ? this.localStorageService.getUserData()?.request?.id : this.requestId,
    };
    requestData.price = this.commonHelper.commaSeparateNumberClean(requestData.price, null);

    const formData = new FormData();

    for (const file of this.valueFilesArray) {
      console.log(file);
      if (file instanceof File) {
        console.log('true');
        formData.append('value_image[]', file);
      }
    }
    // Append other form data values
    Object.keys(requestData).forEach((key) => {
      if (requestData[key] === undefined) return;
      formData.append(key, requestData[key]);
    });

    this.propertyService.addValuable(formData).subscribe(
      (response: APIResponseModel) => {
        this.submitLoader = false;

        if (response.status) {
          // Handle success
          this.commonHelper.updateLocalstorageRequestStage(response.data);
          this.toggleModalEmitter.emit({
            ...response.data,
            listStatus: this.valuableForm.value.id ? LIST_UPDATE : LIST_NEW,
          });
          requestData.id ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();
          this.closeModel();
          this.valuableForm.value.value_image = '';
          this.valueFilesArray = []; // Clear the array after successful submission
        }
      },
      (exception: any) => {
        // Handle errors
        this.commonHelper.httpResponseHandler(exception?.error);
        this.submitLoader = false;
      },
      () => this.submitLoader = false,
    );
  }


  /**
   * delete valuable details
   */
  public deleteValuable(): void {
    // ACL check
    if (!this.permissions?.['delete']) {
      this.toastrService.info('You do not have permission to delete items for this client.');
      return;
    }

    this.submitLoader = true;
    let sendingData: {} = {
      is_delete: '1',
      id: this.valuableForm.value.id,
      roletype: this.userType,
    };
    if (this.userType !== 'Consumer') {
      sendingData = { ...sendingData, request_id: this.requestId };
    }

    //  Delete valuable
    this.propertyService.addValuable(sendingData).subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.toggleModalEmitter.emit({
          id: this.valuableForm.value.id,
          listStatus: LIST_DELETE,
        });
        this.commonHelper.toastrDeleteSuccess();
      }
      this.submitLoader = false;
      this.closeModel();
    }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
  }


  /**
   * close modal
   */
  public closeModel(): void {
    if(!this.submitLoader) {
      this.modalService.close('add-valuable-modal');
      this.resetForm();
    }
  }


  /**
   * Reset form
   */
  public resetForm(): void {
    this.valuableForm.reset({
      valuable_type_id: this.valuableTypes[0]?.value,
      is_image_deleted: ['0'],
      remove_image: [''],
    });
    this.commonHelper.clearfileHanlderObservableEvents();
  }


  /**
   * Whether the 'Other' field is being used.
   */
  get useOther(): boolean {
    return this.formGet.valuable_type_id.value ===
      this.valuableTypes.find((e) => e.displayText.toLowerCase() === 'other')?.value;
  }


  /**
   * If user can edit existing asset, or add a new asset.
   */
  get canEdit(): boolean {
    return this.commonHelper.canEditSection(this.isNewAsset, this.permissions)&& (this.hasPaidSubscription || this.clientHasPaidSubscription)
  }


  /**
   * Establish ACL Permissions. Consumer is assigned full access.
   */
  public getAccessControl(): void {
    if (this.userType === 'Consumer') {
      this.permissions = {
        view: true,
        add: true,
        edit: true,
        delete: true,
      };
    }
    console.log('this.permissions', this.permissions);
  }
}
