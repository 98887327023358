<app-common-modal id="{{modal_id}}" class="app-common-modal" [heightClass]="'min-h-75'">
  <div class="w-full relative rounded-md">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
         (click)="closeModel()">
    <div class="mb-4">
      <div class="flex flex-row items-baseline">
        <div>
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide w-11/12">
            {{agentTerm}} Details
          </h4>
          <p class="text-grey text-regular pt-2 text-sm">
            By including the below information, you are certifying to us that you are authorized to do so.
          </p>
        </div>
      </div>
    </div>
    <form [formGroup]="advisorForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
        <div class="col-span-12"  #scrollToTop>
          <app-bubble-radio
            [options]="namingTypeOptions"
            [selectedIndex]="selectedNamingTypeEmit.index"
            (onClick)="onClickNamingTypOptions($event)">
          </app-bubble-radio>
        </div>
        <ng-container *ngIf="selectedNamingType?.id == 'i'">
          <div class="col-span-12 lg:col-span-4">
            <label for="first-name" class="text-label-grey text-sm text-regular">
              First Name*
            </label>
            <input type="text"
                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                   placeholder="First Name" formControlName="first_name" name="first-name" id="first-name"
                   [ngClass]="formGet?.first_name.errors && formGet?.first_name.touched?'form-input-error':'form-input-focus'">
            <p *ngFor="let error of getErrors('first_name')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <div class="col-span-12 lg:col-span-4">
            <label for="middle_name" class="text-label-grey text-sm text-regular">
              Middle Name
            </label>
            <input type="text"
                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                   id="middle_name" formControlName="middle_name" placeholder="Middle Name"
                   [ngClass]="formGet?.middle_name.errors && formGet?.middle_name.touched?'form-input-error':'form-input-focus'">
            <p *ngFor="let error of getErrors('middle_name')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <div class="col-span-12 lg:col-span-4">
            <label for="last-name" class="text-label-grey text-sm text-regular">
              Last Name*
            </label>
            <input type="text"
                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                   placeholder="Last Name" formControlName="last_name" name="last-name" id="last-name"
                   [ngClass]="formGet?.last_name.errors && formGet?.last_name.touched?'form-input-error':'form-input-focus'">
            <p *ngFor="let error of getErrors('last_name')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedNamingType?.id == 'c'">
          <div class="col-span-12">
            <label for="company-name" class="text-label-grey text-sm text-regular">
              Company Name*
            </label>
            <input type="text"
                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                   placeholder="Company Name" formControlName="first_name" name="first-name" id="company-name"
                   [ngClass]="formGet?.first_name.errors && formGet?.first_name.touched?'form-input-error':'form-input-focus'">
            <p *ngFor="let error of getErrors('first_name')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
        </ng-container>
        <div class="col-span-12">
          <label for="select_professional_type" class="text-label-grey text-sm text-regular">
            {{agentTerm}} Type*
          </label>
          <input type="text" id="select_professional_type"
                 [ngClass]="{
                   'form-input-error':formGet?.professional_type.errors && formGet?.professional_type.touched,
                   'form-input-focus':!(formGet?.professional_type.errors && formGet?.professional_type.touched)
                   }"
                 class="h-10 mt-1 sm:h-12 input py-3 px-4 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 placeholder="e.g. Wealth Manager" formControlName="professional_type" name="input_professional_type">
          <p *ngFor="let error of getErrors('professional_type')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>

        <div class="my-6 col-span-12">
          <div class="flex flex-row items-baseline">
            <div>
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Phone Number and Email Address
              </h4>
            </div>
          </div>

          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
            <div class="col-span-12">
              <label for="email" class="text-label-grey text-sm text-regular">
                Email Address
              </label>
              <input type="email"
                     class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                     placeholder="e.g. marktylor@gmail.com"
                     [ngClass]="formGet?.email.errors && formGet?.email.touched?'form-input-error':'form-input-focus'"
                     formControlName="email" name="email" id="email" (focusout)="formGet?.email.markAsTouched()">
              <p *ngFor="let error of getErrors('email')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12">
              <label for="phone-no" class="text-label-grey text-sm text-regular">
                Phone Number
              </label>
              <app-phone-input id="phone-no" [placeholder]="'e.g. 8665044754'" [phoneNumber]="advisorForm.value.phone"
                               [countryCode]="countryCode" [disabled]="!canAccessForm"
                               [errorClassTrigger]="formGet?.phone.errors && formGet?.phone.touched"
                               [uniqueId]="'phone-custodian'"
                               (onChange)="detectChangePhonenumber($event)"
                               [form]="advisorForm" (focusout)="formGet?.phone.markAsTouched()">
              </app-phone-input>
              <p *ngFor="let error of getErrors('phone')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
          </div>
        </div>

        <div class="mt-1 col-span-12">
          <div class="mb-4">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Address
            </h4>
          </div>
          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
            <div class="col-span-12">
              <label for="country" class="text-label-grey text-sm text-regular">Country</label>
              <app-country-search-component id="country" (optionChangeEmitter)="optionChangeListenerCountry($event)"
                                            [defaultSelected]="formGet?.country?.value" [disabled]="!canAccessForm"
                                            [enableError]="formGet?.country.errors && formGet?.country.touched"></app-country-search-component>
              <p *ngFor="let error of getErrors('country')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-8 relative move-map" #moveMapHere>
              <label for="address" class="text-label-grey text-sm text-regular">
                Street Address
              </label>
              <input type="text" id="address" #search (keyup)="onAddressChange(search)"
                     (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null" (input)="onFocusAddress()"
                     class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                     placeholder="e.g Main St" name="address" formControlName="address"
                     [ngClass]="formGet?.address.errors && formGet?.address.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('address')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 lg:col-span-4">
              <label for="apt_number" class="text-label-grey text-sm text-regular">Apartment / Suite #</label>
              <input name="apt_number" id="apt_number" formControlName="apt_number"
                     class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                     [ngClass]="formGet?.apt_number.errors && formGet?.apt_number.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('apt_number')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12" *ngIf="selectedCountryName!==unitedKingdom">
              <label for="state-Province" class="text-label-grey text-sm text-regular">State/Province</label>
              <app-state-search-component id="state-province" [countryCode]="formGet?.country?.value"
                                          [defaultSelected]="formGet?.state?.value"
                                          (optionChangeEmitter)="optionChangeListenerState($event)"
                                          tabindex="0" [disabled]="!canAccessForm"
                                          (focusout)="markAsTouchedIndividual('state')"></app-state-search-component>
              <p *ngFor="let error of getErrors('state')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class="col-span-12 md:col-span-6 relative ">
              <label for="city" class=" text-label-grey text-sm text-regular ">City</label>
              <app-city-search id="city" [stateCode]="formGet?.state?.value"
                               [enableError]="formGet?.city.errors && formGet?.city.touched"
                               [defaultSelected]="formGet?.city?.value"
                               (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0"
                               [disabled]="!canAccessForm"
                               (focusout)="markAsTouchedIndividual('city')"></app-city-search>
              <p *ngFor="let error of getErrors('city')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <div class=" col-span-12 md:col-span-6 ">
              <label for="zipcode" class="text-label-grey text-sm text-regular">Zip/Postal
                Code</label>
              <input type="text" (focusout)="markAsTouchedIndividual('zipcode')"
                     class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
                     placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                     name="zipcode" id="zipcode" formControlName="zipcode"
                     oninput="this.value = this.value.toUpperCase()"
                     (focus)="validateZipCode()"
                     [ngClass]="formGet?.zipcode.errors && formGet?.zipcode.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('zipcode')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
          </div>
        </div>

        <div class="flex flex-col-reverse sm:flex-row gap-4 col-span-12 justify-between items-center mt-8">
          <app-button-loader class="w-full sm:w-auto" *ngIf="this.advisorForm['controls']['id'].value"
                             [buttonText]="'Remove Provider'" [loader]="submitLoaderDelete" [buttonType]="'button'"
                             [outlineOnly]="true"
                             (click)="deleteAdvisor()" [valid]="!submitLoader">
          </app-button-loader>
          <app-button-loader class="w-full sm:w-auto"
                             [buttonText]="this.advisorForm['controls']['id'].value ? 'Update Provider' : 'Save and Continue'"
                             [valid]="!submitLoaderDelete" [loader]="submitLoader" buttonType="'submit'">
          </app-button-loader>
        </div>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>
