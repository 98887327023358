<main class="relative">
  <!-- BEGIN: Navbar Wrapper -->
  <app-consumer-landing-navbar></app-consumer-landing-navbar>
  <!-- END: Navbar Wrapper -->

  <!-- BEGIN: Banner Wrapper -->
  <div class="relative flex items-center justify-center banner-padding-hfull mh-700-height">
    <div class="container mx-auto z-30">
      <div class="flex items-center text-white">
        <div class="grid grid-cols-12 gap-4 xl:gap-12">
          <div class="col-span-12 xl:col-span-10">
            <div class="mt-8 lg:mt-12">
            <h1 class="home-banner__head text-semibold fs-bhead text-regular">
              Now, have peace of mind knowing you can easily and effectively organize your estate data.
            </h1>
            <p class="home-banner__content fs-content mt-4 mb-8 lg:mb-12 text-regular">
              This is the greatest gift you can give yourself and your loved ones. LegacyNOW supports you through life and
              provides the people you love information they need when you pass.
            </p>
            <div class="btn-wrap flex items-center flex-wrap gap-4 md:gap-6 lg:gap-8">
              <button (click)="onClickLearnMore()"
              class="button-dark-blue whitespace-nowrap fs-menu mr-5 sm:mr-3 text-regular shadow-sm fs-menu bg-dark-blue">
              Learn More
            </button>
            <button (click)="openModal('onSiteGuide')"
              class="button-dark-blue button-get-started button-onsite-guide whitespace-nowrap fs-menu mr-5 sm:mr-3 shadow-sm fs-menu relative">
              Learn About Aida,
              <span class="block">Your On-site Guide</span>
              <div class="absolute avatar-img">
                <img src="assets/images/intro-video.png" alt="" class="avatar">
                <span class="play">
                  <img src="assets/icons/video-play.svg" alt="" >
                </span>
              </div>
            </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <video playsinline [loop]="true" [autoplay]="true" [muted]="true"
           class="absolute banner__bg-video z-10 object-cover w-full h-full">
      <source src="assets/images/professional/banner-clip.mp4" type="video/mp4" />
      <source src="assets/images/professional/banner-clip.ogg" type="video/ogg" />
      Your browser does not support the video tag or the file format of this video.
    </video>
  </div>
  <!-- END: Banner Wrapper -->

  <!-- BEGIN: About Digital Vault Wrapper -->
  <div class="section why-legacy-now__wrap">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 gap-6 lg:gap-8" id="aboutDigitalVault">
        <div class="title__wrap col-span-12">
          <h2 class="text-primary text-semibold fs-title text-regular pb-4 text-center">
            About LegacyNOW's Digital Vault
          </h2>
            <p class="fs-content text-regular text-content text-left">
              LegacyNOW is a best-in-class estate and legacy data management platform. Our Digital Vault includes data
              protection, secure document storage, privacy management, and legacy planning. Best of all, you can grant
              access to your personal Digital Vault to those you select when you pass. This thoughtful gift provides the
              loved ones you entrust with the information needed to settle your estate.
            </p>
        </div>

        <div class="title__wrap col-span-12">
          <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-center">
            Key features the LegacyNOW Digital Vault includes:
          </h1>
          <ul class="space-y-1">
            <li class="li-disc fs-content text-regular text-content text-justify">
              <strong>Security:</strong> LegacyNOW securely stores all data associated with client records in a single
              database using a unique proprietary algorithm that provides additional layers of protection.
            </li>
            <li class="li-disc fs-content text-regular text-content text-justify">
              <strong>Data Organization:</strong> Organize your digitized assets in the Digital Vault for easy access,
              location, and management throughout your lifetime.
            </li>
            <li class="li-disc fs-content text-regular text-content text-justify">
              <strong>Document Storage:</strong> Storage of important documents such as legal
              documents, financial records, medical records, your will, trust documents,
              insurance policies, and more is now at your fingertips.
            </li>
            <li class="li-disc fs-content text-regular text-content text-justify">
              <strong>Legacy Planning:</strong> Consolidate information throughout your lifetime, to assist
              those you love to ease their ability to resolve your estate after you pass.
            </li>
            <li class="li-disc fs-content text-regular text-content text-justify">
              <strong>Collaboration:</strong> Secure collaboration that enables you to share specific
              documents or information with selected individuals while maintaining privacy
              and control.
            </li>
            <li class="li-disc fs-content text-regular text-content text-justify">
              <strong>Access Control:</strong> You control who has access to your Digital Vault, what level of access
              they have, and what information they can see. This may include granting read-only access, limited access,
              editing permissions, and more.
            </li>
            <li class="li-disc fs-content text-regular text-content text-justify">
              <strong>Integration:</strong> Easily integrate with other services and tools, such as estate
              planning software and financial management platforms.
            </li>
            <li class="li-disc fs-content text-regular text-content text-justify">
              <strong>Mobile Access:</strong> Access your Digital Vault from your smartphone and tablet.
            </li>
          </ul>
        </div>
        <div class="col-span-12">
          <img src="assets/images/consumer/new-digital-vault/digital-vault.png" width="100%" height="100%" alt="legacy-now">
        </div>
      </div>
    </div>
  </div>
  <!-- END: About Digital Vault Wrapper -->

  <!-- BEGIN: Why Choose Wrapper-->
  <div class="section why-choose-legacynow__wrap">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-start gap-4 md:gap-6 xl:gap-12">
        <div class="col-span-12 lg:col-span-6 mb-8 lg:mb-0">
          <div class="grid-flow-row grid gap-6 lg:gap-8">
              <div class="col-span-12 lg:col-span-6 bg-box p-5 md:p-6 lg:p-10 rounded-xl">
                <img src="assets/images/consumer/landing/edit-icon.svg" class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16" alt="Legacy-now">
                <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Access at your fingertips</h4>
                <p class="text-regular text-subcontent fs-subcontent">
                  As life changes, so does your information. LegacyNOW's cloud-based platform makes it easy to update and
                  change your information at anytime.
                </p>
              </div>
              <div class="col-span-12 lg:col-span-6 bg-box p-5 md:p-6 lg:p-10 rounded-xl ">
                <img src="assets/images/consumer/landing/report-icon.svg" class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16" alt="Legacy-now">
                <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">More than Estate Planning</h4>
                <p class="text-regular text-subcontent fs-subcontent">
                  Your life accomplishments and wishes are unique. Sharing common end-of-life documents may not meet all your
                  legacy needs. LegacyNOW provides an online archive, managed by you, of all your loved ones needs to
                  know after you pass, based on the role they play in your life.
                </p>
              </div>
                <div class="col-span-12 lg:col-span-6 bg-box p-5 md:p-6 lg:p-10 rounded-xl">
                  <img src="assets/images/consumer/landing/web-eye.svg" class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16" alt="Legacy-now">
                  <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">You’re in control</h4>
                  <p class="text-regular text-subcontent fs-subcontent">
                    Organizing your legacy information for your loved ones to access when you pass is important; however, you retain
                    control of extending them access prior to your passing. Its all up to you.
                  </p>
                </div>
                <div class="col-span-12 lg:col-span-6 bg-box p-5 md:p-6 lg:p-10 rounded-xl">
                  <img src="assets/images/consumer/landing/protected.svg" class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16" alt="Legacy-now">
                  <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Keep files protected from accidental loss</h4>
                  <p class="text-regular text-subcontent fs-subcontent">
                    Accidents happen and things get overlooked. Physical documents and policies stored around your home can be lost.
                    Once stored in the Digital Vault they are always at your fingertips.
                  </p>
                </div>

            <!-- <div class="row-start-1 row-span-2 mt-8 lg:mt-0 md:mt-0 mb-6 row-start-2">
            </div> -->
          </div>

          <!-- <div class="md:grid md:grid-rows-1 mt-8 md:mt-0 lg:mt-8 grid-flow-col gap-6 lg:gap-8">
            <div class="row-end-2 row-span-2">

            </div>
            <div class="row-start-1 row-span-2 md:row-start-2">
            </div>
          </div> -->
        </div>
        <div class="col-span-12 lg:col-span-6 xl:pl-8 sticky">
          <div class="title__wrap mb-6">
            <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-left">
              Why LegacyNOW?<br>Benefits to you and your family
            </h1>
            <p class="fs-content text-regular text-content text-left">
              LegacyNOW protects your most valuable information, documents, and other materials, and leaves your loved ones a
              road map of your estate when they need it the most.
            </p>
          </div>
          <div class="title__wrap mb-6">
            <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-left">Our values</h1>
            <p class="fs-content text-regular text-content text-left">
              LegacyNOW is dedicated to easing the burden many encounter when a loved one passes. We help people prepare for the unexpected so they can live their best life and set their loved ones up to do the same.
            </p>
          </div>
          <div class="btn-wrap mt-4">
            <button (click)="onClickDiscover()"
                    class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">
              Discover How
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Why Choose Wrapper-->

  <!-- BEGIN: How Legacy Now Works Wrapper -->
  <div class="section how-legacynow-works_wrap" id="how-legacynow-works">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 gap-5 lg:gap-8 items-center pb-6">
        <div class="title__wrap col-span-12 lg:col-start-3 lg:col-span-8 pb-2 lg:pb-8">
          <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-left md:text-center">
            How LegacyNOW works
          </h1>
          <p class="fs-content text-regular text-content text-left md:text-center">
            LegacyNOW provides you and your loved ones with the expertise and tools needed to build your estate and legacy
            plan, quickly and accurately.
          </p>
        </div>
        <!-- Testimonial section navigating from landing page -->
        <div id="testimonialSection"></div>
        <div class="col-span-12 lg:col-span-6">
          <h4
            class="text-regular fs-title p-4 text-consumer-blue w-14 h-14 md:w-16 md:h-16 lg:w-20 lg:h-20 text-center bg-light-blue rounded-lg flex items-center justify-center">
            01
          </h4>
          <h2 class="text-medium tracking-wide text-consumer-blue text-xl sm:text-2xl xl:text-3xl my-2 lg:my-6">
            Create a secure and personalized Digital Vault
          </h2>
          <p class="text-regular text-accent-consumer fs-content">
            LegacyNOW's digital vault allows you to securely collect, store, and maintain your important information, service providers and subscription
            account details including properties, assets, and liabilities during your lifetime. It serves as a gift to your
            executors, heirs, and beneficiaries, making the estate resolution and transition process easier upon your passing.
          </p>
        </div>
        <div class="col-span-12 lg:col-span-6">
        <img src="assets/images/consumer/how-works/create-new.svg" width="90%" height="90%" class="float-right"
        alt="legacy-now">
        </div>
      </div>
      <div class="grid grid-cols-12 gap-5 lg:gap-8 items-center pb-6">
        <div class="col-span-12 lg:col-span-6 order-last lg:order-first">
          <img src="assets/images/consumer/how-works/secured-blockchain.svg" width="90%" height="100%" class="float-left"
            alt="legacy-now">
        </div>
        <div class="col-span-12 lg:col-span-6">
          <h4
            class="text-regular fs-title p-4 text-consumer-blue w-14 h-14 md:w-16 md:h-16 lg:w-20 lg:h-20 text-center bg-light-blue rounded-lg flex items-center justify-center">
            02</h4>
          <h2 class="text-medium tracking-wide text-consumer-blue text-xl sm:text-2xl xl:text-3xl my-2 lg:my-6">Secured on Blockchain and
            safely transferred to your Executor/ Heir/ Digital Vault Custodian</h2>
          <p class="text-regular text-accent-consumer fs-content">Blockchain provides proof of access and securely
            transferred to your preferred executor / custodian, making your digital estate data and its transfer secure. </p>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-5 lg:gap-8 items-center">
        <div class="col-span-12 lg:col-span-6">
          <h4
            class="text-regular fs-title p-4 text-consumer-blue w-14 h-14 md:w-16 md:h-16 lg:w-20 lg:h-20 text-center bg-light-blue rounded-lg flex items-center justify-center">
            03</h4>
          <h2 class="text-medium tracking-wide text-consumer-blue text-xl sm:text-2xl xl:text-3xl my-2 lg:my-6">
            Digital Vault Custodian
          </h2>
          <p class="text-regular text-accent-consumer fs-content">
            The person or persons you select as your Digital Vault Custodian are responsible for notifying LegacyNOW of
            your passing. Upon verification of their identity, this notification will grant access to your Digital Vault to those you have identified as
            Executor or otherwise, enabling them to initiate the estate resolution process efficiently.
          </p>
        </div>
        <div class="col-span-12 lg:col-span-6">
          <img src="assets/images/consumer/how-works/executor.svg" width="100%" height="100%" class="float-right"
            alt="legacy-now">
        </div>
      </div>
    </div>
  </div>
  <!-- END: How Legacy Now Works Wrapper -->

  <!-- BEGIN: Need Assistance Wrapper -->
  <!-- <div class="section need_assistance__wrap">
    <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
      <div class="col-span-12 lg:col-span-6">
        <picture>
          <source srcset="assets/images/consumer/need-assistance.webp" type="image/webp">
          <source srcset="assets/images/consumer/need-assistance.png" type="image/png">
          <img class="rounded-md" src="assets/images/consumer/need-assistance.png" width="100%" height="100%"
            alt="legacy-now">
        </picture>
      </div>
      <div class="col-span-12 lg:col-span-6 pt-8 pl-6 lg:pt-0">
        <h2 class="fs-title text-primary text-semibold mb-6">
          Need assistance from our Digital Vault Professional customer service?
        </h2>
        <p class="fs-content text-content text-regular mb-8">With the purpose of mutual profitability for your
          organization and value added benefits for the clients, their executors, and beneficiaries. If you are
          interested in exploring a strategic relationship, please contact us.</p>
      </div>

    </div>
  </div> -->
  <!-- END: Need Assistance Wrapper -->


  <!-- BEGIN: Trusted Experts -->
  <div class="section trusted-experts__wrap">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 gap-4 md:gap-5 lg:gap-8">
        <div class="title__wrap col-span-12 lg:col-start-3 lg:col-span-8 lg:pb-8">
          <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-center">
            LegacyNOW - A Trusted Expert
          </h1>
          <p class="fs-content text-regular text-content text-center">Automation, easy to use wizards, bite-size workflow,
            and proactive notifications will engage you to complete your portfolio with ease.</p>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-box rounded-lg p-5 md:p-6 lg:p-10">
          <img src="assets/images/consumer/landing/speed-simplicity.svg" class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16" alt="Speed & Simplicity">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Speed & Simplicity</h4>
          <p class="text-regular text-subcontent fs-subcontent">
            LegacyNOW provides a streamlined process for adding and managing data, through a user friendly interface.
          </p>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-box rounded-lg p-5 md:p-6 lg:p-10">
          <img src="assets/images/consumer/landing/security.svg" class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16" alt="Security">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Security</h4>
          <p class="text-regular text-subcontent fs-subcontent">
            World-class security, encryption and state of the art methods for securing personal information are employed.
          </p>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-box rounded-lg p-5 md:p-6 lg:p-10">
          <img src="assets/images/consumer/landing/c-support.svg" class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16" alt="Customer Support">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Customer Support</h4>
          <p class="text-regular text-subcontent fs-subcontent">
            Online videos and FAQs are readily available throughout the process. If you still have questions, our support
            team can be reached through a variety of channels including online forms, email and telephone.
          </p>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Trusted Experts -->

  <!-- BEGIN: Security Wrapper -->
  <div class="section">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
        <div class="col-span-12 lg:col-span-7">
          <h2 class="fs-title text-primary text-semibold mb-6">
            Security is our top priority with our state of the art Digital Vault technology
          </h2>
          <p class="fs-content text-content text-regular">
            All data is saved in a single location and our proprietary algorithm delivers additional layers of protection
          making it a best-in-class product.
          </p>
          <p class="fs-content py-3 md:py-4 text-content text-regular">
            Critical information, files and passwords are distributed into various storage locations for added protection.
          </p>
          <p class="fs-content text-content text-regular">
            Blockchain technology is used to ensure a secure password protection.
          </p>
        </div>
        <div class="col-span-12 lg:col-span-5 pb-28">
          <div class="relative ml-60">
            <picture>
              <source srcset="assets/images/security/security-big.webp" type="image/webp">
              <source srcset="assets/images/security/security-big.png" type="image/png">
              <img class="rounded-md overflow-hidden security-img"
                src="assets/images/security/security-big.png" alt="legacy-now">
            </picture>
            <div class="bg-dark-blue lg:border-r-4 lg:border-t-4 lg:border-white security-box absolute rounded-xl p-6">
              <img src="assets/images/security/secure-vault.svg" width="60" height="60" alt="Legacy-now">
              <h4 class="text-semibold text-white fs-subtitle pt-5 pb-2">Secure File Vault</h4>
              <p class="text-regular text-white fs-subcontent">A comprehensive digital archive with industry-leading
                security technology.
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- END: Security Wrapper -->


  <!-- BEGIN: Testimonial Wrapper-->
  <div class="section section-bottom">
    <app-testimonials [mobile]="mobile"></app-testimonials>
  </div>
  <!-- END: Testimonial Wrapper -->

  <!-- BEGIN: Advisor Insight Inbox -->
  <!--div class="section insight-inbox__wrap">
    <div class="grid grid-cols-9 gap-4 lg:gap-12">
      <div class="title__wrap col-span-12 md:col-start-2 md:col-span-9 pb-6">
        <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-center">For Advisors: <br>
          Insights sent
          directly to your inbox</h1>
        <p class="fs-content text-regular text-content text-center">Just sign up here and you'll receive news and
          articles that will help better serve your clients and outperform your competition.</p>
      </div>
      <div class="col-span-12">
        <div class="bg-transparent border rounded-md mx-auto md:w-5/6 lg:w-3/5 ">
          <form action="#" method="post"
            class="flex flex-wrap items-center justify-between rounded-md xl:h-16 md:flex-row bg-white">
            <div class="relative w-full">
              <input type="text" placeholder="Enter your name"
                class="flex-1 h-10 text-content text-sm md:text-lg md:px-4 m-1 text-gray-700 placeholder-gray-400 border-none appearance-none lg:h-12 focus:outline-none ">
              <div class="absolute top-1 right-3 md:right-10">
                <button type="submit"
                  class="flex items-center justify-center text-semibold text-sm text-white w-full sm:mx-2 p-2 m-1 rounded-md md:h-12 md:mx-6 lg:text-xl xl:h-12 sm:text-base md:p-0 bg-primary focus:outline-none ">Let
                  Me Know</button>
              </div>
            </div>

          </form>
        </div>

      </div>

    </div>
  </div-->
  <!-- END: Advisor Insight Inbox -->

  <!-- BEGIN: Footer Wrapper -->
  <app-consumer-landing-footer></app-consumer-landing-footer>
  <!-- END: Footer Wrapper -->
</main>

<!-- <app-aida-floating-video
  vaultVideo="assistanceHelp"
  tooltipText="Meet Aida, Your Virtual Assistant">
</app-aida-floating-video> -->

<app-demo-video-modal [vaultVideo]="vaultVideo" [isLink]="true"></app-demo-video-modal>
