<div class="lg:grid grid-cols-12 background-color-grey">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center my-4">
              <div class="col-span-1">
                <div class="w-16 my-2">
                  <img src="assets/images/executors/executors.svg" alt="digital vault">
                </div>
              </div>

              <div class="col-span-11">
                <div class="pl-4 sm:px-4 ml-8">
                  <h2 class="text-darkblue text-md sm:text-2xl break-words uppercase text-semibold">
                    Executors
                  </h2>
                  <p class="text-sm text-grey tracking-wide text-regular">This bit of
                    information is important, as it helps to make it clear that this is
                    genuinely you. Double-check your spelling and details to be
                    safe.
                  </p>
                </div>
              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-1/6'"></app-card-progress-bar>
              <!-- END: Progress Bar -->
              <div class="p-6">
                <div class="grid grid-cols-12 items-center gap-4 row-gap-4">
                  <!-- <div class="col-span-12 sm:col-span-8">
                    <div>
                      <div class="text-grey text-regular pt-1 text-sm text-justify ml-4" *ngIf="loading">
                        <p *ngFor="let _ of [].constructor(5)">
                          <app-shimmer-loading [loading]='true' [width]="'75%'" [height]="'12px'"></app-shimmer-loading>
                        </p>
                      </div>
                      <p class="text-grey text-regular pt-1 text-sm text-justify" *ngIf="!loading">
                        <fa-icon *ngIf="permissionInform==1" [icon]="faCheckCircle" class="green-tick"></fa-icon>
                        <fa-icon *ngIf="permissionInform==0" [icon]="faCircleXmark" class="red-tick"></fa-icon>
                        I hereby grant LegacyNOW the permission to inform the Executor(s) about the
                        existence of my Digital Vault Account upon completion of my Digital Vault Account setup.
                      </p>
                    </div>
                  </div>
                  <div class="col-span-12 sm:col-span-4">
                    <app-shimmer-loading [loading]="loading" class="w-full h-12">
                      <app-toggle-switch [defaultSelected]="permissionInform" [options]="toggleOptions"
                        (optionChangeEmitter)="onChangeToggleExec($event)">
                      </app-toggle-switch>
                    </app-shimmer-loading>
                  </div> -->
                  <!-- <div class="col-span-12 sm:col-span-8" *ngIf="permissionInform==0 && !loading">
                    <div>
                      <p class="text-grey text-regular pt-1 text-sm text-justify">
                        <fa-icon [icon]="faCheckCircle" class="green-tick"></fa-icon>
                        I hereby grant LegacyNOW the permission, AFTER MY DEATH, to inform the Executor(s) and/or
                        Authorized Representative(s) about the existence of my Digital Vault Account
                      </p>
                    </div>
                  </div> -->
                  <div class="col-span-12 sm:col-span-8">
                    <div>
                        <app-shimmer-loading [loading]="loading" class="w-full h-12">
                        <p class="text-grey text-regular pt-1 text-sm text-justify">
                        <fa-icon *ngIf="nominateExecutor==1" [icon]="faCheckCircle" class="green-tick"></fa-icon>
                        <fa-icon *ngIf="nominateExecutor!==1" [icon]="faCircleXmark" class="red-tick"></fa-icon>
                        I hereby request LegacyNOW to nominate an Executor for my Digital Vault
                       </p>
                      </app-shimmer-loading>
                    </div>
                  </div>
                  <div class="col-span-12 sm:col-span-4">
                    <app-shimmer-loading [loading]="loading" class="w-full h-12">
                      <app-toggle-switch [defaultSelected]="nominateExecutor" [options]="toggleOptions"
                                         (optionChangeEmitter)="toggleNominateExecutor($event)">
                      </app-toggle-switch>
                    </app-shimmer-loading>
                  </div>
                  <div class="col-span-12 sm:col-span-8" *ngIf="nominateExecutor">
                    <div>
                      <app-shimmer-loading [loading]="loading" class="w-full h-12">
                        <p class="text-grey text-regular pt-1 text-sm text-justify">
                        <fa-icon *ngIf="notifyExecutorImmediately==1" [icon]="faCheckCircle" class="green-tick"></fa-icon>
                        <fa-icon *ngIf="notifyExecutorImmediately!==1" [icon]="faCircleXmark" class="red-tick"></fa-icon>
                        I hereby grant LegacyNOW the permission to inform the Executor(s) and/or Authorized Representative(s) about the existence of your Digital Vault Account immediately
                      </p>
                    </app-shimmer-loading>
                    </div>
                  </div>
                  <div class="col-span-12 sm:col-span-4" *ngIf="nominateExecutor">
                    <app-shimmer-loading [loading]="loading" class="w-full h-12">
                      <app-toggle-switch [defaultSelected]="notifyExecutorImmediately" [options]="toggleOptions"
                                         (optionChangeEmitter)="toggleNotifyExecutorImmediately($event)">
                      </app-toggle-switch>
                    </app-shimmer-loading>
                  </div>
                  <div class="col-span-12 sm:col-span-8" *ngIf="nominateExecutor && notifyExecutorImmediately">
                    <div>
                      <app-shimmer-loading [loading]="loading" class="w-full h-12">
                        <p class="text-grey text-regular pt-1 text-sm text-justify">
                        <fa-icon *ngIf="accessVaultToExecutor==1" [icon]="faCheckCircle" class="green-tick"></fa-icon>
                        <fa-icon *ngIf="accessVaultToExecutor!==1" [icon]="faCircleXmark" class="red-tick"></fa-icon>
                        I hereby authorize LegacyNOW to grant access to my Digital Vault for the designated representative
                      </p>
                    </app-shimmer-loading>
                    </div>
                  </div>
                  <div class="col-span-12 sm:col-span-4" *ngIf="nominateExecutor && notifyExecutorImmediately">
                    <app-shimmer-loading [loading]="loading" class="w-full h-12">
                      <app-toggle-switch [defaultSelected]="accessVaultToExecutor" [options]="toggleOptions"
                                         (optionChangeEmitter)="toggleAccessVaultToExecutor($event)">
                      </app-toggle-switch>
                    </app-shimmer-loading>
                  </div>
                  <div class="col-span-12 sm:col-span-8" *ngIf="!notifyExecutorImmediately">
                    <div>
                      <p class="text-grey text-regular pt-1 text-sm text-justify">
                        <fa-icon [icon]="faCheckCircle" class="green-tick"></fa-icon>
                        I hereby grant LegacyNOW the permission, <span class="font-black">After my death</span>, to
                        inform the Executor(s) about the existence of my Digital Vault Account upon completion of my
                        Digital Vault Account setup.
                      </p>
                    </div>
                  </div>

                </div>
                <hr class="w-full h-4 mt-4">

                <div class=" mb-4 mt-2">
                  <div class="grid grid-cols-12" *ngIf="accessVaultToExecutor">
                    <div class="col-span-12 sm:col-span-9 items-start">
                      <p class="text-regular text-sm text-grey font-semibold tracking-wide">
                        Select the access permissions for data to be displayed to your executor and ensure a secure and personalized vault experience.
                      </p>
                    </div>
                    <div class="col-span-12 sm:col-span-3">
                      <button (click)="toggleModal()"
                        class="float-right flex items-center bg-darkblue button button--md flex focus:outline-none items-center justify-center md:w-auto p-2 rounded-xs text-regular text-white w-full sm: button--sm">
                        Access Control
                        <div class="flex items-center justify-center w-5 ml-1">
                          <img class="cursor-pointer filter-white w-full h-full "
                            [ngClass]="(spinner) ? 'animate-spin' : ''" src="assets/icons/settings-cog.svg" alt="">
                        </div>
                      </button>
                  </div>
                  </div>
                  <hr class="w-full h-4 mt-4" *ngIf="accessVaultToExecutor">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    Choosing an Executor
                  </h4>
                  <p class="text-grey text-regular pt-2 text-sm tracking-wide">Acting as an executor is a significant
                    responsibility. If you choose a friend
                    or a family member, they should be: </p>
                  <ul class="mt-2">
                    <li class="text-grey text-regular pt-2 text-sm tracking-wide"> ➝
                      Over 18</li>
                    <li class="text-grey text-regular pt-2 text-sm tracking-wide"> ➝
                      Trustworthy and responsible</li>
                    <li class="text-grey text-regular pt-2 text-sm tracking-wide"> ➝
                      Good with finances and complex paperwork</li>
                    <li class="text-grey text-regular pt-2 text-sm tracking-wide"> ➝
                      Able to dedicate the time needed</li>
                    <li class="text-grey text-regular pt-2 text-sm tracking-wide"> ➝
                      Informed of your decision and accepts the responsible fiduciary
                      responsibilities associated.</li>
                  </ul>
                </div>

                <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 mb-2">
                  <a (click)="changeRoute('executor-beneficiary')" *ngIf="this.userType === 'Consumer'"
                    class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </a>
                  <a (click)="changeRoute('executor-beneficiary/'+requestId)" *ngIf="this.userType !== 'Consumer'"
                    class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </a>
                  <app-button-loader *ngIf="this.userType === 'Consumer'" [buttonText]="'Next'" [valid]="!loading"
                    [loader]="loading" (click)="nextPage()"
                    [buttonType]="'button'">
                  </app-button-loader>
                  <app-button-loader *ngIf="this.userType !== 'Consumer'" [buttonText]="'Next'" [valid]="!loading"
                    [loader]="loading" [buttonType]="'button'"
                    (click)="changeRoute('about-yourself/view/'+requestId)">
                  </app-button-loader>
                </div>
              </div>
              <!-- END: Leftside Card -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class="col-span-12 md:col-span-6 lg:grid justify-center">
    <about-yourself-right-content [formInputValue]="{}"
      [otherValues]="sectionSaveExitOptions "></about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->
</div>
