import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIResponseModel } from '../interface/response.interface';
import {
  LOGIN_USER_INTERFACE,
  OAUTH_LOGIN_INTERFACE,
  REGISTER_USER_INTERFACE,
  RESEND_EMAIL_INTERFACE,
} from '../interface/user.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * User services
 */
export class UserService {
  // children observables
  private refreshChildrenList: any;
  public refreshChildrenListObserve: Observable<any>;
  // to check otp type (register/login)
  private otpType: any;
  public otpTypeObserve: Observable<any>;
  private userIdVerified: any;
  private freeTrialDaysActive: any;
  public freeTrailDaysActiveObserve: Observable<any>;
  private paymentStatus: any;
  public paymentStatusObserve: Observable<any>;

  private BASE_URL: string
  /**
   * @constructor
   */
  constructor(
    private http: HttpClient,
  ) {
    this.BASE_URL = environment.BASE_URL;

    // for children
    this.refreshChildrenList = new BehaviorSubject({});
    this.refreshChildrenListObserve = this.refreshChildrenList.asObservable();

    // for otp type

    this.otpType = new BehaviorSubject(false);
    this.otpTypeObserve = this.otpType.asObservable();

    //for freeTrailPaymentActive
    this.freeTrialDaysActive = new BehaviorSubject({});
    this.freeTrailDaysActiveObserve = this.freeTrialDaysActive.asObservable();

    //for paymentStatus
    this.paymentStatus = new BehaviorSubject(null);
    this.paymentStatusObserve = this.paymentStatus.asObservable();
  }

  /**
   * refersh children list after add/edit/delete children
   *
   * @param {any} updatevalue
   */
  public refreshChildrenListFunction(updatevalue: any): void {
    this.refreshChildrenList.next(updatevalue);
  }
  /**
   * check otp type (false - register ,true-login )
   * @param {boolean} type
   */
  public otpTypeObservableEvents(type: boolean) {
    this.otpType.next(type);
  }

  /**
   * Gets free trail days active status
   * @param value
   */
  public getFreeTrailDaysActiveStatus(value: any) {
    this.freeTrialDaysActive.next(value);
  }

  /**
   * Gets payment status
   * @param value
   */
  public getPaymentStatus(value: any) {
    this.paymentStatus.next(value)
  }

  /**
   * Register user
   *
   * @param {REGISTER_USER_INTERFACE} userData
   * @return {Observable<APIResponseModel>}
   */
  public userRegister(userData: REGISTER_USER_INTERFACE): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/users/register', userData);
  }

  /**
   * Register professional
   *
   * @param {REGISTER_USER_INTERFACE} userData
   * @return {Observable<APIResponseModel>}
   */
  public professionalRegister(userData: REGISTER_USER_INTERFACE): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/register', userData);
  }
  /**
   * verify otp register user
   *
   * @param {REGISTER_USER_INTERFACE} userData
   * @return {Observable<APIResponseModel>}
   */
  public verifyOtpRegister(userData: REGISTER_USER_INTERFACE): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/users/verify-register-otp', userData);
  }
  /**
   * verify otp login user
   *
   * @param {REGISTER_USER_INTERFACE} userData
   * @return {Observable<APIResponseModel>}
   */
  public verifyOtpLogin(userData: REGISTER_USER_INTERFACE): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/users/verify-login-otp', userData);
  }
  /**
   * resend  otp register user
   *
   * @param {REGISTER_USER_INTERFACE} userData
   * @return {Observable<APIResponseModel>}
   */
  public resendOtpRegister(userData: { email: string }): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/users/resend-login-otp', userData);
  }

  /**
   * Login user
   *
   * @param {LOGIN_USER_INTERFACE} userData
   * @return {Observable<APIResponseModel>}
   */
  public userLogin(userData: LOGIN_USER_INTERFACE): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/users/login', userData);
  }

  /**
   * Oauth login api hit
   *
   * @param {OAUTH_LOGIN_INTERFACE} oauthData
   * @return {Observable<APIResponseModel>}
   */
  public oauthLogin(oauthData: OAUTH_LOGIN_INTERFACE): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/users/auth-login', oauthData);
  }

  /**
   * User logout
   *
   * @return {Observable<APIResponseModel>}
   */
  public userLogout(): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/users/logout', {});
  }
  /**
   * Professional User logout
   *
   * @return {Observable<APIResponseModel>}
   */
  public userLogoutProfessional(): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/logout', {});
  }

  /**
   * Resend email
   *
   * @param {RESEND_EMAIL_INTERFACE} data
   * @return {Observable<APIResponseModel>}
   */
  public resendEmail(data: RESEND_EMAIL_INTERFACE): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/users/resend-mail', data);
  }


  /**
   * Request reset password
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public requestResetPassword(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/password/reset-request', data);
  }

  /**
   * Reset password
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public resetPassword(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/password/reset', data);
  }

  /**
   * Confirm account
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public confirmAccount(data: any): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/email/verify?token=${data.token}`);
  }
  /**
   * verify import
   *
   * @param {any} token
   * @return {Observable<APIResponseModel>}
   */
  public verifyImport(token: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/verify-client-import`, token);
  }
  /**
   * Verify account and set new password for clients who are created by agents/professionals
     * @param {any} data
   * @return {Observable<APIResponseModel>}
  */
  public verifyAccount(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/email/verify-client', data);
  }

  /**
   * check email verified or not
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public checkEmailVerified(data: any): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/user/check-email-verified?token=${data}`);
  }

  /**
   * check onboarded client's email verified or not
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public checkClientEmailVerified(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/verify-generate-link`, data);
  }


  /**
   * Get Current user details
   * @return {Observable<APIResponseModel>}
   */
  public getCurrentUserDetails(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/user-details`);
  }
  /**
   * get url for registration
   * @param {string} data
   * @return {Observable<APIResponseModel>}
   */
  public getRegistrationLink(data: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/professional/short/' + data);
  }

  /**
   * pal verifylink
   * @param data
   * @return pal verifylink
   */
  public idPalVerifylink(data: string): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/id-pal/app-link', data);
  }
}
