import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { DlbService } from 'src/app/services/dlb.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { DV_TANSFER_CONTENT, USER_TYPES } from 'src/constants/application.const';
import { ReceivedDlbService } from '../../services/received-dlb.service';
import { CheckoutService } from '../checkout/checkout.service';
import { PaymentOption } from '../checkout/payment-option.model';


@Component({
  selector: 'app-dlb-dashboard',
  templateUrl: './dlb-dashboard.component.html',
})
/**
 * Dlb dashboard component
 */
export class DlbDashboardComponent implements OnInit {
  private transferDataPaid: boolean = false;
  dataLinkPayment: PaymentOption;
  loading: boolean = true;
  public dlbUserData: any;
  public userDetails: Array<Object>;
  public requestId: string;
  public requestIdRaw: string;
  public currentRouter: string;
  public submitLoader: boolean;
  public userId: string;
  public buttonStatus: boolean;
  public userType: string;
  public isDigitalVaultClaimed: boolean;
  public periodRequirePayment: number;
  public messageData = {};
  public downloadDigitalVault: boolean;
  public dvTransferContent: string;

  /**
   * @constructor
   */
  constructor(
    private localStorageService: LocalStorageService,
    private dlbService: DlbService,
    private activeRouter: ActivatedRoute,
    private commonHelper: CommonHelper,
    private router: Router,
    private peopleService: PeopleService,
    private slugInterceptorService: SlugInterceptorService,
    private sessionStorageService: SessionStorageService,
    private toastrService: ToastrService,
    private modalService: CommonModelService,
    private receivedDlbService: ReceivedDlbService,
    private checkoutService: CheckoutService,
  ) {
    this.currentRouter = `/dlb-dashboard/${this.requestIdRaw}`;
    // listen for url change
    this.router.events.subscribe((value: NavigationEnd) => {
      this.currentRouter = value?.url;
    });
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.userType = this.localStorageService.getDataByKey('role');
    this.userDetails = this.localStorageService.getUserData(USER_TYPES.exe);
    const custodianData = this.localStorageService.getDataByKey(USER_TYPES.exe);
    if (custodianData) {
      this.sessionStorageService.storeDataInSession(USER_TYPES.exe, custodianData);
    }
    this.clearLocalStorage();
    this.requestIdRaw = this.activeRouter.snapshot.params?.id;
    const decodeUrl = this.commonHelper.encodeDecodeRequestId(this.activeRouter.snapshot.params?.id, false);
    if (decodeUrl === '') {
      // if not encoded already
      this.requestIdRaw = this.commonHelper.encodeDecodeRequestId(this.activeRouter.snapshot.params?.id, true);
    }
    this.requestId = decodeUrl || this.activeRouter.snapshot.params?.id;
    this.userId = this.localStorageService.getUserData(USER_TYPES.exe)?.user?.id;
    this.dlbUserData = this.localStorageService.getDataByKey('currentDlb');
    if (this.dlbUserData) {
      this.isDigitalVaultClaimed = this.dlbUserData?.user_request?.is_vault_claimed;
      this.dvTransferContent = DV_TANSFER_CONTENT.afterPODProvied.replace(/\[VaultHolderName\]/g, this.dlbUserData?.user_request?.user?.full_name)
      this.claimDigitalVault();
      this.getCreditorStatus();
      this.initTransferDataPaid();
    } else if (decodeUrl) {
      this.getCurrrentDlb(decodeUrl).subscribe((dlbData) => {
        this.dlbUserData = dlbData;
        this.isDigitalVaultClaimed = this.dlbUserData?.user_request?.is_vault_claimed;
        this.localStorageService.storeData('currentDlb', dlbData);
        this.claimDigitalVault();
        this.getCreditorStatus();
        this.initTransferDataPaid();
      })
    }
    else {
      void this.slugInterceptorService.navigate(['executor-custodian', 'dashboard']);
      return;
    }
  }

  private initTransferDataPaid() {
    this.loading = true;
    this.receivedDlbService.isTransferPaid(this.requestId).subscribe({
      next: r => {
        this.transferDataPaid = r 
      },
      error: e => console.error(e),
      complete: () => this.loading = false,
    });
    this.checkoutService.getPaymentOptions(this.requestId).subscribe({
      next: r => this.dataLinkPayment = r.find(e => e.id == 'dataLinkFee'),
      error: e => console.error(e),
    });
    this.downloadDigitalVault = this.localStorageService.getDataByKey('download_digital_vault') == 1;
  }

  get useCensor(): boolean {
    return this.isCustodian && !this.transferDataPaid && !this.loading;
  }

  private get isCustodian(): boolean {
    return this.userType === 'Custodian';
  }

/**
 * Retrieve Current Dlb 
 */
  public getCurrrentDlb(decodeUrl: string): Observable<any> {
    return this.receivedDlbService.getReceivedDlbList().pipe(
      take(1),
      switchMap(response => {
        if (!response.status) {
          return throwError(response);
        }
        const receivedDlbList = response.data;
        const current_dlb = receivedDlbList.find(element => element.user_request_id == decodeUrl);
        return this.peopleService.viewAdvisorPermissions(current_dlb['id'], current_dlb['user_request'].user.id).pipe(
          switchMap(accessControl => {
            let claimVaultStatus;
            let notifyExecutorStatus;
            if (current_dlb['executor'] && !current_dlb['custodian'] && current_dlb?.['user_request']?.claim_notified_date) {
              current_dlb['user_request']['access_to_executor'] = 0;
              current_dlb['user_request']['access_to_custodian'] = 1;
              current_dlb['user_request']['inform_exec_permission'] = 1;
            }

            if (this.userDetails['user']['role'] === 1) {
              claimVaultStatus = true;
              notifyExecutorStatus = false;
            } else {
              claimVaultStatus = !current_dlb?.['user_request']?.access_to_executor && current_dlb?.['user_request']?.access_to_custodian;
              notifyExecutorStatus = current_dlb?.['user_request']?.access_to_executor && !current_dlb?.['user_request']?.access_to_custodian;
            }

            const permissions: Array<Object> = accessControl.data.permission;
            const hasVaultAccess = !!permissions.find((e) => e['view'] == 1); // View should be implicit if any other setting is true
            if (!current_dlb['user_request'].access_to_custodian && current_dlb['user_request']['admin_death_verify'] == 3) {
              current_dlb['user_request']['admin_death_verify'] = 5;
            }
            const notify_all_status_0 = current_dlb?.['user_request']?.notify_all_status === 0;
            const admin_death_verify = current_dlb?.['user_request']?.admin_death_verify;
            const access_to_executor = current_dlb?.['user_request']?.access_to_executor;
            const access_to_custodian = current_dlb?.['user_request']?.access_to_custodian;
            const notify_all_triggered = notify_all_status_0 && (admin_death_verify === 3 || (!access_to_executor && !access_to_custodian && admin_death_verify === null));

            const result = {
              request_id: current_dlb?.['user_request_id'],
              user_id: current_dlb?.['user_request']?.user?.id,
              executor_id: current_dlb['id'],
              user_request: current_dlb?.['user_request'],
              first_name: current_dlb?.['user_request']?.user?.first_name,
              last_name: current_dlb?.['user_request']?.user?.last_name,
              relationship: current_dlb?.['relationship'],
              dob: current_dlb?.['user_request']?.dob,
              email: current_dlb?.['user_request']?.user?.email,
              phone: current_dlb?.['user_request']?.user?.phone,
              address: current_dlb?.['user_request']?.address,
              peopleType: current_dlb?.['beneficiary'] === 1 ? 'Beneficiary' : 'Executor',
              bankBalance: '$' + (current_dlb?.['value']?.bank_balance || '$3X,XXX.XXX'),
              vehicleValue: '$' + (current_dlb?.['value']?.vehicle || '$3X,XXX'),
              propertyValue: '$' + (current_dlb?.['value']?.property || '$5X,XX,XXX'),
              statusCode: current_dlb?.['status'],
              admin_death_verify_status: current_dlb?.['user_request']?.admin_death_verify,
              reject_count: Number(current_dlb?.['user_request']?.reject_count),
              access_vault: hasVaultAccess && current_dlb?.['user_request']?.inform_exec_permission === 1,
              access_to_executor: current_dlb?.['user_request']?.access_to_executor,
              access_to_custodian: current_dlb?.['user_request']?.access_to_custodian,
              cus_death_proof_permission: current_dlb?.['user_request']?.cus_death_proof_permission,
              notify_executor_status: notifyExecutorStatus,
              claim_vault_status: claimVaultStatus,
              advisor_permission: accessControl?.data,
              notify_all_triggered: notify_all_triggered,
              notify_executor_triggered: current_dlb?.['user_request']?.claim_notified_date === null,
              is_payment_complete: current_dlb?.['user_request']?.user?.is_payment_complete,
              is_transfer_vault_amount_paid: current_dlb?.['user_request']?.['user']?.is_tansfer_amount_paid,
              reason_for_rejection: current_dlb?.['user_request']?.user_departed_detail_status?.reason_for_rejection,
              isCustodian: current_dlb?.['custodian'],
              isExecutor: current_dlb?.['executor'],
            };

            return of(result);
          }),
          catchError(error => throwError(error))
        );
      }),
      catchError(error => throwError(error))
    );
  }

  /**
   * show modal
   */
  public showDownloadInfoModal() {
    if (this.localStorageService.getDataByKey('isVaultClaimed')) {
      const claimedDate = new Date(this.localStorageService.getDataByKey('vaultClaimedDate'));
      this.periodRequirePayment = this.commonHelper.daysBetweenDates(new Date(), this.commonHelper.addOneMonth(claimedDate));
      this.periodRequirePayment > 0 ? setTimeout(() => {
        this.modalService.open('message-modal');
      }, 1000) : null;
    }
    // info: period to have Download Digital Vault
    this.messageData = {
      title: { content: 'Important note:' },
      message: [
        {
          content: `Welcome to the LegacyNOW Digital Vault Overview. You have  ${this.periodRequirePayment} days to download this information. Access beyond this period requires payment.`,
          class: 'text-xl',
        },
      ],
      buttons: [{
        content: 'Understood',
        emitValue: true,
        class: 'bg-darkblue text-white mr-4',
      }],
    };
  }

  /**
   * Listener for Message Modal Events
   * @param event
   */
  public messageModalListener(event: any) {
    if (event) {
      this.modalService.close('message-modal');
    }
  }

  /**
   * get creditor status
   */
  public getCreditorStatus() {
    this.dlbService.creditorStatus(this.requestId).subscribe((response: APIResponseModel) => {
      this.buttonStatus = response.data.inform_creditor;
    });
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }

  /**
   * on claiming
   */
  public claimDigitalVault(): void {
    if (!this.isDigitalVaultClaimed) {
      const payload = {
        request_id: this.requestId,
        user_id: this.userId,
        requestIdOverride: true,
      };
      this.dlbService.claimDlb(payload,
      ).subscribe((response: APIResponseModel) => {
        this.submitLoader = false;
        if (response.status) {
          this.dlbService.dlbListingTrigger(true);
          this.localStorageService.storeData('isVaultClaimed', response.data?.is_vault_claimed ?? 0);
          this.localStorageService.storeData('vaultClaimedDate', response.data?.vault_claimed_at ?? undefined);
          this.dlbService.setClaimVaultStatus(true);
          // No restriction on the number of days for downloading from the vault.
          // this.showDownloadInfoModal();
          this.toastrService.success(response.message);
        }
      }, (exception: any) => {
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    }
  }

  /**
   * clear local storage
   */
  private clearLocalStorage() {
    if (this.isCustodian) {
      this.localStorageService.clearLocalStorage();
      this.localStorageService.clearLocalStorage(USER_TYPES.pro);
    } else if (this.userType == 'Consumer') {
      this.localStorageService.clearLocalStorage(USER_TYPES.exe);
      this.localStorageService.clearLocalStorage(USER_TYPES.pro);
    } else {
      this.localStorageService.clearLocalStorage(USER_TYPES.pro);
      this.localStorageService.clearLocalStorage();
    }
  }

  payForDownload() {
    this.localStorageService.storeData('user_request_id', this.requestId);
    this.modalService.open('transfer-service-fee-modal');
  }
}
