import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ChipsOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { AssetsService } from 'src/app/services/assets.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { OverviewService } from 'src/app/services/overview.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { USER_TYPES } from 'src/constants/application.const';
import { ProfessionalPermissionService } from '../../../professional-dashboard/services/professional-permission.service';
import { ReceivedDlbService } from '../../../services/received-dlb.service';


@Component({
  selector: 'app-finance-overview',
  templateUrl: './finance-overview.component.html',
})
/**
 * Finance overview
 */
export class FinanceOverviewComponent implements OnInit {
  public financialList: Array<any> = [];
  public retirementList: Array<any> = [];
  public creditCardList: Array<any> = [];
  public liabilityList: Array<any> = [];
  public preLoader: boolean = false;
  public chipsListAccess: Array<ChipsOptionsInterface>;
  public currentFilter: number;
  public assetsData: any;
  public liabilityStatus = false;
  public assetStatus = false;
  public retirmentAssetStatus = false;
  public liabilityEdit = false;
  public assetEdit = false;
  public retirementAssetEdit = false;
  public userRequestID: string;
  // public requestIdParam: string;
  public userType: string;
  public chipLoader: boolean;
  public primaryBeneficiaryArr: Array<any> = [];
  public secondaryBeneficiaryArr: Array<any> = [];
  @ViewChildren('percentageDetails') percentageDetailsArray: QueryList<ElementRef>;
  @ViewChild('name') name: ElementRef;
  public retirementIdsArray: Array<any> = [];
  public ROUTE: { [key: string]: string } = {
    FINANCIAL_ASSETS: '/assets/financial-assets',
    RETIREMENT_ASSETS: '/assets/retirement-assets',
    LIABILITIES: '/liabilities',
  };
  public accessVault: boolean;
  public sectionDetails: any;
  private transferDataPaid: boolean = false;
  public isVaultClaimed: boolean = false;

  /**
   * @constructor
   */
  constructor(
    private personalDetailsService: PersonalDetailsService,
    private commonHelper: CommonHelper,
    private activeRouter: ActivatedRoute,
    private slugInterceptorService: SlugInterceptorService,
    private toastrService: ToastrService,
    private overviewService: OverviewService,
    private assetsService: AssetsService,
    private localStorageService: LocalStorageService,
    private proPermissionService: ProfessionalPermissionService,
    private receivedDlbService: ReceivedDlbService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.userType = this.localStorageService.getDataByKey('role');
    this.chipsListAccess = [{ value: 0, displayText: 'All' }];
    this.userRequestID = this.commonHelper.getRequestId(this.activeRouter);
    this.initTransferDataPaid();
    // Handle Access Control
    if (this.userType === 'Consumer' || (this.userType === 'Custodian' && this.localStorageService.getDataByKey('isVaultClaimed'))) {
      this.chipsListAccess.push({ value: 1, displayText: 'Financial Assets' });
      this.chipsListAccess.push({ value: 2, displayText: 'Retirement Assets' });
      this.chipsListAccess.push({ value: 3, displayText: 'Liabilities' });
      this.assetStatus = true;
      this.assetEdit = true;
      this.retirmentAssetStatus = true;
      this.retirementAssetEdit = true;
      this.liabilityStatus = true;
      this.liabilityEdit = true;
      this.getInsuranceDetails();
    } else {
      this.getAccessControl();
    }

    this.currentFilter = 0;
    this.creditCardList = [];

    this.getAssetsValue();
    this.overviewService.dashboardDataObserve.subscribe((response) => this.assetsData = response);
    this.accessVault = this.localStorageService.getDataByKey('accessVault');
    this.sectionDetails = this.localStorageService.getDataByKey('sectionData');
    if (this.sectionDetails && this.userType !== 'Consumer') {
      this.currentFilter = this.chipsListAccess.find(item => item.displayText === this.sectionDetails.name)?.value ?? this.currentFilter;
    }
    this.isVaultClaimed = this.localStorageService.getDataByKey('isVaultClaimed') == 1;
  }

  private initTransferDataPaid() {
    this.commonHelper.isCustodian && this.receivedDlbService.isTransferPaid(this.userRequestID).subscribe({
      next: r => this.localStorageService.storeData('is_transfer_amount_paid',r),
      error: e => console.error(e),
    });
  }

  /**
   * Establish ACL Permissions. Consumer is assigned full access.
   */
  public getAccessControl(): void {
    this.chipLoader = true;
    if (this.userType !== 'Consumer') {
      const requestId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('request_user_id') : this.userRequestID;
      const userId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('user_id') : this.localStorageService.getDataByKey('agent_id') || this.localStorageService.getUserData(USER_TYPES.pro)?.user?.id;
      this.proPermissionService.getAccessControl(userId, requestId, ['Liabilities', 'Financial Assets', 'Retirement Assets']).subscribe({
        next: (permissions) => {
          console.log({ permissions });

          for (const permissionsKey in permissions) {
            this.onSelectSection({ permission_name: permissionsKey, ...permissions[permissionsKey] });
          }
        },
        error: (e) => this.commonHelper.httpResponseHandler(e.error),
        complete: () => {
          this.getInsuranceDetails();
          // If Professional doesn't have permission to view/edit/add/delete, then redirect to general overview.
          if (!this.liabilityStatus && !this.assetStatus && !this.retirmentAssetStatus) {
            this.toastrService.info('You do not have permission to view this information. You will be redirected to a general overview.');
            void this.slugInterceptorService.navigate(['/overview', this.userRequestID]);
          }
        },
      });
    }
    this.chipLoader = false;
  }

  /**
   * Get function for html template to access this.commonHelper & allow AOT compilation
   */
  get getCommonHelper(): CommonHelper {
    return this.commonHelper;
  }

  /**
   * on clicking on section name
   * @param {{}}sectionData
   */
  public onSelectSection(sectionData: {}) {
    this.chipLoader = true;
    const bEdit = sectionData['add'] == 1 || sectionData['edit'] == 1 || sectionData['delete'] == 1;
    switch (sectionData['permission_name']) {
      case 'Liabilities':
        this.liabilityStatus = sectionData['view'] == 1 || bEdit;
        this.liabilityEdit = bEdit;
        this.chipsListAccess.push({ value: 3, displayText: 'Liabilities' });
        break;
      case 'Financial Assets':
        this.assetStatus = sectionData['view'] == 1 || bEdit;
        this.assetEdit = bEdit;
        this.chipsListAccess.push({ value: 1, displayText: 'Financial Assets' });
        break;
      case 'Retirement Assets':
        this.retirmentAssetStatus = sectionData['view'] == 1 || bEdit;
        this.retirementAssetEdit = bEdit;
        this.chipsListAccess.push({ value:2, displayText: 'Retirement Assets' })
    }
    this.chipLoader = false;
  }

  /**
   * get assets and liability details
   */
  public getInsuranceDetails(): void {
    this.preLoader = true;
    if(this.commonHelper.isClaimedAndNotPaid){
      this.preLoader = false;
      return;
    }  // handle custodian claimed valut but not paid transfer fee
    this.primaryBeneficiaryArr = [];
    this.secondaryBeneficiaryArr = [];
    this.retirementIdsArray = [];

      this.personalDetailsService.getPersonalDetailsWithBeneficiary(this.userRequestID)
        .subscribe((response: APIResponseModel) => {
          console.log('response', response);
          if (response.status) {
            if(this.liabilityStatus) {
              this.liabilityList = response?.data?.user_liability?.filter((d) => d.user_liability_loan).map((d) => d.user_liability_loan) ?? [];
              this.creditCardList = response?.data?.user_liability?.filter((d) => d.user_liability_card).map((d) => d.user_liability_card) ?? [];
            }
            if(this.assetStatus) {
              this.financialList = response?.data?.user_financial_asset;
              this.financialList.forEach(e => e['people'] = this.personalDetailsService.processUserPeople(e['people']));
            }
            if(this.retirmentAssetStatus) {
              this.retirementList = response?.data?.user_retirement_asset?.filter((e) => e['retirement_asset'] != null) ?? [];
              this.retirementList.forEach(e => e['people'] = this.personalDetailsService.processUserPeople(e['people']));
            }
            this.processRetirementList();
          }
          this.preLoader = false;
        }, (exception: any) => {
          this.commonHelper.httpResponseHandler(exception?.error);
          this.preLoader = false;
        });
  }

  /**
   * Process the retirement list  to populate {@link retirementIdsArray}, {@link primaryBeneficiaryArr}, and {@link secondaryBeneficiaryArr}.
   * @private
   */
  private processRetirementList() {
    this.retirementList?.forEach((e_retirement) => {
      if (e_retirement.people.length && this.retirementIdsArray.indexOf(e_retirement.id) == -1) {
        this.retirementIdsArray.push(e_retirement.id);
        e_retirement.people.forEach((e_ret_people) => {
          if (e_ret_people.pivot.type == 1 && this.primaryBeneficiaryArr.indexOf(e_ret_people.pivot.user_retirement_asset_id) == -1) {
            this.primaryBeneficiaryArr.push(e_ret_people);
          } else if (this.secondaryBeneficiaryArr.indexOf(e_ret_people.pivot.user_retirement_asset_id) == -1) {
            this.secondaryBeneficiaryArr.push(e_ret_people);
          }
        });
      }
      if (e_retirement.frequency_type) {
        e_retirement.frequency = e_retirement.frequency_type.name;
      }
    });
  }

  /**
   * function to return index in asc order
   * @param {any} data
   * @param {number} value
   * @return {any} result
   */
  public indexHandler(data: any, value: number) {
    const result: any = [];
    data.forEach(e => {
      if (e.pivot.type == value) {
        result.push(e);
      }
    });
    return result;
  }

  /**
   * get assets value
   */
  public getAssetsValue() {
    this.assetsService.getTotalAssetsValue(this.userRequestID)
      .subscribe((response: APIResponseModel) => this.overviewService.dashboardDataObservableEvents(response.data));
  }

  /**
   * chip on change listner
   *
   * @param {ChipsOptionsInterface} data
   */
  public chipChangeListener(data: ChipsOptionsInterface): void {
    if (!data) return;
    this.currentFilter = data.value;
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: any[]) {
    void this.slugInterceptorService.navigate(url);
  }

  /**
   * getter for using routing number
   * @param{Object}data
   * @return{boolean}
   */
  public isRoutingType(data: Object): boolean {
    return data?.['import_type'] != 2 && data['financial_asset_type'].name != 'Annuity' && data['financial_asset_type'].name != 'Brokerage Account';
  }
}
