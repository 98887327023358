import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ToggleOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { DVC_DESCRIPTION_TEXT } from '../../../../constants/application.const';


@Component({
  selector: 'app-custodian-info',
  templateUrl: './custodian-info.component.html',
})

/**
 * custodian info
 */
export class CustodianInfoComponent implements OnInit {
  public faCheckCircle = faCheckCircle;
  public faCircleXmark = faCircleXmark;
  public spinner: boolean;
  public loading: boolean;
  public sectionSaveExitOptions: any;
  public requestId: string;
  public userType: string;
  public userData: Array<Object>;
  public userId: string;
  public permissionStatus: number;
  public toggleOptions: Array<ToggleOptionsInterface>;
  public toggleModalAdd: boolean;
  public newAdvisor: any;
  public submitLoader: boolean;
  public enableExecutor: Number;
  public accessVaultToCustodian: Number = 0;
  public data: any;
  DVC_DESCRIPTION_TEXT = DVC_DESCRIPTION_TEXT;


  /**
   * constructor
   */
  constructor(
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private personalDetailsService: PersonalDetailsService,
    private peopleService: PeopleService,
    private router: Router,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private slugInterceptorService: SlugInterceptorService,
  ) {
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.enableExecutor = this.localStorageService.getDataByKey('enableExecutor');
    this.userData = this.localStorageService.getUserData();
    this.userId = this.userData['user']?.id;
    this.spinner = false;
    this.sectionSaveExitOptions = {
      section: 2,
      // type: 2,
    };
    this.toggleOptions = [
      {
        displayText: 'NO',
        value: 0,
      },
      {
        displayText: 'YES',
        value: 1,
      },
    ];
    this.getPersonalDetails();
    this.peopleService.viewExecutorPermissions(this.userId).subscribe((response: APIResponseModel) => {
      if (response?.data['permission'].length > 0) {
        this.data = response.data['permission'];
      } else {
        this.setDefaultPermissions();
      }
    }, () => this.setDefaultPermissions());
  }


  /**
   * Get personal details
   */
  public getPersonalDetails(): void {
    this.loading = true;
    // get people list
    this.personalDetailsService.getPersonalDetails(this.userType !== 'Consumer' ? this.requestId : null).subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          this.permissionStatus = response.data?.grant_custodian_permission;
          this.accessVaultToCustodian = response.data?.access_to_custodian;
        }
      },
      error: (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
      complete: () => this.loading = false,
    });
  }


  /**
   * On Change toggle
   *
   * @param {ToggleOptionsInterface} selectedValue
   */
  public onChangeToggle(selectedValue: ToggleOptionsInterface): void {
    if (!selectedValue) return;
    this.permissionStatus = selectedValue?.value;
    const payLoad = {
      grant_custodian_permission: this.permissionStatus,
      roletype: this.userType,
    };
    if (this.userType === 'Consumer') {
      payLoad['request_id'] = this.requestId;
    }
    this.authorizeAdvisor(payLoad);
  }


  /**
   * Toggle access vault to custodian
   * @param {ToggleOptionsInterface} toggleValue
   */
  public toggleAccessVaultToCustodian(toggleValue: ToggleOptionsInterface): void {
    this.accessVaultToCustodian = toggleValue.value;
    const payLoad = {
      access_to_custodian: this.accessVaultToCustodian,
      roletype: this.userType,
    };
    this.authorizeAdvisor(payLoad);
  }


  /**
   * See {@link PeopleService.authorizeAdvisor}.
   * @param payLoad
   * @private
   */
  private authorizeAdvisor(payLoad) {
    this.peopleService.authorizeAdvisor(payLoad).subscribe({
      next: (response) => (response.status ? console.log(response.status) : null),
      error: () => this.getPersonalDetails(),
    });
  }


  /**
   * Toggles modal
   */
  public toggleACLModal(): void {
    this.spinner = true;
    setTimeout(() => {
      this.modalService.open('access-control-modal');

      this.spinner = false;
    }, 1000);
  }


  /**
   * Add vault custodian
   */
  public toggleModal(): void {
    this.modalService.open('get-custodian-modal');
    this.toggleModalAdd = !this.toggleModalAdd;
  }


  /**
   * get advisor details
   * @param {any} data
   */
  public onGetCustodian(data) {
    this.newAdvisor = data;
  }


  /**
   * Sets default permissions
   */
  public setDefaultPermissions(): void {
    this.peopleService.getDashboardSection().subscribe((response: APIResponseModel) => {
      this.data = response.data;
    });
  }


  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }
}
