<app-common-modal id="chat-modal" class="app-common-modal" [heightClass]="'h-full'">
    <!-- BEGIN: Modal -->

        <div class="relative">
          <img src="assets/images/about-yourself/close.svg" class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
          (click)="closeModel()">

        </div>
        <div class="h-full overflow-y-scroll"><div class="border-card bg-white rounded-md p-3 ">
          <!--search bar chart-->
          <div class="w-full mb-3">
              <div class="relative">
                 <div class="relative h-10 text-grey ">
                    <div class=" absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"> <img src=" assets/images/about-yourself/search.svg " class="w-4  h-4" alt=" dv"> </div>
                    <input type=" text " class=" input py-3 px-4 mt-1 w-full text-regular text-grey ngmodel
                       rounded-xs h-10 appearance-none pl-8 border block focus:outline-none " placeholder="Search by Name" [(ngModel)]="searchKeyword"
                       (input)="searchClient()">
                 </div>
              </div>
           </div>
          <!--end-->
         <div class="inbox-height">
          <!--start-->

          <div class="bg-transparent border-card-grey rounded-xs mt-6 h-20"
            *ngIf="spinner">
            <div class="grid grid-cols-12 h-20 items-center gap-4 px-3 sm:pl-3">
                <div class="col-span-3 sm:col-span-2">
                    <app-shimmer-loading [loading]="spinner" [shape]="'circle'"
                        [width]="'4rem'">
                        <div
                            class="flex items-center justify-center mx-auto rounded-full h-12 w-12 bg-darkblue">
                            <p class="uppercase text-white text-regular text-base">
                                <img src="assets/images/executors/display-img.svg" class="w-5"
                                    alt="dv">
                            </p>
                        </div>
                    </app-shimmer-loading>
                </div>
                <div class="col-span-7 sm:col-span-8">
                    <h4
                        class="break-all text-base sm:text-base md:text-lg text-regular tracking-wide text-darkblue text-semibold">
                        <app-shimmer-loading [loading]="spinner">
                            Lily-Rose Depp
                        </app-shimmer-loading>
                    </h4>
                    <p class="text-grey text-regular text-xs sm:text-sm">
                        <app-shimmer-loading [loading]="spinner">
                            Your Child
                        </app-shimmer-loading>
                    </p>
                </div>
                <div class="col-span-2">
                    <div class="flex items-center justify-center h-5">
                        <app-shimmer-loading [loading]="spinner" [width]="'4rem'">
                            <input type="checkbox"
                                class="h-4 w-4 input text-lightblue border rounded">
                        </app-shimmer-loading>
                    </div>
                </div>
            </div>
            </div>
          <ng-container class="overflow-y-scroll" *ngIf="!spinner" >


            <div  *ngFor="let client of clientList;let i = index" (click)="startNewChat(client.id)">
                <div [ngClass]="{'chat__chat-list-active': chat===selectedUser}" class="chat__chat-list cursor-pointer box relative flex items-center p-5 chat__chat-list-hr">
                    <div class="flex items-center gap-4">
                        <div class="w-14 h-14 flex-none image-fit">
                            <img alt="dv" class="rounded-full" src="http://rubick.left4code.com/dist/images/profile-8.jpg">
                            <div class="w-4 h-4 chat__list-online-status-active absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                        </div>
                        <div class="w-full shrink overflow-hidden">
                            <div class="flex items-center">
                                <a  class="chat__chat-name text-md md:text-lg lg:text-xl text-regular underline">{{client.full_name}}</a>
                            </div>
                            <div class="w-full truncate text-regular text-sm md:text-md lg:text-lg text-darkgrey mt-0.5">{{client.email}}</div>
                        </div>
                    </div>
                    <div *ngIf="chat?.offline == false" class="w-3/12 flex-none text-xs text-grey text-right">
                        {{client.full_name}}
                    </div>
                </div>
                <!-- <div class="chat__chat-list-hr"></div> -->
            </div>



          </ng-container>
          <!--end-->


         </div>
         <div  *ngIf="clientList?.length === 0">
          <h4 class="text-grey text-base lg:text-md text-regular">

            No data found
          </h4>
        </div>
       </div>
    </div>



   <!-- End Modal -->
  </app-common-modal>
