<main class="relative">
  <app-nav-landing-pro></app-nav-landing-pro>
  <!-- BEGIN: Banner Wrapper -->
  <div class="pricing-banner__wrap relative flex items-center justify-center banner-padding-hfull mh-700-height">
    <div class="container mx-auto">
      <div class="flex items-center text-white">
        <div class="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8 xl:gap-12">
          <div class="col-span-12 xl:col-span-9" *ngIf="professionalType==='Wealth Management'">
            <div class="mt-8 lg:mt-12">
            <h1 class="pricing-banner__head text-semibold text-white fs-bhead text-regular">LegacyNOW is confident that
              advisors and clients will love this product</h1>
            <p class="pricing-banner__content fs-content mt-4 text-regular">And we do not charge an upfront licensing
              fee for your firm to use our wealth advisor interface. Instead, a minimal success fee is acquired after your
              firm is earning revenue as a result of the many multifaced, multigeneration services which are provided by
              this software.</p>
            </div>
          </div>
          <div class="col-span-12 xl:col-span-9" *ngIf="professionalType!=='Wealth Management'">
            <div class="mt-8 lg:mt-12">
            <h1 class="pricing-banner__head text-semibold text-white fs-bhead text-regular">LegacyNOW is confident that your
              advisors and clients will love this product</h1>
            <p class="pricing-banner__content fs-content mt-4 text-regular">so we do not charge an upfront licensing
              fee for your firm to use our professional service advisor interface. Instead, we opt for a small success fee
              that only comes when your firm is already making fees from several revenue streams achieved
              by the multifaced, multigeneration services which can be provided by this software.</p>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
  <!-- END: Banner Wrapper -->

  <!-- BEGIN: Competitors Wrap -->
  <div class="section competitors__wrap" *ngIf="professionalType==='Wealth Management'">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
        <div class="col-span-12 lg:col-span-6">
          <h2 class="fs-title text-primary text-semibold">
            Surpassing our competitors, LegacyNOW is more than a SaaS tool that can only used internally by your firm.
          </h2>

        </div>
        <div class="col-span-12 lg:col-span-6 xl:pl-4 lg:pb-0">

          <p class="fs-content text-content text-regular mb-3">
            LegacyNOW is a digital vault platform which is empowers your customers through vault ownership and provides
            advisors the benefits of collaborating with their clients and establishing a relationship with their family
            members (beneficiaries) that will transcend a client’s passing. This provides an increased opportunity for
            product and service revenue.</p>
          <p class="fs-content text-content text-regular">This is a win-win revenue sharing model between our
            organizations.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="section competitors__wrap" *ngIf="professionalType!=='Wealth Management'">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
        <div class="col-span-12 lg:col-span-6">
          <h2 class="fs-title text-primary text-semibold">
            Unlike our competitors, this is not just a SaaS tool where your firm purchases it and only uses it internally
            to better manage your clients estate data points.
          </h2>

        </div>
        <div class="col-span-12 lg:col-span-6 xl:pl-4 lg:pb-0">

          <p class="fs-content text-content text-regular mb-3">
            The LegacyNOW digital vault is a product which will be owned by the consumer after the advisor reaps the
            benefits of collaborating with their clients and future generation family members (beneficiaries), so there is
            an opportunity for both a product and
            service revenue.</p>
          <p class="fs-content text-content text-regular">This is a win-win revenue sharing model between our
            organizations.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Competitors Wrap -->


  <!-- BEGIN: Pricing Wrapper -->
  <div class="section for-more-info__wrap" *ngIf="professionalType==='Wealth Management'">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-center rounded-xl p-6 sm:p-8 md:p-12 bg-dark-blue gap-4 lg:gap-8">
        <div class="col-span-12 lg:col-span-9">
          <div class="title__wrap">
            <h1 class="text-white text-semibold fs-title pb-3 text-bold text-left">To schedule a demo and discuss pricing
              models please click here </h1>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-3">
          <div class="btn-wrap flex items-left justify-start lg:items-center lg:justify-center">
            <button (click)="menuChange('contact-us')"
              class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">Contact
              Us</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section for-more-info__wrap" *ngIf="professionalType!=='Wealth Management'">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-center rounded-xl p-6 sm:p-8 md:p-12 bg-dark-blue gap-4 lg:gap-8">
        <div class="col-span-12 lg:col-span-9">
          <div class="title__wrap">
            <h1 class="text-white text-semibold fs-title pb-3 text-bold text-left">For more pricing details and to
              schedule a demo, please reach out to us here:</h1>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-3">
          <div class="btn-wrap flex items-left justify-start lg:items-center lg:justify-center">
            <button (click)="menuChange('contact-us')"
              class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">Contact
              Us</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Pricing Wrapper -->

  <!-- BEGIN: Testimonial Wrapper-->
  <div class="section">
    <app-testimonials [mobile]="mobile"></app-testimonials>
  </div>
  <!-- END: Testimonial Wrapper -->

  <!-- BEGIN: Advisor Insight Inbox -->
  <!-- <div class="section insight-inbox__wrap">
    <div class="container mx-auto">
      <div class="grid grid-cols-9 gap-4 lg:gap-12">
        <div class="title__wrap col-span-12 md:col-start-2 md:col-span-9 pb-8">
          <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-center">For Advisors Insights sent direct
            to your inbox</h1>
          <p class="fs-content text-regular text-content text-center">Just sign up here and you'll receive news and
            articles that will help better serve your clients and out perform your competition.</p>
        </div>
        <div class="col-span-12">
          <div class="bg-transparent border rounded-md mx-auto md:w-5/6 lg:w-3/5">
            <form action="#" method="post"
              class="flex flex-wrap items-center justify-between rounded-md xl:h-16 md:flex-row bg-white">
              <div class="relative w-full">
                <input type="text" placeholder="Enter your Name"
                  class="flex-1 h-10 text-content text-sm md:text-lg md:px-4 m-1 text-gray-700 placeholder-gray-400 border-none appearance-none lg:h-12 focus:outline-none ">
                <div class="absolute top-1 right-3 md:right-10">
                  <button type="submit"
                    class="flex items-center justify-center text-semibold text-sm text-white w-full sm:mx-2 p-2 m-1 rounded-md md:h-12 md:mx-6 lg:text-xl xl:h-12 sm:text-base md:p-0 bg-primary focus:outline-none ">Let
                    Me Know</button>
                </div>
              </div>
            </form>
          </div>

        </div>

      </div>
    </div>
  </div> -->
  <!-- END: Advisor Insight Inbox -->

  <!-- BEGIN: Footer Wrapper -->
  <app-footer-landing-pro></app-footer-landing-pro>
</main>
<div class="fixed right-6 bottom-6 group z-50">
  <button type="button" aria-expanded="false" (click)="openModal('assistanceHelp')"
    class="flex items-center justify-center text-white bg-blue-700 rounded-full w-16 h-16 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
    <img src="assets/images/intro-video.png" alt="">
    <span class="sr-only">Open actions menu</span>
  </button>
</div>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>
