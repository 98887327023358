/**
 * Input element events
 * 
 * FOCUSOUT : focusout event - called when focus out of Input element 
 * INPUT : input event called when changing input value of Input element 
 */
export const ELEMENT_EVENTS = {
    FOCUSOUT: 'focusout',
    INPUT: 'input',
  };

  /**
 * Input validation status
 * 
 * INVALID : Input value is invalid
 * VALID : Input value is valid
 */
export const STATUS_CHECK = {
  INVALID: 'INVALID',
  VALID: 'VALID',
};
