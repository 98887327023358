<main class="relative">
  <!-- BEGIN: Navbar Wrapper -->
  <!-- <app-nav-landing-pro [bannerPage]="true"></app-nav-landing-pro> -->
  <div class="relative flex items-center justify-center h-screen mb-8 max-height">
    <div
      class="relative h-screen z-30 px-4 pt-24 flex items-center lg:mt-24 xl:mt-0 md:p-20 text-2xl text-white rounded-xl">
      <div class="grid grid-cols-12 xl:gap-12 p-4 md:p-6 xl:p-8 lg:mt-12 rounded"
        style="background-color:rgb(0 0 0 / 25%)">
        <div class="col-span-12 xl:col-span-12  text-left">
          <p class="home-banner__content text-base md:text-lg xl:text-3xl mb-4 text-regular">
            
            help your clients, members or employees and their families by providing them with our best in
            class estate data management and resolution tools.
          </p>
          <p class="home-banner__content text-base md:text-lg xl:text-3xl mt-4 mb-4 text-regular">In order to provide a
            more
            customized experience, please pick the industry that describes you or your organization’s
            specialized area of expertise.</p>
          <div class="md:flex mt-4 mb-4 xl:mt-8 xl:mb-12">
            <div class="flex items-center mr-4" *ngFor="let proType of proTypes">
              <input id="radio-{{proType?.['displayText']}}" type="radio" [value]="proType?.['displayText']"
                name="inline-radio-group" [(ngModel)]="selectedType"
                class="w-4 h-4 md:w-5 md:h-5 text-blue-600 bg-gray-100 border-gray-300 input-accent">
              <label for="radio-{{proType?.['displayText']}}"
                class="ml-2 home-banner__content text-base xl:text-xl text-white">{{proType?.['displayText']}}</label>
            </div>
          </div>
          <div class="btn-wrap">
            <button (click)="onNavigation()"
              class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">
              Next
            </button>
          </div>
        </div>

      </div>

    </div>
    <video playsinline autoplay loop [muted]="true"
      class="absolute banner__bg-video z-10 w-auto object-cover  lg:min-w-full lg:min-h-screen">
      <source src="assets/images/professional/banner-clip.mp4" type="video/mp4" />
      <source src="assets/images/professional/banner-clip.ogg" type="video/ogg" />
    </video>
  </div>
</main>

<div class="fixed right-6 bottom-6 group z-50">
  <button type="button" aria-expanded="false" (click)="openModal('assistanceHelp')"
    class="flex items-center justify-center text-white bg-blue-700 rounded-full w-16 h-16 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
    <img src="assets/images/intro-video.png" alt="">
    <span class="sr-only">Open actions menu</span>
  </button>
</div>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>