import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonService } from 'src/app/services/common.service';
import { SelectSearchComponentt } from '../select-search/select-search.component';
@Component({
  selector: 'app-country-search-component',
  templateUrl: '../select-search/select-search.component.html',
  styleUrls: ['../select-search/select-search.component.css'],
})
/**
 * Country search component
 */
export class CountrySearchComponentComponent extends SelectSearchComponentt implements OnInit, OnChanges {
  @Input() enableError: boolean;
  @Input() defaultSelected: any;
  @Output() optionChangeEmitter = new EventEmitter<SelectOptionsInterface>();
  /**
   * @constructor
   */
  constructor(
    private commonService: CommonService,
    public _eref: ElementRef,
  ) {
    super(_eref);
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.items = [];
    this.placeholder = '';
    this.initialPlaceholder = 'Select Your Country';
    this.showSearchBox = true;
    this.enableError = false;
  }

  /**
   * called when changes occur
   *
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    changes.defaultSelected['currentValue'] && this.checkCachedValue(changes.defaultSelected['currentValue']);
  }

  /**
   * check for cached value
   *
   * @param {any} defaultValue
   */
  private checkCachedValue(defaultValue: any): void {
    if (!this.items?.length) {
      this.getCountryList(defaultValue);
    } else {
      super.onSelectPreProcess(defaultValue);
    }
  }

  /**
   * Country list formatter
   *
   * @param {any} defaultValue
   */
  private getCountryList(defaultValue?: any): void {
    const formattedCountryList: Array<SelectOptionsInterface> = [];
    this.loader = true;
    this.commonService.getCountries().subscribe((response: APIResponseModel) => {
      this.loader = false;
      // Formatting country for select box
      response.data.forEach((item: any) => {
        formattedCountryList.push({ displayText: `${item.name}`, value: item.id });
      });
      this.items = formattedCountryList;
      if (defaultValue) {
        super.onSelectPreProcess(defaultValue);
      }
    }, () => {
      this.loader = false;
    });
  }

  /**
   * listen for options changes
   *
   * @param {string} selectedCountry
   */
  public optionChangeListener(selectedCountry: SelectOptionsInterface): void {
    this.optionChangeEmitter.emit(selectedCountry);
  }

  /**
   * On select change
   *
   * @param {string} value
   */
  public onSelectChange(value: string): void {
    super.onSelectPreProcess(value);
    this.optionChangeListener(this.selectedOption);
  }
}
