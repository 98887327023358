<app-common-modal [id]="this.id" class="app-common-modal" >
  <div class="relative h-full w-full">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModal()" (keydown)="closeModal()">

      <share-buttons
        theme="modern-dark"
        [include]="['copy', 'facebook', 'email', 'messenger', 'linkedin', 'pinterest', 'twitter']"
        [showIcon]="true"
        [showText]="true"
        class="pt-5">
      </share-buttons>
  </div>
</app-common-modal>
