import { Component, HostListener, OnInit } from '@angular/core';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonService } from 'src/app/services/common.service';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-consumer-focused',
  templateUrl: './consumer-focused.component.html',
  styleUrls: ['./consumer-focused.component.css'],
})
export class ConsumerFocusedComponent implements OnInit {
  private innerWidth: number;
  public monthlyPricing: string;
  public menuActive: any;

  /**
   * constructor
   */
  constructor(
    private subscriptionService: ConsumerSubscriptionService,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    private commonService: CommonService) {
  }

  ngOnInit(): void {
    this.updateInnerWidth();
    this.getPricing();
  }

  get isSmallWindow(): boolean {
    return this.innerWidth < 800;
  }

  private updateInnerWidth() {
    this.innerWidth = window.innerWidth;
    console.debug('inner width', this.innerWidth);
  }

  @HostListener('window:resize', [])
  windowResizeListener() {
    this.updateInnerWidth();
  }

  /**
   * Retrieves pricing details
   *
   */
  private getPricing() {
    this.subscriptionService.getPricingDetails().subscribe({
      next: (response: any) => {
        if (response?.status) {
          const packagePrice = { monthly: response.data.subscription_price };
          this.monthlyPricing = this.commonHelper.toCurrency(packagePrice.monthly);
        }
      },
    });
  }

  /**
   * On Menu Change
   * @param{string}url
   */
  public menuChange(url?: string) {
    if (url) {
      this.menuActive = url;
      void this.slugInterceptorService.navigate([url]);
      this.commonService.setActiveNavbarTrigger(this.menuActive);
    }
  }
}
