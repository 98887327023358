<div class="flex justify-center loader " *ngIf="spinner">
  <div class="w-16 h-16  border-b-2 border-red rounded-full animate-spin mt-3 "></div>
</div>
<div class="scroll-hidden" *ngIf="!spinner">
  <div class="h-screen lg:grid grid-cols-12">
    <!-- BEGIN: Login Form -->
    <div class="col-span-12 md:col-span-6 xl:col-span-5 h-screen lg:h-auto p-6 flex">
      <div class="flex flex-col w-full">
        <!-- BEGIN: Logo -->
        <div class="md:pl-2 sm:flex items-center gap-2 md:gap-4 flex-row">
          <a (click)="menuChange('')" class="cursor-pointer">
            <img alt="The-Estate-Registry" width="250" [src]="'assets/images/logo-old.png'"
              class="object-contain w-52 lg:w-60">
            <p class="text-regular text-semibold text-orange text-sm lg:text-lg text-center">
              {{ this.proTypeText ? this.proTypeText + " Edition" : "" }}
            </p>
          </a>
          <a *ngIf="orgLogoPath" class=" border-l-black pl-6 cursor-pointer max-h-12 maxw128">
            <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
          </a>
        </div>
        <!-- END: Logo -->
        <div class="h-full flex items-center justify-center mt-4">
          <div class="my-auto mx-auto bg-white pt-6 sm:pl-10 xl:bg-transparent sm:px-8 w-full">
            <div class="h-full justify-center items-center ">
              <!--mail conformation image-->
              <img src="assets/images/dashboard/mail-verfication.png" class="mt-3 mb-4 w-28 object-contain h-full"
                alt="digital vault">
              <!--end-->
              <!--start mail confirm text-->
              <div class="my-3">
                <!--text wrapper-->
                <div class="mt-4">
                  <h2 class="text-darkblue text-4xl text-semibold pt-1">LegacyNOW <br>
                    Professional Dashboard
                  </h2>
                  <p class="text-regular text-grey text-base pt-3">Welcome to New Password Setup </p>
                </div>
                <form [formGroup]="passwordForm" (ngSubmit)="confirmAccount()" autocomplete="off">

                  <div class="mt-4">
                    <label for="password" class="text-sm pb-2 text-regular text-grey">
                      Password</label>
                    <div class="relative h-12 text-grey z-10">
                      <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
                        (click)="togglePasswordView = !togglePasswordView">
                        <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
                        <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
                      </a>
                      <input [type]="togglePasswordView?'text':'password'" name="password" formControlName="password"
                        id="password"
                        class="input py-3 px-4 mt-1 w-full text-regular text-grey rounded-xs h-12 appearance-none pl-4 border block focus:outline-none"
                        placeholder="Enter Password"
                        [ngClass]="formGet.password.errors && formGet.password.touched?'form-input-error':'form-input-focus'">
                      <div *ngIf="formGet.password.errors && formGet.password.dirty" class="z-100">
                        <div *ngIf="formGet.password.errors.required">
                          <p class="text-regular font-medium text-xs mt-2 error-alert">Password is required</p>
                        </div>
                        <div *ngIf="!formGet.password.errors.required"
                          class="absolute border mt-4 rounded bg-white p-2 text-red-700 border-3 w-full rounded-xs border block focus:outline-none">

                          <p [ngClass]="!formGet.password.errors.minlength? 'green-error-text':'red-error-text'">
                            <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                              alt="digital vault" *ngIf="!formGet.password.errors.minlength">
                            <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                              alt="digital vault" *ngIf="formGet.password.errors.minlength">
                            Minimum at least 8 characters.
                          </p>

                          <p
                            [ngClass]="formGet.password.errors?.passwordStrength?.hasNumeric? 'green-error-text':'red-error-text'">
                            <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                              alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.hasNumeric">
                            <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                              alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.hasNumeric">
                            At least one number (0-9).
                          </p>

                          <p
                            [ngClass]="formGet.password.errors?.passwordStrength?.hasLowerCase? 'green-error-text':'red-error-text'">
                            <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                              alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.hasLowerCase">
                            <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                              alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.hasLowerCase">
                            At least one lowercase letter (a-z).
                          </p>

                          <p
                            [ngClass]="formGet.password.errors?.passwordStrength?.hasUpperCase? 'green-error-text':'red-error-text'">
                            <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                              alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.hasUpperCase">
                            <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                              alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.hasUpperCase">
                            At least one uppercase letter (A-Z).
                          </p>

                          <p
                            [ngClass]="formGet.password.errors?.passwordStrength?.hasSpecialCharacters? 'green-error-text':'red-error-text'">
                            <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                              alt="digital vault"
                              *ngIf="formGet.password.errors?.passwordStrength?.hasSpecialCharacters">
                            <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                              alt="digital vault"
                              *ngIf="!formGet.password.errors?.passwordStrength?.hasSpecialCharacters">
                            At least one special character (!@#$%^&*).
                          </p>

                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="mt-4">
                    <label for="password" class="text-sm pb-2 text-regular text-grey">
                      Confirm Password</label>
                    <div class="relative h-12 text-grey">
                      <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
                        (click)="togglePasswordConfirmView = !togglePasswordConfirmView">
                        <fa-icon [icon]="faEye" *ngIf="togglePasswordConfirmView"></fa-icon>
                        <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordConfirmView"></fa-icon>
                      </a>
                      <input [type]="togglePasswordConfirmView?'text':'password'" name="password_confirmation"
                        formControlName="password_confirmation" id="password_confirmation"
                        class="input py-3 px-4 mt-1 w-full text-regular text-grey rounded-xs h-12 appearance-none pl-4 border block focus:outline-none"
                        placeholder="Enter Password"
                        [ngClass]="formGet.password_confirmation.errors && formGet.password_confirmation.touched?'form-input-error':'form-input-focus'">
                      <div *ngIf="formGet.password_confirmation.errors && formGet.password_confirmation.touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.password_confirmation.errors.minlength">Confirm password must be at least 8
                          characters</p>
                        <p class="text-regular font-medium text-xs my-2 error-alert"
                          *ngIf="formGet?.password_confirmation?.errors?.confirmedValidator">Your
                          Password and Confirm Password must match.
                        </p>
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.password_confirmation.errors.required">Confirm password is required</p>
                      </div>
                    </div>
                  </div>

                  <!-- BEGIN: Form Register Button -->
                  <div class="mt-6 md:flex md:flex-row text-center flex-col-reverse items-center">
                    <div class="ml-auto mt-4 md:mt-0">
                      <app-button-loader [buttonText]="'Submit'" [valid]="passwordForm.valid" [loader]="submitLoader"
                        [buttonType]="'submit'"></app-button-loader>
                    </div>
                  </div>
                  <!-- END: Form Register Button -->

                </form>

              </div>
              <!--end-->
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- END: Login Form -->

    <!-- BEGIN: Login Info -->
    <div class="md:col-span-6 xl:col-span-7 hidden lg:flex bg-login-grey min-h-screen justify-center">
      <div class="fixed h-full flex flex-col items-center justify-center">
        <!-- BEGIN: First Slide -->
        <app-right-carousel-intro></app-right-carousel-intro>
      </div>
    </div>
    <!-- END: Login Info -->
  </div>
</div>