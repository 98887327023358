import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { RecaptchaModule } from 'ng-recaptcha';
import { ChartsModule } from 'ng2-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ClipboardModule } from 'ngx-clipboard';
import { CookieService } from 'ngx-cookie-service';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { QuicklinkModule } from 'ngx-quicklink';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { ManageCardsModule } from 'src/app/components/manage-cards/manage-cards.module';
import { ExpiryNotificationTsGuard } from 'src/app/helper/expiry-notification.helper.ts.guard';
import { JwtInterceptor } from 'src/app/helper/jwtInterceptor.helper';
import { JwtInterceptorProfessional } from 'src/app/helper/jwtInterceptorProfessional.helper';
import { FileTypeIconsPipe } from 'src/app/pipes/file-type-icons.pipe';
import { PipeModule } from 'src/app/pipes/pipes.module';
import { SharedModule } from 'src/app/sharedComponent/shared.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { AccessControlDefaultsModalComponent } from './access-control-defaults-modal/access-control-defaults-modal.component';
import { AssignProfessionalComponent } from './assign-professional/assign-professional.component';
import { ChooseProfessionalModalComponent } from './assign-professional/choose-professional-modal/choose-professional-modal.component';
import { ChatModalComponent } from './chat-modal/chat-modal.component';
import { ClientLinkModalComponent } from './client-link-modal/client-link-modal.component';
import { ConfirmAccountProfessionalComponent } from './confirm-account-professional/confirm-account-professional.component';
import { HomeProfessionalAdvisorManagerComponent } from './home-professional-advisor-manager/home-professional-advisor-manager.component';
import { InvalidIdComponent } from './invalid-id/invalid-id.component';
import { CaliforniaPrivacyComponent } from './landing-page/california-privacy/california-privacy.component';
import { CompanyProComponent } from './landing-page/company-pro/company-pro.component';
import { ContactUsComponent } from './landing-page/contact-us/contact-us.component';
import { DigitalVaultAgreementProComponent } from './landing-page/digital-vault-agreement-pro/digital-vault-agreement-pro.component';
import { DigitalVaultLicensingAgreementProComponent } from './landing-page/digital-vault-licensing-agreement-pro/digital-vault-licensing-agreement-pro.component';
import { FeaturesComponent } from './landing-page/features/features.component';
import { LandingPageProModule } from './landing-page/landing-page.module';
import { PricingProComponent } from './landing-page/pricing-pro/pricing-pro.component';
import { UsPrivacyPolicyComponent } from './landing-page/us-privacy-policy/us-privacy-policy.component';
import { UsePolicyProComponent } from './landing-page/use-policy-pro/use-policy-pro.component';
import { UserAgreementProComponent } from './landing-page/user-agreement-pro/user-agreement-pro.component';
import { ProCheckoutComponent } from './pro-checkout/pro-checkout.component';
import { ProfessionalAccountSettingsComponent } from './professional-account-settings/professional-account-settings.component';
import { EditProfessionalAgentComponent } from './professional-agents/edit-professional-agent/edit-professional-agent.component';
import { HomeProfessionalAgentComponent } from './professional-agents/home-professional-agent/home-professional-agent.component';
import { ProfessionalAgentsComponent } from './professional-agents/professional-agents.component';
import { ProfessionalAuthModule } from './professional-auth/professional-auth.module';
import { ProfessionalChangePasswordComponent } from './professional-change-password/professional-change-password.component';
import { AddProfessionalClientComponent } from './professional-clients/add-professional-client/add-professional-client.component';
import { EditProfessionalClientComponent } from './professional-clients/edit-professional-client/edit-professional-client.component';
import { ClientBulkImportComponent } from './professional-clients/home-professional-client/client-bulk-import/client-bulk-import.component';
import { ClientManagementButtonGroupComponent } from './professional-clients/home-professional-client/client-management-button-group/client-management-button-group.component';
import { DocumentSearchModalComponent } from './professional-clients/home-professional-client/document-search-modal/document-search-modal.component';
import { HomeProfessionalClientComponent } from './professional-clients/home-professional-client/home-professional-client.component';
import { ImportProfessionalClientComponent } from './professional-clients/home-professional-client/import-professional-client/import-professional-client.component';
import { ProfessionalClientsComponent } from './professional-clients/professional-clients.component';
import { ProfessionalConsumerMessageComponent } from './professional-consumer-message/professional-consumer-message.component';
import { ClientManagementCardsComponent } from './professional-dashboard/client-management-cards/client-management-cards.component';
import { HomeProfessionalDashboardComponent } from './professional-dashboard/home-professional-dashboard/home-professional-dashboard.component';
import { ImportedVaultHoldersTableComponent } from './professional-dashboard/imported-vault-holders-table/imported-vault-holders-table.component';
import { ProfessionalDashboardComponent } from './professional-dashboard/professional-dashboard.component';
import { ProfessionalExpiryNotificationComponent } from './professional-dashboard/professional-expiry-notification/professional-expiry-notification.component';
import { ProfessionalInvoiceTableComponent } from './professional-dashboard/professional-invoice-table/professional-invoice-table.component';
import { ProfessionalManagementComponent } from './professional-management/professional-management.component';
import { ProfessionalsListingTableComponent } from './professional-management/view-professional-management/professionals-listing-table/professionals-listing-table.component';
import { ViewProfessionalManagementComponent } from './professional-management/view-professional-management/view-professional-management.component';
import { ProfessionalNavBarModule } from './professional-navbar/professional-navbar.module';
import { ProfessionalNotFoundComponent } from './professional-not-found/professional-not-found.component';
import { ProfessionalOtpVerificationModalComponent } from './professional-otp-verification-modal/professional-otp-verification-modal.component';
import { AddProfessionalPartnerComponent } from './professional-partners/add-professional-partner/add-professional-partner.component';
import { EditProfessionalPartnerComponent } from './professional-partners/edit-professional-partner/edit-professional-partner.component';
import { HomeProfessionalPartnerComponent } from './professional-partners/home-professional-partner/home-professional-partner.component';
import { ProfessionalPartnersComponent } from './professional-partners/professional-partners.component';
import { ProfessionalPaymentSettingsComponent } from './professional-payment-settings/professional-payment-settings.component';
import { ProfessionalPayoutComponent } from './professional-payout/professional-payout.component';
import { ProfessionalProfessionalMessageComponent } from './professional-professional-message/professional-professional-message.component';
import { ProfessionalProfileComponent } from './professional-profile/professional-profile.component';
import { ProfessionalResetPasswordComponent } from './professional-reset-password/professional-reset-password.component';
import { ProfessionalSupportComponent } from './professional-support/professional-support.component';
import { ProfessionalUnauthorizedComponent } from './professional-unauthorized/professional-unauthorized.component';
import { ProfessionalComponent } from './professional.component';
import { ProfessionalRoutingModule } from './professional.routing.module';
import { ProfileModalComponent } from './profile-modal/profile-modal.component';
import { ScheduleClientModalComponent } from './schedule-client-modal/schedule-client-modal.component';
import { ViewDetailedInfoModalComponent } from './view-detailed-info-modal/view-detailed-info-modal.component';

@NgModule({
  declarations: [
    ProfessionalComponent,
    ConfirmAccountProfessionalComponent,
    ProfessionalDashboardComponent,
    ProfessionalChangePasswordComponent,
    ProfessionalAgentsComponent,
    ProfessionalClientsComponent,
    ProfessionalConsumerMessageComponent,
    ProfessionalSupportComponent,
    HomeProfessionalAgentComponent,
    EditProfessionalAgentComponent,
    AddProfessionalClientComponent,
    HomeProfessionalClientComponent,
    EditProfessionalClientComponent,
    ProfessionalProfileComponent,
    ProfessionalAccountSettingsComponent,
    ProfessionalUnauthorizedComponent,
    ProfessionalNotFoundComponent,
    ProfessionalOtpVerificationModalComponent,
    InvalidIdComponent,
    ImportProfessionalClientComponent,
    ProfileModalComponent,
    ClientLinkModalComponent,
    ChatModalComponent,
    ProfessionalPayoutComponent,
    ProfessionalPaymentSettingsComponent,
    DocumentSearchModalComponent,
    ProfessionalExpiryNotificationComponent,
    HomeProfessionalDashboardComponent,
    CaliforniaPrivacyComponent,
    CompanyProComponent,
    ContactUsComponent,
    DigitalVaultAgreementProComponent,
    DigitalVaultLicensingAgreementProComponent,
    FeaturesComponent,
    PricingProComponent,
    UsPrivacyPolicyComponent,
    UsePolicyProComponent,
    UserAgreementProComponent,
    ProfessionalProfessionalMessageComponent,
    ScheduleClientModalComponent,
    ClientBulkImportComponent,
    ProfessionalPartnersComponent,
    HomeProfessionalPartnerComponent,
    AddProfessionalPartnerComponent,
    EditProfessionalPartnerComponent,
    AccessControlDefaultsModalComponent,
    ProfessionalResetPasswordComponent,
    ViewDetailedInfoModalComponent,
    ProCheckoutComponent,
    ProfessionalInvoiceTableComponent,
    HomeProfessionalAdvisorManagerComponent,
    AssignProfessionalComponent,
    ChooseProfessionalModalComponent,
    ProfessionalManagementComponent,
    ViewProfessionalManagementComponent,
    ProfessionalsListingTableComponent,
    ClientManagementButtonGroupComponent,
    ClientManagementCardsComponent,
    ImportedVaultHoldersTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ProfessionalNavBarModule,
    ReactiveFormsModule,
    RecaptchaModule,
    SharedModule,
    ProfessionalRoutingModule,
    FontAwesomeModule,
    ChartsModule,
    NgxShimmerLoadingModule,
    NgxFileDropModule,
    NgxMaskModule,
    ClipboardModule,
    BsDatepickerModule,
    IvyCarouselModule,
    PipeModule,
    QuicklinkModule,
    NgxPaginationModule,
    LandingPageProModule,
    TimepickerModule,
    ProfessionalAuthModule,
    DirectivesModule,
    ManageCardsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  exports: [
    ClientBulkImportComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorProfessional, multi: true },
    ExpiryNotificationTsGuard,
    CookieService,
    FileTypeIconsPipe,
    DatePipe,
  ],

})
/**
 * professional dashboard module
 */
export class ProfessionalDashboardModule {

}
