import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { LOGIN_USER_INTERFACE, RESEND_EMAIL_INTERFACE } from 'src/app/interface/user.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

/**
 * executor Custodian Service
 */
export class ExecutorCustodianService {
  private BASE_URL: string;
  private emitClaimVaultStatus: any;
  public claimVaultStatusEmitted: Observable<any>;

  /**
 * constructor
 */
  constructor(
    private http: HttpClient,
  ) {
    this.BASE_URL = environment.BASE_URL;
    this.emitClaimVaultStatus = new BehaviorSubject(0);
    this.claimVaultStatusEmitted = this.emitClaimVaultStatus.asObservable();
  }

 /**
   *
   * emit claim vault status
   * @param {any} change
   */
    public emitCVStatus(data:any) {
      this.emitClaimVaultStatus.next(data);
    }
  

  /**
   * login - executor/custodian
   *
   * @param {LOGIN_USER_INTERFACE} data
   * @return {Observable<APIResponseModel>}
   */
  public login(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/v1/people/login', data);
  }
  /**
   * login - executor/custodian
   *
   * @param {LOGIN_USER_INTERFACE} data
   * @return {Observable<APIResponseModel>}
   */
  public register(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/v1/people/register', data);
  }

  /**
   * Check valid mail link
   * @param email 
   * @returns Email is valid or not
   */
  public checkValidMailLink(email: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/request/check-people/${email}`);
  }

  /**
   * logout - executor/custodian
   *
   * @return {Observable<APIResponseModel>}
   */
  public logout(): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/people/logout ', {});
  }

  /**
   * Confirm account
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public confirmAccount(data: any): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/people/email/verify?token=${data.token}`);
  }

  /**
   * reset password request
   * @param{{}}data
   * @return {Observable<APIResponseModel>}
   */
  public passwordResetRequest(data: { email: string }): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/people/password/reset-request', data);
  }

  /**
   * reset password request
   * @param{{}}data
   * @return {Observable<APIResponseModel>}
   */
  public passwordReset(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/people/password/reset', data);
  }

  /**
   * change password
   * @param{{}}data
   * @return {Observable<APIResponseModel>}
   */
  public changePassword(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/people/change-password', data);
  }

  /**
   * change email
   * @param{{}}data
   * @return {Observable<APIResponseModel>}
   */
  public changeEmail(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/people/change-email-verification', data);
  }

  /**
   * Register user
   *
   * @param {any} userData
   * @return {Observable<APIResponseModel>}
   */
  public userRegister(userData: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/people/register', userData);
  }

  /**
   * Login user
   *
   * @param {LOGIN_USER_INTERFACE} userData
   * @return {Observable<APIResponseModel>}
   */
  public userLogin(userData: LOGIN_USER_INTERFACE): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/people/login', userData);
  }

  /**
   * get current executor/custodian details
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public getCurrentUser(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/people/profile');
  }

  /**
   *update personal details
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public updatePersonalDetails(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/people/update-profile', data);
  }

  /**
   * Updates user death aprove status
   * @param{any}id
   * @return {Observable}
   */
  public updateUserDeathAproveStatus(id: any): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/departed-not-approved/${id}`);
  }

  /**
   * Update phone
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public updatePhoneNumber(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/people/change-phone', data);
  }

  /**
   * Update profile picture
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public updateProfilePicture(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/people/change-profile-picture', data);
  }

  /**
   * Verify Otp
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public verifyOtp(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/people/update-email`, data);
  }
  /**
   * pal verifylink
   * @param{string} data
   * @return{Observable}
   */
  public idPalVerifylink(data: string): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/id-pal/app-link', data);
  }


  /**
 * Checks security questions status
 * @return{Observable}
 */
  public checkSecurityQuestionsStatus(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/people/check-claim-vault-permission`);
  }

  /**
 * Securitys questions list
 * @return{Observable}
 */
  public securityQuestionsList(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/security-question/list`);
  }

  /**
 * Stores secuity question answer
 * @param{Object} data
 * @param{boolean}isChallenge
 * @return{Observable}
 */
  public storeSecurityQueAns(data: Object, isChallenge: boolean): Observable<APIResponseModel> {
    if (isChallenge) {
      return this.http.post<APIResponseModel>(this.BASE_URL + '/security-question/check-security-challenge', data);
    } else {
      return this.http.post<APIResponseModel>(this.BASE_URL + '/security-question/store-people-answer', data);
    }
  }

  /**
 * Security questions list based on the user
 * @return{Observable}
*/
  public userSecurityQuestions(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/security-question/get-people-question`);
  }

  /**
   * Resend email
   *
   * @param {RESEND_EMAIL_INTERFACE} data
   * @return {Observable<APIResponseModel>}
   */
  public resendEmail(data: RESEND_EMAIL_INTERFACE): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/people/resend-verify-email', data);
  }
}
