import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { requirePasswordToBeCheckedValidator } from 'src/app/helper/customValidatorPassword.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { SlugService } from 'src/app/services/slug.service';
import { UserService } from 'src/app/services/user.service';
import { EMAIL_PATTERN, NAME_PATTERN, PASSWORD_REGEX, PHONE_PATTERN, USER_TYPES } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../helper/validation.helper';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
/**
 * Register component
 */
export class RegisterComponent implements OnInit {
  public countryCode = environment.DEFAULT_COUNTRY_CODE;
  public readonly faEye = faEye;
  public readonly faEyeSlash = faEyeSlash;
  public readonly googleCaptchaKey = environment.GOOGLE_CAPTCHA_KEY
  public registerForm: FormGroup;
  public submitted: boolean;
  public togglePasswordView: boolean = false;
  public toggleNewPasswordViewConfirm: boolean;
  public registerLoader: boolean = false;
  public isRegistered: boolean = false;
  public isRecaptchaValidationEnable: boolean = false;
  public captchaEnabled: boolean;
  public requestId: string;
  // public promoCodeId:string;
  // public paymentType: string;
  public proId:string;
  public menuActive: string;
  public orgLogo: string;
  public orgLogoPath: string;
  public slugUrl: string;
  public emailId: string = '';
  public isDisableEmail: boolean = false;



  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private localStorage: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private slugInterceptorService: SlugInterceptorService,
    private slugService: SlugService,
    private validation: ValidationHelper,
  ) {
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.commonHelper.logoutCommon(undefined, false);
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      this.orgLogoPath = response ? this.commonHelper.getOrgLogo() : '';
      this.orgLogo = response ? `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}` : '';
      this.route.params.subscribe(params => {
        if(params['slug']){
        void this.slugInterceptorService.navigate(['login']); //Redirect to the login page because user trying to register under professionals.
        }
      });
    });
    const queryParams = this.commonHelper.decryptRegisterLink(this.router.url);
    this.requestId = this.route.snapshot.params['id'];
    if (!this.requestId) {
      // to handle if we have id only (if use request id)
    }
    // this.promoCodeId = queryParams['promo'];
    // this.paymentType = queryParams['payment_type'];
    this.isDisableEmail = !!(queryParams['id']);
    this.emailId = queryParams['id'];
    this.proId = queryParams['proId'];
    this.initForm();
    this.setRecaptchaValidation();
  }


  /**
   * See {@link ValidationHelper.getErrors}
   * @param{string} controlKey
   * @return{Array<string>}
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.registerForm);
  }


  /**
   * Initialize form
   */
  private initForm() {
    // register form
    this.registerForm = this.formBuilder.group({
      'first_name': ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN)] }],
      'middle_name': ['', { updateOn: 'blur', validators: [Validators.pattern(NAME_PATTERN), this.validation.fn.trim, Validators.maxLength(50)] }],
      'last_name': ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN)] }],
      'email': [this.emailId , { updateOn: 'blur', validators: [Validators.required, Validators.pattern(EMAIL_PATTERN)] }],
      'phone': ['', { updateOn: 'blur', validators: [Validators.pattern(PHONE_PATTERN)] }],
      'password': ['', [Validators.required, Validators.minLength(8), Validators.pattern(PASSWORD_REGEX), requirePasswordToBeCheckedValidator()]],
      'confirm_password': [''],
      'g-recaptcha-response': ['', [this.isRecaptchaValidationEnable ? Validators.required : Validators.nullValidator]],
      agree: [false, [Validators.requiredTrue]],
    });
    this.validation.setValidationErrors({
      phone: { pattern: 'Please enter a valid phone number' },
      email: { pattern: 'Please enter a valid email address.' },
      password: { passwordStrength: null, minlength: null },
    });

    this.registerForm.controls['first_name'].valueChanges.subscribe(() => this.validation.trimWhitespace(this.registerForm.controls['first_name']));
    this.registerForm.controls['middle_name'].valueChanges.subscribe(() => this.validation.trimWhitespace(this.registerForm.controls['middle_name']));
    this.registerForm.controls['last_name'].valueChanges.subscribe(() => this.validation.trimWhitespace(this.registerForm.controls['last_name']));
  }


  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.registerForm.controls;
  }


  /**
   * @param{string}url
   * on menu change
   */
  public clickOnLogo(url?: string) {
    this.menuActive = url;
    void this.slugInterceptorService.navigate(['/']);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }


  /**
   * on menu change
   * @param{string}url
   * @param newTab
   */
  public menuChange(url: string, newTab: boolean = false) {
    this.menuActive = url;
    void this.slugInterceptorService.navigateByUrl(url, undefined, undefined, newTab);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }


  /**
   * Register user
   *
   *  @param {any} captchaRef for resetting the captcha field
   */
  public registerUser(captchaRef?: any): void {
    this.registerLoader = true;
    // register user
    this.slugUrl = localStorage.getItem('verifiedSlug');
    const isLoggedIn = !!this.localStorage.getUserData()?.accessToken; // Only logged-in user has accessToken
    const currentSession = this.sessionStorageService.getDataFromSession('user');


    if (isLoggedIn && currentSession) {
      this.modalService.open('user-logged-in');
      setTimeout(() => {
        void this.slugInterceptorService.navigate(['/dashboard']);
        this.modalService.close('user-logged-in');
      }, 2000);
    } else {
      const payload = {
        ...this.registerForm.value,
        country_code: this.countryCode.dialCode,
        flag_code: this.countryCode.code,
        slug_url: this.slugUrl,
        co_brand_slug_id: this.slugUrl? this.slugUrl : '',
        // promo_code_id: this.promoCodeId,
        // payment_type: this.paymentType? this.paymentType : ConsumerPaymentMethod.PASS_TO_CONSUMER,
        proId: this.proId,
      };
      if (this.requestId) {
        payload['referer_id'] = this.requestId;
      }
      this.userService.userRegister(payload).subscribe({
        next: (response: APIResponseModel) => {
          // NOTE : Email Stored in Local Storage without Encryption for Evergage Tracking Purposes.
          localStorage.setItem('email',payload.email);
          this.registerLoader = false;
          this.isRegistered = true;
          this.localStorage.storeUserData(USER_TYPES.user, response.data);
          this.toastrService.success(response.message);
          this.localStorage.storeData('email-verification-sent', !!this.requestId || this.registerForm.value.email);
          void this.slugInterceptorService.navigate(['/', '/login']);
          this.captchaReset(captchaRef);
        },
        error: (exception: any) => {
          this.registerLoader = false;
          this.isRegistered = false;
          this.commonHelper.httpResponseHandler(exception?.error);
          this.captchaReset(captchaRef);
        },
      },
      );
    }
  }


  /**
   * resolve captcha google
   *
   * @param {string} captchaResponse
   */
  public resolvedCaptcha(captchaResponse: string): void {
    if (!captchaResponse) return;
    this.registerForm.patchValue({
      'g-recaptcha-response': captchaResponse,
    });
  }


  /**
   * catch error captcha google
   *
   * @param {string} errorDetails
   */
  public onErrorCaptcha(errorDetails: RecaptchaErrorParameters): void {
    console.log(errorDetails);
  }


  /**
   * reset captcha
   *
   * @param {any} captchaRef
   */
  public captchaReset(captchaRef: any): void {
    captchaRef.reset();
    this.registerForm.get('g-recaptcha-response').reset();
  }


  /**
   *
   * @return{boolean}
   */
  public checkPasswordMatch(): boolean {
    return this.registerForm.controls?.['password']?.value == this.registerForm.controls?.['confirm_password']?.value;
  }

  /**
   * Sets up reCaptcha validation for the register form based on the stored configuration.
   * If the configuration is not found in local storage, it fetches it from the common service.
   */
  public setRecaptchaValidation(): void {
    this.commonHelper.getCaptchaConfig().subscribe({
      next: (response) =>{
        this.isRecaptchaValidationEnable = response?.data['is_enabled'];
        this.registerForm.get('g-recaptcha-response').setValidators(this.isRecaptchaValidationEnable ? Validators.required : Validators.nullValidator);
      }
    })
  }

  /**
   * Detect change on phone number component
   * @param event
   */
public detectChangePhonenumber(event): void {
  if (event) {
    this.countryCode = event.value;
  }
}
}
