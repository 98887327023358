<div id="property">
  <div class="grid grid-cols-12 gap-6 pt-8">
    <div class="col-span-12">
      <app-chips [chipsList]="chipsListAccess" [selected]="0" (changeEmitter)="chipChangeListener($event)"></app-chips>
    </div>
    <!-- Property overview details -->
    <ng-container
      *ngIf="(currentFilter === 0 || currentFilter === 1) && permission_status?.['Real Estate']?.['status']">
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="preLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Real Estate
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
            <div class="col-span-12 sm:col-span-1">
              <app-shimmer-loading [loading]="preLoader" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 sm:col-span-11 sm:ml-6">
              <h4 class="text-label-grey text-sm text-regular">Property Name</h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 sm:col-span-11 ">
              <h4 class="text-label-grey text-sm text-regular">Property Address
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Price of Property
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!preLoader && propertyArray?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Real Estate
              </h4>
            </div>
            <div class="col-span-4">
              <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                {{this.propertyData?.['asset']?.property | currency}}
                <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-2 sm:ml-4 cursor-pointer float-right"
                  alt="digital vault" *ngIf="permission_status?.['Real Estate']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.PROPERTY), this.userType !== 'Consumer' ? this.clientRequestId : null)">
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4">
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4" *ngFor="let data of propertyArray;let i = index">
              <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full">
              </div>
              <div class="col-span-12 sm:col-span-2">
                <div class="flex items-center justify-center mx-auto rounded-full">
                  <app-image-component [image]="'/assets/images/property-details/property.svg'"
                    [class]="'object-cover rounded-full h-12 w-12'">
                  </app-image-component>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-10 sm:ml-4">
                <h4 class="text-label-grey text-sm text-regular">Property Name</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['property_name']}}</p>
              </div>
              <div class="col-span-12 sm:col-span-10 ">
                <h4 class="text-label-grey text-sm text-regular">Property Address
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.address}}</p>
              </div>
              <ng-container *ngIf="data?.country?.name">
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm tracking-wide text-regular">Country
                  </h4>
                  <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize">
                    {{data?.country?.name}}</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm tracking-wide text-regular">State
                  </h4>
                  <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize">
                    {{data?.state?.name}}</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm tracking-wide text-regular">City
                  </h4>
                  <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize">
                    {{data?.city?.name}}</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm tracking-wide text-regular">Zip Code
                  </h4>
                  <p class="text-darkgrey break-all text-base text-regular tracking-wide">
                    {{data?.['zipcode']}}</p>
                </div>
              </ng-container>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Price of Property
                </h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{data?.['price'] | currency}}</p>
                  <p *ngIf="!data?.['price']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['current_price']">
                <h4 class="text-label-grey text-sm text-regular">Current Price Of Property
                </h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{data?.['current_price'] | currency}}</p>
                  <p *ngIf="!data?.['current_price']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!preLoader && propertyArray?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Real Estate
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-2 sm:ml-4 cursor-pointer float-right"
                alt="digital vault"
                *ngIf="permission_status?.['Real Estate']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.PROPERTY), this.userType !== 'Consumer' ? this.clientRequestId : null)">
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
    </ng-container>

    <!-- Vehicle overview details -->
    <ng-container *ngIf="(currentFilter === 0 || currentFilter === 2) && permission_status?.['Vehicles']?.['status']">
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="preLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Vehicles
            </h4>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
            <div class="col-span-12 sm:col-span-2">
              <div class="flex items-center justify-center mx-auto rounded-full">
                <app-shimmer-loading [loading]="preLoader" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-10 sm:ml-6">
              <h4 class="text-label-grey text-sm text-regular">Vehicle</h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Year
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Licence Plate
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Country of Registration
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">State of Registration
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Price
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Description
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Insurance Company
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Policy Number
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Insurance Agent
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Agent Contact Info
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!preLoader && vehicleArray?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Vehicle
              </h4>
            </div>
            <div class="col-span-4">
              <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                {{this.propertyData?.['asset']?.['vehicle'] | currency}}
                <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-2 sm:ml-4 cursor-pointer float-right"
                  alt="digital vault" *ngIf="permission_status?.['Vehicles']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.VEHICLE), this.userType !== 'Consumer' ? this.clientRequestId : null)">
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4" *ngFor="let data of vehicleArray;let i = index">
            <div class="col-span-12" *ngIf="i">
              <hr class="divider w-full">
            </div>
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
              <div class="col-span-12 sm:col-span-2">
                <div class="flex items-center justify-center mx-auto rounded-full">
                  <app-image-component [image]="'assets/images/property-details/vehicles.svg'"
                    [class]="'object-cover rounded-full h-12 w-12'">
                  </app-image-component>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-10 sm:ml-6">
                <h4 class="text-label-grey text-sm text-regular">Vehicle</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['vehicle_type']}}
                  {{data?.['vehicle_model']}}
                  {{data?.['vehicle_make']}}</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Year
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.year}}</p>
                <p *ngIf="!data?.['year']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <ng-container *ngIf="data?.country?.name">
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">Country of Registration
                  </h4>
                  <p class="text-lg text-regular text-darkgrey capitalize">{{data?.country?.name}}</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">State of Registration
                  </h4>
                  <p class="text-lg text-regular text-darkgrey capitalize">{{data?.state?.name}}</p>
                  <p *ngIf="!data?.['state']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>
                </div>
              </ng-container>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Vehicle Identification Number (VIN)
                </h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{data?.['vin']}}</p>
                <p *ngIf="!data?.['vin']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Vehicle Plate Number
                </h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{data?.['licence']}}</p>
                <p *ngIf="!data?.['licence']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Purchase Price
                </h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{data?.['vehicle_value'] | currency}}</p>
                <p *ngIf="!data?.['vehicle_value']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Estimated Price
                </h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{data?.['estimated_value'] | currency}}</p>
                <p *ngIf="!data?.['estimated_value']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Description
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.description}}</p>
                <p *ngIf="!data?.['description']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!preLoader && vehicleArray?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Vehicle
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-2 sm:ml-4 cursor-pointer float-right"
                alt="digital vault"
                *ngIf="permission_status?.['Vehicles']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.VEHICLE), this.userType !== 'Consumer' ? this.clientRequestId : null)">
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
    </ng-container>
    <!-- Business overview details -->
    <ng-container *ngIf="(currentFilter === 0 || currentFilter === 3) && permission_status?.['Business']?.['status']">
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="preLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Business
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
            <div class="col-span-12 sm:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">Company Name</h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">Company Address
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Country
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">State
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">City
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">Zip Code
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Type Of Business
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Website
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">Summary of Business
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!preLoader && businessArray?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Business
              </h4>
            </div>
            <div class="col-span-4">
              <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                {{this.propertyData?.['asset']?.['business'] | currency}}
                <img src="assets/images/about-yourself/edit.svg"
                  class="w-4 ml-2 sm:ml-4 cursor-pointer float-right cursor-pointer" alt="digital vault"
                  *ngIf="permission_status?.['Business']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.BUSINESS), this.userType !== 'Consumer' ? this.clientRequestId : null)">
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4" *ngFor="let data of businessArray;let i = index">
            <div class="col-span-12" *ngIf="i">
              <hr class="divider w-full">
            </div>
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
              <div class="col-span-12 sm:col-span-2 ">
                <div class="flex items-center justify-center mx-auto rounded-full">
                  <app-image-component [image]="'assets/images/property-details/business.svg'"
                  [class]="'object-cover rounded-full h-12 w-12'">
                </app-image-component>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">Company Name</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['company_name']}}</p>
              </div>
              <div class="col-span-12 md:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">Company Address
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.address}}</p>
              </div>
              <ng-container *ngIf="data?.country?.name">
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm tracking-wide text-regular">Country
                  </h4>
                  <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize">
                    {{data?.country?.name}}</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm tracking-wide text-regular">State
                  </h4>
                  <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize">
                    {{data?.state?.name}}</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm tracking-wide text-regular">City
                  </h4>
                  <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize">
                    {{data?.city?.name}}</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm tracking-wide text-regular">Zip Code
                  </h4>
                  <p class="text-darkgrey break-all text-base text-regular tracking-wide">
                    {{data?.['zipcode']}}</p>
                </div>
              </ng-container>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['business_organization_type']?.name">
                <h4 class="text-label-grey text-sm text-regular">Type of Organization
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['business_organization_type']?.name}}</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Type Of Business
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['business_type']?.name}}</p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['ein']">
                <h4 class="text-label-grey text-sm text-regular">Employer Identification Number (EIN)
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['ein']}}</p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['net_value']">
                <h4 class="text-label-grey text-sm text-regular">Net Value
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['net_value'] | currency}}</p>
              </div>
              <div class="col-span-12 md:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">Website
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.website}}</p>
                <p *ngIf="!data?.['website']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">Summary of Business
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['business_summary']}}</p>
                <p *ngIf="!data?.['business_summary']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!preLoader && businessArray?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Business
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <img src="assets/images/about-yourself/edit.svg"
                class="w-4 ml-2 sm:ml-4 cursor-pointer float-right cursor-pointer" alt="digital vault"
                *ngIf="permission_status?.['Business']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.BUSINESS), this.userType !== 'Consumer' ? this.clientRequestId : null)">
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>

    </ng-container>

    <!-- Valuables overview details -->
    <ng-container *ngIf="(currentFilter === 0 || currentFilter === 4)&& permission_status?.['Valuables']?.['status']">
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="preLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Valuables
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
            <div class="col-span-12 sm:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Valuable</h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Price
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">Summary of Valuable
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!preLoader && valuablesArray?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Valuables
              </h4>
            </div>
            <div class="col-span-4">

              <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                {{this.propertyData?.['asset']?.['valuable'] | currency}}
                <img src="assets/images/about-yourself/edit.svg"
                  class="w-4 ml-2 sm:ml-4 cursor-pointer float-right cursor-pointer" alt="digital vault"
                  *ngIf="permission_status?.['Valuables']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.VALUABLES), this.userType !== 'Consumer' ? this.clientRequestId : null)">
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4" *ngFor="let data of valuablesArray;let i = index">
            <div class="col-span-12" *ngIf="i">
              <hr class="divider w-full">
            </div>
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
              <div class="col-span-12 sm:col-span-2 ">
                <div class="flex items-center justify-center mx-auto rounded-full">
                  <app-image-component [image]="'/assets/images/property-details/valuables.svg'"
                    [class]="'object-cover rounded-full h-12 w-12'">
                  </app-image-component>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-10 sm:ml-6">
                <h4 class="text-label-grey text-sm text-regular">Valuable</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['valuable_type']?.name}}</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Price
                </h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{data?.['price'] | currency}}</p>
              </div>
              <div class="col-span-12 md:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">Summary of Valuable
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.summary}}</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!preLoader && valuablesArray?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Valuables
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <img src="assets/images/about-yourself/edit.svg"
                class="w-4 ml-2 sm:ml-4 cursor-pointer float-right cursor-pointer" alt="digital vault"
                *ngIf="permission_status?.['Valuables']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.VALUABLES), this.userType !== 'Consumer' ? this.clientRequestId : null)">
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
    </ng-container>
    <!-- Social Media overview details -->
    <ng-container
      *ngIf="(currentFilter === 0 || currentFilter === 5)&& permission_status?.['Social Media']?.['status']">
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="preLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Social Media
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
            <div class="col-span-12 sm:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Type of Social Media</h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Username
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">Password
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!preLoader && socialMediaArray?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Social Media
              </h4>
            </div>
            <div class="col-span-4">
              <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                <img src="assets/images/about-yourself/edit.svg"
                  class="w-4 ml-2 sm:ml-4 cursor-pointer float-right cursor-pointer" alt="digital vault"
                  *ngIf="permission_status?.['Social Media']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.SOCIAL_MEDIA), this.userType !== 'Consumer' ? this.clientRequestId : null)">
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4"
            *ngFor="let data of socialMediaArray;let i = index">
            <div class="col-span-12" *ngIf="i">
              <hr class="divider w-full">
            </div>
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
              <div class="col-span-12 sm:col-span-2 ">
                <div class="flex items-center justify-center mx-auto rounded-full">
                  <app-image-component [image]="'assets/images/property-details/Associations.svg'"
                  [class]="'object-cover rounded-full h-12 w-12'">
                </app-image-component>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-10 sm:ml-6">
                <h4 class="text-label-grey text-sm text-regular">Type of Social Media</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['social_media_name']}}</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Website
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['website']}}</p>
                <p *ngIf="!data?.['website']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Username
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['user_name']}}</p>
                <p *ngIf="!data?.['user_name']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">Password
                </h4>
                <p class="text-lg text-regular text-darkgrey" *ngIf="data?.['password']">*******</p>
                <p *ngIf="!data?.['password']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!preLoader && socialMediaArray?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Social Media
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <img src="assets/images/about-yourself/edit.svg"
                class="w-4 ml-2 sm:ml-4 cursor-pointer float-right cursor-pointer" alt="digital vault"
                *ngIf="permission_status?.['Social Media']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.SOCIAL_MEDIA), this.userType !== 'Consumer' ? this.clientRequestId : null)">
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>

    </ng-container>
    <!-- Subscriptions overview details -->
    <ng-container
      *ngIf="(currentFilter === 0 || currentFilter === 6)&& permission_status?.['Subscriptions']?.['status']">
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="preLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Subscriptions
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
            <div class="col-span-12 sm:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Type of Subscription</h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Name of Subscription
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>

            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">Phone Number
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!preLoader && subscriptionsArray?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Subscriptions
              </h4>
            </div>
            <div class="col-span-4">
              <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                <img src="assets/images/about-yourself/edit.svg"
                  class="w-4 ml-2 sm:ml-4 cursor-pointer float-right cursor-pointer" alt="digital vault"
                  *ngIf="permission_status?.['Subscriptions']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.SUBSCRIPTIONS), this.userType !== 'Consumer' ? this.clientRequestId : null)">
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4"
            *ngFor="let data of subscriptionsArray;let i = index">
            <div class="col-span-12" *ngIf="i">
              <hr class="divider w-full">
            </div>
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
              <div class="col-span-12 sm:col-span-2 ">
                <div class="flex items-center justify-center mx-auto rounded-full">
                  <app-image-component [image]="'assets/images/property-details/subscriptions.svg'"
                    [class]="'object-cover rounded-full h-12 w-12'">
                  </app-image-component>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-10 sm:ml-6">
                <h4 class="text-label-grey text-sm text-regular">Type of Subscription</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['subscriptions']?.name}}</p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['account_number']">
                <h4 class="text-label-grey text-sm text-regular">Account/Member Number </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['account_number']}}</p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['subscription_term']">
                <h4 class="text-label-grey text-sm text-regular">Subscription Term </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['subscription_term']}}</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Name of Subscription
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['subscription_name']}}</p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['website']">
                <h4 class="text-label-grey text-sm text-regular">Website</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['website']}}</p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['user_name']">
                <h4 class="text-label-grey text-sm text-regular">Username</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['user_name']}}</p>
              </div>
              <div class="col-span-12 md:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">Password
                </h4>
                <p class="text-lg text-regular text-darkgrey" *ngIf="data?.['password']">*******</p>
                <p *ngIf="!data?.['password']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">Phone Number
                </h4>
                <p *ngIf="data?.['contact_number']" class="text-lg text-regular text-darkgrey">
                  +{{data?.['country_code']}} {{data?.['contact_number']}}</p>
                <p *ngIf="!data?.['contact_number']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!preLoader && subscriptionsArray?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Subscriptions
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <img src="assets/images/about-yourself/edit.svg"
                class="w-4 ml-2 sm:ml-4 cursor-pointer float-right cursor-pointer" alt="digital vault"
                *ngIf="permission_status?.['Subscriptions']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.SUBSCRIPTIONS), this.userType !== 'Consumer' ? this.clientRequestId : null)">
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>

    </ng-container>
    <!-- Donations overview details -->
    <ng-container *ngIf="(currentFilter === 0 || currentFilter === 7)&& permission_status?.['Donations']?.['status']">
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="preLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Donations
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
            <div class="col-span-12 sm:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Type of Donation</h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Name of Organization
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">Phone Number
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">Address
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">Country
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">State
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">City
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">Zip Code
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>

          </div>
        </div>
      </div>
      <ng-container *ngIf="!preLoader && donationArray?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Donations
              </h4>
            </div>
            <div class="col-span-4">
              <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                <img src="assets/images/about-yourself/edit.svg"
                  class="w-4 ml-2 sm:ml-4 cursor-pointer float-right cursor-pointer" alt="digital vault"
                  *ngIf="permission_status?.['Donations']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.DONATIONS), this.userType !== 'Consumer' ? this.clientRequestId : null)">
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4" *ngFor="let data of donationArray;let i = index">
            <div class="col-span-12" *ngIf="i">
              <hr class="divider w-full">
            </div>
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
              <div class="col-span-12 sm:col-span-2 ">
                <div class="flex items-center justify-center mx-auto rounded-full">
                  <app-image-component [image]="'assets/images/property-details/donation-list.svg'"
                    [class]="'object-cover rounded-full h-12 w-12'">
                  </app-image-component>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-10 sm:ml-6">
                <h4 class="text-label-grey text-sm text-regular">Type of Donation</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['donation_type_name']}}</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Name of Organization
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['organization_name']}}</p>
              </div>
              <div class="col-span-12 md:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">Phone Number
                </h4>
                <p class="text-lg text-regular text-darkgrey">+{{data?.['country_code']}} {{data?.phone}}</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Date of Donation ( MM/DD/YYYY )
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['date_of_donation'] | date: 'MM/dd/yyyy'}}</p>
                <p *ngIf="!data?.['date_of_donation']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Amount
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['amount'] | currency}}</p>
                <p *ngIf="!data?.['amount']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Address
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.address}}</p>
              </div>
              <ng-container *ngIf="data?.country?.name">
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">Country
                  </h4>
                  <p class="text-lg text-regular text-darkgrey capitalize">{{data?.country?.name}}</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">State
                  </h4>
                  <p class="text-lg text-regular text-darkgrey capitalize">{{data?.state?.name}}</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">City
                  </h4>
                  <p class="text-lg text-regular text-darkgrey capitalize">{{data?.city?.name}}</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">Zip Code
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">{{data?.['zipcode']}}</p>
                </div>
              </ng-container>
              <div class="col-span-12 md:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">Website
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.website}}</p>
                <p *ngIf="!data?.['website']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Username
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['user_name']}}</p>
                <p *ngIf="!data?.['user_name']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">Password
                </h4>
                <p class="text-lg text-regular text-darkgrey" *ngIf="data?.['password']">*******</p>
                <p *ngIf="!data?.['password']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>

            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!preLoader && donationArray?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Donations
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <img src="assets/images/about-yourself/edit.svg"
                class="w-4 ml-2 sm:ml-4 cursor-pointer float-right cursor-pointer" alt="digital vault"
                *ngIf="permission_status?.['Donations']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.DONATIONS), this.userType !== 'Consumer' ? this.clientRequestId : null)">
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
    </ng-container>
    <!-- Prescriptions overview details -->
    <ng-container
      *ngIf="(currentFilter === 0 || currentFilter === 8)&& permission_status?.['Prescriptions']?.['status']">
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="preLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Prescriptions
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
            <div class="col-span-12 sm:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Prescription Name</h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Pharmacy Name
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">Phone Number
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">Address
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">Country
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">State
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">City
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-12">
              <h4 class="text-label-grey text-sm text-regular">Zip Code
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>

          </div>
        </div>
      </div>
      <ng-container *ngIf="!preLoader && prescriptionsArray?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Prescriptions
              </h4>
            </div>
            <div class="col-span-4">
              <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                <img src="assets/images/about-yourself/edit.svg"
                  class="w-4 ml-2 sm:ml-4 cursor-pointer float-right cursor-pointer" alt="digital vault"
                  *ngIf="permission_status?.['Prescriptions']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.PRESCRIPTIONS), this.userType !== 'Consumer' ? this.clientRequestId : null)">
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4"
            *ngFor="let data of prescriptionsArray;let i = index">
            <div class="col-span-12" *ngIf="i">
              <hr class="divider w-full">
            </div>
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
              <div class="col-span-12 sm:col-span-2 ">
                <div class="flex items-center justify-center mx-auto rounded-full">
                  <app-image-component [image]="'assets/images/property-details/prescription-list.svg'"
                    [class]="'object-cover rounded-full h-12 w-12'">
                  </app-image-component>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-10 sm:ml-6">
                <h4 class="text-label-grey text-sm text-regular">Prescription Name</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['prescription_name']}}</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Pharmacy Name
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['pharmacy_name']}}</p>
                <p *ngIf="!data?.['pharmacy_name']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">Prescription Number​
                </h4>
                <p *ngIf="data?.['prescription_number']" class="text-lg text-regular text-darkgrey">
                  {{data?.prescription_number}}</p>
                <p *ngIf="!data?.['prescription_number']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">Phone Number
                </h4>
                <p *ngIf="data?.['phone']" class="text-lg text-regular text-darkgrey">+{{data?.['country_code']}}
                  {{data?.phone}}</p>
                <p *ngIf="!data?.['phone']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">Expiry Date ( MM/DD/YYYY )</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['expiry_date'] | date: "MM/dd/YYYY"}}</p>
                <p *ngIf="!data?.['expiry_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Address
                </h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.address}}</p>
                <p *ngIf="!data?.['address']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <ng-container *ngIf="data?.country?.name">

                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">Country
                  </h4>
                  <p class="text-lg text-regular text-darkgrey capitalize">{{data?.country?.name}}</p>
                  <p *ngIf="!data?.['country']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">State
                  </h4>
                  <p class="text-lg text-regular text-darkgrey capitalize">{{data?.state?.name}}</p>
                  <p *ngIf="!data?.['state']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">City
                  </h4>
                  <p class="text-lg text-regular text-darkgrey capitalize">{{data?.city?.name}}</p>
                  <p *ngIf="!data?.['city']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">Zip Code
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">{{data?.['zipcode']}}</p>
                  <p *ngIf="!data?.['zipcode']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!preLoader && prescriptionsArray?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Prescriptions
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <img src="assets/images/about-yourself/edit.svg"
                class="w-4 ml-2 sm:ml-4 cursor-pointer float-right cursor-pointer" alt="digital vault"
                *ngIf="permission_status?.['Prescriptions']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.PRESCRIPTIONS), this.userType !== 'Consumer' ? this.clientRequestId : null)">
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>

    </ng-container>
    <!-- Government Id overview details -->
    <ng-container
      *ngIf="(currentFilter === 0 || currentFilter === 9)&& permission_status?.['Government Id']?.['status']">
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="preLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Government ID
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
            <div class="col-span-12 sm:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">Type of Government ID</h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm text-regular">ID Number
              </h4>
              <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!preLoader && governmentIdArray?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Government ID
              </h4>
            </div>
            <div class="col-span-4">
              <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                <img src="assets/images/about-yourself/edit.svg"
                  class="w-4 ml-2 sm:ml-4 cursor-pointer float-right cursor-pointer" alt="digital vault"
                  *ngIf="permission_status?.['Government Id']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.GOV_IDS), this.userType !== 'Consumer' ? this.clientRequestId : null)">
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4"
            *ngFor="let data of governmentIdArray;let i = index">
            <div class="col-span-12" *ngIf="i">
              <hr class="divider w-full">
            </div>
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
              <div class="col-span-12 sm:col-span-2 ">
                <div class="flex items-center justify-center mx-auto rounded-full">
                  <app-image-component
                    [image]="'assets/images/property-details/Citizenship%20Card.svg'"
                    [defaultImage]="'assets/images/property-details/Citizenship Card.svg'"
                    [class]="'object-cover rounded-full h-12 w-12'">
                  </app-image-component>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-10 sm:ml-6">
                <h4 class="text-label-grey text-sm text-regular">Type of Government ID</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['government_id']?.['id_name']}}</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">ID Number</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['id_number']}}</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">ID Issued Date ( MM/DD/YYYY )</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['issue_date'] | date:"MM/dd/YYYY"}}</p>
                <p *ngIf="!data?.['issue_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-12">
                <h4 class="text-label-grey text-sm text-regular">ID Expiry Date ( MM/DD/YYYY )</h4>
                <p class="text-lg text-regular text-darkgrey">{{data?.['expiry_date'] | date: "MM/dd/YYYY"}}</p>
                <p *ngIf="!data?.['expiry_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!preLoader && governmentIdArray?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Government ID
            </h4>
          </div>
          <div class="col-span-4">
            <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <img src="assets/images/about-yourself/edit.svg"
                class="w-4 ml-2 sm:ml-4 cursor-pointer float-right cursor-pointer" alt="digital vault"
                *ngIf="permission_status?.['Government Id']?.['edit'] && !isAccessingVault && !isVaultClaimed"
                (click)="getCommonHelper.routerNavigate(getRoute(this.ROUTE.GOV_IDS), this.userType !== 'Consumer' ? this.clientRequestId : null)">
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
    </ng-container>
  </div>
</div>
