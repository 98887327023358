<div id="finance">
  <div class="grid grid-cols-12 gap-6 pt-8">
    <div class="col-span-12">
      <app-chips [chipsList]="chipsListAccess" [selected]="0" (changeEmitter)="chipChangeListener($event)"></app-chips>
    </div>
    <div class="col-span-12 my-4 lg:pr-6" *ngIf="preLoader">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-8">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Financial Assets
          </h4>
        </div>
        <div class="col-span-4">
          <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </p>
        </div>
      </div>
      <div class="bg-card-overview border-card-grey rounded mt-4">
        <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
          <div class="col-span-12 sm:col-span-1">
            <app-shimmer-loading [loading]="preLoader" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 sm:col-span-11 sm:ml-6">
            <h4 class="text-label-grey text-sm text-regular">Bank or Brokerage (Where Asset is Held)</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Account Number</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Asset Nickname</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Routing Number</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Amount</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Username</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Password</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="preLoader">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-8">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Retirement assets
          </h4>
        </div>
        <div class="col-span-4">
          <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </p>
        </div>
      </div>
      <div class="bg-card-overview border-card-grey rounded mt-4">
        <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
          <div class="col-span-12 sm:col-span-1">
            <app-shimmer-loading [loading]="preLoader" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 sm:col-span-11 sm:ml-6">
            <h4 class="text-label-grey text-sm text-regular">Bank Name</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Account Number</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Asset Nickname</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Routing Number</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Amount</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Username</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Password</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
        </div>
      </div>
    </div>
    <!-- Financial assets -->
    <ng-container *ngIf="!preLoader && (currentFilter === 0 || currentFilter === 1) && this.assetStatus === true">
      <ng-container *ngIf="financialList?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Financial Assets
              </h4>
            </div>
            <div class="col-span-4">
              <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                {{this.assetsData?.['asset']?.['financial'] | currency}}
                <img src="assets/images/about-yourself/edit.svg" *ngIf="!accessVault && !isVaultClaimed"
                     class="w-4 ml-2 sm:ml-4 cursor-pointer float-right"
                     alt="digital vault"
                     (click)="getCommonHelper.routerNavigate(ROUTE.FINANCIAL_ASSETS, this.userType !== 'Consumer' ? this.userRequestID : null)"/>
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4">
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4" *ngFor="let data of financialList; let i = index">
              <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full"/>
              </div>
              <div class="col-span-12 sm:col-span-2">
                <div class="col-span-2">
                  <div class="flex items-center justify-center border-circle mx-auto rounded-full h-12 w-12">
                    <img src="assets/images/accounts-assets/default-bank.png" class="object-contain"
                         alt="digital vault"/>
                  </div>

                </div>
              </div>
              <!-- Non Crypto -->
              <ng-container *ngIf="data.type === 0">
                <div class="col-span-12 sm:col-span-10 sm:ml-4">
                  <h4 class="text-label-grey text-sm text-regular">
                    Asset Type
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.["financial_asset_type"]?.name }}
                  </p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Sole Owner
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.["has_sole_ownership"] ? 'No' : 'Yes' }}
                  </p>


                </div>
                <div class="col-span-12 md:col-span-6" *ngIf="data?.['has_sole_ownership']">
                  <h4 class="text-label-grey text-sm text-regular">
                    Name of Co-owner
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['joint_ownership_name'] }}
                  </p>
                  <p *ngIf="!data?.['joint_ownership_name']" class="text-darkblue text-base text-regular tracking-wide">
                    Not
                    Available</p>

                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Asset Nickname
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['account_name'] }}
                  </p>
                  <p *ngIf="!data?.['account_name']" class="text-darkblue text-base text-regular tracking-wide">
                    Not
                    Available</p>
                </div>
                <div class="col-span-12 ">
                  <h4 class="text-label-grey text-sm text-regular">
                    Bank or Brokerage (Where Asset is Held)
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.["bank_name"] }}
                  </p>
                  <p *ngIf="!data?.['bank_name']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>
                </div>

                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Account Number
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.["account_number"] }}
                  </p>
                  <p *ngIf="!data?.['account_number']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>

                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    {{isRoutingType(data) ? 'Routing Number' : 'Contract Number'}}
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{isRoutingType(data) ? data?.['routing_number'] : data?.['contract_number'] }}
                  </p>
                  <p *ngIf="isRoutingType(data)? !data?.['routing_number']:!data?.['contract_number']"
                     class="text-darkblue text-base text-regular tracking-wide">Not Available</p>

                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">Amount</h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ (data?.['current_balance'] ? data?.['current_balance'] : '') | currency}}
                  </p>
                  <p *ngIf="!data?.['current_balance'] " class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>
                </div>

                <!-- Beneficiary Details -->
                <ng-container *ngIf="data['people']?.length > 0">
                  <div class="col-span-12 md:col-span-6">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular">
                      Primary Beneficiary
                    </h4>
                    <ng-container *ngFor="let item of indexHandler(data['people'], 1);index as i;">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{i + 1}}. {{ item?.['full_name'] }}
                        {{ item?.['beneficiary_trustee_detail']?.['trust_name'] }} ({{item?.['pivot']?.['percentage']}}
                        %)
                      </p>
                    </ng-container>
                    <p *ngIf="indexHandler(data['people'],1)?.length === 0"
                       class="text-darkblue text-base text-regular tracking-wide">Not
                      Available</p>
                  </div>
                  <div class="col-span-12 md:col-span-6">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular">
                      Secondary Beneficiary
                    </h4>
                    <ng-container *ngFor="let item of indexHandler(data['people'], 2);index as i;">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{i + 1}}. {{ item?.['full_name'] }}
                        {{ item?.['beneficiary_trustee_detail']?.['trust_name'] }} ({{item?.['pivot']?.['percentage']}}
                        %)
                      </p>
                    </ng-container>
                    <p *ngIf="indexHandler(data['people'],2)?.length === 0"
                       class="text-darkblue text-base text-regular tracking-wide">Not
                      Available</p>
                  </div>
                </ng-container>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">Website</h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['website'] }}
                  </p>
                  <p *ngIf="!data?.['website'] " class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>
                <div class="col-span-12 md:col-span-6" *ngIf="data?.['import_type'] == 1">
                  <h4 class="text-label-grey text-sm text-regular">Username</h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['user_name'] }}
                  </p>
                  <p *ngIf="!data?.['user_name'] " class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>
                <div class="col-span-12 md:col-span-6" *ngIf="data?.['import_type'] == 1">
                  <h4 class="text-label-grey text-sm text-regular">Password</h4>
                  <p *ngIf="data?.['password']" class="text-lg text-regular text-darkgrey">*******</p>
                  <p *ngIf="!data?.['password']" class="text-darkblue text-base text-regular tracking-wide">Not Available</p>
                </div>

              </ng-container>
              <!-- Crypto -->
              <ng-container *ngIf="data.type === 1">
                <div class="col-span-12 sm:col-span-10 sm:ml-4">
                  <h4 class="text-label-grey text-sm text-regular">
                    Asset Type
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['financial_asset_type'].name }}
                  </p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Currency Name
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['currency_name'] }}
                  </p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">Symbol</h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['currency_symbol'] }}
                  </p>
                  <p *ngIf="!data?.['currency_symbol']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Where it is held ?
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['currency_held_in'] }}
                  </p>
                  <p *ngIf="!data?.['currency_held_in'] " class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">Amount</h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{(data?.['current_balance'] || '') | currency}}
                  </p>
                  <p *ngIf="!data?.['current_balance']  " class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Description
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['currency_description'] }}
                  </p>
                  <p *ngIf="!data?.['currency_description']  "
                     class="text-darkblue text-base text-regular tracking-wide">Not Available</p>

                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="financialList?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Financial assets
            </h4>
          </div>
          <div class="col-span-4">
            <p *ngIf="assetEdit" class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <img src="assets/images/about-yourself/edit.svg" *ngIf="!accessVault && !isVaultClaimed"
                   class="w-4 ml-2 sm:ml-4 cursor-pointer float-right"
                   alt="digital vault"
                   (click)="getCommonHelper.routerNavigate(ROUTE.FINANCIAL_ASSETS, this.userType !== 'Consumer' ? this.userRequestID : null)"/>
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
    </ng-container>
    <!-- Retirement assets -->
    <ng-container *ngIf="(currentFilter === 0 || currentFilter === 2) && this.retirmentAssetStatus === true">
      <ng-container *ngIf="!preLoader && retirementList?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Retirement Assets
              </h4>
            </div>
            <div class="col-span-4">
              <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                {{ this.assetsData?.['asset']?.['retirement'] | currency }}
                <img src="assets/images/about-yourself/edit.svg" *ngIf="!accessVault && !isVaultClaimed"
                     class="w-4 ml-2 sm:ml-4 cursor-pointer float-right"
                     alt="digital vault"
                     (click)="getCommonHelper.routerNavigate(ROUTE.RETIREMENT_ASSETS, this.userType !== 'Consumer' ? this.userRequestID : null)"/>
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4">
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4" *ngFor="let data of retirementList; let i = index">
              <ng-container *ngIf="data?.['retirement_asset'].type === 1">
                <div class="col-span-12" *ngIf="i">
                  <hr class="divider w-full"/>
                </div>
                <div class="col-span-12 sm:col-span-2">
                  <div class="col-span-2">
                    <div class="flex items-center justify-center border-circle mx-auto rounded-full h-12 w-12">
                      <img src="assets/images/accounts-assets/default-bank.png" class="object-contain"
                           alt="digital vault"/>
                    </div>
                  </div>
                </div>
                <div class="col-span-12 sm:col-span-10 sm:ml-4">
                  <h4 class="text-label-grey text-sm text-regular">
                    Asset Type
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['retirement_asset']?.name }}
                  </p>

                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Asset Nickname
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['asset_name'] }}
                  </p>
                  <p *ngIf="!data?.['asset_name']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Account Name
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['account_name'] }}
                  </p>
                  <p *ngIf="!data?.['account_name'] " class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Account Number
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['account_number'] }}
                  </p>
                  <p *ngIf="!data?.['account_number']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>

                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Market Value
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{(data?.['market_value'] ? data?.['market_value'] : '') | currency}}
                  </p>
                  <p *ngIf="!data?.['market_value'] " class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>
                <div class="col-span-12 md:col-span-6" *ngIf="data?.['current_balance']">
                  <h4 class="text-label-grey text-sm text-regular">
                    Current Balance
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{data?.['current_balance'] | currency}}
                  </p>

                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Current Contribution
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{data?.['current_contributions'] ? 'Yes' : 'No' }}
                  </p>


                </div>
                <div class="col-span-12 md:col-span-6" *ngIf="data?.['current_contributions'] ">
                  <h4 class="text-label-grey text-sm text-regular">
                    Frequency
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.frequency }}
                  </p>
                  <p *ngIf="!data?.frequency" class="text-darkblue text-base text-regular tracking-wide">Not Available
                  </p>
                </div>
                <div class="col-span-12 md:col-span-6" *ngIf="data?.['contribution_amount'] ">
                  <h4 class="text-label-grey text-sm text-regular">
                    Contribution Amount
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.contribution_amount | currency }}
                  </p>
                  <p *ngIf="!data?.contribution_amount" class="text-darkblue text-base text-regular tracking-wide">Not Available
                  </p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Note
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['note'] }}
                  </p>
                  <p *ngIf="!data?.['note']" class="text-darkblue text-base text-regular tracking-wide">Not Available
                  </p>
                </div>
                <!-- Beneficiary Details -->
                <ng-container *ngIf="data['people']?.length > 0">
                  <div class="col-span-12 md:col-span-6">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular">
                      Primary Beneficiary
                    </h4>
                    <ng-container *ngFor="let item of indexHandler(data['people'], 1);index as i;">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{i + 1}}. {{ item?.['full_name'] }}
                        {{ item?.['beneficiary_trustee_detail']?.['trust_name'] }} ({{item?.['pivot']?.['percentage']}}
                        %)
                      </p>
                    </ng-container>
                    <p *ngIf="indexHandler(data['people'],1)?.length === 0"
                       class="text-darkblue text-base text-regular tracking-wide">Not
                      Available</p>
                  </div>
                  <div class="col-span-12 md:col-span-6">
                    <h4 class="text-label-grey text-sm tracking-wide text-regular">
                      Secondary Beneficiary
                    </h4>
                    <ng-container *ngFor="let item of indexHandler(data['people'], 2);index as i;">
                      <p class="text-darkgrey text-base text-regular tracking-wide">
                        {{i + 1}}. {{ item?.['full_name'] }}
                        {{ item?.['beneficiary_trustee_detail']?.['trust_name'] }} ({{item?.['pivot']?.['percentage']}}
                        %)
                      </p>
                    </ng-container>
                    <p *ngIf="indexHandler(data['people'],2)?.length === 0"
                       class="text-darkblue text-base text-regular tracking-wide">Not
                      Available</p>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="data?.['retirement_asset'].type === 2">
                <div class="col-span-12" *ngIf="i">
                  <hr class="divider w-full"/>
                </div>
                <div class="col-span-12 sm:col-span-2">
                  <div class="col-span-2">
                    <div class="flex items-center justify-center border-circle mx-auto rounded-full h-12 w-12">
                      <img src="assets/images/accounts-assets/default-bank.png" class="object-contain"
                           alt="digital vault"/>
                    </div>
                  </div>
                </div>
                <div class="col-span-12 sm:col-span-10 sm:ml-4">
                  <h4 class="text-label-grey text-sm text-regular">
                    Asset Type
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['retirement_asset']?.name }}
                  </p>
                  <p *ngIf="!data?.['retirement_asset']?.name"
                     class="text-darkblue text-base text-regular tracking-wide">Not Available</p>

                </div>           
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Asset Nickname
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['asset_name'] }}
                  </p>
                  <p *ngIf="!data?.['asset_name']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Account Name
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['account_name'] }}
                  </p>
                  <p *ngIf="!data?.['account_name']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Account Number
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['account_number'] }}
                  </p>
                  <p *ngIf="!data?.['account_number']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>

                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Current Balance
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{(data?.['current_balance'] ? data?.['current_balance'] : '') | currency}}
                  </p>
                  <p *ngIf="!data?.['current_balance']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>

                <div class="col-span-12 md:col-span-6" *ngIf="data?.['import_type'] == 1">
                  <h4 class="text-label-grey text-sm text-regular">Username</h4>
                  <p  *ngIf="data?.['user_name'] " class="text-lg text-regular text-darkgrey">
                    {{ data?.['user_name'] }}
                  </p>
                  <p *ngIf="!data?.['user_name'] " class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>
                <div class="col-span-12 md:col-span-6" *ngIf="data?.['import_type'] == 1">
                  <h4 class="text-label-grey text-sm text-regular">Password</h4>
                  <p *ngIf="data?.['password']" class="text-lg text-regular text-darkgrey">*******</p>
                  <p *ngIf="!data?.['password']" class="text-darkblue text-base text-regular tracking-wide">Not Available</p>
                </div>
                <div class="col-span-12 md:col-span-6">
                  <h4 class="text-label-grey text-sm text-regular">
                    Website
                  </h4>
                  <p class="text-lg text-regular text-darkgrey">
                    {{ data?.['website'] }}
                  </p>
                  <p *ngIf="!data?.['website']" class="text-darkblue text-base text-regular tracking-wide">Not
                    Available</p>

                </div>
                  <!-- Beneficiary Details -->
                  <ng-container *ngIf="data['people']?.length > 0">
                    <div class="col-span-12 md:col-span-6">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">
                        Primary Beneficiary
                      </h4>
                      <ng-container *ngFor="let item of indexHandler(data['people'], 1);index as i;">
                        <p class="text-darkgrey text-base text-regular tracking-wide">
                          {{i + 1}}. {{ item?.['full_name'] }}
                          {{ item?.['beneficiary_trustee_detail']?.['trust_name'] }} ({{item?.['pivot']?.['percentage']}}
                          %)
                        </p>
                      </ng-container>
                      <p *ngIf="indexHandler(data['people'],1)?.length === 0"
                         class="text-darkblue text-base text-regular tracking-wide">Not
                        Available</p>
                    </div>
                    <div class="col-span-12 md:col-span-6">
                      <h4 class="text-label-grey text-sm tracking-wide text-regular">
                        Secondary Beneficiary
                      </h4>
                      <ng-container *ngFor="let item of indexHandler(data['people'], 2);index as i;">
                        <p class="text-darkgrey text-base text-regular tracking-wide">
                          {{i + 1}}. {{ item?.['full_name'] }}
                          {{ item?.['beneficiary_trustee_detail']?.['trust_name'] }} ({{item?.['pivot']?.['percentage']}}
                          %)
                        </p>
                      </ng-container>
                      <p *ngIf="indexHandler(data['people'],2)?.length === 0"
                         class="text-darkblue text-base text-regular tracking-wide">Not
                        Available</p>
                    </div>
                  </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!preLoader && retirementList?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Retirement Assets
            </h4>
          </div>
          <div class="col-span-4">
            <p *ngIf="assetEdit" class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <img src="assets/images/about-yourself/edit.svg" *ngIf="!accessVault && !isVaultClaimed"
                   class="w-4 ml-2 sm:ml-4 cursor-pointer float-right"
                   alt="digital vault"
                   (click)="getCommonHelper.routerNavigate(ROUTE.RETIREMENT_ASSETS, this.userType !== 'Consumer' ? this.userRequestID : null)"/>
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
    </ng-container>

    <div class="col-span-12" *ngIf="currentFilter === 0">
      <h4 class="text-regular text-center text-2xl text-darkblue font-semibold tracking-wide">
        Liabilities
      </h4>
      <hr class="divider w-full"/>
    </div>

    <!-- Credit card -->
    <ng-container *ngIf="(currentFilter === 0 || currentFilter === 3)">
      <ng-container *ngIf="!preLoader && creditCardList?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Credit Cards
              </h4>
            </div>
            <div class="col-span-4">
              <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                {{this.assetsData?.['liability']?.['credit_card'] | currency }}
                <img src="assets/images/about-yourself/edit.svg" *ngIf="!accessVault && !isVaultClaimed"
                     class="w-4 ml-2 sm:ml-4 cursor-pointer float-right"
                     alt="digital vault"
                     (click)="getCommonHelper.routerNavigate(ROUTE.LIABILITIES, this.userType !== 'Consumer' ? this.userRequestID : null)"/>
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4">
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4" *ngFor="let data of creditCardList; let i = index">
              <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full"/>
              </div>
              <div class="col-span-12 sm:col-span-2">
                <div class="col-span-2">
                  <div class="flex items-center justify-center border-circle mx-auto rounded-full h-12 w-12">
                    <img src="assets/images/accounts-assets/default-bank.png" class="object-contain"
                         alt="digital vault"/>
                  </div>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-10 sm:ml-4">
                <h4 class="text-label-grey text-sm text-regular">Card Issuer</h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ data?.['card_issuer'] }}
                </p>
                <p *ngIf="!data?.['card_issuer']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Card Name</h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ data?.['card_name'] }}
                </p>
                <p *ngIf="!data?.['card_name']" class="text-darkblue text-base text-regular tracking-wide">Not Available
                </p>

              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">
                  Type of Card
                </h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ data?.['card_type'].type }}
                </p>
                <p *ngIf="!data?.['card_type'].type" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>

              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">
                  Amount
                </h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ (data?.['amount'] ? data?.['amount'] : '') | currency }}
                </p>
                <p *ngIf="!data?.['amount']" class="text-darkblue text-base text-regular tracking-wide">Not Available
                </p>

              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">
                  Card Number
                </h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{data?.['card_number']}}
                </p>
                <p *ngIf="!data?.['card_number']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>

              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">
                  Card Expiry
                </h4>
                <p class="text-lg text-regular text-darkgrey" *ngIf="data?.['exp_month'] ||data?.['exp_year'] ">
                  {{ data?.['exp_month']?.length == 2 ? data?.['exp_month'] : '0' + data?.['exp_month']}}
                  {{data?.['exp_year'] }}
                </p>
                <p *ngIf="!data?.['exp_month'] && !data?.['exp_year'] "
                   class="text-darkblue text-base text-regular tracking-wide">Not Available</p>

              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Website</h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ data?.['website'] }}
                </p>
                <p *ngIf="!data?.['website'] " class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>

              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['import_type'] == 1">
                <h4 class="text-label-grey text-sm text-regular">Username</h4>
                <p  *ngIf="data?.['user_name'] " class="text-lg text-regular text-darkgrey">
                  {{ data?.['user_name'] }}
                </p>
                <p *ngIf="!data?.['user_name'] " class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>

              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['import_type'] == 1">
                <h4 class="text-label-grey text-sm text-regular">Password</h4>
                <p *ngIf="data?.['password']" class="text-lg text-regular text-darkgrey">*******</p>
                <p *ngIf="!data?.['password'] " class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>

            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!preLoader && creditCardList?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Credit Cards
            </h4>
          </div>
          <div class="col-span-4">
            <p *ngIf="liabilityEdit" class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <img src="assets/images/about-yourself/edit.svg" *ngIf="!accessVault && !isVaultClaimed"
                   class="w-4 ml-2 sm:ml-4 cursor-pointer float-right"
                   alt="digital vault"
                   (click)="getCommonHelper.routerNavigate(ROUTE.LIABILITIES, this.userType !== 'Consumer' ? this.userRequestID : null)"/>
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
    </ng-container>

    <!-- Loan details ( Other liabilities ) -->
    <ng-container *ngIf="(currentFilter === 0 || currentFilter === 3)">
      <ng-container *ngIf="!preLoader && liabilityList?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Other Loans
              </h4>
            </div>
            <div class="col-span-4">
              <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                {{(this.assetsData?.['liability']?.total - this.assetsData?.['liability']?.['credit_card']) | currency}}
                <img src="assets/images/about-yourself/edit.svg" *ngIf="!accessVault && !isVaultClaimed"
                     class="w-4 ml-2 sm:ml-4 cursor-pointer float-right"
                     alt="digital vault"
                     (click)="getCommonHelper.routerNavigate(ROUTE.LIABILITIES, this.userType !== 'Consumer' ? this.userRequestID : null)"/>
              </p>
            </div>
          </div>
          <div class="bg-card-overview border-card-grey rounded mt-4">
            <div class="grid grid-cols-12 p-6 gap-5 row-gap-4" *ngFor="let data of liabilityList; let i = index">
              <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full"/>
              </div>
              <div class="col-span-12 sm:col-span-2">
                <div class="col-span-2">
                  <div class="flex items-center justify-center border-circle mx-auto rounded-full h-12 w-12">
                    <img src="assets/images/accounts-assets/default-bank.png" class="object-contain"
                         alt="digital vault"/>
                  </div>
                </div>
              </div>
              <!-- Need to Do .Backend response needs to be changed.liability_type should come inside user_liability_loan in this.liabilityList -->
              <!-- <div class="col-span-12 sm:col-span-10 sm:ml-4">
                <h4 class="text-label-grey text-sm text-regular">Liability Type</h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ data?.['liability_type']?.name }}
                </p>
              </div> -->
              <div class="col-span-12 sm:col-span-10 sm:ml-4">
                <h4 class="text-label-grey text-sm text-regular">Financial Institution</h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ data?.['financial_institution'] }}
                </p>
              </div>


              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">
                  Account Number
                </h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ data?.['account_number'] }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Loan Name</h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ data?.['loan_name'] }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Loan Amount</h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ data?.['loan_amount'] | currency }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Notes</h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ data?.['notes'] }}
                </p>
                <p *ngIf="!data?.['notes'] " class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">Website</h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ data?.['website'] }}
                </p>
                <p *ngIf="!data?.['website'] " class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['import_type']===1">
                <h4 class="text-label-grey text-sm text-regular">Username</h4>
                <p  *ngIf="data?.['user_name'] " class="text-lg text-regular text-darkgrey">
                  {{ data?.['user_name'] }}
                </p>
                <p *ngIf="!data?.['user_name'] " class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['import_type']===1">
                <h4 class="text-label-grey text-sm text-regular">Password</h4>
                <p *ngIf="data?.['password']" class="text-lg text-regular text-darkgrey">*******</p>
                <p *ngIf="!data?.['password'] " class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!preLoader && liabilityList?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Other Loans
            </h4>
          </div>
          <div class="col-span-4">
            <p *ngIf="liabilityEdit" class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
              <img src="assets/images/about-yourself/edit.svg" *ngIf="!accessVault && !isVaultClaimed"
                   class="w-4 ml-2 sm:ml-4 cursor-pointer float-right"
                   alt="digital vault"
                   (click)="getCommonHelper.routerNavigate(ROUTE.LIABILITIES, this.userType !== 'Consumer' ? this.userRequestID : null)"/>
            </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="preLoader">
    <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-8">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Credit Cards
          </h4>
        </div>
        <div class="col-span-4">
          <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </p>
        </div>
      </div>
      <div class="bg-card-overview border-card-grey rounded mt-4">
        <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
          <div class="col-span-12 sm:col-span-1">
            <app-shimmer-loading [loading]="preLoader" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 sm:col-span-11 sm:ml-6">
            <h4 class="text-label-grey text-sm text-regular">Bank Name</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Card Name</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Type Of card</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Card Number</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">
              Card Expiry
            </h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-8">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Other Loans
          </h4>
        </div>
        <div class="col-span-4">
          <p class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </p>
        </div>
      </div>
      <div class="bg-card-overview border-card-grey rounded mt-4">
        <div class="grid grid-cols-12 p-6 gap-5 row-gap-4">
          <div class="col-span-12 sm:col-span-1">
            <app-shimmer-loading [loading]="preLoader" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 sm:col-span-11 sm:ml-6">
            <h4 class="text-label-grey text-sm text-regular">Bank Name</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">
              Account Number
            </h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm text-regular">Loan Name</h4>
            <app-shimmer-loading [loading]="preLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
