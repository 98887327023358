<div class="lg:grid grid-cols-12 background-color-grey">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 my-4">
              <div class="col-span-12 sm:col-span-2">
                  <img src="assets/images/executors/executors.svg" class="w-20" alt="digital vault">
              </div>

              <div class="col-span-12 sm:col-span-10">
                  <h2 class="text-darkblue text-md sm:text-2xl break-words uppercase text-semibold">
                    Executor / Digital Vault Custodian Summary
                  </h2>
                  <p class="text-sm text-grey tracking-wide text-regular">
                    This information is critical and a key to verifying their identity. Please double-check your input
                    prior to moving on.
                  </p>
              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-full'"></app-card-progress-bar>
              <!-- END: Progress Bar -->

              <div class="p-6">
                <div class="flex flex-row justify-between" *ngIf="executorData?.length>0">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    Your Executor
                  </h4>

                </div>

                <!-- executor listing component -->
                <app-list-people [lpSettings]="lpSettings" [userPeopleData]="executorData">
                </app-list-people>


                <div class="flex flex-row justify-between" [ngClass]="executorData?.length>0 ? 'mt-4 mb-2':''"
                  *ngIf="custodianData?.length>0">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    Your Digital Vault Custodian
                  </h4>
                </div>

                <!-- Custodian Listing -->
                <app-list-people [lpSettings]="lpSettings" [userPeopleData]="custodianData">
                </app-list-people>

                <div class="flex flex-row justify-between" *ngIf="custodianData?.length==0 && executorData?.length==0">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    No data to display
                  </h4>
                </div>

                <div class="flex flex-col-reverse sm:flex-row justify-between items-center w-full gap-4 mt-8">
                  <button (click)="click('Back')"
                    class="cursor-pointer text-sm mt-2 sm:mt-0 text-back text-regular font-semibold text-sm tracking-wide flex justify-between">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </button>
                  <div class="flex flex-col-reverse sm:flex-row gap-4 items-center justify-end w-full">
                    <button (click)="click('ReturnToDashboard')" *ngIf="this.userType !== 'Custodian'"
                      class="flex items-center justify-center border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 focus:outline-none text-regular text-white px-6">
                      Return to Dashboard
                    </button>
                    <button (click)="click('Next')" [disabled]="submitLoader || preDefinedLoader" *ngIf="paymentStatus !== paymentStatusData.pending"
                      class="flex items-center justify-center hover:underline-text cursor-pointer button button--lg w-full sm:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-6 text-sm">
                      <div class="loading-red mr-2" *ngIf="submitLoader || preDefinedLoader"></div>
                      Next Section
                    </button>
                  </div>
                </div>
              </div>
              <!-- END: Leftside Card -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class="col-span-12 md:col-span-6 lg:grid justify-center">
    <about-yourself-right-content [formInputValue]="{
      beneficiary: this.beneficiaryArray,
      executor: this.executorArray}" [buttonStatus]="this.executorArray?.concat(this.beneficiaryArray)?.length"
      [otherValues]="sectionSaveExitOptions">
    </about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->
</div>