<app-common-modal id="add-valuable-modal" class="app-common-modal" heightClass="min-h-75">
  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">
    <form [formGroup]="valuableForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Valuables Details
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">
            A thing that is of great worth, especially a small item of personal property.
          </p>
          <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="!canEdit && !isConsumer">
            You do not have permission to
            {{isNewAsset ? 'add information for this client.' : 'edit this client\'s information.'}}
          </p>
        </div>
        <div class="col-span-12 relative">
          <label for="valuable-type" class="text-label-grey text-sm text-regular">Type of Valuables*</label>
          <app-select-with-search [options]="valuableTypes" [placeholderValue]="'Select valuable type'"
            (optionChangeEmitter)="optionChangeEmitterValuableType($event)" id="valuable-type"
            [disabled]="!canEdit || !canAccessForm"
            [defaultSelected]="valuableForm?.value?.['valuable_type_id']"></app-select-with-search>
        </div>
        <div *ngIf="useOther" class="col-span-12">
          <label for="type_other" class="text-label-grey text-sm text-regular">Define Other</label>
          <div class="relative flex items-center">
            <input [attr.disabled]="!canEdit || undefined" type="text" id="type_other"
              class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
              name="valuable_type_other" formControlName="valuable_type_other" placeholder="Define Other"
              [ngClass]="formGet.valuable_type_other.errors && formGet.valuable_type_other.touched?'form-input-error':'form-input-focus'">
          </div>
          <p *ngFor="let error of getErrors('valuable_type_other')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12">
          <label for="price" class="text-label-grey text-sm text-regular">Price of the Valuable*</label>
          <div class="relative flex items-center">
            <span class="absolute ml-4 outline-none">{{undefined | getCurrencySymbol}}</span>
            <input [attr.disabled]="!canEdit || undefined" type="text" id="price" appCurrencyInput
              class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 price-input"
              name="price" formControlName="price" placeholder="e.g 1000.00"
              (focusout)="updatePriceValue(formGet.price.value)"
              [ngClass]="formGet.price.errors && formGet.price.touched?'form-input-error':'form-input-focus'">
          </div>
          <p *ngFor="let error of getErrors('price')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
        <div class="col-span-12 ">
          <h4 class=" text-regular text-lg text-darkblue font-semibold tracking-wide ">
            Upload Certificates of Authenticity, Appraisals & Photos
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">
            <span class="font-sans font-bold">Accepted File Formats</span> — jpeg, jpg, png, doc, docx, pdf
          </p>
          <p class="text-grey text-regular pt-1 text-sm">
            <span class="font-sans font-bold">Maximum File Size</span> — 50 MB.
          </p>
        </div>
        <div class="col-span-12">
          <app-file-listing
            [fileArray]="valueFilesArray"
            [permissions]="permissions"
            [isLoading]="preDefinedLoader"
            [removePrefix]="true"
            [isDisabled]="submitLoader || !canEdit"
            [fileUploadSettings]="fileUploadSettings"
            (fileListingEmitter)="fileListingListener($event)"
            subDirectory="value_image">
          </app-file-listing>
        </div>
        <div class="col-span-12">
          <label for="valuable-summary" class="text-label-grey text-sm text-regular">Summary of the Valuable*</label>
          <textarea rows="4" cols="50" maxlength="500" [attr.disabled]="!canEdit || undefined"
            class="input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
            name="summary" formControlName="summary" id="valuable-summary"
            placeholder="e.g Painting purchased in Paris on a vacation celebrating my 50th birthday. See appraisal and photo attached."
            [ngClass]="formGet.summary.errors && formGet.summary.touched?'form-input-error':'form-input-focus'">
          </textarea>
          <p *ngFor="let error of getErrors('summary')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{error}}
          </p>
        </div>
      </div>
      <div class="flex flex-col-reverse sm:flex-row justify-between items-center w-full gap-4 mt-8 ">
        <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewAsset" [buttonText]="'Remove Valuable'" [valid]="!submitLoader && canEdit"
          [loader]="submitLoader" [buttonType]="'button'" [outlineOnly]="true" (click)="deleteValuable()">
        </app-button-loader>
        <app-button-loader class="w-full sm:w-auto" [buttonText]="!isNewAsset?'Update valuable':'Add valuable'"
          [valid]="((isNewAsset && permissions?.['add']) || (!isNewAsset && permissions?.['edit'])) && !submitLoader && canEdit"
          [loader]="submitLoader" [buttonType]="'submit'">
        </app-button-loader>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>
