/* eslint-disable camelcase */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheckCircle, faCircleExclamation, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { Observable, forkJoin } from 'rxjs';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ToggleOptionsInterface, UpdatePeopleInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { DVC_DESCRIPTION_TEXT, TOGGLE_OPTIONS_YES_NO } from '../../../../constants/application.const';
import { CPCEmitInterface, CPCSettingsInterface } from '../../../sharedComponent/choose-people-component/choose-people-interfaces.';
import { LPEmitterInterface, LPSettingsInterface, LPUserPeopleInterface } from '../../../sharedComponent/list-people/list-people-interfaces';
import { ListPeopleComponent } from '../../../sharedComponent/list-people/list-people.component';
import { MessageModalDataInterface, MessageModalSettingsInterface } from '../../../sharedComponent/message-modal/message-modal.component';


@Component({
  selector: 'app-manage-custodian',
  templateUrl: './manage-custodian.component.html',
})

/**
 *
 */
export class ManageCustodianComponent implements OnInit {
  private userPeopleDataRaw: Array<Object> = [];
  public readonly DVC_DESCRIPTION_TEXT = DVC_DESCRIPTION_TEXT;
  public readonly faCheckCircle = faCheckCircle;
  public readonly faCircleXmark = faCircleXmark;
  public readonly faCircleExclamation = faCircleExclamation;
  public readonly toggleOptions: Array<ToggleOptionsInterface> = TOGGLE_OPTIONS_YES_NO;
  public readonly sectionSaveExitOptions = { section: 2, type: 3 };
  // ChoosePersonComponent settings.
  public cpcSettings: CPCSettingsInterface = {
    text: {
      header: 'Select a Digital Vault Custodian (DVC)',
      sub_header:
        `Select a Digital Vault Custodian who will be responsible for reporting your passing to
         LegacyNOW. The Digital Vault Custodian is a trusted individual you appoint to inform LegacyNOW of your
         passing. This notification initiates the process of informing your Executor(s).`,
      add_new: 'Add a New Custodian',
      choose_people: 'Set as Custodian',
      checkbox: {
        text: 'Assign as DVC:',
      },
    },
  };
  public userPeopleData: Array<LPUserPeopleInterface>;
  public chooseCustodiansData: Array<LPUserPeopleInterface>;
  public lpSettings: LPSettingsInterface = {
    button: {
      show: true,
      text: 'Manage Access',
      useSpinner: true,
      tooltip: 'Select and manage access to different Vault sections for your authorized individuals.',
    },
    delete: {
      show: true,
      tooltip: 'Remove as Digital Vault Custodian',
      useIcon: 'faTrash',
    },
    //edit: { show: true, tooltip: 'Edit' },
  };
  public messageData: MessageModalDataInterface;
  public messageSettings:MessageModalSettingsInterface;
  public requestId: string;
  public userType: string;
  public toggleModalAdd: boolean;
  public submitLoader: boolean;
  public loadingConsent: boolean;
  public accessVaultToCustodian: number = 0;
  public accessToInformExecutor: number = 0;
  public accessToClaimVault: number = 0;
  public editData: Object;
  public editACLData: Object;
  public showAddButton: boolean;
  public isAlternativeCustodian: boolean;
  public hasNamedExecutor: boolean;
  public vaultVideo: string;
  public currentUrl: string;
  public isPartnerDeceased: boolean;
  public userID: string;

  /**
   *
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
    private peopleService: PeopleService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private slugInterceptorService: SlugInterceptorService,
    private personalDetailsService: PersonalDetailsService,
  ) {
  }

  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.currentUrl = window.location.pathname;
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.userID = this.localStorageService.getUserData().user.id;
    this.getConsents();
    this.getUserPeople();
  }

  /**
   * Persist changes from previous state of {@link userPeopleDataRaw} using {@link oldRawData}
   * @param{Array<Object>}oldRawData
   *
   */
  private persistState(oldRawData: Array<Object>) {
    // Preserve User's add/remove of custodians.
    if (oldRawData) {
      this.userPeopleDataRaw.forEach((e) => {
        const old_custodian = oldRawData.find((id) => id['id'] == e['id'])?.['custodian'];
        e['custodian'] = old_custodian ?? e['custodian'];
      });
    }
  }

  /**
   * Get User People from endpoint, call {@link processUserPeopleData}.
   * @private
   */
  private getUserPeople() {
    this.lpSettings.isLoading = true;
    this.personalDetailsService.getUserPeople(this.userType !== 'Consumer' ? this.requestId : undefined).subscribe({
      next: (r) => {
        if (r.status) {
          const oldRawData = this.userPeopleDataRaw;
          // here 4 indicates user has selected widowed option in marital status
          this.isPartnerDeceased = r.data.marital_status === 4;
          // Filter out trust-beneficiaries and Advisors
          // Removed - UAT #227 | Keep in case this decision is vetoed later.
          // this.userPeopleDataRaw = r.data.user_people.filter((e) => e['beneficiary_type']?.type != 2 && e['advisor'] == 0);
          this.userPeopleDataRaw = r.data.user_people.filter((e) => e['beneficiary_type']?.type != 2);
          this.persistState(oldRawData);
          this.processUserPeopleData();
        }
        this.lpSettings.isLoading = false;
      },
      error: (e) => {
        this.commonHelper.httpResponseHandler(e?.error);
        this.lpSettings.isLoading = false;
      },
    });
  }

  /**
   * Process {@link this.userPeopleDataRaw userPeopleDataRaw} to populate {@link this.userPeopleData userPeopleData}
   * with data to display.
   * @private
   */
  private processUserPeopleData() {
    this.userPeopleData = [];
    this.chooseCustodiansData = [];
    // Process items
    this.userPeopleDataRaw.forEach((e) => {
      const person = ListPeopleComponent.getLPUserPeople(e)[0];
      // Removes Deceased Partner or Removes Professional User or Removes PSP has choosen Company
      if (this.isPartnerDeceased && e['relation'] === 1 || (e['advisor']==1 && e?.['professional']?.['professional_id']===null) || (e['advisor']==1 && !e?.['last_name'])) {
        return;
      }

      if (e['advisor'] == 1) {
        person.edit = { hide: true };
      }

      if (e['custodian'] == 1) {
        // user_people to display as custodian(s).
        this.userPeopleData.push(person);
      } else if (e['executor'] != 1) {
        // Determine Eligibility
        this.commonHelper.processPersonForExecutorCustodianEligibility(e, person);

        // user_people who are not custodian or executor, to choose from a list when selecting a new custodian(s).
        // Executors don't get shown. User has already decided not to inform them, so they can not be a custodian.
        this.chooseCustodiansData.push(person);
      }
    });
    this.setDefaultDVCPermission();
    this.updateUI();
    this.updateInitialPermission();
  }

  /**
   * Get personal details
   */
  private getConsents(): void {
    this.loadingConsent = true;
    // get people list
    this.personalDetailsService.getPersonalDetails(this.userType !== 'Consumer' ? this.requestId : undefined).subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          this.accessVaultToCustodian = response.data?.inform_exec_permission;
          this.accessToInformExecutor = response.data?.access_to_executor;
          this.accessToClaimVault = response.data?.access_to_custodian;
          this.loadingConsent = false;
          this.updateUI();
        }
      },
      error: (exception: any) => {
        this.commonHelper.httpResponseHandler(exception?.error);
        this.loadingConsent = false;
      },
    });
  }

  /**
   * toggle add beneficiary modal
   */
  public toggleModal(): void {
    this.modalService.open('choose-people-modal');
    this.toggleModalAdd = !this.toggleModalAdd;
  }

  /**
   * Button click handler
   * @param{string} command
   */
  public click(command: string) {
    const isPro = this.userType !== 'Consumer';
    switch (command) {
      case 'Back':
        const url_back = ['executor-beneficiary', 'manage-executors'];
        if (isPro) {
          url_back.push(this.requestId);
        }
        void this.slugInterceptorService.navigate(url_back);
        break;
      case 'ReturnToDashboard':
        const return_url = isPro ? ['overview', 'finance-overview'] : ['dashboard'];
        void this.slugInterceptorService.navigate(return_url);
        break;
      case 'Next':
        this.save();
        break;
    }
    // payment Notification
    if (this.userType === 'Consumer') {
      this.localStorageService.storeData('payment_alert_called', 0); // need alert message in this section, so make 0 to have another alert
      this.commonHelper.checkPaymentStatus(); // check vault creation fee paid or not, payment instruction modal will be called once after login
    }
  }

  /**
   * Toggles access vault to executor
   * @param {ToggleOptionsInterface}toggleValue
   */
  public toggleAccessVaultToCustodian(toggleValue: ToggleOptionsInterface): void {
    this.accessVaultToCustodian = toggleValue.value;
    this.updateUI();
    this.updateInitialPermission();
  }

  /**
   *
   * @param{ToggleOptionsInterface} toggleValue
   */
  public toggleInformExecutor(toggleValue: ToggleOptionsInterface): void {
    this.accessToInformExecutor = toggleValue.value;
    // If accessToInformExecutor is true, then accessToClaimVault is false implicitly.
    if (this.accessToInformExecutor == 1) {
      this.accessToClaimVault = 0;
    } else {
      this.accessToClaimVault = 1;
    }
    if (!this.accessToInformExecutor) {
      this.messageData = {
        title: 'Important Message:',
        message: [
          {
            content:
              'You have chosen not to allow anyone to claim your LegacyNOW Digital Vault; consequently, your information cannot be shared by anyone other than you. If you decide in the future that you want to designate an Executor or Digital Vault Custodian to access this information after your passing, please update this section.',
            class: 'text-xl',
          },
          { content: 'Would you like to reconsider your choice?', class: 'text-lg py-2' },
        ],
        buttons: [
          {
            content: 'Yes',
            emitValue: { data: messageResponses.accessToClaimVault_yes, value: true },
            class: 'bg-darkblue text-white mr-4',
          },
          {
            content: 'No',
            emitValue: { data: messageResponses.accessToClaimVault_no, value: false },
            class: 'text-red custom-noButton-border',
          },
        ],
      };
      this.messageSettings = {showExit:false};
      this.modalService.open('message-modal');
    }
  }

  /**
   *
   * @param{ToggleOptionsInterface} toggleValue
   */
  public toggleAccessToClaimVault(toggleValue: ToggleOptionsInterface): void {
    this.accessToClaimVault = toggleValue.value;
    if (!this.accessToClaimVault) {
      this.messageData = {
        title: { content: 'Warning!', class: 'text-red' },
        message: [
          {
            content:
              'Failure to provide LegacyNOW with the necessary consent for your Digital Vault Custodian to notify the executors/claim the Digital Vault on your behalf could result in your Digital Vault remaining unclaimed after your passing.',
            class: 'text-xl',
          },
          { content: 'Would you like to reconsider your choice?', class: 'text-lg py-2' },
        ],
        buttons: [
          {
            content: 'Yes',
            emitValue: { data: messageResponses.informExecutor_yes, value: true },
            class: 'bg-darkblue text-white mr-4',
          },
          {
            content: 'No',
            emitValue: { data: messageResponses.informExecutor_no, value: false },
            class: 'text-red custom-noButton-border',
          },
        ],
      };
      this.messageSettings = {showExit:false};
      this.modalService.open('message-modal');
    }
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }

  /**
   * change route with redirect url
   * @param{string} url
   * @param{string} redirectUrl
   */
  public changeRouteWithRedirection(url: string, redirectUrl: string, peopleId?: string) {
    void this.slugInterceptorService.navigate([url], { queryParams: { 'redirectTo': redirectUrl, 'peopleId': peopleId } });
  }

  /**
   * Retrieve click events from list-people
   * @param{LPEmitterInterface} event
   */
  public clickListener(event: LPEmitterInterface) {
    const person = this.userPeopleDataRaw.find((e) => e['id'] == event.id);
    if (event.clickedDelete) {
      person['custodian'] = 0;
    } else if (event.clickedButton) {
      if (!this.userPeopleData.find((e) => e.id == event.id).isLoading) {
        this.toggleACLModal(event.id);
      }
    } else if (event.clickedName || event.clickedEdit) {
      this.clickedName(event.id);
    } else {
      // If doing nothing, we don't need to processUserPeopleData again.
      return;
    }
    this.processUserPeopleData();
  }

  /**
   * Update elements related to UI presentation.
   * @private
   */
  private updateUI() {
    // If an executor is not named custodian, this must be alternative custodian.
    this.isAlternativeCustodian = !this.userPeopleDataRaw.find((e) => e['custodian'] == 1 && e['executor'] == 1);

    // If at least one executor has been named
    this.hasNamedExecutor = Boolean(this.userPeopleDataRaw.find((e) => e['executor'] == 1));
    // Need to add a custodian
    this.showAddButton = this.userPeopleData?.length == 0 || this.isAlternativeCustodian;

    // Only show deletion for alternative custodian.
    this.lpSettings.delete.show = this.isAlternativeCustodian;

    // Only show access control button if access is allowed at all
    this.lpSettings.button.show = this.accessVaultToCustodian == 1;

    // If this is an Exec-Custodian, then this permission was already given on previous screen.
    if (!this.isAlternativeCustodian) {
      this.accessVaultToCustodian = 1;
    }
  }

  /**
   *
   * @param{string} id
   * @param{boolean} isLoading
   */
  private setPersonLoading(id: string, isLoading: boolean = true) {
    const person = this.userPeopleData.find((e) => e.id == id);
    person.isLoading = isLoading;
  }

  /**
   *
   * @param{string} person_id
   */
  private toggleACLModal(person_id: string): void {
    this.setPersonLoading(person_id, true);
    this.peopleService.viewAdvisorPermissions(person_id, this.userID).subscribe({
      next: (r) => {
        this.editACLData = { ...r.data, user_id: this.userID };
        this.modalService.open('access-control-modal');
        this.setPersonLoading(person_id, false);
      },
      error: (e) => {
        this.commonHelper.httpResponseHandler(e.error);
        this.setPersonLoading(person_id, false);
      },
    });
  }

  /**
   * Check permission added for the person or update the permission for all page access
   */
  private updateInitialPermission(): void {
    if (this.accessVaultToCustodian) {
      this.userPeopleData.forEach((person) => {
        this.setPersonLoading(person.id, true);
        this.peopleService.viewAdvisorPermissions(person.id, this.userID).subscribe({
          next: (r) => {
            this.setPersonLoading(person.id, false);
            if (!r.data['permission']?.length) {
              this.editACLData = { advisor_id: r.data['advisor_id'], user_id: this.userID, isUpdatePermission: true };
            }
          },
          error: (e) => {
            this.setPersonLoading(person.id, false);
            this.commonHelper.httpResponseHandler(e.error);
          },
        });
      });
    }
  }

  /**
   * Toggle {@link AddExecutorComponent} modal.
   * @param{Object} editValue
   */
  private toggleAddModal(editValue?: Object): void {
    this.modalService.open('add-custodian-modal');
    this.editData = {};
    if (editValue) {
      this.editData = { ...editValue };
    }
  }

  /**
   * Listener for {@link AddExecutorComponent}
   * @param{Object} event
   */
  public addModalListener(event: Object) {
    if (event['id'] || event['listStatus']) {
      // Close 'choose-people-modal' and refresh user people
      this.modalService.close('choose-people-modal');
      this.getUserPeople();
    }
  }

  /**
   * Listener for ListPeopleComponent
   * @param{CPCEmitInterface} event
   */
  public updateListener(event: CPCEmitInterface) {
    if (event.chosen_people) {
      event.chosen_people.forEach((e) => {
        this.userPeopleDataRaw.find((p) => p['id'] == e.id)['custodian'] = e.checkbox?.isChecked ? 1 : 0;
      });
      this.processUserPeopleData();
    }
    if (event.add_new) {
      this.toggleAddModal();
      // While opening the add modal, close the choose-people-modal
      this.modalService.close('choose-people-modal');
    }
    if (event.person_clicked_id) {
      this.clickedName(event.person_clicked_id);
    }
  }

  /**
   * Handles event of editing a user-person
   * TODO currently filters out advisors and family members as their information doesn't fit with the current component. In future, we want to replace the component with a dynamic component which can handle each case.
   * @param{string} id
   * @private
   */
  private clickedName(id: string) {
    const person = this.userPeopleDataRaw.find((e) => e['id'] == id);
    this.messageData = {
      title: 'Edit Required To Continue',
      message: [],
    };
    this.messageSettings = {};
    if (person['relation'] == 1) {
      this.messageData.message = ['DVCs are required to be 18 years of age or older. If the person selected meet this criteria, kindly proceed to About Yourself and update your partner’s details to proceed.'];
      this.messageData.buttons = [{ content: 'Proceed', emitValue: { data: messageResponses.partner_yes, value: true } }];
      this.toggleMessageModal();
    } else if (person['relation'] == 2) {
      this.messageData.message = ['DVCs are required to be 18 years of age or older. If the person selected meet this criteria, kindly proceed to About Yourself and update your child’s details to proceed.'];
      this.messageData.buttons = [{ content: 'Proceed', emitValue: { data: messageResponses.child_yes, value: person?.['id'] } }];
      this.toggleMessageModal();
    } else if (person['advisor'] != 1) {
      this.toggleAddModal(person);
    }
  }

  /**
   * Save Custodian Status. This presumes to save as 'Alternative Custodian', and so will result in executor=0.
   * @private
   */
  private saveAltCustodian(): Observable<any> {
    return new Observable(observer => {

      this.submitLoader = true;

      // Get Custodian
      const custodiansList = this.userPeopleDataRaw.filter((e) => e['custodian'] == 1).map((e) => e['id']);
      const newCustodian = this.localStorageService.getDataByKey('new-custodian');
      const payload: UpdatePeopleInterface = {
        custodians: custodiansList,
        new_custodians: [newCustodian],
      };
      if (this.userType !== 'Consumer') {
        payload['request_id'] = this.requestId;
      }
      // Submit Alternative Custodian
      this.peopleService.updateExecutorCustodianDetails(payload).subscribe({
          next: (response: APIResponseModel) => {
            this.submitLoader = false;
            if (response.status) {
              this.localStorageService.deleteDataByKey('new-custodian');
              this.commonHelper.updateLocalstorageRequestStage(response.data);
            }
          }, error: (exception) => {
            this.submitLoader = false;
            observer.error(exception);
          }, complete: () => observer.complete(),
        },
      );
    });
  }

  /**
   * store consent permissions
   */
  public storeConsent(): Observable<any> {
    return new Observable(observer => {
      if(this.accessToInformExecutor == 1) this.accessToClaimVault = 0;
      const payLoad = {
        // Accessing Vault Permission for Custodian (First Consent)
        inform_exec_permission: this.accessVaultToCustodian,
        // Access to inform executors about digital vault (Second Consent)
        access_to_executor: this.accessToInformExecutor,
        // Access to claim vault (Third Consent)
        access_to_custodian: this.accessToClaimVault,
        roletype: this.userType,
      };
      if (this.userType !== 'Consumer') {
        payLoad['request_id'] = this.requestId;
      }
      this.peopleService.authorizeAdvisor(payLoad).subscribe({
        error: (exception: any) => {
          this.accessVaultToCustodian = 0;
          observer.error(exception);
        },
        complete: () => observer.complete(),
      });
    });
  }

  /**
   * Save selected information.
   * @private
   */
  private save() {
    const save = { consent: this.storeConsent() };
    // Only need to save custodians if this is an alternative-custodian flow.
    if (this.isAlternativeCustodian) {
      save['altCustodian'] = this.saveAltCustodian();
    }
    forkJoin(save).subscribe({
      error: e => this.commonHelper.httpResponseHandler(e?.error),
      complete: () => this.nextPage(),
    });
  }

  /**
   *
   */
  private nextPage() {
    const url_next = ['executor-beneficiary', 'executors-beneficiaries-view'];
    if (this.userType !== 'Consumer') {
      url_next.push(this.requestId);
    }
    void this.slugInterceptorService.navigate(url_next);
  }

  /**
   * Return true if any custodians have been defined yet.
   */
  get hasCustodianSelected(): boolean {
    return this.userPeopleDataRaw.filter((e) => e['custodian'] == 1).length > 0;
  }

  /**
   * Save and Continue is valid if  there are no 'loading' user people.
   */
  get isValidSave(): boolean {
    return this.userPeopleData?.filter((e) => e.isLoading).length == 0;
  }

  /**
   * Listener for Message Modal Events
   * @param{any} event
   */
  public messageModalListener(event: any) {
    const isProfessional = this.userType !== 'Consumer';
    if (event != null) {
      this.modalService.close('message-modal');
      switch (event.data) {
        case messageResponses.informExecutor_yes:
          this.accessToClaimVault = 1;
          break;

        case messageResponses.accessToClaimVault_yes:
          this.accessToInformExecutor = 1;
          break;

        case messageResponses.accessToClaimVault_no:
          this.accessToClaimVault = 1;
          break;
        case messageResponses.partner_yes:
          let partnerPageUrl = 'about-yourself/partner/';
          isProfessional ? (partnerPageUrl = partnerPageUrl + this.requestId) : partnerPageUrl;
          this.changeRouteWithRedirection(partnerPageUrl, this.currentUrl);
          break;
        case messageResponses.child_yes:
          let childPageUrl = 'about-yourself/children/';
          isProfessional ? (childPageUrl = childPageUrl + this.requestId) : childPageUrl;
          this.changeRouteWithRedirection(childPageUrl, this.currentUrl, event?.value);
          break;
      }
    }
  }

  /**
   * Toggle {@link MessageModalComponent} modal.
   */
  private toggleMessageModal(): void {
    this.modalService.open('message-modal');
  }

  /**
   *
   * @param{{}} event
   */
  public aclSaveListener(event: { id: string; next?: any; error?: any; complete?: boolean }) {
    // Set loading if not complete (started saving), and not loading if is complete.
    this.setPersonLoading(event.id, !event.complete);

    // Set not loading if error returns.
    if (event.error) {
      this.setPersonLoading(event.id, false);
    }
  }

  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');
  }

  /**
   * this function helps to change default value of toggle
   */
  public setDefaultDVCPermission() {
    this.hasNamedExecutor = Boolean(this.userPeopleDataRaw.find((e) => e['executor'] == 1));
    const isExecCumCustodian = Boolean(this.userPeopleDataRaw.find((e) => e['executor'] == 1 && e['custodian'] == 1));
    if (this.userPeopleData.length == 0) {
      this.accessVaultToCustodian = 0;
      if (this.hasNamedExecutor) {
        this.accessToInformExecutor = 1;
      } else {
        this.accessToInformExecutor = 0;
      }
      this.accessToClaimVault = 0;
      this.storeConsent();
    } else if (!this.hasNamedExecutor || isExecCumCustodian) {
      this.accessToInformExecutor = 0;
      this.accessToClaimVault = 1;
      this.storeConsent();
    }
  }
}


enum messageResponses {
  accessToClaimVault_yes,
  accessToClaimVault_no,
  informExecutor_yes,
  informExecutor_no,
  partner_yes,
  child_yes,
}
