import { Component } from '@angular/core';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';

@Component({
  selector: 'app-notice-to-vault-holders',
  templateUrl: './notice-to-vault-holders.component.html',
  styleUrls: ['../documents.css']
})
export class NoticeToVaultHoldersComponent {
  /**
   * @constructor
   */
  constructor(
    private slugInterceptorService: SlugInterceptorService,
  ) {
  }
  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, false, true);
  }
}
