import { Component } from '@angular/core';

@Component({
  selector: 'app-consumer-landing-page',
  templateUrl: './consumer-landing-page.component.html'
})
/**
 * consumer landing page
 */
export class ConsumerLandingPageComponent {
}
