<div class="scroll-hidden box-border body-content pb-12 mb-8">
  <!-- Navbar -->
  <app-navbar></app-navbar>

  <!--first Section start-->
  <div class="pt-12">
    <div class="flex flex-wrap lg:mx-8 mx-6 ">
      <div class="w-full">
        <div class="flex items-center">
          <img src="assets/images/about-yourself/back-arrow.svg" alt="digital vault" class="cursor-pointer"
               (click)="changeRoute('dashboard')">
          <h2 class="text-darkblue text-md lg:text-2xl capitalize pl-5 text-semibold">
            Notifications
          </h2>

        </div>
      </div>
    </div>
  </div>
  <!--end-->

  <div class="scroll-hidden box-border body-content">

    <!--first Section start-->
    <div class="mx-8">
      <div class="pt-4 mb-6">
        <div class="border-card bg-white rounded-md p-6 ">
          <div class="mt-5">
            <div>
              <table class="table w-full text-left" aria-describedby="client">
                <thead class="bg-gray-400 text-white text-md text-regular ">
                <tr>
                  <th class="p-4" scope="row">{{notificationMessage}}</th>
                  <th class="p-4 whitespace-normal" scope="row">{{agentTerm}}</th>
                  <th class="p-4" scope="row">Date</th>
                  <th class="p-4" scope="row">Meeting Link</th>
                </tr>
                </thead>
                <ng-container>
                  <tbody *ngFor="let list of notificationList | paginate
                      : {
                          itemsPerPage: tableSize,
                          currentPage: page,
                          totalItems: count
                        };let i = index">
                  <tr class="border-b-2 border-fuchsia-600 p-4 relative">
                    <td class="p-4">
                      <div class="text-grey text-sm lg:text-lg text-regular">
                        <ng-container *ngIf="list['schedule_type_id'];else userRequestNotificationBlock">
                          <div *ngIf="!list['status'];else zoomMeetingLinkBlock">
                            Appointment Request: {{agentTerm}} would like to schedule
                            a {{list?.['schedule_type']?.['name']}}
                          </div>
                          <ng-template #zoomMeetingLinkBlock>
                            <span>You confirmed appointment successfully, scheduled by {{agentTerm}}</span>
                          </ng-template>
                        </ng-container>
                      </div>
                      <ng-template #userRequestNotificationBlock>
                        <span>You have requested an appointment</span>
                      </ng-template>
                    </td>
                    <td class="p-4">
                      <p class="text-blue-900 text-semibold text-sm text-xs md:text-sm lg:text-xl">
                        <strong class="font-bold">{{list?.['professional']?.['first_name']}}&nbsp;</strong>
                      </p>
                    </td>
                    <td class="p-4 whitespace-normal">
                      <p class="text-grey text-sm lg:text-lg text-regular">
                        {{list?.['date_time'] | dateStringtoDateformat}} </p>
                    </td>
                    <td class="p-4">
                      <div *ngIf="list['status'] || !list['schedule_type_id'];else zoomMeetingLinkBlock">
                        <a *ngIf="list?.['meeting_link'] !==null" href="{{list['meeting_link']}}" target="_blank"
                           class="text-green-500 hover:text-blue-700 underline text-md">Join Zoom Meeting</a>
                      </div>
                      <ng-template #zoomMeetingLinkBlock>
                        <div class="space-x-3 flex">
                          <button (click)="declineEvent(list?.id)"
                                  class="button button-sm bg-red-500 p-2 w-auto rounded-xs h-10 focus:outline-none text-regular text-white text-sm">
                            Decline
                          </button>
                          <button (click)="acceptEvent(list?.id)"
                                  class="button button-lg bg-green-400 p-2 w-auto rounded-xs h-10 focus:outline-none text-regular text-white text-sm">
                            Accept
                          </button>
                        </div>
                      </ng-template>

                    </td>
                  </tr>
                  </tbody>
                </ng-container>
              </table>
              <app-shimmer-loading [loading]="preDefinedLoader" [width]="'12rem'">
              </app-shimmer-loading>
              <div class=" flex justify-center mt-5" *ngIf="notificationList?.length === 0 && !preDefinedLoader">
                <h4 class="text-grey text-base lg:text-md text-regular">
                  No data found
                </h4>
              </div>
              <div class="flex justify-center mt-5">
                <pagination-controls previousLabel="" nextLabel="" (pageChange)="onTableDataChange($event)">
                </pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
