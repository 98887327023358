import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ConsumerPaymentMethod } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
/**
 * ConsumerSubscriptionService services
 */
export class ConsumerSubscriptionService {
  private BASE_URL: string;

  /**
   * @constructor
   */
  constructor(private http: HttpClient,
    private localStorage: LocalStorageService
  ) {
    this.BASE_URL = environment.BASE_URL;
  }

  /**
   * Login user
   *
   * @param {Object} cardData
   * @return {Observable<APIResponseModel>}
   */
  public getMySubscriptions(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/get-subscription-details');
  }

  /**
   *
   * @return {Observable<APIResponseModel>}
   * @param{string}requestId
   */
  public getSubscriptionDetailsApi(requestId?: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/get-subscription-details?request_id=${requestId}`);
  }

  public checkPromoCode(promoCode: string, appliesTo: Array<any>) {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/check-promo-code`, { promocode: promoCode, applies_to: appliesTo });
  }

  /**
   * Login user
   *
   * @param {Object} cardData
   * @return {Observable<APIResponseModel>}
   */
  public deleteCardApi(cardData: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/delete-card', cardData);
  }

  /**
   * Login user
   *
   * @param {Object} cardData
   * @return {Observable<APIResponseModel>}
   */
  public makeCardDefaultApi(cardData: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/make-card-default', cardData);
  }

  /**
   * consumer checkout
   *
   * @param {Object} checkoutData
   * @return {Observable<APIResponseModel>}
   */
  public checkoutApi(checkoutData: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/new-checkout', checkoutData);
  }

  /**
   * professional checkout
   *
   * @param {Object} checkoutData
   * @return {Observable<APIResponseModel>}
   */
  public proCheckoutApi(checkoutData: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/professional/professional-checkout', checkoutData);
  }
  /**
   * update subscription plan
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public updateSubscription(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/update-subscription', data);
  }

  /**
   * Checks if `is_payment_complete` is == 1.
   * @see updateSubscription
   */
  hasPaidSubscription(userId?: string): Promise<boolean> {
    return this.getPaymentStatus(userId).pipe(map(r => {
      const paymentMode = this.localStorage.getDataByKey('payment_mode');
      const inTrialPeriod = this.localStorage.getDataByKey('in_trial_period');
      const today: Date = new Date();
      const subscriptionEndsAt: Date = new Date(r.data?.['subscription_ends_at']);
      const isSubscriptionEnded: boolean = today >= subscriptionEndsAt
      return (r.data.is_payment_complete == 1 || paymentMode == ConsumerPaymentMethod.PAID_BY_PROFESSIONAL || inTrialPeriod || (r?.data?.subscription_cancellation_count > 1 && !isSubscriptionEnded))
    })).toPromise();
  }

  /**
   * check the existing user i.e. subscription payment completed for the first time
   * @param userId
   * @returns boolean
   */
  isExistingUser(userId): Promise<boolean> {
    return this.getPaymentStatus(userId).pipe(map(r => {
      return r?.data?.subscription_cancellation_count > 1 || r.data?.['subscription_ends_at']
    })).toPromise();
  }
  /**
   * Get payment status of user account
   * @return payment status
   */
  public getPaymentStatus(userId?: string): Observable<APIResponseModel> {
    const appendQuery = userId ? `?id=${userId}` : ''
    return this.http.get<APIResponseModel>(this.BASE_URL + `/users/payment-status${appendQuery}`);
  }

  /**
   * Sends an HTTP POST request to retrieve the client's payment status.
   * 
   * @param {Object} [data] - Optional parameter containing the request payload.
   * @returns {Observable<APIResponseModel>} - An Observable that emits the server's response.
   */
  public getClientPaymentStatus(data?:Object): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/professional/payment-status`,data);
  }

  /**
   *
   * @return{Observable}
   */
  public applyRefund() {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/refund', null);
  }

  /**
   *
   * @return{Observable}
   */
  public cancelSubscription() {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/cancel-subscription');
  }

  /**
   * Get pricing details
   * @return {Observable<APIResponseModel>}
   */
  public getPricingDetails() {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/get-package/list');
  }

  /**
   * Fetches cancellation reasons
   *
   * @returns {Observable<APIResponseModel>} An observable of the API response.
   */
  public fetchCancellationReasons() {
    return this.http.get<APIResponseModel>(this.BASE_URL + '/cancellation-reason');
  }

  /**
   * Updates Cancellation Reason
   *
   * @returns {Observable<APIResponseModel>} An observable of the API response.
   */
  public updateCancellationReason(data: any) {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/cancellation-reason/store', data);
  }

  /**
   *  get
   * @param{number}page
   * @param{number}length
   * @param{string}sort
   * @param{string}search
   *
   * @return {Observable}
   */
  public getBillingHistory(page?: number, length?: number, sort?: string, search?: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/users-transaction-paginate?page=${page}&length=${length}&sort=${sort}&search=${search}`);
  }

  /**
   * Called by a professional user accessing a client's vault to know if the client has paid subscriptions.
   * @param userId The ID of the client.
   * @param paymentType The payment method used by the client.
   * @returns A Promise resolving to true if the client has paid subscriptions, otherwise false.
   */
  public clientHasPaidSubscription(userId: string, paymentType: ConsumerPaymentMethod): Promise<boolean> {
    if (paymentType === ConsumerPaymentMethod.PAID_BY_PROFESSIONAL) {
      const payload = {user_id: userId}
      return this.getClientPaymentStatus(payload).pipe(map(r => {
        const today: Date = new Date();
        const subscriptionEndsAt: Date = new Date(r.data?.['subscription_ends_at']);
        let isSubscriptionEnded: boolean = today >= subscriptionEndsAt
        return (r.data.is_payment_complete == 1 || paymentType == ConsumerPaymentMethod.PAID_BY_PROFESSIONAL || (r?.data?.subscription_cancellation_count > 1 && !isSubscriptionEnded))
      })).toPromise();
    }
    else {
      return this.hasPaidSubscription(userId)
    }
  }
}
