<div class="section">
    <div class="max-w-screen mx-auto">
        <div class="grid grid-cols-12 gap-4 row-gap-8">
            <div class="col-span-12">
                <h2 class="text-pale-blue font-ave-bold font-bold text-2xl md:text-3xl lg:text-5xl mb-2">
                    LegacyNOW Subscription
                </h2>
                <p class="text-grey font-ave-medium text-lg sm:text-xl lg:text-2xl">Complete your purchase to access
                    LegacyNOW.</p>
            </div>
            <div class="col-span-12">
                <div>
                    <div class="flex items-center justify-between gap-4">
                        <label for="saved-card" class="text-base pb-2 font-ave-semibold text-black">Saved Card</label>
                        <a class="text-pale-blue font-ave-semibold underline cursor-pointer">Edit Payment</a>
                    </div>
                    <input type="text" name="saved-card" id="saved-card"
                        class="input w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                        placeholder="VISA XXXX XXXX XXXX 1234">
                    <!-- <p class="font-ave-medium text-xs md:text-sm mt-2 error-alert">
                        Please enter a valid email address.</p>
                </div> -->
                </div>
            </div>
            <div class="col-span-12">
                <h2 class="text-pale-blue font-ave-bold font-bold text-xl md:text-2xl lg:text-3xl mb-3">
                    LegacyNOW Subscription - <span class="text-black font-ave-bold">$5.99 / month</span>
                </h2>
                <p class="text-black font-ave-medium text-base md:text-lg lg:text-xl"> If the LegacyNOW platform does
                    not meet your needs or expectations within the next 30 days, feel free to cancel
                    it at any time. We will gladly refund your money and verify the deletion of all your personal
                    information.</p>
            
            </div>
            <div class="col-span-12">
                <div class="flex items-center gap-4 my-8 md:my-12">
                    <input id="default-checkbox" type="checkbox" value=""
                        class="w-5 h-5  text-primary border border-gray-300 rounded">
                    <label for="checkbox" class="inline text-base md:text-lg font-ave-semibold text-black">
                        I have read and understand the <a 
                            class="text-pale-blue font-ave-semibold underline cursor-pointer" (click)="startSubscription()">notice</a> and agree.
                    </label>
                </div>
            
            </div>
            <div class="col-span-12">
                <div class="flex flex-col sm:flex-row justify-between gap-4">
                    <button type="submit"
                        class="flex items-center justify-center button button--lg text-sm sm:text-base sm:w-64 bg-primary rounded-xs h-12 focus:outline-none font-ave-semibold text-white px-8 cursor-pointer">
                        < BACK </button>
                            <button type="submit" 
                                class="flex items-center justify-center button button--lg text-sm sm:text-base sm:w-64 bg-pale-blue rounded-xs h-12 focus:outline-none font-ave-semibold text-white px-8 cursor-pointer">
                                START SUBSCRIPTION
                            </button>
                </div>
            
            </div>
    </div>
</div>



<app-common-modal id="start-subscription" class="app-common-modal">
    <div class="relative">
        <svg (click)="closeModal()" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2"
            fill="none" stroke-linecap="round" stroke-linejoin="round"
            class="absolute right-0 top-0 cursor-pointer z-9999">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>

        <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
                <h2 class="text-pale-blue text-xl text-center font-ave-bold w-5/6 mx-auto">
                    IMPORTANT INFORMATION FOR DIGITAL VAULT
                    USERS ACKNOWLEDGMENT
                </h2>
            </div>
            <div class="col-span-12">
                <p class="text-black font-ave-medium text-base md:text-lg lg:text-xl">Your Digital Vault and related services provided by LegacyNOW
                are intended solely to facilitate the collection of a wide range of
                information and other data which will simplify your estate planning
                needs and facilitate the resolution of your estate upon your passing.
                With your prior authorization, your Digital Vault allows your executor
                and/or heirs to readily and easily access all this information in one
                place - easing the administrative and reporting burdens that are a
                necessary task upon your passing. For that reason, it is important
                that the information in your Digital Vault is maintained, modified,
                and updated to have the most accurate information on hand when
                it is most needed.</p>
               
            </div>
            <div class="col-span-12">
                <p class="text-black font-ave-medium text-base md:text-lg lg:text-xl">
                    <span class="text-pale-blue font-ave-bold">
                        PLEASE NOTE
                    </span> , however, that the maintenance, modification or
                    updating of any information deposited into your digital vault
                    regarding your estate (and particularly your heirs, beneficiaries,
                    and their respective inheritances) in no way impacts or substitutes
                    that may be provided in your will or any provision of applicable
                    law. Your Digital Vault is simply a secured holding facility for
                    important information.
                </p>
            </div>
            <div class="col-span-12">
                <p class="text-black font-ave-medium text-base md:text-lg lg:text-xl">THE LEGACYNOW SERVICES IN NO WAY CONSTITUTES LEGAL
                ADVICE OR IMPACTS THE APPLICATION OF LAW TO THE TERMS
                OF YOUR WILL (AS APPLICABLE), THE VALIDITY OF YOUR WILL,
                OR THE RESOLUTION OF YOUR ESTATE. SHOULD YOU DESIRE
                OR INTEND TO EFFECT ANY MODIFICATION, CHANGE OR
                UPDATING OF YOUR ESTATE, YOUR WILL, YOUR SPECIFIC
                BEQUESTS, OR ANY OTHER MATTER CONCERNING YOUR
                ESTATE AND/OR ESTATE PLANNING, YOU MUST CONSULT WITH A
                LEGAL AND/OR FINANCIAL PROFESSIONAL AND TAKE THE
                LEGALLY REQUIRED ACTIONS TO EFFECT YOUR ESTATE AND
                ITS DISTRIBUTION UPON YOUR PASSING. LEGACYNOW IN
                NO WAY ACCEPTS OR ASSUMES ANY RESPONSIBILITY WITH
                RESPECT TO YOUR ESTATE OR ANY INHERITANCE OR DISTRIBUTION
                THEREOF. ALL MATTERS CONCERNING THE DIGITAL VAULT AND
                LEGACYNOW ARE PROVIDED IN YOUR DIGITAL VAULT AGREEMENT.</p>
            </div>
            <div class="col-span-12">
                <p class="text-black font-ave-medium text-base md:text-lg lg:text-xl">I have read and understood the notice above and acknowledge and
                agree that (i) LEGACYNOW does not provide any legal, financial or
                other advice with regard to my assets, estate or otherwise, and (ii)
                LEGACYNOW accepts or assumes no responsibility or liability with
                respect to my estate, inheritance, or distribution thereof. I further
                acknowledge that any modification, change or updating of the
                information in my Digital Vault requires consultation with a legal
                and/or financial professional and may further require other actions
                (such as the modification of a will) to have any impact on the resolution
                of your estate.</p>
            </div>
            <div class="col-span-12 mx-auto mt-12">
                <button type="submit" (click)="closeModal()"
                    class="flex items-center justify-center button button--lg sm:w-64 bg-pale-blue rounded-xs h-12 focus:outline-none font-ave-semibold text-white px-8 cursor-pointer">
                    Accept
                </button>
            </div>
        </div>
    </div>

   

</app-common-modal>