import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { DlbService } from 'src/app/services/dlb.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { UserService } from 'src/app/services/user.service';
import { INFORMATION_TYPE, USER_TYPES, VERIFICATION_APP, VERIFICATION_STATUS } from 'src/constants/application.const';

@Component({
  selector: 'app-id-verify-options-modal',
  templateUrl: './id-verify-options-modal.component.html'
})
export class IdVerifyOptionsModalComponent implements OnInit {
  @Output() toggleModalEmitter = new EventEmitter<boolean>();
  public idVerifyOptionsForm: FormGroup;
  public preDefinedLoader: boolean;
  public submitLoader: boolean;
  public informationType: string;
  public clientRequestId: string;
  public informationTypeOptions: Array<SelectOptionsInterface> = INFORMATION_TYPE.reverse();
  public verificationAppOptions: Array<SelectOptionsInterface> = VERIFICATION_APP.reverse();

  constructor(
    private formBuilder: FormBuilder,
    private modalService: CommonModelService,
    private userService: UserService,
    private commonHelper: CommonHelper,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private localStorageService: LocalStorageService,
    private dlbService: DlbService
  ) { }

  ngOnInit(): void {
    this.clientRequestId = this.localStorageService.getUserData(USER_TYPES.exe)?.user?.id;
    this.idVerifyOptionsForm = this.formBuilder.group({
      information_type: ['', Validators.required],
      link_type: ['', Validators.required]
    })
    this.preDefinedLoader = false;
    this.submitLoader = false;
    this.resetForm();
  }
  /**
   * Closes modal
   */
  public closeModal(): void {
    this.modalService.close('id-verify-options-modal');
  }
  /**
   * submit form
   */
  public onSubmit(): void {
    this.submitLoader = true;
    this.userService.idPalVerifylink(this.idVerifyOptionsForm.value).subscribe({
      next: (response) => {
        const updateIdVerified = VERIFICATION_STATUS.pending; // initiated ID verification Process
        this.localStorageService.storeData('is_id_verified', updateIdVerified);
        this.toggleModalEmitter.emit(true);
        // this.toastrService.success(response.message);
        this.submitLoader = false;
        this.closeModal();
      },
      error: (exception: any) => {
        this.commonHelper.httpResponseHandler(exception?.error);
        this.submitLoader = false;
      }
    })
  }

  /**
   * Resets form
   */
  public resetForm(): void {
    this.idVerifyOptionsForm.patchValue({
      information_type: this.informationTypeOptions[0]?.value,
      link_type: this.verificationAppOptions[0]?.value
    })
  }
  /**
   * Get form
   */
  get formGet() {
    return this.idVerifyOptionsForm.controls;
  }
}
