import { NgModule } from '@angular/core';
import { DropdownDirective } from './dropdown.directive';
import { SsnSplitterDirective } from './ssn-splitter.directive';
import { DatepickerDirective } from './datepicker.directive';
import { CurrencyInputDirective } from './currency-input.directive';
import { SwipeDirective } from './swipe.directive';


@NgModule({
  declarations: [
    DropdownDirective,
    SsnSplitterDirective,
    DatepickerDirective,
    CurrencyInputDirective,
    SwipeDirective,
  ],
  exports: [
    DropdownDirective,
    SsnSplitterDirective,
    DatepickerDirective,
    CurrencyInputDirective,
    SwipeDirective,
  ],
})
/**
 * directives module
 */
export class DirectivesModule {
}
