<div class="scroll-hidden background-color-grey">
  <app-navbar *ngIf="this.userType === 'Consumer'"></app-navbar>
  <app-professional-navbar *ngIf="this.userType !== 'Consumer'"></app-professional-navbar>
  <div class="lg:grid grid-cols-12 px-12 gap-6">
    <div class="col-span-12 sm:p-6 lg:pr-0 mb-2">
      <div class="w-full">
        <h2 class="text-darkblue text-xl lg:text-2xl xl:text-3xl uppercase text-semibold pt-1 flex items-center"><img
          src="assets/images/payment/backarrow.svg" class="mr-3" alt="Backarrow">Payment Details</h2>
        <p class="text-md text-grey py-3 tracking-wide text-regular">Complete your subscription by providing payment
          details</p>
        <p class="border-b-8 w-24 border-red mb-3"></p>
      </div>
    </div>
    <div class="col-span-12 md:col-span-7 px-6">
      <div class="border-card bg-white rounded-md mb-10">
        <div class="px-4 px-6 my-4">
          <div class="grid grid-cols-12 gap-2 items-center">
            <div class="col-span-10">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Saved Cards
              </h4>
            </div>
            <div class="col-span-2 cursor-pointer" (click)="sectionToggle.saved_cards = !sectionToggle.saved_cards">
              <img src="assets/images/dashboard/angle-up-arrow.png" *ngIf="!sectionToggle.saved_cards"
                   class="w-4 float-right"
                   alt="digital vault">
              <img src="assets/images/dashboard/angle-down-arrow.png" *ngIf="sectionToggle.saved_cards"
                   class="w-4 float-right"
                   alt="digital vault">
            </div>
          </div>
          <ng-container *ngIf="!sectionToggle.saved_cards">
            <div class="w-full my-3">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <label class="mr-4 mt-2">
                    <input type="checkbox" class="form-checkbox w-4 h-4">
                  </label>
                  <img src="assets/images/payment/visacard.jpeg" class="w-6 h-6 mr-4" alt="visacard">
                  <p class="text-lg text-black font-normal">1111 1111 1111 1111</p>
                </div>
                <div class="mr-3">
                  <p>10/13</p>
                </div>
              </div>
            </div>
            <div class="w-full my-3">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <label class="mr-4 mt-2">
                    <input type="checkbox" class="form-checkbox w-4 h-4">
                  </label>
                  <img src="assets/images/payment/visacard.jpeg" class="w-6 h-6 mr-4" alt="visacard">
                  <p class="text-lg text-black font-normal">1111 1111 1111 1111</p>
                </div>
                <div class="mr-3">
                  <p>10/13</p>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="border-card bg-white rounded-md mb-10">
        <div class="px-4 px-6 my-4">
          <div class="grid grid-cols-12 gap-2 items-center">
            <div class="col-span-10">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Enter Card Details
              </h4>
            </div>
            <div class="col-span-2 cursor-pointer" (click)="sectionToggle.card_details = !sectionToggle.card_details">
              <img src="assets/images/dashboard/angle-up-arrow.png" *ngIf="!sectionToggle.card_details"
                   class="w-4 float-right"
                   alt="digital vault">
              <img src="assets/images/dashboard/angle-down-arrow.png" *ngIf="sectionToggle.card_details"
                   class="w-4 float-right"
                   alt="digital vault">
            </div>

          </div>
          <ng-container *ngIf="!sectionToggle.card_details">
            <div class="w-full my-3">
              <form autocomplete="off">
                <div class="lg:grid grid-cols-12">
                  <div class="col-span-12 lg:pr-0 mb-4">
                    <label for="card_number" class="text-label-grey text-sm text-regular mb-2">Card Number</label>
                    <input id="card_number" type="text" placeholder="e.g. 5129 1330 6701 0096"
                           class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 relative">
                  </div>
                </div>
                <div class="lg:grid grid-cols-12">
                  <div class="col-span-12 lg:col-span-6 pr-4 mb-4">
                    <label for="exp_date" class="text-label-grey text-sm text-regular mb-2">Expiry Date</label>
                    <input id="exp_date" type="text" placeholder="e.g. 12/25"
                           class="h-10 sm:h-12 input py-4 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700">
                  </div>
                  <div class="col-span-12 lg:col-span-6 mb-4">
                    <label for="sec_code" class="text-label-grey text-sm text-regular mb-2">Security Code</label>
                    <input id="sec_code" type="text" placeholder="e.g. 065"
                           class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700">
                  </div>
                </div>
                <div class="lg:grid grid-cols-12 mb-4">
                  <div class="col-span-12 lg:pr-0 mb-4">
                    <label for="holder_name" class="text-label-grey text-sm text-regular mb-2">Card Holder Name</label>
                    <input id="holder_name" type="text" placeholder="e.g. Christopher Home"
                           class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700">
                  </div>
                </div>
                <div class="lg:grid grid-cols-12">
                  <div class="col-span-12 lg:pr-0 mb-6">
                    <div class="w-full flex items-center justify-start">
                      <div class=" mr-4">
                        <label class="flex items-center">
                          <input id="make_default" type="checkbox" class="form-checkbox w-6 h-6"></label>
                      </div>
                      <label for="make_default" class="text-label-grey text-base text-normal">Make this my default payment
                        method</label>
                    </div>
                  </div>
                </div>


              </form>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="w-full my-5">
        <div class="flex items-center justify-between">
          <div><a
            class="flex items-center justify-center px-12 py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn-one text-center cursor-pointer"><img
            src="assets/images/payment/barrow-red.svg" class="
            mr-2" alt="barrow-red">Back</a>
          </div>
          <div>
            <a
              class="flex items-center justify-center px-12 py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn text-center cursor-pointer">Pay
              $250</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-5 px-6">
      <div class="w-full mb-8">
        <div class="bg-white rounded-md border-2 border-grey-600 p-6">
          <h3
            class="text-darkblue xl:text-xl lg:text-xl md:text-xl text-semibold border-b mb-4 pb-3 w-full border-grey-500">
            Payment Summary</h3>

          <div class="flex item-center justify-between mt-6">
            <h3 class="text-grey text-md font-light">Create Digital Vault</h3>
            <p class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular">{{undefined |
              getCurrencySymbol}}50.00</p>
          </div>

          <div class="border-b mb-4 pb-3 w-full border-grey-500"></div>
          <div class="flex item-center justify-between my-4 mb-6">
            <h3 class="text-base text-grey  tracking-wide text-regular">Total</h3>
            <p class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular">{{'50.00'|currency}}
            </p>
          </div>
          <a
            class="ml-auto mr-auto w-7/12 flex items-center justify-center px-5 py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn text-center">Pay
            $305</a>
        </div>
      </div>
      <div class="w-full mb-8">
        <div class="bg-white rounded-md border-2 border-grey-600 p-6">
          <div class="flex items-center justify-start">
            <img src="assets/images/payment/dlb.svg" class="w-20 h-20" alt="Payment dv">
            <div class="pl-4">
              <h3 class="text-base text-grey  tracking-wide text-regular mb-2">Your LegacyNOW Digital Vault ID</h3>
              <p class="text-md lg:text-xl text-darkblue tracking-wide font-semibold text-regular">DV-4c398066a5d</p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="border-card bg-white rounded mb-8 p-4 px-6 sm:flex sm:flex-row xl:flex xl:flex-row lg:flex lg:flex-col">
        <div class="py-1 xl:flex-2">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide"> Contact Us </h4>
          <div class="flex flex-row items-center mt-3">
            <img src="assets/images/dashboard/phone.png"
                 alt="digital vault"
                 class="w-4 object-contain"><a
            href="tel:+1{{customerSupportPhone}}" class="text-darkgrey text-regular ml-4 text-sm">Call us on +1
            {{customerSupportPhone}} </a></div>
          <div class="flex flex-row items-baseline pt-2">
            <img src="assets/images/dashboard/mail.png"
                 alt="digital vault"
                 class="w-4 object-contain"><a
            href="mailto:{{customerSupportMail}}" class="text-darkgrey text-regular ml-4 text-sm">Email us
            {{customerSupportMail}} </a></div>
        </div>
        <div
          class="mt-2 md:mt-0 flex justify-center sm:justify-end lg:justify-center lg:mt-2 xl:mt-0 xl:justify-end items-center flex-1">
          <div class="flex items-center">
            <img src="assets/images/dashboard/support-img1.png"
                 alt="digital vault"
                 class="w-10 h-10 object-contain -mr-2"><img
            src="assets/images/dashboard/support-img2.png"
            alt="digital vault" class="w-16 h-16 object-contain z-10 border-white rounded-full"><img
            src="assets/images/dashboard/support-img3.png" alt="digital vault" class="w-10 h-10 object-contain -ml-2">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
