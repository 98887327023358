import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment/moment';
import { Subscription } from 'rxjs';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MessageModalSettingsInterface } from 'src/app/sharedComponent/message-modal/message-modal.component';
import { ConsumerPaymentMethod, PAYMENT_STATUS } from 'src/constants/application.const';
import { CommonHelper } from '../../helper/common.helper';
import { LoadStateHelper } from '../../helper/load-state.helper';
import { ConsumerSubscriptionService } from '../consumer-payment-section/consumer-subscription.service';
import { ConsumerPaymentService } from './consumer-payment-update.service';


@Component({
  selector: 'app-consumer-update-payment',
  templateUrl: './consumer-update-payment.component.html',
  styleUrls: ['./consumer-update-payment.component.css'],
})
/**
 * consumer upate payment
 */
export class ConsumerUpdatePaymentComponent implements OnInit {
  @ViewChild('transferVaultInput', { static: true }) transferVault: ElementRef;
  public loader: LoadStateHelper;
  // Disabling update button since users will only have monthly option to start. Re-enable when we start allowing yearly packages.
  public disableUpdateButton = true;
  public requestId: string;
  public userType: string;
  public subscriptionDetails: any;
  public updatePlanButton: boolean = false;
  public isVaultTransferPaid: boolean;
  public monthlyPackageDetails: any;
  public yearlyPackageDetails: any;
  public vaultTransferAmount: string;
  public showCardScreens = false;
  public vaultRegistrationAmount: string;
  public monthlyPackage: any;
  public monthlyPackagePrice: number;
  public yearlyPackage: any;
  public selectedPackage: string;
  public activePackage: any;
  public isTransferDigitalWallet = false;
  public toggleSectionBody: boolean;
  public toggleSectionBodys: boolean;
  public isUpdatePlan = false;
  public dlbId: string;
  public discountAmount: number = 0;
  public userData: any;
  public transferVaultScreen = false;
  public progressValue: number;
  public yearMask: any;
  public showPaymentScreenSubscription: Subscription;
  public updateSubscriptionSubscription: Subscription;
  public transferVaultScreenSubscription: Subscription;
  public paymentCompleted: string;
  public customerSupportMail: string;
  public customerSupportPhone: number;
  public allowTransferPayment: boolean;
  public transferAmuntPaidDate: string;
  public paymentRefundAt: string;
  public isFirstMonth: number;
  private gotRefund = this.localStorageService.getDataByKey('gotRefund');
  private paidByProfessional: boolean;
  public messageData = {
    title: { content: 'Alert', class: 'text-2xl' },
    message: [
      this.gotRefund ? 'Your subscription has been cancelled.' :
        'Your refund process has been initiated. Refund amount will be credited to your account in two to three business days',
    ],
    buttons: [{
      content: 'Understood!',
      emitValue: false,
    }],
  };
  public alertData = {
    title: { content: 'Alert', class: 'text-2xl' },
    message: [
      this.gotRefund ? 'By canceling your subscription, you will lose access to LegacyNOW\'s premium features and your subscription benefits. However, you will still be able to use the application until your current billing period ends. To proceed, click Confirm Cancellation.' :
        'By canceling your subscription, you will lose access to LegacyNOW\'s premium features and your subscription benefits. Please note that the refund is applicable only for the first cancellation within the initial trial month. Any subsequent cancellations, even within the first month, will not be refunded. However, you will still be able to use the application until your current billing period ends. To proceed, click Confirm Cancellation.',
    ],
    buttons: [{
      content: 'Confirm Cancellation',
      emitValue: true,
      class: 'custom-border-button bg-white confirm_cancellation loading',
    }, {
      content: 'Keep my Subscription',
      emitValue: false,
    }],
  };
  public refundStatus: boolean;
  public isPaymentCompleted: boolean;
  public isAlertShowed: boolean;
  public messageSettings: MessageModalSettingsInterface = { showExit: false };

  /**
   * constructor
   */
  constructor(private subscriptionService: ConsumerSubscriptionService,
    private modalService: CommonModelService,
    private slugInterceptorService: SlugInterceptorService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private paymentService: ConsumerPaymentService,
    loadStateHelper: LoadStateHelper) {
    this.loader = loadStateHelper;
  }

  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.getContactDetails();
    this.showCardScreens = false;
    // get user request id for executor/custodian login
    this.requestId = this.localStorageService.getDataByKey('user_request_id');
    this.userType = this.localStorageService.getDataByKey('role');
    this.userData = this.localStorageService.getUserData();
    this.paymentCompleted = this.userData?.user?.is_payment_complete;
    this.isVaultTransferPaid = this.localStorageService.getDataByKey('is_transfer_amount_paid') == 1;
    this.localStorageService.deleteDataByKey('paymentInitiatedSource');
    this.getSubscriptionDetails();
    this.showPaymentScreenSubscription = this.paymentService.showPaymentScreenObserve1.subscribe((response: boolean) => {
      if (response) {
        this.showCardScreens = response;
      } else {
        this.showCardScreens = false;
        this.getSubscriptionDetails();
        void this.slugInterceptorService.navigate(['subscription-plan'], { skipLocationChange: true });
      }
    });
    this.updateSubscriptionSubscription = this.paymentService.subscriptionPlanObserve.subscribe((responseString: string) => {
        if (responseString) {
          this.subscriptionService.getSubscriptionDetailsApi().subscribe((response: any) => {
            this.isUpdatePlan = true;
            this.transferVaultScreen = false;
            this.isTransferDigitalWallet = false;
            this.dlbId = response?.data?.request_unique_id;
            response.data.package.forEach(element => {
              if (responseString != element.name) {
                this.activePackage = element;
              }
            });
          });
        }
      },
    );
    this.transferVaultScreenSubscription = this.paymentService.transferVaultScreenObserve.subscribe((data: boolean) => {
      if (data) {
        this.subscriptionService.getSubscriptionDetailsApi().subscribe((response: any) => {
          this.isUpdatePlan = false;
          this.transferVaultScreen = true;
          this.isTransferDigitalWallet = true;
          this.dlbId = response?.data?.request_unique_id;
          this.vaultTransferAmount = response.data.vault_transfer_amount;
        });
      }
    });
  }

  /**
   * update subscription plan
   */
  public updateSubscriptionPlan() {
    this.modalService.open('subscription-plan');
  }

  /**
   * close
   */
  public closeModel() {
    this.modalService.close('subscription-plan');
  }

  /**
   * get subscription details
   */
  public getSubscriptionDetails() {
    this.commonHelper.checkPaymentStatus();
    this.loader.set('subscription_details');
    this.subscriptionService.getSubscriptionDetailsApi().subscribe({
      next: response => {
        const data: Object = response.data ?? {};
        this.vaultRegistrationAmount = data['vault_registration_amount'];
        this.subscriptionDetails = data['subscription_details'];
        this.monthlyPackagePrice = data['subscription_price'];
        this.discountAmount = data['discount_amount'] ?? 0;
        this.vaultTransferAmount = data['vault_transfer_amount'];
        this.transferAmuntPaidDate = data['transfer_amount_paid_at'];
        this.paymentRefundAt = data['payment_refund_at'];
        this.dlbId = data['request_unique_id'];
        this.isFirstMonth = data['is_first_month'];
        this.refundStatus = data['is_payment_complete'] === PAYMENT_STATUS.onRefundPeriod;
        this.isPaymentCompleted = data['is_payment_complete'] === PAYMENT_STATUS.paid;
        this.localStorageService.storeData('is_payment_complete', data['is_payment_complete']);
        this.refundStatus = this.localStorageService.getDataByKey('is_payment_complete') === PAYMENT_STATUS.onRefundPeriod;
        this.paidByProfessional = this.localStorageService.getDataByKey('payment_mode') == ConsumerPaymentMethod.PAID_BY_PROFESSIONAL;
        const packages = (data['package'] as Object[]);
        this.yearlyPackageDetails = packages.find(e => e['name'] == 'Yearly');
        this.monthlyPackageDetails = packages.find(e => e['name'] == 'Monthly');
        this.activePackage = this.yearlyPackageDetails;

        this.loader.set('subscription_details', false);
      }, error: e => {
        this.commonHelper.httpResponseHandler(e.error);
        this.loader.set('subscription_details', false);
      },
    });
  }

  /**
   * on destroy
   */
  public ngOnDestroy(): void {
    this.showPaymentScreenSubscription?.unsubscribe();
    this.updateSubscriptionSubscription?.unsubscribe();
    this.transferVaultScreenSubscription?.unsubscribe();
  }

  /**
   * Format a string to long-date format.
   * @param date
   * @private
   */
  private formatDate(date: string) {
    return this.commonHelper.formatDate(moment(date), 'MMMM DD, YYYY');
  }

  /**
   * Get end_date of the subscription, formatted to long-date format.
   */
  get getSubscriptionEndDate(): string {
    return this.formatDate(this.subscriptionDetails?.end_date);
  }

  /**
   * {@link CommonService.getContactUsDetails getContactUsDetails}
   */
  public getContactDetails() {
    this.loader.set('contact');
    this.commonService.getContactUsDetails().subscribe({
      next: (response) => {
        const { support_email, support_number, notify_companies } = response.data;
        this.customerSupportMail = support_email;
        this.customerSupportPhone = support_number;
        this.allowTransferPayment = notify_companies == 1;
        this.loader.set('contact', false);
      }, error: (e) => {
        this.commonHelper.httpResponseHandler(e.error);
        this.loader.set('contact', false);
      },
    });
  }

  /**
   * opens cancel subscription alert modal
   */
  public openConfirmationModal() {
    this.modalService.open('message-modal');
  }

  /**
   * First Month - user can cancel the subscription as many times as he wants and amount will be refunded at once.If he cancels subscription from 4 h section, all will be locked
   * Second Month -If user doesnt have active subscription, make him to pay. if he has active subscription and he cancels the subscription , no refund will be provided but he can login and access vault till the end of the second month. If he cancels subscription also,he can access all sections
   * Third Month - Same as second month untill the account deletion period exceeds
   */
  public applyRefund() {
    // const isFirstMonth = this.localStorageService.getDataByKey('is_first_month'); // 1- is first month, 0 -not first month
    if (!this.paymentRefundAt && this.isFirstMonth) {
      this.loader.set('cancel_subscription');
      this.subscriptionService.applyRefund().subscribe({
        next: (response: APIResponseModel) => {
          // this.cancelSubscription();
          this.modalService.open('message-modal');
          this.isAlertShowed = true;
          this.localStorageService.storeData('is_payment_complete', response.data.is_payment_complete);
          this.refundStatus = this.localStorageService.getDataByKey('is_payment_complete') === PAYMENT_STATUS.onRefundPeriod;
          this.commonService.paymentUpdate(true);
          this.loader.set('cancel_subscription', false);
        },
        error: (exception: any) => {
          this.loader.set('cancel_subscription', false);
          this.commonHelper.httpResponseHandler(exception?.error);
        },
      });
    } else {
      this.cancelSubscription();
    }
  }

  /**
   * cancel subscription
   */
  private cancelSubscription() {
    this.loader.set('cancel_subscription', false);
    this.subscriptionService.cancelSubscription().subscribe({
      next: (response: APIResponseModel) => {
        // alert(response.message);
        this.modalService.open('message-modal');
        this.isAlertShowed = true;
        this.localStorageService.storeData('is_payment_complete', response.data.is_payment_complete);
        this.refundStatus = this.localStorageService.getDataByKey('is_payment_complete') === PAYMENT_STATUS.onRefundPeriod;
        this.commonService.paymentUpdate(true);
        this.loader.set('cancel_subscription', false);
      },
      error: (exception: any) => {
        this.loader.set('cancel_subscription', false);
        this.commonHelper.httpResponseHandler(exception?.error);
      },
    });
  }

  /**
   * Listener for Message Modal buttons.
   * @param{any} event
   */
  public buttonListener(event: any) {
    if (event) {
      this.modalService.close('message-modal');
      this.modalService.open('plan-cancel-reasons');
    } else {
      this.modalService.close('message-modal');
      void this.slugInterceptorService.navigate(['dashboard']);
    }
  }

  /**
   * hide buttons
   * @param{boolean}event
   */
  public hideButtons(event: boolean) {
    if (event) {
      this.refundStatus = this.localStorageService.getDataByKey('is_payment_complete') === PAYMENT_STATUS.onRefundPeriod;
    }
  }

  /**
   * Listens to the cancellation reason modal event.
   *
   * @param {boolean} event - The event triggered by the modal.
   */
  public cancelReasonModalListener(event: boolean) {
    if (event) {
      this.applyRefund();
    }
  }

  public get getPeriodName(): string {
    return this.subscriptionDetails?.name == 'Yearly' ? 'Year' : 'Month';
  }

  public get getCurrentPrice(): number {
    return this.monthlyPackagePrice - this.discountAmount;
  }

  /**
   * Pay for dlb
   */
  public payDlb(): void {
    if (this.userType === 'Consumer') {
      void this.slugInterceptorService.navigate(['digital-vault']);
    }
  }
}
