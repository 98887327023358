export const addressData=[
    {
        id:1,
        type:'premise',
        field_name:'address'
    },
    {
        id:1,
        type:'subpremise',
        field_name:'address'
    },
    {
        id:2,
        type:'street_number',
        field_name:'address'
    },
    {
        id:2,
        type:'route',
        field_name:'address'
    },
    {
        id:4,
        type:'neighborhood',
        field_name:'city'
    },
    {
        id:4,
        type:'postal_town',
        field_name:'city'
    },
    {
        id:4,
        type:'sublocality_level_2',
        field_name:'address'
    },
    {
        id:4,
        type:'sublocality_level_1',
        field_name:'address'
    },
    {
        id:3,
        type:'sublocality',
        field_name:'city'
    },
    {
        id:5,
        type:'locality',
        field_name:'city'
    },
    {
        id:6,
        type:'administrative_area_level_3',
        field_name:'city'
    },
    {
        id:7,
        type:'administrative_area_level_2',
        field_name:'city'
    },
    {
        id:8,
        type:'administrative_area_level_1',
        field_name:'state'
    },
    {
        id:10,
        type:'postal_code_prefix',
        field_name:'postal_code'  
    },
    {
        id:10,
        type:'postal_code',
        field_name:'postal_code'  
    }
    
]