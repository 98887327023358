import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { PAYMENT_STATUS, REFUND_LOCK_PAGES, USER_TYPES } from '../../constants/application.const';
import { IdleService } from '../services/idle.service';
import { LocalStorageService } from '../services/local-storage.service';
import { SessionStorageService } from '../services/session-storage.service';
import { CommonHelper } from './common.helper';
import { SlugInterceptorService } from './slug-interceptor.service';


@Injectable({ providedIn: 'root' })
/**
* @class AuthGuardUser
* @description for protecting routers when user is not logged in
*/
export class AuthGuardUser implements CanActivate {
  public userDetails: {};
  public id: any;
  public paymentStatus = PAYMENT_STATUS


  /**
   * @constructor
   */
  constructor(
    private slugInterceptorService: SlugInterceptorService,
    private localStorage: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private idleService: IdleService,
    private commonHelper: CommonHelper,
  ) {
  }


  /**
   * @method canActivate
   * @description method to check if router can render or not
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return {Boolean}
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this.localStorage.getUserData();
    const currentUserSession = this.sessionStorageService.getDataFromSession(USER_TYPES.user);
    const currentUserCookie = this.localStorage.getLoginCookie();
    const professionalUser = this.localStorage.getUserData(USER_TYPES.pro);
    const professionalUserCookie = this.localStorage.getLoginCookie(USER_TYPES.pro);
    const executorUser = this.localStorage.getUserData(USER_TYPES.exe);
    const executorUserCookie = this.localStorage.getLoginCookie(USER_TYPES.exe);
    const id = this.localStorage.getDataByKey('req_id');
    const routeName = route.routeConfig?.path;
    const verifiedSlug = localStorage.getItem('verifiedSlug');
    // Get the completed request stage for the current user, if available
    const completedRequestStage = currentUser?.request?.request_stage;
    if (currentUser && currentUserCookie && currentUserSession) {
      // logged in so return true
      this.idleService.reset();
      // admin_death_verify is 3, redirect to deactivated
      if (currentUser?.['request']?.['admin_death_verify'] === 3) {
        void this.slugInterceptorService.navigate(['/deactivated']);
      }
      // check vault creation fee
      if (!routeName.includes('payment') && !routeName.includes('saved-cards') && !routeName.includes('checkout')) {
        this.refundActivated(routeName);
        this.commonHelper.checkPaymentStatus();
      }
      // Check if the payment is complete
      if (currentUser?.['user']?.['is_payment_complete'] == 1) {
        // If payment is complete, check the stage using the checkStage function
        this.checkStage(routeName, completedRequestStage);
      }
      return true;
    }
    if (professionalUser && id && professionalUserCookie) {
      void this.slugInterceptorService.navigate(['/login']);
      return false;
    }
    if (executorUser && executorUserCookie) {
      void this.slugInterceptorService.navigate(['/login']);
      return false;
    }
    if (professionalUser) {
      void this.slugInterceptorService.navigate(['/login']);
      return false;
    } else {
      // not logged in so redirect to login page with the return url
      if (route?.params?.slug) {
        if (verifiedSlug !== route.params.slug || !currentUser) {
          this.localStorage.clearOrgData();
        }
        this.slugInterceptorService.navigateByUrl('/' + route.params.slug + '/login');
      } else if (route?.params?.id && route?.url[0].path == 'dlb-dashboard') { // to check if executor custodian is logged in or not in exe custodian dashboard received dlb flow
        // const encodedUrl = this.commonHelper.encodeDecodeRequestId(route?.params.id, true);
        this.localStorage.storeData('redirect-url', route?.params.id);
        void this.slugInterceptorService.navigate(['executor-custodian/login']);
      } else if (route?.params?.id && !routeName.includes('payment')) {
        this.refundActivated(routeName);
        this.commonHelper.checkPaymentStatus();
      } else {
        void this.slugInterceptorService.navigate(['/login']);
      }
      return false;
    }
  }


  /**
   * Refund activated
   * @param{string}routeName
   */
  private refundActivated(routeName: string): void {
    if (this.localStorage.getDataByKey('is_payment_complete') == this.paymentStatus['onRefundPeriod']) {
      // this.redirectToDashboard(routeName);
    }
  }

  /**
   * @method canActivateChild
   * @description method to check if router can render or not
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return {Boolean}
   */
  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }


  /**
  * Checks if the completed request stage is greater than 9.
  * If not, redirects to the dashboard.
  *
  * @param{routeName} routeName - The name of the route being checked.
  * @param{completedRequestStage} completedRequestStage - The completed stage of the request.
  */
  private checkStage(routeName: string, completedRequestStage: number): void {
    // If the completed request stage is less than or equal to 9, redirect to the dashboard
    // Before give "get start" button, the value of completedRequestStage is undefined
    if (completedRequestStage <= 9 || completedRequestStage == undefined) {
      this.redirectToDashboard(routeName);
    }
  }


  /**
  * Redirects to the dashboard if the provided routeName is in the list of locked pages.
  *
  * @param{routeName} routeName - The name of the route to check for redirection.
  */
  private redirectToDashboard(routeName: string): void {
    // Check if the routeName is in the list of locked pages
    const lockedPage = REFUND_LOCK_PAGES.find((data) => data == routeName);

    // If the routeName is in the list, navigate to the dashboard
    if (lockedPage) {
      void this.slugInterceptorService.navigate(['/dashboard']);
    }
  }
}


