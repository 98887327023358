<main class="relative">
  <!-- BEGIN: Navbar Wrapper -->
  <app-nav-landing-pro></app-nav-landing-pro>
  <!-- END: Navbar Wrapper -->

  <!-- BEGIN: Banner Wrapper -->
  <div class="feature-banner__wrap relative flex items-center justify-center banner-padding-hfull mh-700-height">
    <div class="container mx-auto">
      <div class="flex items-center text-white">
        <div class="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8 xl:gap-12">
          <div class="col-span-12 xl:col-span-9" *ngIf="professionalType==='Wealth Management'">
            <div class="mt-8 lg:mt-12">
            <h1 class="feature-banner__head text-semibold text-white fs-bhead text-regular">Designed by advisors for
              advisors
            </h1>
            <p class="feature-banner__content fs-content mt-4 mb-6 lg:mb-12 text-regular">LegacyNOW’s intuitive estate data
              management user interface organizes key estate data points stored in clients' private digital vault and makes
              them assessable to the advisor, making it simple, fast, and easy to assess and update the client’s estate
              proﬁle.</p>
              <div class="btn-wrap">
              <button (click)="scroll(target)"
                class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">Check
                all Features</button>
            </div>
          </div>
          </div>
          <div class="col-span-12 xl:col-span-9" *ngIf="professionalType!=='Wealth Management'">
            <div class="mt-8 lg:mt-12">
            <h1 class="feature-banner__head text-semibold text-white fs-bhead text-regular">Designed by advisors for
              advisors,
              LegacyNOW’s estate data management</h1>
            <p class="feature-banner__content fs-content mt-4 mb-6 lg:mb-12 text-regular">intuitive user interface identifies key
              estate data points
              stored in their clients' private digital vaults and makes them, making it
              simple, fast, and easy to assess and update the client’s estate proﬁle.</p>
              <div class="btn-wrap">
              <button (click)="scroll(target)"
                class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">Check
                all Features</button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Banner Wrapper -->

  <!-- BEGIN: Estate Plan Wrapper -->
  <div class="section estate-plan__wrap">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 gap-6 xl:gap-12" #target>
        <div class="title__wrap col-span-12" *ngIf="professionalType==='Wealth Management'">
          <h1 class="text-primary text-semibold fs-title max-w-4xl mx-auto text-regular pb-4 text-center">Now, Advisors
            can effortlessly help organize the vault holder's estate assets</h1>
          <p class="fs-content text-regular text-content max-w-4xl mx-auto text-center">in digital format throughout the
            vault holder’s lifetime and offer a thoughtfully prepared legacy strategy for resolving the estate when they
            pass, providing peace of mind for them and their families.</p>
        </div>
        <div class="title__wrap col-span-12" *ngIf="professionalType!=='Wealth Management'">
          <h1 class="text-primary text-semibold fs-title max-w-4xl mx-auto text-regular pb-4 text-center">Now, Advisors
            can More Easily Help Organize
            a Client's Estate Digital Assets</h1>
          <p class="fs-content text-regular text-content max-w-4xl mx-auto text-center">during their client’s lifetime and
            offer a
            well prepared legacy strategy for resolving the estate at the time of their passing, providing peace of mind
            for
            them and their family members.</p>
        </div>
        <div class="col-span-12">
          <img src="assets/images/features/estate-plan.webp" width="100%" height="100%" alt="legacy-now">
        </div>
      </div>
    </div>
  </div>
  <!-- END: Estate Plan Wrapper -->

  <!-- BEGIN: Digital Assets Wrap -->
  <div class="section section-even digital-assets__wrap">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 gap-6 xl:gap-12">
        <div class="col-span-12 lg:col-span-6">
          <div class="title__wrap mb-8" *ngIf="professionalType==='Wealth Management'">
            <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-left">Manage, monitor and mitigate</h1>
            <p class="fs-content text-regular text-content text-left">LegacyNOW is pioneering the estate data management
              software solution, offering your clients and their loved ones an estate digital vault with an advanced
              estate data and legacy planning interface. This combined with our estate resolution tool, makes it the most
              effective estate organization tool in existence</p>

          </div>
          <div class="title__wrap mb-8" *ngIf="professionalType!=='Wealth Management'">
            <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-left">Help Your Clients Consolidate,
              Track</h1>
            <p class="fs-content text-regular text-content text-left">LegacyNOW is a pioneering estate data management
              software solution because our estate digital vault offers your clients an advanced estate data and legacy
              planning advisor interface, combined with an estate resolution tool for the executor and beneﬁciaries,
              making it the most useful estate organizer tool in existence for all parties involved
              in the process.</p>

          </div>
          <div class="grid grid-cols-12 gap-6 xl:gap-12" *ngIf="professionalType==='Wealth Management'">
            <div class="col-span-12 lg:col-span-6">
              <div class="bg-white p-5 md:p-6 lg:p-10 rounded-xl">
                <img src="assets/images/features/book-view.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20 " alt="Legacy-now">
                <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Expert Guidance</h4>
                <p class="text-regular text-subcontent fs-subcontent">Offer LegacyNOW to your clients and their family to
                  differentiate your firm from competitors. Provide the next-gen estate digital vault solution to your
                  clients and their family members, promoting a comprehensive offering.</p>
              </div>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <div class="bg-white p-5 md:p-6 lg:p-10 rounded-xl">
                <img src="assets/images/features/digital-assets/advisor-dashboard.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20"
                  alt="Legacy-now">
                <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Advisor Dashboard</h4>
                <p class="text-regular text-subcontent fs-subcontent">Our dashboard provides an easy-to-read vault holder
                  profile for simplified estate assessment and improved strategic legacy planning.</p>
              </div>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <div class="bg-white p-5 md:p-6 lg:p-10 rounded-xl">
                <img src="assets/images/features/digital-assets/api-integration.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20"
                  alt="Legacy-now">
                <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">API Integration</h4>
                <p class="text-regular text-subcontent fs-subcontent">Seamless integration into your current advisor CRM,
                  provides added value and enables easy collaboration with your clients without major changes.</p>
              </div>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <div class="bg-white p-5 md:p-6 lg:p-10 rounded-xl">
                <img src="assets/images/features/digital-assets/real-time.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
                <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Real-Time Messaging</h4>
                <p class="text-regular text-subcontent fs-subcontent">Supports real-time communication and captures an
                  audit trail of vault holder intentions for executors and heirs, and fosters collaboration with the team
                  of estate service providers, maintaining up-to-date documents.</p>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-6 xl:gap-12" *ngIf="professionalType!=='Wealth Management'">
            <div class="col-span-12 lg:col-span-6">
              <div class="bg-white p-5 md:p-6 lg:p-10 rounded-xl">
                <img src="assets/images/features/book-view.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
                <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Expert Guidance</h4>
                <p class="text-regular text-subcontent fs-subcontent">Get ahead of the competition &
                  provide next-gen estate digital vault solution to your clients and their family members.</p>
              </div>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <div class="bg-white p-5 md:p-6 lg:p-10 rounded-xl">
                <img src="assets/images/features/digital-assets/advisor-dashboard.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20"
                  alt="Legacy-now">
                <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Advisor Dashboard</h4>
                <p class="text-regular text-subcontent fs-subcontent">Your dashboard provides an
                  easy-to-read client profile for simplified estate assessment & improved strategic legacy
                  planning.
                </p>
              </div>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <div class="bg-white p-5 md:p-6 lg:p-10 rounded-xl">
                <img src="assets/images/features/digital-assets/api-integration.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20"
                  alt="Legacy-now">
                <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">API Integration</h4>
                <p class="text-regular text-subcontent fs-subcontent">Seamlessly integrate into your
                  current advisor CRM, providing added value to you and your customers without major changes.
                </p>
              </div>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <div class="bg-white p-5 md:p-6 lg:p-10 rounded-xl">
                <img src="assets/images/features/digital-assets/real-time.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
                <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Real-Time Messaging</h4>
                <p class="text-regular text-subcontent fs-subcontent">Supports real-time communication and capturing an
                  audit trail of clients intentions for executors and heirs, as well as collaborate with your client's
                  team of estate service providers, keeping all documents up-to-date.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6 xl:pl-12">
          <div>
            <img class="float-right rounded-md overflow-hidden" src="assets/images/features/digital-assets/dlb-chart.webp"
              width="100%" height="100%" alt="legacy-now">
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Digital Assets Wrap -->

  <!-- BEGIN: Advanced Tool Wrap -->
  <div class="section advanced-tool__wrap">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
        <div class="col-span-12 lg:col-span-6">
          <img src="assets/images/features/digital-vault.webp" width="100%" height="100%" alt="legacy-now">
        </div>
        <div class="col-span-12 lg:col-span-6 xl:pl-12 order-first lg:order-last" *ngIf="professionalType==='Wealth Management'">
          <h2 class="fs-title text-accent text-semibold mb-2 lg:mb-4">
            Digital Vault Creation
          </h2>
          <h2 class="fs-title text-primary text-semibold mb-4 lg:mb-6">
            You can now supply your clients with a sophisticated system
          </h2>
          <p class="fs-content text-content text-regular mb-2 lg:mb-6">
            which digitizes and organizes all their estate assets. When you introduce and assist your clients in creating
            a LegacyNOW digital vault, you deliver a value-add to them, where you can clearly communicate, collaborate,
            and coordinate estate data usage lifecycle.
          </p>
          <p class="fs-content text-content text-regular">
            Upon creating a digital vault, possession is given to the client, who retains control. The secure vault,
            protected by blockchain technology, is accessible by the Advisor once permission is granted by the vault
            holder, providing an up to the minute view of the client’s estate, and robust reporting, analysis, and
            presentation tools needed to execute and improve their performance.
          </p>
        </div>
        <div class="col-span-12 lg:col-span-6 xl:pl-12 order-first lg:order-last" *ngIf="professionalType!=='Wealth Management'">
          <h2 class="fs-title text-accent text-semibold mb-2 lg:mb-4">
            Digital Vault Client Set-Up
          </h2>
          <h2 class="fs-title text-primary text-semibold mb-4 lg:mb-6">
            Your Firm can now Deliver Clients an Advanced Tool
          </h2>
          <p class="fs-content text-content text-regular mb-2 lg:mb-6">
            which digitizes and organizes all of their estate assets. By
            introducing and assisting your clients in setting up a LegacyNOW
            digital vault, you will bring value-add to your customer, where you can clearly communicate,
            collaborate and coordinate estate data usage lifecycle.
          </p>
          <p class="fs-content text-content text-regular">
            Once the digital vault set-up is complete, the consumer takes possession of their own blockchain protected
            estate vault
            while the advisor concurrently has their own provisioned interface with advanced features for analysis,
            presentation and
            recommendations based on all captured data points and documents needed to enhance advisory performance.
          </p>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Advanced Tool Wrap -->

  <!-- BEGIN: Advanced Tool Wrap -->
  <div class="section section-even wealth-transition-tool__wrap" *ngIf="professionalType==='Wealth Management'">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
        <div class="col-span-12 lg:col-span-6 xl:pr-12 lg:pb-0">
          <h2 class="fs-title text-accent text-semibold mb-2 lg:mb-4">
            Wealth Transition Technology
          </h2>
          <h2 class="fs-title text-primary text-semibold mb-4 lg:mb-6">
            Take legacy planning to the next level, NOW.
          </h2>
          <p class="fs-content text-content text-regular mb-2 lg:mb-6">
            LegacyNOW enables advisors to strengthen client relationships through a family inclusive process. This
            comprehensive solution provides improved legacy planning for your clients and when the time comes, streamlines
            the estate resolution process for the executor and beneficiaries.
          </p>
          <p class="fs-content text-content text-regular mb-2 lg:mb-6">
            With permission of the vault holder, Advisors play a pivotal part in educating the executor and heirs on the
            responsibilities and roles they play in the resolution of an estate after their loved one passes, and how the
            digital estate data stored in the vault is utilized to automate the process to ease painful and time-consuming
            tasks.
          </p>
          <p class="fs-content text-content text-regular">
            Our technology creates a natural bonding environment between your funeral home and the family members that
            transcends the passing of your client. This process inherently retains the next generation and provides an
            excellent solution for your firm in maintaining the transition to the next generation.
          </p>
        </div>
        <div class="col-span-12 lg:col-span-6">
          <img src="assets/images/features/wealth-transition.webp" width="100%" height="100%" alt="legacy-now">
        </div>
      </div>
    </div>
  </div>
  <div class="section section-even wealth-transition-tool__wrap" *ngIf="professionalType!=='Wealth Management'">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
        <div class="col-span-12 lg:col-span-6 xl:pr-12 lg:pb-0">
          <h2 class="fs-title text-accent text-semibold mb-2 lg:mb-4">
            Wealth Transition Tech
          </h2>
          <h2 class="fs-title text-primary text-semibold mb-4 lg:mb-6">
            Take Legacy Planning to the Next Level, Now.
          </h2>
          <p class="fs-content text-content text-regular mb-2 lg:mb-6">
            LegacyNOW enables advisors to strengthen client relationships
            through a family inclusive process. This comprehensive solution
            provides improved legacy planning for your clients and when
            the time comes, streamlines the estate resolution process for
            the executor and beneficiaries.
          </p>
          <p class="fs-content text-content text-regular mb-2 lg:mb-6">
            With permission of the vault holder, advisors get to play a pivotal part in educating
            the executor and heirs on the responsibilities and roles they play in the resolution
            of an estate after their loved one passes, and how the digital estate data stored in
            the vault is utilized to automate the process to ease painful and time consuming tasks.
          </p>
          <p class="fs-content text-content text-regular">
            This technology creates a natural bonding environment between the
            advisor and the family members that will transcend the passing
            of your clients. A process that naturally retains the next
            generation and provides an excellent solution for the
            advisor and the firm in maintaining the transition of wealth.
          </p>
        </div>
        <div class="col-span-12 lg:col-span-6">
          <img src="assets/images/features/wealth-transition.webp" width="100%" height="100%" alt="legacy-now">
        </div>
      </div>
    </div>
  </div>
  <!-- END: Advanced Tool Wrap -->

  <!-- BEGIN: Executor Data Link Wrap -->
  <div class="section executor-data__wrap" *ngIf="professionalType==='Wealth Management'">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
        <div class="col-span-12 lg:col-span-6">

          <img src="assets/images/features/executor-estate.webp" width="100%" height="100%" alt="legacy-now">
        </div>
        <div class="col-span-12 lg:col-span-6 xl:pl-12 order-first lg:order-last">
          <h2 class="fs-title text-accent text-semibold mb-2 lg:mb-4">
            Executor Estate Data Link
          </h2>
          <h2 class="fs-title text-primary text-semibold mb-4 lg:mb-6">
            A key differentiator for LegacyNOW
          </h2>
          <p class="fs-content text-content text-regular">
            is our ability to help the advisor, the vault holder and their heirs during life and after passing, by
            streamlining the responsibilities of the executor. With the click of a single button, the executor can
            effortlessly notify all companies saved in the vault of a loved one’s passing and begin the estate resolution
            process. The ease of LegacyNOW’s integrated technology provides comfort to the vault holder and relief to the
            executor. This is a significant and thoughtful gift given in advance by the vault holder to the executor to
            help them deal with responsibilities asked of them in a time of stress and of bereavement. LegacyNOW’s estate
            data link technology brings solace to the vault holder and relief to the executor.
          </p>
        </div>

      </div>
    </div>
  </div>
  <div class="section executor-data__wrap" *ngIf="professionalType!=='Wealth Management'">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
        <div class="col-span-12 lg:col-span-6">

          <img src="assets/images/features/executor-estate.webp" width="100%" height="100%" alt="legacy-now">
        </div>
        <div class="col-span-12 lg:col-span-6 xl:pl-12 order-first lg:order-last">
          <h2 class="fs-title text-accent text-semibold mb-2 lg:mb-4">
            Executor Estate Data Link
          </h2>
          <h2 class="fs-title text-primary text-semibold mb-4 lg:mb-6">
            One of the Main Differentiators of LegacyNOW
          </h2>
          <p class="fs-content text-content text-regular">
            is that it not only helps both the advisor and the client while they are alive,
            but it also helps streamline the responsibilities of the executor. By
            clicking on the Notifier Data Link, the executor is able to send all creditor
            information from the loved one’s digital vault and provide notification
            of the death to all creditors with whom the vault holder had an account
            hence, beginning the estate resolution process. This is a significant and
            thoughtful gift given in advance by the vault holder to the executor to help
            them deal with responsibilities asked of them in a time of stress and of bereavement.
            LegacyNOW’s estate data link technology brings solace to the vault holder and relief to the executor.
          </p>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Executor Data Link Wrap -->

  <!-- BEGIN: Communication Reporting Wrap -->
  <div class="section  section-even communication-report__wrap">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-baseline gap-6 lg:gap-12" *ngIf="professionalType==='Wealth Management'">
        <div class="col-span-12 lg:col-span-6 flex flex-col-reverse lg:flex-col">

          <img class="float-right rounded-md overflow-hidden" src="assets/images/features/enhanced-communication.webp"
            width="100%" height="100%" class="mb-10 lg:mb-12" alt="Enhanced Communication">
            <div>
                <h2 class="fs-title text-primary text-semibold mb-2">
                  Enhanced Communication
                </h2>
                <p class="fs-content text-content text-regular mb-6">
                  LegacyNOW has a built-in communication tool which enables advisors to easily connect with vault holders,
                  executors, beneficiaries, and other professional service provides such as estate attorneys. Our system retains
                  a record of conversations, prompts parties to respond, maintains an audit trail and will schedule reviews,
                  notifying all involved when documents need updating.
                </p>
            </div>
        </div>
        <div class="col-span-12 lg:col-span-6 flex flex-col-reverse lg:flex-col">
          <img class="float-right rounded-md overflow-hidden" src="assets/images/features/client-reporting.webp"
            width="95%" height="100%" class="mb-0 lg:mb-12 mx-auto" alt="Client requested reporting">
            <div>
              <h2 class="fs-title text-primary text-semibold mb-2">
                Client Requested Reporting
              </h2>
              <p class="fs-content text-content text-regular mb-6">
                LegacyNOW allows advisors to generate customized reports in accordance with the complexity level requested by
                the vault holder, delivering estate data point delivery and legacy planning the way they want it and in a
                consumable way the vault holders can appreciate it.
              </p>
            </div>
        </div>
      </div>
      <div class="grid grid-cols-12 items-baseline gap-6 lg:gap-12" *ngIf="professionalType!=='Wealth Management'">
        <div class="col-span-12 lg:col-span-6 flex flex-col-reverse lg:flex-col">
          <img class="float-right rounded-md overflow-hidden" src="assets/images/features/enhanced-communication.webp"
            width="100%" height="100%" class="mb-10 lg:mb-12" alt="Enhanced Communication">
            <div>
              <h2 class="fs-title text-primary text-semibold mb-2">
                Enhanced Communication
              </h2>
              <p class="fs-content text-content text-regular mb-6">
                LegacyNOW has a built in communication tool which enables
                advisors to easily connect with clients, executors, & beneficiaries,
                as well as other professional service provides such as estate
                attorneys. It tracks conversations, prompts parties to respond, maintains an audit trail and will schedule
                reviews,
                notifying advisors when documents need updating.
              </p>
            </div>
        </div>
        <div class="col-span-12 lg:col-span-6 flex flex-col-reverse lg:flex-col">

          <img class="float-right rounded-md overflow-hidden" src="assets/images/features/client-reporting.webp"
            width="95%" height="100%" class="mb-0 lg:mb-12 mx-auto" alt="Client requested reporting">
              <div>
                <h2 class="fs-title text-primary text-semibold mb-2">
                  Client Requested Reporting
                </h2>
                <p class="fs-content text-content text-regular mb-6">
                  LegacyNOW allows advisors to generate customized reports in accordance with
                  the complexity level requested by their clients, delivering estate data point delivery and legacy
                  planning the way they want it and in a consumable way where clients can appreciate it.
                </p>
              </div>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Communication Reporting Wrap -->

  <!-- BEGIN: user Support Wrap -->
  <div class="section user-support__wrap">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
        <div class="col-span-12 lg:col-span-6 xl:pr-12 lg:pb-0">

          <h2 class="fs-title text-primary text-semibold mb-2">
            User Support
          </h2>
          <p class="fs-content text-content text-regular" *ngIf="professionalType==='Wealth Management'">
            LegacyNOW recognizes that knowledge is power and encourages vault holder’s using the software to take
            advantage of our free introductory training, AI chat bot FAQ’s and call-in customer service. This will ensure
            leverage the system’s full capabilities and features.
          </p>
          <p class="fs-content text-content text-regular" *ngIf="professionalType!=='Wealth Management'">
            LegacyNOW wants advisors who are using the software to
            become knowledgeable of all features so they can receive
            the full benefit of its capabilities. We offer free introductory training
            and ongoing support to you and your clients to ensure efficiency of use.
          </p>
        </div>
        <div class="col-span-12 lg:col-span-6">
          <img class="float-right rounded-md overflow-hidden" src="assets/images/features/user-support.webp" width="100%"
            height="100%" alt="User Support">
        </div>
      </div>
    </div>
  </div>
  <!-- END: user Support Wrap -->
  <!-- BEGIN: API Integeration Wrap -->
  <div class="section section-even api-integration__wrap">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
        <div class="col-span-12 lg:col-span-6">

          <img class="float-right rounded-md overflow-hidden" src="assets/images/features/api-integration.webp"
            width="100%" height="100%" alt=" API Integration">
        </div>
        <div class="col-span-12 lg:col-span-6 xl:pl-12 lg:pb-0 order-first lg:order-last">

          <h2 class="fs-title text-primary text-semibold mb-2">
            API Integration
          </h2>
          <p *ngIf="professionalType==='Wealth Management'" class="fs-content text-content text-regular">
            LegacyNOW is able to integrate seamlessly with most estate planning CRM’s, eliminating the need for advisors
            to switch between different software to take advantage of its unique estate data lifecycle features. If there
            isn’t an existing API for your CRM, rest assured, we are able to create it.
          </p>
          <p *ngIf="professionalType!=='Wealth Management'" class="fs-content text-content text-regular">
            LegacyNOW has been programmed to integrate seamlessly with many of the estate planning
            CRM’s on the market today, so advisors don’t have to switch between different software
            to take advantage of its unique estate data lifecycle usage features. If we don’t have
            an API for your current software we are able to create it.
          </p>
        </div>

      </div>
    </div>
  </div>
  <!-- END: API Integration Wrap -->
  <!-- BEGIN: Testimonial Wrapper-->
  <div class="section">
    <app-testimonials [mobile]="mobile"></app-testimonials>
  </div>
  <!-- END: Testimonial Wrapper -->

  <!-- BEGIN: Advisor Insight Inbox -->
  <!-- <div class="section insight-inbox__wrap">
    <div class="container mx-auto">
      <div class="grid grid-cols-9 gap-4 lg:gap-12">
        <div class="title__wrap col-span-12 md:col-start-2 md:col-span-9 pb-8">
          <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-center">For Advisors Insights sent direct
            to your inbox</h1>
          <p class="fs-content text-regular text-content text-center">Sign up here to receive news and articles that will
            help better serve your clients and build your reputation as a value-add knowledge base provider.</p>
        </div>
        <div class="col-span-12">
          <div class="bg-transparent border rounded-md mx-auto md:w-5/6 lg:w-3/5">
            <form action="#" method="post"
              class="flex flex-wrap items-center justify-between rounded-md xl:h-16 md:flex-row bg-white">
              <div class="relative w-full">
                <input type="text" placeholder="Enter your Name"
                  class="flex-1 h-10 text-content text-sm md:text-lg md:px-4 m-1 text-gray-700 placeholder-gray-400 border-none appearance-none lg:h-12 focus:outline-none ">
                <div class="absolute top-1 right-3 md:right-10">
                  <button type="submit"
                    class="flex items-center justify-center text-semibold text-sm text-white w-full sm:mx-2 p-2 m-1 rounded-md md:h-12 md:mx-6 lg:text-xl xl:h-12 sm:text-base md:p-0 bg-primary focus:outline-none ">Let
                    Me Know</button>
                </div>
              </div>
            </form>
          </div>

        </div>

      </div>
    </div>
  </div> -->
  <!-- END: Advisor Insight Inbox -->


  <app-footer-landing-pro></app-footer-landing-pro>
</main>
<div class="fixed right-6 bottom-6 group z-50">
  <button type="button" aria-expanded="false" (click)="openModal('assistanceHelp')"
    class="flex items-center justify-center text-white bg-blue-700 rounded-full w-16 h-16 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
    <img src="assets/images/intro-video.png" alt="">
    <span class="sr-only">Open actions menu</span>
  </button>
</div>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>
