<app-common-modal id="{{modalId}}" class="app-common-modal">
    <h2 class="text-darkblue text-xl text-semibold pt-1">
        Resend {{selectedLogin.text}} Verification Email
    </h2>
    <p class="font-sans text-lg my-2">
        If you need to resend your {{selectedLogin.text}} registration verification email, please enter your email
        below.
    </p>
    <form [formGroup]="resendVerfiyEmailForm" autocomplete="off" (ngSubmit)="resendVerificationEmail()">
        <input type="email" placeholder="Your Email" formControlName="email" id="email"
            class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
            (keyup.enter)="resendVerificationEmail()" />
        <div *ngIf="formGet.email.errors && formGet.email.touched">
            <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.email.errors.pattern">
                Please enter a valid email address.</p>
            <p class="text-regular font-medium text-xs mt-2 error-alert"
                *ngIf="formGet.email.errors.required && formGet.email.touched">
                Email address is required.</p>
        </div>
        <div class="flex flex-row justify-end">
            <button type="submit" [disabled]="resendVerfiyEmailForm.invalid"
                class="bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-4 cursor-pointer m-2">
                Resend
            </button>
            <button
                class="bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-4 cursor-pointer m-2"
                (click)="closeResendVerificationModal()">
                Cancel
            </button>
        </div>
    </form>
</app-common-modal>