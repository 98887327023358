<div class="scroll-hidden">
  <!-- Navbar -->
  <app-navbar *ngIf="this.userType === 'Consumer'"></app-navbar>
  <app-exe-cus-navbar *ngIf="this.userType === 'Custodian'" [useCensor]="useCensor"></app-exe-cus-navbar>

  <div class="lg:grid grid-cols-12 p-8 md:px-24 py-12 background-color-grey">
    <div class="col-span-10 mb-1">
      <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
        Digital Vault Overview for {{dlbUserData?.['first_name']}} {{dlbUserData?.['last_name']}}
      </h2>
      <hr class="border-blue w-24 my-2 border-1">
    </div>
    <div class="col-span-12">
      <ng-container *ngIf="useCensor && !loading && downloadDigitalVault">
        <p class="text-xl text-regular tracking-wide text-red">
          *To view this information, please pay for the
          <a class="underline text-semibold hover:text-blue-600 cursor-pointer" (click)="payForDownload()">{{dataLinkPayment?.header}}</a>
          service.
        </p>
      </ng-container>

      <!-- BEGIN: Web view Tabs -->
      <nav class="flex flex-col hidden lg:flex sm:flex-row border-b">
        <!-- ACTIVE: text-lightblue border-b-2 font-semibold border-blue-900 -->
        <!-- DEFAULT: text-grey text-regular -->

        <button (click)="changeRoute('dlb-dashboard/'+requestIdRaw)"
                [ngClass]="[currentRouter == '/dlb-dashboard/'+requestIdRaw ?'text-lightblue border-b-2 font-semibold border-blue-900':'']"
                class="text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none ">
          Overview
        </button>
        <button (click)="changeRoute('dlb-dashboard/'+requestIdRaw+'/about-yourself-overview')"
                [ngClass]="{'text-lightblue border-b-2 font-semibold border-blue-900': currentRouter == '/dlb-dashboard/'+requestIdRaw+'/about-yourself-overview'}"
                class="text-regular text-lg text-grey text-regular py-4 px-8 block hover:text-blue-500 focus:outline-none">
          About {{dlbUserData?.['first_name'] | titlecase}}
        </button>
        <button
          [ngClass]="{'text-lightblue border-b-2 font-semibold border-blue-900': currentRouter == '/dlb-dashboard/'+requestIdRaw+'/executor-beneficiaries-overview'}"
          (click)="changeRoute('dlb-dashboard/'+requestIdRaw+'/executor-beneficiaries-overview')"
          class="cursor-pointer text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          People Summary
        </button>
        <button
          [ngClass]="{'text-lightblue border-b-2 font-semibold border-blue-900': currentRouter == '/dlb-dashboard/'+requestIdRaw+'/finance-overview'}"
          (click)="changeRoute('dlb-dashboard/'+requestIdRaw+'/finance-overview')"
          class="cursor-pointer text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          Finance
        </button>
        <button
          [ngClass]="{'text-lightblue border-b-2 font-semibold border-blue-900': currentRouter == '/dlb-dashboard/'+requestIdRaw+'/insurance-overview'}"
          (click)="changeRoute('dlb-dashboard/'+requestIdRaw+'/insurance-overview')"
          class="text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          Insurance
        </button>
        <button
          [ngClass]="{'text-lightblue border-b-2 font-semibold border-blue-900': currentRouter == '/dlb-dashboard/'+requestIdRaw+'/property-overview'}"
          (click)="changeRoute('dlb-dashboard/'+requestIdRaw+'/property-overview')"
          class="text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          Property
        </button>
        <button
          [ngClass]="{'text-lightblue border-b-2 font-semibold border-blue-900': currentRouter == '/dlb-dashboard/'+requestIdRaw+'/will-digital-file-overview'}"
          (click)="changeRoute('dlb-dashboard/'+requestIdRaw+'/will-digital-file-overview')"
          class="text-grey text-regular text-lg py-4 px-8 block hover:text-blue-500 focus:outline-none">
          Uploads
        </button>
      </nav>
      <!-- END: Web view Tabs -->

      <!-- BEGIN: Mobile View Tabs -->
      <div class="relative w-auto mt-6 sm:hidden">
        <div
          class="input py-2 h-12 px-4 lg:px-0 text-regular text-sm border rounded-xs appearance-none block focus:outline-none text-white  flex flex-row items-center justify-between lg:justify-around">
          <a href="" class="text-darkblue">Overview </a>
          <div>
            <img src="assets/images/about-yourself/angle-down-arrow.png" class="w-3" alt="digital vault">
          </div>
        </div>
        <div class="absolute hidden w-full">
          <ul class="input w-full border up-arrow bg-white rounded-xs mt-4">
            <li class="px-3 text-grey text-sm py-2 flex justify-between items-center">
              Overview
            </li>
            <li class="px-3 text-grey text-sm py-2 flex justify-between items-center">
              About {{dlbUserData?.['first_name']}}
            </li>

            <li class="px-3 text-grey text-sm py-2 flex justify-between items-center">
              People Summary
            </li>
            <li class="px-3 text-grey text-sm py-2 flex justify-between items-center">
              Finance
            </li>
            <li class="px-3 text-grey text-sm py-2 flex justify-between items-center">
              Insurance
            </li>
            <li class="px-3 text-grey text-sm py-2 flex justify-between items-center">
              Property
            </li>
            <li class="px-3 text-grey text-sm py-2 flex justify-between items-center">
              Uploads
            </li>
          </ul>
        </div>

      </div>
      <!-- BEGIN: END View Tabs -->

      <!-- Router outlet overview section - sub-components -->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<app-message-modal [setMessageData]="messageData" (buttonEmitter)="messageModalListener($event)"></app-message-modal>
<app-data-link-service-info-modal [dvTransferContent]="dvTransferContent"></app-data-link-service-info-modal>
