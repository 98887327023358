<div class="scroll-hidden background-color-grey">
    <app-navbar></app-navbar>

    <div class="grid grid-cols-12 space-x-4 space-y-8 p-6 md:p-8 lg:px-12 xl:px-24">
        <div class="col-span-12">
            <div class="w-full">
                <h2 class="text-darkblue text-xl lg:text-2xl xl:text-4xl uppercase text-semibold pt-1">Billing History
                </h2>
                <p class="border-b-8 w-24 border-red mb-3 mt-1"></p>
            </div>
            <div class="my-10">
                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Invoice ID
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Invoice Date
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="!loader">
                                <tr *ngFor="let data of billingHistoryData"
                                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <th scope="row" class="px-6 py-4 text-gray-700">
                                        {{data?.invoice_id}}
                                    </th>
                                    <td class="px-6 py-4 text-gray-700">
                                        {{data?.created_at | date:'MM/dd/YYYY'}}
                                    </td> 
                                    <td class="px-6 py-4 text-gray-700">
                                        {{data?.total_amount | currency}}
                                    </td>
                                    <td class="px-4 py-4"> 
                                        <span [ngClass]="{
                                            'bg-green-100 text-green-800': data?.status === PaymentStatus.Suceeded,
                                            'bg-blue-100 text-blue-800': data?.status === PaymentStatus.Refunded,
                                            'bg-yellow-100 text-yellow-800': data?.status === PaymentStatus.Cancelled,
                                            'bg-red-100 text-red-800': data?.status === PaymentStatus.Failed
                                          }" class="text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 border dark:text-gray-400 border-gray-400">
                                            {{ getPaymentStatus(data?.status) | titlecase }}
                                          </span>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="flex items-center justify-center" *ngIf="!loader">
                <nav aria-label="Page navigation example">
                    <ul class="flex items-center -space-x-px h-10 text-base">
                        <li>
                            <a *ngIf="currentPage > 1"
                                (click)="getBillingHistoryRequest(currentPage > 1 ? currentPage-1 : 0 )"
                                class="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                <span class="sr-only">Previous</span>
                                <svg class="w-3 h-3 rtl:rotate-180" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M5 1 1 5l4 4" />
                                </svg>
                            </a>
                            <a *ngIf="currentPage === 1"
                                class="flex cursor-not-allowed items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg  dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400">
                                <span class="sr-only">Previous</span>
                                <svg class="w-3 h-3 rtl:rotate-180" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M5 1 1 5l4 4" />
                                </svg>
                            </a>
                        </li>
                        <ng-container *ngFor="let page of paginationLinks;let i = index;">
                            <li *ngIf=" i <= lastPage && i > 0 " (click)="getBillingHistoryRequest(i)">
                                <a [ngClass]="currentPage == i ? 'z-10 text-blue-600 bg-blue-50 border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:bg-gray-700 dark:text-white' : 'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-700'"
                                    class="flex items-center justify-center px-4 h-10 leading-tight border border-gray-300 dark:border-gray-700 dark:text-gray-400">{{i}}</a>
                            </li>
                        </ng-container>
                        <li>
                            <a *ngIf="(currentPage) <  lastPage" (click)="getBillingHistoryRequest( currentPage + 1 )"
                                class="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                <span class="sr-only">Next</span>
                                <svg class="w-3 h-3 rtl:rotate-180" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="m1 9 4-4-4-4" />
                                </svg>
                            </a>
                            <a *ngIf="(currentPage) ===  lastPage"
                                class="flex cursor-not-allowed items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg  dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400">
                                <span class="sr-only">Next</span>
                                <svg class="w-3 h-3 rtl:rotate-180" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="m1 9 4-4-4-4" />
                                </svg>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>