import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from "../../../services/local-storage.service";
import {USER_TYPES} from "../../../../constants/application.const";

@Component({
  selector: 'app-professional',
  templateUrl: './professional.component.html',
})
/**
 * profession class
 */
export class ProfessionalComponent implements OnInit {
  public isLoggedIn: any;

  /**
   * constructor
   */
  constructor(private localStorageService: LocalStorageService) {
  }

  /**
   * loaded initially
   */
  ngOnInit(): void {
    this.isLoggedIn = this.localStorageService.getUserData(USER_TYPES.pro);
  }
}
