<div class="scroll-hidden box-border body-content">

  <div class="pt-12">
    <div class="flex flex-wrap lg:mx-8 mx-6 ">
      <div class="w-full">
        <div class="flex items-center">
          <img src="assets/images/about-yourself/back-arrow.svg" alt="digital vault" class="cursor-pointer"
            (click)="changeRoute('professional-management')">
          <h2 class="text-darkblue text-md lg:text-2xl capitalize pl-5 text-semibold">
            {{isNewAgent ? 'Add' : 'Edit'}} Professional
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-8">
    <form [formGroup]="agentForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="border-card bg-white rounded-md p-6 mx-8">
        <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mb-5">
          <!-- First Name -->
          <div class="col-span-12 md:col-span-6 lg:col-span-4 ">
            <label for="first_name" class="text-sm mb2 text-regular text-label-grey">First Name</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" [transform]="'shrink-7 up-3'"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="text" id="first_name"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.first_name.errors && formGet.first_name.touched?'form-input-error':'form-input-focus'"
              placeholder="First Name" formControlName="first_name">
            <p *ngFor="let error of getErrors('first_name')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <!-- Middle Name -->
          <div class="col-span-12 md:col-span-6 lg:col-span-4 ">
            <label for="middle_name" class="text-sm mb2 text-regular text-label-grey">Middle Name</label>
            <input type="text" id="middle_name"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.middle_name.errors && formGet.middle_name.touched?'form-input-error':'form-input-focus'"
              placeholder="Middle Name" formControlName="middle_name">
            <p *ngFor="let error of getErrors('middle_name')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <!-- Last Name -->
          <div class="col-span-12 md:col-span-6 lg:col-span-4 ">
            <label for="last_name" class="text-sm mb2 text-regular text-label-grey">Last Name</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" [transform]="'shrink-7 up-3'"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="text" id="last_name"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.last_name.errors && formGet.last_name.touched?'form-input-error':'form-input-focus'"
              placeholder="Last Name" formControlName="last_name">
            <p *ngFor="let error of getErrors('last_name')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <!-- Email Address -->
          <div class="col-span-12 md:col-span-6 lg:col-span-6">
            <label for="email" class="text-sm mb2 text-regular text-label-grey">Email Address</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" [transform]="'shrink-7 up-3'"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="email" id="email"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.email.errors && formGet.email.touched?'form-input-error':'form-input-focus'"
              formControlName="email" name="email" placeholder="e.g. jonathanbairstow@gmail.com">
            <p *ngFor="let error of getErrors('email')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <!-- Phone Number -->
          <div class="col-span-12 md:col-span-6 lg:col-span-6">
            <label [for]="'phone-agent-edit'" class="text-sm mb2 text-regular text-label-grey">Phone Number</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" [transform]="'shrink-7 up-3'"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <app-phone-input [placeholder]="'e.g. 8665044754'" [phoneNumber]="agentForm.value.phone"
              [countryCode]="countryCode" (click)="formGet.phone.markAsTouched()" [uniqueId]="'phone-agent-edit'"
              [errorClassTrigger]="formGet.phone.errors && formGet.phone.touched"
              (onChange)="detectChangePhonenumber($event)" [form]="agentForm"
              (focusout)="formGet.phone.markAsTouched()">
            </app-phone-input>
            <p *ngFor="let error of getErrors('phone')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <!-- Title -->
          <div class="col-span-12 md:col-span-6 lg:col-span-6 ">
            <label for="title" class="text-sm mb2 text-regular text-label-grey">Title</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="text"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.title.errors && formGet.title.touched?'form-input-error':'form-input-focus'"
              placeholder="e.g Regional Manager" formControlName="title" id="title">
            <p *ngFor="let error of getErrors('title')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <!-- Manager -->
          <div class="col-span-12 md:col-span-6 lg:col-span-6" *ngIf="this.userType === 'Professional' && managersList?.length > 0">
            <ng-container>
              <label for="advisor" class="text-sm mb2 text-regular text-label-grey">
                Manager
              </label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <div class=" mt-1 col-span-12 lg:col-span-2 relative">
                <app-select-with-search id="advisor" [options]="managersList" [placeholderValue]="'Choose Manager'"
                  [defaultSelected]="formGet.manager_id.value"
                  (optionChangeEmitter)="optionChangeEmitterManagerType($event)" tabindex="0"
                  (focusout)="markAsTouchedIndividual('manager_id')" (click)="formGet.manager_id.markAsTouched()"
                  [ifError]="formGet.manager_id.errors && formGet.manager_id.touched"></app-select-with-search>
                <p *ngFor="let error of getErrors('manager_id')"
                  class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </ng-container>
          </div>
        </div>
        <!-- Assign Professional -->
        <div class="col-span-12 md:col-span-12 lg:col-span-12 relative">
          <ng-container>
            <div class="w-full my-3 mb-5">
              <label for="assign_professional" class="text-sm mb2 text-regular text-label-grey">
                Assign Professional
              </label>
              <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
                <div class="col-span-12 md:col-span-12 lg:col-span-12">
                  <app-assign-professional id="assign_professional" [editData]="formGet?.assign_professionals?.value" [professionalsList]="professionalsList"
                    (selectProfessionalEmitter)="selectedProfessional($event)">
                  </app-assign-professional>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="w-full my-3 mb-5">
          <h3 class="text-semibold text-darkblue text-lg">Address</h3>
          <p class="text-grey text-md text-regular mb-4">Please enter their primary physical address.</p>
          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
            <!-- Country -->
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
              <label class="text-sm mb-2 text-regular text-label-grey">Country</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" [transform]="'shrink-7 up-3'"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <div class=" col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('country')">
                <app-country-search-component (optionChangeEmitter)="optionChangeListenerCountry($event)"
                  [defaultSelected]="formGet?.country?.value"
                  [enableError]="formGet.country.errors && formGet.country.touched"></app-country-search-component>
                <p *ngFor="let error of getErrors('country')" class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
            <!-- Street Address -->
            <div class="col-span-12 md:col-span-6 lg:col-span-4 relative move-map" #moveMapHere>
              <label for="street_address" class="text-sm mb-2 text-regular text-label-grey">Street Address</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" [transform]="'shrink-7 up-3'"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <input id="street_address" type="text" #search (keyup)="onAddressChange(search)"
                (input)="onFocusAddress()" (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null"
                class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
                placeholder="e.g Main St" name="street_address" formControlName="street_address"
                [ngClass]="formGet.street_address.errors && formGet.street_address.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('street_address')"
                class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
            <!-- State/Province -->
            <div class="col-span-12 md:col-span-6 lg:col-span-4" *ngIf="selectedCountryName!==unitedKingdom">
              <label class="text-sm mb-2 text-regular text-label-grey">State/Province</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" [transform]="'shrink-7 up-3'"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('state')">
                <app-state-search-component [countryCode]="formGet?.country?.value"
                  [enableError]="formGet.state.errors && formGet.state.touched"
                  [defaultSelected]="formGet?.state?.value" (optionChangeEmitter)="optionChangeListenerState($event)"
                  tabindex="0" (focusout)="markAsTouchedIndividual('state')">
                </app-state-search-component>
                <p *ngFor="let error of getErrors('state')" class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
            <!-- City -->
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
              <label class="text-sm mb-2 text-regular text-label-grey">City</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" [transform]="'shrink-7 up-3'"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('city')">
                <app-city-search [stateCode]="formGet?.state?.value"
                  [enableError]="formGet.city.errors && formGet.city.touched" [defaultSelected]="formGet?.city?.value"
                  (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0"
                  (focusout)="markAsTouchedIndividual('city')"></app-city-search>
                <p *ngFor="let error of getErrors('city')" class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
            <!-- Zip/Postal Code -->
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
              <label for="zipcode" class="text-sm mb-2 text-regular text-label-grey">Zip/Postal Code</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" [transform]="'shrink-7 up-3'"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <input id="zipcode" type="text" (focusout)="markAsTouchedIndividual('zipcode')"
                class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
                name="zipcode" formControlName="zipcode" oninput="this.value = this.value.toUpperCase()"
                [placeholder]="getZipcodePlaceholder" (focus)="validateZipCode()"
                [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
              <p *ngFor="let error of getErrors('zipcode')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
            </div>
          </div>
        </div>
        <!-- Profile Picture -->
        <div class="w-full my-3">
          <h3 class="text-semibold text-darkblue text-lg mb-1">Upload Profile Picture*</h3>
          <p class="text-grey text-regular  text-sm">
            <span class="font-sans font-bold">Accepted File Formats</span> — jpeg, jpg, png
          </p>
          <p class="text-grey text-regular py-1 text-sm">
            <span class="font-sans font-bold">Maximum File Size</span> — 100 MB.
          </p>
          <div class="pt-0">
            <div class="flex flex-wrap">
              <div class="w-full md:w-full lg:w-2/3 mb-3 ">
                <app-file-upload-handler (click)="markUploadFieldAsTouched('profile_picture')"
                  (fileUploadChangeEmitter)="uploadProfilePicture($event)"
                  (fileDeleteChangeEmitter)="deleteImage($event)" [fileValidationOptions]="fileValidationOptions"
                  [allowImagesOnly]="true" [imageFormatFiltering]="imageFormatFiltering">
                </app-file-upload-handler>
                <p *ngFor="let error of getErrors('profile_picture', false)"
                  class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full my-3">
          <div class="w-full  h-12 lg:h-24 md:w-full relative">
            <div class="absolute bottom-0 right-0">
              <button [disabled]="preDefinedLoader"
                class="flex items-center justify-between button button--lg w-full bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white text-sm px-4">
                <div class="loading-red mr-2" *ngIf="loginLoader"></div>
                <div>{{isNewAgent ? 'Add' : 'Save'}} Professional</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>