import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalManagementService {
  private readonly BASE_URL: string = environment.BASE_URL;
  private emitChangeSource: any;
  public changeEmitted: Observable<string>;

  constructor(private http: HttpClient) {
    this.emitChangeSource = new BehaviorSubject({});
    this.changeEmitted = this.emitChangeSource.asObservable();
  }

  /**
   * Get all professionals
   * @param [page] 
   * @param [length] 
   * @param [sort] 
   * @param [search] 
   * @returns all professionals 
   */
  public getAllProfessionals(page: number = 1, length: number = 10, sort?: string, search?: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/professional-list-paginate?page=${page}&length=${length}&sort=${sort}&search=${search}`)
  }

  /**
 * @param {any} data
 * @return {Observable}
 * add professional
 */
  public addProfessional(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/agent-save`, data);
  }
  /**
   * @param {any} data
   * @return {Observable}
   * view professional
   */
  public viewProfessional(data: any): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/agent/${data}`);
  }
  /**
   * @param {any} data
   * @return {Observable}
   * edit professional
   */
  public editProfessional(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/agent-update`, data);
  }

  /**
  *
  * emit changes
  * @param {any} change
  */
  public emitChange(change: any) {
    this.emitChangeSource.next(change);
  }


  /**
   * @param {any} data
   * @return {Observable}
   * delete professional
   */
  public deleteProfessional(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/professional/agent/${data}`, null);
  }

  /**
   * @param {any} data
   * @return {Observable}
   * get professionals assigned under a manager
   */
  public getProfessionalsByManagerId(managerId: string) {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional/advisor-list/${managerId}`);
  }
}

