import { Component, OnInit } from '@angular/core';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonService } from 'src/app/services/common.service';
import { faCircleXmark, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-consumer-notifications',
  templateUrl: './consumer-notifications.component.html',
  styleUrls: ['./consumer-notifications.component.css'],
})
export class ConsumerNotificationsComponent implements OnInit {
  public notificationList:Array<any> = [];
  public notificationMessage: string;
  public noData: boolean = true;
  public agentTerm: string;
  public faCircleXmark = faCircleXmark;
  public faCheckCircle = faCheckCircle;
  public preDefinedLoader: boolean = true;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];

  constructor(
    private localStorageService: LocalStorageService,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    private commonService: CommonService,
    private socketService: SocketService
  ) {

  }
  ngOnInit(): void {
    this.notificationMessage = 'Notification Message';
    this.agentTerm = this.commonHelper.LocalStorageAgentTerm();
    this.socketService.listenAppointmentStatus().subscribe((response) => {
     this.loadData();
    })
  }
  public loadData():void {
    this.commonService.getUsersAppointmentList().subscribe((response: APIResponseModel)=>{
      if(response?.data?.length) {
        this.preDefinedLoader = false;
        this.notificationList = response?.data;
      }
  }, (expectation) =>{
    this.preDefinedLoader = false;
  })
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.loadData();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.loadData();
  }
  /**
     * change route to schedule accept
     * @param{any}id
    */
  acceptEvent(id: any): void {
    this.changeRoute(`schedule-accept/${id}`);
  }
  /**
     * change route to schedule decline
     * @param{any}id
    */
  declineEvent(id: any): void {
    this.changeRoute(`schedule-decline/${id}`);
  }
  /**
     * change route
     * @param{string}url
    */
  public changeRoute(url:string) {
    void this.slugInterceptorService.navigate([ url]);
  }

}
