<div class="scroll-hidden">
  <!-- Navbar -->
  <app-navbar *ngIf="this.userType === 'Consumer'"></app-navbar>
  <app-exe-cus-navbar *ngIf="this.userType === 'Custodian'"></app-exe-cus-navbar>
  <app-professional-navbar [showReturnToDashboard]="true" *ngIf="this.userType !== 'Consumer'&& this.userType!=='Custodian'"></app-professional-navbar>


  <!-- Router outlet -->
  <router-outlet></router-outlet>
</div>
