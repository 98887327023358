/* eslint-disable require-jsdoc */
import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';
/**
 * dropdwon class
 */
@Directive({
  selector: '[appDropdown]',
})
export class DropdownDirective {
  @HostBinding('class.show') isOpen = false;
  @HostListener('document:click', ['$event'])
  toggleOpen(event: Event) {
    this.elRef.nativeElement.style.display='none';
  }
  constructor(private elRef: ElementRef) {}
}
