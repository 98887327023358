<div class="grid grid-cols-12 gap-5 row-gap-4">

  <div class="col-span-12">
    <h4 class="text-label-grey text-sm tracking-wide text-regular">{{agentTerm}} Type
    </h4>
    <p class="text-darkgrey text-base text-regular tracking-wide">
      {{ advisorInfo?.['advisor_type']?.['name'] || advisorInfo?.['professional_type']?.['name'] || advisorInfo?.['professional_type'] }}</p>
  </div>

  <div class="col-span-12 md:col-span-6">
    <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
    </h4>
    <p class="text-darkgrey text-base text-regular tracking-wide">{{advisorInfo?.['first_name']}}
      {{advisorInfo?.['last_name']}}
    </p>
  </div>

  <div class="col-span-12 md:col-span-6 ">
    <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
    </h4>
    <p class="text-darkgrey text-base text-regular tracking-wide" *ngIf="advisorInfo?.phone">+{{advisorInfo?.['country_code']}}
      {{advisorInfo?.phone}}
    </p>
    <p class="text-darkgrey text-base text-regular tracking-wide" *ngIf="!advisorInfo?.phone">NA
    </p>
  </div>
  <div class="col-span-12 md:col-span-6">
    <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
    </h4>
    <p class="text-darkgrey break-all text-base text-regular tracking-wide" *ngIf="advisorInfo?.email">
      {{advisorInfo?.email}}</p>
      <p class="text-darkgrey break-all text-base text-regular tracking-wide" *ngIf="!advisorInfo?.email">
        NA</p>
  </div>
  <div class="col-span-12 md:col-span-6">
    <h4 class="text-label-grey text-sm tracking-wide text-regular">Address
    </h4>
    <p class="text-darkgrey break-all text-base text-regular tracking-wide" *ngIf="advisorInfo?.address">
      {{advisorInfo?.address}}</p>
      <p class="text-darkgrey break-all text-base text-regular tracking-wide" *ngIf="!advisorInfo?.address">
        NA</p>
  </div>
  <div class="col-span-12 md:col-span-6">
    <h4 class="text-label-grey text-sm tracking-wide text-regular">Country
    </h4>
    <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize">
      {{advisorInfo?.country?.name}}</p>
  </div>
  <div class="col-span-12 md:col-span-6" *ngIf="advisorInfo?.state?.name != null && advisorInfo?.state?.name != ''">
    <h4 class="text-label-grey text-sm tracking-wide text-regular">State
    </h4>
    <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize" *ngIf="advisorInfo?.state?.name">
      {{advisorInfo?.state?.name}}</p>
      <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize" *ngIf="!advisorInfo?.state?.name">
        NA</p>
  </div>
  <div class="col-span-12 md:col-span-6">
    <h4 class="text-label-grey text-sm tracking-wide text-regular">City
    </h4>
    <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize" *ngIf="advisorInfo?.city?.name">
      {{advisorInfo?.city?.name}}</p>
      <p class="text-darkgrey break-all text-base text-regular tracking-wide capitalize" *ngIf="!advisorInfo?.city?.name">
        NA</p>
  </div>
  <div class="col-span-12 md:col-span-6">
    <h4 class="text-label-grey text-sm tracking-wide text-regular">Zip Code
    </h4>
    <p class="text-darkgrey break-all text-base text-regular tracking-wide" *ngIf="advisorInfo?.zipcode">
      {{advisorInfo?.['zipcode']}}</p>
      <p class="text-darkgrey break-all text-base text-regular tracking-wide" *ngIf="!advisorInfo?.zipcode">
        NA</p>
  </div>

</div>
