<div class="scroll-hidden">
  <div class="h-screen lg:grid grid-cols-12">
    <!-- BEGIN: Login Form -->
    <div class="col-span-12 md:col-span-6 xl:col-span-5 h-screen lg:h-auto p-6 flex">
      <div class="flex flex-col w-full">
        <!-- BEGIN: Logo -->
        <div class="md:pl-2 flex items-center gap-2 sm:gap-4 flex-row">
          <a (click)="changeRoute('')" class="cursor-pointer">
            <img alt="The-Estate-Registry" width="250" [src]="'assets/images/logo-old.png'" class="object-contain w-52 lg:w-60">
            <p class="text-regular text-semibold text-orange text-sm lg:text-lg text-center">
              {{this.proTypeText ? this.proTypeText + " Edition" : ""}}
            </p>
          </a>
          <a *ngIf="orgLogoPath"  class=" border-l-black pl-6 cursor-pointer max-h-12 maxw128">
            <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
          </a>
          <!-- <div class="flex items-center justify-center gap-2 md:gap-4 h-16 md:h-24">
            <div class="flex items-start gap-1 md:gap-2 flex-col">
              <p *ngIf="orgLogoPath "
                class="whitespace-nowrap text-xs md:text-sm lg:text-regular text-dark-grey">Sponsored by:</p>
              <a class="cursor-pointer max-h-12 maxw128" (click)="changeRoute('dashboard')" *ngIf="orgLogoPath">
                <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
              </a>
            </div>
          </div> -->
        </div>
        <!-- END: Logo -->
        <div class="h-full flex items-center justify-center mt-4">
          <div class="my-auto mx-auto bg-white pt-8 sm:pl-10 xl:bg-transparent px-5 sm:px-8 w-full">
            <div class="h-full justify-center items-center ">
              <h2 class="text-darkblue mt-2 text-4xl text-semibold pt-1">LegacyNOW <br> Professional Dashboard
              </h2>
              <hr class="border-blue w-20 mt-1 border-1">
              <!-- <div class="mt-4">
                <p class="text-regular text-grey text-base">Enter your email to reset your password
                </p>
              </div> -->
              <form [formGroup]="resetRequestForm" autocomplete="off" (ngSubmit)="resetRequest()">
                <div class="mt-6">
                  <div>
                    <label for="email" class="text-sm pb-2 text-regular text-grey">Enter your email to reset your password</label>
                    <input type="email" name="email" formControlName="email" id="email"
                      class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                      placeholder="Enter your Email address"
                      [ngClass]="formGet.email.errors && formGet.email.touched?'form-input-error':'form-input-focus'">
                    <div *ngIf="formGet.email.errors && formGet.email.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.email.errors.pattern">
                        Please enter a valid email address.</p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.email.errors.required">
                        Email is required.</p>
                    </div>
                  </div>
                </div>

                <!-- BEGIN: Form Register Button -->
                <div class="mt-6 md:flex md:flex-row text-center flex-col-reverse items-center">
                  <div class="ml-auto mt-4 md:mt-0">
                    <button type="button"
                      class="relative button button--lg md:w-auto w-full bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-6 flex items-center"
                      [ngClass]="(resendOtpTimer || !resetRequestForm.valid )?'opacity-50':''"
                      [disabled]="resendOtpTimer || !resetRequestForm.valid" (click)="resetRequest()">
                      <div class="loading-red mr-2" *ngIf="resendOtpTimer"></div>
                      <div>Submit<span *ngIf="resendOtpTimer">&nbsp;in {{resendOtpTimer}}s</span></div>
                    </button>
                  </div>
                </div>
                <!-- END: Form Register Button -->

              </form>

            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- END: Login Form -->

    <!-- BEGIN: Login Info -->
    <div class="md:col-span-6 xl:col-span-7 hidden lg:flex bg-login-grey min-h-screen justify-center">
      <div class="fixed h-full flex flex-col items-center justify-center">
        <!-- BEGIN: First Slide -->
        <app-right-carousel-intro></app-right-carousel-intro>
      </div>
    </div>
    <!-- END: Login Info -->
  </div>
</div>
