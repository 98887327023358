<app-common-modal id="add-business-modal" class="app-common-modal" [heightClass]="'min-h-75'">
  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
         (click)="closeModel()">
    <form [formGroup]="businessForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Business Details
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">
            An organization or enterprising entity engaged in commercial, industrial, or professional activities.
          </p>
          <p class="text-regular font-medium text-xs mt-2 error-alert"
             *ngIf="(isNewAsset && !permissions?.['add']) || (!isNewAsset && !permissions?.['edit'])">
            You do not have permission to
            {{ isNewAsset ? 'add information for this client.' : 'edit this client\'s information.' }}
          </p>
        </div>
        <div class="col-span-12">
          <label for="company_name" class="text-label-grey text-sm text-regular">Company Name*</label>
          <input type="text" id="company_name" [attr.disabled]="!canEdit || undefined"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="company_name" formControlName="company_name" placeholder="e.g. United Health Group"
                 [ngClass]="formGet.company_name.errors && formGet.company_name.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('company_name')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
        <div class="col-span-12 relative"
             (click)="canAccessForm ? markAsTouchedIndividual('business_organization_type_id'):''">
          <label for="business_type_id" class="text-label-grey text-sm text-regular">Type of Organization*</label>
          <app-select-with-search
            [options]="organisationTypes" [placeholderValue]="'Select organization type'"
            (optionChangeEmitter)="optionChangeEmitterOrganisationType($event)"
            id="business_type_id"
            [defaultSelected]="businessForm?.value?.['business_organization_type_id']"
            tabindex="0"
            [disabled]="!canEdit || !canAccessForm"
            (focusout)="markAsTouchedIndividual('business_organization_type_id')"
            [ifError]="formGet.business_organization_type_id.errors && formGet.business_organization_type_id.touched"></app-select-with-search>
          <p *ngFor="let error of getErrors('business_organization_type_id')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>

        <div *ngIf="useOrganizationOther" class="col-span-12">
          <label for="company_name" class="text-label-grey text-sm text-regular">Define Other</label>

          <input type="text" [attr.disabled]="!canEdit || undefined" id="business_organization_other"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="business_organization_other" formControlName="business_organization_other"
                 placeholder="Define Other"
                 [ngClass]="formGet.business_organization_other.errors && formGet.business_organization_other.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('business_organization_other')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>

        <div class="col-span-12 relative" (click)="markAsTouchedIndividual('business_type_id')">
          <label for="business-type" class="text-label-grey text-sm text-regular">Type of Industry*</label>
          <app-select-with-search [options]="businessTypes" [placeholderValue]="'Select business type'"
                                  (optionChangeEmitter)="optionChangeEmitterBusinessType($event)" id="business-type"
                                  [defaultSelected]="businessForm?.value?.['business_type_id']" tabindex="0"
                                  [disabled]="!canEdit || !canAccessForm"
                                  (focusout)="markAsTouchedIndividual('business_type_id')"
                                  [ifError]="formGet.business_type_id.errors && formGet.business_type_id.touched"></app-select-with-search>
          <p *ngFor="let error of getErrors('business_type_id')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>

        <div *ngIf="useIndustryOther" class="col-span-12">
          <input type="text" [attr.disabled]="!canEdit || undefined" id="business_type_other"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="business_type_other" formControlName="business_type_other" placeholder="Define Other"
                 [ngClass]="formGet.business_type_other.errors && formGet.business_type_other.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('business_type_other')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
        <div class="col-span-12  mt-1">
          <label for="ein" class="text-grey text-sm text-regular ">Employer Identification Number (EIN)</label>
          <input type="text" [attr.disabled]="!canEdit || undefined" id="ein"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="ein" formControlName="ein" placeholder="e.g 45-3676756" maxlength="10"
                 [mask]="EIN_MASK" [dropSpecialCharacters]="false"
                 [ngClass]="formGet.ein.errors && formGet.ein.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('ein')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
        <div class="col-span-12">
          <label for="website" class="text-label-grey text-sm text-regular">Website</label>
          <input type="text" [attr.disabled]="!canEdit || undefined"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="website" formControlName="website" placeholder="e.g www.unitedhealthgroup.com" id="website"
                 [ngClass]="formGet.website.errors && formGet.website.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('website')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
        <div class="col-span-12">
          <label for="net_value" class="text-label-grey text-sm text-regular">Net Value</label>
          <div class="relative flex items-center">
            <span class="absolute ml-4 outline-none">{{ undefined | getCurrencySymbol }}</span>
            <input type="text" [attr.disabled]="!canEdit || undefined" id="net_value" appCurrencyInput
                   class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 price-input"
                   name="vehicle-value" formControlName="net_value" placeholder="e.g 1000"
                   [ngClass]="formGet.net_value.errors && formGet.net_value.touched?'form-input-error':'form-input-focus'">
          </div>
          <p *ngFor="let error of getErrors('net_value')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>

        <div class="col-span-12">
          <label for="business_summary" class="text-label-grey text-sm text-regular">Summary of Business</label>
          <textarea rows="4" cols="50" id="business_summary" [attr.disabled]="!canEdit || undefined"
                    class="input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                    name="business_summary" formControlName="business_summary" maxlength="500"
                    placeholder="Enter details about your business or any relevant information here."
                    [ngClass]="formGet.business_summary.errors && formGet.business_summary.touched?'form-input-error':'form-input-focus'">
          </textarea>
          <p *ngFor="let error of getErrors('business_summary')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
        <div class="col-span-12 sm:col-span-9">
          <div>
            <h6 class="text-regular  text-darkblue font-semibold tracking-wide">
              Do you have a Business Partner / Co-Owner?
            </h6>

          </div>
        </div>
        <div class="col-span-12 sm:col-span-3">
          <app-toggle-switch [defaultSelected]="partnerStatus" [options]="toggleOptions"
                             (optionChangeEmitter)="onChangeToggle($event)"
                             [disabled]="!canEdit || !canAccessForm || submitLoader">
          </app-toggle-switch>
        </div>
        <div class="col-span-12" *ngIf="partnerStatus == 1">
          <div class="col-span-12">

            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Business Partner / Co-Owner Information
            </h4>
          </div>
          <div class="col-span-12 lg:col-span-6">
            <label for="partner_first_name" class="text-label-grey text-sm text-regular ">First Name*</label>

            <input type="text" [attr.disabled]="!canEdit || undefined"
                   class="h-10 sm:h-12 input py-3 px-4 w-full mt-1  placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                   name="partner_first_name" id="partner_first_name" formControlName="partner_first_name"
                   placeholder="First Name"
                   [ngClass]="formGet.partner_first_name.errors && formGet.partner_first_name.touched?'form-input-error':'form-input-focus'"
                   (focusout)="onFocusout($event, 'partner_first_name')"
                   (input)="onChangeInput($event, 'partner_first_name')">
            <p *ngFor="let error of getErrors('partner_first_name')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{ error }}
            </p>
          </div>

          <div class="col-span-12 lg:col-span-6">
            <label for="partner_first_name" class="text-label-grey text-sm text-regular">Last Name*</label>

            <input type="text" [attr.disabled]="!canEdit || undefined"
                   class="h-10 sm:h-12 input py-3 px-4 mt-1 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                   name="partner_last_name" formControlName="partner_last_name" placeholder="Last Name"
                   [ngClass]="formGet.partner_last_name.errors && formGet.partner_last_name.touched?'form-input-error':'form-input-focus'"
                   (focusout)="onFocusout($event, 'partner_last_name')"
                   (input)="onChangeInput($event, 'partner_last_name')">
            <p *ngFor="let error of getErrors('partner_last_name')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{ error }}
            </p>
          </div>
          <div class="col-span-12">
            <label for="email" class="text-label-grey text-sm text-regular">Email Address</label>
            <input type="email" [attr.disabled]="!canEdit || undefined"
                   class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                   placeholder="e.g. marktylor@gmail.com"
                   [ngClass]="formGet?.partner_email?.errors && formGet?.partner_email?.touched?'form-input-error':'form-input-focus'"
                   formControlName="partner_email" name="email" id="email"
                   (focusout)="onFocusout($event, 'partner_email')" (input)="onChangeInput($event, 'partner_email')">
            <p *ngFor="let error of getErrors('partner_email')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{ error }}
            </p>
          </div>
          <div class="col-span-12">
            <label for="phone-self" class="text-label-grey text-sm text-regular">Phone Number</label>
            <app-phone-input [placeholder]="'e.g. 8665044754'" id="phone-self" [disabled]="!canEdit"
                             [phoneNumber]="businessForm.value['partner_phone']" [countryCode]="countryCode"
                             (onChange)="detectChangePhoneNumber($event)"
                             (click)="formGet?.partner_phone.markAsTouched()" uniqueId="phone-self"
                             [errorClassTrigger]="formGet?.partner_phone.errors && formGet?.partner_phone.touched"
                             [form]="businessForm" controlKey="partner_phone"
                             (focusout)="formGet.partner_phone.markAsTouched()">
            </app-phone-input>
            <p *ngFor="let error of getErrors('partner_phone')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{ error }}
            </p>
          </div>
        </div>
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Address
          </h4>
          <p class="text-grey text-regular pt-1 text-sm">Please enter your business’s address.
          </p>
        </div>
        <div class=" col-span-12">
          <label for="country" class=" text-label-grey text-sm text-regular ">Country*</label>
          <div class=" col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('country')">
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <app-country-search-component (optionChangeEmitter)="optionChangeListenerCountry($event)"
                                            [defaultSelected]="formGet?.country?.value" id="country"
                                            [disabled]="!canEdit || !canAccessForm"
                                            [enableError]="formGet.country.errors && formGet.country.touched"></app-country-search-component>
              <p *ngFor="let error of getErrors('country')"
                 class="text-regular font-medium text-xs mt-2 error-alert">
                {{ error }}
              </p>
            </app-shimmer-loading>
          </div>
        </div>
        <div class="col-span-12 relative move-map" #moveMapHere>
          <label for="street-add" class="text-label-grey text-sm text-regular">Street
            Address*</label>
          <input type="text" [attr.disabled]="!canEdit || undefined" id="street-add" #search
                 (input)="onFocusAddress()"
                 (keyup)="onAddressChange(search)" (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null"
                 class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                 name="address" formControlName="address" placeholder="e.g Main St" minlength="3" maxlength="500"
                 [ngClass]="formGet.address.errors && formGet.address.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('address')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
        <div class="col-span-12">
          <label for="apt_number" class="text-label-grey text-sm text-regular">Apartment / Suite #</label>
          <input name="apt_number" id="apt_number" formControlName="apt_number" [attr.disabled]="!canEdit || undefined"
                 class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                 [ngClass]="formGet.apt_number.errors && formGet.apt_number.touched?'form-input-error':'form-input-focus'">
          <p *ngFor="let error of getErrors('apt_number')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
        <div class="col-span-12" *ngIf="selectedCountryName!==unitedKingdom">
          <label for="state-Province" class="text-label-grey text-sm text-regular">State/Province*</label>
          <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('state')">
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <app-state-search-component [countryCode]="formGet?.country?.value" id="state-Province"
                                          [defaultSelected]="formGet?.state?.value"
                                          (optionChangeEmitter)="optionChangeListenerState($event)"
                                          tabindex="0" [disabled]="!canEdit || !canAccessForm"
                                          (focusout)="markAsTouchedIndividual('state')"
                                          [enableError]="formGet.state.errors && formGet.state.touched">
              </app-state-search-component>
            </app-shimmer-loading>
            <p *ngFor="let error of getErrors('state')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{ error }}
            </p>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <label for="city" class="text-label-grey text-sm text-regular">City*</label>
          <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('city')">
            <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
              <app-city-search [countryName]="selectedCountryName" [stateCode]="formGet?.state?.value" id="city"
                               [enableError]="formGet.city.errors && formGet.city.touched"
                               [defaultSelected]="formGet?.city?.value"
                               (optionChangeEmitter)="optionChangeListenerCity($event)" tabindex="0"
                               [disabled]="!canEdit || !canAccessForm">
              </app-city-search>
            </app-shimmer-loading>
            <p *ngFor="let error of getErrors('city')"
               class="text-regular font-medium text-xs mt-2 error-alert">
              {{ error }}
            </p>
          </div>
        </div>
        <div class=" col-span-12 md:col-span-6 ">
          <label for="zipcode" class=" text-label-grey text-sm text-regular ">Zip/Postal Code*</label>
          <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
            <input type="text" [attr.disabled]="!canEdit || undefined" id="zipcode"
                   (focusout)="markAsTouchedIndividual('zipcode')"
                   class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
                   placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                   name="zipcode" formControlName="zipcode" oninput="this.value = this.value.toUpperCase()"
                   (focus)="validateZipCode()"
                   [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
          </app-shimmer-loading>
          <p *ngFor="let error of getErrors('zipcode')"
             class="text-regular font-medium text-xs mt-2 error-alert">
            {{ error }}
          </p>
        </div>
      </div>
      <div class=" flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 ">
        <app-button-loader class="w-full sm:w-auto" *ngIf="!isNewAsset" [buttonText]="'Remove business'"
                           [loader]="submitLoaderDelete" [valid]="!submitLoader && canEdit" [buttonType]="'button'"
                           [outlineOnly]="true" (click)="deleteBusiness()">
        </app-button-loader>
        <app-button-loader class="w-full sm:w-auto" [buttonText]="!isNewAsset?'Update Business':'Add Business'"
                           [valid]="((isNewAsset && permissions?.['add']) || (!isNewAsset && permissions?.['edit'])) && !submitLoaderDelete && canEdit"
                           [loader]="submitLoader" [buttonType]="'submit'">
        </app-button-loader>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>
