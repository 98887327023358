<div class="scroll-hidden">
  <div class="h-screen lg:grid grid-cols-12">
    <!-- BEGIN: Login Form -->
    <div class="col-span-12 md:col-span-6 xl:col-span-5 h-screen lg:h-auto p-6 flex">
      <div class="flex flex-col w-full">
        <!-- BEGIN: Logo -->
        <div class="md:pl-2 flex items-center gap-2 sm:gap-4 flex-row">
          <a (click)="menuChange('')" class="cursor-pointer">
            <img alt="The-Estate-Registry" width="250" [src]="'assets/images/logo-old.png'"
              class="object-contain w-52 lg:w-60">
            <p class="text-regular text-semibold text-orange text-sm lg:text-lg text-center">
              {{this.proTypeText ? this.proTypeText + " Edition" : ""}}
            </p>
          </a>
          <a *ngIf="orgLogoPath" class=" border-l-black pl-6 cursor-pointer max-h-12 maxw128">
            <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
          </a>
          <!-- <div class="flex items-center justify-center gap-2 md:gap-4 h-16 md:h-24">
            <div class="flex items-start gap-1 md:gap-2 flex-col">
              <p *ngIf="orgLogoPath "
                class="whitespace-nowrap text-xs md:text-sm lg:text-regular text-dark-grey">Sponsored by:</p>
              <a (click)="menuChange('')" *ngIf="orgLogoPath" class="cursor-pointer max-h-12 maxw128">
                <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
              </a>
            </div>
          </div> -->
        </div>
        <!-- END: Logo -->
        <div class="h-full flex items-center justify-center mt-4">
          <div class="my-auto mx-auto bg-white pt-8 sm:pl-10 xl:bg-transparent px-5 sm:px-8 w-full">
            <div class="h-full justify-center items-center ">
              <h2 class="font-bold text-darkblue text-lg text-regular text-left tracking-wide">
                {{proTypeText}}
              </h2>

              <hr class="border-blue w-20 mt-1 border-1">
              <div class="mt-3">
                <h2 class="text-darkblue text-2xl md:text-4xl leading-10 text-semibold pt-1">
                  Professional Dashboard Login
                </h2>
              </div>
              <form [formGroup]="loginForm" autocomplete="off" (ngSubmit)="loginUser(captchaRef)">
                <div class="mt-6">
                  <div>
                    <label for="email" class="text-sm pb-2 text-regular text-grey">Email
                      Address</label>
                    <input type="email" name="email" formControlName="email" id="email" autocomplete="new-email"
                      class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                      placeholder="Enter valid email address" maxlength="50"
                      [ngClass]="formGet.email.errors && formGet.email.touched?'form-input-error':'form-input-focus'"
                      (focusout)="onFocusout($event,'email')" (input)="onChange($event,'email')">
                    <!-- BEGIN: Error Alert text -->
                    <div *ngIf="formGet.email.errors && formGet.email.touched">

                      <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.email.errors.pattern">
                        Please enter a valid email address.
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.email.errors.required ">
                        Email is required.
                      </p>
                    </div>
                    <!-- END: Error Alert text -->
                  </div>

                  <div class="mt-4">
                    <label for="pro professional-password" class="text-sm pb-2 text-regular text-grey">
                      Password</label>
                    <div class="relative h-12 text-grey">
                      <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
                        (click)="togglePasswordView = !togglePasswordView">
                        <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
                        <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
                      </a>
                      <input [type]="togglePasswordView?'text':'password'" name="pro professional-password"
                        formControlName="password" id="pro professional-password" autocomplete="new-password"
                        class="input py-3 px-4 mt-1 w-full text-regular text-grey rounded-xs h-12 appearance-none pl-4 border block focus:outline-none"
                        placeholder="Enter Password" (focusout)="onFocusout($event,'password')"
                        (input)="onChange($event,'password')"
                        [ngClass]="formGet.password.errors && formGet.password.touched?'form-input-error':'form-input-focus'">
                      <div *ngIf="formGet.password.errors && formGet.password.touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert"
                          *ngIf="formGet.password.errors.required">Password is required</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex text-grey text-xs sm:text-sm"
                  [ngClass]="(formGet.password.errors && formGet.password.touched)?'mt-8':'mt-4'">
                  <a class="cursor-pointer" (click)="menuChange('forgot-password-request')">Forgot Password?</a>
                </div>
                <!-- <div class=" mt-2 ">
                  <label class="flex items-center">
                    <input type="checkbox" formControlName="agree" id="agree"
                      class="mt-1   h-6 text-regular appearance text-grey  pl-4 border block focus:outline-none" />

                    <span class="ml-2 text-sm mt-1 text-regular text-grey">I agree to the
                      <a (mousedown)="onMouseDownPrivacyPolicy()"
                        class="text-regular font-semibold text-darkblue underline cursor-pointer">
                        Privacy Policy
                      </a>
                      and
                      <a (mousedown)="onMouseDownUserAgreeement()"
                        class="text-regular font-semibold text-darkblue underline cursor-pointer">
                        User Agreement
                      </a>
                    </span>

                  </label>
                </div> -->
                <!-- <div *ngIf="formGet.agree.touched && formGet.agree.errors">
                  <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="!isPrivacyPolicyChecked">
                    Accept Privacy Policy is required</p>

                </div> -->
                <div class="grid grid-cols-12 gap-4">
                  <!-- BEGIN: Recaptcha -->
                  <div class="sm:col-span-6 col-span-12 mt-6">
                    <div class="recaptcha h-15">
                      <re-captcha #captchaRef="reCaptcha" (resolved)="resolvedCaptcha($event)"
                        (error)="onErrorCaptcha($event)" errorMode="handled" [siteKey]="googleCaptchaKey"></re-captcha>
                    </div>
                    <div *ngIf="isRecaptchaValidationEnable">
                      <p *ngIf="!isCaptchaValid" class="text-regular font-medium text-xs error-alert">GOOGLE
                        Recaptcha is required</p>
                    </div>
                  </div>
                <!-- END: Recaptcha -->

                <!-- BEGIN: Form Register Button -->
                <div class="sm:col-span-6 col-span-12 mt-6 sm:flex sm:flex-row flex-none  text-center">
                  <div class="ml-auto mt-4 md:mt-0">
                    <button type="submit"
                      class="flex items-center justify-between button button--lg w-full bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-4">
                      <div class="loading-red mr-2" *ngIf="loginLoader"></div>
                      <div>Login</div>
                    </button>
                  </div>
                </div>
                <!-- END: Form Register Button -->
                </div>
              </form>
            </div>
            <!-- <p class="text-regular text-grey text-center py-6 text-xs">
              By creating an account you agree to our
              <a (click)="onMouseDownPrivacyPolicy()"
                class="text-regular font-semibold text-darkblue underline cursor-pointer">
                Privacy Policy
              </a>
              and
              <a (click)="onMouseDownUserAgreeement()"
                class="text-regular font-semibold text-darkblue underline cursor-pointer">
                User Agreement
              </a>
              .Please note that these documents contain important information regarding the terms and conditions
              under
              which we provide our Digital Vault Services
            </p> -->
            <ng-container *ngIf="supportPhoneNumber && supportEmail">
              <div class="flex flex-col md:flex-row my-1 mt-3">
                <p class="text-regular text-grey text-sm tracking-wide ">
                  Technical Support Team, call:
                </p>
                <a href="tel:{{supportPhoneNumber}}"
                  class="w-3/4 text-sm md:w-auto ml-2 cursor-pointer text-darkblue text-regular font-semibold hover:underline hover:text-orange-600">
                  {{supportPhoneNumber}}
                </a>
              </div>
              <div class="flex flex-col md:flex-row my-2">
                <p class="text-regular text-grey text-sm tracking-wide">
                  Or email us at:
                </p>
                <a href="mailto:{{supportEmail}}"
                  class="w-3/4 text-sm md:w-auto ml-2 cursor-pointer text-darkblue text-regular font-semibold hover:underline hover:text-orange-600">
                  {{supportEmail}} </a>
              </div>
              <div class="flex flex-col md:flex-row my-1">
                <p class="text-content text-grey text-sm tracking-wide">
                  Hours of Operation:
                </p>
                <span class="w-3/4 text-sm md:w-auto ml-1 text-darkblue text-regular font-semibold">
                   Monday to Friday 9am to 5pm EST </span>
              </div>
            </ng-container>
          </div>
        </div>

      </div>
    </div>
    <!-- END: Login Form -->

    <!-- BEGIN: Login Info -->
    <div class="md:col-span-6 xl:col-span-7 hidden lg:flex bg-login-grey min-h-screen justify-center">
      <div class="fixed h-full flex flex-col items-center justify-center">
        <!-- BEGIN: First Slide -->
        <app-right-carousel-intro></app-right-carousel-intro>
      </div>
    </div>
    <!-- END: Login Info -->
  </div>
</div>
<app-common-modal id="user-logged-in" class="app-common-modal">
  <h2 class="text-xl lg:text-2xl font-sans font-medium highlighted-text truncate mr-5">
    You are already logged in
  </h2>
  <div class="flex flex-row items-center">
    <p class="font-sans text-lg mt-2">
      Redirecting to Dashboard
    </p>
    <div class="loading-red ml-2 mt-2"></div>
  </div>
</app-common-modal>