<div class="scroll-hidden background-color-grey">
  <app-navbar *ngIf="userType === 'Consumer'"></app-navbar>
  <app-exe-cus-navbar *ngIf=" userType === 'Custodian'"></app-exe-cus-navbar>

  <!--app-professional-navbar *ngIf="this.userType !== 'Consumer'"></app-professional-navbar-->
  <div class="grid grid-cols-12 px-12 gap-4 sm:gap-6 md:gap-8 p-6 md:p-8 lg:px-12 xl:px-24">
    <!-- BEGIN: Leftside Content -->
    <div class="col-span-12 lg:col-span-7">
      <div class="w-full">
        <h2 class="text-darkblue text-xl lg:text-2xl xl:text-4xl uppercase text-semibold pt-1">Payment Details</h2>
        <p class="text-md text-grey py-2 tracking-wide text-regular">Complete payment by providing payment details
        </p>
        <p class="border-b-8 w-24 border-red mb-3"></p>
      </div>
    </div>

    <div class="col-span-12 lg:col-span-7 space-y-8">
      <!-- Saved Cards -->
      <div class="border-card bg-white rounded-md" *ngIf="this.cards?.length">
        <div class="px-4 px-6 my-4">
          <div class="grid grid-cols-12 gap-2 items-center">
            <div class="col-span-11">
              <div class="flex items-center justify-between gap-4">
                <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                  Saved Cards
                </h4>
                <!-- Manage cards to navigate saved card page -->
                <button (click)="navigateToSavedCards()"
                class="flex items-center justify-center w-auto whitespace-no-wrap underline py-2 text-orange rounded text-sm tracking-wide text-regular font-semibold text-center cursor-pointer col-span-3">
                  Manage Cards
                </button>
              </div>
            </div>
            <div class="col-span-1 ml-auto cursor-pointer" (click)="toggleSectionBody = !toggleSectionBody">
              <img src="assets/images/dashboard/angle-up-arrow.png" *ngIf="!toggleSectionBody" class="w-4 float-right"
                alt="digital vault">
              <img src="assets/images/dashboard/angle-down-arrow.png" *ngIf="toggleSectionBody" class="w-4 float-right"
                alt="digital vault">
            </div>

          </div>
          <ng-container *ngFor="let card of cards;let i = index">
            <ng-container *ngIf="!toggleSectionBody && card['is_default'] == 1"> <!-- 1 is for default card -->
              <div class="w-full my-3">
                <div class="flex flex-col sm:flex-row gap-3 sm:items-center justify-between">
                  <div class="sm:flex">
                    <div class="flex items-center gap-3">
                      <img src="assets/images/payment/visa..svg" class="w-6 h-6 mr-4" alt="visacard">
                    </div>
                    <p class="text-regular text-black font-normal">XXXX XXXX XXXX {{card['card_last_four']}}</p>
                  </div>

                  <div class="mr-3">
                    <p>{{card['exp_date']}} </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="w-full my-5">
            <div class="flex flex-col sm:flex-row items-center justify-between gap-4">
                <a (click)="disableCardScreen()"
                  class="cursor-pointer flex items-center justify-center gap-4 w-full sm:w-auto whitespace-no-wrap px-8 py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn-one text-center cursor-pointer"><img
                    src="assets/images/payment/barrow-red.svg" class="w-3 h-3 sm:w-4 sm:h-4" alt="digital vault">Back</a>
                <button type="submit" (click)="initiatePayment()" [disabled]="isLoading"
                  class="flex items-center justify-center w-full sm:w-auto whitespace-no-wrap px-8 py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn text-center cursor-pointer ">
                  <span *ngIf="!isLoading">Pay {{total | currency}} </span>
                  <span *ngIf="isLoading">Loading...</span>
                </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Card Details -->
      <div class="border-card bg-white rounded-md" *ngIf="!this.cards?.length">
        <div class="px-4 px-6 my-4">
          <div class="grid grid-cols-12 gap-2 items-center">
            <div class="col-span-10">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Enter Card Details
              </h4>
            </div>
            <div class="col-span-2 cursor-pointer" (click)="toggleSectionBodys = !toggleSectionBodys">
              <img src="assets/images/dashboard/angle-up-arrow.png" *ngIf="!toggleSectionBodys"
                class="w-4 float-right" alt="digital vault">
              <img src="assets/images/dashboard/angle-down-arrow.png" *ngIf="toggleSectionBodys"
                class="w-4 float-right" alt="digital vault">
            </div>

          </div>
          <ng-container *ngIf="!toggleSectionBodys">
            <div class="w-full my-3">
              <form [formGroup]="paymentForm" (ngSubmit)="initiatePayment()" autocomplete="off">
                <div class="grid grid-cols-12 gap-4 md:gap-6">
                  <div class="col-span-12">
                    <label for="card_number" class="text-label-grey text-sm text-regular mb-2">Card Number</label>
                    <input type="text" [mask]="this.cardMask" formControlName="card_number" maxlength="19"
                      name="card_number" placeholder="e.g. 5129 1330 6701 0096 " id="card_number"
                      (keyup)="onInputKeyUp($event)"
                      class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 relative"
                      [ngClass]="formGet.card_number.errors && formGet.card_number.touched?'form-input-error':'form-input-focus'">
                    <div *ngIf="formGet.card_number.errors && formGet.card_number.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.card_number.errors.required">Card Number is required
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.card_number.errors.minlength">Enter valid card number
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.card_number.errors.maxlength">Enter valid card number
                      </p>
                    </div>
                  </div>

                  <div class="col-span-12 lg:col-span-4">
                    <label for="exp_month" class="text-label-grey text-sm text-regular mb-2">Expiry Month</label>
                    <input type="text" mask="00" maxlength="2" max="12" formControlName="exp_month" name="exp_month"
                      placeholder="e.g. 06" id="exp_month" (keyup)="onInputKeyUp($event)"
                      class="h-10 sm:h-12 input py-4 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                      [ngClass]="formGet.exp_month.errors && formGet.exp_month.touched?'form-input-error':'form-input-focus'">
                    <div *ngIf="formGet.exp_month.errors && formGet.exp_month.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.exp_month.errors.required && formGet.exp_month.touched "> Expiry Month is
                        required</p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="!formGet.exp_month.errors.required&&formGet.exp_month.errors.minlength && formGet.exp_month.touched && !formGet.exp_month.errors.isNotAllowed">
                        Expiry Month must
                        have 2 numbers</p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="!formGet.exp_month.errors.required&&formGet.exp_month.errors.isNotAllowed && formGet.exp_month.touched">
                        Please enter valid month
                      </p>
                    </div>

                  </div>
                  <div class="col-span-12 lg:col-span-4">
                    <label for="exp_year" class="text-label-grey text-sm text-regular mb-2">Expiry Year</label>
                    <input type="text" maxlength="4" [mask]="this.yearMask" formControlName="exp_year"
                      (keyup)="onInputKeyUp($event)" name="first_name" placeholder="e.g. 2022" id="exp_year"
                      class="h-10 sm:h-12 input py-4 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                      [ngClass]="formGet.exp_year.errors && formGet.exp_year.touched?'form-input-error':'form-input-focus'">
                    <div *ngIf="formGet.exp_year.errors && formGet.exp_year.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.exp_year.errors.required && formGet.exp_year.touched"> Expiry Year is required
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.exp_year.errors.isNotAllowed">Please enter valid year
                      </p>
                    </div>
                  </div>
                  <div class="col-span-12 lg:col-span-4">
                    <label for="sec_code" class="text-label-grey text-sm text-regular mb-2">Security Code</label>
                    <input formControlName="cvv" type="text" maxlength="4" name="sec_code" placeholder="e.g. 065"
                      mask="0*" id="sec_code" (keyup)="onInputKeyUp($event)"
                      class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                      [ngClass]="formGet.cvv.errors && formGet.cvv.touched?'form-input-error':'form-input-focus'">
                    <div *ngIf="formGet.cvv.errors && formGet.cvv.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.cvv.errors.required && formGet.cvv.touched"> Security Code is required</p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.cvv.errors.isNotAllowed">Please enter valid security code</p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.cvv.errors.minlength">Please enter valid security code</p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                        *ngIf="formGet.cvv.errors.maxlength">Please enter valid security code</p>
                    </div>
                  </div>
                  <div class="col-span-12 md:col-span-8">
                    <label for="card_name" class="text-label-grey text-sm text-regular mb-2">Card Holder Name</label>
                    <input type="text" name="card_name" id="card_name" placeholder="Full Name"
                      formControlName="card_name" maxlength="50"
                      class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                      [ngClass]="formGet.card_name.errors && formGet.card_name.touched?'form-input-error':'form-input-focus'">
                    <div *ngIf="formGet['card_name'].errors && formGet['card_name'].touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                         *ngIf="formGet['card_name'].errors['pattern']">
                        Contains invalid characters
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                         *ngIf="formGet['card_name'].errors['required']">
                        Name is required
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                         *ngIf="formGet['card_name'].errors['minlength'] && !formGet['card_name'].errors['pattern']">
                        Name must contain at least 3 characters
                      </p>
                      <p class="text-regular font-medium text-xs mt-2 error-alert"
                         *ngIf="formGet['card_name'].errors['maxlength'] && !formGet['card_name'].errors['pattern']">
                        Name must not exceed 50 characters
                      </p>
                    </div>
                  </div>
                    <div class="col-span-12 md:col-span-4">
                      <label for="zipcode" class=" text-label-grey text-sm text-regular ">Zip/Postal Code</label>
                      <input type="text"
                        class=" h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700 "
                        placeholder="Enter Zipcode" name="zipcode" id="zipcode" formControlName="zipcode"
                        oninput="this.value = this.value.toUpperCase()"
                        [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
                      <div *ngIf="formGet.zipcode.errors && formGet.zipcode.touched">
                        <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.zipcode.errors.pattern">Invalid zipcode
                        </p>
                        <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.zipcode.errors.maxlength">Maximum length
                          is 10</p>
                      </div>
                    </div>
                  <!-- <div class="col-span-12">
                    <div class="w-full">
                        <label class="flex items-center gap-3">
                          <input name="defaultCard" type="checkbox" class="form-checkbox w-6 h-6">
                          <p class="text-label-grey text-base text-regular">Make this my default payment method</p>
                        </label>
                    </div>
                  </div> -->
                </div>

                <div class="w-full my-5">
                  <div class="flex flex-col sm:flex-row items-center justify-between gap-4">
                      <a (click)="disableCardScreen()"
                        class="cursor-pointer flex items-center justify-center gap-4 w-full sm:w-auto whitespace-no-wrap px-8 py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn-one text-center cursor-pointer">
                        <img src="assets/images/payment/barrow-red.svg" class="w-3 h-3 sm:w-4 sm:h-4" alt="digital vault">
                        Back
                      </a>
                      <button type="submit" [disabled]="paymentForm.invalid || isLoading"
                        class="flex items-center justify-center w-full sm:w-auto whitespace-no-wrap px-8 py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn text-center cursor-pointer {{(paymentForm.invalid)?'opacity-50':''}} ">
                        <span *ngIf="!isLoading">Pay {{total | currency}}</span>
                        <span *ngIf="isLoading">Loading...</span>
                      </button>
                  </div>
                </div>
              </form>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- END: Leftside Content -->

    <!-- BEGIN: Rightside Content -->
    <div class="col-span-12 lg:col-span-5 bg-white rounded-md border-2 border-grey-600 space-y-8" *ngIf="!transferVaultScreen">
      <div class="w-full">
        <div class=" p-6 ">
          <h3
            class="text-darkblue xl:text-xl lg:text-xl md:text-xl text-semibold border-b pb-3 w-full border-grey-500">
            Payment Summary</h3>

          <div class="flex item-center justify-between my-4" *ngIf="userType!=='Custodian'">
            <h3 class="text-base text-grey  tracking-wide text-regular">Digital Vault Subscription</h3>
            <p class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular">
              ${{this.monthlySubscription}}</p>
          </div>
          <div *ngIf="isTransferDigitalWallet" class="flex item-center justify-between my-4">
            <h3 class="text-base text-grey  tracking-wide text-regular">Transfer Digital Vault</h3>
            <p class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular">
              ${{vaultTransferAmount}}</p>
          </div>

          <div *ngIf="isPromoCodeApplied" class="flex item-center justify-between my-4">
            <h3 class="text-base text-grey  tracking-wide text-regular">Discount Price</h3>
            <p class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular">
            - {{discountAmount | currency}}</p>
          </div>

          <div class="border-b w-full border-grey-500"></div>
          <div class="flex item-center justify-between my-4">
            <h3 class="text-base text-grey  tracking-wide text-regular">Total</h3>
            <p class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular">{{total | currency}}</p>
          </div>

        </div>
      </div>
      <!-- <div class="w-full mb-8">
        <div class="bg-white rounded-md border-2 border-grey-600 p-6 ">
          <div class="flex items-center justify-start">
            <div>
              <img src="assets/images/payment/dlb.svg" alt="digital vault" class="w-20 h-20">
            </div>
            <div class="pl-4">
              <h3 class="text-grey text-lg font-light mb-2">{{userType==='Consumer' ? 'Your':'User\'s'}} LegacyNOW
                Digital Vault ID</h3>
              <p class="text-md lg:text-xl text-darkblue tracking-wide font-semibold text-regular">{{digitalVaultId}}
              </p>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="col-span-12 md:col-span-5 space-y-8" *ngIf="transferVaultScreen">
      <div class="w-full">
        <div class="bg-white rounded-md border-2 border-grey-600 p-6 ">
          <h3
            class="text-darkblue xl:text-xl lg:text-xl md:text-xl text-semibold border-b pb-3 w-full border-grey-500">
            Payment Summary</h3>

          <div class="flex item-center justify-between my-4">
            <h3 class="text-base text-grey  tracking-wide text-regular">Transfer Digital Vault</h3>
            <p class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular">
              ${{vaultTransferAmount}}</p>
          </div>
          <div class="border-b w-full border-grey-500"></div>
          <div class="flex item-center justify-between my-4">
            <h3 class="text-base text-grey  tracking-wide text-regular">Total</h3>
            <p class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular">
              ${{vaultTransferAmount}}</p>
          </div>

        </div>
      </div>
      <div class="w-full">
        <div class="bg-white rounded-md border-2 border-grey-600 p-6 ">
          <div class="flex items-center justify-start">
            <div>
              <img src="assets/images/payment/dlb.svg" alt="digital vault" class="w-20 h-20">
            </div>
            <div class="pl-4">
              <h3 class="text-base text-grey  tracking-wide text-regular mb-2">{{userType==='Consumer' ? 'Your':'User\'s'}} LegacyNOW
                Digital Vault ID</h3>
              <p class="text-md lg:text-xl text-darkblue tracking-wide font-semibold text-regular">{{digitalVaultId}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Rightside Content -->
  </div>
</div>
<app-payment-processing></app-payment-processing>
<app-payment-failure></app-payment-failure>
