import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { UserService } from 'src/app/services/user.service';
import { dashboardSectionDetails } from 'src/app/viewData/dashboard.data';
import { LOCAL_STORAGE_UPDATE_STATUS, PAYMENT_STATUS, USER_TYPES } from 'src/constants/application.const';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
/**
 * Dashboard component
 */
export class DashboardComponent implements OnInit {
  private loadingItems: { dataLoading: boolean, sectionLoading: boolean } = {
    dataLoading: false,
    sectionLoading: false,
  };
  public readonly dashboardSectionDetails = dashboardSectionDetails;
  public readonly paymentStatusData = PAYMENT_STATUS;
  public userDetails: any = {};
  public isLoading: boolean;
  public userType: string;
  public clientRequestId: string;
  public userId: string;
  public isProfessional: boolean = false;
  public clientName: string;
  public requestUniqueId: string;
  public clientData: any;
  public dashboardData: any;
  public paymentStatusProfessional: boolean;
  // eslint-disable-next-line camelcase
  public sectionStatus: { [key: string]: { section_name: string, status: number, updated_at: string, created_at: string } };
  public FreeTrialEnabledFromAdmin: any;
  public queryParamData: any;
  public vaultVideo: string;
  public paymentStatus: number;
  private currentUserDetails: any;

  /**
   * @constructor
   */
  constructor(
    private personalDetailsService: PersonalDetailsService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private slugInterceptorService: SlugInterceptorService,
    private modalService: CommonModelService,
    private userService: UserService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.userType = this.localStorageService.getDataByKey('role');
    this.clientRequestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.currentUserDetails = this.localStorageService.getUserData();
    this.userId = this.currentUserDetails?.user?.id;
    this.getDashboardData();
    this.updateUserRequestDetails();
    this.FreeTrialEnabledFromAdmin = String(this.localStorageService.getDataByKey('form_access'));
    this.paymentStatus = this.localStorageService.getDataByKey('is_payment_complete');
    this.route.queryParams.subscribe((params) => {
      this.queryParamData = params;
    });
  }

  /**
   *
   * @param{string} loader
   * @param{boolean} value
   */
  private setLoading(loader: string, value: boolean) {
    this.loadingItems[loader] = value;
    this.isLoading = this.loadingItems.dataLoading || this.loadingItems.sectionLoading;
  }

  /**
   * get user details
   */
  public getDashboardData() {
    this.setLoading('sectionLoading', true);
    this.setLoading('dataLoading', true);
    this.personalDetailsService.getSectionCompletionStatus(this.clientRequestId).subscribe({
        next: (response) => this.sectionStatus = response,
        error: (e) => this.commonHelper.httpResponseHandler(e.error),
      },
    );

    // Get Personal details
    const isConsumer = this.userType === 'Consumer';
    const isProfessional = this.userType === 'Professional' || this.userType === 'Advisor';
    const reqID = isProfessional ? this.clientRequestId : undefined;
    if (isConsumer || isProfessional) {
      this.personalDetailsService.getDashboardDetails(reqID).subscribe((response: APIResponseModel) => {
        this.isProfessional = false;
        if (response.status) {
          this.userDetails = response.data;
          this.requestUniqueId = this.userDetails.request_unique_id;
          this.localStorageService.storeData('enableVaultCustodian', this.userDetails.inform_exec_permission);
          this.personalDetailsService.sendPersonalDetails(response.data);
          this.dashboardData = response.data;
          if (isProfessional) {
            this.clientData = this.userDetails;
            this.paymentStatusProfessional = this.userDetails.is_payment_complete === 1;
            this.clientName = this.userDetails.user.first_name + ' ' + this.userDetails.user.last_name;
          } else {
            this.getPaymentStatus();
          }
        }
      }, (exception: any) => {
        this.isProfessional = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      }, () => this.setLoading('dataLoading', false));
    } else {
      void this.slugInterceptorService.navigate(['/']);
      this.localStorageService.clearLocalStorage();
      this.localStorageService.clearRole();
    }
    this.setLoading('sectionLoading', false)
  }

  /**
   * Get payment status
   */
  public getPaymentStatus() {
    const isConsumer = this.userType === 'Consumer';
    if (isConsumer) {
      this.localStorageService.storeData('payment_alert_called', 0); // need alert message in this section, so make 0 to have another alert
      this.commonHelper.checkPaymentStatus(); // check vault creation fee paid or not, payment instruction modal will be called once after login
    }
  }

  /**
   * change route
   */
  public navigateToPro() {
    void this.slugInterceptorService.navigate(['dashboard'], null, true);
  }

  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');

  }

  /**
   * Update user request details
   */
  private updateUserRequestDetails():void {
    const isConsumer = this.userType === 'Consumer';
    if(!this.currentUserDetails?.request?.id && isConsumer) {
      this.userService.getCurrentUserDetails().subscribe({
        next: (response: APIResponseModel) => {
          console.log(response);
          const user = response?.data;
          const userRequest = user['user_request'];
          if(userRequest?.id) {
            this.requestUniqueId = userRequest?.request_unique_id;
            this.localStorageService.updateUserData(USER_TYPES.user, {
            key: 'request',
            updateValue: {
              id: userRequest?.id,
              request_stage: userRequest?.request_stage,
              is_payment_complete: user?.is_payment_complete,
            },
            type: LOCAL_STORAGE_UPDATE_STATUS.S,
          });
          }
         }
      });
    }
  }
}
