<div class="scroll-hidden">
  <div class="h-screen lg:grid grid-cols-12">
    <!-- BEGIN: Login Form -->
    <div class="col-span-12 md:col-span-6 xl:col-span-5 h-screen lg:h-auto p-6 flex">
      <div class="flex flex-col w-full">
        <!-- BEGIN: Logo -->
        <div class="md:pl-2 flex items-center gap-2 sm:gap-4 h-16 flex-row">
          <a (click)="onClickLogo()" class="flex items-center cursor-pointer">
            <img alt="The-Estate-Registry" width="250" [src]="'assets/images/logo-old.png'" class="object-contain w-40 sm:w-48">
          </a>
          <a *ngIf="orgLogoPath"  class=" border-l-black pl-6 cursor-pointer max-h-12 maxw128">
            <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">
          </a>
          <!-- <div class="flex items-center gap-2 md:gap-4 flex-col" *ngIf="orgLogoPath">
            <p class="whitespace-nowrap text-xs md:text-sm lg:text-regular text-dark-grey">Sponsored by:</p>

            <a *ngIf="orgLogoPath" class="cursor-pointer max-h-12 maxw128">
              <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" src="{{orgLogo}}">

            </a>
          </div> -->
        </div>
        <!-- END: Logo -->
        <div class="mt- pt-5 flex items-center justify-center">
          <div class="my-auto mx-auto bg-white pt-1 sm:pl-10 xl:bg-transparent px-5 sm:px-8 w-full">
            <div class="justify-center items-center ">
              <div class="mt-14">
                <p class="text-regular text-grey text-base">
                  Enter OTP which is sent to your phone</p>
                <h2 class="mt-6 text-darkblue text-4xl text-semibold pt-1">
                  LegacyNOW <br> Digital Vault</h2>
              </div>
              <form [formGroup]="verifyOtpForm" autocomplete="off" (ngSubmit)="verifyOtp()">
                <div class="mt-6">
                  <div>
                    <label for="otp" class="text-sm pb-2 text-regular text-grey">
                      Email Address</label>
                    <input type="email" name="email" formControlName="email" id="email" tabindex="1"
                      class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                      placeholder="Enter your Email address" maxlength="50" (keyup.enter)="keyEnterEmail()"
                      [ngClass]="formGet.email.errors && formGet.email.touched?'form-input-error':'form-input-focus'">
                    <div *ngIf="formGet.email.errors && formGet.email.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.email.errors.pattern">
                        Please enter a valid email address.</p>
                    </div>
                  </div>
                  <div>
                    <label for="otp" class="text-sm pb-2 text-regular text-grey">One Time Password</label>
                    <input type="text" name="otp" formControlName="otp" id="otp" tabindex="2"
                      class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none pl-4 border block focus:outline-none"
                      placeholder="Enter One Time Password" maxlength="6" (keyup.enter)="verifyOtp()"
                      [ngClass]="formGet.email.errors && formGet.email.touched?'form-input-error':'form-input-focus'">
                    <div *ngIf="formGet.email.errors && formGet.email.touched">
                      <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.email.errors.pattern">
                        Please enter valid otp.</p>
                    </div>
                  </div>
                </div>
                <!-- BEGIN: Form Register Button -->
                <div class="mt-6 md:flex md:flex-row text-center flex-col-reverse items-center">
                  <div class="text-grey text-regular text-xs rounded p-2 px-4 border-darkgrey">Didn't
                    receive OTP?
                    <br>
                    <button type="button" tabindex="4" class="text-darkblue font-medium underline cursor-pointer"
                      [ngClass]="(resendOtpTimer)?'opacity-50':''" [disabled]="resendOtpTimer" (click)="resendEmail()">
                      <div>Resend OTP<span *ngIf="resendOtpTimer">&nbsp;in {{resendOtpTimer}}s</span></div>
                    </button>
                  </div>
                  <div class="ml-auto mt-4 md:mt-0">
                    <button type="button" tabindex="3"
                      class="relative button button--lg md:w-auto w-full bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-6 flex items-center"
                      [ngClass]="(!verifyOtpForm.valid )?'opacity-50':''" [disabled]="!verifyOtpForm.valid"
                      (click)="verifyOtp()">
                      <div class="loading-red mr-2" *ngIf="submitLoader"></div>
                      <div>Verify OTP</div>
                    </button>
                  </div>
                </div>
                <!-- END: Form Register Button -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Login Form -->

    <!-- BEGIN: Login Info -->
    <div class="md:col-span-6 xl:col-span-7 hidden lg:flex bg-login-grey min-h-screen justify-center">
      <div class="fixed h-full flex flex-col items-center justify-center">
        <!-- BEGIN: First Slide -->
        <app-right-carousel-intro></app-right-carousel-intro>
      </div>
    </div>
    <!-- END: Login Info -->
  </div>
</div>
