<app-common-modal id="account-list-popup" class="app-common-modal">
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg" class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt="" (click)="closeModel()">
    <div class="p-6">
      <div class="pt-4 pb-6 border-b">
        <h2 class="text-2xl font-sans text-darkgrey">Select Your Bank</h2>
        <div class="relative h-12 text-grey mt-4">
          <div class="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
            <fa-icon [icon]="faSearch" class="fas fa-search w-4 text-grey"></fa-icon>
          </div>
          <input type="search" name="search" id="searchBank"
            class="input py-3 px-4 mt-1 w-full text-regular text-grey rounded-xs h-12 appearance-none pl-12  border block focus:outline-none"
            placeholder="Search" [(ngModel)]="searchKeyword">
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 py-5 item-center border-b" *ngFor="let data of accountList | textFilterList:[searchKeyword,'name']; let i = index">
        <div class="col-span-2">
          <div>
            <img src="assets/images/accounts-assets/default-bank.png" alt="">
          </div>
        </div>
        <div class="col-span-10">
          <div>
            <h4 class="text-xl font-medium font-sans text-grey">{{data?.name}}</h4>
            <p class="text-sm font-sans text-label-grey">{{data?.['balances']?.['iso_currency_code']}} {{data?.['balances']?.['available'] || 0}}</p>
          </div>
        </div>
      </div>

    </div>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>
