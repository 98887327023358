<div>
  <div class="grid grid-cols-12 gap-6 pt-8">
    <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="initialDataLoader">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-8">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Annuities
          </h4>
        </div>
      </div>
      <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4">
        <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Insurance Type
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Insurance Company
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Owner
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Insured Person
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Policy Type
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Policy Number
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Start Date
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              End Date
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Insurance Agent
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Agent Contact Info
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="initialDataLoader">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-8">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Health Insurance
          </h4>
        </div>
      </div>
      <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4">
        <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Insurance Type
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Insurance Company
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Owner
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Insured Person
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Policy Type
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Policy Number
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Start Date
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              End Date
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Insurance Agent
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
          <div class="col-span-12 md:col-span-6">
            <h4 class="text-label-grey text-sm tracking-wide text-regular">
              Agent Contact Info
            </h4>
            <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="permissionStatus">
    <div class="grid grid-cols-12 gap-6 pt-8">
      <!-- Annuities Overview -->
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="initialDataLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Annuities
            </h4>
          </div>
        </div>
        <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Company
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Owner
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insured Person
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Number
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Start Date
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                End Date
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Agent
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Agent Contact Info
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!initialDataLoader && annuitiesDetails?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Annuities
              </h4>
            </div>
            <div class="col-span-4">
              <p *ngIf="permissionEdit && (insuranceDetails?.length || 0)"
                class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                <img src="assets/images/about-yourself/edit.svg" *ngIf="!isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
                  class="w-4 ml-4 float-right cursor-pointer" alt="digital vault" />
              </p>
            </div>
          </div>
          <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4"
            *ngFor="let data of annuitiesDetails; let i = index">
            <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
              <div class="col-span-12">
                <hr class="divider w-full" *ngIf="i" />
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Type
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_type']?.name }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Owner
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['owner'] }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insured Person
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insured_person'] }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Amount Invested
                </h4>
                <p [ngClass]="+data?.amount > 0 ? 'text-darkgrey' : 'text-darkblue' " class="text-base text-regular tracking-wide">
                  {{ +data?.amount > 0 ? (data?.amount | currency) : 'Not Available' }}
                </p>                
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Market Value
                </h4>
                <p [ngClass]="+data?.market_value > 0 ? 'text-darkgrey' : 'text-darkblue' "
                  class="text-base text-regular tracking-wide">
                  {{ +data?.market_value > 0 ? (data?.market_value | currency) : 'Not Available' }}
                </p> 
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Benefit Base
                </h4>
                <p [ngClass]="+data?.benefit_base > 0 ? 'text-darkgrey' : 'text-darkblue' "
                  class="text-base text-regular tracking-wide">
                  {{ +data?.benefit_base > 0 ? (data?.benefit_base | currency) : 'Not Available' }}
                </p> 
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Income Benefit
                </h4>
                <p [ngClass]="+data?.income_benefit > 0 ? 'text-darkgrey' : 'text-darkblue' "
                  class="text-base text-regular tracking-wide">
                  {{ +data?.income_benefit > 0 ? (data?.income_benefit | currency) : 'Not Available' }}
                </p> 
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Death Benefit
                </h4>
                <p [ngClass]="+data?.death_benefit > 0 ? 'text-darkgrey' : 'text-darkblue' "
                  class="text-base text-regular tracking-wide">
                  {{ +data?.death_benefit > 0 ? (data?.death_benefit | currency) : 'Not Available' }}
                </p> 
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Primary Beneficiary
                </h4>
                <ng-container *ngFor="let item of indexHandler(data['people'], 1);index as i;">
                  <p class="text-darkgrey text-base text-regular tracking-wide">
                    {{i+1}}. {{ item?.['first_name'] }} {{ item?.['last_name'] }}
                    {{ item?.['beneficiary_trustee_detail']?.['trust_name'] }} ({{item?.['pivot']?.['percentage']}}%)
                  </p>
                </ng-container>
                <p *ngIf="indexHandler(data['people'],1).length === 0" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 *ngIf="i==0" class="text-label-grey text-sm tracking-wide text-regular">
                  Secondary Beneficiary
                </h4>
                <ng-container *ngFor="let item of indexHandler(data['people'], 2);index as i;">
                  <p class="text-darkgrey text-base text-regular tracking-wide">
                    {{i+1}}. {{ item?.['first_name'] }} {{ item?.['last_name'] }}
                    {{ item?.['beneficiary_trustee_detail']?.['trust_name'] }} ({{item?.['pivot']?.['percentage']}}%)
                  </p>
                </ng-container>
                <p *ngIf="indexHandler(data['people'],2).length === 0" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Spousal Continuation
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['spousal_continuation'] === 1? 'Yes' : 'No'}}
                </p>
                <p *ngIf="!data?.['spousal_continuation']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Notes
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['notes'] }}
                </p>
                <p *ngIf="!data?.['notes']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!initialDataLoader && annuitiesDetails?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Annuities
            </h4>
          </div>
          <div class="col-span-4">
            <p *ngIf="permissionEdit"
            class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
            <img src="assets/images/about-yourself/edit.svg"  *ngIf="!isAccessingVault && !isVaultClaimed"
              (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
              class="w-4 ml-4 float-right cursor-pointer" alt="add details" />
          </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display 
          </h4>
        </div>
      </div>
      <!-- Health Insurance Overview -->
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="initialDataLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Health Insurance
            </h4>
          </div>
        </div>
        <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Company
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Owner
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insured Person
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Number
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Start Date
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                End Date
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Agent
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Agent Contact Info
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!initialDataLoader && healthInsuranceDetails?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Health Insurance
              </h4>
            </div>
            <div class="col-span-4">
              <p *ngIf="permissionEdit && (insuranceDetails?.length || 0)"
                class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                <img src="assets/images/about-yourself/edit.svg" *ngIf="!isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
                  class="w-4 ml-4 float-right cursor-pointer" alt="digital vault" />
              </p>
            </div>
          </div>
          <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4"
            *ngFor="let data of healthInsuranceDetails; let i = index">
            <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">

              <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full" />
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Type
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_type']?.name }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Company
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['company_name'] }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Amount
                </h4>
                <p [ngClass]="+data?.amount > 0 ? 'text-darkgrey' : 'text-darkblue' " class="text-base text-regular tracking-wide">
                  {{ +data?.amount > 0 ? (data?.amount | currency) : 'Not Available' }}
                </p> 
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Policy Number
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['policy_number'] }}
                </p>
                <p *ngIf="!data?.['policy_number']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>     
               </div>

              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Start Date
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['start_date'] | date: domainViewDateFormat }}
                </p>
                <p *ngIf="!data?.['start_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  End Date
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['end_date'] | date: domainViewDateFormat }}
                </p>
                <p *ngIf="!data?.['end_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Agent
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_agent_name'] }}
                </p>
                <p *ngIf="!data?.['insurance_agent_name']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Agent Contact Info
                </h4>
                <p *ngIf="data?.['insurance_agent_contact']" class="text-darkgrey text-base text-regular tracking-wide">
                  +{{data?.['country_code']}} {{ data?.['insurance_agent_contact'] }}
                </p>
                <p *ngIf="!data?.['insurance_agent_contact']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>

            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6"
        *ngIf="!initialDataLoader && healthInsuranceDetails?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Health Insurance
            </h4>
          </div>
          <div class="col-span-4">
            <p *ngIf="permissionEdit"
            class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
            <img src="assets/images/about-yourself/edit.svg"  *ngIf="!isAccessingVault && !isVaultClaimed"
              (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
              class="w-4 ml-4 float-right cursor-pointer" alt="add details" />
          </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
      <!--Begin: LongTerm Care overview -->
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="initialDataLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Long Term Care
            </h4>
          </div>
        </div>
        <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Company
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Owner
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insured Person
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Number
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Agent
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Agent Contact Info
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!initialDataLoader && longTermCareDetails?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Long Term Care
              </h4>
            </div>
            <div class="col-span-4">
              <p *ngIf="permissionEdit && (insuranceDetails?.length || 0)"
                class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                <img src="assets/images/about-yourself/edit.svg" *ngIf="!isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
                  class="w-4 ml-4 float-right cursor-pointer" alt="digital vault" />
              </p>
            </div>
          </div>
          <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4"
            *ngFor="let data of longTermCareDetails; let i = index">
            <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">

              <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full" />
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Type
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_type']?.name }}
                </p>
                <p *ngIf="!data?.['insurance_type']?.name" class="text-darkblue text-base text-regular tracking-wide">
                  Not Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Company
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['company_name'] }}
                </p>
                <p *ngIf="!data?.['company_name']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>

              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Owner
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['owner'] }}
                </p>
                <p *ngIf="!data?.['owner']" class="text-darkblue text-base text-regular tracking-wide">Not Available</p>

              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insured Person
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insured_person'] }}
                </p>
                <p *ngIf="!data?.['insured_person']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>

              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Policy Number
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['policy_number'] }}
                </p>
                <p *ngIf="!data?.['policy_number'] " class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>

              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm text-regular">
                  Sole Owner
                </h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ data?.["has_sole_ownership"]?'No':'Yes' }}
                </p>


              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['has_sole_ownership']">
                <h4 class="text-label-grey text-sm text-regular">
                  Joint Owner
                </h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ data?.['joint_ownership_name'] }}
                </p>
                <p *ngIf="!data?.['joint_ownership_name']" class="text-darkblue text-base text-regular tracking-wide">
                  Not
                  Available</p>

              </div>
              <div class="col-span-12 md:col-span-6" *ngIf="data?.['joint_insured']">
                <h4 class="text-label-grey text-sm text-regular">
                  Joint Insured
                </h4>
                <p class="text-lg text-regular text-darkgrey">
                  {{ data?.['joint_insured'] }}
                </p>
                <p *ngIf="!data?.['joint_insured']" class="text-darkblue text-base text-regular tracking-wide">
                  Not
                  Available</p>

              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Note
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['notes'] }}
                </p>
                <p *ngIf="!data?.['notes']" class="text-darkblue text-base text-regular tracking-wide">Not Available</p>

              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Agent
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_agent_name'] }}
                </p>
                <p *ngIf="!data?.['insurance_agent_name']" class="text-darkblue text-base text-regular tracking-wide">
                  Not Available</p>

              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Agent Contact Info
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{data?.['insurance_agent_contact'] ? '+'+data?.['country_code']:''}} {{
                  data?.['insurance_agent_contact'] }}
                </p>
                <p *ngIf="!data?.['insurance_agent_contact']"
                  class="text-darkblue text-base text-regular tracking-wide">Not Available</p>

              </div>

            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6"
        *ngIf="!initialDataLoader && longTermCareDetails?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Long Term Care
            </h4>
          </div>
          <div class="col-span-4">
            <p *ngIf="permissionEdit"
            class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
            <img src="assets/images/about-yourself/edit.svg"  *ngIf="!isAccessingVault && !isVaultClaimed"
              (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
              class="w-4 ml-4 float-right cursor-pointer" alt="add details" />
          </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
      <!--End: LongTerm Care overview -->

      <!-- Auto Insurance Overview -->
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="initialDataLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Auto Insurance
            </h4>
          </div>
        </div>
        <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Company
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Owner
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insured Person
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Number
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Agent
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Agent Contact Info
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!initialDataLoader && autoInsuranceDetails?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Auto Insurance
              </h4>
            </div>
            <div class="col-span-4">
              <p *ngIf="permissionEdit && (insuranceDetails?.length || 0)"
                class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                <img src="assets/images/about-yourself/edit.svg" *ngIf="!isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
                  class="w-4 ml-4 float-right cursor-pointer" alt="digital vault" />
              </p>
            </div>
          </div>
          <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4"
            *ngFor="let data of autoInsuranceDetails; let i = index">
            <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">



              <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full" />
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Type
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_type']?.name }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Company
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['company_name'] }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Amount
                </h4>
                <p [ngClass]="+data?.amount > 0 ? 'text-darkgrey' : 'text-darkblue' " class="text-base text-regular tracking-wide">
                  {{ +data?.amount > 0 ? (data?.amount | currency) : 'Not Available' }}
                </p> 
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Policy Number
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['policy_number'] }}
                </p>
                <p *ngIf="!data?.['policy_number']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>

              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Start Date
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['start_date'] | date: domainViewDateFormat }}
                </p>
                <p *ngIf="!data?.['start_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  End Date
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['end_date'] | date: domainViewDateFormat }}
                </p>
                <p *ngIf="!data?.['end_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Agent
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_agent_name'] }}
                </p>
                <p *ngIf="!data?.['insurance_agent_name']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Agent Contact Info
                </h4>
                <p *ngIf="data?.['insurance_agent_contact']"class="text-darkgrey text-base text-regular tracking-wide">
                  +{{data?.['country_code']}} {{ data?.['insurance_agent_contact'] }}
                </p>
                <p *ngIf="!data?.['insurance_agent_contact']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>

            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6"
        *ngIf="!initialDataLoader && autoInsuranceDetails?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Auto Insurance
            </h4>
          </div>
          <div class="col-span-4">
            <p *ngIf="permissionEdit"
            class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
            <img src="assets/images/about-yourself/edit.svg"  *ngIf="!isAccessingVault && !isVaultClaimed"
              (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
              class="w-4 ml-4 float-right cursor-pointer" alt="add details" />
          </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
      <!-- Dental Insurance Overview -->
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="initialDataLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Dental Insurance
            </h4>
          </div>
        </div>
        <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Company
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Owner
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insured Person
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Number
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Start Date
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                End Date
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Agent
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Agent Contact Info
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!initialDataLoader && dentalDetails?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Dental Insurance
              </h4>
            </div>
            <div class="col-span-4">
              <p *ngIf="permissionEdit && (insuranceDetails?.length || 0)"
                class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                <img src="assets/images/about-yourself/edit.svg" *ngIf="!isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
                  class="w-4 ml-4 float-right cursor-pointer" alt="digital vault" />
              </p>
            </div>
          </div>
          <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4"
            *ngFor="let data of dentalDetails; let i = index">
            <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">

              <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full" />
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Type
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_type']?.name }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Company
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['company_name'] }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Amount
                </h4>
                <p [ngClass]="+data?.amount > 0 ? 'text-darkgrey' : 'text-darkblue' " class="text-base text-regular tracking-wide">
                  {{ +data?.amount > 0 ? (data?.amount | currency) : 'Not Available' }}
                </p> 
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Policy Number
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['policy_number'] }}
                </p>
                <p *ngIf="!data?.['policy_number']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>

              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Start Date
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['start_date'] | date: domainViewDateFormat }}
                </p>
                <p *ngIf="!data?.['start_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  End Date
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['end_date'] | date: domainViewDateFormat }}
                </p>
                <p *ngIf="!data?.['end_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Agent
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_agent_name'] }}
                </p>
                <p *ngIf="!data?.['insurance_agent_name']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Agent Contact Info
                </h4>
                <p *ngIf="data?.['insurance_agent_contact']"class="text-darkgrey text-base text-regular tracking-wide">
                  +{{data?.['country_code']}} {{ data?.['insurance_agent_contact'] }}
                </p>
                <p *ngIf="!data?.['insurance_agent_contact']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>

            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!initialDataLoader && dentalDetails?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Dental Insurance
            </h4>
          </div>
          <div class="col-span-4">
            <p *ngIf="permissionEdit"
            class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
            <img src="assets/images/about-yourself/edit.svg"  *ngIf="!isAccessingVault && !isVaultClaimed"
              (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
              class="w-4 ml-4 float-right cursor-pointer" alt="add details" />
          </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
      <!-- Life Insurance Overview -->
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="initialDataLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Life Insurance
            </h4>
          </div>
        </div>
        <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Company
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Owner
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insured Person
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Number
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Start Date
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                End Date
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Agent
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Agent Contact Info
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!initialDataLoader && lifeInsuranceDetails?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Life Insurance
              </h4>
            </div>
            <div class="col-span-4">
              <p *ngIf="permissionEdit && (insuranceDetails?.length || 0)"
                class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                <img
                  (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
                  src="assets/images/about-yourself/edit.svg" *ngIf="!isAccessingVault && !isVaultClaimed" class="w-4 ml-4 float-right cursor-pointer"
                  alt="digital vault" />
              </p>
            </div>
          </div>
          <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4"
            *ngFor="let data of lifeInsuranceDetails; let i = index">
            <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">

              <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full" />
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Type
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_type']?.name }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Company
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['company_name'] }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Owner
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['owner'] }}
                </p>
                <p *ngIf="!data?.['owner']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insured Person
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insured_person'] }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Policy Type
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_policy_type']?.name }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Policy Number
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['policy_number'] }}
                </p>
                <p *ngIf="!data?.['policy_number']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Lump Sum
                </h4>
                <p [ngClass]="+data?.lump_sum > 0 ? 'text-darkgrey' : 'text-darkblue' " class="text-base text-regular tracking-wide">
                  {{ +data?.lump_sum > 0 ? (data?.lump_sum | currency) : 'Not Available' }}
                </p> 
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Start Date
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['start_date'] | date: domainViewDateFormat }}
                </p>
                <p *ngIf="!data?.['start_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  End Date
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['end_date'] | date: domainViewDateFormat }}
                </p>
                <p *ngIf="!data?.['end_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Death Benefit
                </h4>
                <p [ngClass]="+data?.death_benefit > 0 ? 'text-darkgrey' : 'text-darkblue' "
                  class="text-base text-regular tracking-wide">
                  {{ +data?.death_benefit > 0 ? (data?.death_benefit | currency) : 'Not Available' }}
                </p> 
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Agent
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_agent_name'] }}
                </p>
                <p *ngIf="!data?.['insurance_agent_name']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Agent Contact Info
                </h4>
                <p *ngIf="data?.['insurance_agent_contact']"class="text-darkgrey text-base text-regular tracking-wide">
                  +{{data?.['country_code']}} {{ data?.['insurance_agent_contact'] }}
                </p>
                <p *ngIf="!data?.['insurance_agent_contact']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Primary Beneficiary
                </h4>
                <ng-container *ngFor="let item of indexHandler(data['people'], 1);index as i;">
                  <p class="text-darkgrey text-base text-regular tracking-wide">
                    {{i+1}}. {{ item?.['first_name'] }} {{ item?.['last_name'] }}
                    {{ item?.['beneficiary_trustee_detail']?.['trust_name'] }} ({{item?.['pivot']?.['percentage']}}%)
                  </p>
                </ng-container>
                <p *ngIf="indexHandler(data['people'],1).length === 0" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 *ngIf="i==0" class="text-label-grey text-sm tracking-wide text-regular">
                  Secondary Beneficiary
                </h4>
                <ng-container *ngFor="let item of indexHandler(data['people'], 2);index as i;">
                  <p class="text-darkgrey text-base text-regular tracking-wide">
                    {{i+1}}. {{ item?.['first_name'] }} {{ item?.['last_name'] }}
                    {{ item?.['beneficiary_trustee_detail']?.['trust_name'] }} ({{item?.['pivot']?.['percentage']}}%)
                  </p>
                </ng-container>
                <p *ngIf="indexHandler(data['people'],2).length === 0" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6"
        *ngIf="!initialDataLoader && lifeInsuranceDetails?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Life Insurance
            </h4>
          </div>
          <div class="col-span-4">
            <p *ngIf="permissionEdit"
            class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
            <img src="assets/images/about-yourself/edit.svg"  *ngIf="!isAccessingVault && !isVaultClaimed"
              (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
              class="w-4 ml-4 float-right cursor-pointer" alt="add details" />
          </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
      <!-- Vision Insurance Overview -->
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="initialDataLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Vision Insurance
            </h4>
          </div>
        </div>
        <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Company
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Owner
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insured Person
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Number
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Start Date
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                End Date
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Agent
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Agent Contact Info
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!initialDataLoader && visionInsuranceDetails?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Vision Insurance
              </h4>
            </div>
            <div class="col-span-4">
              <p *ngIf="permissionEdit && (insuranceDetails?.length || 0)"
                class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                <img src="assets/images/about-yourself/edit.svg" *ngIf="!isAccessingVault && !isVaultClaimed"
                  (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
                  class="w-4 ml-4 float-right cursor-pointer" alt="digital vault" />
              </p>
            </div>
          </div>
          <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4"
            *ngFor="let data of visionInsuranceDetails; let i = index">
            <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">

              <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full" />
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Type
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_type']?.name }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Company
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['company_name'] }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Amount
                </h4>
                <p [ngClass]="+data?.amount > 0 ? 'text-darkgrey' : 'text-darkblue' " class="text-base text-regular tracking-wide">
                  {{ +data?.amount > 0 ? (data?.amount | currency) : 'Not Available' }}
                </p> 
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Policy Number
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['policy_number'] }}
                </p>
                <p *ngIf="!data?.['policy_number']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>

              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Start Date
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['start_date'] | date: domainViewDateFormat }}
                </p>
                <p *ngIf="!data?.['start_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  End Date
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['end_date'] | date: domainViewDateFormat }}
                </p>
                <p *ngIf="!data?.['end_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Agent
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_agent_name'] }}
                </p>
                <p *ngIf="!data?.['insurance_agent_name']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Agent Contact Info
                </h4>
                <p *ngIf="data?.['insurance_agent_contact']"class="text-darkgrey text-base text-regular tracking-wide">
                  +{{data?.['country_code']}} {{ data?.['insurance_agent_contact'] }}
                </p>
                <p *ngIf="!data?.['insurance_agent_contact']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>

            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6"
        *ngIf="!initialDataLoader && visionInsuranceDetails?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Vision Insurance
            </h4>
          </div>
          <div class="col-span-4">
            <p *ngIf="permissionEdit"
            class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
            <img src="assets/images/about-yourself/edit.svg"  *ngIf="!isAccessingVault && !isVaultClaimed"
              (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
              class="w-4 ml-4 float-right cursor-pointer" alt="add details" />
          </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
      <!-- Other Insurance Overview -->
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="initialDataLoader">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Other Insurance
            </h4>
          </div>
        </div>
        <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4">
          <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Company
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Owner
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insured Person
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Type
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Policy Number
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Start Date
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                End Date
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Insurance Agent
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
            <div class="col-span-12 md:col-span-6">
              <h4 class="text-label-grey text-sm tracking-wide text-regular">
                Agent Contact Info
              </h4>
              <app-shimmer-loading [loading]="initialDataLoader" [width]="'100%'"></app-shimmer-loading>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!initialDataLoader && otherInsuranceDetails?.length">
        <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
          <div class="grid grid-cols-12 justify-between items-center">
            <div class="col-span-8">
              <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                Other Insurance
              </h4>
            </div>
            <div class="col-span-4">
              <p *ngIf="permissionEdit && (insuranceDetails?.length || 0)"
                class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
                <img
                  (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
                  src="assets/images/about-yourself/edit.svg" *ngIf="!isAccessingVault && !isVaultClaimed" class="w-4 ml-4 float-right cursor-pointer"
                  alt="digital vault" />
              </p>
            </div>
          </div>
          <div class="bg-card-overview bg-transparent border-card-grey rounded mt-4"
            *ngFor="let data of otherInsuranceDetails; let i = index">
            <div class="grid grid-cols-12 p-6 gap-y-5 row-gap-4">

              <div class="col-span-12" *ngIf="i">
                <hr class="divider w-full" />
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Type
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_type']?.name }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Company
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['company_name'] }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Owner
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['owner'] }}
                </p>
                <p *ngIf="!data?.['owner']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insured Person
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insured_person'] }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Policy Type
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_policy_type']?.name }}
                </p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Policy Number
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['policy_number'] }}
                </p>
                <p *ngIf="!data?.['policy_number']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Lump Sum
                </h4>
                <p [ngClass]="+data?.lump_sum > 0 ? 'text-darkgrey' : 'text-darkblue' " class="text-base text-regular tracking-wide">
                  {{ +data?.lump_sum > 0 ? (data?.lump_sum | currency) : 'Not Available' }}
                </p> 
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Start Date
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['start_date'] | date: domainViewDateFormat }}
                </p>
                <p *ngIf="!data?.['start_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  End Date
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['end_date'] | date: domainViewDateFormat }}
                </p>
                <p *ngIf="!data?.['end_date']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Death Benefit
                </h4>
                <p [ngClass]="+data?.death_benefit > 0 ? 'text-darkgrey' : 'text-darkblue' "
                  class="text-base text-regular tracking-wide">
                  {{ +data?.death_benefit > 0 ? (data?.death_benefit | currency) : 'Not Available' }}
                </p> 
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Insurance Agent
                </h4>
                <p class="text-darkgrey text-base text-regular tracking-wide">
                  {{ data?.['insurance_agent_name'] }}
                </p>
                <p *ngIf="!data?.['insurance_agent_name']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Agent Contact Info
                </h4>
                <p *ngIf="data?.['insurance_agent_contact']"class="text-darkgrey text-base text-regular tracking-wide">
                  +{{data?.['country_code']}} {{ data?.['insurance_agent_contact'] }}
                </p>
                <p *ngIf="!data?.['insurance_agent_contact']" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 class="text-label-grey text-sm tracking-wide text-regular">
                  Primary Beneficiary
                </h4>
                <ng-container *ngFor="let item of indexHandler(data['people'], 1);index as i;">
                  <p class="text-darkgrey text-base text-regular tracking-wide">
                    {{i+1}}. {{ item?.['first_name'] }} {{ item?.['last_name'] }}
                    {{ item?.['beneficiary_trustee_detail']?.['trust_name'] }} ({{item?.['pivot']?.['percentage']}}%)
                  </p>
                </ng-container>
                <p *ngIf="indexHandler(data['people'],1).length === 0" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
              <div class="col-span-12 md:col-span-6">
                <h4 *ngIf="i==0" class="text-label-grey text-sm tracking-wide text-regular">
                  Secondary Beneficiary
                </h4>
                <ng-container *ngFor="let item of indexHandler(data['people'], 2);index as i;">
                  <p class="text-darkgrey text-base text-regular tracking-wide">
                    {{i+1}}. {{ item?.['first_name'] }} {{ item?.['last_name'] }}
                    {{ item?.['beneficiary_trustee_detail']?.['trust_name'] }} ({{item?.['pivot']?.['percentage']}}%)
                  </p>
                </ng-container>
                <p *ngIf="indexHandler(data['people'],2).length === 0" class="text-darkblue text-base text-regular tracking-wide">Not
                  Available</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6"
        *ngIf="!initialDataLoader && otherInsuranceDetails?.length === 0">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Other Insurance
            </h4>
          </div>
          <div class="col-span-4">
            <p *ngIf="permissionEdit"
            class="text-lightblue text-regular float-right text-semibold tracking-wide flex flex-row">
            <img src="assets/images/about-yourself/edit.svg"  *ngIf="!isAccessingVault && !isVaultClaimed"
              (click)="getCommonHelper.routerNavigate('/insurance-details',this.userType !== 'Consumer' ? clientRequestId : null)"
              class="w-4 ml-4 float-right cursor-pointer" alt="add details" />
          </p>
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4">
          <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
            No Data to Display
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>