import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
})
/**
 * Features class
 */
export class FeaturesComponent implements OnInit {
  public mobile: boolean;
  public innerWidth: number;
  public professionalType: string;
  public vaultVideo: string;


  /**
   * constructor
   */
  constructor(
    private localStorageService: LocalStorageService,
    private modalService: CommonModelService

  ) {

  }

  /**
 * loaded initially
 */
  ngOnInit(): void {
    this.professionalType = this.localStorageService.getDataByKey('pro-type')?.[0]?.displayText;
    this.onResize();
  }
  /**
   * window resize
   * @param {any} event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth <= 767) { // 768px portrait
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  /**
   * scroll to features
   * @param {HTMLElement}el
   */
  public scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

   /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');
  }
}
