import { Component, OnInit } from '@angular/core';
import { ConsumerSubscriptionService } from '../consumer-payment-section/consumer-subscription.service';

@Component({
  selector: 'app-billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.css']
})
export class BillingHistoryComponent implements OnInit {
  public fromNo: number;
  public toNo: number;
  public totalNo: number;
  public lastPage: number;
  public paginationLinks: Array<any>;
  public recordsPerPage: number;
  public currentPage: number;
  public searchKeyword: string;
  public listOrder: string;
  public billingHistoryData: [] = [];
  public loader: boolean;

  /**
   * Retrieves the type of PaymentStatus enum.
   * @returns {typeof PaymentStatus} The type of PaymentStatus enum.
   */
  get PaymentStatus(): typeof PaymentStatus {
    return PaymentStatus
  }

  constructor(private subscriptionService: ConsumerSubscriptionService,) { }

  ngOnInit(): void {
    this.recordsPerPage = 10;
    this.currentPage = 1;
    this.searchKeyword = '';
    this.listOrder = 'desc';
    this.loader = false;
    this.getBillingHistoryData();
  }

  /**
   * Get Billing History Data
   */
  public getBillingHistoryData() {
    this.loader = true;
    this.billingHistoryData = [];
    this.subscriptionService.getBillingHistory(this.currentPage, this.recordsPerPage, this.listOrder, this.searchKeyword).subscribe({
      next: (response: any) => {
        this.loader = false;
        this.billingHistoryData = response.data.data;
        this.fromNo = response.data.from;
        this.toNo = response.data.to;
        this.totalNo = response.data.total;
        this.paginationLinks = response.data.links;
        this.lastPage = response.data.last_page;
      },
      error: () => {
        this.loader = false;
      }
    }
    );
  }

  /**
   * Get Billing History User Requests
   */
  public getBillingHistoryRequest(pageNo?: number) {
    if (pageNo === this.currentPage) {
      return
    }
    if (pageNo) {
      this.currentPage = pageNo;
    }
    this.loader = true;
    this.billingHistoryData = [];
    this.subscriptionService.getBillingHistory(this.currentPage, this.recordsPerPage, this.listOrder, this.searchKeyword).subscribe({
      next: (response: any) => {
        this.loader = false;
        this.billingHistoryData = response.data.data;
        this.fromNo = response.data.from;
        this.toNo = response.data.to;
        this.totalNo = response.data.total;
        this.paginationLinks = response.data.links;
        this.lastPage = response.data.last_page;
      },
      error: () => {
        this.loader = false;
      }
    }
    );
  }

/**
 * Converts a payment status enum value to its corresponding string representation.
 * 
 * @param status -
 * @returns The string representation of the payment status.
 */
public getPaymentStatus(status:string):string{
  switch (status){
    case PaymentStatus.Suceeded :
    return 'Success'
    case PaymentStatus.Cancelled:
    return 'Cancelled'
    case PaymentStatus.Refunded:
    return 'Refunded'
    case PaymentStatus.Failed :
    return 'Failed'
  }
}
}

enum PaymentStatus {
  Suceeded = 'succeeded',
  Cancelled = 'cancelled',
  Refunded = 'refunded',
  Failed = 'failed'
}
