import { Component } from '@angular/core';
import { CommonModelService } from 'src/app/services/common-model.service';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  
})
/**
 * modal
 */
export class EmailModalComponent   {
/**
 * constructor
 */
  constructor(private modalService:CommonModelService) { }

  
}
