<div class="scroll-hidden">
    <!-- Navbar -->
      <app-navbar></app-navbar >

    <div class="background-color-grey p-8 sm:p-12 md:px-16 xl:px-24 py-12">
        <div class="grid grid-cols-12 pb-8">
            <!-- BEGIN: Heading -->
            <div class="col-span-12">
                <div class="pb-4">
                    <h2 class="font-bold text-darkblue text-lg text-regular text-left">
                        The Beneficiary/Executor Edition LegacyNOW Digital Vault
                    </h2>
                    <hr class="border-lightblue w-24 my-1 border-1">
                </div>

                <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
                    Received Digital Vault
                </h2>
                <p class="text-base text-grey py-2 tracking-wide text-regular">If a person has added you as
                    Beneficiary/Executor, it
                    will be displayed in this section.
                </p>
            </div>
            <!-- END: Heading -->
        </div>
        <div class="grid grid-cols-12 gap-8 xl:gap-12">
            <ng-container *ngIf="userDetails.length">
                <app-people-account-details

                *ngFor="let userData of userDetails" class="col-span-12 lg:col-span-6" [userDetails]="userData">
                </app-people-account-details>
            </ng-container>
            <div class="col-span-12" *ngIf="!userDetails.length">
                <div class="col-span-12 mx-8 lg:mx-4">
                    <div
                        class="bg-transparent border-card-grey rounded my-4 mx-auto w-full sm:w-9/12 md:w-7/12 lg:w-6/12 xl:w-4/12">
                        <div class="grid grid-cols-12 p-6 gap-6">
                            <div class="col-span-12 sm:col-span-1">
                                <div class="rounded-full border grid items-center justify-center h-12 w-12">
                                    <img src="assets/images/property-details/view-image.svg" class="w-4" alt="">
                                </div>
                            </div>
                            <div class="col-span-12 sm:col-span-11 sm:ml-8 md:ml-6">
                                <h4 class="w-2/5 h-4 rounded-sm bg-loader-heading"></h4>
                                <p class="w-1/5 h-4 mt-2 rounded-sm bg-loader-content"></p>
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <h4 class="w-4/5 h-4 rounded-sm bg-loader-heading"></h4>
                                <p class="w-2/5 h-4 mt-2 rounded-sm bg-loader-content"></p>
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <h4 class="w-4/5 h-4 rounded-sm bg-loader-heading"></h4>
                                <p class="w-2/5 h-4 mt-2 rounded-sm bg-loader-content"></p>
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <h4 class="w-4/5 h-4 rounded-sm bg-loader-heading"></h4>
                                <p class="w-2/5 h-4 mt-2 rounded-sm bg-loader-content"></p>
                            </div>

                            <div class="col-span-12 sm:col-span-6">
                                <h4 class="w-1/5 h-4 rounded-sm bg-loader-content"></h4>
                                <p class="w-2/5 h-4 rounded-sm mt-2 bg-loader-heading"></p>

                            </div>
                            <div class="col-span-12 sm:col-span-6 grid items-end">
                                <div class="bg-loader-btn h-8 rounded-sm w-2/5 sm:w-4/5 md:w-3/5 ml-auto"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-center col-span-12">
                    <h2 class="text-darkblue text-xl sm:text-2xl uppercase">
                      YOU HAVE CREATED A DIGITAL VAULT
                    </h2>
                    <p class="text-base text-grey py-2 tracking-wide text-regular">You have not been added as
                        beneficiary/executor<br /> to a LegacyNOW digital vault</p>
                </div>
            </div>
        </div>
    </div>
</div>
