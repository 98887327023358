import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { ConsumerPaymentMethod, DV_TANSFER_CONTENT } from 'src/constants/application.const';
import { CommonHelper } from '../../helper/common.helper';
import { SlugInterceptorService } from '../../helper/slug-interceptor.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { ConsumerSubscriptionService } from '../consumer-payment-section/consumer-subscription.service';
import { PaymentOption } from './payment-option.model';


@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  private readonly REDIRECT_URL_KEY = 'post_checkout_redirect_url';
  private readonly renewalHTML = `
    <p class="text-md text-black font-normal text-regular py-auto whitespace-no-wrap">
      Renews on: <u>:RENEWAL_DATE</u>
    </p>`;
  private readonly paymentOptionsDefaults: CheckoutServicePaymentOptions = {
    subscription_monthly: {
      id: '',
      header: 'Monthly Subscription',
      description: `
      <div class="flex flex-col sm:flex-row justify-between">
        <p class="text-base text-grey py-1 text-regular w-full 2xl:w-9/12">
          1 Month Subscription Plan
        </p>
        :RENEWAL_HTML
      </div>`,
      Recurring: true,
      RecurringPeriod: 'Monthly',
      inactive: false,
      requiredToSelect: false,
      disable: false
    },
    subscription_yearly: {
      id: '',
      header: 'Yearly Subscription',
      description: `
      <div class="flex flex-col sm:flex-row justify-between">
        <p class="text-base text-grey py-1 text-regular w-full 2xl:w-9/12">
        :YEARLYSUBSCRPTIONCONTENT
        </p>
      </div>`,
      Recurring: true,
      RecurringPeriod: 'Yearly',
      inactive: false,
      requiredToSelect: false,
      disable: false
    },
    dataLinkFee: {
      id: '',
      header: `Digital Vault Transfer`,
      description: DV_TANSFER_CONTENT.noAccessWhileAlive,
      inactive: false,
      requiredToSelect: false,
      disable: false
    },
  };
  private paymentOptions: CheckoutServicePaymentOptions;
  private cartOptions: string[];
  private lsKey: string = 'checkout_service_cart';

  constructor(
    private subscriptionService: ConsumerSubscriptionService,
    private slugRouter: SlugInterceptorService,
    private localstorage: LocalStorageService,
    private commonHelper: CommonHelper) {
    this.initCart();
  }

  updateCart(id: string, remove = false) {
    if (remove) {
      this.cartOptions = this.cartOptions.filter(e => e != id);
    } else if (Object.keys(this.paymentOptions).find(e => e == id) != null) {
      this.cartOptions.push(id);
    }
    this.localstorage.storeData(this.lsKey, this.cartOptions);
  }

  setCart(ids: string[]) {
    this.cartOptions = [];
    ids.forEach(id => this.updateCart(id));
  }

  get getCart(): string[] {
    return [...this.cartOptions];
  }

  private initCart() {
    this.cartOptions = this.localstorage.getDataByKey('cart') ?? [];
  }

  /**
   * get subscription details
   */
  private updateDetails(reqID?: string) {
    const slugSubscriptionAmount = this.localstorage.getDataByKey('slug_subscription_amount');
    const paymentMode = this.localstorage.getDataByKey('payment_mode');
    const firmName:string = this.localstorage.getDataByKey('firm_name')?.trimEnd();

    return new Observable<boolean>(observer => {
      this.subscriptionService.getSubscriptionDetailsApi(reqID).subscribe({
        next: r => {
          this.paymentOptions = { ...this.paymentOptionsDefaults };
          const data: Object = r.data ?? {};

          // Vault Transfer
          this.paymentOptions.dataLinkFee = {
            ...this.paymentOptions.dataLinkFee,
            fee: Number(data['vault_transfer_amount']),
            feePaid: (data['is_vault_transfer_paid'] == 1),
            paymentInProgress: (data['is_vault_transfer_paid'] == 2),
            removeOption: false,
          };

          // Monthly Subscription
          const paymentComplete = data['is_payment_complete'] == 1;
          const subscriptionDetails = data['subscription_details'];
          const RENEWAL_DATE = this.getRenewalDate(subscriptionDetails);
          let monthlyDescription = this.paymentOptions.subscription_monthly.description.replace(
            ':RENEWAL_HTML', paymentComplete ? this.renewalHTML : '');
          monthlyDescription = monthlyDescription.replace(':RENEWAL_DATE', RENEWAL_DATE);
          this.paymentOptions.subscription_monthly = {
            ...this.paymentOptions.subscription_monthly,
            description: monthlyDescription,
            fee: Number(data['subscription_price']),
            feePaid: data['is_payment_complete'] == 1,
            paymentInProgress: data['is_vault_transfer_paid'] == 2,
            removeOption: false,
            inactive: this.localstorage.getDataByKey('subscription_plan_type') == 1
          };

          if (paymentMode == ConsumerPaymentMethod.PASS_TO_CONSUMER && slugSubscriptionAmount >= 0) {
            this.paymentOptions.subscription_monthly.fee = slugSubscriptionAmount;
          }

          if (paymentMode == ConsumerPaymentMethod.PAID_BY_PROFESSIONAL) {
            this.paymentOptions.subscription_monthly.fee = null;
            // delete this.paymentOptions.subscription_monthly 
          }


          // Yearly Subscription
          let yealyDescription = this.paymentOptions.subscription_yearly.description.replace(
            ':YEARLYSUBSCRPTIONCONTENT', paymentComplete ? (paymentMode == ConsumerPaymentMethod.PAID_BY_PROFESSIONAL ? `This payment is made by your Professional Service Provider${firmName ? ', ' + firmName + ',' : ''} and is valid until ${RENEWAL_DATE}` : `Yearly Subscription Plan and Valid till ${RENEWAL_DATE}`) : `Yearly Subscription Plan`);
          this.paymentOptions.subscription_yearly = {
            ...this.paymentOptions.subscription_yearly,
            description: yealyDescription,
            fee: Number(data['subscription_price']),
            feePaid: data['is_payment_complete'] == 1,
            removeOption: false,
            inactive: this.localstorage.getDataByKey('subscription_plan_type') !== 1
          };
          if (paymentMode == ConsumerPaymentMethod.PASS_TO_CONSUMER && slugSubscriptionAmount >= 0) {
            this.paymentOptions.subscription_yearly.fee = slugSubscriptionAmount;
          }
          if (paymentMode == ConsumerPaymentMethod.PAID_BY_PROFESSIONAL) {
            // this.paymentOptions.subscription_yearly.inactive = true;
            this.paymentOptions.subscription_yearly.fee = null;
            if (!this.paymentOptions.subscription_yearly.feePaid) { //fee not paid by professional means disable datalinkfee
              delete this.paymentOptions.subscription_yearly;
              this.paymentOptions.dataLinkFee.disable = true;
            }
          }
          observer.next(true);
        },
        error: e => observer.error(e),
        complete: () => observer.complete(),
      });
    });
  }

  getPaymentOptions(reqID?: string): Observable<PaymentOption[]> {
    return new Observable<PaymentOption[]>(observer => {
      this.updateDetails(reqID).subscribe({
        next: () => {
          let options: PaymentOption[] = [];
          Object.keys(this.paymentOptions).forEach(id => {
            const option: PaymentOption = this.paymentOptions[id];
            if (option.inactive) {
              return;
            }
            options.push({ ...option, id: id });
          });
          observer.next(options);
          observer.complete();
        },
        error: e => this.commonHelper.httpResponseHandler(e.error),
        complete: () => observer.complete(),
      });
    });
  }

  /**
   * Proceed tosubscription payment
   * @returns to subscription payment
   */
  proceedTosubscriptionPayment(): Observable<any> {
    return new Observable<any>(observer => {
      this.localstorage.storeData('isClicked', true);
      this.getPaymentOptions().subscribe({
        next: paymentOptions => {
          const consumerPaymentMode = Number(this.localstorage.getDataByKey('payment_mode'))
          const selectedPaymentKey: string = (consumerPaymentMode == ConsumerPaymentMethod.PASS_TO_CONSUMER) ? 'subscription_yearly' : 'subscription_monthly'
          paymentOptions.find(item => item.id === selectedPaymentKey).selected = true;
          const ids: string[] = paymentOptions.filter(e => e.selected).map(e => e.id);
          this.setCart(ids);
        },
        complete: () => {
          observer.next();
          observer.complete();
        },
      });
    });
  }

  /**
   * Set a URL to redirect to after payment is completed successfully. If this is unset, will default to '/dashboard'
   */
  setRedirectUrl(url?: string) {
    if (!url) {
      this.localstorage.deleteDataByKey(this.REDIRECT_URL_KEY);
    } else {
      if (!this.isRestrictedUrl(url)) {
        this.localstorage.storeData(this.REDIRECT_URL_KEY, url);
      }
    }
  }

  /**
   * Redirect after a successful checkout. Set with {@link setRedirectUrl}. If unset, defaults to dashboard.
   * Redirect to subscription-plan means set redirect to dashboard
   */
  postCheckoutRedirect(): Promise<boolean> {
    let previousUrl = this.localstorage.getDataByKey(this.REDIRECT_URL_KEY);
    if (previousUrl) {
      previousUrl = this.isRestrictedUrl(previousUrl) ? '/dashboard' : previousUrl;
    }
    return this.slugRouter.navigateByUrl(previousUrl);
  }

  /**
   * Determines whether restricted url
   * @param url 
   * @returns true if restricted url 
   */
  private isRestrictedUrl(url): boolean {
    const previousUrlSegments = url?.split('/');
    let refactoredPreviousUrl;
    //NOTE: The URLs below are restricted for redirection.
    const restrictedUrls = ['subscription-plan', 'saved-cards', 'account-settings', 'billing-history', 'checkout'];
    const lastSegment = previousUrlSegments?.[previousUrlSegments.length - 1];
    refactoredPreviousUrl = lastSegment.includes('?') ? lastSegment.split('?')[0] : lastSegment;
    return restrictedUrls.includes(refactoredPreviousUrl);
  }
  /**
   * Get renewal date
   * @param subscription_details 
   * @returns renewal date 
   */
  private getRenewalDate(subscription_details: Object) : string {
    return this.commonHelper.formatDate(moment(subscription_details?.['end_date']), 'MMMM DD, YYYY');
  }
  /**
   * Get Differences in years
   * @param date1 
   * @param date2 
   * @returns {number} 
   */
  private differenceInYears(date1: Date, date2: Date): number {
    const diffYears = date2.getFullYear() - date1.getFullYear();

    // Adjust for the months and days to ensure full year differences are considered.
    const monthAdjustment = date2.getMonth() - date1.getMonth();
    const dayAdjustment = date2.getDate() - date1.getDate();

    // If the month and day of date2 are before date1, subtract one year from the difference
    if (monthAdjustment < 0 || (monthAdjustment === 0 && dayAdjustment < 0)) {
        return diffYears - 1;
    } else {
        return diffYears;
    }
}
}


interface CheckoutServicePaymentOptions {
  dataLinkFee: PaymentOption,
  subscription_monthly: PaymentOption,
  subscription_yearly: PaymentOption,
}
