import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { SelectSearchComponentt } from '../select-search/select-search.component';

@Component({
  selector: 'app-select-with-search',
  templateUrl: '../select-search/select-search.component.html',
  styleUrls: ['../select-search/select-search.component.css'],
})
/**
 * Select with search component
 */
export class SelectWithSearchComponent extends SelectSearchComponentt implements OnInit, OnChanges {
  @Input() placeholderValue: string;
  @Input() defaultSelected: any;
  @Input() options: Array<SelectOptionsInterface>;
  @Input() ifError: boolean;
  @Input() disabled: boolean = false;
  @Input() showSearchBox: boolean = true;
  @Output() optionChangeEmitter = new EventEmitter<SelectOptionsInterface>();

  /**
   * @constructor
   */
  constructor(
    public _eref: ElementRef,
  ) {
    super(_eref);
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.initialPlaceholder = this.placeholderValue;
    this.placeholder = this.placeholderValue.replace('Select', 'Search') + ' here';
    // this.showSearchBox = this.showSearchBox;
  }

  /**
   * called on changes
   *
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // triggered when list change
    if (changes.options) {
      this.items = changes.options.currentValue;
    }

    // triggered when selected id changes
    if (changes.defaultSelected) {
      super.onSelectPreProcess(changes.defaultSelected.currentValue);
    }
    this.enableError = this.ifError;
  }

  /**
   * listen for options changes
   *
   * @param {string} selectedOption
   */
  public optionChangeListener(selectedOption: SelectOptionsInterface): void {
    this.optionChangeEmitter.emit(selectedOption);
  }

  /**
   * On select change
   *
   * @param {string} value
   */
  public onSelectChange(value: string): void {
    super.onSelectPreProcess(value);
    this.optionChangeListener(this.selectedOption);
  }
}
