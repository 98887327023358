import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SharedModule } from 'src/app/sharedComponent/shared.module';
import { FooterLandingProComponent } from './footer-landing-pro/footer-landing-pro.component';
import { LandingPageProRoutingModule } from './landing-page-routing.module';
import { LandingPageComponent } from './landing-page.component';
import { NavLandingProComponent } from './nav-landing-pro/nav-landing-pro.component';
import { ProfessionalBannerComponent } from './professional-banner/professional-banner.component';


@NgModule({
  declarations: [
    FooterLandingProComponent,
    NavLandingProComponent,
    LandingPageComponent,
    ProfessionalBannerComponent,
  ],
  imports: [
    SharedModule,
    LandingPageProRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
  ],
  exports: [
    FooterLandingProComponent,
    NavLandingProComponent,
    LandingPageComponent,
  ],
})

/**
 * professional landing page module
 */
export class LandingPageProModule {

}
