import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlaidOnEventArgs, PlaidOnExitArgs, PlaidOnSuccessArgs } from 'ngx-plaid-link';
import { ToastrService } from 'ngx-toastr';
import { CheckoutService } from 'src/app/components/checkout/checkout.service';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

import { environment } from 'src/environments/environment';
import { ConsumerSubscriptionService } from '../../components/consumer-payment-section/consumer-subscription.service';
import { CommonHelper } from '../../helper/common.helper';
import { CommonModelService } from '../../services/common-model.service';
import { CommonService } from '../../services/common.service';
import { CommonModalEmitter } from '../common-modal/common-modal.component';


@Component({
  selector: 'app-import-from-plaid-option',
  templateUrl: './import-from-plaid-option.component.html',
})
export class ImportFromPlaidOptionComponent implements OnInit {
  @Input() modalID: string = 'import-from-plaid-option';
  @Input() assetType: string = '';
  @Input() overrideEventHandling: boolean = false;
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() clientHasPaidSubscription:boolean = false;
  @Output() plaidOptionEmitter: EventEmitter<PlaidOptionEmit> = new EventEmitter();
  plaidKey: string;
  handoff: boolean = false;
  readonly PLAID_ENV = environment.PLAID_ENV;
  // enables user to add/edit form fields
  canAccessForm: boolean;
  vaultVideo: string;
  hasPaidSubscription: boolean;
  buttonLoaderPlaid: boolean = false;
  buttonLoaderManual: boolean = false;

  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private subscriptionService: ConsumerSubscriptionService,
    private commonService: CommonService,
    private commonHelper: CommonHelper,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    private checkoutService: CheckoutService,
    private slugInterceptorService: SlugInterceptorService
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.commonService.getPlaidToken().subscribe(r => this.plaidKey = r.data['link_token']);    
    if(!this.commonHelper.isProfessional){
      this.handoff = true;
      this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => {
        this.hasPaidSubscription = r
        this.handoff = false;
      });
    }
  }

  /**
   * toggle financial asset  modal
   */
  clickManual(): void {
    if (this.hasPaidSubscription || this.clientHasPaidSubscription) {
      this.plaidOptionEmitter.emit({ manual: { onClick: true } });
    } else {
      this.buttonLoaderManual = true;
      this.redirectToPaymentPage();
    }
  }

  /**
   * to show info message
   */
  clickPlaid() {
    if (this.hasPaidSubscription || this.clientHasPaidSubscription) {
      this.toastr.info('Automatic aggregation breaks when passwords change');
      this.plaidOptionEmitter.emit({ plaid: { onClick: true } });
    } else {
      this.buttonLoaderPlaid = true;
      this.redirectToPaymentPage();
    }
  }

  /**
   * Redirects to payment page
   */
  private redirectToPaymentPage() {
    this.checkoutService.proceedTosubscriptionPayment().subscribe(()=>{
      this.buttonLoaderManual = this.buttonLoaderPlaid = false;
      this.closeModal();
      void this.slugInterceptorService.navigate(['/checkout']);
    })
  }

  /**
   * Close modal
   */
  closeModal(): void {
    this.handoff = false;
    this.modalService.close(this.modalID);
    this.plaidOptionEmitter.emit({ closedModal: true });
  }

  /**
   * open modal
   * @param{string} item
   */
  openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.setModalVideo(this.vaultVideo);
    this.modalService.open('view-demo-video-modal');
  }

  onPlaidSuccess($event: PlaidOnSuccessArgs): void {
    if (!this.overrideEventHandling) {
      console.log('plaid onSuccess', $event);
    }
    this.plaidOptionEmitter.emit({ plaid: { onSuccess: $event } });
  }

  onPlaidEvent($event: PlaidOnEventArgs) {
    if (!this.overrideEventHandling) {
      console.log('plaid onEvent', $event);
      this.handoff = $event.eventName == 'HANDOFF';
    }
    this.plaidOptionEmitter.emit({ plaid: { onEvent: $event } });
  }

  onPlaidExit($event: PlaidOnExitArgs) {
    if (!this.overrideEventHandling) {
      console.log('plaid onExit', $event);
    }
    this.plaidOptionEmitter.emit({ plaid: { onExit: $event } });
  }

  onPlaidLoad($event: any) {
    if (!this.overrideEventHandling) {
      console.log('plaid onLoad', $event);
    }
    this.plaidOptionEmitter.emit({ plaid: { onLoad: $event } });
  }

  cmListener($event: CommonModalEmitter) {
    this.handoff = $event.action == 'close' ? false : this.handoff;
  }

  /**
   * If user can edit existing asset, or add a new asset.
   */
  get canEdit(): boolean {
    const checkPaidStatus = this.hasPaidSubscription || this.clientHasPaidSubscription; // need to work on it, checking role must be removed and canEditSection must checked both case
    return this.commonHelper.isConsumer ? checkPaidStatus : this.commonHelper.canEditSection(true, this.permissions) && checkPaidStatus;
  }
}


export interface PlaidOptionEmit {
  closedModal?: boolean;
  manual?: { onClick?: boolean };
  plaid?: {
    onClick?: boolean;
    onSuccess?: PlaidOnSuccessArgs;
    onEvent?: PlaidOnEventArgs;
    onExit?: PlaidOnExitArgs;
    onLoad?: any;
  };
}
