import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
/**
 * clients service
 */
export class ChatService {
  private BASE_URL: string;
  private emitChangeSource: any;
  public changeEmitted: Observable<boolean>;
  public role: string = '';


  /**
 * constructor
 */
  constructor(private http: HttpClient,
    private localStorageService: LocalStorageService) {
    this.BASE_URL = environment.BASE_URL;
    this.emitChangeSource = new BehaviorSubject(false);
    this.changeEmitted = this.emitChangeSource.asObservable();
    this.role = localStorageService.getDataByKey('role');
  }
  /**
   *
   * emit changes
   * @param {any} change
   */
  public emitChange(change: boolean) {
    this.emitChangeSource.next(change);
  }

  /**
   * @param {any} data
   * @return {Observable}
   * start new conversation
   */
  public startNewChat(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/start-new-conversation`, data);
  }
  /**
   * @param {any} data
   * @return {Observable}
   * store chat file
   */
  public storeChatFiles(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/store-chat-files`, data);
  }


  /**
   * Get clients list
   * @param{string}search
   * @return {Observable}
   */
  public getConsumersList(search?: string): Observable<APIResponseModel> {
    const url = this.role === 'Sub Organization' ? `${this.BASE_URL}/organization/get-consumer-list` : `${this.BASE_URL}/get-consumer-list`;
    // return this.http.get<API_RESPONSE_INTERFACE>(`${this.BASE_URL}/consumers-list?search=${search}`);
    return this.http.get<APIResponseModel>(url);
  }

  /**
   * Get professionals list
   * @param{string}search
   * @return {Observable}
   */
  public getProfessionalsList(search?: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/get-professional-list`);
  }

  /**
   * Get selected consumer's chat list
   * @param{string}consumerId
   * @param {number} userRequest
   * @return {Observable}
   */
  public getConsumerChatList(consumerId: string, userRequest: number): Observable<APIResponseModel> {
    const url = (this.role === 'Sub Organization') ? (userRequest >= 2 ? `${this.BASE_URL}/organization/get-consumer-chats/${consumerId}?page=${userRequest}` : `${this.BASE_URL}/organization/get-consumer-chats/${consumerId}`) : (userRequest >= 2 ? `${this.BASE_URL}/get-consumer-chats/${consumerId}?page=${userRequest}` : `${this.BASE_URL}/get-consumer-chats/${consumerId}`);
    return this.http.get<APIResponseModel>(url);
  }

  /**
   * Get selected professional's chat list
   * @param {string} professionalId
   * @param {number} userRequest
   * @return {Observable}
   */
  public getProfessionalChatList(professionalId: string, userRequest: number): Observable<APIResponseModel> {
    const url = userRequest >= 2 ? `${this.BASE_URL}/get-professional-chats/${professionalId}?page=${userRequest}` : `${this.BASE_URL}/get-professional-chats/${professionalId}`;
    return this.http.get<APIResponseModel>(url);
  }

  /**
     * file upload
    * @param {any} data
    *
    * @return {Observable}
    */
  public onUploadFiles(data: any): Observable<any> {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (!data) return;
      if (data[key] instanceof Array) {
        data[key].forEach((item) => {
          formData.append(`${key.toString()}[]`, item);
        });
      } else {
        formData.append(key.toString(), data[key]);
      }
    });
    const req = new HttpRequest('POST', `${this.BASE_URL}/upload-chat-file`, formData);

    return this.http.request(req);
  }

  /**
     * chat file download - image/documents
     *
    * @param {string} id
    * @return {Observable}
    */
  public fileDownload(id: string): Observable<any> {
    return this.http.get(`${this.BASE_URL}/download-chat-file/${id}`, { responseType: 'blob' });
  }
}
