<main class="relative">
  <!-- BEGIN: Navbar Wrapper -->
  <app-consumer-landing-navbar></app-consumer-landing-navbar>

  <!-- END: Navbar Wrapper -->

  <!-- BEGIN: Banner Wrapper -->
  <div class="feature-banner__wrap relative flex items-center justify-center banner-padding-hfull mh-700-height">
    <div class="container mx-auto">
      <div class="flex items-center text-white">
        <div class="grid grid-cols-12 gap-4 md:gap-6 xl:gap-12">
          <div class="col-span-12 xl:col-span-7">
            <div class="mt-8 lg:mt-12">
            <h1 class="feature-banner__head text-semibold text-white fs-bhead text-regular">Designed by advisors for
              advisors, LegacyNOW’s estate data management</h1>
            <p class="feature-banner__content fs-content mt-4 mb-12 text-regular">user interface collects key estate data
              points which are stored in their clients digital vault at the advisor’s ﬁnger tips, making_ it simple and fast
              to assess the client’s profile.</p>
              <div class="btn-wrap">
              <button
                class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">Check
                all Features</button>
              </div>
            </div>
          </div>
          <div class="col-span-12 md:col-span-5 lg:col-span-6"></div>
        </div>
      </div>

    </div>

  </div>
  <!-- END: Banner Wrapper -->

  <!-- BEGIN: Estate Plan Wrapper -->
  <div class="section estate-plan__wrap">
    <div class="grid grid-cols-12 gap-6 xl:gap-12">
      <div class="title__wrap col-span-12 pb-6">
        <h1 class="text-primary text-semibold fs-title max-w-4xl mx-auto text-regular pb-4 text-center">Now, advisors
          can deliver an effective estate plan</h1>
        <p class="fs-content text-regular text-content max-w-4xl mx-auto text-center">During their client’s lifetime and
          a well prepared legacy strategy for resolving the estate at the time of their passing. Providing peace of mind
          for them and the family members.</p>
      </div>
      <div class="col-span-12">
        <picture>
          <source srcset="assets/images/features/estate-plan.webp" type="image/webp">
          <source srcset="assets/images/features/estate-plan.png" type="image/png">
          <img src="assets/images/features/estate-plan.png" width="100%" height="100%" alt="legacy-now">
        </picture>
      </div>
    </div>
  </div>
  <!-- END: Estate Plan Wrapper -->

  <!-- BEGIN: Digital Assets Wrap -->
  <div class="section digital-assets__wrap">
    <div class="grid grid-cols-12 gap-6 xl:gap-12">
      <div class="col-span-12 lg:col-span-6">
        <div class="title__wrap mb-6">
          <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-left">Help your clients consolidate,
            track and pass on their digital Assets.</h1>
          <p class="fs-content text-regular text-content text-left">LegacyNOW patent-pending security digital
            information & distributes the fragments across multiple cloud locations, providing several layers of
            protection.</p>

        </div>
        <div class="grid grid-cols-12 gap-6 xl:gap-12">
          <div class="col-span-12 lg:col-span-6">
            <div class="bg-white p-5 md:p-6 lg:p-10 rounded-xl">
              <img src="assets/images/features/digital-assets/expert-guidance.svg" width="60" height="60"
                alt="Legacy-now">
              <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Expert Guidance</h4>
              <p class="text-regular text-subcontent fs-subcontent">Get ahead of the competition & provide next-gen
                estate digital vault solution to your client and their family members.</p>
            </div>
          </div>
          <div class="col-span-12 lg:col-span-6">
            <div class="bg-white p-5 md:p-6 lg:p-10 rounded-xl">
              <img src="assets/images/features/digital-assets/advisor-dashboard.svg" width="60" height="60"
                alt="Legacy-now">
              <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Advisor Dashboard</h4>
              <p class="text-regular text-subcontent fs-subcontent">Your dashboard provides an easy to read client
                profile for simplified estate assessment & improved strategic legacy planning.
              </p>
            </div>
          </div>
          <div class="col-span-12 lg:col-span-6">
            <div class="bg-white p-5 md:p-6 lg:p-10 rounded-xl">
              <img src="assets/images/features/digital-assets/api-integration.svg" width="60" height="60"
                alt="Legacy-now">
              <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">API Integration</h4>
              <p class="text-regular text-subcontent fs-subcontent">Seamlessly integrate into your current advisor CRM,
                bringing value add to You and your customers without major changes.
              </p>
            </div>
          </div>
          <div class="col-span-12 lg:col-span-6">
            <div class="bg-white p-5 md:p-6 lg:p-10 rounded-xl">
              <img src="assets/images/features/digital-assets/real-time.svg" width="60" height="60" alt="Legacy-now">
              <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Real-Time Messaging</h4>
              <p class="text-regular text-subcontent fs-subcontent">Real-time platform lets you keep in touch with your
                clients & their beneficiaries, as well as collaborate with your client's other estate service providers
                to keep all documents up to date.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-6 xl:pl-12">
        <div>
          <picture>
            <source srcset="assets/images/features/digital-assets/dlb-chart.webp" type="image/webp">
            <source srcset="assets/images/features/digital-assets/dlb-chart.png" type="image/png">
            <img class="float-right rounded-md overflow-hidden"
              src="assets/images/features/digital-assets/dlb-chart.png" width="100%" height="100%" alt="legacy-now">
          </picture>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Digital Assets Wrap -->

  <!-- BEGIN: Advanced Tool Wrap -->
  <div class="section advanced-tool__wrap">
    <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
      <div class="col-span-12 lg:col-span-6">
        <picture>
          <source srcset="assets/images/our-partnership.webp" type="image/webp">
          <source srcset="assets/images/our-partnership.png" type="image/png">
          <img class="float-right rounded-md overflow-hidden" src="assets/images/our-partnership.png" width="100%"
            height="100%" alt="legacy-now">
        </picture>
      </div>
      <div class="col-span-12 lg:col-span-6 pb-8 xl:pl-12 lg:pb-0">
        <h2 class="fs-title text-primary text-semibold mb-6">
          Digital Vault Client Set-Up Your firm can now offer clients an advanced tool
        </h2>
        <p class="fs-content text-content text-regular mb-8">
          which finally digitizes and organizes all of their estate assets. By introducing & assisting your clients in
          setting up a LegacyNOW’s digital vault, you will bring value-add to your customer, the executor, and
          beneficiaries.
        </p>
        <p class="fs-content text-content text-regular mb-8">
          Once set-up is complete the consumer takes possession of their own blockchain protected estate vault, while
          the advisor has their own professional services interface with advanced features which analyze and cleverly
          present all captured data points and
          documents needed to enhance advisory performance.

        </p>
      </div>

    </div>
  </div>
  <!-- END: Advanced Tool Wrap -->

  <!-- BEGIN: Advanced Tool Wrap -->
  <div class="section wealth-transition-tool__wrap">
    <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">

      <div class="col-span-12 lg:col-span-6 xl:pr-12 lg:pb-0">
        <h2 class="fs-title text-primary text-semibold mb-6">
          Wealth Transition Tech Take legacy planning to the next level now.
        </h2>
        <p class="fs-content text-content text-regular mb-8">
          LegacyNOW enables advisors to strengthen client relationships through a family inclusive process. This
          comprehensive solution provides improved legacy planning for your clients and when the time comes, streamlines
          the estate resolution process for the
          executor and beneficiaries.


        </p>
        <p class="fs-content text-content text-regular">
          This technology creates a bonding environment between the advisor and the family members that will transcend
          the passing of your clients, providing a process that naturally retains the next generation as and provides an
          excellent solution client for the
          advisor and the firm to maintaining the transition of wealth.
        </p>
      </div>
      <div class="col-span-12 lg:col-span-6">
        <picture>
          <source srcset="assets/images/our-partnership.webp" type="image/webp">
          <source srcset="assets/images/our-partnership.png" type="image/png">
          <img class="float-right rounded-md overflow-hidden" src="assets/images/our-partnership.png" width="100%"
            height="100%" alt="legacy-now">
        </picture>
      </div>

    </div>
  </div>
  <!-- END: Advanced Tool Wrap -->

</main>
<div class="fixed right-6 bottom-6 group z-50">
  <button type="button" aria-expanded="false" (click)="openModal('assistanceHelp')"
    class="flex items-center justify-center text-white bg-blue-700 rounded-full w-16 h-16 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
    <img src="assets/images/intro-video.png" alt="">
    <span class="sr-only">Open actions menu</span>
  </button>
</div>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>
