<div class="scroll-hidden">
  <div class="h-screen lg:grid grid-cols-12">
    <div class="col-span-12 flex">
      <div class="flex flex-col w-full h-full justify-center items-center ">
        <div class="pt-0 w-full">
          <h1 class="text-semibold text-center text-grey text-3xl mb-4">WE... HAVE A PROBLEM</h1>

          <div class="border-t-2 border-b-2 border-gray-400 w-2/5 mx-auto ">
            <img src="assets/images/common/error404.svg" class="my-12" alt="digital vault">
          </div>
          <h1 class="text-center text-grey text-semibold text-3xl mb-4 pt-5">PAGE NOT FOUND</h1>
          <div class="pt-6 text-center">
            <button type="submit"
              class="button mx-auto button--lg pl-6 pr-6 bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white"
              (click)="changeRoute('dashboard')">
              Go to Home</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>