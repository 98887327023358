import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonHelper} from 'src/app/helper/common.helper';
import {SlugInterceptorService} from 'src/app/helper/slug-interceptor.service';
import {APIResponseModel} from 'src/app/interface/response.interface';
import {CommonService} from 'src/app/services/common.service';
import {LocalStorageService} from 'src/app/services/local-storage.service';
import {DATE_FORMAT} from 'src/constants/application.const';


@Component({
  selector: 'about-yourself-right-content',
  templateUrl: './right-content.component.html',
})
/**
 * Right aside content for all about-us page
 */
export class RightContentComponent implements OnInit {
  @Input() formInputValue?:any;
  @Input() otherValues:any;
  @Input() otherArrayValues?:any;
  @Input() countryCode?:number;
  @Input() buttonStatus:number = 1;
  @Input() tooltip:string;

  public submitLoader:boolean;
  public userType:string;
  public requestId:string;
  public newUser:boolean;
  // We may be removing the button. Doing this until confirmed that this is preferred.
  public useButton: boolean = false;
  /**
   * @constructor
   */
  constructor(
    private commonService:CommonService,
    private commonHelper:CommonHelper,
    private localStorageService:LocalStorageService,
    private router:Router,
    private route: ActivatedRoute,
    private slugInterceptorService:SlugInterceptorService,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.userType=this.localStorageService.getDataByKey('role');
    if (this.userType !== 'Consumer') {
      this.requestId=this.route?.snapshot?.params['id'] || this.route?.firstChild?.snapshot?.params.id;
    }
    if (this.userType === 'Consumer') {
      this.newUser=this.localStorageService.getUserData()?.request;
    }
  }

  /**
   * Save and exit functionality
   */
  public saveAndExit(): void {
    this.submitLoader = true;
    if (this.userType === 'Consumer') {
      const requestData = {
        id: this.otherValues?.id,
        type: this.otherValues?.type,
        section: this.otherValues?.section,
        roletype: this.userType,
        request_id: this.localStorageService.getUserData()?.request.id,
        ...this.formInputValue,
        ...this.otherArrayValues,
      };
      if (requestData.section === 1 && requestData.type === 5) {
        requestData.dob = this.commonHelper.formatDate(requestData.dob, DATE_FORMAT);
        requestData.country_code = this.countryCode;
      } else if (requestData.section === 1 && requestData.type === 1) {
        requestData.dob = this.commonHelper.formatDate(requestData.dob, DATE_FORMAT);
      } else if (requestData.section === 2 && requestData.type === 3) {
        void this.slugInterceptorService.navigate(['dashboard']);
        return;
      }

      // posting to api
      this.commonService.saveExitCommon(requestData).subscribe((response:APIResponseModel)=>{
        this.submitLoader = false;
        if (response.status) {
          this.commonHelper.updateLocalstorageRequestStage(response.data);
          void this.slugInterceptorService.navigate(['/dashboard']);
        }
      }, (exception:any)=>{
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    } else {
      const requestData = {
        id: this.otherValues?.id,
        type: this.otherValues?.type,
        section: this.otherValues?.section,
        roletype: this.userType,
        request_id: this.requestId,
        ...this.formInputValue,
        ...this.otherArrayValues,
      };
      if (requestData.section === 1 && requestData.type === 5) {
        // delete requestData.dob,
        requestData.dob = this.commonHelper.formatDate(requestData.dob, DATE_FORMAT);
        requestData.country_code= this.countryCode;
      } else if (requestData.section === 1 && requestData.type === 1) {
        requestData.dob = this.commonHelper.formatDate(requestData.dob, DATE_FORMAT);
      }

      // posting to api
      this.commonService.saveExitCommon(requestData).subscribe((response:APIResponseModel)=>{
        this.submitLoader = false;
        if (response.status) {
          void this.slugInterceptorService.navigate([ `/dashboard`], null, true);
        }
      }, (exception:any)=>{
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    }
  }

  /**
     * change route
     * @param{string}url
    */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([ url], null, this.userType !== 'Consumer');
  }
}
