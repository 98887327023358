import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { SlugService } from 'src/app/services/slug.service';
import { SocketService } from 'src/app/services/socket.service';
import { UserService } from 'src/app/services/user.service';
import { EMAIL_PATTERN, LOCAL_STORAGE_UPDATE_STATUS, LoginType, RESEND_EMAIL, USER_TYPES } from 'src/constants/application.const';
import { ELEMENT_EVENTS } from 'src/constants/form.const';
import { environment } from 'src/environments/environment';
import { ExecutorCustodianService } from '../../executor-custodian/services/executor-custodian.service';
import { BubbleRadioEmitModel, BubbleRadioOption } from '../../sharedComponent/bubble-radio/bubble-radio.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
/**
 * Login component
 */
export class LoginComponent implements OnInit {
  @ViewChild('resendEmailInput') resendEmailInput: ElementRef;
  // check for demo emails
  public readonly demoEmails: string[] = ['pfiumano@phillips-cohen.com', 'arakovich@estate-registry.ca', 'kesava@mailinator.com', 'gleonard@phillips-cohen.com', 'kuppu@mailinator.com', 'praveen@mailinator.com', 'praveen@sparkouttech.com', 'praveenkumartup@gmail.com'];
  public isDemoEmail: boolean;

  private readonly useOTP = false;
  public resendVerificationModalID = 'resend-verification-modal';
  public readonly loginOptions: BubbleRadioOption[] = [
    { text: 'Digital Vault', id: LoginType.DIGITAL_VAULT },
    { text: 'Digital Vault Custodian/Executor', id: LoginType.CUSTODIAN },
  ];
  public readonly faEye = faEye;
  public readonly faEyeSlash = faEyeSlash;
  public readonly googleCaptchaKey = environment.GOOGLE_CAPTCHA_KEY;
  public selectedLoginOption: BubbleRadioEmitModel;
  public togglePasswordView = false;
  public loginLoader = false;
  public spinner = false;
  public verificationEmail: string;
  public loginForm: FormGroup;
  public submitted: boolean;
  public captchaEnabled: boolean;
  public shortUrl?: string;
  public menuActive: string;
  public orgLogo: string;
  public orgLogoPath: string;
  public defaultSubscriptionAmount: number;
  // recaptcha validation
  isRecaptchaValidationEnable: boolean = false;
  isCaptchaValid: boolean = true;
  public messageData = {
    title: 'Important Message!',
    message: ['Your Email Address has not been verified yet from the email we\'ve sent to you. Please verify it to login.'],
    buttons: [{ content: 'Resend Email', emitValue: { data: RESEND_EMAIL, value: true } }, { content: 'Understood', emitValue: false }],
  };

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private executorCustodianService: ExecutorCustodianService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private socketService: SocketService,
    private slugInterceptorService: SlugInterceptorService,
    private slugService: SlugService,
  ) {

  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.checkLoggedIn();
    this.initUserType();
    this.initSlugObserver();
    this.initForm();
    this.handleShortURL();
    this.verificationEmail = this.localStorageService.getDataByKey('email-verification-sent');
    this.localStorageService.deleteDataByKey('email-verification-sent');
    this.modalService.close('payment-alert-freeTrial-completed');
    this.setRecaptchaValidation();
  }

  /**
   * Initialize form
   */
  private initForm() {
    this.loginForm = this.formBuilder.group({
      'email': ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      'password': ['', [Validators.required]],
      'g-recaptcha-response': ['', [this.isRecaptchaValidationEnable ? Validators.required : Validators.nullValidator]],
    });
  }

  /**
   *
   */
  private handleShortURL() {
    this.shortUrl = this.route?.snapshot?.params['id'];
    if (this.shortUrl) {
      this.spinner = true;
      this.userService.getRegistrationLink(this.shortUrl).subscribe((response: APIResponseModel) => {
        window.location.href = response.data;
        this.spinner = false;
      }, (exception) => this.commonHelper.httpResponseHandler(exception.error),
      );
    }
  }

  /**
   * Iniitialize User Type
   */
  private initUserType() {
    const type = this.route.snapshot.queryParams['role'];
    let index: number = this.loginOptions.findIndex((e) => e.id == type);
    index = index >= 0 ? index : 0;
    this.selectedLoginOption = { index: index, option: this.loginOptions[index] };
  }

  /**
   * Initialize Slug
   */
  private initSlugObserver() {
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      this.orgLogoPath = response ? this.commonHelper.getOrgLogo() : '';
      this.orgLogo = response ? `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}` : '';
    });
  }

  /**
   * Check if any users are logged in.
   */
  checkLoggedIn() {
    const isLoggedInDV = this.localStorageService.getUserData()?.access_token != null;
    const isLoggedInC = this.localStorageService.getUserData(USER_TYPES.exe)?.access_token;
    if (isLoggedInDV) {
      void this.slugInterceptorService.navigate(['dashboard']);
    } else if (isLoggedInC) {
      void this.slugInterceptorService.navigate(['executor-custodian', 'dashboard']);
    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.loginForm.controls;
  }

  /**
   * @param{string}url
   * on menu change
   */
  public clickOnLogo(url?: string) {
    this.menuActive = url;
    void this.slugInterceptorService.navigate(['/']);
    this.commonService.setActiveNavbarTrigger(this.menuActive);
  }

  /**
   * @param{string}url
   * on menu change
   */
  public menuChange(url?: string) {
    if (url) {
      this.menuActive = url;
      void this.slugInterceptorService.navigate([url]);
      this.commonService.setActiveNavbarTrigger(this.menuActive);
    }
  }

  /**
   * verify OTP
   * @param{ApiResponseModel}response
   */
  private verifyOTP(response: APIResponseModel) {
    this.loginLoader = false;
    this.userService.otpTypeObservableEvents(response.data.type);
    this.toastrService.success(response.message);
    void this.slugInterceptorService.navigate(['/verify-otp']);
  }

  /**
   *
   * @param{any} captchaRef
   */
  private loginDV(captchaRef?: any) {
    this.userService.userLogin(this.loginForm.value).subscribe((response: APIResponseModel) => {
      if (response?.['data']?.['user']?.['status'] == 0) {
        this.toastrService.info("Access Denied. Contact Support");
        return;
      }
      this.localStorageService.storeUserData(USER_TYPES.user, response.data);
      this.sessionStorageService.storeDataInSession(USER_TYPES.user, response.data);
      // NOTE : Email Stored in Local Storage without Encryption for Evergage Tracking Purposes.
      localStorage.setItem('email', this.loginForm.value['email']);
      if (response.data.type && this.useOTP) {
        this.verifyOTP(response);
      } else {
        this.loginLoader = false;

        this.userLoginResponse(response);
        this.loginSuccessRedirect();
        this.captchaReset(captchaRef);
      }
    }, (exception) => {
      this.loginLoader = false;
      const isEmailNotVerified = exception?.error?.data?.type === 0;
      console.log(isEmailNotVerified);
      if (isEmailNotVerified) {
        this.openVerificationMessageModal();
      } else {
        this.commonHelper.httpResponseHandler(exception?.error);
      }

      this.localStorageService.updateUserData(USER_TYPES.user, {
        key: 'user',
        updateValue: { email: this.loginForm.value.email },
        type: LOCAL_STORAGE_UPDATE_STATUS.S,
      });
      this.captchaReset(captchaRef);
    });
  }

  /**
   *
   * @param{any} captchaRef
   */
  private loginCustodian(captchaRef?: any) {
    this.executorCustodianService.userLogin(this.loginForm.value).subscribe({
      next: (response: APIResponseModel) => {
        this.userLoginResponse(response);
        this.loginSuccessRedirect();
        this.loginLoader = false;
        this.captchaReset(captchaRef);
      },
      error: (error) => {
        this.loginLoader = false;
        const isEmailNotVerified = error?.error?.data?.type === 0;
        console.log(isEmailNotVerified);

        isEmailNotVerified ? (this.modalService.open('message-modal')) : (this.commonHelper.httpResponseHandler(error?.error));
        this.localStorageService.updateUserData(USER_TYPES.exe, {
          key: 'user',
          updateValue: { email: this.loginForm.value.email },
          type: LOCAL_STORAGE_UPDATE_STATUS.S,
        });
        this.captchaReset(captchaRef);
        console.error('API Error:', error);
        // Handle error, show an alert, etc.
        return ''; // or throwError(error) if you want to propagate the error
      },
    });
  }

  /**
   * Login user
   *
   * @param {any} captchaRef for reseting the captcha field
   */
  public loginUser(captchaRef): void {
    this.loginLoader = true;
    this.loginForm.markAllAsTouched();
    if (!this.loginForm.valid) { // if form not valid, it will return to form else login
      this.loginLoader = false;
      this.isCaptchaValid = !!this.loginForm.value['g-recaptcha-response'];
      return;
    }
    if (this.selectedLogin.id == LoginType.DIGITAL_VAULT) {
      this.loginDV(captchaRef);
    } else if (this.selectedLogin.id == LoginType.CUSTODIAN) {
      this.loginCustodian(captchaRef);
    } else {
      console.error('Unknown selectedLogin', this.selectedLogin);
    }
  }

  /**
   * Handle Login Response.
   * @param{APIResponseModel} response
   * @private
   */
  private userLoginResponse(response: APIResponseModel) {
    const slugDetails = response.data?.slug_details;
    const userDetails = response.data?.user;
    if (this.selectedLogin.id == LoginType.DIGITAL_VAULT) {
      const userEmail = userDetails.email;
      this.isDemoEmail = this.demoEmails.includes(userEmail);
      this.localStorageService.storeData('is-demo-email', this.isDemoEmail);
      this.localStorageService.clearLocalStorage(USER_TYPES.exe);
      this.localStorageService.storeData('role', response.data.user.role_name);
      this.localStorageService.storeData('consumer-email', response.data.user.email);
      this.localStorageService.storeData('is_payment_complete', response.data.user?.is_payment_complete || 0);
      this.localStorageService.storeData('gotRefund', !!response.data.user?.payment_refund_at);
      // this.localStorageService.storeData('is_id_verified', response.data.request ? response.data.request?.is_id_verified : 0);
      this.localStorageService.storeData('form_access', response.data ? +response.data?.admin_settings?.consumer_form_access_type : 1);
      this.localStorageService.storeData('admin_settings', response.data ? response.data?.admin_settings : {});
      this.defaultSubscriptionAmount = Number(this.localStorageService.getDataByKey('admin_settings').subscription_price);
      this.commonHelper.updateProDetailsInLocalStorage(slugDetails, userDetails);
    } else {
      this.localStorageService.clearLocalStorage(USER_TYPES.user);
      this.localStorageService.storeUserData(USER_TYPES.exe, response.data);
      this.sessionStorageService.storeDataInSession(USER_TYPES.exe, response.data);
      this.localStorageService.storeData('role', 'Custodian');
      this.localStorageService.storeData(response.data.user.role === 1 ? 'executor-email' : 'custodian-email', response.data.user.email);
      this.localStorageService.storeData('is_challenge_verified', response.data.user?.is_challenge_verified ?? 0);
    }
    this.commonHelper.setDomainCountryInLocal(response?.data?.country[0]?.id).subscribe();
    this.localStorageService.clearLocalStorage(USER_TYPES.pro);
    this.localStorageService.clearLocalStorage(USER_TYPES.org);
    this.toastrService.success(response.message);
    this.socketService.setUseSocket(true);
  }

  /**
   * Redirect user to either dashboard or security-questions after a successful login.
   * @private
   */
  private loginSuccessRedirect() {
    if (this.selectedLogin.id == LoginType.DIGITAL_VAULT) {
      void this.slugInterceptorService.navigate([this.isDemoEmail ? 'about-yourself' : 'dashboard']);
      return;
    } else {
      const redirectPath = this.localStorageService.getDataByKey('redirect-url');
      this.executorCustodianService.checkSecurityQuestionsStatus().subscribe({
        next: (response: APIResponseModel) => {
          const questionsSet = response.data['is_security_questions_set'];
          const custodianAccess = response.data['access_to_custodian'];
          if (!questionsSet && custodianAccess) {
            void this.slugInterceptorService.navigate(['executor-custodian', 'security-questions']);
          } else {
            void this.slugInterceptorService.navigateByUrl(redirectPath ? `dlb-dashboard/${this.commonHelper.encodeDecodeRequestId(redirectPath, true)}` : 'executor-custodian/dashboard');
          }
        },
      });
    }
    this.localStorageService.storeData('logout-event', Date.now().toString());
    this.localStorageService.clearLocalStorage('logout-event');
  }

  /**
   * resolve captcha google
   *
   * @param {string} captchaResponse
   */
  public resolvedCaptcha(captchaResponse?: string): void {
    if (!captchaResponse) return;
    this.loginForm.patchValue({
      'g-recaptcha-response': captchaResponse,
    });
    this.isCaptchaValid = !!this.loginForm.value['g-recaptcha-response'];
  }

  /**
   * catch error captcha google
   *
   * @param {string} errorDetails
   */
  public onErrorCaptcha(errorDetails?: RecaptchaErrorParameters): void {
    console.log(errorDetails);
  }

  /**
   * reset captcha
   *
   * @param {any} captchaRef
   */
  public captchaReset(captchaRef?: any): void {
    captchaRef?.reset();
    this.loginForm.get('g-recaptcha-response')?.reset();
  }

  /**
   * on Register
   */
  public onRegister() {
    const isLoggedIn = typeof this.localStorageService?.getUserData()?.access_token !== 'undefined'; // Only logged-in user has access_token
    const currentSession = this.sessionStorageService.getDataFromSession('user');
    if (isLoggedIn && currentSession) {
      this.modalService.open('user-logged-in');

      setTimeout(() => {
        void this.slugInterceptorService.navigate(['dashboard']);
        this.modalService.close('user-logged-in');
      }, 2000);
    } else {
      void this.slugInterceptorService.navigate(['educational-intro']);
    }
  }

  /**
   * on click
   * @param{string}type
   */
  public onClick(type: string) {
    switch (type) {
      case 'register':
        this.onRegister();
        break;
      case 'forgot-password':
        this.menuChange('forgot-password');
        void this.slugInterceptorService.navigate(['forgot-password'], { queryParams: { role: this.selectedLogin.id } });
        break;
      case 'privacy-policy':
        this.menuChange('privacy-policy');
        break;
      case 'user-agreement':
        this.menuChange('user-agreement');
        break;
      case 'reset-verification':
        this.modalService.open(this.resendVerificationModalID);
        break;
      default:
        break;
    }
  }

  /**
   * typing / onchange input field
   * changed as untouched field
   * not show validation error
   * @param {any} data
   * @param {string} key
   */
  public onChange(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.INPUT) {
      this.loginForm.get(key).markAsUntouched();
    }
  }
  /**
   * afterviewcheck
   * session expiry modal strucks even logout, checks and close the modal
   */
  ngAfterViewChecked() {
    if (this.modalService.isOpen('session_expiry_modal')) {
      this.modalService.close('session_expiry_modal');
    }
  }

  /**
   * focusout of input field
   * changed as touched field
   * check and show validation error
   * @param {any} data
   * @param {string} key
   */
  public onFocusout(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.FOCUSOUT) {
      this.loginForm.get(key).markAsTouched();
    }
  }

  /**
   * Listener for Message Modal Events
   * @param{any} event
   */
  public messageModalListener(event: any) {
    this.modalService.close('message-modal');
    const userType = this.selectedLogin.id;
    if (event?.value) {
      this.resendVerificationEmail(this.loginForm.get('email').value, userType);
    }
  }

  /**
   * Listener for Resend Verification Email Modal Events
   * 
   * @param{any} event
   */
  public verificationModalListener(event: any) {
    if (event?.$resend_verify_email) {
      const email = event?.$resend_verify_email
      this.resendVerificationEmail(email, this.selectedLogin.id)
    }
  }

  /**
   *
   */
  public closeResendVerificationModal() {
    this.modalService.close(this.resendVerificationModalID);
  }

  /**
   *
   */
  private openVerificationMessageModal() {
    this.modalService.open('message-modal');
  }

  /**
   * resend email to vault holder
   * @param{string}email
   * @param{LoginType}type
   */
  public resendVerificationEmail(email: string, type: LoginType) {
    console.log(email);
    const observer = {
      next: (response: APIResponseModel) => {
        if (response.status) {
          this.toastrService.success(response.message);
        }
      },
      error: (exception: any) => {
        this.toastrService.error('Account not Found.');
        console.log('error', exception);
      },
    };

    if (type == LoginType.DIGITAL_VAULT) {
      this.userService.resendEmail({ email }).subscribe(observer);
    } else if (type == LoginType.CUSTODIAN) {
      this.executorCustodianService.resendEmail({ email }).subscribe(observer);
    }

    this.closeResendVerificationModal();
  }

  /**
   * @param{{}} event
   */
  onLoginOptionClick(event: { index: number, option: BubbleRadioOption }) {
    this.selectedLoginOption = event;
  }

  /**
   * @return{BubbleRadioOption}
   */
  get selectedLogin(): BubbleRadioOption {
    return this.selectedLoginOption.option;
  }
  /**
   * check slug details present and if true, hide register option
   * @returns{boolean}
   */
  get hasNotSlugDetails(): boolean {
    return !!(!this.orgLogo)
  }

  /**
   * Accessor to expose enum in template
   * @constructor
   */
  get LoginType(): typeof LoginType {
    return LoginType;
  }

  /**
  * Sets up the reCAPTCHA validation for the  form.
  * Fetches the reCAPTCHA configuration and sets the validation accordingly.
  */
  public setRecaptchaValidation(): void {
    this.commonHelper.getCaptchaConfig().subscribe({
      next: (response) => {
        this.isRecaptchaValidationEnable = response?.data['is_enabled'];
        this.loginForm.get('g-recaptcha-response').setValidators(this.isRecaptchaValidationEnable ? Validators.required : Validators.nullValidator);
      }
    })
  }
}
