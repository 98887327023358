<div class="space-y-3">
    <ng-container>
        <div class="border p-4 border-grey flex">
            <div class="flex flex-row" (click)="viewProfessionalDetails()">
                <p *ngIf="professionalPeople?.length <= 0"
                    class="cursor-pointer text-sm lg:text-base text-regular text-label-grey">
                    Select Professionals
                </p>
            </div>
            <div class="flex flex-grow items-start">
                <app-chip-list-people [lpSettings]="lpSettings" (clickEmitter)="clickListener($event)"
                    [userPeopleData]="professionalPeople">
                </app-chip-list-people>
                <img class="ml-auto cursor-pointer w-5" (click)="viewProfessionalDetails()"
                    src="assets/images/about-yourself/edit.svg" alt="Edit" />
            </div>
        </div>
    </ng-container>
</div>


<app-choose-professional-modal [professionalPeopleData]="choooseProSettings.professional_people"
    [cpcSettings]="cpcSettings" [loadListener]="lpSettings.isLoading"
    (updateEmitter)="listenerAssignProfessionals($event)">
</app-choose-professional-modal>