import { Component, HostListener, OnInit } from '@angular/core';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonService } from 'src/app/services/common.service';


  @Component({
    selector: 'app-consumer-landing-steps',
    templateUrl: './consumer-landing-steps.component.html',
    styleUrls: ['./consumer-landing-steps.component.css'],
  })
  /**
   * landing
   */
export class ConsumerLandingStepsComponent implements OnInit {
    public menuActive: string;
    public isFooterLink: boolean;
    private innerWidth: number;

    /**
     * constructor
     */
    constructor(
      private slugInterceptorService: SlugInterceptorService,
      private commonService: CommonService) { }

    /**
     * loaded initialy
     */
    ngOnInit(): void {
      this.updateInnerWidth();
    }


    /**
   * Navigates to the registration page.
   */
    public navigateToRegister() {
      this.slugInterceptorService.navigate(['register']);
    }


    /**
   * isSmallWindow
   */
    get isSmallWindow(): boolean {
      return this.innerWidth < 800;
    }
    /**
   * updateInnerWidth
   */
    private updateInnerWidth() {
      this.innerWidth = window.innerWidth;
      console.debug('inner width', this.innerWidth);
    }
  /**
   * windowResizeListener
   */
  @HostListener('window:resize', [])
    windowResizeListener() {
      this.updateInnerWidth();
    }
}
