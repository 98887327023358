import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckoutService } from 'src/app/components/checkout/checkout.service';
import { PaymentOption } from 'src/app/components/checkout/payment-option.model';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { UserInfoInterface } from 'src/app/interface/common.interface';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SlugService } from 'src/app/services/slug.service';
import { USER_TYPES } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { APIResponseModel } from '../../../interface/response.interface';
import { PersonalDetailsService } from '../../../services/personal-details.service';
import { ReceivedDlbService } from '../../../services/received-dlb.service';
import { SocketService } from '../../../services/socket.service';


@Component({
  selector: 'app-exe-cus-navbar',
  templateUrl: './exe-cus-navbar.component.html',
})

/**
 * Executor Custodian Dashboard Navbar
 */
export class ExeCusNavbarComponent implements OnInit {
  public readonly faCheckCircle = faCheckCircle;
  public readonly faCircleXmark = faCircleXmark;
  public userType: string;
  private vaultClaimDate: Date;
  public profileMenuToggle: boolean = false;
  public mobileMenuToggle: boolean = false;
  public currentPage: string;
  public currentUser: any;
  public requestId: string;
  public imageSrc: string;
  public logoutLoader: boolean;
  public isVaultClaimed: boolean;
  public downloadVault: SafeResourceUrl;
  public userName: UserInfoInterface;
  public slugLogo: string;
  public slugLogoPath: string;
  public dlbDataLoader: boolean = true;
  @Input() showLogoOnly: boolean = false;
  @Input() useCensor: boolean = false;
  @ViewChild('profileMenu') profileMenuRef: ElementRef;
  public isVerifySecurityPage: boolean;
  private transferDataPaid: boolean = false;
  public dataLinkPaymentOption: PaymentOption;

  /**
   * @constructor
   */
  constructor(
    private commonHelper: CommonHelper,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private slugInterceptorService: SlugInterceptorService,
    private personalDetailsService: PersonalDetailsService,
    private slugService: SlugService,
    private sanitizer: DomSanitizer,
    private receivedDlbService: ReceivedDlbService,
    private checkoutService: CheckoutService,
    private socketService: SocketService
  ) {
    document.addEventListener('click', this.offClickHandler.bind(this));
  }

  /**
   * Called initially
   */
  ngOnInit(): void {
    this.userType = this.localStorageService.getDataByKey('role');
    const requestIdRAW = this.route.snapshot.params['id'];
    const decodedRequestId = this.commonHelper.encodeDecodeRequestId(requestIdRAW, false);
    this.requestId = decodedRequestId || requestIdRAW;
    this.currentPage = this.activatedRoute.snapshot.url[0]?.path;
    this.currentUser = this.localStorageService.getUserData(USER_TYPES.exe);
    this.getUserImage();
    this.getVaultClaimInformation();
    this.changeRouter();
    this.getPaymentOptions();
    this.isVerifySecurityPage = this.localStorageService.getDataByKey('is_verify_security_page');
    this.socketService.listenPaymentStatus().subscribe(() => this.initTransferDataPaid());
    this.slugLogoPath = this.commonHelper.getOrgLogo();
    this.slugLogo = `${environment.BASE_URL_RAW}uploads${this.slugLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.slugLogoPath = this.commonHelper.getOrgLogo();
        this.slugLogo = `${environment.BASE_URL_RAW}uploads${this.slugLogoPath}`;
      } else {
        this.slugLogoPath = '';
        this.slugLogo = '';
      }
    });
  }

  private initTransferDataPaid() {
    this.receivedDlbService.isTransferPaid(this.requestId).subscribe({
      next: r => this.transferDataPaid = true,
      error: console.error,
    });
  }

  /**
   * To hide profile menu when clicking elsewhere on the page.
   * @param{any} event
   */
  private offClickHandler(event: any) {
    if (!this.profileMenuRef.nativeElement.contains(event.target)) {
      this.profileMenuToggle = false;
    }
  }

  /**
   * Get vault download link
   * @private
   */
  private getDownloadLink() {
    this.downloadVault = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.BASE_URL_RAW}requests/${this.requestId}/${this.userName.firtName}-${this.userName.lastName}/${this.userName.firtName}-${this.userName.lastName}-Digital-Vault.zip`);
  }

  /**
   * Get user profile image
   * @private
   */
  private getUserImage() {
    this.imageSrc = `${environment.BASE_URL_RAW}uploads/${this.currentUser?.user?.upload_logo}`;
    this.commonService.profilePicObserve.subscribe((data) => {
      if (!data) return;
      this.currentUser = this.localStorageService.getUserData(USER_TYPES.exe);
      this.imageSrc = `${environment.BASE_URL_RAW}uploads/${this.currentUser?.user?.upload_logo}`;
    });
  }

  /**
   *  Get Vault claim information
   */
  private getVaultClaimInformation() {
    if (this.requestId) {
      this.personalDetailsService.getPersonalDetails(this.requestId).subscribe((response: APIResponseModel) => {
        this.isVaultClaimed = response['data']['is_vault_claimed'];
        this.vaultClaimDate = new Date(response['data']['vault_claimed_at'].replace(/-/g, '/'));
        this.userName = {
          firtName: this.commonHelper.removeAlphaNumericCharacters(String(response['data']['user']['first_name'])),
          lastName: this.commonHelper.removeAlphaNumericCharacters(String(response['data']['user']['last_name'])),
        };
        this.getDownloadLink();
        this.dlbDataLoader = false;
      }, (e) => {
        this.dlbDataLoader = false;
        console.error(e);
      });
    }else{
      this.dlbDataLoader = false;
    }
  }

  /**
   * Change router url
   * @param {string} url
   */
  public changeRouter(url?: string): void {
    this.currentPage = this.activatedRoute.snapshot.url[0]?.path;
    if (url) {
      void this.slugInterceptorService.navigate([url]);
    }
  }
  /**
   * {@link CommonService.getContactUsDetails getContactUsDetails}
   */
  private getPaymentOptions() {
    this.checkoutService.getPaymentOptions().subscribe({
      next: options => {
        this.dataLinkPaymentOption = options.find(e => 'dataLinkFee' == e.id);
      },
      error: e => this.commonHelper.httpResponseHandler(e.error),
    });
  }

  /**
    *pay for transfer vault
    */
  public payNow(): void {
    this.checkoutService.setCart([this.dataLinkPaymentOption.id]);
    void this.slugInterceptorService.navigate(['executor-custodian/checkout']);

  }

  /**
   * Logout user
   */
  public logout(): void {
    this.logoutLoader = true;
    this.commonHelper.logoutCommon(USER_TYPES.exe);
  }

  /**
   * Returns to dashboard
   */
  public returnToDashBoard(): void {
    void this.slugInterceptorService.navigate(['/executor-custodian/dashboard']);
  }

  /**
   * change route
   * @param{string}url
   * @param{boolean}query
   */
  public changeRoute(url: string, query?: {}) {
    query = query ? { queryParams: query } : null;
    void this.slugInterceptorService.navigate([url], query);
  }

  /**
   * Enables digital vault
   * After Vault claimed and paid transfer vault 
   */
  public get disableDownloadVault():boolean {
     return this.isVaultClaimed && this.useCensor;
  }

  /**
   * Get show return dashboard option
   */
  public get showReturnDashboardOption(): boolean {
    return this.currentPage !== 'dashboard' && (this.isVerifySecurityPage || this.currentPage !== 'security-questions');
  }

  get profileMenuImage(): string {
    return this.currentUser?.user?.['upload_logo'] ? this.imageSrc : 'assets/images/navbar/profile-avatar.png';
  }

  get showMobileMenu(): boolean {
    return !this.showLogoOnly && (this.showReturnDashboardOption || this.showDownloadVault);
  }

  get showDownloadVault(): boolean {
    return this.currentPage !== 'dashboard' && this.isVaultClaimed && this.transferDataPaid;
  }
}
