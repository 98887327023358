<app-common-modal id="bulk-import-client-modal" class="app-common-modal">
  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModal()">
    <form [formGroup]="importClientBulkForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Bulk Import {{importTerm !== 'manager' ? 'Clients' : commonHelper?.capitalizeFirstLetter(importTerm)}}
          </h4>

          <p *ngIf="importTerm !== 'manager'" class="text-grey text-regular pt-1 text-sm xl:text-lg">Upload an XLSX or CSV file to bulk import your <span class="text-grey text-semibold text-regular pt-1 text-sm xl:text-lg">{{importTerm ==='employee'? 'new' : 'existing' }}</span> clients.
          </p>
          <p class="text-grey text-regular pt-1 text-sm xl:text-lg">Not sure about the file format? Download our example file:</p>
          <p *ngIf="importTerm === 'manager'" class="text-grey text-regular pt-1 text-sm xl:text-lg">Please upload csv/xlsx file to bulk import the
            existing {{importTerm}}s.
          </p>

          <ng-container *ngIf="this.userRole===PROFESSIONAL_ROLE.professional && (this.importTerm==='client' || this.importTerm==='employee') ">
            <!-- Sample CSV:New Clients -->
            <p *ngIf="this.importTerm ==='employee'" class="text-regular text-blue-600 pt-1 text-sm xl:text-lg"><a
                class="underline"
                href="assets/csv/Sample Template File for Bulk Import of {{commonHelper?.capitalizeFirstLetter(importTerm)}}s.xlsx"
                download="">Download Example CSV</a> 
            </p>
            <!-- Sample CSV:Existing Clients -->
            <p *ngIf="this.importTerm === 'client'"
              class="text-regular text-blue-600 pt-1 text-sm xl:text-lg"><a class="underline"
                href="assets/csv/Sample Template File 1 for Bulk Import of {{commonHelper?.capitalizeFirstLetter(importTerm)}}s.xlsx"
                download="">Download Example CSV</a>
            </p>
          </ng-container>

        <!-- Onboard Type -->
        <div class="w-full my-3 mt-5" *ngIf="this.userRole===PROFESSIONAL_ROLE.professional && this.importTerm==='employee'">
          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
            <div class="col-span-12 md:col-span-10 lg:col-span-10">
              <div class="flex items-center space-x-2">
              <label for="id" class="text-grey text-sm xl:text-lg text-regular md:whitespace-nowrap"><span class="font-sans font-bold">How would you like to send the onboarding<span class="whitespace-nowrap"> link ? </span></span>*</label>
            </div>
              <div class="md:flex justify-between items block mt-2">
                <div id="radio" class="form-check" *ngFor="let onboardType of onboardTypes;let i=index">
                  <input class="form-check-input text-regular text-grey" type="radio" name="radios" id="radios_{{i}}"
                    [value]="onboardType?.value" (change)="onSelectOnboardType($event)"
                    [checked]="selectedChecked('onboard',onboardType?.value)">
                  <label class="form-check-label text-semibold text-grey placeholder-gray-400 text-xl"
                    for="radios_{{i}}">
                    {{onboardType?.displayText}}
                  </label>
                </div>
              </div>
            </div>
                        
            <!-- Copy Onboarding Link -->
            <div class="col-span-12 md:col-span-12 lg:col-span-12" *ngIf="selectedOnboardType !== 1 && generatedUrl">
              <label for="id" class="text-sm xl:text-lg mb2 text-regular text-label-grey">Your Onboarding Link</label>
              <div class="flex flex-col md:flex-row items-center gap-4 my-2">
                <input type="text" id="onboarding_link" [readOnly]="true" [value]="generatedUrl"
                  class="input mt-1 w-full rounded-xs h-12 text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base xl:text-regular">
                <app-button-loader [buttonText]="'Copy'" [valid]="generatedUrl" [buttonType]="'button'" (click)="copyToClipboard()">
                </app-button-loader>
              </div>
            </div>
          </div>
        </div>

          <!-- <div *ngIf="this.importTerm==='client' || this.importTerm==='employee'">
          <p *ngIf="this.importTerm==='employee'" class="text-grey text-regular pt-1 text-sm xl:text-lg">Click <a class="underline"
              href="assets/csv/Sample Template File for Bulk Import of {{commonHelper?.capitalizeFirstLetter(importTerm)}}s.xlsx"
              download="">here</a> to download the ...employee..
            sample
            file
          </p>
          <p *ngIf="(this.userRole=== PROFESSIONAL_ROLE.manager || this.userRole===PROFESSIONAL_ROLE.professional)&& this.importTerm!='employee'"
            class="text-grey text-regular pt-1 text-sm xl:text-lg">Click <a class="underline"
              href="assets/csv/Sample Template File 1 for Bulk Import of {{commonHelper?.capitalizeFirstLetter(importTerm)}}s.xlsx"
              download="">here</a> to download the ..agent..
            sample
            file
          </p>
          <p *ngIf="(this.userRole=== PROFESSIONAL_ROLE.agent && this.importTerm!=='employee')" class="text-grey text-regular pt-1 text-sm xl:text-lg">Click <a
              class="underline"
              href="assets/csv/Sample Template File 2 for Bulk Import of {{commonHelper?.capitalizeFirstLetter(importTerm)}}s.xlsx"
              download="">here</a> to download the
            sample
            file
          </p>
        </div>
        <div *ngIf="this.importTerm==='manager'">
          <p class="text-grey text-regular pt-1 text-sm xl:text-lg">Click <a
            class="underline"
            href="assets/csv/Sample Template File for Bulk Import of {{commonHelper?.capitalizeFirstLetter(importTerm)}}s.xlsx"
            download="">here</a> to download the
          sample
          file
        </p>
        </div> -->
        </div>
          <div class="col-span-12">
            <div *ngIf="this.importTerm ==='employee' || this.importTerm === 'client' else otherBlock">
              <label for="id" class="text-grey text-sm xl:text-lg text-regular mb4"><span class="font-sans font-bold">Instructions:</span></label>
            <ol class="list-decimal text-grey text-regular text-sm xl:text-lg ml-5">
            <li>Download the example file to view.</li>
            <li>Preview your data in the same format as the example.</li>
            <li>Save your file as XLSX or CSV.</li>
            <li>Click "Select File to Upload" or drop the file below to begin the import process.</li>
            </ol>
            </div>
            <ng-template #otherBlock>
              <label for="id" class="text-grey text-sm xl:text-lg text-regular"><span class="font-sans font-bold">Upload File</span></label>
              <p class="text-grey text-regular pt-1 text-sm xl:text-lg">
                Accepted File Formats — xlsx, csv
              </p>
              <p class="text-grey text-regular pt-1 text-sm xl:text-lg">
                Maximum File Size — 10 MB.
              </p>
            </ng-template>
            <div>
              <form [formGroup]="importClientBulkForm" autocomplete="off">
                <div class="col-span-12 mt-6">
                  <app-file-upload-handler (fileUploadChangeEmitter)="getFileData($event)"
                    [fileValidationOptions]="fileValidationOptions" [imageFormatFiltering]="imageFormatFiltering">
                  </app-file-upload-handler>
                </div>
                <!-- <label class="flex items-center" *ngIf="importTerm!=='employee'">
                  <input type="checkbox" class="form-checkbox h-5 w-5 text-darkblue focus:outline-none"
                    (change)="onCheck($event)" [checked]="checked">
                  <span class="text-darkgrey text-base text-regular ml-4">Ignore First Row
                  </span>
                </label> -->
              </form>
            </div>
          </div>
      </div>
      <div class=" flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 ">
        <app-button-loader
          [buttonText]="getButtonText()"
          [valid]="importClientBulkForm.valid" [loader]="submitLoader" [buttonType]="'submit'">
        </app-button-loader>
      </div>
    </form>
    <!-- END: Leftside Card -->
  </div>
</app-common-modal>
