import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonService } from 'src/app/services/common.service';
import { CommonHelper } from '../../helper/common.helper';
import { SelectSearchComponentt } from '../select-search/select-search.component';


@Component({
  selector: 'app-state-search-component',
  templateUrl: '../select-search/select-search.component.html',
  styleUrls: ['../select-search/select-search.component.css'],
})
/**
 * State search component
 */
export class StateSearchComponentComponent extends SelectSearchComponentt implements OnInit, OnChanges {
  @Input() countryCode: number;
  @Input() clearSelected: string;
  @Input() enableError: boolean;
  @Input() defaultSelected: any;
  @Output() optionChangeEmitter = new EventEmitter<SelectOptionsInterface>();
  private visitedCountries: any = {};
  public items: Array<SelectOptionsInterface>;


  /**
   * @constructor
   */
  constructor(
    private commonServices: CommonService,
    private commonHelper: CommonHelper,
    public _eref: ElementRef,
  ) {
    super(_eref);
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.initialPlaceholder = 'Select your State ';
    this.placeholder = 'Search your State here';
    this.items = [];
    this.showSearchBox = true;
  }


  /**
   * triggered when changes occur
   *
   * @param {SimpleChanges} simpleChanges
   */
  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges?.countryCode?.currentValue) {
      const tempCountryCode = simpleChanges?.countryCode?.currentValue || this.countryCode;
      this.getStatesBasedOnCountry(tempCountryCode);
      this.checkCachedValueStateList(tempCountryCode, simpleChanges?.defaultSelected?.currentValue);
    }
    if (simpleChanges?.defaultSelected?.currentValue) {
      const tempCountryCode = simpleChanges?.countryCode?.currentValue || this.countryCode;
      this.checkCachedValueStateList(tempCountryCode, simpleChanges?.defaultSelected?.currentValue);
    }
    if (simpleChanges?.defaultSelected?.previousValue && !simpleChanges?.defaultSelected?.currentValue) {
      this.clearSelectedOption();
    }
  }


  /**
   * check for cached value for state list
   *
   * @param {any} countryCode
   * @param {any} stateCode
   */
  private checkCachedValueStateList(countryCode: any, stateCode: any): void {
    if (!this.visitedCountries[countryCode]) {
      this.getStatesBasedOnCountry(countryCode);
    }
    if (typeof stateCode === 'string') {
      delete this.visitedCountries[countryCode];
      this.pushItem({ displayText: stateCode, value: stateCode });
      this.onSelectPreProcess(stateCode);
    } else if (stateCode) {
      this.setItems(this.visitedCountries[countryCode]);
      this.onSelectPreProcess(stateCode);
    }
  }


  /**
   * Modifies {@link SelectSearchComponentt.items items} & ensures items is sorted properly.
   * Only update items using one of the following functions:<br>
   * {@link setItems}<br>
   * {@link pushItem}
   * @param {Array<SelectOptionsInterface>} items
   * @private
   */
  private setItems(items: Array<SelectOptionsInterface>) {
    this.items = items?.sort(this.commonHelper.compareDisplayText);
  }


  /**
   * Modifies {@link SelectSearchComponentt.items items} & ensures items is sorted properly.
   * Only update items using one of the following functions:<br>
   * {@link setItems}<br>
   * {@link pushItem}
   * @param {SelectOptionsInterface} item
   * @private
   */
  private pushItem(item: SelectOptionsInterface) {
    if (Array.isArray(this.items)) {
      this.setItems([...this.items, item]);
    }
  }


  /**
   * Get State based on country id
   *
   * @param {number} countryId
   */
  public getStatesBasedOnCountry(countryId: number): void {
    if (!countryId) return;
    this.loader = true;
    this.commonServices.getStates(countryId).subscribe({
      next: (response: APIResponseModel) => this.stateListFormatter(response?.data || [], countryId),
      complete: () => this.loader = false,
    });
  }


  /**
   * State list formatter
   *
   * @param {Array<any>} list
   * @param {number} countryId
   */
  private stateListFormatter(list: Array<any>, countryId: number): void {
    const formattedCountryList: Array<SelectOptionsInterface> = [];
    list.forEach((data) => {
      formattedCountryList.push({ displayText: data.name, value: data.id });
    });
    this.setItems(formattedCountryList);
    this.visitedCountries[countryId] = [...formattedCountryList];
    // Select state of the defaultSelected value is available
    if (this.defaultSelected) {
      this.onSelectPreProcess(this.defaultSelected);
    }
  }


  /**
   * listen for options changes
   *
   * @param {string} selectedCountry
   */
  public optionChangeListener(selectedCountry: SelectOptionsInterface): void {
    this.optionChangeEmitter.emit(selectedCountry);
  }


  /**
   * On select change
   *
   * @param {string} value
   */
  public onSelectChange(value: string): void {
    super.onSelectPreProcess(value);
    this.optionChangeListener(this.selectedOption);
  }
}
