import { Component, HostListener, OnInit } from '@angular/core';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-deactivated-account',
  templateUrl: './deactivated-account.component.html',
  styleUrls: ['./deactivated-account.component.css']
})
export class DeactivatedAccountComponent implements OnInit {
  public userType: any;
  public mobile: boolean = false;
  public isDeactivated: any;

  constructor(private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService) { }

  ngOnInit(): void {
    let currentUser = this.localStorageService.getUserData();
    this.userType = this.localStorageService.getDataByKey('role');
    // if admin_death_verify is 3, which means the admin approved users Deceased
    this.isDeactivated = currentUser?.['request']?.['admin_death_verify'];
    if (this.isDeactivated != 3) {
      void this.slugInterceptorService.navigate(['/dashboard']);
    }
  }


  /**
   * window resize
   */
  @HostListener('window:resize')
  onResize() {
    const innerWidth = window.innerWidth;
    this.mobile = innerWidth <= 767;
  }
}
