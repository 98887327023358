import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { ExpiryNotificationTsGuard } from 'src/app/helper/expiry-notification.helper.ts.guard';
import { JwtInterceptor } from 'src/app/helper/jwtInterceptor.helper';
import { JwtInterceptorProfessional } from 'src/app/helper/jwtInterceptorProfessional.helper';
import { FileTypeIconsPipe } from 'src/app/pipes/file-type-icons.pipe';
import { SharedModule } from 'src/app/sharedComponent/shared.module';
import { ProfessionalLoginComponent } from './professional-login/professional-login.component';
import { ProfessionalForgotPasswordComponent } from './professional-forgot-password/professional-forgot-password.component';
import { ProfessionalRegisterComponent } from './professional-register/professional-register.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [
    ProfessionalLoginComponent,
    ProfessionalForgotPasswordComponent,
    ProfessionalRegisterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    SharedModule,
    FontAwesomeModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorProfessional, multi: true },
    ExpiryNotificationTsGuard,
    CookieService,
    FileTypeIconsPipe,
    DatePipe,
   ],

})
/**
 * professional Auth module
 */
export class ProfessionalAuthModule {

}
