<div class="scroll-hidden background-color-grey">

  <!-- Navbar -->
  <app-navbar *ngIf="isConsumer"></app-navbar>
  <app-exe-cus-navbar *ngIf="isCustodian"></app-exe-cus-navbar>

  <div class="grid grid-cols-12 px-12 gap-4 sm:gap-6 md:gap-8 p-6 md:p-8 lg:px-12 xl:px-24">
    <!-- BEGIN: Leftside Content -->
    <div class="col-span-12 lg:col-span-7">
      <div class="w-full">
        <h2 class="text-darkblue text-xl lg:text-2xl xl:text-4xl uppercase text-semibold pt-1">Payment Details</h2>
        <p class="text-md text-grey py-2 tracking-wide text-regular">Complete payment by providing payment details
        </p>
        <p class="border-b-8 w-24 border-red mb-3"></p>
      </div>
    </div>

    <div class="col-span-12 lg:col-span-7 space-y-8">
      <!-- Saved Cards -->
      <div class="border-card bg-white rounded-md" *ngIf="cards?.length">
        <div class="px-4 px-6 my-4">
          <div class="grid grid-cols-12 gap-2 items-center">
            <div class="col-span-11">
              <div class="flex items-center justify-between gap-4">
                <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                  Saved Cards
                </h4>
                <!-- Manage cards to navigate saved card page -->
                <button (click)="navigateToSavedCards()"
                        class="flex items-center justify-center w-auto whitespace-no-wrap underline py-2 text-orange rounded text-sm tracking-wide text-regular font-semibold text-center cursor-pointer col-span-3">
                  Manage Cards
                </button>
              </div>
            </div>
            <div class="col-span-1 ml-auto cursor-pointer" (click)="toggleSectionBody = !toggleSectionBody">
              <img src="assets/images/dashboard/angle-up-arrow.png" *ngIf="!toggleSectionBody" class="w-4 float-right"
                   alt="digital vault">
              <img src="assets/images/dashboard/angle-down-arrow.png" *ngIf="toggleSectionBody" class="w-4 float-right"
                   alt="digital vault">
            </div>

          </div>
          <ng-container *ngFor="let card of cards;let i = index">
            <ng-container *ngIf="!toggleSectionBody && card['is_default'] == 1"> <!-- 1 is for default card -->
              <div class="w-full my-3">
                <div class="flex flex-col sm:flex-row gap-3 sm:items-center justify-between">
                  <div class="sm:flex">
                    <div class="flex items-center gap-3">
                      <img src="assets/images/payment/visa..svg" class="w-6 h-6 mr-4" alt="visacard">
                    </div>
                    <p class="text-regular text-black font-normal">XXXX XXXX XXXX {{ card['card_last_four'] }}</p>
                  </div>

                  <div class="mr-3">
                    <p>{{ card['exp_date'] }} </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <!-- Card Details -->
      <div class="border-card bg-white rounded-md" *ngIf="!cards?.length">
        <div class="px-4 px-6 my-4">
          <div class="grid grid-cols-12 gap-2 items-center">
            <div class="col-span-10">
              <app-shimmer-loading [loading]="preDefinedLoader" class="w-50 h-12">
                <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                  Enter Card Details
                </h4>
              </app-shimmer-loading>
            </div>
            <div class="col-span-2 cursor-pointer" (click)="toggleSectionBody = !toggleSectionBody">
              <img src="assets/images/dashboard/angle-up-arrow.png" *ngIf="!toggleSectionBody"
                   class="w-4 float-right" alt="digital vault">
              <img src="assets/images/dashboard/angle-down-arrow.png" *ngIf="toggleSectionBody"
                   class="w-4 float-right" alt="digital vault">
            </div>

          </div>
          <ng-container *ngIf="!toggleSectionBody">
            <div class="w-full my-3">
              <form [formGroup]="paymentForm" (ngSubmit)="initiatePayment()" autocomplete="off">
                <div class="grid grid-cols-12 gap-4 md:gap-6">
                  <div class="col-span-12">
                    <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                      <label for="card_number" class="text-label-grey text-sm text-regular mb-2">Card Number</label>
                      <input type="text" formControlName="card_number" mask="0*"
                             name="card_number" placeholder="Debit or Credit Card Number" id="card_number"
                             class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 relative"
                             [ngClass]="getForm.card_number.errors && getForm.card_number.touched?'form-input-error':'form-input-focus'">
                    </app-shimmer-loading>
                    <p *ngFor="let error of getErrors('card_number')"
                       class="text-regular font-medium text-xs mt-2 error-alert">
                      {{ error }}
                    </p>
                  </div>

                  <div class="col-span-12 lg:col-span-4">
                    <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                      <label for="exp_month" class="text-label-grey text-sm text-regular mb-2">Expiry Month</label>
                      <input type="text" mask="00" maxlength="2" max="12" formControlName="exp_month" name="exp_month"
                             placeholder="MM" id="exp_month" (keyup)="onInputKeyUp($event)"
                             class="h-10 sm:h-12 input py-4 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                             [ngClass]="getForm.exp_month.errors && getForm.exp_month.touched?'form-input-error':'form-input-focus'">
                    </app-shimmer-loading>
                    <p *ngFor="let error of getErrors('exp_month')"
                       class="text-regular font-medium text-xs mt-2 error-alert">
                      {{ error }}
                    </p>
                  </div>
                  <div class="col-span-12 lg:col-span-4">
                    <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                      <label for="exp_year" class="text-label-grey text-sm text-regular mb-2">Expiry Year</label>
                      <input type="text" maxlength="4" [mask]="YEAR_MASK" formControlName="exp_year"
                             (keyup)="onInputKeyUp($event)" name="first_name" placeholder="YYYY" id="exp_year"
                             class="h-10 sm:h-12 input py-4 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                             [ngClass]="getForm.exp_year.errors && getForm.exp_year.touched?'form-input-error':'form-input-focus'">
                    </app-shimmer-loading>
                    <p *ngFor="let error of getErrors('exp_year')"
                       class="text-regular font-medium text-xs mt-2 error-alert">
                      {{ error }}
                    </p>
                  </div>
                  <div class="col-span-12 lg:col-span-4">
                    <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                      <label for="sec_code" class="text-label-grey text-sm text-regular mb-2">Security Code</label>
                      <input formControlName="cvv" type="text" maxlength="4" name="sec_code" placeholder="Security Code"
                             mask="0*" id="sec_code" (keyup)="onInputKeyUp($event)"
                             class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                             [ngClass]="getForm.cvv.errors && getForm.cvv.touched?'form-input-error':'form-input-focus'">
                    </app-shimmer-loading>
                    <p *ngFor="let error of getErrors('cvv')"
                       class="text-regular font-medium text-xs mt-2 error-alert">
                      {{ error }}
                    </p>
                  </div>
                  <div class="col-span-12 md:col-span-8">
                    <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                      <label for="card_name" class="text-label-grey text-sm text-regular mb-2">Card Holder Name</label>
                      <input type="text" name="card_name" id="card_name" placeholder="Card Holder Name"
                             formControlName="card_name"
                             class="h-10 sm:h-12 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700"
                             [ngClass]="getForm.card_name.errors && getForm.card_name.touched?'form-input-error':'form-input-focus'">
                    </app-shimmer-loading>
                    <p *ngFor="let error of getErrors('card_name')"
                       class="text-regular font-medium text-xs mt-2 error-alert">
                      {{ error }}
                    </p>
                  </div>
                  <div class="col-span-12 md:col-span-4">
                    <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12">
                      <label for="zipcode" class=" text-label-grey text-sm text-regular">Zip/Postal Code</label>
                      <input type="text"
                             class=" h-10 sm:h-12 input py-3 px-4 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block placeholder-grey focus:outline-none focus-within:text-gray-700"
                             placeholder="Zip/Postal Code" name="zipcode" id="zipcode" formControlName="zipcode"
                             oninput="value = value.toUpperCase()"
                             [ngClass]="getForm.zipcode.errors && getForm.zipcode.touched?'form-input-error':'form-input-focus'">
                    </app-shimmer-loading>
                    <p *ngFor="let error of getErrors('zipcode')"
                       class="text-regular font-medium text-xs mt-2 error-alert">
                      {{ error }}
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- END: Leftside Content -->

    <!-- BEGIN: Rightside Content -->
    <div class="col-span-12 lg:col-span-5 space-y-8">
      <div class="w-full">
        <div class="bg-white rounded-md border-2 border-grey-600 p-6">
          <h3 class="text-darkblue xl:text-xl lg:text-xl md:text-xl text-semibold border-b pb-3 w-full border-grey-500">
            Payment Summary
          </h3>

          <ng-container *ngFor="let option of paymentOptions">
            <ng-container *ngIf="option.selected">
              <div class="flex flex-col my-4">
                <div class="flex flex-row item-center justify-between">
                  <h3 class="text-base text-grey  tracking-wide text-regular">{{ option.header }}</h3>
                  <p class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular">
                    {{ option.fee | currency }}
                  </p>
                </div>
                <div class="flex flex-row item-center justify-between">
                  <p *ngIf="option.Recurring" class="text-sm text-gray-600">
                    *recurring
                  </p>
                  <p *ngIf="option.removeOption" class="text-sm text-gray-600 hover:underline cursor-pointer"
                     (click)="removeOption(option)">
                    remove
                  </p>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <!-- Discount Amount -->
          <div class="flex flex-col my-4" *ngIf="getDiscountAmount > 0">
            <div class="flex flex-row item-center justify-between">
              <h3 class="text-base text-grey  tracking-wide text-regular">Discount Amount</h3>
              <p class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular">
                - {{ getDiscountAmount | currency }}
              </p>
            </div>
          </div>

          <div class="border-b w-full border-grey-500"></div>

          <div class="flex item-center justify-between my-4">
            <h3 class="text-base text-grey  tracking-wide text-regular">Total</h3>
            <p class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular">
              {{ getTotal | currency }}
            </p>
          </div>
          <div class="mt-auto">
            <div class="flex flex-col md:flex-row items-center gap-4 my-2" *ngIf="showPromoCode">
              <div class="w-full">
                <input type="text" id="promo_code" [(ngModel)]="promoCode"
                       (keyup.enter)="checkPromoCode()"
                       placeholder="Discount or Promotional Code"
                       class="h-10 input py-3 px-4 w-full placeholder-grey text-regular text-sm rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700 active:text-gray-700 relative">
              </div>
              <button (click)="checkPromoCode()"
                      [ngClass]="{'cursor-not-allowed opacity-50': !promoCode, 'cursor-pointer': promoCode}"
                      class="flex justify-center cursor-pointer mt-auto mx-auto w-full md:w-auto flex px-5 py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn">
                Apply
              </button>
            </div>

            <ng-container *ngIf="promoCodeApplied">
              <div  *ngIf="promoCodeValidity" class="flex items-end">
                <p class="text-regular font-medium text-sm mt-2 ml-auto ">
                   Promocode {{appliedPromoCode}} will be valid till {{promoCodeValidity}}
                </p>
              </div>
              <div  class="flex items-end">
                <p class="cursor-pointer text-regular font-medium text-xs mt-2 ml-auto error-alert"
                   (click)="removePromoCode()">
                  Remove Promo Code
                </p>
              </div>
            </ng-container>

            <div class="flex items-center my-2 gap-4"
                 [ngClass]="{'border-2 rounded border-red-400': isPaymentClicked && !isDVAgreementChecked}">
              <input
                id="dv-agreement"
                type="checkbox"
                class="w-5 h-5 text-primary border border-gray-300 rounded"
                [(ngModel)]="isDVAgreementChecked">
              <label for="dv-agreement" class="inline text-base md:text-lg font-ave-semibold text-black">
                I have read and understand the
                <a class="text-pale-blue font-ave-semibold underline cursor-pointer"
                   (click)="showDVAgreement()">
                  notice</a> and agree.
              </label>
            </div>
            <div class="w-full mt-2">
              <div class="flex flex-col sm:flex-row items-center justify-between gap-4">
                <a (click)="goBack()"
                   class="cursor-pointer flex items-center justify-center gap-4 w-full sm:w-auto whitespace-no-wrap py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn-one text-center cursor-pointer">
                  <img src="assets/images/payment/barrow-red.svg" class="w-3 h-3 sm:w-4 sm:h-4" alt="digital vault">
                  Back
                </a>
                <button type="submit" (click)="initiatePayment()"
                        [ngClass]="{
                        'cursor-not-allowed opacity-50': isPaymentButtonDisabled,
                        'cursor-pointer':!isPaymentButtonDisabled}"
                        class="flex items-center justify-center w-full sm:w-auto whitespace-no-wrap px-8 py-2 border border-transparent rounded text-sm tracking-wide text-regular font-semibold bg-nav-btn text-center">
                  <span *ngIf="!isLoading">Pay {{ total | currency }} </span>
                  <span *ngIf="isLoading">Loading...</span>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div *ngIf="false" class="w-full">
        <div class="bg-white rounded-md border-2 border-grey-600 p-6">
          <div class="flex items-center justify-start">
            <div>
              <img src="assets/images/payment/dlb.svg" alt="digital vault" class="w-20 h-20">
            </div>
            <div class="pl-4">
              <h3
                class="text-base text-grey  tracking-wide text-regular mb-2">
                {{ userType === 'Consumer' ? 'Your' : 'User\'s' }} LegacyNOW Digital Vault ID
              </h3>
              <p class="text-md lg:text-xl text-darkblue tracking-wide font-semibold text-regular">
                DIGITAL ID
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Rightside Content -->
  </div>
</div>
<app-consumer-landing-footer></app-consumer-landing-footer>
<app-payment-processing></app-payment-processing>
<app-payment-failure></app-payment-failure>
<app-digital-vault-users-agreement-modal
  (acceptEmitter)="isDVAgreementChecked = $event ? true : isDVAgreementChecked"
  modalID="agreement-modal">
</app-digital-vault-users-agreement-modal>
<app-message-modal
  id="checkout-message-modal"
  (buttonEmitter)="messageListener($event)"
  [setMessageData]="messageData"
  [setMessageSettings]="{showExit:false}"
>
</app-message-modal>
