import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { ClientsService } from 'src/app/professional-dashboard/services/clients.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { RECORD_STATUS, SELECT_VALUE, SORT_BY_CLIENTS } from 'src/constants/application.const';

enum SortPreference {
  Name = 'desc',
  Advisor = 'advisor',
  OldToNew = 'Old to New',
  NewToOld = 'New to Old',
  Advisor_Permission = 'advisor_permission'
}


interface clientListTableSettingsI {
  sortPreference: SortPreference,
  recordsPerPage: number,
  searchKeyword: string
}

@Component({
  selector: 'app-imported-vault-holders-table',
  templateUrl: './imported-vault-holders-table.component.html',
  styleUrls: ['./imported-vault-holders-table.component.css']
})
export class ImportedVaultHoldersTableComponent implements OnInit {
  public dataLoading: boolean = false;
  public currentPage: number = 1;
  public lastPage: number;
  public recordsPerPage: number = 5;
  public listOrder = 'desc';
  public searchKeyword = '';
  public importedVHList: Array<Object>;
  public paginationLinks: Array<any>;
  public dateFormat: string;

  public clientListTableSettings: clientListTableSettingsI;
  public selectOptions: Array<Object>;
  public orderOptions: Array<Object>;
  public recordStatus: {}[];

  constructor(
    private clientService: ClientsService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService) {
    this.dateFormat = this.commonHelper.domainViewTableDateFormat;
    
  }

  ngOnInit(): void {
    this.clientListTableSettings = this.localStorageService.getDataByKey('clientListTableSettings') || {
      sortPreference: SortPreference.Name,
      recordsPerPage: 5,
      searchKeyword: '',
    };
    this.recordStatus = RECORD_STATUS;
    this.selectOptions = SELECT_VALUE;
    this.orderOptions = SORT_BY_CLIENTS;
    this.getImportedVaultHoldersList();
  }

  /**
   * Retrieve Invoice List
   */
  public getImportedVaultHoldersList(pageNo?: number) {
    if (pageNo === this.currentPage) return;
    if (pageNo) {
      this.currentPage = pageNo;
    }
    this.dataLoading = true;
    this.localStorageService.storeData('clientListTableSettings', this.clientListTableSettings);
    this.clientService.getRecentlyImportedClients(this.currentPage, this.clientListTableSettings.recordsPerPage, this.proSortPreference, this.clientListTableSettings.searchKeyword).subscribe({
      next: (response: APIResponseModel) => {
        this.importedVHList = response?.data?.data;
        console.log(this.importedVHList);
        this.paginationLinks = response?.data?.links;
        this.lastPage = response?.data?.last_page;
        this.sortClientList();
      },
      error: () => {
      },
      complete: () => {
        this.dataLoading = false;
      }
    })
  }

  public sortClientList() {
    switch (this.clientListTableSettings.sortPreference) {
      case SortPreference.OldToNew:
        // Sort by Old to New
        this.importedVHList.sort((a, b) => {
          console.log(a);
          const dateA = new Date(a['created_at']);
          const dateB = new Date(b['created_at']);

          if (dateA < dateB) {
            return -1;
          }

          if (dateA > dateB) {
            return 1;
          }

          return 0;
        });
        break;

      case SortPreference.NewToOld:
        // Sort by New to Old
        this.importedVHList.sort((a, b) => {
          const dateA = new Date(a['created_at']);
          const dateB = new Date(b['created_at']);

          if (dateA > dateB) {
            return -1;
          }

          if (dateA < dateB) {
            return 1;
          }

          return 0;
        });
        break;

      default:
        // Handle the default case (no sorting)
        break;
    }
  }

  get proSortPreference(): SortPreference {
    return this.clientListTableSettings.sortPreference === SortPreference.Advisor || SortPreference.Advisor_Permission ? this.clientListTableSettings.sortPreference : SortPreference.Name;
  }

  /**
   * search imported vault holder based on keyword
   * @param{string}keyword
   */
  public onSearchVaultHolder(keyword: string) {
    this.searchKeyword = keyword;
    if (keyword === '') {
      this.getImportedVaultHoldersList();
    } else {
      this.dataLoading = true;
      this.clientService.getRecentlyImportedClients(this.currentPage, this.recordsPerPage, this.listOrder, this.searchKeyword).subscribe({
        next: (response) => {
          this.dataLoading = false;
          this.importedVHList = response?.data?.data;
        }, error: () => this.dataLoading = false

      });
    }
  }

  /**
   * Copies the generated url to the clipboard.
   */
  public async copyToClipboard(url: string) {
    await navigator.clipboard.writeText(url);
    this.toastr.success('Link Successfully Copied!')
  }
}
