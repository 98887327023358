<div class="scroll-hidden box-border body-content" (click)="toggleDropDown(-1)">

  <!--first Section start-->
  <div class="mx-8">
    <app-client-management-cards (buttonGroupEvent)="buttonGroupEventListener($event)"></app-client-management-cards>
    <div class="pt-4 mb-6">
      <div class="border-card bg-white rounded-md p-6 ">
        <!--start second section -->
        <div>
          <h4 class="text-semibold text-grey text-md md:text-lg lg:text-xl xl:text-2xl">
             Clients List
          </h4>
      </div>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-1/2 ">
            <div class="relative">
              <div class="sm:w-full md:w-2/4  relative h-10 text-grey ">
                <div class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none"> <img
                    src=" assets/images/about-yourself/search.svg " class="w-4  h-4" alt="digital vault"> 
                </div>
                <input type="text" class=" input py-3 px-4 mt-1 w-full text-regular text-grey
                          rounded-xs h-10 appearance-none pl-8 border block focus:outline-none "
                  placeholder="Search by Name" (input)="onSearchClient($event.target['value'])">
              </div>
            </div>
          </div>
          <div class="w-full lg:w-1/2">
            <div class="flex flex-wrap item-center justify-between">
              <div>
                <div class=" md:justify-end">
                  <div class="flex justify-center item-center text-grey mt-4">
                    <div
                      class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                      Showing: 
                    </div>
                    <select [(ngModel)]="proClientTableSettings.recordsPerPage" (change)="getClientList()"
                      class="text-center form-select cursor-pointer bg-transparent ml-4 pt-1 mt-0 rounded-xs w-20 h-8 text-regular text-grey p-2 border-none block focus:outline-none insurance_term">
                      <option *ngFor="let option of selectOptions;let i=index" [value]="option['id']">
                        {{option["value"]}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class=" md:justify-end css-shortby">
                <div class="md:justify-end">
                  <div class="flex justify-center item-center h-10 text-grey mt-4">
                    <div
                      class="text-center text-semibold text-grey inset-y-0 left-0 flex items-center pointer-events-none">
                      Sort by: 
                    </div>
                    <select [(ngModel)]="proClientTableSettings.sortPreference" (change)="getClientList()"
                      class="text-center form-select cursor-pointer bg-transparent ml-4 pt-1 mt-0 rounded-xs h-10 text-regular text-grey border-none block focus:outline-none insurance_term ng-pristine ng-valid ng-touched">
                      <option *ngFor="let order of orderOptions;let i=index" [value]="order['id']"
                        [hidden]="order['value']===professionalType">
                        {{order["value"]}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end-->
        <div class="w-full" *ngIf="spinner">
          <div class="mt-3 w-full">
            <app-shimmer-loading [loading]="true" [width]="'100%'" [height]="'3rem'"></app-shimmer-loading>
          </div>
          <div class="flex flex-row mt-3 mb-6 items-center">
            <div>
              <app-shimmer-loading [loading]="true" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
            </div>
            <div class="ml-4 mt-0 w-full">
              <ng-container *ngFor="let _ of [].constructor(3)">
                <app-shimmer-loading [loading]="true" [width]="'100%'"></app-shimmer-loading>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="mt-8" *ngIf="!spinner">
          <div class="table-responsive" #clientTable>
            <table class="table w-full text-left" aria-describedby="client">
              <thead class="bg-gray-400 text-blue text-md text-regular">
                <tr>
                  <th class="p-4" scope="row">Name</th>
                  <th class="p-4" scope="row">Digital Vault ID</th>
                  <!-- <th *ngIf="professionalType !== 'Advisor'" class="p-4" scope="row">{{agentTerm}}</th> -->
                  <th class="p-4" scope="row">Created On</th>
                  <th class="p-4" scope="row">Billing Type</th>
                  <th class="p-4" scope="row">Beneficiaries</th>
                  <th class="p-4" scope="row">Executors</th>
                  <th class="p-4" scope="row">Action</th>
                </tr>
              </thead>
              <div class="loader" *ngIf="spinner">
                <div class="center-loader w-12 h-12 border-b-2 border-red rounded-full animate-spin mt-3 "></div>
              </div>
              <ng-container *ngIf="!spinner">
                <tbody *ngFor="let client of clientList;let i = index">
                  <tr class="border-b-2 border-fuchsia-600 p-4 relative" *ngIf="clientList.length > 0">
                    <td class="p-2 lg:p-4">
                      <div class="flex items-center no-wrap">
                        <app-image-component class="w-14 h-14 lg:w-20 lg:h-20"
                          [image]="clientImageSrc+client['profile_photo']"
                          [class]="'rounded-full w-14 h-14 lg:w-16 lg:h-16 object-cover '"
                          [defaultImage]="'assets/icons/user-bg.svg'">
                        </app-image-component>
                        <div class="pl-2 pr-4 lg:p-5 "> <a
                            class="text-blue-600 text-semibold underline cursor-pointer text-md lg:text-lg text-regular whitespace-normal">{{client['first_name']}} {{client["middle_name"]}}
                            {{client["last_name"]}} </a>
                          <p class="text-grey text-regular">{{client["email"]}}</p>
                        </div>
                      </div>
                    </td>
                    <td class="p-4">
                      <p class="text-grey-500 text-sm lg:text-lg text-regular">
                        {{client?.['user_request']?.["request_unique_id"] }}</p>
                    </td>
                    <!-- <td class="p-4 whitespace-normal" *ngIf="professionalType !== 'Advisor'">
                      <a
                        class="text-blue-900 text-semibold text-sm text-xs md:text-sm lg:text-xl underline cursor-pointer ">
                        {{client?.["user_request"]?.["agent_name"]}} </a>
                    </td> -->
                    <td class="p-4">
                      <p class="text-grey-500 text-sm lg:text-lg text-regular">
                        {{client?.["created_at"] | date: dateFormat}}</p>
                    </td>
                    <td class="p-4">
                      <p class="text-green-500 text-sm lg:text-lg text-regular"
                        [ngClass]="{'danger': client?.['user_request']?.['advisor_permission'] === 0}">
                        {{client?.["payment_type"] === 2 ? 'Consumer' :'Company' }}</p>
                    </td>
                    <td class="p-4">
                      <div class="flex items-center ">
                        <img src="assets/images/dashboard/table-user.svg" alt="digital vault">
                        <div class="pl-3">
                          <p class="text-grey text-semibold tracking-wide">
                            {{client?.["user_request"]?.["total_beneficiary"]}}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="p-4">
                      <div class="flex items-center">
                        <img src="assets/images/dashboard/table-user.svg" alt="digital vault">
                        <div class="pl-3">
                          <p class="text-grey text-semibold tracking-wide">
                            {{client?.["user_request"]?.["total_executor"]}} </p>
                        </div>
                      </div>
                    </td>
                    <td class="p-4 cursor-pointer m-auto" (click)="toggleDropDown(i)">
                      <img src="assets/images/dashboard/icon-more.png" alt="db">
                    </td>
                    <div
                      class="mt-2 pt-1 absolute right-0 top-16 w-60 rounded-md shadow-lg bg-white focus:outline-none z-20"
                      role="menu" aria-orientation="vertical" aria-labelledby="user-menu"
                      *ngIf="profileMenuToggle && (this.index === i )">
                      <a class="block px-3 py-2 text-sm text-grey text-regular cursor-pointer hover:bg-gray-100"
                        role="menuitem" (click)="onSelectButton(client, ROUTE.OVERVIEW)">Client Overview</a>
                      <a (click)="openConfirmationModal(client)" *ngIf="canRemoveClient(client?.id)"
                        class="block px-3 py-2 text-sm text-grey text-regular cursor-pointer hover:bg-gray-100"
                        role="menuitem">
                        Remove Client</a>
                    </div>
                  </tr>
                </tbody>
              </ng-container>
            </table>
            <div class=" flex justify-center mt-5 " *ngIf="clientList?.length === 0">
              <h4 class="text-grey text-base lg:text-md text-regular">
                No data found
              </h4>
            </div>
          </div>
        </div>
        <div class="pt-8">
          <nav class="flex flex-row flex-nowrap justify-between md:justify-center items-center" aria-label="Pagination">
            <a *ngIf="currentPage > 1" (click)="getClientList(currentPage > 1 ? currentPage - 1 : 0)"
              class="flex w-7 h-7 mr-1 justify-center items-center text-black cursor-pointer " title="Previous Page">
              <span class="sr-only">Previous Page</span>
              <img src="assets/images/dashboard/left-arrow-enable.png" alt="digital vault" />
            </a>
            <a *ngIf="currentPage === 1" class="flex w-7 h-7 mr-1 justify-center items-center text-black "
              title="Previous Page">
              <span class="sr-only">Previous Page</span>
              <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
              <img src="assets/images/dashboard/left-arrow.png" alt="digital vault" />
            </a>
            <div class="pagination">
              <a (click)="getClientList(1)"
                [ngClass]="{ 'bg-darkblue' : currentPage === 1, 'text-white' : currentPage === 1 }"
                class="pagination-link" title="Page 1">1</a>
              <span *ngIf="currentPage > 5">...</span>
              <ng-container *ngFor="let i of [-2, -1, 0, 1, 2]">
                <a *ngIf="currentPage + i > 1 && currentPage + i < lastPage" (click)="getClientList(currentPage + i)"
                  [ngClass]="{ 'bg-darkblue' : currentPage === currentPage + i, 'text-white' : currentPage === currentPage + i }"
                  class="pagination-link" title="Page {{currentPage + i}}">
                  {{currentPage + i}}
                </a>
              </ng-container>
              <span *ngIf="currentPage < lastPage - 4">...</span>
              <a *ngIf="lastPage > 1" (click)="getClientList(lastPage)"
                [ngClass]="{ 'bg-darkblue' : currentPage === lastPage, 'text-white' : currentPage === lastPage }"
                class="pagination-link" title="Page {{lastPage}}">
                {{lastPage}}
              </a>
            </div>
            <a (click)="getClientList(currentPage + 1)" *ngIf="(currentPage) < lastPage"
              class="flex w-7 h-7 ml-1 justify-center items-center text-black cursor-pointer " title="Next Page">
              <span class="sr-only">Next Page</span>
              <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
              <img src="assets/images/dashboard/right-arrow.svg" alt="digital vault" />
            </a>
            <a *ngIf="(currentPage) === lastPage" class="flex w-7 h-7 ml-1 justify-center items-center text-black"
              title="Next Page">
              <span class="sr-only">Next Page</span>
              <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
              <img src="assets/images/dashboard/left-arrow.svg" alt="digital vault" />
            </a>
          </nav>
        </div>
        
    </div>
    <app-imported-vault-holders-table></app-imported-vault-holders-table>
  </div>
</div>

</div>
<app-delete-confirmation-modal [submitLoaderDelete]="submitLoaderDelete" (deleteOption)="deleteClient($event)">
</app-delete-confirmation-modal>
<app-document-search-modal></app-document-search-modal>
<app-access-control-defaults-modal [advisorData]="advisorData"></app-access-control-defaults-modal>