import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgPikchatClientModule } from 'ng-pikchat-client';
import { RecaptchaModule } from 'ng-recaptcha';
import { ChartsModule } from 'ng2-charts';
import { TooltipModule } from 'ng2-tooltip-directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPlaidLinkModule } from 'ngx-plaid-link';
import { QuicklinkModule } from 'ngx-quicklink';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { ToastrModule } from 'ngx-toastr';
import { TreeviewModule } from 'ngx-treeview';
import { TOASTR_TIME_OUT } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { DirectivesModule } from '../directives/directives.module';
import { ExeCusNavbarModule } from '../executor-custodian/pages/exe-cus-navbar/exe-cus-navbar.module';
import { MailStatusResponseComponent } from '../executor-custodian/pages/mail-status-response/mail-status-response.component';
import { FileExtensionPipe } from '../pipes/file-extension.pipe';
import { HandleEmptyStringPipe } from '../pipes/handle-empty-string.pipe';
import { ImageLetterRepresentationPipe } from '../pipes/image-letter-representation.pipe';
import { PeopleListFilterV2Pipe } from '../pipes/people-list-filter-v2.pipe';
import { PeopleListFilterPipe } from '../pipes/people-list-filter.pipe';
import { PipeModule } from '../pipes/pipes.module';
import { ProfessionalNavBarModule } from '../professional-dashboard/components/professional/professional-navbar/professional-navbar.module';
import { ProfessionalDashboardModule } from '../professional-dashboard/components/professional/professional.module';
import { SharedModule } from '../sharedComponent/shared.module';
import { AboutYourselfViewComponent } from './about-yourself/about-yourself-view/about-yourself-view.component';
import { AboutYourselfModule } from './about-yourself/about-yourself.module';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { EmailVerificationModalComponent } from './account-settings/email-verification-modal/email-verification-modal.component';
import { AccountListPopupComponent } from './assets/account-list-popup/account-list-popup.component';
import { AccountsCardViewComponent } from './assets/accounts-card-view/accounts-card-view.component';
import { AssetsComponent } from './assets/assets.component';
import { AddFinancialAssetPopupComponent } from './assets/financial-assets/add-financial-asset-popup/add-financial-asset-popup.component';
import { FinanceListComponent } from './assets/financial-assets/finance-list/finance-list.component';
import { FinancialAssetsComponent } from './assets/financial-assets/financial-assets.component';
import { AddRetirementAssetPopupComponent } from './assets/retirement-assets/add-retirement-asset-popup/add-retirement-asset-popup.component';
import { RetirementAssetsComponent } from './assets/retirement-assets/retirement-assets.component';
import { RetirementListComponent } from './assets/retirement-assets/retirement-list/retirement-list.component';
import { BillingHistoryComponent } from './billing-history/billing-history.component';
import { BusinessDetailsComponent } from './business-details/business-details.component';
import { AddBusinessComponent } from './business-details/manage-business-details/add-business-details/add-business-details.component';
import { ManageBusinessDetailsComponent } from './business-details/manage-business-details/manage-business-details.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ComponentsRoutingModule } from './components-routing.module';
import { ConfirmAccountComponent } from './confirm-account/confirm-account.component';
import { ConsumerCompanyComponent } from './consumer-landing-page/consumer-company/consumer-company.component';
import { ConsumerContactUsComponent } from './consumer-landing-page/consumer-contact-us/consumer-contact-us.component';
import { ConsumerFeaturesComponent } from './consumer-landing-page/consumer-features/consumer-features.component';
import { ConsumerLandingPageModule } from './consumer-landing-page/consumer-landing-page.module';
import { ConsumerPricingComponent } from './consumer-landing-page/consumer-pricing/consumer-pricing.component';
import { ConsumerPrivacyPolicyCaliforniaComponent } from './consumer-landing-page/consumer-privacy-policy-california/consumer-privacy-policy-california.component';
import { ConsumerPrivacyPolicyComponent } from './consumer-landing-page/consumer-privacy-policy/consumer-privacy-policy.component';
import { ConsumerUsePolicyComponent } from './consumer-landing-page/consumer-use-policy/consumer-use-policy.component';
import { DigitalVaultAgreementComponent } from './consumer-landing-page/digital-vault-agreement/digital-vault-agreement.component';
import { DigitalVaultLicensingAgreementComponent } from './consumer-landing-page/digital-vault-licensing-agreement/digital-vault-licensing-agreement.component';
import { UserAgreementComponent } from './consumer-landing-page/user-agreement/user-agreement.component';
import { ConsumerOrgMessageComponent } from './consumer-org-message/consumer-org-message.component';
import { AddCardModalComponent } from './consumer-payment-options/card-details/add-card-modal/add-card-modal.component';
import { ConsumerPaymentOptionsComponent } from './consumer-payment-options/consumer-payment-options.component';
import { PaymentDetailsComponent } from './consumer-payment-options/payment-details/payment-details.component';
import { ConsumerPaymentSectionComponent } from './consumer-payment-section/consumer-payment-section.component';
import { CardDetailComponent } from './consumer-update-payment/card-details/card-details.component';
import { ConsumerUpdateCardDetailsComponent } from './consumer-update-payment/consumer-update-card-details/consumer-update-card-details.component';
import { ConsumerUpdatePaymentComponent } from './consumer-update-payment/consumer-update-payment.component';
import { PlanCancelReasonModalComponent } from './consumer-update-payment/plan-cancel-reason-modal/plan-cancel-reason-modal.component';
import { SubscriptionPlanModalComponent } from './consumer-update-payment/subscription-plan-modal/subscription-plan-modal.component';
import { AgentAppointmentPopupComponent } from './dashboard/agent-appointment-popup/agent-appointment-popup.component';
import { ConsumerNotificationsComponent } from './dashboard/consumer-notifications/consumer-notifications.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboradRightContentComponent } from './dashboard/dashborad-right-content/dashborad-right-content.component';
import { NameAddressComponent } from './dashboard/summery-sections/name-address/name-address.component';
import { SummerySectionsComponent } from './dashboard/summery-sections/summery-sections.component';
import { DeactivatedAccountComponent } from './deactivated-account/deactivated-account.component';
import { DigitalFilesComponent } from './digital-files/digital-files.component';
import { DigitalVaultComponent } from './digital-vault/digital-vault.component';
import { DlbDashboardComponent } from './dlb-dashboard/dlb-dashboard.component';
import { AddDonationComponent } from './donations/add-donation/add-donation.component';
import { DonationsComponent } from './donations/donations.component';
import { EmailModalComponent } from './email-not-verified/email-modal/email-modal.component';
import { EmailNotVerifiedComponent } from './email-not-verified/email-not-verified.component';
import { AdvisorInfoComponent } from './executor-beneficiaries/advisor-info/advisor-info.component';
import { BeneficiaryInfoComponent } from './executor-beneficiaries/beneficiary-info/beneficiary-info.component';
import { CustodianInfoComponent } from './executor-beneficiaries/custodian-info/custodian-info.component';
import { ExecutorBeneficiariesInfo2Component } from './executor-beneficiaries/executor-beneficiaries-info2/executor-beneficiaries-info2.component';
import { ExecutorBeneficiariesViewComponent } from './executor-beneficiaries/executor-beneficiaries-view/executor-beneficiaries-view.component';
import { ExecutorBeneficiariesComponent } from './executor-beneficiaries/executor-beneficiaries.component';
import { IntroEBComponent } from './executor-beneficiaries/intro/intro.component';
import { ManageBeneficiariesComponent } from './executor-beneficiaries/manage-beneficiaries/manage-beneficiaries.component';
import { AddCustodianComponent } from './executor-beneficiaries/manage-custodian/add-custodian/add-custodian.component';
import { ManageCustodianComponent } from './executor-beneficiaries/manage-custodian/manage-custodian.component';
import { AddExecutorComponent } from './executor-beneficiaries/manage-executors/add-executor/add-executor.component';
import { ManageExecutorsComponent } from './executor-beneficiaries/manage-executors/manage-executors.component';
import { AccessControlModalComponent } from './executor-beneficiaries/professional-user-type/access-control/access-control-modal/access-control-modal.component';
import { AddAdvisorModalComponent } from './executor-beneficiaries/professional-user-type/add-advisor-modal/add-advisor-modal.component';
import { AdvisorListingComponent } from './executor-beneficiaries/professional-user-type/advisor-listing/advisor-listing.component';
import { AdvisorSettingsModalComponent } from './executor-beneficiaries/professional-user-type/advisor-settings-modal/advisor-settings-modal.component';
import { SchedulerComponent } from './executor-beneficiaries/professional-user-type/advisor-settings-modal/scheduler/scheduler.component';
import { ViewAdvisorInfoComponent } from './executor-beneficiaries/professional-user-type/advisor-settings-modal/view-advisor-info/view-advisor-info.component';
import { DefineAdvisorModalComponent } from './executor-beneficiaries/professional-user-type/get-advisor-modal/define-advisor-modal/define-advisor-modal.component';
import { GetAdvisorModalComponent } from './executor-beneficiaries/professional-user-type/get-advisor-modal/get-advisor-modal.component';
import { ProfessionalUserTypeComponent } from './executor-beneficiaries/professional-user-type/professional-user-type.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ForgotPasswordRequestComponent } from './fotgot-password-request/forgot-password-request.component';
import { AddGovernmentProofComponent } from './government-proofs/add-government-proof/add-government-proof.component';
import { GovernmentProofsComponent } from './government-proofs/government-proofs.component';
import { GracePeriodComponent } from './grace-period/grace-period.component';
import { AddInsuranceComponent } from './insurance/add-insurance/add-insurance.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { InvalidShortLinkComponent } from './invalid-short-link/invalid-short-link.component';
import { AddLiabilitiesPopupComponent } from './liabilities/add-liabilities-popup/add-liabilities-popup.component';
import { LiabilitiesListComponent } from './liabilities/liabilies-list/liabilities-list.component';
import { LiabilitiesComponent } from './liabilities/liabilities.component';
import { ManageCardsModule } from './manage-cards/manage-cards.module';
import { MessageComponent } from './message/message.component';
import { NavBarModule } from './navbar/navbar.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { OauthCallbackComponent } from './oauth-callback/oauth-callback.component';
import { AboutYourselfComponentOverview } from './overview/about-yourself-overview/about-yourself-overview.component';
import { AccountAssetsComponent } from './overview/account-assets/account-assets.component';
import { ExecutorBeneficiariesOverviewComponent } from './overview/executor-beneficiaries-overview/executor-beneficiaries-overview.component';
import { FinanceOverviewComponent } from './overview/finance-overview/finance-overview.component';
import { InsuranceComponentOverview } from './overview/insurance/insurance.component';
import { OverviewDashboardComponent } from './overview/overview-dashboard/overview-dashboard.component';
import { OverviewComponent } from './overview/overview.component';
import { PropertyOverviewComponent } from './overview/property-overview/property-overview.component';
import { VehicleOverviewComponent } from './overview/vehicle-overview/vehicle-overview.component';
import { WillDigitalFilesOverviewComponent } from './overview/will-digital-files-overview/will-digital-files-overview.component';
import { PaymentMessageTextComponent } from './payment-message-text/payment-message-text.component';
import { PeopleComponent } from './people/people.component';
import { AddPrescriptionComponent } from './prescriptions/add-prescription/add-prescription.component';
import { PrescriptionsComponent } from './prescriptions/prescriptions.component';
import { AddPropertyComponent } from './property/manage-properties/add-property/add-property.component';
import { ManagePropertiesComponent } from './property/manage-properties/manage-properties.component';
import { PropertyComponent } from './property/property.component';
import { CheckBasicDetailsComponent } from './received-dlb/check-basic-details/check-basic-details.component';
import { ClaimDlbComponent } from './received-dlb/claim-dlb/claim-dlb.component';
import { PeopleAccountDetailsComponent } from './received-dlb/people-account-details/people-account-details.component';
import { ReceivedDlbComponent } from './received-dlb/received-dlb.component';
import { VerifyDepartedComponent } from './received-dlb/verify-departed/verify-departed.component';
import { ResendEmailComponent } from './resend-email/resend-email.component';
import { ResendVerificationModalComponent } from './resend-verification-modal/resend-verification-modal.component';
import { ScheduleAcceptPageComponent } from './schedule-accept-page/schedule-accept-page.component';
import { ScheduleDeclinePageComponent } from './schedule-decline-page/schedule-decline-page.component';
import { AddSocialMediaComponent } from './social-media/add-social-media/add-social-media.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { AddSubscriptionComponent } from './subscriptions/add-subscription/add-subscription.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SupportComponent } from './support/support.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AddValuableDetailsComponent } from './valuable-details/add-valuable-details/add-valuable-details.component';
import { ValuableDetailsComponent } from './valuable-details/valuable-details.component';
import { VaultServiceOptions } from './vault-service-options/vault-service-options.component';
import { AddVehicleComponent } from './vehicle/manage-vehicle/add-vehicle/add-vehicle.component';
import { ManageVehicleComponent } from './vehicle/manage-vehicle/manage-vehicle.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { VerifyClientImportComponent } from './verify-client-import/verify-client-import.component';
import { WillFilesComponent } from './will-files/will-files.component';


@NgModule({
  declarations: [
    DashboardComponent,
    SummerySectionsComponent,
    ForgetPasswordComponent,
    ResendEmailComponent,
    NameAddressComponent,
    HandleEmptyStringPipe,
    OverviewComponent,
    AboutYourselfComponentOverview,
    AboutYourselfViewComponent,
    ExecutorBeneficiariesComponent,
    AssetsComponent,
    IntroEBComponent,
    ExecutorBeneficiariesInfo2Component,
    ManageExecutorsComponent,
    AddExecutorComponent,
    ReceivedDlbComponent,
    PeopleAccountDetailsComponent,
    ManageBeneficiariesComponent,
    ExecutorBeneficiariesViewComponent,
    PeopleListFilterPipe,
    PropertyComponent,
    ManagePropertiesComponent,
    AddPropertyComponent,
    VehicleComponent,
    ManageVehicleComponent,
    AddVehicleComponent,
    ImageLetterRepresentationPipe,
    DigitalFilesComponent,
    AccountsCardViewComponent,
    ExecutorBeneficiariesOverviewComponent,
    AccountAssetsComponent,
    PropertyOverviewComponent,
    VehicleOverviewComponent,
    WillDigitalFilesOverviewComponent,
    WillFilesComponent,
    NotFoundComponent,
    AccountSettingsComponent,
    SupportComponent,
    ForgotPasswordRequestComponent,
    ChangePasswordComponent,
    ConfirmAccountComponent,
    OverviewDashboardComponent,
    DashboradRightContentComponent,
    VerifyDepartedComponent,
    ClaimDlbComponent,
    AccountListPopupComponent,
    OauthCallbackComponent,
    DlbDashboardComponent,
    FinancialAssetsComponent,
    AddFinancialAssetPopupComponent,
    RetirementAssetsComponent,
    AddRetirementAssetPopupComponent,
    FinanceListComponent,
    RetirementListComponent,
    BusinessDetailsComponent,
    ManageBusinessDetailsComponent,
    AddBusinessComponent,
    ValuableDetailsComponent,
    AddValuableDetailsComponent,
    LiabilitiesComponent,
    AddLiabilitiesPopupComponent,
    LiabilitiesListComponent,
    InsuranceComponent,
    AddInsuranceComponent,
    PeopleComponent,
    PeopleListFilterV2Pipe,
    InsuranceComponentOverview,
    FileExtensionPipe,
    FinanceOverviewComponent,
    VerifyAccountComponent,
    UnauthorizedComponent,
    EmailNotVerifiedComponent,
    EmailModalComponent,
    CheckBasicDetailsComponent,
    InvalidShortLinkComponent,
    MessageComponent,
    SocialMediaComponent,
    SubscriptionsComponent,
    DonationsComponent,
    PrescriptionsComponent,
    GovernmentProofsComponent,
    AddSocialMediaComponent,
    AddSubscriptionComponent,
    AddDonationComponent,
    AddPrescriptionComponent,
    AddGovernmentProofComponent,
    ConsumerPaymentOptionsComponent,
    SubscriptionPlanModalComponent,
    AddCardModalComponent,
    PaymentDetailsComponent,
    ProfessionalUserTypeComponent,
    AddAdvisorModalComponent,
    AdvisorListingComponent,
    ConsumerFeaturesComponent,
    ConsumerContactUsComponent,
    ConsumerPrivacyPolicyComponent,
    ConsumerPrivacyPolicyCaliforniaComponent,
    ConsumerUsePolicyComponent,
    DigitalVaultAgreementComponent,
    DigitalVaultLicensingAgreementComponent,
    UserAgreementComponent,
    ConsumerPricingComponent,
    ConsumerCompanyComponent,
    ConsumerPaymentSectionComponent,
    ConsumerUpdatePaymentComponent,
    ConsumerUpdateCardDetailsComponent,
    BeneficiaryInfoComponent,
    VerifyClientImportComponent,
    GetAdvisorModalComponent,
    AgentAppointmentPopupComponent,
    SchedulerComponent,
    ConsumerNotificationsComponent,
    AdvisorInfoComponent,
    CustodianInfoComponent,
    ManageCustodianComponent,
    ScheduleAcceptPageComponent,
    ScheduleDeclinePageComponent,
    AdvisorSettingsModalComponent,
    AccessControlModalComponent,
    ViewAdvisorInfoComponent,
    ConsumerOrgMessageComponent,
    AddCustodianComponent,
    DefineAdvisorModalComponent,
    MailStatusResponseComponent,
    EmailVerificationModalComponent,
    DigitalVaultComponent,
    GracePeriodComponent,
    PlanCancelReasonModalComponent,
    CardDetailComponent,
    DeactivatedAccountComponent,
    VaultServiceOptions,
    CheckoutComponent,
    BillingHistoryComponent,
    PaymentMessageTextComponent,
    ResendVerificationModalComponent,
  ],
  imports: [
    CommonModule,
    NavBarModule,
    ConsumerLandingPageModule,
    ProfessionalNavBarModule,
    ComponentsRoutingModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    ProfessionalDashboardModule,
    ExeCusNavbarModule,
    AboutYourselfModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: TOASTR_TIME_OUT,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      maxOpened: 5,
    }),
    RecaptchaModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxShimmerLoadingModule,
    NgxPlaidLinkModule,
    NgxFileDropModule,
    ChartsModule,
    NgxMaskModule.forRoot(),
    SharedModule,
    BsDatepickerModule.forRoot(),
    NgPikchatClientModule,
    PipeModule,
    QuicklinkModule,
    NgxPaginationModule,
    ManageCardsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_PLACES_API_KEY,
      libraries: ['places'],
    }),
    TreeviewModule.forRoot(),
    TooltipModule,
  ],
  providers: [],
  exports: [QuicklinkModule, CheckBasicDetailsComponent, PeopleAccountDetailsComponent, ClaimDlbComponent,ResendVerificationModalComponent],
})
/**
 * consumer related components module
 */
export class ComponentsModule {
}
