<app-common-modal id="{{modal_id}}" class="app-common-modal">
  <div class="w-full relative rounded-md">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">
    <div class="mb-4">
      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide w-11/12" [innerHTML]="settings?.text?.header">
      </h4>
      <p class="text-grey text-regular pt-1 text-sm" [innerHTML]="settings?.text?.sub_header">
      </p>
    </div>
  </div>

  <div class="mb-4" *ngIf="userPeopleData?.length > 0">
    <app-list-people [lpSettings]="lpSettings" [userPeopleData]="userPeopleData" (clickEmitter)="clickListener($event)">
    </app-list-people>
  </div>

  <div class="mb-4" *ngIf="userPeopleData?.length <= 0">
    <div class="flex flex-row items-baseline">
      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
        No available people to choose from. Please add a new person.
      </h4>
    </div>
  </div>

  <div class="grid grid-cols-1 sm:float-right">
    <div class="flex flex-col-reverse sm:flex-row gap-4 col-span-12 justify-between items-center pb-2 float-right">
      <button type="button" (click)="clickAddNew()"
        class="flex items-center justify-center my-1 border-button hover:underline-text text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 focus:outline-none text-regular text-white px-6">
        {{settings?.text?.add_new}}
      </button>
      <button (click)="clickChooseAs()" [disabled]="!isValidSet" type="submit"
        [ngClass]="!isValidSet ? 'bg-disable-toggle-switch' : 'bg-darkblue'"
        class="flex items-center justify-center my-1 hover:underline-text text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 focus:outline-none text-regular text-white px-6">
        {{settings?.text?.choose_people}}
      </button>
    </div>
  </div>
</app-common-modal>
