import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';

import { ToastrService } from 'ngx-toastr';
import { ProfessionalDashboardService } from 'src/app/professional-dashboard/services/professional-dashboard.service';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-schedule-client-modal',
  templateUrl: './schedule-client-modal.component.html',
})
/**
 * Add appointment with agent popup component
 */
export class ScheduleClientModalComponent implements OnInit {
  @Output() toggleModalEmitter = new EventEmitter<any>();
  @Input() clientId: any;
  @Input() clientVault: string;
  @Input() clientName: string;
  public appointmentForm: FormGroup;
  public submitLoader: boolean;
  public locale = 'en';
  public minDate: Date;
  public maxDate: Date;

  public userType: string;
  public countryCode: string; //  in future should be embeded with form itself
  public touchedAgent = false;
  public preDefinedLoader: boolean;
  public itemsList: Array<any> = [];

  public minTime = new Date();
  public domainDateFormat: string;


  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private formBuilder: FormBuilder,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private localeService: BsLocaleService,
    private toaster: ToastrService,
    private professionalUserService: ProfessionalUserService,
    private professionDashBoardService: ProfessionalDashboardService,
  ) {
    this.minDate = new Date();

    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.maxDate.setMonth(this.maxDate.getMonth() + 6);
    this.minTime.setMinutes(0);
    this.domainDateFormat = this.commonHelper.domainDateFormat;
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.preDefinedLoader = false;
    this.countryCode = environment.DEFAULT_COUNTRY_DIALCODE;
    this.userType = this.localStorageService.getDataByKey('role');
    this.appointmentForm = this.formBuilder.group({
      schedule_type_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
      appointmentDate: ['', { updateOn: 'blur', validators: [Validators.required] }],
      description: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(500),] }],
    });
    this.getScheduleTypes();
    this.applyLocale();
    this.resetForm();
  }
  /**
   * add agent appointment
   */
  public onSubmit(): void {
    this.submitLoader = true;
    if(!this.scheduleTimeValid()) return;
    if (this.commonHelper.dateformatWithoutTime(this.appointmentForm.value.appointmentDate) == this.commonHelper.dateformatWithoutTime(new Date())) {
      const twoHoursInMillis = 2 * 60 * 60 * 1000; // milliseconds of two hours
      const currentTimeInMillis = new Date().getTime(); // milliseconds of current time
      const totalMillis = twoHoursInMillis + currentTimeInMillis; // add two hours with current time
      if (totalMillis > new Date(this.appointmentForm.value.appointmentDate).getTime()) { // an error will be displayed if the scheduled date is less than two hours from the current time.
        const date = new Date().setMilliseconds(twoHoursInMillis);
        const formatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        const formattedTime = formatter.format(date);
        this.toaster.error(`Schedule time should be greater than ${formattedTime}`);
        this.submitLoader = false;
        return;
      }
    }
    const payload = {
      user_id: this.clientId,
      schedule_type_id: this.appointmentForm.value.schedule_type_id,
      description: this.appointmentForm.value.description,
      status: 0,
      date_time: this.commonHelper.dateformat(this.appointmentForm.value.appointmentDate),
    };

    this.professionalUserService.scheduleClient(payload).subscribe((response: APIResponseModel) => {
      this.submitLoader = false;
      if (response.status) {
        this.commonHelper.toastrScheduleSuccess();
        this.resetForm();
        this.closeModal();
      }
    }, (exception: any) => {
      this.submitLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }
  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }
  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.appointmentForm.controls;
  }

  /**
   * close modal
   */
  public closeModal(): void {
    this.modalService.close('schedule-client-modal');

    this.resetForm();
  }
  public getScheduleTypes(): void {
    this.professionDashBoardService.getScheduleTypes().subscribe((response: APIResponseModel) => {
      if (response.status) {
        this.itemsList = response.data;
        this.appointmentForm.patchValue({ schedule_type_id: this.itemsList[0]?.id });
      }
    });
  }
  /**
   * Reset appointment Form
   */
  public resetForm(): void {
    this.appointmentForm.reset();

    this.getScheduleTypes();
  }
  /**
   * Mark selected from list
   */
  public markAgentType() {
    this.touchedAgent = true;
  }
  /**
   * check schedule time valid
   */
  private scheduleTimeValid(): boolean {
    let retValue = true;
    if (this.commonHelper.dateformatWithoutTime(this.appointmentForm.value.appointmentDate) == this.commonHelper.dateformatWithoutTime(new Date())) {
      const twoHoursInMillis = 2 * 60 * 60 * 1000; // milliseconds of two hours
      const currentTimeInMillis = new Date().getTime(); // milliseconds of current time
      const totalMillis = twoHoursInMillis + currentTimeInMillis; // add two hours with current time
      if (totalMillis > new Date(this.appointmentForm.value.appointmentDate).getTime()) { // an error will be displayed if the scheduled date is less than two hours from the current time.
        const date = new Date().setMilliseconds(twoHoursInMillis);
        const formatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        const formattedTime = formatter.format(date);
        this.toaster.error(`Schedule time should be greater than ${formattedTime}`);
        this.submitLoader = false;
        retValue = false;
      }
    }
    return retValue;
  }
}


