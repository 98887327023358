<main class="relative" *ngIf="!hideBanner">
  <!-- BEGIN: Navbar Wrapper -->
  <app-nav-landing-pro [bannerPage]="true"></app-nav-landing-pro>
  <div class="relative flex items-center justify-center banner-padding-hfull mh-700-height">
    <div class="container mx-auto z-30">
        <div class="flex items-center text-white mt-8 lg:mt-12">
          <div class="grid grid-cols-12 xl:gap-12 p-4 md:p-6 xl:p-8 rounded"
            style="background-color:rgb(0 0 0 / 25%)">
            <div class="col-span-12">
                  <p class="home-banner__content text-base md:text-lg xl:text-3xl mb-4 text-regular">
                    Welcome to LegacyNOW Pro edition. This software is designed to greatly improve your ability to
                    help your clients, members or employees and their families by providing them with our best in
                    class estate data management and resolution tools.
                  </p>
                  <p class="home-banner__content text-base md:text-lg xl:text-3xl mt-4 mb-4 text-regular">In order to provide a
                    more
                    customized experience, please pick the industry that describes you or your organization’s
                    specialized area of expertise.</p>
                  <div *ngIf="bLoadingProTypes" class="mt-4 mb-4 h-7 w-7 border-card-grey rounded-full animate-spin"></div>
                  <div *ngIf="!bLoadingProTypes" class="flex flex-col lg:flex-row lg:flex-wrap xl:flex-nowrap justify-start gap-2 lg:gap-4 my-4 lg:my-8">
                    <div class="flex items-center mr-4" *ngFor="let proType of proTypes">
                      <input id="radio-{{proType?.['displayText']}}" type="radio" [value]="proType?.['displayText']"
                        name="inline-radio-group" [(ngModel)]="selectedType"
                        class="w-4 h-4 xl:w-5 xl:h-5 text-blue-600 bg-gray-100 border-gray-300 input-accent">
                      <label for="radio-{{proType?.['displayText']}}"
                        class="ml-2 home-banner__content text-base xl:text-xl text-white">{{proType?.['displayText']}}</label>
                    </div>
                  </div>
                  <div class="btn-wrap">
                    <button (click)="onNavigation()" [disabled]="bLoadingProTypes || !selectedType"
                      class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">
                      Next
                    </button>
                  </div>
            </div>
          </div>
        </div>
    </div>
    <video playsinline autoplay loop [muted]="true"
      class="absolute banner__bg-video z-10 w-full h-full object-cover">
      <source src="assets/images/professional/banner-clip.mp4" type="video/mp4" />
      <source src="assets/images/professional/banner-clip.ogg" type="video/ogg" />
    </video>
  </div>
</main>
<main class="relative" *ngIf="hideBanner">
  <!-- BEGIN: Navbar Wrapper -->
  <app-nav-landing-pro></app-nav-landing-pro>
  <!-- END: Navbar Wrapper -->

  <div class="relative flex items-center justify-center banner-padding-hfull mh-700-height">
    <div class="container mx-auto z-30">
      <div class="flex items-center text-white">
        <div class="grid grid-cols-12 gap-4 xl:gap-12">
          <div class="col-span-12">
            <div class="mt-8 lg:mt-12">
            <h1 *ngIf="professionalType==='Wealth Management'"
              class="home-banner__head text-semibold fs-bhead text-regular">
              Now advisors can easily and effectively help clients and their family members organize estate data and
              execute a legacy plan
            </h1>
            <h1 *ngIf="professionalType!=='Wealth Management'"
              class="home-banner__head text-semibold fs-bhead text-regular">
              Now Advisors can Easily & Effectively Help Clients and their Family Members Organize their Estate and
              Execute their Legacy Plan
            </h1>
            <p *ngIf="professionalType==='Wealth Management'"
              class="home-banner__content fs-content mt-4 mb-4 lg:mb-12 text-regular">
              Set yourself apart from other Advisors by leveraging LegacyNOW, the best-in-class estate and legacy data
              management platform. Leveraging LegacyNOW your clients’ can easily create a digital vault that securely
              stores and organizes their assets, adapts, and remains current as they navigate their legacy planning
              through life, and streamlines the estate resolution process.
            </p>
            <p *ngIf="professionalType!=='Wealth Management'"
              class="home-banner__content fs-content mt-4 mb-4 lg:mb-12 text-regular">
              LegacyNOW is a next generation estate and legacy data management platform for the purpose of creating your
              clients' digital vault to aggregate, centralize, store, and update all life planning data points over the
              lifecycle of the estate.
            </p>
            <div class="btn-wrap">
              <button (click)="menuChange('features')"
                class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary">
                Why LegacyNOW
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>

    </div>
    <video autoplay loop [muted]="true"
      class="absolute banner__bg-video z-10 w-full h-full object-cover">
      <source src="assets/images/professional/banner-clip.mp4" type="video/mp4" />
      <source src="assets/images/professional/banner-clip.ogg" type="video/ogg" />
    </video>
  </div>


  <!-- END: Banner Wrapper -->

  <!-- BEGIN: Why Legacy Now Wrapper -->
  <div class="section why-legacy-now__wrap">
    <div class="container mx-auto">
    <div class="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8">
      <div class="title__wrap col-span-12 lg:col-start-3 lg:col-span-8 pb-4 xl:pb-8"
        *ngIf="professionalType==='Wealth Management'">

        <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-center">"LegacyNOW... Peace of Mind Later"
        </h1>
        <p class="fs-content text-regular text-content text-center">Provide a tool to your clients that they find as
          useful as you! LegacyNOW bridges the communication gaps experienced between clients and their estate
          professional service providers (wealth advisors, estate attorneys, CPAs, etc.) and provides a comprehensive
          overview for advisors that helps execute and improve their daily performance. LegacyNOW digital vault owners
          find solace knowing their affairs are in order during their lifetime and for their heirs when they pass.</p>
      </div>
      <div class="title__wrap col-span-12 lg:col-start-3 lg:col-span-8 pb-4 xl:pb-8"
        *ngIf="professionalType!=='Wealth Management'">

        <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-center">"LegacyNOW, Peace of Mind Later"
        </h1>
        <p class="fs-content text-regular text-content text-center">LegacyNOW is an advanced estate & legacy data
          management platform designed speciﬁcally to ﬁll digital asset aggregation and communication gaps experienced
          by advisors during the estate planning and resolution process, while enabling a 360-degree view for advisors
          to help execute
          & improve their daily performance.</p>
      </div>
      <ng-container *ngIf="professionalType==='Wealth Management'">
        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-box rounded-lg xl:p-10 p-6">
          <img src="assets/images/why-legacynow/client-relationships.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Build Multigenerational Legacy Planning
            Communication</h4>
          <p class="text-regular text-subcontent fs-subcontent">The set up and educational process within the LegacyNOW
            digital vault encourages advisors to interact and build long-term relationships and trust with all
            constituents who participate in the estate and their legacy (executors, beneﬁciaries, trustees, guardians,
            etc.). </p>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-box rounded-lg xl:p-10 p-6">
          <img src="assets/images/why-legacynow/communication.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Anytime, Anywhere Communication</h4>
          <p class="text-regular text-subcontent fs-subcontent">Mobile accessible dashboards support easy management,
            tracking and communications regarding their estate, between your organization, the LegacyNOW digital vault
            holder and their professional service providers, executors, and heirs while they are on the move.</p>

        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-box rounded-lg xl:p-10 p-6">
          <img src="assets/images/why-legacynow/enterprise.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Comprehensive Solution with Enterprise-Grade
            Capabilities</h4>
          <p class="text-regular text-subcontent fs-subcontent">Our enterprise portal was designed based on input from
            Wealth Advisors and other professional service providers. The robust system is an interactive estate
            planning tool that delivers a consolidated digital view of the LegacyNOW vault holder’s assets in an easy to
            analyze profile to help the professional services providers manage their portfolio.</p>
        </div>
      </ng-container>
      <ng-container *ngIf="professionalType!=='Wealth Management'">
        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-box rounded-lg xl:p-10 p-6">
          <img src="assets/images/why-legacynow/client-relationships.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Build Multigenerational Client Relationships</h4>
          <p class="text-regular text-subcontent fs-subcontent">The set up & educational process within our digital
            vault
            encourages advisor interactions with executors, beneﬁciaries, trustees, guardians, etc. to build long-term
            relationships and trust with all constituents who participate in the estate.</p>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-box rounded-lg xl:p-10 p-6">
          <img src="assets/images/why-legacynow/communication.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Anytime, Anywhere Communication</h4>
          <p class="text-regular text-subcontent fs-subcontent">Mobile-ready admin dashboards allows you to easily
            manage,
            track & communicate with your clients from wherever you are via a simplified and intuitive UI.</p>

        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-box rounded-lg xl:p-10 p-6">
          <img src="assets/images/why-legacynow/enterprise.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Comprehensive Solution with Enterprise-Grade
            Capabilities</h4>
          <p class="text-regular text-subcontent fs-subcontent">The enterprise portal is built with advisor input for
            advisors. A consolidated view of your clients’ digital assets builds an easy to analyze profile which helps
            you better manage their portfolio.</p>
        </div>
      </ng-container>

      <div class="col-span-12 mt-6 sm:mt-8 text-center">

        <div class="btn-wrap">
          <button (click)="menuChange('contact-us')"
            class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">Learn
            More
          </button>
        </div>
      </div>
    </div>
    </div>
  </div>
  <!-- END: Why Legacy Now Wrapper -->


  <!-- BEGIN: This Software Contains Wrapper -->
  <div class="section section-even software-contains" *ngIf="professionalType==='Wealth Management'">
    <div class="container mx-auto">
      <div class="grid grid-cols-12">
      <div class="title__wrap col-span-12 lg:col-start-3 lg:col-span-8">
        <h1 class="text-primary text-semibold fs-title text-regular xl:pb-12 md:pb-8 pb-4 text-center">Our Solution Provides</h1>
      </div>
      <div class="lg:col-span-10 col-span-12 justify-items-center text-regular text-content text-center">
        <div class="flex items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-dot"
              viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
          </div>
          <div class="pl-3">
            <p class="fs-content text-regular text-black text-left my-2">An ability to easily co-brand the site with
              your wealth management firm name and logo.</p>
          </div>
        </div>
        <div class="flex items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-dot"
              viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
          </div>
          <div class="pl-3">
            <p class="fs-content text-regular text-black text-left my-2">An API plug-in to your existing CRM, making the
              distribution and set-up of digital vaults effortless.</p>
          </div>
        </div>
        <div class="flex items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-dot"
              viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
          </div>
          <div class="pl-3">
            <p class="fs-content text-regular text-black text-left my-2">A customized dashboard, providing transparent
              insight and reporting of key metrics you value.</p>
          </div>
        </div>
        <div class="flex items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-dot"
              viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
          </div>
          <div class="pl-3">
            <p class="fs-content text-regular text-black text-left my-2">Flexibility for clients to privately organize
              and store their estate assets digitally.</p>
          </div>
        </div>
        <div class="flex items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-dot"
              viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
          </div>
          <div class="pl-3">
            <p class="fs-content text-regular text-black text-left my-2">Transparent communication utilities for all
              involved in the estate management and resolution cycle, including Professional Service Providers in
              varying organizations.</p>
          </div>
        </div>
        <div class="flex items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-dot"
              viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
          </div>
          <div class="pl-3">
            <p class="fs-content text-regular text-black text-left my-2">Workflow to ease the burden of estate
              resolution for the executor during the time of bereavement.</p>
          </div>
        </div>
        <div class="flex items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-dot"
              viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
          </div>
          <div class="pl-3">
            <p class="fs-content text-regular text-black text-left my-2">A documented path for retaining cross
              generational client retention with you and your firm.</p>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  <div class="section section-even software-contains" *ngIf="professionalType!=='Wealth Management'">
    <div class="container mx-auto">
      <div class="grid grid-cols-12">
      <div class="title__wrap col-span-12 lg:col-start-3 lg:col-span-8">
        <h1 class="text-primary text-semibold fs-title text-regular xl:pb-12 md:pb-8 pb-4 text-center">This Software Contains</h1>
      </div>
      <div class="lg:col-span-10 col-span-12 justify-items-center text-regular text-content text-center">
        <div class="flex items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-dot"
              viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
          </div>
          <div class="pl-3">
            <p class="fs-content text-regular text-black text-left my-2"> API plug-in to your existing CRM.</p>
          </div>
        </div>
        <div class="flex items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-dot"
              viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
          </div>
          <div class="pl-3">
            <p class="fs-content text-regular text-black text-left my-2">Privately organize and store your client’s
              estate digital assets.</p>
          </div>
        </div>
        <div class="flex items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-dot"
              viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
          </div>
          <div class="pl-3">
            <p class="fs-content text-regular text-black text-left my-2">Optimize the implementation of their legacy
              plan.</p>
          </div>
        </div>
        <div class="flex items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-dot"
              viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
          </div>
          <div class="pl-3">
            <p class="fs-content text-regular text-black text-left my-2">Ease the burden of estate resolution for the
              executor during the time of bereavement.</p>
          </div>
        </div>
        <div class="flex items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-dot"
              viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
          </div>
          <div class="pl-3">
            <p class="fs-content text-regular text-black text-left my-2">Set a path for retaining generational transfer
              of wealth within your firm.</p>
          </div>
        </div>
        <div class="flex items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-dot"
              viewBox="0 0 16 16">
              <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
          </div>
          <div class="pl-3">
            <p class="fs-content text-regular text-black text-left my-2">Opening a good line of communication for all
              involved in cycle estate management and resolution.</p>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  <!-- END: This software contains Wrapper -->

  <!-- BEGIN: Add New Revenue Wrapper -->
  <div class="section add-new-revenue__wrap">
    <div class="container mx-auto">
    <div class="grid grid-cols-12 items-center rounded-xl p-6 sm:p-8 md:p-12 bg-dark-blue gap-4 lg:gap-8">
      <div class="col-span-12 lg:col-span-9">
        <div class="title__wrap">
          <h1 class="text-white text-semibold fs-title pb-3 text-bold text-left">Build New Revenue Streams For Your Firm
          </h1>
          <p class="fs-content text-regular text-white text-left">LegacyNOW’s unique software licensing model promotes a
            no upfront cost revenue sharing opportunity.</p>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-3">
        <div class="btn-wrap flex items-left justify-start lg:items-center lg:justify-center">
          <button (click)="menuChange('pricing')"
            class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">See
            Pricing</button>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!-- END: Add New Revenue Wrapper -->

  <!-- BEGIN: Digital Vault Wrapper -->
  <div class="section section-bottom digital-vault__wrap" *ngIf="professionalType==='Wealth Management'">
    <div class="container mx-auto">
    <div class="flex flex-col-reverse lg:flex-row gap-6">
      <div class="md:basis-6/12 lg:basis-1/4">
        <div class="bg-box rounded-xl p-6 lg:p-8">
          <img src="assets/images/why-legacynow/register.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Register</h4>
          <p class="text-regular text-subcontent fs-subcontent">Create your firm’s account and setup advisors within
            minutes. </p>
        </div>
      </div>

      <div class="lg:basis-3/4 md:ml-8">
        <h2 class="text-bold text-primary fs-title pb-2">Easy to create... Easier to manage <br>
          Help your clients create a personalized digital vault and prepare for their LegacyNOW</h2>
      </div>

    </div>
    <div class="lg:flex lg:flex-row mt-8 gap-6">
      <div class="md:basis-6/12 md:mb-8 lg:mb-0 lg:basis-1/4">
        <div class="bg-box rounded-xl p-6 lg:p-8">
          <img src="assets/images/why-legacynow/request.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Request Access Permissions</h4>
          <p class="text-regular text-subcontent fs-subcontent">Clients are invited via email link to sign up for a
            LegacyNOW digital vault. They grant you, and other professional service providers, executors, or heirs
            access to an organized estate portfolio. This provides the appropriate insight needed by each party in an
            organized estate portfolio that fosters collaboration and communications with all involved in the estate
            management/resolution process. </p>

        </div>
      </div>
      <div class="md:basis-6/12 lg:basis-1/4">
        <div class="p-6 lg:p-8 bg-box rounded-xl mt-8 md:mt-0 lg:-mt-12">
          <img src="assets/images/why-legacynow/provide-peace-of-mind.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Provide Peace of Mind</h4>
          <p class="text-regular text-subcontent fs-subcontent">Assures your clients their estate assets are securely
            organized and centralized in a digital manner and remain up to date. Delivers peace of mind, knowing they
            reduced the burden placed on their executor, and alleviated much of the stress experienced through the gift
            of LegacyNOW.</p>
        </div>
      </div>
      <div class="lg:basis-2/4 lg:pl-8  mt-8 md:mt-0  lg:-mt-12">
        <p class="text-regular text-subcontent fs-content md:mt-8 lg:mt-0 mb-6 sm:mb-8">Get ahead of the competition by oﬀering
          a next-gen legacy planning plugin solution that aligns with the needs of the current and future generations.
          The advanced Estate Data Management toolset securely stores all estate digital assets during the client’s
          lifetime and streamlines the resolution of the estate for the executor and beneﬁciaries when they pass.</p>

          <div class="btn-wrap">
          <button (click)="menuChange('contact-us')"
            class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">Discover
            How</button>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="section section-bottom digital-vault__wrap" *ngIf="professionalType!=='Wealth Management'">
    <div class="container mx-auto">
    <div class="flex flex-col-reverse lg:flex-row gap-6">
      <div class="md:basis-6/12 lg:basis-1/4">
        <div class="bg-box rounded-xl p-6 lg:p-8">
          <img src="assets/images/why-legacynow/register.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Register</h4>
          <p class="text-regular text-subcontent fs-subcontent">Create your account and set up your fellow advisors.</p>
        </div>
      </div>

      <div class="lg:basis-3/4 md:ml-8">
        <h2 class="text-bold text-primary fs-title pb-2">Easy to Setup, Easier to Manage — Help Your Clients set up
          Their Own LegacyNOW Digital vault</h2>
      </div>

    </div>
    <div class="lg:flex lg:flex-row mt-8 gap-6">
      <div class="md:basis-6/12 md:mb-8 lg:mb-0 lg:basis-1/4">
        <div class="bg-box rounded-xl p-6 lg:p-8">
          <img src="assets/images/why-legacynow/request.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Request</h4>
          <p class="text-regular text-subcontent fs-subcontent">Invite clients to sign up for a LegacyNOW digital vault
            using our pre-defined templates. Your clients grant you access to a better organized estate portfolio and
            enables collaboration and communications with the executors and beneﬁciaries.</p>

        </div>
      </div>
      <div class="md:basis-6/12 lg:basis-1/4">
        <div class="p-6 lg:p-8 bg-box rounded-xl mt-8 md:mt-0 lg:-mt-12">
          <img src="assets/images/why-legacynow/provide-peace-of-mind.svg" class="h-14 lg:h-16 xl:h-20 w-14 lg:w-16 xl:w-20" alt="Legacy-now">
          <h4 class="text-semibold text-accent fs-subtitle pt-5 pb-2">Provide Peace of Mind</h4>
          <p class="text-regular text-subcontent fs-subcontent">Assures your client their estate digital assets are
            organized, centralized, securely stored, and up to date. Gives them peace of mind, knowing they gifted their
            executor with a tool which will take away a lot of the stress related to their duties of resolving the
            estate.</p>
        </div>
      </div>
      <div class="lg:basis-2/4 lg:pl-8  mt-8 md:mt-0  lg:-mt-12">
        <p class="text-regular text-subcontent fs-content md:mt-8 lg:mt-0 mb-6 sm:mb-8">Get ahead of the competition by oﬀering
          a next-gen legacy planning plugin solution that aligns with the needs of the current and future generations.
          The advanced Estate Data Management toolset securely stores all estate digital assets during the client’s
          lifetime and streamlines the resolution of the estate for the executor and beneﬁciaries when they pass.</p>

          <div class="btn-wrap">
          <button (click)="menuChange('contact-us')"
            class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">Discover
            How</button>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!-- END: Digital Vault Wrapper -->

  <!-- BEGIN: Security Wrapper -->
  <div class="section security__wrap" *ngIf="professionalType==='Wealth Management'">
    <div class="container mx-auto">
    <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
      <div class="col-span-12 lg:col-span-7">
        <h2 class="fs-title text-primary text-semibold mb-6">
          Security is our top priority with patent-pending state-of-the-art Digital Estate Vault Technology
        </h2>
        <p class="fs-content text-content text-regular">
          LegacyNOW securely stores all data associated with client records in a single database using a unique
          proprietary algorithm that provides additional layers of protection beyond military-grade encryption.
        </p>
        <p class="fs-content py-3 md:py-4 text-content text-regular">Critical information, files, and passwords are fragmented,
          and distributed to multiple storage locations in a process known as "sharding".</p>
        <p class="fs-content text-content text-regular">Blockchain technology ensures the most secure password
          protection.</p>
      </div>
      <div class="col-span-12 lg:col-span-5 pb-28">
        <div class="relative ml-60">
          <picture>
            <source srcset="assets/images/security/security-big.webp" type="image/webp">
            <source srcset="assets/images/security/security-big.png" type="image/png">
            <img class="rounded-md overflow-hidden security-img"
              src="assets/images/security/security-big.png" alt="legacy-now">
          </picture>
          <div class="bg-dark-blue lg:border-r-4 lg:border-t-4 lg:border-white security-box absolute rounded-xl p-6">
            <img src="assets/images/security/secure-vault.svg" width="60" height="60" alt="Legacy-now">
            <h4 class="text-semibold text-white fs-subtitle pt-5 pb-2">Secure File Vault</h4>
            <p class="text-regular text-white fs-subcontent">A comprehensive digital archive with industry-leading
              security technology.</p>
          </div>
        </div>

      </div>
    </div>
  </div>
  </div>
  <div class="section security__wrap" *ngIf="professionalType!=='Wealth Management'">
    <div class="container mx-auto">
    <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
      <div class="col-span-12 lg:col-span-7">
        <h2 class="fs-title text-primary text-semibold mb-6">
          Security is our Top Priority with Patent-Pending State-of-the-Art Digital Estate Vault Technology
        </h2>
        <p class="fs-content text-content text-regular">
          All client records and critical information in one place with unique proprietary algorithm that provides
          additional layers of protection beyond military-grade encryption.
        </p>
        <p class="fs-content py-3 md:py-4 text-content text-regular">Critical information, files, and passwords are split up,
          and the pieces distributed to multiple storage locations.</p>
        <p class="fs-content text-content text-regular">Blockchain technology ensures the most secure password
          protection.
        </p>
      </div>
      <div class="col-span-12 lg:col-span-5 pb-28">
        <div class="relative ml-60">
          <picture>
            <source srcset="assets/images/security/security-big.webp" type="image/webp">
            <source srcset="assets/images/security/security-big.png" type="image/png">
            <img class="rounded-md overflow-hidden security-img"
              src="assets/images/security/security-big.png" alt="legacy-now">
          </picture>
          <div class="bg-dark-blue lg:border-r-4 lg:border-t-4 lg:border-white security-box absolute rounded-xl p-6">
            <img src="assets/images/security/secure-vault.svg" width="60" height="60" alt="Legacy-now">
            <h4 class="text-semibold text-white fs-subtitle pt-5 pb-2">Secure File Vault</h4>
            <p class="text-regular text-white fs-subcontent">A comprehensive digital archive with industry-leading
              security technology.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!-- END: Security Wrapper -->

  <!-- BEGIN: Partnership Wrapper -->
  <div class="section section-even partnership__wrap">
    <div class="container mx-auto">
    <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
      <div class="col-span-12 md:col-span-6 lg:col-span-3">
        <div class="grid grid-rows-3 gap-6 items-center lg:gap-8">
          <div class="bg-box p-6 rounded-xl">
            <picture>
              <source srcset="assets/images/partners/inherit.webp" type="image/webp">
              <source srcset="assets/images/partners/inherit.png" type="image/png">
              <img class="float-right rounded-md overflow-hidden" src="assets/images/partners/inherit.png" width="100%"
                height="100%" alt="legacy-now">
            </picture>
          </div>
          <div class="bg-box p-6 rounded-xl">
            <picture>
              <source srcset="assets/images/partners/estate-serve.webp" type="image/webp">
              <source srcset="assets/images/partners/estate-serve.png" type="image/png">
              <img class="float-right rounded-md overflow-hidden" src="assets/images/partners/estate-serve.png"
                width="100%" height="100%" alt="legacy-now">
            </picture>
          </div>
          <div class="bg-box p-6 rounded-xl">
            <picture>
              <source srcset="assets/images/partners/estate-registry.webp" type="image/webp">
              <source srcset="assets/images/partners/estate-registry.png" type="image/png">
              <img class="float-right rounded-md overflow-hidden" src="assets/images/partners/estate-registry.png"
                width="100%" height="100%" alt="legacy-now">
            </picture>
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6 lg:col-span-3">
        <div class="grid grid-rows-2 gap-6 items-center lg:gap-8">
          <div class="bg-box p-6 rounded-xl">
            <picture>
              <source srcset="assets/images/partners/continued-path.webp" type="image/webp">
              <source srcset="assets/images/partners/continued-path.png" type="image/png">
              <img class="float-right rounded-md overflow-hidden" src="assets/images/partners/continued-path.png"
                width="100%" height="100%" alt="legacy-now">
            </picture>
          </div>
          <div class="bg-box p-6 rounded-xl">
            <picture>
              <source srcset="assets/images/partners/philips.webp" type="image/webp">
              <source srcset="assets/images/partners/philips.png" type="image/png">
              <img class="float-right rounded-md overflow-hidden" src="assets/images/partners/philips.png" width="100%"
                height="100%" alt="legacy-now">
            </picture>
          </div>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-6 order-first lg:order-last">
        <div class="partner__info-wrap">
          <h1 class="text-primary text-semibold fs-title text-regular pb-4">LegacyNOW Welcomes and Encourages Strategic
            Partnerships
          </h1>
          <p class="fs-content text-regular text-content">We have been embraced by the Estate Planner, NAEPC, FPA, ABA,
            ElderLawAnswers and Funeral Home service provider communities and look to continue to form mutually
            beneﬁcial relationships. Our partners are invaluable to the success of LegacyNOW.
          </p>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!-- END: Partnership Wrapper -->

  <!-- BEGIN: Our Partnership Wrapper -->
  <div class="section our-partnership__wrap">
    <div class="container mx-auto">
    <div class="grid grid-cols-12 items-center gap-6 lg:gap-12">
      <div class="col-span-12 lg:col-span-6 pb-4 lg:pb-0">
        <h2 class="fs-title text-primary text-semibold mb-6">
          Our software API integration capabilities support other technology partnerships
        </h2>
        <p class="fs-content text-content text-regular mb-6 sm:mb-8">
          LegacyNOW has a complete library of web services to enable interoperability with other professional services
          providers in the estate planning industry. Our plugin capable software can streamline estate planning
          processes for your clients, their executors, and beneﬁciaries by delivering a private-labelled LegacyNOW life
          planning experience. If you are interested in exploring a strategic relationship please contact us.
        </p>

        <div class="btn-wrap">
          <button (click)="menuChange('contact-us')"
            class="button-primary whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">Contact
            Us</button>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-6">
        <picture>
          <source srcset="assets/images/our-partnership.webp" type="image/webp">
          <source srcset="assets/images/our-partnership.png" type="image/png">
          <img class="float-right rounded-md overflow-hidden" src="assets/images/our-partnership.png" width="100%"
            height="100%" alt="legacy-now">
        </picture>
      </div>
    </div>
  </div>
  </div>
  <!-- END: Security Wrapper -->

  <!-- BEGIN: Partner Organizations Wrapper -->
  <div class="section section-even partner-organization__wrap">
    <div class="container mx-auto">
    <h2 class="fs-title text-center text-primary text-semibold pb-6 sm:pb-8 mb-2 sm:mb-6">
      Partner Organizations
    </h2>
    <div class="grid grid-cols-4 lg:pt-8 gap-6 lg:gap-12">
      <div>
        <picture>
          <source srcset="assets/images/partner-org/oacfp.webp" type="image/webp">
          <source srcset="assets/images/partner-org/oacfp.png" type="image/png">
          <img src="assets/images/partner-org/oacfp.png" width="250" height="250" alt="legacy-now">
        </picture>
      </div>
      <div>
        <picture>
          <source srcset="assets/images/partner-org/aba.webp" type="image/webp">
          <source srcset="assets/images/partner-org/aba.png" type="image/png">
          <img src="assets/images/partner-org/aba.png" width="250" height="250" alt="legacy-now">
        </picture>
      </div>
      <div>
        <picture>
          <source srcset="assets/images/partner-org/naepc.webp" type="image/webp">
          <source srcset="assets/images/partner-org/naepc.png" type="image/png">
          <img src="assets/images/partner-org/naepc.png" width="250" height="250" alt="legacy-now">
        </picture>
      </div>
      <div>
        <picture>
          <source srcset="assets/images/partner-org/napfa.webp" type="image/webp">
          <source srcset="assets/images/partner-org/napfa.png" type="image/png">
          <img src="assets/images/partner-org/napfa.png" width="250" height="250" alt="legacy-now">
        </picture>
      </div>
    </div>
  </div>
  </div>
  <!-- END: Partner Organizations Wrapper -->

  <!-- BEGIN: Advisors Insight -->
  <div class="section bg-dark-blue advisor-insight__wrap">
    <div class="container mx-auto">
    <div class="grid grid-cols-12 items-center gap-4 lg:gap-12">
      <div class="col-span-12 xl:col-span-6">
        <picture>
          <source srcset="assets/images/advisor-insights.webp" type="image/webp">
          <source srcset="assets/images/advisor-insight.png" type="image/png">
          <img class="float-right rounded-md overflow-hidden" src="assets/images/advisor-insight.png" width="100%"
            height="100%" alt="legacy-now">
        </picture>
      </div>
      <div class="col-span-12 xl:col-span-6 lg:pl-8 pb-8 lg:pb-0 order-first lg:order-last">
        <p
          class="fs-content text-blue p-2 sm:px-6 inline-block text-semibold text-regular mb-4 md:mb-8 bg-white rounded-xl">
          LegacyNOW’s “ADVISOR INSIGHTS”</p>
        <h2 class="fs-title text-white text-semibold mb-6">
          Aging Boomers Bring Intergenerational Planning to the Forefront
        </h2>
        <p class="fs-content text-white text-regular mb-6 sm:mb-8">
          More than 70% of heirs are likely to fire or change financial advisors after inheriting their parents’ wealth.
          More than ever, advisors need to establish an intergenerational continuity plan to retain assets. As Baby
          Boomers enter retirement and look
          to structure transfers of accumulated wealth, advisors need to be prepared to ensure the transition is as
          smooth … </p>
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12">

            <div class="btn-wrap flex items-center">
              <button
                class="button-download whitespace-nowrap fs-menu text-regular shadow-sm fs-menu bg-primary text-white">Download
                Full Document</button>
                <img src="assets/images/share.svg" class="ml-4 md:ml-6 h-10 md:h-12 xl:h-16" alt="legacy-now">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!-- END: Advisors Insight -->

  <div class="section section-even">
    <app-testimonials [mobile]="mobile"></app-testimonials>
  </div>

  <!-- BEGIN: Advisor Insight Inbox -->
  <!-- <div class="section insight-inbox__wrap">
    <div class="col-start-2 col-span-4 ...">
      <div class="title__wrap text-center pb-8 mx-12">
        <h1 class="text-primary text-semibold fs-title text-regular pb-4 ">For Advisors Insights sent direct to your
          inbox</h1>
        <p class="fs-content text-regular text-content mx-8">Sign up here and you'll receive news and articles that will
          help better serve your clients and differentiate from your competition.</p>
      </div>
      <div class="col-span-12">
        <div class="bg-transparent border rounded-md mx-auto md:w-5/6 lg:w-3/5">
          <form action="#" method="post"
            class="flex flex-wrap items-center justify-between rounded-md xl:h-16 md:flex-row bg-white">
            <div class="relative w-full">
              <input type="text" placeholder="Enter your Name"
                class="flex-1 h-10 text-content text-sm md:text-lg md:px-4 m-1 text-gray-700 placeholder-gray-400 border-none appearance-none lg:h-12 focus:outline-none ">
              <div class="absolute top-1 right-3 md:right-10">
                <button type="submit"
                  class="flex items-center justify-center text-semibold text-sm text-white w-full sm:mx-2 p-2 m-1 rounded-md md:h-12 md:mx-6 lg:text-xl xl:h-12 sm:text-base md:p-0 bg-primary focus:outline-none ">Let
                  Me Know</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div> -->
  <!-- END: Advisor Insight Inbox -->

  <!-- BEGIN: Footer Wrapper -->
  <app-footer-landing-pro></app-footer-landing-pro>
  <!-- END: Footer Wrapper -->
</main>

<div class="fixed right-6 bottom-6 group z-50">
  <button type="button" aria-expanded="false" (click)="openModal('assistanceHelp')"
    class="flex items-center justify-center text-white bg-blue-700 rounded-full w-16 h-16 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
    <img src="assets/images/intro-video.png" alt="">
    <span class="sr-only">Open actions menu</span>
  </button>
</div>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>
