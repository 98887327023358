import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { USER_TYPES } from '../../constants/application.const';
import { ExecutorCustodianService } from '../executor-custodian/services/executor-custodian.service';
import { APIResponseModel } from '../interface/response.interface';
import { IdleService } from '../services/idle.service';
import { LocalStorageService } from '../services/local-storage.service';
import { SessionStorageService } from '../services/session-storage.service';
import { SlugInterceptorService } from './slug-interceptor.service';

@Injectable({ providedIn: 'root' })
/**
 * @class AuthGuardUser
 * @description for protecting routers when user is not logged in
 */
export class AuthGuardExecutor implements CanActivate {
  /**
   * @constructor
   */
  constructor(
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private slugInterceptorService: SlugInterceptorService,
    private executorCustodianService: ExecutorCustodianService,
    private idleService: IdleService,
  ) {}

  /**
   * @method canActivate
   * @description method to check if router can render or not
   * @return {Boolean}
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this.localStorageService.getUserData(USER_TYPES.exe);
    const currentUserSession = this.sessionStorageService.getDataFromSession(USER_TYPES.exe);
    const currentUserCookie = this.localStorageService.getLoginCookie(USER_TYPES.exe);
    if (currentUser && currentUserCookie && currentUserSession) {
      // securityQuestion check status of completed or redirect to security questions
      this.executorCustodianService.checkSecurityQuestionsStatus().subscribe({
        next: (response: APIResponseModel) => {
          this.localStorageService.storeData('download_digital_vault', response.data['download_digital_vault']);
          this.localStorageService.storeData('notify_companies', response.data['notify_companies']);
          const securityQuestionsStatusResponse = response.data;
          this.executorCustodianService.emitCVStatus(securityQuestionsStatusResponse['access_to_custodian']);
          if (
            securityQuestionsStatusResponse &&
            !securityQuestionsStatusResponse['is_security_questions_set'] &&
            securityQuestionsStatusResponse['access_to_custodian']
          ) {
            void this.slugInterceptorService.navigate(['executor-custodian/security-questions']);
          }
        },
      });
      // logged in so return true
      this.idleService.reset();
      return true;
    } else if (route?.params?.id && route?.url[0].path == 'dlb-dashboard') {
      // to check if executor custodian is logged in or not in exe custodian dashboard received dlb flow
      // const encodedUrl = this.commonHelper.encodeDecodeRequestId(route?.params.id, true);
      this.localStorageService.storeData('redirect-url', route?.params.id);
      void this.slugInterceptorService.navigate(['executor-custodian/login']);
    } else {
      // not logged in so redirect to login page with the return url
      void this.slugInterceptorService.navigate(['/executor-custodian/login'], null, false);
      return false;
    }
  }
}
