import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'slice'
})
export class SlicePipe implements PipeTransform {

  transform(value: any, count: number): any {
    return value.filter((data, index)=>index < count);
  }

}
