<app-common-modal [id]="modal_id" class="app-common-modal">

  <!-- BEGIN: Modal -->
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
         class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
         (click)="closeModal()"/>
  </div>


  <div class="grid grid-cols-12 items-center gap-4 row-gap-4">
    <div class="col-span-12">
      <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
        Define Beneficiaries
      </h4>
      <p class="font-semibold text-xs text-label-grey">You can only add a maximum of 3 beneficiaries</p>
    </div>
  </div>

  <app-list-people
    [lpSettings]="lpSettings"
    [userPeopleData]="settings.user_people"
    (clickEmitter)="listenerListPeople($event)">
  </app-list-people>

  <div class="flex justify-between mt-2 mb-4" [ngClass]="{'error-alert' : isBadAllocation()}">
    <p *ngIf="isBadAllocation()">
      Total allocation should be 100%
    </p>
    <p *ngIf="!isBadAllocation()">
      Remaining to Allocate: {{100 - getAllocated()}}%
    </p>
    <p>
      Allocated: {{getAllocated()}}%
    </p>
  </div>

  <div class="flex flex-col-reverse sm:flex sm:flex-row gap-4 justify-between items-center mt-8">

    <app-button-loader class="w-full sm:w-auto" [buttonText]="'Add New Beneficiary'" [buttonTitle]="'Add New Beneficiary'"
                       [buttonType]="'button'" (click)="addBeneficiary()">
    </app-button-loader>
    <app-button-loader class="w-full sm:w-auto" [buttonText]="'Save and Continue'" [buttonTitle]="'Save and continue'"
                       [valid]="isSubmitValid" [buttonType]="'button'" (click)="submit()">
    </app-button-loader>

  </div>
</app-common-modal>

<app-add-beneficiary (toggleModalEmitter)="refreshListing($event)">
</app-add-beneficiary>
