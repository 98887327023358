<app-consumer-focused-navbar></app-consumer-focused-navbar>
<router-outlet></router-outlet>

<!-- BEGIN: Mobile Legacy Box Wrapper -->
<div class="section legacy-box-mobile" *ngIf="isSmallWindow">
  <div class="max-w-screen mx-auto">
    <div class="hero-box bg-pale-blue">
      <h2 class="font-ave-black text-white text-left">
        Keep your digital legacy securely organized and accessible.
      </h2>
      <button (click)="menuChange('register')" (keydown)="menuChange('register')"
              class="bg-primary font-ave-medium text-white rounded-sm p-3 lg:p-4 text-base lg:text-lg xl:text-xl w-full uppercase my-6 lg:my-8 focus:outline-none start-now">
        Start Now
      </button>
      <p class="font-ave-black text-white text-left">NO OBLIGATION</p>
      <p class="text-white text-left font-ave-semibold">
        Cancel within the next 30 days for a full refund.
      </p>
    </div>
  </div>
</div>
<!-- END: Mobile Legacy Box Wrapper -->

<!-- BEGIN: Digital Legacy Wrapper -->
<div class="section digital-legacy-ss">
  <div class="max-w-screen mx-auto">
    <div>
      <div class="grid grid-cols-12 gap-4 lg:gap-12">
        <div class="col-span-12 lg:col-span-7 md:pr-3 md:pr-0 pt-6 lg:pt-0 order-1 lg:order-0">
          <h2 class="font-ave-black-oblique text-darkblue text-left">
            Easily secure your digital legacy today.
          </h2>
          <p class="font-ave-medium text-black text-left">
            Do you have easy access to all your critical asset and account
            information? Do your loved ones if you suddenly pass? <br/>Now is
            the time to organize your legacy.
          </p>

          <ul class="digital-legacy-lst md:pr-8">
            <li class="open font-ave-medium text-black text-left">
              <b class="font-ave-black">Open a LegacyNOW</b> account in minutes.
            </li>
            <li class="fill font-ave-medium text-black text-left">
              <b class="font-ave-black">Fill LegacyNOW’s digital vault</b> with
              asset info from banks, utilities, social media accounts, and
              whatever you want to store and share.
            </li>
            <li class="encrypted font-ave-medium text-black text-left">
              <b class="font-ave-black">Your info is encrypted</b> so only you
              and those you share it with can see it<b class="font-ave-black">—we can never see your personal data.</b>
            </li>
            <li class="cancel font-ave-medium text-black text-left">
              Cancel within 30 days for a full refund.
              <a class="font-ave-black-oblique"
                 (click)="menuChange('register')">START NOW</a>
            </li>
          </ul>
        </div>

        <div class="col-span-12 md:col-span-12 lg:col-span-5 order-0 lg:order-1 pt-2">
          <img *ngIf="isQuestionsShort"
            class="ml-auto md:mr-auto"
            src="assets/images/MatureCouplePayingOffBills.png"
            alt="40-60 Questions Short"/>

          <img *ngIf="!isQuestionsShort"
            class="ml-auto md:mr-auto"
            src="assets/images/OrangeFilesInCabinetiS.png"
            alt="40-60 Get Organized"/>

        </div>
      </div>

      <div class="grid grid-cols-12 gap-4 lg:gap-12">
        <div class="col-span-12">
          <div class="share-box">
            <h2 class="font-ave-black text-darkblue text-center">
              Do you know someone who could use this service now?<br/><span
              class="font-ave-black-oblique">Please pass it on.</span>
            </h2>
            <button class="share-btn font-ave-medium" (click)="showSocialShareModal()">
              <img src="assets/icons/share.png" alt="share"/>SHARE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Digital Legacy Wrapper -->

<div class="mob-column-reverse">
  <!-- BEGIN: Why Choose Wrapper-->
  <div class="section why-choose-legacy-ss why-choose-legacynow__wrap bg-pale-blue">
    <div class="max-w-screen mx-auto">
      <div class="grid grid-cols-12 gap-4 lg:mb-6">
        <div class="col-span-12">
          <h2 class="text-white font-ave-semibold fs-title pb-6 text-center">
            Why LegacyNOW?
          </h2>
        </div>
      </div>

      <div class="grid grid-cols-12 items-start divide-y-2 lg:divide-y-0 lg:divide-x-2 divide-primary">
        <div class="col-span-12 lg:col-span-4 flex flex-col h-full p-4 xl:py-0 md:p-6 lg:p-8 lg:pl-0">
          <div class="flex gap-4 md:gap-4 items-center">
            <img
              src="assets/images/focused/1.svg"
              class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16"
              alt="Step by Step, Guided Organization"
            />
            <h4 class="font-ave-semibold text-white text-xl xl:text-2xl">
              Step by Step, Guided Organization.
            </h4>
          </div>
          <p class="font-ave-regular text-white text-base xl:text-lg pt-4 md:pt-6">
            The Digital Vault guides you through all possible asset categories so
            you can be sure nothing is missed. Login and update at any time.
          </p>
        </div>
        <div class="col-span-12 lg:col-span-4 flex flex-col h-full p-4 xl:py-0 md:p-6 lg:p-8">
          <div class="flex gap-4 md:gap-4 items-center">
            <img
              src="assets/images/focused/2.svg"
              class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16"
              alt="Impenetrably Secure. Universally Accessible"
            />
            <h4 class="font-ave-semibold text-white text-xl xl:text-2xl">
              Impenetrably Secure. Universally Accessible.
            </h4>
          </div>
          <p class="font-ave-regular text-white text-base xl:text-lg pt-4 md:pt-6">
            Built on the very latest bank-level encryption technology to make sure
            your vital information is accessible to only you and those you
            designate.
          </p>
        </div>
        <div class="col-span-12 lg:col-span-4 flex flex-col h-full p-4 xl:py-0 md:p-6 lg:p-8 lg:pr-0">
          <div class="flex gap-4 md:gap-4 items-center">
            <img
              src="assets/images/focused/3.svg"
              class="w-12 h-12 sm:h-14 sm:w-14 lg:w-16 lg:h-16"
              alt="A Clear Path Forward"
            />
            <h4 class="font-ave-semibold text-white text-xl xl:text-2xl">
              A Clear Path Forward.
            </h4>
          </div>
          <p class="font-ave-regular text-white text-base xl:text-lg pt-4 md:pt-6">
            Designate a Digital Vault Custodian to alert us of your passing so
            that we can immediately transfer your Digital Vault to your chosen
            Executor.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Why Choose Wrapper-->

  <!-- BEGIN: Start Now Wrapper -->
  <div class="section start-now-ss">
    <div class="max-w-screen mx-auto">
      <div>
        <div class="grid grid-cols-12 gap-4 lg:gap-12">
          <div class="col-span-12 lg:col-span-7 ml-auto mr-auto pr-3">
            <img
              class="mr-auto"
              src="assets/images/questions-short-start-now-img.png"
              alt="LegacyNOW"
            />
          </div>

          <div class="col-span-12 md:col-span-12 lg:col-span-5 mt-10 lg:mt-0 flex items-center">
            <div>
              <h2 class="font-ave-bold text-darkblue text-left">
                The right thing to do for your family, today.
              </h2>
              <p class="font-ave-medium text-black text-left">
                The peace of mind you get with
                <b class="font-ave-black">LegacyNOW</b> costs just
                {{ monthlyPricing }}/month, and the process is fast, easy, and
                secure. Feel free to cancel within the first 30 days at no cost.
              </p>
              <button
                (click)="menuChange('register')"
                class="bg-primary font-ave-medium text-white rounded-sm p-3 lg:p-4 text-base lg:text-lg xl:text-xl w-full uppercase my-6 lg:my-8 focus:outline-none start-now">
                Start Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Start Now Wrapper -->
</div>

<app-social-share-popup [id]="socialShareModalID" [class]="'w-full h-full'"></app-social-share-popup>

<app-consumer-focused-footer></app-consumer-focused-footer>
