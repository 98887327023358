import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlaidOnSuccessArgs } from 'ngx-plaid-link';
import { ToastrService } from 'ngx-toastr';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SECTIONS, SectionStatus } from "../../../../constants/application.const";
import { CommonHelper } from '../../../helper/common.helper';
import { APIResponseModel } from '../../../interface/response.interface';
import { AssetsService } from '../../../services/assets.service';
import { CommonService } from '../../../services/common.service';
import { PersonalDetailsService } from "../../../services/personal-details.service";
import { PlaidOptionEmit } from '../../../sharedComponent/import-from-plaid-option/import-from-plaid-option.component';

@Component({
  selector: 'app-retirement-assets',
  templateUrl: './retirement-assets.component.html',

})
/**
 * Retirement assets component
 */
export class RetirementAssetsComponent implements OnInit {
  public sectionSaveExitOptions: any;
  public userType: string;
  public clientRequestId: string;
  public isLoading: boolean = false;
  public isProfessional: boolean;
  public clientHasPaidSubscription: boolean;
  public canAccessForm: boolean;
  public permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  /**
  * True signifies hiding the 'save' buttons and showing the 'progress' buttons
  */
  public buttonProgress: boolean = false;
  midPlaid = 'RA-plaid';
  midRetirementAssets = 'add-retirement-assets-modal';

  /**
   * @constructor
   */
  constructor(
    private modalService: CommonModelService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private assetsService: AssetsService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService,
    private personalDetailsService: PersonalDetailsService,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.clientRequestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.isProfessional = this.commonHelper.isProfessional
    this.sectionSaveExitOptions = {
      section: 3,
      type: 2,
    };
    if (this.clientRequestId) {
      this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.retiremetAssets, SectionStatus.INCOMPLETE).subscribe();
    }

  }

  /**
   * open add Retirement Asset modal
   */
  public openRetirementAssetModal(): void {
    this.modalService.open(this.midPlaid);
  }

  /**
   * Set loading flag
   * @param loading
   */
  public setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  /**
   * Button click handler
   * @param command
   */
  public click(command: string) {
    const isPro = this.userType !== 'Consumer';
    switch (command) {
      case 'Back':
        const url_back = ['assets', 'financial-assets', isPro ? this.clientRequestId : '']
        void this.slugInterceptorService.navigate(url_back);
        break;
      case 'ReturnToDashboard':
        const return_url = isPro ? ['overview', 'finance-overview'] : ['dashboard'];
        void this.slugInterceptorService.navigate(return_url);
        break;
      case 'MarkComplete':
        this.buttonProgress = true;
        this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.retiremetAssets, SectionStatus.COMPLETE).subscribe();
        break;
      case 'SaveForLater':
        this.buttonProgress = true;
        break;
      case 'Next':
        const url_next = ['liabilities', isPro ? this.clientRequestId : '']
        void this.slugInterceptorService.navigate(url_next);
        break;
    }
  }

  public onPlaidSuccess($event: PlaidOnSuccessArgs): void {
    let accountList;
    this.commonService.getRetirementPlaidAccounts($event?.token, this.userType !== 'Custodian' ? this.clientRequestId : undefined)
      .subscribe({
        next: (response: APIResponseModel) => {
          accountList = response.data?.retirement;
          this.modalService.close(this.midPlaid);
          if (!accountList.length) {
            this.toastr.error(`You don't have any Plaid accounts related to Retirement Assets`);
          }
          this.assetsService.accountListTrigger(accountList.length ? accountList : []);
        },
        error: exception => this.commonHelper.httpResponseHandler(exception?.error),
      },
      );
  }

  plaidOptionListener($event: PlaidOptionEmit) {
    if ($event.plaid?.onSuccess) {
      this.onPlaidSuccess($event.plaid.onSuccess);
    }
    if ($event.manual) {
      this.modalService.open(this.midRetirementAssets);
    }
  }

  /**
   * Listens for changes in the paid subscription event.
   * @param event The event containing subscription information.
   */
  public paidStatusEventListener(event) {
    if (Object.keys(event).length > 0) {
      this.clientHasPaidSubscription = event['clientHasPaidSubscription'];
    }
  }

  /**
   * Determines whether the button should be disabled
   * @returns A boolean
   */
  public get disableButton(): boolean {
    return this.isProfessional && !this.clientHasPaidSubscription
  }

  /**
   * Event listener to get permissions and set the canEdit flag.
   * @param {Object} event - The event object containing permissions.
   */
   public getPermissionsEventListener(event) {
    if (Object.keys(event).length > 0) {
      this.permissions = event?.['permissionsList']
    }
  }
}
