import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import _ from 'lodash';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { TitleCaseExceptPipe } from '../title-case-except-pipe.pipe';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.css'],
  host: {
    '(document:click)': 'closeDropDown($event)',
  },
})
/**
 * Select search component ( For country and state )
 */
export class SelectSearchComponentt implements OnInit {
  @Input() disabled: boolean = false;
  @Input() useTitleCaseFormatting: boolean = true;
  @Output() optionChangeEmitter = new EventEmitter<SelectOptionsInterface>();
  public searchKeyword: string;
  public toggleListing: boolean;
  public selectedOption: SelectOptionsInterface;
  public initialPlaceholder: string;
  public placeholder: string;
  public items: Array<SelectOptionsInterface>;
  public enableError: boolean;
  public loader: boolean;
  public showSearchBox: boolean;
  @Input() class: any;
  /**
   * @constructor
   */
  constructor(
    public _eref: ElementRef,
  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.loader = false;
    this.toggleListing = false;
  }

  /**
   * On select change pre-process
   *
   * @param {string} value
   */
  public onSelectPreProcess(value: string): void {
    this.selectedOption = _.find(this.items, { value });
    this.toggleListing = false;
    this.searchKeyword = '';
  }

  /**
   * Triggered when click outside a element
   *
   * @param {Event} event
   */
  public closeDropDown(event?: Event): void {
    if (event && !this._eref.nativeElement.contains(event.target)) {
      this.toggleListing = false;
    }
  }

  /**
   * Clear selected option
   */
  public clearSelectedOption(): void {
    this.selectedOption = { value: '', displayText: '' };
  }

  /**
   * On select change
   *
   * @param {string} value
   */
  public onSelectChange(value: string): void {
  }

  public clickDropdown() {
    this.toggleListing = !this.disabled ? !this.toggleListing : false;
  }


  /**
   * Retrieve the <code>displayText</code> property from an object, if it has one. If the value is nullish and
   * <code>{@link letPlaceholder} == true</code> then {@link initialPlaceholder} will be returned.
   * If <code>{@link useTitleCaseFormatting} == true</code> then the {@link TitleCaseExceptPipe} transform will be applied
   * to the result.
   * @param item
   * @param letPlaceholder
   */
  public getDisplayText(item: Object, letPlaceholder = false): string {
    let displayText = item?.['displayText'];

    // If displayText is nullish && can use placeholder, use placeholder text.
    if (!displayText && letPlaceholder) {
      displayText = this.initialPlaceholder;
    }

    // If useTitleCaseFormatting, apply formatting.
    if (this.useTitleCaseFormatting) {
      displayText = (new TitleCaseExceptPipe).transform(displayText);
    }

    return displayText;
  }
}
