import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.css'],
})
/**
 * Button component with loader
 */
export class ButtonLoaderComponent {
  @Input() buttonText: string;
  @Input() valid: boolean = true;
  @Input() loader: boolean;
  @Input() buttonType: string;
  @Input() outlineOnly: boolean;
  @Input() btnTextSize: string;
  @Input() buttonClass: string;
  @Input() buttonTitle: string = '';
  @Input() buttonTooltip: string;
  @Input() sandClock: boolean;
  @Output() clickButton = new EventEmitter<any>();

  /**
   * handle normal button click
   */
  public handleOnclick(): void {
    this.clickButton.emit();
  }
}
