import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ProfessionalPermissionService } from 'src/app/professional-dashboard/services/professional-permission.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { USER_TYPES } from 'src/constants/application.const';

@Component({
  selector: 'app-user-access-enable-list',
  templateUrl: './user-access-enable-list.component.html',
  styleUrls: ['./user-access-enable-list.component.css'],
})

/**
   *
   */
export class UserAccessEnableListComponent implements OnInit {
  @Input() permissionList: any;
  @Input() loadingClientDetails: boolean;
  @Input() userDetails: any;
  @Input() userType: string;
  @Input() isClientDeathVerified:boolean = false;
  public ROUTE: { [key: string]: string } = {
    OVERVIEW: '/overview',
    EXEC_BENE_VIEW: '/executor-beneficiary/executors-beneficiaries-view',
    ABOUT_YOURSELF: '/about-yourself',
  };

  /**
   * constructor
   */
  constructor(
    private slugInterceptorService: SlugInterceptorService,
    private toastrService: ToastrService,
    private modalService: CommonModelService,
    private commonHelper: CommonHelper,
    private proPermissionService: ProfessionalPermissionService,
    private localStorageService: LocalStorageService
  ) { }

  /**
   * loaded on intialization
   */
  ngOnInit(): void {
  }
  /**
   * Determines round based on the section data provided, then calls clientInfoNavigate()
   * @param{any}sectionData
   */
  public onSelectSection(sectionData: { [key: string]: any }) {
    if(this.isClientDeathVerified){
      return
    }
    this.localStorageService.storeData('sectionData', sectionData);
    this.localStorageService.storeData('overview_user_id', this.userDetails['id'])
    if (!sectionData['view']) {
      return;
    }
    if (this.userDetails.user_request.request_stage < 9) {
      this.toastrService.info('Please complete Personal Details and Executors & Beneficiaries sections before accessing other sections');
    } else {
      // Determine route
      const routes = this.proPermissionService.permissionList;
      const route_found: boolean = sectionData['name'] in routes;
      const route: string[] = route_found ? routes[sectionData['name']]['overview'] : null;

      if (!route_found) {
        this.toastrService.error('Routing Error: Permission not Found');
        return;
      }
      this.modalService.close('view-access-page-modal');
      this.clientInfoNavigate(route.join('/'), route_found);
    }
  }
  /**
   * Handles route navigation to client data, and appends the user_request id to the route.
   * @param {string} route_arg
   * @param {boolean} useID If false, the user_request id is not appended to the route.
   */
  public clientInfoNavigate(route_arg: string, useID: boolean = true) {
    const route: [string] = [route_arg];
    const user_id = this.localStorageService.getUserData(USER_TYPES.pro)?.user?.id;
    const clientPermissions = this.commonHelper.isCustodian ?
    this.userDetails['advisor_permission'].permission : this.advisorPermissionList(user_id);

    if (useID) {
      route.push(this.userDetails['user_request']?.id);
    }
    if (clientPermissions?.length >= 1) { // Overall Advisor Permissions
      if (route[0] === this.ROUTE.OVERVIEW) { // Handle Overview route
        void this.slugInterceptorService.navigate([route.join('/')], null, false);
      } else if (route[0] === this.ROUTE.EXEC_BENE_VIEW) { // Handle Executive/Beneficiaries route
        if (this.userDetails.user_request.request_stage < 8) {
          this.toastrService.info('User has not added Executors yet.');
        } else {
          void this.slugInterceptorService.navigate([route.join('/')], null, false);
        }
      } else {
        void this.slugInterceptorService.navigate([route.join('/')], null, false);
      }
    } else {
      this.toastrService.error('Client Permission Required');
    }
  }
  /**
   * Get advisors permission list
   * @param _id
   */
  private advisorPermissionList(_id: string): any {
    return this.userDetails?.user_request?.user_people?.map((people)=>{
      if(people?.professional_id === _id){
        return people?.['advisor_permission']?.permission
      }
    })
  }
}
