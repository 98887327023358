import {Pipe, PipeTransform} from '@angular/core';
import {getCurrencySymbol} from "@angular/common";
import {environment} from 'src/environments/environment';

@Pipe({
  name: 'getCurrencySymbol'
})
export class GetCurrencySymbolPipe implements PipeTransform {

  transform(currencyCode: string, format: "wide" | "narrow" = "narrow"): string {
    return getCurrencySymbol(currencyCode || (environment.CURRENCY_CODE).toString(), format);
  }

}
