import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'lastSeen',
})
/**
 * Last seen
 */
export class LastSeenPipe implements PipeTransform {
  /**
   * transformation function
   * @param {Date} value
   * @param {string} args
   * @return {string}
   */
  transform(value: Date, ...args: string[]): string {
    const currentDate = new Date().getDate();
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const messagedDate = new Date(value).getDate();
    const messagedMonth = new Date(value).getMonth() + 1;
    const messagedYear = new Date(value).getFullYear();
    const sameYear = (currentYear === messagedYear) ? true : false;
    const sameMonth = (currentMonth === messagedMonth) ? true : false;
    const sameDay = (currentDate === messagedDate) ? true : false;
    const previousDay = (currentDate - messagedDate == 1) ? true : false;
    /**
     * year, date and day checking
     */
    if (value) {
      if (sameYear && sameMonth) {
        if (sameDay) {
          const datePipe = new DatePipe('en-US');
          const dayTransform = datePipe.transform(value, 'h:mm a');
          return dayTransform;
        } else if (previousDay) {
          return 'Yesterday';
        } else {
          const datePipe = new DatePipe('en-US');
          const dayTransform = datePipe.transform(value, 'dd/MM/yyyy');
          return dayTransform;
        }
      } else {
        const datePipe = new DatePipe('en-US');
        const dayTransform = datePipe.transform(value, 'dd/MM/yyyy');
        return dayTransform;
      }
    }
  }
}
