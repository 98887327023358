import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIResponseModel } from '../interface/response.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * Dlb services
 */
export class DlbService {
  private BASE_URL: string;

  // dlb event handlers
  private dlbListing: any;
  public dlbListingObserve: Observable<any>;
  // claim digital vault event handlers
  private claimDigitalVault: any;
  public claimDigitalVaultObserve: Observable<any>;
  private currentDlbUser: any;
  public currentDlbUserObserve: Observable<any>;
  // Id verify status handlers
  private userIdVerified: any;
  public userIdVerfiedObserve: Observable<any>;

  /**
   * @constructor
   */
  constructor(
    private http: HttpClient,
  ) {
    this.BASE_URL = environment.BASE_URL;

    // executor observable and handlers
    this.dlbListing = new BehaviorSubject(false);
    this.dlbListingObserve = this.dlbListing.asObservable();
    this.currentDlbUser = new BehaviorSubject(false);
    this.currentDlbUserObserve = this.currentDlbUser.asObservable();
    this.claimDigitalVault = new BehaviorSubject(false);
    this.claimDigitalVaultObserve = this.claimDigitalVault.asObservable();
    this.userIdVerified = new BehaviorSubject({});
    this.userIdVerfiedObserve = this.userIdVerified.asObservable();
  }

  /**
   * dlb listing update
   *
   * @param {boolean} trigger
   */
  public dlbListingTrigger(trigger: boolean): void {
    this.dlbListing.next(trigger);
  }

  /**
   * set claim digital vault status
   * @param{boolean}isVaultClaimed
   */
  public setClaimVaultStatus(isVaultClaimed: boolean) {
    this.claimDigitalVault.next(isVaultClaimed);
  }
  /**
   * dlb request stage update
   *
   * @param {boolean} trigger
   */
  public setDlbUser(trigger: boolean): void {
    this.currentDlbUser.next(trigger);
  }

  /**
   * Resports death
   * @param {any} requestId
   * @return {Observable<APIResponseModel>}
   */
  public reportDeath(requestId: any): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/report-death/${requestId}`);
  }

  /**
   * resend verify claim digital vault email
     * @param {string} requestId
   * @return {Observable<APIResponseModel>}
   */
  public resendClaimDepartedEmail(requestId: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/resend-claim-departed/${requestId}`);
  }

  /**
   * Verify departed api call
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public verifyDeparted(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/verify-departed`, data);
  }

  /**
   * claim dlb api
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public claimDlb(data: any): Observable<APIResponseModel> {
    // return this.http.post<API_RESPONSE_INTERFACE>(this.BASE_URL + `/claim-departed`, data);
    return this.http.get<APIResponseModel>(this.BASE_URL + `/claim-departed?request_id=${data.request_id}&user_id=${data.user_id}`);
  }
  /**
   * inform creditor api
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public informCreditor(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/import-creditor`, data);
  }
  /**
   * inform creditor status api
   *
   * @param {any} requestId
   * @return {Observable<APIResponseModel>}
   */
  public creditorStatus(requestId: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/request/get-departed-details/${requestId}`);
  }
  /**
  * Gets user id verifying events
  * @param value
  */
  public getUserIdVerifyEvents(value: any) {
    this.userIdVerified.next(value);
  }

  /**
   * Retrive Reasons for IDPal Rejection
   *
   * 
   * @return {Observable<APIResponseModel>}
   */
  public getIdRejectionReasons():Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/get-rejection-reason`);
  }
}
