<div class="testimonial__wrap" *ngIf="mobile">
  <div class="container mx-auto">
    <div class="grid grid-cols-12 gap-4 md:gap-6 xl:gap-12">
      <div class="col-span-12">
        <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-center">Testimonials from our users</h1>
        <app-carousal [landingPage]="true">
          <ng-container *ngTemplateOutlet="templateData"></ng-container>
        </app-carousal>
      </div>
    </div>
  </div>
</div>

<div class="testimonial__wrap" *ngIf="!mobile">
  <div class="container mx-auto">
    <div class="grid grid-cols-12 gap-4 md:gap-6 xl:gap-12">
      <div class="col-span-12">
        <h1 class="text-primary text-semibold fs-title text-regular pb-4 text-center">Testimonials from our users</h1>
        <div *ngIf="!mobile"
          class="testimonial pt-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-6 lg:gap-10">
            <ng-container *ngTemplateOutlet="templateData"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #templateData>
  <ng-container *ngFor="let data of testimonialData">
    <div>
      <div class="bg-box rounded-xl p-5 md:p-6 lg:p-10 flex flex-col h-full">
        <img src="assets/images/quote.svg" class="h-8 w-8 md:w-12 md:h-12 lg:w-16 lg:h-16 mb-2 md:mb-4 lg:mb-6" alt="Legacy-now">
        <p class="fs-content text-regular text-subcontent fs-subcontent line-clamp-4">
          {{data.quote}}
        </p>
        <div class="flex items-center gap-4 pt-6 mt-auto">
          <strong class="text-accent fs-content text-semibold">{{data.name}}</strong>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
