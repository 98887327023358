import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SlugService } from 'src/app/services/slug.service';
import { UserService } from 'src/app/services/user.service';
import { EMAIL_PATTERN, NUMBERS_ONLY_PATTERN, USER_TYPES } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { PersonalDetailsService } from '../../services/personal-details.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
})
/**
 * verify otp
 */
export class VerifyOtpComponent implements OnInit {
  public verifyOtpForm:FormGroup;
  public submitLoader:boolean;
  public resendOtpTimer:number;
  public userEmail:string;
  public demoEmails:string[];
  public isDemoEmail:boolean;
  public isLogin: boolean;
  public orgLogo:string;
  public orgLogoPath:string;

  /**
   * @constructor
   */
  constructor(
    private formBuilder:FormBuilder,
    private userService:UserService,
    private toastService:ToastrService,
    private commonHelper:CommonHelper,
    private localStorageService:LocalStorageService,
    private toastrService: ToastrService,
    private personalDetailsService: PersonalDetailsService,
    private slugInterceptorService: SlugInterceptorService,
    private slugService:SlugService,

  ) { }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      } else {
        this.orgLogoPath = '';
        this.orgLogo = '';
      }
    });
    this.userEmail = this.localStorageService.getUserData()?.user?.email || this.localStorageService.getUserData()?.email;
    this.demoEmails = ['pfiumano@phillips-cohen.com', 'arakovich@estate-registry.ca', 'kesava@mailinator.com', 'kuppu@mailinator.com'];
    this.verifyOtpForm = this.formBuilder.group({
      email: [{ value: this.userEmail, disabled: false }, {updateOn: 'blur',validators:[Validators.required, Validators.pattern(EMAIL_PATTERN)]}],
      otp: ['', [Validators.required, Validators.pattern(NUMBERS_ONLY_PATTERN)]],
    });
    this.userService.otpTypeObserve.subscribe((response:boolean) => {
      if (response) {
        this.isLogin = response;
      }
    });
  }


  /**
   * Reset request onsubmit function
   */
  public verifyOtp(): void {
    this.submitLoader = true;
    let userData = {...this.verifyOtpForm.value, type: this.isLogin ? 0 : 1};
    if (this.isLogin) {
      this.userService.verifyOtpLogin(userData).subscribe((response: APIResponseModel) => {
        if (response.status) {
          this.localStorageService.storeUserData(USER_TYPES.user, response.data);
          this.localStorageService.storeData('role', response.data.user.role_name);
          this.commonHelper.setDomainCountryInLocal(response?.data?.country[0]?.id).subscribe();
          this.localStorageService.clearLocalStorage(USER_TYPES.pro);

          // Check if user needs a password-reset
          if (response.data.user.reset_password == 1) {
            void this.slugInterceptorService.navigate(['/account-settings', 2]);
            return;
          }
          // to check if it is demo account .If yes navigate to about yourself's personal detail page else dashboard page
          this.isDemoEmail = this.demoEmails.includes(this.userEmail);
          this.localStorageService.storeData('is-demo-email', this.isDemoEmail);
          void this.slugInterceptorService.navigate([ this.isDemoEmail ? 'about-yourself' : 'dashboard']);

          this.toastService.success(response?.message);
        }
      }, (exception) => {
        this.commonHelper.httpResponseHandler(exception?.error);
      }, () => {
        this.submitLoader = false;
      });
    } else {
      this.userService.verifyOtpRegister(this.verifyOtpForm.value).subscribe((response: APIResponseModel) => {
        this.submitLoader = false;
        if (response.status) {
          this.localStorageService.storeUserData(USER_TYPES.user, response.data);
          this.localStorageService.storeData('role', response.data.user.role_name);
          // Get Country
          this.personalDetailsService.getPersonalDetails().subscribe(
            (response: APIResponseModel) => this.localStorageService.storeData('country', response?.data?.country?.id));
          this.localStorageService.clearLocalStorage(USER_TYPES.pro);
          void this.slugInterceptorService.navigate(['/dashboard']);
          this.toastService.success(response?.message);
        }
      }, (exception) => {
        this.submitLoader = false;
        this.commonHelper.httpResponseHandler(exception?.error);
      });
    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.verifyOtpForm.controls;
  }


  /**
   * start redsend timer
   */
  public startResendTimer():void {
    // resend otp timer
    this.resendOtpTimer = 30;
    const resendInterval = setInterval(()=>{
      this.resendOtpTimer -= 1;
      if (!this.resendOtpTimer) {
        clearInterval(resendInterval);
      }
    }, 1000);
  }

  /**
   * resend email
   */
  public resendEmail():void {
    this.startResendTimer();
    const email ={
      email: this.verifyOtpForm.controls['email'].value,
    };
    this.userService.resendOtpRegister(email ).subscribe((response:APIResponseModel)=>{
      if (response.status) {
        this.toastrService.success(response.message);
      }
    }, (exception)=>{
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
   * Moves focus of webpage to otp input field.
   */
  public keyEnterEmail():void {
    document.getElementById('otp').focus();
  }


  /**
   * on click logo
   */
  public onClickLogo() {
    void this.slugInterceptorService.navigate(['/']);
  }
}
