
<div class="scroll-hidden">
  <div class="register-page">

    <!-- BEGIN: Header -->
    <div class="col-span-12 flex register-header">
      <div class="container m-auto  flex gap-2 lg:gap-4">
        <!-- legacyNow logo -->
        <div>
        <a (click)="clickOnLogo('')" class="flex items-center justify-center lg:justify-start cursor-pointer">
          <img alt="The-Estate-Registry" width="259" [src]="'assets/images/logo-new.png'" class="logo object-contain">
        </a>
      </div>
        <!-- organization logo -->
        <div class="items-center flex gap-4 lg:gap-6" *ngIf="orgLogo">
          <ng-container>
            <span class="border-l-black h-8 max-h-8"></span>
            <a class="cursor-pointer">
              <img alt="The-Estate-Registry" class="object-contain sponsored-img max-h-12" [src]="orgLogo">
            </a>
          </ng-container>
         </div>
        </div>
    </div>
    <!-- END: Header -->

    <!-- BEGIN: Content -->
    <div class="flex flex-col-reverse lg:flex-row lg:grid grid-cols-12 register-content">
      <!-- BEGIN: Register Form -->
      <div class="col-span-12 md:col-span-6 xl:col-span-5 p-6 flex form-col">
        <div class="flex flex-col w-full">
          <div class="h-full flex items-center justify-center mt-0 sm:mt-4">
            <div class="my-auto mx-auto pt-0 sm:pt-6 sm:pl-10 xl:bg-transparent sm:px-8 w-full">
              <div class="h-full justify-center items-center ">
                <div>
                  <p class="font-ave-regular text-white text-base lg:text-2xl">
                    Create your estate organizer account with
                  </p>
                  <h2 class="text-white text-2xl lg:text-4xl font-ave-black pt-1">
                    LegacyNOW Digital Vault
                  </h2>
                </div>

                <form [formGroup]="registerForm" autocomplete="off" (ngSubmit)="registerUser(captchaRef)">
                  <div class="mt-4 lg:mt-6 space-y-4">
                    <div>
                      <label for="first-name" class="font-ave-medium text-base text-white">First
                        Name*</label>
                      <input type="text" name="first-name" formControlName="first_name" id="first-name"
                            class="input mb-2 w-full rounded-xs h-11 font-ave-medium text-grey appearance-none pl-4 border block focus:outline-none"
                            placeholder="Enter Your First Name"
                            [ngClass]="formGet.first_name.errors && formGet.first_name.touched?'form-input-error':'form-input-focus'">
                      <p *ngFor="let error of getErrors('first_name')"
                        class="font-ave-medium font-medium text-base mt-2 error-alert">
                        {{error}}
                      </p>
                    </div>
                    <div>
                      <label for="middle_name" class="font-ave-medium text-white text-base">Middle Name</label>
                      <input type="text"
                            class="input mb-2 w-full rounded-xs h-11 font-ave-medium text-grey appearance-none pl-4 border block focus:outline-none"
                            id="middle_name" formControlName="middle_name" placeholder="Enter Your Middle Name"
                            [ngClass]="formGet.middle_name.errors && formGet.middle_name.touched?'form-input-error':'form-input-focus'">
                      <p *ngFor="let error of getErrors('middle_name')"
                        class="font-ave-medium font-medium text-base mt-2 error-alert">
                        {{error}}
                      </p>
                    </div>
                    <div>
                      <label for="last-name" class="font-ave-medium text-base mt-2 text-white">Last
                        Name*</label>
                      <input type="text" name="last-name" formControlName="last_name" id="last-name"
                            class="input mb-2 w-full rounded-xs h-11 font-ave-medium text-grey appearance-none pl-4 border block focus:outline-none"
                            placeholder="Enter Your Last Name"
                            [ngClass]="formGet.last_name.errors && formGet.last_name.touched?'form-input-error':'form-input-focus'">
                      <p *ngFor="let error of getErrors('last_name')"
                        class="font-ave-medium font-medium text-base mt-2 error-alert">
                        {{error}}
                      </p>
                    </div>
                    <div>
                      <label for="email" class="font-ave-medium text-base mt-2 text-white">Email
                        Address*</label>
                      <input type="email" name="email" formControlName="email" id="email"
                            class="input mb-2 w-full rounded-xs h-11 font-ave-medium text-grey appearance-none pl-4 border block focus:outline-none"
                            placeholder="Enter Valid Email Address" maxlength="50" [readOnly]="isDisableEmail"
                            [ngClass]="formGet.email.errors && formGet.email.touched?'form-input-error':'form-input-focus'">
                      <p *ngFor="let error of getErrors('email')"
                        class="font-ave-medium font-medium text-base mt-2 error-alert">
                        {{error}}
                      </p>
                    </div>

                    <!-- Phone Number -->
                    <div>
                      <label for="phone-register" class="font-ave-medium text-white text-base mt-2">Phone Number</label>
                      <app-phone-input [placeholder]="'e.g. 8665044754'" class="mb-2" id="phone-register"
                        [phoneNumber]="registerForm.value.phone" [countryCode]="countryCode"
                        (onChange)="detectChangePhonenumber($event)"
                        (focusout)="formGet.phone.markAsTouched()" [uniqueId]="'phone-register'"
                        [errorClassTrigger]="formGet.phone.errors && formGet.phone.touched" [form]="registerForm">
                      </app-phone-input>
                      <p *ngFor="let error of getErrors('phone')"
                        class="font-ave-medium font-medium text-base mt-2 error-alert">
                        {{error}}
                      </p>
                    </div>

                    <div class="relative mt-2 mb-4">
                      <label for="password" class="font-ave-medium text-base mt-4 text-white">Create
                        Password*</label>
                      <div class="relative h-11 text-grey">
                        <a class="absolute inset-y-0 right-0 pr-3 flex items-center"
                          (click)="togglePasswordView = !togglePasswordView">
                          <fa-icon [icon]="faEye" *ngIf="togglePasswordView"></fa-icon>
                          <fa-icon [icon]="faEyeSlash" *ngIf="!togglePasswordView"></fa-icon>
                        </a>
                        <input [type]="togglePasswordView?'text':'password'" name="password" formControlName="password"
                              id="password" autocomplete="new-password"
                              class="input py-3 px-4 mb-2 w-full font-ave-medium text-grey rounded-xs h-11 appearance-none pl-4 border block focus:outline-none"
                              placeholder="Type Your Password"
                              [ngClass]="formGet.password.errors && formGet.password.touched? 'form-input-error':'form-input-focus'">
                        <!-- BEGIN: Error Alert text -->
                        <p *ngFor="let error of getErrors('password')"
                          class="font-ave-medium font-medium text-base mt-2 error-alert">
                          {{error}}
                        </p>
                        <div class="absolute top-10 w-full z-10"
                            *ngIf="formGet.password.errors">
                          <div *ngIf="getErrors('password').length == 0 && formGet.password.dirty"
                              class="relative border mt-4 rounded bg-white p-2 text-red-700 border-3 w-full rounded-xs border block focus:outline-none">

                            <p [ngClass]="!formGet.password.errors.minlength? 'green-error-text':'red-error-text'">
                              <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                                  alt="digital vault" *ngIf="!formGet.password.errors.minlength">
                              <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                                  alt="digital vault" *ngIf="formGet.password.errors.minlength">
                              Minimum at least 8 characters.
                            </p>

                            <p
                              [ngClass]="formGet.password.errors?.passwordStrength?.hasNumeric? 'green-error-text':'red-error-text'">
                              <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                                  alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.hasNumeric">
                              <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                                  alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.hasNumeric">
                              At least one number (0-9).
                            </p>

                            <p
                              [ngClass]="formGet.password.errors?.passwordStrength?.hasLowerCase? 'green-error-text':'red-error-text'">
                              <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                                  alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.hasLowerCase">
                              <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                                  alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.hasLowerCase">
                              At least one lowercase letter (a-z).
                            </p>

                            <p
                              [ngClass]="formGet.password.errors?.passwordStrength?.hasUpperCase? 'green-error-text':'red-error-text'">
                              <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                                  alt="digital vault" *ngIf="formGet.password.errors?.passwordStrength?.hasUpperCase">
                              <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                                  alt="digital vault" *ngIf="!formGet.password.errors?.passwordStrength?.hasUpperCase">
                              At least one uppercase letter (A-Z).
                            </p>

                            <p
                              [ngClass]="formGet.password.errors?.passwordStrength?.hasSpecialCharacters? 'green-error-text':'red-error-text'">
                              <img src="assets/images/password/tickmark.svg" class="float-left green-error-icon"
                                  alt="digital vault"
                                  *ngIf="formGet.password.errors?.passwordStrength?.hasSpecialCharacters">
                              <img src="assets/images/password/closemark.svg" class="float-left red-error-icon"
                                  alt="digital vault"
                                  *ngIf="!formGet.password.errors?.passwordStrength?.hasSpecialCharacters">
                              At least one special character (!@#$%^&*).
                            </p>

                          </div>
                        </div>
                        <!-- END: Error Alert text -->
                      </div>
                    </div>
                    <!-- BEGIN Create space for above component error alert text -->
                    <div *ngFor="let error of getErrors('password')" class="h-1"></div>
                    <!-- END space -->
                    <div class="relative mt-4">
                      <label for="newPasswordConfirm" class="font-ave-medium text-base pb-2 mt-4 text-white">
                        Confirm Password*</label>
                      <div class="relative h-11 text-grey ">
                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                            (click)="toggleNewPasswordViewConfirm = !toggleNewPasswordViewConfirm">
                          <fa-icon [icon]="faEye" *ngIf="toggleNewPasswordViewConfirm"></fa-icon>
                          <fa-icon [icon]="faEyeSlash" *ngIf="!toggleNewPasswordViewConfirm"></fa-icon>
                        </div>
                        <input name="confirm_password" id="newPasswordConfirm"
                              class="input py-3 px-4 mt-1 w-full font-ave-medium rounded-xs h-11 appearance-none pl-4 border block focus:outline-none focus-within:text-grey-800"
                              formControlName="confirm_password" [type]="toggleNewPasswordViewConfirm?'text':'password'"
                              placeholder="Confirm New Password"
                        >
                        <!-- BEGIN: Error Alert text -->
                        <div *ngIf=" formGet?.confirm_password?.touched && !checkPasswordMatch()">
                          <p class="font-ave-medium font-medium text-base my-2 error-alert">
                            Your passwords must match.
                          </p>
                        </div>
                        <!-- END: Error Alert text -->
                      </div>
                    </div>
                    <!-- BEGIN Create space for above component error alert text -->
                    <div class="h-1" *ngIf="formGet?.confirm_password?.touched && !checkPasswordMatch()"></div>
                    <!-- END space -->

                    <div class="mt-2 flex">
                      <input type="checkbox" formControlName="agree" id="agree"
                            class="h-6 mb-auto font-ave-medium appearance text-grey pl-4 border block focus:outline-none"/>
                      <label class="ml-2 text-sm mt-1 font-ave-medium text-white" for="agree">
                        I acknowledge and accept this consent
                        <a class="font-ave-bold text-white underline cursor-pointer"
                          (click)="menuChange('user-agreement', true)">
                          more
                        </a>
                      </label>
                    </div>
                  </div>

                  <div class="mt-8">
                    <div class="recaptcha h-20">
                      <re-captcha #captchaRef="reCaptcha" (resolved)="resolvedCaptcha($event)"
                                  (error)="onErrorCaptcha($event)" errorMode="handled"
                                  [siteKey]="googleCaptchaKey"></re-captcha>
                    </div>
                  </div>

                  <div class="mt-6 text-center">
                    <p class="text-white font-ave-medium text-sm">Already Have an Account?
                      <a (click)="menuChange('login')" class="text-white font-ave-black underline cursor-pointer">Login
                        Here</a>
                    </p>
                    <div class="mt-6">
                      <button type="submit"
                              class="button-primary text-center rounded-xs focus:outline-none font-ave-medium text-white px-8 mx-auto"
                              [disabled]="!registerForm.valid || registerLoader || !checkPasswordMatch()">
                        <div class="loading-red mr-2" *ngIf="registerLoader"></div>
                        <span>REGISTER</span>
                      </button>
                    </div>
                  </div>
                </form>

                <p class="font-ave-medium text-white text-center py-6 text-sm">
                  By creating an account you agree to our
                  <a class="font-ave-black text-white underline cursor-pointer"
                    (click)="menuChange('privacy-policy')"
                    target="_blank">
                    Privacy Policy
                  </a>
                </p>

                <p class="font-ave-medium text-white text-center py-6 text-sm">
                  * Required fields.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- END: Register Form -->

      <!-- BEGIN: Login Info -->
      <div class="md:col-span-6 xl:col-span-7 lg:flex carousel-col lg:min-h-screen justify-center">
        <div class="lg:fixed h-full flex flex-col items-center justify-start px-4">
          <app-register-carousel></app-register-carousel>
        </div>
      </div>
      <!-- END: Login Info -->

    </div>
    <!-- END: Content -->

  </div>
</div>
<app-common-modal id="user-logged-in" class="app-common-modal">
  <h2 class="text-xl lg:text-2xl font-sans font-medium highlighted-text truncate mr-5">
    You are already logged in
  </h2>
  <div class="flex flex-row items-center">
    <p class="font-sans text-lg mt-2">
      Redirecting to Dashboard
    </p>
    <div class="loading-red ml-2 mt-2"></div>
  </div>
</app-common-modal>
