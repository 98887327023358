import { Component, OnInit } from '@angular/core';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-vehicle-overview',
  templateUrl: './vehicle-overview.component.html',
})
/**
 * Vehicle overview component
 */
export class VehicleOverviewComponent implements OnInit {
  public preLoader: boolean = true;
  public vehicleArray: Array<any>;
  public PIC_URL: string;
  public currentUser: any;
  public editData: any;
  public accessVault: boolean;
  public isVaultClaimed: boolean;

  /**
   * @constructor
   */
  constructor(
    private personalDetailsService: PersonalDetailsService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private modalService: CommonModelService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.getPropertyDetails();
    this.currentUser = this.localStorageService.getUserData();
    this.PIC_URL = environment.BASE_URL_RAW + 'uploads/' + this.currentUser.request.id + '/vehicle_image/';
    this.accessVault = this.localStorageService.getDataByKey('accessVault');
    this.isVaultClaimed = this.localStorageService.getDataByKey('isVaultClaimed') == 1;
  }

  /**
   * get property details
   */
  public getPropertyDetails(): void {
    this.preLoader = true;
    this.personalDetailsService.getPersonalDetails().subscribe((response: APIResponseModel) => {
      this.preLoader = false;
      if (response.status) {
        this.vehicleArray = response.data.user_vehicle;
      }
    }, (exception: any) => {
      this.preLoader = false;
      this.commonHelper.httpResponseHandler(exception?.error);
    });
  }

  /**
   * toggle add vehicle modal
   *
   * @param {any} editValue
   */
  public toggleModal(editValue?: any): void {
    this.editData = {};
    if (editValue) {
      this.editData = { ...editValue };
    }
    this.modalService.open('add-vehicle-modal');
  }

  /**
   * Modal event listner
   *
   * @param {any} data
   */
  public modalEventListner(data: any): void {
    this.getPropertyDetails();
  }
}
