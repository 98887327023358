import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { ROUTE_PREFIX } from '../constants/application.const';
import {
  ConsumerLandingContentNewComponent,
} from './components/consumer-landing-page/consumer-landing-content-new/consumer-landing-content-new.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { RegisterComponent } from './components/register/register.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { AuthGuardUser } from './helper/authGuard.helper';
import { RoleGuard } from './helper/roleGuard.helper';
import { routeDataShareInterface } from './interface/common.interface';
const dataShareService: routeDataShareInterface = { socketService: false };
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: ConsumerLandingContentNewComponent, data: dataShareService },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent, canActivate: [RoleGuard] },
  {
    path: 'register/:id',
    component: RegisterComponent,
    canActivate: [RoleGuard],
  },

  {
    path: 'verify-otp',
    component: VerifyOtpComponent,
    canActivate: [RoleGuard],
  },
  {
    path: 'verify-otp/:id',
    component: VerifyOtpComponent,
    canActivate: [RoleGuard],
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardUser],
  },

  // Organization dashboard routes


  { path: ROUTE_PREFIX.ORG, loadChildren: () => import('./organization/organization.module').then((m) => m.OrganizationModule) },
  { path: 'security', loadChildren: () => import('./security/security.module').then((m) => m.SecurityModule) },

  // Professional Dashboard routes


  {
    path: ROUTE_PREFIX.PRO,
    loadChildren: () =>
      import('./professional-dashboard/components/professional/professional.module'
      ).then((module) => module.ProfessionalDashboardModule),
  },

  // executor/custodian routes
  {
    path: ROUTE_PREFIX.EXE,
    loadChildren: () =>
      import('./executor-custodian/executor-custodian.module').then((module) => module.ExecutorCustodianModule,
      ),
  },
  {
    path: ':slug/executor-custodian',
    loadChildren: () =>
      import('./executor-custodian/executor-custodian.module').then((module) => module.ExecutorCustodianModule,
      ),
  },


  { path: ':slug/login', component: LoginComponent },
  { path: ':slug/register', component: RegisterComponent, canActivate: [RoleGuard] },

  {
    path: '',
    loadChildren: () =>
      import('./components/components.module').then((module) => module.ComponentsModule,
      ),
  },


  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [

    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: QuicklinkStrategy,
    }),
  ],
  exports: [RouterModule],
})
/**
 * Routing module
 */
export class AppRoutingModule { }
