<app-common-modal id="claim-dlb-modal" class="app-common-modal">
  <div class="relative">
    <img src="assets/images/about-yourself/close.svg"
      class="absolute right-2 w-8 -mr-6 -mt-4 shadow-md shadow-red-300 rounded-full cursor-pointer" alt=""
      (click)="closeModel()">
    <form [formGroup]="claimDlbForm" autocomplete="off" (ngSubmit)="onSubmit()">

      <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
        <div class="col-span-12">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Enter {{name}}'s Digital Vault Blockchain hash key sent to your mail
          </h4>
        </div>
        <div class="col-span-12">
          <label for="blockchain_key" class="text-label-grey text-sm text-regular">Blockchain hash key</label>
          <input type="text" id="blockchain_key"
            class="h-10 sm:h-12 input py-3 px-4 mt-1 text-sm w-full text-regular rounded-xs appearance-none pl-4 border block focus:outline-none focus-within:text-gray-700"
            [ngClass]="formGet.blockchain_key.errors && formGet.blockchain_key.touched?'form-input-error':'form-input-focus'"
            placeholder="e.g. 1J7mdg5rbQyUHENYdx39WVWK7fsLpEoXZy" formControlName="blockchain_key">
          <div *ngIf="formGet.blockchain_key.errors && formGet.blockchain_key.touched">
            <p class="text-regular font-medium text-xs mt-2 error-alert" *ngIf="formGet.blockchain_key.errors.pattern">
              Please enter valid block chain key( Alpha numeric )
            </p>
          </div>
        </div>
      </div>

      <div class=" flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8 ">
        <button type="button"
          class=" button button--lg opacity-50 mt-4 sm:mt-0 font-semibold tracking-wide w-full sm:w-auto bg-transparent border-red rounded-xs h-10 text-sm focus:outline-none text-regular text-darkblue px-6 "
          (click)="closeModel()">
          Cancel</button>

        <app-button-loader [buttonText]="'Check Digital Vault'" [valid]="claimDlbForm.valid" [loader]="submitLoader"
          [buttonType]="'submit'"></app-button-loader>
      </div>

    </form>
  </div>
</app-common-modal>
