<div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 my-6 items-center" *ngIf="userDetails?.['request_stage'] >= 1">
  <div class="col-span-12">
      <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
      </h4>
    </div>
  <div class="col-span-12 md:col-span-6 ">
      <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB
      </h4>
      <!-- 16th June 1993 -->
      <p class="text-darkgrey text-base text-regular tracking-wide">{{userDetails?.['dob'] | handleEmptyString}}</p>
  </div>
  <div class="col-span-12 md:col-span-6">
      <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
      </h4>
      <p class="text-darkgrey text-base text-regular tracking-wide">{{userDetails?.['ssn'] | handleEmptyString}}</p>
  </div>
  <div class="col-span-12 transition-all ease-in-out overflow-hidden origin-top transform duration-500" [ngClass]="personalDetailsVisibility?'h-full':'h-0'">
      <h4 class="text-label-grey text-sm tracking-wide text-regular">Address
      </h4>
      <p class="text-darkgrey text-base text-regular tracking-wide">{{userDetails?.address | handleEmptyString}}</p>
  </div>
  <div class="col-span-12 cursor-pointer" (click)="personalDetailsVisibility = !personalDetailsVisibility">
      <a class="text-darkblue text-regular underline text-base my-2">
        <span *ngIf="!personalDetailsVisibility">See More</span>
        <span *ngIf="personalDetailsVisibility">See Less</span>
      </a>
  </div>
</div>
<!-- END: See More design Check Zeplin || Screen-29 -->


<div class="my-8" *ngIf="userDetails?.['request_stage'] >= 4">
  <hr class="mid-line border border-1">
</div>
<!-- BEGIN: See Less design || Screen-30 -->
<div class="flex flex-row justify-between" *ngIf="userDetails?.['request_stage'] >= 4">
  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
      Relationship Status
  </h4>
  <div>
      <p class="text-lightblue text-regular text-semibold tracking-wide flex flex-row">
        {{userDetails?.['marital_status'] | handleEmptyString}}

      </p>
  </div>
</div>

<div class="my-8" *ngIf="userDetails?.['request_stage'] >= 5">
  <hr class="mid-line border border-1">
</div>

<div *ngIf="userDetails?.['request_stage'] >= 5">
  <div class="flex flex-row justify-between">
    <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
        Your Children
    </h4>
    <div>
        <p class="text-lightblue text-regular text-semibold tracking-wide flex flex-row">
            1 Children
            <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-4" alt="digital vault">
        </p>
    </div>
  </div>
  <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mt-4">
    <div class="col-span-12">
        <h4 class="text-label-grey text-sm tracking-wide text-regular">Name
        </h4>
        <p class="text-darkgrey text-base text-regular tracking-wide">Lily Rose Depp</p>
    </div>
    <div class="col-span-12 md:col-span-6 ">
        <h4 class="text-label-grey text-sm tracking-wide text-regular">DOB
        </h4>
        <p class="text-darkgrey text-base text-regular tracking-wide">27th May 2000</p>
    </div>
    <div class="col-span-12 md:col-span-6">
        <h4 class="text-label-grey text-sm tracking-wide text-regular">SSN
        </h4>
        <p class="text-darkgrey text-base text-regular tracking-wide">627 129 193</p>
    </div>
    <div class="col-span-12 md:col-span-6 ">
        <h4 class="text-label-grey text-sm tracking-wide text-regular">Phone Number
        </h4>
        <p class="text-darkgrey text-base text-regular tracking-wide">+1 957 695 3845</p>
    </div>
    <div class="col-span-12 md:col-span-6">
        <h4 class="text-label-grey text-sm tracking-wide text-regular">Email Address
        </h4>
        <p class="text-darkgrey text-base text-regular tracking-wide">
            lilyrosedepp@gmail.com</p>
    </div>
    <div class="col-span-12">
        <a href="#" class="text-darkblue text-regular underline text-base my-2">See
                Less</a>
    </div>
  </div>
</div>
<!-- END: See More design || Screen-30 -->

<div class="my-6">
  <hr class="mid-line border border-1">
</div>
