<div class="grid grid-cols-12 gap-6 pt-8">
  <ng-container>
    <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="preLoader">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-8">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Digital Files
          </h4>
        </div>
      </div>
      <div class="bg-transparent border-card-grey rounded mt-4">
        <div class="grid grid-cols-12 p-6 gap-5">
          <div class="col-span-12 mt-4 sm:mt-0" *ngIf="preLoader">
            <div class="flex flex-row">
              <div>
                <app-shimmer-loading [loading]="preLoader" [shape]="'circle'" [width]="'4rem'">
                </app-shimmer-loading>
              </div>
              <div class="ml-6 w-full flex flex-col justify-center">
                <h4 class="text-darkgrey text-base tracking-wide text-regular">
                  <app-shimmer-loading [loading]="preLoader" class="w-full h-12">
                  </app-shimmer-loading>
                </h4>
                <p class="text-label-grey text-xs text-regular tracking-wide w-full">
                  <app-shimmer-loading [loading]="preLoader" class="w-12 h-12">
                  </app-shimmer-loading>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!preLoader && digitalFilesArray?.length">
      <div class="col-span-12 md:col-span-6 my-4 lg:pr-6">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Digital Files
            </h4>
          </div>
          <div class="col-span-4 cursor-pointer" *ngIf="digitalFilesEdit"
               (click)="getCommonHelper.routerNavigate(this.ROUTE.DIGITAL_FILES, this.userType !== 'Consumer' ? this.clientRequestId : null)">
            <img src="assets/images/about-yourself/edit.svg" *ngIf="!isAccessingVault && !isVaultClaimed" class="w-4 ml-4 float-right" alt="digital vault">
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4 py-3">
          <div class="grid grid-cols-12 px-6 py-3 gap-5 row-gap-4" *ngFor="let data of digitalFilesArray;let i=index">
            <div class="col-span-12" *ngIf="i">
              <hr class="divider w-full">
            </div>
            <div class="col-span-12 mt-4 sm:mt-0">
              <div class="flex flex-row">
                <div>
                  <img src="{{'assets/images/digital-files/pdf.svg' | fileTypeIcons:data?.name}}" class="w-6" alt="">
                </div>
                <div class="ml-6">
                  <h4 class="text-darkgrey text-base tracking-wide text-regular">
                    {{getFileName(data?.name.substr(15))}}
                  </h4>
                  <ng-container *ngIf="digitalFilesView">
                    <p class="text-label-grey text-xs text-regular tracking-wide cursor-pointer"
                      (click)="viewImage(data?.name)"
                      *ngIf="imageFormatFiltering.indexOf(data?.['file_extension']) !== -1">
                      View
                    </p>
                    <p class="text-label-grey text-xs text-regular tracking-wide cursor-pointer"
                      (click)="downLoadFile(data)"
                      *ngIf="isConsumer">
                      Download
                    </p>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!preLoader && digitalFilesArray?.length === 0">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-8">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Digital Files
          </h4>
        </div>
        <div class="col-span-4 cursor-pointer" *ngIf="digitalFilesEdit"
          (click)="getCommonHelper.routerNavigate(this.ROUTE.DIGITAL_FILES, this.userType !== 'Consumer' ? this.clientRequestId : null)">
          <img src="assets/images/about-yourself/edit.svg" *ngIf="!isAccessingVault && !isVaultClaimed"
            class="w-4 ml-4 float-right" alt="digital vault">
        </div>
      </div>
      <div class="bg-card-overview border-card-grey rounded mt-4">
        <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
          No Data to Display
        </h4>
      </div>
    </div>
  </ng-container>

  <ng-container>
    <div class="col-span-12 md:col-span-6 my-4 lg:pl-6" *ngIf="preLoader">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-8">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Important Documents
          </h4>
        </div>
      </div>
      <div class="bg-transparent border-card-grey rounded mt-4">
        <div class="grid grid-cols-12 p-6 gap-5">
          <div class="col-span-12 mt-4 sm:mt-0">
            <div class="flex flex-row">
              <div>
                <app-shimmer-loading [loading]="preLoader" [shape]="'circle'" [width]="'4rem'"></app-shimmer-loading>
              </div>
              <div class="ml-6 w-full flex flex-col justify-center">
                <h4 class="text-darkgrey text-base tracking-wide text-regular">
                  <app-shimmer-loading [loading]="preLoader" class="w-full h-12"></app-shimmer-loading>
                </h4>
                <p class="text-label-grey text-xs text-regular tracking-wide w-full">
                  <app-shimmer-loading [loading]="preLoader" class="w-12 h-12"></app-shimmer-loading>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!preLoader && willFilesArray?.length">
      <div class="col-span-12 md:col-span-6 my-4 lg:pl-6">
        <div class="grid grid-cols-12 justify-between items-center">
          <div class="col-span-8">
            <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
              Important Documents
            </h4>
          </div>
          <div class="col-span-4 cursor-pointer">
            <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right" alt="digital vault"
                 *ngIf="digitalFilesEdit && !isAccessingVault && !isVaultClaimed"
                 (click)="getCommonHelper.routerNavigate(this.ROUTE.WILL_FILES, this.userType !== 'Consumer' ? this.clientRequestId : null)">
          </div>
        </div>
        <div class="bg-card-overview border-card-grey rounded mt-4 py-3">
          <div class="grid grid-cols-12 px-6 py-3 gap-5 row-gap-4" *ngFor="let data of willFilesArray;let i=index">
            <div class="col-span-12" *ngIf="i">
              <hr class="divider w-full">
            </div>
            <div class="col-span-12 mt-4 sm:mt-0">
              <div class="flex flex-row">
                <div>
                  <img src="{{'assets/images/digital-files/pdf.svg' | fileTypeIcons:data?.name}}" class="w-6" alt="">
                </div>
                <div class="ml-6">
                  <h4 class="text-darkgrey text-base tracking-wide text-regular">
                    {{getFileName(data?.['fileName'])}}
                  </h4>
                  <ng-container *ngIf="digitalFilesView">
                    <p class="text-label-grey text-xs text-regular tracking-wide cursor-pointer"
                      (click)="viewImage(data?.name)"
                      *ngIf="imageFormatFiltering.indexOf(data?.['file_extension']) !== -1">
                      View
                    </p>
                    <p class="text-label-grey text-xs text-regular tracking-wide cursor-pointer"
                      (click)="downLoadFile(data)"
                      *ngIf="isConsumer">
                      Download
                    </p>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="col-span-12 md:col-span-6 my-4 lg:pr-6" *ngIf="!preLoader && willFilesArray?.length === 0">
      <div class="grid grid-cols-12 justify-between items-center">
        <div class="col-span-8">
          <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
            Important Documents
          </h4>
        </div>
        <div class="col-span-4 cursor-pointer">
          <img src="assets/images/about-yourself/edit.svg" class="w-4 ml-4 float-right" alt="digital vault"
            *ngIf="digitalFilesEdit && !isAccessingVault && !isVaultClaimed"
            (click)="getCommonHelper.routerNavigate(this.ROUTE.WILL_FILES, this.userType !== 'Consumer' ? this.clientRequestId : null)">
        </div>
      </div>
      <div class="bg-card-overview border-card-grey rounded mt-4">
        <h4 class="col-span-12 text-base text-regular tracking-wide text-semibold text-center">
          No Data to Display
        </h4>
      </div>
    </div>
  </ng-container>
</div>

<!-- View Image -->
<app-image-view-popup></app-image-view-popup>

<!-- Download file -->
<app-file-downloader [downloadLink]="downloadLink" [fileName]="downloadFileName"></app-file-downloader>
