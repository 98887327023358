<!-- OPTIONS START -->
<div *ngIf="!openTransferFeeModal">
  <ng-container *ngFor="let option of paymentOptions; let i = index;">
    <app-shimmer-loading [loading]="loading" shape="rect" width="100%" height="40px">
      <ng-container *ngIf="!hideOption(option) && !(option.feePaid && hidePaid)">
        <div class="text-regular text-orange-700 text-lg my-4" *ngIf="!paidSubscription">
          Your subscription fee is currently unpaid. Transfer fee payment unavailable until subscription is paid.
        </div>
        <div class="flex flex-col p-4 border-2 rounded-md" [ngClass]="{'mb-6': i < paymentOptions.length - 1, 'bg-gray-200': option.disable, 'bg-white': !option.disable}">
          <div class="flex flex-row w-full">
            <img [src]="getArrowSRC(option)" (click)="toggleSection(option)" alt="\/" class="w-4 mx-2 my-auto">
            <div class="flex flex-col sm:flex-row justify-between cursor-pointer w-full">
              <div class="flex flex-row" (click)="toggleSection(option)">
                <h4 class="flex text-darkblue text-lg md:text-xl text-semibold hover:underline"
                    [innerHTML]="option.header"></h4>
                <p *ngIf="!option.dropdownToggle"
                   class="text-base text-grey text-regular text-bold hover:underline my-auto mx-3">
                  Learn More!
                </p>
              </div>
              <div class="flex flex-row">
                <p *ngIf="option.feePaid" class="text-darkblue font-semibold text-md">&nbsp;(Paid!)</p>
                <p *ngIf="showYetToPaidText(option)" class="text-orange-700 font-semibold text-md">&nbsp;(Yet to be Paid by your Company)</p>
                <label class="text-lg lg:text-xl text-darkblue font-semibold text-regular mx-2 my-auto"
                       [for]="option.id" *ngIf="option.fee">
                  <app-shimmer-loading [loading]="loading" shape="rect" width="50px" height="16px">
                    <p [innerHTML]="getFeeString(option)"></p>
                  </app-shimmer-loading>
                </label>
                <input *ngIf="showCheckbox(option)"
                       class="form-checkbox w-5 h-5 my-auto payment-checkbox" type="checkbox"
                       [id]="option.id" [(ngModel)]="option.selected">
              </div>
            </div>
          </div>
          <ng-container *ngIf="option.dropdownToggle">
            <p class="text-base text-grey text-regular py-2" [innerHTML]="option.description"></p>
            <ng-container *ngIf="!option.feePaid && paymentOptions.length == 1; then payButton"></ng-container>
          </ng-container>
          <div *ngIf="option.requiredToSelect && !option.selected" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
            <p class="font-bold">Required !</p>
            <p>Please pay your subscription fee before proceeding with the transfer fee payment</p>
          </div>
        </div>
      </ng-container>
    </app-shimmer-loading>
  </ng-container>
</div>
<!-- OPTIONS END -->
<!-- TOTAL START -->
<div *ngIf="getSelectedOptions.length > 0 && !openTransferFeeModal"
     class="flex flex-row p-4 border-2 rounded-md bg-white mt-6">
  <div class="text-lg lg:text-xl text-darkblue tracking-wide font-semibold text-regular mx-2 my-auto">
    Total: {{getTotal | currency}}
  </div>
  <ng-container *ngTemplateOutlet="payButton"></ng-container>
</div>
<ng-template #payButton>
  <button *ngIf="paidSubscription"
    class="flex hover:underline-text cursor-pointer bg-darkblue rounded-xs focus:outline-none text-regular text-white px-6 py-2 ml-auto text-sm"
    (click)="payNow()">
    Pay Now!
  </button>
</ng-template>
<!-- TOTAL END -->

