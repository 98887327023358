<div class="scroll-hidden box-border body-content">
  <div class="pt-12">
    <div class="flex flex-wrap lg:mx-8 mx-6 ">
      <div class="w-full">
        <div class="flex items-center">
          <img src="assets/images/about-yourself/back-arrow.svg" alt="digital vault" class="cursor-pointer"
            (click)="changeRoute('managers')">
          <h2 class="text-darkblue text-md lg:text-2xl capitalize pl-5 text-semibold">
            Add Manager
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-8">
    <form [formGroup]="agentForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="border-card bg-white rounded-md p-6 mx-8">
        <!--first row-->
        <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4 mb-5">
          <div class="col-span-12 md:col-span-6 lg:col-span-4 ">
            <label for="first_name" class="text-sm mb2 text-regular text-label-grey">First Name</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="text"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.first_name.errors && formGet.first_name.touched?'form-input-error':'form-input-focus'"
              placeholder="First Name" formControlName="first_name" id="first_name">
              <p *ngFor="let error of getErrors('first_name')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-4 ">
            <label for="middle_name" class="text-sm mb2 text-regular text-label-grey">Middle Name</label>
            <input type="text"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.middle_name.errors && formGet.middle_name.touched?'form-input-error':'form-input-focus'"
              placeholder="Middle Name" formControlName="middle_name" id="middle_name">
              <p *ngFor="let error of getErrors('middle_name')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-4 ">
            <label for="last_name" class="text-sm mb2 text-regular text-label-grey">Last Name</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="text"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.last_name.errors && formGet.last_name.touched?'form-input-error':'form-input-focus'"
              placeholder="Last Name" formControlName="last_name" id="last_name">
              <p *ngFor="let error of getErrors('last_name')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-4">
            <label for="email" class="text-sm mb2 text-regular text-label-grey">Email Address</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="email"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.email.errors && formGet.email.touched?'form-input-error':'form-input-focus'"
              formControlName="email" name="email" id="email" placeholder="e.g. marktylor@gmail.com">
              <p *ngFor="let error of getErrors('email')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-4">
            <label for="phone_number" class="text-sm mb2 text-regular text-label-grey">Phone Number</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <app-phone-input [placeholder]="'e.g. 8665044754'" [phoneNumber]="agentForm.value.phone"
              [countryCode]="countryCode" [uniqueId]="'phone_number'"
              (onChange)="detectChangePhonenumber($event)"
              [errorClassTrigger]="formGet.phone.errors && formGet.phone.touched"
              [form]="agentForm" id="phone_number" (focusout)="formGet.phone.markAsTouched()">
            </app-phone-input>
            <p *ngFor="let error of getErrors('phone_number')" class="text-regular font-medium text-xs mt-2 error-alert">
              {{error}}
            </p>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-4 ">
            <label for="last_name" class="text-sm mb2 text-regular text-label-grey">Job Title</label>
            <span>
              <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
            </span>
            <input type="text"
              class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
              [ngClass]="formGet.manager_term.errors && formGet.manager_term.touched?'form-input-error':'form-input-focus'"
              placeholder="e.g Regional Manager" formControlName="manager_term" id="manager_term">
              <p *ngFor="let error of getErrors('manager_term')" class="text-regular font-medium text-xs mt-2 error-alert">
                {{error}}
              </p>
          </div>
        </div>
        <!--end-->
        <!--start second section-->
        <div class="w-full my-3 mb-5">
          <h3 class="text-semibold text-darkblue text-lg">Address</h3>
          <p class="text-grey text-md text-regular mb-4">Please enter their primary physical address.</p>
          <!--start iiner-->
          <div class="grid grid-cols-12 gap-4 gap-y-4 row-gap-4">
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
              <label for="country" class="text-sm mb-2 text-regular text-label-grey">Country</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <div class=" col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('country')">
                <app-country-search-component (optionChangeEmitter)="optionChangeListenerCountry($event)" id="country"
                  [defaultSelected]="formGet?.country?.value"
                  [enableError]="formGet.country.errors && formGet.country.touched"></app-country-search-component>
                  <p *ngFor="let error of getErrors('country')" class="text-regular font-medium text-xs mt-2 error-alert">
                    {{error}}
                  </p>
              </div>
            </div>
            <div class="col-span-12 md:col-span-6 lg:col-span-4 relative move-map" #moveMapHere>
              <label for="address" class="text-sm mb-2 text-regular text-label-grey">Street Address</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <input type="text" id="address" #search (keyup)="onAddressChange(search)" (input)="onFocusAddress()"
                (keydown)="$event.key == 'Enter' ? $event.preventDefault() : null"
                class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
                placeholder="e.g Main St" name="street_address" formControlName="street_address"
                [ngClass]="formGet.street_address.errors && formGet.street_address.touched?'form-input-error':'form-input-focus'">
                <p *ngFor="let error of getErrors('street_address')" class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
            </div>
            <div class="col-span-12 md:col-span-6 lg:col-span-4" *ngIf="selectedCountryName!==unitedKingdom">
              <label for="state" class="text-sm mb-2 text-regular text-label-grey">State/Province</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('state')">
                <app-state-search-component [countryCode]="formGet?.country?.value" id="state"
                  [enableError]="formGet.state.errors && formGet.state.touched" tabindex="0"
                  (focusout)="markAsTouchedIndividual('state')" [defaultSelected]="formGet?.state?.value"
                  (optionChangeEmitter)="optionChangeListenerState($event)">
                </app-state-search-component>
                <p *ngFor="let error of getErrors('state')" class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
              </div>
            </div>
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
              <label for="city" class="text-sm mb-2 text-regular text-label-grey">City</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <div class="col-span-12 lg:col-span-2 relative" (click)="markAsTouchedIndividual('city')">
                <app-city-search [stateCode]="formGet?.state?.value" id="city" tabindex="0"
                  (focusout)="markAsTouchedIndividual('city')"
                  [enableError]="formGet.city.errors && formGet.city.touched" [defaultSelected]="formGet?.city?.value"
                  (optionChangeEmitter)="optionChangeListenerCity($event)"></app-city-search>
                  <p *ngFor="let error of getErrors('city')" class="text-regular font-medium text-xs mt-2 error-alert">
                    {{error}}
                  </p>
              </div>
            </div>
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
              <label for="zipcode" class="text-sm mb-2 text-regular text-label-grey">Zip/Postal Code</label>
              <span>
                <fa-icon [icon]="asterisk" size="xs" transform="shrink-7 up-3"
                  [styles]="{'stroke': 'red', 'color': '#9CA3AF'}"></fa-icon>
              </span>
              <input type="text" id="zipcode"
                class="input mt-1 w-full rounded-xs h-12 text-regular text-grey appearance-none p-2 border block focus:outline-none placeholder-gray-400 text-base"
                placeholder="{{(selectedCountry==1)? 'Eg. 12345':(selectedCountry==2)?'Eg. A1A 1A1': (selectedCountry==3)?'Eg. 1234': (selectedCountry==4)?'Enter Zipcode':'Enter Zipcode'}}"
                name="zipcode" formControlName="zipcode" oninput="this.value = this.value.toUpperCase()"
                (focus)="validateZipCode()"
                [ngClass]="formGet.zipcode.errors && formGet.zipcode.touched?'form-input-error':'form-input-focus'">
                <p *ngFor="let error of getErrors('zipcode')" class="text-regular font-medium text-xs mt-2 error-alert">
                  {{error}}
                </p>
            </div>
          </div>
          <!--end-->
        </div>
        <!--end-->
        <!--start third section-->
        <!-- <div class="w-full my-3">
                    <h3 class="text-semibold text-darkblue text-lg mb-4">Upload Profile Picture</h3> -->
        <!--start iiner-->
        <!-- <div class="pt-0">
                        <div class="flex flex-wrap">
                            <div class="w-full md:w-full lg:w-2/3 mb-3 mentor">
                                <app-file-upload-handler class="w-6/12 lg:w-9/12"
                                    (fileUploadChangeEmitter)="uploadProfilePicture($event)"
                                    [fileValidationOptions]="fileValidationOptions">
                                </app-file-upload-handler>
                                <div *ngIf="formGet.profile_picture.errors">
                                    <p class="text-regular font-medium text-xs mt-2 error-alert"
                                        *ngIf="formGet.profile_picture.errors.required">
                                        Profile Picture is required
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> -->
        <!--end-->
        <!-- </div> -->
        <!-- <div class="w-full my-3">
                    <h3 class="text-semibold text-darkblue text-lg mb-4">Upload Proof</h3> -->
        <!--start iiner-->
        <div class="pt-0">
          <div class="flex flex-wrap">
            <div class="w-full md:w-full lg:w-2/3 mb-3 mentor ">
              <!--  <app-file-upload-handler class="w-6/12 lg:w-9/12"
                                    (fileUploadChangeEmitter)="uploadProofImage($event)"
                                    [fileValidationOptions]="fileValidationOptions">
                                </app-file-upload-handler>
                                <div *ngIf="formGet.upload_proof.errors">
                                    <p class="text-regular font-medium text-xs mt-2 error-alert"
                                        *ngIf="formGet.upload_proof.errors.required">
                                        Proof is required
                                    </p>
                                </div> -->
            </div>
            <div class="w-full  h-12 lg:h-24 md:w-full lg:w-1/3 relative">
              <div class="absolute bottom-0 right-0">
                <button
                  class="flex items-center justify-between button button--lg w-full bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white text-sm px-4">
                  <div class="loading-red mr-2" *ngIf="loginLoader"></div>
                  <div>Add Manager</div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--end-->
      </div>
      <!--end-->
      <!-- </div> -->
    </form>
  </div>
</div>
