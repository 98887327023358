import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalInvoiceService {
  private BASE_URL: string;

  constructor(private http: HttpClient) {
    this.BASE_URL = environment.BASE_URL;
  }

  /**
   * Retrieve Invoice List
   * @param {string}type
   * @param {number}page
   * @param {number}length
   * @param {string}sort
   * @param {string}search
   *
   * @return {Observable}
   */
  public getInvoiceList(type?: string, page?: number, length?: number, sort?: string, search?: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/professional/invoice-list/${type}?page=${page}&length=${length}&sort=${sort}&search=${search}`);
  }

  /**
   * Retrieve Invoice List
   * @param {Object}data
   *
   * @return {Observable}
   */
  public updateInvoiceMailAlertSettings(data:Object) {
    return this.http.post<APIResponseModel>(this.BASE_URL + `/professional/update-professional`, data);
  }
}
