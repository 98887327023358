import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ChipsOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { AssetsService } from 'src/app/services/assets.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { OverviewService } from 'src/app/services/overview.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { USER_TYPES } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { ProfessionalPermissionService } from '../../../professional-dashboard/services/professional-permission.service';
import { ReceivedDlbService } from '../../../services/received-dlb.service';


@Component({
  selector: 'app-property-overview',
  templateUrl: './property-overview.component.html',
})
/**
 * Property overview component
 */
export class PropertyOverviewComponent implements OnInit {
  private chipsList: ChipsOptionsInterface[];
  public editData: any;
  public preLoader: boolean = true;
  public propertyArray: Array<any> = [];
  public vehicleArray: Array<any> = [];
  public businessArray: Array<any> = [];
  public valuablesArray: Array<any> = [];
  public socialMediaArray: Array<any> = [];
  public subscriptionsArray: Array<any> = [];
  public donationArray: Array<any> = [];
  public prescriptionsArray: Array<any> = [];
  public governmentIdArray: Array<any> = [];
  public currentUser: any;
  public PIC_URL: string;
  public VEHICLE_URL: string;
  public VALUABLE_URL: string;
  public SOCIAL_MEDIA_URL: string;
  public chipsListAccess: Array<ChipsOptionsInterface>;
  public currentFilter: any;
  public loaded: boolean;
  public propertyData: any;
  public permissionList: Array<any>;
  public permission: Array<any>;
  public clientRequestId: string;
  public userType: string;
  public permission_status: { [key: string]: { status: boolean, edit: boolean } } = {};
  public ROUTE: { [key: string]: string } = {
    PROPERTY: 'Real Estate',
    VEHICLE: 'Vehicles',
    BUSINESS: 'Business',
    VALUABLES: 'Valuables',
    SOCIAL_MEDIA: 'Social Media',
    SUBSCRIPTIONS: 'Subscriptions',
    DONATIONS: 'Donations',
    PRESCRIPTIONS: 'Prescriptions',
    GOV_IDS: 'Government Id',
  };
  public isAccessingVault: boolean;
  public sectionDetails: any;
  private transferDataPaid: boolean = false;
  public isVaultClaimed: boolean;
  public userRequestID: string;
  /**
   * @constructor
   */
  constructor(
    private personalDetailsService: PersonalDetailsService,
    private proPermissionService: ProfessionalPermissionService,
    private commonHelper: CommonHelper,
    private localStorageService: LocalStorageService,
    private activeRouter: ActivatedRoute,
    private overviewService: OverviewService,
    private assetsService: AssetsService,
    private slugInterceptorService: SlugInterceptorService,
    private receivedDlbService: ReceivedDlbService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.isAccessingVault = this.localStorageService.getDataByKey('accessVault');
    this.chipsList = [
      { value: 1, displayText: 'Real Estate' },
      { value: 2, displayText: 'Vehicles' },
      { value: 3, displayText: 'Business' },
      { value: 4, displayText: 'Valuables' },
      { value: 5, displayText: 'Social Media' },
      { value: 6, displayText: 'Subscriptions' },
      { value: 7, displayText: 'Donations' },
      { value: 8, displayText: 'Prescriptions' },
      { value: 9, displayText: 'Government Id' },
    ];

    this.clientRequestId = this.commonHelper.getRequestId(this.activeRouter);

    this.userType = this.localStorageService.getDataByKey('role');
    this.userRequestID = this.commonHelper.getRequestId(this.activeRouter);
    this.currentUser = this.localStorageService.getUserData();
    this.getAssetsValue();
    this.overviewService.dashboardDataObserve.subscribe((response) => this.propertyData = response);

    this.chipsListAccess = [{ value: 0, displayText: 'All' }];
    this.getAccessControl();

    this.currentFilter = 0;

    // Create URL links.
    this.PIC_URL = environment.BASE_URL_RAW + 'uploads/' + this.clientRequestId;
    this.VEHICLE_URL = environment.BASE_URL_RAW + 'uploads/' + this.clientRequestId + '/vehicle_image/';
    this.VALUABLE_URL = environment.BASE_URL_RAW + 'uploads/' + this.clientRequestId + '/value_image/';
    this.SOCIAL_MEDIA_URL = environment.BASE_URL_RAW + 'uploads/' + 'admin/social_media/';

    this.sectionDetails = this.localStorageService.getDataByKey('sectionData');
    if (this.sectionDetails && this.userType !== 'Consumer') {
      this.currentFilter = this.chipsListAccess.find(item => item.displayText === this.sectionDetails.name)?.value ?? this.currentFilter;
    }

    if (this.isCustodian) {
      this.initTransferDataPaid();
    } else {
      this.getPropertyDetails();
    }
    this.isVaultClaimed = this.localStorageService.getDataByKey('isVaultClaimed') == 1;
  }

  private initTransferDataPaid() {
    this.receivedDlbService.isTransferPaid(this.clientRequestId).subscribe({
      next: r => this.localStorageService.storeData('is_transfer_amount_paid', r),
      error: e => console.error(e),
      complete: () => this.getPropertyDetails(),
    });
  }


  private get isCustodian(): boolean {
    return this.userType === 'Custodian';
  }

  /**
   * Establish ACL Permissions. Consumer is assigned full access.
   */
  public getAccessControl(): void {
    if (this.userType === 'Consumer' || (this.commonHelper.isCustodian && this.localStorageService.getDataByKey('isVaultClaimed'))) {
      // Populate with full permissions for consumer.
      this.chipsList.forEach((e) => {
        this.permission_status[e.displayText] = {
          status: true,
          edit: this.commonHelper.isCustodian ? false : true,
        };
        this.chipsListAccess.push({ value: e.value, displayText: e.displayText });
      });
    } else {
      const requestId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('request_user_id') : this.userRequestID;
      const userId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('user_id') : this.localStorageService.getDataByKey('agent_id') || this.localStorageService.getUserData(USER_TYPES.pro)?.user?.id;
      this.proPermissionService.getAccessControl(userId, requestId).subscribe({
        next: (permissions) => {
          for (const permissionsKey in permissions) {
            this.onSelectSection({ permission_name: permissionsKey, ...permissions[permissionsKey] });
          }
        },
        error: (exception: any) => {
          this.commonHelper.httpResponseHandler(exception?.error)
        },
        complete: () => {
          this.getPropertyDetails();
        }
      })
    }
  }

  /**
   * Returns route defined in ProfessionalPermissionService based on permission name
   * @param route
   */
  public getRoute(route: string): string[] {
    return this.proPermissionService.permissionList[route]['section'];
  }

  /**
   * on clicking on section name
   * @param {{}} sectionData
   */
  public onSelectSection(sectionData: {}) {
    // Can edit?
    const bEdit = sectionData['add'] == 1 || sectionData['edit'] == 1 || sectionData['delete'] == 1;

    const chip: ChipsOptionsInterface = this.chipsList.filter((e) => {
      return e.displayText == sectionData['permission_name'];
    })[0] ?? null;
    if (chip) {
      this.permission_status[sectionData['permission_name']] = {
        status: sectionData['view'] == 1 || bEdit,
        edit: bEdit,
      };
      this.chipsListAccess.push(chip);
    }
  }

  /**
   * get property details
   */
  public getPropertyDetails(): void {
    this.preLoader = true;
    if(this.commonHelper.isClaimedAndNotPaid) return; // handle custodian claimed valut but not paid transfer fee

    this.personalDetailsService.getPersonalDetails(this.clientRequestId)
      .subscribe((response: APIResponseModel) => {
        if (response.status) {
          Object.keys(this.permission_status).forEach(section => {
            switch (section) {
              case 'Real Estate':
                this.propertyArray = this.permission_status[section].status && response.data.user_property;
                break;
              case 'Vehicles':
                this.vehicleArray = this.permission_status[section].status && response.data.user_vehicle;
                break;
              case 'Business':
                this.businessArray = this.permission_status[section].status && response.data.user_business;
                break;
              case 'Valuables':
                this.valuablesArray = this.permission_status[section].status && response.data.user_valuable;
                break;
              case 'Social Media':
                this.socialMediaArray = this.permission_status[section].status && response.data.user_social_media;
                break;
              case 'Subscriptions':
                this.subscriptionsArray = this.permission_status[section].status && response.data.user_subscriptions;
                break;
              case 'Donations':
                this.donationArray = this.permission_status[section].status && response.data.user_donations;
                break;
              case 'Prescriptions':
                this.prescriptionsArray = this.permission_status[section].status && response.data.user_prescriptions;
                break;
              case 'Government Id':
                this.governmentIdArray = this.permission_status[section].status && response.data.user_government_id;
                break;

            }
          })
          this.imageUrlResolver(this.propertyArray, 'property_image');
          this.imageUrlResolver(this.vehicleArray, 'vehicle_image');
          this.imageUrlResolver(this.valuablesArray, 'value_image');
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
        () => this.preLoader = false);
    this.preLoader = false;
  }

  /**
   * chip on change listener
   *
   * @param {ChipsOptionsInterface} data
   */
  public chipChangeListener(data: ChipsOptionsInterface): void {
    if (!data) return;
    this.currentFilter = data.value;
  }

  /**
   * get assets value
   */
  public getAssetsValue() {
    this.assetsService.getTotalAssetsValue(this.clientRequestId)
      .subscribe((response: APIResponseModel) => this.overviewService.dashboardDataObservableEvents(response.data));
  }

  /**
   * Get function for html template to access this.commonHelper & allow AOT compilation
   */
  get getCommonHelper(): CommonHelper {
    return this.commonHelper;
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url]);
  }

  /**
   * Images url resolver
   * @param data
   * @param key
   */
  private imageUrlResolver(data: Array<any>, key: string): void {
    const imageType = ['png', 'jpg', 'jpeg'];
    data.forEach((data) => {
      const name = data?.[key]?.split(',');
      const extension = data?.file_extension?.split(',');
      for (let i = 0; i < name?.length; i++) {
        if (name[i]?.length > 0) {
          const files = {
            name: name[i],
            type: extension[i],
          };
          if (imageType.some(e => e == files?.type)) {
            Object.keys(data).map(e => data[key] = files?.name);
          }
        }
      }
    })
  }
}
