import { HttpEventType } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { FileValidationOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { DigitalFilesService } from 'src/app/services/digital-files.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { CLEAR_SELECTED_FILE } from 'src/constants/digitalFile.const';
import { SECTIONS, SectionStatus, USER_TYPES } from '../../../constants/application.const';
import { ProfessionalPermissionService } from '../../professional-dashboard/services/professional-permission.service';
import { FileListingEmitModel, FileUploadSettingsModel } from '../../sharedComponent/file-listing/file-listing.component';
import { ConsumerSubscriptionService } from '../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-digital-files',
  templateUrl: './digital-files.component.html',
})
/**
 * Digital files component
 */
export class DigitalFilesComponent implements OnInit {
  public file: any;
  public preDefinedLoader: boolean;
  public digitalFilesArray: Array<any>;
  public filePercentage: number;
  public selectedFiles: any;
  public fileValidationOptions: FileValidationOptionsInterface;
  public sectionSaveExitOptions: any;
  public hideDeleteIcon: boolean;
  public userType: string;
  public clientRequestId: string;
  public PIC_URL: string;
  public permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  /**
   * True signifies hiding the 'save' buttons and showing the 'progress' buttons
   */
  public buttonProgress: boolean = false;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public userRequestID: string;
  public vaultVideo: string;
  public fileUploadSettings: FileUploadSettingsModel;
  hasPaidSubscription: boolean;
  public clientHasPaidSubscription: boolean;

  /**
   * @constructor
   */
  constructor(
    private digitalFileService: DigitalFilesService,
    private commonHelper: CommonHelper,
    private personalDetailsService: PersonalDetailsService,
    private commonService: CommonService,
    private modalService: CommonModelService,
    private toastService: ToastrService,
    private route: ActivatedRoute,
    private proPermissionService: ProfessionalPermissionService,
    private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService,
    private subscriptionService: ConsumerSubscriptionService,
  ) {
  }

  @HostListener('window:beforeunload')
  beforeUnloadHandler() {
    return this.filePercentage == 0 || this.filePercentage == 100;
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.hasPaidSubscription = r);
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.sectionSaveExitOptions = {
      section: 15,
      type: 1,
    };
    this.hideDeleteIcon = false;
    this.clientRequestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.userRequestID = this.commonHelper.getRequestId(this.route);
    // file validation options
    this.fileValidationOptions = {
      size: 100000,
      fileFormat: ['jpeg', 'jpg', 'png', 'doc', 'docx', 'txt', 'pdf', 'mp3', 'mp4', 'avi', 'mov', 'flv', 'mkv', 'x-flv', 'quicktime', 'x-msvideo', 'vnd.openxmlformats-officedocument.wordprocessingml.document', 'plain', 'mpeg', 'msword', 'x-matroska'],
    };
    this.fileUploadSettings = {
      fileValidationOptions: this.fileValidationOptions,
      hideFileOperations: true,
      allowMultiple: true,
      isDigitalFiles: true,
    };

    this.userType = this.localStorageService.getDataByKey('role');
    this.filePercentage = 0;
    this.commonService.fileHanlderObservableEvents({
      type: CLEAR_SELECTED_FILE,
    });
    this.getPersonalDetails();
    this.getAccessControl();
    if (this.clientRequestId) {
      this.personalDetailsService.setSectionCompletionStatus(this.clientRequestId, SECTIONS.digitalFiles, SectionStatus.INCOMPLETE).subscribe();
    }
  }

  /**
   * Establish ACL Permissions. Consumer is assigned full access.
   */
  public getAccessControl(): void {
    if (this.userType !== 'Consumer') {
      let getACLService;
      const requestId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('request_user_id') : this.userRequestID;
      const userId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('user_id') : this.localStorageService.getDataByKey('agent_id') || this.localStorageService.getUserData(USER_TYPES.pro)?.user?.id;
      getACLService = this.proPermissionService.getAccessControl(userId, requestId, ['Digital Files']);
      getACLService.subscribe(
        (permissions) => this.permissions = permissions?.['Digital Files'],
        (exception: any) => (this.commonHelper.httpResponseHandler(exception?.error)));
    } else {
      this.permissions = {
        view: true,
        add: true,
        edit: true,
        delete: true,
      };
    }
  }

  /**
   * Get personal details
   */
  public async getPersonalDetails(){
    this.preDefinedLoader = true;
    this.personalDetailsService.getPersonalDetails(this.clientRequestId).subscribe({
      next: async  (response: APIResponseModel) => {
        if (response.status) {
          this.digitalFilesArray = response.data.user_digital_file.filter((d: any) => {
            if (d?.file_extension) {
              d['file_extension'] = d['file_extension'].replace(/,/g, ''); // using multiple upload file comma comes with extension, so replaces string
            }
            return !d.is_will;
          });
          this.clientHasPaidSubscription = await this.commonHelper.checkClientSubscription(response);
        }
      }, error: (exception: any) => this.commonHelper.httpResponseHandler(exception?.error), complete: () => this.preDefinedLoader = false,
    });
  }

  /**
   * File upload change emitter
   *
   * @param {any} data
   */
  public fileUploadChangeEmitter(data: any): void {
    if (data) {
      this.selectedFiles = data;
      const sendingData = {
        is_will: '0',
        is_delete: '0',
        request_id: this.clientRequestId,
        roletype: this.userType,
      };
      if (this.selectedFiles.length > 0) {
        const formData = new FormData();

        for (const file of this.selectedFiles) {
          formData.append('digital_files[]', file);
        }
        // appending every value to form object
        Object.keys(sendingData).forEach((key) => {
          if (!sendingData[key]) return;
          formData.append(key, sendingData[key]);
        });
        this.digitalFileService.storeDigitalFiels(formData).subscribe((event: any) => {
          console.log('store', event);
          if (event.type === HttpEventType.UploadProgress) {
            this.filePercentage = Math.round(100 * event.loaded / event.total);
          }
          if (event.type === HttpEventType.Response) {
            this.commonService.fileHanlderObservableEvents({ type: CLEAR_SELECTED_FILE });
            this.commonHelper.updateLocalstorageRequestStage(event.body.data);
            this.commonHelper.toastrFileUploadSuccess();
            this.getPersonalDetails();
          }
        }, (exception) => {
          this.filePercentage = 0;
          this.commonService.fileHanlderObservableEvents({ type: CLEAR_SELECTED_FILE });
          this.commonHelper.httpResponseHandler(exception?.error);
        }, () => this.filePercentage = 0);
      } else {
        this.filePercentage = 0;
        this.file = undefined;
        this.commonService.fileHanlderObservableEvents({ type: CLEAR_SELECTED_FILE });
      }
    }
  }

  /**
   * Delete digital files uploaded based on name
   *
   * @param {string} id
   */
  public deleteDigitalFiles(id: string): void {
    this.hideDeleteIcon = !this.hideDeleteIcon;
    const sendingData = {
      is_will: '0',
      is_delete: '1',
      id,
      request_id: this.clientRequestId,
      roletype: this.userType,
    };
    const formData = new FormData();
    // appending every value to form object
    Object.keys(sendingData).forEach((key) => {
      if (!sendingData[key]) return;
      formData.append(key, sendingData[key]);
    });
    // Delete digital files
    this.digitalFileService.storeDigitalFiels(formData).subscribe((event: any) => {
      if (event.type === HttpEventType.Response) {
        this.toastService.success(event.body.message);
        this.getPersonalDetails();
        this.hideDeleteIcon = !this.hideDeleteIcon;
      }
    }, (exception) => this.commonHelper.httpResponseHandler(exception?.error));
  }

  /**
   * Button click handler
   * @param command
   */
  public click(command: string) {
    const isPro = this.userType !== 'Consumer';
    switch (command) {
      case 'ReturnToDashboard':
        const return_url = isPro
          ? ['overview', 'will-digital-file-overview']
          : ['dashboard'];
        void this.slugInterceptorService.navigate(return_url);
        break;
      case 'Back':
        const back_url = ['government-id-list'];
        if (isPro) {
          back_url.push(this.clientRequestId);
        }
        void this.slugInterceptorService.navigate(back_url);
        break;
      case 'MarkComplete':
        this.buttonProgress = true;
        this.personalDetailsService
          .setSectionCompletionStatus(
            this.clientRequestId,
            SECTIONS.digitalFiles,
            SectionStatus.COMPLETE,
          )
          .subscribe();
        break;
      case 'SaveForLater':
        this.buttonProgress = true;
        break;
      case 'NextSection':
        const url = ['will-files'];
        if (isPro) {
          url.push(this.clientRequestId);
        }
        void this.slugInterceptorService.navigate(url);
        break;
    }
  }

  /**
   * open modal
   * @param{string} item
   */
  public openModal(item: string) {
    this.vaultVideo = item;
    this.modalService.open('view-demo-video-modal');

  }

  fileListingListener($event: FileListingEmitModel) {
    if ($event.image_delete) {
      const name = $event.image_delete;
      const id = this.digitalFilesArray.find((e => e.name == name)).id;
      this.deleteDigitalFiles(id);
    }
    if ($event.fileUploadChange) {
      this.fileUploadChangeEmitter($event.fileUploadChange);
    }
  }
}
