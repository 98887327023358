import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  AddFinancialAssetPopupComponent,
} from 'src/app/components/assets/financial-assets/add-financial-asset-popup/add-financial-asset-popup.component';
import { ConsumerSubscriptionService } from 'src/app/components/consumer-payment-section/consumer-subscription.service';
import { CommonHelper } from 'src/app/helper/common.helper';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { AssetsService } from 'src/app/services/assets.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeopleService } from 'src/app/services/people.service';
import { PersonalDetailsService } from 'src/app/services/personal-details.service';
import { ConsumerPaymentMethod, USER_TYPES } from 'src/constants/application.const';
import {
  ProfessionalPermissionService,
} from '../../../../professional-dashboard/services/professional-permission.service';


@Component({
  selector: 'app-finance-list',
  templateUrl: './finance-list.component.html',
})
/**
 * Finance list component
 */
export class FinanceListComponent implements OnInit {
  @Input() isAssetsView = false;
  @Output() isLoading = new EventEmitter();
  @Output() paidStatusEvent = new EventEmitter(null);
  @Output() emitPermissionsList = new EventEmitter(null);
  public preDefinedLoader: boolean;
  public midPlaid = 'FA_plaid-import-modal';
  public financialAssetList: Array<any>;
  public editData: any;
  public userType: string;
  public clientRequestId: string;
  public clientUserId:string;
  public clientPaymentMode:ConsumerPaymentMethod;
  public permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  public $overallBeneficiaryArray: Array<any>;
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public userRequestID: string;
  public clientHasPaidSubscription:boolean;


  /**
   * @constructor
   */
  constructor(
    private personalDetailsService: PersonalDetailsService,
    private modalService: CommonModelService,
    private commonHelper: CommonHelper,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private proPermissionService: ProfessionalPermissionService,
    private assetsService: AssetsService,
    private toastrService: ToastrService,
    private peopleService: PeopleService,
    private subscriptionService: ConsumerSubscriptionService,
  ) {
  }


  /**
   * called initially
   */
  ngOnInit(): void {
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.clientRequestId = this.route.snapshot.params['id'] || this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id');
    this.userType = this.localStorageService.getDataByKey('role');
    this.userRequestID = this.commonHelper.getRequestId(this.route);
    this.getPersonalDetails();
    this.assetsService.accountListObserve.subscribe((response) => {
      if (response.length) {
        this.getPersonalDetails();
      }
    });

    this.getAccessControl();
    // refresh beneficiary
    this.peopleService.refreshDetailsCommonObserve.subscribe((response: any) => {
      if (response) {
        this.getPersonalDetails();
      }
    });
  }


  /**
   * Establish ACL Permissions. Consumer is assigned full access.
   */
  public getAccessControl(): void {
    if (this.userType !== 'Consumer') {
      let getACLService;
      const requestId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('request_user_id') : this.userRequestID;
      const userId = this.commonHelper.isCustodian ? this.localStorageService.getDataByKey('user_id') : this.localStorageService.getDataByKey('agent_id') || this.localStorageService.getUserData(USER_TYPES.pro)?.user?.id;
      getACLService = this.proPermissionService.getAccessControl(userId, requestId, ['Financial Assets']);
      getACLService.subscribe(
        (permissions) => {
          this.permissions = permissions?.['Financial Assets'],
        this.emitPermissionsList.emit({permissionsList : this.permissions})
        },
        (exception: any) => (this.commonHelper.httpResponseHandler(exception?.error)));
    } else {
      this.permissions = {
        view: true,
        add: true,
        edit: true,
        delete: true,
      };
      this.emitPermissionsList.emit({permissionsList : this.permissions})
    }
  }


  /**
   * Get personal details
   */
  public getPersonalDetails(): void {
    this.setLoading(true);
    // get people list
    this.personalDetailsService.getPersonalDetails(this.userType !== 'Consumer' ? this.clientRequestId : null).subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          this.financialAssetList = response.data.user_financial_asset;
          this.$overallBeneficiaryArray = response.data.user_people.filter((user) => user.beneficiary === 1);
          if(this.commonHelper.isProfessional){
            this.clientUserId = response?.data?.['user']?.['id'];
            this.clientPaymentMode = response?.data?.['user']?.['payment_type'];
            this.subscriptionService.clientHasPaidSubscription(this.clientUserId,this.clientPaymentMode).then( r =>{
              this.clientHasPaidSubscription = r;
              this.paidStatusEvent.emit({clientHasPaidSubscription : this.clientHasPaidSubscription})
            })
          }
        }
      }, error: e => this.commonHelper.httpResponseHandler(e.error),
      complete: () => this.setLoading(false),
    });
  }


  /**
   * Set {@link preDefinedLoader} and emit the value.
   * @param loading
   */
  public setLoading(loading: boolean) {
    this.preDefinedLoader = loading;
    this.isLoading.emit(loading);
  }


  /**
   * Toggle modal edit financial details
   *
   * @param editValue
   */
  public toggleModalEdit(editValue: any): void {
    let bEdit = this.permissions?.['view'] || this.permissions?.['add'] || this.permissions?.['edit'] || this.permissions?.['delete'];
    if (bEdit) {
      this.editData = editValue ? { ...editValue } : {};
      this.modalService.open('add-financial-assets-modal');
    } else {
      this.toastrService.info('You do not have permission to access this information.');
    }
  }


  /**
   * Listens for changes to the financial assets list
   */
  public modalEventListener(event: any): void {
    if (event) {
      this.modalService.close(this.midPlaid)
    }
    this.getPersonalDetails();
  }


  /**
   * To confirm add-financial-asset-popup has finished initializing. If user opens an asset for edit before
   * initialization has finished, the resetForm() function will clear an edited asset's information from the modal.
   */
  public isLoadComplete(): boolean {
    return AddFinancialAssetPopupComponent.loadComplete;
  }

  /**
   * Determines whether the finance assets list can be shown based on certain conditions.
   * @returns {boolean} Returns true if the finance assets list can be shown; otherwise, false.
   */
  public get canShowFinanceAssetsList():boolean{
    return this.financialAssetList.length > 0 &&
   (!this.commonHelper.isProfessional || this.clientHasPaidSubscription);
  }

   /**
   * Gets the secondary content based on the provided data.
   * @param data The data object containing 'bank_name' and 'financial_asset_type' properties.
   * @returns The secondary content string or null if no secondary content is available.
   */
   public getSecondaryContent(data): string | null {
    const b = data?.['bank_name'];
    const c = data?.['financial_asset_type']?.name;
    if (b && c) {return `${b} | ${c}`;}
    if (b){return b;}
    if (c){ return c;}
    return null;
  }
}
