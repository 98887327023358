import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ChartsService } from 'src/app/professional-dashboard/services/charts.service';
import { ProfessionalManagementService } from 'src/app/professional-dashboard/services/professional-management.service';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { CommonModelService } from 'src/app/services/common-model.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ORDER_VALUE, SELECT_VALUE } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-professionals-listing-table',
  templateUrl: './professionals-listing-table.component.html',
  styleUrls: ['./professionals-listing-table.component.css']
})
export class ProfessionalsListingTableComponent implements OnInit {
  public professionalsList: Array<Object>;
  public professionalTableSettings: professionalTableSettingsI;
  public profileMenuToggle: boolean;
  public profileMenuToggleBuffer: boolean;
  public selectOptions: Array<Object>;
  public orderOptions: Array<Object>;
  public currentPage = 1;
  public spinner: boolean;
  public paginationLinks: Array<any>;
  public lastPage: number;
  public index: number;

  public professionalImageSrc: string;
  public viewProData: IProfessionalData;

  public submitLoaderDelete: boolean;
  public deleteId: any;


  constructor(
    private toastrService: ToastrService,
    private chartService: ChartsService,
    private localStorageService: LocalStorageService,
    private professionalUserService: ProfessionalUserService,
    private proManagementService: ProfessionalManagementService,
    private commonHelper: CommonHelper,
    private slugInterceptorService: SlugInterceptorService,
    public modalService: CommonModelService,) { }

  ngOnInit(): void {
    this.professionalTableSettings = this.localStorageService.getDataByKey('professionalTableSettings') || {
      sortPreference: SortPreference.Name,
      recordsPerPage: 5,
      searchKeyword: '',
    };
    this.professionalTableSettings.searchKeyword = '';
    this.professionalImageSrc = `${environment.BASE_URL_RAW}uploads/agent_proof/`;
    this.currentPage = 1;
    this.spinner = true;
    this.getProfessionals();
    this.selectOptions = SELECT_VALUE;
    this.orderOptions = ORDER_VALUE;
    this.profileMenuToggle = false;
  }

  /**
   * Displays the professional's information in a modal view.
   * @param {object} proData - The data of the professional to view.
   */
  public viewProfessional(proData: any) {
    const country = this.commonHelper.capitalizeFirstLetter(proData.country?.name);
    const fullAddress = this.commonHelper.addCommas(proData?.address, proData?.apt_number, proData.city?.name, proData.state?.name, country)
    this.viewProData = {
      name: proData?.["full_name"],
      email: proData?.['email'],
      address: fullAddress,
      phone: (proData['country_code'] ? '+' + proData['country_code'] + ' ' : ' ') + proData['phone'],
      profileImageSrc: proData['profile_picture'] ? this.professionalImageSrc + proData['profile_picture'] : 'assets/icons/user-bg.svg'
    }
    this.modalService.open('profile-view');
  }

  /**
   * professional list
   * @param {number}pageNo
   * @param {number}records
   * @param {string}order
   */
  public getProfessionalsList(pageNo?: number) {
    if (this.currentPage === pageNo) return;
    if (pageNo) {
      this.currentPage = pageNo;
    }
    this.spinner = true;
    this.localStorageService.storeData('professionalTableSettings', this.professionalTableSettings);
    this.proManagementService
      .getAllProfessionals(this.currentPage, this.professionalTableSettings.recordsPerPage, SortPreference.Name, this.professionalTableSettings.searchKeyword)
      .subscribe((response) => {
        this.professionalsList = response.data.data;
        this.paginationLinks = response.data.links;
        this.lastPage = response.data.last_page;
        this.spinner = false;
        this.sortProfessionalList();
      });
  }

  /**
   * toggle dropdown
   * Index less than zero should close all.
   * @param{number}ind
   */
  public toggleDropDown(ind: number) {
    if (ind < 0 && this.profileMenuToggle && this.profileMenuToggleBuffer) {
      this.profileMenuToggleBuffer = false;
    } else if (ind < 0) {
      this.profileMenuToggle = false;
    } else { // Else :: open at index.
      this.profileMenuToggleBuffer = true;
      this.profileMenuToggle = !this.profileMenuToggle;
      this.index = ind;
    }
  }

  /**
   * get all professionals
   */
  public getProfessionals() {
    this.spinner = true;
    this.localStorageService.storeData('professionalTableSettings', this.professionalTableSettings);
    this.proManagementService
      .getAllProfessionals(this.currentPage, this.professionalTableSettings.recordsPerPage, SortPreference.Name, this.professionalTableSettings.searchKeyword)
      .subscribe({
        next: (response) => {
          this.spinner = false;
          this.professionalsList = response.data.data;
          this.paginationLinks = response.data.links;
          this.lastPage = response.data.last_page;
          this.sortProfessionalList();
        },
        error: () => this.spinner = false,
      });
  }

  /**
   * delete professional
   * @param{any}isDelete
   */
  public deleteProfessional(isDelete: any) {
    if (isDelete) {
      this.submitLoaderDelete = true;
      this.proManagementService.deleteProfessional(this.deleteId).subscribe((response) => {
        this.modalService.close('delete-confirmation-modal');
        this.toastrService.success(response.message);
        this.getProfessionals();
        this.submitLoaderDelete = false;
      },
        (exception: any) => {
          this.submitLoaderDelete = false;

          this.commonHelper.httpResponseHandler(exception.error);
        });
    } else {
      this.submitLoaderDelete = false;
    }
    this.profileMenuToggle = false;
  }

  // Function to sort the professionalsList based on the current sortPreference
  public sortProfessionalList() {
    switch (this.professionalTableSettings.sortPreference) {

      case SortPreference.NoofClients:
        this.professionalsList.sort((a, b) => {
          const noOfClientsA = a['total_clients'];
          const noOfClientsB = b['total_clients'];

          if (noOfClientsA < noOfClientsB) {
            return -1;
          }

          if (noOfClientsA > noOfClientsB) {
            return 1;
          }

          return 0;
        });
        break;

      case SortPreference.OldToNew:
        // Sort by Old to New
        this.professionalsList.sort((a, b) => {
          const dateA = new Date(a['created_at']);
          const dateB = new Date(b['created_at']);

          if (dateA < dateB) {
            return -1;
          }

          if (dateA > dateB) {
            return 1;
          }

          return 0;
        });
        break;

      case SortPreference.NewToOld:
        // Sort by New to Old
        this.professionalsList.sort((a, b) => {
          const dateA = new Date(a['created_at']);
          const dateB = new Date(b['created_at']);

          if (dateA > dateB) {
            return -1;
          }

          if (dateA < dateB) {
            return 1;
          }

          return 0;
        });
        break;

      default:
        // Handle the default case (no sorting)
        break;
    }
  }

  /**
   * edit professional
   * @param{any}data
   */
  public editProfessional(data: any) {
    this.localStorageService.storeData('edit-pro-id', data);
    this.proManagementService.emitChange(data);
  }

  /**
   * change route
   * @param{string}url
   */
  public changeRoute(url: string) {
    void this.slugInterceptorService.navigate([url], null, true);
  }

  /**
   * search professional based on keyword
   * @param{string}keyword
   */
  public onSearchProfessional(keyword: string) {
    this.professionalTableSettings.searchKeyword = keyword;
    if (keyword === '') {
      this.getProfessionals();
    } else {
      this.spinner = true;
      this.localStorageService.storeData('professionalTableSettings', this.professionalTableSettings);
      this.proManagementService.getAllProfessionals(this.currentPage, this.professionalTableSettings.recordsPerPage, SortPreference.Name, this.professionalTableSettings.searchKeyword).subscribe((response) => {
        this.spinner = false;
        this.professionalsList = response.data.data;
        this.lastPage = response.data.last_page;
        this.sortProfessionalList();
      });
    }
  }

  /**
   * open delete modal
   * @param{any}data
   */
  public openConfirmationModal(data: any) {
    this.deleteId = data.id;
    this.modalService.open('delete-confirmation-modal');
    this.profileMenuToggle = false;
  }
}

enum SortPreference {
  Name = 'desc',
  OldToNew = 'Old to New',
  NewToOld = 'New to Old',
  LastLogin = 'login',
  NoofClients = 'clients'
}


interface professionalTableSettingsI {
  sortPreference: SortPreference,
  recordsPerPage: number,
  searchKeyword: string
}

interface IProfessionalData {
  name?: string;
  email?: string;
  address?: string;
  phone?: string;
  profileImageSrc?: string;
}
