<div class="lg:grid grid-cols-12 background-color-grey">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 my-4">
              <div class="col-span-12 sm:col-span-2">
                  <img src="assets/images/executors/executors.svg" class="w-20" alt="digital vault">
              </div>

              <div class="col-span-12 sm:col-span-10">
                  <div>
                    <h2 class="text-darkblue text-xl md:text-2xl lg:text-3xl uppercase text-semibold">
                      {{agentTerm}}s
                      <a class="inline-block" (click)="openModal('professionalServiceProviders')" title='Click here for help from Aida'>
                        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                          stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1n text-primary cursor-pointer">
                          <circle cx="12" cy="12" r="10"></circle>
                          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                          <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                      </a>
                    </h2>
                  </div>
                  <!--p class="text-sm text-grey tracking-wide text-regular">This bit of information is important, as
                    it helps to make it clear that this is genuinely you. Double-check your spelling and details
                    to be safe.
                  </p-->
              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-4/6'"></app-card-progress-bar>
              <!-- END: Progress Bar -->

              <div class="p-6">
                <div class="grid grid-cols-12 items-center gap-4 row-gap-4 pb-2">
                  <div class="col-span-12">
                    <h4 class="text-semibold text-lg text-darkblue tracking-wide">
                      Enter your {{agentTerm}}'s Information
                    </h4>
                    <p class="text-grey text-regular tracking-wide pt-1 text-sm">
                      The {{agentTerm}}s will help you to manage your vault.
                    </p>
                  </div>
                </div>

                <!-- executor listing component -->
                <app-advisor-listing [requestID]="clientRequestId">
                </app-advisor-listing>

                <div class="mt-10">
                  <button type="button"
                    class="button button--lg w-full flex flex-row justify-center items-center bg-transparent border-lightblue rounded-xs h-12 md:h-16 focus:outline-none text-regular font-semibold tracking-wide text-darkblue px-6 text-sm md:text-base"
                    (click)="toggleModal()">
                    <img src="assets/images/about-yourself/add-plus.svg" class="w-6 mr-3 hidden sm:block" alt="digital vault">
                    Add a {{agentTerm}}
                  </button>
                </div>

                <div class="flex flex-col-reverse sm:flex sm:flex-row gap-4 justify-between items-center mt-8">
                  <button (click)="click('Back')"
                    class="cursor-pointer  text-sm mt-2 sm:mt-0 text-back text-regular font-semibold text-sm tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </button>
                  <div class="flex flex-col-reverse sm:flex sm:flex-row gap-4 justify-end items-center w-full">
                    <ng-container *ngIf="!buttonProgress">
                      <button (click)="click('SaveForLater')"
                        [disabled]="submitLoader || preDefinedLoader || !canAccessForm"
                        class="flex justify-center items-center border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 text-regular px-6">
                        <div class="loading-blue mr-2" *ngIf="submitLoader || preDefinedLoader"></div>
                        Save for Later
                      </button>
                      <button (click)="click('MarkComplete')"
                        [disabled]="submitLoader || preDefinedLoader ||  !canAccessForm"
                        [tooltip]="'You can update later should this information change'"
                        class="flex justify-center items-center mark-complete-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 text-regular px-6">
                        <div class="loading-blue mr-2" *ngIf="submitLoader || preDefinedLoader"></div>
                        Mark as Complete
                      </button>
                    </ng-container>
                    <ng-container *ngIf="buttonProgress">
                      <button *ngIf="this.userType !== 'Custodian'" (click)="click('ReturnToDashboard')"
                        [disabled]="submitLoader || preDefinedLoader"
                        class="flex items-center justify-center border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 focus:outline-none text-regular text-white px-6">
                        Return to Dashboard
                      </button>
                      <button (click)="click('NextSection')" [disabled]="submitLoader || preDefinedLoader"
                        class="flex items-center justify-center hover:underline-text text-sm tracking-wide w-full sm:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-6 text-bold">
                        Next Section
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
              <!-- END: Leftside Card -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class="col-span-12 md:col-span-6 lg:grid justify-center">
    <about-yourself-right-content [formInputValue]="{}" [otherValues]="sectionSaveExitOptions" [otherArrayValues]="{}">
    </about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->

</div>

<!-- END: Rightside Content -->
<app-message-modal [setMessageData]="this.messageData" [setMessageSettings]="modalSettings" (buttonEmitter)="this.buttonListener($event)"></app-message-modal>
<app-add-advisor-modal></app-add-advisor-modal>
<app-get-advisor-modal [skipEmailSearch]="true"></app-get-advisor-modal>
<app-demo-video-modal [vaultVideo]="vaultVideo"></app-demo-video-modal>
<app-data-link-service-info-modal [paymentInitiatedSource]="'advisors/manage-advisors'" [dvTransferContent]="dvTransferContent"></app-data-link-service-info-modal>
<app-payment-success></app-payment-success>
<app-payment-failure></app-payment-failure>
