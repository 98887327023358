import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ObservableEventInterface, UpdatePeopleInterface } from '../interface/common.interface';
import { APIResponseModel } from '../interface/response.interface';


@Injectable({
  providedIn: 'root',
})
/**
 * People related services
 */
export class PeopleService {
  private readonly BASE_URL: string;
  // children event handlers
  private childComponent: any;
  public childComponentObserve: Observable<any>;


  // advisor Component event handlers
  public advisorComponent: any;
  public advisorComponentObserve: Observable<any>;

  // advisor Details to remove
  public advisorDetails: any;
  public advisorDetailsObserve: Observable<any>;

  // advisor Id- remove from advisor list
  public advisorIdToRemove: any;
  public advisorIdObserve: Observable<any>;

  // refresh added list details common
  public refreshDetailsCommon: any;
  public refreshDetailsCommonObserve: Observable<any>;

  // dlb event handlers
  private resetChosenBenef: any;
  public resetChosenBenefObserve: Observable<any>;


  /**
   * @constructor
   */
  constructor(
    private http: HttpClient,
  ) {
    this.BASE_URL = environment.BASE_URL;

    // children observable and handlers
    this.childComponent = new BehaviorSubject({});
    this.childComponentObserve = this.childComponent.asObservable();

    // advisor observable and handlers
    this.advisorComponent = new BehaviorSubject({});
    this.advisorComponentObserve = this.advisorComponent.asObservable();

    // advisor Details to remove
    this.advisorDetails = new BehaviorSubject({});
    this.advisorDetailsObserve = this.advisorDetails.asObservable();

    // advisor Id- remove from advisor list
    this.advisorIdToRemove = new BehaviorSubject('');
    this.advisorIdObserve = this.advisorIdToRemove.asObservable();

    // refresh added list details common
    this.refreshDetailsCommon = new BehaviorSubject({});
    this.refreshDetailsCommonObserve = this.refreshDetailsCommon.asObservable();

    // reset chosen beneficiaries in retirement assets
    this.resetChosenBenef = new BehaviorSubject(false);
    this.resetChosenBenefObserve = this.resetChosenBenef.asObservable();
  }


  /**
   * Children observable events
   *
   * @param {ObservableEventInterface} data
   */
  public childrenObservableEvents(data: ObservableEventInterface): void {
    this.childComponent.next(data);
  }


  /**
   * advisor observable events
   *
   * @param {any} data
   */
  public advisorObservableEvents(data: any): void {
    this.advisorComponent.next(data);
  }


  /**
   * advisor id - remove from advisor list
   *
   * @param {any} data
   */
  public advisorObservableId(data: any): void {
    this.advisorIdToRemove.next(data);
  }


  /**
   * refresh added list details common
   *
   * @param {any} data
   */
  public refreshDetailList(data: any): void {
    console.log(data, ' refreshDetailList');
    this.refreshDetailsCommon.next(data);
  }


  /**
   * advisor observable events - remove
   *
   * @param {any} data
   */
  public advisorRemoveEvents(data: any): void {
    this.advisorDetails.next(data);
  }


  /**
   * update beneficiary details
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public updatePeopleDetails(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/request/update-people', data);
  }


  /**
   * update executor / custodian details
   *
   * @param {UpdatePeopleInterface} data
   * @return {Observable<APIResponseModel>}
   */
  public updateExecutorCustodianDetails(data: UpdatePeopleInterface): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/request/update-people-type', data);
  }


  /**
   * update partner and children details
   *
   * @param {any} data
   * @return {Observable<APIResponseModel>}
   */
  public storePeopleDetails(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/request/store-people-details', data);
  }


  /**
   * @param {any} data
   * giving permission to advisor to reachout executor and beneficiaries
   * @return {Observable<APIResponseModel>}
   */
  public authorizeAdvisor(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(this.BASE_URL + '/request/store-people-details', data);
  }


  /**
   * @param {any} data
   * get advisor types
   * @return {Observable<APIResponseModel>}
   */
  public getAdvisorTypes(data?: any): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/professional-type-list?roletype=${data}`);
  }


  /**
   * @param {string} email
   * get advisor details by email
   * @return {Observable<APIResponseModel>}
   */
  public getAdvisorByEmail(email: any): Observable<APIResponseModel> {
    console.log(email);
    return this.http.post<APIResponseModel>(this.BASE_URL + `/request/professional-details`, email);
  }


  /**
   * @param {any} country
   * get professional types
   * @return {Observable<APIResponseModel>}
   */
  public getProfessionalTypes(country?: number): Observable<APIResponseModel> {
    if (country) {
      return this.http.get<APIResponseModel>(this.BASE_URL + `/professional-role-list?country=${country}`);
    } else {
      return this.http.get<APIResponseModel>(this.BASE_URL + `/professional-role-list?country`);
    }
  }


  /**
   * Get sections list
   * @return {Observable}
   */
  public getDashboardSection(): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/professional-permission-list`);
  }


  /**
   * @param {any} data
   * @return {Observable}
   * save advisor permission service types
   */
  public saveAdvisorPermissions(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/advisor-permission-save`, data);
  }


  /**
   * get advisor permissions
   * @param {string} advisorId
   * @param {string} userId
   * @return {Observable}
   */
  public viewAdvisorPermissions(advisorId: string, userId: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/advisor-permission/${advisorId}/${userId}`);
  }


  /**
   * Get advisor permissions using a professional id & user request ID.
   * @param {string} professionalId
   * @param {string} userRequestId
   * @return {Observable}
   */
  public viewAdvisorPermissionsRequest(professionalId: string, userRequestId: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/advisor-permission-request/${professionalId}/${userRequestId}`);
  }


  /**
   * @param {any} data
   * @return {Observable}
   * save executor permissions
   */
  public saveExecutorPermissions(data: any): Observable<APIResponseModel> {
    return this.http.post<APIResponseModel>(`${this.BASE_URL}/executor-permission-save`, data);
  }


  /**
   * get executor permissions
   * @param {string} userId
   * @return {Observable}
   */
  public viewExecutorPermissions(userId: string): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(`${this.BASE_URL}/executor-permission/${userId}`);
  }


  /**
   * @param {string} id
   * get user people details by email
   * @return {Observable<APIResponseModel>}
   */
  public getUserPeopleDetail(id: any): Observable<APIResponseModel> {
    return this.http.get<APIResponseModel>(this.BASE_URL + `/request/user-people-details/${id}`);
  }

  /**
   * triggers event to reset chosen beneficiaries in retirement-assets
   *
   * @param {boolean} trigger
   */
    public resetChosenBenefTrigger(trigger: boolean): void {
      this.resetChosenBenef.next(trigger);
    }
}
