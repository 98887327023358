import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { BsDatepickerInputDirective } from 'ngx-bootstrap/datepicker';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker/bs-datepicker.component';


@Directive({
  selector: '[bsDatepicker]',
  exportAs: 'customDatepicker',
})
export class DatepickerDirective {
  @Input('dp') datepicker: BsDatepickerDirective;


  constructor(
    private _el: ElementRef,
    private _changeDetection: ChangeDetectorRef) {
  }


  /**
   * Set value using Datepicker {@link BsDatepickerInputDirective.writeValue writeValue}.
   * @param value
   * @private
   */
  private setValue(value: Date | string) {
    if (!this.datepicker) {
      return;
    }
    this.datepicker.bsValue = undefined;

    this._changeDetection.detectChanges();
    this._changeDetection.markForCheck();
  }


  /**
   * Get current value in the native element.
   * @private
   */
  private getValue() {
    return this._el.nativeElement.value;
  }


  @HostListener('focusin') onFocusIn() {
    if (String(this.getValue()).toLowerCase() == 'invalid date') {
      this.setValue('');
    }
  }
}
