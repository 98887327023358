import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SelectOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PropertyService } from 'src/app/services/property.service';
import { ALPHA_NUMERIC_WITH_HYPHEN_PATTERN, DATE_FORMAT, LIST_DELETE, LIST_NEW, LIST_UPDATE, NAME_PATTERN, UNITED_KINGDOM } from 'src/constants/application.const';
import { ValidationHelper } from '../../../helper/validation.helper';
import { ConsumerSubscriptionService } from '../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-add-government-proof',
  templateUrl: './add-government-proof.component.html',
})
/**
 * add government proof popup
 */
export class AddGovernmentProofComponent implements OnInit {
  @Output() toggleModalEmitter = new EventEmitter<any>();
  @Output() listEmitter = new EventEmitter<any>();
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() isNewAsset: boolean = true;
  @Input() clientHasPaidSubscription:boolean = false;
  public governmentIDsForm: FormGroup;
  public governmentIDList: Array<any>;
  public submitLoaderDelete: boolean;
  public submitLoader: boolean;
  public userType: string;
  public clientRequestId: string;
  public domainDateFormat: any;
  public locale = 'en';
  public bsConfig: Partial<BsDatepickerConfig>;
  public expiryDateBsConfig: Partial<BsDatepickerConfig>;
  public unitedKingdom: string = UNITED_KINGDOM;
  public selectedCountryName: string = '';
  // enables user to add/edit form fields
  public canAccessForm: boolean;
  public minDate: Date;
  public maxDate: Date;
  private hasPaidSubscription: boolean;

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private propertyService: PropertyService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private toastrService: ToastrService,
    private localeService: BsLocaleService,
    private validation: ValidationHelper,
    private subscriptionService: ConsumerSubscriptionService,
  ) {
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.maxDate.setDate(this.maxDate.getDate());
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.hasPaidSubscription = r);
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.domainDateFormat = this.commonHelper.domainDateFormat;
    this.governmentIDList = [];
    this.submitLoaderDelete = false;
    this.clientRequestId = this.localStorageService.getUserData()?.request?.id || this.localStorageService.getDataByKey('req_id') || this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.initForm();
    this.resetForm();
    this.getGovernmentIDList();
    this.applyLocale();
    this.bsConfig = Object.assign({}, {
      showWeekNumbers: false,
      adaptivePosition: true,
      maxDate: this.maxDate,
      dateInputFormat: this.domainDateFormat,
    });
    this.expiryDateBsConfig = Object.assign({}, {
      showWeekNumbers: false,
      adaptivePosition: true,
      minDate: this.minDate,
      dateInputFormat: this.domainDateFormat,
    });
    if (!this.canAccessForm) {
      this.governmentIDsForm.disable();
    }
  }

  private initForm() {
    this.governmentIDsForm = this.formBuilder.group({
      government_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
      government_id_other: [''],
      id_number: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(ALPHA_NUMERIC_WITH_HYPHEN_PATTERN)] }],
      issue_date: ['', { validators: [this.validation.fn.dateRange(undefined, this.maxDate)] }],
      expiry_date: ['', { validators: [this.validation.fn.dateRange(this.minDate)] }],
      country_issued: [''],
      state_issued: [''],
      id: [''],
    });

    Object.keys(this.governmentIDsForm.controls).forEach(controlName => {
      this.governmentIDsForm.get(controlName)?.valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.governmentIDsForm.get(controlName))
      );
    });
    this.validation.setValidationErrors({
      issue_date: { dateHigh: 'Issue Date must not be a future date.', dateInvalid: null, dateLow: null },
      expiry_date: { dateLow: 'Expiry Date must be a future date.', dateInvalid: null, dateHigh: null },
    });
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getErrors(controlKey: string): Array<string> {
    return this.validation.getErrors(controlKey, this.governmentIDsForm);
  }

  /**
   * apply locale
   */
  public applyLocale(): void {
    this.localeService.use(this.locale);
  }

  /**
   * Get government id types
   */
  public getGovernmentIDList(): void {
    this.commonService.getGovernmentIDList().subscribe((response: APIResponseModel) => {
      if (response.status) {
        const rawData = response.data;

        // Filter out the 'others' option from rawData
        const filteredData = rawData.filter(item => item.id_name.toLowerCase() !== 'others');

        this.governmentIDList = this.commonHelper.convertToOptionsFormat(filteredData, 'id', 'id_name')
          .sort(this.commonHelper.compareDisplayText);

        // Always place 'Other' last to make it easy to find.
        this.governmentIDList = this.commonHelper.placeAtPointOfArray(this.governmentIDList, (e) => e.displayText.toLowerCase() == 'other');

        this.listEmitter.emit(response.data);
        this.resetForm();
      }
    });
  }

  /**
   * setter for editing business details
   *
   * @param {any} data
   */
  @Input() set editData(data: any) {
    if (data && Object.keys(data).length) {
      data.issue_date = data.issue_date && new Date(data.issue_date.replace(/-/g,'/'));
      data.expiry_date = data.expiry_date && new Date(data.expiry_date.replace(/-/g,'/'));
      this.governmentIDsForm.patchValue({
        ...data,
        government_id: data?.government_id?.id,
      });
    }
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.governmentIDsForm?.controls;
  }

  /**
   * change event listener for government id type
   *
   * @param {any} data
   */
  public optionChangeEmitterIDType(data: any): void {
    if (!data) return;
    this.governmentIDsForm.patchValue({
      government_id: data.value,
    });
    if (this.useOther) {
      this.formGet['government_id_other'].addValidators([Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN)]);
      this.formGet['government_id_other'].updateValueAndValidity();
    } else {
      this.formGet['government_id_other'].clearValidators();
      this.formGet['government_id_other'].updateValueAndValidity();
    }
  }

  /**
   * listen for options changes country
   *
   * @param {string} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry) return;
    this.governmentIDsForm.controls.state_issued.reset();
    this.governmentIDsForm.patchValue({
      country_issued: selectedCountry.value,
    });
  }

  /**
   * listen for options changes state
   *
   * @param {string} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.governmentIDsForm.patchValue({
      state_issued: selectedState.value,
    });
  }

  /**
   * marked field
   * @param{string} property
   */
  public markAsTouchedType(property: string) {
    this.formGet[property].markAsTouched({
      onlySelf: true,
    });
  }

  /**
   * add governemnt id type
   */
  public onSubmit(): void {
    this.canEdit && this.governmentIDsForm.markAllAsTouched();
    if (!this.governmentIDsForm.valid) {
      this.commonHelper.scrollToFirstInvalidControl(this.governmentIDsForm);
      return; // return if the form is invalid
    }
    this.submitLoader = true;
    this.governmentIDsForm.value.issue_date = this.commonHelper.formatDate(this.governmentIDsForm.value.issue_date, DATE_FORMAT);
    this.governmentIDsForm.value.expiry_date = this.commonHelper.formatDate(this.governmentIDsForm.value.expiry_date, DATE_FORMAT);

    // add government id
    const requestData = {
      ...this.governmentIDsForm.value,
      roletype: this.userType,
      request_id: this.clientRequestId,
    };
    this.propertyService.addGovernmentID(requestData).subscribe((response: APIResponseModel) => {
      this.submitLoader = false;
      if (response.status) {
        this.toggleModalEmitter.emit({
          ...response.data,
          listStatus: !this.isNewAsset ? LIST_UPDATE : LIST_NEW,
        });
        if (this.userType === 'Consumer') {
          this.commonHelper.updateLocalstorageRequestStage(response.data);
        }
        requestData.id ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();
        this.closeModel();
      }
    }, (exception: any) => {
      this.commonHelper.httpResponseHandler(exception?.error);
      this.submitLoader = false;
    }, () => this.submitLoader = false);
  }

  /**
   * delete government id details
   */
  public deleteGovernmentID(): void {
    // ACL check
    if (!this.permissions?.['delete']) {
      this.toastrService.info('You do not have permission to delete items for this client.');
      return;
    }

    this.submitLoaderDelete = true;
    let sendingData: {} = {
      is_delete: '1',
      id: this.governmentIDsForm.value.id,
      roletype: this.userType,
    };
    if (this.userType !== 'Consumer') {
      sendingData = { ...sendingData, request_id: this.clientRequestId };
    }

    //  Delete donation
    this.propertyService.addGovernmentID(sendingData).subscribe({
        next: (response: APIResponseModel) => {
          if (response.status) {
            this.toggleModalEmitter.emit({
              id: this.governmentIDsForm.value.id,
              listStatus: LIST_DELETE,
            });
            this.commonHelper.toastrDeleteSuccess();
            this.closeModel();
          }
        },
        error: (exception: any) => this.commonHelper.httpResponseHandler(exception?.error),
        complete: () => this.submitLoaderDelete = false,
      },
    );
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.modalService.close('add-government-id-modal');
    this.resetForm();
  }

  /**
   * Reset form
   */
  public resetForm(): void {
    const defaultCountry = this.localStorageService.getDataByKey('country');
    this.governmentIDsForm.reset({
      government_id: this.governmentIDList[0]?.value,
      country_issued: defaultCountry,
    });
  }

  /**
   * Whether the 'Other' field is being used.
   */
  get useOther(): boolean {
    const othersValue = this.governmentIDList.find((e) => e.displayText.toLowerCase() === 'other')?.value;
    return !!this.formGet && this.formGet.government_id.value === othersValue;
  }

  /**
   * If user can edit existing asset, or add a new asset.
   */
  get canEdit(): boolean {
    return this.commonHelper.canEditSection(this.isNewAsset, this.permissions) && (this.hasPaidSubscription || this.clientHasPaidSubscription)
  }
}
