import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonHelper } from 'src/app/helper/common.helper';
import { SlugInterceptorService } from 'src/app/helper/slug-interceptor.service';
import { ProfessionalUserService } from 'src/app/professional-dashboard/services/professional-user.service';
import { CommonService } from 'src/app/services/common.service';
import { SlugService } from 'src/app/services/slug.service';
import { ConsumerPaymentMethod, PROFESSIONAL_ROLE } from 'src/constants/application.const';
import { environment } from 'src/environments/environment';
import { USER_TYPES, messageDataDecedent } from '../../../../../constants/application.const';
import { CommonModelService } from '../../../../services/common-model.service';
import { LocalStorageService } from '../../../../services/local-storage.service';


@Component({
  selector: 'app-professional-navbar',
  templateUrl: './professional-navbar.component.html',
  styleUrls: ['./professional-navbar.component.css'],
})
/**
 * professional navbar
 */
export class ProfessionalNavbarComponent implements OnInit {
  @Input() showReturnToDashboard:boolean = false; 
  @ViewChild('profileMenu') profileMenuRef: ElementRef;
  public readonly PRO_ROUTE = environment.PRO_ROUTE;
  public readonly PROFESSIONAL_ROLE = PROFESSIONAL_ROLE;
  public hasDecedent: boolean;
  public profileMenuToggle: boolean;
  public responsiveMenuToggle: boolean;
  public buttonClicked: boolean;
  public currentPage: string;
  public currentUser: any;
  public roleName: string;
  public imageSrc: string;
  public orgLogoPath: string;
  public orgLogo: string;
  public roleType: string;
  public proRoleTypeKey: string;
  public userRequestID:string
  public canShowMessageMenu:boolean;
  /**
   * @constructor
   */
  constructor(
    private router: Router,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private slugInterceptorService: SlugInterceptorService,
    private slugService: SlugService,
    private professionalUserService: ProfessionalUserService
  ) {
    // document.addEventListener('click', this.offClickHandler.bind(this));
    // router.events
    //     .pipe(filter((e) => e instanceof NavigationEnd))
    //     .subscribe((e: NavigationEnd) => {
    //       this.currentPage = e.url.split('/')[e.url.split('/').length - 1];
    //       console.log(this.currentPage);
    //     });
  }

  /**
   * click event handler
   *
   * @param{any} event
   */
  offClickHandler(event: any) {
    if (!this.profileMenuRef?.nativeElement?.contains(event.target)) {
      this.profileMenuToggle = false;
    }
  }

  /**
   * Called initially
   */
  ngOnInit(): void {
    this.currentUser = this.localStorageService.getUserData(USER_TYPES.pro);
    this.canShowMessageMenu = Number(this.localStorageService.getDataByKey('admin_settings')?.message_menu) === 1;
    this.roleName = this.currentUser?.user.role_name;
    this.roleType = this.currentUser?.user.professional_type_name;
    this.orgLogoPath = this.commonHelper.getOrgLogo();
    this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
    this.slugService.slugObserve.subscribe((response: boolean) => {
      if (response) {
        this.orgLogoPath = this.commonHelper.getOrgLogo();
        this.orgLogo = `${environment.BASE_URL_RAW}uploads${this.orgLogoPath}`;
      } else {
        this.orgLogoPath = '';
        this.orgLogo = '';
      }
    });
    if(this.currentUser?.user?.profile_picture == null || this.isProfessionalOrIndividualAdvisor){
    this.imageSrc = `${environment.BASE_URL_RAW}uploads//professional_logo/${this.currentUser?.user?.logo}`;
    }else{
    this.imageSrc = `${environment.BASE_URL_RAW}uploads/agent_proof/${this.currentUser?.user?.profile_picture}`;
    }
    // TODO investigate duplication of currentUser and imageSrc assignment
    this.commonService.profilePicProObserve.subscribe((data: string) => {
      if (!data) return;
      this.currentUser = this.localStorageService.getUserData(USER_TYPES.pro);
      this.imageSrc = this.isProfessionalOrIndividualAdvisor ? `${environment.BASE_URL_RAW}uploads//professional_logo/${this.currentUser?.user?.logo}`:`${environment.BASE_URL_RAW}uploads/agent_proof/${this.currentUser?.user?.profile_picture}`;
    });

    this.profileMenuToggle = false;
    this.responsiveMenuToggle = false;

    this.currentPage = this.activatedRoute.snapshot.url[0]?.path;
    // this.changeRouter(this.activatedRoute.snapshot.url[0]?.path);
    // types of advisor
    this.proRoleTypeKey = this.roleType === 'Law Firm' ? 'managers' : this.roleType === 'Wealth Management' ? 'project-managers' : 'funeral-directors';
  }


  /**
   * Change router url
   * @param {string} url
   * @param {number} pageIndex
   */
  public navigate(url: string, pageIndex?: number): void {
    this.currentPage = this.activatedRoute.snapshot.url[0]?.path;
    void this.slugInterceptorService.navigate([url], { queryParams: { menu: pageIndex } }, true);
  }

  /**
   * Open Link
   * @param link
   * @param target
   */
  public openLink(link: string, target: string) {
    window.open(link, target);
  }

  get messageDataDecedent() {
    return messageDataDecedent;
  }

  /**
   * Decedent Message Modal handler
   * @param data
   */
  public decedentModalHandler(data: any) {
    if (data) {
      this.modalService.close('message-modal');
    }
  }

  /**
   * Handle click event of 'Decedent Check' header option
   */
  public clickDecedent() {
    this.modalService.open('message-modal');
  }

  /**
   * Logout user
   */
  public logout(): void {
    this.buttonClicked = true;
    this.commonHelper.logoutCommon(USER_TYPES.pro);
  }

  /**
   * get advisor term
   * @return{string}
   */
  public getAdvisorTerm() {
    return this.professionalUserService.getAdvisorTerm();
  }

  /**
   * Indicates whether to show the option to return to the dashboard.
   * @returns {boolean} - True if the option should be displayed; otherwise, false.
   */
  public get showReturnDashboardOption(): boolean {
    return this.showReturnToDashboard
  }

  /**
   * Get whether is professional or individual advisor
   */
  public get isProfessionalOrIndividualAdvisor() : boolean {
    return this.roleName === PROFESSIONAL_ROLE.professional || (this.commonHelper.isNullorUndefined(this.currentUser.user.professional_id) && this.commonHelper.isNullorUndefined(this.currentUser.user.slug_url))
  }

  /**
   * Get whether is pay by professional
   */
  public get isPayByProfessional(): boolean {
    return this.localStorageService.getDataByKey('proPaymentType') == ConsumerPaymentMethod.PAID_BY_PROFESSIONAL;
  }
}
