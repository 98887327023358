<!-- File Uploader -->
<ng-container *ngIf="fileUploadSettings">
  <div class="col-span-12">
    <app-shimmer-loading [loading]="isLoading" [height]="'5rem'">
      <app-file-upload-handler
        *ngIf="permissions?.['add']"
        (fileUploadChangeEmitter)="onFileUploadChangeEmitter($event)" (click)="onFileUploadHandlerClick()"
        (isFileDropWhenNoDocumentSelected)="onIsFileDrop($event)"
        [fileValidationOptions]="fileUploadSettings.fileValidationOptions"
        [hideFileOperations]="fileUploadSettings.hideFileOperations"
        [allowMultiple]="fileUploadSettings.allowMultiple"
        [isDigitalFiles]="fileUploadSettings.isDigitalFiles"
        [displayFileName]="fileUploadSettings.displayFileName"
        [disable]="isDisabled"
        [canEdit]="canAccessForm">
      </app-file-upload-handler>
      <p class="p-2 rounded-b-sm text-gray-700 background-color-grey" *ngIf="!permissions?.['view']">
        You do not have permission to view this client's files.
      </p>
      <p class="p-2 rounded-b-sm text-gray-700 background-color-grey" *ngIf="!permissions?.['add']">
        You do not have permission to add files for this client.
      </p>
      <p class="p-2 rounded-b-sm text-gray-700 background-color-grey" *ngIf="!permissions?.['delete']">
        You do not have permission to delete files for this client.
      </p>
    </app-shimmer-loading>
  </div>
</ng-container>
<!-- File Uploader END-->
<!-- File Percentage -->
<div *ngIf="fileUploadPercent > 0"
     class="flex border border-dashed border-grey h-14 mt-4 rounded p-4">
  <div class="flex ml-auto">
    <img src="assets/images/digital-files/loader.gif" class="w-4 h-4" alt="">
    <p class="text-darkgrey text-regular text-xs pl-2">
      File Uploading
      <span class="font-semibold text-darkblue text-regular">{{fileUploadPercent}}%</span>
    </p>
  </div>
</div>
<!-- File Percentage END -->
<!-- File Listing -->
<ng-container *ngIf="!isLoading">
  <div *ngFor="let file of fileArray let i = index"
       class="border border-dashed border-grey h-14 mt-4 rounded p-4 flex flex-row justify-between items-center">
    <div class="flex flex-row items-center w-9/12 sm:w-10/12">
      <img src="{{'assets/images/digital-files/pdf.svg' | fileTypeIcons:file?.name}}" class="w-6"
           alt="digital vault">
      <p class="text-darkgrey text-regular text-sm px-4 overflow-ellipsis overflow-hidden truncate">
        {{removePrefix ? getName(file) : file?.name}}
      </p>
    </div>
    <div class="flex flex-row items-center justify-between gap-2 h-full w-3/12 sm:w-2/12">
      <!-- View and Download Section -->
      <ng-container *ngIf="permissions?.['view']">
        <div class="w-5 h-5" *ngIf="!checkFileType(file)"></div>
        <div [ngClass]="{'cursor-pointer':canAccessForm}" *ngIf="checkFileType(file)" (click)="viewImage(i)"><img
            src="assets/images/digital-files/eye.svg" class="w-5 h-5" alt="">
        </div>
        <div [ngClass]="{'cursor-pointer':canAccessForm}" (click)="downloadFile(file)"><img
            src="assets/images/digital-files/download.svg" class="w-5 h-5" alt="">
        </div>
      </ng-container>
      <!-- Placeholder for No View Permissions -->
      <ng-container *ngIf="!permissions?.['view']">
        <div class="w-5 h-5"></div>
        <div class="w-5 h-5"></div>
      </ng-container>

      <!-- Delete Section -->
      <ng-container *ngIf="permissions?.['delete']">
        <div  [ngClass]="{'cursor-pointer':canAccessForm}"
          (click)="deleteImage(file?.name, i)">
          <img src="assets/images/digital-files/trash.svg" class="w-5 h-5" alt="" *ngIf="!hideDeleteIcon">
          <img src="assets/images/digital-files/loader.gif" class="w-5 h-5" alt="" *ngIf="hideDeleteIcon">
        </div>
      </ng-container>
      <!-- Placeholder for No Delete Permissions -->
      <div class="w-5 h-5" *ngIf="!permissions?.['delete']"></div>
    </div>
  </div>
  <app-image-view-popup></app-image-view-popup>
</ng-container>
<!-- File Listing END -->
<!-- View Image -->