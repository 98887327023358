import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-card-progress-bar',
  templateUrl: './card-progress-bar.component.html',
})
/**
 * Card progress bar
 */
export class CardProgressBarComponent implements OnInit, AfterViewInit {
  @Input() progressValue:string;
  public animationPb:boolean;
  

  /**
   * called initially
   */
  ngOnInit(): void {
    this.animationPb = false;
  }

  /**
   * called after view initialised
   */
  ngAfterViewInit(): void {
    setTimeout(()=>{
      this.animationPb = true;
    }, 100);
  }
}
