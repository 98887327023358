<div class="lg:grid grid-cols-12 background-color-grey">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 my-4">
              <div class="col-span-12 sm:col-span-2">
                  <img src="assets/images/dashboard/retirement-asset.png" class="w-20" alt="digital vault">
              </div>

              <div class="col-span-12 sm:col-span-10">
                  <h2 class="text-darkblue text-xl sm:text-2xl lg:text-3xl uppercase text-semibold">
                    Retirement Assets
                  </h2>
                  <p class="text-sm text-grey tracking-wide text-regular">This is where you can
                    list where your money is kept. It helps whoever deals with your will to make
                    sure they’ve found everything.
                  </p>
              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-2/4'"></app-card-progress-bar>
              <!-- END: Progress Bar -->

              <div class="p-6">
                <div class="grid grid-cols-12 items-center gap-4 row-gap-4">
                  <div class="col-span-12">
                    <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                      Retirement Assets
                    </h4>
                    <p class="text-grey text-regular pt-1 text-sm">
                      Includes annuities, IRA's, 401ks, and the taxable portion of survivor benefits from defined
                      benefit
                      plans, such as traditional employer pension plans. </p>
                      <app-payment-message-text [clientHasPaidSubscription]="clientHasPaidSubscription"></app-payment-message-text>
                    </div>
                </div>

                <app-retirement-list (isLoading)="setLoading($event)" (emitPermissionsList)="getPermissionsEventListener($event)" (paidStatusEvent)="paidStatusEventListener($event)"></app-retirement-list>

                <div class="mt-6">
                  <button type="button" (click)="openRetirementAssetModal()" [disabled]="disableButton" [ngClass]="{'text-grey border-outline-grey' : disableButton}"
                    class="button button--lg w-full flex flex-row gap-3 justify-center items-center bg-transparent border-red rounded-xs h-12 md:h-16 focus:outline-none text-sm md:text-base text-regular font-semibold tracking-wide text-darkblue px-6">
                    <img src="assets/images/accounts-assets/file-plus.svg" class="hidden md:block w-5" alt="digital vault">Add your
                    Retirement Assets</button>
                </div>

                <div class="flex flex-col-reverse sm:flex-row justify-between items-center gap-4 mt-8">
                  <button (click)="click('Back')"
                    class="cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault">
                    Back
                  </button>
                  <div *ngIf="!buttonProgress" class="flex flex-col-reverse sm:flex sm:flex-row gap-4 justify-between items-center w-full sm:w-auto">
                    <button (click)="click('SaveForLater')"
                      [disabled]="preDefinedLoader || !canAccessForm"
                      class="flex items-center justify-center border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 text-regular px-6">
                      <div class="loading-blue mr-2" *ngIf="preDefinedLoader"></div>
                      Save for Later
                    </button>
                    <button (click)="click('MarkComplete')" [tooltip]="'You can update later should this information change'"
                      [disabled]="preDefinedLoader ||  !canAccessForm"
                      class="flex items-center justify-center mark-complete-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 text-regular px-6">
                      <div class="loading-blue mr-2" *ngIf="preDefinedLoader"></div>
                      Mark as Complete
                    </button>
                  </div>
                  <div *ngIf="buttonProgress" class="flex flex-col-reverse sm:flex-row w-full sm:w-auto gap-4 items-center">
                    <button *ngIf="this.userType === 'Consumer'" (click)="click('ReturnToDashboard')"
                      class="flex items-center justify-center border-button text-sm tracking-wide w-full sm:w-auto rounded-xs h-10 focus:outline-none text-regular text-white px-6">
                      Return to Dashboard
                    </button>
                    <button type="button" [disabled]="isLoading" (click)="click('Next')"
                      class="flex items-center justify-center hover:underline-text text-sm tracking-wide w-full sm:w-auto bg-darkblue rounded-xs h-10 focus:outline-none text-regular text-white px-6 text-bold">
                      <div class="loading-red mr-2" *ngIf="isLoading"></div>
                      Next Section
                    </button>
                  </div>
                </div>
              </div>
              <!-- END: Leftside Card -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class=" col-span-12 md:col-span-6 lg:grid justify-center ">
    <about-yourself-right-content [formInputValue]="{}"
      [otherValues]="sectionSaveExitOptions"></about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->
</div>
<!-- Retirement import popup -->
<app-import-from-plaid-option
  [clientHasPaidSubscription]="clientHasPaidSubscription" 
  [assetType]="'Retirement Assets'"
  [modalID]="midPlaid"
  (plaidOptionEmitter)="plaidOptionListener($event)" [permissions]="permissions">
</app-import-from-plaid-option>
<app-payment-status-modal></app-payment-status-modal>
<app-payment-success></app-payment-success>
<app-payment-failure></app-payment-failure>
