import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentOption } from 'src/app/components/checkout/payment-option.model';
import { CommonHelper } from 'src/app/helper/common.helper';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProCheckoutService {
  private readonly paymentOptionsDefaults = {
    payVaultRegistrationFee: {
      id: 'pay_vault_regsistration_fee',
      header: 'Annual subscription fee per vault',
      description: 'Pay Vault Registration Fee',
      Recurring: false,
      RecurringPeriod: 'Yearly',
      inactive:  this.localstorage.getDataByKey('role') !== 'Professional' && this.localstorage.getDataByKey('role') !== 'Advisor',
    }
  };
  private paymentOptions: CheckoutServicePaymentOptions;
  private cartOptions: string[];
  private lsKey: string = 'pro_checkout_service_cart';

  constructor(
    private localstorage: LocalStorageService,
    private commonHelper: CommonHelper
  ) {
    this.initCart();
  }

  private initCart() {
    this.cartOptions = this.localstorage.getDataByKey('cart') ?? [];
  }

  setCart(ids: string[]) {
    this.cartOptions = [];
    ids.forEach(id => this.updateCart(id));
  }

  get getCart(): string[] {
    return [...this.cartOptions];
  }

  updateCart(id: string, remove = false) {
    if (remove) {
      this.cartOptions = this.cartOptions.filter(e => e != id);
    } else if (Object.keys(this.paymentOptions).find(e => e == id) != null) {
      this.cartOptions.push(id);
    }
    this.localstorage.storeData(this.lsKey, this.cartOptions);
  }

  proceedToVaultRegistrationPayment(perVaultAmount: number, vaultCount: number,totalAmount:number): Observable<any> {
    return new Observable<any>(observer => {
      const paymentOptions = this.getPayVaultRegistrationDetails(perVaultAmount, vaultCount,totalAmount);
      const selectedOption = paymentOptions.find(item => item.id === 'payVaultRegistrationFee');
      if (selectedOption) {
        selectedOption.selected = true;
        this.setCart(paymentOptions.filter(e => e.selected).map(e => e.id));
      }
      observer.next(paymentOptions);
      observer.complete();
    });
  }

  getPayVaultRegistrationDetails(perVaultAmount: number, vaultCount: number,totalAmount): PaymentOption[] {
    this.paymentOptions = { ... this.paymentOptionsDefaults };
    this.paymentOptions.payVaultRegistrationFee = {
      ...this.paymentOptions.payVaultRegistrationFee,
      perVaultFee: Number(perVaultAmount),
      removeOption: false,
      totalVaults: vaultCount
    };
    return Object.keys(this.paymentOptions)
      .filter(id => !this.paymentOptions[id].inactive)
      .map(id => ({ ...this.paymentOptions[id], id }));
  }
  
  getPaymentOptions(): Observable<PaymentOption[]> {
    return new Observable<PaymentOption[]>(observer => {
      let options: PaymentOption[] = [];
          Object.keys(this.paymentOptions).forEach(id => {
            const option: PaymentOption = this.paymentOptions[id];
            if (option.inactive) {
              return;
            }
            options.push({ ...option, id: id });
          });
          observer.next(options);
          observer.complete();
      });
    };
  
}

interface CheckoutServicePaymentOptions {
  payVaultRegistrationFee: PaymentOption,
}
