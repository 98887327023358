import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { AddressHelper } from 'src/app/helper/address.helper';
import { CommonHelper } from 'src/app/helper/common.helper';
import { ICountryCode, SelectOptionsInterface, ToggleOptionsInterface } from 'src/app/interface/common.interface';
import { APIResponseModel } from 'src/app/interface/response.interface';
import { CommonModelService } from 'src/app/services/common-model.service';
import { CommonService } from 'src/app/services/common.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PropertyService } from 'src/app/services/property.service';
import {
  APARTMENT_NUMBER_PATTERN,
  EIN_MASK,
  EIN_NUMBER_PATTERN,
  EMAIL_PATTERN,
  LIST_DELETE,
  LIST_NEW,
  LIST_UPDATE,
  NAME_PATTERN,
  NUMBERS_VALID_DOLLARS,
  PHONE_PATTERN,
  TOGGLE_OPTIONS_YES_NO,
  UNITED_KINGDOM,
  WEBSITE_PATTERN_NEW,
} from 'src/constants/application.const';
import { ELEMENT_EVENTS } from 'src/constants/form.const';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from '../../../../helper/validation.helper';
import { ConsumerSubscriptionService } from '../../../consumer-payment-section/consumer-subscription.service';


@Component({
  selector: 'app-add-business',
  templateUrl: './add-business-details.component.html',
})
/**
 * Add / Edit Business
 */
export class AddBusinessComponent implements OnInit {
  @ViewChild('moveMapHere', { static: false }) moveMapHere: ElementRef;
  @Output() toggleModalEmitter = new EventEmitter<any>();
  @Input() permissions: { view: boolean, add: boolean, edit: boolean, delete: boolean };
  @Input() isNewAsset: boolean = true;
  @Input() clientHasPaidSubscription:boolean = false;
  public readonly toggleOptions: Array<ToggleOptionsInterface> = TOGGLE_OPTIONS_YES_NO;
  public readonly selectedCountry: number = environment.APP_DOMAIN;
  public readonly EIN_MASK: string = EIN_MASK;
  public partnerStatus: number;
  public businessForm: FormGroup;
  public preDefinedLoader: boolean;
  public submitLoader: boolean;
  public submitLoaderDelete: boolean;
  public businessTypes: Array<any>;
  public organisationTypes: Array<any>;
  public userType: string;
  public requestId: string;
  public defaultCountry: number;
  public countryCode: ICountryCode = environment.DEFAULT_COUNTRY_CODE; //  in future should be embeded with form itself
  public selectedCountryName: string = '';
  public unitedKingdom: string = UNITED_KINGDOM;
  public elementRefSet: boolean = false;
  public searchElementRef: ElementRef;
  // enables user to add/edit form fields
  private hasPaidSubscription: boolean;
  public canAccessForm: boolean;

  @ViewChild('search', { static: false })
  set setSearchElementRef(value: ElementRef) {
    this.searchElementRef = value;
    if (this.searchElementRef && !this.elementRefSet) { // if not elementRef set initial value for searchElement
      this.updateAddressPacContainer(this.formGet?.country?.value ?? this.defaultCountry);
      this.elementRefSet = true;
    }
  }

  /**
   * @constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private propertyService: PropertyService,
    private commonHelper: CommonHelper,
    private modalService: CommonModelService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private addressHelper: AddressHelper,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toastrService: ToastrService,
    private validation: ValidationHelper,
    private subscriptionService: ConsumerSubscriptionService,
  ) {
  }

  /**
   * called initially
   */
  ngOnInit(): void {
    this.subscriptionService.hasPaidSubscription(this.localStorageService.getDataByKey('overview_user_id')).then(r => this.hasPaidSubscription = r);
    this.canAccessForm = this.commonHelper.getFormAccessControl();
    this.businessTypes = [];
    this.organisationTypes = [];
    this.requestId = this.route.snapshot.params['id'];
    this.userType = this.localStorageService.getDataByKey('role');
    this.defaultCountry = this.localStorageService.getDataByKey('country');
    this.initForm();
    this.resetForm();
    this.getBusinessOrganisationType();
    this.isEnableState();
    if (!this.canAccessForm) {
      this.businessForm.disable();
    }
  }

  private initForm() {
    this.businessForm = this.formBuilder.group({
      company_name: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(50), Validators.pattern(NAME_PATTERN)] }],
      business_type_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
      business_type_other: ['', { updateOn: 'blur', validators: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN)] }],
      business_organization_type_id: ['', { updateOn: 'blur', validators: [Validators.required] }],
      business_organization_other: ['', { updateOn: 'blur', validators: [Validators.maxLength(50), Validators.pattern(NAME_PATTERN)] }],
      website: ['', { updateOn: 'blur', validators: [Validators.pattern(WEBSITE_PATTERN_NEW), Validators.maxLength(150)] }],
      business_summary: ['', { updateOn: 'blur', validators: [Validators.minLength(3), Validators.maxLength(500)] }],
      address: ['', { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.maxLength(255)] }],
      city: ['', { updateOn: 'blur', validators: [Validators.required, this.validation.noWhitespaceValidator()] }],
      country: ['', { updateOn: 'blur', validators: [Validators.required] }],
      state: ['', [Validators.required]],
      apt_number: ['', { updateOn: 'blur', validators: [Validators.pattern(APARTMENT_NUMBER_PATTERN), Validators.maxLength(25)] }],
      net_value: ['', { updateOn: 'blur', validators: [Validators.pattern(NUMBERS_VALID_DOLLARS)] }],
      zipcode: ['', { updateOn: 'blur', validators: [Validators.required, Validators.maxLength(10)] }],
      id: [''],
      ein: ['', { updateOn: 'blur', validators: [Validators.pattern(EIN_NUMBER_PATTERN)] }],
      partner_first_name: ['', { updateOn: 'blur', validators: [Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      partner_last_name: ['', { updateOn: 'blur', validators: [Validators.pattern(NAME_PATTERN), Validators.maxLength(50)] }],
      partner_phone: ['', { updateOn: 'blur', validators: [Validators.pattern(PHONE_PATTERN)] }],
      partner_email: ['', { updateOn: 'blur', validators: [Validators.pattern(EMAIL_PATTERN)] }],
      partner_status: [''],
    });

    // Automatically trim whitespace for all controls
    Object.keys(this.businessForm.controls).forEach(controlName => {
      this.businessForm.get(controlName)?.valueChanges.subscribe(() => 
        this.validation.trimWhitespace(this.businessForm.get(controlName))
      );
    });

    this.validation.setValidationErrors({
      website: { pattern: 'Invalid URL' },
      ein: { pattern: 'Invalid EIN', mask: null },
      partner_phone: { pattern: 'Please enter a valid phone number' },
      partner_email: { pattern: 'Please enter a valid email address' },
      net_value: { invalidCurrency: 'Accept only numeric input and up to 2 decimal places', pattern: false },
      zipcode: { pattern: 'Please enter a valid zipcode' },
      city: { whitSpaceValue: 'Please enter valid character'}
    });
  }

  /**
   * See {@link ValidationHelper.getErrors}
   * @param controlKey
   */
  public getErrors(controlKey: string): Array<string> {
    return !this.canEdit ? [] : this.validation.getErrors(controlKey, this.businessForm);
  }

  /**
   * Get all business and organisation types
   */
  private getBusinessOrganisationType(): void {
    forkJoin([this.commonService.getBusinessTypes(), this.commonService.getOrganisationTypes()]).subscribe((arrayResponse: Array<APIResponseModel>) => {
      const responseStatus = arrayResponse.map((d) => d.status).reduce((a, b) => (a && b));
      if (responseStatus) {
        this.businessTypes = this.commonHelper.convertToOptionsFormat(arrayResponse[0]?.data, 'id', 'name').sort(this.commonHelper.compareDisplayText);
        this.organisationTypes = this.commonHelper.convertToOptionsFormat(arrayResponse[1]?.data, 'id', 'name').sort(this.commonHelper.compareDisplayText);

        // Always place 'Other' last to make it easy to find.
        this.commonHelper.placeAtPointOfArray(this.businessTypes, (e) => e.displayText.toLowerCase() == 'other');
        this.commonHelper.placeAtPointOfArray(this.organisationTypes, (e) => e.displayText.toLowerCase() == 'other');
      }
      // this.resetForm();
    });
  }

  /**
   * Accessor for commonHelper in template
   */
  get getCommonHelper(): CommonHelper {
    return this.commonHelper;
  }

  /**
   * setter for editing business details
   *
   * @param {any} data
   */
  @Input() set editData(data: any) {
    if (data && data?.id) {
      this.businessForm.patchValue({
        ...data,
        country: data?.country?.id,
        state: data?.state?.id,
        city: data?.city?.id,
        apt_number: data?.apt_number,
        partner_email: data?.partner_email,
      });
      if (data?.partner_status) {
        this.partnerStatus = data?.partner_status;
      }
      let tempPhoneNumber;
      if (data?.partner_phone) {
        tempPhoneNumber = data?.partner_phone;
      }
      if (data?.country_code) {
        this.countryCode = { code: data?.flag_code, dialCode: data?.country_code };
      }
      this.businessForm.patchValue({
        partner_phone: tempPhoneNumber,
      });
      this.updateAddressPacContainer(data?.country?.id);
    }
  }

  /**
   * On Change toggle
   *
   * @param {ToggleOptionsInterface} selectedValue
   */
  public onChangeToggle(selectedValue: ToggleOptionsInterface): void {
    if (!selectedValue) {
      return;
    } else {
      this.partnerStatus = selectedValue?.value;
      const condition = this.partnerStatus === 1;

      this.applyConditionalValidation('partner_first_name', condition, [Validators.maxLength(50), Validators.pattern(NAME_PATTERN), Validators.required]);
      this.applyConditionalValidation('partner_last_name', condition, [Validators.maxLength(50), Validators.pattern(NAME_PATTERN), Validators.required]);
      this.applyConditionalValidation('partner_email', condition, [Validators.pattern(EMAIL_PATTERN)]);
      this.applyConditionalValidation('partner_phone', condition, [Validators.pattern(PHONE_PATTERN)]);
      this.businessForm.get('partner_first_name').markAsUntouched();
      this.businessForm.get('partner_last_name').markAsUntouched();
      this.elementRefSet = false;
    }
  }

  /**
   * Initialize google map api by country
   * @param {any} countryId
   */
  public initMap(countryId: any): void {
    console.log('initmap');
    this.addressHelper.initMap(countryId, this.searchElementRef?.nativeElement).subscribe({
      next: r => {
        this.ngZone.run(() => {
          this.businessForm.patchValue(r);
        });
      },
      error: e => console.error('initmap error', e),
    });
  }

  /**
   * clear addresss related fields if street address is empty
   * @param {any} search
   */
  public onAddressChange(search: any) {
    if (search.value === '') {
      this.businessForm.patchValue({
        city: '',
        state: '',
        zipcode: '',
      });
    }
  }

  /**
   * listen for options changes country
   *
   * @param {SelectOptionsInterface} selectedCountry
   */
  public optionChangeListenerCountry(selectedCountry: SelectOptionsInterface): void {
    if (!selectedCountry) return;
    this.businessForm.controls.state.reset();
    this.businessForm.patchValue({
      country: selectedCountry.value,
    });
    this.updateAddressPacContainer(selectedCountry.value);
  }

  /**
   * remove pac container and update country in pac container
   *
   */
  public updateAddressPacContainer(initCountry: any) {
    this.addressHelper.removeAutocompletePacContainer();
    this.initMap(initCountry);
  }

  /**
   * listen for options changes state
   *
   * @param {SelectOptionsInterface} selectedState
   */
  public optionChangeListenerState(selectedState: SelectOptionsInterface): void {
    if (!selectedState) return;
    this.businessForm.controls.city.reset();
    this.businessForm.patchValue({
      state: selectedState.value,
    });
  }

  /**
   * listen for options changes state
   *
   * @param {SelectOptionsInterface} selectedCity
   */
  public optionChangeListenerCity(selectedCity: SelectOptionsInterface): void {
    if (!selectedCity) return;
    this.businessForm.patchValue({
      city: selectedCity.displayText,
    });
    // zipCode validation based on state change
    const zipCodeRef = this.businessForm.get('zipcode');
    if (selectedCity?.additionalDetails) {
      zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(selectedCity?.additionalDetails[0]?.postal_code_regex))]);
      zipCodeRef.updateValueAndValidity();
    }
    // this loader is set false here because of the delay to set country state city
    this.preDefinedLoader = false;
  }

  /**
   * form object getter for validation and showing errors in html
   */
  get formGet() {
    return this.businessForm.controls;
  }

  /**
   * Detect change on phone number component
   *
   * @param event
   */
  public detectChangePhoneNumber(event): void {
    if (event) {
      this.countryCode = event.value;
    }
  }

  /**
   * change event listener for business type
   *
   * @param {any} data
   */
  public optionChangeEmitterBusinessType(data: any): void {
    if (!data) return;
    this.businessForm.patchValue({
      business_type_id: data.value,
    });
  }

  /**
   * change event listener for organisation type
   *
   * @param {any} data
   */
  public optionChangeEmitterOrganisationType(data: any): void {
    if (!data) return;
    this.businessForm.patchValue({
      business_organization_type_id: data.value,
    });
  }

  /**
   * Whether the 'Other' field is being used for {@link business_organization_type_id}.
   */
  get useOrganizationOther(): boolean {
    return this.formGet.business_organization_type_id.value ===
      this.organisationTypes.find((e) => e.displayText.toLowerCase() === 'other')?.value;
  }

  /**
   * Whether the 'Other' field is being used for {@link business_type_id}.
   */
  get useIndustryOther(): boolean {
    return this.formGet.business_type_id.value ===
      this.businessTypes.find((e) => e.displayText.toLowerCase() === 'other' || e.displayText.toLowerCase() === 'other services')?.value;
  }

  /**
   * add business
   */
  public onSubmit(): void {
    // Mark all as touched to show any remaining errors on missed fields.
    this.canEdit && this.businessForm.markAllAsTouched();
    if (!this.businessForm.valid) {
      this.commonHelper.scrollToFirstInvalidControl(this.businessForm);
      return; // return if the form is invalid
    }
    // add business
    let payLoad: {} = {
      ...this.businessForm.value,
      roletype: this.userType,
      is_state: this.selectedCountryName == this.unitedKingdom ? 0 : 1,
      country_code: this.countryCode.dialCode,
      flag_code: this.countryCode.code,
      partner_status: this.partnerStatus,
    };
    if (this.userType !== 'Consumer') {
      payLoad = { ...payLoad, request_id: this.requestId };
    }
    payLoad['state'] = this.selectedCountryName == this.unitedKingdom ? null : payLoad['state'];
    this.submitLoader = true;
    const errorFn = (e) => {
      this.commonHelper.httpResponseHandler(e?.error);
      this.submitLoader = false;
    };
    this.commonService.getCityDetail(payLoad['country'], payLoad['state'], payLoad['city']).subscribe({
      next: (cResponse: APIResponseModel) => {
        if (cResponse?.status) {
          payLoad['city'] = cResponse?.data?.id;
          this.propertyService.addBusiness(payLoad).subscribe({
            next: (response: APIResponseModel) => {
              if (response.status) {
                this.toggleModalEmitter.emit({
                  ...response.data,
                  listStatus: (this.businessForm.value.id) ? LIST_UPDATE : LIST_NEW,
                });
                if (this.userType === 'Consumer') {
                  this.commonHelper.updateLocalstorageRequestStage(response.data);
                  this.businessForm.value.id ? this.commonHelper.toastrUpdateSuccess() : this.commonHelper.toastrInsertSuccess();
                }
                this.closeModel();
              }
            }, error: errorFn,
            complete: () => this.submitLoader = false,
          });
        }
      }, error: errorFn,
    });
  }

  /**
   * delete business details
   */
  public deleteBusiness(): void {
    // ACL check
    if (!this.permissions?.['delete']) {
      this.toastrService.info('You do not have permission to delete items for this client.');
      return;
    }

    this.submitLoaderDelete = true;

    let sendingData: {} = {
      is_delete: '1',
      id: this.businessForm.value.id,
      roletype: this.userType,
    };
    if (this.userType !== 'Consumer') {
      sendingData = { ...sendingData, request_id: this.requestId };
    }

    // Delete business
    this.propertyService.addBusiness(sendingData).subscribe({
      next: (response: APIResponseModel) => {
        if (response.status) {
          this.toggleModalEmitter.emit({
            id: this.businessForm.value.id,
            listStatus: LIST_DELETE,
          });
          this.commonHelper.toastrDeleteSuccess();
          this.closeModel();
        }
      }, error: (e: any) => this.commonHelper.httpResponseHandler(e.error),
      complete: () => this.submitLoaderDelete = false,
    });
  }

  /**
   * mark a field as touched ( Individual business )
   *
   * @param {string} business
   */
  public markAsTouchedIndividual(business: string): void {
    this.formGet[business].markAsTouched({
      onlySelf: true,
    });
  }

  /**
   * close modal
   */
  public closeModel(): void {
    this.commonHelper.scrollToTopModal(this.businessForm);
    this.modalService.close('add-business-modal');
    this.resetForm();
    this.updateAddressPacContainer(this.defaultCountry);
  }

  /**
   * Reset form
   */
  public resetForm(): void {
    this.businessForm?.reset({
      business_type_id: null,
      business_organization_type_id: null,
      country: this.defaultCountry,
    });
    this.elementRefSet = false;
    this.partnerStatus = this.toggleOptions.find(e => e.displayText.toLowerCase() == 'no')?.value; // When initially opening the form, set the partnerStatus to "no".
    if (this.partnerStatus == undefined) {
      console.error('partnerStatus', this.partnerStatus);
    }

  }

  get state() {
    return this.businessForm.get('state');
  }

  /**
   * enable state based on country selection ( if Uk disable state)
   */
  private isEnableState() {
    this.businessForm.controls['country'].valueChanges.subscribe((value) => {
      this.selectedCountryName = this.commonHelper.getCountryNameById(value);
      this.selectedCountryName !== this.unitedKingdom ?
        this.state.addValidators([Validators.required]) : this.state.clearValidators();
      this.state.updateValueAndValidity();
    });
  }

  /**
   * Validate zipcode if user manually enters it
   */
  public validateZipCode() {
    const zipCodeRef = this.businessForm.get('zipcode');
    const stateId = this.businessForm.get('state')?.value;
    if (stateId) { // if has state id , get regex else return to form
      this.commonService.getRegex(stateId).subscribe((response: APIResponseModel) => {
        if (response.data.length) {
          const zipCodeRegex = response.data[0].postal_code_regex;
          zipCodeRef.setValidators([Validators.required, Validators.pattern(new RegExp(zipCodeRegex))]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        } else {
          zipCodeRef.setValidators([Validators.required]);
          zipCodeRef.updateValueAndValidity({ onlySelf: true });
        }
      }, (exception: any) => this.commonHelper.httpResponseHandler(exception?.error));
    } else {
      return;
    }
  }

  /**
   * If user can edit existing asset, or add a new asset.
   */
  get canEdit(): boolean {
    return this.commonHelper.canEditSection(this.isNewAsset, this.permissions) && (this.hasPaidSubscription || this.clientHasPaidSubscription)
  }

  /**
   * focusout of input field
   * changed as touched field
   * check and show validation error
   * @param {any} data
   * @param {string} key
   */
  public onFocusout(data: any, key: string) {
    let validators;
    if (key === 'partner_email') {
      validators = [Validators.pattern(EMAIL_PATTERN)];
      this.businessForm.get(key).updateValueAndValidity();
    } else if (key === 'partner_phone') {
      validators = [Validators.pattern(PHONE_PATTERN)];
      this.businessForm.get(key).updateValueAndValidity();
    } else {
      validators = [Validators.pattern(NAME_PATTERN), Validators.maxLength(50), Validators.required];
    }
    this.businessForm.get(key).addValidators(validators);
    this.businessForm.get(key).updateValueAndValidity();
    if (data?.type == ELEMENT_EVENTS.FOCUSOUT) {
      this.businessForm.get(key).markAsTouched();
    }
  }

  /**
   * change input field
   * changed as untouched field
   * @param {any} data
   * @param {string} key
   */
  public onChangeInput(data: any, key: string) {
    if (data?.type == ELEMENT_EVENTS.INPUT) {
      this.businessForm.get(key).markAsUntouched();
    }
  }

  /**
   * Apply conditional validation to a form control based on a condition.
   *
   * @param{string} controlName The name of the form control.
   * @param{boolean} condition The condition to determine whether to apply or clear validation.
   * @param{ValidatorFn[]} validators (Optional) An array of additional validators to apply.
   */
  private applyConditionalValidation(controlName: string, condition: boolean, validators: ValidatorFn[] = []): void {
    // Get the control from the form group of the specified address details
    const control = this.businessForm.get(controlName);

    if (condition) {
      // Apply the specified validators and the required validator (if condition is true)
      control?.setValidators([...validators]);
    } else {
      // Clear all validators if the condition is false
      control?.clearValidators();
    }

    // Update the control's validation state
    control?.updateValueAndValidity();
  }

  /**
   * this method enables pac-container(address suggestions) to move along with the address field on page scroll
   */
  onFocusAddress() {
    this.addressHelper.movePacContainer(this.moveMapHere?.nativeElement);
  }
}
