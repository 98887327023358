<div class="bg-transparent border-lightblue rounded-xs">
    <div class="grid grid-cols-12 h-20 items-center gap-4 px-3 sm:pl-3">
        <div class="col-span-12 sm:col-span-12">
            <div class="form-check" *ngFor="let schedule of scheduleReminder;let i=index">
              <app-shimmer-loading [loading]="preDefinedLoader" class="w-full h-12"></app-shimmer-loading>
              <ng-container *ngIf="!preDefinedLoader">
                <input class="form-check-input" type="radio" name="radios" id="radios_{{i}}" [value]="schedule?.value"
                    (change)="onSelectSchedule($event)" [checked]="onCheckedSchedule(schedule?.value)">
                <label class="form-check-label text-grey text-regular tracking-wide pt-1 text-md" for="radios_{{i}}">
                    {{schedule?.displayText}}
                </label>
              </ng-container>
            </div>
        </div>
    </div>
</div>
