<div class="lg:grid grid-cols-12 background-color-grey">
  <!-- BEGIN: Leftside Content -->
  <div class="col-span-12 md:col-span-6 sm:p-6 lg:pr-0 flex">
    <div class="flex flex-col w-full">
      <div class="h-full items-center justify-center">
        <div class="pt-6 mx-auto px-6 sm:px-8 lg:px-5 lg:pl-16 w-full">
          <div class="h-full justify-center items-center ">
            <div class="grid grid-cols-12 items-center gap-4 my-4">
              <div class="col-span-12 sm:col-span-2">
                  <img src="assets/images/executors/executors.svg" class="w-20" alt="digital vault">
              </div>

              <div class="col-span-12 sm:col-span-10">
                  <h2 class="text-darkblue text-md sm:text-2xl break-words uppercase text-semibold">
                    Digital Vault Custodian and Executor
                  </h2>
                  <p class="text-sm text-grey tracking-wide text-regular">
                    This information is critical and a key to verifying their identity. Please double-check your input
                    prior to moving on.
                  </p>
              </div>
            </div>
            <!-- BEGIN: Leftside Card -->
            <div class="border-card bg-white rounded-md">
              <!-- BEGIN: Progress Bar -->
              <app-card-progress-bar [progressValue]="'w-1/6'"></app-card-progress-bar>
              <!-- END: Progress Bar -->


              <div class="p-6">

                <div class="mb-4">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    Who is a Digital Vault Custodian?
                  </h4>
                  <p class="text-grey text-regular pt-2 text-sm tracking-wide">
                    The Digital Vault Custodian (DVC) is a trusted individual you appoint to inform LegacyNOW of your
                    passing. This notification initiates the process of informing your Executor(s).
                  </p>
                </div>

                <div class="mb-4">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    <!-- What is an Executor? -->
                    Who is an Executor?
                  </h4>
                  <p class="text-grey text-regular pt-2 text-sm tracking-wide">
                    The executor is the person you appoint to carry out the directions and requests in your will, and
                    to dispose of the property according to your wishes. You can have one or more person named as
                    executor. You can choose a professional executor, friend, family or a combination of both.
                  </p>
                </div>

                <div class="mb-4">
                  <h4 class="text-regular text-lg text-darkblue font-semibold tracking-wide">
                    Choosing an Executor
                  </h4>
                  <p class="text-grey text-regular pt-2 text-sm tracking-wide">
                    Acting as an Executor is a significant responsibility. If you choose a friend or a family member, they
                    should be:
                  </p>
                  <ul class="mt-2 pl-4">
                    <li class="text-grey text-regular pt-2 text-sm tracking-wide">
                      ➝ Over 18
                    </li>
                    <li class="text-grey text-regular pt-2 text-sm tracking-wide">
                      ➝ Trustworthy and responsible
                    </li>
                    <li class="text-grey text-regular pt-2 text-sm tracking-wide">
                      ➝ Good with finances and complex paperwork
                    </li>
                    <li class="text-grey text-regular pt-2 text-sm tracking-wide">
                      ➝ Able to dedicate the time needed
                    </li>
                    <li class="text-grey text-regular pt-2 text-sm tracking-wide">
                      ➝ Informed of your decision and accepts the responsible fiduciary responsibilities associated.
                    </li>
                  </ul>
                </div>

                <div class="flex flex-row justify-between items-center mt-8 mb-2">
                  <button (click)="click('Back')"
                    class=" cursor-pointer text-sm text-back text-regular font-semibold tracking-wide flex justify-between items-center focus:outline-none">
                    <img src="assets/images/about-yourself/back-arrow.svg" class="w-3 mr-3" alt="digital vault"> Back
                  </button>
                  <app-button-loader [buttonText]="'Next'" [valid]="true" [loader]="false" [buttonType]="'button'"
                    (click)="click('Next')"></app-button-loader>
                </div>
              </div>
              <!-- END: Leftside Card -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END: Leftside Content -->

  <!-- BEGIN: Rightside Content -->
  <div class=" col-span-12 md:col-span-6 lg:grid justify-center">
    <about-yourself-right-content [formInputValue]="{}"
      [otherValues]="sectionSaveExitOptions"></about-yourself-right-content>
  </div>
  <!-- END: Rightside Content -->
</div>
